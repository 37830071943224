/* eslint-disable no-useless-escape */
export const validateCompanyName = (name) => {
  // Regex to match letters, numbers, spaces, and certain special characters
  const regex = /^[a-zA-Z0-9&.\-() ]{3,100}$/;
  // Check if the name matches the regex
  return name && regex.test(name.trim());
};

export function emailValidation(emailId) {
  if (!emailId || emailId == "") return false;
  const regex =
    /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
  if (regex.test(emailId) === false) return false;
  return true;
}

export function websiteValidation(wesite) {
  if (!wesite || wesite == "") return false;
  const regex =
    /^(https?:\/\/)?(www\.)?[a-zA-Z0-9-]+(\.[a-zA-Z]{2,})+(\.[a-zA-Z]{2,})?(\/[^\s]*)?$/;
  if (regex.test(wesite) === false) return false;
  return true;
}
export const validateAccountSite = (accountSite) => {
  if (!accountSite || accountSite == "") return false;
  const regex = /^[a-zA-Z0-9\s\-\_\.\,]{1,255}$/;
  return regex.test(accountSite);
};

export const convertDateFormat = (dateString) => {
  if (!dateString) return "";
  const [day, month, year] = dateString.split("-");
  return `${year}-${month}-${day}`;
};

export const convertToDisplayFormat = (dateString) => {
  if (!dateString) return "";
  const [year, month, day] = dateString.split("-");
  return `${day}-${month}-${year}`;
};

export function camelCaseToSpace(string) {
  // Use regex to insert a space before all capital letters
  return (
    string
      ?.replace(/([A-Z])/g, " $1")
      //Replace the first character with its uppercase version
      .replace(/^./, function (str) {
        return str?.toUpperCase();
      })
  );
}
export const validateFirstName = (name) => {
  if (typeof name !== "string") return false;
  const regex = /^[a-zA-Z' -]{2,50}$/;
  return regex.test(name.trim());
};
export const validateFaxNumber = (faxNumber) => {
  const regex =
    /^\+?[0-9]{1,3}(\s*[\-\.]?\s*)?\(?\d{1,4}\)?[\s\-\.]?\d{1,4}[\s\-\.]?\d{3,10}$/;
  return regex.test(faxNumber);
};

export const validateTeamName = (name) => {
  if (typeof name !== "string") return false;
  const regex = /^[A-Za-z0-9\s\-]{3,20}$/;
  return regex.test(name.trim());
};

export const validateLastName = (name) => {
  if (typeof name !== "string") return false;
  const regex = /^[a-zA-Z'-]{1,50}$/;
  return regex.test(name.trim());
};

export const validateJobTitle = (title) => {
  if (typeof title !== "string") return false;
  const regex = /^[a-zA-Z0-9\s\-.,\/]{1,100}$/;
  return regex.test(title.trim());
};

export const validateAnnualRevenue = (revenue) => {
  if (typeof revenue !== "string") return false;
  const regex = /^\d+(\.\d{1,2})?$/;
  return regex.test(revenue.trim());
};

export const validateSkypeId = (skypeId) => {
  if (typeof skypeId !== "string") return false;
  const regex = /^[a-zA-Z][a-zA-Z0-9\.,\-_]{5,31}$/;
  return regex.test(skypeId.trim());
};
export const validateTwitterUsername = (twitterId) => {
  if (typeof twitterId !== "string") return false;
  const regex = /^[a-zA-Z0-9\s@_]{1,15}$/;
  return regex.test(twitterId.trim());
};
export const validateDepartmentName = (departmentName) => {
  if (typeof departmentName !== "string") return false;
  const regex = /^[A-Za-z\s\-_]{1,50}$/;
  return regex.test(departmentName.trim());
};
export const validateAssistantName = (assistantName) => {
  if (typeof assistantName !== "string") return false;
  const regex = /^[A-Za-z\s\-']{1,100}$/;
  return regex.test(assistantName.trim());
};
export const validateReportingTo = (reportingTo) => {
  if (typeof reportingTo !== "string") return false;
  const regex = /^[A-Za-z\s\-']{1,50}$/;
  return regex.test(reportingTo.trim());
};

export const validateCountryName = (country) => {
  if (typeof country !== "string") return false;
  const regex = /^[\w\s.'-]{2,100}$/;
  return regex.test(country.trim());
};

export const validateStateIndia = (state) => {
  if (typeof state !== "string") return false;
  const regex = /^[\w\s.'-]{2,50}$/;
  return regex.test(state.trim());
};

export const validateCityIndia = (city) => {
  if (typeof city !== "string") return false;
  const regex = /^[\w\s.'-]{2,50}$/;
  return regex.test(city.trim());
};

export const validateCallSubject = (subject) => {
  if (typeof subject !== "string") return false;
  const regex = /^[\w\s.,\-_'&]{1,100}$/;
  return regex.test(subject.trim());
};

export const validateAmount = (amount) => {
  // if (typeof amount !== "string") return false;
  const parsedAmount = parseFloat(amount);
  return (
    !isNaN(parsedAmount) && parsedAmount >= 0 && parsedAmount <= 1000000000
  );
};

export const validateMobileNumber = (mobileNumber) => {
  const mobileRegex = /^[6-9][0-9]{9}$/;
  return mobileRegex.test(mobileNumber);
};

export const validateZipCode = (zipCode) => {
  if (typeof zipCode !== "string") return false;
  const regex = /^[0-9]{6}$/;
  return regex.test(zipCode.trim());
};
export const validateAccountNumber = (accountNumber) => {
  if (typeof accountNumber !== "string") return false;
  const regex = /^[0-9\-]{1,20}$/;
  return regex.test(accountNumber.trim());
};
export const validateTicketSymbol = (tickerSymbol) => {
  if (typeof tickerSymbol !== "string") return false;
  const regex = /^[A-Z0-9.\-_]{1,10}$/;
  return regex.test(tickerSymbol.trim());
};
export const validateNumberOfEmployees = (noOfEmployee) => {
  if (typeof noOfEmployee !== "string") return false;
  const regex = /^\d+$/;
  return regex.test(noOfEmployee.trim());
};
export const validateSICCode = (sicCode) => {
  if (typeof sicCode !== "string") return false;
  const regex = /^\d{4}$/;
  return regex.test(sicCode.trim());
};
export const validateProductName = (productName) => {
  if (typeof productName !== "string") return false;
  const regex = /^[A-Za-z0-9\s\-()'.&]{1,100}$/;
  return regex.test(productName.trim());
};
export const validateProductCode = (productCode) => {
  if (typeof productCode !== "string") return false;
  const regex = /^[A-Za-z0-9\-_.]{1,50}$/;
  return regex.test(productCode.trim());
};
export const validateUnitPrice = (unitPrice) => {
  if (typeof unitPrice !== "string") return false;
  const regex = /^(?:\d{1,3}(?:,\d{3})*|\d+)(?:\.\d{1,2})?$/;
  return regex.test(unitPrice.trim());
};
export const validateQuantityInStock = (quantity) => {
  if (typeof quantity !== "string") return false;
  const regex = /^\d+$/;
  return (
    regex.test(quantity.trim()) &&
    parseInt(quantity.trim(), 10) >= 0 &&
    parseInt(quantity.trim(), 10) <= 1000000
  );
};
export const validateQuantityOrdered = (quantity) => {
  if (typeof quantity !== "string") return false;
  const regex = /^(?!0\d)\d+$/;
  return (
    regex.test(quantity.trim()) &&
    parseInt(quantity.trim(), 10) >= 1 &&
    parseInt(quantity.trim(), 10) <= 1000
  );
};
export const validateReorderLevel = (reorderLevel) => {
  if (typeof reorderLevel !== "string") return false;
  const regex = /^\d+$/;
  return (
    regex.test(reorderLevel.trim()) &&
    parseInt(reorderLevel.trim(), 10) >= 1 &&
    parseInt(reorderLevel.trim(), 10) <= 100
  );
};
export const validateQuantityInDemand = (quantity) => {
  if (typeof quantity !== "string") return false;
  const regex = /^\d+$/;
  return (
    regex.test(quantity.trim()) &&
    parseInt(quantity.trim(), 10) >= 0 &&
    parseInt(quantity.trim(), 10) <= 1000
  );
};

export const convertCamelCaseToReadable = (array) => {
  return array.map((key) => {
    const result = key.replace(/([A-Z])/g, " $1");
    return result.charAt(0).toUpperCase() + result.slice(1);
  });
};
export const validateCallAgenda = (agenda) => {
  if (typeof agenda !== "string") return false;
  const regex = /^[a-zA-Z0-9\s.,!?()'-]+$/;
  return (
    regex.test(agenda) &&
    agenda.trim().length > 0 &&
    agenda.trim().length <= 100
  );
};

export const validateCustomerNumber = (customerNumber) => {
  const regex = /^[a-zA-Z0-9]{4,20}$/;
  return regex.test(customerNumber.trim());
};

export const removeExtraSpaces = (str) => {
  if (str == null) {
    return "";
  }
  return str.replace(/^\s+|\s+$/g, "").replace(/\s+/g, " ");
};

export const convertSnakeCaseToReadableString = (str) => {
  if (str === "activity_stats") {
    return "Activity Statistics";
  }
  // Replace underscores with spaces and split the string into words
  const words = str?.split("_");
  // Capitalize the first letter of each word and join them with spaces
  const readableString = words
    ?.map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
  return readableString;
};

export const validateCampaignName = (campaignName) => {
  var regex = /^[a-zA-Z0-9\s\-#!_]{1,255}$/;
  return regex.test(campaignName);
};

export const validateCalcField = (value) => {
  var regex = /^[-+/*]?[0-9]{1,15}(\.[0-9]{1,2})?$/;
  return regex.test(value);
};

export const validateBudgetedCost = (budgetedCost) => {
  var regex = /^(?=.*[1-9])\d{1,12}(\.\d{1,2})?$/;
  return regex.test(budgetedCost);
};

export const formatNumberWithoutDecimalZero = (num) => {
  // Remove trailing ".00" if present
  const formattedNum = num?.replace(/\.00$/, "");

  // Return the formatted number as a string
  return formattedNum;
};

export const validateCategory = (category) => {
  // Define regex pattern
  const regex = /^[a-zA-Z0-9](?:[a-zA-Z0-9 _-]{1,48}[a-zA-Z0-9])?$/;

  // Test the category against the regex pattern
  return regex.test(category);
};

export const validateRoleName = (roleName) => {
  // Define regex pattern
  const regex = /^[A-Za-z\s]{3,30}$/;

  // Test the category against the regex pattern
  return regex.test(roleName);
};

export const validateAliasName = (aliasName) => {
  const regex = /^[a-zA-Z0-9 _-]{3,50}$/;
  return regex.test(aliasName);
};

export const removeLeadingZeros = (expr) => {
  return expr.replace(/\b0+(\d)/g, "$1");
};

export const customCommonStyles = (width, maxHeight) => ({
  menu: (base) => ({
    ...base,
    width: width || "220px", // Default width if not provided
  }),
  option: (provided, state) => ({
    ...provided,
    color: state.isSelected ? "#fff" : "#000",
    backgroundColor: state.isSelected ? "#407BFF" : "",
    width: "100%",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "#f5f5f5",
      color: "#000",
    },
  }),
  control: (baseStyles) => ({
    ...baseStyles,
    border: "2px solid #316AFF",
    backgroundColor: "#fff",
    height: "40px",
    borderRadius: "10px",
    width: "100%",
    boxShadow: "none",
    cursor: "pointer",
  }),
  input: (base) => ({
    ...base,
    color: "#FFFFFF",
  }),
  singleValue: (defaultStyles) => ({ ...defaultStyles, color: "#000" }),
  menuList: (provided) => ({
    ...provided,
    paddingTop: 0,
    paddingBottom: 0,
    maxHeight: maxHeight || "none", // Ensure the height is not restricted
    "&::-webkit-scrollbar": {
      width: 4, // Remove the scrollbar
    },
    "&::-webkit-scrollbar-thumb": {
      background: "#ADC3FF",
      borderRadius: "10px",
    },
  }),
  dropdownIndicator: (base) => ({
    ...base,
    color: "#316AFF", // Custom color
  }),
});

export const formatNumber = (value) => {
  const getValue = parseFloat(value);

  if (isNaN(getValue)) {
    return value;
  }

  if (getValue >= 1.0e9) {
    return (getValue / 1.0e9).toFixed(2) + "B"; // Billions
  } else if (getValue >= 1.0e6) {
    return (getValue / 1.0e6).toFixed(2) + "M"; // Millions
  } else if (getValue >= 1.0e3) {
    return (getValue / 1.0e3).toFixed(2) + "K"; // Thousands
  } else {
    return getValue.toString(); // Less than thousand
  }
};

export const getValue = (key, value) => {
  if (value) {
    if (key === "unitPrice" || key === "commissionRate") {
      return "₹ " + value;
    } else {
      return value;
    }
  }
};

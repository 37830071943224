import React, { useState } from "react";

const Sidebar = ({
  filteredMenuItems,
  currentView,
  setCurrentView,
  setInnerPage,
  sideBarSmall,
  setPastViewInfo,
  setInputValue,
  backToDefaultValues,
  setShowSearchInput
}) => {
  const [hoveredItem, setHoveredItem] = useState(null);
  const [nestedHover, setNestedHover] = useState(null);
  const [tab, setTab] = useState({ sidebarTab: "home", nestedTab: "home" });

  return (
    <>
      {filteredMenuItems.map((item, index) => {
        return (
          <div
            key={index}
            className={`${
              currentView === item.name
                ? sideBarSmall
                  ? "sideBarAcSmall"
                  : "sideBarItemsActive"
                : sideBarSmall
                ? "sideBarDiSmall"
                : "sideBarItemsDisable"
            } position-relative`}
            onClick={(e) => {
              e.stopPropagation();
              setShowSearchInput(false)

              if (item.name === "home") {
                setCurrentView(item.name);
                setInputValue("");
                setInnerPage("list");
                setTab({
                  sidebarTab: item.name,
                  nestedTab: item.name,
                });
                setPastViewInfo(null);
                backToDefaultValues();
              }
            }}
            onMouseEnter={() => setHoveredItem(item.name)}
            onMouseLeave={() => setHoveredItem(null)}
          >
            <img
              src={
                hoveredItem === item.name || tab.sidebarTab === item.name
                  ? item.activeIcon
                  : item.icon
              }
              className="sideBarIcon"
              alt="img"
            />
            <p
              className={`sidebarSmallName ${
                sideBarSmall ? "text-center" : ""
              }`}
              style={{
                color:
                  hoveredItem === item.name || tab.sidebarTab === item.name
                    ? "#2177FE"
                    : "",
              }}
            >
              {item.label}
            </p>
            {item.subMenu && (
              <div className="position-absolute sideBarOverItems">
                {item.subMenu &&
                  item.subMenu.map((ele) => (
                    <div
                      key={ele.id}
                      className="d-flex align-items-center sidebarItems"
                      style={{ gap: "10px" }}
                      onMouseEnter={() => setNestedHover(ele.name)}
                      onMouseLeave={() => setNestedHover(null)}
                      onClick={() => {
                        setCurrentView(ele.name);
                        setInputValue("");
                        setShowSearchInput(false)
                        setInnerPage("list");
                        setTab({
                          sidebarTab: item.name,
                          nestedTab: ele.name,
                        });
                        setPastViewInfo(null);
                        backToDefaultValues();
                      }}
                    >
                      <img
                        src={
                          nestedHover === ele.name ? ele.activeIcon : ele.icon
                        }
                        alt="logo"
                        className="sidebarOverIcons"
                      />
                      <p
                        className="sidebarSmallName"
                        style={{
                          color:
                            nestedHover === ele.name ? "#2177FE" : "#676767",
                        }}
                      >
                        {ele.label}
                      </p>
                    </div>
                  ))}
              </div>
            )}
          </div>
        );
      })}
    </>
  );
};

export default Sidebar;

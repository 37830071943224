import React from "react";

const leftArrow = (
  <svg
    width="28"
    height="13"
    viewBox="0 0 31 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.49963 9.00073L30.9996 9.00027"
      stroke="#515151"
      strokeWidth="2.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.49963 1.5L2.06029 7.93934C1.56029 8.43934 1.31029 8.68934 1.31029 9C1.31029 9.31066 1.56029 9.56066 2.06029 10.0607L8.49963 16.5"
      stroke="#515151"
      strokeWidth="2.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const rightAngleArrow = (
  <svg
    width="28"
    height="28"
    viewBox="0 0 36 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.5 27L21.4393 19.0607C21.9393 18.5607 22.1893 18.3107 22.1893 18C22.1893 17.6893 21.9393 17.4393 21.4393 16.9393L13.5 9"
      stroke="#515151"
      strokeWidth="2.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const leftAngleArrow = (
  <svg
    width="28"
    height="28"
    viewBox="0 0 36 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M22.5 9L14.5607 16.9393C14.0607 17.4393 13.8107 17.6893 13.8107 18C13.8107 18.3107 14.0607 18.5607 14.5607 19.0607L22.5 27"
      stroke="#515151"
      // stroke-opacity="0.5"
      strokeWidth="2.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const editIcon = (
  <svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.20975 2.26659C8.64444 1.79563 8.86178 1.56015 9.09274 1.42279C9.65002 1.09136 10.3362 1.08105 10.9028 1.3956C11.1376 1.52596 11.3616 1.75482 11.8097 2.21253C12.2577 2.67023 12.4818 2.89909 12.6094 3.13895C12.9173 3.71773 12.9072 4.41871 12.5828 4.98799C12.4483 5.22392 12.2178 5.44595 11.7568 5.89L6.27128 11.1734C5.39759 12.0149 4.96075 12.4357 4.41478 12.6489C3.86882 12.8622 3.26862 12.8465 2.06821 12.8151L1.90489 12.8108C1.53944 12.8013 1.35672 12.7965 1.25051 12.6759C1.14429 12.5554 1.15879 12.3693 1.18779 11.997L1.20354 11.7949C1.28517 10.7472 1.32598 10.2233 1.53058 9.75238C1.73517 9.28147 2.08809 8.8991 2.79391 8.13437L8.20975 2.26659Z"
      stroke="#141B34"
      strokeWidth="0.875"
      strokeLinejoin="round"
    />
    <path
      d="M7.58325 2.33325L11.6666 6.41659"
      stroke="#141B34"
      strokeWidth="0.875"
      strokeLinejoin="round"
    />
    <path
      d="M8.16675 12.8333L12.8334 12.8333"
      stroke="#141B34"
      strokeWidth="0.875"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const tick = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="18"
    height="18"
    fill="currentColor"
    className="bi bi-check2"
    viewBox="0 0 16 16"
  >
    <path d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0" />
  </svg>
);

const expandArrow = (
  <svg
    width="27"
    height="27"
    viewBox="0 0 27 27"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle
      cx="13.5"
      cy="13.5"
      r="12.5"
      fill="url(#paint0_linear_1399_3162)"
      stroke="white"
      strokeWidth="2"
    />
    <path
      d="M14.125 17.25L16.9166 13.9001C17.076 13.7088 17.1557 13.6131 17.1557 13.5C17.1557 13.3869 17.076 13.2912 16.9166 13.0999L14.125 9.75"
      stroke="white"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9.75 17.25L12.5416 13.9001C12.701 13.7088 12.7807 13.6131 12.7807 13.5C12.7807 13.3869 12.701 13.2912 12.5416 13.0999L9.75 9.75"
      stroke="white"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <defs>
      <linearGradient
        id="paint0_linear_1399_3162"
        x1="0"
        y1="27"
        x2="28.7189"
        y2="25.0276"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#407BFF" />
        <stop offset="1" stopColor="#12A7FE" />
      </linearGradient>
    </defs>
  </svg>
);

const shrinkArrow = (
  <svg
    width="27"
    height="27"
    viewBox="0 0 27 27"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle
      cx="13.5"
      cy="13.5"
      r="12.5"
      transform="rotate(180 13.5 13.5)"
      fill="url(#paint0_linear_1399_3165)"
      stroke="white"
      strokeWidth="2"
    />
    <path
      d="M12.8749 9.75L10.0834 13.0999C9.92394 13.2912 9.84422 13.3869 9.84422 13.5C9.84422 13.6131 9.92394 13.7088 10.0834 13.9001L12.8749 17.25"
      stroke="white"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M17.25 9.75L14.4584 13.0999C14.299 13.2912 14.2193 13.3869 14.2193 13.5C14.2193 13.6131 14.299 13.7088 14.4584 13.9001L17.25 17.25"
      stroke="white"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <defs>
      <linearGradient
        id="paint0_linear_1399_3165"
        x1="-9.53674e-07"
        y1="27"
        x2="28.7189"
        y2="25.0276"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#407BFF" />
        <stop offset="1" stopColor="#12A7FE" />
      </linearGradient>
    </defs>
  </svg>
);

export {
  leftArrow,
  rightAngleArrow,
  leftAngleArrow,
  editIcon,
  tick,
  expandArrow,
  shrinkArrow,
};

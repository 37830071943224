/* eslint-disable */
import React, { useState, useEffect } from "react";
import filterIcon from "../../Assets/Frame (3).svg";
import Select from "react-select";
import { URI } from "../../constants";
import axios from "axios";
import "./meetings.css";
import Pagination from "../../common/Pagination";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import { massOptions, pageOption } from "../../common/CommonData";
import tableFilterIcon from "../../Assets/filterIcon.svg";
import arrowUp from "../../Assets/square_arrow_up.svg";
import arrowDown from "../../Assets/square_arrow_down.svg";
import pin from "../../Assets/Pin_column.svg";
import unPin from "../../Assets/Unpin.svg";
import cancel from "../../Assets/cancel_circle.svg";
import cancelCircle from "../../Assets/cancelCircle.svg";
import filterLine from "../../Assets/filterLine.svg";
import filterMenu from "../../Assets/filterMenu.svg";
import { useSelector } from "react-redux";
import Loader from "../../common/commonRelatedList/Loader";
import { Modal } from "react-bootstrap";
import searchIcon from "../../Assets/search.svg";
import editIcon1 from "../../Assets/editIconMeet.svg";
import addIcon from "../../Assets/add-01.svg";
import moment from "moment";
import userSearch from "../../Assets/user-search-01.svg";
import down from "../../Assets/Down31.svg";
import up from "../../Assets/Up.svg";
import LeadOwnerModal from "../../common/LeadOwnerModal";
import ContactListModal from "../../common/ContactListModal";
import LeadListModal from "../../common/LeadListModal";
import randomImage from "../../Assets/Lead_Avatar.png";
import mailInfo from "../../Assets/MailInfoContainer.svg";
import callInfoContainer from "../../Assets/callInfoContainer.svg";
import telephoneInfo from "../../Assets/TelephoneInfoContainer.svg";
import AccountsInfoContainer from "../../Assets/AccountsInfoContainer.svg";
import timeMeetings from "../../Assets/timeMeetings.svg";
import CalenderMeetings from "../../Assets/CalenderMeetings.svg";
import LocationMeetings from "../../Assets/LocationMeetings.svg";
import AlarmClockMeetings from "../../Assets/AlarmClockMeetings.svg";
import TestingMeetings from "../../Assets/TestingMeetings.svg";
import {
  leftArrow,
  rightAngleArrow,
  leftAngleArrow,
  editIcon,
} from "../../common/commonSVGs/commonSVGIcons.js";
import ChangeOwnerModal from "../../common/commonRelatedList/ChangeOwnerModal.js";
import { EXPLORER_URL } from "../../constants/DataConst.js";
import {
  validateJobTitle,
  removeExtraSpaces,
  customCommonStyles,
} from "../../common/CommonComponent";
import noDataImage from "../../Assets/Meeting-amico 1.svg";
import Note from "../../common/commonRelatedList/Note.js";
import Lottie from "react-lottie";
import { defaultOptions } from "../../utils/defaultOptions.js";
import getHeaders from "../../common/ApiHeader.js";

import { handleApiError } from "../../utils/ApiUtils";
import { useNavigate } from "react-router-dom";

function Meetings({
  setInnerPage,
  innerPage,
  permissionList,
  notePermission,
  storeModuleId,
}) {
  const navigate = useNavigate();

  const reminderOptions = [
    { label: "None", value: "None" },
    { label: "At time of meeting", value: "At time of meeting" },
    { label: "5 minutes before", value: "5 minutes before" },
    { label: "10 minutes before", value: "10 minutes before" },
    { label: "15 minutes before", value: "15 minutes before" },
    { label: "30 minutes before", value: "30 minutes before" },
    { label: "1 hour before", value: "1 hour before" },
    { label: "2 hour before", value: "2 hour before" },
    { label: "1 day before", value: "1 day before" },
    { label: "2 days before", value: "2 days before" },
  ];

  const leadContactOptions = [
    { label: "None", value: "None" },
    { label: "Lead", value: "Lead" },
    { label: "Contact", value: "Contact" },
    // { label: "Others", value: "Others" },
  ];

  const AddParticipantsOptions = [
    { label: "Users", value: "Users" },
    { label: "Leads", value: "Leads" },
    { label: "Contacts", value: "Contacts" },
  ];

  const repeatEveryOptions = [
    { label: "None", value: "None" },
    { label: "Daily", value: "Daily" },
    { label: "Weekly", value: "Weekly" },
    { label: "Monthly", value: "Monthly" },
    { label: "Yearly", value: "Yearly" },
    { label: "Custom", value: "Custom" },
  ];

  const FrequencyOptions = [
    { label: "Daily", value: "Daily" },
    { label: "Weekly", value: "Weekly" },
    { label: "Monthly", value: "Monthly" },
    { label: "Yearly", value: "Yearly" },
  ];

  const relatedToOptions = [
    { value: "NONE", label: "None" },
    { value: "ACCOUNTS", label: "Account" },
    { value: "DEALS", label: "Deal" },
    { value: "PRODUCTS", label: "Product" },
    { value: "QUOTES", label: "Quote" },
    { value: "SALES_ORDER", label: "Sales Order" },
    { value: "PURCHASE_ORDERS", label: "Purchase Order" },
    { value: "INVOICES", label: "Invoice" },
    { value: "VENDORS", label: "Vendor" },
    { value: "CAMPAIGNS", label: "Campaign" },
  ];

  const meetingsColumn = [
    {
      name: "Title",
      isChecked: true,
      value: "title",
    },
    {
      name: "Modified Time",
      isChecked: false,
      value: "modifiedTime",
    },
    {
      name: "Related To",
      isChecked: true,
      value: "relatedName",
    },
    {
      name: "To Date",
      isChecked: false,
      value: "toDate",
    },
    {
      name: "Description",
      isChecked: true,
      value: "description",
    },
    {
      name: "From Date",
      isChecked: false,
      value: "fromDate",
    },
    {
      name: "All Day",
      isChecked: false,
      value: "allDay",
    },
    {
      name: "Relation To",
      isChecked: false,
      value: "relationName",
    },
    {
      name: "Created By",
      isChecked: false,
      value: "createdBy",
    },
    {
      name: "Relation Module",
      isChecked: false,
      value: "relationModuleName",
    },
    {
      name: "Related Module",
      isChecked: false,
      value: "relatedModuleName",
    },
    {
      name: "Host",
      isChecked: false,
      value: "host",
    },
    {
      name: "Created Time",
      isChecked: false,
      value: "createdTime",
    },
    {
      name: "Location",
      isChecked: false,
      value: "location",
    },
    {
      name: "Modified By",
      isChecked: false,
      value: "modifiedBy",
    },
    {
      name: "Hash",
      isChecked: true,
      value: "transHash",
    },
  ];

  const massColumn = [
    {
      name: "Location",
      isChecked: false,
      value: "location",
    },
  ];

  const createAccountStyles = {
    menu: (base) => ({
      ...base,
      width: "95%",
    }),
    option: (provided) => ({
      ...provided,
      // backgroundColor: "#EAEAEA",
      color: "#000",
      // backgroundColor: 'rgba(33, 119, 254, 0.1)',
      width: "100%",
    }),
    control: (baseStyles) => ({
      ...baseStyles,
      border: "1px solid #b9b9b9b0",
      //   backgroundColor: "#F3F6FB",
      height: "50px",
      borderRadius: "5px",
      width: "95%",
      boxShadow: "none",
    }),
    input: (base) => ({
      ...base,
      color: "#FFFFFF",
    }),
    singleValue: (defaultStyles) => ({ ...defaultStyles, color: "#9C9C9C" }),

    menuList: (provided) => ({
      ...provided,
      paddingTop: 0,
      paddingBottom: 0,
      "&::-webkit-scrollbar": {
        width: 5,
      },

      "&::-webkit-scrollbar-thumb": {
        background: "#316AFF",
        borderRadius: "10px",
      },
    }),
  };

  const reduxStore = useSelector((state) => state.reduxState);
  const { loginUserId } = reduxStore;
  const options = reduxStore.filterOptions.meeting;
  // const [innerPage, setInnerPage] = useState("list");
  // const [filterView, setFilterView] = useState(false); setFilterView is not used anywhere
  const [dropdownRelatedTo, setDropdownRelatedTo] = useState(false);
  const userList = JSON.parse(sessionStorage.getItem("userList"));
  const [selectedOwner, setSelectedOwner] = useState(userList);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState(0); // Total number of items
  const [perPage, setPerPage] = useState(10);
  const [meetingId, setMeetingId] = useState("");
  const [selectedOption, setSelectedOption] = useState(options[0]);
  const [dropdownSelectReminder, setDropdownSelectReminder] = useState(false);
  const [dropdownSelect, setDropdownSelect] = useState(false);
  const [selectedOptionLead, setSelectedOptionLead] = useState(
    leadContactOptions[0]
  );
  const [selectedOptionRepeat, setSelectedOptionRepeat] = useState(
    repeatEveryOptions[0]
  );
  const [selectedOptionFrequency, setSelectedOptionFrequency] = useState(
    FrequencyOptions[0]
  );
  const [addParticipant, setAddParticipant] = useState(
    AddParticipantsOptions[0]
  );
  // const [dropdownTwo, setDropdownTwo] = useState(massOptions[0]); dropdownTwo is not used anywhere
  const [isLoading, setIsLoading] = useState(true);
  const [selectedHeadings, setSelectedHeadings] = useState(meetingsColumn);
  const [showHeadings, setShowHeadings] = useState(false);
  const [showSelectedFilter, setShowSelectedFilter] = useState("");
  const [filterType, setFilterType] = useState("");
  const [pinnedColumn, setPinnedColumn] = useState("Title");
  const [filteredMeetingDetails, setFilteredMeetingDetails] = useState([]);
  const [modalType, setShowModalType] = useState("");
  const [modalSubType, setShowModalSubType] = useState("");
  const [listVisibleType, setListVisibleType] = useState("");
  // const [popoutType, setPopoutType] = useState(""); popoutType is not used anywhere
  // const [showOptionsContact, setShowOptionsContact] = useState(false); showOptionsContact is not used
  // const [purchaseOrdId, setPurchaseOrdId] = useState(""); purchaseOrdId is not used
  // const [selectedField, setSelectedField] = useState(""); selectedField is not used
  const [selectedCheckboxes, setSelectedCheckboxes] = useState([]);
  const [searchColumn, setSearchColumn] = useState("");
  const [savingHeadings, setSavingHeadings] = useState(meetingsColumn);
  const [isAllDay, setIsAllDay] = useState(false);
  const [selectedContact, setSelectedContact] = useState({});
  const [leadList, setLeadList] = useState([]);
  const [selectedLeadItem, setSelectedLeadItem] = useState(leadList);
  const [contactList, setContactList] = useState([]);
  const [listVisible, setListVisible] = useState(false);
  const [isContactListModal, setIsContactListModal] = useState(false);
  const [isLeadListModal, setIsLeadListModal] = useState(false);
  const [productList, setProductList] = useState([]);
  const [invoiceList, setInvoiceList] = useState([]);
  const [salesList, setSalesList] = useState([]);
  const [selectedAccount, setSelectedAccount] = useState({});
  const [selectedDeal, setSelectedDeal] = useState({});
  const [selectedQuote, setSelectedQuote] = useState({});
  const [vendorList, setVendorList] = useState([]);
  const [selectedVendorItem, setSelectedVendorItem] = useState(vendorList);
  const [selectedProductItem, setSelectedProductItem] = useState(productList);
  const [selectedInvoiceItem, setSelectedInvoiceItem] = useState(invoiceList);
  const [selectedSalesItem, setSelectedSalesItem] = useState(salesList);
  const [purchaseList, setPurchaseList] = useState([]);
  const [isLeadOwnerModal, setIsLeadOwnerModal] = useState(false);

  const [selectedPurchaseItem, setSelectedPurchaseItem] =
    useState(purchaseList);
  const [selectedOptionModule, setSelectedOptionModule] = useState(
    relatedToOptions[0]
  );
  const [selectedDate, setSelectedDate] = useState(null);
  const [showDateInput, setShowDateInput] = useState(false);
  const [searchInput, setSearchInput] = useState("");
  const [filteredItems, setFilteredItems] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const [selectedParticipants, setSelectedParticipants] = useState([]);
  const [emailInput, setEmailInput] = useState("");
  const [emailList, setEmailList] = useState([]);
  const [selectedEmailList, setSelectedEmailList] = useState([]);
  const [meetingOverviewDetails, setMeetingOverviewDetails] = useState({});
  const [selectedMeetingId, setSelectedMeetingId] = useState("");
  const [selectedOptionRadio, setSelectedOptionRadio] = useState("never");
  const [deleteModal, setDeleteModal] = useState("");
  const [activeMeetingId, setActiveMeetingId] = useState(null);
  const [activeTab, setActiveTab] = useState("Info");
  // const [meetView, setMeetView] = useState("list"); meetView is not used any where
  const [allMeetingDetails, setAllMeetingDetails] = useState([]);
  const [infoDetails, setInfoDetails] = useState({});
  const [openActivities, setOpenActivities] = useState([]);
  const [timeLineDataList, setTimeLineDataList] = useState([]);
  const [editPurchaseOverview, setEditPurchaseOverview] = useState(false);
  const [addButton, setAddButton] = useState(false);
  const [participantsDetails, setParticipantsDetails] = useState([]);
  const [showOwnerModalType, setShowOwnerModalType] = useState("");
  const [emailNotification, setEmailNotification] = useState(true);
  const [isChangeOwnerList, setIsChangeOwnerList] = useState(false);
  // const [isOwnerSelect, setIsOwnerSelect] = useState(false); isOwnerSelect is not used anywhere
  const [overviewPage, setOverviewPage] = useState(false);
  const [deletedItems, setDeletedItems] = useState([]);
  const [isDropdownOpenEdit, setIsDropdownOpenEdit] = useState(false);
  const [fromTimeSet, setFromTimeSet] = useState({
    hour: "00",
    minutes: "00",
    meridiem: "AM",
  });
  const [showFromTime, setShowFromTime] = useState({
    hour: false,
    minutes: false,
  });
  const [toTimeSet, setToTimeSet] = useState({
    hour: "00",
    minutes: "00",
    meridiem: "AM",
  });
  const [showToTime, setShowToTime] = useState({
    hour: false,
    minutes: false,
  });
  const [selectedObj, setSelectedObj] = useState({
    name: "Location",
    isChecked: false,
    value: "location",
  });
  const [massUpdateValue, setMassUpdateValue] = useState("");
  const [showSearchFields, setShowSearchFields] = useState(massColumn);
  const [moduleListVisible, setModuleListVisible] = useState(false);
  const [mandatoryMsg, setMandatoryMsg] = useState("");
  const [moduleWiseData, setModuleWiseData] = useState([]);
  const [selectedModuleItem, setSelectedModuleItem] = useState(moduleWiseData);
  const [overlapModal, setOverlapModal] = useState(false);
  const [overlapMessage, setOverlapMessage] = useState("");
  const [showButtons, setShowButtons] = useState(false);
  const [selectRecent, setSelectRecent] = useState("ALL");
  const [showAccessModal, setShowAccessModal] = useState(false);
  const [editNote, setEditNote] = useState(false);
  const [noteInfo, setNoteInfo] = useState([]);
  const [addTitle, setAddTitle] = useState("");
  const [note, setNote] = useState("");
  const [noteTitle, setNoteTitle] = useState("");
  const [showTitleNote, setShowTitleNote] = useState(false);
  const [selectedNoteFiles, setSelectedNoteFiles] = useState([]);
  const [deletedImg, setDeletedImg] = useState("");
  const [updateNotes, setUpdateNotes] = useState({
    description: "",
    title: "",
    noteImageList: [],
  });
  // const [clickedDropdownObj, setClickedDropdownObj] = useState({
  //   label: "",
  //   value: "",
  // }); clickedDropdownObj is not used anywhere
  const [meetingInfo, setMeetingInfo] = useState({
    title: "",
    makeThisOnline: false,
    fromDate: new Date().toISOString().split("T")[0],
    fromTime: "",
    toDate: new Date().toISOString().split("T")[0],
    toTime: "",
    location: "",
    repeatType: "NONE",
    description: "",
    allDay: true,
    participantsRemainder: reminderOptions[0],
    remainder: reminderOptions[0],
    afterTimes: 1,
    repeatEveryNum: 0,
  });
  const [apiLoader, setApiLoader] = useState(false);
  const [createLoader, setCreateLoader] = useState(false);
  const [noteDeleteConfirmation, setNoteDeleteConfirmation] = useState(false);

  useEffect(() => {
    if (storeModuleId) {
      meetingsOverview(storeModuleId);
      setMeetingId(storeModuleId);
    }
  }, []);

  const resetForm = {
    title: "",
    makeThisOnline: false,
    fromDate: new Date().toISOString().split("T")[0],
    fromTime: "",
    toDate: new Date().toISOString().split("T")[0],
    toTime: "",
    location: "",
    repeatType: "NONE",
    description: "",
    allDay: true,
    participantsRemainder: reminderOptions[0],
    remainder: reminderOptions[0],
    relatedTo: "",
    relatedRelationId: "",
    addParticipants: [],
    afterTimes: 1,
    repeatEveryNum: 0,
  };

  const handleSelectActiveTab = (value) => {
    setActiveTab(value);
  };

  const getAllAssignModules = async (main, sub, id) => {
    const requestBody = {
      loginUserId: loginUserId,
      mainModule: main,
      subModule: sub,
      moduleId: id,
    };

    const headersResult = getHeaders(true);

    // Check if headers result indicates an error
    if (headersResult.error) {
      navigate("../"); // Redirect to login if token is missing
      return;
    }

    await axios
      .post(URI.moduleWiseAssign, requestBody, { headers: headersResult })
      .then((response) => {
        if (response.data.status === 200) {
          setModuleWiseData(response.data.data.assignModulewiseDetails);
        }
      })
      .catch(async (error) => {
        handleApiError(error, navigate);
      });
  };

  const handleCheckMeeting = async (e) => {
    let isValidForm = true;

    const setErrorMessage = (setter, message, timeout = 5000) => {
      setter(message);
      setTimeout(() => {
        setter("");
      }, timeout);
      isValidForm = false;
    };

    if (meetingInfo.title === "") {
      setErrorMessage(setMandatoryMsg, "Title name cannot be empty.", 4000);
    }

    if (!validateJobTitle(meetingInfo.title) && meetingInfo.title.length > 0) {
      setErrorMessage(setMandatoryMsg, "Please enter a valid title.");
    }

    if (!isValidForm) return;

    if (
      meetingInfo.fromDate > meetingInfo.toDate ||
      (!isAllDay && (fromTimeSet.hour === "00" || toTimeSet.hour === "00")) ||
      (!isAllDay &&
        meetingInfo.fromDate === meetingInfo.toDate &&
        fromTimeSet.hour === toTimeSet.hour &&
        fromTimeSet.minutes === toTimeSet.minutes &&
        fromTimeSet.meridiem === toTimeSet.meridiem)
    ) {
      if (meetingInfo.fromDate > meetingInfo.toDate) {
        setTimeout(() => {
          NotificationManager.error(
            "",
            "From date cannot be greater than to date",
            3000
          );
        });
      }
      if (!isAllDay && (fromTimeSet.hour === "00" || toTimeSet.hour === "00")) {
        setTimeout(() => {
          NotificationManager.error("", "Hours cannot be Zero", 3000);
        });
      } else if (
        !isAllDay &&
        meetingInfo.fromDate === meetingInfo.toDate &&
        fromTimeSet.hour === toTimeSet.hour &&
        fromTimeSet.minutes === toTimeSet.minutes &&
        fromTimeSet.meridiem === toTimeSet.meridiem
      ) {
        setTimeout(() => {
          NotificationManager.error(
            "",
            "From and To time cannot be the same",
            3000
          );
        });
      }
    } else {
      let requestBody = {};
      requestBody = {
        ...requestBody,
        moduleName: "meetings",
        loggedInUserId: loginUserId,
        fromDate: moment(new Date(meetingInfo.fromDate)).format("DD-MM-YYYY"),
        toDate: moment(new Date(meetingInfo.toDate)).format("DD-MM-YYYY"),

        allDay: isAllDay ? "true" : "false",
      };
      if (!isAllDay) {
        requestBody = {
          ...requestBody,
          fromTime: `${fromTimeSet.hour}:${fromTimeSet.minutes} ${fromTimeSet.meridiem}`,
          toTime: `${toTimeSet.hour}:${toTimeSet.minutes} ${toTimeSet.meridiem}`,
        };
      }
      setApiLoader(true);
      const headersResult = getHeaders(true);

      // Check if headers result indicates an error
      if (headersResult.error) {
        navigate("../"); // Redirect to login if token is missing
        return;
      }
      await axios
        .post(URI.checkMeetingAvailable, requestBody, {
          headers: headersResult,
        })

        .then((response) => {
          if (response.data.status === 200) {
            setOverlapMessage(response.data.message);
            if (response.data.data.isScheduled) {
              setOverlapModal(true);
            } else {
              handleInsertMeetings(e);
            }
          } else {
            NotificationManager.error("", response.data.message, 5000);
          }
        })
        .catch(function (error) {
          handleApiError(error, navigate);
        })
        .finally(() => {
          setApiLoader(false);
        });
    }
  };

  const handleInsertMeetings = async (e) => {
    if (
      meetingInfo.title === "" ||
      meetingInfo.fromDate > meetingInfo.toDate ||
      (!isAllDay &&
        meetingInfo.fromDate === meetingInfo.toDate &&
        fromTimeSet.hour === toTimeSet.hour &&
        fromTimeSet.minutes === toTimeSet.minutes &&
        fromTimeSet.meridiem === toTimeSet.meridiem) ||
      (!isAllDay && (fromTimeSet.hour === "00" || toTimeSet.hour === "00"))
    ) {
      if (meetingInfo.title === "") {
        setMandatoryMsg("Title cannot be empty.");
        setTimeout(() => {
          setMandatoryMsg("");
        }, 3000);
      }
      if (meetingInfo.fromDate > meetingInfo.toDate) {
        setTimeout(() => {
          NotificationManager.error(
            "",
            "From cannot be greater than To time",
            3000
          );
        });
      }
      if (!isAllDay && (fromTimeSet.hour === "00" || toTimeSet.hour === "00")) {
        if (
          !isAllDay &&
          (fromTimeSet.hour === "00" || toTimeSet.hour === "00")
        ) {
          setTimeout(() => {
            NotificationManager.error("", "Hours cannot be Zero", 3000);
          });
        } else if (
          !isAllDay &&
          meetingInfo.fromDate === meetingInfo.toDate &&
          fromTimeSet.hour === toTimeSet.hour &&
          fromTimeSet.minutes === toTimeSet.minutes &&
          fromTimeSet.meridiem === toTimeSet.meridiem
        ) {
          setTimeout(() => {
            NotificationManager.error(
              "",
              "From and To time cannot be the same",
              3000
            );
          });
        }
      }
    } else {
      let requestBody = {};
      if (!isAllDay) {
        requestBody = {
          fromTime: `${fromTimeSet.hour}:${fromTimeSet.minutes} ${fromTimeSet.meridiem}`,
          toTime: `${toTimeSet.hour}:${toTimeSet.minutes} ${toTimeSet.meridiem}`,
        };
      }
      if (!editPurchaseOverview) {
        requestBody = {
          ...requestBody,
          repeatType: selectedOptionRepeat.value,
        };
      }
      if (
        selectedOptionRepeat.value !== "None" &&
        selectedOptionRadio === "never" &&
        !editPurchaseOverview
      ) {
        requestBody = {
          ...requestBody,
          repeatEnds: "Never",
        };
      } else if (
        selectedOptionRepeat.value !== "None" &&
        selectedOptionRadio === "after" &&
        !editPurchaseOverview
      ) {
        requestBody = {
          ...requestBody,
          repeatEnds: `After ${meetingInfo.afterTimes} Times`,
        };
      } else if (
        selectedOptionRepeat.value !== "None" &&
        selectedOptionRadio === "on" &&
        !editPurchaseOverview
      ) {
        requestBody = {
          ...requestBody,
          repeatEnds: `On ${moment(new Date(selectedDate)).format(
            "DD-MM-YYYY"
          )}`,
        };
      }
      const modId = selectedModuleItem.moduleId;

      if (selectedOptionLead.value === "Contact") {
        requestBody = {
          ...requestBody,
          relatedTo: selectedContact.contactId,
          relatedRelationId: modId,
        };
      } else if (selectedOptionLead.value === "Lead") {
        requestBody = {
          ...requestBody,
          relatedTo: selectedLeadItem.leadId,
        };
      } else if (selectedOptionLead.value === "Others") {
        requestBody = {
          ...requestBody,
          relatedRelationId: modId,
        };
      }
      if (selectedItems.length > 0) {
        requestBody = {
          ...requestBody,
          addParticipants: selectedItems,
        };
      }
      if (selectedOptionRepeat.value === "Custom") {
        requestBody = {
          ...requestBody,
          frequency: "Daily",
          repeatEvery: `${meetingInfo.repeatEveryNum} Days`,
        };
      }
      requestBody = {
        ...requestBody,
        loggedInUserId: loginUserId,
        meetingOwnerId: selectedOwner.userId,
        title: removeExtraSpaces(meetingInfo.title),
        makeThisOnline: meetingInfo.makeThisOnline,
        fromDate: moment(new Date(meetingInfo.fromDate)).format("DD-MM-YYYY"),
        toDate: moment(new Date(meetingInfo.toDate)).format("DD-MM-YYYY"),
        hostId: selectedOwner.userId,
        location: removeExtraSpaces(meetingInfo.location),
        choosingType: selectedOptionLead.value.toUpperCase(),
        description: removeExtraSpaces(meetingInfo.description),
        allDay: isAllDay ? "true" : "false",
        participantsRemainder: meetingInfo.participantsRemainder.value,
        remainder: meetingInfo.remainder.value,
        inviteByEmails: emailList.length > 0 ? emailList : [],
      };
      if (editPurchaseOverview) {
        requestBody.meetingId = meetingId;
        requestBody.makeThisOnline = false;
        requestBody.emailNotification = emailNotification;
        requestBody.deleteParticipants = deletedItems.filter(
          (item) => item.participantsId !== ""
        );
      }

      const url = editPurchaseOverview
        ? URI.updateMeetings
        : URI.insertMeetings;
      setCreateLoader(true);
      const headersResult = getHeaders(true);

      // Check if headers result indicates an error
      if (headersResult.error) {
        navigate("../"); // Redirect to login if token is missing
        return;
      }
      await axios
        .post(url, requestBody, { headers: headersResult })

        .then((response) => {
          if (response.data.status === 200) {
            NotificationManager.success("", response.data.message, 5000);
            setShowModalType("");
            if (e === "SAVE") {
              editPurchaseOverview
                ? meetingsOverview(meetingId)
                : getAllMeetings(selectedOption);
              setMeetingInfo(resetForm);
              setEmailList([]);
              setSelectedItems([]);
            }
          } else {
            NotificationManager.error("", response.data.message, 5000);
          }
        })
        .catch(function (error) {
          handleApiError(error, navigate);
        })
        .finally(() => {
          setCreateLoader(false);
          setOverlapModal(false);
        });
    }
  };

  const deleteUpdateTransferMass = async () => {
    const { value } = selectedObj;
    let url = URI.massDelete;
    const requestBody = {
      loggedInUserId: loginUserId,
      moduleName: "MEETINGS",
      massAction: modalType === "Mass Delete" ? "DELETE" : "EDIT",
      moduleIdList: selectedCheckboxes,
    };
    if (modalType === "Mass Update") {
      requestBody[value] = removeExtraSpaces(massUpdateValue);
      url = URI.massUpdate;
    } else if (modalType === "Mass Transfer") {
      requestBody.ownerId = selectedOwner.userId;
      url = URI.massTransfer;
    }
    const headersResult = getHeaders(true);

    // Check if headers result indicates an error
    if (headersResult.error) {
      navigate("../"); // Redirect to login if token is missing
      return;
    }
    await axios
      .post(url, requestBody, { headers: headersResult })
      .then((response) => {
        if (response.data.status === 200) {
          NotificationManager.success("", response.data.message, 5000);
          getAllMeetings(selectedOption);
          setSelectedCheckboxes([]);
          // setMeetView("list");
          setShowModalType("");
          setShowSearchFields(massColumn);
          setMassUpdateValue("");
        } else {
          NotificationManager.error("", response.data.message, 5000);
        }
      })
      .catch(function (error) {
        handleApiError(error, navigate);
      });
  };

  const meetingsOverview = async (meetingId) => {
    const requestBody = {
      loggedInUserId: loginUserId,
      meetingId: meetingId,
    };

    const headersResult = getHeaders(true);

    // Check if headers result indicates an error
    if (headersResult.error) {
      navigate("../"); // Redirect to login if token is missing
      return;
    }
    await axios
      .post(URI.meetingOverviewDetails, requestBody, {
        headers: headersResult,
      })
      .then((response) => {
        if (response.data.status === 200) {
          getAllTimeLineMeets(meetingId);
          setInnerPage("overview");
          setMeetingOverviewDetails(response.data.data.meetingInformation);
          setInfoDetails(response.data.data.infoDetails);
          setOpenActivities(response.data.data.openActivitiesInfo);
          setParticipantsDetails(response.data.data.participantsDetails);

          const [fromTime12Hour, fromMeridiem] =
            response.data.data.meetingInformation.fromTime.split(" ");
          const [fromHoursStr, fromMinutesStr] = fromTime12Hour.split(":");
          fromTimeSet.hour = fromHoursStr;
          fromTimeSet.minutes = fromMinutesStr;
          fromTimeSet.meridiem = fromMeridiem;

          const [toTime12Hour, toMeridiem] =
            response.data.data.meetingInformation.toTime.split(" ");
          const [toHoursStr, toMinutesStr] = toTime12Hour.split(":");
          toTimeSet.hour = toHoursStr;
          toTimeSet.minutes = toMinutesStr;
          toTimeSet.meridiem = toMeridiem;
        } else {
          NotificationManager.error("", response.data.message, 4000);
        }
      })
      .catch(async (error) => {
        handleApiError(error, navigate);
      });
  };

  const handleAddParticipantOverview = async (meetingId) => {
    const requestBody = {
      loggedInUserId: loginUserId,
      meetingId: meetingId,
      addParticipants: selectedItems,
      inviteByEmails: emailList.length > 0 ? emailList : [],
      deleteParticipants: deletedItems.filter(
        (item) => item.participantsId !== ""
      ),
    };
    setApiLoader(true);
    const headersResult = getHeaders(true);

    // Check if headers result indicates an error
    if (headersResult.error) {
      navigate("../"); // Redirect to login if token is missing
      return;
    }
    await axios
      .post(URI.addMeetingParticipants, requestBody, {
        headers: headersResult,
      })
      .then((response) => {
        if (response.data.status === 200) {
          NotificationManager.success("", response.data.message, 5000);
          meetingsOverview(meetingId);
        } else {
          NotificationManager.error("", response.data.message, 4000);
        }
      })
      .catch(async (error) => {
        handleApiError(error, navigate);
      })
      .finally(() => {
        setApiLoader(false);
        setShowModalSubType("");
        setAddButton(false);
        setEmailInput("");
        setSearchInput("");
      });
  };

  const hours = Array.from({ length: 12 }, (_, i) =>
    String(i + 1).padStart(2, "0")
  );
  const minutes = Array.from({ length: 12 }, (_, i) =>
    String(i * 5).padStart(2, "0")
  );

  const getAllTimeLineMeets = async (meetingId) => {
    const headersResult = getHeaders(true);

    // Check if headers result indicates an error
    if (headersResult.error) {
      navigate("../"); // Redirect to login if token is missing
      return;
    }
    await axios
      .get(URI.getMeetingsTimeline + "/" + loginUserId + "/" + meetingId, {
        headers: headersResult,
      })

      .then((response) => {
        if (response.data.status === 200) {
          setTimeLineDataList(response.data.data.getMeetingTimeline);
        }
      })
      .catch(async (error) => {
        handleApiError(error, navigate);
      });
  };

  const getAllLeadsDetails = async () => {
    const requestBody = {
      loggedInUserId: loginUserId,
      pageNo: 1,
      pageSize: 50,
    };
    const headersResult = getHeaders(true);

    // Check if headers result indicates an error
    if (headersResult.error) {
      navigate("../"); // Redirect to login if token is missing
      return;
    }
    await axios
      .post(URI.getAllLeads, requestBody, { headers: headersResult })

      .then((response) => {
        if (response.data.status === 200) {
          setLeadList(response.data.data.allLeadDetails);
        }
      })
      .catch(async (error) => {
        handleApiError(error, navigate);
      });
  };

  const getAllContact = async () => {
    const requestBody = {
      loggedInUserId: loginUserId,
      pageNo: 1,
      pageSize: 50,
    };
    const headersResult = getHeaders(true);

    // Check if headers result indicates an error
    if (headersResult.error) {
      navigate("../"); // Redirect to login if token is missing
      return;
    }
    await axios
      .post(URI.getAllContact, requestBody, { headers: headersResult })
      .then((response) => {
        if (response.data.status === 200) {
          setContactList(response.data.data.allContactDetails);
        }
      })
      .catch((err) => {
        handleApiError(err, navigate);
      });
  };

  const handleChangeMeetings = (e) => {
    const { name, value } = e.target;
    if (
      (name === "description" || name === "title" || name === "location") &&
      value.startsWith(" ")
    ) {
      return;
    }
    if (name === "fromDate" && meetingInfo.toDate < value) {
      setTimeout(() => {
        NotificationManager.error(
          "",
          "To Date must be greater than From date",
          3000
        );
      });
    }
    if (name === "toDate" && value < meetingInfo.fromDate) {
      setTimeout(() => {
        NotificationManager.error(
          "",
          "To Date must be greater than From date",
          3000
        );
      });
    }

    if (name === "afterTimes") {
      const regex = /^\d{0,10}$/;
      if (!regex.test(value)) {
        return;
      }
    }
    setMeetingInfo((prevCallsInfo) => ({
      ...prevCallsInfo,
      [name]: value,
    }));
  };

  const handleChangeOwnerInputClick = (e) => {
    e.stopPropagation();
    setIsChangeOwnerList(!isChangeOwnerList);
  };

  const handleChangeOwnerItemClick = (item) => {
    setSelectedOwner(item);
    setIsChangeOwnerList(false);
    // setIsOwnerSelect(true);
  };

  const handleCheckboxToggle = (itemId, itemType) => {
    const isSelected = selectedItems?.some(
      (item) => item.moduleId === itemId
      // && item.flagValue === itemType
    );

    if (isSelected) {
      if (overviewPage) {
        const selectItems = selectedItems.filter(
          (item) => item.moduleId === itemId
        )[0].moduleId;
        const participantsId = selectedParticipants.filter(
          (item) => item.moduleId === selectItems
        )[0].participantsId;
        setDeletedItems([...deletedItems, { participantsId: participantsId }]);
      }
      setSelectedItems(
        selectedItems.filter((item) => item.moduleId !== itemId)
      );
    } else {
      setSelectedItems([
        ...selectedItems,
        { moduleId: itemId, flagValue: itemType },
      ]);
    }
  };

  const handleRadioChange = (event) => {
    setSelectedOptionRadio(event.target.value);
  };

  const validateEmails = (email) => {
    // Regular expression for basic email validation
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  };

  const handleInputChange = (event) => {
    if (event.target.value.startsWith(" ")) {
      return;
    }
    setEmailInput(event.target.value);
  };

  const handleAddEmail = () => {
    const emails = emailInput.trim().split(",");
    if (!validateEmails(emails)) {
      return;
    } else {
      const uniqueEmails = emails.filter(
        (email) => !emailList.includes(email.trim())
      );
      setEmailList([...emailList, ...uniqueEmails]);
    }
    setEmailInput("");
  };

  const handleRemoveEmail = (indexToRemove) => {
    const updatedEmailList = emailList.filter(
      (email, index) => index !== indexToRemove
    );

    if (overviewPage) {
      const emailsDeselect = emailList.filter(
        (email, index) => index === indexToRemove
      );
      setDeletedItems([
        ...deletedItems,
        {
          participantsId: selectedEmailList?.filter(
            (item) => item.email === emailsDeselect[0]
          )[0]?.participantsId,
        },
      ]);
    }

    setEmailList(updatedEmailList);
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter" || event.key === ",") {
      handleAddEmail();
    }
  };

  const handleSearchInputChange = (event) => {
    const inputValue = event.target.value;
    if (inputValue.startsWith(" ")) {
      return;
    }
    setSearchInput(inputValue);

    let filteredList = [];
    if (addParticipant.value === "Users") {
      filteredList = userList.filter((item) =>
        item.name.toLowerCase().includes(inputValue.toLowerCase())
      );
    } else if (addParticipant.value === "Contacts") {
      filteredList = contactList.filter((item) =>
        item.contactName.toLowerCase().includes(inputValue.toLowerCase())
      );
    } else if (addParticipant.value === "Leads") {
      filteredList = leadList.filter((item) =>
        item.leadName.toLowerCase().includes(inputValue.toLowerCase())
      );
    }
    setFilteredItems(filteredList);
  };

  const handleDateTextClick = () => {
    setShowDateInput(true);
  };

  const handleDateInputChange = (event) => {
    setSelectedDate(event.target.value);
    setShowDateInput(false);
  };

  const formatDate = (date) => {
    const options = { year: "numeric", month: "short", day: "numeric" };
    return new Date(date).toLocaleDateString("en-US", options);
  };

  const handleCheckboxChange = () => {
    setIsAllDay(!isAllDay);
  };

  const handleChangeModule = (selectedOption) => {
    setSelectedOptionModule(selectedOption);
    getAllAssignModules(
      "CONTACTS",
      [selectedOption.value],
      selectedContact.contactId
    );
  };

  const handleModuleClick = (e) => {
    e.stopPropagation();
    setListVisible(false);
    setModuleListVisible(!moduleListVisible);
  };

  const handleModuleItemClick = (item) => {
    setSelectedModuleItem(item);
    setModuleListVisible(false);
  };

  const handleInputClick = (type) => {
    setListVisible(!listVisible);
    setListVisibleType(type);
    setModuleListVisible(false);
  };

  const handleColumnCheckboxChange = (name, isChecked) => {
    setSavingHeadings((prevHeadings) =>
      prevHeadings.map((heading) =>
        heading.name === name ? { ...heading, isChecked } : heading
      )
    );
  };

  const handlePageDropDowmChange = (selectedOption) => {
    setCurrentPage(1);
    setPerPage(parseInt(selectedOption.value));
  };

  const totalPages = Math.ceil(Number(totalItems) / perPage);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleChange = (selectedOption) => {
    setSelectedOption(selectedOption);
    getAllMeetings(selectedOption);
  };

  const handleChangeLead = (selectedOption) => {
    setSelectedOptionLead(selectedOption);
    if (selectedOption.value === "Contact") {
      getAllAssignModules(
        "CONTACTS",
        [selectedOptionModule.value],
        selectedContact.contactId
      );
    }
  };

  const handleAddParticipant = (addParticipant) => {
    setAddParticipant(addParticipant);
  };

  const handleChangeRepeat = (selectedOption) => {
    setSelectedOptionRepeat(selectedOption);
  };

  const handleChangeFrequency = (selectedOption) => {
    setSelectedOptionFrequency(selectedOption);
  };

  const handleSelect = (selectedOption) => {
    if (selectedOption === "ScheduleCall") {
      setShowModalType("ScheduleCall");
    }
  };

  const handleSubSelect = (selectedOption) => {
    if (selectedOption === "AddParticipants") {
      setShowModalSubType("AddParticipants");
    }
    if (selectedOption === "Reset") {
      setShowModalSubType("Reset");
    }
  };

  const getAllMeetings = async (selectedOption) => {
    setIsLoading(true);
    const requestBody = {
      customFilter: selectedOption.value,
      loggedInUserId: loginUserId,
      pageNo: currentPage,
      pageSize: perPage,
    };

    const headersResult = getHeaders(true);

    // Check if headers result indicates an error
    if (headersResult.error) {
      navigate("../"); // Redirect to login if token is missing
      return;
    }
    await axios
      .post(URI.getAllMeetings, requestBody, { headers: headersResult })

      .then((response) => {
        if (response.data.status === 200) {
          setAllMeetingDetails(response.data.data.allMeetingDetails);
          setFilteredMeetingDetails(response.data.data.allMeetingDetails);
          setTotalItems(response.data.data.rowCount);
          setIsLoading(false);
        } else {
          setIsLoading(false);
        }
      })
      .catch(async (error) => {
        handleApiError(error, navigate);
      });
  };

  const deleteMeetings = async () => {
    const requestBody = {
      loggedInUserId: loginUserId,
      meetingId: meetingId,
    };
    const headersResult = getHeaders(true);

    // Check if headers result indicates an error
    if (headersResult.error) {
      navigate("../"); // Redirect to login if token is missing
      return;
    }
    await axios
      .post(URI.deleteMeetings, requestBody, { headers: headersResult })
      .then((response) => {
        if (response.data.status === 200) {
          NotificationManager.success("", response.data.message, 5000);
          getAllMeetings(selectedOption);
          setInnerPage("list");
          setDeleteModal("");
        } else {
          NotificationManager.error("", response.data.message, 5000);
        }
      })
      .catch(function (error) {
        handleApiError(error, navigate);
      });
  };

  const handleSelectCheckboxChange = (event) => {
    const { value } = event.target;

    const selectedCheckbox = { moduleId: value };

    setSelectedCheckboxes((prevSelectedCheckboxes) => {
      const isAlreadySelected = prevSelectedCheckboxes.some(
        (checkbox) => checkbox.moduleId === value
      );

      if (isAlreadySelected) {
        return prevSelectedCheckboxes.filter(
          (checkbox) => checkbox.moduleId !== value
        );
      } else {
        return [...prevSelectedCheckboxes, selectedCheckbox];
      }
    });
  };

  const sortedColumnsData = (type, headingName) => {
    const sortedData = [...allMeetingDetails];

    if (type === "asc") {
      sortedData.sort((a, b) => {
        if (
          typeof a[headingName] === "string" &&
          typeof b[headingName] === "string"
        ) {
          return a[headingName].localeCompare(b[headingName]);
        }
        return a[headingName] - b[headingName];
      });
    } else if (type === "desc") {
      sortedData.sort((a, b) => {
        if (
          typeof a[headingName] === "string" &&
          typeof b[headingName] === "string"
        ) {
          return b[headingName].localeCompare(a[headingName]);
        }
        return b[headingName] - a[headingName];
      });
    }
    setFilteredMeetingDetails(sortedData);
  };

  const handlePinColumn = (name) => {
    setPinnedColumn(name);
    const updatedColumns = [...selectedHeadings];
    const clickedColumnIndex = updatedColumns.findIndex(
      (column) => column.name === name
    );

    if (clickedColumnIndex !== -1) {
      const clickedColumn = updatedColumns.splice(clickedColumnIndex, 1)[0];
      updatedColumns.unshift(clickedColumn);
      setSelectedHeadings(updatedColumns);
    }
  };

  const handleChangeSelectDropDown = (selectedOption, field) => {
    setMeetingInfo((prevLeadInfo) => ({
      ...prevLeadInfo,
      [field]: selectedOption,
    }));
  };

  const handleChangeNoteTitle = (e) => {
    const { name, value } = e.target;
    if (value.length === 1 && value[0] === ' ') {
      return; 
    }
    if (editNote) {
      setUpdateNotes((prevLeadInfo) => ({
        ...prevLeadInfo,
        [name]: value,
      }));
    } else {
      setAddTitle(value);
    }
  };

  const handleChildDataChange = (dataFromChild) => {
    const deletedImg = dataFromChild.toString("");
    setDeletedImg(deletedImg);
  };

  const getNotesDetails = async (meetingId, orderType) => {
    const requestBody = {
      loggedInUserId: loginUserId,
      moduleId: meetingId,
      orderType: orderType,
    };

    const headersResult = getHeaders(true);

    // Check if headers result indicates an error
    if (headersResult.error) {
      navigate("../"); // Redirect to login if token is missing
      return;
    }
    await axios
      .post(URI.getNote, requestBody, { headers: headersResult })
      .then((response) => {
        if (response.data.status === 200) {
          setNoteInfo(response.data.data.noteInfo);
        } else {
          NotificationManager.error("", response.data.message, 5000);
        }
      })
      .catch(async (error) => {
        handleApiError(error, navigate);
      });
  };

  const handleChangeNoteDescription = (e) => {
    const { name, value } = e.target;
    if (value.length === 1 && value[0] === ' ') {
      return; 
    }
    if (editNote) {
      setUpdateNotes((prevLeadInfo) => ({
        ...prevLeadInfo,
        [name]: value,
      }));
    } else {
      setNote(value); // Directly set the input value
    }
  };

  const updateNote = (noteId) => {
    const formData = new FormData();
    formData.append("moduleId", meetingId);
    formData.append("loggedInUserId", loginUserId);
    formData.append("moduleName", "CALLS");
    formData.append("noteDescription", updateNotes.description);
    formData.append("noteTitle", updateNotes.title);
    formData.append("noteId", noteId);
    formData.append("deletedImageNames", deletedImg.toString());

    selectedNoteFiles.forEach((file) => {
      formData.append("noteImages", file);
    });

    if (updateNotes.description.length > 1) {
      const headersResult = getHeaders(true);

      // Check if headers result indicates an error
      if (headersResult.error) {
        navigate("../"); // Redirect to login if token is missing
        return;
      }
      axios
        .post(URI.updateNote, formData, { headers: headersResult })
        .then((response) => {
          if (response.data.status === 200) {
            setShowButtons(false);
            setEditNote(false);
            NotificationManager.success("", response.data.message, 5000);
            setUpdateNotes({
              description: "",
              title: "",
              noteImageList: [],
            });
            setSelectedNoteFiles([]);
            getNotesDetails(meetingId, selectRecent);
            setDeletedImg("");
          } else {
            setDeletedImg("");
            setSelectedNoteFiles([]);
            NotificationManager.error("", response.data.message, 5000);
          }
        })
        .catch((error) => {
          handleApiError(error, navigate);
        });
    } else {
      NotificationManager.error("Empty", "Note can not be empty.", 5000);
    }
  };

  const deleteNoteDetails = (meetingId, noteId) => {
    const formData = new FormData();
    formData.append("moduleId", meetingId);
    formData.append("loggedInUserId", loginUserId);
    formData.append("moduleName", "MEETINGS");
    formData.append("noteId", noteId);
    const headersResult = getHeaders(true);

    // Check if headers result indicates an error
    if (headersResult.error) {
      navigate("../"); // Redirect to login if token is missing
      return;
    }
    axios
      .post(URI.deleteNote, formData, { headers: headersResult })
      .then((response) => {
        if (response.data.status === 200) {
          NotificationManager.success("", response.data.message, 3000);
          getNotesDetails(meetingId, selectRecent);
          setNoteDeleteConfirmation(false);
        } else {
          NotificationManager.error("", response.data.message, 5000);
        }
      })
      .catch((error) => {
        handleApiError(error, navigate);
      });
  };

  const handleSelectRecent = (item) => {
    setSelectRecent(item);
    var value = "";
    if (item === "Recent Last") {
      value = "RECENT_LAST";
    } else if (item === "Recent First") {
      value = "RECENT_FIRST";
    } else {
      value = item;
    }
    getNotesDetails(meetingId, value);
  };

  const handleAddTitleClick = () => {
    setShowTitleNote(true);
  };

  const insertNote = () => {
    const formData = new FormData();
    formData.append("moduleId", meetingId);
    formData.append("loggedInUserId", loginUserId);
    formData.append("moduleName", "MEETINGS");
    formData.append("noteDescription", note);
    formData.append("noteTitle", addTitle);

    selectedNoteFiles.forEach((file) => {
      formData.append("noteImages", file);
    });

    if (note.length > 1) {
      const headersResult = getHeaders(true);

      // Check if headers result indicates an error
      if (headersResult.error) {
        navigate("../"); // Redirect to login if token is missing
        return;
      }
      axios
        .post(URI.insertNote, formData, { headers: headersResult })
        .then((response) => {
          if (response.data.status === 200) {
            setShowButtons(false);
            setAddTitle("");
            setNote("");
            setSelectedNoteFiles([]);
            // getNotesDetails(newNoteId,leadId);
            // Pass newNoteId to fetchNoteImage
            getNotesDetails(meetingId, selectRecent);
          } else {
            NotificationManager.error("", response.data.message, 5000);
          }
        })
        .catch((error) => {
          handleApiError(error, navigate);
        });
    } else {
      NotificationManager.error("Empty", "Note can not be empty.", 5000);
    }
  };

  const hasPermission = (action) => {
    const permission = permissionList.calls.find((p) => p.action === action);
    return permission ? permission.status === "TRUE" : false;
  };

  const handleTextAreaClick = () => {
    if (hasPermission("CREATE")) {
      setShowButtons(true);
    } else {
      setShowAccessModal(true);
    }
  };

  const nodataPage = () => {
    return (
      <div className="d-flex flex-column justify-content-center align-items-center  ">
        <img src={noDataImage} alt="img" height={200} width={200} />
        <h5 className="opacity-75">Manage Meeting</h5>
        <p className="opacity-75 p-0 m-0">
          Meetings are legal agreements between a customer and a vector
        </p>
        <p className="opacity-75 p-0 m-0">
          to deliver the requested product(s) at the agreed upon price
        </p>
        <p className="opacity-75 p-0 m-0">within the specified time period.</p>
        <button
          className="createContactButtonSave px-2 mt-4 cursorPointer rounded-5"
          style={{ width: "11%" }}
          // onClick={() => setInnerPage("create")
          // }
          onClick={() => {
            handleSelect("ScheduleCall");
            setEditPurchaseOverview(false);
            setMeetingInfo(resetForm);
            setEmailList([]);
            setSelectedItems([]);
            setIsAllDay(false);
            setFromTimeSet({
              hour: "00",
              minutes: "00",
              meridiem: "AM",
            });
            setToTimeSet({
              hour: "00",
              minutes: "00",
              meridiem: "AM",
            });
          }}
        >
          Create a Meeting
        </button>
      </div>
    );
  };

  const handleInputClickSet = (item, value) => {
    value === "Owner"
      ? setSelectedOwner(item)
      : value === "Quotes"
      ? setSelectedQuote(item)
      : value === "Accounts"
      ? setSelectedAccount(item)
      : value === "Deals"
      ? setSelectedDeal(item)
      : value === "Contact"
      ? setSelectedContact(item)
      : value === "Lead"
      ? setSelectedLeadItem(item)
      : value === "Vendors"
      ? setSelectedVendorItem(item)
      : value === "Products"
      ? setSelectedProductItem(item)
      : value === "Invoices"
      ? setSelectedInvoiceItem(item)
      : value === "Sales Order"
      ? setSelectedSalesItem(item)
      : setSelectedPurchaseItem(item);

    if (value === "Contact") {
      getAllAssignModules(
        "CONTACTS",
        [selectedOptionModule.value],
        item.contactId
      );
    }

    if (value === "participantsRemainder") {
      setMeetingInfo((prevLeadInfo) => ({
        ...prevLeadInfo,
        participantsRemainder: item,
      }));
    }

    if (value === "remainder") {
      setMeetingInfo((prevLeadInfo) => ({
        ...prevLeadInfo,
        remainder: item,
      }));
    }

    // Purchase
    setListVisible(false);
  };

  const handleSelectedLeadChange = (value) => {
    setSelectedOwner(value); // Update the selected lead value
  };

  const handleSelectedContactListChange = (value) => {
    setSelectedContact(value); // Update the selected lead value
  };

  const handleSelectedLeadListChange = (value) => {
    setSelectedLeadItem(value);
  };

  const handleDeleteMeetings = () => {
    setDeleteModal("DeleteCalls"); // Update the selected lead value
  };

  const handleUpdate = () => {
    setShowModalType("ScheduleCall");
  };

  const meetingHomePage = () => {
    return (
      <div>
        {isLoading ? (
          <>
            <Loader />
          </>
        ) : filteredMeetingDetails &&
          filteredMeetingDetails.length === 0 &&
          selectedOption.value === "all_meetings" ? (
          <>{nodataPage()}</>
        ) : (
          <>
            <div className="creteLeadBar">
              {selectedCheckboxes.length > 0 ? (
                <div className="d-flex w-50 align-items-center gap-2">
                  <Select
                    options={massOptions}
                    styles={customCommonStyles("100%")}
                    value={{
                      value: "Action",
                      label: "Action",
                    }}
                    onChange={(option) => {
                      // setDropdownTwo(option);
                      if (option.value === "Mass Update") {
                        //   setShowSearchFields(meetingsColumn);
                      } else if (option.value === "Mass Transfer") {
                        setSelectedOwner(userList[0]);
                      }
                      setShowModalType(option.value);
                    }}
                    className="w-25"
                    isSearchable={false}
                  />
                </div>
              ) : (
                <div className="d-flex w-50 align-items-center gap-2">
                  <Select
                    options={options}
                    styles={customCommonStyles("195px")}
                    value={selectedOption}
                    onChange={handleChange}
                    isSearchable={false}
                  />
                </div>
              )}
              {selectedCheckboxes.length === 0 && (
                <div className="d-flex w-50 justify-content-end gap-2">
                  <div className="btn-group">
                    <button
                      type="button"
                      className="createContactButtonSave py-2"
                      // aria-expanded="false"
                      // style={{
                      //   marginRight: "2px",
                      //   background:
                      //     "linear-gradient(86.07deg, #407BFF 0%, #12A7FE 100%)",
                      //   color: "#fff",
                      // }}
                      onClick={() => {
                        handleSelect("ScheduleCall");
                        setEditPurchaseOverview(false);
                        setMeetingInfo(resetForm);
                        setEmailList([]);
                        setSelectedItems([]);
                        setIsAllDay(false);
                        setFromTimeSet({
                          hour: "00",
                          minutes: "00",
                          meridiem: "AM",
                        });
                        setToTimeSet({
                          hour: "00",
                          minutes: "00",
                          meridiem: "AM",
                        });
                      }}
                    >
                      Create Meeting
                    </button>

                    {/* <button
                      type="button"
                      className="btn btn-sm  dropdown-toggle dropdown-toggle-split py-2"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                      style={{
                        padding: "5px",
                        background:
                          "linear-gradient(86.07deg, #407BFF 0%, #12A7FE 100%)",
                        color: "#fff",
                      }}
                    >
                      <span className="visually-hidden"></span>
                    </button> */}
                    {/* <ul className="dropdown-menu">
                      <li>
                        <a className="dropdown-item ">Import Call</a>
                      </li>
                      <li>
                        <a className="dropdown-item ">Import Notes</a>
                      </li>
                    </ul> */}
                  </div>
                </div>
              )}
            </div>
            <div className="totalRecordBar">
              <div className="d-flex w-50">
                <h6>
                  {" "}
                  {selectedCheckboxes.length > 0
                    ? `${selectedCheckboxes.length} Records Selected`
                    : `Total Records : ${totalItems}`}{" "}
                </h6>
              </div>
              <div className="d-flex w-50 align-items-center justify-content-end gap-2">
                {/* <p className="mb-0">· ‹1/10›</p> */}
                {selectedCheckboxes.length === 0 && (
                  <Select
                    options={pageOption}
                    styles={customCommonStyles("100%")}
                    isSearchable={false}
                    value={pageOption.find(
                      (option) => option.value === perPage.toString()
                    )}
                    onChange={handlePageDropDowmChange}
                  />
                )}
                <Pagination
                  currentPage={currentPage}
                  totalPages={totalPages}
                  onPageChange={handlePageChange}
                />
              </div>
            </div>
            <div className="leadMain">
              {/* {filterView ? (
                <div className="filterLead">
                  <h6 style={{ borderBottom: "2px solid #316AFF" }}>
                    Filter by Sales
                  </h6>
                  <input
                    type="text"
                    placeholder="Search"
                    className="filterSearch"
                  />
                  <div className="checkboxItem">
                    <h6 style={{ color: "#515151" }}>
                      ▾ System Defined Filters
                    </h6>
                    <input
                      type="checkbox"
                      id="vehicle1"
                      name="vehicle1"
                      value="Bike"
                    />
                    <label htmlFor="vehicle1"> Touched Record</label>
                    <br />
                    <input
                      type="checkbox"
                      id="vehicle1"
                      name="vehicle1"
                      value="Bike"
                    />
                    <label htmlFor="vehicle1"> Untouched Record</label>
                    <br />
                    <input
                      type="checkbox"
                      id="vehicle1"
                      name="vehicle1"
                      value="Bike"
                    />
                    <label htmlFor="vehicle1"> Record Action</label>
                    <br />
                    <h6 style={{ color: "#515151", marginTop: "10px" }}>
                      ▾ Filter By Fields
                    </h6>
                    <input
                      type="checkbox"
                      id="vehicle1"
                      name="vehicle1"
                      value="Bike"
                    />
                    <label htmlFor="vehicle1"> Annual Revenue</label>
                    <br />
                    <input
                      type="checkbox"
                      id="vehicle1"
                      name="vehicle1"
                      value="Bike"
                    />
                    <label htmlFor="vehicle1"> City</label>
                    <br />
                    <input
                      type="checkbox"
                      id="vehicle1"
                      name="vehicle1"
                      value="Bike"
                    />
                    <label htmlFor="vehicle1"> Company</label>
                    <br />
                  </div>
                </div>
              ) : (
                <></>
              )} */}
              <div
                className={
                  filterIcon ? "leadListTableFullWidth" : "leadListTable"
                }
              >
                <table className="table leadTable">
                  <thead>
                    <tr>
                      <th scope="col">
                        <input
                          className="cursorPointer"
                          type="checkbox"
                          id="all"
                          name="all"
                          value="All"
                          checked={filteredMeetingDetails.every((selected) =>
                            selectedCheckboxes.some(
                              (list) => list.moduleId === selected.meetingId
                            )
                          )}
                          onChange={(e) => {
                            const allList = filteredMeetingDetails.map(
                              (item) => ({
                                moduleId: item.meetingId,
                              })
                            );
                            if (e.target.checked) {
                              const newSelections = allList.filter(
                                (newItem) =>
                                  !selectedCheckboxes.some(
                                    (existingItem) =>
                                      existingItem.moduleId === newItem.moduleId
                                  )
                              );
                              setSelectedCheckboxes((prev) => [
                                ...prev,
                                ...newSelections,
                              ]);
                            } else {
                              setSelectedCheckboxes(
                                selectedCheckboxes.filter(
                                  (aItem) =>
                                    !allList.some(
                                      (bItem) =>
                                        aItem.moduleId === bItem.moduleId
                                    )
                                )
                              );
                            }
                          }}
                          // checked={
                          //   selectedCheckboxes.length ===
                          //     filteredMeetingDetails.length &&
                          //   filteredMeetingDetails.length > 0
                          // }
                          // onChange={(e) => {
                          //   if (e.target.checked) {
                          //     const allList = filteredMeetingDetails.map(
                          //       (item) => ({
                          //         moduleId: item.meetingId,
                          //       })
                          //     );
                          //     setSelectedCheckboxes(allList);
                          //   } else {
                          //     setSelectedCheckboxes([]);
                          //   }
                          // }}
                        />
                      </th>
                      {selectedHeadings.map(
                        (heading) =>
                          heading.isChecked && (
                            <th
                              scope="col"
                              key={heading.name}
                              className="position-relative"
                            >
                              <span className="pe-3">{heading.name}</span>
                              <span className="">
                                <img
                                  src={tableFilterIcon}
                                  alt=""
                                  className="cursorPointer"
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    setShowHeadings(false);
                                    setShowSelectedFilter(heading.name);
                                  }}
                                />
                                {heading.name === showSelectedFilter && (
                                  <span
                                    className="position-absolute rounded-2 border bg-light mt-4"
                                    style={{ left: "-10%", top: "0.8rem" }}
                                  >
                                    <span className="d-flex flex-column px-1">
                                      {/* Ascending filter */}
                                      <span
                                        className="my-1 px-1 cursorPointer fw-normal text-start filter_type"
                                        onClick={() => {
                                          if (filterType === "asc") {
                                            setFilterType("");
                                            setFilteredMeetingDetails(
                                              allMeetingDetails
                                            );
                                          } else {
                                            setFilterType("asc");
                                            sortedColumnsData(
                                              "asc",
                                              heading.value
                                            );
                                          }
                                        }}
                                      >
                                        {filterType === "asc" ? (
                                          <img
                                            src={cancel}
                                            alt="asc"
                                            className="opacity-75 pe-1"
                                          />
                                        ) : (
                                          <img
                                            src={arrowUp}
                                            alt="asc"
                                            className="opacity-75 pe-1"
                                          />
                                        )}
                                        Asc
                                      </span>
                                      {/* Descending filter */}
                                      <span
                                        className="my-2 px-1 cursorPointer fw-normal text-start filter_type"
                                        onClick={() => {
                                          if (filterType === "desc") {
                                            setFilterType("");
                                            setFilteredMeetingDetails(
                                              allMeetingDetails
                                            );
                                          } else {
                                            setFilterType("desc");
                                            sortedColumnsData(
                                              "desc",
                                              heading.value
                                            );
                                          }
                                        }}
                                      >
                                        {filterType === "desc" ? (
                                          <img
                                            src={cancel}
                                            alt="desc"
                                            className="opacity-75 pe-1"
                                          />
                                        ) : (
                                          <img
                                            src={arrowDown}
                                            alt="desc"
                                            className="opacity-75 pe-1"
                                          />
                                        )}
                                        Desc
                                      </span>
                                      <span
                                        className={`my-1 px-1 fw-normal text-start filter_type ${
                                          selectedHeadings[0].name ===
                                            "Title" && heading.name === "Title"
                                            ? "opacity-50"
                                            : "cursorPointer"
                                        }`}
                                        onClick={() => {
                                          if (pinnedColumn !== "Title") {
                                            setPinnedColumn("Title");
                                            handlePinColumn("Title");
                                          } else {
                                            setPinnedColumn(heading.name);
                                            handlePinColumn(heading.name);
                                          }
                                        }}
                                      >
                                        <img
                                          src={
                                            pinnedColumn === heading.name
                                              ? unPin
                                              : pin
                                          }
                                          alt="pin"
                                          className="opacity-75 pe-1"
                                        />{" "}
                                        {pinnedColumn === heading.name
                                          ? "Unpin"
                                          : "Pin"}{" "}
                                        Column
                                      </span>
                                    </span>
                                  </span>
                                )}
                                <img src={filterLine} alt="" className="ps-2" />
                              </span>
                            </th>
                          )
                      )}

                      <th
                        scope="col"
                        className="text-end cursorPointer position-relative"
                      >
                        <img
                          src={filterMenu}
                          alt=""
                          className={`filter_menu ${
                            showHeadings ? "bg-light" : ""
                          }`}
                          onClick={(e) => {
                            e.stopPropagation();
                            setShowSelectedFilter("");
                            setShowHeadings(true);
                          }}
                        />
                        {showHeadings && (
                          <div
                            className="position-absolute rounded-2  border border-primary bg-light"
                            style={{
                              right: "0.2rem",
                            }}
                          >
                            <div className="d-flex flex-column px-2">
                              <span
                                className="p-2 cursorPointer fw-normal text-start"
                                onClick={() => {
                                  setShowModalType("Manage Columns");
                                  setSavingHeadings(selectedHeadings);
                                  setSearchColumn("");
                                }}
                              >
                                Manage Columns
                              </span>
                              <span
                                className="p-2 fw-normal text-start opacity-50"
                                onClick={() => {
                                  setShowModalType("Reset Column Size");
                                }}
                              >
                                Reset Column Size
                              </span>
                            </div>
                          </div>
                        )}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {filteredMeetingDetails &&
                    filteredMeetingDetails.length > 0 ? (
                      filteredMeetingDetails.map((item, index) => {
                        return (
                          <tr
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              // getContactOverview(item.contactId);
                            }}
                            key={index}
                          >
                            <th scope="col">
                              <input
                                className="cursorPointer"
                                type="checkbox"
                                id="vehicle1"
                                name="vehicle1"
                                value={item.meetingId}
                                checked={selectedCheckboxes.some(
                                  (checkbox) =>
                                    checkbox.moduleId === item.meetingId
                                )}
                                onChange={handleSelectCheckboxChange}
                              />
                            </th>

                            <React.Fragment>
                              {selectedHeadings.map((obj, i) => {
                                const value = obj.value;
                                const itemValue = item[value];

                                return obj.isChecked ? (
                                  value === "transHash" ? (
                                    <td
                                      style={{ wordBreak: "break-all" }}
                                      className="overflow_text_hash"
                                    >
                                      <a
                                        rel="noreferrer"
                                        href={
                                          EXPLORER_URL +
                                          "about-tx-hash/" +
                                          item.transHash
                                        }
                                        className="anchorLinkHash textColor"
                                        data-title={item.transHash}
                                        target="_blank"
                                      >
                                        {item.transHash
                                          ? item.transHash.slice(0, 10) + "...."
                                          : "-"}
                                      </a>
                                    </td>
                                  ) : (
                                    <td
                                      key={i}
                                      onClick={() => {
                                        if (value === "title") {
                                          setMeetingId(item.meetingId);
                                          // setPurchaseOrdId(item.meetingId);
                                          meetingsOverview(item.meetingId);
                                          setSelectedMeetingId(
                                            allMeetingDetails?.findIndex(
                                              (contact) =>
                                                contact.meetingId ===
                                                item.meetingId
                                            )
                                          );
                                          setOverviewPage(true);
                                        }
                                      }}
                                      className={` ${
                                        value === "title"
                                          ? "leadName cursorPointer"
                                          : ""
                                      }`}
                                    >
                                      {itemValue !== null ? itemValue : "-"}
                                    </td>
                                  )
                                ) : null;
                              })}
                            </React.Fragment>
                            <td></td>
                          </tr>
                        );
                      })
                    ) : (
                      <tr>
                        <td
                          colSpan={selectedHeadings.length + 2}
                          style={{ textAlign: "center" }}
                        >
                          No Data
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </>
        )}
      </div>
    );
  };

  useEffect(() => {
    getAllMeetings(selectedOption);
  }, [currentPage, perPage]);

  useEffect(() => {
    const foundItem = userList?.find((item) => item.userId === loginUserId);
    if (foundItem) {
      setSelectedOwner(foundItem);
    }
  }, [loginUserId]);

  useEffect(() => {
    // setShowOptionsContact(false);
    setSelectedDate(new Date().toISOString().substr(0, 10));
  }, []);

  useEffect(() => {
    setInnerPage(innerPage || "list");
    // setShowOptionsContact(false);
    getAllContact();
    getAllLeadsDetails();
  }, []);

  useEffect(() => {
    // Initialize filteredItems with the entire list when the component mounts
    if (addParticipant.value === "Users") {
      setFilteredItems(userList);
    } else if (addParticipant.value === "Contacts") {
      setFilteredItems(contactList);
    } else if (addParticipant.value === "Leads") {
      setFilteredItems(leadList);
    }
  }, [addParticipant.value]);

  useEffect(() => {
    if (contactList.length > 0) {
      setSelectedContact(contactList[0]);
    }
  }, [contactList]);

  useEffect(() => {
    if (leadList.length > 0) {
      setSelectedLeadItem(leadList[0]);
    }
  }, [leadList]);

  useEffect(() => {
    if (moduleWiseData.length > 0) {
      setSelectedModuleItem(moduleWiseData[0]);
    }
  }, [moduleWiseData]);

  const handleMeetingView = () => {
    if (innerPage === "list") {
      return <>{meetingHomePage()}</>;
    } else if (innerPage === "overview") {
      return <>{overviewPageMeet()}</>;
    }
  };

  const handleDoneClick = () => {
    if (meetingInfo.afterTimes === "0" || meetingInfo.afterTimes === "") {
      setMeetingInfo({ ...meetingInfo, afterTimes: "1" });
    }
    // Proceed with other actions on "Done" click
  };

  const overviewPageMeet = () => {
    return (
      <div onClick={() => setIsDropdownOpenEdit(false)}>
        <div className="indiv_main_container">
          <div className="individual_header_container commonContainer_bg w-100  flex-column  d-flex justify-content-between mb-4">
            <div className="iconContainer d-flex justify-content-between px-2 pt-2">
              <span className="d-flex pt-5">
                <span
                  className="cursorPointer"
                  onClick={() => {
                    setInnerPage("list");
                    setEditPurchaseOverview(false);
                    setOverviewPage(false);
                  }}
                >
                  {leftArrow}
                </span>
                <p className="ps-3 CallsHeading">
                  {meetingOverviewDetails.title} -
                </p>
                <p
                  className="ps-3 commonTextColor CallsHeading"
                  style={{ color: "rgba(81, 81, 81, 1)" }}
                >
                  {meetingOverviewDetails.meetingOwner}
                </p>
              </span>
              <div className="pt-5 d-flex ps-4 pb-3">
                <div className="pe-4" style={{ alignContent: "space-evenly" }}>
                  <span
                    className={`me-2 ${
                      selectedMeetingId === 0 ? "opacity-50" : "cursorPointer"
                    }`}
                    onClick={() => {
                      const leadIndex = allMeetingDetails.findIndex(
                        (contact) => contact.meetingId === meetingId
                      );
                      if (leadIndex !== 0) {
                        setSelectedMeetingId(leadIndex - 1);
                        setMeetingId(
                          allMeetingDetails[leadIndex - 1].meetingId
                        );
                        meetingsOverview(
                          allMeetingDetails[leadIndex - 1].meetingId
                        );
                        // getNotesDetails(
                        //   meetingOverviewDetails[leadIndex - 1].purchaseOrdId,
                        //   selectRecent
                        // );
                      }
                    }}
                  >
                    {leftAngleArrow}
                  </span>
                  <span
                    className={`ms-1 ${
                      selectedMeetingId === allMeetingDetails.length - 1
                        ? "opacity-50"
                        : "cursorPointer"
                    }`}
                    onClick={() => {
                      const leadIndex = allMeetingDetails.findIndex(
                        (lead) => lead.meetingId === meetingId
                      );
                      if (leadIndex < allMeetingDetails.length - 1) {
                        setSelectedMeetingId(leadIndex + 1);
                        setMeetingId(
                          allMeetingDetails[leadIndex + 1].meetingId
                        );
                        meetingsOverview(
                          allMeetingDetails[leadIndex + 1].meetingId
                        );
                        // getNotesDetails(
                        //   meetingOverviewDetails[leadIndex + 1].purchaseOrdId,
                        //   selectRecent
                        // );
                      }
                    }}
                  >
                    {rightAngleArrow}
                  </span>
                </div>
                <div className="d-flex gap-1">
                  {/* <button className="btn  btnColor text-white">
                Send Email
              </button> */}
                  <button
                    className="btn  btnColor text-white"
                    onClick={() => {
                      setEditPurchaseOverview(true);
                      setMeetingInfo({
                        ...meetingOverviewDetails,
                        fromDate: moment(
                          meetingOverviewDetails.fromDate,
                          "DD-MM-YYYY"
                        ).format("YYYY-MM-DD"),
                        toDate: moment(
                          meetingOverviewDetails.toDate,
                          "DD-MM-YYYY"
                        ).format("YYYY-MM-DD"),
                        participantsRemainder: {
                          label: meetingOverviewDetails.participantsRemainder
                            ? meetingOverviewDetails.participantsRemainder
                            : reminderOptions[0].label,
                          value: meetingOverviewDetails.participantsRemainder
                            ? meetingOverviewDetails.participantsRemainder
                            : reminderOptions[0].value,
                        },
                        remainder: {
                          label: meetingOverviewDetails.remainder
                            ? meetingOverviewDetails.remainder
                            : reminderOptions[0].label,
                          value: meetingOverviewDetails.remainder
                            ? meetingOverviewDetails.remainder
                            : reminderOptions[0].value,
                        },
                      });
                      setSelectedItems(
                        participantsDetails
                          .filter((item) => {
                            return item.moduleId !== null;
                          })
                          .map((item) => ({
                            flagValue: item.flagValue + "S",
                            moduleId: item.moduleId,
                            participantsId: item.participantId,
                          }))
                      );
                      setSelectedParticipants(
                        participantsDetails
                          .filter((item) => {
                            return item.moduleId !== null;
                          })
                          .map((item) => ({
                            participantsId: item.participantId,
                            moduleId: item.moduleId,
                          }))
                      );
                      setEmailList(
                        participantsDetails
                          .filter((item) => {
                            return item.flagValue === "INVITED";
                          })
                          .map((item) => item.email)
                      );
                      setSelectedEmailList(
                        participantsDetails
                          .filter((item) => {
                            return item.flagValue === "INVITED";
                          })
                          .map((item) => ({
                            participantsId: item.participantId,
                            email: item.email,
                          }))
                      );
                      setIsAllDay(meetingOverviewDetails.allDay);
                      handleUpdate();
                    }}
                  >
                    Edit
                  </button>
                  <div className="position-relative">
                    <button
                      className="btn btn-secondary bg-white text-dark border border-primary ms-2"
                      type="button"
                      data-bs-toggle="dropdown"
                      aria-expanded={isDropdownOpenEdit ? "true" : "false"}
                      onClick={(e) => {
                        e.stopPropagation();
                        setIsDropdownOpenEdit(!isDropdownOpenEdit);
                      }}
                    >
                      ---
                    </button>
                    <ul
                      className={`dropdown-menu ${
                        isDropdownOpenEdit ? "show" : ""
                      }`}
                    >
                      <li
                        className="dropdown-item cursorPointer"
                        onClick={() => {
                          setIsDropdownOpenEdit(false);
                          setMeetingInfo({
                            ...meetingOverviewDetails,
                            fromDate: moment(
                              meetingOverviewDetails.fromDate,
                              "DD-MM-YYYY"
                            ).format("YYYY-MM-DD"),
                            toDate: moment(
                              meetingOverviewDetails.toDate,
                              "DD-MM-YYYY"
                            ).format("YYYY-MM-DD"),
                            participantsRemainder: {
                              label:
                                meetingOverviewDetails.participantsRemainder
                                  ? meetingOverviewDetails.participantsRemainder
                                  : reminderOptions[0].label,
                              value:
                                meetingOverviewDetails.participantsRemainder
                                  ? meetingOverviewDetails.participantsRemainder
                                  : reminderOptions[0].value,
                            },
                            remainder: {
                              label: meetingOverviewDetails.remainder
                                ? meetingOverviewDetails.remainder
                                : reminderOptions[0].label,
                              value: meetingOverviewDetails.remainder
                                ? meetingOverviewDetails.remainder
                                : reminderOptions[0].value,
                            },
                          });
                          setSelectedItems(
                            participantsDetails
                              .filter((item) => {
                                return item.moduleId !== null;
                              })
                              .map((item) => ({
                                flagValue: item.flagValue + "S",
                                moduleId: item.moduleId,
                                participantsId: item.participantId,
                              }))
                          );
                          setSelectedParticipants(
                            participantsDetails
                              .filter((item) => {
                                return item.moduleId !== null;
                              })
                              .map((item) => ({
                                participantsId: item.participantId,
                                moduleId: item.moduleId,
                              }))
                          );
                          setEmailList(
                            participantsDetails
                              .filter((item) => {
                                return item.flagValue === "INVITED";
                              })
                              .map((item) => item.email)
                          );
                          setSelectedEmailList(
                            participantsDetails
                              .filter((item) => {
                                return item.flagValue === "INVITED";
                              })
                              .map((item) => ({
                                participantsId: item.participantId,
                                email: item.email,
                              }))
                          );
                          setEditPurchaseOverview(false);
                          handleSelect("ScheduleCall");
                          setEditPurchaseOverview(false);
                          // handleInsertMeetings();
                        }}
                      >
                        Clone
                      </li>
                      <li
                        className="dropdown-item cursorPointer"
                        onClick={() => {
                          setIsDropdownOpenEdit(false);
                          handleDeleteMeetings();
                        }}
                      >
                        Delete
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="indiv_main_container ">
          <div className="individual_header_container commonContainer_bg w-100 d-flex justify-content-between">
            <div
              className="lead_Information_mainContainer rounded-2  d-flex flex-column mx-3 my-4"
              style={{ backgroundColor: "transparent", width: "35%" }}
            >
              <div className="leadInformation_content_container justify-content-between ">
                <div
                  // className="timeline_main_container p-3 rounded-2 bg-white  d-flex flex-column gap-4 w-75 "
                  className={`timeline_main_container rounded-2 bg-white d-flex flex-column gap-4`}
                >
                  <div
                    className={`w-100 relatedListContainer p-3 rounded bg-white list-container`}
                    style={{
                      overflowY: "scroll",
                      height: "50vw",
                      overflowX: "hidden",
                      marginLeft: "0px",
                    }}
                  >
                    <h5 className="w-100 px-2 pt-2 mb-0 fw-medium">
                      All Meetings
                    </h5>
                    <div
                      className="mx-1"
                      style={{ border: "1px solid rgba(226, 229, 235, 1)" }}
                    ></div>
                    <ul
                      className="relateList_container p-0"
                      style={{
                        border: "1px solid rgba(226, 229, 235, 1)",
                        borderRadius: "8px",
                      }}
                    >
                      {allMeetingDetails.map((item) => {
                        const isActive = item.meetingId === activeMeetingId;

                        return (
                          <li
                            key={item.meetingId} // Ensure each li has a unique key for React
                            className={`cursorPointer relatedList_tab ${
                              isActive ? "active" : ""
                            }`}
                            style={{
                              alignItems: "center",
                              borderBottom: "1px solid #E2E5EB",
                              padding: "17.5px 0px 17.5px 5px",
                              color: isActive ? "#666666" : "",
                              backgroundColor: isActive
                                ? "#cce5ff"
                                : "transparent",
                            }}
                            onClick={() => {
                              meetingsOverview(item.meetingId);
                              setActiveMeetingId(item.meetingId); // Set active meetingId on click
                              setMeetingId(item.meetingId);
                            }}
                            onMouseEnter={(e) => {
                              if (!isActive) {
                                e.currentTarget.style.backgroundColor =
                                  "#e7eeff"; // Change background on hover
                              }
                            }}
                            onMouseLeave={(e) => {
                              if (!isActive) {
                                e.currentTarget.style.backgroundColor =
                                  "transparent"; // Reset background on mouse leave if not active
                              } else if (isActive) {
                                e.currentTarget.style.backgroundColor =
                                  "#cce5ff"; // Reset background on mouse leave if not active
                              }
                            }}
                          >
                            {item.callType === "Outbound" ? (
                              <span className="p-0">
                                {/* <img src={callIconSuccess} alt="/" width={25} /> */}
                              </span>
                            ) : (
                              <span className="p-0">
                                {/* <img
                            src={callIconIncoming}
                            alt="/"
                            width={25}
                          /> */}
                              </span>
                            )}
                            {/* Display the modifiedBy or callToName or callOwner */}
                            {item?.title || "New Meeting"}
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="lead_Information_mainContainer rounded-2  d-flex flex-column mx-3 my-4"
              style={{ backgroundColor: "transparent", width: "37%" }}
            >
              <div className="leadInformation_content_container justify-content-between ">
                <div
                  // className="timeline_main_container p-3 rounded-2 bg-white  d-flex flex-column gap-4 w-75 "
                  className={`timeline_main_container p-3 rounded-2 bg-white  d-flex flex-column gap-3`}
                >
                  <div
                    className="d-flex  gap-4 justify-content-between px-2 bg-white rounded-5"
                    style={{
                      border: "0.95px solid rgba(231, 233, 238, 1)",
                      alignSelf: "flex-end",
                      padding: "7.5px 5px 7.5px 5px",
                    }}
                  >
                    <span
                      className={`cursorPointer callsTimeline ${
                        activeTab === "Info" ? "overviewTimelineTab" : ""
                      }`}
                      onClick={() => {
                        handleSelectActiveTab("Info");
                      }}
                    >
                      Info
                    </span>

                    <span
                      className={`cursorPointer callsTimeline ${
                        activeTab === "Timeline" ? "overviewTimelineTab" : ""
                      }`}
                      onClick={() => {
                        handleSelectActiveTab("Timeline");
                      }}
                    >
                      Timeline
                    </span>
                  </div>
                  <div
                    className="mx-1"
                    style={{ border: "1px solid rgba(226, 229, 235, 1)" }}
                  ></div>
                  <div
                    className="gap-4 justify-content-between px-2 bg-white px-1 rounded-3 py-1"
                    style={{ border: "0.95px solid rgba(231, 233, 238, 1)" }}
                  >
                    {activeTab === "Info" && (
                      <div className="historyContent_mainContainer p-3">
                        <div
                          className="d-flex w-100"
                          style={{ borderBottom: "1px solid #E2E5EB" }}
                        >
                          <div className="p-0">
                            <img
                              src={
                                infoDetails.meetingImageData
                                  ? infoDetails.meetingImageData
                                  : randomImage
                              }
                              alt="/"
                              width={50}
                            />
                          </div>
                          <div>
                            <p
                              className="ms-4 infoCall"
                              style={{
                                alignSelf: "self-end",
                                marginBottom: "5px",
                              }}
                            >
                              {infoDetails.relatedName && (
                                <>
                                  {infoDetails?.relatedName}(
                                  {infoDetails?.relatedModuleName})
                                </>
                              )}
                            </p>
                            <p
                              className="ms-4 infoCall"
                              style={{
                                alignSelf: "self-end",
                                fontSize: "14px",
                              }}
                            >
                              {infoDetails?.mobile !== ""
                                ? infoDetails?.mobile
                                : "-"}
                            </p>
                          </div>
                        </div>{" "}
                        <div
                          className="d-flex flex-column w-100"
                          style={{ borderBottom: "1px solid #E2E5EB" }}
                        >
                          <div className="pt-2 pb-0 d-flex">
                            <span>
                              <img
                                src={AccountsInfoContainer}
                                alt="/"
                                width={25}
                              />
                            </span>
                            <p className="mb-0 ms-1 infoTextCall">
                              {infoDetails?.leadName !== ""
                                ? infoDetails?.leadName
                                : "-"}
                            </p>
                          </div>
                          <div className="pt-1 pb-0 d-flex">
                            <span>
                              <img src={telephoneInfo} alt="/" width={25} />
                            </span>
                            <p className="mb-0 ms-1 infoTextCall">
                              {infoDetails?.phone !== ""
                                ? infoDetails?.phone
                                : "-"}
                            </p>
                          </div>
                          <div className="pt-1 pb-0 d-flex">
                            <span>
                              <img src={callInfoContainer} alt="/" width={25} />
                            </span>
                            <p className="mb-0 ms-1 infoTextCall">
                              {infoDetails?.mobile !== ""
                                ? infoDetails?.mobile
                                : "-"}
                            </p>
                          </div>
                          <div className="pt-1 pb-1 d-flex">
                            <span>
                              <img src={mailInfo} alt="/" width={25} />
                            </span>
                            <p className="mb-0 ms-1 infoTextCall">
                              {infoDetails?.email !== ""
                                ? infoDetails?.email
                                : "-"}
                            </p>
                          </div>
                        </div>
                        <div className="d-flex w-100">
                          <div className="w-100">
                            <p
                              className="ms-0 infoCall mt-3"
                              style={{
                                alignSelf: "self-end",
                                marginBottom: "15px",
                                fontSize: "20px",
                              }}
                            >
                              Open Activities
                            </p>
                            <div>
                              {openActivities?.map((item, index) => {
                                return (
                                  <div
                                    className="ps-2 mb-2 rounded-2 pt-2 pb-2"
                                    style={{
                                      backgroundColor: "#2177FE1A",
                                      color: "#9C9C9C",
                                      fontWeight: "300",
                                      fontSize: "16px",
                                    }}
                                    key={index}
                                  >
                                    {item.openActivitiesCount}
                                  </div>
                                );
                              })}
                            </div>
                          </div>
                        </div>{" "}
                      </div>
                    )}
                    {activeTab === "Timeline" && (
                      <div className="historyContent_mainContainer ">
                        {timeLineDataList &&
                        Object.keys(timeLineDataList).length > 0 ? (
                          Object.keys(timeLineDataList).map((date) => (
                            <div
                              className="history_container d-flex flex-column"
                              key={date}
                            >
                              {timeLineDataList[date].map((item, index) => (
                                <>
                                  <div
                                    className="d-flex gap-2"
                                    key={index}
                                    style={{
                                      alignItems: "center",
                                      borderBottom: "1px solid #E2E5EB",
                                    }}
                                  >
                                    <span className="pt-2 w-100">
                                      {`${item.tmlDescription}`}
                                      <span
                                        style={{
                                          color: "rgba(33, 119, 254, 1)",
                                          display: "contents",
                                          width: "100%",
                                        }}
                                      >{` ${item.tmlCreatedBy}`}</span>
                                      <span>{`${date}`}</span>
                                    </span>
                                  </div>
                                </>
                              ))}
                            </div>
                          ))
                        ) : (
                          <div
                            className="text-center d-flex justify-content-center align-items-center opacity-75"
                            style={{ height: "20vh" }}
                          >
                            <h4 className="h-75">No Data available</h4>
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="w-75 rounded-2 px-3 py-4 leadDetail_main_Container ">
              <div className="bg-white common_bg p-3 rounded-2">
                <div className="lead_Information_mainContainer commonContainer_bg rounded-2  d-flex flex-column my-2">
                  <div className="leadInformation_content_container d-flex justify-content-between ">
                    <div className="leadContact_container d-flex gap-4">
                      <div className="d-flex">
                        <div className="leadContact_container d-flex gap-4">
                          <div className="d-flex flex-column gap-2 px-3 py-3">
                            {meetingOverviewDetails.fromDate && (
                              <div className="meetingsContainer">
                                <span>
                                  <img
                                    src={CalenderMeetings}
                                    alt="/"
                                    width={22}
                                  />
                                </span>
                                <span>{meetingOverviewDetails?.fromDate}</span>
                                <span
                                  onClick={() => {
                                    setEditPurchaseOverview(true);
                                    setMeetingInfo({
                                      ...meetingOverviewDetails,
                                      fromDate: moment(
                                        meetingOverviewDetails.fromDate,
                                        "DD-MM-YYYY"
                                      ).format("YYYY-MM-DD"),
                                      toDate: moment(
                                        meetingOverviewDetails.toDate,
                                        "DD-MM-YYYY"
                                      ).format("YYYY-MM-DD"),
                                      participantsRemainder: {
                                        label:
                                          meetingOverviewDetails.participantsRemainder
                                            ? meetingOverviewDetails.participantsRemainder
                                            : reminderOptions[0].label,
                                        value:
                                          meetingOverviewDetails.participantsRemainder
                                            ? meetingOverviewDetails.participantsRemainder
                                            : reminderOptions[0].value,
                                      },
                                      remainder: {
                                        label: meetingOverviewDetails.remainder
                                          ? meetingOverviewDetails.remainder
                                          : reminderOptions[0].label,
                                        value: meetingOverviewDetails.remainder
                                          ? meetingOverviewDetails.remainder
                                          : reminderOptions[0].value,
                                      },
                                    });
                                    setSelectedItems(
                                      participantsDetails
                                        .filter((item) => {
                                          return item.moduleId !== null;
                                        })
                                        .map((item) => ({
                                          flagValue: item.flagValue + "S",
                                          moduleId: item.moduleId,
                                          participantsId: item.participantId,
                                        }))
                                    );
                                    setSelectedParticipants(
                                      participantsDetails
                                        .filter((item) => {
                                          return item.moduleId !== null;
                                        })
                                        .map((item) => ({
                                          participantsId: item.participantId,
                                          moduleId: item.moduleId,
                                        }))
                                    );
                                    setEmailList(
                                      participantsDetails
                                        .filter((item) => {
                                          return item.flagValue === "INVITED";
                                        })
                                        .map((item) => item.email)
                                    );
                                    setSelectedEmailList(
                                      participantsDetails
                                        .filter((item) => {
                                          return item.flagValue === "INVITED";
                                        })
                                        .map((item) => ({
                                          participantsId: item.participantId,
                                          email: item.email,
                                        }))
                                    );
                                    setIsAllDay(meetingOverviewDetails.allDay);
                                    handleUpdate();
                                  }}
                                  className="cursorPointer meetings_edit_icon"
                                >
                                  {editIcon}
                                </span>
                              </div>
                            )}
                            <div className="meetingsContainer">
                              <span>
                                <img src={timeMeetings} alt="/" width={22} />
                              </span>
                              <span>
                                {meetingOverviewDetails.allDay
                                  ? "All-Day"
                                  : `${meetingOverviewDetails?.fromTime} - ${meetingOverviewDetails?.toTime}`}
                              </span>
                              <span
                                onClick={() => {
                                  setEditPurchaseOverview(true);
                                  setMeetingInfo({
                                    ...meetingOverviewDetails,
                                    fromDate: moment(
                                      meetingOverviewDetails.fromDate,
                                      "DD-MM-YYYY"
                                    ).format("YYYY-MM-DD"),
                                    toDate: moment(
                                      meetingOverviewDetails.toDate,
                                      "DD-MM-YYYY"
                                    ).format("YYYY-MM-DD"),
                                    participantsRemainder: {
                                      label:
                                        meetingOverviewDetails.participantsRemainder
                                          ? meetingOverviewDetails.participantsRemainder
                                          : reminderOptions[0].label,
                                      value:
                                        meetingOverviewDetails.participantsRemainder
                                          ? meetingOverviewDetails.participantsRemainder
                                          : reminderOptions[0].value,
                                    },
                                    remainder: {
                                      label: meetingOverviewDetails.remainder
                                        ? meetingOverviewDetails.remainder
                                        : reminderOptions[0].label,
                                      value: meetingOverviewDetails.remainder
                                        ? meetingOverviewDetails.remainder
                                        : reminderOptions[0].value,
                                    },
                                  });
                                  setSelectedItems(
                                    participantsDetails
                                      .filter((item) => {
                                        return item.moduleId !== null;
                                      })
                                      .map((item) => ({
                                        flagValue: item.flagValue + "S",
                                        moduleId: item.moduleId,
                                        participantsId: item.participantId,
                                      }))
                                  );
                                  setSelectedParticipants(
                                    participantsDetails
                                      .filter((item) => {
                                        return item.moduleId !== null;
                                      })
                                      .map((item) => ({
                                        participantsId: item.participantId,
                                        moduleId: item.moduleId,
                                      }))
                                  );
                                  setEmailList(
                                    participantsDetails
                                      .filter((item) => {
                                        return item.flagValue === "INVITED";
                                      })
                                      .map((item) => item.email)
                                  );
                                  setSelectedEmailList(
                                    participantsDetails
                                      .filter((item) => {
                                        return item.flagValue === "INVITED";
                                      })
                                      .map((item) => ({
                                        participantsId: item.participantId,
                                        email: item.email,
                                      }))
                                  );
                                  setIsAllDay(meetingOverviewDetails.allDay);
                                  handleUpdate();
                                }}
                                className="cursorPointer meetings_edit_icon"
                              >
                                {editIcon}
                              </span>
                            </div>
                            {meetingOverviewDetails.location && (
                              <div className="meetingsContainer">
                                <span>
                                  <img
                                    src={LocationMeetings}
                                    alt="/"
                                    width={22}
                                  />
                                </span>
                                <span>{meetingOverviewDetails?.location}</span>
                                <span
                                  onClick={() => {
                                    setEditPurchaseOverview(true);
                                    setMeetingInfo({
                                      ...meetingOverviewDetails,
                                      fromDate: moment(
                                        meetingOverviewDetails.fromDate,
                                        "DD-MM-YYYY"
                                      ).format("YYYY-MM-DD"),
                                      toDate: moment(
                                        meetingOverviewDetails.toDate,
                                        "DD-MM-YYYY"
                                      ).format("YYYY-MM-DD"),
                                      participantsRemainder: {
                                        label:
                                          meetingOverviewDetails.participantsRemainder
                                            ? meetingOverviewDetails.participantsRemainder
                                            : reminderOptions[0].label,
                                        value:
                                          meetingOverviewDetails.participantsRemainder
                                            ? meetingOverviewDetails.participantsRemainder
                                            : reminderOptions[0].value,
                                      },
                                      remainder: {
                                        label: meetingOverviewDetails.remainder
                                          ? meetingOverviewDetails.remainder
                                          : reminderOptions[0].label,
                                        value: meetingOverviewDetails.remainder
                                          ? meetingOverviewDetails.remainder
                                          : reminderOptions[0].value,
                                      },
                                    });
                                    setSelectedItems(
                                      participantsDetails
                                        .filter((item) => {
                                          return item.moduleId !== null;
                                        })
                                        .map((item) => ({
                                          flagValue: item.flagValue + "S",
                                          moduleId: item.moduleId,
                                          participantsId: item.participantId,
                                        }))
                                    );
                                    setSelectedParticipants(
                                      participantsDetails
                                        .filter((item) => {
                                          return item.moduleId !== null;
                                        })
                                        .map((item) => ({
                                          participantsId: item.participantId,
                                          moduleId: item.moduleId,
                                        }))
                                    );
                                    setEmailList(
                                      participantsDetails
                                        .filter((item) => {
                                          return item.flagValue === "INVITED";
                                        })
                                        .map((item) => item.email)
                                    );
                                    setSelectedEmailList(
                                      participantsDetails
                                        .filter((item) => {
                                          return item.flagValue === "INVITED";
                                        })
                                        .map((item) => ({
                                          participantsId: item.participantId,
                                          email: item.email,
                                        }))
                                    );
                                    setIsAllDay(meetingOverviewDetails.allDay);
                                    handleUpdate();
                                  }}
                                  className="cursorPointer meetings_edit_icon"
                                >
                                  {editIcon}
                                </span>
                              </div>
                            )}
                            {meetingOverviewDetails.remainder && (
                              <div>
                                <span>
                                  <img
                                    src={AlarmClockMeetings}
                                    alt="/"
                                    width={22}
                                  />
                                </span>

                                <span>{`${meetingOverviewDetails?.remainder}`}</span>
                              </div>
                            )}
                            {meetingOverviewDetails.description && (
                              <div className="meetingsContainer">
                                <span>
                                  <img
                                    src={TestingMeetings}
                                    alt="/"
                                    width={22}
                                  />
                                </span>
                                <span>
                                  {meetingOverviewDetails.description}
                                </span>
                                <span
                                  onClick={() => {
                                    setEditPurchaseOverview(true);
                                    setMeetingInfo({
                                      ...meetingOverviewDetails,
                                      fromDate: moment(
                                        meetingOverviewDetails.fromDate,
                                        "DD-MM-YYYY"
                                      ).format("YYYY-MM-DD"),
                                      toDate: moment(
                                        meetingOverviewDetails.toDate,
                                        "DD-MM-YYYY"
                                      ).format("YYYY-MM-DD"),
                                      participantsRemainder: {
                                        label:
                                          meetingOverviewDetails.participantsRemainder
                                            ? meetingOverviewDetails.participantsRemainder
                                            : reminderOptions[0].label,
                                        value:
                                          meetingOverviewDetails.participantsRemainder
                                            ? meetingOverviewDetails.participantsRemainder
                                            : reminderOptions[0].value,
                                      },
                                      remainder: {
                                        label: meetingOverviewDetails.remainder
                                          ? meetingOverviewDetails.remainder
                                          : reminderOptions[0].label,
                                        value: meetingOverviewDetails.remainder
                                          ? meetingOverviewDetails.remainder
                                          : reminderOptions[0].value,
                                      },
                                    });
                                    setSelectedItems(
                                      participantsDetails
                                        .filter((item) => {
                                          return item.moduleId !== null;
                                        })
                                        .map((item) => ({
                                          flagValue: item.flagValue + "S",
                                          moduleId: item.moduleId,
                                          participantsId: item.participantId,
                                        }))
                                    );
                                    setSelectedParticipants(
                                      participantsDetails
                                        .filter((item) => {
                                          return item.moduleId !== null;
                                        })
                                        .map((item) => ({
                                          participantsId: item.participantId,
                                          moduleId: item.moduleId,
                                        }))
                                    );
                                    setEmailList(
                                      participantsDetails
                                        .filter((item) => {
                                          return item.flagValue === "INVITED";
                                        })
                                        .map((item) => item.email)
                                    );
                                    setSelectedEmailList(
                                      participantsDetails
                                        .filter((item) => {
                                          return item.flagValue === "INVITED";
                                        })
                                        .map((item) => ({
                                          participantsId: item.participantId,
                                          email: item.email,
                                        }))
                                    );
                                    setIsAllDay(meetingOverviewDetails.allDay);
                                    handleUpdate();
                                  }}
                                  className="cursorPointer meetings_edit_icon"
                                >
                                  {editIcon}
                                </span>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="lead_Information_mainContainer commonContainer_bg rounded-2  d-flex flex-column mb-2 mt-4">
                  <div className="leadInformation_content_container justify-content-between ">
                    <div className="d-flex">
                      <h5 className="w-100 text-primary px-2 py-2 ms-2 mt-2">
                        Participants
                      </h5>
                      <button
                        className="rounded-5 me-4 h-75"
                        style={{
                          backgroundColor: "#E4F5FF",
                          alignSelf: "center",
                          border: "0.76px solid #529CF3",
                          width: "15%",
                        }}
                        onClick={() => {
                          setSelectedItems(
                            participantsDetails
                              .filter((item) => {
                                return item.moduleId !== null;
                              })
                              .map((item) => ({
                                flagValue: item.flagValue + "S",
                                moduleId: item.moduleId,
                                participantsId: item.participantId,
                              }))
                          );
                          setSelectedParticipants(
                            participantsDetails
                              .filter((item) => {
                                return item.moduleId !== null;
                              })
                              .map((item) => ({
                                participantsId: item.participantId,
                                moduleId: item.moduleId,
                              }))
                          );
                          setEmailList(
                            participantsDetails
                              .filter((item) => {
                                return item.flagValue === "INVITED";
                              })
                              .map((item) => item.email)
                          );
                          setSelectedEmailList(
                            participantsDetails
                              .filter((item) => {
                                return item.flagValue === "INVITED";
                              })
                              .map((item) => ({
                                participantsId: item.participantId,
                                email: item.email,
                              }))
                          );
                          handleSubSelect("AddParticipants");
                          setShowModalType("");
                          setAddButton(true);
                          setDeletedItems([]);
                        }}
                      >
                        Add
                      </button>
                    </div>
                    <div
                      className="ms-2 me-2"
                      style={{ borderBottom: "1px solid #D9D9D9" }}
                    ></div>
                    {/* <div className="d-flex gap-3">
                      <p>Invited - {participantsDetails.length}</p>
                      <p>Accepted - 0</p>
                      <p>Maybe - 0</p>
                      <p>Decline - 0</p>
                      <p>No Reply - {participantsDetails.length}</p>
                    </div> */}
                  </div>
                  <div
                    className="list-container gap-2 justify-content-between m-2 px-2 px-1 rounded-3 py-1 d-flex flex-column"
                    style={{
                      height:
                        participantsDetails.length > 0 ? "321px" : "100px",
                      overflowY: "scroll",
                    }}
                  >
                    {participantsDetails.length > 0 ? (
                      <>
                        {participantsDetails.map((item, index) => (
                          <div
                            key={index}
                            className="d-flex flex-column m-0 p-0"
                            style={{ borderBottom: "1px solid #D9D9D9" }}
                          >
                            <ul
                              className="m-0 py-0"
                              style={{ paddingLeft: "20px" }}
                            >
                              {item.participantName &&
                                item.participantName !== "no-name" && (
                                  <li
                                    className="m-0 p-0"
                                    style={{
                                      color: "#316AFF",
                                      fontWeight: "500",
                                      fontSize: "16px",
                                      marginBottom: "5px",
                                    }}
                                  >
                                    {item.participantName}
                                  </li>
                                )}

                              <p>{item.email}</p>
                            </ul>
                          </div>
                        ))}
                      </>
                    ) : (
                      <p>No participant found</p>
                    )}
                  </div>
                </div>
                <div className="lead_Information_mainContainer rounded-2 commonContainer_bg d-flex flex-column mb-2 mt-4">
                  <div className="leadInformation_content_container justify-content-between ">
                    <h5 className="w-100 text-primary px-2 py-2 ms-2 mt-2">
                      Other Information
                    </h5>
                    <div className="d-flex">
                      <div className="leadContact_container d-flex gap-4">
                        <div className="opacity-75 d-flex flex-column gap-2 px-2 py-2">
                          <span>Created By</span>
                          <span className="mt-3">Modified By</span>
                        </div>
                        <div className="d-flex flex-column p-2 gap-2">
                          <div className="d-flex flex-column ">
                            <span className="p-0">
                              {meetingOverviewDetails.createdBy}
                            </span>
                            <span className="small opacity-75 p-0 m-0">
                              {meetingOverviewDetails.createdTime}
                            </span>
                          </div>
                          <div className="d-flex flex-column ">
                            <span className="p-0">
                              {meetingOverviewDetails.modifiedBy}
                            </span>
                            <span className="small opacity-75 p-0 m-0">
                              {meetingOverviewDetails.modifiedTime}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {meetingOverviewDetails.callResult && (
                  <div className="lead_Information_mainContainer commonContainer_bg rounded-2  d-flex flex-column mb-2 mt-4">
                    <div className="leadInformation_content_container justify-content-between ">
                      <h5 className="w-100 text-primary px-2 py-2">
                        Outcome Of Outgoing Call
                      </h5>
                      <div className="d-flex">
                        <div className="leadContact_container d-flex gap-4">
                          <div className="opacity-75 d-flex flex-column gap-2 px-2 py-2">
                            <span>Call Result</span>
                            <span>Description</span>
                          </div>
                          <div className="d-flex flex-column gap-2 px-2 py-2">
                            {/* <span>{meetingOverviewDetails.callResult}</span> */}

                            <span>{meetingOverviewDetails.description}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {notePermission === "TRUE" && (
                  <div
                    id="notesSection"
                    className="lead_Information_mainContainer commonContainer_bg rounded-2  d-flex flex-column py-2 mt-2"
                  >
                    <div>
                      <Note
                        note={note}
                        addTitle={addTitle}
                        showButtons={showButtons}
                        setShowButtons={setShowButtons}
                        textFile={selectedNoteFiles}
                        showTitleNote={showTitleNote}
                        setShowTitleNote={setShowTitleNote}
                        handleTextAreaClick={handleTextAreaClick}
                        handleSaveButton={insertNote}
                        handleAddTitleClick={handleAddTitleClick}
                        setNoteTitle={setNoteTitle}
                        noteInfo={noteInfo}
                        selectRecent={selectRecent}
                        handleSelectRecent={handleSelectRecent}
                        handleDeleteClick={deleteNoteDetails}
                        leadId={meetingId}
                        handleUpdateClick={updateNote}
                        setUpdateNotes={setUpdateNotes}
                        updateNotes={updateNotes}
                        setEditNote={setEditNote}
                        editNote={editNote}
                        handleChangeNoteTitle={handleChangeNoteTitle}
                        handleChangeNoteDescription={
                          handleChangeNoteDescription
                        }
                        setAddTitle={setAddTitle}
                        setNote={setNote}
                        moduleType={"Meetings"}
                        recordName={meetingOverviewDetails?.callOwner}
                        selectedNoteFiles={selectedNoteFiles}
                        setSelectedNoteFiles={setSelectedNoteFiles}
                        onChildDataChange={handleChildDataChange}
                        noteDeleteConfirmation={noteDeleteConfirmation}
                        setNoteDeleteConfirmation={setNoteDeleteConfirmation}
                      />

                      {showTitleNote && (
                        <textarea
                          rows={2}
                          className="notesClass px-2"
                          placeholder="Add note Title"
                          value={noteTitle}
                          onChange={(e) => setNoteTitle(e.target.value)}
                          style={{ width: "95%", marginTop: "10px" }}
                        />
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div
      onClick={() => {
        setShowHeadings(false);
        setShowSelectedFilter("");
        // setListVisibleType("");
        // setSelectedField("");
        // setPopoutType("");
        setShowSelectedFilter("");
        setListVisibleType("");
        setListVisible(false);
        setIsDropdownOpenEdit(false);
      }}
    >
      {handleMeetingView()}
      <Modal
        show={modalType === "Manage Columns"}
        id="ConvertModal"
        aria-labelledby="example-custom-modal-styling-title"
        className="withdrw_modal"
      >
        <Modal.Body>
          <div className="modalClass  position-relative">
            <div className="d-flex justify-content-between ">
              <h4 className="">Manage Columns</h4>
            </div>
            <div className="w-100 position-relative">
              <div className="mb-1 pt-2 position-relative w-100">
                <input
                  type="text"
                  className="assetSearchbar searchbar rounded-2 p-2 pe-5 common_input_bg w-100 "
                  placeholder="Search"
                  aria-label="Username"
                  aria-describedby="basic-addon1"
                  value={searchColumn}
                  onChange={(e) => {
                    if (e.target.value.charAt(0) !== " ") {
                      const value = e.target.value.replace(
                        /[^a-zA-Z ]+|(\s)\s+/g,
                        "$1"
                      );
                      setSearchColumn(value);
                    }
                  }}
                />
                <span
                  className="position-absolute  mt-2 me-2 end-0 opacity-75 cursorPointer"
                  onClick={() => {
                    setSearchColumn("");
                    setSavingHeadings(meetingsColumn);
                  }}
                >
                  {searchColumn === "" ? (
                    <img src={searchIcon} alt="" width={20} />
                  ) : (
                    "X"
                  )}
                </span>
              </div>
              <div className=" opacity-75 columns_container">
                {savingHeadings
                  .filter((lead) =>
                    lead.name
                      .toLowerCase()
                      .includes(searchColumn.trim().toLowerCase())
                  )
                  .sort((a, b) => a.name.localeCompare(b.name))
                  .map((column) => {
                    return (
                      <div
                        key={column.name}
                        className="d-flex gap-2 py-2 rounded-2 align-items-center"
                      >
                        <div className="d-flex gap-2 ps-2">
                          <input
                            type="checkbox"
                            id={column.name}
                            name={column.name}
                            checked={column.isChecked}
                            onChange={(e) => {
                              handleColumnCheckboxChange(
                                column.name,
                                e.target.checked
                              );
                            }}
                            className="cursorPointer"
                          />
                          <label
                            className="small cursorPointer"
                            htmlFor={column.name}
                          >
                            {column.name}
                          </label>
                        </div>
                      </div>
                    );
                  })}
              </div>
              <aside className="float-end mt-2">
                <div className="d-flex align-items-center gap-4">
                  <button
                    className="cancel_btn bg-light"
                    onClick={() => {
                      setShowModalType("");
                    }}
                  >
                    Cancel
                  </button>
                  <button
                    className="save_btn"
                    onClick={() => {
                      setSelectedHeadings(savingHeadings);
                      setTimeout(() => {
                        setShowModalType("");
                      }, 200);
                    }}
                  >
                    Save
                  </button>
                </div>
              </aside>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        show={["DeleteCalls"].includes(deleteModal)}
        aria-labelledby="contained-modal-title-vcenter"
        onClick={(e) => e.stopPropagation()}
        // size={showModalType !== "delete" ? "md" : "lg"}
      >
        <Modal.Header
          className=" border-0 mb-0 d-flex flex-column align-items-start"
          style={{ backgroundColor: modalType !== "delete" && "#F2F2F2" }}
        >
          {deleteModal === "DeleteCalls" && (
            <div className="fs-5" style={{ color: "#3F7DFF" }}>
              Are you sure you want to delete &quot;Outgoing call to{" "}
              {meetingOverviewDetails?.title}&quot;?
            </div>
          )}
        </Modal.Header>
        <Modal.Body className="ModalBody">
          <div className={`pt-4  ${modalType !== "delete" && "pe-3"}`}>
            <div className="d-flex justify-content-end w-100 gap-4 pe-2">
              <button
                className="cancelButton px-4"
                onClick={() => {
                  setDeleteModal("");
                }}
              >
                Cancel
              </button>
              <button
                className="createContactButtonSave"
                onClick={() => {
                  deleteMeetings();
                }}
              >
                Delete
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        show={modalType === "ScheduleCall"}
        id="ConvertModal"
        aria-labelledby="example-custom-modal-styling-title"
        className={`withdrw_modal rounded-2 modal-lg ${
          overlapModal ? "opacity-75" : ""
        }`}
        onClick={() => {
          setShowFromTime({
            hour: false,
            minutes: false,
          });
          setShowToTime({
            hour: false,
            minutes: false,
          });
          setDropdownSelectReminder(false);
          setDropdownRelatedTo(false);
          setDropdownSelect(false);
        }}
      >
        <Modal.Body className="p-0 rounded-2">
          <div className="modalClass position-relative">
            <div
              className="d-flex justify-content-between px-4 pt-4 pb-2 rounded-2"
              style={{ background: "rgba(242, 242, 242, 1)" }}
            >
              <h4 className="commonTextColor">Meeting Information</h4>
              <img
                src={cancelCircle}
                alt="cancel"
                style={{
                  width: "25px",
                  height: "25px",
                  cursor: "pointer",
                }}
                onClick={() => {
                  setShowModalType("");
                }}
              />
            </div>
            <div className="w-100 position-relative">
              <div className="columns_container ps-4 pe-2 pt-2 pb-3">
                <div className="inputFieldDivCalls mt-2 gap-3">
                  <div className="inputFieldDivLeftCalls">
                    <p className="mb-0 mb-1" style={{ color: "#515151" }}>
                      Title<span className="text-danger">*</span>
                    </p>
                    <input
                      type="text"
                      className={
                        mandatoryMsg
                          ? "mandatoryField w-100"
                          : "insertCommonInputFieldCalls"
                      }
                      name="title"
                      value={meetingInfo.title}
                      onChange={handleChangeMeetings}
                      placeholder="Enter a Title"
                    />
                    {mandatoryMsg && (
                      <p style={{ color: "#fa5a5a" }}>
                        {mandatoryMsg && mandatoryMsg}
                      </p>
                    )}
                    <div className="mt-1">
                      <input
                        type="checkbox"
                        checked={isAllDay}
                        onChange={handleCheckboxChange}
                      />
                      <label className="ms-1">All Day</label>
                    </div>
                  </div>

                  <div className="inputFieldDivLeftCalls">
                    <p className="mb-0 mb-1" style={{ color: "#515151" }}>
                      Location
                    </p>
                    <input
                      type="text"
                      className="insertCommonInputFieldCalls"
                      name="location"
                      maxLength={80}
                      value={meetingInfo.location}
                      onChange={handleChangeMeetings}
                      placeholder="Enter Location"
                    />
                    <div className="mt-1">
                      <input
                        type="checkbox"
                        // checked={isAllDay}
                        // onChange={handleCheckboxChange}
                      />

                      <label className="ms-2">
                        Make this an online meeting
                      </label>
                    </div>
                  </div>
                </div>
                <div className="inputFieldDivCalls mt-4 gap-3">
                  <div className="inputFieldDivLeftCalls">
                    <div className="d-flex">
                      <div className={isAllDay ? "w-100" : "w-50"}>
                        <p className="mb-0 mb-1" style={{ color: "#515151" }}>
                          From Date<span className="text-danger">*</span>
                        </p>
                        <input
                          type="date"
                          className="insertCommonInputFieldCalls"
                          name="fromDate"
                          value={meetingInfo.fromDate}
                          onChange={handleChangeMeetings}
                          min="2000-01-01"
                          max="2050-01-01"
                          onKeyDown={(e) => e.preventDefault()}
                        />
                      </div>
                      {!isAllDay && (
                        <div className="w-50">
                          <>
                            <p
                              className="mb-0 mb-1"
                              style={{ color: "#515151" }}
                            >
                              From Time<span className="text-danger">*</span>
                            </p>
                            <div className="insertCommonInputFieldCalls d-flex gap-2">
                              <div className="hours_box position-relative">
                                <div
                                  className="cursorPointer"
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    setShowToTime({
                                      hour: false,
                                      minutes: false,
                                    });
                                    setListVisible(false);
                                    setListVisibleType("");
                                    setShowFromTime({
                                      hour: !showFromTime.hour,
                                      minutes: false,
                                    });
                                  }}
                                >
                                  {fromTimeSet.hour}
                                </div>
                                {showFromTime.hour ? (
                                  <div className="hours_options position-absolute d-flex flex-column rounded-1">
                                    {hours.map((hour, index) => {
                                      return (
                                        <span
                                          className="p-1 cursorPointer"
                                          onClick={() => {
                                            setFromTimeSet({
                                              hour: hour,
                                              minutes: fromTimeSet.minutes,
                                              meridiem: fromTimeSet.meridiem,
                                            });
                                          }}
                                          key={index}
                                        >
                                          {hour}
                                        </span>
                                      );
                                    })}
                                  </div>
                                ) : null}
                              </div>
                              <div>:</div>
                              <div className="minutes_box position-relative">
                                <div
                                  className="cursorPointer"
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    setShowToTime({
                                      hour: false,
                                      minutes: false,
                                    });
                                    setListVisible(false);
                                    setListVisibleType("");
                                    setShowFromTime({
                                      hour: false,
                                      minutes: !showFromTime.minutes,
                                    });
                                  }}
                                >
                                  {fromTimeSet.minutes}
                                </div>
                                {showFromTime.minutes ? (
                                  <div className="hours_options position-absolute d-flex flex-column rounded-1">
                                    {" "}
                                    {minutes.map((minute, index) => {
                                      return (
                                        <span
                                          className="p-1 cursorPointer"
                                          onClick={() => {
                                            setFromTimeSet({
                                              hour: fromTimeSet.hour,
                                              minutes: minute,
                                              meridiem: fromTimeSet.meridiem,
                                            });
                                          }}
                                          key={index}
                                        >
                                          {minute}
                                        </span>
                                      );
                                    })}
                                  </div>
                                ) : null}
                              </div>
                              <div>:</div>
                              <div className="hours_box_type">
                                <div
                                  className="cursorPointer"
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    setShowFromTime({
                                      hour: false,
                                      minutes: false,
                                    });

                                    setFromTimeSet({
                                      hour: fromTimeSet.hour,
                                      minutes: fromTimeSet.minutes,
                                      meridiem:
                                        fromTimeSet.meridiem === "AM"
                                          ? "PM"
                                          : "AM",
                                    });
                                  }}
                                >
                                  {fromTimeSet.meridiem}
                                </div>
                              </div>
                            </div>
                          </>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="inputFieldDivLeftCalls w-100">
                    <div className="d-flex">
                      <div className={isAllDay ? "w-100" : "w-50"}>
                        <p className="mb-0 mb-1" style={{ color: "#515151" }}>
                          To Date<span className="text-danger">*</span>
                        </p>
                        <input
                          type="date"
                          className="insertCommonInputFieldCalls"
                          name="toDate"
                          value={meetingInfo.toDate}
                          onChange={handleChangeMeetings}
                          min="2000-01-01"
                          max="2050-01-01"
                          onKeyDown={(e) => e.preventDefault()}
                        />
                      </div>
                      {!isAllDay && (
                        <div className="w-50">
                          <p className="mb-0 mb-1" style={{ color: "#515151" }}>
                            To Time<span className="text-danger">*</span>
                          </p>
                          <div className="insertCommonInputFieldCalls d-flex gap-2">
                            <div className="hours_box position-relative">
                              <div
                                className="cursorPointer"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  setShowFromTime({
                                    hour: false,
                                    minutes: false,
                                  });
                                  setListVisible(false);
                                  setListVisibleType("");
                                  setShowToTime({
                                    hour: !showToTime.hour,
                                    minutes: false,
                                  });
                                }}
                              >
                                {toTimeSet.hour}
                              </div>
                              {showToTime.hour ? (
                                <div className="hours_options position-absolute d-flex flex-column rounded-1">
                                  {hours.map((hour, index) => {
                                    return (
                                      <span
                                        className="p-1 cursorPointer"
                                        onClick={() => {
                                          setToTimeSet({
                                            hour: hour,
                                            minutes: toTimeSet.minutes,
                                            meridiem: toTimeSet.meridiem,
                                          });
                                        }}
                                        key={index}
                                      >
                                        {hour}
                                      </span>
                                    );
                                  })}
                                </div>
                              ) : null}
                            </div>
                            <div>:</div>
                            <div className="minutes_box position-relative">
                              <div
                                className="cursorPointer"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  setShowFromTime({
                                    hour: false,
                                    minutes: false,
                                  });
                                  setListVisible(false);
                                  setListVisibleType("");
                                  setShowToTime({
                                    hour: false,
                                    minutes: !showToTime.minutes,
                                  });
                                }}
                              >
                                {toTimeSet.minutes}
                              </div>
                              {showToTime.minutes ? (
                                <div className="hours_options position-absolute d-flex flex-column rounded-1">
                                  {" "}
                                  {minutes.map((minute, index) => {
                                    return (
                                      <span
                                        className="p-1 cursorPointer"
                                        onClick={() => {
                                          setToTimeSet({
                                            hour: toTimeSet.hour,
                                            minutes: minute,
                                            meridiem: toTimeSet.meridiem,
                                          });
                                        }}
                                        key={index}
                                      >
                                        {minute}
                                      </span>
                                    );
                                  })}
                                </div>
                              ) : null}
                            </div>
                            <div>:</div>
                            <div className="hours_box_type">
                              <div
                                className="cursorPointer"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  setShowToTime({
                                    hour: false,
                                    minutes: false,
                                  });
                                  setToTimeSet({
                                    hour: toTimeSet.hour,
                                    minutes: toTimeSet.minutes,
                                    meridiem:
                                      toTimeSet.meridiem === "AM" ? "PM" : "AM",
                                  });
                                }}
                              >
                                {toTimeSet.meridiem}
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="inputFieldDivCalls mt-4 gap-3">
                  <div className="inputFieldDivLeftCalls">
                    <p className="mb-0 mb-1" style={{ color: "#515151" }}>
                      Host
                    </p>
                    <div className="d-flex input-with-clear">
                      <input
                        type="text"
                        //   placeholder="Vendor Owner"
                        className="insertCommonInputFieldCalls cursorPointer"
                        value={selectedOwner?.name}
                        onClick={(e) => {
                          e.stopPropagation();
                          setShowFromTime({
                            hour: false,
                            minutes: false,
                          });
                          setShowToTime({
                            hour: false,
                            minutes: false,
                          });
                          handleInputClick("Owner");
                          setDropdownSelectReminder(false);
                          setDropdownRelatedTo(false);
                          setDropdownSelect(false);
                        }}
                        readOnly
                      />
                      <span
                        className="clear-buttonCalls"
                        onClick={(e) => {
                          e.stopPropagation();
                          handleInputClick("Owner");
                        }}
                      >
                        <img
                          src={listVisible ? up : down}
                          alt="img"
                          height={20}
                          width={20}
                        />
                      </span>
                      <span
                        className="cursorPointer pt-2 ps-1"
                        onClick={() => {
                          if (editPurchaseOverview) {
                            setShowOwnerModalType("changeOwner");
                          } else {
                            setIsLeadOwnerModal(!isLeadOwnerModal);
                          }
                        }}
                      >
                        <img
                          src={userSearch}
                          alt="img"
                          width={20}
                          height={20}
                        />
                      </span>
                      {listVisibleType === "Owner" && listVisible && (
                        <ul className="data-list list-group  lead_ownerList_container_meetings position-absolute">
                          {userList.map((item, index) => (
                            <li
                              key={index}
                              className="cursorPointer list-group-item"
                              onClick={() => handleInputClickSet(item, "Owner")}
                            >
                              <div className="d-flex flex-column">
                                <span> {item?.name} </span>
                                <span> {item.emailId} </span>
                              </div>
                            </li>
                          ))}
                        </ul>
                      )}
                    </div>
                  </div>

                  <div className="inputFieldDivLeftCalls">
                    <p className="mb-0 mb-1" style={{ color: "#515151" }}>
                      Participants
                    </p>
                    <div
                      className="input-container d-flex ps-2"
                      style={{
                        borderRadius: "5px",
                        border: "1px solid #D9D9D9",
                        justifyContent: "space-between",
                        width: "95%",
                        height: "49px",
                      }}
                    >
                      <input
                        type="text"
                        className="insertCommonInputFieldCalls p-0 mb-1"
                        style={{ border: "none", width: "70%" }}
                        placeholder={selectedItems.length + emailList.length}
                        readOnly
                      />
                      <div
                        className="m-0"
                        style={{
                          color: "#407BFF",
                          cursor: "pointer",
                          fontSize: "35px",
                          backgroundColor: "#0000000D",
                          width: "15%",
                          textAlign: "-webkit-center",
                        }}
                        onClick={() => {
                          handleSubSelect("AddParticipants");
                          setShowModalType("");
                          setSearchInput("");
                          setFilteredItems(userList);
                        }}
                      >
                        <img
                          src={addIcon}
                          alt=""
                          width={26}
                          style={{ verticalAlign: "initial" }}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="inputFieldDivCalls mt-4 gap-3">
                  <div className="inputFieldDivLeftCalls">
                    <p className="mb-0 mb-1" style={{ color: "#515151" }}>
                      Related To
                    </p>
                    <Select
                      options={leadContactOptions}
                      styles={createAccountStyles}
                      value={selectedOptionLead}
                      onChange={handleChangeLead}
                      isSearchable={false}
                    />
                  </div>

                  <div className="inputFieldDivLeftCalls">
                    {!editPurchaseOverview && (
                      <>
                        <p className="mb-0 mb-1" style={{ color: "#515151" }}>
                          Repeat
                        </p>
                        <div
                          className="input-container d-flex ps-2"
                          style={{
                            borderRadius: "5px",
                            border: "1px solid #D9D9D9",
                            justifyContent: "space-between",
                            width: "95%",
                            height: "49px",
                          }}
                        >
                          <input
                            type="text"
                            className="insertCommonInputFieldCalls mb-1 p-0"
                            style={{ border: "none", width: "70%" }}
                            placeholder={selectedOptionRepeat.value}
                            // onChange={handleEmailChange}
                            readOnly
                          />
                          <div
                            className="m-0"
                            style={{
                              color: "#407BFF",
                              cursor: "pointer",
                              fontSize: "35px",
                              backgroundColor: "#0000000D",
                              width: "15%",
                              textAlign: "-webkit-center",
                            }}
                            onClick={() => {
                              handleSubSelect("Reset");
                              setShowModalType("");
                            }}
                          >
                            <img
                              src={editIcon1}
                              alt=""
                              width={20}
                              style={{
                                verticalAlign: "initial",
                                marginBottom: "5px",
                              }}
                            />
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                </div>
                {(selectedOptionLead.value === "Contact" ||
                  selectedOptionLead.value === "Lead") && (
                  <div className="inputFieldDivCalls mt-4 gap-3">
                    <div className="inputFieldDivLeftCalls">
                      <p className="mb-0 mb-1" style={{ color: "#515151" }}>
                        Selected Relation
                      </p>
                      <input
                        type="text"
                        className="insertCommonInputFieldCalls cursorPointer"
                        value={selectedOptionLead.value}
                        disabled
                      />
                    </div>
                    <div className="inputFieldDivLeftCalls">
                      <p className="mb-0 mb-1" style={{ color: "#515151" }}>
                        {selectedOptionLead.value}
                      </p>

                      {selectedOptionLead.value === "Contact" && (
                        <div
                          className="d-flex input-with-clear "
                          // onClick={handleOwnerInputClick}
                        >
                          <input
                            type="text"
                            //   placeholder="Vendor Owner"
                            className="insertCommonInputFieldCalls cursorPointer"
                            value={selectedContact.contactName}
                            onClick={(e) => {
                              e.stopPropagation();
                              handleInputClick("Contact");
                              setDropdownSelectReminder(false);
                              setDropdownRelatedTo(false);
                              setDropdownSelect(false);
                            }}
                            readOnly
                          />
                          <span
                            className="clear-buttonCalls"
                            onClick={(e) => {
                              e.stopPropagation();
                              handleInputClick("Contact");
                            }}
                          >
                            <img
                              src={listVisible ? up : down}
                              alt="img"
                              height={20}
                              width={20}
                            />
                          </span>
                          <span
                            className="cursorPointer pt-2 ps-1"
                            onClick={() => setIsContactListModal(true)}
                          >
                            <img
                              src={userSearch}
                              alt="img"
                              width={20}
                              height={20}
                            />
                          </span>
                          {listVisibleType === "Contact" && listVisible && (
                            <ul className="data-list list-group  lead_ownerList_container_meetings position-absolute">
                              {contactList.map((item, index) => (
                                <li
                                  key={index}
                                  className="cursorPointer list-group-item"
                                  onClick={() => {
                                    handleInputClickSet(item, "Contact");
                                    setDropdownSelectReminder(false);
                                    setDropdownRelatedTo(false);
                                    setDropdownSelect(false);
                                  }}
                                >
                                  <div className="d-flex flex-column">
                                    <span> {item.contactName} </span>
                                  </div>
                                </li>
                              ))}
                            </ul>
                          )}
                        </div>
                      )}
                      {selectedOptionLead.value === "Lead" && (
                        <div
                          className="d-flex input-with-clear "
                          // onClick={handleOwnerInputClick}
                        >
                          <input
                            type="text"
                            //   placeholder="Vendor Owner"
                            className="insertCommonInputFieldCalls cursorPointer"
                            value={selectedLeadItem.leadName}
                            onClick={(e) => {
                              e.stopPropagation();
                              handleInputClick("Lead");
                            }}
                            readOnly
                          />
                          <span
                            className="clear-buttonCalls"
                            onClick={(e) => {
                              e.stopPropagation();
                              handleInputClick("Lead");
                              setDropdownSelectReminder(false);
                              setDropdownRelatedTo(false);
                              setDropdownSelect(false);
                            }}
                          >
                            <img
                              src={listVisible ? up : down}
                              alt="img"
                              height={20}
                              width={20}
                            />
                          </span>
                          <span
                            className="cursorPointer pt-2 ps-1"
                            onClick={() => setIsLeadListModal(true)}
                          >
                            <img
                              src={userSearch}
                              alt="img"
                              width={20}
                              height={20}
                            />
                          </span>

                          {listVisibleType === "Lead" && listVisible && (
                            <ul className="data-list list-group  lead_ownerList_container_meetings position-absolute">
                              {leadList.map((item, index) => (
                                <li
                                  key={index}
                                  className="cursorPointer list-group-item"
                                  onClick={() =>
                                    handleInputClickSet(item, "Lead")
                                  }
                                >
                                  <div className="d-flex flex-column">
                                    <span> {item.leadName} </span>
                                  </div>
                                </li>
                              ))}
                            </ul>
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                )}
                {(selectedOptionLead.value === "Contact" ||
                  selectedOptionLead.value === "Others") && (
                  <>
                    {" "}
                    <div className="inputFieldDivCalls mt-4 gap-3">
                      <div className="inputFieldDivLeftCalls">
                        <p className="mb-0 mb-1" style={{ color: "#515151" }}>
                          Related To Module
                        </p>
                        {/* <Select
                          options={relatedToOptions}
                          styles={createAccountStyles}
                          value={selectedOptionModule}
                          onChange={handleChangeModule}
                          isDisabled={selectedOptionLead.value === "Lead"}
                          isSearchable={false}
                        /> */}
                        <div className="d-flex input-with-clear">
                          <input
                            className="insertCommonInputFieldCalls cursorPointer"
                            value={selectedOptionModule.label}
                            onClick={(e) => {
                              e.stopPropagation();
                              setDropdownRelatedTo(!dropdownRelatedTo);
                              setListVisible(false);
                              setModuleListVisible(false);
                              setDropdownSelectReminder(false);
                              setDropdownSelect(false);
                            }}
                            readOnly
                            disabled={selectedOptionLead.value === "Lead"}
                          />
                          {dropdownRelatedTo ? (
                            <>
                              <span
                                className="clear-buttonCalls"
                                onClick={() => setDropdownRelatedTo(false)}
                                style={{
                                  top: "1.5rem",
                                  left: "19.5rem",
                                }}
                              >
                                <img
                                  src={up}
                                  alt="img"
                                  height={20}
                                  width={20}
                                />
                              </span>
                            </>
                          ) : (
                            <>
                              <span
                                className="clear-buttonCalls"
                                style={{
                                  top: "1.5rem",
                                  left: "19.5rem",
                                }}
                              >
                                <img
                                  src={down}
                                  alt="img"
                                  height={20}
                                  width={20}
                                />
                              </span>
                            </>
                          )}
                          {dropdownRelatedTo && (
                            <ul
                              className="data-list list-group  lead_ownerList_container position-absolute "
                              style={{
                                top: "3.25rem",
                                width: "21.9rem",
                                minHeight: "max-content",
                              }}
                            >
                              {relatedToOptions.map((item, index) => (
                                <li
                                  key={index}
                                  className="cursorPointer list-group-item"
                                  onClick={() => {
                                    handleChangeModule(item);
                                    setDropdownRelatedTo(false);
                                    setDropdownSelectReminder(false);
                                    setDropdownSelect(false);
                                    setSelectedOptionModule(item);
                                  }}
                                >
                                  <div className="d-flex flex-column">
                                    <span> {item.label} </span>
                                  </div>
                                </li>
                              ))}
                            </ul>
                          )}
                        </div>
                      </div>
                      <div className="inputFieldDivLeftCalls position-relative">
                        <p className="mb-0 mb-1">
                          {selectedOptionModule.label}
                        </p>
                        <div className="d-flex input-with-clear ">
                          <input
                            type="text"
                            placeholder="Search"
                            className="insertCommonInputFieldCalls cursorPointer"
                            value={
                              selectedOptionLead.value === "Lead"
                                ? ""
                                : moduleWiseData.length > 0
                                ? selectedModuleItem.moduleName
                                : ""
                            }
                            disabled={selectedOptionLead.value === "Lead"}
                            onClick={handleModuleClick}
                            readOnly
                          />
                          {selectedOptionLead.value === "Lead" ? (
                            <span className="clear-buttonCalls">
                              <img
                                src={moduleListVisible ? up : down}
                                alt="img"
                                height={20}
                                width={20}
                              />{" "}
                            </span>
                          ) : (
                            <span
                              className="clear-buttonCalls"
                              onClick={handleModuleClick}
                            >
                              <img
                                src={moduleListVisible ? up : down}
                                alt="img"
                                height={20}
                                width={20}
                              />{" "}
                            </span>
                          )}
                        </div>
                        {moduleListVisible && moduleWiseData.length > 0 && (
                          <ul
                            className="data-list list-group  lead_ownerList_container_meetings position-absolute "
                            // style={{
                            //   top: "200px",
                            //    width: "27.5vw",
                            // }}

                            style={{ top: "4.9rem", width: "21.8rem" }}
                          >
                            {" "}
                            {moduleWiseData.map((item, index) => (
                              <li
                                key={index}
                                className="cursorPointer list-group-item"
                                onClick={() => handleModuleItemClick(item)}
                              >
                                {item.moduleName}
                              </li>
                            ))}
                          </ul>
                        )}
                      </div>
                    </div>
                  </>
                )}
                <div
                  className="inputFieldDivCalls mt-4 gap-3"
                  style={{ alignItems: "self-start" }}
                >
                  <div className="inputFieldDivLeftCalls">
                    <p className="mb-0 mb-1" style={{ color: "#515151" }}>
                      Participants Reminder
                    </p>
                    {/* <Select
                      options={reminderOptions}
                      styles={createAccountStyles}
                      value={meetingInfo.participantsRemainder}
                      menuPlacement="auto"
                      onChange={(selectedOption) =>
                        handleChangeSelectDropDown(
                          selectedOption,
                          "participantsRemainder"
                        )
                      }
                      isSearchable={false}
                    /> */}
                    <div className="d-flex input-with-clear">
                      <input
                        className="insertCommonInputFieldCalls cursorPointer"
                        value={meetingInfo.participantsRemainder.value}
                        onClick={(e) => {
                          e.stopPropagation();
                          setDropdownSelect(!dropdownSelect);
                          setListVisible(false);
                          setModuleListVisible(false);
                          setDropdownRelatedTo(false);
                          setDropdownSelectReminder(false);
                        }}
                        readOnly
                      />
                      {dropdownSelect ? (
                        <span
                          className="clear-buttonCalls"
                          onClick={(e) => {
                            e.stopPropagation();
                            setDropdownSelect(false);
                            setListVisible(false);
                            setModuleListVisible(false);
                            setDropdownRelatedTo(false);
                            setDropdownSelectReminder(false);
                          }}
                          style={{
                            top: "1.5rem",
                            left: "19.5rem",
                          }}
                        >
                          <img src={up} alt="img" height={20} width={20} />
                        </span>
                      ) : (
                        <span
                          className="clear-buttonCalls"
                          style={{
                            top: "1.5rem",
                            left: "19.5rem",
                          }}
                        >
                          <img src={down} alt="img" height={20} width={20} />
                        </span>
                      )}
                      {dropdownSelect && (
                        <ul
                          className="data-list list-group  lead_ownerList_container position-absolute "
                          style={{
                            top: "3.25rem",
                            width: "21.9rem",
                            minHeight: "max-content",
                          }}
                        >
                          {reminderOptions.map((item, index) => (
                            <li
                              key={index}
                              className="cursorPointer list-group-item"
                              onClick={(e) => {
                                e.stopPropagation();
                                handleInputClickSet(
                                  item,
                                  "participantsRemainder"
                                );
                                setDropdownSelect(false);
                                setDropdownRelatedTo(false);
                                setListVisible(false);
                                setModuleListVisible(false);
                              }}
                            >
                              <div className="d-flex flex-column">
                                <span> {item.label} </span>
                              </div>
                            </li>
                          ))}
                        </ul>
                      )}
                    </div>
                    <p className="mb-0 mb-1 mt-4" style={{ color: "#515151" }}>
                      Reminder
                    </p>
                    {/* <Select
                      options={reminderOptions}
                      value={meetingInfo.remainder}
                      styles={createAccountStyles}
                      menuPlacement="auto"
                      onChange={(selectedOption) =>
                        handleChangeSelectDropDown(selectedOption, "remainder")
                      }
                      isSearchable={false}
                    /> */}
                    <div className="d-flex input-with-clear">
                      <input
                        className="insertCommonInputFieldCalls cursorPointer"
                        value={meetingInfo.remainder.value}
                        onClick={(e) => {
                          e.stopPropagation();
                          setDropdownSelectReminder(!dropdownSelectReminder);
                          setListVisible(false);
                          setModuleListVisible(false);
                          setDropdownSelect(false);
                          setDropdownRelatedTo(false);
                        }}
                        readOnly
                      />
                      {/* <Select
                      options={reminderOptions}
                      styles={createAccountStyles}
                      value={meetingInfo.participantsRemainder}
                      menuPlacement="auto"
                      onChange={(selectedOption) =>
                        handleChangeSelectDropDownMeetings(
                          selectedOption,
                          "participantsRemainder"
                        )
                      }
                    /> */}
                      {dropdownSelectReminder ? (
                        <>
                          <span
                            className="clear-buttonCalls"
                            onClick={() => setDropdownSelectReminder(false)}
                            style={{
                              top: "1.5rem",
                              left: "19.5rem",
                            }}
                          >
                            <img src={up} alt="img" height={20} width={20} />
                          </span>
                        </>
                      ) : (
                        <>
                          <span
                            className="clear-buttonCalls"
                            style={{
                              top: "1.5rem",
                              left: "19.5rem",
                            }}
                          >
                            <img src={down} alt="img" height={20} width={20} />
                          </span>
                        </>
                      )}
                      {dropdownSelectReminder && (
                        <ul
                          className="data-list list-group  lead_ownerList_container position-absolute "
                          style={{
                            top: "3.25rem",
                            width: "21.9rem",
                            minHeight: "max-content",
                          }}
                        >
                          {reminderOptions.map((item, index) => (
                            <li
                              key={index}
                              className="cursorPointer list-group-item"
                              onClick={() => {
                                handleInputClickSet(item, "remainder");
                                setDropdownSelectReminder(false);
                              }}
                            >
                              <div className="d-flex flex-column">
                                <span> {item.label} </span>
                              </div>
                            </li>
                          ))}
                        </ul>
                      )}
                    </div>
                  </div>

                  <div className="inputFieldDivLeftCalls">
                    <p className="mb-0 mb-1" style={{ color: "#515151" }}>
                      Description
                    </p>
                    <textarea
                      type="text"
                      maxLength={2000}
                      className="insertCommonInputFieldCalls"
                      name="description"
                      value={meetingInfo.description}
                      onChange={handleChangeMeetings}
                      placeholder="Description"
                    />
                  </div>
                </div>
              </div>
              <aside className="float-end mt-2">
                <div className="d-flex align-items-center gap-2 px-4 pt-2 pb-3 mb-3">
                  <button
                    className="cancel_btn bg-light rounded-2"
                    style={{
                      paddingTop: "6px",
                      paddingBottom: "6px",
                      width: "100px",
                    }}
                    onClick={() => {
                      setShowModalType("");
                      setSelectedOptionLead(leadContactOptions[0]);
                      setSelectedOptionModule(relatedToOptions[0]);
                    }}
                  >
                    Cancel
                  </button>
                  <button
                    className="save_btn py-2 border border-0 rounded-2"
                    style={{ width: "122px" }}
                    onClick={() => {
                      handleCheckMeeting("SAVE");
                    }}
                    disabled={apiLoader}
                  >
                    {apiLoader ? (
                      <Lottie
                        options={defaultOptions("white")}
                        height="24px"
                        width="36px"
                      />
                    ) : (
                      "Save"
                    )}
                  </button>
                </div>
              </aside>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        show={modalSubType === "AddParticipants"}
        id="ConvertModal"
        aria-labelledby="example-custom-modal-styling-title"
        className="withdrw_modal rounded-2 modal-lg"
      >
        <Modal.Body className="p-0 rounded-2">
          <div className="modalClass position-relative">
            <div
              className="d-flex justify-content-between px-4 pt-4 pb-2 rounded-2"
              style={{ background: "rgba(242, 242, 242, 1)" }}
            >
              <h4 className="commonTextColor">Add Participants</h4>
            </div>
            <div className="w-100 position-relative">
              <div className="columns_container ps-4 pe-2 pt-2 pb-3">
                <div className="inputFieldDivCalls mt-2 gap-3">
                  <div className="inputFieldDivLeftCalls">
                    <p className="mb-0 mb-1" style={{ color: "#515151" }}>
                      {addParticipant.value}
                    </p>
                    <Select
                      options={AddParticipantsOptions}
                      styles={createAccountStyles}
                      value={addParticipant}
                      onChange={handleAddParticipant}
                      isSearchable={false}
                    />
                  </div>

                  <div className="inputFieldDivLeftCalls">
                    <p className="mb-0 mb-1" style={{ color: "#515151" }}>
                      Search
                    </p>
                    <input
                      type="text"
                      placeholder="Search by Name"
                      className="insertCommonInputFieldCalls"
                      value={searchInput}
                      onChange={handleSearchInputChange}
                    />
                  </div>
                </div>
                <div
                  className="inputFieldDivCalls mt-4 gap-3"
                  style={{ alignItems: "self-start" }}
                >
                  <div className="inputFieldDivLeftCalls ">
                    <p className="mb-0 mb-1" style={{ color: "#515151" }}>
                      Invite by Email Address
                    </p>
                    <div>
                      <input
                        type="text"
                        className="insertCommonInputFieldCalls"
                        placeholder="Add Email Address"
                        value={emailInput}
                        onChange={handleInputChange}
                        onKeyDown={handleKeyDown}
                      />
                      {emailList.length > 0 && (
                        <div className="email-list my-2 mx-0 list-container">
                          {emailList.map((email, index) => (
                            <div key={index} className="email-item">
                              {email}
                              <button
                                className="remove-email"
                                onClick={() => handleRemoveEmail(index)}
                              >
                                &#10006;
                              </button>
                            </div>
                          ))}
                        </div>
                      )}
                    </div>
                  </div>
                  <div
                    className="inputFieldDivLeftCalls mt-0"
                    style={{ overflowX: "hidden" }}
                  >
                    <div className="checkList">
                      <div
                        className="list-container p-2 overflow-y-scroll m-0"
                        style={{
                          border: "1.5px solid #B9B9B980",
                          height: "150px",
                        }}
                      >
                        {filteredItems?.map((item, index) => (
                          <li
                            key={index}
                            className="cursorPointer list-group-item"
                          >
                            <div className="d-flex flex-column">
                              {addParticipant.value === "Users" && (
                                <>
                                  <div
                                    className="ms-1 d-flex flex-column"
                                    onClick={() =>
                                      handleCheckboxToggle(item.userId, "Users")
                                    }
                                  >
                                    <div className="d-flex">
                                      <input
                                        type="checkbox"
                                        checked={selectedItems.some(
                                          (selectedItem) =>
                                            selectedItem.moduleId ===
                                            item.userId
                                        )}
                                        readOnly
                                      />

                                      <span className="ms-1">{item.name}</span>
                                    </div>
                                    <span>{item.emailId}</span>
                                  </div>
                                </>
                              )}
                              {addParticipant.value === "Contacts" && (
                                <div
                                  className="ms-1 d-flex flex-column"
                                  onClick={() =>
                                    handleCheckboxToggle(
                                      item.contactId,
                                      "CONTACTS"
                                    )
                                  }
                                >
                                  <div className="d-flex">
                                    <input
                                      type="checkbox"
                                      checked={selectedItems.some(
                                        (selectedItem) =>
                                          selectedItem.moduleId ===
                                          item.contactId
                                      )}
                                      readOnly
                                    />

                                    <span className="ms-1">
                                      {item.contactName}
                                    </span>
                                  </div>
                                  <span>{item.emailId}</span>
                                </div>
                              )}
                              {addParticipant.value === "Leads" && (
                                <div
                                  className="ms-1 d-flex flex-column"
                                  onClick={() =>
                                    handleCheckboxToggle(item.leadId, "LEADS")
                                  }
                                >
                                  <div className="d-flex">
                                    <input
                                      type="checkbox"
                                      checked={selectedItems.some(
                                        (selectedItem) =>
                                          selectedItem.moduleId === item.leadId
                                      )}
                                      readOnly
                                    />

                                    <span className="ms-1">
                                      {item.leadName}
                                    </span>
                                  </div>
                                  <span>{item.emailId}</span>
                                </div>
                              )}
                            </div>
                          </li>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <aside className="float-end mt-2">
                <div className="d-flex align-items-center gap-2 px-4 pt-2 pb-3 mb-3">
                  {/* <button
                    className="cancel_btn bg-light rounded-2"
                    style={{ width: "122px", height: "40px" }}
                    onClick={() => {
                      if (!addButton) {
                        setShowModalSubType("");
                        setShowModalType("ScheduleCall");
                        setSelectedItems([]);
                        setEmailInput("");
                        setEmailList([]);
                      } else {
                        setShowModalSubType("");
                        setSelectedItems([]);
                        setEmailList([]);
                        setAddButton(false);
                        setEmailInput("");
                      }
                    }}
                  >
                    Cancel
                  </button> */}
                  <button
                    className="save_btn py-2 border border-0 rounded-2 h-100"
                    style={{ width: "122px" }}
                    onClick={() => {
                      if (!addButton) {
                        setShowModalSubType("");
                        setShowModalType("ScheduleCall");
                        setEmailInput("");

                        // setEmailList([]);
                      } else {
                        handleAddParticipantOverview(meetingId);
                      }
                    }}
                    disabled={apiLoader}
                  >
                    {apiLoader ? (
                      <Lottie
                        options={defaultOptions("white")}
                        height="24px"
                        width="36px"
                      />
                    ) : (
                      "Done"
                    )}
                  </button>
                </div>
              </aside>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        show={modalSubType === "Reset"}
        id="ConvertModal"
        aria-labelledby="example-custom-modal-styling-title"
        className="withdrw_modal rounded-2"
        onClick={() => {
          setShowFromTime({
            hour: false,
            minutes: false,
          });
          setShowToTime({
            hour: false,
            minutes: false,
          });
        }}
      >
        <Modal.Body className="p-0 rounded-2">
          <div className="modalClass position-relative">
            <div
              className="d-flex justify-content-between px-4 pt-4 pb-2 rounded-2"
              style={{ background: "rgba(242, 242, 242, 1)" }}
            >
              <h4 className="commonTextColor">Repeat</h4>
            </div>
            <div className="w-100 position-relative">
              <div className="columns_container ps-4 pe-2 pt-2 pb-3">
                <div className="inputFieldDivCalls mt-2 gap-3">
                  <div className="inputFieldDivLeftCalls d-flex">
                    <p className="mb-0 mb-1 me-3" style={{ color: "#515151" }}>
                      All Day
                    </p>
                    <input
                      type="checkbox"
                      checked={isAllDay}
                      onChange={handleCheckboxChange}
                    />
                  </div>
                </div>
                <div className="inputFieldDivCalls mt-4 gap-3">
                  <div className="inputFieldDivLeftCalls">
                    <div className="d-flex">
                      <div className="w-50">
                        <p className="mb-0 mb-1" style={{ color: "#515151" }}>
                          From Date
                        </p>
                        <input
                          type="date"
                          className="insertCommonInputFieldCalls"
                          name="fromDate"
                          value={meetingInfo.fromDate}
                          onChange={handleChangeMeetings}
                          min={new Date().toISOString().split("T")[0]}
                          max={
                            new Date(
                              new Date().getFullYear() + 3,
                              new Date().getMonth(),
                              new Date().getDate()
                            )
                              .toISOString()
                              .split("T")[0]
                          }
                        />
                      </div>
                      <div className="w-50">
                        {!isAllDay && (
                          <>
                            {" "}
                            <p
                              className="mb-0 mb-1"
                              style={{ color: "#515151" }}
                            >
                              From Time
                            </p>
                            <div className="insertCommonInputFieldCalls d-flex gap-2">
                              <div className="hours_box position-relative">
                                <div
                                  className="cursorPointer"
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    setShowFromTime({
                                      hour: !showFromTime.hour,
                                      minutes: false,
                                    });
                                  }}
                                >
                                  {fromTimeSet.hour}
                                </div>
                                {showFromTime.hour ? (
                                  <div className="hours_options position-absolute d-flex flex-column rounded-1">
                                    {hours.map((hour, index) => {
                                      return (
                                        <span
                                          className="p-1 cursorPointer"
                                          onClick={() => {
                                            setFromTimeSet({
                                              hour: hour,
                                              minutes: fromTimeSet.minutes,
                                              meridiem: fromTimeSet.meridiem,
                                            });
                                          }}
                                          key={index}
                                        >
                                          {hour}
                                        </span>
                                      );
                                    })}
                                  </div>
                                ) : null}
                              </div>
                              <div>:</div>
                              <div className="minutes_box position-relative">
                                <div
                                  className="cursorPointer"
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    setShowFromTime({
                                      hour: false,
                                      minutes: !showFromTime.minutes,
                                    });
                                  }}
                                >
                                  {fromTimeSet.minutes}
                                </div>
                                {showFromTime.minutes ? (
                                  <div className="hours_options position-absolute d-flex flex-column rounded-1">
                                    {" "}
                                    {minutes.map((minute, index) => {
                                      return (
                                        <span
                                          className="p-1 cursorPointer"
                                          onClick={() => {
                                            setFromTimeSet({
                                              hour: fromTimeSet.hour,
                                              minutes: minute,
                                              meridiem: fromTimeSet.meridiem,
                                            });
                                          }}
                                          key={index}
                                        >
                                          {minute}
                                        </span>
                                      );
                                    })}
                                  </div>
                                ) : null}
                              </div>
                              <div>:</div>
                              <div className="hours_box_type">
                                <div
                                  className="cursorPointer"
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    setShowFromTime({
                                      hour: false,
                                      minutes: false,
                                    });

                                    setFromTimeSet({
                                      hour: fromTimeSet.hour,
                                      minutes: fromTimeSet.minutes,
                                      meridiem:
                                        fromTimeSet.meridiem === "AM"
                                          ? "PM"
                                          : "AM",
                                    });
                                  }}
                                >
                                  {fromTimeSet.meridiem}
                                </div>
                              </div>
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="inputFieldDivCalls mt-4 gap-3">
                  <div className="inputFieldDivLeftCalls">
                    <div className="d-flex">
                      <div className="w-50">
                        <p className="mb-0 mb-1" style={{ color: "#515151" }}>
                          To Date
                        </p>
                        <input
                          type="date"
                          className="insertCommonInputFieldCalls"
                          name="toDate"
                          value={meetingInfo.toDate}
                          onChange={handleChangeMeetings}
                          min={new Date().toISOString().split("T")[0]}
                          max={
                            new Date(
                              new Date().getFullYear() + 3,
                              new Date().getMonth(),
                              new Date().getDate()
                            )
                              .toISOString()
                              .split("T")[0]
                          }
                        />
                      </div>
                      <div className="w-50">
                        {!isAllDay && (
                          <>
                            <p
                              className="mb-0 mb-1"
                              style={{ color: "#515151" }}
                            >
                              To Time
                            </p>
                            <div className="insertCommonInputFieldCalls d-flex gap-2">
                              <div className="hours_box position-relative">
                                <div
                                  className="cursorPointer"
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    setShowFromTime({
                                      hour: false,
                                      minutes: false,
                                    });
                                    setListVisible(false);
                                    setListVisibleType("");
                                    setShowToTime({
                                      hour: !showToTime.hour,
                                      minutes: false,
                                    });
                                  }}
                                >
                                  {toTimeSet.hour}
                                </div>
                                {showToTime.hour ? (
                                  <div className="hours_options position-absolute d-flex flex-column rounded-1">
                                    {hours.map((hour, index) => {
                                      return (
                                        <span
                                          className="p-1 cursorPointer"
                                          onClick={() => {
                                            setToTimeSet({
                                              hour: hour,
                                              minutes: toTimeSet.minutes,
                                              meridiem: toTimeSet.meridiem,
                                            });
                                          }}
                                          key={index}
                                        >
                                          {hour}
                                        </span>
                                      );
                                    })}
                                  </div>
                                ) : null}
                              </div>
                              <div>:</div>
                              <div className="minutes_box position-relative">
                                <div
                                  className="cursorPointer"
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    setShowFromTime({
                                      hour: false,
                                      minutes: false,
                                    });
                                    setListVisible(false);
                                    setListVisibleType("");
                                    setShowToTime({
                                      hour: false,
                                      minutes: !showToTime.minutes,
                                    });
                                  }}
                                >
                                  {toTimeSet.minutes}
                                </div>
                                {showToTime.minutes ? (
                                  <div className="hours_options position-absolute d-flex flex-column rounded-1">
                                    {" "}
                                    {minutes.map((minute, index) => {
                                      return (
                                        <span
                                          className="p-1 cursorPointer"
                                          onClick={() => {
                                            setToTimeSet({
                                              hour: toTimeSet.hour,
                                              minutes: minute,
                                              meridiem: toTimeSet.meridiem,
                                            });
                                          }}
                                          key={index}
                                        >
                                          {minute}
                                        </span>
                                      );
                                    })}
                                  </div>
                                ) : null}
                              </div>
                              <div>:</div>
                              <div className="hours_box_type">
                                <div
                                  className="cursorPointer"
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    setShowToTime({
                                      hour: false,
                                      minutes: false,
                                    });
                                    setToTimeSet({
                                      hour: toTimeSet.hour,
                                      minutes: toTimeSet.minutes,
                                      meridiem:
                                        toTimeSet.meridiem === "AM"
                                          ? "PM"
                                          : "AM",
                                    });
                                  }}
                                >
                                  {toTimeSet.meridiem}
                                </div>
                              </div>
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="inputFieldDivCalls mt-4 gap-3">
                  <div className="inputFieldDivLeftCalls">
                    <p className="mb-0 mb-1" style={{ color: "#515151" }}>
                      Repeat
                    </p>
                    <Select
                      options={repeatEveryOptions}
                      styles={createAccountStyles}
                      value={selectedOptionRepeat}
                      onChange={handleChangeRepeat}
                      isSearchable={false}
                    />
                  </div>

                  <div className="inputFieldDivLeftCalls">
                    {selectedOptionRepeat.value === "Custom" && (
                      <>
                        <p className="mb-0 mb-1" style={{ color: "#515151" }}>
                          Frequency
                        </p>
                        <Select
                          options={FrequencyOptions}
                          styles={createAccountStyles}
                          value={selectedOptionFrequency}
                          onChange={handleChangeFrequency}
                          isSearchable={false}
                        />
                      </>
                    )}
                  </div>
                </div>
                <div className="inputFieldDivCalls mt-4 gap-3">
                  <div className="inputFieldDivLeftCalls">
                    {selectedOptionRepeat.value === "Custom" && (
                      <>
                        <p className="mb-0 mb-1" style={{ color: "#515151" }}>
                          Repeat Every
                        </p>
                        <div className="d-flex">
                          <input
                            type="number"
                            className="insertCommonInputFieldCalls mb-1"
                            name="repeatEveryNum"
                            value={meetingInfo.repeatEveryNum}
                            onChange={handleChangeMeetings}
                          />
                          <div
                            className="m-0"
                            style={{
                              color: "rgba(156, 156, 156, 1)",
                              fontSize: "18px",
                              backgroundColor: "#0000000D",
                              width: "0%",
                              textAlign: "-webkit-center",
                            }}
                          >
                            {/* selectedOptionFrequency */}
                            <p
                              className="ms-2 h-100"
                              style={{
                                alignContent: "center",
                                backgroundColor: "inherit",
                                width: "50px",
                              }}
                            >
                              Days
                            </p>
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                  <div className="inputFieldDivLeftCalls">
                    {selectedOptionRepeat.value === "Custom" && <></>}
                  </div>
                </div>
                {selectedOptionRepeat.value !== "None" && (
                  <>
                    <div className="d-flex justify-content-between pt-3 pb-2 rounded-2 relative">
                      <h4 className="commonTextColor">End</h4>
                    </div>
                    <div className="columns_container pt-1 pb-1 overflow-y-visible">
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="flexRadioDefault"
                          id="flexRadioDefault1"
                          value="never"
                          checked={selectedOptionRadio === "never"}
                          onChange={handleRadioChange}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="flexRadioDefault1"
                        >
                          Never
                        </label>
                      </div>
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="flexRadioDefault"
                          id="flexRadioDefault2"
                          value="after"
                          checked={selectedOptionRadio === "after"}
                          onChange={handleRadioChange}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="flexRadioDefault2"
                        >
                          After{" "}
                          <input
                            type="text"
                            maxLength={2}
                            style={{
                              background: "rgba(185, 185, 185, 0.25)",
                              border: "None",
                              width: "20px",
                            }}
                            name="afterTimes"
                            value={meetingInfo.afterTimes}
                            onChange={handleChangeMeetings}
                          />{" "}
                          times
                        </label>
                      </div>
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="flexRadioDefault"
                          id="flexRadioDefault3"
                          value="on"
                          checked={selectedOptionRadio === "on"}
                          onChange={handleRadioChange}
                        />
                        <p>
                          On{" "}
                          <span
                            onClick={handleDateTextClick}
                            style={{ cursor: "pointer" }}
                          >
                            {selectedDate
                              ? formatDate(selectedDate)
                              : " (Click to select date)"}
                          </span>
                        </p>
                        {showDateInput && (
                          <input
                            type="date"
                            value={selectedDate || ""}
                            onChange={handleDateInputChange}
                          />
                        )}
                      </div>
                    </div>{" "}
                    <div className="inputFieldDivCalls mt-0 gap-3">
                      <div className="inputFieldDivLeftCalls d-flex">
                        <input type="checkbox" className="me-3" />
                        <p className="mb-0 mb-1" style={{ color: "#515151" }}>
                          Send Seperate invites for each meeting.
                        </p>
                      </div>
                    </div>
                  </>
                )}
              </div>
              <aside className="float-end mt-2">
                <div className="d-flex align-items-center gap-2 px-4 pt-2 pb-3 mb-3">
                  <button
                    className="cancel_btn bg-light rounded-2"
                    style={{ width: "122px", height: "40px" }}
                    onClick={() => {
                      setShowModalSubType("");
                      setShowModalType("ScheduleCall");
                    }}
                  >
                    Cancel
                  </button>
                  <button
                    className="save_btn py-2 border border-0 rounded-2"
                    style={{ width: "122px" }}
                    onClick={() => {
                      setShowModalSubType("");
                      setShowModalType("ScheduleCall");
                      handleDoneClick();
                    }}
                  >
                    Done
                  </button>
                </div>
              </aside>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        size="md"
        show={overlapModal}
        // id="changeOwnerModal"
        aria-labelledby="example-custom-modal-styling-title"
        className="withdrw_modal justify-content-center align-items-center w-100"
      >
        <Modal.Body style={{ padding: "8px 25px 10px 10px" }}>
          <div className="d-flex flex-column pt-1">
            <div
              className="w-100 ms-2 me-3 my-2 px-2 bg-white px-1 py-1 d-flex flex-column recordContainer"
              style={{
                height: "100px",
                overflowY: "hidden",
                color: "black",
                border: "none",
              }}
            >
              <p>{overlapMessage}</p>
              <p>Are you Sure you want to create this meeting anyway?</p>
            </div>
            <aside
              className="float-end mt-2  bg-white "
              style={{ marginBottom: "12px" }}
            >
              <div
                className="d-flex align-items-center gap-3 bg-white "
                style={{ flexFlow: "row-reverse" }}
              >
                <button
                  className="save_btn"
                  style={{
                    height: "35px",
                    border: "none",
                    width: "100px",
                    borderRadius: "5px",
                  }}
                  onClick={() => {
                    handleInsertMeetings("SAVE");
                  }}
                  disabled={createLoader}
                >
                  {createLoader ? (
                    <Lottie
                      options={defaultOptions("white")}
                      height="24px"
                      width="36px"
                    />
                  ) : (
                    "Create"
                  )}
                </button>
                <button
                  className="cancel_btn bg-light"
                  style={{
                    height: "32.5px",
                    width: "100px",
                    borderRadius: "5px",
                  }}
                  onClick={() => setOverlapModal(false)}
                >
                  Cancel
                </button>
              </div>
            </aside>
          </div>
        </Modal.Body>
      </Modal>
      <LeadOwnerModal
        isLeadOwnerModal={isLeadOwnerModal}
        setIsLeadOwnerModal={setIsLeadOwnerModal}
        onSelectedLeadChange={handleSelectedLeadChange}
        defaultUser={selectedOwner}
      />
      <ContactListModal
        isContactListModal={isContactListModal}
        setIsContactListModal={setIsContactListModal}
        onSelectedLeadChange={handleSelectedContactListChange}
        defaultUser={selectedContact}
        allContactDetails={contactList}
      />
      <LeadListModal
        isLeadListModal={isLeadListModal}
        setIsLeadListModal={setIsLeadListModal}
        onSelectedLeadChange={handleSelectedLeadListChange}
        defaultUser={selectedLeadItem}
        leadDetails={leadList}
      />
      <ChangeOwnerModal
        modalType={showOwnerModalType}
        setShowModalType={setShowOwnerModalType}
        selectedOwnerItem={selectedOwner}
        handleChangeOwnerInputClick={handleChangeOwnerInputClick}
        setSelectedOwnerItem={setSelectedOwner}
        isLeadOwnerModal={isLeadOwnerModal}
        setIsLeadOwnerModal={setIsLeadOwnerModal}
        isChangeOwnerList={isChangeOwnerList}
        userList={userList}
        handleChangeOwnerItemClick={handleChangeOwnerItemClick}
        emailNotification={emailNotification}
        setEmailNotification={setEmailNotification}
        handleEditClick={handleChangeMeetings}
        handleUpdateClick={handleCheckMeeting}
        setUpdatedFields={setMeetingInfo}
        existingContact={editPurchaseOverview}
        handleUpdateContact={handleCheckMeeting}
        onSelectedChangeOwner={handleSelectedLeadChange}
      />
      <Modal
        show={[
          "delete",
          "Mass Delete",
          "Mass Update",
          "Mass Transfer",
        ].includes(modalType)}
        aria-labelledby="contained-modal-title-vcenter"
        onClick={(e) => e.stopPropagation()}
        // size={showModalType !== "delete" ? "md" : "lg"}
      >
        <Modal.Header
          className=" border-0 mb-0 d-flex flex-column align-items-start"
          style={{ backgroundColor: modalType !== "delete" && "#F2F2F2" }}
        >
          {modalType !== "delete" && (
            <div
              className={`mass_heading fs-4 ${
                modalType !== "Mass Delete" && "ps-4"
              }`}
            >
              {modalType}
            </div>
          )}
          {modalType === "delete" && (
            <div className="fs-5" style={{ color: "#3F7DFF" }}>
              Are you sure you want to delete $
              {meetingOverviewDetails?.callOwner}?
            </div>
          )}
        </Modal.Header>
        <Modal.Body className="ModalBody">
          {modalType === "delete" ? (
            <>
              <div className="d-flex gap-2 pb-2 align-items-center">
                <span className="fw-bold">Note:</span>
                <span className="small pt-1">
                  Any associated activities, visits, drafts will also be
                  deleted.
                </span>
              </div>
            </>
          ) : modalType === "Mass Delete" ? (
            <div className="fs-6 pt-1 pb-2">
              Are you sure you want to delete selected item(s)?
            </div>
          ) : modalType === "Mass Update" ? (
            <div className="d-flex gap-2 mb-3 mt-2 align-items-center w-100">
              <div className="w-100">
                <div className="d-flex flex-column ps-1 align-items-center mx-4">
                  <label className="text-start w-100 opacity-75">
                    Select a field
                  </label>
                  <div
                    className="position-relative w-100 rounded-1"
                    style={{ border: "1px solid rgba(185, 185, 185, 0.5)" }}
                  >
                    <div
                      onClick={(e) => {
                        e.stopPropagation();
                        setListVisible(!listVisible);
                        setModuleListVisible(false);
                      }}
                      className={`cursorPointer d-flex justify-content-between w-100 my-1 p-1 border-0`}
                    >
                      <span className="">{selectedObj.name}</span>
                      <img
                        src={listVisible ? up : down}
                        alt="img"
                        height={20}
                        width={20}
                        className="pt-1"
                      />
                    </div>
                    {listVisible && (
                      <div
                        className="position-absolute field_container w-100"
                        style={{ height: "max-content" }}
                        onClick={(e) => {
                          e.stopPropagation();
                        }}
                      >
                        <div className="">
                          <div className="w-100 rounded-2">
                            <div
                              className="border border-1 field_container_dropdown rounded-1"
                              style={{ height: "max-content" }}
                            >
                              {showSearchFields
                                .sort((a, b) => a.name.localeCompare(b.name))
                                .map(
                                  (field) =>
                                    ![
                                      "Quotes Owner",
                                      "Deal Name",
                                      "Account Name",
                                      "Contact Name",
                                      "Valid Until",
                                      "Hash",
                                    ].includes(field.name) && (
                                      <div
                                        key={field.name}
                                        className="d-flex gap-2 p-2 cursorPointer rounded-2 align-items-center"
                                        onClick={() => {
                                          // setClickedDropdownObj({
                                          //   label: "",
                                          //   value: "",
                                          // });
                                          setMassUpdateValue("");
                                          setSelectedObj(field);
                                          setListVisible(false);
                                        }}
                                      >
                                        <span className="ps-2">
                                          {field.name}
                                        </span>
                                      </div>
                                    )
                                )}
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="w-100 mx-auto d-flex justify-content-center mt-4">
                    <div className="w-100">
                      <label className="opacity-75" htmlFor="massValue">
                        Enter value
                      </label>
                      <div
                        className="position-relative w-100 border rounded-1"
                        style={{ border: "1px solid rgba(185, 185, 185, 0.5)" }}
                      >
                        {
                          <input
                            className="w-100 border-0 py-2 rounded-1"
                            type="text"
                            value={massUpdateValue}
                            onChange={(e) => {
                              setMassUpdateValue(e.target.value);
                            }}
                            style={{ outline: "none" }}
                            id="massValue"
                          />
                        }
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="w-100 px-4 py-2 mb-1">
              <label className="text-start w-100 opacity-75">Transfer To</label>
              <div
                className="d-flex input-with-clear position-relative mx-auto"
                onClick={(e) => {
                  // setIsLeadForm(true);
                  handleInputClick(e);
                }}
              >
                <input
                  type="text"
                  className="ps-3 w-100 cursorPointer py-2"
                  value={selectedOwner?.name}
                  readOnly
                />
                <span
                  className="position-absolute h-100 mass_transfer_user pt-1"
                  style={{ right: "0.1rem" }}
                >
                  <span
                    className="cursorPointer px-1"
                    onClick={() => setIsLeadOwnerModal(!isLeadOwnerModal)}
                  >
                    <img
                      src={userSearch}
                      alt="img"
                      width={20}
                      height={20}
                      className="ms-2 mt-2 me-2"
                    />
                  </span>
                </span>
              </div>
            </div>
          )}
          <div className={`pt-4  ${modalType !== "delete" && "pe-3"}`}>
            <div className="d-flex justify-content-end w-100 gap-4 pe-2">
              <button
                className="cancelButton px-4"
                onClick={() => {
                  setShowModalType("");
                  setSelectedCheckboxes([]);
                  setShowSearchFields(massColumn);
                }}
              >
                Cancel
              </button>
              <button
                className="createContactButtonSave"
                onClick={() => {
                  if (modalType === "delete") {
                    // deleteProductDetails();
                  } else {
                    //  else if (showModalType === "Mass Delete") {
                    //   deleteUpdateTransferMass();
                    // } else {
                    deleteUpdateTransferMass();
                  }
                }}
              >
                {modalType === "Mass Update"
                  ? "Update"
                  : modalType === "Mass Transfer"
                  ? "Done"
                  : "Delete"}
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <NotificationContainer />
    </div>
  );
}

export default Meetings;

import React from "react";
import { Dropdown, DropdownButton } from "react-bootstrap";
import newsearch from "../../Assets/newSearch.svg";
import Select from "react-select";

const MassUpdate = ({
  dropdownAllDetails,
  setSearchTerm,
  searchTerm,
  filteredOptions,
  setMassVal,
  setClickedDropdownObj,
  setIsFieldSelected,
  setSelectDropdownAllDetails,
  clickedDropdownObj,
  glAccountOptions,
  fieldDropdownStyles,
  massVal,
  validatePhoneNumber,
  handleUpdateMassDetails,
  isFieldSelected,
  handleClose,
}) => {
  return (
    <div className="ModalBody p-4">
      <div className="mb-3">
        <label className="form-label">Select a field</label>
        <Dropdown>
          <DropdownButton
            id="mass-dropdown"
            title={
              <span className="dropdown-title">{dropdownAllDetails.name}</span>
            }
          >
            <div className="px-2 py-1 position-relative">
              <input
                id="selectField"
                type="text"
                className="rounded-2 w-100 pe-4"
                placeholder="Search"
                onChange={(e) => setSearchTerm(e.target.value)}
                value={searchTerm}
              />
              <img
                className="position-absolute top-50 translate-middle-y newSearchIcon"
                src={newsearch}
                alt="searchIcon"
              />
            </div>
            <hr className="my-1" />
            <div className="dropdown-container-mass px-2">
              {filteredOptions
                .filter((option) => !["Hash"].includes(option.name))

                .map((option, index) => (
                  <Dropdown.Item
                    key={index}
                    eventKey={option.name}
                    onClick={() => {
                      setMassVal("");
                      setClickedDropdownObj({
                        label: "",
                        value: "",
                      });
                      setIsFieldSelected(true);
                      setSelectDropdownAllDetails(option); // Set both name and value
                    }}
                  >
                    {option.name}
                  </Dropdown.Item>
                ))}
            </div>
          </DropdownButton>
        </Dropdown>
      </div>
      <div className="mb-4">
        <label className="form-label" htmlFor="massValue">
          Enter value
        </label>
        <div className="position-relative w-100 border rounded-1">
          {["GL Account"].includes(dropdownAllDetails.name) ? (
            <Select
              value={clickedDropdownObj}
              menuPlacement="auto"
              onChange={(selectedOption) => {
                setClickedDropdownObj(selectedOption);
                setMassVal(selectedOption.value);
                // setIsValueEntered(
                //   selectedOption.value.trim() !== ""
                // );
              }}
              options={glAccountOptions}
              styles={fieldDropdownStyles}
              isSearchable={false}
            />
          ) : (
            <input
              className="w-100 border-0 py-2 rounded-1 inputEnterField"
              type="text"
              value={massVal}
              // onChange={(e) => {
              //   setMassVal(e.target.value && e.target.value.trimStart())
              //   setIsValueEntered(e.target.value.trim() !== "");

              // }
              // }
              onChange={(e) => {
                const value = e.target.value.trimStart();
                if (dropdownAllDetails.name === "Phone") {
                  const validatedValue = validatePhoneNumber(value);
                  setMassVal(validatedValue);
                  // setIsValueEntered(validatedValue.trim() !== "");
                } else {
                  setMassVal(value);
                  // setIsValueEntered(value.trim() !== "");
                }
              }}
              style={{ outline: "none" }}
              id="massValue"
            />
          )}
        </div>
      </div>
      <div className="d-flex justify-content-end gap-3">
        <button className="cancelButton px-4" onClick={handleClose}>
          Cancel
        </button>
        <button
          className="createContactButtonSave"
          onClick={handleUpdateMassDetails}
          disabled={!isFieldSelected || !massVal}
          style={{
            opacity: !isFieldSelected || !massVal ? 0.5 : 1,
          }}
        >
          Update
        </button>
      </div>
    </div>
  );
};

export default MassUpdate;

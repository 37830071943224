/* eslint-disable */
import React, { useEffect, useState } from "react";
import Select from "react-select";
import groupIcon from "../../Assets/elements (4).svg";
import down from "../../Assets/Down31.svg";
import up from "../../Assets/Up.svg";
import userSearch from "../../Assets/user-search-01.svg";
import { useSelector } from "react-redux";
import accountsIcon from "../../Assets/De-Active/Account.svg";
import { URI } from "../../constants";
import axios from "axios";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import Loader from "../../common/commonRelatedList/Loader";
import noDataAccount from "../../Assets/Marketing-bro (1) 1.svg";
import {
  pageOption,
  massOptions,
  contactColumn,
  dealsColumn,
  leadsColumn,
} from "../../common/CommonData";
import Share from "../../Assets/ShareIcon.svg";
import Pagination from "../../common/Pagination";
import tableFilterIcon from "../../Assets/filterIcon.svg";
import arrowUp from "../../Assets/square_arrow_up.svg";
import arrowDown from "../../Assets/square_arrow_down.svg";
import pin from "../../Assets/Pin_column.svg";
import unPin from "../../Assets/Unpin.svg";
import cancel from "../../Assets/cancel_circle.svg";
import filterLine from "../../Assets/filterLine.svg";
import searchIcon from "../../Assets/search.svg";
import filterMenu from "../../Assets/filterMenu.svg";
import { EXPLORER_URL } from "../../constants/DataConst.js";
import Close from "../../Assets/Clone.svg";
import Delete from "../../Assets/DeleteIconNew.svg";
import hierachy from "../../Assets/hierarchy.svg";
import history from "../../Assets/history.svg";
import groupIcon2 from "../../Assets/Group 74.svg";
import NoteBook from "../../Assets/notebook-01.svg";
import openActivityIcon from "../../Assets/openActivity.svg";
import closeActivityIcon from "../../Assets/closeActivity.svg";
import Note from "../../common/commonRelatedList/Note";
import CloseActivity from "../../common/commonRelatedList/CloseActivity";
import OpenActivity from "../../common/commonRelatedList/OpenActivity";
import DealsCommonTable from "../../common/DealsCommonTable";
import { Modal } from "react-bootstrap";
import noteIcon from "../../Assets/notebook-01.svg";
import leadListIcon from "../../Assets/Leadlist.svg";
import DealListIcon from "../../Assets/DealsList.svg";
import LeadOwnerModal from "../../common/LeadOwnerModal";
import ChangeOwnerModal from "../../common/commonRelatedList/ChangeOwnerModal";
import EditableInput from "../../common/EditableInput";
import updateIcon from "../../Assets/Group 71.svg";
import cancelIcon from "../../Assets/Group 72.svg";
import AccountListModal from "../../common/AccountListModal";
import ShareModal from "../../common/ShareModal";
import {
  validateCampaignName,
  removeExtraSpaces,
  validateCalcField,
  customCommonStyles,
} from "../../common/CommonComponent.js";
import {
  leftArrow,
  rightAngleArrow,
  leftAngleArrow,
  tick,
  expandArrow,
  shrinkArrow,
} from "../../common/commonSVGs/commonSVGIcons.js";
import LeadList from "../../common/commonRelatedList/LeadList.js";
import ContactList from "../../common/commonRelatedList/ContactList.js";
import Lottie from "react-lottie";
import { defaultOptions } from "../../utils/defaultOptions.js";
import useScrollToUndo from "../../hooks/useScrollToUndo.js.js";
import getHeaders from "../../common/ApiHeader.js";
import { handleApiError } from "../../utils/ApiUtils";
import { useNavigate } from "react-router-dom";
import SearchIcon from "../../Assets/newSearch.svg";
import logoConvert from "../../Assets/logoConvert.svg";

function Campaigns({
  setCurrentView,
  setInnerPage,
  innerPage,
  currentView,
  permissionList,
  notePermission,
  setPastViewInfo,
  pastViewInfo,
  setSelectedCampaignName,
  setSelectedCampaignId,
  selectedCampaignId,
  storeModuleId,
}) {
  const navigate = useNavigate();
  const [selectedItem, setSelectedItem] = useState(null);
  const [listVisible, setListVisible] = useState(false);
  const [existingCampaign, setExistingCampaign] = useState(false);
  const [existingClone, setExistingClone] = useState(false);
  const [showModalType, setShowModalType] = useState("");
  const [selectedCampaignItem, setSelectedCampaignItem] = useState([]);
  const [parentListVisible, setParentListVisible] = useState(false);
  const [isCampaignListModal, setIsCampaignListModal] = useState(false);
  const [allList, setAllList] = useState([]);
  const [showOptionsCampaign, setShowOptionsCampaign] = useState(false);
  const reduxStore = useSelector((state) => state.reduxState);
  const [isShrinked, setIsShrinked] = useState(false);
  const [leadPageView, setLeadPageView] = useState("overview");
  const { loginUserId } = reduxStore;
  const [clickedDropdownObj, setClickedDropdownObj] = useState({
    label: "",
    value: "",
  });
  const [openActivities, setOpenActivities] = useState([]);
  const [closeActivities, setCloseActivities] = useState([]);
  const [timeLineDataList, setTimeLineDataList] = useState([]);
  const [isLeadOwnerModal, setIsLeadOwnerModal] = useState(false);

  const [showHeadingsContact, setShowHeadingsContact] = useState(false);
  const [selectedHeadingsContact, setSelectedHeadingsContact] =
    useState(contactColumn);
  const [savingHeadingsContact, setSavingHeadingsContact] =
    useState(contactColumn);
  const [filterContactDetails, setFilterContactDetails] = useState([]);

  const [showHeadingsLead, setShowHeadingsLead] = useState(false);
  const [selectedHeadingsLead, setSelectedHeadingsLead] = useState(leadsColumn);
  const [savingHeadingsLead, setSavingHeadingsLead] = useState(leadsColumn);
  const [filterLeadDetails, setFilterLeadDetails] = useState([]);
  const [tooltipContent, setTooltipContent] = useState("");
  const [showTooltipExpectedRevenue, setShowTooltipExpectedRevenue] =
    useState(false);
  const [showTooltipBudgetedCost, setShowTooltipBudgetedCost] = useState(false);
  const [showTooltipActualCost, setShowTooltipActualCost] = useState(false);

  const [showHeadingsDeals, setShowHeadingsDeals] = useState(false);
  const [selectedHeadingsDeals, setSelectedHeadingsDeals] =
    useState(dealsColumn);
  const [savingHeadingsDeals, setSavingHeadingsDeals] = useState(dealsColumn);
  const [filterDealDetails, setFilterDealDetails] = useState([]);
  const [selectedDealsId, setSelectedDealsId] = useState([]);
  const [showAccessModal, setShowAccessModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);

  const [campaignInfoDetails, setCampaignInfoDetails] = useState({});
  const [isOwnerSelect, setIsOwnerSelect] = useState(false);
  const [mandatoryMsg, setMandatoryMsg] = useState("");
  const [dateMsg, setDateMsg] = useState("");
  const [expectedRevenueMsg, setExpectedRevenueMsg] = useState("");
  const [budgetCostMsg, setBudgetCostMsg] = useState("");
  const [actualMsg, setActualMsg] = useState("");
  const [leadList, setLeadList] = useState([]);
  const [contactList, setContactList] = useState([]);
  const [selectedLeadId, setSelectedLeadId] = useState([]);
  const [selectedContactId, setSelectedContactId] = useState([]);
  const [openActivitiesCalls, setOpenActivitiesCalls] = useState([]);
  const [openActivitiesMeetings, setOpenActivitiesMeetings] = useState([]);
  const [openActivitiesTasks, setOpenActivitiesTasks] = useState([]);
  const [closedActivitiesCall, setClosedActivitiesCall] = useState([]);
  const [closedActivitiesMeetings, setClosedActivitiesMeetings] = useState([]);
  const [closedActivitiesTasks, setClosedActivitiesTasks] = useState([]);
  const [modalCampaignsDetails, setModalCampaignDetails] = useState([]);
  const [noteDeleteConfirmation, setNoteDeleteConfirmation] = useState(false);
  const [shareModal, setShareModal] = useState(false);
  const [shareInfoModal, setShareInfoModal] = useState(false);

  console.log("closedActivitiesTasks",closedActivitiesTasks)
  useScrollToUndo(pastViewInfo, setPastViewInfo); // for scroll to previously clicked section

  const handledeleteModule = async (deleteProdId, assignModuleName) => {
    const requestBody = {
      loginUserId: loginUserId,
      moduleName: "CAMPAIGNS",
      moduleId: campaignId,
      assignModuleName: assignModuleName,
      assignModuleId: deleteProdId,
    };

    try {
      const headersResult = getHeaders(true);

      // Check if headers result indicates an error
      if (headersResult.error) {
        navigate("../"); // Redirect to login if token is missing
        return;
      }

      const response = await axios.post(URI.deleteRole, requestBody, {
        headers: headersResult,
      });
      if (response.data.status === 200) {
        NotificationManager.success("", response.data.message, 5000);
        campaignOverviewDetails(campaignId);
      } else {
        NotificationManager.error("", response.data.message, 5000);
      }
    } catch (error) {
      handleApiError(error, navigate);
    }
  };

  const handleAssignModule = async (selectedIds, assignModuleName) => {
    const moduleIdList = selectedIds.map((id) => ({ moduleId: id }));

    if (moduleIdList.length < 1) {
      return NotificationManager.error("", "Please select an item", 5000);
    }

    const requestBody = {
      loginUserId: loginUserId,
      moduleName: "CAMPAIGNS",
      moduleId: campaignId,
      assignModuleName: assignModuleName,
      moduleIdList,
    };

    setApiLoader(true);

    try {
      const headersResult = getHeaders(true);

      // Check if headers result indicates an error
      if (headersResult.error) {
        navigate("../"); // Redirect to login if token is missing
        return;
      }
      const response = await axios.post(URI.assignRole, requestBody, {
        headers: headersResult,
      });
      if (response.data.status === 200) {
        NotificationManager.success("", response.data.message, 5000);
        campaignOverviewDetails(campaignId);
      } else {
        NotificationManager.error("", response.data.message, 5000);
      }
    } catch (error) {
      handleApiError(error, navigate);
    } finally {
      setApiLoader(false);
    }
  };

  const handleAssignDealsModule = (selectedInvoiceId) => {
    handleAssignModule(selectedInvoiceId, "DEALS");
  };

  const userList = JSON.parse(sessionStorage.getItem("userList")) || [];

  useEffect(() => {
    const foundItem = userList.find((item) => item.userId === loginUserId);
    if (foundItem) {
      setSelectedItem(foundItem);
    }
  }, [loginUserId]);

  useEffect(() => {
    if (selectedCampaignId && innerPage === "overview") {
      campaignOverviewDetails(selectedCampaignId);
      setCampaignId(selectedCampaignId);
    }
  }, []);

  useEffect(() => {
    if (storeModuleId) {
      campaignOverviewDetails(storeModuleId);
      setCampaignId(storeModuleId);
    }
  }, []);

  const options = reduxStore.filterOptions.campaign;

  const campaignStatusOptions = [
    { value: "None", label: "None" },
    { value: "Planning", label: "Planning" },
    { value: "Active", label: "Active" },
    { value: "Inactive", label: "Inactive" },
    { value: "Complete", label: "Complete" },
  ];
  const campaignTypeOptions = [
    // { value: "None", label: "None" },
    { value: "Conference", label: "Conference" },
    { value: "Webinar", label: "Webinar" },
    { value: "Trade Show", label: "Trade Show" },
    { value: "Public Relations", label: "Public Relations" },
    { value: "Partners", label: "Partners" },
    { value: "Referral Program", label: "Referral Program" },
    { value: "Advertisement", label: "Advertisement" },
    { value: "Banner Ads", label: "Banner Ads" },
    { value: "Direct mail", label: "Direct mail" },
    { value: "Email", label: "Email" },
    { value: "Telemarketing", label: "Telemarketing" },
    { value: "Others", label: "Others" },
  ];

  const fieldDropdowns = [
    {
      name: "Campaign Owner",
      isShowed: false,
      type: "select",
    },
    {
      name: "Campaign Name",
      isShowed: true,
      type: "input",
      value: "campaignName",
    },
    {
      name: "Expected Revenue",
      isShowed: false,
      type: "input",
    },
    {
      name: "Budgeted Cost",
      isShowed: false,
      type: "input",
    },
    {
      name: "Status",
      isShowed: true,
      type: "dropdown",
      value: "status",
    },
    {
      name: "Type",
      isShowed: true,
      type: "dropdown",
      value: "type",
    },
    {
      name: "Expected Response",
      isShowed: false,
      type: "input",
    },
    {
      name: "Numbers Sent",
      isShowed: false,
      type: "input",
    },
    {
      name: "Created By",
      isShowed: false,
      type: "readOnly",
    },
    {
      name: "Modified By",
      isShowed: false,
      type: "readOnly",
    },
    {
      name: "Description",
      isShowed: false,
      type: "input",
    },
    {
      name: "Actual Cost",
      isShowed: true,
      type: "input",
    },
    {
      name: "End Date",
      isShowed: false,
      type: "date",
      value: "endDate",
    },
    {
      name: "Start Date",
      isShowed: false,
      type: "date",
      value: "startDate",
    },
  ];

  const [selectedObj, setSelectedObj] = useState(fieldDropdowns[1]);

  const fieldIndividualDropdowns = [
    {
      name: "Campaign Owner",
      type: "select",
      value: "campaignOwner",
    },
    {
      name: "Expected Revenue",
      type: "input",
      value: "expectedRevenueInfo",
    },
    {
      name: "Budgeted Cost",
      type: "input",
      value: "budgetedCostInfo",
    },
    {
      name: "Status",
      type: "dropdown",
      value: "campaignInfoStatus",
    },
    {
      name: "Type",
      type: "dropdown",
      value: "campaignInfoType",
    },
    {
      name: "Expected Response",
      type: "input",
      value: "expectedResponseInfo",
    },
    {
      name: "Numbers Sent",
      type: "input",
      value: "numbersSentInfo",
    },
    {
      name: "Created By",
      type: "readOnly",
      value: "createdBy",
    },
    {
      name: "Modified By",
      type: "readOnly",
      value: "modifiedBy",
    },
    // {
    //   name: "Description",
    //   type: "input",
    //   value: "description",
    // },
    {
      name: "Actual Cost",
      type: "input",
      value: "actualCostInfo",
    },
    { name: "Start Date", type: "input", value: "startDateInfo" },
    { name: "End Date", type: "input", value: "endDateInfo" },
  ];

  const campaignColumn = [
    {
      name: "Campaign Name",
      isChecked: true,
      value: "campaignName",
    },
    {
      name: "Actual Cost",
      isChecked: false,
      value: "actualCost",
    },
    {
      name: "Budgeted Cost",
      isChecked: false,
      value: "budgetedCost",
    },
    {
      name: "Campaign Owner",
      isChecked: true,
      value: "campaignOwner",
    },
    {
      name: "Campaign Status",
      isChecked: true,
      value: "campaignStatus",
    },
    {
      name: "Campaign Type",
      isChecked: true,
      value: "campaignType",
    },
    {
      name: "Created By",
      isChecked: false,
      value: "createdBy",
    },
    {
      name: "End Date",
      isChecked: false,
      value: "endDate",
    },
    {
      name: "Expected Response",
      isChecked: false,
      value: "expectedResponse",
    },
    {
      name: "Expected Revenue",
      isChecked: false,
      value: "expectedRevenue",
    },
    {
      name: "Layout",
      isChecked: false,
      value: "layout",
    },
    {
      name: "Numbers Sent",
      isChecked: false,
      value: "numbersSent",
    },
    {
      name: "Start Date",
      isChecked: false,
      value: "startDate",
    },
    {
      name: "Hash",
      isChecked: true,
      value: "transHash",
    },
  ];

  const convertDateFormat = (dateString) => {
    if (!dateString) return "";
    const [day, month, year] = dateString.split("-");
    return `${year}-${month}-${day}`;
  };

  const convertToDisplayFormat = (dateString) => {
    if (!dateString) return "";
    const [year, month, day] = dateString.split("-");
    return `${day}-${month}-${year}`;
  };
  const [searchField, setSearchField] = useState("");
  const [fieldLabels, setFieldLabels] = useState(fieldDropdowns);
  const [selectedField, setSelectedField] = useState("");
  const [showSearchFields, setShowSearchFields] = useState(fieldDropdowns);
  const [selectedHeadings, setSelectedHeadings] = useState(campaignColumn);
  const [savingHeadings, setSavingHeadings] = useState(campaignColumn);
  const [selectedOption, setSelectedOption] = useState(options[0]);
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [isLoading, setIsLoading] = useState(true);
  const [filteredLeadDetails, setFilteredLeadDetails] = useState([]);
  const [totalItems, setTotalItems] = useState(0);
  const [selectedCheckboxes, setSelectedCheckboxes] = useState([]);
  const [massUpdateValue, setMassUpdateValue] = useState("");
  const [showHeadings, setShowHeadings] = useState(false);
  const [showSelectedFilter, setShowSelectedFilter] = useState("");
  const [filterType, setFilterType] = useState("");
  const [pinnedColumn, setPinnedColumn] = useState("Campaign Name");
  const [searchColumn, setSearchColumn] = useState("");
  const [campaignId, setCampaignId] = useState("");
  const totalPages = Math.ceil(Number(totalItems) / perPage);
  const [updateButtonLabel, setUpdateButtonLabel] = useState("Save");
  const [selectedLeadIndex, setSelectedLeadIndex] = useState(0);
  const [note, setNote] = useState("");
  const [addTitle, setAddTitle] = useState("");
  const [showButtons, setShowButtons] = useState(false);
  const [selectedNoteFiles, setSelectedNoteFiles] = useState([]);
  const [showTitleNote, setShowTitleNote] = useState(false);
  const [noteTitle, setNoteTitle] = useState("");
  const [noteInfo, setNoteInfo] = useState([]);
  const [selectRecent, setSelectRecent] = useState("ALL");
  const isForHandler = false;
  const [selectedHandlerItem, setSelectedHandlerItem] = useState(null);
  const [isChangeOwnerList, setIsChangeOwnerList] = useState(false);
  const [updateNotes, setUpdateNotes] = useState({
    description: "",
    title: "",
    noteImageList: [],
  });
  const [editNote, setEditNote] = useState(false);
  const [emailNotification, setEmailNotification] = useState(false);
  const [apiLoader, setApiLoader] = useState(false);
  const [deletedImg, setDeletedImg] = useState("");
  const [shareMemberIds, setShareMemberIds] = useState([]);
  const [shareUserList, setShareUserList] = useState([]);
  const[sharePublicList,setSharePublicList]=useState([])
  const [shareRoleList, setShareRoleList] = useState([]);
  const [shareGroupList, setShareGroupList] = useState([]);

  const handleChangeNoteTitle = (e) => {
    const { name, value } = e.target;
    if (value.length === 1 && value[0] === ' ') {
      return; 
    }
    if (editNote) {
      setUpdateNotes((prevLeadInfo) => ({
        ...prevLeadInfo,
        [name]: value,
      }));
    } else {
      setAddTitle(value);
    }
  };

  const handleChildDataChange = (dataFromChild) => {
    let deletedImg = dataFromChild.toString("");
    setDeletedImg(deletedImg);
  };
  const handleChangeNoteDescription = (e) => {
    const { name, value } = e.target;
    if (value.length === 1 && value[0] === ' ') {
      return; 
    }
    if (editNote) {
      setUpdateNotes((prevLeadInfo) => ({
        ...prevLeadInfo,
        [name]: value,
      }));
    } else {
      setNote(value); // Directly set the input value
    }
  };
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  React.useEffect(() => {
    getAllCampaignDetails(selectedOption);
  }, [currentPage, perPage]);

  const sortedColumnsData = (type, headingName) => {
    const sortedData = [...allList];

    if (type === "asc") {
      sortedData.sort((a, b) => {
        if (
          typeof a[headingName] === "string" &&
          typeof b[headingName] === "string"
        ) {
          return a[headingName].localeCompare(b[headingName]);
        }
        return a[headingName] - b[headingName];
      });
    } else if (type === "desc") {
      sortedData.sort((a, b) => {
        if (
          typeof a[headingName] === "string" &&
          typeof b[headingName] === "string"
        ) {
          return b[headingName].localeCompare(a[headingName]);
        }
        return b[headingName] - a[headingName];
      });
    }
    setFilteredLeadDetails(sortedData);
  };

  const [campaignData, setCampaignData] = useState({
    campaignName: "",
    startDate: "",
    endDate: "",
    campaignType: campaignTypeOptions[0],
    campaignStatus: campaignStatusOptions[0],
    expectedRevenue: "",
    budgetedCost: "",
    actualCost: "",
    ExpectedResponse: "",
    numbersSent: "",
    parentCampaign: "",
    description: "",
  });
  const [campaignUpdateData, setCampaignUpdateData] = useState({
    campaignName: "",
    startDate: "",
    endDate: "",
    campaignType: campaignTypeOptions[0],
    campaignStatus: campaignStatusOptions[0],
    expectedRevenue: "",
    budgetedCost: "",
    actualCost: "",
    ExpectedResponse: "",
    numbersSent: "",
    parentCampaign: "",
    description: "",
  });

  const dataReset = () => {
    const campaignData = {
      campaignName: "",
      startDate: "",
      endDate: "",
      campaignType: campaignTypeOptions[0],
      campaignStatus: campaignStatusOptions[0],
      expectedRevenue: "",
      budgetedCost: "",
      actualCost: "",
      ExpectedResponse: "",
      numbersSent: "",
      parentCampaign: "",
      description: "",
    };
    setCampaignData(campaignData);
    setCampaignUpdateData(campaignData);
  };

  const [updatedFields, setUpdatedFields] = useState({
    campaignName: campaignInfoDetails.campaignName,
    startDate: campaignInfoDetails.startDate,
    endDate: campaignInfoDetails.endDate,
    campaignType: campaignInfoDetails.campaignType,
    campaignInfoType: campaignInfoDetails.campaignType,
    campaignStatus: campaignInfoDetails.campaignStatus,
    campaignInfoStatus: campaignInfoDetails.campaignStatus,
    expectedRevenue: campaignInfoDetails.expectedRevenue,
    budgetedCost: campaignInfoDetails.budgetedCost,
    actualCost: campaignInfoDetails.actualCost,
    ExpectedResponse: campaignInfoDetails.ExpectedResponse,
    numbersSent: campaignInfoDetails.numbersSent,
    parentCampaign: campaignInfoDetails.parentCampaign,
    description: campaignInfoDetails.description,
  });

  const [editingFields, setEditingFields] = useState({
    campaignName: false,
    startDate: false,
    endDate: false,
    campaignType: false,
    campaignStatus: false,
    expectedRevenue: false,
    budgetedCost: false,
    actualCost: false,
    ExpectedResponse: false,
    numbersSent: false,
    parentCampaign: false,
    description: false,
  });

  const [showType, setShowType] = useState("");

  // for save and new && save lottie loader
  const [saveSubmit, setSaveSubmit] = useState(false);
  const [saveAndNewSubmit, setSaveAndNewSubmit] = useState(false);
  const [searchCampaign, setSearchCampaign] = useState("");

  const filteredCampigns = modalCampaignsDetails.filter((item) =>
    item.campaignName?.toLowerCase().includes(searchCampaign?.toLowerCase())
  );

  const handleSearchCampaign = (e) => {
    const { value } = e.target;
    if (value.trim() !== "" || value === "") {
      setSearchCampaign(value);
    }
  };

  const handleIndividualInputChange = (field, value) => {
    setUpdatedFields((prevFields) => ({
      ...prevFields,
      [field]: [
        "expectedResponse",
        "expectedResponseInfo",
        "actualCost",
        "actualCostInfo",
        "budgetedCost",
        "budgetedCostInfo",
        "expectedRevenue",
        "expectedRevenueInfo",
        "numbersSent",
        "numbersSentInfo",
      ].includes(field)
        ? value
            .replace(/[^0-9.]/g, "")
            .replace(/(\..*)\./g, "$1")
            .replace(/(\.\d{2})\d+/, "$1")
        : value,
    }));
  };

  const handleEditClick = (field) => {
    if (Object.values(editingFields).some((value) => value)) {
      return;
    }
    setEditingFields((prevFields) => ({
      ...prevFields,
      [field]: true,
    }));
  };

  const handleCancelClick = (field) => {
    setEditingFields((prevFields) => ({
      ...prevFields,
      [field]: false,
    }));
    setUpdatedFields((prevFields) => ({
      ...prevFields,
      [field]: campaignInfoDetails[field],
    }));
  };

  const handleUpdateClick = async (field) => {
    var value;
    var requestBody = {};
    const isObj =
      typeof campaignInfoDetails[field] === "object" &&
      campaignInfoDetails[field] !== null &&
      !Array.isArray(campaignInfoDetails[field]);

    if (
      field === "campaignStatus" ||
      field === "campaignType" ||
      field === "campaignInfoType" ||
      field === "campaignInfoStatus"
    ) {
      value = updatedFields[field].value;
    } else {
      value = updatedFields[field];
    }

    const fieldMappings = {
      campaignInfoType: "campaignType",
      campaignInfoStatus: "campaignStatus",
      startDateInfo: "startDate",
      endDateInfo: "endDate",
      actualCostInfo: "actualCost",
      numbersSentInfo: "numbersSent",
      expectedResponseInfo: "expectedResponse",
      budgetedCostInfo: "budgetedCost",
      expectedRevenueInfo: "expectedRevenue",
    };
    // if (fieldMappings.hasOwnProperty(field)) {
    //   field = fieldMappings[field];
    // } not supported by eslint
    if (Object.hasOwn(fieldMappings, field)) {
      field = fieldMappings[field];
    }
    setApiLoader(true);
    try {
      requestBody = {
        campaignOwnerId: selectedItem?.userId,
        campaignId: campaignId,
        loggedInUserId: loginUserId,
        [field]: value,
      };

      const compareValue = isObj
        ? campaignInfoDetails[field].value
        : campaignInfoDetails[field];

      if (value !== compareValue || field === "emailNotification") {
        const headersResult = getHeaders(true);

        // Check if headers result indicates an error
        if (headersResult.error) {
          navigate("../"); // Redirect to login if token is missing
          return;
        }

        const response = await axios.post(URI.updateAllCampaigns, requestBody, {
          headers: headersResult,
        });
        if (response.data.status === 200) {
          setCampaignInfoDetails((prevDetails) => ({
            ...prevDetails,
            [field]: updatedFields[field],
          }));
          campaignOverviewDetails(campaignId);
          setIsOwnerSelect(false);
          setShowModalType("");
          NotificationManager.success("", response.data.message, 5000);
        } else {
          NotificationManager.error("", response.data.message, 5000);
        }
      }

      const resetFields = Object.fromEntries(
        Object.keys(editingFields).map((key) => [key, false])
      );
      setEditingFields({
        ...resetFields,
      });
      setShowType("");
    } catch (error) {
      handleApiError(error, navigate);
    } finally {
      setApiLoader(false);
    }
  };

  const handleChangeIndDropDown = (selectedOption, field) => {
    setUpdatedFields((prevFields) => ({
      ...prevFields,
      [field]: selectedOption,
    }));
  };

  const handleSelectedLeadChange = (value) => {
    setSelectedItem(value);
    setSelectedHandlerItem(value);
  };

  const handleOwnerInputClick = (e) => {
    e.stopPropagation();
    setListVisible(!listVisible);
    setParentListVisible(false);
  };

  const handleChange = (selectedOption) => {
    getAllCampaignDetails(selectedOption);
  };

  const handleUserItemClick = (item) => {
    setSelectedItem(item);
    setListVisible(false);
  };

  const handleOwnerParentInputClick = (e) => {
    e.stopPropagation();
    setParentListVisible(!parentListVisible);
    setListVisible(false);
  };

  /* eslint-disable no-useless-escape */
  const getFieldname = (value) => {
    let fieldValue = "";
    if (value === "Status") {
      fieldValue = "campaignStatus";
    } else if (value === "Type") {
      fieldValue = "campaignType";
    } else {
      fieldValue = value
        .replace(/\s+/g, "")
        .replace(/^(.)/, (match) => match.toLowerCase());
    }

    return fieldValue;
  };
  /* eslint-enable no-useless-escape */

  const handlePinColumn = (name) => {
    setPinnedColumn(name);
    const updatedColumns = [...selectedHeadings];
    const clickedColumnIndex = updatedColumns.findIndex(
      (column) => column.name === name
    );

    if (clickedColumnIndex !== -1) {
      const clickedColumn = updatedColumns.splice(clickedColumnIndex, 1)[0];
      updatedColumns.unshift(clickedColumn);
      setSelectedHeadings(updatedColumns);
    }
  };

  /* eslint-disable no-useless-escape */
  const sanitizeExpression = (expression) => {
    const match = expression.match(/^\s*-?\d+(\.\d+)?([+\-*\/]-?\d+(\.\d+)?)*/);

    if (match) {
      return match[0].trim();
    } else {
      return "Invalid input";
    }
  };
  /* eslint-enable no-useless-escape */

  const handleBlur = (field) => {
    calculateResult(field);
    hideAllTooltips();
  };

  /* eslint-disable no-useless-escape */
  const handleKeyPress = (event) => {
    const allowedCharacters = /[0-9.\+\-\*\/\(\)]/;
    const char = event.key;
    if (!allowedCharacters.test(char)) {
      event.preventDefault();
    }
  };
  /* eslint-enable no-useless-escape */

  const handleChangeCampaign = (event) => {
    const { name, value } = event.target;
    const dateFields = ["startDate", "endDate"];

    const formattedValue = dateFields.includes(name)
      ? convertToDisplayFormat(value)
      : value;

    if (existingCampaign || existingClone) {
      setCampaignUpdateData((prevData) => ({
        ...prevData,
        [name]: formattedValue,
      }));
    } else {
      setCampaignData((prevData) => ({
        ...prevData,
        [name]: formattedValue,
      }));
    }

    switch (name) {
      case "expectedRevenue":
        handleTooltip(value, setShowTooltipExpectedRevenue);
        break;
      case "budgetedCost":
        handleTooltip(value, setShowTooltipBudgetedCost);
        break;
      case "actualCost":
        handleTooltip(value, setShowTooltipActualCost);
        break;
      default:
        break;
    }
  };

  /* eslint-disable no-useless-escape */
  const handleTooltip = (value, setShowTooltip) => {
    const regex = /^[\d.\+\-\*\/\(\)]+$/;
    if (regex.test(value)) {
      try {
        const previewResult = eval(value);
        setTooltipContent(previewResult.toString());
      } catch (error) {
        setTooltipContent(sanitizeExpression(value));
      }
      setShowTooltip(true);
    } else {
      setShowTooltip(false);
    }
  };

  const calculateResult = (field) => {
    const value = (
      existingCampaign || existingClone
        ? campaignUpdateData[field]
        : campaignData[field]
    ).trim();
    const sanitizedValue = value;
    const regex = /^[\d.\+\-\*\/\(\)]+$/;

    if (regex.test(sanitizedValue)) {
      try {
        const result = eval(sanitizedValue);
        if (existingCampaign || existingClone) {
          setCampaignUpdateData((prevData) => ({
            ...prevData,
            [field]: result.toString(),
          }));
        } else {
          setCampaignData((prevData) => ({
            ...prevData,
            [field]: result.toString(),
          }));
        }
      } catch (error) {
        if (existingCampaign || existingClone) {
          setCampaignUpdateData((prevData) => ({
            ...prevData,
            [field]: sanitizeExpression(value),
          }));
        } else {
          setCampaignData((prevData) => ({
            ...prevData,
            [field]: sanitizeExpression(value),
          }));
        }
      }
    }
  };
  /* eslint-enable no-useless-escape */

  const hideAllTooltips = () => {
    setShowTooltipExpectedRevenue(false);
    setShowTooltipBudgetedCost(false);
    setShowTooltipActualCost(false);
  };

  const tooltipStyles = {
    position: "absolute",
    top: "100%",
    left: "0",
    backgroundColor: "#f9f9f9",
    border: "1px solid #d3d3d3",
    padding: "5px",
    borderRadius: "3px",
    boxShadow: "0 2px 5px rgba(0,0,0,0.2)",
    zIndex: 1000,
    height: "fit-content",
    width: "fit-content",
  };

  const handleChangeSelectDropDown = (selectedOption, field) => {
    if (existingCampaign || existingClone) {
      setCampaignUpdateData((prevLeadInfo) => ({
        ...prevLeadInfo,
        [field]: selectedOption,
      }));
    } else {
      setCampaignData((prevLeadInfo) => ({
        ...prevLeadInfo,
        [field]: selectedOption,
      }));
    }
  };

  const handleChangeOwnerItemClick = (item) => {
    setSelectedItem(item);
    setIsChangeOwnerList(false);
    setIsOwnerSelect(true);
  };

  const handleChangeOwnerInputClick = (e) => {
    e.stopPropagation();
    setIsChangeOwnerList(!isChangeOwnerList);
  };

  const hasPermission = (action) => {
    const permission = permissionList.products.find((p) => p.action === action);
    return permission ? permission.status === "TRUE" : false;
  };

  const handleCreateCampaign = () => {
    if (hasPermission("CREATE")) {
      setCurrentPage(1);
      setPerPage(10);
      setInnerPage("create");
      setExistingCampaign(false);
      setUpdateButtonLabel("Save");
      dataReset();
    } else {
      setShowAccessModal(true);
    }
  };

  const handleEditCampaign = () => {
    if (hasPermission("EDIT")) {
      setExistingCampaign(true);
      setUpdateButtonLabel("Update");
      setInnerPage("create");
      setCampaignUpdateData({ ...campaignInfoDetails });
    } else {
      setShowAccessModal(true);
    }
  };

  const handleCreateCampaignClone = () => {
    if (hasPermission("CREATE")) {
      setInnerPage("clone");
      setShowOptionsCampaign(false);
      setExistingClone(true);
      setCampaignUpdateData({ ...campaignInfoDetails });
      setUpdateButtonLabel("Save");
      setExistingCampaign(false);
    } else {
      setShowAccessModal(true);
    }
  };

  const handleDeleteCampaign = () => {
    if (hasPermission("DELETE")) {
      setDeleteModal(true);
    } else {
      setShowAccessModal(true);
    }
  };

  const deleteUpdateTransferMass = async () => {
    const { value } = selectedObj;
    let url = URI.massDelete;
    const requestBody = {
      loggedInUserId: loginUserId,
      moduleName: "CAMPAIGNS",
      massAction: showModalType === "Mass Delete" ? "DELETE" : "EDIT",
      moduleIdList: selectedCheckboxes,
    };
    if (showModalType === "Mass Update") {
      if (value === "startDate" || value === "endDate") {
        requestBody[value] = convertToDisplayFormat(massUpdateValue);
      } else {
        requestBody[value] = massUpdateValue;
      }
      url = URI.massUpdate;
    } else if (showModalType === "Mass Transfer") {
      requestBody.ownerId = selectedItem.userId;
      url = URI.massTransfer;
    }

    const headersResult = getHeaders(true);

    // Check if headers result indicates an error
    if (headersResult.error) {
      navigate("../"); // Redirect to login if token is missing
      return;
    }

    await axios
      .post(url, requestBody, {
        headers: headersResult,
      })
      .then((response) => {
        if (response.data.status === 200) {
          NotificationManager.success("", response.data.message, 5000);
          getAllCampaignDetails(selectedOption);
          setSelectedCheckboxes([]);
          setInnerPage("list");
          setShowModalType("");
          setShowSearchFields(fieldDropdowns);
          setMassUpdateValue("");
        } else {
          NotificationManager.error("", response.data.message, 5000);
        }
      })
      .catch(function (error) {
        handleApiError(error, navigate);
      });
  };

  const handleTextAreaClick = () => {
    setShowButtons(true);
  };

  const handleTextFileChange = (event) => {
    const files = Array.from(event.target.files);
    if (files) {
      setSelectedNoteFiles(files);
    } else {
      setSelectedNoteFiles([]);
    }
  };
  const handleAddTitleClick = () => {
    setShowTitleNote(true);
  };
  const deleteCampaignDetails = async () => {
    const requestBody = {
      loggedInUserId: loginUserId,
      campaignId: campaignId,
    };

    const headersResult = getHeaders(true);

    // Check if headers result indicates an error
    if (headersResult.error) {
      navigate("../"); // Redirect to login if token is missing
      return;
    }
    await axios
      .post(URI.deleteCampaignDetails, requestBody, {
        headers: headersResult,
      })
      .then((response) => {
        if (response.data.status === 200) {
          setInnerPage("list");
          setDeleteModal(false);

          getAllCampaignDetails(selectedOption);
          NotificationManager.success("", response.data.message, 5000);
        } else {
          NotificationManager.error("", response.data.message, 5000);
        }
      })
      .catch(async (error) => {
        handleApiError(error, navigate);
      });
  };

  const getNotesDetails = async (campaignId, orderType) => {
    const requestBody = {
      loggedInUserId: loginUserId,
      moduleId: campaignId,
      orderType: orderType,
    };

    const headersResult = getHeaders(true);

    // Check if headers result indicates an error
    if (headersResult.error) {
      navigate("../"); // Redirect to login if token is missing
      return;
    }

    await axios
      .post(URI.getNote, requestBody, {
        headers: headersResult,
      })
      .then((response) => {
        if (response.data.status === 200) {
          setNoteInfo(response.data.data.noteInfo);
        } else {
          NotificationManager.error("", response.data.message, 5000);
        }
      })
      .catch(async (error) => {
        handleApiError(error, navigate);
      });
  };

  const scrollToSection = (sectionId) => {
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: "smooth" });
    }
  };

  const insertNote = async () => {
    const formData = new FormData();
    formData.append("moduleId", campaignId);
    formData.append("loggedInUserId", loginUserId);
    formData.append("moduleName", "CAMPAIGNS");
    formData.append("noteDescription", note);
    formData.append("noteTitle", addTitle);

    selectedNoteFiles.forEach((file) => {
      formData.append("noteImages", file);
    });

    const headersResult = getHeaders(true);

    // Check if headers result indicates an error
    if (headersResult.error) {
      navigate("../"); // Redirect to login if token is missing
      return;
    }

    await axios
      .post(URI.insertNote, formData, {
        headers: headersResult,
      })
      .then(() => {
        // const newNoteId = response.data.data.noteId; // Extracting noteId from response
        setShowButtons(false);
        setNote("");
        setAddTitle("");
        setSelectedNoteFiles([]);
        // fetchNoteImage(newNoteId); // Pass newNoteId to fetchNoteImage
        getNotesDetails(campaignId, selectRecent);
      })
      .catch((error) => {
        handleApiError(error, navigate);
      });
  };

  const handleSelectRecent = (item) => {
    setSelectRecent(item);
    var value = "";
    if (item === "Recent Last") {
      value = "RECENT_LAST";
    } else if (item === "Recent First") {
      value = "RECENT_FIRST";
    } else {
      value = item;
    }
    getNotesDetails(campaignId, value);
  };

  const deleteNoteDetails = async (campaignId, noteId) => {
    const formData = new FormData();
    formData.append("moduleId", campaignId);
    formData.append("loggedInUserId", loginUserId);
    formData.append("moduleName", "CAMPAIGNS");
    formData.append("noteId", noteId);

    const headersResult = getHeaders(true);

    // Check if headers result indicates an error
    if (headersResult.error) {
      navigate("../"); // Redirect to login if token is missing
      return;
    }

    await axios
      .post(URI.deleteNote, formData, {
        headers: headersResult,
      })
      .then((response) => {
        if (response.data.status === 200) {
          NotificationManager.success("", response.data.message, 3000);
          getNotesDetails(campaignId, selectRecent);
          setNoteDeleteConfirmation(false);
        } else {
          NotificationManager.error("", response.data.message, 5000);
        }
      })
      .catch((error) => {
        handleApiError(error, navigate);
      });
  };

  const updateNote = async (noteId) => {
    const formData = new FormData();
    formData.append("moduleId", campaignId);
    formData.append("loggedInUserId", loginUserId);
    formData.append("moduleName", "CAMPAIGNS");
    formData.append("noteDescription", updateNotes.description);
    formData.append("noteTitle", updateNotes.title);
    formData.append("noteId", noteId);
    formData.append("deletedImageNames", deletedImg.toString());

    selectedNoteFiles.forEach((file) => {
      formData.append("noteImages", file);
    });

    const headersResult = getHeaders(true);

    // Check if headers result indicates an error
    if (headersResult.error) {
      navigate("../"); // Redirect to login if token is missing
      return;
    }

    await axios
      .post(URI.updateNote, formData, {
        headers: headersResult,
      })
      .then((response) => {
        if (response.data.status === 200) {
          setShowButtons(false);
          setEditNote(false);
          setUpdateNotes({
            description: "",
            title: "",
            noteImageList: [],
          });
          NotificationManager.success("", response.data.message, 5000);
          setSelectedNoteFiles([]);
          getNotesDetails(campaignId, selectRecent);
          setDeletedImg("");
        } else {
          setDeletedImg("");
          setSelectedNoteFiles([]);
          NotificationManager.error("", response.data.message, 5000);
        }
      })
      .catch((error) => {
        handleApiError(error, navigate);
      });
  };

  const handleSelectCheckboxChange = (event) => {
    const { value } = event.target;

    const selectedCheckbox = { moduleId: value };

    setSelectedCheckboxes((prevSelectedCheckboxes) => {
      const isAlreadySelected = prevSelectedCheckboxes.some(
        (checkbox) => checkbox.moduleId === value
      );

      if (isAlreadySelected) {
        return prevSelectedCheckboxes.filter(
          (checkbox) => checkbox.moduleId !== value
        );
      } else {
        return [...prevSelectedCheckboxes, selectedCheckbox];
      }
    });
  };

  const handleFieldClick = (field, i) => {
    if (field.type === "dropdown") {
      const fieldValue = field.name
        .replace(/\s+/g, "")
        .replace(/^(.)/, (match) => match.toLowerCase());

      setClickedDropdownObj(campaignOverviewDetails[fieldValue]);
    }

    const updatedColumns = [...fieldLabels];

    const existedObj = updatedColumns.find(
      (field) => field.name === selectedField
    );
    const selectedObj = updatedColumns.find((item) => item.name === field.name);

    const existedObjIndex = updatedColumns.findIndex(
      (field) => field.name === existedObj.name
    );
    const selectedObjIndex = updatedColumns.findIndex(
      (field) => field.name === selectedObj.name
    );

    updatedColumns[existedObjIndex].isShowed = false;
    updatedColumns[selectedObjIndex].isShowed = true;

    const removedObj = updatedColumns.splice(existedObjIndex, 1)[0];
    updatedColumns.splice(existedObjIndex, 0, selectedObj);

    updatedColumns.push(removedObj);
    const uniqueData = updatedColumns.filter(
      (item, index, self) =>
        index === self.findIndex((t) => t.name === item.name)
    );
    setFieldLabels(uniqueData);
    setShowSearchFields(uniqueData);
  };

  const handleParentUserItemClick = (item) => {
    setSelectedCampaignItem(item);
    setParentListVisible(false);
  };

  const getAllCampaignDetails = async (selectedOption) => {
    setIsLoading(true);
    setSelectedOption(selectedOption);
    const requestBody = {
      loggedInUserId: loginUserId,
      filter: selectedOption.value,
      pageNo: currentPage,
      pageSize: perPage,
    };

    const headersResult = getHeaders(true);

    // Check if headers result indicates an error
    if (headersResult.error) {
      navigate("../"); // Redirect to login if token is missing
      return;
    }

    await axios
      .post(URI.getAllCampaigns, requestBody, {
        headers: headersResult,
      })
      .then((response) => {
        if (response.data.status === 200) {
          setAllList(response.data.data.getCampaignInformation);
          setSelectedCampaignItem(response.data.data.getCampaignInformation);
          setFilteredLeadDetails(response.data.data.getCampaignInformation);
          setModalCampaignDetails(response.data.data.getCampaignInformation);
          setTotalItems(response.data.data.p_row_count);
          setIsLoading(false);
        } else {
          NotificationManager.error("", response.data.message, 5000);
          setIsLoading(false);
        }
      })
      .catch(async (error) => {
        handleApiError(error, navigate);
      });
  };

  const handlePageDropDowmChange = (selectedOption) => {
    setCurrentPage(1);
    setPerPage(parseInt(selectedOption.value));
  };

  const createCampaignStyles = {
    menu: (base) => ({
      ...base,
      width: "60%",
    }),
    option: (provided) => ({
      ...provided,
      // backgroundColor: "#EAEAEA",
      color: "#000",
      // backgroundColor: 'rgba(33, 119, 254, 0.1)',
      width: "100%",
    }),
    control: (baseStyles) => ({
      ...baseStyles,
      border: "1px solid #b9b9b9b0",
      backgroundColor: "transparent",
      height: "40px",
      borderRadius: "4px",
      width: "60%",
      boxShadow: "none",
    }),
    input: (base) => ({
      ...base,
      color: "#000",
    }),
    singleValue: (defaultStyles) => ({ ...defaultStyles, color: "#000" }),

    menuList: (provided) => ({
      ...provided,
      paddingTop: 0,
      paddingBottom: 0,
      "&::-webkit-scrollbar": {
        width: 5,
      },

      "&::-webkit-scrollbar-thumb": {
        background: "#316AFF",
        borderRadius: "10px",
      },
    }),
  };
  const customSelectLocationStyles = {
    menu: (base) => ({
      ...base,
      width: "100%",
    }),
    option: (provided, state) => ({
      ...provided,
      color: state.isSelected ? "#fff" : "#000",
      backgroundColor: state.isSelected ? "#407BFF" : "",
      width: "100%",
      cursor: "pointer",
      "&:hover": {
        backgroundColor: "#f5f5f5",
      },
    }),
    control: (baseStyles) => ({
      ...baseStyles,
      border: "2px solid #316AFF",
      backgroundColor: "#fff",
      height: "40px",
      borderRadius: "10px",
      width: "100%",
      boxShadow: "none",
      cursor: "pointer",
    }),
    input: (base) => ({
      ...base,
      color: "#FFFFFF",
    }),
    singleValue: (defaultStyles) => ({ ...defaultStyles, color: "#000" }),
    menuList: (provided) => ({
      ...provided,
      paddingTop: 0,
      paddingBottom: 0,
      "&::-webkit-scrollbar": {
        width: 5,
      },
      "&::-webkit-scrollbar-thumb": {
        background: "#316AFF",
        borderRadius: "10px",
      },
    }),
    dropdownIndicator: (base) => ({
      ...base,
      color: "#316AFF",
    }),
  };
  const fieldDropdownStyles = {
    menu: (base) => ({
      ...base,
      width: "100%",
    }),
    option: (provided) => ({
      ...provided,
      // backgroundColor: "#EAEAEA",
      color: "#000",
      // backgroundColor: 'rgba(33, 119, 254, 0.1)',
      width: "100%",
    }),
    control: (baseStyles) => ({
      ...baseStyles,
      border: 0,
      // backgroundColor: 'rgba(33, 119, 254, 0.1)',
      height: "20px",
      borderRadius: "10px",
      width: "90%",
      boxShadow: "none",
    }),
    input: (base) => ({
      ...base,
      color: "#FFFFFF",
    }),
    singleValue: (defaultStyles) => ({ ...defaultStyles, color: "#000" }),

    menuList: (provided) => ({
      ...provided,
      paddingTop: 0,
      paddingBottom: 0,
      "&::-webkit-scrollbar": {
        width: 5,
      },

      "&::-webkit-scrollbar-thumb": {
        background: "#316AFF",
        borderRadius: "10px",
      },
    }),
  };

  const handledeleteModuleDeal = async (deleteDealId) => {
    console.log("campaignId", campaignId);
    const requestBody = {
      loginUserId: loginUserId,
      moduleName: "CAMPAIGNS",
      moduleId: campaignId,
      assignModuleName: "DEALS",
      assignModuleId: deleteDealId,
    };

    try {
      const headersResult = getHeaders(true);

      // Check if headers result indicates an error
      if (headersResult.error) {
        navigate("../"); // Redirect to login if token is missing
        return;
      }
      const response = await axios.post(URI.deleteRole, requestBody, {
        headers: headersResult,
      });
      if (response.data.status === 200) {
        NotificationManager.success("", response.data.message, 5000);
        campaignOverviewDetails(campaignId);
        accountOverviewDetail(accountId);
      } else {
        NotificationManager.error("", response.data.message, 5000);
      }
    } catch (error) {
      handleApiError(error, navigate);
    }
  };

  const handleSaveButton = async (e) => {
    let isValidForm = true;

    const setErrorMessage = (setter, message, timeout = 5000) => {
      setter(message);
      setTimeout(() => {
        setter("");
      }, timeout);
      isValidForm = false;
    };

    const campaignInfo =
      existingCampaign || existingClone ? campaignUpdateData : campaignData;

    if (campaignInfo.campaignName === "") {
      setErrorMessage(setMandatoryMsg, "Campaign Name cannot be empty.", 5000);
    }
    if (
      campaignInfo.campaignName &&
      !validateCampaignName(campaignInfo.campaignName)
    ) {
      setErrorMessage(
        setMandatoryMsg,
        "Please enter a valid Campaign name.",
        5000
      );
    }
    if (
      campaignInfo.expectedRevenue &&
      !validateCalcField(campaignInfo.expectedRevenue)
    ) {
      setErrorMessage(
        setExpectedRevenueMsg,
        "Please enter a valid expected Revenue.",
        5000
      );
    }
    if (
      campaignInfo.budgetedCost &&
      !validateCalcField(campaignInfo.budgetedCost)
    ) {
      setErrorMessage(
        setBudgetCostMsg,
        "Please enter a valid Budget Cost.",
        5000
      );
    }
    if (
      campaignInfo.actualCost &&
      !validateCalcField(campaignInfo.actualCost)
    ) {
      setErrorMessage(setActualMsg, "Please enter a valid Actual Cost.", 5000);
    }
    if (
      campaignInfo.startDate &&
      campaignInfo.endDate &&
      campaignInfo.endDate < campaignInfo.startDate
    ) {
      setErrorMessage(setDateMsg, "End Date must be greater than Start Date.");
    }
    if (!isValidForm) return;
    let requestBody = {};
    e === "SAVE AND NEW" ? setSaveAndNewSubmit(true) : setSaveSubmit(true);
    if (existingClone || existingCampaign) {
      requestBody = {
        loggedInUserId: loginUserId,
        campaignOwnerId: selectedItem.userId,
        campaignName: removeExtraSpaces(campaignUpdateData.campaignName),
        startDate: campaignUpdateData.startDate,
        endDate: campaignUpdateData.endDate,
        campaignType: campaignUpdateData.campaignType.value,
        campaignStatus: campaignUpdateData.campaignStatus.value,
        expectedRevenue: Number(campaignUpdateData.expectedRevenue),
        budgetedCost: Number(campaignUpdateData.budgetedCost),
        actualCost: Number(campaignUpdateData.actualCost),
        expectedResponse: campaignUpdateData.ExpectedResponse,
        numbersSent: Number(campaignUpdateData.numbersSent),
        parentCampaign: selectedCampaignItem.campaignId,
        description: removeExtraSpaces(campaignUpdateData.description),
      };
    } else {
      requestBody = {
        loggedInUserId: loginUserId,
        campaignOwnerId: selectedItem.userId,
        campaignName: removeExtraSpaces(campaignData.campaignName),
        startDate: campaignData.startDate,
        endDate: campaignData.endDate,
        campaignType: campaignData.campaignType.value,
        campaignStatus: campaignData.campaignStatus.value,
        expectedRevenue: Number(campaignData.expectedRevenue),
        budgetedCost: Number(campaignData.budgetedCost),
        actualCost: Number(campaignData.actualCost),
        expectedResponse: campaignData.ExpectedResponse,
        numbersSent: Number(campaignData.numbersSent),
        parentCampaign: selectedCampaignItem.campaignId,
        description: removeExtraSpaces(campaignData.description),
      };
    }

    const headersResult = getHeaders(true);

    // Check if headers result indicates an error
    if (headersResult.error) {
      navigate("../"); // Redirect to login if token is missing
      return;
    }

    await axios
      .post(URI.insertCampaigns, requestBody, {
        headers: headersResult,
      })
      .then((response) => {
        if (response.data.status === 200) {
          NotificationManager.success("", response.data.message, 5000);
          getAllCampaignDetails(selectedOption);
          setInnerPage("list");
          dataReset();
          setCurrentPage(1);
          setPerPage(10);
          if (e === "SAVE AND NEW") {
            setInnerPage("create");
          }
        } else {
          NotificationManager.error("", response.data.message, 5000);
        }
      })
      .catch(function (error) {
        handleApiError(error, navigate);
      })
      .finally(() => {
        setSaveAndNewSubmit(false);
        setSaveSubmit(false);
      });
  };

  const campaignOverviewDetails = async (campaignId) => {
    let campaignInfoDetails = {};

    const headersResult = getHeaders(true);

    // Check if headers result indicates an error
    if (headersResult.error) {
      navigate("../"); // Redirect to login if token is missing
      return;
    }

    await axios
      .get(URI.campaignOverviewDetails + "/" + loginUserId + "/" + campaignId, {
        headers: headersResult,
      })
      .then((response) => {
        if (response.data.status === 200) {
          campaignInfoDetails = response.data.data.campaignInformation;

          campaignInfoDetails.campaignName =
            response.data.data.campaignInformation.campaignName;
          campaignInfoDetails.startDate =
            response.data.data.campaignInformation.startDate;
          campaignInfoDetails.endDate =
            response.data.data.campaignInformation.endDate;
          campaignInfoDetails.expectedRevenue =
            response.data.data.campaignInformation.expectedRevenue;
          campaignInfoDetails.budgetedCost =
            response.data.data.campaignInformation.budgetedCost;
          campaignInfoDetails.actualCost =
            response.data.data.campaignInformation.actualCost;
          campaignInfoDetails.ExpectedResponse =
            response.data.data.campaignInformation.expectedResponse;
          campaignInfoDetails.numbersSent =
            response.data.data.campaignInformation.numbersSent;
          campaignInfoDetails.description =
            response.data.data.campaignInformation.description;
          campaignInfoDetails.createdBy =
            response.data.data.campaignInformation.createdBy;
          campaignInfoDetails.modifiedBy =
            response.data.data.campaignInformation.modifiedBy;
          campaignInfoDetails.lastUpdate =
            response.data.data.campaignInformation.lastUpdate;
          campaignInfoDetails.campaignType = {
            label: response.data.data.campaignInformation.campaignType,
            value: response.data.data.campaignInformation.campaignType,
          };
          campaignInfoDetails.campaignInfoType =
            campaignInfoDetails.campaignType;

          campaignInfoDetails.campaignStatus = {
            label: response.data.data.campaignInformation.status,
            value: response.data.data.campaignInformation.status,
          };
          campaignInfoDetails.campaignInfoStatus =
            campaignInfoDetails.campaignStatus;

          campaignInfoDetails.startDateInfo =
            response.data.data.campaignInformation.startDate;
          campaignInfoDetails.endDateInfo =
            response.data.data.campaignInformation.endDate;
          campaignInfoDetails.actualCostInfo =
            response.data.data.campaignInformation.actualCost;
          campaignInfoDetails.numbersSentInfo =
            response.data.data.campaignInformation.numbersSent;
          campaignInfoDetails.expectedResponseInfo =
            response.data.data.campaignInformation.expectedResponse;
          campaignInfoDetails.budgetedCostInfo =
            response.data.data.campaignInformation.budgetedCost;
          campaignInfoDetails.expectedRevenueInfo =
            response.data.data.campaignInformation.expectedRevenue;

          setSelectedItem({
            name: response.data.data.campaignInformation.campaignOwner,
            userId: response.data.data.campaignInformation.campaignOwnerId,
          });

          setSelectedCampaignItem({
            campaignName:
              response.data.data.campaignInformation.parentCampaignName,
            campaignId: response.data.data.campaignInformation.parentCampaign,
          });

          setInnerPage("overview");
          setLeadList(response.data.data.campaignLeadsData);
          setContactList(response.data.data.campaignContactsData);
          setCampaignInfoDetails(campaignInfoDetails);
          setOpenActivities(response.data.data.assignOpenActivities);
          setOpenActivitiesCalls(response.data.data.openActivitiesCalls);
          setOpenActivitiesMeetings(response.data.data.openActivitiesMeetings);
          setOpenActivitiesTasks(response.data.data.assignedOpenActivesTask);
          setClosedActivitiesCall(response.data.data.closedActivitiesCalls);
          setClosedActivitiesMeetings(
            response.data.data.closedActivitiesMeetings
          );
          setClosedActivitiesTasks(response.data.data.assignedClosedActivesTask);
          setCloseActivities(response.data.data.assignClosedActivities);
          setFilterContactDetails(response.data.data.getProdContDetails);
          setFilterLeadDetails(response.data.data.getProdLeadDetails);
          setFilterDealDetails(response.data.data.campaignDealsData);
          setUpdatedFields(campaignInfoDetails);
        } else {
          NotificationManager.error("", response.data.message, 5000);
        }
      })
      .catch(async (error) => {
        handleApiError(error, navigate);
      });
  };

  const handleSelectParentList = (value) => {
    setSelectedCampaignItem(value); // Update the selected lead value
  };

  const handleUpdateCampaign = async (e) => {
    let isValidForm = true;

    const setErrorMessage = (setter, message, timeout = 5000) => {
      setter(message);
      setTimeout(() => {
        setter("");
      }, timeout);
      isValidForm = false;
    };

    const campaignInfo =
      existingCampaign || existingClone ? campaignUpdateData : campaignData;

    if (campaignInfo.campaignName === "") {
      setErrorMessage(setMandatoryMsg, "Campaign Name cannot be empty.", 5000);
    }
    if (
      campaignInfo.campaignName &&
      !validateCampaignName(campaignInfo.campaignName)
    ) {
      setErrorMessage(
        setMandatoryMsg,
        "Please enter a valid Campaign name.",
        5000
      );
    }
    if (
      campaignInfo.expectedRevenue &&
      !validateCalcField(campaignInfo.expectedRevenue)
    ) {
      setErrorMessage(
        setExpectedRevenueMsg,
        "Please enter a valid expected Revenue.",
        5000
      );
    }
    if (
      campaignInfo.budgetedCost &&
      !validateCalcField(campaignInfo.budgetedCost)
    ) {
      setErrorMessage(
        setBudgetCostMsg,
        "Please enter a valid expected Revenue.",
        5000
      );
    }
    if (
      campaignInfo.actualCost &&
      !validateCalcField(campaignInfo.actualCost)
    ) {
      setErrorMessage(
        setActualMsg,
        "Please enter a valid expected Revenue.",
        5000
      );
    }
    if (
      campaignInfo.startDate &&
      campaignInfo.endDate &&
      campaignInfo.endDate <= campaignInfo.startDate
    ) {
      setErrorMessage(setDateMsg, "End Date must be greater than Start Date.");
    }
    if (!isValidForm) return;
    setSaveSubmit(true);
    const requestBody = {
      campaignId: campaignId,
      loggedInUserId: loginUserId,
      campaignOwnerId: selectedItem.userId,
      campaignName: removeExtraSpaces(campaignUpdateData.campaignName),
      startDate: campaignUpdateData.startDate,
      endDate: campaignUpdateData.endDate,
      campaignType: campaignUpdateData.campaignType.value,
      campaignStatus: campaignUpdateData.campaignStatus.value,
      expectedRevenue: Number(campaignUpdateData.expectedRevenue),
      budgetedCost: Number(campaignUpdateData.budgetedCost),
      actualCost: Number(campaignUpdateData.actualCost),
      expectedResponse: campaignUpdateData.ExpectedResponse,
      numbersSent: Number(campaignUpdateData.numbersSent),
      parentCampaign: selectedCampaignItem.campaignId,
      description: removeExtraSpaces(campaignUpdateData.description),
    };

    const headersResult = getHeaders(true);

    // Check if headers result indicates an error
    if (headersResult.error) {
      navigate("../"); // Redirect to login if token is missing
      return;
    }

    await axios
      .post(URI.updateAllCampaigns, requestBody, {
        headers: headersResult,
      })
      .then((response) => {
        if (response.data.status === 200) {
          dataReset();
          setExistingCampaign(false);
          getAllCampaignDetails(selectedOption);
          if (e === "SAVE") {
            setInnerPage("list");
            setSelectedItem();
          }

          NotificationManager.success("", response.data.message, 5000);
        } else {
          NotificationManager.error("", response.data.message, 5000);
        }
      })
      .catch(async (error) => {
        handleApiError(error, navigate);
      })
      .finally(() => {
        setSaveSubmit(false);
      });
  };

  const getAllTimeleadLeads = async (campaignId) => {
    const headersResult = getHeaders(true);

    // Check if headers result indicates an error
    if (headersResult.error) {
      navigate("../"); // Redirect to login if token is missing
      return;
    }

    await axios
      .get(URI.getCampaignTimeline + "/" + loginUserId + "/" + campaignId, {
        headers: headersResult,
      })
      .then((response) => {
        if (response.data.status === 200) {
          setTimeLineDataList(response.data.data.getCampaignDetails);
        }
      })
      .catch(async (error) => {
        handleApiError(error, navigate);
      });
  };

  const nodataPage = () => {
    return (
      <div className="d-flex flex-column justify-content-center align-items-center  ">
        <img src={noDataAccount} alt="img" height={200} width={200} />
        <h5 className="opacity-75">Manage Campaign</h5>
        <p className="opacity-75 p-0 m-0">
          Quotes are legal agreements between a customer and a vector
        </p>
        <p className="opacity-75 p-0 m-0">
          to deliver the requested product(s) at the agreed upon price
        </p>
        <p className="opacity-75 p-0 m-0">within the specified time period.</p>
        <button
          className="createContactButtonSave px-2 mt-4 cursorPointer"
          style={{ width: "15%" }}
          onClick={handleCreateCampaign}
        >
          Create a Campaign
        </button>
      </div>
    );
  };

  const campaignHomePage = () => {
    return (
      <div>
        {isLoading ? (
          <>
            <Loader />
          </>
        ) : (
          <>
            {allList &&
            allList.length === 0 &&
            selectedOption.value === "all_campaigns" ? (
              <>{nodataPage()}</>
            ) : (
              <>
                <div className="creteContactBar">
                  {selectedCheckboxes.length > 0 ? (
                    <div className="d-flex w-50 align-items-center gap-2">
                      <Select
                        options={massOptions}
                        styles={customCommonStyles("100%")}
                        value={{
                          value: "Action",
                          label: "Action",
                        }}
                        onChange={(option) => {
                          if (option.value === "Mass Update") {
                            // setShowSearchFields(campaignColumn);
                          } else if (option.value === "Mass Transfer") {
                            setSelectedItem(userList[0]);
                          }
                          setMassUpdateValue("");
                          setShowModalType(option.value);
                          setListVisible(false);
                        }}
                        className="w-25"
                        isSearchable={false}
                      />
                      {/* <Select
                    options={massOptions}
                    styles={customSelectLocationStyles}
                    value={dropdownTwo}
                    onChange={(option) => {
                      setDropdownTwo(option);
                    }}
                  /> */}
                    </div>
                  ) : (
                    <>
                      <div className="d-flex w-50 align-items-center gap-2">
                        {/* <img
                    src={filterIcon}
                    height={25}
                    className="contactFilter"
                    alt=""
                  /> */}
                        <Select
                          options={options}
                          styles={customCommonStyles("190px")}
                          value={selectedOption}
                          onChange={handleChange}
                          isSearchable={false}
                        />
                      </div>
                      <div className="d-flex w-50 justify-content-end ">
                        {/* <div class="btn-group"> */}
                        <button
                          className="createContactButtonSave py-2"
                          onClick={handleCreateCampaign}
                        >
                          Create Campaign
                        </button>
                        {/* <button
                            type="button"
                            class="btn btn-sm  dropdown-toggle dropdown-toggle-split"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                            style={{
                              background:
                                "linear-gradient(86.07deg, #407BFF 0%, #12A7FE 100%)",
                              color: "#fff",
                            }}
                          >
                            <span class="visually-hidden">
                              Toggle Dropdown
                            </span>
                          </button>
                          <ul class="dropdown-menu">
                            <li>
                              <a class="dropdown-item ">Import Leads</a>
                            </li>
                            <li>
                              <a class="dropdown-item ">Import Notes</a>
                            </li>
                          </ul> */}
                        {/* </div> */}
                      </div>
                    </>
                  )}
                </div>

                <div className="totalRecordBarContact">
                  <div className="d-flex w-50">
                    {selectedCheckboxes.length > 0
                      ? `${selectedCheckboxes.length} Records Selected`
                      : `Total Records : ${totalItems}`}
                  </div>
                  <div className="d-flex w-50 align-items-center justify-content-end gap-2">
                    {selectedCheckboxes.length === 0 ? (
                      allList.length !== 0 && (
                        <Select
                          options={pageOption}
                          styles={customCommonStyles("100%")}
                          value={pageOption.find(
                            (option) => option.value === perPage.toString()
                          )}
                          onChange={handlePageDropDowmChange}
                          isSearchable={false}
                        />
                      )
                    ) : (
                      <></>
                    )}
                    {allList.length !== 0 && (
                      <Pagination
                        currentPage={currentPage}
                        totalPages={totalPages}
                        onPageChange={handlePageChange}
                      />
                    )}
                  </div>
                </div>
                <div className="contactMain">
                  {/* <div className="contactLead">
          <h6 style={{ borderBottom: "2px solid #316AFF" }}>
            Filter Accounts by
          </h6>
          <input
            type="text"
            placeholder="Search"
            className="contactSearch"
          />
          <div className="checkboxItemAccount">
            <h6 style={{ color: "#515151" }}>▾ System Defined Filters</h6>
            <input
              type="checkbox"
              id="vehicle1"
              name="vehicle1"
              value="Bike"
            />
            &nbsp;
            <label for="vehicle1">Touched Record</label>
            <br />
            <input
              type="checkbox"
              id="vehicle1"
              name="vehicle1"
              value="Bike"
            />
            &nbsp;
            <label for="vehicle1">Untouched Record</label>
            <br />
            <input
              type="checkbox"
              id="vehicle1"
              name="vehicle1"
              value="Bike"
            />
            &nbsp;
            <label for="vehicle1">Record Action</label>
            <br />
            <input
              type="checkbox"
              id="vehicle1"
              name="vehicle1"
              value="Bike"
            />
            &nbsp;
            <label for="vehicle1">Touched Record</label>
            <br />
            <input
              type="checkbox"
              id="vehicle1"
              name="vehicle1"
              value="Bike"
            />
            &nbsp;
            <label for="vehicle1">Untouched Record</label>
            <br />
            <input
              type="checkbox"
              id="vehicle1"
              name="vehicle1"
              value="Bike"
            />
            &nbsp;
            <label for="vehicle1">Record Action</label>
            <br />
            <h6 style={{ color: "#515151", marginTop: "10px" }}>
              ▾ Filter By Fields
            </h6>
            <input
              type="checkbox"
              id="vehicle1"
              name="vehicle1"
              value="Bike"
            />
            &nbsp;
            <label for="vehicle1">Annual Revenue</label>
            <br />
            <input
              type="checkbox"
              id="vehicle1"
              name="vehicle1"
              value="Bike"
            />
            &nbsp;
            <label for="vehicle1">City</label>
            <br />
            <input
              type="checkbox"
              id="vehicle1"
              name="vehicle1"
              value="Bike"
            />
            &nbsp;
            <label for="vehicle1">Company</label>
            <br />
            <input
              type="checkbox"
              id="vehicle1"
              name="vehicle1"
              value="Bike"
            />
            &nbsp;
            <label for="vehicle1">Annual Revenue</label>
            <br />
            <input
              type="checkbox"
              id="vehicle1"
              name="vehicle1"
              value="Bike"
            />
            &nbsp;
            <label for="vehicle1">City</label>
            <br />
            <input
              type="checkbox"
              id="vehicle1"
              name="vehicle1"
              value="Bike"
            />
            &nbsp;
            <label for="vehicle1">Company</label>
            <br />
          </div>
        </div> */}
                  <div className="leadListTable">
                    <table className="table leadTable">
                      <thead>
                        <tr>
                          <th scope="col">
                            <input
                              className="cursorPointer"
                              type="checkbox"
                              id="all"
                              name="all"
                              // checked={
                              //   selectedCheckboxes.length ===
                              //     filteredLeadDetails.length &&
                              //   filteredLeadDetails.length > 0
                              // }
                              // onChange={(e) => {
                              //   if (e.target.checked) {
                              //     const allList = filteredLeadDetails.map(
                              //       (item) => ({ moduleId: item.campaignId })
                              //     );
                              //     setSelectedCheckboxes(allList);
                              //   } else {
                              //     setSelectedCheckboxes([]);
                              //   }
                              // }}
                              checked={filteredLeadDetails.every((selected) =>
                                selectedCheckboxes.some(
                                  (list) =>
                                    list.moduleId === selected.campaignId
                                )
                              )}
                              onChange={(e) => {
                                const allList = filteredLeadDetails.map(
                                  (item) => ({
                                    moduleId: item.campaignId,
                                  })
                                );
                                if (e.target.checked) {
                                  const newSelections = allList.filter(
                                    (newItem) =>
                                      !selectedCheckboxes.some(
                                        (existingItem) =>
                                          existingItem.moduleId ===
                                          newItem.moduleId
                                      )
                                  );
                                  setSelectedCheckboxes((prev) => [
                                    ...prev,
                                    ...newSelections,
                                  ]);
                                } else {
                                  setSelectedCheckboxes(
                                    selectedCheckboxes.filter(
                                      (aItem) =>
                                        !allList.some(
                                          (bItem) =>
                                            aItem.moduleId === bItem.moduleId
                                        )
                                    )
                                  );
                                }
                              }}
                            />
                          </th>
                          {selectedHeadings.map(
                            (heading) =>
                              heading.isChecked && (
                                <th scope="col" key={heading.name}>
                                  <span className="pe-3">{heading.name}</span>
                                  <span className="position-relative">
                                    <img
                                      src={tableFilterIcon}
                                      alt=""
                                      className="cursorPointer"
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        setShowHeadings(false);
                                        setShowSelectedFilter(heading.name);
                                      }}
                                    />
                                    {heading.name === showSelectedFilter && (
                                      <span
                                        className="position-absolute rounded-2 border bg-light top-0 mt-4"
                                        style={{ right: "-10%" }}
                                      >
                                        <span className="d-flex flex-column px-1">
                                          {/* Ascending filter */}
                                          <span
                                            className="my-1 px-1 cursorPointer fw-normal text-start filter_type"
                                            onClick={() => {
                                              if (filterType === "asc") {
                                                setFilterType("");
                                                setFilteredLeadDetails(allList);
                                              } else {
                                                setFilterType("asc");
                                                sortedColumnsData(
                                                  "asc",
                                                  heading.value
                                                );
                                              }
                                            }}
                                          >
                                            {filterType === "asc" ? (
                                              <img
                                                src={cancel}
                                                alt="asc"
                                                className="opacity-75 pe-1"
                                              />
                                            ) : (
                                              <img
                                                src={arrowUp}
                                                alt="asc"
                                                className="opacity-75 pe-1"
                                              />
                                            )}
                                            Asc
                                          </span>
                                          {/* Descending filter */}
                                          <span
                                            className="my-2 px-1 cursorPointer fw-normal text-start filter_type"
                                            onClick={() => {
                                              if (filterType === "desc") {
                                                setFilterType("");
                                                setFilteredLeadDetails(allList);
                                              } else {
                                                setFilterType("desc");
                                                sortedColumnsData(
                                                  "desc",
                                                  heading.value
                                                );
                                              }
                                            }}
                                          >
                                            {filterType === "desc" ? (
                                              <img
                                                src={cancel}
                                                alt="desc"
                                                className="opacity-75 pe-1"
                                              />
                                            ) : (
                                              <img
                                                src={arrowDown}
                                                alt="desc"
                                                className="opacity-75 pe-1"
                                              />
                                            )}
                                            Desc
                                          </span>
                                          <span
                                            className={`my-1 px-1 fw-normal text-start filter_type ${
                                              selectedHeadings[0].name ===
                                                "Campaign Name" &&
                                              heading.name === "Campaign Name"
                                                ? "opacity-50"
                                                : "cursorPointer"
                                            }`}
                                            onClick={() => {
                                              if (
                                                pinnedColumn !== "Campaign Name"
                                              ) {
                                                setPinnedColumn(
                                                  "Campaign Name"
                                                );
                                                handlePinColumn(
                                                  "Campaign Name"
                                                );
                                              } else {
                                                setPinnedColumn(heading.name);
                                                handlePinColumn(heading.name);
                                              }
                                            }}
                                          >
                                            <img
                                              src={
                                                pinnedColumn === heading.name
                                                  ? unPin
                                                  : pin
                                              }
                                              alt="pin"
                                              className="opacity-75 pe-1"
                                            />{" "}
                                            {pinnedColumn === heading.name
                                              ? "Unpin"
                                              : "Pin"}{" "}
                                            Column
                                          </span>
                                        </span>
                                      </span>
                                    )}
                                    <img
                                      src={filterLine}
                                      alt=""
                                      className="ps-2"
                                    />
                                  </span>
                                </th>
                              )
                          )}
                          <th
                            scope="col"
                            className="text-end cursorPointer position-relative"
                          >
                            <img
                              src={filterMenu}
                              alt=""
                              className={`filter_menu ${
                                showHeadings ? "bg-light" : ""
                              }`}
                              onClick={(e) => {
                                e.stopPropagation();
                                setShowSelectedFilter("");
                                setShowHeadings(true);
                              }}
                            />
                            {showHeadings && (
                              <div
                                className="position-absolute rounded-2  border border-primary bg-light"
                                style={{
                                  right: "0.2rem",
                                }}
                              >
                                <div className="d-flex flex-column px-2">
                                  <span
                                    className="p-2 cursorPointer fw-normal text-start"
                                    onClick={() => {
                                      setSearchColumn("");
                                      setSavingHeadings(selectedHeadings);
                                      setShowModalType("Manage Columns");
                                    }}
                                  >
                                    Manage Columns
                                  </span>
                                  <span
                                    className="p-2 fw-normal text-start opacity-50"
                                    onClick={() => {
                                      setShowModalType("Reset Column Size");
                                    }}
                                  >
                                    Reset Column Size
                                  </span>
                                </div>
                              </div>
                            )}
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {filteredLeadDetails &&
                        filteredLeadDetails.length > 0 ? (
                          filteredLeadDetails.map((item, i) => {
                            return (
                              <tr key={i} className="">
                                <td>
                                  <input
                                    className="cursorPointer"
                                    type="checkbox"
                                    id="vehicle1"
                                    name="vehicle1"
                                    value={item.campaignId}
                                    checked={selectedCheckboxes.some(
                                      (checkbox) =>
                                        checkbox.moduleId === item.campaignId
                                    )}
                                    onChange={handleSelectCheckboxChange}
                                  />
                                </td>
                                <React.Fragment>
                                  {selectedHeadings.map((obj, i) => {
                                    const value = obj.value;
                                    const itemValue = item[value];
                                    return obj.isChecked ? (
                                      value === "transHash" ? (
                                        <td
                                          style={{ wordBreak: "break-all" }}
                                          className="overflow_text_hash"
                                          key={itemValue}
                                        >
                                          <a
                                            rel="noreferrer"
                                            href={
                                              EXPLORER_URL +
                                              "about-tx-hash/" +
                                              item.transHash
                                            }
                                            className="anchorLinkHash textColor"
                                            data-title={item.transHash}
                                            target="_blank"
                                          >
                                            {item.transHash
                                              ? item.transHash.slice(0, 10) +
                                                "...."
                                              : "-"}
                                          </a>
                                        </td>
                                      ) : (
                                        <td
                                          key={i}
                                          onClick={() => {
                                            if (value === "campaignName") {
                                              campaignOverviewDetails(
                                                item.campaignId
                                              );
                                              setSelectedCampaignName(
                                                item.campaignName
                                              );
                                              setSelectedCampaignId(
                                                item.campaignId
                                              );
                                              setCampaignId(item.campaignId);
                                              getNotesDetails(
                                                item.campaignId,
                                                selectRecent
                                              );
                                              setSelectedLeadIndex(
                                                allList.findIndex(
                                                  (camp) =>
                                                    camp.campaignId ===
                                                    item.campaignId
                                                )
                                              );
                                            }
                                          }}
                                          className={`overflow_text ${
                                            value === "campaignName"
                                              ? "leadName cursorPointer"
                                              : ""
                                          }`}
                                          style={{ maxWidth: "3vw" }}
                                        >
                                          {itemValue !== null ? itemValue : "-"}
                                        </td>
                                      )
                                    ) : null;
                                  })}
                                </React.Fragment>
                                <td></td>
                              </tr>
                            );
                          })
                        ) : (
                          <tr>
                            <td
                              colSpan={selectedHeadings.length + 2}
                              style={{ textAlign: "center" }}
                            >
                              No Data
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </>
            )}
          </>
        )}
      </div>
    );
  };
  const createCampaignPage = () => {
    return (
      <>
        <div className="createLeadButtonBar">
          <div className="d-flex align-items-center w-50 gap-2">
            <img src={groupIcon} height={25} alt="img" />
            <div className="d-flex flex-column">
              <p className="mb-0">
                {existingCampaign ? "Edit" : existingClone ? "Clone" : "Create"}{" "}
                Campaign
              </p>
            </div>
          </div>
          <div className="d-flex w-50 gap-2 justify-content-end">
            <button
              className="createLeadButton"
              onClick={() => {
                setInnerPage("list");
                setCurrentPage(1);
                setPerPage(10);
                if (innerPage === "clone" && !existingCampaign) {
                  setInnerPage("overview");
                }

                if (existingCampaign){
                  setInnerPage("overview");
                }
              }}
            >
              Cancel
            </button>
            {existingCampaign === false && (
              <button
                className="createLeadButton"
                onClick={() => {
                  handleSaveButton("SAVE AND NEW");
                }}
                style={{ width: "175px" }}
                disabled={saveAndNewSubmit}
              >
                {saveAndNewSubmit ? (
                  <Lottie
                    options={defaultOptions("blue")}
                    height="24px"
                    width="36px"
                  />
                ) : (
                  "Save and New"
                )}
              </button>
            )}
            <button
              className="createContactButtonSave"
              type="submit"
              onClick={() => {
                if (existingCampaign) {
                  handleUpdateCampaign("SAVE");
                } else if (existingClone) {
                  handleSaveButton("ADD");
                } else {
                  handleSaveButton("ADD");
                }
              }}
              disabled={saveSubmit}
            >
              {saveSubmit ? (
                <Lottie
                  options={defaultOptions("white")}
                  height="30px"
                  width="36px"
                />
              ) : (
                updateButtonLabel
              )}
            </button>
          </div>
        </div>

        <div className="leadFields ">
          <h6 className="py-2 " style={{ borderBottom: "2px solid #316AFF" }}>
            Campaign Information
          </h6>

          <div className="inputFieldDiv">
            <div className="inputFieldDivLeft position-relative">
              <p className="mb-0">
                Campaign Owner<span className="text-danger">*</span>
              </p>
              <div className="d-flex input-with-clear ">
                <input
                  type="text"
                  className="insertCommonInputField cursorPointer"
                  value={selectedItem?.name || ""}
                  onClick={handleOwnerInputClick}
                  readOnly
                />
                {existingCampaign ? (
                  <></>
                ) : (
                  <span
                    className="clear-button"
                    onClick={(e) => {
                      handleOwnerInputClick(e);
                    }}
                  >
                    <img
                      src={listVisible ? up : down}
                      alt="img"
                      height={20}
                      width={20}
                    />
                  </span>
                )}

                <span
                  className="cursorPointer pt-2 ps-1"
                  onClick={() => {
                    if (existingCampaign) {
                      setShowModalType("changeOwner");
                      setListVisible(false);
                    } else {
                      setIsLeadOwnerModal(!isLeadOwnerModal);
                    }
                  }}
                >
                  <img src={userSearch} alt="img" width={20} height={20} />
                </span>
              </div>
              {existingCampaign ? (
                <></>
              ) : (
                <>
                  {listVisible && (
                    <ul
                      className="data-list list-group  lead_ownerList_container position-absolute"
                      style={{ top: "6rem" }}
                      onClick={(e) => e.stopPropagation()}
                    >
                      {userList.map((item, index) => (
                        <li
                          key={index}
                          className="cursorPointer list-group-item"
                          onClick={() => handleUserItemClick(item)}
                        >
                          <div className="d-flex flex-column">
                            <span> {item.name} </span>
                            <span> {item.emailId} </span>
                          </div>
                        </li>
                      ))}
                    </ul>
                  )}
                </>
              )}
            </div>
            <div className="inputFieldDivRight position-relative">
              <p className="mb-0"> Parent Campaign</p>
              <div className="d-flex input-with-clear ">
                <input
                  type="text"
                  className="insertCommonInputField cursorPointer"
                  value={selectedCampaignItem.campaignName || ""}
                  onClick={handleOwnerParentInputClick}
                  readOnly
                />
                <span
                  className="clear-button"
                  onClick={handleOwnerParentInputClick}
                >
                  <img
                    src={parentListVisible ? up : down}
                    alt="img"
                    height={20}
                    width={20}
                  />
                </span>

                <span
                  className="cursorPointer pt-2 ps-1"
                  onClick={() => {
                    setIsCampaignListModal(!isCampaignListModal);
                  }}
                >
                  <img src={accountsIcon} alt="img" width={25} height={25} />
                </span>
              </div>
              {parentListVisible && (
                <ul
                  className="data-list list-group  lead_ownerList_container position-absolute"
                  style={{ top: "6rem" }}
                  onClick={(e) => e.stopPropagation()}
                >
                  {allList.map((item, index) => (
                    <li
                      key={index}
                      className="cursorPointer list-group-item"
                      onClick={() => handleParentUserItemClick(item)}
                    >
                      <div className="d-flex flex-column">
                        <span> {item.campaignName} </span>
                        {/* <span> {item.emailId} </span> */}
                      </div>
                    </li>
                  ))}
                </ul>
              )}
            </div>
          </div>

          <div className="inputFieldDiv">
            <div className="inputFieldDivLeft">
              <p className="mb-0">
                Campaign Name<span className="text-danger">*</span>
              </p>
              <input
                type="text"
                maxLength={255}
                name="campaignName"
                className={
                  mandatoryMsg ? "mandatoryField" : "insertCommonInputField"
                }
                value={
                  existingCampaign || existingClone
                    ? campaignUpdateData.campaignName
                    : campaignData.campaignName
                }
                onChange={handleChangeCampaign}
              />
              <p style={{ color: "#fa5a5a", marginBottom: "0px" }}>
                {mandatoryMsg && mandatoryMsg}
              </p>
            </div>
            <div className="inputFieldDivRight">
              <p className="mb-0">
                Type<span className="text-danger">*</span>
              </p>
              <Select
                menuPlacement="auto"
                onChange={(selectedOption) =>
                  handleChangeSelectDropDown(selectedOption, "campaignType")
                }
                value={
                  existingCampaign || existingClone
                    ? campaignUpdateData.campaignType
                    : campaignData.campaignType
                }
                options={campaignTypeOptions}
                styles={createCampaignStyles}
                isSearchable={false}
              />
            </div>
          </div>
          <div className="inputFieldDiv">
            <div className="inputFieldDivLeft">
              <p className="mb-0">Start Date</p>
              <input
                type="date"
                className="insertCommonInputField"
                name="startDate"
                value={
                  existingCampaign || existingClone
                    ? convertDateFormat(campaignUpdateData.startDate)
                    : convertDateFormat(campaignData.startDate)
                }
                onChange={handleChangeCampaign}
                onKeyDown={(e) => e.preventDefault()}
                min="2000-01-01"
                max="2050-12-31"
              />
            </div>
            <div className="inputFieldDivRight">
              <p className="mb-0">End Date</p>
              <input
                type="date"
                className="insertCommonInputField"
                name="endDate"
                value={
                  existingCampaign || existingClone
                    ? convertDateFormat(campaignUpdateData.endDate)
                    : convertDateFormat(campaignData.endDate)
                }
                onChange={handleChangeCampaign}
                onKeyDown={(e) => e.preventDefault()}
                min="2000-01-01"
                max="2050-12-31"
              />
              <p style={{ color: "#fa5a5a", marginBottom: "0px" }}>
                {dateMsg && dateMsg}
              </p>
            </div>
          </div>
          <div className="inputFieldDiv">
            <div className="inputFieldDivLeft position-relative">
              <p className="mb-0">Expected Revenue</p>
              <input
                type="text"
                className="insertCommonInputField"
                pattern="[0-9]*"
                maxLength={15}
                autoComplete="off"
                name="expectedRevenue"
                style={{ padding: "12px 15px" }}
                value={
                  existingCampaign || existingClone
                    ? campaignUpdateData.expectedRevenue
                    : campaignData.expectedRevenue
                }
                onChange={handleChangeCampaign}
                onBlur={() => handleBlur("expectedRevenue")}
                onKeyPress={handleKeyPress}
              />
              <span
                className="clear-button position-absolute px-1"
                style={{ left: "0px", bottom: "9px" }}
              >
                ₹
              </span>
              {showTooltipExpectedRevenue && (
                <div style={{ ...tooltipStyles, bottom: "30px" }}>
                  {"= " + tooltipContent}
                </div>
              )}
              <p
                className="small erro_msg position-absolute"
                style={{ left: "0px", bottom: "-35px" }}
              >
                {expectedRevenueMsg && expectedRevenueMsg}
              </p>
            </div>
            <div className="inputFieldDivRight position-relative">
              <p className="mb-0">Budgeted Cost</p>
              <input
                type="text"
                className="insertCommonInputField"
                pattern="[0-9]*"
                maxLength={16}
                autoComplete="off"
                name="budgetedCost"
                style={{ padding: "12px 15px" }}
                value={
                  existingCampaign || existingClone
                    ? campaignUpdateData.budgetedCost
                    : campaignData.budgetedCost
                }
                onChange={handleChangeCampaign}
                onBlur={() => handleBlur("budgetedCost")}
                onKeyPress={handleKeyPress}
              />
              <span
                className="clear-button position-absolute px-1"
                style={{ left: "0px", bottom: "9px" }}
              >
                ₹
              </span>
              {showTooltipBudgetedCost && (
                <div style={{ ...tooltipStyles, bottom: "30px" }}>
                  {"= " + tooltipContent}
                </div>
              )}
              <span
                className="small erro_msg position-absolute"
                style={{ left: "0px", bottom: "-20px" }}
              >
                {budgetCostMsg && budgetCostMsg}
              </span>
            </div>
          </div>
          <div className="inputFieldDiv">
            <div className="inputFieldDivLeft position-relative">
              <p className="mb-0">Actual Cost</p>
              <input
                type="text"
                className="insertCommonInputField"
                pattern="[0-9]*"
                maxLength={16}
                autoComplete="off"
                name="actualCost"
                style={{ padding: "12px 15px" }}
                value={
                  existingCampaign || existingClone
                    ? campaignUpdateData.actualCost
                    : campaignData.actualCost
                }
                onChange={handleChangeCampaign}
                onBlur={() => handleBlur("actualCost")}
                onKeyPress={handleKeyPress}
              />
              <span
                className="clear-button position-absolute px-1"
                style={{ left: "0px", bottom: "9px" }}
              >
                ₹
              </span>
              {showTooltipActualCost && (
                <div style={{ ...tooltipStyles, bottom: "30px" }}>
                  {"= " + tooltipContent}
                </div>
              )}
              <p
                className="small erro_msg position-absolute"
                style={{ left: "0px", bottom: "-35px" }}
              >
                {actualMsg && actualMsg}
              </p>
            </div>
            <div className="inputFieldDivRight">
              <p className="mb-0">Expected Response</p>
              <input
                type="number"
                className="insertCommonInputField"
                pattern="[0-9]*"
                maxLength={50}
                autoComplete="off"
                name="ExpectedResponse"
                value={
                  existingCampaign || existingClone
                    ? campaignUpdateData.ExpectedResponse
                    : campaignData.ExpectedResponse
                }
                onChange={handleChangeCampaign}
                // onInput={(e) => {
                //   e.target.value = e.target.value.replace(/[^0-9]/g, "");
                // }}
              />
            </div>
          </div>
          <div className="inputFieldDiv">
            <div className="inputFieldDivLeft">
              <p className="mb-0">Numbers Sent</p>
              <input
                type="text"
                className="insertCommonInputField"
                pattern="[0-9]*"
                maxLength={9}
                autoComplete="off"
                name="numbersSent"
                value={
                  existingCampaign || existingClone
                    ? campaignUpdateData.numbersSent
                    : campaignData.numbersSent
                }
                onChange={handleChangeCampaign}
                onInput={(e) => {
                  e.target.value = e.target.value.replace(/[^0-9]/g, "");
                }}
              />
            </div>
            <div className="inputFieldDivRight">
              <p className="mb-0">Status</p>
              <Select
                value={
                  existingCampaign || existingClone
                    ? campaignUpdateData.campaignStatus
                    : campaignData.campaignStatus
                }
                menuPlacement="auto"
                onChange={(selectedOption) =>
                  handleChangeSelectDropDown(selectedOption, "campaignStatus")
                }
                options={campaignStatusOptions}
                styles={createCampaignStyles}
                isSearchable={false}
              />
            </div>
          </div>
          <h6
            className="py-2 mt-4"
            style={{ borderBottom: "2px solid #316AFF" }}
          >
            Description Information
          </h6>
          <div className="inputFieldDiv">
            <div className="inputFieldDivLeft">
              <p>Description</p>
              <textarea
                rows="4"
                cols="50"
                maxLength="2000"
                style={{
                  width: "100%",
                  border: "1px solid #B9B9B94D",
                  height: "100px",
                  outline: "none",
                  borderRadius: "10px",
                  backgroundColor: "transparent",
                  padding: "8px 12px",
                  resize: "none",
                }}
                value={
                  existingCampaign || existingClone
                    ? campaignUpdateData.description
                    : campaignData.description
                }
                name="description"
                onChange={handleChangeCampaign}
              />
            </div>
          </div>
        </div>
      </>
    );
  };

  const getAllInfoShare = async (campaignId) => {
    setIsLoading(true);
    const requestBody = {
      moduleId: campaignId,
      loggedInUserId: loginUserId,
    };

    const headersResult = getHeaders(true);

    // Check if headers result indicates an error
    if (headersResult.error) {
      navigate("../"); // Redirect to login if token is missing
      return;
    }
    await axios
      .post(URI.getShareInfo, requestBody, {
        headers: headersResult,
      })
      .then((response) => {
        if (response.data.status === 200) {
          const hasSharedItems =
            (response.data.data.getSharingInfoUsers &&
              response.data.data.getSharingInfoUsers.length > 0) ||
            (response.data.data.getSharingInfoRoles &&
              response.data.data.getSharingInfoRoles.length > 0) ||
            (response.data.data.getSharingInfoGroups &&
              response.data.data.getSharingInfoGroups.length > 0)||
              (response.data.data.getSharingInfoPublic &&
                response.data.data.getSharingInfoPublic.length > 0);

          const userShareMemberIds = response.data.data.getSharingInfoUsers.map(
            (user) => user.shareModuleId
          );
          const roleShareMemberIds = response.data.data.getSharingInfoRoles.map(
            (role) => role.shareModuleId
          );
          const groupShareMemberIds =
            response.data.data.getSharingInfoGroups.map(
              (group) => group.shareModuleId
            );
            const groupPublicSharedMemberIds =
            response.data.data.getSharingInfoPublic.map(
              (user) => user.shareModuleId
            );
          setShareMemberIds([
            ...userShareMemberIds,
            ...roleShareMemberIds,
            ...groupShareMemberIds,
            ...groupPublicSharedMemberIds
          ]);

          setShareUserList(response.data.data.getSharingInfoUsers);
          setShareRoleList(response.data.data.getSharingInfoRoles);
          setShareGroupList(response.data.data.getSharingInfoGroups);
          setSharePublicList(response.data.data.getSharingInfoPublic);
          if (hasSharedItems) {
            setShareInfoModal(true);
          } else {
            setShareModal(true);
          }
          setIsLoading(false);
        }
      })
      .catch((err) => {
        handleApiError(err, navigate);
      });
  };

  const individualCampaignDetailPage = () => {
    return (
      <>
        <div className="indiv_main_container ">
          {/* <p className="text-danger fw-bolder">Work in progress... </p> */}
          <header className="commonContainer_bg w-100">
            <div className="d-flex justify-content-between px-3 pt-2">
              <span
                className="cursorPointer"
                onClick={() => {
                  setInnerPage("list");
                  getAllCampaignDetails(selectedOption);
                  setSelectedCampaignId("");
                  setSelectedCampaignName("");
                  const resetFields = Object.fromEntries(
                    Object.keys(editingFields).map((key) => [key, false])
                  );
                  setEditingFields({
                    ...resetFields,
                  });
                  setShowType("");
                }}
              >
                {leftArrow}
              </span>
              <span>
                <span
                  className={`me-2 ${
                    selectedLeadIndex === 0 ? "opacity-50" : "cursorPointer"
                  }`}
                  onClick={() => {
                    const leadIndex = allList.findIndex(
                      (camp) => camp.campaignId === campaignId
                    );
                    if (leadIndex !== 0) {
                      setSelectedLeadIndex(leadIndex - 1);
                      setCampaignId(allList[leadIndex - 1].campaignId);
                      campaignOverviewDetails(
                        allList[leadIndex - 1].campaignId
                      );
                    }
                  }}
                >
                  {leftAngleArrow}
                </span>
                <span
                  className={`ms-1 ${
                    selectedLeadIndex === allList.length - 1
                      ? "opacity-50"
                      : "cursorPointer"
                  }`}
                  onClick={() => {
                    const leadIndex = allList.findIndex(
                      (camp) => camp.campaignId === campaignId
                    );
                    if (leadIndex < allList.length - 1) {
                      setSelectedLeadIndex(leadIndex + 1);
                      setCampaignId(allList[leadIndex + 1].campaignId);
                      campaignOverviewDetails(
                        allList[leadIndex + 1].campaignId
                      );
                    }
                  }}
                >
                  {rightAngleArrow}
                </span>
              </span>
            </div>
            <div className=" d-flex justify-content-between">
              <div className="profile_logo_container d-flex pt-2">
                <div className="d-flex flex-column  justify-content-center ps-2">
                  <span>{campaignInfoDetails?.campaignName}</span>
                  <span className="opacity-75 ">
                    {/* ₹{" " + dealOverviewDetails.amount} */}
                  </span>
                </div>
              </div>
              <div className="d-flex  justify-content-end align-items-end  px-2 pb-2">
                <div className="d-flex gap-2">
                  {/* <button className="btn  btnColor text-white">Send Email</button> */}
                  <button
                    className="btn btn-secondary bg-white text-dark border border-primary"
                    onClick={handleEditCampaign}
                  >
                    Edit
                  </button>
                  <button
                    className="btn btn-secondary bg-white text-dark border border-primary"
                    onClick={(e) => {
                      e.stopPropagation();
                      setShowOptionsCampaign(!showOptionsCampaign);
                    }}
                  >
                    ---
                  </button>
                  {showOptionsCampaign && (
                    <div
                      className="rounded  showOptions p-2 end-0"
                      style={{ top: "11.5rem" }}
                    >
                      <div
                        className="d-flex gap-3 align-items-center mt-2"
                        onClick={handleCreateCampaignClone}
                      >
                        <img src={Close} alt="close" />
                        <div
                          style={{ color: "#515151", fontWeight: "300" }}
                          className="mt-1 cursorPointer ms-2"
                        >
                          Clone
                        </div>
                      </div>
                      {campaignInfoDetails.isShared === false ? (
                        <div
                          className="d-flex gap-3 align-items-center mt-2"
                          onClick={() => {
                            if (!isLoading) {
                              getAllInfoShare(campaignId);
                            }
                          }}
                        >
                          <img src={Share} alt="share" />
                          <div
                            style={{ color: "#515151", fontWeight: "300" }}
                            className="mt-2 cursorPointer ms-2"
                          >
                            Share
                          </div>
                        </div>
                      ) : (
                        ""
                      )}
                      <div
                        className="d-flex gap-3 align-items-center mt-2"
                        onClick={handleDeleteCampaign}
                      >
                        <img src={Delete} alt="close" />
                        <div
                          style={{ color: "#515151", fontWeight: "300" }}
                          className="mt-2 cursorPointer ms-2"
                        >
                          Delete
                        </div>
                      </div>
                      <div style={{ borderBottom: "1px solid #515151s" }}></div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </header>

          <div className="detailList_main_container mt-2 commonContainer_bg">
            <div className="detailList_main_container d-flex py-2 ">
              <div
                className={` pt-3 cursorPointer ${isShrinked ? "" : "w-25"}`}
                onClick={() => {
                  setIsShrinked(!isShrinked);
                }}
              >
                {isShrinked ? expandArrow : shrinkArrow}
              </div>
              <div
                className={`d-flex justify-content-between px-2 ${
                  isShrinked ? "w-100" : "w-75 ps-3"
                }`}
              >
                <span className="pt-3 ps-2">
                  Last Update : {campaignInfoDetails.lastUpdate}
                </span>
                <div className="d-flex gap-4 justify-content-between px-2 bg-white px-1  rounded-5 py-1">
                  <span
                    // className=""
                    className={`cursorPointer ${
                      leadPageView === "overview"
                        ? "overviewTimelineTab "
                        : "  d-flex justify-content-center align-items-center"
                    }`}
                    onClick={() => setLeadPageView("overview")}
                  >
                    Overview
                  </span>
                  <span
                    className={`cursorPointer ${
                      leadPageView === "timeline"
                        ? "overviewTimelineTab"
                        : " d-flex justify-content-center align-items-center"
                    }`}
                    onClick={() => {
                      setLeadPageView("timeline");
                      getAllTimeleadLeads(campaignId);
                    }}
                  >
                    Timeline
                  </span>
                </div>
              </div>
            </div>

            <div className="detailList_content_container bg-white d-flex justify-content-between px-2 pt-2">
              <div
                className={`w-25 relatedListContainer rounded commonContainer_bg  me-2 ${
                  isShrinked ? "d-none" : ""
                }`} // style={{ height: "100vh" }}
              >
                <div className="d-flex pt-2">
                  <p className="opacity-57 ps-2 opacity-75 m-0 py-2">
                    Related List
                  </p>
                </div>
                <div className="horizontal_line mx-1"></div>
                <ul className="relateList_container">
                  {notePermission === "TRUE" && (
                    <li
                      className={`cursorPointer py-2 relatedList_tab rounded-1 }`}
                      onClick={() => {
                        if (leadPageView === "timeline") {
                          setLeadPageView("overview");
                          setTimeout(() => scrollToSection("notesSection"), 0);
                        } else {
                          scrollToSection("notesSection");
                        }
                      }}
                    >
                      <span>
                        <img src={NoteBook} alt="img" height={20} width={20} />
                      </span>
                      Notes
                    </li>
                  )}

                  <li
                    className={`cursorPointer py-2 relatedList_tab rounded-1 }`}
                    onClick={() => {
                      if (leadPageView === "timeline") {
                        setLeadPageView("overview");
                        setTimeout(() => scrollToSection("openActivity"), 0);
                      } else {
                        scrollToSection("openActivity");
                      }
                    }}
                  >
                    <span>
                      <img
                        src={openActivityIcon}
                        alt="img"
                        height={20}
                        width={20}
                      />
                    </span>
                    Open Activities
                  </li>
                  <li
                    className={`cursorPointer py-2 relatedList_tab rounded-1 }`}
                    onClick={() => {
                      if (leadPageView === "timeline") {
                        setLeadPageView("overview");
                        setTimeout(() => scrollToSection("closeActivity"), 0);
                      } else {
                        scrollToSection("closeActivity");
                      }
                    }}
                  >
                    <span>
                      <img
                        src={closeActivityIcon}
                        alt="img"
                        height={20}
                        width={20}
                      />
                    </span>
                    Closed Activities
                  </li>
                  <li
                    className={`cursorPointer py-2 relatedList_tab rounded-1`}
                    onClick={() => {
                      if (leadPageView === "timeline") {
                        setLeadPageView("overview");
                        setTimeout(() => scrollToSection("contact"), 0);
                      } else {
                        scrollToSection("contact");
                      }
                    }}
                  >
                    <span>
                      <img src={noteIcon} alt="img" height={20} width={20} />
                    </span>
                    Contact
                  </li>

                  <li
                    className={`cursorPointer py-2 relatedList_tab rounded-1`}
                    onClick={() => {
                      if (leadPageView === "timeline") {
                        setLeadPageView("overview");
                        setTimeout(() => scrollToSection("leads"), 0);
                      } else {
                        scrollToSection("leads");
                      }
                    }}
                  >
                    <span>
                      <img
                        src={leadListIcon}
                        alt="img"
                        height={20}
                        width={20}
                        style={{ color: "red" }}
                      />
                    </span>
                    Leads{" "}
                  </li>

                  <li
                    className={`cursorPointer py-2 relatedList_tab rounded-1`}
                    onClick={() => {
                      if (leadPageView === "timeline") {
                        setLeadPageView("overview");
                        setTimeout(() => scrollToSection("deals"), 0);
                      } else {
                        scrollToSection("deals");
                      }
                    }}
                  >
                    <span>
                      <img
                        src={DealListIcon}
                        alt="img"
                        height={20}
                        width={20}
                      />
                    </span>
                    Deals
                  </li>
                </ul>
              </div>

              {leadPageView === "overview" ? (
                <div
                  className={`bg-white w-75 rounded-2 leadDetail_main_Container ${
                    isShrinked ? "w-100" : "w-75"
                  }`}
                >
                  <div className=" common_bg ">
                    <div className="lead_DetailContainer     rounded-2  commonContainer_bg d-flex justify-content-between">
                      <div className="w-100 d-flex flex-column">
                        {fieldLabels.map((field, i) => {
                          const fieldValue = getFieldname(field.name);
                          return field.isShowed ? (
                            <div className="d-flex w-100 ps-3" key={i}>
                              <div className="w-25 position-relative">
                                <div
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    setSearchField("");
                                    setSelectedField(
                                      selectedField === field.name
                                        ? ""
                                        : field.name
                                    );
                                  }}
                                  className={`cursorPointer d-flex justify-content-between w-100 my-1 p-1 ${
                                    selectedField === field.name
                                      ? "outline_border rounded-1"
                                      : ""
                                  }`}
                                >
                                  <span>{field.name}</span>
                                  <img
                                    src={
                                      selectedField === field.name ? up : down
                                    }
                                    alt="img"
                                    height={20}
                                    width={20}
                                    className="pt-1"
                                  />
                                </div>
                                {selectedField === field.name && (
                                  <div
                                    className="position-absolute field_container w-100"
                                    onClick={(e) => {
                                      e.stopPropagation();
                                    }}
                                  >
                                    <div className="">
                                      <label
                                        htmlFor="selectField"
                                        className="position-relative w-100"
                                      >
                                        <input
                                          id="selectField"
                                          type="text"
                                          className="rounded-2 p-1 w-100 pe-4"
                                          placeholder="Search"
                                          value={searchField}
                                          onChange={(e) => {
                                            if (
                                              e.target.value.charAt(0) !== " "
                                            ) {
                                              const value =
                                                e.target.value.replace(
                                                  /[^a-zA-Z ]+|(\s)\s+/g,
                                                  "$1"
                                                );
                                              setSearchField(value);
                                            }
                                          }}
                                        />
                                        <img
                                          className="position-absolute mt-1 me-2 end-0 pt-1 cursorPointer"
                                          onClick={() => {
                                            setSearchField("");
                                          }}
                                          src={searchIcon}
                                          alt="search"
                                          width={20}
                                        />
                                      </label>

                                      <div className="w-100 rounded-2">
                                        <div className="border border-1 field_container_dropdown rounded-1">
                                          {showSearchFields
                                            .filter((field) =>
                                              field.name
                                                .toLowerCase()
                                                .includes(
                                                  searchField
                                                    .trim()
                                                    .toLowerCase()
                                                )
                                            )
                                            .sort((a, b) =>
                                              a.name.localeCompare(b.name)
                                            )
                                            .map(
                                              (field, i) =>
                                                (selectedField === field.name ||
                                                  !field.isShowed) && (
                                                  <div
                                                    key={field.name}
                                                    className="d-flex gap-2 p-2 cursorPointer rounded-2 align-items-center"
                                                    onClick={() => {
                                                      selectedField ===
                                                      field.name
                                                        ? setSelectedField("")
                                                        : handleFieldClick(
                                                            field,
                                                            i
                                                          );
                                                    }}
                                                  >
                                                    <span className="right_tick">
                                                      {selectedField ===
                                                      field.name
                                                        ? tick
                                                        : ""}
                                                    </span>
                                                    <span className="ps-2">
                                                      {field.name}
                                                    </span>
                                                  </div>
                                                )
                                            )}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                )}
                              </div>
                              <div
                                className={`w-50 mt-1 ms-5 position-relative`}
                              >
                                {field.type === "input" ||
                                field.type === "date" ? (
                                  <EditableInput
                                    className="ps-3"
                                    fieldType={
                                      field.type === "date" ? "date" : "text"
                                    }
                                    inputType={fieldValue}
                                    handleIndividualInputChange={
                                      handleIndividualInputChange
                                    }
                                    editingFields={editingFields}
                                    handleEditClick={handleEditClick}
                                    handleCancelClick={handleCancelClick}
                                    handleUpdateClick={handleUpdateClick}
                                    updatedFields={updatedFields}
                                    accountOverviewDetails={campaignInfoDetails}
                                    showType={showType}
                                    setShowType={setShowType}
                                  />
                                ) : field.type === "dropdown" ? (
                                  <div
                                    className="position-relative d-flex"
                                    style={{ alignItems: "center" }}
                                    onClick={() => {
                                      handleEditClick(fieldValue);
                                    }}
                                  >
                                    {editingFields[fieldValue] &&
                                    showType === fieldValue ? (
                                      <Select
                                        value={updatedFields[fieldValue]}
                                        // value={campaignInfoDetails[fieldValue]}
                                        menuPlacement="auto"
                                        onChange={(selectedOption) =>
                                          handleChangeIndDropDown(
                                            selectedOption,
                                            fieldValue
                                          )
                                        }
                                        options={
                                          field.name === "Type"
                                            ? campaignTypeOptions
                                            : field.name === "Status"
                                            ? campaignStatusOptions
                                            : campaignStatusOptions
                                        }
                                        styles={fieldDropdownStyles}
                                        isSearchable={false}
                                      />
                                    ) : (
                                      <input
                                        type="text"
                                        className={`cursorPointer indvEditInput
                        ${
                          showType === fieldValue
                            ? "selectedBorder "
                            : "unselectBorder bg-transparent"
                        }`}
                                        value={
                                          campaignInfoDetails[fieldValue]?.value
                                        }
                                        name={fieldValue}
                                        onClick={(e) => {
                                          if (showType === "") {
                                            updatedFields[fieldValue] =
                                              campaignInfoDetails[fieldValue];
                                            setShowType(e.target.name);
                                            setUpdatedFields(updatedFields);
                                          }
                                          handleEditClick(fieldValue);
                                        }}
                                        // readOnly={showType !== "emailId"}
                                        readOnly={true}
                                      />
                                    )}
                                    {editingFields[fieldValue] &&
                                      showType === fieldValue && (
                                        <div className="d-flex gap-2">
                                          <span
                                            onClick={() => {
                                              setShowType("");
                                              handleUpdateClick(fieldValue);
                                            }}
                                            className="ps-2 cursorPointer"
                                          >
                                            <img
                                              src={updateIcon}
                                              alt="img"
                                              height={20}
                                              width={20}
                                            />
                                          </span>
                                          <span
                                            onClick={() => {
                                              setShowType("");
                                              // handleUpdateClick(inputType);
                                              handleCancelClick(fieldValue);
                                            }}
                                            className="cursorPointer"
                                          >
                                            <img
                                              src={cancelIcon}
                                              alt="img"
                                              height={20}
                                              width={20}
                                            />
                                          </span>
                                        </div>
                                      )}
                                  </div>
                                ) : field.type === "readOnly" ? (
                                  <div>
                                    <span className="d-flex flex-column">
                                      <span>
                                        {
                                          campaignInfoDetails[
                                            field.name === "Created By"
                                              ? "createdBy"
                                              : "modifiedBy"
                                          ]
                                        }
                                      </span>
                                      <span className="small opacity-75">
                                        {
                                          campaignInfoDetails[
                                            field.name === "Created By"
                                              ? "createdDate"
                                              : "modifiedDate"
                                          ]
                                        }
                                      </span>
                                    </span>
                                  </div>
                                ) : (
                                  <div>
                                    {field.type === "checkbox" ? (
                                      <div className="position-relative">
                                        <input
                                          type="checkbox"
                                          name={field.name}
                                          onChange={(e) => {
                                            handleEditClick(fieldValue);
                                            setUpdatedFields((prevFields) => ({
                                              ...prevFields,
                                              emailOptOut: e.target.checked,
                                            }));
                                          }}
                                          className="mt-2"
                                        />
                                        {editingFields["emailOptOut"] && (
                                          <div
                                            className="position-absolute end-0 top-0"
                                            style={{ marginRight: "1rem" }}
                                          >
                                            <div className="d-flex gap-2">
                                              <span
                                                onClick={(e) => {
                                                  e.stopPropagation();
                                                  setShowType("");
                                                  handleUpdateClick(fieldValue);
                                                }}
                                                className="ps-2 cursorPointer"
                                              >
                                                <img
                                                  src={updateIcon}
                                                  alt="img"
                                                  height={20}
                                                  width={20}
                                                />
                                              </span>
                                              <span
                                                onClick={(e) => {
                                                  e.stopPropagation();
                                                  setShowType("");
                                                  handleCancelClick(fieldValue);
                                                }}
                                                className="cursorPointer"
                                              >
                                                <img
                                                  src={cancelIcon}
                                                  alt="img"
                                                  height={20}
                                                  width={20}
                                                />
                                              </span>
                                            </div>
                                          </div>
                                        )}
                                      </div>
                                    ) : (
                                      <div className="position-relative">
                                        <div
                                          className="d-flex position-relative"
                                          onClick={(e) => {
                                            handleOwnerInputClick(e);
                                            setShowModalType("changeOwner");
                                            setListVisible(false);
                                          }}
                                        >
                                          <input
                                            type="text"
                                            className="w-100 ps-1 cursorPointer indvEditInput border-0 indivOwnerInput"
                                            value={selectedItem?.name}
                                            readOnly
                                          />
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                )}
                              </div>
                            </div>
                          ) : null;
                        })}
                      </div>
                    </div>

                    {/*------ lead Information Conatiner -----------*/}

                    <div className="lead_Information_mainContainer commonContainer_bg     rounded-2  d-flex flex-column my-2 py-2">
                      <h5 className="w-100 text-primary ps-3 pt-1">
                        Campaign Information
                      </h5>

                      <div className="leadInformation_content_container d-flex justify-content-between pe-4">
                        <div className="leadContact_container d-flex gap-2 w-100 ps-2 pe-5">
                          <div className="w-50">
                            {fieldIndividualDropdowns
                              .slice(0, 7)
                              .map((field) => {
                                const fieldValue =
                                  field.value ||
                                  field.name
                                    .replace(/\s+/g, "")
                                    .replace(/^(.)/, (match) =>
                                      match.toLowerCase()
                                    );
                                return (
                                  <div
                                    className="d-flex justify-content-between align-items-center my-2 w-100 gap-3"
                                    key={fieldValue}
                                  >
                                    <div className="opacity-75 p-2 w-100">
                                      {field.name}
                                    </div>
                                    <div className="w-100">
                                      {field.type === "other" ? (
                                        <div>
                                          <input
                                            type="text"
                                            className={`cursorPointer indvEditInput rounded-1 w-100 ${
                                              showType === "salesOwnerId"
                                                ? "selectedBorder "
                                                : "unselectBorder bg-transparent"
                                            }`}
                                            value={selectedItem?.name}
                                            readOnly
                                          />
                                        </div>
                                      ) : field.type === "input" ? (
                                        <EditableInput
                                          fieldType={
                                            [
                                              "startDateInfo",
                                              "endDateInfo",
                                            ].includes(fieldValue)
                                              ? "date"
                                              : "text"
                                          }
                                          className="ps-3"
                                          inputType={fieldValue}
                                          handleIndividualInputChange={
                                            handleIndividualInputChange
                                          }
                                          editingFields={editingFields}
                                          handleEditClick={handleEditClick}
                                          handleCancelClick={handleCancelClick}
                                          handleUpdateClick={handleUpdateClick}
                                          updatedFields={updatedFields}
                                          accountOverviewDetails={
                                            campaignInfoDetails
                                          }
                                          setShowType={setShowType}
                                          showType={showType}
                                          fieldsType="second"
                                        />
                                      ) : field.type === "dropdown" ? (
                                        <div
                                          className="position-relative d-flex"
                                          style={{ alignItems: "center" }}
                                          onClick={() => {
                                            handleEditClick(fieldValue);
                                          }}
                                        >
                                          {editingFields[fieldValue] &&
                                          showType === fieldValue ? (
                                            <Select
                                              value={updatedFields[fieldValue]}
                                              // value={campaignInfoDetails[fieldValue]}
                                              menuPlacement="auto"
                                              onChange={(selectedOption) =>
                                                handleChangeIndDropDown(
                                                  selectedOption,
                                                  fieldValue
                                                )
                                              }
                                              options={
                                                field.name === "Type"
                                                  ? campaignTypeOptions
                                                  : field.name === "Status"
                                                  ? campaignStatusOptions
                                                  : campaignStatusOptions
                                              }
                                              styles={fieldDropdownStyles}
                                              isSearchable={false}
                                            />
                                          ) : (
                                            <input
                                              type="text"
                                              className={`cursorPointer indvEditInput
                            ${
                              showType === fieldValue
                                ? "selectedBorder "
                                : "unselectBorder bg-transparent"
                            }`}
                                              value={
                                                campaignInfoDetails[fieldValue]
                                                  ?.value
                                              }
                                              name={fieldValue}
                                              onClick={(e) => {
                                                if (showType === "") {
                                                  updatedFields[fieldValue] =
                                                    campaignInfoDetails[
                                                      fieldValue
                                                    ];
                                                  setShowType(e.target.name);
                                                  setUpdatedFields(
                                                    updatedFields
                                                  );
                                                }
                                                handleEditClick(fieldValue);
                                              }}
                                              // readOnly={showType !== "emailId"}
                                              readOnly={true}
                                            />
                                          )}
                                          {editingFields[fieldValue] &&
                                            showType === fieldValue && (
                                              <div className="d-flex gap-2">
                                                <span
                                                  onClick={() => {
                                                    setShowType("");
                                                    handleUpdateClick(
                                                      fieldValue
                                                    );
                                                  }}
                                                  className="ps-2 cursorPointer"
                                                >
                                                  <img
                                                    src={updateIcon}
                                                    alt="img"
                                                    height={20}
                                                    width={20}
                                                  />
                                                </span>
                                                <span
                                                  onClick={() => {
                                                    setShowType("");
                                                    // handleUpdateClick(inputType);
                                                    handleCancelClick(
                                                      fieldValue
                                                    );
                                                  }}
                                                  className="cursorPointer"
                                                >
                                                  <img
                                                    src={cancelIcon}
                                                    alt="img"
                                                    height={20}
                                                    width={20}
                                                  />
                                                </span>
                                              </div>
                                            )}
                                        </div>
                                      ) : field.type === "readOnly" ? (
                                        <div>
                                          <span className="d-flex flex-column py-0">
                                            <span className="pb-0">
                                              {
                                                campaignInfoDetails[
                                                  field.name === "Created By"
                                                    ? "createdBy"
                                                    : field.name ===
                                                      "Modified By"
                                                    ? "modifiedBy"
                                                    : "parentAccountName"
                                                ]
                                              }
                                            </span>
                                            <span className="small opacity-75 pt-0">
                                              {
                                                campaignInfoDetails[
                                                  field.name === "Created By"
                                                    ? "createdDate"
                                                    : "modifiedDate"
                                                ]
                                              }
                                            </span>
                                          </span>
                                        </div>
                                      ) : (
                                        <div className="position-relative">
                                          <div
                                            className="d-flex  position-relative"
                                            onClick={(e) => {
                                              handleOwnerInputClick(e);
                                              setShowModalType("changeOwner");
                                            }}
                                          >
                                            <input
                                              type="text"
                                              className="w-100 cursorPointer  indvEditInput border-0 indivOwnerInput"
                                              value={selectedItem?.name}
                                              readOnly
                                            />
                                          </div>
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                );
                              })}
                          </div>
                          <div className="w-50 ps-5 pe-0">
                            {fieldIndividualDropdowns
                              .slice(7, 13)
                              .map((field) => {
                                const fieldValue =
                                  field.value ||
                                  field.name
                                    .replace(/\s+/g, "")
                                    .replace(/^(.)/, (match) =>
                                      match.toLowerCase()
                                    );
                                return (
                                  <div
                                    className="d-flex justify-content-between align-items-center my-2 w-100 gap-3"
                                    key={fieldValue}
                                  >
                                    <div className="opacity-75 p-2 w-100">
                                      {field.name}
                                    </div>
                                    <div className="w-100">
                                      {field.type === "other" ? (
                                        <div>
                                          <input
                                            type="text"
                                            className={`cursorPointer indvEditInput rounded-1 w-100 ${
                                              showType === "salesOwnerId"
                                                ? "selectedBorder "
                                                : "unselectBorder bg-transparent"
                                            }`}
                                            value={selectedItem?.name}
                                            readOnly
                                          />
                                        </div>
                                      ) : field.type === "input" ? (
                                        <EditableInput
                                          fieldType={
                                            [
                                              "startDateInfo",
                                              "endDateInfo",
                                            ].includes(fieldValue)
                                              ? "date"
                                              : "text"
                                          }
                                          className="ps-3"
                                          inputType={fieldValue}
                                          handleIndividualInputChange={
                                            handleIndividualInputChange
                                          }
                                          editingFields={editingFields}
                                          handleEditClick={handleEditClick}
                                          handleCancelClick={handleCancelClick}
                                          handleUpdateClick={handleUpdateClick}
                                          updatedFields={updatedFields}
                                          accountOverviewDetails={
                                            campaignInfoDetails
                                          }
                                          setShowType={setShowType}
                                          showType={showType}
                                          fieldsType="second"
                                        />
                                      ) : field.type === "dropdown" ? (
                                        <div
                                          className="position-relative d-flex"
                                          style={{ alignItems: "center" }}
                                          onClick={() => {
                                            handleEditClick(fieldValue);
                                          }}
                                        >
                                          {editingFields[fieldValue] &&
                                          showType === fieldValue ? (
                                            <Select
                                              value={updatedFields[fieldValue]}
                                              // value={campaignInfoDetails[fieldValue]}
                                              menuPlacement="auto"
                                              onChange={(selectedOption) =>
                                                handleChangeIndDropDown(
                                                  selectedOption,
                                                  fieldValue
                                                )
                                              }
                                              options={
                                                field.name === "Type"
                                                  ? campaignTypeOptions
                                                  : field.name === "Status"
                                                  ? campaignStatusOptions
                                                  : campaignStatusOptions
                                              }
                                              styles={fieldDropdownStyles}
                                              isSearchable={false}
                                            />
                                          ) : (
                                            <input
                                              type="text"
                                              className={`cursorPointer indvEditInput
                            ${
                              showType === fieldValue
                                ? "selectedBorder "
                                : "unselectBorder bg-transparent"
                            }`}
                                              value={
                                                campaignInfoDetails[fieldValue]
                                                  ?.value
                                              }
                                              name={fieldValue}
                                              onClick={(e) => {
                                                if (showType === "") {
                                                  updatedFields[fieldValue] =
                                                    campaignInfoDetails[
                                                      fieldValue
                                                    ];
                                                  setShowType(e.target.name);
                                                  setUpdatedFields(
                                                    updatedFields
                                                  );
                                                }
                                                handleEditClick(fieldValue);
                                              }}
                                              // readOnly={showType !== "emailId"}
                                              readOnly={true}
                                            />
                                          )}
                                          {editingFields[fieldValue] &&
                                            showType === fieldValue && (
                                              <div className="d-flex gap-2">
                                                <span
                                                  onClick={() => {
                                                    setShowType("");
                                                    handleUpdateClick(
                                                      fieldValue
                                                    );
                                                  }}
                                                  className="ps-2 cursorPointer"
                                                >
                                                  <img
                                                    src={updateIcon}
                                                    alt="img"
                                                    height={20}
                                                    width={20}
                                                  />
                                                </span>
                                                <span
                                                  onClick={() => {
                                                    setShowType("");
                                                    // handleUpdateClick(inputType);
                                                    handleCancelClick(
                                                      fieldValue
                                                    );
                                                  }}
                                                  className="cursorPointer"
                                                >
                                                  <img
                                                    src={cancelIcon}
                                                    alt="img"
                                                    height={20}
                                                    width={20}
                                                  />
                                                </span>
                                              </div>
                                            )}
                                        </div>
                                      ) : field.type === "readOnly" ? (
                                        <div>
                                          <span className="d-flex flex-column py-0">
                                            <span className="pb-0">
                                              {
                                                campaignInfoDetails[
                                                  field.name === "Created By"
                                                    ? "createdBy"
                                                    : field.name ===
                                                      "Modified By"
                                                    ? "modifiedBy"
                                                    : "parentAccountName"
                                                ]
                                              }
                                            </span>
                                            <span className="small opacity-75 pt-0">
                                              {
                                                campaignInfoDetails[
                                                  field.name === "Created By"
                                                    ? "createdDate"
                                                    : "modifiedDate"
                                                ]
                                              }
                                            </span>
                                          </span>
                                        </div>
                                      ) : (
                                        <div className="position-relative">
                                          <div
                                            className="d-flex  position-relative"
                                            onClick={(e) => {
                                              // setIsProductForm(true);
                                              handleOwnerInputClick(e);
                                              setShowModalType("changeOwner");
                                            }}
                                          >
                                            <input
                                              type="text"
                                              className="w-100 cursorPointer  indvEditInput border-0 indivOwnerInput"
                                              value={selectedItem?.name}
                                              readOnly
                                            />
                                          </div>
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                );
                              })}
                          </div>
                        </div>
                      </div>
                      <h5 className="w-100 text-primary ps-3 pt-1">
                        Description
                      </h5>
                      <div className="d-flex gap-2 ps-3 pt-1">
                        <span className="opacity-75 px-1">Description</span>
                        {/* <p>{leadOverviewDetails.description}</p> */}
                        <EditableInput
                          fieldType="text"
                          inputType="description"
                          handleIndividualInputChange={
                            handleIndividualInputChange
                          }
                          editingFields={editingFields}
                          handleEditClick={handleEditClick}
                          handleCancelClick={handleCancelClick}
                          handleUpdateClick={handleUpdateClick}
                          updatedFields={updatedFields}
                          accountOverviewDetails={campaignInfoDetails}
                          showType={showType}
                          setShowType={setShowType}
                          isTextarea={true}
                        />
                      </div>
                    </div>
                  </div>

                  {notePermission === "TRUE" && (
                    <div
                      id="notesSection"
                      className="lead_Information_mainContainer commonContainer_bg rounded-2  d-flex flex-column py-2 mt-2"
                    >
                      <Note
                        note={note}
                        addTitle={addTitle}
                        showButtons={showButtons}
                        setShowButtons={setShowButtons}
                        textFile={selectedNoteFiles}
                        showTitleNote={showTitleNote}
                        setShowTitleNote={setShowTitleNote}
                        handleTextAreaClick={handleTextAreaClick}
                        handleTextFileChange={handleTextFileChange}
                        handleSaveButton={insertNote}
                        handleAddTitleClick={handleAddTitleClick}
                        setNoteTitle={setNoteTitle}
                        noteInfo={noteInfo}
                        selectRecent={selectRecent}
                        handleSelectRecent={handleSelectRecent}
                        handleDeleteClick={deleteNoteDetails}
                        leadId={campaignId}
                        handleUpdateClick={updateNote}
                        setUpdateNotes={setUpdateNotes}
                        updateNotes={updateNotes}
                        setEditNote={setEditNote}
                        editNote={editNote}
                        handleChangeNoteTitle={handleChangeNoteTitle}
                        handleChangeNoteDescription={
                          handleChangeNoteDescription
                        }
                        setAddTitle={setAddTitle}
                        setNote={setNote}
                        moduleType={"Campaigns"}
                        // recordName={campaignOverviewDetail?.dealName}
                        selectedNoteFiles={selectedNoteFiles}
                        setSelectedNoteFiles={setSelectedNoteFiles}
                        onChildDataChange={handleChildDataChange}
                        noteDeleteConfirmation={noteDeleteConfirmation}
                        setNoteDeleteConfirmation={setNoteDeleteConfirmation}
                      />

                      {showTitleNote && (
                        <textarea
                          rows={2}
                          className="notesClass px-2"
                          placeholder="Add note Title"
                          value={noteTitle}
                          onChange={(e) => setNoteTitle(e.target.value)}
                          style={{ width: "95%", marginTop: "10px" }}
                        />
                      )}
                    </div>
                  )}
                  <div
                    id="openActivity"
                    className="lead_Information_mainContainer commonContainer_bg rounded-2  d-flex flex-column py-2 mt-4"
                  >
                    <OpenActivity
                      openActivities={openActivities}
                      getOverview={() => accountOverviewDetail(accountId)}
                      openActivitiesCalls={openActivitiesCalls}
                      openActivitiesMeetings={openActivitiesMeetings}
                      openActivitiesTasks={openActivitiesTasks}
                      moduleName="Campaign"
                    />
                  </div>

                  <div
                    id="closeActivity"
                    className="lead_Information_mainContainer commonContainer_bg rounded-2  d-flex flex-column py-2 mt-4"
                  >
                    <CloseActivity
                      closeActivities={closeActivities}
                      getOverview={() => accountOverviewDetail(accountId)}
                      closeActivitiesCalls={closedActivitiesCall}
                      closeActivitiesMeetings={closedActivitiesMeetings}
                  closedActivitiesTasks={closedActivitiesTasks}
                    />
                  </div>

                  <div
                    id="contact"
                    className="lead_Information_mainContainer commonContainer_bg rounded-2  d-flex flex-column py-2 mt-4"
                  >
                    <ContactList
                      module={"Campaigns"}
                      contactList={contactList || []}
                      setContactList={setContactList}
                      assignContactID={campaignId}
                      handleAssignModule={handleAssignModule}
                      selectedContactId={selectedContactId}
                      setSelectedContactId={setSelectedContactId}
                      handleAssignProductModule={() =>
                        handleAssignModule(selectedContactId, "CONTACTS")
                      }
                      handledeleteModule={(campaignId) =>
                        handledeleteModule(campaignId, "CONTACTS")
                      }
                      selectedHeadings={selectedHeadingsContact}
                      showHeadings={showHeadingsContact}
                      setShowSelectedFilter={setShowSelectedFilter}
                      setShowHeadings={setShowHeadingsContact}
                      manageColumnsAction={() => {
                        setShowModalType("Manage Contact Columns");
                        setSearchColumn("");
                        setSavingHeadings(contactColumn);
                      }}
                      setSearchColumn={setSearchColumn}
                      setSavingHeadings={setSavingHeadings}
                      resetColumnSizeAction={() => alert("Reset Column Size")}
                      data={filterContactDetails}
                      onRowClick={(index) => alert(`Row ${index} clicked`)}
                      apiLoader={apiLoader}
                    />
                  </div>
                  <div
                    id="leads"
                    className="lead_Information_mainContainer commonContainer_bg rounded-2  d-flex flex-column py-2 mt-4"
                  >
                    <LeadList
                      module={"Campaigns"}
                      leadList={leadList || []}
                      setLeadList={setLeadList}
                      assignContactID={campaignId}
                      handleAssignModule={handleAssignModule}
                      selectedLeadId={selectedLeadId}
                      setSelectedLeadId={setSelectedLeadId}
                      handleAssignProductModule={() =>
                        handleAssignModule(selectedLeadId, "LEADS")
                      }
                      handledeleteModule={(campaignId) =>
                        handledeleteModule(campaignId, "LEADS")
                      }
                      selectedHeadings={selectedHeadingsLead}
                      showHeadings={showHeadingsLead}
                      setShowSelectedFilter={setShowSelectedFilter}
                      setShowHeadings={setShowHeadingsLead}
                      manageColumnsAction={() => {
                        setShowModalType("Manage Lead Columns");
                        setSearchColumn("");
                        setSavingHeadings(leadsColumn);
                      }}
                      setSearchColumn={setSearchColumn}
                      setSavingHeadings={setSavingHeadings}
                      resetColumnSizeAction={() => alert("Reset Column Size")}
                      data={filterLeadDetails}
                      onRowClick={(index) => alert(`Row ${index} clicked`)}
                      apiLoader={apiLoader}
                    />
                  </div>

                  <div
                    id="deals"
                    // className="lead_Information_mainContainer commonContainer_bg rounded-2  d-flex flex-column py-2 mt-4"
                  >
                    <DealsCommonTable
                      title="Deals"
                      buttonLabel="New Deals"
                      buttonAction={() => {
                        setCurrentView("deals");
                        setInnerPage("create");
                        setPastViewInfo((prev) => ({
                          ...prev,
                          view: currentView,
                          innerPage: innerPage,
                        }));
                      }}
                      showHeadings={showHeadingsDeals}
                      setShowHeadings={setShowHeadingsDeals}
                      selectedHeadings={selectedHeadingsDeals}
                      data={filterDealDetails}
                      onRowClick={(index) => alert(`Row ${index} clicked`)}
                      manageColumnsAction={() => {
                        setShowModalType("Manage Deals Columns");
                        setSearchColumn("");
                        setSavingHeadings(dealsColumn);
                      }}
                      resetColumnSizeAction={() => alert("Reset Column Size")}
                      setShowSelectedFilter={setShowSelectedFilter}
                      selectedDealsId={selectedDealsId}
                      setSelectedDealsId={setSelectedDealsId}
                      setSearchColumn={setSearchColumn}
                      setSavingHeadings={setSavingHeadings}
                      handleAssignDealsModule={handleAssignDealsModule}
                      handledeleteModuleDeal={(campaignId) =>
                        handledeleteModuleDeal(campaignId)
                      }
                    />
                  </div>
                </div>
              ) : (
                <div
                  className={`timeline_main_container d-flex flex-column gap-4 ${
                    isShrinked ? "w-100" : "w-75"
                  }`}
                >
                  {/* <div className="upcomingAction_container w-100 flex-column  py-2  d-flex  commonContainer_bg rounded-2">
                    <div className="header d-flex gap-2 ps-2">
                      <span>
                        <img src={hierachy} alt="img" height={20} width={20} />
                      </span>
                      <span>Upcoming Actions</span>
                    </div>
                    <div className="horizontal_line w-100 opacity-25 mx-1"></div>

                    <div
                      className="d-flex justify-content-center align-items-center "
                      style={{ height: "25vh" }}
                    >
                      <h4 className="opacity-75 ">No upcoming actions</h4>
                    </div>
                  </div> */}

                  <div className="w-100 historyMain_Container flex-column   d-flex  commonContainer_bg rounded-2 ">
                    <div className="header d-flex gap-2 px-2 py-2 align-items-center">
                      <span>
                        <img src={history} alt="img" height={20} width={20} />
                      </span>
                      <span>History</span>
                    </div>
                    <div className="horizontal_line w-100 mx-1"></div>
                    <div className="historyContent_mainContainer ">
                      {timeLineDataList &&
                      Object.keys(timeLineDataList).length > 0 ? (
                        Object.keys(timeLineDataList).map((date) => (
                          <div
                            className="history_container pt-3 d-flex flex-column"
                            key={date}
                          >
                            <span className="px-2 timelineBg_color border border-primary rounded-5 py-2 mx-5">
                              {date}
                            </span>
                            {timeLineDataList[date].map((item, index) => (
                              <div className="d-flex gap-2" key={index}>
                                <span className="opacity-75 pt-5 ps-2">
                                  {item.createdTime}
                                </span>
                                <div className="d-flex flex-column align-items-center">
                                  <p className="vertical_line m-0 p-0"></p>
                                  <span>
                                    <img
                                      src={groupIcon2}
                                      alt="img"
                                      height={25}
                                      width={25}
                                    />
                                  </span>
                                </div>
                                <span className="pt-5">
                                  {`${item.tmlDescription} ${item.tmlCreatedBy} ${date}`}
                                </span>
                              </div>
                            ))}
                          </div>
                        ))
                      ) : (
                        <div className="text-center d-flex justify-content-center align-items-center h-50 mt-2">
                          <h4>No Data available</h4>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        <Modal
          show={deleteModal}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
        >
          <Modal.Header
            onClick={() => {
              setDeleteModal(false);
            }}
            style={{
              fontSize: "20px",
              fontWeight: "bold",
              background:
                "linear-gradient(to right, rgba(64, 123, 255, 1), rgba(18, 167, 254, 1))",
              WebkitBackgroundClip: "text",
              backgroundClip: "text",
              WebkitTextFillColor: "transparent",
            }}
          >
            Are you sure you want to delete &nbsp;
            <span>{campaignInfoDetails?.campaignName}</span>?
          </Modal.Header>
          <Modal.Body className="ModalBody overflow-auto">
            <div className="d-flex">
              <p className="mb-0 fw-bold">Note :&nbsp;</p>
              <span>
                Any associated activities, visits, drafts will also be deleted.
              </span>
            </div>
            <div className="d-flex flex-column gap-4">
              <div className="d-flex justify-content-end w-100 gap-4">
                <button
                  className="cancelButton"
                  onClick={() => setDeleteModal(false)}
                >
                  Cancel
                </button>
                <button
                  className="createContactButtonSave"
                  onClick={() => {
                    deleteCampaignDetails();
                  }}
                >
                  Delete
                </button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </>
    );
  };

  const handleColumnCheckboxChange = (name, isChecked) => {
    setSavingHeadings((prevHeadings) =>
      prevHeadings.map((heading) =>
        heading.name === name ? { ...heading, isChecked } : heading
      )
    );
  };

  const handleColumnCheckboxChangeContact = (name, isChecked) => {
    setSavingHeadingsContact((prevHeadings) =>
      prevHeadings.map((heading) =>
        heading.name === name ? { ...heading, isChecked } : heading
      )
    );
  };

  const handleColumnCheckboxChangeLead = (name, isChecked) => {
    setSavingHeadingsLead((prevHeadings) =>
      prevHeadings.map((heading) =>
        heading.name === name ? { ...heading, isChecked } : heading
      )
    );
  };

  const handleColumnCheckboxChangeDeal = (name, isChecked) => {
    setSavingHeadingsDeals((prevHeadings) =>
      prevHeadings.map((heading) =>
        heading.name === name ? { ...heading, isChecked } : heading
      )
    );
  };

  const handleCampaignView = () => {
    if (innerPage === "list") {
      return <>{campaignHomePage()}</>;
    } else if (["create", "clone"].includes(innerPage)) {
      return <>{createCampaignPage()}</>;
    } else if (innerPage === "overview") {
      return <>{individualCampaignDetailPage()}</>;
    }
  };

  useEffect(() => {
    setInnerPage(innerPage || "list");
  }, []);
  return (
    <div
      onClick={() => {
        setShowHeadings(false);
        setShowSelectedFilter("");
        setSelectedField("");
        setShowOptionsCampaign(false);
        setListVisible(false);
        setListVisible(false);
        setParentListVisible(false);
        setShowHeadings(false);
        setShowSelectedFilter("");
        setShowHeadingsContact(false);
        setShowHeadingsLead(false);
      }}
    >
      {handleCampaignView()}

      {/* ------------------------------------------------------- */}

      <Modal
        show={showModalType === "Manage Columns"}
        id="ConvertModal"
        aria-labelledby="example-custom-modal-styling-title"
        className="withdrw_modal"
      >
        <Modal.Body>
          <div className="modalClass  position-relative">
            <div className="d-flex justify-content-between ">
              <h4 className="">Manage Columns</h4>
            </div>
            <div className="w-100 position-relative">
              <div className="mb-1 pt-2 position-relative w-100">
                <input
                  type="text"
                  className="assetSearchbar searchbar rounded-2 p-2 pe-5 common_input_bg w-100 "
                  placeholder="Search"
                  aria-label="Username"
                  aria-describedby="basic-addon1"
                  value={searchColumn}
                  onChange={(e) => {
                    if (e.target.value.charAt(0) !== " ") {
                      const value = e.target.value.replace(
                        /[^a-zA-Z ]+|(\s)\s+/g,
                        "$1"
                      );
                      setSearchColumn(value);
                      // const list = productColumn.filter((product) =>
                      //   product.name
                      //     .toLowerCase()
                      //     .includes(value.trim().toLowerCase())
                      // );
                      // setSavingHeadings(list);
                      // setSearchColumn(value);
                    }
                  }}
                />
                <span
                  className="position-absolute  mt-2 me-2 end-0 opacity-75"
                  onClick={() => {
                    setSearchColumn("");
                    setSavingHeadings(campaignColumn);
                  }}
                >
                  {searchColumn === "" ? (
                    <img src={searchIcon} alt="" width={20} />
                  ) : (
                    "X"
                  )}
                </span>
              </div>
              <div className=" opacity-75 columns_container">
                {savingHeadings
                  .filter((lead) =>
                    lead.name
                      .toLowerCase()
                      .includes(searchColumn.trim().toLowerCase())
                  )
                  .sort((a, b) => a.name.localeCompare(b.name))
                  .map((column) => {
                    return (
                      <div
                        key={column.name}
                        className="d-flex gap-2 py-2 rounded-2 align-items-center"
                      >
                        <div className="d-flex gap-2 ps-2">
                          <input
                            type="checkbox"
                            id={column.name}
                            name={column.name}
                            checked={column.isChecked}
                            onChange={(e) => {
                              handleColumnCheckboxChange(
                                column.name,
                                e.target.checked
                              );
                            }}
                            className="cursorPointer"
                          />
                          <label
                            className="small cursorPointer"
                            htmlFor={column.name}
                          >
                            {column.name}
                          </label>
                        </div>
                      </div>
                    );
                  })}
              </div>
              <aside className="float-end mt-2">
                <div className="d-flex align-items-center gap-4">
                  <button
                    className="cancel_btn bg-light"
                    onClick={() => {
                      setShowModalType("");
                      // setSavingHeadings(productColumn);
                      // setSearchColumn("");
                    }}
                  >
                    Cancel
                  </button>
                  <button
                    className="save_btn"
                    onClick={() => {
                      setSelectedHeadings(savingHeadings);
                      setTimeout(() => {
                        setShowModalType("");
                      }, 200);
                    }}
                  >
                    Save
                  </button>
                </div>
              </aside>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      {/* Delete Modal */}
      <Modal
        show={[
          "delete",
          "Mass Delete",
          "Mass Update",
          "Mass Transfer",
        ].includes(showModalType)}
        aria-labelledby="contained-modal-title-vcenter"
        onClick={(e) => e.stopPropagation()}
        // size={showModalType !== "delete" ? "md" : "lg"}
      >
        <Modal.Header
          className=" border-0 mb-0 d-flex flex-column align-items-start"
          style={{ backgroundColor: showModalType !== "delete" && "#F2F2F2" }}
        >
          {showModalType !== "delete" && (
            <div
              className={`mass_heading fs-4 ${
                showModalType !== "Mass Delete" && "ps-4"
              }`}
            >
              {showModalType}
            </div>
          )}
          {showModalType === "delete" && (
            <div className="fs-5" style={{ color: "#3F7DFF" }}>
              Are you sure you want to delete {"productInfo?.productName"}?
            </div>
          )}
        </Modal.Header>
        <Modal.Body className="ModalBody">
          {showModalType === "delete" ? (
            <>
              <div className="d-flex gap-2 pb-2 align-items-center">
                <span className="fw-bold">Note:</span>
                <span className="small pt-1">
                  Any associated activities, visits, drafts will also be
                  deleted.
                </span>
              </div>
            </>
          ) : showModalType === "Mass Delete" ? (
            <>
              <div className="fs-5 pt-0 pb-2">
                Are you sure you want to delete selected item(s)?
              </div>
              <div className="small">
                <span className="fw-bold">Note: </span>
                <span>
                  Any associated Activities, Drafts will also be Deleted
                </span>
              </div>
            </>
          ) : showModalType === "Mass Update" ? (
            <div className="d-flex gap-2 mb-3 mt-2 align-items-center w-100">
              <div className="w-100">
                <div className="d-flex flex-column ps-1 align-items-center mx-4">
                  <label className="text-start w-100 opacity-75">
                    Select a field
                  </label>
                  <div
                    className="position-relative w-100 rounded-1"
                    style={{ border: "1px solid rgba(185, 185, 185, 0.5)" }}
                  >
                    <div
                      onClick={(e) => {
                        e.stopPropagation();
                        setListVisible(!listVisible);
                        setSearchField("");
                      }}
                      className={`cursorPointer d-flex justify-content-between w-100 my-1 p-1 border-0`}
                    >
                      <span className="">{selectedObj.name}</span>
                      <img
                        src={listVisible ? up : down}
                        alt="img"
                        height={20}
                        width={20}
                        className="pt-1"
                      />
                    </div>
                    {listVisible && (
                      <div
                        className="position-absolute field_container w-100"
                        onClick={(e) => {
                          e.stopPropagation();
                        }}
                      >
                        <div className="">
                          <label
                            htmlFor="selectField"
                            className="position-relative w-100"
                          >
                            <input
                              id="selectField"
                              type="text"
                              className="rounded-2 p-1 w-100 pe-4"
                              placeholder="Search"
                              aria-label="Username"
                              aria-describedby="basic-addon1"
                              value={searchField}
                              onChange={(e) => {
                                if (e.target.value.charAt(0) !== " ") {
                                  const value = e.target.value.replace(
                                    /[^a-zA-Z ]+|(\s)\s+/g,
                                    "$1"
                                  );
                                  setSearchField(value);
                                }
                              }}
                            />
                            <img
                              className="position-absolute mt-1 me-2 end-0 pt-1 cursorPointer"
                              onClick={() => {
                                setSearchField("");
                              }}
                              src={searchIcon}
                              alt="search"
                              width={20}
                            />
                          </label>

                          <div className="w-100 rounded-2">
                            <div className="border border-1 field_container_dropdown rounded-1">
                              {showSearchFields
                                .filter((field) =>
                                  field.name
                                    .toLowerCase()
                                    .includes(searchField.trim().toLowerCase())
                                )
                                .sort((a, b) => a.name.localeCompare(b.name))
                                .map(
                                  (field) =>
                                    ![
                                      "Description",
                                      "Created By",
                                      "Modified By",
                                      "Campaign Owner",
                                    ].includes(field.name) && (
                                      <div
                                        key={field.name}
                                        className="d-flex gap-2 p-2 cursorPointer rounded-2 align-items-center"
                                        onClick={() => {
                                          setClickedDropdownObj({
                                            label: "",
                                            value: "",
                                          });
                                          setMassUpdateValue("");
                                          setSelectedObj(field);
                                          setListVisible(false);
                                        }}
                                      >
                                        <span className="ps-2">
                                          {field.name}
                                        </span>
                                      </div>
                                    )
                                )}
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="w-100 mx-auto d-flex justify-content-center mt-4">
                    <div className="w-100">
                      <label className="opacity-75" htmlFor="massValue">
                        Enter value
                      </label>
                      <div
                        className="position-relative w-100 border rounded-1"
                        style={{ border: "1px solid rgba(185, 185, 185, 0.5)" }}
                      >
                        {["Status", "Type"].includes(selectedObj.name) ? (
                          <Select
                            value={clickedDropdownObj}
                            menuPlacement="auto"
                            onChange={(selectedOption) => {
                              setClickedDropdownObj(selectedOption);
                              setMassUpdateValue(selectedOption.value);
                            }}
                            options={
                              selectedObj.name === "Status"
                                ? campaignStatusOptions
                                : selectedObj.name === "Type"
                                ? campaignTypeOptions
                                : campaignTypeOptions
                            }
                            styles={fieldDropdownStyles}
                            isSearchable={false}
                          />
                        ) : selectedObj.name === "Start Date" ||
                          selectedObj.name === "End Date" ? (
                          <input
                            className="w-100 border-0 py-2 rounded-1"
                            type="date"
                            value={massUpdateValue}
                            onChange={(e) => {
                              setMassUpdateValue(e.target.value);
                            }}
                            style={{ outline: "none" }}
                            id="massValue"
                          />
                        ) : (
                          <input
                            className="w-100 border-0 py-2 rounded-1"
                            type="text"
                            value={massUpdateValue}
                            // onChange={(e) => {
                            //   setMassUpdateValue(e.target.value);
                            // }}

                            onChange={(e) => {
                              const trimmedValue = e.target.value.trimStart();
                              setMassUpdateValue(trimmedValue);
                            }}
                            style={{ outline: "none" }}
                            id="massValue"
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="w-100 px-4 py-2 mb-1">
              <label className="text-start w-100 opacity-75">Transfer To</label>
              <div
                className="d-flex input-with-clear position-relative mx-auto"
                onClick={(e) => {
                  handleOwnerInputClick(e);
                }}
              >
                <input
                  type="text"
                  className="ps-3 w-100 cursorPointer py-2"
                  value={selectedItem?.name}
                  readOnly
                />
                <span
                  className="position-absolute h-100 mass_transfer_user pt-1"
                  style={{ right: "0.1rem" }}
                >
                  <span
                    className="cursorPointer px-1"
                    onClick={() => setIsLeadOwnerModal(!isLeadOwnerModal)}
                  >
                    <img
                      src={userSearch}
                      alt="img"
                      width={20}
                      height={20}
                      className="ms-2 mt-2 me-2"
                    />
                  </span>
                </span>
              </div>
            </div>
          )}

          <div className={`pt-4  ${showModalType !== "delete" && "pe-3"}`}>
            <div className="d-flex justify-content-end w-100 gap-4 pe-2">
              <button
                className="cancelButton px-4"
                onClick={() => {
                  setShowModalType("");
                  // setSelectedCheckboxes([]);
                  setShowSearchFields(fieldDropdowns);
                }}
              >
                Cancel
              </button>
              <button
                className="createContactButtonSave"
                onClick={() => {
                  if (showModalType === "delete") {
                    // deleteProduct();
                  } else {
                    //  else if (showModalType === "Mass Delete") {
                    //   deleteUpdateTransferMass();
                    // } else {
                    deleteUpdateTransferMass();
                  }
                }}
              >
                {showModalType === "Mass Update"
                  ? "Update"
                  : showModalType === "Mass Transfer"
                  ? "Done"
                  : "Delete"}
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      {/* ------------------------------------------------------- */}
      <Modal
        show={showModalType === "Manage Contact Columns"}
        id="ConvertModal"
        aria-labelledby="example-custom-modal-styling-title"
        className="withdrw_modal"
      >
        <Modal.Body>
          <div className="modalClass  position-relative">
            <div className="d-flex justify-content-between ">
              <h4 className="">Manage Columns</h4>
              {/* <img
                src={crossIcon}
                alt="cross"
                style={{ cursor: "pointer" }}
                className="ms-1"
                onClick={() => {
                  setShowModalType("");
                }}
              /> */}
            </div>
            <div className="w-100 position-relative">
              <div className="mb-1 pt-2 position-relative w-100">
                <input
                  type="text"
                  className="assetSearchbar searchbar rounded-2 p-2 pe-5 common_input_bg w-100 "
                  placeholder="Search"
                  aria-label="Username"
                  aria-describedby="basic-addon1"
                  value={searchColumn}
                  onChange={(e) => {
                    if (e.target.value.charAt(0) !== " ") {
                      const value = e.target.value.replace(
                        /[^a-zA-Z ]+|(\s)\s+/g,
                        "$1"
                      );
                      setSearchColumn(value);
                    }
                  }}
                />
                <span
                  className="position-absolute  mt-2 me-2 end-0 opacity-75"
                  onClick={() => {
                    setSearchColumn("");
                    setSavingHeadings(contactColumn);
                  }}
                >
                  {searchColumn === "" ? (
                    <img src={searchIcon} alt="" width={20} />
                  ) : (
                    <div style={{ cursor: "pointer" }}>X</div>
                  )}
                </span>
              </div>
              <div className=" opacity-75 columns_container">
                {savingHeadingsContact
                  .filter((lead) =>
                    lead.name
                      .toLowerCase()
                      .includes(searchColumn.trim().toLowerCase())
                  )
                  .map((column) => {
                    return (
                      <div
                        key={column.name}
                        className="d-flex gap-2 py-2 rounded-2 align-items-center"
                      >
                        <div className="d-flex gap-2 ps-2">
                          <input
                            type="checkbox"
                            id={column.name}
                            name={column.name}
                            checked={column.isChecked}
                            onChange={(e) => {
                              handleColumnCheckboxChangeContact(
                                column.name,
                                e.target.checked
                              );
                            }}
                            className="cursorPointer"
                          />
                          <label
                            className="small cursorPointer"
                            htmlFor={column.name}
                          >
                            {column.name}
                          </label>
                        </div>
                      </div>
                    );
                  })}
              </div>
              <aside className="float-end mt-2">
                <div className="d-flex align-items-center gap-4">
                  <button
                    className="cancel_btn bg-light"
                    onClick={() => {
                      setShowModalType("");
                    }}
                  >
                    Cancel
                  </button>
                  <button
                    className="save_btn"
                    onClick={() => {
                      setSelectedHeadingsContact(savingHeadingsContact);
                      setTimeout(() => {
                        setShowModalType("");
                      }, 200);
                    }}
                  >
                    Save
                  </button>
                </div>
              </aside>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={showModalType === "Manage Lead Columns"}
        id="ConvertModal"
        aria-labelledby="example-custom-modal-styling-title"
        className="withdrw_modal"
      >
        <Modal.Body>
          <div className="modalClass  position-relative">
            <div className="d-flex justify-content-between ">
              <h4 className="">Manage Columns</h4>
              {/* <img
                src={crossIcon}
                alt="cross"
                style={{ cursor: "pointer" }}
                className="ms-1"
                onClick={() => {
                  setShowModalType("");
                }}
              /> */}
            </div>
            <div className="w-100 position-relative">
              <div className="mb-1 pt-2 position-relative w-100">
                <input
                  type="text"
                  className="assetSearchbar searchbar rounded-2 p-2 pe-5 common_input_bg w-100 "
                  placeholder="Search"
                  aria-label="Username"
                  aria-describedby="basic-addon1"
                  value={searchColumn}
                  onChange={(e) => {
                    if (e.target.value.charAt(0) !== " ") {
                      const value = e.target.value.replace(
                        /[^a-zA-Z ]+|(\s)\s+/g,
                        "$1"
                      );
                      setSearchColumn(value);
                    }
                  }}
                />
                <span
                  className="position-absolute  mt-2 me-2 end-0 opacity-75"
                  onClick={() => {
                    setSearchColumn("");
                    setSavingHeadings(leadsColumn);
                  }}
                >
                  {searchColumn === "" ? (
                    <img src={searchIcon} alt="" width={20} />
                  ) : (
                    <div style={{ cursor: "pointer" }}>X</div>
                  )}
                </span>
              </div>
              <div className=" opacity-75 columns_container">
                {savingHeadingsLead
                  .filter((lead) =>
                    lead.name
                      .toLowerCase()
                      .includes(searchColumn.trim().toLowerCase())
                  )
                  .map((column) => {
                    return (
                      <div
                        key={column.name}
                        className="d-flex gap-2 py-2 rounded-2 align-items-center"
                      >
                        <div className="d-flex gap-2 ps-2">
                          <input
                            type="checkbox"
                            id={column.name}
                            name={column.name}
                            checked={column.isChecked}
                            onChange={(e) => {
                              handleColumnCheckboxChangeLead(
                                column.name,
                                e.target.checked
                              );
                            }}
                            className="cursorPointer"
                          />
                          <label
                            className="small cursorPointer"
                            htmlFor={column.name}
                          >
                            {column.name}
                          </label>
                        </div>
                      </div>
                    );
                  })}
              </div>
              <aside className="float-end mt-2">
                <div className="d-flex align-items-center gap-4">
                  <button
                    className="cancel_btn bg-light"
                    onClick={() => {
                      setShowModalType("");
                    }}
                  >
                    Cancel
                  </button>
                  <button
                    className="save_btn"
                    onClick={() => {
                      setSelectedHeadingsLead(savingHeadingsLead);
                      setTimeout(() => {
                        setShowModalType("");
                      }, 200);
                    }}
                  >
                    Save
                  </button>
                </div>
              </aside>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={showModalType === "Manage Deals Columns"}
        id="ConvertModal"
        aria-labelledby="example-custom-modal-styling-title"
        className="withdrw_modal"
      >
        <Modal.Body>
          <div className="modalClass  position-relative">
            <div className="d-flex justify-content-between ">
              <h4 className="">Manage Columns</h4>
              {/* <img
                src={crossIcon}
                alt="cross"
                style={{ cursor: "pointer" }}
                className="ms-1"
                onClick={() => {
                  setShowModalType("");
                }}
              /> */}
            </div>
            <div className="w-100 position-relative">
              <div className="mb-1 pt-2 position-relative w-100">
                <input
                  type="text"
                  className="assetSearchbar searchbar rounded-2 p-2 pe-5 common_input_bg w-100 "
                  placeholder="Search"
                  aria-label="Username"
                  aria-describedby="basic-addon1"
                  value={searchColumn}
                  onChange={(e) => {
                    if (e.target.value.charAt(0) !== " ") {
                      const value = e.target.value.replace(
                        /[^a-zA-Z ]+|(\s)\s+/g,
                        "$1"
                      );
                      setSearchColumn(value);
                    }
                  }}
                />
                <span
                  className="position-absolute  mt-2 me-2 end-0 opacity-75"
                  onClick={() => {
                    setSearchColumn("");
                    setSavingHeadings(dealsColumn);
                  }}
                >
                  {searchColumn === "" ? (
                    <img src={searchIcon} alt="" width={20} />
                  ) : (
                    <div style={{ cursor: "pointer" }}>X</div>
                  )}
                </span>
              </div>
              <div className=" opacity-75 columns_container">
                {savingHeadingsDeals
                  .filter((lead) =>
                    lead.name
                      .toLowerCase()
                      .includes(searchColumn.trim().toLowerCase())
                  )
                  .map((column) => {
                    return (
                      <div
                        key={column.name}
                        className="d-flex gap-2 py-2 rounded-2 align-items-center"
                      >
                        <div className="d-flex gap-2 ps-2">
                          <input
                            type="checkbox"
                            id={column.name}
                            name={column.name}
                            checked={column.isChecked}
                            onChange={(e) => {
                              handleColumnCheckboxChangeDeal(
                                column.name,
                                e.target.checked
                              );
                            }}
                            className="cursorPointer"
                          />
                          <label
                            className="small cursorPointer"
                            htmlFor={column.name}
                          >
                            {column.name}
                          </label>
                        </div>
                      </div>
                    );
                  })}
              </div>
              <aside className="float-end mt-2">
                <div className="d-flex align-items-center gap-4">
                  <button
                    className="cancel_btn bg-light"
                    onClick={() => {
                      setShowModalType("");
                    }}
                  >
                    Cancel
                  </button>
                  <button
                    className="save_btn"
                    onClick={() => {
                      setSelectedHeadingsDeals(savingHeadingsDeals);
                      setTimeout(() => {
                        setShowModalType("");
                      }, 200);
                    }}
                  >
                    Save
                  </button>
                </div>
              </aside>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <LeadOwnerModal
        isLeadOwnerModal={isLeadOwnerModal}
        setIsLeadOwnerModal={setIsLeadOwnerModal}
        onSelectedLeadChange={handleSelectedLeadChange}
        defaultUser={isForHandler ? selectedHandlerItem : selectedItem}
      />
      <ChangeOwnerModal
        modalType={showModalType}
        setShowModalType={setShowModalType}
        selectedOwnerItem={selectedItem}
        handleChangeOwnerInputClick={handleChangeOwnerInputClick}
        setSelectedOwnerItem={setSelectedItem}
        isLeadOwnerModal={isLeadOwnerModal}
        setIsLeadOwnerModal={setIsLeadOwnerModal}
        isChangeOwnerList={isChangeOwnerList}
        userList={userList}
        handleChangeOwnerItemClick={handleChangeOwnerItemClick}
        emailNotification={emailNotification}
        setEmailNotification={setEmailNotification}
        handleEditClick={handleEditClick}
        handleUpdateClick={handleUpdateClick}
        setUpdatedFields={setUpdatedFields}
        existingContact={existingCampaign}
        handleUpdateContact={handleUpdateCampaign}
        onSelectedChangeOwner={handleSelectedLeadChange}
        apiLoader={apiLoader} // for lottie loader
      />

      <ShareModal
        moduleType="CAMPAIGNS"
        setShareModal={setShareModal}
        shareModal={shareModal}
        shareInfoModal={shareInfoModal}
        setShareInfoModal={setShareInfoModal}
        id={campaignId}
        shareUserList={shareUserList}
        shareGroupList={shareGroupList}
        shareRoleList={shareRoleList}
        sharePublicList={sharePublicList}
        modalHeading={"Sharing Campaigns"}
        shareMemberIds={shareMemberIds}
        module={"Campaign"}
      />
      {/* {isCampaignListModal ? (
        <AccountListModal
          isAccountListModal={isCampaignListModal}
          setIsAccountListModal={setIsCampaignListModal}
          onSelectedLeadChange={handleSelectParentList}
          defaultUser={selectedCampaignItem}
          allAccountDetails={allList}
          type="campaign"
        />
      ) : null} */}

      <Modal
        show={isCampaignListModal}
        className="d-flex justify-content-center align-items-center"
        aria-labelledby="example-custom-modal-styling-title"
        size="lg"
        id="commonLeadModal"
      >
        <Modal.Body className="ModalBody overflow-auto">
          <div
            className="modalUS row justify-content-center"
            style={{ margin: "0", gap: "10px", paddingBottom: "10px" }}
          >
            <div className="header_Container d-flex justify-content-between">
              <h4 className="commonTextColor pt-2">Choose Campaign</h4>
              <div className="inputContainer d-flex justify-content-start">
                <input
                  type="text"
                  placeholder="Search"
                  className="topBarModalSearch px-5"
                  onChange={handleSearchCampaign}
                  value={searchCampaign}
                />
                <img src={SearchIcon} alt="search" className="searchIcon" />
              </div>
            </div>

            <div className="Owner_table_container border border-dark rounded-2 h-25">
              <div className="table-wrapper">
                <table className="userdata-table">
                  <thead>
                    <tr>
                      <th>Campaign Name</th>
                      <th>Campaign Type</th>
                      <th>Campaign Owner</th>
                      <th>Start Date</th>
                      <th>End Date</th>
                    </tr>
                  </thead>
                  <tbody className="table-body tableUserData">
                    {filteredCampigns.length > 0 &&
                      filteredCampigns?.map((item, index) => (
                        <tr
                          key={index}
                          onClick={() => {
                            setSelectedCampaignItem(item);
                          }}
                        >
                          <>
                            <td>{item.campaignName}</td>
                            <td>{item.campaignType}</td>
                            <td>{item.campaignOwner}</td>
                            <td>{item.startDate}</td>
                            <td>{item.endDate}</td>
                          </>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            </div>
            <div className="d-flex w-100 align-items-center justify-content-end gap-2">
              {filteredCampigns.length > 0 && (
                <>
                  <Select
                    options={pageOption}
                    styles={customSelectLocationStyles}
                    value={pageOption.find(
                      (option) => option.value === perPage.toString()
                    )}
                    onChange={handlePageDropDowmChange}
                    isSearchable={false}
                  />
                  <Pagination
                    currentPage={currentPage}
                    totalPages={totalPages}
                    onPageChange={handlePageChange}
                  />
                </>
              )}
            </div>
            <div className="d-flex pb-2 pt-3 justify-content-between">
              <div className="selectedUser d-flex gap-1">
                <span>Selected Campaign</span>
                <img src={logoConvert} alt="img" height={20} width={20} />
                <span>{selectedCampaignItem.campaignName}</span>
              </div>
              <div className="d-flex gap-2">
                <button
                  className="btn btn-outline-secondary border border-primary btn-sm px-3 py-2 text-dark"
                  onClick={() => {
                    setIsCampaignListModal(false);
                    setSelectedCampaignItem({});
                  }}
                >
                  Cancel
                </button>
                <button
                  className="btn btnColor btn-sm px-3 text-white"
                  onClick={() => {
                    setIsCampaignListModal(false);
                    //  setSelectedCampaignId(selectedCampaignItem.campaignId)
                  }}
                >
                  Done
                </button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <NotificationContainer />
    </div>
  );
}

export default Campaigns;

import React, { useState } from "react";
import filterMenu from "../Assets/filterMenu.svg";
import SearchIcon from "../Assets/SearchIcon.svg";
import { Modal } from "react-bootstrap";
import cancel from "../Assets/cancelCircle.svg";

const dealsIcon = (
  <svg
    width="22"
    height="22"
    viewBox="0 0 30 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M23.7381 23.75H23.75M23.7381 23.75C22.9598 24.5219 21.5492 24.3296 20.5599 24.3296C19.3456 24.3296 18.7609 24.5672 17.8943 25.4338C17.1564 26.1717 16.1672 27.5 15 27.5C13.8329 27.5 12.8437 26.1717 12.1057 25.4338C11.2391 24.5672 10.6544 24.3296 9.44008 24.3296C8.45085 24.3296 7.04023 24.5219 6.26186 23.75C5.47726 22.972 5.67035 21.5555 5.67035 20.5599C5.67035 19.3018 5.3952 18.7232 4.49922 17.8273C3.16642 16.4945 2.50002 15.8281 2.5 15C2.50001 14.1719 3.1664 13.5055 4.49919 12.1727C5.299 11.3729 5.67035 10.5803 5.67035 9.44007C5.67035 8.45082 5.47812 7.04018 6.25 6.26181C7.02803 5.47723 8.4445 5.67032 9.4401 5.67032C10.5803 5.67032 11.3729 5.299 12.1727 4.49921C13.5055 3.1664 14.1719 2.5 15 2.5C15.8281 2.5 16.4945 3.1664 17.8273 4.49921C18.6269 5.29883 19.4193 5.67032 20.5599 5.67032C21.5492 5.67032 22.9598 5.47809 23.7382 6.25C24.5227 7.02804 24.3297 8.44449 24.3297 9.44007C24.3297 10.6982 24.6048 11.2767 25.5008 12.1727C26.8336 13.5055 27.5 14.1719 27.5 15C27.5 15.8281 26.8336 16.4945 25.5008 17.8273C24.6048 18.7232 24.3297 19.3018 24.3297 20.5599C24.3297 21.5555 24.5227 22.972 23.7381 23.75Z"
      stroke="#2177FE"
      strokeWidth="2"
    />
    <path
      d="M11.25 16.1161L13.5 18.125L18.75 11.875"
      stroke="#2177FE"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const DealsCommonTable = ({
  title,
  buttonLabel,
  buttonAction,
  showHeadings,
  setShowHeadings,
  selectedHeadings,
  data,
  manageColumnsAction,
  resetColumnSizeAction,
  setShowSelectedFilter,
  selectedDealsId,
  // setSelectedDealsId,
  handleAssignDealsModule,
  // setSearchColumn,
  // setSavingHeadings,
  handledeleteModuleDeal,
}) => {
  const [isAssignModal, setIsAssignModal] = useState(false);
  const [hoveredRow, setHoveredRow] = useState(null);
  const [showCancelModal, setShowCancelModal] = useState(false);
  const [dealName, setDealName] = useState("");
  const [deleteDealId, setDeleteDealId] = useState("");
  // const [dealDetails, setDealDetails] = useState([]);

  // const handleCheckboxChange = (dealId) => {
  //   setSelectedDealsId((prevSelected) => {
  //     if (prevSelected.includes(dealId)) {
  //       return prevSelected.filter((id) => id !== dealId);
  //     } else {
  //       return [...prevSelected, dealId];
  //     }
  //   });
  // };
  const handleRowMouseEnter = (index) => {
    setHoveredRow(index);
  };

  const handleRowMouseLeave = () => {
    setHoveredRow(null);
  };

  const handleDeleteConfirmation = () => {
    handledeleteModuleDeal(deleteDealId);
    setShowCancelModal(false);
    // setSelectedProductId([]);
  };

  return (
    <div className="lead_Information_mainContainer commonContainer_bg rounded-2 d-flex flex-column py-2 mt-4">
      <div className="d-flex justify-content-between align-items-center ms-2">
        <div className="d-flex gap-2">
          {/* <img src={dealsIcon} alt="product" /> */}
          <span>{dealsIcon}</span>
          <div className="notesHeader">{title}</div>
        </div>
        <div className="d-flex gap-2 pe-1">
          {/* <button
              className="addProductsButton p-1"
              onClick={() => setIsAssignModal(true)}
            >
              Assign
            </button> */}
          <button className="addProductsButton" onClick={buttonAction}>
            {buttonLabel}
          </button>
        </div>
      </div>
      <div style={{ borderBottom: "2px solid #ffffff" }} className="mt-2"></div>
      <div className="p-2 mt-2" onClick={() => setShowHeadings(false)}>
        <div style={{ overflowX: "auto" }}>
          <table
            id="productTable"
            style={{ borderCollapse: "collapse", width: "100%" }}
            className="p-2 Table leadTable"
          >
            <thead>
              <tr className="p-2 productTableHeader">
                {selectedHeadings.map(
                  (heading) =>
                    heading.isChecked && (
                      <th scope="col" key={heading.name}   style={{
                        whiteSpace: "nowrap", 
                        minWidth: "150px", 
                        textAlign: "left", 
                      }}>
                        <span className="pe-5">{heading.name}</span>
                      </th>
                    )
                )}
                <th
                  scope="col"
                  className="text-end cursorPointer position-relative"
                  style={{ whiteSpace: "nowrap" }}
                >
                  <img
                    src={filterMenu}
                    alt=""
                    className={`filter_menu ${showHeadings ? "bg-light" : ""}`}
                    onClick={(e) => {
                      e.stopPropagation();
                      setShowSelectedFilter("");
                      setShowHeadings(true);
                    }}
                  />
                  {showHeadings && (
                    <div
                      className="position-absolute rounded-2 border border-primary bg-light"
                      style={{
                        right: "0.2rem",
                      }}
                    >
                      <div className="d-flex flex-column px-2">
                        <span
                          className="p-2 cursorPointer fw-normal text-start"
                          onClick={() => {
                            manageColumnsAction();
                            // setSearchColumn("");
                            // setSavingHeadings(selectedHeadings);
                          }}
                        >
                          Manage Columns
                        </span>
                        <span
                          className="p-2 fw-normal text-start opacity-50"
                          onClick={resetColumnSizeAction}
                        >
                          Reset Column Size
                        </span>
                      </div>
                    </div>
                  )}
                </th>
              </tr>
            </thead>
            <tbody>
              {data && data.length > 0 ? (
                data.map((item, index) => (
                  <tr
                    style={{
                      cursor: "pointer",
                      borderBottom: "1px solid #ccc",
                    }}
                    onMouseEnter={() => handleRowMouseEnter(index)}
                    onMouseLeave={handleRowMouseLeave}
                    key={index}
                    onClick={() => item.dealId}
                  >
                    {selectedHeadings.map((obj, i) => {
                      const value = obj.value;
                      const itemValue = item[value];
                      return obj.isChecked ? (
                        <td
                          key={i}
                          className={`${
                            value === "contactName"
                              ? "productsTabledata w-50 overflow_text"
                              : "productsTabledata w-50 overflow_text "
                          }`}
                          style={{ paddingLeft: "0px" }}
                        >
                          {itemValue !== null ? itemValue : "-"}
                        </td>
                      ) : null;
                    })}
                    <td style={{ textAlign: "center" }} className="mt-2">
                      {hoveredRow === index && (
                        <img
                          src={cancel}
                          alt="cancel"
                          style={{
                            width: "20px",
                            height: "20px",
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            setDeleteDealId(item.dealId);
                            setDealName(item.dealName);
                            setShowCancelModal(true);
                          }}
                        />
                      )}
                    </td>
                  </tr>
                ))
              ) : (
                <tr className="d-flex justify-content-center">
                  <td
                    colSpan={
                      selectedHeadings.filter((obj) => obj.isChecked).length + 1
                    } // +1 for the extra <td></td>
                    style={{ textAlign: "center", padding: "20px" }}
                  >
                    <div className="text-center mt-2">
                      <div
                        className="ms-4"
                        style={{ color: "#B9B9B9", fontSize: "20px" }}
                      >
                        No records found
                      </div>
                      <div
                        className="ms-4"
                        style={{ color: "#B9B9B9", fontSize: "14px" }}
                      >
                        There is no data available to show
                      </div>
                    </div>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        {isAssignModal && (
          <>
            {/* <AssignCommonModal
              show={isProductModal}
              onHide={() => setIsProductModal(false)}
              assignContactID={assignContactID}
              selectedDealsId={selectedDealsId}
              setSelectedQuoteId={setSelectedQuoteId}
              handleAssignQuoteModule={handleAssignQuoteModule}
            /> */}

            <Modal
              className="d-flex justify-content-center align-items-center"
              aria-labelledby="example-custom-modal-styling-title"
              size="lg"
              id="commonLeadModal"
              show={isAssignModal}
              onHide={() => setIsAssignModal(false)}
            >
              <Modal.Body className="ModalBody  overflow-auto">
                <div
                  className="modalUS row justify-content-center"
                  style={{
                    margin: "0",
                    gap: "10px",
                    paddingBottom: "10px",
                  }}
                >
                  <div className="header_Container d-flex justify-content-between">
                    <h4 className="commonTextColor pt-2">Assign </h4>
                    {/* <div className="d-flex justify-content-start">
                <input
                  type="text"
                  className="inputBox"
                  placeholder="Search users"
                  value={searchQuery}
                  onChange={handleSearchChange}
                />
                <img src={SearchIcon} alt="search" className="SearchIcon" />
              </div> */}
                    <div className="inputContainer d-flex justify-content-start">
                      <input
                        type="text"
                        placeholder="Search"
                        className="topBarModalSearch px-5"
                      />
                      <img
                        src={SearchIcon}
                        alt="search"
                        className="searchIcon"
                      />
                    </div>
                  </div>

                  <div className="Owner_table_container rounded-4 h-25">
                    <div className="table-wrapper">
                      <table className="userdata-table">
                        <thead>
                          <tr>
                            <th>Deal Name</th>
                            <th>Amount</th>
                            <th>Stage</th>
                            <th>Closing Date</th>
                          </tr>
                        </thead>
                        <tbody className="table-body tableUserData">
                          {/* {dealDetails.map((item, index) => (
                            <tr key={index}>
                              <td className="">
                                <input
                                  type="checkbox"
                                  checked={selectedDealsId.includes(
                                    item.dealId
                                  )}
                                  onChange={() => {
                                    handleCheckboxChange(item.dealId);
                                  }}
                                />

                                <span className="ms-2"> {item.dealName} </span>
                              </td>
                              <td>{item.amount}</td>
                              <td>{item.stage}</td>
                              <td>{item.closingDate}</td>
                            </tr>
                          ))} */}
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div className="d-flex w-100 align-items-center justify-content-end gap-2">
                    {/* <Select
                        options={pageOption}
                        styles={customSelectLocationStylesPage}
                        value={pageOption.find(
                          (option) => option.value === perPage.toString()
                        )}
                        onChange={handlePageDropDowmChange}
                      />

                      <Pagination
                        currentPage={currentPage}
                        totalPages={totalPages}
                        onPageChange={handlePageChange}
                      /> */}
                  </div>

                  <div className="d-flex pb-2 pt-3 justify-content-between">
                    <div className="selectedUser d-flex gap-1 pt-2">
                      {/* <span>Selected User</span>
                <img src={logoConvert} alt="img" height={25} width={25} />
                <span>{selectedLead.name}</span> */}
                    </div>
                    <div className="d-flex gap-2">
                      <button
                        className="createContactButton modalButton"
                        onClick={() => setIsAssignModal(false)}
                      >
                        Cancel
                      </button>
                      <button
                        className="createContactButtonSave modalButton"
                        onClick={() => {
                          handleAssignDealsModule(selectedDealsId);
                          setTimeout(() => {
                            setIsAssignModal(false);
                          }, 200);
                        }}
                      >
                        Next
                      </button>
                    </div>
                  </div>
                </div>
              </Modal.Body>
            </Modal>
          </>
        )}
        {showCancelModal && (
          <Modal
            show={showCancelModal}
            className="d-flex justify-content-start align-items-center"
            aria-labelledby="example-custom-modal-styling-title"
            size="lg"
            id="commonLeadModal"
          >
            <Modal.Header
              onClick={() => {
                setShowCancelModal(false);
              }}
              style={{
                fontSize: "20px",
                fontWeight: "bold",
                background:
                  "linear-gradient(to right, rgba(64, 123, 255, 1), rgba(18, 167, 254, 1))",
                WebkitBackgroundClip: "text",
                backgroundClip: "text",
                WebkitTextFillColor: "transparent",
              }}
            >
              Are you sure you want to delete {dealName} ?
              {/* <span className="borderBottom">{prodName}</span> */}
            </Modal.Header>
            <Modal.Body className="ModalBody overflow-auto">
              <div className="d-flex flex-column gap-4">
                <div>
                  Note:{" "}
                  <span style={{ fontSize: "14px", color: "#9C9C9C" }}>
                    Any associated activities, visits, drafts will also be
                    deleted.
                  </span>
                </div>

                <div className="d-flex justify-content-end w-100 gap-4">
                  <button
                    className="cancelButton px-2"
                    onClick={() => {
                      setShowCancelModal(false);
                    }}
                  >
                    Cancel
                  </button>
                  <button
                    className="unassignButton px-2"
                    onClick={() => handleDeleteConfirmation()}
                  >
                    Delete
                  </button>
                </div>
              </div>
            </Modal.Body>
          </Modal>
        )}
      </div>
    </div>
  );
};

export default DealsCommonTable;

import React from "react";
import ProductRelated from "../../Assets/ProductRelated.svg";

function StageHistoryList({ stageHistoryList }) {
  return (
    <div>
      <div className="d-flex justify-content-between align-items-center ms-2">
        <div className="d-flex gap-2">
          <img src={ProductRelated} alt="product" />
          <div className="notesHeader">Stage History</div>
        </div>
        {/* <div className='me-4'>
                <button className="addProductsButton p-1">New Deal</button>
                </div> */}
      </div>
      <div style={{ borderBottom: "2px solid #ffffff" }} className="mt-2"></div>
      <div className="p-2 mt-2">
        <table
          id="productTable"
          style={{ borderCollapse: "collapse", width: "100%" }}
          className="p-2"
        >
          {stageHistoryList?.length > 0 && (
            <thead>
              <tr className="p-2 productTableHeader">
                <th style={{ width: "15%" }}>Stage</th>
                <th style={{ width: "12%" }}>Amount</th>
                <th style={{ width: "12%" }}>Probability</th>
                <th style={{ width: "18%" }}>Expected Revenue</th>
                <th style={{ width: "15%" }}>Closing Date</th>
                <th style={{ width: "10%" }}>Stage Duration</th>
                <th style={{ width: "15%" }}>Modified BY</th>
              </tr>
            </thead>
          )}
          <tbody className="p-2">
            {stageHistoryList?.length > 0 ? (
              stageHistoryList?.map((item, index) => (
                <tr style={{ borderBottom: "1px solid #ccc" }} key={index}>
                  <td style={{ color: "#2177FE", fontSize: "16px" }}>
                    {item.stage}
                  </td>
                  <td className="productsTabledata">{item.amount}</td>
                  <td className="productsTabledata">{item.probability}</td>
                  <td className="productsTabledata">{item.expectedRevenue}</td>
                  <td className="productsTabledata">{item.closingDate}</td>
                  <td className="productsTabledata">{item.stageDuration}</td>
                  <td className="productsTabledata">{item.modifiedBy}</td>
                </tr>
              ))
            ) : (
              <tr>
                <td
                  colSpan="7"
                  style={{ textAlign: "center", padding: "20px" }}
                >
                  <div className="text-center mt-2">
                    <div
                      className="ms-4"
                      style={{ color: "#B9B9B9", fontSize: "20px" }}
                    >
                      No records found
                    </div>
                    <div
                      className="ms-4"
                      style={{ color: "#B9B9B9", fontSize: "14px" }}
                    >
                      There is no data available to show
                    </div>
                  </div>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default StageHistoryList;

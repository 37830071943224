import React, { useEffect, useState } from "react";
import MultiLineChart from "../InitialHome/DashboardComponents/MultiLineChart";
import down from "../../Assets/Down31.svg";
import up from "../../Assets/Up.svg";
import searchIcon from "../../Assets/search.svg";
import moment from "moment";
import axios from "axios";
import { URI } from "../../constants";
import { useSelector } from "react-redux";
import NoData from "../../Assets/Nodata.svg";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import getHeaders from "../../common/ApiHeader";

const CommonMultiLine = ({
  id,
  title,
  dataObj,
  selectOverview,
  setCloseDropdown,
  closeDropdown,
}) => {
  const userList = JSON.parse(sessionStorage.getItem("userList"));
  const reduxStore = useSelector((state) => state.reduxState);
  const { loginUserId } = reduxStore;

  const today = new Date();
  const yesterday = new Date(today);
  yesterday.setDate(today.getDate() - 2);

  const [finalData, setFinalData] = useState(dataObj);
  const [searchField, setSearchField] = useState("");
  const [selectedCurrent, setSelectedCurrent] = useState("week");
  const [selectedLast, setSelectedLast] = useState("weeks");
  const [selectedNumber, setSelectedNumber] = useState(1);
  const [selectedTimePeriod, setSelectedTimePeriod] = useState("current");
  const [fromDate, setFromDate] = useState(yesterday);
  const [toDate, setToDate] = useState(today);
  const [selectedFrequency, setSelectedFrequency] = useState("daily");
  const [selectedFreqList, setSelectedFreqList] = useState([
    "daily",
    // "weekly",
    // "monthly",
    // "yearly",
  ]);
  const [selectedUsers, setSelectedUsers] = useState(
    userList.map((user) => user.userId)
  );
  const [selectedData, setSelectedData] = useState({
    current: "Current Week",
    last: "Last 4 Weeks",
    usersCount: selectedUsers.length,
    type: "current",
  });
  // const [apiLoader, setApiLoader] = useState(false);
  const [showInsideDropdownType, setShowInsideDropdownType] = useState("");
  const [showDropdownType, setShowDropdownType] = useState("");

  const handleSelectedFreq = (type) => {
    setSelectedFrequency(type);
    getAnalyticsByFilter(selectedData, type);
  };

  const getKey = (dataArray, keys) => {
    for (const data of dataArray) {
      for (const key of keys) {
        if (Object.prototype.hasOwnProperty.call(data, key)) {
          return key;
        }
      }
    }
    return null; // Return null if none of the keys are found
  };

  const handleApply = (e) => {
    e.stopPropagation();

    if (showDropdownType === `timePeriod ${id}`) {
      const obj = {
        current: `${selectedTimePeriod} ${selectedCurrent || "week"}`,
        last: `${selectedTimePeriod} ${selectedNumber} ${
          selectedLast || "weeks"
        }`,
        usersCount: selectedUsers.length,
        type: selectedTimePeriod,
      };
      let list = ["daily"];
      if (
        (selectedTimePeriod === "last" && selectedLast === "years") ||
        selectedTimePeriod === "custom"
      ) {
        list = ["monthly", "yearly"];
      } else if (
        selectedTimePeriod === "current" &&
        selectedCurrent === "week"
      ) {
        list = ["daily"];
      } else if (
        (selectedCurrent === "year" && selectedTimePeriod === "current") ||
        (selectedLast === "months" && selectedTimePeriod === "last")
      ) {
        list = ["weekly", "monthly"];
      } else if (
        !["custom"].includes(selectedTimePeriod) &&
        ((selectedCurrent === "month" && selectedTimePeriod === "current") ||
          (["days", "weeks"].includes(selectedLast) &&
            selectedTimePeriod === "last"))
      ) {
        list = ["daily", "weekly"];
      }
      setSelectedFreqList(list);
      setShowDropdownType("");
      setSelectedData(obj);
      setSelectedFrequency(list[0]);
      getAnalyticsByFilter(obj, list[0]);
      // getAnalyticsByFilter(obj, selectedFrequency);
    } else {
      setShowDropdownType("");
      const obj = {
        current: selectedData.current,
        last: selectedData.last,
        usersCount: selectedUsers.length,
        type: selectedTimePeriod,
      };
      setSelectedData(obj);
      // getAnalyticsByFilter(obj, list[0]);
      getAnalyticsByFilter(obj, selectedFrequency);
    }
  };

  const getAnalyticsByFilter = async (obj, freq) => {
    const requestBody = {
      loggedInUserId: loginUserId,
      loginIdAllUsers: selectedUsers.toString(),
      analyticType: selectOverview,
      salesTrendType: title.toLowerCase().replace(/\s+/g, "_"),
      days: freq || "daily",
    };

    const url =
      selectOverview === "sales_trend"
        ? URI.getAnalyticsByFilterTrend
        : URI.getAnalyticsByFilterFollowUp;

    if (obj.type === "custom") {
      requestBody.timePeriod = "custom";
      requestBody.fromDate = moment(fromDate).format("DD-MM-YYYY");
      requestBody.toDate = moment(toDate).format("DD-MM-YYYY");
    } else {
      requestBody.timePeriod =
        obj.type === "current"
          ? obj.current.toLowerCase().replace(/\s+/g, "_")
          : obj.last.toLowerCase().replace(/\s+/g, "_");
      // .replace(/\s+/g, '_')
    }

    await axios
      .post(url, requestBody, {
        headers: getHeaders(true),
      })
      .then((response) => {
        if (response.data.status === 200) {
          let getData = [];
          let obj;
          let dynamicKey;

          if (selectOverview === "sales_trend") {
            getData = response.data.data.getSalesFilterData;
            dynamicKey = getKey(getData, [
              "leadsCount",
              "dealsAmount",
              "dealsCount",
              "contactsCount",
            ]);
            obj = getData?.map((data) => ({
              createdDate: data.createdDate,
              [dynamicKey]: data[dynamicKey],
            }));
            setFinalData(obj);
          } else {
            getData = response.data.data.getSalesFollowUpFilterData;
            dynamicKey = getKey(getData, [
              "taskCount",
              "meetingCount",
              "callCount",
              "notesCount",
            ]);
            const dateKey = getKey(getData, [
              "weekStartDate",
              "createdDate",
              "monthStartDate",
              "yearStartDate",
            ]);

            const obj = getData?.map((data) => ({
              [dateKey]: data[dateKey],
              [dynamicKey]: data[dynamicKey],
            }));
            setFinalData(obj);
          }
          //   setTotalData(response.data.data);
        } else {
          NotificationManager.error("", response.data.message, 3000);
          setFinalData([]);
        }
      })
      .catch(async (error) => {
        throw new Error("Failed to fetch data", error);
      });
  };

  useEffect(() => {
    ["sales_follow_up_trend", "sales_trend"].includes(selectOverview) &&
      getAnalyticsByFilter(selectedData, selectedFrequency);
  }, [selectOverview]);

  return (
    <div
      onClick={() => {
        setShowDropdownType(
          showDropdownType === `frequency ${id}` ? "" : showDropdownType
        );
      }}
    >
      <div className="d-flex justify-content-between mb-2">
        <div className="leadsHeader">{title}</div>
        <div className="d-flex gap-3">
          <div className="position-relative">
            <div
              className="d-flex gap-2 sales_trend_dropdown cursorPointer px-2 py-1 rounded-pill"
              onClick={(e) => {
                e.stopPropagation();
                setShowDropdownType(
                  closeDropdown !== `users ${id}` ? `users ${id}` : ""
                );
                setCloseDropdown(
                  closeDropdown !== `users ${id}` ? `users ${id}` : ""
                );
              }}
            >
              <span>Selected Users:</span>
              <span>
                <span>
                  {userList.length === selectedData.usersCount
                    ? "All"
                    : selectedData.usersCount}
                </span>
                {/* <span>{isApplied ? selectedUsers.length : 0}</span> */}
                <img
                  src={
                    showDropdownType === closeDropdown &&
                    showDropdownType === `users ${id}`
                      ? up
                      : down
                  }
                  alt="img"
                  height={20}
                  width={20}
                  className="mb-1"
                />
              </span>
            </div>
            {showDropdownType === closeDropdown &&
            showDropdownType === `users ${id}` ? (
              <div
                className="position-absolute bg-white rounded-2 users_container p-3"
                style={{ width: "18rem" }}
              >
                <div className="d-flex gap-2 w-100 users_container_input">
                  <div className="text-nowrap position-relative">
                    <span
                      onClick={() => {
                        setShowInsideDropdownType(
                          showInsideDropdownType === "usersType"
                            ? ""
                            : "usersType"
                        );
                      }}
                      className="cursorPointer"
                    >
                      Users
                    </span>
                    {/* {showInsideDropdownType === "usersType" ? (
                          <div
                            className="position-absolute users_container_list start-0 rounded-1"
                            style={{ top: "2rem", backgroundColor: "#dde9f6" }}
                          >
                            <span className="d-flex flex-column py-1 mx-2">
                              <span
                                className="mb-2 cursorPointer"
                                onClick={() => {
                                  changeUserType("Users");
                                }}
                              >
                                Users
                              </span>
                              <span
                                className="cursorPointer"
                                onClick={() => {
                                  changeUserType("Groups");
                                }}
                              >
                                Groups
                              </span>
                            </span>
                          </div>
                        ) : null} */}
                  </div>
                  <input
                    type="text"
                    className=""
                    placeholder="Search"
                    value={searchField}
                    onChange={(e) => {
                      if (e.target.value.charAt(0) !== " ") {
                        const value = e.target.value.replace(
                          /[^a-zA-Z ]+|(\s)\s+/g,
                          "$1"
                        );
                        setSearchField(value);
                      }
                    }}
                  />
                </div>
                <div className="users_container_list">
                  {searchField.length === 0 && (
                    <div className="my-1 users_container_list_item px-2 py-1 rounded-2">
                      <input
                        type="checkbox"
                        id="all"
                        value="all"
                        className="cursorPointer"
                        onChange={(e) => {
                          if (e.target.checked) {
                            setSelectedUsers(
                              userList.map((user) => user.userId)
                            );
                          } else {
                            setSelectedUsers([]);
                          }
                        }}
                        checked={selectedUsers.length === userList.length}
                      />
                      <label className="ps-2 cursorPointer" htmlFor="all">
                        All
                      </label>
                    </div>
                  )}
                  {userList.length > 0
                    ? userList
                        .filter((user) =>
                          user.name
                            ?.toLowerCase()
                            .includes(searchField.toLowerCase())
                        )
                        // .filter((user) =>
                        //   (usersType === "Users"
                        //     ? user.name
                        //     : user.groupName
                        //   )
                        //     ?.toLowerCase()
                        //     .includes(searchField.toLowerCase())
                        // )
                        .sort((a, b) => a.name.localeCompare(b.name))
                        // .sort((a, b) =>
                        //   usersType === "Users"
                        //     ? a.name.localeCompare(b.name)
                        //     : a.groupName.localeCompare(b.groupName)
                        // )
                        .map((ele) => {
                          return (
                            <div
                              className="mb-1 users_container_list_item px-2 py-1 rounded-2 d-flex align-items-center"
                              key={ele.userId}
                            >
                              <input
                                type="checkbox"
                                id={ele.userId}
                                name={ele.userId}
                                className="cursorPointer"
                                onChange={(e) => {
                                  if (e.target.checked) {
                                    setSelectedUsers([
                                      ...selectedUsers,
                                      ele.userId,
                                    ]);
                                  } else {
                                    setSelectedUsers(
                                      selectedUsers.filter(
                                        (user) => user !== ele.userId
                                      )
                                    );
                                  }
                                }}
                                checked={selectedUsers.includes(ele.userId)}
                              />
                              <label
                                className="ps-2 cursorPointer overflow_text w-100 pb-1"
                                htmlFor={ele.userId}
                                title={ele.name}
                              >
                                {ele.name}
                              </label>
                            </div>
                          );
                        })
                    : null}
                </div>

                <div className="d-flex justify-content-end mt-3">
                  <>
                    <button
                      className="createContactButton px-3 py-1 h-auto w-auto"
                      onClick={(e) => {
                        e.stopPropagation();
                        setShowDropdownType("");
                      }}
                    >
                      Cancel
                    </button>
                    <button
                      className="createContactButtonSave px-3 py-1 h-auto w-auto ms-3"
                      onClick={(e) => {
                        handleApply(e);
                      }}
                    >
                      Apply
                    </button>
                  </>
                </div>
              </div>
            ) : null}
          </div>
          <div className="position-relative">
            <div
              className="d-flex gap-2 sales_trend_dropdown cursorPointer px-2 py-1 rounded-pill"
              onClick={(e) => {
                e.stopPropagation();
                setToDate(today);
                setFromDate(yesterday);
                setShowDropdownType(
                  showDropdownType === `timePeriod ${id}`
                    ? ""
                    : `timePeriod ${id}`
                );
                setCloseDropdown(
                  showDropdownType === `timePeriod ${id}`
                    ? ""
                    : `timePeriod ${id}`
                );
                setShowInsideDropdownType("");
                setSearchField("");
                setSelectedTimePeriod((prev) => prev);
              }}
            >
              {selectedData.type !== "custom" ? (
                <span className="first_letter_uppercase">
                  {`${
                    selectedData.type === "last"
                      ? selectedData.last?.replace(/\b\w/g, (char) =>
                          char.toUpperCase()
                        )
                      : selectedData.current?.replace(/\b\w/g, (char) =>
                          char.toUpperCase()
                        )
                  }`}
                </span>
              ) : (
                <span>
                  {moment(fromDate).format("DD-MM-YYYY")} -&nbsp;
                  {moment(toDate).format("DD-MM-YYYY")}
                </span>
              )}

              <span>
                <img
                  src={
                    showDropdownType === closeDropdown &&
                    showDropdownType === `timePeriod ${id}`
                      ? up
                      : down
                  }
                  alt="img"
                  height={20}
                  width={20}
                  className="mb-1"
                />
              </span>
            </div>
            {showDropdownType === closeDropdown &&
            showDropdownType === `timePeriod ${id}` ? (
              <div
                className="position-absolute bg-white rounded-2 users_container p-3"
                style={{
                  width: "15rem",
                }}
              >
                <div className="">
                  <div className="text-nowrap position-relative">
                    <h6>TIME PERIOD</h6>
                  </div>
                  <div className="d-flex flex-column gap-3 pb-3 pt-1">
                    <div className="d-flex align-items-center">
                      <input
                        type="radio"
                        id="current"
                        name={`timePeriod ${id}`}
                        value="current"
                        className="cursorPointer"
                        onChange={(e) => {
                          if (e.target.checked) {
                            setSelectedTimePeriod(e.target.value);
                          } else {
                            setSelectedTimePeriod("");
                          }
                          setShowInsideDropdownType(e.target.checked);
                          setSelectedCurrent("");
                          setSelectedLast("");
                        }}
                        checked={selectedTimePeriod === "current"}
                      />
                      <label className="ps-2 cursorPointer" htmlFor="current">
                        Current
                        <span
                          className="ps-2 position-relative first_letter_uppercase"
                          onClick={() => {
                            setShowInsideDropdownType(
                              showInsideDropdownType === "currentYear"
                                ? ""
                                : "currentYear"
                            );
                          }}
                        >
                          {selectedCurrent?.replace(/\b\w/g, (char) =>
                            char.toUpperCase()
                          ) || "Week"}
                          <img
                            src={
                              showInsideDropdownType === "currentYear"
                                ? up
                                : down
                            }
                            alt="img"
                            height={20}
                            width={20}
                            className="mb-1 opacity-75"
                          />
                          {showInsideDropdownType === "currentYear" ? (
                            <span
                              className="position-absolute w-100 d-flex flex-column border rounded-2 bg-white end-0"
                              style={{ top: "1.2rem", zIndex: 2 }}
                            >
                              {["week", "month", "year"].map((ele, index) => {
                                return (
                                  <span
                                    className="users_container_list_item px-2 py-1 first_letter_uppercase"
                                    onClick={() => {
                                      setSelectedCurrent(ele);
                                      setSelectedLast("");
                                    }}
                                    key={index}
                                  >
                                    {ele}
                                  </span>
                                );
                              })}
                            </span>
                          ) : null}
                        </span>
                      </label>
                    </div>
                    <div className="d-flex align-items-center">
                      <input
                        type="radio"
                        id="last"
                        name={`timePeriod ${id}`}
                        value="last"
                        className="cursorPointer"
                        onChange={(e) => {
                          if (e.target.checked) {
                            setSelectedTimePeriod(e.target.value);
                          } else {
                            setSelectedTimePeriod("");
                          }
                          setShowInsideDropdownType(e.target.checked);
                        }}
                        checked={selectedTimePeriod === "last"}
                      />
                      <label
                        className="ps-2 cursorPointer d-flex align-items-center"
                        htmlFor="last"
                      >
                        <div>Last</div>
                        <div className="position-relative">
                          <span
                            className=" border-bottom ms-1 px-1"
                            onClick={() => {
                              setShowInsideDropdownType(
                                showInsideDropdownType === "number"
                                  ? ""
                                  : "number"
                              );
                              setSearchField("");
                            }}
                          >
                            {selectedNumber}
                          </span>
                          {showInsideDropdownType === "number" ? (
                            <div
                              className="position-absolute border rounded-2 start-0 bg-white"
                              style={{
                                width: "5rem",
                                top: "1.35rem",
                              }}
                            >
                              <label
                                className="d-flex gap-2 border-bottom"
                                htmlFor="numberField"
                              >
                                <img
                                  className="cursorPointer ps-1"
                                  src={searchIcon}
                                  alt="search"
                                  width={16}
                                />
                                <input
                                  type="number"
                                  className="border-0 w-100"
                                  style={{ outline: "none" }}
                                  id="numberField"
                                  value={searchField}
                                  onChange={(e) => {
                                    setSearchField(e.target.value);
                                  }}
                                />
                              </label>
                              <span className="d-flex flex-column users_container_list ps-4">
                                {[...Array(99).keys()]
                                  .map((i) => i + 1)
                                  .filter((num) =>
                                    num.toString().includes(searchField)
                                  )
                                  .map((num) => (
                                    <span
                                      key={num}
                                      onClick={() => {
                                        setSelectedNumber(num);
                                        setShowInsideDropdownType("");
                                      }}
                                    >
                                      {parseFloat(num)}
                                    </span>
                                  ))}
                              </span>
                            </div>
                          ) : null}
                        </div>
                        <div
                          className="ps-1 position-relative first_letter_uppercase pt-1"
                          onClick={() => {
                            setShowInsideDropdownType(
                              showInsideDropdownType === "lastYear"
                                ? ""
                                : "lastYear"
                            );
                          }}
                        >
                          {selectedLast || "weeks"}
                          <img
                            src={
                              showInsideDropdownType === "lastYear" ? up : down
                            }
                            alt="img"
                            height={20}
                            width={20}
                            className="mb-1 opacity-75"
                          />
                          {showInsideDropdownType === "lastYear" ? (
                            <span
                              className="position-absolute w-auto d-flex flex-column border rounded-2 bg-white end-0"
                              style={{ top: "1.45rem" }}
                            >
                              {["days", "weeks", "months", "years"].map(
                                (ele, index) => {
                                  return (
                                    <span
                                      className="users_container_list_item px-2 py-1 first_letter_uppercase"
                                      onClick={() => {
                                        setSelectedLast(ele);
                                        setSelectedCurrent("");
                                      }}
                                      key={index}
                                    >
                                      {ele}
                                    </span>
                                  );
                                }
                              )}
                            </span>
                          ) : null}
                        </div>
                      </label>
                    </div>
                    <div className="d-flex align-items-center">
                      <input
                        type="radio"
                        id="custom"
                        name={`timePeriod ${id}`}
                        value="custom"
                        className="cursorPointer"
                        onChange={(e) => {
                          if (e.target.checked) {
                            setSelectedTimePeriod(e.target.value);
                          } else {
                            setSelectedTimePeriod("");
                          }
                          setShowInsideDropdownType(e.target.checked);
                        }}
                        checked={selectedTimePeriod === "custom"}
                      />
                      <label
                        className="ps-2 cursorPointer d-flex align-items-center"
                        htmlFor="custom"
                      >
                        Custom
                      </label>
                    </div>
                    {selectedTimePeriod === "custom" ? (
                      <div>
                        <div className="d-flex align-items-center w-100 border-bottom custom_input">
                          <label htmlFor="fromDate" className="w-25">
                            From
                          </label>
                          <input
                            type="date"
                            id="fromDate"
                            className="w-75 borderless_input cursorPointer "
                            value={moment(fromDate).format("YYYY-MM-DD")}
                            onChange={(e) => {
                              if (!e.target.value) {
                                setFromDate(yesterday);
                              } else {
                                setFromDate(e.target.value);
                              }
                            }}
                            max={moment(toDate).format("YYYY-MM-DD")}
                          />
                        </div>
                        <div className="d-flex align-items-center w-100 mt-2 border-bottom custom_input">
                          <label htmlFor="toDate" className="w-25">
                            To
                          </label>
                          <input
                            type="date"
                            id="toDate"
                            className="w-75 borderless_input cursorPointer"
                            value={moment(toDate).format("YYYY-MM-DD")}
                            onChange={(e) => {
                              if (!e.target.value) {
                                setToDate(today);
                              } else {
                                setToDate(e.target.value);
                              }
                            }}
                            max={moment(new Date()).format("YYYY-MM-DD")}
                            min={moment(fromDate).format("YYYY-MM-DD")}
                          />
                        </div>
                      </div>
                    ) : null}
                  </div>
                </div>
                <div className="d-flex justify-content-end mt-3">
                  <>
                    <button
                      className="createContactButton px-3 py-1 h-auto w-auto"
                      onClick={(e) => {
                        e.stopPropagation();
                        setShowDropdownType("");
                      }}
                    >
                      Cancel
                    </button>
                    <button
                      className="createContactButtonSave px-3 py-1 h-auto w-auto ms-3"
                      onClick={(e) => {
                        handleApply(e);
                      }}
                    >
                      Apply
                    </button>
                  </>
                </div>
              </div>
            ) : null}
          </div>
          <div className="position-relative">
            <div
              className="d-flex gap-2 sales_trend_dropdown cursorPointer px-2 py-1 rounded-pill"
              onClick={(e) => {
                e.stopPropagation();
                setShowDropdownType(
                  showDropdownType === `frequency ${id}`
                    ? ""
                    : `frequency ${id}`
                );
                setCloseDropdown(
                  showDropdownType === `frequency ${id}`
                    ? ""
                    : `frequency ${id}`
                );
              }}
            >
              <span className="first_letter_uppercase">
                {selectedFrequency}
              </span>
              <span>
                <img
                  src={
                    showDropdownType === closeDropdown &&
                    showDropdownType === `frequency ${id}`
                      ? up
                      : down
                  }
                  alt="img"
                  height={20}
                  width={20}
                />
              </span>
              {showDropdownType === closeDropdown &&
              showDropdownType === `frequency ${id}` ? (
                <span
                  className="position-absolute w-100 d-flex flex-column border rounded-2 bg-white end-0"
                  style={{ top: "2rem", zIndex: 2 }}
                >
                  {["daily", "weekly", "monthly", "yearly"].map(
                    (ele, index) => {
                      return (
                        <span
                          className={`users_container_list_item px-2 py-1 first_letter_uppercase ${
                            selectedFreqList.includes(ele) ? "" : "opacity-50"
                          }`}
                          onClick={() => {
                            selectedFreqList.includes(ele) &&
                              handleSelectedFreq(ele);
                          }}
                          key={index}
                        >
                          {ele}
                        </span>
                      );
                    }
                  )}
                </span>
              ) : null}
            </div>
          </div>
        </div>
      </div>
      <div className="w-100 h-100">
        {finalData?.length > 0 ? (
          <MultiLineChart data={finalData} />
        ) : (
          <div className="text-center d-flex flex-column justify-content-center align-items-center no_data_img">
            <img src={NoData} alt="No data" className="h-100" />
            <h5 className="fs-6 my-0 py-0">No data</h5>
          </div>
        )}
      </div>
      <NotificationContainer />
    </div>
  );
};

export default CommonMultiLine;

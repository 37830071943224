import React, { useState, useEffect } from "react";
import cancel from "../../Assets/cancelCircle.svg";
import { Modal } from "react-bootstrap";
import ProductRelated from "../../Assets/ProductRelated.svg";
import Tick from "../../Assets/TickMark.svg";
import Cross from "../../Assets/CrossLead.svg";
import axios from "axios";
import { URI } from "../../constants";
import { useSelector } from "react-redux";
import Select from "react-select";
import { customSelectLocationStylesPage, pageOption } from "../CommonData";
import Pagination from "../Pagination";
import SearchIcon from "../../Assets/newSearch.svg";
import NoData from "../../Assets/Nodata.svg";
import Lottie from "react-lottie";
import { defaultOptions } from "../../utils/defaultOptions";
import getHeaders from "../../common/ApiHeader.js";

function Product({
  module,
  productsList,
  selectedProductId,
  setSelectedProductId,
  handleAssignProductModule,
  handledeleteModule,
  assignContactID,
  apiLoader,
}) {
  const reduxStore = useSelector((state) => state.reduxState);
  const { loginUserId } = reduxStore;

  const [hoveredRow, setHoveredRow] = useState(null);
  const [showCancelModal, setShowCancelModal] = useState(false);
  const [isProductModal, setIsProductModal] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };
  const handleRowMouseEnter = (index) => {
    setHoveredRow(index);
  };
  const [allProductDetails, setAllProductDetails] = useState([]);
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [perPage, setPerPage] = useState(10);
  const [totalItems, setTotalItems] = useState(0); // Total number of items
  const [currentPage, setCurrentPage] = useState(1);
  const [deleteProdId, setDeleteProdId] = useState("");
  const [prodName, setProdName] = useState("");

  const totalPages = Math.ceil(Number(totalItems) / perPage);

  const handleRowMouseLeave = () => {
    setHoveredRow(null);
  };

  const handleCheckboxChange = (productId) => {
    setSelectedProductId((prevSelected) => {
      if (prevSelected.includes(productId)) {
        return prevSelected.filter((id) => id !== productId);
      } else {
        return [...prevSelected, productId];
      }
    });
  };

  const handleDeleteConfirmation = () => {
    handledeleteModule(deleteProdId);
    setShowCancelModal(false);
    setSelectedProductId([]);
  };

  const getAllProduct = async () => {
    const requestBody = {
      loginUserId: loginUserId,
      // loggedInUserId: loginUserId,
      pageNo: currentPage,
      pageSize: perPage,
      subModuleName: "PRODUCTS",
      moduleId: assignContactID,
      mainModule: module || "Leads",
    };
    await axios
      .post(URI.getAllUnassignProducts, requestBody, {
        headers: getHeaders(true),
      })
      // .post(URI.getAllProduct, requestBody)

      .then((response) => {
        if (response.data.status === 200) {
          setAllProductDetails(response.data.data.unassignModuleDetails);
          // setAllProductDetails(response.data.data.allProductDetails);
          setTotalItems(response.data.data.recordCount);
          // setTotalItems(response.data.data.rowCount);
        }
      })
      .catch(async (error) => {
        throw new Error("Failed to fetch data", error);
      });
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handlePageDropDowmChange = (selectedOption) => {
    setCurrentPage(1);

    setPerPage(parseInt(selectedOption.value));
  };

  const handleKeyDown = (e) => {
    if (e.key === " " && !e.target.value.trim()) {
      e.preventDefault();
    }
    if (/[^a-zA-Z\s]/.test(e.key)) {
      e.preventDefault();
    }
  };

  useEffect(() => {
    if (isProductModal) {
      productsList.length > 0 && getAllProduct();
    }
  }, [isProductModal]);

  useEffect(() => {
    getAllProduct();
  }, [currentPage, perPage]);

  useEffect(() => {
    const filtered = allProductDetails.filter((item) =>
      item.productName.toLowerCase().includes(searchQuery.toLowerCase())
    );
    setFilteredProducts(filtered);
  }, [searchQuery, allProductDetails]);

  useEffect(() => {
    if (!apiLoader) {
      setIsProductModal(false);
    }
  }, [apiLoader]);

  return (
    <div>
      <div className="d-flex justify-content-between align-items-center ms-2">
        <div className="d-flex gap-2">
          <img src={ProductRelated} alt="product" />
          <div className="notesHeader">Products</div>
        </div>
        <div className="d-flex gap-2 pe-1">
          <button
            className="addProductsButton p-1"
            // onClick={() => {
            //   productsList.length > 0 &&
            //     setSelectedProductId(
            //       productsList.map((product) => product.productId)
            //     );
            //     setInitialSelectedProductId([]);
            //   setIsProductModal(true);
            // }}

            onClick={() => {
              setSelectedProductId(
                productsList.map((product) => product.productId)
              );
              setIsProductModal(true);
              setSelectedProductId([]);
            }}
          >
            Add Product
          </button>
        </div>
      </div>
      <div style={{ borderBottom: "2px solid #ffffff" }} className="mt-2"></div>
      <div className="p-2 mt-2">
        <div className="productListCommon p-2">
          <table
            id="productTable"
            style={{ borderCollapse: "collapse", width: "100%" }}
            className="p-2 Table leadTable"
          >
            {productsList.length > 0 && (
              <thead>
                <tr className="p-2 productTableHeader">
                  <th style={{ width: "15%" }}>Product Name</th>
                  <th style={{ width: "15%" }}>Product Code</th>
                  <th
                    style={{
                      width: "16%",
                      whiteSpace: "nowrap",
                      paddingRight: "10px",
                    }}
                  >
                    Product Active
                  </th>
                  <th style={{ width: "17%", paddingRight: "18px" }}>
                    Manufacturer
                  </th>
                  <th
                    style={{
                      width: "19%",
                      whiteSpace: "nowrap",
                      paddingRight: "18px",
                    }}
                  >
                    Support Start Date
                  </th>
                  <th
                    style={{
                      width: "18%",
                      whiteSpace: "nowrap",
                      paddingRight: "16px",
                    }}
                  >
                    Support End Date
                  </th>
                </tr>
              </thead>
            )}
            <tbody className="p-2">
              {productsList.length > 0 ? (
                productsList.map((item, index) => (
                  <tr
                    key={index}
                    className={`position-relative p-2 ${
                      hoveredRow === index ? "mt-4" : ""
                    }`}
                    onMouseEnter={() => handleRowMouseEnter(index)}
                    onMouseLeave={handleRowMouseLeave}
                    style={{ borderBottom: "1px solid #ccc" }}
                  >
                    <td style={{ color: "#2177FE", fontSize: "16px" }}>
                      {item.productName}
                    </td>
                    <td className="productsTabledata">{item.productCode}</td>
                    <td>
                      {item.productActive ? (
                        <img
                          src={Tick}
                          alt="true"
                          style={{ width: "20px", height: "20px" }}
                        />
                      ) : (
                        <img
                          src={Cross}
                          alt="cross"
                          style={{ width: "20px", height: "20px" }}
                        />
                      )}
                    </td>
                    <td className="productsTabledata">{item.manufacturer}</td>
                    <td className="productsTabledata">
                      {item.supportStartDate}
                    </td>
                    <td className="productsTabledata">{item.supportEndDate}</td>
                    <td
                      style={{ textAlign: "center" }}
                      className="position-absolute end-0 mt-2"
                    >
                      {hoveredRow === index && (
                        <img
                          src={cancel}
                          alt="cancel"
                          style={{
                            width: "20px",
                            height: "20px",
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            setShowCancelModal(true);
                            setDeleteProdId(item.productId);
                            setProdName(item.productName);
                          }}
                        />
                      )}
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td
                    colSpan="7"
                    style={{ textAlign: "center", padding: "20px" }}
                  >
                    <div className="text-center mt-2">
                      <div
                        className="ms-4"
                        style={{ color: "#B9B9B9", fontSize: "20px" }}
                      >
                        No records found
                      </div>
                      <div
                        className="ms-4"
                        style={{ color: "#B9B9B9", fontSize: "14px" }}
                      >
                        There is no data available to show
                      </div>
                    </div>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        {showCancelModal && (
          <Modal
            show={showCancelModal}
            className="d-flex justify-content-start align-items-center"
            aria-labelledby="example-custom-modal-styling-title"
            size="lg"
            id="commonLeadModal"
          >
            <Modal.Header
              onClick={() => {
                setShowCancelModal(false);
              }}
              style={{
                fontSize: "20px",
                fontWeight: "bold",
                background:
                  "linear-gradient(to right, rgba(64, 123, 255, 1), rgba(18, 167, 254, 1))",
                WebkitBackgroundClip: "text",
                backgroundClip: "text",
                WebkitTextFillColor: "transparent",
              }}
            >
              Are you sure you want to delete {prodName} ?
              {/* <span className="borderBottom">{prodName}</span> */}
            </Modal.Header>
            <Modal.Body className="ModalBody overflow-auto">
              <div className="d-flex flex-column gap-4">
                <div>
                  Note:{" "}
                  <span style={{ fontSize: "14px", color: "#9C9C9C" }}>
                    Any associated activities, visits, drafts will also be
                    deleted.
                  </span>
                </div>

                <div className="d-flex justify-content-end w-100 gap-4">
                  <button
                    className="cancelButton px-2"
                    onClick={() => {
                      setShowCancelModal(false);
                    }}
                  >
                    Cancel
                  </button>
                  <button
                    className="unassignButton px-2"
                    onClick={() => handleDeleteConfirmation()}
                  >
                    Delete
                  </button>
                </div>
              </div>
            </Modal.Body>
          </Modal>
        )}
        {/* {isProductModal && (<AssignCommonModal />} */}

        <>
          {/* <AssignCommonModal
              show={isProductModal}
              onHide={() => setIsProductModal(false)}
              assignContactID={assignContactID}
              selectedProductId={selectedProductId}
              setSelectedProductId={setSelectedProductId}
              handleAssignModule={handleAssignModule}
            /> */}

          <Modal
            className="d-flex justify-content-center align-items-center"
            aria-labelledby="example-custom-modal-styling-title"
            size="lg"
            id="commonLeadModal"
            show={isProductModal}
            onHide={() => setIsProductModal(false)}
          >
            <Modal.Body className="ModalBody  overflow-auto">
              <div
                className="modalUS row justify-content-center pb-0"
                style={{
                  margin: "0",
                  gap: "10px",
                }}
              >
                <div className="header_Container d-flex justify-content-between">
                  <h4 className="commonTextColor pt-2 pb-0 mb-0">
                    Assign Products
                  </h4>
                  {/* <div className="d-flex justify-content-start">
                <input
                  type="text"
                  className="inputBox"
                  placeholder="Search users"
                  value={searchQuery}
                  onChange={handleSearchChange}
                />
                <img src={SearchIcon} alt="search" className="SearchIcon" />
              </div> */}
                  <div className="inputContainer d-flex justify-content-start">
                    <input
                      type="text"
                      placeholder="Search"
                      className="topBarModalSearch px-5 mb-1"
                      value={searchQuery}
                      onChange={handleSearchChange}
                      onKeyDown={handleKeyDown}
                    />
                    <img
                      src={SearchIcon}
                      alt="search"
                      className="searchIcon"
                      style={{ width: "20px" }}
                    />
                  </div>
                </div>

                <div className="Owner_table_container rounded-4 h-25 mt-0">
                  <div className="table-wrapper">
                    {filteredProducts.length > 0 ? (
                      <table className="userdata-table ">
                        <thead>
                          <tr>
                            <th>Product Name</th>
                            <th>Product Code</th>
                            {/* <th>Vendor Name</th> */}
                            <th>Unit Price</th>
                            <th>Tax</th>
                            <th>Taxable</th>
                          </tr>
                        </thead>
                        <tbody className="table-body tableUserData">
                          {filteredProducts.map((item, index) => (
                            <tr key={index}>
                              <td className="">
                                <input
                                  type="checkbox"
                                  // checked={
                                  //   selectedProductId === item.productId
                                  // }
                                  checked={selectedProductId.includes(
                                    item.productId
                                  )}
                                  onChange={() => {
                                    handleCheckboxChange(item.productId);
                                  }}
                                />

                                <span className="ms-2">
                                  {" "}
                                  {item.productName}{" "}
                                </span>
                              </td>
                              <td>{item.productCode}</td>
                              <td>{item.unitPrice}</td>
                              <td>{item.tax}</td>
                              <td>
                                {item.taxable === "Active"
                                  ? "Active"
                                  : "Inactive"}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    ) : (
                      <div className="w-100 text-center d-flex flex-column justify-content-center align-items-center no_data_img pt-5">
                        <img src={NoData} alt="No data" className="" />
                        <h4 className="fs-5 my-0 py-0">No record(s) found</h4>
                      </div>
                    )}
                  </div>
                </div>
                <div className="d-flex w-100 align-items-center justify-content-end gap-2">
                  {filteredProducts.length > 0 && (
                    <>
                      <Select
                        options={pageOption}
                        styles={customSelectLocationStylesPage}
                        // value={selectPageOption}
                        value={pageOption.find(
                          (option) => option.value === perPage.toString()
                        )}
                        onChange={handlePageDropDowmChange}
                      />
                      {/* <p className="mb-0">· ‹1/10›</p> */}

                      <Pagination
                        currentPage={currentPage}
                        totalPages={totalPages}
                        onPageChange={handlePageChange}
                      />
                    </>
                  )}
                </div>

                <div className="d-flex pt-2 justify-content-between">
                  <div className="selectedUser d-flex gap-1 pt-2">
                    {/* <span>Selected User</span>
                <img src={logoConvert} alt="img" height={25} width={25} />
                <span>{selectedLead.name}</span> */}
                  </div>
                  <div className="d-flex gap-2">
                    <button
                      className="createContactButton modalButton"
                      onClick={() => {
                        setIsProductModal(false);
                        setSearchQuery("");
                        setSelectedProductId([]);
                      }}
                    >
                      Cancel
                    </button>
                    <button
                      className="createContactButtonSave modalButton"
                      onClick={() => {
                        handleAssignProductModule(selectedProductId);
                        setSelectedProductId([]);
                        setSearchQuery("");
                        // setTimeout(() => {
                        //   setIsProductModal(false);
                        // }, 200);
                      }}
                      disabled={apiLoader}
                    >
                      {apiLoader ? (
                        <Lottie
                          options={defaultOptions("white")}
                          height="24px"
                          width="36px"
                        />
                      ) : (
                        "Next"
                      )}
                    </button>
                  </div>
                </div>
              </div>
            </Modal.Body>
          </Modal>
        </>
      </div>
    </div>
  );
}

export default Product;

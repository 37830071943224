import React, { useState } from "react";
import attachIcon from "../../Assets/file-attachment.svg";
import filterMenu from "../../Assets/filterMenu.svg";
import SearchIcon from "../../Assets/SearchIcon.svg";
import { Modal } from "react-bootstrap";

const PurchaseCommonTable = ({
  title,
  buttonLabel,
  buttonAction,
  showHeadings,
  setShowHeadings,
  selectedHeadings,
  data,
  onRowClick,
  manageColumnsAction,
  resetColumnSizeAction,
  setShowSelectedFilter,
  selectedPurchaseId,
  // setSelectedPurchaseId,
  handleAssignPurchaseModule,
}) => {
  const [isAssignModal, setIsAssignModal] = useState(false);
  // const [allPurchaseOrders, setAllPurchaseOrders] = useState([]);

  // const handleCheckboxChange = (purchaseOrdId) => {
  //   setSelectedPurchaseId((prevSelected) => {
  //     if (prevSelected.includes(purchaseOrdId)) {
  //       return prevSelected.filter((id) => id !== purchaseOrdId);
  //     } else {
  //       return [...prevSelected, purchaseOrdId];
  //     }
  //   });
  // };

  return (
    <div
      className="lead_Information_mainContainer commonContainer_bg rounded-2 d-flex flex-column py-2 mt-4"
      // style={{ overflowX: "auto", overflowY: "hidden" }}
    >
      <div className="d-flex justify-content-between align-items-center ms-2">
        <div className="d-flex gap-2">
          <img src={attachIcon} alt="product" />
          <div className="notesHeader">{title}</div>
        </div>
        <div className="d-flex gap-2 pe-1">
          <button className="addProductsButton" onClick={buttonAction}>
            {buttonLabel}
          </button>
        </div>
      </div>
      <div style={{ borderBottom: "2px solid #ffffff" }} className="mt-1"></div>
      <div className="p-2 mt-2" onClick={() => setShowHeadings(false)}>
        <div style={{ overflowX: "auto" }}>
          <table
            id="productTable"
            style={{ borderCollapse: "collapse", width: "100%" }}
            className="py-2"
          >
            <thead>
              <tr className="productTableHeader">
                {selectedHeadings.map(
                  (heading, index) =>
                    heading.isChecked && (
                      <th scope="col" key={index}>
                        <span className="pe-5">{heading.name}</span>
                      </th>
                    )
                )}
                <th
                  scope="col"
                  className="text-end cursorPointer position-relative px-0"
                >
                  <img
                    src={filterMenu}
                    alt=""
                    className={`filter_menu ${showHeadings ? "bg-light" : ""}`}
                    onClick={(e) => {
                      e.stopPropagation();
                      setShowSelectedFilter("");
                      setShowHeadings(true);
                    }}
                  />
                  {showHeadings && (
                    <div
                      className="position-absolute rounded-2 border border-primary bg-light"
                      style={{
                        right: "0.2rem",
                      }}
                    >
                      <div className="d-flex flex-column px-2">
                        <span
                          className="p-2 cursorPointer fw-normal text-start"
                          onClick={manageColumnsAction}
                        >
                          Manage Columns
                        </span>
                        <span
                          className="p-2 fw-normal text-start opacity-50"
                          onClick={resetColumnSizeAction}
                        >
                          Reset Column Size
                        </span>
                      </div>
                    </div>
                  )}
                </th>
              </tr>
            </thead>
            <tbody>
              {data?.length > 0 ? (
                data.map((item, index) => (
                  <tr
                    style={{
                      cursor: "pointer",
                      borderBottom: "1px solid #ccc",
                    }}
                    key={index}
                    onClick={() => onRowClick(index)}
                    className="w-100 px-0"
                  >
                    {selectedHeadings.map((obj, i) => {
                      const value = obj.value;
                      const itemValue = item[value];
                      return obj.isChecked ? (
                        <td
                          key={i}
                          className={`productsTabledata px-0 ${
                            itemValue ?? "" ? "" : "ps-4"
                          }`}
                        >
                          {itemValue ?? "" ? itemValue : "--"}
                        </td>
                      ) : null;
                    })}
                    <td></td>
                  </tr>
                ))
              ) : (
                <tr>
                  {/* <tr className="d-flex justify-content-center"> */}
                  <td
                    colSpan={selectedHeadings.length + 1} // +1 for the extra <td></td>
                    style={{ textAlign: "center", padding: "20px" }}
                  >
                    <div className="text-center mt-2">
                      <div
                        className="ms-4"
                        style={{ color: "#B9B9B9", fontSize: "20px" }}
                      >
                        No records found
                      </div>
                      <div
                        className="ms-4"
                        style={{ color: "#B9B9B9", fontSize: "14px" }}
                      >
                        There is no data available to show
                      </div>
                    </div>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        {isAssignModal && (
          <>
            {/* <AssignCommonModal
              show={isProductModal}
              onHide={() => setIsProductModal(false)}
              assignContactID={assignContactID}
              selectedPurchaseId={selectedPurchaseId}
              setSelectedQuoteId={setSelectedQuoteId}
              handleAssignQuoteModule={handleAssignQuoteModule}
            /> */}

            <Modal
              className="d-flex justify-content-center align-items-center"
              aria-labelledby="example-custom-modal-styling-title"
              size="lg"
              id="commonLeadModal"
              show={isAssignModal}
              onHide={() => setIsAssignModal(false)}
            >
              <Modal.Body className="ModalBody  overflow-auto">
                <div
                  className="modalUS row justify-content-center"
                  style={{
                    margin: "0",
                    gap: "10px",
                    paddingBottom: "10px",
                  }}
                >
                  <div className="header_Container d-flex justify-content-between">
                    <h4 className="commonTextColor pt-2">Assign </h4>
                    {/* <div className="d-flex justify-content-start">
                <input
                  type="text"
                  className="inputBox"
                  placeholder="Search users"
                  value={searchQuery}
                  onChange={handleSearchChange}
                />
                <img src={SearchIcon} alt="search" className="SearchIcon" />
              </div> */}
                    <div className="inputContainer d-flex justify-content-start">
                      <input
                        type="text"
                        placeholder="Search"
                        className="topBarModalSearch px-5"
                      />
                      <img
                        src={SearchIcon}
                        alt="search"
                        className="searchIcon"
                      />
                    </div>
                  </div>

                  <div className="Owner_table_container rounded-4 h-25">
                    <div className="table-wrapper">
                      <table className="userdata-table">
                        <thead>
                          <tr>
                            <th>Subject</th>
                            <th>Status</th>
                            <th>Po Date</th>
                            <th>Purchase Order Owner</th>
                          </tr>
                        </thead>
                        <tbody className="table-body tableUserData">
                          {/* {allPurchaseOrders.map((item, index) => (
                            <tr key={index}>
                              <td className="">
                                <input
                                  type="checkbox"
                                  checked={selectedPurchaseId.includes(
                                    item.purchaseOrdId
                                  )}
                                  onChange={() => {
                                    handleCheckboxChange(item.purchaseOrdId);
                                  }}
                                />

                                <span className="ms-2"> {item.subject} </span>
                              </td>
                              <td>{item.status}</td>
                              <td>{item.poDate}</td>

                              <td>{item.purchaseOrderOwner}</td>
                            </tr>
                          ))} */}
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div className="d-flex w-100 align-items-center justify-content-end gap-2">
                    {/* <Select
                        options={pageOption}
                        styles={customSelectLocationStylesPage}
                        value={pageOption.find(
                          (option) => option.value === perPage.toString()
                        )}
                        onChange={handlePageDropDowmChange}
                      />

                      <Pagination
                        currentPage={currentPage}
                        totalPages={totalPages}
                        onPageChange={handlePageChange}
                      /> */}
                  </div>

                  <div className="d-flex pb-2 pt-3 justify-content-between">
                    <div className="selectedUser d-flex gap-1 pt-2">
                      {/* <span>Selected User</span>
                <img src={logoConvert} alt="img" height={25} width={25} />
                <span>{selectedLead.name}</span> */}
                    </div>
                    <div className="d-flex gap-2">
                      <button
                        className="createContactButton modalButton"
                        onClick={() => setIsAssignModal(false)}
                      >
                        Cancel
                      </button>
                      <button
                        className="createContactButtonSave modalButton"
                        onClick={() => {
                          handleAssignPurchaseModule(selectedPurchaseId);
                          setTimeout(() => {
                            setIsAssignModal(false);
                          }, 200);
                        }}
                      >
                        Next
                      </button>
                    </div>
                  </div>
                </div>
              </Modal.Body>
            </Modal>
          </>
        )}
      </div>
    </div>
  );
};

export default PurchaseCommonTable;

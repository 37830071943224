import React from "react";

const MassDelete = ({ handleClose, deleteMassDetails }) => {
  return (
    <div className="modalClass position-relative">
      <>
        <div className="fs-5 pt-0 pb-2">
          Are you sure you want to delete selected item(s)?
        </div>
        <div className="small">
          <span className="fw-bold">Note: </span>
          <span>Any associated Activities, Drafts will also be Deleted</span>
        </div>
      </>
      <div className="pt-4 pe-5">
        <div className="d-flex justify-content-end w-100 gap-4 pe-2">
          <button className="cancelButton px-4" onClick={handleClose}>
            Cancel
          </button>
          <button className="btn-update" onClick={deleteMassDetails}>
            Delete
          </button>
        </div>
      </div>
    </div>
  );
};

export default MassDelete;

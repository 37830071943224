import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import SearchIcon from "../Assets/search.svg";
import logoConvert from "../Assets/logoConvert.svg";
import axios from "axios";
import { URI } from "../constants";
import { useSelector } from "react-redux";
import getHeaders from "./ApiHeader";

const ProductListOverviewModal = ({
  showModalType,
  isProductOwnerModal,
  setIsProductOwnerModal,
  onSelectedProductChange,
  defaultUser,
}) => {
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedLead, setSelectedLead] = useState("");
  const [allProductDetails, setAllProductDetails] = useState([]);
  const reduxStore = useSelector((state) => state.reduxState);
  const { loginUserId } = reduxStore;
  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const getAllProduct = async () => {
    const requestBody = {
      loggedInUserId: loginUserId,
      pageNo: 1,
      pageSize: 100,
    };
    await axios
      .post(URI.getAllProduct, requestBody, {
        headers: getHeaders(true),
      })

      .then((response) => {
        if (response.data.status === 200) {
          setAllProductDetails(response.data.data.allProductDetails);
        }
      })
      .catch(async (error) => {
        throw new Error("Failed to fetch data", error);
      });
  };

  useEffect(() => {
    getAllProduct();
  }, []);

  useEffect(() => {
    if (isProductOwnerModal) {
      setSearchQuery("");
      setSelectedLead(
        defaultUser || {
          productName: "",
          productId: "",
        }
      );
    }
  }, [isProductOwnerModal, defaultUser]);

  const filteredProductList = allProductDetails.filter((item) =>
    item.productName?.toLowerCase().includes(searchQuery?.toLowerCase())
  );
  return (
    <div className="OwnerModal">
      <Modal
        show={
          showModalType === "type"
            ? isProductOwnerModal !== ""
            : isProductOwnerModal
        }
        className="d-flex justify-content-center align-items-center"
        aria-labelledby="example-custom-modal-styling-title"
        size="lg"
        id="commonLeadModal"
      >
        <Modal.Body className="ModalBody  overflow-auto">
          <div
            className="modalUS row justify-content-center"
            style={{
              margin: "0",
              gap: "10px",
              paddingBottom: "10px",
            }}
          >
            <div className="header_Container d-flex justify-content-between">
              <h4 className="commonTextColor pt-2">Product Owner</h4>
              {/* <div className="d-flex justify-content-start">
                <input
                  type="text"
                  className="inputBox"
                  placeholder="Search users"
                  value={searchQuery}
                  onChange={handleSearchChange}
                />
                <img src={SearchIcon} alt="search" className="SearchIcon" />
              </div> */}
              <div className="inputContainer d-flex justify-content-start">
                <input
                  type="text"
                  placeholder="Search"
                  className="topBarModalSearch px-5"
                  onChange={handleSearchChange}
                  value={searchQuery}
                />
                <img src={SearchIcon} alt="search" className="searchIcon" />
              </div>
            </div>

            <div className="Owner_table_container rounded-4 h-25">
              <div className="table-wrapper">
                <table className="userdata-table">
                  <thead>
                    <tr>
                      <th>Product Name</th>
                      <th>Product Code</th>
                      <th>Product Owner</th>
                      <th>Taxable</th>
                    </tr>
                  </thead>
                  <tbody className="table-body tableUserData">
                    {filteredProductList?.map((user, index) => (
                      <tr
                        key={index}
                        onClick={() => {
                          setSelectedLead(user);
                        }}
                      >
                        <td className="">
                          <span className="ms-2"> {user.productName} </span>
                        </td>
                        <td>{user.productCode}</td>
                        <td>{user.productOwner}</td>
                        <td>{user.taxable === false ? "✕" : "✓"}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>

            <div className="d-flex pb-2 pt-3 justify-content-between">
              <div className="selectedUser d-flex gap-1 pt-2">
                <span>Selected User</span>
                <img src={logoConvert} alt="img" height={25} width={25} />
                <span>{selectedLead.productName}</span>
              </div>
              <div className="d-flex gap-2">
                <button
                  className="createContactButton modalButton"
                  onClick={() =>
                    setIsProductOwnerModal(
                      showModalType === "type" ? "" : false
                    )
                  }
                >
                  Cancel
                </button>
                <button
                  className="createContactButtonSave modalButton"
                  onClick={() => {
                    setIsProductOwnerModal(false);
                    onSelectedProductChange(selectedLead);
                  }}
                >
                  Done
                </button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default ProductListOverviewModal;

import React, { useState, useEffect, useRef } from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from "recharts";
import { getChartColor } from "../dashboardData";

const MyStackedBarChart = ({ data }) => {
  const chartContainerRef = useRef(null);
  const [chartDimensions, setChartDimensions] = useState({
    width: 0,
    height: 0,
  });
  const chartRef = useRef(null);

  useEffect(() => {
    const handleResize = () => {
      if (chartContainerRef.current) {
        setChartDimensions({
          width: chartContainerRef.current.offsetWidth,
          height: chartContainerRef.current.offsetHeight - 22,
        });
      }
    };

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const getUniqueCampaignTypes = (data) => {
    const types = new Set();
    data.forEach((item) => {
      types.add(item.campaignType);
    });
    return Array.from(types);
  };

  const transformData = (data, campaignTypes) => {
    const result = [];

    campaignTypes.forEach((type) => {
      const newObj = { campaignType: type };
      data.forEach((item) => {
        if (item.campaignType === type) {
          newObj[item.campaignName] =
            (newObj[item.campaignName] || 0) + item.recordCount;
        }
      });
      result.push(newObj);
    });

    return result;
  };

  const campaignTypes = getUniqueCampaignTypes(data);
  const transformedData = transformData(data, campaignTypes);

  const campaignNames = [...new Set(data.map((item) => item.campaignName))];

  const [hiddenBars, setHiddenBars] = useState({});

  const handleLegendClick = (dataKey) => {
    setHiddenBars((prevHiddenBars) => ({
      ...prevHiddenBars,
      [dataKey]: !prevHiddenBars[dataKey],
    }));
  };

  return (
    <div ref={chartContainerRef} style={{ width: "100%", height: "100%" }}>
      {chartDimensions.width > 0 && chartDimensions.height > 0 && (
        <BarChart
          width={chartDimensions.width}
          height={chartDimensions.height}
          data={transformedData}
          margin={{
            top: 0,
            right: 0,
            left: 0,
            bottom: 10,
          }}
          fontSize="12px"
          ref={chartRef}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis
            dataKey="campaignType"
            // label={{
            //   value: "Campaign Name",
            //   position: "insideBottomCenter",
            //   offset: 45,
            //   style: {
            //     textAnchor: "middle",
            //     fontSize: "12px",
            //   },
            // }}
          />
          <YAxis
            label={{
              value: "Record Count",
              angle: -90,
              position: "insideLeft",
              style: {
                textAnchor: "middle",
                fontSize: "12px",
              },
            }}
          />
          <Tooltip
            contentStyle={{
              fontSize: "12px",
              padding: "0px 2px",
              backgroundColor: "#e4e7eb",
              maxWidth: "25rem",
              whiteSpace: "wrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          />
          <Legend
            onClick={(e) => handleLegendClick(e.dataKey)}
            iconType="circle"
            iconSize={9}
            wrapperStyle={{
              fontSize: "10px",
              padding: "0 5px",
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "center",
              alignItems: "center",
              overflowY: "auto",
              cursor: "pointer",
            }}
            layout="horizontal"
            align="right"
            formatter={(value) => (
              <span
                style={{ fontSize: "10px", maxWidth: "13rem" }}
                className="overflow_text d-block"
                title={value}
              >
                {value}
              </span>
            )}
          />
          {campaignNames.map((name, index) => (
            <Bar
              key={name}
              dataKey={name}
              stackId="a"
              fill={getChartColor(index)}
              hide={hiddenBars[name]}
            />
          ))}
        </BarChart>
      )}
    </div>
  );
};

export default MyStackedBarChart;

import React from "react";
import { Modal } from "react-bootstrap";
import searchIcon from "../../Assets/search.svg";

const PurchaseOrderVendorModal = ({
  modalType,
  searchColumn,
  setSearchColumn,
  setSavingHeadings,
  purchaseColumns,
  savingHeadingsPurchase,
  handleColumnCheckboxChangePurchase,
  setShowModalType,
  setSelectedHeadingsPurchases,
}) => {
  return (
    <Modal
      show={modalType === "Purchase Order Columns"}
      id="ConvertModal"
      aria-labelledby="example-custom-modal-styling-title"
      className="withdrw_modal"
    >
      <Modal.Body>
        <div className="modalClass  position-relative">
          <div className="d-flex justify-content-between ">
            <h4 className="">Manage Columns</h4>
            {/* <img
                src={crossIcon}
                alt="cross"
                style={{ cursor: "pointer" }}
                className="ms-1"
                onClick={() => {
                  setShowModalType("");
                }}
              /> */}
          </div>
          <div className="w-100 position-relative">
            <div className="mb-1 pt-2 position-relative w-100">
              <input
                type="text"
                className="assetSearchbar searchbar rounded-2 p-2 pe-5 common_input_bg w-100 "
                placeholder="Search"
                aria-label="Username"
                aria-describedby="basic-addon1"
                value={searchColumn}
                onChange={(e) => {
                  if (e.target.value.charAt(0) !== " ") {
                    const value = e.target.value.replace(
                      /[^a-zA-Z ]+|(\s)\s+/g,
                      "$1"
                    );
                    setSearchColumn(value);
                    // const list = purchaseColumns.filter((contact) =>
                    //   contact.name
                    //     .toLowerCase()
                    //     .includes(value.trim().toLowerCase())
                    // );
                    // setSelectedHeadingsPurchases(list);
                  }
                }}
              />
              <span
                className="position-absolute  mt-2 me-2 end-0 opacity-75"
                onClick={() => {
                  setSearchColumn("");
                  setSavingHeadings(purchaseColumns);
                }}
              >
                {searchColumn === "" ? (
                  <img src={searchIcon} alt="" width={20} />
                ) : (
                  <div style={{ cursor: "pointer" }}>X</div>
                )}
              </span>
            </div>
            <div className=" opacity-75 columns_container">
              {savingHeadingsPurchase
                .filter((lead) =>
                  lead.name
                    .toLowerCase()
                    .includes(searchColumn.trim().toLowerCase())
                )
                .sort((a, b) => a.name.localeCompare(b.name))
                .map((column) => {
                  return (
                    <div
                      key={column.name}
                      className="d-flex gap-2 py-2 rounded-2 align-items-center"
                    >
                      <div className="d-flex gap-2 ps-2">
                        <input
                          type="checkbox"
                          id={column.name}
                          name={column.name}
                          checked={column.isChecked}
                          onChange={(e) => {
                            handleColumnCheckboxChangePurchase(
                              column.name,
                              e.target.checked
                            );
                          }}
                          className="cursorPointer"
                        />
                        <label
                          className="small cursorPointer"
                          htmlFor={column.name}
                        >
                          {column.name}
                        </label>
                      </div>
                    </div>
                  );
                })}
            </div>
            <aside className="float-end mt-2">
              <div className="d-flex align-items-center gap-4">
                <button
                  className="cancel_btn bg-light"
                  onClick={() => {
                    setShowModalType(" ");
                  }}
                >
                  Cancel
                </button>
                <button
                  className="save_btn"
                  onClick={() => {
                    setSelectedHeadingsPurchases(savingHeadingsPurchase);
                    setSearchColumn(" ");

                    setTimeout(() => {
                      setShowModalType("");
                    }, 200);
                  }}
                >
                  Save
                </button>
              </div>
            </aside>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default PurchaseOrderVendorModal;

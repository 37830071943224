import React, { useState, useEffect } from "react";
import cancel from "../../Assets/cancelCircle.svg";
import { Modal } from "react-bootstrap";
import ProductRelated from "../../Assets/ProductRelated.svg";
import axios from "axios";
import { URI } from "../../constants";
import { useSelector } from "react-redux";
import Select from "react-select";
import { customSelectLocationStylesPage, pageOption } from "../CommonData";
import Pagination from "../Pagination";
import SearchIcon from "../../Assets/newSearch.svg";
import NoData from "../../Assets/Nodata.svg";
import filterMenu from "../../Assets/filterMenu.svg";
import Lottie from "react-lottie";
import { defaultOptions } from "../../utils/defaultOptions";
import getHeaders from "../../common/ApiHeader.js";

function LeadList({
  module,
  leadList,
  selectedLeadId,
  setSelectedLeadId,
  handleAssignProductModule,
  handledeleteModule,
  assignContactID,
  selectedHeadings,
  setSearchColumn,
  setSavingHeadings,
  showHeadings,
  setShowSelectedFilter,
  setShowHeadings,
  manageColumnsAction,
  resetColumnSizeAction,
  onRowClick,
  apiLoader,
}) {
  const reduxStore = useSelector((state) => state.reduxState);
  const { loginUserId } = reduxStore;

  const [hoveredRow, setHoveredRow] = useState(null);
  const [showCancelModal, setShowCancelModal] = useState(false);
  const [isLeadModal, setIsLeadModal] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };
  const handleRowMouseEnter = (index) => {
    setHoveredRow(index);
  };
  const [allLeadDetails, setAllLeadDetails] = useState([]);
  const [filteredLeads, setFilteredLeads] = useState([]);
  const [perPage, setPerPage] = useState(10);
  const [totalItems, setTotalItems] = useState(0); // Total number of items
  const [currentPage, setCurrentPage] = useState(1);
  const [deleteLeadId, setDeleteLeadId] = useState("");
  const [leadName, setLeadName] = useState("");

  const totalPages = Math.ceil(Number(totalItems) / perPage);

  const handleRowMouseLeave = () => {
    setHoveredRow(null);
  };

  const handleCheckboxChange = (leadId) => {
    setSelectedLeadId((prevSelected) => {
      if (prevSelected.includes(leadId)) {
        return prevSelected.filter((id) => id !== leadId);
      } else {
        return [...prevSelected, leadId];
      }
    });
  };

  const handleDeleteConfirmation = () => {
    handledeleteModule(deleteLeadId);
    setShowCancelModal(false);
    setSelectedLeadId([]);
  };

  useEffect(() => {
    if (!apiLoader) {
      setIsLeadModal(false);
    }
  }, [apiLoader]);

  const getAllLead = async () => {
    const requestBody = {
      loginUserId: loginUserId,
      pageNo: currentPage,
      pageSize: perPage,
      subModuleName: "Leads",
      moduleId: assignContactID,
      mainModule: module || "Campaign",
    };
    await axios
      .post(URI.getAllUnassignProducts, requestBody, {
        headers: getHeaders(true),
      })
      // .post(URI.getAllLead, requestBody)

      .then((response) => {
        if (response.data.status === 200) {
          setAllLeadDetails(response.data.data.unassignModuleDetails);
          setTotalItems(response.data.data.recordCount);
        }
      })
      .catch(async (error) => {
        alert(error);
      });
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handlePageDropDowmChange = (selectedOption) => {
    setCurrentPage(1);

    setPerPage(parseInt(selectedOption.value));
  };

  const handleKeyDown = (e) => {
    if (e.key === " " && !e.target.value.trim()) {
      e.preventDefault();
    }
    if (/[^a-zA-Z\s]/.test(e.key)) {
      e.preventDefault();
    }
  };

  useEffect(() => {
    if (isLeadModal) {
      leadList.length > 0 && getAllLead();
    }
  }, [isLeadModal]);

  useEffect(() => {
    getAllLead();
  }, [currentPage, perPage]);

  useEffect(() => {
    const filtered = allLeadDetails.filter((item) =>
      item.leadName.toLowerCase().includes(searchQuery.toLowerCase())
    );
    setFilteredLeads(filtered);
  }, [searchQuery, allLeadDetails]);

  return (
    <div>
      <div className="d-flex justify-content-between align-items-center ms-2">
        <div className="d-flex gap-2">
          <img src={ProductRelated} alt="product" />
          <div className="notesHeader">Leads</div>
        </div>
        <div className="d-flex gap-2 pe-1">
          <button
            className="addProductsButton p-1"
            // onClick={() => {
            //   leadList.length > 0 &&
            //     setSelectedLeadId(
            //       leadList.map((product) => product.leadId)
            //     );
            //     setInitialSelectedLeadId([]);
            //   setIsLeadModal(true);
            // }}

            onClick={() => {
              setSelectedLeadId(leadList.map((product) => product.leadId));
              setIsLeadModal(true);
              setSelectedLeadId([]);
            }}
          >
            Add Lead
          </button>
        </div>
      </div>
      <div style={{ borderBottom: "2px solid #ffffff" }} className="mt-2"></div>
      <div className="p-2 mt-2">
        <div className="productListCommon p-2">
          <table
            id="productTable"
            style={{ borderCollapse: "collapse", width: "100%" }}
            className="p-2 Table leadTable"
          >
            <thead
              style={{ display: "table", width: "100%", tableLayout: "auto" }}
            >
              <tr className="p-2 productTableHeader">
                {selectedHeadings.map(
                  (heading, index) =>
                    heading.isChecked && (
                      <th scope="col" key={index} style={{
                        whiteSpace: "nowrap", 
                        minWidth: "150px", 
                        textAlign: "left", 
                      }}>
                        <span className="pe-5">{heading.name}</span>
                      </th>
                    )
                )}
                <th
                  scope="col"
                  className="text-end cursorPointer position-relative"
                  style={{ whiteSpace: "nowrap" }}
                >
                  <img
                    src={filterMenu}
                    alt=""
                    className={`filter_menu ${showHeadings ? "bg-light" : ""}`}
                    onClick={(e) => {
                      e.stopPropagation();
                      setShowSelectedFilter("");
                      setShowHeadings(true);
                    }}
                  />
                  {showHeadings && (
                    <div
                      className="position-absolute rounded-2 border border-primary bg-light"
                      style={{
                        right: "0.2rem",
                      }}
                    >
                      <div className="d-flex flex-column px-2">
                        <span
                          className="p-2 cursorPointer fw-normal text-start"
                          onClick={() => {
                            manageColumnsAction();
                            setSearchColumn("");
                            setSavingHeadings(selectedHeadings);
                          }}
                        >
                          Manage Columns
                        </span>
                        <span
                          className="p-2 fw-normal text-start opacity-50"
                          onClick={resetColumnSizeAction}
                        >
                          Reset Column Size
                        </span>
                      </div>
                    </div>
                  )}
                </th>
              </tr>
            </thead>
            <tbody className="p-2">
              {leadList && leadList.length > 0 ? (
                leadList.map((item, index) => (
                  <tr
                    style={{
                      cursor: "pointer",
                      borderBottom: "1px solid #ccc",
                    }}
                    key={index}
                    onClick={() => onRowClick(index)}
                    onMouseEnter={() => handleRowMouseEnter(index)}
                    onMouseLeave={handleRowMouseLeave}
                  >
                    {selectedHeadings.map((obj, i) => {
                      const value = obj.value;
                      const itemValue = item[value];
                      return obj.isChecked ? (
                        <td
                          key={i}
                          className={`${
                            value === "contactName"
                              ? "productsTabledata w-50 overflow_text"
                              : "productsTabledata w-50 overflow_text"
                          }`}
                        >
                          {itemValue !== null ? itemValue : "-"}
                        </td>
                      ) : null;
                    })}
                    <td></td>
                    <td style={{ textAlign: "center" }} className="mt-2">
                      {hoveredRow === index && (
                        <img
                          src={cancel}
                          alt="cancel"
                          style={{
                            width: "20px",
                            height: "20px",
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            setShowCancelModal(true);
                            setDeleteLeadId(item.leadId);
                            setLeadName(item.productName);
                          }}
                        />
                      )}
                    </td>
                  </tr>
                ))
              ) : (
                <tr className="d-flex justify-content-center">
                  <td
                    colSpan={
                      selectedHeadings.filter((obj) => obj.isChecked).length + 1
                    }
                    style={{ textAlign: "center", padding: "20px" }}
                  >
                    <div className="text-center mt-2">
                      <div style={{ color: "#B9B9B9", fontSize: "20px" }}>
                        No records found
                      </div>
                      <div style={{ color: "#B9B9B9", fontSize: "14px" }}>
                        There is no data available to show
                      </div>
                    </div>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        {showCancelModal && (
          <Modal
            show={showCancelModal}
            className="d-flex justify-content-start align-items-center"
            aria-labelledby="example-custom-modal-styling-title"
            size="lg"
            id="commonLeadModal"
          >
            <Modal.Header
              onClick={() => {
                setShowCancelModal(false);
              }}
              style={{
                fontSize: "20px",
                fontWeight: "bold",
                background:
                  "linear-gradient(to right, rgba(64, 123, 255, 1), rgba(18, 167, 254, 1))",
                WebkitBackgroundClip: "text",
                backgroundClip: "text",
                WebkitTextFillColor: "transparent",
              }}
            >
              Are you sure you want to delete {leadName} ?
              {/* <span className="borderBottom">{leadName}</span> */}
            </Modal.Header>
            <Modal.Body className="ModalBody overflow-auto">
              <div className="d-flex flex-column gap-4">
                <div>
                  Note:{" "}
                  <span style={{ fontSize: "14px", color: "#9C9C9C" }}>
                    Any associated activities, visits, drafts will also be
                    deleted.
                  </span>
                </div>

                <div className="d-flex justify-content-end w-100 gap-4">
                  <button
                    className="cancelButton px-2"
                    onClick={() => {
                      setShowCancelModal(false);
                    }}
                  >
                    Cancel
                  </button>
                  <button
                    className="unassignButton px-2"
                    onClick={() => handleDeleteConfirmation()}
                  >
                    Delete
                  </button>
                </div>
              </div>
            </Modal.Body>
          </Modal>
        )}
        {/* {isLeadModal && (<AssignCommonModal />} */}

        <>
          {/* <AssignCommonModal
                show={isLeadModal}
                onHide={() => setIsLeadModal(false)}
                assignContactID={assignContactID}
                selectedLeadId={selectedLeadId}
                setSelectedLeadId={setSelectedLeadId}
                handleAssignModule={handleAssignModule}
              /> */}

          <Modal
            className="d-flex justify-content-center align-items-center"
            aria-labelledby="example-custom-modal-styling-title"
            size="lg"
            id="commonLeadModal"
            show={isLeadModal}
            onHide={() => setIsLeadModal(false)}
          >
            <Modal.Body className="ModalBody  overflow-auto">
              <div
                className="modalUS row justify-content-center pb-0"
                style={{
                  margin: "0",
                  gap: "10px",
                }}
              >
                <div className="header_Container d-flex justify-content-between">
                  <h4 className="commonTextColor pt-2 pb-0 mb-0">
                    Assign Leads
                  </h4>
                  {/* <div className="d-flex justify-content-start">
                  <input
                    type="text"
                    className="inputBox"
                    placeholder="Search users"
                    value={searchQuery}
                    onChange={handleSearchChange}
                  />
                  <img src={SearchIcon} alt="search" className="SearchIcon" />
                </div> */}
                  <div className="inputContainer d-flex justify-content-start">
                    <input
                      type="text"
                      placeholder="Search"
                      className="topBarModalSearch px-5 mb-1"
                      value={searchQuery}
                      onChange={handleSearchChange}
                      onKeyDown={handleKeyDown}
                    />
                    <img
                      src={SearchIcon}
                      alt="search"
                      className="searchIcon"
                      style={{ width: "20px" }}
                    />
                  </div>
                </div>

                <div className="Owner_table_container rounded-4 h-25 mt-0">
                  <div className="table-wrapper">
                    {filteredLeads.length > 0 ? (
                      <table className="userdata-table ">
                        <thead>
                          <tr>
                            <th>Lead Name</th>
                            <th>Company</th>
                            <th>Email</th>
                            <th>Phone </th>
                            <th>Lead Source</th>
                            <th>Lead Owner</th>
                          </tr>
                        </thead>
                        <tbody className="table-body tableUserData">
                          {filteredLeads.map((item, index) => (
                            <tr key={index}>
                              <td className="">
                                <input
                                  type="checkbox"
                                  checked={selectedLeadId.includes(item.leadId)}
                                  onChange={() =>
                                    handleCheckboxChange(item.leadId)
                                  }
                                />
                                <span className="ms-2">
                                  {item.leadName === "" ? "-" : item.leadName}
                                </span>
                              </td>
                              <td>
                                {item.company === "" ? "-" : item.company}
                              </td>
                              <td>{item.email === "" ? "-" : item.email}</td>
                              <td>{item.phone === "" ? "-" : item.phone}</td>
                              <td>
                                {item.leadSource === "" ? "-" : item.leadSource}
                              </td>
                              <td>
                                {item.leadOwner === "" ? "-" : item.leadOwner}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    ) : (
                      <div className="w-100 text-center d-flex flex-column justify-content-center align-items-center no_data_img pt-5">
                        <img src={NoData} alt="No data" className="" />
                        <h4 className="fs-5 my-0 py-0">No record(s) found</h4>
                      </div>
                    )}
                  </div>
                </div>
                <div className="d-flex w-100 align-items-center justify-content-end gap-2">
                  {filteredLeads.length > 0 && (
                    <>
                      <Select
                        options={pageOption}
                        styles={customSelectLocationStylesPage}
                        // value={selectPageOption}
                        value={pageOption.find(
                          (option) => option.value === perPage.toString()
                        )}
                        onChange={handlePageDropDowmChange}
                      />
                      {/* <p className="mb-0">· ‹1/10›</p> */}

                      <Pagination
                        currentPage={currentPage}
                        totalPages={totalPages}
                        onPageChange={handlePageChange}
                      />
                    </>
                  )}
                </div>

                <div className="d-flex pt-2 justify-content-between">
                  <div className="selectedUser d-flex gap-1 pt-2">
                    {/* <span>Selected User</span>
                  <img src={logoConvert} alt="img" height={25} width={25} />
                  <span>{selectedLead.name}</span> */}
                  </div>
                  <div className="d-flex gap-2">
                    <button
                      className="createContactButton modalButton"
                      onClick={() => {
                        setIsLeadModal(false);
                        setSearchQuery("");
                        setSelectedLeadId([]);
                      }}
                    >
                      Cancel
                    </button>
                    <button
                      className="createContactButtonSave modalButton"
                      onClick={() => {
                        handleAssignProductModule(selectedLeadId);
                        setSelectedLeadId([]);
                        setSearchQuery("");
                      }}
                      disabled={apiLoader || selectedLeadId.length === 0}
                    >
                      {apiLoader ? (
                        <Lottie
                          options={defaultOptions("white")}
                          height="24px"
                          width="36px"
                        />
                      ) : (
                        "Next"
                      )}
                    </button>
                  </div>
                </div>
              </div>
            </Modal.Body>
          </Modal>
        </>
      </div>
    </div>
  );
}

export default LeadList;

import React, { useLayoutEffect, useState, useRef, useEffect } from "react";
import noData from "../../../Assets/Nodata.svg";
import CanvasJSReact from "@canvasjs/react-charts";
import { camelCaseToSpace } from "../../../common/CommonComponent";
import { getChartColor } from "../dashboardData";

const CanvasJSChart = CanvasJSReact.CanvasJSChart;

const ColumnChart = ({
  component,
  deleteIcon,
  // convertSnakeCaseToReadableString,
  setModalType,
  setSelectedComponentId,
  currentView,
  data,
  showTitles,
  title,
}) => {
  const chartContainerRef = useRef(null);
  const [chartDimensions, setChartDimensions] = useState({
    width: 0,
    height: 100,
  });
  const [finalData, setFinalData] = useState([]);
  const [options, setOptions] = useState({});

  useLayoutEffect(() => {
    const getData = data === undefined ? component.componentData : data;
    if (getData.length > 0) {
      const headings = getData?.length > 0 ? Object.keys(getData[0]) : [];

      const transformedData = getData.map((item) => {
        if (
          ["campaigns_by_leads", "campaigns_by_type"].includes(
            component?.componentType
          )
        ) {
          return {
            label: item.campaignName,
            y: item.leads || item.recordCount,
          };
        } else {
          return {
            label: item[headings[currentView === "home" ? 0 : 1]] || "No Type",
            y: parseFloat(item[headings[currentView === "home" ? 1 : 0]]),
            // x: item[headings[1]],
          };
        }
      });

      setFinalData(transformedData);
    }
    const handleResize = () => {
      if (chartContainerRef.current) {
        setChartDimensions({
          width: chartContainerRef.current.offsetWidth,
          height: chartContainerRef.current.offsetHeight,
        });
      }
    };

    setTimeout(handleResize, 100);

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    const getData = data === undefined ? component.componentData : data;
    const headings = getData?.length > 0 ? Object.keys(getData[0]) : [];

    setOptions({
      animationEnabled: true,
      axisX: {
        title:
          headings.length === 2 && showTitles
            ? title?.x || camelCaseToSpace(headings[1])
            : "",
      },
      axisY: {
        title:
          headings.length === 2 && showTitles
            ? title?.y || camelCaseToSpace(headings[0])
            : "",
      },
      data: [
        {
          type: "column",
          indexLabel: "",
          yValueFormatString: `${
            headings[0] === "sumOfRevenueAmount" ? "₹" : ""
          }  #,###`,
          opacity: "1",
          // dataPoints: finalData,
          dataPoints: finalData.map((dataPoint, index) => ({
            ...dataPoint,
            color: getChartColor(index), // Assign color based on index or any logic
          })),
        },
      ],
    });
  }, [finalData]);

  return (
    <div
      className={` rounded-1 position-relative ${
        currentView === "home"
          ? "mx-2 mt-3 component_container"
          : "bg-transparent h-100"
      }`}
    >
      {currentView === "home" ? (
        <>
          <div className="component_header d-flex justify-content-between p-2">
            <span className="first_letter_uppercase fs-5">
              {component.componentName}
            </span>
          </div>

          <div className="component_content">
            {component.componentData.length > 0 ? (
              <div
                ref={chartContainerRef}
                style={{
                  width: "100%",
                  height: "29vh",
                }}
              >
                {chartDimensions.width > 0 && chartDimensions.height > 0 && (
                  <CanvasJSChart
                    options={{
                      ...options,
                      width: chartDimensions.width,
                      height: chartDimensions.height,
                    }}
                  />
                )}
              </div>
            ) : (
              <div className="text-center d-flex flex-column justify-content-center align-items-center no_data_img mt-3">
                <img src={noData} alt="No data" className="" />
                <h4 className="fs-5 my-0 py-0">No data</h4>
              </div>
            )}
          </div>
          <div className="component_footer p-2 position-absolute end-0 bottom-0 mb-1">
            <span className="">
              <span
                className="deleteIcon rounded-1 p-1 cursorPointer"
                onClick={() => {
                  // setSelectedComponentId("");
                  setModalType("delete");
                  setSelectedComponentId(component.componentId);
                }}
              >
                {deleteIcon}
              </span>
            </span>
          </div>
        </>
      ) : (
        // <div style={{ width: "100%", height: "100%" }}>
        //   <CanvasJSChart options={options} />
        // </div>
        <div
          ref={chartContainerRef}
          style={{
            width: "100%",
            // marginLeft: "5%",
            // width: currentView === "home" ? "95%" : "100%",
            height: "90%",
          }}
        >
          {chartDimensions.width > 0 && chartDimensions.height > 0 && (
            <CanvasJSChart
              options={{
                ...options,
                width: chartDimensions.width,
                height: chartDimensions.height,
              }}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default ColumnChart;

// ApiHeader.js

const getHeaders = (includeAuthorization = false) => {
  const headers = {
    //   'Content-Type': 'application/json',
    // Add other common headers here
  };

  if (includeAuthorization) {
    const accessToken = localStorage.getItem("accessToken"); // Example: Fetch token from local storage// Example: Fetch token from local storage
    // const accessToken = sessionStorage.getItem("accessToken"); // Example: Fetch token from local storage// Example: Fetch token from local storage
    if (accessToken) {
      headers["Authorization"] = `Bearer ${accessToken}`;
    } else {
      return { error: true };
    }
  }

  return headers;
};

export default getHeaders;

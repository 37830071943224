import React, { useEffect, useState } from "react";
import groupIcon from "../../Assets/De-Active/Task.svg";
import filterIcon from "../../Assets/Frame (3).svg";
import Select from "react-select";
import { URI } from "../../constants";
import axios from "axios";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import "./task.css";
import Switch from "react-switch";
import Modal from "react-bootstrap/Modal";
import searchIcon from "../../Assets/search.svg";
import userSearch from "../../Assets/user-search-01.svg";
import Button from "react-bootstrap/Button";
import LeadOwnerModal from "../../common/LeadOwnerModal";
import AccountListModal from "../../common/AccountListModal";
import down from "../../Assets/Down31.svg";
import up from "../../Assets/Up.svg";
import Pagination from "../../common/Pagination";
import tableFilterIcon from "../../Assets/filterIcon.svg";
import filterMenu from "../../Assets/filterMenu.svg";
import pin from "../../Assets/Pin_column.svg";
import unPin from "../../Assets/Unpin.svg";
import cancel from "../../Assets/cancel_circle.svg";
import arrowDown from "../../Assets/square_arrow_down.svg";
import arrowUp from "../../Assets/square_arrow_up.svg";
import filterLine from "../../Assets/filterLine.svg";
import { pageOption, massOptions } from "../../common/CommonData";
import EditableInput from "../../common/EditableInput";
import updateIcon from "../../Assets/Group 71.svg";
import cancelIcon from "../../Assets/Group 72.svg";
import groupIcon2 from "../../Assets/Group 74.svg";
import { useSelector } from "react-redux";
import moment from "moment";
import Loader from "../../common/commonRelatedList/Loader";
import noDataTask from "../../Assets/Work in progress-rafiki 1.svg";
import Clone from "../../Assets/Clone.svg";
import Delete from "../../Assets/DeleteIconNew.svg";
import history from "../../Assets/history.svg";
import ChangeOwnerModal from "../../common/commonRelatedList/ChangeOwnerModal";
import { leftArrow, tick } from "../../common/commonSVGs/commonSVGIcons.js";
import { EXPLORER_URL } from "../../constants/DataConst.js";
import {
  validateCallSubject,
  removeExtraSpaces,
  customCommonStyles,
} from "../../common/CommonComponent.js";
import AccessModal from "../../common/AccessModal";
import Lottie from "react-lottie";
import { defaultOptions } from "../../utils/defaultOptions.js";
import getHeaders from "../../common/ApiHeader.js";
import SearchIcon from "../../Assets/SearchIcon.svg";
import logoConvert from "../../Assets/logoConvert.svg";

import { handleApiError } from "../../utils/ApiUtils";
import { useNavigate } from "react-router-dom";

function Tasks({ setInnerPage, innerPage, permissionList, storeModuleId }) {
  const navigate = useNavigate();

  const leadContactOptions = [
    { value: "Lead", label: "Lead" },
    { value: "Contact", label: "Contact" },
  ];
  const statusOptions = [
    { value: "Not Started", label: "Not Started" },
    { value: "Deferred", label: "Deferred" },
    { value: "In Progress", label: "In Progress" },
    { value: "Completed", label: "Completed" },
    { value: "Waiting for input", label: "Waiting for input" },
  ];
  const priorityOptions = [
    { value: "Low", label: "Low" },
    { value: "Lowest", label: "Lowest" },
    { value: "Normal", label: "Normal" },
    { value: "High", label: "High" },
    { value: "Highest", label: "Highest" },
  ];
  const notifyOptions = [
    { value: "Email", label: "Email" },
    { value: "Pop Up", label: "Pop Up" },
    { value: "Both", label: "Both" },
  ];
  const repeatOptions = [
    { value: "Daily", label: "Daily" },
    { value: "Weekly", label: "Weekly" },
    { value: "Monthly", label: "Monthly" },
    { value: "Yearly", label: "Yearly" },
  ];

  const enableContactOptions = [
    { value: "ACCOUNTS", label: "Account" },
    { value: "DEALS", label: "Deal" },
    { value: "PRODUCTS", label: "Product" },
    { value: "QUOTES", label: "Quote" },
    { value: "SALES_ORDERS", label: "Sales Order" },
    { value: "PURCHASE_ORDERS", label: "Purchase Order" },
    { value: "INVOICES", label: "Invoice" },
    { value: "CAMPAIGNS", label: "Campaign" },
    { value: "VENDORS", label: "Vendor" },
    // { value: "CASES", label: "Case" },
  ];

  function generateTimeArray() {
    const times = [];
    const period = ["AM", "PM"];

    for (let i = 0; i < 2; i++) {
      // 0 for AM, 1 for PM
      for (let hour = 0; hour < 12; hour++) {
        for (let minute = 0; minute < 60; minute += 30) {
          const formattedHour = (hour === 0 ? 12 : hour)
            .toString()
            .padStart(2, "0");
          const formattedMinute = minute.toString().padStart(2, "0");
          const time = `${formattedHour}:${formattedMinute} ${period[i]}`;
          times.push({ label: time, value: time });
        }
      }
    }

    return times;
  }

  const timeArray = generateTimeArray();

  const [clickedDropdownObj, setClickedDropdownObj] = useState({
    label: "",
    value: "",
  });

  const userList = JSON.parse(sessionStorage.getItem("userList"));
  const [selectedItem, setSelectedItem] = useState(null);
  // useEffect(() => {
  //   if (userList && userList.length > 0) {
  //     setSelectedItem(userList[0]);
  //   }
  // }, []);
  const [leadDetails, setLeadDetails] = useState([]);
  const [contactDetails, setContactDetails] = useState([]);
  const [selectedLeadItem, setSelectedLeadItem] = useState(leadDetails);
  useEffect(() => {
    if (leadDetails.length > 0) {
      setSelectedLeadItem(leadDetails[0]);
    }
  }, [leadDetails]);
  const [existingTask, setExistingTask] = useState(false);
  const [selectedContactItem, setSelectedContactItem] =
    useState(contactDetails);
  useEffect(() => {
    if (contactDetails.length > 0) {
      setSelectedContactItem(contactDetails[0]);
    }
  }, [contactDetails]);

  const [listVisible, setListVisible] = useState(false);
  const [leadListVisible, setLeadListVisible] = useState(false);
  const [contactListVisible, setContactListVisible] = useState(false);
  const [accountListVisible, setAccountListVisible] = useState(false);
  const [leadAndAccountListVisible, setLeadAndAccountListVisible] =
    useState(false);
  const [isLeadOwnerModal, setIsLeadOwnerModal] = useState(false);
  const [isLeadListModal, setIsLeadListModal] = useState(false);
  const [isContactListModal, setIsContactListModal] = useState(false);
  const [isAccountListModal, setIsAccountListModal] = useState(false);
  const [enableContactOptionVisible, setEnableContactOptionVisible] =
    useState(false);
  const [selectLeadOrContact, setSelectLeadOrContact] = useState({
    value: "Lead",
    label: "Lead",
  });
  const [selectEnableContactOptions, setSelectEnableContactOptions] = useState({
    value: "ACCOUNTS",
    label: "Account",
  });
  const [endOn, setEndOn] = useState("Never");
  const [searchColumn, setSearchColumn] = useState("");
  const [modalType, setShowModalType] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [totalItems, setTotalItems] = useState(0);
  const [selectedTask, setSelectedTask] = useState("");
  const [showType, setShowType] = useState("");
  const [getTaskHistoryData, setTaskHistoryData] = useState([]);
  const [showOptionsTask, setShowOptionsTask] = useState(false);
  const [closeTaskModal, setCloseTaskModal] = useState(false);
  const [showSelectTimeMsg, setShowSelectTimeMsg] = useState("");
  const [showSelectDateMsg, setShowSelectDateMsg] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const totalPages = Math.ceil(Number(totalItems) / perPage);
  const [deleteModal, setDeleteModal] = useState(false);
  const [existingClone, setExistingClone] = useState(false);
  const [updateButtonLabel, setUpdateButtonLabel] = useState("Save");
  const [selectedCheckboxes, setSelectedCheckboxes] = useState([]);
  const [massUpdateValue, setMassUpdateValue] = useState("");
  const [selectedObj, setSelectedObj] = useState({
    name: "Due Date",
    isChecked: false,
    value: "dueDate",
  });
  const [taskPageView, setTaskPageView] = useState("overview");
  const [mandatoryMsg, setMandatoryMsg] = useState("");
  const [mandatoryMsgDueDate, setMandatoryMsgDueDate] = useState("");
  const [moduleWiseData, setModuleWiseData] = useState([]);
  const [selectedModuleItem, setSelectedModuleItem] = useState(moduleWiseData);
  const [showAccessModal, setShowAccessModal] = useState(false);
  const [currentPageContact, setCurrentPageContact] = useState(1);
  const [perPageContact, setPerPageContact] = useState(10);
  const [totalItemsContact, setTotalItemsContact] = useState(0);
  const [modalContactDetails, setModalContactDetails] = useState([]);
  const [searchContact, setSearchContact] = useState("");

  const [currentPageLead, setCurrentPageLead] = useState(1);
  const [perPageLead, setPerPageLead] = useState(10);
  const [totalItemsLead, setTotalItemsLead] = useState(0);
  const [modalLeadDetails, setModalLeadDetails] = useState([]);
  const [searchLead, setSearchLead] = useState("");
  const [errorMessageTask, setErrorMessageTask] = useState("");

  const totalPagesContact = Math.ceil(
    Number(totalItemsContact) / perPageContact
  );

  const totalPagesLead = Math.ceil(Number(totalItemsLead) / perPageLead);

  const filteredContactDetails = modalContactDetails.filter((item) =>
    item.contactName?.toLowerCase().includes(searchContact?.toLowerCase())
  );

  const filteredLeadDetails = modalLeadDetails.filter((item) =>
    item.leadName?.toLowerCase().includes(searchLead?.toLowerCase())
  );

  const hadlePageChangeContact = (page) => {
    setCurrentPageContact(page);
  };

  const hadlePageChangeLead = (page) => {
    setCurrentPageLead(page);
  };

  const handlePageDropDownChangeContact = (selectedOption) => {
    setCurrentPageContact(1);
    setPerPageContact(parseInt(selectedOption.value));
  };

  const handlePageDropDownChangeLead = (selectedOption) => {
    setCurrentPageLead(1);
    setPerPageLead(parseInt(selectedOption.value));
  };

  const handleSearchChangeContact = (e) => {
    const { value } = e.target;
    if (value.trim() !== "" || value === "") {
      setSearchContact(value);
    }
  };

  const handleSearchChangeLead = (e) => {
    const { value } = e.target;
    if (value.trim() !== "" || value === "") {
      setSearchLead(value);
    }
  };

  const hasPermission = (action) => {
    const permission = permissionList.tasks.find((p) => p.action === action);
    return permission ? permission.status === "TRUE" : false;
  };

  useEffect(() => {
    if (moduleWiseData.length > 0) {
      setSelectedModuleItem(moduleWiseData[0]);
    }
  }, [moduleWiseData]);

  function compareDateTimeToCurrent(dateString, timeString) {
    var currentDate = new Date();
    var dateParts = dateString.match(/(\d{2})-(\d{2})-(\d{4})/);
    var day = parseInt(dateParts[1]);
    var month = parseInt(dateParts[2]) - 1;
    var year = parseInt(dateParts[3]);
    var timeParts = timeString.match(/(\d{2}):(\d{2}) (AM|PM)/);

    if (!timeParts) {
      return "Invalid time format";
    }

    var hours = parseInt(timeParts[1]);
    var minutes = parseInt(timeParts[2]);
    var period = timeParts[3];

    if (period === "PM" && hours !== 12) {
      hours += 12;
    } else if (period === "AM" && hours === 12) {
      hours = 0;
    }

    var inputDateTime = new Date(year, month, day, hours, minutes);

    if (inputDateTime > currentDate) {
      return true;
    }
    // else if (inputDateTime < currentDate) {
    //     return "Input date and time are less than current date and time";
    // }
    else {
      return false;
    }
  }

  const handleCreateTasks = () => {
    if (hasPermission("CREATE")) {
      setInnerPage("create");
      setExistingTask(false);
      setUpdateButtonLabel("Save");
      setSelectedCheckboxes([]);
    } else {
      setShowAccessModal(true);
    }
  };

  const handleEditTask = () => {
    if (hasPermission("EDIT")) {
      setTaskUpdateInfo({ ...taskOverviewDetails });
      setInnerPage("create");
      setExistingTask(true);
      setUpdateButtonLabel("Update");
      setChecked(taskOverviewDetails.reminder);
    } else {
      setShowAccessModal(true);
    }
  };

  const handleCreateCloneTask = () => {
    if (hasPermission("CREATE")) {
      setShowOptionsTask(false);
      setInnerPage("clone");
      setExistingClone(true);
      setExistingTask(false);
      setTaskUpdateInfo({ ...taskOverviewDetails });
      setUpdateButtonLabel("Save");
    } else {
      setShowAccessModal(true);
    }
  };

  const handleDeleteTask = () => {
    if (hasPermission("DELETE")) {
      setDeleteModal(true);
    } else {
      setShowAccessModal(true);
    }
  };

  const handleSelectCheckboxChange = (event) => {
    const { value } = event.target;

    const selectedCheckbox = { moduleId: value };

    setSelectedCheckboxes((prevSelectedCheckboxes) => {
      const isAlreadySelected = prevSelectedCheckboxes.some(
        (checkbox) => checkbox.moduleId === value
      );

      if (isAlreadySelected) {
        return prevSelectedCheckboxes.filter(
          (checkbox) => checkbox.moduleId !== value
        );
      } else {
        return [...prevSelectedCheckboxes, selectedCheckbox];
      }
    });
  };

  const handleOwnerInputClick = (e) => {
    e.stopPropagation();
    setListVisible(!listVisible);
    setLeadAndAccountListVisible(false);
    setEnableContactOptionVisible(false);
    setLeadListVisible(false);
    setContactListVisible(false);
    setAccountListVisible(false);
  };
  const handleLeadClick = (e) => {
    e.stopPropagation();
    setLeadListVisible(!leadListVisible);
    setListVisible(false);
    setLeadAndAccountListVisible(false);
    setEnableContactOptionVisible(false);
    setContactListVisible(false);
    setAccountListVisible(false);
  };
  const handleContactClick = (e) => {
    e.stopPropagation();
    if (selectLeadOrContact.value === "Contact") {
      setContactListVisible(!contactListVisible);
      setListVisible(false);
      setLeadAndAccountListVisible(false);
      setEnableContactOptionVisible(false);
      setLeadListVisible(false);
      setAccountListVisible(false);
    } else {
      setContactListVisible(false);
    }
  };
  const handleAccountClick = (e) => {
    e.stopPropagation();
    setAccountListVisible(!accountListVisible);
    setListVisible(false);
    setLeadAndAccountListVisible(false);
    setEnableContactOptionVisible(false);
    setLeadListVisible(false);
    setContactListVisible(false);
  };

  const handleEnableContactOption = (e) => {
    e.stopPropagation();
    setEnableContactOptionVisible(!enableContactOptionVisible);
    setListVisible(false);
    setLeadAndAccountListVisible(false);
    setLeadListVisible(false);
    setContactListVisible(false);
    setAccountListVisible(false);
  };

  const handlePageDropDowmChange = (selectedOption) => {
    setCurrentPage(1);
    setPerPage(parseInt(selectedOption.value));
  };

  const getAllAssignModules = async (main, sub, id) => {
    const requestBody = {
      loginUserId: loginUserId,
      mainModule: main,
      subModule: sub,
      moduleId: id,
    };
    const headersResult = getHeaders(true);

    // Check if headers result indicates an error
    if (headersResult.error) {
      navigate("../"); // Redirect to login if token is missing
      return;
    }
    await axios
      .post(URI.moduleWiseAssign, requestBody, {
        headers: headersResult,
      })
      .then((response) => {
        if (response.data.status === 200) {
          setModuleWiseData(response.data.data.assignModulewiseDetails);
          NotificationManager.success("", response.data.message, 5000);
          setIsContactListModal(false)
        }
      })
      .catch(async (error) => {
        handleApiError(error, navigate);
      });
  };

  const handleSelectLeadOrContact = (option) => {
    setSelectLeadOrContact(option);
    if (option.value === "Contact") {
      getAllAssignModules(
        "CONTACTS",
        ["ACCOUNTS"],
        selectedContactItem.contactId
      );
    }
    setLeadAndAccountListVisible(false);
    setAccountListVisible(false);
  };

  const handleSelectEnableOptions = (option) => {
    setSelectEnableContactOptions(option);
    setEnableContactOptionVisible(false);
    getAllAssignModules(
      "CONTACTS",
      [option.value],
      selectedContactItem.contactId
    );
  };
  const handleLeadAndContactClick = (e) => {
    e.stopPropagation();
    setLeadAndAccountListVisible(!leadAndAccountListVisible);
    setListVisible(false);
    setEnableContactOptionVisible(false);
    setLeadListVisible(false);
    setContactListVisible(false);
    setAccountListVisible(false);
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleClickSelectedTask = (index) => {
    setSelectedTask(index);
  };

  const fieldDropdownStyles = {
    menu: (base) => ({
      ...base,
      width: "100%",
    }),
    option: (provided) => ({
      ...provided,
      color: "#000",
      width: "100%",
    }),
    control: (baseStyles) => ({
      ...baseStyles,
      border: 0,
      // backgroundColor: 'rgba(33, 119, 254, 0.1)',
      height: "20px",
      borderRadius: "10px",
      width: "100%",
      boxShadow: "none",
    }),
    input: (base) => ({
      ...base,
      color: "#FFFFFF",
    }),
    singleValue: (defaultStyles) => ({ ...defaultStyles, color: "#000" }),

    menuList: (provided) => ({
      ...provided,
      paddingTop: 0,
      paddingBottom: 0,
      "&::-webkit-scrollbar": {
        width: 5,
      },

      "&::-webkit-scrollbar-thumb": {
        background: "#316AFF",
        borderRadius: "10px",
      },
    }),
  };

  const [checked, setChecked] = useState(false);
  const [repeatChecked, setRepeatChecked] = useState(false);
  const [show, setShow] = useState(false);
  const [showRepeat, setShowRepeat] = useState(false);
  const [allTaskDetails, setAllTaskDetails] = useState([]);
  const [taskOverviewDetails, setTaskOverviewDetails] = useState({});
  const [repeatMsg, setRepeatMsg] = useState("");

  const fieldDropdowns = [
    {
      name: "Task Owner",
      isShowed: true,
      type: "select",
      value: "",
    },
    {
      name: "Due Date",
      isShowed: true,
      type: "date",
    },
    {
      name: "Related To",
      isShowed: true,
      type: "readOnly",
    },
    {
      name: "Related Module",
      isShowed: false,
      type: "readOnly",
    },
    {
      name: "Relation To",
      isShowed: false,
      type: "readOnly",
    },
    {
      name: "Relation Module",
      isShowed: false,
      type: "readOnly",
    },
    {
      name: "Priority",
      isShowed: true,
      type: "dropdown",
    },
    {
      name: "Closed Time",
      isShowed: false,
      type: "readOnly",
    },

    {
      name: "Description",
      isShowed: false,
      type: "input",
      value: "",
    },
    {
      name: "Status",
      isShowed: true,
      type: "dropdown",
      value: "",
    },

    {
      name: "Created By",
      isShowed: false,
      type: "readOnly",
    },

    {
      name: "Modified By",
      isShowed: false,
      type: "readOnly",
      value: "",
    },
  ];
  const tasksColumn = [
    {
      name: "Subject",
      isChecked: true,
      value: "subject",
    },
    {
      name: "Due Date",
      isChecked: true,
      value: "dueDate",
    },
    {
      name: "Status",
      isChecked: true,
      value: "status",
    },
    {
      name: "Priority",
      isChecked: true,
      value: "priority",
    },
    {
      name: "Related To",
      isChecked: true,
      value: "relatedTo",
    },
    {
      name: "Related Module",
      isChecked: false,
      value: "relatedModuleName",
    },
    {
      name: "Relation To",
      isChecked: false,
      value: "relationTo",
    },
    {
      name: "Relation Module",
      isChecked: false,
      value: "relationModuleName",
    },
    {
      name: "Task Owner",
      isChecked: true,
      value: "taskOwner",
    },
    {
      name: "Created By",
      isChecked: false,
      value: "createdBy",
    },
    {
      name: "Tag",
      isChecked: false,
      value: "Tag",
    },
    {
      name: "Modified By",
      isChecked: false,
      value: "modifiedBy",
    },
    {
      name: "Created Time",
      isChecked: false,
      value: "createdDate",
    },
    {
      name: "Modified Time",
      isChecked: false,
      value: "modifiedTime",
    },
    {
      name: "Closed Time",
      isChecked: false,
      value: "closedTime",
    },
    {
      name: "Description",
      isChecked: false,
      value: "description",
    },
    {
      name: "Hash",
      isChecked: true,
      value: "transHash",
    },
  ];

  const reduxStore = useSelector((state) => state.reduxState);
  const { loginUserId } = reduxStore;
  const options = reduxStore.filterOptions.task;
  const [selectedOption, setSelectedOption] = useState(options[0]);

  const [selectedHeadings, setSelectedHeadings] = useState(tasksColumn);
  const [savingHeadings, setSavingHeadings] = useState(tasksColumn);
  const [fieldLabels, setFieldLabels] = useState(fieldDropdowns);
  const [showSearchFields, setShowSearchFields] = useState(fieldDropdowns);
  const [searchField, setSearchField] = useState("");
  const [selectedField, setSelectedField] = useState("");
  const [selectedOwnerItem, setSelectedOwnerItem] = useState(userList);
  const [isChangeOwnerList, setIsChangeOwnerList] = useState(false);
  const [isOwnerSelect, setIsOwnerSelect] = useState(false);
  const [emailNotification, setEmailNotification] = useState(true);

  const [showHeadings, setShowHeadings] = useState(false);
  const [pinnedColumn, setPinnedColumn] = useState("Subject");
  const [filteredTaskDetails, setFilteredTaskDetails] = useState([]);
  const [showSelectedFilter, setShowSelectedFilter] = useState("");
  const [taskId, setTaskId] = useState("");
  const [subjectName, setSubjectName] = useState("");
  const [editingFields, setEditingFields] = useState({
    subject: false,
    contactName: false,
    taskOwner: false,
    dueDate: false,
    priority: false,
    status: false,
    relatedTo: false,
    description: false,
    modifiedTime: false,
    closedTime: false,
    createdBy: false,
    createdTime: false,
    modifiedBy: false,

    subjectInfo: false,
    dueDateInfo: false,
    statusInfo: false,
    priorityInfo: false,
    descriptionInfo: false,
  });

  const [filterType, setFilterType] = useState("");

  const handleClose = () => setShow(false);
  const handleRepeatClose = () => setShowRepeat(false);

  const handleReminderChange = (checked) => {
    if (checked) {
      setTaskInfo((prevLeadInfo) => ({
        ...prevLeadInfo,
        remindDate: new Date()
          .toISOString()
          .split("T")[0]
          .split("-")
          .reverse()
          .join("-"),
      }));
      setShow(true);
      setTimeout(() => {
        setChecked(checked);
      }, 1000);
      taskInfo.notify = notifyOptions[0];
    } else {
      setShow(false);
      setChecked(false);
      taskInfo.notify = notifyOptions[0];
      // taskInfo.remindTime = "";
    }
  };
  const handleSelectedLeadChange = (value) => {
    setSelectedItem(value);
    setSelectedOwnerItem(value);
  };
  const handleSelectedAccountListChange = (value) => {
    setSelectedModuleItem(value); // Update the selected lead value
  };
  const handleRepeatChange = (checked) => {
    if (checked) {
      setShowRepeat(true);
      setRepeatChecked(checked);
      taskInfo.repeatType = repeatOptions[0];
    } else {
      setShowRepeat(false);
      setRepeatChecked(false);
      taskInfo.repeatType = repeatOptions[0];
      taskInfo.repeatEndDate = "";
      taskInfo.repeatEnd = "";
    }
  };

  const handlePinColumn = (name) => {
    setPinnedColumn(name);
    const updatedColumns = [...selectedHeadings];
    const clickedColumnIndex = updatedColumns.findIndex(
      (column) => column.name === name
    );

    if (clickedColumnIndex !== -1) {
      const clickedColumn = updatedColumns.splice(clickedColumnIndex, 1)[0];
      updatedColumns.unshift(clickedColumn);
      setSelectedHeadings(updatedColumns);
    }
  };

  const handleColumnCheckboxChange = (name, isChecked) => {
    setSavingHeadings((prevHeadings) =>
      prevHeadings.map((heading) =>
        heading.name === name ? { ...heading, isChecked } : heading
      )
    );
  };

  const handleChangeIndDropDown = (selectedOption, field) => {
    setUpdatedFields((prevFields) => ({
      ...prevFields,
      [field]: selectedOption,
    }));
  };

  const [updatedFields, setUpdatedFields] = useState({
    subject: taskOverviewDetails.subject,
    dueDate: taskOverviewDetails.dueDate,
    status: taskOverviewDetails.status,
    priority: taskOverviewDetails.priority,
    description: taskOverviewDetails.description,
    taskOwner: taskOverviewDetails.taskOwner,
    modifiedBy: taskOverviewDetails.modifiedBy,
    remindStatus: taskOverviewDetails.remindStatus,
    relatedTo: taskOverviewDetails.relatedTo,
    closedTime: taskOverviewDetails.closedTime,
    modifiedTime: taskOverviewDetails.modifiedTime,
    contactName: taskOverviewDetails.contactName,

    subjectInfo: taskOverviewDetails.subjectInfo,
    dueDateInfo: taskOverviewDetails.dueDateInfo,
    statusInfo: taskOverviewDetails.statusInfo,
    priorityInfo: taskOverviewDetails.priorityInfo,
    descriptionInfo: taskOverviewDetails.descriptionInfo,
  });

  const [taskInfo, setTaskInfo] = useState({
    subject: "",
    dueDate: "",
    status: statusOptions[0],
    priority: priorityOptions[0],
    description: "",
    taskOwner: "",
    modifiedBy: "",
    remindStatus: "",
    relatedTo: "",
    closedTime: "",
    modifiedTime: "",
    contactName: "",
    remindTime: "",
    remindDate: "",
    repeatEndTimes: "",
    repeatEndDate: "",
    repeatType: repeatOptions[0],
    notify: notifyOptions[0],
  });

  const [taskUpdateInfo, setTaskUpdateInfo] = useState({
    subject: "",
    dueDate: "",
    status: "",
    priority: "",
    description: "",
    taskOwner: "",
    modifiedBy: "",
    remindStatus: "",
    relatedTo: "",
    closedTime: "",
    modifiedTime: "",
    contactName: "",
    remindTime: "",
    remindDate: "",
    repeatEndTimes: "",
    repeatEndDate: "",
    repeatType: repeatOptions[0],
    notify: notifyOptions[0],
  });

  // for save and new && save lottie loader
  const [saveSubmit, setSaveSubmit] = useState(false);
  const [saveAndNewSubmit, setSaveAndNewSubmit] = useState(false);
  const [apiLoader, setApiLoader] = useState(false);

  const getTodayDate = () => {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, "0");
    const day = String(today.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  const todayDate = getTodayDate();

  const getMinDate = (repeatType) => {
    const today = new Date();
    let minDate;

    switch (repeatType) {
      case "Weekly":
        minDate = new Date(today.setDate(today.getDate() + 7));
        break;
      case "Monthly":
        minDate = new Date(today.setDate(today.getDate() + 30));
        break;
      case "Yearly":
        minDate = new Date(today.setDate(today.getDate() + 365));
        break;
      default:
        minDate = new Date(today.setDate(today.getDate()));
    }

    if (minDate) {
      const year = minDate.getFullYear();
      const month = String(minDate.getMonth() + 1).padStart(2, "0");
      const day = String(minDate.getDate()).padStart(2, "0");
      return `${year}-${month}-${day}`;
    }
    return "";
  };

  const [minDate, setMinDate] = useState(getMinDate(taskInfo.repeatType.value));
  const validMinDate = moment("2000-01-01").format("YYYY-MM-DD");
  const validMaxDate = moment("2050-12-31").format("YYYY-MM-DD");

  useEffect(() => {
    taskInfo.repeatEndDate = "";
    setMinDate(getMinDate(taskInfo.repeatType.value));
  }, [taskInfo.repeatType.value]);

  const resetData = () => {
    const formData = {
      subject: "",
      dueDate: "",
      status: statusOptions[0],
      priority: priorityOptions[0],
      description: "",
      modSearchId: "",
      modSearchName: "",
      remindStatus: "",
      remindTime: "",
      notify: notifyOptions[0],
      repeatStatus: "",
      repeatType: repeatOptions[0],
      repeatEnd: "",
      repeatEndDate: "",
      remindDate: "",
    };
    setTaskInfo(formData);
    setTaskUpdateInfo(formData);
  };

  const handleChange = (selectedOption) => {
    // setSelectedOption(selectedOption);
    getAllTask(selectedOption);
  };

  const customSelectLocationStyles = {
    menu: (base) => ({
      ...base,
      width: "100%",
    }),
    option: (provided, state) => ({
      ...provided,
      color: "#000",
      backgroundColor: state.isSelected ? "#407BFF" : "",
      width: "100%",
      cursor: "pointer",
      "&:hover": {
        backgroundColor: "#f5f5f5",
      },
    }),
    control: (baseStyles) => ({
      ...baseStyles,
      border: "2px solid #316AFF",
      backgroundColor: "#fff",
      height: "40px",
      borderRadius: "10px",
      width: "100%",
      boxShadow: "none",
      cursor: "pointer",
    }),
    input: (base) => ({
      ...base,
      color: "#000",
    }),
    singleValue: (defaultStyles) => ({ ...defaultStyles, color: "#000" }),

    menuList: (provided) => ({
      ...provided,
      paddingTop: 0,
      paddingBottom: 0,
      "&::-webkit-scrollbar": {
        width: 5,
      },

      "&::-webkit-scrollbar-thumb": {
        background: "#316AFF",
        borderRadius: "10px",
      },
    }),
    dropdownIndicator: (base) => ({
      ...base,
      color: "#316AFF",
    }),
  };

  const createLeadStyles = {
    menu: (base) => ({
      ...base,
      width: "60%",
    }),
    option: (provided) => ({
      ...provided,
      // backgroundColor: "#EAEAEA",
      color: "#000",
      // backgroundColor: 'rgba(33, 119, 254, 0.1)',
      width: "100%",
    }),
    control: (baseStyles) => ({
      ...baseStyles,
      border: "1px solid #b9b9b9b0",
      backgroundColor: "transparent",
      height: "40px",
      borderRadius: "4px",
      width: "60%",
      boxShadow: "none",
    }),
    input: (base) => ({
      ...base,
      color: "#000",
    }),
    singleValue: (defaultStyles) => ({ ...defaultStyles, color: "#000" }),

    menuList: (provided) => ({
      ...provided,
      paddingTop: 0,
      paddingBottom: 0,
      "&::-webkit-scrollbar": {
        width: 5,
      },

      "&::-webkit-scrollbar-thumb": {
        background: "#316AFF",
        borderRadius: "10px",
      },
    }),
  };

  const handleInputFieldChange = (e) => {
    const { name, value } = e.target;
    let formattedValue = value;

    if (name === "dueDate" || name === "remindDate") {
      formattedValue = convertToDisplayFormat(value);
    }

    if (name === "repeatEndTimes") {
      if (
        /^\d*$/.test(value) &&
        (value === "" || (parseInt(value) >= 1 && parseInt(value) <= 30))
      ) {
        formattedValue = value;
      } else {
        return;
      }
    }

    if (existingTask || existingClone) {
      setTaskUpdateInfo((prevLeadInfo) => ({
        ...prevLeadInfo,
        [name]: formattedValue,
      }));
    } else {
      setTaskInfo((prevLeadInfo) => ({
        ...prevLeadInfo,
        [name]: formattedValue,
      }));
    }
  };

  const handleDropDownChanges = (selectedOption, field) => {
    if (existingTask || existingClone) {
      setTaskUpdateInfo((prevLeadInfo) => ({
        ...prevLeadInfo,
        [field]: selectedOption,
      }));
    } else {
      setTaskInfo((prevDealsInfo) => ({
        ...prevDealsInfo,
        [field]: selectedOption,
      }));
    }
  };

  const handleUserItemClick = (item) => {
    setSelectedItem(item);
    setListVisible(false);
  };
  const handleLeadItemClick = (item) => {
    setSelectedLeadItem(item);
    setLeadListVisible(false);
  };
  const handleContactItemClick = (item) => {
    setSelectedContactItem(item);
    setContactListVisible(false);
    getAllAssignModules(
      "CONTACTS",
      [selectEnableContactOptions?.value],
      item.contactId
    );
  };
  const handleAccountItemClick = (item) => {
    setSelectedModuleItem(item);
    setAccountListVisible(false);
  };

  const handleChangeSelectDropDown = (selectedOption, field) => {
    if (existingTask || existingClone) {
      setTaskUpdateInfo((prevLeadInfo) => ({
        ...prevLeadInfo,
        [field]: selectedOption,
      }));
    } else {
      setTaskInfo((prevLeadInfo) => ({
        ...prevLeadInfo,
        [field]: selectedOption,
      }));
    }
  };

  const deleteAccountDetails = async () => {
    const requestBody = {
      loggedInUserId: loginUserId,
      taskId: taskId,
    };

    const headersResult = getHeaders(true);

    // Check if headers result indicates an error
    if (headersResult.error) {
      navigate("../"); // Redirect to login if token is missing
      return;
    }
    await axios
      .post(URI.deleteTask, requestBody, {
        headers: headersResult,
      })

      .then((response) => {
        if (response.data.status === 200) {
          setInnerPage("list");
          setDeleteModal(false);
          getAllTask(selectedOption);
          NotificationManager.success("", response.data.message, 5000);
        } else {
          NotificationManager.error("", response.data.message, 5000);
        }
      })
      .catch(async (error) => {
        handleApiError(error, navigate);
      });
  };

  const getAllTask = async (selectedOption) => {
    setIsLoading(true);
    setSelectedOption(selectedOption);
    const requestBody = {
      loggedInUserId: loginUserId,
      customFilter: selectedOption.value,
      pageNo: currentPage,
      pageSize: perPage,
    };

    const headersResult = getHeaders(true);

    // Check if headers result indicates an error
    if (headersResult.error) {
      navigate("../"); // Redirect to login if token is missing
      return;
    }
    await axios
      .post(URI.getAllTask, requestBody, {
        headers: headersResult,
      })
      .then((response) => {
        if (response.data.status === 200) {
          setAllTaskDetails(response.data.data.allTasksDtls);
          setFilteredTaskDetails(response.data.data.allTasksDtls);
          setTotalItems(response.data.data.rowCount);
          setIsLoading(false);
        } else {
          setIsLoading(false);
          NotificationManager.error("", response.data.message, 5000);
        }
      })
      .catch((err) => {
        handleApiError(err, navigate);
      });
  };

  const closeTaskDetails = async () => {
    const requestBody = {
      loggedInUserId: loginUserId,
      taskId: taskId,
    };
    const headersResult = getHeaders(true);

    // Check if headers result indicates an error
    if (headersResult.error) {
      navigate("../"); // Redirect to login if token is missing
      return;
    }
    await axios
      .post(URI.closeTask, requestBody, {
        headers: headersResult,
      })
      .then((response) => {
        if (response.data.status === 200) {
          NotificationManager.success("", response.data.message, 5000);
          setCloseTaskModal(false);
          taskOverviewDetail(taskId);
          getAllTaskHistory(taskId);
        } else {
          NotificationManager.error("", response.data.message, 5000);
        }
      })
      .catch((err) => {
        handleApiError(err, navigate);
      });
  };

  const getAllLeadsDetails = async () => {
    const requestBody = {
      loggedInUserId: loginUserId,
      pageNo: currentPageLead,
      pageSize: perPageLead,
    };

    const headersResult = getHeaders(true);

    // Check if headers result indicates an error
    if (headersResult.error) {
      navigate("../"); // Redirect to login if token is missing
      return;
    }
    await axios
      .post(URI.getAllLeads, requestBody, {
        headers: headersResult,
      })

      .then((response) => {
        if (response.data.status === 200) {
          setLeadDetails(response.data.data.allLeadDetails);
        }

        setModalLeadDetails(response.data.data.allLeadDetails);
        setTotalItemsLead(response.data.data.rowCount);
      })
      .catch(async (error) => {
        handleApiError(error, navigate);
      });
  };

  const getAllContact = async () => {
    const requestBody = {
      loggedInUserId: loginUserId,
      pageNo: currentPageContact,
      pageSize: perPageContact,
    };

    const headersResult = getHeaders(true);

    // Check if headers result indicates an error
    if (headersResult.error) {
      navigate("../"); // Redirect to login if token is missing
      return;
    }
    await axios
      .post(URI.getAllContact, requestBody, {
        headers: headersResult,
      })
      .then((response) => {
        if (response.data.status === 200) {
          setContactDetails(response.data.data.allContactDetails);
          setModalContactDetails(response.data.data.allContactDetails);
          setTotalItemsContact(response.data.data.rowCount);
        } else {
          throw new Error("Failed to fetch data", response.data.message);
        }
      })
      .catch((err) => {
        handleApiError(err, navigate);
      });
  };

  useEffect(() => {
    if (storeModuleId) {
      taskOverviewDetail(storeModuleId);
      setTaskId(storeModuleId);
    }
  }, []);

  const getAllTaskHistory = async (taskId) => {
    const headersResult = getHeaders(true);

    // Check if headers result indicates an error
    if (headersResult.error) {
      navigate("../"); // Redirect to login if token is missing
      return;
    }
    await axios
      .get(URI.taskTimeline + "/" + loginUserId + "/" + taskId, {
        headers: headersResult,
      })
      .then((response) => {
        if (response.data.status === 200) {
          setTaskHistoryData(response.data.data.getTaskTimelineDetails);
        }
      })
      .catch(async (error) => {
        handleApiError(error, navigate);
      });
  };

  const convertDateFormat = (dateString) => {
    if (!dateString) return "";
    const [day, month, year] = dateString.split("-");
    return `${year}-${month}-${day}`;
  };

  const convertToDisplayFormat = (dateString) => {
    if (!dateString) return "";
    const [year, month, day] = dateString.split("-");
    return `${day}-${month}-${year}`;
  };

  const taskOverviewDetail = async (taskId) => {
    let taskOverviewDetails = {};
    const headersResult = getHeaders(true);

    // Check if headers result indicates an error
    if (headersResult.error) {
      navigate("../"); // Redirect to login if token is missing
      return;
    }
    await axios
      .get(URI.taskOverview + "/" + loginUserId + "/" + taskId, {
        headers: headersResult,
      })
      .then((response) => {
        if (response.data.status === 200) {
          taskOverviewDetails = response.data.data.taskInfoDetails;
          taskOverviewDetails.taskOwner =
            response.data.data.taskInfoDetails.taskOwner;
          taskOverviewDetails.subject =
            response.data.data.taskInfoDetails.subject;
          taskOverviewDetails.dueDate =
            response.data.data.taskInfoDetails.dueDate;
          taskOverviewDetails.description =
            response.data.data.taskInfoDetails.description;
          taskOverviewDetails.priority =
            response.data.data.taskInfoDetails.priority;
          taskOverviewDetails.closingDate =
            response.data.data.taskInfoDetails.closingDate;
          taskOverviewDetails.reminder =
            response.data.data.taskInfoDetails.reminder;
          // taskOverviewDetails.remind =
          //   response.data.data.taskInfoDetails.remind;
          taskOverviewDetails.createdDate =
            response.data.data.taskInfoDetails.createdDate;
          taskOverviewDetails.createdBy =
            response.data.data.taskInfoDetails.createdBy;
          taskOverviewDetails.contact =
            response.data.data.taskInfoDetails.contact;
          taskOverviewDetails.repeat =
            response.data.data.taskInfoDetails.repeat;
          taskOverviewDetails.modifiedDate =
            response.data.data.taskInfoDetails.modifiedDate;
          taskOverviewDetails.modifiedBy =
            response.data.data.taskInfoDetails.modifiedBy;
          taskOverviewDetails.status =
            response.data.data.taskInfoDetails.status;
          taskOverviewDetails.subject =
            response.data.data.taskInfoDetails.subject;
          taskOverviewDetails.remindDate =
            response.data.data.taskInfoDetails.remindDate;
          taskOverviewDetails.priority = {
            label: response.data.data.taskInfoDetails.priority,
            value: response.data.data.taskInfoDetails.priority,
          };
          // taskOverviewDetails.subject = {
          //   label: response.data.data.taskInfoDetails.subject,
          //   value: response.data.data.taskInfoDetails.subject,
          // };
          taskOverviewDetails.status = {
            label: response.data.data.taskInfoDetails.status,
            value: response.data.data.taskInfoDetails.status,
          };
          taskOverviewDetails.notify = {
            label: response.data.data.taskInfoDetails.notify,
            value: response.data.data.taskInfoDetails.notify,
          };

          taskOverviewDetails.subjectInfo =
            response.data.data.taskInfoDetails.subject;
          taskOverviewDetails.dueDateInfo =
            response.data.data.taskInfoDetails.dueDate;
          taskOverviewDetails.statusInfo =
            response.data.data.taskInfoDetails.status;
          taskOverviewDetails.priorityInfo =
            response.data.data.taskInfoDetails.priority;
          taskOverviewDetails.descriptionInfo =
            response.data.data.taskInfoDetails.description;

          setSelectedItem({
            name: response.data.data.taskInfoDetails.taskOwner,
            userId: response.data.data.taskInfoDetails.taskOwnerId,
          });
          if (response.data.data.taskInfoDetails.relatedModuleName === "LEAD") {
            setSelectedLeadItem({
              leadName: response.data.data.taskInfoDetails.relatedToName,
              leadId: response.data.data.taskInfoDetails.relatedTo,
            });
            setSelectLeadOrContact({
              value: "Lead",
              label: "Lead",
            });
          } else if (
            response.data.data.taskInfoDetails.relatedModuleName === "CONTACT"
          ) {
            if (response.data.data.taskInfoDetails.relationModuleName != null) {
              setSelectEnableContactOptions({
                value: response.data.data.taskInfoDetails.relationModuleName,
                label:
                  response.data.data.taskInfoDetails.relationModuleName
                    .charAt(0)
                    .toUpperCase() +
                  response.data.data.taskInfoDetails.relationModuleName
                    .slice(1)
                    .toLowerCase(),
              });
            }
            setSelectedContactItem({
              contactName: response.data.data.taskInfoDetails.relatedToName,
              contactId: response.data.data.taskInfoDetails.relatedTo,
            });
            setSelectedModuleItem({
              moduleName: response.data.data.taskInfoDetails.relationToName,
              moduleId: response.data.data.taskInfoDetails.relationTo,
            });
            setSelectLeadOrContact({
              value: "Contact",
              label: "Contact",
            });
          }
          if (response.data.data.taskInfoDetails.reminder === true) {
            setChecked(true);
          }
          setTaskOverviewDetails(taskOverviewDetails);
          setInnerPage("overview");

          const updatedFields = {
            mobile: response.data.data.taskInfoDetails.mobile,
            taskOwner: response.data.data.taskInfoDetails.taskOwner,
            dueDate: response.data.data.taskInfoDetails.dueDate,
            contact: response.data.data.taskInfoDetails.contact,
            reminder: response.data.data.taskInfoDetails.reminder,
            // remind: response.data.data.taskInfoDetails.remind,
            repeat: response.data.data.taskInfoDetails.repeat,
            modifiedDate: response.data.data.taskInfoDetails.modifiedDate,
            createdDate: response.data.data.taskInfoDetails.createdDate,
            closingDate: response.data.data.taskInfoDetails.closingDate,
            description: response.data.data.taskInfoDetails.description,
            priority: {
              label: response.data.data.taskInfoDetails.priority,
              value: response.data.data.taskInfoDetails.priority,
            },
            status: {
              label: response.data.data.taskInfoDetails.status,
              value: response.data.data.taskInfoDetails.status,
            },
            subject: response.data.data.taskInfoDetails.subject,

            subjectInfo: response.data.data.taskInfoDetails.subject,
            dueDateInfo: response.data.data.taskInfoDetails.dueDate,
            statusInfo: response.data.data.taskInfoDetails.status,
            priorityInfo: response.data.data.taskInfoDetails.priority,
            descriptionInfo: response.data.data.taskInfoDetails.description,
          };

          setUpdatedFields(updatedFields);
        }
      })
      .catch(async (error) => {
        handleApiError(error, navigate);
      });
  };

  const handleChangeOwnerInputClick = (e) => {
    e.stopPropagation();
    setIsChangeOwnerList(!isChangeOwnerList);
  };

  const handleChangeOwnerItemClick = (item) => {
    setSelectedOwnerItem(item);
    setIsChangeOwnerList(false);
    setIsOwnerSelect(true);
    // setIsSelectedOwner(false);
  };

  useEffect(() => {
    getAllLeadsDetails();
    getAllContact();
    // getAllAccountDetails();
    setInnerPage(innerPage || "list");
  }, []);

  useEffect(() => {
    getAllContact();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPageContact, perPageContact]);

  useEffect(() => {
    getAllLeadsDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPageLead, perPageLead]);

  useEffect(() => {
    const foundItem = userList.find((item) => item.userId === loginUserId);
    if (foundItem) {
      setSelectedItem(foundItem);
    }
  }, [loginUserId]);

  useEffect(() => {
    getAllTask(selectedOption);
  }, [perPage, currentPage]);

  const handleCreateTask = async (e) => {
    let isValidForm = true;

    const setErrorMessage = (setter, message, timeout = 5000) => {
      setter(message);
      setTimeout(() => {
        setter("");
      }, timeout);
      isValidForm = false;
    };

    const currentTaskInfo =
      existingTask || existingClone ? taskUpdateInfo : taskInfo;

    if (currentTaskInfo.subject === "") {
      setErrorMessage(setMandatoryMsg, "Subject cannot be empty.", 5000);
    }
    if (
      currentTaskInfo.subject &&
      !validateCallSubject(currentTaskInfo.subject)
    ) {
      setErrorMessage(
        setMandatoryMsg,
        "Please enter a valid  Subject name.",
        5000
      );
    }

    if (currentTaskInfo.dueDate === "") {
      setErrorMessage(
        setMandatoryMsgDueDate,
        "Due date cannot be empty.",
        5000
      );
    }

    let requestBody = {};

    if (!isValidForm) return;

    if (existingClone) {
      requestBody = {
        taskId: taskId,
        taskOwnerId: selectedItem.userId,
        loggedInUserId: loginUserId,
        subject: removeExtraSpaces(taskUpdateInfo.subject),
        dueDate: taskUpdateInfo.dueDate,
        status: taskUpdateInfo.status.value,
        priority: taskUpdateInfo.priority.value,
        description: removeExtraSpaces(taskUpdateInfo.description),
        recurringRepeatStatus: repeatChecked ? true : false,
        reminderStatus: checked ? true : false,
      };
      if (selectLeadOrContact.value === "Lead") {
        requestBody.leadId = selectedLeadItem.leadId;
      } else if (selectLeadOrContact.value === "Contact") {
        requestBody.contactId = selectedContactItem.contactId;
        requestBody.modSearchId = selectedModuleItem.moduleId;
        requestBody.modSearchName = selectEnableContactOptions.value;
      }
      if (repeatChecked) {
        requestBody.repeatType = taskInfo.repeatType.value;
        if (endOn === "Never") {
          requestBody.repeatEnd = "Never";
        }
        if (endOn === "After") {
          requestBody.repeatEnd =
            "After " + taskUpdateInfo.repeatEndTimes + " Times";
        }
        if (endOn === "On") {
          requestBody.repeatEnd = taskUpdateInfo.repeatEndDate
            ? "On " +
              moment(new Date(taskUpdateInfo.repeatEndDate)).format(
                "DD-MM-YYYY"
              )
            : "";
        }
      }
      if (checked) {
        requestBody.remindDate = taskUpdateInfo.remindDate;
        requestBody.remindTime = taskUpdateInfo.remindTime;
        requestBody.notify = taskUpdateInfo.notify.value;
      }
    } else {
      requestBody = {
        taskOwnerId: selectedItem.userId,
        loggedInUserId: loginUserId,
        subject: removeExtraSpaces(taskInfo.subject),
        dueDate: taskInfo.dueDate,
        status: taskInfo.status.value,
        priority: taskInfo.priority.value,
        description: removeExtraSpaces(taskInfo.description),
        recurringRepeatStatus: repeatChecked ? "true" : "false",
        reminderStatus: checked ? "true" : "false",
      };
      if (selectLeadOrContact.value === "Lead") {
        requestBody.leadId = selectedLeadItem.leadId;
      } else if (selectLeadOrContact.value === "Contact") {
        requestBody.contactId = selectedContactItem.contactId;
        requestBody.modSearchId = selectedModuleItem.moduleId;
        requestBody.modSearchName = selectEnableContactOptions.value;
      }
      if (repeatChecked) {
        requestBody.repeatType = taskInfo.repeatType.value;
        if (endOn === "Never") {
          requestBody.repeatEnd = "Never";
        }
        if (endOn === "After") {
          requestBody.repeatEnd = "After " + taskInfo.repeatEndTimes + " Times";
        }
        if (endOn === "On") {
          requestBody.repeatEnd = taskInfo.repeatEndDate
            ? "On " +
              moment(new Date(taskInfo.repeatEndDate)).format("DD-MM-YYYY")
            : "";
        }
      }
      if (checked) {
        requestBody.remindTime = taskInfo.remindTime;
        requestBody.notify = taskInfo.notify.value;
        requestBody.remindDate = taskInfo.remindDate;
      }
    }

    e === "SAVE AND NEW" ? setSaveAndNewSubmit(true) : setSaveSubmit(true);

    const headersResult = getHeaders(true);

    // Check if headers result indicates an error
    if (headersResult.error) {
      navigate("../"); // Redirect to login if token is missing
      return;
    }

    await axios
      .post(URI.createTask, requestBody, {
        headers: headersResult,
      })
      .then((response) => {
        if (response.data.status === 200) {
          getAllTask(selectedOption);
          resetData();
          setSelectedItem(userList[0]);
          setSelectLeadOrContact({ value: "Lead", label: "Lead" });
          setSelectEnableContactOptions({
            value: "ACCOUNTS",
            label: "Account",
          });
          setSelectedLeadItem(leadDetails[0]);
          setSelectedContactItem(contactDetails[0]);
          setRepeatChecked(false);
          setChecked(false);
          NotificationManager.success("", response.data.message, 5000);
          if (e === "SAVE" || e === "ADD") {
            setInnerPage("list");
            resetData();
            setSelectedItem(userList[0]);
            setSelectLeadOrContact({ value: "Lead", label: "Lead" });
            setSelectEnableContactOptions({
              value: "ACCOUNTS",
              label: "Account",
            });
            setSelectedContactItem(contactDetails[0]);
            setSelectedLeadItem(leadDetails[0]);
            setRepeatChecked(false);
            setChecked(false);
          }
        } else {
          NotificationManager.error("", response.data.message, 5000);
        }
      })
      .catch((err) => {
        handleApiError(err, navigate);
      })
      .finally(() => {
        setSaveSubmit(false);
        setSaveAndNewSubmit(false);
      });
  };

  const handleUpdateTask = async () => {
    let isValidForm = true;

    const setErrorMessage = (setter, message, timeout = 5000) => {
      setter(message);
      setTimeout(() => {
        setter("");
      }, timeout);
      isValidForm = false;
    };

    const currentTaskInfo =
      existingTask || existingClone ? taskUpdateInfo : taskInfo;

    if (currentTaskInfo.subject === "") {
      setErrorMessage(setMandatoryMsg, "Subject cannot be empty.", 5000);
    }
    if (
      currentTaskInfo.subject &&
      !validateCallSubject(currentTaskInfo.subject)
    ) {
      setErrorMessage(
        setMandatoryMsg,
        "Please enter a valid  Subject name.",
        5000
      );
    }

    if (currentTaskInfo.dueDate === "") {
      setErrorMessage(
        setMandatoryMsgDueDate,
        "Due date cannot be empty.",
        5000
      );
    }
    // let requestBody = {};

    if (!isValidForm) return;

    const requestBody = {
      taskId: taskId,
      taskOwnerId: selectedItem.userId,
      loggedInUserId: loginUserId,
      subject: removeExtraSpaces(taskUpdateInfo.subject),
      dueDate: taskUpdateInfo.dueDate,
      status: taskUpdateInfo.status.value,
      priority: taskUpdateInfo.priority.value,
      description: removeExtraSpaces(taskUpdateInfo.description),
      recurringRepeatStatus: repeatChecked ? "true" : "false",
      reminderStatus: checked ? "true" : "false",
      emailNotification: emailNotification,
    };
    if (selectLeadOrContact.value === "Lead") {
      requestBody.leadId = selectedLeadItem.leadId;
    } else if (selectLeadOrContact.value === "Contact") {
      requestBody.contactId = selectedContactItem.contactId;
      requestBody.modSearchId = selectedModuleItem.moduleId;
      requestBody.modSearchName = selectEnableContactOptions.value;
    }
    if (repeatChecked) {
      requestBody.repeatType = taskInfo.repeatType.value;
      if (endOn === "Never") {
        requestBody.repeatEnd = "Never";
      }
      if (endOn === "After") {
        requestBody.repeatEnd =
          "After " + taskUpdateInfo.repeatEndTimes + " Times";
      }
      if (endOn === "On") {
        requestBody.repeatEnd = taskUpdateInfo.repeatEndDate
          ? "On " +
            moment(new Date(taskUpdateInfo.repeatEndDate)).format("DD-MM-YYYY")
          : "";
      }
    }
    if (checked) {
      requestBody.remindDate = taskUpdateInfo.remindDate;
      requestBody.remindTime = taskUpdateInfo.remindTime;
      requestBody.notify = taskUpdateInfo.notify.value;
    }

    setSaveSubmit(true);
    const headersResult = getHeaders(true);

    // Check if headers result indicates an error
    if (headersResult.error) {
      navigate("../"); // Redirect to login if token is missing
      return;
    }
    await axios
      .post(URI.updateAllTask, requestBody, {
        headers: headersResult,
      })
      .then((response) => {
        if (response.data.status === 200) {
          getAllTask(selectedOption);
          setInnerPage("list");
          resetData();
          setSelectedItem(userList[0]);
          setSelectLeadOrContact({ value: "Lead", label: "Lead" });
          setSelectEnableContactOptions({
            value: "ACCOUNTS",
            label: "Account",
          });
          setSelectedLeadItem(leadDetails[0]);
          setSelectedContactItem(contactDetails[0]);
          setRepeatChecked(false);
          setChecked(false);
          NotificationManager.success("", response.data.message, 5000);
        }
      })
      .catch((err) => {
        handleApiError(err, navigate);
      })
      .finally(() => {
        setSaveSubmit(false);
      });
  };

  const sortedColumnsData = (type, headingName) => {
    const sortedData = [...allTaskDetails];

    if (type === "asc") {
      sortedData.sort((a, b) => {
        if (
          typeof a[headingName] === "string" &&
          typeof b[headingName] === "string"
        ) {
          return a[headingName].localeCompare(b[headingName]);
        }
        return a[headingName] - b[headingName];
      });
    } else if (type === "desc") {
      sortedData.sort((a, b) => {
        if (
          typeof a[headingName] === "string" &&
          typeof b[headingName] === "string"
        ) {
          return b[headingName].localeCompare(a[headingName]);
        }
        return b[headingName] - a[headingName];
      });
    }
    setFilteredTaskDetails(sortedData);
  };

  const deleteUpdateTransferMass = async () => {
    const { value } = selectedObj;
    const requiredFields = ["dueDate"];
    let url = URI.massDelete;
    const requestBody = {
      loggedInUserId: loginUserId,
      moduleName: "TASKS",
      massAction: modalType === "Mass Delete" ? "DELETE" : "EDIT",
      moduleIdList: selectedCheckboxes,
    };

    if (modalType === "Mass Update") {
      if (value === "dueDate") {
        if (massUpdateValue.length === 0) {
          setErrorMessageTask("Value cannot be empty");
          setTimeout(() => {
            setErrorMessageTask("");
          }, 3000);
          return;
        }
        requestBody[value] = convertToDisplayFormat(massUpdateValue);
      } else {
        if (requiredFields.includes(value) && massUpdateValue.length === 0) {
          setErrorMessageTask("Value cannot be empty");
          setTimeout(() => {
            setErrorMessageTask("");
          }, 3000);
          return;
        }
        requestBody[value] = massUpdateValue;
      }
      url = URI.massUpdate;
    } else if (modalType === "Mass Transfer") {
      requestBody.ownerId = selectedItem.userId;
      url = URI.massTransfer;
    }

    const headersResult = getHeaders(true);

    // Check if headers result indicates an error
    if (headersResult.error) {
      navigate("../"); // Redirect to login if token is missing
      return;
    }

    await axios
      .post(url, requestBody, {
        headers: headersResult,
      })
      .then((response) => {
        if (response.data.status === 200) {
          NotificationManager.success("", response.data.message, 5000);
          getAllTask(selectedOption);
          setSelectedCheckboxes([]);
          setInnerPage("list");
          setShowModalType("");
          setShowSearchFields(fieldDropdowns);
          setMassUpdateValue("");
          setClickedDropdownObj({
            label: "",
            value: "",
          });
        } else {
          NotificationManager.error("", response.data.message, 5000);
        }
      })
      .catch(function (error) {
        handleApiError(error, navigate);
      });
  };

  const handleIndividualInputChange = (field, value) => {
    let newValue = value;
    if (field === "dueDate") {
      newValue = value;
      // newValue = convertToDisplayFormat(value);
    }

    setUpdatedFields((prevFields) => ({
      ...prevFields,
      [field]: newValue,
    }));
  };

  const handleEditClick = (field) => {
    if (Object.values(editingFields).some((value) => value)) {
      return;
    }

    setEditingFields((prevFields) => ({
      ...prevFields,
      [field]: true,
    }));
  };
  const handleCancelClick = (field) => {
    setEditingFields((prevFields) => ({
      ...prevFields,
      [field]: false,
    }));
    setUpdatedFields((prevFields) => ({
      ...prevFields,
      [field]: taskOverviewDetails[field],
    }));
  };

  const handleFieldClick = (field) => {
    if (field.type === "dropdown") {
      const fieldValue = field.name
        .replace(/\s+/g, "")
        .replace(/^(.)/, (match) => match.toLowerCase());

      setClickedDropdownObj(taskOverviewDetails[fieldValue]);
      setUpdatedFields((prevFields) => ({
        ...prevFields,
        [fieldValue]: taskOverviewDetails[fieldValue],
      }));
    } else if (field.type === "checkbox") {
      // setClickedEmailOtp(taskOverviewDetails["emailOptOut"]);
      setUpdatedFields((prevFields) => ({
        ...prevFields,
        emailOptOut: taskOverviewDetails["emailOptOut"],
      }));
    }

    const updatedColumns = [...fieldLabels];
    const existedObj = updatedColumns.find(
      (field) => field.name === selectedField
    );
    const selectedObj = updatedColumns.find((item) => item.name === field.name);

    const existedObjIndex = updatedColumns.findIndex(
      (field) => field.name === existedObj.name
    );
    const selectedObjIndex = updatedColumns.findIndex(
      (field) => field.name === selectedObj.name
    );

    updatedColumns[existedObjIndex].isShowed = false;
    updatedColumns[selectedObjIndex].isShowed = true;

    const removedObj = updatedColumns.splice(existedObjIndex, 1)[0];
    updatedColumns.splice(existedObjIndex, 0, selectedObj);

    updatedColumns.push(removedObj);
    const uniqueData = updatedColumns.filter(
      (item, index, self) =>
        index === self.findIndex((t) => t.name === item.name)
    );
    setFieldLabels(uniqueData);
    setShowSearchFields(uniqueData);
  };

  const handleUpdateClick = async (field) => {
    var value = "";
    const isObj =
      typeof taskOverviewDetails[field] === "object" &&
      taskOverviewDetails[field] !== null &&
      !Array.isArray(taskOverviewDetails[field]);

    if (
      field === "status" ||
      field === "priority" ||
      field === "statusInfo" ||
      field === "priorityInfo"
    ) {
      value = updatedFields[field].value;
    } else {
      value = updatedFields[field];
    }
    const fieldMappings = {
      priorityInfo: "priority",
      statusInfo: "status",
      relatedToInfo: "relatedTo",
      dueDateInfo: "dueDate",
      subjectInfo: "subject",
      taskOwner: "taskOwner",
    };
    // if (fieldMappings.hasOwnProperty(field)) {
    //   field = fieldMappings[field];
    // } not supported by eslint
    if (Object.hasOwn(fieldMappings, field)) {
      field = fieldMappings[field];
    }

    setApiLoader(true);

    try {
      const requestBody = {
        taskOwnerId: isOwnerSelect
          ? selectedOwnerItem?.userId
          : selectedItem?.userId,
        taskId: taskId,
        [field]: value,
        loggedInUserId: loginUserId,
      };

      const compareValue = isObj
        ? taskOverviewDetails[field].value
        : taskOverviewDetails[field];

      if (value !== compareValue || field === "emailNotification") {
        const headersResult = getHeaders(true);

        // Check if headers result indicates an error
        if (headersResult.error) {
          navigate("../"); // Redirect to login if token is missing
          return;
        }
        const response = await axios.post(
          URI.individualUpdateTask,
          requestBody,
          {
            headers: headersResult,
          }
        );

        if (response.status === 200) {
          setTaskOverviewDetails((prevDetails) => ({
            ...prevDetails,
            [field]: taskInfo[field],
          }));
          taskOverviewDetail(taskId);
          setShowModalType("");
          getAllTask(selectedOption);
          NotificationManager.success("", response.data.message, 5000);
        } else {
          NotificationManager.error("Unexpected status code:", response.status);
        }

        const resetFields = Object.fromEntries(
          Object.keys(editingFields).map((key) => [key, false])
        );
        setEditingFields({
          ...resetFields,
        });
        setShowType("");
      }
    } catch (error) {
      handleApiError(error, navigate);
    } finally {
      setApiLoader(false);
    }
  };

  const individualTaskDetailPage = () => {
    return (
      <div className="indiv_main_container ">
        <div
          className="w-100 rounded"
          style={{ borderBottom: "3px solid #fff" }}
        >
          <header className="commonContainer_bg rounded w-100">
            <div className="d-flex justify-content-between px-3 pt-2">
              <span
                className="cursorPointer"
                onClick={() => {
                  setInnerPage("list");
                  const resetFields = Object.fromEntries(
                    Object.keys(editingFields).map((key) => [key, false])
                  );
                  setEditingFields({
                    ...resetFields,
                  });
                  setShowType("");
                }}
              >
                {leftArrow}
              </span>
            </div>
            <div className="d-flex justify-content-between">
              <div className="profile_logo_container d-flex pt-2">
                <div className="d-flex flex-column  justify-content-center ps-2">
                  <span className="first_letter_uppercase ps-2">
                    {updatedFields.subject || subjectName}
                  </span>
                  {/* <span>Add Tags</span> */}
                </div>
              </div>
              <div className="d-flex  justify-content-end align-items-end  px-2 pb-2">
                <div className="d-flex gap-2">
                  <button
                    className="btn btn-secondary bg-white text-dark border border-primary d-flex align-items-center"
                    onClick={handleEditTask}
                  >
                    Edit
                  </button>
                  <button
                    className="btn btn-secondary bg-white text-dark border border-primary d-flex align-items-center"
                    onClick={(e) => {
                      e.stopPropagation();
                      setShowOptionsTask(!showOptionsTask);
                    }}
                  >
                    ...
                  </button>
                  {showOptionsTask && (
                    <div
                      className="rounded  showOptions p-2"
                      style={{ top: "10rem" }}
                    >
                      <div
                        className="d-flex gap-3 align-items-center mt-2"
                        onClick={handleCreateCloneTask}
                      >
                        <img src={Clone} alt="clone" />
                        <div
                          style={{ color: "#515151", fontWeight: "300" }}
                          className="mt-1 cursorPointer ms-2"
                        >
                          Clone
                        </div>
                      </div>
                      <div
                        className="d-flex gap-3 align-items-center mt-2"
                        onClick={handleDeleteTask}
                      >
                        <img src={Delete} alt="close" />
                        <div
                          style={{ color: "#515151", fontWeight: "300" }}
                          className="mt-2 cursorPointer ms-2"
                        >
                          Delete
                        </div>
                      </div>
                      <div style={{ borderBottom: "1px solid #515151s" }}></div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </header>
        </div>
        <div className="detailList_main_container mt-2 commonContainer_bg">
          <div className="detailList_main_container d-flex p-2  justify-content-end">
            <div className="d-flex justify-content-between px-2  w-100">
              <div className="d-flex gap-4 px-2 bg-white px-1  rounded-5 py-1">
                <span
                  className={`cursorPointer } ${
                    taskPageView === "overview"
                      ? "overviewTimelineTab "
                      : "  d-flex justify-content-center align-items-center"
                  }`}
                  onClick={() => setTaskPageView("overview")}
                >
                  Overview
                </span>
                <span
                  className={`cursorPointer } ${
                    taskPageView === "timeline"
                      ? "overviewTimelineTab"
                      : " d-flex justify-content-center align-items-center"
                  }`}
                  onClick={() => {
                    setTaskPageView("timeline");
                  }}
                >
                  Timeline
                </span>
              </div>
            </div>
          </div>
        </div>

        <div className="d-flex gap-2">
          <div
            className="w-25 rounded bg-white mt-2 p-2"
            style={{ height: "auto" }}
          >
            <h5 className="pb-2" style={{ borderBottom: "1px solid #E2E5EB" }}>
              All Tasks
            </h5>
            <div>
              {allTaskDetails.map((item, index) => {
                return (
                  <div
                    className={`ms-2 mt-4 me-2 p-2 ${
                      selectedTask === index ? "selected-item" : "cursorPointer"
                    }`}
                    key={index}
                    onClick={() => {
                      handleClickSelectedTask(index);
                      getAllTaskHistory(item.taskId);
                      taskOverviewDetail(item.taskId);
                      setSubjectName(item.subject);
                    }}
                  >
                    <div
                      style={{
                        color: "#313949",
                        fontWeight: "500",
                        fontSize: "18px",
                      }}
                    >
                      {item.subject}
                    </div>
                    <div
                      className="d-flex gap-3 mt-2"
                      style={{
                        color: "#515151",
                        fontSize: "14px",
                        fontWeight: "300",
                      }}
                    >
                      <div>{item.taskOwner}</div>
                      <div>{item.status}</div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
          <div className="w-75" style={{ height: "100vh" }}>
            <div className="d-flex gap-2">
              {taskPageView === "overview" ? (
                <div style={{ width: "100%" }}>
                  <div className="bg-white rounded mt-2 p-3">
                    {taskOverviewDetails.status?.value?.toLowerCase() ===
                    "completed" ? (
                      <div className="text-primary d-flex justify-content-end mb-3">
                        Completed
                      </div>
                    ) : (
                      <div className="d-flex justify-content-end mb-3">
                        <button
                          className="createContactButtonSave"
                          onClick={() => {
                            setCloseTaskModal(true);
                          }}
                        >
                          Close Task
                        </button>
                      </div>
                    )}

                    <div className=" rounded-2 commonContainer_bg d-flex flex-column justify-content-between p-2">
                      <div className="w-100 d-flex flex-column">
                        {fieldLabels.map((field, i) => {
                          const fieldValue = field.name
                            .replace(/\s+/g, "")
                            .replace(/^(.)/, (match) => match.toLowerCase());
                          return field.isShowed ? (
                            <div className="d-flex" key={i}>
                              <div
                                className=" position-relative"
                                style={{ width: "23%" }}
                              >
                                <div
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    setSelectedField(
                                      selectedField === field.name
                                        ? ""
                                        : field.name
                                    );
                                    setSearchField("");
                                  }}
                                  className={`cursorPointer d-flex justify-content-between w-100 my-1 p-1 ${
                                    selectedField === field.name
                                      ? "outline_border rounded-1"
                                      : ""
                                  }`}
                                >
                                  <span>{field.name}</span>
                                  <img
                                    src={
                                      selectedField === field.name ? up : down
                                    }
                                    alt="img"
                                    height={20}
                                    width={20}
                                    className="pt-1"
                                  />
                                </div>
                                {selectedField === field.name && (
                                  <div
                                    className="position-absolute field_container w-100"
                                    onClick={(e) => {
                                      e.stopPropagation();
                                    }}
                                  >
                                    <div className="">
                                      <label
                                        htmlFor="selectField"
                                        className="position-relative w-100"
                                      >
                                        <input
                                          id="selectField"
                                          type="text"
                                          className="rounded-2 p-1 w-100 pe-4"
                                          placeholder="Search"
                                          value={searchField}
                                          onChange={(e) => {
                                            if (
                                              e.target.value.charAt(0) !== " "
                                            ) {
                                              const value =
                                                e.target.value.replace(
                                                  /[^a-zA-Z ]+|(\s)\s+/g,
                                                  "$1"
                                                );
                                              setSearchField(value);
                                            }
                                          }}
                                        />
                                        <img
                                          className="position-absolute mt-1 me-2 end-0 pt-1 cursorPointer"
                                          onClick={() => {
                                            setSearchField("");
                                            // setShowSearchFields(fieldDropdowns);
                                          }}
                                          src={searchIcon}
                                          alt="search"
                                          width={20}
                                        />
                                      </label>

                                      <div className="w-100 rounded-2">
                                        <div className="border border-1 field_container_dropdown rounded-1">
                                          {showSearchFields
                                            .filter((field) =>
                                              field.name
                                                .toLowerCase()
                                                .includes(
                                                  searchField
                                                    .trim()
                                                    .toLowerCase()
                                                )
                                            )
                                            .sort((a, b) =>
                                              a.name.localeCompare(b.name)
                                            )
                                            .map(
                                              (field, i) =>
                                                (selectedField === field.name ||
                                                  !field.isShowed) && (
                                                  <div
                                                    key={field.name}
                                                    className="d-flex gap-2 p-2 cursorPointer rounded-2 align-items-center"
                                                    onClick={() => {
                                                      selectedField ===
                                                      field.name
                                                        ? setSelectedField("")
                                                        : handleFieldClick(
                                                            field,
                                                            i
                                                          );
                                                    }}
                                                  >
                                                    <span className="right_tick">
                                                      {selectedField ===
                                                      field.name
                                                        ? tick
                                                        : ""}
                                                    </span>
                                                    <span className="ps-2">
                                                      {field.name}
                                                    </span>
                                                  </div>
                                                )
                                            )}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                )}
                              </div>

                              <div
                                className={`mt-1 ms-5 position-relative`}
                                style={{ width: "65%" }}
                              >
                                {field.type === "input" ? (
                                  <EditableInput
                                    className="ps-3"
                                    inputType={fieldValue}
                                    handleIndividualInputChange={
                                      handleIndividualInputChange
                                    }
                                    editingFields={editingFields}
                                    handleEditClick={handleEditClick}
                                    handleCancelClick={handleCancelClick}
                                    handleUpdateClick={handleUpdateClick}
                                    updatedFields={updatedFields}
                                    accountOverviewDetails={taskOverviewDetails}
                                    showType={showType}
                                    setShowType={setShowType}
                                  />
                                ) : field.type === "dropdown" ? (
                                  <div
                                    className="position-relative"
                                    onClick={() => {
                                      handleEditClick(fieldValue);
                                    }}
                                  >
                                    {editingFields[fieldValue] &&
                                    showType === fieldValue ? (
                                      <Select
                                        value={updatedFields[fieldValue]}
                                        // value={taskOverviewDetails[fieldValue]}
                                        menuPlacement="auto"
                                        onChange={(selectedOption) =>
                                          handleChangeIndDropDown(
                                            selectedOption,
                                            fieldValue
                                          )
                                        }
                                        options={
                                          field.name === "Status"
                                            ? statusOptions
                                            : field.name === "Priority"
                                            ? priorityOptions
                                            : priorityOptions
                                        }
                                        styles={fieldDropdownStyles}
                                        isSearchable={false}
                                      />
                                    ) : (
                                      <input
                                        type="text"
                                        className={`cursorPointer indvEditInput
                      ${
                        showType === fieldValue
                          ? "selectedBorder "
                          : "unselectBorder bg-transparent"
                      }`}
                                        value={
                                          taskOverviewDetails[fieldValue]?.value
                                        }
                                        name={fieldValue}
                                        onClick={(e) => {
                                          if (showType === "") {
                                            updatedFields[fieldValue] =
                                              taskOverviewDetails[fieldValue];
                                            setShowType(e.target.name);
                                            setUpdatedFields(updatedFields);
                                          }
                                          handleEditClick(fieldValue);
                                        }}
                                        // readOnly={showType !== "emailId"}
                                        readOnly={true}
                                      />
                                    )}
                                    {editingFields[fieldValue] &&
                                      showType === fieldValue && (
                                        <div className="d-flex gap-2">
                                          <span
                                            onClick={() => {
                                              setShowType("");
                                              handleUpdateClick(fieldValue);
                                            }}
                                            className="ps-2 cursorPointer"
                                          >
                                            <img
                                              src={updateIcon}
                                              alt="img"
                                              height={20}
                                              width={20}
                                            />
                                          </span>
                                          <span
                                            onClick={() => {
                                              setShowType("");
                                              // handleUpdateClick(inputType);
                                              handleCancelClick(fieldValue);
                                            }}
                                            className="cursorPointer"
                                          >
                                            <img
                                              src={cancelIcon}
                                              alt="img"
                                              height={20}
                                              width={20}
                                            />
                                          </span>
                                        </div>
                                      )}
                                  </div>
                                ) : field.type === "readOnly" ? (
                                  <div>
                                    <span className="d-flex flex-column">
                                      <span>
                                        {
                                          taskOverviewDetails[
                                            field.name === "Created By"
                                              ? "createdBy"
                                              : field.name === "Related To"
                                              ? "relatedTo"
                                              : field.name === "Related Module"
                                              ? "relatedModuleName"
                                              : field.name === "Relation To"
                                              ? "relationTo"
                                              : field.name === "Relation Module"
                                              ? "relationModuleName"
                                              : "modifiedBy"
                                          ]
                                        }
                                      </span>
                                      <span className="small opacity-75">
                                        {field.name === "Created By" ||
                                        field.name === "Modified By"
                                          ? taskOverviewDetails[
                                              field.name === "Created By"
                                                ? "createdDate"
                                                : "modifiedDate"
                                            ]
                                          : ""}
                                      </span>
                                      <span className="small opacity-75">
                                        {
                                          taskOverviewDetails[
                                            field.name === "Closed Time"
                                              ? "closedTime"
                                              : ""
                                          ]
                                        }
                                      </span>
                                      <span className="small opacity-75">
                                        {
                                          taskOverviewDetails[
                                            field.name === "Contact Name"
                                              ? "contact"
                                              : "-"
                                          ]
                                        }
                                      </span>
                                    </span>
                                  </div>
                                ) : field.type === "date" ? (
                                  <EditableInput
                                    fieldType={"date"}
                                    inputType="dueDate"
                                    handleIndividualInputChange={
                                      handleIndividualInputChange
                                    }
                                    editingFields={editingFields}
                                    handleEditClick={handleEditClick}
                                    handleCancelClick={handleCancelClick}
                                    handleUpdateClick={handleUpdateClick}
                                    updatedFields={updatedFields}
                                    accountOverviewDetails={taskOverviewDetails}
                                    showType={showType}
                                    setShowType={setShowType}
                                  />
                                ) : (
                                  <div>
                                    <div className="position-relative">
                                      <div
                                        className="d-flex  "
                                        // onClick={handleOwnerInputClick}
                                        onClick={() => {
                                          setShowModalType("changeOwner");
                                        }}
                                      >
                                        <input
                                          type="text"
                                          className="w-100  cursorPointer indvEditInput border-0 indivOwnerInput"
                                          value={selectedItem?.name}
                                          readOnly
                                        />
                                      </div>
                                    </div>
                                  </div>
                                )}
                              </div>
                            </div>
                          ) : null;
                        })}
                      </div>
                      {closeTaskModal && (
                        <Modal
                          show={closeTaskModal}
                          size="lg"
                          aria-labelledby="contained-modal-title-vcenter"
                        >
                          <Modal.Header
                            style={{
                              fontSize: "20px",
                              fontWeight: "bold",
                              background:
                                "linear-gradient(to right, rgba(64, 123, 255, 1), rgba(18, 167, 254, 1))",
                              WebkitBackgroundClip: "text",
                              backgroundClip: "text",
                              WebkitTextFillColor: "transparent",
                            }}
                          >
                            Are you sure you want to mark this task as
                            completed&nbsp;
                            <span>{taskOverviewDetails?.subject}</span>
                          </Modal.Header>
                          <Modal.Body className="ModalBody overflow-auto">
                            <div className="d-flex flex-column gap-4">
                              <div className="d-flex justify-content-end w-100 gap-4">
                                <button
                                  className="cancelButton px-2"
                                  onClick={() => setCloseTaskModal(false)}
                                >
                                  Cancel
                                </button>
                                <button
                                  className="unassignButton px-2"
                                  onClick={closeTaskDetails}
                                >
                                  Mark as Completed
                                </button>
                              </div>
                            </div>
                          </Modal.Body>
                        </Modal>
                      )}
                      <h5 className="w-100 text-primary px-1 py-2">
                        Task Information
                      </h5>
                      <div className="d-flex flex-column gap-3">
                        <div className="d-flex align-items-center w-100 ps-1">
                          <div className="opacity-75" style={{ width: "23%" }}>
                            Task Owner
                          </div>
                          <div className="ms-5" style={{ width: "65%" }}>
                            <div
                              className="d-flex position-relative"
                              onClick={(e) => {
                                if (
                                  Object.values(editingFields).some(
                                    (value) => value
                                  )
                                ) {
                                  return;
                                }
                                setExistingTask(true);
                                handleOwnerInputClick(e);
                                setShowModalType("changeOwner");
                              }}
                            >
                              <input
                                type="text"
                                className="w-100 cursorPointer indvEditInput border-0 indivOwnerInput"
                                value={selectedItem?.name}
                                readOnly
                              />
                            </div>
                          </div>
                        </div>

                        <div className="d-flex align-items-center w-100 ps-1">
                          <div className="opacity-75" style={{ width: "23%" }}>
                            Subject
                          </div>
                          <div className="ms-5" style={{ width: "65%" }}>
                            <EditableInput
                              inputType="subjectInfo"
                              handleIndividualInputChange={
                                handleIndividualInputChange
                              }
                              editingFields={editingFields}
                              handleEditClick={handleEditClick}
                              handleCancelClick={handleCancelClick}
                              handleUpdateClick={handleUpdateClick}
                              updatedFields={updatedFields}
                              accountOverviewDetails={taskOverviewDetails}
                              showType={showType}
                              setShowType={setShowType}
                            />
                          </div>
                        </div>

                        <div className="d-flex align-items-center w-100 ps-1">
                          <div className="opacity-75" style={{ width: "23%" }}>
                            Due Date
                          </div>
                          <div className="ms-5" style={{ width: "65%" }}>
                            <EditableInput
                              fieldType={"date"}
                              inputType="dueDateInfo"
                              handleIndividualInputChange={
                                handleIndividualInputChange
                              }
                              editingFields={editingFields}
                              handleEditClick={handleEditClick}
                              handleCancelClick={handleCancelClick}
                              handleUpdateClick={handleUpdateClick}
                              updatedFields={updatedFields}
                              accountOverviewDetails={taskOverviewDetails}
                              showType={showType}
                              setShowType={setShowType}
                            />
                          </div>
                        </div>

                        <div className="d-flex align-items-center w-100 ps-1">
                          <div className="opacity-75" style={{ width: "23%" }}>
                            Related To
                          </div>
                          <div
                            className="ms-5 first_letter_uppercase"
                            style={{ width: "65%" }}
                          >
                            <span>{taskOverviewDetails.relatedTo || "-"}</span>
                            {/* <span>{taskOverviewDetails.relatedToName}</span> */}
                          </div>
                        </div>
                        <div className="d-flex align-items-center w-100 ps-1">
                          <div className="opacity-75" style={{ width: "23%" }}>
                            Related Module
                          </div>
                          <div
                            className="ms-5 first_letter_uppercase"
                            style={{ width: "65%" }}
                          >
                            <span>
                              {taskOverviewDetails.relatedModuleName?.toLowerCase() ||
                                "-"}
                            </span>
                            {/* <span>{taskOverviewDetails.relatedToName}</span> */}
                          </div>
                        </div>
                        <div className="d-flex align-items-center w-100 ps-1">
                          <div className="opacity-75" style={{ width: "23%" }}>
                            Relation To
                          </div>
                          <div className="ms-5" style={{ width: "65%" }}>
                            <span>{taskOverviewDetails.relationTo || "-"}</span>
                            {/* <span>{taskOverviewDetails.relatedToName}</span> */}
                          </div>
                        </div>
                        <div className="d-flex align-items-center w-100 ps-1">
                          <div className="opacity-75" style={{ width: "23%" }}>
                            Relation Module
                          </div>
                          <div
                            className="ms-5 first_letter_uppercase"
                            style={{ width: "65%" }}
                          >
                            <span>
                              {taskOverviewDetails.relationModuleName?.toLowerCase() ||
                                "-"}
                            </span>
                            {/* <span>{taskOverviewDetails.relatedToName}</span> */}
                          </div>
                        </div>

                        <div className="d-flex align-items-center w-100 ps-1">
                          <div className="opacity-75" style={{ width: "23%" }}>
                            Status
                          </div>
                          <div className="ms-5 w-50">
                            {editingFields["statusInfo"] &&
                            showType === "statusInfo" ? (
                              <div className="position-relative">
                                <Select
                                  value={updatedFields.statusInfo}
                                  menuPlacement="auto"
                                  onChange={(selectedOption) =>
                                    handleChangeIndDropDown(
                                      selectedOption,
                                      "statusInfo"
                                    )
                                  }
                                  options={statusOptions}
                                  styles={fieldDropdownStyles}
                                  isSearchable={false}
                                />
                                {editingFields["statusInfo"] &&
                                  showType === "statusInfo" && (
                                    <div
                                      className="position-absolute top-0"
                                      style={{ right: "-1.2rem" }}
                                    >
                                      <div className="d-flex gap-2">
                                        <span
                                          onClick={() => {
                                            setShowType("");
                                            handleUpdateClick("statusInfo");
                                          }}
                                          className="ps-2 cursorPointer"
                                        >
                                          <img
                                            src={updateIcon}
                                            alt="img"
                                            height={20}
                                            width={20}
                                          />
                                        </span>
                                        <span
                                          onClick={() => {
                                            setShowType("");
                                            handleCancelClick("statusInfo");
                                          }}
                                          className="cursorPointer"
                                        >
                                          <img
                                            src={cancelIcon}
                                            alt="img"
                                            height={20}
                                            width={20}
                                          />
                                        </span>
                                      </div>
                                    </div>
                                  )}
                              </div>
                            ) : (
                              <input
                                type="text"
                                className={`mt-1 cursorPointer indvEditInput ${
                                  showType === "statusInfo"
                                    ? "selectedBorder "
                                    : "unselectBorder bg-transparent"
                                }`}
                                value={taskOverviewDetails?.statusInfo?.value}
                                name={"statusInfo"}
                                onClick={(e) => {
                                  if (showType === "") {
                                    updatedFields.statusInfo =
                                      taskOverviewDetails.statusInfo;
                                    setShowType(e.target.name);
                                    setUpdatedFields(updatedFields);
                                  }
                                  handleEditClick("statusInfo");
                                }}
                                readOnly
                              />
                            )}
                          </div>
                        </div>

                        <div className="d-flex align-items-center w-100 ps-1">
                          <div className="opacity-75" style={{ width: "23%" }}>
                            Priority
                          </div>
                          <div className="ms-5" style={{ width: "65%" }}>
                            {editingFields["priorityInfo"] &&
                            showType === "priorityInfo" ? (
                              <div className="position-relative">
                                <Select
                                  value={updatedFields.priorityInfo}
                                  menuPlacement="auto"
                                  onChange={(selectedOption) =>
                                    handleChangeIndDropDown(
                                      selectedOption,
                                      "priorityInfo"
                                    )
                                  }
                                  options={priorityOptions}
                                  styles={fieldDropdownStyles}
                                  isSearchable={false}
                                />
                                {editingFields["priorityInfo"] &&
                                  showType === "priorityInfo" && (
                                    <div
                                      className="position-absolute top-0"
                                      style={{ right: "-3.5rem" }}
                                    >
                                      <div className="d-flex gap-2">
                                        <span
                                          onClick={() => {
                                            setShowType("");
                                            handleUpdateClick("priorityInfo");
                                          }}
                                          className="ps-2 cursorPointer"
                                        >
                                          <img
                                            src={updateIcon}
                                            alt="img"
                                            height={20}
                                            width={20}
                                          />
                                        </span>
                                        <span
                                          onClick={() => {
                                            setShowType("");
                                            handleCancelClick("priorityInfo");
                                          }}
                                          className="cursorPointer"
                                        >
                                          <img
                                            src={cancelIcon}
                                            alt="img"
                                            height={20}
                                            width={20}
                                          />
                                        </span>
                                      </div>
                                    </div>
                                  )}
                              </div>
                            ) : (
                              <input
                                type="text"
                                className={`mt-1 cursorPointer indvEditInput ${
                                  showType === "priorityInfo"
                                    ? "selectedBorder "
                                    : "unselectBorder bg-transparent"
                                }`}
                                value={taskOverviewDetails?.priorityInfo?.value}
                                name={"priorityInfo"}
                                onClick={(e) => {
                                  if (showType === "") {
                                    updatedFields.priorityInfo =
                                      taskOverviewDetails.priorityInfo;
                                    setShowType(e.target.name);
                                    setUpdatedFields(updatedFields);
                                  }
                                  handleEditClick("priorityInfo");
                                }}
                                readOnly
                              />
                            )}
                          </div>
                        </div>

                        <div className="d-flex align-items-center w-100 ps-1">
                          <div className="opacity-75" style={{ width: "23%" }}>
                            Created By
                          </div>
                          <div className="ms-5" style={{ width: "65%" }}>
                            <span>{taskOverviewDetails.createdBy}</span>
                            <span className="ms-2">{taskOverviewDetails.createdDate}</span>
                          </div>
                        </div>

                        <div className="d-flex align-items-center w-100 ps-1">
                          <div className="opacity-75" style={{ width: "23%" }}>
                            Modified By
                          </div>
                          <div className="ms-5" style={{ width: "65%" }}>
                            <span>{taskOverviewDetails.modifiedBy ||"--"}</span>
                            <span>{taskOverviewDetails.modifiedDate}</span>
                          </div>
                        </div>

                        <div className="d-flex align-items-center w-100 ps-1">
                          <div className="opacity-75" style={{ width: "23%" }}>
                            Reminder
                          </div>
                          <div className="ms-5" style={{ width: "65%" }}>
                            <span>
                              {taskOverviewDetails.reminder
                                ? `On day(s) ${taskOverviewDetails.remindDate} of due date at ${taskOverviewDetails.remindTime} through ${taskOverviewDetails.notify?.value}`
                                : "-"}
                            </span>
                          </div>
                        </div>

                        <div className="d-flex align-items-center w-100 ps-1">
                          <div className="opacity-75" style={{ width: "23%" }}>
                            Repeat
                          </div>
                          <div className="ms-5" style={{ width: "65%" }}>
                            <EditableInput
                              inputType="repeat"
                              handleIndividualInputChange={
                                handleIndividualInputChange
                              }
                              editingFields={editingFields}
                              handleEditClick={handleEditClick}
                              handleCancelClick={handleCancelClick}
                              handleUpdateClick={handleUpdateClick}
                              updatedFields={updatedFields}
                              accountOverviewDetails={taskOverviewDetails}
                              showType={showType}
                              setShowType={setShowType}
                            />
                          </div>
                        </div>

                        <div className="d-flex align-items-center w-100 ps-1">
                          <div className="opacity-75" style={{ width: "23%" }}>
                            Closed Time
                          </div>
                          <div className="ms-5" style={{ width: "65%" }}>
                            <span>{taskOverviewDetails.closedTime || "-"}</span>
                          </div>
                        </div>

                        <div className="d-flex align-items-center w-100 ps-1">
                          <div className="opacity-75" style={{ width: "23%" }}>
                            Description
                          </div>
                          <div className="ms-5" style={{ width: "65%" }}>
                            <EditableInput
                              fieldType="text"
                              inputType="descriptionInfo"
                              handleIndividualInputChange={
                                handleIndividualInputChange
                              }
                              editingFields={editingFields}
                              handleEditClick={handleEditClick}
                              handleCancelClick={handleCancelClick}
                              handleUpdateClick={handleUpdateClick}
                              updatedFields={updatedFields}
                              accountOverviewDetails={taskOverviewDetails}
                              showType={showType}
                              setShowType={setShowType}
                              isTextarea={true}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <div
                  className="bg-white rounded mt-2 p-2"
                  style={{ width: "100%" }}
                >
                  <div className="w-100 historyMain_Container flex-column   d-flex  commonContainer_bg rounded-2 ">
                    <div className="header d-flex gap-2 p-2">
                      <span>
                        <img src={history} alt="img" height={20} width={20} />
                      </span>
                      <span>History</span>
                    </div>
                    <div className="horizontal_line w-100  border border-white  mx-1"></div>
                    <div className="historyContent_mainContainer ">
                      {getTaskHistoryData &&
                      Object.keys(getTaskHistoryData).length > 0 ? (
                        Object.keys(getTaskHistoryData).map((date) => (
                          <div
                            className="history_container pt-3 d-flex flex-column"
                            key={date}
                          >
                            <span className="px-2 timelineBg_color border border-primary rounded-5 py-2 mx-5">
                              {date}
                            </span>
                            {getTaskHistoryData[date].map((item, index) => (
                              <div className="d-flex gap-2" key={index}>
                                <span className="opacity-75 pt-5 ps-2">
                                  {item.createdTime}
                                </span>
                                <div className="d-flex flex-column align-items-center">
                                  <p className="vertical_line m-0 p-0"></p>
                                  <span>
                                    <img
                                      src={groupIcon2}
                                      alt="img"
                                      height={25}
                                      width={25}
                                    />
                                  </span>
                                </div>
                                <span className="pt-5">
                                  {`${item.tmlDescription} ${item.tmlCreatedBy} ${date}`}
                                </span>
                              </div>
                            ))}
                          </div>
                        ))
                      ) : (
                        <div
                          className="text-center d-flex justify-content-center align-items-center opacity-75"
                          style={{ height: "20vh" }}
                        >
                          <h4>No Data available</h4>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  };

  const nodataPage = () => {
    return (
      <div className="d-flex flex-column justify-content-center align-items-center  ">
        <img src={noDataTask} alt="img" height={200} width={200} />
        <h5 className="opacity-75">Manage Task</h5>
        <p className="opacity-75 p-0 m-0">
          Quotes are legal agreements between a customer and a vector
        </p>
        <p className="opacity-75 p-0 m-0">
          to deliver the requested product(s) at the agreed upon price
        </p>
        <p className="opacity-75 p-0 m-0">within the specified time period.</p>
        <button
          className="createContactButtonSave px-2 mt-4 cursorPointer"
          style={{ width: "15%" }}
          onClick={() => setInnerPage("create")}
        >
          Create a Task
        </button>
      </div>
    );
  };

  const handleTaskView = () => {
    if (innerPage === "list") {
      return (
        <div>
          {isLoading ? (
            <>
              <Loader />
            </>
          ) : (
            <>
              {filteredTaskDetails && filteredTaskDetails.length > 0 ? (
                <>
                  <div className="creteLeadBar">
                    {selectedCheckboxes.length > 0 ? (
                      <div className="d-flex w-50 align-items-center gap-2">
                        <Select
                          options={massOptions}
                          styles={customCommonStyles("100%")}
                          value={{
                            value: "Action",
                            label: "Action",
                          }}
                          onChange={(option) => {
                            if (option.value === "Mass Update") {
                              setShowSearchFields(tasksColumn);
                              setSelectedObj({
                                name: "Due Date",
                                isChecked: false,
                                value: "dueDate",
                              });
                            } else if (option.value === "Mass Transfer") {
                              setSelectedItem(userList[0]);
                            }
                            setMassUpdateValue("");
                            setShowModalType(option.value);
                          }}
                          className="w-25"
                          isSearchable={false}
                        />
                      </div>
                    ) : (
                      <>
                        <div className="d-flex w-50 align-items-center gap-2">
                          {/* <img
                        src={filterView ? filterIcon : filterIconCross}
                        height={25}
                        className="leadFilter"
                        alt="img"
                      /> */}
                          <Select
                            options={options}
                            styles={customCommonStyles("180px", "350px")}
                            value={selectedOption}
                            onChange={handleChange}
                            isSearchable={false}
                          />
                        </div>
                        <div className="d-flex w-50 justify-content-end gap-2">
                          <div className="btn-group">
                            <button
                              className=" py-2 createContactButtonSave"
                              // style={{
                              //   background:
                              //     "linear-gradient(86.07deg, #407BFF 0%, #12A7FE 100%)",
                              //   border: "none",
                              //   borderRight: "2px solid #fff",
                              //   color: "#fff",
                              // }}

                              onClick={handleCreateTasks}
                            >
                              Create Task
                            </button>
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                  <div className="totalRecordBarContact">
                    <div className="d-flex w-50">
                      <h6>Total Records : {totalItems}</h6>
                    </div>
                    <div className="d-flex w-50 align-items-center justify-content-end gap-2">
                      <Select
                        options={pageOption}
                        styles={customCommonStyles("100%")}
                        value={pageOption.find(
                          (option) => option.value === perPage.toString()
                        )}
                        onChange={handlePageDropDowmChange}
                        isSearchable={false}
                      />
                      <Pagination
                        currentPage={currentPage}
                        totalPages={totalPages}
                        onPageChange={handlePageChange}
                      />
                    </div>
                  </div>
                  <div className="contactMain">
                    {/* <div className="contactLead">
              <h6 style={{ borderBottom: "2px solid #316AFF" }}>
                Filter by Task
              </h6>
              <input
                type="text"
                placeholder="Search"
                className="contactSearch"
              />
              <div className="checkboxItem">
                <h6 style={{ color: "#515151" }}>▾ System Defined Filters</h6>
                <input
                  type="checkbox"
                  id="vehicle1"
                  name="vehicle1"
                  value="Bike"
                />
                &nbsp;
                <label for="vehicle1">Touched Record</label>
                <br />
                <input
                  type="checkbox"
                  id="vehicle1"
                  name="vehicle1"
                  value="Bike"
                />
                &nbsp;
                <label for="vehicle1">Untouched Record</label>
                <br />
                <input
                  type="checkbox"
                  id="vehicle1"
                  name="vehicle1"
                  value="Bike"
                />
                &nbsp;
                <label for="vehicle1">Record Action</label>
                <br />
                <input
                  type="checkbox"
                  id="vehicle1"
                  name="vehicle1"
                  value="Bike"
                />
                &nbsp;
                <label for="vehicle1">Touched Record</label>
                <br />
                <input
                  type="checkbox"
                  id="vehicle1"
                  name="vehicle1"
                  value="Bike"
                />
                &nbsp;
                <label for="vehicle1">Untouched Record</label>
                <br />
                <input
                  type="checkbox"
                  id="vehicle1"
                  name="vehicle1"
                  value="Bike"
                />
                &nbsp;
                <label for="vehicle1">Record Action</label>
                <br />
                <h6 style={{ color: "#515151", marginTop: "10px" }}>
                  ▾ Filter By Fields
                </h6>
                <input
                  type="checkbox"
                  id="vehicle1"
                  name="vehicle1"
                  value="Bike"
                />
                &nbsp;
                <label for="vehicle1">Annual Revenue</label>
                <br />
                <input
                  type="checkbox"
                  id="vehicle1"
                  name="vehicle1"
                  value="Bike"
                />
                &nbsp;
                <label for="vehicle1">City</label>
                <br />
                <input
                  type="checkbox"
                  id="vehicle1"
                  name="vehicle1"
                  value="Bike"
                />
                &nbsp;
                <label for="vehicle1">Company</label>
                <br />
                <input
                  type="checkbox"
                  id="vehicle1"
                  name="vehicle1"
                  value="Bike"
                />
                &nbsp;
                <label for="vehicle1">Annual Revenue</label>
                <br />
                <input
                  type="checkbox"
                  id="vehicle1"
                  name="vehicle1"
                  value="Bike"
                />
                &nbsp;
                <label for="vehicle1">City</label>
                <br />
                <input
                  type="checkbox"
                  id="vehicle1"
                  name="vehicle1"
                  value="Bike"
                />
                &nbsp;
                <label for="vehicle1">Company</label>
                <br />
              </div>
            </div> */}
                    <div
                      className={
                        filterIcon ? "leadListTableFullWidth" : "leadListTable"
                      }
                    >
                      <table className="table leadTable">
                        <thead>
                          <tr>
                            <th scope="col">
                              <input
                                className="cursorPointer"
                                type="checkbox"
                                id="all"
                                name="all"
                                value="All"
                                checked={filteredTaskDetails.every((selected) =>
                                  selectedCheckboxes.some(
                                    (list) =>
                                      list.moduleId === selected.contactId
                                  )
                                )}
                                onChange={(e) => {
                                  const allList = filteredTaskDetails.map(
                                    (item) => ({
                                      moduleId: item.contactId,
                                    })
                                  );
                                  if (e.target.checked) {
                                    const newSelections = allList.filter(
                                      (newItem) =>
                                        !selectedCheckboxes.some(
                                          (existingItem) =>
                                            existingItem.moduleId ===
                                            newItem.moduleId
                                        )
                                    );
                                    setSelectedCheckboxes((prev) => [
                                      ...prev,
                                      ...newSelections,
                                    ]);
                                  } else {
                                    setSelectedCheckboxes(
                                      selectedCheckboxes.filter(
                                        (aItem) =>
                                          !allList.some(
                                            (bItem) =>
                                              aItem.moduleId === bItem.moduleId
                                          )
                                      )
                                    );
                                  }
                                }}
                                // checked={
                                //   selectedCheckboxes.length ===
                                //     filteredTaskDetails.length &&
                                //   filteredTaskDetails.length > 0
                                // }
                                // onChange={(e) => {
                                //   if (e.target.checked) {
                                //     const allList = filteredTaskDetails.map(
                                //       (item) => ({ moduleId: item.taskId })
                                //     );
                                //     setSelectedCheckboxes(allList);
                                //   } else {
                                //     setSelectedCheckboxes([]);
                                //   }
                                // }}
                              />
                            </th>
                            {selectedHeadings.map(
                              (heading) =>
                                heading.isChecked && (
                                  <th scope="col" key={heading.name}>
                                    <span className="pe-3">{heading.name}</span>
                                    <span className="position-relative">
                                      <img
                                        src={tableFilterIcon}
                                        alt=""
                                        className="cursorPointer"
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          setShowHeadings(false);
                                          setShowSelectedFilter(heading.name);
                                        }}
                                      />
                                      {heading.name === showSelectedFilter && (
                                        <span
                                          className="position-absolute rounded-2 border bg-light top-0 mt-4"
                                          style={{ right: "-10%" }}
                                        >
                                          <span className="d-flex flex-column px-1">
                                            {/* Ascending filter */}
                                            <span
                                              className="my-1 px-1 cursorPointer fw-normal text-start filter_type"
                                              onClick={() => {
                                                if (filterType === "asc") {
                                                  setFilterType("");
                                                  setFilteredTaskDetails(
                                                    allTaskDetails
                                                  );
                                                } else {
                                                  setFilterType("asc");
                                                  sortedColumnsData(
                                                    "asc",
                                                    heading.value
                                                  );
                                                }
                                              }}
                                            >
                                              {filterType === "asc" ? (
                                                <img
                                                  src={cancel}
                                                  alt="asc"
                                                  className="opacity-75 pe-1"
                                                />
                                              ) : (
                                                <img
                                                  src={arrowUp}
                                                  alt="asc"
                                                  className="opacity-75 pe-1"
                                                />
                                              )}
                                              Asc
                                            </span>
                                            {/* Descending filter */}
                                            <span
                                              className="my-2 px-1 cursorPointer fw-normal text-start filter_type"
                                              onClick={() => {
                                                if (filterType === "desc") {
                                                  setFilterType("");
                                                  setFilteredTaskDetails(
                                                    leadDetails
                                                  );
                                                } else {
                                                  setFilterType("desc");
                                                  sortedColumnsData(
                                                    "desc",
                                                    heading.value
                                                  );
                                                }
                                              }}
                                            >
                                              {filterType === "desc" ? (
                                                <img
                                                  src={cancel}
                                                  alt="desc"
                                                  className="opacity-75 pe-1"
                                                />
                                              ) : (
                                                <img
                                                  src={arrowDown}
                                                  alt="desc"
                                                  className="opacity-75 pe-1"
                                                />
                                              )}
                                              Desc
                                            </span>
                                            <span
                                              className={`my-1 px-1 fw-normal text-start filter_type ${
                                                selectedHeadings[0].name ===
                                                  "Subject" &&
                                                heading.name === "Subject"
                                                  ? "opacity-50"
                                                  : "cursorPointer"
                                              }`}
                                              onClick={() => {
                                                if (
                                                  pinnedColumn !== "Subject"
                                                ) {
                                                  setPinnedColumn("Subject");
                                                  handlePinColumn("Subject");
                                                } else {
                                                  setPinnedColumn(heading.name);
                                                  handlePinColumn(heading.name);
                                                }
                                              }}
                                            >
                                              <img
                                                src={
                                                  pinnedColumn === heading.name
                                                    ? unPin
                                                    : pin
                                                }
                                                alt="pin"
                                                className="opacity-75 pe-1"
                                              />{" "}
                                              {pinnedColumn === heading.name
                                                ? "Unpin"
                                                : "Pin"}{" "}
                                              Column
                                            </span>
                                          </span>
                                        </span>
                                      )}
                                      <img
                                        src={filterLine}
                                        alt=""
                                        className="ps-2"
                                      />
                                    </span>
                                  </th>
                                )
                            )}

                            {/* <th scope="col">Company</th>
                  <th scope="col">Email</th>
                  <th scope="col">Phone</th>
                  <th scope="col">Lead Source</th> */}
                            {/* <th scope="col">Lead Owner</th> */}
                            <th
                              scope="col"
                              className="text-end cursorPointer position-relative"
                            >
                              <img
                                src={filterMenu}
                                alt=""
                                className={`filter_menu ${
                                  showHeadings ? "bg-light" : ""
                                }`}
                                onClick={(e) => {
                                  e.stopPropagation();
                                  setShowSelectedFilter("");
                                  setShowHeadings(true);
                                }}
                              />
                              {showHeadings && (
                                <div
                                  className="position-absolute rounded-2  border border-primary bg-light"
                                  style={{
                                    right: "0.2rem",
                                  }}
                                >
                                  <div className="d-flex flex-column px-2">
                                    <span
                                      className="p-2 cursorPointer fw-normal text-start"
                                      onClick={() => {
                                        setSearchColumn("");
                                        setSavingHeadings(selectedHeadings);
                                        setShowModalType("Manage Columns");
                                      }}
                                    >
                                      Manage Columns
                                    </span>
                                    <span
                                      className="p-2 fw-normal text-start opacity-50"
                                      onClick={() => {
                                        setShowModalType("Reset Column Size");
                                      }}
                                    >
                                      Reset Column Size
                                    </span>
                                  </div>
                                </div>
                              )}
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {filteredTaskDetails &&
                            filteredTaskDetails.map((item, i) => {
                              return (
                                <tr key={i} className="">
                                  <td>
                                    <input
                                      className="cursorPointer"
                                      type="checkbox"
                                      id="vehicle1"
                                      name="vehicle1"
                                      value={item.taskId}
                                      checked={selectedCheckboxes.some(
                                        (checkbox) =>
                                          checkbox.moduleId === item.taskId
                                      )}
                                      onChange={handleSelectCheckboxChange}
                                    />
                                  </td>
                                  <React.Fragment>
                                    {selectedHeadings.map((obj, index) => {
                                      const value = obj.value;
                                      const itemValue = item[value];

                                      return obj.isChecked ? (
                                        value === "transHash" ? (
                                          <td
                                            style={{ wordBreak: "break-all" }}
                                            className="overflow_text_hash"
                                          >
                                            <a
                                              rel="noreferrer"
                                              href={
                                                EXPLORER_URL +
                                                "about-tx-hash/" +
                                                item.transHash
                                              }
                                              className="anchorLinkHash textColor"
                                              data-title={item.transHash}
                                              target="_blank"
                                            >
                                              {item.transHash
                                                ? item.transHash.slice(0, 10) +
                                                  "...."
                                                : "-"}
                                            </a>
                                          </td>
                                        ) : (
                                          <td
                                            key={index}
                                            onClick={() => {
                                              if (value === "subject") {
                                                setInnerPage("overview");
                                                taskOverviewDetail(item.taskId);
                                                setTaskId(item.taskId);
                                                handleClickSelectedTask(i);
                                                getAllTaskHistory(item.taskId);
                                                setSubjectName(item.subject);
                                                setTaskPageView("overview");
                                              }
                                            }}
                                            className={`overflow_text ${
                                              value === "subject"
                                                ? "leadName cursorPointer"
                                                : ""
                                            }`}
                                            style={{ maxWidth: "3vw" }}
                                          >
                                            {itemValue !== null
                                              ? itemValue
                                              : "-"}
                                          </td>
                                        )
                                      ) : null;
                                    })}
                                  </React.Fragment>
                                  <td></td>
                                </tr>
                              );
                            })}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </>
              ) : (
                <>{nodataPage()}</>
              )}
            </>
          )}
        </div>
      );
    } else if (["create", "clone"].includes(innerPage)) {
      return (
        <>
          <div className="createLeadButtonBar">
            <div className="d-flex align-items-center w-50 gap-2">
              <img src={groupIcon} height={25} alt="group" />
              <div className="d-flex flex-column">
                <p className="mb-0 first_letter_uppercase">
                  {existingTask ? "Edit" : innerPage} Task
                </p>
              </div>
            </div>
            <div className="d-flex w-50 gap-2 justify-content-end">
              <button
                className="createLeadButton"
                onClick={() => {
                  setInnerPage("list");
                  resetData();
                  setSelectedItem(userList[0]);
                  setSelectLeadOrContact({ value: "Lead", label: "Lead" });
                  setSelectEnableContactOptions({
                    value: "ACCOUNTS",
                    label: "Account",
                  });
                  setSelectedLeadItem(leadDetails[0]);
                  setSelectedContactItem(contactDetails[0]);
                  setRepeatChecked(false);
                  setChecked(false);

                  if (existingTask) {
                    setInnerPage("overview");
                  } else {
                    setInnerPage("list");
                  }
                  if (innerPage === "clone" && !existingTask) {
                    setInnerPage("overview");
                  }
                }}
              >
                Cancel
              </button>
              {!existingTask && (
                <button
                  className="createContactButton"
                  onClick={() => {
                    handleCreateTask("SAVE AND NEW");
                  }}
                  disabled={saveAndNewSubmit}
                >
                  {saveAndNewSubmit ? (
                    <Lottie
                      options={defaultOptions("blue")}
                      height="24px"
                      width="36px"
                    />
                  ) : (
                    "Save and New"
                  )}
                </button>
              )}
              <button
                className="createContactButtonSave"
                type="submit"
                onClick={() => {
                  if (existingTask) {
                    handleUpdateTask("SAVE");
                  } else if (existingClone) {
                    handleCreateTask("ADD");
                  } else {
                    handleCreateTask("SAVE");
                  }
                }}
                disabled={saveSubmit}
              >
                {saveSubmit ? (
                  <Lottie
                    options={defaultOptions("white")}
                    height="30px"
                    width="36px"
                  />
                ) : (
                  updateButtonLabel
                )}
              </button>
            </div>
          </div>
          <div className="tasksField">
            <h6 className="py-2" style={{ borderBottom: "2px solid #316AFF" }}>
              Task Information
            </h6>
            <div className="inputFieldDiv">
              {/* <div className="inputFieldDivLeft">
                <p className="mb-0">Task Owner</p>
                <input
                  type="text"
                  className="leadInputField"
                  value={taskInfo.taskInfo}
                  name="taskInfo"
                  onChange={handleInputFieldChange}
                />
              </div> */}
              {/* ---------------------------------------- */}
              <div className="inputFieldDivLeft position-relative">
                <p className="mb-0">
                  Task Owner
                  <span className="text-danger">*</span>
                </p>
                <div className="d-flex input-with-clear ">
                  <input
                    type="text"
                    //   placeholder="Vendor Owner"
                    className="leadInputField cursorPointer"
                    value={selectedItem?.name || ""}
                    onClick={handleOwnerInputClick}
                    readOnly
                  />
                  <span
                    className="clear-button"
                    onClick={handleOwnerInputClick}
                  >
                    <img
                      src={listVisible ? up : down}
                      alt="img"
                      height={20}
                      width={20}
                    />
                  </span>

                  <span
                    className="cursorPointer pt-2 ps-1"
                    // onClick={() => setIsLeadOwnerModal(!isLeadOwnerModal)}
                    onClick={() => {
                      if (existingTask) {
                        setShowModalType("changeOwner");
                        setListVisible(false);
                      } else {
                        setIsLeadOwnerModal(!isLeadOwnerModal);
                      }
                    }}
                  >
                    <img src={userSearch} alt="img" width={20} height={20} />
                  </span>
                </div>

                {existingTask ? (
                  <></>
                ) : (
                  <>
                    {listVisible && (
                      <ul
                        className="data-list list-group  lead_ownerList_container position-absolute"
                        style={{ top: "6rem" }}
                        onClick={(e) => e.stopPropagation()}
                      >
                        {userList.map((item, index) => (
                          <li
                            key={index}
                            className="cursorPointer list-group-item"
                            onClick={() => handleUserItemClick(item)}
                          >
                            {item.name}
                          </li>
                        ))}
                      </ul>
                    )}
                  </>
                )}
              </div>
              {/* ------------------------------------------------- */}
              <div className="inputFieldDivRight">
                <p className="mb-0">
                  Due Date
                  <span className="text-danger">*</span>
                </p>
                <input
                  type="date"
                  className={
                    mandatoryMsgDueDate
                      ? "mandatoryField"
                      : "insertCommonInputField"
                  }
                  value={
                    existingTask || existingClone
                      ? convertDateFormat(taskUpdateInfo.dueDate)
                      : taskInfo.dueDate
                      ? convertDateFormat(taskInfo.dueDate)
                      : ""
                  }
                  min={todayDate}
                  name="dueDate"
                  onChange={handleInputFieldChange}
                  onKeyDown={(e) => e.preventDefault()}
                  max="2050-12-31"
                />
                <p className="mb-0" style={{ color: "#fa5a5a" }}>
                  {mandatoryMsgDueDate && mandatoryMsgDueDate}
                </p>
              </div>
            </div>
            <div
              className="inputFieldDiv"
              style={{
                borderBottom: "2px solid rgba(162, 161, 168, 0.2)",
                paddingBottom: "20px",
              }}
            >
              <div className="inputFieldDivLeft">
                <p className="mb-0">
                  Subject
                  <span className="text-danger">*</span>
                </p>
                {/* <Select
                  value={
                    existingTask ? taskUpdateInfo.subject : taskInfo.subject
                  }
                  menuPlacement="auto"
                  onChange={(selectedOption) =>
                    handleDropDownChanges(selectedOption, "subject")
                  }
                  options={subjectOptions}
                  styles={createLeadStyles}
                /> */}
                <input
                  type="text"
                  className={
                    mandatoryMsg ? "mandatoryField" : "insertCommonInputField"
                  }
                  value={
                    existingTask || existingClone
                      ? taskUpdateInfo.subject
                      : taskInfo.subject
                  }
                  name="subject"
                  onChange={handleInputFieldChange}
                  maxLength={100}
                />
                <p className="mb-0" style={{ color: "#fa5a5a" }}>
                  {mandatoryMsg && mandatoryMsg}
                </p>
              </div>
            </div>
            <div className="inputFieldDiv" style={{ paddingTop: "20px" }}>
              <div className="inputFieldDivLeft">
                <div
                  className="
                  input-with-clear  "
                >
                  <input
                    type="text"
                    className="leadInputField cursorPointer"
                    value={selectLeadOrContact.value}
                    onClick={handleLeadAndContactClick}
                    readOnly
                  />
                  <span className="clear-button">
                    <img
                      src={leadAndAccountListVisible ? up : down}
                      alt="img"
                      height={20}
                      width={20}
                      onClick={handleLeadAndContactClick}
                    />
                  </span>
                </div>

                {leadAndAccountListVisible && (
                  <ul
                    className="data-list list-group  lead_ownerList_task_container"
                    style={{ position: "absolute", top: "510px" }}
                  >
                    {leadContactOptions.map((item, index) => (
                      <li
                        key={index}
                        className="cursorPointer list-group-item"
                        onClick={() => handleSelectLeadOrContact(item)}
                      >
                        {item.value}
                      </li>
                    ))}
                  </ul>
                )}
              </div>
              {selectLeadOrContact.value === "Lead" ? (
                <div className="inputFieldDivRight position-relative">
                  <div className="d-flex input-with-clear ">
                    <input
                      type="text"
                      placeholder="Search"
                      className="leadInputField cursorPointer"
                      value={selectedLeadItem.leadName || ""}
                      // disabled={selectLeadOrContact.value === "Lead"}
                      onClick={handleLeadClick}
                      readOnly
                    />
                    <span className="clear-button" onClick={handleLeadClick}>
                      <img
                        src={leadListVisible ? up : down}
                        alt="img"
                        height={20}
                        width={20}
                      />
                    </span>

                    <span
                      className="cursorPointer pt-2 ps-1"
                      onClick={() => setIsLeadListModal(!isLeadListModal)}
                    >
                      <img src={userSearch} alt="img" width={20} height={20} />
                    </span>
                  </div>
                  {leadListVisible && (
                    <ul
                      className="data-list list-group  lead_ownerList_container position-absolute"
                      style={{ top: "4rem" }}
                    >
                      {" "}
                      {leadDetails.map((item, index) => (
                        <li
                          key={index}
                          className="cursorPointer list-group-item"
                          onClick={() => handleLeadItemClick(item)}
                        >
                          {item.leadName}
                        </li>
                      ))}
                    </ul>
                  )}
                </div>
              ) : (
                <div className="inputFieldDivRight position-relative">
                  <div className="d-flex input-with-clear ">
                    <input
                      type="text"
                      placeholder="Search"
                      className="leadInputField cursorPointer"
                      value={selectedContactItem.contactName || ""}
                      disabled={selectLeadOrContact.value === "Lead"}
                      onClick={handleContactClick}
                    />

                    <span
                      className={`clear-button ${
                        selectLeadOrContact.value === "Lead" ? "disabled" : ""
                      }`}
                      onClick={(e) => {
                        if (selectLeadOrContact.value !== "Lead") {
                          handleContactClick(e);
                        }
                      }}
                    >
                      <img
                        src={contactListVisible ? up : down}
                        alt="img"
                        height={20}
                        width={20}
                      />{" "}
                    </span>

                    <span
                      className="cursorPointer pt-2 ps-1"
                      onClick={() => setIsContactListModal(!isContactListModal)}
                    >
                      <img src={userSearch} alt="img" width={20} height={20} />
                    </span>
                  </div>
                  {contactListVisible && (
                    <ul
                      className="data-list list-group  lead_ownerList_container position-absolute"
                      style={{ top: "4rem" }}
                    >
                      {" "}
                      {contactDetails.map((item, index) => (
                        <li
                          key={index}
                          className="cursorPointer list-group-item"
                          onClick={() => handleContactItemClick(item)}
                        >
                          {item.contactName}
                        </li>
                      ))}
                    </ul>
                  )}
                </div>
              )}
            </div>
            {/* {isLeadListModal ? (
              <LeadListModal
                isLeadListModal={isLeadListModal}
                setIsLeadListModal={setIsLeadListModal}
                onSelectedLeadChange={handleSelectedLeadListChange}
                defaultUser={selectedLeadItem}
                leadDetails={leadDetails}
              />
            ) : null} */}
            {/* {isContactListModal ? (
              <ContactListModal
                isContactListModal={isContactListModal}
                setIsContactListModal={setIsContactListModal}
                onSelectedLeadChange={handleSelectedContactListChange}
                defaultUser={selectedContactItem}
                allContactDetails={contactDetails}
              />
            ) : null} */}

            <Modal
              show={isLeadListModal}
              className="d-flex justify-content-center align-items-center"
              aria-labelledby="example-custom-modal-styling-title"
              size="lg"
              id="commonLeadModal"
            >
              <Modal.Body className="ModalBody  overflow-auto">
                <div
                  className="modalUS row justify-content-center"
                  style={{ margin: "0", gap: "10px", paddingBottom: "10px" }}
                >
                  <div className="header_Container d-flex justify-content-between">
                    <h4 className="commonTextColor pt-2">Change Lead</h4>
                    <div className="inputContainer d-flex justify-content-start">
                      <input
                        type="text"
                        placeholder="Search"
                        className="topBarModalSearch px-5"
                        onChange={handleSearchChangeLead}
                        value={searchLead}
                      />
                      <img
                        src={SearchIcon}
                        alt="search"
                        className="searchIcon"
                      />
                    </div>
                  </div>

                  <div className="Owner_table_container border border-dark rounded-2 h-25">
                    <div className="table-wrapper">
                      <table className="userdata-table">
                        <thead>
                          <tr>
                            <th style={{ width: "15%" }}>Lead Name</th>
                            <th style={{ width: "15%" }}>Company</th>
                            <th style={{ width: "20%" }}>Email</th>
                            <th style={{ width: "15%" }}>Phone</th>
                            <th style={{ width: "15%" }}>Lead Source</th>
                            <th style={{ width: "15%" }}>Lead Owner</th>
                          </tr>
                        </thead>
                        <tbody className="table-body tableUserData">
                          {filteredLeadDetails?.map((item, index) => (
                            <tr
                              key={index}
                              onClick={() => {
                                setSelectedLeadItem(item);
                              }}
                            >
                              <td
                                style={{ width: "15%" }}
                                className="overflow_text"
                              >
                                {" "}
                                {item.leadName}{" "}
                              </td>
                              <td
                                style={{ width: "15%" }}
                                className="overflow_text"
                              >
                                {item.companyName}
                              </td>
                              <td
                                style={{ width: "20%" }}
                                className="overflow_text"
                              >
                                {item.emailId}
                              </td>
                              <td
                                style={{ width: "15%" }}
                                className="overflow_text"
                              >
                                {item.phoneNumber}
                              </td>
                              <td
                                style={{ width: "15%" }}
                                className="overflow_text"
                              >
                                {item.leadSource}
                              </td>
                              <td
                                style={{ width: "15%" }}
                                className="overflow_text"
                              >
                                {item.leadOwner}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div className="d-flex w-100 align-items-center justify-content-end gap-2">
                    {filteredLeadDetails.length > 0 && (
                      <>
                        <Select
                          options={pageOption}
                          styles={customCommonStyles("100%")}
                          // value={selectPageOption}
                          value={pageOption.find(
                            (option) => option.value === perPageLead.toString()
                          )}
                          onChange={handlePageDropDownChangeLead}
                        />
                        <Pagination
                          currentPage={currentPageLead}
                          totalPages={totalPagesLead}
                          onPageChange={hadlePageChangeLead}
                        />
                      </>
                    )}
                  </div>
                  <div className="d-flex pb-2 pt-3 justify-content-between">
                    <div className="selectedUser d-flex gap-1">
                      <span>Selected User</span>
                      <img src={logoConvert} alt="img" height={20} width={20} />
                      <span>{selectedLeadItem.leadName}</span>
                    </div>
                    <div className="d-flex gap-2">
                      <button
                        className="btn btn-outline-secondary border border-primary btn-sm px-3 py-2 text-dark"
                        onClick={() => {
                          setIsLeadListModal(false);
                          setSelectedLeadItem({});
                        }}
                      >
                        Cancel
                      </button>
                      <button
                        className="btn btnColor btn-sm px-3 text-white"
                        onClick={() => {
                          setIsLeadListModal(false);
                        }}
                      >
                        Done
                      </button>
                    </div>
                  </div>
                </div>
              </Modal.Body>
            </Modal>

            <Modal
              show={isContactListModal}
              className="d-flex justify-content-center align-items-center"
              aria-labelledby="example-custom-modal-styling-title"
              size="lg"
              id="commonLeadModal"
            >
              <Modal.Body className="ModalBody  overflow-auto">
                <div
                  className="modalUS row justify-content-center"
                  style={{ margin: "0", gap: "10px", paddingBottom: "10px" }}
                >
                  <div className="header_Container d-flex justify-content-between">
                    <h4 className="commonTextColor pt-2">Change Contact</h4>
                    <div className="inputContainer d-flex justify-content-start">
                      <input
                        type="text"
                        placeholder="Search"
                        className="topBarModalSearch px-5"
                        onChange={handleSearchChangeContact}
                        value={searchContact}
                      />
                      <img
                        src={SearchIcon}
                        alt="search"
                        className="searchIcon"
                      />
                    </div>
                  </div>

                  <div className="Owner_table_container border border-dark rounded-2 h-25">
                    <div className="table-wrapper">
                      <table className="userdata-table">
                        <thead>
                          <tr>
                            <th>Contact Name</th>
                            <th>Account Name</th>
                            <th>Email</th>
                            <th>Phone</th>
                            <th>Contact Owner</th>
                          </tr>
                        </thead>
                        <tbody className="table-body tableUserData">
                          {filteredContactDetails?.map((item, index) => (
                            <tr
                              key={index}
                              onClick={() => {
                                setSelectedContactItem(item);
                              }}
                            >
                              <td>{item.contactName}</td>
                              <td>{item.accountName}</td>
                              <td>{item.emailId}</td>
                              <td>{item.phone}</td>
                              <td>{item.contactOwner}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div className="d-flex w-100 align-items-center justify-content-end gap-2">
                    {filteredContactDetails.length > 0 && (
                      <>
                        <Select
                          options={pageOption}
                          styles={customCommonStyles("100%")}
                          // value={selectPageOption}
                          value={pageOption.find(
                            (option) =>
                              option.value === perPageContact.toString()
                          )}
                          onChange={handlePageDropDownChangeContact}
                        />
                        <Pagination
                          currentPage={currentPageContact}
                          totalPages={totalPagesContact}
                          onPageChange={hadlePageChangeContact}
                        />
                      </>
                    )}
                  </div>
                  <div className="d-flex pb-2 pt-3 justify-content-between">
                    <div className="selectedUser d-flex gap-1">
                      <span>Selected User</span>
                      <img src={logoConvert} alt="img" height={20} width={20} />
                      <span>{selectedContactItem.contactName}</span>
                    </div>
                    <div className="d-flex gap-2">
                      <button
                        className="btn btn-outline-secondary border border-primary btn-sm px-3 py-2 text-dark"
                        onClick={() => {
                          setIsContactListModal(false);
                          setSelectedContactItem({});
                        }}
                      >
                        Cancel
                      </button>
                      <button
                        className="btn btnColor btn-sm px-3 text-white"
                        onClick={() => {
                          getAllAssignModules(
                            "CONTACTS",
                            [selectEnableContactOptions.value],
                            selectedContactItem.contactId
                          );
                        }}
                      >
                        Done
                      </button>
                    </div>
                  </div>
                </div>
              </Modal.Body>
            </Modal>

            <div
              className="inputFieldDiv"
              style={{
                borderBottom: "2px solid rgba(162, 161, 168, 0.2)",
                paddingBottom: "20px",
              }}
            >
              <div className="inputFieldDivLeft">
                {/* <p className="mb-0">Account</p> */}
                <div className="input-with-clear ">
                  <input
                    type="text"
                    className="leadInputField"
                    value={
                      selectLeadOrContact.value === "Contact"
                        ? selectEnableContactOptions?.label
                        : ""
                    }
                    disabled={selectLeadOrContact.value === "Lead"}
                    onClick={handleEnableContactOption}
                  />
                  {selectLeadOrContact.value === "Contact" && (
                    <span className="clear-button">
                      <img
                        src={enableContactOptionVisible ? up : down}
                        alt="img"
                        height={20}
                        width={20}
                        onClick={handleEnableContactOption}
                      />
                    </span>
                  )}
                </div>
                {enableContactOptionVisible &&
                  selectLeadOrContact.value === "Contact" && (
                    <ul
                      className="data-list list-group  lead_ownerList_task_container"
                      style={{ position: "absolute", top: "600px" }}
                    >
                      {enableContactOptions.map((item, index) => (
                        <li
                          key={index}
                          className="cursorPointer list-group-item"
                          onClick={() => handleSelectEnableOptions(item)}
                        >
                          {item.label}
                        </li>
                      ))}
                    </ul>
                  )}
              </div>
              <div className="inputFieldDivRight position-relative">
                <div className="d-flex input-with-clear ">
                  <input
                    type="text"
                    placeholder="Search"
                    className="leadInputField cursorPointer"
                    value={
                      selectLeadOrContact.value === "Lead"
                        ? ""
                        : moduleWiseData.length > 0 &&
                          selectLeadOrContact.value === "Contact"
                        ? selectedModuleItem.moduleName
                        : ""
                    }
                    disabled={selectLeadOrContact.value === "Lead"}
                    onClick={handleAccountClick}
                    readOnly
                  />
                  {selectLeadOrContact.value === "Lead" ? (
                    <span className="clear-button">
                      <img
                        src={accountListVisible ? up : down}
                        alt="img"
                        height={20}
                        width={20}
                      />{" "}
                    </span>
                  ) : (
                    <span className="clear-button" onClick={handleAccountClick}>
                      <img
                        src={accountListVisible ? up : down}
                        alt="img"
                        height={20}
                        width={20}
                      />{" "}
                    </span>
                  )}

                  {/* {selectLeadOrContact.value === "Lead" ? (
                    <span className="cursorPointer pt-2 ps-1">
                      <img src={userSearch} alt="img" width={20} height={20} />
                    </span>
                  ) : (
                    <span
                      className="cursorPointer pt-2 ps-1"
                      onClick={() => setIsAccountListModal(!isAccountListModal)}
                    >
                      <img src={userSearch} alt="img" width={20} height={20} />
                    </span>
                  )} */}
                </div>
                {accountListVisible && moduleWiseData.length > 0 && (
                  <ul
                    className="data-list list-group  lead_ownerList_container position-absolute"
                    style={{ top: "4rem" }}
                  >
                    {" "}
                    {moduleWiseData.map((item, index) => (
                      <li
                        key={index}
                        className="cursorPointer list-group-item"
                        onClick={() => handleAccountItemClick(item)}
                      >
                        {item.moduleName}
                      </li>
                    ))}
                  </ul>
                )}
              </div>
            </div>
            {isAccountListModal ? (
              <AccountListModal
                isAccountListModal={isAccountListModal}
                setIsAccountListModal={setIsAccountListModal}
                onSelectedLeadChange={handleSelectedAccountListChange}
              />
            ) : null}
            <div className="inputFieldDiv" style={{ paddingTop: "20px" }}>
              <div className="inputFieldDivLeft">
                <p className="mb-0">Status</p>
                <Select
                  value={
                    existingTask || existingClone
                      ? taskUpdateInfo.status
                      : taskInfo.status
                  }
                  menuPlacement="auto"
                  onChange={(selectedOption) =>
                    handleDropDownChanges(selectedOption, "status")
                  }
                  options={statusOptions}
                  styles={createLeadStyles}
                  isSearchable={false}
                />
              </div>
              <div className="inputFieldDivRight">
                <p className="mb-0">Priority</p>
                <Select
                  value={
                    existingTask || existingClone
                      ? taskUpdateInfo.priority
                      : taskInfo.priority
                  }
                  menuPlacement="auto"
                  onChange={(selectedOption) =>
                    handleDropDownChanges(selectedOption, "priority")
                  }
                  options={priorityOptions}
                  styles={createLeadStyles}
                  isSearchable={false}
                />
              </div>
            </div>
            <div className="inputFieldDiv mt-4">
              <div className="inputFieldDivLeft flex-row align-items-center">
                <p className="mb-0">Reminder</p>
                <Switch
                  onChange={handleReminderChange}
                  checked={checked}
                  uncheckedIcon={false}
                  checkedIcon={false}
                />
                <p
                  className="mb-0 ms-2"
                  onClick={() => {
                    setShow(true);
                  }}
                >
                  {checked &&
                    "On " +
                      (existingTask || existingClone
                        ? taskUpdateInfo.remindDate
                        : taskInfo.remindDate) +
                      " at " +
                      (existingTask || existingClone
                        ? taskUpdateInfo.remindTime
                        : taskInfo.remindTime) +
                      " by " +
                      (existingTask || existingClone
                        ? taskUpdateInfo.notify.value === "Both"
                          ? "Email and Pop Up"
                          : taskUpdateInfo.notify.value
                        : taskInfo.notify.value)}
                </p>
              </div>
              {existingTask || existingClone ? (
                <></>
              ) : (
                <div className="inputFieldDivRight flex-row">
                  <p className="mb-0">Repeat</p>
                  <Switch
                    onChange={handleRepeatChange}
                    checked={repeatChecked}
                    uncheckedIcon={false}
                    checkedIcon={false}
                  />
                  {repeatChecked ? (
                    <p className="mb-0 ms-2">{taskInfo.repeatType.value}</p>
                  ) : (
                    <></>
                  )}
                </div>
              )}
            </div>
            <h6
              className="py-2 mt-3"
              style={{ borderBottom: "2px solid #316AFF" }}
            >
              Description Information
            </h6>
            <div className="inputFieldDiv">
              <div className="inputFieldDivLeft">
                <p>Description</p>
                <textarea
                  rows="4"
                  cols="50"
                  maxLength="1000"
                  style={{
                    width: "100%",
                    border: "1px solid #B9B9B94D",
                    height: "100px",
                    outline: "none",
                    borderRadius: "10px",
                    backgroundColor: "transparent",
                    padding: "8px 12px",
                  }}
                  className="px-2"
                  name="description"
                  value={
                    existingTask || existingClone
                      ? taskUpdateInfo.description
                      : taskInfo.description
                  }
                  onChange={handleInputFieldChange}
                />
              </div>
            </div>
          </div>
          {isLeadOwnerModal ? (
            <LeadOwnerModal
              isLeadOwnerModal={isLeadOwnerModal}
              setIsLeadOwnerModal={setIsLeadOwnerModal}
              onSelectedLeadChange={handleSelectedLeadChange}
              defaultUser={selectedItem}
            />
          ) : null}
        </>
      );
    } else if (innerPage === "overview") {
      return <>{individualTaskDetailPage()}</>;
    }
  };
  const handleDoneButtonClick = () => {
    let valid = true;

    if (!taskUpdateInfo.remindDate && !taskInfo.remindDate) {
      setShowSelectDateMsg("Select a Date is mandatory");
      setTimeout(() => {
        setShowSelectDateMsg("");
      }, 3000);
      valid = false;
    }

    if (!taskUpdateInfo.remindTime && !taskInfo.remindTime) {
      setShowSelectTimeMsg("Select a Time is mandatory");
      setTimeout(() => {
        setShowSelectTimeMsg("");
      }, 3000);
      valid = false;
    }
    const dt =
      existingTask || existingClone
        ? convertDateFormat(taskUpdateInfo.remindDate)
        : convertDateFormat(taskInfo.remindDate);

    const time =
      existingTask || existingClone
        ? convertDateFormat(taskUpdateInfo.remindTime)
        : convertDateFormat(taskInfo.remindTime);

    if (
      !compareDateTimeToCurrent(moment(new Date(dt)).format("DD-MM-YYYY"), time)
    ) {
      setShowSelectTimeMsg(
        "Please enter a time that is later than the current time."
      );
      setTimeout(() => {
        setShowSelectTimeMsg("");
      }, 3000);
      valid = false;
    }

    if (valid) {
      handleClose();
      setChecked(true);
    }
  };

  return (
    <div
      className="taskMain"
      onClick={() => {
        setShowHeadings(false);
        setShowSelectedFilter("");
        setListVisible(false);
        setShowOptionsTask(false);
        setLeadAndAccountListVisible(false);
        setEnableContactOptionVisible(false);
        setLeadListVisible(false);
        setContactListVisible(false);
        setAccountListVisible(false);
      }}
    >
      {handleTaskView()}
      <Modal
        show={modalType === "Manage Columns"}
        id="ConvertModal"
        aria-labelledby="example-custom-modal-styling-title"
        className="withdrw_modal"
      >
        <Modal.Body>
          <div className="modalClass  position-relative">
            <div className="d-flex justify-content-between ">
              <h4 className="">Manage Columns</h4>
              {/* <img
                src={crossIcon}
                alt="cross"
                style={{ cursor: "pointer" }}
                className="ms-1"
                onClick={() => {
                  setShowModalType("");
                }}
              /> */}
            </div>
            <div className="w-100 position-relative">
              <div className="mb-1 pt-2 position-relative w-100">
                <input
                  type="text"
                  className="assetSearchbar searchbar rounded-2 p-2 pe-5 common_input_bg w-100 "
                  placeholder="Search"
                  aria-label="Username"
                  aria-describedby="basic-addon1"
                  value={searchColumn}
                  onChange={(e) => {
                    if (e.target.value.charAt(0) !== " ") {
                      const value = e.target.value.replace(
                        /[^a-zA-Z ]+|(\s)\s+/g,
                        "$1"
                      );
                      setSearchColumn(value);
                    }
                  }}
                />
                <span
                  className="position-absolute  mt-2 me-2 end-0 opacity-75"
                  onClick={() => {
                    setSearchColumn("");
                    setSavingHeadings(tasksColumn);
                  }}
                >
                  {searchColumn === "" ? (
                    <img
                      src={searchIcon}
                      alt=""
                      width={20}
                      style={{ cursor: "pointer" }}
                    />
                  ) : (
                    <span style={{ cursor: "pointer" }}>X</span>
                  )}
                </span>
              </div>
              <div className=" opacity-75 columns_container">
                {savingHeadings
                  .filter((lead) =>
                    lead.name
                      .toLowerCase()
                      .includes(searchColumn.trim().toLowerCase())
                  )
                  .map((column) => {
                    return (
                      <div
                        key={column.name}
                        className="d-flex gap-2 py-2 rounded-2 align-items-center"
                      >
                        <div className="d-flex gap-2 ps-2">
                          <input
                            type="checkbox"
                            id={column.name}
                            name={column.name}
                            checked={column.isChecked}
                            onChange={(e) => {
                              handleColumnCheckboxChange(
                                column.name,
                                e.target.checked
                              );
                            }}
                            className="cursorPointer"
                          />
                          <label
                            className="small cursorPointer"
                            htmlFor={column.name}
                          >
                            {column.name}
                          </label>
                        </div>
                      </div>
                    );
                  })}
              </div>
              <aside className="float-end mt-2">
                <div className="d-flex align-items-center gap-4">
                  <button
                    className="cancel_btn bg-light"
                    onClick={() => {
                      setShowModalType("");
                    }}
                  >
                    Cancel
                  </button>
                  <button
                    className="save_btn"
                    onClick={() => {
                      setSelectedHeadings(savingHeadings);
                      setTimeout(() => {
                        setShowModalType("");
                      }, 200);
                    }}
                  >
                    Save
                  </button>
                </div>
              </aside>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>
          <Modal.Title>Set a Reminder</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="inputFieldDiv gap-3">
            <div className="inputFieldDivLeft">
              <p className="mb-0">Select a Date</p>
              <input
                type="date"
                name="remindDate"
                className="leadInputFieldModal"
                value={
                  existingTask || existingClone
                    ? convertDateFormat(taskUpdateInfo.remindDate)
                    : convertDateFormat(taskInfo.remindDate)
                }
                min={todayDate}
                onChange={handleInputFieldChange}
                onKeyDown={(e) => e.preventDefault()}
              />
              <p className="mb-0 text-danger">{showSelectDateMsg}</p>
            </div>
            <div className="inputFieldDivRight">
              <p className="mb-0">
                Select a Time
                <span className="text-danger">*</span>
              </p>
              <Select
                options={timeArray}
                styles={customSelectLocationStyles}
                value={
                  existingTask || existingClone
                    ? {
                        label: taskUpdateInfo.remindTime,
                        value: taskUpdateInfo.remindTime,
                      }
                    : { label: taskInfo.remindTime, value: taskInfo.remindTime }
                }
                onChange={(selectedOption) =>
                  handleChangeSelectDropDown(selectedOption.value, "remindTime")
                }
                isSearchable={false}
              />
            </div>
          </div>
          <p
            className="mb-0 text-danger small text-end"
            style={{ height: "1rem" }}
          >
            {showSelectTimeMsg}
          </p>
          <div className="inputFieldDiv">
            <div className="inputFieldDivLeft">
              <p className="mb-0">
                Notify
                <span className="text-danger">*</span>
              </p>
              <Select
                options={notifyOptions}
                styles={customSelectLocationStyles}
                value={
                  existingTask || existingClone
                    ? taskUpdateInfo.notify
                    : taskInfo.notify
                }
                onChange={(selectedOption) =>
                  handleChangeSelectDropDown(selectedOption, "notify")
                }
                isSearchable={false}
              />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => {
              // taskInfo.notify = notifyOptions[0];
              taskInfo.remindTime = "";
              taskInfo.remindDate = "";
              handleClose();
              setChecked(false);
              // setTaskInfo(taskInfo);
            }}
          >
            Cancel
          </Button>

          <Button variant="primary" onClick={handleDoneButtonClick}>
            Done
          </Button>
        </Modal.Footer>
      </Modal>
      {/* ----------------------------------------------------------------------- */}
      <Modal
        show={showRepeat}
        onHide={handleRepeatClose}
        backdrop="static"
        keyboard={false}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>
          <Modal.Title>Repeat</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="inputFieldDiv gap-2">
            <div className="inputFieldDivLeft">
              <p className="mb-0">
                Repeat Type
                <span className="text-danger">*</span>
              </p>
              <Select
                options={repeatOptions}
                styles={customSelectLocationStyles}
                value={taskInfo.repeatType}
                onChange={(selectedOption) =>
                  handleChangeSelectDropDown(selectedOption, "repeatType")
                }
                isSearchable={false}
              />
            </div>
            <div className="inputFieldDivRight">
              <p className="mb-0">
                Ends On
                <span className="text-danger">*</span>
              </p>
              <div className="d-flex gap-2 w-100">
                <input
                  type="radio"
                  name="endsOn"
                  value="Never"
                  onClick={() => {
                    setEndOn("Never");
                  }}
                  checked={endOn === "Never"}
                />
                <span
                  className="d-flex align-items-center"
                  style={{ width: "40px" }}
                >
                  Never
                </span>
              </div>
              <div className="d-flex gap-2 w-100">
                <input
                  type="radio"
                  name="endsOn"
                  value="After"
                  onClick={() => {
                    setEndOn("After");
                  }}
                />
                <span
                  className="d-flex align-items-center"
                  style={{ width: "40px" }}
                >
                  After
                </span>
                <input
                  type="text"
                  pattern="[0-9]*"
                  maxLength={1}
                  className="leadInputFieldModal defaultNumberInput"
                  style={{ width: "10%" }}
                  value={taskInfo.repeatEndTimes}
                  name="repeatEndTimes"
                  onChange={handleInputFieldChange}
                  onKeyDown={(e) => {
                    if (
                      !/\d/.test(e.key) &&
                      e.key !== "Backspace" &&
                      e.key !== "Delete" &&
                      e.key !== "ArrowLeft" &&
                      e.key !== "ArrowRight"
                    ) {
                      e.preventDefault();
                    }
                  }}
                />
                <span className="d-flex align-items-center">Times</span>
              </div>
              <div className="d-flex gap-2 w-100">
                <input
                  type="radio"
                  name="endsOn"
                  value="On"
                  onClick={() => {
                    setEndOn("On");
                  }}
                />
                <span
                  className="d-flex align-items-center"
                  style={{ width: "50px" }}
                >
                  On
                </span>
                <input
                  type="date"
                  className="leadInputFieldModal"
                  style={{ width: "100%" }}
                  value={taskInfo.repeatEndDate}
                  name="repeatEndDate"
                  min={minDate}
                  onKeyDown={(e) => e.preventDefault()}
                  onChange={handleInputFieldChange}
                />
              </div>
            </div>
          </div>
          <p className="mb-0 text-center mt-3" style={{ color: "#fa5a5a" }}>
            {repeatMsg && repeatMsg}
          </p>
          {/* <div className="inputFieldDiv">
            <div className="inputFieldDivLeft">
              <p className="mb-0">Notify</p>
              <Select
                options={notifyOptions}
                styles={customSelectLocationStyles}
                value={selectedNotifyOption}
                onChange={handleNotifyChange}
              />
            </div>
          </div> */}
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => {
              taskInfo.repeatType = repeatOptions[0];
              taskInfo.repeatEndDate = "";
              taskInfo.repeatEnd = "";
              handleRepeatClose();
              setRepeatChecked(false);
            }}
          >
            Cancel
          </Button>
          <Button
            variant="primary"
            onClick={() => {
              if (endOn === "After") {
                if (taskInfo.repeatEndTimes === "") {
                  setRepeatMsg("Repeat end times is Mandatory");
                  setTimeout(() => {
                    setRepeatMsg("");
                  }, 3000);
                } else {
                  handleRepeatClose();
                  setRepeatChecked(true);
                }
              } else if (endOn === "On") {
                if (taskInfo.repeatEndDate === "") {
                  setRepeatMsg("Repeat end date is Mandatory");
                  setTimeout(() => {
                    setRepeatMsg("");
                  }, 3000);
                } else {
                  handleRepeatClose();
                  setRepeatChecked(true);
                }
              } else {
                handleRepeatClose();
                setRepeatChecked(true);
              }
            }}
          >
            Done
          </Button>
        </Modal.Footer>
      </Modal>
      {/* ---------------------- */}

      <Modal
        show={deleteModal}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
      >
        <Modal.Header
          style={{
            fontSize: "20px",
            fontWeight: "bold",
            background:
              "linear-gradient(to right, rgba(64, 123, 255, 1), rgba(18, 167, 254, 1))",
            WebkitBackgroundClip: "text",
            backgroundClip: "text",
            WebkitTextFillColor: "transparent",
          }}
        >
          Are you sure you want to delete&nbsp;
          <span>{taskOverviewDetails.subject}</span>?
        </Modal.Header>
        <Modal.Body className="ModalBody overflow-auto">
          <div className="d-flex">
            <p className="mb-0 fw-bold">Note :&nbsp;</p>
            <span>
              Any associated activities, visits, drafts will also be deleted.
            </span>
          </div>
          <div className="d-flex flex-column gap-4">
            <div className="d-flex justify-content-end w-100 gap-4">
              <button
                className="cancelButton"
                onClick={() => setDeleteModal(false)}
              >
                Cancel
              </button>
              <button
                className="createContactButtonSave"
                onClick={() => {
                  deleteAccountDetails();
                }}
              >
                Delete
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      {/* mass delete modal */}
      <Modal
        show={[
          "delete",
          "Mass Delete",
          "Mass Update",
          "Mass Transfer",
        ].includes(modalType)}
        aria-labelledby="contained-modal-title-vcenter"
        onClick={(e) => e.stopPropagation()}
        // size={modalType !== "delete" ? "md" : "lg"}
      >
        <Modal.Header
          className=" border-0 mb-0 d-flex flex-column align-items-start"
          style={{ backgroundColor: modalType !== "delete" && "#F2F2F2" }}
        >
          {modalType !== "delete" && (
            <div
              className={`mass_heading fs-4 ${
                modalType !== "Mass Delete" && "ps-4"
              }`}
            >
              {modalType}
            </div>
          )}
          {modalType === "delete" && (
            <div className="fs-5" style={{ color: "#3F7DFF" }}>
              Are you sure you want to delete
              {/* {taskOverviewDetails?.leadName}? */}
            </div>
          )}
        </Modal.Header>
        <Modal.Body className="ModalBody">
          {modalType === "delete" ? (
            <>
              <div className="d-flex gap-2 pb-2 align-items-center">
                <span className="fw-bold">Note:</span>
                <span className="small pt-1">
                  Any associated activities, visits, drafts will also be
                  deleted.
                </span>
              </div>
            </>
          ) : modalType === "Mass Delete" ? (
            <div className="fs-6 pt-1 pb-2">
              Are you sure you want to delete selected item(s)?
            </div>
          ) : modalType === "Mass Update" ? (
            <div className="d-flex gap-2 mb-3 mt-2 align-items-center w-100">
              <div className="w-100">
                <div className="d-flex flex-column ps-1 align-items-center mx-4">
                  <label className="text-start w-100 opacity-75">
                    Select a field
                  </label>
                  <div
                    className="position-relative w-100 rounded-1"
                    style={{ border: "1px solid rgba(185, 185, 185, 0.5)" }}
                  >
                    <div
                      onClick={(e) => {
                        e.stopPropagation();
                        setListVisible(!listVisible);
                      }}
                      className={`cursorPointer d-flex justify-content-between w-100 my-1 p-1 border-0`}
                    >
                      <span className="">{selectedObj.name}</span>
                      <img
                        src={listVisible ? up : down}
                        alt="img"
                        height={20}
                        width={20}
                        className="pt-1"
                      />
                    </div>
                    {listVisible && (
                      <div
                        className="position-absolute field_container w-100"
                        onClick={(e) => {
                          e.stopPropagation();
                        }}
                      >
                        <div className="">
                          <label
                            htmlFor="selectField"
                            className="position-relative w-100"
                          >
                            <input
                              id="selectField"
                              type="text"
                              className="rounded-2 p-1 w-100 pe-4"
                              placeholder="Search"
                              aria-label="Username"
                              aria-describedby="basic-addon1"
                              value={searchField}
                              onChange={(e) => {
                                const value = e.target.value.replace(
                                  /[^a-zA-Z]/g,
                                  ""
                                );
                                setSearchField(value);

                                const filteredData = tasksColumn.filter(
                                  (field) =>
                                    field.name
                                      .toLowerCase()
                                      .includes(value.trim().toLowerCase())
                                );
                                setShowSearchFields(
                                  /^[a-zA-Z]+$/.test(value)
                                    ? filteredData
                                    : fieldLabels
                                );
                              }}
                            />
                            <img
                              className="position-absolute mt-1 me-2 end-0 pt-1 cursorPointer"
                              onClick={() => {
                                setSearchField("");
                              }}
                              src={searchIcon}
                              alt="search"
                              width={20}
                            />
                          </label>

                          <div className="w-100 rounded-2">
                            <div className="border border-1 field_container_dropdown_tasks rounded-1">
                              {showSearchFields.map(
                                (field) =>
                                  ![
                                    "Subject",
                                    "Related To",
                                    "Related Module",
                                    "Relation To",
                                    "Relation Module",
                                    "Contact Name",
                                    "Task Owner",
                                    "Tag",
                                    "Created Date",
                                    "Created By",
                                    "Modified By",
                                    "Modified Time",
                                    "Closed Time",
                                    "Description",
                                    "Hash",
                                  ].includes(field.name) && (
                                    <div
                                      key={field.name}
                                      className="d-flex gap-2 p-2 cursorPointer rounded-2 align-items-center"
                                      onClick={() => {
                                        setMassUpdateValue("");
                                        setSelectedObj(field);
                                        setListVisible(false);
                                      }}
                                    >
                                      <span className="ps-2">{field.name}</span>
                                    </div>
                                  )
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="w-100 mx-auto d-flex justify-content-center mt-4">
                    <div className="w-100">
                      <label className="opacity-75" htmlFor="massValue">
                        Enter value
                      </label>
                      <div
                        className="position-relative w-100 border rounded-1"
                        style={{ border: "1px solid rgba(185, 185, 185, 0.5)" }}
                      >
                        {["Status", "Priority", "Task Owner"].includes(
                          selectedObj.name
                        ) ? (
                          <Select
                            value={clickedDropdownObj}
                            menuPlacement="auto"
                            onChange={(selectedOption) => {
                              setClickedDropdownObj(selectedOption);
                              setMassUpdateValue(selectedOption.value);
                            }}
                            options={
                              selectedObj.name === "Status"
                                ? statusOptions
                                : selectedObj.name === "Priority"
                                ? priorityOptions
                                : priorityOptions
                            }
                            styles={fieldDropdownStyles}
                            isSearchable={false}
                          />
                        ) : (
                          <input
                            className="w-100 border-0 py-2 rounded-1 px-2"
                            type="date"
                            value={massUpdateValue}
                            onChange={(e) => {
                              setMassUpdateValue(e.target.value);
                            }}
                            style={{ outline: "none" }}
                            id="massValue"
                            min={validMinDate}
                            max={validMaxDate}
                            onKeyDown={(e) => e.preventDefault()}
                          />
                        )}
                      </div>
                      {errorMessageTask && (
                        <span className="text-danger text-center pt-1">
                          {errorMessageTask}
                        </span>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="w-100 px-4 py-2 mb-1">
              <label className="text-start w-100 opacity-75">Transfer To</label>
              <div
                className="d-flex input-with-clear position-relative mx-auto"
                onClick={(e) => {
                  handleOwnerInputClick(e);
                }}
              >
                <input
                  type="text"
                  className="ps-3 w-100 cursorPointer py-2"
                  value={selectedItem?.name}
                  readOnly
                />
                <span
                  className="position-absolute h-100 mass_transfer_user pt-1"
                  style={{ right: "0.1rem" }}
                >
                  <span
                    className="cursorPointer px-1"
                    onClick={() => setIsLeadOwnerModal(!isLeadOwnerModal)}
                  >
                    <img
                      src={userSearch}
                      alt="img"
                      width={20}
                      height={20}
                      className="ms-2 mt-2 me-2"
                    />
                  </span>
                </span>
              </div>
            </div>
          )}

          <div className={`pt-4  ${modalType !== "delete" && "pe-3"}`}>
            <div className="d-flex justify-content-end w-100 gap-4 pe-2">
              <button
                className="cancelButton px-4"
                onClick={() => {
                  setShowModalType("");
                  // setSelectedCheckboxes([]);
                  setShowSearchFields(fieldDropdowns);
                }}
              >
                Cancel
              </button>
              <button
                className="createContactButtonSave"
                onClick={() => {
                  if (modalType === "delete") {
                    deleteAccountDetails();
                  } else {
                    //  else if (modalType === "Mass Delete") {
                    //   deleteUpdateTransferMass();
                    // } else {
                    deleteUpdateTransferMass();
                  }
                }}
              >
                {modalType === "Mass Update"
                  ? "Update"
                  : modalType === "Mass Transfer"
                  ? "Done"
                  : "Delete"}
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <ChangeOwnerModal
        modalType={modalType}
        setShowModalType={setShowModalType}
        selectedOwnerItem={selectedOwnerItem}
        handleChangeOwnerInputClick={handleChangeOwnerInputClick}
        setSelectedOwnerItem={setSelectedOwnerItem}
        isLeadOwnerModal={isLeadOwnerModal}
        setIsLeadOwnerModal={setIsLeadOwnerModal}
        isChangeOwnerList={isChangeOwnerList}
        userList={userList}
        handleChangeOwnerItemClick={handleChangeOwnerItemClick}
        emailNotification={emailNotification}
        setEmailNotification={setEmailNotification}
        handleEditClick={handleEditClick}
        handleUpdateClick={handleUpdateClick}
        setUpdatedFields={setUpdatedFields}
        existingContact={existingTask}
        handleUpdateContact={handleUpdateTask}
        onSelectedChangeOwner={handleSelectedLeadChange}
        apiLoader={apiLoader} // for lottie loader
      />

      {/* ---------------------- */}
      <LeadOwnerModal
        isLeadOwnerModal={isLeadOwnerModal}
        setIsLeadOwnerModal={setIsLeadOwnerModal}
        onSelectedLeadChange={handleSelectedLeadChange}
        defaultUser={selectedItem}
      />

      <AccessModal
        setShowAccessModal={setShowAccessModal}
        showAccessModal={showAccessModal}
      />
      <NotificationContainer />
    </div>
  );
}

export default Tasks;

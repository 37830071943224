import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import SearchIcon from "../Assets/search.svg";
import logoConvert from "../Assets/logoConvert.svg";

function AccountListModal({
  isAccountListModal,
  setIsAccountListModal,
  onSelectedLeadChange,
  defaultUser,
  allAccountDetails,
  type, // Add a type prop
}) {
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedAccount, setSelectedAccount] = useState("");

  const handleSearchChange = (e) => {
    const { value } = e.target;
    if (value.trim() !== "" || value === "") {
      setSearchQuery(value);
    }
  };

  useEffect(() => {
    setSearchQuery("");
    setSelectedAccount(
      defaultUser || {
        dealName: "",
        dealId: "",
      }
    );
  }, [isAccountListModal]);

  const filteredUserList = allAccountDetails.filter((item) =>
    item[type === "account" ? "accountName" : "campaignName"]
      ?.toLowerCase()
      .includes(searchQuery?.toLowerCase())
  );

  return (
    <div className="OwnerModal">
      <Modal
        show={isAccountListModal}
        className="d-flex justify-content-center align-items-center"
        aria-labelledby="example-custom-modal-styling-title"
        size="lg"
        id="commonLeadModal"
      >
        <Modal.Body className="ModalBody overflow-auto">
          <div
            className="modalUS row justify-content-center"
            style={{ margin: "0", gap: "10px", paddingBottom: "10px" }}
          >
            <div className="header_Container d-flex justify-content-between">
              <h4 className="commonTextColor pt-2">
                {type === "account" ? "Choose Account" : "Choose Campaign"}
              </h4>
              <div className="inputContainer d-flex justify-content-start">
                <input
                  type="text"
                  placeholder="Search"
                  className="topBarModalSearch px-5"
                  onChange={handleSearchChange}
                  value={searchQuery}
                />
                <img src={SearchIcon} alt="search" className="searchIcon" />
              </div>
            </div>

            <div className="Owner_table_container border border-dark rounded-2 h-25">
              <div className="table-wrapper">
                <table className="userdata-table">
                  <thead>
                    <tr>
                      {type === "account" ? (
                        <>
                          <th>Account Name</th>
                          <th>Phone</th>
                          <th>Website</th>
                          <th>Account Owner</th>
                        </>
                      ) : (
                        <>
                          <th>Campaign Name</th>
                          <th>Campaign Type</th>
                          <th>Campaign Owner</th>
                          <th>Start Date</th>
                          <th>End Date</th>
                        </>
                      )}
                    </tr>
                  </thead>
                  <tbody className="table-body tableUserData">
                    {filteredUserList &&
                      filteredUserList.length > 0 &&
                      filteredUserList?.map((item, index) => (
                        <tr
                          key={index}
                          onClick={() => {
                            setSelectedAccount(item);
                          }}
                        >
                          {type === "account" ? (
                            <>
                              <td>{item.accountName || "--"}</td>
                              <td>{item.phone || "--"}</td>
                              <td>{item.website || "--"}</td>
                              <td>{item.accountOwner || "--"}</td>
                            </>
                          ) : (
                            <>
                              <td>{item.campaignName}</td>
                              <td>{item.campaignType}</td>
                              <td>{item.campaignOwner}</td>
                              <td>{item.startDate}</td>
                              <td>{item.endDate}</td>
                            </>
                          )}
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            </div>
            <div className="d-flex pb-2 pt-3 justify-content-between">
              <div className="selectedUser d-flex gap-1">
                <span>
                  Selected {type === "account" ? "Account" : "Campaign"}
                </span>
                <img src={logoConvert} alt="img" height={20} width={20} />
                <span>
                  {type === "account"
                    ? selectedAccount.accountName
                    : selectedAccount.campaignName}
                </span>
              </div>
              <div className="d-flex gap-2">
                <button
                  className="btn btn-outline-secondary border border-primary btn-sm px-3 py-2 text-dark"
                  onClick={() => setIsAccountListModal(false)}
                >
                  Cancel
                </button>
                <button
                  className="btn btnColor btn-sm px-3 text-white"
                  onClick={() => {
                    setIsAccountListModal(false);
                    onSelectedLeadChange(selectedAccount);
                  }}
                >
                  Done
                </button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default AccountListModal;

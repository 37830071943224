import React, { useLayoutEffect, useRef, useState } from "react";
import CanvasJSReact from "@canvasjs/react-charts";
import { getChartColor } from "../dashboardData";

const CanvasJSChart = CanvasJSReact.CanvasJSChart;

const DonutChart = ({
  component,
  deleteIcon,
  setModalType,
  setSelectedComponentId,
  currentView,
  data,
}) => {
  const chartContainerRef = useRef(null);
  const [chartDimensions, setChartDimensions] = useState({
    width: 0,
    height: 100,
  });
  const [finalData, setFinalData] = useState([]);

  useLayoutEffect(() => {
    const getData = data === undefined ? component.componentData : data;

    if (getData.length > 0) {
      const transformedData = getData.map((item) => {
        const headings = getData?.length > 0 ? Object.keys(getData[0]) : [];

        return {
          name: item[headings[1]] || "No Type",
          y: item.percentage,
          count: item.recordsCount,
        };
      });
      setFinalData(transformedData);
    }
    const handleResize = () => {
      if (chartContainerRef.current) {
        setChartDimensions({
          width: chartContainerRef.current.offsetWidth,
          height: chartContainerRef.current.offsetHeight,
        });
      }
    };

    setTimeout(handleResize, 100);

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const options = {
    animationEnabled: true,
    data: [
      {
        type: "doughnut",
        // showInLegend: currentView === "home",
        indexLabel: "{name}: {count} ({y})",
        // indexLabel: currentView === "home" ? "" : "{name}: {y}",
        yValueFormatString: "#,###'%'",
        // dataPoints: finalData,
        dataPoints: finalData.map((dataPoint, index) => ({
          ...dataPoint,
          color: getChartColor(index),
        })),
        toolTipContent: "{name}: {count} ({y})",
      },
    ],
    legend: {
      horizontalAlign: "right",
      verticalAlign: "center",
      fontSize: "10",
      itemSpacing: 60,
      padding: 10,
      maxWidth: null,
    },
  };

  return (
    <div
      className={` rounded-1 position-relative ${
        currentView === "home"
          ? "mx-2 mt-3 component_container"
          : "bg-transparent h-100 "
      }`}
    >
      {currentView === "home" && (
        <div className="component_header d-flex justify-content-between p-2">
          <span className="first_letter_uppercase fs-5">
            {component.componentName}
          </span>
        </div>
      )}
      <div className="h-100 pt-2">
        {/* <div className="component_content p-2 d-flex justify-content-center align-items-center w-100 h-100"> */}
        <div
          id="doughnutChart"
          ref={chartContainerRef}
          style={{
            width: "100%",
            // width: currentView === "home" ? "95%" : "100%",
            height: "80%",
          }}
        >
          {chartDimensions.width > 0 && chartDimensions.height > 0 && (
            <CanvasJSChart
              options={{
                ...options,
                width: chartDimensions.width,
                height: chartDimensions.height,
              }}
            />
          )}
        </div>
      </div>
      {currentView === "home" && (
        <div className="component_footer p-2 position-absolute end-0 bottom-0 mb-1">
          <span className="">
            <span
              className="deleteIcon rounded-1 p-1 cursorPointer"
              onClick={() => {
                // setSelectedComponentId("");
                setModalType("delete");
                setSelectedComponentId(component.componentId);
              }}
            >
              {deleteIcon}
            </span>
          </span>
        </div>
      )}
    </div>
  );
};

export default DonutChart;

/* eslint-disable */
import React, { useState, useEffect } from "react";
import filterIcon from "../../Assets/Frame (3).svg";
import Select from "react-select";
import { URI } from "../../constants";
import axios from "axios";
import "./calls.css";
import Pagination from "../../common/Pagination";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import {
  fieldDropdownStyles,
  massOptions,
  pageOption,
} from "../../common/CommonData";
import tableFilterIcon from "../../Assets/filterIcon.svg";
import arrowUp from "../../Assets/square_arrow_up.svg";
import arrowDown from "../../Assets/square_arrow_down.svg";
import pin from "../../Assets/Pin_column.svg";
import unPin from "../../Assets/Unpin.svg";
import cancel from "../../Assets/cancel_circle.svg";
import cancelCircle from "../../Assets/cancelCircle.svg";
import filterLine from "../../Assets/filterLine.svg";
import filterMenu from "../../Assets/filterMenu.svg";
import { useSelector } from "react-redux";
import Loader from "../../common/commonRelatedList/Loader";
import { Modal } from "react-bootstrap";
import searchIcon from "../../Assets/search.svg";
import LeadOwnerModal from "../../common/LeadOwnerModal";
import userSearch from "../../Assets/user-search-01.svg";
import down from "../../Assets/Down31.svg";
import up from "../../Assets/Up.svg";
import ContactListModal from "../../common/ContactListModal";
import LeadListModal from "../../common/LeadListModal";
import callIconSuccess from "../../Assets/Outgoing.svg";
import mailInfo from "../../Assets/MailInfoContainer.svg";
import callInfoContainer from "../../Assets/callInfoContainer.svg";
import telephoneInfo from "../../Assets/TelephoneInfoContainer.svg";
import AccountsInfoContainer from "../../Assets/AccountsInfoContainer.svg";
import moment from "moment";
import callIconIncoming from "../../Assets/Incoming.svg";
import EditableInput from "../../common/EditableInput";
import updateIcon from "../../Assets/Group 71.svg";
import cancelIcon from "../../Assets/Group 72.svg";
import noDataImage from "../../Assets/Calling-amico 1.svg";
import {
  leftArrow,
  rightAngleArrow,
  leftAngleArrow,
} from "../../common/commonSVGs/commonSVGIcons.js";
import ChangeOwnerModal from "../../common/commonRelatedList/ChangeOwnerModal.js";
import { EXPLORER_URL } from "../../constants/DataConst.js";
import {
  validateCallSubject,
  validateCallAgenda,
  removeExtraSpaces,
  customCommonStyles,
} from "../../common/CommonComponent";
import randomImage from "../../Assets/Lead_Avatar.png";
import Note from "../../common/commonRelatedList/Note.js";
import Lottie from "react-lottie";
import { defaultOptions } from "../../utils/defaultOptions.js";
import getHeaders from "../../common/ApiHeader.js";
import { handleApiError } from "../../utils/ApiUtils";
import { useNavigate } from "react-router-dom";
import SearchIcon from "../../Assets/SearchIcon.svg";
import logoConvert from "../../Assets/logoConvert.svg";

function Calls({
  setInnerPage,
  innerPage,
  permissionList,
  notePermission,
  storeModuleId,
}) {
  const navigate = useNavigate();
  const leadContactOptions = [
    { value: "Lead", label: "Lead" },
    { value: "Contact", label: "Contact" },
  ];
  const relatedToOptions = [
    { value: "ACCOUNTS", label: "Account" },
    { value: "DEALS", label: "Deal" },
    { value: "PRODUCTS", label: "Product" },
    { value: "QUOTES", label: "Quote" },
    { value: "SALES_ORDER", label: "Sales Order" },
    { value: "PURCHASE_ORDERS", label: "Purchase Order" },
    { value: "INVOICES", label: "Invoice" },
    { value: "VENDORS", label: "Vendor" },
    { value: "CAMPAIGNS", label: "Campaign" },
  ];
  const callPurposeOptions = [
    { value: "None", label: "None" },
    { value: "Prospecting", label: "Prospecting" },
    { value: "Administrative", label: "Administrative" },
    { value: "Negotiation", label: "Negotiation" },
    { value: "Demo", label: "Demo" },
    { value: "Project", label: "Project" },
    { value: "Support", label: "Support" },
  ];
  const reminderOptions = [
    { value: "None", label: "None" },
    { value: "5 minutes before", label: "5 minutes before" },
    { value: "10 minutes before", label: "10 minutes before" },
    { value: "15 minutes before", label: "15 minutes before" },
    { value: "30 minutes before", label: "30 minutes before" },
    { value: "1 hour before", label: "1 hour before" },
  ];

  const callResultOptions = [
    { value: "None", label: "None" },
    { value: "Interested", label: "Interested" },
    { value: "Not interested", label: "Not interested" },
    { value: "No response/Busy", label: "No response/Busy" },
    { value: "Requested more info", label: "Requested more info" },
    { value: "Requested call back", label: "Requested call back" },
    { value: "Invalid number", label: "Invalid number" },
  ];
  const callTypeOptions = [
    { value: "Outbound", label: "Outbound" },
    { value: "Inbound", label: "Inbound" },
    { value: "Missed", label: "Missed" },
  ];

  const callsColumn = [
    {
      name: "Subject",
      isChecked: true,
      value: "subject",
    },
    {
      name: "Call Type",
      isChecked: false,
      value: "callType",
    },
    {
      name: "Call Start Time",
      isChecked: true,
      value: "callStartTime",
    },
    {
      name: "Related To",
      isChecked: true,
      value: "relationName",
    },
    {
      name: "Related To Module",
      isChecked: true,
      value: "relationModuleName",
    },
    {
      name: "Call To",
      isChecked: true,
      value: "callToName",
    },
    {
      name: "Call To Module",
      isChecked: true,
      value: "callToModuleName",
    },
    {
      name: "Call Owner",
      isChecked: false,
      value: "callOwner",
    },
    {
      name: "Outgoing Call Status",
      isChecked: true,
      value: "outGoingCallStatus",
    },
    {
      name: "Created By",
      isChecked: false,
      value: "createdBy",
    },
    {
      name: "Modified By",
      isChecked: false,
      value: "modifiedBy",
    },
    {
      name: "Created Time",
      isChecked: false,
      value: "createdTime",
    },
    {
      name: "Modified Time",
      isChecked: false,
      value: "modifiedTime",
    },
    {
      name: "Call Duration(in seconds)",
      isChecked: false,
      value: "callDuration",
    },

    {
      name: "Call Purpose",
      isChecked: false,
      value: "callPurpose",
    },
    {
      name: "Call Agenda",
      isChecked: false,
      value: "callAgenda",
    },
    {
      name: "Call Result",
      isChecked: false,
      value: "callResult",
    },
    {
      name: "Call Choosing Type",
      isChecked: false,
      value: "callChoosingType",
    },
    {
      name: "Hash",
      isChecked: true,
      value: "transHash",
    },
  ];
  const massColumn = [
    {
      name: "Call Purpose",
      isChecked: false,
      value: "callPurpose",
    },
    {
      name: "Call Agenda",
      isChecked: false,
      value: "callAgenda",
    },
    {
      name: "Call Result",
      isChecked: false,
      value: "callResult",
    },
  ];

  const createAccountStyles = {
    menu: (base) => ({
      ...base,
      width: "100%",
    }),
    option: (provided) => ({
      ...provided,
      // backgroundColor: "#EAEAEA",
      color: "#000",
      // backgroundColor: 'rgba(33, 119, 254, 0.1)',
      width: "100%",
    }),
    control: (baseStyles) => ({
      ...baseStyles,
      border: "1px solid #b9b9b9b0",
      //   backgroundColor: "#F3F6FB",
      height: "50px",
      borderRadius: "5px",
      width: "100%",
      boxShadow: "none",
    }),
    input: (base) => ({
      ...base,
      color: "#515151",
    }),
    singleValue: (defaultStyles) => ({ ...defaultStyles, color: "#000000" }),

    menuList: (provided) => ({
      ...provided,
      paddingTop: 0,
      paddingBottom: 0,
      "&::-webkit-scrollbar": {
        width: 5,
      },

      "&::-webkit-scrollbar-thumb": {
        background: "#316AFF",
        borderRadius: "10px",
      },
    }),
  };

  const reduxStore = useSelector((state) => state.reduxState);
  const { loginUserId } = reduxStore;
  const options = reduxStore.filterOptions.call;
  // const [ setInnerPage] = useState("list");
  const [showButtons, setShowButtons] = useState(false);
  const [dropdownCallResult, setDropdownCallResult] = useState(false);
  // const [filterView, setFilterView] = useState(false);
  const filterView = false; // hence the [filterView, setFilterView]  setFilterView is not used anywhere i am using another var
  const userList = JSON.parse(sessionStorage.getItem("userList"));
  const [dropdownSelectReminder, setDropdownSelectReminder] = useState(false);
  const [dropdownRelatedTo, setDropdownRelatedTo] = useState(false);
  const [allCallDetails, setAllCallDetails] = useState([]);
  const [selectedItem, setSelectedItem] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState(0); // Total number of items
  const [perPage, setPerPage] = useState(10);
  const [callId, setCallId] = useState("");
  const [selectRecent, setSelectRecent] = useState("ALL");
  const [selectedOption, setSelectedOption] = useState(options[0]);
  // const [showAccessModal, setShowAccessModal] = useState(false); showAccessModal is not used anywhere so i am commenting
  const [rescheduleCheck, setRescheduleCheck] = useState(false);
  const [selectedOptionLead, setSelectedOptionLead] = useState(
    leadContactOptions[0]
  );
  const [selectedOptionModule, setSelectedOptionModule] = useState(
    relatedToOptions[0]
  );
  // const [dropdownTwo, setDropdownTwo] = useState(massOptions[0]); dropdownTwo is not used anywhere so i am commenting
  const [isLoading, setIsLoading] = useState(true);
  const [selectedHeadings, setSelectedHeadings] = useState(callsColumn);
  const [showHeadings, setShowHeadings] = useState(false);
  const [showSelectedFilter, setShowSelectedFilter] = useState("");
  const [filterType, setFilterType] = useState("");
  const [pinnedColumn, setPinnedColumn] = useState("Subject");
  const [filteredCallDetails, setFilteredCallDetails] = useState([]);
  const [modalType, setShowModalType] = useState("");
  const [showOwnerModalType, setShowOwnerModalType] = useState("");
  const [listVisibleType, setListVisibleType] = useState("");
  // const [showOptionsContact, setShowOptionsContact] = useState(false); showOptionsContact is not used anywhere so i am commenting
  const [selectedCheckboxes, setSelectedCheckboxes] = useState([]);
  const [searchColumn, setSearchColumn] = useState("");
  const [savingHeadings, setSavingHeadings] = useState(callsColumn);
  const [modalView, setModalView] = useState("ScheduleCall");
  const [listVisible, setListVisible] = useState(false);
  const [isLeadOwnerModal, setIsLeadOwnerModal] = useState(false);
  const [selectedAccount, setSelectedAccount] = useState({});
  const [selectedContact, setSelectedContact] = useState({});
  const [selectedDeal, setSelectedDeal] = useState({});
  const [selectedQuote, setSelectedQuote] = useState({});
  const [contactList, setContactList] = useState([]);
  const [productList, setProductList] = useState([]);
  const [leadList, setLeadList] = useState([]);
  const [isContactListModal, setIsContactListModal] = useState(false);
  const [isLeadListModal, setIsLeadListModal] = useState(false);
  const [selectedLeadItem, setSelectedLeadItem] = useState(leadList);
  const [vendorList, setVendorList] = useState([]);
  const [selectedVendorItem, setSelectedVendorItem] = useState(vendorList);
  const [selectedProductItem, setSelectedProductItem] = useState(productList);
  const [invoiceList, setInvoiceList] = useState([]);
  const [selectedInvoiceItem, setSelectedInvoiceItem] = useState(invoiceList);
  const [salesList, setSalesList] = useState([]);
  const [selectedSalesItem, setSelectedSalesItem] = useState(salesList);
  const [purchaseList, setPurchaseList] = useState([]);
  const [selectedPurchaseItem, setSelectedPurchaseItem] =
    useState(purchaseList);
  const [selectedCallId, setSelectedCallId] = useState(0);
  const [callDetails, setCallDetails] = useState({});
  const [infoDetails, setInfoDetails] = useState({});
  const [openActivities, setOpenActivities] = useState([]);
  const [timeLineDataList, setTimeLineDataList] = useState([]);
  const [deleteModal, setDeleteModal] = useState("");
  const [editPurchaseOverview, setEditPurchaseOverview] = useState(false);
  const [showType, setShowType] = useState("");
  // const [isLeadForm, setIsLeadForm] = useState(false); i am commenting this hence isLeadForm is not used anywhere
  const [moduleWiseData, setModuleWiseData] = useState([]);
  const [selectedModuleItem, setSelectedModuleItem] = useState(moduleWiseData);
  const [moduleListVisible, setModuleListVisible] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [selectedOwnerItem, setSelectedOwnerItem] = useState(userList);
  const [isChangeOwnerList, setIsChangeOwnerList] = useState(false);
  // const [isOwnerSelect, setIsOwnerSelect] = useState(false); i am commenting this hence isOwnerSelect is not used anywhere
  const [emailNotification, setEmailNotification] = useState(true);
  const [mandatoryCallMsg, setMandatoryCallMsg] = useState("");
  const [overlapMessage, setOverlapMessage] = useState("");
  const [overlapModal, setOverlapModal] = useState(false);
  const [editNote, setEditNote] = useState(false);
  const [dropdownSelect, setDropdownSelect] = useState(false);
  const [dropdownCallPurpose, setDropdownCallPurpose] = useState(false);
  const [deletedImg, setDeletedImg] = useState("");

  const [currentPageContact, setCurrentPageContact] = useState(1);
  const [perPageContact, setPerPageContact] = useState(10);
  const [totalItemsContact, setTotalItemsContact] = useState(0);
  const [modalContactDetails, setModalContactDetails] = useState([]);
  const [searchContact, setSearchContact] = useState("");

  const [currentPageLead, setCurrentPageLead] = useState(1);
  const [perPageLead, setPerPageLead] = useState(10);
  const [totalItemsLead, setTotalItemsLead] = useState(0);
  const [modalLeadDetails, setModalLeadDetails] = useState([]);
  const [searchLead, setSearchLead] = useState("");
  const [noteDeleteConfirmation, setNoteDeleteConfirmation] = useState(false);

  const totalPagesContact = Math.ceil(
    Number(totalItemsContact) / perPageContact
  );

  const totalPagesLead = Math.ceil(Number(totalItemsLead) / perPageLead);

  const filteredContactDetails = modalContactDetails.filter((item) =>
    item.contactName?.toLowerCase().includes(searchContact?.toLowerCase())
  );

  const filteredLeadDetails = modalLeadDetails.filter((item) =>
    item.leadName?.toLowerCase().includes(searchLead?.toLowerCase())
  );

  const hadlePageChangeContact = (page) => {
    setCurrentPageContact(page);
  };

  const hadlePageChangeLead = (page) => {
    setCurrentPageLead(page);
  };

  const handlePageDropDownChangeContact = (selectedOption) => {
    setCurrentPageContact(1);
    setPerPageContact(parseInt(selectedOption.value));
  };

  const handlePageDropDownChangeLead = (selectedOption) => {
    setCurrentPageLead(1);
    setPerPageLead(parseInt(selectedOption.value));
  };

  const handleSearchChangeContact = (e) => {
    const { value } = e.target;
    if (value.trim() !== "" || value === "") {
      setSearchContact(value);
    }
  };

  const handleSearchChangeLead = (e) => {
    const { value } = e.target;
    if (value.trim() !== "" || value === "") {
      setSearchLead(value);
    }
  };

  const [noteInfo, setNoteInfo] = useState([]);
  const [updateNotes, setUpdateNotes] = useState({
    description: "",
    title: "",
    noteImageList: [],
  });
  const fieldDropdowns = [
    {
      name: "Call To",
      type: "nonEditable",
      value: "callToName",
    },
    {
      name: "Related to",
      type: "nonEditable",
      value: "relationName",
    },
    {
      name: "Call Type",
      type: "nonEditable",
      value: "callType",
    },
    {
      name: "Outgoing Call Status",
      type: "nonEditable",
      value: "outGoingCallStatus",
    },
    {
      name: "Call Start Date",
      type: "nonEditable",
      value: "callStartDate",
    },
    {
      name: "Call Start Time",
      type: "nonEditable",
      value: "callStartTime",
    },
    {
      name: !callDetails.callDuration ? "Call Owner" : "Call Duration",
      type: !callDetails.callDuration ? "select" : "nonEditable",
      value: !callDetails.callDuration ? "callOwner" : "callDuration",
    },
    {
      name: "Subject",
      type: "input",
      value: "subject",
    },
    {
      name: "Created By",
      type: "readOnly",
      value: "createdBy",
    },
    {
      name: "Modified By",
      type: "readOnly",
      value: "modifiedBy",
    },
  ];

  const handleModuleClick = (e) => {
    e.stopPropagation();
    setListVisible(false);
    setModuleListVisible(!moduleListVisible);
  };

  useEffect(() => {
    if (moduleWiseData.length > 0) {
      setSelectedModuleItem(moduleWiseData[0]);
    }
  }, [moduleWiseData]);

  const handleModuleItemClick = (item) => {
    setSelectedModuleItem(item);
    setModuleListVisible(false);
  };
  const [callStartTime, setCallStartTime] = useState({
    hour: "00",
    minutes: "00",
    meridiem: "AM",
  });
  const [showCallStartTime, setShowCallStartTime] = useState({
    hour: false,
    minutes: false,
  });
  const [rescheduleTime, setRescheduleTime] = useState({
    hour: "00",
    minutes: "00",
    meridiem: "AM",
  });
  const [showRescheduleTime, setShowRescheduleTime] = useState({
    hour: false,
    minutes: false,
  });
  const [callInfo, setCallInfo] = useState({
    loggedInUserId: loginUserId,
    choosingType: "",
    leadId: "",
    callType: callTypeOptions[0],
    outGoingCallStatus: "",
    callStartDate: new Date().toISOString().split("T")[0],
    rescheduleDate: "",
    callStartTime: "",
    callOwnerId: "",
    subject: "",
    reminder: reminderOptions[0],
    callPurpose: callPurposeOptions[0],
    callAgenda: "",
    callDuration: "00:00",
    callResult: callResultOptions[0],
    description: "",
    modSearchName: relatedToOptions[0],
    modSearchId: "",
    minutes: 0,
    seconds: 0,
    noteTitle: "",
    noteDescription: "",
  });

  const [editingFields, setEditingFields] = useState({
    subject: false,
    callPurpose: false,
    callAgenda: false,
    callDuration: false,
    callResult: false,
    description: false,
    modSearchName: false,
    modSearchId: false,
  });

  const [apiLoader, setApiLoader] = useState(false);
  const [createLoader, setCreateLoader] = useState(false);

  const callInformation = {
    loggedInUserId: loginUserId,
    choosingType: "",
    leadId: "",
    callType: callTypeOptions[0],
    outGoingCallStatus: "",
    callStartDate: new Date().toISOString().split("T")[0],
    callStartTime: "",
    callOwnerId: "",
    subject: "",
    reminder: reminderOptions[0],
    callPurpose: callPurposeOptions[0],
    callAgenda: "",
    callDuration: "0:0",
    callResult: callResultOptions[0],
    description: "",
    modSearchName: relatedToOptions[0],
    modSearchId: "",
    minutes: 0,
    seconds: 0,
    noteTitle: "",
    noteDescription: "",
  };

  const hours = Array.from({ length: 12 }, (_, i) =>
    String(i + 1).padStart(2, "0")
  );
  const minutes = Array.from({ length: 12 }, (_, i) =>
    String(i * 5).padStart(2, "0")
  );

  const handleChangeCalls = (e) => {
    const { name, value } = e.target;
    if (
      (name === "subject" || name === "callAgenda" || name === "description") &&
      value.startsWith(" ")
    ) {
      return;
    }

    if (name === "minutes") {
      if (parseFloat(value) < 0 || parseFloat(value) > 999) {
        return;
      }
    }

    if (name === "seconds") {
      if (parseFloat(value) < 0 || parseFloat(value) > 59) {
        return;
      }
      // .replace(/\D/g, "")
    }
    setCallInfo((prevCallsInfo) => ({
      ...prevCallsInfo,
      [name]: ["seconds", "minutes"].includes(name)
        ? value.replace(/\D/g, "")
        : value,
    }));

    if (name === "minutes" && value.trim() === "") {
      setCallInfo((prevCallsInfo) => ({
        ...prevCallsInfo,
        minutes: 0,
      }));
    }
    if (name === "seconds" && value.trim() === "") {
      setCallInfo((prevCallsInfo) => ({
        ...prevCallsInfo,
        seconds: 0,
      }));
    }
  };

  const handleFocus = (e) => {
    const { name } = e.target;
    if (e.target.value === "0") {
      setCallInfo((prevCallsInfo) => ({
        ...prevCallsInfo,
        [name]: "",
      }));
    }
  };

  const handleBlur = (e) => {
    const { name, value } = e.target;
    if (value.trim() === "") {
      setCallInfo((prevCallsInfo) => ({
        ...prevCallsInfo,
        [name]: "0",
      }));
    }
  };

  const handleDeleteCalls = () => {
    setDeleteModal("DeleteCalls"); // Update the selected lead value
  };

  const handleSelectedLeadChange = (value) => {
    setSelectedItem(value); // Update the selected lead value
  };

  const handleChangeOwnerInputClick = (e) => {
    e.stopPropagation();
    setIsChangeOwnerList(!isChangeOwnerList);
  };

  const handleChangeOwnerItemClick = (item) => {
    setSelectedOwnerItem(item);
    setIsChangeOwnerList(false);
    // setIsOwnerSelect(true);
  };

  const handleSelectActiveTab = (value) => {
    setActiveTab(value); // Update the selected lead value
  };

  const handleInputClick = (type) => {
    setListVisible(!listVisible);
    setModuleListVisible(false);
    setListVisibleType(type);
  };

  const handleChangeSelectDropDown = (selectedOption, field) => {
    setCallInfo((prevLeadInfo) => ({
      ...prevLeadInfo,
      [field]: selectedOption,
    }));
  };

  const handleSelectedContactListChange = (value) => {
    setSelectedContact(value); // Update the selected lead value
  };

  const handleSelectedLeadListChange = (value) => {
    setSelectedLeadItem(value);
  };

  useEffect(() => {
    if (storeModuleId) {
      callsOverview(storeModuleId);
      setCallId(storeModuleId);
    }
  }, []);

  const getAllAssignModules = async (main, sub, id) => {
    const requestBody = {
      loginUserId: loginUserId,
      mainModule: main,
      subModule: sub,
      moduleId: id,
    };

    const headersResult = getHeaders(true);

    // Check if headers result indicates an error
    if (headersResult.error) {
      navigate("../"); // Redirect to login if token is missing
      return;
    }

    await axios
      .post(URI.moduleWiseAssign, requestBody, {
        headers: headersResult,
      })
      .then((response) => {
        if (response.data.status === 200) {
          setModuleWiseData(response.data.data.assignModulewiseDetails);
        }
        setIsContactListModal(false);
      })
      .catch(async (error) => {
        handleApiError(error, navigate);
      });
  };

  function compareDateTimeToCurrent(dateString, timeString) {
    var currentDate = new Date();
    var dateParts = dateString.match(/(\d{2})-(\d{2})-(\d{4})/);
    var day = parseInt(dateParts[1]);
    var month = parseInt(dateParts[2]) - 1;
    var year = parseInt(dateParts[3]);
    var timeParts = timeString.match(/(\d{2}):(\d{2}) (AM|PM)/);

    if (!timeParts) {
      return "Invalid time format";
    }

    var hours = parseInt(timeParts[1]);
    var minutes = parseInt(timeParts[2]);
    var period = timeParts[3];

    if (period === "PM" && hours !== 12) {
      hours += 12;
    } else if (period === "AM" && hours === 12) {
      hours = 0;
    }

    var inputDateTime = new Date(year, month, day, hours, minutes);

    if (inputDateTime > currentDate) {
      return true;
    }
    // else if (inputDateTime < currentDate) {
    //     return "Input date and time are less than current date and time";
    // }
    else {
      return false;
    }
  }

  const handleCheckCalls = async (e) => {
    let isValidForm = true;

    const setErrorMessage = (setter, message, timeout = 5000) => {
      setter(message);
      setTimeout(() => {
        setter("");
      }, timeout);
      isValidForm = false;
    };

    if (!rescheduleCheck) {
      if (callInfo.subject === "") {
        setErrorMessage(setMandatoryMsg, "subject name cannot be empty.", 5000);
      }

      //code
      if (selectedOptionLead.value === "Contact" && contactList.length <= 0) {
        setErrorMessage(
          setMandatoryMsgLeadContact,
          "Please Create a contact",
          5000
        );
      } else if (selectedOptionLead.value === "Lead" && leadList.length <= 0) {
        setErrorMessage(
          setMandatoryMsgLeadContact,
          "Please Create a Lead",
          5000
        );
      }

      if (
        modalView === "ScheduleCall" &&
        !compareDateTimeToCurrent(
          moment(new Date(callInfo.callStartDate)).format("DD-MM-YYYY"),
          `${callStartTime.hour}:${callStartTime.minutes} ${callStartTime.meridiem}`
        )
      ) {
        setErrorMessage(
          setMandatoryMsgTime,
          "Please enter a time that is later than the current time.",
          5000
        );
      } else if (
        modalView !== "ScheduleCall" &&
        compareDateTimeToCurrent(
          moment(new Date(callInfo.callStartDate)).format("DD-MM-YYYY"),
          `${callStartTime.hour}:${callStartTime.minutes} ${callStartTime.meridiem}`
        )
      ) {
        setErrorMessage(
          setMandatoryMsgTime,
          "Please enter a time that is before than the current time.",
          5000
        );
      }
    } else {
      if (
        modalType === "RescheduleCall" &&
        !compareDateTimeToCurrent(
          moment(new Date(callInfo.rescheduleDate)).format("DD-MM-YYYY"),
          `${rescheduleTime.hour}:${rescheduleTime.minutes} ${rescheduleTime.meridiem}`
        )
      ) {
        setErrorMessage(
          setMandatoryMsgTime,
          "Please enter a time that is later than the current time.",
          5000
        );
      }
      if (callInfo.noteTitle === "") {
        setErrorMessage(
          setMandatoryMsgNoteTitle,
          "Please enter note title.",
          5000
        );
      }
      if (callInfo.noteDescription === "") {
        setErrorMessage(
          setMandatoryNoteDesc,
          "Please enter note description.",
          5000
        );
      }
    }

    if (!isValidForm) return;

    if (callInfo.callAgenda && !validateCallAgenda(callInfo.callAgenda)) {
      setMandatoryCallMsg("Please enter a valid agenda.");
      setTimeout(() => {
        setMandatoryCallMsg("");
      }, 5000);
    } else {
      const requestBody = {
        moduleName: "calls",
        loggedInUserId: loginUserId,
        fromDate: rescheduleCheck
          ? moment(new Date(callInfo.rescheduleDate)).format("DD-MM-YYYY")
          : moment(new Date(callInfo.callStartDate)).format("DD-MM-YYYY"),
        fromTime: rescheduleCheck
          ? `${rescheduleTime.hour}:${rescheduleTime.minutes} ${rescheduleTime.meridiem}`
          : `${callStartTime.hour}:${callStartTime.minutes} ${callStartTime.meridiem}`,
      };

      setApiLoader(true);
      const headersResult = getHeaders(true);

      // Check if headers result indicates an error
      if (headersResult.error) {
        navigate("../"); // Redirect to login if token is missing
        return;
      }
      await axios
        .post(URI.checkMeetingAvailable, requestBody, {
          headers: headersResult,
        })

        .then((response) => {
          if (response.data.status === 200) {
            setOverlapMessage(response.data.message);
            if (response.data.data.isScheduled) {
              setOverlapModal(true);
            } else {
              if (rescheduleCheck) {
                recheduleCancelCalls();
                setRescheduleCheck(false);
              } else {
                handleInsertCalls(e);
              }
            }
          } else {
            NotificationManager.error("", response.data.message, 5000);
          }
        })
        .catch(function (error) {
          handleApiError(error, navigate);
        })
        .finally(() => {
          setApiLoader(false);
        });
    }
  };

  const recheduleCancelCalls = async () => {
    let requestBody = {};
    let isValidForm = true;

    const setErrorMessage = (setter, message, timeout = 5000) => {
      setter(message);
      setTimeout(() => {
        setter("");
      }, timeout);
      isValidForm = false;
    };

    if (
      modalType === "RescheduleCall" &&
      !compareDateTimeToCurrent(
        moment(new Date(callInfo.rescheduleDate)).format("DD-MM-YYYY"),
        `${rescheduleTime.hour}:${rescheduleTime.minutes} ${rescheduleTime.meridiem}`
      )
    ) {
      setErrorMessage(
        setMandatoryMsgTime,
        "Please enter a time that is later than the current time.",
        5000
      );
    }
    if (callInfo.noteTitle === "") {
      setErrorMessage(
        setMandatoryMsgNoteTitle,
        "Please enter note title.",
        5000
      );
    }
    if (callInfo.noteDescription === "") {
      setErrorMessage(
        setMandatoryNoteDesc,
        "Please enter note description.",
        5000
      );
    }
    if (!isValidForm) return;

    requestBody = {
      callId: callId,
      loggedInUserId: loginUserId,
      noteTitle: callInfo.noteTitle,
      noteDescription: callInfo.noteDescription,
    };
    if (modalType === "RescheduleCall") {
      requestBody.callStartDate = moment(
        new Date(callInfo.rescheduleDate)
      ).format("DD-MM-YYYY");
      requestBody.callStartTime = `${rescheduleTime.hour}:${rescheduleTime.minutes} ${rescheduleTime.meridiem}`;
    }
    const headersResult = getHeaders(true);

    // Check if headers result indicates an error
    if (headersResult.error) {
      navigate("../"); // Redirect to login if token is missing
      return;
    }
    await axios
      .post(URI.rescheduleCancelCalls, requestBody, {
        headers: headersResult,
      })

      .then((response) => {
        if (response.data.status === 200) {
          NotificationManager.success("", response.data.message, 5000);
          setCallInfo({
            ...callInfo,
            noteDescription: "",
            noteTitle: "",
          });
          callsOverview(callId);
          setShowModalType("");
        } else {
          NotificationManager.error("", response.data.message, 5000);
        }
      })
      .catch(function (error) {
        handleApiError(error, navigate);
      });
  };

  const handleInsertCalls = async () => {
    let requestBody = {};
    const modId = selectedModuleItem.moduleId;
    let isValid = true;

    const setErrorMessage = (setter, message, timeout = 5000) => {
      setter(message);
      setTimeout(() => {
        setter("");
      }, timeout);
      isValid = false;
    };

    if (selectedOptionLead.value === "Contact" && contactList.length <= 0) {
      setErrorMessage(
        setMandatoryMsgLeadContact,
        "Please Create a contact",
        5000
      );
    } else if (selectedOptionLead.value === "Lead" && leadList.length <= 0) {
      setErrorMessage(setMandatoryMsgLeadContact, "Please Create a Lead", 5000);
    }

    if (callInfo.subject === "" || !validateCallSubject(callInfo.subject)) {
      setMandatoryMsg("Please enter a valid subject.");
      setTimeout(() => {
        setMandatoryMsg("");
      }, 3000);
      isValid = false;
    }

    if (callInfo.callAgenda && !validateCallAgenda(callInfo.callAgenda)) {
      setMandatoryCallMsg("Please enter a valid agenda.");
      setTimeout(() => {
        setMandatoryCallMsg("");
      }, 3000);
      isValid = false;
    }

    if (!isValid) {
      return;
    }

    if (modalView !== "ScheduleCall") {
      if (
        callInfo.callType.value === "Outbound" ||
        callInfo.callType.value === "Inbound"
      ) {
        requestBody = {
          ...requestBody,
          callDuration:
            editPurchaseOverview && isComplete === 0
              ? callDetails.callDuration
              : callInfo.minutes || callInfo.seconds
              ? `${callInfo.minutes}:${callInfo.seconds}`
              : callInfo.callDuration,
        };
        if (callInfo.callType.value === "Outbound") {
          requestBody = {
            ...requestBody,
            outGoingCallStatus: "Completed",
          };
        }
      }
    }

    if (modalView === "ScheduleCall") {
      if (selectedOptionLead.value === "Contact") {
        requestBody = { ...requestBody, contactId: selectedContact.contactId };
      } else {
        requestBody = { ...requestBody, leadId: selectedLeadItem.leadId };
      }
      requestBody = {
        ...requestBody,
        loggedInUserId: loginUserId,
        choosingType: "schedule_a_call",
        callType: "Outbound",
        outGoingCallStatus: "Scheduled",
        callStartDate: editPurchaseOverview
          ? callDetails.callStartDate
          : moment(new Date(callInfo.callStartDate)).format("DD-MM-YYYY"),
        callStartTime: editPurchaseOverview
          ? callDetails.callStartTime
          : `${callStartTime.hour}:${callStartTime.minutes} ${callStartTime.meridiem}`,
        callOwnerId: selectedItem.userId,
        subject: removeExtraSpaces(callInfo.subject),
        reminder: callInfo.reminder.value,
        callPurpose: callInfo.callPurpose.value,
        callAgenda: removeExtraSpaces(callInfo.callAgenda),
        modSearchName: selectedOptionModule.value,
        modSearchId: modId,
        choosing: selectedOptionLead.value,
      };
    } else {
      if (selectedOptionLead.value === "Contact") {
        requestBody = { ...requestBody, contactId: selectedContact.contactId };
      } else {
        requestBody = { ...requestBody, leadId: selectedLeadItem.leadId };
      }
      requestBody = {
        ...requestBody,
        loggedInUserId: loginUserId,
        choosingType: "log_a_call",
        callType: callInfo.callType.value,
        callStartDate:
          editPurchaseOverview && isComplete === 0
            ? callDetails.callStartDate
            : moment(new Date(callInfo.callStartDate)).format("DD-MM-YYYY"),
        callStartTime:
          editPurchaseOverview && isComplete === 0
            ? callDetails.callStartTime
            : `${callStartTime.hour}:${callStartTime.minutes} ${callStartTime.meridiem}`,
        callOwnerId: selectedItem.userId,
        subject: removeExtraSpaces(callInfo.subject),
        callPurpose: callInfo.callPurpose.value,
        callAgenda: removeExtraSpaces(callInfo.callAgenda),
        description: removeExtraSpaces(callInfo.description),
        callResult: callInfo.callResult.value,
        modSearchName: selectedOptionModule.value,
        modSearchId: modId,
        choosing: selectedOptionLead.value,
      };
    }

    if (editPurchaseOverview) {
      requestBody = {
        ...requestBody,
        callId: callId,
        isComplete: isComplete,
        emailNotification: emailNotification,
      };
    }

    const url = editPurchaseOverview ? URI.updateCalls : URI.insertCalls;
    editPurchaseOverview && setApiLoader(true);
    setCreateLoader(true);
    const headersResult = getHeaders(true);

    // Check if headers result indicates an error
    if (headersResult.error) {
      navigate("../"); // Redirect to login if token is missing
      return;
    }
    await axios
      .post(url, requestBody, {
        headers: headersResult,
      })
      .then((response) => {
        if (response.data.status === 200) {
          NotificationManager.success("", response.data.message, 5000);
          setShowModalType("");
          setCallInfo(callInformation);
          getAllCalls(selectedOption);
          setSelectedOptionLead(leadContactOptions[0]);

          if (editPurchaseOverview) {
            callsOverview(callId);
            setIsComplete(0);
          }
        } else {
          NotificationManager.error("", response.data.message, 5000);
        }
        const callStartTime = {
          hour: "00",
          minutes: "00",
          meridiem: "AM",
        };
        setCallStartTime(callStartTime);
      })
      .catch(function (error) {
        handleApiError(error, navigate);
      })
      .finally(() => {
        setCreateLoader(false);
        setOverlapModal(false);
        setApiLoader(false);
      });
  };

  function titleCase(str) {
    return str?.toLowerCase().replace(/(?:^|\s)\w/g, function (match) {
      return match.toUpperCase();
    });
  }

  const callsOverview = async (callId) => {
    var callDetails = {};
    const callStartTime = {
      hour: "00",
      minutes: "00",
      meridiem: "AM",
    };
    const requestBody = {
      loggedInUserId: loginUserId,
      callId: callId,
    };

    const headersResult = getHeaders(true);

    // Check if headers result indicates an error
    if (headersResult.error) {
      navigate("../"); // Redirect to login if token is missing
      return;
    }
    await axios
      .post(URI.callOverviewDetails, requestBody, {
        headers: headersResult,
      })
      .then((response) => {
        if (response.data.status === 200) {
          getNotesDetails(callId, selectRecent);
          setRescheduleCheck(false);
          const [time12Hour, meridiem] =
            response.data.data.callInformationData.callStartTime.split(" ");
          const [hoursStr, minutesStr] = time12Hour.split(":");
          callDetails = response.data.data.callInformationData;
          if (response.data.data.callInformationData.callDuration !== null) {
            const [minute, second] =
              response.data.data.callInformationData.callDuration.split(":");

            callDetails.minutes = minute;
            callDetails.seconds = second;
          }

          callStartTime.hour = hoursStr;
          callStartTime.minutes = minutesStr;
          callStartTime.meridiem = meridiem;

          rescheduleTime.hour = hoursStr;
          rescheduleTime.minutes = minutesStr;
          rescheduleTime.meridiem = meridiem;

          setSelectedOptionLead({
            label: titleCase(
              response.data.data.callInformationData.callToModuleName
            ),
            value: titleCase(
              response.data.data.callInformationData.callToModuleName
            ),
          });
          if (
            response.data.data.callInformationData.callToModuleName?.toLowerCase() !==
              "lead" &&
            response.data.data.callInformationData.relationModuleName !== null
          ) {
            setSelectedOptionModule({
              label: titleCase(
                response.data.data.callInformationData.relationModuleName
              ),
              value: titleCase(
                response.data.data.callInformationData.relationModuleName
              ),
            });
          }

          if (
            response.data.data.callInformationData.callToModuleName?.toLowerCase() ===
              "lead" &&
            response.data.data.callInformationData.relationModuleName !== null
          ) {
            setSelectedLeadItem({
              leadId: response.data.data.callInformationData.callToId,
              leadName: response.data.data.callInformationData.callToName,
            });
          } else {
            setSelectedContact({
              contactId: response.data.data.callInformationData.callToId,
              contactName: response.data.data.callInformationData.callToName,
            });
            setModuleWiseData([
              {
                moduleName: response.data.data.callInformationData.relationName,
                moduleId: response.data.data.callInformationData.relationToId,
              },
            ]);
            setSelectedModuleItem({
              moduleName: response.data.data.callInformationData.relationName,
              moduleId: response.data.data.callInformationData.relationToId,
            });
          }

          getAllTimeLineCalls(callId);
          setInnerPage("overview");

          setCallDetails(callDetails);
          setInfoDetails(response.data.data.infoDetails);
          setOpenActivities(response.data.data.openActivities);
          setCallStartTime(callStartTime);
        } else {
          NotificationManager.error("", response.data.message, 5000);
        }
      })
      .catch(async (error) => {
        handleApiError(error, navigate);
      });
  };

  const handleEditClick = (field) => {
    if (Object.values(editingFields).some((value) => value)) {
      return;
    }
    setCallInfo((prevState) => ({
      ...prevState,
      [field]: callDetails[field],
    }));

    setEditingFields((prevFields) => ({
      ...prevFields,
      [field]: true,
    }));
  };

  const handleCancelClick = (field) => {
    setEditingFields((prevFields) => ({
      ...prevFields,
      [field]: false,
    }));
    setCallInfo((prevState) => ({
      ...prevState,
      [field]: callDetails[field],
    }));
  };

  const handleIndividualInputChange = (field, value) => {
    setCallInfo((prevState) => ({
      ...prevState,
      [field]: value,
    }));
  };

  const handleInputClickSet = (item, value) => {
    value === "Owner"
      ? setSelectedItem(item)
      : value === "Quotes"
      ? setSelectedQuote(item)
      : value === "Accounts"
      ? setSelectedAccount(item)
      : value === "Deals"
      ? setSelectedDeal(item)
      : value === "Contacts"
      ? setSelectedContact(item)
      : value === "Leads"
      ? setSelectedLeadItem(item)
      : value === "Vendors"
      ? setSelectedVendorItem(item)
      : value === "Products"
      ? setSelectedProductItem(item)
      : value === "Invoices"
      ? setSelectedInvoiceItem(item)
      : value === "Sales Order"
      ? setSelectedSalesItem(item)
      : setSelectedPurchaseItem(item);

    if (value === "Contacts") {
      getAllAssignModules(
        "CONTACTS",
        [selectedOptionModule.value],
        item.contactId
      );
    }
    // Purchase
    setListVisible(false);
    modalType !== "ScheduleCall" && handleUpdateClick("Owner", item);
  };

  const handleUpdateClick = async (field, item) => {
    var value = "";

    if (field === "callPurpose" || field === "callResult") {
      value = callInfo[field].value;
    } else if (field === "Owner") {
      field = "callOwnerId";
      value = item !== "" ? item.userId : selectedItem.userId;
    } else {
      value = callInfo[field];
    }
    try {
      const requestBody = {
        loggedInUserId: loginUserId,
        callId: callId,
        [field]: value,
      };

      if (callInfo[field] !== callDetails[field]) {
        const headersResult = getHeaders(true);

        // Check if headers result indicates an error
        if (headersResult.error) {
          navigate("../"); // Redirect to login if token is missing
          return;
        }
        const response = await axios.post(
          URI.individualUpdateCalls,
          requestBody,
          {
            headers: headersResult,
          }
        );
        if (response.data.status === 200) {
          NotificationManager.success("", response.data.message, 5000);
          setCallInfo((prevDetails) => ({
            ...prevDetails,
            [field]: callInfo[field],
          }));

          setEditingFields((prevFields) => ({
            ...prevFields,
            [field]: false,
          }));
          callsOverview(callId);
        } else {
          NotificationManager.error("", response.data.message, 5000);
        }
      }

      const resetFields = Object.fromEntries(
        Object.keys(editingFields).map((key) => [key, false])
      );
      setEditingFields({
        ...resetFields,
      });
      setShowType("");
    } catch (error) {
      handleApiError(error, navigate);
    }
  };

  const handleColumnCheckboxChange = (name, isChecked) => {
    setSavingHeadings((prevHeadings) =>
      prevHeadings.map((heading) =>
        heading.name === name ? { ...heading, isChecked } : heading
      )
    );
  };

  const handlePageDropDowmChange = (selectedOption) => {
    setCurrentPage(1);
    setPerPage(parseInt(selectedOption.value));
  };

  const handleSelectedChangeOwner = (value) => {
    setSelectedItem(value);
  };

  const totalPages = Math.ceil(Number(totalItems) / perPage);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleChange = (selectedOption) => {
    setSelectedOption(selectedOption);
    getAllCalls(selectedOption);
  };

  const handleChangeLead = (selectedOption) => {
    setSelectedOptionLead(selectedOption);
    if (selectedOption.value === "Contact") {
      getAllAssignModules(
        "CONTACTS",
        [selectedOptionModule.value],
        selectedContact.contactId
      );
    }
  };

  const handleChangeModule = (selectedOption) => {
    setSelectedOptionModule(selectedOption);
    getAllAssignModules(
      "CONTACTS",
      [selectedOption.value],
      selectedContact.contactId
    );
  };

  const getAllCalls = async (selectedOption) => {
    setIsLoading(true);
    const requestBody = {
      loggedInUserId: loginUserId,
      customFilter: selectedOption.value,
      pageNo: currentPage,
      pageSize: perPage,
    };
    const headersResult = getHeaders(true);

    // Check if headers result indicates an error
    if (headersResult.error) {
      navigate("../"); // Redirect to login if token is missing
      return;
    }
    await axios
      .post(URI.getAllCalls, requestBody, {
        headers: headersResult,
      })

      .then((response) => {
        if (response.data.status === 200) {
          setAllCallDetails(response.data.data.allCallDetails);
          setFilteredCallDetails(response.data.data.allCallDetails);
          setTotalItems(response.data.data.rowCount);
          setIsLoading(false);
          setCurrentPageContact(1);
          setCurrentPageLead(1);
          setPerPageContact(10);
          setPerPageLead(10);
        } else {
          setIsLoading(false);
        }
      })
      .catch(async (error) => {
        handleApiError(error, navigate);
      });
  };

  const getAllTimeLineCalls = async (callId) => {
    const headersResult = getHeaders(true);

    // Check if headers result indicates an error
    if (headersResult.error) {
      navigate("../"); // Redirect to login if token is missing
      return;
    }
    await axios
      .get(URI.getCallsTimeline + "/" + loginUserId + "/" + callId, {
        headers: headersResult,
      })

      .then((response) => {
        if (response.data.status === 200) {
          setTimeLineDataList(response.data.data.getCallTimeline);
          // setContactPageView("timeline");
        }
      })
      .catch(async (error) => {
        handleApiError(error, navigate);
      });
  };

  const deleteCalls = async () => {
    const requestBody = {
      loggedInUserId: loginUserId,
      callId: callId,
    };
    setApiLoader(true);

    const headersResult = getHeaders(true);

    // Check if headers result indicates an error
    if (headersResult.error) {
      navigate("../"); // Redirect to login if token is missing
      return;
    }
    await axios
      .post(URI.deleteCalls, requestBody, {
        headers: headersResult,
      })
      .then((response) => {
        if (response.data.status === 200) {
          NotificationManager.success("", response.data.message, 5000);
          getAllCalls(selectedOption);
          setInnerPage("list");
          setDeleteModal("");
        } else {
          NotificationManager.error("", response.data.message, 5000);
        }
      })
      .catch(function (error) {
        handleApiError(error, navigate);
      })
      .finally(() => setApiLoader(false));
  };

  const getAllLeadsDetails = async () => {
    const requestBody = {
      loggedInUserId: loginUserId,
      pageNo: currentPageLead,
      pageSize: perPageLead,
    };
    const headersResult = getHeaders(true);

    // Check if headers result indicates an error
    if (headersResult.error) {
      navigate("../"); // Redirect to login if token is missing
      return;
    }
    await axios
      .post(URI.getAllLeads, requestBody, {
        headers: headersResult,
      })

      .then((response) => {
        if (response.data.status === 200) {
          setLeadList(response.data.data.allLeadDetails);
        }
        setModalLeadDetails(response.data.data.allLeadDetails);
        setTotalItemsLead(response.data.data.rowCount);
      })
      .catch(async (error) => {
        handleApiError(error, navigate);
      });
  };

  const getAllContact = async () => {
    const requestBody = {
      loggedInUserId: loginUserId,
      pageNo: currentPageContact,
      pageSize: perPageContact,
    };
    const headersResult = getHeaders(true);

    // Check if headers result indicates an error
    if (headersResult.error) {
      navigate("../"); // Redirect to login if token is missing
      return;
    }
    await axios
      .post(URI.getAllContact, requestBody, {
        headers: headersResult,
      })
      .then((response) => {
        if (response.data.status === 200) {
          setContactList(response.data.data.allContactDetails);
        }
        setModalContactDetails(response.data.data.allContactDetails);
        setTotalItemsContact(response.data.data.rowCount);
      })
      .catch((err) => {
        handleApiError(err, navigate);
      });
  };

  const handleUpdate = () => {
    if (
      callDetails.outGoingCallStatus === "Scheduled" ||
      callDetails.outGoingCallStatus === "Overdue"
    ) {
      setShowModalType("ScheduleCall");
      setModalView("ScheduleCall");
    } else {
      setShowModalType("ScheduleCall");
      setModalView("LogCall");
    }
  };

  const handleMarkAsComplete = () => {
    setShowModalType("ScheduleCall");
    setModalView("LogCall");
  };

  const handleSelectCheckboxChange = (event) => {
    const { value } = event.target;

    const selectedCheckbox = { moduleId: value };

    setSelectedCheckboxes((prevSelectedCheckboxes) => {
      const isAlreadySelected = prevSelectedCheckboxes.some(
        (checkbox) => checkbox.moduleId === value
      );

      if (isAlreadySelected) {
        return prevSelectedCheckboxes.filter(
          (checkbox) => checkbox.moduleId !== value
        );
      } else {
        return [...prevSelectedCheckboxes, selectedCheckbox];
      }
    });
  };

  const sortedColumnsData = (type, headingName) => {
    const sortedData = [...allCallDetails];

    if (type === "asc") {
      sortedData.sort((a, b) => {
        if (
          typeof a[headingName] === "string" &&
          typeof b[headingName] === "string"
        ) {
          return a[headingName].localeCompare(b[headingName]);
        }
        return a[headingName] - b[headingName];
      });
    } else if (type === "desc") {
      sortedData.sort((a, b) => {
        if (
          typeof a[headingName] === "string" &&
          typeof b[headingName] === "string"
        ) {
          return b[headingName].localeCompare(a[headingName]);
        }
        return b[headingName] - a[headingName];
      });
    }
    setFilteredCallDetails(sortedData);
  };

  const handlePinColumn = (name) => {
    setPinnedColumn(name);
    const updatedColumns = [...selectedHeadings];
    const clickedColumnIndex = updatedColumns.findIndex(
      (column) => column.name === name
    );

    if (clickedColumnIndex !== -1) {
      const clickedColumn = updatedColumns.splice(clickedColumnIndex, 1)[0];
      updatedColumns.unshift(clickedColumn);
      setSelectedHeadings(updatedColumns);
    }
  };

  const handleOwnerInputClick = (e) => {
    e.stopPropagation();
    setListVisible(!listVisible);
    setModuleListVisible(false);
  };

  const handleChangeNoteTitle = (e) => {
    const { name, value } = e.target;
    if (value.length === 1 && value[0] === ' ') {
      return; 
    }
    if (editNote) {
      setUpdateNotes((prevLeadInfo) => ({
        ...prevLeadInfo,
        [name]: value,
      }));
    } else {
      setAddTitle(value);
    }
  };

  const handleChildDataChange = (dataFromChild) => {
    let deletedImg = dataFromChild.toString("");
    setDeletedImg(deletedImg);
  };

  const getNotesDetails = async (callId, orderType) => {
    const requestBody = {
      loggedInUserId: loginUserId,
      moduleId: callId,
      orderType: orderType,
    };
    const headersResult = getHeaders(true);

    // Check if headers result indicates an error
    if (headersResult.error) {
      navigate("../"); // Redirect to login if token is missing
      return;
    }
    await axios
      .post(URI.getNote, requestBody, {
        headers: headersResult,
      })
      .then((response) => {
        if (response.data.status === 200) {
          setNoteInfo(response.data.data.noteInfo);
        } else {
          NotificationManager.error("", response.data.message, 5000);
        }
      })
      .catch(async (error) => {
        handleApiError(error, navigate);
      });
  };

  const handleChangeNoteDescription = (e) => {
    const { name, value } = e.target;
    if (value.length === 1 && value[0] === ' ') {
      return; 
    }
    if (editNote) {
      setUpdateNotes((prevLeadInfo) => ({
        ...prevLeadInfo,
        [name]: value,
      }));
    } else {
      setNote(value); // Directly set the input value
    }
  };

  const updateNote = (noteId) => {
    const formData = new FormData();
    formData.append("moduleId", callId);
    formData.append("loggedInUserId", loginUserId);
    formData.append("moduleName", "CALLS");
    formData.append("noteDescription", updateNotes.description);
    formData.append("noteTitle", updateNotes.title);
    formData.append("noteId", noteId);
    formData.append("deletedImageNames", deletedImg.toString());

    selectedNoteFiles.forEach((file) => {
      formData.append("noteImages", file);
    });

    if (updateNotes.description.length > 1) {
      const headersResult = getHeaders(true);

      // Check if headers result indicates an error
      if (headersResult.error) {
        navigate("../"); // Redirect to login if token is missing
        return;
      }
      axios
        .post(URI.updateNote, formData, {
          headers: headersResult,
        })
        .then((response) => {
          if (response.data.status === 200) {
            setShowButtons(false);
            setEditNote(false);
            setUpdateNotes({
              description: "",
              title: "",
              noteImageList: [],
            });
            setSelectedNoteFiles([]);
            getNotesDetails(callId, selectRecent);
            setDeletedImg("");
          } else {
            setDeletedImg("");
            setSelectedNoteFiles([]);
            NotificationManager.error("", response.data.message, 5000);
          }
        })
        .catch((error) => {
          handleApiError(error, navigate);
        });
    } else {
      NotificationManager.error("Empty", "Note can not be empty.", 5000);
    }
  };

  const deleteNoteDetails = (callId, noteId) => {
    const formData = new FormData();
    formData.append("moduleId", callId);
    formData.append("loggedInUserId", loginUserId);
    formData.append("moduleName", "CALLS");
    formData.append("noteId", noteId);
    const headersResult = getHeaders(true);

    // Check if headers result indicates an error
    if (headersResult.error) {
      navigate("../"); // Redirect to login if token is missing
      return;
    }
    axios
      .post(URI.deleteNote, formData, {
        headers: headersResult,
      })
      .then((response) => {
        if (response.data.status === 200) {
          NotificationManager.success("", response.data.message, 3000);
          setNoteDeleteConfirmation(false);
          getNotesDetails(callId, selectRecent);
        } else {
          NotificationManager.error("", response.data.message, 5000);
        }
      })
      .catch((error) => {
        handleApiError(error, navigate);
      });
  };

  const handleSelectRecent = (item) => {
    setSelectRecent(item);
    var value = "";
    if (item === "Recent Last") {
      value = "RECENT_LAST";
    } else if (item === "Recent First") {
      value = "RECENT_FIRST";
    } else {
      value = item;
    }
    getNotesDetails(callId, value);
  };

  const handleAddTitleClick = () => {
    setShowTitleNote(true);
  };

  const insertNote = () => {
    const formData = new FormData();
    formData.append("moduleId", callId);
    formData.append("loggedInUserId", loginUserId);
    formData.append("moduleName", "CALLS");
    formData.append("noteDescription", note);
    formData.append("noteTitle", addTitle);

    selectedNoteFiles.forEach((file) => {
      formData.append("noteImages", file);
    });

    if (note.length > 1) {
      const headersResult = getHeaders(true);

      // Check if headers result indicates an error
      if (headersResult.error) {
        navigate("../"); // Redirect to login if token is missing
        return;
      }
      axios
        .post(URI.insertNote, formData, {
          headers: headersResult,
        })
        .then((response) => {
          if (response.data.status === 200) {
            setShowButtons(false);
            setAddTitle("");
            setNote("");
            setSelectedNoteFiles([]);
            // getNotesDetails(newNoteId,leadId);
            // Pass newNoteId to fetchNoteImage
            getNotesDetails(callId, selectRecent);
          } else {
            NotificationManager.error("", response.data.message, 5000);
          }
        })
        .catch((error) => {
          handleApiError(error, navigate);
        });
    } else {
      NotificationManager.error("Empty", "Note can not be empty.", 5000);
    }
  };

  const hasPermission = (action) => {
    const permission = permissionList.calls.find((p) => p.action === action);
    return permission ? permission.status === "TRUE" : false;
  };

  const handleTextAreaClick = () => {
    if (hasPermission("CREATE")) {
      setShowButtons(true);
    } else {
      // setShowAccessModal(true);
    }
  };

  const nodataPage = () => {
    return (
      <div
        className="d-flex flex-column justify-content-center align-items-center mb-5"
        onClick={() => setIsDropdownOpen(false)}
      >
        <img src={noDataImage} alt="img" height={200} width={200} />
        <h5 className="opacity-75">Manage Call</h5>
        <p className="opacity-75 p-0 m-0">
          Calls are legal agreements between a customer and a vector
        </p>
        <p className="opacity-75 p-0 m-0">
          to deliver the requested product(s) at the agreed upon price
        </p>
        <p className="opacity-75 p-0 m-0">within the specified time period.</p>
        <button
          className="createContactButtonSave px-2 mt-4 cursorPointer rounded-5"
          style={{ width: "175px", height: "40px" }}
          onClick={(e) => {
            // setInnerPage("createCall");
            e.stopPropagation();
            setIsDropdownOpen(!isDropdownOpen);
          }}
        >
          Create a Call
        </button>
        <ul
          className={`dropdown-menu ${isDropdownOpen ? "show" : ""}`}
          style={{ position: "relative", marginTop: "5px" }}
        >
          <li
            className="dropdown-item cursorPointer"
            onClick={() => {
              setIsDropdownOpen(false);
              setEditPurchaseOverview(false);
              const callStartTime = {
                hour: "00",
                minutes: "00",
                meridiem: "AM",
              };
              setCallStartTime(callStartTime);
              setShowModalType("ScheduleCall");
              setModalView("ScheduleCall");
            }}
          >
            Schedule a Call
          </li>
          <li
            className="dropdown-item cursorPointer"
            onClick={() => {
              setIsDropdownOpen(false);
              setEditPurchaseOverview(false);
              const callStartTime = {
                hour: "00",
                minutes: "00",
                meridiem: "AM",
              };
              setCallStartTime(callStartTime);
              setShowModalType("ScheduleCall");
              setModalView("LogCall");
            }}
          >
            Log a Call
          </li>
        </ul>
      </div>
    );
  };

  const [massUpdateValue, setMassUpdateValue] = useState("");
  const [addTitle, setAddTitle] = useState("");
  const [note, setNote] = useState("");
  const [selectedObj, setSelectedObj] = useState({
    name: "Call Agenda",
    isChecked: false,
    value: "callAgenda",
  });
  const [clickedDropdownObj, setClickedDropdownObj] = useState({
    label: "",
    value: "",
  });
  const [showSearchFields, setShowSearchFields] = useState(massColumn);
  const [searchField, setSearchField] = useState("");
  const [fieldLabels, setFieldLabels] = useState(massColumn);
  const [activeTab, setActiveTab] = useState("Info");
  const [activeCallId, setActiveCallId] = useState(null);
  const [isComplete, setIsComplete] = useState(0);
  const [mandatoryMsg, setMandatoryMsg] = useState("");
  const [mandatoryMsgLeadContact, setMandatoryMsgLeadContact] = useState("");
  const [mandatoryMsgTime, setMandatoryMsgTime] = useState("");
  const [mandatoryMsgNoteTitle, setMandatoryMsgNoteTitle] = useState("");
  const [mandatoryNoteDesc, setMandatoryNoteDesc] = useState("");
  const [noteTitle, setNoteTitle] = useState("");
  const [showTitleNote, setShowTitleNote] = useState(false);
  const [selectedNoteFiles, setSelectedNoteFiles] = useState([]);
  const [isDropdownOpenEdit, setIsDropdownOpenEdit] = useState(false);
  const [isDropdownOpenCancel, setIsDropdownOpenCancel] = useState(false);

  const deleteUpdateTransferMass = async () => {
    const { value } = selectedObj;
    let url = URI.massDelete;
    const requestBody = {
      loggedInUserId: loginUserId,
      moduleName: "CALLS",
      massAction: modalType === "Mass Delete" ? "DELETE" : "EDIT",
      moduleIdList: selectedCheckboxes,
    };
    if (modalType === "Mass Update") {
      requestBody[value] = massUpdateValue;
      url = URI.massUpdate;
    } else if (modalType === "Mass Transfer") {
      requestBody.ownerId = selectedItem.userId;
      url = URI.massTransfer;
    }

    const headersResult = getHeaders(true);

    // Check if headers result indicates an error
    if (headersResult.error) {
      navigate("../"); // Redirect to login if token is missing
      return;
    }
    await axios
      .post(url, requestBody, {
        headers: headersResult,
      })
      .then((response) => {
        if (response.data.status === 200) {
          NotificationManager.success("", response.data.message, 5000);
          getAllCalls(selectedOption);
          setSelectedCheckboxes([]);
          setInnerPage("list");
          setShowModalType("");
          setShowSearchFields(massColumn);
          setMassUpdateValue("");
        } else {
          NotificationManager.error("", response.data.message, 5000);
        }
      })
      .catch(function (error) {
        handleApiError(error, navigate);
      });
  };

  const callHomePage = () => {
    return (
      <div>
        {isLoading ? (
          <>
            <Loader />
          </>
        ) : (!filteredCallDetails || filteredCallDetails.length === 0) &&
          selectedOption.value === "all_calls" ? (
          <>{nodataPage()}</>
        ) : (
          <>
            <div className="creteLeadBar justify-content-between">
              {selectedCheckboxes.length > 0 ? (
                <div className="d-flex w-50 align-items-center gap-2">
                  <Select
                    options={massOptions}
                    styles={customCommonStyles("100%")}
                    value={{
                      value: "Action",
                      label: "Action",
                    }}
                    onChange={(option) => {
                      // setDropdownTwo(option);
                      if (option.value === "Mass Update") {
                        //   setShowSearchFields(callsColumn);
                      } else if (option.value === "Mass Transfer") {
                        setSelectedItem(userList[0]);
                      }
                      setShowModalType(option.value);
                    }}
                    className="w-25"
                    isSearchable={false}
                  />
                </div>
              ) : (
                <div>
                  <Select
                    options={options}
                    styles={customCommonStyles("210px", "350px")}
                    value={selectedOption}
                    onChange={handleChange}
                    isSearchable={false}
                  />
                </div>
              )}
              {selectedCheckboxes.length === 0 && (
                <div className="d-flex w-50 justify-content-end gap-2">
                  <div className="btn-group">
                    <button
                      type="button"
                      className="createContactButtonSave py-2"
                      data-bs-toggle="dropdown"
                      aria-expanded={isDropdownOpen ? "true" : "false"}
                      onClick={(e) => {
                        e.stopPropagation();
                        setIsDropdownOpen(!isDropdownOpen);
                        setCurrentPageContact(1);
                        setCurrentPageLead(1);
                        setPerPageContact(10);
                        setPerPageLead(10);
                      }}
                    >
                      Create Call
                    </button>

                    <ul
                      className={`dropdown-menu ${
                        isDropdownOpen ? "show" : ""
                      }`}
                    >
                      <li
                        className="dropdown-item cursorPointer"
                        onClick={() => {
                          setIsDropdownOpen(false);
                          setEditPurchaseOverview(false);
                          setShowModalType("ScheduleCall");
                          setModalView("ScheduleCall");
                          setCallStartTime({
                            hour: "00",
                            minutes: "00",
                            meridiem: "AM",
                          });
                        }}
                      >
                        Schedule a Call
                      </li>
                      <li
                        className="dropdown-item cursorPointer"
                        onClick={() => {
                          setIsDropdownOpen(false);
                          setEditPurchaseOverview(false);
                          setShowModalType("ScheduleCall");
                          setModalView("LogCall");
                          setCallStartTime({
                            hour: "00",
                            minutes: "00",
                            meridiem: "AM",
                          });
                        }}
                      >
                        Log a Call
                      </li>
                    </ul>

                    {/* <button
                      type="button"
                      className="btn btn-sm  dropdown-toggle dropdown-toggle-split py-2"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                      style={{
                        padding: "5px",
                        background:
                          "linear-gradient(86.07deg, #407BFF 0%, #12A7FE 100%)",
                        color: "#fff",
                      }}
                    >
                      <span className="visually-hidden"></span>
                    </button> */}
                    {/* <ul className="dropdown-menu">
                      <li>
                        <a className="dropdown-item ">Import Call</a>
                      </li>
                      <li>
                        <a className="dropdown-item ">Import Notes</a>
                      </li>
                    </ul> */}
                  </div>
                </div>
              )}
            </div>
            <div className="totalRecordBar">
              <div className="d-flex w-50">
                <h6>
                  {" "}
                  {selectedCheckboxes.length > 0
                    ? `${selectedCheckboxes.length} Records Selected`
                    : `Total Records : ${totalItems}`}{" "}
                </h6>
              </div>
              <div className="d-flex w-50 align-items-center justify-content-end gap-2">
                {/* <p className="mb-0">· ‹1/10›</p> */}

                {selectedCheckboxes.length === 0 ? (
                  filteredCallDetails?.length !== 0 && (
                    <Select
                      options={pageOption}
                      styles={customCommonStyles("100%")}
                      isSearchable={false}
                      value={pageOption.find(
                        (option) => option.value === perPage.toString()
                      )}
                      onChange={handlePageDropDowmChange}
                    />
                  )
                ) : (
                  <></>
                )}

                {filteredCallDetails?.length !== 0 && (
                  <Pagination
                    currentPage={currentPage}
                    totalPages={totalPages}
                    onPageChange={handlePageChange}
                  />
                )}
              </div>
            </div>
            <div className="leadMain">
              {filterView ? (
                <div className="filterLead">
                  <h6 style={{ borderBottom: "2px solid #316AFF" }}>
                    Filter by Sales
                  </h6>
                  <input
                    type="text"
                    placeholder="Search"
                    className="filterSearch"
                  />
                  <div className="checkboxItem">
                    <h6 style={{ color: "#515151" }}>
                      ▾ System Defined Filters
                    </h6>
                    <input
                      type="checkbox"
                      id="vehicle1"
                      name="vehicle1"
                      value="Bike"
                    />
                    <label htmlFor="vehicle1"> Touched Record</label>
                    <br />
                    <input
                      type="checkbox"
                      id="vehicle1"
                      name="vehicle1"
                      value="Bike"
                    />
                    <label htmlFor="vehicle1"> Untouched Record</label>
                    <br />
                    <input
                      type="checkbox"
                      id="vehicle1"
                      name="vehicle1"
                      value="Bike"
                    />
                    <label htmlFor="vehicle1"> Record Action</label>
                    <br />
                    <h6 style={{ color: "#515151", marginTop: "10px" }}>
                      ▾ Filter By Fields
                    </h6>
                    <input
                      type="checkbox"
                      id="vehicle1"
                      name="vehicle1"
                      value="Bike"
                    />
                    <label htmlFor="vehicle1"> Annual Revenue</label>
                    <br />
                    <input
                      type="checkbox"
                      id="vehicle1"
                      name="vehicle1"
                      value="Bike"
                    />
                    <label htmlFor="vehicle1"> City</label>
                    <br />
                    <input
                      type="checkbox"
                      id="vehicle1"
                      name="vehicle1"
                      value="Bike"
                    />
                    <label htmlFor="vehicle1"> Company</label>
                    <br />
                  </div>
                </div>
              ) : (
                <></>
              )}
              <div
                className={
                  filterIcon ? "leadListTableFullWidth" : "leadListTable"
                }
              >
                <table className="table leadTable">
                  <thead>
                    <tr>
                      <th scope="col">
                        <input
                          className="cursorPointer"
                          type="checkbox"
                          id="all"
                          name="all"
                          value="All"
                          checked={filteredCallDetails.every((selected) =>
                            selectedCheckboxes.some(
                              (list) => list.moduleId === selected.callId
                            )
                          )}
                          onChange={(e) => {
                            const allList = filteredCallDetails.map((item) => ({
                              moduleId: item.callId,
                            }));
                            if (e.target.checked) {
                              const newSelections = allList.filter(
                                (newItem) =>
                                  !selectedCheckboxes.some(
                                    (existingItem) =>
                                      existingItem.moduleId === newItem.moduleId
                                  )
                              );
                              setSelectedCheckboxes((prev) => [
                                ...prev,
                                ...newSelections,
                              ]);
                            } else {
                              setSelectedCheckboxes(
                                selectedCheckboxes.filter(
                                  (aItem) =>
                                    !allList.some(
                                      (bItem) =>
                                        aItem.moduleId === bItem.moduleId
                                    )
                                )
                              );
                            }
                          }}
                          // checked={
                          //   selectedCheckboxes.length ===
                          //     filteredCallDetails?.length &&
                          //   filteredCallDetails?.length > 0
                          // }
                          // onChange={(e) => {
                          //   if (e.target.checked) {
                          //     const allList = filteredCallDetails?.map(
                          //       (item) => ({
                          //         moduleId: item.callId,
                          //       })
                          //     );
                          //     setSelectedCheckboxes(allList);
                          //   } else {
                          //     setSelectedCheckboxes([]);
                          //   }
                          // }}
                        />
                      </th>
                      {selectedHeadings.map(
                        (heading) =>
                          heading.isChecked && (
                            <th scope="col" key={heading.name}>
                              <span className="pe-3">{heading.name}</span>
                              <span className="position-relative">
                                <img
                                  src={tableFilterIcon}
                                  alt=""
                                  className="cursorPointer"
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    setShowHeadings(false);
                                    setShowSelectedFilter(heading.name);
                                  }}
                                />
                                {heading.name === showSelectedFilter && (
                                  <span
                                    className="position-absolute rounded-2 border bg-light top-0 mt-4"
                                    style={{ right: "-10%" }}
                                  >
                                    <span className="d-flex flex-column px-1">
                                      {/* Ascending filter */}
                                      <span
                                        className="my-1 px-1 cursorPointer fw-normal text-start filter_type"
                                        onClick={() => {
                                          if (filterType === "asc") {
                                            setFilterType("");
                                            setFilteredCallDetails(
                                              allCallDetails
                                            );
                                          } else {
                                            setFilterType("asc");
                                            sortedColumnsData(
                                              "asc",
                                              heading.value
                                            );
                                          }
                                        }}
                                      >
                                        {filterType === "asc" ? (
                                          <img
                                            src={cancel}
                                            alt="asc"
                                            className="opacity-75 pe-1"
                                          />
                                        ) : (
                                          <img
                                            src={arrowUp}
                                            alt="asc"
                                            className="opacity-75 pe-1"
                                          />
                                        )}
                                        Asc
                                      </span>
                                      {/* Descending filter */}
                                      <span
                                        className="my-2 px-1 cursorPointer fw-normal text-start filter_type"
                                        onClick={() => {
                                          if (filterType === "desc") {
                                            setFilterType("");
                                            setFilteredCallDetails(
                                              allCallDetails
                                            );
                                          } else {
                                            setFilterType("desc");
                                            sortedColumnsData(
                                              "desc",
                                              heading.value
                                            );
                                          }
                                        }}
                                      >
                                        {filterType === "desc" ? (
                                          <img
                                            src={cancel}
                                            alt="desc"
                                            className="opacity-75 pe-1"
                                          />
                                        ) : (
                                          <img
                                            src={arrowDown}
                                            alt="desc"
                                            className="opacity-75 pe-1"
                                          />
                                        )}
                                        Desc
                                      </span>
                                      <span
                                        className={`my-1 px-1 fw-normal text-start filter_type ${
                                          selectedHeadings[0].name ===
                                            "Subject" &&
                                          heading.name === "Subject"
                                            ? "opacity-50"
                                            : "cursorPointer"
                                        }`}
                                        onClick={() => {
                                          if (pinnedColumn !== "Subject") {
                                            setPinnedColumn("Subject");
                                            handlePinColumn("Subject");
                                          } else {
                                            setPinnedColumn(heading.name);
                                            handlePinColumn(heading.name);
                                          }
                                        }}
                                      >
                                        <img
                                          src={
                                            pinnedColumn === heading.name
                                              ? unPin
                                              : pin
                                          }
                                          alt="pin"
                                          className="opacity-75 pe-1"
                                        />{" "}
                                        {pinnedColumn === heading.name
                                          ? "Unpin"
                                          : "Pin"}{" "}
                                        Column
                                      </span>
                                    </span>
                                  </span>
                                )}
                                <img src={filterLine} alt="" className="ps-2" />
                              </span>
                            </th>
                          )
                      )}

                      <th
                        scope="col"
                        className="text-end cursorPointer position-relative"
                      >
                        <img
                          src={filterMenu}
                          alt=""
                          className={`filter_menu ${
                            showHeadings ? "bg-light" : ""
                          }`}
                          onClick={(e) => {
                            e.stopPropagation();
                            setShowSelectedFilter("");
                            setShowHeadings(true);
                            setShowModalType("");
                            setIsDropdownOpen(false);
                          }}
                        />
                        {showHeadings && (
                          <div
                            className="position-absolute rounded-2  border border-primary bg-light"
                            style={{
                              right: "0.2rem",
                            }}
                          >
                            <div className="d-flex flex-column px-2">
                              <span
                                className="p-2 cursorPointer fw-normal text-start"
                                onClick={() => {
                                  setShowModalType("Manage Columns");
                                  setSavingHeadings(selectedHeadings);
                                  setSearchColumn("");
                                }}
                              >
                                Manage Columns
                              </span>
                              <span
                                className="p-2 fw-normal text-start opacity-50"
                                onClick={() => {
                                  setShowModalType("Reset Column Size");
                                }}
                              >
                                Reset Column Size
                              </span>
                            </div>
                          </div>
                        )}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {filteredCallDetails && filteredCallDetails.length > 0 ? (
                      filteredCallDetails.map((item, index) => {
                        return (
                          <tr
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              // getContactOverview(item.contactId);
                            }}
                            key={index}
                          >
                            <th scope="col">
                              <input
                                className="cursorPointer"
                                type="checkbox"
                                id="vehicle1"
                                name="vehicle1"
                                value={item.callId}
                                checked={selectedCheckboxes.some(
                                  (checkbox) =>
                                    checkbox.moduleId === item.callId
                                )}
                                onChange={handleSelectCheckboxChange}
                              />
                            </th>

                            <React.Fragment>
                              {selectedHeadings.map((obj, i) => {
                                const value = obj.value;
                                const itemValue = item[value];

                                return obj.isChecked ? (
                                  value === "transHash" ? (
                                    <td
                                      style={{ wordBreak: "break-all" }}
                                      className="overflow_text_hash"
                                    >
                                      <a
                                        rel="noreferrer"
                                        href={
                                          EXPLORER_URL +
                                          "about-tx-hash/" +
                                          item.transHash
                                        }
                                        className="anchorLinkHash textColor"
                                        data-title={item.transHash}
                                        target="_blank"
                                      >
                                        {item.transHash
                                          ? item.transHash.slice(0, 10) + "...."
                                          : "-"}
                                      </a>
                                    </td>
                                  ) : (
                                    <td
                                      key={i}
                                      onClick={() => {
                                        if (value === "subject") {
                                          callsOverview(item.callId);
                                          setCallId(item.callId);
                                          setSelectedCallId(
                                            allCallDetails.findIndex(
                                              (contact) =>
                                                contact.callId === item.callId
                                            )
                                          );
                                        }
                                      }}
                                      className={` ${
                                        value === "subject"
                                          ? "leadName cursorPointer"
                                          : ""
                                      }`}
                                    >
                                      {itemValue !== null ? itemValue : "-"}
                                    </td>
                                  )
                                ) : null;
                              })}
                            </React.Fragment>
                            <td></td>
                          </tr>
                        );
                      })
                    ) : (
                      <tr>
                        <td
                          colSpan={selectedHeadings.length + 2}
                          style={{ textAlign: "center" }}
                        >
                          No Data
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </>
        )}
      </div>
    );
  };

  const overviewPage = () => {
    return (
      <div
        onClick={() => {
          setIsDropdownOpenEdit(false);
          setIsDropdownOpenCancel(false);
        }}
      >
        <div className="indiv_main_container">
          <div className="individual_header_container commonContainer_bg w-100  flex-column  d-flex justify-content-between mb-4">
            <div className="iconContainer d-flex justify-content-between px-2 pt-2">
              <span className="d-flex pt-5">
                <span
                  className="cursorPointer"
                  onClick={() => {
                    getAllCalls(selectedOption);
                    setInnerPage("list");
                    setEditPurchaseOverview(false);
                    const resetFields = Object.fromEntries(
                      Object.keys(editingFields).map((key) => [key, false])
                    );
                    setEditingFields({
                      ...resetFields,
                    });
                    setShowType("");
                  }}
                >
                  {leftArrow}
                </span>
                <p className="ps-3 CallsHeading">{callDetails.callOwner} -</p>
                <p
                  className="ps-3 commonTextColor CallsHeading"
                  style={{ color: "rgba(81, 81, 81, 1)" }}
                >
                  {" "}
                  {callDetails.callToId?.slice(0, 4) === "LEAD"
                    ? "LEAD"
                    : "CONTACT"}
                </p>
              </span>
              <div className="pt-5 d-flex ps-4 pb-3">
                <div className="pe-4" style={{ alignContent: "space-evenly" }}>
                  <span
                    className={`me-2 ${
                      selectedCallId === 0 ? "opacity-50" : "cursorPointer"
                    }`}
                    onClick={() => {
                      const leadIndex = allCallDetails.findIndex(
                        (contact) => contact.callId === callId
                      );
                      if (leadIndex !== 0) {
                        setSelectedCallId(leadIndex - 1);
                        setCallId(allCallDetails[leadIndex - 1].callId);
                        callsOverview(allCallDetails[leadIndex - 1].callId);
                        // getNotesDetails(
                        //   allCallDetails[leadIndex - 1].purchaseOrdId,
                        //   selectRecent
                        // );
                      }
                    }}
                  >
                    {leftAngleArrow}
                  </span>
                  <span
                    className={`ms-1 ${
                      selectedCallId === allCallDetails.length - 1
                        ? "opacity-50"
                        : "cursorPointer"
                    }`}
                    onClick={() => {
                      const leadIndex = allCallDetails.findIndex(
                        (lead) => lead.callId === callId
                      );
                      if (leadIndex < allCallDetails.length - 1) {
                        setSelectedCallId(leadIndex + 1);
                        setCallId(allCallDetails[leadIndex + 1].callId);
                        callsOverview(allCallDetails[leadIndex + 1].callId);
                        // getNotesDetails(
                        //   allCallDetails[leadIndex + 1].purchaseOrdId,
                        //   selectRecent
                        // );
                      }
                    }}
                  >
                    {rightAngleArrow}
                  </span>
                </div>
                <div className="d-flex gap-1">
                  {/* <button className="btn  btnColor text-white">
                      Send Email
                    </button> */}
                  {(callDetails.outGoingCallStatus === "Scheduled" ||
                    callDetails.outGoingCallStatus === "Overdue") && (
                    <>
                      <button
                        className="btn  btnColor text-white"
                        onClick={() => {
                          setEditPurchaseOverview(true);
                          setRescheduleCheck(false);
                          setIsComplete(1);
                          setCallInfo({
                            ...callDetails,
                            callType: {
                              label: callDetails.callType,
                              value: callDetails.callType,
                            },
                            callPurpose: {
                              label: callDetails.callPurpose,
                              value: callDetails.callPurpose,
                            },
                            callResult: {
                              label: callDetails.callResult,
                              value: callDetails.callResult,
                            },
                            reminder: {
                              label: callDetails.reminder,
                              value: callDetails.reminder,
                            },
                            callStartDate: moment(
                              callDetails.callStartDate,
                              "DD-MM-YYYY"
                            ).format("YYYY-MM-DD"),
                            meridiem: callDetails.callStartTime.split(" "),
                            minutes: "00",
                            seconds: "00",
                          });
                          handleMarkAsComplete();
                        }}
                      >
                        Mark as Complete
                      </button>
                      <div className="position-relative">
                        <button
                          type="button"
                          className="btn btn-sm dropdown-toggle dropdown-toggle-split h-100"
                          data-bs-toggle="dropdown"
                          aria-expanded={
                            isDropdownOpenCancel ? "true" : "false"
                          }
                          onClick={(e) => {
                            e.stopPropagation();
                            setIsDropdownOpenCancel(!isDropdownOpenCancel);
                            setIsDropdownOpenEdit(false);
                          }}
                          style={{
                            background:
                              "linear-gradient(86.07deg, #407BFF 0%, #12A7FE 100%)",
                            color: "#fff",
                          }}
                        >
                          <span className="visually-hidden"></span>
                        </button>
                        <ul
                          className={`dropdown-menu ${
                            isDropdownOpenCancel ? "show" : ""
                          }`}
                        >
                          <li
                            className="dropdown-item cursorPointer"
                            onClick={() => {
                              setIsDropdownOpenCancel(false);
                              setCallInfo({
                                ...callInfo,
                                rescheduleDate: moment(
                                  callDetails.callStartDate,
                                  "DD-MM-YYYY"
                                ).format("YYYY-MM-DD"),
                              });
                              setShowModalType("RescheduleCall");
                              setRescheduleCheck(true);
                            }}
                          >
                            Reschedule Call
                          </li>
                          <li
                            className="dropdown-item cursorPointer"
                            onClick={() => {
                              setIsDropdownOpenCancel(false);
                              setShowModalType("cancelCall");
                            }}
                          >
                            Cancel Call
                          </li>
                        </ul>
                      </div>
                    </>
                  )}
                  <div className="position-relative">
                    <button
                      className="btn btn-secondary bg-white text-dark border border-primary ms-2"
                      type="button"
                      data-bs-toggle="dropdown"
                      aria-expanded={isDropdownOpenEdit ? "true" : "false"}
                      onClick={(e) => {
                        e.stopPropagation();
                        setIsDropdownOpenCancel(false);
                        setIsDropdownOpenEdit(!isDropdownOpenEdit);
                      }}
                    >
                      ---
                    </button>
                    <ul
                      className={`dropdown-menu ${
                        isDropdownOpenEdit ? "show" : ""
                      }`}
                    >
                      {callDetails.outGoingCallStatus !== "Cancelled" && (
                        <li
                          className="dropdown-item cursorPointer"
                          onClick={() => {
                            setIsDropdownOpenEdit(false);
                            setEditPurchaseOverview(true);
                            setCallInfo({
                              ...callDetails,
                              callType: {
                                label: callDetails.callType,
                                value: callDetails.callType,
                              },
                              callPurpose: {
                                label: callDetails.callPurpose,
                                value: callDetails.callPurpose,
                              },
                              callResult: {
                                label: callDetails.callResult,
                                value: callDetails.callResult,
                              },
                              reminder: {
                                label: callDetails.reminder,
                                value: callDetails.reminder,
                              },
                            });
                            handleUpdate();
                          }}
                        >
                          Edit
                        </li>
                      )}

                      <li
                        className="dropdown-item cursorPointer"
                        onClick={() => {
                          setIsDropdownOpenEdit(false);
                          handleDeleteCalls();
                          setIsDropdownOpen(false);
                        }}
                      >
                        Delete
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="indiv_main_container ">
          <div className="individual_header_container commonContainer_bg w-100 d-flex justify-content-between align-items-stretch">
            <div
              className="lead_Information_mainContainer rounded-2  d-flex flex-column mx-3 my-4"
              style={{ backgroundColor: "transparent", width: "35%" }}
            >
              <div
                className="leadInformation_content_container justify-content-between "
                style={{ height: "100%" }}
              >
                <div
                  // className="timeline_main_container p-3 rounded-2 bg-white  d-flex flex-column gap-4 w-75 "
                  className={`timeline_main_container rounded-2 bg-white d-flex flex-column gap-4`}
                  style={{ height: "100%" }}
                >
                  <div
                    className={`w-100 relatedListContainer relatedListCallContainer p-3 rounded bg-white list-container`}
                  >
                    <h5 className="w-100 px-2 pt-2 mb-0 fw-medium">
                      All Calls
                    </h5>
                    <div
                      className="mx-1"
                      style={{ border: "1px solid rgba(226, 229, 235, 1)" }}
                    ></div>
                    <ul
                      className="relateList_container p-0"
                      style={{
                        border: "1px solid rgba(226, 229, 235, 1)",
                        borderRadius: "8px",
                      }}
                    >
                      {allCallDetails.map((item) => {
                        const isActive = item.callId === activeCallId;

                        return (
                          <li
                            key={item.callId} // Ensure each li has a unique key for React
                            className={`cursorPointer relatedList_tab ${
                              isActive ? "active" : ""
                            }`}
                            style={{
                              alignItems: "center",
                              borderBottom: "1px solid #E2E5EB",
                              padding: "17.5px 0px 17.5px 5px",
                              color: isActive ? "#666666" : "",
                              backgroundColor: isActive
                                ? "#cce5ff"
                                : "transparent",
                            }}
                            onClick={() => {
                              callsOverview(item.callId);
                              setActiveCallId(item.callId);
                              setCallId(item.callId);
                            }}
                            onMouseEnter={(e) => {
                              if (!isActive) {
                                e.currentTarget.style.backgroundColor =
                                  "#e7eeff";
                              }
                            }}
                            onMouseLeave={(e) => {
                              if (!isActive) {
                                e.currentTarget.style.backgroundColor =
                                  "transparent";
                              } else if (isActive) {
                                e.currentTarget.style.backgroundColor =
                                  "#cce5ff";
                              }
                            }}
                          >
                            {item.callType === "Outbound" ? (
                              <span className="p-0">
                                <img src={callIconSuccess} alt="/" width={25} />
                              </span>
                            ) : (
                              <span className="p-0">
                                <img
                                  src={callIconIncoming}
                                  alt="/"
                                  width={25}
                                />
                              </span>
                            )}
                            {/* Display the modifiedBy or callToName or callOwner */}
                            {item?.callToName || item?.callOwner || ""}
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="lead_Information_mainContainer rounded-2  d-flex flex-column mx-3 my-4"
              style={{ backgroundColor: "transparent", width: "37%" }}
            >
              <div
                className="leadInformation_content_container justify-content-between "
                style={{ height: "100%" }}
              >
                <div
                  // className="timeline_main_container p-3 rounded-2 bg-white  d-flex flex-column gap-4 w-75 "
                  className={`timeline_main_container p-3 rounded-2 bg-white  d-flex flex-column gap-3`}
                  style={{ height: "100%" }}
                >
                  <div
                    className="d-flex  gap-4 justify-content-between px-2 bg-white rounded-5"
                    style={{
                      border: "0.95px solid rgba(231, 233, 238, 1)",
                      alignSelf: "flex-end",
                      padding: "7.5px 5px 7.5px 5px",
                    }}
                  >
                    <span
                      className={`cursorPointer callsTimeline ${
                        activeTab === "Info" ? "overviewTimelineTab" : ""
                      }`}
                      onClick={() => {
                        handleSelectActiveTab("Info");
                      }}
                    >
                      Info
                    </span>
                    <span
                      className={`cursorPointer callsTimeline ${
                        activeTab === "Timeline" ? "overviewTimelineTab" : ""
                      }`}
                      onClick={() => {
                        handleSelectActiveTab("Timeline");
                      }}
                    >
                      Timeline
                    </span>
                  </div>
                  <div
                    className="mx-1"
                    style={{ border: "1px solid rgba(226, 229, 235, 1)" }}
                  ></div>
                  <div
                    className="gap-4 justify-content-between px-2 bg-white px-1 rounded-3 py-1"
                    style={{ border: "0.95px solid rgba(231, 233, 238, 1)" }}
                  >
                    {activeTab === "Info" && (
                      <div className="historyContent_mainContainer p-3">
                        <div
                          className="d-flex w-100"
                          style={{ borderBottom: "1px solid #E2E5EB" }}
                        >
                          <div className="p-0">
                            <img
                              src={
                                infoDetails?.callImageData
                                  ? infoDetails?.callImageData
                                  : randomImage
                              }
                              alt="/"
                              width={50}
                            />
                          </div>
                          <div>
                            <p
                              className="ms-4 infoCall"
                              style={{
                                alignSelf: "self-end",
                                marginBottom: "5px",
                              }}
                            >
                              {callDetails.callOwner}
                            </p>
                            <p
                              className="ms-4 infoCall"
                              style={{
                                alignSelf: "self-end",
                                fontSize: "14px",
                              }}
                            >
                              {infoDetails?.mobile !== ""
                                ? infoDetails?.mobile
                                : "-"}
                            </p>
                          </div>
                        </div>{" "}
                        <div
                          className="d-flex flex-column w-100"
                          style={{ borderBottom: "1px solid #E2E5EB" }}
                        >
                          <div className="pt-2 pb-0 d-flex">
                            <span>
                              <img
                                src={AccountsInfoContainer}
                                alt="/"
                                width={25}
                              />
                            </span>
                            <p className="mb-0 ms-1 infoTextCall">
                              {infoDetails?.leadName !== ""
                                ? infoDetails?.leadName
                                : "-"}
                            </p>
                          </div>
                          <div className="pt-1 pb-0 d-flex">
                            <span>
                              <img src={telephoneInfo} alt="/" width={25} />
                            </span>
                            <p className="mb-0 ms-1 infoTextCall">
                              {infoDetails?.phone !== ""
                                ? infoDetails?.phone
                                : "-"}
                            </p>
                          </div>
                          <div className="pt-1 pb-0 d-flex">
                            <span>
                              <img src={callInfoContainer} alt="/" width={25} />
                            </span>
                            <p className="mb-0 ms-1 infoTextCall">
                              {infoDetails?.mobile !== ""
                                ? infoDetails?.mobile
                                : "-"}
                            </p>
                          </div>
                          <div className="pt-1 pb-1 d-flex">
                            <span>
                              <img src={mailInfo} alt="/" width={25} />
                            </span>
                            <p className="mb-0 ms-1 infoTextCall">
                              {infoDetails?.email !== ""
                                ? infoDetails?.email
                                : "-"}
                            </p>
                          </div>
                        </div>
                        <div className="d-flex w-100">
                          <div className="w-100">
                            <p
                              className="ms-0 infoCall mt-3"
                              style={{
                                alignSelf: "self-end",
                                marginBottom: "15px",
                                fontSize: "20px",
                              }}
                            >
                              Open Activities
                            </p>
                            <div>
                              {openActivities.map((item, index) => {
                                return (
                                  <div
                                    className="ps-2 mb-2 rounded-2 pt-2 pb-2"
                                    style={{
                                      backgroundColor: "#2177FE1A",
                                      color: "#9C9C9C",
                                      fontWeight: "300",
                                      fontSize: "16px",
                                    }}
                                    key={index}
                                  >
                                    {item.openActivitiesCount}
                                  </div>
                                );
                              })}
                            </div>
                          </div>
                        </div>{" "}
                      </div>
                    )}
                    {activeTab === "Timeline" && (
                      <div className="historyContent_mainContainer ">
                        {timeLineDataList &&
                        Object.keys(timeLineDataList).length > 0 ? (
                          Object.keys(timeLineDataList).map((date) => (
                            <div
                              className="history_container d-flex flex-column"
                              key={date}
                            >
                              {timeLineDataList[date].map((item, index) => (
                                <>
                                  <div
                                    className="d-flex gap-2"
                                    key={index}
                                    style={{
                                      alignItems: "center",
                                      borderBottom: "1px solid #E2E5EB",
                                    }}
                                  >
                                    <span className="pt-2 w-100">
                                      {`${item.tmlDescription}`}
                                      <span
                                        style={{
                                          color: "rgba(33, 119, 254, 1)",
                                          display: "contents",
                                          width: "100%",
                                        }}
                                      >{` ${item.tmlCreatedBy}`}</span>
                                      <span>{`${date}`}</span>
                                    </span>
                                  </div>
                                </>
                              ))}
                            </div>
                          ))
                        ) : (
                          <div
                            className="text-center d-flex justify-content-center align-items-center opacity-75"
                            style={{ height: "20vh" }}
                          >
                            <h4 className="h-75">No Data available</h4>
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="w-75 rounded-2 px-3 py-4 leadDetail_main_Container ">
              <div className="bg-white common_bg px-3 py-4 rounded-2">
                <div className="lead_Information_mainContainer commonContainer_bg rounded-2  d-flex flex-column my-2">
                  <div className="leadInformation_content_container d-flex justify-content-between width_container">
                    <div className="leadContact_container d-flex gap-4 w-100">
                      <div className="d-flex flex-column px-2 py-2 w-100">
                        {fieldDropdowns.slice(0, 10).map((field) => {
                          const fieldValue =
                            field.value ||
                            field.name
                              .replace(/\s+/g, "")
                              .replace(/^(.)/, (match) => match.toLowerCase());
                          return (
                            <div
                              className="d-flex justify-content-between align-items-center my-1 w-100 gap-3"
                              key={fieldValue}
                            >
                              <div className="opacity-75 p-2 w-100">
                                {field.name}
                              </div>
                              <div className="w-100 ">
                                {field.type === "input" ? (
                                  <EditableInput
                                    fieldType={
                                      ["dueDate", "poDate"].includes(fieldValue)
                                        ? "date"
                                        : "text"
                                    }
                                    className="ps-3"
                                    inputType={fieldValue}
                                    handleIndividualInputChange={
                                      handleIndividualInputChange
                                    }
                                    editingFields={editingFields}
                                    handleEditClick={handleEditClick}
                                    handleCancelClick={handleCancelClick}
                                    handleUpdateClick={handleUpdateClick}
                                    updatedFields={callInfo}
                                    accountOverviewDetails={callDetails}
                                    setShowType={setShowType}
                                    showType={showType}
                                    fieldsType="second"
                                  />
                                ) : field.type === "readOnly" ? (
                                  <div>
                                    <span className="d-flex flex-column px-0">
                                      <span className="px-0 pb-0">
                                        {
                                          callDetails[
                                            field.name === "Created By"
                                              ? "createdBy"
                                              : "modifiedBy"
                                          ]
                                        }
                                      </span>
                                      <span className="small opacity-75 px-0 pt-0">
                                        {
                                          callDetails[
                                            field.name === "Created By"
                                              ? "createdTime"
                                              : "modifiedTime"
                                          ]
                                        }
                                      </span>
                                    </span>
                                  </div>
                                ) : field.type === "nonEditable" ? (
                                  <span className="d-flex flex-column">
                                    {callDetails[fieldValue]}
                                  </span>
                                ) : (
                                  <div className="position-relative">
                                    <div
                                      className="d-flex input-with-clear "
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleInputClick("Owner");
                                      }}
                                    >
                                      <input
                                        type="text"
                                        className="insertCommonInputFieldCalls cursorPointer indvEditInput border-0"
                                        value={callDetails.callOwner}
                                        readOnly
                                      />
                                    </div>
                                    {listVisibleType === "Owner" &&
                                      listVisible && (
                                        <ul
                                          className="data-list list-group  lead_ownerList_container_meetings position-absolute"
                                          style={{ top: "55px", width: "100%" }}
                                        >
                                          {userList.map((item, index) => (
                                            <li
                                              key={index}
                                              className="cursorPointer list-group-item"
                                              onClick={() =>
                                                handleInputClickSet(
                                                  item,
                                                  "Owner"
                                                )
                                              }
                                            >
                                              <div className="d-flex flex-column">
                                                <span className="p-0">
                                                  {" "}
                                                  {item.name}{" "}
                                                </span>
                                                <span className="p-0 small">
                                                  {" "}
                                                  {item.emailId}{" "}
                                                </span>
                                              </div>
                                            </li>
                                          ))}
                                        </ul>
                                      )}
                                  </div>
                                )}
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                </div>

                {callDetails.callType === "Outbound" && (
                  <div className="lead_Information_mainContainer commonContainer_bg rounded-2  d-flex flex-column mb-2 mt-4">
                    <div className="leadInformation_content_container justify-content-between">
                      <h5 className="w-100 text-primary px-2 py-2 ms-1 my-1">
                        Purpose Of Outgoing Call
                      </h5>
                      <div className="d-flex">
                        <div className="leadContact_container d-flex gap-4">
                          <div className="opacity-75 d-flex flex-column gap-3 px-2 py-2">
                            <span>Call Purpose</span>
                            <span>Call Agenda</span>
                          </div>
                          <div className="d-flex flex-column gap-3 px-2 py-2">
                            {/* <span>{callDetails.callPurpose}</span> */}
                            <div
                              className="position-relative mb-1"
                              onClick={() => {
                                handleEditClick("callPurpose");
                              }}
                            >
                              {editingFields.callPurpose ? (
                                <div className="">
                                  <Select
                                    value={callInfo.callPurpose}
                                    menuPlacement="auto"
                                    onChange={(selectedOption) =>
                                      handleChangeSelectDropDown(
                                        selectedOption,
                                        "callPurpose"
                                      )
                                    }
                                    options={callPurposeOptions}
                                    styles={fieldDropdownStyles}
                                    isSearchable={false}
                                  />
                                  <div
                                    className="position-absolute end-0 top-0 mt-1"
                                    style={{ marginRight: "-3.5rem" }}
                                  >
                                    <div className="d-flex gap-2">
                                      <span
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          setShowType("");
                                          handleUpdateClick("callPurpose", "");
                                        }}
                                        className="ps-2 cursorPointer"
                                      >
                                        <img
                                          src={updateIcon}
                                          alt="img"
                                          height={20}
                                          width={20}
                                        />
                                      </span>
                                      <span
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          setShowType("");
                                          handleCancelClick("callPurpose");
                                        }}
                                        className="cursorPointer"
                                      >
                                        <img
                                          src={cancelIcon}
                                          alt="img"
                                          height={20}
                                          width={20}
                                        />
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              ) : (
                                <div
                                  onClick={() => {
                                    handleEditClick("callPurpose");
                                  }}
                                  className="w-100 bg-transparent border rounded-1 p-1"
                                >
                                  {callDetails.callPurpose}
                                </div>
                              )}
                            </div>
                            {/* <span>{callDetails.callAgenda}</span> */}
                            <EditableInput
                              className="ps-3"
                              inputType="callAgenda"
                              handleIndividualInputChange={
                                handleIndividualInputChange
                              }
                              editingFields={editingFields}
                              handleEditClick={handleEditClick}
                              handleCancelClick={handleCancelClick}
                              handleUpdateClick={handleUpdateClick}
                              updatedFields={callInfo}
                              accountOverviewDetails={callDetails}
                              setShowType={setShowType}
                              showType={showType}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {callDetails.callDuration && (
                  <div className="lead_Information_mainContainer commonContainer_bg rounded-2  d-flex flex-column mb-2 mt-4">
                    <div className="leadInformation_content_container justify-content-between ">
                      <h5 className="w-100 text-primary px-2 py-2 ms-1 my-1">
                        {callDetails.callType === "Outbound" &&
                          "Outcome Of Outgoing Call"}
                        {callDetails.callType === "Inbound" &&
                          "Reason for Incoming Call"}
                      </h5>
                      <div className="d-flex">
                        <div className="leadContact_container d-flex gap-4">
                          <div className="opacity-75 d-flex flex-column gap-3 px-2 py-2">
                            {callDetails.callType === "Outbound" && (
                              <span>Call Result</span>
                            )}
                            {callDetails.callType !== "Missed" && (
                              <span>Description</span>
                            )}
                          </div>
                          <div className="d-flex flex-column gap-3 px-2 py-2">
                            {/* <span>{callDetails.callResult}</span> */}
                            {callDetails.callType === "Outbound" && (
                              <div
                                className="position-relative mb-1"
                                onClick={() => {
                                  handleEditClick("callResult");
                                }}
                              >
                                {editingFields.callResult ? (
                                  <div className="">
                                    <Select
                                      value={callInfo.callResult}
                                      menuPlacement="auto"
                                      onChange={(selectedOption) =>
                                        handleChangeSelectDropDown(
                                          selectedOption,
                                          "callResult"
                                        )
                                      }
                                      options={callResultOptions}
                                      styles={fieldDropdownStyles}
                                      isSearchable={false}
                                    />
                                    <div
                                      className="position-absolute end-0 top-0 mt-1"
                                      style={{ marginRight: "-3.5rem" }}
                                    >
                                      <div className="d-flex gap-2">
                                        <span
                                          onClick={(e) => {
                                            e.stopPropagation();
                                            setShowType("");
                                            handleUpdateClick("callResult", "");
                                          }}
                                          className="ps-2 cursorPointer"
                                        >
                                          <img
                                            src={updateIcon}
                                            alt="img"
                                            height={20}
                                            width={20}
                                          />
                                        </span>
                                        <span
                                          onClick={(e) => {
                                            e.stopPropagation();
                                            setShowType("");
                                            handleCancelClick("callResult");
                                          }}
                                          className="cursorPointer"
                                        >
                                          <img
                                            src={cancelIcon}
                                            alt="img"
                                            height={20}
                                            width={20}
                                          />
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                ) : (
                                  <div
                                    onClick={() => {
                                      handleEditClick("callResult");
                                    }}
                                    className="w-100 bg-transparent border rounded-1 p-1"
                                  >
                                    {callDetails.callResult}
                                  </div>
                                )}
                              </div>
                            )}
                            {/* <span>{callDetails.description}</span> */}
                            {callInfo.callType.value !== "Missed" && (
                              <EditableInput
                                className="ps-3"
                                inputType="description"
                                handleIndividualInputChange={
                                  handleIndividualInputChange
                                }
                                editingFields={editingFields}
                                handleEditClick={handleEditClick}
                                handleCancelClick={handleCancelClick}
                                handleUpdateClick={handleUpdateClick}
                                updatedFields={callInfo}
                                accountOverviewDetails={callDetails}
                                setShowType={setShowType}
                                showType={showType}
                              />
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {notePermission === "TRUE" && (
                  <div
                    id="notesSection"
                    className="lead_Information_mainContainer commonContainer_bg rounded-2  d-flex flex-column py-2 mt-2"
                  >
                    <div>
                      <Note
                        note={note}
                        addTitle={addTitle}
                        showButtons={showButtons}
                        setShowButtons={setShowButtons}
                        textFile={selectedNoteFiles}
                        showTitleNote={showTitleNote}
                        setShowTitleNote={setShowTitleNote}
                        handleTextAreaClick={handleTextAreaClick}
                        handleSaveButton={insertNote}
                        handleAddTitleClick={handleAddTitleClick}
                        setNoteTitle={setNoteTitle}
                        noteInfo={noteInfo}
                        selectRecent={selectRecent}
                        handleSelectRecent={handleSelectRecent}
                        handleDeleteClick={deleteNoteDetails}
                        leadId={callId}
                        handleUpdateClick={updateNote}
                        setUpdateNotes={setUpdateNotes}
                        updateNotes={updateNotes}
                        setEditNote={setEditNote}
                        editNote={editNote}
                        handleChangeNoteTitle={handleChangeNoteTitle}
                        handleChangeNoteDescription={
                          handleChangeNoteDescription
                        }
                        setAddTitle={setAddTitle}
                        setNote={setNote}
                        moduleType={"Calls"}
                        recordName={callDetails?.callOwner}
                        selectedNoteFiles={selectedNoteFiles}
                        setSelectedNoteFiles={setSelectedNoteFiles}
                        onChildDataChange={handleChildDataChange}
                        noteDeleteConfirmation={noteDeleteConfirmation}
                        setNoteDeleteConfirmation={setNoteDeleteConfirmation}
                      />

                      {showTitleNote && (
                        <textarea
                          rows={2}
                          className="notesClass px-2"
                          placeholder="Add note Title"
                          value={noteTitle}
                          onChange={(e) => setNoteTitle(e.target.value)}
                          style={{ width: "95%", marginTop: "10px" }}
                        />
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  useEffect(() => {
    getAllCalls(selectedOption);
  }, [currentPage, perPage]);

  useEffect(() => {
    setInnerPage(innerPage || "list");
    // setShowOptionsContact(false);
    getAllContact();
    getAllLeadsDetails();
  }, []);

  useEffect(() => {
    getAllContact();
  }, [currentPageContact, perPageContact]);

  useEffect(() => {
    getAllLeadsDetails();
  }, [currentPageLead, perPageLead]);

  useEffect(() => {
    if (contactList.length > 0) {
      setSelectedContact(contactList[0]);
    }
  }, [contactList]);

  useEffect(() => {
    if (leadList.length > 0) {
      setSelectedLeadItem(leadList[0]);
    }
  }, [leadList]);

  useEffect(() => {
    const foundItem = userList?.find((item) => item.userId === loginUserId);
    if (foundItem) {
      setSelectedItem(foundItem);
    }
  }, [loginUserId]);

  const handleCallView = () => {
    if (innerPage === "list") {
      return <>{callHomePage()}</>;
    } else if (innerPage === "overview") {
      return <>{overviewPage()}</>;
    }
  };

  return (
    <div
      onClick={() => {
        setShowHeadings(false);
        setShowSelectedFilter("");
        // setListVisibleType("");
        setShowSelectedFilter("");
        setModuleListVisible(false);
        setListVisible(false);
        setIsDropdownOpenEdit(false);
        setIsDropdownOpen(false);
        setDropdownCallPurpose(false);
        setDropdownRelatedTo(false);
        setDropdownSelectReminder(false);
        setDropdownCallResult(false);
        setDropdownSelect(false);
      }}
    >
      {handleCallView()}
      <Modal
        show={modalType === "Manage Columns"}
        id="ConvertModal"
        aria-labelledby="example-custom-modal-styling-title"
        className="withdrw_modal"
      >
        <Modal.Body>
          <div className="modalClass  position-relative">
            <div className="d-flex justify-content-between ">
              <h4 className="">Manage Columns</h4>
            </div>
            <div className="w-100 position-relative">
              <div className="mb-1 pt-2 position-relative w-100">
                <input
                  type="text"
                  className="assetSearchbar searchbar rounded-2 p-2 pe-5 common_input_bg w-100 "
                  placeholder="Search"
                  aria-label="Username"
                  aria-describedby="basic-addon1"
                  value={searchColumn}
                  onChange={(e) => {
                    if (e.target.value.charAt(0) !== " ") {
                      const value = e.target.value.replace(
                        /[^a-zA-Z ]+|(\s)\s+/g,
                        "$1"
                      );
                      setSearchColumn(value);
                    }
                  }}
                />
                <span
                  className="position-absolute  mt-2 me-2 end-0 opacity-75 cursorPointer"
                  onClick={() => {
                    setSearchColumn("");
                    setSavingHeadings(callsColumn);
                  }}
                >
                  {searchColumn === "" ? (
                    <img src={searchIcon} alt="" width={20} />
                  ) : (
                    "X"
                  )}
                </span>
              </div>
              <div className=" opacity-75 columns_container">
                {savingHeadings
                  .filter((lead) =>
                    lead.name
                      .toLowerCase()
                      .includes(searchColumn.trim().toLowerCase())
                  )
                  .sort((a, b) => a.name.localeCompare(b.name))
                  .map((column) => {
                    return (
                      <div
                        key={column.name}
                        className="d-flex gap-2 py-2 rounded-2 align-items-center"
                      >
                        <div className="d-flex gap-2 ps-2">
                          <input
                            type="checkbox"
                            id={column.name}
                            name={column.name}
                            checked={column.isChecked}
                            onChange={(e) => {
                              handleColumnCheckboxChange(
                                column.name,
                                e.target.checked
                              );
                            }}
                            className="cursorPointer"
                          />
                          <label
                            className="small cursorPointer"
                            htmlFor={column.name}
                          >
                            {column.name}
                          </label>
                        </div>
                      </div>
                    );
                  })}
              </div>
              <aside className="float-end mt-2">
                <div className="d-flex align-items-center gap-4">
                  <button
                    className="cancel_btn bg-light"
                    onClick={() => {
                      setShowModalType("");
                      setIsComplete(0);
                    }}
                  >
                    Cancel
                  </button>
                  <button
                    className="save_btn"
                    onClick={() => {
                      setSelectedHeadings(savingHeadings);
                      setIsComplete(0);
                      setTimeout(() => {
                        setShowModalType("");
                      }, 200);
                    }}
                  >
                    Save
                  </button>
                </div>
              </aside>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        show={modalType === "ScheduleCall"}
        id="ConvertModal"
        aria-labelledby="example-custom-modal-styling-title"
        className={`withdrw_modal rounded-2 modal-lg ${
          overlapModal ? "opacity-75" : ""
        }`}
        onClick={() => {
          setDropdownCallPurpose(false);
          setDropdownRelatedTo(false);
          setDropdownSelectReminder(false);
          setDropdownCallResult(false);
          setDropdownSelect(false);
        }}
      >
        <Modal.Body
          className="p-0 rounded-2"
          onClick={() => {
            setShowCallStartTime({
              hour: false,
              minutes: false,
            });
          }}
        >
          <div className="modalClass position-relative">
            <div
              className="d-flex justify-content-between px-4 pt-4 pb-2 rounded-2"
              style={{ background: "rgba(242, 242, 242, 1)" }}
            >
              <h4 className="commonTextColor">
                {modalView === "ScheduleCall"
                  ? editPurchaseOverview
                    ? "Edit Schedule a Call"
                    : "Schedule a Call"
                  : isComplete === 1
                  ? "Complete"
                  : editPurchaseOverview
                  ? "Edit Log a Call"
                  : "Log a Call"}
              </h4>

              <img
                src={cancelCircle}
                alt="cancel"
                style={{
                  width: "25px",
                  height: "25px",
                  cursor: "pointer",
                }}
                onClick={() => {
                  setShowModalType("");
                  setCallInfo(callInformation);
                  setIsComplete(0);
                  setSelectedOptionLead(leadContactOptions[0]);

                  setCallStartTime({
                    hour: "00",
                    minutes: "00",
                    meridiem: "AM",
                  });
                }}
              />
            </div>
            <div className="w-100 position-relative">
              <div className="columns_container ps-4 pe-2 pt-2 pb-3">
                <div className="inputFieldDivCalls mt-2 gap-3">
                  <div className="inputFieldDivLeftCalls">
                    <p className="mb-0 mb-1" style={{ color: "#515151" }}>
                      Call{" "}
                      {callInfo.callType.value === "Outbound" ? "To" : "From"}
                      <span className="text-danger">*</span>
                    </p>
                    <Select
                      options={leadContactOptions}
                      styles={createAccountStyles}
                      value={selectedOptionLead}
                      onChange={handleChangeLead}
                      isSearchable={false}
                    />
                  </div>

                  <div className="inputFieldDivLeftCalls">
                    <p className="mb-0 mb-1" style={{ color: "#515151" }}>
                      {selectedOptionLead.value}
                      <span className="text-danger"></span>
                    </p>
                    {/* <input
                      type="text"
                      placeholder="Search by Name"
                      className="insertCommonInputFieldCalls"
                      value={selectedOptionLead.value}
                    /> */}
                    {selectedOptionLead.value === "Contact" && (
                      <div
                        className="d-flex input-with-clear "
                        onClick={(e) => {
                          e.stopPropagation();
                          handleInputClick("Contacts");
                          setDropdownCallPurpose(false);
                          setDropdownRelatedTo(false);
                          setDropdownSelectReminder(false);
                          setDropdownCallResult(false);
                          setDropdownSelect(false);
                        }}
                        style={{
                          alignItems: "baseline",
                        }}
                      >
                        <div className="d-flex flex-column w-100">
                          <input
                            type="text"
                            //   placeholder="Vendor Owner"
                            className={
                              mandatoryMsgLeadContact
                                ? "mandatoryField w-100"
                                : "insertCommonInputFieldCalls cursorPointer w-100"
                            }
                            value={selectedContact.contactName}
                            readOnly
                          />
                          {mandatoryMsgLeadContact && (
                            <p className="p-0 m-0" style={{ color: "#fa5a5a" }}>
                              {mandatoryMsgLeadContact}
                            </p>
                          )}
                        </div>
                        {listVisible ? (
                          <span
                            className="clear-buttonCalls"
                            onClick={() => setSelectedContact("")}
                          >
                            <img src={up} alt="img" height={20} width={20} />
                          </span>
                        ) : (
                          <span
                            className="clear-buttonCalls"
                            // onClick={() => setSelectedItem("")}
                          >
                            <img src={down} alt="img" height={20} width={20} />
                          </span>
                        )}

                        <span
                          className="cursorPointer pt-2 ps-1"
                          onClick={() => setIsContactListModal(true)}
                        >
                          <img
                            src={userSearch}
                            alt="img"
                            width={20}
                            height={20}
                          />
                        </span>
                        {listVisibleType === "Contacts" && listVisible && (
                          <ul
                            className="data-list list-group  lead_ownerList_container_meetings position-absolute "
                            style={{ top: "3.2rem", width: "21.8rem" }}
                          >
                            {contactList.map((item, index) => (
                              <li
                                key={index}
                                className="cursorPointer list-group-item"
                                onClick={() =>
                                  handleInputClickSet(item, "Contacts")
                                }
                              >
                                <div className="d-flex flex-column">
                                  <span> {item.contactName} </span>
                                </div>
                              </li>
                            ))}
                          </ul>
                        )}
                      </div>
                    )}
                    {selectedOptionLead.value === "Lead" && (
                      <div
                        className="d-flex input-with-clear "
                        onClick={(e) => {
                          e.stopPropagation();
                          handleInputClick("Leads");
                          setDropdownCallPurpose(false);
                          setDropdownRelatedTo(false);
                          setDropdownSelectReminder(false);
                          setDropdownCallResult(false);
                          setDropdownSelect(false);
                        }}
                        style={{
                          alignItems: "baseline",
                        }}
                      >
                        <div className="d-flex flex-column w-100">
                          <input
                            type="text"
                            //   placeholder="Vendor Owner"
                            className={
                              mandatoryMsgLeadContact
                                ? "mandatoryField w-100"
                                : "insertCommonInputFieldCalls cursorPointer w-100"
                            }
                            value={selectedLeadItem.leadName}
                            readOnly
                          />
                          {mandatoryMsgLeadContact && (
                            <p className="p-0 m-0" style={{ color: "#fa5a5a" }}>
                              {mandatoryMsgLeadContact}
                            </p>
                          )}
                        </div>
                        {listVisible ? (
                          <span
                            className="clear-buttonCalls"
                            onClick={() => setSelectedLeadItem("")}
                          >
                            <img src={up} alt="img" height={20} width={20} />
                          </span>
                        ) : (
                          <span
                            className="clear-buttonCalls"
                            // onClick={() => setSelectedItem("")}
                          >
                            <img src={down} alt="img" height={20} width={20} />
                          </span>
                        )}

                        <span
                          className="cursorPointer pt-2 ps-1"
                          onClick={() => setIsLeadListModal(true)}
                        >
                          <img
                            src={userSearch}
                            alt="img"
                            width={20}
                            height={20}
                          />
                        </span>
                        {listVisibleType === "Leads" && listVisible && (
                          <ul
                            className="data-list list-group  lead_ownerList_container_meetings position-absolute "
                            style={{ top: "3.2rem", width: "21.4rem" }}
                          >
                            {leadList.map((item, index) => (
                              <li
                                key={index}
                                className="cursorPointer list-group-item"
                                onClick={() =>
                                  handleInputClickSet(item, "Leads")
                                }
                              >
                                <div className="d-flex flex-column">
                                  <span> {item.leadName} </span>
                                </div>
                              </li>
                            ))}
                          </ul>
                        )}
                      </div>
                    )}
                  </div>
                </div>
                <div className="inputFieldDivCalls mt-4 gap-3">
                  <div className="inputFieldDivLeftCalls">
                    <p className="mb-0 mb-1" style={{ color: "#515151" }}>
                      Related To
                    </p>
                    {/* <Select
                      options={relatedToOptions}
                      styles={createAccountStyles}
                      value={selectedOptionModule}
                      onChange={handleChangeModule}
                      isDisabled={selectedOptionLead.value === "Lead"}
                      isSearchable={false}
                    /> */}
                    <div className="d-flex input-with-clear">
                      <input
                        className="insertCommonInputFieldCalls cursorPointer"
                        value={selectedOptionModule.label}
                        onClick={(e) => {
                          e.stopPropagation();
                          setDropdownCallPurpose(false);
                          setDropdownRelatedTo(!dropdownRelatedTo);
                          setDropdownSelectReminder(false);
                          setListVisible(false);
                          setModuleListVisible(false);
                          setDropdownCallResult(false);
                          setDropdownSelect(false);
                        }}
                        readOnly
                        disabled={selectedOptionLead.value === "Lead"}
                      />
                      {dropdownRelatedTo ? (
                        <>
                          <span
                            className="clear-buttonCalls"
                            onClick={() => setDropdownRelatedTo(false)}
                            style={{
                              top: "1.5rem",
                              left: "19.5rem",
                            }}
                          >
                            <img src={up} alt="img" height={20} width={20} />
                          </span>
                        </>
                      ) : (
                        <>
                          <span
                            className="clear-buttonCalls"
                            style={{
                              top: "1.5rem",
                              left: "19.5rem",
                            }}
                          >
                            <img src={down} alt="img" height={20} width={20} />
                          </span>
                        </>
                      )}
                      {dropdownRelatedTo && (
                        <ul
                          className="data-list list-group  lead_ownerList_container position-absolute "
                          style={{
                            top: "3.25rem",
                            width: "21.9rem",
                            minHeight: "max-content",
                          }}
                        >
                          {relatedToOptions.map((item, index) => (
                            <li
                              key={index}
                              className="cursorPointer list-group-item"
                              onClick={() => {
                                handleChangeModule(item);
                                setDropdownRelatedTo(false);
                                setDropdownCallPurpose(false);
                                setSelectedOptionModule(item);
                              }}
                            >
                              <div className="d-flex flex-column">
                                <span> {item.label} </span>
                              </div>
                            </li>
                          ))}
                        </ul>
                      )}
                    </div>
                  </div>
                  <div className="inputFieldDivLeftCalls position-relative">
                    <p className="mb-0 mb-1">{selectedOptionModule.label}</p>
                    <div
                      className="d-flex input-with-clear "
                      onClick={handleModuleClick}
                    >
                      <input
                        type="text"
                        placeholder="Search"
                        className="insertCommonInputFieldCalls cursorPointer"
                        value={
                          selectedOptionLead.value === "Lead"
                            ? ""
                            : moduleWiseData.length > 0
                            ? selectedModuleItem.moduleName
                            : ""
                        }
                        disabled={selectedOptionLead.value === "Lead"}
                        readOnly
                      />
                      {selectedOptionLead.value === "Lead" &&
                      moduleListVisible ? (
                        <span className="clear-buttonCalls">
                          <img
                            src={moduleListVisible ? up : down}
                            alt="img"
                            height={20}
                            width={20}
                          />{" "}
                        </span>
                      ) : (
                        <span
                          className="clear-buttonCalls"
                          onClick={handleModuleClick}
                        >
                          <img
                            src={moduleListVisible ? up : down}
                            alt="img"
                            height={20}
                            width={20}
                          />{" "}
                        </span>
                      )}
                    </div>
                    {moduleListVisible && moduleWiseData.length > 0 && (
                      <ul
                        className="data-list list-group  lead_ownerList_container_meetings position-absolute "
                        // style={{
                        //   top: "200px",
                        //    width: "27.5vw",
                        // }}

                        style={{ top: "4.9rem", width: "21.8rem" }}
                      >
                        {" "}
                        {moduleWiseData.map((item, index) => (
                          <li
                            key={index}
                            className="cursorPointer list-group-item"
                            onClick={() => handleModuleItemClick(item)}
                          >
                            {item.moduleName}
                          </li>
                        ))}
                      </ul>
                    )}
                  </div>
                </div>
                <div
                  className="inputFieldDivCalls mt-4 gap-3"
                  style={{ alignItems: "start" }}
                >
                  <div className="inputFieldDivLeftCalls">
                    <p className="mb-0 mb-1" style={{ color: "#515151" }}>
                      Call Type<span className="text-danger">*</span>
                    </p>
                    {modalView === "ScheduleCall" || isComplete === 1 ? (
                      <input
                        type="text"
                        className="insertCommonInputFieldCalls"
                        value="Outbound"
                        readOnly
                      />
                    ) : (
                      <>
                        {editPurchaseOverview ? (
                          <input
                            type="text"
                            className="insertCommonInputFieldCalls"
                            value={callInfo.callType.value}
                            readOnly
                          />
                        ) : (
                          <Select
                            options={callTypeOptions}
                            styles={createAccountStyles}
                            value={callInfo.callType}
                            menuPlacement="auto"
                            onChange={(selectedOption) =>
                              handleChangeSelectDropDown(
                                selectedOption,
                                "callType"
                              )
                            }
                            isSearchable={false}
                          />
                        )}
                      </>
                    )}
                  </div>
                  <div className="inputFieldDivLeftCalls">
                    {callInfo.callType.value === "Outbound" ? (
                      <>
                        <p className="mb-0 mb-1" style={{ color: "#515151" }}>
                          Outgoing Call Status
                          <span className="text-danger">*</span>
                        </p>
                        <input
                          type="text"
                          className="insertCommonInputFieldCalls"
                          style={{ cursor: "not-allowed" }}
                          value={
                            modalView === "ScheduleCall"
                              ? "Scheduled"
                              : "Completed"
                          }
                        />
                      </>
                    ) : (
                      <>
                        <div className="inputFieldDivLeftCalls">
                          <p
                            className="mb-0 mb-1"
                            style={{
                              color: "#515151",
                            }}
                          >
                            Subject<span className="text-danger">*</span>
                          </p>
                          <input
                            type="text"
                            // className="insertCommonInputFieldCalls"
                            className={
                              mandatoryMsg
                                ? "mandatoryField w-100"
                                : "insertCommonInputFieldCalls"
                            }
                            name="subject"
                            value={callInfo.subject}
                            onChange={handleChangeCalls}
                            maxLength={100}
                          />
                          <p style={{ color: "#fa5a5a" }}>
                            {mandatoryMsg && mandatoryMsg}
                          </p>
                        </div>
                      </>
                    )}
                  </div>
                </div>
                <div className="inputFieldDivCalls mt-3 gap-3">
                  <div
                    className="inputFieldDivLeftCalls w-100"
                    style={{ alignSelf: "flex-start" }}
                  >
                    <p
                      className="p-0 mb-1 mt-0 mx-0"
                      style={{ color: "#515151" }}
                    >
                      Call Start Date<span className="text-danger">*</span>
                    </p>
                    {editPurchaseOverview && isComplete === 0 ? (
                      <input
                        type="text"
                        className="insertCommonInputFieldCalls w-100"
                        value={callInfo.callStartDate}
                        readOnly
                      />
                    ) : (
                      <input
                        type="date"
                        className="insertCommonInputFieldCalls w-100"
                        name="callStartDate"
                        value={callInfo.callStartDate}
                        onChange={handleChangeCalls}
                        min={
                          modalView === "ScheduleCall"
                            ? new Date().toISOString().split("T")[0]
                            : new Date(
                                new Date().getFullYear() - 5,
                                new Date().getMonth(),
                                new Date().getDate()
                              )
                                .toISOString()
                                .split("T")[0]
                        }
                        max={
                          modalView === "ScheduleCall"
                            ? new Date(
                                new Date().getFullYear() + 1,
                                new Date().getMonth(),
                                new Date().getDate()
                              )
                                .toISOString()
                                .split("T")[0]
                            : new Date().toISOString().split("T")[0]
                        }
                        onKeyDown={(e) => e.preventDefault()}
                      />
                    )}
                  </div>

                  <div className="inputFieldDivLeftCalls">
                    <p className="mb-1" style={{ color: "#515151" }}>
                      Call Start Time<span className="text-danger">*</span>
                    </p>
                    {editPurchaseOverview && isComplete === 0 ? (
                      <div
                        // type="text"
                        className="insertCommonInputFieldCalls d-flex gap-1"
                        // value={callInfo.callStartTime}
                        // readOnly
                      >
                        {callDetails.callStartTime}
                      </div>
                    ) : (
                      <>
                        <div
                          className={
                            mandatoryMsgTime
                              ? "mandatoryField w-100 d-flex gap-1"
                              : "insertCommonInputFieldCalls d-flex gap-1"
                          }
                        >
                          {/* <input
                          id="callStartTime"
                          type="time"
                          className="w-100 opacity-0"
                          name="callStartTime"
                          value={callInfo.callStartTime}
                          onChange={handleChangeCalls}
                        />
                        <label
                          htmlFor="callStartTime"
                          className="custom_timer w-100"
                        >
                          {convertTo12HourFormat(callInfo.callStartTime)}
                          <span
                            className="text-end position-absolute end-0 pe-1"
                            style={{ bottom: "0.9rem" }}
                          >
                            DT
                          </span>
                        </label> */}
                          <div className="hours_box position-relative">
                            <div
                              className="cursorPointer"
                              onClick={(e) => {
                                e.stopPropagation();
                                setShowCallStartTime({
                                  hour: !showCallStartTime.hour,
                                  minutes: false,
                                });
                              }}
                            >
                              {callStartTime.hour}
                            </div>
                            {showCallStartTime.hour ? (
                              <div className="hours_options position-absolute d-flex flex-column rounded-1">
                                {hours.map((hour, index) => {
                                  return (
                                    <span
                                      className="p-1 cursorPointer"
                                      onClick={() => {
                                        setCallStartTime({
                                          hour: hour,
                                          minutes: callStartTime.minutes,
                                          meridiem: callStartTime.meridiem,
                                        });
                                      }}
                                      key={index}
                                    >
                                      {hour}
                                    </span>
                                  );
                                })}
                              </div>
                            ) : null}
                          </div>
                          <div>:</div>
                          <div className="minutes_box position-relative">
                            <div
                              className="cursorPointer"
                              onClick={(e) => {
                                e.stopPropagation();
                                setShowCallStartTime({
                                  hour: false,
                                  minutes: !showCallStartTime.minutes,
                                });
                              }}
                            >
                              {callStartTime.minutes}
                            </div>
                            {showCallStartTime.minutes ? (
                              <div className="hours_options position-absolute d-flex flex-column rounded-1">
                                {" "}
                                {minutes.map((minute, index) => {
                                  return (
                                    <span
                                      className="p-1 cursorPointer"
                                      onClick={() => {
                                        setCallStartTime({
                                          hour: callStartTime.hour,
                                          minutes: minute,
                                          meridiem: callStartTime.meridiem,
                                        });
                                      }}
                                      key={index}
                                    >
                                      {minute}
                                    </span>
                                  );
                                })}
                              </div>
                            ) : null}
                          </div>
                          <div className="hours_box_type">
                            <div
                              className="cursorPointer"
                              onClick={(e) => {
                                e.stopPropagation();
                                setShowCallStartTime({
                                  hour: false,
                                  minutes: false,
                                });

                                setCallStartTime({
                                  hour: callStartTime.hour,
                                  minutes: callStartTime.minutes,
                                  meridiem:
                                    callStartTime.meridiem === "AM"
                                      ? "PM"
                                      : "AM",
                                });
                              }}
                            >
                              {callStartTime.meridiem}
                            </div>
                          </div>
                        </div>
                        {mandatoryMsgTime && (
                          <p style={{ color: "#fa5a5a" }}>{mandatoryMsgTime}</p>
                        )}
                      </>
                    )}
                  </div>
                </div>
                <div className="inputFieldDivCalls mt-4 gap-3">
                  {(modalView === "ScheduleCall" ||
                    callInfo.callType.value !== "Missed") && (
                    <div
                      className="inputFieldDivLeftCalls"
                      style={{ alignSelf: "flex-start" }}
                    >
                      <p
                        className="p-0 mb-1 mt-0 mx-0"
                        style={{ color: "#515151" }}
                      >
                        {modalView === "ScheduleCall"
                          ? "Call Owner"
                          : "Call Duration"}
                        {modalView === "ScheduleCall" ? (
                          <></>
                        ) : (
                          <span className="text-danger">*</span>
                        )}
                      </p>
                      {modalView === "ScheduleCall" ? (
                        <>
                          <div
                            className="d-flex input-with-clear "
                            // onClick={handleOwnerInputClick}
                            onClick={(e) => {
                              e.stopPropagation();
                              handleInputClick("Owner");
                            }}
                          >
                            <input
                              type="text"
                              //   placeholder="Vendor Owner"
                              className="insertCommonInputFieldCalls cursorPointer"
                              value={selectedItem?.name}
                              readOnly
                              style={{ height: "53px" }}
                            />
                            {listVisible ? (
                              <span
                                className="clear-buttonCalls"
                                onClick={() => setSelectedItem("")}
                              >
                                <img
                                  src={up}
                                  alt="img"
                                  height={20}
                                  width={20}
                                />
                              </span>
                            ) : (
                              <span
                                className="clear-buttonCalls"
                                // onClick={() => setSelectedItem("")}
                              >
                                <img
                                  src={down}
                                  alt="img"
                                  height={20}
                                  width={20}
                                />
                              </span>
                            )}

                            <span
                              className="cursorPointer pt-2 ps-1"
                              onClick={() => {
                                if (editPurchaseOverview) {
                                  setShowOwnerModalType("changeOwner");
                                } else {
                                  setIsLeadOwnerModal(!isLeadOwnerModal);
                                }
                              }}
                            >
                              <img
                                src={userSearch}
                                alt="img"
                                width={20}
                                height={20}
                              />
                            </span>
                            {listVisibleType === "Owner" && listVisible && (
                              <ul
                                className="data-list list-group  lead_ownerList_container_meetings position-absolute"
                                style={{ top: "55px", width: "18vw" }}
                              >
                                {userList.map((item, index) => (
                                  <li
                                    key={index}
                                    className="cursorPointer list-group-item"
                                    onClick={() =>
                                      handleInputClickSet(item, "Owner")
                                    }
                                  >
                                    <div className="d-flex flex-column">
                                      <span> {item.name} </span>
                                      <span> {item.emailId} </span>
                                    </div>
                                  </li>
                                ))}
                              </ul>
                            )}
                          </div>
                        </>
                      ) : (
                        <div
                          className="insertCommonInputFieldCalls d-flex gap-2"
                          style={{
                            width:
                              callInfo.callType.value === "Inbound"
                                ? "50%"
                                : "100%",
                          }}
                        >
                          <input
                            type="text"
                            // min={0}
                            // max={1}
                            maxLength={3}
                            style={{
                              border: "None",
                              width: "25px",
                            }}
                            placeholder="00"
                            name="minutes"
                            value={callInfo?.minutes}
                            onChange={handleChangeCalls}
                            onFocus={handleFocus}
                            onBlur={handleBlur}
                          />
                          <p>Minutes</p>
                          <input
                            type="text"
                            maxLength={2}
                            style={{
                              border: "None",
                              width: "25px",
                            }}
                            placeholder="00"
                            name="seconds"
                            value={callInfo.seconds}
                            onChange={handleChangeCalls}
                            onFocus={handleFocus}
                            onBlur={handleBlur}
                          />
                          <p>Seconds</p>
                        </div>
                      )}
                    </div>
                  )}
                  {callInfo.callType.value === "Outbound" && (
                    <div
                      className="inputFieldDivLeftCalls"
                      style={{
                        width:
                          callInfo.callType.value !== "Missed" ? "100%" : "50%",
                      }}
                    >
                      <p
                        className="mb-0 mb-1"
                        style={{
                          color: "#515151",
                        }}
                      >
                        Subject<span className="text-danger">*</span>
                      </p>
                      <input
                        type="text"
                        // className="insertCommonInputFieldCalls"
                        className={
                          mandatoryMsg
                            ? "mandatoryField w-100"
                            : "insertCommonInputFieldCalls"
                        }
                        name="subject"
                        value={callInfo.subject}
                        onChange={handleChangeCalls}
                        maxLength={100}
                      />
                      <p style={{ color: "#fa5a5a" }}>
                        {mandatoryMsg && mandatoryMsg}
                      </p>
                    </div>
                  )}
                </div>
                {modalView === "ScheduleCall" && (
                  <>
                    <div className="inputFieldDivCalls mt-4 gap-3">
                      <div className="inputFieldDivLeftCalls w-50">
                        <p className="mb-0 mb-1" style={{ color: "#515151" }}>
                          Reminder
                        </p>

                        {editPurchaseOverview ? (
                          <input
                            type="text"
                            className="insertCommonInputFieldCalls"
                            value={callInfo.reminder.value}
                          />
                        ) : (
                          // <Select
                          //   options={reminderOptions}
                          //   styles={createAccountStyles}
                          //   menuPlacement="auto"
                          //   isSearchable={false}
                          //   onChange={(selectedOption) =>
                          //     handleChangeSelectDropDown(
                          //       selectedOption,
                          //       "reminder"
                          //     )
                          //   }
                          // />
                          <div className="d-flex input-with-clear">
                            <input
                              className="insertCommonInputFieldCalls cursorPointer"
                              style={{ width: "93%" }}
                              value={callInfo.reminder.value}
                              onClick={(e) => {
                                e.stopPropagation();
                                setDropdownSelectReminder(
                                  !dropdownSelectReminder
                                );
                                setListVisible(false);
                                setModuleListVisible(false);
                                setDropdownSelect(false);
                                setDropdownCallPurpose(false);
                                setDropdownCallResult(false);
                              }}
                              readOnly
                            />
                            {/* <Select
                        options={reminderOptions}
                        styles={createAccountStyles}
                        value={meetingInfo.participantsRemainder}
                        menuPlacement="auto"
                        onChange={(selectedOption) =>
                          handleChangeSelectDropDownMeetings(
                            selectedOption,
                            "participantsRemainder"
                          )
                        }
                      /> */}
                            {dropdownSelectReminder ? (
                              <>
                                <span
                                  className="clear-buttonCalls"
                                  onClick={() =>
                                    setDropdownSelectReminder(false)
                                  }
                                  style={{
                                    top: "1.5rem",
                                    left: "19.5rem",
                                  }}
                                >
                                  <img
                                    src={up}
                                    alt="img"
                                    height={20}
                                    width={20}
                                  />
                                </span>
                              </>
                            ) : (
                              <>
                                <span
                                  className="clear-buttonCalls"
                                  style={{
                                    top: "1.5rem",
                                    left: "19.5rem",
                                  }}
                                >
                                  <img
                                    src={down}
                                    alt="img"
                                    height={20}
                                    width={20}
                                  />
                                </span>
                              </>
                            )}
                            {dropdownSelectReminder && (
                              <ul
                                className="data-list list-group  lead_ownerList_container position-absolute "
                                style={{
                                  top: "3.25rem",
                                  width: "21.9rem",
                                  minHeight: "max-content",
                                }}
                              >
                                {reminderOptions.map((item, index) => (
                                  <li
                                    key={index}
                                    className="cursorPointer list-group-item"
                                    onClick={() => {
                                      handleChangeSelectDropDown(
                                        item,
                                        "reminder"
                                      );
                                      setDropdownSelectReminder(false);
                                    }}
                                  >
                                    <div className="d-flex flex-column">
                                      <span> {item.label} </span>
                                    </div>
                                  </li>
                                ))}
                              </ul>
                            )}
                          </div>
                        )}
                      </div>
                    </div>
                  </>
                )}
                {callInfo.callType.value === "Outbound" && (
                  <>
                    <div className="d-flex justify-content-between pt-5 pb-2 rounded-2 relative">
                      <h4 className="commonTextColor">
                        Purpose of Outgoing Call
                      </h4>
                    </div>
                    <div className="columns_container pt-1 pb-3 overflow-y-visible">
                      <div className="inputFieldDivCalls mt-2 gap-3">
                        <div
                          className="inputFieldDivLeftCalls"
                          style={{ alignSelf: "normal" }}
                        >
                          <p className="mb-0 mb-1" style={{ color: "#515151" }}>
                            Call Purpose
                          </p>
                          {/* <Select
                        options={callPurposeOptions}
                        styles={createAccountStyles}
                        value={callInfo.callPurpose}
                        menuPlacement="auto"
                        onChange={(selectedOption) =>
                          handleChangeSelectDropDown(
                            selectedOption,
                            "callPurpose"
                          )
                        }
                        isSearchable={false}
                      /> */}
                          <div className="d-flex input-with-clear">
                            <input
                              className="insertCommonInputFieldCalls cursorPointer"
                              value={callInfo.callPurpose.value}
                              onClick={(e) => {
                                e.stopPropagation();
                                setDropdownCallPurpose(!dropdownCallPurpose);
                                setDropdownSelectReminder(false);
                                setListVisible(false);
                                setModuleListVisible(false);
                                setDropdownCallResult(false);
                                setDropdownSelect(false);
                              }}
                              readOnly
                            />
                            {dropdownCallPurpose ? (
                              <>
                                <span
                                  className="clear-buttonCalls"
                                  onClick={() =>
                                    setDropdownSelectReminder(false)
                                  }
                                  style={{
                                    top: "1.5rem",
                                    left: "19.5rem",
                                  }}
                                >
                                  <img
                                    src={up}
                                    alt="img"
                                    height={20}
                                    width={20}
                                  />
                                </span>
                              </>
                            ) : (
                              <>
                                <span
                                  className="clear-buttonCalls"
                                  style={{
                                    top: "1.5rem",
                                    left: "19.5rem",
                                  }}
                                >
                                  <img
                                    src={down}
                                    alt="img"
                                    height={20}
                                    width={20}
                                  />
                                </span>
                              </>
                            )}
                            {dropdownCallPurpose && (
                              <ul
                                className="data-list list-group  lead_ownerList_container position-absolute "
                                style={{
                                  top: "3.25rem",
                                  width: "21.9rem",
                                  minHeight: "max-content",
                                }}
                              >
                                {callPurposeOptions.map((item, index) => (
                                  <li
                                    key={index}
                                    className="cursorPointer list-group-item"
                                    onClick={() => {
                                      handleChangeSelectDropDown(
                                        item,
                                        "callPurpose"
                                      );
                                      setDropdownSelectReminder(false);
                                      setDropdownCallPurpose(false);
                                    }}
                                  >
                                    <div className="d-flex flex-column">
                                      <span> {item.label} </span>
                                    </div>
                                  </li>
                                ))}
                              </ul>
                            )}
                          </div>
                        </div>

                        <div className="inputFieldDivLeftCalls">
                          <p className="mb-0 mb-1" style={{ color: "#515151" }}>
                            Call Agenda
                          </p>
                          <input
                            type="text"
                            className="insertCommonInputFieldCalls"
                            name="callAgenda"
                            value={callInfo.callAgenda}
                            onChange={handleChangeCalls}
                            autoComplete="off"
                            onPaste={(e) => e.preventDefault()}
                            onCopy={(e) => e.preventDefault()}
                          />
                          <p style={{ color: "#fa5a5a" }}>
                            {mandatoryCallMsg && mandatoryCallMsg}
                          </p>
                        </div>
                      </div>
                    </div>
                  </>
                )}
                {modalView === "LogCall" && (
                  <>
                    <div className="d-flex justify-content-between pt-5 pb-2 rounded-2 relative">
                      <h4 className="commonTextColor">
                        {callInfo.callType.value === "Outbound" &&
                          "Outcome Of Outgoing Call"}
                        {callInfo.callType.value === "Inbound" &&
                          "Reason for Incoming Call"}
                      </h4>
                    </div>
                    <div className="columns_container pt-1 pb-3 overflow-y-visible">
                      <div className="inputFieldDivCalls mt-2 gap-3">
                        {callInfo.callType.value === "Outbound" && (
                          <div className="inputFieldDivLeftCalls">
                            <p
                              className="mb-0 mb-1"
                              style={{ color: "#515151" }}
                            >
                              Call Result
                            </p>
                            {/* <Select
                            options={callResultOptions}
                            styles={createAccountStyles}
                            value={callInfo.callResult}
                            menuPlacement="auto"
                            onChange={(selectedOption) =>
                              handleChangeSelectDropDown(
                                selectedOption,
                                "callResult"
                              )
                            }
                            isSearchable={false}
                          /> */}
                            <div className="d-flex input-with-clear">
                              <input
                                className="insertCommonInputFieldCalls cursorPointer"
                                value={callInfo.callResult.value}
                                onClick={(e) => {
                                  e.stopPropagation();
                                  setDropdownCallResult(!dropdownCallResult);
                                  setDropdownSelectReminder(false);
                                  setListVisible(false);
                                  setDropdownSelect(false);
                                  setDropdownCallPurpose(false);
                                  setModuleListVisible(false);
                                }}
                                readOnly
                              />
                              {dropdownCallResult ? (
                                <>
                                  <span
                                    className="clear-buttonCalls"
                                    onClick={() => setDropdownCallResult(false)}
                                    style={{
                                      top: "1.5rem",
                                      left: "19.5rem",
                                    }}
                                  >
                                    <img
                                      src={up}
                                      alt="img"
                                      height={20}
                                      width={20}
                                    />
                                  </span>
                                </>
                              ) : (
                                <>
                                  <span
                                    className="clear-buttonCalls"
                                    style={{
                                      top: "1.5rem",
                                      left: "19.5rem",
                                    }}
                                  >
                                    <img
                                      src={down}
                                      alt="img"
                                      height={20}
                                      width={20}
                                    />
                                  </span>
                                </>
                              )}
                              {dropdownCallResult && (
                                <ul
                                  className="data-list list-group  lead_ownerList_container position-absolute "
                                  style={{
                                    top: "3.25rem",
                                    width: "21.9rem",
                                    minHeight: "max-content",
                                  }}
                                >
                                  {callResultOptions.map((item, index) => (
                                    <li
                                      key={index}
                                      className="cursorPointer list-group-item"
                                      onClick={() => {
                                        handleChangeSelectDropDown(
                                          item,
                                          "callResult"
                                        );
                                        setDropdownSelectReminder(false);
                                        setDropdownCallPurpose(false);
                                        setDropdownCallResult(false);
                                      }}
                                    >
                                      <div className="d-flex flex-column">
                                        <span> {item.label} </span>
                                      </div>
                                    </li>
                                  ))}
                                </ul>
                              )}
                            </div>
                          </div>
                        )}

                        {callInfo.callType.value !== "Missed" && (
                          <div className="inputFieldDivLeftCalls">
                            <p
                              className="mb-0 mb-1"
                              style={{
                                color: "#515151",
                              }}
                            >
                              Description
                            </p>
                            <input
                              type="text"
                              className="insertCommonInputFieldCalls"
                              name="description"
                              value={callInfo.description}
                              onChange={handleChangeCalls}
                              style={{
                                width:
                                  callInfo.callType.value === "Inbound"
                                    ? "50%"
                                    : "100%",
                              }}
                            />
                          </div>
                        )}
                      </div>
                    </div>
                  </>
                )}
              </div>
              <aside className="float-end mt-2">
                <div className="d-flex align-items-center gap-2 px-4 pt-2 pb-3 mb-3">
                  <button
                    className="cancel_btn bg-light rounded-2 ms-2"
                    style={{
                      paddingTop: "6px",
                      paddingBottom: "6px",
                      width: "100px",
                    }}
                    onClick={() => {
                      setShowModalType("");
                      setCallInfo(callInformation);
                      setIsComplete(0);
                      setSelectedOptionLead(leadContactOptions[0]);
                      setSelectedOptionModule(relatedToOptions[0]);
                      setCallStartTime({
                        hour: "00",
                        minutes: "00",
                        meridiem: "AM",
                      });
                    }}
                  >
                    Cancel
                  </button>
                  <button
                    className="save_btn py-2 border border-0 rounded-2"
                    style={{ width: "122px" }}
                    onClick={(e) => {
                      isComplete === 1 ||
                      editPurchaseOverview ||
                      modalView !== "ScheduleCall"
                        ? handleInsertCalls(e)
                        : handleCheckCalls(e);
                    }}
                    disabled={apiLoader}
                  >
                    {modalView !== "ScheduleCall" || editPurchaseOverview ? (
                      <>
                        {apiLoader ? (
                          <Lottie
                            options={defaultOptions("white")}
                            height="24px"
                            width="36px"
                          />
                        ) : (
                          "Save"
                        )}
                      </>
                    ) : (
                      <>
                        {apiLoader ? (
                          <Lottie
                            options={defaultOptions("white")}
                            height="24px"
                            width="36px"
                          />
                        ) : (
                          "Schedule"
                        )}
                      </>
                    )}
                  </button>
                </div>
              </aside>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        show={["DeleteCalls"].includes(deleteModal)}
        aria-labelledby="contained-modal-title-vcenter"
        onClick={(e) => e.stopPropagation()}
        // size={showModalType !== "delete" ? "md" : "lg"}
      >
        <Modal.Header
          className=" border-0 mb-0 d-flex flex-column align-items-start"
          style={{ backgroundColor: modalType !== "delete" && "#F2F2F2" }}
        >
          {deleteModal === "DeleteCalls" && (
            <div className="fs-5" style={{ color: "#3F7DFF" }}>
              Are you sure you want to delete &quot;Outgoing call to{" "}
              {callDetails?.callOwner}&quot;
            </div>
          )}
        </Modal.Header>
        <Modal.Body className="ModalBody">
          <div className={`pt-4  ${modalType !== "delete" && "pe-3"}`}>
            <div className="d-flex justify-content-end w-100 gap-4 pe-2">
              <button
                className="cancelButton px-4"
                onClick={() => {
                  setDeleteModal("");
                  setIsComplete(0);
                }}
              >
                Cancel
              </button>
              <button
                className="createContactButtonSave"
                onClick={() => {
                  deleteCalls();
                }}
                disabled={apiLoader}
              >
                {apiLoader ? (
                  <Lottie
                    options={defaultOptions("white")}
                    height="24px"
                    width="36px"
                  />
                ) : (
                  "Delete"
                )}
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        show={[
          "delete",
          "Mass Delete",
          "Mass Update",
          "Mass Transfer",
        ].includes(modalType)}
        aria-labelledby="contained-modal-title-vcenter"
        onClick={(e) => e.stopPropagation()}
        // size={showModalType !== "delete" ? "md" : "lg"}
      >
        <Modal.Header
          className=" border-0 mb-0 d-flex flex-column align-items-start"
          style={{ backgroundColor: modalType !== "delete" && "#F2F2F2" }}
        >
          {modalType !== "delete" && (
            <div
              className={`mass_heading fs-4 ${
                modalType !== "Mass Delete" && "ps-4"
              }`}
            >
              {modalType}
            </div>
          )}
          {modalType === "delete" && (
            <div className="fs-5" style={{ color: "#3F7DFF" }}>
              Are you sure you want to delete ${callDetails?.callOwner}?
            </div>
          )}
        </Modal.Header>
        <Modal.Body className="ModalBody">
          {modalType === "delete" ? (
            <>
              <div className="d-flex gap-2 pb-2 align-items-center">
                <span className="fw-bold">Note:</span>
                <span className="small pt-1">
                  Any associated activities, visits, drafts will also be
                  deleted.
                </span>
              </div>
            </>
          ) : modalType === "Mass Delete" ? (
            <div className="fs-6 pt-1 pb-2">
              Are you sure you want to delete selected item(s)?
            </div>
          ) : modalType === "Mass Update" ? (
            <div className="d-flex gap-2 mb-3 mt-2 align-items-center w-100">
              <div className="w-100">
                <div className="d-flex flex-column ps-1 align-items-center mx-4">
                  <label className="text-start w-100 opacity-75">
                    Select a field
                  </label>
                  <div
                    className="position-relative w-100 rounded-1"
                    style={{ border: "1px solid rgba(185, 185, 185, 0.5)" }}
                  >
                    <div
                      onClick={(e) => {
                        e.stopPropagation();
                        setListVisible(!listVisible);
                        setModuleListVisible(false);
                      }}
                      className={`cursorPointer d-flex justify-content-between w-100 my-1 p-1 border-0`}
                    >
                      <span className="">{selectedObj.name}</span>
                      <img
                        src={listVisible ? up : down}
                        alt="img"
                        height={20}
                        width={20}
                        className="pt-1"
                      />
                    </div>
                    {listVisible && (
                      <div
                        className="position-absolute field_container w-100"
                        onClick={(e) => {
                          e.stopPropagation();
                        }}
                      >
                        <div className="">
                          <label
                            htmlFor="selectField"
                            className="position-relative w-100"
                          >
                            <input
                              id="selectField"
                              type="text"
                              className="rounded-2 p-1 w-100 pe-4"
                              placeholder="Search"
                              aria-label="Username"
                              aria-describedby="basic-addon1"
                              value={searchField}
                              onChange={(e) => {
                                const value = e.target.value.replace(
                                  /[^a-zA-Z]/g,
                                  ""
                                );
                                setSearchField(value);

                                const filteredData = massColumn.filter(
                                  (field) =>
                                    field.name
                                      .toLowerCase()
                                      .includes(value.trim().toLowerCase())
                                );
                                setShowSearchFields(
                                  /^[a-zA-Z]+$/.test(value)
                                    ? filteredData
                                    : fieldLabels
                                );
                              }}
                            />
                            <img
                              className="position-absolute mt-1 me-2 end-0 pt-1 cursorPointer"
                              onClick={() => {
                                setSearchField("");
                              }}
                              src={searchIcon}
                              alt="search"
                              width={20}
                            />
                          </label>

                          <div className="w-100 rounded-2">
                            <div className="border border-1 field_container_dropdown rounded-1">
                              {showSearchFields
                                .sort((a, b) => a.name.localeCompare(b.name))
                                .map(
                                  (field) =>
                                    ![
                                      "Quotes Owner",
                                      "Deal Name",
                                      "Account Name",
                                      "Contact Name",
                                      "Valid Until",
                                      "Hash",
                                    ].includes(field.name) && (
                                      <div
                                        key={field.name}
                                        className="d-flex gap-2 p-2 cursorPointer rounded-2 align-items-center"
                                        onClick={() => {
                                          setClickedDropdownObj({
                                            label: "",
                                            value: "",
                                          });
                                          setMassUpdateValue("");
                                          setSelectedObj(field);
                                          setListVisible(false);
                                        }}
                                      >
                                        <span className="ps-2">
                                          {field.name}
                                        </span>
                                      </div>
                                    )
                                )}
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="w-100 mx-auto d-flex justify-content-center mt-4">
                    <div className="w-100">
                      <label className="opacity-75" htmlFor="massValue">
                        Enter value
                      </label>
                      <div
                        className="position-relative w-100 border rounded-1"
                        style={{ border: "1px solid rgba(185, 185, 185, 0.5)" }}
                      >
                        {["Call Result", "Call Purpose"].includes(
                          selectedObj.name
                        ) ? (
                          <Select
                            value={clickedDropdownObj}
                            menuPlacement="auto"
                            onChange={(selectedOption) => {
                              setClickedDropdownObj(selectedOption);
                              setMassUpdateValue(selectedOption.value);
                            }}
                            options={
                              selectedObj.name === "Call Result"
                                ? callResultOptions
                                : callPurposeOptions
                            }
                            styles={fieldDropdownStyles}
                            isSearchable={false}
                          />
                        ) : (
                          <input
                            className="w-100 border-0 py-2 rounded-1"
                            type="text"
                            value={massUpdateValue}
                            onChange={(e) => {
                              setMassUpdateValue(e.target.value);
                            }}
                            style={{ outline: "none" }}
                            id="massValue"
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="w-100 px-4 py-2 mb-1">
              <label className="text-start w-100 opacity-75">Transfer To</label>
              <div
                className="d-flex input-with-clear position-relative mx-auto"
                onClick={(e) => {
                  // setIsLeadForm(true);
                  handleOwnerInputClick(e);
                }}
              >
                <input
                  type="text"
                  className="ps-3 w-100 cursorPointer py-2"
                  value={selectedItem?.name}
                  readOnly
                />
                <span
                  className="position-absolute h-100 mass_transfer_user pt-1"
                  style={{ right: "0.1rem" }}
                >
                  <span
                    className="cursorPointer px-1"
                    onClick={() => setIsLeadOwnerModal(!isLeadOwnerModal)}
                  >
                    <img
                      src={userSearch}
                      alt="img"
                      width={20}
                      height={20}
                      className="ms-2 mt-2 me-2"
                    />
                  </span>
                </span>
              </div>
            </div>
          )}

          <div className={`pt-4  ${modalType !== "delete" && "pe-3"}`}>
            <div className="d-flex justify-content-end w-100 gap-4 pe-2">
              <button
                className="cancelButton px-4"
                onClick={() => {
                  setShowModalType("");
                  setSelectedCheckboxes([]);
                  setShowSearchFields(massColumn);
                  setIsComplete(0);
                }}
              >
                Cancel
              </button>
              <button
                className="createContactButtonSave"
                onClick={() => {
                  if (modalType === "delete") {
                    // deleteProductDetails();
                  } else {
                    //  else if (showModalType === "Mass Delete") {
                    //   deleteUpdateTransferMass();
                    // } else {
                    deleteUpdateTransferMass();
                  }
                }}
              >
                {modalType === "Mass Update"
                  ? "Update"
                  : modalType === "Mass Transfer"
                  ? "Done"
                  : "Delete"}
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        show={["RescheduleCall", "cancelCall"].includes(modalType)}
        id="ConvertModal"
        aria-labelledby="example-custom-modal-styling-title"
        className={`withdrw_modal rounded-2 modal-lg ${
          overlapModal ? "opacity-75" : ""
        }`}
      >
        <Modal.Body
          className="p-0 rounded-2"
          onClick={() => {
            setShowRescheduleTime({
              hour: false,
              minutes: false,
            });
          }}
        >
          <div className="modalClass position-relative">
            <div
              className="d-flex justify-content-between px-4 pt-4 pb-2 rounded-2"
              style={{ background: "rgba(242, 242, 242, 1)" }}
            >
              <h4 className="commonTextColor">
                {modalType === "RescheduleCall" ? "Re-schedule" : "Cancel"}
              </h4>
              <img
                src={cancelCircle}
                alt="cancel"
                style={{
                  width: "25px",
                  height: "25px",
                  cursor: "pointer",
                }}
                onClick={() => {
                  setShowModalType("");
                  setCallInfo(callInformation);
                }}
              />
            </div>
            <div className="w-100 position-relative">
              <div className="columns_container ps-4 pe-2 pt-2 pb-1">
                {modalType === "RescheduleCall" ? (
                  <div className="inputFieldDivCalls mt-4 gap-3">
                    <div
                      className="inputFieldDivLeftCalls"
                      style={{ alignSelf: "flex-start" }}
                    >
                      <p
                        className="p-0 mb-1 mt-0 mx-0"
                        style={{ color: "#515151" }}
                      >
                        Reschedule date<span className="text-danger">*</span>
                      </p>
                      <input
                        type="date"
                        className="insertCommonInputFieldCalls"
                        name="rescheduleDate"
                        value={callInfo.rescheduleDate}
                        onChange={handleChangeCalls}
                        style={{ width: "94%" }}
                        min={new Date().toISOString().split("T")[0]}
                        max={
                          new Date(
                            new Date().getFullYear() + 1,
                            new Date().getMonth(),
                            new Date().getDate()
                          )
                            .toISOString()
                            .split("T")[0]
                        }
                        onKeyDown={(e) => e.preventDefault()}
                      />
                    </div>
                    <div className="inputFieldDivLeftCalls">
                      <p className="mb-1" style={{ color: "#515151" }}>
                        Reschedule Time<span className="text-danger">*</span>
                      </p>
                      <div
                        className={"insertCommonInputFieldCalls d-flex gap-1"}
                        style={{
                          border: mandatoryMsgTime
                            ? "1px solid red"
                            : "1px solid #b9b9b9b0",
                        }}
                      >
                        <div className="hours_box position-relative">
                          <div
                            className="cursorPointer"
                            onClick={(e) => {
                              e.stopPropagation();
                              setShowRescheduleTime({
                                hour: !showRescheduleTime.hour,
                                minutes: false,
                              });
                            }}
                          >
                            {rescheduleTime.hour}
                          </div>
                          {showRescheduleTime.hour ? (
                            <div className="hours_options position-absolute d-flex flex-column rounded-1">
                              {hours.map((hour, index) => {
                                return (
                                  <span
                                    className="p-1 cursorPointer"
                                    onClick={() => {
                                      setRescheduleTime({
                                        hour: hour,
                                        minutes: rescheduleTime.minutes,
                                        meridiem: rescheduleTime.meridiem,
                                      });
                                    }}
                                    key={index}
                                  >
                                    {hour}
                                  </span>
                                );
                              })}
                            </div>
                          ) : null}
                        </div>
                        <div>:</div>
                        <div className="minutes_box position-relative">
                          <div
                            className="cursorPointer"
                            onClick={(e) => {
                              e.stopPropagation();
                              setShowRescheduleTime({
                                hour: false,
                                minutes: !showRescheduleTime.minutes,
                              });
                            }}
                          >
                            {rescheduleTime.minutes}
                          </div>
                          {showRescheduleTime.minutes ? (
                            <div className="hours_options position-absolute d-flex flex-column rounded-1">
                              {" "}
                              {minutes.map((minute, index) => {
                                return (
                                  <span
                                    className="p-1 cursorPointer"
                                    onClick={() => {
                                      setRescheduleTime({
                                        hour: rescheduleTime.hour,
                                        minutes: minute,
                                        meridiem: rescheduleTime.meridiem,
                                      });
                                    }}
                                    key={index}
                                  >
                                    {minute}
                                  </span>
                                );
                              })}
                            </div>
                          ) : null}
                        </div>
                        <div className="hours_box_type">
                          <div
                            className="cursorPointer"
                            onClick={(e) => {
                              e.stopPropagation();
                              setShowRescheduleTime({
                                hour: false,
                                minutes: false,
                              });

                              setRescheduleTime({
                                hour: rescheduleTime.hour,
                                minutes: rescheduleTime.minutes,
                                meridiem:
                                  rescheduleTime.meridiem === "AM"
                                    ? "PM"
                                    : "AM",
                              });
                            }}
                          >
                            {rescheduleTime.meridiem}
                          </div>
                        </div>
                      </div>
                      <span style={{ color: "#fa5a5a" }}>
                        {mandatoryMsgTime}
                      </span>
                    </div>
                  </div>
                ) : (
                  <>
                    <div
                      className="inputFieldDivCalls mt-2 mb-0"
                      style={{
                        backgroundColor: "#E4F5FF",
                        paddingLeft: "10px",
                        height: "25px",
                        alignItems: "start",
                      }}
                    >
                      <p>
                        <span style={{ color: "#515151" }}>Note: </span>
                        <span style={{ color: "#9C9C9C" }}>
                          {" "}
                          Are you sure you want to Cancel this call?
                        </span>
                      </p>
                    </div>
                  </>
                )}
                <div className="columns_container pt-1 pb-3 overflow-y-visible mt-2">
                  <div className="d-flex flex-column">
                    <div className="d-flex gap-4">
                      <div
                        className="inputFieldDivLeftCalls"
                        style={{ marginLeft: "2.5px" }}
                      >
                        <p className="mb-0" style={{ color: "#515151" }}>
                          Note Title<span className="text-danger">*</span>
                        </p>
                        <input
                          type="text"
                          // className="insertCommonInputFieldCalls"
                          className={
                            "insertCommonInputFieldCalls d-flex gap-1 mt-1"
                          }
                          name="noteTitle"
                          value={callInfo.noteTitle}
                          onChange={handleChangeCalls}
                          maxLength={100}
                          style={{
                            border: mandatoryMsgNoteTitle
                              ? "1px solid red"
                              : "1px solid #b9b9b9b0",
                          }}
                        />
                        {mandatoryMsgNoteTitle && (
                          <p style={{ color: "#fa5a5a" }}>
                            {mandatoryMsgNoteTitle}
                          </p>
                        )}
                      </div>
                      <div className="inputFieldDivLeftCalls">
                        <p className="mb-0" style={{ color: "#515151" }}>
                          Note Description<span className="text-danger">*</span>
                        </p>
                        <input
                          type="text"
                          className={
                            "insertCommonInputFieldCalls d-flex gap-1 mt-1"
                          }
                          name="noteDescription"
                          value={callInfo.noteDescription}
                          onChange={handleChangeCalls}
                          style={{
                            border: mandatoryNoteDesc
                              ? "1px solid red"
                              : "1px solid #b9b9b9b0",
                          }}
                        />
                        {mandatoryNoteDesc && (
                          <p style={{ color: "#fa5a5a" }}>
                            {mandatoryNoteDesc}
                          </p>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <aside className="float-end mt-0">
                <div className="d-flex align-items-center gap-2 px-4 pt-2 pb-3 mb-3">
                  <button
                    className="cancel_btn bg-light rounded-2 ms-2"
                    style={{
                      paddingTop: "6px",
                      paddingBottom: "6px",
                      width: "100px",
                    }}
                    onClick={() => {
                      setShowModalType("");
                      setCallInfo(callInformation);
                      // setRescheduleTime({
                      //   hour: "00",
                      //   minutes: "00",
                      //   meridiem: "AM",
                      // });
                      setRescheduleCheck(false);
                    }}
                  >
                    Cancel
                  </button>
                  <button
                    className="save_btn py-2 border border-0 rounded-2"
                    style={{ width: "122px" }}
                    onClick={(e) => {
                      e.stopPropagation();
                      if (modalType === "RescheduleCall") {
                        handleCheckCalls();
                      } else {
                        recheduleCancelCalls();
                      }
                    }}
                  >
                    {modalType === "RescheduleCall" ? "Reschedule" : "Done"}
                  </button>
                </div>
              </aside>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <LeadOwnerModal
        isLeadOwnerModal={isLeadOwnerModal}
        setIsLeadOwnerModal={setIsLeadOwnerModal}
        onSelectedLeadChange={handleSelectedLeadChange}
        defaultUser={selectedItem}
      />
      {/* <ContactListModal
        isContactListModal={isContactListModal}
        setIsContactListModal={setIsContactListModal}
        onSelectedLeadChange={handleSelectedContactListChange}
        defaultUser={selectedContact}
        allContactDetails={contactList}
      /> */}

      <Modal
        show={isContactListModal}
        className="d-flex justify-content-center align-items-center"
        aria-labelledby="example-custom-modal-styling-title"
        size="lg"
        id="commonLeadModal"
      >
        <Modal.Body className="ModalBody  overflow-auto">
          <div
            className="modalUS row justify-content-center"
            style={{ margin: "0", gap: "10px", paddingBottom: "10px" }}
          >
            <div className="header_Container d-flex justify-content-between">
              <h4 className="commonTextColor pt-2">Change Contact</h4>
              <div className="inputContainer d-flex justify-content-start">
                <input
                  type="text"
                  placeholder="Search"
                  className="topBarModalSearch px-5"
                  onChange={handleSearchChangeContact}
                  value={searchContact}
                />
                <img src={SearchIcon} alt="search" className="searchIcon" />
              </div>
            </div>

            <div className="Owner_table_container border border-dark rounded-2 h-25">
              <div className="table-wrapper">
                <table className="userdata-table">
                  <thead>
                    <tr>
                      <th>Contact Name</th>
                      <th>Account Name</th>
                      <th>Email</th>
                      <th>Phone</th>
                      <th>Contact Owner</th>
                    </tr>
                  </thead>
                  <tbody className="table-body tableUserData">
                    {filteredContactDetails?.map((item, index) => (
                      <tr
                        key={index}
                        onClick={() => {
                          setSelectedContact(item);
                        }}
                      >
                        <td>{item.contactName}</td>
                        <td>{item.accountName}</td>
                        <td>{item.emailId}</td>
                        <td>{item.phone}</td>
                        <td>{item.contactOwner}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
            <div className="d-flex w-100 align-items-center justify-content-end gap-2">
              {filteredContactDetails.length > 0 && (
                <>
                  <Select
                    options={pageOption}
                    styles={customCommonStyles("100%")}
                    // value={selectPageOption}
                    value={pageOption.find(
                      (option) => option.value === perPageContact.toString()
                    )}
                    onChange={handlePageDropDownChangeContact}
                  />
                  <Pagination
                    currentPage={currentPageContact}
                    totalPages={totalPagesContact}
                    onPageChange={hadlePageChangeContact}
                  />
                </>
              )}
            </div>
            <div className="d-flex pb-2 pt-3 justify-content-between">
              <div className="selectedUser d-flex gap-1">
                <span>Selected User</span>
                <img src={logoConvert} alt="img" height={20} width={20} />
                <span>{selectedContact.contactName}</span>
              </div>
              <div className="d-flex gap-2">
                <button
                  className="btn btn-outline-secondary border border-primary btn-sm px-3 py-2 text-dark"
                  onClick={() => {
                    setIsContactListModal(false);
                    setSelectedContact({});
                  }}
                >
                  Cancel
                </button>
                <button
                  className="btn btnColor btn-sm px-3 text-white"
                  onClick={() => {
                    getAllAssignModules(
                      "CONTACTS",
                      [selectedOptionModule.value],
                      selectedContact.contactId
                    );
                  }}
                >
                  Done
                </button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      {/* <LeadListModal
        isLeadListModal={isLeadListModal}
        setIsLeadListModal={setIsLeadListModal}
        onSelectedLeadChange={handleSelectedLeadListChange}
        defaultUser={selectedLeadItem}
        leadDetails={leadList}
      /> */}

      <Modal
        show={isLeadListModal}
        className="d-flex justify-content-center align-items-center"
        aria-labelledby="example-custom-modal-styling-title"
        size="lg"
        id="commonLeadModal"
      >
        <Modal.Body className="ModalBody  overflow-auto">
          <div
            className="modalUS row justify-content-center"
            style={{ margin: "0", gap: "10px", paddingBottom: "10px" }}
          >
            <div className="header_Container d-flex justify-content-between">
              <h4 className="commonTextColor pt-2">Change Lead</h4>
              <div className="inputContainer d-flex justify-content-start">
                <input
                  type="text"
                  placeholder="Search"
                  className="topBarModalSearch px-5"
                  onChange={handleSearchChangeLead}
                  value={searchLead}
                />
                <img src={SearchIcon} alt="search" className="searchIcon" />
              </div>
            </div>

            <div className="Owner_table_container border border-dark rounded-2 h-25">
              <div className="table-wrapper">
                <table className="userdata-table">
                  <thead>
                    <tr>
                      <th style={{ width: "15%" }}>Lead Name</th>
                      <th style={{ width: "15%" }}>Company</th>
                      <th style={{ width: "20%" }}>Email</th>
                      <th style={{ width: "15%" }}>Phone</th>
                      <th style={{ width: "15%" }}>Lead Source</th>
                      <th style={{ width: "15%" }}>Lead Owner</th>
                    </tr>
                  </thead>
                  <tbody className="table-body tableUserData">
                    {filteredLeadDetails?.map((item, index) => (
                      <tr
                        key={index}
                        onClick={() => {
                          setSelectedLeadItem(item);
                        }}
                      >
                        <td style={{ width: "15%" }} className="overflow_text">
                          {" "}
                          {item.leadName}{" "}
                        </td>
                        <td style={{ width: "15%" }} className="overflow_text">
                          {item.companyName}
                        </td>
                        <td style={{ width: "20%" }} className="overflow_text">
                          {item.emailId}
                        </td>
                        <td style={{ width: "15%" }} className="overflow_text">
                          {item.phoneNumber}
                        </td>
                        <td style={{ width: "15%" }} className="overflow_text">
                          {item.leadSource}
                        </td>
                        <td style={{ width: "15%" }} className="overflow_text">
                          {item.leadOwner}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
            <div className="d-flex w-100 align-items-center justify-content-end gap-2">
              {filteredLeadDetails.length > 0 && (
                <>
                  <Select
                    options={pageOption}
                    styles={customCommonStyles("100%")}
                    // value={selectPageOption}
                    value={pageOption.find(
                      (option) => option.value === perPageLead.toString()
                    )}
                    onChange={handlePageDropDownChangeLead}
                  />
                  <Pagination
                    currentPage={currentPageLead}
                    totalPages={totalPagesLead}
                    onPageChange={hadlePageChangeLead}
                  />
                </>
              )}
            </div>
            <div className="d-flex pb-2 pt-3 justify-content-between">
              <div className="selectedUser d-flex gap-1">
                <span>Selected User</span>
                <img src={logoConvert} alt="img" height={20} width={20} />
                <span>{selectedLeadItem.leadName}</span>
              </div>
              <div className="d-flex gap-2">
                <button
                  className="btn btn-outline-secondary border border-primary btn-sm px-3 py-2 text-dark"
                  onClick={() => {
                    setIsLeadListModal(false);
                    setSelectedLeadItem({});
                  }}
                >
                  Cancel
                </button>
                <button
                  className="btn btnColor btn-sm px-3 text-white"
                  onClick={() => {
                    setIsLeadListModal(false);
                  }}
                >
                  Done
                </button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <ChangeOwnerModal
        modalType={showOwnerModalType}
        setShowModalType={setShowOwnerModalType}
        selectedOwnerItem={selectedOwnerItem}
        handleChangeOwnerInputClick={handleChangeOwnerInputClick}
        setSelectedOwnerItem={setSelectedOwnerItem}
        isLeadOwnerModal={isLeadOwnerModal}
        setIsLeadOwnerModal={setIsLeadOwnerModal}
        isChangeOwnerList={isChangeOwnerList}
        userList={userList}
        handleChangeOwnerItemClick={handleChangeOwnerItemClick}
        emailNotification={emailNotification}
        setEmailNotification={setEmailNotification}
        handleEditClick={handleEditClick}
        handleUpdateClick={handleUpdateClick}
        setUpdatedFields={setCallInfo}
        existingContact={editPurchaseOverview}
        handleUpdateContact={handleInsertCalls}
        onSelectedChangeOwner={handleSelectedChangeOwner}
      />
      <Modal
        size="md"
        show={overlapModal}
        // id="changeOwnerModal"
        aria-labelledby="example-custom-modal-styling-title"
        className="withdrw_modal justify-content-center align-items-center w-100"
      >
        <Modal.Body style={{ padding: "8px 25px 10px 10px" }}>
          <div className="d-flex flex-column pt-1">
            <div
              className="w-100 ms-2 me-3 my-2 px-2 bg-white px-1 py-1 d-flex flex-column recordContainer"
              style={{
                height: "100px",
                overflowY: "hidden",
                color: "black",
                border: "none",
              }}
            >
              <p>{overlapMessage}</p>
              <p>Are you Sure you want to create this Calls anyway?</p>
            </div>
            <aside
              className="float-end mt-2  bg-white "
              style={{ marginBottom: "12px" }}
            >
              <div
                className="d-flex align-items-center gap-3 bg-white "
                style={{ flexFlow: "row-reverse" }}
              >
                <button
                  className="save_btn"
                  style={{
                    height: "35px",
                    border: "none",
                    width: "100px",
                    borderRadius: "5px",
                  }}
                  onClick={(e) => {
                    if (rescheduleCheck) {
                      recheduleCancelCalls();
                      setRescheduleCheck(false);
                      setOverlapModal(false);
                    } else {
                      handleInsertCalls(e);
                    }
                  }}
                  disabled={createLoader}
                >
                  {createLoader ? (
                    <Lottie
                      options={defaultOptions("white")}
                      height="24px"
                      width="36px"
                    />
                  ) : (
                    "Create"
                  )}
                </button>
                <button
                  className="cancel_btn bg-light"
                  style={{
                    height: "32.5px",
                    width: "100px",
                    borderRadius: "5px",
                  }}
                  onClick={() => setOverlapModal(false)}
                >
                  Cancel
                </button>
              </div>
            </aside>
          </div>
        </Modal.Body>
      </Modal>
      <NotificationContainer />
    </div>
  );
}

export default Calls;

import React, { useCallback, useEffect, useState, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import "./home.css";
import homeIcon from "../../Assets/De-Active/Home.svg";
import crmIcon from "../../Assets/De-Active/Crm.svg";
import crmActiveIcon from "../../Assets/De-Active/CrmActive.svg";
import marketingIcon from "../../Assets/De-Active/Marketing.svg";
import marketingActiveIcon from "../../Assets/De-Active/marketingActive.svg";
import commerceIcon from "../../Assets/De-Active/Commerce.svg";
import commerceActiveIcon from "../../Assets/De-Active/commerceActive.svg";
import dataIcon from "../../Assets/De-Active/Data.svg";
import dataActiveIcon from "../../Assets/De-Active/dataActiveIcon.svg";
import homeIconActive from "../../Assets/De-Active/homeActive.svg";
import leadIcon from "../../Assets/De-Active/Lead.svg";
import leadIconActive from "../../Assets/De-Active/leadsActive.svg";
import ContactIcon from "../../Assets/De-Active/Contact.svg";
import contactIconActive from "../../Assets/De-Active/contactActive.svg";
import accountsIcon from "../../Assets/De-Active/Account.svg";
import accountIconActive from "../../Assets/De-Active/accountActive.svg";
import dealsIcon from "../../Assets/De-Active/Deals.svg";
import dealsIconActive from "../../Assets/De-Active/dealsActive.svg";
import productsIcon from "../../Assets/prodInactive.svg";
import productsIconActive from "../../Assets/De-Active/productsActive.svg";
import tasksIcon from "../../Assets/De-Active/Task.svg";
import tasksIconActive from "../../Assets/De-Active/taskActive.svg";
import reportsIcon from "../../Assets/De-Active/Report.svg";
import reportsActiveIcon from "../../Assets/De-Active/reportActive.svg";
import purchaseOrdersIcon from "../../Assets/De-Active/Purchase Order.svg";
import purchaseOrdersIconActive from "../../Assets/De-Active/purchaseActive.svg";
import salesOrdersIcon from "../../Assets/De-Active/Sales Order.svg";
import salesOrdersIconActive from "../../Assets/De-Active/salesActive.svg";
import invoicesIcon from "../../Assets/De-Active/Invoice.svg";
import invoiceIconActive from "../../Assets/De-Active/invoiceActive.svg";
import quotesIcon from "../../Assets/De-Active/Quotes.svg";
import quotesIconActive from "../../Assets/De-Active/quotesActive.svg";
import callsIcon from "../../Assets/De-Active/call.svg";
import callsIconActive from "../../Assets/De-Active/callActive.svg";
import AnalyticsIcon from "../../Assets/De-Active/Analytics.svg";
import AnalyticsActiveIcon from "../../Assets/De-Active/analyticsActive.svg";
import vendorIcon from "../../Assets/De-Active/vendorHome.svg";
import vendorIconActive from "../../Assets/De-Active/vendorHomeActive.svg";
import campaignIcon from "../../Assets/De-Active/campaign.svg";
import campaignIconActive from "../../Assets/De-Active/campaignActive.svg";
import notification from "../../Assets/notification-01.svg";
import calendar from "../../Assets/calendar-03.svg";
import setting from "../../Assets/setting-01.svg";
import leadAvatar from "../../Assets/Lead_Avatar.png";
import meeting from "../../Assets/De-Active/Meeting.svg";
import Lead from "../Lead/Lead";
import InitialHome from "../InitialHome/InitialHome";
import Contacts from "../Contacts/Contacts";
import Accounts from "../Accounts/Accounts";
import Reports from "../Reports/Reports";
import Deals from "../Deals/Deals";
import Tasks from "../Tasks/Tasks";
import crmLogo from "../../Assets/crmLogo2.svg";
import Product from "../Products/Product";
import { Setting } from "../Settings/Setting";
import SalesOrder from "../SalesOrder/SalesOrder";
import Purchase from "../PurchaseOrders/Purchase";
import { Vendor } from "../Vendors/Vendor";
import Invoices from "../Invoices/Invoices";
import Quotes from "../Quotes/Quotes";
import axios from "axios";
import { URI } from "../../constants";
import Campaigns from "../Campaigns/Campaigns";
import Search from "../../Assets/search.svg";
import Analytics from "../Analytics/Analytics";
import Logout from "../../Assets/LogoutIcon.png";
import meetingActive from "../../Assets/De-Active/meetingActive.svg";
import upgrade from "../../Assets/upgrade.svg";
import { useSelector } from "react-redux";
import Calls from "../Calls/Calls";
import Meetings from "../Meetings/Meetings";
import { useDispatch } from "react-redux";
import {
  permissionDetailsList,
  setFilterOptions,
} from "../../Redux/stateSlice";
import {
  expandArrow,
  shrinkArrow,
} from "../../common/commonSVGs/commonSVGIcons.js";
import Sidebar from "../../common/Sidebar.js";
import SearchScreenCommon from "../../common/SearchScreenCommon.js";
import getHeaders from "../../common/ApiHeader.js";
import moment from "moment";
import { handleApiError } from "../../utils/ApiUtils";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import filter from "../../Assets/filter.svg";
import { Modal } from "react-bootstrap";
import Cancel from "../../Assets/CancelCircleModal.svg";
import ModalSearch from "../../Assets/ModalSearchBlue.svg";
import SockJS from "sockjs-client"; //npm i sockjs-client
import * as Stomp from "stompjs"; //npm i stompjs
import Alarm from "../../Assets/Alarm.svg";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

var stompClient = "";

function Home() {
  const menuItems = [
    {
      name: "crm",
      label: "CRM",
      icon: crmIcon,
      activeIcon: crmActiveIcon,
      subMenu: [
        {
          id: 1,
          icon: leadIcon,
          activeIcon: leadIconActive,
          name: "leads",
          label: "Leads",
          link: "",
        },
        {
          id: 2,
          icon: ContactIcon,
          activeIcon: contactIconActive,
          name: "contacts",
          label: "Contacts",
          link: "",
        },
        {
          id: 3,
          icon: accountsIcon,
          activeIcon: accountIconActive,
          label: "Accounts",
          name: "accounts",
          link: "",
        },
        {
          id: 4,
          icon: dealsIcon,
          activeIcon: dealsIconActive,
          name: "deals",
          label: "Deals",
          link: "",
        },
        {
          id: 5,
          icon: tasksIcon,
          activeIcon: tasksIconActive,
          label: "Tasks",
          name: "tasks",
          link: "",
        },
        {
          id: 6,
          icon: callsIcon,
          activeIcon: callsIconActive,
          label: "Calls",
          name: "calls",
          link: "",
        },
        {
          id: 7,
          icon: meeting,
          activeIcon: meetingActive,
          label: "Meetings",
          name: "meetings",
          link: "",
        },
      ],
    },
    {
      name: "marketing",
      label: "Marketing",
      icon: marketingIcon,
      activeIcon: marketingActiveIcon,
      subMenu: [
        {
          id: 1,
          icon: campaignIcon,
          activeIcon: campaignIconActive,
          label: "Campaigns",
          name: "campaigns",
          link: "",
        },
      ],
    },
    {
      name: "commerce",
      label: "Commerce",
      icon: commerceIcon,
      activeIcon: commerceActiveIcon,
      subMenu: [
        {
          id: 1,
          icon: productsIcon,
          activeIcon: productsIconActive,
          name: "products",
          label: "Products",
          link: "",
        },
        {
          id: 2,
          icon: quotesIcon,
          activeIcon: quotesIconActive,
          name: "quotes",
          label: "Quotes",
          link: "",
        },
        {
          id: 3,
          icon: salesOrdersIcon,
          activeIcon: salesOrdersIconActive,
          label: "Sales Orders",
          name: "salesOrders",
          link: "",
        },
        {
          id: 4,
          icon: purchaseOrdersIcon,
          activeIcon: purchaseOrdersIconActive,
          name: "purchaseOrders",
          label: "Purchase Orders",
          link: "",
        },
        {
          id: 5,
          icon: invoicesIcon,
          activeIcon: invoiceIconActive,
          label: "Invoices",
          name: "invoices",
          link: "",
        },
        {
          id: 6,
          icon: vendorIcon,
          activeIcon: vendorIconActive,
          label: "Vendors",
          name: "vendors",
          link: "",
        },
      ],
    },
    {
      name: "data",
      label: "Data",
      icon: dataIcon,
      activeIcon: dataActiveIcon,
      subMenu: [
        {
          id: 1,
          icon: reportsIcon,
          activeIcon: reportsActiveIcon,
          name: "reports",
          label: "Reports",
          link: "",
        },
        {
          id: 2,
          icon: AnalyticsIcon,
          activeIcon: AnalyticsActiveIcon,
          name: "analytics",
          label: "Analytics",
          link: "",
        },
      ],
    },
  ];

 

  const [currentView, setCurrentView] = useState("home");
  const [innerPage, setInnerPage] = useState("");
  const [sideBarSmall, setSideBarSmall] = useState(false);
  const [showLogout, setShowLogout] = useState(false);
  // const [permissionData, setPermissionData] = useState([]);
  const [fetchDealInfo, setFetchDealInfo] = useState(null);
  const [fetchContactInfo, setFetchContactInfo] = useState(null);
  const [fetchAccInfo, setFetchAccInfo] = useState(null);
  const [fetchQuoteInfo, setFetchQuoteInfo] = useState(null);
  const [fetchSalesInfo, setFetchSalesInfo] = useState(null);
  const [fetchProdInfo, setFetchProdInfo] = useState(null);
  const [fetchVendorInfo, setFetchVendorInfo] = useState(null);
  const [pastViewInfo, setPastViewInfo] = useState({
    view: "",
    innerPage: "",
    undo: "",
  });

   // eslint-disable-next-line no-console
   console.log("currrev",currentView,innerPage)
  const navigate = useNavigate();
  const reduxStore = useSelector((state) => state.reduxState);
  const dispatch = useDispatch();
  const {
    loginUserInfo,
    profileId,
    loginUserId,
    permissionList,
    planExpiry,
    subscriptionType,
  } = reduxStore;
  const [day, month, year] = planExpiry.split("-").map(Number);
  const expiryDate = new Date(year, month - 1, day);
  const currentDate = new Date();
  const differenceInTime = expiryDate - currentDate;
  const differenceInDays = Math.ceil(differenceInTime / (1000 * 3600 * 24));
  const [inputValue, setInputValue] = useState("");
  const [selectedCampaignName, setSelectedCampaignName] = useState("");
  const [selectedCampaignId, setSelectedCampaignId] = useState("");
  const [notificationData, setNotificationData] = useState([]);
  const [selectedTab, setSelectedTab] = useState("all");
  const [showNotification, setShowNotification] = useState(false);
  const [notificationCount, setNotificationCount] = useState(0);
  const [showFilterSearchModal, setShowFilterSearchModal] = useState(false);
  const [showFilterItems, setShowFilterItems] = useState(false);

  const [storeMessage, setStoreMessage] = useState("");
  const [showSearchInput, setShowSearchInput] = useState(false);
  const [allCheckboxUnselected, setAllCheckboxUnselected] = useState(false);
  const [searchResults, setSearchResults] = useState({});
  const [storeModuleId, setStoreModuleId] = useState(null);
  const [requestNo, setRequestNo] = useState("");
  const [showPopup, setShowPopup] = useState(false);
  const [popupMsg, setPopupMsg] = useState("Testing call reminder");

  const NotificationPopup = ({ message, dueDate }) => {
    return (
      <div className="notification-popup">
        <div className="d-flex gap-2 align-items-center">
          <img src={Alarm} alt="alarm" />
          <div
            style={{ fontSize: "16px", fontWeight: "500", color: "#212529" }}
          >
            {message}
          </div>
        </div>
        <div className="opacity-50 text-center">Calls Due on {dueDate}</div>
      </div>
    );
  };

  useEffect(() => {
    if (showPopup) {
      toast(
        <NotificationPopup message={popupMsg} dueDate="Jul 25 01:00 PM" />,
        {
          position: "bottom-right",
          autoClose: false,
          closeOnClick: false,
          draggable: false,
        }
      );
    }
  }, [showPopup]);

  const filterSearchCheckBoxes = [
    { label: "All", value: "ALL" },
    { label: "Leads", value: "LEADS" },
    { label: "Contacts", value: "CONTACTS" },
    { label: "Accounts", value: "ACCOUNTS" },
    { label: "Deals", value: "DEALS" },
    { label: "Tasks", value: "TASKS" },
    { label: "Meetings", value: "MEETINGS" },
    { label: "Calls", value: "CALLS" },
    { label: "Products", value: "PRODUCTS" },
    { label: "Quotes", value: "QUOTES" },
    { label: "Sales Order", value: "SALES_ORDERS" },
    { label: "Purchase Order", value: "PURCHASE_ORDERS" },
    { label: "Invoices", value: "INVOICES" },
    { label: "Campaigns", value: "CAMPAIGNS" },
    { label: "Vendors", value: "VENDORS" },
  ];

  const initializeCheckedItems = () => {
    return filterSearchCheckBoxes.reduce((acc, checkbox) => {
      acc[checkbox.value] = true;
      return acc;
    }, {});
  };
  const [checkedItems, setCheckedItems] = useState(initializeCheckedItems());

  const compareDates = (a, b) => {
    const parseDate = (dateString) => {
      if (dateString === "today") {
        return new Date();
      } else if (dateString === "yesterday") {
        const yesterday = new Date();
        yesterday.setDate(yesterday.getDate() - 1);
        return yesterday;
      } else {
        return new Date(dateString);
      }
    };

    const dateA = parseDate(a);
    const dateB = parseDate(b);

    return dateB - dateA;
  };

  // const notePermission = permissionList.hasOwnProperty("notes")
  //   ? permissionList.notes[0].status
  //   : ""; not supported by eslint
  const notePermission = Object.hasOwn(permissionList, "notes")
    ? permissionList.notes[0].status
    : "";

  const handleSignOut = async () => {
    const requestBody = {
      loggedInUserId: loginUserId,
      deviceId: localStorage.getItem("deviceId"),
      // deviceId: sessionStorage.getItem('deviceId'),
    };

    await axios
      .post(URI.signOut, requestBody, {
        headers: getHeaders(true),
      })
      .then((response) => {
        if (response.data.status === 200 || response.data.status === 201) {
          window.sessionStorage.clear();
          window.localStorage.clear();
          window.history.forward();
          _disconnect();
          navigate("../");
        }
      })
      .catch(async (error) => {
        handleApiError(error, navigate);
      });
  };

  const handleUpgradeNavigate = () => {
    navigate("/upgrade");
  };

  const getProfilePermission = async () => {
    await axios
      .get(URI.getProfilePermission + "/" + loginUserId + "/" + profileId, {
        headers: getHeaders(true),
      })

      .then((response) => {
        if (response.data.status === 200) {
          // setPermissionData(response.data.data);
          dispatch(
            permissionDetailsList(response.data.data.getProfilePermissionDetail)
          );
        }
      })
      .catch(async (error) => {
        handleApiError(error, navigate);
      });
  };

  const handleSearch = () => {
    if (
      allCheckboxUnselected &&
      Object.values(checkedItems).every((value) => !value)
    ) {
      const resetCheckedItems = filterSearchCheckBoxes.reduce(
        (acc, checkbox) => {
          acc[checkbox.value] = true;
          return acc;
        },
        {}
      );
      setCheckedItems(resetCheckedItems);
      setAllCheckboxUnselected(false); // Reset the flag
    }
  };

  const getUserList = async () => {
    await axios
      .get(URI.getUserList + "/" + loginUserId + "/active", {
        headers: getHeaders(true),
      })

      .then((response) => {
        if (response.data.status === 200) {
          sessionStorage.setItem(
            "userList",
            JSON.stringify(response.data.data.getUserList)
          );
          sessionStorage.setItem(
            "settingUserList",
            JSON.stringify(response.data.data.getUserList)
          );
        }
      })
      .catch(async (error) => {
        handleApiError(error, navigate);
      });
  };

  const getGeneralSearchList = async () => {
    let moduleName;
    const allUnchecked = Object.values(checkedItems).every(
      (checked) => !checked
    );
    if (checkedItems["ALL"] || allUnchecked) {
      moduleName = ["All"];
    } else {
      moduleName = Object.keys(checkedItems).filter((key) => checkedItems[key]);
    }
    const requestBody = {
      loggedInUserId: loginUserId,
      searchString: inputValue,
      moduleName: moduleName,
    };
    await axios
      .post(URI.generalSearch, requestBody, {
        headers: getHeaders(true),
      })

      .then((response) => {
        if (response.data.status === 200) {
          setCurrentView("showScreen");
          setSearchResults(response.data.data);
        }
      })
      .catch(async (error) => {
        handleApiError(error, navigate);
      });
  };

  const getAlerts = async (type) => {
    setSelectedTab(type);
    let count = 0;
    const requestBody = {
      loggedInUserId: loginUserId,
      alertType: type?.toUpperCase(), //all
      size: 10,
    };

    await axios
      .post(URI.getUserAlerts, requestBody, {
        headers: getHeaders(true),
      })
      .then((response) => {
        if (response.data.status === 200 || response.data.status === 201) {
          const arr = [];
          setNotificationData(response.data.data);
          Object.keys(response.data.data)
            .sort(compareDates)
            .forEach((key) => {
              return arr.push(response.data.data[key]);
            });
          const finalArray = arr.flat();
          for (const element of finalArray) {
            if (element.isRead === 0) {
              count = count + 1;
            }
            setNotificationCount(count);
          }
        }
      })
      .catch(async (error) => {
        handleApiError(error, navigate);
      });
  };

  const alertSettingChanges = async (__item, value) => {
    let req_Body = {};
    if (value === "all") {
      req_Body = {
        loggedInUserId: loginUserId,
        readAll: 1,
      };
    } else {
      req_Body = {
        loggedInUserId: loginUserId,
        alertId: __item.alertId,
        isRead: value,
      };
    }

    await axios
      .post(URI.userAlertSetting, req_Body, {
        headers: getHeaders(true),
      })

      .then((response) => {
        if (response.data.status === 200 || response.data.status === 201) {
          getAlerts(selectedTab);
        } else {
          NotificationManager.error("", response.data.message, 2000);
        }
      })
      .catch(function (error) {
        handleApiError(error, navigate);
      });
  };

  const _send = (message) => {
    if (stompClient) {
      stompClient.send("/app/sendNotify", {}, JSON.stringify(message));
    }
  };
  const _connect = () => {
    const ws = SockJS(URI.socketStream);
    stompClient = Stomp.over(ws);
    stompClient.connect(
      {},
      function () {
        var reqNo = Math.floor(Math.random() * 1000 + 1);

        const req = {
          method: "SUBSCRIBE",
          event: ["user_notification","activity_remainder"],
          userId: loginUserId,
          id: reqNo,
        };

        stompClient.subscribe("/user/topic/notifyStream", function (sdkEvent) {
          onMessageReceived(sdkEvent);
        });
        _send(req);
        setRequestNo(reqNo);
      },
      errorCallBack
    );
  };

  const errorCallBack = () => {
    setTimeout(() => {
      _connect();
    }, 5000);
  };

  const _disconnect = () => {
    const req = {
      method: "UNSUBSCRIBE",
      event: ["user_notification","activity_remainder"],
      userId: loginUserId,
      id: requestNo, //random number
    };
    _send(req);

    if (stompClient !== null) {
      stompClient.disconnect();
    }
  };

  const onMessageReceived = (message) => {
    const serverMsg = JSON.parse(message.body);
    if (serverMsg.header === "user_notification") {
      getAlerts("all");
    } else if (serverMsg.header === "activity_remainder") {
      setShowPopup(true);
      setPopupMsg(serverMsg.alertMessage);
    }
  };

  useEffect(() => {
    getProfilePermission();
    getUserList();
    getAlerts("all");
  }, []);

  useEffect(() => {
    if (innerPage === "create") {
      document.body.scrollTop = document.documentElement.scrollTop = 0; // scrolling to the top for create pages
    }
  }, [innerPage]);

  useEffect(() => {
    axios.get("/Filteroption.json").then((response) => {
      dispatch(setFilterOptions(response.data));
    });
  }, []);
  useEffect(() => {
    _connect();
  }, []);

  const filterMenuItems = (menuItems, permissions) => {
    return menuItems
      .map((menuItem) => {
        const filteredSubMenu = menuItem.subMenu.filter((subMenuItem) => {
          const modulePermissions = permissions[subMenuItem.name];
          if (modulePermissions) {
            const viewPermission = modulePermissions.find(
              (permission) => permission.action === "VIEW"
            );
            return viewPermission && viewPermission.status === "TRUE";
          }
          return false;
        });

        // Return the menuItem with the filtered subMenu
        return {
          ...menuItem,
          subMenu: filteredSubMenu,
        };
      })
      .filter((menuItem) => menuItem.subMenu.length > 0);
  };

  const filteredMenuItems = filterMenuItems(menuItems, permissionList)?.reduce(
    (acc, current) =>
      acc.find((item) => item.name === current.name) ? acc : [...acc, current],
    []
  );

  const newObject = {
    name: "home",
    label: "Home",
    icon: homeIcon,
    activeIcon: homeIconActive,
  };
  filteredMenuItems.unshift(newObject);

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      if (inputValue.length > 1) {
        setShowFilterSearchModal(false);
        getGeneralSearchList();
        handleSearch();
        setShowSearchInput(true);
      } else {
        setStoreMessage("Please Enter a minimum of 2 characters");
        setTimeout(() => {
          setStoreMessage("");
        }, 3000);
      }
    }
  };

  const handleInputChange = (event) => {
    const { value } = event.target;
    setInputValue(value);
  };
  const handleCheckboxChange = (e) => {
    const { value, checked } = e.target;
    if (value === "ALL") {
      const newCheckedItems = filterSearchCheckBoxes.reduce((acc, checkbox) => {
        acc[checkbox.value] = checked;
        return acc;
      }, {});
      setCheckedItems(newCheckedItems);
      setAllCheckboxUnselected(!checked);
    } else {
      setCheckedItems((prev) => {
        const updatedItems = {
          ...prev,
          [value]: checked,
          ALL: false,
        };
        const allChecked = filterSearchCheckBoxes
          .slice(1)
          .every((checkbox) => updatedItems[checkbox.value]);
        if (allChecked) {
          updatedItems.ALL = true;
        }
        return updatedItems;
      });
      setAllCheckboxUnselected(false);
    }
  };

  const getButtonLabel = () => {
    if (checkedItems["ALL"]) {
      return "All Modules";
    }
    const selectedLabels = filterSearchCheckBoxes
      .filter(
        (checkbox) => checkedItems[checkbox.value] && checkbox.value !== "ALL"
      )
      .map((checkbox) => checkbox.label);
    return selectedLabels.length > 0
      ? selectedLabels.join(", ")
      : "All Modules";
  };

  // clear all the values to default which we are fetching from clicking
  const backToDefaultValues = () => {
    // setFetchContactInfo(null);
    // example by uncommenting this it will clear fetch contactInfo which we select in contact module list
    setSelectedCampaignName("");
    setSelectedCampaignId("");
    setFetchProdInfo(null);
  };

  const views = useMemo(
    () => ({
      home: <InitialHome currentView={currentView} />,
      leads: (
        <Lead
          setCurrentView={setCurrentView}
          setInnerPage={setInnerPage}
          innerPage={innerPage}
          filteredMenuItems={filteredMenuItems}
          currentView={currentView}
          permissionList={permissionList}
          notePermission={notePermission}
          pastViewInfo={pastViewInfo}
          setPastViewInfo={setPastViewInfo}
          fetchProdInfo={fetchProdInfo}
          storeModuleId={storeModuleId}
        />
      ),
      contacts: (
        <Contacts
          setCurrentView={setCurrentView}
          setInnerPage={setInnerPage}
          innerPage={innerPage}
          currentView={currentView}
          permissionList={permissionList}
          notePermission={notePermission}
          fetchContactInfo={fetchContactInfo}
          setFetchContactInfo={setFetchContactInfo}
          fetchAccInfo={fetchAccInfo}
          setFetchAccInfo={setFetchAccInfo}
          pastViewInfo={pastViewInfo}
          setPastViewInfo={setPastViewInfo}
          fetchProdInfo={fetchProdInfo}
          fetchVendorInfo={fetchVendorInfo}
          storeModuleId={storeModuleId}
        />
      ),
      accounts: (
        <Accounts
          setCurrentView={setCurrentView}
          setInnerPage={setInnerPage}
          innerPage={innerPage}
          currentView={currentView}
          permissionList={permissionList}
          notePermission={notePermission}
          fetchAccInfo={fetchAccInfo}
          setFetchAccInfo={setFetchAccInfo}
          pastViewInfo={pastViewInfo}
          setPastViewInfo={setPastViewInfo}
          fetchProdInfo={fetchProdInfo}
          storeModuleId={storeModuleId}
        />
      ),
      deals: (
        <Deals
          setCurrentView={setCurrentView}
          setInnerPage={setInnerPage}
          innerPage={innerPage}
          currentView={currentView}
          permissionList={permissionList}
          notePermission={notePermission}
          fetchDealInfo={fetchDealInfo}
          setFetchDealInfo={setFetchDealInfo}
          fetchContactInfo={fetchContactInfo}
          fetchAccInfo={fetchAccInfo}
          pastViewInfo={pastViewInfo}
          setPastViewInfo={setPastViewInfo}
          selectedCampaignName={selectedCampaignName}
          selectedCampaignId={selectedCampaignId}
          fetchProdInfo={fetchProdInfo}
          storeModuleId={storeModuleId}
        />
      ),
      tasks: (
        <Tasks
          setCurrentView={setCurrentView}
          setInnerPage={setInnerPage}
          innerPage={innerPage}
          permissionList={permissionList}
          storeModuleId={storeModuleId}
        />
      ),
      products: (
        <Product
          setCurrentView={setCurrentView}
          setInnerPage={setInnerPage}
          innerPage={innerPage}
          currentView={currentView}
          permissionList={permissionList}
          notePermission={notePermission}
          pastViewInfo={pastViewInfo}
          setPastViewInfo={setPastViewInfo}
          fetchProdInfo={fetchProdInfo}
          setFetchProdInfo={setFetchProdInfo}
          storeModuleId={storeModuleId}
        />
      ),
      salesOrders: (
        <SalesOrder
          setCurrentView={setCurrentView}
          setInnerPage={setInnerPage}
          currentView={currentView}
          innerPage={innerPage}
          permissionList={permissionList}
          notePermission={notePermission}
          fetchDealInfo={fetchDealInfo}
          fetchContactInfo={fetchContactInfo}
          fetchAccInfo={fetchAccInfo}
          fetchQuoteInfo={fetchQuoteInfo}
          fetchSalesInfo={fetchSalesInfo}
          setFetchSalesInfo={setFetchSalesInfo}
          pastViewInfo={pastViewInfo}
          setPastViewInfo={setPastViewInfo}
          storeModuleId={storeModuleId}
        />
      ),
      purchaseOrders: (
        <Purchase
          setCurrentView={setCurrentView}
          permissionList={permissionList}
          notePermission={notePermission}
          setInnerPage={setInnerPage}
          currentView={currentView}
          innerPage={innerPage}
          fetchContactInfo={fetchContactInfo}
          setFetchContactInfo={setFetchContactInfo}
          pastViewInfo={pastViewInfo}
          setPastViewInfo={setPastViewInfo}
          storeModuleId={storeModuleId}
        />
      ),
      reports: <Reports />,
      settings: <Setting />,
      vendors: (
        <Vendor
          setCurrentView={setCurrentView}
          currentView={currentView}
          setInnerPage={setInnerPage}
          innerPage={innerPage}
          permissionList={permissionList}
          notePermission={notePermission}
          pastViewInfo={pastViewInfo}
          setPastViewInfo={setPastViewInfo}
          fetchVendorInfo={fetchVendorInfo}
          setFetchVendorInfo={setFetchVendorInfo}
          storeModuleId={storeModuleId}
        />
      ),
      invoices: (
        <Invoices
          setCurrentView={setCurrentView}
          setInnerPage={setInnerPage}
          innerPage={innerPage}
          currentView={currentView}
          permissionList={permissionList}
          notePermission={notePermission}
          fetchDealInfo={fetchDealInfo}
          fetchContactInfo={fetchContactInfo}
          fetchAccInfo={fetchAccInfo}
          fetchSalesInfo={fetchSalesInfo}
          setFetchSalesInfo={setFetchSalesInfo}
          pastViewInfo={pastViewInfo}
          setPastViewInfo={setPastViewInfo}
          storeModuleId={storeModuleId}
        />
      ),
      quotes: (
        <Quotes
          setCurrentView={setCurrentView}
          setInnerPage={setInnerPage}
          innerPage={innerPage}
          currentView={currentView}
          permissionList={permissionList}
          notePermission={notePermission}
          fetchDealInfo={fetchDealInfo}
          fetchContactInfo={fetchContactInfo}
          fetchAccInfo={fetchAccInfo}
          fetchQuoteInfo={fetchQuoteInfo}
          setFetchQuoteInfo={setFetchQuoteInfo}
          pastViewInfo={pastViewInfo}
          setPastViewInfo={setPastViewInfo}
          storeModuleId={storeModuleId}
        />
      ),
      calls: (
        <Calls
          setCurrentView={setCurrentView}
          setInnerPage={setInnerPage}
          innerPage={innerPage}
          permissionList={permissionList}
          notePermission={notePermission}
          storeModuleId={storeModuleId}
        />
      ),
      meetings: (
        <Meetings
          setCurrentView={setCurrentView}
          setInnerPage={setInnerPage}
          innerPage={innerPage}
          permissionList={permissionList}
          notePermission={notePermission}
          storeModuleId={storeModuleId}
        />
      ),
      campaigns: (
        <Campaigns
          setCurrentView={setCurrentView}
          setInnerPage={setInnerPage}
          innerPage={innerPage}
          currentView={currentView}
          filteredMenuItems={filteredMenuItems}
          permissionList={permissionList}
          notePermission={notePermission}
          setPastViewInfo={setPastViewInfo}
          pastViewInfo={pastViewInfo}
          setSelectedCampaignName={setSelectedCampaignName}
          setSelectedCampaignId={setSelectedCampaignId}
          selectedCampaignId={selectedCampaignId}
          storeModuleId={storeModuleId}
        />
      ),
      analytics: <Analytics currentView={currentView} />,
      showScreen: (
        <SearchScreenCommon
          currentView={currentView}
          checkedItems={checkedItems}
          filterSearchCheckBoxes={filterSearchCheckBoxes}
          setCheckedItems={setCheckedItems}
          labels={getButtonLabel()}
          inputValue={inputValue}
          setCurrentView={setCurrentView}
          searchResults={searchResults}
          setSearchResults={setSearchResults}
          setInnerPage={setInnerPage}
          setStoreModuleId={setStoreModuleId}
          storeModuleId={storeModuleId}
          setShowSearchInput={setShowSearchInput}
        />
      ),
    }),
    [
      currentView,
      setCurrentView,
      setInnerPage,
      innerPage,
      filteredMenuItems,
      permissionList,
      notePermission,
      pastViewInfo,
      setPastViewInfo,
      fetchContactInfo,
      setFetchContactInfo,
      fetchAccInfo,
      setFetchAccInfo,
      fetchDealInfo,
      setFetchDealInfo,
      fetchProdInfo,
      setFetchProdInfo,
      fetchQuoteInfo,
      setFetchQuoteInfo,
      fetchSalesInfo,
      setFetchSalesInfo,
    ]
  );

  const handleView = useCallback(() => {
    return views[currentView] || null;
  }, [views, currentView]);

  return (
    <div
      className="mainBody"
      onClick={() => {
        // e.stopPropagation();
        setShowLogout(false);
        setShowNotification(false);
      }}
    >
      <div
        className={`position-relative ${
          sideBarSmall ? "sidebarSmall" : "sidebar"
        }`}
      >
        <span
          className="position-absolute shrink_icon cursorPointer"
          onClick={() => {
            setSideBarSmall(!sideBarSmall);
          }}
        >
          {sideBarSmall ? expandArrow : shrinkArrow}
        </span>
        <div className="d-flex mt-1 mb-1">
          <img src={crmLogo} alt="img" className="sidebar_logo" />
        </div>
        <Sidebar
          filteredMenuItems={filteredMenuItems}
          currentView={currentView}
          setCurrentView={setCurrentView}
          setInnerPage={setInnerPage}
          sideBarSmall={sideBarSmall}
          setPastViewInfo={setPastViewInfo}
          setInputValue={setInputValue}
          backToDefaultValues={backToDefaultValues}
          showSearchInput={showSearchInput}
          setShowSearchInput={setShowSearchInput}
        />
      </div>

      <div className={`w-100 ${sideBarSmall ? "rightSideSmall" : "rightSide"}`}>
        <div
          className={
            showSearchInput
              ? "topBar d-flex justify-content-between"
              : "topBar d-flex justify-content-end"
          }
        >
          {showSearchInput && (
            <div className="inputContainer">
              <img src={Search} alt="search" className="searchIcon" />
              <input
                type="text"
                value={inputValue}
                placeholder="Search"
                className="topBarSearch px-5"
                onKeyDown={handleKeyDown}
                onChange={handleInputChange}
              />
            </div>
          )}

          {showFilterSearchModal && (
            <Modal
              show={showFilterSearchModal}
              className="d-flex justify-content-start align-items-start mt-5"
              aria-labelledby="example-custom-modal-styling-title"
              size="md"
            >
              <Modal.Header className="searchHeader">
                <div className="d-flex gap-3 align-items-center w-100">
                  <input
                    type="text"
                    value={inputValue}
                    placeholder="Type here and press Enter to search"
                    className="modalSearch px-2"
                    onKeyDown={handleKeyDown}
                    onChange={handleInputChange}
                    autoFocus="autofocus"
                  />

                  <div className="d-flex gap-2">
                    <img
                      src={filter}
                      alt="filter"
                      className="cursorPointer ms-1"
                      onClick={() => {
                        setShowFilterItems(true);
                      }}
                    />
                    <img
                      alt="cancel"
                      src={Cancel}
                      className="cursorPointer"
                      onClick={() => {
                        setShowFilterSearchModal(false);
                        setShowFilterItems(false);
                      }}
                    />
                  </div>
                </div>{" "}
              </Modal.Header>
              {storeMessage && (
                <div style={{ color: "red" }} className="ms-3">
                  {storeMessage}
                </div>
              )}
              {showFilterItems && (
                <div>
                  <div
                    className="text-end cursorPointer me-4 hide-text"
                    onClick={() => setShowFilterItems(false)}
                    style={{ color: "#2177FE" }}
                  >
                    Hide
                  </div>
                  <Modal.Body className="ModalBody overflow-auto">
                    <div className="container">
                      <div className="row">
                        {filterSearchCheckBoxes.map((checkbox, index) => (
                          <div key={index} className="col-md-4 cursorPointer">
                            <div className="form-check">
                              <input
                                className="form-check-input cursorPointer"
                                type="checkbox"
                                value={checkbox.value}
                                id={`checkbox-${checkbox.value}`}
                                checked={checkedItems[checkbox.value]}
                                onChange={handleCheckboxChange}
                              />
                              <label
                                className="form-check-label cursorPointer"
                                htmlFor={`checkbox-${checkbox.value}`}
                                style={{ fontSize: "15px" }}
                              >
                                {checkbox.label}
                              </label>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  </Modal.Body>
                </div>
              )}
            </Modal>
          )}

          <div className="d-flex align-items-center gap-3 position-relative">
            {!showSearchInput && (
              <img
                src={ModalSearch}
                alt="search"
                className="cursorPointer ms-1"
                onClick={() => {
                  setShowFilterSearchModal(true);
                  setInputValue("");
                  setCheckedItems(initializeCheckedItems);
                  setShowFilterItems(false);
                }}
              />
            )}

            <div className="position-relative w-100">
              <img
                height="10%"
                src={notification}
                style={{
                  border: "2px solid #fff",
                  borderRadius: "20px",
                  padding: "5px",
                }}
                alt="img"
                className="cursorPointer"
                onClick={(e) => {
                  e.stopPropagation();
                  setShowNotification(!showNotification);
                  setShowLogout(false);
                }}
              />
              <span className="notification_count position-absolute text-white rounded-circle d-flex justify-content-center align-items-center p-1">
                <span>{notificationCount}</span>
              </span>
              {showNotification ? (
                <div
                  className="notification_dropdown position-absolute border rounded-2"
                  // ref={modalRef}
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                >
                  <div className="notification_triangle position-absolute"></div>
                  <div className="px-2 py-1">
                    <div className="pb-2 fs-5 ms-1 mt-2">Notifications</div>
                    <div className="ms-1 me-2">
                      <div className="d-flex align-items-center justify-content-between  border-bottom">
                        <div className="d-flex gap-4 mb-1 mt-2">
                          {["all", "user"].map((ele, index) => {
                            return (
                              <span
                                className={`text-center cursorPointer first_letter_uppercase ${
                                  selectedTab === ele
                                    ? "notificationTabSelected commonTextColor border-3 rounded-top"
                                    : ""
                                }`}
                                onClick={(e) => {
                                  e.stopPropagation();
                                  selectedTab !== ele && getAlerts(ele);
                                }}
                                key={index}
                              >
                                {ele}
                              </span>
                            );
                          })}
                        </div>
                        <div
                          className={`readalloption cursorPointer small px-2 ${
                            notificationCount === 0 && "opacity-50"
                          }`}
                          style={{ color: "#2177fe" }}
                          onClick={(e) => {
                            e.stopPropagation();
                            notificationCount > 0 &&
                              alertSettingChanges("", "all");
                          }}
                        >
                          Mark all as read
                        </div>
                      </div>
                    </div>
                  </div>
                  <ul
                    className="dashboard_container px-2"
                    style={{ maxHeight: "20rem" }}
                  >
                    <>
                      {Object.keys(notificationData)
                        .sort(compareDates)
                        .map((key) => {
                          return (
                            <div key={key} className="w-100">
                              <h6
                                className={`notifiHeader first_letter_uppercase mb-0 pt-2 px-2 mt-1 ${
                                  notificationData[key].length === 0
                                    ? "d-none"
                                    : ""
                                  // ""
                                }`}
                              >
                                {/^[0-9]*$/.test(key.replace(/-/g, ""))
                                  ? moment(key, "MM-DD-YYYY").format(
                                      "MMM D, YYYY"
                                    )
                                  : key}
                              </h6>
                              {notificationData[key].map((item) => {
                                return (
                                  <li
                                    className="cursorPointer mb-3 small border-bottom pb-1"
                                    key={item.alertId}
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      alertSettingChanges(
                                        item,
                                        item.isRead === 0 ? 1 : 0
                                      );
                                    }}
                                  >
                                    <div className="d-flex align-items-center gap-3">
                                      <div
                                        className={`dot ${
                                          item.isRead === 0
                                            ? "dotRead ms-2 mb-4"
                                            : "ms-2 mb-4"
                                        }`}
                                      ></div>
                                      <div style={{ width: "95%" }}>
                                        <div>{item.alertMessage}</div>
                                        <div className="opacity-75">
                                          {item.createDate}
                                        </div>
                                      </div>
                                    </div>
                                  </li>
                                );
                              })}
                            </div>
                          );
                        })}
                    </>
                  </ul>
                </div>
              ) : null}
            </div>
            <div className="cursorPointer">
              <label
                htmlFor="topCalender"
                style={{
                  border: "2px solid #fff",
                  borderRadius: "20px",
                  padding: "2px 5px",
                }}
                className="position-relative d-flex justify-content-between align-items-center w-100"
              >
                <img
                  src={calendar}
                  alt="calender"
                  className="position-absolute cursorPointer"
                />
                <input
                  type="date"
                  className="opacity-0 cursorPointer"
                  id="topCalender"
                  style={{ width: "22px" }}
                />
              </label>
            </div>

            <img
              height="10%"
              src={setting}
              style={{
                border: "2px solid #fff",
                borderRadius: "20px",
                padding: "5px",
              }}
              alt="img"
              className="cursorPointer"
              onClick={() => {
                setInnerPage("");
                setCurrentView("settings");
                setInputValue("");
              }}
            />
            <img
              style={{
                border: "2px solid #fff",
                borderRadius: "20px",
                padding: "5px",
              }}
              width={36}
              height={36}
              // src={profile}
              src={loginUserInfo.userImageData || leadAvatar}
              alt="img"
              className="cursorPointer position-relative"
              onClick={(e) => {
                e.stopPropagation();
                setShowLogout(!showLogout);
                setInputValue("");
                setShowNotification(false);
              }}
            />
            {showLogout && (
              <div
                id="profileInfo"
                className="profileInfoContainer"
                onClick={(e) => {
                  e.stopPropagation();
                }}
              >
                <div className="triangle-container triangleClass">
                  <div className="triangle"></div>
                </div>
                <div className="bg-white p-1">
                  <div
                    style={{
                      color: "#515151",
                      fontSize: "16px",
                      fontWeight: "400",
                    }}
                  >
                    {loginUserInfo.firstName} {loginUserInfo.lastName}
                  </div>
                  <div
                    style={{
                      color: "#515151",
                    }}
                    className="small opacity-75 overflow_text"
                  >
                    {loginUserInfo.emailId}
                  </div>
                  <div
                    style={{
                      borderBottom: "1px solid rgba(185, 185, 185, 0.7) ",
                    }}
                    className="mt-1"
                  ></div>
                  {subscriptionType === "Free Plan" && (
                    <div className="mt-2 upgradeClass p-2">
                      <div style={{ color: "#000000", fontSize: "14px" }}>
                        {differenceInDays < 10
                          ? `Your Trial expires in ${differenceInDays} days.`
                          : "Upgrade to Premium to explore the power of Chain CRM."}
                      </div>
                      <div
                        className="d-flex gap-2 justify-content-center align-items-center upgrade p-1 text-center mt-2 cursorPointer"
                        onClick={handleUpgradeNavigate}
                      >
                        <div style={{ color: "#000000", fontSize: "14px" }}>
                          UPGRADE
                        </div>
                        <img src={upgrade} alt="upgrade" />
                      </div>
                    </div>
                  )}

                  {subscriptionType === "Standard Plan" && (
                    <div className="mt-2 upgradeClass p-2">
                      <div style={{ color: "#000000", fontSize: "14px" }}>
                        {differenceInDays < 10
                          ? `Please Renew your plan. Your plan expires in ${differenceInDays} days.`
                          : `Upload to Premium & explore the power of Chain CRM.`}
                      </div>
                      <div
                        className="d-flex gap-2 justify-content-center align-items-center upgrade p-1 text-center mt-2 cursorPointer"
                        onClick={handleUpgradeNavigate}
                      >
                        <div style={{ color: "#000000", fontSize: "14px" }}>
                          {differenceInDays < 10 ? "RENEW" : "UPGRADE"}
                        </div>
                        <img src={upgrade} alt="upgrade" />
                      </div>
                    </div>
                  )}

                  {subscriptionType === "Premium Plan" && (
                    <div className="mt-2 upgradeClass p-2">
                      <div style={{ color: "#000000", fontSize: "14px" }}>
                        Please Renew your plan. Your plan expires in{" "}
                        {differenceInDays} days.
                      </div>
                      <div
                        className="d-flex gap-2 justify-content-center align-items-center upgrade p-1 text-center mt-2 cursorPointer"
                        onClick={handleUpgradeNavigate}
                      >
                        <div style={{ color: "#000000", fontSize: "14px" }}>
                          RENEW
                        </div>
                        <img src={upgrade} alt="upgrade" />
                      </div>
                    </div>
                  )}

                  <div className="mt-2 d-flex gap-3 align-items-center cursorPointer">
                    <img src={Logout} alt="logout" height={30} />
                    <div
                      style={{
                        color: "#F45B69",
                        fontSize: "14px",
                        fontWeight: "400",
                      }}
                      onClick={handleSignOut}
                    >
                      Sign Out
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
        {handleView()}
      </div>
      <div></div>
      <NotificationContainer />
      <ToastContainer />
    </div>
  );
}

export default Home;

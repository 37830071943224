import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import SearchIcon from "../Assets/search.svg";
import logoConvert from "../Assets/logoConvert.svg";

function ContactListModal({
  isContactListModal,
  setIsContactListModal,
  onSelectedLeadChange,
  defaultUser,
  allContactDetails,
}) {
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedContact, setSelectedContact] = useState("");

  const handleSearchChange = (e) => {
    const { value } = e.target;
    // Check if the input is not just spaces
    if (value.trim() !== "" || value === "") {
      setSearchQuery(value);
    }
  };
  const filteredUserList = allContactDetails?.filter((item) =>
    item.contactName?.toLowerCase().includes(searchQuery?.toLowerCase())
  );

  useEffect(() => {
    setSearchQuery("");
    setSelectedContact(
      defaultUser || {
        name: "",
        userId: "",
      }
    );
  }, [isContactListModal]);

  useEffect(() => {
    setSearchQuery("");
    setSelectedContact(
      defaultUser || {
        dealName: "",
        dealId: "",
      }
    );
  }, [isContactListModal]);

  return (
    <div className="OwnerModal">
      <Modal
        show={isContactListModal}
        className="d-flex justify-content-center align-items-center"
        aria-labelledby="example-custom-modal-styling-title"
        size="lg"
        id="commonLeadModal"
      >
        <Modal.Body className="ModalBody  overflow-auto">
          <div
            className="modalUS row justify-content-center"
            style={{ margin: "0", gap: "10px", paddingBottom: "10px" }}
          >
            <div className="header_Container d-flex justify-content-between">
              <h4 className="commonTextColor pt-2">Choose Contact</h4>
              <div className="inputContainer d-flex justify-content-start">
                <input
                  type="text"
                  placeholder="Search"
                  className="topBarModalSearch px-5"
                  onChange={handleSearchChange}
                  value={searchQuery}
                />
                <img src={SearchIcon} alt="search" className="searchIcon" />
              </div>
            </div>

            <div className="Owner_table_container border border-dark rounded-2 h-25">
              <div className="table-wrapper">
                <table className="userdata-table">
                  <thead>
                    <tr>
                      <th>Contact Name</th>
                      <th>Account Name</th>
                      <th>Email</th>
                      <th>Phone</th>
                      <th>Contact Owner</th>
                    </tr>
                  </thead>
                  <tbody className="table-body tableUserData">
                    {filteredUserList?.map((item, index) => (
                      <tr
                        key={index}
                        onClick={() => {
                          setSelectedContact(item);
                        }}
                      >
                        <td>{item.contactName}</td>
                        <td>{item.accountName}</td>
                        <td>{item.emailId}</td>
                        <td>{item.phone}</td>
                        <td>{item.contactOwner}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
            <div className="d-flex pb-2 pt-3 justify-content-between">
              <div className="selectedUser d-flex gap-1">
                <span>Selected User</span>
                <img src={logoConvert} alt="img" height={20} width={20} />
                <span>{selectedContact.contactName}</span>
              </div>
              <div className="d-flex gap-2">
                <button
                  className="btn btn-outline-secondary border border-primary btn-sm px-3 py-2 text-dark"
                  onClick={() => setIsContactListModal(false)}
                >
                  Cancel
                </button>
                <button
                  className="btn btnColor btn-sm px-3 text-white"
                  onClick={() => {
                    setIsContactListModal(false);
                    onSelectedLeadChange(selectedContact);
                  }}
                >
                  Done
                </button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default ContactListModal;

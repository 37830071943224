import React from "react";
import { Modal } from "react-bootstrap";

const unAuthorizedIcon = (
  <svg
    className="mb-1"
    width="22"
    height="22"
    viewBox="0 0 28 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.83317 5.83203L22.1665 22.1654"
      stroke="url(#paint0_linear_3796_17819)"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M25.6663 13.9993C25.6663 7.55603 20.443 2.33268 13.9997 2.33268C7.55635 2.33268 2.33301 7.55603 2.33301 13.9993C2.33301 20.4427 7.55635 25.666 13.9997 25.666C20.443 25.666 25.6663 20.4427 25.6663 13.9993Z"
      stroke="url(#paint1_linear_3796_17819)"
      strokeWidth="2"
    />
    <defs>
      <linearGradient
        id="paint0_linear_3796_17819"
        x1="22.1665"
        y1="13.9987"
        x2="5.83317"
        y2="13.9987"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#CB304C" />
        <stop offset="1" stopColor="#A92222" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_3796_17819"
        x1="2.33301"
        y1="13.9993"
        x2="25.6663"
        y2="13.9993"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#CB304C" />
        <stop offset="1" stopColor="#A92222" />
      </linearGradient>
    </defs>
  </svg>
);

const AccessModal = ({ showAccessModal, setShowAccessModal }) => {
  return (
    <div>
      <Modal
        show={showAccessModal}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
      >
        <Modal.Header
          onClick={() => {
            setShowAccessModal(false);
          }}
          style={{
            fontSize: "20px",
            fontWeight: "bold",
            background:
              "linear-gradient(to right, rgba(64, 123, 255, 1), rgba(18, 167, 254, 1))",
            WebkitBackgroundClip: "text",
            backgroundClip: "text",
            WebkitTextFillColor: "transparent",
          }}
        >
          <div className="d-flex justify-content-between w-100">
            <span>
              <span className="">{unAuthorizedIcon}</span>
              <span className="ps-2 pt-2">Permission Denied</span>
            </span>

            <span className="cursorPointer">
              <svg
                width="25"
                height="25"
                viewBox="0 0 25 25"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M15.6238 15.623L9.37451 9.37305M9.37518 15.623L15.6245 9.37305"
                  stroke="#2177FE"
                  strokeWidth="1.5625"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M22.9163 12.4993C22.9163 6.74638 18.2526 2.08268 12.4997 2.08268C6.74671 2.08268 2.08301 6.74638 2.08301 12.4993C2.08301 18.2523 6.74671 22.916 12.4997 22.916C18.2526 22.916 22.9163 18.2523 22.9163 12.4993Z"
                  stroke="#2177FE"
                  strokeWidth="1.5625"
                />
              </svg>
            </span>
          </div>
        </Modal.Header>
        <Modal.Body className="ModalBody overflow-auto">
          <div className="opacity-75 py-3">
            <p className="mb-0 pb-0">
              You do not have permission to perform this operation.
            </p>
            <p className="mt-0 pt-0">Contact your administration</p>
          </div>
          <div className="d-flex flex-column gap-4">
            <div className="d-flex justify-content-end w-100 gap-4">
              <button
                className="createContactButtonSave"
                onClick={() => {
                  setShowAccessModal(false);
                }}
              >
                Ok
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default AccessModal;

import React, { useEffect, useState, useRef } from "react";
import "./lead.css";
import Select from "react-select";
import filterIcon from "../../Assets/Frame (3).svg";
import groupIcon from "../../Assets/elements (4).svg";
import searchIcon from "../../Assets/search.svg";
import axios from "axios";
import "../../Stylesheet/commonstyle.css";
import prodIcon from "../../Assets/product.svg";
import openActivityIcon from "../../Assets/openActivity.svg";
import closeActivityIcon from "../../Assets/closeActivity.svg";
import updateIcon from "../../Assets/Group 71.svg";
import cancelIcon from "../../Assets/Group 72.svg";
import groupIcon2 from "../../Assets/Group 74.svg";
import history from "../../Assets/history.svg";
import userSearch from "../../Assets/user-search-01.svg";
import down from "../../Assets/Down31.svg";
import up from "../../Assets/Up.svg";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import "react-notifications/lib/notifications.css";
import filterMenu from "../../Assets/filterMenu.svg";
import tableFilterIcon from "../../Assets/filterIcon.svg";
// import hierachy from "../../Assets/hierarchy.svg";
import Pagination from "../../common/Pagination";
import Clone from "../../Assets/Clone.svg";
import Share from "../../Assets/ShareIcon.svg";
import Delete from "../../Assets/DeleteIconNew.svg";
import leadAvatar from "../../Assets/Lead_Avatar.png";
import {
  emailValidation,
  validateCompanyName,
  validateFirstName,
  validateLastName,
  validateAnnualRevenue,
  validateJobTitle,
  validateSkypeId,
  validateStateIndia,
  validateCountryName,
  validateCityIndia,
  validateTwitterUsername,
  websiteValidation,
  removeExtraSpaces,
  validateFaxNumber,
  customCommonStyles,
  removeLeadingZeros,
} from "../../common/CommonComponent";
import NoData from "../../Assets/Nodata.svg";
import { URI } from "../../constants";
import LeadOwnerModal from "../../common/LeadOwnerModal";
import {
  leadSourceOptions,
  industryOptions,
  ratingOptions,
  leadStatusOptions,
  pageOption,
  massOptions,
} from "../../common/CommonData";
import EditableInput from "../../common/EditableInput";
import filterLine from "../../Assets/filterLine.svg";
import { Modal } from "react-bootstrap";
import arrowUp from "../../Assets/square_arrow_up.svg";
import arrowDown from "../../Assets/square_arrow_down.svg";
import pin from "../../Assets/Pin_column.svg";
import unPin from "../../Assets/Unpin.svg";
import cancel from "../../Assets/cancel_circle.svg";
import Note from "../../common/commonRelatedList/Note";
import Product from "../../common/commonRelatedList/ProductList";
import OpenActivity from "../../common/commonRelatedList/OpenActivity";
import CloseActivity from "../../common/commonRelatedList/CloseActivity";
import downloadIcon from "../../Assets/Frame (5).svg";
import NoteBook from "../../Assets/notebook-01.svg";
import ShareModal from "../../common/ShareModal";
import leadNoteData from "../../Assets/leadNodata.svg";
import { useSelector } from "react-redux";
import Loader from "../../common/commonRelatedList/Loader";
import AccessModal from "../../common/AccessModal";
import ChangeOwnerModal from "../../common/commonRelatedList/ChangeOwnerModal";
import {
  leftArrow,
  rightAngleArrow,
  leftAngleArrow,
  tick,
  expandArrow,
  shrinkArrow,
} from "../../common/commonSVGs/commonSVGIcons.js";
import { EXPLORER_URL } from "../../constants/DataConst.js";
import CampaignsList from "../../common/commonRelatedList/CampaignsList.js";
import CampaignIcon from "../../Assets/CampaignBlue.svg";
import Lottie from "react-lottie";
import { defaultOptions } from "../../utils/defaultOptions.js";
import getHeaders from "../../common/ApiHeader.js";
import InvitedMeetingList from "../../common/commonRelatedList/InvitedMeetingList.js";
import { handleApiError } from "../../utils/ApiUtils";
import { useNavigate } from "react-router-dom";
import campaignIcon from "../../Assets/De-Active/campaign.svg";
import SearchIcon from "../../Assets/SearchIcon.svg";
import logoConvert from "../../Assets/logoConvert.svg";

function Lead({
  setCurrentView,
  setInnerPage,
  innerPage,
  permissionList,
  notePermission,
  pastViewInfo,
  setPastViewInfo,
  currentView,
  fetchProdInfo,
  storeModuleId,
  // selectedCampaignId,
}) {
  const navigate = useNavigate();
  const reduxStore = useSelector((state) => state.reduxState);
  // const [innerPage, setInnerPage] = useState("list");
  const [emailOptOut, setEmailOptOut] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [validEmail, setValidEmail] = useState(true);
  // const [secEmailError, setSecEmailError] = useState(true); setSecEmailError is not in use
  const [leadDetails, setLeadDetails] = useState([]);
  const [filteredLeadDetails, setFilteredLeadDetails] = useState([]);
  const [leadOverviewDetails, setLeadOverviewDetails] = useState({});
  const [invitedMeetings, setInvitedMeetings] = useState([]);
  const [updateButtonLabel, setUpdateButtonLabel] = useState("Save");
  const [existingClone, setExistingClone] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedCheckboxes, setSelectedCheckboxes] = useState([]);
  const [editNote, setEditNote] = useState(false);
  const [selectedLead, setSelectedLead] = useState();

  const options = reduxStore.filterOptions.lead;
  const stages = {
    Qualification: 10,
    "Need Analysis": 20,
    "Value Proposition": 40,
    "Identify Decision Makers": 60,
    "Proposal/Price Quote": 75,
    "Negotiation/Review": 90,
    "Closed Won": 100,
    "Closed Lost": 0,
    "Closed Lost to Competition": 0,
    // "Identify Decision Makers": 0,
  };

  const [selectedOption, setSelectedOption] = useState(options[0]);
  const [shareModal, setShareModal] = useState(false);
  const [showImageModal, setShowImageModal] = useState(false);
  // const [deleteMsg, setDeleteMsg] = useState(""); setDeleteMsg is not used
  const [showOptionsLead, setShowOptionsLead] = useState(false);
  const [noteInfo, setNoteInfo] = useState([]);
  const [selectRecent, setSelectRecent] = useState("ALL");
  const [imageMsg, setImageMsg] = useState("");
  const [emailErrorMsg, setEmailErrorMsg] = useState("");
  const [showRemoveModal, setShowRemoveModal] = useState(false);
  const [showAccessModal, setShowAccessModal] = useState(false);
  const [updateNotes, setUpdateNotes] = useState({
    description: "",
    title: "",
    noteImageList: [],
  });
  const [isShrinked, setIsShrinked] = useState(false);
  const [isDragging, setIsDragging] = useState(false);
  const [isOwnerSelect, setIsOwnerSelect] = useState(false);

  // eslint-disable-next-line no-console
  console.log("leadOverviewDetails",leadOverviewDetails)

  const allowedFileTypes = [
    "image/png",
    "image/jpg",
    "image/jpeg",
    "image/gif",
  ];

  const maxSizeInBytes = 2 * 1024 * 1024; // 2 MB in bytes
  const [isChangeOwnerList, setIsChangeOwnerList] = useState(false);
  const [emailNotification, setEmailNotification] = useState(true);
  const [errorMessage, setErrorMessage] = useState("");
  const [amtErrorMsg, setAmtErrorMsg] = useState("");
  const [dateErrorMsg, setDateErrorMsg] = useState("");
  const [probablityErrorMsg, setProbabilityErrorMsg] = useState("");
  const [shareUserList, setShareUserList] = useState([]);
  const [shareRoleList, setShareRoleList] = useState([]);
  const [shareGroupList, setShareGroupList] = useState([]);
  const[sharePublicList,setSharePublicList]=useState([])
  const [shareInfoModal, setShareInfoModal] = useState(false);
  const [imageLoader, setImageLoader] = useState(false);

  const [dealErrorMsg, setDealErrorMsg] = useState("");
  const [deletedImg, setDeletedImg] = useState("");
  const [showTooltipAnnualRevenue, setShowTooltipAnnualRevenue] =
    useState(false);
  const [tooltipContent, setTooltipContent] = useState("");
  const [noteDeleteConfirmation, setNoteDeleteConfirmation] = useState(false);

  const handleSelectRecent = (item) => {
    setSelectRecent(item);
    var value = "";
    if (item === "Recent Last") {
      value = "RECENT_LAST";
    } else if (item === "Recent First") {
      value = "RECENT_FIRST";
    } else {
      value = item;
    }
    getNotesDetails(leadId, value);
  };

  const leadsColumn = [
    {
      name: "Lead Name",
      isChecked: true,
      value: "leadName",
    },
    {
      name: "Company",
      isChecked: true,
      value: "companyName",
    },
    {
      name: "Email",
      isChecked: true,
      value: "emailId",
    },
    {
      name: "Phone Number",
      isChecked: true,
      value: "phoneNumber",
    },
    {
      name: "Lead Source",
      isChecked: false,
      value: "leadSource",
    },
    {
      name: "Lead Owner",
      isChecked: true,
      value: "leadOwner",
    },
    {
      name: "Hash",
      isChecked: true,
      value: "transHash",
    },
    {
      name: "First Name",
      isChecked: false,
      value: "firstName",
    },
    {
      name: "Last Name",
      isChecked: false,
      value: "lastName",
    },
    {
      name: "Fax",
      isChecked: false,
      value: "fax",
    },
    {
      name: "Mobile",
      isChecked: false,
      value: "mobile",
    },
    {
      name: "Job Title",
      isChecked: false,
      value: "jobTitle",
    },
    {
      name: "Website",
      isChecked: false,
      value: "website",
    },
    {
      name: "Lead Status",
      isChecked: false,
      value: "leadStatus",
    },
    {
      name: "Industry",
      isChecked: false,
      value: "industryType",
    },
    {
      name: "No. of Employees",
      isChecked: false,
      value: "noOfEmployees",
    },
    {
      name: "Annual Revenue",
      isChecked: false,
      value: "annualRevenue",
    },
    {
      name: "Rating",
      isChecked: false,
      value: "rating",
    },
    {
      name: "Secondary Mail",
      isChecked: false,
      value: "secondaryEmailId",
    },
    {
      name: "Created By",
      isChecked: false,
      value: "createdBy",
    },
    {
      name: "Modified By",
      isChecked: false,
      value: "modifiedBy",
    },
    {
      name: "Skype ID",
      isChecked: false,
      value: "skypeId",
    },
    {
      name: "Twitter",
      isChecked: false,
      value: "twitter",
    },
    {
      name: "Country",
      isChecked: false,
      value: "country",
    },
    {
      name: "State",
      isChecked: false,
      value: "state",
    },
    {
      name: "City",
      isChecked: false,
      value: "city",
    },
    {
      name: "Street",
      isChecked: false,
      value: "street",
    },
    {
      name: "Zip Code",
      isChecked: false,
      value: "zipCode",
    },
  ];
  const fieldDropdowns = [
    {
      name: "Lead Owner",
      isShowed: true,
      type: "select",
      value: "",
    },
    {
      name: "Email Id",
      isShowed: true,
      type: "input",
    },
    {
      name: "Mobile",
      isShowed: true,
      type: "input",
    },
    {
      name: "Phone Number",
      isShowed: false,
      type: "input",
    },
    {
      name: "Job Title",
      isShowed: true,
      type: "input",
      value: "title",
    },
    {
      name: "Fax",
      isShowed: false,
      type: "input",
    },
    {
      name: "Rating",
      isShowed: true,
      type: "dropdown",
    },
    {
      name: "Website",
      isShowed: false,
      type: "input",
    },
    {
      name: "Lead Source",
      isShowed: false,
      type: "dropdown",
    },
    {
      name: "Lead Status",
      isShowed: false,
      type: "dropdown",
    },
    {
      name: "Industry Type",
      isShowed: false,
      type: "dropdown",
    },
    {
      name: "Annual Revenue",
      isShowed: false,
      type: "input",
    },
    {
      name: "Email Opt Out",
      isShowed: false,
      type: "checkbox",
    },
    {
      name: "No Of Employees",
      isShowed: false,
      type: "input",
    },
    {
      name: "Skype Id",
      isShowed: false,
      type: "input",
    },
    {
      name: "Created By",
      isShowed: false,
      type: "readOnly",
    },
    {
      name: "Modified By",
      isShowed: false,
      type: "readOnly",
    },
    {
      name: "Secondary Email Id",
      isShowed: false,
      type: "input",
    },
    {
      name: "Twitter",
      isShowed: false,
      type: "input",
    },
    {
      name: "Street",
      isShowed: false,
      type: "input",
    },
    {
      name: "City",
      isShowed: false,
      type: "input",
    },
    {
      name: "State",
      isShowed: false,
      type: "input",
    },
    {
      name: "Zip Code",
      isShowed: false,
      type: "input",
    },
    {
      name: "Country",
      isShowed: false,
      type: "input",
    },
    // {
    //   name: "Description1",
    //   isShowed: false,
    //   type: "input",
    // },
  ];

  const { loginUserId } = reduxStore;
  const [fieldLabels, setFieldLabels] = useState(fieldDropdowns);
  const [showSearchFields, setShowSearchFields] = useState(fieldDropdowns);
  const [searchField, setSearchField] = useState("");
  const [selectedField, setSelectedField] = useState("");
  const [selectedObj, setSelectedObj] = useState({
    name: "Annual Revenue",
    isChecked: false,
    value: "annualRevenue",
  });
  const [massUpdateValue, setMassUpdateValue] = useState("");
  const [selectedHeadings, setSelectedHeadings] = useState(leadsColumn);
  const [savingHeadings, setSavingHeadings] = useState(leadsColumn);
  const [showHeadings, setShowHeadings] = useState(false);
  const [showModalType, setShowModalType] = useState("");
  const [searchColumn, setSearchColumn] = useState("");
  const [showSelectedFilter, setShowSelectedFilter] = useState("");
  const [filterType, setFilterType] = useState("");
  const [pinnedColumn, setPinnedColumn] = useState("Lead Name");
  const [showButtons, setShowButtons] = useState(false);
  const [note, setNote] = useState("");
  const [addTitle, setAddTitle] = useState("");
  const [selectedNoteFiles, setSelectedNoteFiles] = useState([]);
  const [selectedFiles, setSelectedFiles] = useState(null);
  const [showTitleNote, setShowTitleNote] = useState(false);
  const [noteTitle, setNoteTitle] = useState("");
  const [imageUrl, setImageUrl] = useState(null);
  const [uploadImg, setUploadImg] = useState(false);
  const [productsList, setProductsList] = useState([]);
  const [campaignList, setCampaignList] = useState([]);
  const [openActivities, setOpenActivities] = useState([]);
  const [openActivitiesCalls, setOpenActivitiesCalls] = useState([]);
  const [openActivitiesMeetings, setOpenActivitiesMeetings] = useState([]);
  const [closeActivities, setCloseActivities] = useState([]);
  const [closeActivitiesCalls, setCloseActivitiesCalls] = useState([]);
  const [closeActivitiesMeetings, setCloseActivitiesMeetings] = useState([]);
  const [clickedDropdownObj, setClickedDropdownObj] = useState({
    label: "",
    value: "",
  });

  useEffect(() => {
    if (storeModuleId) {
      leadOverviewDetail(storeModuleId);
      setLeadId(storeModuleId);
    }
  }, []);

  const handleSelectCheckboxChange = (event) => {
    const { value } = event.target;

    const selectedCheckbox = { moduleId: value };

    setSelectedCheckboxes((prevSelectedCheckboxes) => {
      const isAlreadySelected = prevSelectedCheckboxes.some(
        (checkbox) => checkbox.moduleId === value
      );

      if (isAlreadySelected) {
        return prevSelectedCheckboxes.filter(
          (checkbox) => checkbox.moduleId !== value
        );
      } else {
        return [...prevSelectedCheckboxes, selectedCheckbox];
      }
    });
  };

  const handleTextAreaClick = () => {
    if (hasPermission("CREATE")) {
      setShowButtons(true);
    } else {
      setShowAccessModal(true);
    }
  };

  const handleAddTitleClick = () => {
    setShowTitleNote(true);
  };

  const handleUploadImage = (e) => {
    const file = e.target.files[0];
    if (file) {
      if (!allowedFileTypes.includes(file.type)) {
        setImageMsg(
          "Invalid file type. Please upload an image file (JPG, PNG, JPEG)."
        );
        setTimeout(() => setImageMsg(""), 5000);
        return;
      }
      if (file.size > maxSizeInBytes) {
        setImageMsg("File size exceeds 2 MB. Please select a smaller file.");
        setTimeout(() => setImageMsg(""), 5000);
        return;
      }
      const imageUrl = URL.createObjectURL(file);
      setImageUrl(imageUrl);
      setSelectedFiles(e.target.files[0]);
    }
    setUploadImg(true);
  };

  const handlePageDropDowmChange = (selectedOption) => {
    setCurrentPage(1);
    setPerPage(parseInt(selectedOption.value));
  };

  const scrollToSection = (sectionId) => {
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: "smooth" });
    }
  };

  const stageOptions = [
    { value: "Qualification", label: "Qualification" },
    { value: "Need Analysis", label: "Need Analysis" },
    { value: "Value Proposition", label: "Value Proposition" },
    // { value: "Id. Decision Makers", label: "Id. Decision Makers" },
    { value: "Proposal/Price Quote", label: "Proposal/Price Quote" },
    { value: "Negotiation/Review", label: "Negotiation/Review" },
    { value: "Closed Won", label: "Closed Won" },
    { value: "Closed Lost", label: "Closed Lost" },
    {
      value: "Closed Lost to Competition",
      label: "Closed Lost to Competition",
    },
    { value: "Identify Decision makers", label: "Identify Decision makers" },
  ];

  const roleOptions = [
    { value: "None", label: "None" },
    { value: "Developer/Evaluator", label: "Developer/Evaluator" },
    { value: "Decision Makers", label: "Decision Makers" },
    { value: "Purchasing", label: "Purchasing" },
    { value: "Executive Sponsor", label: "Executive Sponsor" },
    { value: "Engineering Lead", label: "Engineering Lead" },
    { value: "Economic Decision Makers", label: "Economic Decision Makers" },
    { value: "Product Management", label: "Product Management" },
  ];
  const [leadInfo, setLeadInfo] = useState({
    firstName: "",
    lastName: "",
    title: "",
    phoneNumber: "",
    mobileNumber: "",
    emailId: "",
    fax: "",
    skypeId: "",
    secondaryEmailId: "",
    twitter: "",
    website: "",
    companyName: "",
    annualRevenue: "",
    noOfEmployees: "",
    rating: ratingOptions[0],
    industryType: industryOptions[0],
    leadSource: leadSourceOptions[0],
    leadStatus: leadStatusOptions[0],
    country: "",
    state: "",
    city: "",
    street: "",
    zipCode: "",
    leadImage: "",
    emailOptOut: emailOptOut,
  });

  const [websiteMsg, setWebsiteMsg] = useState("");
  const [companyErrorMsg, setCompanyErrorMsg] = useState("");
  const [firstNameErrorMsg, setFirstNameErrorMsg] = useState("");
  const [titleMsg, setTitleMsg] = useState("");
  const [annualRevenueMsg, setAnnualRevenueMsg] = useState("");
  const [skypeIdMsg, setSkypeIdMsg] = useState("");
  const [twitterId, setTwitterId] = useState("");
  const [cityMsg, setCityMsg] = useState("");
  const [countryMsg, setCountryMsg] = useState("");
  const [stateMsg, setStateMsg] = useState("");
  const [lastNameErrorMsg, setLastNameErrorMsg] = useState("");
  const [faxErrorMsg, setFaxErrorMsg] = useState("");

  const [leadUpdateInfo, setLeadUpdateInfo] = useState({
    firstName: "",
    lastName: "",
    title: "",
    phoneNumber: "",
    mobileNumber: "",
    emailId: "",
    fax: "",
    skypeId: "",
    secondaryEmailId: "",
    twitter: "",
    website: "",
    companyName: "",
    annualRevenue: "",
    noOfEmployees: "",
    rating: ratingOptions[0],
    industryType: industryOptions[0],
    leadSource: leadSourceOptions[0],
    leadStatus: leadStatusOptions[0],
    country: "",
    state: "",
    city: "",
    street: "",
    zipCode: "",
    leadImage: "",
  });

  // const [activeTab, setActiveTab] = useState("note"); activeTab is not in use
  const [existingLead, setExistingLead] = useState(false);
  const [leadId, setLeadId] = useState("");
  const [selectedProductId, setSelectedProductId] = useState([]);
  const [selectedCampaignId, setSelectedCampaignId] = useState([]);
  const [selectedLeadIndex, setSelectedLeadIndex] = useState(0);
  const [showType, setShowType] = useState("");
  const [leadPageView, setLeadPageView] = useState("overview");
  const [timeLineDataList, setTimeLineDataList] = useState();
  const fileInputRef = useRef(null);

  const resetForm = () => {
    const formData = {
      firstName: "",
      lastName: "",
      title: "",
      phoneNumber: "",
      mobileNumber: "",
      emailId: "",
      fax: "",
      skypeId: "",
      secondaryEmailId: "",
      twitter: "",
      website: "",
      companyName: "",
      annualRevenue: "",
      noOfEmployees: "",
      rating: ratingOptions[0],
      industryType: industryOptions[0],
      leadSource: leadSourceOptions[0],
      leadStatus: leadStatusOptions[0],
      country: "",
      state: "",
      city: "",
      street: "",
      zipCode: "",
      leadImage: "",
      emailOptOut: emailOptOut,
    };

    setLeadInfo(formData);
    setLeadUpdateInfo(formData);
  };

  const userList = JSON.parse(sessionStorage.getItem("userList"));

  const [showInputs, setShowInputs] = useState(false);
  const [listVisible, setListVisible] = useState(false);
  const [selectedItem, setSelectedItem] = useState(
    userList?.find((item) => item.userId === loginUserId) || userList[0]
  );
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [selectedOwnerItem, setSelectedOwnerItem] = useState(
    userList.find((item) => item.userId === loginUserId) || userList[0]
  );
  const [totalItems, setTotalItems] = useState(0); // Total number of items
  const [editingFields, setEditingFields] = useState({
    emailId: false,
    phoneNumber: false,
    leadStatus: false,
    mobile: false,
    skypeId: false,
    leadOwner: false,
    leadInfoEmail: false,
    leadInfoCity: false,
    leadInfoCountry: false,
    leadInfoFax: false,
    leadInfoNoOfEmployees: false,
    leadInfoSecondaryEmailId: false,
    leadInfoSkypeId: false,
    leadInfoState: false,
    leadInfoStreet: false,
    leadInfotwitter: false,
    leadInfoWebsite: false,
    leadInfoZipCode: false,
    leadAnnualRevenue: false,
    leadSource: false,
    leadPhone: false,
    leadMobile: false,
    leadStatusInfo: false,
    firstName: false,
    lastName: false,
    title: false,
    fax: false,
    secondaryEmailId: false,
    twitter: false,
    website: false,
    companyName: false,
    annualRevenue: false,
    noOfEmployees: false,
    rating: false,
    industryType: false,
    country: false,
    state: false,
    city: false,
    street: false,
    zipCode: false,
    description: false,
    emailOptOut: false,
    leadInfoEmailOptOut: false,
  });
  // const [isLeadForm, setIsLeadForm] = useState(false); isLeadForm is not in use
  const [isLeadOwnerModal, setIsLeadOwnerModal] = useState(false);

  // for save and new && save lottie loader
  const [saveSubmit, setSaveSubmit] = useState(false);
  const [saveAndNewSubmit, setSaveAndNewSubmit] = useState(false);

  // lottie loader for other sections of the lead module
  const [apiLoader, setApiLoader] = useState(false);

  const [updatedFields, setUpdatedFields] = useState({
    emailId: leadOverviewDetails.emailId,
    phoneNumber: leadOverviewDetails.phoneNumber,
    mobile: leadOverviewDetails.mobile,
    leadStatus: leadOverviewDetails.leadStatus,
    emailOptOut: leadOverviewDetails.emailOptOut,
    leadInfoEmailOptOut: leadOverviewDetails.emailOptOut,
    skypeId: leadOverviewDetails.skypeId,
    leadOwner: leadOverviewDetails.leadOwner,
    leadOwnerInfo: leadOverviewDetails.leadOwner,
    leadInfoEmail: leadOverviewDetails.leadInfoEmail,
    leadInfoCity: leadOverviewDetails.city,
    leadInfoCountry: leadOverviewDetails.country,
    leadInfoFax: leadOverviewDetails.fax,
    leadInfoNoOfEmployees: leadOverviewDetails.noOfEmployees,
    leadInfoSecondaryEmailId: leadOverviewDetails.secondaryEmailId,
    leadInfoSkypeId: leadOverviewDetails.skypeId,
    leadInfoState: leadOverviewDetails.state,
    leadInfoStreet: leadOverviewDetails.street,
    leadInfotwitter: leadOverviewDetails.twitter,
    leadInfoWebsite: leadOverviewDetails.website,
    leadInfoZipCode: leadOverviewDetails.zipCode,
    leadAnnualRevenue: leadOverviewDetails.annualRevenue,
    leadInfoIndustryType: leadOverviewDetails.industryType,
    leadInfoLeadSource: leadOverviewDetails.leadSource,
    leadInfoRating: leadOverviewDetails.rating,
    leadInfoLeadStatus: leadOverviewDetails.leadStatus,
    leadPhone: leadOverviewDetails.leadPhone,
    leadStatusInfo: leadOverviewDetails.leadStatusInfo,
    leadMobile: leadOverviewDetails.leadMobile,
    leadSource: leadOverviewDetails.leadSource,
    firstName: leadOverviewDetails.firstName,
    lastName: leadOverviewDetails.lastName,
    title: leadOverviewDetails.title,
    fax: leadOverviewDetails.fax,
    secondaryEmailId: leadOverviewDetails.secondaryEmailId,
    twitter: leadOverviewDetails.twitter,
    website: leadOverviewDetails.website,
    companyName: leadOverviewDetails.companyName,
    annualRevenue: leadOverviewDetails.annualRevenue,
    noOfEmployees: leadOverviewDetails.noOfEmployees,
    rating: leadOverviewDetails.rating,
    industryType: leadOverviewDetails.industryType,
    country: leadOverviewDetails.country,
    state: leadOverviewDetails.state,
    city: leadOverviewDetails.city,
    street: leadOverviewDetails.street,
    zipCode: leadOverviewDetails.zipCode,
    description: leadOverviewDetails.description,
  });

  const [convertFormData, setConvertFormData] = useState({
    amount: "",
    dealName: "",
    closingDate: "",
    stage: stageOptions[0].value,
    source: "",
    role: roleOptions[0].value,
    probability: stages[stageOptions[0].value] || 10,
  });
  const [selectedCampaignItem, setSelectedCampaignItem] = useState({
    campaignName: "",
    campaignId: "",
  });
  const [parentListVisible, setParentListVisible] = useState(false);
  const [allList, setAllList] = useState([]);
  const [isCampaignListModal, setIsCampaignListModal] = useState(false);
  const [shareMemberIds, setShareMemberIds] = useState([]);
  const totalPages = Math.ceil(Number(totalItems) / perPage);

  const [totalItemsCampaign, setTotalItemsCampaign] = useState(0);
  const [currentPageCampaign, setCurrentPageCampaign] = useState(1);
  const [perPageCampaign, setPerPageCampaign] = useState(10);
  const [modalCampaignsDetails, setModalCampaignDetails] = useState([]);
  const [searchCampaign, setSearchCampaign] = useState("");

  const filteredCampigns = modalCampaignsDetails.filter((item) =>
    item.campaignName?.toLowerCase().includes(searchCampaign?.toLowerCase())
  );

  const handleSearchCampaign = (e) => {
    const { value } = e.target;
    if (value.trim() !== "" || value === "") {
      setSearchCampaign(value);
    }
  };

  const handlePageChangeCampaign = (page) => {
    setCurrentPageCampaign(page);
  };

  const handlePageDropDownChangeCampaign = (selectedOption) => {
    setCurrentPageCampaign(1);
    setPerPageCampaign(parseInt(selectedOption.value));
  };

  const totalPagesCampaign = Math.ceil(
    Number(totalItemsCampaign) / perPageCampaign
  );

  useEffect(() => {
    getAllCampaignDetails();
  }, [currentPageCampaign, perPageCampaign]);

  const handlePinColumn = (name) => {
    setPinnedColumn(name);
    const updatedColumns = [...selectedHeadings];
    const clickedColumnIndex = updatedColumns.findIndex(
      (column) => column.name === name
    );

    if (clickedColumnIndex !== -1) {
      const clickedColumn = updatedColumns.splice(clickedColumnIndex, 1)[0];
      updatedColumns.unshift(clickedColumn);
      setSelectedHeadings(updatedColumns);
    }
  };

  const sortedColumnsData = (type, headingName) => {
    const sortedData = [...leadDetails];

    if (type === "asc") {
      sortedData.sort((a, b) => {
        if (
          typeof a[headingName] === "string" &&
          typeof b[headingName] === "string"
        ) {
          return a[headingName].localeCompare(b[headingName]);
        }
        return a[headingName] - b[headingName];
      });
    } else if (type === "desc") {
      sortedData.sort((a, b) => {
        if (
          typeof a[headingName] === "string" &&
          typeof b[headingName] === "string"
        ) {
          return b[headingName].localeCompare(a[headingName]);
        }
        return b[headingName] - a[headingName];
      });
    }
    setFilteredLeadDetails(sortedData);
  };

  const getAllCampaignDetails = async () => {
    setIsLoading(true);
    // setSelectedOption(selectedOption);
    const requestBody = {
      loggedInUserId: loginUserId,
      filter: "all_campaigns",
      pageNo: currentPageCampaign,
      pageSize: perPageCampaign,
    };
    const headersResult = getHeaders(true);

    // Check if headers result indicates an error
    if (headersResult.error) {
      navigate("../"); // Redirect to login if token is missing
      return;
    }

    await axios
      .post(URI.getAllCampaigns, requestBody, {
        headers: headersResult,
      })
      .then((response) => {
        if (response.data.status === 200) {
          setAllList(response.data.data.getCampaignInformation);
          // if (!selectedCampaignName && !selectedCampaignId) {
          //   setSelectedCampaignItem(response.data.data.getCampaignInformation);
          // }
          // setFilteredLeadDetails(response.data.data.getCampaignInformation);
          // setTotalItems(response.data.data.p_row_count);
          setModalCampaignDetails(response.data.data.getCampaignInformation);
          setTotalItemsCampaign(response.data.data.p_row_count);
          setIsLoading(false);
        } else {
          setIsLoading(false);
        }
      })
      .catch(function (error) {
        handleApiError(error, navigate);
      });
  };

  const deleteUpdateTransferMass = async () => {
    const { value } = selectedObj;
    let url = URI.massDelete;
    const requestBody = {
      loggedInUserId: loginUserId,
      moduleName: "LEADS",
      massAction: showModalType === "Mass Delete" ? "DELETE" : "EDIT",
      moduleIdList: selectedCheckboxes,
    };

    if (showModalType === "Mass Update") {
      if (
        (value === "companyName" || value === "emailId") &&
        !massUpdateValue.trim()
      ) {
        setErrorMessage("Value cannot be empty");
        setTimeout(() => {
          setErrorMessage("");
        }, 3000);
        return;
      }

      // if (value === "annualRevenue") {
      //   const annualRevenueNumber = parseFloat(massUpdateValue);

      //   if (isNaN(annualRevenueNumber)) {
      //     setErrorMessage("Please enter a valid Annual Revenue.");
      //     setTimeout(() => {
      //       setErrorMessage("");
      //     }, 3000);
      //     return;
      //   }
      // }

      requestBody[value] = massUpdateValue;
      url = URI.massUpdate;
    } else if (showModalType === "Mass Transfer") {
      requestBody.ownerId = selectedItem.userId;
      url = URI.massTransfer;
    }

    const headersResult = getHeaders(true);

    // Check if headers result indicates an error
    if (headersResult.error) {
      navigate("../"); // Redirect to login if token is missing
      return;
    }
    await axios
      .post(url, requestBody, {
        headers: headersResult,
      })
      .then((response) => {
        if (response.data.status === 200) {
          NotificationManager.success("", response.data.message, 5000);
          getAllLeadsDetails(currentPage, selectedOption);
          setSelectedCheckboxes([]);
          setInnerPage("list");
          setShowModalType("");
          setShowSearchFields(fieldDropdowns);
          setMassUpdateValue("");
        } else {
          NotificationManager.error("", response.data.message, 5000);
        }
      })
      .catch(function (error) {
        handleApiError(error, navigate);
      });
  };

  const handleSubmitLead = async (e) => {
    let isValidForm = true;

    const setErrorMessage = (setter, message, timeout = 5000) => {
      setter(message);
      setTimeout(() => {
        setter("");
      }, timeout);
      isValidForm = false;
    };

    const leadData = existingLead || existingClone ? leadUpdateInfo : leadInfo;

    if (leadData.companyName === "") {
      setErrorMessage(
        setCompanyErrorMsg,
        "Company name cannot be empty.",
        4000
      );
    }
    if (leadData.lastName === "") {
      setErrorMessage(setLastNameErrorMsg, "Last name cannot be empty.", 4000);
    }
    if (leadData.companyName && !validateCompanyName(leadData.companyName)) {
      setErrorMessage(setCompanyErrorMsg, "Please enter a valid company name.");
    }

    if (leadData.firstName && !validateFirstName(leadData.firstName)) {
      setErrorMessage(setFirstNameErrorMsg, "Please enter a valid First name.");
    }

    if (leadData.lastName && !validateLastName(leadData.lastName)) {
      setErrorMessage(setLastNameErrorMsg, "Please enter a valid Last name.");
    }
    if (leadData.fax && !validateFaxNumber(leadData.fax)) {
      setErrorMessage(setFaxErrorMsg, "Please enter valid fax.");
    }

    if (!validateJobTitle(leadData.title) && leadData.title) {
      setErrorMessage(setTitleMsg, "Please enter a valid title.");
    }

    if (
      !validateAnnualRevenue(leadData.annualRevenue) &&
      leadData.annualRevenue
    ) {
      setErrorMessage(
        setAnnualRevenueMsg,
        "Please enter a valid Annual revenue."
      );
    }

    if (!validateSkypeId(leadData.skypeId) && leadData.skypeId) {
      setErrorMessage(setSkypeIdMsg, "Please enter a valid Skype Id.");
    }
    if (!validateTwitterUsername(leadData.twitter) && leadData.twitter) {
      setErrorMessage(setTwitterId, "Please enter a valid twitter Id.");
    }
    if (!validateCountryName(leadData.country) && leadData.country) {
      setErrorMessage(setCountryMsg, "Please enter a valid Country name.");
    }

    if (!validateStateIndia(leadData.state) && leadData.state) {
      setErrorMessage(setStateMsg, "Please enter a valid State name.");
    }

    if (!validateCityIndia(leadData.city) && leadData.city) {
      setErrorMessage(setCityMsg, "Please enter a valid City name.");
    }
    if (!websiteValidation(leadData.website) && leadData.website) {
      setErrorMessage(setWebsiteMsg, "Please enter a valid website name.");
    }

    if (leadData.emailId === "") {
      setErrorMessage(setEmailErrorMsg, "Email cannot be empty.", 5000);
    } else {
      const isValid = emailValidation(leadData.emailId);
      setValidEmail(isValid);
      if (!isValid) {
        setErrorMessage(
          setEmailErrorMsg,
          "Please enter a valid email address.",
          5000
        );
      }
    }

    if (!isValidForm) return;

    const formData = new FormData();
    let requestBody = {};
    e === "SAVE AND NEW" ? setSaveAndNewSubmit(true) : setSaveSubmit(true);

    if (existingClone) {
      requestBody = {
        leadOwnerId: selectedItem.userId,
        loggedInUserId: loginUserId,
        firstName: removeExtraSpaces(leadUpdateInfo.firstName),
        lastName: removeExtraSpaces(leadUpdateInfo.lastName),
        title: removeExtraSpaces(leadUpdateInfo.title),
        phoneNumber: leadUpdateInfo.phoneNumber,
        mobile: leadUpdateInfo.mobileNumber,
        emailId: removeExtraSpaces(leadUpdateInfo.emailId),
        fax: removeExtraSpaces(leadUpdateInfo.fax),
        skypeId: removeExtraSpaces(leadUpdateInfo.skypeId),
        secondaryEmailId: removeExtraSpaces(leadUpdateInfo.secondaryEmailId),
        twitter: removeExtraSpaces(leadUpdateInfo.twitter),
        website: removeExtraSpaces(leadUpdateInfo.website),
        companyName: removeExtraSpaces(leadUpdateInfo.companyName),
        industryType: leadUpdateInfo.industryType.value?.replace(/^-|-$/g, ""),
        annualRevenue: Number(leadUpdateInfo.annualRevenue),
        noOfEmployees: parseFloat(leadUpdateInfo.noOfEmployees),
        leadSource: leadUpdateInfo.leadSource.value?.replace(/^-|-$/g, ""),
        leadStatus: leadUpdateInfo.leadStatus.value?.replace(/^-|-$/g, ""),
        rating: leadUpdateInfo.rating.value?.replace(/^-|-$/g, ""),
        description: removeExtraSpaces(leadUpdateInfo.description),
        street: removeExtraSpaces(leadUpdateInfo.street),
        city: removeExtraSpaces(leadUpdateInfo.city),
        state: removeExtraSpaces(leadUpdateInfo.state),
        zipCode: removeExtraSpaces(leadUpdateInfo.zipCode),
        country: removeExtraSpaces(leadUpdateInfo.country),
        emailOptOut: leadUpdateInfo.emailOptOut,
      };
    } else {
      requestBody = {
        leadOwnerId: selectedItem.userId,
        loggedInUserId: loginUserId,
        firstName: removeExtraSpaces(leadInfo.firstName),
        lastName: removeExtraSpaces(leadInfo.lastName),
        title: removeExtraSpaces(leadInfo.title),
        phoneNumber: leadInfo.phoneNumber,
        mobile: leadInfo.mobileNumber,
        emailId: removeExtraSpaces(leadInfo.emailId),
        fax: removeExtraSpaces(leadInfo.fax),
        skypeId: removeExtraSpaces(leadInfo.skypeId),
        secondaryEmailId: removeExtraSpaces(leadInfo.secondaryEmailId),
        twitter: removeExtraSpaces(leadInfo.twitter),
        website: removeExtraSpaces(leadInfo.website),
        companyName: removeExtraSpaces(leadInfo.companyName),
        industryType: leadInfo.industryType.value?.replace(/^-|-$/g, ""),
        annualRevenue: Number(leadInfo.annualRevenue),
        noOfEmployees: parseFloat(leadInfo.noOfEmployees),
        leadSource: leadInfo.leadSource.value?.replace(/^-|-$/g, ""),
        leadStatus: leadInfo?.leadStatus?.value?.replace(/^-|-$/g, ""),
        rating: leadInfo.rating.value?.replace(/^-|-$/g, ""),
        description: removeExtraSpaces(leadInfo.description),
        street: removeExtraSpaces(leadInfo.street),
        city: removeExtraSpaces(leadInfo.city),
        state: removeExtraSpaces(leadInfo.state),
        zipCode: removeExtraSpaces(leadInfo.zipCode),
        country: removeExtraSpaces(leadInfo.country),
        emailOptOut: emailOptOut === true ? "true" : "false",
        productId:
          pastViewInfo?.view !== "" && fetchProdInfo
            ? fetchProdInfo?.productId
            : null, // it will pass the productId when we are routing from other page
      };
    }

    formData.append("leadDtlsInputs", JSON.stringify(requestBody));
    formData.append(
      "leadImage",
      existingClone ? leadUpdateInfo.leadImage : leadInfo.leadImage
    );

    const headersResult = getHeaders(true);

    // Check if headers result indicates an error
    if (headersResult.error) {
      navigate("../"); // Redirect to login if token is missing
      return;
    }

    await axios
      .post(URI.addLeadDetails, formData, {
        headers: headersResult,
      })
      .then((response) => {
        if (response.data.status === 200) {
          NotificationManager.success("", response.data.message, 5000);
          getAllLeadsDetails(1, selectedOption);
          resetForm();
          setSelectedFiles(null);
          fileInputRef.current.value = "";
          setSelectedItem(
            userList.find((item) => item.userId === loginUserId) || userList[0]
          );
          if (e === "SAVE AND NEW") {
            setInnerPage("create");
          } else {
            setInnerPage("list");
          }
        } else {
          NotificationManager.error("", response.data.message, 5000);
        }
      })
      .catch(function (error) {
        handleApiError(error, navigate);
      })
      .finally(() => {
        setSaveAndNewSubmit(false);
        setSaveSubmit(false);
      });
  };

  const removeImage = async () => {
    setImageLoader(true);
    const requestBody = {
      loggedInUserId: loginUserId,
      leadId: leadId,
    };

    const headersResult = getHeaders(true);

    // Check if headers result indicates an error
    if (headersResult.error) {
      navigate("../"); // Redirect to login if token is missing
      return;
    }

    await axios
      .post(URI.removeLeadImage, requestBody, {
        headers: headersResult,
      })

      .then((response) => {
        if (response.data.status === 200) {
          NotificationManager.success("", response.data.message, 5000);
          setTimeout(() => {
            setShowImageModal(false);
            setShowRemoveModal(false);
            leadOverviewDetail(leadId);
          }, 3000);
        } else {
          NotificationManager.error("", response.data.message, 5000);
        }
      })
      .catch(async (error) => {
        handleApiError(error, navigate);
      })
      .finally(() => setImageLoader(false));
  };

  const uploadImage = async () => {
    setImageLoader(true);
    const formData = new FormData();
    const requestBody = {
      loggedInUserId: loginUserId,
      leadId: leadId,
      firstName: leadOverviewDetails.firstName,
      lastName: leadOverviewDetails.lastName,
      phoneNumber: leadOverviewDetails.phoneNumber,
      emailId: leadOverviewDetails.emailId,
      companyName: leadOverviewDetails.companyName,
    };
    formData.append("leadDtlsInputs", JSON.stringify(requestBody));
    formData.append(
      "leadImage",
      uploadImg ? selectedFiles : leadOverviewDetails.leadImageData
    );

    const headersResult = getHeaders(true);

    // Check if headers result indicates an error
    if (headersResult.error) {
      navigate("../"); // Redirect to login if token is missing
      return;
    }
    await axios
      .post(URI.individualUpdateLeads, formData, {
        headers: headersResult,
      })
      .then((response) => {
        if (response.data.status === 200) {
          leadOverviewDetail(leadId);
          setShowImageModal(false);
        } else {
          NotificationManager.error("", response.data.message, 5000);
        }
      })
      .catch(async (error) => {
        handleApiError(error, navigate);
      })
      .finally(() => setImageLoader(false));
  };

  const getNotesDetails = async (leadId, orderType) => {
    const requestBody = {
      loggedInUserId: loginUserId,
      moduleId: leadId,
      orderType: orderType,
    };

    const headersResult = getHeaders(true);

    // Check if headers result indicates an error
    if (headersResult.error) {
      navigate("../"); // Redirect to login if token is missing
      return;
    }
    await axios
      .post(URI.getNote, requestBody, {
        headers: headersResult,
      })
      .then((response) => {
        if (response.data.status === 200) {
          setNoteInfo(response.data.data.noteInfo);
        } else {
          NotificationManager.error("", response.data.message, 5000);
        }
      })
      .catch(async (error) => {
        handleApiError(error, navigate);
      });
  };

  const deleteNoteDetails = async (leadId, noteId) => {
    const formData = new FormData();
    formData.append("moduleId", leadId);
    formData.append("loggedInUserId", loginUserId);
    formData.append("moduleName", "LEADS");
    formData.append("noteId", noteId);

    const headersResult = getHeaders(true);

    // Check if headers result indicates an error
    if (headersResult.error) {
      navigate("../"); // Redirect to login if token is missing
      return;
    }

    await axios
      .post(URI.deleteNote, formData, {
        headers: headersResult,
      })
      .then((response) => {
        if (response.data.status === 200) {
          NotificationManager.success("", response.data.message, 3000);
          getNotesDetails(leadId, selectRecent);
          setNoteDeleteConfirmation(false);
        } else {
          NotificationManager.error("", response.data.message, 5000);
        }
      })
      .catch((error) => {
        handleApiError(error, navigate);
      });
  };

  const insertNote = async () => {
    const formData = new FormData();
    formData.append("moduleId", leadId);
    formData.append("loggedInUserId", loginUserId);
    formData.append("moduleName", "LEADS");
    formData.append("noteDescription", note);
    formData.append("noteTitle", addTitle);

    selectedNoteFiles.forEach((file) => {
      formData.append("noteImages", file);
    });

    if (note.length > 1) {
      const headersResult = getHeaders(true);

      // Check if headers result indicates an error
      if (headersResult.error) {
        navigate("../"); // Redirect to login if token is missing
        return;
      }
      await axios
        .post(URI.insertNote, formData, {
          headers: headersResult,
        })
        .then((response) => {
          if (response.data.status === 200) {
            setShowButtons(false);
            setAddTitle("");
            setNote("");
            setSelectedNoteFiles([]);
            // getNotesDetails(newNoteId,leadId);
            // Pass newNoteId to fetchNoteImage
            getNotesDetails(leadId, selectRecent);
          } else {
            NotificationManager.error("", response.data.message, 5000);
          }
        })
        .catch((error) => {
          handleApiError(error, navigate);
        });
    } else {
      NotificationManager.error("Empty", "Note can not be empty.", 5000);
    }
  };

  const updateNote = async (noteId) => {
    const formData = new FormData();
    formData.append("moduleId", leadId);
    formData.append("loggedInUserId", loginUserId);
    formData.append("moduleName", "LEADS");
    formData.append("noteDescription", updateNotes.description);
    formData.append("noteTitle", updateNotes.title);
    formData.append("noteId", noteId);
    formData.append("deletedImageNames", deletedImg.toString());

    selectedNoteFiles.forEach((file) => {
      formData.append("noteImages", file);
    });

    if (updateNotes.description.length > 1) {
      const headersResult = getHeaders(true);

      // Check if headers result indicates an error
      if (headersResult.error) {
        navigate("../"); // Redirect to login if token is missing
        return;
      }
      await axios
        .post(URI.updateNote, formData, {
          headers: headersResult,
        })
        .then((response) => {
          if (response.data.status === 200) {
            setShowButtons(false);
            setEditNote(false);
            NotificationManager.success("", response.data.message, 5000);
            setUpdateNotes({
              description: "",
              title: "",
              noteImageList: [],
            });
            setSelectedNoteFiles([]);
            getNotesDetails(leadId, selectRecent);
            setDeletedImg("");
          } else {
            setDeletedImg("");
            setSelectedNoteFiles([]);
            NotificationManager.error("", response.data.message, 5000);
          }
        })
        .catch((error) => {
          handleApiError(error, navigate);
        });
    } else {
      NotificationManager.error("Empty", "Note can not be empty.", 5000);
    }
  };

  const handleUpdateLead = async (e) => {
    let isValidForm = true;

    const setErrorMessage = (setter, message, timeout = 5000) => {
      setter(message);
      setTimeout(() => {
        setter("");
      }, timeout);
      isValidForm = false;
    };

    const leadData = existingLead || existingClone ? leadUpdateInfo : leadInfo;

    if (leadData.companyName === "") {
      setErrorMessage(
        setCompanyErrorMsg,
        "Company name cannot be empty.",
        4000
      );
    }
    if (leadData.lastName === "") {
      setErrorMessage(setLastNameErrorMsg, "Last name cannot be empty.", 4000);
    }
    if (leadData.companyName && !validateCompanyName(leadData.companyName)) {
      setErrorMessage(setCompanyErrorMsg, "Please enter a valid company name.");
    }

    if (leadData.firstName && !validateFirstName(leadData.firstName)) {
      setErrorMessage(setFirstNameErrorMsg, "Please enter a valid First name.");
    }

    if (leadData.lastName && !validateLastName(leadData.lastName)) {
      setErrorMessage(setLastNameErrorMsg, "Please enter a valid Last name.");
    }
    if (leadData.fax && !validateFaxNumber(leadData.fax)) {
      setErrorMessage(setFaxErrorMsg, "Please enter valid fax.");
    }

    if (!validateJobTitle(leadData.title) && leadData.title) {
      setErrorMessage(setTitleMsg, "Please enter a valid title.");
    }

    if (
      leadData.annualRevenue &&
      !validateAnnualRevenue(leadData.annualRevenue.toString())
    ) {
      setErrorMessage(
        setAnnualRevenueMsg,
        "Please enter a valid Annual revenue."
      );
    }

    if (!validateSkypeId(leadData.skypeId) && leadData.skypeId) {
      setErrorMessage(setSkypeIdMsg, "Please enter a valid Skype Id.");
    }
    if (!validateTwitterUsername(leadData.twitter) && leadData.twitter) {
      setErrorMessage(setTwitterId, "Please enter a valid twitter Id.");
    }
    if (!validateCountryName(leadData.country) && leadData.country) {
      setErrorMessage(setCountryMsg, "Please enter a valid Country name.");
    }

    if (!validateStateIndia(leadData.state) && leadData.state) {
      setErrorMessage(setStateMsg, "Please enter a valid State name.");
    }

    if (!validateCityIndia(leadData.city) && leadData.city) {
      setErrorMessage(setCityMsg, "Please enter a valid City name.");
    }
    if (!websiteValidation(leadData.website) && leadData.website) {
      setErrorMessage(setWebsiteMsg, "Please enter a valid website name.");
    }

    if (leadData.emailId === "") {
      setErrorMessage(setEmailErrorMsg, "Email cannot be empty.", 5000);
    } else {
      const isValid = emailValidation(leadData.emailId);
      setValidEmail(isValid);
      if (!isValid) {
        setErrorMessage(
          setEmailErrorMsg,
          "Please enter a valid email address.",
          5000
        );
      }
    }

    if (!isValidForm) return;

    const formData = new FormData();
    setSaveSubmit(true);
    const requestBody = {
      leadId: leadId,
      leadOwnerId: selectedItem.userId,
      loggedInUserId: loginUserId,
      firstName: removeExtraSpaces(leadUpdateInfo.firstName),
      lastName: removeExtraSpaces(leadUpdateInfo.lastName),
      title: removeExtraSpaces(leadUpdateInfo.title),
      phoneNumber: leadUpdateInfo.phoneNumber,
      mobile: leadUpdateInfo.mobileNumber,
      emailId: removeExtraSpaces(leadUpdateInfo.emailId),
      fax: removeExtraSpaces(leadUpdateInfo.fax),
      skypeId: removeExtraSpaces(leadUpdateInfo.skypeId),
      secondaryEmailId: removeExtraSpaces(leadUpdateInfo.secondaryEmailId),
      twitter: removeExtraSpaces(leadUpdateInfo.twitter),
      website: removeExtraSpaces(leadUpdateInfo.website),
      companyName: removeExtraSpaces(leadUpdateInfo.companyName),
      industryType: leadUpdateInfo.industryType.value?.replace(/^-|-$/g, ""),
      annualRevenue: Number(leadUpdateInfo.annualRevenue),
      noOfEmployees: parseFloat(leadUpdateInfo.noOfEmployees),
      leadSource: leadUpdateInfo.leadSource.value?.replace(/^-|-$/g, ""),
      leadStatus: leadUpdateInfo.leadStatus.value?.replace(/^-|-$/g, ""),
      rating: leadUpdateInfo.rating.value?.replace(/^-|-$/g, ""),
      description: removeExtraSpaces(leadUpdateInfo.description),
      street: removeExtraSpaces(leadUpdateInfo.street),
      city: removeExtraSpaces(leadUpdateInfo.city),
      state: removeExtraSpaces(leadUpdateInfo.state),
      zipCode: removeExtraSpaces(leadUpdateInfo.zipCode),
      country: removeExtraSpaces(leadUpdateInfo.country),
      emailOptOut: emailOptOut === true ? "true" : "false",
      emailNotification: emailNotification,
    };

    formData.append("leadDtlsInputs", JSON.stringify(requestBody));
    formData.append("leadImage", leadUpdateInfo.leadImage);

    const headersResult = getHeaders(true);

    // Check if headers result indicates an error
    if (headersResult.error) {
      navigate("../"); // Redirect to login if token is missing
      return;
    }

    await axios
      .post(URI.updateLeadDetails, formData, {
        headers: headersResult,
      })
      .then((response) => {
        if (response.data.status === 200) {
          NotificationManager.success("", response.data.message, 5000);
          setExistingLead(false);
          if (e === "SAVE") {
            setInnerPage("list");
          }
          resetForm();
          getAllLeadsDetails(1, selectedOption);
        } else {
          NotificationManager.error("", response.data.message, 5000);
        }
      })
      .catch(function (error) {
        handleApiError(error, navigate);
      })
      .finally(() => {
        setSaveSubmit(false);
      });
  };

  const handleAssignModule = async (selectedProductId) => {
    const moduleIdList = selectedProductId.map((id) => ({ moduleId: id }));
    if (moduleIdList.length < 1) {
      return NotificationManager.error("", "Please select product", 5000);
    }

    const requestBody = {
      loginUserId: loginUserId,
      moduleName: "LEADS",
      moduleId: leadId,
      assignModuleName: "PRODUCTS",
      moduleIdList,
    };

    setApiLoader(true);

    try {
      const headersResult = getHeaders(true);

      // Check if headers result indicates an error
      if (headersResult.error) {
        navigate("../"); // Redirect to login if token is missing
        return;
      }

      const response = await axios.post(URI.assignRole, requestBody, {
        headers: headersResult,
      });
      if (response.data.status === 200) {
        NotificationManager.success("", response.data.message, 5000);
        leadOverviewDetail(leadId);
      } else {
        NotificationManager.error("", response.data.message, 5000);
      }
    } catch (error) {
      handleApiError(error, navigate);
    } finally {
      setApiLoader(false);
    }
  };

  const handleAssignModuleCampaigns = async (selectedCampaignId) => {
    const moduleIdList = selectedCampaignId.map((id) => ({ moduleId: id }));

    if (moduleIdList.length < 1) {
      return NotificationManager.error("", "Please select an item", 5000);
    }

    const requestBody = {
      loginUserId: loginUserId,
      moduleName: "LEADS",
      moduleId: leadId,
      assignModuleName: "CAMPAIGNS",
      moduleIdList,
    };

    setApiLoader(true);

    try {
      const headersResult = getHeaders(true);

      // Check if headers result indicates an error
      if (headersResult.error) {
        navigate("../"); // Redirect to login if token is missing
        return;
      }
      const response = await axios.post(URI.assignRole, requestBody, {
        headers: headersResult,
      });
      if (response.data.status === 200) {
        NotificationManager.success("", response.data.message, 5000);
        leadOverviewDetail(leadId);
      } else {
        NotificationManager.error("", response.data.message, 5000);
      }
    } catch (error) {
      handleApiError(error, navigate);
    } finally {
      setApiLoader(false);
    }
  };

  const handledeleteModule = async (deleteProdId) => {
    const requestBody = {
      loginUserId: loginUserId,
      moduleName: "LEADS",
      moduleId: leadId,
      assignModuleName: "PRODUCTS",
      assignModuleId: deleteProdId,
    };

    try {
      const headersResult = getHeaders(true);

      // Check if headers result indicates an error
      if (headersResult.error) {
        navigate("../"); // Redirect to login if token is missing
        return;
      }
      const response = await axios.post(URI.deleteRole, requestBody, {
        headers: headersResult,
      });
      if (response.data.status === 200) {
        NotificationManager.success("", response.data.message, 5000);
        leadOverviewDetail(leadId);
      } else {
        NotificationManager.error("", response.data.message, 5000);
      }
    } catch (error) {
      handleApiError(error, navigate);
    }
  };

  const handledeleteModuleCampaigns = async (deleteCampId) => {
    const requestBody = {
      loginUserId: loginUserId,
      moduleName: "LEADS",
      moduleId: leadId,
      assignModuleName: "CAMPAIGNS",
      assignModuleId: deleteCampId,
    };

    try {
      const headersResult = getHeaders(true);

      // Check if headers result indicates an error
      if (headersResult.error) {
        navigate("../"); // Redirect to login if token is missing
        return;
      }
      const response = await axios.post(URI.deleteRole, requestBody, {
        headers: headersResult,
      });
      if (response.data.status === 200) {
        NotificationManager.success("", response.data.message, 5000);
        leadOverviewDetail(leadId);
      } else {
        NotificationManager.error("", response.data.message, 5000);
      }
    } catch (error) {
      handleApiError(error, navigate);
    }
  };

  const getAllLeadsDetails = async (pageNo, selectedOption) => {
    setIsLoading(true);
    setSelectedOption(selectedOption);
    const requestBody = {
      loggedInUserId: loginUserId,
      customFilter: selectedOption.value,
      pageNo: pageNo,
      pageSize: perPage,
    };
    const headersResult = getHeaders(true);

    // Check if headers result indicates an error
    if (headersResult.error) {
      navigate("../"); // Redirect to login if token is missing
      return;
    }

    await axios
      .post(URI.getAllLeads, requestBody, {
        headers: headersResult,
      })
      .then((response) => {
        if (response.data.status === 200) {
          setLeadDetails(response.data.data.allLeadDetails);
          setFilteredLeadDetails(response.data.data.allLeadDetails);
          setTotalItems(response.data.data.rowCount);
          setIsLoading(false);
        } else {
          setIsLoading(false);
        }
      })
      .catch(async (error) => {
        handleApiError(error, navigate);
      });
  };

  const getAllTimeleadLeads = async (leadId) => {
    const headersResult = getHeaders(true);

    // Check if headers result indicates an error
    if (headersResult.error) {
      navigate("../"); // Redirect to login if token is missing
      return;
    }
    await axios
      .get(URI.getLeadTimeline + "/" + loginUserId + "/" + leadId, {
        headers: headersResult,
      })

      .then((response) => {
        if (response.data.status === 200) {
          setTimeLineDataList(response.data.data.getLeadDetails);
        }
      })
      .catch(async (error) => {
        handleApiError(error, navigate);
      });
  };

  const getAllInfoShare = async (leadId) => {
    setIsLoading(true);
    const requestBody = {
      moduleId: leadId,
      loggedInUserId: loginUserId,
    };

    const headersResult = getHeaders(true);

    // Check if headers result indicates an error
    if (headersResult.error) {
      navigate("../"); // Redirect to login if token is missing
      return;
    }
    await axios
      .post(URI.getShareInfo, requestBody, {
        headers: headersResult,
      })
      .then((response) => {
        if (response.data.status === 200) {
          const hasSharedItems =
            (response.data.data.getSharingInfoUsers &&
              response.data.data.getSharingInfoUsers.length > 0) ||
            (response.data.data.getSharingInfoRoles &&
              response.data.data.getSharingInfoRoles.length > 0) ||
            (response.data.data.getSharingInfoGroups &&
              response.data.data.getSharingInfoGroups.length > 0) ||
              (response.data.data.getSharingInfoPublic &&
                response.data.data.getSharingInfoPublic.length > 0);

          const userShareMemberIds = response.data.data.getSharingInfoUsers.map(
            (user) => user.shareModuleId
          );
          const roleShareMemberIds = response.data.data.getSharingInfoRoles.map(
            (role) => role.shareModuleId
          );
          const groupShareMemberIds =
            response.data.data.getSharingInfoGroups.map(
              (group) => group.shareModuleId
            );
            const groupPublicSharedMemberIds =
            response.data.data.getSharingInfoPublic.map(
              (user) => user.shareModuleId
            )
          setShareMemberIds([
            ...userShareMemberIds,
            ...roleShareMemberIds,
            ...groupShareMemberIds,
            ...groupPublicSharedMemberIds
          ]);

          setShareUserList(response.data.data.getSharingInfoUsers);
          setShareRoleList(response.data.data.getSharingInfoRoles);
          setShareGroupList(response.data.data.getSharingInfoGroups);
          setSharePublicList(response.data.data.getSharingInfoPublic);
          if (hasSharedItems) {
            setShareInfoModal(true);
          } else {
            setShareModal(true);
          }
          setIsLoading(false);
        }
      })
      .catch((err) => {
        handleApiError(err, navigate);
      });
  };

  const leadOverviewDetail = async (leadID) => {
    let leadOverviewDetails = {};
    const headersResult = getHeaders(true);

    // Check if headers result indicates an error
    if (headersResult.error) {
      navigate("../"); // Redirect to login if token is missing
      return;
    }
    await axios
      .get(URI.leadOverview + "/" + loginUserId + "/" + leadID, {
        headers: headersResult,
      })

      .then((response) => {
        if (response.data.status === 200) {
          leadOverviewDetails = response.data.data.leadOverviewDetails;
          if (response.data.data.leadOverviewDetails.isLeadConverted) {
            setLeadOverviewDetails(leadOverviewDetails);
          } else {
            leadOverviewDetails.leadAnnualRevenue =
              response.data.data.leadOverviewDetails.annualRevenue;
            leadOverviewDetails.leadInfoIndustryType =
              response.data.data.leadOverviewDetails.industryType;
            leadOverviewDetails.leadInfoLeadSource =
              response.data.data.leadOverviewDetails.leadSource;
            leadOverviewDetails.leadInfoRating =
              response.data.data.leadOverviewDetails.rating;
            leadOverviewDetails.leadInfoLeadStatus =
              response.data.data.leadOverviewDetails.leadStatus;
            leadOverviewDetails.leadInfoEmail =
              response.data.data.leadOverviewDetails.emailId;
            leadOverviewDetails.leadInfoCity =
              response.data.data.leadOverviewDetails.city;
            leadOverviewDetails.leadInfoCountry =
              response.data.data.leadOverviewDetails.country;
            leadOverviewDetails.leadInfoFax =
              response.data.data.leadOverviewDetails.fax;
            leadOverviewDetails.leadInfoNoOfEmployees =
              response.data.data.leadOverviewDetails.noOfEmployees;
            leadOverviewDetails.leadInfoSecondaryEmailId =
              response.data.data.leadOverviewDetails.secondaryEmailId;
            leadOverviewDetails.leadInfoSkypeId =
              response.data.data.leadOverviewDetails.skypeId;
            leadOverviewDetails.leadInfoState =
              response.data.data.leadOverviewDetails.state;
            leadOverviewDetails.leadInfoStreet =
              response.data.data.leadOverviewDetails.street;
            leadOverviewDetails.leadInfotwitter =
              response.data.data.leadOverviewDetails.twitter;
            leadOverviewDetails.leadInfoWebsite =
              response.data.data.leadOverviewDetails.website;
            leadOverviewDetails.leadInfoZipCode =
              response.data.data.leadOverviewDetails.zipCode;
            leadOverviewDetails.leadPhone =
              response.data.data.leadOverviewDetails.phoneNumber;
            leadOverviewDetails.leadStatusInfo =
              response.data.data.leadOverviewDetails.leadStatus;
            leadOverviewDetails.leadMobile =
              response.data.data.leadOverviewDetails.mobile;
            leadOverviewDetails.mobileNumber =
              response.data.data.leadOverviewDetails.mobile;
            leadOverviewDetails.leadOwnerInfo =
              response.data.data.leadOverviewDetails.leadOwner;
            leadOverviewDetails.LeadImage =
              response.data.data.leadOverviewDetails.contactImageData;
            leadOverviewDetails.industryType = {
              label: response.data.data.leadOverviewDetails.industryType,
              value: response.data.data.leadOverviewDetails.industryType,
            };
            leadOverviewDetails.leadInfoIndustryType = {
              label: response.data.data.leadOverviewDetails.industryType,
              value: response.data.data.leadOverviewDetails.industryType,
            };
            leadOverviewDetails.leadInfoLeadSource = {
              label: response.data.data.leadOverviewDetails.leadSource,
              value: response.data.data.leadOverviewDetails.leadSource,
            };
            leadOverviewDetails.leadInfoRating = {
              label: response.data.data.leadOverviewDetails.rating,
              value: response.data.data.leadOverviewDetails.rating,
            };
            leadOverviewDetails.leadInfoLeadStatus = {
              label: response.data.data.leadOverviewDetails.leadStatus,
              value: response.data.data.leadOverviewDetails.leadStatus,
            };
            leadOverviewDetails.rating = {
              label: response.data.data.leadOverviewDetails.rating,
              value: response.data.data.leadOverviewDetails.rating,
            };
            leadOverviewDetails.leadSource = {
              label: response.data.data.leadOverviewDetails.leadSource,
              value: response.data.data.leadOverviewDetails.leadSource,
            };

            leadOverviewDetails.leadStatus = {
              label: response.data.data.leadOverviewDetails.leadStatus,
              value: response.data.data.leadOverviewDetails.leadStatus,
            };
            // setClickedDropdownObj(leadOverviewDetails.rating);
            setUpdatedFields((prevFields) => ({
              ...prevFields,
              rating: leadOverviewDetails.rating,
              leadStatus: leadOverviewDetails.leadStatus,
              emailOptOut: response.data.data.leadOverviewDetails.emailOptOut,
              leadInfoEmailOptOut:
                response.data.data.leadOverviewDetails.emailOptOut,
              industryType: leadOverviewDetails.industryType,
              leadSource: leadOverviewDetails.leadSource,
            }));
            setLeadOverviewDetails(leadOverviewDetails);
            setEmailOptOut(response.data.data.leadOverviewDetails.emailOptOut);
            setProductsList(response.data.data.productsList);
            setCampaignList(response.data.data.campaignsAssignedDtls);
            setOpenActivities(response.data.data.openActivitiesList);
            setOpenActivitiesCalls(response.data.data.openActivitiesCalls);
            setInvitedMeetings(response.data.data.getInvitedMeetings);
            setOpenActivitiesMeetings(
              response.data.data.openActivitiesMeetings
            );
            setCloseActivities(response.data.data.closedActivitiesList);
            setCloseActivitiesCalls(response.data.data.closedActivitiesCalls);
            setCloseActivitiesMeetings(
              response.data.data.closedActivitiesMeetings
            );
            const updatedFields = {
              emailId: response.data.data.leadOverviewDetails.emailId,
              phoneNumber: response.data.data.leadOverviewDetails.phoneNumber,
              mobile: response.data.data.leadOverviewDetails.mobile,
              leadStatus: response.data.data.leadOverviewDetails.leadStatus,
              skypeId: response.data.data.leadOverviewDetails.skypeId,
              leadOwner: response.data.data.leadOverviewDetails.leadOwner,
              leadOwnerInfo: response.data.data.leadOverviewDetails.leadOwner,
              leadAnnualRevenue:
                response.data.data.leadOverviewDetails.leadAnnualRevenue,
              leadInfoIndustryType:
                response.data.data.leadOverviewDetails.industryType,
              leadInfoLeadSource:
                response.data.data.leadOverviewDetails.leadSource,
              leadInfoRating: response.data.data.leadOverviewDetails.rating,
              leadInfoLeadStatus:
                response.data.data.leadOverviewDetails.leadStatus,
              leadInfoEmail: response.data.data.leadOverviewDetails.emailId,
              leadInfoCity: response.data.data.leadOverviewDetails.city,
              leadInfoCountry: response.data.data.leadOverviewDetails.country,
              leadInfoFax: response.data.data.leadOverviewDetails.fax,
              leadInfoNoOfEmployees:
                response.data.data.leadOverviewDetails.noOfEmployees,
              leadInfoSecondaryEmailId:
                response.data.data.leadOverviewDetails.secondaryEmailId,
              leadInfoSkypeId: response.data.data.leadOverviewDetails.skypeId,
              leadInfoState: response.data.data.leadOverviewDetails.state,
              leadInfoStreet: response.data.data.leadOverviewDetails.street,
              leadInfotwitter: response.data.data.leadOverviewDetails.twitter,
              leadInfoWebsite: response.data.data.leadOverviewDetails.website,
              leadInfoZipCode: response.data.data.leadOverviewDetails.zipCode,
              leadPhone: response.data.data.leadOverviewDetails.phoneNumber,
              leadStatusInfo: response.data.data.leadOverviewDetails.leadStatus,
              leadMobile: response.data.data.leadOverviewDetails.mobile,
              leadSource: response.data.data.leadOverviewDetails.leadSource,
              firstName: response.data.data.leadOverviewDetails.firstName,
              lastName: response.data.data.leadOverviewDetails.lastName,
              title: response.data.data.leadOverviewDetails.title,
              fax: response.data.data.leadOverviewDetails.fax,
              secondaryEmailId:
                response.data.data.leadOverviewDetails.secondaryEmailId,
              twitter: response.data.data.leadOverviewDetails.twitter,
              website: response.data.data.leadOverviewDetails.website,
              companyName: response.data.data.leadOverviewDetails.companyName,
              annualRevenue:
                response.data.data.leadOverviewDetails.annualRevenue,
              noOfEmployees:
                response.data.data.leadOverviewDetails.noOfEmployees,
              rating: response.data.data.leadOverviewDetails.rating,
              industryType: response.data.data.leadOverviewDetails.industryType,
              country: response.data.data.leadOverviewDetails.country,
              state: response.data.data.leadOverviewDetails.state,
              city: response.data.data.leadOverviewDetails.city,
              street: response.data.data.leadOverviewDetails.street,
              zipCode: response.data.data.leadOverviewDetails.zipCode,
              description: response.data.data.leadOverviewDetails.description,
              leadImage:
                response.data.data.leadOverviewDetails.contactImageData,
            };
            setSelectedItem({
              name: response.data.data.leadOverviewDetails.leadOwner,
              userId: response.data.data.leadOverviewDetails.leadOwnerId,
            });
            setUpdatedFields(updatedFields);
            // fetchNoteImage(response.data.data.leadNotesDetailsList.noteId, leadID);
          }
        }
      })
      .catch(async (error) => {
        handleApiError(error, navigate);
      });
  };

  const handleChangeIndDropDown = (selectedOption, field) => {
    setUpdatedFields((prevFields) => ({
      ...prevFields,
      [field]: selectedOption,
    }));
  };

  const handleChangeSelectDropDown = (selectedOption, field) => {
    if (existingLead || existingClone) {
      setLeadUpdateInfo((prevLeadInfo) => ({
        ...prevLeadInfo,
        [field]: selectedOption,
      }));
    } else {
      setLeadInfo((prevLeadInfo) => ({
        ...prevLeadInfo,
        [field]: selectedOption,
      }));
    }
  };

  const handleConvertSelectDropDown = (selectedOption, field) => {
    setConvertFormData((prevLeadInfo) => ({
      ...prevLeadInfo,
      [field]: selectedOption.value,
    }));
  };

  const handleDragOver = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setIsDragging(true);
  };

  const handleDragLeave = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setIsDragging(false);
  };

  const handleDrop = (event) => {
    const maxSizeInBytes = 2 * 1024 * 1024; // 2 MB in bytes

    event.preventDefault();
    event.stopPropagation();
    setIsDragging(false);
    const file = event.dataTransfer.files[0];

    if (file) {
      if (!allowedFileTypes.includes(file.type)) {
        setImageMsg(
          "Invalid file type. Please upload an image file (JPG, PNG, JPEG)."
        );
        setTimeout(() => setImageMsg(""), 3000);
        return;
      }
      if (file.size > maxSizeInBytes) {
        setImageMsg("File size exceeds 2 MB. Please select a smaller file.");
        setTimeout(() => setImageMsg(""), 3000);
        return;
      }
      setSelectedFiles(file);
      fileInputRef.current.files = event.dataTransfer.files;
      if (existingLead || existingClone) {
        setLeadUpdateInfo((prevLeadInfo) => ({
          ...prevLeadInfo,
          leadImage: file,
        }));
      } else {
        setLeadInfo((prevLeadInfo) => ({
          ...prevLeadInfo,
          leadImage: file,
        }));
      }
    }
  };

  const handleLeadImageChange = (event) => {
    const selectedFile = event.target.files[0];

    if (selectedFile) {
      if (!allowedFileTypes.includes(selectedFile.type)) {
        setImageMsg(
          "Invalid file type. Please upload an image file (JPG, PNG, JPEG)."
        );
        setTimeout(() => setImageMsg(""), 3000);
        return;
      }
      if (selectedFile.size > maxSizeInBytes) {
        setImageMsg("File size exceeds 2 MB. Please select a smaller file.");
        setTimeout(() => setImageMsg(""), 3000);
        return;
      }
      setSelectedFiles(selectedFile);
      if (existingLead || existingClone) {
        setLeadUpdateInfo((prevLeadInfo) => ({
          ...prevLeadInfo,
          leadImage: selectedFile,
        }));
      } else {
        setLeadInfo((prevLeadInfo) => ({
          ...prevLeadInfo,
          leadImage: selectedFile,
        }));
      }
    }
  };

  const calculateResult = (field) => {
    const value = (
      existingLead || existingClone ? leadUpdateInfo[field] : leadInfo[field]
    ).trim();

    const sanitizedValue = value;
    // eslint-disable-next-line no-useless-escape
    const regex = /^[\d.\+\-\*\/\(\)]+$/;

    if (regex.test(sanitizedValue)) {
      try {
        const cleanedValue = removeLeadingZeros(sanitizedValue);
        let result = eval(cleanedValue);
        if (typeof result === "number" && result % 1 !== 0) {
          const decimalPart = result.toString().split(".")[1];
          if (decimalPart && decimalPart.length > 2) {
            result = parseFloat(result).toFixed(2);
          }
        }
        if (existingLead || existingClone) {
          setLeadUpdateInfo((prevData) => ({
            ...prevData,
            [field]: result.toString(),
          }));
        } else {
          setLeadInfo((prevData) => ({
            ...prevData,
            [field]: result.toString(),
          }));
        }
      } catch (error) {
        if (existingLead || existingClone) {
          setLeadUpdateInfo((prevData) => ({
            ...prevData,
            [field]: sanitizeExpression(value),
          }));
        } else {
          setLeadInfo((prevData) => ({
            ...prevData,
            [field]: sanitizeExpression(value),
          }));
        }
      }
    }
  };

  /* eslint-disable no-useless-escape */
  const sanitizeExpression = (expression) => {
    const match = expression.match(/^\s*-?\d+(\.\d+)?([+\-*\/]-?\d+(\.\d+)?)*/);

    if (match) {
      return match[0].trim();
    } else {
      return "Invalid input";
    }
  };
  /* eslint-disable no-useless-escape */
  const handleKeyPress = (event) => {
    const allowedCharacters = /[0-9.\+\-\*\/\(\)]/;
    const char = event.key;
    if (!allowedCharacters.test(char)) {
      event.preventDefault();
    }
  };
  /* eslint-disable no-useless-escape */

  const handleTooltip = (value, setShowTooltip) => {
    const regex = /^[\d.\+\-\*\/\(\)]+$/;
    if (regex.test(value)) {
      try {
        const sanitizedValue = removeLeadingZeros(value);
        const previewResult = eval(sanitizedValue);
        setTooltipContent(previewResult.toString());
      } catch (error) {
        setTooltipContent(sanitizeExpression(value));
      }
      setShowTooltip(true);
    } else {
      setShowTooltip(false);
    }
  };

  const handleChangeLead = (e) => {
    const { name, value } = e.target;

    if (existingLead || existingClone) {
      setLeadUpdateInfo((prevLeadInfo) => ({
        ...prevLeadInfo,
        [name]: value,
      }));
    } else {
      setLeadInfo((prevLeadInfo) => ({
        ...prevLeadInfo,
        [name]: value,
      }));
    }
    switch (name) {
      case "annualRevenue":
        handleTooltip(value, setShowTooltipAnnualRevenue);
        break;

      default:
        break;
    }
  };
  const hideAllTooltips = () => {
    setShowTooltipAnnualRevenue(false);
  };

  const tooltipStyles = {
    position: "absolute",
    top: "100%",
    left: "0",
    backgroundColor: "#f9f9f9",
    border: "1px solid #d3d3d3",
    padding: "5px",
    borderRadius: "3px",
    boxShadow: "0 2px 5px rgba(0,0,0,0.2)",
    zIndex: 1000,
    height: "fit-content",
    width: "fit-content",
  };

  const handleBlur = (field) => {
    calculateResult(field);
    hideAllTooltips();
  };

  const handleChangeNoteTitle = (e) => {
    const { name, value } = e.target;
    if (value.length === 1 && value[0] === ' ') {
      return; 
    }
    if (editNote) {
      setUpdateNotes((prevLeadInfo) => ({
        ...prevLeadInfo,
        [name]: value,
      }));
    } else {
      setAddTitle(value);
    }
  };

  const handleChildDataChange = (dataFromChild) => {
    const deletedImg = dataFromChild.toString("");
    setDeletedImg(deletedImg);
  };

  const handleChangeNoteDescription = (e) => {
    const { name, value } = e.target;
    if (value.length === 1 && value[0] === ' ') {
      return; 
    }
    if (editNote) {
      setUpdateNotes((prevLeadInfo) => ({
        ...prevLeadInfo,
        [name]: value,
      }));
    } else {
      setNote(value); // Directly set the input value
    }
  };

  const handleConvertChange = (e) => {
    const { name, value } = e.target;
    setConvertFormData((prevState) => ({
      ...prevState,
      [name]:
        name === "amount"
          ? value
              .replace(/[^0-9.]/g, "") // Allow only numbers and dots
              .replace(/(\..*)\./g, "$1") // Prevent multiple dots
              .replace(/(\.\d{2})\d+/, "$1") // Restrict to two decimal places
          : value,
    }));
  };

  const createLeadStyles = {
    menu: (base) => ({
      ...base,
      width: "60%",
    }),
    option: (provided) => ({
      ...provided,
      // backgroundColor: "#EAEAEA",
      color: "#000",
      // backgroundColor: 'rgba(33, 119, 254, 0.1)',
      width: "100%",
    }),
    control: (baseStyles) => ({
      ...baseStyles,
      border: "1px solid #b9b9b9b0",
      backgroundColor: "transparent",
      height: "40px",
      borderRadius: "4px",
      width: "60%",
      boxShadow: "none",
    }),
    input: (base) => ({
      ...base,
      color: "#000",
    }),
    singleValue: (defaultStyles) => ({ ...defaultStyles, color: "#000" }),

    menuList: (provided) => ({
      ...provided,
      paddingTop: 0,
      paddingBottom: 0,
      "&::-webkit-scrollbar": {
        width: 5,
      },

      "&::-webkit-scrollbar-thumb": {
        background: "#316AFF",
        borderRadius: "10px",
      },
    }),
  };

  const fieldDropdownStyles = {
    menu: (base) => ({
      ...base,
      width: "100%",
    }),
    option: (provided) => ({
      ...provided,
      // backgroundColor: "#EAEAEA",
      color: "#000",
      // backgroundColor: 'rgba(33, 119, 254, 0.1)',
      width: "100%",
    }),
    control: (baseStyles) => ({
      ...baseStyles,
      border: 0,
      // backgroundColor: 'rgba(33, 119, 254, 0.1)',
      height: "20px",
      borderRadius: "10px",
      width: "100%",
      boxShadow: "none",
    }),
    input: (base) => ({
      ...base,
      color: "#FFFFFF",
    }),
    singleValue: (defaultStyles) => ({ ...defaultStyles, color: "#000" }),

    menuList: (provided) => ({
      ...provided,
      paddingTop: 0,
      paddingBottom: 0,
      "&::-webkit-scrollbar": {
        width: 5,
      },

      "&::-webkit-scrollbar-thumb": {
        background: "#316AFF",
        borderRadius: "10px",
      },
    }),
  };

  const handleChange = (option) => {
    if (selectedOption.value !== option.value) {
      setCurrentPage(1);
      getAllLeadsDetails(1, option);
    }
  };

  const handleIndividualInputChange = (field, value) => {
    if (
      field === "leadPhone" ||
      field === "leadMobile" ||
      field === "leadInfoFax" ||
      field === "leadAnnualRevenue"
    ) {
      const numericValue = value.replace(/[^0-9]/g, "").slice(0, 10);
      setUpdatedFields((prevFields) => ({
        ...prevFields,
        [field]: numericValue,
      }));
    } else if (
      field === "leadInfoNoOfEmployees" ||
      field === "leadInfoZipCode"
    ) {
      const numericValue = value.replace(/[^0-9]/g, "").slice(0, 6);
      setUpdatedFields((prevFields) => ({
        ...prevFields,
        [field]: numericValue,
      }));
    } else {
      setUpdatedFields((prevFields) => ({
        ...prevFields,
        [field]: value,
      }));
    }
  };

  const handleEditClick = (field) => {
    if (Object.values(editingFields).some((value) => value)) {
      return;
    }
    setEditingFields((prevFields) => ({
      ...prevFields,
      [field]: true,
    }));
  };

  const handleCancelClick = (field) => {
    setEditingFields((prevFields) => ({
      ...prevFields,
      [field]: false,
    }));
    setUpdatedFields((prevFields) => ({
      ...prevFields,
      [field]: leadOverviewDetails[field],
    }));
  };

  const EditableCheckbox = ({
    type,
    checked,
    handleChange,
    handleEditClick,
    handleUpdateClick,
    showType,
    setShowType,
  }) => {
    return (
      <div className="d-flex editInput_container">
        {![type].includes(showType) ? (
          <span
            className="right_tick cursorPointer"
            onClick={() => {
              handleEditClick(type);
              setShowType(type);
            }}
          >
            {checked ? tick : "-"}
          </span>
        ) : (
          <input
            type="checkbox"
            // checked={updatedFields.emailOptOut}
            checked={checked}
            // onChange={(e) => {
            //   setUpdatedFields((prevFields) => ({
            //     ...prevFields,
            //     emailOptOut: e.target.checked,
            //   }));
            // }}
            // onClick={() => {
            //   handleEditClick("emailOptOut");
            // }}

            onChange={handleChange}
            onClick={() => {
              if (showType === "") {
                setShowType(type);
              }
              handleEditClick(type);
            }}
            readOnly={!editingFields[type]}
          />
        )}
        {showType === type && (
          <div className="d-flex gap-2">
            <span
              onClick={() => {
                setShowType("");
                handleUpdateClick(type);
              }}
              className="ps-2 cursorPointer"
            >
              <img src={updateIcon} alt="Update" height={20} width={20} />
            </span>
            <span
              onClick={() => {
                setShowType("");
                handleCancelClick(type);
              }}
              className="cursorPointer"
            >
              <img src={cancelIcon} alt="Cancel" height={20} width={20} />
            </span>
          </div>
        )}
      </div>
    );
  };

  const handleUpdateClick = async (field) => {
    var value;
    var requestBody = {};
    const isObj =
      typeof leadOverviewDetails[field] === "object" &&
      leadOverviewDetails[field] !== null &&
      !Array.isArray(leadOverviewDetails[field]);
    if (
      field === "industryType" ||
      field === "leadInfoIndustryType" ||
      field === "leadInfoLeadSource" ||
      field === "leadInfoLeadStatus" ||
      field === "rating" ||
      field === "leadSource" ||
      field === "leadStatus" ||
      field === "leadInfoRating"
    ) {
      value = updatedFields[field].value;
    } else if (field === "emailOptOut") {
      value = updatedFields[field] ? "true" : "false";
      // value = leadOverviewDetails[field] ? "true" : "false";
    } else if (field === "leadInfoEmailOptOut") {
      value = updatedFields.leadInfoEmailOptOut ? "true" : "false";
    } else {
      value = ["annualRevenue", "noOfEmployees"].includes(field)
        ? parseFloat(updatedFields[field])
        : updatedFields[field];
    }
    const fieldMappings = {
      leadInfoEmail: "emailId",
      leadOwnerInfo: "leadOwner",
      leadPhone: "phoneNumber",
      leadMobile: "mobile",
      leadStatusInfo: "leadStatus",
      leadAnnualRevenue: "annualRevenue",
      leadInfoIndustryType: "industryType",
      leadInfoLeadSource: "leadSource",
      leadInfoRating: "rating",
      leadInfoLeadStatus: "leadStatus",
      leadInfoCity: "city",
      leadInfoCountry: "country",
      leadInfoFax: "fax",
      leadInfoNoOfEmployees: "noOfEmployees",
      leadInfoSecondaryEmailId: "secondaryEmailId",
      leadInfoSkypeId: "skypeId",
      leadInfoState: "state",
      leadInfoStreet: "street",
      leadInfotwitter: "twitter",
      leadInfoWebsite: "website",
      leadInfoZipCode: "zipCode",
      leadInfoEmailOptOut: "emailOptOut",
      // emailNotification: "emailNotification",
    };
    // if (field !== "leadOwnerInfo") {
    // if (fieldMappings.hasOwnProperty(field)) {
    //   field = fieldMappings[field];
    // }
    // } not supported by eslint
    if (Object.hasOwn(fieldMappings, field)) {
      field = fieldMappings[field];
    }
    field === "emailNotification" && setApiLoader(true);

    try {
      const formData = new FormData();
      if (field === "emailNotification") {
        requestBody = {
          // userId: loginUserId,
          leadId: leadId,
          loggedInUserId: loginUserId,
          leadOwnerId: isOwnerSelect
            ? selectedOwnerItem?.userId
            : selectedItem?.userId,
          emailNotification: emailNotification,
        };
      } else {
        requestBody = {
          // userId: loginUserId,
          leadId: leadId,
          loggedInUserId: loginUserId,
          // leadOwnerId: isOwnerSelect
          //   ? selectedOwnerItem?.userId
          //   : selectedItem?.userId,
          [field]: value,
        };
      }
      const compareValue = isObj
        ? leadOverviewDetails[field].value
        : leadOverviewDetails[field];
      if (value !== compareValue || field === "emailNotification") {
        formData.append("leadDtlsInputs", JSON.stringify(requestBody));
        const headersResult = getHeaders(true);

        // Check if headers result indicates an error
        if (headersResult.error) {
          navigate("../"); // Redirect to login if token is missing
          return;
        }
        const response = await axios.post(URI.individualUpdateLeads, formData, {
          headers: headersResult,
        });
        if (response.data.status === 200) {
          setLeadOverviewDetails((prevDetails) => ({
            ...prevDetails,
            [field]: updatedFields[field],
          }));

          leadOverviewDetail(leadId);
          setIsOwnerSelect(false);
          setShowModalType("");
          NotificationManager.success("", response.data.message, 5000);
        } else {
          NotificationManager.error("", response.data.message, 5000);
        }
      }
      const resetFields = Object.fromEntries(
        Object.keys(editingFields).map((key) => [key, false])
      );
      setEditingFields({
        ...resetFields,
      });
      setShowType("");
    } catch (error) {
      handleApiError(error, navigate);
    } finally {
      setApiLoader(false);
    }
  };

  const handleCheckboxChange = () => {
    setShowInputs(!showInputs);
  };

  const handleConvertSubmit = async () => {
    let missingFields = false;

    if (convertFormData.amount === "") {
      setAmtErrorMsg("Amount cannot be empty.");
      setTimeout(() => {
        setAmtErrorMsg("");
      }, 4000);
      missingFields = showInputs;
    }
    if (convertFormData.closingDate === "") {
      setDateErrorMsg("Date cannot be empty.");
      setTimeout(() => {
        setDateErrorMsg("");
      }, 4000);
      missingFields = showInputs;
    }

    if (convertFormData.probability === "") {
      setProbabilityErrorMsg("Date cannot be empty.");
      setTimeout(() => {
        setProbabilityErrorMsg("");
      }, 4000);
      missingFields = showInputs;
    }
    if (convertFormData.dealName === "") {
      setDealErrorMsg("Deal name cannot be empty.");
      setTimeout(() => {
        setDealErrorMsg("");
      }, 4000);
      missingFields = showInputs;
    }

    if (missingFields) {
      return;
    } else {
      setApiLoader(true);
      var requestBody = {};
      requestBody = {
        loggedInUserId: loginUserId,
        leadId: leadId,
        ownerId: selectedItem.userId,
        dealOption: showInputs ? 1 : 0,
        dealName: convertFormData.dealName,
        amount: Number(convertFormData.amount),
        closingDate: convertFormData.closingDate
          ?.split("-")
          .reverse()
          .join("-"),
        stage: convertFormData.stage,
        contactRole: convertFormData.role,
        probability: convertFormData.probability,
      };
      if (selectedCampaignItem.campaignId !== "") {
        requestBody.campaign = selectedCampaignItem?.campaignId;
      }

      const headersResult = getHeaders(true);

      // Check if headers result indicates an error
      if (headersResult.error) {
        navigate("../"); // Redirect to login if token is missing
        return;
      }
      await axios
        .post(URI.convertLeadDetails, requestBody, {
          headers: headersResult,
        })

        .then((response) => {
          if (response.data.status === 200) {
            NotificationManager.success("", response.data.message, 5000);
            setConvertFormData({
              amount: "",
              dealName: "",
              closingDate: "",
              stage: stageOptions[0].value,
              source: "",
              role: roleOptions[0].value,
            });
            getAllLeadsDetails(currentPage, selectedOption);
            setInnerPage("list");
            // resetForm();
          } else {
            NotificationManager.error("", response.data.message, 5000);
          }
        })
        .catch(function (error) {
          handleApiError(error, navigate);
        })
        .finally(() => {
          setApiLoader(false);
        });
    }
  };

  const handleOwnerInputClick = (e) => {
    e.stopPropagation();
    setListVisible(!listVisible);
  };

  const handleChangeOwnerInputClick = (e) => {
    e.stopPropagation();
    setIsChangeOwnerList(!isChangeOwnerList);

    // setInvidualListVisible(!individualListVisible);
  };

  const handleSelectedChangeOwner = (value) => {
    setSelectedItem(value);
  };

  const handleChangeOwnerItemClick = (item) => {
    setSelectedOwnerItem(item);
    setIsChangeOwnerList(false);
    setIsOwnerSelect(true);
    // setIsSelectedOwner(false);
  };

  const handleUserItemClick = (item) => {
    setSelectedItem(item);
    setListVisible(false);
  };

  const handleSelectedLeadChange = (value) => {
    setSelectedItem(value); // Update the selected lead value
    setSelectedOwnerItem(value);
  };
  const hasPermission = (action) => {
    const permission = permissionList.leads.find((p) => p.action === action);
    return permission ? permission.status === "TRUE" : false;
  };

  const handleCreateLead = () => {
    if (hasPermission("CREATE")) {
      setEmailOptOut(false);
      setInnerPage("create");
      setExistingLead(false);
      setExistingClone(false);
      setUpdateButtonLabel("Save");
      setSelectedCheckboxes([]);
    } else {
      setShowAccessModal(true);
    }
  };

  const handleCreateLeadClone = () => {
    if (hasPermission("CREATE")) {
      if (leadOverviewDetails.userPermission === "Read Only") {
        setShowAccessModal(true);
        return;
      }
      setInnerPage("create");
      setShowOptionsLead(false);
      setExistingClone(true);
      setExistingLead(false);
      setLeadUpdateInfo({ ...leadOverviewDetails });
      setUpdateButtonLabel("Save");
    } else {
      setShowAccessModal(true);
    }
  };

  const handleEditLead = () => {
    if (hasPermission("EDIT")) {
      if (leadOverviewDetails.userPermission === "Read Only") {
        setShowAccessModal(true);
        return;
      }
  setExistingLead(true);
      setUpdateButtonLabel("Update");
      setInnerPage("create");
      setLeadUpdateInfo({ ...leadOverviewDetails });
    } else {
      setShowAccessModal(true);
    }
  };
  

  const handleDeleteLead = () => {
    if (hasPermission("DELETE")) {
      if (leadOverviewDetails.userPermission === "Read Only" ||leadOverviewDetails.userPermission === "Read Write") {
        setShowAccessModal(true);
        return;
      }
      setShowModalType("delete");
    } else {
      setShowAccessModal(true);
    }
  };

  const nodataPage = () => {
    return (
      <div className="d-flex flex-column justify-content-center align-items-center  ">
        <img src={leadNoteData} alt="img" height={200} width={200} />
        <h5 className="opacity-75">Manage Lead</h5>
        <p className="opacity-75 p-0 m-0">
          Quotes are legal agreements between a customer and a vector
        </p>
        <p className="opacity-75 p-0 m-0">
          to deliver the requested product(s) at the agreed upon price
        </p>
        <p className="opacity-75 p-0 m-0">within the specified time period.</p>
        <button
          className="createContactButtonSave px-2 mt-4 cursorPointer rounded-3"
          style={{ width: "15%" }}
          onClick={() => setInnerPage("create")}
        >
          Create a Lead
        </button>
      </div>
    );
  };

  const leadHomePage = () => {
    return (
      <>
        <div>
          {isLoading ? (
            <>
              <Loader />
            </>
          ) : filteredLeadDetails &&
            filteredLeadDetails.length === 0 &&
            selectedOption.value === "all_leads" ? (
            <>{nodataPage()}</>
          ) : (
            <>
              <div className="creteLeadBar">
                {selectedCheckboxes.length > 0 ? (
                  <div className="d-flex w-50 align-items-center gap-2">
                    <Select
                      options={massOptions}
                      styles={customCommonStyles("100%")}
                      value={{
                        value: "Action",
                        label: "Action",
                      }}
                      onChange={(option) => {
                        if (option.value === "Mass Update") {
                          setShowSearchFields(leadsColumn);
                          setSelectedObj({
                            name: "Annual Revenue",
                            isChecked: false,
                            value: "annualRevenue",
                          });
                        } else if (option.value === "Mass Transfer") {
                          setSelectedItem(
                            userList.find(
                              (item) => item.userId === loginUserId
                            ) || userList[0]
                          );
                        }
                        setMassUpdateValue("");
                        setShowModalType(option.value);
                      }}
                      className="w-25"
                    />
                  </div>
                ) : (
                  <>
                    <div className="d-flex w-50 align-items-center gap-2">
                      {/* <img
                        src={filterView ? filterIcon : filterIconCross}
                        height={25}
                        className="leadFilter"
                        alt="img"
                      /> */}
                      <Select
                        options={options}
                        styles={customCommonStyles("210px")}
                        value={selectedOption}
                        onChange={handleChange}
                        isSearchable={false}
                      />
                    </div>
                    <div className="d-flex w-50 justify-content-end gap-2">
                      <div className="btn-group">
                        <button
                          className="createContactButtonSave py-2"
                          onClick={handleCreateLead}
                        >
                          Create Lead
                        </button>
                        {/* <button
                          type="button"
                          className="btn btn-sm dropdown-toggle dropdown-toggle-split"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                          style={{
                            background:
                              "linear-gradient(86.07deg, #407BFF 0%, #12A7FE 100%)",
                            color: "#fff",
                          }}
                        >
                          <span className="visually-hidden"></span>
                        </button> */}
                        {/* <ul className="dropdown-menu">
                          <li>
                            <a className="dropdown-item ">Import Leads</a>
                          </li>
                          <li>
                            <a className="dropdown-item ">Import Notes</a>
                          </li>
                        </ul> */}
                      </div>
                      {/* <Select
                    options={massOptions}
                    styles={customSelectLocationStyles}
                    value={dropdownTwo}
                    onChange={(option) => {
                      setDropdownTwo(option);
                    }}
                  /> */}
                    </div>
                  </>
                )}
              </div>
              <div className="totalRecordBar">
                <div className="d-flex w-50">
                  <h6>
                    {selectedCheckboxes.length > 0
                      ? `${selectedCheckboxes.length} Records Selected`
                      : `Total Records : ${totalItems}`}
                  </h6>
                </div>
                <div className="d-flex w-50 align-items-center justify-content-end gap-2">
                  {/* {selectedCheckboxes.length === 0 && (
                    <Select
                      options={pageOption}
                      styles={customSelectLocationStyles}
                      value={pageOption.find(
                        (option) => option.value === perPage.toString()
                      )}
                      onChange={handlePageDropDowmChange}
                    />
                  )} */}

                  {selectedCheckboxes.length === 0 ? (
                    filteredLeadDetails.length !== 0 && (
                      <Select
                        options={pageOption}
                        styles={customCommonStyles("100%")}
                        // value={selectPageOption}
                        value={pageOption.find(
                          (option) => option.value === perPage.toString()
                        )}
                        onChange={handlePageDropDowmChange}
                        isSearchable={false}
                      />
                    )
                  ) : (
                    <></>
                  )}

                  {filteredLeadDetails.length !== 0 && (
                    <Pagination
                      currentPage={currentPage}
                      totalPages={totalPages}
                      onPageChange={(page) => setCurrentPage(page)}
                    />
                  )}
                </div>
              </div>

              <div className="leadMain">
                {/* {filterView ? (
                  <div className="filterLead">
                    <h6 style={{ borderBottom: "2px solid #316AFF" }}>
                      Filter by Lead
                    </h6>
                    <input
                      type="text"
                      placeholder="Search"
                      className="filterSearch"
                    />
                    <div className="checkboxItem">
                      <h6 style={{ color: "#515151" }}>
                        ▾ System Defined Filters
                      </h6>
                      <input
                        type="checkbox"
                        id="vehicle1"
                        name="vehicle1"
                        value="Bike"
                      />
                      <label htmlFor="vehicle1"> Touched Record</label>
                      <br />
                      <input
                        type="checkbox"
                        id="vehicle1"
                        name="vehicle1"
                        value="Bike"
                      />
                      <label htmlFor="vehicle1"> Untouched Record</label>
                      <br />
                      <input
                        type="checkbox"
                        id="vehicle1"
                        name="vehicle1"
                        value="Bike"
                      />
                      <label htmlFor="vehicle1"> Record Action</label>
                      <br />
                      <h6 style={{ color: "#515151", marginTop: "10px" }}>
                        ▾ Filter By Fields
                      </h6>
                      <input
                        type="checkbox"
                        id="vehicle1"
                        name="vehicle1"
                        value="Bike"
                      />
                      <label htmlFor="vehicle1"> Annual Revenue</label>
                      <br />
                      <input
                        type="checkbox"
                        id="vehicle1"
                        name="vehicle1"
                        value="Bike"
                      />
                      <label htmlFor="vehicle1"> City</label>
                      <br />
                      <input
                        type="checkbox"
                        id="vehicle1"
                        name="vehicle1"
                        value="Bike"
                      />
                      <label htmlFor="vehicle1"> Company</label>
                      <br />
                    </div>
                  </div>
                ) : (
                  <></>
                )} */}
                <div
                  className={
                    filterIcon ? "leadListTableFullWidth" : "leadListTable"
                  }
                >
                  <table className="table leadTable">
                    <thead>
                      <tr>
                        <th scope="col">
                          <input
                            className="cursorPointer"
                            type="checkbox"
                            id="all"
                            name="all"
                            value="All"
                            checked={
                              // selectedCheckboxes.length ===
                              //   filteredLeadDetails.length &&
                              // filteredLeadDetails.length > 0
                              filteredLeadDetails.every((selected) =>
                                selectedCheckboxes.some(
                                  (list) => list.moduleId === selected.leadId
                                )
                              )
                            }
                            onChange={(e) => {
                              const allList = filteredLeadDetails.map(
                                (item) => ({
                                  moduleId: item.leadId,
                                })
                              );

                              if (e.target.checked) {
                                const newSelections = allList.filter(
                                  (newItem) =>
                                    !selectedCheckboxes.some(
                                      (existingItem) =>
                                        existingItem.moduleId ===
                                        newItem.moduleId
                                    )
                                );
                                setSelectedCheckboxes((prev) => [
                                  ...prev,
                                  ...newSelections,
                                ]);
                              } else {
                                setSelectedCheckboxes(
                                  selectedCheckboxes.filter(
                                    (aItem) =>
                                      !allList.some(
                                        (bItem) =>
                                          aItem.moduleId === bItem.moduleId
                                      )
                                  )
                                );
                              }
                            }}
                          />
                        </th>
                        {selectedHeadings.map(
                          (heading) =>
                            heading.isChecked && (
                              <th scope="col" key={heading.name}>
                                <span className="pe-3">{heading.name}</span>
                                <span className="position-relative">
                                  <img
                                    src={tableFilterIcon}
                                    alt=""
                                    className="cursorPointer"
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      setShowHeadings(false);
                                      setShowSelectedFilter(heading.name);
                                    }}
                                  />
                                  {heading.name === showSelectedFilter && (
                                    <span
                                      className="position-absolute rounded-2 border bg-light top-0 mt-4"
                                      style={{ right: "-10%" }}
                                    >
                                      <span className="d-flex flex-column px-1">
                                        <span
                                          className="my-1 px-1 cursorPointer fw-normal text-start filter_type"
                                          onClick={() => {
                                            if (filterType === "asc") {
                                              setFilterType("");
                                              setFilteredLeadDetails(
                                                leadDetails
                                              );
                                            } else {
                                              setFilterType("asc");
                                              sortedColumnsData(
                                                "asc",
                                                heading.value
                                              );
                                            }
                                          }}
                                        >
                                          {filterType === "asc" ? (
                                            <img
                                              src={cancel}
                                              alt="asc"
                                              className="opacity-75 pe-1"
                                            />
                                          ) : (
                                            <img
                                              src={arrowUp}
                                              alt="asc"
                                              className="opacity-75 pe-1"
                                            />
                                          )}
                                          Asc
                                        </span>
                                        <span
                                          className="my-2 px-1 cursorPointer fw-normal text-start filter_type"
                                          onClick={() => {
                                            if (filterType === "desc") {
                                              setFilterType("");
                                              setFilteredLeadDetails(
                                                leadDetails
                                              );
                                            } else {
                                              setFilterType("desc");
                                              sortedColumnsData(
                                                "desc",
                                                heading.value
                                              );
                                            }
                                          }}
                                        >
                                          {filterType === "desc" ? (
                                            <img
                                              src={cancel}
                                              alt="desc"
                                              className="opacity-75 pe-1"
                                            />
                                          ) : (
                                            <img
                                              src={arrowDown}
                                              alt="desc"
                                              className="opacity-75 pe-1"
                                            />
                                          )}
                                          Desc
                                        </span>
                                        <span
                                          // className={`my-1 px-1 fw-normal text-start filter_type cursorPointer ${
                                          //   heading.name === "Lead Name"
                                          //     ? "opacity-50"
                                          //     : ""
                                          // }`}
                                          className={`my-1 px-1 fw-normal text-start filter_type ${
                                            selectedHeadings[0].name ===
                                              "Lead Name" &&
                                            heading.name === "Lead Name"
                                              ? "opacity-50"
                                              : "cursorPointer"
                                          }`}
                                          onClick={() => {
                                            if (pinnedColumn !== "Lead Name") {
                                              setPinnedColumn("Lead Name");
                                              handlePinColumn("Lead Name");
                                            } else {
                                              setPinnedColumn(heading.name);
                                              handlePinColumn(heading.name);
                                            }
                                          }}
                                        >
                                          <img
                                            src={
                                              pinnedColumn === heading.name
                                                ? unPin
                                                : pin
                                            }
                                            alt="pin"
                                            className="opacity-75 pe-1"
                                          />
                                          {pinnedColumn === heading.name
                                            ? "Unpin"
                                            : "Pin"}{" "}
                                          Column
                                        </span>
                                      </span>
                                    </span>
                                  )}
                                  <img
                                    src={filterLine}
                                    alt=""
                                    className="ps-2"
                                  />
                                </span>
                              </th>
                            )
                        )}
                        <th
                          scope="col"
                          className="text-end cursorPointer position-relative"
                        >
                          <img
                            src={filterMenu}
                            alt=""
                            className={`filter_menu ${
                              showHeadings ? "bg-light" : ""
                            }`}
                            onClick={(e) => {
                              e.stopPropagation();
                              setShowSelectedFilter("");
                              setShowHeadings(true);
                            }}
                          />
                          {showHeadings && (
                            <div
                              className="position-absolute rounded-2 border border-primary bg-light"
                              style={{
                                right: "0.2rem",
                              }}
                            >
                              <div className="d-flex flex-column px-2">
                                <span
                                  className="p-2 cursorPointer fw-normal text-start"
                                  onClick={() => {
                                    setSearchColumn("");
                                    setSavingHeadings(selectedHeadings);
                                    setShowModalType("Manage Columns");
                                  }}
                                >
                                  Manage Columns
                                </span>
                                <span
                                  className="p-2 fw-normal text-start opacity-50"
                                  onClick={() => {
                                    setShowModalType("Reset Column Size");
                                  }}
                                >
                                  Reset Column Size
                                </span>
                              </div>
                            </div>
                          )}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {filteredLeadDetails && filteredLeadDetails.length > 0 ? (
                        filteredLeadDetails.map((item, i) => {
                          return (
                            <tr key={i} className="">
                              <td>
                                <input
                                  className="cursorPointer"
                                  type="checkbox"
                                  id="vehicle1"
                                  // name={item.vendorId}
                                  value={item.leadId}
                                  // checked={selectedCheckboxes.includes(
                                  //   item.leadId
                                  // )}
                                  checked={selectedCheckboxes.some(
                                    (checkbox) =>
                                      checkbox.moduleId === item.leadId
                                  )}
                                  onChange={handleSelectCheckboxChange}
                                />
                              </td>
                              <React.Fragment>
                                {selectedHeadings.map((obj, i) => {
                                  const value = obj.value;
                                  const itemValue = item[value];
                                  return obj.isChecked ? (
                                    value === "transHash" ? (
                                      <td
                                        style={{ wordBreak: "break-all" }}
                                        className="overflow_text_hash"
                                        key={i}
                                      >
                                        <a
                                          rel="noreferrer"
                                          href={
                                            EXPLORER_URL +
                                            "about-tx-hash/" +
                                            item.transHash
                                          }
                                          className="anchorLinkHash textColor"
                                          data-title={item.transHash}
                                          target="_blank"
                                        >
                                          {item.transHash
                                            ? item.transHash.slice(0, 10) +
                                              "...."
                                            : "-"}
                                        </a>
                                      </td>
                                    ) : (
                                      <td
                                        key={i}
                                        onClick={() => {
                                          if (value === "leadName") {
                                            setInnerPage("overview");
                                            leadOverviewDetail(item.leadId);
                                            setLeadPageView("overview");
                                            getNotesDetails(
                                              item.leadId,
                                              selectRecent
                                            );
                                            setLeadId(item.leadId);
                                            setSelectedCheckboxes([]);
                                            setSelectedLead(item);
                                            setSelectedLeadIndex(
                                              leadDetails.findIndex(
                                                (contact) =>
                                                  contact.leadId === item.leadId
                                              )
                                            );
                                          }
                                        }}
                                        className={`overflow_text ${
                                          value === "leadName"
                                            ? "leadName cursorPointer"
                                            : ""
                                        }`}
                                        style={{ maxWidth: "3vw" }}
                                      >
                                        {itemValue !== null ? itemValue : "-"}
                                      </td>
                                    )
                                  ) : null;
                                })}
                              </React.Fragment>
                              <td></td>
                            </tr>
                          );
                        })
                      ) : (
                        <tr>
                          <td
                            colSpan={selectedHeadings.length + 2}
                            style={{ textAlign: "center" }}
                          >
                            No Data
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </>
          )}
        </div>
      </>
    );
  };

  const createLeadPage = () => {
    return (
      <>
        <div className="createLeadButtonBar">
          <div className="d-flex align-items-center w-50 gap-2">
            <img src={groupIcon} height={25} alt="img" />
            <div className="d-flex flex-column">
              <p className="mb-0">
                {existingLead ? "Edit" : existingClone ? "Clone" : "Create"}{" "}
                Lead
              </p>
            </div>
          </div>
          <div className="d-flex w-50 gap-2 justify-content-end">
            <button
              className="createContactButton"
              onClick={() => {
                if (pastViewInfo) {
                  setCurrentView(pastViewInfo.view);
                  setInnerPage(pastViewInfo.innerPage);
                  setPastViewInfo((prev) => ({
                    ...prev,
                    view: null,
                    innerPage: null,
                    undo: currentView,
                  }));
                  return;
                }
                if (existingLead) {
                  setInnerPage("overview");
                } else {
                  setInnerPage("list");
                }
                if (innerPage==="create" && existingClone){
                  setInnerPage("overview");
                }

                resetForm();
                if (fileInputRef.current) {
                  fileInputRef.current.value = "";
                  setSelectedFiles(null);
                }
                setSelectedFiles(null);
              }}
            >
              Cancel
            </button>
            {existingLead === false && (
              <button
                className="createContactButton"
                onClick={() => {
                  handleSubmitLead("SAVE AND NEW");
                }}
                disabled={saveAndNewSubmit}
              >
                {saveAndNewSubmit ? (
                  <Lottie
                    options={defaultOptions("blue")}
                    height="24px"
                    width="36px"
                  />
                ) : (
                  "Save and New"
                )}
              </button>
            )}

            <button
              className="createContactButtonSave"
              type="submit"
              onClick={() => {
                if (existingLead) {
                  handleUpdateLead("SAVE");
                } else if (existingClone) {
                  handleSubmitLead("ADD");
                } else {
                  handleSubmitLead("ADD");
                }
              }}
              disabled={saveSubmit}
            >
              {/* {existingLead ? "UPDATE" : "SAVE"} */}
              {saveSubmit ? (
                <Lottie
                  options={defaultOptions("white")}
                  height="30px"
                  width="36px"
                />
              ) : (
                updateButtonLabel
              )}
            </button>
          </div>
        </div>

        <div className="leadFields ">
          <h6 className="py-2 " style={{ borderBottom: "2px solid #316AFF" }}>
            Lead Information
          </h6>
          <div className="inputFieldDiv">
            <div
              className={`form-group ${isDragging ? "drag-over" : ""}`}
              onDragOver={handleDragOver}
              onDragLeave={handleDragLeave}
              onDrop={handleDrop}
            >
              <p className="mb-0">Upload Image </p>
              <input
                type="file"
                id="accountImage"
                name="accountImage"
                accept=".png, .jpg, .jpeg, .gif"
                className="file-control"
                onChange={handleLeadImageChange}
                ref={fileInputRef}
                style={{ display: "none" }}
              />
              <label htmlFor="accountImage" className="label-file">
                <span style={{ fontSize: "15px" }}>
                  {selectedFiles
                    ? selectedFiles.name.length > 30
                      ? `${selectedFiles.name.substring(0, 30)}...`
                      : selectedFiles.name
                    : "Drag and Drop files (JPG,PNG)"}
                </span>
              </label>
              {imageMsg && (
                <p className="mb-0 small erro_msg">{imageMsg}</p>
              )}
            </div>
          </div>

          <div className="inputFieldDiv">
            <div className="inputFieldDivLeft position-relative">
              <p className="mb-0">Lead Owner </p>
              <div className="d-flex input-with-clear">
                <input
                  type="text"
                  //   placeholder="Vendor Owner"
                  className="leadInputField cursorPointer"
                  value={selectedItem?.name}
                  onClick={handleOwnerInputClick}
                  readOnly
                />

                {/* <span
                  className="clear-button"
                  onClick={() => selectedItem && setSelectedItem("")}
                >
                  <img
                    src={listVisible ? up : down}
                    alt="img"
                    height={20}
                    width={20}
                  />
                </span> */}
                {existingLead ? (
                  <span
                    className="cursorPointer pt-2 ps-1"
                    onClick={() => {
                      setSelectedOwnerItem(selectedItem);
                      setShowModalType("changeOwner");
                      setListVisible(false);
                    }}
                  >
                    <img src={userSearch} alt="img" width={20} height={20} />
                  </span>
                ) : (
                  <span
                    className="cursorPointer pt-2 ps-1"
                    onClick={() => setIsLeadOwnerModal(!isLeadOwnerModal)}
                  >
                    <img src={userSearch} alt="img" width={20} height={20} />
                  </span>
                )}
              </div>

              {existingLead ? (
                <></>
              ) : (
                <>
                  {listVisible && (
                    <ul
                      className="data-list list-group  lead_ownerList_container position-absolute"
                      style={{ top: "6rem" }}
                    >
                      {userList.map((item, index) => (
                        <li
                          key={index}
                          className="cursorPointer list-group-item"
                          onClick={() => handleUserItemClick(item)}
                        >
                          <div className="d-flex flex-column">
                            <span> {item.name} </span>
                            <span> {item.emailId} </span>
                          </div>
                        </li>
                      ))}
                    </ul>
                  )}
                </>
              )}
            </div>
            <div className="inputFieldDivRight">
              <p className="mb-0">
                Company Name <span className="text-danger">*</span>
              </p>
              <input
                type="text"
                className={
                  companyErrorMsg ? "mandatoryField" : "insertCommonInputField"
                }
                name="companyName"
                // value={leadInfo.companyName}
                value={
                  existingLead || existingClone
                    ? leadUpdateInfo.companyName
                    : leadInfo.companyName
                }
                onChange={handleChangeLead}
                maxLength={50}
              />
              <span className="small erro_msg">{companyErrorMsg}</span>
            </div>
          </div>
          <div className="inputFieldDiv">
            <div className="inputFieldDivLeft">
              <p className="mb-0">First Name</p>

              <input
                type="text"
                className="insertCommonInputField"
                name="firstName"
                value={
                  existingLead || existingClone
                    ? leadUpdateInfo.firstName
                    : leadInfo.firstName
                }
                onChange={handleChangeLead}
                maxLength={50}
              />
              <span className="small erro_msg">{firstNameErrorMsg}</span>
            </div>
            <div className="inputFieldDivRight">
              <p className="mb-0">
                Last Name <span className="text-danger">*</span>
              </p>
              <input
                type="text"
                className={
                  lastNameErrorMsg ? "mandatoryField" : "insertCommonInputField"
                }
                name="lastName"
                // value={leadInfo.lastName}
                value={
                  existingLead || existingClone
                    ? leadUpdateInfo.lastName
                    : leadInfo.lastName
                }
                onChange={handleChangeLead}
                maxLength={50}
              />
              <span className="small erro_msg">{lastNameErrorMsg}</span>
            </div>
          </div>
          <div className="inputFieldDiv">
            <div className="inputFieldDivLeft">
              <p className="mb-0">Job Title</p>
              <input
                type="text"
                className="insertCommonInputField"
                name="title"
                value={
                  existingLead || existingClone
                    ? leadUpdateInfo.title
                    : leadInfo.title
                }
                onChange={handleChangeLead}
                maxLength={50}
              />
              <span className="small erro_msg">{titleMsg}</span>
            </div>
            <div className="inputFieldDivRight">
              <p className="mb-0">
                Email <span className="text-danger">*</span>
              </p>
              <input
                type="text"
                className={
                  emailErrorMsg ? "mandatoryField" : "insertCommonInputField"
                }
                name="emailId"
                value={
                  existingLead || existingClone
                    ? leadUpdateInfo.emailId
                    : leadInfo.emailId
                }
                // value={leadInfo.email}
                onChange={handleChangeLead}
                maxLength={64}
              />
              <span className="small erro_msg">{emailErrorMsg}</span>
            </div>
          </div>
          <div className="inputFieldDiv">
            <div className="inputFieldDivLeft">
              <p className="mb-0">Phone Number</p>
              <input
                type="text"
                className="insertCommonInputField"
                pattern="[0-9]*"
                maxLength={10}
                autoComplete="off"
                name="phoneNumber"
                value={
                  existingLead || existingClone
                    ? leadUpdateInfo.phoneNumber
                    : leadInfo.phoneNumber
                }
                // value={leadInfo.phoneNumber.replace(
                //   /[^0-9]|([\u2700-\u27BF]|[\uE000-\uF8FF]|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDFFF]|[\u2011-\u26FF]|\uD83E[\uDD10-\uDDFF])/g,
                //   ""
                // )}
                onChange={handleChangeLead}
                onKeyDown={(e) => {
                  // Allow only numeric input
                  if (
                    !/\d/.test(e.key) &&
                    e.key !== "Backspace" &&
                    e.key !== "Delete" &&
                    e.key !== "ArrowLeft" &&
                    e.key !== "ArrowRight"
                  ) {
                    e.preventDefault();
                  }
                }}
              />
            </div>
            <div className="inputFieldDivRight">
              <p className="mb-0">Fax</p>
              <input
                type="text"
                className={
                  faxErrorMsg ? "mandatoryField" : "insertCommonInputField"
                }
                name="fax"
                maxLength={17}
                autoComplete="off"
                value={
                  existingLead || existingClone
                    ? leadUpdateInfo.fax
                    : leadInfo.fax
                }
                onChange={handleChangeLead}
              />
              <span className="small erro_msg">{faxErrorMsg}</span>
            </div>
          </div>
          <div className="inputFieldDiv">
            <div className="inputFieldDivLeft">
              <p className="mb-0">Mobile</p>
              <input
                type="text"
                className="insertCommonInputField"
                pattern="[0-9]*"
                maxLength={10}
                autoComplete="off"
                name="mobileNumber"
                value={
                  existingLead || existingClone
                    ? leadUpdateInfo.mobileNumber
                    : leadInfo.mobileNumber
                }
                // value={leadInfo.mobileNumber.replace(
                //   /[^0-9]|([\u2700-\u27BF]|[\uE000-\uF8FF]|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDFFF]|[\u2011-\u26FF]|\uD83E[\uDD10-\uDDFF])/g,
                //   ""
                // )}
                onChange={handleChangeLead}
                onKeyDown={(e) => {
                  // Allow only numeric input
                  if (
                    !/\d/.test(e.key) &&
                    e.key !== "Backspace" &&
                    e.key !== "Delete" &&
                    e.key !== "ArrowLeft" &&
                    e.key !== "ArrowRight"
                  ) {
                    e.preventDefault();
                  }
                }}
              />
            </div>
            <div className="inputFieldDivRight">
              <p className="mb-0">Website</p>
              <input
                type="text"
                className="insertCommonInputField"
                name="website"
                // value={leadInfo.website}
                value={
                  existingLead || existingClone
                    ? leadUpdateInfo.website
                    : leadInfo.website
                }
                onChange={handleChangeLead}
              />
              <p style={{ color: "#fa5a5a" }}>{websiteMsg && websiteMsg}</p>
            </div>
          </div>
          <div className="inputFieldDiv">
            <div className="inputFieldDivLeft">
              <p className="mb-0">Lead Source</p>

              <Select
                value={
                  existingLead || existingClone
                    ? leadUpdateInfo.leadSource
                    : leadInfo.leadSource
                }
                menuPlacement="auto"
                onChange={(selectedOption) =>
                  handleChangeSelectDropDown(selectedOption, "leadSource")
                }
                options={leadSourceOptions}
                styles={createLeadStyles}
                isSearchable={false}
              />
            </div>
            <div className="inputFieldDivRight">
              <p className="mb-0">Lead Status</p>

              <Select
                value={
                  existingLead || existingClone
                    ? leadUpdateInfo.leadStatus
                    : leadInfo.leadStatus
                }
                menuPlacement="auto"
                onChange={(selectedOption) =>
                  handleChangeSelectDropDown(selectedOption, "leadStatus")
                }
                options={leadStatusOptions}
                styles={createLeadStyles}
                isSearchable={false}
              />
            </div>
          </div>
          <div className="inputFieldDiv">
            <div className="inputFieldDivLeft">
              <p className="mb-0">Industry</p>

              <Select
                value={
                  existingLead || existingClone
                    ? leadUpdateInfo.industryType
                    : leadInfo.industryType
                }
                menuPlacement="auto"
                onChange={(selectedOption) =>
                  handleChangeSelectDropDown(selectedOption, "industryType")
                }
                options={industryOptions}
                styles={createLeadStyles}
                isSearchable={false}
              />
            </div>
            <div className="inputFieldDivRight">
              <p className="mb-0">No of Employee</p>
              <input
                type="text"
                className="insertCommonInputField"
                pattern="[0-9]*"
                // name="noOfEmployees"
                name={
                  existingLead || existingClone
                    ? "noOfEmployees"
                    : "noOfEmployees"
                }
                maxLength={5}
                // value={leadInfo.noOfEmployees.replace(
                //   /[^0-9]|([\u2700-\u27BF]|[\uE000-\uF8FF]|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDFFF]|[\u2011-\u26FF]|\uD83E[\uDD10-\uDDFF])/g,
                //   ""
                // )}
                value={
                  existingLead || existingClone
                    ? leadUpdateInfo.noOfEmployees
                    : leadInfo.noOfEmployees
                }
                onChange={handleChangeLead}
              />
            </div>
          </div>
          <div className="inputFieldDiv">
            <div className="inputFieldDivLeft position-relative">
              <p className="mb-0">Annual Revenue</p>
              <input
                type="text"
                className="insertCommonInputField"
                pattern="[0-9.]*"
                maxLength={16}
                name="annualRevenue"
                style={{ padding: "12px 15px" }}
                value={
                  existingLead || existingClone
                    ? leadUpdateInfo.annualRevenue
                    : leadInfo.annualRevenue
                }
                onChange={handleChangeLead}
                onBlur={() => handleBlur("annualRevenue")}
                onKeyPress={handleKeyPress}
              />

              <span
                className="clear-button position-absolute px-1"
                style={{ left: "0px", bottom: "9px" }}
              >
                ₹
              </span>
              {showTooltipAnnualRevenue && (
                <div style={{ ...tooltipStyles, bottom: "30px" }}>
                  {"= " + tooltipContent}
                </div>
              )}
              <span
                className="small erro_msg position-absolute"
                style={{ left: "0px", bottom: "-19px" }}
              >
                {annualRevenueMsg}
              </span>
            </div>
            <div className="inputFieldDivRight">
              <p className="mb-0">Rating</p>

              <Select
                value={
                  existingLead || existingClone
                    ? leadUpdateInfo.rating
                    : leadInfo.rating
                }
                menuPlacement="auto"
                onChange={(selectedOption) =>
                  handleChangeSelectDropDown(selectedOption, "rating")
                }
                options={ratingOptions}
                styles={createLeadStyles}
                isSearchable={false}
              />
            </div>
          </div>
          <div className="inputFieldDiv">
            <div className="inputFieldDivLeft flex-row">
              <span className="ms-2">Email Opt Out</span>

              <input
                type="checkbox"
                name="emailOptOut"
                checked={emailOptOut}
                onClick={() => {
                  setEmailOptOut(!emailOptOut);
                }}
              />
            </div>
            <div className="inputFieldDivRight">
              <p className="mb-0">Secondary Mail</p>
              <input
                type="text"
                className="insertCommonInputField"
                name="secondaryEmailId"
                value={
                  existingLead || existingClone
                    ? leadUpdateInfo.secondaryEmailId
                    : leadInfo.secondaryEmailId
                }
                // value={leadInfo.secondaryEmailId}
                onChange={handleChangeLead}
              />
              {/* {!secEmailError && leadUpdateInfo.secondaryEmailId != "" && (
                <p style={{ color: "red", margin: "5px 0" }}>
                  Please enter a valid email address.
                </p>
              )} */}
            </div>
          </div>
          <div className="inputFieldDiv">
            <div className="inputFieldDivLeft">
              <p className="mb-0">Skype ID</p>
              <input
                type="text"
                className="insertCommonInputField"
                name="skypeId"
                // value={leadInfo.skypeId}
                value={
                  existingLead || existingClone
                    ? leadUpdateInfo.skypeId
                    : leadInfo.skypeId
                }
                onChange={handleChangeLead}
              />
              <span className="small erro_msg">{skypeIdMsg}</span>
            </div>
            <div className="inputFieldDivRight">
              <p className="mb-0">Twitter</p>
              <input
                type="text"
                className="insertCommonInputField"
                name="twitter"
                value={
                  existingLead || existingClone
                    ? leadUpdateInfo.twitter
                    : leadInfo.twitter
                }
                // value={leadInfo.twitter}
                onChange={handleChangeLead}
              />
              <span className="small erro_msg">{twitterId}</span>
            </div>
          </div>

          <h6 className="py-2" style={{ borderBottom: "2px solid #316AFF" }}>
            Address Information
          </h6>

          <div className="inputFieldDiv">
            <div className="inputFieldDivLeft">
              <p className="mb-0">Street</p>
              <input
                type="text"
                className="insertCommonInputField"
                name="street"
                // value={addressInfo.street}
                value={
                  existingLead || existingClone
                    ? leadUpdateInfo.street
                    : leadInfo.street
                }
                onChange={handleChangeLead}
              />
            </div>
            <div className="inputFieldDivRight">
              <p className="mb-0">City</p>
              <input
                type="text"
                className="insertCommonInputField"
                name="city"
                // value={addressInfo.city}
                value={
                  existingLead || existingClone
                    ? leadUpdateInfo.city
                    : leadInfo.city
                }
                onChange={handleChangeLead}
              />
              <span className="small erro_msg">{cityMsg}</span>
            </div>
          </div>
          <div className="inputFieldDiv">
            <div className="inputFieldDivLeft">
              <p className="mb-0">State</p>
              <input
                type="text"
                className="insertCommonInputField"
                name="state"
                // value={addressInfo.state}
                value={
                  existingLead || existingClone
                    ? leadUpdateInfo.state
                    : leadInfo.state
                }
                onChange={handleChangeLead}
              />
              <span className="small erro_msg">{stateMsg}</span>
            </div>
            <div className="inputFieldDivRight">
              <p className="mb-0">Zip Code</p>
              <input
                type="text"
                className="insertCommonInputField"
                pattern="[0-9]*"
                maxLength={6}
                name="zipCode"
                // value={addressInfo.zipCode.replace(
                //   /[^0-9]|([\u2700-\u27BF]|[\uE000-\uF8FF]|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDFFF]|[\u2011-\u26FF]|\uD83E[\uDD10-\uDDFF])/g,
                //   ""
                // )}
                value={
                  existingLead || existingClone
                    ? leadUpdateInfo.zipCode
                    : leadInfo.zipCode
                }
                onChange={handleChangeLead}
              />
            </div>
          </div>
          <div className="inputFieldDiv">
            <div className="inputFieldDivLeft">
              <p className="mb-0">Country</p>
              <input
                type="text"
                className="insertCommonInputField"
                name="country"
                // value={addressInfo.country}
                value={
                  existingLead || existingClone
                    ? leadUpdateInfo.country
                    : leadInfo.country
                }
                onChange={handleChangeLead}
              />
              <span className="small erro_msg">{countryMsg}</span>
            </div>
          </div>
          <h6 className="py-2" style={{ borderBottom: "2px solid #316AFF" }}>
            Description Information
          </h6>
          <div className="inputFieldDiv">
            <div className="inputFieldDivLeft">
              <p>Description</p>
              <textarea
                rows="4"
                cols="50"
                maxLength="1000"
                style={{
                  width: "100%",
                  border: "1px solid #B9B9B94D",
                  height: "100px",
                  outline: "none",
                  borderRadius: "10px",
                  backgroundColor: "transparent",
                  padding: "8px 12px",
                  resize: "none",
                }}
                name="description"
                // value={description}
                value={
                  existingLead || existingClone
                    ? leadUpdateInfo.description
                    : leadInfo.description
                }
                onChange={handleChangeLead}
              />
            </div>
          </div>
        </div>
      </>
    );
  };

  const convertPage = () => {
    return (
      <>
        <div className="convertMain_container h-100 ">
          <div className="convert_header_container commonContainer_bg w-100  d-flex justify-content-between">
            <div className="profile_logo_container d-flex pt-2">
              <div className="d-flex flex-column align-items-center justify-content-center ps-2">
                <span>Convert Lead</span>
                <span className="opacity-75 ">
                  {leadOverviewDetails.leadName +
                    " - " +
                    updatedFields.companyName}
                </span>
              </div>
            </div>
            <div className="d-flex  justify-content-end align-items-end  px-2 pb-2">
              <div className="d-flex gap-2">
                <button
                  className="createContactButton"
                  // className="btn  btnColor text-white"
                  onClick={() => setInnerPage("overview")}
                >
                  Cancel
                </button>
                <button
                  // className="btn btn-secondary bg-white text-dark border border-primary "
                  className={`createContactButtonSave ${
                    innerPage === "convert"
                      ? "text-white btnColor"
                      : "text-dark bg-white"
                  }`}
                  onClick={handleConvertSubmit}
                  disabled={apiLoader}
                >
                  {apiLoader ? (
                    <Lottie
                      options={defaultOptions("white")}
                      height="24px"
                      width="36px"
                    />
                  ) : (
                    "Convert"
                  )}
                </button>
              </div>
            </div>
          </div>

          <div className="convert_ContentMain_container d-flex gap-3 mt-2 ">
            <div className="bg-white px-2  rounded-2 py-2 w-50">
              <div
                className="commonContainer_bg  rounded-2 mt-2 py-2 "
                style={{ border: "1px solid #E2E5EB" }}
              >
                <div className="d-flex gap-1">
                  <div className="d-flex flex-column convertLabel w-50">
                    <span className="opacity-75">Create New Account</span>
                    <span className="opacity-75">Create New Contact</span>
                    <span className="opacity-75">Owner of the New Records</span>
                  </div>
                  <div className="d-flex flex-column convertLabel w-50">
                    <span>{updatedFields.companyName}</span>
                    <span className="opacity-75">
                      {leadOverviewDetails.leadName}
                    </span>
                    <div className=" position-relative w-100 pt-0 pe-5">
                      <div
                        className="position-relative w-100 d-flex align-items-center"
                        onClick={handleOwnerInputClick}
                      >
                        <div className="position-relative w-100 border rounded-2 d-flex align-items-center">
                          <span className="cursorPointer w-100">
                            {selectedItem?.name || ""}
                          </span>

                          <span
                            className=" position-absolute ps-0"
                            style={{ right: "0.2rem" }}
                            onClick={() => selectedItem && setSelectedItem("")}
                          >
                            <img
                              src={!listVisible ? down : up}
                              alt="img"
                              height={20}
                              width={20}
                            />
                          </span>
                        </div>

                        <span
                          className="cursorPointer pt-2 ps-1"
                          onClick={() => setIsLeadOwnerModal(!isLeadOwnerModal)}
                          style={{ zIndex: "10" }}
                        >
                          <img
                            src={userSearch}
                            alt="img"
                            width={20}
                            height={20}
                          />
                        </span>
                      </div>
                      {listVisible && (
                        <ul
                          className="data-list list-group  lead_ownerList_container position-absolute bg-white w-auto"
                          style={{ top: "2.8rem" }}
                        >
                          {/* <ul className="data-list list-group  lead_ownerList_container"> */}
                          {userList.map((item, index) => (
                            <li
                              key={index}
                              className="cursorPointer my-1 border-bottom"
                              onClick={() => handleUserItemClick(item)}
                            >
                              <div className="d-flex flex-column">
                                <span className="py-0"> {item.name} </span>
                                <span className="py-0 small opacity-75">
                                  {" "}
                                  {item.emailId}{" "}
                                </span>
                              </div>
                            </li>
                          ))}
                        </ul>
                      )}
                    </div>
                  </div>
                </div>
                <div className="d-flex  gap-2 ps-2 pt-2 ">
                  <input
                    type="checkbox"
                    onChange={handleCheckboxChange}
                    checked={showInputs}
                    className="cursorPointer"
                  />
                  <span>Create a new Deal for this Account.</span>
                </div>
              </div>
            </div>
            <div
              className="bg-white p-2 rounded-2 w-50"
              // style={{ width: "60%" }}
            >
              <>
                {showInputs && (
                  <div
                    className="commonContainer_bg convertMain_container rounded-2 py-2 ps-3"
                    style={{ border: "1px solid #E2E5EB" }}
                  >
                    <div className="d-flex flex-column h-50  position-relative">
                      <label>
                        Amount <span className="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        // className="insertCommonInputField ps-3"
                        className={
                          amtErrorMsg
                            ? "mandatoryField ps-3"
                            : "insertCommonInputField ps-3"
                        }
                        name="amount"
                        value={convertFormData.amount}
                        onChange={handleConvertChange}
                        onKeyDown={() => {
                          // Allow only numeric input
                          // if (
                          //  / !/\d/.test(e.key) &&
                          //   e.key !== "Backspace" &&
                          //   e.key !== "Delete" &&
                          //   e.key !== "ArrowLeft" &&
                          //   e.key !== "ArrowRight"
                          // ) {
                          //   e.preventDefault();
                          // }
                        }}
                      />
                      <span className="position-absolute start-0 pt-1 ms-1 dollar_icon">
                        ₹
                      </span>
                      <span className="small erro_msg">{amtErrorMsg}</span>
                    </div>
                    <div className="d-flex flex-column mt-3 w-100">
                      <label>
                        Deal Name <span className="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        className={
                          dealErrorMsg
                            ? "mandatoryField ps-2"
                            : "insertCommonInputField ps-2"
                        }
                        name="dealName"
                        value={convertFormData.dealName}
                        onChange={handleConvertChange}
                      />
                      <span className="small erro_msg">{dealErrorMsg}</span>

                      {/* <div
                        className="d-flex input-with-clear position-relative"
                        onClick={(e) => {
                          e.stopPropagation();
                          setShowDealList(!showDealList);
                        }}
                      >
                        <input
                          type="text"
                          className="insertCommonInputField cursorPointer"
                          value={convertFormData.dealName}
                          readOnly
                        />
                        <span
                          className="position-absolute pt-2 cursorPointer"
                          // onClick={() => setShowDealList("")}
                          style={{ right: "42%" }}
                        >
                          <img
                            src={showDealList ? up : down}
                            alt="img"
                            height={20}
                            width={20}
                          />
                        </span>
                      </div>
                      {showDealList && (
                        <ul
                          className="data-list list-group  lead_ownerList_container position-absolute bg-white"
                        >
                          {dealList.map((item, index) => (
                            <li
                              key={index}
                              className="cursorPointer w-100 px-2 border-bottom my-1"
                              onClick={
                                () => {
                                  setConvertFormData((prevState) => ({
                                    ...prevState,
                                    dealName: item.dealName,
                                  }));
                                  setShowDealList(false);
                                }
                              }
                            >
                              <div className="d-flex flex-column">
                                <span style={{ fontWeight: "500" }}>
                                  {item?.dealName}
                                </span>
                                <span
                                  className="small"
                                  style={{ color: "#999999" }}
                                >
                                  {item.emailId}{" "}
                                </span>
                              </div>
                            </li>
                          ))}
                        </ul>
                      )} */}
                    </div>
                    <div className="d-flex flex-column mt-3 mb-2">
                      <label>
                        Closing Date <span className="text-danger">*</span>
                      </label>
                      <input
                        type="date"
                        name="closingDate"
                        style={{
                          // width: "25%",
                          border: "1px solid #B9B9B94D",
                          height: "40px",
                          outline: "none",
                        }}
                        // className="bg-transparent px-2 rounded-1 insertCommonInputField"
                        className={`bg-transparent px-2 rounded-1 ${
                          dateErrorMsg
                            ? "mandatoryField ps-2"
                            : "insertCommonInputField ps-2"
                        }`}
                        value={convertFormData.closingDate}
                        onChange={handleConvertChange}
                        onKeyDown={(e) => e.preventDefault()}
                      />
                      <span className="small erro_msg">{dateErrorMsg}</span>
                    </div>
                    <div className="d-flex flex-column mt-3">
                      <label>
                        Stage <span className="text-danger">*</span>
                      </label>

                      <Select
                        value={{
                          value: convertFormData.stage,
                          label: convertFormData.stage,
                        }}
                        // value={stageOptions.find(
                        //   (option) => option.value === stage
                        // )}
                        menuPlacement="auto"
                        onChange={(selectedOption) => {
                          handleConvertSelectDropDown(selectedOption, "stage");
                          setConvertFormData((prevState) => ({
                            ...prevState,
                            probability: stages[selectedOption.value],
                          }));
                        }}
                        options={stageOptions}
                        styles={createLeadStyles}
                        isSearchable={false}
                      />
                    </div>

                    <div className="d-flex flex-column mt-3 mb-2">
                      <label>
                        Probability (%) <span className="text-danger">*</span>
                      </label>
                      <input
                        name="probability"
                        type="text"
                        pattern="[0-9]*"
                        maxLength={3}
                        autoComplete="off"
                        style={{
                          // width: "25%",
                          border: "1px solid #B9B9B94D",
                          height: "40px",
                          outline: "none",
                        }}
                        className="bg-transparent px-2 rounded-1 insertCommonInputField"
                        value={convertFormData.probability}
                        onChange={handleConvertChange}
                        readOnly
                      />
                      <span className="small erro_msg">
                        {probablityErrorMsg}
                      </span>
                    </div>

                    <div className="d-flex flex-column mt-3">
                      <label> Contact Role</label>
                      <Select
                        // value={roleOptions.find(
                        //   (option) => option.value === role
                        // )}
                        value={{
                          value: convertFormData.role,
                          label: convertFormData.role,
                        }}
                        menuPlacement="auto"
                        onChange={(selectedOption) =>
                          handleConvertSelectDropDown(selectedOption, "role")
                        }
                        options={roleOptions}
                        styles={createLeadStyles}
                        isSearchable={false}
                      />
                    </div>

                    <div className="d-flex flex-column mt-3 position-relative">
                      <label>Campaign Source</label>
                      <div className="d-flex input-with-clear ">
                        <input
                          type="text"
                          className={"insertCommonInputField cursorPointer"}
                          value={selectedCampaignItem?.campaignName}
                          onClick={(e) => {
                            e.stopPropagation();
                            setParentListVisible(!parentListVisible);
                          }}
                          readOnly
                        />
                        <span
                          className="clear-button"
                          onClick={(e) => {
                            e.stopPropagation();
                            setParentListVisible(!parentListVisible);
                          }}
                        >
                          <img
                            src={parentListVisible ? up : down}
                            alt="img"
                            height={20}
                            width={20}
                          />
                        </span>
                        <span
                          className="cursorPointer pt-2 ps-1"
                          onClick={() => {
                            setIsCampaignListModal(!isCampaignListModal);
                          }}
                        >
                          <img
                            src={campaignIcon}
                            alt="img"
                            width={20}
                            height={20}
                          />
                        </span>
                      </div>
                      {parentListVisible && allList.length > 0 && (
                        <ul
                          className="data-list list-group  lead_ownerList_container position-absolute pb-1"
                          style={{ top: "4.2rem" }}
                          onClick={(e) => e.stopPropagation()}
                        >
                          {allList.map((item, index) => (
                            <li
                              key={index}
                              className="cursorPointer list-group-item"
                              onClick={(e) => {
                                e.stopPropagation();
                                setSelectedCampaignItem({
                                  campaignName: item.campaignName,
                                  campaignId: item.campaignId,
                                });
                                setParentListVisible(false);
                              }}
                            >
                              {item.campaignName}
                            </li>
                          ))}
                        </ul>
                      )}
                    </div>
                  </div>
                )}
              </>
            </div>
          </div>
        </div>
        <div></div>
      </>
    );
  };

  const handleFieldClick = (field) => {
    const fieldValue = field.name
      .replace(/\s+/g, "")
      .replace(/^(.)/, (match) => match.toLowerCase());
    if (field.type === "dropdown") {
      setUpdatedFields((prevFields) => ({
        ...prevFields,
        [fieldValue]: leadOverviewDetails[fieldValue] || "",
      }));
    } else if (field.type === "checkbox") {
      setUpdatedFields((prevFields) => ({
        ...prevFields,
        emailOptOut: leadOverviewDetails["emailOptOut"],
      }));
    } else {
      setUpdatedFields((prevFields) => ({
        ...prevFields,
        [fieldValue]: leadOverviewDetails[fieldValue] || "",
      }));
    }

    const updatedColumns = [...fieldLabels];

    const existedObj = updatedColumns.find(
      (field) => field.name === selectedField
    );
    const selectedObj = updatedColumns.find((item) => item.name === field.name);

    const existedObjIndex = updatedColumns.findIndex(
      (field) => field.name === existedObj.name
    );

    const selectedObjIndex = updatedColumns.findIndex(
      (field) => field.name === selectedObj.name
    );

    updatedColumns[existedObjIndex].isShowed = false;
    updatedColumns[selectedObjIndex].isShowed = true;

    const removedObj = updatedColumns.splice(existedObjIndex, 1)[0];
    updatedColumns.splice(existedObjIndex, 0, selectedObj);

    updatedColumns.push(removedObj);
    const uniqueData = updatedColumns.filter(
      (item, index, self) =>
        index === self.findIndex((t) => t.name === item.name)
    );
    setFieldLabels(uniqueData);
    setShowSearchFields(uniqueData);
  };

  const deleteLead = async () => {
    const requestBody = {
      // loggedInUserId: "BCRM0027",
      loggedInUserId: loginUserId,

      leadId: leadId,
    };

    const headersResult = getHeaders(true);

    // Check if headers result indicates an error
    if (headersResult.error) {
      navigate("../"); // Redirect to login if token is missing
      return;
    }

    await axios
      .post(URI.deleteLead, requestBody, {
        headers: headersResult,
      })

      .then((response) => {
        if (response.data.status === 200) {
          NotificationManager.success("", response.data.message, 5000);
          getAllLeadsDetails(currentPage, selectedOption);
          setShowOptionsLead(false);
          setShowModalType("");
          setInnerPage("list");
        } else {
          NotificationManager.error("", response.data.message, 5000);
        }
      })
      .catch(function (error) {
        handleApiError(error, navigate);
      });
  };

  const individualLeadDetailPage = () => {
    return (
      <div className="indiv_main_container ">
        {leadOverviewDetails.isLeadConverted ? (
          <div className="w-100 p-2">
            <div className="mx-auto text-center pt-2 border-bottom pb-3">
              <img src={NoData} alt="No data" className="" />
              <h4 className="fs-5 my-0 py-0 fw-bold opacity-75">Sorry!</h4>
              <span className="opacity-75 ">You cannot access the lead</span>
            </div>
            <div className="py-2 ">
              <h5 className="pt-1">Conversion Details</h5>

              <div className="d-flex justify-content-between">
                <section className="w-50">
                  {leadOverviewDetails.contact && (
                    <div className="border-bottom w-100 d-flex justify-content-between pt-3 pb-1">
                      <span>Contact</span>
                      <span>{leadOverviewDetails.contact}</span>
                    </div>
                  )}
                  {leadOverviewDetails.account && (
                    <div className="border-bottom w-100 d-flex justify-content-between pt-3 pb-1">
                      <span>Account</span>
                      <span>{leadOverviewDetails.account}</span>
                    </div>
                  )}
                  {leadOverviewDetails.dealName && (
                    <div className="border-bottom w-100 d-flex justify-content-between pt-3 pb-1">
                      <span>Deal Name</span>
                      <span>{leadOverviewDetails.dealName}</span>
                    </div>
                  )}
                </section>
                <section className="">
                  <button
                    className="createContactButton"
                    onClick={() => {
                      setInnerPage("list");
                    }}
                  >
                    Go To leads
                  </button>
                </section>
              </div>
            </div>
          </div>
        ) : (
          <>
            <header className="commonContainer_bg w-100">
              <div className="d-flex justify-content-between px-3 pt-2">
                <span
                  className="cursorPointer"
                  onClick={() => {
                    getAllLeadsDetails(currentPage, selectedOption);
                    setInnerPage("list");
                    const resetFields = Object.fromEntries(
                      Object.keys(editingFields).map((key) => [key, false])
                    );
                    setEditingFields({
                      ...resetFields,
                    });
                    setShowType("");
                  }}
                >
                  {leftArrow}
                </span>
                <span>
                  <span
                    className={`me-2 ${
                      selectedLeadIndex === 0 ? "opacity-50" : "cursorPointer"
                    }`}
                    onClick={() => {
                      const leadIndex = leadDetails.findIndex(
                        (lead) => lead.leadId === leadId
                      );
                      if (leadIndex !== 0) {
                        setSelectedLeadIndex(leadIndex - 1);
                        setLeadId(leadDetails[leadIndex - 1].leadId);
                        leadOverviewDetail(leadDetails[leadIndex - 1].leadId);
                        getNotesDetails(
                          leadDetails[leadIndex - 1].leadId,
                          selectRecent
                        );
                        setSelectedLead(leadDetails[leadIndex - 1]);
                      }
                    }}
                  >
                    {leftAngleArrow}
                  </span>
                  <span
                    className={`ms-1 ${
                      selectedLeadIndex === leadDetails.length - 1
                        ? "opacity-50"
                        : "cursorPointer"
                    }`}
                    onClick={() => {
                      const leadIndex = leadDetails.findIndex(
                        (lead) => lead.leadId === leadId
                      );
                      if (leadIndex < leadDetails.length - 1) {
                        setSelectedLeadIndex(leadIndex + 1);
                        setLeadId(leadDetails[leadIndex + 1].leadId);
                        leadOverviewDetail(leadDetails[leadIndex + 1].leadId);
                        getNotesDetails(
                          leadDetails[leadIndex + 1].leadId,
                          selectRecent
                        );
                        setSelectedLead(leadDetails[leadIndex + 1]);
                      }
                    }}
                  >
                    {rightAngleArrow}
                  </span>
                </span>
              </div>
              <div className=" d-flex justify-content-between">
                <div className="profile_logo_container d-flex pt-2">
                  <div className="logoContainer d-flex align-items-center justify-content-center pt-1">
                    <div className="logoContainer d-flex align-items-center justify-content-center pt-1 cursorPointer">
                      <img
                        src={
                          leadOverviewDetails.leadImageData === null
                            ? leadAvatar
                            : leadOverviewDetails.leadImageData
                        }
                        name="leadImageData"
                        alt="img"
                        style={{ width: "100px", height: "100px" }}
                        onClick={() => {
                          setShowImageModal(true);
                        }}
                      />
                    </div>
                  </div>
                  <div className="d-flex flex-column align-items-center justify-content-center ps-2">
                    <span>{leadOverviewDetails?.leadName}</span>
                    <span className="opacity-75 ">
                      {leadOverviewDetails?.companyName}
                    </span>
                  </div>
                </div>
                <div className="d-flex  justify-content-end align-items-end  px-2 pb-2">
                  <div className="d-flex gap-2 position-relative">
                    <button
                      className={`btn btn-secondary} ${
                        innerPage === "convert"
                          ? "text-white btnColor"
                          : "text-dark bg-white"
                      }`}
                      onClick={() => {
                        setShowInputs(false);
                        setConvertFormData({
                          amount: "",
                          dealName: leadOverviewDetails.leadName,
                          closingDate: "",
                          stage: stageOptions[0].value,
                          source: "",
                          role: roleOptions[0].value,
                          probability: stages[stageOptions[0].value],
                        });
                        setInnerPage("convert");
                      }}
                    >
                      Convert
                    </button>
                    
 <button
 className="btn btn-secondary bg-white text-dark border border-primary"
 onClick={handleEditLead}
>
 Edit
</button>
                   
                    <button
                      className="btn btn-secondary bg-white text-dark border border-primary"
                      onClick={(e) => {
                        e.stopPropagation();
                        setShowOptionsLead(!showOptionsLead);
                      }}
                    >
                      ---
                    </button>
                
                    {showOptionsLead && (
                      <div
                        className="rounded  showOptions p-2 end-0"
                        style={{ top: "2.6rem", zIndex: "1" }}
                      >
                        
                        <div
                          className="d-flex gap-3 align-items-center mt-2"
                          onClick={handleCreateLeadClone}
                        >
                          <img src={Clone} alt="clone" />
                          <div
                            style={{ color: "#515151", fontWeight: "300" }}
                            className="mt-1 cursorPointer ms-2"
                          >
                            Clone
                          </div>
                        </div>
                        {leadOverviewDetails.isShared===false ? (
  <div
    className="d-flex gap-3 align-items-center mt-2"
    onClick={() => {
      if (!isLoading) {
        getAllInfoShare(leadId);
      }
    }}
  >
    <img src={Share} alt="share" />
    <div
      style={{ color: "#515151", fontWeight: "300" }}
      className="mt-2 cursorPointer ms-2"
    >
      Share
    </div>
  </div>
) : (
 ""
)}

                      
                         <div
                         className="d-flex gap-3 align-items-center mt-2"
                         onClick={handleDeleteLead}
                       >
                         <img src={Delete} alt="close" />
                         <div
                           style={{ color: "#515151", fontWeight: "300" }}
                           className="mt-2 cursorPointer ms-2"
                         >
                           Delete
                         </div>
                       </div>
                    
                       
                        <div
                          style={{ borderBottom: "1px solid #515151s" }}
                        ></div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </header>
            <div
              className="detailList_main_container commonContainer_bg mt-2"
              style={{ height: "auto" }}
            >
              <div className="detailList_main_container d-flex pt-3 pb-2">
                <div
                  className={` pt-3 cursorPointer ${isShrinked ? "" : "w-25"}`}
                  onClick={() => {
                    setIsShrinked(!isShrinked);
                  }}
                >
                  {isShrinked ? expandArrow : shrinkArrow}
                </div>
                <div
                  className={`d-flex justify-content-between px-2 ${
                    isShrinked ? "w-100" : "w-75 ps-3"
                  }`}
                >
                  <div className="pt-3">
                    Last Update: {leadOverviewDetails.lastUpdate}
                  </div>
                  <div className="d-flex  gap-4 justify-content-between px-2 bg-white px-1  rounded-5 py-1">
                    <span
                      className={`cursorPointer ${
                        leadPageView === "overview"
                          ? "overviewTimelineTab"
                          : "  d-flex justify-content-center align-items-center"
                      }`}
                      onClick={() => setLeadPageView("overview")}
                    >
                      Overview
                    </span>
                    <span
                      className={`cursorPointer ${
                        leadPageView === "timeline"
                          ? "overviewTimelineTab"
                          : " d-flex justify-content-center align-items-center"
                      }`}
                      onClick={() => {
                        if (leadPageView !== "timeline") {
                          setLeadPageView("timeline");
                          getAllTimeleadLeads(leadId);
                        }
                      }}
                    >
                      Timeline
                    </span>
                  </div>
                </div>
              </div>

              <div className="detailList_content_container d-flex justify-content-between px-2 py-2 bg-white">
                <div
                  className={`w-25 relatedListContainer rounded commonContainer_bg me-2 ${
                    isShrinked ? "d-none" : ""
                  }`}
                  // style={{ height: "100vh" }}
                >
                  <div className="d-flex">
                    <p className="opacity-57 ps-2 opacity-75 m-0 py-2">Related List</p>
                  </div>
                  <div className="horizontal_line mx-1"></div>
                  <ul>
                    {notePermission === "TRUE" && (
                      <li
                        className={`cursorPointer p-2 relatedList_tab rounded-1`}
                        onClick={() => {
                          if (leadPageView === "timeline") {
                            setLeadPageView("overview");
                            // setActiveTab("notes");
                            setTimeout(
                              () => scrollToSection("notesSection"),
                              0
                            );
                          } else {
                            // setActiveTab("notes");
                            scrollToSection("notesSection");
                          }
                        }}
                      >
                        <span>
                          <img
                            src={NoteBook}
                            alt="img"
                            height={20}
                            width={20}
                          />
                        </span>
                        Notes
                      </li>
                    )}

                    <li
                      className={`cursorPointer p-2 relatedList_tab rounded-1`}
                      onClick={() => {
                        if (leadPageView === "timeline") {
                          setLeadPageView("overview");
                          // setActiveTab("product");
                          setTimeout(() => scrollToSection("product"), 0);
                        } else if (leadPageView === "overview") {
                          // setActiveTab("product");
                          scrollToSection("product");
                        }
                      }}
                    >
                      <span>
                        <img src={prodIcon} alt="img" height={20} width={20} />
                      </span>
                      Products
                    </li>
                    <li
                      className={`cursorPointer p-2 relatedList_tab rounded-1`}
                      onClick={() => {
                        if (leadPageView === "timeline") {
                          setLeadPageView("overview");
                          // setActiveTab("campaign");
                          setTimeout(() => scrollToSection("campaign"), 0);
                        } else if (leadPageView === "overview") {
                          // setActiveTab("campaign");
                          scrollToSection("campaign");
                        }
                      }}
                    >
                      <span>
                        <img
                          src={CampaignIcon}
                          alt="img"
                          height={20}
                          width={20}
                        />
                      </span>
                      Campaigns
                    </li>
                    <li
                      className={`cursorPointer p-2 relatedList_tab rounded-1`}
                      onClick={() => {
                        if (leadPageView === "timeline") {
                          setLeadPageView("overview");
                          // setActiveTab("openActivity");
                          setTimeout(() => scrollToSection("openActivity"), 0);
                        } else if (leadPageView === "overview") {
                          // setActiveTab("openActivity");
                          scrollToSection("openActivity");
                        }
                      }}
                    >
                      <span>
                        <img
                          src={openActivityIcon}
                          alt="img"
                          height={20}
                          width={20}
                        />
                      </span>
                      Open Activities
                    </li>
                    <li
                      className={`cursorPointer p-2 relatedList_tab rounded-1`}
                      onClick={() => {
                        if (leadPageView === "timeline") {
                          setLeadPageView("overview");
                          // setActiveTab("closeActivity");
                          setTimeout(() => scrollToSection("closeActivity"), 0);
                        } else if (leadPageView === "overview") {
                          // setActiveTab("closeActivity");
                          scrollToSection("closeActivity");
                        }
                      }}
                    >
                      <span>
                        <img
                          src={closeActivityIcon}
                          alt="img"
                          height={20}
                          width={20}
                        />
                      </span>
                      Closed Activities
                    </li>
                    <li
                      className={`cursorPointer p-2 relatedList_tab rounded-1`}
                      onClick={() => {
                        if (leadPageView === "timeline") {
                          setLeadPageView("overview");
                          // setActiveTab("invited");
                          setTimeout(() => scrollToSection("invited"), 0);
                        } else if (leadPageView === "overview") {
                          // setActiveTab("invited");
                          scrollToSection("invited");
                        }
                      }}
                    >
                      <span>
                        <img
                          src={CampaignIcon}
                          alt="img"
                          height={20}
                          width={20}
                        />
                      </span>
                      Invited Meetings
                    </li>
                  </ul>
                </div>

                {leadPageView === "overview" ? (
                  <div
                    className={`common_bg leadDetail_main_Container ${
                      isShrinked ? "w-100" : "w-75"
                    }`}
                  >
                    <div className=" rounded-2 commonContainer_bg d-flex justify-content-between p-2">
                      <div className="w-100 d-flex flex-column">
                        {fieldLabels.map((field, i) => {
                          const fieldValue =
                            field.value ||
                            field.name
                              .replace(/\s+/g, "")
                              .replace(/^(.)/, (match) => match.toLowerCase());
                          return field.isShowed ? (
                            <div className="d-flex w-50 ps-1" key={i}>
                              <div className="w-50 position-relative">
                                <div
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    setSelectedField(
                                      selectedField === field.name
                                        ? ""
                                        : field.name
                                    );
                                    setSearchField("");
                                  }}
                                  className={`cursorPointer d-flex justify-content-between w-100 my-1 p-1 ${
                                    selectedField === field.name
                                      ? "outline_border rounded-1"
                                      : ""
                                  }`}
                                >
                                  <span>{field.name}</span>
                                  <img
                                    src={
                                      selectedField === field.name ? up : down
                                    }
                                    alt="img"
                                    height={20}
                                    width={20}
                                    className="pt-1"
                                  />
                                </div>
                                {selectedField === field.name && (
                                  <div
                                    className="position-absolute field_container w-100"
                                    onClick={(e) => {
                                      e.stopPropagation();
                                    }}
                                  >
                                    <div className="">
                                      <label
                                        htmlFor="selectField"
                                        className="position-relative w-100"
                                      >
                                        <input
                                          id="selectField"
                                          type="text"
                                          className="rounded-2 p-1 w-100 pe-4"
                                          placeholder="Search"
                                          value={searchField}
                                          onChange={(e) => {
                                            if (
                                              e.target.value.charAt(0) !== " "
                                            ) {
                                              const value =
                                                e.target.value.replace(
                                                  /[^a-zA-Z ]+|(\s)\s+/g,
                                                  "$1"
                                                );
                                              setSearchField(value);
                                            }
                                          }}
                                        />
                                        <img
                                          className="position-absolute mt-1 me-2 end-0 pt-1 cursorPointer"
                                          onClick={() => {
                                            setSearchField("");
                                            // setShowSearchFields(fieldDropdowns);
                                          }}
                                          src={searchIcon}
                                          alt="search"
                                          width={20}
                                        />
                                      </label>

                                      <div className="w-100 rounded-2">
                                        <div className="border border-1 field_container_dropdown rounded-1">
                                          {showSearchFields
                                            .filter((field) =>
                                              field.name
                                                .toLowerCase()
                                                .includes(
                                                  searchField
                                                    .trim()
                                                    .toLowerCase()
                                                )
                                            )
                                            .sort((a, b) =>
                                              a.name.localeCompare(b.name)
                                            )
                                            .map(
                                              (field, i) =>
                                                (selectedField === field.name ||
                                                  !field.isShowed) && (
                                                  <div
                                                    key={field.name}
                                                    className="d-flex gap-2 p-2 cursorPointer rounded-2 align-items-center"
                                                    onClick={() => {
                                                      selectedField ===
                                                      field.name
                                                        ? setSelectedField("")
                                                        : handleFieldClick(
                                                            field,
                                                            i
                                                          );
                                                    }}
                                                  >
                                                    <span className="right_tick">
                                                      {selectedField ===
                                                      field.name
                                                        ? tick
                                                        : ""}
                                                    </span>
                                                    <span className="ps-2">
                                                      {field.name}
                                                    </span>
                                                  </div>
                                                )
                                            )}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                )}
                              </div>
                              <div
                                className={`w-50 mt-1 ms-3 position-relative`}
                              >
                                {field.type === "input" ? (
                                  <div className="w-100">
                                    <EditableInput
                                      className="ps-3"
                                      inputType={fieldValue}
                                      handleIndividualInputChange={
                                        handleIndividualInputChange
                                      }
                                      editingFields={editingFields}
                                      handleEditClick={handleEditClick}
                                      handleCancelClick={handleCancelClick}
                                      handleUpdateClick={handleUpdateClick}
                                      updatedFields={updatedFields}
                                      accountOverviewDetails={
                                        leadOverviewDetails
                                      }
                                      showType={showType}
                                      setShowType={setShowType}
                                    />
                                  </div>
                                ) : field.type === "dropdown" ? (
                                  <div
                                    className="position-relative d-flex w-100"
                                    style={{ alignItems: "center" }}
                                    onClick={() => {
                                      handleEditClick(fieldValue);
                                    }}
                                  >
                                    {editingFields[fieldValue] &&
                                    showType === fieldValue ? (
                                      <Select
                                        value={updatedFields[fieldValue]}
                                        // value={accountInfoDetails[fieldValue]}
                                        menuPlacement="auto"
                                        onChange={(selectedOption) =>
                                          handleChangeIndDropDown(
                                            selectedOption,
                                            fieldValue
                                          )
                                        }
                                        options={
                                          field.name === "Rating"
                                            ? ratingOptions
                                            : field.name === "Lead Status"
                                            ? leadStatusOptions
                                            : field.name === "Lead Source"
                                            ? leadSourceOptions
                                            : industryOptions
                                        }
                                        styles={fieldDropdownStyles}
                                        isSearchable={false}
                                      />
                                    ) : (
                                      <input
                                        type="text"
                                        className={`w-100 cursorPointer indvEditInput
                        ${
                          showType === fieldValue
                            ? "selectedBorder "
                            : "unselectBorder bg-transparent"
                        }`}
                                        value={
                                          leadOverviewDetails[fieldValue]?.value
                                        }
                                        name={fieldValue}
                                        onClick={(e) => {
                                          if (showType === "") {
                                            updatedFields[fieldValue] =
                                              leadOverviewDetails[fieldValue];
                                            setShowType(e.target.name);
                                            setUpdatedFields(updatedFields);
                                          }
                                          handleEditClick(fieldValue);
                                        }}
                                        // readOnly={showType !== "emailId"}
                                        readOnly={true}
                                      />
                                    )}
                                    {editingFields[fieldValue] &&
                                      showType === fieldValue && (
                                        <div className="d-flex gap-2">
                                          <span
                                            onClick={() => {
                                              setShowType("");
                                              handleUpdateClick(fieldValue);
                                            }}
                                            className="ps-2 cursorPointer"
                                          >
                                            <img
                                              src={updateIcon}
                                              alt="img"
                                              height={20}
                                              width={20}
                                            />
                                          </span>
                                          <span
                                            onClick={() => {
                                              setShowType("");
                                              // handleUpdateClick(inputType);
                                              handleCancelClick(fieldValue);
                                            }}
                                            className="cursorPointer"
                                          >
                                            <img
                                              src={cancelIcon}
                                              alt="img"
                                              height={20}
                                              width={20}
                                            />
                                          </span>
                                        </div>
                                      )}
                                  </div>
                                ) : field.type === "readOnly" ? (
                                  <div>
                                    <span className="d-flex flex-column">
                                      <span>
                                        {
                                          leadOverviewDetails[
                                            field.name === "Created By"
                                              ? "createdBy"
                                              : "modifiedBy"
                                          ]
                                        }
                                      </span>
                                      <span className="small opacity-75">
                                        {
                                          leadOverviewDetails[
                                            field.name === "Created By"
                                              ? "createdDate"
                                              : "modifiedDate"
                                          ]
                                        }
                                      </span>
                                    </span>
                                  </div>
                                ) : (
                                  <div>
                                    {field.type === "checkbox" ? (
                                      <EditableCheckbox
                                        type="emailOptOut"
                                        checked={
                                          leadOverviewDetails.emailOptOut
                                        }
                                        // checked={emailOptOut}
                                        handleChange={() => {
                                          // setEmailOptOut(!emailOptOut);
                                          setLeadOverviewDetails(
                                            (prevDetails) => ({
                                              ...prevDetails,
                                              emailOptOut:
                                                !leadOverviewDetails.emailOptOut,
                                            })
                                          );
                                        }}
                                        handleEditClick={handleEditClick}
                                        handleUpdateClick={handleUpdateClick}
                                        showType={showType}
                                        setShowType={setShowType}
                                      />
                                    ) : (
                                      <div className="position-relative">
                                        <div
                                          className="d-flex  position-relative"
                                          onClick={(e) => {
                                            // setIsLeadForm(false);
                                            handleOwnerInputClick(e);
                                            setSelectedOwnerItem(selectedItem);
                                            setShowModalType("changeOwner");
                                            setListVisible(false);
                                          }}
                                        >
                                          <input
                                            type="text"
                                            className="w-100 ps-1 cursorPointer  indvEditInput border-0 indivOwnerInput"
                                            value={selectedItem?.name}
                                            readOnly
                                          />
                                          {/* <span
                                        className="position-absolute cursorPointer"
                                        style={{ right: "0.8rem" }}
                                        onClick={() => setSelectedItem("")}
                                      >
                                        <img
                                          src={listVisible ? up : down}
                                          alt="img"
                                          height={20}
                                          width={20}
                                        />
                                      </span> */}
                                          {/* <span
                                        className="position-absolute end-0"
                                        style={{ marginRight: "-1.6rem" }}
                                      >
                                        <span
                                          className="cursorPointer pb-1 ps-1"
                                          onClick={() => {
                                            setSelectedOwnerItem(selectedItem);
                                            setShowModalType("changeOwner");
                                            setListVisible(false);
                                          }}
                                        >
                                          <img
                                            src={userSearch}
                                            alt="img"
                                            width={20}
                                            height={20}
                                            // className="mb-2 ms-1"
                                          />
                                        </span>
                                      </span> */}
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                )}
                              </div>
                            </div>
                          ) : null;
                        })}
                      </div>
                    </div>

                    {/*------ lead Information Conatiner -----------*/}

                    <div className="lead_Information_mainContainer commonContainer_bg     rounded-2  d-flex flex-column my-2 py-2">
                      <h5 className="w-100 text-primary ps-3 pt-1">
                        Lead Information
                      </h5>

                      <div className="leadInformation_content_container d-flex justify-content-between pe-4">
                        <div className="leadContact_container d-flex gap-2 w-100 ps-2 pe-5">
                          <div className="w-50">
                            <div className="d-flex justify-content-between align-items-center my-2 w-100">
                              <div className="opacity-75 p-2 w-75">
                                Lead Owner
                              </div>
                              <div className="w-100">
                                <div className="position-relative">
                                  <div
                                    className="d-flex input-with-clear position-relative"
                                    onClick={(e) => {
                                      // setIsLeadForm(true);
                                      handleOwnerInputClick(e);
                                      setShowModalType("changeOwner");
                                      setSelectedOwnerItem(selectedItem);
                                      setListVisible(false);
                                    }}
                                  >
                                    <input
                                      type="text"
                                      className="w-100 ps-1 cursorPointer  indvEditInput border-0 "
                                      value={selectedItem?.name}
                                      readOnly
                                    />
                                    {/* <span
                                  className="position-absolute cursorPointer"
                                  style={{ right: "0.8rem" }}
                                  onClick={() => setIsLeadForm(false)}
                                >
                                  <img
                                    src={listVisible ? up : down}
                                    alt="img"
                                    height={20}
                                    width={20}
                                    className="mb-2"
                                  />
                                </span> */}
                                    {/* <span
                                  className="position-absolute end-0"
                                  style={{ marginRight: "-2.2rem" }}
                                >
                                  <span
                                    className="ps-5 cursorPointer"
                                    onClick={() => {
                                      setSelectedOwnerItem(selectedItem);
                                      setShowModalType("changeOwner");
                                      setListVisible(false);
                                    }}
                                    style={{ zIndex: "10" }}
                                  >
                                    <img
                                      src={userSearch}
                                      alt="img"
                                      width={20}
                                      height={20}
                                      className="mb-2 ms-1"
                                    />
                                  </span>
                                </span> */}
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="d-flex justify-content-between align-items-center my-2 w-100">
                              <div className="opacity-75 p-2 w-75">Email</div>
                              <div className="w-100">
                                <EditableInput
                                  inputType="leadInfoEmail"
                                  handleIndividualInputChange={
                                    handleIndividualInputChange
                                  }
                                  editingFields={editingFields}
                                  handleEditClick={handleEditClick}
                                  handleCancelClick={handleCancelClick}
                                  handleUpdateClick={handleUpdateClick}
                                  updatedFields={updatedFields}
                                  accountOverviewDetails={leadOverviewDetails}
                                  showType={showType}
                                  setShowType={setShowType}
                                />
                              </div>
                            </div>
                            <div className="d-flex justify-content-between align-items-center my-2 w-100">
                              <div className="opacity-75 p-2 w-75">
                                Phone Number
                              </div>
                              <div className="w-100">
                                <EditableInput
                                  inputType="leadPhone"
                                  handleIndividualInputChange={
                                    handleIndividualInputChange
                                  }
                                  editingFields={editingFields}
                                  handleEditClick={handleEditClick}
                                  handleCancelClick={handleCancelClick}
                                  handleUpdateClick={handleUpdateClick}
                                  updatedFields={updatedFields}
                                  accountOverviewDetails={leadOverviewDetails}
                                  showType={showType}
                                  setShowType={setShowType}
                                />
                              </div>
                            </div>
                            <div className="d-flex justify-content-between align-items-center my-2 w-100">
                              <div className="opacity-75 p-2 w-75">Mobile</div>
                              <div className="w-100">
                                <EditableInput
                                  inputType="leadMobile"
                                  handleIndividualInputChange={
                                    handleIndividualInputChange
                                  }
                                  editingFields={editingFields}
                                  handleEditClick={handleEditClick}
                                  handleCancelClick={handleCancelClick}
                                  handleUpdateClick={handleUpdateClick}
                                  updatedFields={updatedFields}
                                  accountOverviewDetails={leadOverviewDetails}
                                  showType={showType}
                                  setShowType={setShowType}
                                  maxLength={10}
                                />
                              </div>
                            </div>
                            <div className="d-flex justify-content-between align-items-center my-2 w-100">
                              <div className="opacity-75 p-2 w-75">
                                Lead Source
                              </div>
                              <div className="w-100">
                                {editingFields["leadInfoLeadSource"] &&
                                showType === "leadInfoLeadSource" ? (
                                  <div className="position-relative">
                                    <Select
                                      value={updatedFields.leadInfoLeadSource}
                                      menuPlacement="auto"
                                      onChange={(selectedOption) =>
                                        handleChangeIndDropDown(
                                          selectedOption,
                                          "leadInfoLeadSource"
                                        )
                                      }
                                      options={leadSourceOptions}
                                      styles={fieldDropdownStyles}
                                      isSearchable={false}
                                    />
                                    {editingFields["leadInfoLeadSource"] &&
                                      showType === "leadInfoLeadSource" && (
                                        <div
                                          className="position-absolute top-0"
                                          style={{ right: "-4rem" }}
                                        >
                                          <div className="d-flex gap-1">
                                            <span
                                              onClick={() => {
                                                setShowType("");
                                                handleUpdateClick(
                                                  "leadInfoLeadSource"
                                                );
                                              }}
                                              className="ps-2 cursorPointer"
                                            >
                                              <img
                                                src={updateIcon}
                                                alt="img"
                                                height={20}
                                                width={20}
                                              />
                                            </span>
                                            <span
                                              onClick={() => {
                                                setShowType("");
                                                // handleUpdateClick(inputType);
                                                handleCancelClick(
                                                  "leadInfoLeadSource"
                                                );
                                              }}
                                              className="cursorPointer"
                                            >
                                              <img
                                                src={cancelIcon}
                                                alt="img"
                                                height={20}
                                                width={20}
                                              />
                                            </span>
                                          </div>
                                        </div>
                                      )}
                                  </div>
                                ) : (
                                  <input
                                    type="text"
                                    className={`mt-1 w-100 cursorPointer indvEditInput
                            ${
                              showType === "leadInfoLeadSource"
                                ? "selectedBorder "
                                : "unselectBorder bg-transparent"
                            }`}
                                    value={
                                      leadOverviewDetails?.leadSource?.value
                                    }
                                    name={"leadInfoLeadSource"}
                                    onClick={(e) => {
                                      if (showType === "") {
                                        updatedFields.leadInfoLeadSource =
                                          leadOverviewDetails.leadSource;
                                        setShowType(e.target.name);
                                        setUpdatedFields(updatedFields);
                                      }
                                      handleEditClick("leadInfoLeadSource");
                                    }}
                                    // readOnly={showType !== "emailId"}
                                    readOnly={true}
                                  />
                                )}
                              </div>
                            </div>

                            <div className="d-flex justify-content-between align-items-center my-2 w-100">
                              <div className="opacity-75 p-2 w-75">
                                Industry Type
                              </div>
                              <div className="w-100">
                                {editingFields["leadInfoIndustryType"] &&
                                showType === "leadInfoIndustryType" ? (
                                  <div className="position-relative">
                                    <Select
                                      value={updatedFields.leadInfoIndustryType}
                                      menuPlacement="auto"
                                      onChange={(selectedOption) =>
                                        handleChangeIndDropDown(
                                          selectedOption,
                                          "leadInfoIndustryType"
                                        )
                                      }
                                      options={industryOptions}
                                      styles={fieldDropdownStyles}
                                      isSearchable={false}
                                    />
                                    {editingFields["leadInfoIndustryType"] &&
                                      showType === "leadInfoIndustryType" && (
                                        <div
                                          className="position-absolute top-0"
                                          style={{ right: "-4rem" }}
                                        >
                                          <div className="d-flex gap-1">
                                            <span
                                              onClick={() => {
                                                setShowType("");
                                                handleUpdateClick(
                                                  "leadInfoIndustryType"
                                                );
                                              }}
                                              className="ps-2 cursorPointer"
                                            >
                                              <img
                                                src={updateIcon}
                                                alt="img"
                                                height={20}
                                                width={20}
                                              />
                                            </span>
                                            <span
                                              onClick={() => {
                                                setShowType("");
                                                // handleUpdateClick(inputType);
                                                handleCancelClick(
                                                  "leadInfoIndustryType"
                                                );
                                              }}
                                              className="cursorPointer"
                                            >
                                              <img
                                                src={cancelIcon}
                                                alt="img"
                                                height={20}
                                                width={20}
                                              />
                                            </span>
                                          </div>
                                        </div>
                                      )}
                                  </div>
                                ) : (
                                  <input
                                    type="text"
                                    className={`mt-2 w-100 cursorPointer indvEditInput
                            ${
                              showType === "leadInfoIndustryType"
                                ? "selectedBorder "
                                : "unselectBorder bg-transparent"
                            }`}
                                    value={
                                      leadOverviewDetails?.industryType?.value
                                    }
                                    name={"leadInfoIndustryType"}
                                    onClick={(e) => {
                                      if (showType === "") {
                                        updatedFields.leadInfoIndustryType =
                                          leadOverviewDetails.industryType;
                                        setShowType(e.target.name);
                                        setUpdatedFields(updatedFields);
                                      }
                                      handleEditClick("leadInfoIndustryType");
                                    }}
                                    // readOnly={showType !== "emailId"}
                                    readOnly={true}
                                  />
                                )}
                              </div>
                            </div>
                            <div className="d-flex justify-content-between align-items-center my-2 w-100">
                              <div className="opacity-75 p-2 w-75">
                                Annual Revenue
                              </div>
                              <div className="w-100">
                                <EditableInput
                                  inputType="leadAnnualRevenue"
                                  handleIndividualInputChange={
                                    handleIndividualInputChange
                                  }
                                  editingFields={editingFields}
                                  handleEditClick={handleEditClick}
                                  handleCancelClick={handleCancelClick}
                                  handleUpdateClick={handleUpdateClick}
                                  updatedFields={updatedFields}
                                  accountOverviewDetails={leadOverviewDetails}
                                  showType={showType}
                                  setShowType={setShowType}
                                />
                              </div>
                            </div>

                            <div className="d-flex justify-content-between align-items-center my-2 w-100">
                              <div className="opacity-75 p-2 w-75">
                                Email Opt Out
                              </div>
                              <div className="w-100">
                                {/* <input
                                  type="checkbox"
                                  name="emailOptOut"
                                  checked={emailOptOut}
                                  onClick={() => {
                                    setEmailOptOut(!emailOptOut);
                                  }}
                                /> */}
                                <div className="d-flex editInput_container">
                                  {showType !== "leadInfoEmailOptOut" ? (
                                    <span
                                      className="right_tick cursorPointer"
                                      onClick={() => {
                                        handleEditClick("leadInfoEmailOptOut");
                                        setShowType("leadInfoEmailOptOut");
                                      }}
                                    >
                                      {leadOverviewDetails.emailOptOut
                                        ? tick
                                        : "-"}
                                    </span>
                                  ) : (
                                    // <input
                                    //   type="checkbox"
                                    //   checked={
                                    //     updatedFields.leadInfoEmailOptOut
                                    //   }
                                    //   // checked={leadOverviewDetails.emailOptOut}
                                    //   // onChange={(e) => {
                                    //   //   setUpdatedFields((prevFields) => ({
                                    //   //     ...prevFields,
                                    //   //     emailOptOut: e.target.checked,
                                    //   //   }));
                                    //   // }}
                                    //   // onClick={() => {
                                    //   //   handleEditClick("emailOptOut");
                                    //   // }}

                                    //   onChange={handleChange}
                                    //   onClick={() => {
                                    //     if (showType === "") {
                                    //       setShowType("leadInfoEmailOptOut");
                                    //     }
                                    //     handleEditClick("leadInfoEmailOptOut");
                                    //   }}
                                    //   readOnly={
                                    //  /   !editingFields?.leadInfoEmailOptOut
                                    //   }
                                    // />
                                    <EditableCheckbox
                                      // checked={leadOverviewDetails.emailOptOut}
                                      type="leadInfoEmailOptOut"
                                      checked={
                                        updatedFields.leadInfoEmailOptOut
                                      }
                                      handleChange={(e) => {
                                        // setEmailOptOut(!leadInfoEmailOptOut);
                                        setUpdatedFields((prevDetails) => ({
                                          ...prevDetails,
                                          leadInfoEmailOptOut: e.target.checked,
                                        }));
                                      }}
                                      handleEditClick={handleEditClick}
                                      handleUpdateClick={handleUpdateClick}
                                      showType={showType}
                                      setShowType={setShowType}
                                    />
                                  )}
                                  {/* {showType === "leadInfoEmailOptOut" && (
                                    <div className="d-flex gap-2">
                                      <span
                                        onClick={() => {
                                          setShowType("");
                                          handleUpdateClick(
                                            "leadInfoEmailOptOut"
                                          );
                                        }}
                                        className="ps-2 cursorPointer"
                                      >
                                        <img
                                          src={updateIcon}
                                          alt="Update"
                                          height={20}
                                          width={20}
                                        />
                                      </span>
                                      <span
                                        onClick={() => {
                                          setShowType("");
                                          handleCancelClick(
                                            "leadInfoEmailOptOut"
                                          );
                                        }}
                                        className="cursorPointer"
                                      >
                                        <img
                                          src={cancelIcon}
                                          alt="Cancel"
                                          height={20}
                                          width={20}
                                        />
                                      </span>
                                    </div>
                                  )} */}
                                </div>
                              </div>
                            </div>
                            <div className="d-flex justify-content-between align-items-center my-2 w-100">
                              <div className="opacity-75 p-2 w-75">
                                Modified By
                              </div>
                              <div className="w-100 d-flex flex-column">
                                <span className="pb-0">
                                  {" "}
                                  {leadOverviewDetails.modifiedBy}
                                </span>
                                <span className="opacity-50 small pt-0">
                                  {leadOverviewDetails.modifiedDate}
                                </span>
                              </div>
                            </div>
                            <div className="d-flex justify-content-between align-items-center my-2 w-100">
                              <div className="opacity-75 p-2 w-75">Website</div>
                              <div className="w-100">
                                <EditableInput
                                  inputType="leadInfoWebsite"
                                  handleIndividualInputChange={
                                    handleIndividualInputChange
                                  }
                                  editingFields={editingFields}
                                  handleEditClick={handleEditClick}
                                  handleCancelClick={handleCancelClick}
                                  handleUpdateClick={handleUpdateClick}
                                  updatedFields={updatedFields}
                                  accountOverviewDetails={leadOverviewDetails}
                                  showType={showType}
                                  setShowType={setShowType}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="w-50 ps-5 pe-0">
                            <div className="d-flex justify-content-between align-items-center my-2 w-100">
                              <div className="opacity-75 p-2 w-100">
                                Company
                              </div>
                              <div className="w-100">
                                <EditableInput
                                  inputType="companyName"
                                  handleIndividualInputChange={
                                    handleIndividualInputChange
                                  }
                                  editingFields={editingFields}
                                  handleEditClick={handleEditClick}
                                  handleCancelClick={handleCancelClick}
                                  handleUpdateClick={handleUpdateClick}
                                  updatedFields={updatedFields}
                                  accountOverviewDetails={leadOverviewDetails}
                                  showType={showType}
                                  setShowType={setShowType}
                                />
                              </div>
                            </div>
                            <div className="d-flex justify-content-between align-items-center my-2 w-100">
                              <div className="opacity-75 p-2 w-100">
                                Lead Name
                              </div>
                              <div className="w-100">
                                {leadOverviewDetails.leadName}
                              </div>
                            </div>
                            <div className="d-flex justify-content-between align-items-center my-2 w-100">
                              <div className="opacity-75 p-2 w-100">Fax</div>
                              <div className="w-100">
                                <EditableInput
                                  inputType="leadInfoFax"
                                  handleIndividualInputChange={
                                    handleIndividualInputChange
                                  }
                                  editingFields={editingFields}
                                  handleEditClick={handleEditClick}
                                  handleCancelClick={handleCancelClick}
                                  handleUpdateClick={handleUpdateClick}
                                  updatedFields={updatedFields}
                                  accountOverviewDetails={leadOverviewDetails}
                                  showType={showType}
                                  setShowType={setShowType}
                                />
                              </div>
                            </div>

                            <div className="d-flex justify-content-between align-items-center my-2 w-100">
                              <div className="opacity-75 p-2 w-100">
                                Lead Status
                              </div>
                              {editingFields["leadInfoLeadStatus"] &&
                              showType === "leadInfoLeadStatus" ? (
                                <div className="position-relative w-100">
                                  <Select
                                    value={updatedFields["leadInfoLeadStatus"]}
                                    menuPlacement="auto"
                                    onChange={(selectedOption) => {
                                      setClickedDropdownObj(selectedOption);
                                      setUpdatedFields((prevFields) => ({
                                        ...prevFields,
                                        leadInfoLeadStatus: selectedOption,
                                      }));
                                    }}
                                    options={leadStatusOptions}
                                    styles={fieldDropdownStyles}
                                    isSearchable={false}
                                  />
                                  {editingFields["leadInfoLeadStatus"] &&
                                    showType === "leadInfoLeadStatus" && (
                                      <div
                                        className="position-absolute end-0 top-0"
                                        style={{ marginRight: "-3.6rem" }}
                                      >
                                        <div className="d-flex">
                                          <span
                                            onClick={(e) => {
                                              e.stopPropagation();
                                              setShowType("");
                                              handleUpdateClick(
                                                "leadInfoLeadStatus"
                                              );
                                            }}
                                            className="cursorPointer"
                                          >
                                            <img
                                              src={updateIcon}
                                              alt="img"
                                              height={20}
                                              width={20}
                                            />
                                          </span>
                                          <span
                                            onClick={(e) => {
                                              e.stopPropagation();
                                              setShowType("");
                                              handleCancelClick(
                                                "leadInfoLeadStatus"
                                              );
                                            }}
                                            className="cursorPointer pe-1"
                                          >
                                            <img
                                              src={cancelIcon}
                                              alt="img"
                                              height={20}
                                              width={20}
                                            />
                                          </span>
                                        </div>
                                      </div>
                                    )}
                                </div>
                              ) : (
                                <input
                                  type="text"
                                  readOnly
                                  value={
                                    updatedFields["leadInfoLeadStatus"]?.value
                                  }
                                  // onClick={() => {
                                  //   handleEditClick("leadStatus");
                                  // }}
                                  onClick={() => {
                                    if (showType === "") {
                                      updatedFields.leadInfoLeadStatus =
                                        leadOverviewDetails.leadStatus;
                                      setShowType("leadInfoLeadStatus");
                                      setUpdatedFields(updatedFields);
                                    }
                                    handleEditClick("leadInfoLeadStatus");
                                  }}
                                  name={"leadInfoLeadStatus"}
                                  className={`w-100 mt-2 pt-1 cursorPointer editInput_container 
                                ${
                                  showType === "leadInfoLeadStatus"
                                    ? "selectedBorder "
                                    : "unselectBorder bg-transparent"
                                }`}
                                />
                              )}
                            </div>
                            <div className="d-flex justify-content-between align-items-center my-2 w-100">
                              <div className="opacity-75 p-2 w-100">
                                No. of Employees
                              </div>
                              <div className="w-100">
                                <EditableInput
                                  inputType="leadInfoNoOfEmployees"
                                  handleIndividualInputChange={
                                    handleIndividualInputChange
                                  }
                                  editingFields={editingFields}
                                  handleEditClick={handleEditClick}
                                  handleCancelClick={handleCancelClick}
                                  handleUpdateClick={handleUpdateClick}
                                  updatedFields={updatedFields}
                                  accountOverviewDetails={leadOverviewDetails}
                                  showType={showType}
                                  setShowType={setShowType}
                                />
                              </div>
                            </div>
                            <div className="d-flex justify-content-between align-items-center my-2 w-100">
                              <div className="opacity-75 p-2 w-100">Rating</div>
                              {editingFields["leadInfoRating"] &&
                              showType === "leadInfoRating" ? (
                                <div className="position-relative w-100">
                                  <Select
                                    value={updatedFields.leadInfoRating}
                                    menuPlacement="auto"
                                    onChange={(selectedOption) =>
                                      handleChangeIndDropDown(
                                        selectedOption,
                                        "leadInfoRating"
                                      )
                                    }
                                    options={ratingOptions}
                                    styles={fieldDropdownStyles}
                                    isSearchable={false}
                                  />
                                  {editingFields["leadInfoRating"] &&
                                    showType === "leadInfoRating" && (
                                      <div
                                        className="position-absolute top-0"
                                        style={{ right: "-3.6rem" }}
                                      >
                                        <div className="d-flex">
                                          <span
                                            onClick={() => {
                                              setShowType("");
                                              handleUpdateClick(
                                                "leadInfoRating"
                                              );
                                            }}
                                            className="cursorPointer"
                                          >
                                            <img
                                              src={updateIcon}
                                              alt="img"
                                              height={20}
                                              width={20}
                                            />
                                          </span>
                                          <span
                                            onClick={() => {
                                              setShowType("");
                                              // handleUpdateClick(inputType);
                                              handleCancelClick(
                                                "leadInfoRating"
                                              );
                                            }}
                                            className="cursorPointerm"
                                          >
                                            <img
                                              src={cancelIcon}
                                              alt="img"
                                              height={20}
                                              width={20}
                                            />
                                          </span>
                                        </div>
                                      </div>
                                    )}
                                </div>
                              ) : (
                                <input
                                  type="text"
                                  className={`w-100 mt-2 pt-1 cursorPointer indvEditInput
                            ${
                              showType === "leadInfoRating"
                                ? "selectedBorder "
                                : "unselectBorder bg-transparent"
                            }`}
                                  value={leadOverviewDetails?.rating?.value}
                                  name={"leadInfoRating"}
                                  onClick={(e) => {
                                    if (showType === "") {
                                      updatedFields.leadInfoRating =
                                        leadOverviewDetails.rating;
                                      setShowType(e.target.name);
                                      setUpdatedFields(updatedFields);
                                    }
                                    handleEditClick("leadInfoRating");
                                  }}
                                  readOnly={true}
                                />
                              )}
                            </div>
                            <div className="d-flex justify-content-between align-items-center my-2 w-100">
                              <div className="opacity-75 p-2 w-100">
                                Skype ID
                              </div>
                              <div className="w-100">
                                <EditableInput
                                  inputType="leadInfoSkypeId"
                                  handleIndividualInputChange={
                                    handleIndividualInputChange
                                  }
                                  editingFields={editingFields}
                                  handleEditClick={handleEditClick}
                                  handleCancelClick={handleCancelClick}
                                  handleUpdateClick={handleUpdateClick}
                                  updatedFields={updatedFields}
                                  accountOverviewDetails={leadOverviewDetails}
                                  showType={showType}
                                  setShowType={setShowType}
                                />
                              </div>
                            </div>
                            <div className="d-flex justify-content-between align-items-center my-2 w-100">
                              <div className="opacity-75 p-2 w-100">
                                Twitter
                              </div>
                              <div className="w-100">
                                <EditableInput
                                  inputType="leadInfotwitter"
                                  handleIndividualInputChange={
                                    handleIndividualInputChange
                                  }
                                  editingFields={editingFields}
                                  handleEditClick={handleEditClick}
                                  handleCancelClick={handleCancelClick}
                                  handleUpdateClick={handleUpdateClick}
                                  updatedFields={updatedFields}
                                  accountOverviewDetails={leadOverviewDetails}
                                  showType={showType}
                                  setShowType={setShowType}
                                />
                              </div>
                            </div>
                            <div className="d-flex justify-content-between align-items-center my-2 w-100">
                              <div className="opacity-75 p-2 w-100">
                                Secondary Email
                              </div>
                              <div className="w-100">
                                <EditableInput
                                  inputType="leadInfoSecondaryEmailId"
                                  handleIndividualInputChange={
                                    handleIndividualInputChange
                                  }
                                  editingFields={editingFields}
                                  handleEditClick={handleEditClick}
                                  handleCancelClick={handleCancelClick}
                                  handleUpdateClick={handleUpdateClick}
                                  updatedFields={updatedFields}
                                  accountOverviewDetails={leadOverviewDetails}
                                  showType={showType}
                                  setShowType={setShowType}
                                />
                              </div>
                            </div>

                            <div className="d-flex justify-content-between align-items-center my-2 w-100">
                              <div className="opacity-75 p-2 w-100">
                                Created By
                              </div>
                              <div className="w-100">
                                <span className="d-flex flex-column px-0">
                                  <span className="pb-0 px-0">
                                    {leadOverviewDetails.createdBy}
                                  </span>
                                  <span className="opacity-50 small pt-0 px-0">
                                    {leadOverviewDetails.createdDate}
                                  </span>
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <h5 className="w-100 text-primary ps-3 pt-1 mb-0">
                        Address Information
                      </h5>

                      <div className="leadInformation_content_container d-flex justify-content-between ">
                        <div className="leadContact_container d-flex gap-2 w-100 ps-2 pe-5">
                          <div className="w-50">
                            <div className="d-flex justify-content-between align-items-center mb-2 w-100">
                              <div className="opacity-75 p-2 w-75">Country</div>
                              <div className="w-100">
                                <EditableInput
                                  inputType="leadInfoCountry"
                                  handleIndividualInputChange={
                                    handleIndividualInputChange
                                  }
                                  editingFields={editingFields}
                                  handleEditClick={handleEditClick}
                                  handleCancelClick={handleCancelClick}
                                  handleUpdateClick={handleUpdateClick}
                                  updatedFields={updatedFields}
                                  accountOverviewDetails={leadOverviewDetails}
                                  showType={showType}
                                  setShowType={setShowType}
                                />
                              </div>
                            </div>
                            <div className="d-flex justify-content-between align-items-center my-2 w-100">
                              <div className="opacity-75 p-2 w-75">City</div>
                              <div className="w-100">
                                <EditableInput
                                  inputType="leadInfoCity"
                                  handleIndividualInputChange={
                                    handleIndividualInputChange
                                  }
                                  editingFields={editingFields}
                                  handleEditClick={handleEditClick}
                                  handleCancelClick={handleCancelClick}
                                  handleUpdateClick={handleUpdateClick}
                                  updatedFields={updatedFields}
                                  accountOverviewDetails={leadOverviewDetails}
                                  showType={showType}
                                  setShowType={setShowType}
                                />
                              </div>
                            </div>
                            <div className="d-flex justify-content-between align-items-center my-2 w-100">
                              <div className="opacity-75 p-2 w-75">
                                Zip Code
                              </div>
                              <div className="w-100">
                                <EditableInput
                                  inputType="leadInfoZipCode"
                                  handleIndividualInputChange={
                                    handleIndividualInputChange
                                  }
                                  editingFields={editingFields}
                                  handleEditClick={handleEditClick}
                                  handleCancelClick={handleCancelClick}
                                  handleUpdateClick={handleUpdateClick}
                                  updatedFields={updatedFields}
                                  accountOverviewDetails={leadOverviewDetails}
                                  showType={showType}
                                  setShowType={setShowType}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="w-50 ps-5 pe-0">
                            <div className="d-flex justify-content-between align-items-center mb-2 w-100">
                              <div className="opacity-75 py-2 w-75">State</div>
                              <div className="w-100 px-4">
                                <EditableInput
                                  inputType="leadInfoState"
                                  handleIndividualInputChange={
                                    handleIndividualInputChange
                                  }
                                  editingFields={editingFields}
                                  handleEditClick={handleEditClick}
                                  handleCancelClick={handleCancelClick}
                                  handleUpdateClick={handleUpdateClick}
                                  updatedFields={updatedFields}
                                  accountOverviewDetails={leadOverviewDetails}
                                  showType={showType}
                                  setShowType={setShowType}
                                />
                              </div>
                            </div>
                            <div className="d-flex justify-content-between align-items-center my-2 w-100">
                              <div className="opacity-75 py-2 w-75">Street</div>
                              <div className="w-100 px-4">
                                <EditableInput
                                  inputType="leadInfoStreet"
                                  handleIndividualInputChange={
                                    handleIndividualInputChange
                                  }
                                  editingFields={editingFields}
                                  handleEditClick={handleEditClick}
                                  handleCancelClick={handleCancelClick}
                                  handleUpdateClick={handleUpdateClick}
                                  updatedFields={updatedFields}
                                  accountOverviewDetails={leadOverviewDetails}
                                  showType={showType}
                                  setShowType={setShowType}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <h5 className="w-100 text-primary mt-2 ps-3">
                        Description
                      </h5>
                      <div className="d-flex gap-2 px-2">
                        <span className="opacity-75 px-2 py-2">
                          Description
                        </span>
                        <EditableInput
                          fieldType="text"
                          inputType="description"
                          handleIndividualInputChange={
                            handleIndividualInputChange
                          }
                          editingFields={editingFields}
                          handleEditClick={handleEditClick}
                          handleCancelClick={handleCancelClick}
                          handleUpdateClick={handleUpdateClick}
                          updatedFields={updatedFields}
                          accountOverviewDetails={leadOverviewDetails}
                          showType={showType}
                          setShowType={setShowType}
                          isTextarea={true}
                        />
                      </div>
                    </div>
                    {notePermission === "TRUE" && (
                      <div
                        id="notesSection"
                        className="lead_Information_mainContainer commonContainer_bg rounded-2  d-flex flex-column py-2 mt-2"
                      >
                        <div>
                          <Note
                            note={note}
                            addTitle={addTitle}
                            showButtons={showButtons}
                            setShowButtons={setShowButtons}
                            textFile={selectedNoteFiles}
                            showTitleNote={showTitleNote}
                            setShowTitleNote={setShowTitleNote}
                            handleTextAreaClick={handleTextAreaClick}
                            handleSaveButton={insertNote}
                            handleAddTitleClick={handleAddTitleClick}
                            setNoteTitle={setNoteTitle}
                            noteInfo={noteInfo}
                            selectRecent={selectRecent}
                            handleSelectRecent={handleSelectRecent}
                            handleDeleteClick={deleteNoteDetails}
                            leadId={leadId}
                            handleUpdateClick={updateNote}
                            setUpdateNotes={setUpdateNotes}
                            updateNotes={updateNotes}
                            setEditNote={setEditNote}
                            editNote={editNote}
                            handleChangeNoteTitle={handleChangeNoteTitle}
                            handleChangeNoteDescription={
                              handleChangeNoteDescription
                            }
                            setAddTitle={setAddTitle}
                            setNote={setNote}
                            moduleType={"Lead"}
                            recordName={leadOverviewDetails?.leadName}
                            selectedNoteFiles={selectedNoteFiles}
                            setSelectedNoteFiles={setSelectedNoteFiles}
                            onChildDataChange={handleChildDataChange}
                            noteDeleteConfirmation={noteDeleteConfirmation}
                            setNoteDeleteConfirmation={
                              setNoteDeleteConfirmation
                            }
                          />

                          {showTitleNote && (
                            <textarea
                              rows={2}
                              className="notesClass px-2"
                              placeholder="Add note Title"
                              value={noteTitle}
                              onChange={(e) => setNoteTitle(e.target.value)}
                              style={{ width: "95%", marginTop: "10px" }}
                            />
                          )}
                        </div>
                      </div>
                    )}

                    <div
                      id="product"
                      className="lead_Information_mainContainer commonContainer_bg rounded-2  d-flex flex-column py-2 mt-4"
                    >
                      <Product
                        module={"Leads"}
                        productsList={productsList || []}
                        setProductsList={setProductsList}
                        assignContactID={leadId}
                        handleAssignModule={handleAssignModule}
                        selectedProductId={selectedProductId}
                        setSelectedProductId={setSelectedProductId}
                        handleAssignProductModule={() =>
                          handleAssignModule(selectedProductId, "PRODUCTS")
                        }
                        handledeleteModule={(productId) =>
                          handledeleteModule(productId)
                        }
                        apiLoader={apiLoader}
                      />
                    </div>

                    <div
                      id="campaign"
                      className="lead_Information_mainContainer commonContainer_bg rounded-2  d-flex flex-column py-2 mt-4"
                    >
                      <CampaignsList
                        module={"Leads"}
                        campaignList={campaignList || []}
                        assignContactID={leadId}
                        handleAssignModuleCampaigns={
                          handleAssignModuleCampaigns
                        }
                        selectedCampaignId={selectedCampaignId}
                        setSelectedCampaignId={setSelectedCampaignId}
                        handleAssignCampaignModule={() =>
                          handleAssignModuleCampaigns(
                            selectedCampaignId,
                            "CAMPAIGNS"
                          )
                        }
                        handledeleteModuleCampaigns={(campaignId) =>
                          handledeleteModuleCampaigns(campaignId)
                        }
                        apiLoader={apiLoader}
                      />
                    </div>
                    <div
                      id="invited"
                      className="lead_Information_mainContainer commonContainer_bg rounded-2  d-flex flex-column py-2 mt-4"
                    >
                      <InvitedMeetingList invitedMeetings={invitedMeetings} />
                    </div>

                    <div
                      id="openActivity"
                      className="lead_Information_mainContainer commonContainer_bg rounded-2  d-flex flex-column py-2 mt-4"
                    >
                      <OpenActivity
                        openActivities={openActivities}
                        getOverview={() => leadOverviewDetail(leadId)}
                        openActivitiesCalls={openActivitiesCalls}
                        openActivitiesMeetings={openActivitiesMeetings}
                        moduleName="lead"
                        selectedData={selectedLead}
                      />
                    </div>

                    <div
                      id="closeActivity"
                      className="lead_Information_mainContainer commonContainer_bg rounded-2  d-flex flex-column py-2 mt-4"
                    >
                      <CloseActivity
                        closeActivities={closeActivities}
                        getOverview={() => leadOverviewDetail(leadId)}
                        closeActivitiesCalls={closeActivitiesCalls}
                        closeActivitiesMeetings={closeActivitiesMeetings}
                        // moduleName="lead"
                        // selectedData={selectedLead}
                      />
                    </div>
                  </div>
                ) : (
                  <div
                    className={`timeline_main_container d-flex flex-column gap-4 ${
                      isShrinked ? "w-100" : "w-75"
                    }`}
                  >
                    {/* <div className="upcomingAction_container w-100 flex-column  py-2  d-flex  commonContainer_bg">
                      <div className="header d-flex gap-2 ps-2">
                        <span>
                          <img
                            src={hierachy}
                            alt="img"
                            height={20}
                            width={20}
                          />
                        </span>
                        <span>Upcoming Actions</span>
                      </div>
                      <div className="horizontal_line w-100 opacity-25 mx-1"></div>

                      <div
                        className="d-flex justify-content-center align-items-center "
                        style={{ height: "25vh" }}
                      >
                        <h4 className="opacity-75 ">No upcoming actions</h4>
                      </div>
                    </div> */}

                    <div className="w-100 historyMain_Container flex-column   d-flex  commonContainer_bg ">
                      <div className="header d-flex gap-2 px-2 py-2 align-items-center ">
                        <span>
                          <img src={history} alt="img" height={20} width={20} />
                        </span>
                        <span>History</span>
                      </div>
                      <div className="horizontal_line w-100 mx-1 mt-1"></div>
                      <div className="historyContent_mainContainer ">
                        {timeLineDataList &&
                        Object.keys(timeLineDataList).length > 0 ? (
                          Object.keys(timeLineDataList).map((date) => (
                            <div
                              className="history_container pt-3 d-flex flex-column"
                              key={date}
                            >
                              <span className="px-2 timelineBg_color border border-primary rounded-5 py-2 mx-5">
                                {date}
                              </span>
                              {timeLineDataList[date].map((item, index) => (
                                <div className="d-flex gap-2" key={index}>
                                  <span className="opacity-75 pt-5 ps-2">
                                    {item.createdTime}
                                  </span>
                                  <div className="d-flex flex-column align-items-center">
                                    <p className="vertical_line m-0 p-0"></p>
                                    <span>
                                      <img
                                        src={groupIcon2}
                                        alt="img"
                                        height={25}
                                        width={25}
                                      />
                                    </span>
                                  </div>
                                  <span className="pt-5">
                                    {`${item.tmlDescription} ${item.tmlCreatedBy} ${date}`}
                                  </span>
                                </div>
                              ))}
                            </div>
                          ))
                        ) : (
                          <div className="text-center d-flex justify-content-center align-items-center h-50 mt-2">
                            <h4>No Data available</h4>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                )}
                <Modal
                  show={showImageModal}
                  className="d-flex justify-content-center align-items-center"
                  aria-labelledby="example-custom-modal-styling-title"
                  size="lg"
                  id="commonLeadModal"
                >
                  <Modal.Header
                    closeButton
                    onClick={() => {
                      setShowImageModal(false);
                      setUploadImg(false);
                    }}
                  >
                    {leadOverviewDetails.leadImageData && (
                      <button
                        className="imageAccButtonRemove"
                        onClick={() => {
                          setShowRemoveModal(true);
                        }}
                      >
                        Remove
                      </button>
                    )}
                  </Modal.Header>
                  <Modal.Body className="ModalBody overflow-auto">
                    <div className="d-flex flex-column gap-4">
                      <div
                        className="d-flex justify-content-center"
                        // style={{ position: "relative" }}
                      >
                        <img
                          alt="upload"
                          src={
                            uploadImg
                              ? imageUrl
                              : leadOverviewDetails.leadImageData !== null
                              ? leadOverviewDetails.leadImageData
                              : leadAvatar
                          }
                          className="profilePhoto cursorPointer"
                        />
                        {leadOverviewDetails.leadImageData && (
                          <a
                            href={
                              uploadImg
                                ? imageUrl
                                : leadOverviewDetails.leadImageData
                            }
                            download="image.jpg"
                            // style={{
                            //   position: "absolute",
                            //   right: "-15px",
                            //   top: "5px",
                            //   background: "#b0b0b2",
                            //   borderRadius: "10px",
                            //   padding: "5px",
                            //   cursor: "pointer",
                            // }}
                          >
                            <img src={downloadIcon} alt="" />
                          </a>
                        )}
                      </div>

                      {/* <p
                        className="mx-auto text-center"
                        style={{ wordBreak: "break-word" }}
                      >
                        {deleteMsg}
                      </p> */}
                      {imageMsg && (
                        <span
                          className="mb-0 small erro_msg mx-auto text-center"
                          style={{ wordBreak: "break-word" }}
                        >
                          {imageMsg}
                        </span>
                      )}
                      <div className="d-flex w-100 gap-2 justify-content-center">
                        <input
                          type="file"
                          id="accountImage"
                          name="accountImage"
                          // accept="image/*"
                          accept=".png, .jpg, .jpeg, .gif"
                          className="file-control "
                          onChange={handleUploadImage}
                        />
                        <label
                          htmlFor="accountImage"
                          className="imageAccButton"
                        >
                          <span>Upload Image </span>
                        </label>
                        <button
                          className="imageAccButtonSave"
                          onClick={() => {
                            uploadImage();
                          }}
                        >
                          {imageLoader ? (
                            <Lottie
                              options={defaultOptions("white")}
                              height="24px"
                              width="36px"
                            />
                          ) : (
                            "Save"
                          )}
                        </button>
                      </div>
                    </div>
                  </Modal.Body>
                </Modal>

                <Modal
                  show={showRemoveModal}
                  className="d-flex justify-content-center align-items-center"
                  aria-labelledby="example-custom-modal-styling-title"
                  size="lg"
                  // id="commonLeadModal"
                >
                  {/* <Modal.Header
                closeButton
                onClick={() => {
                  setShowRemoveModal(false);
                  setShowImageModal(true);
                }}
              ></Modal.Header> */}
                  <Modal.Body className="ModalBody overflow-auto">
                    <div className="d-flex flex-column gap-4">
                      <p className="text-center mt-3">
                        Are you sure you want to remove?
                      </p>
                      <div className="d-flex w-100 gap-2">
                        <button
                          className="imageAccButton"
                          onClick={() => {
                            setShowRemoveModal(false);
                            setShowImageModal(true);
                          }}
                        >
                          Cancel
                        </button>
                        <button
                          className="imageAccButtonSave"
                          onClick={() => {
                            removeImage();
                          }}
                        >
                          {imageLoader ? (
                            <Lottie
                              options={defaultOptions("white")}
                              height="24px"
                              width="36px"
                            />
                          ) : (
                            "Remove"
                          )}
                        </button>
                      </div>
                    </div>
                  </Modal.Body>
                </Modal>
              </div>
            </div>
          </>
        )}
      </div>
    );
  };

  const handleColumnCheckboxChange = (name, isChecked) => {
    setSavingHeadings((prevHeadings) =>
      prevHeadings.map((heading) =>
        heading.name === name ? { ...heading, isChecked } : heading
      )
    );
  };

  const handleLeadView = () => {
    if (innerPage === "list") {
      return <>{leadHomePage()}</>;
    } else if (innerPage === "convert") {
      return <>{convertPage()}</>;
    } else if (innerPage === "create") {
      return <>{createLeadPage()}</>;
    } else if (innerPage === "overview") {
      return <>{individualLeadDetailPage()}</>;
    }
  };

  useEffect(() => {
    getAllLeadsDetails(currentPage, selectedOption);
    // setSelectedCheckboxes([])
  }, [currentPage, perPage]);

  useEffect(() => {
    // getAllDeal();
    getAllCampaignDetails();
    setInnerPage(innerPage || "list");
  }, []);

  return (
    <div
      onClick={() => {
        setListVisible(false);
        setShowHeadings(false);
        setShowSelectedFilter("");
        setSelectedField("");
        setShowOptionsLead(false);
        setParentListVisible(false);
      }}
    >
      {handleLeadView()}

      {/* Modals */}
      <Modal
        show={showModalType === "Manage Columns"}
        id="ConvertModal"
        aria-labelledby="example-custom-modal-styling-title"
        className="withdrw_modal"
      >
        <Modal.Body>
          <div className="modalClass  position-relative">
            <div className="d-flex justify-content-between ">
              <h4 className="">Manage Columns</h4>
            </div>
            <div className="w-100 position-relative">
              <div className="mb-1 pt-2 position-relative w-100">
                <input
                  type="text"
                  className="assetSearchbar searchbar rounded-2 p-2 pe-5 common_input_bg w-100 "
                  placeholder="Search"
                  value={searchColumn}
                  onChange={(e) => {
                    if (e.target.value.charAt(0) !== " ") {
                      const value = e.target.value.replace(
                        /[^a-zA-Z ]+|(\s)\s+/g,
                        "$1"
                      );
                      setSearchColumn(value);
                    }
                  }}
                />
                <span
                  className="position-absolute  mt-2 me-2 end-0 opacity-75 cursorPointer"
                  onClick={() => {
                    setSearchColumn("");
                    setSavingHeadings(leadsColumn);
                  }}
                >
                  {searchColumn === "" ? (
                    <img src={searchIcon} alt="" width={20} />
                  ) : (
                    "X"
                  )}
                </span>
              </div>
              <div className=" opacity-75 columns_container">
                {savingHeadings
                  .filter((lead) =>
                    lead.name
                      .toLowerCase()
                      .includes(searchColumn.trim().toLowerCase())
                  )
                  .sort((a, b) => a.name.localeCompare(b.name))
                  .map((column) => {
                    return (
                      <div
                        key={column.name}
                        className="d-flex gap-2 py-2 rounded-2 align-items-center"
                      >
                        <div className="d-flex gap-2 ps-2">
                          <input
                            type="checkbox"
                            id={column.name}
                            name={column.name}
                            checked={column.isChecked}
                            onChange={(e) => {
                              handleColumnCheckboxChange(
                                column.name,
                                e.target.checked
                              );
                            }}
                            className="cursorPointer"
                          />
                          <label
                            className="small cursorPointer"
                            htmlFor={column.name}
                          >
                            {column.name}
                          </label>
                        </div>
                      </div>
                    );
                  })}
              </div>
              <aside className="float-end mt-2">
                <div className="d-flex align-items-center gap-4">
                  <button
                    className="cancel_btn bg-light"
                    onClick={() => {
                      setShowModalType("");
                      // setSavingHeadings(leadsColumn);
                      // setSearchColumn("");
                    }}
                  >
                    Cancel
                  </button>
                  <button
                    className="save_btn"
                    onClick={() => {
                      setSelectedHeadings(savingHeadings);
                      setTimeout(() => {
                        setShowModalType("");
                      }, 200);
                    }}
                  >
                    Save
                  </button>
                </div>
              </aside>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      {/* Delete Modal */}
      <Modal
        show={[
          "delete",
          "Mass Delete",
          "Mass Update",
          "Mass Transfer",
        ].includes(showModalType)}
        aria-labelledby="contained-modal-title-vcenter"
        onClick={(e) => e.stopPropagation()}
        // size={showModalType !== "delete" ? "md" : "lg"}
      >
        <Modal.Header
          className=" border-0 mb-0 d-flex flex-column align-items-start"
          style={{ backgroundColor: showModalType !== "delete" && "#F2F2F2" }}
        >
          {showModalType !== "delete" && (
            <div
              className={`mass_heading fs-4 ${
                showModalType !== "Mass Delete" && "ps-4"
              }`}
            >
              {showModalType}
            </div>
          )}
          {showModalType === "delete" && (
            <div className="fs-5" style={{ color: "#3F7DFF" }}>
              Are you sure you want to delete {leadOverviewDetails?.leadName}?
            </div>
          )}
        </Modal.Header>
        <Modal.Body className="ModalBody">
          {showModalType === "delete" ? (
            <>
              <div className="d-flex gap-2 pb-2 align-items-center">
                <span className="fw-bold">Note:</span>
                <span className="small pt-1">
                  Any associated activities, visits, drafts will also be
                  deleted.
                </span>
              </div>
            </>
          ) : showModalType === "Mass Delete" ? (
            <>
              <div className="fs-5 pt-0 pb-2">
                Are you sure you want to delete selected item(s)?
              </div>
              <div className="small">
                <span className="fw-bold">Note: </span>{" "}
                <span>
                  {" "}
                  Any associated Activities, Visits, Drafts will also be Deleted
                </span>
              </div>
            </>
          ) : showModalType === "Mass Update" ? (
            <div className="d-flex gap-2 mb-3 mt-2 align-items-center w-100">
              <div className="w-100">
                <div className="d-flex flex-column ps-1 align-items-center mx-4">
                  <label className="text-start w-100 opacity-75">
                    Select a field
                  </label>
                  <div
                    className="position-relative w-100 rounded-1"
                    style={{ border: "1px solid rgba(185, 185, 185, 0.5)" }}
                  >
                    <div
                      onClick={(e) => {
                        e.stopPropagation();
                        setListVisible(!listVisible);
                        setSearchField("");
                      }}
                      className={`cursorPointer d-flex justify-content-between w-100 my-1 p-1 border-0`}
                    >
                      <span className="">{selectedObj.name}</span>
                      <img
                        src={listVisible ? up : down}
                        alt="img"
                        height={20}
                        width={20}
                        className="pt-1"
                      />
                    </div>
                    {listVisible && (
                      <div
                        className="position-absolute field_container w-100"
                        onClick={(e) => {
                          e.stopPropagation();
                        }}
                      >
                        <div className="">
                          <label
                            htmlFor="selectField"
                            className="position-relative w-100"
                          >
                            <input
                              id="selectField"
                              type="text"
                              className="rounded-2 p-1 w-100 pe-4"
                              placeholder="Search"
                              value={searchField}
                              onChange={(e) => {
                                if (e.target.value.charAt(0) !== " ") {
                                  const value = e.target.value.replace(
                                    /[^a-zA-Z ]+|(\s)\s+/g,
                                    "$1"
                                  );
                                  setSearchField(value);
                                }
                              }}
                            />
                            <img
                              className="position-absolute mt-1 me-2 end-0 pt-1 cursorPointer"
                              onClick={() => {
                                setSearchField("");
                              }}
                              src={searchIcon}
                              alt="search"
                              width={20}
                            />
                          </label>

                          <div className="w-100 rounded-2">
                            <div className="border border-1 field_container_dropdown rounded-1">
                              {showSearchFields
                                .filter((field) =>
                                  field.name
                                    .toLowerCase()
                                    .includes(searchField.trim().toLowerCase())
                                )
                                .sort((a, b) => a.name.localeCompare(b.name))
                                .map(
                                  (field) =>
                                    ![
                                      "Lead Owner",
                                      "Created By",
                                      "Modified By",
                                      "Secondary Email Id",
                                      "Secondary Mail",
                                      "Email Opt Out",
                                      "Lead Name",
                                      "First Name",
                                      "Last Name",
                                      "Hash",
                                    ].includes(field.name) && (
                                      <div
                                        key={field.name}
                                        className="d-flex gap-2 p-2 cursorPointer rounded-2 align-items-center"
                                        onClick={() => {
                                          setClickedDropdownObj({
                                            label: "",
                                            value: "",
                                          });
                                          setMassUpdateValue("");
                                          setSelectedObj(field);
                                          setListVisible(false);
                                        }}
                                      >
                                        <span className="ps-2">
                                          {field.name}
                                        </span>
                                      </div>
                                    )
                                )}
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="w-100 mx-auto d-flex justify-content-center mt-4">
                    <div className="w-100">
                      <label className="opacity-75" htmlFor="massValue">
                        Enter value
                      </label>
                      <div
                        className="position-relative w-100 border rounded-1"
                        style={{ border: "1px solid rgba(185, 185, 185, 0.5)" }}
                      >
                        {[
                          "Lead Source",
                          "Lead Status",
                          "Industry Type",
                          "Rating",
                          "Industry",
                        ].includes(selectedObj.name) ? (
                          <Select
                            value={clickedDropdownObj}
                            menuPlacement="auto"
                            onChange={(selectedOption) => {
                              setClickedDropdownObj(selectedOption);
                              setMassUpdateValue(selectedOption.value);
                            }}
                            options={
                              selectedObj.name === "Rating"
                                ? ratingOptions
                                : selectedObj.name === "Lead Status"
                                ? leadStatusOptions
                                : selectedObj.name === "Lead Source"
                                ? leadSourceOptions
                                : industryOptions
                            }
                            styles={fieldDropdownStyles}
                            isSearchable={false}
                          />
                        ) : (
                          <input
                            className="w-100 border-0 py-2 ps-2 rounded-1"
                            type="text"
                            value={massUpdateValue}
                            onChange={(e) => {
                              const trimmedValue = e.target.value
                                ?.replace(/\s{2,}/g, " ")
                                .trimStart();
                              const numericFields = [
                                "annualRevenue",
                                "fax",
                                "noOfEmployees",
                                "zipCode",
                                "mobile",
                              ];
                              const isNumericField = numericFields.includes(
                                selectedObj.value
                              );

                              if (isNumericField) {
                                // Allow only numbers with one decimal place

                                const regex = /^\d*\.?\d{0,2}$/;
                                if (regex.test(trimmedValue)) {
                                  setMassUpdateValue(trimmedValue);
                                }
                              } else {
                                // Allow non-numeric inputs without further validation
                                setMassUpdateValue(trimmedValue);
                              }
                            }}
                            style={{ outline: "none" }}
                            id="massValue"
                          />
                        )}
                      </div>
                      {errorMessage && (
                        <span className="text-danger text-center pt-1">
                          {errorMessage}
                        </span>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="w-100 px-4 py-2 mb-1">
              <label className="text-start w-100 opacity-75">Transfer To</label>
              <div
                className="d-flex input-with-clear position-relative mx-auto"
                onClick={(e) => {
                  handleOwnerInputClick(e);
                }}
              >
                <input
                  type="text"
                  className="ps-3 w-100 cursorPointer py-2"
                  value={selectedItem?.name}
                  readOnly
                />
                <span
                  className="position-absolute h-100 mass_transfer_user pt-1"
                  style={{ right: "0.1rem" }}
                >
                  <span
                    className="cursorPointer px-1"
                    onClick={() => setIsLeadOwnerModal(!isLeadOwnerModal)}
                  >
                    <img
                      src={userSearch}
                      alt="img"
                      width={20}
                      height={20}
                      className="ms-2 mt-2 me-2"
                    />
                  </span>
                </span>
              </div>
            </div>
          )}

          <div className={`pt-4  ${showModalType !== "delete" && "pe-3"}`}>
            <div className="d-flex justify-content-end w-100 gap-4 pe-2">
              <button
                className="cancelButton px-4"
                onClick={() => {
                  setShowModalType("");
                  // setSelectedCheckboxes([]);
                  setShowSearchFields(fieldDropdowns);
                }}
              >
                Cancel
              </button>
              <button
                className="createContactButtonSave"
                onClick={() => {
                  if (showModalType === "delete") {
                    deleteLead();
                  } else {
                    //  else if (showModalType === "Mass Delete") {
                    //   deleteUpdateTransferMass();
                    // } else {
                    deleteUpdateTransferMass();
                  }
                }}
              >
                {showModalType === "Mass Update"
                  ? "Update"
                  : showModalType === "Mass Transfer"
                  ? "Done"
                  : "Delete"}
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <ChangeOwnerModal
        modalType={showModalType}
        setShowModalType={setShowModalType}
        selectedOwnerItem={selectedOwnerItem}
        handleChangeOwnerInputClick={handleChangeOwnerInputClick}
        setSelectedOwnerItem={setSelectedOwnerItem}
        isLeadOwnerModal={isLeadOwnerModal}
        setIsLeadOwnerModal={setIsLeadOwnerModal}
        isChangeOwnerList={isChangeOwnerList}
        userList={userList}
        handleChangeOwnerItemClick={handleChangeOwnerItemClick}
        emailNotification={emailNotification}
        setEmailNotification={setEmailNotification}
        handleEditClick={handleEditClick}
        handleUpdateClick={handleUpdateClick}
        setUpdatedFields={setUpdatedFields}
        existingContact={existingLead}
        handleUpdateContact={handleUpdateLead}
        onSelectedChangeOwner={handleSelectedChangeOwner}
        apiLoader={apiLoader} // for lottie loader
      />

      {/* Share Modal */}
      <ShareModal
        moduleType="LEADS"
        setShareModal={setShareModal}
        shareModal={shareModal}
        shareInfoModal={shareInfoModal}
        setShareInfoModal={setShareInfoModal}
        id={leadId}
        shareUserList={shareUserList}
        shareGroupList={shareGroupList}
        shareRoleList={shareRoleList}
        sharePublicList={sharePublicList}
        modalHeading={"Sharing Lead"}
        shareMemberIds={shareMemberIds}
        module={"Lead"}
      />

      <LeadOwnerModal
        isLeadOwnerModal={isLeadOwnerModal}
        setIsLeadOwnerModal={setIsLeadOwnerModal}
        onSelectedLeadChange={handleSelectedLeadChange}
        defaultUser={selectedItem}
      />
      <AccessModal
        setShowAccessModal={setShowAccessModal}
        showAccessModal={showAccessModal}
      />

      <Modal
        show={isCampaignListModal}
        className="d-flex justify-content-center align-items-center"
        aria-labelledby="example-custom-modal-styling-title"
        size="lg"
        id="commonLeadModal"
      >
        <Modal.Body className="ModalBody overflow-auto">
          <div
            className="modalUS row justify-content-center"
            style={{ margin: "0", gap: "10px", paddingBottom: "10px" }}
          >
            <div className="header_Container d-flex justify-content-between">
              <h4 className="commonTextColor pt-2">Choose Campaign</h4>
              <div className="inputContainer d-flex justify-content-start">
                <input
                  type="text"
                  placeholder="Search"
                  className="topBarModalSearch px-5"
                  onChange={handleSearchCampaign}
                  value={searchCampaign}
                />
                <img src={SearchIcon} alt="search" className="searchIcon" />
              </div>
            </div>

            <div className="Owner_table_container border border-dark rounded-2 h-25">
              <div className="table-wrapper">
                <table className="userdata-table">
                  <thead>
                    <tr>
                      <th>Campaign Name</th>
                      <th>Campaign Type</th>
                      <th>Campaign Owner</th>
                      <th>Start Date</th>
                      <th>End Date</th>
                    </tr>
                  </thead>
                  <tbody className="table-body tableUserData">
                    {filteredCampigns.length > 0 &&
                      filteredCampigns?.map((item, index) => (
                        <tr
                          key={index}
                          onClick={() => {
                            setSelectedCampaignItem(item);
                          }}
                        >
                          <>
                            <td>{item.campaignName}</td>
                            <td>{item.campaignType}</td>
                            <td>{item.campaignOwner}</td>
                            <td>{item.startDate}</td>
                            <td>{item.endDate}</td>
                          </>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            </div>
            <div className="d-flex w-100 align-items-center justify-content-end gap-2">
              {filteredCampigns.length > 0 && (
                <>
                  <Select
                    options={pageOption}
                    styles={customCommonStyles("225px")}
                    value={pageOption.find(
                      (option) => option.value === perPageCampaign.toString()
                    )}
                    onChange={handlePageDropDownChangeCampaign}
                    isSearchable={false}
                  />
                  <Pagination
                    currentPage={currentPageCampaign}
                    totalPages={totalPagesCampaign}
                    onPageChange={handlePageChangeCampaign}
                  />
                </>
              )}
            </div>
            <div className="d-flex pb-2 pt-3 justify-content-between">
              <div className="selectedUser d-flex gap-1">
                <span>Selected Campaign</span>
                <img src={logoConvert} alt="img" height={20} width={20} />
                <span>{selectedCampaignItem.campaignName}</span>
              </div>
              <div className="d-flex gap-2">
                <button
                  className="btn btn-outline-secondary border border-primary btn-sm px-3 py-2 text-dark"
                  onClick={() => {
                    setIsCampaignListModal(false);
                    setSelectedCampaignItem({
                      campaignName: "",
                      campaignId: "",
                    });
                  }}
                >
                  Cancel
                </button>
                <button
                  className="btn btnColor btn-sm px-3 text-white"
                  onClick={() => {
                    setIsCampaignListModal(false);
                    //  setSelectedCampaignId(selectedCampaignItem.campaignId)
                  }}
                >
                  Done
                </button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <NotificationContainer />
    </div>
  );
}

export default Lead;

export const handleApiError = (error, navigateCallback) => {
    
  // Navigate to initial page ("/") on error using callback
  if (typeof navigateCallback === "function" && error.response?.data.status === 400) {
    window.sessionStorage.clear();
    window.localStorage.clear();
    window.history.forward();
    navigateCallback("/");
  }
};

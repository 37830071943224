import React from "react";
import pageLoader from "../../Assets/lotties/PageLoader.json";
import Lottie from "react-lottie";
export default function Loader() {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: pageLoader,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  return (
    <>
      <div
        className="d-flex justify-content-center align-items-center"
        style={{ height: "80vh" }}
      >
        {/* <img
          src={loaderIcon}
          alt="loader"
          width={180}
          className="border-0 my-0 py-0"
        /> */}
        <Lottie options={defaultOptions} width="150px" height={"150px"} />
      </div>
    </>
  );
}

import React from "react";
import { Modal } from "react-bootstrap";
import Lottie from "react-lottie";
import { defaultOptions } from "../../utils/defaultOptions";

const VendorRemoveModal = ({
  showRemoveModal,
  setShowRemoveModal,
  setShowImageModal,
  removeImage,
  imageLoader,
}) => {
  return (
    <Modal
      show={showRemoveModal}
      className="d-flex justify-content-center align-items-center"
      aria-labelledby="example-custom-modal-styling-title"
      size="lg"
    >
      <Modal.Body className="ModalBody overflow-auto">
        <div className="d-flex flex-column gap-4">
          <p className="text-center mt-3">Are you sure you want to remove?</p>
          <div className="d-flex w-100 gap-2">
            <button
              className="imageAccButton"
              onClick={() => {
                setShowRemoveModal(false);
                setShowImageModal(true);
              }}
            >
              Cancel
            </button>
            <button
              className="imageAccButtonSave"
              onClick={() => {
                removeImage();
              }}
            >
              {imageLoader ? (
                <Lottie
                  options={defaultOptions("white")}
                  height="24px"
                  width="36px"
                />
              ) : (
                "Remove"
              )}
            </button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default VendorRemoveModal;

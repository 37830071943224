import React, { useEffect, useState } from "react";
import "./Analytics.css";
import { Modal } from "react-bootstrap";
import cancelCircle from "../../Assets/cancelCircle.svg";
import Select from "react-select";
import {
  customModules,
  dashboardModules,
  getFromOptions,
} from "../../common/CommonData";
import { componentData } from "../InitialHome/dashboardData";
import axios from "axios";
import { URI } from "../../constants";
import { useSelector } from "react-redux";
import GaugeChartComponent from "../InitialHome/DashboardComponents/GaugeChartComponent";
import SingleColumnChart from "../InitialHome/DashboardComponents/SingleColumnChart";
import DonutChart from "../InitialHome/DashboardComponents/DonutChart";
import NoData from "../../Assets/Nodata.svg";
import ColumnChart from "../InitialHome/DashboardComponents/ColumnChart";
import FunnelChart from "../InitialHome/DashboardComponents/FunnelChart";
import {
  convertSnakeCaseToReadableString,
  formatNumber,
} from "../../common/CommonComponent";
import { useNavigate } from "react-router-dom";

import CommonMultiLine from "./CommonMultiLine";
import getHeaders from "../../common/ApiHeader";
import StackedBarChart from "../InitialHome/DashboardComponents/StackedBarChart";
import { handleApiError } from "../../utils/ApiUtils";

const Analytics = ({ currentView }) => {
  const reduxStore = useSelector((state) => state.reduxState);
  const { loginUserId } = reduxStore;
  const navigate = useNavigate();

  const today = new Date();
  const yesterday = new Date(today);
  yesterday.setDate(today.getDate() - 2);

  const [selectOverview, setSelectOverview] = useState("org_overview");
  const [modalType, setModalType] = useState("");

  const [getFormType, setGetFormType] = useState(getFromOptions[0]);
  const [moduleType, setModuleType] = useState(dashboardModules[0]);
  const [componentType, setComponentType] = useState({
    value: "",
    label: "",
  });
  const [componentName, setComponentName] = useState("");
  const [totalData, setTotalData] = useState(null);
  const [apiLoader, setApiLoader] = useState(false);
  const [closeDropdown, setCloseDropdown] = useState("");

  const getAnalyticDetails = async (type) => {
    setSelectOverview(type);
    setApiLoader(true);

    const headersResult = getHeaders(true);

    // Check if headers result indicates an error
    if (headersResult.error) {
      navigate("../"); // Redirect to login if token is missing
      return;
    }

    await axios
      .get(URI.getAnalyticDetails + loginUserId + "/" + type, {
        headers: headersResult,
      })
      .then((response) => {
        if (response.data.status === 200) {
          setApiLoader(false);
          setTotalData(response.data.data);
        }
      })
      .catch(async (error) => {
        setApiLoader(false);
        handleApiError(error, navigate);
      });
  };

  useEffect(() => {
    getAnalyticDetails("org_overview");
    // getAnalyticDetails("marketing_metrics");
  }, []);

  const showNoData = () => {
    return (
      <div className="text-center d-flex flex-column justify-content-center align-items-center no_data_img">
        <img src={NoData} alt="No data" className="h-50 w-50" />
        <h5 className="fs-6 my-0 py-0">No data</h5>
      </div>
    );
  };

  const orgOverview = () => {
    const getUniqueMonths = (data) => {
      if (data) {
        const months = new Set();
        Object.values(data).forEach((items) => {
          items.forEach((item) => months.add(item.monthYear));
        });
        return Array.from(months);
      }
    };

    const uniqueMonths = getUniqueMonths(totalData?.getLastThreeMonthsPerf);

    const uniqueStatuses = Object.keys(totalData?.getLastThreeMonthsPerf);

    return (
      <div>
        <div className="mt-1 d-flex w-100 justify-content-between gap-2">
          <div
            className="overviewContent py-2 px-1"
            style={{ width: "16.6vw" }}
          >
            <div className="d-flex">
              <div className="overviewHeaders w-50" title="LEADS THIS MONTH">
                LEADS THIS MONTH
              </div>
            </div>
            {totalData?.getLeadsThisMonth?.length > 0 ? (
              <>
                <div className="d-flex gap-3 align-items-center mt-2">
                  <div className="">
                    {totalData.getLeadsThisMonth[0]?.currentMonthData}
                  </div>

                  <div
                    style={{
                      fontSize: "14px",
                    }}
                    className={` ${
                      totalData?.getLeadsThisMonth[0]?.percentage?.includes("-")
                        ? "text-danger"
                        : "text-success"
                    }`}
                  >
                    {/* <span className="triangleGreen"></span>{" "} */}
                    <span>{totalData.getLeadsThisMonth[0]?.percentage}%</span>
                  </div>
                </div>
                <div className="pt-3">
                  <div
                    style={{ color: "#616E88", fontSize: "14px" }}
                    className="wrap_text"
                  >
                    Last Month Relative:&nbsp;
                    {totalData.getLeadsThisMonth[0]?.lastMonthRelative}
                  </div>
                </div>
              </>
            ) : (
              <>{showNoData()}</>
            )}
          </div>
          <div
            className="overviewContent py-2 px-1"
            style={{ width: "16.6vw" }}
          >
            <div className="d-flex">
              <div className="overviewHeaders w-50" title="REVENUE THIS MONTH">
                REVENUE THIS MONTH
              </div>
            </div>
            {totalData?.getRevenueThisMonth?.length > 0 ? (
              <>
                <div className="d-flex gap-3 align-items-center mt-2">
                  <div className="">
                    ₹{" "}
                    {formatNumber(
                      totalData.getRevenueThisMonth[0]?.currentMonthData
                    )}
                  </div>

                  <div
                    style={{
                      fontSize: "14px",
                    }}
                    className={` ${
                      totalData?.getRevenueThisMonth[0]?.percentage?.includes(
                        "-"
                      )
                        ? "text-danger"
                        : "text-success"
                    }`}
                  >
                    <span>{totalData.getRevenueThisMonth[0]?.percentage}%</span>
                  </div>
                </div>
                <div className="pt-3">
                  <div
                    style={{ color: "#616E88", fontSize: "14px" }}
                    className="wrap_text"
                  >
                    Last Month Relative:&nbsp;
                    {formatNumber(
                      totalData.getRevenueThisMonth[0]?.lastMonthRelative
                    )}
                  </div>
                </div>
              </>
            ) : (
              <>{showNoData()}</>
            )}
          </div>
          <div
            className="overviewContent py-2 px-1"
            style={{ width: "16.6vw" }}
          >
            <div className="d-flex">
              <div className="overviewHeaders w-50" title="DEALS IN PIPELINE">
                DEALS IN PIPELINE
              </div>
            </div>
            {totalData?.getDealsInPipeline?.length > 0 ? (
              <div className="mt-2">
                {totalData?.getDealsInPipeline[0]?.dealsInPipeline}
              </div>
            ) : (
              <>{showNoData()}</>
            )}
          </div>
          <div
            className="overviewContent py-2 px-1"
            style={{ width: "16.6vw" }}
          >
            <div className="d-flex">
              <div className="overviewHeaders w-50" title="ACCOUNTS THIS MONTH">
                ACCOUNTS THIS MONTH
              </div>
            </div>
            {totalData?.getAccountThisMonth?.length > 0 ? (
              <>
                <div className="d-flex gap-3 align-items-center mt-2">
                  <div className="">
                    {totalData.getAccountThisMonth[0]?.currentMonthData}
                  </div>

                  <div
                    style={{
                      fontSize: "14px",
                    }}
                    className={` ${
                      totalData?.getAccountThisMonth[0]?.percentage?.includes(
                        "-"
                      )
                        ? "text-danger"
                        : "text-success"
                    }`}
                  >
                    <span>{totalData.getAccountThisMonth[0]?.percentage}%</span>
                  </div>
                </div>
                <div className="pt-3">
                  <div
                    style={{ color: "#616E88", fontSize: "14px" }}
                    className="wrap_text"
                  >
                    Last Month Relative:&nbsp;
                    {totalData.getAccountThisMonth[0]?.lastMonthRelative}
                  </div>
                </div>
              </>
            ) : (
              <>{showNoData()}</>
            )}
          </div>
        </div>

        <div className="mt-2 d-flex gap-2">
          <div
            className="overviewContent p-2"
            style={{ width: "25vw", borderRadius: "5px", height: "40vh" }}
          >
            <div className="d-flex">
              <div
                className="overviewHeaders w-50"
                title="LEAD GENERATION TARGET - THIS YEAR"
              >
                LEAD GENERATION TARGET - THIS YEAR
              </div>
            </div>
            <div className="mt-2 h-100">
              {totalData?.getLeadGenerationTarget?.length > 0 ? (
                <GaugeChartComponent
                  currentView={currentView}
                  data={totalData.getLeadGenerationTarget}
                />
              ) : (
                <>{showNoData()}</>
              )}
            </div>
          </div>
          <div
            className="overviewContent p-2"
            style={{ width: "43vw", borderRadius: "5px", height: "40vh" }}
          >
            <div className="w-100 h-100">
              <div
                className="overviewHeaders w-50"
                title="REVENUE TARGET - THIS YEAR"
              >
                REVENUE TARGET - THIS YEAR
              </div>
              <div className="w-100 h-100">
                {totalData?.getRevenueTarget?.length > 0 ? (
                  <SingleColumnChart data={totalData.getRevenueTarget} />
                ) : (
                  <>{showNoData()}</>
                )}
              </div>
            </div>
          </div>
        </div>

        <div className="mt-2 d-flex gap-2">
          <div
            className="overviewContent p-2"
            style={{ width: "26.5vw", borderRadius: "5px", height: "50vh" }}
          >
            <div className="w-100">
              <div
                className="overviewHeaders w-50"
                title="LAST 3 MONTHS PERFORMANCE MONITOR"
              >
                LAST 3 MONTHS PERFORMANCE MONITOR
              </div>
              <div
                className="w-100 mt-1 small analytics_table table-scroll pb-2"
                style={{
                  overflow: "auto",
                  height: "43vh",
                }}
              >
                <table className="table responsive">
                  <thead>
                    <tr style={{ backgroundColor: "#f2f2f2" }}>
                      <th
                        style={{
                          border: "1px solid #ccc",
                        }}
                        className="text-center px-1 py-2"
                      ></th>
                      {uniqueMonths.map((month, index) => (
                        <th
                          key={index}
                          style={{
                            border: "1px solid #ccc",
                          }}
                          className="text-center px-1 py-2"
                        >
                          {month}
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {uniqueStatuses.map((status, rowIndex) => (
                      <tr key={rowIndex} className="">
                        <td
                          style={{
                            border: "1px solid #ccc",
                          }}
                          className="text-center px-1 py-2 text-wrap first_letter_uppercase"
                        >
                          {status
                            ?.replace(/_/g, " ")
                            .toLowerCase()
                            .replace(/^\w|\s\w/g, (c) => c.toUpperCase())}
                        </td>
                        {uniqueMonths.map((month, colIndex) => (
                          <td
                            key={colIndex}
                            className="text-center px-1 py-2"
                            style={{
                              border: "1px solid #ccc",
                            }}
                          >
                            {["REVENUE_WON", "OPEN_AMOUNT"].includes(status)
                              ? "₹ "
                              : ""}{" "}
                            {formatNumber(
                              totalData?.getLastThreeMonthsPerf[status]?.find(
                                (item) => item.monthYear === month
                              )?.recordCount
                            ) || 0}
                          </td>
                        ))}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div
            className="overviewContent p-2"
            style={{ width: "25.5vw", borderRadius: "5px", height: "50vh" }}
          >
            <div className="d-flex">
              <div className="overviewHeaders w-50" title="LEADS BY SOURCE">
                LEADS BY SOURCE
              </div>
            </div>
            <div className="h-100">
              {totalData?.getLeadsBySource?.length > 0 ? (
                <DonutChart
                  currentView={currentView}
                  data={totalData.getLeadsBySource}
                />
              ) : (
                <>{showNoData()}</>
              )}
            </div>
          </div>
          <div
            className="overviewContent py-2"
            style={{ width: "15.5vw", borderRadius: "5px" }}
          >
            <div className="d-flex px-2">
              <div className="overviewHeaders w-50" title="PROLIFIC SALES REPS">
                PROLIFIC SALES REPS
              </div>
            </div>
            <div className="">
              {totalData?.getProlificSalesReps?.length > 0 ? (
                <div
                  className="w-100 analytics_table table-scroll border rounded-2"
                  style={{
                    overflow: "auto",
                    height: "43vh",
                  }}
                >
                  <table className="table responsive">
                    <thead>
                      <tr className="w-100 text-wrap">
                        <th className="fw-bold">Deal Owner</th>
                        <th className="fw-bold">Sum Of Amount</th>
                      </tr>
                    </thead>
                    <tbody className="">
                      {totalData?.getProlificSalesReps?.map((comp) => {
                        return (
                          <tr className="w-100" key={comp.dealOwner}>
                            <td
                              className="overflow_text"
                              style={{ maxWidth: "8vw" }}
                              title={comp.dealOwner}
                            >
                              {comp.dealOwner}
                            </td>
                            <td
                              className="overflow_text"
                              style={{ maxWidth: "8vw" }}
                              title={comp.sumOfAmount}
                            >
                              ₹ {formatNumber(comp.sumOfAmount)}
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              ) : (
                <>{showNoData()}</>
              )}
            </div>
          </div>
        </div>

        <div className="mt-2 d-flex gap-2 w-100">
          {/* <div
            className="overviewContent p-2 w-50"
            style={{ borderRadius: "5px", height: "50vh" }}
          >
            <div className="w-100 ">
              <div className="overviewHeaders w-50" title="MOMO">
                MOMO
              </div>
              <div>table</div>
            </div>
          </div> */}
          <div
            className="overviewContent p-2 w-50"
            style={{ borderRadius: "5px", height: "50vh" }}
          >
            <div className="d-flex">
              <div className="overviewHeaders w-50" title="LEAD PRODUCT DTLS">
                LEAD PRODUCT DTLS
              </div>
            </div>
            <div className="mt-2 h-100 w-100">
              {totalData?.getLeadProductDtls?.length > 0 ? (
                <ColumnChart
                  currentView={currentView}
                  data={totalData.getLeadProductDtls}
                  showTitles={true}
                />
              ) : (
                <>{showNoData()}</>
              )}
            </div>
          </div>
          <div
            className="overviewContent p-2 w-50"
            style={{ borderRadius: "5px", height: "50vh" }}
          >
            <div className="d-flex">
              <div className="overviewHeaders w-50" title="Leads">
                LEADS
              </div>
            </div>
            <div className="mt-2 h-100">
              {totalData?.getLeads?.length > 0 ? (
                <ColumnChart
                  currentView={currentView}
                  data={totalData.getLeads}
                  showTitles={true}
                />
              ) : (
                <>{showNoData()}</>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  };

  const leadAnalyticsOverview = () => {
    const funnelColumnData = [
      totalData.getMonthlySalesFunnel[
        totalData.getMonthlySalesFunnel?.length - 1
      ],
    ];

    const funnelFinalData = [
      {
        monthlyLeadCount: funnelColumnData[0].leadsCreated,
        leadsCreatedMonth: "Leads Created",
      },
      {
        monthlyLeadCount: funnelColumnData[0].convertedToContacts,
        leadsCreatedMonth: "Converted to Contacts",
      },
    ];
    return (
      <div style={{ width: "70vw" }}>
        <div className="d-flex flex-column gap-2 w-100">
          <div className="d-flex gap-2 mt-2 w-100">
            <div className="d-flex flex-column gap-2 w-50">
              <div className="d-flex gap-2 w-100">
                <div className="d-flex flex-column gap-2 w-50">
                  <div
                    className="overviewContent p-2 w-100"
                    style={{ borderRadius: "5px", height: "27vh" }}
                  >
                    <div className="d-flex">
                      <div
                        className="overviewHeaders w-50"
                        title="TODAY'S LEADS"
                      >
                        TODAY&apos;S LEADS
                      </div>
                    </div>
                    {totalData?.getTodaysLeadData.length > 0 ? (
                      <div className="component_content p-2">
                        <div className="">
                          <div className="mt-3">
                            <span className="fs-5 fw-bold">
                              {totalData?.getTodaysLeadData[0].todayLeads}
                            </span>
                            <span
                              className={`fs-5 ps-3 ${
                                totalData?.getTodaysLeadData[0].percentageChange?.includes(
                                  "-"
                                )
                                  ? "text-danger"
                                  : "text-success"
                              }`}
                            >
                              {`${totalData?.getTodaysLeadData[0]?.percentageChange}`}
                            </span>
                          </div>
                          <div className="mt-1">
                            <span>Yesterday Relative:</span>
                            <span className="ps-1">
                              {
                                totalData?.getTodaysLeadData[0]
                                  ?.yesterdayRelative
                              }
                            </span>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <>{showNoData()}</>
                    )}
                  </div>
                  <div
                    className="overviewContent p-2 w-100"
                    style={{ borderRadius: "5px", height: "27vh" }}
                  >
                    <div className="d-flex">
                      <div
                        className="overviewHeaders w-50"
                        title="THIS WEEK'S LEADS"
                      >
                        THIS WEEK&apos;S LEADS
                      </div>
                    </div>
                    {totalData?.getThisWeekLeads.length > 0 ? (
                      <div className="component_content p-2">
                        <div className="">
                          <div className="mt-3">
                            <span className="fs-5 fw-bold">
                              {totalData?.getThisWeekLeads[0].leadsThisWeek}
                            </span>
                            <span
                              className={`fs-5 ps-3 ${
                                totalData?.getThisWeekLeads[0].percentage?.includes(
                                  "-"
                                )
                                  ? "text-danger"
                                  : "text-success"
                              }`}
                            >
                              {`${
                                totalData?.getThisWeekLeads[0]?.percentage + "%"
                              }`}
                            </span>
                          </div>
                          <div className="mt-1">
                            <span>Last Week Relative:</span>
                            <span className="ps-1">
                              {totalData?.getThisWeekLeads[0]?.lastWeekRelative}
                            </span>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <>{showNoData()}</>
                    )}
                  </div>
                </div>
                <div
                  className="overviewContent p-2 w-50"
                  style={{ borderRadius: "5px", height: "55vh" }}
                >
                  <div className="d-flex">
                    <div
                      className="overviewHeaders w-50"
                      title="TOP 10 LEAD SOURCES"
                    >
                      TOP 10 LEAD SOURCES
                    </div>
                  </div>
                  <div className="">
                    {totalData?.getTopTenLead?.length > 0 ? (
                      <div
                        className="w-100 analytics_table table-scroll border rounded-2"
                        style={{
                          overflowY: "auto",
                          maxHeight: "49vh",
                        }}
                      >
                        <table className="table responsive">
                          <thead>
                            <tr className="w-100 text-wrap">
                              <th className="fw-bold">Lead Source</th>
                              <th className="fw-bold">Records Count</th>
                            </tr>
                          </thead>
                          <tbody className="">
                            {totalData?.getTopTenLead?.map((comp) => {
                              return (
                                <tr className="w-100" key={comp.leadSource}>
                                  <td
                                    className="overflow_text"
                                    style={{ maxWidth: "9.5vw" }}
                                    title={comp.leadSource}
                                  >
                                    {comp.leadSource}
                                  </td>
                                  <td style={{ maxWidth: "3vw" }}>
                                    {comp.recordsCount}
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      </div>
                    ) : (
                      <>{showNoData()}</>
                    )}
                  </div>
                </div>
              </div>
              <div className="d-flex gap-2">
                <div
                  className="overviewContent p-2 w-50"
                  style={{
                    borderRadius: "5px",
                    maxHeight: "40vh",
                  }}
                >
                  <div className="d-flex">
                    <div
                      className="overviewHeaders w-50"
                      title="TOP LEAD OWNERS"
                    >
                      TOP LEAD OWNERS
                    </div>
                  </div>
                  <div className="">
                    {totalData?.getTopLeadOwners?.length > 0 ? (
                      <div
                        className="w-100 analytics_table table-scroll border rounded-2"
                        style={{
                          // overflowX: "auto",
                          overflowY: "auto",
                          maxHeight: "34vh",
                        }}
                      >
                        <table className="table responsive">
                          <thead>
                            <tr className="w-100 text-wrap">
                              <th className="fw-bold">Lead Owner</th>
                              <th className="fw-bold">Records Count</th>
                            </tr>
                          </thead>
                          <tbody className="">
                            {totalData?.getTopLeadOwners?.map((comp, i) => {
                              return (
                                <tr className="w-100" key={i}>
                                  <td
                                    className="overflow_text"
                                    style={{ maxWidth: "8vw" }}
                                    title={comp.leadOwner}
                                  >
                                    {comp.leadOwner}
                                  </td>
                                  <td
                                    className="overflow_text d-flex justify-content-between"
                                    style={{ maxWidth: "8vw" }}
                                  >
                                    <span>{comp.recordsCount}</span>
                                    <span className="ps-1 text-success">
                                      {comp.percentage}
                                    </span>
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      </div>
                    ) : (
                      <>{showNoData()}</>
                    )}
                  </div>
                </div>
                <div
                  className="overviewContent p-2 w-50"
                  style={{ borderRadius: "5px", height: "40vh" }}
                >
                  <div className="d-flex">
                    <div
                      className="overviewHeaders w-50"
                      title="JUNK LEADS BY SOURCE "
                    >
                      JUNK LEADS BY SOURCE
                    </div>
                  </div>
                  <div className="">
                    {totalData?.getJunkLeadsBySource?.length > 0 ? (
                      <div
                        className="w-100 analytics_table table-scroll border rounded-2"
                        style={{
                          // overflowX: "auto",
                          overflowY: "auto",
                          maxHeight: "34vh",
                        }}
                      >
                        <table className="table responsive ">
                          <thead>
                            <tr className="w-100 text-wrap">
                              <th className="fw-bold">Lead Source</th>
                              <th className="fw-bold">Records Count</th>
                            </tr>
                          </thead>
                          <tbody className="">
                            {totalData?.getJunkLeadsBySource?.map((comp, i) => {
                              return (
                                <tr className="w-100 " key={i}>
                                  <td
                                    className="overflow_text"
                                    title={comp.leadSource}
                                  >
                                    {comp.leadSource}
                                  </td>
                                  <td
                                    className="overflow_text"
                                    // title={comp.recordsCount}
                                  >
                                    {comp.recordsCount}
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      </div>
                    ) : (
                      <>{showNoData()}</>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="w-50">
              <div
                className="overviewContent p-2 sales_funnel_container"
                style={{ borderRadius: "5px", height: "100%" }}
              >
                <div className="d-flex">
                  <div className="overviewHeaders w-50" title="SALES FUNNEL ">
                    SALES FUNNEL
                  </div>
                </div>
                <div className="mt-2 h-50 w-100">
                  {totalData?.getMonthlySalesFunnel?.length > 0 ? (
                    <ColumnChart
                      currentView={currentView}
                      data={funnelFinalData}
                      showTitles={false}
                    />
                  ) : (
                    <>{showNoData()}</>
                  )}
                </div>
                <div className="">
                  {totalData?.getMonthlySalesFunnel?.length > 0 ? (
                    <div
                      className="w-100 analytics_table table-scroll border rounded-2"
                      style={{
                        // overflowX: "auto",
                        overflowY: "auto",
                        maxHeight: "40vh",
                      }}
                    >
                      <table className="table responsive">
                        <thead className="">
                          <tr
                            className="w-100 text-wrap"
                            style={{ backgroundColor: "#f5f5f5" }}
                          >
                            <th className="fw-bold">Lead Source</th>
                            <th className="fw-bold text-center">
                              Leads Created
                            </th>
                            <th className="fw-bold text-center"></th>
                            <th className="fw-bold text-center">
                              Converted to Contacts
                            </th>
                            <th className="fw-bold text-center"></th>
                            <th className="fw-bold text-center">Accounts</th>
                            <th className="fw-bold text-center">
                              Conversion Rate
                            </th>
                          </tr>
                        </thead>
                        <tbody className="">
                          {totalData?.getMonthlySalesFunnel?.map((comp, i) => {
                            return (
                              <tr className="w-100" key={i}>
                                <td
                                  className="overflow_text"
                                  title={comp.leadSource}
                                >
                                  {comp.leadSource}
                                </td>
                                <td className="overflow_text text-center">
                                  {comp.leadsCreated}
                                </td>
                                <td className="overflow_text opacity-75 text-center">
                                  {comp.convertedToContactsPercentage}%
                                </td>
                                <td className="overflow_text text-center">
                                  {comp.convertedToContacts}
                                </td>
                                <td className="overflow_text opacity-75 text-center">
                                  {comp.convertedToAccountsPercentage}%
                                </td>
                                <td className="overflow_text text-center">
                                  {comp.accounts}
                                </td>
                                <td className="overflow_text opacity-75 text-center">
                                  {comp.convertionRate}%
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>
                  ) : (
                    <>{showNoData()}</>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="d-flex gap-2">
            <div
              className="overviewContent p-2 w-50"
              style={{ borderRadius: "5px", height: "60vh" }}
            >
              <div className="d-flex">
                <div
                  className="overviewHeaders w-50"
                  title="MONTHLY LEAD CREATION "
                >
                  MONTHLY LEAD CREATION
                </div>
              </div>
              <div className="mt-2 h-100 w-100">
                {totalData?.getMonthyLeadCreation?.length > 0 ? (
                  <ColumnChart
                    currentView={currentView}
                    data={totalData.getMonthyLeadCreation}
                    showTitles={true}
                  />
                ) : (
                  <>{showNoData()}</>
                )}
              </div>
            </div>
            <div
              className="overviewContent p-2 w-50"
              style={{ borderRadius: "5px", height: "60vh" }}
            >
              <div className="d-flex">
                <div className="overviewHeaders w-50" title="LEADS BY INDUSTRY">
                  LEADS BY INDUSTRY
                </div>
              </div>
              <div className="mt-2 h-100">
                {totalData?.getLeadsByIndustry?.length > 0 ? (
                  <DonutChart
                    currentView={currentView}
                    data={totalData.getLeadsByIndustry}
                  />
                ) : (
                  <>{showNoData()}</>
                )}
              </div>
            </div>
          </div>
          {/* <div className="d-flex gap-2">
            <div
              className="overviewContent p-2 w-50 me-5"
              style={{ borderRadius: "5px", height: "60vh" }}
            >
              <div className="d-flex">
                <div
                  className="overviewHeaders w-50"
                  title="MONTHLY LEAD CREATION "
                >
                  MONTHLY LEAD CREATION
                </div>
              </div>
              <div className="mt-2 h-100 w-100">
                {totalData?.getMonthyLeadCreation?.length > 0 ? (
                  <ColumnChart
                    currentView={currentView}
                    data={totalData.getMonthyLeadCreation}
                  />
                ) : (
                  <>{showNoData()}</>
                )}
              </div>
            </div>
          </div> */}
        </div>
      </div>
    );
  };

  const dealInsights = () => {
    return (
      <div>
        <div className="mt-1 d-flex w-100 justify-content-between gap-2">
          <div className="overviewContent py-2 px-1 w-25">
            <div className="d-flex">
              <div className="overviewHeaders w-50" title="REVENUE THIS MONTH">
                REVENUE THIS MONTH
              </div>
            </div>
            {totalData?.getRevenueThisMonth?.length > 0 ? (
              <>
                <div className="d-flex gap-3 align-items-center mt-2">
                  <div className="fs-5">
                    ₹{" "}
                    {formatNumber(
                      totalData.getRevenueThisMonth[0]?.currentMonthData
                    )}
                  </div>

                  <div
                    style={{ fontSize: "14px" }}
                    className={` ${
                      totalData?.getRevenueThisMonth[0]?.percentage?.includes(
                        "-"
                      )
                        ? "text-danger"
                        : "text-success"
                    }`}
                  >
                    <span>{totalData.getRevenueThisMonth[0]?.percentage}%</span>
                  </div>
                </div>
                <div className="pt-3">
                  <div
                    style={{ color: "#616E88", fontSize: "14px" }}
                    className="wrap_text"
                  >
                    Last Month Relative:&nbsp;
                    {formatNumber(
                      totalData.getRevenueThisMonth[0]?.lastMonthRelative
                    )}
                  </div>
                </div>
              </>
            ) : (
              <>{showNoData()}</>
            )}
          </div>
          <div className="overviewContent py-2 px-1 w-25">
            <div className="d-flex">
              <div className="overviewHeaders w-50" title="DEALS CREATED">
                DEALS CREATED
              </div>
            </div>
            {totalData?.getDealsCreated?.length > 0 ? (
              <>
                <div className="d-flex gap-3 align-items-center mt-2">
                  <div className="fs-5">
                    {totalData.getDealsCreated[0]?.currentMonthData}
                  </div>

                  <div
                    style={{
                      fontSize: "14px",
                    }}
                    className={` ${
                      totalData?.getDealsCreated[0]?.percentage?.includes("-")
                        ? "text-danger"
                        : "text-success"
                    }`}
                  >
                    <span>{totalData.getDealsCreated[0]?.percentage}%</span>
                  </div>
                </div>
                <div className="pt-3">
                  <div
                    style={{ color: "#616E88", fontSize: "14px" }}
                    className="wrap_text"
                  >
                    Last Month Relative:&nbsp;
                    {totalData.getDealsCreated[0]?.lastMonthRelative}
                  </div>
                </div>
              </>
            ) : (
              <>{showNoData()}</>
            )}
          </div>
          <div className="overviewContent py-2 px-1 w-25">
            <div className="d-flex">
              <div className="overviewHeaders w-50" title="DEALS IN PIPELINE">
                DEALS IN PIPELINE
              </div>
            </div>
            {totalData?.getDealsInPipeline?.length > 0 ? (
              <div className="mt-2 fs-5">
                {totalData?.getDealsInPipeline[0]?.dealsInPipeline}
              </div>
            ) : (
              <>{showNoData()}</>
            )}
          </div>
          <div className="overviewContent py-2 px-1 w-25">
            <div className="d-flex">
              <div className="overviewHeaders w-50" title="REVENUE LOST">
                REVENUE LOST
              </div>
            </div>
            {totalData?.getRevenueLost?.length > 0 ? (
              <>
                <div className="d-flex gap-3 align-items-center mt-2">
                  <div className="fs-5">
                    ₹{" "}
                    {formatNumber(
                      totalData.getRevenueLost[0]?.currentMonthData
                    )}
                  </div>

                  <div
                    style={{
                      fontSize: "14px",
                    }}
                    className={` ${
                      totalData?.getRevenueLost[0]?.percentage?.includes("-")
                        ? "text-danger"
                        : "text-success"
                    }`}
                  >
                    <span>{totalData.getRevenueLost[0]?.percentage}%</span>
                  </div>
                </div>
                <div className="pt-3">
                  <div
                    style={{ color: "#616E88", fontSize: "14px" }}
                    className="wrap_text"
                  >
                    Last Month Relative:&nbsp;
                    {formatNumber(
                      totalData.getRevenueLost[0]?.lastMonthRelative
                    )}
                  </div>
                </div>
              </>
            ) : (
              <>{showNoData()}</>
            )}
          </div>
        </div>

        <div className="mt-2 d-flex gap-2">
          <div className="overviewContent py-2 px-1 w-25">
            <div className="d-flex">
              <div className="overviewHeaders w-50" title="REVENUE BY USERS">
                REVENUE BY USERS
              </div>
            </div>
            <div className="">
              {totalData?.getRevenueByUsers?.length > 0 ? (
                <div
                  className="w-100 analytics_table  border rounded-2"
                  style={{
                    overflow: "auto",
                    height: "50vh",
                  }}
                >
                  <table className="table responsive">
                    <thead>
                      <tr className="w-100 text-wrap">
                        <th className="fw-bold">Deal Owner</th>
                        <th className="fw-bold">Sum Of Amount</th>
                      </tr>
                    </thead>
                    <tbody className="">
                      {totalData?.getRevenueByUsers?.map((comp) => {
                        return (
                          <tr className="w-100" key={comp.dealOwner}>
                            <td
                              className="overflow_text"
                              style={{ maxWidth: "8vw" }}
                              title={comp.dealOwner}
                            >
                              {comp.dealOwner}
                            </td>
                            <td
                              className="overflow_text"
                              style={{ maxWidth: "8vw" }}
                              title={comp.sumOfAmount}
                            >
                              ₹ {formatNumber(comp.sumOfAmount)}
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              ) : (
                <>{showNoData()}</>
              )}
            </div>
          </div>
          <div className="overviewContent py-2 px-1 w-50">
            <div className="d-flex">
              <div className="overviewHeaders w-50" title="DEALS BY STAGES">
                DEALS BY STAGES
              </div>
            </div>
            <div className="mt-2 h-100 w-100">
              {totalData?.getDealsByStages?.length > 0 ? (
                <FunnelChart
                  currentView={currentView}
                  data={totalData.getDealsByStages}
                />
              ) : (
                <>{showNoData()}</>
              )}
            </div>
          </div>
          <div className="overviewContent py-2 px-1 w-25">
            <div className="d-flex">
              <div
                className="overviewHeaders w-50"
                title="OPEN AMOUNT BY USERS"
              >
                OPEN AMOUNT BY USERS
              </div>
            </div>
            <div className="">
              {totalData?.getOpenAmountByUsers?.length > 0 ? (
                <div
                  className="w-100 analytics_table border rounded-2"
                  style={{
                    overflow: "auto",
                    height: "50vh",
                  }}
                >
                  <table className="table responsive">
                    <thead>
                      <tr className="w-100 text-wrap">
                        <th className="fw-bold">Deal Owner</th>
                        <th className="fw-bold">Sum Of Amount</th>
                      </tr>
                    </thead>
                    <tbody className="">
                      {totalData?.getOpenAmountByUsers?.map((comp) => {
                        return (
                          <tr className="w-100" key={comp.dealOwner}>
                            <td
                              className="overflow_text"
                              style={{ maxWidth: "5.2vw" }}
                              title={comp.dealOwner}
                            >
                              {comp.dealOwner}
                            </td>
                            <td
                              className="overflow_text d-flex justify-content-between border-0"
                              style={{ maxWidth: "10vw" }}
                            >
                              <span title={comp.sumOfAmount}>
                                ₹{formatNumber(comp.sumOfAmount)}
                              </span>
                              <span className="ps-1 text-success">
                                {comp.percentage}%
                              </span>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              ) : (
                <>{showNoData()}</>
              )}
            </div>
          </div>
        </div>

        <div className="overviewContent py-2 px-1 w-100 mt-2">
          <div className="d-flex">
            <div className="overviewHeaders w-50" title="AMOUNT BY STAGE">
              AMOUNT BY STAGE
            </div>
          </div>
          <div className="mt-2" style={{ height: "50vh" }}>
            {totalData?.getAmountByStage?.length > 0 ? (
              <ColumnChart
                currentView={currentView}
                showTitles={true}
                data={totalData.getAmountByStage.map((ele) => {
                  return {
                    recordCount: parseFloat(ele.stageAmount),
                    createdTime: ele.stage,
                  };
                })}
                title={{ x: "Stage", y: "Stage Amount" }}
              />
            ) : (
              <>{showNoData()}</>
            )}
          </div>
        </div>

        <div className="overviewContent py-2 px-1 w-100 mt-2">
          <div className="d-flex">
            <div
              className="overviewHeaders w-50"
              title="AMOUNT BY LEAD SOURCE
"
            >
              AMOUNT BY LEAD SOURCE
            </div>
          </div>
          <div className="mt-2 w-100" style={{ height: "50vh" }}>
            {totalData?.getAmountByLeadSource?.length > 0 ? (
              <ColumnChart
                currentView={currentView}
                showTitles={true}
                data={totalData.getAmountByLeadSource.map((ele) => {
                  return {
                    recordCount: parseFloat(ele.sumOfAmount),
                    createdTime: ele.leadSource,
                  };
                })}
                title={{ x: "Lead Source", y: "Sum Of Amount" }}
              />
            ) : (
              <>{showNoData()}</>
            )}
          </div>
        </div>
      </div>
    );
  };

  const marketingMetrics = () => {
    return (
      <div>
        <div className="mt-1 d-flex w-100 justify-content-between gap-2">
          <div
            className="overviewContent py-2 px-1"
            style={{ width: "16.6vw" }}
          >
            <div className="d-flex">
              <div className="overviewHeaders w-50" title="TOTAL CAMPAIGNS">
                TOTAL CAMPAIGNS
              </div>
            </div>
            {totalData?.totalCampaigns?.length > 0 ? (
              <>
                <div className="d-flex gap-3 align-items-center mt-2">
                  <div className="">
                    {totalData.totalCampaigns[0]?.currentYearData}
                  </div>

                  <div
                    style={{
                      fontSize: "14px",
                    }}
                    className={` ${
                      totalData?.totalCampaigns[0]?.percentage?.includes("-")
                        ? "text-danger"
                        : "text-success"
                    }`}
                  >
                    {/* <span className="triangleGreen"></span>{" "} */}
                    <span>{totalData.totalCampaigns[0]?.percentage}%</span>
                  </div>
                </div>
                <div className="pt-3">
                  <div
                    style={{ color: "#616E88", fontSize: "14px" }}
                    className="wrap_text"
                  >
                    Last Year Relative:&nbsp;
                    {totalData.totalCampaigns[0]?.lastYearRelative}
                  </div>
                </div>
              </>
            ) : (
              <>{showNoData()}</>
            )}
          </div>
          <div
            className="overviewContent py-2 px-1"
            style={{ width: "16.6vw" }}
          >
            <div className="d-flex">
              <div className="overviewHeaders w-50" title="ACTUAL COST">
                ACTUAL COST
              </div>
            </div>
            {totalData?.actualCost?.length > 0 ? (
              <>
                <div className="d-flex gap-3 align-items-center mt-2">
                  <div className="">
                    ₹ {formatNumber(totalData.actualCost[0]?.currentYearData)}
                  </div>

                  <div
                    style={{
                      fontSize: "14px",
                    }}
                    className={` ${
                      totalData?.actualCost[0]?.percentage?.includes("-")
                        ? "text-danger"
                        : "text-success"
                    }`}
                  >
                    <span>{totalData.actualCost[0]?.percentage}%</span>
                  </div>
                </div>
                <div className="pt-3">
                  <div
                    style={{ color: "#616E88", fontSize: "14px" }}
                    className="wrap_text"
                  >
                    Last Year Relative:&nbsp;
                    {totalData.actualCost[0]?.lastYearRelative}
                  </div>
                </div>
              </>
            ) : (
              <>{showNoData()}</>
            )}
          </div>
          <div
            className="overviewContent py-2 px-1"
            style={{ width: "16.6vw" }}
          >
            <div className="d-flex">
              <div className="overviewHeaders w-50" title="BUDGETED COST">
                BUDGETED COST
              </div>
            </div>
            {totalData?.budgetedCost?.length > 0 ? (
              <>
                <div className="d-flex gap-3 align-items-center mt-2">
                  <div className="">
                    ₹ {formatNumber(totalData.budgetedCost[0]?.currentYearData)}
                  </div>

                  <div
                    style={{
                      fontSize: "14px",
                    }}
                    className={` ${
                      totalData?.budgetedCost[0]?.percentage?.includes("-")
                        ? "text-danger"
                        : "text-success"
                    }`}
                  >
                    <span>{totalData.budgetedCost[0]?.percentage}%</span>
                  </div>
                </div>
                <div className="pt-3">
                  <div
                    style={{ color: "#616E88", fontSize: "14px" }}
                    className="wrap_text"
                  >
                    Last Year Relative:&nbsp;
                    {totalData.budgetedCost[0]?.lastYearRelative}
                  </div>
                </div>
              </>
            ) : (
              <>{showNoData()}</>
            )}
          </div>
          <div
            className="overviewContent py-2 px-1"
            style={{ width: "16.6vw" }}
          >
            <div className="d-flex">
              <div className="overviewHeaders w-50" title="EXPECTED REVENUE">
                EXPECTED REVENUE
              </div>
            </div>
            {totalData?.expectedRevenue?.length > 0 ? (
              <>
                <div className="d-flex gap-3 align-items-center mt-2">
                  <div className="">
                    ₹{" "}
                    {formatNumber(
                      totalData.expectedRevenue[0]?.currentYearData
                    )}
                  </div>

                  <div
                    style={{
                      fontSize: "14px",
                    }}
                    className={` ${
                      totalData?.expectedRevenue[0]?.percentage?.includes("-")
                        ? "text-danger"
                        : "text-success"
                    }`}
                  >
                    <span>{totalData.expectedRevenue[0]?.percentage}%</span>
                  </div>
                </div>
                <div className="pt-3">
                  <div
                    style={{ color: "#616E88", fontSize: "14px" }}
                    className="wrap_text"
                  >
                    Last Year Relative:&nbsp;
                    {totalData.expectedRevenue[0]?.lastYearRelative}
                  </div>
                </div>
              </>
            ) : (
              <>{showNoData()}</>
            )}
          </div>
        </div>

        <div className="mt-2 d-flex gap-2 w-100">
          <div
            className="overviewContent p-2 w-50"
            style={{ borderRadius: "5px", height: "50vh" }}
          >
            <div className="d-flex">
              <div className="overviewHeaders w-50" title="CAMPAIGNS BY TYPE">
                CAMPAIGNS BY TYPE
              </div>
            </div>
            <div className="mt-2 h-100 w-100">
              {totalData?.campaignsByType?.length > 0 ? (
                // <ColumnChart
                //   currentView={currentView}
                //   // data={totalData.campaignsByType}
                //   data={totalData.campaignsByType.map((ele) => {
                //     return {
                //       recordCount: parseFloat(ele.recordCount),
                //       createdTime: ele.campaignName,
                //     };
                //   })}
                //   title={{ x: "Campaign Name", y: "Record Count" }}
                //   showTitles={true}
                // />
                <StackedBarChart
                  data={totalData?.campaignsByType}
                  currentView={currentView}
                  title={{ x: "Campaign Name", y: "Record Count" }}
                  showTitles={true}
                />
              ) : (
                <>{showNoData()}</>
              )}
            </div>
          </div>
          <div
            className="overviewContent p-2 w-50"
            style={{ borderRadius: "5px", height: "50vh" }}
          >
            <div className="d-flex">
              <div
                className="overviewHeaders w-50"
                title="CAMPAIGNS BY REVENUE AMOUNT"
              >
                CAMPAIGNS BY REVENUE AMOUNT
              </div>
            </div>
            <div className="mt-2 h-100">
              {totalData?.campaignsByRevenueAmount?.length > 0 ? (
                <ColumnChart
                  currentView={currentView}
                  data={totalData.campaignsByRevenueAmount}
                  showTitles={true}
                />
              ) : (
                <>{showNoData()}</>
              )}
            </div>
          </div>
        </div>

        <div className="overviewContent py-2 px-1 w-100 mt-2">
          <div className="d-flex">
            <div className="overviewHeaders w-50" title="CAMPAIGNS BY LEADS">
              CAMPAIGNS BY LEADS
            </div>
          </div>
          <div className="mt-2" style={{ height: "50vh" }}>
            {totalData?.campaignsByLeads?.length > 0 ? (
              <ColumnChart
                currentView={currentView}
                showTitles={true}
                data={totalData.campaignsByLeads}
                // title={{ x: "Campaign Name", y: "Leads" }}
              />
            ) : (
              <>{showNoData()}</>
            )}
          </div>
        </div>
      </div>
    );
  };

  const salesTrend = () => {
    return (
      <div
        className="mt-1"
        onClick={() => {
          // setCloseDropdown("");
        }}
      >
        <div
          className="bg-white border_radius p-2 multiline_chart_container"
          style={{ width: "65vw" }}
        >
          <CommonMultiLine
            id="1"
            title="Leads Creation"
            dataObj={totalData.getLeadsCreation}
            selectOverview={selectOverview}
            setCloseDropdown={setCloseDropdown}
            closeDropdown={closeDropdown}
          />
        </div>

        <div
          className="bg-white border_radius p-2 multiline_chart_container mt-3"
          style={{ width: "65vw" }}
        >
          <CommonMultiLine
            id="2"
            setCloseDropdown={setCloseDropdown}
            closeDropdown={closeDropdown}
            title="Leads Conversion"
            dataObj={totalData.getLeadsConversion}
            selectOverview={selectOverview}
          />
        </div>
        <div
          className="bg-white border_radius p-2 multiline_chart_container mt-3"
          style={{ width: "65vw" }}
        >
          <CommonMultiLine
            id="3"
            title="Contacts Creation"
            dataObj={totalData.getContactsCreation}
            selectOverview={selectOverview}
            setCloseDropdown={setCloseDropdown}
            closeDropdown={closeDropdown}
          />
        </div>
        <div
          className="bg-white border_radius p-2 multiline_chart_container mt-3"
          style={{ width: "65vw" }}
        >
          <CommonMultiLine
            id="4"
            title="Deals Creation"
            dataObj={totalData.getDealsCreation}
            selectOverview={selectOverview}
            setCloseDropdown={setCloseDropdown}
            closeDropdown={closeDropdown}
          />
        </div>
        <div
          className="bg-white border_radius p-2 multiline_chart_container mt-3"
          style={{ width: "65vw" }}
        >
          <CommonMultiLine
            id="5"
            title="Deals Closing By Count"
            dataObj={totalData.getDealsClosingByCount}
            selectOverview={selectOverview}
            setCloseDropdown={setCloseDropdown}
            closeDropdown={closeDropdown}
          />
        </div>
        <div
          className="bg-white border_radius p-2 multiline_chart_container mt-3"
          style={{ width: "65vw" }}
        >
          <CommonMultiLine
            id="6"
            title="Deals Closing By Amount"
            dataObj={totalData.getDealsClosingByAmount}
            selectOverview={selectOverview}
            setCloseDropdown={setCloseDropdown}
            closeDropdown={closeDropdown}
          />
        </div>
      </div>
    );
  };

  const salesFollowupTrend = () => {
    return (
      <div className="mt-1">
        <div
          className="bg-white border_radius p-2 multiline_chart_container"
          style={{ width: "65vw" }}
        >
          <CommonMultiLine
            title="Task Closure"
            dataObj={totalData.getTaskClosureData}
            selectOverview={selectOverview}
            id="11"
            setCloseDropdown={setCloseDropdown}
            closeDropdown={closeDropdown}
          />
        </div>
        <div
          className="bg-white border_radius p-2 multiline_chart_container mt-3"
          style={{ width: "65vw" }}
        >
          <CommonMultiLine
            title="Meeting Creation"
            dataObj={totalData.getMeetingCreationData}
            selectOverview={selectOverview}
            id="12"
            setCloseDropdown={setCloseDropdown}
            closeDropdown={closeDropdown}
          />
        </div>
        <div
          className="bg-white border_radius p-2 multiline_chart_container mt-3"
          style={{ width: "65vw" }}
        >
          <CommonMultiLine
            title="Calls Completed"
            dataObj={totalData.getCallCompletedData}
            selectOverview={selectOverview}
            id="13"
            setCloseDropdown={setCloseDropdown}
            closeDropdown={closeDropdown}
          />
        </div>
        <div
          className="bg-white border_radius p-2 multiline_chart_container mt-3"
          style={{ width: "65vw" }}
        >
          {/* <div className="leadsHeader">Notes Creation</div>
          <div className="w-100 h-100">
            <MultiLineChart data={totalData.getNotesCreationData} />
          </div> */}
          <CommonMultiLine
            title="Notes Creation"
            dataObj={totalData.getNotesCreationData}
            selectOverview={selectOverview}
            id="14"
            setCloseDropdown={setCloseDropdown}
            closeDropdown={closeDropdown}
          />
        </div>
      </div>
    );
  };

  const activityStats = () => {
    return (
      <div>
        <div className="mt-1 d-flex w-100 justify-content-between gap-2">
          <div className="overviewContent py-2 px-1 w-25">
            <div className="d-flex">
              <div className="overviewHeaders w-50" title="OPEN TASKS">
                OPEN TASKS
              </div>
            </div>
            {totalData?.getOpenTasks?.length > 0 ? (
              <div className="mt-2 fs-5">
                {totalData?.getOpenTasks[0]?.recordsCount}
              </div>
            ) : (
              <>{showNoData()}</>
            )}
          </div>
          <div className="overviewContent py-2 px-1 w-25">
            <div className="d-flex">
              <div className="overviewHeaders w-50" title="TASKS COMPLETED">
                TASKS COMPLETED
              </div>
            </div>
            {totalData?.getTasksCompleted?.length > 0 ? (
              <>
                <div className="d-flex gap-3 align-items-center mt-2">
                  <div className="">
                    {totalData.getTasksCompleted[0]?.recordsCount}
                  </div>

                  <div
                    style={{
                      fontSize: "14px",
                    }}
                    className={` ${
                      typeof totalData?.getTasksCompleted[0]?.percentage ===
                      "string"
                        ? totalData?.getTasksCompleted[0]?.percentage?.includes(
                            "-"
                          )
                          ? "text-danger"
                          : "text-success"
                        : "text-success"
                    }`}
                  >
                    {/* <span className="triangleGreen"></span>{" "} */}
                    <span>{totalData.getTasksCompleted[0]?.percentage}%</span>
                  </div>
                </div>
                <div className="pt-3">
                  <div
                    style={{ color: "#616E88", fontSize: "14px" }}
                    className="wrap_text"
                  >
                    Last Month Relative:&nbsp;
                    {totalData.getTasksCompleted[0]?.lastMonthRelative}
                  </div>
                </div>
              </>
            ) : (
              <>{showNoData()}</>
            )}
          </div>
          <div className="overviewContent py-2 px-1 w-25">
            <div className="d-flex">
              <div className="overviewHeaders w-50" title="CALLS THIS WEEK">
                CALLS THIS WEEK
              </div>
            </div>
            {totalData?.getCallsThisWeek?.length > 0 ? (
              <div className="mt-2 fs-5">
                {totalData?.getCallsThisWeek[0]?.recordsCount}
              </div>
            ) : (
              <>{showNoData()}</>
            )}
          </div>
          <div className="overviewContent py-2 px-1 w-25">
            <div className="d-flex">
              <div className="overviewHeaders w-50" title="MEETINGS THIS MONTH">
                MEETINGS THIS MONTH
              </div>
            </div>
            {totalData?.getMeetingsThisMonth?.length > 0 ? (
              <div className="mt-2 fs-5">
                {totalData?.getMeetingsThisMonth[0]?.recordsCount}
              </div>
            ) : (
              <>{showNoData()}</>
            )}
          </div>
        </div>

        <div className="mt-2 d-flex gap-2">
          <div className="w-25">
            <div className="overviewContent py-2 px-1 w-100">
              <div className="d-flex">
                <div className="overviewHeaders w-50" title="OVERDUE TASKS">
                  OVERDUE TASKS
                </div>
              </div>
              <div className="">
                {totalData?.getOverdueTasks?.length > 0 ? (
                  <div className="mt-2 fs-5">
                    {totalData?.getOverdueTasks[0]?.recordsCount}
                  </div>
                ) : (
                  <>{showNoData()}</>
                )}
              </div>
            </div>
            <div className="overviewContent py-2 px-1 w-100 mt-2">
              <div className="d-flex">
                <div className="overviewHeaders w-50" title="TASKS BY USERS">
                  TASKS BY USERS
                </div>
              </div>
              <div className="">
                {totalData?.getTasksByUsers?.length > 0 ? (
                  <div
                    className="w-100 analytics_table border rounded-2"
                    style={{
                      overflow: "auto",
                      height: "50vh",
                    }}
                  >
                    <table className="table responsive">
                      <thead>
                        <tr className="w-100 text-wrap">
                          <th className="fw-bold">Task Owner</th>
                          <th className="fw-bold">Record Count</th>
                        </tr>
                      </thead>
                      <tbody className="">
                        {totalData?.getTasksByUsers?.map((comp, i) => {
                          return (
                            <tr className="w-100" key={i}>
                              <td
                                className="overflow_text"
                                style={{ maxWidth: "6vw" }}
                                title={comp.taskOwner}
                              >
                                {comp.taskOwner}
                              </td>
                              <td>
                                <span>{comp.recordsCount}</span>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                ) : (
                  <>{showNoData()}</>
                )}
              </div>
            </div>
          </div>
          <div className="overviewContent py-2 px-1 w-75">
            <div className="d-flex">
              <div className="overviewHeaders w-50" title="CALLS BY PURPOSE">
                CALLS BY PURPOSE
              </div>
            </div>
            <div className="mt-2 h-100 w-100">
              {totalData?.getCallsByPurpose?.length > 0 ? (
                <ColumnChart
                  currentView={currentView}
                  data={totalData.getCallsByPurpose}
                  showTitles={true}
                  // title={{ x: "Campaign Name", y: "Leads" }}
                />
              ) : (
                <>{showNoData()}</>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div>
      <div className="d-flex gap-2">
        <div
          style={{ backgroundColor: "white", minHeight: "78vh", width: "15vw" }}
          className="ms-2 me-2 rounded-1"
        >
          <div
            className={
              selectOverview === "org_overview"
                ? "selectedOverview mt-2"
                : "unselectedOverview mt-2"
            }
            onClick={() => {
              getAnalyticDetails("org_overview");
            }}
          >
            Org Overview
          </div>
          <div
            className={
              selectOverview === "lead_analytics"
                ? "selectedOverview mt-2"
                : "unselectedOverview mt-2"
            }
            onClick={() => {
              getAnalyticDetails("lead_analytics");
            }}
          >
            Lead Analytics
          </div>
          <div
            className={
              selectOverview === "Deal_Insights"
                ? "selectedOverview mt-2"
                : "unselectedOverview mt-2"
            }
            onClick={() => {
              getAnalyticDetails("Deal_Insights");
            }}
          >
            Deal Insights
          </div>
          <div
            className={
              selectOverview === "marketing_metrics"
                ? "selectedOverview mt-2"
                : "unselectedOverview mt-2"
            }
            onClick={() => {
              getAnalyticDetails("marketing_metrics");
            }}
          >
            Marketing Metrics
          </div>
          <div
            className={
              selectOverview === "sales_trend"
                ? "selectedOverview mt-2"
                : "unselectedOverview mt-2"
            }
            onClick={() => {
              setSelectOverview("sales_trend");
              // getAnalyticDetails("sales_trend");
            }}
          >
            Sales Trend
          </div>
          <div
            className={
              selectOverview === "sales_follow_up_trend"
                ? "selectedOverview mt-2"
                : "unselectedOverview mt-2"
            }
            onClick={() => {
              setSelectOverview("sales_follow_up_trend");
              // getAnalyticDetails("sales_follow_up_trend");
            }}
          >
            Sales Follow-up Trend
          </div>

          <div
            className={
              selectOverview === "activity_stats"
                ? "selectedOverview mt-2"
                : "unselectedOverview mt-2"
            }
            onClick={() => {
              // setSelectOverview("activity_statistics");
              getAnalyticDetails("activity_stats");
            }}
          >
            Activity Statistics
          </div>
        </div>
        <div>
          <div
            className="d-flex justify-content-between align-items-center"
            style={{ width: "66vw", height: "8vh" }}
          >
            <div className="d-flex gap-2 align-items-center">
              <h5>{convertSnakeCaseToReadableString(selectOverview)}</h5>
              {/* {selectOverview === "org_overview" && <h5>Org Overview</h5>}
              {selectOverview === "lead_analytics" && <h5>Lead Analytics</h5>}
              {selectOverview === "Deal_Insights" && <h5>Deal Insights</h5>}
              {selectOverview === "sales_trend" && <h5>Sales Trend</h5>}
              {selectOverview === "sales_follow_up_trend" && (
                <h5>Sales Follow-up Trend</h5>
              )}
              {selectOverview === "activity_stats" && (
                <h5>Activity Stats</h5>
              )} */}

              {/* {["sales_trend", "Sales", "sales_follow_up_trend"].includes(
                selectOverview
              ) && (
                <div
                  className="bg-white p-1"
                  style={{
                    borderRadius: "5px",
                    border: "1px solid #515151",
                    fontSize: "12px",
                  }}
                >
                  All Users
                </div>
              )} */}
            </div>
            {/* {selectOverview !== "Trend" && selectOverview !== "Sales" && (
              <div className="d-flex gap-2">
                <div>
                  <button
                    className="addComponent p-2"
                    onClick={() => {
                      setModalType("add_component");
                    }}
                  >
                    Add Component
                  </button>
                </div>
                <div>
                  <p className="optionMenu cursorPointer">···</p>
                </div>
              </div>
            )} */}
          </div>
          {totalData !== null && !apiLoader && (
            <>
              {selectOverview === "org_overview" && orgOverview()}
              {selectOverview === "lead_analytics" && leadAnalyticsOverview()}
              {selectOverview === "Deal_Insights" && dealInsights()}
              {selectOverview === "marketing_metrics" && marketingMetrics()}
              {selectOverview === "sales_trend" && salesTrend()}
              {selectOverview === "sales_follow_up_trend" &&
                salesFollowupTrend()}
              {selectOverview === "activity_stats" && activityStats()}
            </>
          )}
        </div>
      </div>
      <Modal
        show={modalType === "add_component"}
        aria-labelledby="contained-modal-title-vcenter"
        onClick={(e) => e.stopPropagation()}
      >
        <Modal.Header
          className=" border-0 mb-0 d-flex flex-column align-items-start"
          style={{ backgroundColor: "#F2F2F2" }}
        >
          <div
            className="d-flex justify-content-between w-100"
            style={{ background: "rgba(242, 242, 242, 1)" }}
          >
            <div>
              <h4 className="commonTextColor">Add Component</h4>
            </div>
            <div>
              <img
                src={cancelCircle}
                alt="cancel"
                style={{
                  width: "25px",
                  height: "25px",
                }}
                className="cursorPointer"
                onClick={() => {
                  setModalType("");
                }}
              />
            </div>
          </div>
        </Modal.Header>
        <Modal.Body className="ModalBody">
          <div className="d-flex gap-2 mb-3 mt-2 align-items-center w-100">
            <div className="w-100">
              <div className="d-flex flex-column ps-1 align-items-center mx-4">
                <label className="text-start w-100 opacity-75">Get From</label>
                <div
                  className="position-relative w-100 border rounded-1"
                  // style={{ border: "1px solid rgba(185, 185, 185, 0.5)" }}
                >
                  <Select
                    menuPlacement="auto"
                    value={getFormType}
                    options={getFromOptions}
                    onChange={(option) => {
                      setGetFormType(option);
                      setModuleType(
                        option.value === "Dashboard"
                          ? dashboardModules[0]
                          : customModules[0]
                      );
                      setComponentType(
                        componentData[
                          option.value === "Dashboard"
                            ? dashboardModules[0].value
                            : customModules[0].value
                        ][0]
                      );
                    }}
                  />
                </div>
                <div className="w-100 mx-auto d-flex justify-content-center mt-4">
                  <div className="w-100">
                    <label className="opacity-75" htmlFor="massValue">
                      Select Module
                    </label>
                    <div
                      className="position-relative w-100 border rounded-1"
                      // style={{ border: "1px solid rgba(185, 185, 185, 0.5)" }}
                    >
                      <Select
                        menuPlacement="auto"
                        value={moduleType}
                        options={
                          getFormType.value === "Dashboard"
                            ? dashboardModules
                            : customModules
                        }
                        onChange={(option) => {
                          setModuleType(option);
                          setComponentType(componentData[option.value][0]);
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className="w-100 mx-auto d-flex justify-content-center mt-4">
                  <div className="w-100">
                    <label className="opacity-75" htmlFor="massValue">
                      Custom View
                    </label>
                    <div className="position-relative w-100 border rounded-1">
                      <Select
                        menuPlacement="auto"
                        value={componentType}
                        options={componentData[moduleType.value]}
                        onChange={(option) => {
                          setComponentType(option);
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className="w-100 mx-auto d-flex justify-content-center mt-4">
                  <div className="w-100">
                    <label className="opacity-75" htmlFor="massValue">
                      Component Name <span className="text-danger">*</span>
                    </label>
                    <div className="position-relative w-100 border rounded-1">
                      {/* <Select menuPlacement="auto" /> */}
                      <input
                        className="w-100 border-0 py-2 rounded-1"
                        type="text"
                        value={componentName}
                        onChange={(e) => {
                          setComponentName(e.target.value);
                        }}
                        style={{ outline: "none" }}
                        id="componentName"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className={`pt-4 `}>
            <div className="d-flex justify-content-end w-100 gap-4 pe-4">
              <button
                className="cancelButton px-4"
                onClick={() => {
                  setModalType("");
                }}
              >
                Cancel
              </button>
              <button
                className={`createContactButtonSave 
              `}
                // disabled={componentName.length < 2 || apiLoader}
                // onClick={() => {
                //   addDashboardData();
                // }}
              >
                Save
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default Analytics;

import axios from "axios";
import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import Select from "react-select";
import { URI } from "../constants";
// import profile from "../Assets/Frame 12.svg";
import profileAvatar from "../Assets/Lead_Avatar.png";
import crossIcon from "../Assets/CrossLead.svg";
import { Tooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";
import alert from "../Assets/alert-circle (1).svg";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import { useSelector } from "react-redux";
import Lottie from "react-lottie";
import { defaultOptions } from "../utils/defaultOptions";
import getHeaders from "../common/ApiHeader.js";
import { useNavigate } from "react-router-dom";
import { handleApiError } from "../utils/ApiUtils.js";

const ShareModal = ({
  shareModal,
  setShareModal,
  shareUserList,
  shareGroupList,
  shareRoleList,
  sharePublicList,
  id,
  moduleType,
  modalHeading,
  shareInfoModal,
  setShareInfoModal,
  shareMemberIds,
  getOverview,
  module,

}) => {
  const permissionOptions = [
    { value: "Full Access", label: "Full Access" },
    { value: "Read Write", label: "Read Write" },
    { value: "Read Only", label: "Read Only" },
  ];

  const userOptions = [
    { value: "User", label: "User" },
    { value: "Role", label: "Role" },
    { value: "Groups", label: "Groups" },
  ];

  const userList = JSON.parse(sessionStorage.getItem("userList"));
  const navigate = useNavigate();
  var filteredUserList = "";

  const [recordAccessType, setRecordAccessType] = useState("Private");
  const [addMemberModal, setAddMemberModal] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [withRelatedList, setWithRelatedList] = useState(false);
  const [mangeShareModal, setMangeShareModal] = useState(false);
  const [selectedPermissionOption, setSelectedPermissionOption] = useState(
    permissionOptions[0]
  );
  const [selectMemberOption, setSelectMemberOption] = useState(userOptions[0]);
  const [addedUsers, setAddedUsers] = useState([]);
  const [rolesList, setRolesList] = useState([]);
  const [addedRole, setAddedRole] = useState([]);
  const [addedGroup, setAddedGroup] = useState([]);
  const [deleteModal, setDeleteModal] = useState(false);
  const [groupListData, setGroupListData] = useState([]);

  // lottie loader state
  const [apiLoader, setApiLoader] = useState(false);
  const reduxStore = useSelector((state) => state.reduxState);
  const { loginUserId } = reduxStore;

  const addUser = (user) => {
    if (!addedUsers.some((addedUser) => addedUser.emailId === user.emailId)) {
      setAddedUsers([...addedUsers, user]);
    }
  };

  const removeUser = (user) => {
    setAddedUsers(addedUsers.filter((u) => u.emailId !== user.emailId));
  };

  const customSelectLocationStyles = {
    menu: (base) => ({
      ...base,
      width: "100%",
      zIndex: 10,
    }),
    option: (provided) => ({
      ...provided,
      color: "#000",
      backgroundColor: "#fff",
      width: "100%",
      zIndex: "10",
    }),
    control: (baseStyles) => ({
      ...baseStyles,
      border: "2px solid #e2e5eb",
      backgroundColor: "#fff",
      height: "40px",
      borderRadius: "10px",
      width: "100%",
      boxShadow: "none",
    }),
    input: (base) => ({
      ...base,
      color: "#FFFFFF",
    }),
    singleValue: (defaultStyles) => ({ ...defaultStyles, color: "#000" }),

    menuList: (provided) => ({
      ...provided,
      paddingTop: 0,
      paddingBottom: 0,
      "&::-webkit-scrollbar": {
        width: 5,
      },

      "&::-webkit-scrollbar-thumb": {
        background: "#00EEFD",
        borderRadius: "10px",
      },
    }),
    dropdownIndicator: (base) => ({
      ...base,
      color: "#316AFF", // Custom colour
    }),
  };

  const handleSearchChange = (e) => {
    const { value } = e.target;
    if (value.length === 0 || value.charAt(0) !== " ") {
      setSearchQuery(e.target.value);
    }
  };

  const getGroupListDetails = async () => {
    const headersResult = getHeaders(true);

    if (headersResult.error) {
      navigate("../");
      return;
    }
    await axios
      .get(URI.getGroupList + "/" + loginUserId+"/"+ id, {
        headers: headersResult,
      })

      .then((response) => {
        if (response.data.status === 200) {
          setGroupListData(response.data.data);
        } else {
          NotificationManager.error("", response.data.message, 5000);
        }
      })
      .catch(async (error) => {
        handleApiError(error, navigate);
      });
  };

  const getAllRolesListDetails = async () => {
    const requestBody = {
      loggedInUserId: loginUserId,
      moduleId:id
    };
    const headersResult = getHeaders(true);

    if (headersResult.error) {
      navigate("../");
      return;
    }
    await axios
      .post(URI.getAllRoleList, requestBody, {
        headers: headersResult,
      })
      .then((response) => {
        if (response.data.status === 200) {
          const filteredData = response.data.data.roleList.filter(
            (item) => item.level !== 1
          );
          setRolesList(filteredData);
        } else {
          NotificationManager.error("", response.data.message, 5000);
        }
      })
      .catch((err) => {
        handleApiError(err, navigate);
      });
  };

  const addRole = (role) => {
    if (!addedRole.some((addedRole) => addedRole.roleName === role.roleName)) {
      setAddedRole([...addedRole, role]);
    }
  };

  const addGroup = (group) => {
    if (
      !addedGroup.some((addedGroup) => addedGroup.groupName === group.groupName)
    ) {
      setAddedGroup([...addedGroup, group]);
    }
  };

  const removeRole = (user) => {
    setAddedRole(addedRole.filter((u) => u.roleName !== user.roleName));
  };

  const removeGroup = (user) => {
    setAddedGroup(addedGroup.filter((u) => u.groupName !== user.groupName));
  };

  filteredUserList =
    userList !== null &&
    userList.filter((item) =>
      item.name?.toLowerCase().includes(searchQuery?.toLowerCase())
    );
  filteredUserList = filteredUserList.filter(
    (item) => item.userId !== loginUserId
  );

  if (shareUserList.length > 0) {
    const userIdsInArray2 = shareUserList.map((obj) => obj.userId);

    filteredUserList = filteredUserList.filter(
      (obj) => !userIdsInArray2.includes(obj.userId)
    );
  }

  const filteredRoleList = rolesList.filter((item) =>
    item.roleName?.toLowerCase().includes(searchQuery?.toLowerCase())
  );

  const filteredGroupList = groupListData.filter((item) =>
    item.groupName?.toLowerCase().includes(searchQuery?.toLowerCase())
  );

  const handleInsertShare = async () => {
    const memberLists =
      recordAccessType === "Private"
        ? [
            ...addedUsers.map((member) => ({
              addType: "Users",
              memberUserId: member.userId,
            })),
            ...addedRole.map((member) => ({
              addType: "Roles",
              memberUserId: member.roleId,
            })),
            ...addedGroup.map((member) => ({
              addType: "Groups",
              memberUserId: member.groupId,
            })),
          ]
        : [];

    const requestBody = {
      loggedInUserId: loginUserId,
      accessType: recordAccessType,
      moduleId: id,
      moduleType: moduleType,
      userPermission: selectedPermissionOption.value,
      withRelatedList: withRelatedList,
      memberList: memberLists,
    };
    const headersResult = getHeaders(true);

    if (headersResult.error) {
      navigate("../");
      return;
    }
    setApiLoader(true);
    await axios
      .post(URI.insertShare, requestBody, {
        headers: headersResult,
      })
      .then((response) => {
        if (response.data.status === 200) {
          NotificationManager.success("", response.data.message, 5000);
          setShareModal(false);
          setMangeShareModal(false);
          setAddedUsers([]);
          setAddedRole([]);
          setAddedGroup([]);
          setRecordAccessType("Private");
          setSelectedPermissionOption(permissionOptions[0]);
          setWithRelatedList(false);
          getOverview(id);
        } else {
          NotificationManager.error("", response.data.message, 5000);
        }
      })
      .catch((error) => {
        handleApiError(error, navigate);
      })
      .finally(() => setApiLoader(false));
  };

  const memberListView = () => {
    if (selectMemberOption.value === "User") {
      return (
        <>
          {filteredUserList !== false &&
            filteredUserList.map((item, index) => (
              <div
                className="d-flex gap-2 align-items-center px-3 mt-2"
                key={index}
              >
                <div className="" style={{ width: "50px" }}>
                  <img
                    width={36}
                    height={36}
                    src={item.userImageData || profileAvatar}
                    alt="img"
                    className="cursorPointer position-relative rounded-circle"
                  />
                </div>
                <div className="d-flex flex-column flex-grow-1">
                  <p className="mb-0">{item.name}</p>
                  <p className="mb-0">
                    {item.emailId.length > 23
                      ? `${item.emailId.substring(0, 23)} ...`
                      : item.emailId}
                  </p>
                </div>
                <div style={{ width: "80px" }}>
                  <button className="add_btn" onClick={() => addUser(item)}>
                    Add
                  </button>
                </div>
              </div>
            ))}
        </>
      );
    } else if (selectMemberOption.value === "Role") {
      return (
        <>
          {filteredRoleList.map((item) => (
            <div
              className="d-flex justify-content-between px-2 py-2 roleList"
              key={item.id}
            >
              <span className="opacity-75">{item.roleName}</span>
              <button className="add_btn" onClick={() => addRole(item)}>
                Add
              </button>
            </div>
          ))}
        </>
      );
    } else {
      return (
        <>
          {filteredGroupList.map((item) => {
            return (
              <div
                className="d-flex justify-content-between px-2 py-2"
                key={item.id}
              >
                <span>{item.groupName}</span>
                <button className="add_btn" onClick={() => addGroup(item)}>
                  Add
                </button>
              </div>
            );
          })}
        </>
      );
    }
  };

  const revokeShareList = async () => {
    const requestBody = {
      moduleShareIds: shareMemberIds,
      status: "REVOKE",
      loggedInUserId: loginUserId,
    };
    const headersResult = getHeaders(true);
    if (headersResult.error) {
      navigate("../");
      return;
    }
    setApiLoader(true);
    await axios
      .post(URI.revokeShare, requestBody, {
        headers: getHeaders(true),
      })

      .then((response) => {
        if (response.data.status === 200) {
          NotificationManager.success("", response.data.message, 5000);
          setDeleteModal(false);
        } else {
          NotificationManager.error("", response.data.message, 5000);
        }
      })
      .catch(async (error) => {
        handleApiError(error, navigate);
      })
      .finally(() => {
        setShareInfoModal(false);
        setApiLoader(false);
      });
  };

  const disableButtonCondtion =
    recordAccessType === "Private" &&
    addedUsers.length === 0 &&
    addedRole.length === 0 &&
    addedGroup.length === 0;

  const hasSharedItems =
    (shareUserList && shareUserList.length > 0) ||
    (shareGroupList && shareGroupList.length > 0) ||
    (shareRoleList && shareRoleList.length > 0)||
    (sharePublicList && sharePublicList.length > 0);

  useEffect(() => {
    if (selectMemberOption.value === "User") {
      setAddedUsers([...addedUsers]);
    } else if (selectMemberOption.value === "Role") {
      getAllRolesListDetails();
    } else {
      getGroupListDetails();
    }
  }, [selectMemberOption]);

  return (
    <div>
      {hasSharedItems ? (
        <>
          <Modal
            show={shareInfoModal}
            className="d-flex justify-content-center align-items-center"
            aria-labelledby="example-custom-modal-styling-title"
            size="lg"
            id="commonLeadModal"
          >
            <Modal.Body className="ModalBody  overflow-auto">
              <div
                className="modalUS row justify-content-center"
                style={{
                  margin: "0",
                  gap: "10px",
                  paddingBottom: "10px",
                }}
              >
                <div className="header_Container d-flex justify-content-between">
                  <h4 className="commonTextColor pt-2">Sharing Info</h4>
                  {/* <div className="d-flex justify-content-start">
                <input
                  type="text"
                  className="inputBox"
                  placeholder="Search users"
                  value={searchQuery}
                  onChange={handleSearchChange}
                />
                <img src={SearchIcon} alt="search" className="SearchIcon" />
              </div> */}
                  <div className="inputContainer d-flex justify-content-start">
                    {/* <input
                      type="text"
                      placeholder="Search"
                      className="topBarModalSearch px-5"
                      onChange={handleSearchChange}
                      value={searchQuery}
                    /> */}
                    {/* <img src={SearchIcon} alt="search" className="searchIcon" /> */}
                  </div>
                </div>

                <div className="Owner_table_container rounded-4 h-25">
                  <table className="userdata-table w-100">
                    <thead>
                      <tr>
                        <th className="sharemodal_header_one">Users</th>
                        <th className="sharemodal_header_two">Record Type</th>
                        <th className="sharemodal_header_two">Permission</th>
                      </tr>
                    </thead>
                    <tbody className="table-body">
                      {shareUserList?.map((user, index) => (
                        <tr
                          key={index}
                          // onClick={() => {
                          //   setSelectedLead(user);
                          // }}
                          className="w-100"
                        >
                          <td> {user.users}</td>
                          <td>{user.recordType}</td>
                          <td>{user.permission}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
                <div className="Owner_table_container rounded-4 h-25">
                  <table className="userdata-table w-100">
                    <thead>
                      <tr>
                        <th className="sharemodal_header_one">Roles</th>
                        <th className="sharemodal_header_two">Record Type</th>
                        <th className="sharemodal_header_two">Permission</th>
                      </tr>
                    </thead>
                    <tbody className="table-body">
                      {shareRoleList?.map((user, index) => (
                        <tr
                          key={index}
                          // onClick={() => {
                          //   setSelectedLead(user);
                          // }}
                          className="w-100"
                        >
                          <td>{user.roles}</td>
                          <td>{user.recordType}</td>
                          <td>{user.permission}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
                <div className="Owner_table_container rounded-4 h-25">
                  <table className="userdata-table w-100">
                    <thead>
                      <tr>
                        <th className="sharemodal_header_one">Groups</th>
                        <th className="sharemodal_header_two">Record Type</th>
                        <th className="sharemodal_header_two">Permission</th>
                      </tr>
                    </thead>
                    <tbody className="table-body ">
                      {shareGroupList?.map((user, index) => (
                        <tr
                          key={index}
                          // onClick={() => {
                          //   setSelectedLead(user);
                          // }}
                        >
                          <td>{user.groups}</td>
                          <td>{user.recordType}</td>
                          <td>{user.permission}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>

                <div className="d-flex pb-2 pt-3 justify-content-between">
                  <div className="selectedUser d-flex gap-1 pt-2">
                    <span
                      className="text-danger cursorPointer"
                      onClick={() => {
                        setDeleteModal(true);
                        // setShareInfoModal(false);
                      }}
                    >
                      Revoke Sharing{" "}
                    </span>
                    {/* <img src={logoConvert} alt="img" height={25} width={25} />
                <span>{selectedLead.permission}</span> */}
                  </div>
                  <div className="d-flex gap-2">
                    <button
                      className="createContactButton px-2"
                      onClick={() => {
                        setShareInfoModal(false);
                        setSearchQuery("");
                      }}
                    >
                      Cancel
                    </button>
                    <button
                      className=" manageSharingBtn "
                      // style={{ width: "0px", padding: "0px" }}
                      onClick={() => {
                        setMangeShareModal(true);
                        setShareInfoModal(false);
                        // onSelectedLeadChange(selectedLead);
                      }}
                    >
                      Manage Sharing
                    </button>
                  </div>
                </div>
              </div>
            </Modal.Body>
          </Modal>
        </>
      ) : (
        <>
          {/* Share Modal */}
          <Modal
            show={shareModal}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
          >
            <Modal.Header
              // closeButton
              className="d-flex justify-content-between mb-0 pb-2"
            >
              {" "}
              <h4 className="commonTextColor">{modalHeading}</h4>
              <div
                onClick={() => {
                  setShareModal(false);
                  setAddedUsers([]);
                  setAddedGroup([]);
                  setAddedRole([]);
                  setRecordAccessType("Private");
                  setSelectedPermissionOption(permissionOptions[0]);
                  setWithRelatedList(false);
                  setSearchQuery("");
                  // setAddedGroup("")
                }}
                className="cursorPointer"
              >
                <img
                  src={crossIcon}
                  alt="cross"
                  style={{ cursor: "pointer" }}
                  className="mb-2"
                  width={22}
                />
              </div>
            </Modal.Header>
            <Modal.Body>
              <div className="d-flex flex-column gap-4 px-4">
                <div className="d-flex gap-2">
                  <p className="mb-0 me-2">Record access type</p>
                  <input
                    type="radio"
                    id="private"
                    name="RecordAccessType"
                    value="Private"
                    checked={recordAccessType === "Private"}
                    onChange={(e) => setRecordAccessType(e.target.value)}
                  />
                  <label htmlFor="private">Private</label>
                  <input
                    type="radio"
                    id="public"
                    name="RecordAccessType"
                    value="Public"
                    checked={recordAccessType === "Public"}
                    onChange={(e) => {
                      setRecordAccessType(e.target.value);
                      setAddedUsers([]);
                      setAddedRole([]);
                      setAddedGroup([]);
                      setSelectedPermissionOption(permissionOptions[0]);
                      setWithRelatedList(false);
                    }}
                  />
                  <label htmlFor="public">Public</label>
                </div>

                <div className="d-flex align-items-center gap-3">
                  {recordAccessType === "Private" && (
                    <span
                      className="insertCommonInputField text-primary cursorPointer pt-2"
                      onClick={() => {
                        addedUsers.length === 0 && setAddedUsers([]);
                        setAddMemberModal(true);
                      }}
                    >
                      {addedUsers.length + " Users"}{" "}
                      {addedRole.length + " Roles"}{" "}
                      {addedGroup.length + " Group"}
                    </span>
                  )}
                  {recordAccessType === "Public" && (
                    <p className="mb-0">Permission</p>
                  )}
                  <Select
                    options={permissionOptions}
                    styles={customSelectLocationStyles}
                    value={selectedPermissionOption}
                    onChange={(option) => setSelectedPermissionOption(option)}
                  />
                </div>
                <div className="d-flex gap-2">
                  <input
                    type="checkbox"
                    id="withRelatedList"
                    name="withRelatedList"
                    checked={withRelatedList}
                    onChange={(e) => setWithRelatedList(e.target.checked)}
                  />
                  <label htmlFor="withRelatedList ">With Related list</label>
                  <span
                    data-tooltip-id="tooltip"
                    data-tooltip-content={`Enabling this option will share this ${module} with all the records in the related list (current and upcoming) except closed activities, invited meetings and multiple relation modules`}
                  >
                    <img
                      src={alert}
                      height={15}
                      width={15}
                      alt="icon"
                      className="cursorPointer"
                      style={{ marginTop: "-4px" }}
                    />
                  </span>

                  <Tooltip
                    id="tooltip"
                    place="top"
                    className="custom-tooltip"
                  />
                </div>
                <div className="d-flex justify-content-end gap-2">
                  <button
                    className=" cancelButton px-4"
                    onClick={() => {
                      setShareModal(false);
                      setAddedUsers([]);
                      setAddedRole([]);
                      setAddedGroup([]);
                      setRecordAccessType("Private");
                      setSelectedPermissionOption(permissionOptions[0]);
                      setWithRelatedList(false);
                      setSearchQuery("");
                    }}
                  >
                    Cancel
                  </button>
                  <button
                    // className="createContactButtonSave"
                    className={
                      disableButtonCondtion
                        ? "createContactButtonSave opacity-50"
                        : "createContactButtonSave"
                    }
                    onClick={() => {
                      handleInsertShare();
                    }}
                    disabled={disableButtonCondtion || apiLoader}
                  >
                    {apiLoader ? (
                      <Lottie
                        options={defaultOptions("white")}
                        height="24px"
                        width="36px"
                      />
                    ) : (
                      "Share"
                    )}
                  </button>
                </div>
              </div>
            </Modal.Body>
          </Modal>
        </>
      )}

      {/* Add Modal */}
      {/* <AddShareMemberModal
            show={addMemberModal}
            handleClose={() => setAddMemberModal(false)}
            userList={userList}
            loggedInUserId="BCRM0027"
            recordAccessType={recordAccessType}
            accountId={accountId}
            selectedPermissionOption={selectedPermissionOption}
            withRelatedList={withRelatedList}
            URI={URI}
          /> */}
      <Modal
        show={addMemberModal}
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header
          closeButton
          onClick={() => {
            setAddMemberModal(false);
            setAddedUsers([]);
            setAddedRole([]);
            setAddedGroup([]);
            setSelectMemberOption(userOptions[0]);
            setSelectedPermissionOption(permissionOptions[0]);
          }}
        ></Modal.Header>
        <Modal.Body>
          <div className="d-flex flex-column gap-4 px-4">
            <h4 className="commonTextColor">Add members</h4>

            <div className="d-flex w-100">
              <p className="mb-0 fs-5" style={{ width: "40%" }}>
                Available
              </p>

              <p className="mb-0 fs-5">Selected</p>
            </div>
            <div className="d-flex w-100 gap-3">
              <div
                style={{
                  width: "40%",
                  // height: "50vh",
                  border: "1px solid #E2E5EB",
                  borderTopLeftRadius: "10px",
                  borderBottomLeftRadius: "10px",
                  overflowY: "auto",
                }}
              >
                <div className="d-flex gap-2 w-100 px-1 pt-1">
                  <Select
                    options={userOptions}
                    styles={customSelectLocationStyles}
                    value={selectMemberOption}
                    onChange={(option) => setSelectMemberOption(option)}
                  />
                  <input
                    type="text"
                    className="insertCommonInputField rounded-2  "
                    placeholder="Search"
                    style={{ width: "73%" }}
                    onChange={handleSearchChange}
                    value={searchQuery}
                  />
                </div>

                <div style={{ width: "100%" }} className="memberList_container">
                  {memberListView()}
                </div>
                {/* {filteredUserList.map((item, index) => (
                  <div
                    className="d-flex gap-2 align-items-center px-3 mt-2"
                    key={index}
                  >
                    <div className="" style={{ width: "50px" }}>
                      <img
                        width={36}
                        src={profile}
                        alt="img"
                        className="cursorPointer position-relative"
                      />
                    </div>
                    <div className="d-flex flex-column flex-grow-1">
                      <p className="mb-0">{item.name}</p>
                      <p className="mb-0">
                        {item.emailId.length > 23
                          ? `${item.emailId.substring(0, 23)} ...`
                          : item.emailId}
                      </p>
                    </div>
                    <div style={{ width: "80px" }}>
                      <button className="add_btn" onClick={() => addUser(item)}>
                        Add
                      </button>
                    </div>
                  </div>
                ))} */}
              </div>

              <div
                className="d-flex flex-column gap-1 addedList_container"
                style={{
                  width: "60%",
                  border: "1px solid #E2E5EB",
                  borderTopRightRadius: "10px",
                  borderBottomRightRadius: "10px",
                }}
              >
                <div
                  style={{
                    // width: "60%",
                    // height: "30vh",
                    border: "1px solid #E2E5EB",
                    borderTopRightRadius: "10px",
                    borderBottomRightRadius: "10px",
                    padding: "10px",
                  }}
                >
                  <div
                    style={{
                      padding: "10px",
                      backgroundColor: "#a6a9ad",
                      borderRadius: "10px",
                    }}
                  >
                    <p>User - {addedUsers.length}</p>
                    <div
                      className="d-flex gap-2 flex-wrap"
                      style={{ width: "fit-content", overflow: "auto" }}
                    >
                      {addedUsers.map((item, index) => (
                        <div
                          className="d-flex gap-2"
                          key={index}
                          style={{
                            backgroundColor: "#fff",
                            padding: "5px",
                            width: "fit-content",
                            borderRadius: "10px",
                          }}
                        >
                          <p className="mb-0">{item.name}</p>
                          <p
                            className="mb-0 cursorPointer"
                            onClick={() => removeUser(item)}
                          >
                            ×
                          </p>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>

                <div
                  style={{
                    // width: "60%",
                    // height: "30vh",
                    border: "1px solid #E2E5EB",
                    borderTopRightRadius: "10px",
                    borderBottomRightRadius: "10px",
                    padding: "10px",
                  }}
                >
                  <div
                    style={{
                      padding: "10px",
                      backgroundColor: "#a6a9ad",
                      borderRadius: "10px",
                    }}
                  >
                    <p>Role - {addedRole.length}</p>
                    <div
                      className="d-flex gap-2 flex-wrap"
                      style={{ width: "fit-content", overflow: "auto" }}
                    >
                      {addedRole.map((item, index) => (
                        <div
                          className="d-flex gap-2"
                          key={index}
                          style={{
                            backgroundColor: "#fff",
                            padding: "5px",
                            width: "fit-content",
                            borderRadius: "10px",
                          }}
                        >
                          <p className="mb-0">{item.roleName}</p>
                          <p
                            className="mb-0 cursorPointer"
                            onClick={() => removeRole(item)}
                          >
                            ×
                          </p>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>

                <div
                  style={{
                    // width: "60%",
                    // height: "30vh",
                    border: "1px solid #E2E5EB",
                    borderTopRightRadius: "10px",
                    borderBottomRightRadius: "10px",
                    padding: "10px",
                  }}
                >
                  <div
                    style={{
                      padding: "10px",
                      backgroundColor: "#a6a9ad",
                      borderRadius: "10px",
                    }}
                  >
                    <p>Group - {addedGroup.length}</p>
                    <div
                      className="d-flex gap-2 flex-wrap"
                      style={{ width: "fit-content", overflow: "auto" }}
                    >
                      {addedGroup.map((item, index) => (
                        <div
                          className="d-flex gap-2"
                          key={index}
                          style={{
                            backgroundColor: "#fff",
                            padding: "5px",
                            width: "fit-content",
                            borderRadius: "10px",
                          }}
                        >
                          <p className="mb-0">{item.groupName}</p>
                          <p
                            className="mb-0 cursorPointer"
                            onClick={() => removeGroup(item)}
                          >
                            ×
                          </p>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="d-flex justify-content-end gap-2">
              <button
                className="cancelButton px-4"
                onClick={() => {
                  setAddMemberModal(false);
                  setAddedUsers([]);
                  setAddedRole([]);
                  setAddedGroup([]);
                  setSelectMemberOption(userOptions[0]);
                  setSelectedPermissionOption(permissionOptions[0]);
                  setSearchQuery("");
                }}
              >
                Cancel
              </button>
              <button
                className={`${
                  (recordAccessType === "Private" &&
                    addedUsers.length === 0 &&
                    addedRole.length === 0 &&
                    addedGroup.length === 0) ||
                  apiLoader
                    ? "createContactButtonSave opacity-50"
                    : "createContactButtonSave"
                }`}
                disabled={
                  (recordAccessType === "Private" &&
                    addedUsers.length === 0 &&
                    addedRole.length === 0 &&
                    addedGroup.length === 0) ||
                  apiLoader
                }
                onClick={() => {
                  setAddMemberModal(false);
                  setSelectMemberOption(userOptions[0]);
                }}
              >
                Save
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={mangeShareModal}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header
          // closeButton
          className="d-flex justify-content-between mb-0 pb-2"
        >
          <h4 className="commonTextColor">{modalHeading}</h4>
          <div
            onClick={() => {
              setShareModal(false);
              setMangeShareModal(false);

              setAddedUsers([]);
              setAddedRole([]);
              setAddedGroup([]);
              setSelectMemberOption(userOptions[0]);
              setSelectedPermissionOption(permissionOptions[0]);
              setSearchQuery("");
            }}
            className="cursorPointer"
          >
            <img
              src={crossIcon}
              alt="cross"
              style={{ cursor: "pointer" }}
              className="mb-2"
              width={22}
            />
          </div>
        </Modal.Header>
        <Modal.Body>
          <div className="d-flex flex-column gap-4 px-4">
            <div className="d-flex gap-2">
              <p className="mb-0 me-2">Record access type</p>
              <input
                type="radio"
                id="private"
                name="RecordAccessType"
                value="Private"
                checked={recordAccessType === "Private"}
                onChange={(e) => setRecordAccessType(e.target.value)}
              />
              <label htmlFor="private">Private</label>
              <input
                type="radio"
                id="public"
                name="RecordAccessType"
                value="Public"
                checked={recordAccessType === "Public"}
                onChange={(e) => setRecordAccessType(e.target.value)}
              />
              <label htmlFor="public">Public</label>
            </div>

            <div className="d-flex align-items-center gap-3">
              {recordAccessType === "Private" && (
                <span
                  className="insertCommonInputField text-primary cursorPointer pt-2"
                  onClick={() => {
                    addedUsers.length === 0 && setAddedUsers([]);
                    setAddMemberModal(true);
                    setSelectMemberOption(userOptions[0]);
                    // setMangeShareModal(false);
                  }}
                >
                  {addedUsers.length + " Users"} {addedRole.length + " Roles"}{" "}
                  {addedGroup.length + " Group"}
                </span>
              )}
              {recordAccessType === "Public" && (
                <p className="mb-0">Permission</p>
              )}
              <Select
                options={permissionOptions}
                styles={customSelectLocationStyles}
                value={selectedPermissionOption}
                onChange={(option) => setSelectedPermissionOption(option)}
              />
            </div>
            <div className="d-flex gap-2">
              <input
                type="checkbox"
                id="withRelatedList"
                name="withRelatedList"
                checked={withRelatedList}
                onChange={(e) => setWithRelatedList(e.target.checked)}
              />
              <label htmlFor="withRelatedList">With Related list</label>
            </div>
{recordAccessType==="Private" &&(
  <div>
     <div className="Owner_table_container rounded-4 h-25 ">
              <table className="userdata-table w-100">
                <thead>
                  <tr>
                    <th className="sharemodal_header_one">Users</th>
                    <th className="sharemodal_header_two">Record Type</th>
                    <th className="sharemodal_header_two">Permission</th>
                  </tr>
                </thead>
                <tbody className="table-body">
                  {shareUserList?.map((user, index) => (
                    <tr
                      key={index}
                      // onClick={() => {
                      //   setSelectedLead(user);
                      // }}
                      className="w-100"
                    >
                      <td>{user.users}</td>
                      <td>{user.recordType}</td>
                      <td>{user.permission}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>

            <div className="Owner_table_container rounded-4 h-25 ">
              <table className="userdata-table w-100">
                <thead>
                  <tr>
                    <th className="sharemodal_header_one">Roles</th>
                    <th className="sharemodal_header_two">Record Type</th>
                    <th className="sharemodal_header_two">Permission</th>
                  </tr>
                </thead>
                <tbody className="table-body">
                  {shareRoleList?.map((user, index) => (
                    <tr
                      key={index}
                      // onClick={() => {
                      //   setSelectedLead(user);
                      // }}
                      className="w-100"
                    >
                      <td> {user.roles}</td>
                      <td>{user.recordType}</td>
                      <td>{user.permission}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>

            <div className="Owner_table_container rounded-4 h-25 ">
              <table className="userdata-table w-100">
                <thead>
                  <tr>
                    <th className="sharemodal_header_one">Groups</th>
                    <th className="sharemodal_header_two">Record Type</th>
                    <th className="sharemodal_header_two">Permission</th>
                  </tr>
                </thead>
                <tbody className="table-body">
                  {shareGroupList?.map((user, index) => (
                    <tr
                      key={index}
                      // onClick={() => {
                      //   setSelectedLead(user);
                      // }}
                      className="w-100"
                    >
                      <td> {user.groups}</td>
                      <td>{user.recordType}</td>
                      <td>{user.permission}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
    </div>
)}

{recordAccessType==="Public"&&(
  <div>
     <div className="Owner_table_container rounded-4 h-25 ">
              <table className="userdata-table w-100">
                <thead>
                  <tr>
                    <th className="sharemodal_header_one">Access Type</th>
                    <th className="sharemodal_header_two">Record Type</th>
                    <th className="sharemodal_header_two">Permission</th>
                  </tr>
                </thead>
                <tbody className="table-body">
  {sharePublicList?.length > 0 ? (
    sharePublicList.map((user, index) => (
      <tr
        key={index}
        // onClick={() => {
        //   setSelectedLead(user);
        // }}
        className="w-100"
      >
        <td>{user.accessType}</td>
        <td>{user.recordType}</td>
        <td>{user.permission}</td>
      </tr>
    ))
  ) : (
    <tr>
      <td colSpan="3" className="text-center">No public shared Users found</td>
    </tr>
  )}
</tbody>

              </table>
            </div>
    </div>
)}
           

            <div className="d-flex justify-content-end gap-2">
              <button
                className=" cancelButton px-4"
                onClick={() => {
                  setMangeShareModal(false);
                  setAddedUsers([]);
                  setAddedRole([]);
                  setAddedGroup([]);
                  setSelectMemberOption(userOptions[0]);
                  setSelectedPermissionOption(permissionOptions[0]);
                  setSearchQuery("");
                }}
              >
                Cancel
              </button>
              <button
                // className="createContactButtonSave"
                className={`${
                  recordAccessType === "Private" &&
                  addedUsers.length === 0 &&
                  addedRole.length === 0 &&
                  addedGroup.length === 0
                    ? "createContactButtonSave opacity-50"
                    : "createContactButtonSave"
                }`}
                onClick={() => {
                  handleInsertShare();
                }}
                disabled={
                  (recordAccessType === "Private" &&
                    addedUsers.length === 0 &&
                    addedRole.length === 0 &&
                    addedGroup.length === 0) ||
                  apiLoader
                }
              >
                {apiLoader ? (
                  <Lottie
                    options={defaultOptions("white")}
                    height="24px"
                    width="36px"
                  />
                ) : (
                  "Share"
                )}
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={deleteModal}
        aria-labelledby="contained-modal-title-vcenter"
        onClick={(e) => e.stopPropagation()}
        className="deleteModal"
        // size={showModalType !== "delete" ? "md" : "lg"}
      >
        <Modal.Header
          className=" border-0 mb-0 d-flex flex-column align-items-start"
          style={{ backgroundColor: "#F2F2F2" }}
        >
          <div className="mass_heading fs-4">{/* {modalType} */}</div>

          <div className="fs-5" style={{ color: "#3F7DFF" }}>
            Are you sure want to revoke sharing?
          </div>
        </Modal.Header>
        <Modal.Body className="ModalBody">
          <>
            <div className="d-flex gap-2 pb-2 align-items-center">
              <span className="fw-bold">Note:</span>
              <span className="small pt-1">
                Sharing through this record only revoked.
              </span>
            </div>
          </>

          <div className="pt-4 ">
            <div className="d-flex justify-content-end w-100 gap-4 pe-2">
              <button
                className="cancelButton px-4"
                onClick={() => {
                  setDeleteModal(false);
                  setSearchQuery("");
                }}
              >
                Cancel
              </button>
              <button
                className="createContactButtonSave"
                onClick={() => {
                  revokeShareList();
                }}
                disabled={apiLoader}
              >
                {apiLoader ? (
                  <Lottie
                    options={defaultOptions("white")}
                    height="24px"
                    width="36px"
                  />
                ) : (
                  "Revoke"
                )}
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <NotificationContainer />
    </div>
  );
};

export default ShareModal;

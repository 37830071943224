import React, { useState } from "react";
import attachIcon from "../Assets/file-attachment.svg";
import filterMenu from "../Assets/filterMenu.svg";
import SearchIcon from "../Assets/SearchIcon.svg";
import { Modal } from "react-bootstrap";

const QuotesCommonTable = ({
  title,
  buttonLabel,
  buttonAction,
  showHeadings,
  setShowHeadings,
  selectedHeadings,
  data,
  onRowClick,
  manageColumnsAction,
  resetColumnSizeAction,
  setShowSelectedFilter,
  // setSelectedQuoteId,
  handleAssignQuoteModule,
  selectedQuoteId,
  // setSearchColumn,
  // setSavingHeadings,
}) => {
  const [isAssignModal, setIsAssignModal] = useState(false);
  // const [allQuoteDetails, setAllQuoteDetails] = useState([]);

  // const handleCheckboxChange = (quoteId) => {
  //   setSelectedQuoteId((prevSelected) => {
  //     if (prevSelected.includes(quoteId)) {
  //       return prevSelected.filter((id) => id !== quoteId);
  //     } else {
  //       return [...prevSelected, quoteId];
  //     }
  //   });
  // };

  return (
    <>
      <div
        className="lead_Information_mainContainer commonContainer_bg rounded-2 d-flex flex-column py-2 mt-4"
        // style={{ overflowX: "auto", overflowY: "hidden" }}
      >
        <div className="d-flex justify-content-between align-items-center ms-2">
          <div className="d-flex gap-2">
            <img src={attachIcon} alt="product" />
            <div className="notesHeader">{title}</div>
          </div>
          <div className="d-flex gap-2 pe-1">
            {/* <button
              className="addProductsButton p-1"
              onClick={() => setIsAssignModal(true)}
            >
              Assign
            </button> */}
            <button className="addProductsButton" onClick={buttonAction}>
              {buttonLabel}
            </button>
          </div>
        </div>
        <div
          style={{ borderBottom: "2px solid #ffffff" }}
          className="mt-2"
        ></div>
        <div className="p-2 mt-2" onClick={() => setShowHeadings(false)}>
          <div style={{ overflowX: "auto" }}>
            <table
              id="productTable"
              style={{ borderCollapse: "collapse", width: "100%" }}
              className="p-2"
            >
              <thead>
                <tr className="p-2 productTableHeader">
                  {selectedHeadings.map(
                    (heading, index) =>
                      heading.isChecked && (
                        <th key={index} scope="col">
                          <span className="pe-5">{heading.name}</span>
                        </th>
                      )
                  )}
                  <th className="cursorPointer position-relative">
                    <img
                      src={filterMenu}
                      alt=""
                      className={`filter_menu ${
                        showHeadings ? "bg-light" : ""
                      }`}
                      onClick={(e) => {
                        e.stopPropagation();
                        setShowSelectedFilter("");
                        setShowHeadings(true);
                      }}
                    />
                    {showHeadings && (
                      <div
                        className="position-absolute rounded-2 border border-primary bg-light"
                        style={{
                          right: "0.2rem",
                        }}
                      >
                        <div className="d-flex flex-column px-2">
                          <span
                            className="p-2 cursorPointer fw-normal text-start"
                            onClick={() => {
                              manageColumnsAction();
                              // setSearchColumn("");
                              // setSavingHeadings(selectedHeadings);
                            }}
                          >
                            Manage Columns
                          </span>
                          <span
                            className="p-2 fw-normal text-start opacity-50"
                            onClick={resetColumnSizeAction}
                          >
                            Reset Column Size
                          </span>
                        </div>
                      </div>
                    )}
                  </th>
                </tr>
              </thead>
              <tbody>
                {data && data.length > 0 ? (
                  data.map((item, index) => (
                    <tr
                      style={{
                        cursor: "pointer",
                        borderBottom: "1px solid #ccc",
                      }}
                      key={index}
                      onClick={() => onRowClick(index)}
                    >
                      {selectedHeadings.map((obj, i) => {
                        const value = obj.value;
                        const itemValue = item[value];
                        return obj.isChecked ? (
                          <td
                            key={i}
                            className="productsTabledata"
                            style={{ paddingLeft: "0px" }}
                          >
                            {itemValue || "-"}
                          </td>
                        ) : null;
                      })}
                      <td></td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td
                      colSpan={
                        selectedHeadings.filter((obj) => obj.isChecked).length +
                        1
                      } // +1 for the extra <td></td>
                      style={{ textAlign: "center", padding: "20px" }}
                    >
                      <div className="text-center mt-2">
                        <div
                          className="ms-4"
                          style={{ color: "#B9B9B9", fontSize: "20px" }}
                        >
                          No records found
                        </div>
                        <div
                          className="ms-4"
                          style={{ color: "#B9B9B9", fontSize: "14px" }}
                        >
                          There is no data available to show
                        </div>
                      </div>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
          {isAssignModal && (
            <Modal
              className="d-flex justify-content-center align-items-center"
              aria-labelledby="example-custom-modal-styling-title"
              size="lg"
              id="commonLeadModal"
              show={isAssignModal}
              onHide={() => setIsAssignModal(false)}
            >
              <Modal.Body className="ModalBody  overflow-auto">
                <div
                  className="modalUS row justify-content-center"
                  style={{
                    margin: "0",
                    gap: "10px",
                    paddingBottom: "10px",
                  }}
                >
                  <div className="header_Container d-flex justify-content-between">
                    <h4 className="commonTextColor pt-2">Assign </h4>
                    <div className="inputContainer d-flex justify-content-start">
                      <input
                        type="text"
                        placeholder="Search"
                        className="topBarModalSearch px-5"
                      />
                      <img
                        src={SearchIcon}
                        alt="search"
                        className="searchIcon"
                      />
                    </div>
                  </div>
                  <div className="Owner_table_container rounded-4 h-25">
                    <div className="table-wrapper">
                      <table className="userdata-table">
                        <thead>
                          <tr>
                            <th>Subject</th>
                            <th>Quote Stage</th>
                            <th>Valid Until</th>
                            <th>Carrier</th>
                          </tr>
                        </thead>
                        <tbody>
                          {/* {allQuoteDetails.length > 0 ? (
                            allQuoteDetails.map((row, index) => (
                              <tr
                                key={index}
                                className="quote-row"
                                onClick={() => handleCheckboxChange(row.id)}
                                style={{
                                  backgroundColor: selectedQuoteId.includes(
                                    row.id
                                  )
                                    ? "#f1f1f1"
                                    : "white",
                                }}
                              >
                                <td>{row.subject || "-"}</td>
                                <td>{row.quoteStage || "-"}</td>
                                <td>{row.validUntil || "-"}</td>
                                <td>{row.carrier || "-"}</td>
                              </tr>
                            ))
                          ) : (
                            <tr>
                              <td colSpan="4" className="text-center">
                                No quotes found
                              </td>
                            </tr>
                          )} */}
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div
                    className="d-flex justify-content-end mt-2"
                    style={{ gap: "10px" }}
                  >
                    <button
                      className="assignButton p-2 px-4"
                      onClick={() => {
                        handleAssignQuoteModule(selectedQuoteId);
                        setIsAssignModal(false);
                      }}
                    >
                      Assign
                    </button>
                    <button
                      className="assignButton p-2 px-4"
                      onClick={() => setIsAssignModal(false)}
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </Modal.Body>
            </Modal>
          )}
        </div>
      </div>
    </>
  );
};

export default QuotesCommonTable;

import React, { useState, useEffect } from "react";
import "./ActivateAccount.css";
import CrmLogo from "../../Assets/CrmLogo.svg";
import { URI } from "../../constants";
import axios from "axios";
import { FadeLoader } from "react-spinners";
import Lottie from "react-lottie";
import signupAnimation from "../../Assets/lotties/signupAnimation.json";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import successAnimation from "../../Assets/lotties/successAnimation.json";
import { useNavigate } from "react-router-dom";

const ActivateAccount = () => {
  const [verifyPage, setVerifyPage] = useState("verify");
  const [sessionValidity, setSessionValidity] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [userDetails, setUserDetails] = useState();
  const urlFetched = window.location.href;
  const after = urlFetched.slice(urlFetched.indexOf("?") + 1);
  var getToken = after.slice(after.indexOf("=") + 1);
  const navigate = useNavigate();

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: signupAnimation,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const defaultOptions2 = {
    loop: true,
    autoplay: true,
    animationData: successAnimation,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const getVerifySession = async () => {
    setIsLoading(true);
    await axios
      .post(URI.verifyEmailLink, {
        linkDetails: getToken,
      })
      .then((res) => {
        if (res.data.status !== 200) {
          setSessionValidity(true);
          setIsLoading(false);
        } else {
          setUserDetails(res.data.data);
          setIsLoading(false);
        }
      })
      .catch(() => {});
  };

  const activateDeactivateUser = async () => {
    const requestBody = {
      loggedInUserId: userDetails.userId,
      userId: userDetails.userId,
      status: "ACTIVE",
      isSuperAdmin: 1,
    };
    await axios
      .post(URI.activeInactiveUser, requestBody)
      .then((response) => {
        if (response.data.status === 200) {
          setVerifyPage("success");
        } else {
          NotificationManager.error("", response.data.message, 5000);
        }
      })
      .catch((err) => {
        throw new Error("Failed to fetch data", err);
      });
  };

  const verifyActivation = () => {
    return (
      <div
        className="d-flex justify-content-center align-items-center"
        style={{ width: "100%", height: "100vh" }}
      >
        <div className="py-2">
          <div
            className="mt-4 accountSuccess d-flex flex-column justify-content-center p-2"
            style={{ height: "48vh", width: "40vw" }}
          >
            <div className="d-flex justify-content-center">
              <img src={CrmLogo} alt="logo" />
            </div>
            <div
              style={{ color: "#407BFF", fontSize: "24px" }}
              className="text-center mt-2"
            >
              Account Confirmation
            </div>
            <div
              style={{ fontSize: "15px", color: "#000000", fontWeight: "300" }}
              className="ms-2 mt-1"
            >
              <div className="text-center">
                A verification email has been sent to
                <span
                  style={{
                    fontSize: "15px",
                    color: "#000000",
                    fontWeight: "bold",
                  }}
                >
                  {" "}
                  {userDetails?.email}
                </span>{" "}
              </div>
              <div className="text-center">
                {" "}
                Please verify your email address to access your CRM account.
              </div>
            </div>
            <div className="mt-2 text-center">
              <div
                style={{
                  fontSize: "15px",
                  color: "#000000",
                  fontWeight: "300",
                }}
              >
                By Clicking verify, you agree to the{" "}
                <span style={{ color: "#407BFF", fontSize: "14px" }}>
                  Terms of Use
                </span>{" "}
                and{" "}
                <span style={{ color: "#407BFF", fontSize: "14px" }}>
                  Privacy Policy.
                </span>
              </div>
            </div>
            <div className="mt-4 ms-2 d-flex justify-content-center">
              <button
                className="verifyButton p-2"
                onClick={() => {
                  activateDeactivateUser();
                }}
              >
                Verify
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const verifyActivationSuccess = () => {
    return (
      <div
        className="d-flex justify-content-center align-items-center"
        style={{ width: "100%", height: "100vh" }}
      >
        <div className="py-2">
          <div
            className="mt-4 accountSuccess d-flex flex-column justify-content-center p-2"
            style={{ height: "48vh", width: "40vw" }}
          >
            <div className="mt-4">
              <Lottie options={defaultOptions2} height="15vh" width="13.5vw" />
            </div>
            <div
              style={{ color: "#407BFF", fontSize: "24px" }}
              className="text-center"
            >
              Account Verified!
            </div>
            <div
              style={{ fontSize: "15px", color: "#000000", fontWeight: "300" }}
              className="ms-2 mt-1"
            >
              <div className="text-center">
                You’ve successfully verified your Chain CRM account{" "}
              </div>
              <div
                className="text-center"
                style={{
                  color: "#000000",
                  fontSize: "14px",
                  fontWeight: "bold",
                }}
              >
                {userDetails?.email}
              </div>
            </div>

            <div className="mt-2 ms-2 d-flex justify-content-center">
              <button
                className="verifyButton p-2"
                onClick={() => navigate("/")}
              >
                Access Your Account
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const handleActivationView = () => {
    if (verifyPage === "verify") {
      return verifyActivation();
    } else if (verifyPage === "success") {
      return verifyActivationSuccess();
    }
  };

  useEffect(() => {
    getVerifySession();
  }, []);

  return (
    <>
      {
        <div className="mainContainer">
          {isLoading ? (
            <div className="loginMainCon successPage">
              <FadeLoader
                color="rgb(54, 215, 183)"
                loading={isLoading}
                size={150}
              />
            </div>
          ) : sessionValidity ? (
            <div className="loginContainer">
              <div className="loginSectionSignup">
                <div className="loginInput">
                  <img
                    src={CrmLogo}
                    height={50}
                    className="d-flex mt-4"
                    alt=""
                  />
                  <p
                    className="mt-4"
                    style={{
                      background:
                        "linear-gradient(to right, rgba(64, 123, 255, 1), rgba(18, 167, 254, 1))",
                      WebkitBackgroundClip: "text",
                      WebkitTextFillColor: "transparent",
                      fontSize: "x-large",
                      textAlignLast: "center",
                    }}
                  >
                    Your token has been expired
                  </p>
                  <span
                    className="mt-2"
                    style={{
                      borderBottom: "1px solid rgba(217, 217, 217, 1)",
                      width: "75%",
                      alignSelf: "center",
                    }}
                  />
                  <div className="mt-5">
                    <Lottie
                      options={defaultOptions}
                      height="15vh"
                      width="11.5vw"
                    />
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="accountActivation">{handleActivationView()}</div>
          )}
        </div>
      }
      <NotificationContainer />
    </>
  );
};

export default ActivateAccount;

export const exportDataModules = {
  accounts: [
    {
      label: "All Accounts",
      value: "all_accounts",
    },
    {
      label: "My Accounts",
      value: "my_accounts",
    },
    {
      label: "New Last week",
      value: "new_last_week",
    },
    {
      label: "New This week",
      value: "new_this_week",
    },
    {
      label: "Recently Created Accounts",
      value: "recently_created_accounts",
    },
    {
      label: "Recently Modified Accounts",
      value: "recently_modified_accounts",
    },
    {
      label: "Accounts Shared by Me",
      value: "accounts_shared_by_me",
    },
    {
      label: "Accounts Shared to Me",
      value: "accounts_shared_to_me",
    },
  ],
  calls: [
    {
      label: "All Calls",
      value: "all_calls",
    },
    {
      label: "Attended Calls",
      value: "attended_calls",
    },
    {
      label: "Cancelled Calls",
      value: "cancelled_calls",
    },

    {
      label: "Completed Calls",
      value: "completed_calls",
    },
    {
      label: "Incoming Calls",
      value: "incoming_calls",
    },
    {
      label: "Missed Calls",
      value: "missed_calls",
    },
    {
      label: "My Attended Calls",
      value: "my_attended_calls",
    },
    {
      label: "My Calls",
      value: "my_calls",
    },
    {
      label: "My Missed Calls",
      value: "my_missed_calls",
    },
    {
      label: "My Incoming Calls",
      value: "my_incoming_calls",
    },
    {
      label: "My Outgoing Calls",
      value: "my_outgoing_calls",
    },
    {
      label: "My Overdue Calls",
      value: "my_overdue_calls",
    },
    {
      label: "My Scheduled Calls",
      value: "my_scheduled_calls",
    },
    {
      label: "Today Scheduled Calls",
      value: "today's_scheduled_calls",
    },
    {
      label: "Unattended Calls",
      value: "unattend_calls",
    },
    {
      label: "Outgoing Calls",
      value: "outgoing_calls",
    },
    {
      label: "Overdue Calls",
      value: "overdue_calls",
    },
    {
      label: "Scheduled Calls",
      value: "scheduled_calls",
    },
  ],
  contacts: [
    {
      label: "All Contacts",
      value: "all_contacts",
    },
    {
      label: "Contacts Shared by Me",
      value: "contacts_shared_by_me",
    },
    {
      label: "Contacts Shared to Me",
      value: "contacts_shared_to_me",
    },
    {
      label: "Mailing Labels",
      value: "mailing_labels",
    },
    {
      label: "My Contacts",
      value: "my_contacts",
    },
    {
      label: "New Last week",
      value: "new_last_week",
    },
    {
      label: "New This Week",
      value: "new_this_week",
    },
    {
      label: "Recently Created Contacts",
      value: "recently_created_contacts",
    },
    {
      label: "Recently Modified Contacts",
      value: "recently_modified_contacts",
    },
  ],
  leads: [
    {
      label: "All Leads",
      value: "all_leads",
    },

    {
      label: "Converted Leads",
      value: "converted_leads",
    },
    {
      label: "Junk Leads",
      value: "junk_leads",
    },
    {
      label: "Leads Shared by Me",
      value: "leads_shared_by_me",
    },
    {
      label: "Leads Shared to Me",
      value: "leads_shared_to_me",
    },
    {
      label: "My Converted Leads",
      value: "my_converted_leads",
    },
    {
      label: "My Leads",
      value: "my_leads",
    },
    {
      label: "Recently Created Leads",
      value: "recently_created_leads",
    },
    {
      label: "Recently Modified Leads",
      value: "recently_modified_leads",
    },
  ],
  deals: [
    {
      label: "All Deals",
      value: "all_deals",
    },
    {
      label: "Closing Next Month",
      value: "closing_next_month",
    },
    {
      label: "Closing This Month",
      value: "closing_this_month",
    },
    {
      label: "Deals Shared by Me",
      value: "deals_shared_by_me",
    },
    {
      label: "Deals Shared to Me",
      value: "deals_shared_to_me",
    },
    {
      label: "My Deals",
      value: "my_deals",
    },
    {
      label: "My Deals Closing This Month",
      value: "my_deals_closing_this_month",
    },
    {
      label: "New Last Week",
      value: "new_last_week",
    },
    {
      label: "New This Week",
      value: "new_this_week",
    },
    {
      label: "Recently Created Deals",
      value: "recently_created_deals",
    },
    {
      label: "Recently Modified Deals",
      value: "recently_modified_deals",
    },
  ],
  invoices: [
    {
      label: "All Invoices",
      value: "all_invoices",
    },
    {
      label: "My Invoices",
      value: "my_invoices",
    },
    {
      label: "Invoices Shared by Me",
      value: "invoices_shared_by_me",
    },
    {
      label: "Invoices Shared to Me",
      value: "invoices_shared_to_me",
    },
  ],
  products: [
    {
      label: "All products",
      value: "all_products",
    },
    {
      label: "Active products",
      value: "active_products",
    },
    {
      label: "My products",
      value: "my_products",
    },
    {
      label: "Products shared by me",
      value: "products_shared_by_me",
    },
    {
      label: "Products shared to me",
      value: "products_shared_to_me",
    },
  ],
  vendors: [
    {
      label: "All Vendors",
      value: "all_vendors",
    },
    {
      label: "My Vendors",
      value: "my_vendors",
    },
    {
      label: "Vendors Shared by Me",
      value: "vendors_shared_by_me",
    },
    {
      label: "Vendors Shared to Me",
      value: "vendors_shared_to_me",
    },
  ],
  meetings: [
    {
      label: "All Meetings",
      value: "all_meetings",
    },
    {
      label: "Completed Meetings",
      value: "completed_meetings",
    },
    {
      label: "My Completed Meetings",
      value: "my_completed_meetings",
    },
    {
      label: "My Meetings",
      value: "my_meetings",
    },
    {
      label: "My Today Meetings",
      value: "my_today_meetings",
    },
    {
      label: "My Upcoming Meetings",
      value: "my_upcoming_meetings",
    },
    {
      label: "This Weeks Meetings",
      value: "this_weeks_meetings",
    },
    {
      label: "Today  Meetings",
      value: "today_meetings",
    },
    {
      label: "Upcoming Meetings",
      value: "upcoming_meetings",
    },
  ],
  sales_orders: [
    { label: "All sales orders", value: "all_sales_orders" },
    { label: "My sales orders", value: "my_sales_orders" },
    { label: "Sales orders shared by me", value: "sales_orders_shared_by_me" },
    { label: "Sales orders shared to me", value: "sales_orders_shared_to_me" },
  ],
  purchase_orders: [
    { label: "All purchase orders", value: "all_purchase_orders" },
    { label: "My purchase orders", value: "my_purchase_orders" },
    { label: "Overdue purchase orders", value: "overdue_purchase_orders" },
    {
      label: "Purchase orders shared by me",
      value: "purchase_orders_shared_by_me",
    },
    {
      label: "Purchase orders shared to me",
      value: "purchase_orders_shared_to_me",
    },
  ],
  quotes: [
    { label: "All quotes", value: "all_quotes" },
    { label: "My quotes", value: "my_quotes" },
    { label: "Quotes shared by me", value: "quotes_shared_by_me" },
    { label: "Quotes shared to me", value: "quotes_shared_to_me" },
  ],
  tasks: [
    { label: "All Tasks", value: "all_tasks" },
    { label: "Closed Tasks", value: "closed_tasks" },
    { label: "My Closed Tasks", value: "my_closed_tasks" },
    { label: "My Next 7 Days", value: "my_next_7_days" },
    { label: "My Open Tasks", value: "my_open_tasks" },
    { label: "My Overdue Tasks", value: "my_overdue_tasks" },
    { label: "My Tasks", value: "my_tasks" },
    { label: "My Today's Tasks", value: "my_today_tasks" },
    { label: "My Tomorrow's Tasks", value: "my_tomorrow_tasks" },
    { label: "Next 7 Days", value: "next_7_days" },
    { label: "Open Tasks", value: "open_tasks" },
    { label: "Overdue Tasks", value: "overdue_tasks" },
    { label: "Today's Tasks", value: "today_tasks" },
    { label: "Tomorrow's Tasks", value: "tomorrow_tasks" },
  ],
  campaigns: [
    // { label: "All Campaigns", value: "all_campaigns" },
    { label: "All Active Campaigns", value: "all_active_campaigns" },
    { label: "Campaigns Shared by me", value: "campaigns_shared_by_me" },
    { label: "Campaigns Shared to me", value: "campaigns_shared_to_me" },
    { label: "My Active Campaigns", value: "my_active_campaigns" },
  ],
};

export const exportDataChooseFields = {
  contacts: [
    {
      label: "Other Zip",
      value: "otherZip",
    },
    {
      label: "Last Name",
      value: "lastName",
    },
    {
      label: "Secondary Email",
      value: "secondaryEmail",
    },
    {
      label: "Account Name",
      value: "accountName",
    },
    {
      label: "Contact Description",
      value: "contDescription",
    },
    {
      label: "Email Id",
      value: "emailId",
    },
    {
      label: "Job Title",
      value: "title",
    },
    {
      label: "Mailing Zip",
      value: "mailingZip",
    },
    {
      label: "Twitter",
      value: "twitter",
    },
    {
      label: "Mailing Country",
      value: "mailingCountry",
    },
    {
      label: "Modified By",
      value: "modifiedBy",
    },
    {
      label: "Mailing State",
      value: "mailingState",
    },
    {
      label: "Other State",
      value: "otherState",
    },
    {
      label: "Department",
      value: "department",
    },
    {
      label: "Fax",
      value: "fax",
    },
    {
      label: "Mailing City",
      value: "mailingCity",
    },
    {
      label: "Contact Name",
      value: "contactName",
    },
    {
      label: "Lead Source",
      value: "leadSource",
    },
    {
      label: "Other Street",
      value: "otherStreet",
    },
    {
      label: "Trans Hash Value",
      value: "transHash",
    },
    {
      label: "Assistant",
      value: "assistant",
    },
    {
      label: "Home Phone",
      value: "homePhone",
    },
    {
      label: "Related To",
      value: "relatedTo",
    },
    {
      label: "Other City",
      value: "otherCity",
    },
    {
      label: "Mobile",
      value: "mobile",
    },
    {
      label: "Date Of Birth",
      value: "dateOfBirth",
    },
    {
      label: "Vendor Name",
      value: "vendorName",
    },
    {
      label: "First Name",
      value: "firstName",
    },
    {
      label: "Skype Id",
      value: "skypeId",
    },
    {
      label: "Other Phone",
      value: "otherPhone",
    },
    {
      label: "Phone",
      value: "phone",
    },
    {
      label: "Other Country",
      value: "otherCountry",
    },
    {
      label: "Created By",
      value: "createdBy",
    },
    {
      label: "Contact Owner",
      value: "contactOwner",
    },
    {
      label: "Assistant Phone",
      value: "assistantPhone",
    },
    {
      label: "Mailing Street",
      value: "mailingStreet",
    },
  ],
  leads: [
    {
      label: "Lead Status",
      value: "leadStatus",
    },
    {
      label: "Zip Code",
      value: "zipCode",
    },
    {
      label: "Country",
      value: "country",
    },
    {
      label: "Last Name",
      value: "lastName",
    },
    {
      label: "City",
      value: "city",
    },
    {
      label: "Lead Name",
      value: "leadName",
    },
    {
      label: "Company Name",
      value: "companyName",
    },
    {
      label: "Rating",
      value: "rating",
    },
    {
      label: "Description",
      value: "description",
    },
    {
      label: "Email Id",
      value: "emailId",
    },
    {
      label: "Job Title",
      value: "title",
    },
    {
      label: "Email Opt Out",
      value: "emailOptOut",
    },
    {
      label: "Lead Owner",
      value: "leadOwner",
    },
    {
      label: "Twitter",
      value: "twitter",
    },
    {
      label: "Industry Type",
      value: "industryType",
    },
    {
      label: "Street",
      value: "street",
    },
    {
      label: "Modified By",
      value: "modifiedBy",
    },
    {
      label: "State",
      value: "state",
    },
    {
      label: "Fax",
      value: "fax",
    },
    {
      label: "Website",
      value: "website",
    },
    {
      label: "Lead Source",
      value: "leadSource",
    },
    {
      label: "Trans Hash Value",
      value: "transHash",
    },
    {
      label: "Mobile",
      value: "mobile",
    },
    {
      label: "Secondary Email Id",
      value: "secondaryEmailId",
    },
    {
      label: "Skype Id",
      value: "skypeId",
    },
    {
      label: "First Name",
      value: "firstName",
    },
    {
      label: "Phone Number",
      value: "phoneNumber",
    },
    {
      label: "Created By",
      value: "createdBy",
    },
    {
      label: "Modified Date",
      value: "modifiedDate",
    },
    {
      label: "No Of Employees",
      value: "noOfEmployees",
    },
    {
      label: "Annual Revenue",
      value: "annualRevenue",
    },
  ],
  convertedLeads: [
    {
      label: "Lead Status",
      value: "leadStatus",
    },
    {
      label: "Zip Code",
      value: "zipCode",
    },
    {
      label: "Country",
      value: "country",
    },
    {
      label: "Last Name",
      value: "lastName",
    },
    {
      label: "City",
      value: "city",
    },
    {
      label: "Lead Name",
      value: "leadName",
    },
    {
      label: "Company Name",
      value: "companyName",
    },
    {
      label: "Converted Deal",
      value: "convertedDeal",
    },
    {
      label: "Converted Account",
      value: "convertedAccount",
    },
    {
      label: "Converted Contact",
      value: "convertedContact",
    },
    {
      label: "Rating",
      value: "rating",
    },
    {
      label: "Description",
      value: "description",
    },
    {
      label: "Email Id",
      value: "emailId",
    },
    {
      label: "Job Title",
      value: "title",
    },
    {
      label: "Email Opt Out",
      value: "emailOptOut",
    },
    {
      label: "Lead Owner",
      value: "leadOwner",
    },
    {
      label: "Twitter",
      value: "twitter",
    },
    {
      label: "Industry Type",
      value: "industryType",
    },
    {
      label: "Street",
      value: "street",
    },
    {
      label: "Modified By",
      value: "modifiedBy",
    },
    {
      label: "State",
      value: "state",
    },
    {
      label: "Fax",
      value: "fax",
    },
    {
      label: "Website",
      value: "website",
    },
    {
      label: "Lead Source",
      value: "leadSource",
    },
    {
      label: "Trans Hash Value",
      value: "transHash",
    },
    {
      label: "Mobile",
      value: "mobile",
    },
    {
      label: "Secondary Email Id",
      value: "secondaryEmailId",
    },
    {
      label: "Skype Id",
      value: "skypeId",
    },
    {
      label: "First Name",
      value: "firstName",
    },
    {
      label: "Phone Number",
      value: "phoneNumber",
    },
    {
      label: "Created By",
      value: "createdBy",
    },
    {
      label: "Modified Date",
      value: "modifiedDate",
    },
    {
      label: "No Of Employees",
      value: "noOfEmployees",
    },
    {
      label: "Annual Revenue",
      value: "annualRevenue",
    },
  ],
  accounts: [
    {
      label: "Shipping City",
      value: "shippingCity",
    },
    {
      label: "Shipping Code",
      value: "shippingCode",
    },
    {
      label: "Account Name",
      value: "accountName",
    },
    {
      label: "Shipping Street",
      value: "shippingStreet",
    },
    {
      label: "Rating",
      value: "rating",
    },
    {
      label: "Industry",
      value: "industry",
    },
    {
      label: "Account Site",
      value: "accountSite",
    },
    {
      label: "Shipping Country",
      value: "shippingCountry",
    },
    {
      label: "Billing Country",
      value: "billingCountry",
    },
    {
      label: "Modified By",
      value: "modifiedBy",
    },
    {
      label: "Fax",
      value: "fax",
    },
    {
      label: "Website",
      value: "website",
    },
    {
      label: "Transaction Hash",
      value: "transHash",
    },
    {
      label: "Tricker Symbol",
      value: "tickerSymbol",
    },
    {
      label: "Account Owner",
      value: "accountOwner",
    },
    {
      label: "Billing State",
      value: "billingState",
    },
    {
      label: "Billing Code",
      value: "billingCode",
    },
    {
      label: "Created Date",
      value: "createdDate",
    },
    {
      label: "Ownership",
      value: "ownership",
    },
    {
      label: "Phone",
      value: "phone",
    },
    {
      label: "Billing Street",
      value: "billingStreet",
    },
    {
      label: "Created By",
      value: "createdBy",
    },
    {
      label: "SIC Code",
      value: "sicCode",
    },
    {
      label: "Modified Date",
      value: "modifiedDate",
    },
    {
      label: "Shipping State",
      value: "shippingState",
    },
    {
      label: "Employees",
      value: "employees",
    },
    {
      label: "Account Description",
      value: "accDescription",
    },
    {
      label: "Annual Revenue",
      value: "annualRevenue",
    },
    {
      label: "Billing City",
      value: "billingCity",
    },
  ],
  deals: [
    {
      label: "Expected Revenue",
      value: "expectedRevenue",
    },
    {
      label: "Amount",
      value: "amount",
    },
    {
      label: "Account Name",
      value: "accountName",
    },
    {
      label: "Lead Source",
      value: "leadSource",
    },
    {
      label: "Probability",
      value: "probability",
    },
    {
      label: "Contact Name",
      value: "contactName",
    },
    {
      label: "Transaction Hash",
      value: "transHash",
    },
    {
      label: "Type",
      value: "type",
    },
    {
      label: "Closing Date",
      value: "closingDate",
    },
    {
      label: "Deal Owner",
      value: "dealOwner",
    },
    {
      label: "Deal Name",
      value: "dealName",
    },
    {
      label: "Stage",
      value: "stage",
    },
    {
      label: "Campaign Source",
      value: "campaignSource",
    },
    {
      label: "Next Step",
      value: "nextStep",
    },
  ],
  invoices: [
    {
      label: "Shipping City",
      value: "shippingCity",
    },
    {
      label: "Shipping Code",
      value: "shippingCode",
    },
    {
      label: "Account Name",
      value: "accountName",
    },
    {
      label: "Subject",
      value: "subject",
    },
    {
      label: "Shipping Street",
      value: "shippingStreet",
    },
    {
      label: "Due Date",
      value: "dueDate",
    },
    {
      label: "Shipping Country",
      value: "shippingCountry",
    },
    {
      label: "Invoice Number",
      value: "invoiceNumber",
    },
    {
      label: "Billing Country",
      value: "billingCountry",
    },
    {
      label: "Excise Duty",
      value: "exciseDuty",
    },
    {
      label: "Sales Commission",
      value: "salesCommission",
    },
    {
      label: "Grand Total",
      value: "grandTotal",
    },
    { label: "Contact Name", value: "contactName" },
    { label: "Transaction Hash", value: "transHash" },
    { label: "Billing State", value: "billingState" },
    { label: "Invoice Date", value: "invoiceDate" },
    { label: "Deal Name", value: "dealName" },
    { label: "Billing Code", value: "billingCode" },
    { label: "Invoice Owner", value: "invoiceOwner" },
    { label: "Billing Street", value: "billingStreet" },
    { label: "Purchase Order", value: "purchaseOrder" },
    { label: "Shipping State", value: "shippingState" },
    { label: "Billing City", value: "billingCity" },
    { label: "Status", value: "status" },
  ],
  calls: [
    { label: "Call Duration", value: "callDuration" },
    { label: "Modified Time", value: "modifiedTime" },
    { label: "Subject", value: "subject" },
    { label: "Transaction Hash", value: "transHash" },
    { label: "Call Start Time", value: "callStartTime" },
    { label: "Call To Name", value: "callToName" },
    { label: "Outgoing Call Status", value: "outGoingCallStatus" },
    { label: "Call Type", value: "callType" },
    { label: "Call Agenda", value: "callAgenda" },
    { label: "Relation Module Name", value: "relationModuleName" },
    { label: "Call Owner", value: "callOwner" },
    { label: "Relation Name", value: "relationName" },
    { label: "Created By", value: "createdBy" },
    { label: "Call Purpose", value: "callPurpose" },
    { label: "Call To Module Name", value: "callToModuleName" },
    { label: "Created Time", value: "createdTime" },
    { label: "Modified By", value: "modifiedBy" },
  ],
  products: [
    { label: "Sales End Date", value: "salesEndDate" },
    { label: "Handler", value: "handler" },
    { label: "Commission Rate", value: "commissionRate" },
    { label: "Description", value: "description" },
    { label: "Product Name", value: "productName" },
    { label: "Manufacturer", value: "manufacturer" },
    { label: "Product Category", value: "productCategory" },
    { label: "Reorder Level", value: "reorderLevel" },
    { label: "Support End Date", value: "supportEndDate" },
    { label: "Product Image", value: "productImage" },
    { label: "Product Owner", value: "productOwner" },
    { label: "Product Active", value: "productActive" },
    { label: "Unit Price", value: "unitPrice" },
    { label: "Usage Unit", value: "usageUnit" },
    { label: "Quantity In Stock", value: "quantityInStock" },
    { label: "Quantity In Demand", value: "quantityInDemand" },
    { label: "Taxable", value: "taxable" },
    { label: "Transaction Hash", value: "transHash" },
    { label: "Tax", value: "tax" },
    { label: "Support Start Date", value: "supportStartDate" },
    { label: "Product Code", value: "productCode" },
    { label: "Sales Start Date", value: "salesStartDate" },
    { label: "Quantity Ordered", value: "qtyOrdered" },
  ],
  vendors: [
    { label: "Zip Code", value: "zipCode" },
    { label: "Country", value: "country" },
    { label: "Website", value: "website" },
    { label: "City", value: "city" },
    { label: "Transaction Hash", value: "transHash" },
    { label: "Email ID", value: "emailId" },
    { label: "Vendor Name", value: "vendorName" },
    { label: "Email Option Out", value: "emailOptOut" },
    { label: "Phone", value: "phone" },
    { label: "Created By", value: "createdBy" },
    { label: "Street", value: "street" },
    { label: "Vendor Description", value: "vendorDescription" },
    { label: "Modified By", value: "modifiedBy" },
    { label: "Google Account", value: "glAccount" },
    { label: "State", value: "state" },
    { label: "Category", value: "category" },
  ],
  meetings: [
    { label: "Modified Time", value: "modifiedTime" },
    { label: "Related Name", value: "relatedName" },
    { label: "Description", value: "description" },
    { label: "Job Title", value: "title" },
    { label: "Meeting Owner", value: "meetingOwner" },
    { label: "Related To", value: "relatedTo" },
    { label: "Relation Module Name", value: "relationModuleName" },
    { label: "All Day", value: "allDay" },
    { label: "Participants Remainder", value: "participantsRemainder" },
    { label: "Host", value: "host" },
    { label: "Created Time", value: "createdTime" },
    { label: "Modified By", value: "modifiedBy" },
    { label: "Related Module Name", value: "relatedModuleName" },
    { label: "Repeat Ends", value: "repeatEnds" },
    { label: "Transaction Hash", value: "transHash" },
    { label: "To Date", value: "toDate" },
    { label: "Repeat Type", value: "repeatType" },
    { label: "From Date", value: "fromDate" },
    { label: "Relation Name", value: "relationName" },
    { label: "Created By", value: "createdBy" },
    { label: "Related Relation", value: "relatedRelation" },
    { label: "Location", value: "location" },
    { label: "Remainder", value: "remainder" },
  ],
  sales_orders: [
    { label: "Modified Time", value: "modifiedTime" },
    { label: "Shipping City", value: "shippingCity" },
    { label: "Shipping Code", value: "shippingCode" },
    { label: "Account Name", value: "accountName" },
    { label: "Subject", value: "subject" },
    { label: "Shipping Street", value: "shippingStreet" },
    { label: "Due Date", value: "dueDate" },
    { label: "Pending", value: "pending" },
    { label: "Description", value: "description" },
    { label: "Sales Order Owner", value: "salesOrderOwner" },
    { label: "Shipping Country", value: "shippingCountry" },
    { label: "Created Time", value: "createdTime" },
    { label: "Billing Country", value: "billingCountry" },
    { label: "Modified By", value: "modifiedBy" },
    { label: "Excise Duty", value: "exciseDuty" },
    { label: "Sales Commission", value: "salesCommission" },
    { label: "Grand Total", value: "grandTotal" },
    { label: "Terms and Condition", value: "termsAndCondi" },
    { label: "Contact Name", value: "contactName" },
    { label: "Transaction Hash", value: "transHash" },
    { label: "Billing State", value: "billingState" },
    { label: "Deal Name", value: "dealName" },
    { label: "Billing Code", value: "billingCode" },
    { label: "Carrier", value: "carrier" },
    { label: "Created By", value: "createdBy" },
    { label: "Billing Street", value: "billingStreet" },
    { label: "Purchase Order", value: "purchaseOrder" },
    { label: "Shipping State", value: "shippingState" },
    { label: "Sales Order Number", value: "soNumber" },
    { label: "Customer Number", value: "customerNo" },
    { label: "Billing City", value: "billingCity" },
    { label: "Status", value: "status" },
  ],
  purchase_orders: [
    { label: "Shipping City", value: "shippingCity" },
    { label: "Shipping Code", value: "shippingCode" },
    { label: "Subject", value: "subject" },
    { label: "Due Date", value: "dueDate" },
    { label: "Description", value: "description" },
    { label: "Discount", value: "discount" },
    { label: "Sub Total", value: "subTotal" },
    { label: "Terms and Condition", value: "termsAndConditions" },
    { label: "Purchase Orders Date", value: "poDate" },
    { label: "Shipping Date", value: "shippingDate" },
    { label: "Billing Country", value: "billingCountry" },
    { label: "Excise Duty", value: "exciseDuty" },
    { label: "Tracking Number", value: "trackingNumber" },
    { label: "Sales Commission", value: "salesCommission" },
    { label: "Requisition Number", value: "requisitionNumber" },
    { label: "Grand Total", value: "grandTotal" },
    { label: "Transaction Hash", value: "transHash" },
    { label: "Contact Name", value: "contactName" },
    { label: "Tax", value: "tax" },
    { label: "Billing State", value: "billingState" },
    { label: "Vendor Name", value: "vendorName" },
    { label: "Carrier", value: "carrier" },
    { label: "Created Date", value: "createdDate" },
    { label: "Billing Street", value: "billingStreet" },
    { label: "Purchase Order Owner", value: "purchaseOrderOwner" },
    { label: "Shipping State", value: "shippingState" },
    { label: "Adjustment", value: "adjustment" },
    { label: "Purchase Order Number", value: "poNumber" },
    { label: "Billing City", value: "billingCity" },
    { label: "Status", value: "status" },
  ],
  quotes: [
    { label: "Shipping City", value: "shippingCity" },
    { label: "Shipping Code", value: "shippingCode" },
    { label: "Account Name", value: "accountName" },
    { label: "Subject", value: "subject" },
    { label: "Shipping Street", value: "shippingStreet" },
    { label: "Shipping Country", value: "shippingCountry" },
    { label: "Billing Country", value: "billingCountry" },
    { label: "Grand Total", value: "grandTotal" },
    { label: "Contact Name", value: "contactName" },
    { label: "Transaction Hash", value: "transHash" },
    { label: "Quotes Owner", value: "quotesOwner" },
    { label: "Quotes Stage", value: "quoteStage" },
    { label: "Team", value: "team" },
    { label: "Billing State", value: "billingState" },
    { label: "Deal Name", value: "dealName" },
    { label: "Billing Code", value: "billingCode" },
    { label: "Carrier", value: "carrier" },
    { label: "Billing Street", value: "billingStreet" },
    { label: "Valid Until", value: "validUntil" },
    { label: "Shipping State", value: "shippingState" },
    { label: "Billing City", value: "billingCity" },
    { label: "Created By", value: "createdBy" },
    { label: "Modified By", value: "modifiedBy" },
    { label: "Created Time", value: "createdTime" },
    { label: "Modified Time", value: "modifiedTime" },
    { label: "Sub Total", value: "subTotal" },
    { label: "Discount", value: "discount" },
    { label: "Tax", value: "tax" },
    { label: "Adjustment", value: "adjustment" },
    { label: "Terms and Conditions", value: "termsAndConditions" },
    { label: "Description", value: "description" },
  ],
  tasks: [
    { label: "Related Module Name", value: "relatedModuleName" },
    { label: "Task Owner", value: "taskOwner" },
    { label: "Subject", value: "subject" },
    { label: "Contact Name", value: "contactName" },
    { label: "Transaction Hash", value: "transHash" },
    { label: "Due Date", value: "dueDate" },
    { label: "Description", value: "description" },
    { label: "Relation To", value: "relationTo" },
    { label: "Priority", value: "priority" },
    { label: "Related To", value: "relatedTo" },
    { label: "Relation Module Name", value: "relationModuleName" },
    { label: "Created Date", value: "createdDate" },
    { label: "Created By", value: "createdBy" },
    { label: "Modified Date", value: "modifiedDate" },
    { label: "Modified By", value: "modifiedBy" },
    { label: "Status", value: "status" },
  ],
  campaigns: [
    { label: "Expected Revenue", value: "expectedRevenue" },
    { label: "Modified Time", value: "modifiedTime" },
    { label: "Campaign Type", value: "campaignType" },
    { label: "Expected Response", value: "expectedResponse" },
    { label: "End Date", value: "endDate" },
    { label: "Transaction Hash", value: "transHash" },
    { label: "Numbers Sent", value: "numbersSent" },
    { label: "Campaign Owner", value: "campaignOwner" },
    { label: "Layout", value: "layout" },
    { label: "Budgeted Cost", value: "budgetedCost" },
    { label: "Created By", value: "createdBy" },
    { label: "Parent Campaign Name", value: "parentCampaignName" },
    { label: "Campaign Status", value: "campaignStatus" },
    { label: "Created Time", value: "createdTime" },
    { label: "Modified By", value: "modifiedBy" },
    { label: "Campaign Name", value: "campaignName" },
    { label: "Start Date", value: "startDate" },
    { label: "Actual Cost", value: "actualCost" },
    { label: "Description", value: "description" },
  ],
};

export const fieldsLabel = {
  fields: [
    { label: "All Fields", value: "all_fields" },
    { label: "Choose Fields", value: "choose_fields" },
  ],
};

export const convertedLeads = {};

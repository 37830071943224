import React,{useState} from "react";
import attachIcon from "../Assets/file-attachment.svg";
import filterMenu from "../Assets/filterMenu.svg";
import { tick } from "./commonSVGs/commonSVGIcons";
import { Modal } from "react-bootstrap";
import cancel from "../Assets/cancelCircle.svg";

const ContactCommonTable = ({
  title,
  buttonLabel,
  buttonAction,
  showHeadings,
  setShowHeadings,
  selectedHeadings,
  data,
  manageColumnsAction,
  resetColumnSizeAction,
  setShowSelectedFilter,
  setSearchColumn,
  setSavingHeadings,
  handledeleteModuleContact,
  setShowCancelModal,
  showCancelModal,

}) => {

  const [hoveredRow, setHoveredRow] = useState(null);
  const [deleteContactId,setDeleteContactId]=useState("");
  const [contactName,setContactName]=useState("")
 

  const handleDeleteConfirmation = () => {
    handledeleteModuleContact(deleteContactId);
    setShowCancelModal(false);
    // setSelectedProductId([]);
  };

  const handleRowMouseEnter = (index) => {
    setHoveredRow(index);
  };

  const handleRowMouseLeave = () => {
    setHoveredRow(null);
  };
  return (
    <div className="lead_Information_mainContainer commonContainer_bg rounded-2 d-flex flex-column py-2 mt-4">
      <div className="d-flex justify-content-between align-items-center ms-2">
        <div className="d-flex gap-2">
          <img src={attachIcon} alt="product" />
          <div className="notesHeader">{title}</div>
        </div>
        <div className="d-flex gap-2 pe-1">
          <button className="addProductsButton" onClick={buttonAction}>
            {buttonLabel}
          </button>
        </div>
      </div>
      <div style={{ borderBottom: "2px solid #ffffff" }} className="mt-2"></div>
      <div className="p-2 mt-2" onClick={() => setShowHeadings(false)}>
        <div style={{ overflowX: "auto" }}>
          <table
            id="productTable"
            style={{ borderCollapse: "collapse", width: "100%" }}
            className="p-2"
          >
            <thead>
              <tr className="p-2 productTableHeader">
                {selectedHeadings.map(
                  (heading, index) =>
                    heading.isChecked && (
                      <th scope="col" key={index}>
                        <span className="pe-5">{heading.name}</span>
                      </th>
                    )
                )}
                <th
                  scope="col"
                  className="text-end cursorPointer position-relative"
                >
                  <img
                    src={filterMenu}
                    alt=""
                    className={`filter_menu ${showHeadings ? "bg-light" : ""}`}
                    onClick={(e) => {
                      e.stopPropagation();
                      setShowSelectedFilter("");
                      setShowHeadings(true);
                    }}
                  />
                  {showHeadings && (
                    <div
                      className="position-absolute rounded-2 border border-primary bg-light"
                      style={{
                        right: "0.2rem",
                      }}
                    >
                      <div className="d-flex flex-column px-2">
                        <span
                          className="p-2 cursorPointer fw-normal text-start"
                          onClick={() => {
                            manageColumnsAction();
                            setSearchColumn("");
                            setSavingHeadings(selectedHeadings);
                          }}
                        >
                          Manage Columns
                        </span>
                        <span
                          className="p-2 fw-normal text-start opacity-50"
                          onClick={resetColumnSizeAction}
                        >
                          Reset Column Size
                        </span>
                      </div>
                    </div>
                  )}
                </th>
              </tr>
            </thead>
            <tbody>
              {data && data.length > 0 ? (
                data.map((item, index) => (
                  <tr
                    style={{
                      cursor: "pointer",
                      borderBottom: "1px solid #ccc",
                    }}
                    onMouseEnter={() => handleRowMouseEnter(index)}
                    onMouseLeave={handleRowMouseLeave}
                    key={index}
                    onClick={() =>item.contactId}
                  >
                    {selectedHeadings.map((obj, i) => {
                      const value = obj.value;
                      const itemValue = item[value];
                      return obj.isChecked ? (
                        <td
                          key={i}
                          className="productsTabledata"
                          style={{ paddingLeft: "0px" }}
                        >
                          {obj.value === "emailOptOut" && itemValue
                            ? tick
                            : itemValue
                            ? itemValue
                            : "--"}
                        </td>
                      ) : null;
                    })}
                   <td style={{ textAlign: "center" }} className="end-0 mt-2">
                      {hoveredRow === index && (
                        <img
                          src={cancel}
                          alt="cancel"
                          style={{
                            width: "20px",
                            height: "20px",
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            setDeleteContactId(item.contactId);
                            setContactName(item.contactName);
                            setShowCancelModal(true);
                          }}
                        />
                      )}
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td
                    colSpan={
                      selectedHeadings.filter((obj) => obj.isChecked).length + 1
                    } // +1 for the extra <td></td>
                    style={{ textAlign: "center", padding: "20px" }}
                  >
                    <div className="text-center mt-2">
                      <div
                        className="ms-4"
                        style={{ color: "#B9B9B9", fontSize: "20px" }}
                      >
                        No records found
                      </div>
                      <div
                        className="ms-4"
                        style={{ color: "#B9B9B9", fontSize: "14px" }}
                      >
                        There is no data available to show
                      </div>
                    </div>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
          {showCancelModal && (
          <Modal
            show={showCancelModal}
            className="d-flex justify-content-start align-items-center"
            aria-labelledby="example-custom-modal-styling-title"
            size="lg"
            id="commonLeadModal"
          >
            <Modal.Header
              onClick={() => {
                setShowCancelModal(false);
              }}
              style={{
                fontSize: "20px",
                fontWeight: "bold",
                background:
                  "linear-gradient(to right, rgba(64, 123, 255, 1), rgba(18, 167, 254, 1))",
                WebkitBackgroundClip: "text",
                backgroundClip: "text",
                WebkitTextFillColor: "transparent",
              }}
            >
              Are you sure you want to delete {contactName} ?
              {/* <span className="borderBottom">{prodName}</span> */}
            </Modal.Header>
            <Modal.Body className="ModalBody overflow-auto">
              <div className="d-flex flex-column gap-4">
                <div>
                  Note:{" "}
                  <span style={{ fontSize: "14px", color: "#9C9C9C" }}>
                    Any associated activities, visits, drafts will also be
                    deleted.
                  </span>
                </div>

                <div className="d-flex justify-content-end w-100 gap-4">
                  <button
                    className="cancelButton px-2"
                    onClick={() => {
                      setShowCancelModal(false);
                    }}
                  >
                    Cancel
                  </button>
                  <button
                    className="unassignButton px-2"
                    onClick={() => handleDeleteConfirmation()}
                  >
                    Delete
                  </button>
                </div>
              </div>
            </Modal.Body>
          </Modal>
        )}
        </div>
      </div>
    </div>
  );
};

export default ContactCommonTable;

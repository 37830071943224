import React from 'react';
import NoteIcon from "../../Assets/noteIcon.svg";

const InvitedMeetingList = ({ invitedMeetings }) => {
  return (
    <div>
      <div className="d-flex gap-2 align-items-center ms-2">
        <img src={NoteIcon} alt="note" />
        <div className="notesHeader">Invited Meetings</div>
      </div>
      <div style={{ borderBottom: "2px solid #ffffff" }} className="mt-2"></div>
      <div className="ms-2 me-2">
      <div
            className="mb-2 mt-4 mx-2"
            style={{
              border: "1px solid #DEDFDF",
              borderRadius: "10px",
            }}
          >
 <table
                id="productTable"
                style={{
                  borderCollapse: "collapse",
                  width: "100%",
                  textAlign: "center",
                }}
                className="p-2"
              >
                {invitedMeetings?.length > 0 && (
                  <thead
                    style={{
                      borderBottom: "2px solid #DEDFDF",
                      height: "40px",
                    }}
                  >
                    <tr className="p-2 productTableHeader">
                    <th style={{ width: "30%" }}>Title</th>
        <th style={{ width: "40%" }}>Meeting Date</th>
        <th style={{ width: "30%" }}>Status</th>
                    </tr>
                  </thead>
                )}
                <tbody className="p-2">
                  {invitedMeetings?.length > 0 ? (
                    invitedMeetings?.map((item, index) => (
                      <tr
                        style={{ borderBottom: "1px solid #ccc" }}
                        key={index}
                      >
                        <td style={{ color: "#2177FE", fontSize: "16px",width:"30%" }}>
                          {item?.title}
                        </td>
                        <td className="productsTabledata" style={{ width: "40%" }}>{item?.meetingDate}</td>
                        <td className="productsTabledata" style={{ width: "30%" }}>{item?.status}</td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="5" style={{ textAlign: "center" }}>
                        <div className="text-center mt-2">
                          <div
                            className="ms-4"
                            style={{ color: "#B9B9B9", fontSize: "20px" }}
                          >
                            No records found
                          </div>
                          <div
                            className="ms-4"
                            style={{ color: "#B9B9B9", fontSize: "14px" }}
                          >
                            There is no data available to show
                          </div>
                        </div>
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
</div>
      </div>
    </div>
  );
}

export default InvitedMeetingList;

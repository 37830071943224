/* eslint-disable */
import React, { useEffect, useState } from "react";
import { URI } from "../../constants";
import { useNavigate } from "react-router-dom";
import crmLogo from "../../Assets/crmLogo2.svg";
import "./signup.css";
import Select from "react-select";
import axios from "axios";
import { FadeLoader } from "react-spinners";
import Lottie from "react-lottie";
import showPasswordIcon from "../../Assets/showPassword.svg";
import hidePasswordIcon from "../../Assets/ClosePassword.svg";
import signupAnimation from "../../Assets/lotties/signupAnimation.json";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import {
  removeExtraSpaces,
  validateFirstName,
  validateLastName,
} from "../../common/CommonComponent";
import { Tooltip } from "react-tooltip";
import alert from "../../Assets/alert-circle (1).svg";

function Signup() {
  const urlFetched = window.location.href;
  const after = urlFetched.slice(urlFetched.indexOf("?") + 1);
  var getToken = after.slice(after.indexOf("=") + 1);

  const [signupView, setSighupView] = useState("invitePage");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [password, setPassword] = useState("");
  const [phoneNo, setPhoneNo] = useState("");
  const [ipAdd, setIpAdd] = useState("");
  const [ipPhoneCode, setIpPhoneCode] = useState("");
  const [IpCountry, setIpCountry] = useState("");
  const [firstNameError, setFirstNameError] = useState("");
  const [lastNameError, setLastNameError] = useState("");
  const [phoneNoError, setPhoneNoError] = useState("");
  const [countdown, setCountdown] = useState(5);
  const [showPassword, setShowPassword] = useState(false);

  const [stateList, setStateList] = useState([]);
  const [stateName, setStateName] = useState({
    label: "",
    value: "",
  });

  const [userDetails, setUserDetails] = useState();
  const [isFirstCharacterEntered, setIsFirstCharacterEntered] = useState(false);
  const [passwordError, setPasswordError] = useState("");
  const navigate = useNavigate();

  const CountryList = [{ value: "IN", label: "India" }];

  const [indivCountry, setIndivCountry] = useState({
    value: CountryList[0].value,
    label: CountryList[0].label,
  });
  const [sessionValidity, setSessionValidity] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isCheckboxChecked, setIsCheckboxChecked] = useState(true);

  const handleCheckboxChange = (e) => {
    setIsCheckboxChecked(e.target.checked);
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleStateChange = async (country, e) => {
    setStateName(e);
  };

  const handleChangefirstName = (e) => {
    const { value } = e.target;
    const inputValue = value.replace(/[^a-zA-Z\s]/g, "");

    setFirstName(inputValue);
  };

  useEffect(() => {
    let timer;
    if (signupView === "signUpAccepted") {
      timer = setInterval(() => {
        setCountdown((prevCountdown) => prevCountdown - 1);
      }, 1000);
      if (countdown === 0) {
        clearInterval(timer);
        navigate("/");
      }
    }
    return () => clearInterval(timer);
  }, [signupView, countdown, navigate]);

  const handleChangelastName = (e) => {
    const { value } = e.target;
    const inputValue = value.replace(/[^a-zA-Z\s]/g, "");

    setLastName(inputValue);

    if (inputValue.length > 0) {
      setIsFirstCharacterEntered(true);
    } else {
      setIsFirstCharacterEntered(false);
    }
  };

  const handleChangePassword = (e) => {
    const { value } = e.target;
    setPassword(value);

    if (value.length > 0) {
      setIsFirstCharacterEntered(true);
    } else {
      setIsFirstCharacterEntered(false);
    }
  };

  const handleChangePhoneNumber = (e) => {
    const value = e.target.value;
    const regex = /^\d{0,10}$/;
    if (regex.test(value)) {
      setPhoneNo(value);
      setIsFirstCharacterEntered((prev) => ({
        ...prev,
        phoneNo: value.length > 0 && value[0] !== " ",
      }));
    }
  };

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: signupAnimation,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const handleRejectEmail = async () => {
    await axios
      .post(URI.rejectEmail, {
        loggedInUserId: userDetails.userId,
      })
      .then((res) => {
        if (res.data.status === 200) {
          navigate("/");
        } else {
          NotificationManager.error("", res.data.message, 5000);
        }
      })
      .catch(() => {});
  };

  const getVerifySession = async () => {
    setIsLoading(true);
    await axios
      .post(URI.verifyEmailLink, {
        linkDetails: getToken,
      })
      .then((res) => {
        if (res.data.status !== 200) {
          setSessionValidity(true);
          setIsLoading(false);
        } else {
          setUserDetails(res.data.data);
          setIsLoading(false);
        }
      })
      .catch(() => {});
  };


  const handleCountry = async (e) => {
    setStateList([]);
    const header = {
      "X-CSCAPI-KEY":
        "SVRmaGtaRDlRZmY2WkQ0Mk9YbUVta1NER3c3VEdJZUMxdGkycFVnMA==",
    };

    await axios
      .get("https://api.countrystatecity.in/v1/countries/" + "IN" + "/states", {
        headers: header,
      })
      .then((response) => {
        if (response.status === 200) {
          const newStates = response.data
            .sort((a, b) => a.name.localeCompare(b.name))
            .map((element) => ({
              label: element.name,
              value: element.iso2,
            }));
          setStateList(newStates);
          setStateName(newStates[0]);
          handleStateChange(e, newStates[0]);
        }
      })
      .catch((err) => {
        throw new Error("Failed to fetch data", err);
      });
  };

  const handleSignup = async () => {
    const errors = {};
    let hasError = false;

    if (!firstName) {
      errors.firstName = "First Name cannot be empty.";
      hasError = true;
    }

    if (!lastName) {
      errors.lastName = "Last Name cannot be empty.";
      hasError = true;
    }

    if (!phoneNo) {
      errors.phoneNo = "Phone Number cannot be empty.";
      hasError = true;
    } else if (!/^\d{10}$/.test(phoneNo)) {
      errors.phoneNo = "Phone Number must be 10 digits.";
      hasError = true;
    }

    if (!password) {
      errors.password = "Password cannot be empty.";
      hasError = true;
    } else if (
      !/^(?=.*[a-z])(?=.*[0-9])(?=.*[A-Z])(?=.*[!@#$%^&*.])[a-zA-Z0-9!@#$%^&*.]{8,40}$/.test(
        password
      )
    ) {
      errors.password =
        "Weak password";
      hasError = true;
    }

    if (firstName && !validateFirstName(firstName)) {
      errors.firstName = "Please enter a valid First name.";
      hasError = true;
    }

    if (lastName && !validateLastName(lastName)) {
      errors.firstName = "Please enter a valid Last name.";
      hasError = true;
    }

    if (hasError) {
      setPasswordError(errors.password || "");
      setFirstNameError(errors.firstName || "");
      setLastNameError(errors.lastName || "");
      setPhoneNoError(errors.phoneNo || "");
      setTimeout(() => {
        setPasswordError("");
        setFirstNameError("");
        setLastNameError("");
        setPhoneNoError("");
      }, 3000);

      return; // Stop further execution
    }

    const requestBody = {
      userId: userDetails.userId,
      firstName: removeExtraSpaces(firstName),
      lastName: removeExtraSpaces(lastName),
      phoneNumber: removeExtraSpaces(phoneNo),
      custCredential: removeExtraSpaces(password),
      state: stateName.value,
      country: "India",
      ipPhoneCode: ipPhoneCode,
      ipAddress: ipAdd,
      ipCountryCode: IpCountry,
      loggedInUserId: userDetails.userId,
    };

    await axios
      .post(URI.signupInvitation, requestBody)
      .then((response) => {
        if (response.data.status === 200) {
          setSighupView("signUpAccepted");
          NotificationManager.success("", response.data.message, 5000);
          setFirstName("");
          setLastName("");
          setPhoneNo("");
          setPassword("");
        } else {
          NotificationManager.error("", response.data.message, 5000);
        }
      })
      .catch((err) => {
        throw new Error("Failed to fetch data", err);
      });
  };

  const ipDetails = async () => {
    const response = await axios.get("https://ipwhois.app/json/");
    setIpCountry(response.data.country_code);
    setIpAdd(response.data.ip);
    setIpPhoneCode(response.data.country_phone);
  };

  const customSelectLocationStyles = {
    option: (provided) => ({
      ...provided,
      backgroundColor: "#EAEAEA",
      color: "#000",
    }),
    control: (baseStyles) => ({
      ...baseStyles,
      border: "1px solid #D9D9D9",
      height: "40px",
      borderRadius: "10px",
      width: "100%",
      boxShadow: "none",
    }),
    input: (base) => ({
      ...base,
      color: "#000",
    }),
    singleValue: (defaultStyles) => ({ ...defaultStyles, color: "#000" }),

    menuList: (provided) => ({
      ...provided,
      paddingTop: 0,
      paddingBottom: 0,
      "&::-webkit-scrollbar": {
        width: 5,
      },

      "&::-webkit-scrollbar-thumb": {
        background: "#316AFF",
        borderRadius: "10px",
      },
    }),
  };

  const customFilterOption = (option, rawInput) => {
    const input = rawInput.replace(/\s+/g, "");
    return option.label
      .replace(/\s+/g, "")
      .toLowerCase()
      .includes(input.toLowerCase());
  };

  const handleSignupView = () => {
    if (signupView === "invitePage") {
      return (
        <div className="signUpContainer">
          <div className="signUpSection">
            <div className="signupTopLine">
              <img src={crmLogo} height={40} className="d-flex mb-5" />
              <h5 className="headerClass">
                <span style={{ color: "#407BFF" }}>{userDetails?.email}</span>{" "}
                invited by{" "}
                <span style={{ color: "#407BFF" }}>
                  {userDetails?.userName}
                </span>
              </h5>
            </div>
            <div className="d-flex flex-column align-items-center">
              <h3 style={{ color: "#407BFF" }} className="headerClass">
                Join Our Organization
              </h3>
              <p className="text-center">
                Create an account on Chain CRM using your email address{" "}
                <b>{userDetails?.email}</b>
              </p>
            </div>
            <div className="d-flex gap-3 buttonWrap">
              <button
                className="signupBtn"
                onClick={() => {
                  handleRejectEmail();
                }}
              >
                Reject
              </button>
              <button
                className="signupBtn"
                onClick={() => {
                  setSighupView("detailsPage");
                  setFirstName("");
                  setLastName("");
                  setPhoneNo("");
                  setPassword("");
                }}
              >
                Accept & Sign Up
              </button>
            </div>
          </div>
        </div>
      );
    } else if (signupView === "detailsPage") {
      return (
        <div className="signUpContainerDetails">
          <div className="signUpSectionDetails">
            <div className="signupTopLine">
              <img src={crmLogo} height={40} className="d-flex mb-5" />
              <h5>
                <span style={{ color: "#407BFF" }}>{userDetails?.email}</span>{" "}
                invited by{" "}
                <span style={{ color: "#407BFF" }}>
                  {userDetails?.userName}
                </span>
              </h5>
            </div>
            <div className="d-flex flex-column align-items-center">
              <h3 style={{ color: "#407BFF" }} className="headerClass">
                Join Our Organization
              </h3>
              <p className="text-center">
                A new Chain CRM account will be created for the email address{" "}
                {""}
                {userDetails?.email}
              </p>
            </div>
            <div className="d-flex w-100 gap-5 mt-2 formInputWrap">
              <div className="w-100">
                <input
                  type="text"
                  className="signupInputField w-100"
                  placeholder="First Name"
                  value={firstName}
                  onChange={(e) => {
                    const input = e.target.value;
                    const regex = /^[A-Za-z][A-Za-z\s]*$/; // Allow letters, numbers, and spaces, but not space as the first character

                    if (
                      input === "" ||
                      (input[0] !== " " && regex.test(input))
                    ) {
                      setFirstName(input);
                    }
                  }}
                  maxLength={30}
                />
                <div className="ms-2">
                  {firstNameError && (
                    <p style={{ color: "red" }}>{firstNameError}</p>
                  )}
                </div>
              </div>
              <div className="w-100 ">
                <input
                  type="text"
                  className="signupInputField w-100"
                  placeholder="Last Name"
                  value={lastName}
                  onChange={(e) => {
                    const input = e.target.value;
                    const regex = /^[A-Za-z][A-Za-z\s]*$/; // Allow letters, numbers, and spaces, but not space as the first character

                    if (
                      input === "" ||
                      (input[0] !== " " && regex.test(input))
                    ) {
                      setLastName(input);
                    }
                  }}
                  maxLength={30}
                />
                <div className="ms-2">
                  {lastNameError && (
                    <p style={{ color: "red" }}>{lastNameError}</p>
                  )}
                </div>
              </div>
            </div>
            <div className="d-flex w-100 gap-5 formInputWrap">
            <div className="w-100 position-relative">
  <input
    type={showPassword ? "text" : "password"}
    className="signupInputFieldPassword"
    placeholder="Password"
    value={password}
    onChange={(e) => {
      setPassword(e.target.value.trim());
    }}
    maxLength={40}
    minLength={8}
  />
  <img
    src={showPassword ? showPasswordIcon : hidePasswordIcon}
    alt={showPassword ? "Hide Password" : "Show Password"}
    onClick={togglePasswordVisibility}
    className="cursorPointer"
    style={{
      position: "absolute",
      right: "5%",
      top: "36%",
      transform: "translateY(-50%)",
    }}
  />
  <Tooltip id="tooltip" place="top" className="custom-tooltip" />
  <span
    className="py-2 mt-1 ms-3 position-absolute tooltipIcon"
    data-tooltip-id="tooltip"
    data-tooltip-content="Password must contain at least one lowercase letter, one uppercase letter, one number, one special character and should be between 8 to 40 characters long."
  >
    <img
      src={alert}
      height={20}
      width={20}
      alt="icon"
      className="cursorPointer infoImage"
      style={{ marginTop: "-4px" }}
    />
  </span>

  <div className="ms-2" style={{ height: "20px" }}>
    {passwordError && (
      <p style={{ color: "red", margin: 0 }}>{passwordError}</p>
    )}
  </div>
</div>


              <div className="w-100">
                <input
                  type="text"
                  className="signupInputFieldPassword"
                  pattern="[0-9]*"
                  placeholder="Phone"
                  name="phoneNumber"
                  value={phoneNo}
                  onChange={handleChangePhoneNumber}
                  maxLength={10}
                  // onKeyDown={handleKeyDown}
                />
                <div className="ms-2">
                  {phoneNoError && (
                    <p style={{ color: "red" }}>{phoneNoError}</p>
                  )}
                </div>
              </div>
            </div>
            <div className="d-flex w-100 gap-5 formInputWrap">
              <Select
                value={indivCountry}
                menuPlacement="auto"
                // onChange={(e) => handleCountry(e)}
                options={CountryList}
                styles={customSelectLocationStyles}
                className="w-100 "
                isSearchable={false}
              />
              <Select
                value={stateName}
                menuPlacement="auto"
                onChange={(e) => handleStateChange(indivCountry, e)}
                options={stateList}
                styles={customSelectLocationStyles}
                className="w-100"
                filterOption={customFilterOption}
              />
            </div>
            <div className="d-flex justify-content-start align-items-start w-100">
              <div>
                <input
                  type="checkbox"
                  checked={isCheckboxChecked}
                  onChange={handleCheckboxChange}
                  style={{ height: "27px" }}
                />
              </div>
              <p className="ms-2">
                I agree to the{" "}
                <span
                  style={{
                    color: "#407BFF",
                    fontWeight: "bold",
                    cursor: "pointer",
                  }}
                >
                  Term of services
                </span>{" "}
                and{" "}
                <span
                  style={{
                    color: "#407BFF",
                    fontWeight: "bold",
                    cursor: "pointer",
                  }}
                >
                  Privacy policy
                </span>{" "}
                of Block Stack Corporation{" "}
              </p>
            </div>
            <div className="d-flex gap-3 buttonWrap">
              <button
                className="signupBtn"
                onClick={() => {
                  setSighupView("invitePage");
                  setFirstName("");
                  setLastName("");
                  setPhoneNo("");
                  setPassword("");
                }}
              >
                Back
              </button>
              <button
                className="signupBtn"
                onClick={() => handleSignup()}
                style={{ opacity: isCheckboxChecked ? 1 : 0.5 }}
                disabled={!isCheckboxChecked}
              >
                Sign up & Accept
              </button>
            </div>
          </div>
        </div>
      );
    } else if (signupView === "signUpAccepted") {
      return (
        <div className="signUpContainer">
          <div className="signUpAcceptSection">
            <div className="acceptedLine">
              <img
                src={crmLogo}
                height={40}
                className="d-flex mb-5"
                alt="crm"
              />
            </div>
            <div className="d-flex flex-column align-items-center">
              <p style={{ fontWeight: "bold", fontSize: "20px" }}>Accepted</p>
              <p>
                You are now a part of this organization. You will be redirected
                in {countdown} seconds, otherwise please click
                &#39;Continue&#39;
              </p>
              <button
                className="signupBtn"
                onClick={() => {
                  navigate("/");
                }}
              >
                Continue
              </button>
            </div>
          </div>
        </div>
      );
    }
  };
  useEffect(() => {
    ipDetails();
    handleCountry();
    getVerifySession();
  }, []);
  return (
    <>
      {
        <div className="mainContainer">
          {isLoading ? (
            <div className="loginMainCon successPage">
              <FadeLoader
                color="rgb(54, 215, 183)"
                loading={isLoading}
                size={150}
              />
            </div>
          ) : sessionValidity ? (
            <div className="loginContainer">
              <div className="loginSectionSignup">
                <div className="loginInput">
                  <img
                    src={crmLogo}
                    height={50}
                    className="d-flex mt-4"
                    alt=""
                  />
                  <p
                    className="mt-4"
                    style={{
                      background:
                        "linear-gradient(to right, rgba(64, 123, 255, 1), rgba(18, 167, 254, 1))",
                      WebkitBackgroundClip: "text",
                      WebkitTextFillColor: "transparent",
                      fontSize: "x-large",
                      textAlignLast: "center",
                    }}
                  >
                    Your Token has been expired
                  </p>
                  <span
                    className="mt-2"
                    style={{
                      borderBottom: "1px solid rgba(217, 217, 217, 1)",
                      width: "75%",
                      alignSelf: "center",
                    }}
                  />
                  <div className="mt-5">
                    <Lottie
                      options={defaultOptions}
                      height="15vh"
                      width="11.5vw"
                    />
                  </div>
                </div>
              </div>
            </div>
          ) : (
            handleSignupView()
          )}
        </div>
      }
      <NotificationContainer />
    </>
  );
}

export default Signup;

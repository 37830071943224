import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loginUserId: "",
  loginUserInfo: {},
  profileId: "",
  permissionList: {},
  socialDetails: [],
  planExpiry: "",
  subscriptionType: "",
  filterOptions: [],
};

export const stateSlice = createSlice({
  name: "stae",
  initialState,
  reducers: {
    setLoginUserId: (state, action) => {
      state.loginUserId = action.payload;
    },
    setLoginUserInfo: (state, action) => {
      state.loginUserInfo = action.payload;
    },
    setUserProfileId: (state, action) => {
      state.profileId = action.payload;
    },
    permissionDetailsList: (state, action) => {
      state.permissionList = action.payload;
    },
    setSocialMediaInfo: (state, action) => {
      state.socialDetails = action.payload;
    },
    setPlanExpiry: (state, action) => {
      state.planExpiry = action.payload;
    },
    setSubscriptionType: (state, action) => {
      state.subscriptionType = action.payload;
    },

    setFilterOptions: (state, action) => {
      state.filterOptions = action.payload;
    },
  },
});

export const {
  setLoginUserId,
  setLoginUserInfo,
  setUserProfileId,
  permissionDetailsList,
  setSocialMediaInfo,
  setPlanExpiry,
  setSubscriptionType,
  setFilterOptions,
} = stateSlice.actions;

export default stateSlice.reducer;

import React from 'react';
import Chart from 'react-apexcharts';
import moment from "moment";

const SplineAreaChart = ({ data = [] }) => {
  const dates = data.map(item => moment(item.loginDateTime).format('YYYY-MM-DD'));
  const signInCounts = data.map(item => item.signInCount);

  const options = {
    chart: {
      type: 'area',
      height: 350,
      zoom: {
        enabled: false
      }
    },
    dataLabels: {
      enabled: false
    },
    colors: [
      "#FF9364",
      "#F25F33"
    ],
    stroke: {
      curve: 'smooth'
    },
    xaxis: {
      type: 'datetime',
      categories: dates 
    },
    tooltip: {
      x: {
        format: "dd MMM yyyy"
      }
      // Custom tooltip function can be added here if needed
    }
  };

  const series = [
    {
      name: 'Sign in count',
      data: signInCounts 
    },
  ];

  return (
    <div id="chart">
      <Chart options={options} series={series} type="area" height={200} />
    </div>
  );
};

export default SplineAreaChart;

import axios from "axios";
import getHeaders from "../../common/ApiHeader";
import { URI } from "../../constants";
import { handleApiError } from "../../utils/ApiUtils";

export const updateVendorDetails = async (
  requestBody,
  vendorFormData,
  setBtnLoader
) => {
  const formData = new FormData();
  formData.append("vendorDtlsInputs", JSON.stringify(requestBody));
  formData.append("vendorImage", vendorFormData.vendorImage);

  setBtnLoader(true);
  try {
    const response = await axios.post(URI.updateAllVendorDetails, formData, {
      headers: getHeaders(true),
    });
    return response.data;
  } catch (error) {
    throw new Error("Failed to fetch data", error);
  } finally {
    setBtnLoader(false);
  }
};

export const handleSubmitVendorDetails = async (
  e,
  requestBody,
  existingClone,
  vendorUpdateInfo,
  vendorFormData,
  setSaveAndNewSubmit,
  setBtnLoader,
  setVendorPage,
  navigate
) => {
  const formData = new FormData();
  formData.append("vendorDtlsInputs", JSON.stringify(requestBody));
  formData.append(
    "vendorImage",
    existingClone ? vendorUpdateInfo.vendorImage : vendorFormData.vendorImage
  );

  try {
    const response = await axios.post(URI.addVendorDetails, formData, {
      headers: getHeaders(true),
    });

    return response.data;
  } catch (error) {
    handleApiError(error, navigate);
  } finally {
    setSaveAndNewSubmit(false);
    setBtnLoader(false);
    if (e === "CLONE SAVE AND NEW") setVendorPage("vendorHomePage");
    if (e === "Add") setVendorPage("create");
  }
};

import React from "react";
import { Modal } from "react-bootstrap";
import down from "../../Assets/Down31.svg";
import up from "../../Assets/Up.svg";
import userSearch from "../../Assets/user-search-01.svg";
import { defaultOptions } from "../../utils/defaultOptions";
import Lottie from "react-lottie";
const ChangeOwnerModal = ({
  modalType,
  setShowModalType,
  selectedOwnerItem,
  handleChangeOwnerInputClick,
  setSelectedOwnerItem,
  isLeadOwnerModal,
  setIsLeadOwnerModal,
  isChangeOwnerList,
  userList,
  handleChangeOwnerItemClick,
  emailNotification,
  setEmailNotification,
  handleEditClick,
  handleUpdateClick,
  setUpdatedFields,
  onSelectedChangeOwner,
  existingContact,
  apiLoader,
}) => {
  return (
    <Modal
      show={modalType === "changeOwner"}
      id="changeOwnerModal "
      aria-labelledby="example-custom-modal-styling-title"
      className="withdrw_modal d-flex justify-content-center align-items-center "
    >
      <Modal.Header
        className=" border-0 mb-0 d-flex flex-column align-items-start"
        style={{ backgroundColor: "#F2F2F2" }}
      >
        <h4 className="commonTextColor">Change Owner</h4>
      </Modal.Header>
      <Modal.Body>
        <div className="d-flex flex-column pt-1">
          <span className="pb-1">Choose Owner</span>
          <div className="d-flex input-with-clear">
            <input
              type="text"
              placeholder="Selected User"
              className=" cursorPointer"
              style={{
                width: "35vw",
                right: "28px",
                height: "42px",
                outline: "none",
                borderRadius: "10px",
                padding: "12px",
                border: "1px solid black",
              }}
              value={selectedOwnerItem?.name}
              onClick={handleChangeOwnerInputClick}
              readOnly
            />
            {selectedOwnerItem ? (
              <span
                className="clear-button-owner"
                onClick={() => setSelectedOwnerItem("")}
              >
                <img src={down} alt="img" height={20} width={20} />
              </span>
            ) : (
              <span className="clear-button-owner">
                <img src={up} alt="img" height={20} width={20} />
              </span>
            )}

            <span
              className="cursorPointer pt-2 ps-1"
              onClick={() => setIsLeadOwnerModal(!isLeadOwnerModal)}
            >
              <img src={userSearch} alt="img" width={20} height={20} />
            </span>
          </div>

          {isChangeOwnerList && (
            <ul
              className="data-list list-group  lead_ownerList_container position-absolute"
              style={{ top: "5.7rem", width: "89%" }}
              onClick={(e) => e.stopPropagation()}
            >
              {userList.map((item, index) => (
                <li
                  key={index}
                  className="cursorPointer list-group-item"
                  onClick={() => handleChangeOwnerItemClick(item)}
                >
                  <div className="d-flex flex-column">
                    <span style={{ fontWeight: "500" }}> {item?.name} </span>
                    <span style={{ fontSize: "14px", color: "#999999" }}>
                      {" "}
                      {item.emailId}{" "}
                    </span>
                  </div>
                </li>
              ))}
            </ul>
          )}

          {selectedOwnerItem?.name ? (
            <div className="pt-2 d-flex gap-2">
              <input
                type="checkbox"
                checked={emailNotification}
                onClick={() => {
                  setEmailNotification(!emailNotification);
                  handleEditClick("emailNotification");
                }}
                onChange={(e) => {
                  setUpdatedFields((prevFields) => ({
                    ...prevFields,
                    emailNotification: e.target.checked,
                  }));
                }}
              />
              <span>Send Email notification {selectedOwnerItem.name}</span>
            </div>
          ) : (
            <></>
          )}

          <div className="d-flex justify-content-end gap-4 pt-3 pe-3">
            <button
              className=" cancelButton  px-4"
              onClick={() => setShowModalType("")}
            >
              Cancel{" "}
            </button>
            <button
              className="unassignButton btnColor"
              onClick={() => {
                if (existingContact) {
                  onSelectedChangeOwner(selectedOwnerItem);
                  setShowModalType("");
                } else {
                  handleUpdateClick("emailNotification");
                }
              }}
              disabled={apiLoader}
              style={{ width: "122px" }}
            >
              {apiLoader ? (
                <Lottie
                  options={defaultOptions("white")}
                  height="24px"
                  width="36px"
                />
              ) : existingContact ? (
                "DONE"
              ) : (
                "Change Owner"
              )}
            </button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ChangeOwnerModal;

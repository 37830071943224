import React from "react";
import { Modal } from "react-bootstrap";
import Lottie from "react-lottie";
import { defaultOptions } from "../../utils/defaultOptions";

const VendorShowImageModal = ({
  showImageModal,
  setShowImageModal,
  setUploadImg,
  setErrorMessage,
  vendorInfoDetails,
  setShowRemoveModal,
  uploadImg,
  imageUrl,
  downloadIcon,
  vendorAvatar,
  errorMessage,
  uploadImage,
  isImageUploaded,
  handleUploadImage,
  imageLoader,
}) => {
  return (
    <Modal
      show={showImageModal}
      className="d-flex justify-content-center align-items-center"
      aria-labelledby="example-custom-modal-styling-title"
      size="lg"
      id="commonLeadModal"
    >
      <Modal.Header
        closeButton
        onClick={() => {
          setShowImageModal(false);
          setUploadImg(false);
          setErrorMessage(" ");
        }}
        className="custom-modal-header"
        style={{ borderBottom: "none" }}
      >
        {vendorInfoDetails.vendorImageData && (
          <button
            className="imageAccButtonRemove"
            onClick={() => {
              setShowRemoveModal(true);
            }}
          >
            Remove
          </button>
        )}
      </Modal.Header>
      <Modal.Body className="ModalBody overflow-auto">
        <div className="d-flex flex-column gap-4">
          <div className="d-flex" style={{ position: "relative" }}>
            {vendorInfoDetails.vendorImageData && (
              <a
                href={uploadImg ? imageUrl : vendorInfoDetails.vendorImageData}
                download="image.jpg"
                style={{
                  position: "absolute",
                  right: "10px",
                  top: "5px",
                  background: "rgba(255, 255, 255, 0.3)",
                  borderRadius: "10px",
                  padding: "5px",
                  cursor: "pointer",
                }}
              >
                <img src={downloadIcon} alt="download" />
              </a>
            )}

            <div className="bordered-container">
              <img
                alt="upload"
                src={
                  uploadImg
                    ? imageUrl
                    : vendorInfoDetails.vendorImageData !== null
                    ? vendorInfoDetails.vendorImageData
                    : vendorAvatar
                }
                className="profilePhoto"
              />
              {errorMessage && (
                <p className="mb-2 text-center imgMessege">{errorMessage}</p>
              )}
            </div>
          </div>

          {/* <p>{deleteMsg}</p> */}
          <div className="d-flex w-100 gap-3 mx-1">
            <input
              type="file"
              id="accountImage"
              name="accountImage"
              accept="image/*"
              className="file-control"
              onChange={handleUploadImage}
            />
            <label htmlFor="accountImage" className="imageAccButton">
              <span>Upload Image</span>
            </label>

            <button
              className={`imageAccButtonSave ${
                !isImageUploaded ? "save-button-disabled" : ""
              }`}
              onClick={() => {
                uploadImage();
              }}
              disabled={!isImageUploaded || imageLoader}
            >
              {imageLoader ? (
                <Lottie
                  options={defaultOptions("white")}
                  height="24px"
                  width="36px"
                />
              ) : (
                "Save"
              )}
            </button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default VendorShowImageModal;

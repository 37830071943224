import React, { useEffect, useState } from "react";
import filterIcon from "../../Assets/Frame (3).svg";
import Select from "react-select";
import groupIcon from "../../Assets/De-Active/Invoice.svg";
import down from "../../Assets/Down31.svg";
import up from "../../Assets/Up.svg";
import userSearch from "../../Assets/user-search-01.svg";
import LeadOwnerModal from "../../common/LeadOwnerModal";
import "./invoices.css";
import "../Lead/lead.css";
import axios from "axios";
import { URI } from "../../constants";
import filterMenu from "../../Assets/filterMenu.svg";
import tableFilterIcon from "../../Assets/filterIcon.svg";
import filterLine from "../../Assets/filterLine.svg";
import { Dropdown, Modal } from "react-bootstrap";
import Pagination from "../../common/Pagination";
import arrowUp from "../../Assets/square_arrow_up.svg";
import arrowDown from "../../Assets/square_arrow_down.svg";
import pin from "../../Assets/Pin_column.svg";
import unPin from "../../Assets/Unpin.svg";
import cancel from "../../Assets/cancel_circle.svg";
import searchIcon from "../../Assets/search.svg";
import noteIcon from "../../Assets/notebook-01.svg";
import openActivityIcon from "../../Assets/openActivity.svg";
import closeActivityIcon from "../../Assets/closeActivity.svg";
import updateIcon from "../../Assets/Group 71.svg";
import cancelIcon from "../../Assets/Group 72.svg";
import groupIcon2 from "../../Assets/Group 74.svg";
import history from "../../Assets/history.svg";
// import hierachy from "../../Assets/hierarchy.svg";
import EditableInput from "../../common/EditableInput";
import Clone from "../../Assets/Clone.svg";
import Share from "../../Assets/ShareIcon.svg";
import Delete from "../../Assets/DeleteIconNew.svg";
import salesOrdersIcon from "../../Assets/De-Active/Sales Order.svg";
import accountsIcon from "../../Assets/De-Active/Account.svg";
import ContactIcon from "../../Assets/De-Active/Contact.svg";
import dealsIcon from "../../Assets/De-Active/Deals.svg";

import { massOptions, pageOption, taxOptions } from "../../common/CommonData";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import ShareModal from "../../common/ShareModal";
import Note from "../../common/commonRelatedList/Note";
import OpenActivity from "../../common/commonRelatedList/OpenActivity";
import CloseActivity from "../../common/commonRelatedList/CloseActivity";
import { useSelector } from "react-redux";
import Loader from "../../common/commonRelatedList/Loader";
import nodataInvoice from "../../Assets/nodataInvoice.svg";
import {
  leftArrow,
  rightAngleArrow,
  leftAngleArrow,
  editIcon,
  tick,
  expandArrow,
  shrinkArrow,
} from "../../common/commonSVGs/commonSVGIcons.js";
import AccessModal from "../../common/AccessModal";
import logoConvert from "../../Assets/logoConvert.svg";
import SearchIcon from "../../Assets/newSearch.svg";
import moment from "moment";
import { EXPLORER_URL } from "../../constants/DataConst.js";
import {
  validateCallSubject,
  validateCountryName,
  validateStateIndia,
  validateCityIndia,
  removeExtraSpaces,
  customCommonStyles,
  removeLeadingZeros,
} from "../../common/CommonComponent";
import Lottie from "react-lottie";
import { defaultOptions } from "../../utils/defaultOptions.js";
import getHeaders from "../../common/ApiHeader.js";
import { handleApiError } from "../../utils/ApiUtils";
import { useNavigate } from "react-router-dom";
import CalculatorTooltip from "../../common/CalculatorTooltip.js";

function Invoices({
  setCurrentView,
  setInnerPage,
  innerPage,
  permissionList,
  notePermission,
  fetchDealInfo,
  fetchContactInfo,
  fetchAccInfo,
  fetchSalesInfo,
  setFetchSalesInfo,
  pastViewInfo,
  setPastViewInfo,
  currentView,
  storeModuleId,
}) {
  const navigate = useNavigate();
  // const [filterView, setFilterView] = useState(false); setFilterView is not used
  const [dealList, setDealList] = useState([]);
  const [contactList, setContactList] = useState([]);
  const [accountList, setAccountList] = useState([]);
  const [salesList, setSalesList] = useState([]);
  const [products, setProducts] = useState([]);
  const [showAccessModal, setShowAccessModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [billingCountryError, setBillingCountryError] = useState("");
  const [billingStateError, setBillingStateError] = useState("");
  const [billingCityError, setBillingCityError] = useState("");
  const [shippingCountryError, setShippingCountryError] = useState("");
  const [shippingStateError, setShippingStateError] = useState("");
  const [shippingCityError, setShippingCityError] = useState("");
  const [showTooltipExciseDuty, setShowTooltipExciseDuty] = useState(false);
  const [showTooltipSalesCommission, setShowTooltipSalesCommission] =
    useState(false);
  const [tooltipContent, setTooltipContent] = useState("");

  const hasPermission = (action) => {
    const permission = permissionList.invoices.find((p) => p.action === action);
    return permission ? permission.status === "TRUE" : false;
  };

  const handleCreateInvoice = () => {
    if (hasPermission("CREATE")) {
      let discountedAmount = 0;
      let taxAmount = 0;
      let total = 0;
      if (products.length > 0) {
        const { unitPrice, tax, productId, productName, description } =
          products[0];
        discountedAmount = parseFloat(unitPrice) * parseFloat(unitPrice) - 0;
        taxAmount = (parseFloat(tax || 0) / 100) * discountedAmount;
        total = (discountedAmount + taxAmount).toFixed(2) || 0;

        setAllProducts([
          {
            description: description,
            productId: productId,
            productName: productName || "-",
            quantity: unitPrice || 1,
            listPrice: unitPrice || 0,
            amount: unitPrice * unitPrice,
            discount: 0,
            tax: parseFloat(tax) || 0,
            total: isNaN(total) ? 0 : total,
            discountType: 1,
            discountValue: 0,
          },
        ]);
      }
      setEditInvoiceOverview(false);
      setInvoiceInfo({
        dealId: "",
        salesOrderId: "",
        contactId: "",
        accountId: "",
        description: "",
        invoiceDate: new Date().toISOString().split("T")[0],
        invoiceId: "",
        invoiceOwnerId: selectedOwner.userId,
        purchaseOrder: "",
        salesCommission: "",
        subject: "",
        dueDate: "",
        exciseDuty: "",
        status: stausOptions[0],
        termsAndConditions: "",
        billingStreet: "",
        billingCity: "",
        billingState: "",
        billingCode: "",
        billingCountry: "",
        shippingStreet: "",
        shippingCity: "",
        shippingState: "",
        shippingCode: "",
        shippingCountry: "",
        loggedInUserId: loginUserId,
        purchaseItemDtlsDto: [],
        subTotal: isNaN(total) ? 0 : total,
        discount: 0,
        discountType: 1,
        discountValue: 0,
        tax: 0,
        adjustment: 0,
        grandTotal: isNaN(total) ? 0 : total,
      });

      setInnerPage("create");
      setSelectedCheckboxes([]);
      if (isAccountListModal) {
        setSelectedAccount({
          moduleName: accountList[0]?.moduleName,
          moduleId: accountList[0].moduleId,
        });
      }
      setSelectedAccount({
        accountName: accountList?.accountName,
        accountId: accountList?.accountId,
      });
      setSelectedSale({
        subject: "",
        salesOrdId: "",
      });
      setSelectedDeal({
        dealName: dealList?.dealName,
        dealId: dealList?.dealId,
      });
      setSelectedContact({
        contactName: contactList?.contactName,
        contactId: contactList?.contactId,
      });
      setSelectedOwner({
        name: userList[0].name,
        userId: userList[0].userId,
      });
    } else {
      setShowAccessModal(true);
    }
  };

  useEffect(() => {
    if (storeModuleId) {
      getInvoiceOverview(storeModuleId);
      setInvoiceId(storeModuleId);
    }
  }, []);

  const handleCreateNoDataInvoice = () => {
    if (hasPermission("CREATE")) {
      setInnerPage("create");
    } else {
      setShowAccessModal(true);
    }
  };

  const handleEditInvoice = () => {
    if (hasPermission("EDIT")) {
      setInnerPage("create");
      setEditInvoiceOverview(true);
      setInvoiceInfo({
        ...singleInvoiceDetails.getinvoiceInfo,
        ...singleInvoiceDetails.invoiceItemsTotDtls,
        invoiceDate: singleInvoiceDetails.getinvoiceInfo.invoiceDate
          ?.split("-")
          .reverse()
          .join("-"),
        dueDate: singleInvoiceDetails.getinvoiceInfo.dueDate
          ?.split("-")
          .reverse()
          .join("-"),
      });
    } else {
      setShowAccessModal(true);
    }
  };

  const handleEditInvoiceClone = () => {
    if (hasPermission("CREATE")) {
      setInnerPage("clone");
      setEditInvoiceOverview(false);
      setInvoiceInfo({
        ...singleInvoiceDetails.getinvoiceInfo,
        ...singleInvoiceDetails.invoiceItemsTotDtls,
        invoiceDate: singleInvoiceDetails.getinvoiceInfo.invoiceDate
          ?.split("-")
          .reverse()
          .join("-"),
        dueDate: singleInvoiceDetails.getinvoiceInfo.dueDate
          ?.split("-")
          .reverse()
          .join("-"),
      });
    } else {
      setShowAccessModal(true);
    }
  };

  const handleDeleteInvoice = () => {
    if (hasPermission("DELETE")) {
      setShowModalType("delete");
    } else {
      setShowAccessModal(true);
    }
  };

  const nodataPage = () => {
    return (
      <div className="nodatapage d-flex flex-column justify-content-center align-items-center bg-white px-2 py-5 my-3 mx-5 rounded-3">
        <img src={nodataInvoice} alt="img" height={200} width={200} />
        <h5 className="opacity-75">Manage Invoice</h5>
        <p className="opacity-75 p-0 m-0">
          Quotes are legal agreements between a customer and a vector
        </p>
        <p className="opacity-75 p-0 m-0">
          to deliver the requested product(s) at the agreed upon price
        </p>
        <p className="opacity-75 p-0 m-0">within the specified time period.</p>
        <button
          className="createContactButtonSave px-2 mt-4 cursorPointer rounded-5"
          style={{ width: "19.5%", height: "42.5px" }}
          onClick={handleCreateNoDataInvoice}
        >
          Create Invoice
        </button>
      </div>
    );
  };
  const reduxStore = useSelector((state) => state.reduxState);
  const { loginUserId } = reduxStore;
  const options = reduxStore.filterOptions.invoice;
  const [selectedOption, setSelectedOption] = useState(options[0]);
  const userList = JSON.parse(sessionStorage.getItem("userList"));

  const [selectedOwner, setSelectedOwner] = useState({
    name: "",
    userId: "",
  });
  const [selectedContact, setSelectedContact] = useState({
    contactName: fetchContactInfo?.contactName || "",
    contactId: "",
  });
  const [selectedDeal, setSelectedDeal] = useState({
    dealName: "",
    dealId: "",
  });

  const [selectedAccount, setSelectedAccount] = useState({
    accountName: "",
    accountId: "",
  });
  const [selectedSale, setSelectedSale] = useState({
    subject: "",
    salesOrdId: "",
  });
  const [timeLineDataList, setTimeLineDataList] = useState([]);
  const [listVisibleType, setListVisibleType] = useState("");
  const [selectedProduct, setSelectedProduct] = useState({
    productName: "",
    productId: "",
  });
  const [selectedProductIndex, setSelectedProductIndex] = useState("");
  const [invoiceId, setInvoiceId] = useState("");
  const [selectedInvoiceIndex, setSelectedInvoiceIndex] = useState(0);
  const [isLeadOwnerModal, setIsLeadOwnerModal] = useState(false);
  const [isDealListModal, setIsDealListModal] = useState(false);
  const [isContactListModal, setIsContactListModal] = useState(false);
  const [isAccountListModal, setIsAccountListModal] = useState(false);
  const [isSalesListModal, setIsSalesListModal] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [currentPageContact, setCurrentPageContact] = useState(1);
  const [currentPageAccount, setCurrentPageAccount] = useState(1);
  const [perPageAccount, setPerPageAccount] = useState(10);
  const [totalItems, setTotalItems] = useState(0); // Total number of items
  const [totalItemsContact, setTotalItemsContact] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [perPageContact, setPerPageContact] = useState(10);
  const [invoicePageView, setInvoicePageView] = useState("overview");
  const [editingFields, setEditingFields] = useState({
    contactOwner: false,
    leadSource: false,
    firstName: false,
    lastName: false,
    accountName: false,
    title: false,
    phone: false,
    mobile: false,
    emailId: false,
    fax: false,
    skypeId: false,
    secondaryEmailId: false,
    twitter: false,
    otherPhone: false,
    assistant: false,
    department: false,
    homePhone: false,
    dateOfBirth: false,
    assistantPhone: false,
    reportingTo: false,
    mailingCountry: false,
    mailingState: false,
    mailingCity: false,
    mailingStreet: false,
    mailingZip: false,
    otherCountry: false,
    otherState: false,
    otherCity: false,
    otherStreet: false,
    otherZip: false,
    description: false,
    subTotal: false,
    discount: false,
    discountType: false,
    discountValue: false,
    tax: false,
    adjustment: false,
    grandTotal: false,
  });
  const [showType, setShowType] = useState("");
  const fieldDropdowns = [
    {
      name: "Invoice Number",
      isShowed: true,
      type: "readOnly",
      value: "invoiceNumber",
    },
    {
      name: "Invoice Date",
      isShowed: true,
      type: "input",
    },
    {
      name: "Due Date",
      isShowed: true,
      type: "input",
    },
    {
      name: "Grand Total",
      isShowed: true,
      type: "readOnly",
    },
    {
      name: "Contact Name",
      isShowed: true,
      type: "select",
    },
    {
      name: "Subject",
      isShowed: true,
      type: "input",
    },
    {
      name: "Sales Commission",
      isShowed: false,
      type: "input",
      value: "salesCommission",
    },
    {
      name: "Account Name",
      isShowed: false,
      type: "select",
    },
    {
      name: "Deal Name",
      isShowed: false,
      type: "select",
    },
    {
      name: "Sales Order",
      isShowed: false,
      type: "select",
      value: "salesOrderId",
    },
    {
      name: "Invoice Owner",
      isShowed: false,
      type: "select",
    },
    {
      name: "Purchase Order",
      isShowed: false,
      type: "input",
    },
    {
      name: "Excise Duty",
      isShowed: false,
      type: "input",
    },
    {
      name: "Status",
      isShowed: false,
      type: "dropdown",
    },
    {
      name: "Created By",
      isShowed: false,
      type: "readOnly",
    },
    {
      name: "Modified By",
      isShowed: false,
      type: "readOnly",
    },
    {
      name: "Billing Street",
      isShowed: false,
      type: "input",
    },
    {
      name: "Billing State",
      isShowed: false,
      type: "input",
    },
    {
      name: "Billing City",
      isShowed: false,
      type: "input",
    },
    {
      name: "Billing Code",
      isShowed: false,
      type: "input",
    },
    {
      name: "Billing Country",
      isShowed: false,
      type: "input",
    },
    {
      name: "Shipping Street",
      isShowed: false,
      type: "input",
    },
    {
      name: "Shipping State",
      isShowed: false,
      type: "input",
    },
    {
      name: "Shipping City",
      isShowed: false,
      type: "input",
    },
    {
      name: "Shipping Code",
      isShowed: false,
      type: "input",
    },
    {
      name: "Shipping Country",
      isShowed: false,
      type: "input",
    },
  ];
  const fieldIndividualDropdowns = [
    {
      name: "Invoice Number",
      isShowed: true,
      type: "readOnly",
      value: "invoiceNumber",
    },
    {
      name: "Invoice Date",
      isShowed: true,
      type: "input",
      value: "invoiceDateInfo",
    },
    {
      name: "Due Date",
      isShowed: true,
      type: "input",
      value: "dueDateInfo",
    },
    {
      name: "Grand Total",
      isShowed: true,
      type: "readOnly",
    },
    {
      name: "Contact Name",
      isShowed: true,
      type: "select",
    },
    {
      name: "Subject",
      isShowed: true,
      type: "input",
      value: "subjectInfo",
    },
    {
      name: "Sales Commission",
      isShowed: false,
      type: "input",
      value: "salesCommissionInfo",
    },
    {
      name: "Account Name",
      isShowed: false,
      type: "select",
    },
    {
      name: "Deal Name",
      isShowed: false,
      type: "select",
    },
    {
      name: "Sales Order",
      isShowed: false,
      type: "select",
      value: "salesOrderId",
    },
    {
      name: "Invoice Owner",
      isShowed: false,
      type: "select",
    },
    {
      name: "Purchase Order",
      isShowed: false,
      type: "input",
      value: "purchaseOrderInfo",
    },
    {
      name: "Excise Duty",
      isShowed: false,
      type: "input",
      value: "exciseDutyInfo",
    },
    {
      name: "Status",
      isShowed: false,
      type: "dropdown",
      value: "statusInfo",
    },
    {
      name: "Created By",
      isShowed: false,
      type: "readOnly",
    },
    {
      name: "Modified By",
      isShowed: false,
      type: "readOnly",
    },
    {
      name: "Billing Street",
      isShowed: false,
      type: "input",
      value: "billingStreetInfo",
    },
    {
      name: "Billing State",
      isShowed: false,
      type: "input",
      value: "billingStateInfo",
    },
    {
      name: "Billing City",
      isShowed: false,
      type: "input",
      value: "billingCityInfo",
    },
    {
      name: "Billing Code",
      isShowed: false,
      type: "input",
      value: "billingCodeInfo",
    },
    {
      name: "Billing Country",
      isShowed: false,
      type: "input",
      value: "billingCountryInfo",
    },
    {
      name: "Shipping Street",
      isShowed: false,
      type: "input",
      value: "shippingStreetInfo",
    },
    {
      name: "Shipping State",
      isShowed: false,
      type: "input",
      value: "shippingStateInfo",
    },
    {
      name: "Shipping City",
      isShowed: false,
      type: "input",
      value: "shippingCityInfo",
    },
    {
      name: "Shipping Code",
      isShowed: false,
      type: "input",
      value: "shippingCodeInfo",
    },
    {
      name: "Shipping Country",
      isShowed: false,
      type: "input",
      value: "shippingCountryInfo",
    },
  ];
  const [fieldLabels, setFieldLabels] = useState(fieldDropdowns);
  const [showSearchFields, setShowSearchFields] = useState(fieldDropdowns);
  const [selectedField, setSelectedField] = useState("");
  const [searchField, setSearchField] = useState("");
  const [popoutType, setPopoutType] = useState("");
  const [discountType, setDiscountType] = useState("percentage");
  const [modalAccountDetails, setModalAccountDetail] = useState([]);
  const [modalContactDetails, setModalContactDetails] = useState([]);
  const [modalSalesOrderDetails, setModalSalesOrderDetails] = useState([]);
  const [modalDealDetails, setModalDealDetails] = useState([]);
  const [searchDeal, setSearchDeal] = useState("");
  const [searchContact, setSearchContact] = useState("");
  const [searchAccount, setSearchAccount] = useState("");
  const [searchSalesOrder, setSearchSalesOrder] = useState("");

  const handleSearchDeal = (e) => {
    const { value } = e.target;
    if (value.trim() !== "" || value === "") {
      setSearchDeal(value);
    }
  };

  const handleSearchContact = (e) => {
    const { value } = e.target;
    if (value.trim() !== "" || value === "") {
      setSearchContact(value);
    }
  };

  const handleSearchAccount = (e) => {
    const { value } = e.target;
    if (value.trim() !== "" || value === "") {
      setSearchAccount(value);
    }
  };

  const handleSearchSalesOrder = (e) => {
    const { value } = e.target;
    if (value.trim() !== "" || value === "") {
      setSearchSalesOrder(value);
    }
  };

  const filteredDeals = modalDealDetails.filter((item) =>
    item.dealName?.toLowerCase().includes(searchDeal?.toLowerCase())
  );

  const filteredContacts = modalContactDetails.filter((item) =>
    item.contactName?.toLowerCase().includes(searchContact?.toLowerCase())
  );

  const filteredAccount = modalAccountDetails.filter((item) =>
    item.accountName?.toLowerCase().includes(searchAccount?.toLowerCase())
  );

  const filteredSalesOrder = modalSalesOrderDetails.filter((item) =>
    item.subject?.toLowerCase().includes(searchSalesOrder?.toLowerCase())
  );
  const stausOptions = [
    { value: "Created", label: "Created" },
    { value: "Approved", label: "Approved" },
    { value: "Delivered", label: "Delivered" },
    { value: "Cancelled", label: "Cancelled" },
  ];
  const invoiceColumns = [
    {
      name: "Subject",
      isChecked: true,
      value: "subject",
    },
    {
      name: "Shipping City",
      isChecked: false,
      value: "shippingCity",
    },
    {
      name: "Shipping Code",
      isChecked: false,
      value: "shippingCode",
    },
    {
      name: "Grand Total",
      isChecked: true,
      value: "grandTotal",
    },
    {
      name: "Account Name",
      isChecked: true,
      value: "accountName",
    },
    {
      name: "Contact Name",
      isChecked: true,
      value: "contactName",
    },
    {
      name: "Invoice Number",
      isChecked: false,
      value: "invoiceNumber",
    },
    {
      name: "Shipping Street",
      isChecked: false,
      value: "shippingStreet",
    },
    {
      name: "Due Date",
      isChecked: false,
      value: "dueDate",
    },
    {
      name: "Sales Commision",
      isChecked: false,
      value: "salesCommission",
    },
    {
      name: "Billing State",
      isChecked: false,
      value: "billingState",
    },
    {
      name: "Invoice Date",
      isChecked: true,
      value: "invoiceDate",
    },
    {
      name: "Deal Name",
      isChecked: false,
      value: "dealName",
    },
    {
      name: "Billing Code",
      isChecked: false,
      value: "billingCode",
    },
    {
      name: "Invoice Owner",
      isChecked: false,
      value: "invoiceOwner",
    },
    {
      name: "Billing Street",
      isChecked: false,
      value: "billingStreet",
    },
    {
      name: "Shipping Country",
      isChecked: false,
      value: "shippingCountry",
    },
    {
      name: "Purchase Order",
      isChecked: false,
      value: "purchaseOrder",
    },
    {
      name: "Billing Country",
      isChecked: false,
      value: "billingCountry",
    },
    {
      name: "Shipping State",
      isChecked: false,
      value: "shippingState",
    },
    {
      name: "Excise Duty",
      isChecked: false,
      value: "exciseDuty",
    },
    {
      name: "Billing City",
      isChecked: false,
      value: "billingCity",
    },
    {
      name: "Status",
      isChecked: false,
      value: "status",
    },
    {
      name: "Hash",
      isChecked: true,
      value: "transHash",
    },
  ];
  const [selectedHeadings, setSelectedHeadings] = useState(invoiceColumns);
  const [savingHeadings, setSavingHeadings] = useState(invoiceColumns);
  const [showHeadings, setShowHeadings] = useState(false);
  const [modalType, setShowModalType] = useState("");
  const [searchColumn, setSearchColumn] = useState("");
  const [showSelectedFilter, setShowSelectedFilter] = useState("");
  const [filterType, setFilterType] = useState("");
  const [pinnedColumn, setPinnedColumn] = useState("Subject");
  const [allInvoices, setAllInvoices] = useState([]);
  const [editInvoiceOverview, setEditInvoiceOverview] = useState(false);
  const [filteredAllInvoices, setFilteredAllInvoices] = useState([]);
  const [showOptionsInvoice, setShowOptionsInvoice] = useState(false);
  const [shareModal, setShareModal] = useState(false);
  const [openActivities, setOpenActivities] = useState([]);
  const [openActivitiesCalls, setOpenActivitiesCalls] = useState([]);
  const [openActivitiesMeetings, setOpenActivitiesMeetings] = useState([]);
  const [closedActivitiesCall, setClosedActivitiesCall] = useState([]);
  const [closedActivitiesMeetings, setClosedActivitiesMeetings] = useState([]);
  const [closeActivities, setCloseActivities] = useState([]);
  const [allProducts, setAllProducts] = useState([
    {
      productId: "",
      productName: "-",
      quantity: 1,
      listPrice: "",
      amount: 1,
      discount: 0,
      tax: 0,
      total: 1,
      description: "",
      discountType: 1,
      discountValue: 0,
    },
  ]);
  const [singleInvoiceDetails, setSingleInvoiceDetails] = useState({
    quotesAccContDealData: {},
    assignCloseActivities: [],
    getinvoiceInfo: {},
    invoiceItems: [],
    invoiceItemsTotDtls: {},
    assignOpenActivities: [],
    productsList: [],
  });
  const {
    quotesAccContDealData,
    // getinvoiceInfo,
    invoiceItems,
    // invoiceItemsTotDtls,
  } = singleInvoiceDetails;

  const [invoiceInfo, setInvoiceInfo] = useState({
    dealId: "",
    salesOrderId: "",
    contactId: "",
    accountId: "",
    description: "",
    invoiceDate: new Date().toISOString().split("T")[0],
    invoiceId: "",
    invoiceOwnerId: selectedOwner.userId,
    purchaseOrder: "",
    salesCommission: "",
    subject: "",
    dueDate: "",
    exciseDuty: "",
    status: stausOptions[0].value,
    termsAndConditions: "",
    billingStreet: "",
    billingCity: "",
    billingState: "",
    billingCode: "",
    billingCountry: "",
    shippingStreet: "",
    shippingCity: "",
    shippingState: "",
    shippingCode: "",
    shippingCountry: "",
    loggedInUserId: loginUserId,
    purchaseItemDtlsDto: [],
    subTotal: 0,
    discount: 0,
    discountType: 1,
    discountValue: 0,
    tax: 0,
    adjustment: 0,
    grandTotal: 0,
    accountName: "",
    contactName: "",
    dealName: "",
    invoiceOwner: "",
  });

  const [statusDropdown, setStatusDropdown] = useState(stausOptions[0]);
  const [noteInfo, setNoteInfo] = useState([]);
  const [addTitle, setAddTitle] = useState("");
  const [editNote, setEditNote] = useState(false);
  const [note, setNote] = useState("");
  const [selectedNoteFiles, setSelectedNoteFiles] = useState([]);
  const [updateNotes, setUpdateNotes] = useState({
    description: "",
    title: "",
    noteImageList: [],
  });
  const [showTitleNote, setShowTitleNote] = useState(false);
  const [noteTitle, setNoteTitle] = useState("");
  const [showButtons, setShowButtons] = useState(false);
  const [selectRecent, setSelectRecent] = useState("ALL");
  const [subjectError, setSubjectError] = useState("");
  const [accNameError, setAccNameError] = useState("");
  const [shareUserList, setShareUserList] = useState([]);
  const [sharePublicList, setSharePublicList] = useState([]);
  const [shareRoleList, setShareRoleList] = useState([]);
  const [shareGroupList, setShareGroupList] = useState([]);
  const [shareInfoModal, setShareInfoModal] = useState(false);
  const [shareMemberIds, setShareMemberIds] = useState([]);
  const [totalItemsAccount, setTotalItemsAccount] = useState(0);
  const [deletedImg, setDeletedImg] = useState("");

  // for save and new && save lottie loader
  const [saveSubmit, setSaveSubmit] = useState(false);
  const [saveAndNewSubmit, setSaveAndNewSubmit] = useState(false);

  const totalPages = Math.ceil(Number(totalItems) / perPage);
  const totalPagesContact = Math.ceil(
    Number(totalItemsContact) / perPageContact
  );
  const totalPagesAccount = Math.ceil(
    Number(totalItemsAccount) / perPageAccount
  );

  const [currentPageDeal, setCurrentPageDeal] = useState(1);
  const [totalItemsDeal, setTotalItemsDeal] = useState(0);
  const [totalItemsSalesOrder, setTotalItemsSalesOrder] = useState(0);
  const [perPageDeal, setPerPageDeal] = useState(10);
  const [noteDeleteConfirmation, setNoteDeleteConfirmation] = useState(false);

  const totalPagesDeal = Math.ceil(Number(totalItemsDeal) / perPageDeal);

  const handlepageChangeDeal = (page) => {
    setCurrentPageDeal(page);
  };

  const handlePageDropDownChangeDeal = (selectedOption) => {
    setCurrentPageDeal(1);
    setPerPageDeal(parseInt(selectedOption.value));
  };

  const [currentPageSalesOrder, setCurrentPageSalesOrder] = useState(1);
  const [perPageSalesOrder, setPerPageSalesOrder] = useState(10);

  const totalPagesSalesOrder = Math.ceil(
    Number(totalItemsSalesOrder) / perPageSalesOrder
  );

  const handlePageDropDownChangeSalesOrder = (selectedOption) => {
    setCurrentPageSalesOrder(1);
    setPerPageSalesOrder(parseInt(selectedOption.value));
  };

  useEffect(() => {
    getAllDeal();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPageDeal, perPageDeal]);

  const minDate = moment("2000-01-01").format("YYYY-MM-DD");
  const maxDate = moment("2050-12-31").format("YYYY-MM-DD");

  const tdStyleObj = {
    backgroundColor: "white",
    border: "1px solid rgba(185, 185, 185, 0.5)",
  };

  const getAllDeal = async () => {
    const requestBody = {
      loggedInUserId: loginUserId,
      pageNo: currentPageDeal,
      pageSize: perPageDeal,
    };

    const headersResult = getHeaders(true);

    // Check if headers result indicates an error
    if (headersResult.error) {
      navigate("../"); // Redirect to login if token is missing
      return;
    }

    await axios
      .post(URI.getAllDeals, requestBody, {
        headers: headersResult,
      })
      .then((response) => {
        if (response.data.status === 200) {
          if (
            !pastViewInfo ||
            pastViewInfo?.view === "" ||
            pastViewInfo?.view === "salesOrders"
          ) {
            setDealList(response.data.data.allDealDetails);
          }
          setModalDealDetails(response.data.data.allDealDetails);
          setTotalItemsDeal(response.data.data.rowCount);
        } else {
          NotificationManager.error("Deals", response.data.message, 5000);
        }
      })
      .catch((err) => {
        handleApiError(err, navigate);
      });
  };

  const getAllSales = async () => {
    const requestBody = {
      loggedInUserId: loginUserId,
      pageNo: currentPageSalesOrder,
      pageSize: perPageSalesOrder,
    };

    const headersResult = getHeaders(true);

    // Check if headers result indicates an error
    if (headersResult.error) {
      navigate("../"); // Redirect to login if token is missing
      return;
    }

    await axios
      .post(URI.getAllSales, requestBody, {
        headers: headersResult,
      })
      .then((response) => {
        if (response.data.status === 200) {
          setSalesList(response.data.data.getAllSalesOrder);
          setModalSalesOrderDetails(response.data.data.getAllSalesOrder);
          setTotalItemsSalesOrder(response.data.data.rowCount);
          // setSelectedSale({
          //   subject: response.data.data.getAllSalesOrder[0]?.subject,
          //   salesOrdId: response.data.data.getAllSalesOrder[0]?.salesOrdId,
          // });
        } else {
          NotificationManager.error("Sales", response.data.message, 5000);
        }
      })
      .catch((err) => {
        handleApiError(err, navigate);
      });
  };

  const getAllContact = async () => {
    const requestBody = {
      loggedInUserId: loginUserId,
      pageNo: currentPageContact,
      pageSize: perPageContact,
    };

    const headersResult = getHeaders(true);

    // Check if headers result indicates an error
    if (headersResult.error) {
      navigate("../"); // Redirect to login if token is missing
      return;
    }

    await axios
      .post(URI.getAllContact, requestBody, {
        headers: headersResult,
      })
      .then((response) => {
        if (response.data.status === 200) {
          if (
            !pastViewInfo ||
            pastViewInfo?.view === "" ||
            pastViewInfo?.view === "salesOrders"
          ) {
            setContactList(response.data.data.allContactDetails);
          }
          setModalContactDetails(response.data.data.allContactDetails);
          setTotalItemsContact(response.data.data.rowCount);
          // if (response.data.data.allContactDetails.length > 0) {
          //   setSelectedContact({
          //     contactName: response.data.data.allContactDetails[0]?.contactName,
          //     contactId: response.data.data.allContactDetails[0]?.contactId,
          //   });
          // }
        } else {
          NotificationManager.error("Contacts", response.data.message, 5000);
        }
      })
      .catch((err) => {
        handleApiError(err, navigate);
      });
  };

  const getAllProduct = async () => {
    const requestBody = {
      loggedInUserId: loginUserId,
      pageNo: 1,
      pageSize: 20,
    };

    const headersResult = getHeaders(true);

    // Check if headers result indicates an error
    if (headersResult.error) {
      navigate("../"); // Redirect to login if token is missing
      return;
    }

    await axios
      .post(URI.getAllProduct, requestBody, {
        headers: headersResult,
      })
      .then((response) => {
        if (response.data.status === 200) {
          if (response.data.data.allProductDetails.length > 0) {
            setProducts(response.data.data.allProductDetails);
            setAllProducts([
              {
                description:
                  response.data.data.allProductDetails[0].description,
                productId: response.data.data.allProductDetails[0].productId,
                productName:
                  response.data.data.allProductDetails[0].productName,
                quantity:
                  response.data.data.allProductDetails[0].unitPrice || 1,
                listPrice:
                  response.data.data.allProductDetails[0].unitPrice || 0,
                amount:
                  response.data.data.allProductDetails[0].unitPrice *
                    response.data.data.allProductDetails[0].unitPrice || 0,
                discount: 0,
                tax:
                  parseFloat(response.data.data.allProductDetails[0].tax) || 0,
                total: isNaN(response.data.data.allProductDetails[0].total)
                  ? 0
                  : response.data.data.allProductDetails[0].total,
                discountType: 1,
                discountValue: 0,
              },
            ]);
          }
        } else {
          NotificationManager.error("Products", response.data.message, 5000);
        }
      })
      .catch(async (error) => {
        handleApiError(error, navigate);
      });
  };

  const getAllAccountDetails = async () => {
    const requestBody = {
      loggedInUserId: loginUserId,
      pageNo: currentPageAccount,
      pageSize: perPageAccount,
    };

    const headersResult = getHeaders(true);

    // Check if headers result indicates an error
    if (headersResult.error) {
      navigate("../"); // Redirect to login if token is missing
      return;
    }

    await axios
      .post(URI.getAllAccountDetails, requestBody, {
        headers: headersResult,
      })
      .then((response) => {
        if (response.data.status === 200) {
          if (
            !pastViewInfo ||
            pastViewInfo?.view === "" ||
            pastViewInfo?.view === "salesOrders"
          ) {
            setAccountList(response.data.data.allAccountDetails);
          }
          setModalAccountDetail(response.data.data.allAccountDetails);
          setTotalItemsAccount(response.data.data.rowCount);
          // setSelectedAccount({
          //   accountName: response.data.data.allAccountDetails[0]?.accountName,
          //   accountId: response.data.data.allAccountDetails[0]?.accountId,
          // });
        } else {
          NotificationManager.error("Accounts", response.data.message, 5000);
        }
      })
      .catch(async (error) => {
        handleApiError(error, navigate);
      });
  };

  const scrollToSection = (sectionId) => {
    setInvoicePageView("overview");
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: "smooth" });
    }
  };

  const handleChange = (option) => {
    if (selectedOption.value !== option.value) {
      setCurrentPage(1);
      getAllInvoices(1, option);
    }
  };

  const handleOwnerInputClick = () => {
    setListVisible(!listVisible);
  };

  const handlePageDropDowmChange = (selectedOption) => {
    setCurrentPage(1);
    setPerPage(parseInt(selectedOption.value));
  };

  const handlePageDropDownChangeContact = (selectedOption) => {
    setCurrentPageContact(1);
    setPerPageContact(parseInt(selectedOption.value));
  };

  const handlePageDropDownChangeAccount = (selectedOption) => {
    setCurrentPageAccount(1);
    setPerPageAccount(parseInt(selectedOption.value));
  };

  const handleChangeNoteTitle = (e) => {
    const { name, value } = e.target;
    if (value.length === 1 && value[0] === ' ') {
      return; 
    }
    if (editNote) {
      setUpdateNotes((prevLeadInfo) => ({
        ...prevLeadInfo,
        [name]: value,
      }));
    } else {
      setAddTitle(value);
    }
  };

  const handleChildDataChange = (dataFromChild) => {
    const deletedImg = dataFromChild.toString("");
    setDeletedImg(deletedImg);
  };

  const handleChangeNoteDescription = (e) => {
    const { name, value } = e.target;
    if (value.length === 1 && value[0] === ' ') {
      return; 
    }
    if (editNote) {
      setUpdateNotes((prevLeadInfo) => ({
        ...prevLeadInfo,
        [name]: value,
      }));
    } else {
      setNote(value); // Directly set the input value
    }
  };

  const insertNote = () => {
    const formData = new FormData();
    formData.append("moduleId", invoiceId);
    formData.append("loggedInUserId", loginUserId);
    formData.append("moduleName", "INVOICES");
    formData.append("noteDescription", note);
    formData.append("noteTitle", addTitle);

    selectedNoteFiles.forEach((file) => {
      formData.append("noteImages", file);
    });

    const headersResult = getHeaders(true);

    // Check if headers result indicates an error
    if (headersResult.error) {
      navigate("../"); // Redirect to login if token is missing
      return;
    }

    axios
      .post(URI.insertNote, formData, {
        headers: headersResult,
      })
      .then((response) => {
        if (response.data.status === 200) {
          setShowButtons(false);
          setAddTitle("");
          setNote("");
          setSelectedNoteFiles([]);
          getNotesDetails(invoiceId, selectRecent);
        } else {
          NotificationManager.error("", response.data.message, 5000);
        }
      })
      .catch((error) => {
        handleApiError(error, navigate);
      });
  };

  const updateNote = (noteId) => {
    const formData = new FormData();
    formData.append("moduleId", invoiceId);
    formData.append("loggedInUserId", loginUserId);
    formData.append("moduleName", "INVOICES");
    formData.append("noteDescription", updateNotes.description);
    formData.append("noteTitle", updateNotes.title);
    formData.append("noteId", noteId);
    formData.append("deletedImageNames", deletedImg.toString());

    selectedNoteFiles.forEach((file) => {
      formData.append("noteImages", file);
    });

    const headersResult = getHeaders(true);

    // Check if headers result indicates an error
    if (headersResult.error) {
      navigate("../"); // Redirect to login if token is missing
      return;
    }

    axios
      .post(URI.updateNote, formData, {
        headers: headersResult,
      })
      .then((response) => {
        if (response.data.status === 200) {
          setShowButtons(false);
          setEditNote(false);
          NotificationManager.success("", response.data.message, 5000);
          setUpdateNotes({
            description: "",
            title: "",
            noteImageList: [],
          });
          setSelectedNoteFiles([]);
          getNotesDetails(invoiceId, selectRecent);
          setDeletedImg("");
        } else {
          setDeletedImg("");
          setSelectedNoteFiles([]);
          NotificationManager.error("", response.data.message, 5000);
        }
      })
      .catch((error) => {
        handleApiError(error, navigate);
      });
  };

  const deleteNoteDetails = (id, noteId) => {
    const formData = new FormData();
    formData.append("moduleId", id);
    formData.append("loggedInUserId", loginUserId);
    formData.append("moduleName", "INVOICES");
    formData.append("noteId", noteId);

    const headersResult = getHeaders(true);

    // Check if headers result indicates an error
    if (headersResult.error) {
      navigate("../"); // Redirect to login if token is missing
      return;
    }

    axios
      .post(URI.deleteNote, formData, {
        headers: headersResult,
      })
      .then((response) => {
        if (response.data.status === 200) {
          NotificationManager.success("", response.data.message, 3000);
          getNotesDetails(id, selectRecent);
          setNoteDeleteConfirmation(false);
        } else {
          NotificationManager.error("", response.data.message, 5000);
        }
      })
      .catch((error) => {
        handleApiError(error, navigate);
      });
  };

  const clearSelectedItem = () => {
    // setDealInfoInvoice(null);

    listVisibleType === "contactName"
      ? setSelectedContact(contactList[0])
      : listVisibleType === "accountName"
      ? setSelectedAccount(accountList[0])
      : listVisibleType === "dealName"
      ? setSelectedDeal(dealList[0])
      : listVisibleType === "invoiceOwner"
      ? setSelectedOwner(userList[0])
      : listVisibleType === "productName"
      ? setSelectedProduct(products[0])
      : setSelectedSale({
          subject: "",
          salesOrdId: "",
        });
  };

  const createLeadStyles = {
    menu: (base) => ({
      ...base,
      width: "60%",
    }),
    option: (provided) => ({
      ...provided,
      // backgroundColor: "#EAEAEA",
      color: "#000",
      // backgroundColor: 'rgba(33, 119, 254, 0.1)',
      width: "100%",
    }),
    control: (baseStyles) => ({
      ...baseStyles,
      border: "1px solid #b9b9b9b0",
      backgroundColor: "#F3F6FB",
      height: "40px",
      borderRadius: "4px",
      width: "60%",
      boxShadow: "none",
    }),
    input: (base) => ({
      ...base,
      color: "#000",
    }),
    singleValue: (defaultStyles) => ({ ...defaultStyles, color: "#000" }),

    menuList: (provided) => ({
      ...provided,
      paddingTop: 0,
      paddingBottom: 0,
      "&::-webkit-scrollbar": {
        width: 5,
      },

      "&::-webkit-scrollbar-thumb": {
        background: "#316AFF",
        borderRadius: "10px",
      },
    }),
  };

  const customSelectLocationStyles = {
    menu: (base) => ({
      ...base,
      width: "100%",
    }),
    option: (provided, state) => ({
      ...provided,
      color: "#000",
      backgroundColor: state.isSelected ? "#407BFF" : "",
      width: "100%",
      cursor: "pointer",
      "&:hover": {
        backgroundColor: "#f5f5f5",
      },
    }),
    control: (baseStyles) => ({
      ...baseStyles,
      border: "2px solid #316AFF",
      backgroundColor: "#fff",
      height: "40px",
      borderRadius: "10px",
      width: "100%",
      boxShadow: "none",
      cursor: "pointer",
    }),
    input: (base) => ({
      ...base,
      color: "#FFFFFF",
    }),
    singleValue: (defaultStyles) => ({ ...defaultStyles, color: "#000" }),

    menuList: (provided) => ({
      ...provided,
      paddingTop: 0,
      paddingBottom: 0,
      "&::-webkit-scrollbar": {
        width: 5,
      },

      "&::-webkit-scrollbar-thumb": {
        background: "#00EEFD",
        borderRadius: "10px",
      },
    }),
    dropdownIndicator: (base) => ({
      ...base,
      color: "#316AFF", // Custom colour
    }),
  };

  const fieldDropdownStyles = {
    menu: (base) => ({
      ...base,
      width: "100%",
    }),
    option: (provided) => ({
      ...provided,
      color: "#000",
      width: "100%",
    }),
    control: (baseStyles) => ({
      ...baseStyles,
      border: 0,
      height: "20px",
      borderRadius: "10px",
      // width: "75%",
      boxShadow: "none",
    }),
    input: (base) => ({
      ...base,
      color: "#FFFFFF",
    }),
    singleValue: (defaultStyles) => ({ ...defaultStyles, color: "#000" }),

    menuList: (provided) => ({
      ...provided,
      paddingTop: 0,
      paddingBottom: 0,
      "&::-webkit-scrollbar": {
        width: 5,
      },

      "&::-webkit-scrollbar-thumb": {
        background: "#316AFF",
        borderRadius: "10px",
      },
    }),
  };

  const handleTextFileChange = (event) => {
    const files = Array.from(event.target.files);
    if (files) {
      setSelectedNoteFiles(files);
    } else {
      setSelectedNoteFiles([]);
    }
  };

  const handleSelectRecent = (item) => {
    setSelectRecent(item);
    var value = "";
    if (item === "Recent Last") {
      value = "RECENT_LAST";
    } else if (item === "Recent First") {
      value = "RECENT_FIRST";
    } else {
      value = item;
    }
    getNotesDetails(invoiceId, value);
  };

  const handleAddRow = () => {
    const { unitPrice, tax, productId, productName, description } = products[0];

    const discountedAmount = parseFloat(unitPrice) * parseFloat(unitPrice) - 0;
    const taxAmount = (parseFloat(tax || 0) / 100) * discountedAmount;
    const total = (discountedAmount + taxAmount).toFixed(2) || 0;
    setAllProducts([
      ...allProducts,
      {
        // slNo: 1,
        productId: productId,
        description: description,
        productName: productName,
        quantity: unitPrice || 1,
        listPrice: unitPrice || 0,
        amount: unitPrice * unitPrice || 0,
        discount: 0,
        tax: parseFloat(tax) || 0,
        total: isNaN(total) ? 0 : total,
        // total: unitPrice * unitPrice,
        discountType: 1,
        discountValue: 0,
      },
    ]);

    const productsTotal = allProducts
      .reduce(
        (sum, product) =>
          parseFloat(sum) +
          parseFloat(isNaN(product.total) ? 0 : product.total),
        0
      )
      ?.toFixed(2);

    const subTotalValue =
      Number(productsTotal) + Number(isNaN(total) ? 0 : total);

    setInvoiceInfo((info) => ({
      ...info,
      subTotal: subTotalValue,
      grandTotal: parseFloat(
        subTotalValue - Number(invoiceInfo.discount || 0)
      ).toFixed(2),
    }));
  };

  const handleDeleteRow = (index) => {
    if (allProducts.length === 1) {
      return; // Prevent deleting the last row
    }
    const newRows = [...allProducts];
    newRows.splice(index, 1);
    setAllProducts(newRows);

    const subTotalValue = newRows.reduce(
      (sum, product) => parseFloat(sum) + parseFloat(product.total),
      0
    );
    setInvoiceInfo((prevPurchaseInfo) => ({
      ...prevPurchaseInfo,
      subTotal: subTotalValue || 0,
      grandTotal: parseFloat(
        subTotalValue - parseFloat(invoiceInfo.discount || 0)
      ).toFixed(2),
    }));
  };

  const handleChangeSelectDropDown = (selectedOption, field) => {
    setInvoiceInfo((prevLeadInfo) => ({
      ...prevLeadInfo,
      [field]: selectedOption,
    }));
  };

  const handleUpdateClick = async (field) => {
    if (
      (field === "dueDateInfo" &&
        (invoiceInfo["dueDateInfo"] < invoiceInfo["invoiceDateInfo"] ||
          invoiceInfo["dueDateInfo"] < invoiceInfo["invoiceDate"])) ||
      (field === "dueDate" &&
        (invoiceInfo["dueDate"] < invoiceInfo["invoiceDateInfo"] ||
          invoiceInfo["dueDate"] < invoiceInfo["invoiceDate"]))
    ) {
      NotificationManager.error("Empty", "Note can not be empty.", 5000);
      getInvoiceOverview(invoiceId);
      return;
    }
    const value =
      field === "status" || field === "statusInfo"
        ? invoiceInfo[field].value
        : invoiceInfo[field];
    // const isDate = !isNaN(Date.parse(value));
    const finalValue = [
      "salesCommission",
      "exciseDuty",
      "salesCommissionInfo",
      "exciseDutyInfo",
    ].includes(field)
      ? parseFloat(value)
      : value;

    const fieldMappings = {
      billingCityInfo: "billingCity",
      billingStateInfo: "billingState",
      billingCodeInfo: "billingCode",
      billingStreetInfo: "billingStreet",
      billingCountryInfo: "billingCountry",
      shippingStreetInfo: "shippingStreet",
      shippingCityInfo: "shippingCity",
      shippingStateInfo: "shippingState",
      shippingCodeInfo: "shippingCode",
      shippingCountryInfo: "shippingCountry",
      statusInfo: "status",
      subjectInfo: "subject",
      dueDateInfo: "dueDate",
      invoiceDateInfo: "invoiceDate",
      purchaseOrderInfo: "purchaseOrder",
      exciseDutyInfo: "exciseDuty",
      salesCommissionInfo: "salesCommission",
    };
    // if (fieldMappings.hasOwnProperty(field)) {
    //   field = fieldMappings[field];
    // } not supported by eslint
    if (Object.hasOwn(fieldMappings, field)) {
      field = fieldMappings[field];
    }

    try {
      const requestBody = {
        invoiceId: invoiceId,
        loggedInUserId: loginUserId,
      };
      if (["salesCommission", "exciseDuty"].includes(field)) {
        requestBody[field] = finalValue;
      } else {
        // requestBody[field] = isDate
        //   ? value.split("-").reverse().join("-")
        //   : value;
        requestBody[field] = value;
      }

      if (
        value !== singleInvoiceDetails.getinvoiceInfo[field] ||
        field === "emailNotification"
      ) {
        const headersResult = getHeaders(true);

        // Check if headers result indicates an error
        if (headersResult.error) {
          navigate("../"); // Redirect to login if token is missing
          return;
        }

        const response = await axios.post(URI.updateInvoice, requestBody, {
          headers: headersResult,
        });

        if (response.data.status === 200) {
          NotificationManager.success("", response.data.message, 5000);
          setInvoiceInfo((prevDetails) => ({
            ...prevDetails,
            [field]: singleInvoiceDetails.getinvoiceInfo[field],
          }));

          getInvoiceOverview(invoiceId);
          getNotesDetails(invoiceId, selectRecent);
        } else {
          NotificationManager.error("", response.data.message, 5000);
        }
      }

      const resetFields = Object.fromEntries(
        Object.keys(editingFields).map((key) => [key, false])
      );
      setEditingFields({
        ...resetFields,
        [field]: false,
      });
      setShowType("");
    } catch (error) {
      handleApiError(error, navigate);
    }
  };

  const handleUpdateIdClick = async (field, value) => {
    const fieldName =
      field === "contactName"
        ? "contactId"
        : field === "accountName"
        ? "accountId"
        : field === "dealName"
        ? "dealId"
        : field === "invoiceOwner"
        ? "invoiceOwnerId"
        : "salesOrderId";

    try {
      const requestBody = {
        invoiceId: invoiceId,
        [fieldName]:
          field === "contactName"
            ? value.contactId
            : field === "accountName"
            ? value.accountId
            : field === "dealName"
            ? value.dealId
            : field === "invoiceOwner"
            ? value.userId
            : value.salesOrderId,
        loggedInUserId: loginUserId,
      };

      const headersResult = getHeaders(true);

      // Check if headers result indicates an error
      if (headersResult.error) {
        navigate("../"); // Redirect to login if token is missing
        return;
      }

      const response = await axios.post(URI.updateInvoice, requestBody, {
        headers: headersResult,
      });
      if (response.data.status === 200) {
        NotificationManager.success("", response.data.message, 4000);
        setInvoiceInfo((prevDetails) => ({
          ...prevDetails,
          [field]: singleInvoiceDetails.getinvoiceInfo[field],
        }));
        const resetFields = Object.fromEntries(
          Object.keys(editingFields).map((key) => [key, false])
        );
        setEditingFields({
          ...resetFields,
          [field]: false,
        });
        getInvoiceOverview(invoiceId);
        getNotesDetails(invoiceId, selectRecent);
      } else {
        NotificationManager.error("", response.data.message, 4000);
      }
    } catch (error) {
      handleApiError(error, navigate);
    }
  };

  const handleIndividualInputChange = (field, value) => {
    setInvoiceInfo((prevFields) => ({
      ...prevFields,
      [field]: value,
    }));
  };

  const handleEditClick = (field) => {
    if (Object.values(editingFields).some((value) => value)) {
      return;
    }
    setEditingFields((prevFields) => ({
      ...prevFields,
      [field]: true,
    }));
  };

  const handleCancelClick = (field) => {
    setEditingFields((prevFields) => ({
      ...prevFields,
      [field]: false,
    }));

    setInvoiceInfo((prevLeadInfo) => ({
      ...prevLeadInfo,
      [field]:
        field === "status"
          ? {
              value: singleInvoiceDetails.getinvoiceInfo[field],
              label: singleInvoiceDetails.getinvoiceInfo[field],
            }
          : singleInvoiceDetails.getinvoiceInfo[field] || "",
    }));
  };

  const createInvoiceStyles = {
    menu: (base) => ({
      ...base,
      width: "60%",
    }),
    option: (provided) => ({
      ...provided,
      color: "#000",
      width: "100%",
    }),
    control: (baseStyles) => ({
      ...baseStyles,
      backgroundColor: "transparent",
      height: "40px",
      borderRadius: "4px",
      width: "60%",
      boxShadow: "none",
      border: "1px solid #b9b9b9b",
    }),
    input: (base) => ({
      ...base,
      color: "#FFFFFF",
    }),
    singleValue: (defaultStyles) => ({ ...defaultStyles, color: "#000" }),

    menuList: (provided) => ({
      ...provided,
      paddingTop: 0,
      paddingBottom: 0,
      "&::-webkit-scrollbar": {
        width: 5,
      },

      "&::-webkit-scrollbar-thumb": {
        background: "#316AFF",
        borderRadius: "10px",
      },
    }),
  };

  const handleRowChange = (index, e) => {
    const { name, value } = e.target;
    // praseFloat removes all special characters include - and +

    let discountedAmount;
    let taxAmount;
    const newRows = [...allProducts];
    if (name === "quantity") {
      const getValue = value.replace(/[^0-9+\-.]+/g, "");
      newRows[index].quantity = getValue;
      const useValue = getValue === "" ? 0 : getValue;
      discountedAmount =
        useValue * parseFloat(newRows[index].listPrice) -
        parseFloat(newRows[index].discount);
      taxAmount =
        (parseFloat(newRows[index].tax || 0) / 100) * discountedAmount;

      newRows[index].amount =
        (useValue * parseFloat(newRows[index].listPrice)).toFixed(2) || 0;
      newRows[index].total =
        (discountedAmount + taxAmount).toFixed(2) || "0.00";
    } else if (name === "listPrice") {
      const getValue = value.replace(/[^0-9+\-.]+/g, "");
      newRows[index].listPrice = getValue;
      const useValue = getValue === "" ? 0 : getValue;
      discountedAmount =
        useValue * parseFloat(newRows[index].quantity) -
        parseFloat(newRows[index].discount);
      taxAmount =
        (parseFloat(newRows[index].tax || 0) / 100) * discountedAmount;

      newRows[index].amount = (
        useValue * parseFloat(newRows[index].quantity)
      ).toFixed(2);

      newRows[index].total =
        (discountedAmount + taxAmount).toFixed(2) || "0.00";
    } else if (name === "description") {
      newRows[index][name] = value;
    }
    setAllProducts(newRows);

    const subTotalValue =
      newRows.reduce(
        (sum, product) => parseFloat(sum) + parseFloat(product.total),
        0
      ) || 0;

    setInvoiceInfo((info) => ({
      ...info,
      subTotal: parseFloat(subTotalValue).toFixed(2) || 0,
      grandTotal: parseFloat(
        subTotalValue - parseFloat(invoiceInfo.discount || 0)
      ).toFixed(2),
    }));
  };

  const getAllAssignModules = async (main, sub, id) => {
    // if (main === "DEALS") {
    //   let contactList = [];
    //   let accountList = [];
    // } else if (main === "CONTACTS") {
    //   let accountList = [];
    //   let dealList = [];
    // } else if (main === "ACCOUNTS") {
    //   let dealList = [];
    //   let contactList = [];
    // }
    const requestBody = {
      loginUserId: loginUserId,
      mainModule: main,
      subModule: sub,
      moduleId: id,
    };

    const headersResult = getHeaders(true);

    // Check if headers result indicates an error
    if (headersResult.error) {
      navigate("../"); // Redirect to login if token is missing
      return;
    }

    await axios
      .post(URI.moduleWiseAssign, requestBody, {
        headers: headersResult,
      })
      .then((response) => {
        if (response.data.status === 200) {
          if (main === "DEALS") {
            for (const element of response.data.data.contactsDtlsList) {
              const modifiedResponse = {
                contactName: "",
                contactId: "",
              };

              modifiedResponse["contactName"] = element.moduleName;
              modifiedResponse["contactId"] = element.moduleId;
              contactList.push(modifiedResponse);
            }
            if (response.data.data.contactsDtlsList.length > 0) {
              setSelectedContact({
                contactName: response.data.data.contactsDtlsList[0]?.moduleName,
                contactId: response.data.data.contactsDtlsList[0]?.moduleId,
              });
            }
            setContactList(contactList);
            for (const element of response.data.data.accountsDtlsList) {
              const modifiedResponse = {
                accountName: "",
                accountId: "",
              };

              modifiedResponse["accountName"] = element.moduleName;
              modifiedResponse["accountId"] = element.moduleId;
              accountList.push(modifiedResponse);
            }
            if (response.data.data.accountsDtlsList.length > 0) {
              setSelectedAccount({
                accountName: response.data.data.accountsDtlsList[0]?.moduleName,
                accountId: response.data.data.accountsDtlsList[0]?.moduleId,
              });
            }
            setAccountList(accountList);
          } else if (main === "CONTACTS") {
            for (const element of response.data.data.dealsDtlsList) {
              const modifiedResponse = {
                dealName: "",
                dealId: "",
              };

              modifiedResponse["dealName"] = element.moduleName;
              modifiedResponse["dealId"] = element.moduleId;
              dealList.push(modifiedResponse);
            }

            if (response.data.data.dealsDtlsList.length > 0) {
              setSelectedDeal({
                dealName: response.data.data.dealsDtlsList[0]?.moduleName,
                dealId: response.data.data.dealsDtlsList[0]?.moduleId,
              });
            }
            setDealList(dealList);
            for (const element of response.data.data.accountsDtlsList) {
              const modifiedResponse = {
                accountName: "",
                accountId: "",
              };

              modifiedResponse["accountName"] = element.moduleName;
              modifiedResponse["accountId"] = element.moduleId;
              accountList.push(modifiedResponse);
            }

            if (response.data.data.accountsDtlsList.length > 0) {
              setSelectedAccount({
                accountName: response.data.data.accountsDtlsList[0]?.moduleName,
                accountId: response.data.data.accountsDtlsList[0]?.moduleId,
              });
            }
            setAccountList(accountList);
          } else if (main === "ACCOUNTS") {
            for (const element of response.data.data.dealsDtlsList) {
              const modifiedResponse = {
                dealName: "",
                dealId: "",
              };

              modifiedResponse["dealName"] = element.moduleName;
              modifiedResponse["dealId"] = element.moduleId;
              dealList.push(modifiedResponse);
            }
            if (response.data.data.dealsDtlsList.length > 0) {
              setSelectedDeal({
                dealName: response.data.data.dealsDtlsList[0]?.moduleName,
                dealId: response.data.data.dealsDtlsList[0]?.moduleId,
              });
            }
            setDealList(dealList);
            for (const element of response.data.data.contactsDtlsList) {
              const modifiedResponse = {
                contactName: "",
                contactId: "",
              };

              modifiedResponse["contactName"] = element.moduleName;
              modifiedResponse["contactId"] = element.moduleId;
              contactList.push(modifiedResponse);
            }
            if (response.data.data.contactsDtlsList.length > 0) {
              setSelectedContact({
                contactName: response.data.data.contactsDtlsList[0]?.moduleName,
                contactId: response.data.data.contactsDtlsList[0]?.moduleId,
              });
            }
            setContactList(contactList);
          }
        }
      })
      .catch(async (error) => {
        handleApiError(error, navigate);
      });
  };

  useEffect(() => {
    if (pastViewInfo) {
      if (pastViewInfo.view === "contacts") {
        const main = pastViewInfo.view.toUpperCase();
        const sub = ["ACCOUNTS", "DEALS"];
        const id = fetchContactInfo?.contactId;
        getAllAssignModules(main, sub, id);
        setSelectedContact({
          contactName: fetchContactInfo?.contactName,
          contactId: id,
        });
        return;
      } else if (pastViewInfo.view === "accounts") {
        const main = pastViewInfo.view.toUpperCase();
        const sub = ["CONTACTS", "DEALS"];
        const id = fetchAccInfo?.accountId;
        getAllAssignModules(main, sub, id);
        setSelectedAccount({
          accountName: fetchAccInfo?.accountName,
          accountId: id,
        });
        return;
      } else if (pastViewInfo.view === "deals") {
        const main = pastViewInfo.view.toUpperCase();
        const sub = ["CONTACTS", "ACCOUNTS"];
        const id = fetchDealInfo?.dealId;
        getAllAssignModules(main, sub, id);
        setSelectedDeal({
          dealName: fetchDealInfo?.dealName,
          dealId: id,
        });
      }
    }
  }, []);

  const handleUserItemClick = (field, item, i) => {
    setListVisibleType(field);
    field === "contactName"
      ? setSelectedContact(item)
      : field === "accountName"
      ? setSelectedAccount(item)
      : field === "dealName"
      ? setSelectedDeal(item)
      : field === "invoiceOwner"
      ? setSelectedOwner(item)
      : field === "productName"
      ? setSelectedProduct(item)
      : setSelectedSale(item);

    if (!["create", "clone"].includes(innerPage)) {
      handleUpdateIdClick(field, item);
    }
    if (field === "productName") {
      const newRows = [...allProducts];
      newRows[i] = {
        ...newRows[i],
        productId: item.productId,
        productName: item.productName,
        quantity: item.unitPrice || 1,
        listPrice: item.unitPrice,
        amount: item.unitPrice * item.unitPrice,
        discount: 0,
        tax: parseFloat(item.tax || 0),
        total: returnTotalValue(item),
        discountType: discountType === "percentage" ? 1 : 2,
        discountValue: 0,
      };
      setAllProducts(newRows);
      const subTotalValue = newRows.reduce(
        (sum, product) => parseFloat(sum) + parseFloat(product.total),
        0
      );
      setInvoiceInfo((invInfo) => ({
        ...invInfo,
        subTotal: parseFloat(subTotalValue).toFixed(2) || 0,
        grandTotal:
          parseFloat(subTotalValue).toFixed(2) -
          parseFloat(invoiceInfo.discount || 0),
      }));
    }
    if (field === "dealName") {
      getAllAssignModules("DEALS", ["CONTACTS", "ACCOUNTS"], item.dealId); // Adjust the parameters as needed
    } else if (field === "contactName") {
      getAllAssignModules("CONTACTS", ["DEALS", "ACCOUNTS"], item.contactId);
    } else if (field === "accountName") {
      getAllAssignModules("ACCOUNTS", ["DEALS", "CONTACTS"], item.accountId);
    } else if (field === "salesOrderId") {
      setInvoiceInfo((prevPurchaseInfo) => ({
        ...prevPurchaseInfo,
        subject: item.subject,
      }));
    }
  };
  /* eslint-disable no-useless-escape */
  const sanitizeExpression = (expression) => {
    const match = expression.match(/^\s*-?\d+(\.\d+)?([+\-*\/]-?\d+(\.\d+)?)*/);

    if (match) {
      return match[0].trim();
    } else {
      return "Invalid input";
    }
  };
  /* eslint-enable no-useless-escape */

  const handleBlur = (field) => {
    calculateResult(field);
    hideAllTooltips();
  };
  /* eslint-disable no-useless-escape */
  const handleKeyPress = (event) => {
    const allowedCharacters = /[0-9.\+\-\*\/\(\)]/;
    const char = event.key;
    if (!allowedCharacters.test(char)) {
      event.preventDefault();
    }
  };
  /* eslint-enable no-useless-escape */

  const hideAllTooltips = () => {
    setShowTooltipExciseDuty(false);
    setShowTooltipSalesCommission(false);
  };

  const tooltipStyles = {
    position: "absolute",
    top: "100%",
    left: "0",
    backgroundColor: "#f9f9f9",
    border: "1px solid #d3d3d3",
    padding: "5px",
    borderRadius: "3px",
    boxShadow: "0 2px 5px rgba(0,0,0,0.2)",
    zIndex: 1000,
    height: "fit-content",
    width: "fit-content",
  };
  /* eslint-disable no-useless-escape */

  const calculateResult = (field) => {
    const value = invoiceInfo[field].trim();
    const sanitizedValue = value;
    const regex = /^[\d.\+\-\*\/\(\)]+$/;

    if (regex.test(sanitizedValue)) {
      try {
        const cleanedValue = removeLeadingZeros(sanitizedValue);
        let result = eval(cleanedValue);
        if (typeof result === "number" && result % 1 !== 0) {
          const decimalPart = result.toString().split(".")[1];
          if (decimalPart && decimalPart.length > 2) {
            result = parseFloat(result).toFixed(2);
          }
        }
        setInvoiceInfo((prevData) => ({
          ...prevData,
          [field]: result.toString(),
        }));
      } catch (error) {
        setInvoiceInfo((prevData) => ({
          ...prevData,
          [field]: sanitizeExpression(value),
        }));
      }
    }
  };

  /* eslint-disable no-useless-escape */
  const handleTooltip = (value, setShowTooltip) => {
    const regex = /^[\d.\+\-\*\/\(\)]+$/;
    if (regex.test(value)) {
      try {
        const sanitizedValue = removeLeadingZeros(value);
        const previewResult = eval(sanitizedValue);
        setTooltipContent(previewResult.toString());
      } catch (error) {
        setTooltipContent(sanitizeExpression(value));
      }
      setShowTooltip(true);
    } else {
      setShowTooltip(false);
    }
  };

  const handleChangeInputs = (e) => {
    const { name, value } = e.target;

    if (/^[a-zA-Z0-9]/.test(value)) {
      setInvoiceInfo((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    } else {
      setInvoiceInfo((prevState) => ({
        ...prevState,
        [name]: "",
      }));
    }

    switch (name) {
      case "exciseDuty":
        handleTooltip(value, setShowTooltipExciseDuty);
        break;
      case "salesCommission":
        handleTooltip(value, setShowTooltipSalesCommission);
        break;

      default:
        break;
    }
  };

  const sortedColumnsData = (type, headingName) => {
    const sortedData = [...allInvoices];

    if (type === "asc") {
      sortedData.sort((a, b) => {
        if (
          typeof a[headingName] === "string" &&
          typeof b[headingName] === "string"
        ) {
          return a[headingName].localeCompare(b[headingName]);
        }
        return a[headingName] - b[headingName];
      });
    } else if (type === "desc") {
      sortedData.sort((a, b) => {
        if (
          typeof a[headingName] === "string" &&
          typeof b[headingName] === "string"
        ) {
          return b[headingName].localeCompare(a[headingName]);
        }
        return b[headingName] - a[headingName];
      });
    }
    setFilteredAllInvoices(sortedData);
  };

  const handleColumnCheckboxChange = (name, isChecked) => {
    setSavingHeadings((prevHeadings) =>
      prevHeadings.map((heading) =>
        heading.name === name ? { ...heading, isChecked } : heading
      )
    );
  };

  const handlePinColumn = (name) => {
    setPinnedColumn(name);
    const updatedColumns = [...selectedHeadings];
    const clickedColumnIndex = updatedColumns.findIndex(
      (column) => column.name === name
    );

    if (clickedColumnIndex !== -1) {
      const clickedColumn = updatedColumns.splice(clickedColumnIndex, 1)[0];
      updatedColumns.unshift(clickedColumn);
      setSelectedHeadings(updatedColumns);
    }
  };

  const getAllInvoices = async (pageNo, selectedOption) => {
    setIsLoading(true);
    setSelectedOption(selectedOption);
    const requestBody = {
      loggedInUserId: loginUserId,
      customFilter: selectedOption.value,
      pageNo: pageNo,
      pageSize: perPage,
    };

    const headersResult = getHeaders(true);

    // Check if headers result indicates an error
    if (headersResult.error) {
      navigate("../"); // Redirect to login if token is missing
      return;
    }

    await axios
      .post(URI.getAllInvoices, requestBody, {
        headers: headersResult,
      })

      .then((response) => {
        if (response.data.status === 200) {
          setAllInvoices(response.data.data.allInvoiceDetails);
          setFilteredAllInvoices(response.data.data.allInvoiceDetails);
          setTotalItems(response.data.data.rowCount);
          setIsLoading(false);
        } else {
          setAllInvoices([]);
          setFilteredAllInvoices([]);
          setIsLoading(false);
        }
      })
      .catch(async (error) => {
        handleApiError(error, navigate);
      });
  };

  const handleSaveButton = async (e) => {
    let missingFields = false;

    if (invoiceInfo.subject === "") {
      setSubjectError("Subject cannot be empty.");
      setTimeout(() => {
        setSubjectError("");
      }, 4000);
      missingFields = true;
    }
    if (invoiceInfo.subject && !validateCallSubject(invoiceInfo.subject)) {
      setSubjectError("Please enter a valid subject.");
      setTimeout(() => {
        setSubjectError("");
      }, 4000);
      missingFields = true;
    }
    if (
      selectedAccount.accountName === undefined ||
      selectedAccount.accountName === ""
    ) {
      setAccNameError("Account Name cannot be empty.");
      setTimeout(() => {
        setAccNameError("");
      }, 4000);
      missingFields = true;
    }

    if (
      invoiceInfo.billingCountry &&
      !validateCountryName(invoiceInfo.billingCountry)
    ) {
      setBillingCountryError("Please enter a valid billing country name.");
      setTimeout(() => {
        setBillingCountryError("");
      }, 4000);
      missingFields = true;
    }

    if (
      invoiceInfo.billingState &&
      !validateStateIndia(invoiceInfo.billingState)
    ) {
      setBillingStateError("Please enter a valid billing state name.");
      setTimeout(() => {
        setBillingStateError("");
      }, 4000);
      missingFields = true;
    }

    if (
      invoiceInfo.billingCity &&
      !validateCityIndia(invoiceInfo.billingCity)
    ) {
      setBillingCityError("Please enter a valid billing city name.");
      setTimeout(() => {
        setBillingCityError("");
      }, 4000);
      missingFields = true;
    }

    if (
      invoiceInfo.shippingCountry &&
      !validateCountryName(invoiceInfo.shippingCountry)
    ) {
      setShippingCountryError("Please enter a valid shipping country name.");
      setTimeout(() => {
        setShippingCountryError("");
      }, 4000);
      missingFields = true;
    }

    if (
      invoiceInfo.shippingState &&
      !validateStateIndia(invoiceInfo.shippingState)
    ) {
      setShippingStateError("Please enter a valid shipping state name.");
      setTimeout(() => {
        setShippingStateError("");
      }, 4000);
      missingFields = true;
    }

    if (
      invoiceInfo.shippingCity &&
      !validateCityIndia(invoiceInfo.shippingCity)
    ) {
      setShippingCityError("Please enter a valid shipping city name.");
      setTimeout(() => {
        setShippingCityError("");
      }, 4000);
      missingFields = true;
    }

    if (missingFields) {
      return;
    } else {
      const {
        subject,
        invoiceDate,
        dueDate,
        salesCommission,
        purchaseOrder,
        exciseDuty,
        termsAndConditions,
        description,
        billingStreet,
        billingCity,
        billingState,
        billingCode,
        billingCountry,
        shippingStreet,
        shippingCity,
        shippingState,
        shippingCode,
        shippingCountry,
      } = invoiceInfo;
      e === "SAVE AND NEW" ? setSaveAndNewSubmit(true) : setSaveSubmit(true);
      const requestBody = {
        invoiceOwnerId: selectedOwner.userId,
        subject: removeExtraSpaces(subject),
        invoiceDate: invoiceDate.split("-").reverse().join("-"),
        dueDate: dueDate.split("-").reverse().join("-"),
        salesCommission: parseFloat(salesCommission),
        dealId: selectedDeal.dealId || null,
        salesOrderId: selectedSale.salesOrdId || null,
        contactId: selectedContact.contactId || null,
        accountId: selectedAccount.accountId || null,
        purchaseOrder: removeExtraSpaces(purchaseOrder),
        exciseDuty: parseFloat(exciseDuty),
        termsAndConditions,
        description: removeExtraSpaces(description),
        billingStreet: removeExtraSpaces(billingStreet),
        billingCity: removeExtraSpaces(billingCity),
        billingState: removeExtraSpaces(billingState),
        billingCode: removeExtraSpaces(billingCode),
        billingCountry: removeExtraSpaces(billingCountry),
        shippingStreet: removeExtraSpaces(shippingStreet),
        shippingCity: removeExtraSpaces(shippingCity),
        shippingState: removeExtraSpaces(shippingState),
        shippingCode: removeExtraSpaces(shippingCode),
        shippingCountry: removeExtraSpaces(shippingCountry),
        loggedInUserId: loginUserId,
        purchaseItemDtlsDto: allProducts,
        subTotal: parseFloat(invoiceInfo.subTotal),
        discount: parseFloat(invoiceInfo.discount),
        discountType: parseFloat(invoiceInfo.discountType),
        discountValue: parseFloat(invoiceInfo.discountValue),
        tax: parseFloat(invoiceInfo.tax),
        adjustment: parseFloat(invoiceInfo.adjustment),
        grandTotal: parseFloat(invoiceInfo.grandTotal),
        status: statusDropdown.value || stausOptions[0].value,
      };

      if (editInvoiceOverview) {
        const deleteItemDtlsDto = [];
        const allProductIds = allProducts.map(
          (product) => product.invoiceOrderItemsId
        );

        invoiceItems.forEach((item) => {
          if (!allProductIds.includes(item.invoiceOrderItemsId)) {
            deleteItemDtlsDto.push({
              invoiceOrderItemsId: item.invoiceOrderItemsId,
            });
          }
        });
        // if (deleteItemDtlsDto.length > 0) {
        requestBody.deleteItemDtlsDto = deleteItemDtlsDto;
        // }
        requestBody.invoiceId = invoiceId;
      }

      const headersResult = getHeaders(true);

      // Check if headers result indicates an error
      if (headersResult.error) {
        navigate("../"); // Redirect to login if token is missing
        return;
      }

      const url = editInvoiceOverview
        ? URI.updateAllInvoice
        : URI.insertInvoice;
      await axios
        .post(url, requestBody, {
          headers: headersResult,
        })
        .then((response) => {
          if (response.data.status === 200) {
            NotificationManager.success("", response.data.message, 5000);
            const discountedAmount =
              products[0].unitPrice * products[0].unitPrice - 0;
            const taxAmount =
              (parseFloat(products[0].tax || 0) / 100) * discountedAmount;
            getAllInvoices(1, selectedOption);
            setSingleInvoiceDetails({
              quotesAccContDealData: {},
              assignCloseActivities: [],
              getinvoiceInfo: {},
              invoiceItems: [],
              invoiceItemsTotDtls: {},
              assignOpenActivities: [],
              productsList: [],
            });
            setAllProducts([
              {
                productId: products[0].productId,
                description: products[0].description,
                productName: products[0].productName,
                quantity: products[0].unitPrice,
                listPrice: products[0].unitPrice,
                amount: products[0].unitPrice * products[0].unitPrice,
                discount: 0,
                tax: parseFloat(products[0].tax || 0),
                total: (discountedAmount + taxAmount).toFixed(2),
                // total: products[0].unitPrice * products[0].unitPrice,
                discountType: 1,
                discountValue: 0,
              },
            ]);
            if (
              pastViewInfo &&
              pastViewInfo?.view !== "" &&
              pastViewInfo?.innerPage !== ""
            ) {
              setCurrentView(pastViewInfo.view);
              setInnerPage(pastViewInfo.innerPage);
              setPastViewInfo((prev) => ({
                ...prev,
                view: "",
                innerPage: "",
                undo: currentView, // it will set the current view of the page so when we cancel or save it will go to same section in the page
              }));
              return;
            }
            if (e === "SAVE") {
              setInnerPage("list");
            }
            setInvoiceInfo({
              dealId: "",
              salesOrderId: "",
              contactId: "",
              accountId: "",
              description: "",
              invoiceDate: new Date().toISOString().split("T")[0],
              invoiceId: "",
              invoiceOwnerId: selectedOwner.userId,
              purchaseOrder: "",
              salesCommission: "",
              subject: "",
              dueDate: "",
              exciseDuty: "",
              status: stausOptions[0],
              termsAndConditions: "",
              billingStreet: "",
              billingCity: "",
              billingState: "",
              billingCode: "",
              billingCountry: "",
              shippingStreet: "",
              shippingCity: "",
              shippingState: "",
              shippingCode: "",
              shippingCountry: "",
              loggedInUserId: loginUserId,
              purchaseItemDtlsDto: [],
              subTotal: (discountedAmount + taxAmount).toFixed(2),
              discountType: 1,
              discountValue: 0,
              discount: 0,
              tax: 0,
              adjustment: 0,
              grandTotal: (discountedAmount + taxAmount).toFixed(2),
            });
            if (isAccountListModal) {
              setSelectedAccount({
                moduleName: accountList[0]?.moduleName,
                moduleId: accountList[0]?.moduleId,
              });
            }
            setSelectedAccount({
              accountName: accountList[0]?.accountName,
              accountId: accountList[0]?.accountId,
            });
          } else {
            NotificationManager.error("", response.data.message, 4000);
          }
        })
        .catch(function (error) {
          handleApiError(error, navigate);
        })
        .finally(() => {
          setSaveAndNewSubmit(false);
          setSaveSubmit(false);
        });
    }
  };

  const returnTotalValue = (item) => {
    const discountedAmount = item.unitPrice * item.unitPrice - 0;
    const taxAmount =
      (parseFloat(isNaN(item.tax) ? 0 : item.tax) / 100) * discountedAmount;
    return (discountedAmount + taxAmount).toFixed(2);
  };

  const getNotesDetails = async (id, orderType) => {
    const requestBody = {
      loggedInUserId: loginUserId,
      moduleId: id,
      orderType: orderType,
    };

    const headersResult = getHeaders(true);

    // Check if headers result indicates an error
    if (headersResult.error) {
      navigate("../"); // Redirect to login if token is missing
      return;
    }

    await axios
      .post(URI.getNote, requestBody, {
        headers: headersResult,
      })
      .then((response) => {
        if (response.data.status === 200) {
          setNoteInfo(response.data.data.noteInfo);
        } else {
          NotificationManager.error("", response.data.message, 5000);
        }
      })
      .catch(async (error) => {
        handleApiError(error, navigate);
      });
  };

  const getAllInfoShare = async (invoiceId) => {
    setIsLoading(true);
    const requestBody = {
      moduleId: invoiceId,
      loggedInUserId: loginUserId,
    };

    const headersResult = getHeaders(true);

    // Check if headers result indicates an error
    if (headersResult.error) {
      navigate("../"); // Redirect to login if token is missing
      return;
    }

    await axios
      .post(URI.getShareInfo, requestBody, {
        headers: headersResult,
      })
      .then((response) => {
        if (response.data.status === 200) {
          const hasSharedItems =
            (response.data.data.getSharingInfoUsers &&
              response.data.data.getSharingInfoUsers.length > 0) ||
            (response.data.data.getSharingInfoRoles &&
              response.data.data.getSharingInfoRoles.length > 0) ||
            (response.data.data.getSharingInfoGroups &&
              response.data.data.getSharingInfoGroups.length > 0);

          const userShareMemberIds = response.data.data.getSharingInfoUsers.map(
            (user) => user.shareModuleId
          );
          const roleShareMemberIds = response.data.data.getSharingInfoRoles.map(
            (role) => role.shareModuleId
          );
          const groupShareMemberIds =
            response.data.data.getSharingInfoGroups.map(
              (group) => group.shareModuleId
            );
          const groupPublicSharedMemberIds =
            response.data.data.getSharingInfoPublic.map(
              (user) => user.shareModuleId
            );
          setShareMemberIds([
            ...userShareMemberIds,
            ...roleShareMemberIds,
            ...groupShareMemberIds,
            ...groupPublicSharedMemberIds,
          ]);

          setShareUserList(response.data.data.getSharingInfoUsers);
          setShareRoleList(response.data.data.getSharingInfoRoles);
          setShareGroupList(response.data.data.getSharingInfoGroups);
          setSharePublicList(response.data.data.getSharingInfoPublic);
          if (hasSharedItems) {
            setShareInfoModal(true);
          } else {
            setShareModal(true);
          }
          setIsLoading(false);
        }
      })
      .catch((err) => {
        handleApiError(err, navigate);
      });
  };

  const getInvoiceOverview = async (invoiceId) => {
    const headersResult = getHeaders(true);

    // Check if headers result indicates an error
    if (headersResult.error) {
      navigate("../"); // Redirect to login if token is missing
      return;
    }

    await axios
      .get(URI.invoiceOverview + loginUserId + "/" + invoiceId, {
        headers: headersResult,
      })

      .then((response) => {
        if (response.data.status === 200) {
          setInnerPage("overview");
          setSingleInvoiceDetails(response.data.data);
          setOpenActivities(response.data.data.assignOpenActivities);
          setOpenActivitiesCalls(response.data.data.openActivitiesCalls);
          setOpenActivitiesMeetings(response.data.data.openActivitiesMeetings);
          setClosedActivitiesCall(response.data.data.closedActivitiesCalls);
          setClosedActivitiesMeetings(
            response.data.data.closedActivitiesMeetings
          );
          setCloseActivities(response.data.data.assignCloseActivities);
          const invoiceOwnerObj = userList.find(
            (item) =>
              item.name === response.data.data.getinvoiceInfo.invoiceOwner
          );
          const contactNameObj = contactList.find(
            (item) =>
              item.contactId ===
              response.data.data.quotesAccContDealData.contactId
          );
          const dealNameObj = dealList.find(
            (item) =>
              item.dealId === response.data.data.quotesAccContDealData.dealId
          );
          const saleOrderObj = salesList.find(
            (item) =>
              item.salesOrdId ===
              response.data.data.getinvoiceInfo?.salesOrderId
          );
          const accountNameObj = accountList.find(
            (item) =>
              item.accountId ===
              response.data.data.quotesAccContDealData.accountId
          );
          invoiceOwnerObj !== undefined && setSelectedOwner(invoiceOwnerObj);
          contactNameObj !== undefined && setSelectedContact(contactNameObj);
          dealNameObj !== undefined && setSelectedDeal(dealNameObj);
          accountNameObj !== undefined && setSelectedAccount(accountNameObj);
          saleOrderObj !== undefined && setSelectedSale(saleOrderObj);

          setInvoiceInfo({
            ...response.data.data.getinvoiceInfo,
            status: {
              label: response.data.data.getinvoiceInfo.status,
              value: response.data.data.getinvoiceInfo.status,
            },
            accountName: invoiceOwnerObj?.accountName,
            contactName: contactNameObj?.contactName,
            billingStreetInfo: response.data.data.getinvoiceInfo.billingStreet,
            billingCityInfo: response.data.data.getinvoiceInfo.billingCity,
            billingStateInfo: response.data.data.getinvoiceInfo.billingState,
            billingCodeInfo: response.data.data.getinvoiceInfo.billingCode,
            billingCountryInfo:
              response.data.data.getinvoiceInfo.billingCountry,
            shippingStreetInfo:
              response.data.data.getinvoiceInfo.shippingStreet,
            shippingCityInfo: response.data.data.getinvoiceInfo.shippingCity,
            shippingStateInfo: response.data.data.getinvoiceInfo.shippingState,
            shippingCodeInfo: response.data.data.getinvoiceInfo.shippingCode,
            shippingCountryInfo:
              response.data.data.getinvoiceInfo.shippingCountry,
            dueDateInfo: response.data.data.getinvoiceInfo.dueDate,
            invoiceDateInfo: response.data.data.getinvoiceInfo.invoiceDate,
            statusInfo: {
              label: response.data.data.getinvoiceInfo.status,
              value: response.data.data.getinvoiceInfo.status,
            },
            subjectInfo: response.data.data.getinvoiceInfo.subject,
            purchaseOrderInfo: response.data.data.getinvoiceInfo.purchaseOrder,
            exciseDutyInfo: response.data.data.getinvoiceInfo.exciseDuty,
            salesCommissionInfo:
              response.data.data.getinvoiceInfo.salesCommission,
            dealName: dealNameObj?.dealName,
            invoiceOwner: invoiceOwnerObj?.name,
            salesOrderId: saleOrderObj?.salesOrderId,
            ...response.data.data.invoiceItemsTotDtls,
          });
          setStatusDropdown({
            label: response.data.data.getinvoiceInfo.status,
            value: response.data.data.getinvoiceInfo.status,
          });
          setAllProducts(response.data.data.invoiceItems);
        } else {
          setSingleInvoiceDetails({
            quotesAccContDealData: {},
            assignCloseActivities: [],
            getinvoiceInfo: {},
            invoiceItems: [],
            invoiceItemsTotDtls: {},
            assignOpenActivities: [],
            productsList: [],
          });
          NotificationManager.error("", response.data.message, 4000);
        }
      })
      .catch(async (error) => {
        handleApiError(error, navigate);
      });
  };

  const getAllTimeline = async () => {
    const headersResult = getHeaders(true);

    // Check if headers result indicates an error
    if (headersResult.error) {
      navigate("../"); // Redirect to login if token is missing
      return;
    }

    await axios
      .get(URI.getInvoiceTimeline + loginUserId + "/" + invoiceId, {
        headers: headersResult,
      })

      .then((response) => {
        if (response.data.status === 200) {
          setTimeLineDataList(response.data.data.getInvoiceTimeline);
          setInvoicePageView("timeline");
        } else {
          NotificationManager.error("", response.data.message, 4000);
        }
      })
      .catch(async (error) => {
        handleApiError(error, navigate);
      });
  };

  const deleteInvoice = async () => {
    const requestBody = {
      loggedInUserId: loginUserId,
      invoiceId: invoiceId,
    };

    const headersResult = getHeaders(true);

    // Check if headers result indicates an error
    if (headersResult.error) {
      navigate("../"); // Redirect to login if token is missing
      return;
    }

    await axios
      .post(URI.deleteInvoice, requestBody, {
        headers: headersResult,
      })
      .then((response) => {
        if (response.data.status === 200) {
          NotificationManager.success("", response.data.message, 5000);
          getAllInvoices(currentPage, selectedOption);
          setShowModalType("");
          setInnerPage("list");
        } else {
          NotificationManager.error("", response.data.message, 5000);
          setShowModalType("");
        }
      })
      .catch(function (error) {
        handleApiError(error, navigate);
      });
  };

  useEffect(() => {
    const foundItem =
      userList && userList.find((item) => item.userId === loginUserId);
    if (foundItem) {
      setSelectedOwner(foundItem);
    }
  }, [loginUserId]);

  useEffect(() => {
    getAllInvoices(currentPage, selectedOption);
  }, [currentPage, perPage]);

  React.useEffect(() => {
    getAllDeal();
    getAllProduct();
    getAllAccountDetails();
    setInnerPage(innerPage || "list");
  }, []);

  useEffect(() => {
    getAllContact();
  }, [currentPageContact, perPageContact]);

  useEffect(() => {
    getAllSales();
  }, [currentPageSalesOrder, perPageSalesOrder]);

  useEffect(() => {
    getAllAccountDetails();
  }, [currentPageAccount, perPageAccount]);

  const [discountObj, setDiscountObj] = useState({
    type: 1,
    value: 0,
  });
  const [selectedTax, setSelectedTax] = useState(taxOptions[0]);
  const [isShrinked, setIsShrinked] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const [massUpdateValue, setMassUpdateValue] = useState("");
  const [selectedObj, setSelectedObj] = useState({
    name: "",
    isChecked: false,
    value: "",
  });
  const [selectedCheckboxes, setSelectedCheckboxes] = useState([]);
  const [listVisible, setListVisible] = useState(false);
  const [clickedDropdownObj, setClickedDropdownObj] = useState({
    label: "",
    value: "",
  });

  const handleSelectCheckboxChange = (event) => {
    const { value } = event.target;

    const selectedCheckbox = { moduleId: value };

    setSelectedCheckboxes((prevSelectedCheckboxes) => {
      const isAlreadySelected = prevSelectedCheckboxes.some(
        (checkbox) => checkbox.moduleId === value
      );

      if (isAlreadySelected) {
        return prevSelectedCheckboxes.filter(
          (checkbox) => checkbox.moduleId !== value
        );
      } else {
        return [...prevSelectedCheckboxes, selectedCheckbox];
      }
    });
  };

  const deleteUpdateTransferMass = async () => {
    // const { value } = selectedObj;
    // const { value } = selectedObj;
    const value = selectedObj.name
      ?.replace(/\s+/g, "")
      .replace(/^(.)/, (match) => match.toLowerCase());

    let url = URI.massDelete;
    const requestBody = {
      loggedInUserId: loginUserId,
      moduleName: "INVOICES",
      massAction: modalType === "Mass Delete" ? "DELETE" : "EDIT",
      moduleIdList: selectedCheckboxes,
    };
    if (modalType === "Mass Update") {
      if (value === "subject" && massUpdateValue.length === 0) {
        setErrorMessage("Value can not be empty");
        setTimeout(() => {
          setErrorMessage("");
        }, 3000);
        return;
      }
      requestBody[value] = ["Due Date", "Invoice Date"].includes(
        selectedObj.name
      )
        ? massUpdateValue.split("-").reverse().join("-")
        : massUpdateValue || null;
      url = URI.massUpdate;
    } else if (modalType === "Mass Transfer") {
      requestBody.ownerId = selectedOwner.userId;
      url = URI.massTransfer;
    }

    await axios
      .post(url, requestBody, {
        headers: getHeaders(true),
      })
      .then((response) => {
        if (response.data.status === 200) {
          NotificationManager.success("", response.data.message, 5000);
          getAllInvoices(currentPage, selectedOption);
          setSelectedCheckboxes([]);
          setInnerPage("list");
          setShowModalType("");
          setShowSearchFields(fieldDropdowns);
          setMassUpdateValue("");
        } else {
          NotificationManager.error("", response.data.message, 5000);
        }
      })
      .catch(function (error) {
        handleApiError(error, navigate);
      });
  };

  const discountPopup = (index) => {
    return (
      <div
        className="position-absolute w-100 popout_box rounded-1 p-3 bg-white"
        style={{ left: selectedProductIndex === "" && "-4rem" }}
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <h6 className="fw-bold">
          {popoutType === "tax" ? "Select Tax" : "Set Discount"}
        </h6>
        {popoutType === "discount" ? (
          <>
            <div className="d-flex align-items-center my-3">
              <input
                type="radio"
                id="percentage"
                name="discount"
                value="percentage"
                className="cursorPointer"
                onChange={(e) => {
                  setDiscountType(e.target.checked ? "percentage" : "");
                  setDiscountObj({
                    type: 1,
                    value: 0,
                  });
                }}
                checked={discountType === "percentage"}
              />
              <label className="ms-1">Percentage</label>
              {discountType === "percentage" ? (
                <div className="w-50 d-flex">
                  <input
                    type="text"
                    className="w-75 ms-2 me-1"
                    name="percentage"
                    maxLength={3}
                    onChange={(e) => {
                      setDiscountObj({
                        type: 1,
                        value: e.target.value
                          .replace(/[^0-9.]/g, "")
                          .replace(/(\..*)\./g, "$1"),
                      });
                    }}
                    value={discountObj.value}
                  />
                  <span>%</span>
                </div>
              ) : null}
            </div>
            <div className="d-flex align-items-center my-2">
              <input
                type="radio"
                id="price"
                name="discount"
                value="price"
                className="cursorPointer"
                onChange={(e) => {
                  setDiscountType(e.target.checked ? "price" : "");
                  setDiscountObj({
                    type: 2,
                    value: 0,
                  });
                }}
                checked={discountType === "price"}
              />
              <label className="ms-1">Direct Price Reduction</label>
              {discountType === "price" ? (
                <input
                  type="text"
                  className="w-25 ms-2"
                  name="percentage"
                  value={discountObj.value}
                  onChange={(e) => {
                    setDiscountObj({
                      type: 2,
                      value: e.target.value
                        .replace(/[^0-9.]/g, "")
                        .replace(/(\..*)\./g, "$1"),
                    });
                  }}
                />
              ) : null}
            </div>
          </>
        ) : (
          <div className="my-3">
            <Select
              value={selectedTax}
              menuPlacement="auto"
              onChange={(selectedOption) => {
                setSelectedTax(selectedOption);
              }}
              isSearchable={false}
              options={taxOptions}
              styles={createLeadStyles}
            />
          </div>
        )}

        <div className="pt-2 d-flex gap-3">
          <button
            className="btn btn-primary px-3 py-1 opacity-75"
            onClick={() => {
              const newRows = [...allProducts];
              let discountedAmount;
              let taxAmount;

              if (selectedProductIndex === "") {
                if (popoutType === "tax") {
                  const total = invoiceInfo.subTotal - invoiceInfo.discount;
                  const taxValue = (selectedTax.value / 100) * total;
                  setInvoiceInfo((prevPurchaseInfo) => ({
                    ...prevPurchaseInfo,
                    tax: selectedTax.value,
                    grandTotal: (
                      parseFloat(total) +
                      parseFloat(taxValue || 0) +
                      parseFloat(invoiceInfo.adjustment)
                    ).toFixed(2),
                  }));
                } else {
                  const discount =
                    discountObj.type === 1
                      ? (discountObj.value / 100) *
                        parseFloat(invoiceInfo.subTotal)
                      : discountObj.value;
                  const total = invoiceInfo.subTotal - discount;
                  const taxValue = (parseFloat(invoiceInfo.tax) / 100) * total;

                  setInvoiceInfo((prevPurchaseInfo) => ({
                    ...prevPurchaseInfo,
                    discount: parseFloat(discount).toFixed(2),
                    discountType: discountObj.type,
                    discountValue: parseFloat(discountObj.value).toFixed(2),
                    grandTotal: (
                      parseFloat(total) +
                      parseFloat(taxValue || 0) +
                      parseFloat(invoiceInfo.adjustment)
                    ).toFixed(2),
                  }));
                }
              } else {
                const discount =
                  discountObj.type === 1
                    ? (discountObj.value / 100) *
                      parseFloat(newRows[index].amount)
                    : discountObj.value;
                discountedAmount =
                  parseFloat(newRows[index].amount || 0) - discount;
                taxAmount =
                  (parseFloat(newRows[index].tax || 0) / 100) *
                  discountedAmount;
                newRows[index].total = (discountedAmount + taxAmount).toFixed(
                  2
                );
                newRows[index].discountType = discountObj.type;
                newRows[index].discountValue = discountObj.value;
                newRows[index].discount = discount;

                setAllProducts(newRows);
                const subTotalValue = newRows.reduce(
                  (sum, product) => parseFloat(sum) + parseFloat(product.total),
                  0
                );
                setInvoiceInfo((prevPurchaseInfo) => ({
                  ...prevPurchaseInfo,
                  subTotal: subTotalValue,
                  grandTotal:
                    subTotalValue - parseFloat(invoiceInfo.discount || 0),
                }));
              }
              setSelectedProductIndex("");
              setPopoutType("");
            }}
          >
            Done
          </button>
          <button
            className="btn btn-light border"
            onClick={() => {
              setSelectedProductIndex("");
              setPopoutType("");
            }}
          >
            Cancel
          </button>
        </div>
      </div>
    );
  };

  const accountsDropdown = (topGap, tableIndex) => {
    const list =
      listVisibleType === "invoiceOwner"
        ? userList
        : listVisibleType === "contactName"
        ? contactList
        : listVisibleType === "dealName"
        ? dealList
        : listVisibleType === "accountName"
        ? accountList
        : listVisibleType === "productName"
        ? products
        : salesList;

    if (listVisibleType !== "" && list.length > 0) {
      return (
        <ul
          className="data-list list-group  lead_ownerList_container position-absolute bg-white"
          style={{
            top: topGap,
            width:
              topGap === "2rem" ? "112%" : topGap === "1.8rem" ? "100%" : "",
          }}
        >
          {list.map((item, index) => (
            <li
              key={index}
              className="cursorPointer w-100 px-2 border-bottom my-1"
              onClick={() =>
                handleUserItemClick(listVisibleType, item, tableIndex)
              }
            >
              <div className="d-flex flex-column">
                <span style={{ fontWeight: "500" }}>
                  {listVisibleType === "invoiceOwner"
                    ? item?.name
                    : listVisibleType === "contactName"
                    ? item?.contactName
                    : listVisibleType === "accountName"
                    ? item?.accountName
                    : listVisibleType === "dealName"
                    ? item?.dealName
                    : listVisibleType === "productName"
                    ? item.productName
                    : item.subject}
                </span>
                <span className="small" style={{ color: "#999999" }}>
                  {item.emailId}{" "}
                </span>
              </div>
            </li>
          ))}
        </ul>
      );
    }
  };

  const invoiceHomePage = () => {
    return (
      <>
        {filteredAllInvoices &&
        filteredAllInvoices.length === 0 &&
        selectedOption.value === "all_invoices" ? (
          <>{nodataPage()}</>
        ) : (
          <>
            <div className="creteLeadBar">
              {selectedCheckboxes.length > 0 ? (
                <div className="d-flex w-50 align-items-center gap-2">
                  <Select
                    options={massOptions}
                    styles={customCommonStyles("100%")}
                    value={{
                      value: "Action",
                      label: "Action",
                    }}
                    onChange={(option) => {
                      if (option.value === "Mass Update") {
                        setShowSearchFields(invoiceColumns);
                        setSelectedObj({
                          name: "Subject",
                          isShowed: true,
                          type: "input",
                        });
                      } else if (option.value === "Mass Transfer") {
                        setSelectedOwner({
                          name: userList[0].name,
                          id: userList[0].userId,
                        });
                      }
                      setMassUpdateValue("");
                      setShowModalType(option.value);
                    }}
                    className="w-25"
                  />
                </div>
              ) : (
                <div className="d-flex w-50 align-items-center gap-2">
                  {/* <img
                        src={filterView ? filterIcon : filterIconCross}
                        height={25}
                        className="leadFilter"
                        alt="img"
                      /> */}
                  <Select
                    options={options}
                    styles={customCommonStyles("190px")}
                    value={selectedOption}
                    onChange={handleChange}
                    isSearchable={false}
                  />
                </div>
              )}
              <div className="d-flex w-50 justify-content-end gap-2">
                <div className="btn-group">
                  {selectedCheckboxes.length <= 0 && (
                    <button
                      className="createContactButtonSave py-2"
                      onClick={handleCreateInvoice}
                    >
                      Create Invoice
                    </button>
                  )}
                  {/* <button
                    type="button"
                    className="btn btn-sm  dropdown-toggle dropdown-toggle-split py-2"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                    style={{
                      background:
                        "linear-gradient(86.07deg, #407BFF 0%, #12A7FE 100%)",
                      color: "#fff",
                    }}
                  >
                    <span className="visually-hidden"></span>
                  </button> */}
                  {/* <ul className="dropdown-menu">
                    <li>
                      <a className="dropdown-item ">Import Invoice</a>
                    </li>
                    <li>
                      <a className="dropdown-item ">Import Notes</a>
                    </li>
                  </ul> */}
                </div>
              </div>
            </div>
            <div className="totalRecordBarContact">
              <div className="d-flex w-50">
                <h6>
                  {" "}
                  {selectedCheckboxes.length > 0
                    ? `${selectedCheckboxes.length} Records Selected`
                    : `Total Records : ${totalItems}`}{" "}
                </h6>
              </div>
              <div className="d-flex w-50 align-items-center justify-content-end gap-2">
                {selectedCheckboxes.length === 0 ? (
                  filteredAllInvoices.length !== 0 && (
                    <Select
                      options={pageOption}
                      styles={customCommonStyles("100%")}
                      isSearchable={false}
                      value={pageOption.find(
                        (option) => option.value === perPage.toString()
                      )}
                      onChange={handlePageDropDowmChange}
                    />
                  )
                ) : (
                  <></>
                )}

                {filteredAllInvoices.length !== 0 && (
                  <Pagination
                    currentPage={currentPage}
                    totalPages={totalPages}
                    onPageChange={(page) => {
                      setCurrentPage(page);
                    }}
                  />
                )}
              </div>
            </div>
            <div className="leadMain">
              {/* {filterView ? (
                <div className="filterLead">
                  <h6 style={{ borderBottom: "2px solid #316AFF" }}>
                    Filter by Sales
                  </h6>
                  <input
                    type="text"
                    placeholder="Search"
                    className="filterSearch"
                  />
                  <div className="checkboxItem">
                    <h6 style={{ color: "#515151" }}>
                      ▾ System Defined Filters
                    </h6>
                    <input
                      type="checkbox"
                      id="vehicle1"
                      name="vehicle1"
                      value="Bike"
                    />
                    <label htmlFor="vehicle1"> Touched Record</label>
                    <br />
                    <input
                      type="checkbox"
                      id="vehicle1"
                      name="vehicle1"
                      value="Bike"
                    />
                    <label htmlFor="vehicle1"> Untouched Record</label>
                    <br />
                    <input
                      type="checkbox"
                      id="vehicle1"
                      name="vehicle1"
                      value="Bike"
                    />
                    <label htmlFor="vehicle1"> Record Action</label>
                    <br />
                    <h6 style={{ color: "#515151", marginTop: "10px" }}>
                      ▾ Filter By Fields
                    </h6>
                    <input
                      type="checkbox"
                      id="vehicle1"
                      name="vehicle1"
                      value="Bike"
                    />
                    <label htmlFor="vehicle1"> Annual Revenue</label>
                    <br />
                    <input
                      type="checkbox"
                      id="vehicle1"
                      name="vehicle1"
                      value="Bike"
                    />
                    <label htmlFor="vehicle1"> City</label>
                    <br />
                    <input
                      type="checkbox"
                      id="vehicle1"
                      name="vehicle1"
                      value="Bike"
                    />
                    <label htmlFor="vehicle1"> Company</label>
                    <br />
                  </div>
                </div>
              ) : (
                <></>
              )} */}
              <div
                className={
                  filterIcon ? "leadListTableFullWidth" : "leadListTable"
                }
              >
                <table className="table leadTable">
                  <thead>
                    <tr>
                      <th scope="col">
                        <input
                          className="cursorPointer"
                          type="checkbox"
                          id="vehicle1"
                          name="vehicle1"
                          value="All"
                          checked={filteredAllInvoices.every((selected) =>
                            selectedCheckboxes.some(
                              (list) => list.moduleId === selected.invoiceId
                            )
                          )}
                          onChange={(e) => {
                            const allList = filteredAllInvoices.map((item) => ({
                              moduleId: item.invoiceId,
                            }));
                            if (e.target.checked) {
                              const newSelections = allList.filter(
                                (newItem) =>
                                  !selectedCheckboxes.some(
                                    (existingItem) =>
                                      existingItem.moduleId === newItem.moduleId
                                  )
                              );
                              setSelectedCheckboxes((prev) => [
                                ...prev,
                                ...newSelections,
                              ]);
                            } else {
                              setSelectedCheckboxes(
                                selectedCheckboxes.filter(
                                  (aItem) =>
                                    !allList.some(
                                      (bItem) =>
                                        aItem.moduleId === bItem.moduleId
                                    )
                                )
                              );
                            }
                          }}
                          // checked={
                          //   selectedCheckboxes.length ===
                          //   filteredAllInvoices.length
                          // }
                          // onChange={(e) => {
                          //   if (e.target.checked) {
                          //     const allList = filteredAllInvoices.map(
                          //       (item) => ({
                          //         moduleId: item.invoiceId,
                          //       })
                          //     );
                          //     setSelectedCheckboxes(allList);
                          //   } else {
                          //     setSelectedCheckboxes([]);
                          //   }
                          // }}
                        />
                      </th>
                      {selectedHeadings.map(
                        (heading) =>
                          heading.isChecked && (
                            <th scope="col" key={heading.name}>
                              <span className="pe-3">{heading.name}</span>
                              <span className="position-relative">
                                <img
                                  src={tableFilterIcon}
                                  alt=""
                                  className="cursorPointer"
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    setShowHeadings(false);
                                    setShowSelectedFilter(heading.name);
                                  }}
                                />
                                {heading.name === showSelectedFilter && (
                                  <span
                                    className="position-absolute rounded-2 border bg-light top-0 mt-4"
                                    style={{ right: "-10%" }}
                                  >
                                    <span className="d-flex flex-column px-1">
                                      <span
                                        className="my-1 px-1 cursorPointer fw-normal text-start filter_type"
                                        onClick={() => {
                                          if (filterType === "asc") {
                                            setFilterType("");
                                            setFilteredAllInvoices(allInvoices);
                                          } else {
                                            setFilterType("asc");
                                            sortedColumnsData(
                                              "asc",
                                              heading.value
                                            );
                                          }
                                        }}
                                      >
                                        {filterType === "asc" ? (
                                          <img
                                            src={cancel}
                                            alt="asc"
                                            className="opacity-75 pe-1"
                                          />
                                        ) : (
                                          <img
                                            src={arrowUp}
                                            alt="asc"
                                            className="opacity-75 pe-1"
                                          />
                                        )}
                                        Asc
                                      </span>
                                      <span
                                        className="my-2 px-1 cursorPointer fw-normal text-start filter_type"
                                        onClick={() => {
                                          if (filterType === "desc") {
                                            setFilterType("");
                                            setFilteredAllInvoices(allInvoices);
                                          } else {
                                            setFilterType("desc");
                                            sortedColumnsData(
                                              "desc",
                                              heading.value
                                            );
                                          }
                                        }}
                                      >
                                        {filterType === "desc" ? (
                                          <img
                                            src={cancel}
                                            alt="desc"
                                            className="opacity-75 pe-1"
                                          />
                                        ) : (
                                          <img
                                            src={arrowDown}
                                            alt="desc"
                                            className="opacity-75 pe-1"
                                          />
                                        )}
                                        Desc
                                      </span>
                                      <span
                                        className={`my-1 px-1 fw-normal text-start filter_type ${
                                          selectedHeadings[0].name ===
                                            "Subject" &&
                                          heading.name === "Subject"
                                            ? "opacity-50"
                                            : "cursorPointer"
                                        }`}
                                        onClick={() => {
                                          if (pinnedColumn !== "Subject") {
                                            setPinnedColumn("Subject");
                                            handlePinColumn("Subject");
                                          } else {
                                            setPinnedColumn(heading.name);
                                            handlePinColumn(heading.name);
                                          }
                                        }}
                                      >
                                        <img
                                          src={
                                            pinnedColumn === heading.name
                                              ? unPin
                                              : pin
                                          }
                                          alt="pin"
                                          className="opacity-75 pe-1"
                                        />
                                        {pinnedColumn === heading.name
                                          ? "Unpin"
                                          : "Pin"}
                                        Column
                                      </span>
                                    </span>
                                  </span>
                                )}
                                <img src={filterLine} alt="" className="ps-2" />
                              </span>
                            </th>
                          )
                      )}
                      <th
                        scope="col"
                        className="text-end cursorPointer position-relative"
                      >
                        <img
                          src={filterMenu}
                          alt=""
                          className={`filter_menu ${
                            showHeadings ? "bg-light" : ""
                          }`}
                          onClick={(e) => {
                            e.stopPropagation();
                            setShowSelectedFilter("");
                            setShowHeadings(true);
                          }}
                        />
                        {showHeadings && (
                          <div
                            className="position-absolute rounded-2  border border-primary bg-light"
                            style={{
                              right: "0.2rem",
                            }}
                          >
                            <div className="d-flex flex-column px-2">
                              <span
                                className="p-2 cursorPointer fw-normal text-start"
                                onClick={() => {
                                  setSearchColumn("");
                                  setSavingHeadings(selectedHeadings);
                                  setShowModalType("Manage Columns");
                                }}
                              >
                                Manage Columns
                              </span>
                              <span
                                className="p-2 fw-normal text-start opacity-50"
                                onClick={() => {
                                  setShowModalType("Reset Column Size");
                                }}
                              >
                                Reset Column Size
                              </span>
                            </div>
                          </div>
                        )}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {filteredAllInvoices && filteredAllInvoices.length > 0 ? (
                      filteredAllInvoices.map((item, i) => {
                        return (
                          <tr key={i} className="">
                            <td>
                              <input
                                className="cursorPointer"
                                type="checkbox"
                                id="vehicle1"
                                name="vehicle1"
                                value={item.invoiceId}
                                checked={selectedCheckboxes.some(
                                  (checkbox) =>
                                    checkbox.moduleId === item.invoiceId
                                )}
                                onChange={handleSelectCheckboxChange}
                              />
                            </td>
                            <React.Fragment>
                              {selectedHeadings.map((obj, i) => {
                                const value = obj.value;
                                const itemValue = item[value];
                                return obj.isChecked ? (
                                  value === "transHash" ? (
                                    <td
                                      style={{ wordBreak: "break-all" }}
                                      className="overflow_text_hash"
                                      key={itemValue}
                                    >
                                      <a
                                        rel="noreferrer"
                                        href={
                                          EXPLORER_URL +
                                          "about-tx-hash/" +
                                          item.transHash
                                        }
                                        className="anchorLinkHash textColor"
                                        data-title={item.transHash}
                                        target="_blank"
                                      >
                                        {item.transHash
                                          ? item.transHash.slice(0, 10) + "...."
                                          : "-"}
                                      </a>
                                    </td>
                                  ) : (
                                    <td
                                      key={i}
                                      onClick={() => {
                                        if (value === "subject") {
                                          setInvoiceId(item.invoiceId);
                                          getInvoiceOverview(item.invoiceId);
                                          getNotesDetails(
                                            item.invoiceId,
                                            selectRecent
                                          );
                                          setSelectedInvoiceIndex(
                                            allInvoices.findIndex(
                                              (lead) =>
                                                lead.invoiceId ===
                                                item.invoiceId
                                            )
                                          );
                                        }
                                      }}
                                      className={`overflow_text pe-1 ${
                                        value === "subject"
                                          ? "leadName cursorPointer"
                                          : ""
                                      }`}
                                      style={{ maxWidth: "3vw" }}
                                    >
                                      {itemValue !== null ? itemValue : "-"}
                                    </td>
                                  )
                                ) : null;
                              })}
                            </React.Fragment>
                            <td></td>
                          </tr>
                        );
                      })
                    ) : (
                      <tr>
                        <td
                          colSpan={selectedHeadings.length + 2}
                          style={{ textAlign: "center" }}
                        >
                          No Data
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </>
        )}
      </>
    );
  };
  const createInvoicePage = () => {
    return (
      <>
        <div className="createLeadButtonBar">
          <div className="d-flex align-items-center w-50 gap-2">
            <img src={groupIcon} height={25} alt="img" />
            <div className="d-flex flex-column">
              <p className="mb-0 first_letter_uppercase">
                {editInvoiceOverview ? "Edit" : innerPage} Invoice
              </p>
            </div>
          </div>
          <div className="d-flex w-50 gap-2 justify-content-end">
            <button
              className="createLeadButton"
              onClick={() => {
                if (pastViewInfo) {
                  setCurrentView(pastViewInfo.view);
                  setInnerPage(pastViewInfo.innerPage);
                  setPastViewInfo((prev) => ({
                    ...prev,
                    view: "",
                    innerPage: "",
                    undo: currentView, // it will set the current view of the page so when we cancel or save it will go to same section in the page
                  }));
                  return;
                }
                if (editInvoiceOverview) {
                  setInnerPage("overview");
                } else {
                  setInnerPage("list");
                }

                if (innerPage === "clone" && !editInvoiceOverview) {
                  setInnerPage("overview");
                }

                setInvoiceInfo((prevPurchaseInfo) => ({
                  ...prevPurchaseInfo,
                  invoiceDate: prevPurchaseInfo.invoiceDate
                    ?.split("-")
                    .reverse()
                    .join("-"),
                  dueDate: prevPurchaseInfo.dueDate
                    ?.split("-")
                    .reverse()
                    .join("-"),
                }));
              }}
            >
              Cancel
            </button>
            {!editInvoiceOverview && (
              <button
                className="createLeadButton"
                style={{ width: "175px" }}
                onClick={() => {
                  if (
                    new Date(invoiceInfo.invoiceDate) >
                    new Date(invoiceInfo.dueDate)
                  ) {
                    NotificationManager.error(
                      "",
                      "Due date can not be less than Invoice date",
                      3000
                    );
                  } else {
                    handleSaveButton("SAVE AND NEW");
                  }
                }}
                // disabled={
                //   invoiceInfo.subject?.length < 2 || apiLoader
                // }
                disabled={saveAndNewSubmit}
              >
                {saveAndNewSubmit ? (
                  <Lottie
                    options={defaultOptions("blue")}
                    height="24px"
                    width="36px"
                  />
                ) : (
                  "Save and New"
                )}
              </button>
            )}
            <button
              className="save-button"
              // className={`save-button ${
              //   invoiceInfo.subject?.length < 2
              //     ?
              //       "opacity-50"
              //     : ""
              // }`}
              type="submit"
              onClick={() => {
                if (
                  new Date(invoiceInfo.invoiceDate) >
                  new Date(invoiceInfo.dueDate)
                ) {
                  NotificationManager.error(
                    "",
                    "Due date can not be less than Invoice date",
                    3000
                  );
                } else {
                  handleSaveButton("SAVE");
                }
              }}
              // disabled={
              //   invoiceInfo.subject?.length < 2 || apiLoader

              // }
            >
              {saveSubmit ? (
                <Lottie
                  options={defaultOptions("white")}
                  height="30px"
                  width="36px"
                />
              ) : !editInvoiceOverview ? (
                "Save"
              ) : (
                "Update"
              )}
            </button>
          </div>
        </div>
        <div className="leadFields ">
          <h6 className="py-2 " style={{ borderBottom: "2px solid #316AFF" }}>
            Invoice Information
          </h6>
          <div className="inputFieldDiv">
            <div className="inputFieldDivLeft  position-relative">
              <p className="mb-0">
                Invoice Owner<span className="text-danger">*</span>
              </p>
              <div className="d-flex input-with-clear  ">
                <span
                  className="insertCommonInputField cursorPointer"
                  onClick={(e) => {
                    e.stopPropagation();
                    setListVisibleType(
                      listVisibleType === "invoiceOwner" || editInvoiceOverview
                        ? ""
                        : "invoiceOwner"
                    );
                  }}
                >
                  {selectedOwner.name}
                </span>
                {!editInvoiceOverview && (
                  <span
                    className="clear-button"
                    onClick={() => clearSelectedItem()}
                  >
                    <img
                      src={listVisibleType === "invoiceOwner" ? up : down}
                      alt="img"
                      height={20}
                      width={20}
                    />
                  </span>
                )}
                <span
                  className="cursorPointer pt-2 ps-1"
                  onClick={() => setIsLeadOwnerModal(true)}
                >
                  <img src={userSearch} alt="img" width={20} height={20} />
                </span>
              </div>
              {listVisibleType === "invoiceOwner" && accountsDropdown("6rem")}
            </div>
            <div className="inputFieldDivRight position-relative">
              <p className="mb-0">Sales Order</p>
              <div
                className="d-flex input-with-clear"
                onClick={(e) => {
                  e.stopPropagation();
                  setFetchSalesInfo(null);
                  setListVisibleType(
                    listVisibleType === "salesOrderId" ? "" : "salesOrderId"
                  );
                }}
              >
                <span className="leadInputField cursorPointer rounded-1 pt-2">
                  {fetchSalesInfo
                    ? fetchSalesInfo?.subject
                    : selectedSale.subject}
                </span>
                <span
                  className="clear-button"
                  onClick={() => {
                    clearSelectedItem();
                  }}
                >
                  <img
                    src={listVisibleType === "salesOrderId" ? up : down}
                    alt="img"
                    height={20}
                    width={20}
                  />
                </span>
                <span
                  className="cursorPointer pt-2 ps-1"
                  onClick={(e) => {
                    e.stopPropagation();
                    setIsSalesListModal(true);
                  }}
                >
                  <img src={salesOrdersIcon} alt="img" width={20} height={25} />
                </span>
              </div>

              {listVisibleType === "salesOrderId" && accountsDropdown("6rem")}
            </div>
          </div>

          <div className="inputFieldDiv">
            <div className="inputFieldDivLeft">
              <p className="mb-0">
                Subject <span className="text-danger">*</span>
              </p>
              <input
                type="text"
                // className="insertCommonInputField overflow_text pe-4"
                className={
                  subjectError ? "mandatoryField" : "insertCommonInputField"
                }
                name="subject"
                value={invoiceInfo.subject}
                onChange={handleChangeInputs}
                maxLength={100}
              />
              <span className="small erro_msg">{subjectError}</span>
            </div>
            <div className="inputFieldDivRight">
              <p className="mb-0">Purchase Order</p>
              <input
                type="text"
                className="insertCommonInputField"
                name="purchaseOrder"
                value={invoiceInfo.purchaseOrder}
                onChange={handleChangeInputs}
              />
            </div>
          </div>
          <div className="inputFieldDiv">
            <div className="inputFieldDivLeft">
              <p className="mb-0">Invoice Date</p>
              <input
                type="date"
                className="insertCommonInputField"
                name="invoiceDate"
                value={invoiceInfo.invoiceDate}
                onChange={handleChangeInputs}
                onKeyDown={(e) => e.preventDefault()}
                min={minDate}
                max={maxDate}
              />
            </div>
            <div className="inputFieldDivRight position-relative">
              <p className="mb-0">Excise Duty</p>
              <div className="position-relative">
                <input
                  type="text"
                  className="insertCommonInputField ps-3"
                  name="exciseDuty"
                  // value={existingLead ? leadUpdateInfo.title : leadInfo.title}
                  // onChange={handleChangeLead}
                  value={invoiceInfo.exciseDuty}
                  onChange={handleChangeInputs}
                  onBlur={() => handleBlur("exciseDuty")}
                  onKeyPress={handleKeyPress}
                />
                <span
                  className="position-absolute"
                  style={{ top: "0.4rem", right: "35%" }}
                >
                  <CalculatorTooltip />
                </span>
              </div>
              <span className="position-absolute start-0 pt-2 ms-1 dollar_icon">
                ₹
              </span>
              {showTooltipExciseDuty && (
                <div style={{ ...tooltipStyles, bottom: "30px" }}>
                  {"= " + tooltipContent}
                </div>
              )}
            </div>
          </div>
          <div className="inputFieldDiv">
            <div className="inputFieldDivLeft">
              <p className="mb-0">Due Date</p>
              <input
                type="date"
                className="insertCommonInputField"
                name="dueDate"
                value={invoiceInfo.dueDate}
                onChange={handleChangeInputs}
                onKeyDown={(e) => e.preventDefault()}
                min={invoiceInfo.invoiceDate}
                max={maxDate}
              />
            </div>
            <div className="inputFieldDivRight">
              <p className="mb-0">Status</p>
              <Select
                value={statusDropdown}
                menuPlacement="auto"
                onChange={(selectedOption) => {
                  setStatusDropdown(selectedOption);
                }}
                options={stausOptions}
                styles={createInvoiceStyles}
              />
            </div>
          </div>
          <div className="inputFieldDiv">
            <div className="inputFieldDivLeft position-relative">
              <p className="mb-0">Sales Commission</p>
              <div className="position-relative">
                <input
                  type="text"
                  className="insertCommonInputField ps-3"
                  name="salesCommission"
                  value={invoiceInfo.salesCommission}
                  onChange={handleChangeInputs}
                  onBlur={() => handleBlur("salesCommission")}
                  onKeyPress={handleKeyPress}
                />
                <span
                  className="position-absolute"
                  style={{ top: "0.4rem", right: "35%" }}
                >
                  <CalculatorTooltip />
                </span>
              </div>

              <span className="position-absolute start-0 pt-2 ms-1 dollar_icon">
                ₹
              </span>
              {showTooltipSalesCommission && (
                <div style={{ ...tooltipStyles, bottom: "30px" }}>
                  {"= " + tooltipContent}
                </div>
              )}
            </div>
            <div className="inputFieldDivRight position-relative">
              <p className="mb-0">
                Account Name <span className="text-danger">*</span>
              </p>
              <div
                className="d-flex input-with-clear  "
                onClick={(e) => {
                  e.stopPropagation();
                  setListVisibleType(
                    listVisibleType === "accountName" ? "" : "accountName"
                  );
                }}
              >
                <span
                  className={
                    accNameError
                      ? "mandatoryField cursorPointer rounded-1 pt-2 overflow_text pe-4"
                      : "insertCommonInputField cursorPointer rounded-1 pt-2 overflow_text pe-4 dfg"
                  }
                >
                  {fetchAccInfo
                    ? fetchAccInfo.accountName
                    : isDealListModal ||
                      isAccountListModal ||
                      isContactListModal
                    ? selectedAccount?.moduleName
                    : selectedAccount?.accountName}
                </span>
                <span
                  className="clear-button"
                  onClick={() => clearSelectedItem()}
                >
                  <img
                    src={listVisibleType === "accountName" ? up : down}
                    alt="img"
                    height={20}
                    width={20}
                  />
                </span>
                <span
                  className="cursorPointer pt-2 ps-1"
                  onClick={(e) => {
                    e.stopPropagation();
                    setIsAccountListModal(true);
                  }}
                >
                  <img src={accountsIcon} alt="img" width={20} height={25} />
                </span>
              </div>
              <span className="small erro_msg">{accNameError}</span>

              {listVisibleType === "accountName" && accountsDropdown("6rem")}
            </div>
          </div>

          <div className="inputFieldDiv">
            <div className="inputFieldDivLeft position-relative">
              <p className="mb-0">Contact Name</p>
              <div className="d-flex input-with-clear">
                <span
                  className="leadInputField cursorPointer rounded-1 pt-2 overflow_text pe-4"
                  onClick={(e) => {
                    e.stopPropagation();
                    setListVisibleType(
                      listVisibleType === "contactName" ? "" : "contactName"
                    );
                  }}
                >
                  {fetchContactInfo
                    ? fetchContactInfo.contactName
                    : isDealListModal ||
                      isAccountListModal ||
                      isContactListModal
                    ? selectedContact?.moduleName
                    : selectedContact.contactName}
                </span>
                <span
                  className="clear-button"
                  onClick={() => clearSelectedItem()}
                >
                  <img
                    src={listVisibleType === "contactName" ? up : down}
                    alt="img"
                    height={20}
                    width={20}
                  />
                </span>
                <span
                  className="cursorPointer pt-2 ps-1"
                  onClick={(e) => {
                    e.stopPropagation();
                    setIsContactListModal(true);
                  }}
                >
                  <img src={ContactIcon} alt="img" width={20} height={25} />
                </span>
              </div>
              {listVisibleType === "contactName" && accountsDropdown("6rem")}
            </div>
            <div className="inputFieldDivRight position-relative">
              <p className="mb-0">Deal Name</p>
              <div
                className="d-flex input-with-clear"
                onClick={(e) => {
                  e.stopPropagation();
                  setListVisibleType(
                    listVisibleType === "dealName" ? "" : "dealName"
                  );
                }}
              >
                <span className="leadInputField cursorPointer rounded-1 pt-2 overflow_text pe-4">
                  {fetchDealInfo
                    ? fetchDealInfo.dealName
                    : selectedDeal.dealName}
                </span>
                <span
                  className="clear-button"
                  onClick={() => {
                    clearSelectedItem();
                  }}
                >
                  <img
                    src={listVisibleType === "dealName" ? up : down}
                    alt="img"
                    height={20}
                    width={20}
                  />
                </span>
                <span
                  className="cursorPointer pt-2 ps-1"
                  onClick={(e) => {
                    e.stopPropagation();
                    setIsDealListModal(true);
                  }}
                >
                  <img src={dealsIcon} alt="img" width={20} height={20} />
                </span>
              </div>
              {listVisibleType === "dealName" && accountsDropdown("6rem")}
            </div>
          </div>

          {/* </form> */}
          <h6
            className="py-2 mt-3"
            style={{ borderBottom: "2px solid #316AFF" }}
          >
            Address Information
          </h6>
          <div
            className="inputFieldDiv"
            style={{ width: "min-content", alignSelf: "end" }}
          >
            <Dropdown>
              <Dropdown.Toggle id="dropdown-basic">
                Copy Address
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item
                  onClick={() => {
                    setInvoiceInfo((prevData) => ({
                      ...prevData,
                      shippingStreet:
                        invoiceInfo.billingStreet.length > 0
                          ? invoiceInfo.billingStreet
                          : invoiceInfo.shippingStreet,
                      shippingCity:
                        invoiceInfo.billingCity.length > 0
                          ? invoiceInfo.billingCity
                          : invoiceInfo.shippingCity,
                      shippingState:
                        invoiceInfo.billingState.length > 0
                          ? invoiceInfo.billingState
                          : invoiceInfo.shippingState,
                      shippingCode:
                        invoiceInfo.billingCode.length > 0
                          ? invoiceInfo.billingCode
                          : invoiceInfo.shippingCode,
                      shippingCountry:
                        invoiceInfo.billingCountry.length > 0
                          ? invoiceInfo.billingCountry
                          : invoiceInfo.shippingCountry,
                    }));
                  }}
                >
                  Billing to Shipping
                </Dropdown.Item>
                <Dropdown.Item
                  onClick={() => {
                    setInvoiceInfo((prevData) => ({
                      ...prevData,
                      billingStreet:
                        invoiceInfo.shippingStreet.length > 0
                          ? invoiceInfo.shippingStreet
                          : invoiceInfo.billingStreet,
                      billingCity:
                        invoiceInfo.shippingCity.length > 0
                          ? invoiceInfo.shippingCity
                          : invoiceInfo.billingCity,
                      billingState:
                        invoiceInfo.shippingState.length > 0
                          ? invoiceInfo.shippingState
                          : invoiceInfo.billingState,
                      billingCode:
                        invoiceInfo.shippingCode.length > 0
                          ? invoiceInfo.shippingCode
                          : invoiceInfo.billingCode,
                      billingCountry:
                        invoiceInfo.shippingCountry.length > 0
                          ? invoiceInfo.shippingCountry
                          : invoiceInfo.billingCountry,
                    }));
                  }}
                >
                  Shipping to Billing
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
          <div className="inputFieldDiv">
            <div className="inputFieldDivLeft">
              <p className="mb-0">Billing Street</p>
              <input
                type="text"
                className="insertCommonInputField"
                name="billingStreet"
                value={invoiceInfo.billingStreet}
                onChange={handleChangeInputs}
              />
            </div>
            <div className="inputFieldDivRight">
              <p className="mb-0">Shipping Street</p>
              <input
                type="text"
                className="insertCommonInputField"
                name="shippingStreet"
                value={invoiceInfo.shippingStreet}
                onChange={handleChangeInputs}
              />
            </div>
          </div>
          <div className="inputFieldDiv">
            <div className="inputFieldDivLeft">
              <p className="mb-0">Billing City</p>
              <input
                type="text"
                className="insertCommonInputField"
                name="billingCity"
                value={invoiceInfo.billingCity}
                onChange={handleChangeInputs}
              />
              <span className="small erro_msg">{billingCityError}</span>
            </div>
            <div className="inputFieldDivRight">
              <p className="mb-0">Shipping City</p>
              <input
                type="text"
                className="insertCommonInputField"
                name="shippingCity"
                value={invoiceInfo.shippingCity}
                onChange={handleChangeInputs}
              />
              <span className="small erro_msg">{shippingCityError}</span>
            </div>
          </div>
          <div className="inputFieldDiv">
            <div className="inputFieldDivLeft">
              <p className="mb-0">Billing State</p>
              <input
                type="text"
                className="insertCommonInputField"
                name="billingState"
                value={invoiceInfo.billingState}
                onChange={handleChangeInputs}
              />
              <span className="small erro_msg">{billingStateError}</span>
            </div>
            <div className="inputFieldDivRight">
              <p className="mb-0">Shipping State</p>
              <input
                type="text"
                className="insertCommonInputField"
                name="shippingState"
                value={invoiceInfo.shippingState}
                onChange={handleChangeInputs}
              />
              <span className="small erro_msg">{shippingStateError}</span>
            </div>
          </div>
          <div className="inputFieldDiv">
            <div className="inputFieldDivLeft">
              <p className="mb-0">Billing Code</p>
              <input
                type="text"
                className="insertCommonInputField"
                name="billingCode"
                value={invoiceInfo.billingCode}
                onChange={handleChangeInputs}
              />
            </div>
            <div className="inputFieldDivRight">
              <p className="mb-0">Shipping Code</p>
              <input
                type="text"
                className="insertCommonInputField"
                name="shippingCode"
                value={invoiceInfo.shippingCode}
                onChange={handleChangeInputs}
              />
            </div>
          </div>
          <div className="inputFieldDiv">
            <div className="inputFieldDivLeft">
              <p className="mb-0">Billing Country</p>
              <input
                type="text"
                className="insertCommonInputField"
                name="billingCountry"
                value={invoiceInfo.billingCountry}
                onChange={handleChangeInputs}
              />
              <span className="small erro_msg">{billingCountryError}</span>
            </div>
            <div className="inputFieldDivRight">
              <p className="mb-0">Shipping Country</p>
              <input
                type="text"
                className="insertCommonInputField"
                name="shippingCountry"
                value={invoiceInfo.shippingCountry}
                onChange={handleChangeInputs}
              />
              <span className="small erro_msg">{shippingCountryError}</span>
            </div>
          </div>
          <div>
            <h6
              className="py-2 mt-2"
              style={{ borderBottom: "2px solid #316AFF" }}
            >
              Invoice Items <span className="text-danger">*</span>
            </h6>
          </div>
          <div
            className="horizontal-scroll-container mb-2"
            style={{
              // overflowX: "auto",
              width: "100%",
              maxWidth: "100%",
              // overflow: "hidden",
              border: "none",
            }}
          >
            <table
              className="table"
              style={{
                tableLayout: "fixed",
                minWidth: "1000px",
                margin: "0px",
              }}
            >
              <colgroup>
                <col style={{ width: "2%" }} />
                <col style={{ width: "3%" }} />
                <col style={{ width: "12%" }} />
                <col style={{ width: "7%" }} />
                <col style={{ width: "7%" }} />
                <col style={{ width: "6%" }} />
                <col style={{ width: "6%" }} />
                <col style={{ width: "6%" }} />
                <col style={{ width: "6%" }} />
              </colgroup>
              <thead>
                <tr className="mb-2">
                  <th
                    style={{
                      backgroundColor: "rgba(243, 246, 251, 1)",
                      border: "none",
                    }}
                  ></th>
                  <th
                    style={{
                      backgroundColor: "#EFEFEF",
                      border: "1px solid rgba(185, 185, 185, 0.5)",
                    }}
                  >
                    SL.NO
                  </th>
                  <th
                    style={{
                      backgroundColor: "#EFEFEF",
                      border: "1px solid rgba(185, 185, 185, 0.5)",
                    }}
                  >
                    Product Name
                  </th>
                  <th
                    style={{
                      backgroundColor: "#EFEFEF",
                      border: "1px solid rgba(185, 185, 185, 0.5)",
                    }}
                  >
                    Quantity
                  </th>
                  <th
                    style={{
                      backgroundColor: "#EFEFEF",
                      border: "1px solid rgba(185, 185, 185, 0.5)",
                    }}
                  >
                    List Price (₹)
                  </th>
                  <th
                    style={{
                      backgroundColor: "#EFEFEF",
                      border: "1px solid rgba(185, 185, 185, 0.5)",
                    }}
                  >
                    Amount (₹)
                  </th>
                  <th
                    style={{
                      backgroundColor: "#EFEFEF",
                      border: "1px solid rgba(185, 185, 185, 0.5)",
                    }}
                  >
                    Discount (₹)
                  </th>
                  <th
                    style={{
                      backgroundColor: "#EFEFEF",
                      border: "1px solid rgba(185, 185, 185, 0.5)",
                    }}
                  >
                    Tax (%)
                  </th>
                  <th
                    style={{
                      backgroundColor: "#EFEFEF",
                      border: "1px solid rgba(185, 185, 185, 0.5)",
                    }}
                  >
                    Total (₹)
                  </th>
                </tr>
              </thead>
              <tbody>
                {allProducts.map((row, index) => (
                  <tr key={index}>
                    <td
                      style={{
                        backgroundColor: "rgba(243, 246, 251, 1)",
                        border: "none",
                        alignContent: "center",
                      }}
                    >
                      <button
                        className={` ${
                          allProducts.length <= 1 ? "opacity-0" : "last-button"
                        }`}
                        onClick={() => handleDeleteRow(index)}
                        style={{ backgroundColor: "transparent" }}
                      >
                        X
                      </button>
                    </td>
                    <td style={tdStyleObj}>
                      <input
                        type="number"
                        name="slNo"
                        defaultValue={index + 1}
                        style={{
                          border: "none",
                          borderRadius: "5px",
                          backgroundColor: "white",
                          color: "#2177FE",
                          width: "-webkit-fill-available",
                        }}
                        readOnly
                        disabled
                      />
                    </td>
                    <td style={tdStyleObj}>
                      <div
                        className="d-flex input-with-clear position-relative w-100"
                        style={{
                          marginBottom: "10px",
                          borderRadius: "2.5px",
                          border: "1px solid rgba(185, 185, 185, 0.5)",
                        }}
                      >
                        <span
                          onClick={(e) => {
                            e.stopPropagation();
                            setSelectedProductIndex(index);
                            setListVisibleType(
                              listVisibleType === "productName"
                                ? ""
                                : "productName"
                            );
                          }}
                          style={{
                            width: "92%",
                          }}
                          className="cursorPointer p-1 overflow_text"
                        >
                          {row.productName || selectedProduct.productName}
                        </span>

                        <span className="position-absolute cursorPointer end-0">
                          <img
                            src={
                              listVisibleType === "productName" &&
                              selectedProductIndex === index
                                ? up
                                : down
                            }
                            alt="img"
                            height={20}
                            width={20}
                          />
                        </span>
                        {listVisibleType === "productName" &&
                          selectedProductIndex === index &&
                          accountsDropdown("1.8rem", index)}
                      </div>
                      <textarea
                        type="text"
                        name="description"
                        value={row.description}
                        onChange={(e) => handleRowChange(index, e)}
                        placeholder="Add Description"
                        className="pt-2 pb-3 ps-1 tdInputStyleObj"
                      />
                    </td>
                    <td style={tdStyleObj}>
                      <input
                        type="number"
                        name="quantity"
                        value={row.quantity}
                        onChange={(e) => handleRowChange(index, e)}
                        className="tdInputStyleObj"
                      />
                    </td>
                    <td style={tdStyleObj}>
                      <input
                        type="number"
                        name="listPrice"
                        value={row.listPrice}
                        onChange={(e) => handleRowChange(index, e)}
                        className="tdInputStyleObj"
                      />
                    </td>
                    <td style={tdStyleObj}>
                      <input
                        type="number"
                        name="amount"
                        value={row.amount}
                        onChange={(e) => handleRowChange(index, e)}
                        className="tdInputStyleObj"
                        readOnly
                        disabled
                      />
                    </td>

                    <td style={tdStyleObj} className="position-relative">
                      <div className="d-flex">
                        <input
                          type="number"
                          name="discount"
                          value={row.discount}
                          onChange={(e) => handleRowChange(index, e)}
                          readOnly
                          disabled
                          className="me-3 tdInputStyleObj"
                        />
                        <span
                          onClick={(e) => {
                            e.stopPropagation();
                            setSelectedProductIndex(index);
                            setPopoutType(
                              popoutType === "discount" ? "" : "discount"
                            );
                          }}
                          style={{ marginLeft: "-0.7rem" }}
                          className="cursorPointer"
                        >
                          {editIcon}
                        </span>
                      </div>
                      {popoutType === "discount" &&
                        selectedProductIndex === index && (
                          <>{discountPopup(index)}</>
                        )}
                    </td>
                    <td style={tdStyleObj}>
                      <input
                        type="number"
                        name="tax"
                        value={row.tax || 0}
                        onChange={(e) => handleRowChange(index, e)}
                        className="tdInputStyleObj"
                        readOnly
                        disabled
                      />
                    </td>
                    <td style={tdStyleObj}>
                      <input
                        type="number"
                        name="total"
                        value={row.total}
                        onChange={(e) => handleRowChange(index, e)}
                        className="tdInputStyleObj"
                        readOnly
                        disabled
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
            className="mt-3"
          >
            <button
              onClick={handleAddRow}
              style={{
                height: "max-content",
                borderRadius: "10px",
                padding: "5px 25px 5px 25px",
                borderColor: "#529CF3",
                backgroundColor: "#E4F5FF",
                color: "#515151",
              }}
            >
              Add Row
            </button>
            <div
              style={{
                display: "flex",
                width: "max-content",
                marginRight: "5%",
                border: "1.86px solid rgba(226, 229, 235, 1)",
                boxShadow: "0px 0px 18.12px 0px rgba(0, 0, 0, 0.03)",
                background: "rgba(255, 255, 255, 1)",
                borderRadius: "10px",
              }}
              className=""
              // className="table-responsive"
            >
              <table
                className="table"
                style={{
                  borderCollapse: "collapse",
                  width: "max-content",
                  margin: "0",
                  boxShadow: "0px 0px 18.12px 0px rgba(0, 0, 0, 0.03)",
                }}
              >
                <thead style={{ width: "max-content" }}>
                  <tr className="mb-2">
                    <th
                      scope="col"
                      style={{
                        backgroundColor: "#F8F8F8",
                        width: "max-content",
                      }}
                    >
                      Sub Total(₹)<span className="text-danger">*</span>
                    </th>
                    <td style={{ backgroundColor: "#F8F8F8" }}>
                      <input
                        type="number"
                        name="subTotal"
                        // value={invoiceInfo.subTotal}
                        value={allProducts
                          .reduce(
                            (sum, product) =>
                              parseFloat(sum) + parseFloat(product.total),
                            0
                          )
                          ?.toFixed(2)}
                        onChange={handleChangeInputs}
                        style={{
                          borderRadius: "6px",
                          border: "1px solid rgba(185, 185, 185, 0.5)",
                          backgroundColor: "#F3F6FB",
                        }}
                        readOnly
                        disabled
                      />
                    </td>
                  </tr>
                  <tr>
                    <th scope="col">Discount(₹)</th>
                    <td className="dicount_column position-relative">
                      <div className="d-flex">
                        <input
                          type="text"
                          name="discount"
                          value={invoiceInfo.discount || 0}
                          onChange={handleChangeInputs}
                          style={{
                            borderRadius: "6px",
                            border: "1px solid rgba(185, 185, 185, 0.5)",
                            backgroundColor: "#F3F6FB",
                          }}
                          readOnly
                          disabled
                          className="me-3"
                        />
                        <span
                          onClick={(e) => {
                            e.stopPropagation();
                            setSelectedProductIndex("");
                            setPopoutType(
                              popoutType === "discount" ? "" : "discount"
                            );
                          }}
                          style={{ marginLeft: "-0.7rem" }}
                          className="cursorPointer discount_icon"
                        >
                          {editIcon}
                        </span>
                      </div>
                      {popoutType === "discount" &&
                        selectedProductIndex === "" && <>{discountPopup("")}</>}
                    </td>
                  </tr>
                  <tr>
                    <th
                      scope="col"
                      style={{
                        backgroundColor: "#F8F8F8",
                      }}
                    >
                      Tax(%)
                    </th>
                    <td
                      style={{ backgroundColor: "#F8F8F8" }}
                      className="dicount_column position-relative"
                    >
                      <div className="d-flex">
                        <input
                          type="number"
                          value={invoiceInfo.tax || 0}
                          onChange={handleChangeInputs}
                          style={{
                            borderRadius: "6px",
                            border: "1px solid rgba(185, 185, 185, 0.5)",
                            backgroundColor: "#F3F6FB",
                          }}
                          readOnly
                          disabled
                        />
                        <span
                          onClick={(e) => {
                            e.stopPropagation();
                            setSelectedProductIndex("");
                            setPopoutType(popoutType === "tax" ? "" : "tax");
                          }}
                          className="cursorPointer discount_icon ps-1"
                        >
                          {editIcon}
                        </span>
                      </div>
                      {popoutType === "tax" && selectedProductIndex === "" && (
                        <>{discountPopup("")}</>
                      )}
                    </td>
                  </tr>
                  <tr>
                    <th scope="col">Adjustment(₹)</th>
                    <td>
                      <input
                        type="number"
                        name="adjustment"
                        value={invoiceInfo.adjustment}
                        onChange={(e) => {
                          const total =
                            invoiceInfo.subTotal - invoiceInfo.discount;
                          const taxValue =
                            (parseFloat(invoiceInfo.tax) / 100) * total;
                          setInvoiceInfo((prevPurchaseInfo) => ({
                            ...prevPurchaseInfo,
                            adjustment: e.target.value,
                            grandTotal: (
                              parseFloat(total) +
                              parseFloat(taxValue || 0) +
                              parseFloat(e.target.value || 0)
                            ).toFixed(2),
                          }));
                        }}
                        style={{
                          borderRadius: "6px",
                          border: "1px solid rgba(185, 185, 185, 0.5)",
                          backgroundColor: "transparent",
                        }}
                      />
                    </td>
                  </tr>
                  <tr>
                    <th
                      scope="col"
                      style={{
                        backgroundColor: "#F8F8F8",
                      }}
                    >
                      Grand Total(₹)<span className="text-danger">*</span>
                    </th>
                    <td style={{ backgroundColor: "#F8F8F8" }}>
                      <input
                        type="number"
                        name="grandTotal"
                        value={invoiceInfo.grandTotal}
                        onChange={handleChangeInputs}
                        style={{
                          borderRadius: "6px",
                          border: "1px solid rgba(185, 185, 185, 0.5)",
                          backgroundColor: "#F3F6FB",
                        }}
                        readOnly
                        disabled
                      />
                    </td>
                  </tr>
                </thead>
              </table>
            </div>
          </div>
          <h6
            className="py-2 mt-2"
            style={{ borderBottom: "2px solid #316AFF" }}
          >
            Terms and Conditions
          </h6>
          <div className="inputFieldDiv" style={{ display: "block" }}>
            <div
              className="inputFieldDivLeft"
              style={{ display: "block", width: "100%" }}
            >
              <p>Terms and Conditions</p>
              <textarea
                rows="4"
                cols="50"
                maxLength="2000"
                style={{
                  width: "100%",
                  border: "1px solid #B9B9B94D",
                  height: "100px",
                  outline: "none",
                  borderRadius: "10px",
                  backgroundColor: "transparent",
                }}
                name="termsAndConditions"
                value={invoiceInfo.termsAndConditions}
                onChange={handleChangeInputs}
                className="ps-2 pt-2 pb-4"
              />
            </div>
          </div>
          <h6
            className="py-2 mt-2"
            style={{ borderBottom: "2px solid #316AFF" }}
          >
            Description Information
          </h6>
          <div className="inputFieldDiv" style={{ display: "block" }}>
            <div
              className="inputFieldDivLeft"
              style={{ display: "block", width: "100%" }}
            >
              <p>Description</p>
              <textarea
                rows="4"
                cols="50"
                maxLength="2000"
                style={{
                  width: "100%",
                  border: "1px solid #B9B9B94D",
                  height: "100px",
                  outline: "none",
                  borderRadius: "10px",
                  backgroundColor: "transparent",
                }}
                name="description"
                value={invoiceInfo.description}
                onChange={handleChangeInputs}
                className="ps-2 pt-2 pb-4"
              />
            </div>
          </div>
        </div>
      </>
    );
  };

  const handleFieldClick = (field) => {
    if (field.type === "dropdown") {
      // let fieldValue = field.name
      //   .replace(/\s+/g, "")
      //   .replace(/^(.)/, (match) => match.toLowerCase());
      // setClickedDropdownObj(contactInfo[fieldValue]);
    } else if (field.type === "checkbox") {
      // setClickedEmailOtp(contactInfo["emailOptOut"]);
    }
    const updatedColumns = [...fieldLabels];

    const existedObj = updatedColumns.find(
      (field) => field.name === selectedField
    );
    const selectedObj = updatedColumns.find((item) => item.name === field.name);

    const existedObjIndex = updatedColumns.findIndex(
      (field) => field.name === existedObj.name
    );
    const selectedObjIndex = updatedColumns.findIndex(
      (field) => field.name === selectedObj.name
    );

    updatedColumns[existedObjIndex].isShowed = false;
    updatedColumns[selectedObjIndex].isShowed = true;

    const removedObj = updatedColumns.splice(existedObjIndex, 1)[0];
    updatedColumns.splice(existedObjIndex, 0, selectedObj);

    updatedColumns.push(removedObj);
    const uniqueData = updatedColumns.filter(
      (item, index, self) =>
        index === self.findIndex((t) => t.name === item.name)
    );
    setFieldLabels(uniqueData);
    setShowSearchFields(uniqueData);
  };

  const individualInvoice = () => {
    return (
      <>
        <div className="indiv_main_container ">
          <div className="individual_header_container commonContainer_bg w-100  flex-column  d-flex justify-content-between">
            <div className="iconContainer d-flex justify-content-between px-2 pt-2">
              <span
                className="cursorPointer"
                onClick={() => {
                  getAllInvoices(currentPage, selectedOption);
                  setInnerPage("list");
                  setEditInvoiceOverview(false);
                  const resetFields = Object.fromEntries(
                    Object.keys(editingFields).map((key) => [key, false])
                  );
                  setEditingFields({
                    ...resetFields,
                  });
                  setShowType("");
                }}
              >
                {leftArrow}
              </span>

              <span>
                <span
                  className={`me-2 ${
                    selectedInvoiceIndex === 0 ? "opacity-50" : "cursorPointer"
                  }`}
                  onClick={() => {
                    const invoiceIndex = allInvoices.findIndex(
                      (invoice) => invoice.invoiceId === invoiceId
                    );
                    if (invoiceIndex !== 0) {
                      setSelectedInvoiceIndex(invoiceIndex - 1);
                      setInvoiceId(allInvoices[invoiceIndex - 1].invoiceId);
                      getInvoiceOverview(
                        allInvoices[invoiceIndex - 1].invoiceId
                      );
                      getNotesDetails(
                        allInvoices[invoiceIndex - 1].invoiceId,
                        selectRecent
                      );
                    }
                  }}
                >
                  {leftAngleArrow}
                </span>
                <span
                  className={`ms-1 ${
                    selectedInvoiceIndex === allInvoices.length - 1
                      ? "opacity-50"
                      : "cursorPointer"
                  }`}
                  onClick={() => {
                    const invoiceIndex = allInvoices.findIndex(
                      (invoice) => invoice.invoiceId === invoiceId
                    );
                    if (invoiceIndex < allInvoices.length - 1) {
                      setSelectedInvoiceIndex(invoiceIndex + 1);
                      setInvoiceId(allInvoices[invoiceIndex + 1].invoiceId);
                      getInvoiceOverview(
                        allInvoices[invoiceIndex + 1].invoiceId
                      );
                      getNotesDetails(
                        allInvoices[invoiceIndex + 1].invoiceId,
                        selectRecent
                      );
                    }
                  }}
                >
                  {rightAngleArrow}
                </span>
              </span>
            </div>
            <div>
              <div className="d-flex justify-content-between align-items-center pt-3 pb-2">
                <div className="d-flex flex-column justify-content-end ps-2">
                  <span className="first_letter_uppercase">
                    {singleInvoiceDetails?.getinvoiceInfo?.subject}
                  </span>
                </div>
                <div className="d-flex  justify-content-end align-items-end  px-3">
                  <div className="d-flex gap-2 position-relative ">
                    <button
                      className="btn btn-secondary bg-white text-dark border border-primary"
                      onClick={handleEditInvoice}
                    >
                      Edit
                    </button>
                    <button
                      className="btn btn-secondary bg-white text-dark border border-primary"
                      onClick={(e) => {
                        e.stopPropagation();
                        setShowOptionsInvoice(!showOptionsInvoice);
                      }}
                    >
                      ---
                    </button>
                    {showOptionsInvoice && (
                      <div
                        className="rounded showOptions p-2 end-0"
                        style={{ top: "2.6rem" }}
                      >
                        <div
                          className="d-flex gap-3 align-items-center mt-2"
                          onClick={handleEditInvoiceClone}
                        >
                          <img src={Clone} alt="close" />
                          <div
                            style={{ color: "#515151", fontWeight: "300" }}
                            className="mt-1 cursorPointer ms-2"
                          >
                            Clone
                          </div>
                        </div>
                        {invoiceInfo.isShared === false && (
                          <div
                            className="d-flex gap-3 align-items-center mt-2"
                            onClick={() => {
                              if (!isLoading) {
                                getAllInfoShare(invoiceId);
                              }
                            }}
                          >
                            <img src={Share} alt="close" />
                            <div
                              style={{ color: "#515151", fontWeight: "300" }}
                              className="mt-2 cursorPointer ms-2"
                            >
                              Share
                            </div>
                          </div>
                        )}

                        <div
                          className="d-flex gap-3 align-items-center mt-2"
                          onClick={handleDeleteInvoice}
                        >
                          <img src={Delete} alt="close" />
                          <div
                            style={{ color: "#515151", fontWeight: "300" }}
                            className="mt-2 cursorPointer ms-2"
                          >
                            Delete
                          </div>
                        </div>
                        <div
                          style={{ borderBottom: "1px solid #515151s" }}
                        ></div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="detailList_main_container mt-2 commonContainer_bg">
          <div className="detailList_main_container d-flex py-2 ">
            <div
              className={` pt-3 cursorPointer ${isShrinked ? "" : "w-25"}`}
              onClick={() => {
                setIsShrinked(!isShrinked);
              }}
            >
              {isShrinked ? expandArrow : shrinkArrow}
            </div>
            <div
              className={`d-flex justify-content-between px-2 ${
                isShrinked ? "w-100" : "w-75 ps-3"
              }`}
            >
              <span className="pt-3 ps-2">
                Last Update: {singleInvoiceDetails.getinvoiceInfo.lastUpdate}
              </span>
              <div className="d-flex  gap-4 justify-content-between px-2 bg-white px-1  rounded-5 py-1">
                <span
                  // className=""
                  className={`cursorPointer } ${
                    invoicePageView === "overview"
                      ? "overviewTimelineTab "
                      : "  d-flex justify-content-center align-items-center"
                  }`}
                  onClick={() => setInvoicePageView("overview")}
                >
                  Overview
                </span>
                <span
                  className={`cursorPointer } ${
                    invoicePageView === "timeline"
                      ? "overviewTimelineTab"
                      : " d-flex justify-content-center align-items-center"
                  }`}
                  onClick={() => {
                    getAllTimeline();
                  }}
                >
                  Timeline
                </span>
              </div>
            </div>
          </div>

          <div className="detailList_content_container d-flex justify-content-between px-2 pt-2 bg-white">
            <div
              className={`w-25 relatedListContainer  rounded commonContainer_bg me-2  mx-2 ${
                isShrinked ? "d-none" : ""
              }`}
            >
              <div className="d-flex">
                <p className="opacity-57 ps-2 opacity-75 m-0  py-2">
                  Related List
                </p>
              </div>
              <div className="horizontal_line  mx-1 "></div>
              <ul className="relateList_container">
                {notePermission === "TRUE" && (
                  <li
                    className={`cursorPointer py-2 relatedList_tab rounded-1 }`}
                    onClick={() => {
                      if (invoicePageView === "timeline") {
                        setInvoicePageView("overview");
                        setTimeout(() => scrollToSection("notesSection"), 0);
                      } else {
                        scrollToSection("notesSection");
                      }
                    }}
                  >
                    <span>
                      <img src={noteIcon} alt="img" height={20} width={20} />
                    </span>
                    Notes
                  </li>
                )}

                <li
                  className={`cursorPointer py-2 relatedList_tab rounded-1 }`}
                  onClick={() => {
                    if (invoicePageView === "timeline") {
                      setInvoicePageView("overview");
                      setTimeout(() => scrollToSection("openActivity"), 0);
                    } else {
                      scrollToSection("openActivity");
                    }
                  }}
                >
                  <span>
                    <img
                      src={openActivityIcon}
                      alt="img"
                      height={20}
                      width={20}
                    />
                  </span>
                  Open Activities
                </li>
                <li
                  className={`cursorPointer py-2 relatedList_tab rounded-1 }`}
                  onClick={() => {
                    if (invoicePageView === "timeline") {
                      setInvoicePageView("overview");
                      setTimeout(() => scrollToSection("closeActivity"), 0);
                    } else {
                      scrollToSection("closeActivity");
                    }
                  }}
                >
                  <span>
                    <img
                      src={closeActivityIcon}
                      alt="img"
                      height={20}
                      width={20}
                    />
                  </span>
                  Closed Activities
                </li>
              </ul>
            </div>
            {/* Contact Overview Implementation */}
            {invoicePageView === "overview" ? (
              <div
                className={`common_bg bg-white w-75 rounded-2  leadDetail_main_Container ${
                  isShrinked ? "w-100" : "w-75"
                }`}
              >
                <div className=" common_bg ">
                  <div className="lead_DetailContainer h-25 py-2 rounded-2  commonContainer_bg d-flex justify-content-between align-items-center">
                    <div className=" w-100 d-flex flex-column ">
                      {fieldLabels.map((field, i) => {
                        const fieldValue =
                          field.value ||
                          field.name
                            .replace(/\s+/g, "")
                            .replace(/^(.)/, (match) => match.toLowerCase());

                        return field.isShowed ? (
                          <div className="d-flex w-75 ps-3" key={i}>
                            <div className="w-50 position-relative pe-5">
                              <div
                                onClick={(e) => {
                                  e.stopPropagation();
                                  setSelectedField(
                                    selectedField === field.name
                                      ? ""
                                      : field.name
                                  );
                                  setSearchField("");
                                }}
                                className={`opacity-75 cursorPointer d-flex justify-content-between w-100 my-1 p-1 ${
                                  selectedField === field.name
                                    ? "outline_border rounded-1"
                                    : ""
                                }`}
                              >
                                <span>{field.name}</span>
                                <img
                                  src={selectedField === field.name ? up : down}
                                  alt="img"
                                  height={20}
                                  width={20}
                                  className="pt-1 cursorPointer"
                                />
                              </div>
                              {selectedField === field.name && (
                                <div
                                  className="position-absolute field_container w-100 pe-5"
                                  onClick={(e) => {
                                    e.stopPropagation();
                                  }}
                                >
                                  <div className="">
                                    <label
                                      htmlFor="selectField"
                                      className="position-relative w-100"
                                    >
                                      <input
                                        id="selectField"
                                        type="text"
                                        className="rounded-2 p-1 w-100 pe-4"
                                        placeholder="Search"
                                        value={searchField}
                                        onChange={(e) => {
                                          if (
                                            e.target.value.charAt(0) !== " "
                                          ) {
                                            const value =
                                              e.target.value.replace(
                                                /[^a-zA-Z ]+|(\s)\s+/g,
                                                "$1"
                                              );
                                            setSearchField(value);
                                          }
                                        }}
                                      />
                                      <img
                                        className="position-absolute mt-1 me-2 end-0 pt-1 cursorPointer"
                                        onClick={() => {
                                          setSearchField("");
                                        }}
                                        src={searchIcon}
                                        alt="search"
                                        width={20}
                                      />
                                    </label>

                                    <div className="w-100 rounded-2">
                                      <div className="border border-1 field_container_dropdown rounded-1">
                                        {showSearchFields
                                          .filter((field) =>
                                            field.name
                                              .toLowerCase()
                                              .includes(
                                                searchField.trim().toLowerCase()
                                              )
                                          )
                                          .sort((a, b) =>
                                            a.name.localeCompare(b.name)
                                          )
                                          .map(
                                            (field, i) =>
                                              (selectedField === field.name ||
                                                !field.isShowed) && (
                                                <div
                                                  key={field.name}
                                                  className="d-flex gap-2 p-2 cursorPointer rounded-2 align-items-center"
                                                  onClick={() => {
                                                    selectedField === field.name
                                                      ? setSelectedField("")
                                                      : handleFieldClick(
                                                          field,
                                                          i
                                                        );
                                                  }}
                                                >
                                                  <span className="right_tick">
                                                    {selectedField ===
                                                    field.name
                                                      ? tick
                                                      : ""}
                                                  </span>
                                                  <span className="ps-2">
                                                    {field.name}
                                                  </span>
                                                </div>
                                              )
                                          )}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              )}
                            </div>
                            <div className={`w-50 mt-1 ms-5 position-relative`}>
                              {field.type === "input" ? (
                                <EditableInput
                                  fieldType={
                                    ["dueDate", "invoiceDate"].includes(
                                      fieldValue
                                    )
                                      ? "date"
                                      : "text"
                                  }
                                  className="ps-3"
                                  inputType={fieldValue}
                                  handleIndividualInputChange={
                                    handleIndividualInputChange
                                  }
                                  editingFields={editingFields}
                                  handleEditClick={handleEditClick}
                                  handleCancelClick={handleCancelClick}
                                  handleUpdateClick={handleUpdateClick}
                                  updatedFields={invoiceInfo}
                                  accountOverviewDetails={invoiceInfo}
                                  setShowType={setShowType}
                                  showType={showType}
                                />
                              ) : field.type === "dropdown" ? (
                                <div
                                  className="position-relative d-flex"
                                  style={{ alignItems: "center" }}
                                  onClick={() => {
                                    handleEditClick(fieldValue);
                                  }}
                                >
                                  {editingFields[fieldValue] &&
                                  showType === fieldValue ? (
                                    <Select
                                      value={invoiceInfo[fieldValue]}
                                      // value={purchaseInfo[fieldValue]}
                                      menuPlacement="auto"
                                      onChange={(selectedOption) =>
                                        handleChangeSelectDropDown(
                                          selectedOption,
                                          fieldValue
                                        )
                                      }
                                      options={stausOptions}
                                      styles={fieldDropdownStyles}
                                      isSearchable={false}
                                    />
                                  ) : (
                                    <input
                                      type="text"
                                      className={`cursorPointer indvEditInput
                    ${
                      showType === fieldValue
                        ? "selectedBorder "
                        : "unselectBorder bg-transparent"
                    }`}
                                      value={invoiceInfo[fieldValue]?.value}
                                      name={fieldValue}
                                      onClick={(e) => {
                                        if (showType === "") {
                                          // invoiceInfo[fieldValue] =
                                          // invoiceInfo[fieldValue];
                                          setShowType(e.target.name);
                                          setInvoiceInfo(invoiceInfo);
                                        }
                                        handleEditClick(fieldValue);
                                      }}
                                      // readOnly={showType !== "emailId"}
                                      readOnly={true}
                                    />
                                  )}
                                  {editingFields[fieldValue] &&
                                    showType === fieldValue && (
                                      <div className="d-flex gap-2">
                                        <span
                                          onClick={() => {
                                            setShowType("");
                                            handleUpdateClick(fieldValue);
                                          }}
                                          className="ps-2 cursorPointer"
                                        >
                                          <img
                                            src={updateIcon}
                                            alt="img"
                                            height={20}
                                            width={20}
                                          />
                                        </span>
                                        <span
                                          onClick={() => {
                                            setShowType("");
                                            // handleUpdateClick(inputType);
                                            handleCancelClick(fieldValue);
                                          }}
                                          className="cursorPointer"
                                        >
                                          <img
                                            src={cancelIcon}
                                            alt="img"
                                            height={20}
                                            width={20}
                                          />
                                        </span>
                                      </div>
                                    )}
                                </div>
                              ) : field.type === "readOnly" ? (
                                <div>
                                  <span className="d-flex flex-column">
                                    <span>{invoiceInfo[fieldValue]}</span>
                                    {["Created By", "Modified By"].includes(
                                      field.name
                                    ) && (
                                      <span className="small opacity-75">
                                        {
                                          invoiceInfo[
                                            field.name === "Created By"
                                              ? "createdDate"
                                              : "modifiedDate"
                                          ]
                                        }
                                      </span>
                                    )}
                                  </span>
                                </div>
                              ) : (
                                <div className="position-relative">
                                  <div
                                    className="d-flex input-with-clear position-relative"
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      setListVisibleType(
                                        listVisibleType === fieldValue
                                          ? ""
                                          : fieldValue
                                      );
                                    }}
                                  >
                                    <div className="position-relative rounded-1  w-100">
                                      <span className="w-100 ps-1 cursorPointer">
                                        {fieldValue === "contactName"
                                          ? selectedContact.contactName
                                          : fieldValue === "accountName"
                                          ? selectedAccount.accountName
                                          : fieldValue === "dealName"
                                          ? selectedDeal.dealName
                                          : fieldValue === "invoiceOwner"
                                          ? selectedOwner.name
                                          : selectedSale.subject}
                                      </span>
                                      {/* <span
                                        className="position-absolute cursorPointer"
                                        style={{ right: "0.8rem" }}
                                        onClick={() => clearSelectedItem()}
                                      >
                                        <img
                                          src={
                                            listVisibleType === fieldValue
                                              ? up
                                              : down
                                          }
                                          alt="img"
                                          height={20}
                                          width={20}
                                        />
                                      </span> */}
                                    </div>
                                    {/* <span
                                      className="position-absolute end-0"
                                      style={{ marginRight: "-1.6rem" }}
                                    >
                                      <span
                                        className="cursorPointer pb-1 ps-1"
                                        onClick={() => {
                                          fieldValue === "contactName"
                                            ? setIsContactListModal(true)
                                            : fieldValue === "accountName"
                                            ? setIsAccountListModal(true)
                                            : fieldValue === "dealName"
                                            ? setIsDealListModal(true)
                                            : fieldValue === "invoiceOwner"
                                            ? setIsLeadOwnerModal(true)
                                            : setIsSalesListModal(true);
                                        }}
                                      >
                                        <img
                                          src={userSearch}
                                          alt="img"
                                          width={20}
                                          height={20}
                                        />
                                      </span>
                                    </span> */}
                                  </div>
                                  {/* {listVisibleType === fieldValue &&
                                    accountsDropdown("2rem")} */}
                                </div>
                              )}
                            </div>
                          </div>
                        ) : null;
                      })}
                    </div>
                  </div>
                  <div className="lead_DetailContainer h-25 py-2 rounded-2  commonContainer_bg d-flex justify-content-between mt-2 px-3">
                    <div>
                      <h5 className="w-100 text-primary pt-2">Accounts</h5>
                      <div>
                        <h6>{quotesAccContDealData?.accountName}</h6>
                        <div className="d-flex flex-column">
                          <span>{quotesAccContDealData?.accountSite}</span>
                          <span>
                            {quotesAccContDealData?.accPhone ? (
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                fill="currentColor"
                                className="bi bi-telephone me-1"
                                viewBox="0 0 16 16"
                              >
                                <path d="M3.654 1.328a.678.678 0 0 0-1.015-.063L1.605 2.3c-.483.484-.661 1.169-.45 1.77a17.6 17.6 0 0 0 4.168 6.608 17.6 17.6 0 0 0 6.608 4.168c.601.211 1.286.033 1.77-.45l1.034-1.034a.678.678 0 0 0-.063-1.015l-2.307-1.794a.68.68 0 0 0-.58-.122l-2.19.547a1.75 1.75 0 0 1-1.657-.459L5.482 8.062a1.75 1.75 0 0 1-.46-1.657l.548-2.19a.68.68 0 0 0-.122-.58zM1.884.511a1.745 1.745 0 0 1 2.612.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.68.68 0 0 0 .178.643l2.457 2.457a.68.68 0 0 0 .644.178l2.189-.547a1.75 1.75 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.6 18.6 0 0 1-7.01-4.42 18.6 18.6 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877z" />
                              </svg>
                            ) : null}
                            {quotesAccContDealData?.accPhone}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div>
                      <h5 className="w-100 text-primary pt-2">Contacts</h5>
                      <div>
                        <h6>{quotesAccContDealData.contactName}</h6>
                        <div className="d-flex flex-column">
                          <span>{quotesAccContDealData.contactEmail}</span>
                          <span>
                            {quotesAccContDealData.contactPhone ? (
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                fill="currentColor"
                                className="bi bi-telephone me-1"
                                viewBox="0 0 16 16"
                              >
                                <path d="M3.654 1.328a.678.678 0 0 0-1.015-.063L1.605 2.3c-.483.484-.661 1.169-.45 1.77a17.6 17.6 0 0 0 4.168 6.608 17.6 17.6 0 0 0 6.608 4.168c.601.211 1.286.033 1.77-.45l1.034-1.034a.678.678 0 0 0-.063-1.015l-2.307-1.794a.68.68 0 0 0-.58-.122l-2.19.547a1.75 1.75 0 0 1-1.657-.459L5.482 8.062a1.75 1.75 0 0 1-.46-1.657l.548-2.19a.68.68 0 0 0-.122-.58zM1.884.511a1.745 1.745 0 0 1 2.612.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.68.68 0 0 0 .178.643l2.457 2.457a.68.68 0 0 0 .644.178l2.189-.547a1.75 1.75 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.6 18.6 0 0 1-7.01-4.42 18.6 18.6 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877z" />
                              </svg>
                            ) : null}
                            {quotesAccContDealData.contactPhone}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div>
                      <h5 className="w-100 text-primary pt-2">Deals</h5>
                      <div>
                        <h6>{quotesAccContDealData.dealName}</h6>
                        <div>
                          <span>{quotesAccContDealData.dealClosingDate}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="lead_Information_mainContainer commonContainer_bg rounded-2  d-flex flex-column my-2">
                    <h5 className="w-100 text-primary px-2 pt-2">
                      Invoice Information
                    </h5>

                    <div className="d-flex justify-content-between">
                      <div className="leadContact_container d-flex gap-4 w-100 ps-2 pe-5">
                        <div className="w-50">
                          {fieldIndividualDropdowns.slice(0, 9).map((field) => {
                            const fieldValue =
                              field.value ||
                              field.name
                                .replace(/\s+/g, "")
                                .replace(/^(.)/, (match) =>
                                  match.toLowerCase()
                                );
                            return (
                              <div
                                className="d-flex justify-content-between align-items-center mb-1 w-100 gap-1"
                                key={fieldValue}
                              >
                                <div className="opacity-75 p-2 w-100">
                                  {field.name}
                                </div>
                                <div className="w-100">
                                  {field.type === "input" ? (
                                    <EditableInput
                                      fieldType={
                                        [
                                          "dueDateInfo",
                                          "invoiceDateInfo",
                                        ].includes(fieldValue)
                                          ? "date"
                                          : "text"
                                      }
                                      className="ps-3"
                                      inputType={fieldValue}
                                      handleIndividualInputChange={
                                        handleIndividualInputChange
                                      }
                                      editingFields={editingFields}
                                      handleEditClick={handleEditClick}
                                      handleCancelClick={handleCancelClick}
                                      handleUpdateClick={handleUpdateClick}
                                      updatedFields={invoiceInfo}
                                      accountOverviewDetails={invoiceInfo}
                                      setShowType={setShowType}
                                      showType={showType}
                                    />
                                  ) : field.type === "readOnly" ? (
                                    <div>
                                      <span className="d-flex flex-column">
                                        <span>{invoiceInfo[fieldValue]}</span>
                                        {["Created By", "Modified By"].includes(
                                          field.name
                                        ) && (
                                          <span className="small opacity-75">
                                            {
                                              invoiceInfo[
                                                field.name === "Created By"
                                                  ? "createdDate"
                                                  : "modifiedDate"
                                              ]
                                            }
                                          </span>
                                        )}
                                      </span>
                                    </div>
                                  ) : (
                                    <div className="position-relative">
                                      <div
                                        className="d-flex position-relative"
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          setListVisibleType(
                                            listVisibleType === fieldValue
                                              ? ""
                                              : fieldValue
                                          );
                                        }}
                                      >
                                        <div className="position-relative  w-100">
                                          <span className="w-100 ps-1 cursorPointer py-1 px-2   ">
                                            {fieldValue === "contactName"
                                              ? selectedContact.contactName
                                              : fieldValue === "accountName"
                                              ? selectedAccount.accountName
                                              : fieldValue === "dealName"
                                              ? selectedDeal.dealName
                                              : fieldValue === "invoiceOwner"
                                              ? selectedOwner.name
                                              : selectedSale.subject}
                                          </span>
                                          {/* <span
                                            className="position-absolute cursorPointer"
                                            style={{ right: "0.8rem" }}
                                            onClick={() => clearSelectedItem()}
                                          >
                                            <img
                                              src={listVisibleType ? up : down}
                                              alt="img"
                                              height={20}
                                              width={20}
                                            />
                                          </span> */}
                                        </div>

                                        {/* <span
                                          className="position-absolute end-0"
                                          style={{ marginRight: "-1.6rem" }}
                                        >
                                          <span
                                            className="cursorPointer pb-1 ps-1"
                                            onClick={() => {
                                              fieldValue === "contactName"
                                                ? setIsContactListModal(true)
                                                : fieldValue === "accountName"
                                                ? setIsAccountListModal(true)
                                                : fieldValue === "dealName"
                                                ? setIsDealListModal(true)
                                                : fieldValue === "invoiceOwner"
                                                ? setIsLeadOwnerModal(true)
                                                : setIsSalesListModal(true);
                                            }}
                                          >
                                            <img
                                              src={userSearch}
                                              alt="img"
                                              width={20}
                                              height={20}
                                            />
                                          </span>
                                        </span> */}
                                      </div>
                                      {/* {listVisibleType === fieldValue &&
                                        accountsDropdown("2rem")} */}
                                    </div>
                                  )}
                                </div>
                              </div>
                            );
                          })}
                        </div>
                        <div className="w-50">
                          {fieldIndividualDropdowns
                            .slice(9, 16)
                            .map((field) => {
                              const fieldValue =
                                field.value ||
                                field.name
                                  .replace(/\s+/g, "")
                                  .replace(/^(.)/, (match) =>
                                    match.toLowerCase()
                                  );
                              return (
                                <div
                                  className="d-flex justify-content-between align-items-center mb-2 w-100 gap-1"
                                  key={fieldValue}
                                >
                                  <div className="opacity-75 p-2 w-100">
                                    {field.name}
                                  </div>
                                  <div className="w-100">
                                    {field.type === "input" ? (
                                      <EditableInput
                                        fieldType={
                                          ["dueDate", "invoiceDate"].includes(
                                            fieldValue
                                          )
                                            ? "date"
                                            : "text"
                                        }
                                        className="ps-3"
                                        inputType={fieldValue}
                                        handleIndividualInputChange={
                                          handleIndividualInputChange
                                        }
                                        editingFields={editingFields}
                                        handleEditClick={handleEditClick}
                                        handleCancelClick={handleCancelClick}
                                        handleUpdateClick={handleUpdateClick}
                                        updatedFields={invoiceInfo}
                                        accountOverviewDetails={invoiceInfo}
                                        setShowType={setShowType}
                                        showType={showType}
                                      />
                                    ) : field.type === "readOnly" ? (
                                      <div>
                                        <span className="d-flex flex-column">
                                          <span>{invoiceInfo[fieldValue]}</span>
                                          {[
                                            "Created By",
                                            "Modified By",
                                          ].includes(field.name) && (
                                            <span className="small opacity-75">
                                              {
                                                invoiceInfo[
                                                  field.name === "Created By"
                                                    ? "createdDate"
                                                    : "modifiedDate"
                                                ]
                                              }
                                            </span>
                                          )}
                                        </span>
                                      </div>
                                    ) : field.type === "dropdown" ? (
                                      <div
                                        className="position-relative d-flex"
                                        style={{ alignItems: "center" }}
                                        onClick={() => {
                                          handleEditClick(fieldValue);
                                        }}
                                      >
                                        {editingFields[fieldValue] &&
                                        showType === fieldValue ? (
                                          <Select
                                            value={invoiceInfo[fieldValue]}
                                            // value={purchaseInfo[fieldValue]}
                                            menuPlacement="auto"
                                            onChange={(selectedOption) =>
                                              handleChangeSelectDropDown(
                                                selectedOption,
                                                fieldValue
                                              )
                                            }
                                            options={stausOptions}
                                            styles={fieldDropdownStyles}
                                            isSearchable={false}
                                          />
                                        ) : (
                                          <input
                                            type="text"
                                            className={`cursorPointer indvEditInput
                    ${
                      showType === fieldValue
                        ? "selectedBorder "
                        : "unselectBorder bg-transparent"
                    }`}
                                            value={
                                              invoiceInfo[fieldValue]?.value
                                            }
                                            name={fieldValue}
                                            onClick={(e) => {
                                              if (showType === "") {
                                                // invoiceInfo[fieldValue] =
                                                // invoiceInfo[fieldValue];
                                                setShowType(e.target.name);
                                                setInvoiceInfo(invoiceInfo);
                                              }
                                              handleEditClick(fieldValue);
                                            }}
                                            // readOnly={showType !== "emailId"}
                                            readOnly={true}
                                          />
                                        )}
                                        {editingFields[fieldValue] &&
                                          showType === fieldValue && (
                                            <div className="d-flex gap-2">
                                              <span
                                                onClick={() => {
                                                  setShowType("");
                                                  handleUpdateClick(fieldValue);
                                                }}
                                                className="ps-2 cursorPointer"
                                              >
                                                <img
                                                  src={updateIcon}
                                                  alt="img"
                                                  height={20}
                                                  width={20}
                                                />
                                              </span>
                                              <span
                                                onClick={() => {
                                                  setShowType("");
                                                  // handleUpdateClick(inputType);
                                                  handleCancelClick(fieldValue);
                                                }}
                                                className="cursorPointer"
                                              >
                                                <img
                                                  src={cancelIcon}
                                                  alt="img"
                                                  height={20}
                                                  width={20}
                                                />
                                              </span>
                                            </div>
                                          )}
                                      </div>
                                    ) : (
                                      <div className="position-relative">
                                        <div
                                          className="d-flex input-with-clear position-relative"
                                          onClick={(e) => {
                                            e.stopPropagation();
                                            setListVisibleType(
                                              listVisibleType === fieldValue
                                                ? ""
                                                : fieldValue
                                            );
                                          }}
                                        >
                                          <div className="position-relative rounded-1  w-100">
                                            <span className="w-100 ps-1 cursorPointer">
                                              {fieldValue === "contactName"
                                                ? selectedContact.contactName
                                                : fieldValue === "accountName"
                                                ? selectedAccount.accountName
                                                : fieldValue === "dealName"
                                                ? selectedDeal.dealName
                                                : fieldValue === "invoiceOwner"
                                                ? selectedOwner.name
                                                : selectedSale.subject}
                                            </span>
                                            {/* <span
                                            className="position-absolute cursorPointer"
                                            style={{ right: "0.8rem" }}
                                            onClick={() => clearSelectedItem()}
                                          >
                                            <img
                                              src={listVisibleType ? up : down}
                                              alt="img"
                                              height={20}
                                              width={20}
                                            />
                                          </span> */}
                                          </div>
                                          {/* <span
                                          className="position-absolute end-0"
                                          style={{ marginRight: "-1.6rem" }}
                                        >
                                          <span
                                            className="cursorPointer pb-1 ps-1"
                                            onClick={() => {
                                              fieldValue === "contactName"
                                                ? setIsContactListModal(true)
                                                : fieldValue === "accountName"
                                                ? setIsAccountListModal(true)
                                                : fieldValue === "dealName"
                                                ? setIsDealListModal(true)
                                                : fieldValue === "invoiceOwner"
                                                ? setIsLeadOwnerModal(true)
                                                : setIsSalesListModal(true);
                                            }}
                                          >
                                            <img
                                              src={userSearch}
                                              alt="img"
                                              width={20}
                                              height={20}
                                            />
                                          </span>
                                        </span> */}
                                        </div>
                                        {/* {listVisibleType === fieldValue &&
                                        accountsDropdown("2rem")} */}
                                      </div>
                                    )}
                                  </div>
                                </div>
                              );
                            })}
                        </div>
                      </div>
                    </div>
                    <h5 className="w-100 text-primary px-2 py-2">
                      Address Information
                    </h5>

                    <div className="d-flex justify-content-between">
                      <div className="leadContact_container d-flex gap-4 w-100 ps-2 pe-5">
                        <div className="w-50">
                          {fieldIndividualDropdowns
                            .slice(16, 21)
                            .map((field) => {
                              const fieldValue =
                                field.value ||
                                field.name
                                  .replace(/\s+/g, "")
                                  .replace(/^(.)/, (match) =>
                                    match.toLowerCase()
                                  );
                              return (
                                <div
                                  className="d-flex justify-content-between align-items-center my-2 w-100 gap-3"
                                  key={fieldValue}
                                >
                                  <div className="opacity-75 p-2 w-100">
                                    {field.name}
                                  </div>
                                  <div className="w-100">
                                    <EditableInput
                                      fieldType="text"
                                      className="ps-3"
                                      inputType={fieldValue}
                                      handleIndividualInputChange={
                                        handleIndividualInputChange
                                      }
                                      editingFields={editingFields}
                                      handleEditClick={handleEditClick}
                                      handleCancelClick={handleCancelClick}
                                      handleUpdateClick={handleUpdateClick}
                                      updatedFields={invoiceInfo}
                                      accountOverviewDetails={invoiceInfo}
                                      setShowType={setShowType}
                                      showType={showType}
                                    />
                                  </div>
                                </div>
                              );
                            })}
                        </div>
                        <div className="w-50">
                          {fieldIndividualDropdowns
                            .slice(21, 26)
                            .map((field) => {
                              const fieldValue =
                                field.value ||
                                field.name
                                  .replace(/\s+/g, "")
                                  .replace(/^(.)/, (match) =>
                                    match.toLowerCase()
                                  );
                              return (
                                <div
                                  className="d-flex justify-content-between align-items-center my-2 w-100 gap-3"
                                  key={fieldValue}
                                >
                                  <div className="opacity-75 p-2 w-100">
                                    {field.name}
                                  </div>
                                  <div className="w-100">
                                    <EditableInput
                                      fieldType={"text"}
                                      className="ps-3"
                                      inputType={fieldValue}
                                      handleIndividualInputChange={
                                        handleIndividualInputChange
                                      }
                                      editingFields={editingFields}
                                      handleEditClick={handleEditClick}
                                      handleCancelClick={handleCancelClick}
                                      handleUpdateClick={handleUpdateClick}
                                      updatedFields={invoiceInfo}
                                      accountOverviewDetails={invoiceInfo}
                                      setShowType={setShowType}
                                      showType={showType}
                                    />
                                  </div>
                                </div>
                              );
                            })}
                        </div>
                      </div>
                    </div>

                    <h5 className="w-100 text-primary px-2 pt-2">
                      Invoiced Items
                    </h5>
                    <div
                      className="horizontal-scroll-container mb-2 px-2 border-0 w-100"
                      style={{
                        overflow: "auto",
                      }}
                    >
                      <table
                        className="table w-100 responsive"
                        style={{
                          margin: "0px",
                        }}
                      >
                        <colgroup>
                          <col />
                          <col />
                          <col />
                          <col />
                          <col />
                          <col />
                          <col />
                          <col />
                          <col />
                        </colgroup>
                        <thead>
                          <tr className="mb-2">
                            {/* <th
                              className="border-nonw"
                              style={{
                                backgroundColor: "rgba(243, 246, 251, 1)",
                              }}
                            ></th> */}
                            <th
                              style={{
                                backgroundColor: "#EFEFEF",
                                border: "1px solid rgba(185, 185, 185, 0.5)",
                              }}
                            >
                              SL.NO
                            </th>
                            <th
                              style={{
                                backgroundColor: "#EFEFEF",
                                border: "1px solid rgba(185, 185, 185, 0.5)",
                              }}
                            >
                              Product Name
                            </th>
                            <th
                              style={{
                                backgroundColor: "#EFEFEF",
                                border: "1px solid rgba(185, 185, 185, 0.5)",
                              }}
                            >
                              Quantity
                            </th>
                            <th
                              style={{
                                backgroundColor: "#EFEFEF",
                                border: "1px solid rgba(185, 185, 185, 0.5)",
                              }}
                            >
                              List Price (₹)
                            </th>
                            <th
                              style={{
                                backgroundColor: "#EFEFEF",
                                border: "1px solid rgba(185, 185, 185, 0.5)",
                              }}
                            >
                              Amount (₹)
                            </th>
                            <th
                              style={{
                                backgroundColor: "#EFEFEF",
                                border: "1px solid rgba(185, 185, 185, 0.5)",
                              }}
                            >
                              Discount (₹)
                            </th>
                            <th
                              style={{
                                backgroundColor: "#EFEFEF",
                                border: "1px solid rgba(185, 185, 185, 0.5)",
                              }}
                            >
                              Tax (%)
                            </th>
                            <th
                              style={{
                                backgroundColor: "#EFEFEF",
                                border: "1px solid rgba(185, 185, 185, 0.5)",
                              }}
                            >
                              Total (₹)
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {allProducts.map((row, index) => (
                            <tr key={index}>
                              {/* <td
                                style={{
                                  backgroundColor: "rgba(243, 246, 251, 1)",
                                  border: "none",
                                  alignContent: "center",
                                  padding: "0px",
                                }}
                              ></td> */}
                              <td
                                style={{
                                  color: "#2177FE",
                                }}
                              >
                                {index + 1}
                              </td>
                              <td
                                style={tdStyleObj}
                                className="d-flex flex-column gap-1 w-100"
                              >
                                <span
                                  className="overflow_text"
                                  style={{ maxWidth: "10vw" }}
                                >
                                  {row.productName || "-"}
                                </span>
                                <span className="opacity-50 pt-0">
                                  {row.description || "-"}
                                </span>
                              </td>
                              <td style={tdStyleObj}>{row.quantity}</td>
                              <td style={tdStyleObj}>{row.listPrice}</td>
                              <td style={tdStyleObj}>{row.amount}</td>
                              <td style={tdStyleObj}>{row.discount}</td>
                              <td style={tdStyleObj}>{row.tax}</td>
                              <td style={tdStyleObj}>{row.total}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>

                    <div className="table-responsive rounded-2 d-flex justify-content-end pe-2">
                      <table
                        className="table"
                        style={{
                          borderCollapse: "collapse",
                          width: "26vw",
                          margin: "0",
                          boxShadow: "0px 0px 18.12px 0px rgba(0, 0, 0, 0.03)",
                          background: "rgba(255, 255, 255, 1)",
                        }}
                      >
                        {" "}
                        <thead
                          style={{ width: "max-content" }}
                          key={invoiceInfo?.purchaseId}
                        >
                          <tr className="mb-2">
                            <th
                              scope="col"
                              style={{
                                backgroundColor: "#F8F8F8",
                                width: "max-content",
                              }}
                            >
                              Sub Total(₹)
                            </th>
                            <td style={{ backgroundColor: "#F8F8F8" }}>
                              {invoiceInfo?.subTotal}
                            </td>
                          </tr>
                          <tr>
                            <th scope="col">Discount(₹)</th>
                            <td>{invoiceInfo?.discount}</td>
                          </tr>
                          <tr>
                            <th
                              scope="col"
                              style={{
                                backgroundColor: "#F8F8F8",
                              }}
                            >
                              Tax(%)
                            </th>
                            <td style={{ backgroundColor: "#F8F8F8" }}>
                              {invoiceInfo?.tax}
                            </td>
                          </tr>
                          <tr>
                            <th scope="col">Adjustment(₹)</th>
                            <td>{invoiceInfo?.adjustment}</td>
                          </tr>
                          <tr>
                            <th
                              scope="col"
                              style={{
                                backgroundColor: "#F8F8F8",
                              }}
                            >
                              Grand Total(₹)
                            </th>
                            <td style={{ backgroundColor: "#F8F8F8" }}>
                              {invoiceInfo?.grandTotal}
                            </td>
                          </tr>
                        </thead>
                      </table>
                    </div>
                    <h5 className="w-100 text-primary px-2 pt-2">
                      Terms and Conditions
                    </h5>
                    <div className="d-flex gap-2 w-75">
                      <span className="opacity-75 px-2 pb-2 w-50">
                        Terms and Conditions
                      </span>

                      <EditableInput
                        fieldType="text"
                        inputType="termsAndConditions"
                        handleIndividualInputChange={
                          handleIndividualInputChange
                        }
                        editingFields={editingFields}
                        handleEditClick={handleEditClick}
                        handleCancelClick={handleCancelClick}
                        handleUpdateClick={handleUpdateClick}
                        updatedFields={invoiceInfo}
                        accountOverviewDetails={invoiceInfo}
                        showType={showType}
                        setShowType={setShowType}
                        isTextarea={true}
                      />
                    </div>
                    <h5 className="w-100 text-primary px-2 pt-2">
                      Description
                    </h5>
                    <div className="d-flex gap-2 mb-2 w-75">
                      <span className="opacity-75 px-2 pb-2 w-50">
                        Description
                      </span>
                      <EditableInput
                        fieldType="text"
                        inputType="description"
                        handleIndividualInputChange={
                          handleIndividualInputChange
                        }
                        editingFields={editingFields}
                        handleEditClick={handleEditClick}
                        handleCancelClick={handleCancelClick}
                        handleUpdateClick={handleUpdateClick}
                        updatedFields={invoiceInfo}
                        accountOverviewDetails={invoiceInfo}
                        showType={showType}
                        setShowType={setShowType}
                        isTextarea={true}
                      />
                    </div>
                  </div>
                  {notePermission === "TRUE" && (
                    <div
                      id="notesSection"
                      className="lead_Information_mainContainer commonContainer_bg rounded-2  d-flex flex-column py-2 mt-2"
                    >
                      <div>
                        <Note
                          note={note}
                          addTitle={addTitle}
                          showButtons={showButtons}
                          setShowButtons={setShowButtons}
                          textFile={selectedNoteFiles}
                          showTitleNote={showTitleNote}
                          setShowTitleNote={setShowTitleNote}
                          handleTextAreaClick={() => setShowButtons(true)}
                          handleTextFileChange={handleTextFileChange}
                          handleSaveButton={insertNote}
                          handleAddTitleClick={() => setShowTitleNote(true)}
                          setNoteTitle={setNoteTitle}
                          noteInfo={noteInfo}
                          selectRecent={selectRecent}
                          handleSelectRecent={handleSelectRecent}
                          handleDeleteClick={deleteNoteDetails}
                          leadId={invoiceId}
                          handleUpdateClick={updateNote}
                          setUpdateNotes={setUpdateNotes}
                          updateNotes={updateNotes}
                          setEditNote={setEditNote}
                          editNote={editNote}
                          handleChangeNoteTitle={handleChangeNoteTitle}
                          handleChangeNoteDescription={
                            handleChangeNoteDescription
                          }
                          setAddTitle={setAddTitle}
                          setNote={setNote}
                          moduleType={"Invoice"}
                          recordName={
                            singleInvoiceDetails?.getinvoiceInfo?.subject
                          }
                          selectedNoteFiles={selectedNoteFiles}
                          setSelectedNoteFiles={setSelectedNoteFiles}
                          onChildDataChange={handleChildDataChange}
                          noteDeleteConfirmation={noteDeleteConfirmation}
                          setNoteDeleteConfirmation={setNoteDeleteConfirmation}
                        />
                        {showTitleNote && (
                          <textarea
                            rows={2}
                            className="notesClass px-2"
                            placeholder="Add note Title"
                            value={noteTitle}
                            onChange={(e) => setNoteTitle(e.target.value)}
                            style={{ width: "95%", marginTop: "10px" }}
                          />
                        )}
                      </div>
                    </div>
                  )}

                  <div
                    id="openActivity"
                    className="lead_Information_mainContainer commonContainer_bg rounded-2  d-flex flex-column py-2 mt-2"
                  >
                    <OpenActivity
                      openActivities={openActivities}
                      // getOverview={() => accountOverviewDetail(accountId)}
                      openActivitiesCalls={openActivitiesCalls}
                      openActivitiesMeetings={openActivitiesMeetings}
                      moduleName="Invoices"
                    />
                  </div>

                  <div
                    id="closeActivity"
                    className="lead_Information_mainContainer commonContainer_bg rounded-2  d-flex flex-column py-2 mt-2"
                  >
                    <CloseActivity
                      closeActivities={closeActivities}
                      // getOverview={() => accountOverviewDetail(accountId)}
                      closeActivitiesCalls={closedActivitiesCall}
                      closeActivitiesMeetings={closedActivitiesMeetings}
                    />
                  </div>
                </div>
              </div>
            ) : (
              <>
                <div
                  className={`timeline_main_container rounded-2 bg-white d-flex flex-column gap-4 ${
                    isShrinked ? "w-100" : "w-75"
                  }`}
                >
                  {/* <div className="upcomingAction_container w-100 flex-column h-25 py-2  d-flex  commonContainer_bg rounded-2">
                    <div className="header d-flex gap-2 p-2">
                      <span>
                        <img src={hierachy} alt="img" height={20} width={20} />
                      </span>
                      <span>Upcoming Actions</span>
                    </div>
                    <div className="horizontal_line w-100  mx-1 border border-white"></div>

                    <div
                      className="d-flex justify-content-center align-items-center "
                      style={{ height: "20vh" }}
                    >
                      <h4 className="opacity-75 ">No upcoming actions</h4>
                    </div>
                  </div> */}

                  <div className="w-100 historyMain_Container flex-column   d-flex  commonContainer_bg rounded-2 ">
                    <div className="header d-flex gap-2 px-2 py-2 align-items-center">
                      <span>
                        <img src={history} alt="img" height={20} width={20} />
                      </span>
                      <span>History</span>
                    </div>
                    <div className="horizontal_line w-100 mx-1"></div>
                    <div className="historyContent_mainContainer ">
                      {Object.keys(timeLineDataList).length > 0 ? (
                        Object.keys(timeLineDataList).map((date) => (
                          <div
                            className="history_container pt-3 d-flex flex-column"
                            key={date}
                          >
                            <span className="px-2 timelineBg_color border border-primary rounded-5 py-2 mx-5">
                              {date}
                            </span>
                            {timeLineDataList[date].map((item, index) => (
                              <div className="d-flex gap-2" key={index}>
                                <span className="opacity-75 pt-5 ps-2">
                                  {item.createdTime}
                                </span>
                                <div className="d-flex flex-column align-items-center">
                                  <p className="vertical_line m-0 p-0"></p>
                                  <span>
                                    <img
                                      src={groupIcon2}
                                      alt="img"
                                      height={25}
                                      width={25}
                                    />
                                  </span>
                                </div>
                                <span className="pt-5">
                                  {`${item.tmlDescription} ${item.tmlCreatedBy} ${date}`}
                                </span>
                              </div>
                            ))}
                          </div>
                        ))
                      ) : (
                        <div
                          className="text-center d-flex justify-content-center align-items-center opacity-75"
                          style={{ height: "20vh" }}
                        >
                          <h4 className="h-75">No Data available</h4>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </>
    );
  };

  const handleInvoiceView = () => {
    if (innerPage === "list") {
      return (
        <>
          {isLoading ? (
            <>
              <Loader />
            </>
          ) : (
            invoiceHomePage()
          )}
        </>
      );
    } else if (["create", "clone"].includes(innerPage)) {
      return <>{createInvoicePage()}</>;
    } else if (innerPage === "overview") {
      return <>{individualInvoice()}</>;
    }
  };
  return (
    <div
      onClick={() => {
        setListVisibleType("");
        setShowHeadings(false);
        setShowSelectedFilter("");
        setSelectedField("");
        setShowOptionsInvoice(false);
      }}
    >
      {handleInvoiceView()}

      {/* Modals */}
      <Modal
        show={modalType === "Manage Columns"}
        id="ConvertModal"
        aria-labelledby="example-custom-modal-styling-title"
        className="withdrw_modal"
      >
        <Modal.Body>
          <div className="modalClass  position-relative">
            <div className="d-flex justify-content-between ">
              <h4 className="">Manage Columns</h4>
            </div>
            <div className="w-100 position-relative">
              <div className="mb-1 pt-2 position-relative w-100">
                <input
                  type="text"
                  className="assetSearchbar searchbar rounded-2 p-2 pe-5 common_input_bg w-100 "
                  placeholder="Search"
                  value={searchColumn}
                  onChange={(e) => {
                    if (e.target.value.charAt(0) !== " ") {
                      const value = e.target.value.replace(
                        /[^a-zA-Z ]+|(\s)\s+/g,
                        "$1"
                      );
                      setSearchColumn(value);
                    }
                  }}
                />
                <span
                  className="position-absolute  mt-2 me-3 end-0 opacity-75 cursorPointer"
                  onClick={() => {
                    setSearchColumn("");
                    setSavingHeadings(invoiceColumns);
                  }}
                >
                  {searchColumn === "" ? (
                    <img src={searchIcon} alt="" width={20} />
                  ) : (
                    "X"
                  )}
                </span>
              </div>
              <div className=" opacity-75 columns_container">
                {savingHeadings
                  .filter((lead) =>
                    lead.name
                      .toLowerCase()
                      .includes(searchColumn.trim().toLowerCase())
                  )
                  .sort((a, b) => a.name.localeCompare(b.name))
                  .map((column) => {
                    return (
                      <div
                        key={column.name}
                        className="d-flex gap-2 py-2 rounded-2 align-items-center"
                      >
                        <div className="d-flex gap-2 ps-2">
                          <input
                            type="checkbox"
                            id={column.name}
                            name={column.name}
                            checked={column.isChecked}
                            onChange={(e) => {
                              handleColumnCheckboxChange(
                                column.name,
                                e.target.checked
                              );
                            }}
                            className="cursorPointer"
                          />
                          <label
                            className="small cursorPointer"
                            htmlFor={column.name}
                          >
                            {column.name}
                          </label>
                        </div>
                      </div>
                    );
                  })}
              </div>
              <aside className="float-end mt-2">
                <div className="d-flex align-items-center gap-4">
                  <button
                    className="cancel_btn bg-light"
                    onClick={() => {
                      setShowModalType("");
                    }}
                  >
                    Cancel
                  </button>
                  <button
                    className="save_btn"
                    onClick={() => {
                      setSelectedHeadings(savingHeadings);
                      setTimeout(() => {
                        setShowModalType("");
                      }, 200);
                    }}
                  >
                    Save
                  </button>
                </div>
              </aside>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <ShareModal
        moduleType="INVOICES"
        setShareModal={setShareModal}
        shareModal={shareModal}
        id={invoiceId}
        modalHeading={"Sharing Invoice"}
        shareUserList={shareUserList}
        shareGroupList={shareGroupList}
        shareRoleList={shareRoleList}
        shareMemberIds={shareMemberIds}
        shareInfoModal={shareInfoModal}
        sharePublicList={sharePublicList}
        setShareInfoModal={setShareInfoModal}
        getOverview={getInvoiceOverview}
        module={"Invoices"}
      />

      {/* mass delete modal */}
      <Modal
        show={[
          "delete",
          "Mass Delete",
          "Mass Update",
          "Mass Transfer",
        ].includes(modalType)}
        aria-labelledby="contained-modal-title-vcenter"
        onClick={(e) => e.stopPropagation()}
        // size={showModalType !== "delete" ? "md" : "lg"}
      >
        <Modal.Header
          className=" border-0 mb-0 d-flex flex-column align-items-start"
          style={{ backgroundColor: modalType !== "delete" && "#F2F2F2" }}
        >
          {modalType !== "delete" && (
            <div
              className={`mass_heading fs-4 ${
                modalType !== "Mass Delete" && "ps-4"
              }`}
            >
              {modalType}
            </div>
          )}
          {modalType === "delete" && (
            <div className="fs-5" style={{ color: "#3F7DFF" }}>
              Are you sure you want to delete {invoiceInfo?.subject}?
            </div>
          )}
        </Modal.Header>
        <Modal.Body className="ModalBody">
          {modalType === "delete" ? (
            <>
              <div className="d-flex gap-2 pb-2 align-items-center">
                <span className="fw-bold">Note:</span>
                <span className="small pt-1">
                  Any associated activities, visits, drafts will also be
                  deleted.
                </span>
              </div>
            </>
          ) : modalType === "Mass Delete" ? (
            <>
              <div className="fs-5 pt-0 pb-2">
                Are you sure you want to delete selected item(s)?
              </div>
              <div className="small">
                <span className="fw-bold">Note: </span>
                <span>
                  Any associated Activities, Drafts will also be Deleted
                </span>
              </div>
            </>
          ) : modalType === "Mass Update" ? (
            <div className="d-flex gap-2 mb-3 mt-2 align-items-center w-100">
              <div className="w-100">
                <div className="d-flex flex-column ps-1 align-items-center mx-4">
                  <label className="text-start w-100 opacity-75">
                    Select a field
                  </label>
                  <div
                    className="position-relative w-100 rounded-1"
                    style={{ border: "1px solid rgba(185, 185, 185, 0.5)" }}
                  >
                    <div
                      onClick={(e) => {
                        e.stopPropagation();
                        setListVisible(!listVisible);
                        setSearchField("");
                      }}
                      className={`cursorPointer d-flex justify-content-between w-100 my-1 p-1 border-0`}
                    >
                      <span className="">{selectedObj.name}</span>
                      <img
                        src={listVisible ? up : down}
                        alt="img"
                        height={20}
                        width={20}
                        className="pt-1 cursorPointer"
                      />
                    </div>
                    {listVisible && (
                      <div
                        className="position-absolute field_container w-100"
                        onClick={(e) => {
                          e.stopPropagation();
                        }}
                      >
                        <div className="">
                          <label
                            htmlFor="selectField"
                            className="position-relative w-100"
                          >
                            <input
                              id="selectField"
                              type="text"
                              className="rounded-2 p-1 w-100 pe-4"
                              placeholder="Search"
                              value={searchField}
                              onChange={(e) => {
                                if (e.target.value.charAt(0) !== " ") {
                                  const value = e.target.value.replace(
                                    /[^a-zA-Z ]+|(\s)\s+/g,
                                    "$1"
                                  );
                                  setSearchField(value);
                                }
                              }}
                            />
                            <img
                              className="position-absolute mt-1 me-2 end-0 pt-1 cursorPointer"
                              onClick={() => {
                                setSearchField("");
                              }}
                              src={searchIcon}
                              alt="search"
                              width={20}
                            />
                          </label>

                          <div className="w-100 rounded-2">
                            <div className="border border-1 field_container_dropdown rounded-1">
                              {showSearchFields
                                .filter((field) =>
                                  field.name
                                    .toLowerCase()
                                    .includes(searchField.trim().toLowerCase())
                                )
                                .sort((a, b) => a.name.localeCompare(b.name))
                                .map(
                                  (field) =>
                                    ![
                                      "Invoice Owner",
                                      "Deal Name",
                                      "Account Name",
                                      "Contact Name",
                                      "Sales Order",
                                      "Grand Total",
                                      "Invoice Number",
                                      "Hash",
                                      // "Due Date",
                                      // "Invoice Date",
                                    ].includes(field.name) && (
                                      <div
                                        key={field.name}
                                        className="d-flex gap-2 p-2 cursorPointer rounded-2 align-items-center"
                                        onClick={() => {
                                          setClickedDropdownObj({
                                            label: "",
                                            value: "",
                                          });
                                          setMassUpdateValue("");
                                          setSelectedObj(field);
                                          setListVisible(false);
                                        }}
                                      >
                                        <span className="ps-2">
                                          {field.name}
                                        </span>
                                      </div>
                                    )
                                )}
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="w-100 mx-auto d-flex justify-content-center mt-4">
                    <div className="w-100">
                      <label className="opacity-75" htmlFor="massValue">
                        Enter value
                      </label>
                      <div
                        className="position-relative w-100 border rounded-1"
                        style={{ border: "1px solid rgba(185, 185, 185, 0.5)" }}
                      >
                        {["Status"].includes(selectedObj.name) ? (
                          <Select
                            value={clickedDropdownObj}
                            menuPlacement="auto"
                            onChange={(selectedOption) => {
                              setClickedDropdownObj(selectedOption);
                              setMassUpdateValue(selectedOption.value);
                            }}
                            options={stausOptions}
                            styles={fieldDropdownStyles}
                            isSearchable={false}
                          />
                        ) : (
                          <input
                            className="w-100 border-0 py-2 ps-2 rounded-1"
                            type={`${
                              ["Due Date", "Invoice Date"].includes(
                                selectedObj.name
                              )
                                ? "date"
                                : "text"
                            }`}
                            value={massUpdateValue}
                            onChange={(e) => {
                              // const trimmedValue = e.target.value?.replace(
                              //   /\s+/g,
                              //   " "
                              // );
                              const trimmedValue = e.target.value
                                ?.replace(/\s{2,}/g, " ")
                                .trimStart();
                              if (
                                [
                                  "Billing Code",
                                  "Excise Duty",
                                  "Sales Commision",
                                  "Shipping Code",
                                ].includes(selectedObj.name)
                              ) {
                                setMassUpdateValue(
                                  trimmedValue.replace(/[^0-9.]+/g, "")
                                );
                              } else if (
                                [
                                  "Billing Street",
                                  "Subject",
                                  "Shipping Street",
                                  "Billing State",
                                  "Shipping State",
                                  "Billing Country",
                                  "Shipping Country",
                                  "Shipping City",
                                  "Billing City",
                                ].includes(selectedObj.name)
                              ) {
                                setMassUpdateValue(trimmedValue);
                              } else {
                                setMassUpdateValue(
                                  !["Invoice Date", "Due Date"].includes(
                                    selectedObj.name
                                  )
                                    ? trimmedValue.replace(/[^a-zA-Z]+/g, "")
                                    : e.target.value
                                );
                              }
                            }}
                            style={{ outline: "none" }}
                            id="massValue"
                            min={minDate}
                            max={maxDate}
                          />
                        )}
                      </div>
                      {errorMessage && (
                        <span className="text-danger text-center pt-1">
                          {errorMessage}
                        </span>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="w-100 px-4 py-2 mb-1">
              <label className="text-start w-100 opacity-75">Transfer To</label>
              <div
                className="d-flex input-with-clear position-relative mx-auto"
                onClick={(e) => {
                  handleOwnerInputClick(e);
                }}
              >
                <input
                  type="text"
                  className="ps-3 w-100 cursorPointer py-2"
                  value={selectedOwner.name}
                  readOnly
                />
                <span
                  className="position-absolute h-100 mass_transfer_user pt-1"
                  style={{ right: "0.1rem" }}
                >
                  <span
                    className="cursorPointer px-1"
                    onClick={() => setIsLeadOwnerModal(!isLeadOwnerModal)}
                  >
                    <img
                      src={userSearch}
                      alt="img"
                      width={20}
                      height={20}
                      className="ms-2 mt-2 me-2"
                    />
                  </span>
                </span>
              </div>
            </div>
          )}

          <div className={`pt-4  ${modalType !== "delete" && "pe-3"}`}>
            <div className="d-flex justify-content-end w-100 gap-4 pe-2">
              <button
                className="cancelButton px-4"
                onClick={() => {
                  setShowModalType("");
                  // setSelectedCheckboxes([]);
                  setShowSearchFields(fieldDropdowns);
                }}
              >
                Cancel
              </button>
              <button
                className="createContactButtonSave"
                onClick={() => {
                  if (modalType === "delete") {
                    deleteInvoice();
                  } else {
                    deleteUpdateTransferMass();
                  }
                }}
                // disabled={
                //   massUpdateValue.length < 2 ||
                //  / !["Mass Update"].includes(modalType)
                // }
              >
                {modalType === "Mass Update"
                  ? "Update"
                  : modalType === "Mass Transfer"
                  ? "Done"
                  : "Delete"}
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <LeadOwnerModal
        isLeadOwnerModal={isLeadOwnerModal}
        setIsLeadOwnerModal={setIsLeadOwnerModal}
        onSelectedLeadChange={(value) => {
          setSelectedOwner(value);
        }}
        defaultUser={selectedOwner}
      />
      <Modal
        show={isDealListModal}
        className="d-flex justify-content-center align-items-center"
        aria-labelledby="example-custom-modal-styling-title"
        size="lg"
        id="commonLeadModal"
      >
        <Modal.Body className="ModalBody  overflow-auto">
          <div
            className="modalUS row justify-content-center"
            style={{ margin: "0", gap: "10px", paddingBottom: "10px" }}
          >
            <div className="header_Container d-flex justify-content-between">
              <h4 className="commonTextColor">Change Deal</h4>
              <div className="d-flex justify-content-start">
                <div className="inputContainer d-flex justify-content-start">
                  <input
                    type="text"
                    className="inputBox px-5 mb-1 w-75 py-1"
                    style={{ outline: "none" }}
                    placeholder="Search users"
                    value={searchDeal}
                    onChange={handleSearchDeal}
                  />
                  <img
                    src={SearchIcon}
                    alt="search"
                    className="searchIcon"
                    style={{ width: "20px" }}
                  />
                </div>
              </div>
            </div>

            <div className="Owner_table_container border border-dark rounded-2 h-25">
              <div className="table-wrapper">
                <table className="userdata-table">
                  <thead>
                    <tr>
                      <th>Deal Name</th>
                      <th>Closing Date</th>
                      <th>Stage</th>
                      <th>Lead Source</th>
                      <th>Contact Name</th>
                    </tr>
                  </thead>
                  <tbody className="table-body tableUserData">
                    {filteredDeals?.map((item, index) => (
                      <tr
                        key={index}
                        onClick={() => {
                          setSelectedDeal(item);
                        }}
                      >
                        <td>{item.dealName}</td>
                        <td>{item.closingDate}</td>
                        <td>{item.stage}</td>
                        <td>{item.leadSource}</td>
                        <td>{item.contactName}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
            <div className="d-flex w-100 align-items-center justify-content-end gap-2">
              {filteredDeals.length > 0 && (
                <>
                  <Select
                    options={pageOption}
                    value={pageOption.find(
                      (option) => option.value === perPageDeal.toString()
                    )}
                    onChange={handlePageDropDownChangeDeal}
                    isSearchable={false}
                    styles={customCommonStyles("225px")}
                  />
                  <Pagination
                    currentPage={currentPageDeal}
                    totalPages={totalPagesDeal}
                    onPageChange={handlepageChangeDeal}
                  />
                </>
              )}
            </div>
            <div className="d-flex pb-2 pt-3 justify-content-between">
              <div className="selectedUser d-flex gap-1">
                <span>Selected User</span>
                <img src={logoConvert} alt="img" height={20} width={20} />
                <span>{selectedDeal.dealName}</span>
              </div>
              <div className="d-flex gap-2">
                <button
                  className="btn btn-outline-secondary border border-primary btn-sm px-3 py-2 text-dark"
                  onClick={() => {
                    setIsDealListModal(false);
                    setSelectedDeal({});
                  }}
                >
                  Cancel
                </button>
                <button
                  className="btn btnColor btn-sm px-3 text-white"
                  onClick={() => {
                    setIsDealListModal(false);
                    getAllAssignModules(
                      "DEALS",
                      ["CONTACTS", "ACCOUNTS"],
                      selectedDeal.dealId
                    );
                  }}
                >
                  Done
                </button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={isContactListModal}
        className="d-flex justify-content-center align-items-center"
        aria-labelledby="example-custom-modal-styling-title"
        size="lg"
        id="commonLeadModal"
      >
        <Modal.Body className="ModalBody  overflow-auto">
          <div
            className="modalUS row justify-content-center"
            style={{ margin: "0", gap: "10px", paddingBottom: "10px" }}
          >
            <div className="header_Container d-flex justify-content-between">
              <h4 className="commonTextColor pt-2">Change Contact</h4>
              <div className="inputContainer d-flex justify-content-start">
                <input
                  type="text"
                  placeholder="Search"
                  className="topBarModalSearch px-5"
                  onChange={handleSearchContact}
                  value={searchContact}
                />
                <img src={SearchIcon} alt="search" className="searchIcon" />
              </div>
            </div>

            <div className="Owner_table_container border border-dark rounded-2 h-25">
              <div className="table-wrapper">
                <table className="userdata-table">
                  <thead>
                    <tr>
                      <th style={{ width: "20%" }}>Contact Name</th>
                      <th style={{ width: "20%" }}>Account Name</th>
                      <th style={{ width: "20%" }}>Email</th>
                      <th style={{ width: "20%" }}>Phone</th>
                      <th style={{ width: "20%" }}>Contact Owner</th>
                    </tr>
                  </thead>
                  <tbody className="table-body tableUserData">
                    {filteredContacts?.map((item, index) => (
                      <tr
                        key={index}
                        onClick={() => {
                          setSelectedContact(item);
                        }}
                      >
                        <td style={{ width: "20%" }}>{item.contactName}</td>
                        <td style={{ width: "20%" }}>{item.accountName}</td>
                        <td style={{ width: "20%" }}>{item.emailId}</td>
                        <td style={{ width: "20%" }}>{item.phone}</td>
                        <td style={{ width: "20%" }}>{item.contactOwner}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
            <div className="d-flex w-100 align-items-center justify-content-end gap-2">
              {filteredContacts.length > 0 && (
                <>
                  <Select
                    options={pageOption}
                    styles={customSelectLocationStyles}
                    // value={selectPageOption}
                    value={pageOption.find(
                      (option) => option.value === perPageContact.toString()
                    )}
                    onChange={handlePageDropDownChangeContact}
                  />
                  {/* <p className="mb-0">· ‹1/10›</p> */}

                  <Pagination
                    currentPage={currentPageContact}
                    totalPages={totalPagesContact}
                    onPageChange={(page) => {
                      setCurrentPageContact(page);
                    }}
                  />
                </>
              )}
            </div>
            <div className="d-flex pb-2 pt-3 justify-content-between">
              <div className="selectedUser d-flex gap-1">
                <span>Selected User</span>
                <img src={logoConvert} alt="img" height={20} width={20} />
                <span>{selectedContact.contactName}</span>
              </div>

              <div className="d-flex gap-2">
                <button
                  className="btn btn-outline-secondary border border-primary btn-sm px-3 py-2 text-dark"
                  onClick={() => {
                    setIsContactListModal(false);
                    setSelectedContact({});
                  }}
                >
                  Cancel
                </button>
                <button
                  className="btn btnColor btn-sm px-3 text-white"
                  onClick={() => {
                    setIsContactListModal(false);
                    getAllAssignModules(
                      "CONTACTS",
                      ["DEALS", "ACCOUNTS"],
                      selectedContact.contactId
                    );
                  }}
                >
                  Done
                </button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        show={isAccountListModal}
        className="d-flex justify-content-center align-items-center"
        aria-labelledby="example-custom-modal-styling-title"
        size="lg"
        id="commonLeadModal"
      >
        <Modal.Body className="ModalBody  overflow-auto">
          <div
            className="modalUS row justify-content-center"
            style={{ margin: "0", gap: "10px", paddingBottom: "10px" }}
          >
            <div className="header_Container d-flex justify-content-between">
              <h4 className="commonTextColor pt-2">Change Account</h4>
              <div className="inputContainer d-flex justify-content-start">
                <input
                  type="text"
                  placeholder="Search"
                  className="topBarModalSearch px-5"
                  onChange={handleSearchAccount}
                  value={searchAccount}
                />
                <img src={SearchIcon} alt="search" className="searchIcon" />
              </div>
            </div>

            <div className="Owner_table_container border border-dark rounded-2 h-25">
              <div className="table-wrapper">
                <table className="userdata-table">
                  <thead>
                    <tr>
                      <th>Account Name</th>
                      <th>Phone</th>
                      <th>Website</th>
                      <th>Account Owner</th>
                    </tr>
                  </thead>
                  <tbody className="table-body tableUserData">
                    {filteredAccount?.map((item, index) => (
                      <tr
                        key={index}
                        onClick={() => {
                          setSelectedAccount(item);
                        }}
                      >
                        <td>{item.accountName}</td>
                        <td>{item.phone}</td>
                        <td>{item.website}</td>
                        <td>{item.accountOwner}</td>
                      </tr>
                    ))}
                    {filteredAccount &&
                      filteredAccount.length > 1 &&
                      filteredAccount?.map((item, index) => (
                        <tr
                          key={index}
                          onClick={() => {
                            setSelectedAccount(item);
                          }}
                        >
                          <td>{item.accountName}</td>
                          <td>{item.phone}</td>
                          <td>{item.website}</td>
                          <td>{item.accountOwner}</td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            </div>
            <div className="d-flex w-100 align-items-center justify-content-end gap-2">
              {filteredAccount.length > 0 && (
                <>
                  <Select
                    options={pageOption}
                    styles={customSelectLocationStyles}
                    // value={selectPageOption}
                    value={pageOption.find(
                      (option) => option.value === perPageAccount.toString()
                    )}
                    onChange={handlePageDropDownChangeAccount}
                  />
                  {/* <p className="mb-0">· ‹1/10›</p> */}

                  <Pagination
                    currentPage={currentPageAccount}
                    totalPages={totalPagesAccount}
                    onPageChange={(page) => {
                      setCurrentPageAccount(page);
                    }}
                  />
                </>
              )}
            </div>
            <div className="d-flex pb-2 pt-3 justify-content-between">
              <div className="selectedUser d-flex gap-1">
                <span>Selected User</span>
                <img src={logoConvert} alt="img" height={20} width={20} />
                <span>{selectedAccount.accountName}</span>
              </div>
              <div className="d-flex gap-2">
                <button
                  className="btn btn-outline-secondary border border-primary btn-sm px-3 py-2 text-dark"
                  onClick={() => {
                    setIsAccountListModal(false);
                    setSelectedAccount({});
                  }}
                >
                  Cancel
                </button>
                <button
                  className="btn btnColor btn-sm px-3 text-white"
                  onClick={() => {
                    setIsAccountListModal(false);
                    getAllAssignModules(
                      "ACCOUNTS",
                      ["DEALS", "CONTACTS"],
                      selectedAccount.accountId
                    );
                  }}
                >
                  Done
                </button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      {/* <SalesOrderListModal
        isSalesListModal={isSalesListModal}
        setIsSalesListModal={setIsSalesListModal}
        onSelectedSalesChange={(value) => {
          setSelectedSale(value);
        }}
        defaultUser={selectedSale}
      /> */}

      <Modal
        show={isSalesListModal}
        className="d-flex justify-content-center align-items-center"
        aria-labelledby="example-custom-modal-styling-title"
        size="lg"
        id="commonLeadModal"
      >
        <Modal.Body className="ModalBody  overflow-auto">
          <div
            className="modalUS row justify-content-center"
            style={{ margin: "0", gap: "10px", paddingBottom: "10px" }}
          >
            <div className="header_Container d-flex justify-content-between">
              <h4 className="commonTextColor pt-2">Change Account</h4>
              <div className="inputContainer d-flex justify-content-start">
                <input
                  type="text"
                  placeholder="Search"
                  className="topBarModalSearch px-5"
                  onChange={handleSearchSalesOrder}
                  value={searchSalesOrder}
                />
                <img src={SearchIcon} alt="search" className="searchIcon" />
              </div>
            </div>

            <div className="Owner_table_container border border-dark rounded-2 h-25">
              <div className="table-wrapper">
                <table className="userdata-table">
                  <thead>
                    <tr>
                      <th>Subject</th>
                      <th>Status</th>
                      <th>GrandTotal</th>
                      <th>Deal Name</th>
                    </tr>
                  </thead>
                  <tbody className="table-body tableUserData">
                    {filteredSalesOrder?.map((item, index) => (
                      <tr
                        key={index}
                        // onClick={() => {
                        //   setSelectedSales(item);
                        // }}
                      >
                        <td>{item.subject}</td>
                        <td>{item.status}</td>
                        <td>{item.grandTotal}</td>
                        <td>{item.dealName}</td>
                      </tr>
                    ))}
                    {filteredSalesOrder &&
                      filteredSalesOrder.length > 1 &&
                      filteredSalesOrder?.map((item, index) => (
                        <tr
                          key={index}
                          onClick={() => {
                            setSelectedSale(item);
                          }}
                        >
                          <td>{item.subject}</td>
                          <td>{item.status}</td>
                          <td>{item.grandTotal}</td>
                          <td>{item.dealName}</td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            </div>
            <div className="d-flex w-100 align-items-center justify-content-end gap-2">
              {filteredSalesOrder.length > 0 && (
                <>
                  <Select
                    options={pageOption}
                    styles={customSelectLocationStyles}
                    // value={selectPageOption}
                    value={pageOption.find(
                      (option) => option.value === perPageSalesOrder.toString()
                    )}
                    onChange={handlePageDropDownChangeSalesOrder}
                  />
                  {/* <p className="mb-0">· ‹1/10›</p> */}

                  <Pagination
                    currentPage={currentPageSalesOrder}
                    totalPages={totalPagesSalesOrder}
                    onPageChange={(page) => {
                      setCurrentPageSalesOrder(page);
                    }}
                  />
                </>
              )}
            </div>
            <div className="d-flex pb-2 pt-3 justify-content-between">
              <div className="selectedUser d-flex gap-1">
                <span>Selected User</span>
                <img src={logoConvert} alt="img" height={20} width={20} />
                <span>{selectedSale.subject}</span>
              </div>
              <div className="d-flex gap-2">
                <button
                  className="btn btn-outline-secondary border border-primary btn-sm px-3 py-2 text-dark"
                  onClick={() => {
                    setIsSalesListModal(false);
                    setSelectedSale({});
                  }}
                >
                  Cancel
                </button>
                <button
                  className="btn btnColor btn-sm px-3 text-white"
                  onClick={() => {
                    setIsSalesListModal(false);
                  }}
                >
                  Done
                </button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <AccessModal
        setShowAccessModal={setShowAccessModal}
        showAccessModal={showAccessModal}
      />

      <NotificationContainer />
    </div>
  );
}

export default Invoices;

import "./reports.css";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import axios from "axios";
import { URI } from "../../constants";
import { camelCaseToSpace } from "../../common/CommonData";
import filterUp from "../../Assets/filterUp.svg";
import filterDown from "../../Assets/filterDown.svg";
import filterNormal from "../../Assets/filterNormal.svg";
import getHeaders from "../../common/ApiHeader";
import { useNavigate } from "react-router-dom";

function Reports() {
  const leftArrow = (
    <svg
      width="28"
      height="13"
      viewBox="0 0 31 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.49963 9.00073L30.9996 9.00027"
        stroke="#515151"
        strokeWidth="2.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.49963 1.5L2.06029 7.93934C1.56029 8.43934 1.31029 8.68934 1.31029 9C1.31029 9.31066 1.56029 9.56066 2.06029 10.0607L8.49963 16.5"
        stroke="#515151"
        strokeWidth="2.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );

  const leadReports = [
    {
      name: [
        "Todays Leads",
        "Leads by Status",
        "Leads by Source",
        "Leads by Ownership",
        "Leads by Industry",
        "Converted Leads",
      ],
      description: [
        "Leads obtained today",
        "Leads and their statuses",
        "Leads from various sources",
        "Leads by Owner",
        "Leads by industry",
        "Leads converted to Account / Deal / Contact",
      ],
    },
  ];

  const dealReports = [
    {
      name: [
        "Today's Sales",
        "Stage Vs Deal Type Report",
        "Salesperson's Performance Report",
        "Sales by Lead Source",
        "Sales This Month",
        "Pipeline by Stage",
        "Pipeline by Probability",
        "Open Deals",
        "Lost Deals",
        "Deals by Type",
        "Deals Closing this Month",
      ],
      description: [
        "Today's Sales",
        "Summarizes the various stages of New and Existing Business",
        "Deals gained by salesperson",
        "Sales gained from various Lead Sources",
        "This Month's Sales",
        "Deals by Stage",
        "Deals by Probability",
        "Deals Pending",
        "Deals Lost",
        "Deals by Type",
        "Deals closing this month",
      ],
    },
  ];

  const productReports = [
    {
      name: [
        "Products by Support Termination Date",
        "Products by Category",
        "Products by Cases",
      ],
      description: [
        "Products ending support this month",
        "Products based on category",
        "Products and Cases",
      ],
    },
  ];

  const vendorReports = [
    {
      name: ["Vendors vs Purchases", "Amount by Vendors"],
      description: ["Vendors Based on Purchases", "Vendors and Purchases"],
    },
  ];

  const quoteReports = [
    {
      name: ["Quotes by Stage", "Quotes by Accounts"],
      description: ["Quote Based on Stages", "Quote Based on Accounts"],
    },
  ];

  const salesReports = [
    {
      name: [
        "Sales Orders by Status",
        "Sales Orders by Owner",
        "Sales Orders by Accounts",
      ],
      description: [
        "Sales Orders Based on Status",
        "Sales Orders and their Owners.",
        "Sales Orders Based on Accounts",
      ],
    },
  ];

  const purchaseReports = [
    {
      name: ["Purchase Orders by Status", "Contacts vs Purchases"],
      description: [
        "Purchase Orders Based on Status",
        "Contacts Based on Purchases",
      ],
    },
  ];

  const invoiceReports = [
    {
      name: ["Invoices by Status", "Invoices by Accounts"],
      description: ["Invoice Based on Status", "Invoices Based on Accounts"],
    },
  ];

  const [activeTab, setActiveTab] = useState(dealReports);
  const [reportValue, setReportValue] = useState("Deals");
  const [heading, setHeading] = useState("Deal Reports");
  const [reportsView, setReportsView] = useState("Main Page");
  const [tableViewHeading, setTableViewHeading] = useState("");
  const [headers, setHeaders] = useState([]);
  const [clmHeaders, setClmHeaders] = useState([]);
  const [tableData, setTableData] = useState([]);
  const reduxStore = useSelector((state) => state.reduxState);
  const { loginUserId } = reduxStore;
  const [sortBy, setSortBy] = useState({ column: null, order: "asc" });
  const navigate = useNavigate();

  const getReports = async (moduleType, reportingType) => {
    const requestBody = {
      loggedInUserId: loginUserId,
      reportingType: reportingType.toLowerCase(),
      moduleType: moduleType,
    };

    const headersResult = getHeaders(true);

    // Check if headers result indicates an error
    if (headersResult.error) {
      navigate("../"); // Redirect to login if token is missing
      return;
    }

    await axios
      .post(URI.getReports, requestBody, {
        headers: getHeaders(true),
      })

      .then((response) => {
        if (response.data.status === 200) {
          const data = response.data.data.reportingDetails.map(
            (item, index) => ({
              slNo: index + 1,
              ...item,
            })
          );

          const clmHeaders = response.data.data.headerDetails;
          if (response.data.data.reportingDetails.length > 0) {
            const headers = Object.keys(data[0]);
            setHeaders(headers);
          }
          setClmHeaders(clmHeaders);
          setTableData(data);
        }
        setReportsView("Table View");
      })
      .catch(async (error) => {
        throw new Error("Failed to fetch data", error);
      });
  };

  function Table({ data }) {
    return (
      <table className="table">
        <thead>
          <tr>
            <th style={{ color: "black", fontWeight: "600" }}>Report Name</th>
            <th style={{ color: "black", fontWeight: "600" }}>Description</th>
          </tr>
        </thead>
        <tbody className="table-body tableUserData">
          {data.name.map((name, index) => (
            <tr key={index}>
              <td className="text-primary">
                <h6
                  className="cursorPointer"
                  onClick={() => {
                    setTableViewHeading(name);
                    getReports(reportValue, name);
                  }}
                >
                  {name}
                </h6>
              </td>
              <td>{data.description[index]}</td>
            </tr>
          ))}
        </tbody>
      </table>
    );
  }

  const toggleSortOrder = (column) => {
    setSortBy((prevSortBy) => {
      if (prevSortBy.column !== column) {
        return { column, order: "asc" }; // If changing column, start with ascending
      }
      // Cycle through asc -> desc -> null
      const nextOrder =
        prevSortBy.order === "asc"
          ? "desc"
          : prevSortBy.order === "desc"
          ? null
          : "asc";
      return { column, order: nextOrder };
    });
  };

  const sortedData = [...tableData].sort((a, b) => {
    const column = sortBy.column;
    const order = sortBy.order;
    if (column && order) {
      const aValue = a[column];
      const bValue = b[column];
      if (aValue < bValue) return order === "asc" ? -1 : 1;
      if (aValue > bValue) return order === "asc" ? 1 : -1;
      return 0;
    }
    return 0;
  });

  const getSortIcon = (column) => {
    if (sortBy.column === column) {
      if (sortBy.order === "asc")
        return (
          <span>
            <img alt="img" src={filterUp} />
          </span>
        ); // Icon for ascending
      if (sortBy.order === "desc")
        return (
          <span>
            <img alt="img" src={filterDown} />
          </span>
        ); // Icon for descending
    }
    return (
      <span>
        <img alt="img" src={filterNormal} />
      </span>
    ); // Default icon
  };

  function TableView() {
    return (
      <table className="table  table-bordered mb-0">
        <thead>
          <tr>
            {tableData.length > 0 ? (
              <>
                {headers.map((header, key) => (
                  <th
                    key={key}
                    className="table_report"
                    style={{
                      backgroundColor: "#F8FAFF",
                      color: "black",
                      fontWeight: "500",
                      cursor: "pointer",
                      paddingRight: "10px",
                    }}
                    onClick={() => toggleSortOrder(header)}
                  >
                    {camelCaseToSpace(header)}
                    {/* {header} */}
                    <span className="ps-2">{getSortIcon(header)}</span>

                    {/* {sortBy.column === header && (
                      <span>{sortBy.order === "asc" ? " ▲" : " ▼"}</span>
                    )} */}
                  </th>
                ))}
              </>
            ) : (
              <>
                {clmHeaders?.map((header, key) => (
                  <th
                    key={key}
                    style={{
                      color: "black",
                      fontWeight: "500",
                      cursor: "pointer",
                      paddingRight: "10px",
                    }}
                    // onClick={() => toggleSortOrder(header)}
                  >
                    {/* {camelCaseToSpace(header)} */}
                    {header}
                    {/* {sortBy.column === header && (
                  <span>{sortBy.order === "asc" ? " ▲" : " ▼"}</span>
                )} */}
                  </th>
                ))}
              </>
            )}
          </tr>
        </thead>
        <tbody>
          {(sortedData === undefined || sortedData.length === 0) && (
            // array does not exist or is empty
            <tr>
              <td
                style={{ textAlignLast: "center", paddingRight: "10px" }}
                colSpan={clmHeaders?.length}
              >
                No data Found
              </td>
            </tr>
          )}
          {(sortedData !== undefined || sortedData.length !== 0) &&
            sortedData.map((item, index) => (
              <tr key={index}>
                {headers.map((header) => (
                  <td
                    key={header}
                    // style={{ fontWeight: "400", paddingRight: "10px" }}
                  >
                    {item[header]}
                  </td>
                ))}
              </tr>
            ))}
        </tbody>
      </table>
    );
  }

  return (
    <>
      {reportsView === "Main Page" && (
        <>
          <h4 className="ms-2 mt-2 text-primary">{heading}</h4>
          <div className="d-flex pb-4 ">
            <div className="Owner_table_container_reports rounded-4 h-25 me-3 h-100 tableContainerDiv">
              <div className="table-wrapper py-1">
                <table className="table table-borderless">
                  <tr className="ms-3">
                    <th className="ps-4 py-2">Folders</th>
                  </tr>
                  {[
                    {
                      label: "Deal Reports",
                      data: dealReports,
                      value: "Deals",
                    },
                    {
                      label: "Lead Reports",
                      data: leadReports,
                      value: "Leads",
                    },
                    {
                      label: "Product Reports",
                      data: productReports,
                      value: "Products",
                    },
                    {
                      label: "Vendor Reports",
                      data: vendorReports,
                      value: "Vendor",
                    },
                    {
                      label: "Quote Reports",
                      data: quoteReports,
                      value: "Quotes",
                    },
                    {
                      label: "Sales Order Reports",
                      data: salesReports,
                      value: "Sales",
                    },
                    {
                      label: "Purchases Order Reports",
                      data: purchaseReports,
                      value: "Purchase",
                    },
                    {
                      label: "Invoice Reports",
                      data: invoiceReports,
                      value: "Invoice",
                    },
                  ].map((item, index) => (
                    <tr key={index}>
                      <td
                        className={`cursorPointer py-2 ps-4 ${
                          heading === item.label ? "selectTab" : "deSelect"
                        }`}
                        onClick={() => {
                          setActiveTab(item.data);
                          setHeading(item.label);
                          setReportValue(item.value);
                        }}
                      >
                        {item.label}
                      </td>
                    </tr>
                  ))}
                </table>
              </div>
            </div>
            <div className="Owner_table_container_reports rounded-4 h-100 w-75 tableContainerDiv">
              <div className="table-wrapper w-100">
                {activeTab.map((data, index) => (
                  <div key={index}>
                    <Table data={data} />
                  </div>
                ))}
              </div>
            </div>
          </div>
        </>
      )}
      {reportsView === "Table View" && (
        <>
          <div className=" d-flex">
            <span
              className="cursorPointer ms-1 pt-2"
              onClick={() => setReportsView("Main Page")}
            >
              {leftArrow}
            </span>
            <h5 className="ms-3 pt-2">{tableViewHeading}</h5>
          </div>
          <div className="d-flex pb-4 w-100 px-4">
            <div
              className="Owner_table_container_reports w-75 tableContainerDiv w-100 h-100"
              style={{ backgroundColor: "transparent", border: "none" }}
            >
              <div className="table-wrapper w-100" style={{ height: "auto" }}>
                <TableView />
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default Reports;

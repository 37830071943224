import React from "react";
import { Tooltip } from "react-tooltip";
import alert from "../Assets/alert-circle (1).svg";

const CalculatorTooltip = () => {
  return (
    <>
      <Tooltip id="tooltip" place="top" className="custom-tooltip" />
      <span
        className="tooltipIcon"
        data-tooltip-id="tooltip"
        data-tooltip-content="This field acts as a calculator for any expression you input. 
        E.g. 10+10 will yield 20 automatically"
        // style={{ paddingTop: "0.25rem" }}
      >
        <img
          src={alert}
          height={20}
          width={20}
          alt="icon"
          className="cursorPointer infoImage"
          //   style={{ marginTop: "-4px" }}
        />
      </span>
    </>
  );
};

export default CalculatorTooltip;

import React from "react";

const Pagination = ({ currentPage, totalPages, onPageChange }) => {
  const handlePrevPage = () => {
    if (currentPage > 1) {
      onPageChange(currentPage - 1);
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      onPageChange(currentPage + 1);
    }
  };

  return (
    <div>
      <p className="mb-0">
        <span
          className={`${
            currentPage === 1 ? "paginationArrowDisable" : "paginationArrow"
          }`}
          onClick={handlePrevPage}
        >
          ⟨
        </span>
        <span className="px-2">
          {currentPage}&nbsp;/&nbsp;{totalPages}
        </span>
        <span
          className={`${
            currentPage === totalPages
              ? "paginationArrowDisable"
              : "paginationArrow"
          }`}
          onClick={handleNextPage}
        >
          ⟩
        </span>
      </p>
    </div>
  );
};

export default Pagination;

import React, { useEffect, useState, useRef } from "react";
import { URI } from "../../constants";
import axios from "axios";
import filterIcon from "../../Assets/Frame (3).svg";
import Select from "react-select";
import "./contacts.css";
import groupIcon from "../../Assets/De-Active/Contact.svg";
import { Dropdown } from "react-bootstrap";
import tableFilterIcon from "../../Assets/filterIcon.svg";
import arrowUp from "../../Assets/square_arrow_up.svg";
import arrowDown from "../../Assets/square_arrow_down.svg";
import pin from "../../Assets/Pin_column.svg";
import unPin from "../../Assets/Unpin.svg";
import cancel from "../../Assets/cancel_circle.svg";
import filterLine from "../../Assets/filterLine.svg";
import filterMenu from "../../Assets/filterMenu.svg";
import searchIcon from "../../Assets/search.svg";
import { Modal } from "react-bootstrap";
import downloadIcon from "../../Assets/Frame (5).svg";
import moment from "moment";
import NoteBook from "../../Assets/notebook-01.svg";
import ContactAvatar from "../../Assets/Contact_Avatar.png";
import accountsIcon from "../../Assets/De-Active/Account.svg";
import vendorIcon from "../../Assets/De-Active/vendorHome.svg";
import {
  convertDateFormat,
  convertToDisplayFormat,
  emailValidation,
  removeExtraSpaces,
} from "../../common/CommonComponent";
import {
  leftArrow,
  rightAngleArrow,
  leftAngleArrow,
  tick,
  expandArrow,
  shrinkArrow,
} from "../../common/commonSVGs/commonSVGIcons.js";
import Product from "../../common/commonRelatedList/ProductList";
import OpenActivity from "../../common/commonRelatedList/OpenActivity";
import CloseActivity from "../../common/commonRelatedList/CloseActivity";
import noDataContact from "../../Assets/Contact us-bro 1.svg";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
// import hierachy from "../../Assets/hierarchy.svg";
import history from "../../Assets/history.svg";
import groupIcon2 from "../../Assets/Group 74.svg";
import EditableInput from "../../common/EditableInput";
import {
  dealsColumn,
  invoiceColumns,
  leadSourceOptions,
  massOptions,
  pageOption,
  purchaseColumns,
  salesColumns,
} from "../../common/CommonData";
import updateIcon from "../../Assets/Group 71.svg";
import cancelIcon from "../../Assets/Group 72.svg";
import down from "../../Assets/Down31.svg";
import up from "../../Assets/Up.svg";
import userSearch from "../../Assets/user-search-01.svg";
import LeadOwnerModal from "../../common/LeadOwnerModal";
// import AccountListModal from "../../common/AccountListModal";
// import VendorListModal from "../../common/VendorListModal";
import cadences from "../../Assets/concade.svg";
import attachIcon from "../../Assets/file-attachment.svg";
import prodIcon from "../../Assets/product.svg";
import openActivityIcon from "../../Assets/openActivity.svg";
import closeActivityIcon from "../../Assets/closeActivity.svg";
import inviteIcon from "../../Assets/invite.svg";
import emailIcon from "../../Assets/mail-01.svg";
import campaignsIcon from "../../Assets/user-group.svg";
import Pagination from "../../common/Pagination";
import Note from "../../common/commonRelatedList/Note";
import Lottie from "react-lottie";
import Clone from "../../Assets/Clone.svg";
import Share from "../../Assets/ShareIcon.svg";
import Delete from "../../Assets/DeleteIconNew.svg";
import ShareModal from "../../common/ShareModal";
import QuotesCommonTable from "../../common/QuotesCommonTable";
import { useSelector } from "react-redux";
import Loader from "../../common/commonRelatedList/Loader";
import SalesCommonTable from "../../common/SalesCommonTable";
import InvoicesCommonTable from "../../common/InvoicesCommonTable";
import PurchaseCommonTable from "../../common/commonRelatedList/PurchaseCommonTable";
import DealsCommonTable from "../../common/DealsCommonTable";
import AccessModal from "../../common/AccessModal";
import ChangeOwnerModal from "../../common/commonRelatedList/ChangeOwnerModal";
import { EXPLORER_URL } from "../../constants/DataConst.js";
import {
  validateFirstName,
  validateLastName,
  validateDepartmentName,
  validateJobTitle,
  validateAssistantName,
  validateSkypeId,
  validateTwitterUsername,
  validateCityIndia,
  validateStateIndia,
  validateCountryName,
  validateMobileNumber,
  validateZipCode,
  customCommonStyles,
} from "../../common/CommonComponent";
import CampaignsList from "../../common/commonRelatedList/CampaignsList.js";
import CampaignIcon from "../../Assets/CampaignBlue.svg";
import useScrollToUndo from "../../hooks/useScrollToUndo.js.js";
import { defaultOptions } from "../../utils/defaultOptions.js";
import getHeaders from "../../common/ApiHeader.js";
import InvitedMeetingList from "../../common/commonRelatedList/InvitedMeetingList.js";
import { handleApiError } from "../../utils/ApiUtils";
import { useNavigate } from "react-router-dom";
import logoConvert from "../../Assets/logoConvert.svg";

function Contacts({
  setCurrentView,
  setInnerPage,
  innerPage,
  permissionList,
  notePermission,
  setFetchContactInfo,
  fetchAccInfo,
  setFetchAccInfo,
  pastViewInfo,
  setPastViewInfo,
  currentView,
  fetchContactInfo,
  fetchProdInfo,
  fetchVendorInfo,
  storeModuleId,
}) {
  const navigate = useNavigate();
  // const [contactView, setInnerPage] = useState("list");
  // const [contactDetails, setContactDetails] = useState({});
  const [contactInfo, setContactInfo] = useState({});
  const [editContact, setEditContact] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [totalItems, setTotalItems] = useState(0);
  const [selectedFiles, setSelectedFiles] = useState(null);
  const [showImageModal, setShowImageModal] = useState(false);
  const [deleteMsg, setDeleteMsg] = useState("");
  const [imageUrl, setImageUrl] = useState(null);
  const [uploadImg, setUploadImg] = useState(false);
  const [selectedNoteFiles, setSelectedNoteFiles] = useState([]);
  const [noteTitle, setNoteTitle] = useState("");
  const [showTitleNote, setShowTitleNote] = useState(false);
  const [addTitle, setAddTitle] = useState("");
  const [editNote, setEditNote] = useState(false);
  const [isShrinked, setIsShrinked] = useState(false);
  const [savingHeadingsDeals, setSavingHeadingsDeals] = useState(dealsColumn);
  const [emailNotification, setEmailNotification] = useState(true);
  const [openActivitiesCalls, setOpenActivitiesCalls] = useState([]);
  const [openActivitiesMeetings, setOpenActivitiesMeetings] = useState([]);
  const [invitedMeetings, setInvitedMeetings] = useState([]);
  const [updateNotes, setUpdateNotes] = useState({
    description: "",
    title: "",
    noteImageList: [],
  });
  const [selectedContact, setSelectedContact] = useState();
  const [imageMsg, setImageMsg] = useState("");
  const [imageLoader, setImageLoader] = useState(false);
  const [showRemoveModal, setShowRemoveModal] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedDealsId, setSelectedDealsId] = useState([]);
  const [selectedHeadingsDeals, setSelectedHeadingsDeals] =
    useState(dealsColumn);
  const [showAccessModal, setShowAccessModal] = useState(false);
  const fileInputRef = useRef(null);
  const [isDragging, setIsDragging] = useState(false);
  const [noteDeleteConfirmation, setNoteDeleteConfirmation] = useState(false);
  const allowedFileTypes = [
    "image/png",
    "image/jpg",
    "image/jpeg",
    "image/gif",
  ];

  const hasPermission = (action) => {
    const permission = permissionList.contacts.find((p) => p.action === action);
    return permission ? permission.status === "TRUE" : false;
  };
  const maxSizeInBytes = 2 * 1024 * 1024; // 2 MB in bytes

  const handleCreateContact = () => {
    setSelectedVendorItem({ vendorName: "", vendorId: "" });
    if (hasPermission("CREATE")) {
      setInnerPage("create");
      setEditContact(false);
      setUpdateButtonLabel("Save");
      setSelectedCheckboxes([]);
    } else {
      setShowAccessModal(true);
    }
  };

  const handleCreateContactClone = () => {
    if (hasPermission("CREATE")) {
      if (contactInfo.userPermission === "Read Only") {
        setShowAccessModal(true);
        return;
      }
      setInnerPage("clone");
      setShowOptionsContact(false);
      setExistingClone(true);
      setUpdateDate({ ...contactInfo });
      setUpdateButtonLabel("Save");
      setEditContact(false);
    } else {
      setShowAccessModal(true);
    }
  };

  const handleEditContact = () => {
    if (hasPermission("EDIT")) {
      if (contactInfo.userPermission === "Read Only") {
        setShowAccessModal(true);
        return;
      }
      setInnerPage("create");
      setUpdateButtonLabel("Update");
      setEditContact(true);
      setUpdateDate({ ...contactInfo });
      setSelectedReportingItem({
        moduleId: contactInfo?.reportingId,
        moduleName: contactInfo?.reportingName,
      });
    } else {
      setShowAccessModal(true);
    }
  };

  const handleDeleteContact = () => {
    if (hasPermission("DELETE")) {
      if (
        contactInfo.userPermission === "Read Only" ||
        contactInfo.userPermission === "Read Write"
      ) {
        setShowAccessModal(true);
        return;
      }
      setShowModalType("delete");
    } else {
      setShowAccessModal(true);
    }
  };

  useEffect(() => {
    if (storeModuleId) {
      getContactOverview(storeModuleId);
      setContactId(storeModuleId);
    }
  }, []);

  const contactColumn = [
    {
      name: "Contact Name",
      isChecked: true,
      value: "contactName",
    },
    {
      name: "Assistant",
      isChecked: true,
      value: "assistant",
    },
    {
      name: "Assistant Phone",
      isChecked: true,
      value: "assistantPhone",
    },
    {
      name: "Description",
      isChecked: false,
      value: "contDescription",
    },

    {
      name: "Account Name",
      isChecked: true,
      value: "accountName",
    },
    {
      name: "Email",
      isChecked: true,
      value: "emailId",
    },
    {
      name: "Phone",
      isChecked: false,
      value: "phone",
    },

    {
      name: "Contact Owner",
      isChecked: false,
      value: "contactOwner",
    },
    {
      name: "Department",
      isChecked: false,
      value: "department",
    },
    {
      name: "Hash",
      isChecked: true,
      value: "transHash",
    },
    {
      name: "First Name",
      isChecked: false,
      value: "firstName",
    },
    {
      name: "Last Name",
      isChecked: false,
      value: "lastName",
    },
    {
      name: "Fax",
      isChecked: false,
      value: "fax",
    },

    {
      name: "Mobile",
      isChecked: false,
      value: "mobile",
    },
    {
      name: "Job Title",
      isChecked: false,
      value: "Job Title",
    },
    {
      name: "Website",
      isChecked: false,
      value: "website",
    },
    {
      name: "Lead Status",
      isChecked: false,
      value: "leadStatus",
    },

    {
      name: "Lead Source",
      isChecked: false,
      value: "leadSource",
    },
    {
      name: "Industry",
      isChecked: false,
      value: "industryType",
    },
    {
      name: "No. of Employees",
      isChecked: false,
      value: "noOfEmployees",
    },
    {
      name: "Annual Revenue",
      isChecked: false,
      value: "annualRevenue",
    },
    {
      name: "Rating",
      isChecked: false,
      value: "rating",
    },
    {
      name: "Secondary Mail",
      isChecked: false,
      value: "secondaryEmailId",
    },
    {
      name: "Created By",
      isChecked: false,
      value: "createdBy",
    },
    {
      name: "Modified By",
      isChecked: false,
      value: "modifiedBy",
    },
    {
      name: "Skype ID",
      isChecked: false,
      value: "skypeId",
    },
    {
      name: "Twitter",
      isChecked: false,
      value: "twitter",
    },
    {
      name: "Mailing Country",
      isChecked: false,
      value: "mailingCountry",
    },
    {
      name: "Mailing State",
      isChecked: false,
      value: "mailingState",
    },
    {
      name: "Mailing City",
      isChecked: false,
      value: "mailingCity",
    },
    {
      name: "Mailing street",
      isChecked: false,
      value: "mailingStreet",
    },
    {
      name: "Mailing Code",
      isChecked: false,
      value: "mailingZip",
    },
    {
      name: "Other Country",
      isChecked: false,
      value: "otherCountry",
    },
    {
      name: "Other State",
      isChecked: false,
      value: "otherState",
    },
    {
      name: "Other City",
      isChecked: false,
      value: "otherCity",
    },
    {
      name: "Other Street",
      isChecked: false,
      value: "otherStreet",
    },
    {
      name: "Other Code",
      isChecked: false,
      value: "otherZip",
    },
  ];

  const [selectedHeadings, setSelectedHeadings] = useState(contactColumn);
  const [savingHeadings, setSavingHeadings] = useState(contactColumn);
  const [pinnedColumn, setPinnedColumn] = useState("Contact");
  const [showHeadings, setShowHeadings] = useState(false);
  const [showSelectedFilter, setShowSelectedFilter] = useState("");
  const [allContactDetails, setAllContactDetails] = useState([]);
  const [filteredContactDetails, setFiltereContactDetails] = useState([]);
  const [filterType, setFilterType] = useState("");
  const [modalType, setShowModalType] = useState("");
  const [searchColumn, setSearchColumn] = useState("");

  const [contactPageView, setContactPageView] = useState("overview");
  const [accountDetails, setAccountDetails] = useState([]);
  const [selectedAccountItem, setSelectedAccountItem] = useState(
    pastViewInfo ? fetchAccInfo : ""
  );

  const [vendorDetails, setVendorDetails] = useState([]);
  const [reportingDetails, setReportingDetails] = useState([]);
  const [emailOptOut, setEmailOptOut] = useState(false);
  const [validEmail, setValidEmail] = useState(true);

  const [selectedVendorItem, setSelectedVendorItem] = useState({
    vendorName: fetchVendorInfo?.vendorName || "",
    vendorId: fetchVendorInfo?.vendorId || "",
  });
  const [selectedReportingItem, setSelectedReportingItem] = useState({
    moduleName: "",
    moduleId: "",
  });
  const [contactId, setContactId] = useState("");
  const [showButtons, setShowButtons] = useState(false);
  const [note, setNote] = useState("");
  const [noteInfo, setNoteInfo] = useState([]);
  const [selectRecent, setSelectRecent] = useState("ALL");
  const [productsList, setProductsList] = useState([]);
  const [campaignList, setCampaignList] = useState([]);
  const [selectedCampaignId, setSelectedCampaignId] = useState([]);
  const [openActivities, setOpenActivities] = useState([]);
  const [closeActivities, setCloseActivities] = useState([]);
  const [closeActivitiesCalls, setCloseActivitiesCalls] = useState([]);
  const [closeActivitiesMeetings, setCloseActivitiesMeetings] = useState([]);
  const [deals, setDeals] = useState([]);
  const [salesOrderList, setSalesOrderList] = useState([]);
  const [purchaseOrderList, setPurchaseOrderList] = useState([]);
  const [invoiceList, setInvoiceList] = useState([]);
  const [updateButtonLabel, setUpdateButtonLabel] = useState("Save");
  const [showOptionsContact, setShowOptionsContact] = useState(false);
  const [existingClone, setExistingClone] = useState(false);
  const [shareModal, setShareModal] = useState(false);
  const [shareInfoModal, setShareInfoModal] = useState(false);
  const [filterQuotesDetails, setFilterQuotesDetails] = useState([]);
  const [showHeadingsQuotes, setShowHeadingsQuotes] = useState(false);
  const [shareUserList, setShareUserList] = useState([]);
  const [shareRoleList, setShareRoleList] = useState([]);
  const [shareGroupList, setShareGroupList] = useState([]);
  const[sharePublicList,setSharePublicList]=useState([])
  const [isChangeOwnerList, setIsChangeOwnerList] = useState(false);
  const [shareMemberIds, setShareMemberIds] = useState([]);

  // for save && save and new buttons to enable loader
  const [saveAndNew, setSaveAndNew] = useState(false);
  const [save, setSave] = useState(false);
  const [deletedImg, setDeletedImg] = useState("");
  const [searchAccount, setSearchAccount] = useState("");
  const [searchVendor, setSearchVendor] = useState("");
  const [modalAccountDetails, setModalAccountDetails] = useState([]);
  const [modalVendorDetails, setModalVendorDetails] = useState([]);

  const handleSearchAccount = (e) => {
    const { value } = e.target;
    if (value.trim() !== "" || value === "") {
      setSearchAccount(value);
    }
  };

  const handleSearchVendor = (e) => {
    const { value } = e.target;
    if (value.trim() !== "" || value === "") {
      setSearchVendor(value);
    }
  };
  const filteredAccounts = modalAccountDetails.filter((item) =>
    item.accountName?.toLowerCase().includes(searchAccount?.toLowerCase())
  );

  const filteredVendors = modalVendorDetails.filter((item) =>
    item.vendorName?.toLowerCase().includes(searchVendor?.toLowerCase())
  );

  const [savingHeadingsInvoices, setSavingHeadingsInvoices] =
    useState(invoiceColumns);
  const quotesColumn = [
    {
      name: "Subject",
      isChecked: true,
      value: "subject",
    },
    {
      name: "Quote Stage",
      isChecked: true,
      value: "quoteStage",
    },
    {
      name: "Valid Until",
      isChecked: true,
      value: "validUntil",
    },
    {
      name: "Carrier",
      isChecked: true,
      value: "carrier",
    },
    {
      name: "Grand Total",
      isChecked: false,
      value: "grandTotal",
    },
    {
      name: "Contact",
      isChecked: false,
      value: "contactName",
    },
    {
      name: "Account Name",
      isChecked: false,
      value: "accountName",
    },
    {
      name: "Quotes Owner",
      isChecked: false,
      value: "quoteOwner",
    },

    {
      name: "Deal Name",
      isChecked: false,
      value: "dealName",
    },
  ];

  const reduxStore = useSelector((state) => state.reduxState);
  const { loginUserId } = reduxStore;
  const options = reduxStore.filterOptions.contact;
  const [selectedOption, setSelectedOption] = useState(options[0]);

  const [selectedHeadingsQuotes, setSelectedHeadingsQuotes] =
    useState(quotesColumn);

  const [savingHeadingsQuotes, setSavingHeadingsQuotes] =
    useState(quotesColumn);
  const handleSelectRecent = (item) => {
    setSelectRecent(item);
    var value = "";
    if (item === "Recent Last") {
      value = "RECENT_LAST";
    } else if (item === "Recent First") {
      value = "RECENT_FIRST";
    } else {
      value = item;
    }
    getNotesDetails(contactId, value);
  };

  const handleTextAreaClick = () => {
    setShowButtons(true);
  };

  const handleAddTitleClick = () => {
    setShowTitleNote(true);
  };

  const handleUploadImage = (e) => {
    const file = e.target.files[0];
    if (file) {
      if (!allowedFileTypes.includes(file.type)) {
        setImageMsg(
          "Invalid file type. Please upload an image file (JPG, PNG, JPEG)."
        );
        setTimeout(() => setImageMsg(""), 5000);
        return;
      }
      if (file.size > maxSizeInBytes) {
        setImageMsg("File size exceeds 2 MB. Please select a smaller file.");
        setTimeout(() => setImageMsg(""), 5000);
        return;
      }
      const imageUrl = URL.createObjectURL(file);
      setImageUrl(imageUrl);
      setSelectedFiles(e.target.files[0]);
    }
    setUploadImg(true);
  };

  const getNotesDetails = async (contactId, orderType) => {
    const requestBody = {
      // loggedInUserId: reduxStore.loginUserId,
      loggedInUserId: loginUserId,
      moduleId: contactId,
      orderType: orderType,
    };

    const headersResult = getHeaders(true);

    // Check if headers result indicates an error
    if (headersResult.error) {
      navigate("../"); // Redirect to login if token is missing
      return;
    }

    await axios
      .post(URI.getNote, requestBody, {
        headers: headersResult,
      })
      .then((response) => {
        if (response.data.status === 200) {
          setNoteInfo(response.data.data.noteInfo);
        } else {
          NotificationManager.error("", response.data.message, 5000);
        }
      })
      .catch(async (error) => {
        handleApiError(error, navigate);
      });
  };

  const createLeadStyles = {
    menu: (base) => ({
      ...base,
      width: "60%",
    }),
    option: (provided) => ({
      ...provided,
      // backgroundColor: "#EAEAEA",
      color: "#000",
      // backgroundColor: 'rgba(33, 119, 254, 0.1)',
      width: "100%",
    }),
    control: (baseStyles) => ({
      ...baseStyles,
      border: "1px solid #b9b9b9b0",
      backgroundColor: "#F3F6FB",
      height: "40px",
      borderRadius: "10px",
      width: "60%",
      boxShadow: "none",
    }),
    input: (base) => ({
      ...base,
      color: "#FFFFFF",
    }),
    singleValue: (defaultStyles) => ({ ...defaultStyles, color: "#000" }),

    menuList: (provided) => ({
      ...provided,
      paddingTop: 0,
      paddingBottom: 0,
      "&::-webkit-scrollbar": {
        width: 5,
      },

      "&::-webkit-scrollbar-thumb": {
        background: "#316AFF",
        borderRadius: "10px",
      },
    }),
  };

  const [selectedHeadingsSales, setSelectedHeadingsSales] =
    useState(salesColumns);

  const fieldDropdownStyles = {
    menu: (base) => ({
      ...base,
      width: "100%",
    }),
    option: (provided) => ({
      ...provided,
      // backgroundColor: "#EAEAEA",
      color: "#000",
      // backgroundColor: 'rgba(33, 119, 254, 0.1)',
      width: "100%",
    }),
    control: (baseStyles) => ({
      ...baseStyles,
      border: 0,
      // backgroundColor: 'rgba(33, 119, 254, 0.1)',
      height: "20px",
      borderRadius: "10px",
      width: "100%",
      boxShadow: "none",
    }),
    input: (base) => ({
      ...base,
      color: "#FFFFFF",
    }),
    singleValue: (defaultStyles) => ({ ...defaultStyles, color: "#000" }),

    menuList: (provided) => ({
      ...provided,
      paddingTop: 0,
      paddingBottom: 0,
      "&::-webkit-scrollbar": {
        width: 5,
      },

      "&::-webkit-scrollbar-thumb": {
        background: "#316AFF",
        borderRadius: "10px",
      },
    }),
  };

  const handleChange = (selectedOption) => {
    getAllContact(selectedOption);
    // setSelectedOption(selectedOption);
  };

  const [formData, setFormData] = useState({
    contactOwner: "",
    leadSource: leadSourceOptions[0],
    firstName: "",
    lastName: "",
    accountName: "",
    title: "",
    phone: "",
    mobile: "",
    emailId: "",
    fax: "",
    skypeId: "",
    secondaryEmailId: "",
    twitter: "",
    otherPhone: "",
    assistant: "",
    department: "",
    homePhone: "",
    dateOfBirth: "",
    assistantPhone: "",
    reportingTo: "",
    // country: "",
    // state: "",
    // city: "",
    // street: "",
    // zipCode: "",
    description: "",
    vendorOwner: "",
    accountOwner: "",
    contactImage: "",
  });

  const [mailingAdd, setMailingAdd] = useState({
    mailingCountry: "",
    mailingState: "",
    mailingCity: "",
    mailingStreet: "",
    mailingZip: "",
  });
  const [otherAdd, setotherAdd] = useState({
    otherCountry: "",
    otherState: "",
    otherCity: "",
    otherStreet: "",
    otherZip: "",
  });

  const copyOtherToMailing = () => {
    handleCopyAddress("otherTomailing");
  };

  const copyMailingToOther = () => {
    handleCopyAddress("mailingToOther");
  };

  const handleCopyAddress = (direction) => {
    if (editContact) {
      if (direction === "mailingToOther") {
        setUpdateDate((prevInfo) => ({
          ...prevInfo,
          otherCountry: prevInfo.mailingCountry || prevInfo.otherCountry,
          otherState: prevInfo.mailingState || prevInfo.otherState,
          otherCity: prevInfo.mailingCity || prevInfo.otherCity,
          otherStreet: prevInfo.mailingStreet || prevInfo.otherStreet,
          otherZip: prevInfo.mailingZip || prevInfo.otherZip,
        }));
      } else if (direction === "otherTomailing") {
        setUpdateDate((prevInfo) => ({
          ...prevInfo,
          mailingCountry: prevInfo.otherCountry || prevInfo.mailingCountry,
          mailingState: prevInfo.otherState || prevInfo.mailingState,
          mailingCity: prevInfo.otherCity || prevInfo.mailingCity,
          mailingStreet: prevInfo.otherStreet || prevInfo.mailingStreet,
          mailingZip: prevInfo.otherZip || prevInfo.mailingZip,
        }));
      }
    } else {
      if (direction === "mailingToOther") {
        setotherAdd((prevInfo) => ({
          ...prevInfo,
          otherCountry: prevInfo.mailingCountry || prevInfo.otherCountry,
          otherState: prevInfo.mailingState || prevInfo.otherState,
          otherCity: prevInfo.mailingCity || prevInfo.otherCity,
          otherStreet: prevInfo.mailingStreet || prevInfo.otherStreet,
          otherZip: prevInfo.mailingZip || prevInfo.otherZip,
        }));
        setMailingAdd((prevInfo) => ({
          ...prevInfo,
          otherCountry: prevInfo.mailingCountry || prevInfo.otherCountry,
          otherState: prevInfo.mailingState || prevInfo.otherState,
          otherCity: prevInfo.mailingCity || prevInfo.otherCity,
          otherStreet: prevInfo.mailingStreet || prevInfo.otherStreet,
          otherZip: prevInfo.mailingZip || prevInfo.otherZip,
        }));
      } else if (direction === "otherTomailing") {
        setMailingAdd((prevInfo) => ({
          ...prevInfo,
          mailingCountry: prevInfo.otherCountry || prevInfo.mailingCountry,
          mailingState: prevInfo.otherState || prevInfo.mailingState,
          mailingCity: prevInfo.otherCity || prevInfo.mailingCity,
          mailingStreet: prevInfo.otherStreet || prevInfo.mailingStreet,
          mailingZip: prevInfo.otherZip || prevInfo.mailingZip,
        }));
        setotherAdd((prevInfo) => ({
          ...prevInfo,
          mailingCountry: prevInfo.otherCountry || prevInfo.mailingCountry,
          mailingState: prevInfo.otherState || prevInfo.mailingState,
          mailingCity: prevInfo.otherCity || prevInfo.mailingCity,
          mailingStreet: prevInfo.otherStreet || prevInfo.mailingStreet,
          mailingZip: prevInfo.otherZip || prevInfo.mailingZip,
        }));
      }
    }
  };
  const [selectedHeadingsInvoices, setSelectedHeadingsInvoices] =
    useState(invoiceColumns);

  const [updateData, setUpdateDate] = useState({
    contactOwner: "",
    leadSource: leadSourceOptions[0],
    firstName: "",
    lastName: "",
    accountName: "",
    title: "",
    phone: "",
    mobile: "",
    emailId: "",
    fax: "",
    skypeId: "",
    secondaryEmailId: "",
    twitter: "",
    otherPhone: "",
    assistant: "",
    department: "",
    homePhone: "",
    dateOfBirth: "",
    assistantPhone: "",
    reportingTo: "",
    mailingCountry: "",
    mailingState: "",
    mailingCity: "",
    mailingStreet: "",
    mailingZip: "",
    otherCountry: "",
    otherState: "",
    otherCity: "",
    otherStreet: "",
    otherZip: "",
    country: "",
    state: "",
    city: "",
    street: "",
    zipCode: "",
    description: "",
    contactImage: "",
  });

  const resetForm = () => {
    const formData = {
      contactOwner: "",
      leadSource: leadSourceOptions[0],
      firstName: "",
      lastName: "",
      accountName: "",
      title: "",
      phone: "",
      mobile: "",
      emailId: "",
      fax: "",
      skypeId: "",
      secondaryEmailId: "",
      twitter: "",
      otherPhone: "",
      assistant: "",
      department: "",
      homePhone: "",
      dateOfBirth: "",
      assistantPhone: "",
      reportingTo: "",
      // country: "",
      // state: "",
      // city: "",
      // street: "",
      // zipCode: "",
      description: "",
      contactImage: "",
    };
    const mailingAddressData = {
      mailingCountry: "",
      mailingState: "",
      mailingCity: "",
      mailingStreet: "",
      mailingZip: "",
    };
    const otherAddressData = {
      otherCountry: "",
      otherState: "",
      otherCity: "",
      otherStreet: "",
      otherZip: "",
    };
    setFormData(formData);
    setMailingAdd(mailingAddressData);
    setotherAdd(otherAddressData);
    setUpdateDate(formData);
  };

  const [timeLineDataList, setTimeLineDataList] = useState([]);
  const [editingFields, setEditingFields] = useState({
    contactOwner: false,
    leadSource: false,
    firstName: false,
    lastName: false,
    accountName: false,
    title: false,
    phone: false,
    mobile: false,
    emailId: false,
    fax: false,
    skypeId: false,
    secondaryEmailId: false,
    twitter: false,
    otherPhone: false,
    assistant: false,
    department: false,
    homePhone: false,
    dateOfBirth: false,
    assistantPhone: false,
    reportingTo: false,
    mailingCountry: false,
    mailingState: false,
    mailingCity: false,
    mailingStreet: false,
    mailingZip: false,
    otherCountry: false,
    otherState: false,
    otherCity: false,
    otherStreet: false,
    otherZip: false,
    // country: false,
    // state: false,
    // city: false,
    // street: false,
    // zipCode: false,
    description: false,
  });

  const [updatedFields, setUpdatedFields] = useState({
    contactOwner: contactInfo.contactOwner,
    contactOwner1: contactInfo.contactOwner,
    leadSource: contactInfo.leadSource,
    firstName: contactInfo.firstName,
    lastName: contactInfo.lastName,
    accountName: contactInfo.accountName,
    title: contactInfo.title,
    phone: contactInfo.phone,
    mobile: contactInfo.mobile,
    mobile1: contactInfo.mobile,
    emailId: contactInfo.emailId,
    email1: contactInfo.emailId,
    phoneNumber1: contactInfo.phone,
    leadSourceInfo: contactInfo.leadSource,
    fax: contactInfo.fax,
    skypeId: contactInfo.skypeId,
    secondaryEmailId: contactInfo.secondaryEmailId,
    twitter: contactInfo.twitter,
    otherPhone: contactInfo.otherPhone,
    assistant: contactInfo.assistant,
    department: contactInfo.department,
    department1: contactInfo.department,
    homePhone: contactInfo.homePhone,
    dateOfBirth: contactInfo.dateOfBirth,
    assistantPhone: contactInfo.assistantPhone,
    reportingTo: contactInfo.reportingTo,
    mailingCountry: contactInfo.mailingCountry,
    mailingState: contactInfo.mailingState,
    mailingCity: contactInfo.mailingCity,
    mailingStreet: contactInfo.mailingStreet,
    mailingZip: contactInfo.mailingZip,
    otherCountry: contactInfo.otherCountry,
    otherState: contactInfo.otherStreet,
    otherCity: contactInfo.otherCity,
    otherStreet: contactInfo.otherStreet,
    otherZip: contactInfo.otherZip,
    description: contactInfo.description,
    skypeId1: contactInfo.skypeId,
    assistant1: contactInfo.assistant,
    assistantPhone1: contactInfo.assistantPhone,
    fax1: contactInfo.fax,
    homePhone1: contactInfo.homePhone,
    title1: contactInfo.title,
    mailingCountry1: contactInfo.mailingCountry,
    mailingState1: contactInfo.mailingState,
    mailingCity1: contactInfo.mailingCity,
    mailingStreet1: contactInfo.mailingStreet,
    mailingZip1: contactInfo.mailingZip,
    otherCountry1: contactInfo.otherCountry,
    otherState1: contactInfo.otherStreet,
    otherCity1: contactInfo.otherCity,
    otherStreet1: contactInfo.otherStreet,
    otherZip1: contactInfo.otherZip,
    twitter1: contactInfo.twitter,
    secondaryEmailId1: contactInfo.secondaryEmailId,
    dateOfBirth1: contactInfo.dateOfBirth,
    otherPhone1: contactInfo.otherPhone,
    emailOptOut1: contactInfo.emailOptOut,
    emailOptOut: contactInfo.emailOptOut,
  });
  const [showType, setShowType] = useState("");
  const handleChangeSelectDropDown = (selectedOption, field) => {
    if (editContact || existingClone) {
      setUpdateDate((prevLeadInfo) => ({
        ...prevLeadInfo,
        [field]: selectedOption,
      }));
    } else {
      setFormData((prevLeadInfo) => ({
        ...prevLeadInfo,
        [field]: selectedOption,
      }));
      setMailingAdd((prevLeadInfo) => ({
        ...prevLeadInfo,
        [field]: selectedOption,
      }));
      setotherAdd((prevLeadInfo) => ({
        ...prevLeadInfo,
        [field]: selectedOption,
      }));
    }
  };

  const userList = JSON.parse(sessionStorage.getItem("userList"));

  const [selectedItem, setSelectedItem] = useState(null);
  const [listVisible, setListVisible] = useState(false);
  const [isLeadOwnerModal, setIsLeadOwnerModal] = useState(false);
  const [accountListVisible, setAccountListVisible] = useState(false);
  const [isAccountListModal, setIsAccountListModal] = useState(false);
  const [vendorListVisible, setVendorListVisible] = useState(false);
  const [isVendorListModal, setIsVendorListModal] = useState(false);
  const [reportingListVisible, setReportingListVisible] = useState(false);
  // const [clcikedEmailOpt, setClickedEmailOtp] = useState(false);

  useScrollToUndo(pastViewInfo, setPastViewInfo); // for scroll to previously clicked section

  const fieldDropdowns = [
    {
      name: "Contact Owner",
      isShowed: false,
      type: "select",
    },
    {
      name: "Account Name",
      isShowed: false,
      type: "readOnly",
      value: "accountName",
    },
    {
      name: "Email Id",
      isShowed: true,
      type: "input",
      value: "emailId",
    },
    {
      name: "Phone",
      isShowed: true,
      type: "input",
    },
    {
      name: "Other Phone",
      isShowed: false,
      type: "input",
    },
    {
      name: "Mobile",
      isShowed: true,
      type: "input",
    },
    {
      name: "Assistant",
      isShowed: false,
      type: "input",
    },
    {
      name: "Created By",
      isShowed: false,
      type: "readOnly",
      value: "createdBy",
    },
    {
      name: "Modified By",
      isShowed: false,
      type: "readOnly",
      value: "modifiedBy",
    },
    {
      name: "Lead Source",
      isShowed: false,
      type: "dropdown",
      value: "leadSource",
    },
    {
      name: "Contact Name",
      isShowed: false,
      type: "readOnly",
      value: "contactName",
    },
    {
      name: "Vendor Name",
      isShowed: true,
      type: "readOnly",
      value: "vendorName",
    },
    {
      name: "Job Title",
      isShowed: false,
      value: "title",
      type: "input",
    },
    {
      name: "Department",
      isShowed: true,
      type: "input",
    },
    {
      name: "Home Phone",
      isShowed: false,
      type: "input",
    },
    {
      name: "Fax",
      isShowed: false,
      type: "input",
    },
    {
      name: "Date Of Birth",
      isShowed: false,
      type: "input",
    },
    {
      name: "Assistant Phone",
      isShowed: false,
      type: "input",
    },
    {
      name: "Email Opt Out",
      isShowed: false,
      type: "checkbox",
      value: "emailOptOut",
    },

    {
      name: "Skype Id",
      isShowed: false,
      type: "input",
    },
    {
      name: "Secondary Email Id",
      isShowed: false,
      type: "input",
    },
    {
      name: "Reporting To",
      isShowed: false,
      type: "readOnly",
    },
    {
      name: "Twitter",
      isShowed: false,
      type: "input",
    },
    {
      name: "Mailing Street",
      isShowed: false,
      type: "input",
    },
    {
      name: "Mailing City",
      isShowed: false,
      type: "input",
    },
    {
      name: "Mailing State",
      isShowed: false,
      type: "input",
    },
    {
      name: "Mailing Zip",
      isShowed: false,
      type: "input",
    },
    {
      name: "Mailing Country",
      isShowed: false,
      type: "input",
    },
    {
      name: "Other Street",
      isShowed: false,
      type: "input",
    },
    {
      name: "Other City",
      isShowed: false,
      type: "input",
    },
    {
      name: "Other State",
      isShowed: false,
      type: "input",
    },
    {
      name: "Other Zip",
      isShowed: false,
      type: "input",
    },
    {
      name: "Other Country",
      isShowed: false,
      type: "input",
    },
  ];

  const fieldIndividualDropdowns = [
    {
      name: "Contact Owner",
      isShowed: false,
      type: "select",
    },
    {
      name: "Account Name",
      isShowed: false,
      type: "readOnly",
      value: "accountName",
    },
    {
      name: "Email Id",
      isShowed: true,
      type: "input",
      value: "email1",
    },
    {
      name: "Phone",
      isShowed: true,
      type: "input",
      value: "phoneNumber1",
    },
    {
      name: "Other Phone",
      isShowed: false,
      type: "input",
      value: "otherPhone1",
    },
    {
      name: "Mobile",
      isShowed: true,
      type: "input",
      value: "mobile1",
    },
    {
      name: "Assistant",
      isShowed: false,
      type: "input",
      value: "assistant1",
    },
    {
      name: "Created By",
      isShowed: false,
      type: "readOnly",
      value: "createdBy",
    },
    {
      name: "Modified By",
      isShowed: false,
      type: "readOnly",
      value: "modifiedBy",
    },
    {
      name: "Lead Source",
      isShowed: false,
      type: "dropdown",
      value: "leadSourceInfo",
    },
    {
      name: "Contact Name",
      isShowed: false,
      type: "readOnly",
      value: "contactName",
    },
    {
      name: "Vendor Name",
      isShowed: true,
      type: "readOnly",
      value: "vendorName",
    },
    {
      name: "Job Title",
      isShowed: false,
      value: "title1",
      type: "input",
    },
    {
      name: "Department",
      isShowed: true,
      type: "input",
      value: "department1",
    },
    {
      name: "Home Phone",
      isShowed: false,
      type: "input",
      value: "homePhone1",
    },
    {
      name: "Fax",
      isShowed: false,
      type: "input",
      value: "fax1",
    },
    {
      name: "Date Of Birth",
      isShowed: false,
      type: "input",
      value: "dateOfBirth1",
    },
    {
      name: "Assistant Phone",
      isShowed: false,
      type: "input",
      value: "assistantPhone1",
    },
    {
      name: "Email Opt Out",
      isShowed: false,
      type: "checkbox",
      value: "emailOptOut1",
    },

    {
      name: "Skype Id",
      isShowed: false,
      type: "input",
      value: "skypeId1",
    },
    {
      name: "Secondary Email Id",
      isShowed: false,
      type: "input",
      value: "secondaryEmailId1",
    },
    {
      name: "Reporting To",
      isShowed: false,
      type: "readOnly",
      value: "reportingName",
    },
    {
      name: "Twitter",
      isShowed: false,
      type: "input",
      value: "twitter1",
    },
    {
      name: "Mailing Street",
      isShowed: false,
      type: "input",
      value: "mailingStreet1",
    },
    {
      name: "Mailing City",
      isShowed: false,
      type: "input",
      value: "mailingCity1",
    },
    {
      name: "Mailing State",
      isShowed: false,
      type: "input",
      value: "mailingState1",
    },
    {
      name: "Mailing Zip",
      isShowed: false,
      type: "input",
      value: "mailingZip1",
    },
    {
      name: "Mailing Country",
      isShowed: false,
      type: "input",
      value: "mailingCountry1",
    },
    {
      name: "Other Street",
      isShowed: false,
      type: "input",
      value: "otherStreet1",
    },
    {
      name: "Other City",
      isShowed: false,
      type: "input",
      value: "otherCity1",
    },
    {
      name: "Other State",
      isShowed: false,
      type: "input",
      value: "otherState1",
    },
    {
      name: "Other Zip",
      isShowed: false,
      type: "input",
      value: "otherZip1",
    },
    {
      name: "Other Country",
      isShowed: false,
      type: "input",
      value: "otherCountry1",
    },
  ];

  const [fieldLabels, setFieldLabels] = useState(fieldDropdowns);
  const [showSearchFields, setShowSearchFields] = useState(fieldDropdowns);
  const [selectedField, setSelectedField] = useState("");
  const [searchField, setSearchField] = useState("");
  const [selectedContactIndex, setSelectedContactIndex] = useState(0);
  const [emailMsg, setEmailMsg] = useState("");
  const [firstNameErrorMsg, setFirstNameErrorMsg] = useState("");
  const [lastNameErrorMsg, setLastNameErrorMsg] = useState("");
  const [departmentMsg, setDepartmentMsg] = useState("");
  const [otherPhoneMsg, setOtherPhoneMsg] = useState("");
  const [mobileMsg, setMobileMsg] = useState("");
  const [assistantMsg, setAssistantMsg] = useState("");
  const [titleMsg, setTitleMsg] = useState("");
  const [skypeIdMsg, setSkypeIdMsg] = useState("");
  const [twitterId, setTwitterId] = useState("");
  const [phoneMsg, setPhoneMsg] = useState("");
  const [mailingCityErrorMsg, setMailingCityErrorMsg] = useState("");
  const [mailingStateErrorMsg, setMailingStateErrorMsg] = useState("");
  const [mailingCountryErrorMsg, setMailingCountryErrorMsg] = useState("");
  const [otherCityErrorMsg, setOtherCityErrorMsg] = useState("");
  const [otherStateErrorMsg, setOtherStateErrorMsg] = useState("");
  const [otherCountryErrorMsg, setOtherCountryErrorMsg] = useState("");
  const [mailingMsg, setMailingZipMsg] = useState("");
  const [otherZipMsg, setOtherZipMsg] = useState("");
  // eslint-disable-next-line no-unused-vars
  const [emailValidMsg, setEmailValidMsg] = useState("");
  const [selectedProductId, setSelectedProductId] = useState([]);
  const [selectedQuoteId, setSelectedQuoteId] = useState([]);
  const [selectedSalesId, setSelectedSalesId] = useState([]);
  const [selectedPurchaseId, setSelectedPurchaseId] = useState([]);
  const [selectedInvoiceId, setSelectedInvoiceId] = useState([]);
  const [showHeadingsDeals, setShowHeadingsDeals] = useState(false);
  const [showHeadingsInvoices, setShowHeadingsInvoices] = useState(false);
  const [showHeadingsSales, setShowHeadingsSales] = useState(false);
  const [savingHeadingsSales, setSavingHeadingsSales] = useState(salesColumns);
  const [showHeadingsPurchases, setShowHeadingsPurchases] = useState(false);
  const [selectedHeadingsPurchases, setSelectedHeadingsPurchases] =
    useState(purchaseColumns);
  const [savingHeadingsPurchase, setSavingHeadingsPurchase] =
    useState(purchaseColumns);
  const [massUpdateValue, setMassUpdateValue] = useState("");
  const [selectedObj, setSelectedObj] = useState({
    name: "First Name",
    isChecked: false,
    value: "firstName",
  });
  const [selectedCheckboxes, setSelectedCheckboxes] = useState([]);
  const [clickedDropdownObj, setClickedDropdownObj] = useState({
    label: "",
    value: "",
  });
  const [apiLoader, setApiLoader] = useState(false);
  const [currentPageAccount, setCurrentPageAccount] = useState(1);
  const [totalItemsAccount, setTotalItemsAccount] = useState(0);
  const [perPageAccount, setPerPageAccount] = useState(10);

  const totalPagesAccount = Math.ceil(
    Number(totalItemsAccount) / perPageAccount
  );

  const [currentPageVendor, setCurrentPageVendor] = useState(1);
  const [totalItemsVendor, setTotalItemsVendor] = useState(0);
  const [perPageVendor, setPerPageVendor] = useState(10);

  const totalPagesVendor = Math.ceil(Number(totalItemsVendor) / perPageVendor);

  const handlepageChangeAccount = (page) => {
    setCurrentPageAccount(page);
  };

  const handlepageChangeVendor = (page) => {
    setCurrentPageVendor(page);
  };

  const handlePageDropDownChangeAccount = (selectedOption) => {
    setCurrentPageAccount(1);
    setPerPageAccount(parseInt(selectedOption.value));
  };

  const handlePageDropDownChangeVendor = (selectedOption) => {
    setCurrentPageVendor(1);
    setPerPageVendor(parseInt(selectedOption.value));
  };

  const getAllAccountDetails = async () => {
    const requestBody = {
      loggedInUserId: loginUserId,
      pageNo: currentPageAccount,
      pageSize: perPageAccount,
    };

    const headersResult = getHeaders(true);

    // Check if headers result indicates an error
    if (headersResult.error) {
      navigate("../"); // Redirect to login if token is missing
      return;
    }
    await axios
      .post(URI.getAllAccountDetails, requestBody, {
        headers: headersResult,
      })
      .then((response) => {
        if (response.data.status === 200) {
          setAccountDetails(response.data.data.allAccountDetails);
          setModalAccountDetails(response.data.data.allAccountDetails);
          setTotalItemsAccount(response.data.data.rowCount);
          // setSelectedAccountItem(response.data.data.allAccountDetails);
        }
      })
      .catch(async (error) => {
        handleApiError(error, navigate);
      });
  };

  const removeImage = async () => {
    setImageLoader(true);
    const requestBody = {
      loggedInUserId: loginUserId,
      contactId: contactId,
    };

    const headersResult = getHeaders(true);

    // Check if headers result indicates an error
    if (headersResult.error) {
      navigate("../"); // Redirect to login if token is missing
      return;
    }
    await axios
      .post(URI.removeContactImage, requestBody, {
        headers: headersResult,
      })
      .then((response) => {
        if (response.data.status === 200) {
          NotificationManager.success("", response.data.message, 5000);
          setTimeout(() => {
            setDeleteMsg("");
            setShowImageModal(false);
            setShowRemoveModal(false);
            getContactOverview(contactId);
          }, 3000);
        } else {
          NotificationManager.error("", response.data.message, 5000);
        }
      })
      .catch(async (error) => {
        handleApiError(error, navigate);
      })
      .finally(() => setImageLoader(false));
  };

  const uploadImage = async () => {
    setImageLoader(true);
    const formData = new FormData();
    const requestBody = {
      loggedInUserId: loginUserId,
      contactId: contactId,
    };
    formData.append("contactDtlsInputs", JSON.stringify(requestBody));
    formData.append(
      "contactImage",
      uploadImg ? selectedFiles : contactInfo.contactImageData
    );

    const headersResult = getHeaders(true);

    // Check if headers result indicates an error
    if (headersResult.error) {
      navigate("../"); // Redirect to login if token is missing
      return;
    }
    await axios
      .post(URI.individualUpdateContact, formData, {
        headers: headersResult,
      })
      .then((response) => {
        if (response.data.status === 200) {
          getContactOverview(contactId);
          setShowImageModal(false);
        } else {
          NotificationManager.error("", response.data.message, 5000);
        }
      })
      .catch(async (error) => {
        handleApiError(error, navigate);
      })
      .finally(() => setImageLoader(false));
  };

  const handleChangeNoteTitle = (e) => {
    const { name, value } = e.target;
    if (value.length === 1 && value[0] === ' ') {
      return; 
    }
    if (editNote) {
      setUpdateNotes((prevLeadInfo) => ({
        ...prevLeadInfo,
        [name]: value,
      }));
    } else {
      setAddTitle(value);
    }
  };

  const handleChildDataChange = (dataFromChild) => {
    const deletedImg = dataFromChild.toString("");
    setDeletedImg(deletedImg);
  };

  const handleAssignModule = async (selectedProductId) => {
    const moduleIdList = selectedProductId.map((id) => ({ moduleId: id }));

    if (moduleIdList.length < 1) {
      return NotificationManager.error("", "Please select product", 5000);
    }

    const requestBody = {
      loginUserId: loginUserId,
      moduleName: "CONTACTS",
      moduleId: contactId,
      assignModuleName: "PRODUCTS",
      moduleIdList,
    };
    setApiLoader(true);
    try {
      const headersResult = getHeaders(true);

      // Check if headers result indicates an error
      if (headersResult.error) {
        navigate("../"); // Redirect to login if token is missing
        return;
      }
      const response = await axios.post(URI.assignRole, requestBody, {
        headers: headersResult,
      });
      if (response.data.status === 200) {
        NotificationManager.success("", response.data.message, 5000);
        getContactOverview(contactId);
      } else {
        NotificationManager.error("", response.data.message, 5000);
      }
    } catch (error) {
      handleApiError(error, navigate);
    } finally {
      setApiLoader(false);
    }
  };

  const getAllAssignModules = async (main, sub, id) => {
    const requestBody = {
      loginUserId: loginUserId,
      mainModule: main,
      subModule: sub,
      moduleId: id,
    };

    await axios
      .post(URI.moduleWiseAssign, requestBody, {
        headers: getHeaders(true),
      })
      .then((response) => {
        if (response.data.status === 200) {
          const getData = response.data.data.assignModulewiseDetails;
          setReportingDetails(getData);
          getData.length > 0
            ? setSelectedReportingItem(getData[0])
            : setSelectedReportingItem({
                moduleName: "",
                moduleId: "",
              });
        } else {
          setReportingDetails([]);
        }
      })
      .catch(async (error) => {
        throw new Error("Failed to fetch data", error);
      });
  };

  const handleAssignModuleCampaigns = async (selectedCampaignId) => {
    const moduleIdList = selectedCampaignId.map((id) => ({ moduleId: id }));

    if (moduleIdList.length < 1) {
      return NotificationManager.error("", "Please select an item", 5000);
    }

    const requestBody = {
      loginUserId: loginUserId,
      moduleName: "CONTACTS",
      moduleId: contactId,
      assignModuleName: "CAMPAIGNS",
      moduleIdList,
    };

    setApiLoader(true);
    try {
      const headersResult = getHeaders(true);

      // Check if headers result indicates an error
      if (headersResult.error) {
        navigate("../"); // Redirect to login if token is missing
        return;
      }
      const response = await axios.post(URI.assignRole, requestBody, {
        headers: headersResult,
      });
      if (response.data.status === 200) {
        NotificationManager.success("", response.data.message, 5000);
        getContactOverview(contactId);
      } else {
        NotificationManager.error("", response.data.message, 5000);
      }
    } catch (error) {
      handleApiError(error, navigate);
    } finally {
      setApiLoader(false);
    }
  };

  const handledeleteModule = async (deleteProdId) => {
    const requestBody = {
      loginUserId: loginUserId,
      moduleName: "CONTACTS",
      moduleId: contactId,
      assignModuleName: "PRODUCTS",
      assignModuleId: deleteProdId,
    };

    try {
      const headersResult = getHeaders(true);

      // Check if headers result indicates an error
      if (headersResult.error) {
        navigate("../"); // Redirect to login if token is missing
        return;
      }
      const response = await axios.post(URI.deleteRole, requestBody, {
        headers: headersResult,
      });
      if (response.data.status === 200) {
        NotificationManager.success("", response.data.message, 5000);
        getContactOverview(contactId);
      } else {
        NotificationManager.error("", response.data.message, 5000);
      }
    } catch (error) {
      handleApiError(error, navigate);
    }
  };

  const handledeleteModuleDeal = async (deleteDealId) => {
    const requestBody = {
      loginUserId: loginUserId,
      moduleName: "CONTACTS",
      moduleId: contactId,
      assignModuleName: "DEALS",
      assignModuleId: deleteDealId,
    };

    try {
      const headersResult = getHeaders(true);

      // Check if headers result indicates an error
      if (headersResult.error) {
        navigate("../"); // Redirect to login if token is missing
        return;
      }
      const response = await axios.post(URI.deleteRole, requestBody, {
        headers: headersResult,
      });
      if (response.data.status === 200) {
        NotificationManager.success("", response.data.message, 5000);
        getContactOverview(contactId);
      } else {
        NotificationManager.error("", response.data.message, 5000);
      }
    } catch (error) {
      handleApiError(error, navigate);
    }
  };

  const handledeleteModuleCampaigns = async (deleteCampId) => {
    const requestBody = {
      loginUserId: loginUserId,
      moduleName: "CONTACTS",
      moduleId: contactId,
      assignModuleName: "CAMPAIGNS",
      assignModuleId: deleteCampId,
    };

    try {
      const headersResult = getHeaders(true);

      // Check if headers result indicates an error
      if (headersResult.error) {
        navigate("../"); // Redirect to login if token is missing
        return;
      }
      const response = await axios.post(URI.deleteRole, requestBody, {
        headers: headersResult,
      });
      if (response.data.status === 200) {
        NotificationManager.success("", response.data.message, 5000);
        getContactOverview(contactId);
      } else {
        NotificationManager.error("", response.data.message, 5000);
      }
    } catch (error) {
      handleApiError(error, navigate);
    }
  };

  const handleChangeNoteDescription = (e) => {
    const { name, value } = e.target;
    if (value.length === 1 && value[0] === ' ') {
      return; 
    }
    if (editNote) {
      setUpdateNotes((prevLeadInfo) => ({
        ...prevLeadInfo,
        [name]: value,
      }));
    } else {
      setNote(value); // Directly set the input value
    }
  };

  const getAllVendorDetails = async () => {
    const requestBody = {
      loggedInUserId: loginUserId,
      pageNo: currentPageVendor,
      pageSize: perPageVendor,
    };

    const headersResult = getHeaders(true);

    // Check if headers result indicates an error
    if (headersResult.error) {
      navigate("../"); // Redirect to login if token is missing
      return;
    }

    await axios
      .post(URI.getAllVendors, requestBody, {
        headers: headersResult,
      })
      .then((response) => {
        if (response.data.status === 200) {
          if (!fetchVendorInfo?.vendorId && !fetchVendorInfo?.vendorName) {
            setSelectedVendorItem(response.data.data.allVendorsDtls);
          }
          setVendorDetails(response.data.data.allVendorsDtls);
          setModalVendorDetails(response.data.data.allVendorsDtls);
          setTotalItemsVendor(response.data.data.rowCount);
        }
      })
      .catch(function (error) {
        handleApiError(error, navigate);
      });
  };

  const handleDragOver = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setIsDragging(true);
  };

  const handleDragLeave = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setIsDragging(false);
  };

  const handleDrop = (e) => {
    const maxSizeInBytes = 2 * 1024 * 1024; // 2 MB in bytes

    e.preventDefault();
    e.stopPropagation();
    setIsDragging(false);
    const file = e.dataTransfer.files[0];
    if (file) {
      if (!allowedFileTypes.includes(file.type)) {
        setImageMsg(
          "Invalid file type. Please upload an image file (JPG, PNG, JPEG)."
        );
        setTimeout(() => setImageMsg(""), 3000);
        return;
      }
      if (file.size > maxSizeInBytes) {
        setImageMsg("File size exceeds 2 MB. Please select a smaller file.");
        setTimeout(() => setImageMsg(""), 3000);
        return;
      }
      setSelectedFiles(file);
      fileInputRef.current.files = e.dataTransfer.files;
      if (editContact || existingClone) {
        setUpdateDate((prevLeadInfo) => ({
          ...prevLeadInfo,
          ["accountImage"]: file,
        }));
      } else {
        setFormData((prevLeadInfo) => ({
          ...prevLeadInfo,
          ["accountImage"]: file,
        }));
      }
    }
  };

  const handleImageChange = (e) => {
    const selectedFile = e.target.files[0];
    const maxSizeInBytes = 2 * 1024 * 1024;
    if (selectedFile && selectedFile.size > maxSizeInBytes) {
      setImageMsg("File size exceeds 2 MB.Please select a smaller file.");
      setTimeout(() => {
        setImageMsg("");
      }, 3000);
      return;
    }
    setSelectedFiles(selectedFile);
    setImageMsg("");
    if (editContact) {
      setUpdatedFields((prevLeadInfo) => ({
        ...prevLeadInfo,
        contactImage: e.target.files[0],
      }));
    } else {
      setFormData((prevLeadInfo) => ({
        ...prevLeadInfo,
        contactImage: e.target.files[0],
      }));
    }
  };

  const handleInputFieldChange = (e) => {
    const { name, value } = e.target;
    // if (editContact) {
    //   const { name, value } = e.target;
    //   setUpdateDate({
    //     ...updateData,
    //     [name]: value,
    //   });
    // } else {
    //   const { name, value } = e.target;
    //   setFormData({
    //     ...formData,
    //     [name]: value,
    //   });
    // }
    const formattedValue =
      name === "dateOfBirth" ? convertToDisplayFormat(value) : value;
    if (editContact || existingClone) {
      setUpdateDate((prevLeadInfo) => ({
        ...prevLeadInfo,
        [name]: formattedValue,
      }));
    } else {
      setFormData((prevLeadInfo) => ({
        ...prevLeadInfo,
        [name]: formattedValue,
      }));
      setMailingAdd((prevLeadInfo) => ({
        ...prevLeadInfo,
        [name]: formattedValue,
      }));
      setotherAdd((prevLeadInfo) => ({
        ...prevLeadInfo,
        [name]: formattedValue,
      }));
    }
  };

  const deleteNoteDetails = async (contactId, noteId) => {
    const formData = new FormData();
    formData.append("moduleId", contactId);
    formData.append("loggedInUserId", loginUserId);
    formData.append("moduleName", "CONTACTS");
    formData.append("noteId", noteId);

    const headersResult = getHeaders(true);

    // Check if headers result indicates an error
    if (headersResult.error) {
      navigate("../"); // Redirect to login if token is missing
      return;
    }

    await axios
      .post(URI.deleteNote, formData, {
        headers: headersResult,
      })
      .then((response) => {
        if (response.data.status === 200) {
          NotificationManager.success("", response.data.message, 3000);
          getNotesDetails(contactId, selectRecent);
          setNoteDeleteConfirmation(false);
        } else {
          NotificationManager.error("", response.data.message, 5000);
        }
      })
      .catch((error) => {
        handleApiError(error, navigate);
      });
  };

  const handlePinColumn = (name) => {
    setPinnedColumn(name);
    const updatedColumns = [...selectedHeadings];
    const clickedColumnIndex = updatedColumns.findIndex(
      (column) => column.name === name
    );

    if (clickedColumnIndex !== -1) {
      const clickedColumn = updatedColumns.splice(clickedColumnIndex, 1)[0];
      updatedColumns.unshift(clickedColumn);
      setSelectedHeadings(updatedColumns);
    }
  };

  const sortedColumnsData = (type, headingName) => {
    const sortedData = [...allContactDetails];

    if (type === "asc") {
      sortedData.sort((a, b) => {
        if (
          typeof a[headingName] === "string" &&
          typeof b[headingName] === "string"
        ) {
          return a[headingName].localeCompare(b[headingName]);
        }
        return a[headingName] - b[headingName];
      });
    } else if (type === "desc") {
      sortedData.sort((a, b) => {
        if (
          typeof a[headingName] === "string" &&
          typeof b[headingName] === "string"
        ) {
          return b[headingName].localeCompare(a[headingName]);
        }
        return b[headingName] - a[headingName];
      });
    }
    setFiltereContactDetails(sortedData);
  };

  const UpdateContact = async (e) => {
    let isValidForm = true;

    const setErrorMessage = (setter, message, timeout = 5000) => {
      setter(message);
      setTimeout(() => {
        setter("");
      }, timeout);
      isValidForm = false;
    };

    const contactData = editContact || existingClone ? updateData : formData;

    if (contactData.firstName === "") {
      setErrorMessage(setFirstNameErrorMsg, "First name can not be empty.");
    }
    if (contactData.lastName === "") {
      setErrorMessage(setLastNameErrorMsg, "Last name can not be empty.");
    }
    if (
      contactData.firstName !== "" &&
      !validateFirstName(contactData.firstName)
    ) {
      setErrorMessage(setFirstNameErrorMsg, "Please enter a valid First name.");
    }
    if (
      contactData.lastName !== "" &&
      !validateLastName(contactData.lastName)
    ) {
      setErrorMessage(setLastNameErrorMsg, "Please enter a valid Last  name.");
    }
    if (
      contactData.department !== null &&
      contactData.department !== "" &&
      !validateDepartmentName(contactData.department)
    ) {
      setErrorMessage(
        setDepartmentMsg,
        "Please enter a valid Department name."
      );
    }
    if (
      contactData.assistant !== null &&
      contactData.assistant !== "" &&
      !validateAssistantName(contactData.assistant)
    ) {
      setErrorMessage(setAssistantMsg, "Please enter a valid Assistant name.");
    }
    if (
      contactData.title !== null &&
      contactData.title !== "" &&
      !validateJobTitle(contactData.title)
    ) {
      setErrorMessage(setTitleMsg, "Please enter a valid title.");
    }
    if (
      contactData.skypeId !== null &&
      contactData.skypeId !== "" &&
      !validateSkypeId(contactData.skypeId)
    ) {
      setErrorMessage(setSkypeIdMsg, "Please enter a valid Skype Id.");
    }
    if (
      contactData.twitter !== null &&
      contactData.twitter !== "" &&
      !validateTwitterUsername(contactData.twitter)
    ) {
      setErrorMessage(setTwitterId, "Please enter a valid twitter Id.");
    }
    if (
      contactData.phone !== null &&
      contactData.phone !== "" &&
      !validateMobileNumber(contactData.phone)
    ) {
      setErrorMessage(setPhoneMsg, "Please enter a valid Phone Number.");
    }
    if (
      contactData.otherPhone !== null &&
      contactData.otherPhone !== "" &&
      !validateMobileNumber(contactData.otherPhone)
    ) {
      setErrorMessage(
        setOtherPhoneMsg,
        "Please enter a valid Other phone number."
      );
    }

    if (
      contactData.mobile !== null &&
      contactData.mobile !== "" &&
      !validateMobileNumber(contactData.mobile)
    ) {
      setErrorMessage(setMobileMsg, "Please enter a valid Mobile number.");
    }
    // if (
    //   contactData.reportingTo !== null &&
    //   contactData.reportingTo !== "" &&
    //  / !validateReportingTo(contactData.reportingTo)
    // ) {
    //   setErrorMessage(setReportingMsg, "Please enter a valid reportingTo.");
    // }
    if (contactData.emailId === null && contactData.emailId === "") {
      setErrorMessage(setEmailMsg, "Email cannot be empty.", 5000);
    } else {
      const isValid = emailValidation(contactData.emailId);
      setValidEmail(isValid);
      if (!isValid) {
        setErrorMessage(
          setEmailMsg,
          "Please enter a valid email address.",
          5000
        );
      }
    }
    if (existingClone) {
      if (
        updateData.mailingCity &&
        !validateCityIndia(updateData.mailingCity)
      ) {
        setErrorMessage(
          setMailingCityErrorMsg,
          "Please enter a valid Mailing City."
        );
      }
      if (
        updateData.mailingState &&
        !validateStateIndia(updateData.mailingState)
      ) {
        setErrorMessage(
          setMailingStateErrorMsg,
          "Please enter a valid Mailing State."
        );
      }
      if (
        updateData.mailingCountry &&
        !validateCountryName(updateData.mailingCountry)
      ) {
        setErrorMessage(
          setMailingCountryErrorMsg,
          "Please enter a valid Mailing Country."
        );
      }
      if (updateData.otherCity && !validateCityIndia(updateData.otherCity)) {
        setErrorMessage(
          setOtherCityErrorMsg,
          "Please enter a valid Other City."
        );
      }
      if (updateData.otherState && !validateStateIndia(updateData.otherState)) {
        setErrorMessage(
          setOtherStateErrorMsg,
          "Please enter a valid Other State."
        );
      }
      if (
        updateData.otherCountry &&
        !validateCountryName(updateData.otherCountry)
      ) {
        setErrorMessage(
          setOtherCountryErrorMsg,
          "Please enter a valid Other Country."
        );
      }
      if (updateData.mailingZip && !validateZipCode(updateData.mailingZip)) {
        setErrorMessage(setMailingZipMsg, "Please enter a valid Mailing zip.");
      }
      if (updateData.otherZip && !validateZipCode(updateData.otherZip)) {
        setErrorMessage(setOtherZipMsg, "Please enter a valid Other zip.");
      }
    } else {
      if (
        mailingAdd.mailingCity &&
        !validateCityIndia(mailingAdd.mailingCity)
      ) {
        setErrorMessage(
          setMailingCityErrorMsg,
          "Please enter a valid Mailing City."
        );
      }
      if (
        mailingAdd.mailingState &&
        !validateStateIndia(mailingAdd.mailingState)
      ) {
        setErrorMessage(
          setMailingStateErrorMsg,
          "Please enter a valid Mailing State."
        );
      }
      if (
        mailingAdd.mailingCountry &&
        !validateCountryName(mailingAdd.mailingCountry)
      ) {
        setErrorMessage(
          setMailingCountryErrorMsg,
          "Please enter a valid Mailing Country."
        );
      }
      if (otherAdd.otherCity && !validateCityIndia(otherAdd.otherCity)) {
        setErrorMessage(
          setOtherCityErrorMsg,
          "Please enter a valid Other City."
        );
      }
      if (otherAdd.otherState && !validateStateIndia(otherAdd.otherState)) {
        setErrorMessage(
          setOtherStateErrorMsg,
          "Please enter a valid Other State."
        );
      }
      if (
        otherAdd.otherCountry &&
        !validateCountryName(otherAdd.otherCountry)
      ) {
        setErrorMessage(
          setOtherCountryErrorMsg,
          "Please enter a valid Other Country."
        );
      }
      if (mailingAdd.mailingZip && !validateZipCode(mailingAdd.mailingZip)) {
        setErrorMessage(setMailingZipMsg, "Please enter a valid Mailing zip.");
      }
      if (otherAdd.otherZip && !validateZipCode(otherAdd.otherZip)) {
        setErrorMessage(setOtherZipMsg, "Please enter a valid Other zip.");
      }
    }

    if (!isValidForm) return;

    const formDataContact = new FormData();
    setSave(true);

    const requestBody = {
      loggedInUserId: loginUserId,
      contactOwnerId: selectedItem.userId,
      contactId: contactId,
      firstName: removeExtraSpaces(updateData.firstName),
      lastName: removeExtraSpaces(updateData.lastName),
      accountId: selectedAccountItem?.accountId,
      vendorId: selectedVendorItem?.vendorId,

      emailId: removeExtraSpaces(updateData.emailId),
      phone: removeExtraSpaces(updateData.phone),
      otherPhone: removeExtraSpaces(updateData.otherPhone),
      mobile: removeExtraSpaces(updateData.mobile),
      assistant: removeExtraSpaces(updateData.assistant),
      leadSource: updateData.leadSource.value,
      title: removeExtraSpaces(updateData.title),
      department: removeExtraSpaces(updateData.department),
      homePhone: removeExtraSpaces(updateData.homePhone),
      fax: removeExtraSpaces(updateData.fax),
      dateOfBirth: updateData.dateOfBirth,

      assistantPhone: removeExtraSpaces(updateData.assistantPhone),
      skypeId: removeExtraSpaces(updateData.skypeId),
      secondaryEmailId: removeExtraSpaces(updateData.secondaryEmailId), //updateData.secondaryEmailId,
      twitter: removeExtraSpaces(updateData.twitter),
      reportingTo: removeExtraSpaces(updateData.reportingTo),
      mailingStreet: removeExtraSpaces(updateData.mailingStreet),
      mailingCity: removeExtraSpaces(updateData.mailingCity),
      mailingState: removeExtraSpaces(updateData.mailingState),
      mailingZip: removeExtraSpaces(updateData.mailingZip),
      mailingCountry: removeExtraSpaces(updateData.mailingCountry),
      otherStreet: removeExtraSpaces(updateData.otherStreet),
      otherCity: removeExtraSpaces(updateData.otherCity),
      otherState: removeExtraSpaces(updateData.otherState),
      otherZip: removeExtraSpaces(updateData.otherZip),
      otherCountry: removeExtraSpaces(updateData.otherCountry),
      description: removeExtraSpaces(updateData.description),
      emailOptOut: emailOptOut,
      emailNotification: emailNotification,
    };
    formDataContact.append("contactDtlsInputs", JSON.stringify(requestBody));
    formDataContact.append("contactImage", updateData.contactImage);

    const headersResult = getHeaders(true);

    // Check if headers result indicates an error
    if (headersResult.error) {
      navigate("../"); // Redirect to login if token is missing
      return;
    }

    await axios
      .post(URI.updateContactDetails, formDataContact, {
        headers: headersResult,
      })
      .then((response) => {
        if (response.data.status === 200) {
          NotificationManager.success("", response.data.message, 5000);

          resetForm();
          setSelectedFiles([]);

          getAllContact(selectedOption);
          setInnerPage("list");
          if (e === "SAVE") {
            setInnerPage("list");
            setSelectedFiles([]);
          }
        } else {
          NotificationManager.error("", response.data.message, 5000);
        }
      })
      .catch((err) => {
        handleApiError(err, navigate);
      })
      .finally(() => {
        setSave(false);
      });
  };

  const addNewContact = async (e) => {
    let isValidForm = true;

    const setErrorMessage = (setter, message, timeout = 5000) => {
      setter(message);
      setTimeout(() => {
        setter("");
      }, timeout);
      isValidForm = false;
    };

    const contactData = editContact || existingClone ? updateData : formData;
    if (contactData.firstName === "") {
      setErrorMessage(setFirstNameErrorMsg, "First name can not be empty.");
    }
    if (contactData.lastName === "") {
      setErrorMessage(setLastNameErrorMsg, "Last name can not be empty.");
    }
    if (
      !validateFirstName(contactData.firstName) &&
      contactData.firstName.length > 0
    ) {
      setErrorMessage(setFirstNameErrorMsg, "Please enter a valid First name.");
    }
    if (
      contactData.lastName !== "" &&
      !validateLastName(contactData.lastName)
    ) {
      setErrorMessage(setLastNameErrorMsg, "Please enter a valid Last name.");
    }
    if (
      !validateDepartmentName(contactData.department) &&
      contactData.department.length > 0
    ) {
      setErrorMessage(
        setDepartmentMsg,
        "Please enter a valid Department name."
      );
    }
    if (
      !validateAssistantName(contactData.assistant) &&
      contactData.assistant.length > 0
    ) {
      setErrorMessage(setAssistantMsg, "Please enter a valid Assistant name.");
    }
    if (!validateJobTitle(contactData.title) && contactData.title.length > 0) {
      setErrorMessage(setTitleMsg, "Please enter a valid title.");
    }
    if (
      !validateSkypeId(contactData.skypeId) &&
      contactData.skypeId.length > 0
    ) {
      setErrorMessage(setSkypeIdMsg, "Please enter a valid Skype Id.");
    }
    if (
      !validateTwitterUsername(contactData.twitter) &&
      contactData.twitter.length > 0
    ) {
      setErrorMessage(setTwitterId, "Please enter a valid twitter Id.");
    }
    if (
      !validateMobileNumber(contactData.phone) &&
      contactData.phone.length > 0
    ) {
      setErrorMessage(setPhoneMsg, "Please enter a valid Phone Number.");
    }
    if (
      !validateMobileNumber(contactData.otherPhone) &&
      contactData.otherPhone.length > 0
    ) {
      setErrorMessage(
        setOtherPhoneMsg,
        "Please enter a valid Other phone number."
      );
    }

    if (
      !validateMobileNumber(contactData.mobile) &&
      contactData.mobile.length > 0
    ) {
      setErrorMessage(setMobileMsg, "Please enter a valid Mobile number.");
    }
    // if (
    // /  !validateReportingTo(contactData.reportingTo) &&
    //   contactData.reportingTo.length > 0
    // ) {
    //   setErrorMessage(setReportingMsg, "Please enter a valid reportingTo.");
    // }
    if (contactData.emailId === "") {
      setErrorMessage(setEmailMsg, "Email cannot be empty.", 5000);
    } else {
      const isValid = emailValidation(contactData.emailId);
      setValidEmail(isValid);
      if (!isValid) {
        setErrorMessage(
          setEmailMsg,
          "Please enter a valid email address.",
          5000
        );
      }
    }
    if (existingClone) {
      if (
        updateData.mailingCity &&
        !validateCityIndia(updateData.mailingCity)
      ) {
        setErrorMessage(
          setMailingCityErrorMsg,
          "Please enter a valid Mailing City."
        );
      }
      if (
        updateData.mailingState &&
        !validateStateIndia(updateData.mailingState)
      ) {
        setErrorMessage(
          setMailingStateErrorMsg,
          "Please enter a valid Mailing State."
        );
      }
      if (
        updateData.mailingCountry &&
        !validateCountryName(updateData.mailingCountry)
      ) {
        setErrorMessage(
          setMailingCountryErrorMsg,
          "Please enter a valid Mailing Country."
        );
      }
      if (updateData.otherCity && !validateCityIndia(updateData.otherCity)) {
        setErrorMessage(
          setOtherCityErrorMsg,
          "Please enter a valid Other City."
        );
      }
      if (updateData.otherState && !validateStateIndia(updateData.otherState)) {
        setErrorMessage(
          setOtherStateErrorMsg,
          "Please enter a valid Other State."
        );
      }
      if (
        updateData.otherCountry &&
        !validateCountryName(updateData.otherCountry)
      ) {
        setErrorMessage(
          setOtherCountryErrorMsg,
          "Please enter a valid Other Country."
        );
      }
      if (updateData.mailingZip && !validateZipCode(updateData.mailingZip)) {
        setErrorMessage(setMailingZipMsg, "Please enter a valid Mailing zip.");
      }
      if (updateData.otherZip && !validateZipCode(updateData.otherZip)) {
        setErrorMessage(setOtherZipMsg, "Please enter a valid Other zip.");
      }
    } else {
      if (
        mailingAdd.mailingCity &&
        !validateCityIndia(mailingAdd.mailingCity)
      ) {
        setErrorMessage(
          setMailingCityErrorMsg,
          "Please enter a valid Mailing City."
        );
      }
      if (
        mailingAdd.mailingState &&
        !validateStateIndia(mailingAdd.mailingState)
      ) {
        setErrorMessage(
          setMailingStateErrorMsg,
          "Please enter a valid Mailing State."
        );
      }
      if (
        mailingAdd.mailingCountry &&
        !validateCountryName(mailingAdd.mailingCountry)
      ) {
        setErrorMessage(
          setMailingCountryErrorMsg,
          "Please enter a valid Mailing Country."
        );
      }
      if (otherAdd.otherCity && !validateCityIndia(otherAdd.otherCity)) {
        setErrorMessage(
          setOtherCityErrorMsg,
          "Please enter a valid Other City."
        );
      }
      if (otherAdd.otherState && !validateStateIndia(otherAdd.otherState)) {
        setErrorMessage(
          setOtherStateErrorMsg,
          "Please enter a valid Other State."
        );
      }
      if (
        otherAdd.otherCountry &&
        !validateCountryName(otherAdd.otherCountry)
      ) {
        setErrorMessage(
          setOtherCountryErrorMsg,
          "Please enter a valid Other Country."
        );
      }
      if (otherAdd.mailingZip && !validateZipCode(otherAdd.mailingZip)) {
        setErrorMessage(setMailingZipMsg, "Please enter a valid Mailing zip.");
      }
      if (otherAdd.otherZip && !validateZipCode(otherAdd.otherZip)) {
        setErrorMessage(setOtherZipMsg, "Please enter a valid Other zip.");
      }
    }

    if (!isValidForm) return;

    const formDataContact = new FormData();
    let requestBody = {};
    e === "ADD" ? setSave(true) : setSaveAndNew(true);

    if (existingClone) {
      requestBody = {
        loggedInUserId: loginUserId,
        contactOwnerId: selectedItem.userId,
        contactId: contactId,
        firstName: removeExtraSpaces(updateData.firstName),
        lastName: removeExtraSpaces(updateData.lastName),
        accountId: selectedAccountItem?.accountId,
        vendorId: selectedVendorItem?.vendorId,

        emailId: removeExtraSpaces(updateData.emailId),
        phone: removeExtraSpaces(updateData.phone),
        otherPhone: removeExtraSpaces(updateData.otherPhone),
        mobile: removeExtraSpaces(updateData.mobile),
        assistant: removeExtraSpaces(updateData.assistant),
        leadSource: updateData.leadSource.value,
        title: removeExtraSpaces(updateData.title),
        department: removeExtraSpaces(updateData.department),
        homePhone: removeExtraSpaces(updateData.homePhone),
        fax: removeExtraSpaces(updateData.fax),
        dateOfBirth: updateData.dateOfBirth,

        assistantPhone: removeExtraSpaces(updateData.assistantPhone),
        skypeId: removeExtraSpaces(updateData.skypeId),
        secondaryEmailId: removeExtraSpaces(updateData.secondaryEmailId), //updateData.secondaryEmailId,
        twitter: removeExtraSpaces(updateData.twitter),
        reportingTo: selectedReportingItem.moduleId,
        mailingStreet: removeExtraSpaces(updateData.mailingStreet),
        mailingCity: removeExtraSpaces(updateData.mailingCity),
        mailingState: removeExtraSpaces(updateData.mailingState),
        mailingZip: removeExtraSpaces(updateData.mailingZip),
        mailingCountry: removeExtraSpaces(updateData.mailingCountry),
        otherStreet: removeExtraSpaces(updateData.otherStreet),
        otherCity: removeExtraSpaces(updateData.otherCity),
        otherState: removeExtraSpaces(updateData.otherState),
        otherZip: removeExtraSpaces(updateData.otherZip),
        otherCountry: removeExtraSpaces(updateData.otherCountry),
        description: removeExtraSpaces(updateData.description),
        emailOptOut: emailOptOut,
      };
    } else {
      requestBody = {
        contactOwnerId: selectedItem.userId,
        loggedInUserId: loginUserId,
        firstName: removeExtraSpaces(formData.firstName),
        lastName: removeExtraSpaces(formData.lastName),
        accountId: selectedAccountItem?.accountId,
        vendorId: selectedVendorItem?.vendorId,
        emailId: removeExtraSpaces(formData.emailId),
        phone: removeExtraSpaces(formData.phone),
        otherPhone: removeExtraSpaces(formData.otherPhone),
        mobile: removeExtraSpaces(formData.mobile),
        assistant: removeExtraSpaces(formData.assistant),
        leadSource: formData.leadSource.value,
        title: removeExtraSpaces(formData.title),
        department: removeExtraSpaces(formData.department),
        homePhone: removeExtraSpaces(formData.homePhone),
        fax: removeExtraSpaces(formData.fax),
        dateOfBirth: formData.dateOfBirth,
        assistantPhone: removeExtraSpaces(formData.assistantPhone),
        skypeId: removeExtraSpaces(formData.skypeId),
        secondaryEmailId: removeExtraSpaces(formData.secondaryEmailId),
        twitter: removeExtraSpaces(formData.twitter),
        reportingTo: selectedReportingItem.moduleId,
        mailingStreet: removeExtraSpaces(mailingAdd.mailingStreet),
        mailingCity: removeExtraSpaces(mailingAdd.mailingCity),
        mailingState: removeExtraSpaces(mailingAdd.mailingState),
        mailingZip: removeExtraSpaces(mailingAdd.mailingZip),
        mailingCountry: removeExtraSpaces(mailingAdd.mailingCountry),
        otherStreet: removeExtraSpaces(otherAdd.otherStreet),
        otherCity: removeExtraSpaces(otherAdd.otherCity),
        otherState: removeExtraSpaces(otherAdd.otherState),
        otherZip: removeExtraSpaces(otherAdd.otherZip),
        otherCountry: removeExtraSpaces(otherAdd.otherCountry),
        description: removeExtraSpaces(formData.description),
        emailOptOut: emailOptOut === true ? "true" : "false",
        productId:
          pastViewInfo?.view !== "" && fetchProdInfo
            ? fetchProdInfo?.productId
            : null, // it will pass the productId when we are routing from other page
      };
    }

    formDataContact.append("contactDtlsInputs", JSON.stringify(requestBody));
    formDataContact.append(
      "contactImage",
      existingClone ? updateData.contactImage : formData.contactImage
    );

    const headersResult = getHeaders(true);

    // Check if headers result indicates an error
    if (headersResult.error) {
      navigate("../"); // Redirect to login if token is missing
      return;
    }

    await axios
      .post(URI.createContact, formDataContact, {
        headers: headersResult,
      })
      .then((response) => {
        if (response.data.status === 200) {
          NotificationManager.success("", response.data.message, 5000);
          if (pastViewInfo) {
            setCurrentView(pastViewInfo.view);
            setInnerPage(pastViewInfo.innerPage);
            setPastViewInfo((prev) => ({
              ...prev,
              view: null,
              innerPage: null,
              undo: currentView,
            }));
            return;
          }
          resetForm();
          getAllContact(selectedOption);

          setSelectedFiles(null);
          fileInputRef.current.value = "";

          if (e === "SAVE AND NEW") {
            setInnerPage("create");
          } else {
            setInnerPage("list");
          }
        } else {
          NotificationManager.error("", response.data.message, 5000);
        }
      })
      .catch((err) => {
        handleApiError(err, navigate);
      })
      .finally(() => {
        setSaveAndNew(false);
        setSave(false);
      });
  };

  const insertNote = async () => {
    const formData = new FormData();
    formData.append("moduleId", contactId);
    formData.append("loggedInUserId", loginUserId);
    formData.append("moduleName", "CONTACTS");
    formData.append("noteDescription", note);
    formData.append("noteTitle", addTitle);

    selectedNoteFiles.forEach((file) => {
      formData.append("noteImages", file);
    });

    if (note.length > 1) {
      const headersResult = getHeaders(true);

      // Check if headers result indicates an error
      if (headersResult.error) {
        navigate("../"); // Redirect to login if token is missing
        return;
      }

      await axios
        .post(URI.insertNote, formData, {
          headers: headersResult,
        })
        .then((response) => {
          if (response.data.status === 200) {
            setShowButtons(false);
            setAddTitle("");
            setNote("");
            setSelectedNoteFiles([]);
            getNotesDetails(contactId, selectRecent);
          } else {
            NotificationManager.error("", response.data.message, 5000);
          }
        })
        .catch((error) => {
          handleApiError(error, navigate);
        });
    } else {
      NotificationManager.error("Empty", "Note can not be empty.", 5000);
    }
  };
  const getAllContact = async (selectedOption) => {
    setIsLoading(true);
    setSelectedOption(selectedOption);
    const requestBody = {
      loggedInUserId: loginUserId,
      customFilter: selectedOption.value,
      pageNo: currentPage,
      pageSize: perPage,
    };

    const headersResult = getHeaders(true);

    // Check if headers result indicates an error
    if (headersResult.error) {
      navigate("../"); // Redirect to login if token is missing
      return;
    }
    await axios
      .post(URI.getAllContact, requestBody, {
        headers: headersResult,
      })
      .then((response) => {
        if (response.data.status === 200) {
          setAllContactDetails(response.data.data.allContactDetails);
          setFiltereContactDetails(response.data.data.allContactDetails);
          setTotalItems(response.data.data.rowCount);
          setIsLoading(false);
        } else {
          NotificationManager.error("", response.data.message, 5000);
          setIsLoading(false);
        }
      })
      .catch((err) => {
        handleApiError(err, navigate);
      });
  };

  const getAllTimelineContacts = async () => {
    const headersResult = getHeaders(true);

    // Check if headers result indicates an error
    if (headersResult.error) {
      navigate("../"); // Redirect to login if token is missing
      return;
    }

    await axios
      .get(URI.getContactTimeline + "/" + loginUserId + "/" + contactId, {
        headers: headersResult,
      })
      .then((response) => {
        if (response.data.status === 200) {
          setTimeLineDataList(response.data.data.getContactDetails);
          setContactPageView("timeline");
        }
      })
      .catch(async (error) => {
        handleApiError(error, navigate);
      });
  };

  const getAllInfoShare = async (contactId) => {
    setIsLoading(true);
    const requestBody = {
      moduleId: contactId,
      loggedInUserId: loginUserId,
    };

    const headersResult = getHeaders(true);

    // Check if headers result indicates an error
    if (headersResult.error) {
      navigate("../"); // Redirect to login if token is missing
      return;
    }

    await axios
      .post(URI.getShareInfo, requestBody, {
        headers: headersResult,
      })
      .then((response) => {
        if (response.data.status === 200) {
          const hasSharedItems =
            (response.data.data.getSharingInfoUsers &&
              response.data.data.getSharingInfoUsers.length > 0) ||
            (response.data.data.getSharingInfoRoles &&
              response.data.data.getSharingInfoRoles.length > 0) ||
            (response.data.data.getSharingInfoGroups &&
              response.data.data.getSharingInfoGroups.length > 0)||
              (response.data.data.getSharingInfoPublic &&
                response.data.data.getSharingInfoPublic.length > 0);

          const userShareMemberIds = response.data.data.getSharingInfoUsers.map(
            (user) => user.shareModuleId
          );
          const roleShareMemberIds = response.data.data.getSharingInfoRoles.map(
            (role) => role.shareModuleId
          );
          const groupShareMemberIds =
            response.data.data.getSharingInfoGroups.map(
              (group) => group.shareModuleId
            );
            const groupPublicSharedMemberIds =
            response.data.data.getSharingInfoPublic.map(
              (user) => user.shareModuleId
            );
          setShareMemberIds([
            ...userShareMemberIds,
            ...roleShareMemberIds,
            ...groupShareMemberIds,
            ...groupPublicSharedMemberIds
          ]);
          setShareUserList(response.data.data.getSharingInfoUsers);
          setShareRoleList(response.data.data.getSharingInfoRoles);
          setShareGroupList(response.data.data.getSharingInfoGroups);
          setSharePublicList(response.data.data.getSharingInfoPublic);
          if (hasSharedItems) {
            setShareInfoModal(true);
          } else {
            setShareModal(true);
          }
          setIsLoading(false);
        }
      })
      .catch((err) => {
        handleApiError(err, navigate);
      });
  };

  const getContactOverview = async (contactId) => {
    let contactInfo = {};

    const headersResult = getHeaders(true);

    // Check if headers result indicates an error
    if (headersResult.error) {
      navigate("../"); // Redirect to login if token is missing
      return;
    }
    await axios
      .get(URI.getContactOverview + loginUserId + "/" + contactId, {
        headers: headersResult,
      })
      .then((response) => {
        if (response.data.status === 200) {
          setSelectedContact(response.data.data.contactInfo);
          contactInfo = response.data.data.contactInfo;
          contactInfo.contactOwner1 =
            response.data.data.contactInfo.contactOwner;
          contactInfo.department1 = response.data.data.contactInfo.department;
          contactInfo.email1 = response.data.data.contactInfo.emailId;
          contactInfo.mobile1 = response.data.data.contactInfo.mobile;
          contactInfo.skypeId1 = response.data.data.contactInfo.skypeId;
          contactInfo.assistant1 = response.data.data.contactInfo.assistant;
          contactInfo.assistantPhone1 =
            response.data.data.contactInfo.assistantPhone;
          contactInfo.fax1 = response.data.data.contactInfo.fax;
          contactInfo.homePhone1 = response.data.data.contactInfo.homePhone;
          contactInfo.title1 = response.data.data.contactInfo.title;
          contactInfo.mailingCountry1 =
            response.data.data.contactInfo.mailingCountry;
          contactInfo.mailingState1 =
            response.data.data.contactInfo.mailingState;
          contactInfo.mailingCity1 = response.data.data.contactInfo.mailingCity;
          contactInfo.mailingStreet1 =
            response.data.data.contactInfo.mailingStreet;
          contactInfo.mailingZip1 = response.data.data.contactInfo.mailingZip;
          contactInfo.otherCountry1 =
            response.data.data.contactInfo.otherCountry;
          contactInfo.otherState1 = response.data.data.contactInfo.otherState;
          contactInfo.otherCity1 = response.data.data.contactInfo.otherCity;
          contactInfo.otherStreet1 = response.data.data.contactInfo.otherStreet;
          contactInfo.otherZip1 = response.data.data.contactInfo.otherZip;
          contactInfo.twitter1 = response.data.data.contactInfo.twitter;
          contactInfo.secondaryEmailId1 =
            response.data.data.contactInfo.secondaryEmailId;
          contactInfo.dateOfBirth1 = response.data.data.contactInfo.dateOfBirth;
          contactInfo.emailOptOut = response.data.data.contactInfo.emailOptOut;
          contactInfo.emailOptOut1 = response.data.data.contactInfo.emailOptOut;
          contactInfo.otherPhone1 = response.data.data.contactInfo.otherPhone;
          contactInfo.phoneNumber1 = response.data.data.contactInfo.phone;
          contactInfo.leadSourceInfo = {
            label: response.data.data.contactInfo.leadSource,
            value: response.data.data.contactInfo.leadSource,
          };
          contactInfo.leadSource = {
            label: response.data.data.contactInfo.leadSource,
            value: response.data.data.contactInfo.leadSource,
          };
          setInnerPage("editContact");
          setContactInfo(contactInfo);
          const updatedFields = {
            contactOwner: response.data.data.contactInfo.contactOwner,
            vendorOwner: response.data.data.contactInfo.vendorOwner,
            contactOwner1: response.data.data.contactInfo.contactOwner,
            leadSource: {
              label: response.data.data.contactInfo.leadSource,
              value: response.data.data.contactInfo.leadSource,
            },
            leadSourceInfo: {
              label: response.data.data.contactInfo.leadSource,
              value: response.data.data.contactInfo.leadSource,
            },
            firstName: response.data.data.contactInfo.firstName,
            lastName: response.data.data.contactInfo.lastName,
            accountName: response.data.data.contactInfo.accountName,
            title: response.data.data.contactInfo.title,
            phone: response.data.data.contactInfo.phone,
            mobile: response.data.data.contactInfo.mobile,
            mobile1: response.data.data.contactInfo.mobile,
            skypeId1: response.data.data.contactInfo.skypeId,
            assistant1: response.data.data.contactInfo.assistant,
            assistantPhone1: response.data.data.contactInfo.assistantPhone,
            fax1: response.data.data.contactInfo.fax,
            homePhone1: response.data.data.contactInfo.homePhone,
            title1: response.data.data.contactInfo.title,
            mailingCountry1: response.data.data.contactInfo.mailingCountry,
            mailingState1: response.data.data.contactInfo.mailingState,
            mailingCity1: response.data.data.contactInfo.mailingCity,
            mailingStreet1: response.data.data.contactInfo.mailingStreet,
            mailingZip1: response.data.data.contactInfo.mailingZip,
            otherCountry1: response.data.data.contactInfo.otherCountry,
            otherState1: response.data.data.contactInfo.otherState,
            otherCity1: response.data.data.contactInfo.otherCity,
            otherStreet1: response.data.data.contactInfo.otherStreet,
            otherZip1: response.data.data.contactInfo.otherZip,
            twitter1: response.data.data.contactInfo.twitter,
            secondaryEmailId1: response.data.data.contactInfo.secondaryEmailId,
            dateOfBirth1: response.data.data.contactInfo.dateOfBirth,
            emailOptOut1: response.data.data.contactInfo.emailOptOut,
            emailOptOut: response.data.data.contactInfo.emailOptOut,
            otherPhone1: response.data.data.contactInfo.otherPhone,
            phoneNumber1: response.data.data.contactInfo.phone,
            emailId: response.data.data.contactInfo.emailId,
            email1: response.data.data.contactInfo.emailId,
            fax: response.data.data.contactInfo.fax,
            skypeId: response.data.data.contactInfo.skypeId,
            secondaryEmailId: response.data.data.contactInfo.secondaryEmailId,
            twitter: response.data.data.contactInfo.twitter,
            otherPhone: response.data.data.contactInfo.otherPhone,
            assistant: response.data.data.contactInfoassistant,
            department: response.data.data.contactInfo.department,
            department1: response.data.data.contactInfo.department,
            homePhone: response.data.data.contactInfo.homePhone,
            dateOfBirth: response.data.data.contactInfo.dateOfBirth,

            assistantPhone: response.data.data.contactInfo.assistantPhone,
            reportingTo: response.data.data.contactInfo.reportingTo,
            mailingCountry: response.data.data.contactInfo.mailingCountry,
            mailingState: response.data.data.contactInfo.mailingState,
            mailingCity: response.data.data.contactInfo.mailingCity,
            mailingStreet: response.data.data.contactInfo.mailingStreet,
            mailingZip: response.data.data.contactInfo.mailingZip,
            otherCountry: response.data.data.contactInfo.otherCountry,
            otherState: response.data.data.contactInfo.otherStreet,
            otherCity: response.data.data.contactInfo.otherCity,
            otherStreet: response.data.data.contactInfo.otherStreet,
            otherZip: response.data.data.contactInfo.otherZip,
            description: response.data.data.contactInfo.description,
          };
          setSelectedItem({
            name: response.data.data.contactInfo.contactOwner,
            userId: response.data.data.contactInfo.contactOwnerId,
          });

          setSelectedAccountItem(
            accountDetails.find(
              (user) =>
                user.accountId === response.data.data.contactInfo.accountId
            )
          );
          setSelectedVendorItem({
            vendorName: response.data.data.contactInfo.vendorName,
            vendorId: response.data.data.contactInfo.vendorId,
          });
          setUpdatedFields(updatedFields);
          setEmailOptOut(response.data.data.contactInfo.emailOptOut);
          setProductsList(response.data.data.productDtls);
          setCampaignList(response.data.data.getAssignedCampaignsDtls);
          setOpenActivities(response.data.data.openActivitiesDtls);
          setOpenActivitiesCalls(response.data.data.openActivitiesCalls);
          setOpenActivitiesMeetings(response.data.data.openActivitiesMeetings);
          setCloseActivities(response.data.data.closedActivitiesDtls);
          setCloseActivitiesCalls(response.data.data.closedActivitiesCalls);
          setInvitedMeetings(response.data.data.getInvitedMeetings);
          setCloseActivitiesMeetings(
            response.data.data.closedActivitiesMeetings
          );
          setDeals(response.data.data.dealsDtls);
          setFilterQuotesDetails(response.data.data.quotesDtls);
          setSalesOrderList(response.data.data.salesDtls);
          setPurchaseOrderList(response.data.data.purchaseDtls);
          setInvoiceList(response.data.data.invoiceDtls);
        } else {
          NotificationManager.error("", response.data.message, 5000);
        }
      })
      .catch((err) => {
        handleApiError(err, navigate);
      });
  };

  const handleChangeIndDropDown = (selectedOption, field) => {
    setUpdatedFields((prevFields) => ({
      ...prevFields,
      [field]: selectedOption,
    }));
  };

  const scrollToSection = (sectionId) => {
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: "smooth" });
    }
  };

  const handleUpdateClick = async (field) => {
    var value;
    let requestBody = {};
    const isObj =
      typeof contactInfo[field] === "object" &&
      contactInfo[field] !== null &&
      !Array.isArray(contactInfo[field]);

    if (field === "leadSource" || field === "leadSourceInfo") {
      value = updatedFields[field].value;
    } else {
      value = updatedFields[field];
    }
    const fieldMappings = {
      email1: "emailId",
      phoneNumber1: "phone",
      leadSourceInfo: "leadSource",
      contactOwner1: "contactOwner",
      mobile1: "mobile",
      department1: "department",
      skypeId1: "skypeId",
      assistant1: "assistant",
      assistantPhone1: "assistantPhone",
      fax1: "fax",
      homePhone1: "homePhone",
      title1: "title",
      mailingCountry1: "mailingCountry",
      mailingState1: "mailingState",
      mailingCity1: "mailingCity",
      mailingStreet1: "mailingStreet",
      mailingZip1: "mailingZip",
      otherCountry1: "otherCountry",
      otherState1: "otherStreet",
      otherCity1: "otherCity",
      otherStreet1: "otherStreet",
      otherZip1: "otherZip",
      twitter1: "twitter",
      secondaryEmailId1: "secondaryEmailId",
      dateOfBirth1: "dateOfBirth",
      otherPhone1: "otherPhone",
      emailOptOut1: "emailOptOut",
    };
    // if (fieldMappings.hasOwnProperty(field)) {
    //   field = fieldMappings[field];
    // } not supported by eslint
    if (Object.hasOwn(fieldMappings, field)) {
      field = fieldMappings[field];
    }

    // if (field === "emailOptOut") {
    //   value = contactInfo[field] ? "true" : "false";
    // }

    setApiLoader(true);
    try {
      const formDataContact = new FormData();

      if (field === "emailNotification") {
        requestBody = {
          // contactOwnerId: selectedItem.userId,
          contactOwnerId: selectedItem?.userId,
          contactId: contactId,
          loggedInUserId: loginUserId,
          emailNotification: emailNotification,
        };
      } else {
        requestBody = {
          contactId: contactId,
          loggedInUserId: loginUserId,

          [field]: value,
        };
      }

      const compareValue = isObj
        ? contactInfo[field].value
        : contactInfo[field];

      if (value !== compareValue || field === "emailNotification") {
        formDataContact.append(
          "contactDtlsInputs",
          JSON.stringify(requestBody)
        );
        const headersResult = getHeaders(true);

        // Check if headers result indicates an error
        if (headersResult.error) {
          navigate("../"); // Redirect to login if token is missing
          return;
        }

        const response = await axios.post(
          URI.individualUpdateContact,
          formDataContact,
          {
            headers: headersResult,
          }
        );

        if (response.data.status === 200) {
          NotificationManager.success("", response.data.message, 5000);
          setContactInfo((prevDetails) => ({
            ...prevDetails,
            [field]: updatedFields[field],
          }));

          setEditingFields((prevFields) => ({
            ...prevFields,
            [field]: false,
          }));

          getContactOverview(contactId);
          setShowModalType("");
        } else {
          NotificationManager.error("", response.data.message, 5000);
        }

        const resetFields = Object.fromEntries(
          Object.keys(editingFields).map((key) => [key, false])
        );
        setEditingFields({
          ...resetFields,
        });
        setShowType("");
      }
    } catch (error) {
      handleApiError(error, navigate);
    } finally {
      setApiLoader(false);
    }
  };

  const handleIndividualInputChange = (field, value) => {
    setUpdatedFields((prevFields) => ({
      ...prevFields,
      [field]: value,
    }));
  };

  const handleEditClick = (field) => {
    setUpdatedFields((prevFields) => ({
      ...prevFields,
      [field]: contactInfo[field],
    }));
    setEditingFields((prevFields) => ({
      ...prevFields,
      [field]: true,
    }));
  };

  const handleCancelClick = (field) => {
    setEditingFields((prevFields) => ({
      ...prevFields,
      [field]: false,
    }));
  };

  // handleSelectedLeadChange

  const handleSelectedChangeOwner = (value) => {
    setSelectedItem(value);
  };

  // for Contact  Owner user List dropdown
  const handleOwnerInputClick = (e) => {
    e.stopPropagation();
    setListVisible(!listVisible);
    setVendorListVisible(false);
    setAccountListVisible(false);
  };

  const handleUserItemClick = (item) => {
    setSelectedItem(item);
    setListVisible(false);
  };

  const handleSelectedLeadChange = (value) => {
    setSelectedItem(value);
  };

  // for Account Name List dropdown
  const handleAccountClick = (e) => {
    // if (!accountListVisible) {
    //   getAllAccountDetails();
    // }
    setFetchAccInfo(null);
    e.stopPropagation();
    setAccountListVisible(!accountListVisible);
    setVendorListVisible(false);
    setListVisible(false);
    setReportingListVisible(false);
  };

  const handleAccountItemClick = (item) => {
    setSelectedAccountItem(item);
    setAccountListVisible(false);
    getAllAssignModules("ACCOUNTS", ["CONTACTS"], item.accountId);
  };

  // for Vendor Name List dropdown

  const handleVendorClick = (e) => {
    // if (!vendorListVisible) {
    //   getAllVendorDetails();
    // }
    e.stopPropagation();
    setVendorListVisible(!vendorListVisible);
    setListVisible(false);
    setAccountListVisible(false);
    setReportingListVisible(false);
  };

  const handleVendorItemClick = (item) => {
    setSelectedVendorItem(item);
    setVendorListVisible(false);
  };

  // individual Input field

  // const handleSelect = (eventKey) => {
  //   setSelectedIndividualOption(eventKey);
  // };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handlePageDropDowmChange = (selectedOption) => {
    setCurrentPage(1);

    setPerPage(parseInt(selectedOption.value));
  };
  const totalPages = Math.ceil(Number(totalItems) / perPage);

  const handleColumnCheckboxChangeInvoices = (name, isChecked) => {
    setSavingHeadingsInvoices((prevHeadings) =>
      prevHeadings.map((heading) =>
        heading.name === name ? { ...heading, isChecked } : heading
      )
    );
  };

  const handleColumnCheckboxChangeSales = (name, isChecked) => {
    setSavingHeadingsSales((prevHeadings) =>
      prevHeadings.map((heading) =>
        heading.name === name ? { ...heading, isChecked } : heading
      )
    );
  };

  const handleColumnCheckboxChangePurchase = (name, isChecked) => {
    setSavingHeadingsPurchase((prevHeadings) =>
      prevHeadings.map((heading) =>
        heading.name === name ? { ...heading, isChecked } : heading
      )
    );
  };

  const handleColumnCheckboxChangeDeals = (name, isChecked) => {
    setSavingHeadingsDeals((prevHeadings) =>
      prevHeadings.map((heading) =>
        heading.name === name ? { ...heading, isChecked } : heading
      )
    );
  };

  // Function to handle selection and update selected options
  const handleFieldClick = (field) => {
    if (field.type === "dropdown") {
      const fieldValue = field.name
        .replace(/\s+/g, "")
        .replace(/^(.)/, (match) => match.toLowerCase());

      setClickedDropdownObj(contactInfo[fieldValue]);
      setUpdatedFields((prevFields) => ({
        ...prevFields,
        [fieldValue]: contactInfo[fieldValue],
      }));
    } else if (field.type === "checkbox") {
      // setClickedEmailOtp(contactInfo["emailOptOut"]);
      setUpdatedFields((prevFields) => ({
        ...prevFields,
        emailOptOut: contactInfo["emailOptOut"],
      }));
    }

    const updatedColumns = [...fieldLabels];
    const existedObj = updatedColumns.find(
      (field) => field.name === selectedField
    );
    const selectedObj = updatedColumns.find((item) => item.name === field.name);

    const existedObjIndex = updatedColumns.findIndex(
      (field) => field.name === existedObj.name
    );
    const selectedObjIndex = updatedColumns.findIndex(
      (field) => field.name === selectedObj.name
    );

    updatedColumns[existedObjIndex].isShowed = false;
    updatedColumns[selectedObjIndex].isShowed = true;

    const removedObj = updatedColumns.splice(existedObjIndex, 1)[0];
    updatedColumns.splice(existedObjIndex, 0, selectedObj);

    updatedColumns.push(removedObj);
    const uniqueData = updatedColumns.filter(
      (item, index, self) =>
        index === self.findIndex((t) => t.name === item.name)
    );
    setFieldLabels(uniqueData);
    setShowSearchFields(uniqueData);
  };

  const updateNote = async (noteId) => {
    const formData = new FormData();
    formData.append("moduleId", contactId);
    formData.append("loggedInUserId", loginUserId);
    formData.append("moduleName", "CONTACTS");
    formData.append("noteDescription", updateNotes.description);
    formData.append("noteTitle", updateNotes.title);
    formData.append("noteId", noteId);
    formData.append("deletedImageNames", deletedImg.toString());

    selectedNoteFiles.forEach((file) => {
      formData.append("noteImages", file);
    });
    const headersResult = getHeaders(true);

    // Check if headers result indicates an error
    if (headersResult.error) {
      navigate("../"); // Redirect to login if token is missing
      return;
    }
    await axios
      .post(URI.updateNote, formData, {
        headers: headersResult,
      })
      .then((response) => {
        if (response.data.status === 200) {
          setShowButtons(false);
          setEditNote(false);
          setUpdateNotes({
            description: "",
            title: "",
            noteImageList: [],
          });
          NotificationManager.success("", response.data.message, 5000);
          setSelectedNoteFiles([]);
          getNotesDetails(contactId, selectRecent);
          setDeletedImg("");
        } else {
          setDeletedImg("");
          setSelectedNoteFiles([]);
          NotificationManager.error("", response.data.message, 5000);
        }
      })
      .catch((error) => {
        handleApiError(error, navigate);
      });
  };

  const deleteContact = async () => {
    const requestBody = {
      loggedInUserId: loginUserId,
      contactId: contactId,
    };

    const headersResult = getHeaders(true);

    // Check if headers result indicates an error
    if (headersResult.error) {
      navigate("../"); // Redirect to login if token is missing
      return;
    }

    await axios
      .post(URI.deleteContact, requestBody, {
        headers: headersResult,
      })

      .then((response) => {
        if (response.data.status === 200) {
          NotificationManager.success("", response.data.message, 5000);
          getAllContact(selectedOption);
          setShowOptionsContact(false);
          setShowModalType("");
          setInnerPage("list");
        } else {
          NotificationManager.error("", response.data.message, 5000);
          setShowOptionsContact(false);
          setShowModalType("");
        }
      })
      .catch(function (error) {
        handleApiError(error, navigate);
      });
  };

  const handleSelectCheckboxChange = (event) => {
    const { value } = event.target;

    const selectedCheckbox = { moduleId: value };

    setSelectedCheckboxes((prevSelectedCheckboxes) => {
      const isAlreadySelected = prevSelectedCheckboxes.some(
        (checkbox) => checkbox.moduleId === value
      );

      if (isAlreadySelected) {
        return prevSelectedCheckboxes.filter(
          (checkbox) => checkbox.moduleId !== value
        );
      } else {
        return [...prevSelectedCheckboxes, selectedCheckbox];
      }
    });
  };

  const deleteUpdateTransferMass = async () => {
    const { value } = selectedObj;
    let url = URI.massDelete;
    const requestBody = {
      loggedInUserId: loginUserId,
      moduleName: "CONTACTS",
      massAction: modalType === "Mass Delete" ? "DELETE" : "EDIT",
      moduleIdList: selectedCheckboxes,
    };
    if (modalType === "Mass Update") {
      requestBody[value] = massUpdateValue;
      url = URI.massUpdate;
    } else if (modalType === "Mass Transfer") {
      requestBody.ownerId = selectedItem.userId;
      url = URI.massTransfer;
    }
    const headersResult = getHeaders(true);

    // Check if headers result indicates an error
    if (headersResult.error) {
      navigate("../"); // Redirect to login if token is missing
      return;
    }

    await axios
      .post(url, requestBody, {
        headers: headersResult,
      })
      .then((response) => {
        if (response.data.status === 200) {
          NotificationManager.success("", response.data.message, 5000);
          getAllContact(selectedOption);
          setSelectedCheckboxes([]);
          setInnerPage("list");
          setShowModalType("");
          setShowSearchFields(fieldDropdowns);
          setMassUpdateValue("");
        } else {
          NotificationManager.error("", response.data.message, 5000);
        }
      })
      .catch(function (error) {
        handleApiError(error, navigate);
      });
  };

  const nodataPage = () => {
    return (
      <div className="d-flex flex-column justify-content-center align-items-center  ">
        <img src={noDataContact} alt="img" height={200} width={200} />
        <h5 className="opacity-75">Manage Contact</h5>
        <p className="opacity-75 p-0 m-0">
          Quotes are legal agreements between a customer and a vector
        </p>
        <p className="opacity-75 p-0 m-0">
          to deliver the requested product(s) at the agreed upon price
        </p>
        <p className="opacity-75 p-0 m-0">within the specified time period.</p>
        <button
          className="createContactButtonSave px-2 mt-4 cursorPointer"
          style={{ width: "15%" }}
          onClick={() => setInnerPage("create")}
        >
          Create a Contact
        </button>
      </div>
    );
  };

  useEffect(() => {
    if (fetchContactInfo && innerPage === "editContact") {
      getContactOverview(fetchContactInfo?.contactId);
      setContactId(fetchContactInfo?.contactId);
    }
  }, []);

  const handleContactsView = () => {
    if (innerPage === "list") {
      return (
        <div>
          {isLoading ? (
            <>
              <Loader />
            </>
          ) : (
            <>
              {filteredContactDetails &&
              filteredContactDetails.length === 0 &&
              selectedOption.value === "all_contacts" ? (
                <>{nodataPage()}</>
              ) : (
                <>
                  <div className="creteContactBar">
                    {selectedCheckboxes.length > 0 ? (
                      <div className="d-flex w-50 align-items-center gap-2">
                        <Select
                          options={massOptions}
                          styles={customCommonStyles("100%")}
                          value={{
                            value: "Action",
                            label: "Action",
                          }}
                          onChange={(option) => {
                            if (option.value === "Mass Update") {
                              setShowSearchFields(contactColumn);
                              setSelectedObj(contactColumn[0]);
                            } else if (option.value === "Mass Transfer") {
                              setSelectedItem(userList[0]);
                            }
                            setMassUpdateValue("");
                            setShowModalType(option.value);
                          }}
                          className="w-25"
                          isSearchable={false}
                        />
                      </div>
                    ) : (
                      <>
                        <div className="d-flex w-50 align-items-center gap-2">
                          {/* <img
                          src={filterView ? filterIcon : filterIconCross}
                          height={25}
                          className="leadFilter"
                          alt="img"
                        /> */}
                          <Select
                            options={options}
                            styles={customCommonStyles()}
                            value={selectedOption}
                            onChange={handleChange}
                            isSearchable={false}
                          />
                        </div>
                        <div className="d-flex w-50 justify-content-end gap-2">
                          <div className="btn-group">
                            <button
                              className="createContactButtonSave py-2"
                              onClick={handleCreateContact}
                            >
                              Create Contact
                            </button>
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                  <div className="totalRecordBarContact">
                    <div className="d-flex w-50">
                      <h6>
                        {" "}
                        {selectedCheckboxes.length > 0
                          ? `${selectedCheckboxes.length} Records Selected`
                          : `Total Records : ${totalItems}`}{" "}
                      </h6>
                    </div>
                    <div className="d-flex w-50 align-items-center justify-content-end gap-2">
                      {selectedCheckboxes.length === 0 ? (
                        filteredContactDetails.length !== 0 && (
                          <Select
                            options={pageOption}
                            styles={customCommonStyles("100%")}
                            // value={selectPageOption}
                            value={pageOption.find(
                              (option) => option.value === perPage.toString()
                            )}
                            onChange={handlePageDropDowmChange}
                            isSearchable={false}
                          />
                        )
                      ) : (
                        <></>
                      )}
                      {/* <Select
                options={options}
                styles={customSelectLocationStyles}
                value={selectedOption}
                onChange={handleChange}
              />
              <p className="mb-0">· ‹1/10›</p> */}

                      {filteredContactDetails.length !== 0 && (
                        <Pagination
                          currentPage={currentPage}
                          totalPages={totalPages}
                          onPageChange={handlePageChange}
                        />
                      )}
                    </div>
                  </div>
                  <div className="contactMain">
                    <div
                      className={
                        filterIcon ? "leadListTableFullWidth" : "leadListTable"
                      }
                    >
                      <table className="table leadTable">
                        <thead>
                          <tr>
                            <th scope="col">
                              <input
                                className="cursorPointer"
                                type="checkbox"
                                id="all"
                                name="all"
                                value="All"
                                checked={filteredContactDetails.every(
                                  (selected) =>
                                    selectedCheckboxes.some(
                                      (list) =>
                                        list.moduleId === selected.contactId
                                    )
                                )}
                                onChange={(e) => {
                                  const allList = filteredContactDetails.map(
                                    (item) => ({
                                      moduleId: item.contactId,
                                    })
                                  );
                                  if (e.target.checked) {
                                    const newSelections = allList.filter(
                                      (newItem) =>
                                        !selectedCheckboxes.some(
                                          (existingItem) =>
                                            existingItem.moduleId ===
                                            newItem.moduleId
                                        )
                                    );
                                    setSelectedCheckboxes((prev) => [
                                      ...prev,
                                      ...newSelections,
                                    ]);
                                  } else {
                                    setSelectedCheckboxes(
                                      selectedCheckboxes.filter(
                                        (aItem) =>
                                          !allList.some(
                                            (bItem) =>
                                              aItem.moduleId === bItem.moduleId
                                          )
                                      )
                                    );
                                  }
                                }}
                                // onChange={(e) => {
                                //   if (e.target.checked) {
                                //     const allList = filteredContactDetails.map(
                                //       (item) => ({
                                //         moduleId: item.contactId,
                                //       })
                                //     );
                                //     setSelectedCheckboxes(allList);
                                //   } else {
                                //     setSelectedCheckboxes([]);
                                //   }
                                // }}
                              />
                            </th>
                            {selectedHeadings.map(
                              (heading) =>
                                heading.isChecked && (
                                  <th scope="col" key={heading.name}>
                                    <span className="pe-5">{heading.name}</span>
                                    <span className="position-relative">
                                      <img
                                        src={tableFilterIcon}
                                        alt=""
                                        className="cursorPointer"
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          setShowHeadings(false);
                                          setShowSelectedFilter(heading.name);
                                        }}
                                      />
                                      {heading.name === showSelectedFilter && (
                                        <span
                                          className="position-absolute rounded-2 border bg-light top-0 mt-4"
                                          style={{ right: "-10%" }}
                                        >
                                          <span className="d-flex flex-column px-1">
                                            {/* Ascending filter */}
                                            <span
                                              className="my-1 px-1 cursorPointer fw-normal text-start filter_type"
                                              onClick={() => {
                                                if (filterType === "asc") {
                                                  setFilterType("");
                                                  setFiltereContactDetails(
                                                    allContactDetails
                                                  );
                                                } else {
                                                  setFilterType("asc");
                                                  sortedColumnsData(
                                                    "asc",
                                                    heading.value
                                                  );
                                                }
                                              }}
                                            >
                                              {filterType === "asc" ? (
                                                <img
                                                  src={cancel}
                                                  alt="asc"
                                                  className="opacity-75 pe-1"
                                                />
                                              ) : (
                                                <img
                                                  src={arrowUp}
                                                  alt="asc"
                                                  className="opacity-75 pe-1"
                                                />
                                              )}
                                              Asc
                                            </span>
                                            {/* Descending filter */}
                                            <span
                                              className="my-2 px-1 cursorPointer fw-normal text-start filter_type"
                                              onClick={() => {
                                                if (filterType === "desc") {
                                                  setFilterType("");
                                                  setFiltereContactDetails(
                                                    allContactDetails
                                                  );
                                                } else {
                                                  setFilterType("desc");
                                                  sortedColumnsData(
                                                    "desc",
                                                    heading.value
                                                  );
                                                }
                                              }}
                                            >
                                              {filterType === "desc" ? (
                                                <img
                                                  src={cancel}
                                                  alt="desc"
                                                  className="opacity-75 pe-1"
                                                />
                                              ) : (
                                                <img
                                                  src={arrowDown}
                                                  alt="desc"
                                                  className="opacity-75 pe-1"
                                                />
                                              )}
                                              Desc
                                            </span>
                                            <span
                                              className={`my-1 px-1 fw-normal text-start filter_type ${
                                                selectedHeadings[0].name ===
                                                  "Contact Name" &&
                                                heading.name === "Contact Name"
                                                  ? "opacity-50"
                                                  : "cursorPointer"
                                              }`}
                                              onClick={() => {
                                                if (
                                                  pinnedColumn !==
                                                  "Contact Name"
                                                ) {
                                                  setPinnedColumn(
                                                    "Contact Name"
                                                  );
                                                  handlePinColumn(
                                                    "Contact Name"
                                                  );
                                                } else {
                                                  setPinnedColumn(heading.name);
                                                  handlePinColumn(heading.name);
                                                }
                                              }}
                                            >
                                              <img
                                                src={
                                                  pinnedColumn === heading.name
                                                    ? unPin
                                                    : pin
                                                }
                                                alt="pin"
                                                className="opacity-75 pe-1"
                                              />{" "}
                                              {pinnedColumn === heading.name
                                                ? "Unpin"
                                                : "Pin"}{" "}
                                              Column
                                            </span>
                                          </span>
                                        </span>
                                      )}
                                      <img
                                        src={filterLine}
                                        alt=""
                                        className="ps-2"
                                      />
                                    </span>
                                  </th>
                                )
                            )}

                            <th
                              scope="col"
                              className="text-end cursorPointer position-relative"
                            >
                              <img
                                src={filterMenu}
                                alt=""
                                className={`filter_menu ${
                                  showHeadings ? "bg-light" : ""
                                }`}
                                onClick={(e) => {
                                  e.stopPropagation();
                                  setShowSelectedFilter("");
                                  setShowHeadings(true);
                                }}
                              />
                              {showHeadings && (
                                <div
                                  className="position-absolute rounded-2  border border-primary bg-light"
                                  style={{
                                    right: "0.2rem",
                                  }}
                                >
                                  <div className="d-flex flex-column px-2">
                                    <span
                                      className="p-2 cursorPointer fw-normal text-start"
                                      onClick={() => {
                                        setSearchColumn("");
                                        setSavingHeadings(selectedHeadings);
                                        setShowModalType("Manage Columns");
                                      }}
                                    >
                                      Manage Columns
                                    </span>
                                    <span
                                      className="p-2 fw-normal text-start opacity-50"
                                      onClick={() => {
                                        setShowModalType("Reset Column Size");
                                      }}
                                    >
                                      Reset Column Size
                                    </span>
                                  </div>
                                </div>
                              )}
                            </th>
                            {/* <th scope="col">Contact Name</th>
                    <th scope="col">Account Name</th>
                    <th scope="col">Email</th>
                    <th scope="col">Phone</th>
                    <th scope="col">Contact Owner</th> */}
                          </tr>
                        </thead>
                        <tbody>
                          {filteredContactDetails &&
                          filteredContactDetails.length > 0 ? (
                            filteredContactDetails.map((item, index) => {
                              return (
                                <tr style={{ cursor: "pointer" }} key={index}>
                                  <th scope="col">
                                    <input
                                      className="cursorPointer"
                                      type="checkbox"
                                      id="vehicle1"
                                      name="vehicle1"
                                      value={item.contactId}
                                      checked={selectedCheckboxes.some(
                                        (checkbox) =>
                                          checkbox.moduleId === item.contactId
                                      )}
                                      onChange={handleSelectCheckboxChange}
                                    />
                                  </th>

                                  <React.Fragment>
                                    {selectedHeadings.map((obj, i) => {
                                      const value = obj.value;
                                      const itemValue = item[value];
                                      return obj.isChecked ? (
                                        value === "transHash" ? (
                                          <td
                                            style={{ wordBreak: "break-all" }}
                                            className="overflow_text_hash"
                                          >
                                            <a
                                              rel="noreferrer"
                                              href={
                                                EXPLORER_URL +
                                                "about-tx-hash/" +
                                                item.transHash
                                              }
                                              className="anchorLinkHash textColor"
                                              data-title={item.transHash}
                                              target="_blank"
                                            >
                                              {item.transHash
                                                ? item.transHash.slice(0, 10) +
                                                  "...."
                                                : "-"}
                                            </a>
                                          </td>
                                        ) : (
                                          <td
                                            key={i}
                                            onClick={() => {
                                              if (value === "contactName") {
                                                setContactId(item.contactId);
                                                getContactOverview(
                                                  item.contactId
                                                );
                                                getNotesDetails(
                                                  item.contactId,
                                                  selectRecent
                                                );
                                                setFetchContactInfo(item);
                                                setSelectedContact(item);
                                                setSelectedContactIndex(
                                                  allContactDetails.findIndex(
                                                    (lead) =>
                                                      lead.contactId ===
                                                      item.contactId
                                                  )
                                                );
                                              }
                                            }}
                                            className={`overflow_text ${
                                              value === "contactName"
                                                ? "contactName cursorPointer"
                                                : ""
                                            }`}
                                            style={{ maxWidth: "3vw" }}
                                          >
                                            {itemValue !== null
                                              ? itemValue
                                              : "-"}
                                          </td>
                                        )
                                      ) : null;
                                    })}
                                  </React.Fragment>
                                  <td></td>
                                </tr>
                              );
                            })
                          ) : (
                            <tr>
                              <td
                                colSpan={selectedHeadings.length + 2}
                                style={{ textAlign: "center" }}
                              >
                                No Data
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </>
              )}
            </>
          )}
        </div>
      );
    } else if (["create", "clone"].includes(innerPage)) {
      return (
        <>
          <div className="createContactButtonBar">
            <div className="d-flex align-items-center w-50 gap-2">
              <img src={groupIcon} height={25} alt="img" />
              <div className="d-flex flex-column">
                <p className="mb-0 first_letter_uppercase">
                  {editContact ? "Edit" : innerPage} Contact
                </p>
              </div>
            </div>
            <div className="d-flex w-50 gap-2 justify-content-end">
              <button
                className="createContactButton "
                onClick={() => {
                  if (pastViewInfo) {
                    setCurrentView(pastViewInfo.view);
                    setInnerPage(pastViewInfo.innerPage);
                    setPastViewInfo((prev) => ({
                      ...prev,
                      view: null,
                      innerPage: null,
                      undo: currentView,
                    }));
                    return;
                  }
                  if (editContact) {
                    setInnerPage("editContact");
                  } else {
                    setInnerPage("list");
                    setSelectedFiles(null);
                    setFetchAccInfo(null);
                    fileInputRef.current.value = "";
                  }
                  if (innerPage === "clone" && !editContact) {
                    setInnerPage("editContact");
                  }
                  resetForm();
                }}
              >
                Cancel
              </button>

              {editContact ? (
                <></>
              ) : (
                <>
                  <button
                    className="createContactButton"
                    onClick={() => addNewContact("SAVE AND NEW")}
                    disabled={saveAndNew}
                  >
                    {saveAndNew ? (
                      <Lottie
                        options={defaultOptions("blue")}
                        height="24px"
                        width="36px"
                      />
                    ) : (
                      "Save and New"
                    )}
                  </button>
                </>
              )}
              <button
                className="createContactButtonSave"
                onClick={() => {
                  // editContact ? UpdateContact() : addNewContact("SAVE")

                  if (editContact) {
                    UpdateContact("SAVE");
                  } else if (existingClone) {
                    addNewContact("ADD");
                  } else {
                    addNewContact("ADD");
                  }
                }}
                disabled={save}
              >
                {/* Save */}

                {save ? (
                  <Lottie
                    options={defaultOptions("white")}
                    height="30px"
                    width="36px"
                  />
                ) : (
                  updateButtonLabel
                )}
              </button>
            </div>
          </div>

          <div className="contactFields">
            <p
              className="py-2"
              style={{
                borderBottom: "2px solid #316AFF",
                fontSize: "18px",
                color: "#515151",
              }}
            >
              Contact Information
            </p>
            <div className="inputFieldDiv">
              <div
                className={`form-group ${isDragging ? "drag-over" : ""}`}
                onDragOver={handleDragOver}
                onDragLeave={handleDragLeave}
                onDrop={handleDrop}
              >
                <p className="mb-0">Upload Image</p>
                <input
                  type="file"
                  id="accountImage"
                  name="accountImage"
                  accept=".png, .jpg, .jpeg, .gif"
                  className="file-control"
                  onChange={handleImageChange}
                  ref={fileInputRef}
                  style={{ display: "none" }}
                />
                <label htmlFor="accountImage" className="label-file">
                  <span>
                    {selectedFiles
                      ? selectedFiles?.name?.length > 30
                        ? `${selectedFiles.name.substring(0, 30)}...`
                        : selectedFiles.name
                      : "Drag and Drop files (JPG,PNG)"}
                  </span>
                </label>
                {imageMsg && (
                  <p className="mb-0 small erro_msg  text-wrap w-100">
                    {imageMsg}
                  </p>
                )}
              </div>
            </div>
            <div className="inputFieldDiv">
              <div className="inputFieldDivRight  position position-relative ">
                <p className="mb-0">
                  Contact Owner <span className="text-danger">*</span>
                </p>
                <div
                  className="d-flex input-with-clear"
                  onClick={handleOwnerInputClick}
                >
                  <input
                    type="text"
                    //   placeholder="Vendor Owner"
                    className="leadInputField cursorPointer"
                    value={selectedItem?.name}
                    // onClick={handleOwnerInputClick}
                    readOnly
                  />
                  {listVisible && !editContact ? (
                    <span
                      className="clear-button"
                      onClick={() => setSelectedItem("")}
                    >
                      <img src={up} alt="img" height={20} width={20} />
                    </span>
                  ) : (
                    <>
                      {!editContact && (
                        <span className="clear-button">
                          <img src={down} alt="img" height={20} width={20} />
                        </span>
                      )}
                    </>
                  )}

                  {editContact ? (
                    <>
                      {" "}
                      <span
                        className="cursorPointer pt-2 ps-1"
                        onClick={() => {
                          setShowModalType("changeOwner");
                          setListVisible(false);
                        }}
                      >
                        <img
                          src={userSearch}
                          alt="img"
                          width={20}
                          height={20}
                        />
                      </span>{" "}
                    </>
                  ) : (
                    <>
                      {" "}
                      <span
                        className="cursorPointer pt-2 ps-1"
                        onClick={() => setIsLeadOwnerModal(!isLeadOwnerModal)}
                      >
                        <img
                          src={userSearch}
                          alt="img"
                          width={20}
                          height={20}
                        />
                      </span>{" "}
                    </>
                  )}
                </div>
                {editContact ? (
                  <></>
                ) : (
                  <>
                    {listVisible && (
                      <ul
                        className="data-list list-group  lead_ownerList_container position-absolute"
                        style={{ top: "5.85rem", width: "24.3vw" }}
                        onClick={(e) => e.stopPropagation()}
                      >
                        {userList.map((item, index) => (
                          <li
                            key={index}
                            className="cursorPointer list-group-item"
                            onClick={() => handleUserItemClick(item)}
                          >
                            <div className="d-flex flex-column">
                              <span style={{ fontWeight: "500" }}>
                                {" "}
                                {item.name}{" "}
                              </span>
                              <span
                                style={{ fontSize: "14px", color: "#999999" }}
                              >
                                {" "}
                                {item.emailId}{" "}
                              </span>
                            </div>
                          </li>
                        ))}
                      </ul>
                    )}
                  </>
                )}
              </div>
              <div className="inputFieldDivLeft">
                <p className="mb-1">Lead Source</p>

                <Select
                  value={
                    editContact ? updateData.leadSource : formData.leadSource
                  }
                  menuPlacement="auto"
                  onChange={(selectedOption) =>
                    handleChangeSelectDropDown(selectedOption, "leadSource")
                  }
                  options={leadSourceOptions}
                  styles={createLeadStyles}
                  isSearchable={false}
                />
              </div>
            </div>
            <div className="inputFieldDiv">
              <div className="inputFieldDivRight">
                <p className="mb-1">
                  First Name <span className="text-danger">*</span>
                </p>
                <input
                  type="text"
                  // className="insertCommonInputField"
                  className={
                    firstNameErrorMsg
                      ? "mandatoryField"
                      : "insertCommonInputField"
                  }
                  value={
                    editContact || existingClone
                      ? updateData.firstName
                      : formData.firstName
                  }
                  name="firstName"
                  onChange={handleInputFieldChange}
                />
                <span className="small erro_msg">{firstNameErrorMsg}</span>
              </div>
              <div className="inputFieldDivRight">
                <p className="mb-1">
                  Last Name <span className="text-danger">*</span>
                </p>
                <input
                  type="text"
                  className={
                    lastNameErrorMsg
                      ? "mandatoryField"
                      : "insertCommonInputField"
                  }
                  value={
                    editContact || existingClone
                      ? updateData.lastName
                      : formData.lastName
                  }
                  name="lastName"
                  onChange={handleInputFieldChange}
                />
                <span className="small erro_msg">{lastNameErrorMsg}</span>
              </div>
            </div>
            <div className="inputFieldDiv">
              <div className="inputFieldDivLeft  position position-relative">
                <p className="mb-0">Account Name </p>
                <div
                  className="d-flex input-with-clear  "
                  onClick={handleAccountClick}
                >
                  <input
                    type="text"
                    //   placeholder="Vendor Owner"
                    className="leadInputField cursorPointer"
                    value={
                      fetchAccInfo
                        ? fetchAccInfo?.accountName
                        : selectedAccountItem?.accountName || ""
                    }
                    // onClick={handleAccountClick}
                    readOnly
                  />
                  {accountListVisible ? (
                    <span
                      className="clear-button"
                      onClick={() => {
                        setSelectedAccountItem("");
                      }}
                    >
                      <img src={up} alt="img" height={20} width={20} />
                    </span>
                  ) : (
                    <span className="clear-button">
                      <img src={down} alt="img" height={20} width={20} />
                    </span>
                  )}
                  <span
                    className="cursorPointer pt-2 ps-1"
                    onClick={(e) => {
                      e.stopPropagation();
                      setIsAccountListModal(!isAccountListModal);
                    }}
                  >
                    <img src={accountsIcon} alt="img" width={22} height={22} />
                  </span>
                </div>
                {accountListVisible && (
                  <ul
                    className="data-list list-group  lead_ownerList_container position-absolute"
                    style={{ top: "5.85rem", width: "24.3vw" }}
                    onClick={(e) => e.stopPropagation()}
                  >
                    {accountDetails?.map((item, index) => (
                      <li
                        key={index}
                        className="cursorPointer list-group-item"
                        onClick={() => handleAccountItemClick(item)}
                      >
                        <div className="d-flex ">
                          <span> {item.accountName} </span>
                        </div>
                      </li>
                    ))}
                  </ul>
                )}
              </div>
              <div className="inputFieldDivRight  position position-relative">
                <p className="mb-1">Vendor Name </p>
                <div
                  className="d-flex input-with-clear  "
                  onClick={handleVendorClick}
                  // onClick={()=> setVendorListVisible(!vendorListVisible)}
                >
                  <input
                    type="text"
                    //   placeholder="Vendor Owner"
                    className="leadInputField cursorPointer"
                    value={selectedVendorItem.vendorName}
                    // onClick={handleVendorClick}
                    readOnly
                  />
                  {vendorListVisible ? (
                    <span
                      className="clear-button"
                      onClick={() => setSelectedVendorItem("")}
                    >
                      <img src={up} alt="img" height={20} width={20} />
                    </span>
                  ) : (
                    <span className="clear-button">
                      <img src={down} alt="img" height={20} width={20} />
                    </span>
                  )}

                  <span
                    className="cursorPointer pt-2 ps-1"
                    onClick={(e) => {
                      e.stopPropagation();
                      setIsVendorListModal(!isVendorListModal);
                    }}
                  >
                    <img src={vendorIcon} alt="img" width={25} height={30} />
                  </span>
                </div>
                {vendorListVisible && (
                  <ul
                    className="data-list list-group  lead_ownerList_container position-absolute"
                    style={{ top: "6.1rem", width: "24.3vw" }}
                    onClick={(e) => e.stopPropagation()}
                  >
                    {vendorDetails.map((item, index) => (
                      <li
                        key={index}
                        className="cursorPointer list-group-item"
                        onClick={() => handleVendorItemClick(item)}
                      >
                        <div className="d-flex ">
                          <span> {item.vendorName} </span>
                        </div>
                      </li>
                    ))}
                  </ul>
                )}
              </div>
            </div>
            <div className="inputFieldDiv">
              <div className="inputFieldDivLeft">
                <p className="mb-1">
                  Email<span className="text-danger">*</span>
                </p>
                <input
                  type="text"
                  // className="insertCommonInputField"
                  className={
                    emailMsg ? "mandatoryField" : "insertCommonInputField"
                  }
                  value={
                    editContact || existingClone
                      ? updateData.emailId
                      : formData.emailId
                  }
                  name="emailId"
                  onChange={handleInputFieldChange}
                />
                {!validEmail && formData.emailId !== "" && (
                  <p style={{ color: "red", margin: "5px 0" }}>
                    {emailValidMsg && emailValidMsg}
                  </p>
                )}

                <span className="small erro_msg">{emailMsg}</span>
              </div>
              <div className="inputFieldDivRight">
                <p className="mb-1">Job Title</p>
                <input
                  type="text"
                  className="insertCommonInputField"
                  value={
                    editContact || existingClone
                      ? updateData.title
                      : formData.title
                  }
                  name="title"
                  onChange={handleInputFieldChange}
                />
                <span className="small erro_msg">{titleMsg}</span>
              </div>
            </div>
            <div className="inputFieldDiv">
              <div className="inputFieldDivLeft">
                <p className="mb-1">Phone</p>
                <input
                  type="text"
                  className="insertCommonInputField"
                  maxLength={10}
                  autoComplete="off"
                  value={
                    editContact || existingClone
                      ? updateData.phone
                      : formData.phone
                  }
                  name="phone"
                  onChange={handleInputFieldChange}
                  // onKeyDown={(e) => {
                  //   if (
                  //     !/\d/.test(e.key) &&
                  //     e.key !== "Backspace" &&
                  //     e.key !== "Delete" &&
                  //     e.key !== "ArrowLeft" &&
                  //     e.key !== "ArrowRight"
                  //   ) {
                  //     e.preventDefault();
                  //   }
                  // }}
                />
                <span className="small erro_msg">{phoneMsg}</span>
              </div>
              <div className="inputFieldDivRight">
                <p className="mb-1">Department</p>
                <input
                  type="text"
                  className="insertCommonInputField"
                  value={
                    editContact || existingClone
                      ? updateData.department
                      : formData.department
                  }
                  name="department"
                  onChange={handleInputFieldChange}
                />
                <span className="small erro_msg">{departmentMsg}</span>
              </div>
            </div>
            <div className="inputFieldDiv">
              <div className="inputFieldDivLeft">
                <p className="mb-1">Other Phone</p>
                <input
                  type="text"
                  className="insertCommonInputField"
                  maxLength={10}
                  autoComplete="off"
                  value={
                    editContact || existingClone
                      ? updateData.otherPhone
                      : formData.otherPhone
                  }
                  name="otherPhone"
                  onChange={handleInputFieldChange}
                  // onKeyDown={(e) => {
                  //   // Allow only numeric input
                  //   if (
                  //     !/\d/.test(e.key) &&
                  //     e.key !== "Backspace" &&
                  //     e.key !== "Delete" &&
                  //     e.key !== "ArrowLeft" &&
                  //     e.key !== "ArrowRight"
                  //   ) {
                  //     e.preventDefault();
                  //   }
                  // }}
                />
                <span className="small erro_msg">{otherPhoneMsg}</span>
              </div>
              <div className="inputFieldDivRight">
                <p className="mb-1">Home Phone</p>
                <input
                  type="text"
                  className="insertCommonInputField"
                  maxLength={10}
                  autoComplete="off"
                  value={
                    editContact || existingClone
                      ? updateData.homePhone
                      : formData.homePhone
                  }
                  name="homePhone"
                  onChange={handleInputFieldChange}
                  // onKeyDown={(e) => {
                  //   // Allow only numeric input
                  //   if (
                  //     !/\d/.test(e.key) &&
                  //     e.key !== "Backspace" &&
                  //     e.key !== "Delete" &&
                  //     e.key !== "ArrowLeft" &&
                  //     e.key !== "ArrowRight"
                  //   ) {
                  //     e.preventDefault();
                  //   }
                  // }}
                />
              </div>
            </div>
            <div className="inputFieldDiv">
              <div className="inputFieldDivLeft">
                <p className="mb-1">Mobile</p>
                <input
                  type="text"
                  className="insertCommonInputField"
                  maxLength={10}
                  autoComplete="off"
                  value={
                    editContact || existingClone
                      ? updateData.mobile
                      : formData.mobile
                  }
                  name="mobile"
                  onChange={handleInputFieldChange}
                  // onKeyDown={(e) => {
                  //   // Allow only numeric input
                  //   if (
                  //     !/\d/.test(e.key) &&
                  //     e.key !== "Backspace" &&
                  //     e.key !== "Delete" &&
                  //     e.key !== "ArrowLeft" &&
                  //     e.key !== "ArrowRight"
                  //   ) {
                  //     e.preventDefault();
                  //   }
                  // }}
                />

                <span className="small erro_msg">{mobileMsg}</span>
              </div>
              <div className="inputFieldDivRight">
                <p className="mb-1">Fax</p>
                <input
                  type="text"
                  className="insertCommonInputField"
                  maxLength={12}
                  autoComplete="off"
                  value={
                    editContact || existingClone ? updateData.fax : formData.fax
                  }
                  name="fax"
                  onChange={handleInputFieldChange}
                />
              </div>
            </div>
            <div className="inputFieldDiv">
              <div className="inputFieldDivLeft">
                <p className="mb-1">Assistant</p>
                <input
                  type="text"
                  className="insertCommonInputField"
                  value={
                    editContact || existingClone
                      ? updateData.assistant
                      : formData.assistant
                  }
                  name="assistant"
                  onChange={handleInputFieldChange}
                />
                <span className="small erro_msg">{assistantMsg}</span>
              </div>
              <div className="inputFieldDivRight">
                <p className="mb-1">Date of Birth</p>
                <input
                  type="date"
                  className="insertCommonInputField"
                  value={
                    editContact || existingClone
                      ? convertDateFormat(updateData.dateOfBirth)
                      : convertDateFormat(formData.dateOfBirth)
                  }
                  onKeyDown={(e) => e.preventDefault()}
                  name="dateOfBirth"
                  onChange={handleInputFieldChange}
                  max={moment().subtract(18, "years").format("YYYY-MM-DD")}
                  min={moment().subtract(100, "years").format("YYYY-MM-DD")}
                />
              </div>
            </div>
            <div className="inputFieldDiv">
              <div className="inputFieldDivLeft">
                <p className="mb-1">Asst Phone</p>
                <input
                  type="text"
                  className="insertCommonInputField"
                  pattern="[0-9]*"
                  maxLength={10}
                  value={
                    editContact || existingClone
                      ? updateData.assistantPhone
                      : formData.assistantPhone
                  }
                  name="assistantPhone"
                  onChange={handleInputFieldChange}
                  onKeyDown={(e) => {
                    // Allow only numeric input
                    if (
                      !/\d/.test(e.key) &&
                      e.key !== "Backspace" &&
                      e.key !== "Delete" &&
                      e.key !== "ArrowLeft" &&
                      e.key !== "ArrowRight"
                    ) {
                      e.preventDefault();
                    }
                  }}
                />
              </div>
              <div className="inputFieldDivLeft flex-row">
                <span className="ms-2">Email Opt Out</span>

                <input
                  type="checkbox"
                  name="emailOptOut"
                  checked={emailOptOut}
                  onClick={() => {
                    setEmailOptOut(!emailOptOut);
                  }}
                />
              </div>
            </div>
            {/* <div className="inputFieldDiv">
              
            </div> */}
            <div className="inputFieldDiv">
              <div className="inputFieldDivRight mt-4">
                <p className="mb-1">Skype ID</p>
                <input
                  type="text"
                  className="insertCommonInputField"
                  value={
                    editContact || existingClone
                      ? updateData.skypeId
                      : formData.skypeId
                  }
                  name="skypeId"
                  onChange={handleInputFieldChange}
                />
                <span className="small erro_msg">{skypeIdMsg}</span>
              </div>
              <div className="inputFieldDivRight">
                <p className="mb-1">Secondary Email</p>
                <input
                  type="text"
                  className="insertCommonInputField"
                  value={
                    editContact || existingClone
                      ? updateData.secondaryEmailId
                      : formData.secondaryEmailId
                  }
                  name="secondaryEmailId"
                  onChange={handleInputFieldChange}
                />
              </div>
            </div>
            <div className="inputFieldDiv">
              <div className="inputFieldDivRight">
                <p className="mb-1">Twitter</p>
                <input
                  type="text"
                  className="insertCommonInputField"
                  value={
                    editContact || existingClone
                      ? updateData.twitter
                      : formData.twitter
                  }
                  name="twitter"
                  onChange={handleInputFieldChange}
                />
                <span className="small erro_msg">{twitterId}</span>
              </div>
              <div className="inputFieldDivRight position-relative">
                <p className="mb-1">Reporting To</p>
                <div
                  className="d-flex input-with-clear  "
                  onClick={(e) => {
                    e.stopPropagation();
                    setReportingListVisible(!reportingListVisible);
                    setVendorListVisible(false);
                    setListVisible(false);
                    setAccountListVisible(false);
                  }}
                >
                  <input
                    type="text"
                    className="leadInputField cursorPointer"
                    value={selectedReportingItem.moduleName}
                    readOnly
                  />
                  {reportingListVisible ? (
                    <span className="clear-button">
                      <img src={up} alt="img" height={20} width={20} />
                    </span>
                  ) : (
                    <span className="clear-button">
                      <img src={down} alt="img" height={20} width={20} />
                    </span>
                  )}
                </div>
                {reportingListVisible && reportingDetails.length > 0 && (
                  <ul
                    className="data-list list-group  lead_ownerList_container position-absolute"
                    style={{ top: "6.1rem", width: "24.3vw" }}
                    onClick={(e) => e.stopPropagation()}
                  >
                    {reportingDetails.map((item, index) => (
                      <li
                        key={index}
                        className="cursorPointer list-group-item"
                        onClick={() => {
                          setSelectedReportingItem(item);
                          setReportingListVisible(false);
                        }}
                      >
                        <div className="d-flex ">
                          <span> {item.moduleName} </span>
                        </div>
                      </li>
                    ))}
                  </ul>
                )}
              </div>
            </div>
            <h6
              className="py-2 mt-3"
              style={{
                borderBottom: "2px solid #316AFF",
                fontSize: "18px",
                color: "#515151",
              }}
            >
              Address Information
            </h6>
            <div
              className="inputFieldDiv"
              style={{ width: "min-content", alignSelf: "end" }}
            >
              <Dropdown>
                <Dropdown.Toggle id="dropdown-basic">
                  Copy Address
                </Dropdown.Toggle>

                <Dropdown.Menu style={{ width: "11vw" }}>
                  <Dropdown.Item onClick={copyMailingToOther}>
                    Mailing to Other
                  </Dropdown.Item>
                  <Dropdown.Item onClick={copyOtherToMailing}>
                    Other to Mailing
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
            <div className="inputFieldDiv">
              <div className="inputFieldDivLeft">
                <p className="mb-1">Mailing Street</p>
                <input
                  type="text"
                  className="insertCommonInputField"
                  value={
                    editContact || existingClone
                      ? updateData.mailingStreet
                      : mailingAdd.mailingStreet
                  }
                  name="mailingStreet"
                  onChange={handleInputFieldChange}
                />
              </div>
              <div className="inputFieldDivRight">
                <p className="mb-1">Other Street</p>
                <input
                  type="text"
                  className="insertCommonInputField"
                  value={
                    editContact || existingClone
                      ? updateData.otherStreet
                      : otherAdd.otherStreet
                  }
                  name="otherStreet"
                  onChange={handleInputFieldChange}
                />
              </div>
            </div>
            <div className="inputFieldDiv">
              <div className="inputFieldDivLeft">
                <p className="mb-1">Mailing City</p>
                <input
                  type="text"
                  className="insertCommonInputField"
                  value={
                    editContact || existingClone
                      ? updateData.mailingCity
                      : mailingAdd.mailingCity
                  }
                  name="mailingCity"
                  onChange={handleInputFieldChange}
                />
                <span className="small erro_msg">{mailingCityErrorMsg}</span>
              </div>
              <div className="inputFieldDivRight">
                <p className="mb-1">Other City</p>
                <input
                  type="text"
                  className="insertCommonInputField"
                  value={
                    editContact || existingClone
                      ? updateData.otherCity
                      : otherAdd.otherCity
                  }
                  name="otherCity"
                  onChange={handleInputFieldChange}
                />
                <span className="small erro_msg">{otherCityErrorMsg}</span>
              </div>
            </div>
            <div className="inputFieldDiv">
              <div className="inputFieldDivLeft">
                <p className="mb-1">Mailing State</p>
                <input
                  type="text"
                  className="insertCommonInputField"
                  value={
                    editContact || existingClone
                      ? updateData.mailingState
                      : mailingAdd.mailingState
                  }
                  name="mailingState"
                  onChange={handleInputFieldChange}
                />
                <span className="small erro_msg">{mailingStateErrorMsg}</span>
              </div>
              <div className="inputFieldDivRight">
                <p className="mb-1">Other State</p>
                <input
                  type="text"
                  className="insertCommonInputField"
                  value={
                    editContact || existingClone
                      ? updateData.otherState
                      : otherAdd.otherState
                  }
                  name="otherState"
                  onChange={handleInputFieldChange}
                />
                <span className="small erro_msg">{otherStateErrorMsg}</span>
              </div>
            </div>
            <div className="inputFieldDiv">
              <div className="inputFieldDivLeft">
                <p className="mb-1">Mailing Zip</p>
                <input
                  type="text"
                  pattern="[0-9]*"
                  maxLength={6}
                  className="insertCommonInputField"
                  value={
                    editContact || existingClone
                      ? updateData.mailingZip
                      : mailingAdd.mailingZip
                  }
                  name="mailingZip"
                  onChange={handleInputFieldChange}
                />
                <span className="small erro_msg">{mailingMsg}</span>
              </div>
              <div className="inputFieldDivRight">
                <p className="mb-1">Other Zip</p>
                <input
                  type="text"
                  pattern="[0-9]*"
                  maxLength={6}
                  className="insertCommonInputField"
                  value={
                    editContact || existingClone
                      ? updateData.otherZip
                      : otherAdd.otherZip
                  }
                  name="otherZip"
                  onChange={handleInputFieldChange}
                />
                <span className="small erro_msg">{otherZipMsg}</span>
              </div>
            </div>
            <div className="inputFieldDiv">
              <div className="inputFieldDivLeft">
                <p className="mb-1">Mailing Country</p>
                <input
                  type="text"
                  className="insertCommonInputField"
                  value={
                    editContact || existingClone
                      ? updateData.mailingCountry
                      : mailingAdd.mailingCountry
                  }
                  name="mailingCountry"
                  onChange={handleInputFieldChange}
                />
                <span className="small erro_msg">{mailingCountryErrorMsg}</span>
              </div>
              <div className="inputFieldDivRight">
                <p className="mb-1">Other Country</p>
                <input
                  type="text"
                  className="insertCommonInputField"
                  value={
                    editContact || existingClone
                      ? updateData.otherCountry
                      : otherAdd.otherCountry
                  }
                  name="otherCountry"
                  onChange={handleInputFieldChange}
                />
                <span className="small erro_msg">{otherCountryErrorMsg}</span>
              </div>
            </div>
            <h6
              className="py-2 mt-3"
              style={{
                borderBottom: "2px solid #316AFF",
                fontSize: "18px",
                color: "#515151",
              }}
            >
              Description Information
            </h6>
            <div className="inputFieldDiv">
              <div className="inputFieldDivLeft">
                <p>Description</p>
                <textarea
                  rows="4"
                  cols="50"
                  maxLength="2000"
                  style={{
                    width: "100%",
                    border: "1px solid #B9B9B94D",
                    height: "100px",
                    outline: "none",
                    borderRadius: "10px",
                    backgroundColor: "transparent",
                    padding: "8px 12px",
                  }}
                  value={
                    editContact || existingClone
                      ? updateData.description
                      : formData.description
                  }
                  name="description"
                  onChange={handleInputFieldChange}
                />
              </div>
            </div>
          </div>

          <Modal
            show={isAccountListModal}
            className="d-flex justify-content-center align-items-center"
            aria-labelledby="example-custom-modal-styling-title"
            size="lg"
            id="commonLeadModal"
          >
            <Modal.Body className="ModalBody overflow-auto">
              <div
                className="modalUS row justify-content-center"
                style={{ margin: "0", gap: "10px", paddingBottom: "10px" }}
              >
                <div className="header_Container d-flex justify-content-between">
                  <h4 className="commonTextColor pt-2">Choose Account</h4>
                  <div className="inputContainer d-flex justify-content-start">
                    <input
                      type="text"
                      placeholder="Search"
                      className="topBarModalSearch px-5"
                      onChange={handleSearchAccount}
                      value={searchAccount}
                    />
                    <img src={searchIcon} alt="search" className="searchIcon" />
                  </div>
                </div>

                <div className="Owner_table_container border border-dark rounded-2 h-25">
                  <div className="table-wrapper">
                    <table className="userdata-table">
                      <thead>
                        <tr>
                          <th>Account Name</th>
                          <th>Phone</th>
                          <th>Website</th>
                          <th>Account Owner</th>
                        </tr>
                      </thead>
                      <tbody className="table-body tableUserData">
                        {filteredAccounts.length > 0 &&
                          filteredAccounts?.map((item, index) => (
                            <tr
                              key={index}
                              onClick={() => {
                                setSelectedAccountItem(item);
                              }}
                            >
                              <>
                                <td>{item.accountName || "--"}</td>
                                <td>{item.phone || "--"}</td>
                                <td>{item.website || "--"}</td>
                                <td>{item.accountOwner || "--"}</td>
                              </>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </div>
                </div>
                <div className="d-flex w-100 align-items-center justify-content-end gap-2">
                  {filteredAccounts.length > 0 && (
                    <>
                      <Select
                        options={pageOption}
                        // styles={customSelectLocationStyles}
                        value={pageOption.find(
                          (option) => option.value === perPageAccount.toString()
                        )}
                        onChange={handlePageDropDownChangeAccount}
                        isSearchable={false}
                        styles={customCommonStyles("225px")}
                      />
                      <Pagination
                        currentPage={currentPageAccount}
                        totalPages={totalPagesAccount}
                        onPageChange={handlepageChangeAccount}
                      />
                    </>
                  )}
                </div>
                <div className="d-flex pb-2 pt-3 justify-content-between">
                  <div className="selectedUser d-flex gap-1">
                    <span>Selected Account</span>
                    <img src={logoConvert} alt="img" height={20} width={20} />
                    <span>{selectedAccountItem?.accountName}</span>
                  </div>
                  <div className="d-flex gap-2">
                    <button
                      className="btn btn-outline-secondary border border-primary btn-sm px-3 py-2 text-dark"
                      onClick={() => {
                        setIsAccountListModal(false);
                        setSelectedAccountItem({});
                      }}
                    >
                      Cancel
                    </button>
                    <button
                      className="btn btnColor btn-sm px-3 text-white"
                      onClick={() => {
                        setIsAccountListModal(false);
                      }}
                    >
                      Done
                    </button>
                  </div>
                </div>
              </div>
            </Modal.Body>
          </Modal>
          {/* 
          {isVendorListModal ? (
            <VendorListModal
              isVendorListModal={isVendorListModal}
              setIsVendorListModal={setIsVendorListModal}
              onSelectedVendorChange={handleSelectedVendorListChange}
              vendorDetails={vendorDetails}
              defaultUser={selectedVendorItem}
            />
          ) : null} */}

          <Modal
            show={isVendorListModal}
            className="d-flex justify-content-center align-items-center"
            aria-labelledby="example-custom-modal-styling-title"
            size="lg"
            id="commonLeadModal"
          >
            <Modal.Body className="ModalBody  overflow-auto">
              <div
                className="modalUS row justify-content-center"
                style={{ margin: "0", gap: "10px", paddingBottom: "10px" }}
              >
                <div className="header_Container d-flex justify-content-between">
                  <h4 className="commonTextColor">Choose Vendor</h4>
                  <div className="d-flex justify-content-start">
                    <div className="inputContainer d-flex justify-content-start">
                      <input
                        type="text"
                        className="inputBox px-5 mb-1 w-75 py-1"
                        style={{ outline: "none" }}
                        placeholder="Search Users"
                        value={searchVendor}
                        onChange={handleSearchVendor}
                      />
                      <img
                        src={searchIcon}
                        alt="search"
                        className="searchIcon"
                        style={{ width: "20px" }}
                      />
                    </div>
                  </div>
                </div>

                <div className="Owner_table_container border border-dark rounded-2 h-25">
                  <div className="table-wrapper">
                    <table className="userdata-table">
                      <thead>
                        <tr>
                          <th>Vendor Name</th>
                          <th>Email</th>

                          <th>Phone</th>
                          <th>Website</th>
                          <th> Owner</th>
                        </tr>
                      </thead>
                      <tbody className="table-body tableUserData">
                        {filteredVendors?.map((item, index) => (
                          <tr
                            key={index}
                            onClick={() => {
                              setSelectedVendorItem(item);
                            }}
                          >
                            <td className="d-flex gap-2">
                              <img
                                src={logoConvert}
                                alt="img"
                                height={20}
                                width={20}
                              />

                              {item.vendorName}
                            </td>
                            <td>{item.emailId}</td>
                            <td>{item.phone}</td>
                            <td>{item.website}</td>
                            <td>{item.vendorId}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
                <div className="d-flex w-100 align-items-center justify-content-end gap-2">
                  {filteredVendors.length > 0 && (
                    <>
                      <Select
                        options={pageOption}
                        value={pageOption.find(
                          (option) => option.value === perPageVendor.toString()
                        )}
                        onChange={handlePageDropDownChangeVendor}
                        isSearchable={false}
                        styles={customCommonStyles("225px")}
                      />
                      <Pagination
                        currentPage={currentPageVendor}
                        totalPages={totalPagesVendor}
                        onPageChange={handlepageChangeVendor}
                      />
                    </>
                  )}
                </div>
                <div className="d-flex pb-2 pt-3 justify-content-between">
                  <div className="selectedUser d-flex gap-1">
                    <span>Selected User</span>
                    <img src={logoConvert} alt="img" height={20} width={20} />
                    <span>{selectedVendorItem.vendorName}</span>
                  </div>
                  <div className="d-flex gap-2">
                    <button
                      className="btn btn-outline-secondary border border-primary btn-sm px-3 py-2 text-dark"
                      onClick={() => {
                        setIsVendorListModal(false);
                        setSelectedVendorItem({});
                      }}
                    >
                      Cancel
                    </button>
                    <button
                      className="btn btnColor btn-sm px-3 text-white"
                      onClick={() => {
                        setIsVendorListModal(false);
                      }}
                    >
                      Done
                    </button>
                  </div>
                </div>
              </div>
            </Modal.Body>
          </Modal>
        </>
      );
    } else if (innerPage === "editContact") {
      return (
        <>
          <div className="indiv_main_container ">
            <div className="individual_header_container commonContainer_bg w-100  flex-column  d-flex justify-content-between">
              <div className="iconContainer d-flex justify-content-between px-2 pt-2">
                <span
                  className="cursorPointer"
                  onClick={() => {
                    setInnerPage("list");
                    getAllContact(selectedOption);
                    setFetchContactInfo(null);
                    const resetFields = Object.fromEntries(
                      Object.keys(editingFields).map((key) => [key, false])
                    );
                    setEditingFields({
                      ...resetFields,
                    });
                    setShowType("");
                    setContactPageView("overview");
                  }}
                >
                  {leftArrow}
                </span>

                <span>
                  <span
                    className={`me-2 ${
                      selectedContactIndex === 0
                        ? "opacity-50"
                        : "cursorPointer"
                    }`}
                    onClick={() => {
                      const leadIndex = allContactDetails.findIndex(
                        (contact) => contact.contactId === contactId
                      );
                      if (leadIndex !== 0) {
                        setSelectedContactIndex(leadIndex - 1);
                        setContactId(
                          allContactDetails[leadIndex - 1].contactId
                        );
                        getContactOverview(
                          allContactDetails[leadIndex - 1].contactId
                        );
                        setSelectedContact(allContactDetails[leadIndex - 1]);
                        setFetchContactInfo(allContactDetails[leadIndex - 1]);
                      }
                    }}
                  >
                    {leftAngleArrow}
                  </span>
                  <span
                    className={`ms-1 ${
                      selectedContactIndex === allContactDetails.length - 1
                        ? "opacity-50"
                        : "cursorPointer"
                    }`}
                    onClick={() => {
                      const leadIndex = allContactDetails.findIndex(
                        (lead) => lead.contactId === contactId
                      );
                      if (leadIndex < allContactDetails.length - 1) {
                        setSelectedContactIndex(leadIndex + 1);
                        setContactId(
                          allContactDetails[leadIndex + 1].contactId
                        );
                        getContactOverview(
                          allContactDetails[leadIndex + 1].contactId
                        );
                        setSelectedContact(allContactDetails[leadIndex + 1]);
                        setFetchContactInfo(allContactDetails[leadIndex + 1]);
                      }
                    }}
                  >
                    {rightAngleArrow}
                  </span>
                </span>
              </div>
              <div>
                <div className=" d-flex justify-content-between p-2">
                  <div className="profile_logo_container d-flex">
                    <div className="logoContainer d-flex align-items-center justify-content-center">
                      <div className="logoContainer d-flex align-items-center justify-content-center">
                        {contactInfo && (
                          <img
                            src={
                              contactInfo.contactImageData === null
                                ? ContactAvatar
                                : contactInfo.contactImageData
                            }
                            name="contactImageData"
                            alt="img"
                            style={{ width: "100px", height: "100px" }}
                            onClick={() => {
                              setShowImageModal(true);
                            }}
                          />
                        )}
                      </div>
                    </div>
                    <div className="d-flex flex-column align-items-center justify-content-center ps-2">
                      <span>{contactInfo?.contactName}</span>
                      <span className="opacity-75 ">
                        {contactInfo?.accountName}{" "}
                      </span>
                    </div>
                  </div>
                  <div className="d-flex  justify-content-end align-items-end  px-2 pb-2">
                    <div className="d-flex gap-2  position-relative">
                      {/* <button className="btn  btnColor text-white">
                        Send Email
                      </button> */}
                      <button
                        className="btn btn-secondary bg-white text-dark border border-primary"
                        onClick={handleEditContact}
                      >
                        Edit
                      </button>
                      <button
                        className="btn btn-secondary bg-white text-dark border border-primary"
                        onClick={(e) => {
                          e.stopPropagation();

                          setShowOptionsContact(!showOptionsContact);
                        }}
                      >
                        ---
                      </button>
                    </div>
                    {showOptionsContact && (
                      <div
                        className="rounded  showOptions p-2"
                        style={{ top: "15.4rem", zIndex: "1px" }}
                      >
                        <div
                          className="d-flex gap-3 align-items-center mt-2"
                          onClick={handleCreateContactClone}
                        >
                          <img src={Clone} alt="clone" />
                          <div
                            style={{ color: "#515151", fontWeight: "300" }}
                            className="mt-1 cursorPointer ms-2"
                          >
                            Clone
                          </div>
                        </div>
                        {contactInfo.isShared === false && (
                          <div
                            className="d-flex gap-3 align-items-center mt-2"
                            onClick={() => {
                              if (!isLoading) {
                                getAllInfoShare(contactId);
                              }
                            }}
                          >
                            <img src={Share} alt="close" />
                            <div
                              style={{ color: "#515151", fontWeight: "300" }}
                              className="mt-2 cursorPointer ms-2"
                            >
                              Share
                            </div>
                          </div>
                        )}

                        <div
                          className="d-flex gap-3 align-items-center mt-2"
                          onClick={handleDeleteContact}
                        >
                          <img src={Delete} alt="close" />
                          <div
                            style={{ color: "#515151", fontWeight: "300" }}
                            className="mt-2 cursorPointer ms-2"
                          >
                            Delete
                          </div>
                        </div>
                        <div
                          style={{ borderBottom: "1px solid #515151s" }}
                        ></div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="detailList_main_container mt-2 commonContainer_bg">
            <div className="detailList_main_container d-flex py-2 ">
              <div
                className={` pt-3 cursorPointer ${isShrinked ? "" : "w-25"}`}
                onClick={() => {
                  setIsShrinked(!isShrinked);
                }}
              >
                {isShrinked ? expandArrow : shrinkArrow}
              </div>{" "}
              <div
                className={`d-flex justify-content-between px-2 ${
                  isShrinked ? "w-100" : "w-75 ps-3"
                }`}
              >
                {contactInfo?.lastUpdate && (
                  <span className="pt-3 ps-2">
                    Last Update: {contactInfo?.lastUpdate}
                  </span>
                )}
                <div className="d-flex  gap-4 justify-content-between px-2 bg-white px-1  rounded-5 py-1">
                  <span
                    // className=""
                    className={`cursorPointer } ${
                      contactPageView === "overview"
                        ? "overviewTimelineTab "
                        : "  d-flex justify-content-center align-items-center"
                    }`}
                    onClick={() => setContactPageView("overview")}
                  >
                    Overview
                  </span>
                  <span
                    className={`cursorPointer } ${
                      contactPageView === "timeline"
                        ? "overviewTimelineTab"
                        : " d-flex justify-content-center align-items-center"
                    }`}
                    onClick={() => {
                      getAllTimelineContacts();
                      setContactPageView("timeline");
                    }}
                  >
                    Timeline
                  </span>
                </div>
              </div>
            </div>

            <div className="detailList_content_container d-flex justify-content-between px-2 pt-2 bg-white">
              <div
                className={`w-25 relatedListContainer rounded commonContainer_bg me-2 ${
                  isShrinked ? "d-none" : ""
                }`} // style={{ height: "100vh" }}
              >
                <div className="d-flex">
                  <p className="opacity-57 ps-2 opacity-75 m-0 py-2">
                    Related List
                  </p>
                </div>
                <div className="horizontal_line mx-1"></div>
                <ul className="relateList_container">
                  {notePermission === "TRUE" && (
                    <li
                      className={`cursorPointer py-2 relatedList_tab rounded-1 }`}
                      onClick={() => {
                        if (contactPageView === "timeline") {
                          setContactPageView("overview");
                          setTimeout(() => scrollToSection("notesSection"), 0);
                        } else {
                          scrollToSection("notesSection");
                        }
                      }}
                    >
                      <span>
                        <img src={NoteBook} alt="img" height={20} width={20} />
                      </span>
                      Notes
                    </li>
                  )}

                  <li
                    className={`cursorPointer py-2 relatedList_tab rounded-1 }`}
                    onClick={() => {
                      if (contactPageView === "timeline") {
                        setContactPageView("overview");
                        setTimeout(() => scrollToSection("deals"), 0);
                      } else {
                        scrollToSection("deals");
                      }
                    }}
                  >
                    <span>
                      <img src={cadences} alt="img" height={20} width={20} />
                    </span>
                    Deals
                  </li>
                  <li
                    className={`cursorPointer py-2 relatedList_tab rounded-1 }`}
                    onClick={() => {
                      if (contactPageView === "timeline") {
                        setContactPageView("overview");
                        setTimeout(() => scrollToSection("openActivity"), 0);
                      } else {
                        scrollToSection("openActivity");
                      }
                    }}
                  >
                    <span>
                      <img
                        src={openActivityIcon}
                        alt="img"
                        height={20}
                        width={20}
                      />
                    </span>
                    Open Activity
                  </li>
                  <li
                    className={`cursorPointer py-2 relatedList_tab rounded-1 }`}
                    onClick={() => {
                      if (contactPageView === "timeline") {
                        setContactPageView("overview");
                        setTimeout(() => scrollToSection("closeActivity"), 0);
                      } else {
                        scrollToSection("closeActivity");
                      }
                    }}
                  >
                    <span>
                      <img
                        src={closeActivityIcon}
                        alt="img"
                        height={20}
                        width={20}
                      />
                    </span>
                    Closed Activity
                  </li>
                  <li
                    className={`cursorPointer py-2 relatedList_tab rounded-1 }`}
                    onClick={() => {
                      if (contactPageView === "timeline") {
                        setContactPageView("overview");
                        setTimeout(() => scrollToSection("product"), 0);
                      } else {
                        scrollToSection("product");
                      }
                    }}
                  >
                    <span>
                      <img src={prodIcon} alt="img" height={20} width={20} />
                    </span>
                    Products
                  </li>
                  <li
                    className={`cursorPointer py-2 relatedList_tab rounded-1 }`}
                    onClick={() => {
                      if (contactPageView === "timeline") {
                        setContactPageView("overview");
                        setTimeout(() => scrollToSection("quotes"), 0);
                      } else {
                        scrollToSection("quotes");
                      }
                    }}
                  >
                    <span>
                      <img src={attachIcon} alt="img" height={20} width={20} />
                    </span>
                    Quotes
                  </li>
                  <li
                    className={`cursorPointer py-2 relatedList_tab rounded-1 }`}
                    onClick={() => {
                      if (contactPageView === "timeline") {
                        setContactPageView("overview");
                        setTimeout(() => scrollToSection("invited"), 0);
                      } else {
                        scrollToSection("invited");
                      }
                    }}
                  >
                    <span>
                      <img src={cadences} alt="img" height={20} width={20} />
                    </span>
                    Invited Meetings
                  </li>
                  <li
                    className={`cursorPointer py-2 relatedList_tab rounded-1 }`}
                    onClick={() => {
                      if (contactPageView === "timeline") {
                        setContactPageView("overview");
                        setTimeout(() => scrollToSection("campaign"), 0);
                      } else {
                        scrollToSection("campaign");
                      }
                    }}
                  >
                    <span>
                      <img
                        src={CampaignIcon}
                        alt="img"
                        height={20}
                        width={20}
                      />
                    </span>
                    Campaigns
                  </li>
                  <li
                    className={`cursorPointer py-2 relatedList_tab rounded-1 }`}
                    onClick={() => {
                      if (contactPageView === "timeline") {
                        setContactPageView("overview");
                        setTimeout(() => scrollToSection("salesOrders"), 0);
                      } else {
                        scrollToSection("salesOrders");
                      }
                    }}
                  >
                    <span>
                      <img src={inviteIcon} alt="img" height={20} width={20} />
                    </span>
                    Sales Orders
                  </li>
                  <li
                    className={`cursorPointer py-2 relatedList_tab rounded-1 }`}
                    onClick={() => {
                      if (contactPageView === "timeline") {
                        setContactPageView("overview");
                        setTimeout(() => scrollToSection("purchaseOrder"), 0);
                      } else {
                        scrollToSection("purchaseOrder");
                      }
                    }}
                  >
                    <span>
                      <img src={emailIcon} alt="img" height={20} width={20} />
                    </span>
                    Purchase Orders
                  </li>
                  <li
                    className={`cursorPointer py-2 relatedList_tab rounded-1 }`}
                    onClick={() => {
                      if (contactPageView === "timeline") {
                        setContactPageView("overview");
                        setTimeout(() => scrollToSection("invoices"), 0);
                      } else {
                        scrollToSection("invoices");
                      }
                    }}
                  >
                    <span>
                      <img
                        src={campaignsIcon}
                        alt="img"
                        height={20}
                        width={20}
                      />
                    </span>
                    Invoices
                  </li>
                </ul>
              </div>
              {/* Contact Overview Implementation */}
              {contactPageView === "overview" ? (
                <div
                  // className="bg-white w-75 rounded-2 p-3 leadDetail_main_Container "

                  className={`bg-white w-75 rounded-2 leadDetail_main_Container ${
                    isShrinked ? "w-100" : "w-75"
                  }`}
                >
                  <div className=" common_bg ">
                    <div className="lead_DetailContainer h-25 py-2    rounded-2  commonContainer_bg d-flex justify-content-between align-items-center">
                      <div className=" w-100 d-flex flex-column ">
                        {fieldLabels.map((field, i) => {
                          const fieldValue =
                            field.value ||
                            field.name
                              .replace(/\s+/g, "")
                              .replace(/^(.)/, (match) => match.toLowerCase());
                          return field.isShowed ? (
                            <div className="d-flex w-75 ps-3" key={i}>
                              <div className="w-50 position-relative">
                                <div
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    setSelectedField(
                                      selectedField === field.name
                                        ? ""
                                        : field.name
                                    );
                                    setSearchField("");
                                  }}
                                  className={`cursorPointer d-flex justify-content-between w-100 my-1 p-1 opacity-75 ${
                                    selectedField === field.name
                                      ? "outline_border rounded-1"
                                      : ""
                                  }`}
                                >
                                  <span>{field.name}</span>
                                  <img
                                    src={
                                      selectedField === field.name ? up : down
                                    }
                                    alt="img"
                                    height={20}
                                    width={20}
                                    className="pt-1"
                                  />
                                </div>
                                {selectedField === field.name && (
                                  <div
                                    className="position-absolute field_container w-100 "
                                    onClick={(e) => {
                                      e.stopPropagation();
                                    }}
                                  >
                                    <div className="">
                                      <label
                                        htmlFor="selectField"
                                        className="position-relative w-100"
                                      >
                                        <input
                                          id="selectField"
                                          type="text"
                                          className="rounded-2 p-1 w-100 pe-4"
                                          placeholder="Search"
                                          aria-label="Username"
                                          aria-describedby="basic-addon1"
                                          value={searchField}
                                          onChange={(e) => {
                                            if (
                                              e.target.value.charAt(0) !== " "
                                            ) {
                                              const value =
                                                e.target.value.replace(
                                                  /[^a-zA-Z ]+|(\s)\s+/g,
                                                  "$1"
                                                );
                                              setSearchField(value);
                                            }

                                            // const filteredData =
                                            //   fieldDropdowns.filter((field) =>
                                            //     field.name
                                            //       .toLowerCase()
                                            //       .includes(
                                            //         value.trim().toLowerCase()
                                            //       )
                                            //   );
                                            // setShowSearchFields(
                                            //   /^[a-zA-Z]+$/.test(value)
                                            //     ? filteredData
                                            //     : fieldLabels
                                            // );
                                          }}
                                        />
                                        <img
                                          className="position-absolute mt-1 me-2 end-0 pt-1 cursorPointer"
                                          onClick={() => {
                                            setSearchField("");
                                          }}
                                          src={searchIcon}
                                          alt="search"
                                          width={20}
                                        />
                                      </label>

                                      <div className="w-100 rounded-2">
                                        <div className="border border-1 field_container_dropdown rounded-1">
                                          {showSearchFields
                                            .filter((field) =>
                                              field.name
                                                .toLowerCase()
                                                .includes(
                                                  searchField
                                                    .trim()
                                                    .toLowerCase()
                                                )
                                            )
                                            .sort((a, b) =>
                                              a.name.localeCompare(b.name)
                                            )
                                            .map(
                                              (field, i) =>
                                                (selectedField === field.name ||
                                                  !field.isShowed) && (
                                                  <div
                                                    key={field.name}
                                                    className="d-flex gap-2 p-2 cursorPointer rounded-2 align-items-center"
                                                    onClick={() => {
                                                      selectedField ===
                                                      field.name
                                                        ? setSelectedField("")
                                                        : handleFieldClick(
                                                            field,
                                                            i
                                                          );
                                                    }}
                                                  >
                                                    <span className="right_tick">
                                                      {selectedField ===
                                                      field.name
                                                        ? tick
                                                        : ""}
                                                    </span>
                                                    <span className="ps-2">
                                                      {field.name}
                                                    </span>
                                                  </div>
                                                )
                                            )}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                )}
                              </div>
                              <div
                                className={`w-50 mt-1 ms-5 position-relative`}
                              >
                                {field.type === "input" ? (
                                  <EditableInput
                                    fieldType={
                                      ["dateOfBirth"].includes(fieldValue)
                                        ? "date"
                                        : "text"
                                    }
                                    className="ps-3"
                                    inputType={fieldValue}
                                    handleIndividualInputChange={
                                      handleIndividualInputChange
                                    }
                                    editingFields={editingFields}
                                    handleEditClick={handleEditClick}
                                    handleCancelClick={handleCancelClick}
                                    handleUpdateClick={handleUpdateClick}
                                    updatedFields={updatedFields}
                                    accountOverviewDetails={contactInfo}
                                    showType={showType}
                                    setShowType={setShowType}
                                  />
                                ) : field.type === "dropdown" ? (
                                  <>
                                    {editingFields["leadSource"] &&
                                    showType === "leadSource" ? (
                                      <Select
                                        value={updatedFields.leadSource}
                                        menuPlacement="auto"
                                        onChange={(selectedOption) =>
                                          handleChangeIndDropDown(
                                            selectedOption,
                                            "leadSource"
                                          )
                                        }
                                        options={leadSourceOptions}
                                        styles={createLeadStyles}
                                        isSearchable={false}
                                      />
                                    ) : (
                                      <input
                                        type="text"
                                        className={`cursorPointer indvEditInput
                            ${
                              showType === "leadSource"
                                ? "selectedBorder "
                                : "unselectBorder bg-transparent"
                            }`}
                                        value={contactInfo?.leadSource?.value}
                                        name={"leadSource"}
                                        onClick={(e) => {
                                          if (showType === "") {
                                            updatedFields.leadSource =
                                              contactInfo.leadSource;
                                            setShowType(e.target.name);
                                            setUpdatedFields(updatedFields);
                                          }
                                          handleEditClick("leadSource");
                                        }}
                                        // readOnly={showType !== "emailId"}
                                        readOnly={true}
                                      />
                                    )}
                                    {editingFields["leadSource"] &&
                                      showType === "leadSource" && (
                                        <div className="d-flex gap-2">
                                          <span
                                            onClick={() => {
                                              setShowType("");
                                              handleUpdateClick("leadSource");
                                            }}
                                            className="ps-2 cursorPointer"
                                          >
                                            <img
                                              src={updateIcon}
                                              alt="img"
                                              height={20}
                                              width={20}
                                            />
                                          </span>
                                          <span
                                            onClick={() => {
                                              setShowType("");
                                              // handleUpdateClick(inputType);
                                              handleCancelClick("leadSource");
                                            }}
                                            className="cursorPointer"
                                          >
                                            <img
                                              src={cancelIcon}
                                              alt="img"
                                              height={20}
                                              width={20}
                                            />
                                          </span>
                                        </div>
                                      )}
                                  </>
                                ) : field.type === "readOnly" ? (
                                  <div>
                                    <span className="d-flex flex-column">
                                      <span>{contactInfo[field.value]}</span>
                                      {(field.name === "Created By" ||
                                        field.name === "Modified By") && (
                                        <span className="small opacity-75">
                                          {
                                            contactInfo[
                                              field.name === "Created By"
                                                ? "createdDate"
                                                : "modifiedDate"
                                            ]
                                          }
                                        </span>
                                      )}
                                    </span>
                                  </div>
                                ) : (
                                  <div>
                                    {field.type === "checkbox" ? (
                                      <div className="d-flex editInput_container">
                                        {showType !== fieldValue ? (
                                          <span
                                            className="right_tick cursorPointer"
                                            onClick={() => {
                                              handleEditClick(fieldValue);
                                              setShowType(fieldValue);
                                            }}
                                          >
                                            {contactInfo[fieldValue]
                                              ? tick
                                              : "-"}
                                          </span>
                                        ) : (
                                          <input
                                            type="checkbox"
                                            checked={updatedFields[fieldValue]}
                                            onChange={(e) => {
                                              setUpdatedFields(
                                                (prevFields) => ({
                                                  ...prevFields,
                                                  [fieldValue]:
                                                    e.target.checked,
                                                })
                                              );
                                            }}
                                            onClick={() => {
                                              handleEditClick(fieldValue);
                                            }}
                                          />
                                        )}
                                        {showType === fieldValue && (
                                          <div className="d-flex gap-2">
                                            <span
                                              onClick={() => {
                                                setShowType("");
                                                handleUpdateClick(fieldValue);
                                              }}
                                              className="ps-2 cursorPointer"
                                            >
                                              <img
                                                src={updateIcon}
                                                alt="Update"
                                                height={20}
                                                width={20}
                                              />
                                            </span>
                                            <span
                                              onClick={() => {
                                                setShowType("");
                                                handleCancelClick(fieldValue);
                                              }}
                                              className="cursorPointer"
                                            >
                                              <img
                                                src={cancelIcon}
                                                alt="Cancel"
                                                height={20}
                                                width={20}
                                              />
                                            </span>
                                          </div>
                                        )}
                                      </div>
                                    ) : (
                                      <div className="position-relative">
                                        <div
                                          className="d-flex input-with-clear position-relative"
                                          onClick={() => {
                                            setShowModalType("changeOwner");
                                          }}
                                        >
                                          <input
                                            type="text"
                                            className="w-100 cursorPointer indvEditInput border-0 indivOwnerInput"
                                            value={selectedItem?.name}
                                            readOnly
                                          />
                                          <span
                                            className="position-absolute end-0"
                                            style={{ marginRight: "-1.6rem" }}
                                          >
                                            {/* <span
                                              className="cursorPointer pb-1 ps-1"
                                              onClick={() =>
                                                setIsLeadOwnerModal(
                                                  !isLeadOwnerModal
                                                )
                                              }
                                            >
                                              <img
                                                src={userSearch}
                                                alt="img"
                                                width={20}
                                                height={20}
                                              />
                                            </span> */}
                                            {/* <span
                                              className="ps-5 cursorPointer pt-1"
                                              onClick={() => {
                                                setShowModalType("changeOwner");
                                                setListVisible(false);
                                              }}
                                              style={{ zIndex: "10" }}
                                            >
                                              <img
                                                src={userSearch}
                                                alt="img"
                                                width={20}
                                                height={20}
                                                className="mb-2 ms-1"
                                              />
                                            </span> */}
                                          </span>
                                        </div>
                                        {/* {listVisible && !isContactForm && (
                                          <ul className="position-absolute w-100 px-2 small field_container_dropdown">
                                            {userList.map((item, index) => (
                                              <li
                                                key={index}
                                                className="cursorPointer list-group-item my-2 border-bottom"
                                                onClick={() => {
                                                  handleUserItemClick(item);
                                                }}
                                              >
                                                <div className="d-flex flex-column">
                                                  <span> {item.name}</span>
                                                  <span className="small opacity-75">
                                                    {" "}
                                                    {item.emailId}
                                                  </span>
                                                </div>
                                              </li>
                                            ))}
                                          </ul>
                                        )} */}

                                        {/* {editingFields["contactInfo"] &&
                                          !isContactForm && (
                                            <div
                                              className="position-absolute top-0"
                                              style={{ right: "-5rem" }}
                                            >
                                              <div className="d-flex gap-2">
                                                <span
                                                  onClick={() => {
                                                    setShowType("");
                                                    handleUpdateClick(
                                                      "contactInfo"
                                                    );
                                                  }}
                                                  className="ps-2 cursorPointer"
                                                >
                                                  <img
                                                    src={updateIcon}
                                                    alt="img"
                                                    height={20}
                                                    width={20}
                                                  />
                                                </span>
                                                <span
                                                  onClick={() => {
                                                    setShowType("");
                                                    // handleUpdateClick(inputType);
                                                    handleCancelClick(
                                                      "contactInfo"
                                                    );

                                                    setSelectedItem(
                                                      userList.find(
                                                        (user) =>
                                                          user.userId ===
                                                          contactInfo.contactOwnerId
                                                      )
                                                    );
                                                  }}
                                                  className="cursorPointer"
                                                >
                                                  <img
                                                    src={cancelIcon}
                                                    alt="img"
                                                    height={20}
                                                    width={20}
                                                  />
                                                </span>
                                              </div>
                                            </div>
                                          )} */}
                                      </div>
                                    )}
                                  </div>
                                )}
                              </div>
                            </div>
                          ) : null;
                        })}
                      </div>
                      {/* <div className="d-flex flex-column gap-2 px-2 py-2">
                          <span>
                            <EditableInput
                              inputType="contactOwner"
                              handleIndividualInputChange={
                                handleIndividualInputChange
                              }
                              editingFields={editingFields}
                              handleEditClick={handleEditClick}
                              handleCancelClick={handleCancelClick}
                              handleUpdateClick={handleUpdateClick}
                              updatedFields={updatedFields}
                              accountOverviewDetails={contactInfo}
                              showType={showType}
                              setShowType={setShowType}
                            />
                          </span>

                          <span>
                            <EditableInput
                              inputType="emailId"
                              handleIndividualInputChange={
                                handleIndividualInputChange
                              }
                              editingFields={editingFields}
                              handleEditClick={handleEditClick}
                              handleCancelClick={handleCancelClick}
                              handleUpdateClick={handleUpdateClick}
                              updatedFields={updatedFields}
                              accountOverviewDetails={contactInfo}
                              showType={showType}
                              setShowType={setShowType}
                            />
                          </span>

                          <span>
                            <EditableInput
                              inputType="phoneNumber"
                              handleIndividualInputChange={
                                handleIndividualInputChange
                              }
                              editingFields={editingFields}
                              handleEditClick={handleEditClick}
                              handleCancelClick={handleCancelClick}
                              handleUpdateClick={handleUpdateClick}
                              updatedFields={updatedFields}
                              accountOverviewDetails={contactInfo}
                              showType={showType}
                              setShowType={setShowType}
                            />
                          </span>

                          <span>
                            <EditableInput
                              inputType="mobile"
                              handleIndividualInputChange={
                                handleIndividualInputChange
                              }
                              editingFields={editingFields}
                              handleEditClick={handleEditClick}
                              handleCancelClick={handleCancelClick}
                              handleUpdateClick={handleUpdateClick}
                              updatedFields={updatedFields}
                              accountOverviewDetails={contactInfo}
                              showType={showType}
                              setShowType={setShowType}
                            />
                          </span>

                          <span>
                            <EditableInput
                              inputType="department"
                              handleIndividualInputChange={
                                handleIndividualInputChange
                              }
                              editingFields={editingFields}
                              handleEditClick={handleEditClick}
                              handleCancelClick={handleCancelClick}
                              handleUpdateClick={handleUpdateClick}
                              updatedFields={updatedFields}
                              accountOverviewDetails={contactInfo}
                              showType={showType}
                              setShowType={setShowType}
                            />
                          </span>
                        </div> */}
                    </div>

                    <div className="lead_Information_mainContainer commonContainer_bg     rounded-2  d-flex flex-column my-2 py-2">
                      <h5 className="w-100 text-primary px-2 py-2">
                        Contact Information
                      </h5>

                      <div className="d-flex justify-content-between">
                        <div className="leadContact_container d-flex gap-4 w-100 ps-2 pe-2">
                          <div className="w-50 ">
                            {fieldIndividualDropdowns
                              .slice(0, 9)
                              .map((field) => {
                                const fieldValue =
                                  field.value ||
                                  field.name
                                    .replace(/\s+/g, "")
                                    .replace(/^(.)/, (match) =>
                                      match.toLowerCase()
                                    );
                                return (
                                  <div
                                    className="d-flex justify-content-between align-items-center my-2 w-100 gap-3"
                                    key={fieldValue}
                                  >
                                    <div className="opacity-75 p-2 w-100">
                                      {field.name}
                                    </div>
                                    <div className="w-100">
                                      {field.type === "input" ? (
                                        <EditableInput
                                          fieldType={
                                            ["dateOfBirth"].includes(fieldValue)
                                              ? "date"
                                              : "text"
                                          }
                                          className="ps-3"
                                          inputType={fieldValue}
                                          handleIndividualInputChange={
                                            handleIndividualInputChange
                                          }
                                          editingFields={editingFields}
                                          handleEditClick={handleEditClick}
                                          handleCancelClick={handleCancelClick}
                                          handleUpdateClick={handleUpdateClick}
                                          updatedFields={updatedFields}
                                          accountOverviewDetails={contactInfo}
                                          showType={showType}
                                          setShowType={setShowType}
                                        />
                                      ) : field.type === "dropdown" ? (
                                        <>
                                          {editingFields["leadSourceInfo"] &&
                                          showType === "leadSourceInfo" ? (
                                            <Select
                                              value={
                                                updatedFields.leadSourceInfo
                                              }
                                              menuPlacement="auto"
                                              onChange={(selectedOption) =>
                                                handleChangeIndDropDown(
                                                  selectedOption,
                                                  "leadSourceInfo"
                                                )
                                              }
                                              options={leadSourceOptions}
                                              styles={createLeadStyles}
                                              isSearchable={false}
                                            />
                                          ) : (
                                            <input
                                              type="text"
                                              className={`cursorPointer indvEditInput
                            ${
                              showType === "leadSourceInfo"
                                ? "selectedBorder "
                                : "unselectBorder bg-transparent"
                            }`}
                                              value={
                                                contactInfo?.leadSourceInfo
                                                  ?.value
                                              }
                                              name={"leadSourceInfo"}
                                              onClick={(e) => {
                                                if (showType === "") {
                                                  updatedFields.leadSourceInfo =
                                                    contactInfo.leadSourceInfo;
                                                  setShowType(e.target.name);
                                                  setUpdatedFields(
                                                    updatedFields
                                                  );
                                                }
                                                handleEditClick(
                                                  "leadSourceInfo"
                                                );
                                              }}
                                              // readOnly={showType !== "emailId"}
                                              readOnly={true}
                                            />
                                          )}
                                          {editingFields["leadSourceInfo"] &&
                                            showType === "leadSourceInfo" && (
                                              <div className="d-flex gap-2">
                                                <span
                                                  onClick={() => {
                                                    setShowType("");
                                                    handleUpdateClick(
                                                      "leadSourceInfo"
                                                    );
                                                  }}
                                                  className="ps-2 cursorPointer"
                                                >
                                                  <img
                                                    src={updateIcon}
                                                    alt="img"
                                                    height={20}
                                                    width={20}
                                                  />
                                                </span>
                                                <span
                                                  onClick={() => {
                                                    setShowType("");
                                                    // handleUpdateClick(inputType);
                                                    handleCancelClick(
                                                      "leadSourceInfo"
                                                    );
                                                  }}
                                                  className="cursorPointer"
                                                >
                                                  <img
                                                    src={cancelIcon}
                                                    alt="img"
                                                    height={20}
                                                    width={20}
                                                  />
                                                </span>
                                              </div>
                                            )}
                                        </>
                                      ) : field.type === "readOnly" ? (
                                        <div>
                                          <span className="d-flex flex-column">
                                            <span>
                                              {contactInfo[field.value]}
                                            </span>
                                            {(field.name === "Created By" ||
                                              field.name === "Modified By") && (
                                              <span className="small opacity-75">
                                                {
                                                  contactInfo[
                                                    field.name === "Created By"
                                                      ? "createdDate"
                                                      : "modifiedDate"
                                                  ]
                                                }
                                              </span>
                                            )}
                                          </span>
                                        </div>
                                      ) : (
                                        <div>
                                          {field.type === "checkbox" ? (
                                            <div className="d-flex editInput_container">
                                              {showType !== fieldValue ? (
                                                <span
                                                  className="right_tick cursorPointer"
                                                  onClick={() => {
                                                    handleEditClick(fieldValue);
                                                    setShowType(fieldValue);
                                                  }}
                                                >
                                                  {contactInfo[fieldValue]
                                                    ? tick
                                                    : "-"}
                                                </span>
                                              ) : (
                                                <input
                                                  type="checkbox"
                                                  checked={
                                                    updatedFields[fieldValue]
                                                  }
                                                  onChange={(e) => {
                                                    setUpdatedFields(
                                                      (prevFields) => ({
                                                        ...prevFields,
                                                        [fieldValue]:
                                                          e.target.checked,
                                                      })
                                                    );
                                                  }}
                                                  onClick={() => {
                                                    handleEditClick(fieldValue);
                                                  }}
                                                />
                                              )}
                                              {showType === fieldValue && (
                                                <div className="d-flex gap-2">
                                                  <span
                                                    onClick={() => {
                                                      setShowType("");
                                                      handleUpdateClick(
                                                        fieldValue
                                                      );
                                                    }}
                                                    className="ps-2 cursorPointer"
                                                  >
                                                    <img
                                                      src={updateIcon}
                                                      alt="Update"
                                                      height={20}
                                                      width={20}
                                                    />
                                                  </span>
                                                  <span
                                                    onClick={() => {
                                                      setShowType("");
                                                      handleCancelClick(
                                                        fieldValue
                                                      );
                                                    }}
                                                    className="cursorPointer"
                                                  >
                                                    <img
                                                      src={cancelIcon}
                                                      alt="Cancel"
                                                      height={20}
                                                      width={20}
                                                    />
                                                  </span>
                                                </div>
                                              )}
                                            </div>
                                          ) : (
                                            <div className="position-relative">
                                              <div
                                                className="d-flex input-with-clear position-relative"
                                                onClick={() => {
                                                  setShowModalType(
                                                    "changeOwner"
                                                  );
                                                }}
                                              >
                                                <input
                                                  type="text"
                                                  className="w-100 cursorPointer indvEditInput border-0 indivOwnerInput"
                                                  value={selectedItem?.name}
                                                  readOnly
                                                />
                                                <span
                                                  className="position-absolute end-0"
                                                  style={{
                                                    marginRight: "-1.6rem",
                                                  }}
                                                ></span>
                                              </div>
                                            </div>
                                          )}
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                );
                              })}
                          </div>
                          <div className="w-50 ">
                            {fieldIndividualDropdowns
                              .slice(9, 23)
                              .map((field) => {
                                const fieldValue =
                                  field.value ||
                                  field.name
                                    .replace(/\s+/g, "")
                                    .replace(/^(.)/, (match) =>
                                      match.toLowerCase()
                                    );
                                return (
                                  <div
                                    className="d-flex justify-content-between align-items-center my-2 w-100 gap-3"
                                    key={fieldValue}
                                  >
                                    <div className="opacity-75 p-2 w-100">
                                      {field.name}
                                    </div>
                                    <div className="w-100">
                                      {field.type === "input" ? (
                                        <EditableInput
                                          fieldType={
                                            ["dateOfBirth"].includes(fieldValue)
                                              ? "date"
                                              : "text"
                                          }
                                          className="ps-3"
                                          inputType={fieldValue}
                                          handleIndividualInputChange={
                                            handleIndividualInputChange
                                          }
                                          editingFields={editingFields}
                                          handleEditClick={handleEditClick}
                                          handleCancelClick={handleCancelClick}
                                          handleUpdateClick={handleUpdateClick}
                                          updatedFields={updatedFields}
                                          accountOverviewDetails={contactInfo}
                                          showType={showType}
                                          setShowType={setShowType}
                                        />
                                      ) : field.type === "dropdown" ? (
                                        <>
                                          {editingFields["leadSourceInfo"] &&
                                          showType === "leadSourceInfo" ? (
                                            <Select
                                              value={
                                                updatedFields.leadSourceInfo
                                              }
                                              menuPlacement="auto"
                                              onChange={(selectedOption) =>
                                                handleChangeIndDropDown(
                                                  selectedOption,
                                                  "leadSourceInfo"
                                                )
                                              }
                                              options={leadSourceOptions}
                                              styles={createLeadStyles}
                                              isSearchable={false}
                                            />
                                          ) : (
                                            <input
                                              type="text"
                                              className={`cursorPointer indvEditInput
                            ${
                              showType === "leadSourceInfo"
                                ? "selectedBorder "
                                : "unselectBorder bg-transparent"
                            }`}
                                              value={
                                                contactInfo?.leadSourceInfo
                                                  ?.value
                                              }
                                              name={"leadSourceInfo"}
                                              onClick={(e) => {
                                                if (showType === "") {
                                                  updatedFields.leadSourceInfo =
                                                    contactInfo.leadSourceInfo;
                                                  setShowType(e.target.name);
                                                  setUpdatedFields(
                                                    updatedFields
                                                  );
                                                }
                                                handleEditClick(
                                                  "leadSourceInfo"
                                                );
                                              }}
                                              // readOnly={showType !== "emailId"}
                                              readOnly={true}
                                            />
                                          )}
                                          {editingFields["leadSourceInfo"] &&
                                            showType === "leadSourceInfo" && (
                                              <div className="d-flex gap-2">
                                                <span
                                                  onClick={() => {
                                                    setShowType("");
                                                    handleUpdateClick(
                                                      "leadSourceInfo"
                                                    );
                                                  }}
                                                  className="ps-2 cursorPointer"
                                                >
                                                  <img
                                                    src={updateIcon}
                                                    alt="img"
                                                    height={20}
                                                    width={20}
                                                  />
                                                </span>
                                                <span
                                                  onClick={() => {
                                                    setShowType("");
                                                    // handleUpdateClick(inputType);
                                                    handleCancelClick(
                                                      "leadSourceInfo"
                                                    );
                                                  }}
                                                  className="cursorPointer"
                                                >
                                                  <img
                                                    src={cancelIcon}
                                                    alt="img"
                                                    height={20}
                                                    width={20}
                                                  />
                                                </span>
                                              </div>
                                            )}
                                        </>
                                      ) : field.type === "readOnly" ? (
                                        <div>
                                          <span className="d-flex flex-column">
                                            <span>
                                              {contactInfo[field.value]}
                                            </span>
                                            {(field.name === "Created By" ||
                                              field.name === "Modified By") && (
                                              <span className="small opacity-75">
                                                {
                                                  contactInfo[
                                                    field.name === "Created By"
                                                      ? "createdDate"
                                                      : "modifiedDate"
                                                  ]
                                                }
                                              </span>
                                            )}
                                          </span>
                                        </div>
                                      ) : (
                                        <div>
                                          {field.type === "checkbox" ? (
                                            <div className="d-flex editInput_container">
                                              {showType !== fieldValue ? (
                                                <span
                                                  className="right_tick cursorPointer"
                                                  onClick={() => {
                                                    handleEditClick(fieldValue);
                                                    setShowType(fieldValue);
                                                  }}
                                                >
                                                  {contactInfo[fieldValue]
                                                    ? tick
                                                    : "-"}
                                                </span>
                                              ) : (
                                                <input
                                                  type="checkbox"
                                                  checked={
                                                    updatedFields[fieldValue]
                                                  }
                                                  onChange={(e) => {
                                                    setUpdatedFields(
                                                      (prevFields) => ({
                                                        ...prevFields,
                                                        [fieldValue]:
                                                          e.target.checked,
                                                      })
                                                    );
                                                  }}
                                                  onClick={() => {
                                                    handleEditClick(fieldValue);
                                                  }}
                                                />
                                              )}
                                              {showType === fieldValue && (
                                                <div className="d-flex gap-2">
                                                  <span
                                                    onClick={() => {
                                                      setShowType("");
                                                      handleUpdateClick(
                                                        fieldValue
                                                      );
                                                    }}
                                                    className="ps-2 cursorPointer"
                                                  >
                                                    <img
                                                      src={updateIcon}
                                                      alt="Update"
                                                      height={20}
                                                      width={20}
                                                    />
                                                  </span>
                                                  <span
                                                    onClick={() => {
                                                      setShowType("");
                                                      handleCancelClick(
                                                        fieldValue
                                                      );
                                                    }}
                                                    className="cursorPointer"
                                                  >
                                                    <img
                                                      src={cancelIcon}
                                                      alt="Cancel"
                                                      height={20}
                                                      width={20}
                                                    />
                                                  </span>
                                                </div>
                                              )}
                                            </div>
                                          ) : (
                                            <div className="position-relative">
                                              <div
                                                className="d-flex input-with-clear position-relative"
                                                onClick={() => {
                                                  setShowModalType(
                                                    "changeOwner"
                                                  );
                                                }}
                                              >
                                                <input
                                                  type="text"
                                                  className="w-100 cursorPointer indvEditInput border-0 indivOwnerInput"
                                                  value={selectedItem?.name}
                                                  readOnly
                                                />
                                                <span
                                                  className="position-absolute end-0"
                                                  style={{
                                                    marginRight: "-1.6rem",
                                                  }}
                                                >
                                                  {/* <span
                                              className="cursorPointer pb-1 ps-1"
                                              onClick={() =>
                                                setIsLeadOwnerModal(
                                                  !isLeadOwnerModal
                                                )
                                              }
                                            >
                                              <img
                                                src={userSearch}
                                                alt="img"
                                                width={20}
                                                height={20}
                                              />
                                            </span> */}
                                                  {/* <span
                                              className="ps-5 cursorPointer pt-1"
                                              onClick={() => {
                                                setShowModalType("changeOwner");
                                                setListVisible(false);
                                              }}
                                              style={{ zIndex: "10" }}
                                            >
                                              <img
                                                src={userSearch}
                                                alt="img"
                                                width={20}
                                                height={20}
                                                className="mb-2 ms-1"
                                              />
                                            </span> */}
                                                </span>
                                              </div>
                                              {/* {listVisible && !isContactForm && (
                                          <ul className="position-absolute w-100 px-2 small field_container_dropdown">
                                            {userList.map((item, index) => (
                                              <li
                                                key={index}
                                                className="cursorPointer list-group-item my-2 border-bottom"
                                                onClick={() => {
                                                  handleUserItemClick(item);
                                                }}
                                              >
                                                <div className="d-flex flex-column">
                                                  <span> {item.name}</span>
                                                  <span className="small opacity-75">
                                                    {" "}
                                                    {item.emailId}
                                                  </span>
                                                </div>
                                              </li>
                                            ))}
                                          </ul>
                                        )} */}

                                              {/* {editingFields["contactInfo"] &&
                                          !isContactForm && (
                                            <div
                                              className="position-absolute top-0"
                                              style={{ right: "-5rem" }}
                                            >
                                              <div className="d-flex gap-2">
                                                <span
                                                  onClick={() => {
                                                    setShowType("");
                                                    handleUpdateClick(
                                                      "contactInfo"
                                                    );
                                                  }}
                                                  className="ps-2 cursorPointer"
                                                >
                                                  <img
                                                    src={updateIcon}
                                                    alt="img"
                                                    height={20}
                                                    width={20}
                                                  />
                                                </span>
                                                <span
                                                  onClick={() => {
                                                    setShowType("");
                                                    // handleUpdateClick(inputType);
                                                    handleCancelClick(
                                                      "contactInfo"
                                                    );

                                                    setSelectedItem(
                                                      userList.find(
                                                        (user) =>
                                                          user.userId ===
                                                          contactInfo.contactOwnerId
                                                      )
                                                    );
                                                  }}
                                                  className="cursorPointer"
                                                >
                                                  <img
                                                    src={cancelIcon}
                                                    alt="img"
                                                    height={20}
                                                    width={20}
                                                  />
                                                </span>
                                              </div>
                                            </div>
                                          )} */}
                                            </div>
                                          )}
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                );
                              })}
                          </div>
                        </div>
                      </div>
                      <h5 className="w-100 text-primary px-2 py-2">
                        Address Information
                      </h5>
                      <div className="d-flex justify-content-between">
                        <div className="leadContact_container d-flex gap-4 w-100 ps-2 pe-2">
                          <div className="w-50">
                            {fieldIndividualDropdowns
                              .slice(23, 28)
                              .map((field) => {
                                const fieldValue =
                                  field.value ||
                                  field.name
                                    .replace(/\s+/g, "")
                                    .replace(/^(.)/, (match) =>
                                      match.toLowerCase()
                                    );

                                return (
                                  <div
                                    className="d-flex justify-content-between align-items-center my-2 w-100 gap-3"
                                    key={fieldValue}
                                  >
                                    <div className="opacity-75 p-2 w-100">
                                      {field.name}
                                    </div>
                                    <div className="w-100">
                                      <EditableInput
                                        inputType={fieldValue}
                                        handleIndividualInputChange={
                                          handleIndividualInputChange
                                        }
                                        editingFields={editingFields}
                                        handleEditClick={handleEditClick}
                                        handleCancelClick={handleCancelClick}
                                        handleUpdateClick={handleUpdateClick}
                                        updatedFields={updatedFields}
                                        accountOverviewDetails={contactInfo}
                                        showType={showType}
                                        setShowType={setShowType}
                                      />
                                    </div>
                                  </div>
                                );
                              })}
                          </div>
                          <div className="w-50">
                            {fieldIndividualDropdowns
                              .slice(28, 33)
                              .map((field) => {
                                const fieldValue =
                                  field.value ||
                                  field.name
                                    .replace(/\s+/g, "")
                                    .replace(/^(.)/, (match) =>
                                      match.toLowerCase()
                                    );
                                return (
                                  <div
                                    className="d-flex justify-content-between align-items-center my-2 w-100 gap-3"
                                    key={fieldValue}
                                  >
                                    <div className="opacity-75 p-2 w-100">
                                      {field.name}
                                    </div>
                                    <div className="w-100">
                                      <EditableInput
                                        inputType={fieldValue}
                                        handleIndividualInputChange={
                                          handleIndividualInputChange
                                        }
                                        editingFields={editingFields}
                                        handleEditClick={handleEditClick}
                                        handleCancelClick={handleCancelClick}
                                        handleUpdateClick={handleUpdateClick}
                                        updatedFields={updatedFields}
                                        accountOverviewDetails={contactInfo}
                                        showType={showType}
                                        setShowType={setShowType}
                                      />
                                    </div>
                                  </div>
                                );
                              })}
                          </div>
                        </div>
                      </div>
                      <h5 className="w-100 text-primary mt-4 ps-3">
                        Description
                      </h5>
                      <div className="d-flex gap-2 px-2">
                        <span className="opacity-75 px-2 py-2">
                          Description
                        </span>
                        {/* <p>{contactInfo.description}</p> */}
                        <EditableInput
                          fieldType="text"
                          inputType="description"
                          handleIndividualInputChange={
                            handleIndividualInputChange
                          }
                          editingFields={editingFields}
                          handleEditClick={handleEditClick}
                          handleCancelClick={handleCancelClick}
                          handleUpdateClick={handleUpdateClick}
                          updatedFields={updatedFields}
                          accountOverviewDetails={contactInfo}
                          showType={showType}
                          setShowType={setShowType}
                          isTextarea={true}
                        />
                      </div>
                    </div>
                    {notePermission === "TRUE" && (
                      <div
                        id="notesSection"
                        className="lead_Information_mainContainer commonContainer_bg rounded-2  d-flex flex-column py-2 mt-2"
                      >
                        <div>
                          <Note
                            note={note}
                            addTitle={addTitle}
                            showButtons={showButtons}
                            setShowButtons={setShowButtons}
                            textFile={selectedNoteFiles}
                            showTitleNote={showTitleNote}
                            setShowTitleNote={setShowTitleNote}
                            handleTextAreaClick={handleTextAreaClick}
                            handleSaveButton={insertNote}
                            handleAddTitleClick={handleAddTitleClick}
                            setNoteTitle={setNoteTitle}
                            noteInfo={noteInfo}
                            selectRecent={selectRecent}
                            handleSelectRecent={handleSelectRecent}
                            handleDeleteClick={deleteNoteDetails}
                            leadId={contactId}
                            handleUpdateClick={updateNote}
                            setUpdateNotes={setUpdateNotes}
                            updateNotes={updateNotes}
                            setEditNote={setEditNote}
                            editNote={editNote}
                            handleChangeNoteTitle={handleChangeNoteTitle}
                            handleChangeNoteDescription={
                              handleChangeNoteDescription
                            }
                            setAddTitle={setAddTitle}
                            setNote={setNote}
                            selectedNoteFiles={selectedNoteFiles}
                            setSelectedNoteFiles={setSelectedNoteFiles}
                            onChildDataChange={handleChildDataChange}
                            moduleType={"Contacts"}
                            noteDeleteConfirmation={noteDeleteConfirmation}
                            setNoteDeleteConfirmation={
                              setNoteDeleteConfirmation
                            }
                          />
                          {showTitleNote && (
                            <textarea
                              rows={2}
                              className="notesClass px-2"
                              placeholder="Add note Title"
                              value={noteTitle}
                              onChange={(e) => setNoteTitle(e.target.value)}
                              style={{ width: "95%", marginTop: "10px" }}
                            />
                          )}
                        </div>
                      </div>
                    )}

                    {/* </div> */}

                    <div
                      id="deals"
                      // className="lead_Information_mainContainer commonContainer_bg rounded-2  d-flex flex-column py-2 mt-4"
                    >
                      <DealsCommonTable
                        title="Deals"
                        buttonLabel="New"
                        buttonAction={() => {
                          setCurrentView("deals");
                          setInnerPage("create");
                          setPastViewInfo((prev) => ({
                            ...prev,
                            view: currentView,
                            innerPage: innerPage,
                            undo: null,
                          }));
                        }}
                        showHeadings={showHeadingsDeals}
                        setShowHeadings={setShowHeadingsDeals}
                        selectedHeadings={selectedHeadingsDeals}
                        data={deals}
                        onRowClick={() => {}}
                        manageColumnsAction={() =>
                          setShowModalType("Manage Deals Columns")
                        }
                        resetColumnSizeAction={() => {}}
                        setShowSelectedFilter={setShowSelectedFilter}
                        selectedDealsId={selectedDealsId}
                        setSelectedDealsId={setSelectedDealsId}
                        handledeleteModuleDeal={handledeleteModuleDeal}
                      />{" "}
                    </div>

                    <div
                      id="openActivity"
                      className="lead_Information_mainContainer commonContainer_bg rounded-2  d-flex flex-column py-2 mt-4"
                    >
                      {selectedContact && (
                        <OpenActivity
                          openActivities={openActivities}
                          getOverview={() => getContactOverview(contactId)}
                          openActivitiesCalls={openActivitiesCalls}
                          openActivitiesMeetings={openActivitiesMeetings}
                          moduleName="contact"
                          selectedData={selectedContact}
                        />
                      )}
                    </div>

                    <div
                      id="closeActivity"
                      className="lead_Information_mainContainer commonContainer_bg rounded-2  d-flex flex-column py-2 mt-4"
                    >
                      <CloseActivity
                        closeActivities={closeActivities}
                        getOverview={() => getContactOverview(contactId)}
                        closeActivitiesCalls={closeActivitiesCalls}
                        closeActivitiesMeetings={closeActivitiesMeetings}
                      />
                    </div>

                    <div
                      id="product"
                      className="lead_Information_mainContainer commonContainer_bg rounded-2  d-flex flex-column py-2 mt-4"
                    >
                      <Product
                        module={"Contacts"}
                        productsList={productsList || []}
                        assignContactID={contactId}
                        selectedProductId={selectedProductId}
                        handleAssignModule={handleAssignModule}
                        handleAssignProductModule={() =>
                          handleAssignModule(selectedProductId, "PRODUCTS")
                        }
                        handledeleteModule={(productId) =>
                          handledeleteModule(productId)
                        }
                        setSelectedProductId={setSelectedProductId}
                        apiLoader={apiLoader}
                      />
                    </div>
                    <div
                      id="campaign"
                      className="lead_Information_mainContainer commonContainer_bg rounded-2  d-flex flex-column py-2 mt-4"
                    >
                      <CampaignsList
                        module={"Contacts"}
                        campaignList={campaignList || []}
                        assignContactID={contactId}
                        handleAssignModuleCampaigns={
                          handleAssignModuleCampaigns
                        }
                        selectedCampaignId={selectedCampaignId}
                        setSelectedCampaignId={setSelectedCampaignId}
                        handleAssignCampaignModule={() =>
                          handleAssignModuleCampaigns(
                            selectedCampaignId,
                            "CAMPAIGNS"
                          )
                        }
                        handledeleteModuleCampaigns={(campaignId) =>
                          handledeleteModuleCampaigns(campaignId)
                        }
                      />
                    </div>

                    <div
                      id="invited"
                      className="lead_Information_mainContainer commonContainer_bg rounded-2  d-flex flex-column py-2 mt-4"
                    >
                      <InvitedMeetingList invitedMeetings={invitedMeetings} />
                    </div>

                    <div id="quotes">
                      <QuotesCommonTable
                        title="Quotes"
                        buttonLabel="New"
                        buttonAction={() => {
                          setCurrentView("quotes");
                          setInnerPage("create");
                          setPastViewInfo((prev) => ({
                            ...prev,
                            view: currentView,
                            innerPage: innerPage,
                            undo: null,
                          }));
                        }}
                        showHeadings={showHeadingsQuotes}
                        setShowHeadings={setShowHeadingsQuotes}
                        selectedHeadings={selectedHeadingsQuotes}
                        data={filterQuotesDetails}
                        onRowClick={() => {}}
                        manageColumnsAction={() => {
                          setShowModalType("Manage Quotes Columns");
                          setSearchColumn("");
                          setSavingHeadings(quotesColumn);
                        }}
                        resetColumnSizeAction={() => {}}
                        setShowSelectedFilter={setShowSelectedFilter}
                        setSelectedQuoteId={setSelectedQuoteId}
                        selectedQuoteId={selectedQuoteId}
                      />
                    </div>

                    <div
                      id="salesOrders"
                      // className="lead_Information_mainContainer commonContainer_bg rounded-2  d-flex flex-column py-2 mt-4"
                    >
                      <SalesCommonTable
                        title="Sales Orders"
                        buttonLabel="New"
                        buttonAction={() => {
                          setCurrentView("salesOrders");
                          setInnerPage("create");
                          setPastViewInfo((prev) => ({
                            ...prev,
                            view: currentView,
                            innerPage: innerPage,
                            undo: null,
                          }));
                        }}
                        showHeadings={showHeadingsSales}
                        setShowHeadings={setShowHeadingsSales}
                        selectedHeadings={selectedHeadingsSales}
                        data={salesOrderList}
                        onRowClick={() => {}}
                        manageColumnsAction={() => {
                          setShowModalType("Manage Sales Columns");
                          setSearchColumn("");
                          setSavingHeadings(salesColumns);
                        }}
                        resetColumnSizeAction={() => {}}
                        setShowSelectedFilter={setShowSelectedFilter}
                        setSelectedSalesId={setSelectedSalesId}
                        selectedSalesId={selectedSalesId}
                        setSearchColumn={setSearchColumn}
                        setSavingHeadings={setSavingHeadings}
                      />
                    </div>

                    <div
                      id="purchaseOrder"
                      // className="lead_Information_mainContainer commonContainer_bg rounded-2  d-flex flex-column py-2 mt-4"
                    >
                      {/* <PurchaseOrderList
                        purchaseOrderList={purchaseOrderList}
                        selectedPurchaseId={selectedPurchaseId}
                        setSelectedPurchaseId={setSelectedPurchaseId}
                      /> */}
                      <PurchaseCommonTable
                        title="Purchase Orders"
                        buttonLabel="New"
                        buttonAction={() => {
                          setCurrentView("purchaseOrders");
                          setInnerPage("create");
                          setPastViewInfo((prev) => ({
                            ...prev,
                            view: currentView,
                            innerPage: innerPage,
                            undo: null,
                          }));
                        }}
                        showHeadings={showHeadingsPurchases}
                        setShowHeadings={setShowHeadingsPurchases}
                        selectedHeadings={selectedHeadingsPurchases}
                        onRowClick={() => {}}
                        manageColumnsAction={() =>
                          setShowModalType("Purchase Order Columns")
                        }
                        resetColumnSizeAction={() => {}}
                        data={purchaseOrderList}
                        setShowSelectedFilter={setShowSelectedFilter}
                        selectedPurchaseId={selectedPurchaseId}
                        setSelectedPurchaseId={setSelectedPurchaseId}
                      />
                    </div>
                    <div
                      id="invoices"
                      // className="lead_Information_mainContainer commonContainer_bg rounded-2  d-flex flex-column py-2 mt-4"
                    >
                      <InvoicesCommonTable
                        title="Invoices"
                        buttonLabel="New"
                        buttonAction={() => {
                          setCurrentView("invoices");
                          setInnerPage("create");
                          setPastViewInfo((prev) => ({
                            ...prev,
                            view: currentView,
                            innerPage: innerPage,
                            undo: null,
                          }));
                        }}
                        showHeadings={showHeadingsInvoices}
                        setShowHeadings={setShowHeadingsInvoices}
                        selectedHeadings={selectedHeadingsInvoices}
                        onRowClick={() => {}}
                        manageColumnsAction={() => {
                          setShowModalType("Manage Invoices Columns");
                          setSearchColumn("");
                          setSavingHeadings(invoiceColumns);
                        }}
                        resetColumnSizeAction={() => {}}
                        data={invoiceList}
                        setShowSelectedFilter={setShowSelectedFilter}
                        selectedInvoiceId={selectedInvoiceId}
                        setSelectedInvoiceId={setSelectedInvoiceId}
                        setSearchColumn={setSearchColumn}
                        setSavingHeadings={setSavingHeadings}
                      />
                    </div>
                  </div>
                </div>
              ) : (
                <>
                  <div
                    // className="timeline_main_container p-3 rounded-2 bg-white  d-flex flex-column gap-4 w-75 "
                    className={`timeline_main_container rounded-2 bg-white  d-flex flex-column gap-4  ${
                      isShrinked ? "w-100" : "w-75"
                    }`}
                  >
                    {/* <div className="upcomingAction_container w-100 flex-column h-25 py-2  d-flex  commonContainer_bg rounded-2">
                      <div className="header d-flex gap-2 p-2">
                        <span>
                          <img
                            src={hierachy}
                            alt="img"
                            height={20}
                            width={20}
                          />
                        </span>
                        <span>Upcoming Actions</span>
                      </div>
                      <div className="horizontal_line w-100  mx-1 border border-white"></div>

                      <div
                        className="d-flex justify-content-center align-items-center "
                        style={{ height: "20vh" }}
                      >
                        <h4 className="opacity-75 ">No upcoming actions</h4>
                      </div>
                    </div> */}

                    <div className="w-100 historyMain_Container flex-column   d-flex  commonContainer_bg rounded-2 ">
                      <div className="header d-flex gap-2 p-2">
                        <span>
                          <img src={history} alt="img" height={20} width={20} />
                        </span>
                        <span>History</span>
                      </div>
                      <div className="horizontal_line w-100 mx-1"></div>
                      <div className="historyContent_mainContainer ">
                        {timeLineDataList &&
                        Object.keys(timeLineDataList).length > 0 ? (
                          Object.keys(timeLineDataList).map((date) => (
                            <div
                              className="history_container pt-3 d-flex flex-column"
                              key={date}
                            >
                              <span className="px-2 timelineBg_color border border-primary rounded-5 py-2 mx-5">
                                {date}
                              </span>
                              {timeLineDataList[date].map((item, index) => (
                                <div className="d-flex gap-2" key={index}>
                                  <span className="opacity-75 pt-5 ps-2">
                                    {item.createdTime}
                                  </span>
                                  <div className="d-flex flex-column align-items-center">
                                    <p className="vertical_line m-0 p-0"></p>
                                    <span>
                                      <img
                                        src={groupIcon2}
                                        alt="img"
                                        height={25}
                                        width={25}
                                      />
                                    </span>
                                  </div>
                                  <span className="pt-5">
                                    {`${item.tmlDescription} ${item.tmlCreatedBy} ${date}`}
                                  </span>
                                </div>
                              ))}
                            </div>
                          ))
                        ) : (
                          <div
                            className="text-center d-flex justify-content-center align-items-center opacity-75"
                            style={{ height: "20vh" }}
                          >
                            <h4 className="h-75">No Data available</h4>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </>
      );
    }
  };

  const handleColumnCheckboxChange = (name, isChecked) => {
    setSavingHeadings((prevHeadings) =>
      prevHeadings.map((heading) =>
        heading.name === name ? { ...heading, isChecked } : heading
      )
    );
  };

  const handleColumnCheckboxChangeQuotes = (name, isChecked) => {
    setSavingHeadingsQuotes((prevHeadings) =>
      prevHeadings.map((heading) =>
        heading.name === name ? { ...heading, isChecked } : heading
      )
    );
  };

  const handleChangeOwnerInputClick = (e) => {
    e.stopPropagation();
    setIsChangeOwnerList(!isChangeOwnerList);

    // setInvidualListVisible(!individualListVisible);
  };

  const handleChangeOwnerItemClick = (item) => {
    setSelectedItem(item);
    setIsChangeOwnerList(false);
    // setIsSelectedOwner(false);
  };

  useEffect(() => {
    getAllContact(selectedOption);
  }, [currentPage, perPage]);

  useEffect(() => {
    // getAllAccountDetails();
    getAllVendorDetails();
    setInnerPage(innerPage || "list");
  }, []);

  useEffect(() => {
    getAllAccountDetails();
  }, [currentPageAccount, perPageAccount]);

  useEffect(() => {
    getAllVendorDetails();
  }, [currentPageVendor, perPageVendor]);

  useEffect(() => {
    const foundItem = userList.find((item) => item.userId === loginUserId);
    if (foundItem) {
      setSelectedItem(foundItem);
    }
  }, [loginUserId]);

  return (
    <div
      onClick={() => {
        setShowHeadings(false);
        setShowSelectedFilter("");
        setSelectedField("");
        setShowOptionsContact(false);
        setReportingListVisible(false);
        setListVisible(false);
        setAccountListVisible(false);
        setVendorListVisible(false);
      }}
    >
      {handleContactsView()}
      {/* <LeadOwnerModal
        isLeadOwnerModal={isLeadOwnerModal}
        setIsLeadOwnerModal={setIsLeadOwnerModal}
        onSelectedLeadChange={handleSelectedLeadChange}
      /> */}
      <Modal
        show={modalType === "Manage Columns"}
        id="ConvertModal"
        aria-labelledby="example-custom-modal-styling-title"
        className="withdrw_modal"
      >
        <Modal.Body>
          <div className="modalClass  position-relative">
            <div className="d-flex justify-content-between ">
              <h4 className="">Manage Columns</h4>
            </div>
            <div className="w-100 position-relative">
              <div className="mb-1 pt-2 position-relative w-100">
                <input
                  type="text"
                  className="assetSearchbar searchbar rounded-2 p-2 pe-5 common_input_bg w-100 "
                  placeholder="Search"
                  aria-label="Username"
                  aria-describedby="basic-addon1"
                  value={searchColumn}
                  onChange={(e) => {
                    if (e.target.value.charAt(0) !== " ") {
                      const value = e.target.value.replace(
                        /[^a-zA-Z ]+|(\s)\s+/g,
                        "$1"
                      );
                      setSearchColumn(value);
                    }
                  }}
                />
                <span
                  className="position-absolute  mt-2 me-2 end-0 opacity-75"
                  onClick={() => {
                    setSearchColumn("");
                    setSavingHeadings(contactColumn);
                  }}
                >
                  {searchColumn === "" ? (
                    <img src={searchIcon} alt="" width={20} />
                  ) : (
                    <div style={{ cursor: "pointer" }}>X</div>
                  )}
                </span>
              </div>
              <div className=" opacity-75 columns_container">
                {savingHeadings
                  .filter((lead) =>
                    lead.name
                      .toLowerCase()
                      .includes(searchColumn.trim().toLowerCase())
                  )
                  .sort((a, b) => a.name.localeCompare(b.name))
                  .map((column) => {
                    return (
                      <div
                        key={column.name}
                        className="d-flex gap-2 py-2 rounded-2 align-items-center"
                      >
                        <div className="d-flex gap-2 ps-2">
                          <input
                            type="checkbox"
                            id={column.name}
                            name={column.name}
                            checked={column.isChecked}
                            onChange={(e) => {
                              handleColumnCheckboxChange(
                                column.name,
                                e.target.checked
                              );
                            }}
                            className="cursorPointer"
                          />
                          <label
                            className="small cursorPointer"
                            htmlFor={column.name}
                          >
                            {column.name}
                          </label>
                        </div>
                      </div>
                    );
                  })}
              </div>
              <aside className="float-end mt-2">
                <div className="d-flex align-items-center gap-4">
                  <button
                    className="cancel_btn bg-light"
                    onClick={() => {
                      setShowModalType("");
                    }}
                  >
                    Cancel
                  </button>
                  <button
                    className="save_btn"
                    onClick={() => {
                      setSelectedHeadings(savingHeadings);
                      setTimeout(() => {
                        setShowModalType("");
                      }, 200);
                    }}
                  >
                    Save
                  </button>
                </div>
              </aside>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        show={showImageModal}
        className="d-flex justify-content-center align-items-center"
        aria-labelledby="example-custom-modal-styling-title"
        size="lg"
        id="commonLeadModal"
      >
        <Modal.Header
          closeButton
          onClick={() => {
            setShowImageModal(false);
            setUploadImg(false);
            // setShowOptionsContact("");
          }}
        >
          {contactInfo.contactImageData && (
            <button
              className="imageAccButtonRemove"
              onClick={() => {
                setShowRemoveModal(true);
              }}
            >
              Remove
            </button>
          )}
        </Modal.Header>
        <Modal.Body className="ModalBody overflow-auto">
          <div className="d-flex flex-column gap-4">
            <div className="d-flex" style={{ position: "relative" }}>
              {contactInfo.contactImageData && (
                <a
                  href={uploadImg ? imageUrl : contactInfo.contactImageData}
                  download
                  style={{
                    position: "absolute",
                    right: "10px",
                    top: "5px",
                    background: "#b0b0b2",
                    borderRadius: "10px",
                    padding: "5px",
                    cursor: "pointer",
                  }}
                >
                  <img src={downloadIcon} alt="download" />
                </a>
              )}

              <img
                alt="upload"
                src={
                  uploadImg
                    ? imageUrl
                    : contactInfo.contactImageData !== null
                    ? contactInfo.contactImageData
                    : ContactAvatar
                }
                className="profilePhoto"
              />
            </div>
            <p>{deleteMsg}</p>
            <div className="d-flex w-100 gap-2">
              <input
                type="file"
                id="accountImage"
                name="accountImage"
                // accept="image/*"
                accept=".png, .jpg, .jpeg, .gif"
                className="file-control"
                onChange={handleUploadImage}
              />
              <label htmlFor="accountImage" className="imageAccButton">
                <span>Upload Image</span>
              </label>
              <button
                className="imageAccButtonSave"
                onClick={() => {
                  uploadImage();
                }}
              >
                {imageLoader ? (
                  <Lottie
                    options={defaultOptions("white")}
                    height="24px"
                    width="36px"
                  />
                ) : (
                  "Save"
                )}
              </button>
            </div>
            {imageMsg && (
              <span className="mb-0 small erro_msg">{imageMsg}</span>
            )}
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={showRemoveModal}
        className="d-flex justify-content-center align-items-center"
        aria-labelledby="example-custom-modal-styling-title"
        size="lg"
      >
        <Modal.Body className="ModalBody overflow-auto">
          <div className="d-flex flex-column gap-4">
            <p className="text-center mt-3">Are you sure you want to remove?</p>
            <div className="d-flex w-100 gap-2">
              <button
                className="imageAccButton"
                onClick={() => {
                  setShowRemoveModal(false);
                  setShowImageModal(true);
                }}
              >
                Cancel
              </button>
              <button
                className="imageAccButtonSave"
                onClick={() => {
                  removeImage();
                }}
              >
                {imageLoader ? (
                  <Lottie
                    options={defaultOptions("white")}
                    height="24px"
                    width="36px"
                  />
                ) : (
                  "Remove"
                )}
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      {/* delete/update/transfer mass modal */}
      <Modal
        show={[
          "delete",
          "Mass Delete",
          "Mass Update",
          "Mass Transfer",
        ].includes(modalType)}
        aria-labelledby="contained-modal-title-vcenter"
        onClick={(e) => e.stopPropagation()}
        // size={showModalType !== "delete" ? "md" : "lg"}
      >
        <Modal.Header
          className=" border-0 mb-0 d-flex flex-column align-items-start"
          style={{ backgroundColor: modalType !== "delete" && "#F2F2F2" }}
        >
          {modalType !== "delete" && (
            <div
              className={`mass_heading fs-4 ${
                modalType !== "Mass Delete" && "ps-4"
              }`}
            >
              {modalType}
            </div>
          )}
          {modalType === "delete" && (
            <div className="fs-5" style={{ color: "#3F7DFF" }}>
              Are you sure you want to delete {contactInfo?.contactName}?
            </div>
          )}
        </Modal.Header>
        <Modal.Body className="ModalBody">
          {modalType === "delete" ? (
            <>
              <div className="d-flex gap-2 pb-2 align-items-center">
                <span className="fw-bold">Note:</span>
                <span className="small pt-1">
                  Any associated activities, visits, drafts will also be
                  deleted.
                </span>
              </div>
            </>
          ) : modalType === "Mass Delete" ? (
            <>
              <div className="fs-5 pt-0 pb-2">
                Are you sure you want to delete selected item(s)?
              </div>
              <div className="small">
                <span className="fw-bold">Note: </span>{" "}
                <span>
                  {" "}
                  Any associated Activities, Visits, Drafts will also be Deleted
                </span>
              </div>
            </>
          ) : modalType === "Mass Update" ? (
            <div className="d-flex gap-2 mb-3 mt-2 align-items-center w-100">
              <div className="w-100">
                <div className="d-flex flex-column ps-1 align-items-center mx-4">
                  <label className="text-start w-100 opacity-75">
                    Select a field
                  </label>
                  <div
                    className="position-relative w-100 rounded-1"
                    style={{ border: "1px solid rgba(185, 185, 185, 0.5)" }}
                  >
                    <div
                      onClick={(e) => {
                        e.stopPropagation();
                        setListVisible(!listVisible);
                      }}
                      className={`cursorPointer d-flex justify-content-between w-100 my-1 p-1 border-0`}
                    >
                      <span className="">{selectedObj.name}</span>
                      <img
                        src={listVisible ? up : down}
                        alt="img"
                        height={20}
                        width={20}
                        className="pt-1"
                      />
                    </div>
                    {listVisible && (
                      <div
                        className="position-absolute field_container w-100"
                        onClick={(e) => {
                          e.stopPropagation();
                        }}
                      >
                        <div className="">
                          <label
                            htmlFor="selectField"
                            className="position-relative w-100"
                          >
                            <input
                              id="selectField"
                              type="text"
                              className="rounded-2 p-1 w-100 pe-4"
                              placeholder="Search"
                              aria-label="Username"
                              aria-describedby="basic-addon1"
                              value={searchField}
                              onChange={(e) => {
                                const value = e.target.value.replace(
                                  /[^a-zA-Z]/g,
                                  ""
                                );
                                setSearchField(value);

                                const filteredData = contactColumn.filter(
                                  (field) =>
                                    field.name
                                      .toLowerCase()
                                      .includes(value.trim().toLowerCase())
                                );
                                setShowSearchFields(
                                  /^[a-zA-Z]+$/.test(value)
                                    ? filteredData
                                    : fieldLabels
                                );
                              }}
                            />
                            <img
                              className="position-absolute mt-1 me-2 end-0 pt-1 cursorPointer"
                              onClick={() => {
                                setSearchField("");
                              }}
                              src={searchIcon}
                              alt="search"
                              width={20}
                            />
                          </label>

                          <div className="w-100 rounded-2">
                            <div className="border border-1 field_container_dropdown rounded-1">
                              {showSearchFields
                                .sort((a, b) => a.name.localeCompare(b.name))
                                .map(
                                  (field) =>
                                    ![
                                      "Contact Owner",
                                      "Vendor Name",
                                      "Account Name",
                                      "Date of Birth",
                                      "Date of Birth",
                                      "Email Opt Out",
                                      "Secondary Email",
                                      "Hash",
                                    ].includes(field.name) && (
                                      <div
                                        key={field.name}
                                        className="d-flex gap-2 p-2 cursorPointer rounded-2 align-items-center"
                                        onClick={() => {
                                          setClickedDropdownObj({
                                            label: "",
                                            value: "",
                                          });
                                          setMassUpdateValue("");
                                          setSelectedObj(field);
                                          setListVisible(false);
                                        }}
                                      >
                                        <span className="ps-2">
                                          {field.name}
                                        </span>
                                      </div>
                                    )
                                )}
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="w-100 mx-auto d-flex justify-content-center mt-4">
                    <div className="w-100">
                      <label className="opacity-75" htmlFor="massValue">
                        Enter value
                      </label>
                      <div
                        className="position-relative w-100 border rounded-1"
                        style={{ border: "1px solid rgba(185, 185, 185, 0.5)" }}
                      >
                        {["Lead Source"].includes(selectedObj.name) ? (
                          <Select
                            value={clickedDropdownObj}
                            menuPlacement="auto"
                            onChange={(selectedOption) => {
                              setClickedDropdownObj(selectedOption);
                              setMassUpdateValue(selectedOption.value);
                            }}
                            options={leadSourceOptions}
                            styles={fieldDropdownStyles}
                            isSearchable={false}
                          />
                        ) : (
                          <input
                            className="w-100 border-0 py-2 rounded-1"
                            type="text"
                            value={massUpdateValue}
                            // onChange={(e) => {
                            //   setMassUpdateValue(e.target.value);
                            // }}
                            onChange={(e) => {
                              const trimmedValue = e.target.value
                                ?.replace(/\s{2,}/g, " ")
                                .trimStart();
                              setMassUpdateValue(trimmedValue);
                            }}
                            style={{ outline: "none" }}
                            id="massValue"
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="w-100 px-4 py-2 mb-1">
              <label className="text-start w-100 opacity-75">Transfer To</label>
              <div
                className="d-flex input-with-clear position-relative mx-auto"
                onClick={(e) => {
                  handleOwnerInputClick(e);
                }}
              >
                <input
                  type="text"
                  className="ps-3 w-100 cursorPointer py-2"
                  value={selectedItem?.name}
                  readOnly
                />
                <span
                  className="position-absolute h-100 mass_transfer_user pt-1"
                  style={{ right: "0.1rem" }}
                >
                  <span
                    className="cursorPointer px-1"
                    onClick={() => setIsLeadOwnerModal(!isLeadOwnerModal)}
                  >
                    <img
                      src={userSearch}
                      alt="img"
                      width={20}
                      height={20}
                      className="ms-2 mt-2 me-2"
                    />
                  </span>
                </span>
              </div>
            </div>
          )}

          <div className={`pt-4  ${modalType !== "delete" && "pe-3"}`}>
            <div className="d-flex justify-content-end w-100 gap-4 pe-2">
              <button
                className="cancelButton px-4"
                onClick={() => {
                  setShowModalType("");
                  // setSelectedCheckboxes([]);
                  setShowSearchFields(fieldDropdowns);
                }}
              >
                Cancel
              </button>
              <button
                className="createContactButtonSave"
                onClick={() => {
                  if (modalType === "delete") {
                    deleteContact();
                  } else {
                    //  else if (showModalType === "Mass Delete") {
                    //   deleteUpdateTransferMass();
                    // } else {
                    deleteUpdateTransferMass();
                  }
                }}
              >
                {modalType === "Mass Update"
                  ? "Update"
                  : modalType === "Mass Transfer"
                  ? "Done"
                  : "Delete"}
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <ShareModal
        moduleType="CONTACTS"
        setShareModal={setShareModal}
        shareModal={shareModal}
        shareInfoModal={shareInfoModal}
        setShareInfoModal={setShareInfoModal}
        id={contactId}
        shareUserList={shareUserList}
        sharePublicList={sharePublicList}
        shareGroupList={shareGroupList}
        shareRoleList={shareRoleList}
        modalHeading={"Sharing Contact"}
        shareMemberIds={shareMemberIds}
        module={"Contacts"}
      />
      <LeadOwnerModal
        isLeadOwnerModal={isLeadOwnerModal}
        setIsLeadOwnerModal={setIsLeadOwnerModal}
        onSelectedLeadChange={handleSelectedLeadChange}
        defaultUser={selectedItem}
      />

      <Modal
        show={modalType === "Manage Quotes Columns"}
        id="ConvertModal"
        aria-labelledby="example-custom-modal-styling-title"
        className="withdrw_modal"
      >
        <Modal.Body>
          <div className="modalClass  position-relative">
            <div className="d-flex justify-content-between ">
              <h4 className="">Manage Columns</h4>
              {/* <img
                src={crossIcon}
                alt="cross"
                style={{ cursor: "pointer" }}
                className="ms-1"
                onClick={() => {
                  setShowModalType("");
                }}
              /> */}
            </div>
            <div className="w-100 position-relative">
              <div className="mb-1 pt-2 position-relative w-100">
                <input
                  type="text"
                  className="assetSearchbar searchbar rounded-2 p-2 pe-5 common_input_bg w-100 "
                  placeholder="Search"
                  aria-label="Username"
                  aria-describedby="basic-addon1"
                  value={searchColumn}
                  onChange={(e) => {
                    if (e.target.value.charAt(0) !== " ") {
                      const value = e.target.value.replace(
                        /[^a-zA-Z ]+|(\s)\s+/g,
                        "$1"
                      );
                      setSearchColumn(value);
                      // const list = contactColumn.filter((contact) =>
                      //   contact.name
                      //     .toLowerCase()
                      //     .includes(value.trim().toLowerCase())
                      // );
                      // setSavingHeadingsQuotes(list);
                    }
                  }}
                />
                <span
                  className="position-absolute  mt-2 me-2 end-0 opacity-75"
                  onClick={() => {
                    setSearchColumn("");
                    setSavingHeadings(quotesColumn);
                  }}
                >
                  {searchColumn === "" ? (
                    <img src={searchIcon} alt="" width={20} />
                  ) : (
                    <div style={{ cursor: "pointer" }}>X</div>
                  )}
                </span>
              </div>
              <div className=" opacity-75 columns_container">
                {savingHeadingsQuotes
                  .filter((lead) =>
                    lead.name
                      .toLowerCase()
                      .includes(searchColumn.trim().toLowerCase())
                  )
                  .sort((a, b) => a.name.localeCompare(b.name))
                  .map((column) => {
                    return (
                      <div
                        key={column.name}
                        className="d-flex gap-2 py-2 rounded-2 align-items-center"
                      >
                        <div className="d-flex gap-2 ps-2">
                          <input
                            type="checkbox"
                            id={column.name}
                            name={column.name}
                            checked={column.isChecked}
                            onChange={(e) => {
                              handleColumnCheckboxChangeQuotes(
                                column.name,
                                e.target.checked
                              );
                            }}
                            className="cursorPointer"
                          />
                          <label
                            className="small cursorPointer"
                            htmlFor={column.name}
                          >
                            {column.name}
                          </label>
                        </div>
                      </div>
                    );
                  })}
              </div>
              <aside className="float-end mt-2">
                <div className="d-flex align-items-center gap-4">
                  <button
                    className="cancel_btn bg-light"
                    onClick={() => {
                      setShowModalType("");
                    }}
                  >
                    Cancel
                  </button>
                  <button
                    className="save_btn"
                    onClick={() => {
                      setSelectedHeadingsQuotes(savingHeadingsQuotes);
                      setTimeout(() => {
                        setShowModalType("");
                      }, 200);
                    }}
                  >
                    Save
                  </button>
                </div>
              </aside>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={modalType === "Manage Invoices Columns"}
        id="ConvertModal"
        aria-labelledby="example-custom-modal-styling-title"
        className="withdrw_modal"
      >
        <Modal.Body>
          <div className="modalClass  position-relative">
            <div className="d-flex justify-content-between ">
              <h4 className="">Manage Columns</h4>
              {/* <img
                src={crossIcon}
                alt="cross"
                style={{ cursor: "pointer" }}
                className="ms-1"
                onClick={() => {
                  setShowModalType("");
                }}
              /> */}
            </div>
            <div className="w-100 position-relative">
              <div className="mb-1 pt-2 position-relative w-100">
                <input
                  type="text"
                  className="assetSearchbar searchbar rounded-2 p-2 pe-5 common_input_bg w-100 "
                  placeholder="Search"
                  aria-label="Username"
                  aria-describedby="basic-addon1"
                  value={searchColumn}
                  onChange={(e) => {
                    if (e.target.value.charAt(0) !== " ") {
                      const value = e.target.value.replace(
                        /[^a-zA-Z ]+|(\s)\s+/g,
                        "$1"
                      );
                      setSearchColumn(value);
                      // const list = invoiceColumns.filter((contact) =>
                      //   contact.name
                      //     .toLowerCase()
                      //     .includes(value.trim().toLowerCase())
                      // );
                      // setSelectedHeadingsInvoices(list);
                    }
                  }}
                />
                <span
                  className="position-absolute  mt-2 me-2 end-0 opacity-75"
                  onClick={() => {
                    setSearchColumn("");
                    setSavingHeadings(invoiceColumns);
                  }}
                >
                  {searchColumn === "" ? (
                    <img src={searchIcon} alt="" width={20} />
                  ) : (
                    <div style={{ cursor: "pointer" }}>X</div>
                  )}
                </span>
              </div>
              <div className=" opacity-75 columns_container">
                {savingHeadingsInvoices
                  .filter((lead) =>
                    lead.name
                      .toLowerCase()
                      .includes(searchColumn.trim().toLowerCase())
                  )
                  .sort((a, b) => a.name.localeCompare(b.name))
                  .map((column) => {
                    return (
                      <div
                        key={column.name}
                        className="d-flex gap-2 py-2 rounded-2 align-items-center"
                      >
                        <div className="d-flex gap-2 ps-2">
                          <input
                            type="checkbox"
                            id={column.name}
                            name={column.name}
                            checked={column.isChecked}
                            onChange={(e) => {
                              handleColumnCheckboxChangeInvoices(
                                column.name,
                                e.target.checked
                              );
                            }}
                            className="cursorPointer"
                          />
                          <label
                            className="small cursorPointer"
                            htmlFor={column.name}
                          >
                            {column.name}
                          </label>
                        </div>
                      </div>
                    );
                  })}
              </div>
              <aside className="float-end mt-2">
                <div className="d-flex align-items-center gap-4">
                  <button
                    className="cancel_btn bg-light"
                    onClick={() => {
                      setShowModalType("");
                    }}
                  >
                    Cancel
                  </button>
                  <button
                    className="save_btn"
                    onClick={() => {
                      setSelectedHeadingsInvoices(savingHeadingsInvoices);
                      setTimeout(() => {
                        setShowModalType("");
                      }, 200);
                    }}
                  >
                    Save
                  </button>
                </div>
              </aside>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      {/* --------------sales modal ---------------*/}

      <Modal
        show={modalType === "Manage Sales Columns"}
        id="ConvertModal"
        aria-labelledby="example-custom-modal-styling-title"
        className="withdrw_modal"
      >
        <Modal.Body>
          <div className="modalClass  position-relative">
            <div className="d-flex justify-content-between ">
              <h4 className="">Manage Columns</h4>
              {/* <img
                src={crossIcon}
                alt="cross"
                style={{ cursor: "pointer" }}
                className="ms-1"
                onClick={() => {
                  setShowModalType("");
                }}
              /> */}
            </div>
            <div className="w-100 position-relative">
              <div className="mb-1 pt-2 position-relative w-100">
                <input
                  type="text"
                  className="assetSearchbar searchbar rounded-2 p-2 pe-5 common_input_bg w-100 "
                  placeholder="Search"
                  aria-label="Username"
                  aria-describedby="basic-addon1"
                  value={searchColumn}
                  onChange={(e) => {
                    if (e.target.value.charAt(0) !== " ") {
                      const value = e.target.value.replace(
                        /[^a-zA-Z ]+|(\s)\s+/g,
                        "$1"
                      );
                      setSearchColumn(value);
                      // const list = salesColumns.filter((contact) =>
                      //   contact.name
                      //     .toLowerCase()
                      //     .includes(value.trim().toLowerCase())
                      // );
                      // setSelectedHeadingsSales(list);
                    }
                  }}
                />
                <span
                  className="position-absolute  mt-2 me-2 end-0 opacity-75"
                  onClick={() => {
                    setSearchColumn("");
                    setSavingHeadings(salesColumns);
                  }}
                >
                  {searchColumn === "" ? (
                    <img src={searchIcon} alt="" width={20} />
                  ) : (
                    <div style={{ cursor: "pointer" }}>X</div>
                  )}
                </span>
              </div>
              <div className=" opacity-75 columns_container">
                {savingHeadingsSales
                  .filter((lead) =>
                    lead.name
                      .toLowerCase()
                      .includes(searchColumn.trim().toLowerCase())
                  )
                  .map((column) => {
                    return (
                      <div
                        key={column.name}
                        className="d-flex gap-2 py-2 rounded-2 align-items-center"
                      >
                        <div className="d-flex gap-2 ps-2">
                          <input
                            type="checkbox"
                            id={column.name}
                            name={column.name}
                            checked={column.isChecked}
                            onChange={(e) => {
                              handleColumnCheckboxChangeSales(
                                column.name,
                                e.target.checked
                              );
                            }}
                            className="cursorPointer"
                          />
                          <label
                            className="small cursorPointer"
                            htmlFor={column.name}
                          >
                            {column.name}
                          </label>
                        </div>
                      </div>
                    );
                  })}
              </div>
              <aside className="float-end mt-2">
                <div className="d-flex align-items-center gap-4">
                  <button
                    className="cancel_btn bg-light"
                    onClick={() => {
                      setShowModalType("");
                    }}
                  >
                    Cancel
                  </button>
                  <button
                    className="save_btn"
                    onClick={() => {
                      setSelectedHeadingsSales(savingHeadingsSales);
                      setSearchColumn("");

                      setTimeout(() => {
                        setShowModalType("");
                      }, 200);
                    }}
                  >
                    Save
                  </button>
                </div>
              </aside>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      {/* --------- */}

      <Modal
        show={modalType === "Purchase Order Columns"}
        id="ConvertModal"
        aria-labelledby="example-custom-modal-styling-title"
        className="withdrw_modal"
      >
        <Modal.Body>
          <div className="modalClass  position-relative">
            <div className="d-flex justify-content-between ">
              <h4 className="">Manage Columns</h4>
              {/* <img
                src={crossIcon}
                alt="cross"
                style={{ cursor: "pointer" }}
                className="ms-1"
                onClick={() => {
                  setShowModalType("");
                }}
              /> */}
            </div>
            <div className="w-100 position-relative">
              <div className="mb-1 pt-2 position-relative w-100">
                <input
                  type="text"
                  className="assetSearchbar searchbar rounded-2 p-2 pe-5 common_input_bg w-100 "
                  placeholder="Search"
                  aria-label="Username"
                  aria-describedby="basic-addon1"
                  value={searchColumn}
                  onChange={(e) => {
                    if (e.target.value.charAt(0) !== " ") {
                      const value = e.target.value.replace(
                        /[^a-zA-Z ]+|(\s)\s+/g,
                        "$1"
                      );
                      setSearchColumn(value);
                      // const list = purchaseColumns.filter((contact) =>
                      //   contact.name
                      //     .toLowerCase()
                      //     .includes(value.trim().toLowerCase())
                      // );
                      // setSelectedHeadingsPurchases(list);
                    }
                  }}
                />
                <span
                  className="position-absolute  mt-2 me-2 end-0 opacity-75"
                  onClick={() => {
                    setSearchColumn("");
                    setSavingHeadings(purchaseColumns);
                  }}
                >
                  {searchColumn === "" ? (
                    <img src={searchIcon} alt="" width={20} />
                  ) : (
                    <div style={{ cursor: "pointer" }}>X</div>
                  )}
                </span>
              </div>
              <div className=" opacity-75 columns_container">
                {savingHeadingsPurchase
                  .filter((lead) =>
                    lead.name
                      .toLowerCase()
                      .includes(searchColumn.trim().toLowerCase())
                  )
                  .sort((a, b) => a.name.localeCompare(b.name))
                  .map((column) => {
                    return (
                      <div
                        key={column.name}
                        className="d-flex gap-2 py-2 rounded-2 align-items-center"
                      >
                        <div className="d-flex gap-2 ps-2">
                          <input
                            type="checkbox"
                            id={column.name}
                            name={column.name}
                            checked={column.isChecked}
                            onChange={(e) => {
                              handleColumnCheckboxChangePurchase(
                                column.name,
                                e.target.checked
                              );
                            }}
                            className="cursorPointer"
                          />
                          <label
                            className="small cursorPointer"
                            htmlFor={column.name}
                          >
                            {column.name}
                          </label>
                        </div>
                      </div>
                    );
                  })}
              </div>
              <aside className="float-end mt-2">
                <div className="d-flex align-items-center gap-4">
                  <button
                    className="cancel_btn bg-light"
                    onClick={() => {
                      setShowModalType(" ");
                    }}
                  >
                    Cancel
                  </button>
                  <button
                    className="save_btn"
                    onClick={() => {
                      setSelectedHeadingsPurchases(savingHeadingsPurchase);
                      setSearchColumn(" ");

                      setTimeout(() => {
                        setShowModalType("");
                      }, 200);
                    }}
                  >
                    Save
                  </button>
                </div>
              </aside>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={modalType === "Manage Deals Columns"}
        id="ConvertModal"
        aria-labelledby="example-custom-modal-styling-title"
        className="withdrw_modal"
      >
        <Modal.Body>
          <div className="modalClass  position-relative">
            <div className="d-flex justify-content-between ">
              <h4 className="">Manage Columns</h4>
              {/* <img
                src={crossIcon}
                alt="cross"
                style={{ cursor: "pointer" }}
                className="ms-1"
                onClick={() => {
                  setShowModalType("");
                }}
              /> */}
            </div>
            <div className="w-100 position-relative">
              <div className="mb-1 pt-2 position-relative w-100">
                <input
                  type="text"
                  className="assetSearchbar searchbar rounded-2 p-2 pe-5 common_input_bg w-100 "
                  placeholder="Search"
                  aria-label="Username"
                  aria-describedby="basic-addon1"
                  value={searchColumn}
                  onChange={(e) => {
                    if (e.target.value.charAt(0) !== " ") {
                      const value = e.target.value.replace(
                        /[^a-zA-Z ]+|(\s)\s+/g,
                        "$1"
                      );
                      setSearchColumn(value);
                      // const list = quotesColumn.filter((contact) =>
                      //   contact.name
                      //     .toLowerCase()
                      //     .includes(value.trim().toLowerCase())
                      // );
                      // setSelectedHeadingsDeals(list);
                    }
                  }}
                />
                <span
                  className="position-absolute  mt-2 me-2 end-0 opacity-75"
                  onClick={() => {
                    setSearchColumn("");
                    setSavingHeadings(dealsColumn);
                  }}
                >
                  {searchColumn === "" ? (
                    <img src={searchIcon} alt="" width={20} />
                  ) : (
                    <div style={{ cursor: "pointer" }}>X</div>
                  )}
                </span>
              </div>
              <div className=" opacity-75 columns_container">
                {savingHeadingsDeals

                  .filter((lead) =>
                    lead.name
                      .toLowerCase()
                      .includes(searchColumn.trim().toLowerCase())
                  )
                  .map((column) => {
                    return (
                      <div
                        key={column.name}
                        className="d-flex gap-2 py-2 rounded-2 align-items-center"
                      >
                        <div className="d-flex gap-2 ps-2">
                          <input
                            type="checkbox"
                            id={column.name}
                            name={column.name}
                            checked={column.isChecked}
                            onChange={(e) => {
                              handleColumnCheckboxChangeDeals(
                                column.name,
                                e.target.checked
                              );
                            }}
                            className="cursorPointer"
                          />
                          <label
                            className="small cursorPointer"
                            htmlFor={column.name}
                          >
                            {column.name}
                          </label>
                        </div>
                      </div>
                    );
                  })}
              </div>
              <aside className="float-end mt-2">
                <div className="d-flex align-items-center gap-4">
                  <button
                    className="cancel_btn bg-light"
                    onClick={() => {
                      setShowModalType("");
                    }}
                  >
                    Cancel
                  </button>
                  <button
                    className="save_btn"
                    onClick={() => {
                      setSelectedHeadingsDeals(savingHeadingsDeals);
                      setSearchColumn("");

                      setTimeout(() => {
                        setShowModalType("");
                      }, 200);
                    }}
                  >
                    Save
                  </button>
                </div>
              </aside>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <ChangeOwnerModal
        modalType={modalType}
        setShowModalType={setShowModalType}
        selectedOwnerItem={selectedItem}
        handleChangeOwnerInputClick={handleChangeOwnerInputClick}
        setSelectedOwnerItem={setSelectedItem}
        isLeadOwnerModal={isLeadOwnerModal}
        setIsLeadOwnerModal={setIsLeadOwnerModal}
        isChangeOwnerList={isChangeOwnerList}
        userList={userList}
        handleChangeOwnerItemClick={handleChangeOwnerItemClick}
        emailNotification={emailNotification}
        setEmailNotification={setEmailNotification}
        handleEditClick={handleEditClick}
        handleUpdateClick={handleUpdateClick}
        setUpdatedFields={setUpdatedFields}
        existingContact={editContact}
        handleUpdateContact={UpdateContact}
        onSelectedChangeOwner={handleSelectedChangeOwner}
        apiLoader={apiLoader} // for lottie loader
      />

      <AccessModal
        setShowAccessModal={setShowAccessModal}
        showAccessModal={showAccessModal}
      />
      <NotificationContainer />
    </div>
  );
}

export default Contacts;

import React, { useEffect, useState } from "react";
import companyLogo from "../../Assets/company_logo.png";
import NoData from "../../Assets/Nodata.svg";
import dashboardNoData from "../../Assets/dashboard_noData.svg";
import cancelCircle from "../../Assets/cancelCircle.svg";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import { URI } from "../../constants";
import axios from "axios";
import { useSelector } from "react-redux";
import Select from "react-select";
import { Modal } from "react-bootstrap";
import { componentData } from "./dashboardData";
import {
  customModules,
  dashboardModules,
  getFromOptions,
} from "../../common/CommonData";
import TableComponent from "./DashboardComponents/Table";
// import DonutChart from "./DashboardComponents/Doughnut";
import DonutChart from "./DashboardComponents/DonutChart";
import ColumnChart from "./DashboardComponents/ColumnChart";
import FunnelChart from "./DashboardComponents/FunnelChart";
import GaugeChartComponent from "./DashboardComponents/GaugeChartComponent";
import {
  convertSnakeCaseToReadableString,
  formatNumberWithoutDecimalZero,
} from "../../common/CommonComponent";
import Lottie from "react-lottie";
import { defaultOptions } from "../../utils/defaultOptions";
import getHeaders from "../../common/ApiHeader";
import Loader from "../../common/commonRelatedList/Loader";
import { useNavigate } from "react-router-dom";
import { handleApiError } from "../../utils/ApiUtils";

const deleteIcon = (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16.25 4.58331L15.7336 12.9376C15.6016 15.072 15.5356 16.1392 15.0006 16.9065C14.7361 17.2859 14.3955 17.606 14.0006 17.8467C13.2018 18.3333 12.1325 18.3333 9.99395 18.3333C7.8526 18.3333 6.78192 18.3333 5.98254 17.8458C5.58733 17.6047 5.24666 17.284 4.98224 16.904C4.4474 16.1355 4.38288 15.0667 4.25384 12.9293L3.75 4.58331"
      stroke="#FD3434"
      strokeWidth="1.25"
      strokeLinecap="round"
    />
    <path
      d="M2.5 4.58333H17.5M13.3798 4.58333L12.8109 3.40977C12.433 2.63021 12.244 2.24043 11.9181 1.99734C11.8458 1.94341 11.7692 1.89545 11.6892 1.85391C11.3283 1.66666 10.8951 1.66666 10.0288 1.66666C9.14069 1.66666 8.69665 1.66666 8.32974 1.86176C8.24842 1.905 8.17082 1.95491 8.09774 2.01097C7.76803 2.26391 7.58386 2.66796 7.2155 3.47605L6.71077 4.58333"
      stroke="#FD3434"
      strokeWidth="1.25"
      strokeLinecap="round"
    />
    <path
      d="M7.91602 13.75L7.91602 8.74998"
      stroke="#FD3434"
      strokeWidth="1.25"
      strokeLinecap="round"
    />
    <path
      d="M12.084 13.75L12.084 8.74998"
      stroke="#FD3434"
      strokeWidth="1.25"
      strokeLinecap="round"
    />
  </svg>
);

// const editIcon = (
//   <svg
//     width="20"
//     height="20"
//     viewBox="0 0 20 20"
//     fill="none"
//     xmlns="http://www.w3.org/2000/svg"
//   >
//     <g clip-path="url(#clip0_5050_20423)">
//       <path
//         d="M5.83594 5.83301H5.0026C4.56058 5.83301 4.13665 6.0086 3.82409 6.32116C3.51153 6.63372 3.33594 7.05765 3.33594 7.49967V14.9997C3.33594 15.4417 3.51153 15.8656 3.82409 16.1782C4.13665 16.4907 4.56058 16.6663 5.0026 16.6663H12.5026C12.9446 16.6663 13.3686 16.4907 13.6811 16.1782C13.9937 15.8656 14.1693 15.4417 14.1693 14.9997V14.1663"
//         stroke="#2177FE"
//         strokeWidth="1.25"
//         strokeLinecap="round"
//         strokeLinejoin="round"
//       />
//       <path
//         d="M16.9875 5.48759C17.3157 5.15938 17.5001 4.71424 17.5001 4.25009C17.5001 3.78594 17.3157 3.34079 16.9875 3.01259C16.6593 2.68438 16.2142 2.5 15.75 2.5C15.2858 2.5 14.8407 2.68438 14.5125 3.01259L7.5 10.0001V12.5001H10L16.9875 5.48759Z"
//         stroke="#2177FE"
//         strokeWidth="1.25"
//         strokeLinecap="round"
//         strokeLinejoin="round"
//       />
//       <path
//         d="M13.3359 4.16699L15.8359 6.66699"
//         stroke="#2177FE"
//         strokeWidth="1.25"
//         strokeLinecap="round"
//         strokeLinejoin="round"
//       />
//     </g>
//     <defs>
//       <clipPath id="clip0_5050_20423">
//         <rect width="20" height="20" fill="white" />
//       </clipPath>
//     </defs>
//   </svg>
// );

function InitialHome({ currentView }) {
  const navigate = useNavigate();

  const [dashboardData, setDashboardData] = useState([]);
  const [modalType, setModalType] = useState("");
  const [getFormType, setGetFormType] = useState(getFromOptions[0]);
  const [moduleType, setModuleType] = useState(dashboardModules[0]);
  const [componentType, setComponentType] = useState({
    value: "",
    label: "",
  });
  const [componentName, setComponentName] = useState("");
  const [apiLoader, setApiLoader] = useState(false);
  const [selectedComponentId, setSelectedComponentId] = useState("");

  const reduxStore = useSelector((state) => state.reduxState);
  const { loginUserId, loginUserInfo } = reduxStore;

  const getDashboardData = async () => {
    const requestBody = {
      loggedInUserId: loginUserId,
    };

    setApiLoader("all");
    try {
      const headersResult = getHeaders(true);

      if (headersResult.error) {
        navigate("../");
        return;
      }
      const response = await axios.post(URI.getDashboardData, requestBody, {
        headers: headersResult,
      });
      if (response.data.status === 200) {
        setDashboardData(response.data.data);
      } else {
        setDashboardData([]);
      }
      setApiLoader("");
    } catch (err) {
      setApiLoader("");
      handleApiError(err, navigate);
    }
  };

  const deleteDashboardData = async () => {
    const requestBody = {
      loggedInUserId: loginUserId,
      componentId: selectedComponentId,
    };
    setApiLoader("delete");
    try {
      const response = await axios.post(URI.deleteDashboardData, requestBody, {
        headers: getHeaders(true),
      });
      if (response.data.status === 200) {
        NotificationManager.success("", response.data.message, 5000);
        getDashboardData();
        setModalType("");
      } else {
        NotificationManager.error("", response.data.message, 5000);
      }
    } catch (err) {
      handleApiError(err, navigate);
    } finally {
      setApiLoader("");
    }
  };

  const addDashboardData = async () => {
    const requestBody = {
      loggedInUserId: loginUserId,
      componentModuleName: moduleType.value,
      componentType: componentType.value,
      componentName: componentName,
    };

    setApiLoader("add");
    try {
      const response = await axios.post(URI.addDashboardData, requestBody, {
        headers: getHeaders(true),
      });
      if (response.data.status === 200) {
        NotificationManager.success("", response.data.message, 5000);
        getDashboardData();
      } else {
        NotificationManager.error("", response.data.message, 5000);
      }
      setModalType("");
    } catch (err) {
      handleApiError(err, navigate);
    } finally {
      setApiLoader("");
    }
  };

  useEffect(() => {
    getDashboardData();
  }, []);

  return (
    <>
      <div className="welcomeBar pb-0">
        <div className="welcomeLeft">
          <div>
            <img
              src={companyLogo}
              style={{
                padding: "5px",
                borderRadius: "10px",
              }}
              alt="blockstack"
              height={50}
              width={50}
            />
          </div>
          <div>
            <span style={{ fontWeight: "500" }}>
              Welcome,
              <span className="ps-1">{loginUserInfo?.firstName}</span>
              <span className="ps-1">{loginUserInfo?.lastName}</span>
            </span>
          </div>
        </div>
        <div className="welcomeRight">
          {/* <img src={Vector} alt="vector" className="cursorPointer" />
          <div className="dropdown">
            <button
              className="btn dropdown-toggle"
              type="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
              style={{ border: "2px solid #316AFF" }}
            >
              BS’s Home
            </button>
            <ul className="dropdown-menu">
              <li>
                <a className="dropdown-item" href="#">
                  Action
                </a>
              </li>
              <li>
                <a className="dropdown-item" href="#">
                  Another action
                </a>
              </li>
              <li>
                <a className="dropdown-item" href="#">
                  Something else here
                </a>
              </li>
            </ul>
          </div> */}
          <div className="w-auto">
            <button
              className="createLeadButton w-auto"
              onClick={(e) => {
                e.stopPropagation();
                setGetFormType(getFromOptions[0]);
                setModuleType(dashboardModules[0]);
                setComponentType(componentData[dashboardModules[0].value][0]);
                setComponentName("");
                setModalType("add_component");
              }}
            >
              Add Component
            </button>
            {/* {showDropdownType === "component" ? (
              <div className="position-absolute bg-white rounded-2 add_comp_dropdown end-0 px-1 py-2">
                <span
                  className="p-1 cursorPointer"
                  onClick={(e) => {
                    e.stopPropagation();
                    setModalType("add_component");
                    setShowDropdownType("");
                  }}
                >
                  Add Component
                </span>
              </div>
            ) : null} */}
          </div>
        </div>
      </div>
      <section
        className={`px-1 d-flex flex-wrap dashboard_container w-100  ${
          apiLoader !== "all"
            ? "justify-content-start"
            : "justify-content-center"
        } `}
      >
        {apiLoader === "all" ? (
          <div>
            <Loader />
          </div>
        ) : (
          <>
            {dashboardData.length > 0 ? (
              dashboardData?.map((component, i) => {
                const moduleType =
                  componentData[component?.componentModuleName];
                const selectedComponentArray = moduleType?.filter(
                  (module) => module.value === component.componentType
                );

                const componentType =
                  selectedComponentArray?.length > 0
                    ? selectedComponentArray[0]?.view || "Table"
                    : "Table";

                const headers =
                  component.componentData?.length > 0
                    ? Object.keys(component?.componentData[0])
                    : [];

                return (
                  <div key={i} className="">
                    {componentType === "Table" ? (
                      <TableComponent
                        component={component}
                        index={i}
                        deleteIcon={deleteIcon}
                        setModalType={setModalType}
                        setSelectedComponentId={setSelectedComponentId}
                      />
                    ) : componentType === "Doughnut chart" ? (
                      <div className="">
                        <DonutChart
                          component={component}
                          index={i}
                          deleteIcon={deleteIcon}
                          setModalType={setModalType}
                          setSelectedComponentId={setSelectedComponentId}
                          currentView={currentView}
                        />
                      </div>
                    ) : // <GaugeChartComponent
                    //   component={component}
                    //   index={i}
                    //   deleteIcon={deleteIcon}
                    // />
                    componentType === "Column chart" ? (
                      <ColumnChart
                        component={component}
                        index={i}
                        deleteIcon={deleteIcon}
                        convertSnakeCaseToReadableString={
                          convertSnakeCaseToReadableString
                        }
                        setModalType={setModalType}
                        setSelectedComponentId={setSelectedComponentId}
                        currentView={currentView}
                        showTitles={false}
                        // showTitles={[
                        //   "campaigns_by_leads",
                        //   "campaigns_by_type",
                        // ].includes(component?.componentType)}
                        // title={{ y: "Record Count", x: "Campaign Name" }}
                      />
                    ) : componentType === "Funnel chart" ? (
                      <FunnelChart
                        component={component}
                        index={i}
                        deleteIcon={deleteIcon}
                        convertSnakeCaseToReadableString={
                          convertSnakeCaseToReadableString
                        }
                        setModalType={setModalType}
                        setSelectedComponentId={setSelectedComponentId}
                        currentView={currentView}
                      />
                    ) : componentType === "Gauge chart" ? (
                      <GaugeChartComponent
                        component={component}
                        index={i}
                        deleteIcon={deleteIcon}
                        setModalType={setModalType}
                        setSelectedComponentId={setSelectedComponentId}
                      />
                    ) : (
                      <div className="component_container mx-2 mt-3 rounded-1 position-relative">
                        <div className="component_header d-flex justify-content-between p-2">
                          <span
                            className="first_letter_uppercase fs-5 overflow_text"
                            title={component.componentName}
                          >
                            {component.componentName}
                          </span>
                        </div>
                        {component.componentData?.length > 0 ? (
                          <div className="component_content p-2">
                            {/* <h5>
                          {convertSnakeCaseToReadableString(
                            component.componentType
                          )}
                        </h5> */}
                            {[
                              "deals_created",
                              "leads_this_month",
                              "revenue_this_month",
                              "account_this_month",
                              "this_week_leads",
                              "revenue_lost",
                            ].includes(component.componentType) ? (
                              <div className="">
                                <div className="mt-3">
                                  <span className="fs-5 fw-bold">
                                    {[
                                      "actual_cost",
                                      "budgeted_cost",
                                      "expected_revenue",
                                      "revenue_lost",
                                      "revenue_this_month",
                                    ].includes(component.componentType)
                                      ? "₹"
                                      : ""}
                                    {
                                      component.componentData[0][
                                        ["this_week_leads"].includes(
                                          component.componentType
                                        )
                                          ? "leadsThisWeek"
                                          : "currentMonthData"
                                      ]
                                    }
                                  </span>
                                  <span
                                    className={`fs-5 ps-3 ${
                                      component?.componentData[0]?.percentage?.includes(
                                        "-"
                                      )
                                        ? "text-danger"
                                        : "text-success"
                                    }`}
                                  >
                                    {`${
                                      component?.componentData?.length > 0
                                        ? component?.componentData[0]
                                            ?.percentage + "%"
                                        : ""
                                    }`}
                                  </span>
                                </div>
                                <div className="mt-1">
                                  <span>
                                    {["this_week_leads"].includes(
                                      component.componentType
                                    )
                                      ? "Last Week Relative: "
                                      : "Last Month Relative: "}{" "}
                                  </span>
                                  <span>
                                    {[
                                      "actual_cost",
                                      "budgeted_cost",
                                      "expected_revenue",
                                      "revenue_lost",
                                      "revenue_this_month",
                                    ].includes(component.componentType)
                                      ? "₹"
                                      : ""}
                                    {headers?.length > 1
                                      ? component.componentData[0][
                                          headers[
                                            ["this_week_leads"].includes(
                                              component.componentType
                                            )
                                              ? 2
                                              : 0
                                          ]
                                        ]
                                      : ""}
                                  </span>
                                </div>
                              </div>
                            ) : (
                              <div>
                                {headers?.length === 1 ? (
                                  <div className="fs-5 opacity-75">
                                    {component.componentData[0][headers[0]]}
                                  </div>
                                ) : (
                                  <div className="">
                                    <div className="mt-3">
                                      <span className="fs-5 fw-bold">
                                        {
                                          component.componentData[0]
                                            ?.recordsCount
                                        }
                                      </span>
                                      <span className="fs-5 fw-bold">
                                        {formatNumberWithoutDecimalZero(
                                          component.componentData[0][
                                            [
                                              "total_campaigns",
                                              "actual_cost",
                                              "budgeted_cost",
                                              "expected_revenue",
                                            ].includes(component.componentType)
                                              ? "currentYearData"
                                              : "currentMonthData"
                                          ]?.toLocaleString("en-US", {
                                            minimumFractionDigits: 2,
                                            maximumFractionDigits: 2,
                                          })
                                        )}
                                      </span>
                                      {typeof component?.componentData[0]
                                        ?.percentage === "string" && (
                                        <span
                                          className={`fs-5 ps-3 ${
                                            component?.componentData[0]?.percentage?.includes(
                                              "-"
                                            )
                                              ? "text-danger"
                                              : "text-success"
                                          }`}
                                        >
                                          {`${
                                            component.componentData.length > 0
                                              ? component.componentData[0]
                                                  ?.percentage + "%"
                                              : ""
                                          }`}
                                        </span>
                                      )}
                                    </div>
                                    <div className="mt-1">
                                      <span>
                                        {[
                                          "total_campaigns",
                                          "expected_revenue",
                                          "budgeted_cost",
                                          "actual_cost",
                                        ].includes(component.componentType)
                                          ? "Last Year Relative: "
                                          : component.componentType ===
                                            "today's_leads"
                                          ? "Yesterday Relative: "
                                          : "Last Month Relative: "}
                                      </span>
                                      <span>
                                        {headers?.length > 1
                                          ? component.componentData[0][
                                              headers[
                                                [
                                                  "total_campaigns",
                                                  "expected_revenue",
                                                  "budgeted_cost",
                                                  "actual_cost",
                                                ].includes(
                                                  component.componentType
                                                )
                                                  ? 2
                                                  : 0
                                              ]
                                            ]
                                          : ""}
                                      </span>
                                    </div>
                                  </div>
                                )}
                              </div>
                            )}
                          </div>
                        ) : (
                          <div className="text-center d-flex flex-column justify-content-center align-items-center no_data_img mt-2">
                            <img src={NoData} alt="No data" className="" />
                            <h4 className="fs-5 my-0 py-0">No data</h4>
                          </div>
                        )}
                        <div className="component_footer p-2 position-absolute end-0 bottom-0 mb-1">
                          <span className="">
                            <span
                              className="deleteIcon rounded-1 p-1 cursorPointer"
                              onClick={() => {
                                setModalType("delete");
                                setSelectedComponentId(component.componentId);
                              }}
                            >
                              {deleteIcon}
                            </span>
                          </span>
                        </div>
                      </div>
                    )}
                  </div>
                );
              })
            ) : (
              <div className="text-center d-flex flex-column justify-content-center align-items-center mx-auto">
                <img
                  src={dashboardNoData}
                  alt="No data"
                  className=""
                  height={230}
                />
                <h4 className="mt-3 py-0 mb-0 opacity-75">
                  Hi, {loginUserInfo?.companyName}!
                </h4>
                <p className="d-flex flex-column py-0 opacity-50">
                  <span>
                    Seems that there are no data points available to show
                  </span>
                  <span>on your dashboard</span>
                </p>
                <button
                  className="save-button w-auto px-3 py-2 rounded-pill"
                  onClick={() => {
                    setGetFormType(getFromOptions[0]);
                    setModuleType(dashboardModules[0]);
                    setComponentType(
                      componentData[dashboardModules[0].value][0]
                    );
                    setComponentName("");
                    setModalType("add_component");
                  }}
                >
                  Add Component
                </button>
              </div>
            )}
          </>
        )}
      </section>
      {/*  */}

      <Modal
        show={modalType === "add_component"}
        aria-labelledby="contained-modal-title-vcenter"
        onClick={(e) => e.stopPropagation()}
        // size={showModalType !== "delete" ? "md" : "lg"}
      >
        <Modal.Header
          className=" border-0 mb-0 d-flex flex-column align-items-start"
          style={{ backgroundColor: "#F2F2F2" }}
        >
          <div
            className="d-flex justify-content-between w-100"
            style={{ background: "rgba(242, 242, 242, 1)" }}
          >
            <div>
              <h4 className="commonTextColor">Add Component</h4>
            </div>
            <div>
              <img
                src={cancelCircle}
                alt="cancel"
                style={{
                  width: "25px",
                  height: "25px",
                }}
                className="cursorPointer"
                onClick={() => {
                  setModalType("");
                }}
              />
            </div>
          </div>
        </Modal.Header>
        <Modal.Body className="ModalBody">
          <div className="d-flex gap-2 mb-3 mt-2 align-items-center w-100">
            <div className="w-100">
              <div className="d-flex flex-column ps-1 align-items-center mx-4">
                <label className="text-start w-100 opacity-75">Get From</label>
                <div
                  className="position-relative w-100 border rounded-1"
                  // style={{ border: "1px solid rgba(185, 185, 185, 0.5)" }}
                >
                  <Select
                    menuPlacement="auto"
                    value={getFormType}
                    options={getFromOptions}
                    isSearchable={false}
                    onChange={(option) => {
                      setGetFormType(option);
                      setModuleType(
                        option.value === "Dashboard"
                          ? dashboardModules[0]
                          : customModules[0]
                      );
                      setComponentType(
                        componentData[
                          option.value === "Dashboard"
                            ? dashboardModules[0].value
                            : customModules[0].value
                        ][0]
                      );
                    }}
                  />
                </div>
                <div className="w-100 mx-auto d-flex justify-content-center mt-4">
                  <div className="w-100">
                    <label className="opacity-75" htmlFor="massValue">
                      Select Module
                    </label>
                    <div
                      className="position-relative w-100 border rounded-1"
                      // style={{ border: "1px solid rgba(185, 185, 185, 0.5)" }}
                    >
                      <Select
                        menuPlacement="auto"
                        value={moduleType}
                        options={
                          getFormType.value === "Dashboard"
                            ? dashboardModules
                            : customModules
                        }
                        onChange={(option) => {
                          setModuleType(option);
                          setComponentType(componentData[option.value][0]);
                          setComponentName("");
                        }}
                        isSearchable={false}
                      />
                    </div>
                  </div>
                </div>
                <div className="w-100 mx-auto d-flex justify-content-center mt-4">
                  <div className="w-100">
                    <label className="opacity-75" htmlFor="massValue">
                      Custom View
                    </label>
                    <div className="position-relative w-100 border rounded-1">
                      <Select
                        menuPlacement="auto"
                        value={componentType}
                        options={componentData[moduleType.value]}
                        onChange={(option) => {
                          setComponentType(option);
                          setComponentName("");
                        }}
                        isSearchable={false}
                      />
                    </div>
                  </div>
                </div>
                <div className="w-100 mx-auto d-flex justify-content-center mt-4">
                  <div className="w-100">
                    <label className="opacity-75" htmlFor="massValue">
                      Component Name <span className="text-danger">*</span>
                    </label>
                    <div className="position-relative w-100 border rounded-1">
                      {/* <Select menuPlacement="auto" /> */}
                      <input
                        className="w-100 border-0 py-2 rounded-1 ps-2"
                        type="text"
                        value={componentName}
                        onChange={(e) => {
                          setComponentName(e.target.value);
                        }}
                        style={{ outline: "none" }}
                        id="componentName"
                        maxLength={30}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className={`pt-4 `}>
            <div className="d-flex justify-content-end w-100 gap-4 pe-4">
              <button
                className="cancelButton px-4"
                onClick={() => {
                  setModalType("");
                }}
              >
                Cancel
              </button>
              <button
                className={`createContactButtonSave ${
                  componentName?.length < 2 ? "opacity-50" : ""
                }`}
                disabled={componentName?.length < 2 || apiLoader == "add"}
                onClick={() => {
                  addDashboardData();
                }}
              >
                {apiLoader === "add" ? (
                  <Lottie
                    options={defaultOptions("white")}
                    height="24px"
                    width="36px"
                  />
                ) : (
                  "Save"
                )}
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={modalType === "delete"}
        aria-labelledby="contained-modal-title-vcenter"
        onClick={(e) => e.stopPropagation()}
        // size={showModalType !== "delete" ? "md" : "lg"}
      >
        <Modal.Header
          className=" border-0 mb-0 d-flex flex-column align-items-start"
          style={{ backgroundColor: "#F2F2F2" }}
        >
          <div className="fs-5" style={{ color: "#3F7DFF" }}>
            Are you sure you want to delete{" "}
            {dashboardData.length > 0
              ? convertSnakeCaseToReadableString(
                  dashboardData.find(
                    (item) => item.componentId === selectedComponentId
                  )?.componentName
                )
              : ""}
            ?
          </div>
        </Modal.Header>
        <Modal.Body className="ModalBody">
          <div className="d-flex gap-2 pb-2 align-items-center">
            <span className="fw-bold">Note:</span>
            <span className="small pt-1">
              This action can&apos;t be undone.
            </span>
          </div>

          <div className={`pt-4 pe-3`}>
            <div className="d-flex justify-content-end w-100 gap-4 pe-2">
              <button
                className="cancelButton px-4"
                onClick={() => {
                  setModalType("");
                }}
              >
                Cancel
              </button>
              <button
                className="createContactButtonSave"
                onClick={() => {
                  deleteDashboardData();
                }}
                disabled={apiLoader === "delete"}
              >
                {apiLoader === "delete" ? (
                  <Lottie
                    options={defaultOptions("white")}
                    height="24px"
                    width="36px"
                  />
                ) : (
                  "Delete"
                )}
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <NotificationContainer />
    </>
  );
}

export default InitialHome;

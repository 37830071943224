import React from "react";
import cancelIcon from "../Assets/Group 72.svg";
import updateIcon from "../Assets/Group 71.svg";
import { getValue } from "./CommonComponent";

const EditableInput = ({
  fieldType,
  inputType,
  handleIndividualInputChange,
  handleEditClick,
  handleCancelClick,
  handleUpdateClick,
  updatedFields,
  accountOverviewDetails,
  editingFields,
  showType,
  setShowType,
  isTextarea,
}) => {
  const value = editingFields[inputType]
    ? fieldType === "date"
      ? updatedFields[inputType]?.split("-").reverse().join("-")
      : updatedFields[inputType] || ""
    : fieldType === "date"
    ? accountOverviewDetails[inputType]?.split("-").reverse().join("-")
    : getValue(inputType, accountOverviewDetails[inputType]) || "";

  const handleInputChange = (e) => {
    if (showType === inputType) {
      handleIndividualInputChange(
        inputType,
        fieldType === "date"
          ? e.target.value.split("-").reverse().join("-")
          : e.target.value
      );
    }
  };

  const handleClick = (e) => {
    if (showType === "") {
      setShowType(e.target.name);
    }
    handleEditClick(inputType);
  };

  return (
    <div className="d-flex editInput_container ">
      {isTextarea ? (
        <textarea
          rows="4"
          cols="50"
          maxLength="1000"
          style={{
            width: "100%",
            border: "1px solid #B9B9B94D",
            height: "100px",
            outline: "none",
            borderRadius: "10px",
            backgroundColor: "transparent",
            padding: "8px 12px",
            resize: "none",
          }}
          value={value}
          name={inputType}
          onChange={handleInputChange}
          onClick={handleClick}
          readOnly={!editingFields[inputType] || showType !== inputType}
        />
      ) : (
        <input
          type={fieldType === "date" ? "date" : "text"}
          className={`cursorPointer indvEditInput rounded-1 w-100 ${
            showType === inputType
              ? "selectedBorder "
              : "unselectBorder bg-transparent"
          }`}
          value={value}
          name={inputType}
          onChange={handleInputChange}
          onClick={handleClick}
          onKeyDown={
            fieldType === "date" ? (e) => e.preventDefault() : undefined
          }
          readOnly={!editingFields[inputType] || showType !== inputType}
          min="2000-01-01"
          max="2050-12-31"
        />
      )}

      {editingFields[inputType] && showType === inputType && (
        <div className="d-flex gap-2">
          <span
            onClick={() => {
              setShowType("");
              handleUpdateClick(inputType);
            }}
            className="ps-2 cursorPointer"
          >
            <img src={updateIcon} alt="img" height={20} width={20} />
          </span>
          <span
            onClick={() => {
              setShowType("");
              handleCancelClick(inputType);
            }}
            className="cursorPointer"
          >
            <img src={cancelIcon} alt="img" height={20} width={20} />
          </span>
        </div>
      )}
    </div>
  );
};

export default EditableInput;

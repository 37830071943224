import React, { useRef, useEffect, useState } from "react";
import CanvasJSReact from "@canvasjs/react-charts";
import { camelCaseToSpace } from "../../../common/CommonComponent";

const CanvasJSChart = CanvasJSReact.CanvasJSChart;

const MultiLineChart = ({ data }) => {
  const headings = data?.length > 0 ? Object.keys(data[0]) : [];

  const chartRef = useRef(null);
  const chartContainerRef = useRef(null);
  // const [containerWidth, setContainerWidth] = useState(0); containerWidth is not used anywhere so i am commenting this
  const [containerHeight, setContainerHeight] = useState(400);

  const [chartOptions, setChartOptions] = useState({});

  useEffect(() => {
    setChartOptions({
      theme: "light2",
      animationEnabled: true,
      axisX: {
        title: camelCaseToSpace(
          ["taskCount", "dealsAmount"].includes(headings[0])
            ? headings[1]
            : headings[0]
        ),
      },
      axisY: {
        title: camelCaseToSpace(
          ["taskCount", "dealsAmount"].includes(headings[0])
            ? headings[0]
            : headings[1]
        ),
        titleFontColor: "#2177fe",
        lineColor: "#2177fe",
        labelFontColor: "#2177fe",
        tickColor: "#2177fe",
        // titleFontColor: "#6D78AD",
        // lineColor: "#6D78AD",
        // labelFontColor: "#6D78AD",
        // tickColor: "#6D78AD",
      },
      toolTip: {
        shared: true,
      },
      legend: {
        cursor: "pointer",
        itemclick: (e) => {
          if (
            typeof e.dataSeries.visible === "undefined" ||
            e.dataSeries.visible
          ) {
            e.dataSeries.visible = false;
          } else {
            e.dataSeries.visible = true;
          }
          chartRef.current.render();
        },
      },
      data: [
        // data: data || [
        {
          type: "spline",
          name: camelCaseToSpace(
            ["taskCount", "dealsAmount"].includes(headings[0])
              ? headings[0]
              : headings[1]
          ),
          showInLegend: true,
          color: "#2177fe",
          xValueFormatString: "DD MMM YYYY",
          yValueFormatString: "#,##0 ",
          dataPoints: data?.map((ele) => ({
            x: new Date(
              ele[
                headings[
                  ["taskCount", "dealsAmount"].includes(headings[0]) ? 1 : 0
                ]
              ]
            ),
            y: ele[
              headings[
                ["taskCount", "dealsAmount"].includes(headings[0]) ? 0 : 1
              ]
            ],
          })),
        },
      ],
    });

    const handleResize = () => {
      if (chartContainerRef.current) {
        // setContainerWidth(chartContainerRef.current.offsetWidth);
        setContainerHeight(chartContainerRef.current.offsetHeight);
      }
    };

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [data]);

  return (
    // <div style={{ height: "10vh", width: "100%" }} className="multiline_chart">
    //   <CanvasJSChart options={options} />
    // </div>

    // <div
    //   ref={chartContainerRef}
    //   style={{ height: height || "98%", width: width || "100%" }}
    // >
    //   <CanvasJSChart
    //     options={chartOptions}
    //     onRef={(ref) => (chartRef.current = ref)}
    //   />
    // </div>

    <div ref={chartContainerRef} style={{ width: "100%" }} className="w-100">
      <CanvasJSChart
        onRef={(ref) => (chartRef.current = ref)}
        options={chartOptions}
        containerProps={{
          //   width: `${containerWidth}px`,
          height: `${containerHeight - 100}px `,
        }}
      />
    </div>
  );
};

export default MultiLineChart;

export const leadSourceOptions = [
  { value: "None", label: "None" },
  { value: "Advertisement", label: "Advertisement" },
  { value: "Cold Call", label: "Cold Call" },
  { value: "Employee Referral", label: "Employee Referral" },
  { value: "External Referral", label: "External Referral" },
  { value: "Online Store", label: "Online Store" },
  { value: "Partner", label: "Partner" },
  { value: "Public Relations", label: "Public Relations" },
  { value: "Sales Email Alias", label: "Sales Email Alias" },
  { value: "Seminar Partner", label: "Seminar Partner" },
  { value: "Internal Seminar", label: "Internal Seminar" },
  { value: "Trade Show", label: "Trade Show" },
  { value: "Web Download", label: "Web Download" },
  { value: "Web Research", label: "Web Research" },
  { value: "Web Cases", label: "Web Cases" },
  { value: "Web Mail", label: "Web Mail" },
  { value: "Chat", label: "Chat" },
  { value: "Twitter", label: "Twitter" },
  { value: "Facebook", label: "Facebook" },
  { value: "Google+", label: "Google+" },
];

export const leadStatusOptions = [
  { value: "None", label: "None" },
  { value: "Attempted to Contact", label: "Attempted to Contact" },
  { value: "Contact in Future", label: "Contact in Future" },
  { value: "Contacted", label: "Contacted" },
  { value: "Junk Lead", label: "Junk Lead" },
  { value: "Lost Lead", label: "Lost Lead" },
  { value: "Not Contacted", label: "Not Contacted" },
  { value: "Pre-Qualified", label: "Pre-Qualified" },
  { value: "Not Qualified", label: "Not Qualified" },
];

export const glAccountOptions = [
  { value: "Sales-Software", label: "Sales-Software" },
  { value: "Sales-Hardware", label: "Sales-Hardware" },
  { value: "Rental Income", label: "Rental Income" },
  { value: "Interest Income", label: "Interest Income" },
  { value: "Sales Software Support", label: "Sales Software Support" },
  { value: "Sales Other", label: "Sales Other" },
  { value: "Interest Sales", label: "Interest Sales" },
  { value: "Labor Hardware Service", label: "Labor Hardware Service" },
];

export const industryOptions = [
  { value: "None", label: "None" },
  {
    value: "ASP (Application Service Provider)",
    label: "ASP (Application Service Provider)",
  },
  { value: "Data/Telecom OEM", label: "Data/Telecom OEM" },
  {
    value: "ERP (Enterprise Resource Planning)",
    label: "ERP (Enterprise Resource Planning)",
  },
  { value: "Government/Military", label: "Government/Military" },
  { value: "Large Enterprise", label: "Large Enterprise" },
  { value: "ManagemantISV", label: "ManagemantISV" },
  {
    value: "MSP (Management Service Provider)",
    label: "MSP (Management Service Provider)",
  },
  {
    value: "Network Equipment Enterprise",
    label: "Network Equipment Enterprise",
  },
  { value: "Non-management ISV", label: "Non-management ISV" },
  { value: "Optical Networking", label: "Optical Networking" },
  { value: "Service Provider", label: "Service Provider" },
  { value: "Small/Medium Enterprise", label: "Small/Medium Enterprise" },
  { value: "Storage Euipment", label: "Storage Euipment" },
  { value: "Storage Service Provider", label: "Storage Service Provider" },
  { value: "Systems Integrator", label: "Systems Integrator" },
  { value: "Wireless Industry", label: "Wireless Industry" },
];

export const ratingOptions = [
  { value: "None", label: "None" },
  { value: "Acquired", label: "Acquired" },
  { value: "Active", label: "Active" },
  { value: "Market Failed", label: "Market Failed" },
  { value: "Project Cancel", label: "Project Cancel" },
  { value: "Shutdown", label: "Shutdown" },
];

export const accountTypeOptions = [
  { value: "None", label: "None" },
  { value: "Analyst", label: "Analyst" },
  { value: "Competitor", label: "Competitor" },
  { value: "Customer", label: "Customer" },
  { value: "Distributor", label: "Distributor" },
  { value: "Integrator", label: "Integrator" },
  { value: "Investor", label: "Investor" },
  { value: "Partner", label: "Partner" },
  { value: "Press", label: "Press" },
  { value: "Prospect", label: "Prospect" },
  { value: "Reseller", label: "Reseller" },
  { value: "Supplier", label: "Supplier" },
  { value: "Vendor", label: "Vendor" },
  { value: "Other", label: "Other" },
];
export const ownershipOptions = [
  { value: "None", label: "None" },
  { value: "Private", label: "Private" },
  { value: "Public", label: "Public" },
  { value: "Subsidiary", label: "Subsidiary" },
  { value: "Other", label: "Other" },
];

export const stageOptions = [
  { value: "Qualification", label: "Qualification" },
  { value: "Needs Analysis", label: "Needs Analysis" },
  { value: "Value Proposition", label: "Value Proposition" },
  { value: "Identify Decision Makers", label: "Identify Decision Makers" },
  { value: "Proposal/Price Quote", label: "Proposal/Price Quote" },
  { value: "Negotiation/Review", label: "Negotiation/Review" },
  { value: "Closed Won", label: "Closed Won" },
  { value: "Closed Lost", label: "Closed Lost" },
  { value: "Closed Lost to Competition", label: "Closed Lost to Competition" },
];

export const contactOption = [
  "Contact Owner",
  "Email",
  "Phone",
  "Mobile",
  "Department",
];
export const pageOption = [
  { value: "10", label: "10 Records Per Page" },
  { value: "20", label: "20 Records Per Page" },
  { value: "30", label: "30 Records Per Page" },
  { value: "40", label: "40 Records Per Page" },
  { value: "50", label: "50 Records Per Page" },
];

export const manufacturerOptions = [
  { value: "None", label: "None" },
  { value: "AltvetPet Inc.", label: "AltvetPet Inc." },
  { value: "lexPon Inc.", label: "lexPon Inc." },
  { value: "MetBeat Corp.", label: "MetBeat Corp." },
];
export const productCategoryOptions = [
  { value: "None", label: "None" },
  { value: "Hardware", label: "Hardware" },
  { value: "Software", label: "Software" },
  { value: "CRM Applications", label: "CRM Applications" },
];

export const massOptions = [
  { value: "Mass Transfer", label: "Mass Transfer" },
  { value: "Mass Delete", label: "Mass Delete" },
  { value: "Mass Update", label: "Mass Update" },
];

export const taxOptions = [
  {
    label: "GST - 0%",
    value: 0.0,
  },
  {
    label: "GST - 5%",
    value: 5.0,
  },
  {
    label: "GST - 12%",
    value: 12.0,
  },
  {
    label: "GST - 18%",
    value: 18.0,
  },
  {
    label: "GST - 28%",
    value: 28.0,
  },
];

export const customSelectLocationStylesPage = {
  menu: (base) => ({
    ...base,
    width: "100%",
  }),
  option: (provided) => ({
    ...provided,
    // backgroundColor: "#EAEAEA",
    color: "#000",
    // backgroundColor: 'rgba(33, 119, 254, 0.1)',
    width: "100%",
  }),
  control: (baseStyles) => ({
    ...baseStyles,
    border: "1px solid #b9b9b9b0",
    backgroundColor: "#F3F6FB",
    height: "40px",
    borderRadius: "10px",
    width: "100%",
    boxShadow: "none",
  }),
  input: (base) => ({
    ...base,
    color: "#FFFFFF",
  }),
  singleValue: (defaultStyles) => ({ ...defaultStyles, color: "#000" }),

  menuList: (provided) => ({
    ...provided,
    paddingTop: 0,
    paddingBottom: 0,
    "&::-webkit-scrollbar": {
      width: 5,
    },

    "&::-webkit-scrollbar-thumb": {
      background: "#316AFF",
      borderRadius: "10px",
    },
  }),
};

export const fieldDropdownStyles = {
  menu: (base) => ({
    ...base,
    width: "100%",
  }),
  option: (provided) => ({
    ...provided,
    // backgroundColor: "#EAEAEA",
    color: "#000",
    // backgroundColor: 'rgba(33, 119, 254, 0.1)',
    width: "100%",
  }),
  control: (baseStyles) => ({
    ...baseStyles,
    border: 0,
    // backgroundColor: 'rgba(33, 119, 254, 0.1)',
    height: "20px",
    borderRadius: "10px",
    width: "90%",
    boxShadow: "none",
  }),
  input: (base) => ({
    ...base,
    color: "#FFFFFF",
  }),
  singleValue: (defaultStyles) => ({ ...defaultStyles, color: "#000" }),

  menuList: (provided) => ({
    ...provided,
    paddingTop: 0,
    paddingBottom: 0,
    "&::-webkit-scrollbar": {
      width: 5,
    },

    "&::-webkit-scrollbar-thumb": {
      background: "#316AFF",
      borderRadius: "10px",
    },
  }),
};

export function camelCaseToSpace(string) {
  // Use regex to insert a space before all capital letters
  return string.replace(/([A-Z])/g, " $1").toUpperCase();
  // Replace the first character with its uppercase version
  // .replace(/^./, function (str) {
  //   return str.toUpperCase();
  // })
}

export const salesColumns = [
  {
    name: "Sales Order Owner",
    isChecked: true,
    value: "salesOrderOwner",
  },
  {
    name: "Subject",
    isChecked: true,
    value: "subject",
  },
  {
    name: "Status",
    isChecked: true,
    value: "status",
  },
  {
    name: "Grand Total",
    isChecked: true,
    value: "grandTotal",
  },
  {
    name: "Deal Name",
    isChecked: true,
    value: "dealName",
  },
  {
    name: "Purchase Order",
    isChecked: false,
    value: "purchaseOrder",
  },
  {
    name: "Shipping City",
    isChecked: false,
    value: "shippingCity",
  },
  {
    name: "Shipping Code",
    isChecked: false,
    value: "shippingCode",
  },
  {
    name: "Shipping Street",
    isChecked: false,
    value: "shippingStreet",
  },
  {
    name: "Shipping Country",
    isChecked: false,
    value: "shippingCountry",
  },
  {
    name: "Billing Country",
    isChecked: false,
    value: "billingCountry",
  },
  {
    name: "Excise Duty",
    isChecked: false,
    value: "exciseDuty",
  },
  {
    name: "Billing Code",
    isChecked: false,
    value: "billingCode",
  },
  {
    name: "Contact Name",
    isChecked: true,
    value: "contactName",
  },
  {
    name: "Billing Street",
    isChecked: false,
    value: "billingStreet",
  },
  {
    name: "Billing State",
    isChecked: false,
    value: "billingState",
  },
  {
    name: "Account Name",
    isChecked: false,
    value: "accountName",
  },
  {
    name: "Carrier",
    isChecked: false,
    value: "carrier",
  },
  {
    name: "Shipping State",
    isChecked: false,
    value: "shippingState",
  },
  {
    name: "Billing City",
    isChecked: false,
    value: "billingCity",
  },
  {
    name: "Due Date",
    isChecked: false,
    value: "dueDate",
  },
];

export const invoiceColumns = [
  {
    name: "Subject",
    isChecked: true,
    value: "subject",
  },
  {
    name: "Shipping City",
    isChecked: false,
    value: "shippingCity",
  },
  {
    name: "Shipping Code",
    isChecked: false,
    value: "shippingCode",
  },
  {
    name: "Grand Total",
    isChecked: true,
    value: "grandTotal",
  },
  {
    name: "Account Name",
    isChecked: true,
    value: "accountName",
  },
  {
    name: "Contact Name",
    isChecked: true,
    value: "contactName",
  },
  {
    name: "Invoice Number",
    isChecked: false,
    value: "invoiceNumber",
  },
  {
    name: "Shipping Street",
    isChecked: false,
    value: "shippingStreet",
  },
  {
    name: "Due Date",
    isChecked: false,
    value: "dueDate",
  },
  {
    name: "Sales Commision",
    isChecked: false,
    value: "salesCommision",
  },
  {
    name: "Billing State",
    isChecked: false,
    value: "billingState",
  },
  {
    name: "Invoice Date",
    isChecked: true,
    value: "invoiceDate",
  },
  {
    name: "Deal Name",
    isChecked: false,
    value: "dealName",
  },
  {
    name: "Billing Code",
    isChecked: false,
    value: "billingCode",
  },
  {
    name: "Invoice Owner",
    isChecked: false,
    value: "invoiceOwner",
  },
  {
    name: "Billing Streete",
    isChecked: false,
    value: "billingStreet",
  },
  {
    name: "Shipping Country",
    isChecked: false,
    value: "shippingCountry",
  },
  {
    name: "Purchase Order",
    isChecked: false,
    value: "purchaseOrder",
  },
  {
    name: "Billing Country",
    isChecked: false,
    value: "billingCountry",
  },
  {
    name: "Shipping State",
    isChecked: false,
    value: "shippingState",
  },
  {
    name: "Excise Duty",
    isChecked: false,
    value: "exciseDuty",
  },
  {
    name: "Billing City",
    isChecked: false,
    value: "billingCity",
  },
  {
    name: "Status",
    isChecked: false,
    value: "status",
  },
];

export const contactColumn = [
  {
    name: "Contact Name",
    isChecked: true,
    value: "contactName",
  },
  {
    name: "Assistant",
    isChecked: true,
    value: "assistant",
  },
  {
    name: "Assistant Phone",
    isChecked: true,
    value: "assistantPhone",
  },
  {
    name: "Description",
    isChecked: false,
    value: "description",
  },
  {
    name: "Account Name",
    isChecked: true,
    value: "accountName",
  },
  {
    name: "Email",
    isChecked: true,
    value: "emailId",
  },
  {
    name: "Phone",
    isChecked: false,
    value: "phone",
  },
  {
    name: "Contact Owner",
    isChecked: false,
    value: "contactOwner",
  },
  {
    name: "Department",
    isChecked: false,
    value: "department",
  },
  {
    name: "First Name",
    isChecked: false,
    value: "firstName",
  },
  {
    name: "Last Name",
    isChecked: false,
    value: "lastName",
  },
  {
    name: "Fax",
    isChecked: false,
    value: "fax",
  },
  {
    name: "Mobile",
    isChecked: false,
    value: "mobile",
  },
  {
    name: "Lead Source",
    isChecked: false,
    value: "leadSource",
  },
  {
    name: "Secondary Mail",
    isChecked: false,
    value: "secondaryEmailId",
  },
  {
    name: "Created By",
    isChecked: false,
    value: "createdBy",
  },
  {
    name: "Modified By",
    isChecked: false,
    value: "modifiedBy",
  },
  {
    name: "Skype ID",
    isChecked: false,
    value: "skypeId",
  },
  {
    name: "Twitter",
    isChecked: false,
    value: "twitter",
  },
  {
    name: "Mailing Country",
    isChecked: false,
    value: "mailingCountry",
  },
  {
    name: "Mailing State",
    isChecked: false,
    value: "mailingState",
  },
  {
    name: "Mailing City",
    isChecked: false,
    value: "mailingCity",
  },
  {
    name: "Mailing street",
    isChecked: false,
    value: "mailingStreet",
  },
  {
    name: "Mailing Code",
    isChecked: false,
    value: "mailingZip",
  },
  {
    name: "Other Country",
    isChecked: false,
    value: "otherCountry",
  },
  {
    name: "Other State",
    isChecked: false,
    value: "otherState",
  },
  {
    name: "Other City",
    isChecked: false,
    value: "otherCity",
  },
  {
    name: "Other Street",
    isChecked: false,
    value: "otherStreet",
  },
  {
    name: "Other Code",
    isChecked: false,
    value: "otherZip",
  },
];

export const dealsColumn = [
  {
    name: "Deal Name",
    isChecked: true,
    value: "dealName",
  },
  {
    name: "Amount",
    isChecked: true,
    value: "amount",
  },
  {
    name: "Stage",
    isChecked: true,
    value: "stage",
  },
  {
    name: "Closing Date",
    isChecked: true,
    value: "closingDate",
  },
  {
    name: "Account Name",
    isChecked: true,
    value: "accountName",
  },
  {
    name: "Contact Name",
    isChecked: true,
    value: "contactName",
  },
  {
    name: "Probability",
    isChecked: false,
    value: "probability",
  },
  {
    name: "Type",
    isChecked: false,
    value: "type",
  },
  {
    name: "Next Step",
    isChecked: false,
    value: "nextStep",
  },
  {
    name: "Lead Source",
    isChecked: false,
    value: "leadSource",
  },
  {
    name: "Expected Revenue",
    isChecked: false,
    value: "expectedRevenue",
  },
  {
    name: "Description",
    isChecked: false,
    value: "description",
  },
  {
    name: "Campaign Source",
    isChecked: false,
    value: "campaignSource",
  },
  {
    name: "Modified By",
    isChecked: false,
    value: "modifiedBy",
  },
  {
    name: "Deal Owner",
    isChecked: true,
    value: "dealOwner",
  },
];

export const leadsColumn = [
  {
    name: "Lead Name",
    isChecked: true,
    value: "leadName",
  },
  {
    name: "Company",
    isChecked: true,
    value: "companyName",
  },
  {
    name: "Email",
    isChecked: true,
    value: "emailId",
  },
  {
    name: "Phone Number",
    isChecked: true,
    value: "phoneNumber",
  },
  {
    name: "Lead Source",
    isChecked: false,
    value: "leadSource",
  },
  {
    name: "Lead Owner",
    isChecked: true,
    value: "leadOwner",
  },
  {
    name: "First Name",
    isChecked: false,
    value: "firstName",
  },
  {
    name: "Last Name",
    isChecked: false,
    value: "lastName",
  },
  {
    name: "Fax",
    isChecked: false,
    value: "fax",
  },
  {
    name: "Mobile",
    isChecked: false,
    value: "mobile",
  },
  {
    name: "Title",
    isChecked: false,
    value: "title",
  },
  {
    name: "Website",
    isChecked: false,
    value: "website",
  },
  {
    name: "Lead Status",
    isChecked: false,
    value: "leadStatus",
  },
  {
    name: "Industry",
    isChecked: false,
    value: "industryType",
  },
  {
    name: "No. of Employees",
    isChecked: false,
    value: "noOfEmployees",
  },
  {
    name: "Annual Revenue",
    isChecked: false,
    value: "annualRevenue",
  },
  {
    name: "Rating",
    isChecked: false,
    value: "rating",
  },
  {
    name: "Secondary Mail",
    isChecked: false,
    value: "secondaryEmailId",
  },
  {
    name: "Created By",
    isChecked: false,
    value: "createdBy",
  },
  {
    name: "Modified By",
    isChecked: false,
    value: "modifiedBy",
  },
  {
    name: "Skype ID",
    isChecked: false,
    value: "skypeId",
  },
  {
    name: "Twitter",
    isChecked: false,
    value: "twitter",
  },
  {
    name: "Country",
    isChecked: false,
    value: "country",
  },
  {
    name: "State",
    isChecked: false,
    value: "state",
  },
  {
    name: "City",
    isChecked: false,
    value: "city",
  },
  {
    name: "Street",
    isChecked: false,
    value: "street",
  },
  {
    name: "Zip Code",
    isChecked: false,
    value: "zipCode",
  },
];

export const purchaseColumns = [
  {
    name: "Subject",
    isChecked: true,
    value: "subject",
  },
  {
    name: "Shipping City",
    isChecked: false,
    value: "shippingCity",
  },
  {
    name: "Shipping Code",
    isChecked: false,
    value: "shippingCode",
  },
  {
    name: "Discount",
    isChecked: false,
    value: "discount",
  },
  {
    name: "Sub Total",
    isChecked: true,
    value: "subTotal",
  },
  {
    name: "Po Date",
    isChecked: true,
    value: "poDate",
  },
  {
    name: "Shipping Country",
    isChecked: false,
    value: "shippingCountry",
  },
  {
    name: "Billing Country",
    isChecked: false,
    value: "billingCountry",
  },
  {
    name: "Excise Duty",
    isChecked: false,
    value: "exciseDuty",
  },
  {
    name: "Tracking Number",
    isChecked: true,
    value: "trackingNumber",
  },
  {
    name: "Sales Commission",
    isChecked: false,
    value: "salesCommission",
  },
  {
    name: "Requisition Number",
    isChecked: false,
    value: "requisitionNumber",
  },
  {
    name: "Grand Total",
    isChecked: false,
    value: "grandTotal",
  },
  {
    name: "Contact Name",
    isChecked: true,
    value: "contactName",
  },
  {
    name: "Tax",
    isChecked: false,
    value: "tax",
  },
  {
    name: "Billing State",
    isChecked: false,
    value: "billingState",
  },
  {
    name: "Vendor Name",
    isChecked: false,
    value: "vendorName",
  },
  {
    name: "Carrier",
    isChecked: false,
    value: "carrier",
  },
  {
    name: "Purchase Order Owner",
    isChecked: false,
    value: "purchaseOwner",
  },
  {
    name: "Shipping State",
    isChecked: false,
    value: "shippingState",
  },
  {
    name: "Po Number",
    isChecked: false,
    value: "poNumber",
  },
  {
    name: "Billing City",
    isChecked: false,
    value: "billingCity",
  },
  {
    name: "Status",
    isChecked: true,
    value: "status",
  },
  {
    name: "Due Date",
    isChecked: false,
    value: "dueDate",
  },
];

export const getFromOptions = [
  { value: "Dashboard", label: "Dashboard" },
  { value: "Custom View", label: "Custom View" },
];

export const dashboardModules = [
  { value: "org_overview", label: "Org Overview" },
  { value: "lead_analytics", label: "Lead Analytics" },
  { value: "deal_insights", label: "Deal Insights" },
  { value: "marketing_metrics", label: "Marketing Metrics" },
  // { value: "Activity Stats", label: "Activity Stats" },
];

export const customModules = [
  { value: "leads", label: "Leads" },
  { value: "contacts", label: "Contacts" },
  { value: "accounts", label: "Accounts" },
  { value: "deals", label: "Deals" },
  { value: "products", label: "Products" },
  { value: "tasks", label: "Tasks" },
  { value: "vendors", label: "Vendors" },
  { value: "purchases", label: "Purchase Orders" },
  { value: "sales", label: "Sales Orders" },
  { value: "invoices", label: "Invoices" },
  { value: "quotes", label: "Quotes" },
  { value: "calls", label: "Calls" },
  { value: "meetings", label: "Meetings" },
];

export const accountColumn = [
  {
    name: "Shipping City",
    isChecked: false,
    value: "shippingCity",
  },
  {
    name: "File Name",
    isChecked: false,
    value: "fileName",
  },
  {
    name: "Shipping Code",
    isChecked: false,
    value: "shippingCode",
  },
  {
    name: "Account Name",
    isChecked: true,
    value: "accountName",
  },
  {
    name: "Shipping Street",
    isChecked: false,
    value: "shippingStreet",
  },
  {
    name: "Rating",
    isChecked: false,
    value: "rating",
  },
  {
    name: "Description",
    isChecked: false,
    value: "description",
  },
  {
    name: "Industry",
    isChecked: false,
    value: "industry",
  },
  {
    name: "Account Site",
    isChecked: false,
    value: "accountSite",
  },
  {
    name: "Parent Account ID",
    isChecked: false,
    value: "parentAccountId",
  },
  {
    name: "Shipping Country",
    isChecked: false,
    value: "shippingCountry",
  },
  {
    name: "Billing Country",
    isChecked: false,
    value: "billingCountry",
  },
  {
    name: "Modified By",
    isChecked: false,
    value: "modifiedBy",
  },
  {
    name: "Fax",
    isChecked: false,
    value: "fax",
  },
  {
    name: "Lead ID",
    isChecked: false,
    value: "leadId",
  },
  {
    name: "Website",
    isChecked: true,
    value: "website",
  },
  {
    name: "Account Type",
    isChecked: false,
    value: "accountType",
  },
  {
    name: "Ticker Symbol",
    isChecked: false,
    value: "tickerSymbol",
  },
  {
    name: "Account Owner",
    isChecked: true,
    value: "accountOwner",
  },
  {
    name: "Billing State",
    isChecked: false,
    value: "billingState",
  },
  {
    name: "Account Number",
    isChecked: false,
    value: "accountNumber",
  },
  {
    name: "Billing Code",
    isChecked: false,
    value: "billingCode",
  },
  {
    name: "Ownership",
    isChecked: false,
    value: "ownership",
  },
  {
    name: "Phone",
    isChecked: true,
    value: "phone",
  },
  {
    name: "Created By",
    isChecked: false,
    value: "createdBy",
  },
  {
    name: "Billing Street",
    isChecked: false,
    value: "billingStreet",
  },
  {
    name: "SIC Code",
    isChecked: false,
    value: "sicCode",
  },
  {
    name: "Shipping State",
    isChecked: false,
    value: "shippingState",
  },
  {
    name: "Employees",
    isChecked: false,
    value: "employees",
  },
  {
    name: "Annual Revenue",
    isChecked: false,
    value: "annualRevenue",
  },
  {
    name: "Billing City",
    isChecked: false,
    value: "billingCity",
  },
];

export const handleKeyDown = (e) => {
  if (e.key === " ") {
    e.preventDefault();
  }
};

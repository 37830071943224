import React, { useState } from "react";
import { Dropdown } from "react-bootstrap";
import leadAvatar from "../../Assets/Lead_Avatar.png";
import Time from "../../Assets/time.svg";
import Delete from "../../Assets/DeleteNote.svg";
import Attach from "../../Assets/Attach.svg";
import NoteIcon from "../../Assets/noteIcon.svg";
import EyeIcon from "../../Assets/Attach.svg";
import DownloadIcon from "../../Assets/DownloadExport.svg";
import { useSelector } from "react-redux";
import AccessModal from "../../common/AccessModal";
import { Modal } from "react-bootstrap";
import { EXPLORER_URL } from "../../constants/DataConst.js";
import DeleteNote from "../../Assets/DeleteSocial.svg";
import Cancel from "../../Assets/CancelCircleModal.svg";
function Note({
  note,
  addTitle,
  setAddTitle,
  setNote,
  showButtons,
  setShowButtons,
  handleTextAreaClick,
  handleSaveButton,
  noteInfo,
  selectRecent,
  handleSelectRecent,
  handleDeleteClick,
  leadId,
  handleUpdateClick,
  updateNotes,
  setUpdateNotes,
  editNote,
  setEditNote,
  handleChangeNoteTitle,
  handleChangeNoteDescription,
  moduleType,
  recordName,
  setSelectedNoteFiles,
  selectedNoteFiles,
  onChildDataChange,
  noteDeleteConfirmation,
  setNoteDeleteConfirmation
}) {
  const [noteId, setNoteId] = useState(null);
  const [showAccessModal, setShowAccessModal] = useState(false);
  const reduxStore = useSelector((state) => state.reduxState);
  const [errorMessage, setErrorMessage] = useState("");
  const [showImageModal, setShowImageModal] = useState(false);
  const [currentImage, setCurrentImage] = useState(null);
  const [currentImageList, setCurrentImageList] = useState([]);
  const [defaultImageList, setDefaultImageList] = useState([]);
  const [currentImageArray, setCurrentImageArray] = useState([]);
  const [deletedImage, setDeletedImage] = useState([]);


  const { permissionList } = reduxStore;
/* eslint-disable-next-line no-console */
 console.log("moduleType",moduleType) 

  const handleDelete = (index) => {
    const currentImageList2 = currentImageList.filter(
      (item, idx) => idx !== index
    );
    const selectedImage = currentImageArray[index];
    // onChildDataChange.push(selectedImage)
    deletedImage.push(selectedImage);

    setDeletedImage(deletedImage);
    onChildDataChange(deletedImage);
    setCurrentImageList(currentImageList2);
  };

  const hasPermission = (action) => {
    const permission = permissionList.notes.find((p) => p.action === action);
    return permission ? permission.status === "TRUE" : false;
  };

  const handleCreateNote = () => {
    if (hasPermission("CREATE")) {
      setCurrentImageList([]);
      handleTextAreaClick();
    } else {
      setShowAccessModal(true);
    }
  };

  const handleEditNote = (item, index) => {
    if (hasPermission("EDIT")) {
      handleTextAreaClick();
      setUpdateNotes(noteInfo[index]);
      setCurrentImageArray(noteInfo[index].fileName?.split(","));
      setCurrentImageList(noteInfo[index].noteImageList);
      setDefaultImageList(noteInfo[index].noteImageList);
      setEditNote(true);
      setNoteId(item.noteId);
    } else {
      setShowAccessModal(true);
    }
  };

  const handleTextFileChange = (event) => {
    const files = Array.from(event.target.files);
    const validFileTypes = [
      "image/png",
      "image/jpg",
      "image/jpeg",
      "image/svg+xml",
      "application/pdf",
      "application/msword",
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    ];
    const maxSize = 2 * 1024 * 1024; // 2 MB
    let hasInvalidFile = false;
    let hasInvalidSize = false;

    files.forEach((file) => {
      if (!validFileTypes.includes(file.type)) {
        hasInvalidFile = true;
      }
      if (file.size > maxSize) {
        hasInvalidSize = true;
      }
    });

    if (hasInvalidFile || hasInvalidSize) {
      const errorMessages = [];
      if (hasInvalidFile)
        errorMessages.push(
          "Please select valid files (png, jpg, jpeg, svg, pdf, doc, docx)."
        );
      if (hasInvalidSize)
        errorMessages.push("File size must be less than 2 MB.");
      setErrorMessage(errorMessages.join(" "));
      setSelectedNoteFiles([]);
      setTimeout(() => {
        setErrorMessage("");
      }, 3000);
    } else {
      setErrorMessage("");
      setSelectedNoteFiles(files);
    }
  };

  const getTruncatedFileName = (fileName) => {
    const maxLength = 5;
    if (fileName.length > maxLength) {
      return `${fileName.slice(0, maxLength)}...`;
    }
    return fileName;
  };

  const handleImageClick = (imageData) => {
    setCurrentImage(imageData);
    setShowImageModal(true);
  };

  const validNonImageTypes = [
    "image/pdf",
    "image/msword",
    "image/vnd.openxmlformats-officedocument.wordprocessingml.document",
    "image/docx",
    "image/doc",
  ];

  const validImageTypes = [
    "image/png",
    "image/jpg",
    "image/jpeg",
    "image/svg+xml",  
    ];

  const isNonImageFile = (dataUrl) => {
    const mimeType = dataUrl.split(";")[0].split(":")[1];
    return validNonImageTypes.includes(mimeType);
  };

  const getFileExtension = (mimeType) => {
    switch (mimeType) {
      case "image/png":
        return "png";
      case "image/jpg":
        return "jpg";
      case "image/jpeg":
        return "jpeg";
      case "image/svg+xml":
        return "svg";
      case "image/pdf":
        return "pdf";
      case "image/msword":
        return "doc";
      case "image/vnd.openxmlformats-officedocument.wordprocessingml.document":
        return "docx";
      default:
        return "bin";
    }
  };

  const b64toBlob = (b64Data, contentType = "", sliceSize = 512) => {
    const byteCharacters = atob(b64Data.split(",")[1]);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);
      const byteNumbers = new Array(slice.length);

      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    return new Blob(byteArrays, { type: contentType });
  };

  const handleDownloadClick = (dataUrl, mimeType, fileName) => {
    const blob = b64toBlob(dataUrl, mimeType);

    const blobUrl = URL.createObjectURL(blob);

    const link = document.createElement("a");
    link.href = blobUrl;
    link.download = fileName || "file";

    document.body.appendChild(link);
    link.click();

    document.body.removeChild(link);

    URL.revokeObjectURL(blobUrl);
  };

  return (
    <div>
      <div className="d-flex justify-content-between ms-2 me-1">
        <div className="d-flex gap-2 align-items-center">
          <img src={NoteIcon} alt="note" />
          <div className="notesHeader">Notes</div>
        </div>
        <Dropdown>
          <Dropdown.Toggle id="dropdown-note">{selectRecent}</Dropdown.Toggle>
          <Dropdown.Menu>
            <Dropdown.Item onClick={() => handleSelectRecent("All")}>
              All
            </Dropdown.Item>
            <Dropdown.Item onClick={() => handleSelectRecent("Recent Last")}>
              Recent Last
            </Dropdown.Item>
            <Dropdown.Item onClick={() => handleSelectRecent("Recent First")}>
              Recent First
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </div>

      <div style={{ borderBottom: "1px solid #515151" }} className="mt-1"></div>

      <div className="ms-4">
        {noteInfo.length > 0 &&
          noteInfo?.map((item, index) => (
            <div key={index} className="mt-4">
              <div className="d-flex justify-content-between align-items-center">
                <div className="d-flex gap-3 align-items-center">
                  <div>
                    <img
                      src={leadAvatar}
                      alt="noteHeader"
                      width={40}
                      height={40}
                    />
                  </div>
                  <div className="overflow_text" style={{ maxWidth: "30vw" }}>
                    {item.title}
                  </div>
                  <div
                    style={{
                      color: "#515151",
                      fontWeight: "300",
                      fontSize: "14px",
                      wordBreak: "break-word",
                      padding: "5px",
                    }}
                  >
                    {item.description}
                  </div>
                </div>
                <div className="d-flex gap-3 me-4">
                  <img
                    src={Delete}
                    alt="time"
                    onClick={() => {
                      if (hasPermission("DELETE")) {
                        setNoteDeleteConfirmation(true);
                        setAddTitle("");
                        setNote("");
                       setEditNote(false);
                       setShowButtons(false)
                      } else {
                        setShowAccessModal(true);
                      }
                    }}
                    className="cursorPointer"
                  />
                  {noteDeleteConfirmation &&(
                    <div>
                      <Modal
        show={noteDeleteConfirmation}
        className="d-flex justify-content-center align-items-center"
        aria-labelledby="example-custom-modal-styling-title"
        size="lg"
        // id="commonLeadModal"
        // onHide={() => setShowImageModal(false)}
      >
        <Modal.Header>
        <div className="d-flex gap-4 justify-content-between w-100">
                    <div
                      style={{
                        fontSize: "22px",
                        fontWeight: "bold",
                        background:
                          "linear-gradient(to right, rgba(64, 123, 255, 1), rgba(18, 167, 254, 1))",
                        WebkitBackgroundClip: "text",
                        backgroundClip: "text",
                        WebkitTextFillColor: "transparent",
                      }}
                    >
                     Delete Note
                    </div>
                    <img
                      src={Cancel}
                      alt="cancel"
                      className="cursorPointer"
                      onClick={() => {
                        setNoteDeleteConfirmation(false);
                      }}
                    />
                  </div>
        </Modal.Header>
        <Modal.Body>
          <div>
          <div style={{fontSize:"18px"}}>Are you sure you want to delete this Note?</div>
          <div className="d-flex  gap-4 justify-content-end mt-4">
          <button className="cancelRecycle px-4 p-1" onClick={()=>setNoteDeleteConfirmation(false)}>Cancel</button>
            <button className="deleteButton px-4 p-1"onClick={()=>handleDeleteClick(leadId, item.noteId)}>Ok</button>
          </div>
          </div>
        </Modal.Body>
      </Modal>
                      </div>
                  )}
                  <button
                    className="editNoteButton"
                    onClick={() => handleEditNote(item, index)}
                  >
                    Edit
                  </button>
                </div>
              </div>

              <div className="d-flex flex-wrap gap-3 pt-1">
                {item.noteImageList?.map((image, index) => {
                  const mimeType = image.noteImageData
                    .split(";")[0]
                    .split(":")[1];
                  const fileExtension = getFileExtension(mimeType);
                  const fileName =
                    item.fileName.slice(0, 13) + `.${fileExtension}`;

                  return (
                    <div className="d-flex gap-3" key={index}>
                      <div className="d-flex flex-column imageContainer">
                        {isNonImageFile(image.noteImageData) ? (
                          <div className="fileName">
                            {item.fileName ? (
                              <td className="text-center">
                                <a
                                  rel="noreferrer"
                                  href={
                                    EXPLORER_URL +
                                    "about-fileName/" +
                                    encodeURIComponent(item.fileName)
                                  }
                                  className="anchorLinkHash textColor"
                                  data-title={item.fileName}
                                  target="_blank"
                                >
                                  {getTruncatedFileName(item.fileName)}
                                </a>
                              </td>
                            ) : (
                              <td className="text-right ms-4">
                                <span className="ps-3">-</span>
                              </td>
                            )}
                            <div className="imageOverlay">
                              <img
                                src={DownloadIcon}
                                alt="download"
                                className="icon"
                                onClick={() =>
                                  handleDownloadClick(
                                    image.noteImageData,
                                    mimeType,
                                    fileName
                                  )
                                }
                              />
                            </div>
                          </div>
                        ) : (
                          <>
                            <img
                              src={image.noteImageData}
                              alt="img1"
                              height={60}
                              width={60}
                              onClick={() =>
                                handleImageClick(image.noteImageData)
                              }
                              className="imageThumbnail"
                            />
                            <div className="imageOverlay">
                              <img
                                src={EyeIcon}
                                alt="view"
                                className="icon"
                                onClick={() =>
                                  handleImageClick(image.noteImageData)
                                }
                              />
                              <img
                                src={DownloadIcon}
                                alt="download"
                                className="icon"
                                onClick={() =>
                                  handleDownloadClick(
                                    image.noteImageData,
                                    mimeType,
                                    fileName
                                  )
                                }
                              />
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  );
                })}
              </div>
              <div className="d-flex gap-3 mt-1">
                <div>
                  {moduleType} -
                  <span style={{ color: "#2177FE", fontSize: "14px" }}>
                    {" "}
                    {recordName || ""}
                  </span>{" "}
                </div>
                <div style={{width:"20%",color: "#2177FE"}} className="overflow_text">
                  <span>{item.fileName}</span>
                  </div>
                <div className="d-flex gap-2">
                  <img src={Time} alt="time" />
                  <div> {item.createdTime}</div>
                </div>
              </div>
              <div
                style={{ borderBottom: "1px solid #515151" }}
                className="mt-2"
              ></div>
            </div>
          ))}
      </div>
      <div
        className="bg-white ms-4 me-4 mt-4"
        style={{ borderRadius: "8px", border: "1px solid #E7E9EE" }}
      >
        {showButtons && (
          <div
            className="d-flex flex-column justify-content-between  ms-2 me-2"
            style={{ width: "95%" }}
          >
            <input
              type="text"
              placeholder="Add Title"
              value={editNote ? updateNotes.title : addTitle}
              name="title"
              onChange={handleChangeNoteTitle}
              className="notesClass mt-2"
              style={{ width: "95%" }}
            />
          </div>
        )}
        <div
          style={{ border: "1px solid #DEDFDF" }}
          className="mt-1 ms-2 me-2"
        ></div>
        <div className="mt-2 ms-2 me-2">
          <textarea
            rows={2}
            className="notesClass"
            placeholder="Add a note"
            name="description"
            value={editNote ? updateNotes.description : note}
            onChange={handleChangeNoteDescription}
            onClick={handleCreateNote}
            style={{ width: "95%" }}
          />
        </div>
        <div style={{ border: "1px solid #DEDFDF" }} className="mt-1"></div>
        {showButtons && (
          <div className="d-flex justify-content-between align-items-center ms-4 me-4 mt-2 p-1">
            <div className="d-flex gap-2">
              <div className="d-flex gap-3 align-items-center">
                <div className="d-flex gap-3 flex-wrap">
                  {currentImageList &&
                    currentImageList.map((imgData, index) => {
                      const base64Header = imgData.noteImageData.split(",")[0];
                      const mimeType = base64Header.match(/:(.*?);/)[1];

                      return (
                        <div
                          className="image-container position-relative"
                          key={index}
                        >
                          {validImageTypes.includes(mimeType) ? (
                            <img
                              src={imgData.noteImageData}
                              alt="noteImage"
                              height={60}
                              width={60}
                            />
                          ) : (
                            <p
                              className="text-center mb-0"
                              style={{
                                display: "flex",
                                width: "60px",
                                height: "60px",
                                alignItems: "center",
                              }}
                            >
                              {currentImageArray[index].slice(0, 5) + "..."}
                            </p>
                          )}
                          <img
                            src={DeleteNote}
                            alt="Delete"
                            className={
                              validImageTypes.includes(mimeType)
                                ? "delete-note-icon cursorPointer"
                                : "delete-note-icon-true cursorPointer"
                            }
                            onClick={() => handleDelete(index)}
                          />
                        </div>
                      );
                    })}
                </div>
                <div>
                  <label htmlFor="Attachment" className="cursorPointer">
                    <img src={Attach} alt="Attach" width="30px" />
                  </label>
                  <input
                    type="file"
                    onChange={handleTextFileChange}
                    multiple
                    accept=".png, .jpg, .jpeg, .pdf, .doc, .docx"
                    id="Attachment"
                    style={{ display: "none" }}
                  />
                </div>
                {errorMessage && (
                  <div style={{ color: "red" }}>{errorMessage}</div>
                )}

                <div>
                  {selectedNoteFiles.map((file, index) => (
                    <div key={index}>
                      <p>{file.name}</p>
                    </div>
                  ))}
                </div>
              </div>

              {/* <div onClick={handleAddTitleClick()} className='cursorPointer'>AddTitle</div> */}
            </div>
            <div className="d-flex gap-2">
              <button
                onClick={() => {
                  setShowButtons(false);
                  if (editNote) {
                    setEditNote(false);
                    setCurrentImageList(defaultImageList);
                    setDeletedImage([]);
                    onChildDataChange([]);
                  } else {
                    setAddTitle("");
                    setNote("");
                    setSelectedNoteFiles([]);
                  }
                }}
                className="cancelNoteButton px-3"
              >
                Cancel
              </button>

              <button
                className="editNoteButton"
                onClick={() => {
                  if (
                    !(
                      (editNote &&
                        updateNotes.description.length > 1 &&
                        updateNotes.title.length > 1) ||
                      (!editNote && note.length > 1 && addTitle.length > 1)
                    )
                  ) {
                    return;
                  }
                  editNote ? handleUpdateClick(noteId) : handleSaveButton();
                }}
                style={{
                  opacity:
                    (editNote &&
                      updateNotes.description.length > 1 &&
                      updateNotes.title.length > 1) ||
                    (!editNote && note.length > 1 && addTitle.length > 1)
                      ? 1
                      : 0.5,
                }}
                disabled={
                  !(
                    (editNote &&
                      updateNotes.description.length > 1 &&
                      updateNotes.title.length > 1) ||
                    (!editNote && note.length > 1 && addTitle.length > 1)
                  )
                }
              >
                Save
              </button>
            </div>
          </div>
        )}
      </div>

      <Modal
        show={showImageModal}
        className="d-flex justify-content-center align-items-center"
        aria-labelledby="example-custom-modal-styling-title"
        size="lg"
        id="commonLeadModal"
        onHide={() => setShowImageModal(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>Image Preview</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <img
            src={currentImage}
            alt="Preview"
            className="profilePhotoNotes cursorPointer"
          />
        </Modal.Body>
      </Modal>

      {showAccessModal && (
        <AccessModal
          showAccessModal={showAccessModal}
          setShowAccessModal={setShowAccessModal}
        />
      )}
    </div>
  );
}

export default Note;

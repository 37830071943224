import whiteLoader from "../Assets/lotties/whiteLoader.json";
import blueLoader from "../Assets/lotties/blueLoader.json";
import redLoader from "../Assets/lotties/redLoader.json";

export const defaultOptions = (param) => {
  return {
    loop: true,
    autoplay: true,
    animationData:
      param === "white"
        ? whiteLoader
        : param === "blue"
        ? blueLoader
        : redLoader,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid meet",
    },
  };
};

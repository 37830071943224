import React, { useState } from "react";
import Closed from "../../Assets/Close Activity.svg";

function CloseActivity({
  // closeActivities,
  closeActivitiesCalls,
  closeActivitiesMeetings,
  closedActivitiesTasks,
}) {
  // eslint-disable-next-line no-console
  console.log(" closeActivitiesTasks", closedActivitiesTasks)
  const [openActivityTabView, setOpenActivityTabView] = useState("Tasks");
  return (
    <div className="p-2">
      <div className="d-flex gap-2 ms-2">
        <img src={Closed} alt="closed" height={20} width={20} />
        <div className="notesHeader">Closed Activities</div>
      </div>
      <div style={{ borderBottom: "2px solid #ffffff" }} className="mt-2"></div>
      <div className="p-2 mt-2">
        {(closedActivitiesTasks?.length > 0 ||
          closeActivitiesMeetings?.length > 0 ||
          closeActivitiesCalls?.length > 0) && (
          <div className="mt-2 ms-2 d-flex gap-0 justify-content-between px-3 bg-white rounded-5 py-1 widthContainerDataOpenActivity">
            <span
              className={`cursorPointer ${
                openActivityTabView === "Tasks"
                  ? "overviewTimelineTabOpenActivity"
                  : "  d-flex justify-content-center align-items-center"
              }`}
              onClick={() => {
                setOpenActivityTabView("Tasks");
              }}
            >
              Tasks
            </span>
            <span
              className={`cursorPointer ${
                openActivityTabView === "Meetings"
                  ? "overviewTimelineTabOpenActivity"
                  : "  d-flex justify-content-center align-items-center"
              }`}
              onClick={() => {
                setOpenActivityTabView("Meetings");
              }}
            >
              Meetings
            </span>
            <span
              className={`cursorPointer ${
                openActivityTabView === "Calls"
                  ? "overviewTimelineTabOpenActivity"
                  : "  d-flex justify-content-center align-items-center"
              }`}
              onClick={() => {
                setOpenActivityTabView("Calls");
              }}
            >
              Calls
            </span>
          </div>
        )}
        <div
          className="mb-2 mt-4 mx-2"
          style={{
            border: "1px solid #DEDFDF",
            borderRadius: "10px",
          }}
        >
          {openActivityTabView === "Tasks" && (
            <table
              id="productTable"
              style={{
                borderCollapse: "collapse",
                width: "100%",
                textAlign: "center",
              }}
              className="p-2"
            >
              {closedActivitiesTasks?.length > 0 && (
                <thead
                  style={{
                    borderBottom: "2px solid #DEDFDF",
                    height: "40px",
                  }}
                >
                  <tr className="p-2 productTableHeader">
                    <th style={{ width: "20%" }}>Subject</th>
                    <th style={{ width: "20%" }}>Due Date</th>
                    <th style={{ width: "20%" }}>Task Owner</th>
                    <th style={{ width: "20%" }}>Status</th>
                    <th style={{ width: "20%" }}>Priority</th>
                  </tr>
                </thead>
              )}
              <tbody className="p-2">
                {closedActivitiesTasks?.length > 0 ? (
                  closedActivitiesTasks?.map((item, index) => (
                    <tr style={{ borderBottom: "1px solid #ccc" }} key={index}>
                      <td style={{ color: "#2177FE", fontSize: "16px" }}>
                        {item?.subject}
                      </td>
                      <td className="productsTabledata">{item?.dueDate}</td>
                      <td className="productsTabledata">{item?.taskOwner}</td>
                      <td className="productsTabledata">{item?.status}</td>
                      <td className="productsTabledata">{item?.priority}</td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="5" style={{ textAlign: "center" }}>
                      <div className="text-center mt-2">
                        <div
                          className="ms-4"
                          style={{ color: "#B9B9B9", fontSize: "20px" }}
                        >
                          No records found
                        </div>
                        <div
                          className="ms-4"
                          style={{ color: "#B9B9B9", fontSize: "14px" }}
                        >
                          There is no data available to show
                        </div>
                      </div>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          )}
          {openActivityTabView === "Meetings" && (
            <table
              id="productTable"
              style={{
                borderCollapse: "collapse",
                width: "100%",
                textAlign: "center",
              }}
              className="p-2"
            >
              {closeActivitiesMeetings?.length > 0 && (
                <thead
                  style={{
                    borderBottom: "2px solid #DEDFDF",
                    height: "40px",
                  }}
                >
                  <tr className="p-2 productTableHeader">
                    <th style={{ width: "20%" }}>Title</th>
                    <th style={{ width: "20%" }}>From</th>
                    <th style={{ width: "20%" }}>To</th>
                    <th style={{ width: "20%" }}>All Day</th>
                    <th style={{ width: "20%" }}>Host</th>
                  </tr>
                </thead>
              )}
              <tbody className="p-2">
                {closeActivitiesMeetings?.length > 0 ? (
                  closeActivitiesMeetings?.map((item, index) => (
                    <tr style={{ borderBottom: "1px solid #ccc" }} key={index}>
                      <td style={{ color: "#2177FE", fontSize: "16px" }}>
                        {item?.title}
                      </td>
                      <td className="productsTabledata">{item?.from}</td>
                      <td className="productsTabledata">{item?.to}</td>
                      <td className="productsTabledata">
                        {item?.allDay ? "✔️" : "X"}
                      </td>
                      <td className="productsTabledata">{item?.host}</td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="5" style={{ textAlign: "center" }}>
                      <div className="text-center mt-2">
                        <div
                          className="ms-4"
                          style={{ color: "#B9B9B9", fontSize: "20px" }}
                        >
                          No records found
                        </div>
                        <div
                          className="ms-4"
                          style={{ color: "#B9B9B9", fontSize: "14px" }}
                        >
                          There is no data available to show
                        </div>
                      </div>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          )}
          {openActivityTabView === "Calls" && (
            <table
              id="productTable"
              style={{
                borderCollapse: "collapse",
                width: "100%",
                textAlign: "center",
              }}
              className="p-2"
            >
              {closeActivitiesCalls?.length > 0 && (
                <thead
                  style={{
                    borderBottom: "2px solid #DEDFDF",
                    height: "40px",
                  }}
                >
                  <tr className="p-2 productTableHeader">
                    <th style={{ width: "20%" }}>Subject</th>
                    <th style={{ width: "20%" }}>Call Start Time</th>
                    <th style={{ width: "20%" }}>Call Owner</th>
                    {/* <th style={{ width: "20%" }}>Call Agenda</th> */}
                    {/* <th style={{ width: "20%" }}>Call Result</th> */}
                    <th style={{ width: "20%" }}>Call To</th>
                    <th style={{ width: "20%" }}>Call Type</th>
                    {/* <th style={{ width: "20%" }}>Call Duration</th> */}
                  </tr>
                </thead>
              )}
              <tbody className="p-2">
                {closeActivitiesCalls?.length > 0 ? (
                  closeActivitiesCalls?.map((item, index) => (
                    <tr style={{ borderBottom: "1px solid #ccc" }} key={index}>
                      <td style={{ color: "#2177FE", fontSize: "16px" }}>
                        {item?.subject}
                      </td>
                      <td className="productsTabledata">
                        {item?.callStartTime}
                      </td>
                      <td className="productsTabledata">{item?.callOwner}</td>
                      <td className="productsTabledata">{item?.callTo}</td>
                      <td className="productsTabledata">{item?.callType}</td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="5" style={{ textAlign: "center" }}>
                      <div className="text-center mt-2">
                        <div
                          className="ms-4"
                          style={{ color: "#B9B9B9", fontSize: "20px" }}
                        >
                          No records found
                        </div>
                        <div
                          className="ms-4"
                          style={{ color: "#B9B9B9", fontSize: "14px" }}
                        >
                          There is no data available to show
                        </div>
                      </div>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          )}
        </div>
      </div>
    </div>
  );
}

export default CloseActivity;

import React, { useState, useEffect } from "react";
import call from "../../Assets/call.svg";
import email from "../../Assets/mail-05.svg";
import { useSelector } from "react-redux";
import moment from "moment";
import leadAvatar from "../../Assets/Lead_Avatar.png";
import LinkedIn from "../../Assets/linkedIn.svg";
import Facebook from "../../Assets/facebook.svg";
import Instagram from "../../Assets/Instagram.svg";
import Twitter from "../../Assets/Twitter.svg";
import Discord from "../../Assets/Discord.svg";
import { Modal } from "react-bootstrap";
import { URI } from "../../constants";
import axios from "axios";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import { useDispatch } from "react-redux";
import { setLoginUserInfo, setSocialMediaInfo } from "../../Redux/stateSlice";
import Select from "react-select";
import Delete from "../../Assets/DeleteSocial.svg";
import Lottie from "react-lottie";
import { defaultOptions } from "../../utils/defaultOptions";
import getHeaders from "../../common/ApiHeader.js";
import { handleApiError } from "../../utils/ApiUtils";
import { useNavigate } from "react-router-dom";

export const Personal = ({ setEditUserModal, setUserInfoUpdate }) => {
  const reduxStore = useSelector((state) => state.reduxState);
  const navigate = useNavigate();

  const { loginUserId, socialDetails, loginUserInfo } = reduxStore;
  const [addSocialMedia, setAddSocialMedia] = useState(false);
  const [showDeleteSocialModal, setShowDeleteSocialModal] = useState(false);
  const [addLink, setAddLink] = useState("");
  const [socialId, setSocialId] = useState("");
  const [btnLoader, setBtnLoader] = useState(false);

  const dispatch = useDispatch();

  const handleKeyDown = (e) => {
    if (e.key === " ") {
      e.preventDefault();
    }
  };

  const socialMediaOptions = [
    { value: "Instagram", label: "Instagram" },
    { value: "Facebook", label: "Facebook" },
    { value: "LinkedIn", label: "LinkedIn" },
    { value: "Twitter", label: "Twitter" },
    { value: "Discord", label: "Discord" },
  ];
  const [selectSocialProfile, setSelectSocialProfile] = useState([
    socialMediaOptions[0].value,
  ]);

  const isSaveButtonDisabled = !selectSocialProfile || addLink.trim() === "";

  const handleSelectSocialProfile = (selectedOption) => {
    setSelectSocialProfile(selectedOption);
  };

  const getUserProfileInfo = async () => {
    await axios
      .get(URI.getProfileUserInfo + "/" + loginUserId + "/" + loginUserId, {
        headers: getHeaders(true),
      })

      .then((response) => {
        if (response.data.status === 200) {
          dispatch(setLoginUserInfo(response.data.data.userInfo));
          dispatch(setSocialMediaInfo(response.data.data.socialMediaDetails));
        }
      })
      .catch(async (error) => {
        handleApiError(error, navigate);
      });
  };

  useEffect(() => {
    getUserProfileInfo();
  }, []);

  const handleImageClick = () => {
    document.getElementById("fileInput").click();
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      uploadUserImageData(file);
    }
  };

  const uploadUserImageData = async (file) => {
    const formData = new FormData();
    formData.append("userId", loginUserInfo.userId);
    formData.append("userImage", file);

    try {
      const response = await axios.post(URI.uploadUserImage, formData, {
        headers: getHeaders(true),
      });
      if (response.data.status === 200) {
        NotificationManager.success("", response.data.message, 5000);
        getUserProfileInfo();
      } else {
        NotificationManager.error("", response.data.message, 5000);
      }
    } catch (err) {
      handleApiError(err, navigate);
    }
  };
  const addSocialMediaDetails = async () => {
    const requestBody = {
      socialMediaName: selectSocialProfile.value,
      url: addLink,
      loggedInUserId: loginUserInfo.userId,
    };
    setBtnLoader(true);
    try {
      const response = await axios.post(URI.addSocialProfile, requestBody, {
        headers: getHeaders(true),
      });
      if (response.data.status === 200) {
        NotificationManager.success("", response.data.message, 5000);
        setAddLink("");
        setSelectSocialProfile([]);
        getUserProfileInfo();
      } else {
        NotificationManager.error("", response.data.message, 5000);
      }
    } catch (err) {
      handleApiError(err, navigate);
    } finally {
      setBtnLoader(false);
      setAddSocialMedia(false);
    }
  };

  const deleteSocialProfile = async () => {
    const requestBody = {
      loggedInUserId: loginUserInfo.userId,
      id: socialId,
    };

    try {
      const response = await axios.post(URI.deleteSocialProfile, requestBody, {
        headers: getHeaders(true),
      });
      if (response.data.status === 200) {
        setShowDeleteSocialModal(false);
        NotificationManager.success("", response.data.message, 5000);

        if (response.data.data && response.data.data.socialMediaDetails) {
          dispatch(setSocialMediaInfo(response.data.data.socialMediaDetails));
           }
        getUserProfileInfo();
       
      } else {
        NotificationManager.error("", response.data.message, 5000);
      }
    } catch (err) {
      handleApiError(err, navigate);
    }
  };

  const createLeadStyles = {
    menu: (base) => ({
      ...base,
      width: "100%",
    }),
    option: (provided) => ({
      ...provided,
      // backgroundColor: "#EAEAEA",
      color: "#000",
      // backgroundColor: 'rgba(33, 119, 254, 0.1)',
      width: "100%",
    }),
    control: (baseStyles) => ({
      ...baseStyles,
      border: "1px solid #b9b9b9b0",
      backgroundColor: "transparent",
      height: "40px",
      borderRadius: "4px",
      width: "100%",
      boxShadow: "none",
    }),
    input: (base) => ({
      ...base,
      color: "#000",
    }),
    singleValue: (defaultStyles) => ({ ...defaultStyles, color: "#000" }),

    menuList: (provided) => ({
      ...provided,
      paddingTop: 0,
      paddingBottom: 0,
      "&::-webkit-scrollbar": {
        width: 5,
      },

      "&::-webkit-scrollbar-thumb": {
        background: "#316AFF",
        borderRadius: "10px",
      },
    }),
  };

  const handleDelete = (id) => {
    setShowDeleteSocialModal(true);
    setSocialId(id);
  };

  return (
    <div
      className="Personal_main_container bg-white rounded-2 px-1 py-2  d-flex flex-column gap-2"
      style={{ width: "63vw" }}
    >
      <div
        style={{
          background:
            "linear-gradient(to right, rgba(64, 123, 255, 1), rgba(18, 167, 254, 1))",
          WebkitBackgroundClip: "text",
          WebkitTextFillColor: "transparent",
          fontSize: "x-large",
        }}
        className="ms-2"
      >
        Personal Profile
      </div>
      <div className="info_container commonContainer_bg pt-2 py-2 d-flex justify-content-between ms-2 me-2 rounded-2">
        <div className="d-flex ms-2">
          <img
            src={
              loginUserInfo?.userImageData === null
                ? leadAvatar
                : loginUserInfo?.userImageData
            }
            alt="userImage"
            height={100}
            width={100}
            onClick={handleImageClick}
            style={{ cursor: "pointer" }}
          />
          <input
            type="file"
            id="fileInput"
            style={{ display: "none" }}
            accept="image/png, image/jpeg, image/jpg"
            onChange={handleFileChange}
          />
          <div className="name_container d-flex flex-column justify-content-end align-items-start ms-2">
            <span>{loginUserInfo?.firstName}</span>
            <span className="opacity-75">
              {loginUserInfo?.userRole || "--"}
            </span>
          </div>
          <div className="name_container d-flex flex-column justify-content-end align-items-end ms-4">
            <span
              className=" text-white  px-2 danger_gradient  rounded-2 d-flex justify-content-center align-items-center p-1"
              style={{ fontSize: "10px" }}
            >
              {loginUserInfo.userProfile?.toLowerCase() === "super admin"
                ? "SUPER ADMIN"
                : loginUserInfo.userProfile?.toUpperCase()}
            </span>
          </div>
        </div>
        <div className="d-flex flex-column  justify-content-end align-items-end ">
          <span className="d-flex opacity-75 fs_small">
            <img src={email} height={15} width={20} alt="img" />
            {loginUserInfo?.emailId}
          </span>
          <span className="d-flex opacity-75 fs_small w-100">
            <img src={call} height={15} width={20} alt="img" />
            {loginUserInfo?.phoneNumber}
          </span>
        </div>

        <div className="d-flex  justify-content-end align-items-end  px-2 pb-2">
          <div className="d-flex gap-1 px-2  ">
            <span
              className="btn btn-primary setting_btn text-center pt-1 rounded-3"
              onClick={() => {
                setEditUserModal(true);
                setUserInfoUpdate(loginUserInfo);
              }}
            >
              EDIT
            </span>
          </div>
        </div>
      </div>

      <div className="commonContainer_bg pt-3 pb-2 infoDetails_main_container d-flex flex-column gap-2 ms-2 me-2 rounded-2">
        <div className="UserAdd_container d-flex  ps-4 ">
          <div>
            <h5 className="text-primary">User Information</h5>
            <div className="userInformation_container d-flex gap-4 justify-content-around w-100  ">
              <div className="d-flex flex-column opacity-75">
                <span>First Name</span>
                <span>Last Name</span>
                <span>Email</span>
                <span>Role</span>
                <span>Profile</span>
                <span>Added By</span>
                <span>Alias</span>
                <span>Mobile</span>
                <span>Phone</span>
                <span>Website</span>
                <span>Fax</span>
                <span>Date of Birth</span>
              </div>
              <div className="d-flex flex-column">
                <span>{loginUserInfo?.firstName || "-"}</span>
                <span>{loginUserInfo?.lastName || "-"}</span>
                <span>{loginUserInfo?.emailId || "-"}</span>
                <span>{loginUserInfo?.userRole || "-"}</span>
                <span>{loginUserInfo?.userProfile || "-"}</span>
                <span>{loginUserInfo?.createdBy || "-"}</span>
                <span>{loginUserInfo?.aliasName || "-"}</span>
                <span>{loginUserInfo?.mobileNumber || "-"}</span>
                <span>{loginUserInfo?.phoneNumber || "-"}</span>
                <span>{loginUserInfo?.website || "-"}</span>
                <span>{loginUserInfo?.fax || "-"}</span>
                <span>
                  {" "}
                  {loginUserInfo?.dateOfBirth
                    ? moment(loginUserInfo?.dateOfBirth,

                      "YYYY-MM-DD"
                    ).format("DD-MM-YYYY")
                    : "--"}
                    
                </span>
              </div>
            </div>
           
          </div>
        </div>
        <div className="ps-4">
          <h5 className="text-primary">Address Information</h5>

          <div className="addressInfo_container d-flex gap-5">
            <div className="d-flex flex-column opacity-75">
              <span>Street</span>
              <span>City</span>
              <span>State</span>
              <span>Zip Code</span>
              <span>Country</span>
            </div>
            <div className="d-flex flex-column">
              <span>{loginUserInfo?.street || "-"}</span>
              <span>{loginUserInfo?.city || "-"}</span>
              <span>{loginUserInfo?.state || "-"}</span>
              <span>{loginUserInfo?.zipCode || "-"}</span>
              <span>{loginUserInfo?.country || "-"}</span>
            </div>
          </div>
        </div>
      
      {/* ---------------seond ----------------------------*/}

      <div className="UserAdd_container d-flex  px-4 gap-4">
        <div className="w-50">
          <div className="d-flex justify-content-between ">
            {" "}
            <h5 className="text-primary">Social Profiles</h5>{" "}
            <button
              className="btn btn-primary setting_btn text-center pt-1 rounded-3"
              onClick={() => setAddSocialMedia(true)}
            >
              Add
            </button>{" "}
          </div>
          {addSocialMedia && (
            <Modal
              show={addSocialMedia}
              className="d-flex justify-content-center align-items-start"
              aria-labelledby="example-custom-modal-styling-title"
              size="lg"
              id="commonLeadModal"
            >
              <Modal.Header>
                <div className="d-flex justify-content-between align-items-center w-100">
                  <div
                    style={{
                      fontSize: "25px",
                      fontWeight: "bold",
                      background:
                        "linear-gradient(to right, rgba(64, 123, 255, 1), rgba(18, 167, 254, 1))",
                      WebkitBackgroundClip: "text",
                      backgroundClip: "text",
                      WebkitTextFillColor: "transparent",
                    }}
                  >
                    Add Social Handle
                  </div>
                </div>{" "}
              </Modal.Header>
              <Modal.Body className="ModalBody overflow-auto">
                <div className="mt-2">
                  <div>
                    <p>Select Social Media</p>

                    <Select
                      value={selectSocialProfile}
                      menuPlacement="bottom"
                      onChange={(selectedOption) =>
                        handleSelectSocialProfile(selectedOption)
                      }
                      options={socialMediaOptions}
                      styles={createLeadStyles}
                      isSearchable={false}
                    />
                  </div>
                  <div>
                    <div className="mt-2"> Add Link</div>
                    <div className="position-relative">
                      <input
                        type="text "
                        name="profileDescription"
                        className="inputBoxProfile mt-1"
                        value={addLink}
                        onChange={(e) => {
                          setAddLink(e.target.value);
                        }}
                        onKeyDown={handleKeyDown}
                      />
                    </div>
                  </div>

                  <div className="d-flex gap-3 align-items-center justify-content-end mt-4">
                    <button
                      className="p-1 py-1 px-4 cancelButton"
                      onClick={() => {
                        setAddSocialMedia(false);
                        setAddLink("");
                        setSelectSocialProfile();
                      }}
                    >
                      Cancel
                    </button>
                    <button
                      className="p-2 px-4 createContactButtonSave"
                      onClick={addSocialMediaDetails}
                      disabled={isSaveButtonDisabled || btnLoader}
                      style={{
                        opacity: isSaveButtonDisabled ? 0.5 : 1,
                      }}
                    >
                      {btnLoader ? (
                        <Lottie
                          options={defaultOptions("white")}
                          height="24px"
                          width="36px"
                        />
                      ) : (
                        "Save"
                      )}
                    </button>
                  </div>
                </div>
              </Modal.Body>
            </Modal>
          )}
          {socialDetails?.length > 0 ? (
            <div className="d-flex gap-3 mt-2">
              {socialDetails.map((media) => (
                <div key={media.id} className="social-media-container">
                  {media.socialMediaName.toLowerCase() === "twitter" && (
                    <img
                      src={Twitter}
                      alt="Twitter"
                      className="social-media-image"
                    />
                  )}
                  {media.socialMediaName.toLowerCase() === "instagram" && (
                    <img
                      src={Instagram}
                      alt="Instagram"
                      className="social-media-image"
                    />
                  )}
                  {media.socialMediaName.toLowerCase() === "facebook" && (
                    <img
                      src={Facebook}
                      alt="facebook"
                      className="social-media-image"
                    />
                  )}
                  {media.socialMediaName.toLowerCase() === "linkedin" && (
                    <img
                      src={LinkedIn}
                      alt="linkedIn"
                      className="social-media-image"
                    />
                  )}
                  {media.socialMediaName.toLowerCase() === "discord" && (
                    <img
                      src={Discord}
                      alt="discord"
                      className="social-media-image"
                    />
                  )}

                  <img
                    src={Delete}
                    alt="Delete"
                    className="delete-icon"
                    onClick={() => {
                      handleDelete(media.id);
                    }}
                  />
                </div>
              ))}
            </div>
          ) : (
            <div className="opacity-75">No Social profiles found</div>
          )}
          {showDeleteSocialModal && (
            <Modal
              show={showDeleteSocialModal}
              className="d-flex justify-content-center align-items-center"
              aria-labelledby="example-custom-modal-styling-title"
              size="xl"
              id="commonLeadModal"
            >
              <Modal.Body className="ModalBody overflow-auto">
                <div className="w-100 ">
                  <div className=" text-center">
                    Are you sure you want to delete this Social Profile
                  </div>
                  <div className="d-flex gap-4 align-items-center justify-content-end">
                    <button
                      onClick={() => {
                        setShowDeleteSocialModal(false);
                      }}
                      className="cancelRecycle px-4 mt-4 d-flex justify-content-center align-items-center"
                    >
                      Cancel
                    </button>
                    <button
                      onClick={deleteSocialProfile}
                      className="deleteButton px-4 mt-4 d-flex justify-content-center align-items-center"
                    >
                      OK
                    </button>
                  </div>
                </div>
              </Modal.Body>
            </Modal>
          )}
          <div className="userInformation_container d-flex w-auto">
            {/* <div className="d-flex flex-column opacity-75">
                <span>First Name</span>
                <span>Last Name</span>
                <span>Email</span>
                <span>Role</span>
                <span>Profile</span>
              </div>
              <div className="d-flex flex-column">
                <span>{userInfo.firstName || "-"}</span>
                <span>{userInfo.lastName || "-"}</span>
                <span>{userInfo.emailId || "-"}</span>
                <span>{userInfo.userRole || "-"}</span>
                <span>{userInfo.userProfile || "-"}</span>
              </div> */}
          </div>
        </div>
        {/* <div className="w-50">
              <div className="d-flex justify-content-between">
                {" "}
                <h5 className="text-primary">Local Information</h5>{" "}
                <button className="btn btn-primary setting_btn text-center pt-1 rounded-3">
                  EDIT
                </button>{" "}
              </div>
              <div className="addressInfo_container d-flex gap-5">
                <div className="d-flex flex-column opacity-75">
                  <span>Language</span>
                  <span>Country Locale</span>
                  <span>Date Format</span>
                  <span>Time Format</span>
                  <span>Number Format</span>
                </div>
                <div className="d-flex flex-column">
                <span>{userInfo.language || "-"}</span>
                <span>{userInfo.countryLocal || "-"}</span>
                <span>{userInfo.dateFormat || "-"}</span>
                <span>{userInfo.timeFormat || "-"}</span>
                <span>{userInfo.numberFormat || "-"}</span>
              </div>
              </div>
            </div> */}
      </div>

      {/* -------------------third ----------------------------*/}

      <div className="UserAdd_container d-flex  px-4 gap-4 w-100">
      <div className="">
  <h5 className="text-primary">Groups</h5>
  <div className="d-flex gap-5" style={{ width: "100%", overflowWrap: "anywhere" }}>
  <span style={{ flex: "-2 0 22%", whiteSpace: "nowrap" }} className="opacity-75">Members in</span>
  <span style={{ flex: "1", wordBreak: "break-word" }}>{loginUserInfo.groupNames || "--"}</span>
</div>

  </div>
  </div>
      </div>

      <NotificationContainer />
    </div>
  );
};

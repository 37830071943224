export const validateVendorData = (
  vendorData,
  setMandatoryMsg,
  setEmailMsg,
  setPhoneMsg,
  setWebsiteMsg,
  setCityMsg,
  setStateMsg,
  setCodeMsg,
  setCountryMsg,
  validateFirstName,
  emailValidation,
  validateCityIndia,
  validateStateIndia,
  validateZipCode,
  validateCountryName,
  validateMobileNumber,
  websiteValidation,
  validateCategory,
  setCategoryMsg
) => {
  let isValid = true;

  const setErrorMessage = (setter, message, timeout = 5000) => {
    setter(message);
    setTimeout(() => {
      setter("");
    }, timeout);
    isValid = false;
  };

  if (vendorData.vendorName === "") {
    setErrorMessage(setMandatoryMsg, "Vendor name cannot be empty.", 5000);
  }

  if (vendorData.vendorName && !validateFirstName(vendorData.vendorName)) {
    setErrorMessage(setMandatoryMsg, "Please enter a valid Vendor name.", 5000);
  }

  if (vendorData.emailId === "") {
    setErrorMessage(setEmailMsg, "Email name cannot be empty.", 5000);
  }

  if (vendorData.emailId && !emailValidation(vendorData.emailId)) {
    setErrorMessage(setEmailMsg, "Please enter a valid Email Id.", 5000);
  }

  if (vendorData.phone === "") {
    setErrorMessage(setPhoneMsg, "Phone number cannot be empty.", 5000);
  }

  if (
    vendorData.phone &&
    !validateMobileNumber(vendorData.phone) &&
    vendorData.phone.length > 0
  ) {
    setErrorMessage(setPhoneMsg, "Please enter a valid Phone.", 5000);
  }

  if (vendorData.category && !validateCategory(vendorData.category)) {
    setErrorMessage(setCategoryMsg, "Please enter a valid Category.", 5000);
  }

  if (!websiteValidation(vendorData.website) && vendorData.website.length > 0) {
    setErrorMessage(setWebsiteMsg, "Please enter a valid Vendor site.");
  }

  if (vendorData.city && !validateCityIndia(vendorData.city)) {
    setErrorMessage(setCityMsg, "Please enter a valid City.");
  }

  if (vendorData.state && !validateStateIndia(vendorData.state)) {
    setErrorMessage(setStateMsg, "Please enter a valid State.");
  }

  if (vendorData.zipCode && !validateZipCode(vendorData.zipCode)) {
    setErrorMessage(setCodeMsg, "Please enter a valid Zip Code.");
  }

  if (vendorData.country && !validateCountryName(vendorData.country)) {
    setErrorMessage(setCountryMsg, "Please enter a valid Country.", 5000);
  }

  return isValid;
};

import React, { useState, useEffect } from "react";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import leadAvatar from "../../Assets/Lead_Avatar.png";
import call from "../../Assets/call.svg";
import email from "../../Assets/mail-05.svg";
import { useSelector } from "react-redux";
import { URI } from "../../constants";
import axios from "axios";
import getHeaders from "../../common/ApiHeader";
import { Modal } from "react-bootstrap";
import { defaultOptions } from "../../utils/defaultOptions";
import Lottie from "react-lottie";
import Cancel from "../../Assets/CancelCircleModal.svg";
import {
  removeExtraSpaces,
  validateAliasName,
  websiteValidation,
  validateCompanyName,
  validateZipCode,
  validateFaxNumber,
  validateCityIndia,
  validateStateIndia,
  validateCountryName
} from "../../common/CommonComponent";
import { handleApiError } from "../../utils/ApiUtils";
import { useNavigate } from "react-router-dom";

const CompanySettings = ({ userInfo }) => {
  const reduxStore = useSelector((state) => state.reduxState);
  const { loginUserId, loginUserInfo } = reduxStore;
  const [companyDetails, setCompanyDetails] = useState([]);
  const [editCompanyModal, setEditCompanyModal] = useState(false);
  const [apiLoader, setApiLoader] = useState(false);
  const [isFirstCharacterEntered, setIsFirstCharacterEntered] = useState(false);
  const [companyNameError, setCompanyNameError] = useState("");
  const [aliasNameError, setAliasNameError] = useState("");
  const [websiteError, setWebsiteError] = useState("");
  const [errorMessageEmployeeCount, setErrorMessageEmployeeCount] = useState("");
  const [updateZipCodeError, setUpdateZipCodeError] = useState("");
  const [updateFaxError, setUpdateFaxError] = useState("");
  const [updateCountryError, setUpdateCountryError] = useState("");
  const [updateStateError, setUpdateStateError] = useState("");
  const [updateCityError, setUpdateCityError] = useState("");
  const navigate = useNavigate();

  const [companyInfoUpdate, setCompanyInfoUpdate] = useState({
    companyName: "",
    aliasName: "",
    phone: "",
    mobile: "",
    website: "",
    fax: "",
    description: "",
    street: "",
    city: "",
    state: "",
    zip: "",
    country: "",
    employeeCount: "",
  });

  const handleImageClick = () => {
    document.getElementById("fileInput").click();
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    const validFileTypes = ["image/png", "image/jpg", "image/jpeg", "image/svg+xml"];
    const maxSizeInBytes = 2 * 1024 * 1024; // 2MB in bytes
  
    if (file) {
      if (!validFileTypes.includes(file.type)) {
        NotificationManager.error("","Invalid file type. Please select a PNG, JPG, JPEG, or SVG file.", 3000);
        return;
      }
      
      if (file.size > maxSizeInBytes) {
        NotificationManager.error("","File size exceeds 2MB. Please select a smaller file.", 3000);
        return;
      }
  
      uploadCompanyImageData(file);
    }
  };
  

  const uploadCompanyImageData = async (file) => {
    const formData = new FormData();
    formData.append("loggedInUserId", loginUserInfo.userId);
    formData.append("companyImage", file);

    try {
      const response = await axios.post(URI.uploadCompanyProfile, formData, {
        headers: getHeaders(true),
      });
      if (response.data.status === 200) {
        NotificationManager.success("", response.data.message, 5000);
        getCompanyInfoDetails();
      } else {
        NotificationManager.error("", response.data.message, 5000);
      }
    } catch (err) {
      handleApiError(err, navigate);
    }
  };

  const handleChangeUpdate = (e) => {
    const { name, value } = e.target;
    if (name === "mobile" || name === "phone") {
      const regex = /^\d{0,10}$/;
      if (!regex.test(value)) {
        return;
      }
    }
    if (name === "fax") {
      const regex = /^\d{0,12}$/;
      if (!regex.test(value)) {
        return;
      }
    }

    if (name === "website") {
      const regex = /^[^\s]*$/;
      if (!regex.test(value)) {
        return;
      }
    }

    if (name === "zip") {
      const regex = /^\d{0,10}$/;
      if (!regex.test(value)) {
        return;
      }
    }

    if (name === "employeeCount") {
      const regex = /^\d{0,6}$/; // max 6 digits
      if (!regex.test(value)) {
        return;
      }

      const numericValue = parseInt(value.replace(/\D/g, ''), 10);

      if (numericValue > 100000) {
        setErrorMessageEmployeeCount("The Employee count cannot exceed 1,00,000.");
        setTimeout(() => setErrorMessageEmployeeCount(""), 3000);
        return;
      }
    }

    if (value.length > 0) {
      setIsFirstCharacterEntered(true);
    } else {
      setIsFirstCharacterEntered(false);
    }
    setCompanyInfoUpdate((prevFields) => ({
      ...prevFields,
      [name]: value,
    }));
  };

  const handleKeyDown = (e) => {
    if (!isFirstCharacterEntered && e.key === " ") {
      e.preventDefault();
    }
  };

  const getCompanyInfoDetails = async () => {
    await axios
      .get(URI.getCompanyDetails + "/" + loginUserId, {
        headers: getHeaders(true),
      })

      .then((response) => {
        if (response.data.status === 200) {
          setCompanyDetails(response.data.data.getCompanyData);
        }
      })
      .catch(async (error) => {
        handleApiError(error, navigate);
      });
  };

  useEffect(() => {
    getCompanyInfoDetails();
  }, []);

  const updateCompanyDetails = async () => {
    const errors = {};
    let hasError = false;
    if (!companyInfoUpdate?.companyName) {
      errors.companyName = "Company Name cannot be empty";
      hasError = true;
    }

    if (
      companyInfoUpdate?.companyName &&
      !validateCompanyName(companyInfoUpdate?.companyName)
    ) {
      errors.companyName = "Please enter a valid Company Name";
      hasError = true;
    }

    if (
      companyInfoUpdate?.alias &&
      !validateAliasName(companyInfoUpdate?.alias)
    ) {
      errors.aliasName = "Please enter valid Alias Name";
      hasError = true;
    }
    if (
      companyInfoUpdate?.website &&
      !websiteValidation(companyInfoUpdate?.website)
    ) {
      errors.website = "Please enter valid website";
      hasError = true;
    }
    if (
      companyInfoUpdate?.zip &&
      !validateZipCode(companyInfoUpdate?.zip)
    ) {
      errors.zip = "Please enter valid ZipCode";
      hasError = true;
    }
    if (
      companyInfoUpdate?.fax &&
      !validateFaxNumber(companyInfoUpdate?.fax)
    ) {
      errors.fax = "Please enter valid Fax";
      hasError = true;
    }
    if (
      companyInfoUpdate?.country &&
      !validateCountryName(companyInfoUpdate?.country)
    ) {
      errors.country = "Please enter valid Country";
      hasError = true;
    }

    if (
      companyInfoUpdate?.city &&
      !validateCityIndia(companyInfoUpdate?.city)
    ) {
      errors.city = "Please enter valid City";
      hasError = true;
    }

    if (
      companyInfoUpdate?.state &&
      !validateStateIndia(companyInfoUpdate?.state)
    ) {
      errors.state = "Please enter valid State";
      hasError = true;
    }
  if (hasError) {
      setCompanyNameError(errors.companyName || "");
      setAliasNameError(errors.aliasName || "");
      setWebsiteError(errors.website || "");
      setUpdateZipCodeError(errors.zip||"");
      setUpdateFaxError(errors.fax||"");
      setUpdateCountryError(errors.country||"")
      setUpdateStateError(errors.state||"");
      setUpdateCityError(errors.city||"")
      setTimeout(() => {
        setCompanyNameError("");
        setAliasNameError("");
        setWebsiteError("");
        setUpdateFaxError("");
        setUpdateZipCodeError("");
        setUpdateCountryError("");
        setUpdateStateError("");
        setUpdateCityError("")
  }, 3000);
      return;
    }
    const requestBody = {
      loggedInUserId: loginUserId,
      companyName:
        companyInfoUpdate?.companyName === null
          ? ""
          : removeExtraSpaces(companyInfoUpdate?.companyName),
      companyId: companyInfoUpdate?.companyId,
      aliasName:
        companyInfoUpdate?.alias === null
          ? ""
          : removeExtraSpaces(companyInfoUpdate?.alias),
      phoneNumber:
        companyInfoUpdate?.phone === null ? "" : companyInfoUpdate?.phone,
      mobile:
        companyInfoUpdate?.mobile === null ? "" : companyInfoUpdate?.mobile,
      description:
        companyInfoUpdate?.description === null
          ? ""
          : companyInfoUpdate?.description,
      employeeCount:
        companyInfoUpdate?.employeeCount === null
          ? ""
          : companyInfoUpdate?.employeeCount,
      website:
        companyInfoUpdate?.website === null
          ? ""
          : removeExtraSpaces(companyInfoUpdate?.website),
      fax: companyInfoUpdate?.fax === null ? "" : companyInfoUpdate?.fax,
      street:
        companyInfoUpdate?.street === null
          ? ""
          : removeExtraSpaces(companyInfoUpdate?.street),
      city:
        companyInfoUpdate?.city === null
          ? ""
          : removeExtraSpaces(companyInfoUpdate?.city),
      state:
        companyInfoUpdate?.state === null
          ? ""
          : removeExtraSpaces(companyInfoUpdate?.state),
      zipCode: companyInfoUpdate?.zip === null ? "" : companyInfoUpdate?.zip,
      country:
        companyInfoUpdate?.country === null
          ? ""
          : removeExtraSpaces(companyInfoUpdate?.country),
    };
    setApiLoader(true);
    await axios
      .post(URI.updateCompanyDetails, requestBody, {
        headers: getHeaders(true),
      })
      .then((response) => {
        if (response.data.status === 200) {
          NotificationManager.success("", response.data.message, 5000);
          setEditCompanyModal(false);
          getCompanyInfoDetails();
        } else {
          NotificationManager.error("", response.data.message, 5000);
        }
      })
      .catch((err) => {
        handleApiError(err, navigate);
      })
      .finally(() => setApiLoader(false));
  };

  return (
    <div
      className="Personal_main_container bg-white rounded-2 px-1 py-2  d-flex flex-column gap-2"
      style={{ width: "63vw" }}
    >
      <div
        style={{
          background:
            "linear-gradient(to right, rgba(64, 123, 255, 1), rgba(18, 167, 254, 1))",
          WebkitBackgroundClip: "text",
          WebkitTextFillColor: "transparent",
          fontSize: "x-large",
        }}
        className="ms-2"
      >
        Company Details
      </div>
      <div className="info_container commonContainer_bg pt-2 py-2 d-flex justify-content-between ms-2 me-2 rounded-2">
        <div className="d-flex ms-2">
          <img
            src={
              companyDetails?.profileImageData === null
                ? leadAvatar
                : companyDetails?.profileImageData
            }
            alt="companyImage"
            height={100}
            width={100}
            onClick={
              userInfo?.userProfile === "Super Admin" ? handleImageClick : null
            }
            style={{ cursor: "pointer" }}
          />
          {userInfo?.userProfile === "Super Admin" && (
            <input
              type="file"
              id="fileInput"
              style={{ display: "none" }}
              accept="image/png, image/jpeg, image/jpg"
              onChange={handleFileChange}
            />           
          )}
          <div className="name_container d-flex flex-column justify-content-end align-items-start ms-2">
            <span>{companyDetails?.companyName}</span>
          </div>
        </div>
        <div className="d-flex flex-column  justify-content-end align-items-start ">
          <span className="d-flex opacity-75 fs_small">
            <img src={email} height={15} width={20} alt="img" />
            {companyDetails?.companyEmail || "--"}
          </span>
          <span className="d-flex opacity-75 fs_small w-100">
            <img src={call} height={15} width={20} alt="img" />
            {companyDetails?.phone || "--"}
          </span>
        </div>
        <div className="d-flex  justify-content-end align-items-end  px-2 pb-2">
          <div className="d-flex gap-1 px-2  ">
            {userInfo?.userProfile === "Super Admin" && (
              <span
                className="btn btn-primary setting_btn text-center pt-1 rounded-3"
                onClick={() => {
                  setEditCompanyModal(true);
                  setCompanyInfoUpdate({ ...companyDetails });
                }}
              >
                EDIT
              </span>
            )}
          </div>
        </div>
      </div>
      <div className="commonContainer_bg pt-3 pb-2 infoDetails_main_container d-flex flex-column gap-2 ms-2 me-2 rounded-2" style={{height:"80vh"}}>
        <div className="UserAdd_container d-flex  ps-4 ">
          <div>
            <h5 className="text-primary">Company Information</h5>
            <div className="userInformation_container d-flex gap-5 justify-content-around w-100  ">
              <div className="d-flex flex-column opacity-75">
                <span>Company Name</span>
                <span>Company Email</span>
                <span>Employee Count</span>
                <span>Alias</span>
                <span>Website</span>
                <span>Mobile</span>
                <span>Description</span>
                <span>Fax</span>
                <span>Created Date</span>
              </div>
              <div className="d-flex flex-column">
                <span>{companyDetails?.companyName || "--"}</span>
                <span>{companyDetails?.companyEmail || "--"}</span>
                <span>{companyDetails?.employeeCount || "--"}</span>
                <span>{companyDetails?.alias || "--"}</span>
                <span>{companyDetails?.website || "--"}</span>
                <span>{companyDetails?.mobile || "--"}</span>
                <span>{companyDetails?.description || "--"}</span>
                <span>{companyDetails?.fax || "--"}</span>
                <span>{companyDetails?.createdDate || "--"}</span>
              </div>
            </div>
          </div>
        </div>
        <div className="ps-4 mt-2">
          <div className="d-flex gap-5">
            <h5 className="text-primary locationClass ms-1">Location</h5>
            <span className="d-flex gap-1 me-4">
             {companyDetails?.street && <span>{companyDetails?.street},</span>}
             {companyDetails?.city && <span>{companyDetails?.city},</span>}
             {companyDetails?.state && <span>{companyDetails?.state},</span>}
              {companyDetails?.zip &&<span>{companyDetails?.zip},</span>}
              {companyDetails?.country &&<span>{companyDetails?.country}</span>}
            </span>
          </div>
        </div>
      </div>

      <NotificationContainer />

      <Modal
        show={editCompanyModal}
        className="d-flex justify-content-center align-items-start w-100"
        aria-labelledby="example-custom-modal-styling-title"
        id="commonLeadModal"
      >
        <Modal.Header>
          <div className="d-flex justify-content-between align-items-center w-100">
            <div
              style={{
                fontSize: "25px",
                fontWeight: "bold",
                background:
                  "linear-gradient(to right, rgba(64, 123, 255, 1), rgba(18, 167, 254, 1))",
                WebkitBackgroundClip: "text",
                backgroundClip: "text",
                WebkitTextFillColor: "transparent",
              }}
            >
              Edit Company Details
            </div>
            <div>
              <img
                alt="cancel"
                src={Cancel}
                className="cursorPointer"
                onClick={() => {
                  setEditCompanyModal(false);
                }}
              />
            </div>
          </div>{" "}
        </Modal.Header>
        <Modal.Body className="ModalBody">
          <div className="ms-2 me-2 editUserModal">
            <div style={{ fontSize: "20px", fontWeight: "500" }}>
            Company Information
            </div>
            <div
              className="editUserModal"
              style={{ height: "56vh", overflow: "auto", overflowX: "hidden" }}
            >
            <div className="d-flex gap-3 align-items-center mt-4 pe-4">
  <div
    style={{ width: "30%" }}
    className="editCompanyHeader text-end"
  >
    Company Name
    <span className="text-danger">*</span>
  </div>
  <div style={{ width: "70%" }}>
    <input
      type="text"
      name="companyName"
      className="inputBoxCompanyDetails px-2"
      value={companyInfoUpdate?.companyName}
      onChange={handleChangeUpdate}
      onKeyDown={handleKeyDown}
      maxLength={50}
    />
    
    </div>
 
</div>
<div className="d-flex justify-content-center ms-5">
      {companyNameError && (
        <p style={{ color: "red", fontSize: "14px", margin: 0 }}>
          {companyNameError}
        </p>
      )}
    </div>


              <div
                className="d-flex gap-3 align-items-center mt-2 pe-4"
                style={{ width: "100%" }}
              >
                <div
                  style={{ width: "30%" }}
                  className="editCompanyHeader text-end"
                >
                  Alias
                </div>
                <div style={{ width: "70%" }}>
                  <input
                    type="text"
                    name="alias"
                    className="inputBoxCompanyDetails px-2"
                    value={companyInfoUpdate?.alias}
                    onChange={handleChangeUpdate}
                    onKeyDown={handleKeyDown}
                    maxLength={30}
                  />
                
                </div>
              </div>
              <div className="d-flex justify-content-center ms-4">
              {aliasNameError && (
                    <p style={{ color: "red", fontSize: "14px" }}>
                      {aliasNameError}
                    </p>
                  )}
</div>
              <div
                className="d-flex gap-3 align-items-center mt-2 pe-4"
                style={{ width: "100%" }}
              >
                <div
                  style={{ width: "30%" }}
                  className="editCompanyHeader text-end"
                >
                  Employee Count
                </div>
                <div style={{ width: "70%" }}>
                  <input
                    type="text"
                    name="employeeCount"
                    className="inputBoxCompanyDetails px-2"
                    value={companyInfoUpdate?.employeeCount}
                    onChange={handleChangeUpdate}
                    maxLength={6}
                  />
                 
                </div>
              </div>
              <div className="d-flex justify-content-end me-2">
              {errorMessageEmployeeCount &&(
                     <p style={{ color: "red", fontSize: "14px" }}>
                     {errorMessageEmployeeCount}
                   </p>
                  )}
                  </div>
              <div
                className="d-flex gap-3 align-items-center mt-2 pe-4"
                style={{ width: "100%" }}
              >
                <div
                  style={{ width: "30%" }}
                  className="editCompanyHeader text-end"
                >
                  Description
                </div>
                <div style={{ width: "70%" }}>
                  <input
                    type="text"
                    name="description"
                    className="inputBoxCompanyDetails px-2"
                    value={companyInfoUpdate?.description}
                    onChange={handleChangeUpdate}
                    onKeyDown={handleKeyDown}
                    maxLength={50}
                  />
                </div>
              </div>

              <div
                className="d-flex gap-3 align-items-center pe-4 mt-2"
                style={{ width: "100%" }}
              >
                <div
                  style={{ width: "30%" }}
                  className="editCompanyHeader text-end"
                >
                  Phone
                </div>
                <div style={{ width: "70%" }}>
                  <input
                    type="text"
                    name="phone"
                    pattern="[0-9]*"
                    className=" inputBoxCompanyDetails px-2"
                    value={companyInfoUpdate?.phone}
                    onChange={handleChangeUpdate}
                    onKeyDown={handleKeyDown}
                    maxLength={10}
                  />
                </div>
              </div>
              <div
                className="d-flex gap-3  align-items-center   mt-2 pe-4"
                style={{ width: "100%" }}
              >
                <div
                  style={{ width: "30%" }}
                  className="editCompanyHeader text-end"
                >
                  Mobile
                </div>
                <div style={{ width: "70%" }}>
                  <input
                    type="text"
                    pattern="[0-9]*"
                    name="mobile"
                    className="inputBoxCompanyDetails px-2"
                    value={companyInfoUpdate?.mobile}
                    onChange={handleChangeUpdate}
                    onKeyDown={handleKeyDown}
                    maxLength={10}
                  />
                </div>
              </div>

              <div
                className="d-flex gap-3 align-items-center   mt-2 pe-4"
                style={{ width: "100%" }}
              >
                <div
                  style={{ width: "30%" }}
                  className="editCompanyHeader text-end"
                >
                  Website
                </div>
                <div style={{ width: "70%" }}>
                  <input
                    type="text"
                    name="website"
                    className="inputBoxCompanyDetails px-2"
                    value={companyInfoUpdate?.website}
                    onChange={handleChangeUpdate}
                    onKeyDown={handleKeyDown}
                  />
                 
                </div>
              </div>
             <div className="ms-2 d-flex justify-content-center"> {websiteError && (
                    <p style={{ color: "red", fontSize: "14px" }}>
                      {websiteError}
                    </p>
                  )}
                  </div>

              <div
                className="d-flex gap-3 align-items-center mt-2 pe-4"
                style={{ width: "100%" }}
              >
                <div
                  style={{ width: "30%" }}
                  className="editCompanyHeader text-end"
                >
                  Fax
                </div>
                <div style={{ width: "70%" }}>
                  <input
                    type="text"
                    name="fax"
                    pattern="[0-9]*"
                    className=" inputBoxCompanyDetails px-2"
                    value={companyInfoUpdate?.fax}
                    onChange={handleChangeUpdate}
                    onKeyDown={handleKeyDown}
                    maxLength={12}
                  />
                </div>
              </div>
              <div className=" d-flex justify-content-center me-2">
                   {updateFaxError && (
                    <p style={{ color: "red", fontSize: "14px" }}>
                      {updateFaxError}
                    </p>
                  )}
                  </div>
              <div
                className="d-flex gap-3 align-items-center mt-2 pe-4"
                style={{ width: "100%" }}
              >
                <div
                  style={{ width: "30%" }}
                  className="editCompanyHeader text-end"
                >
                  Street
                </div>
                <div style={{ width: "70%" }}>
                  <input
                    type="text"
                    name="street"
                    className=" inputBoxCompanyDetails px-2"
                    value={companyInfoUpdate?.street}
                    onChange={handleChangeUpdate}
                    onKeyDown={handleKeyDown}
                    maxLength={30}
                  />
                </div>
              </div>

              <div
                className="d-flex gap-3 align-items-center mt-2 pe-4"
                style={{ width: "100%" }}
              >
                <div
                  style={{ width: "30%" }}
                  className="editCompanyHeader text-end"
                >
                  City
                </div>
                <div style={{ width: "70%" }}>
                  <input
                    type="text"
                    name="city"
                    className=" inputBoxCompanyDetails px-2"
                    value={companyInfoUpdate?.city}
                    onChange={handleChangeUpdate}
                    onKeyDown={handleKeyDown}
                    maxLength={30}
                  />
                </div>
              </div>
              <div className=" d-flex justify-content-center me-2">
                   {updateCityError && (
                    <p style={{ color: "red", fontSize: "14px" }}>
                      {updateCityError}
                    </p>
                  )}
                  </div>

              <div
                className="d-flex gap-3 align-items-center mt-2 pe-4"
                style={{ width: "100%" }}
              >
                <div
                  style={{ width: "30%" }}
                  className="editCompanyHeader text-end"
                >
                  State
                </div>
                <div style={{ width: "70%" }}>
                  <input
                    type="text"
                    name="state"
                    className=" inputBoxCompanyDetails px-2"
                    value={companyInfoUpdate?.state}
                    onChange={handleChangeUpdate}
                    onKeyDown={handleKeyDown}
                    maxLength={30}
                  />
                </div>
              </div>
              <div className=" d-flex justify-content-center me-2">
                   {updateStateError && (
                    <p style={{ color: "red", fontSize: "14px" }}>
                      {updateStateError}
                    </p>
                  )}
                  </div>

              <div
                className="d-flex gap-3 align-items-center mt-2 pe-4"
                style={{ width: "100%" }}
              >
                <div
                  style={{ width: "30%" }}
                  className="editCompanyHeader text-end"
                >
                  Zip Code
                </div>
                <div style={{ width: "70%" }}>
                  <input
                    type="text"
                    name="zip"
                    className="inputBoxCompanyDetails px-2"
                    value={companyInfoUpdate?.zip}
                    onChange={handleChangeUpdate}
                    onKeyDown={handleKeyDown}
                    maxLength={6}
                  />
                </div>
              </div>
              <div className="ms-2 d-flex justify-content-center">
                   {updateZipCodeError && (
                    <p style={{ color: "red", fontSize: "14px" }}>
                      {updateZipCodeError}
                    </p>
                  )}
                  </div>

              <div
                className="d-flex gap-3 align-items-center mt-2 pe-4"
                style={{ width: "100%" }}
              >
                <div
                  style={{ width: "30%" }}
                  className="editCompanyHeader text-end"
                >
                  Country
                </div>
                <div style={{ width: "70%" }}>
                  <input
                    type="text"
                    name="country"
                    className=" inputBoxCompanyDetails px-2"
                    value={companyInfoUpdate?.country}
                    onChange={handleChangeUpdate}
                    onKeyDown={handleKeyDown}
                  />
                </div>
              </div>
              <div className=" d-flex justify-content-center me-2">
                   {updateCountryError && (
                    <p style={{ color: "red", fontSize: "14px" }}>
                      {updateCountryError}
                    </p>
                  )}
                  </div>
            </div>

            <div className="d-flex gap-3 align-items-center justify-content-end mt-4 w-100">
              <button
                className="p-2 px-4 cancelRecycle"
                onClick={() => {
                  setEditCompanyModal(false);
                }}
              >
                Cancel
              </button>
              <button
                className="p-2 px-4 createContactButtonSave"
                onClick={updateCompanyDetails}
                disabled={apiLoader}
              >
                {apiLoader ? (
                  <Lottie
                    options={defaultOptions("white")}
                    height="24px"
                    width="36px"
                  />
                ) : (
                  "Save"
                )}
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default CompanySettings;

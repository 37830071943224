import React, { useEffect, useState } from "react";
import { URI } from "../../constants";
import axios from "axios";
import Dropdown from "react-bootstrap/Dropdown";
import filterIcon from "../../Assets/Frame (3).svg";
import Select from "react-select";
import groupIcon from "../../Assets/elements (4).svg";
// import hierachy from "../../Assets/hierarchy.svg";
import history from "../../Assets/history.svg";
import groupIcon2 from "../../Assets/Group 74.svg";
import down from "../../Assets/Down31.svg";
import up from "../../Assets/Up.svg";
import userSearch from "../../Assets/user-search-01.svg";
import LeadOwnerModal from "../../common/LeadOwnerModal";
import "./salesOrder.css";
import Pagination from "../../common/Pagination";
import dealsIcon from "../../Assets/De-Active/Deals.svg";
import ContactIcon from "../../Assets/De-Active/Contact.svg";
import accountsIcon from "../../Assets/De-Active/Account.svg";

import {
  pageOption,
  massOptions,
  taxOptions,
  invoiceColumns,
} from "../../common/CommonData";
import tableFilterIcon from "../../Assets/filterIcon.svg";
import filterLine from "../../Assets/filterLine.svg";
import filterMenu from "../../Assets/filterMenu.svg";
import pin from "../../Assets/Pin_column.svg";
import unPin from "../../Assets/Unpin.svg";
import arrowUp from "../../Assets/square_arrow_up.svg";
import arrowDown from "../../Assets/square_arrow_down.svg";
import cancel from "../../Assets/cancel_circle.svg";
import searchIcon from "../../Assets/search.svg";
import { Modal } from "react-bootstrap";
import attachIcon from "../../Assets/file-attachment.svg";
import openActivityIcon from "../../Assets/openActivity.svg";
import closeActivityIcon from "../../Assets/closeActivity.svg";
import Note from "../../common/commonRelatedList/Note";
import NoteBook from "../../Assets/notebook-01.svg";
import EditableInput from "../../common/EditableInput";
import updateIcon from "../../Assets/Group 71.svg";
import cancelIcon from "../../Assets/Group 72.svg";
import Clone from "../../Assets/Clone.svg";
import Share from "../../Assets/ShareIcon.svg";
import Delete from "../../Assets/DeleteIconNew.svg";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import "react-notifications/lib/notifications.css";
import OpenActivity from "../../common/commonRelatedList/OpenActivity";
import CloseActivity from "../../common/commonRelatedList/CloseActivity";
import ShareModal from "../../common/ShareModal";
import { useSelector } from "react-redux";
import Loader from "../../common/commonRelatedList/Loader";
import SalesNoDataImage from "../../Assets/SalesNoDataImage.svg";
import AccessModal from "../../common/AccessModal";
import ChangeOwnerModal from "../../common/commonRelatedList/ChangeOwnerModal";
import logoConvert from "../../Assets/logoConvert.svg";
import SearchIcon from "../../Assets/newSearch.svg";
import {
  leftArrow,
  rightAngleArrow,
  leftAngleArrow,
  editIcon,
  expandArrow,
  shrinkArrow,
} from "../../common/commonSVGs/commonSVGIcons.js";
import quotesIcon from "../../Assets/De-Active/Quotes.svg";
import { EXPLORER_URL } from "../../constants/DataConst.js";
import {
  validateCallSubject,
  validateCityIndia,
  validateStateIndia,
  validateCountryName,
  validateZipCode,
  validateCustomerNumber,
  removeExtraSpaces,
  validateCalcField,
  customCommonStyles,
  removeLeadingZeros,
} from "../../common/CommonComponent.js";
import moment from "moment";
import InvoicesCommonTable from "../../common/InvoicesCommonTable.js";
import useScrollToUndo from "../../hooks/useScrollToUndo.js.js";
import Lottie from "react-lottie";
import { defaultOptions } from "../../utils/defaultOptions.js";
import getHeaders from "../../common/ApiHeader.js";
import { handleApiError } from "../../utils/ApiUtils";
import { useNavigate } from "react-router-dom";
import CalculatorTooltip from "../../common/CalculatorTooltip.js";

function SalesOrder({
  setCurrentView,
  setInnerPage,
  innerPage,
  permissionList,
  notePermission,
  fetchContactInfo,
  fetchAccInfo,
  fetchQuoteInfo,
  setFetchSalesInfo,
  setPastViewInfo,
  pastViewInfo,
  currentView,
  fetchDealInfo,
  fetchSalesInfo,
  storeModuleId,
}) {
  //redux
  const navigate = useNavigate();
  const reduxStore = useSelector((state) => state.reduxState);
  const { loginUserId } = reduxStore;

  // const [innerPage, setInnerPage] = useState("list");
  // const [allSalesOrdersDetails, setAllSalesOrdersDetails] = useState([]); allSalesOrderDetails is not used anywhere so i am commenting
  const [editSalesOverview, setEditSalesOverview] = useState(false);
  const [showType, setShowType] = useState("");
  const [showOptionsLead, setShowOptionsLead] = useState(false);
  const [openActivities, setOpenActivities] = useState([]);
  const [openActivitiesCalls, setOpenActivitiesCalls] = useState([]);
  const [openActivitiesMeetings, setOpenActivitiesMeetings] = useState([]);
  const [closedActivitiesCall, setClosedActivitiesCall] = useState([]);
  const [closedActivitiesMeetings, setClosedActivitiesMeetings] = useState([]);
  const [closeActivities, setCloseActivities] = useState([]);
  const [mandatoryMsg, setMandatoryMsg] = useState("");
  const [custErrMsg, setCustErrMsg] = useState("");
  // const [dropdownTwo, setDropdownTwo] = useState(massOptions[0]); dropdownTwo is not used anywhere so i am commenting this
  const [accNameError, setAccNameError] = useState("");
  const [billingCityError, setBillingCityError] = useState("");
  const [billingStateError, setBillingStateError] = useState("");
  const [billingCountryError, setBillingCountryError] = useState("");
  const [billingCodeError, setBillingCodeError] = useState("");
  const [shippingCityError, setShippingCityError] = useState("");
  const [shippingStateError, setShippingStateError] = useState("");
  const [shippingCountryError, setShippingCountryError] = useState("");
  const [shippingCodeError, setShippingCodeError] = useState("");
  const [noteDeleteConfirmation, setNoteDeleteConfirmation] = useState(false);
  // const [selectedHeadingsInvoices, setSelectedHeadingsInvoices] =
  //   useState(invoiceColumns); setSelectedHeadingsInvoices is not used anywhere so i am commenting this

  const fieldDropdowns = [
    {
      name: "Sales Order Owner",
      isShowed: true,
      type: "other",
      value: "salesOrderOwner",
    },
    {
      name: "Subject",
      isShowed: true,
      type: "input",
      value: "subject",
    },
    {
      name: "Customer No.",
      isShowed: true,
      type: "input",
      value: "customerNo",
    },
    {
      name: "Quote Name",
      isShowed: true,
      type: "select",
      value: "quoteName",
    },

    {
      name: "Pending",
      isShowed: true,
      type: "input",
      value: "pending",
    },
    {
      name: "Carrier",
      isShowed: false,
      type: "dropdown",
      value: "carrier",
    },
    {
      name: "Sales Commission",
      isShowed: false,
      type: "input",
      value: "salesCommission",
    },
    {
      name: "Account Name",
      isShowed: false,
      type: "select",
    },
    {
      name: "Modified By",
      isShowed: false,
      type: "readOnly",
    },
    {
      name: "SO Number",
      isShowed: false,
      type: "readOnly",
      value: "soNumber",
    },

    {
      name: "Deal Name",
      isShowed: false,
      type: "select",
      value: "dealName",
    },

    {
      name: "Purchase Order",
      isShowed: false,
      type: "input",
    },
    {
      name: "Due Date",
      isShowed: false,
      type: "input",
    },
    {
      name: "Contact Name",
      isShowed: false,
      type: "select",
      value: "contactName",
    },
    {
      name: "Excise Duty",
      isShowed: false,
      type: "input",
    },
    {
      name: "Status",
      isShowed: false,
      type: "dropdown",
      value: "status",
    },
    {
      name: "Created By",
      isShowed: false,
      type: "readOnly",
    },
    {
      name: "Billing Street",
      isShowed: false,
      type: "input",
    },
    {
      name: "Billing City",
      isShowed: false,
      type: "input",
    },

    {
      name: "Billing State",
      isShowed: false,
      type: "input",
    },

    {
      name: "Billing Code",
      isShowed: false,
      type: "input",
    },

    {
      name: "Billing Country",
      isShowed: false,
      type: "input",
    },
    {
      name: "Shipping Street",
      isShowed: false,
      type: "input",
    },
    {
      name: "Shipping City",
      isShowed: false,
      type: "input",
    },
    {
      name: "Shipping State",
      isShowed: false,
      type: "input",
    },
    {
      name: "Shipping Code",
      isShowed: false,
      type: "input",
    },
    {
      name: "Shipping Country",
      isShowed: false,
      type: "input",
    },
    {
      name: "Sub Total",
      isShowed: false,
      type: "readOnly",
    },
    {
      name: "Grand Total",
      isShowed: false,
      type: "readOnly",
    },
    {
      name: "Discount",
      isShowed: false,
      type: "input",
    },
    {
      name: "Tax",
      isShowed: false,
      type: "readOnly",
    },
    {
      name: "Adjustment",
      isShowed: false,
      type: "readOnly",
    },
    {
      name: "Terms and Conditions",
      isShowed: false,
      type: "input",
      value: "termsAndConditions",
    },
    {
      name: "Description",
      isShowed: false,
      type: "input",
      value: "descriptionInformation",
    },
  ];

  const fieldIndividualDropdowns = [
    {
      name: "Sales Order Owner",
      isShowed: true,
      type: "other",
      value: "salesOrderOwner",
    },
    {
      name: "Subject",
      isShowed: true,
      type: "input",
      value: "subjectInfo",
    },
    {
      name: "Customer No.",
      isShowed: true,
      type: "input",
      value: "customerNoInfo",
    },
    {
      name: "Quote Name",
      isShowed: true,
      type: "readOnly",
      value: "quotesOwner",
    },

    {
      name: "Pending",
      isShowed: true,
      type: "input",
      value: "pendingInfo",
    },
    {
      name: "Carrier",
      isShowed: false,
      type: "dropdown",
      value: "carrierInfo",
    },
    {
      name: "Sales Commission",
      isShowed: false,
      type: "input",
      value: "salesCommissionInfo",
    },
    {
      name: "Account Name",
      isShowed: false,
      type: "readOnly",
    },
    {
      name: "Modified By",
      isShowed: false,
      type: "readOnly",
    },
    {
      name: "SO Number",
      isShowed: false,
      type: "readOnly",
    },

    {
      name: "Deal Name",
      isShowed: false,
      type: "readOnly",
      value: "dealName",
    },

    {
      name: "Purchase Order",
      isShowed: false,
      type: "input",
      value: "purchaseOrderInfo",
    },
    {
      name: "Due Date",
      isShowed: false,
      type: "input",
      value: "dueDateInfo",
    },
    {
      name: "Contact Name",
      isShowed: false,
      type: "readOnly",
      value: "contactName",
    },
    {
      name: "Excise Duty",
      isShowed: false,
      type: "input",
      value: "exciseDutyInfo",
    },
    {
      name: "Status",
      isShowed: false,
      type: "dropdown",
      value: "statusInfo",
    },
    {
      name: "Created By",
      isShowed: false,
      type: "readOnly",
    },
    {
      name: "Billing Street",
      isShowed: false,
      type: "input",
      value: "billingStreetInfo",
    },
    {
      name: "Billing City",
      isShowed: false,
      type: "input",
      value: "billingCityInfo",
    },

    {
      name: "Billing State",
      isShowed: false,
      type: "input",
      value: "billingStateInfo",
    },

    {
      name: "Billing Code",
      isShowed: false,
      type: "input",
      value: "billingCodeInfo",
    },

    {
      name: "Billing Country",
      isShowed: false,
      type: "input",
      value: "billingCountryInfo",
    },
    {
      name: "Shipping Street",
      isShowed: false,
      type: "input",
      value: "shippingStreetInfo",
    },
    {
      name: "Shipping City",
      isShowed: false,
      type: "input",
      value: "shippingCityInfo",
    },
    {
      name: "Shipping State",
      isShowed: false,
      type: "input",
      value: "shippingStateInfo",
    },
    {
      name: "Shipping Code",
      isShowed: false,
      type: "input",
      value: "shippingCodeInfo",
    },
    {
      name: "Shipping Country",
      isShowed: false,
      type: "input",
      value: "shippingCountryInfo",
    },
    {
      name: "Sub Total",
      isShowed: false,
      type: "readOnly",
    },
    {
      name: "Discount",
      isShowed: false,
      type: "input",
    },
    {
      name: "Tax",
      isShowed: false,
      type: "readOnly",
    },
    {
      name: "Adjustment",
      isShowed: false,
      type: "readOnly",
    },
    {
      name: "Terms and Conditions",
      isShowed: false,
      type: "input",
      value: "termsAndConditions",
    },
    {
      name: "Description",
      isShowed: false,
      type: "input",
      value: "descriptionInformation",
    },
  ];

  const options = reduxStore.filterOptions.salesOrder;
  const carrierOptions = [
    { value: "FedEX", label: "FedEX" },
    { value: "UPS", label: "UPS" },
    { value: "USPS", label: "USPS" },
    { value: "DHL", label: "DHL" },
    { value: "Blue Dart", label: "Blue Dart" },
  ];
  const stausOptions = [
    { value: "Created", label: "Created" },
    { value: "Approved", label: "Approved" },
    { value: "Delivered", label: "Delivered" },
    { value: "Cancelled", label: "Cancelled" },
  ];

  const [isLeadOwnerModal, setIsLeadOwnerModal] = useState(false);

  const userList = JSON.parse(sessionStorage.getItem("userList"));

  const [listVisible, setListVisible] = useState(false);
  const [selectedOption, setSelectedOption] = useState(options[0]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState(0); // Total number of items
  const [perPage, setPerPage] = useState(10);
  const [showHeadings, setShowHeadings] = useState(false);
  const [showSelectedFilter, setShowSelectedFilter] = useState("");
  const [filterType, setFilterType] = useState("");
  const [massUpdateValue, setMassUpdateValue] = useState("");
  const [showAccessModal, setShowAccessModal] = useState(false);
  const [selectedOwnerItem, setSelectedOwnerItem] = useState(userList);
  const [isChangeOwnerList, setIsChangeOwnerList] = useState(false);
  const [isOwnerSelect, setIsOwnerSelect] = useState(false);
  const [emailNotification, setEmailNotification] = useState(true);
  const [modalAccountDetails, setModalAccountDetail] = useState([]);
  const [modalContactDetails, setModalContactDetails] = useState([]);
  const [modalDealDetails, setModalDealDetails] = useState([]);
  const [modalQuotesDetails, setModalQuotesDetails] = useState([]);
  const [searchDeal, setSearchDeal] = useState("");
  const [searchContact, setSearchContact] = useState("");
  const [searchAccount, setSearchAccount] = useState("");
  const [searchQuote, setSearchQuote] = useState("");
  const [quotesModalSearch, setQuotesModalSearch] = useState([]);
  const [showTooltipExciseDuty, setShowTooltipExciseDuty] = useState(false);
  const [exciseDutyMsg, setExciseDutyMsg] = useState("");
  const [tooltipContentExciseDuty, setTooltipContentExciseDuty] = useState("");
  const [showTooltipSalesCommission, setShowTooltipSalesCommission] =
    useState(false);
  const [tooltipContentSalesCommission, setTooltipContentSalesCommission] =
    useState("");
  const [salesCommissionMsg, setSalesCommissionMsg] = useState("");
  const [apiLoader, setApiLoader] = useState(false);

  const minDate = moment("2000-01-01").format("YYYY-MM-DD");
  const maxDate = moment("2050-12-31").format("YYYY-MM-DD");

  const [currentPageQuote, setCurrentPageQuote] = useState(1);
  const [totalItemsQuote, setTotalItemsQuote] = useState(0);
  const [perPageQuote, setPerPageQuote] = useState(10);
  const [rows, setRows] = useState([
    {
      productId: "",
      productName: "",
      quantity: 1,
      listPrice: "",
      amount: 1,
      discount: 0,
      tax: 0,
      total: 1,
      description: "",
      discountType: 1,
      discountValue: 0,
    },
  ]);

  const totalPagesQuote = Math.ceil(Number(totalItemsQuote) / perPageQuote);

  const handlepageChangeQuote = (page) => {
    setCurrentPageQuote(page);
  };

  const handlePageDropDownChangeQuote = (selectedOption) => {
    setCurrentPageQuote(1);
    setPerPageQuote(parseInt(selectedOption.value));
  };

  const hasPermission = (action) => {
    const permission = permissionList.salesOrders.find(
      (p) => p.action === action
    );
    return permission ? permission.status === "TRUE" : false;
  };

  const handleCreateSalesOrder = () => {
    if (hasPermission("CREATE")) {
      setEditSalesOverview(false);
      setInnerPage("create");
      let discountedAmount = 0;
      let taxAmount = 0;
      let total = 0;
      if (productList.length > 0) {
        const { unitPrice, tax, productId, productName, description } =
          productList[0];

        discountedAmount = parseFloat(unitPrice) * parseFloat(unitPrice) - 0;
        taxAmount = (parseFloat(tax || 0) / 100) * discountedAmount;
        total = (discountedAmount + taxAmount).toFixed(2) || 0;
        setRows([
          {
            productId: productId,
            description: description,
            productName: productName,
            quantity: unitPrice || 1,
            listPrice: unitPrice || 0,
            amount: unitPrice * unitPrice || 0,
            discount: 0,
            tax: parseFloat(tax) || 0,
            total: isNaN(total) ? 0 : total,
            discountType: 1,
            discountValue: 0,
          },
        ]);
      }
      setSaleInfo({
        salesOrderOwnerId: "",
        loggedInUserId: loginUserId,
        subject: "",
        customerNo: "",
        quoteId: "",
        pending: "",
        carrier: carrierOptions[0],
        salesCommission: "",
        accountId: "",
        dealId: "",
        purchaseOrder: "",
        dueDate: "",
        contactId: "",
        exciseDuty: "",
        status: stausOptions[0],
        termsAndConditions: "",
        description: "",
        subTotal: isNaN(total) ? 0 : total,
        discount: 0,
        discountType: 1,
        discountValue: 0,
        tax: 0,
        adjustment: 0,
        grandTotal: isNaN(total) ? 0 : total,
        billingStreet: "",
        billingCity: "",
        billingState: "",
        billingCode: "",
        billingCountry: "",
        shippingStreet: "",
        shippingCity: "",
        shippingState: "",
        shippingCode: "",
        shippingCountry: "",
        quotesOwner: "",
        soNumber: "",
        purchaseItemDtlsDto: [],
      });

      setBillingAdd({
        billingStreet: "",
        billingCity: "",
        billingState: "",
        billingCode: "",
        billingCountry: "",
      });

      setShippingAdd({
        shippingStreet: "",
        shippingCity: "",
        shippingState: "",
        shippingCode: "",
        shippingCountry: "",
      });

      // setSelectedContact(contactList);
      // setSelectedOwnerItem(userList[2]);
      setSelectedOwnerItem(
        userList.find((user) => user.userId === loginUserId)
      );
      // setSelectedAccount(accountList.length > 0 && accountList[0]);
      // setSelectedDeal(dealList);
      // setSelectedQuote(quotesList)
      setSelectedCheckboxes([]);
    } else {
      setShowAccessModal(true);
    }
  };

  const handleEditSalesOrder = () => {
    if (hasPermission("EDIT")) {
      setInnerPage("create");
      setEditSalesOverview(true);
      setSalesInfoDetails(singleSalesDetails.salesInfo);
      // let selectedObj =
      //   singleSalesDetails.salesItemsTotalInfoDetails
      //     .saleOrdId === salesOrdId;
      setSaleInfo({
        ...singleSalesDetails.salesInfo,
        dueDate: fetchDateFormatConversion(
          singleSalesDetails.salesInfo.dueDate
        ),
        carrier: {
          label: singleSalesDetails.salesInfo.carrier,
          value: singleSalesDetails.salesInfo.carrier,
        },
        status: {
          label: singleSalesDetails.salesInfo.status,
          value: singleSalesDetails.salesInfo.status,
        },
        subjectInfo: singleSalesDetails.salesInfo.subject,
        purchaseOrderInfo: singleSalesDetails.salesInfo.purchaseOrder,
        dueDateInfo: singleSalesDetails.salesInfo.dueDate,
        exciseDutyInfo: singleSalesDetails.salesInfo.exciseDuty,
        salesCommissionInfo: singleSalesDetails.salesInfo.salesCommission,
        pendingInfo: singleSalesDetails.salesInfo.pending,
        customerNoInfo: singleSalesDetails.salesInfo.customerNo,
        carrierInfo: {
          label: singleSalesDetails.salesInfo.carrier,
          value: singleSalesDetails.salesInfo.carrier,
        },
        statusInfo: {
          label: singleSalesDetails.salesInfo.status,
          value: singleSalesDetails.salesInfo.status,
        },
        adjustment: singleSalesDetails.salesItemsTotDtls.adjustment,
        discount: singleSalesDetails.salesItemsTotDtls.discount,
        grandTotal: singleSalesDetails.salesItemsTotDtls.grandTotal,
        purchaseId: singleSalesDetails.salesItemsTotDtls.purchaseId,
        subTotal: singleSalesDetails.salesItemsTotDtls.subTotal,
        tax: singleSalesDetails.salesItemsTotDtls.tax,
        discountType: singleSalesDetails.salesItemsTotDtls.discountType || 1,
        discountValue: singleSalesDetails.salesItemsTotDtls.discountValue || 0,
      });
      setSalesItemsTotalInfoDetails(singleSalesDetails.salesItemsTotDtls);
      setBillingAdd({
        billingStreet: singleSalesDetails.salesInfo.billingStreet,
        billingCity: singleSalesDetails.salesInfo.billingCity,
        billingState: singleSalesDetails.salesInfo.billingState,
        billingCode: singleSalesDetails.salesInfo.billingCode,
        billingCountry: singleSalesDetails.salesInfo.billingCountry,
        billingStreetInfo: singleSalesDetails.salesInfo.billingStreet,
        billingCityInfo: singleSalesDetails.salesInfo.billingCity,
        billingStateInfo: singleSalesDetails.salesInfo.billingState,
        billingCodeInfo: singleSalesDetails.salesInfo.billingCode,
        billingCountryInfo: singleSalesDetails.salesInfo.billingCountry,
      });
      setShippingAdd({
        shippingStreet: singleSalesDetails.salesInfo.shippingStreet,
        shippingCity: singleSalesDetails.salesInfo.shippingCity,
        shippingState: singleSalesDetails.salesInfo.shippingState,
        shippingCode: singleSalesDetails.salesInfo.shippingCode,
        shippingCountry: singleSalesDetails.salesInfo.shippingCountry,
        shippingStreetInfo: singleSalesDetails.salesInfo.shippingStreet,
        shippingCityInfo: singleSalesDetails.salesInfo.shippingCity,
        shippingStateInfo: singleSalesDetails.salesInfo.shippingState,
        shippingCodeInfo: singleSalesDetails.salesInfo.shippingCode,
        shippingCountryInfo: singleSalesDetails.salesInfo.shippingCountry,
      });
      setShowOptionsSales(!showOptionsSales);
    } else {
      setShowAccessModal(true);
    }
  };

  const handleDeleteSalesOrder = () => {
    if (hasPermission("DELETE")) {
      setShowModalType("delete");
    } else {
      setShowAccessModal(true);
    }
  };

  const salesColumns = [
    {
      name: "Sales Order Owner",
      isChecked: true,
      value: "salesOrderOwner",
    },
    {
      name: "Subject",
      isChecked: true,
      value: "subject",
    },
    {
      name: "Status",
      isChecked: true,
      value: "status",
    },
    {
      name: "Deal Name",
      isChecked: true,
      value: "dealName",
    },
    {
      name: "Purchase Order",
      isChecked: false,
      value: "purchaseOrder",
    },
    {
      name: "Shipping City",
      isChecked: false,
      value: "shippingCity",
    },
    {
      name: "Shipping Code",
      isChecked: false,
      value: "shippingCode",
    },
    {
      name: "Shipping Street",
      isChecked: false,
      value: "shippingStreet",
    },
    {
      name: "Shipping Country",
      isChecked: false,
      value: "shippingCountry",
    },
    {
      name: "Billing Country",
      isChecked: false,
      value: "billingCountry",
    },
    {
      name: "Excise Duty",
      isChecked: false,
      value: "exciseDuty",
    },
    {
      name: "Billing Code",
      isChecked: false,
      value: "billingCode",
    },
    {
      name: "Contact Name",
      isChecked: true,
      value: "contactName",
    },
    {
      name: "Billing Street",
      isChecked: false,
      value: "billingStreet",
    },
    {
      name: "Billing State",
      isChecked: false,
      value: "billingState",
    },
    {
      name: "Account Name",
      isChecked: false,
      value: "accountName",
    },
    {
      name: "Carrier",
      isChecked: false,
      value: "carrier",
    },
    {
      name: "Shipping State",
      isChecked: false,
      value: "shippingState",
    },
    {
      name: "Billing City",
      isChecked: false,
      value: "billingCity",
    },
    {
      name: "Due Date",
      isChecked: false,
      value: "dueDate",
    },
    {
      name: "Customer No.",
      type: "input",
      value: "customerNo",
    },
    {
      name: "Pending",
      type: "input",
      value: "pendingInfo",
    },
    {
      name: "Sales Commission",
      type: "input",
      value: "salesCommission",
    },
  ];

  const [popoutType, setPopoutType] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [discountType, setDiscountType] = useState("percentage");

  const [shareUserList, setShareUserList] = useState([]);
  const [shareRoleList, setShareRoleList] = useState([]);
  const [shareGroupList, setShareGroupList] = useState([]);
  const [sharePublicList, setSharePublicList] = useState([]);
  const [shareInfoModal, setShareInfoModal] = useState(false);
  const [shareMemberIds, setShareMemberIds] = useState([]);

  const discountPopup = (index) => {
    return (
      <div
        className="position-absolute w-100 popout_box rounded-1 p-3 bg-white"
        style={{ left: selectedProductIndex === "" && "-4rem" }}
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <h6 className="fw-bold">
          {popoutType === "tax" ? "Select Tax" : "Set Discount"}
        </h6>
        {popoutType === "discount" ? (
          <>
            <div className="d-flex align-items-center my-3">
              <input
                type="radio"
                id="percentage"
                name="discount"
                value="percentage"
                className="cursorPointer"
                onChange={(e) => {
                  setDiscountType(e.target.checked ? "percentage" : "");
                }}
                checked={discountType === "percentage"}
              />
              <label className="ms-1">Percentage</label>
              {discountType === "percentage" ? (
                <div className="w-50 d-flex">
                  <input
                    type="text"
                    className="w-75 ms-2 me-1"
                    name="percentage"
                    maxLength={3}
                    onChange={(e) => {
                      setDiscountObj({
                        type: 1,
                        value: e.target.value,
                      });
                    }}
                    value={discountObj.value}
                  />
                  <span>%</span>
                </div>
              ) : null}
            </div>
            <div className="d-flex align-items-center my-2">
              <input
                type="radio"
                id="price"
                name="discount"
                value="price"
                className="cursorPointer"
                onChange={(e) => {
                  setDiscountType(e.target.checked ? "price" : "");
                }}
                checked={discountType === "price"}
              />
              <label className="ms-1">Direct Price Reduction</label>
              {discountType === "price" ? (
                <input
                  type="text"
                  className="w-25 ms-2"
                  name="percentage"
                  value={discountObj.value}
                  onChange={(e) => {
                    setDiscountObj({
                      type: 2,
                      value: e.target.value,
                    });
                  }}
                />
              ) : null}
            </div>
          </>
        ) : (
          <div className="my-3">
            <Select
              value={selectedTax}
              menuPlacement="auto"
              onChange={(selectedOption) => {
                setSelectedTax(selectedOption);
              }}
              options={taxOptions}
              styles={createLeadStyles}
              isSearchable={false}
            />
          </div>
        )}

        <div className="pt-2 d-flex gap-3">
          <button
            className="btn btn-primary px-3 py-1 opacity-75"
            onClick={() => {
              const newRows = [...rows];
              let discountedAmount;
              let taxAmount;
              // let total = parseFloat(newRows[index].amount);

              if (selectedProductIndex === "") {
                if (popoutType === "tax") {
                  const total = saleInfo.subTotal - saleInfo.discount;
                  const taxValue = (selectedTax.value / 100) * total;
                  setSaleInfo((prevPurchaseInfo) => ({
                    ...prevPurchaseInfo,
                    tax: selectedTax.value,
                    grandTotal: (
                      parseFloat(total) +
                      parseFloat(taxValue || 0) +
                      parseFloat(saleInfo.adjustment)
                    ).toFixed(2),
                  }));
                } else {
                  const discount =
                    discountObj.type === 1
                      ? (discountObj.value / 100) *
                        parseFloat(saleInfo.subTotal)
                      : discountObj.value;
                  const total = saleInfo.subTotal - discount;
                  const taxValue = (saleInfo.tax / 100) * total;

                  setSaleInfo((prevPurchaseInfo) => ({
                    ...prevPurchaseInfo,
                    discount: parseFloat(discount).toFixed(2),
                    discountType: discountObj.type,
                    discountValue: parseFloat(discountObj.value).toFixed(2),
                    grandTotal: (
                      parseFloat(total) +
                      parseFloat(taxValue || 0) +
                      parseFloat(saleInfo.adjustment)
                    ).toFixed(2),
                  }));
                }
              } else {
                const discount =
                  discountObj.type === 1
                    ? (discountObj.value / 100) *
                      parseFloat(newRows[index].amount)
                    : discountObj.value;
                discountedAmount =
                  parseFloat(newRows[index].amount || 0) - discount;
                taxAmount =
                  (parseFloat(newRows[index].tax || 0) / 100) *
                  discountedAmount;
                // newRows[index].amount = discountedAmount;
                newRows[index].total = (discountedAmount + taxAmount).toFixed(
                  2
                );
                newRows[index].discountType = discountObj.type;
                newRows[index].discountValue = discountObj.value;
                newRows[index].discount = discount;

                setRows(newRows);
                const subTotalValue = newRows.reduce(
                  (sum, product) => parseFloat(sum) + parseFloat(product.total),
                  0
                );
                setSaleInfo((prevPurchaseInfo) => ({
                  ...prevPurchaseInfo,
                  subTotal: subTotalValue || 0,
                  grandTotal:
                    subTotalValue - parseFloat(saleInfo.discount || 0),
                }));
              }
              setSelectedProductIndex("");
              setPopoutType("");
            }}
          >
            Done
          </button>
          <button
            className="btn btn-light border"
            onClick={() => {
              setSelectedProductIndex("");
              setPopoutType("");
            }}
          >
            Cancel
          </button>
        </div>
      </div>
    );
  };

  const [pinnedColumn, setPinnedColumn] = useState("Sales Order Owner");
  const [selectedHeadings, setSelectedHeadings] = useState(salesColumns);
  const [savingHeadings, setSavingHeadings] = useState(salesColumns);
  const [modalType, setShowModalType] = useState("");
  const [searchColumn, setSearchColumn] = useState("");
  const [filteredSalesDetails, setFilteredSalesDetails] = useState([]);
  const [selectedField, setSelectedField] = useState("");

  const handleColumnCheckboxChange = (name, isChecked) => {
    setSavingHeadings((prevHeadings) =>
      prevHeadings.map((heading) =>
        heading.name === name ? { ...heading, isChecked } : heading
      )
    );
  };
  const handlePinColumn = (name) => {
    setPinnedColumn(name);
    const updatedColumns = [...selectedHeadings];
    const clickedColumnIndex = updatedColumns.findIndex(
      (column) => column.name === name
    );
    if (clickedColumnIndex !== -1) {
      const clickedColumn = updatedColumns.splice(clickedColumnIndex, 1)[0];
      updatedColumns.unshift(clickedColumn);
      setSelectedHeadings(updatedColumns);
    }
  };

  const sortedColumnsData = (type, headingName) => {
    const sortedData = [...salesDetails];
    if (type === "asc") {
      sortedData.sort((a, b) => {
        if (
          typeof a[headingName] === "string" &&
          typeof b[headingName] === "string"
        ) {
          return a[headingName].localeCompare(b[headingName]);
        }
        return a[headingName] - b[headingName];
      });
    } else if (type === "desc") {
      sortedData.sort((a, b) => {
        if (
          typeof a[headingName] === "string" &&
          typeof b[headingName] === "string"
        ) {
          return b[headingName].localeCompare(a[headingName]);
        }
        return b[headingName] - a[headingName];
      });
    }
    setFilteredSalesDetails(sortedData);
  };

  const handleChange = (selectedOption) => {
    setSelectedOption(selectedOption);
    getAllSales(selectedOption);
  };

  const totalPages = Math.ceil(Number(totalItems) / perPage);

  const handlePageDropDowmChange = (selectedOption) => {
    setCurrentPage(1);
    setPerPage(parseInt(selectedOption.value));
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const fieldDropdownStyles = {
    menu: (base) => ({
      ...base,
      width: "100%",
    }),
    option: (provided) => ({
      ...provided,
      // backgroundColor: "#EAEAEA",
      color: "#000",
      // backgroundColor: 'rgba(33, 119, 254, 0.1)',
      width: "100%",
    }),
    control: (baseStyles) => ({
      ...baseStyles,
      border: 0,
      // backgroundColor: 'rgba(33, 119, 254, 0.1)',
      height: "20px",
      borderRadius: "10px",
      width: "100%",
      boxShadow: "none",
    }),
    input: (base) => ({
      ...base,
      color: "#FFFFFF",
    }),
    singleValue: (defaultStyles) => ({ ...defaultStyles, color: "#000" }),

    menuList: (provided) => ({
      ...provided,
      paddingTop: 0,
      paddingBottom: 0,
      "&::-webkit-scrollbar": {
        width: 5,
      },

      "&::-webkit-scrollbar-thumb": {
        background: "#316AFF",
        borderRadius: "10px",
      },
    }),
  };

  const createLeadStyles = {
    menu: (base) => ({
      ...base,
      width: "60%",
    }),
    option: (provided) => ({
      ...provided,
      // backgroundColor: "#EAEAEA",
      color: "#000",
      // backgroundColor: 'rgba(33, 119, 254, 0.1)',
      width: "100%",
    }),
    control: (baseStyles) => ({
      ...baseStyles,
      border: "1px solid #b9b9b9b0",
      backgroundColor: "#F3F6FB",
      height: "40px",
      borderRadius: "4px",
      width: "60%",
      boxShadow: "none",
    }),
    input: (base) => ({
      ...base,
      color: "#FFFFFF",
    }),
    singleValue: (defaultStyles) => ({ ...defaultStyles, color: "#000" }),

    menuList: (provided) => ({
      ...provided,
      paddingTop: 0,
      paddingBottom: 0,
      "&::-webkit-scrollbar": {
        width: 5,
      },

      "&::-webkit-scrollbar-thumb": {
        background: "#316AFF",
        borderRadius: "10px",
      },
    }),
  };

  const [productList, setProductList] = useState([]);

  const getAllProduct = async () => {
    const requestBody = {
      loggedInUserId: loginUserId,
      pageNo: 1,
      pageSize: 20,
    };
    const headersResult = getHeaders(true);

    if (headersResult.error) {
      navigate("../");
      return;
    }
    await axios
      .post(URI.getAllProduct, requestBody, {
        headers: headersResult,
      })
      .then((response) => {
        if (response.data.status === 200) {
          if (response.data.data.allProductDetails.length > 0) {
            setProductList(response.data.data.allProductDetails);
            setRows([
              {
                description:
                  response.data.data.allProductDetails[0].description,
                productId: response.data.data.allProductDetails[0].productId,
                productName:
                  response.data.data.allProductDetails[0].productName,
                quantity:
                  response.data.data.allProductDetails[0].unitPrice || 1,
                listPrice:
                  response.data.data.allProductDetails[0].unitPrice || 0,
                amount:
                  response.data.data.allProductDetails[0].unitPrice *
                    response.data.data.allProductDetails[0].unitPrice || 0,
                discount: 0,
                tax:
                  parseFloat(response.data.data.allProductDetails[0].tax) || 0,
                total: isNaN(response.data.data.allProductDetails[0].total)
                  ? 0
                  : response.data.data.allProductDetails[0].total,
                discountType: 1,
                discountValue: 0,
              },
            ]);
          }
        }
      })
      .catch(async (error) => {
        handleApiError(error, navigate);
      });
  };

  const [selectedProduct, setSelectedProduct] = useState({
    productName: "",
    productId: "",
  });

  const [selectedProductIndex, setSelectedProductIndex] = useState("");
  const [discountObj, setDiscountObj] = useState({
    type: 1,
    value: 0,
  });
  const [selectedTax, setSelectedTax] = useState(taxOptions[0]);

  const handleAddRow = () => {
    const { description, productId, unitPrice, tax, productName } =
      productList[0];
    const discountedAmount = parseFloat(unitPrice) * parseFloat(unitPrice) - 0;
    const taxAmount = (parseFloat(tax || 0) / 100) * discountedAmount;
    const total = (discountedAmount + taxAmount).toFixed(2) || 0;
    setRows([
      ...rows,
      {
        // slNo: 1,
        productId: productId,
        description: description,
        productName: productName,
        quantity: unitPrice || 1,
        listPrice: unitPrice || 0,
        amount: unitPrice * unitPrice || 0,
        discount: 0,
        tax: parseFloat(tax) || 0,
        total: isNaN(total) ? 0 : total,
        discountType: 1,
        discountValue: 0,
      },
    ]);

    const productsTotal = rows
      .reduce(
        (sum, product) =>
          parseFloat(sum) +
          parseFloat(isNaN(product.total) ? 0 : product.total),
        0
      )
      ?.toFixed(2);

    const subTotalValue =
      Number(productsTotal) + Number(isNaN(total) ? 0 : total);

    setSaleInfo((prevPurchaseInfo) => ({
      ...prevPurchaseInfo,
      subTotal: subTotalValue,
      grandTotal: parseFloat(
        subTotalValue - Number(saleInfo.discount || 0)
      ).toFixed(2),
    }));
  };

  const handleRowChange = (index, e) => {
    const { name, value } = e.target;
    let discountedAmount;
    let taxAmount = 0;
    const newRows = [...rows];
    if (name === "quantity") {
      const getValue = value.replace(/[^0-9+\-.]+/g, "");
      newRows[index].quantity = getValue;
      const useValue = getValue === "" ? 0 : getValue;
      discountedAmount =
        useValue * parseFloat(newRows[index].listPrice) -
        parseFloat(newRows[index].discount);
      taxAmount =
        (parseFloat(newRows[index].tax || 0) / 100) * discountedAmount;

      newRows[index].amount =
        (useValue * parseFloat(newRows[index].listPrice)).toFixed(2) || 0;
      newRows[index].total =
        (discountedAmount + taxAmount).toFixed(2) || "0.00";
    } else if (name === "listPrice") {
      const getValue = value.replace(/[^0-9+\-.]+/g, "");
      newRows[index].listPrice = getValue;
      const useValue = getValue === "" ? 0 : getValue;
      discountedAmount =
        useValue * parseFloat(newRows[index].quantity) -
        parseFloat(newRows[index].discount);
      taxAmount =
        (parseFloat(newRows[index].tax || 0) / 100) * discountedAmount;

      newRows[index].amount = (
        useValue * parseFloat(newRows[index].quantity)
      ).toFixed(2);

      newRows[index].total =
        (discountedAmount + taxAmount).toFixed(2) || "0.00";
    } else if (name === "description") {
      newRows[index][name] = value;
    }

    setRows(newRows);

    const subTotalValue =
      newRows.reduce(
        (sum, product) => parseFloat(sum) + parseFloat(product.total),
        0
      ) || 0;
    setSaleInfo((prevPurchaseInfo) => ({
      ...prevPurchaseInfo,
      subTotal: parseFloat(subTotalValue).toFixed(2) || 0,
      grandTotal: parseFloat(
        subTotalValue - parseFloat(saleInfo.discount || 0)
      ).toFixed(2),
    }));
  };

  const handleDeleteRow = (index) => {
    if (rows.length === 1) {
      return; // Prevent deleting the last row
    }
    const newRows = [...rows];
    newRows.splice(index, 1);
    setRows(newRows);

    const subTotalValue = newRows.reduce(
      (sum, product) => parseFloat(sum) + parseFloat(product.total),
      0
    );
    setSaleInfo((prevPurchaseInfo) => ({
      ...prevPurchaseInfo,
      subTotal: subTotalValue || 0,
      grandTotal: parseFloat(
        subTotalValue - parseFloat(saleInfo.discount || 0)
      ).toFixed(2),
    }));
  };

  const handleOwnerInputClick = () => {
    setListVisible(!listVisible);
  };

  const [listVisibleType, setListVisibleType] = useState("");

  const handleUserItemClick = (field, item, i) => {
    !["create", "clone"].includes(innerPage) &&
      handleUpdateIdClick(field, item);
    // setSelectedItem(item);
    setListVisibleType(field);
    field === "contactName"
      ? setSelectedContact(item)
      : field === "accountName"
      ? setSelectedAccount(item)
      : field === "quotesOwner"
      ? setSelectedQuote(item)
      : field === "dealName"
      ? setSelectedDeal(item)
      : setSelectedOwnerItem(item);
    if (field === "productId") {
      const newRows = [...rows];
      newRows[i] = {
        ...newRows[i],
        productId: item.productId,
        productName: item.productName,
        description: item.description,
        quantity: item.unitPrice || 1,
        listPrice: item.unitPrice,
        amount: item.unitPrice * item.unitPrice,
        discount: 0,
        tax: parseFloat(item.tax || 0),
        total: returnTotalValue(item),
        discountType: discountType === "percentage" ? 1 : 2,
        discountValue: 0,
      };
      setRows(newRows);
      const subTotalValue = newRows.reduce(
        (sum, product) => parseFloat(sum) + parseFloat(product.total),
        0
      );
      setSaleInfo((prevPurchaseInfo) => ({
        ...prevPurchaseInfo,
        subTotal: parseFloat(subTotalValue).toFixed(2) || 0,
        grandTotal:
          parseFloat(subTotalValue).toFixed(2) -
          parseFloat(saleInfo.discount || 0),
      }));
    }
    if (field === "dealName") {
      getAllAssignModules(
        "DEALS",
        ["CONTACTS", "ACCOUNTS", "QUOTES"],
        item.dealId
      ); // Adjust the parameters as needed
    } else if (field === "contactName") {
      getAllAssignModules(
        "CONTACTS",
        ["DEALS", "ACCOUNTS", "QUOTES"],
        item.contactId
      );
    } else if (field === "accountName") {
      getAllAssignModules(
        "ACCOUNTS",
        ["DEALS", "CONTACTS", "QUOTES"],
        item.accountId
      );
    } else if (field === "quotesOwner") {
      getAllAssignModules(
        "QUOTES",
        ["DEALS", "CONTACTS", "ACCOUNTS"],
        item.quoteId
      );
    } else {
      // alert(field, "error");
    }
  };

  const handleSelectedLeadChange = (value) => {
    // setSelectedItem(value); // Update the selected lead value
    setSelectedOwnerItem(value);
    listVisibleType === "contactName"
      ? setSelectedContact(value)
      : listVisibleType === "accountName"
      ? setSelectedAccount(value)
      : listVisibleType === "quotesOwner"
      ? setSelectedQuote(value)
      : listVisibleType === "dealName"
      ? setSelectedDeal(value)
      : listVisibleType === "productId"
      ? setSelectedProduct(value)
      : setSelectedOwnerItem(value);
  };

  const handleSelectedChangeOwner = (value) => {
    setSelectedOwnerItem(value);
  };

  const [singleSalesDetails, setSingleSalesDetails] = useState({
    salesContactDetails: {},
    salesInfoDetails: {},
    salesItemsInfoDetails: [],
    salesItemsTotalInfoDetails: {},
    salesAccountDetails: {},
    salesDealsDetails: {},
  });

  const [saleInfo, setSaleInfo] = useState({
    salesOrderOwnerId: "",
    loggedInUserId: loginUserId,
    subject: "",
    customerNo: "",
    quoteId: "",
    pending: "",
    carrier: carrierOptions[0],
    salesCommission: "",
    accountId: "",
    dealId: "",
    purchaseOrder: "",
    dueDate: new Date().toISOString().split("T")[0],
    contactId: "",
    exciseDuty: "",
    status: stausOptions[0],
    termsAndConditions: "",
    description: "",
    subTotal: 0,
    discount: 0,
    discountType: 1,
    discountValue: 0,
    tax: 0,
    adjustment: 0,
    grandTotal: 0,
    billingStreet: "",
    billingCity: "",
    billingState: "",
    billingCode: "",
    billingCountry: "",
    shippingStreet: "",
    shippingCity: "",
    shippingState: "",
    shippingCode: "",
    shippingCountry: "",
    purchaseItemDtlsDto: [],
    subjectInfo: "",
    purchaseOrderInfo: "",
    dueDateInfo: "",
    exciseDutyInfo: "",
    salesCommissionInfo: "",
    customerNoInfo: "",
    pendingInfo: "",
    carrierInfo: carrierOptions[0],
    statusInfo: stausOptions[0],
  });

  const [salesInfoDetails, setSalesInfoDetails] = useState({
    salesOrderOwnerId: "",
    loggedInUserId: loginUserId,
    subject: "",
    customerNo: "",
    quoteId: "",
    pending: "",
    carrier: {
      label: "",
      value: "",
    },
    salesCommission: "",
    accountId: "",
    dealId: "",
    purchaseOrder: "",
    dueDate: "",
    contactId: "",
    exciseDuty: "",
    status: {
      label: "",
      value: "",
    },
    termsAndConditions: "",
    description: "",
    subTotal: 0,
    discount: 0,
    discountType: 1,
    discountValue: 0,
    tax: 0,
    adjustment: 0,
    grandTotal: 0,
    billingStreet: "",
    billingCity: "",
    billingState: "",
    billingCode: "",
    billingCountry: "",
    shippingStreet: "",
    shippingCity: "",
    shippingState: "",
    shippingCode: "",
    shippingCountry: "",
    quotesOwner: "",
    soNumber: "",
    purchaseOrderInfo: "",
    dueDateInfo: "",
    exciseDutyInfo: "",
    salesCommissionInfo: "",
    subjectInfo: "",
    customerNoInfo: "",
    pendingInfo: "",
    carrierInfo: {
      label: "",
      value: "",
    },
    statusInfo: {
      label: "",
      value: "",
    },
    billingStreetInfo: "",
    billingCityInfo: "",
    billingStateInfo: "",
    billingCodeInfo: "",
    billingCountryInfo: "",
    shippingStreetInfo: "",
    shippingCityInfo: "",
    shippingStateInfo: "",
    shippingCodeInfo: "",
    shippingCountryInfo: "",
  });

  // eslint-disable-next-line no-unused-vars
  const [salesItemsTotalInfoDetails, setSalesItemsTotalInfoDetails] = useState(
    []
  );

  const [billingAdd, setBillingAdd] = useState({
    billingStreet: "",
    billingCity: "",
    billingState: "",
    billingCode: "",
    billingCountry: "",
    billingStreetInfo: "",
    billingCityInfo: "",
    billingStateInfo: "",
    billingCodeInfo: "",
    billingCountryInfo: "",
  });
  const [shippingAdd, setShippingAdd] = useState({
    shippingStreet: "",
    shippingCity: "",
    shippingState: "",
    shippingCode: "",
    shippingCountry: "",
    shippingStreetInfo: "",
    shippingCityInfo: "",
    shippingStateInfo: "",
    shippingCodeInfo: "",
    shippingCountryInfo: "",
  });

  const copyBillingToShipping = () => {
    setShippingAdd({
      shippingStreet: billingAdd.billingStreet || shippingAdd.shippingStreet,
      shippingCity: billingAdd.billingCity || shippingAdd.shippingCity,
      shippingState: billingAdd.billingState || shippingAdd.shippingState,
      shippingCode: billingAdd.billingCode || shippingAdd.shippingCode,
      shippingCountry: billingAdd.billingCountry || shippingAdd.shippingCountry,
    });
  };

  const copyShippingToBilling = () => {
    setBillingAdd({
      billingStreet: shippingAdd.shippingStreet || billingAdd.billingStreet,
      billingCity: shippingAdd.shippingCity || billingAdd.billingCity,
      billingState: shippingAdd.shippingState || billingAdd.billingState,
      billingCode: shippingAdd.shippingCode || billingAdd.billingCode,
      billingCountry: shippingAdd.shippingCountry || billingAdd.billingCountry,
    });
  };

  const [editingFields, setEditingFields] = useState({
    salesOrderOwnerId: false,
    loggedInUserId: false,
    subject: false,
    customerNo: false,
    quoteId: false,
    pending: false,
    carrier: false,
    salesCommission: false,
    accountId: false,
    dealId: false,
    purchaseOrder: false,
    dueDate: false,
    contactId: false,
    exciseDuty: false,
    status: false,
    termsAndConditions: false,
    description: false,
    subTotal: false,
    discount: false,
    tax: false,
    adjustment: false,
    grandTotal: false,
    billingStreet: false,
    billingCity: false,
    billingState: false,
    billingCode: false,
    billingCountry: false,
    shippingStreet: false,
    shippingCity: false,
    shippingState: false,
    shippingCode: false,
    shippingCountry: false,
    quotesOwner: false,
    soNumber: false,
    statusInfo: false,
  });

  /* eslint-disable no-useless-escape */

  const handleKeyPress = (event) => {
    const allowedCharacters = /[0-9.\+\-\*\/\(\)]/;
    const char = event.key;
    if (!allowedCharacters.test(char)) {
      event.preventDefault();
    }
  };

  const sanitizeExpression = (expression) => {
    const match = expression.match(/^\s*-?\d+(\.\d+)?([+\-*\/]-?\d+(\.\d+)?)*/);

    if (match) {
      return match[0].trim();
    } else {
      return "Invalid input";
    }
  };

  /* eslint-enable no-useless-escape */

  const handleChangeSales = (e) => {
    const { name, value } = e.target;

    if (name === "exciseDuty") {
      handleTooltipForSales(
        value,
        setShowTooltipExciseDuty,
        setTooltipContentExciseDuty
      );
    } else if (name === "salesCommission") {
      handleTooltipForSales(
        value,
        setShowTooltipSalesCommission,
        setTooltipContentSalesCommission
      );
    }

    setSaleInfo((prevSalesInfo) => ({
      ...prevSalesInfo,
      [name]: value,
    }));
  };

  /* eslint-disable no-useless-escape */

  const handleTooltipForSales = (value, setShowTooltip, setTooltipContent) => {
    const regex = /^[\d.\+\-\*\/\(\)]+$/;

    if (regex.test(value)) {
      try {
        const sanitizedValue = removeLeadingZeros(value);
        const previewResult = eval(sanitizedValue);
        setTooltipContent(previewResult.toString());
      } catch (error) {
        setTooltipContent(sanitizeExpression(value));
      }
      setShowTooltip(true);
    } else {
      setShowTooltip(false);
    }
  };

  const calculateResultForSales = (field) => {
    const value = saleInfo[field].trim();
    const sanitizedValue = value;
    const regex = /^[\d.\+\-\*\/\(\)]+$/;

    if (regex.test(sanitizedValue)) {
      try {
        const cleanedValue = removeLeadingZeros(sanitizedValue);
        let result = eval(cleanedValue);
        if (typeof result === "number" && result % 1 !== 0) {
          const decimalPart = result.toString().split(".")[1];
          if (decimalPart && decimalPart.length > 2) {
            result = parseFloat(result).toFixed(2);
          }
        }
        setSaleInfo((prevSalesInfo) => ({
          ...prevSalesInfo,
          [field]: result.toString(),
        }));
      } catch (error) {
        setSaleInfo((prevSalesInfo) => ({
          ...prevSalesInfo,
          [field]: sanitizeExpression(value),
        }));
      }
    }
  };
  /* eslint-enable no-useless-escape */

  const handleBlurForSales = (field) => {
    calculateResultForSales(field);
    hideAllTooltips();
  };

  const requiredDateFormatConversion = (inputDate) =>
    moment(inputDate, ["DD-MM-YYYY", "YYYY-MM-DD"]).format("DD-MM-YYYY");

  const fetchDateFormatConversion = (inputDate) =>
    moment(inputDate, ["DD-MM-YYYY", "YYYY-MM-DD"]).format("YYYY-MM-DD");

  const hideAllTooltips = () => {
    setShowTooltipExciseDuty(false);
    setShowTooltipSalesCommission(false);
  };

  const tooltipStyles = {
    position: "absolute",
    top: "100%",
    left: "0",
    backgroundColor: "#f9f9f9",
    border: "1px solid #d3d3d3",
    padding: "5px",
    borderRadius: "3px",
    boxShadow: "0 2px 5px rgba(0,0,0,0.2)",
    zIndex: 1000,
    height: "fit-content",
    width: "fit-content",
  };

  const handleIndividualInputChange = (field, value) => {
    if (
      field === "salesCommission" ||
      field === "salesCommissionInfo" ||
      field === "exciseDuty" ||
      field === "exciseDutyInfo"
    ) {
      const numericValue = value.replace(/[^0-9]/g, "").slice(0, 10);
      setSalesInfoDetails((prevFields) => ({
        ...prevFields,
        [field]: numericValue,
      }));
    } else {
      setSalesInfoDetails((prevState) => ({
        ...prevState,
        [field]: value,
      }));
    }
  };

  const handleCancelClick = (field) => {
    setEditingFields((prevFields) => ({
      ...prevFields,
      [field]: false,
    }));

    setSalesInfoDetails((prevFields) => ({
      ...prevFields,
      [field]: ["carrier", "status", "statusInfo", "carrierInfo"].includes(
        field
      )
        ? {
            value: saleInfo[field],
            label: saleInfo[field],
          }
        : saleInfo[field] || "",
    }));
  };

  const handleEditClick = (field) => {
    setEditingFields((prevFields) => ({
      ...prevFields,
      [field]: true,
    }));
  };

  const tick = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      fill="currentColor"
      className="bi bi-check2"
      viewBox="0 0 16 16"
    >
      <path d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0" />
    </svg>
  );

  const [salesDetails, setSalesDetails] = useState([]);
  const [timeLineDataList, setTimeLineDataList] = useState();
  const [itemTotDtls, setItemTotDtls] = useState();
  const [itemsInfo, setItemsInfo] = useState([]);
  const [salesOrdId, setSalesOrdId] = useState("");
  const [salesIndivPage, setSalesIndivPage] = useState("overview");
  const [salesOverviewDetails, setSalesOverviewDetails] = useState({});
  const [fieldLabels, setFieldLabels] = useState(fieldDropdowns);
  const [showSearchFields, setShowSearchFields] = useState(fieldDropdowns);
  const [clickedDropdownObj, setClickedDropdownObj] = useState({
    label: "",
    value: "",
  });
  const [searchField, setSearchField] = useState("");
  // const [activeTab, setActiveTab] = useState("note"); activeTab is not used anywhere so i am commenting this
  const [selectedSalesId, setSelectedSalesId] = useState(0);
  const [shareModal, setShareModal] = useState(false);
  useScrollToUndo(pastViewInfo, setPastViewInfo);

  const handleChangeSelectDropDown = (selectedOption, field) => {
    setSalesInfoDetails((prevLeadInfo) => ({
      ...prevLeadInfo,
      [field]: selectedOption,
    }));

    setSaleInfo((prevLeadInfo) => ({
      ...prevLeadInfo,
      [field]: selectedOption,
    }));
  };

  const insertNote = () => {
    const formData = new FormData();
    formData.append("moduleId", salesOrdId);
    formData.append("loggedInUserId", loginUserId);
    formData.append("moduleName", "SALES");
    formData.append("noteDescription", note);
    formData.append("noteTitle", addTitle);

    selectedNoteFiles.forEach((file) => {
      formData.append("noteImages", file);
    });

    const headersResult = getHeaders(true);

    if (headersResult.error) {
      navigate("../");
      return;
    }

    axios
      .post(URI.insertNote, formData, {
        headers: headersResult,
      })
      .then(() => {
        setShowButtons(false);
        setAddTitle("");
        setNote("");
        setSelectedNoteFiles([]);
        getNotesDetails(salesOrdId, selectRecent);
      })
      .catch((error) => {
        handleApiError(error, navigate);
      });
  };

  const getAllSales = async (selectedOption) => {
    setIsLoading(true);
    const requestBody = {
      loggedInUserId: loginUserId,
      customFilter: selectedOption.value,
      pageNo: currentPage,
      pageSize: perPage,
    };
    const headersResult = getHeaders(true);

    if (headersResult.error) {
      navigate("../");
      return;
    }
    await axios
      .post(URI.getAllSales, requestBody, {
        headers: headersResult,
      })

      .then((response) => {
        if (response.data.status === 200) {
          // setAllSalesOrdersDetails(response.data.data.getAllSalesOrder);
          setSalesDetails(response.data.data.getAllSalesOrder);
          setFilteredSalesDetails(response.data.data.getAllSalesOrder);
          setTotalItems(response.data.data.rowCount);
          setIsLoading(false);
        }
      })
      .catch(async (error) => {
        handleApiError(error, navigate);
      });
  };

  const [selectedContact, setSelectedContact] = useState(fetchContactInfo);
  const [selectedAccount, setSelectedAccount] = useState({});
  const [selectedDeal, setSelectedDeal] = useState(fetchDealInfo);
  const [selectedQuote, setSelectedQuote] = useState({});
  const [selectedObj, setSelectedObj] = useState({
    name: "Subject",
    isChecked: true,
    value: "subject",
  });
  const [selectedCheckboxes, setSelectedCheckboxes] = useState([]);
  const [showHeadingsInvoices, setShowHeadingsInvoices] = useState(false);

  // for save and new && save lottie loader
  const [saveSubmit, setSaveSubmit] = useState(false);
  const [saveAndNewSubmit, setSaveAndNewSubmit] = useState(false);

  const handleSelectCheckboxChange = (event) => {
    const { value } = event.target;

    const selectedCheckbox = { moduleId: value };

    setSelectedCheckboxes((prevSelectedCheckboxes) => {
      if (
        prevSelectedCheckboxes.some((checkbox) => checkbox.moduleId === value)
      ) {
        return prevSelectedCheckboxes.filter(
          (checkbox) => checkbox.moduleId !== value
        );
      } else {
        return [...prevSelectedCheckboxes, selectedCheckbox];
      }
    });
  };

  const deleteUpdateTransferMass = async () => {
    const { value } = selectedObj;
    let url = URI.massDelete;
    const requestBody = {
      loggedInUserId: loginUserId,
      moduleName: "SALES ORDERS",
      massAction: modalType === "Mass Delete" ? "DELETE" : "EDIT",
      moduleIdList: selectedCheckboxes,
    };
    if (modalType === "Mass Update") {
      if (massUpdateValue.length < 1) {
        setErrorMessage("Value can not be empty");
        setTimeout(() => {
          setErrorMessage("");
        }, 3000);
        return;
      }
      requestBody[value] = ["Due Date"].includes(selectedObj.name)
        ? massUpdateValue.split("-").reverse().join("-")
        : massUpdateValue || null;
      url = URI.massUpdate;
    } else if (modalType === "Mass Transfer") {
      requestBody.ownerId = selectedOwnerItem.userId;
      url = URI.massTransfer;
    }

    const headersResult = getHeaders(true);

    if (headersResult.error) {
      navigate("../");
      return;
    }

    await axios
      .post(url, requestBody, {
        headers: headersResult,
      })
      .then((response) => {
        if (response.data.status === 200) {
          NotificationManager.success("", response.data.message, 5000);
          getAllSales(selectedOption);
          setSelectedCheckboxes([]);
          setInnerPage("list");
          setShowModalType("");
          setShowSearchFields(fieldDropdowns);
          setMassUpdateValue("");
        } else {
          NotificationManager.error("", response.data.message, 5000);
        }
      })
      .catch(function (error) {
        handleApiError(error, navigate);
      });
  };

  const handleSaveButton = async (e) => {
    let missingFields = false;

    if (saleInfo.subject === "") {
      setMandatoryMsg("Subject cannot be empty.");
      setTimeout(() => {
        setMandatoryMsg("");
      }, 3000);
      missingFields = true;
    }

    if (saleInfo.customerNo && !validateCustomerNumber(saleInfo.customerNo)) {
      setCustErrMsg("Please enter valid Customer No.");
      setTimeout(() => {
        setCustErrMsg("");
      }, 4000);
      missingFields = true;
    }

    if (saleInfo.subject && !validateCallSubject(saleInfo.subject)) {
      setMandatoryMsg("Please enter a valid subject.");
      setTimeout(() => {
        setMandatoryMsg("");
      }, 4000);
      missingFields = true;
    }
    if (saleInfo.exciseDuty && !validateCalcField(saleInfo.exciseDuty)) {
      setExciseDutyMsg("Please enter a valid ExciseDuty.");
      setTimeout(() => {
        setExciseDutyMsg("");
      }, 4000);
      missingFields = true;
    }
    if (
      saleInfo.salesCommission &&
      !validateCalcField(saleInfo.salesCommission)
    ) {
      setSalesCommissionMsg("Please enter a Sales Commission.");
      setTimeout(() => {
        setSalesCommissionMsg("");
      }, 4000);
      missingFields = true;
    }

    if (selectedAccount?.accountName === "") {
      setAccNameError("Account Name cannot be empty.");
      setTimeout(() => {
        setAccNameError("");
      }, 4000);
      missingFields = true;
    }

    if (billingAdd.billingCity && !validateCityIndia(billingAdd.billingCity)) {
      setBillingCityError("Invalid billing city.");
      setTimeout(() => {
        setBillingCityError("");
      }, 4000);
      missingFields = true;
    }

    if (
      billingAdd.billingState &&
      !validateStateIndia(billingAdd.billingState)
    ) {
      setBillingStateError("Invalid billing state.");
      setTimeout(() => {
        setBillingStateError("");
      }, 4000);
      missingFields = true;
    }

    if (
      billingAdd.billingCountry &&
      !validateCountryName(billingAdd.billingCountry)
    ) {
      setBillingCountryError("Invalid billing country.");
      setTimeout(() => {
        setBillingCountryError("");
      }, 4000);
      missingFields = true;
    }

    if (billingAdd.billingCode && !validateZipCode(billingAdd.billingCode)) {
      setBillingCodeError("Invalid Billing Code.");
      setTimeout(() => {
        setBillingCodeError("");
      }, 4000);
      missingFields = true;
    }

    if (
      shippingAdd.shippingCity &&
      !validateCityIndia(shippingAdd.shippingCity)
    ) {
      setShippingCityError("Invalid shipping city.");
      setTimeout(() => {
        setShippingCityError("");
      }, 4000);
      missingFields = true;
    }
    if (
      shippingAdd.shippingState &&
      !validateStateIndia(shippingAdd.shippingState)
    ) {
      setShippingStateError("Invalid shipping state.");
      setTimeout(() => {
        setShippingStateError("");
      }, 4000);
      missingFields = true;
    }

    if (
      shippingAdd.shippingCountry &&
      !validateCountryName(shippingAdd.shippingCountry)
    ) {
      setShippingCountryError("Invalid shipping country.");
      setTimeout(() => {
        setShippingCountryError("");
      }, 4000);
      missingFields = true;
    }
    if (
      shippingAdd.shippingCode &&
      !validateZipCode(shippingAdd.shippingCode)
    ) {
      setShippingCodeError("Invalid Shipping Code.");
      setTimeout(() => {
        setShippingCodeError("");
      }, 4000);
      missingFields = true;
    }

    if (missingFields) {
      return;
    } else {
      e === "SAVE" ? setSaveSubmit(true) : setSaveAndNewSubmit(true);
      const requestBody = {
        loggedInUserId: loginUserId,
        salesOrderOwnerId: selectedOwnerItem?.userId,
        subject: removeExtraSpaces(saleInfo.subject),
        customerNo: removeExtraSpaces(saleInfo.customerNo),
        quoteId: selectedQuote.quoteId || null,

        pending: removeExtraSpaces(saleInfo.pending),
        carrier: saleInfo.carrier.value,
        salesCommission: Number(saleInfo.salesCommission),
        // accountId: saleInfo.accountId,
        accountId: selectedAccount?.accountId || null,
        // dealId: saleInfo.dealId,
        dealId: selectedDeal?.dealId || null,
        purchaseOrder: saleInfo.purchaseOrder,
        dueDate:
          saleInfo.dueDate === ""
            ? ""
            : requiredDateFormatConversion(saleInfo.dueDate),
        // contactId: saleInfo.contactId,
        contactId: selectedContact?.contactId || null,
        exciseDuty: Number(saleInfo.exciseDuty),
        status: saleInfo.status.value,
        termsAndConditions: removeExtraSpaces(saleInfo.termsAndConditions),
        description: removeExtraSpaces(saleInfo.description),
        billingStreet: removeExtraSpaces(billingAdd.billingStreet),
        billingCity: removeExtraSpaces(billingAdd.billingCity),
        billingState: removeExtraSpaces(billingAdd.billingState),
        billingCode: removeExtraSpaces(billingAdd.billingCode),
        billingCountry: removeExtraSpaces(billingAdd.billingCountry),
        shippingStreet: removeExtraSpaces(shippingAdd.shippingStreet),
        shippingCity: removeExtraSpaces(shippingAdd.shippingCity),
        shippingState: removeExtraSpaces(shippingAdd.shippingState),
        shippingCode: removeExtraSpaces(shippingAdd.shippingCode),
        shippingCountry: removeExtraSpaces(shippingAdd.shippingCountry),
        // purchaseItemDtlsDto: rows.length > 0 ? rows : [{}],
        purchaseItemDtlsDto: rows,
        subTotal: Number(saleInfo.subTotal),
        discount: Number(saleInfo.discount),
        discountType: Number(saleInfo.discountType),
        discountValue: Number(saleInfo.discountValue),
        tax: Number(saleInfo.tax),
        adjustment: Number(saleInfo.adjustment),
        grandTotal: Number(saleInfo.grandTotal),
        emailNotification: emailNotification,
      };
      // if (editSalesOverview) {
      //   requestBody.salesOrdId = salesOrdId;
      // }
      if (editSalesOverview) {
        const deleteItemDtlsDto = [];
        const allProductIds = rows.map((product) => product.salesOrderItemsId);

        itemsInfo.forEach((item) => {
          if (!allProductIds.includes(item.salesOrderItemsId)) {
            deleteItemDtlsDto.push({
              salesOrderItemsId: item.salesOrderItemsId,
            });
          }
        });
        // if (deleteItemDtlsDto.length > 0) {
        requestBody.deleteItemDtlsDto = deleteItemDtlsDto;
        requestBody.salesOrdId = salesOrdId;
        // }
      }

      const url = editSalesOverview ? URI.updateSalesDetails : URI.createSales;

      const headersResult = getHeaders(true);

      if (headersResult.error) {
        navigate("../");
        return;
      }

      await axios
        .post(url, requestBody, {
          headers: headersResult,
        })

        .then((response) => {
          if (response.data.status === 200) {
            NotificationManager.success("", response.data.message, 5000);
            const discountedAmount =
              productList[0].unitPrice * productList[0].unitPrice - 0;
            const taxAmount =
              (parseFloat(productList[0].tax || 0) / 100) * discountedAmount;
            // getAllAccountDetails();
            // accountResetValues();
            setSaleInfo({
              salesOrderOwnerId: "",
              loggedInUserId: loginUserId,
              subject: "",
              customerNo: "",
              quoteId: "",
              pending: "",
              carrier: carrierOptions[0],
              salesCommission: "",
              accountId: "",
              dealId: "",
              purchaseOrder: "",
              dueDate: "",
              contactId: "",
              exciseDuty: "",
              status: stausOptions[0],
              termsAndConditions: "",
              description: "",
              subTotal: (discountedAmount + taxAmount).toFixed(2),
              discount: 0,
              discountType: 1,
              discountValue: 0,
              tax: 0,
              adjustment: 0,
              grandTotal: (discountedAmount + taxAmount).toFixed(2),
              billingStreet: "",
              billingCity: "",
              billingState: "",
              billingCode: "",
              billingCountry: "",
              shippingStreet: "",
              shippingCity: "",
              shippingState: "",
              shippingCode: "",
              shippingCountry: "",
              purchaseItemDtlsDto: [],
            });
            setSingleSalesDetails({
              salesContactDetails: {},
              salesInfoDetails: {},
              salesItemsInfoDetails: [],
              salesItemsTotalInfoDetails: {},
              salesAccountDetails: {},
              salesDealsDetails: {},
            });
            setRows([
              {
                productId: productList[0].productId,
                productName: productList[0].productName,
                quantity: productList[0].unitPrice,
                listPrice: productList[0].unitPrice,
                amount: productList[0].unitPrice * productList[0].unitPrice,
                discount: 0,
                tax: productList[0].tax || 0,
                total: (discountedAmount + taxAmount).toFixed(2),
                // total: productList[0].unitPrice * productList[0].unitPrice,
                discountType: 1,
                discountValue: 0,
                description: productList[0].description,
              },
            ]);
            setBillingAdd({
              billingStreet: "",
              billingCity: "",
              billingState: "",
              billingCode: "",
              billingCountry: "",
            });
            setShippingAdd({
              shippingStreet: "",
              shippingCity: "",
              shippingState: "",
              shippingCode: "",
              shippingCountry: "",
            });
            getAllSales(selectedOption);
            if (
              pastViewInfo &&
              pastViewInfo?.view !== "" &&
              pastViewInfo?.innerPage !== ""
            ) {
              setCurrentView(pastViewInfo.view);
              setInnerPage(pastViewInfo.innerPage);
              setPastViewInfo((prev) => ({
                ...prev,
                view: "",
                innerPage: "",
                undo: currentView, // it will set the current view of the page so when we cancel or save it will go to same section in the page
              }));
              return;
            }
            if (e === "SAVE") {
              setInnerPage("list");
            } else if (e === "UPDATE") {
              salesOverviewDetail(salesOrdId);
              setInnerPage("overview");
            } else {
              NotificationManager.error("", response.data.message, 5000);
            }
          }
        })
        .catch(function (error) {
          throw new Error("Failed to fetch data", error);
        })
        .finally(() => {
          setSaveAndNewSubmit(false);
          setSaveSubmit(false);
        });

      // setAccountsView("AccountsRecord");
    }
  };

  const returnTotalValue = (item) => {
    const discountedAmount = item.unitPrice * item.unitPrice - 0;
    const taxAmount =
      (parseFloat(isNaN(item.tax) ? 0 : item.tax) / 100) * discountedAmount;
    return (discountedAmount + taxAmount).toFixed(2);
  };

  const getAllTimelineSales = async (salesOrdId) => {
    const headersResult = getHeaders(true);

    if (headersResult.error) {
      navigate("../");
      return;
    }
    await axios
      .get(URI.getSalesTimeline + "/" + loginUserId + "/" + salesOrdId, {
        headers: headersResult,
      })

      .then((response) => {
        if (response.data.status === 200) {
          setTimeLineDataList(response.data.data.getSalesTimelineDetails);
          setSalesIndivPage("timeline");
        }
      })
      .catch(async (error) => {
        handleApiError(error, navigate);
      });
  };

  const getAllInfoShare = async (salesOrdId) => {
    setIsLoading(true);
    const requestBody = {
      moduleId: salesOrdId,
      loggedInUserId: loginUserId,
    };

    const headersResult = getHeaders(true);

    if (headersResult.error) {
      navigate("../");
      return;
    }
    await axios
      .post(URI.getShareInfo, requestBody, {
        headers: headersResult,
      })
      .then((response) => {
        if (response.data.status === 200) {
          const hasSharedItems =
            (response.data.data.getSharingInfoUsers &&
              response.data.data.getSharingInfoUsers.length > 0) ||
            (response.data.data.getSharingInfoRoles &&
              response.data.data.getSharingInfoRoles.length > 0) ||
            (response.data.data.getSharingInfoGroups &&
              response.data.data.getSharingInfoGroups.length > 0) ||
            (response.data.data.getSharingInfoPublic &&
              response.data.data.getSharingInfoPublic.length > 0);

          const userShareMemberIds = response.data.data.getSharingInfoUsers.map(
            (user) => user.shareModuleId
          );
          const roleShareMemberIds = response.data.data.getSharingInfoRoles.map(
            (role) => role.shareModuleId
          );
          const groupShareMemberIds =
            response.data.data.getSharingInfoGroups.map(
              (group) => group.shareModuleId
            );
          const groupPublicSharedMemberIds =
            response.data.data.getSharingInfoPublic.map(
              (user) => user.shareModuleId
            );
          setShareMemberIds([
            ...userShareMemberIds,
            ...roleShareMemberIds,
            ...groupShareMemberIds,
            ...groupPublicSharedMemberIds,
          ]);

          setShareUserList(response.data.data.getSharingInfoUsers);
          setShareRoleList(response.data.data.getSharingInfoRoles);
          setShareGroupList(response.data.data.getSharingInfoGroups);
          setSharePublicList(response.data.data.getSharingInfoPublic);
          if (hasSharedItems) {
            setShareInfoModal(true);
          } else {
            setShareModal(true);
          }
          setIsLoading(false);
        }
      })
      .catch((err) => {
        handleApiError(err, navigate);
      });
  };

  useEffect(() => {
    if (storeModuleId) {
      salesOverviewDetail(storeModuleId);
      setSalesOrdId(storeModuleId);
    }
  }, []);

  const salesOverviewDetail = async (salesOrdId) => {
    let salesOverviewDetails = {};
    let salesInfo = {};
    let itemTotDtl = {};
    let itemInfo = {};
    const headersResult = getHeaders(true);

    if (headersResult.error) {
      navigate("../");
      return;
    }
    await axios
      .get(`${URI.salesOverview}/${loginUserId}/${salesOrdId}`, {
        headers: headersResult,
      })
      .then((response) => {
        if (response.data.status === 200) {
          salesOverviewDetails = response.data.data.salesInfo;
          salesInfo = response.data.data.salesInfo;
          itemTotDtl = response.data.data.salesItemsTotDtls;
          itemInfo = response.data.data.salesItemsInfo;
          setSalesOverviewDetails(salesOverviewDetails);
          setItemTotDtls(itemTotDtl);
          setItemsInfo(itemInfo);
          setSingleSalesDetails(response.data.data);

          salesOverviewDetails.subjectInfo =
            response.data.data.salesInfo.subject;
          salesOverviewDetails.purchaseOrderInfo =
            response.data.data.salesInfo.purchaseOrder;
          salesOverviewDetails.dueDateInfo =
            response.data.data.salesInfo.dueDate;
          salesOverviewDetails.exciseDutyInfo =
            response.data.data.salesInfo.exciseDuty;
          salesOverviewDetails.salesCommissionInfo =
            response.data.data.salesInfo.salesCommission;
          salesOverviewDetails.pendingInfo =
            response.data.data.salesInfo.pending;
          salesOverviewDetails.customerNoInfo =
            response.data.data.salesInfo.customerNo;
          salesOverviewDetails.carrierInfo =
            response.data.data.salesInfo.carrier;
          salesOverviewDetails.carrier = response.data.data.salesInfo.carrier;
          salesOverviewDetails.status = response.data.data.salesInfo.status;
          salesOverviewDetails.statusInfo = response.data.data.salesInfo.status;
          salesOverviewDetails.billingStreetInfo =
            response.data.data.salesInfo.billingStreet;
          salesOverviewDetails.billingCityInfo =
            response.data.data.salesInfo.billingCity;
          salesOverviewDetails.billingStateInfo =
            response.data.data.salesInfo.billingState;
          salesOverviewDetails.billingCodeInfo =
            response.data.data.salesInfo.billingCode;
          salesOverviewDetails.billingCountryInfo =
            response.data.data.salesInfo.billingCountry;
          salesOverviewDetails.shippingStreetInfo =
            response.data.data.salesInfo.shippingStreet;
          salesOverviewDetails.shippingCityInfo =
            response.data.data.salesInfo.shippingCity;
          salesOverviewDetails.shippingStateInfo =
            response.data.data.salesInfo.shippingState;
          salesOverviewDetails.shippingCodeInfo =
            response.data.data.salesInfo.shippingCode;
          salesOverviewDetails.shippingCountryInfo =
            response.data.data.salesInfo.shippingCountry;
          salesInfo.carrierInfo = {
            label: salesOverviewDetails.carrierInfo,
            value: salesOverviewDetails.carrierInfo,
          };
          salesInfo.statusInfo = {
            label: salesOverviewDetails.statusInfo,
            value: salesOverviewDetails.statusInfo,
          };

          const ownerName = userList.find(
            (item) => item.name === response.data.data.salesInfo.salesOrderOwner
          );
          const contactName = contactList.find(
            (item) =>
              item.contactName === response.data.data.salesInfo.contactName
          );
          const accountName = accountList.find(
            (item) =>
              item.accountName === response.data.data.salesInfo.accountName
          );
          const quotesOwnerObj = quotesList.find(
            (item) =>
              item.quotesOwner === response.data.data.salesInfo.quotesOwner
          );
          const dealNameObj = dealList.find(
            (item) => item.dealName === response.data.data.salesInfo.dealName
          );
          setSalesInfoDetails({
            ...response.data.data.salesInfo,
            carrier: {
              label: response.data.data.salesInfo.carrier,
              value: response.data.data.salesInfo.carrier,
            },
            status: {
              label: response.data.data.salesInfo.status,
              value: response.data.data.salesInfo.status,
            },
            carrierInfo: {
              label: response.data.data.salesInfo.carrier,
              value: response.data.data.salesInfo.carrier,
            },
            statusInfo: {
              label: response.data.data.salesInfo.status,
              value: response.data.data.salesInfo.status,
            },
            salesOrderOwner: ownerName?.name,

            subjectInfo: response.data.data.salesInfo.subject,
            purchaseOrderInfo: response.data.data.salesInfo.purchaseOrder,
            dueDateInfo: response.data.data.salesInfo.dueDate,
            exciseDutyInfo: response.data.data.salesInfo.exciseDuty,
            salesCommissionInfo: response.data.data.salesInfo.salesCommission,
            pendingInfo: response.data.data.salesInfo.pending,
            customerNoInfo: response.data.data.salesInfo.customerNo,
            billingStreetInfo: response.data.data.salesInfo.billingStreet,
            billingCityInfo: response.data.data.salesInfo.billingCity,
            billingStateInfo: response.data.data.salesInfo.billingState,
            billingCodeInfo: response.data.data.salesInfo.billingCode,
            billingCountryInfo: response.data.data.salesInfo.billingCountry,
            shippingStreetInfo: response.data.data.salesInfo.shippingStreet,
            shippingCityInfo: response.data.data.salesInfo.shippingCity,
            shippingStateInfo: response.data.data.salesInfo.shippingState,
            shippingCodeInfo: response.data.data.salesInfo.shippingCode,
            shippingCountryInfo: response.data.data.salesInfo.shippingCountry,

            contactName: contactName?.contactName,
            accountName: accountName?.accountName,
            quotesOwner: quotesOwnerObj?.quotesOwner,
            dealName: dealNameObj?.dealName,
            discount: response.data.data.salesItemsTotDtls.discountValue,
          });
          setSelectedOwnerItem(
            userList.find(
              (user) =>
                user.userId === response.data.data.salesInfo.salesOrderOwnerId
            )
          );
          accountName !== undefined && setSelectedAccount(accountName);
          contactName !== undefined && setSelectedContact(contactName);
          quotesOwnerObj !== undefined && setSelectedQuote(quotesOwnerObj);
          dealNameObj !== undefined && setSelectedDeal(dealNameObj);
          setSalesItemsTotalInfoDetails(response.data.data.salesItemsTotDtls);
          setRows(response.data.data.salesItemsInfo);
          setInnerPage("overview");
          setOpenActivities(response.data.data.assignOpenActivities);
          setOpenActivitiesCalls(response.data.data.openActivitiesCalls);
          setOpenActivitiesMeetings(response.data.data.openActivitiesMeetings);
          setClosedActivitiesCall(response.data.data.closedActivitiesCalls);
          setClosedActivitiesMeetings(
            response.data.data.closedActivitiesMeetings
          );
          setCloseActivities(response.data.data.assignCloseActivities);
          setSaleInfo(salesInfo);
        } else {
          NotificationManager.error("", response.data.message, 5000);
        }
      })
      .catch(async (error) => {
        handleApiError(error, navigate);
      });
  };

  const handleUpdateClick = async (field) => {
    if (
      (field === "subject" || field === "subjectInfo") &&
      salesInfoDetails[field] === ""
    ) {
      NotificationManager.error("", "Subject Cannot be Empty", 5000);
      salesOverviewDetail(salesOrdId);
      return;
    }

    var value;
    if (
      field === "status" ||
      field === "carrier" ||
      field === "statusInfo" ||
      field === "carrierInfo"
    ) {
      value = salesInfoDetails[field].value;
    } else {
      value = salesInfoDetails[field];
    }
    let requestBody = {};
    const fieldMappings = {
      subjectInfo: "subject",
      purchaseOrderInfo: "purchaseOrder",
      dueDateInfo: "dueDate",
      exciseDutyInfo: "exciseDuty",
      salesCommissionInfo: "salesCommission",
      customerNoInfo: "customerNo",
      pendingInfo: "pending",
      carrierInfo: "carrier",
      statusInfo: "status",
      billingStreetInfo: "billingStreet",
      billingCityInfo: "billingCity",
      billingStateInfo: "billingState",
      billingCodeInfo: "billingCode",
      billingCountryInfo: "billingCountry",
      shippingStreetInfo: "shippingStreet",
      shippingCityInfo: "shippingCity",
      shippingStateInfo: "shippingState",
      shippingCodeInfo: "shippingCode",
      shippingCountryInfo: "shippingCountry",
    };
    // if (fieldMappings.hasOwnProperty(field)) {
    //   field = fieldMappings[field];
    // } not supported by eslint
    if (Object.hasOwn(fieldMappings, field)) {
      field = fieldMappings[field];
    }
    try {
      if (field === "emailNotification") {
        requestBody = {
          loggedInUserId: loginUserId,
          salesOrdId: salesOrdId,
          salesOrderOwnerId: isOwnerSelect
            ? selectedOwnerItem?.userId
            : selectedOwnerItem?.userId,
          emailNotification: emailNotification,
        };
      } else {
        requestBody = {
          loggedInUserId: loginUserId,
          salesOrdId: salesOrdId,

          [field]: value,
        };
      }

      // const compareValue = isObj
      //   ? singleSalesDetails.salesInfo[field].value
      //   : singleSalesDetails.salesInfo[field];

      if (
        value !== singleSalesDetails.salesInfo[field] ||
        field === "emailNotification"
      ) {
        setApiLoader(true);
        const headersResult = getHeaders(true);

        if (headersResult.error) {
          navigate("../");
          return;
        }
        const response = await axios.post(
          URI.individualUpdateSales,
          requestBody,
          {
            headers: headersResult,
          }
        );
        if (response.data.status === 200) {
          NotificationManager.success("", response.data.message, 5000);
          setSalesInfoDetails((prevDetails) => ({
            ...prevDetails,
            [field]: singleSalesDetails.salesInfo[field],
          }));
          salesOverviewDetail(salesOrdId);
          setIsOwnerSelect(false);
          setShowModalType("");
        } else {
          NotificationManager.error("", response.data.message, 5000);
        }

        const resetFields = Object.fromEntries(
          Object.keys(editingFields).map((key) => [key, false])
        );
        setEditingFields({
          ...resetFields,
        });
        setShowType("");
      }
    } catch (error) {
      handleApiError(error, navigate);
    } finally {
      setApiLoader(false);
    }
  };

  const handleUpdateIdClick = async (field, value) => {
    const fieldName =
      field === "contactName"
        ? "contactId"
        : field === "accountName"
        ? "accountId"
        : field === "dealName"
        ? "dealId"
        : field === "quotesOwner"
        ? "quoteId"
        : "salesOrderOwnerId";

    try {
      const requestBody = {
        salesOrdId: salesOrdId,
        [fieldName]:
          field === "contactName"
            ? value.contactId
            : field === "accountName"
            ? value.accountId
            : field === "dealName"
            ? value.dealId
            : field === "quotesOwner"
            ? value.quoteId
            : value.userId,
        loggedInUserId: loginUserId,
      };
      if (field === "accountName") {
        requestBody.accountName = value.accountName;
      }
      if (field === "dealName") {
        requestBody.dealName = value.dealName;
      }
      if (field === "quotesOwner") {
        requestBody.quotesOwner = value.quotesOwner;
      }
      const headersResult = getHeaders(true);

      if (headersResult.error) {
        navigate("../");
        return;
      }
      const response = await axios.post(
        URI.individualUpdateSales,
        requestBody,
        {
          headers: headersResult,
        }
      );
      if (response.data.status === 200) {
        NotificationManager.success("", response.data.message, 4000);
        setSalesInfoDetails((prevDetails) => ({
          ...prevDetails,
          [field]: singleSalesDetails.salesInfo[field],
        }));

        setEditingFields((prevFields) => ({
          ...prevFields,
          [field]: false,
        }));
        salesOverviewDetail(salesOrdId);
        getNotesDetails(salesOrdId, selectRecent);
      } else {
        NotificationManager.error("", response.data.message, 4000);
      }
    } catch (error) {
      handleApiError(error, navigate);
    }
  };

  const handleChangeBillingAddress = (e) => {
    const { name, value } = e.target;

    setBillingAdd((prevPurchaseInfo) => ({
      ...prevPurchaseInfo,
      [name]: value,
    }));
  };

  const handleChangeShippingAddress = (e) => {
    const { name, value } = e.target;
    setShippingAdd((prevPurchaseInfo) => ({
      ...prevPurchaseInfo,
      [name]: value,
    }));
  };

  const handleFieldClick = (field) => {
    if (field.type === "dropdown") {
      const fieldValue = field.name
        .replace(/\s+/g, "")
        .replace(/^(.)/, (match) => match.toLowerCase());

      // setClickedDropdownObj(salesOverviewDetails[fieldValue]);
      setSalesInfoDetails((prevFields) => ({
        ...prevFields,
        [fieldValue]: singleSalesDetails.salesInfo[fieldValue],
      }));
    } else if (field.type === "checkbox") {
      // setUpdatedFields((prevFields) => ({
      //   ...prevFields,
      //   emailOptOut: vendorOverviewDetails["emailOptOut"],
      // }));
    }

    const updatedColumns = [...fieldLabels];

    const existedObj = updatedColumns.find(
      (field) => field.name === selectedField
    );
    const selectedObj = updatedColumns.find((item) => item.name === field.name);

    const existedObjIndex = updatedColumns.findIndex(
      (field) => field.name === existedObj.name
    );
    const selectedObjIndex = updatedColumns.findIndex(
      (field) => field.name === selectedObj.name
    );

    updatedColumns[existedObjIndex].isShowed = false;
    updatedColumns[selectedObjIndex].isShowed = true;

    const removedObj = updatedColumns.splice(existedObjIndex, 1)[0];
    updatedColumns.splice(existedObjIndex, 0, selectedObj);

    updatedColumns.push(removedObj);
    const uniqueData = updatedColumns.filter(
      (item, index, self) =>
        index === self.findIndex((t) => t.name === item.name)
    );
    setFieldLabels(uniqueData);
    setShowSearchFields(uniqueData);
  };

  const [note, setNote] = useState("");
  const [selectedNoteFiles, setSelectedNoteFiles] = useState([]);
  const [showButtons, setShowButtons] = useState(false);
  const [showTitleNote, setShowTitleNote] = useState(false);
  const [noteTitle, setNoteTitle] = useState("");

  const handleTextAreaClick = () => {
    setShowButtons(true);
  };

  const handleTextFileChange = (event) => {
    const files = Array.from(event.target.files);
    if (files) {
      setSelectedNoteFiles(files);
    } else {
      setSelectedNoteFiles([]);
    }
  };
  const handleAddTitleClick = () => {
    setShowTitleNote(true);
  };

  const [showOptionsSales, setShowOptionsSales] = useState(false);

  const deleteSales = async () => {
    const requestBody = {
      loggedInUserId: loginUserId,
      salesOrdId: salesOrdId,
    };
    const headersResult = getHeaders(true);

    if (headersResult.error) {
      navigate("../");
      return;
    }
    await axios
      .post(URI.deleteSales, requestBody, {
        headers: headersResult,
      })

      .then((response) => {
        if (response.data.status === 200) {
          NotificationManager.success("", response.data.message, 5000);
          getAllSales(selectedOption);
          setShowModalType("");
          setShowOptionsSales(false);
          setInnerPage("list");
        } else {
          NotificationManager.error("", response.data.message, 5000);
          setShowModalType("");
        }
      })
      .catch(function (error) {
        handleApiError(error, navigate);
      });
  };

  const [noteInfo, setNoteInfo] = useState([]);
  const [addTitle, setAddTitle] = useState("");

  const getNotesDetails = async (saleOrdId, orderType) => {
    const requestBody = {
      loggedInUserId: loginUserId,
      moduleId: saleOrdId,
      orderType: orderType,
    };
    const headersResult = getHeaders(true);

    if (headersResult.error) {
      navigate("../");
      return;
    }
    await axios
      .post(URI.getNote, requestBody, {
        headers: headersResult,
      })
      .then((response) => {
        if (response.data.status === 200) {
          setNoteInfo(response.data.data.noteInfo);
        } else {
          NotificationManager.error("", response.data.message, 5000);
        }
      })
      .catch(async (error) => {
        handleApiError(error, navigate);
      });
  };

  const [selectRecent, setSelectRecent] = useState("ALL");
  const [accountList, setAccountList] = useState([]);
  const [contactList, setContactList] = useState([]);
  const [dealList, setDealList] = useState([]);
  const [quotesList, setQuotesList] = useState([]);
  const [currentPageContact, setCurrentPageContact] = useState(1);
  const [perPageContact, setPerPageContact] = useState(10);
  const [totalItemsContact, setTotalItemsContact] = useState(0);
  const [currentPageAccount, setCurrentPageAccount] = useState(1);
  const [totalItemsAccount, setTotalItemsAccount] = useState(0);
  const [perPageAccount, setPerPageAccount] = useState(10);

  const totalPagesContact = Math.ceil(
    Number(totalItemsContact) / perPageContact
  );

  const totalPagesAccount = Math.ceil(
    Number(totalItemsAccount) / perPageAccount
  );

  const handlePageDropDownChangeContact = (selectedOption) => {
    setCurrentPageContact(1);
    setPerPageContact(parseInt(selectedOption.value));
  };

  const filteredDeals = modalDealDetails.filter((item) =>
    item.dealName?.toLowerCase().includes(searchDeal?.toLowerCase())
  );

  const filteredContacts = modalContactDetails.filter((item) =>
    item.contactName?.toLowerCase().includes(searchContact?.toLowerCase())
  );

  const filteredAccount = modalAccountDetails.filter((item) =>
    item.accountName?.toLowerCase().includes(searchAccount?.toLowerCase())
  );

  const handlePageDropDownChangeAccount = (selectedOption) => {
    setCurrentPageAccount(1);
    setPerPageAccount(parseInt(selectedOption.value));
  };

  const handlepageChangeAccount = (page) => {
    setCurrentPageAccount(page);
  };

  const [currentPageDeal, setCurrentPageDeal] = useState(1);
  const [totalItemsDeal, setTotalItemsDeal] = useState(0);
  const [perPageDeal, setPerPageDeal] = useState(10);

  const totalPagesDeal = Math.ceil(Number(totalItemsDeal) / perPageDeal);

  const handlepageChangeDeal = (page) => {
    setCurrentPageDeal(page);
  };

  const handlePageDropDownChangeDeal = (selectedOption) => {
    setCurrentPageDeal(1);
    setPerPageDeal(parseInt(selectedOption.value));
  };

  useEffect(() => {
    getAllDeal();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPageDeal, perPageDeal]);

  const handleSearchDeal = (e) => {
    const { value } = e.target;
    if (value.trim() !== "" || value === "") {
      setSearchDeal(value);
    }
  };

  const handleSearchContact = (e) => {
    const { value } = e.target;
    if (value.trim() !== "" || value === "") {
      setSearchContact(value);
    }
  };

  const handleSearchAccount = (e) => {
    const { value } = e.target;
    if (value.trim() !== "" || value === "") {
      setSearchAccount(value);
    }
  };

  const handleSearchQuote = (e) => {
    const { value } = e.target;
    if (value.trim() !== "" || value === "") {
      setSearchQuote(value);
      const filteredQuotes = modalQuotesDetails.filter((item) =>
        item.quotesOwner?.toLowerCase().includes(value.toLowerCase())
      );
      setQuotesModalSearch(filteredQuotes);
    }
  };

  const getAllAccountDetails = async () => {
    const requestBody = {
      loggedInUserId: loginUserId,
      pageNo: currentPageAccount,
      pageSize: perPageAccount,
    };
    const headersResult = getHeaders(true);

    if (headersResult.error) {
      navigate("../");
      return;
    }
    await axios
      .post(URI.getAllAccountDetails, requestBody, {
        headers: headersResult,
      })
      .then((response) => {
        if (response.data.status === 200) {
          if (!pastViewInfo || pastViewInfo?.view === "") {
            setAccountList(response.data.data.allAccountDetails);
          }
          setModalAccountDetail(response.data.data.allAccountDetails);
          setTotalItemsAccount(response.data.data.rowCount);
        }
      })
      .catch(async (error) => {
        handleApiError(error, navigate);
      });
  };

  const getAllContact = async () => {
    const requestBody = {
      loggedInUserId: loginUserId,
      pageNo: currentPageContact,
      pageSize: perPageContact,
    };
    const headersResult = getHeaders(true);

    if (headersResult.error) {
      navigate("../");
      return;
    }
    await axios
      .post(URI.getAllContact, requestBody, {
        headers: headersResult,
      })
      .then((response) => {
        if (response.data.status === 200) {
          setContactList(response.data.data.allContactDetails);
          setModalContactDetails(response.data.data.allContactDetails);
          setTotalItemsContact(response.data.data.rowCount);
        }
      })
      .catch((err) => {
        handleApiError(err, navigate);
      });
  };

  const getAllDeal = async () => {
    const requestBody = {
      loggedInUserId: loginUserId,
      pageNo: currentPageDeal,
      pageSize: perPageDeal,
    };
    const headersResult = getHeaders(true);

    if (headersResult.error) {
      navigate("../");
      return;
    }
    await axios
      .post(URI.getAllDeals, requestBody, {
        headers: headersResult,
      })
      .then((response) => {
        if (response.data.status === 200) {
          if (!pastViewInfo || pastViewInfo?.view === "") {
            setDealList(response.data.data.allDealDetails);
          }
          setModalDealDetails(response.data.data.allDealDetails);
          setTotalItemsDeal(response.data.data.rowCount);
        }
      })
      .catch((err) => {
        handleApiError(err, navigate);
      });
  };

  const getAllQuotes = async () => {
    const requestBody = {
      loggedInUserId: loginUserId,
      pageNo: currentPageQuote,
      pageSize: perPageQuote,
    };
    const headersResult = getHeaders(true);

    if (headersResult.error) {
      navigate("../");
      return;
    }
    await axios
      .post(URI.getAllQuote, requestBody, {
        headers: headersResult,
      })
      .then((response) => {
        if (response.data.status === 200) {
          setQuotesList(response.data.data.allQuotesDetails);
          setModalQuotesDetails(response.data.data.allQuotesDetails);
          setQuotesModalSearch(response.data.data.allQuotesDetails);
          setTotalItemsQuote(response.data.data.rowCount);
        }
      })
      .catch((err) => {
        handleApiError(err, navigate);
      });
  };

  const handleSelectRecent = (item) => {
    setSelectRecent(item);
    var value = "";
    if (item === "Recent Last") {
      value = "RECENT_LAST";
    } else if (item === "Recent First") {
      value = "RECENT_FIRST";
    } else {
      value = item;
    }
    getNotesDetails(salesOrdId, value);
  };

  useEffect(() => {
    getAllContact();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPageContact, perPageContact]);

  // const clearSelectedItem = () => {
  //   setSelectedOwner("");
  //   setSelectedContact("");
  //   setSelectedAccount("");
  // };

  const getAllAssignModules = async (main, sub, id) => {
    if (main === "DEALS") {
      var contactList = [];
      var accountList = [];
      var quotesList = [];
    } else if (main === "CONTACTS") {
      // eslint-disable-next-line no-redeclare
      var accountList = [];
      // eslint-disable-next-line no-redeclare
      var quotesList = [];
      var dealList = [];
    } else if (main === "ACCOUNTS") {
      // eslint-disable-next-line no-redeclare
      var quotesList = [];
      // eslint-disable-next-line no-redeclare
      var dealList = [];
      // eslint-disable-next-line no-redeclare
      var contactList = [];
    } else if (main === "QUOTES") {
      // eslint-disable-next-line no-redeclare
      var accountList = [];
      // eslint-disable-next-line no-redeclare
      var dealList = [];
      // eslint-disable-next-line no-redeclare
      var contactList = [];
    }
    const requestBody = {
      loginUserId: loginUserId,
      mainModule: main,
      subModule: sub,
      moduleId: id,
    };

    const headersResult = getHeaders(true);

    if (headersResult.error) {
      navigate("../");
      return;
    }

    await axios
      .post(URI.moduleWiseAssign, requestBody, {
        headers: headersResult,
      })
      .then((response) => {
        if (response.data.status === 200) {
          if (main === "DEALS") {
            for (const element of response.data.data.contactsDtlsList) {
              const modifiedResponse = {
                contactName: "",
                contactId: "",
              };

              modifiedResponse["contactName"] = element.moduleName;
              modifiedResponse["contactId"] = element.moduleId;
              contactList.push(modifiedResponse);
            }
            if (response.data.data.contactsDtlsList.length === 0) {
              setSelectedContact({
                contactName: "",
                contactId: "",
              });
            } else {
              setSelectedContact({
                contactName: response.data.data.contactsDtlsList[0]?.moduleName,
                contactId: response.data.data.contactsDtlsList[0]?.moduleId,
              });
            }
            setContactList(contactList);
            for (const element of response.data.data.accountsDtlsList) {
              const modifiedResponse = {
                accountName: "",
                accountId: "",
              };

              modifiedResponse["accountName"] = element.moduleName;
              modifiedResponse["accountId"] = element.moduleId;
              accountList.push(modifiedResponse);
            }
            if (response.data.data.accountsDtlsList.length === 0) {
              setSelectedAccount({
                accountName: "",
                accountId: "",
              });
            } else {
              setSelectedAccount({
                accountName: response.data.data.accountsDtlsList[0]?.moduleName,
                accountId: response.data.data.accountsDtlsList[0]?.moduleId,
              });
            }
            setAccountList(accountList);

            for (const element of response.data.data.quotesDtlsList) {
              const modifiedResponse = {
                quotesOwner: "",
                quoteId: "",
              };

              modifiedResponse["quotesOwner"] = element.moduleName;
              modifiedResponse["quoteId"] = element.moduleId;
              quotesList.push(modifiedResponse);
            }

            if (response.data.data.quotesDtlsList.length > 0) {
              setSelectedQuote({
                quotesOwner: response.data.data.quotesDtlsList[0]?.moduleName,
                quoteId: response.data.data.quotesDtlsList[0]?.moduleId,
              });
            }
            setQuotesList(quotesList);
          } else if (main === "CONTACTS") {
            for (const element of response.data.data.dealsDtlsList) {
              const modifiedResponse = {
                dealName: "",
                dealId: "",
              };

              modifiedResponse["dealName"] = element.moduleName;
              modifiedResponse["dealId"] = element.moduleId;
              dealList.push(modifiedResponse);
            }
            if (response.data.data.dealsDtlsList.length === 0) {
              setSelectedDeal({
                dealName: "",
                dealId: "",
              });
            } else {
              setSelectedDeal({
                dealName: response.data.data.dealsDtlsList[0]?.moduleName,
                dealId: response.data.data.dealsDtlsList[0]?.moduleId,
              });
            }
            setDealList(dealList);
            for (const element of response.data.data.accountsDtlsList) {
              const modifiedResponse = {
                accountName: "",
                accountId: "",
              };

              modifiedResponse["accountName"] = element.moduleName;
              modifiedResponse["accountId"] = element.moduleId;
              accountList.push(modifiedResponse);
            }
            if (response.data.data.accountsDtlsList.length === 0) {
              setSelectedAccount({
                accountName: "",
                accountId: "",
              });
            } else {
              setSelectedAccount({
                accountName: response.data.data.accountsDtlsList[0]?.moduleName,
                accountId: response.data.data.accountsDtlsList[0]?.moduleId,
              });
            }
            setAccountList(accountList);

            for (const element of response.data.data.quotesDtlsList) {
              const modifiedResponse = {
                quotesOwner: "",
                quoteId: "",
              };

              modifiedResponse["quotesOwner"] = element.moduleName;
              modifiedResponse["quoteId"] = element.moduleId;
              quotesList.push(modifiedResponse);
            }
            if (response.data.data.quotesDtlsList.length > 0) {
              setSelectedQuote({
                quotesOwner: response.data.data.quotesDtlsList[0]?.moduleName,
                quoteId: response.data.data.quotesDtlsList[0]?.moduleId,
              });
            }
            setQuotesList(quotesList);
          } else if (main === "ACCOUNTS") {
            for (const element of response.data.data.dealsDtlsList) {
              const modifiedResponse = {
                dealName: "",
                dealId: "",
              };

              modifiedResponse["dealName"] = element.moduleName;
              modifiedResponse["dealId"] = element.moduleId;
              dealList.push(modifiedResponse);
            }
            if (response.data.data.dealsDtlsList.length === 0) {
              setSelectedDeal({
                dealName: "",
                dealId: "",
              });
            } else {
              setSelectedDeal({
                dealName: response.data.data.dealsDtlsList[0]?.moduleName,
                dealId: response.data.data.dealsDtlsList[0]?.moduleId,
              });
            }
            setDealList(dealList);
            for (const element of response.data.data.contactsDtlsList) {
              const modifiedResponse = {
                contactName: "",
                contactId: "",
              };

              modifiedResponse["contactName"] = element.moduleName;
              modifiedResponse["contactId"] = element.moduleId;
              contactList.push(modifiedResponse);
            }

            if (response.data.data.contactsDtlsList.length === 0) {
              setSelectedContact({
                contactName: "",
                contactId: "",
              });
            } else {
              setSelectedContact({
                contactName: response.data.data.contactsDtlsList[0]?.moduleName,
                contactId: response.data.data.contactsDtlsList[0]?.moduleId,
              });
            }
            setContactList(contactList);

            for (const element of response.data.data.quotesDtlsList) {
              const modifiedResponse = {
                quotesOwner: "",
                quoteId: "",
              };

              modifiedResponse["quotesOwner"] = element.moduleName;
              modifiedResponse["quoteId"] = element.moduleId;
              quotesList.push(modifiedResponse);
            }
            if (response.data.data.quotesDtlsList.length > 0) {
              setSelectedQuote({
                quotesOwner: response.data.data.quotesDtlsList[0]?.moduleName,
                quoteId: response.data.data.quotesDtlsList[0]?.moduleId,
              });
            }
            setQuotesList(quotesList);
          } else if (main === "QUOTES") {
            for (const element of response.data.data.dealsDtlsList) {
              const modifiedResponse = {
                dealName: "",
                dealId: "",
              };

              modifiedResponse["dealName"] = element.moduleName;
              modifiedResponse["dealId"] = element.moduleId;
              dealList.push(modifiedResponse);
            }
            if (response.data.data.dealsDtlsList.length === 0) {
              setSelectedDeal({
                dealName: "",
                dealId: "",
              });
            } else {
              setSelectedDeal({
                dealName: response.data.data.dealsDtlsList[0]?.moduleName,
                dealId: response.data.data.dealsDtlsList[0]?.moduleId,
              });
            }
            setDealList(dealList);
            for (const element of response.data.data.contactsDtlsList) {
              const modifiedResponse = {
                contactName: "",
                contactId: "",
              };

              modifiedResponse["contactName"] = element.moduleName;
              modifiedResponse["contactId"] = element.moduleId;
              contactList.push(modifiedResponse);
            }
            if (response.data.data.contactsDtlsList.length === 0) {
              setSelectedContact({
                contactName: "",
                contactId: "",
              });
            } else {
              setSelectedContact({
                contactName: response.data.data.contactsDtlsList[0]?.moduleName,
                contactId: response.data.data.contactsDtlsList[0]?.moduleId,
              });
            }
            setContactList(contactList);

            for (const element of response.data.data.accountsDtlsList) {
              const modifiedResponse = {
                accountName: "",
                accountId: "",
              };

              modifiedResponse["accountName"] = element.moduleName;
              modifiedResponse["accountId"] = element.moduleId;
              accountList.push(modifiedResponse);
            }
            if (response.data.data.accountsDtlsList.length === 0) {
              setSelectedAccount({
                accountName: "",
                accountId: "",
              });
            } else {
              setSelectedAccount({
                accountName: response.data.data.accountsDtlsList[0]?.moduleName,
                accountId: response.data.data.accountsDtlsList[0]?.moduleId,
              });
            }
            setAccountList(accountList);
          }
        }
      })
      .catch(async (error) => {
        handleApiError(error, navigate);
      });
  };

  useEffect(() => {
    if (pastViewInfo) {
      if (pastViewInfo.view === "contacts") {
        const main = pastViewInfo.view.toUpperCase();
        const sub = ["ACCOUNTS", "DEALS", "QUOTES"];
        const id = fetchContactInfo?.contactId;
        getAllAssignModules(main, sub, id);
        return;
      } else if (pastViewInfo.view === "accounts") {
        const main = pastViewInfo.view.toUpperCase();
        const sub = ["CONTACTS", "DEALS", "QUOTES"];
        const id = fetchAccInfo?.accountId;
        getAllAssignModules(main, sub, id);
        return;
      } else if (pastViewInfo.view === "deals") {
        const main = pastViewInfo.view.toUpperCase();
        const sub = ["CONTACTS", "ACCOUNTS", "QUOTES"];
        const id = fetchDealInfo?.dealId;
        getAllAssignModules(main, sub, id);
      } else if (pastViewInfo.view === "quotes") {
        const main = pastViewInfo.view.toUpperCase();
        const sub = ["CONTACTS", "ACCOUNTS", "DEALS"];
        const id = fetchQuoteInfo?.quoteId;
        setSelectedQuote({
          quotesOwner: fetchQuoteInfo?.quotesOwner,
          quoteId: fetchQuoteInfo?.quoteId,
        });
        getAllAssignModules(main, sub, id);
      }
    }
  }, []);

  const accountsDropdown = (topGap, tableIndex) => {
    const list =
      listVisibleType === "salesOrderOwner"
        ? userList
        : listVisibleType === "contactName"
        ? contactList
        : listVisibleType === "dealName"
        ? dealList
        : listVisibleType === "quotesOwner"
        ? quotesList
        : listVisibleType === "productId"
        ? productList
        : accountList;
    if (listVisibleType !== "" && list.length > 0) {
      return (
        <ul
          className="data-list list-group  lead_ownerList_container position-absolute bg-white"
          style={{
            top: topGap,
            width:
              topGap === "2rem" ? "112%" : topGap === "1.8rem" ? "100%" : "",
          }}
        >
          {list.map((item, index) => (
            <li
              key={index}
              className="cursorPointer w-100 px-2 border-bottom my-1"
              onClick={() =>
                handleUserItemClick(listVisibleType, item, tableIndex)
              }
            >
              <div className="d-flex flex-column">
                <span style={{ fontWeight: "500" }}>
                  {" "}
                  {listVisibleType === "salesOrderOwner"
                    ? item?.name
                    : listVisibleType === "contactName"
                    ? item?.contactName
                    : listVisibleType === "accountName"
                    ? item?.accountName
                    : listVisibleType === "dealName"
                    ? item?.dealName
                    : listVisibleType === "quotesOwner"
                    ? item?.subject
                    : listVisibleType === "productId"
                    ? item?.productName
                    : item?.subject}
                </span>
                <span className="small" style={{ color: "#999999" }}>
                  {" "}
                  {item.emailId}{" "}
                </span>
              </div>
            </li>
          ))}
        </ul>
      );
    }
  };

  const scrollToSection = (sectionId) => {
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: "smooth" });
    }
  };

  const deleteNoteDetails = (salesOrdId, noteId) => {
    const formData = new FormData();
    formData.append("moduleId", salesOrdId);
    formData.append("loggedInUserId", loginUserId);
    formData.append("moduleName", "SALES");
    formData.append("noteId", noteId);

    const headersResult = getHeaders(true);

    if (headersResult.error) {
      navigate("../");
      return;
    }

    axios
      .post(URI.deleteNote, formData, {
        headers: headersResult,
      })
      .then((response) => {
        if (response.data.status === 200) {
          NotificationManager.success("", response.data.message, 3000);
          setNoteDeleteConfirmation(false);
          getNotesDetails(salesOrdId, selectRecent);
        } else {
          NotificationManager.error("", response.data.message, 5000);
        }
      })
      .catch((error) => {
        handleApiError(error, navigate);
      });
  };

  const [updateNotes, setUpdateNotes] = useState({
    description: "",
    title: "",
    noteImageList: [],
  });
  const [editNote, setEditNote] = useState(false);
  const [isShrinked, setIsShrinked] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  // const [filterView, setFilterView] = useState(false); setFilterView is not used anywhere so i am commenting this
  const [isAccountListModal, setIsAccountListModal] = useState(false);
  const [isDealListModal, setIsDealListModal] = useState(false);
  const [isQuoteListModal, setIsQuoteListModal] = useState(false);
  const [isContactListModal, setIsContactListModal] = useState(false);
  const [deletedImg, setDeletedImg] = useState("");

  const handleChangeNoteTitle = (e) => {
    const { name, value } = e.target;
    if (value.length === 1 && value[0] === ' ') {
      return; 
    }
    if (editNote) {
      setUpdateNotes((prevLeadInfo) => ({
        ...prevLeadInfo,
        [name]: value,
      }));
    } else {
      setAddTitle(value);
    }
  };

  const handleChildDataChange = (dataFromChild) => {
    const deletedImg = dataFromChild.toString("");
    setDeletedImg(deletedImg);
  };

  const handleChangeNoteDescription = (e) => {
    const { name, value } = e.target;
    if (value.length === 1 && value[0] === ' ') {
      return; 
    }
    if (editNote) {
      setUpdateNotes((prevLeadInfo) => ({
        ...prevLeadInfo,
        [name]: value,
      }));
    } else {
      setNote(value); // Directly set the input value
    }
  };

  const updateNote = (noteId) => {
    const formData = new FormData();
    formData.append("moduleId", salesOrdId);
    formData.append("loggedInUserId", loginUserId);
    formData.append("moduleName", "SALES");
    formData.append("noteDescription", updateNotes.description);
    formData.append("noteTitle", updateNotes.title);
    formData.append("noteId", noteId);
    formData.append("deletedImageNames", deletedImg.toString());

    selectedNoteFiles.forEach(() => {
      formData.append("noteImages", updateNotes.noteImageList);
    });

    const headersResult = getHeaders(true);

    if (headersResult.error) {
      navigate("../");
      return;
    }

    axios
      .post(URI.updateNote, formData, {
        headers: headersResult,
      })
      .then((response) => {
        if (response.data.status === 200) {
          setShowButtons(false);
          setEditNote(false);
          setUpdateNotes({
            description: "",
            title: "",
            noteImageList: [],
          });
          setSelectedNoteFiles([]);
          getNotesDetails(salesOrdId, selectRecent);
          setDeletedImg("");
        } else {
          setDeletedImg("");
          setSelectedNoteFiles([]);
          NotificationManager.error("", response.data.message, 5000);
        }
      })
      .catch((error) => {
        handleApiError(error, navigate);
      });
  };

  const nodataPage = () => {
    return (
      <div className="nodatapage d-flex flex-column justify-content-center align-items-center bg-white px-2 py-5 my-3 mx-5 rounded-3">
        <img src={SalesNoDataImage} alt="img" height={200} width={200} />
        <h5 className="opacity-75">Manage Sales Order</h5>
        <p className="opacity-75 p-0 m-0">
          Quotes are legal agreements between a customer and a vector
        </p>
        <p className="opacity-75 p-0 m-0">
          to deliver the requested product(s) at the agreed upon price
        </p>
        <p className="opacity-75 p-0 m-0">within the specified time period.</p>
        <button
          className="createContactButtonSave px-2 mt-4 cursorPointer rounded-5"
          style={{ width: "19.5%", height: "42.5px" }}
          onClick={() => setInnerPage("create")}
        >
          Create a Sales Order
        </button>
      </div>
    );
  };

  const handleChangeOwnerInputClick = (e) => {
    e.stopPropagation();
    setIsChangeOwnerList(!isChangeOwnerList);

    // setInvidualListVisible(!individualListVisible);
  };

  const handleChangeOwnerItemClick = (item) => {
    setSelectedOwnerItem(item);
    setIsChangeOwnerList(false);
    setIsOwnerSelect(true);
    // setIsSelectedOwner(false);
  };

  useEffect(() => {
    const foundItem =
      userList && userList.find((item) => item.userId === loginUserId);
    if (foundItem) {
      setSelectedOwnerItem(foundItem);
    }
  }, [loginUserId]);

  useEffect(() => {
    getAllSales(selectedOption);
  }, [currentPage, perPage]);

  useEffect(() => {
    getAllAccountDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPageAccount, perPageAccount]);

  useEffect(() => {
    getAllQuotes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPageQuote, perPageQuote]);

  useEffect(() => {
    getAllProduct();
    setInnerPage(innerPage || "list");
  }, []);

  useEffect(() => {
    if (fetchSalesInfo && innerPage === "overview") {
      salesOverviewDetail(fetchSalesInfo?.salesOrdId);
      setSalesOrdId(fetchSalesInfo?.salesOrdId);
    }
  }, []);

  const handleSaleOrdersView = () => {
    if (innerPage === "list") {
      return (
        <>
          {isLoading ? (
            <>
              <Loader />
            </>
          ) : (
            <>
              {filteredSalesDetails &&
              filteredSalesDetails.length === 0 &&
              selectedOption.value === "all_sales_orders" ? (
                <>{nodataPage()}</>
              ) : (
                <>
                  <div className="creteContactBar">
                    {selectedCheckboxes.length > 0 ? (
                      <div className="d-flex w-50 align-items-center gap-2">
                        <Select
                          options={massOptions}
                          styles={customCommonStyles("100%")}
                          value={{
                            value: "Action",
                            label: "Action",
                          }}
                          onChange={(option) => {
                            // setDropdownTwo(option);
                            if (option.value === "Mass Update") {
                              setShowSearchFields(salesColumns);
                              setSelectedObj({
                                name: "Subject",
                                isChecked: true,
                                value: "subject",
                              });
                            } else if (option.value === "Mass Transfer") {
                              setSelectedOwnerItem(
                                userList.find(
                                  (user) => user.userId === loginUserId
                                )
                              );
                            }
                            setMassUpdateValue("");
                            setShowModalType(option.value);
                          }}
                          className="w-25"
                          isSearchable={false}
                        />
                      </div>
                    ) : (
                      <>
                        <div className="d-flex w-50 align-items-center gap-2">
                          {/* <img
                        src={filterView ? filterIcon : filterIconCross}
                        height={25}
                        className="leadFilter"
                        alt="img"
                      /> */}
                          <Select
                            options={options}
                            styles={customCommonStyles("230px")}
                            value={selectedOption}
                            onChange={handleChange}
                            isSearchable={false}
                          />
                        </div>
                        <div className="d-flex w-50 justify-content-end gap-2">
                          <div className="btn-group">
                            <button
                              className="createContactButtonSave py-2"
                              // style={{
                              //   background:
                              //     "linear-gradient(86.07deg, #407BFF 0%, #12A7FE 100%)",
                              //   borderRight: "2px solid #fff",
                              //   color: "#fff",
                              // }}
                              style={{ width: "180px" }}
                              onClick={handleCreateSalesOrder}
                            >
                              Create Sales Order
                            </button>
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                  <div className="totalRecordBarContact">
                    <div className="d-flex w-50">
                      <h6>
                        {" "}
                        {selectedCheckboxes.length > 0
                          ? `${selectedCheckboxes.length} Records Selected`
                          : `Total Records : ${totalItems}`}{" "}
                      </h6>
                    </div>
                    <div className="d-flex w-50 align-items-center justify-content-end gap-2">
                      {/* <Select
              options={options}
              styles={customSelectLocationStyles}
              value={selectedOption}
              onChange={handleChange}
            />
            <p className="mb-0">· ‹1/10›</p> */}

                      {selectedCheckboxes.length === 0 ? (
                        filteredSalesDetails.length !== 0 && (
                          <Select
                            options={pageOption}
                            styles={customCommonStyles("100%")}
                            isSearchable={false}
                            value={pageOption.find(
                              (option) => option.value === perPage.toString()
                            )}
                            onChange={handlePageDropDowmChange}
                          />
                        )
                      ) : (
                        <></>
                      )}

                      {filteredSalesDetails.length !== 0 && (
                        <Pagination
                          currentPage={currentPage}
                          totalPages={totalPages}
                          onPageChange={handlePageChange}
                        />
                      )}
                    </div>
                  </div>
                  <div className="contactMain">
                    {/* {filterView ? (
                      <div className="filterLead">
                        <h6 style={{ borderBottom: "2px solid #316AFF" }}>
                          Filter by Sales
                        </h6>
                        <input
                          type="text"
                          placeholder="Search"
                          className="filterSearch"
                        />
                        <div className="checkboxItem">
                          <h6 style={{ color: "#515151" }}>
                            ▾ System Defined Filters
                          </h6>
                          <input
                            type="checkbox"
                            id="vehicle1"
                            name="vehicle1"
                            value="Bike"
                          />
                          <label htmlFor="vehicle1"> Touched Record</label>
                          <br />
                          <input
                            type="checkbox"
                            id="vehicle1"
                            name="vehicle1"
                            value="Bike"
                          />
                          <label htmlFor="vehicle1"> Untouched Record</label>
                          <br />
                          <input
                            type="checkbox"
                            id="vehicle1"
                            name="vehicle1"
                            value="Bike"
                          />
                          <label htmlFor="vehicle1"> Record Action</label>
                          <br />
                          <h6 style={{ color: "#515151", marginTop: "10px" }}>
                            ▾ Filter By Fields
                          </h6>
                          <input
                            type="checkbox"
                            id="vehicle1"
                            name="vehicle1"
                            value="Bike"
                          />
                          <label htmlFor="vehicle1"> Annual Revenue</label>
                          <br />
                          <input
                            type="checkbox"
                            id="vehicle1"
                            name="vehicle1"
                            value="Bike"
                          />
                          <label htmlFor="vehicle1"> City</label>
                          <br />
                          <input
                            type="checkbox"
                            id="vehicle1"
                            name="vehicle1"
                            value="Bike"
                          />
                          <label htmlFor="vehicle1"> Company</label>
                          <br />
                        </div>
                      </div>
                    ) : (
                      <></>
                    )} */}
                    <div
                      className={
                        filterIcon ? "leadListTableFullWidth" : "leadListTable"
                      }
                    >
                      <table className="table leadTable">
                        <thead>
                          <tr>
                            <th scope="col">
                              <input
                                type="checkbox"
                                id="vehicle1"
                                name="vehicle1"
                                value="Bike"
                                checked={
                                  selectedCheckboxes.length ===
                                  filteredSalesDetails.length
                                }
                                onChange={(e) => {
                                  if (e.target.checked) {
                                    const allList = filteredSalesDetails.map(
                                      (item) => ({ moduleId: item.salesOrdId })
                                    );
                                    setSelectedCheckboxes(allList);
                                  } else {
                                    setSelectedCheckboxes([]);
                                  }
                                }}
                              />
                            </th>
                            {selectedHeadings.map(
                              (heading) =>
                                heading.isChecked && (
                                  <th scope="col" key={heading.name}>
                                    <span className="pe-3">{heading.name}</span>
                                    <span className="position-relative">
                                      <img
                                        src={tableFilterIcon}
                                        alt=""
                                        className="cursorPointer"
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          setShowHeadings(false);
                                          setShowSelectedFilter(heading.name);
                                        }}
                                      />
                                      {heading.name === showSelectedFilter && (
                                        <span
                                          className="position-absolute rounded-2 border bg-light top-0 mt-4"
                                          style={{ right: "-10%" }}
                                        >
                                          <span className="d-flex flex-column px-1">
                                            {/* Ascending filter */}
                                            <span
                                              className="my-1 px-1 cursorPointer fw-normal text-start filter_type"
                                              onClick={() => {
                                                if (filterType === "asc") {
                                                  setFilterType("");
                                                  setFilteredSalesDetails(
                                                    salesDetails
                                                  );
                                                } else {
                                                  setFilterType("asc");
                                                  sortedColumnsData(
                                                    "asc",
                                                    heading.value
                                                  );
                                                }
                                              }}
                                            >
                                              {filterType === "asc" ? (
                                                <img
                                                  src={cancel}
                                                  alt="asc"
                                                  className="opacity-75 pe-1"
                                                />
                                              ) : (
                                                <img
                                                  src={arrowUp}
                                                  alt="asc"
                                                  className="opacity-75 pe-1"
                                                />
                                              )}
                                              Asc
                                            </span>
                                            {/* Descending filter */}
                                            <span
                                              className="my-2 px-1 cursorPointer fw-normal text-start filter_type"
                                              onClick={() => {
                                                if (filterType === "desc") {
                                                  setFilterType("");
                                                  setFilteredSalesDetails(
                                                    salesDetails
                                                  );
                                                } else {
                                                  setFilterType("desc");
                                                  sortedColumnsData(
                                                    "desc",
                                                    heading.value
                                                  );
                                                }
                                              }}
                                            >
                                              {filterType === "desc" ? (
                                                <img
                                                  src={cancel}
                                                  alt="desc"
                                                  className="opacity-75 pe-1"
                                                />
                                              ) : (
                                                <img
                                                  src={arrowDown}
                                                  alt="desc"
                                                  className="opacity-75 pe-1"
                                                />
                                              )}
                                              Desc
                                            </span>
                                            <span
                                              className={`my-1 px-1 fw-normal text-start filter_type ${
                                                selectedHeadings[0].name ===
                                                  "Sales Order Owner" &&
                                                heading.name ===
                                                  "Sales Order Owner"
                                                  ? "opacity-50"
                                                  : "cursorPointer"
                                              }`}
                                              onClick={() => {
                                                if (
                                                  pinnedColumn !==
                                                  "Sales Order Owner"
                                                ) {
                                                  setPinnedColumn(
                                                    "Sales Order Owner"
                                                  );
                                                  handlePinColumn(
                                                    "Sales Order Owner"
                                                  );
                                                } else {
                                                  setPinnedColumn(heading.name);
                                                  handlePinColumn(heading.name);
                                                }
                                              }}
                                            >
                                              <img
                                                src={
                                                  pinnedColumn === heading.name
                                                    ? unPin
                                                    : pin
                                                }
                                                alt="pin"
                                                className="opacity-75 pe-1"
                                              />{" "}
                                              {pinnedColumn === heading.name
                                                ? "Unpin"
                                                : "Pin"}{" "}
                                              Column
                                            </span>
                                          </span>
                                        </span>
                                      )}
                                      <img
                                        src={filterLine}
                                        alt=""
                                        className="ps-2"
                                      />
                                    </span>
                                  </th>
                                )
                            )}
                            <th
                              scope="col"
                              className="text-end cursorPointer position-relative"
                            >
                              <img
                                src={filterMenu}
                                alt=""
                                className={`filter_menu ${
                                  showHeadings ? "bg-light" : ""
                                }`}
                                onClick={(e) => {
                                  e.stopPropagation();
                                  setShowSelectedFilter("");
                                  setShowHeadings(true);
                                }}
                              />
                              {showHeadings && (
                                <div
                                  className="position-absolute rounded-2  border border-primary bg-light"
                                  style={{
                                    right: "0.2rem",
                                  }}
                                >
                                  <div className="d-flex flex-column px-2">
                                    <span
                                      className="p-2 cursorPointer fw-normal text-start"
                                      onClick={() => {
                                        setSearchColumn("");
                                        setSavingHeadings(selectedHeadings);
                                        setShowModalType("Manage Columns");
                                      }}
                                    >
                                      Manage Columns
                                    </span>
                                    <span
                                      className="p-2 fw-normal text-start opacity-50"
                                      onClick={() => {
                                        setShowModalType("Reset Column Size");
                                      }}
                                    >
                                      Reset Column Size
                                    </span>
                                  </div>
                                </div>
                              )}
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {filteredSalesDetails &&
                          filteredSalesDetails.length > 0 ? (
                            filteredSalesDetails.map((item, i) => {
                              return (
                                <tr key={i} className="">
                                  <td>
                                    <input
                                      type="checkbox"
                                      id="vehicle1"
                                      name="vehicle1"
                                      value={item.salesOrdId}
                                      checked={selectedCheckboxes.some(
                                        (checkbox) =>
                                          checkbox.moduleId === item.salesOrdId
                                      )}
                                      onChange={handleSelectCheckboxChange}
                                    />
                                  </td>
                                  <React.Fragment>
                                    {selectedHeadings.map((obj, i) => {
                                      const value = obj.value;
                                      const itemValue = item[value];

                                      return obj.isChecked ? (
                                        value === "transHash" ? (
                                          <td
                                            style={{ wordBreak: "break-all" }}
                                            className="overflow_text_hash"
                                          >
                                            <a
                                              rel="noreferrer"
                                              href={
                                                EXPLORER_URL +
                                                "about-txHash/" +
                                                item.transHash
                                              }
                                              className="anchorLinkHash textColor"
                                              data-title={item.transHash}
                                              target="_blank"
                                            >
                                              {item.transHash
                                                ? item.transHash.slice(0, 10) +
                                                  "...."
                                                : "-"}
                                            </a>
                                          </td>
                                        ) : (
                                          <td
                                            key={i}
                                            onClick={() => {
                                              if (value === "salesOrderOwner") {
                                                salesOverviewDetail(
                                                  item.salesOrdId
                                                );
                                                setSalesOrdId(item.salesOrdId);
                                                setSelectedCheckboxes([]);
                                                getNotesDetails(
                                                  item.salesOrdId,
                                                  selectRecent
                                                );
                                                setFetchSalesInfo(item);
                                                setSelectedSalesId(
                                                  salesDetails.findIndex(
                                                    (lead) =>
                                                      lead.salesOrdId ===
                                                      item.salesOrdId
                                                  )
                                                );
                                              }
                                            }}
                                            className={`overflow_text ${
                                              value === "salesOrderOwner"
                                                ? "salesOrderOwner cursorPointer"
                                                : ""
                                            }`}
                                            style={{ maxWidth: "3vw" }}
                                          >
                                            {itemValue !== null
                                              ? itemValue
                                              : "-"}
                                          </td>
                                        )
                                      ) : null;
                                    })}
                                  </React.Fragment>
                                  <td></td>
                                </tr>
                              );
                            })
                          ) : (
                            <tr>
                              <td
                                colSpan={selectedHeadings.length + 2}
                                style={{ textAlign: "center" }}
                              >
                                No Data
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </>
              )}
            </>
          )}
        </>
      );
    } else if (["create", "clone"].includes(innerPage)) {
      return (
        <>
          <div className="createContactButtonBar">
            <div className="d-flex align-items-center w-50 gap-2">
              <img src={groupIcon} height={25} alt="img" />
              <div className="d-flex flex-column">
                <p
                  className="mb-0 first_letter_uppercase"
                  style={{ fontSize: "22px" }}
                >
                  {editSalesOverview ? "Edit" : innerPage} Sales Order
                </p>
                {/* <p
                  className="mb-0"
                  style={{
                    color: "#529CF3",
                    fontSize: "12px",
                    width: "fit-content",
                  }}
                >
                  Edit Layout
                </p> */}
              </div>
            </div>
            <div className="d-flex w-50 gap-2 justify-content-end">
              <button
                className="createLeadButton"
                onClick={() => {
                  if (pastViewInfo) {
                    setCurrentView(pastViewInfo.view);
                    setInnerPage(pastViewInfo.innerPage);
                    setPastViewInfo((prev) => ({
                      ...prev,
                      view: "",
                      innerPage: "",
                      undo: currentView,

                      // it will set the current view of the page so when we cancel or save it will go to same section in the page
                    }));
                    return;
                  } else if (editSalesOverview) {
                    setInnerPage("overview");
                  } else {
                    setInnerPage("list");
                    setEditSalesOverview(false);
                  }
                }}
              >
                Cancel
              </button>
              {!editSalesOverview && (
                <button
                  className="createLeadButton"
                  style={{ width: "175px" }}
                  // onClick={() => addNewContact("SAVE AND NEW")}
                  onClick={() => {
                    handleSaveButton("SAVE AND NEW");
                  }}
                  disabled={saveAndNewSubmit}
                >
                  {saveAndNewSubmit ? (
                    <Lottie
                      options={defaultOptions("blue")}
                      height="24px"
                      width="36px"
                    />
                  ) : (
                    "Save and New"
                  )}
                </button>
              )}
              <button
                className="save-button"
                // onClick={() => editProduct ? UpdateContact() : addNewContact("SAVE")}
                onClick={() => {
                  handleSaveButton(editSalesOverview ? "UPDATE" : "SAVE");
                }}
                disabled={saveSubmit}
              >
                {saveSubmit ? (
                  <Lottie
                    options={defaultOptions("white")}
                    height="30px"
                    width="36px"
                  />
                ) : !editSalesOverview ? (
                  "Save"
                ) : (
                  "Update"
                )}
              </button>
            </div>
          </div>

          <div className="contactFields">
            <h6
              className="py-2 mb-0"
              style={{
                borderBottom: "2px solid #316AFF",
                fontSize: "18px",
                color: "#515151",
              }}
            >
              Sales Order Information
            </h6>
            <div className="inputFieldDiv">
              <div className="inputFieldDivLeft position-relative">
                <p className="mb-0">
                  Sales Order Owner<span style={{ color: "red" }}> *</span>
                </p>
                <div className="d-flex input-with-clear position-relative">
                  <span
                    className="leadInputField cursorPointer rounded-1 pt-2"
                    onClick={(e) => {
                      e.stopPropagation();
                      setListVisibleType(
                        listVisibleType === "salesOrderOwner" ||
                          editSalesOverview
                          ? ""
                          : "salesOrderOwner"
                      );
                    }}
                  >
                    {selectedOwnerItem?.name}
                  </span>

                  {editSalesOverview ? (
                    <></>
                  ) : (
                    <>
                      <span
                        className="position-absolute pt-2 cursorPointer"
                        style={{ right: "42%" }}
                        onClick={(e) => {
                          e.stopPropagation();
                          setListVisibleType(
                            listVisibleType === "salesOrderOwner"
                              ? ""
                              : "salesOrderOwner"
                          );
                        }}
                      >
                        {" "}
                        <img
                          src={
                            listVisibleType === "salesOrderOwner" ? up : down
                          }
                          alt="img"
                          height={20}
                          width={20}
                        />
                      </span>
                    </>
                  )}
                  <span
                    className="cursorPointer pt-2 ps-1"
                    onClick={() => {
                      if (editSalesOverview) {
                        setShowModalType("changeOwner");

                        setListVisible(false);
                      } else {
                        setIsLeadOwnerModal(!isLeadOwnerModal);
                      }
                    }}
                    // onClick={() => setIsLeadOwnerModal(!isLeadOwnerModal)}
                  >
                    <img src={userSearch} alt="img" width={20} height={20} />
                  </span>
                </div>
                {listVisibleType === "salesOrderOwner" &&
                  accountsDropdown("6rem")}
              </div>
              <div className="inputFieldDivLeft position-relative">
                <p className="mb-0">Deal Name</p>
                {/* <input
                  type="text"
                  className="insertCommonInputField"
                  name="dealId"
                  style={{
                    width: "60%",
                    border: "1px solid #B9B9B94D",
                    height: "40px",
                    outline: "none",
                    borderRadius: "10px",
                  }}
                  value={saleInfo.dealId}
                  onChange={handleChangeSales}
                /> */}
                <div className="d-flex input-with-clear position-relative">
                  <span
                    className="leadInputField cursorPointer rounded-1 pt-2"
                    onClick={(e) => {
                      e.stopPropagation();
                      setListVisibleType(
                        listVisibleType === "dealName" ? "" : "dealName"
                      );
                    }}
                  >
                    {isDealListModal || isContactListModal || isAccountListModal
                      ? selectedDeal?.moduleName
                      : selectedDeal?.dealName}
                  </span>

                  <span
                    className="position-absolute pt-2 cursorPointer"
                    onClick={(e) => {
                      e.stopPropagation();
                      setListVisibleType(
                        listVisibleType === "dealName" ? "" : "dealName"
                      );
                    }}
                    style={{ right: "42%" }}
                  >
                    <img
                      src={listVisibleType === "dealName" ? up : down}
                      alt="img"
                      height={20}
                      width={20}
                    />
                  </span>

                  <span
                    className="cursorPointer pt-2 ps-1"
                    onClick={() => setIsDealListModal(true)}
                  >
                    <img src={dealsIcon} alt="img" width={22} height={25} />
                  </span>
                </div>
                {listVisibleType === "dealName" && accountsDropdown("6rem")}
              </div>
            </div>
            <div className="inputFieldDiv">
              <div className="inputFieldDivRight">
                <p className="mb-0">
                  Subject<span style={{ color: "red" }}> *</span>
                </p>
                <input
                  type="text"
                  className={
                    mandatoryMsg ? "mandatoryField " : "insertCommonInputField"
                  }
                  name="subject"
                  style={{
                    width: "60%",
                    border: "1px solid #B9B9B94D",
                    height: "40px",
                    outline: "none",
                    borderRadius: "10px",
                  }}
                  value={saleInfo.subject}
                  onChange={handleChangeSales}
                  maxLength={100}
                />
                <p style={{ color: "#fa5a5a" }}>
                  {mandatoryMsg && mandatoryMsg}
                </p>
              </div>
              <div className="inputFieldDivRight">
                <p className="mb-0">Purchase Order</p>
                <input
                  type="text"
                  maxLength={20}
                  className="insertCommonInputField"
                  name="purchaseOrder"
                  style={{
                    width: "60%",
                    border: "1px solid #B9B9B94D",
                    height: "40px",
                    outline: "none",
                    borderRadius: "10px",
                  }}
                  value={saleInfo.purchaseOrder.replace(/[^\d]/g, "")}
                  onChange={handleChangeSales}
                />
              </div>
            </div>
            <div className="inputFieldDiv">
              <div className="inputFieldDivLeft">
                <p className="mb-0">Customer No.</p>
                <input
                  type="text"
                  className={
                    custErrMsg ? "mandatoryField " : "insertCommonInputField"
                  }
                  name="customerNo"
                  style={{
                    width: "60%",
                    border: "1px solid #B9B9B94D",
                    height: "40px",
                    outline: "none",
                    borderRadius: "10px",
                  }}
                  value={saleInfo.customerNo}
                  onChange={handleChangeSales}
                />
                <p style={{ color: "#fa5a5a" }}>{custErrMsg && custErrMsg}</p>
              </div>
              <div className="inputFieldDivRight">
                <p className="mb-0">Due Date</p>
                <input
                  type="date"
                  className="insertCommonInputField"
                  name="dueDate"
                  style={{
                    width: "60%",
                    border: "1px solid #B9B9B94D",
                    height: "40px",
                    outline: "none",
                    borderRadius: "10px",
                  }}
                  value={saleInfo.dueDate}
                  onChange={handleChangeSales}
                  min={minDate}
                  max={maxDate}
                  onKeyDown={(e) => e.preventDefault()}
                />
              </div>
            </div>
            <div className="inputFieldDiv">
              <div className="inputFieldDivLeft position-relative">
                <p className="mb-0">Quote Name</p>
                {/* <input
                  type="text"
                  className="insertCommonInputField"
                  name="quoteId"
                  style={{
                    width: "60%",
                    border: "1px solid #B9B9B94D",
                    height: "40px",
                    outline: "none",
                    borderRadius: "10px",
                  }}
                  value={saleInfo.quoteId}
                  onChange={handleChangeSales}
                /> */}
                <div className="d-flex input-with-clear position-relative">
                  <span
                    className="leadInputField cursorPointer rounded-1 pt-2"
                    onClick={(e) => {
                      e.stopPropagation();
                      setListVisibleType(
                        listVisibleType === "quotesOwner" ? "" : "quotesOwner"
                      );
                    }}
                  >
                    {fetchQuoteInfo
                      ? fetchQuoteInfo.subject
                      : isDealListModal ||
                        isContactListModal ||
                        isAccountListModal
                      ? selectedQuote?.moduleName
                      : selectedQuote.quotesOwner}
                  </span>

                  <span
                    className="position-absolute pt-2 cursorPointer"
                    // onClick={() => setSelectedAccount("")}
                    onClick={(e) => {
                      e.stopPropagation();
                      setListVisibleType(
                        listVisibleType === "quotesOwner" ? "" : "quotesOwner"
                      );
                    }}
                    style={{ right: "42%" }}
                  >
                    <img
                      src={listVisibleType === "quotesOwner" ? up : down}
                      alt="img"
                      height={20}
                      width={20}
                    />
                  </span>

                  <span
                    className="cursorPointer pt-2 ps-1"
                    onClick={() => setIsQuoteListModal(true)}
                  >
                    <img src={quotesIcon} alt="img" width={20} height={20} />
                  </span>
                </div>
                {listVisibleType === "quotesOwner" && accountsDropdown("6rem")}
              </div>
              <div className="inputFieldDivRight position-relative">
                <p className="mb-0">Contact Name</p>
                {/* <input
                  type="text"
                  className="insertCommonInputField"
                  name="contactId"
                  style={{
                    width: "60%",
                    border: "1px solid #B9B9B94D",
                    height: "40px",
                    outline: "none",
                    borderRadius: "10px",
                  }}
                  value={saleInfo.contactId}
                  onChange={handleChangeSales}
                /> */}
                <div className="d-flex input-with-clear position-relative">
                  <span
                    className="leadInputField cursorPointer rounded-1 pt-2"
                    onClick={(e) => {
                      e.stopPropagation();
                      setListVisibleType(
                        listVisibleType === "contactName" ? "" : "contactName"
                      );
                    }}
                  >
                    {isDealListModal || isContactListModal || isAccountListModal
                      ? selectedContact?.moduleName
                      : selectedContact?.contactName}
                  </span>

                  <span
                    className="position-absolute pt-2 cursorPointer"
                    // onClick={() => setSelectedContact("")}
                    onClick={(e) => {
                      e.stopPropagation();
                      setListVisibleType(
                        listVisibleType === "contactName" ? "" : "contactName"
                      );
                    }}
                    style={{ right: "42%" }}
                  >
                    <img
                      src={listVisibleType === "contactName" ? up : down}
                      alt="img"
                      height={20}
                      width={20}
                    />
                  </span>

                  <span
                    className="cursorPointer pt-2 ps-1"
                    onClick={() => setIsContactListModal(true)}
                  >
                    <img src={ContactIcon} alt="img" width={22} height={22} />
                  </span>
                </div>
                {listVisibleType === "contactName" && accountsDropdown("6rem")}
                {/* {!validEmail && mail != "" && <p style={{ color: 'red', margin: '5px 0' }}>Please enter a valid email address.</p>} */}
              </div>
            </div>
            <div className="inputFieldDiv">
              <div className="inputFieldDivLeft">
                <p className="mb-0">Pending</p>
                <input
                  type="text"
                  className="insertCommonInputField"
                  name="pending"
                  style={{
                    width: "60%",
                    border: "1px solid #B9B9B94D",
                    height: "40px",
                    outline: "none",
                    borderRadius: "10px",
                  }}
                  value={saleInfo.pending}
                  onChange={handleChangeSales}
                />
              </div>
              <div className="inputFieldDivRight position-relative">
                <p className="mb-0">Excise Duty</p>
                <div className="position-relative">
                  <input
                    type="text"
                    className="insertCommonInputField"
                    name="exciseDuty"
                    style={{
                      width: "60%",
                      border: "1px solid #B9B9B94D",
                      height: "40px",
                      outline: "none",
                      borderRadius: "10px",
                    }}
                    value={saleInfo.exciseDuty}
                    onChange={handleChangeSales}
                    onBlur={() => handleBlurForSales("exciseDuty")}
                    onKeyPress={handleKeyPress}
                  />
                  <span
                    className="position-absolute"
                    style={{ top: "0.4rem", right: "35%" }}
                  >
                    <CalculatorTooltip />
                  </span>
                </div>

                {showTooltipExciseDuty && (
                  <div style={{ ...tooltipStyles, bottom: "30px" }}>
                    {"= " + tooltipContentExciseDuty}
                  </div>
                )}
                <span className="position-absolute start-0 pt-2 ms-1 dollar_icon">
                  ₹
                </span>
                <p
                  className="small erro_msg position-absolute"
                  style={{ left: "0px", bottom: "-35px" }}
                >
                  {exciseDutyMsg && exciseDutyMsg}
                </p>
              </div>
            </div>
            <div className="inputFieldDiv">
              <div className="inputFieldDivLeft">
                <p className="mb-0">
                  Carrier<span style={{ color: "red" }}> *</span>
                </p>
                <Select
                  value={saleInfo.carrier}
                  menuPlacement="auto"
                  onChange={(selectedOption) =>
                    handleChangeSelectDropDown(selectedOption, "carrier")
                  }
                  options={carrierOptions}
                  styles={createLeadStyles}
                  isSearchable={false}
                />
                {/* {!secEmailError && secondaryEmailId != "" && <p style={{ color: 'red', margin: '5px 0' }}>Please enter a valid email address.</p>} */}
              </div>
              <div className="inputFieldDivRight">
                <p className="mb-0">
                  Status<span style={{ color: "red" }}> *</span>
                </p>
                <Select
                  value={saleInfo.status}
                  menuPlacement="auto"
                  onChange={(selectedOption) =>
                    handleChangeSelectDropDown(selectedOption, "status")
                  }
                  options={stausOptions}
                  styles={createLeadStyles}
                  isSearchable={false}
                />
              </div>
            </div>
            <div className="inputFieldDiv position-relative">
              <div className="inputFieldDivLeft">
                <p className="mb-0">Sales Commission</p>
                <div className="position-relative">
                  <input
                    type="text"
                    className="insertCommonInputField"
                    name="salesCommission"
                    style={{
                      width: "60%",
                      border: "1px solid #B9B9B94D",
                      height: "40px",
                      outline: "none",
                      borderRadius: "10px",
                    }}
                    value={saleInfo.salesCommission}
                    onChange={handleChangeSales}
                    onBlur={() => handleBlurForSales("salesCommission")}
                    onKeyPress={handleKeyPress}
                  />
                  <span
                    className="position-absolute"
                    style={{ top: "0.4rem", right: "35%" }}
                  >
                    <CalculatorTooltip />
                  </span>
                </div>

                {showTooltipSalesCommission && (
                  <div style={{ ...tooltipStyles, bottom: "30px" }}>
                    {"= " + tooltipContentSalesCommission}
                  </div>
                )}
                <span className="position-absolute start-0 pt-2 ms-1 dollar_icon">
                  ₹
                </span>
                <p
                  className="small erro_msg position-absolute"
                  style={{ left: "0px", bottom: "-35px" }}
                >
                  {salesCommissionMsg && salesCommissionMsg}
                </p>
              </div>
            </div>
            <div className="inputFieldDiv position-relative">
              <div className="inputFieldDivLeft">
                <p className="mb-0">
                  Account Name<span style={{ color: "red" }}> *</span>
                </p>
                {/* <input
                  type="text"
                  className="insertCommonInputField"
                  name="accountId"
                  style={{
                    width: "60%",
                    border: "1px solid #B9B9B94D",
                    height: "40px",
                    outline: "none",
                    borderRadius: "10px",
                  }}
                  value={saleInfo.accountId}
                  onChange={handleChangeSales}
                /> */}
                <div className="d-flex input-with-clear position-relative">
                  <span
                    // className="leadInputField "
                    className={`cursorPointer rounded-1 pt-2 ${
                      accNameError
                        ? "mandatoryField "
                        : "insertCommonInputField  "
                    }`}
                    onClick={(e) => {
                      e.stopPropagation();
                      setListVisibleType(
                        listVisibleType === "accountName" ? "" : "accountName"
                      );
                    }}
                  >
                    {fetchAccInfo
                      ? fetchAccInfo.accountName
                      : isDealListModal ||
                        isContactListModal ||
                        isAccountListModal
                      ? selectedAccount?.moduleName
                      : selectedAccount?.accountName}
                  </span>

                  <span
                    className="position-absolute pt-2 cursorPointer"
                    // onClick={() => setSelectedAccount("")}
                    onClick={(e) => {
                      e.stopPropagation();
                      setListVisibleType(
                        listVisibleType === "accountName" ? "" : "accountName"
                      );
                    }}
                    style={{ right: "42%" }}
                  >
                    <img
                      src={listVisibleType === "accountName" ? up : down}
                      alt="img"
                      height={20}
                      width={20}
                    />
                  </span>

                  <span
                    className="cursorPointer pt-2 ps-1"
                    onClick={() => setIsAccountListModal(true)}
                  >
                    <img src={accountsIcon} alt="img" width={22} height={25} />
                  </span>
                </div>
                <span className="small erro_msg">{accNameError}</span>

                {listVisibleType === "accountName" && accountsDropdown("6rem")}
              </div>
            </div>
            <h6
              className="py-2 mt-4"
              style={{
                borderBottom: "2px solid #316AFF",
                fontSize: "18px",
                color: "#515151",
              }}
            >
              Address Information
            </h6>
            <div
              className="inputFieldDiv"
              style={{ width: "min-content", alignSelf: "end" }}
            >
              <Dropdown>
                <Dropdown.Toggle id="dropdown-basic">
                  Copy Address
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Item onClick={copyBillingToShipping}>
                    Billing to Shipping
                  </Dropdown.Item>
                  <Dropdown.Item onClick={copyShippingToBilling}>
                    Shipping to Billing
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
            <div className="inputFieldDiv">
              <div className="inputFieldDivLeft">
                <p className="mb-0">Billing Street</p>
                <input
                  type="text"
                  className="insertCommonInputField"
                  name="billingStreet"
                  style={{
                    width: "60%",
                    border: "1px solid #B9B9B94D",
                    height: "40px",
                    outline: "none",
                    borderRadius: "10px",
                  }}
                  value={billingAdd.billingStreet}
                  onChange={handleChangeBillingAddress}
                />
              </div>
              <div className="inputFieldDivRight">
                <p className="mb-0">Shipping Street</p>
                <input
                  type="text"
                  className="insertCommonInputField"
                  name="shippingStreet"
                  style={{
                    width: "60%",
                    border: "1px solid #B9B9B94D",
                    height: "40px",
                    outline: "none",
                    borderRadius: "10px",
                  }}
                  value={shippingAdd.shippingStreet}
                  onChange={handleChangeShippingAddress}
                />
              </div>
            </div>
            <div className="inputFieldDiv">
              <div className="inputFieldDivLeft">
                <p className="mb-0">Billing City</p>
                <input
                  type="text"
                  className="insertCommonInputField"
                  name="billingCity"
                  style={{
                    width: "60%",
                    border: "1px solid #B9B9B94D",
                    height: "40px",
                    outline: "none",
                    borderRadius: "10px",
                  }}
                  value={billingAdd.billingCity.replace(/[^a-zA-Z\s]/g, "")}
                  onChange={handleChangeBillingAddress}
                />
                <span className="small erro_msg">{billingCityError}</span>
              </div>
              <div className="inputFieldDivRight">
                <p className="mb-0">Shipping City</p>
                <input
                  type="text"
                  className="insertCommonInputField"
                  name="shippingCity"
                  style={{
                    width: "60%",
                    border: "1px solid #B9B9B94D",
                    height: "40px",
                    outline: "none",
                    borderRadius: "10px",
                  }}
                  value={shippingAdd.shippingCity.replace(/[^a-zA-Z\s]/g, "")}
                  onChange={handleChangeShippingAddress}
                />
                <span className="small erro_msg">{shippingCityError}</span>
              </div>
            </div>
            <div className="inputFieldDiv">
              <div className="inputFieldDivLeft">
                <p className="mb-0">Billing State</p>
                <input
                  type="text"
                  className="insertCommonInputField"
                  name="billingState"
                  style={{
                    width: "60%",
                    border: "1px solid #B9B9B94D",
                    height: "40px",
                    outline: "none",
                    borderRadius: "10px",
                  }}
                  value={billingAdd.billingState.replace(/[^a-zA-Z\s]/g, "")}
                  onChange={handleChangeBillingAddress}
                />
                <span className="small erro_msg">{billingStateError}</span>
              </div>
              <div className="inputFieldDivRight">
                <p className="mb-0">Shipping State</p>
                <input
                  type="text"
                  className="insertCommonInputField"
                  name="shippingState"
                  style={{
                    width: "60%",
                    border: "1px solid #B9B9B94D",
                    height: "40px",
                    outline: "none",
                    borderRadius: "10px",
                  }}
                  value={shippingAdd.shippingState.replace(/[^a-zA-Z\s]/g, "")}
                  onChange={handleChangeShippingAddress}
                />
                <span className="small erro_msg">{shippingStateError}</span>
              </div>
            </div>
            <div className="inputFieldDiv">
              <div className="inputFieldDivLeft">
                <p className="mb-0">Billing Code</p>
                <input
                  type="text"
                  className="insertCommonInputField"
                  name="billingCode"
                  pattern="[0-9]*"
                  style={{
                    width: "60%",
                    border: "1px solid #B9B9B94D",
                    height: "40px",
                    outline: "none",
                    borderRadius: "10px",
                  }}
                  value={billingAdd.billingCode.replace(/\D/g, "").slice(0, 6)}
                  onChange={handleChangeBillingAddress}
                  onKeyDown={(e) => {
                    // Allow only numeric input
                    if (
                      !/\d/.test(e.key) &&
                      e.key !== "Backspace" &&
                      e.key !== "Delete" &&
                      e.key !== "ArrowLeft" &&
                      e.key !== "ArrowRight"
                    ) {
                      e.preventDefault();
                    }
                  }}
                />
                <span className="small erro_msg">{billingCodeError}</span>
              </div>
              <div className="inputFieldDivRight">
                <p className="mb-0">Shipping Code</p>
                <input
                  type="text"
                  className="insertCommonInputField"
                  name="shippingCode"
                  style={{
                    width: "60%",
                    border: "1px solid #B9B9B94D",
                    height: "40px",
                    outline: "none",
                    borderRadius: "10px",
                  }}
                  onKeyDown={(e) => {
                    // Allow only numeric input
                    if (
                      !/\d/.test(e.key) &&
                      e.key !== "Backspace" &&
                      e.key !== "Delete" &&
                      e.key !== "ArrowLeft" &&
                      e.key !== "ArrowRight"
                    ) {
                      e.preventDefault();
                    }
                  }}
                  value={shippingAdd.shippingCode
                    .replace(/\D/g, "")
                    .slice(0, 6)}
                  onChange={handleChangeShippingAddress}
                />
                <span className="small erro_msg">{shippingCodeError}</span>
              </div>
            </div>
            <div className="inputFieldDiv">
              <div className="inputFieldDivLeft">
                <p className="mb-0">Billing Country</p>
                <input
                  type="text"
                  className="insertCommonInputField"
                  name="billingCountry"
                  style={{
                    width: "60%",
                    border: "1px solid #B9B9B94D",
                    height: "40px",
                    outline: "none",
                    borderRadius: "10px",
                  }}
                  value={billingAdd.billingCountry.replace(/[^a-zA-Z\s]/g, "")}
                  onChange={handleChangeBillingAddress}
                />
                <span className="small erro_msg">{billingCountryError}</span>
              </div>
              <div className="inputFieldDivRight">
                <p className="mb-0">Shipping Country</p>
                <input
                  type="text"
                  className="insertCommonInputField"
                  name="shippingCountry"
                  style={{
                    width: "60%",
                    border: "1px solid #B9B9B94D",
                    height: "40px",
                    outline: "none",
                    borderRadius: "10px",
                  }}
                  value={shippingAdd.shippingCountry?.replace(
                    /[^a-zA-Z\s]/g,
                    ""
                  )}
                  onChange={handleChangeShippingAddress}
                />
                <span className="small erro_msg">{shippingCountryError}</span>
              </div>
            </div>
            <h6
              className="py-2 mt-4"
              style={{
                borderBottom: "2px solid #316AFF",
                fontSize: "18px",
                color: "#515151",
              }}
            >
              Ordered Items<span className="text-danger">*</span>
            </h6>
            <div
              className="horizontal-scroll-container mb-2"
              style={{
                // overflowX: "auto",
                width: "100%",
                maxWidth: "100%",
                // overflow: "hidden",
                border: "none",
              }}
            >
              <table
                className="table"
                style={{
                  tableLayout: "fixed",
                  minWidth: "1000px",
                  margin: "0px",
                }}
              >
                <colgroup>
                  <col style={{ width: "1%" }} />
                  <col style={{ width: "4%" }} />
                  <col style={{ width: "12%" }} />
                  <col style={{ width: "6%" }} />
                  <col style={{ width: "7%" }} />
                  <col style={{ width: "6%" }} />
                  <col style={{ width: "7%" }} />
                  <col style={{ width: "6%" }} />
                  <col style={{ width: "6%" }} />
                </colgroup>
                <thead>
                  <tr className="mb-2">
                    <th
                      style={{
                        backgroundColor: "rgba(243, 246, 251, 1)",
                        border: "none",
                      }}
                    ></th>
                    <th
                      style={{
                        backgroundColor: "#EFEFEF",
                        border: "1px solid rgba(185, 185, 185, 0.5)",
                      }}
                    >
                      SL.NO
                    </th>
                    <th
                      style={{
                        backgroundColor: "#EFEFEF",
                        border: "1px solid rgba(185, 185, 185, 0.5)",
                      }}
                    >
                      Product Name
                    </th>
                    <th
                      style={{
                        backgroundColor: "#EFEFEF",
                        border: "1px solid rgba(185, 185, 185, 0.5)",
                      }}
                    >
                      Quantity
                    </th>
                    <th
                      style={{
                        backgroundColor: "#EFEFEF",
                        border: "1px solid rgba(185, 185, 185, 0.5)",
                      }}
                    >
                      List Price (₹)
                    </th>
                    <th
                      style={{
                        backgroundColor: "#EFEFEF",
                        border: "1px solid rgba(185, 185, 185, 0.5)",
                      }}
                    >
                      Amount (₹)
                    </th>
                    <th
                      style={{
                        backgroundColor: "#EFEFEF",
                        border: "1px solid rgba(185, 185, 185, 0.5)",
                      }}
                    >
                      Discount (₹)
                    </th>
                    <th
                      style={{
                        backgroundColor: "#EFEFEF",
                        border: "1px solid rgba(185, 185, 185, 0.5)",
                      }}
                    >
                      Tax (%)
                    </th>
                    <th
                      style={{
                        backgroundColor: "#EFEFEF",
                        border: "1px solid rgba(185, 185, 185, 0.5)",
                      }}
                    >
                      Total (₹)
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {rows.map((row, index) => (
                    <tr key={index}>
                      <td
                        style={{
                          backgroundColor: "rgba(243, 246, 251, 1)",
                          border: "none",
                          alignContent: "center",
                          padding: "0px",
                        }}
                      >
                        <button
                          className={` ${
                            rows.length <= 1 ? "opacity-0" : "last-button"
                          }`}
                          onClick={() => handleDeleteRow(index)}
                          style={{
                            backgroundColor: "transparent",
                            padding: "0px",
                          }}
                        >
                          X
                        </button>
                      </td>
                      <td
                        style={{
                          backgroundColor: "white",
                          border: "1px solid rgba(185, 185, 185, 0.5)",
                        }}
                      >
                        <input
                          type="number"
                          name="slNo"
                          value={index + 1}
                          style={{
                            border: "none",
                            borderRadius: "5px",
                            backgroundColor: "white",
                            color: "#2177FE",
                            width: "-webkit-fill-available",
                          }}
                          readOnly
                          disabled
                        />
                      </td>
                      <td
                        style={{
                          backgroundColor: "white",
                          border: "1px solid rgba(185, 185, 185, 0.5)",
                        }}
                      >
                        <div className="d-flex input-with-clear position-relative w-100">
                          {/* <input
                    type="text"
                    className="leadInputField cursorPointer"
                    defaultValue={selectedOwner.name}
                    readOnly
                    onClick={(e) => {
                      e.stopPropagation();
                      setListVisibleType(
                        listVisibleType === "purchaseOwner"
                          ? ""
                          : "purchaseOwner"
                      );
                    }}
                  /> */}
                          <span
                            type="text"
                            name="productName"
                            // value={row.productName}

                            // onChange={(e) => handleRowChange(index, e)}
                            onClick={(e) => {
                              e.stopPropagation();
                              setSelectedProductIndex(index);
                              setListVisibleType(
                                listVisibleType === "productId"
                                  ? ""
                                  : "productId"
                              );
                            }}
                            style={{
                              marginBottom: "10px",
                              borderRadius: "2.5px",
                              border: "1px solid rgba(185, 185, 185, 0.5)",
                              width: "-webkit-fill-available",
                            }}
                            readOnly
                            className="cursorPointer px-1"
                          >
                            {row.productName || selectedProduct.productName}
                          </span>

                          <span
                            className="position-absolute cursorPointer end-0"
                            onClick={(e) => {
                              e.stopPropagation();
                              setSelectedProductIndex(index);
                              setListVisibleType(
                                listVisibleType === "productId"
                                  ? ""
                                  : "productId"
                              );
                            }}
                          >
                            <img
                              src={
                                listVisibleType === "productId" &&
                                selectedProductIndex === index
                                  ? up
                                  : down
                              }
                              alt="img"
                              height={20}
                              width={20}
                            />
                          </span>
                          {listVisibleType === "productId" &&
                            selectedProductIndex === index &&
                            accountsDropdown("1.8rem", index)}
                        </div>
                        <textarea
                          type="text"
                          name="description"
                          value={row.description}
                          onChange={(e) => handleRowChange(index, e)}
                          placeholder="Add Description"
                          style={{
                            marginBottom: "10px",
                            borderRadius: "2.5px",
                            border: "1px solid rgba(185, 185, 185, 0.5)",
                            width: "-webkit-fill-available",
                          }}
                        />
                      </td>
                      <td
                        style={{
                          backgroundColor: "white",
                          border: "1px solid rgba(185, 185, 185, 0.5)",
                        }}
                      >
                        <input
                          type="number"
                          name="quantity"
                          value={row.quantity}
                          onChange={(e) => handleRowChange(index, e)}
                          style={{
                            marginBottom: "10px",
                            borderRadius: "2.5px",
                            border: "1px solid rgba(185, 185, 185, 0.5)",
                            width: "-webkit-fill-available",
                          }}
                        />
                      </td>
                      <td
                        style={{
                          backgroundColor: "white",
                          border: "1px solid rgba(185, 185, 185, 0.5)",
                        }}
                      >
                        <input
                          type="number"
                          name="listPrice"
                          value={row.listPrice}
                          onChange={(e) => handleRowChange(index, e)}
                          style={{
                            marginBottom: "10px",
                            borderRadius: "2.5px",
                            border: "1px solid rgba(185, 185, 185, 0.5)",
                            width: "-webkit-fill-available",
                          }}
                        />
                      </td>
                      <td
                        style={{
                          backgroundColor: "white",
                          border: "1px solid rgba(185, 185, 185, 0.5)",
                        }}
                      >
                        <input
                          type="number"
                          name="amount"
                          value={row.amount}
                          onChange={(e) => handleRowChange(index, e)}
                          style={{
                            marginBottom: "10px",
                            borderRadius: "2.5px",
                            border: "1px solid rgba(185, 185, 185, 0.5)",
                            width: "-webkit-fill-available",
                          }}
                          readOnly
                          disabled
                        />
                      </td>
                      <td
                        style={{
                          backgroundColor: "white",
                          border: "1px solid rgba(185, 185, 185, 0.5)",
                        }}
                        className="position-relative"
                      >
                        <div className="d-flex">
                          <input
                            type="number"
                            name="discount"
                            value={row.discount}
                            onChange={(e) => handleRowChange(index, e)}
                            style={{
                              marginBottom: "10px",
                              borderRadius: "2.5px",
                              border: "1px solid rgba(185, 185, 185, 0.5)",
                              width: "-webkit-fill-available",
                            }}
                            readOnly
                            disabled
                            className="me-3"
                          />
                          <span
                            onClick={(e) => {
                              e.stopPropagation();
                              setSelectedProductIndex(index);
                              setPopoutType(
                                popoutType === "discount" ? "" : "discount"
                              );
                            }}
                            style={{ marginLeft: "-0.7rem" }}
                            className="cursorPointer"
                          >
                            {editIcon}
                          </span>
                        </div>
                        {popoutType === "discount" &&
                          selectedProductIndex === index && (
                            <>{discountPopup(index)}</>
                          )}
                      </td>
                      <td
                        style={{
                          backgroundColor: "white",
                          border: "1px solid rgba(185, 185, 185, 0.5)",
                        }}
                      >
                        <input
                          type="number"
                          name="tax"
                          value={row.tax}
                          onChange={(e) => handleRowChange(index, e)}
                          style={{
                            marginBottom: "10px",
                            borderRadius: "2.5px",
                            border: "1px solid rgba(185, 185, 185, 0.5)",
                            width: "-webkit-fill-available",
                          }}
                          readOnly
                          disabled
                        />
                      </td>
                      <td
                        style={{
                          backgroundColor: "white",
                          border: "1px solid rgba(185, 185, 185, 0.5)",
                        }}
                      >
                        <input
                          type="number"
                          name="total"
                          value={row.total}
                          onChange={(e) => handleRowChange(index, e)}
                          style={{
                            marginBottom: "10px",
                            borderRadius: "2.5px",
                            border: "1px solid rgba(185, 185, 185, 0.5)",
                            width: "-webkit-fill-available",
                          }}
                          readOnly
                          disabled
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>

            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
              className="mt-3"
            >
              <button
                onClick={handleAddRow}
                style={{
                  height: "max-content",
                  borderRadius: "10px",
                  padding: "5px 25px 5px 25px",
                  borderColor: "#529CF3",
                  backgroundColor: "#E4F5FF",
                  color: "#515151",
                  marginLeft: "1.5%",
                }}
              >
                Add Row
              </button>
              <div
                style={{
                  display: "flex",
                  width: "max-content",
                  border: "1.86px solid rgba(226, 229, 235, 1)",
                  boxShadow: "0px 0px 18.12px 0px rgba(0, 0, 0, 0.03)",
                  background: "rgba(255, 255, 255, 1)",
                  borderRadius: "10px",
                }}
              >
                <table
                  className="table"
                  style={{
                    borderCollapse: "collapse",
                    width: "max-content",
                    margin: "0",
                    boxShadow: "0px 0px 18.12px 0px rgba(0, 0, 0, 0.03)",
                  }}
                >
                  {" "}
                  <thead style={{ width: "max-content" }}>
                    <tr className="mb-2">
                      <th
                        scope="col"
                        style={{
                          backgroundColor: "#F8F8F8",
                          width: "max-content",
                        }}
                      >
                        Sub Total(₹)
                      </th>
                      <td style={{ backgroundColor: "#F8F8F8" }}>
                        <input
                          type="text"
                          name="subTotal"
                          // value={saleInfo.subTotal}
                          value={rows
                            .reduce(
                              (sum, product) =>
                                parseFloat(sum) + parseFloat(product.total),
                              0
                            )
                            ?.toFixed(2)}
                          onChange={handleChangeSales}
                          style={{
                            borderRadius: "6px",
                            border: "1px solid rgba(185, 185, 185, 0.5)",
                            backgroundColor: "#F3F6FB",
                          }}
                          readOnly
                          disabled
                        />
                      </td>
                    </tr>
                    <tr>
                      {" "}
                      <th scope="col">Discount(₹)</th>
                      <td className="dicount_column position-relative">
                        <div className="d-flex">
                          <input
                            type="text"
                            name="discount"
                            value={saleInfo.discount || 0}
                            onChange={handleChangeSales}
                            style={{
                              borderRadius: "6px",
                              border: "1px solid rgba(185, 185, 185, 0.5)",
                              backgroundColor: "#F3F6FB",
                            }}
                            readOnly
                            disabled
                            className="me-3"
                          />
                          <span
                            onClick={(e) => {
                              e.stopPropagation();
                              setSelectedProductIndex("");
                              setPopoutType(
                                popoutType === "discount" ? "" : "discount"
                              );
                            }}
                            style={{ marginLeft: "-0.7rem" }}
                            className="cursorPointer discount_icon"
                          >
                            {editIcon}
                          </span>
                        </div>
                        {popoutType === "discount" &&
                          selectedProductIndex === "" && (
                            <>{discountPopup("")}</>
                          )}
                      </td>
                    </tr>
                    <tr>
                      <th
                        scope="col"
                        style={{
                          backgroundColor: "#F8F8F8",
                        }}
                      >
                        {/* ch */}
                        Tax(%)
                      </th>
                      <td
                        style={{ backgroundColor: "#F8F8F8" }}
                        className="dicount_column position-relative"
                      >
                        <div className="d-flex">
                          <input
                            type="number"
                            value={saleInfo.tax || 0}
                            onChange={handleChangeSales}
                            style={{
                              borderRadius: "6px",
                              border: "1px solid rgba(185, 185, 185, 0.5)",
                              backgroundColor: "#F3F6FB",
                            }}
                            readOnly
                            disabled
                          />
                          <span
                            onClick={(e) => {
                              e.stopPropagation();
                              setSelectedProductIndex("");
                              setPopoutType(popoutType === "tax" ? "" : "tax");
                            }}
                            className="cursorPointer discount_icon ps-1"
                          >
                            {editIcon}
                          </span>
                        </div>
                        {popoutType === "tax" &&
                          selectedProductIndex === "" && (
                            <>{discountPopup("")}</>
                          )}
                      </td>
                    </tr>
                    <tr>
                      <th scope="col">Adjustment(₹)</th>
                      <td>
                        <input
                          type="number"
                          name="adjustment"
                          value={saleInfo.adjustment}
                          onChange={(e) => {
                            const total = saleInfo.subTotal - saleInfo.discount;
                            const taxValue = (saleInfo.tax / 100) * total;
                            setSaleInfo((prevPurchaseInfo) => ({
                              ...prevPurchaseInfo,
                              adjustment: e.target.value,
                              grandTotal: (
                                parseFloat(total) +
                                parseFloat(taxValue || 0) +
                                parseFloat(e.target.value || 0)
                              ).toFixed(2),
                            }));
                          }}
                          style={{
                            borderRadius: "6px",
                            border: "1px solid rgba(185, 185, 185, 0.5)",
                            backgroundColor: "transparent",
                          }}
                        />
                      </td>
                    </tr>
                    <tr>
                      <th
                        scope="col"
                        style={{
                          backgroundColor: "#F8F8F8",
                        }}
                      >
                        Grand Total(₹)
                      </th>
                      <td style={{ backgroundColor: "#F8F8F8" }}>
                        <input
                          type="number"
                          name="grandTotal"
                          value={saleInfo.grandTotal}
                          onChange={handleChangeSales}
                          style={{
                            borderRadius: "6px",
                            border: "1px solid rgba(185, 185, 185, 0.5)",
                            backgroundColor: "#F3F6FB",
                          }}
                          readOnly
                          disabled
                        />
                      </td>
                    </tr>
                  </thead>
                </table>
              </div>
            </div>
            <h6
              className="py-2 mt-3"
              style={{
                borderBottom: "2px solid #316AFF",
                fontSize: "18px",
                color: "#515151",
                marginBottom: "0px",
              }}
            >
              Terms and Conditions
            </h6>
            <div className="inputFieldDiv" style={{ display: "block" }}>
              <div
                className="inputFieldDivLeft"
                style={{ display: "block", width: "100%" }}
              >
                <p>Terms and Conditions</p>
                <textarea
                  rows="4"
                  cols="50"
                  maxLength="2000"
                  style={{
                    width: "100%",
                    border: "1px solid #B9B9B94D",
                    height: "100px",
                    outline: "none",
                    borderRadius: "10px",
                    backgroundColor: "transparent",
                  }}
                  name="termsAndConditions"
                  value={saleInfo.termsAndConditions}
                  onChange={handleChangeSales}
                  className="ps-2 pt-2 pb-4"
                />
              </div>
            </div>
            <h6
              className="py-2 mt-3"
              style={{
                borderBottom: "2px solid #316AFF",
                fontSize: "18px",
                color: "#515151",
                marginBottom: "0px",
              }}
            >
              Description Information
            </h6>
            <div className="inputFieldDiv" style={{ display: "block" }}>
              <div
                className="inputFieldDivLeft"
                style={{ display: "block", width: "100%" }}
              >
                <p>Description</p>
                <textarea
                  rows="4"
                  cols="50"
                  maxLength="2000"
                  name="description"
                  style={{
                    width: "100%",
                    border: "1px solid #B9B9B94D",
                    height: "100px",
                    outline: "none",
                    borderRadius: "10px",
                    backgroundColor: "transparent",
                    padding: "8px 12px",
                  }}
                  value={saleInfo.description}
                  onChange={handleChangeSales}
                />
              </div>
            </div>
          </div>
        </>
      );
    } else if (innerPage === "overview") {
      return (
        <>
          <div
            className="indiv_main_container "
            // onBlur={() => setShowOptionsLead(false)}
          >
            <div className="individual_header_container commonContainer_bg w-100  flex-column  d-flex justify-content-between">
              <div className="iconContainer d-flex justify-content-between px-2 pt-2">
                <span
                  className="cursorPointer"
                  onClick={() => {
                    getAllSales(selectedOption);
                    setInnerPage("list");
                    setFetchSalesInfo(null);
                    const resetFields = Object.fromEntries(
                      Object.keys(editingFields).map((key) => [key, false])
                    );
                    setEditingFields({
                      ...resetFields,
                    });
                    setShowType("");
                  }}
                >
                  {leftArrow}
                </span>
                <span>
                  <span
                    className={`me-2 ${
                      selectedSalesId === 0 ? "opacity-50" : "cursorPointer"
                    }`}
                    onClick={() => {
                      const leadIndex = salesDetails.findIndex(
                        (contact) => contact.salesOrdId === salesOrdId
                      );
                      if (leadIndex !== 0) {
                        setSelectedSalesId(leadIndex - 1);
                        setSalesOrdId(salesDetails[leadIndex - 1].salesOrdId);
                        salesOverviewDetail(
                          salesDetails[leadIndex - 1].salesOrdId
                        );
                        getNotesDetails(
                          salesDetails[leadIndex - 1].salesOrdId,
                          selectRecent
                        );
                        setFetchSalesInfo(salesDetails[leadIndex - 1]);
                      }
                    }}
                  >
                    {leftAngleArrow}
                  </span>
                  <span
                    className={`ms-1 ${
                      selectedSalesId === salesDetails.length - 1
                        ? "opacity-50"
                        : "cursorPointer"
                    }`}
                    onClick={() => {
                      const leadIndex = salesDetails.findIndex(
                        (lead) => lead.salesOrdId === salesOrdId
                      );
                      if (leadIndex < salesDetails.length - 1) {
                        setSelectedSalesId(leadIndex + 1);
                        setSalesOrdId(salesDetails[leadIndex + 1].salesOrdId);
                        salesOverviewDetail(
                          salesDetails[leadIndex + 1].salesOrdId
                        );
                        getNotesDetails(
                          salesDetails[leadIndex + 1].salesOrdId,
                          selectRecent
                        );
                        setFetchSalesInfo(salesDetails[leadIndex + 1]);
                      }
                    }}
                  >
                    {rightAngleArrow}
                  </span>
                </span>
              </div>
              <div>
                <div className="profile_logo_container d-flex pt-2">
                  <div className="d-flex flex-column  justify-content-end ps-2 pb-2 ">
                    <span className="first_letter_uppercase">
                      {salesInfoDetails?.subject}
                    </span>
                  </div>
                </div>
                <div className="d-flex  justify-content-end align-items-end  px-2 pb-2">
                  <div className="d-flex gap-2">
                    {/* <button className="btn  btnColor text-white">
                      Send Email
                    </button> */}
                    <button
                      className="btn btn-secondary bg-white text-dark border border-primary"
                      onClick={handleEditSalesOrder}
                    >
                      Edit
                    </button>
                    <button
                      className="btn btn-secondary bg-white text-dark border border-primary"
                      onClick={(e) => {
                        e.stopPropagation();
                        setShowOptionsLead(true);
                      }}
                    >
                      ---
                    </button>
                    {showOptionsLead && (
                      <div
                        className="rounded  showOptions p-2"
                        style={{ marginTop: "4rem" }}
                      >
                        <div
                          className="d-flex gap-3 align-items-center mt-2"
                          onClick={(e) => {
                            e.stopPropagation();
                            setShowOptionsSales(false);
                            setEditSalesOverview(false);
                            setInnerPage("clone");
                            setSaleInfo({
                              ...singleSalesDetails.salesInfo,
                              dueDate: fetchDateFormatConversion(
                                singleSalesDetails.salesInfo.dueDate
                              ),
                              carrier: {
                                label: singleSalesDetails.salesInfo.carrier,
                                value: singleSalesDetails.salesInfo.carrier,
                              },
                              status: {
                                label: singleSalesDetails.salesInfo.status,
                                value: singleSalesDetails.salesInfo.status,
                              },

                              adjustment:
                                singleSalesDetails.salesItemsTotDtls.adjustment,
                              discount:
                                singleSalesDetails.salesItemsTotDtls.discount,
                              grandTotal:
                                singleSalesDetails.salesItemsTotDtls.grandTotal,
                              purchaseId:
                                singleSalesDetails.salesItemsTotDtls.purchaseId,
                              subTotal:
                                singleSalesDetails.salesItemsTotDtls.subTotal,
                              tax: singleSalesDetails.salesItemsTotDtls.tax,
                              discountType:
                                singleSalesDetails.salesItemsTotDtls
                                  .discountType || 1,
                              discountValue:
                                singleSalesDetails.salesItemsTotDtls
                                  .discountValue || 0,
                            });
                            setSalesItemsTotalInfoDetails(
                              singleSalesDetails.salesItemsTotDtls
                            );
                            setBillingAdd({
                              billingStreet:
                                singleSalesDetails.salesInfo.billingStreet,
                              billingCity:
                                singleSalesDetails.salesInfo.billingCity,
                              billingState:
                                singleSalesDetails.salesInfo.billingState,
                              billingCode:
                                singleSalesDetails.salesInfo.billingCode,
                              billingCountry:
                                singleSalesDetails.salesInfo.billingCountry,
                            });
                            setShippingAdd({
                              shippingStreet:
                                singleSalesDetails.salesInfo.shippingStreet,
                              shippingCity:
                                singleSalesDetails.salesInfo.shippingCity,
                              shippingState:
                                singleSalesDetails.salesInfo.shippingState,
                              shippingCode:
                                singleSalesDetails.salesInfo.shippingCode,
                              shippingCountry:
                                singleSalesDetails.salesInfo.shippingCountry,
                            });
                          }}
                        >
                          <img src={Clone} alt="close" />
                          <div
                            style={{ color: "#515151", fontWeight: "300" }}
                            className="mt-1 cursorPointer ms-2"
                          >
                            Clone
                          </div>
                        </div>
                        {salesOverviewDetails.isShared === false && (
                          <div
                            className="d-flex gap-3 align-items-center mt-2"
                            onClick={() => {
                              if (!isLoading) {
                                getAllInfoShare(salesOrdId);
                              }
                            }}
                          >
                            <img src={Share} alt="close" />
                            <div
                              style={{ color: "#515151", fontWeight: "300" }}
                              className="mt-2 cursorPointer ms-2"
                            >
                              Share
                            </div>
                          </div>
                        )}

                        <div
                          className="d-flex gap-3 align-items-center mt-2"
                          onClick={handleDeleteSalesOrder}
                        >
                          <img src={Delete} alt="close" />
                          <div
                            style={{ color: "#515151", fontWeight: "300" }}
                            className="mt-2 cursorPointer ms-2"
                          >
                            Delete
                          </div>
                        </div>
                        <div
                          style={{ borderBottom: "1px solid #515151s" }}
                        ></div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div className="detailList_main_container mt-2 commonContainer_bg">
              <div className="detailList_main_container d-flex py-2 ">
                <div
                  className={` pt-3 cursorPointer ${isShrinked ? "" : "w-25"}`}
                  onClick={() => {
                    setIsShrinked(!isShrinked);
                  }}
                >
                  {isShrinked ? expandArrow : shrinkArrow}
                </div>
                <div
                  className={`d-flex justify-content-between px-2 ${
                    isShrinked ? "w-100" : "w-75 ps-3"
                  }`}
                >
                  <div className="pt-3 ps-2">
                    Last Update: {salesOverviewDetails.lastUpdate}
                  </div>
                  <div className="d-flex  gap-4 justify-content-between px-2 bg-white px-1  rounded-5 py-1">
                    <span
                      // className=""
                      className={`cursorPointer } ${
                        salesIndivPage === "overview"
                          ? "overviewTimelineTab "
                          : "  d-flex justify-content-center align-items-center"
                      }`}
                      onClick={() => setSalesIndivPage("overview")}
                    >
                      Overview
                    </span>
                    <span
                      className={`cursorPointer } ${
                        salesIndivPage === "timeline"
                          ? "overviewTimelineTab"
                          : " d-flex justify-content-center align-items-center"
                      }`}
                      onClick={() => {
                        setSalesIndivPage("timeline");
                        getAllTimelineSales(salesOrdId);
                      }}
                    >
                      Timeline
                    </span>
                  </div>
                </div>
              </div>
              <div className="detailList_content_container d-flex justify-content-between px-2 pt-2 bg-white">
                <div
                  className={`w-25 relatedListContainer  rounded commonContainer_bg me-2 ${
                    isShrinked ? "d-none" : ""
                  }`}
                  // style={{ height: "100vh" }}
                >
                  <div className="d-flex">
                    <p className="opacity-57 ps-2 opacity-75  m-0 py-2">
                      Related List
                    </p>
                  </div>
                  <div className="horizontal_line mx-1"></div>
                  <ul className="relateList_container">
                    {notePermission === "TRUE" && (
                      <li
                        className={`cursorPointer py-2 relatedList_tab rounded-1 }`}
                        onClick={() => {
                          if (salesIndivPage === "timeline") {
                            setSalesIndivPage("overview");
                            setTimeout(
                              () => scrollToSection("notesSection"),
                              0
                            );
                            // setActiveTab("note");
                          } else {
                            // setActiveTab("note");
                            scrollToSection("notesSection");
                          }
                        }}
                      >
                        <span>
                          <img
                            src={NoteBook}
                            alt="img"
                            height={20}
                            width={20}
                          />
                        </span>
                        Note
                      </li>
                    )}

                    <li
                      className={`cursorPointer py-2 relatedList_tab rounded-1 }`}
                      onClick={() => {
                        if (salesIndivPage === "timeline") {
                          setSalesIndivPage("overview");
                          setTimeout(() => scrollToSection("invoices"), 0);
                          // setActiveTab("invoices");
                        } else {
                          // setActiveTab("invoices");
                          scrollToSection("invoices");
                        }
                      }}
                    >
                      <span>
                        <img
                          src={attachIcon}
                          alt="img"
                          height={20}
                          width={20}
                        />
                      </span>
                      Invoices
                    </li>
                    <li
                      className={`cursorPointer py-2 relatedList_tab rounded-1 }`}
                      onClick={() => {
                        if (salesIndivPage === "timeline") {
                          setSalesIndivPage("overview");
                          setTimeout(() => scrollToSection("openActivity"), 0);
                          // setActiveTab("openActivity");
                        } else {
                          // setActiveTab("openActivity");
                          scrollToSection("openActivity");
                        }
                      }}
                    >
                      <span>
                        <img
                          src={openActivityIcon}
                          alt="img"
                          height={20}
                          width={20}
                        />
                      </span>
                      Open Activity
                    </li>
                    <li
                      className={`cursorPointer py-2 relatedList_tab rounded-1 }`}
                      onClick={() => {
                        if (salesIndivPage === "timeline") {
                          setSalesIndivPage("overview");
                          setTimeout(() => scrollToSection("closeActivity"), 0);
                          // setActiveTab("closeActivity");
                        } else {
                          // setActiveTab("closeActivity");
                          scrollToSection("closeActivity");
                        }
                      }}
                    >
                      <span>
                        <img
                          src={closeActivityIcon}
                          alt="img"
                          height={20}
                          width={20}
                        />
                      </span>
                      Closed Activity
                    </li>
                  </ul>
                </div>
                {salesIndivPage === "overview" ? (
                  <>
                    <div
                      className={`common_bg bg-white w-75 rounded-2 leadDetail_main_Container ${
                        isShrinked ? "w-100" : "w-75"
                      }`}
                    >
                      <div className="py-3 rounded-2 commonContainer_bg d-flex justify-content-between align-items-center">
                        <div className="w-100 d-flex flex-column">
                          {fieldLabels.map((field, i) => {
                            const fieldValue =
                              field.value ||
                              field.name
                                .replace(/\s+/g, "")
                                .replace(/^(.)/, (match) =>
                                  match.toLowerCase()
                                );
                            return field.isShowed ? (
                              <div className="d-flex w-50 ps-3" key={i}>
                                <div className="w-50 position-relative">
                                  <div
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      setSelectedField(
                                        selectedField === field.name
                                          ? ""
                                          : field.name
                                      );
                                      setSearchField("");
                                    }}
                                    className={`cursorPointer d-flex justify-content-between w-100 my-1 p-1 ${
                                      selectedField === field.name
                                        ? "outline_border rounded-1"
                                        : ""
                                    }`}
                                  >
                                    <span>{field.name}</span>
                                    <img
                                      src={
                                        selectedField === field.name ? up : down
                                      }
                                      alt="img"
                                      height={20}
                                      width={20}
                                      className="pt-1"
                                    />
                                  </div>
                                  {selectedField === field.name && (
                                    <div
                                      className="position-absolute field_container w-100"
                                      onClick={(e) => {
                                        e.stopPropagation();
                                      }}
                                    >
                                      <div className="">
                                        <label
                                          htmlFor="selectField"
                                          className="position-relative w-100"
                                        >
                                          <input
                                            id="selectField"
                                            type="text"
                                            className="rounded-2 p-1 w-100 pe-4"
                                            placeholder="Search"
                                            value={searchField}
                                            onChange={(e) => {
                                              if (
                                                e.target.value.charAt(0) !== " "
                                              ) {
                                                const value =
                                                  e.target.value.replace(
                                                    /[^a-zA-Z ]+|(\s)\s+/g,
                                                    "$1"
                                                  );
                                                setSearchField(value);
                                              }
                                            }}
                                          />
                                          <img
                                            className="position-absolute mt-1 me-2 end-0 pt-1 cursorPointer"
                                            onClick={() => {
                                              setSearchField("");
                                            }}
                                            src={searchIcon}
                                            alt="search"
                                            width={20}
                                          />
                                        </label>

                                        <div className="w-100 rounded-2">
                                          <div className="border border-1 field_container_dropdown rounded-1">
                                            {showSearchFields
                                              .filter((field) =>
                                                field.name
                                                  .toLowerCase()
                                                  .includes(
                                                    searchField
                                                      .trim()
                                                      .toLowerCase()
                                                  )
                                              )
                                              .sort((a, b) =>
                                                a.name.localeCompare(b.name)
                                              )
                                              .map(
                                                (field, i) =>
                                                  (selectedField ===
                                                    field.name ||
                                                    !field.isShowed) && (
                                                    <div
                                                      key={field.name}
                                                      className="d-flex gap-2 p-2 cursorPointer rounded-2 align-items-center"
                                                      onClick={() => {
                                                        selectedField ===
                                                        field.name
                                                          ? setSelectedField("")
                                                          : handleFieldClick(
                                                              field,
                                                              i
                                                            );
                                                      }}
                                                    >
                                                      <span className="right_tick">
                                                        {selectedField ===
                                                        field.name
                                                          ? tick
                                                          : ""}
                                                      </span>
                                                      <span className="ps-2">
                                                        {field.name}
                                                      </span>
                                                    </div>
                                                  )
                                              )}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  )}
                                </div>
                                <div
                                  className={`w-50 mt-1 ms-5 position-relative`}
                                >
                                  {field.type === "input" ? (
                                    // <span>
                                    //   {salesOverviewDetails[fieldValue]}
                                    // </span>
                                    <EditableInput
                                      fieldType={
                                        ["dueDate", "dueDateInfo"].includes(
                                          fieldValue
                                        )
                                          ? "date"
                                          : "text"
                                      }
                                      className="ps-3"
                                      inputType={fieldValue}
                                      handleIndividualInputChange={
                                        handleIndividualInputChange
                                      }
                                      editingFields={editingFields}
                                      handleEditClick={handleEditClick}
                                      handleCancelClick={handleCancelClick}
                                      handleUpdateClick={handleUpdateClick}
                                      updatedFields={salesInfoDetails}
                                      accountOverviewDetails={salesInfoDetails}
                                      setShowType={setShowType}
                                      showType={showType}
                                    />
                                  ) : field.type === "dropdown" ? (
                                    <div
                                      className="position-relative d-flex"
                                      style={{ alignItems: "center" }}
                                      onClick={() => {
                                        handleEditClick(fieldValue);
                                      }}
                                    >
                                      {editingFields[fieldValue] &&
                                      showType === fieldValue ? (
                                        <Select
                                          value={salesInfoDetails[fieldValue]}
                                          // value={accountInfoDetails[fieldValue]}
                                          menuPlacement="auto"
                                          onChange={(selectedOption) =>
                                            handleChangeSelectDropDown(
                                              selectedOption,
                                              fieldValue
                                            )
                                          }
                                          options={
                                            fieldValue === "status"
                                              ? stausOptions
                                              : carrierOptions
                                          }
                                          styles={fieldDropdownStyles}
                                          isSearchable={false}
                                        />
                                      ) : (
                                        <input
                                          type="text"
                                          className={`cursorPointer indvEditInput
                ${
                  showType === fieldValue
                    ? "selectedBorder "
                    : "unselectBorder bg-transparent"
                }`}
                                          value={
                                            salesInfoDetails[fieldValue].value
                                              ? salesInfoDetails[fieldValue]
                                                  .value
                                              : salesInfoDetails[fieldValue]
                                          }
                                          name={fieldValue}
                                          onClick={(e) => {
                                            if (showType === "") {
                                              setShowType(e.target.name);
                                              setSalesInfoDetails(
                                                salesInfoDetails
                                              );
                                              setSaleInfo(saleInfo);
                                            }
                                            handleEditClick(fieldValue);
                                          }}
                                          // readOnly={showType !== "emailId"}
                                          readOnly={true}
                                        />
                                      )}
                                      {editingFields[fieldValue] &&
                                        showType === fieldValue && (
                                          <div className="d-flex gap-2">
                                            <span
                                              onClick={() => {
                                                setShowType("");
                                                handleUpdateClick(fieldValue);
                                              }}
                                              className="ps-2 cursorPointer"
                                            >
                                              <img
                                                src={updateIcon}
                                                alt="img"
                                                height={20}
                                                width={20}
                                              />
                                            </span>
                                            <span
                                              onClick={() => {
                                                setShowType("");
                                                // handleUpdateClick(inputType);
                                                handleCancelClick(fieldValue);
                                              }}
                                              className="cursorPointer"
                                            >
                                              <img
                                                src={cancelIcon}
                                                alt="img"
                                                height={20}
                                                width={20}
                                              />
                                            </span>
                                          </div>
                                        )}
                                    </div>
                                  ) : field.type === "other" ? (
                                    <div
                                      className="position-relative"
                                      // onClick={() => {
                                      //   handleEditClick(fieldValue);
                                      // }}
                                    >
                                      <div
                                      // className="position-relative"
                                      >
                                        <div
                                        // className="position-absolute "
                                        // style={{
                                        //   marginRight: "-0.7rem",
                                        // }}
                                        >
                                          <div
                                            className="d-flex gap-1"
                                            onClick={() => {
                                              setShowModalType("changeOwner");
                                              setListVisible(false);
                                            }}
                                          >
                                            <input
                                              type="text"
                                              //   placeholder="Vendor Owner"
                                              className={`w-100  cursorPointer  rounded-2  indvEditInput border-0 indivOwnerInput ${
                                                showType === "salesOwnerId"
                                                  ? "selectedBorder "
                                                  : "unselectBorder bg-transparent"
                                              }`}
                                              value={selectedOwnerItem?.name}
                                              readOnly
                                              style={{
                                                background: "transparent",
                                                border: "none",
                                              }}
                                            />

                                            {/* <span
                                              className="position-absolute end-0"
                                              style={{
                                                marginRight: "-1.6rem",
                                              }}
                                            >
                                              <span
                                                className="cursorPointer pb-1 ps-1"
                                              
                                                onClick={() => {
                                                  setShowModalType(
                                                    "changeOwner"
                                                  );
                                                  setListVisible(false);
                                                }}
                                              >
                                                <img
                                                  src={userSearch}
                                                  alt="img"
                                                  width={20}
                                                  height={20}
                                                />
                                              </span>
                                            </span> */}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  ) : field.type === "readOnly" ? (
                                    <div>
                                      <span className="d-flex flex-column">
                                        <span>
                                          {field.name === "Quote Name" ||
                                          field.name === "Account Name" ||
                                          field.name === "Deal Name" ||
                                          field.name === "Contact Name" ||
                                          field.name === "Created By" ||
                                          field.name === "SO Number" ||
                                          field.name === "Modified By"
                                            ? salesOverviewDetails[
                                                field.name === "Quote Name"
                                                  ? "quoteName"
                                                  : field.name ===
                                                    "Account Name"
                                                  ? "accountName"
                                                  : field.name === "Deal Name"
                                                  ? "dealName"
                                                  : field.name ===
                                                    "Contact Name"
                                                  ? "contactName"
                                                  : field.name === "Created By"
                                                  ? "createdBy"
                                                  : field.name === "SO Number"
                                                  ? "soNumber"
                                                  : field.name === "Tax"
                                                  ? "tax"
                                                  : field.name === "Grand Total"
                                                  ? "grandTotal"
                                                  : field.name === "Sub Total"
                                                  ? "subTotal"
                                                  : field.name === "Adjustment"
                                                  ? "adjustment"
                                                  : "modifiedBy"
                                              ]
                                            : salesItemsTotalInfoDetails[
                                                field.name === "Tax"
                                                  ? "tax"
                                                  : field.name === "Grand Total"
                                                  ? "grandTotal"
                                                  : field.name === "Sub Total"
                                                  ? "subTotal"
                                                  : "adjustment"
                                              ]}
                                        </span>
                                        <span className="small opacity-75">
                                          {
                                            salesOverviewDetails[
                                              field.name === "Quote Name"
                                                ? ""
                                                : field.name === "Account Name"
                                                ? ""
                                                : field.name === "Deal Name"
                                                ? ""
                                                : field.name === "Contact Name"
                                                ? ""
                                                : field.name === "Created By"
                                                ? "createdDate"
                                                : field.name === "SO Number"
                                                ? ""
                                                : field.name === "Grand Total"
                                                ? ""
                                                : field.name === "Tax"
                                                ? ""
                                                : field.name === "Sub Total"
                                                ? ""
                                                : field.name === "Adjustment"
                                                ? ""
                                                : "modifiedDate"
                                            ]
                                          }
                                        </span>
                                      </span>
                                    </div>
                                  ) : (
                                    <div className="">
                                      <div className="d-flex ">
                                        <input
                                          type="text"
                                          className={`w-100 cursorPointer  rounded-2  indvEditInput border-0 indivOwnerInput ${
                                            showType === "salesOwnerId"
                                              ? "selectedBorder "
                                              : "unselectBorder bg-transparent"
                                          }`}
                                          value={salesInfoDetails[fieldValue]}
                                          readOnly
                                        />
                                      </div>
                                    </div>
                                  )}
                                </div>
                              </div>
                            ) : null;
                          })}
                        </div>
                      </div>
                      <div className="lead_Information_mainContainer commonContainer_bg rounded-2 d-flex flex-column my-2 pt-2 ps-2 pe-4">
                        <h5 className="w-100 text-primary px-1 pt-3">
                          Sales Order Information
                        </h5>

                        <div className="d-flex justify-content-between">
                          <div className="leadContact_container d-flex gap-4 w-100 ps-2 pe-2">
                            <div className="w-50 ">
                              {fieldIndividualDropdowns
                                .slice(0, 9)
                                .map((field) => {
                                  const fieldValue =
                                    field.value ||
                                    field.name
                                      .replace(/\s+/g, "")
                                      .replace(/^(.)/, (match) =>
                                        match.toLowerCase()
                                      );
                                  return (
                                    <div
                                      className="d-flex justify-content-between align-items-center my-2 w-100 gap-3"
                                      key={fieldValue}
                                    >
                                      <div className="opacity-75 p-2 w-100">
                                        {field.name}
                                      </div>
                                      <div className="w-100">
                                        {field.type === "other" ? (
                                          <div
                                            className="position-relative"
                                            // onClick={() => {
                                            //   handleEditClick(fieldValue);
                                            // }}
                                          >
                                            <div
                                            // className="position-relative"
                                            >
                                              <div
                                              // className="position-absolute "
                                              // style={{
                                              //   marginRight: "-0.7rem",
                                              // }}
                                              >
                                                <div
                                                  className="d-flex gap-1"
                                                  onClick={() => {
                                                    setShowModalType(
                                                      "changeOwner"
                                                    );
                                                    setListVisible(false);
                                                  }}
                                                >
                                                  <input
                                                    type="text"
                                                    //   placeholder="Vendor Owner"
                                                    className={`w-100  cursorPointer  rounded-2  indvEditInput border-0 indivOwnerInput ${
                                                      showType ===
                                                      "salesOwnerId"
                                                        ? "selectedBorder "
                                                        : "unselectBorder bg-transparent"
                                                    }`}
                                                    value={
                                                      selectedOwnerItem?.name
                                                    }
                                                    readOnly
                                                    style={{
                                                      background: "transparent",
                                                      border: "none",
                                                    }}
                                                  />

                                                  {/* <span
                                                  className="position-absolute end-0"
                                                  style={{
                                                    marginRight: "-1.6rem",
                                                  }}
                                                >
                                                  <span
                                                    className="cursorPointer pb-1 ps-1"
                                                  
                                                    onClick={() => {
                                                      setShowModalType(
                                                        "changeOwner"
                                                      );
                                                      setListVisible(false);
                                                    }}
                                                  >
                                                    <img
                                                      src={userSearch}
                                                      alt="img"
                                                      width={20}
                                                      height={20}
                                                    />
                                                  </span>
                                                </span> */}
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        ) : field.type === "input" ? (
                                          <EditableInput
                                            fieldType={
                                              [
                                                "dueDate",
                                                "dueDateInfo",
                                              ].includes(fieldValue)
                                                ? "date"
                                                : "text"
                                            }
                                            className="ps-3"
                                            inputType={fieldValue}
                                            handleIndividualInputChange={
                                              handleIndividualInputChange
                                            }
                                            editingFields={editingFields}
                                            handleEditClick={handleEditClick}
                                            handleCancelClick={
                                              handleCancelClick
                                            }
                                            handleUpdateClick={
                                              handleUpdateClick
                                            }
                                            updatedFields={salesInfoDetails}
                                            accountOverviewDetails={
                                              salesInfoDetails
                                            }
                                            setShowType={setShowType}
                                            showType={showType}
                                            fieldsType="second"
                                          />
                                        ) : field.type === "dropdown" ? (
                                          <div
                                            className="position-relative d-flex w-100"
                                            style={{ alignItems: "center" }}
                                            onClick={() => {
                                              handleEditClick(fieldValue);
                                            }}
                                          >
                                            {editingFields[fieldValue] &&
                                            showType === fieldValue ? (
                                              <Select
                                                value={
                                                  salesInfoDetails[fieldValue]
                                                }
                                                // value={accountInfoDetails[fieldValue]}
                                                menuPlacement="auto"
                                                onChange={(selectedOption) =>
                                                  handleChangeSelectDropDown(
                                                    selectedOption,
                                                    fieldValue
                                                  )
                                                }
                                                options={
                                                  fieldValue === "status"
                                                    ? stausOptions
                                                    : carrierOptions
                                                }
                                                styles={fieldDropdownStyles}
                                                isSearchable={false}
                                              />
                                            ) : (
                                              <input
                                                type="text"
                                                className={`w-100 cursorPointer indvEditInput
                              ${
                                showType === fieldValue
                                  ? "selectedBorder "
                                  : "unselectBorder bg-transparent"
                              }`}
                                                value={
                                                  saleInfo[fieldValue]?.value
                                                }
                                                name={fieldValue}
                                                onClick={(e) => {
                                                  if (showType === "") {
                                                    // purchaseInfo[fieldValue] =
                                                    // purchaseInfo[fieldValue];
                                                    setShowType(e.target.name);
                                                    setSalesInfoDetails(
                                                      salesInfoDetails
                                                    );
                                                    setSaleInfo(saleInfo);
                                                  }
                                                  handleEditClick(fieldValue);
                                                }}
                                                // readOnly={showType !== "emailId"}
                                                readOnly={true}
                                              />
                                            )}
                                            {editingFields[fieldValue] &&
                                              showType === fieldValue && (
                                                <div className="d-flex gap-2">
                                                  <span
                                                    onClick={() => {
                                                      setShowType("");
                                                      handleUpdateClick(
                                                        fieldValue
                                                      );
                                                    }}
                                                    className="ps-2 cursorPointer"
                                                  >
                                                    <img
                                                      src={updateIcon}
                                                      alt="img"
                                                      height={20}
                                                      width={20}
                                                    />
                                                  </span>
                                                  <span
                                                    onClick={() => {
                                                      setShowType("");
                                                      // handleUpdateClick(inputType);
                                                      handleCancelClick(
                                                        fieldValue
                                                      );
                                                    }}
                                                    className="cursorPointer"
                                                  >
                                                    <img
                                                      src={cancelIcon}
                                                      alt="img"
                                                      height={20}
                                                      width={20}
                                                    />
                                                  </span>
                                                </div>
                                              )}
                                          </div>
                                        ) : field.type === "readOnly" ? (
                                          <div>
                                            <span className="d-flex flex-column">
                                              <span>
                                                {
                                                  salesOverviewDetails[
                                                    field.name === "Quote Name"
                                                      ? "quoteName"
                                                      : field.name ===
                                                        "Account Name"
                                                      ? "accountName"
                                                      : field.name ===
                                                        "Deal Name"
                                                      ? "dealName"
                                                      : field.name ===
                                                        "Contact Name"
                                                      ? "contactName"
                                                      : field.name ===
                                                        "Created By"
                                                      ? "createdBy"
                                                      : field.name ===
                                                        "SO Number"
                                                      ? "soNumber"
                                                      : "modifiedBy"
                                                  ]
                                                }
                                              </span>
                                              <span className="small opacity-75">
                                                {
                                                  salesOverviewDetails[
                                                    field.name === "Quote Name"
                                                      ? ""
                                                      : field.name ===
                                                        "Account Name"
                                                      ? ""
                                                      : field.name ===
                                                        "Deal Name"
                                                      ? ""
                                                      : field.name ===
                                                        "Contact Name"
                                                      ? ""
                                                      : field.name ===
                                                        "Created By"
                                                      ? "createdDate"
                                                      : field.name ===
                                                        "SO Number"
                                                      ? ""
                                                      : "modifiedDate"
                                                  ]
                                                }
                                              </span>
                                            </span>
                                          </div>
                                        ) : (
                                          <div className="position-relative">
                                            <div
                                              className="d-flex input-with-clear position-relative"
                                              // onClick={(e) => {
                                              //   e.stopPropagation();
                                              //   setListVisibleType(
                                              //     listVisibleType === fieldValue
                                              //       ? ""
                                              //       : fieldValue
                                              //   );
                                              //   setSelectModalType(fieldValue);
                                              // }}
                                            >
                                              <input
                                                type="text"
                                                className={`cursorPointer indvEditInput rounded-1 w-100 ${
                                                  showType === "salesOwnerId"
                                                    ? "selectedBorder "
                                                    : "unselectBorder bg-transparent"
                                                }`}
                                                value={
                                                  salesInfoDetails[fieldValue]
                                                }
                                                readOnly
                                              />
                                              {/* <span
                                           
                                            >
                                              {salesInfoDetails[fieldValue]}
                                            </span> */}

                                              {/* <span
                                              className="position-absolute"
                                              style={{ right: "0.8rem" }}
                                              onClick={() =>
                                                clearSelectedItem()
                                              }
                                            >
                                              <img
                                                src={
                                                  listVisibleType === fieldValue
                                                    ? up
                                                    : down
                                                }
                                                alt="img"
                                                height={20}
                                                width={20}
                                                className="cursorPointer"
                                              />
                                            </span> */}

                                              {/* <span
                                              className="position-absolute end-0"
                                              style={{ marginRight: "-1.6rem" }}
                                            >
                                              <span
                                                className="cursorPointer pb-1 ps-1"
                                                onClick={() =>
                                                  setSelectModalType(fieldValue)
                                                }
                                              >
                                                <img
                                                  src={userSearch}
                                                  alt="img"
                                                  width={20}
                                                  height={20}
                                                />
                                              </span>
                                            </span> */}
                                            </div>
                                            {/* {listVisibleType === fieldValue &&
                                            accountsDropdown("2rem")} */}
                                          </div>
                                        )}
                                      </div>
                                    </div>
                                  );
                                })}
                            </div>
                            <div className="w-50 ">
                              {fieldIndividualDropdowns
                                .slice(9, 17)
                                .map((field) => {
                                  const fieldValue =
                                    field.value ||
                                    field.name
                                      .replace(/\s+/g, "")
                                      .replace(/^(.)/, (match) =>
                                        match.toLowerCase()
                                      );
                                  return (
                                    <div
                                      className="d-flex justify-content-between align-items-center my-2 w-100 gap-3"
                                      key={fieldValue}
                                    >
                                      <div className="opacity-75 p-2 w-100">
                                        {field.name}
                                      </div>
                                      <div className="w-100">
                                        {field.type === "input" ? (
                                          <EditableInput
                                            fieldType={
                                              [
                                                "dueDate",
                                                "dueDateInfo",
                                              ].includes(fieldValue)
                                                ? "date"
                                                : "text"
                                            }
                                            className="ps-3"
                                            inputType={fieldValue}
                                            handleIndividualInputChange={
                                              handleIndividualInputChange
                                            }
                                            editingFields={editingFields}
                                            handleEditClick={handleEditClick}
                                            handleCancelClick={
                                              handleCancelClick
                                            }
                                            handleUpdateClick={
                                              handleUpdateClick
                                            }
                                            updatedFields={salesInfoDetails}
                                            accountOverviewDetails={
                                              salesInfoDetails
                                            }
                                            setShowType={setShowType}
                                            showType={showType}
                                            fieldsType="second"
                                          />
                                        ) : field.type === "dropdown" ? (
                                          <div
                                            className="position-relative d-flex w-100"
                                            style={{ alignItems: "center" }}
                                            onClick={() => {
                                              handleEditClick(fieldValue);
                                            }}
                                          >
                                            {editingFields[fieldValue] &&
                                            showType === fieldValue ? (
                                              <Select
                                                value={saleInfo[fieldValue]}
                                                // value={accountInfoDetails[fieldValue]}
                                                menuPlacement="auto"
                                                onChange={(selectedOption) =>
                                                  handleChangeSelectDropDown(
                                                    selectedOption,
                                                    fieldValue
                                                  )
                                                }
                                                options={
                                                  fieldValue === "statusInfo"
                                                    ? stausOptions
                                                    : carrierOptions
                                                }
                                                styles={fieldDropdownStyles}
                                                isSearchable={false}
                                              />
                                            ) : (
                                              <input
                                                type="text"
                                                className={`w-100 cursorPointer indvEditInput
                        ${
                          showType === fieldValue
                            ? "selectedBorder "
                            : "unselectBorder bg-transparent"
                        }`}
                                                value={
                                                  saleInfo[fieldValue]?.value
                                                }
                                                name={fieldValue}
                                                onClick={(e) => {
                                                  if (showType === "") {
                                                    // purchaseInfo[fieldValue] =
                                                    // purchaseInfo[fieldValue];
                                                    setShowType(e.target.name);
                                                    setSalesInfoDetails(
                                                      salesInfoDetails
                                                    );
                                                    setSaleInfo(saleInfo);
                                                  }
                                                  handleEditClick(fieldValue);
                                                }}
                                                // readOnly={showType !== "emailId"}
                                                readOnly={true}
                                              />
                                            )}
                                            {editingFields[fieldValue] &&
                                              showType === fieldValue && (
                                                <div className="d-flex gap-2">
                                                  <span
                                                    onClick={() => {
                                                      setShowType("");
                                                      handleUpdateClick(
                                                        fieldValue
                                                      );
                                                    }}
                                                    className="ps-2 cursorPointer"
                                                  >
                                                    <img
                                                      src={updateIcon}
                                                      alt="img"
                                                      height={20}
                                                      width={20}
                                                    />
                                                  </span>
                                                  <span
                                                    onClick={() => {
                                                      setShowType("");
                                                      // handleUpdateClick(inputType);
                                                      handleCancelClick(
                                                        fieldValue
                                                      );
                                                    }}
                                                    className="cursorPointer"
                                                  >
                                                    <img
                                                      src={cancelIcon}
                                                      alt="img"
                                                      height={20}
                                                      width={20}
                                                    />
                                                  </span>
                                                </div>
                                              )}
                                          </div>
                                        ) : field.type === "readOnly" ? (
                                          <div>
                                            <span className="d-flex flex-column">
                                              <span>
                                                {
                                                  salesOverviewDetails[
                                                    field.name === "Quote Name"
                                                      ? "quoteName"
                                                      : field.name ===
                                                        "Account Name"
                                                      ? "accountName"
                                                      : field.name ===
                                                        "Deal Name"
                                                      ? "dealName"
                                                      : field.name ===
                                                        "Contact Name"
                                                      ? "contactName"
                                                      : field.name ===
                                                        "Created By"
                                                      ? "createdBy"
                                                      : field.name ===
                                                        "SO Number"
                                                      ? "soNumber"
                                                      : "modifiedBy"
                                                  ]
                                                }
                                              </span>
                                              <span className="small opacity-75">
                                                {
                                                  salesOverviewDetails[
                                                    field.name === "Quote Name"
                                                      ? ""
                                                      : field.name ===
                                                        "Account Name"
                                                      ? ""
                                                      : field.name ===
                                                        "Deal Name"
                                                      ? ""
                                                      : field.name ===
                                                        "Contact Name"
                                                      ? ""
                                                      : field.name ===
                                                        "Created By"
                                                      ? "createdDate"
                                                      : field.name ===
                                                        "SO Number"
                                                      ? ""
                                                      : "modifiedDate"
                                                  ]
                                                }
                                              </span>
                                            </span>
                                          </div>
                                        ) : (
                                          <div className="position-relative">
                                            <div
                                              className="d-flex input-with-clear position-relative"
                                              // onClick={(e) => {
                                              //   e.stopPropagation();
                                              //   setListVisibleType(
                                              //     listVisibleType === fieldValue
                                              //       ? ""
                                              //       : fieldValue
                                              //   );
                                              // }}
                                            >
                                              {/* <span
                                              className="w-100 ps-3 cursorPointer  rounded-2   indvEditInput border-0 indivOwnerInput"
                                          
                                            >
                                              {salesInfoDetails[fieldValue]}
                                            </span> */}
                                              <input
                                                type="text"
                                                className={`cursorPointer indvEditInput rounded-1 w-100 ${
                                                  showType === "salesOwnerId"
                                                    ? "selectedBorder "
                                                    : "unselectBorder bg-transparent"
                                                }`}
                                                value={
                                                  salesInfoDetails[fieldValue]
                                                }
                                                readOnly
                                              />
                                            </div>
                                          </div>
                                        )}
                                      </div>
                                    </div>
                                  );
                                })}
                            </div>
                          </div>
                        </div>
                        <h5 className="w-100 text-primary px-1 pt-1 mt-4">
                          Address Information
                        </h5>

                        <div className="d-flex justify-content-between mb-2">
                          <div className="leadContact_container d-flex gap-2 w-100 ps-3 pe-5">
                            <div className="w-50">
                              {fieldIndividualDropdowns
                                .slice(17, 22)
                                .map((field) => {
                                  const fieldValue =
                                    field.value ||
                                    field.name
                                      .replace(/\s+/g, "")
                                      .replace(/^(.)/, (match) =>
                                        match.toLowerCase()
                                      );
                                  return (
                                    <div
                                      className="d-flex justify-content-between align-items-center my-2 w-100 gap-3"
                                      key={fieldValue}
                                    >
                                      <div className="opacity-75 p-2 w-100">
                                        {field.name}
                                      </div>
                                      <div className="w-100">
                                        <EditableInput
                                          fieldType={"text"}
                                          className="ps-3"
                                          inputType={fieldValue}
                                          handleIndividualInputChange={
                                            handleIndividualInputChange
                                          }
                                          editingFields={editingFields}
                                          handleEditClick={handleEditClick}
                                          handleCancelClick={handleCancelClick}
                                          handleUpdateClick={handleUpdateClick}
                                          updatedFields={salesInfoDetails}
                                          accountOverviewDetails={
                                            salesInfoDetails
                                          }
                                          setShowType={setShowType}
                                          showType={showType}
                                          fieldsType="second"
                                        />
                                      </div>
                                    </div>
                                  );
                                })}
                            </div>
                            <div className="w-50">
                              {fieldIndividualDropdowns
                                .slice(22, 27)
                                .map((field) => {
                                  const fieldValue =
                                    field.value ||
                                    field.name
                                      .replace(/\s+/g, "")
                                      .replace(/^(.)/, (match) =>
                                        match.toLowerCase()
                                      );
                                  return (
                                    <div
                                      className="d-flex justify-content-between align-items-center my-2 w-100 gap-3"
                                      key={fieldValue}
                                    >
                                      <div className="opacity-75 p-2 w-100">
                                        {field.name}
                                      </div>
                                      <div className="w-100">
                                        <EditableInput
                                          fieldType={"text"}
                                          className="ps-3"
                                          inputType={fieldValue}
                                          handleIndividualInputChange={
                                            handleIndividualInputChange
                                          }
                                          editingFields={editingFields}
                                          handleEditClick={handleEditClick}
                                          handleCancelClick={handleCancelClick}
                                          handleUpdateClick={handleUpdateClick}
                                          updatedFields={salesInfoDetails}
                                          accountOverviewDetails={
                                            salesInfoDetails
                                          }
                                          showType={showType}
                                          setShowType={setShowType}
                                          fieldsType="second"
                                        />
                                      </div>
                                    </div>
                                  );
                                })}
                            </div>
                          </div>
                        </div>
                        <h5 className="w-100 text-primary px-1 pt-1 mt-4">
                          Ordered Items
                        </h5>
                        <div
                          className="horizontal-scroll-container mb-2"
                          style={{
                            overflowX: "auto",
                            width: "100%",
                            maxWidth: "100%",
                            overflow: "hidden",
                            border: "none",
                            padding: "1rem",
                          }}
                        >
                          <table
                            className="table table-bordered"
                            style={{
                              tableLayout: "fixed",
                              minWidth: "700px",
                              margin: "0px",
                              borderColor: "rgba(185, 185, 185, 0.5)",
                            }}
                          >
                            <colgroup>
                              <col style={{ width: "3%" }} />
                              <col style={{ width: "8%" }} />
                              <col style={{ width: "6%" }} />
                              <col style={{ width: "7%" }} />
                              <col style={{ width: "7%" }} />
                              <col style={{ width: "7%" }} />
                              <col style={{ width: "5%" }} />
                              <col style={{ width: "5%" }} />
                            </colgroup>
                            <thead>
                              <tr className="mb-2">
                                <th
                                  style={{
                                    backgroundColor: "#EFEFEF",
                                    fontSize: "13px",
                                  }}
                                >
                                  S.NO
                                </th>
                                <th
                                  style={{
                                    backgroundColor: "#EFEFEF",
                                    fontSize: "13px",
                                  }}
                                >
                                  Product Name
                                </th>
                                <th
                                  style={{
                                    backgroundColor: "#EFEFEF",
                                    fontSize: "13px",
                                  }}
                                >
                                  Quantity
                                </th>
                                <th
                                  style={{
                                    backgroundColor: "#EFEFEF",
                                    fontSize: "13px",
                                  }}
                                >
                                  List Price (₹)
                                </th>
                                <th
                                  style={{
                                    backgroundColor: "#EFEFEF",
                                    fontSize: "13px",
                                  }}
                                >
                                  Amount (₹)
                                </th>
                                <th
                                  style={{
                                    backgroundColor: "#EFEFEF",
                                    fontSize: "13px",
                                  }}
                                >
                                  Discount (₹)
                                </th>
                                <th
                                  style={{
                                    backgroundColor: "#EFEFEF",
                                    fontSize: "13px",
                                  }}
                                >
                                  Tax (%)
                                </th>
                                <th
                                  style={{
                                    backgroundColor: "#EFEFEF",
                                    fontSize: "13px",
                                  }}
                                >
                                  Total (₹)
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {itemsInfo?.map((item, ind) => (
                                <tr key={ind}>
                                  <td
                                    style={{
                                      backgroundColor: "white",

                                      fontSize: "13px",
                                    }}
                                  >
                                    {ind + 1}.
                                  </td>
                                  <td
                                    style={{
                                      backgroundColor: "white",
                                      display: "flex",
                                      flexDirection: "column",
                                      fontSize: "13px",
                                    }}
                                  >
                                    <span>{item?.productName}</span>
                                    <span>{item?.description}</span>
                                  </td>
                                  <td
                                    style={{
                                      backgroundColor: "white",
                                      fontSize: "13px",
                                    }}
                                  >
                                    {item?.quantity}
                                  </td>
                                  <td
                                    style={{
                                      backgroundColor: "white",
                                      fontSize: "13px",
                                    }}
                                  >
                                    {item?.listPrice}
                                  </td>
                                  <td
                                    style={{
                                      backgroundColor: "white",
                                      fontSize: "13px",
                                    }}
                                  >
                                    {item?.amount}
                                  </td>
                                  <td
                                    style={{
                                      backgroundColor: "white",
                                      fontSize: "13px",
                                    }}
                                  >
                                    {item?.discount}
                                  </td>
                                  <td
                                    style={{
                                      backgroundColor: "white",
                                      fontSize: "13px",
                                    }}
                                  >
                                    {item?.tax}
                                  </td>
                                  <td
                                    style={{
                                      backgroundColor: "white",
                                      fontSize: "13px",
                                    }}
                                  >
                                    {item?.total}
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            width: "max-content",
                            border: "1.86px solid rgba(226, 229, 235, 1)",
                            boxShadow:
                              "0px 0px 18.12px 0px rgba(0, 0, 0, 0.03)",
                            background: "rgba(255, 255, 255, 1)",
                            borderRadius: "10px",
                            alignSelf: "end",
                            marginRight: "1rem",
                          }}
                          className="table-responsive"
                        >
                          <table
                            className="table"
                            style={{
                              borderCollapse: "collapse",
                              width: "300px",
                              margin: "0",
                              boxShadow:
                                "0px 0px 18.12px 0px rgba(0, 0, 0, 0.03)",
                            }}
                          >
                            {" "}
                            <thead style={{ width: "max-content" }}>
                              <tr className="mb-2">
                                <th
                                  scope="col"
                                  style={{
                                    backgroundColor: "#F8F8F8",
                                    width: "max-content",
                                    fontSize: "13px",
                                  }}
                                >
                                  Sub Total
                                </th>
                                <td
                                  style={{
                                    backgroundColor: "#F8F8F8",
                                    fontSize: "13px",
                                  }}
                                >
                                  ₹{itemTotDtls?.subTotal}
                                </td>
                              </tr>
                              <tr className="mb-2">
                                <th
                                  scope="col"
                                  style={{
                                    width: "max-content",
                                    fontSize: "13px",
                                  }}
                                >
                                  Discount
                                </th>
                                <td
                                  style={{
                                    fontSize: "13px",
                                  }}
                                >
                                  ₹{itemTotDtls?.discount}
                                </td>
                              </tr>
                              <tr className="mb-2">
                                <th
                                  scope="col"
                                  style={{
                                    backgroundColor: "#F8F8F8",
                                    width: "max-content",
                                    fontSize: "13px",
                                  }}
                                >
                                  Tax
                                </th>
                                <td
                                  style={{
                                    backgroundColor: "#F8F8F8",
                                    fontSize: "13px",
                                  }}
                                >
                                  {itemTotDtls?.tax}%
                                </td>
                              </tr>
                              <tr className="mb-2">
                                <th
                                  scope="col"
                                  style={{
                                    width: "max-content",
                                    fontSize: "13px",
                                  }}
                                >
                                  Adjustment
                                </th>
                                <td
                                  style={{
                                    fontSize: "13px",
                                  }}
                                >
                                  ₹{itemTotDtls?.adjustment}
                                </td>
                              </tr>
                              <tr className="mb-2">
                                <th
                                  scope="col"
                                  style={{
                                    backgroundColor: "#F8F8F8",
                                    width: "max-content",
                                    fontSize: "13px",
                                  }}
                                >
                                  Grand Total
                                </th>
                                <td
                                  style={{
                                    backgroundColor: "#F8F8F8",
                                    fontSize: "13px",
                                  }}
                                >
                                  ₹{itemTotDtls?.grandTotal}
                                </td>
                              </tr>
                            </thead>
                          </table>
                        </div>
                        <h5 className="w-100 text-primary px-1 pt-1">
                          Terms and Conditions
                        </h5>
                        <div className="leadInformation_content_container d-flex justify-content-between ">
                          <div className="leadContact_container d-flex gap-4">
                            <div className="opacity-75 d-flex flex-column gap-2 px-2 py-2">
                              <span>Terms and Conditions</span>
                            </div>
                            <div className="d-flex flex-column gap-2 px-2 py-2">
                              <span>
                                {/* {salesOverviewDetails.termsAndConditions} */}
                                {/* <EditableInput
                                  inputType="termsAndConditions"
                                  handleIndividualInputChange={
                                    handleIndividualInputChange
                                  }
                                  editingFields={editingFields}
                                  handleEditClick={handleEditClick}
                                  handleCancelClick={handleCancelClick}
                                  handleUpdateClick={handleUpdateClick}
                                  updatedFields={salesInfoDetails}
                                  accountOverviewDetails={salesInfoDetails}
                                  showType={showType}
                                  setShowType={setShowType}
                                /> */}

                                <EditableInput
                                  fieldType="text"
                                  inputType="termsAndConditions"
                                  handleIndividualInputChange={
                                    handleIndividualInputChange
                                  }
                                  editingFields={editingFields}
                                  handleEditClick={handleEditClick}
                                  handleCancelClick={handleCancelClick}
                                  handleUpdateClick={handleUpdateClick}
                                  updatedFields={salesInfoDetails}
                                  accountOverviewDetails={salesInfoDetails}
                                  showType={showType}
                                  setShowType={setShowType}
                                  isTextarea={true}
                                />
                              </span>
                            </div>
                          </div>
                        </div>
                        <h5 className="w-100 text-primary px-1 pt-4">
                          Description Information
                        </h5>
                        <div className="leadInformation_content_container d-flex justify-content-between ">
                          <div className="leadContact_container d-flex gap-4">
                            <div className="opacity-75 d-flex flex-column gap-2 px-2 py-2">
                              <span>Description</span>
                            </div>
                            <div
                              className="d-flex flex-column gap-2 px-2 py-2"
                              style={{ marginLeft: "4.7rem" }}
                            >
                              <span>
                                {/* {salesOverviewDetails.descriptionInformation} */}
                                <EditableInput
                                  fieldType="text"
                                  inputType="description"
                                  handleIndividualInputChange={
                                    handleIndividualInputChange
                                  }
                                  editingFields={editingFields}
                                  handleEditClick={handleEditClick}
                                  handleCancelClick={handleCancelClick}
                                  handleUpdateClick={handleUpdateClick}
                                  updatedFields={salesInfoDetails}
                                  accountOverviewDetails={salesInfoDetails}
                                  showType={showType}
                                  setShowType={setShowType}
                                  isTextarea={true}
                                />
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                      {notePermission === "TRUE" && (
                        <div
                          id="notesSection"
                          className="lead_Information_mainContainer commonContainer_bg     rounded-2  d-flex flex-column my-2 py-2"
                        >
                          <Note
                            setAddTitle={setAddTitle}
                            addTitle={addTitle}
                            note={note}
                            noteTitle={noteTitle}
                            setNote={setNote}
                            showButtons={showButtons}
                            setShowButtons={setShowButtons}
                            textFile={selectedNoteFiles}
                            showTitleNote={showTitleNote}
                            setShowTitleNote={setShowTitleNote}
                            handleTextAreaClick={handleTextAreaClick}
                            handleTextFileChange={handleTextFileChange}
                            handleSaveButton={insertNote}
                            handleAddTitleClick={handleAddTitleClick}
                            setNoteTitle={setNoteTitle}
                            noteInfo={noteInfo}
                            selectRecent={selectRecent}
                            handleSelectRecent={handleSelectRecent}
                            handleChangeNoteTitle={handleChangeNoteTitle}
                            handleChangeNoteDescription={
                              handleChangeNoteDescription
                            }
                            setUpdateNotes={setUpdateNotes}
                            updateNotes={updateNotes}
                            setEditNote={setEditNote}
                            editNote={editNote}
                            handleUpdateClick={updateNote}
                            handleDeleteClick={deleteNoteDetails}
                            leadId={salesOrdId}
                            selectedNoteFiles={selectedNoteFiles}
                            setSelectedNoteFiles={setSelectedNoteFiles}
                            onChildDataChange={handleChildDataChange}
                            moduleType={"Sales Order"}
                            noteDeleteConfirmation={noteDeleteConfirmation}
                            setNoteDeleteConfirmation={
                              setNoteDeleteConfirmation
                            }
                          />

                          {showTitleNote && (
                            <textarea
                              rows={2}
                              className="notesClass px-2"
                              placeholder="Add note Title"
                              value={noteTitle}
                              onChange={(e) => setNoteTitle(e.target.value)}
                              style={{ width: "95%", marginTop: "10px" }}
                            />
                          )}
                        </div>
                      )}

                      <div
                        id="invoices"
                        // className="lead_Information_mainContainer commonContainer_bg rounded-2  d-flex flex-column py-2 mt-4"
                      >
                        {/* <InvoiceList invoiceList={invoices} /> */}
                        <InvoicesCommonTable
                          title="Invoices"
                          buttonLabel="New"
                          buttonAction={() => {
                            setCurrentView("invoices");
                            setInnerPage("create");
                            setPastViewInfo((prev) => ({
                              ...prev,
                              view: currentView,
                              innerPage: innerPage,
                              undo: null,
                            }));
                          }}
                          showHeadings={showHeadingsInvoices}
                          setShowHeadings={setShowHeadingsInvoices}
                          selectedHeadings={invoiceColumns}
                          // selectedHeadings={selectedHeadingsInvoices}
                          onRowClick={(index) => {
                            alert(`Row ${index} clicked`);
                          }}
                          manageColumnsAction={() => {
                            setShowModalType("Manage Invoices Columns");
                            setSearchColumn("");
                            setSavingHeadings(invoiceColumns);
                          }}
                          resetColumnSizeAction={() =>
                            alert("Reset Column Size")
                          }

                          // data={invoiceList}
                          // setShowSelectedFilter={setShowSelectedFilter}
                          // selectedInvoiceId={selectedInvoiceId}
                          // setSelectedInvoiceId={setSelectedInvoiceId}
                          // setSearchColumn={setSearchColumn}
                          // setSavingHeadings={setSavingHeadings}
                        />
                      </div>

                      <div
                        id="openActivity"
                        className="lead_Information_mainContainer commonContainer_bg rounded-2  d-flex flex-column py-2 mt-4"
                      >
                        <OpenActivity
                          openActivities={openActivities}
                          // getOverview={() => accountOverviewDetail(accountId)}
                          openActivitiesCalls={openActivitiesCalls}
                          openActivitiesMeetings={openActivitiesMeetings}
                          moduleName="Sales"
                        />
                      </div>

                      <div
                        id="closeActivity"
                        className="lead_Information_mainContainer commonContainer_bg rounded-2  d-flex flex-column py-2 mt-4"
                      >
                        <CloseActivity
                          closeActivities={closeActivities}
                          // getOverview={() => accountOverviewDetail(accountId)}
                          closeActivitiesCalls={closedActivitiesCall}
                          closeActivitiesMeetings={closedActivitiesMeetings}
                        />
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <div
                      className={`timeline_main_container rounded-2 bg-white d-flex flex-column gap-4 ${
                        isShrinked ? "w-100" : "w-75"
                      }`}
                    >
                      {/* <div className="upcomingAction_container w-100 flex-column h-25 py-2  d-flex  commonContainer_bg rounded-2">
                        <div className="header d-flex gap-2 p-2">
                          <span>
                            <img
                              src={hierachy}
                              alt="img"
                              height={20}
                              width={20}
                            />
                          </span>
                          <span>Upcoming Actions</span>
                        </div>
                        <div className="horizontal_line w-100  mx-1 border border-white"></div>

                        <div
                          className="d-flex justify-content-center align-items-center "
                          style={{ height: "20vh" }}
                        >
                          <h4 className="opacity-75 ">No upcoming actions</h4>
                        </div>
                      </div> */}

                      <div className="w-100 historyMain_Container flex-column   d-flex  commonContainer_bg rounded-2 ">
                        <div className="header d-flex gap-2 px-2 py-2 align-items-center">
                          <span>
                            <img
                              src={history}
                              alt="img"
                              height={20}
                              width={20}
                            />
                          </span>
                          <span>History</span>
                        </div>
                        <div className="horizontal_line w-100  mx-1 mt-1"></div>
                        <div className="historyContent_mainContainer ">
                          {timeLineDataList &&
                          Object.keys(timeLineDataList).length > 0 ? (
                            Object.keys(timeLineDataList).map((date) => (
                              <div
                                className="history_container pt-3 d-flex flex-column"
                                key={date}
                              >
                                <span className="px-2 timelineBg_color border border-primary rounded-5 py-2 mx-5">
                                  {date}
                                </span>
                                {timeLineDataList[date].map((item, index) => (
                                  <div className="d-flex gap-2" key={index}>
                                    <span className="opacity-75 pt-5 ps-2">
                                      {item.createdTime}
                                    </span>
                                    <div className="d-flex flex-column align-items-center">
                                      <p className="vertical_line m-0 p-0"></p>
                                      <span>
                                        <img
                                          src={groupIcon2}
                                          alt="img"
                                          height={25}
                                          width={25}
                                        />
                                      </span>
                                    </div>
                                    <span className="pt-5">
                                      {`${item.tmlDescription} ${item.tmlCreatedBy} ${date}`}
                                    </span>
                                  </div>
                                ))}
                              </div>
                            ))
                          ) : (
                            <div
                              className="text-center d-flex justify-content-center align-items-center opacity-75"
                              style={{ height: "20vh" }}
                            >
                              <h4 className="h-75">No Data available</h4>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </>
      );
    }
  };
  return (
    <div
      onClick={() => {
        setListVisibleType(false);
        setShowHeadings(false);
        setShowSelectedFilter("");
        setSelectedField("");
        setShowOptionsLead(false);
      }}
    >
      {handleSaleOrdersView()}
      <LeadOwnerModal
        isLeadOwnerModal={isLeadOwnerModal}
        setIsLeadOwnerModal={setIsLeadOwnerModal}
        onSelectedLeadChange={handleSelectedLeadChange}
        defaultUser={selectedOwnerItem}
      />
      <Modal
        show={isQuoteListModal}
        className="d-flex justify-content-center align-items-center"
        aria-labelledby="example-custom-modal-styling-title"
        size="lg"
        id="commonLeadModal"
      >
        <Modal.Body className="ModalBody  overflow-auto">
          <div
            className="modalUS row justify-content-center"
            style={{ margin: "0", gap: "10px", paddingBottom: "10px" }}
          >
            <div className="header_Container d-flex justify-content-between">
              <h4 className="commonTextColor">Choose Quote</h4>
              <div className="inputContainer d-flex justify-content-start">
                <input
                  type="text"
                  placeholder="Search"
                  className="topBarModalSearch px-5"
                  onChange={handleSearchQuote}
                  value={searchQuote}
                />
                <img src={SearchIcon} alt="search" className="searchIcon" />
              </div>
            </div>

            <div className="Owner_table_container border border-dark rounded-2 h-25">
              <div className="table-wrapper">
                <table className="userdata-table">
                  <thead>
                    <tr>
                      <th>Quotes Owner</th>
                      <th>Quote Stage</th>
                      <th>Valid Until</th>
                      <th>Carrier</th>
                    </tr>
                  </thead>
                  <tbody className="table-body tableUserData">
                    {quotesModalSearch?.map((item, index) => (
                      <tr
                        key={index}
                        onClick={() => {
                          setSelectedQuote(item);
                        }}
                      >
                        <td className="d-flex gap-2">
                          <img
                            src={logoConvert}
                            alt="img"
                            height={20}
                            width={20}
                          />

                          {item.quotesOwner}
                        </td>
                        <td>{item.quoteStage}</td>
                        <td>{item.validUntil}</td>
                        <td>{item.carrier}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
            <div className="d-flex w-100 align-items-center justify-content-end gap-2">
              {quotesModalSearch.length > 0 && (
                <>
                  <Select
                    options={pageOption}
                    value={pageOption.find(
                      (option) => option.value === perPageQuote.toString()
                    )}
                    onChange={handlePageDropDownChangeQuote}
                    isSearchable={false}
                    styles={customCommonStyles("225px")}
                  />
                  <Pagination
                    currentPage={currentPageQuote}
                    totalPages={totalPagesQuote}
                    onPageChange={handlepageChangeQuote}
                  />
                </>
              )}
            </div>

            <div className="d-flex pb-2 pt-3 justify-content-between">
              <div className="selectedUser d-flex gap-1 pt-2">
                <span>Selected User</span>
                <img src={logoConvert} alt="img" height={25} width={25} />
                <span>{selectedQuote.quotesOwner}</span>
              </div>
              <div className="d-flex gap-2">
                <button
                  className="createContactButton modalButton"
                  onClick={() => setIsQuoteListModal(false)}
                >
                  Cancel
                </button>
                <button
                  className="createContactButtonSave modalButton"
                  onClick={() => {
                    setIsQuoteListModal(false);
                    getAllAssignModules(
                      "QUOTES",
                      ["DEALS", "CONTACTS", "ACCOUNTS"],
                      selectedQuote.quoteId
                    );
                  }}
                >
                  Done
                </button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        show={isDealListModal}
        className="d-flex justify-content-center align-items-center"
        aria-labelledby="example-custom-modal-styling-title"
        size="lg"
        id="commonLeadModal"
      >
        <Modal.Body className="ModalBody  overflow-auto">
          <div
            className="modalUS row justify-content-center"
            style={{ margin: "0", gap: "10px", paddingBottom: "10px" }}
          >
            <div className="header_Container d-flex justify-content-between">
              <h4 className="commonTextColor">Choose Deal</h4>
              <div className="d-flex justify-content-start">
                <div className="inputContainer d-flex justify-content-start">
                  <input
                    type="text"
                    className="topBarModalSearch px-5"
                    placeholder="Search "
                    value={searchDeal}
                    onChange={handleSearchDeal}
                  />
                  <img src={SearchIcon} alt="search" className="searchIcon" />
                </div>
              </div>
            </div>

            <div className="Owner_table_container border border-dark rounded-2 h-25">
              <div className="table-wrapper">
                <table className="userdata-table">
                  <thead>
                    <tr>
                      <th>Deal Name</th>
                      <th>Closing Date</th>
                      <th>Stage</th>
                      <th>Lead Source</th>
                      <th>Contact Name</th>
                    </tr>
                  </thead>
                  <tbody className="table-body tableUserData">
                    {filteredDeals?.map((item, index) => (
                      <tr
                        key={index}
                        onClick={() => {
                          setSelectedDeal(item);
                        }}
                      >
                        <td>{item.dealName}</td>
                        <td>{item.closingDate}</td>
                        <td>{item.stage}</td>
                        <td>{item.leadSource}</td>
                        <td>{item.contactName}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
            <div className="d-flex w-100 align-items-center justify-content-end gap-2">
              {filteredDeals.length > 0 && (
                <>
                  <Select
                    options={pageOption}
                    value={pageOption.find(
                      (option) => option.value === perPageDeal.toString()
                    )}
                    onChange={handlePageDropDownChangeDeal}
                    isSearchable={false}
                    styles={customCommonStyles("225px")}
                  />
                  <Pagination
                    currentPage={currentPageDeal}
                    totalPages={totalPagesDeal}
                    onPageChange={handlepageChangeDeal}
                  />
                </>
              )}
            </div>
            <div className="d-flex pb-2 pt-3 justify-content-between">
              <div className="selectedUser d-flex gap-1">
                <span>Selected User</span>
                <img src={logoConvert} alt="img" height={20} width={20} />
                <span>{selectedDeal?.dealName}</span>
              </div>
              <div className="d-flex gap-2">
                <button
                  className="btn btn-outline-secondary border border-primary btn-sm px-3 py-2 text-dark"
                  onClick={() => setIsDealListModal(false)}
                >
                  Cancel
                </button>
                <button
                  className="btn btnColor btn-sm px-3 text-white"
                  onClick={() => {
                    setIsDealListModal(false);
                    getAllAssignModules(
                      "DEALS",
                      ["CONTACTS", "ACCOUNTS", "QUOTES"],
                      selectedDeal.dealId
                    );
                  }}
                >
                  Done
                </button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        show={isContactListModal}
        className="d-flex justify-content-center align-items-center"
        aria-labelledby="example-custom-modal-styling-title"
        size="lg"
        id="commonLeadModal"
      >
        <Modal.Body className="ModalBody  overflow-auto">
          <div
            className="modalUS row justify-content-center"
            style={{ margin: "0", gap: "10px", paddingBottom: "10px" }}
          >
            <div className="header_Container d-flex justify-content-between">
              <h4 className="commonTextColor pt-2">Choose Contact</h4>
              <div className="inputContainer d-flex justify-content-start">
                <input
                  type="text"
                  placeholder="Search"
                  className="topBarModalSearch px-5"
                  onChange={handleSearchContact}
                  value={searchContact}
                />
                <img src={SearchIcon} alt="search" className="searchIcon" />
              </div>
            </div>

            <div className="Owner_table_container border border-dark rounded-2 h-25">
              <div className="table-wrapper">
                <table className="userdata-table">
                  <thead>
                    <tr>
                      <th style={{ width: "20%" }}>Contact Name</th>
                      <th style={{ width: "20%" }}>Account Name</th>
                      <th style={{ width: "20%" }}>Email</th>
                      <th style={{ width: "20%" }}>Phone</th>
                      <th style={{ width: "20%" }}>Contact Owner</th>
                    </tr>
                  </thead>
                  <tbody className="table-body tableUserData">
                    {filteredContacts?.map((item, index) => (
                      <tr
                        key={index}
                        onClick={() => {
                          setSelectedContact(item);
                        }}
                      >
                        <td style={{ width: "20%" }}>{item.contactName}</td>
                        <td style={{ width: "20%" }}>{item.accountName}</td>
                        <td style={{ width: "20%" }}>{item.emailId}</td>
                        <td style={{ width: "20%" }}>{item.phone}</td>
                        <td style={{ width: "20%" }}>{item.contactOwner}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>

            <div className="d-flex w-100 align-items-center justify-content-end gap-2">
              {filteredContacts.length > 0 && (
                <>
                  <Select
                    options={pageOption}
                    styles={customCommonStyles("225px")}
                    // value={selectPageOption}
                    value={pageOption.find(
                      (option) => option.value === perPageContact.toString()
                    )}
                    onChange={handlePageDropDownChangeContact}
                  />
                  {/* <p className="mb-0">· ‹1/10›</p> */}

                  <Pagination
                    currentPage={currentPageContact}
                    totalPages={totalPagesContact}
                    onPageChange={(page) => {
                      setCurrentPageContact(page);
                    }}
                  />
                </>
              )}
            </div>
            <div className="d-flex pb-2 pt-3 justify-content-between">
              <div className="selectedUser d-flex gap-1">
                <span>Selected User</span>
                <img src={logoConvert} alt="img" height={20} width={20} />
                <span>{selectedContact?.contactName}</span>
              </div>
              <div className="d-flex gap-2">
                <button
                  className="btn btn-outline-secondary border border-primary btn-sm px-3 py-2 text-dark"
                  onClick={() => setIsContactListModal(false)}
                >
                  Cancel
                </button>
                <button
                  className="btn btnColor btn-sm px-3 text-white"
                  onClick={() => {
                    setIsContactListModal(false);
                    getAllAssignModules(
                      "CONTACTS",
                      ["DEALS", "ACCOUNTS", "QUOTES"],
                      selectedContact.contactId
                    );
                  }}
                >
                  Done
                </button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        show={isAccountListModal}
        className="d-flex justify-content-center align-items-center"
        aria-labelledby="example-custom-modal-styling-title"
        size="lg"
        id="commonLeadModal"
      >
        <Modal.Body className="ModalBody  overflow-auto">
          <div
            className="modalUS row justify-content-center"
            style={{ margin: "0", gap: "10px", paddingBottom: "10px" }}
          >
            <div className="header_Container d-flex justify-content-between">
              <h4 className="commonTextColor pt-2">Change Owner</h4>
              <div className="inputContainer d-flex justify-content-start">
                <input
                  type="text"
                  placeholder="Search"
                  className="topBarModalSearch px-5"
                  onChange={handleSearchAccount}
                  value={searchAccount}
                />
                <img src={SearchIcon} alt="search" className="searchIcon" />
              </div>
            </div>

            <div className="Owner_table_container border border-dark rounded-2 h-25">
              <div className="table-wrapper">
                <table className="userdata-table">
                  <thead>
                    <tr>
                      <th>Account Name</th>
                      <th>Phone</th>
                      <th>Website</th>
                      <th>Account Owner</th>
                    </tr>
                  </thead>
                  <tbody className="table-body tableUserData">
                    {filteredAccount?.map((item, index) => (
                      <tr
                        key={index}
                        onClick={() => {
                          setSelectedAccount(item);
                        }}
                      >
                        <td>{item.accountName}</td>
                        <td>{item.phone}</td>
                        <td>{item.website}</td>
                        <td>{item.accountOwner}</td>
                      </tr>
                    ))}
                    {filteredAccount &&
                      filteredAccount.length > 1 &&
                      filteredAccount?.map((item, index) => (
                        <tr
                          key={index}
                          onClick={() => {
                            setSelectedAccount(item);
                          }}
                        >
                          <td>{item.accountName}</td>
                          <td>{item.phone}</td>
                          <td>{item.website}</td>
                          <td>{item.accountOwner}</td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            </div>
            <div className="d-flex w-100 align-items-center justify-content-end gap-2">
              {filteredAccount.length > 0 && (
                <>
                  <Select
                    options={pageOption}
                    // styles={customSelectLocationStyles}
                    value={pageOption.find(
                      (option) => option.value === perPageAccount.toString()
                    )}
                    onChange={handlePageDropDownChangeAccount}
                    isSearchable={false}
                    styles={customCommonStyles("225px")}
                  />
                  <Pagination
                    currentPage={currentPageAccount}
                    totalPages={totalPagesAccount}
                    onPageChange={handlepageChangeAccount}
                  />
                </>
              )}
            </div>
            <div className="d-flex pb-2 pt-3 justify-content-between">
              <div className="selectedUser d-flex gap-1">
                <span>Selected User</span>
                <img src={logoConvert} alt="img" height={20} width={20} />
                <span>{selectedAccount?.accountName}</span>
              </div>
              <div className="d-flex gap-2">
                <button
                  className="btn btn-outline-secondary border border-primary btn-sm px-3 py-2 text-dark"
                  onClick={() => setIsAccountListModal(false)}
                >
                  Cancel
                </button>
                <button
                  className="btn btnColor btn-sm px-3 text-white"
                  onClick={() => {
                    setIsAccountListModal(false);
                    getAllAssignModules(
                      "ACCOUNTS",
                      ["DEALS", "CONTACTS", "QUOTES"],
                      selectedAccount?.accountId
                    );
                  }}
                >
                  Done
                </button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        show={modalType === "Manage Columns"}
        id="ConvertModal"
        aria-labelledby="example-custom-modal-styling-title"
        className="withdrw_modal"
      >
        <Modal.Body>
          <div className="modalClass  position-relative">
            <div className="d-flex justify-content-between ">
              <h4 className="">Manage Columns</h4>
            </div>
            <div className="w-100 position-relative">
              <div className="mb-1 pt-2 position-relative w-100">
                <input
                  type="text"
                  className="assetSearchbar searchbar rounded-2 p-2 pe-5 common_input_bg w-100 "
                  placeholder="Search"
                  value={searchColumn}
                  onChange={(e) => {
                    if (e.target.value.charAt(0) !== " ") {
                      const value = e.target.value.replace(
                        /[^a-zA-Z ]+|(\s)\s+/g,
                        "$1"
                      );
                      setSearchColumn(value);
                    }
                  }}
                />
                <span
                  className="position-absolute  mt-2 me-2 end-0 opacity-75 cursorPointer"
                  onClick={() => {
                    setSearchColumn("");
                    setSavingHeadings(salesColumns);
                  }}
                >
                  {searchColumn === "" ? (
                    <img src={searchIcon} alt="" width={20} />
                  ) : (
                    "X"
                  )}
                </span>
              </div>
              <div className=" opacity-75 columns_container">
                {savingHeadings
                  .filter((lead) =>
                    lead.name
                      .toLowerCase()
                      .includes(searchColumn.trim().toLowerCase())
                  )
                  .sort((a, b) => a.name.localeCompare(b.name))
                  .map((column) => {
                    return (
                      <div
                        key={column.name}
                        className="d-flex gap-2 py-2 rounded-2 align-items-center"
                      >
                        <div className="d-flex gap-2 ps-2">
                          <input
                            type="checkbox"
                            id={column.name}
                            name={column.name}
                            checked={column.isChecked}
                            onChange={(e) => {
                              handleColumnCheckboxChange(
                                column.name,
                                e.target.checked
                              );
                            }}
                            className="cursorPointer"
                          />
                          <label
                            className="small cursorPointer"
                            htmlFor={column.name}
                          >
                            {column.name}
                          </label>
                        </div>
                      </div>
                    );
                  })}
              </div>
              <aside className="float-end mt-2">
                <div className="d-flex align-items-center gap-4">
                  <button
                    className="cancel_btn bg-light"
                    onClick={() => {
                      setShowModalType("");
                    }}
                  >
                    Cancel
                  </button>
                  <button
                    className="save_btn"
                    onClick={() => {
                      setSelectedHeadings(savingHeadings);
                      setTimeout(() => {
                        setShowModalType("");
                      }, 200);
                    }}
                  >
                    Save
                  </button>
                </div>
              </aside>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        show={[
          "delete",
          "Mass Delete",
          "Mass Update",
          "Mass Transfer",
        ].includes(modalType)}
        aria-labelledby="contained-modal-title-vcenter"
        onClick={(e) => e.stopPropagation()}
        // size={showModalType !== "delete" ? "md" : "lg"}
      >
        <Modal.Header
          className=" border-0 mb-0 d-flex flex-column align-items-start"
          style={{ backgroundColor: modalType !== "delete" && "#F2F2F2" }}
        >
          {modalType !== "delete" && (
            <div
              className={`mass_heading fs-4 ${
                modalType !== "Mass Delete" && "ps-4"
              }`}
            >
              {modalType}
            </div>
          )}
          {modalType === "delete" && (
            <div className="fs-5" style={{ color: "#3F7DFF" }}>
              Are you sure you want to delete {salesOverviewDetails?.subject}?
            </div>
          )}
        </Modal.Header>
        <Modal.Body className="ModalBody">
          {modalType === "delete" ? (
            <div className="d-flex gap-2 pb-2 align-items-center">
              <span className="fw-bold">Note:</span>
              <span className="small pt-1">
                Any associated activities, visits, drafts will also be deleted.
              </span>
            </div>
          ) : modalType === "Mass Delete" ? (
            <>
              <div className="fs-5 pt-0 pb-2">
                Are you sure you want to delete selected item(s)?
              </div>
              <div className="small">
                <span className="fw-bold">Note: </span>
                <span>
                  Any associated Activities, Drafts will also be Deleted
                </span>
              </div>
            </>
          ) : modalType === "Mass Update" ? (
            <div className="d-flex gap-2 mb-3 mt-2 align-items-center w-100">
              <div className="w-100">
                <div className="d-flex flex-column ps-1 align-items-center mx-4">
                  <label className="text-start w-100 opacity-75">
                    Select a field
                  </label>
                  <div
                    className="position-relative w-100 rounded-1"
                    style={{ border: "1px solid rgba(185, 185, 185, 0.5)" }}
                  >
                    <div
                      onClick={(e) => {
                        e.stopPropagation();
                        setListVisible(!listVisible);
                        setSearchField("");
                      }}
                      className={`cursorPointer d-flex justify-content-between w-100 my-1 p-1 border-0`}
                    >
                      <span className="">{selectedObj.name}</span>
                      <img
                        src={listVisible ? up : down}
                        alt="img"
                        height={20}
                        width={20}
                        className="pt-1"
                      />
                    </div>
                    {listVisible && (
                      <div
                        className="position-absolute field_container w-100"
                        onClick={(e) => {
                          e.stopPropagation();
                        }}
                      >
                        <div className="">
                          <label
                            htmlFor="selectField"
                            className="position-relative w-100"
                          >
                            <input
                              id="selectField"
                              type="text"
                              className="rounded-2 p-1 w-100 pe-4"
                              placeholder="Search"
                              value={searchField}
                              onChange={(e) => {
                                if (e.target.value.charAt(0) !== " ") {
                                  const value = e.target.value.replace(
                                    /[^a-zA-Z ]+|(\s)\s+/g,
                                    "$1"
                                  );
                                  setSearchField(value);
                                }
                              }}
                            />
                            <img
                              className="position-absolute mt-1 me-2 end-0 pt-1 cursorPointer"
                              onClick={() => {
                                setSearchField("");
                              }}
                              src={searchIcon}
                              alt="search"
                              width={20}
                            />
                          </label>

                          <div className="w-100 rounded-2">
                            <div className="border border-1 field_container_dropdown rounded-1">
                              {showSearchFields
                                .filter((field) =>
                                  field.name
                                    .toLowerCase()
                                    .includes(searchField.trim().toLowerCase())
                                )
                                .sort((a, b) => a.name.localeCompare(b.name))
                                .map(
                                  (field) =>
                                    ![
                                      "Sales Order Owner",
                                      "Account Name",
                                      "Contact Name",
                                      "Created By",
                                      "Modified By",
                                      "Deal Name",
                                      "Email Opt Out",
                                    ].includes(field.name) && (
                                      <div
                                        key={field.name}
                                        className="d-flex gap-2 p-2 cursorPointer rounded-2 align-items-center"
                                        onClick={() => {
                                          setClickedDropdownObj({
                                            label: "",
                                            value: "",
                                          });
                                          setMassUpdateValue("");
                                          setSelectedObj(field);
                                          setListVisible(false);
                                        }}
                                      >
                                        <span className="ps-2">
                                          {field.name}
                                        </span>
                                      </div>
                                    )
                                )}
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="w-100 mx-auto d-flex justify-content-center mt-4">
                    <div className="w-100">
                      <label className="opacity-75" htmlFor="massValue">
                        Enter value
                      </label>
                      <div
                        className="position-relative w-100 border rounded-1"
                        style={{ border: "1px solid rgba(185, 185, 185, 0.5)" }}
                      >
                        {["Status", "Carrier"].includes(selectedObj.name) ? (
                          <Select
                            value={clickedDropdownObj}
                            menuPlacement="auto"
                            onChange={(selectedOption) => {
                              setClickedDropdownObj(selectedOption);
                              setMassUpdateValue(selectedOption.value);
                            }}
                            options={
                              selectedObj.name === "Carrier"
                                ? carrierOptions
                                : stausOptions
                            }
                            styles={fieldDropdownStyles}
                            isSearchable={false}
                          />
                        ) : (
                          <input
                            className="w-100 border-0 py-2 rounded-1"
                            // type="text"
                            value={massUpdateValue}
                            type={`${
                              ["Due Date"].includes(selectedObj.name)
                                ? "date"
                                : "text"
                            }`}
                            // onChange={(e) => {
                            //   const trimmedValue = e.target.value.trimStart();
                            //   setMassUpdateValue(trimmedValue);
                            // }}
                            onChange={(e) => {
                              const trimmedValue = e.target.value
                                ?.replace(/\s{2,}/g, " ")
                                .trimStart();
                              if (
                                [
                                  "Billing Code",
                                  "Excise Duty",
                                  "Sales Commission",
                                  "Shipping Code",
                                  "Purchase Order",
                                  "Requisition Number",
                                  "Customer No.",
                                ].includes(selectedObj.name)
                              ) {
                                setMassUpdateValue(
                                  trimmedValue.replace(/[^0-9.]+/g, "")
                                );
                              } else if (
                                [
                                  "Billing Street",
                                  "Subject",
                                  "Shipping Street",
                                  "Tracking Number",
                                  "Pending",
                                ].includes(selectedObj.name)
                              ) {
                                setMassUpdateValue(trimmedValue);
                              } else {
                                // setMassUpdateValue(
                                //   trimmedValue.replace(/[^a-zA-Z]+/g, "")
                                // );
                                setMassUpdateValue(
                                  !["Due Date"].includes(selectedObj.name)
                                    ? trimmedValue.replace(/[^a-zA-Z]+/g, "")
                                    : e.target.value
                                );
                              }
                            }}
                            style={{ outline: "none" }}
                            id="massValue"
                            min={minDate}
                            max={maxDate}
                          />
                        )}
                      </div>
                      {errorMessage && (
                        <span className="text-danger text-center pt-1">
                          {errorMessage}
                        </span>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="w-100 px-4 py-2 mb-1">
              <label className="text-start w-100 opacity-75">Transfer To</label>
              <div
                className="d-flex input-with-clear position-relative mx-auto"
                onClick={(e) => {
                  handleOwnerInputClick(e);
                }}
              >
                <input
                  type="text"
                  className="ps-3 w-100 cursorPointer py-2"
                  value={selectedOwnerItem?.name}
                  readOnly
                />
                <span
                  className="position-absolute h-100 mass_transfer_user pt-1"
                  style={{ right: "0.1rem" }}
                >
                  <span
                    className="cursorPointer px-1"
                    onClick={() => setIsLeadOwnerModal(!isLeadOwnerModal)}
                  >
                    <img
                      src={userSearch}
                      alt="img"
                      width={20}
                      height={20}
                      className="ms-2 mt-2 me-2"
                    />
                  </span>
                </span>
              </div>
            </div>
          )}

          <div className={`pt-4  ${modalType !== "delete" && "pe-3"}`}>
            <div className="d-flex justify-content-end w-100 gap-4 pe-2">
              <button
                className="cancelButton px-4"
                onClick={() => {
                  setShowModalType("");
                  // setSelectedCheckboxes([]);
                  setShowSearchFields(fieldDropdowns);
                }}
              >
                Cancel
              </button>
              <button
                className="createContactButtonSave"
                onClick={() => {
                  if (modalType === "delete") {
                    deleteSales();
                  } else {
                    //  else if (showModalType === "Mass Delete") {
                    //   deleteUpdateTransferMass();
                    // } else {
                    deleteUpdateTransferMass();
                  }
                }}
              >
                {modalType === "Mass Update"
                  ? "Update"
                  : modalType === "Mass Transfer"
                  ? "Done"
                  : "Delete"}
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <AccessModal
        setShowAccessModal={setShowAccessModal}
        showAccessModal={showAccessModal}
      />
      <ShareModal
        moduleType="SALES ORDERS"
        setShareModal={setShareModal}
        shareModal={shareModal}
        id={salesOrdId}
        shareUserList={shareUserList}
        shareGroupList={shareGroupList}
        shareRoleList={shareRoleList}
        shareMemberIds={shareMemberIds}
        shareInfoModal={shareInfoModal}
        sharePublicList={sharePublicList}
        setShareInfoModal={setShareInfoModal}
        modalHeading={"Sharing Sales Order"}
        module={"Sales Order"}
      />

      <ChangeOwnerModal
        modalType={modalType}
        setShowModalType={setShowModalType}
        selectedOwnerItem={selectedOwnerItem}
        handleChangeOwnerInputClick={handleChangeOwnerInputClick}
        setSelectedOwnerItem={setSelectedOwnerItem}
        isLeadOwnerModal={isLeadOwnerModal}
        setIsLeadOwnerModal={setIsLeadOwnerModal}
        isChangeOwnerList={isChangeOwnerList}
        userList={userList}
        handleChangeOwnerItemClick={handleChangeOwnerItemClick}
        emailNotification={emailNotification}
        setEmailNotification={setEmailNotification}
        handleEditClick={handleEditClick}
        handleUpdateClick={handleUpdateClick}
        setUpdatedFields={setSalesInfoDetails}
        existingContact={editSalesOverview}
        handleUpdateContact={handleSaveButton}
        onSelectedChangeOwner={handleSelectedChangeOwner}
        apiLoader={apiLoader} // for lottie loader
      />

      <NotificationContainer />
    </div>
  );
}

export default SalesOrder;

import React from "react";
import NoData from "../../../Assets/Nodata.svg";
import { convertCamelCaseToReadable } from "../../../common/CommonComponent";

const TableComponent = ({
  component,
  setModalType,
  setSelectedComponentId,
  deleteIcon,
}) => {
  const headers =
    component.componentData?.length > 0
      ? Object.keys(component?.componentData[0])
      : [];
  const headings =
    component.componentData?.length > 0
      ? convertCamelCaseToReadable(Object.keys(component.componentData[0]))
      : [];

  return (
    <div className="component_container mx-2 mt-3 rounded-1 position-relative">
      <div className="component_header d-flex justify-content-between p-2">
        <span className="first_letter_uppercase fs-5">
          {component.componentName}
        </span>
        {/* <span>
                  <img src={refreshIcon} alt="reload" />
                </span> */}
      </div>
      <div className="component_content px-1">
        <div className="d-flex">
          <div className="w-100">
            <div
              id="table-scroll"
              className="w-100"
              style={{ minHeight: "29vh" }}
            >
              <table className="table responsive">
                <thead>
                  <tr className="w-100">
                    {headings.map((heading) => {
                      return <th key={heading}>{heading}</th>;
                    })}
                  </tr>
                </thead>
                <tbody className="tableData">
                  {component.componentData?.length > 0 ? (
                    component.componentData.map((data, i) => {
                      return (
                        <tr className="" key={i}>
                          {headers.map((comp) => {
                            return (
                              <td
                                key={comp}
                                className="overflow_text"
                                style={{ maxWidth: "4.6vw" }}
                                title={data[comp]}
                              >
                                {typeof data[comp] === "boolean" ? (
                                  <span>
                                    {data[comp] === false ? "✕" : "✓"}
                                  </span>
                                ) : (
                                  <span>
                                    {["sumOfAmount"].includes(comp) ? "₹" : ""}{" "}
                                    {data[comp]}
                                  </span>
                                )}
                              </td>
                            );
                          })}
                        </tr>
                      );
                    })
                  ) : (
                    <div className="text-center d-flex flex-column justify-content-center align-items-center no_data_img">
                      <img src={NoData} alt="No data" className="" />
                      <h4 className="fs-5 my-0 py-0">No data</h4>
                    </div>
                  )}
                </tbody>
              </table>
            </div>

            {/* <div className="d-flex gap-1 justify-content-end me-2 p-2">
                <img src={ArrowLeft} alt="arrowLeft" />
                <div style={{ color: "#515151" }}>1/10</div>
                <img src={ArrowRight} alt="arrowRight" />
                </div> */}
          </div>
        </div>
      </div>
      <div className="component_footer p-2 position-absolute end-0 bottom-0 mb-1">
        <span className="">
          <span
            className="deleteIcon rounded-1 p-1 cursorPointer"
            onClick={() => {
              // setSelectedComponentId("");
              setModalType("delete");
              setSelectedComponentId(component.componentId);
            }}
          >
            {deleteIcon}
          </span>
          {/* <span className="editIcon rounded-1 p-1 cursorPointer">
                {editIcon}
              </span> */}
        </span>
      </div>
    </div>
  );
};

export default TableComponent;

import React, { useEffect, useState } from "react";
import "./Upgrade.css";
import GstinImage from "../../Assets/GstinImage.svg";
import CrmLogo from "../../Assets/CrmLogo.svg";
import Logout from "../../Assets/LogoutIcon.png";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import leadAvatar from "../../Assets/Lead_Avatar.png";
import { URI } from "../../constants";
import axios from "axios";
import {
  NotificationManager,
  NotificationContainer,
} from "react-notifications";
import getHeaders from "../../common/ApiHeader.js";
import { handleApiError } from "../../utils/ApiUtils";


function Upgrade() {
  const [selectUpgrade, setSelectUpgrade] = useState("Monthly");
  const [selectPlan, setSelectPlan] = useState("current");
  const [selectPlanName, setSelectPlanName] = useState("");
  const [selectPlanAmount, setSelectPlanAmount] = useState("");
  const [showLogout, setShowLogout] = useState(false);
  const reduxStore = useSelector((state) => state.reduxState);
  const [subscriptionPlanDetails, setSubscriptionPlanDetails] = useState([]);
  const [value, setValue] = useState(1);
  const [billingName, setBillingName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [country, setCountry] = useState("India");
  const [streetAddress, setStreetAddress] = useState("");
  const [state, setState] = useState("");
  const [city, setCity] = useState("");
  const [zip, setZip] = useState("");
  const [gstin, setGstin] = useState("");
  const [isGstinRegistered, setIsGstinRegistered] = useState(null);
  const [selectPlanDetail, setSelectPlanDetail] = useState(false);
  const [errorMessageValue, setErrorMesageValue] = useState("");
  const navigate = useNavigate();
  const { loginUserInfo, loginUserId } = reduxStore;
  const totalAmount = value * selectPlanAmount;
  const taxAmount = totalAmount * 0.18;
  const grandTotal = totalAmount + taxAmount;
  const [billingmsg, setBillingMsg] = useState("");
  const [phoneNumberMsg, setPhoneNumberMsg] = useState("");
  const [addressMsg, setAddressMsg] = useState("");
  const [stateMsg, setStateMsg] = useState("");

  const resetState = () => {
    setBillingName("");
    setPhoneNumber("");
    setCountry("India");
    setStreetAddress("");
    setState("");
    setCity("");
    setZip("");
    setGstin("");
    setIsGstinRegistered(null);
    setValue(1);
    setSelectPlanDetail(false);
  };
  const handleSignOut = async () => {
    const requestBody = {
      loggedInUserId: loginUserId,
      deviceId: localStorage.getItem("deviceId"),
    };

    await axios
      .post(URI.signOut, requestBody, {
        headers: getHeaders(true),
      })

      .then((response) => {
        if (response.data.status === 200 || response.data.status === 201) {
          window.sessionStorage.clear();
          window.localStorage.clear();
          window.history.forward();
          navigate("../");
        }
      }).catch(async (error) => {
        handleApiError(error, navigate);
      });
  };
  const handleProceed = () => {
    if (value > 0) {
      setSelectPlan("proceed");
    } else {
      setErrorMesageValue("No of users must be greater than 0");
      setTimeout(() => {
        setErrorMesageValue("");
      }, 3000);
    }
  };

  const handleChange = (e) => {
    setValue(e.target.value);
  };

  const handleChangeInputBox = (setter) => (event) => {
    const value = event.target.value;

    // Prevent updating state if the first character is a space
    if (value.length === 1 && value === " ") {
      return;
    }

    setter(value);
  };

  const getSubscriptionPlanDetails = async () => {
    await axios
      .get(URI.subscriptionPlan + "/" + selectUpgrade)

      .then((response) => {
        if (response.data.status === 200) {
          setSubscriptionPlanDetails(response.data.data);
        }
      })
      .catch(async (error) => {
        handleApiError(error, navigate);
      });
  };

  const insertSubscriptionPlan = async () => {
    const errors = {};
    let hasError = false;

    if (!billingName) {
      errors.billingName = "Billing Name cannot be empty.";
      hasError = true;
    }

    if (!streetAddress) {
      errors.streetAddress = "Street Address cannot be empty.";
      hasError = true;
    }
    if (!phoneNumber) {
      errors.phoneNumber = "Phone Number cannot be empty.";
      hasError = true;
    }

    if (!state) {
      errors.state = "State cannot be empty.";
      hasError = true;
    }

    if (!city) {
      errors.city = "City cannot be empty.";
      hasError = true;
    }

    if (hasError) {
      setPhoneNumberMsg(errors.phoneNumber || "");
      setStateMsg(errors.state || "");
      setAddressMsg(errors.streetAddress || "");
      setBillingMsg(errors.billingName || "");
      setTimeout(() => {
        setBillingMsg("");
        setAddressMsg("");
        setStateMsg("");
        setPhoneNumberMsg("");
      }, 3000);

      return; // Stop further execution
    }

    const requestBody = {
      loggedInUserId: loginUserId,
      country: country,
      state: state,
      city: city,
      zip: zip,
      companyName: billingName,
      address: streetAddress,
      phone: phoneNumber,
      planName: selectPlanName,
      gstin: isGstinRegistered,
      amount: grandTotal.toFixed(2),
      billingCycle: selectUpgrade,
      noOfUsers: value,
      gstinOrBusinessType:
        isGstinRegistered === "true" ? gstin : "Non Registered Business",
      tax: 18.0,
    };

    try {
      const response = await axios.post(URI.insertSubscription, requestBody);
      if (response.data.status === 200) {
        setSelectPlan("success");
        resetState();
        NotificationManager.success("", response.data.message, 5000);
      } else {
        setSelectPlan("error");
        resetState();
        NotificationManager.error("", response.data.message, 5000);
      }
    } catch (error) {
      handleApiError(error, navigate);
    }
  };

  useEffect(() => {
    getSubscriptionPlanDetails();
  }, [selectUpgrade]);

  const selectPlanStandard = () => {
    return (
      <div
        className="d-flex justify-content-center mt-4"
        style={{ width: "100%" }}
      >
        <div>
          <div className="userSubscribe text-start">Subscribe to chain CRM</div>
          <div className="text-start ">
            <span className="planData">₹{selectPlanAmount}</span>
            <span className="userSubscribe">/user</span>
            <span className="userSubscribe">
              {selectUpgrade === "Monthly" ? "/month" : "/annually"}
            </span>
            <span className="standardButton p-2 px-2 ms-1">
              {selectPlanDetail ? "PREMIUM" : "STANDARD"}
            </span>
          </div>
          <div>
            <div style={{ width: "25vw" }}>
              <div
                className="mt-2"
                style={{
                  border: "1px solid rgba(231, 231, 231, 0.5)",
                  borderRadius: "10px",
                }}
              >
                <div
                  className="userSubscribe p-2"
                  style={{
                    backgroundColor: "#EEF3FF",
                    border: "1px solid rgba(231, 231, 231, 0.5)",
                    borderRadius: "6px",
                  }}
                >
                  CRM Plus
                </div>
                <div className="d-flex justify-content-between align-items-center p-2">
                  <div className="userSubscribe">No. of Users</div>
                  <input
                    type="number"
                    value={value}
                    onChange={handleChange}
                    className="numberInputBox"
                    min="1"
                  />
                </div>
              </div>
            </div>
            <div style={{ width: "25vw" }}>
              <div
                className="mt-4"
                style={{
                  border: "1px solid rgba(231, 231, 231, 0.5)",
                  borderRadius: "10px",
                }}
              >
                <div
                  className="userSubscribe p-2"
                  style={{
                    backgroundColor: "#EEF3FF",
                    border: "1px solid rgba(231, 231, 231, 0.5)",
                    borderRadius: "6px",
                  }}
                >
                  Summary
                </div>
                <div className="d-flex justify-content-between align-items-center p-2">
                  <div className="userSubscribe">Sub Total</div>
                  <div>₹{totalAmount}</div>
                </div>
                <div className="d-flex justify-content-between align-items-center p-2">
                  <div className="userSubscribe">Tax</div>
                  <div>₹{taxAmount.toFixed(2)}</div>
                </div>
                <div
                  style={{ borderBottom: "1px solid #B9B9B9" }}
                  className="ms-4 me-4 mt-4"
                ></div>
                <div className="d-flex justify-content-between align-items-center p-2">
                  <div className="userSubscribe">Total Amount</div>
                  <div>₹{grandTotal.toFixed(2)}</div>
                </div>
                <div className="py-2">
                  <div
                    className="proceedButton p-1 ms-2 me-2 cursorPointer"
                    onClick={handleProceed}
                  >
                    PROCEED{" "}
                  </div>
                </div>
                <div style={{ color: "red", fontSize: "14px" }}>
                  {errorMessageValue}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const proccedPlan = () => {
    return (
      <div
        className="d-flex justify-content-center mt-2"
        style={{ width: "100%" }}
      >
        <div className="py-2">
          <div className="userSubscribe text-start">Subscribe to chain CRM</div>
          <div className="text-start ">
            <span className="planData">₹{selectPlanAmount}</span>
            <span className="userSubscribe">/user</span>
            <span className="userSubscribe">
              {selectUpgrade === "Monthly" ? "/month" : "/annually"}
            </span>
            <span className="standardButton p-2 px-2 ms-1">
              {selectPlanDetail ? "PREMIUM" : "STANDARD"}
            </span>
          </div>
          <div className="d-flex gap-4 justify-content-between ">
            <div style={{ width: "28vw" }}>
              <div
                className="mt-2"
                style={{
                  border: "1px solid rgba(231, 231, 231, 0.5)",
                  borderRadius: "10px",
                }}
              >
                <div
                  className="userSubscribe p-2"
                  style={{
                    backgroundColor: "#EEF3FF",
                    border: "1px solid rgba(231, 231, 231, 0.5)",
                    borderRadius: "1px",
                  }}
                >
                  Billing Details
                </div>
                <div className="p-2">
                  <input
                    type="text"
                    className="mt-2 upgradeInputBox p-2"
                    placeholder="Billing Name/Company Name"
                    value={billingName}
                    // onChange={(e) => setBillingName(e.target.value)}
                    onChange={handleChangeInputBox(setBillingName)}
                  />
                  {billingmsg && <p style={{ color: "red" }}>{billingmsg}</p>}
                  <input
                    type="number"
                    className="mt-2 upgradeInputBox p-2"
                    placeholder="Phone Number"
                    value={phoneNumber}
                    onChange={handleChangeInputBox(setPhoneNumber)}
                    maxLength={10}
                  />
                  {phoneNumberMsg && (
                    <p style={{ color: "red" }}>{phoneNumberMsg}</p>
                  )}
                  <input
                    type="text"
                    className="mt-2 upgradeInputBox p-2"
                    placeholder="Country"
                    value={country}
                    onChange={handleChangeInputBox(setCountry)}
                    readOnly
                  />

                  <input
                    type="text"
                    className="mt-2 upgradeInputBox p-2"
                    placeholder="Street Address"
                    value={streetAddress}
                    onChange={handleChangeInputBox(setStreetAddress)}
                  />
                  {addressMsg && <p style={{ color: "red" }}>{addressMsg}</p>}
                  <input
                    type="text"
                    className="mt-2 upgradeInputBox p-2"
                    placeholder="State"
                    value={state}
                    onChange={handleChangeInputBox(setState)}
                  />
                  {stateMsg && <p style={{ color: "red" }}>{stateMsg}</p>}
                  <div className="d-flex gap-3">
                    <input
                      type="text"
                      className="mt-2 upgradeInputBoxCity p-2"
                      placeholder="City"
                      value={city}
                      onChange={handleChangeInputBox(setCity)}
                    />
                    <input
                      type="number"
                      className="mt-2 upgradeInputBoxZip p-2"
                      placeholder="Zip"
                      value={zip}
                      onChange={handleChangeInputBox(setZip)}
                      maxLength={6}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div style={{ width: "25vw" }}>
              <div
                className="mt-2"
                style={{
                  border: "1px solid rgba(231, 231, 231, 0.5)",
                  borderRadius: "10px",
                }}
              >
                <div
                  className="userSubscribe p-2"
                  style={{
                    backgroundColor: "#EEF3FF",
                    border: "1px solid rgba(231, 231, 231, 0.5)",
                    borderRadius: "1px",
                  }}
                >
                  Summary
                </div>
                <div className="d-flex justify-content-between align-items-center p-2">
                  <div className="userSubscribe">Sub Total</div>
                  <div>₹{totalAmount}</div>
                </div>
                <div className="d-flex justify-content-between align-items-center p-2">
                  <div className="userSubscribe">Tax</div>
                  <div>₹{taxAmount.toFixed(2)}</div>
                </div>
                <div
                  style={{ borderBottom: "1px solid #B9B9B9" }}
                  className="ms-4 me-4 mt-4"
                ></div>
                <div className="d-flex justify-content-between align-items-center p-2">
                  <div className="userSubscribe">Total Amount</div>
                  <div>₹{grandTotal.toFixed(2)}</div>
                </div>
                <div className="py-2">
                  <div
                    className="proceedButton p-1 ms-2 me-2 cursorPointer"
                    onClick={() => {
                      insertSubscriptionPlan();
                    }}
                  >
                    PROCEED{" "}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="gstinClass mt-4 p-2">
            <div className="d-flex gap-2 align-items-center">
              <div>Are you registered for GSTIN?</div>
              <input
                type="radio"
                name="gstinRegistered"
                checked={isGstinRegistered === true}
                onChange={() => setIsGstinRegistered(true)}
              />
              <span>Yes</span>
              <input
                type="radio"
                name="gstinRegistered"
                checked={isGstinRegistered === false}
                onChange={() => setIsGstinRegistered(false)}
              />
              <span>No</span>
            </div>
            {isGstinRegistered && (
              <div className="d-flex gap-2 align-items-center mt-2">
                <div className="d-flex gap-2 align-items-center mt-2">
                  <input
                    type="text"
                    className="mt-2 upgradeInputBoxGstin p-2"
                    placeholder="GSTIN"
                    value={gstin}
                    onChange={handleChangeInputBox(setGstin)}
                  />
                  <img src={GstinImage} alt="gstin" />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  };

  const paymentSuccess = () => {
    return (
      <div
        className="d-flex justify-content-center align-items-center"
        style={{ width: "100%", height: "100vh" }}
      >
        <div className="py-2">
          <div
            className="mt-4 paymentSuccess d-flex flex-column p-2"
            style={{ height: "auto", width: "36vw" }}
          >
            <div
              style={{ fontSize: "30px", color: "#515151", fontWeight: "500" }}
              className="mt-4 ms-2"
            >
              Payment Successful!
            </div>
            <div
              style={{ fontSize: "18px", color: "#515151", fontWeight: "300" }}
              className="ms-2"
            >
              <div>Your premium subscription will expire on 02 June 2024</div>
            </div>
            <div className="mt-2 ms-2">
              <button className="dashboardButton p-2">Go to Dashboard</button>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const paymentError = () => {
    return (
      <div
        className="d-flex justify-content-center align-items-center"
        style={{ width: "100%", height: "100vh" }}
      >
        <div
          className="mt-4 paymentSuccess d-flex flex-column p-2"
          style={{ height: "30vh", width: "36vw" }}
        >
          <div
            style={{ fontSize: "30px", color: "#515151", fontWeight: "500" }}
            className="mt-4 ms-2"
          >
            Payment Error!
          </div>
          <div
            style={{ fontSize: "18px", color: "#515151", fontWeight: "300" }}
            className="ms-2"
          >
            <div>Sorry,your transaction has failed.</div>
            <div>Please try again</div>
          </div>
          <div className="mt-2 ms-2">
            <button
              className="dashboardButton p-2"
              onClick={() => {
                setSelectPlan("current");
                setSelectUpgrade("Monthly");
              }}
            >
              Try Again
            </button>
          </div>
        </div>
      </div>
    );
  };

  const selectPlanCurrent = () => {
    return (
      <div className="d-flex justify-content-center" style={{ width: "100%" }}>
        <div>
          <div className="mt-4 w-100 text-center">
            <div style={{ color: "#12A7FE", fontSize: "20px" }}>Pricing</div>
          </div>
          <div className="upgradeHeader text-center">
            Boost your revenue with
          </div>
          <div className="text-center upgradeHeader"> data-driven sales </div>
          <div className="d-flex justify-content-center p-1 ">
            <div className="d-flex gap-2 selectedPlanClass p-1">
              <div
                className={`p-1 px-2 ms-2 cursorPointer ${
                  selectUpgrade === "Monthly"
                    ? "selectedPlan"
                    : "deselectedPlan"
                }`}
                onClick={() => setSelectUpgrade("Monthly")}
              >
                Monthly
              </div>
              <div
                className={`p-1 px-2 cursorPointer ${
                  selectUpgrade === "Yearly" ? "selectedPlan" : "deselectedPlan"
                }`}
                onClick={() => setSelectUpgrade("Yearly")}
              >
                Annually
              </div>
            </div>
          </div>
          <div
            className="mt-2 d-flex gap-4 justify-content-center align-items-end"
            style={{ height: "60vh" }}
          >
            {subscriptionPlanDetails?.map(
              (item, index) =>
                item.planName === "Free Plan" && (
                  <div
                    key={index}
                    className="planDetails position-relative"
                    style={{ height: "44vh" }}
                  >
                    <div>
                      <div className="ms-4 pt-2">{item.planName}</div>
                      <div className="planData ms-4"> ₹{item.price}</div>
                      <ul className="listClass">
                        <li>{item.featuresDescription}</li>
                        <li>Up to {item.maximumUsers} Users</li>
                        <li>{item.support}</li>
                        <li>Free</li>
                      </ul>
                      <div className="ms-3">
                        <button className="planButton p-1">CURRENT PLAN</button>
                      </div>
                    </div>
                  </div>
                )
            )}

            {subscriptionPlanDetails.map(
              (item, index) =>
                item.planName === "Standard Plan" && (
                  <div key={index}>
                    <div className="recommendedClassTop p-1">
                      <div className="recommendedClass p-1 px-2">
                        Recommended
                      </div>
                    </div>
                    <div
                      className="planDetails position-relative mt-2"
                      style={{ height: "50vh" }}
                    >
                      <div className="ms-4 pt-2">{item.planName}</div>
                      <div className="planData ms-4"> ₹{item.price}</div>
                      <ul className="listClass">
                        <li>₹18/User</li>
                        <li>{item.featuresDescription}</li>
                        <li>Unlimited Users</li>
                        <li>Increased Storage</li>
                        <li>{item.support}</li>
                      </ul>
                      <div className="ms-3">
                        <button
                          className="planButton p-1"
                          onClick={() => {
                            setSelectPlan("standard");
                            setSelectPlanName("Standard Plan");
                            setSelectPlanAmount(item.price);
                          }}
                        >
                          SELECT PLAN
                        </button>
                      </div>
                    </div>
                  </div>
                )
            )}

            {subscriptionPlanDetails.map(
              (item, index) =>
                item.planName === "Premium Plan" && (
                  <div
                    key={index}
                    className="planDetails position-relative"
                    style={{ height: "58vh" }}
                  >
                    <div>
                      <div className="ms-4 pt-2">{item.planName}</div>
                      <div className="planData ms-4"> ₹{item.price}</div>
                      <ul className="listClass">
                        <li>₹35/User</li>
                        <li>{item.featuresDescription}</li>
                        <li>Custom Modules</li>
                        <li>Blockchain Security</li>
                        <li>Advanced Analytics</li>
                        <li>{item.support}</li>
                      </ul>
                      <div className="ms-3">
                        <button
                          className="planButton p-1"
                          onClick={() => {
                            setSelectPlanAmount(item.price);
                            setSelectPlan("standard");
                            setSelectPlanName("Premium Plan");
                            setSelectPlanDetail(true);
                          }}
                        >
                          SELECT PLAN
                        </button>
                      </div>
                    </div>
                  </div>
                )
            )}
          </div>
          <div className="d-flex justify-content-center p-2">
            <div className="mt-4 learnMoreHeader">
              <div className="text-center learnMoreDetails py-2">
                <span>Add-ons are powerful upgrades</span>
              </div>
              <div className="text-center learnMoreDetails">
                <span>for your CRM Capabilities</span>
              </div>
              <div className="mt-1 d-flex justify-content-center py-2">
                <button className="learnMoreButton p-1">LEARN MORE</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div>
      <div
        style={{ backgroundColor: "#F3F6FB", height: "8vh" }}
        className="d-flex justify-content-between p-1 align-items-center"
      >
        <img src={CrmLogo} alt="logo" height={35} width={200} />
        <img
          style={{
            border: "2px solid #fff",
            borderRadius: "20px",
            padding: "5px",
          }}
          width={36}
          height={36}
          // src={profile}
          src={loginUserInfo.userImageData || leadAvatar}
          alt="img"
          className="cursorPointer position-relative"
          onClick={(e) => {
            e.stopPropagation();
            setShowLogout(!showLogout);
          }}
        />
        {showLogout && (
          <div
            id="profileInfo"
            className="upgradeInfoContainer"
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <div className="triangle-container triangleClass">
              <div className="triangle"></div>
            </div>
            <div className="bg-white p-1">
              <div
                style={{
                  color: "#515151",
                  fontSize: "16px",
                  fontWeight: "400",
                }}
              >
                {loginUserInfo.firstName} {loginUserInfo.lastName}
              </div>
              <div
                style={{
                  color: "#515151",
                }}
                className="small opacity-75 overflow_text"
              >
                {loginUserInfo.emailId}
              </div>
              <div
                style={{
                  borderBottom: "1px solid rgba(185, 185, 185, 0.7) ",
                }}
                className="mt-1"
              ></div>
              <div className="mt-2 d-flex gap-3 align-items-center cursorPointer">
                <img src={Logout} alt="logout" height={30} />
                <div
                  style={{
                    color: "#F45B69",
                    fontSize: "14px",
                    fontWeight: "400",
                  }}
                  onClick={handleSignOut}
                >
                  Sign Out
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      {selectPlan === "current" && selectPlanCurrent()}
      {selectPlan === "standard" && selectPlanStandard()}
      {selectPlan === "proceed" && proccedPlan()}
      {selectPlan === "success" && paymentSuccess()}
      {selectPlan === "error" && paymentError()}
      <div>
        <NotificationContainer />
      </div>
    </div>
  );
}

export default Upgrade;

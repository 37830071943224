import React, { useState, useEffect } from "react";
import "./Setting.css";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import settingGroup from "../../Assets/setting group.svg";
import security from "../../Assets/security-check.svg";
import downArrow from "../../Assets/Polygon 2.svg";
import call from "../../Assets/call.svg";
import emailImage from "../../Assets/mail-05.svg";
import { URI } from "../../constants";
import axios from "axios";
import loginHistoryImage from "../../Assets/loginHistoryImage.svg";
import Chrome from "../../Assets/ChromeNew.svg";
import Desktop from "../../Assets/DesktopImage.svg";
import { Modal } from "react-bootstrap";
import { Personal } from "./Personal";
import SplineAreaChart from "./SplineAreaChart";
import Search from "../../Assets/search.svg";
import down from "../../Assets/Down31.svg";
import up from "../../Assets/Up.svg";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import { Tree, TreeNode } from "react-organizational-chart";
import filter from "../../Assets/filter.svg";
import { useSelector } from "react-redux";
import Cancel from "../../Assets/CancelCircleModal.svg";
import AccessModal from "../../common/AccessModal";
import groupIcon from "../../Assets/showGroupImage.svg";
import leftArrow from "../../Assets/arrowLeftIcon.svg";
import EditIcon from "../../Assets/editIconNew.svg";
import moment from "moment";
import leadAvatar from "../../Assets/Lead_Avatar.png";
import Info from "../../Assets/deleteUserInfo.svg";
import Edit from "../../Assets/EditOrg.svg";
import Delete from "../../Assets/DeleteOrg.svg";
import {
  camelCaseToSpace,
  validateAliasName,
  validateFirstName,
  validateLastName,
  websiteValidation,
  customCommonStyles,
  validateZipCode,
  validateFaxNumber,
  validateCityIndia,
  validateStateIndia,
  validateCountryName
} from "../../common/CommonComponent";
import { setLoginUserInfo } from "../../Redux/stateSlice";
import { useDispatch } from "react-redux";
import CrossIcon from "../../Assets/CrossIcon.svg";
import databaseSetting from "../../Assets/database-setting.svg";
import cancelCircle from "../../Assets/cancelCircle.svg";
import cancelFilter from "../../Assets/CancelFilter.svg";
import Download from "../../Assets/DownloadExport.svg";
import {
  exportDataModules,
  fieldsLabel,
  exportDataChooseFields,
} from "./ExportData";
import {
  removeExtraSpaces,
  validateRoleName,
} from "../../common/CommonComponent";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import Lottie from "react-lottie";
import { defaultOptions as customDefaultOptions } from "../../utils/defaultOptions";
import CompanySettings from "./CompanySettings";
import getHeaders from "../../common/ApiHeader.js";
import { handleApiError } from "../../utils/ApiUtils";
import { useNavigate } from "react-router-dom";
import showPasswordIcon from "../../Assets/showPassword.svg";
import hidePasswordIcon from "../../Assets/ClosePassword.svg";
import alert from "../../Assets/alert-circle (1).svg";
import { Tooltip } from "react-tooltip";
import Pagination from "../../common/Pagination";
import Select from "react-select";
import { pageOption } from "../../common/CommonData";

export const Setting = () => {
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState("user");
  const [menuTab, setMenuTab] = useState("Personal");
  const [isMenuOne, setIsMenuOne] = useState(true);
  const [isMenuTwo, setIsMenuTwo] = useState(false);
  const [isMenuThree, setIsMenuThree] = useState(false);
  const [allActivity, setAllActivity] = useState("All Activity");
  const [selectedItem, setSelectedItem] = useState("Active");
  const [displayValue, setDisplayValue] = useState("Active Users");
  const [initialUser, setInitialUser] = useState([]);
  const [initialAllUser, setInitialAllUser] = useState([]);
  const [search, setSearch] = useState("");
  const [showAddUserModal, setShowAddUserModal] = useState(false);
  const [roles, setRoles] = useState([]);
  const [rolesList, setRolesList] = useState([]);
  const [profileLists, setProfileLists] = useState([]);
  const [profileListVisible, setProfileListVisible] = useState(false);
  const [rolesModal, setRolesModal] = useState(false);
  const [selectedRoleItem, setSelectedRoleItem] = useState("");
  const [selectTransferId, setSelectTransferId] = useState("");
  const [selectedProfileItem, setSelectedProfileItem] = useState({});
  const listVisible = false;
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const [roleError, setRoleError] = useState("");
  const [profileError, setProfileError] = useState("");
  const [getLoginActivityData, setLoginActivityData] = useState([]);
  const [getLoginChartData, setLoginChartData] = useState([]);
  const [selectActivity, setSelectActivity] = useState("Today");
  const [specificDate, setSpecificDate] = useState("");
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [recycleBinHistoryData, setRecycleBinHistoryData] = useState([]);
  const [permissionData, setPermissionData] = useState([]);
  const [sampleData, setSampleData] = useState([]);
  const [profileInfo, setProfileInfo] = useState([]);
  const [groupListData, setGroupListData] = useState([]);
  const [searchGroup, setSearchGroup] = useState("");
  const [showGroup, setShowGroup] = useState("group");
  const [auditLogData, setAuditLogData] = useState([]);
  const [showDropdownFilters, setShowDropdownFilters] = useState(false);
  const [showExportDropdownFilters, setShowExportDropdownFilters] =
    useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [recycleModalShow, setRecycleModalShow] = useState(false);
  const [deleteConfirmationModal, setDeleteConfirmationModal] = useState(false);
  const [createGroupModal, setCreateGroupModal] = useState(false);
  const [showGroupItems, setShowGroupItems] = useState(false);
  const [showProfileItems, setShowProfileItems] = useState(false);
  const [activeIndex, setActiveIndex] = useState("");
  const [hoveredRow, setHoveredRow] = useState(null);
  const [hoveredProfile, setHoveredProfile] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);
  const [selectedProfile, setSelectedProfile] = useState(null);
  const [dropdownAllUsers, setSelectDropdownAllUsers] = useState("All Users");
  const [dropdownExportAllUsers, setSelectDropdownExportAllUsers] =
    useState("All Users");
  const [dropdownExportAllUsersValue, setDropdownExportAllUsersValue] =
    useState("all_users");
  const [dropdownAllActions, setSelectDropdownAllActions] =
    useState("All Actions");
  const [dropdownExportActions, setDropdownExportActions] =
    useState("All Modules");
  const [dropdownExportActionsValue, setDropdownExportActionsValue] =
    useState("all_modules");
  const [dropdownTime, setSelectDropdownTime] = useState("Any time");
  const [dropdownExportTime, setDropdownExportTime] = useState("Anytime");
  const [dropdownExportTimeValue, setDropdownExportTimeValue] =
    useState("anytime");
  const [activeGroupTab, setActiveGroupTab] = useState("users");
  const [deleteGroup, setDeleteGroup] = useState(false);
  const [editRename, setEditRename] = useState(false);
  const [showAddMemberModal, setShowAddMemberModal] = useState(false);
  const [selectMembers, setSelectMembers] = useState("users");
  const [searchMember, setSearchMember] = useState("");
  const [searchViewUser, setSearchViewUser] = useState("");
  const [searchProfile, setSearchProfile] = useState("");
  const [groupIdIndividual, setGroupIdInvidual] = useState("");
  const [groupName, setGroupName] = useState([]);
  const [addNewMember, setAddNewMember] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [groupMembersList, setGroupMembersList] = useState([]);
  const [showDateRange, setShowDateRange] = useState(false);
  const [searchAllMembers, setSearchAllMembers] = useState("");
  const [selectedGroupStatus, setSelectedGroupStatus] = useState("Active");
  const [selectedStatusName, setSelectedStatusName] = useState("Active User");
  const [selectedMemberName, setSelectedMemberName] = useState("Users");
  const [searchUsersRolesGroups, setSearchUsersRolesGroups] = useState("");
  const [deleteUserModal, setDeleteUserModal] = useState(false);
  const [selectedOptionDelete, setSelectedOptionDelete] = useState("delete");
  const [selectedUserList, setSelectedUserList] = useState("");
  const [userListVisible, setUserListVisible] = useState(false);
  const [editUserModal, setEditUserModal] = useState(false);
  const [addNewProfile, setAddNewProfile] = useState(false);
  const [storageModal, setStorageModal] = useState(false);
  const [selectedStorageModule, setSelectedStorageModule] = useState("");
  const [initialExportPage, setInitialExportPage] = useState("initial");
  const [changePasswordTab, setChangePasswordTab] = useState("password");
  const [hoveredIndex, setHoveredIndex] = useState(null);
  const [storeExportData, setStoreExportData] = useState({});
  const [selectedModule, setSelectedModule] = useState("");

  const [selectedField, setSelectedField] = useState("");
  const [selectedFieldsListVisible, setSelectedFieldsListVisible] =
    useState(false);
  const [checkedFields, setCheckedFields] = useState([]);
  const [listVisibleModule, setListVisibleModule] = useState(false);
  const [moduleListVisible, setModuleListVisible] = useState(false);
  const [storeExportId, setStoreExportId] = useState("");
  const [showExportAuditLog, setShowExportAuditLog] = useState(false);
  const [openDropdown, setOpenDropdown] = useState(null);
  const [selectedModuleList, setSelectedModuleList] = useState({
    label: "",
    value: "",
  });
  const [showPasswordNew, setShowPasswordNew] = useState(false);
  // const [flagValue, setFlagValue] = useState(1);
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [otpVerification, setOtpVerification] = useState("");
  const [showPasswordNewCreate, setShowPasswordNewCreate] = useState(false);

  const [currentPageAudit, setCurrentPageAudit] = useState(1);
  const [perPageAudit, setPerPageAudit] = useState(10);
  const [totalItemsAudit, setTotalItemsAudit] = useState(0);
  const [currentPageExport, setCurrentPageExport] = useState(1);
  const [perPageExport, setPerPageExport] = useState(10);
  const [totalItemsExport, setTotalItemsExport] = useState(0);

  const handlePageChangeAudit = (page) => {
    setCurrentPageAudit(page);
  };

  const totalPagesAudit = Math.ceil(Number(totalItemsAudit) / perPageAudit);

  const handlePageDropDowmChangeAudit = (selectedOption) => {
    setCurrentPageAudit(1);
    setPerPageAudit(parseInt(selectedOption.value));
  };

  const handlePageChangeExport = (page) => {
    setCurrentPageExport(page);
  };

  const totalPagesExport = Math.ceil(Number(totalItemsExport) / perPageExport);

  const handlePageDropDowmChangeExport = (selectedOption) => {
    setCurrentPageExport(1);
    setPerPageExport(parseInt(selectedOption.value));
  };
  const handleToggle = (dropdownIndex) => {
    setOpenDropdown(openDropdown === dropdownIndex ? null : dropdownIndex);
  };
  const moduleNames = Object.keys(exportDataModules);
  const { sampleDataCount } = sampleData;
  const [isFocused, setIsFocused] = useState(false);
  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleBlur = () => {
    setIsFocused(false);
    if (otpVerification.length === 0) {
      setOtpVerification("");
    }
  };

  const dropdownExportItems = [
    { label: "All modules", value: "all_modules" },
    { label: "Tasks", value: "tasks" },
    { label: "Vendors", value: "vendors" },
    { label: "Contacts", value: "contacts" },
    { label: "Deals", value: "deals" },
    { label: "Leads", value: "leads" },
    { label: "Products", value: "products" },
    { label: "Sales Orders", value: "sales_orders" },
    { label: "Purchase Order", value: "purchase_order" },
    { label: "Invoices", value: "invoices" },
    { label: "Calls", value: "calls" },
    { label: "Meetings", value: "meetings" },
    { label: "Accounts", value: "accounts" },
  ];

  const handleModuleClick = (moduleName) => {
    setSelectedModule(moduleName);
    setSelectedModuleList(exportDataModules[moduleName][0] || "");
    setSelectedField(fieldsLabel.fields[0] || "");
    setListVisibleModule(false);
  };

  const handleMuduleListClick = (item) => {
    setSelectedModuleList(item);
    setModuleListVisible(false);
  };

  const handleFieldItemClick = (item) => {
    setSelectedField(item);
    setSelectedFieldsListVisible(false);
  };

  const togglePasswordVisibility = () => {
    setShowPasswordNew(!showPasswordNew);
  };

  const toggleNewPasswordVisibility = () => {
    setShowPasswordNewCreate(!showPasswordNewCreate);
  };

  useEffect(() => {
    if (selectedField.value === "choose_fields") {
      const fields =
        selectedModuleList.value === "converted_leads"
          ? exportDataChooseFields["convertedLeads"]
          : exportDataChooseFields[selectedModule] || [];

      const newCheckedFields = fields.map((field) => field.value);
      setCheckedFields((prev) => ({
        ...prev,
        [selectedModule]:
          prev[selectedModule]?.filter((field) =>
            newCheckedFields.includes(field)
          ) || [],
      }));
    }
  }, [selectedField, selectedModule, selectedModuleList]);

  const handleCheckboxChangeExport = (value) => {
    setCheckedFields((prev) => {
      const currentCheckedFields = prev[selectedModule] || [];
      const newCheckedFields = currentCheckedFields.includes(value)
        ? currentCheckedFields.filter((field) => field !== value)
        : [...currentCheckedFields, value];
      return {
        ...prev,
        [selectedModule]: newCheckedFields,
      };
    });
  };

  const handleOnDragEnd = (result) => {
    if (!result.destination) return;

    const moduleCheckedFields = Array.from(checkedFields[selectedModule] || []);
    const [removed] = moduleCheckedFields.splice(result.source.index, 1);
    moduleCheckedFields.splice(result.destination.index, 0, removed);

    setCheckedFields((prev) => ({
      ...prev,
      [selectedModule]: moduleCheckedFields,
    }));
  };

  const renderCheckboxes = () => {
    if (selectedField.value !== "choose_fields") return null;
    const fields =
      selectedModuleList.value === "converted_leads"
        ? exportDataChooseFields["convertedLeads"]
        : exportDataChooseFields[selectedModule] || [];

    const moduleCheckedFields = checkedFields[selectedModule] || [];
    const checkedFieldsSet = new Set(moduleCheckedFields);
    const uncheckedFields = fields.filter(
      (field) => !checkedFieldsSet.has(field.value)
    );

    const sortedCheckedFields = moduleCheckedFields
      .map((fieldValue) => fields.find((f) => f.value === fieldValue))
      .filter(Boolean);
    const sortedUncheckedFields = uncheckedFields.sort((a, b) =>
      a.label.localeCompare(b.label)
    );

    return (
      <div
        className="checkbox-list mt-2 ms-2 bg-white rounded-2"
        style={{ height: "50vh", overflowY: "scroll", width: "25vw" }}
      >
        <DragDropContext onDragEnd={handleOnDragEnd}>
          <Droppable droppableId="checkboxes">
            {(provided) => (
              <div {...provided.droppableProps} ref={provided.innerRef}>
                {sortedCheckedFields.map((field, index) => (
                  <Draggable
                    key={field.value}
                    draggableId={field.value}
                    index={index}
                  >
                    {(provided) => (
                      <div
                        className="form-check dragAndDrop"
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                      >
                        <input
                          type="checkbox"
                          className="form-check-input"
                          id={`checkbox-${field.value}`}
                          checked={checkedFieldsSet.has(field.value)}
                          onChange={() =>
                            handleCheckboxChangeExport(field.value)
                          }
                        />
                        <label
                          className="form-check-label dragAndDrop"
                          htmlFor={`checkbox-${field.value}`}
                        >
                          {field.label}
                        </label>
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
        {sortedUncheckedFields.map((field) => (
          <div key={field.value} className="form-check">
            <input
              type="checkbox"
              className="form-check-input"
              id={`checkbox-${field.value}`}
              checked={checkedFieldsSet.has(field.value)}
              onChange={() => handleCheckboxChangeExport(field.value)}
            />
            <label
              className="form-check-label"
              htmlFor={`checkbox-${field.value}`}
            >
              {field.label}
            </label>
          </div>
        ))}
      </div>
    );
  };

  const selectedModuleData = exportDataModules[selectedModule] || [];

  const [userInfoUpdate, setUserInfoUpdate] = useState({
    firstName: "",
    lastName: "",
    aliasName: "",
    phoneNumber: "",
    mobileNumber: "",
    website: "",
    fax: "",
    dateOfBirth: "",
    street: "",
    city: "",
    state: "",
    zipCode: "",
    country: "",
  });
  const dispatch = useDispatch();

  const getTodayDate = () => {
    const today = new Date();
    const year = today.getFullYear();
    let month = today.getMonth() + 1;
    let day = today.getDate();

    // Ensure month and day are formatted as two digits
    if (month < 10) {
      month = "0" + month;
    }
    if (day < 10) {
      day = "0" + day;
    }

    return `${year}-${month}-${day}`;
  };

  const [addNewProfileMember, setAddNewProfileMember] = useState({
    profileName: "",
    profileDescription: "",
  });
  const [profileNameError, setProfileNameError] = useState("");
  const [cloneprofileError, setCloneProfileError] = useState("");
  const [profileShow, setProfileShow] = useState("profile");
  const [selectedAction, setSelectedAction] = useState(null);
  const [profileId, setProfileId] = useState("");
  const [profileDetails, setProfileDetails] = useState("");
  const [activeRole, setActiveRole] = useState("role");
  const [activeRoleItems, setActiveRoleItems] = useState("roles");
  const [hiddenNodes, setHiddenNodes] = useState([]);
  const [addNewRoleMember, setAddNewRoleMember] = useState({
    roleName: "",
    description: "",
  });

  const [editRoleMember, setEditRoleMember] = useState({
    roleName: "",
    description: "",
  });

  const [selectedReportsTo, setSelectedReportsTo] = useState("");
  const [shareDataWithPeers, setShareDataWithPeers] = useState(false);
  const [roleNameError, setRoleNameError] = useState("");
  const [selectedRoleId, setSelectedRoleId] = useState("");
  const [hoveredNodeId, setHoveredNodeId] = useState(null);
  const [storeRoleNameDeleted, setStoreRoleNameDeleted] = useState("");
  const [highlightedRoles, setHighlightedRoles] = useState([]);
  const [renameProfileModal, setRenameProfileModal] = useState(false);
  const [cloneProfile, setCloneProfile] = useState(false);
  const [viewUsersModal, setViewUsersModal] = useState(false);
  const [viewUsersData, setViewUsersData] = useState([]);
  const [rolesListData, setRolesListData] = useState([]);
  const [editRoleId, setEditRoleId] = useState("");
  const [isLoginHistoryClicked, setIsLoginHistoryClicked] = useState(false);
  const [isAuditTabClicked, setIsAuditTabClicked] = useState(false);
  const [isExportTabClicked, setIsExportTabClicked] = useState(false);
  const [activeDeactiveModal, setActiveDeactiveModal] = useState(false);
  const [modalAction, setModalAction] = useState(null);
  const [searchLoginActivity, setSearchLoginActivity] = useState("");
  const [loginActivityUserData, setLoginActivityUserData] = useState([]);
  const [isFirstCharacterEntered, setIsFirstCharacterEntered] = useState(false);
  const [getAllExportData, setAllExportData] = useState([]);
  const today = new Date();
  const twelveYearsAgo = new Date(today.setFullYear(today.getFullYear() - 12));
  const [updateFirstNameError, setUpdateFirstNameError] = useState("");
  const [updateAliasName, setUpdateAliasName] = useState("");
  const [updateLastNameError, setUpdateLastNameError] = useState("");
  const [updateWebsiteError, setUpdateWebsiteError] = useState("");
  const [updateZipCodeError, setUpdateZipCodeError] = useState("");
  const [updateFaxError, setUpdateFaxError] = useState("");
  const [updateCountryError, setUpdateCountryError] = useState("");
  const [updateStateError, setUpdateStateError] = useState("");
  const [updateCityError, setUpdateCityError] = useState("");

  const formattedDate = twelveYearsAgo.toISOString().split("T")[0];

  const handleChangeProfile = (e) => {
    const { name, value } = e.target;
    if (value.length > 0) {
      setIsFirstCharacterEntered(true);
    } else {
      setIsFirstCharacterEntered(false);
    }
    setAddNewProfileMember((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleChangeProfileName = (e) => {
    const { name, value } = e.target;
    if (value.length > 0) {
      setIsFirstCharacterEntered(true);
    } else {
      setIsFirstCharacterEntered(false);
    }
    setRenameProfile((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleChangeRoleDetails = (e) => {
    const { name, value } = e.target;
    if (value.length > 0) {
      setIsFirstCharacterEntered(true);
    } else {
      setIsFirstCharacterEntered(false);
    }
    setAddNewRoleMember((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleChangeEditRoleDetails = (e) => {
    const { name, value } = e.target;
    if (value.length > 0) {
      setIsFirstCharacterEntered(true);
    } else {
      setIsFirstCharacterEntered(false);
    }
    setEditRoleMember((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleChangeUpdate = (e) => {
    const { name, value } = e.target;
    if (name === "mobileNumber" || name === "phoneNumber") {
      const regex = /^\d{0,10}$/;
      if (!regex.test(value)) {
        return;
      }
    }
    if (name === "fax") {
      const regex = /^\d{0,12}$/;
      if (!regex.test(value)) {
        return;
      }
    }

    if (name === "website") {
      const regex = /^[^\s]*$/;
      if (!regex.test(value)) {
        return;
      }
    }

    if (value.length > 0) {
      setIsFirstCharacterEntered(true);
    } else {
      setIsFirstCharacterEntered(false);
    }
    setUserInfoUpdate((prevFields) => ({
      ...prevFields,
      [name]: value,
    }));
  };

  const handleOptionChangeDelete = (event) => {
    setSelectedOptionDelete(event.target.value);
    setSelectedUserList("");
  };

  const handleRowMouseEnter = (index) => {
    setHoveredRow(index);
    if (selectedRow !== index) {
      setShowGroupItems(false);
      setSelectedRow(null);
    }
  };

  const handleRowMouseEnterProfile = (index) => {
    setHoveredProfile(index);
    if (selectedProfile !== index) {
      setShowProfileItems(false);
      setSelectedProfile(null);
    }
  };

  const handleCheckboxChangeSelectedUsers = (user) => {
    setSelectedUsers((prevSelected) => {
      const newSelected = { ...prevSelected };
      if (newSelected[user.grpMembrId]) {
        delete newSelected[user.grpMembrId];
      } else {
        newSelected[user.grpMembrId] = true;
      }
      return newSelected;
    });
  };

  const handleCustomRangeChange = () => {
    const today = new Date();
    const yesterday = new Date(today);
    yesterday.setDate(today.getDate() - 1);

    const formatDate = (date) => {
      const yyyy = date.getFullYear();
      const mm = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-indexed.
      const dd = String(date.getDate()).padStart(2, "0");
      return `${yyyy}-${mm}-${dd}`;
    };
    setFromDate(formatDate(yesterday));
    setToDate(formatDate(today));
    setSpecificDate(formatDate(today));
  };

  const handleSpecificRangeChange = () => {
    const today = new Date();
    const formatDate = (date) => {
      const yyyy = date.getFullYear();
      const mm = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-indexed.
      const dd = String(date.getDate()).padStart(2, "0");
      return `${yyyy}-${mm}-${dd}`;
    };
    setSpecificDate(formatDate(today));
  };

  const handleCheckboxChangeSelectedRoles = (user) => {
    setSelectedRoles((prevSelected) => {
      const newSelected = { ...prevSelected };
      if (newSelected[user.grpRoleId]) {
        delete newSelected[user.grpRoleId];
      } else {
        newSelected[user.grpRoleId] = true;
      }
      return newSelected;
    });
  };
  const collectAllKeys = (node, allKeys = []) => {
    allKeys.push(node);
    if (node.subRoles && node.subRoles.length > 0) {
      node.subRoles.forEach((child) => collectAllKeys(child, allKeys));
    }
    return allKeys;
  };

  React.useEffect(() => {
    if (storeRoleNameDeleted && storeRoleNameDeleted?.roleId) {
      const highlightedKeys = [];
      const findRoleAndHighlight = (nodes) => {
        for (const node of nodes) {
          if (node.roleId === storeRoleNameDeleted.roleId) {
            highlightedKeys.push(...collectAllKeys(node));
            break;
          } else if (node.subRoles && node.subRoles.length > 0) {
            findRoleAndHighlight(node.subRoles);
          }
        }
      };
      findRoleAndHighlight(roles);
      setHighlightedRoles(highlightedKeys);
    } else {
      setHighlightedRoles([]);
    }
  }, [storeRoleNameDeleted, roles]);

  const handleCheckboxChangeSelectedGroups = (user) => {
    setSelectedGroups((prevSelected) => {
      const newSelected = { ...prevSelected };
      if (newSelected[user.memberGroupId]) {
        delete newSelected[user.memberGroupId];
      } else {
        newSelected[user.memberGroupId] = true;
      }
      return newSelected;
    });
  };

  const handleKeyDown = (e) => {
    // Prevent typing space as the first character
    if (!isFirstCharacterEntered && e.key === " ") {
      e.preventDefault();
    }
  };

  // const handleRowMouseLeave = () => {
  //   setHoveredRow(null);
  //   setShowGroupItems(false);
  //   setDeleteGroup(true)
  // };
  const handleIconClick = (index) => {
    setSelectedRow(index);
    setShowGroupItems(true);
  };

  const handleIconClickProfile = (index) => {
    setSelectedProfile(index);
    setShowProfileItems(true);
  };

  const handleCloseGroupItems = () => {
    setShowGroupItems(false);
    setSelectedRow(null);
  };
  const [addGroup, setAddGroup] = useState({
    groupName: "",
    groupDescription: "",
  });

  const [renameGroup, setRenameGroup] = useState({
    groupName: "",
    groupDescription: "",
  });

  const [renameProfile, setRenameProfile] = useState({
    profileName: "",
    profileDescription: "",
  });
  const [groupNameErrorMsg, setGroupNameErrorMsg] = useState("");
  const [showAccessModal, setShowAccessModal] = useState(false);
  const [getAllMembers, setAllMembers] = useState([]);
  const [inputValue, setInputValue] = useState("");

  const handleChangeAddGroup = (e) => {
    const { name, value } = e.target;
    setInputValue(value);

    // Check if first character has been entered
    if (value.trim().length > 0) {
      setIsFirstCharacterEntered(true);
    } else {
      setIsFirstCharacterEntered(false);
    }

    if (editRename) {
      setRenameGroup((prevLeadInfo) => ({
        ...prevLeadInfo,
        [name]: value,
      }));
    } else {
      setAddGroup((prevDealsInfo) => ({
        ...prevDealsInfo,
        [name]: value,
      }));
    }
  };

  const isButtonDisabled = inputValue.length <= 1;

  const { userInfo } = profileInfo;

  const profileText =
    userInfo?.userProfile?.toLowerCase() === "super admin"
      ? "Super Admin"
      : userInfo?.userProfile;
  const profileClass =
    userInfo?.userProfile?.toLowerCase() === "super admin"
      ? "danger_gradient"
      : "profile_gradient";

  const [checkedItems, setCheckedItems] = useState({});
  const [checkedItemsUser, setCheckedItemsUser] = useState({});
  const [checkedItemsRoles, setCheckedItemsRoles] = useState({});
  const [checkedItemsGroups, setCheckedItemsGroups] = useState({});

  const [selectedUsers, setSelectedUsers] = useState({});
  const [selectedRoles, setSelectedRoles] = useState({});
  const [selectedGroups, setSelectedGroups] = useState({});
  const [dataPageView, setDataPageView] = useState("dataStorage");
  const [storageData, setStorageData] = useState({});
  const [storageFileData, setStorageFileData] = useState({});
  const [storageFileDataPopup, setStorageFileDataPopup] = useState([]);
  const [apiLoader, setApiLoader] = useState(false);
  const [apiLoaderActivate,setApiLoaderActivate]=useState(false)
  const [mandatoryOldPassword, setMandatoryOldPassword] = useState("");
  const [mandatoryNewPassword, setMandatoryNewPassword] = useState("");
  const [mandatoryOTP, setMandatoryOTP] = useState("");
  const [disabled, setDisabled] = useState(false);
  const [timer, setTimer] = useState(60);
  const [textDisable, setTextDisable] = useState(false);

  const reduxStore = useSelector((state) => state.reduxState);
  const { loginUserId, loginUserInfo } = reduxStore;

  const handleCheckboxChange = (item) => {
    setCheckedItems((prevCheckedItems) => {
      const newCheckedItems = {
        ...prevCheckedItems,
        [item.moduleId]: !prevCheckedItems[item.moduleId],
      };
      return newCheckedItems;
    });
  };

  const handleCheckboxChangeUser = (item) => {
    setCheckedItemsUser((prevCheckedItems) => {
      const newCheckedItems = {
        ...prevCheckedItems,
        [item.userId]: !prevCheckedItems[item.userId],
      };
      return newCheckedItems;
    });
  };

  const handleCheckboxChangeRole = (item) => {
    setCheckedItemsRoles((prevCheckedItems) => {
      const newCheckedItems = {
        ...prevCheckedItems,
        [item.roleId]: !prevCheckedItems[item.roleId],
      };
      return newCheckedItems;
    });
  };

  const handleCheckboxChangeGroup = (item) => {
    setCheckedItemsGroups((prevCheckedItems) => {
      const newCheckedItems = {
        ...prevCheckedItems,
        [item.groupId]: !prevCheckedItems[item.groupId],
      };
      return newCheckedItems;
    });
  };

  const handleButtonClickUser = () => {
    const isAnyUserChecked = Object.values(checkedItemsUser).some(
      (value) => value
    );
    const isAnyRoleChecked = Object.values(checkedItemsRoles).some(
      (value) => value
    );
    const isAnyGroupChecked = Object.values(checkedItemsGroups).some(
      (value) => value
    );
    if (isAnyUserChecked || isAnyRoleChecked || isAnyGroupChecked) {
      addUserMember();
    } else {
      alert("No items are checked");
    }
  };

  const handleButtonClickMembers = () => {
    const isAnyUserChecked = Object.values(checkedItemsUser).some(
      (value) => value
    );
    const isAnyRoleChecked = Object.values(checkedItemsRoles).some(
      (value) => value
    );
    const isAnyGroupChecked = Object.values(checkedItemsGroups).some(
      (value) => value
    );
    if (isAnyUserChecked || isAnyRoleChecked || isAnyGroupChecked) {
      addUserMemberList();
    } else {
      alert("No items are checked");
    }
  };

  const handleProfileClick = (e) => {
    if (!cloneProfile) {
      e.stopPropagation();
      setProfileListVisible(!profileListVisible);
    }
  };

  const handleUserClick = (e) => {
    e.stopPropagation();
    setUserListVisible(!userListVisible);
  };

  const handleRoleClick = () => {
    setRolesModal(true);
    setShowAddUserModal(false);
  };

  const handleSelectActivity = (activity) => {
    setSelectActivity(activity);
    if (activity !== "Specific Date") {
      setSpecificDate("");
    }
    if (activity !== "Custom Range") {
      setFromDate("");
      setToDate("");
    }
    setErrorMessage(""); // Reset the error message when activity changes
  };

  const handleProfileItemClick = (item) => {
    setProfileListVisible(false);
    setSelectedProfileItem(item);
  };

  const handleUserListItemClick = (item) => {
    setUserListVisible(false);
    setSelectedUserList(item);
  };

  const handleSelectMember = (item) => {
    setSelectMembers(item);
    let value = "";
    if (item === "groups") {
      value = "Groups";
    } else if (item === "roles") {
      value = "Roles";
    } else if (item === "users") {
      value = "Users";
    } else {
      value = item;
    }
    setSelectedMemberName(value);
  };
  const handleSelectGroupStatus = (item) => {
    setSelectedGroupStatus(item);
    let value = "";
    if (item === "Active") {
      value = "Active User";
    } else if (item === "Inactive") {
      value = "Deactivate User";
    } else if (item === "deleted") {
      value = "Deleted User";
    } else {
      value = item;
    }
    setSelectedStatusName(value);
  };

  const handleSelect = (item) => {
    setSelectedItem(item);
    let value = "";
    if (item === "Active") {
      value = "Active Users";
    } else if (item === "Inactive") {
      value = "Deactivated Users";
    } else if (item === "unconfirmed") {
      value = "Unconfirmed Users";
    }
    // else if (item === "confirmed") {
    //   value = "Confirmed User";
    // }
    else if (item === "deleted") {
      value = "Deleted Users";
    } else {
      value = item;
    }
    setDisplayValue(value);
  };

  const handleSelectDropdownActions = (event) => {
    setSelectDropdownAllActions(event);
  };

  const resetAuditDropdown = () => {
    setSelectDropdownAllActions("All Actions");
    setSelectDropdownAllUsers("All Users");
    setSelectDropdownTime("Any time");
  };

  const handleSelectDropdownExportActions = (label, value) => {
    setDropdownExportActions(label);
    setDropdownExportActionsValue(value);
  };

  const handleSelectEportDropdownTime = (label, value) => {
    if (label !== "Date Range") {
      setFromDate("");
      setToDate("");
      setShowDateRange(false);
    } else {
      setShowDateRange(true);
    }
    setDropdownExportTime(label);
    setDropdownExportTimeValue(value);
  };

  const handleSelectDropdownTime = (event) => {
    if (event !== "Date Range") {
      setFromDate("");
      setToDate("");
      setShowDateRange(false);
    } else {
      setShowDateRange(true);
    }
    setSelectDropdownTime(event);
  };

  const handleSelectDropdownUsers = (event) => {
    setSelectDropdownAllUsers(event);
  };

  const handleSelectDropdownExportUsers = (name, value) => {
    setSelectDropdownExportAllUsers(name);
    setDropdownExportAllUsersValue(value);
  };

  const visibleUsers = initialUser.filter((user) =>
    user.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const getUserList = async () => {
    try {
      const url = search
        ? `${URI.getUserList}/${loginUserId}/${selectedItem}/${search}`
        : `${URI.getUserList}/${loginUserId}/${selectedItem}`;

      const headersResult = getHeaders(true);
      if (headersResult.error) {
        navigate("../");
        return;
      }
      const response = await axios.get(url, {
        headers: headersResult,
      });
      if (response.data.status === 200) {
        const userList = response.data.data.getUserList;
        sessionStorage.setItem("settingUserList", JSON.stringify(userList));
        setInitialUser(userList);
        const user = userList.find((user) => user.userId === loginUserId);
        if (user) {
          getUserProfileInfo(user.userId);
          setActiveIndex(user);
        } else if (userList.length > 0) {
          getUserProfileInfo(userList[0].userId);
          setActiveIndex(userList[0]);
        } else {
          setActiveIndex(null);
        }
      }
    } catch (error) {
      handleApiError(error, navigate);
    }
  };

  const getUserListActivate = async () => {
    try {
      const url = `${URI.getUserList}/${loginUserId}/Active_Inactive`;
      const headersResult = getHeaders(true);
      if (headersResult.error) {
        navigate("../");
        return;
      }
      const response = await axios.get(url, {
        headers: headersResult,
      });
      if (response.data.status === 200) {
        sessionStorage.setItem(
          "settingUserList",
          JSON.stringify(response.data.data.getUserList)
        );
        // setInitialAllUser([]);
        setInitialAllUser(response.data.data.getUserList);
      }
    } catch (error) {
      handleApiError(error, navigate);
    }
  };

  const getAllRecycleBinHistory = async () => {
    const headersResult = getHeaders(true);
    if (headersResult.error) {
      navigate("../");
      return;
    }
    await axios
      .get(URI.getRecyclebinHistory + "/" + loginUserId, {
        headers: headersResult,
      })

      .then((response) => {
        if (response.data.status === 200) {
          setRecycleBinHistoryData(response.data.data);
        }
      })
      .catch(async (error) => {
        handleApiError(error, navigate);
      });
  };

  const getAllExportDataDetails = async () => {
    const formattedFromDate = fromDate
      ? moment(fromDate).format("DD-MM-YYYY")
      : "";
    const formattedToDate = toDate ? moment(toDate).format("DD-MM-YYYY") : "";

    const requestBody = {
      loggedInUserId: loginUserId,
      userId: userInfo?.userProfile==="Standard"? loginUserId:dropdownExportAllUsersValue,
      moduleName: dropdownExportActionsValue,
      choosingType: dropdownExportTimeValue,
      fromDate: formattedFromDate,
      toDate: formattedToDate,
      pageNo: currentPageExport,
      pageSize: perPageExport,
    };
    const headersResult = getHeaders(true);
    if (headersResult.error) {
      navigate("../");
      return;
    }
    await axios
      .post(URI.getAllExportData, requestBody, {
        headers: headersResult,
      })

      .then((response) => {
        if (response.data.status === 200) {
          setAllExportData(response.data.data.dataExportHistory);
          setTotalItemsExport(response.data.data.rowCount);
        }
      })
      .catch(async (error) => {
        handleApiError(error, navigate);
      });
  };

  const handleChangePassword = async (flagValue,type) => {
    setTextDisable(true);
    let requestBody = {};
    let isValid = true;

    const setErrorMessage = (setter, message, timeout = 5000) => {
      setter(message);
      setTimeout(() => {
        setter("");
      }, timeout);
      isValid = false;
    };

    if (oldPassword === "") {
      setErrorMessage(
        setMandatoryOldPassword,
        "Please enter your old password",
        5000
      );
    }

    if (newPassword.length === 0) {
      setMandatoryNewPassword("New password cannot be empty.");
      setTimeout(() => {
        setMandatoryNewPassword("");
      }, 3000);
      isValid = false;
    } else if (
      /^(?=.*[a-z])(?=.*[0-9])(?=.*[A-Z])(?=.*[!@#$%^&*.])[a-zA-Z0-9!@#$%^&*.]{8,40}$/.test(
        newPassword
      ) === false
    ) {
      setMandatoryNewPassword(
        "Weak password"
      );
      setTimeout(() => {
        setMandatoryNewPassword("");
      }, 5000);
      isValid = false;
    }

    if (flagValue === 2 && otpVerification === "") {
      setErrorMessage(setMandatoryOTP, "Please Enter a OTP", 5000);
      setTextDisable(false)
    }

    if (!isValid) {
      return;
    }

    requestBody = {
      loggedInUserId: loginUserId,
      oldCredential: oldPassword,
      newCredential: newPassword,
    };

    if (flagValue === 1) {
      requestBody = {
        ...requestBody,
        flagValue: 1,
      };
    } else if (flagValue === 2) {
      requestBody = {
        ...requestBody,
        flagValue: 2,
        otp: otpVerification,
      };
    }
    if(type!=="resend"){
      setApiLoader(true);
    }
   
    const headersResult = getHeaders(true);
    if (headersResult.error) {
      navigate("../");
      return;
    }
    await axios
      .post(URI.changePassword, requestBody, {
        headers: headersResult,
      })

      .then((response) => {
        if (response.data.status === 200) {
          setTextDisable(false);
          setApiLoader(false);
          if (flagValue === 1) {
            setChangePasswordTab("otp");
            NotificationManager.success("", response.data.message, 5000);
            // setFlagValue(2);
            setDisabled(true);
            setTimer(60);
          } else if (flagValue === 2) {
            setChangePasswordTab("password");
            setOtpVerification("");
            setNewPassword("");
            setOldPassword("");
          
            NotificationManager.success("", response.data.message, 5000);
          }
        } else {
          setTextDisable(false);
          setApiLoader(false);
          NotificationManager.error("", response.data.message, 5000);
        }
      })
      .catch(async (error) => {
        handleApiError(error, navigate);
        setApiLoader(false);
      });
  };

  const downloadExportExcel = async (exportId) => {
    try {
      const response = await axios.get(
        URI.downloadExcel + "/" + loginUserId + "/" + exportId,
        {
          responseType: "blob",
        },
        {
          headers: getHeaders(true),
        }
      );

      // Create a link element
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;

      // Extract file name from the response headers if available
      const contentDisposition = response.headers["content-disposition"];
      let fileName = `Export_${exportId}.xlsx`;
      if (contentDisposition) {
        const fileNameMatch = contentDisposition.match(/filename="(.+)"/);
        if (fileNameMatch.length === 2) {
          fileName = fileNameMatch[1];
        }
      }

      // Set the download attribute with the file name
      link.setAttribute("download", fileName);

      // Append the link element to the document and trigger the download
      document.body.appendChild(link);
      link.click();

      // Clean up by removing the link element
      document.body.removeChild(link);
    } catch (error) {
      handleApiError(error, navigate);
    }
  };

  const getProfilePermission = async (profileId) => {
    const headersResult = getHeaders(true);
    if (headersResult.error) {
      navigate("../");
      return;
    }
    await axios
      .get(URI.getProfilePermission + "/" + loginUserId + "/" + profileId, {
        headers: headersResult,
      })

      .then((response) => {
        if (response.data.status === 200) {
          setPermissionData(response.data.data);
        }
      })
      .catch(async (error) => {
        handleApiError(error, navigate);
      });
  };

  const moduleIdMap = {
    Leads: 1,
    Contacts: 2,
    Accounts: 3,
    Deals: 4,
    Tasks: 5,
    Meetings: 6,
    Calls: 7,
    Reports: 8,
    Campaigns: 9,
    Attachments: 10,
    Documents: 11,
    Notes: 12,
    "Purchase orders": 13,
    "Sales orders": 14,
    Products: 15,
    Quotes: 16,
    Invoices: 17,
    Vendors: 18,
    Analytics: 19,
  };

  const getModuleId = (key) => {
    const normalizedKey = key.toLowerCase().replace(/\s/g, "");
    const normalizedMap = Object.fromEntries(
      Object.entries(moduleIdMap).map(([k, v]) => [
        k.toLowerCase().replace(/\s/g, ""),
        v,
      ])
    );
    return normalizedMap[normalizedKey];
  };

  const updateProfilePermissions = async (event, action, moduleName) => {
    const isChecked = event.target.checked;
    const permissions = permissionData.getProfilePermissionDetail[moduleName];

    let isView =
      permissions.find((act) => act.action === "VIEW").status === "TRUE"
        ? 1
        : 2;
    let isCreate =
      permissions.find((act) => act.action === "CREATE").status === "TRUE"
        ? 1
        : 2;
    let isEdit =
      permissions.find((act) => act.action === "EDIT").status === "TRUE"
        ? 1
        : 2;
    let isDelete =
      permissions.find((act) => act.action === "DELETE").status === "TRUE"
        ? 1
        : 2;
    let isActive = 1;

    // eslint-disable-next-line default-case
    switch (action.action) {
      case "EDIT":
        isEdit = isChecked ? 1 : 2;
        break;
      case "DELETE":
        isDelete = isChecked ? 1 : 2;
        break;
      case "VIEW":
        isView = isChecked ? 1 : 2;
        if (isView === 2) {
          isActive = 0;
          isCreate = 2;
          isEdit = 2;
          isDelete = 2;
        } else {
          isActive = 1;
          isCreate = 1;
          isEdit = 1;
          isDelete = 1;
        }
        setSelectedAction(null);
        break;
      case "CREATE":
        isCreate = isChecked ? 1 : 2;
        break;
    }

    const requestBody = {
      userId: loginUserId,
      profileId: profileId,
      isActive: isActive,
      isView: isView,
      isEdit: isEdit,
      isCreate: isCreate,
      isDelete: isDelete,
      moduleId: getModuleId(moduleName),
    };

    try {
      const headersResult = getHeaders(true);
      if (headersResult.error) {
        navigate("../");
        return;
      }
      const response = await axios.post(
        URI.updateProfilePermission,
        requestBody,
        {
          headers: headersResult,
        }
      );
      if (response.data.status === 200) {
        getProfilePermission(profileId);
      }
    } catch (error) {
      handleApiError(error, navigate);
    }
  };

  const deleteParticularUser = async () => {
    let requestBody;
    if (selectedOptionDelete === "delete") {
      requestBody = {
        loggedInUserId: loginUserId,
        userId: userInfo.userId,
        deleteOption: "DELETE_WITHOUT_TRANSFER",
      };
    } else {
      requestBody = {
        loggedInUserId: loginUserId,
        userId: userInfo.userId,
        transferUserId: selectedUserList.userId,
        deleteOption: "TRANSFER_NOW",
      };
    }
    const headersResult = getHeaders(true);
    if (headersResult.error) {
      navigate("../");
      return;
    }
    await axios
      .post(URI.deleteUser, requestBody, {
        headers: headersResult,
      })
      .then((response) => {
        if (response.data.status === 200) {
          NotificationManager.success("", response.data.message, 5000);
          setDeleteUserModal(false);
          getUserList();
        } else {
          NotificationManager.error("", response.data.message, 5000);
        }
      })
      .catch(async (error) => {
        handleApiError(error, navigate);
      });
  };

  const convertToSnakeCase = (input) => {
    if (!input) return ""; // Handle empty or null input

    return input
      .toLowerCase() // Convert to lowercase
      .replace(/\s+/g, "_") // Replace spaces with underscores
      .replace(/[^\w_]/g, ""); // Optionally remove non-alphanumeric characters except underscores
  };

  const getAuditLogHistory = async () => {
    const formattedFromDate = fromDate
      ? moment(fromDate).format("DD-MM-YYYY")
      : "";
    const formattedToDate = toDate ? moment(toDate).format("DD-MM-YYYY") : "";

    const requestBody = {
      loggedInUserId: loginUserId,
      users:
        dropdownAllUsers === "All Users"
          ? convertToSnakeCase(dropdownAllUsers)
          : dropdownAllUsers,
      actionType: convertToSnakeCase(dropdownAllActions),
      actionTime: convertToSnakeCase(dropdownTime),
      fromDate: formattedFromDate,
      toDate: formattedToDate,
      pageNo: currentPageAudit,
      pageSize: perPageAudit,
    };
    const headersResult = getHeaders(true);
    if (headersResult.error) {
      navigate("../");
      return;
    }
    await axios
      .post(URI.getAuditLog, requestBody, {
        headers: headersResult,
      })

      .then((response) => {
        if (response.data.status === 200) {
          setAuditLogData(response.data.data.auditLogDetails);
          setTotalItemsAudit(response.data.data.rowCount);
        }
      })
      .catch(async (error) => {
        handleApiError(error, navigate);
      });
  };

  const removeDataBackupDetails = async () => {
    const requestBody = {
      loggedInUserId: loginUserId,
    };
    const headersResult = getHeaders(true);
    if (headersResult.error) {
      navigate("../");
      return;
    }
    await axios
      .post(URI.removedataBackup, requestBody, {
        headers: headersResult,
      })

      .then((response) => {
        if (response.data.status === 200) {
          NotificationManager.success("", response.data.message, 5000);
          getSampleDataDetails();
        } else {
          NotificationManager.error("", response.data.message, 5000);
        }
      })
      .catch(async (error) => {
        handleApiError(error, navigate);
      });
  };

  const getSampleDataDetails = async () => {
    const headersResult = getHeaders(true);
    if (headersResult.error) {
      navigate("../");
      return;
    }
    await axios
      .get(URI.getSampleData + "/" + loginUserId, {
        headers: headersResult,
      })

      .then((response) => {
        if (response.data.status === 200) {
          setSampleData(response.data.data);
        }
      })
      .catch(async (error) => {
        handleApiError(error, navigate);
      });
  };

  const getAuditLogExportDetails = async () => {
    const formattedFromDate = fromDate
      ? moment(fromDate).format("DD-MM-YYYY")
      : "";
    const formattedToDate = toDate ? moment(toDate).format("DD-MM-YYYY") : "";

    const requestBody = {
      loggedInUserId: loginUserId,
      users:
        dropdownAllUsers === "All Users"
          ? convertToSnakeCase(dropdownAllUsers)
          : dropdownAllUsers,
      actionType: convertToSnakeCase(dropdownAllActions),
      actionTime: convertToSnakeCase(dropdownTime),
      fromDate: formattedFromDate,
      toDate: formattedToDate,
    };
    const headersResult = getHeaders(true);
    if (headersResult.error) {
      navigate("../");
      return;
    }
    await axios
      .post(URI.getAuditLogExportDetails, requestBody, {
        headers: headersResult,
      })

      .then((response) => {
        if (response.data.status === 200) {
          setStoreExportId(response.data.data.fileName);
          setShowExportAuditLog(true);
        }
      })
      .catch(async (error) => {
        handleApiError(error, navigate);
      });
  };

  const downloadAuditLogDetails = async () => {
    try {
      const response = await axios.get(
        URI.downloadAuditLog + "/" + loginUserId + "/" + storeExportId,
        {
          responseType: "blob",
        },
        {
          headers: getHeaders(true),
        }
      );
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;

      const contentDisposition = response.headers["content-disposition"];
      let fileName = `AuditLog_${storeExportId}.xlsx`;
      if (contentDisposition) {
        const fileNameMatch = contentDisposition.match(/filename="(.+)"/);
        if (fileNameMatch.length === 2) {
          fileName = fileNameMatch[1];
        }
      }
      link.setAttribute("download", fileName);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      setShowExportAuditLog(false);
    } catch (error) {
      handleApiError(error, navigate);
    }
  };

  useEffect(() => {
    let interval;
    if (disabled) {
      interval = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);
    }

    return () => clearInterval(interval);
  }, [disabled]);

  useEffect(() => {
    if (timer === 0) {
      setDisabled(false);
    }
  }, [timer]);

  useEffect(() => {
    if (isAuditTabClicked) {
      if (dropdownTime === "Date Range" && (!fromDate || !toDate)) {
        return;
      }
      getAuditLogHistory();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    dropdownAllUsers,
    dropdownAllActions,
    dropdownTime,
    fromDate,
    toDate,
    isAuditTabClicked,
  ]);

  useEffect(() => {
    getAuditLogHistory();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPageAudit, perPageAudit]);

  useEffect(() => {
    getAllExportDataDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPageExport, perPageExport]);

  useEffect(() => {
    if (isExportTabClicked) {
      if (dropdownExportTime === "Date Range" && (!fromDate || !toDate)) {
        return;
      }
      getAllExportDataDetails();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    dropdownExportAllUsers,
    dropdownExportActions,
    dropdownExportTime,
    fromDate,
    toDate,
    isExportTabClicked,
  ]);

  const getUserProfileInfo = async (userId) => {
    const headersResult = getHeaders(true);
    if (headersResult.error) {
      navigate("../");
      return;
    }
    await axios
      .get(URI.getProfileUserInfo + "/" + userId + "/" + loginUserId, {
        headers: headersResult,
      })

      .then((response) => {
        if (response.data.status === 200) {
          setActiveIndex(userId);
          setProfileInfo(response.data.data);
        }
        if (response.data.data.userInfo.userId === loginUserId) {
          dispatch(setLoginUserInfo(response.data.data.userInfo));
        }
      })
      .catch(async (error) => {
        handleApiError(error, navigate);
      });
  };

  const getStorageData = async () => {
    const headersResult = getHeaders(true);
    if (headersResult.error) {
      navigate("../");
      return;
    }
    await axios
      .get(URI.getStorageData + "/" + loginUserId, {
        headers: headersResult,
      })

      .then((response) => {
        if (response.data.status === 200) {
          setStorageData(response.data.data);
        }
      })
      .catch(async (error) => {
        handleApiError(error, navigate);
      });
  };

  const getStorageFileDataPopup = async (selectedStorageModule) => {
    const headersResult = getHeaders(true);
    if (headersResult.error) {
      navigate("../");
      return;
    }
    await axios
      .get(
        URI.getStorageFileDataPopup +
          "/" +
          loginUserId +
          "/" +
          selectedStorageModule,
        {
          headers: headersResult,
        }
      )

      .then((response) => {
        if (response.data.status === 200) {
          setStorageFileDataPopup(response.data.data);
        }
      })
      .catch(async (error) => {
        handleApiError(error, navigate);
      });
  };

  const getStorageFileData = async () => {
    const headersResult = getHeaders(true);
    if (headersResult.error) {
      navigate("../");
      return;
    }
    await axios
      .get(URI.getStorageFileData + "/" + loginUserId, {
        headers: headersResult,
      })

      .then((response) => {
        if (response.data.status === 200) {
          setStorageFileData(response.data.data);
        }
      })
      .catch(async (error) => {
        handleApiError(error, navigate);
      });
  };

  const emptyAllRecycleBin = async () => {
    const headersResult = getHeaders(true);
    if (headersResult.error) {
      navigate("../");
      return;
    }
    setApiLoader(true);
    await axios
      .get(URI.emptyRecycle + "/" + loginUserId, {
        headers: headersResult,
      })

      .then((response) => {
        if (response.data.status === 200) {
          NotificationManager.success("", response.data.message, 5000);
          getAllRecycleBinHistory();
        } else {
          NotificationManager.error("", response.data.message, 5000);
        }
      })
      .catch(async (error) => {
        handleApiError(error, navigate);
      })
      .finally(() => {
        setApiLoader(false);
        setRecycleModalShow(false);
      });
  };

  const getGroupListDetails = async () => {
    const headersResult = getHeaders(true);
    if (headersResult.error) {
      navigate("../");
      return;
    }
    await axios
      .get(URI.getGroupList + "/" + loginUserId, {
        headers: headersResult,
      })

      .then((response) => {
        if (response.data.status === 200) {
          setGroupListData(response.data.data);
        } else {
          throw new Error("error");
        }
      })
      .catch(async (error) => {
        handleApiError(error, navigate);
      });
  };

  const deleteRestoreData = async (actionType) => {
    const selectedItems = recycleBinHistoryData.filter(
      (item) => checkedItems[item.moduleId]
    );
    const data = [];
    for (const item of selectedItems) {
      const requestBody = {
        loggedInUserId: loginUserId,
        moduleId: item.moduleId,
        type: item.type,
        action: actionType,
      };
      data.push(requestBody);
    }
    const headersResult = getHeaders(true);
    if (headersResult.error) {
      navigate("../");
      return;
    }
    setApiLoader(true);
    await axios
      .post(URI.deleteRestore, data, {
        headers: headersResult,
      })
      .then((response) => {
        if (response.data.status === 200) {
          NotificationManager.success("", response.data.message, 5000);
          getAllRecycleBinHistory();
        } else {
          NotificationManager.error("", response.data.message, 5000);
        }
      })
      .catch((err) => {
        handleApiError(err, navigate);
      })
      .finally(() => {
        setApiLoader(false);
        setCheckedItems({});
        setDeleteConfirmationModal(false);
      });
  };

  const addUserMember = async () => {
    const selectedItems = initialAllUser.filter(
      (item) => checkedItemsUser[item.userId]
    );

    const selectedItems2 = rolesList.filter(
      (item) => checkedItemsRoles[item.roleId]
    );

    const selectedItems3 = groupListData.filter(
      (item) => checkedItemsGroups[item.groupId]
    );
    const groupMemberIdDto = selectedItems.map((item) => ({
      userId: item.userId,
    }));

    const groupRoleIdDto = selectedItems2.map((item) => ({
      roleMemberId: item.roleId,
    }));

    const groupGroupIdDto = selectedItems3.map((item) => ({
      groupMemberId: item.groupId,
    }));

    const requestBody = {
      groupName: addGroup.groupName,
      description: addGroup.groupDescription,
      loggedInUserId: loginUserId,
      groupMemberIdDto: groupMemberIdDto,
      groupGroupIdDto: groupGroupIdDto,
      groupRoleIdDto: groupRoleIdDto,
    };

    try {
      const headersResult = getHeaders(true);
      if (headersResult.error) {
        navigate("../");
        return;
      }
      const response = await axios.post(URI.addUserMember, requestBody, {
        headers: headersResult,
      });
      if (response.data.status === 200) {
        setShowAddMemberModal(false);
        getGroupListDetails();
        setShowGroup("group");
        setCheckedItemsUser({});
        setCheckedItemsGroups({});
        setCheckedItemsRoles({});
        setAddGroup({ groupName: "", groupDescription: "" });
        setSelectMembers("users");
        NotificationManager.success("", response.data.message, 5000);
      } else {
        NotificationManager.error("", response.data.message, 5000);
      }
    } catch (err) {
      handleApiError(err, navigate);
    }
  };

  const handleSearchGroupChange = (e) => {
    const { value } = e.target;
    if (value.length === 0 || value.charAt(0) !== " ") {
      setSearchGroup(value);
    }
  };

  const addUserMemberList = async () => {
    const selectedItems = groupMembersList.filter(
      (item) => checkedItemsUser[item.userId]
    );

    const selectedItems2 = groupMembersList.filter(
      (item) => checkedItemsRoles[item.roleId]
    );

    const selectedItems3 = groupMembersList.filter(
      (item) => checkedItemsGroups[item.groupId]
    );
    const groupMemberIdDto = selectedItems.map((item) => ({
      userId: item.userId,
    }));

    const groupRoleIdDto = selectedItems2.map((item) => ({
      roleMemberId: item.roleId,
    }));

    const groupGroupIdDto = selectedItems3.map((item) => ({
      groupMemberId: item.groupId,
    }));

    const requestBody = {
      groupId: groupIdIndividual,
      loggedInUserId: loginUserId,
      groupMemberIdDto: groupMemberIdDto,
      groupGroupIdDto: groupGroupIdDto,
      groupRoleIdDto: groupRoleIdDto,
    };

    try {
      const headersResult = getHeaders(true);
      if (headersResult.error) {
        navigate("../");
        return;
      }
      const response = await axios.post(URI.addGroupMemberList, requestBody, {
        headers: headersResult,
      });
      if (response.data.status === 200) {
        setAddNewMember(false);
        getAllMembersList("users", groupIdIndividual);
        setCheckedItemsUser({});
        setCheckedItemsGroups({});
        setCheckedItemsRoles({});
        setSelectMembers("users");
        NotificationManager.success("", response.data.message, 5000);
      } else {
        NotificationManager.error("", response.data.message, 5000);
        setCheckedItemsUser({});
        setCheckedItemsGroups({});
        setCheckedItemsRoles({});
      }
    } catch (err) {
      handleApiError(err, navigate);
    }
  };

  const deleteUsersMember = async () => {
    const selectedItems = getAllMembers.filter(
      (item) => selectedUsers[item.grpMembrId]
    );
    const groupMemberIdDto = selectedItems.map((item) => ({
      groupMemberId: item.grpMembrId,
    }));

    const requestBody = {
      loggedInUserId: loginUserId,
      groupMemberIdDto: groupMemberIdDto,
    };

    try {
      const headersResult = getHeaders(true);
      if (headersResult.error) {
        navigate("../");
        return;
      }
      const response = await axios.post(URI.deleteGroupUsers, requestBody, {
        headers: headersResult,
      });
      if (response.data.status === 200) {
        setShowAddMemberModal(false);
        getAllMembersList("users", groupIdIndividual);
        setSelectedUsers({});
        setSelectMembers("users");
        NotificationManager.success("", response.data.message, 5000);
      } else {
        NotificationManager.error("", response.data.message, 5000);
      }
    } catch (err) {
      handleApiError(err, navigate);
    }
  };

  const deleteRolesMember = async () => {
    const selectedItems = getAllMembers.filter(
      (item) => selectedRoles[item.grpRoleId]
    );
    const groupRoleIdDto = selectedItems.map((item) => ({
      roleMemberId: item.grpRoleId,
    }));

    const requestBody = {
      loggedInUserId: loginUserId,
      groupRoleIdDto: groupRoleIdDto,
    };

    try {
      const headersResult = getHeaders(true);
      if (headersResult.error) {
        navigate("../");
        return;
      }
      const response = await axios.post(URI.deleteGroupRoles, requestBody, {
        headers: headersResult,
      });
      if (response.data.status === 200) {
        setShowAddMemberModal(false);
        getAllMembersList("roles", groupIdIndividual);
        setSelectedRoles({});
        setSelectMembers("users");
        NotificationManager.success("", response.data.message, 5000);
      } else {
        NotificationManager.error("", response.data.message, 5000);
      }
    } catch (err) {
      handleApiError(err, navigate);
    }
  };

  const deleteGroupsMember = async () => {
    const selectedItems = getAllMembers.filter(
      (item) => selectedGroups[item.memberGroupId]
    );
    const groupId = selectedItems.length > 0 ? selectedItems[0].groupId : null;

    const groupGroupIdDto = selectedItems.map((item) => ({
      groupMemberId: item.memberGroupId,
    }));

    const requestBody = {
      loggedInUserId: loginUserId,
      groupGroupIdDto: groupGroupIdDto,
      groupId: groupId,
    };

    try {
      const headersResult = getHeaders(true);
      if (headersResult.error) {
        navigate("../");
        return;
      }
      const response = await axios.post(URI.deleteGroupByGroups, requestBody, {
        headers: headersResult,
      });
      if (response.data.status === 200) {
        setShowAddMemberModal(false);
        getAllMembersList("groups", groupIdIndividual);
        setSelectedGroups({});
        setSelectMembers("users");
        NotificationManager.success("", response.data.message, 5000);
      } else {
        NotificationManager.error("", response.data.message, 5000);
      }
    } catch (err) {
      handleApiError(err, navigate);
    }
  };

  useEffect(() => {
    getUserList(displayValue);
  }, [displayValue, search]);

  useEffect(() => {
    if (groupIdIndividual) {
      getAllMembersList("users", groupIdIndividual, selectedGroupStatus);
    }
  }, [selectedGroupStatus, groupIdIndividual]);

  useEffect(() => {
    getSampleDataDetails();
  }, []);

  const filteredUsers = initialUser.filter((item) =>
    item.name.toLowerCase().includes(search.toLowerCase())
  );

  const filteredGroupName = groupListData.filter((item) =>
    item.groupName?.toLowerCase().includes(searchGroup.toLowerCase())
  );

  const filteredViewUsers = viewUsersData.filter((user) =>
    user.userName?.toLowerCase().includes(searchViewUser.toLowerCase())
  );

  const filteredProfileNames = profileLists.filter((user) =>
    user.profileName?.toLowerCase().includes(searchProfile.toLowerCase())
  );

  const filteredAllMembers = getAllMembers.filter((item) =>
    activeGroupTab === "users"
      ? item.users?.toLowerCase().includes(searchAllMembers?.toLowerCase())
      : activeGroupTab === "roles"
      ? item.roles?.toLowerCase().includes(searchAllMembers?.toLowerCase())
      : activeGroupTab === "groups"
      ? item.memberGroupName
          ?.toLowerCase()
          .includes(searchAllMembers?.toLowerCase())
      : false
  );

  const filteredAllMembersUsers = groupMembersList.filter((item) =>
    selectMembers === "users"
      ? item.users?.toLowerCase().includes(searchMember?.toLowerCase())
      : selectMembers === "roles"
      ? item.roles?.toLowerCase().includes(searchMember?.toLowerCase())
      : selectMembers === "groups"
      ? item.groups?.toLowerCase().includes(searchMember?.toLowerCase())
      : false
  );

  const filteredRoles = rolesList.filter((item) =>
    item.roleName?.toLowerCase().includes(searchUsersRolesGroups.toLowerCase())
  );

  const filteredUsersNew = initialAllUser.filter((item) =>
    item.name?.toLowerCase().includes(searchUsersRolesGroups.toLowerCase())
  );

  const filteredGroups = groupListData.filter((item) =>
    item.groupName?.toLowerCase().includes(searchUsersRolesGroups.toLowerCase())
  );

  const getAllRoles = async () => {
    const requestBody = {
      loggedInUserId: loginUserId,
      pageNo: 1,
      pageSize: 10,
    };
    const headersResult = getHeaders(true);
    if (headersResult.error) {
      navigate("../");
      return;
    }
    await axios
      .post(URI.getRoles, requestBody, {
        headers: headersResult,
      })
      .then((response) => {
        if (response.data.status === 200) {
          setRoles(response.data.data.allRolesData);
        } else {
          alert("error");
        }
      })
      .catch((err) => {
        handleApiError(err, navigate);
      });
  };

  const getAllRolesListDetails = async () => {
    const requestBody = {
      loggedInUserId: loginUserId,
    };
    const headersResult = getHeaders(true);

    if (headersResult.error) {
      navigate("../");
      return;
    }
    await axios
      .post(URI.getAllRoleList, requestBody, {
        headers: headersResult,
      })
      .then((response) => {
        if (response.data.status === 200) {
          const filteredData = response.data.data.roleList.filter(
            (item) => item.level !== 1
          );
          setRolesList(filteredData);
        } else {
          alert("error");
        }
      })
      .catch((err) => {
        handleApiError(err, navigate);
      });
  };

  const resetDropdownData = () => {
    setDropdownExportAllUsersValue("all_users");
    setSelectDropdownExportAllUsers("All Users");
    setDropdownExportActionsValue("all_modules");
    setDropdownExportActions("All Modules");
    setDropdownExportTime("Anytime");
    setDropdownExportTimeValue("anytime");
  };

  const handleFilterExportIconClick = () => {
    setShowExportDropdownFilters(!showExportDropdownFilters);
    setSearchTerm("");
    resetDropdownData();
    setShowDateRange(false);
  };

  const updateUserDetails = async () => {
    const errors = {};
    let hasError = false;
    if (!userInfoUpdate?.firstName) {
      errors.firstName = "First Name cannot be empty";
      hasError = true;
    }

    if (
      userInfoUpdate?.firstName &&
      !validateFirstName(userInfoUpdate?.firstName)
    ) {
      errors.firstName = "Please enter valid First Name";
      hasError = true;
    }
    if (
      userInfoUpdate?.aliasName &&
      !validateAliasName(userInfoUpdate?.aliasName)
    ) {
      errors.aliasName = "Please enter valid Alias Name";
      hasError = true;
    }
    if (
      userInfoUpdate?.lastName &&
      !validateLastName(userInfoUpdate?.lastName)
    ) {
      errors.lastName = "Please enter valid Last Name";
      hasError = true;
    }
    if (
      userInfoUpdate?.website &&
      !websiteValidation(userInfoUpdate?.website)
    ) {
      errors.website = "Please enter valid website";
      hasError = true;
    }
    if (
      userInfoUpdate?.zipCode &&
      !validateZipCode(userInfoUpdate?.zipCode)
    ) {
      errors.zipCode = "Please enter valid ZipCode";
      hasError = true;
    }
    if (
      userInfoUpdate?.fax &&
      !validateFaxNumber(userInfoUpdate?.fax)
    ) {
      errors.fax = "Please enter valid Fax";
      hasError = true;
    }
    if (
      userInfoUpdate?.country &&
      !validateCountryName(userInfoUpdate?.country)
    ) {
      errors.country = "Please enter valid Country";
      hasError = true;
    }

    if (
      userInfoUpdate?.city &&
      !validateCityIndia(userInfoUpdate?.city)
    ) {
      errors.city = "Please enter valid City";
      hasError = true;
    }

    if (
      userInfoUpdate?.state &&
      !validateStateIndia(userInfoUpdate?.state)
    ) {
      errors.state = "Please enter valid State";
      hasError = true;
    }
   

    if (hasError) {
      setUpdateFirstNameError(errors.firstName || "");
      setUpdateAliasName(errors.aliasName || "");
      setUpdateLastNameError(errors.lastName || "");
      setUpdateWebsiteError(errors.website || "");
      setUpdateZipCodeError(errors.zipCode||"");
      setUpdateFaxError(errors.fax||"");
      setUpdateCountryError(errors.country||"")
      setUpdateStateError(errors.state||"");
      setUpdateCityError(errors.city||"")
      setTimeout(() => {
        setUpdateFirstNameError("");
        setUpdateAliasName("");
        setUpdateLastNameError("");
        setUpdateWebsiteError("");
        setUpdateZipCodeError("");
        setUpdateFaxError("");
        setUpdateCountryError("");
        setUpdateStateError("");
        setUpdateCityError("")

      }, 3000);
      return;
    }
    const requestBody = {
      ownerId: userInfo.userId === null ? "" : userInfo.userId,
      loggedInUserId: loginUserId,
      firstName:
        userInfoUpdate?.firstName === null
          ? ""
          : removeExtraSpaces(userInfoUpdate?.firstName),
      lastName:
        userInfoUpdate?.lastName === null
          ? ""
          : removeExtraSpaces(userInfoUpdate?.lastName),
      aliasName:
        userInfoUpdate?.aliasName === null
          ? ""
          : removeExtraSpaces(userInfoUpdate?.aliasName),
      phoneNumber:
        userInfoUpdate?.phoneNumber === null ? "" : userInfoUpdate?.phoneNumber,
      mobile:
        userInfoUpdate?.mobileNumber === null
          ? ""
          : userInfoUpdate?.mobileNumber,
      website:
        userInfoUpdate?.website === null
          ? ""
          : removeExtraSpaces(userInfoUpdate?.website),
      fax: userInfoUpdate?.fax === null ? "" : userInfoUpdate?.fax,
      dob:
        userInfoUpdate?.dateOfBirth === null ? "" : userInfoUpdate?.dateOfBirth,
      street:
        userInfoUpdate?.street === null
          ? ""
          : removeExtraSpaces(userInfoUpdate?.street),
      city:
        userInfoUpdate?.city === null
          ? ""
          : removeExtraSpaces(userInfoUpdate?.city),
      state:
        userInfoUpdate?.state === null
          ? ""
          : removeExtraSpaces(userInfoUpdate?.state),
      zipCode: userInfoUpdate?.zipCode === null ? "" : userInfoUpdate?.zipCode,
      country:
        userInfoUpdate?.country === null
          ? ""
          : removeExtraSpaces(userInfoUpdate?.country),
    };
    const headersResult = getHeaders(true);
    if (headersResult.error) {
      navigate("../");
      return;
    }
    setApiLoader(true);
    await axios
      .post(URI.updateUserDetails, requestBody, {
        headers: headersResult,
      })
      .then((response) => {
        if (response.data.status === 200) {
          NotificationManager.success("", response.data.message, 5000);
          setEditUserModal(false);
          getUserList();
        } else {
          NotificationManager.error("", response.data.message, 5000);
        }
      })
      .catch((err) => {
        handleApiError(err, navigate);
      })
      .finally(() => setApiLoader(false));
  };

  const addNewProfileDetails = async () => {
    const errors = {};
    let hasError = false;

    if (!addNewProfileMember.profileName) {
      errors.profileName = "Profile Name cannot be empty.";
      hasError = true;
    }

    if (!cloneProfile && !selectedProfileItem.profileName) {
      errors.cloneProfile = "Clone Profile cannot be empty.";
      hasError = true;
    }

    if (hasError) {
      setProfileNameError(errors.profileName || "");
      setCloneProfileError(errors.cloneProfile || "");

      setTimeout(() => {
        setProfileNameError("");
        setCloneProfileError("");
      }, 3000);
      return;
    }

    const requestBody = {
      profileName: addNewProfileMember.profileName,
      profileDescription: addNewProfileMember.profileDescription,
      cloneProfile: cloneProfile
        ? profileDetails.profileName
        : selectedProfileItem.profileName,
      createdBy: loginUserId,
    };
    setApiLoader(true);
    try {
      const headersResult = getHeaders(true);
      if (headersResult.error) {
        navigate("../");
        return;
      }
      const response = await axios.post(URI.addNewProfile, requestBody, {
        headers: headersResult,
      });

      if (response.data.status === 200) {
        NotificationManager.success("", response.data.message, 5000);
        getAllProfileDetails();
        setSelectedProfileItem("");
        setAddNewProfileMember({
          profileName: "",
          profileDescription: "",
        });
      } else {
        NotificationManager.error("", response.data.message, 5000);
      }
    } catch (err) {
      handleApiError(err, navigate);
    } finally {
      setApiLoader(false);
      setAddNewProfile(false);
    }
  };

  const getExportDetails = async () => {
    const requestBody = {
      loggedInUserId: loginUserId,
      customFilter: selectedModuleList.value,
      moduleName: selectedModule,
      fields: selectedField.value,
    };
    if (selectedField.value === "choose_fields") {
      requestBody.selectedFields = checkedFields[selectedModule];
    }
    setApiLoader(true);
    try {
      const headersResult = getHeaders(true);
      if (headersResult.error) {
        navigate("../");
        return;
      }
      const response = await axios.post(URI.getExportDetails, requestBody, {
        headers: headersResult,
      });
      if (response.data.status === 200) {
        setInitialExportPage("initial");
        NotificationManager.success("", response.data.message, 5000);
        setSelectedModule("");
        setCheckedFields([]);
        setSelectedField("");
        getAllExportDataDetails();
        setShowExportDropdownFilters(false);
        resetDropdownData();
      } else {
        NotificationManager.error("", response.data.message, 5000);
      }
    } catch (err) {
      handleApiError(err, navigate);
    } finally {
      setApiLoader(false);
    }
  };

  const addNewRoleDetails = async () => {
    const errors = {};
    let hasError = false;

    if (!addNewRoleMember.roleName) {
      errors.roleName = "Role Name cannot be empty.";
      hasError = true;
    }

    if (
      addNewRoleMember.roleName &&
      !validateRoleName(addNewRoleMember.roleName)
    ) {
      errors.roleName = "Please enter a valid Role Name.";
      hasError = true;
    }

    if (!selectedRoleItem) {
      errors.role = "Role must be selected.";
      hasError = true;
    }
    if (hasError) {
      setRoleNameError(errors.roleName || "");
      setRoleError(errors.role || "");

      setTimeout(() => {
        setRoleNameError("");
        setRoleError("");
      }, 3000);

      return;
    }
    setApiLoader(true);
    const requestBody = {
      loggedInUserId: loginUserId,
      roleName: addNewRoleMember.roleName,
      reportsTo: selectedRoleId,
      shareDataWithPeers: shareDataWithPeers === true ? "true" : "false",
      description: addNewRoleMember.description,
    };
    try {
      const headersResult = getHeaders(true);
      if (headersResult.error) {
        navigate("../");
        return;
      }
      const response = await axios.post(URI.addNewRole, requestBody, {
        headers: headersResult,
      });
      if (response.data.status === 200) {
        getAllRoles();
        NotificationManager.success("", response.data.message, 5000);
        setActiveRoleItems("roles");
        setAddNewRoleMember({
          roleName: "",
          description: "",
        });
        setShareDataWithPeers(false);
        setSelectedRoleItem("");
      } else {
        NotificationManager.error("", response.data.message, 5000);
      }
    } catch (err) {
      handleApiError(err, navigate);
    } finally {
      setApiLoader(false);
    }
  };

  const renameProfileDetails = async () => {
    const requestBody = {
      profileId: profileId,
      profileName: renameProfile.profileName,
      profileDescription: renameProfile.profileDescription,
      modifiedBy: loginUserId,
    };
    try {
      const headersResult = getHeaders(true);
      if (headersResult.error) {
        navigate("../");
        return;
      }
      const response = await axios.post(URI.renameProfile, requestBody, {
        headers: headersResult,
      });
      if (response.data.status === 200) {
        NotificationManager.success("", response.data.message, 5000);
        setRenameProfileModal(false);
        getAllProfileDetails();
        setShowProfileItems(false);
      } else {
        NotificationManager.error("", response.data.message, 5000);
      }
    } catch (err) {
      handleApiError(err, navigate);
    }
  };

  const deleteProfileDetails = async (profileId) => {
    const requestBody = {
      loggedInUserId: loginUserId,
      profileId: profileId,
    };
    try {
      const headersResult = getHeaders(true);
      if (headersResult.error) {
        navigate("../");
        return;
      }
      const response = await axios.post(URI.deleteProfile, requestBody, {
        headers: headersResult,
      });
      if (response.data.status === 200) {
        NotificationManager.success("", response.data.message, 5000);
        getAllProfileDetails();
        setShowProfileItems(false);
      } else {
        NotificationManager.error("", response.data.message, 5000);
      }
    } catch (err) {
      handleApiError(err, navigate);
    }
  };

  function handleSetRole(data, roleData) {
    data.map((item) => {
      if (item.roleId === roleData?.reportsTo) {
        setSelectedReportsTo(item);
      }
      if (item.subRoles && item.subRoles.length > 0) {
        handleSetRole(item.subRoles, roleData);
      }
    });
  }

  const viewUsersProfileDetails = async () => {
    const requestBody = {
      profileName: profileDetails.profileName,
      loggedInUserId: loginUserId,
      pageNo: 1,
      pageSize: 10,
    };
    try {
      const headersResult = getHeaders(true);
      if (headersResult.error) {
        navigate("../");
        return;
      }
      const response = await axios.post(URI.viewUsersProfile, requestBody, {
        headers: headersResult,
      });
      if (response.data.status === 200) {
        setViewUsersData(response.data.data.userList);
      } else {
        NotificationManager.error("", response.data.message, 5000);
      }
    } catch (err) {
      handleApiError(err, navigate);
    }
  };

  const deleteRoleDetails = async () => {
    const requestBody = {
      loggedInUserId: loginUserId,
      roleId: storeRoleNameDeleted.roleId,
      roleTransferId: selectTransferId,
    };
    setApiLoader(true);
    try {
      const headersResult = getHeaders(true);
      if (headersResult.error) {
        navigate("../");
        return;
      }
      const response = await axios.post(URI.deleteRoleDetails, requestBody, {
        headers: headersResult,
      });
      if (response.data.status === 200) {
        getAllRoles();
        NotificationManager.success("", response.data.message, 5000);
        setActiveRoleItems("roles");
        setSelectedRoleItem("");
        setStoreRoleNameDeleted("");
      } else {
        NotificationManager.error("", response.data.message, 5000);
      }
    } catch (err) {
      handleApiError(err, navigate);
    } finally {
      setApiLoader(false);
    }
  };

  const editRoleDetails = async () => {
    const errors = {};
    let hasError = false;

    if (!editRoleMember.roleName) {
      errors.roleName = "Role Name cannot be empty.";
      hasError = true;
    }

    if (editRoleMember.roleName && !validateRoleName(editRoleMember.roleName)) {
      errors.roleName = "Please enter a valid Role Name.";
      hasError = true;
    }
    if (hasError) {
      setRoleNameError(errors.roleName || "");

      setTimeout(() => {
        setRoleNameError("");
      }, 3000);

      return;
    }

    const requestBody = {
      loggedInUserId: loginUserId,
      roleName: editRoleMember.roleName,
      reportsTo: selectedReportsTo.roleId,
      shareDataWithPeers:
        editRoleId.shareDataWithPeers === true ? "true" : "false",
      description: editRoleMember.description,
      roleId: editRoleId.roleId,
    };
    setApiLoader(true);
    try {
      const headersResult = getHeaders(true);
      if (headersResult.error) {
        navigate("../");
        return;
      }
      const response = await axios.post(URI.editRole, requestBody, {
        headers: headersResult,
      });
      if (response.data.status === 200) {
        getAllRoles();
        NotificationManager.success("", response.data.message, 5000);
        setActiveRoleItems("roles");
        setSelectedRoleItem("");
      } else {
        NotificationManager.error("", response.data.message, 5000);
      }
    } catch (err) {
      handleApiError(err, navigate);
    } finally {
      setApiLoader(false);
    }
  };

  const getUsersByrole = async (roleName) => {
    const requestBody = {
      loggedInUserId: loginUserId,
      roleName: roleName,
      pageNo: 1,
      pageSize: 10,
    };
    try {
      const headersResult = getHeaders(true);
      if (headersResult.error) {
        navigate("../");
        return;
      }
      const response = await axios.post(URI.getUsersRole, requestBody, {
        headers: headersResult,
      });
      if (response.data.status === 200) {
        setRolesListData(response.data.data.userList);
      } else {
        NotificationManager.error("", response.data.message, 5000);
      }
    } catch (err) {
      handleApiError(err, navigate);
    }
  };

  const handleEditClick = (role) => {
    setStoreRoleNameDeleted(role);
    setEditRoleMember({ ...role });
    setEditRoleId(role);
    handleSetRole(roles, role);
  };

  function titleCase(str) {
    return str.toLowerCase().replace(/(?:^|\s)\w/g, function (match) {
      return match.toUpperCase();
    });
  }

  function titleCaseNew(str) {
    return str
      .split("_")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  }

  // Example usage
  // const moduleName = "sales_orders";

  const renderTree = (roles, hiddenNodes, hoveredNodeId, setHoveredNodeId) => {
    return roles.map((role) => {
      const isHidden = hiddenNodes.includes(role.roleId);
      const isRootNode = role.level === 1;
      const isCEO = role.deleted;

      const handleMouseEnter = () => {
        if (!isRootNode) {
          setHoveredNodeId(role.roleId);
        }
      };

      const handleMouseLeave = () => {
        setHoveredNodeId(null);
      };

      const nodeLabel = (
        <div
          className="node-label"
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          <div
            onClick={() => {
              if (role.level !== 1) {
                setActiveRoleItems("viewRoles");
                getUsersByrole(role.roleName);
              }
            }}
          >
            <span> {role.roleName}</span>
          </div>
          {!isRootNode && hoveredNodeId === role.roleId && (
            <div className="hover-icons">
              <div className="hover-icon">
                <img
                  src={Edit}
                  alt="edit"
                  style={{
                    width: "12px",
                    height: "12px",
                    backgroundColor: "#DEEAFB",
                  }}
                  onClick={() => {
                    setActiveRoleItems("edit");
                    handleEditClick(role);
                  }}
                />
              </div>
              {isCEO && (
                <div className="hover-icon">
                  <img
                    src={Delete}
                    alt="delete"
                    style={{
                      width: "12px",
                      height: "12px",
                      backgroundColor: "#DEEAFB",
                    }}
                    onClick={() => {
                      setActiveRoleItems("delete");
                      setStoreRoleNameDeleted(role);
                      setSelectTransferId("");
                    }}
                  />
                </div>
              )}
            </div>
          )}
        </div>
      );

      if (isHidden) {
        return null;
      }

      if (role.subRoles && role.subRoles.length > 0) {
        return (
          <TreeNode key={role.roleId} label={nodeLabel}>
            {renderTree(
              role.subRoles,
              hiddenNodes,
              hoveredNodeId,
              setHoveredNodeId
            )}
          </TreeNode>
        );
      } else {
        return <TreeNode key={role.roleId} label={nodeLabel} />;
      }
    });
  };

  const getAllNodeIds = (roles) => {
    let nodeIds = [];
    roles.forEach((role) => {
      nodeIds.push(role.roleId);
      if (role.subRoles && role.subRoles.length > 0) {
        nodeIds = [...nodeIds, ...getAllNodeIds(role.subRoles)];
      }
    });
    return nodeIds;
  };
  const isOnlyRootVisible =
    roles.length > 0 && hiddenNodes.length === getAllNodeIds(roles).length - 1;

  const handleExpandAll = () => {
    setHiddenNodes([]);
  };

  const handleCollapseAll = () => {
    if (roles.length > 0) {
      const rootNodeId = roles[0].roleId;
      const allNodeIds = getAllNodeIds(roles);
      const hiddenNodeIds = allNodeIds.filter((id) => id !== rootNodeId);
      setHiddenNodes(hiddenNodeIds);
    }
  };

  const handleDisableRole = (item) => {
    if (activeRoleItems === "add") {
      return false; // Disable only the first item in 'Add New Role' mode
    }
    const found = highlightedRoles.some((e) => e.roleId === item.roleId);
    return found ? true : false;
  };

  const renderTreeRolesAndSharing = (roles) => {
    return roles.map((role, index) => {
      const isHidden = hiddenNodes.includes(role.roleId);
      // const isFirstIndex = parentIndex === -1 && index === 0;
      const shouldDisableFirstIndex = role.level === 1;

      const nodeLabel = (
        <div
          className="node-label"
          style={{
            backgroundColor: "#ffffff",
            border: "2px solid #407BFF",
            borderRadius: "25px",
            padding: "5px 5px",
            cursor: shouldDisableFirstIndex ? "not-allowed" : "pointer",
            textAlign: "center",
            margin: "0px auto",
            position: "relative",
            fontSize: "16px",
            display: "inline-block",
            opacity:
              handleDisableRole(role) || shouldDisableFirstIndex
                ? "0.3"
                : "0.9",
            pointerEvents: shouldDisableFirstIndex ? "none" : "auto", // Disable click events for the first item
          }}
          disabled={role.roleId === selectedRoleId || shouldDisableFirstIndex}
          onClick={() => {
            if (!shouldDisableFirstIndex && handleDisableRole(role) === false) {
              handleClick(role.roleName, role.roleId, role.roleTransferId);
            }
            if (activeRoleItems === "edit") {
              setSelectedReportsTo(role);
            }
          
          }}
        >
          {role.roleName}
        </div>
      );

      if (isHidden) {
        return null;
      }

      if (role.subRoles && role.subRoles.length > 0) {
        return (
          <TreeNode key={role.roleId} label={nodeLabel}>
            {renderTreeRolesAndSharing(role.subRoles, index)}
          </TreeNode>
        );
      } else {
        return <TreeNode key={role.roleId} label={nodeLabel} />;
      }
    });
  };

  const handleClick = (roleName, roleId, roleTransferId) => {
    setSelectTransferId(roleTransferId);
    setSelectedRoleItem(roleName);
    setSelectedRoleId(roleId);
   
    if (activeRole === "role" && activeRoleItems === "roles") {
      setShowAddUserModal(false);
    } else if (activeRole === "role" && activeRoleItems === "delete") {
      setShowAddUserModal(false);
    } else if (activeRole === "role" && activeRoleItems === "edit")
      setShowAddUserModal(false);
    else if (activeRole === "role" && activeRoleItems === "add")
      setShowAddUserModal(false);
    else if (activeRole === "" && activeRoleItems === "roles")
      setShowAddUserModal(true);
    else {
      setShowAddUserModal(true);
    }
    setRolesModal(false);
  };

  const activateDeactivateUser = async () => {
    const requestBody = {
      loggedInUserId: loginUserId,
      userId: activeIndex,
      status: modalAction === "activate" ? "ACTIVE" : "INACTIVE",
    };
    const headersResult = getHeaders(true);
    setApiLoaderActivate(true)
    if (headersResult.error) {
      navigate("../");
      return;
    }
    await axios
      .post(URI.activeInactiveUser, requestBody, {
        headers: headersResult,
      })
      .then((response) => {
        if (response.data.status === 200) {
          NotificationManager.success("", response.data.message, 5000);
          // getUserListActivate();
          getUserList();
          setApiLoaderActivate(false)
          setActiveDeactiveModal(false);
         
        } else {
          NotificationManager.error("", response.data.message, 5000);
        }
      })
      .catch((err) => {
        handleApiError(err, navigate);
      });
  };

  const getActiveInactiveUsers = async (item) => {
    if (item?.profile?.toUpperCase() === "SUPER ADMIN") {
      return false;
    }
    const requestBody = {
      loggedInUserId: loginUserId,
      userId: item.userId,
      status:
        item.userStatus?.toUpperCase() === "ACTIVE" ? "INACTIVE" : "ACTIVE",
    };
    const headersResult = getHeaders(true);
    if (headersResult.error) {
      navigate("../");
      return;
    }
    await axios
      .post(URI.activeInactiveUser, requestBody, {
        headers: headersResult,
      })
      .then((response) => {
        if (response.data.status === 200) {
          getUserListActivate();
        } else {
          alert("error");
        }
      })
      .catch((err) => {
        handleApiError(err, navigate);
      });
  };

  useEffect(() => {
    if (loginUserId) {
      getLoginActivityDetails(allActivity);
    }
  }, [allActivity, searchLoginActivity]);

  const getLoginActivityDetails = async () => {
    const requestBody = {
      loggedInUserId: loginUserId,
      duration: selectActivity,
      flagValue: allActivity,
      searchString: searchLoginActivity,
    };

    if (selectActivity === "Specific Date" && specificDate) {
      requestBody.specificDate = moment(specificDate)?.format("DD-MM-YYYY");
    } else if (selectActivity === "Custom Range" && fromDate && toDate) {
      requestBody.fromDate = moment(fromDate)?.format("DD-MM-YYYY");
      requestBody.toDate = moment(toDate)?.format("DD-MM-YYYY");
    }
    const headersResult = getHeaders(true);
    if (headersResult.error) {
      navigate("../");
      return;
    }

    await axios
      .post(URI.getLoginActivity, requestBody, {
        headers: headersResult,
      })
      .then((response) => {
        if (response.data.status === 200) {
          setLoginActivityData(response.data.data.getLogInActivity);
          setLoginChartData(response.data.data.getLogInChartData);
          setLoginActivityUserData(response.data.data.getLogInUserActivity);
          setErrorMessage(response.data.data.message);
        } else {
          setErrorMessage(
            response.data.data.message || "No LogIn Activity details found"
          );
          setLoginActivityData([]);
        }
      })
      .catch((err) => {
        handleApiError(err, navigate);
      });
  };

  const addUser = async () => {
    const errors = {};
    let hasError = false;

    if (!email) {
      errors.email = "Email cannot be empty.";
      hasError = true;
    }

    if (!selectedRoleItem) {
      errors.role = "Role must be selected.";
      hasError = true;
    }

    if (!selectedProfileItem || !selectedProfileItem.profileName) {
      errors.profile = "Profile must be selected.";
      hasError = true;
    }

    if (hasError) {
      setError(errors.email || "");
      setRoleError(errors.role || "");
      setProfileError(errors.profile || "");

      setTimeout(() => {
        setError("");
        setRoleError("");
        setProfileError("");
      }, 3000);

      return; // Stop further execution
    }

    const requestBody = {
      loggedInUserId: loginUserId,
      emailId: email,
      role: selectedRoleItem,
      profile: selectedProfileItem.profileName,
    };
    setApiLoader(true);
    try {
      const headersResult = getHeaders(true);
      if (headersResult.error) {
        navigate("../");
        return;
      }
      const response = await axios.post(URI.addUser, requestBody, {
        headers: headersResult,
      });
      if (response.data.status === 200) {
        NotificationManager.success("", response.data.message, 5000);
        setEmail("");
        setSelectedProfileItem("");
        setSelectedRoleItem("");
      } else {
        NotificationManager.error("", response.data.message, 5000);
      }
    } catch (err) {
      handleApiError(err, navigate);
      NotificationManager.error(
        "",
        "An error occurred while adding the user.",
        5000
      );
    } finally {
      setApiLoader(false);
      setShowAddUserModal(false);
    }
  };

  const getAllMembersList = async (members, id, status) => {
    let endpoint = `${URI.getGroupMembers}/${loginUserId}/${id}/${members}`;

    if (members === "users" && status) {
      endpoint += `/${status}`;
    }
    const headersResult = getHeaders(true);
    if (headersResult.error) {
      navigate("../");
      return;
    }
    await axios
      .get(endpoint, {
        headers: headersResult,
      })
      .then((response) => {
        if (response.data.status === 200) {
          setAllMembers(response.data.data.getGroupMembersDetails);
        }
      })
      .catch((error) => {
        handleApiError(error, navigate);
      });
  };

  const createNewGroup = async () => {
    const errors = {};
    let hasError = false;

    if (!addGroup.groupName) {
      errors.groupName = "Group Name cannot be empty.";
      hasError = true;
    }

    if (!addGroup.groupName.trim()) {
      setGroupNameErrorMsg("Group Name cannot be empty.");
      setTimeout(() => {
        setGroupNameErrorMsg("");
      }, 3000);
      return false;
    }

    if (hasError) {
      setGroupNameErrorMsg(errors.groupName || "");
      setTimeout(() => {
        setGroupNameErrorMsg("");
      }, 3000);
      return;
    }
    setShowAddMemberModal(true);
    setCreateGroupModal(false);
    setCheckedItemsUser({});
    setCheckedItemsRoles({});
    setCheckedItemsGroups({});
    handleSelectMember("users");
    getUserListActivate();
  };

  const getAllProfileDetails = async () => {
    const requestBody = {
      loggedInUserId: loginUserId,
      pageNo: 1,
      pageSize: 10,
    };
    const headersResult = getHeaders(true);
    if (headersResult.error) {
      navigate("../");
      return;
    }
    await axios
      .post(URI.getProfileList, requestBody, {
        headers: headersResult,
      })
      .then((response) => {
        if (response.data.status === 200) {
          setProfileLists(response.data.data.profileList);
          response?.data?.data?.profileList &&
            response?.data?.data?.profileList.map((e) => {
              if (e.profileName === "Administrator") {
                setSelectedProfileItem(e);
              }
            });
        } else {
          alert("error");
        }
      })
      .catch((err) => {
        handleApiError(err, navigate);
      });
  };

  const deleteGroupDetils = async (groupId) => {
    const requestBody = {
      loggedInUserId: loginUserId,
      groupId: groupId,
    };
    const headersResult = getHeaders(true);
    if (headersResult.error) {
      navigate("../");
      return;
    }
    await axios
      .post(URI.deleteGroup, requestBody, {
        headers: headersResult,
      })
      .then((response) => {
        if (response.data.status === 200) {
          NotificationManager.success("", response.data.message, 5000);
          setDeleteGroup(false);
          getGroupListDetails();
        } else {
          NotificationManager.error("", response.data.message, 5000);
        }
      })
      .catch((err) => {
        handleApiError(err, navigate);
      });
  };

  const updateRenameGroup = async () => {
    const requestBody = {
      loggedInUserId: loginUserId,
      groupId: renameGroup.groupId || groupName.groupId,
      groupDescription:
        renameGroup.groupDescription !== undefined &&
        renameGroup.groupDescription !== null
          ? renameGroup.groupDescription
          : groupName.groupDescription,
      groupName:
        renameGroup.groupName !== undefined && renameGroup.groupName !== null
          ? renameGroup.groupName
          : groupName.groupName,
    };
    setApiLoader(true);
    const headersResult = getHeaders(true);
    if (headersResult.error) {
      navigate("../");
      return;
    }
    await axios
      .post(URI.renameEditGroup, requestBody, {
        headers: headersResult,
      })
      .then((response) => {
        if (response.data.status === 200) {
          NotificationManager.success("", response.data.message, 5000);
          handleCloseGroupItems();
          getGroupListDetails();
          setShowGroup("group");
        } else {
          NotificationManager.error("", response.data.message, 5000);
        }
      })
      .catch((err) => {
        handleApiError(err, navigate);
      })
      .finally(() => {
        setApiLoader(false);
        setCreateGroupModal(false);
      });
  };

  const addnewMemberGroup = async (users, groupId) => {
    const requestBody = {
      loggedInUserId: loginUserId,
      searchString: "",
      flagValue: users,
      groupId: groupId,
    };
    const headersResult = getHeaders(true);
    if (headersResult.error) {
      navigate("../");
      return;
    }
    await axios
      .post(URI.getMembersToAdd, requestBody, {
        headers: headersResult,
      })
      .then((response) => {
        if (response.data.status === 200) {
          setGroupMembersList(response.data.data.getMembersDetails);
        } else {
          NotificationManager.error("", response.data.message, 5000);
        }
      })
      .catch((err) => {
        handleApiError(err, navigate);
      });
  };

  const handleChangeSettings = (e) => {
    const { value } = e.target;
    setEmail(value);
    if (value === "") {
      setError("Email cannot be empty");
    } else {
      setError("");
    }
  };

  function ToggleSwitch({ checked, onChange }) {
    return (
      <label className="toggle-switch">
        <input type="checkbox" checked={checked} onChange={onChange} />
        <span className="toggle-slider"></span>
      </label>
    );
  }

  const handleSearchAllMembersChange = (e) => {
    const { value } = e.target;
    if (value.length === 0 || value.charAt(0) !== " ") {
      setSearchAllMembers(value);
    }
  };

  const handleSearchMembersChange = (e) => {
    const { value } = e.target;
    if (value.length === 0 || value.charAt(0) !== " ") {
      setSearchMember(value);
    }
  };

  const developerGroup = () => {
    return (
      <div className="bg-white rounded-1 p-2" style={{ height: "auto" }}>
        <div
          className="commonContainer_bg rounded-2 ms-2 mt-1"
          style={{ height: "100vh" }}
        >
          <div className="d-flex justify-content-between">
            <div className="d-flex gap-2 align-items-center ms-2 mt-2">
              <div>
                <img
                  src={leftArrow}
                  alt="leftArrow"
                  className="cursorPointer"
                  onClick={() => {
                    setShowGroup("group");
                    setActiveGroupTab("users");
                  }}
                />
              </div>
              <div
                style={{
                  fontSize: "24px",
                  background:
                    "linear-gradient(to right, rgba(64, 123, 255, 1), rgba(18, 167, 254, 1))",
                  WebkitBackgroundClip: "text",
                  backgroundClip: "text",
                  WebkitTextFillColor: "transparent",
                }}
              >
                {groupName.groupName}
              </div>
              <div>
                <img
                  src={EditIcon}
                  alt="EditIcon"
                  className="cursorPointer"
                  onClick={(index) => {
                    handleRenameClick(index);
                  }}
                />
              </div>
            </div>
            <div>
              {Object.keys(selectedUsers).length > 0 ||
              Object.keys(selectedRoles).length > 0 ||
              Object.keys(selectedGroups).length > 0 ? (
                <button
                  className="deleteButton mt-2 me-2 px-4 p-1"
                  onClick={() => {
                    if (activeGroupTab === "users") {
                      handleButtonClickDeleteUsers();
                    } else if (activeGroupTab === "roles") {
                      handleButtonClickDeleteRoles();
                    } else {
                      handleButtonClickDeleteGroups();
                    }
                  }}
                >
                  Remove
                </button>
              ) : (
                <button
                  className="createContactButtonSave mt-2 me-2"
                  onClick={() => {
                    setAddNewMember(true);
                    handleSelectMember(activeGroupTab);
                    addnewMemberGroup(activeGroupTab, groupIdIndividual);
                    setSearchMember("");
                  }}
                >
                  Add Member
                </button>
              )}
            </div>
            {addNewMember && (
              <Modal
                show={addNewMember}
                className="d-flex justify-content-center align-items-center"
                aria-labelledby="example-custom-modal-styling-title"
                size="xl"
                id="commonLeadModal"
                style={{ height: "auto" }}
              >
                <Modal.Body className="ModalBody overflow-auto">
                  <div className="w-100 ">
                    <div
                      className="ms-2"
                      style={{ fontSize: "18px", fontWeight: "bold" }}
                    >
                      Add Members to this Group &quot;{groupName.groupName}
                      &quot;
                    </div>
                    <div className="mt-4 d-flex justify-content-between">
                      <Dropdown>
                        <Dropdown.Toggle id="dropdown-activity">
                          {selectedMemberName}
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                          <Dropdown.Item
                            eventKey="Users"
                            onClick={() => {
                              handleSelectMember("users");
                              addnewMemberGroup("users", groupIdIndividual);
                            }}
                          >
                            Users
                          </Dropdown.Item>
                          <Dropdown.Item
                            eventKey="Roles"
                            onClick={() => {
                              handleSelectMember("roles");
                              addnewMemberGroup("roles", groupIdIndividual);
                            }}
                          >
                            Roles
                          </Dropdown.Item>
                          <Dropdown.Item
                            eventKey="Groups"
                            onClick={() => {
                              handleSelectMember("groups");
                              addnewMemberGroup("groups", groupIdIndividual);
                            }}
                          >
                            Groups
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                      <div className="inputContainer">
                        <img src={Search} alt="search" className="searchIcon" />
                        <input
                          type="text"
                          placeholder="Search"
                          value={searchMember}
                          onChange={handleSearchMembersChange}
                          className="searchGroup bg-white px-5 w-75 p-2 ms-2"
                        />
                      </div>
                    </div>
                    {selectMembers === "users" && (
                      <div
                        className="bg-white rounded-2 mt-4 membersListClass"
                        style={{
                          height: "50vh",
                          overflowX: "hidden",
                          overflowY: "auto",
                          width: "30vw",
                        }}
                      >
                        <div
                          className="w-100 d-flex p-2"
                          style={{ color: "#515151" }}
                        >
                          <div
                            style={{ width: "50%" }}
                            className="d-flex justify-content-between me-2"
                          >
                            <div>Users</div>
                            <div style={{ color: "#E2E5EB" }}>|</div>
                          </div>
                          <div
                            style={{ width: "50%" }}
                            className="d-flex justify-content-between me-2"
                          >
                            <div>Email Address</div>
                            <div style={{ color: "#E2E5EB" }}>|</div>
                          </div>
                        </div>
                        <div
                          style={{ borderBottom: "1px solid #515151" }}
                          className="ms-2 me-2"
                        ></div>
                        {filteredAllMembersUsers.length > 0 ? (
                          filteredAllMembersUsers.map((item, index) => (
                            <div key={index}>
                              <div className="d-flex p-2">
                                <div
                                  style={{ width: "50%" }}
                                  className="d-flex gap-2"
                                >
                                  <input
                                    type="checkbox"
                                    checked={
                                      checkedItemsUser[item.userId] || false
                                    }
                                    onChange={() =>
                                      handleCheckboxChangeUser(item)
                                    }
                                  />
                                  <div
                                    className="overflow_text"
                                    style={{
                                      fontSize: "14px",
                                      color: "#515151",
                                    }}
                                  >
                                    {item.users}
                                  </div>
                                </div>

                                <div
                                  className="overflow_text"
                                  style={{
                                    width: "50%",
                                    fontSize: "14px",
                                    color: "#515151",
                                  }}
                                >
                                  {item.emailId}
                                </div>
                              </div>
                            </div>
                          ))
                        ) : (
                          <div
                            style={{
                              padding: "10px",
                              fontSize: "16px",
                              color: "#515151",
                            }}
                            className="d-flex justify-content-center"
                          >
                            No users found
                          </div>
                        )}
                      </div>
                    )}

                    {selectMembers === "roles" && (
                      <div
                        className="bg-white rounded-2 mt-4 membersListClass"
                        style={{
                          height: "50vh",
                          overflowX: "hidden",
                          overflowY: "auto",
                          width: "30vw",
                        }}
                      >
                        <div
                          className="w-100 d-flex p-2"
                          style={{ color: "#515151" }}
                        >
                          <div
                            style={{ width: "50%" }}
                            className="d-flex justify-content-between me-2"
                          >
                            <div>Roles</div>
                            <div style={{ color: "#E2E5EB" }}>|</div>
                          </div>
                          <div
                            style={{ width: "50%" }}
                            className="d-flex justify-content-between me-2"
                          >
                            <div>Subordinates</div>
                            <div style={{ color: "#E2E5EB" }}>|</div>
                          </div>
                        </div>
                        <div
                          style={{ borderBottom: "1px solid #515151" }}
                          className="ms-2 me-2"
                        ></div>
                        {filteredAllMembersUsers.length > 0 ? (
                          filteredAllMembersUsers.map((item, index) => (
                            <div key={index}>
                              <div className="d-flex gap-2 p-2">
                                <div
                                  style={{ width: "50%" }}
                                  className="d-flex gap-2"
                                >
                                  <input
                                    type="checkbox"
                                    checked={
                                      checkedItemsRoles[item.roleId] || false
                                    }
                                    onChange={() =>
                                      handleCheckboxChangeRole(item)
                                    }
                                  />
                                  <div
                                    className="overflow_text"
                                    style={{
                                      fontSize: "14px",
                                      color: "#515151",
                                    }}
                                  >
                                    {item.roles}
                                  </div>
                                </div>
                                <div
                                  style={{
                                    width: "50%",
                                    fontSize: "14px",
                                    color: "#515151",
                                  }}
                                  className="d-flex justify-content-center"
                                >
                                  <input
                                    type="checkbox"
                                    style={{ opacity: "0.7" }}
                                    disabled={true}
                                  />
                                </div>
                              </div>
                            </div>
                          ))
                        ) : (
                          <div
                            style={{
                              padding: "10px",
                              fontSize: "16px",
                              color: "#515151",
                            }}
                            className="d-flex justify-content-center"
                          >
                            No roles found
                          </div>
                        )}
                      </div>
                    )}

                    {selectMembers === "groups" && (
                      <div
                        className="bg-white rounded-2 mt-4 membersListClass"
                        style={{
                          height: "50vh",
                          overflowX: "hidden",
                          overflowY: "auto",
                          width: "30vw",
                        }}
                      >
                        <div
                          className="w-100 d-flex p-2"
                          style={{ color: "#515151" }}
                        >
                          <div
                            style={{ width: "50%" }}
                            className="d-flex justify-content-between me-2"
                          >
                            <div>Groups</div>
                            <div style={{ color: "#E2E5EB" }}>|</div>
                          </div>
                          <div
                            style={{ width: "50%" }}
                            className="d-flex justify-content-between me-2"
                          >
                            <div>Members</div>
                            <div style={{ color: "#E2E5EB" }}>|</div>
                          </div>
                        </div>
                        <div
                          style={{ borderBottom: "1px solid #515151" }}
                          className="ms-2 me-2"
                        ></div>
                        {filteredAllMembersUsers.length > 0 ? (
                          filteredAllMembersUsers.map((item, index) => (
                            <div key={index}>
                              <div className="d-flex p-2">
                                <div
                                  style={{ width: "50%" }}
                                  className="d-flex gap-2"
                                >
                                  <input
                                    type="checkbox"
                                    checked={
                                      checkedItemsGroups[item.groupId] || false
                                    }
                                    onChange={() =>
                                      handleCheckboxChangeGroup(item)
                                    }
                                  />
                                  <div
                                    className="overflow_text"
                                    style={{
                                      fontSize: "14px",
                                      color: "#515151",
                                    }}
                                  >
                                    {item.groups}
                                  </div>
                                </div>
                                <div
                                  className="overflow_text"
                                  style={{
                                    width: "50%",
                                    fontSize: "14px",
                                    color: "#515151",
                                  }}
                                >
                                  {item.members}
                                </div>
                              </div>
                            </div>
                          ))
                        ) : (
                          <div
                            style={{
                              padding: "10px",
                              fontSize: "16px",
                              color: "#515151",
                            }}
                            className="d-flex justify-content-center"
                          >
                            No groups found
                          </div>
                        )}
                      </div>
                    )}

                    <div className="d-flex gap-2 align-items-center justify-content-end">
                      <button
                        onClick={() => {
                          setAddNewMember(false);
                          setSelectMembers("users");
                        }}
                        className="cancelRecycle px-4 mt-4 d-flex justify-content-center align-items-center"
                      >
                        Cancel
                      </button>
                      <button
                        onClick={() => {
                          handleButtonClickMembers();
                        }}
                        className="deleteButton px-4 mt-4 d-flex justify-content-center align-items-center"
                      >
                        Save
                      </button>
                    </div>
                  </div>
                </Modal.Body>
              </Modal>
            )}
          </div>
          <div
            className="w-50 ms-4 overflow_text"
            style={{ color: "#515151", fontSize: "16px", fontWeight: "300" }}
          >
            {" "}
            {groupName.groupDescription}
          </div>
          <div className="d-flex justify-content-between align-items-center mt-3 w-100">
            <div
              className="buttonTab_container d-flex p-1 bg-white ms-2"
              style={{ border: "1px solid #515151", borderRadius: "30px" }}
            >
              <span
                className={
                  activeGroupTab === "users"
                    ? "activeTabSetting"
                    : "inActiveTab "
                }
                onClick={() => {
                  setActiveGroupTab("users");
                  getAllMembersList("users", groupIdIndividual);
                }}
              >
                Users
              </span>
              <span
                className={
                  activeGroupTab === "roles"
                    ? "activeTabSetting"
                    : "inActiveTab "
                }
                onClick={() => {
                  setActiveGroupTab("roles");
                  getAllMembersList("roles", groupIdIndividual);
                }}
              >
                Roles
              </span>
              <span
                className={
                  activeGroupTab === "groups"
                    ? "activeTabSetting "
                    : "inActiveTab "
                }
                onClick={() => {
                  setActiveGroupTab("groups");
                  getAllMembersList("groups", groupIdIndividual);
                }}
              >
                Groups
              </span>
            </div>
            <div className="d-flex justify-content-end me-2 ">
              {activeGroupTab === "users" && (
                <DropdownButton
                  id="dropdown-active-user"
                  title={selectedStatusName}
                >
                  <Dropdown.Item
                    eventKey="Active"
                    onClick={() => {
                      handleSelectGroupStatus("Active");
                    }}
                  >
                    Active Users
                  </Dropdown.Item>
                  <Dropdown.Item
                    eventKey="Inactive"
                    onClick={() => {
                      handleSelectGroupStatus("Inactive");
                    }}
                  >
                    Deactivated Users
                  </Dropdown.Item>
                  <Dropdown.Item
                    eventKey="deleted"
                    onClick={() => {
                      handleSelectGroupStatus("deleted");
                    }}
                  >
                    Deleted Users
                  </Dropdown.Item>
                </DropdownButton>
              )}

              <div className="inputContainer">
                <img src={Search} alt="search" className="searchIcon" />
                <input
                  type="text"
                  placeholder="Search"
                  value={searchAllMembers}
                  onChange={handleSearchAllMembersChange}
                  className="searchGroup px-5 p-2 ms-2"
                />
              </div>
            </div>
          </div>
          {activeGroupTab === "users" && (
            <div
              className="bg-white rounded-2 ms-2 mt-4 me-2"
              style={{
                height: "60vh",
                overflowY: "auto",
                border: "1px solid #E2E5EB",
              }}
            >
              <div className="w-100 d-flex p-2" style={{ color: "#515151" }}>
                <div
                  style={{ width: "33%" }}
                  className="d-flex justify-content-between me-2"
                >
                  <div>User</div>
                  <div style={{ color: "#E2E5EB" }}>|</div>
                </div>
                <div
                  style={{ width: "42%" }}
                  className="d-flex justify-content-between me-2"
                >
                  <div>Email</div>
                  <div style={{ color: "#E2E5EB" }}>|</div>
                </div>
                <div
                  style={{ width: "25%" }}
                  className="d-flex justify-content-between me-2"
                >
                  <div>Status</div>
                  <div style={{ color: "#E2E5EB" }}>|</div>
                </div>
              </div>
              <div
                style={{ borderBottom: "1px solid #515151" }}
                className="ms-2 me-2"
              ></div>

              {filteredAllMembers.length > 0 ? (
                filteredAllMembers.map((item, index) => (
                  <div
                    key={index}
                    className="w-100 d-flex justify-content-between p-2"
                  >
                    <div className="d-flex gap-2" style={{ width: "33%" }}>
                      <input
                        type="checkbox"
                        checked={selectedUsers[item.grpMembrId] || false}
                        onChange={() => handleCheckboxChangeSelectedUsers(item)}
                      />
                      <div>{item.users}</div>
                    </div>
                    <div style={{ width: "42%" }} className="overflow_text">
                      {item.emailId}
                    </div>
                    <div
                      style={{ width: "25%" }}
                      className="d-flex align-items-center gap-2"
                    >
                      <input
                        type="checkbox"
                        className="custom-checkbox"
                        checked={item.userStatus === "Active"}
                      />

                      <div
                        style={{
                          color: "#29AE4F",
                          fontWeight: "400",
                        }}
                      >
                        {item.userStatus}
                      </div>
                    </div>
                  </div>
                ))
              ) : (
                <div
                  style={{ height: "50vh" }}
                  className="d-flex justify-content-center align-items-center"
                >
                  <div style={{ fontSize: "20px" }}>No users found</div>
                </div>
              )}
            </div>
          )}

          {activeGroupTab === "roles" && (
            <div
              className="bg-white rounded-2 ms-2 mt-4 me-2"
              style={{
                height: "60vh",
                overflowY: "auto",
                border: "1px solid #E2E5EB",
              }}
            >
              <div className="w-100 d-flex p-2" style={{ color: "#515151" }}>
                <div
                  style={{ width: "50%" }}
                  className="d-flex justify-content-between me-2"
                >
                  <div>Roles</div>
                  <div style={{ color: "#E2E5EB" }}>|</div>
                </div>
                <div
                  style={{ width: "50%" }}
                  className="d-flex justify-content-between me-2"
                >
                  <div>Subordinates</div>
                  <div style={{ color: "#E2E5EB" }}>|</div>
                </div>
              </div>
              <div
                style={{ borderBottom: "1px solid #515151" }}
                className="ms-2 me-2"
              ></div>

              {filteredAllMembers.length > 0 ? (
                filteredAllMembers.map((item, index) => (
                  <div
                    key={index}
                    className="w-100 d-flex justify-content-between p-2"
                  >
                    <div style={{ width: "50%" }} className="d-flex gap-2">
                      <input
                        type="checkbox"
                        checked={selectedRoles[item.grpRoleId] || false}
                        onChange={() => handleCheckboxChangeSelectedRoles(item)}
                      />
                      <div>{item.roles}</div>
                    </div>
                    <div style={{ width: "50%" }}>{item.subordinates}</div>
                  </div>
                ))
              ) : (
                <div
                  style={{ height: "50vh" }}
                  className="d-flex justify-content-center align-items-center"
                >
                  <div style={{ fontSize: "20px" }}>No users found</div>
                </div>
              )}
            </div>
          )}

          {activeGroupTab === "groups" && (
            <div
              className="bg-white rounded-2 ms-2 mt-4 me-2"
              style={{
                height: "60vh",
                overflowY: "auto",
                border: "1px solid #E2E5EB",
              }}
            >
              <div className="w-100 d-flex p-2" style={{ color: "#515151" }}>
                <div
                  style={{ width: "50%" }}
                  className="d-flex justify-content-between me-2"
                >
                  <div>Groups</div>
                  <div style={{ color: "#E2E5EB" }}>|</div>
                </div>
                <div
                  style={{ width: "50%" }}
                  className="d-flex justify-content-between me-2"
                >
                  <div>Members</div>
                  <div style={{ color: "#E2E5EB" }}>|</div>
                </div>
              </div>
              <div
                style={{ borderBottom: "1px solid #515151" }}
                className="ms-2 me-2"
              ></div>

              {filteredAllMembers.length > 0 ? (
                filteredAllMembers.map((item, index) => (
                  <div
                    key={index}
                    className="w-100 d-flex justify-content-between p-2"
                  >
                    <div style={{ width: "50%" }} className="d-flex gap-2">
                      <input
                        type="checkbox"
                        checked={selectedGroups[item.memberGroupId] || false}
                        onChange={() =>
                          handleCheckboxChangeSelectedGroups(item)
                        }
                      />
                      <div className="overflow_text">
                        {item.memberGroupName}
                      </div>
                    </div>
                    <div className="overflow_text" style={{ width: "50%" }}>
                      {item.members}
                    </div>
                  </div>
                ))
              ) : (
                <div
                  style={{ height: "50vh" }}
                  className="d-flex justify-content-center align-items-center"
                >
                  <div style={{ fontSize: "20px" }}>No users found</div>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    );
  };

  const handleRenameClick = (index) => {
    setCreateGroupModal(true);
    setEditRename(true);
    setRenameGroup(groupListData[index]);
  };

  const handleRenameProfileModal = (index) => {
    setRenameProfileModal(true);
    setRenameProfile(profileLists[index]);
  };

  const handleSearchChangeUser = (e) => {
    const { value } = e.target;
    if (value.length === 0 || value.charAt(0) !== " ") {
      setSearch(value);
    }
  };

  const handleSearchUsersRolesGroupsChange = (e) => {
    const { value } = e.target;
    if (value.length === 0 || value.charAt(0) !== " ") {
      setSearchUsersRolesGroups(value);
    }
  };

  const settingHomePage = () => {
    return (
      <>
        <div className="setting_rightSideContainer   bg-white rounded-2 px-1 py-2">
          <div
            className="buttonTab_container d-flex  px-2  bg-white  gap-2 py-2"
            style={{ border: "1px solid #515151", borderRadius: "30px" }}
          >
            <span
              className={
                activeTab === "user" ? "activeTabSetting" : "inActiveTab "
              }
              onClick={() => {
                setActiveTab("user");
                getUserList();
                setSearch("");
              }}
            >
              User
            </span>
            <span
              className={
                activeTab === "Group" ? "activeTabSetting" : "inActiveTab "
              }
              onClick={() => {
                setActiveTab("Group");
                getGroupListDetails();
                setSearchGroup("");
              }}
            >
              Group
            </span>
            <span
              className={
                activeTab === "activeUser"
                  ? "activeTabSetting "
                  : "inActiveTab "
              }
              onClick={() => {
                setActiveTab("activeUser");
                getUserListActivate();
              }}
            >
              Activate
            </span>
          </div>
          {menuTab === "User" && activeTab === "user" && (
            <div className="setting_content_container d-flex gap-3 pt-3">
              <div className="userInformation_container d-flex flex-column commonContainer_bg">
                <div className="d-flex  gap-3 px-2 py-2">
                  <DropdownButton
                    id="dropdown-active-user"
                    title={displayValue}
                  >
                    <Dropdown.Item
                      eventKey="Active"
                      onClick={() => handleSelect("Active")}
                    >
                      Active Users
                    </Dropdown.Item>
                    <Dropdown.Item
                      eventKey="Inactive"
                      onClick={() => handleSelect("Inactive")}
                    >
                      Deactivated Users
                    </Dropdown.Item>
                    <Dropdown.Item
                      eventKey="unconfirmed"
                      onClick={() => handleSelect("unconfirmed")}
                    >
                      Unconfirmed Users
                    </Dropdown.Item>
                    {/* <Dropdown.Item
                      eventKey="confirmed"
                      onClick={() => handleSelect("confirmed")}
                    >
                      confirmed Users
                    </Dropdown.Item> */}
                    <Dropdown.Item
                      eventKey="deleted"
                      onClick={() => handleSelect("deleted")}
                    >
                      Deleted Users
                    </Dropdown.Item>
                  </DropdownButton>

                  <div className="">
                    <div
                      className="addUser p-2"
                      onClick={() => {
                        setShowAddUserModal(true);
                        getAllRoles();
                        getAllProfileDetails();
                        setActiveRole("");
                        setStoreRoleNameDeleted("");
                      }}
                    >
                      +Add User
                    </div>
                  </div>
                </div>

                <div className="inputContainer">
                  <img src={Search} alt="search" className="searchIcon" />
                  <input
                    type="text"
                    placeholder="Search"
                    value={search}
                    onChange={handleSearchChangeUser}
                    className="searchGroup px-5 w-75 p-2 ms-2"
                  />
                </div>
                <div
                  style={{ borderBottom: "1px solid #515151" }}
                  className="mt-2"
                ></div>
                <div className="d-flex flex-column ">
                  {filteredUsers.length === 0 ? (
                    <div className="w-100 text-center mt-4 ms-4">
                      <p>No user details found</p>
                    </div>
                  ) : (
                    filteredUsers.map((item, index) => (
                      <div key={index}>
                        <div
                          className={`${
                            activeIndex === item.userId
                              ? "usersDisplay w-100 cursorPointer p-1"
                              : "p-1 w-100 cursorPointer"
                          }`}
                          onClick={() => {
                            getUserProfileInfo(item.userId);
                          }}
                        >
                          <div className="d-flex" style={{ width: "100%" }}>
                            <div style={{ width: "20%" }}>
                              <img
                                src={
                                  item?.userImageData === null
                                    ? leadAvatar
                                    : item?.userImageData
                                }
                                alt="userImage"
                                style={{ width: "50px", height: "50px" }}
                              />
                            </div>
                            <div className="me-2" style={{ width: "50%" }}>
                              <div
                                style={{
                                  color: "#515151",
                                  fontSize: "16px",
                                  fontWeight: "400",
                                }}
                              >
                                {item.name}
                              </div>
                              <div
                                style={{
                                  color: "#515151",
                                  fontSize: "12px",
                                  fontWeight: "400",
                                }}
                              >
                                {item.role}
                              </div>
                              <div
                                style={{
                                  color: "#515151",
                                  fontSize: "12px",
                                  fontWeight: "400",
                                }}
                              >
                                {item.emailId}
                              </div>
                            </div>
                            <div style={{ width: "30%" }}>
                              {item.profile?.toLowerCase() ===
                                "super admin" && (
                                <div className="d-flex justify-content-end">
                                  <div className=" text-white danger_gradient  px-1">
                                    Super Admin
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                        {index !== filteredUsers.length - 1 && (
                          <div
                            style={{ borderBottom: "1px solid #515151" }}
                          ></div>
                        )}
                      </div>
                    ))
                  )}
                </div>
              </div>
              <div className="userDetail_container commonContainer_bg">
                <div className="userDetail_header pt-2 px-2">
                  <div className="logoContainer_setting d-flex justify-content-between">
                    <div className="d-flex">
                      <span>
                        <img
                          src={
                            userInfo?.userImageData === null
                              ? leadAvatar
                              : userInfo?.userImageData
                          }
                          alt="userImage"
                          style={{ width: "75px", height: "75px" }}
                        />
                      </span>
                      <div className="d-flex flex-column  justify-content-end align-items-start ms-2">
                        <span>
                          {" "}
                          {userInfo?.firstName ? userInfo.firstName : "--"}
                        </span>
                        <span
                          className="opacity-75 "
                          style={{ fontSize: "10px" }}
                        >
                          {userInfo?.userRole ? userInfo.userRole : "--"}
                        </span>
                      </div>
                    </div>

                    <div className="d-flex flex-column  justify-content-end align-items-start">
                      <span className="d-flex opacity-75 fs_small">
                        <img
                          src={emailImage}
                          height={15}
                          width={20}
                          alt="img"
                        />
                        {userInfo?.emailId ? userInfo?.emailId : "--"}
                      </span>
                      <span className="opacity-75 fs_small w-100">
                        <img src={call} height={15} width={20} alt="img" />
                        {userInfo?.phoneNumber ? userInfo?.phoneNumber : "--"}
                      </span>
                    </div>
                    <div className="d-flex gap-1 px-2 flex-column  justify-content-end align-items-start ">
                      {userInfo?.userStatus !== "Deleted" && (
                        <span
                          className="btn btn-primary setting_btn text-center pt-1 rounded-3"
                          onClick={() => {
                            setEditUserModal(true);
                            setUserInfoUpdate({ ...userInfo });
                          }}
                        >
                          EDIT
                        </span>
                      )}
                      <span
                        className={`text-white ${profileClass} text-center px-2`}
                        style={{ fontSize: "14px" }}
                      >
                        {profileText}
                      </span>
                    </div>
                  </div>
                </div>
                <hr />
                <div className="userDetail_main_container px-5">
                  <h5 className="text-primary ">User Information</h5>
                  <div className="information_Container d-flex gap-5 ">
                    <div className="opacity-75 d-flex flex-column user_info ">
                      <span>First Name</span>
                      <span>Last Name</span>
                      <span> Email</span>
                      <span> Role</span>
                      <span> Profile</span>
                      <span> Added By</span>
                      <span> Alias</span>
                      <span> Mobile</span>
                      <span> Phone</span>
                      <span> Website</span>
                      <span> Fax</span>
                      <span> Date of Birth</span>
                    </div>
                    <div className=" d-flex flex-column user_info">
                      <span>
                        {" "}
                        {userInfo?.firstName ? userInfo.firstName : "--"}
                      </span>
                      <span>
                        {userInfo?.lastName ? userInfo.lastName : "--"}
                      </span>
                      <span>
                        {" "}
                        {userInfo?.emailId ? userInfo?.emailId : "--"}
                      </span>
                      <span>
                        {" "}
                        {userInfo?.userRole ? userInfo?.userRole : "--"}
                      </span>
                      <span>
                        {" "}
                        {userInfo?.userProfile ? userInfo?.userProfile : "--"}
                      </span>
                      <span>
                        {userInfo?.createdBy ? userInfo?.createdBy : "--"}
                      </span>
                      <span>
                        {" "}
                        {userInfo?.aliasName ? userInfo?.aliasName : "--"}
                      </span>
                      <span>
                        {" "}
                        {userInfo?.mobileNumber ? userInfo?.mobileNumber : "--"}
                      </span>
                      <span>
                        {" "}
                        {userInfo?.phoneNumber ? userInfo?.phoneNumber : "--"}
                      </span>
                      <span>
                        {" "}
                        {userInfo?.website ? userInfo?.website : "--"}
                      </span>
                      <span> {userInfo?.fax ? userInfo?.fax : "--"}</span>
                      <span>
                        {" "}
                        {userInfo?.dateOfBirth
                          ? moment(userInfo.dateOfBirth).format("DD-MM-YYYY")
                          : "--"}
                      </span>{" "}
                    </div>
                  </div>
                  <h5 className="text-primary pt-4 ">Address Information</h5>
                  <div className="information_Container d-flex gap-5 user_info ">
                    <div className="opacity-75 d-flex flex-column">
                      <span>Street</span>
                      <span>City</span>
                      <span> State</span>
                      <span> ZipCode</span>
                      <span>Country</span>
                    </div>
                    <div className=" d-flex flex-column ms-4">
                      <span>{userInfo?.street ? userInfo?.street : "--"}</span>
                      <span>{userInfo?.city ? userInfo?.city : "--"}</span>
                      <span>{userInfo?.state ? userInfo?.state : "--"}</span>
                      <span>
                        {userInfo?.zipCode ? userInfo?.zipCode : "--"}
                      </span>
                      <span>
                        {userInfo?.country ? userInfo?.country : "--"}
                      </span>
                    </div>
                  </div>
                  <h5 className="text-primary pt-4 ">Groups</h5>
                  <div
                    className="opacity-75 d-flex w-100"
                    style={{ overflowWrap: "anywhere" }}
                  >
                    <span className="w-25">Members In</span>
                    <span className="w-75 ms-3">
                      {userInfo?.groupNames ? userInfo?.groupNames : "--"}
                    </span>
                  </div>
                  <div className="p-2 w-100 d-flex justify-content-end">
                    <div>
                      {loginUserId !== userInfo?.userId &&
                        selectedItem !== "deleted" && (
                          <div className="d-flex gap-2">
                            <button
                              className="createContactButtonSave mt-4"
                              onClick={() => {
                                setDeleteUserModal(true);
                              }}
                            >
                              Delete this User
                            </button>
                            {selectedItem === "Inactive" ? (
                              <button
                                className="createContactButtonSave mt-4 px-2"
                                onClick={() => {
                                  setActiveDeactiveModal(true);
                                  setModalAction("activate");
                                }}
                              >
                                Activate
                              </button>
                            ) : (
                              <button
                                className="deleteButton mt-4 px-2"
                                onClick={() => {
                                  setActiveDeactiveModal(true);
                                  setModalAction("deactivate");
                                }}
                              >
                                Deactivate
                              </button>
                            )}
                          </div>
                        )}
                    </div>

                    {activeDeactiveModal && (
                      <Modal
                        show={activeDeactiveModal}
                        className="d-flex justify-content-center align-items-start"
                        aria-labelledby="example-custom-modal-styling-title"
                        size="lg"
                        id="commonLeadModal"
                      >
                        <Modal.Header>
                          <div className="d-flex justify-content-between align-items-center w-100">
                            <div
                              style={{
                                fontSize: "25px",
                                fontWeight: "bold",
                                background:
                                  "linear-gradient(to right, rgba(64, 123, 255, 1), rgba(18, 167, 254, 1))",
                                WebkitBackgroundClip: "text",
                                backgroundClip: "text",
                                WebkitTextFillColor: "transparent",
                              }}
                            >
                              {modalAction === "activate"
                                ? "User Activation"
                                : "User Deactivation"}
                            </div>
                          </div>{" "}
                        </Modal.Header>
                        <Modal.Body className="ModalBody overflow-auto">
                          <div>
                            {modalAction === "activate" ? (
                              <div className="w-100">
                                Are you sure you want to activate the user(s)?
                              </div>
                            ) : (
                              <div className="w-100">
                                The user(s) you have selected will no longer be
                                able to access Chain CRM. However, if you
                                reactivate them at a later date, they will be
                                able to view all of their existing data.
                              </div>
                            )}
                            <div className="d-flex gap-3 align-items-center justify-content-end mt-4">
                              <button
                                className="p-2 px-4 cancelRecycle"
                                onClick={() => {
                                  setActiveDeactiveModal(false);
                                }}
                              >
                                Cancel
                              </button>
                              {modalAction === "deactivate" ? (
                                <button
                                  className="p-2 px-4 deleteButton"
                                  onClick={activateDeactivateUser}
                                  disabled={apiLoaderActivate}
                                >
                                     {apiLoaderActivate ? (
                    <Lottie
                      options={customDefaultOptions("red")}
                      height="24px"
                      width="36px"
                      disabled={apiLoaderActivate}
                    />
                  ) : (
                    "Deactivate"
                  )}
                                </button>
                              ) : (
                                <button
                                  className="p-2 px-4 createContactButtonSave"
                                  onClick={activateDeactivateUser}
                                  disabled={apiLoaderActivate}
                                >
                                 {apiLoaderActivate ? (
                    <Lottie
                      options={customDefaultOptions("white")}
                      height="24px"
                      width="36px"
                      disabled={apiLoaderActivate}
                    />
                  ) : (
                    "Activate"
                  )}
                                </button>
                              )}
                            </div>
                          </div>
                        </Modal.Body>
                      </Modal>
                    )}

                    {deleteUserModal && (
                      <Modal
                        show={deleteUserModal}
                        className="d-flex justify-content-center align-items-start"
                        aria-labelledby="example-custom-modal-styling-title"
                        size="lg"
                        id="commonLeadModal"
                      >
                        <Modal.Header>
                          <div className="d-flex justify-content-between align-items-center w-100">
                            <div
                              style={{
                                fontSize: "25px",
                                fontWeight: "bold",
                                background:
                                  "linear-gradient(to right, rgba(64, 123, 255, 1), rgba(18, 167, 254, 1))",
                                WebkitBackgroundClip: "text",
                                backgroundClip: "text",
                                WebkitTextFillColor: "transparent",
                              }}
                            >
                              Delete {userInfo?.firstName}&apos;{" "}
                              {userInfo?.lastName}
                            </div>
                            <div>
                              <img
                                alt="cancel"
                                src={Info}
                                className="cursorPointer"
                                onClick={() => {
                                  setDeleteUserModal(false);
                                  setSelectedOptionDelete("delete");
                                }}
                              />
                            </div>
                          </div>{" "}
                        </Modal.Header>
                        <Modal.Body className="ModalBody overflow-auto">
                          <div>
                            <div
                              style={{
                                color: "#515151",
                                fontWeight: "400",
                                fontSize: "20px",
                              }}
                            >
                              Select a Delete Option
                            </div>

                            <div className="d-flex gap-2 mt-2 w-100">
                              <div className="d-flex gap-2 w-50">
                                <input
                                  type="radio"
                                  id="delete"
                                  value="delete"
                                  checked={selectedOptionDelete === "delete"} // set the 'checked' prop based on the selected option
                                  onChange={handleOptionChangeDelete}
                                  style={{ width: "20px" }}
                                />
                                <div className="deleteOption p-2">
                                  Delete without transfer
                                </div>
                              </div>
                              <div className="d-flex gap-2 w-50">
                                <input
                                  type="radio"
                                  id="transfer"
                                  value="transfer"
                                  checked={selectedOptionDelete === "transfer"} // set the 'checked' prop based on the selected option
                                  onChange={handleOptionChangeDelete}
                                  style={{ width: "20px" }}
                                />
                                <div className="deleteOption p-2">
                                  Transfer Now{" "}
                                </div>
                              </div>
                            </div>
                            <div className="mt-4">
                              {selectedOptionDelete === "transfer" && (
                                <div
                                  className="position-relative ms-4"
                                  style={{ width: "40%" }}
                                >
                                  <p className="mb-0">Transfer to</p>
                                  <div
                                    className="d-flex input-with-clear mt-1"
                                    onClick={handleUserClick}
                                  >
                                    <input
                                      type="text"
                                      placeholder="Select User"
                                      className="inputBoxTransfer cursorPointer"
                                      value={selectedUserList.name}
                                      readOnly
                                    />

                                    <span
                                      className="clear-button-profile"
                                      onClick={() =>
                                        selectedItem && setSelectedItem("")
                                      }
                                    >
                                      <img
                                        src={listVisible ? up : down}
                                        alt="img"
                                        height={20}
                                        width={20}
                                      />
                                    </span>
                                  </div>
                                  {userListVisible && (
                                    <ul
                                      className="data-list list-group settings_list_transfer"
                                      style={{ top: "2rem" }}
                                    >
                                      <div>
                                        <div className="inputContainer mt-1 p-1 w-100">
                                          <img
                                            src={Search}
                                            alt="search"
                                            className="searchIcon"
                                          />
                                          <input
                                            type="text"
                                            placeholder="Search users"
                                            value={search}
                                            onChange={(e) =>
                                              setSearch(e.target.value)
                                            }
                                            className="px-5 p-2 w-100"
                                            style={{
                                              outline: "none",
                                              borderRadius: "5px",
                                              border: "none",
                                            }}
                                          />
                                        </div>
                                      </div>
                                      {filteredUsers.length > 0 ? (
                                        filteredUsers
                                          .filter(
                                            (user) =>
                                              user.userId !== userInfo?.userId
                                          )
                                          .map((item, index) => (
                                            <li
                                              key={index}
                                              className="cursorPointer list-group-item"
                                              onClick={() =>
                                                handleUserListItemClick(item)
                                              }
                                            >
                                              <div className="d-flex gap-2 mt-1 w-100">
                                                <div>
                                                  <img
                                                    src={
                                                      item.userImageData ===
                                                      null
                                                        ? leadAvatar
                                                        : item.userImageData
                                                    }
                                                    alt="userImage"
                                                    style={{
                                                      width: "50px",
                                                      height: "50px",
                                                    }}
                                                  />
                                                </div>
                                                <div>
                                                  <div>{item.name}</div>
                                                  <div>{item.emailId}</div>
                                                </div>
                                              </div>
                                            </li>
                                          ))
                                      ) : (
                                        <li className="list-group-item">
                                          No users found
                                        </li>
                                      )}
                                    </ul>
                                  )}
                                </div>
                              )}
                            </div>
                            <div className="noteDelete p-2 mt-4">
                              <div className="noteText">Note :</div>
                              <div className="noteTextDetails">
                                If the deleted user has integrated any
                                additional application with this CRM account,
                                those integrations will be disable.
                              </div>
                              <div className="noteTextDetails">
                                Deleting this user will not reduce the number of
                                user licenses for which your company is billed,
                                if you need to reduce user licenses, see{" "}
                                <span
                                  style={{
                                    color: "#2177FE",
                                    fontSize: "16px",
                                  }}
                                >
                                  subscription
                                </span>
                                .
                              </div>
                            </div>

                            <div className="d-flex gap-3 align-items-center justify-content-end mt-4">
                              <button
                                className="p-2 px-4 cancelRecycle"
                                onClick={() => {
                                  setDeleteUserModal(false);
                                  setSelectedOptionDelete("delete");
                                }}
                              >
                                Cancel
                              </button>
                              <button
                                className="p-2 px-4 deleteButton"
                                onClick={deleteParticularUser}
                              >
                                Delete
                              </button>
                            </div>
                          </div>
                        </Modal.Body>
                      </Modal>
                    )}
                  </div>
                </div>
              </div>
            </div>
          )}
          {menuTab === "User" && activeTab === "activeUser" && (
            <div
              className="commonContainer_bg rounded-2 mt-4 ms-2"
              style={{ height: "83vh" }}
            >
              <div
                className="p-2"
                style={{
                  fontSize: "24px",
                  background:
                    "linear-gradient(to right, rgba(64, 123, 255, 1), rgba(18, 167, 254, 1))",
                  WebkitBackgroundClip: "text",
                  backgroundClip: "text",
                  WebkitTextFillColor: "transparent",
                }}
              >
                Activate Users
              </div>
              <div
                className="w-75 ms-2"
                style={{
                  color: "#515151",
                  fontSize: "16px",
                  fontWeight: "300",
                }}
              >
                This page allows you to activate and deactivate users. Note:
                Your organization cannot have more active users than user
                licenses.
              </div>
              <div
                className="bg-white rounded-2 ms-2 mt-4 me-2"
                style={{ height: "53vh", overflowY: "auto" }}
              >
                <div className="w-100 d-flex p-2" style={{ color: "#515151" }}>
                  <div
                    style={{ width: "15%" }}
                    className="d-flex justify-content-between me-2"
                  >
                    <div>Full Name</div>
                    <div style={{ color: "#E2E5EB" }}>|</div>
                  </div>
                  <div
                    style={{ width: "35%" }}
                    className="d-flex justify-content-between me-2"
                  >
                    <div>Email Address</div>
                    <div style={{ color: "#E2E5EB" }}>|</div>
                  </div>
                  <div
                    style={{ width: "20%" }}
                    className="d-flex justify-content-between me-2"
                  >
                    <div>Role</div>
                    <div style={{ color: "#E2E5EB" }}>|</div>
                  </div>
                  <div
                    style={{ width: "15%" }}
                    className="d-flex justify-content-between me-2"
                  >
                    <div>Profile</div>
                    <div style={{ color: "#E2E5EB" }}>|</div>
                  </div>

                  <div style={{ width: "15%" }}>User Status</div>
                </div>
                <div
                  style={{ borderBottom: "1px solid #515151" }}
                  className="ms-2 me-2"
                ></div>
                {initialAllUser.map((item, index) => (
                  <div key={index}>
                    <div className="d-flex gap-2 p-2">
                      <div
                        style={{
                          width: "15%",
                          fontSize: "14px",
                          color: "#515151",
                        }}
                        className="overflow_text"
                      >
                        {item.name}
                      </div>
                      <div
                        style={{
                          width: "35%",
                          fontSize: "14px",
                          color: "#529CF3",
                        }}
                        className="overflow_text"
                      >
                        {item.emailId}
                      </div>
                      <div
                        style={{
                          width: "20%",
                          fontSize: "14px",
                          color: "#515151",
                        }}
                        className="overflow_text"
                      >
                        {item.role}
                      </div>
                      <div
                        style={{
                          width: "15%",
                          fontSize: "14px",
                          color: "#529CF3",
                        }}
                        className="overflow_text"
                      >
                        {item.profile}
                      </div>
                      <ToggleSwitch
                        checked={item.userStatus === "Active" ? true : false}
                        onChange={() => {
                          getActiveInactiveUsers(item);
                        }}
                      />
                    </div>
                    {index !== initialUser.length - 1 && (
                      <div
                        style={{ borderBottom: "1px solid #515151" }}
                        className="ms-2 me-2"
                      ></div>
                    )}
                  </div>
                ))}
              </div>
            </div>
          )}
          {menuTab === "User" &&
            activeTab === "Group" &&
            showGroup === "group" && (
              <div className="commonContainer_bg rounded-2 mt-4 ms-2 groupClassActive">
                <div
                  className="p-2"
                  style={{
                    fontSize: "24px",
                    background:
                      "linear-gradient(to right, rgba(64, 123, 255, 1), rgba(18, 167, 254, 1))",
                    WebkitBackgroundClip: "text",
                    backgroundClip: "text",
                    WebkitTextFillColor: "transparent",
                  }}
                >
                  Groups
                </div>
                <div
                  className="w-75 ms-2"
                  style={{
                    color: "#515151",
                    fontSize: "16px",
                    fontWeight: "300",
                  }}
                >
                  This page lists all public groups created for your
                  organization.
                </div>
                <div className="d-flex justify-content-between mt-4 me-2">
                  <div className="inputContainer">
                    <img src={Search} alt="search" className="searchIcon" />
                    <input
                      type="text"
                      placeholder="Search"
                      value={searchGroup}
                      onChange={handleSearchGroupChange}
                      className="searchGroup px-5 w-75 p-2 ms-2"
                    />
                  </div>
                  <div>
                    <button
                      className="createContactButtonSave"
                      onClick={() => {
                        setCreateGroupModal(true);
                        setAddGroup({ groupName: "", groupDescription: "" });
                        setRenameGroup({ groupName: "", groupDescription: "" });
                        setEditRename(false);
                      }}
                    >
                      Create Group
                    </button>
                  </div>

                  {showAddMemberModal && (
                    <Modal
                      show={showAddMemberModal}
                      className="d-flex justify-content-center align-items-center"
                      aria-labelledby="example-custom-modal-styling-title"
                      size="xl"
                      id="commonLeadModal"
                      style={{ height: "auto" }}
                    >
                      <Modal.Body className="ModalBody overflow-auto">
                        <div className="w-100 ">
                          <div
                            className="ms-2"
                            style={{ fontSize: "18px", fontWeight: "bold" }}
                          >
                            Add Members to this Group &quot;{addGroup.groupName}
                            &quot;
                          </div>
                          <div className="mt-4 d-flex justify-content-between">
                            <Dropdown>
                              <Dropdown.Toggle id="dropdown-activity">
                                {selectedMemberName}
                              </Dropdown.Toggle>

                              <Dropdown.Menu>
                                <Dropdown.Item
                                  eventKey="Users"
                                  onClick={() => {
                                    handleSelectMember("users");
                                    getUserListActivate();
                                  }}
                                >
                                  Users
                                </Dropdown.Item>
                                <Dropdown.Item
                                  eventKey="Roles"
                                  onClick={() => {
                                    handleSelectMember("roles");
                                    getAllRolesListDetails();
                                  }}
                                >
                                  Roles
                                </Dropdown.Item>
                                <Dropdown.Item
                                  eventKey="Groups"
                                  onClick={() => {
                                    handleSelectMember("groups");
                                    getGroupListDetails();
                                  }}
                                >
                                  Groups
                                </Dropdown.Item>
                              </Dropdown.Menu>
                            </Dropdown>
                            <div className="inputContainer">
                              <img
                                src={Search}
                                alt="search"
                                className="searchIcon"
                              />
                              <input
                                type="text"
                                placeholder="Search"
                                value={searchUsersRolesGroups}
                                onChange={handleSearchUsersRolesGroupsChange}
                                className="searchGroup bg-white px-5 w-75 p-2 ms-2"
                              />
                            </div>
                          </div>
                          {selectMembers === "users" && (
                            <div
                              className="bg-white rounded-2 mt-4 membersListClass"
                              style={{
                                height: "50vh",
                                overflowX: "hidden",
                                overflowY: "auto",
                                width: "30vw",
                              }}
                            >
                              <div
                                className="w-100 d-flex p-2"
                                style={{ color: "#515151" }}
                              >
                                <div
                                  style={{ width: "50%" }}
                                  className="d-flex justify-content-between me-2"
                                >
                                  <div>Users</div>
                                  <div style={{ color: "#E2E5EB" }}>|</div>
                                </div>
                                <div
                                  style={{ width: "50%" }}
                                  className="d-flex justify-content-between me-2"
                                >
                                  <div>Email Address</div>
                                  <div style={{ color: "#E2E5EB" }}>|</div>
                                </div>
                              </div>
                              <div
                                style={{ borderBottom: "1px solid #515151" }}
                                className="ms-2 me-2"
                              ></div>
                              {filteredUsersNew.length === 0 ? (
                                <div className="d-flex p-2">
                                  <div
                                    style={{
                                      width: "100%",
                                      fontSize: "14px",
                                      color: "#515151",
                                      textAlign: "center",
                                    }}
                                  >
                                    No users found
                                  </div>
                                </div>
                              ) : (
                                filteredUsersNew.map((item, index) => (
                                  <div key={index}>
                                    <div className="d-flex p-2">
                                      <div
                                        style={{ width: "50%" }}
                                        className="d-flex gap-2"
                                      >
                                        <input
                                          type="checkbox"
                                          checked={
                                            checkedItemsUser[item.userId] ||
                                            false
                                          }
                                          onChange={() =>
                                            handleCheckboxChangeUser(item)
                                          }
                                        />
                                        <div
                                          className="overflow_text"
                                          style={{
                                            fontSize: "14px",
                                            color: "#515151",
                                          }}
                                        >
                                          {item.name}
                                        </div>
                                      </div>

                                      <div
                                        className="overflow_text"
                                        style={{
                                          width: "50%",
                                          fontSize: "14px",
                                          color: "#515151",
                                        }}
                                      >
                                        {item.emailId}
                                      </div>
                                    </div>
                                  </div>
                                ))
                              )}
                            </div>
                          )}

                          {selectMembers === "roles" && (
                            <div
                              className="bg-white rounded-2 mt-4 membersListClass"
                              style={{
                                height: "50vh",
                                overflowX: "hidden",
                                overflowY: "auto",
                                width: "30vw",
                              }}
                            >
                              <div
                                className="w-100 d-flex p-2"
                                style={{ color: "#515151" }}
                              >
                                <div
                                  style={{ width: "50%" }}
                                  className="d-flex justify-content-between me-2"
                                >
                                  <div>Roles</div>
                                  <div style={{ color: "#E2E5EB" }}>|</div>
                                </div>
                                <div
                                  style={{ width: "50%" }}
                                  className="d-flex justify-content-between me-2"
                                >
                                  <div>Subordinates</div>
                                  <div style={{ color: "#E2E5EB" }}>|</div>
                                </div>
                              </div>
                              <div
                                style={{ borderBottom: "1px solid #515151" }}
                                className="ms-2 me-2"
                              ></div>
                              {filteredRoles.length === 0 ? (
                                <div className="p-2">
                                  <div
                                    style={{
                                      fontSize: "14px",
                                      color: "#515151",
                                      textAlign: "center",
                                    }}
                                  >
                                    No roles found
                                  </div>
                                </div>
                              ) : (
                                filteredRoles.map((item, index) => (
                                  <div key={index}>
                                    <div className="d-flex gap-2 p-2">
                                      <div
                                        style={{ width: "50%" }}
                                        className="d-flex gap-2"
                                      >
                                        <input
                                          type="checkbox"
                                          checked={
                                            checkedItemsRoles[item.roleId] ||
                                            false
                                          }
                                          onChange={() =>
                                            handleCheckboxChangeRole(item)
                                          }
                                        />
                                        <div
                                          className="overflow_text"
                                          style={{
                                            fontSize: "14px",
                                            color: "#515151",
                                          }}
                                        >
                                          {item.roleName}
                                        </div>
                                      </div>
                                      <div
                                        style={{
                                          width: "50%",
                                          fontSize: "14px",
                                          color: "#515151",
                                        }}
                                        className="d-flex justify-content-center"
                                      >
                                        <input
                                          type="checkbox"
                                          style={{ opacity: "0.7" }}
                                          disabled={true}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                ))
                              )}
                            </div>
                          )}

                          {selectMembers === "groups" && (
                            <div
                              className="bg-white rounded-2 mt-4 membersListClass"
                              style={{
                                height: "50vh",
                                overflowX: "hidden",
                                overflowY: "auto",
                                width: "30vw",
                              }}
                            >
                              <div
                                className="w-100 d-flex p-2"
                                style={{ color: "#515151" }}
                              >
                                <div
                                  style={{ width: "50%" }}
                                  className="d-flex justify-content-between me-2"
                                >
                                  <div>Groups</div>
                                  <div style={{ color: "#E2E5EB" }}>|</div>
                                </div>
                                <div
                                  style={{ width: "50%" }}
                                  className="d-flex justify-content-between me-2"
                                >
                                  <div>Members</div>
                                  <div style={{ color: "#E2E5EB" }}>|</div>
                                </div>
                              </div>
                              <div
                                style={{ borderBottom: "1px solid #515151" }}
                                className="ms-2 me-2"
                              ></div>
                              {filteredGroups.length === 0 ? (
                                <div className="p-2">
                                  <div
                                    style={{
                                      fontSize: "14px",
                                      color: "#515151",
                                      textAlign: "center",
                                    }}
                                  >
                                    No groups found
                                  </div>
                                </div>
                              ) : (
                                filteredGroups.map((item, index) => (
                                  <div key={index}>
                                    <div className="d-flex  p-2">
                                      <div
                                        style={{ width: "50%" }}
                                        className="d-flex gap-2"
                                      >
                                        <input
                                          type="checkbox"
                                          checked={
                                            checkedItemsGroups[item.groupId] ||
                                            false
                                          }
                                          onChange={() =>
                                            handleCheckboxChangeGroup(item)
                                          }
                                        />
                                        <div
                                          className="overflow_text"
                                          style={{
                                            fontSize: "14px",
                                            color: "#515151",
                                          }}
                                        >
                                          {item.groupName}
                                        </div>
                                      </div>
                                      <div
                                        className="overflow_text"
                                        style={{
                                          width: "50%",
                                          fontSize: "14px",
                                          color: "#515151",
                                        }}
                                      >
                                        {item.members}
                                      </div>
                                    </div>
                                  </div>
                                ))
                              )}
                            </div>
                          )}

                          <div className="d-flex gap-2 align-items-center justify-content-end">
                            <button
                              onClick={() => {
                                setShowAddMemberModal(false);
                              }}
                              className="cancelRecycle px-4 mt-4 d-flex justify-content-center align-items-center"
                            >
                              Cancel
                            </button>
                            <button
                              onClick={() => {
                                handleButtonClickUser();
                                handleCloseGroupItems();
                              }}
                              className="deleteButton px-4 mt-4 d-flex justify-content-center align-items-center"
                            >
                              Save
                            </button>
                          </div>
                        </div>
                      </Modal.Body>
                    </Modal>
                  )}
                </div>
                <div className="bg-white rounded-2 ms-2 mt-4 me-2 responsive-div ">
                  <div
                    className="w-100 d-flex p-2"
                    style={{ color: "#515151" }}
                  >
                    <div
                      style={{ width: "25%" }}
                      className="d-flex justify-content-between me-2"
                    >
                      <div>Group Name </div>
                      <div style={{ color: "#E2E5EB" }}>|</div>
                    </div>
                    <div
                      style={{ width: "25%" }}
                      className="d-flex justify-content-between me-2"
                    >
                      <div>Members</div>
                      <div style={{ color: "#E2E5EB" }}>|</div>
                    </div>
                    <div
                      style={{ width: "25%" }}
                      className="d-flex justify-content-between me-2"
                    >
                      <div>Created By</div>
                      <div style={{ color: "#E2E5EB" }}>|</div>
                    </div>
                    <div style={{ width: "25%" }}>
                      <div>Modified By</div>
                    </div>
                  </div>
                  <div
                    style={{ borderBottom: "1px solid #515151" }}
                    className="ms-2 me-2"
                  ></div>
                  {filteredGroupName.length > 0 ? (
                    filteredGroupName.map((item, index) => (
                      <div key={index}>
                        <div
                          className="d-flex gap-2 p-2 position-relative"
                          onMouseEnter={() => handleRowMouseEnter(index)}
                          // onMouseLeave={handleRowMouseLeave}
                        >
                          <div
                            className="d-flex gap-2 overflow_text"
                            style={{
                              width: "25%",
                              cursor: "pointer",
                            }}
                          >
                            <div
                              onClick={() => {
                                setShowGroup("developer");
                                setGroupIdInvidual(item.groupId);
                                setGroupName(item);
                                getAllMembersList("users", item.groupId);
                              }}
                            >
                              <span
                                style={{ fontSize: "14px", color: "#529CF3" }}
                              >
                                {item.groupName}
                              </span>
                            </div>
                            <div>
                              {hoveredRow === index && (
                                <div
                                  className="position-absolute"
                                  style={{ zIndex: "10" }}
                                >
                                  <img
                                    src={groupIcon}
                                    alt="cancel"
                                    style={{
                                      width: "20px",
                                      height: "20px",
                                      cursor: "pointer",
                                    }}
                                    onClick={() => handleIconClick(index)}
                                  />
                                  {showGroupItems && selectedRow === index && (
                                    <div
                                      key={index}
                                      className="rounded-1 p-2"
                                      style={{
                                        backgroundColor: "#ffffff",
                                        color: "#515151",
                                        zIndex: "10",
                                        border: "1px solid #515151",
                                      }}
                                    >
                                      <div
                                        className="mt-1"
                                        onClick={() => {
                                          setShowGroup("developer");
                                          getAllMembersList(
                                            "users",
                                            item.groupId
                                          );
                                          setGroupIdInvidual(item.groupId);
                                          setGroupName(item);
                                          handleCloseGroupItems();
                                          setEditRename(true);
                                        }}
                                      >
                                        Edit
                                      </div>
                                      <div
                                        className="mt-1"
                                        onClick={() => {
                                          handleRenameClick(index);
                                        }}
                                      >
                                        Rename
                                      </div>
                                      <div
                                        className="mt-1"
                                        onClick={() => {
                                          setDeleteGroup(true);
                                        }}
                                      >
                                        Delete
                                      </div>

                                      {deleteGroup && (
                                        <Modal
                                          show={deleteGroup}
                                          className="d-flex justify-content-center align-items-center"
                                          aria-labelledby="example-custom-modal-styling-title"
                                          size="xl"
                                          id="commonLeadModal"
                                        >
                                          <Modal.Body className="ModalBody overflow-auto">
                                            <div className="w-100 ">
                                              <div className=" text-center">
                                                Are you sure you want to delete
                                                this group {item.groupName} ?
                                              </div>
                                              <div className="d-flex gap-4 align-items-center justify-content-end">
                                                <button
                                                  onClick={() => {
                                                    setDeleteGroup(false);
                                                    handleCloseGroupItems();
                                                  }}
                                                  className="cancelRecycle px-4 mt-4 d-flex justify-content-center align-items-center"
                                                >
                                                  Cancel
                                                </button>
                                                <button
                                                  onClick={() => {
                                                    deleteGroupDetils(
                                                      item.groupId
                                                    );
                                                    handleCloseGroupItems();
                                                  }}
                                                  className="deleteButton px-4 mt-4 d-flex justify-content-center align-items-center"
                                                >
                                                  OK
                                                </button>
                                              </div>
                                            </div>
                                          </Modal.Body>
                                        </Modal>
                                      )}
                                    </div>
                                  )}
                                </div>
                              )}
                            </div>
                          </div>
                          <div
                            style={{
                              width: "25%",
                              fontSize: "14px",
                              color: "#515151",
                            }}
                          >
                            {item.members}
                          </div>
                          <div
                            style={{
                              width: "25%",
                              fontSize: "14px",
                              color: "#515151",
                            }}
                          >
                            {item.createdBy}
                          </div>
                          <div
                            style={{
                              width: "25%",
                              fontSize: "14px",
                              color: "#515151",
                            }}
                          >
                            {item.modifiedBy}
                          </div>
                        </div>
                        {index !== groupListData.length - 1 && (
                          <div
                            style={{ borderBottom: "1px solid #515151" }}
                            className="ms-2 me-2"
                          ></div>
                        )}
                      </div>
                    ))
                  ) : (
                    <div className="text-center mt-5">
                      <p>No groups found</p>
                    </div>
                  )}
                </div>
              </div>
            )}

          {menuTab === "User" &&
            activeTab === "Group" &&
            showGroup === "developer" &&
            developerGroup()}
        </div>
      </>
    );
  };

  useEffect(() => {
    if (
      isLoginHistoryClicked &&
      ((selectActivity === "Specific Date" && specificDate) ||
        (selectActivity === "Custom Range" && fromDate && toDate) ||
        (selectActivity !== "Specific Date" &&
          selectActivity !== "Custom Range"))
    ) {
      getLoginActivityDetails();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [specificDate, fromDate, toDate, selectActivity, isLoginHistoryClicked]);

  const handleButtonClick = (action) => {
    const isAnyChecked = Object.values(checkedItems).some((value) => value);
    if (!isAnyChecked) {
      setIsModalVisible(true);
    } else {
      if (action === "restore") {
        deleteRestoreData(action);
        setCheckedItems({});
      } else if (action === "delete") {
        setDeleteConfirmationModal(true);
      }
    }
  };

  const handleButtonClickDeleteUsers = () => {
    const isAnydeleteUserChecked = Object.values(selectedUsers).some(
      (value) => value
    );
    if (isAnydeleteUserChecked) {
      deleteUsersMember();
    } else {
      alert("No items are checked");
    }
  };

  const handleButtonClickDeleteRoles = () => {
    const isAnydeleteUserChecked = Object.values(selectedRoles).some(
      (value) => value
    );
    if (isAnydeleteUserChecked) {
      deleteRolesMember();
    } else {
      alert("No items are checked");
    }
  };

  const handleButtonClickDeleteGroups = () => {
    const isAnydeleteUserChecked = Object.values(selectedGroups).some(
      (value) => value
    );
    if (isAnydeleteUserChecked) {
      deleteGroupsMember();
    } else {
      alert("No items are checked");
    }
  };

  const confirmDelete = () => {
    deleteRestoreData("delete");
  };

  const loginHistory = () => {
    return (
      <div>
        <div
          className="bg-white rounded-2 p-1"
          style={{ height: "100vh", width: "63vw" }}
        >
          <div className="ms-4 d-flex justify-content-between align-items-center me-1">
            <div>
              <div>Sign-in Activity</div>
            </div>
            <div className="d-flex gap-2 ">
              <div>
                {selectActivity === "Specific Date" && (
                  <input
                    type="date"
                    value={specificDate}
                    className="settingsDate p-1"
                    onChange={(e) => setSpecificDate(e.target.value)}
                    max={getTodayDate()}
                    onKeyDown={(e) => e.preventDefault()}
                  />
                )}
                {selectActivity === "Custom Range" && (
                  <div className="d-flex gap-2 align-items-center">
                    <div>From</div>
                    <input
                      type="date"
                      value={fromDate}
                      className="settingsDate p-1"
                      onChange={(e) => setFromDate(e.target.value)}
                      max={toDate}
                      onKeyDown={(e) => e.preventDefault()}
                    />
                    <div>To</div>
                    <input
                      type="date"
                      value={toDate}
                      className="settingsDate p-1"
                      onChange={(e) => setToDate(e.target.value)}
                      min={fromDate}
                      max={getTodayDate()}
                      onKeyDown={(e) => e.preventDefault()}
                    />
                  </div>
                )}
              </div>

              <Dropdown>
                <Dropdown.Toggle id="dropdown-activity">
                  {selectActivity}
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Item onClick={() => handleSelectActivity("Today")}>
                    Today
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={() => handleSelectActivity("Yesterday")}
                  >
                    Yesterday
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={() => handleSelectActivity("Last 7 Days")}
                  >
                    Last 7 Days
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={() => {
                      handleSelectActivity("Specific Date");
                      handleSpecificRangeChange();
                    }}
                  >
                    Specific Date
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={() => {
                      handleSelectActivity("Custom Range");
                      handleCustomRangeChange();
                    }}
                  >
                    Custom Range
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </div>

          {errorMessage ? (
            <div
              className="loginGraph ms-4 mt-2"
              style={{
                height: "35vh",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <p>{errorMessage}</p>
            </div>
          ) : getLoginChartData.length === 0 ? (
            <div
              className="loginGraph ms-4"
              style={{
                height: "35vh",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <p>No activity details found</p>
            </div>
          ) : (
            <div className="loginGraph ms-4 mt-2" style={{ height: "35vh" }}>
              <SplineAreaChart data={getLoginChartData} />
            </div>
          )}

          <div className="mt-2 d-flex justify-content-end">
            <div className="activityContent d-flex gap-2 p-1 me-2">
              <div
                className={`p-1 ${
                  allActivity === "All Activity"
                    ? "activeLogin"
                    : "inActiveLogin"
                }`}
                onClick={() => {
                  setAllActivity("All Activity");
                }}
              >
                All Activity
              </div>
              <div
                className={`p-1 ${
                  allActivity === "Activity by Users"
                    ? "activeLogin"
                    : "inActiveLogin"
                }`}
                onClick={() => {
                  setAllActivity("Activity by Users");
                  setSearchLoginActivity("");
                }}
              >
                All Activity By User
              </div>
            </div>
          </div>
          <div
            className="mt-2 d-flex justify-content-between ms-4 me-2 p-2 rounded-2"
            style={{ backgroundColor: "#f5f5f5" }}
          >
            <div className="d-flex">
              Show Underlying data{" "}
              {searchLoginActivity.length > 1 && (
                <div className="d-flex gap-1">
                  <span
                    className="ms-2 py-1 px-2"
                    style={{ backgroundColor: "#FFDEE9", borderRadius: "25px" }}
                  >
                    {searchLoginActivity}
                    <img
                      src={CrossIcon}
                      alt="cross"
                      onClick={() => setSearchLoginActivity("")}
                      className="ms-1 cursorPointer"
                      style={{ color: "#888" }}
                    />
                  </span>
                </div>
              )}
            </div>
            <div>
              Total Count
              {allActivity === "All Activity" && searchLoginActivity === "" ? (
                <span
                  style={{
                    backgroundColor: "#3260AA",
                    borderRadius: "30px",
                    color: "#ffffff",
                  }}
                  className="px-2 ms-1"
                >
                  {getLoginChartData.length > 0
                    ? getLoginChartData.reduce(
                        (acc, current) => acc + current.signInCount,
                        0
                      )
                    : 0}
                </span>
              ) : (
                <span
                  style={{
                    backgroundColor: "#3260AA",
                    borderRadius: "30px",
                    color: "#ffffff",
                  }}
                  className="px-2 ms-1"
                >
                  {loginActivityUserData.length > 0
                    ? loginActivityUserData.reduce(
                        (acc, current) => acc + current.totalSignInCount,
                        0
                      )
                    : 0}
                </span>
              )}
            </div>
          </div>

          {allActivity === "All Activity" && (
            <div className="loginGraph ms-4">
              <div
                className="p-2 d-flex w-100"
                style={{
                  color: "#515151",
                  fontSize: "17px",
                  fontWeight: "500",
                }}
              >
                <div style={{ width: "33%" }}>Name/Email Address</div>
                <div style={{ width: "33%" }}>Time/Location</div>
                <div style={{ width: "33%" }}> Device Info</div>
              </div>
              <div
                style={{ borderBottom: "1px solid #E2E5EB" }}
                className="ms-2 me-2"
              ></div>
              <div>
                {getLoginActivityData.length > 0 ? (
                  getLoginActivityData.map((item, index) => {
                    const [timeDiff, location] =
                      item.locationWithTimeDiff.includes(",")
                        ? item.locationWithTimeDiff.split(",")
                        : [item.locationWithTimeDiff, ""];
                    return (
                      <div
                        key={index}
                        className="mt-4 ms-2 w-100 d-flex loginActivity p1"
                      >
                        <div
                          className="d-flex gap-2 align-items-center cursorPointer"
                          style={{ width: "33%" }}
                          onClick={() => {
                            setSearchLoginActivity(item.loginEmailId);
                          }}
                        >
                          <div>
                            <img src={loginHistoryImage} alt="loginHistory" />
                          </div>
                          <div>
                            <div
                              className="overflow_text"
                              style={{
                                fontSize: "16px",
                                color: "#515151",
                                fontWeight: "500",
                              }}
                            >
                              {item.loginName}
                            </div>
                            <div
                              className="overflow_text"
                              style={{
                                fontSize: "12px",
                                color: "#515151",
                                fontWeight: "300",
                              }}
                            >
                              {item.loginEmailId}
                            </div>
                          </div>
                        </div>
                        <div
                          className="overflow_text"
                          style={{
                            width: "33%",
                            color: "#5F5F5F",
                            fontSize: "14px",
                          }}
                        >
                          <div>{timeDiff}</div>
                          <div>{location}</div>
                        </div>
                        <div
                          className="d-flex gap-2 mt-2"
                          style={{ width: "33%", color: "#5F5F5F" }}
                        >
                          <div>{item.loginIpAddress}</div>
                          <div>
                            {item.loginBrowser === "Chrome" && (
                              <img src={Chrome} alt="chrome" />
                            )}
                          </div>
                          <div>
                            {item.loginDeviceType === "Web" && (
                              <img src={Desktop} alt="desktop" />
                            )}
                          </div>
                        </div>
                        <div
                          style={{ borderBottom: "1px solid #E2E5EB" }}
                          className="ms-2 me-2 mt-2"
                        >
                          {" "}
                        </div>
                      </div>
                    );
                  })
                ) : (
                  <div
                    className="mt-4 ms-2 w-100 d-flex loginActivity justify-content-center align-items-center p1"
                    style={{
                      color: "#515151",
                      fontSize: "16px",
                      textAlign: "center",
                      height: "30vh",
                    }}
                  >
                    No data found
                  </div>
                )}
              </div>
            </div>
          )}

          {allActivity === "Activity by Users" && (
            <div className="loginGraph ms-4">
              <div
                className="p-2 d-flex w-100"
                style={{
                  color: "#515151",
                  fontSize: "17px",
                  fontWeight: "500",
                }}
              >
                <div style={{ width: "50%" }}>Name/Email Address</div>
                <div style={{ width: "50%" }}>Total Sign In Count</div>
              </div>
              <div
                style={{ borderBottom: "1px solid #E2E5EB" }}
                className="ms-2 me-2"
              ></div>
              <div>
                {loginActivityUserData.length > 0 ? (
                  loginActivityUserData.map((item, index) => {
                    return (
                      <div
                        key={index}
                        className="mt-4 ms-2 w-100 d-flex loginActivity p1"
                      >
                        <div
                          className="d-flex gap-2 align-items-center cursorPointer"
                          style={{ width: "100%" }}
                          onClick={() => {
                            setAllActivity("All Activity");
                            setSearchLoginActivity(item.loginEmailId);
                          }}
                        >
                          <div>
                            <div
                              style={{
                                fontSize: "16px",
                                color: "#515151",
                                fontWeight: "500",
                              }}
                            >
                              {item.loginName}
                            </div>
                            <div
                              style={{
                                fontSize: "12px",
                                color: "#515151",
                                fontWeight: "300",
                              }}
                            >
                              {item.loginEmailId}
                            </div>
                          </div>
                        </div>
                        <div
                          style={{
                            width: "100%",
                            color: "#5F5F5F",
                            fontSize: "14px",
                          }}
                          className="d-flex ms-4"
                        >
                          <div>{item.totalSignInCount}</div>
                        </div>
                        <div
                          style={{ borderBottom: "1px solid #E2E5EB" }}
                          className="ms-2 me-2 mt-2"
                        >
                          {" "}
                        </div>
                      </div>
                    );
                  })
                ) : (
                  <div
                    className="mt-4 ms-2 w-100 d-flex loginActivity justify-content-center align-items-center p1"
                    style={{
                      color: "#515151",
                      fontSize: "16px",
                      textAlign: "center",
                      height: "20vh",
                    }}
                  >
                    No data found
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    );
  };

  const recycleBinHistory = () => {
    return (
      <div
        className="bg-white rounded-1 p-2"
        style={{ height: "auto", width: "63vw" }}
      >
        <div
          className="commonContainer_bg rounded-2 ms-2 mt-1"
          style={{ height: "95vh", width: "61vw" }}
        >
          <div
            className="p-2"
            style={{
              fontSize: "24px",
              background:
                "linear-gradient(to right, rgba(64, 123, 255, 1), rgba(18, 167, 254, 1))",
              WebkitBackgroundClip: "text",
              backgroundClip: "text",
              WebkitTextFillColor: "transparent",
            }}
          >
            Recycle Bin
          </div>
          <div
            className="w-75 ms-4"
            style={{ color: "#515151", fontSize: "16px", fontWeight: "300" }}
          >
            {" "}
            The Recycle Bin displays all the records you have access to that
            have been deleted in your Chain CRM account.
          </div>
          <div
            className="mt-2 ms-4 w-75"
            style={{ color: "#515151", fontSize: "15px", fontWeight: "300" }}
          >
            <div className="d-flex gap-2 align-items-end">
              <div style={{ fontSize: "30px" }}>.</div>
              <div className="mt-1">
                After records are deleted, they will be stored in the Recycle
                Bin for 60 days. After that, they will be deleted permanently.
              </div>
            </div>
            <div className="d-flex gap-2 align-items-center">
              <div style={{ fontSize: "30px" }}>.</div>
              <div className="mt-1">
                {" "}
                Only users with an administrator profile can delete records in
                the Recycle Bin.
              </div>
            </div>
            <div className="d-flex gap-2 align-items-center">
              <div style={{ fontSize: "30px" }}>.</div>
              <div className="mt-1">
                Non-admin users can restore their own records from the Recycle
                Bin. Depending on their permissions, they may also be able to
                restore other users&apos; records as well.
              </div>
            </div>
          </div>
          <div className="d-flex justify-content-between ms-4 me-4 mt-4">
            <div className="d-flex gap-2">
              <button
                className="p-1 cancelRecycle px-4"
                onClick={() => handleButtonClick("restore")}
              >
                Restore
              </button>
              <button
                className="p-1 px-4 deleteButton"
                onClick={() => handleButtonClick("delete")}
              >
                Delete
              </button>
            </div>
            {deleteConfirmationModal && (
              <Modal
                show={deleteConfirmationModal}
                className="d-flex justify-content-center align-items-center"
                aria-labelledby="example-custom-modal-styling-title"
                size="lg"
                id="commonLeadModal"
              >
                <Modal.Header>
                  <div className="d-flex justify-content-between w-100">
                    <div
                      style={{
                        fontSize: "25px",
                        fontWeight: "bold",
                        background:
                          "linear-gradient(to right, rgba(64, 123, 255, 1), rgba(18, 167, 254, 1))",
                        WebkitBackgroundClip: "text",
                        backgroundClip: "text",
                        WebkitTextFillColor: "transparent",
                      }}
                    >
                      Permanently Delete Records
                    </div>
                    <img
                      src={Cancel}
                      alt="cancel"
                      className="cursorPointer"
                      onClick={() => {
                        setDeleteConfirmationModal(false);
                      }}
                    />
                  </div>
                </Modal.Header>
                <Modal.Body className="ModalBody overflow-auto">
                  <div className="w-100 ">
                    <div
                      style={{
                        color: "#9C9C9C",
                        fontSize: "22px",
                        fontWeight: "300",
                      }}
                      className="w-75"
                    >
                      The selected records and their related entities will be
                      permanently deleted
                    </div>
                    <div
                      style={{ backgroundColor: "#E4F5FF" }}
                      className="p-2 mt-2"
                    >
                      <div
                        style={{
                          color: "#515151",
                          fontWeight: "500",
                          fontSize: "20px",
                        }}
                      >
                        Note:
                      </div>
                      <div
                        style={{
                          color: "#9C9C9C",
                          fontWeight: "300",
                          fontSize: "20px",
                        }}
                      >
                        Deletion of the selected records will be delayed if
                        multiple related entities are associated with them.
                      </div>
                    </div>
                    <div
                      style={{
                        color: "#9C9C9C",
                        fontSize: "22px",
                        fontWeight: "300",
                      }}
                      className="w-75 mt-2"
                    >
                      Are you sure you want to permanently delete the records?{" "}
                    </div>
                    <div className="d-flex gap-3 align-items-center justify-content-end mt-4">
                      <button
                        className="p-1 px-4 cancelRecycle"
                        onClick={() => setDeleteConfirmationModal(false)}
                      >
                        Cancel
                      </button>
                      <button
                        className="p-1 px-4 deleteButton"
                        onClick={confirmDelete}
                        disabled={apiLoader}
                        style={{ width: "98px" }}
                      >
                        {apiLoader ? (
                          <Lottie
                            options={customDefaultOptions("white")}
                            height="24px"
                            width="36px"
                          />
                        ) : (
                          "Delete"
                        )}
                      </button>
                    </div>
                  </div>
                </Modal.Body>
              </Modal>
            )}
            {isModalVisible && (
              <Modal
                show={isModalVisible}
                className="d-flex justify-content-center align-items-center"
                aria-labelledby="example-custom-modal-styling-title"
                size="xl"
                id="commonLeadModal"
              >
                <Modal.Body className="ModalBody overflow-auto">
                  <div className="w-100 ">
                    <div className=" text-center">
                      Select at least one record
                    </div>
                    <div className="d-flex align-items-center justify-content-center">
                      <button
                        onClick={() => setIsModalVisible(false)}
                        className="createContactButtonSave mt-4 d-flex justify-content-center align-items-center"
                      >
                        OK, got it!
                      </button>
                    </div>
                  </div>
                </Modal.Body>
              </Modal>
            )}
            <div>
              {recycleBinHistoryData.length > 0 && (
                <button
                  className="deleteButton p-2"
                  onClick={() => {
                    setRecycleModalShow(true);
                  }}
                >
                  Empty Recycle Bin
                </button>
              )}

              {recycleModalShow && (
                <Modal
                  show={recycleModalShow}
                  className="d-flex justify-content-center align-items-center"
                  aria-labelledby="example-custom-modal-styling-title"
                  size="lg"
                >
                  <Modal.Body className="ModalBody overflow-auto">
                    <div className="w-100 ">
                      <div style={{ fontSize: "22px", fontWeight: "400" }}>
                        Entities in the recycle bin and their related items will
                        be permanently deleted. Do you want to continue?
                      </div>
                      <div className="d-flex gap-3 align-items-center justify-content-end mt-4">
                        <button
                          className="p-1 px-4 cancelRecycle"
                          onClick={() => setRecycleModalShow(false)}
                        >
                          Cancel
                        </button>
                        <button
                          className="p-1 px-4 deleteButton"
                          onClick={() => emptyAllRecycleBin()}
                          disabled={apiLoader}
                          style={{ width: "98px" }}
                        >
                          {apiLoader ? (
                            <Lottie
                              options={customDefaultOptions("white")}
                              height="24px"
                              width="36px"
                            />
                          ) : (
                            "OK"
                          )}
                        </button>
                      </div>
                    </div>
                  </Modal.Body>
                </Modal>
              )}
            </div>
          </div>
          <div className="bg-white rounded-2 ms-2 mt-3 me-2 recycleBinDetails">
            <div className="w-100 d-flex p-2" style={{ color: "#515151" }}>
              <div
                style={{ width: "25%" }}
                className="d-flex justify-content-between me-2"
              >
                <div>Name</div>
                <div style={{ color: "#E2E5EB" }}>|</div>
              </div>
              <div
                style={{ width: "25%" }}
                className="d-flex justify-content-between me-2"
              >
                <div>Type</div>
                <div style={{ color: "#E2E5EB" }}>|</div>
              </div>
              <div
                style={{ width: "25%" }}
                className="d-flex justify-content-between me-2"
              >
                <div>Deleted By</div>
                <div style={{ color: "#E2E5EB" }}>|</div>
              </div>
              <div
                style={{ width: "25%" }}
                className="d-flex justify-content-between me-2"
              >
                <div>Deleted Time</div>
                <div style={{ color: "#E2E5EB" }}>|</div>
              </div>
            </div>
            <div
              style={{ borderBottom: "1px solid #515151" }}
              className="ms-2 me-2"
            ></div>
            {recycleBinHistoryData.length === 0 ? (
              <div
                className="d-flex align-items-center justify-content-center mt-4"
                style={{ height: "30vh" }}
              >
                No Recycle bin details found
              </div>
            ) : (
              recycleBinHistoryData.map((item, index) => (
                <div key={index}>
                  <div className="d-flex gap-2 p-2">
                    <div
                      className="d-flex gap-2 overflow_text"
                      style={{ width: "25%" }}
                    >
                      <input
                        type="checkbox"
                        checked={checkedItems[item.moduleId] || false}
                        onChange={() => handleCheckboxChange(item)}
                      />
                      <div style={{ fontSize: "14px", color: "#515151" }}>
                        {item.name}
                      </div>
                    </div>
                    <div
                      style={{
                        width: "25%",
                        fontSize: "14px",
                        color: "#529CF3",
                      }}
                    >
                      {item.type}
                    </div>
                    <div
                      style={{
                        width: "25%",
                        fontSize: "14px",
                        color: "#515151",
                      }}
                    >
                      {item.deletedBy}
                    </div>
                    <div
                      style={{
                        width: "25%",
                        fontSize: "14px",
                        color: "#529CF3",
                      }}
                    >
                      {item.deletedTime}
                    </div>
                  </div>
                  {index !== recycleBinHistoryData.length - 1 && (
                    <div
                      style={{ borderBottom: "1px solid #515151" }}
                      className="ms-2 me-2"
                    ></div>
                  )}
                </div>
              ))
            )}
          </div>
        </div>
      </div>
    );
  };

  const storageHistory = () => {
    return (
      <div
        className="bg-white rounded-1 p-2"
        style={{ height: "auto", width: "63vw" }}
      >
        <div className="mt-2 ms-2 d-flex gap-4 justify-content-between px-2 bg-white px-1  rounded-5 py-1 widthContainerData">
          <span
            className={`cursorPointer ${
              dataPageView === "dataStorage"
                ? "overviewTimelineTab "
                : "  d-flex justify-content-center align-items-center"
            }`}
            onClick={() => {
              setDataPageView("dataStorage");
              getStorageData();
            }}
          >
            Data Storage
          </span>
          <span
            className={`cursorPointer ${
              dataPageView === "fileStorage"
                ? "overviewTimelineTab"
                : " d-flex justify-content-center align-items-center"
            }`}
            onClick={() => {
              setDataPageView("fileStorage");
              getStorageFileData();
            }}
          >
            File Storage
          </span>
        </div>
        <div
          className="commonContainer_bg rounded-2 ms-3 mt-3"
          style={{ height: "auto", width: "61vw" }}
        >
          {dataPageView === "dataStorage" ? (
            <div
              className="p-3"
              style={{ height: "82vh", overflowY: "scroll" }}
            >
              <div className="storageButton ms-2">
                Displaying the total storage space used by the organization.
                Data storage includes records across all modules and notes.
                Total used storage includes data present in the recycle bin.
              </div>
              <div className="mt-4 ms-2 px-2 bg-white px-1  rounded-3 py-1 d-flex flex-column recordContainer">
                <p
                  className="mt-2 ms-2"
                  style={{ fontWeight: "400", fontSize: "20px" }}
                >
                  <span style={{ color: "#515151" }}>
                    {storageData.recordsAvailable} Records available{" "}
                  </span>
                  <span style={{ color: "#9C9C9C" }}>
                    of {storageData.maxRecords} Records
                  </span>
                </p>
                <div
                  className="ms-2 mb-4 d-flex"
                  style={{
                    backgroundColor: "#E3EEFF",
                    width: "90%",
                    height: "40px",
                  }}
                >
                  <div
                    className="h-100"
                    style={{
                      position: "relative",
                      width: `${
                        (storageData.recordsAvailable /
                          storageData.maxRecords) *
                        100
                      }%`,
                      borderRight: "2px solid #9C9C9C",
                    }}
                  >
                    <div
                      style={{
                        position: "absolute",
                        top: 0,
                        bottom: 0,
                        left: 0,
                        right: 0,
                        background:
                          "linear-gradient(86.07deg, #407BFF 0%, #12A7FE 100%)",
                        height: "70%",
                        alignSelf: "center",
                      }}
                    ></div>
                  </div>
                </div>
              </div>
              <p
                className="mt-4 ms-2"
                style={{
                  fontWeight: "400",
                  fontSize: "20px",
                  color: "#515151",
                }}
              >
                Usage Details
              </p>
              <div
                className="mt-2 ms-2 px-2 bg-white px-1  rounded-3 py-1 d-flex flex-column recordContainer list-container"
                style={{ height: "300px", overflowY: "scroll" }}
              >
                <table className="table mt-0">
                  <thead>
                    <tr>
                      <th scope="col">Module</th>
                      <th scope="col">Record Count</th>
                      <th scope="col">Storage</th>
                    </tr>
                  </thead>
                  <tbody>
                    {storageData?.storageModule?.map((item) => {
                      return (
                        <tr key={item.moduleName}>
                          <td
                            className="cursorPointer"
                            style={{ color: "#529CF3" }}
                            onClick={() => {
                              setStorageModal(true);
                              setSelectedStorageModule(item.moduleName);
                              getStorageFileDataPopup(item.moduleName);
                            }}
                          >
                            {camelCaseToSpace(item.moduleName)}
                          </td>
                          <td className="text-start">{item.recordsCount}</td>
                          <td>{item.storage}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          ) : (
            <div
              className="p-3"
              style={{ height: "82vh", overflowY: "scroll" }}
            >
              <div className="storageButton ms-2">
                Displaying the total storage space used by the organization.
                Data storage includes records across all modules and notes.
                Total used storage includes data present in the recycle bin.
              </div>
              <div className="mt-4 ms-2 px-2 bg-white px-1  rounded-3 py-1 d-flex flex-column recordContainer">
                <p
                  className="mt-2 ms-2"
                  style={{ fontWeight: "400", fontSize: "20px" }}
                >
                  <span style={{ color: "#515151" }}>
                    {storageFileData.recordsAvailable} available{" "}
                  </span>
                  <span style={{ color: "#9C9C9C" }}>
                    of {storageFileData.maxRecords}
                  </span>
                </p>
                <div
                  className="ms-2 mb-4 d-flex"
                  style={{
                    backgroundColor: "#E3EEFF",
                    width: "90%",
                    height: "40px",
                  }}
                >
                  <div
                    className="h-100"
                    style={{
                      position: "relative",
                      width: `${
                        storageFileData.recordsAvailableMb ===
                        storageFileData.maxRecordsMb
                          ? "100%"
                          : `${
                              (storageFileData.recordsAvailableMb /
                                storageFileData.maxRecordsMb) *
                              100
                            }%`
                      } `,
                      borderRight: "2px solid #9C9C9C",
                    }}
                  >
                    <div
                      style={{
                        position: "absolute",
                        top: 0,
                        bottom: 0,
                        left: 0,
                        right: 0,
                        background:
                          "linear-gradient(86.07deg, #407BFF 0%, #12A7FE 100%)",
                        height: "70%",
                        alignSelf: "center",
                      }}
                    ></div>
                  </div>
                </div>
              </div>
              <p
                className="mt-4 ms-2"
                style={{
                  fontWeight: "400",
                  fontSize: "20px",
                  color: "#515151",
                }}
              >
                Usage Details
              </p>
              <div
                className="mt-2 ms-2 px-2 bg-white px-1  rounded-3 py-1 d-flex flex-column recordContainer list-container"
                style={{ height: "300px", overflowY: "scroll" }}
              >
                <table className="table mt-0">
                  <thead>
                    <tr>
                      <th scope="col">Module</th>
                      <th scope="col">File Count</th>
                      <th scope="col">Storage</th>
                    </tr>
                  </thead>
                  <tbody>
                    {storageFileData?.modulewiseFilesStorageDtls?.map(
                      (item, index) => {
                        return (
                          <tr key={index}>
                            <td>{camelCaseToSpace(item.moduleName)}</td>
                            <td>{item.fileCount}</td>
                            <td>{item.totalSize}</td>
                          </tr>
                        );
                      }
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  };

  const auditLogHistory = () => {
    return (
      <div
        className="bg-white rounded-1 p-2"
        style={{ height: "100vh", width: "63vw" }}
        onClick={() => {
          setOpenDropdown(null);
        }}
      >
        <div className=" ms-2 mt-1" style={{ height: "80vh" }}>
          <div
            className="p-2"
            style={{
              fontSize: "24px",
              background:
                "linear-gradient(to right, rgba(64, 123, 255, 1), rgba(18, 167, 254, 1))",
              WebkitBackgroundClip: "text",
              backgroundClip: "text",
              WebkitTextFillColor: "transparent",
            }}
          >
            Audit Log
          </div>
          <div
            className="w-75 ms-2"
            style={{ color: "#515151", fontSize: "16px", fontWeight: "300" }}
          >
            {" "}
            The audit log provides you with a chronological sequence of actions
            performed by the users in Chain CRM.
          </div>
          <div className="d-flex justify-content-between align-items-center mt-2 me-2 ms-2">
            <div className="d-flex gap-2">
              {showDropdownFilters ? (
                <img
                  src={cancelFilter}
                  alt="cancel filter"
                  onClick={() => {
                    setShowDropdownFilters(!showDropdownFilters);
                    setIsAuditTabClicked(false);
                  }}
                  className="cursorPointer"
                />
              ) : (
                <img
                  src={filter}
                  alt="filter"
                  onClick={() => {
                    setShowDropdownFilters(!showDropdownFilters);
                    setIsAuditTabClicked(true);
                    resetAuditDropdown();
                    setShowDateRange(false);
                  }}
                  className="cursorPointer"
                />
              )}
              {showDropdownFilters && (
                <div className="d-flex gap-2">
                  <DropdownButton
                    id="dropdown-active-user"
                    title={dropdownAllUsers}
                    show={openDropdown === 1}
                    onClick={(e) => {
                      e.stopPropagation();
                      handleToggle(1);
                      setSearchTerm("");
                    }}
                  >
                    <div className="ms-2 me-2">
                      <input
                        type="text"
                        placeholder="Search users..."
                        className="searchUser p-1"
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                        onClick={(e) => e.stopPropagation()}
                      />
                    </div>
                    <hr />
                    <Dropdown.Item
                      eventKey="All Users"
                      onClick={() => handleSelectDropdownUsers("All Users")}
                    >
                      All Users
                    </Dropdown.Item>
                    {visibleUsers.length > 0 ? (
                      visibleUsers.map((user, index) => (
                        <Dropdown.Item
                          key={index}
                          eventKey={user.name}
                          onClick={() => handleSelectDropdownUsers(user.name)}
                        >
                          {user.name}
                        </Dropdown.Item>
                      ))
                    ) : (
                      <Dropdown.Item disabled>No users found</Dropdown.Item>
                    )}
                  </DropdownButton>
                  <DropdownButton
                    id="dropdown-active-user"
                    title={dropdownAllActions}
                    show={openDropdown === 2}
                    onClick={(e) => {
                      e.stopPropagation();
                      handleToggle(2);
                    }}
                  >
                    <Dropdown.Item
                      eventKey="All Actions"
                      onClick={() => handleSelectDropdownActions("All Actions")}
                    >
                      All Actions
                    </Dropdown.Item>
                    <Dropdown.Item
                      eventKey="Added"
                      onClick={() => handleSelectDropdownActions("Added")}
                    >
                      Added
                    </Dropdown.Item>
                    <Dropdown.Item
                      eventKey="Updated"
                      onClick={() => handleSelectDropdownActions("Updated")}
                    >
                      Updated
                    </Dropdown.Item>
                    <Dropdown.Item
                      eventKey="Deleted"
                      onClick={() => handleSelectDropdownActions("Deleted")}
                    >
                      Deleted
                    </Dropdown.Item>
                  </DropdownButton>
                  <DropdownButton
                    id="dropdown-active-user"
                    title={dropdownTime}
                    show={openDropdown === 3}
                    onClick={(e) => {
                      e.stopPropagation();
                      handleToggle(3);
                    }}
                  >
                    <Dropdown.Item
                      eventKey="Any time"
                      onClick={() => handleSelectDropdownTime("Any time")}
                    >
                      Any time
                    </Dropdown.Item>
                    <Dropdown.Item
                      eventKey="Today"
                      onClick={() => handleSelectDropdownTime("Today")}
                    >
                      Today
                    </Dropdown.Item>
                    <Dropdown.Item
                      eventKey="last 7 days"
                      onClick={() => handleSelectDropdownTime("Last 7 days")}
                    >
                      Last 7 days
                    </Dropdown.Item>
                    <Dropdown.Item
                      eventKey="last 30 days"
                      onClick={() => handleSelectDropdownTime("Last 30 days")}
                    >
                      Last 30 days
                    </Dropdown.Item>
                    <Dropdown.Item
                      eventKey="Date Range"
                      onClick={() => {
                        handleSelectDropdownTime("Date Range");
                        handleCustomRangeChange();
                      }}
                    >
                      Date Range
                    </Dropdown.Item>
                  </DropdownButton>
                  <div>
                    {showDateRange && (
                      <div className="d-flex gap-1 align-items-center">
                        <div style={{ fontSize: "14px" }}>
                          {" "}
                          From
                          <input
                            type="date"
                            value={fromDate}
                            className="settingsDate p-1 ms-1"
                            placeholder="From date"
                            max={toDate}
                            onKeyDown={(e) => e.preventDefault()}
                            onChange={(e) => setFromDate(e.target.value)}
                          />
                        </div>
                        <div style={{ fontSize: "14px" }}>
                          To
                          <input
                            type="date"
                            value={toDate}
                            className="settingsDate p-1 ms-1"
                            placeholder="To date"
                            min={fromDate}
                            max={getTodayDate()}
                            onKeyDown={(e) => e.preventDefault()}
                            onChange={(e) => setToDate(e.target.value)}
                          />
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              )}
            </div>

            <div>
              <div
                style={{
                  color: "#2177FE",
                  fontSize: "13px",
                  textDecoration: "underline",
                }}
                className="p1 cursorPointer"
                onClick={getAuditLogExportDetails}
              >
                Export Audit Log
              </div>
              {showExportAuditLog && (
                <Modal
                  show={showExportAuditLog}
                  className="d-flex justify-content-end align-items-end"
                  aria-labelledby="example-custom-modal-styling-title"
                  size="xl"
                  id="commonLeadModalExcel"
                >
                  <Modal.Header>
                    <div className="d-flex justify-content-between align-items-center w-100">
                      <div
                        style={{
                          fontSize: "18px",
                          fontWeight: "bold",
                          background:
                            "linear-gradient(to right, rgba(64, 123, 255, 1), rgba(18, 167, 254, 1))",
                          WebkitBackgroundClip: "text",
                          backgroundClip: "text",
                          WebkitTextFillColor: "transparent",
                        }}
                      >
                        Export Status
                      </div>
                      <div>
                        <img
                          alt="cancel"
                          src={Cancel}
                          className="cursorPointer"
                          onClick={() => {
                            setShowExportAuditLog(false);
                          }}
                        />
                      </div>
                    </div>{" "}
                  </Modal.Header>
                  <Modal.Body className="ModalBody overflow-auto">
                    <div style={{ width: "18vw" }}>
                      <div className="d-flex gap-3 align-items-center justify-content-end">
                        <div style={{ fontSize: "16px" }}>Audit Log</div>
                        <button
                          className="p-1 px-4 unassignButton"
                          onClick={downloadAuditLogDetails}
                        >
                          Download
                        </button>
                      </div>
                    </div>
                  </Modal.Body>
                </Modal>
              )}
            </div>
          </div>
          <div
            className="mt-4 bg-white rounded-3"
            style={{
              border: "1px solid #515151",
              height: "60vh",
              overflowY: "scroll",
            }}
          >
            <div>
              {auditLogData && Object.keys(auditLogData).length > 0 ? (
                Object.keys(auditLogData).map((date) => (
                  <div
                    className="history_container pt-3 d-flex flex-column"
                    key={date}
                  >
                    <span className="px-2" style={{ fontWeight: "500" }}>
                      {date}
                    </span>
                    <div
                      style={{
                        borderBottom: "1px solid #E2E5EB",
                        fontWeight: "400",
                      }}
                      className="ms-2 me-2 mt-1"
                    ></div>
                    {auditLogData[date].map((item, index) => (
                      <div className="d-flex gap-2" key={index}>
                        <div className="d-flex flex-column align-items-center"></div>
                        <span className="pt-2 d-flex gap-2">
                          {!item.auditMessage ? (
                            <span style={{ color: "#515151" }}>--</span>
                          ) : (
                            <>
                              <span style={{ color: "#B9B9B9" }}>
                                {item.logTime}-
                              </span>
                              <span style={{ color: "#515151" }}>
                                {item.auditMessage}
                              </span>
                            </>
                          )}
                        </span>
                      </div>
                    ))}
                  </div>
                ))
              ) : (
                <div
                  className="text-center d-flex justify-content-center align-items-center mt-2"
                  style={{ height: "50vh" }}
                >
                  <h4>No Data available</h4>
                </div>
              )}
            </div>
          </div>
          <div className="d-flex w-100 align-items-center justify-content-end gap-2 mt-2 py-2">
            {auditLogData && Object.keys(auditLogData).length > 0 && (
              <Select
                options={pageOption}
                styles={customCommonStyles("100%")}
                menuPlacement="top"
                value={pageOption.find(
                  (option) => option.value === perPageAudit.toString()
                )}
                onChange={handlePageDropDowmChangeAudit}
                isSearchable={false}
              />
            )}
            <Pagination
              currentPage={currentPageAudit}
              totalPages={totalPagesAudit}
              onPageChange={handlePageChangeAudit}
            />
          </div>
        </div>
      </div>
    );
  };

  const handleChange = (e) => {
    const value = e.target.value;
    if (/^\d*$/.test(value) && value.length <= 6) {
      setOtpVerification(value);
    }
  };

  const handleInput = (e) => {
    const value = e.target.value;
    if (!/^\d*$/.test(value)) {
      e.target.value = value.replace(/[^\d]/g, '');
    }
  };

  const changePassword = () => {
    return (
      <div
        className="bg-white rounded-1 p-2"
        style={{ height: "100vh", width: "63vw" }}
      >
        <div className=" ms-2 mt-1" style={{ height: "80vh" }}>
          <div
            className="p-2"
            style={{
              fontSize: "24px",
              background:
                "linear-gradient(to right, rgba(64, 123, 255, 1), rgba(18, 167, 254, 1))",
              WebkitBackgroundClip: "text",
              backgroundClip: "text",
              WebkitTextFillColor: "transparent",
            }}
          >
            {changePasswordTab === "password"
              ? "Change Password"
              : "OTP Verification"}
          </div>
          <div
            className="w-100 ms-2 mt-1 d-flex justify-content-between"
            style={{ color: "#515151", fontSize: "16px", fontWeight: "300" }}
          >
            {" "}
            <p className="p-0 m-0">
              {changePasswordTab === "password"
                ? "Your password is reset! Choose a new one below to complete the process."
                : "We have sent an OTP to your logged-in email address."}
            </p>
            {changePasswordTab === "password" ? (
              <div>
                <button
                  className="createContactButtonSave me-5"
                  onClick={() => {
                    // setFlagValue(1);
                    handleChangePassword(1);
                  }}
                  disabled={apiLoader}
                >
                  {apiLoader ? (
                    <Lottie
                      options={customDefaultOptions("white")}
                      height="24px"
                      width="36px"
                    />
                  ) : (
                    "Submit"
                  )}
                </button>
              </div>
            ) : (
              <div className=" d-flex">
                <button
                  className="cancel_btn bg-light me-3"
                  style={{ borderRadius: "5px" }}
                  onClick={() => {
                    setChangePasswordTab("password");
                    setOtpVerification("");
                    setNewPassword("");
                    setOldPassword("");
                  }}
                >
                  Cancel
                </button>
                <button
                  className="createContactButtonSave me-5"
                  onClick={() => {
                    handleChangePassword(2);
                  }}
                  disabled={apiLoader}
                >
                  {apiLoader ? (
                    <Lottie
                      options={customDefaultOptions("white")}
                      height="24px"
                      width="36px"
                    />
                  ) : (
                    "Submit"
                  )}
                </button>
              </div>
            )}
          </div>

          <div className="d-flex p-2 gap-5 mt-2">
            <div className="d-flex flex-column">
              <p
                className="p-0 m-0"
                style={{ fontWeight: "500", fontSize: "16px" }}
              >
                {changePasswordTab === "password"
                  ? "Old Password"
                  : "Enter OTP"}
                <span className="text-danger">*</span>
              </p>
              {changePasswordTab === "password" ? (
                <>
                  <div
                    className="d-flex p-2 justify-content-between"
                    style={{
                      border: mandatoryOldPassword
                        ? "1.5px solid red"
                        : "1.5px solid #B9B9B980",
                      borderRadius: "4px",
                      width: "20rem",
                      height: "2.5rem",
                    }}
                  >
                    <input
                      type={showPasswordNew ? "text" : "password"}
                      style={{
                        width: "20rem",
                        border: "none",
                        outline: "none",
                      }}
                      value={oldPassword}
                      onChange={(event) => {
                        setOldPassword(event.target.value.trim());
                      }}
                      maxLength={40}
                      autoComplete="off"
                    />
                    <img
                      src={
                        showPasswordNew ? showPasswordIcon : hidePasswordIcon
                      }
                      alt={showPasswordNew ? "Hide Password" : "Show Password"}
                      onClick={togglePasswordVisibility}
                      className="cursorPointer ms-1"
                      style={{
                        verticalAlign: "bottom",
                      }}
                    />
                  </div>
                  {mandatoryOldPassword && (
                    <p className="p-0 m-0" style={{ color: "#fa5a5a" }}>
                      {mandatoryOldPassword}
                    </p>
                  )}
                </>
              ) : (
                <>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      border: mandatoryOTP
                        ? "1.5px solid red"
                        : "1.5px solid #B9B9B980",
                      borderRadius: "4px",
                      width: "20rem",
                      height: "2.5rem",
                      padding: "0.5rem",
                      position: "relative",
                    }}
                  >
                    {Array.from({ length: 6 }).map((_, index) => (
                      <div
                        key={index}
                        style={{
                          width: "2rem",
                          height: "2rem",
                          borderRadius: "50%",
                          margin: "0 0.25rem",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        {isFocused ||
                        otpVerification.length > 0 ||
                        otpVerification[index]
                          ? otpVerification[index] || "•"
                          : ""}
                      </div>
                    ))}
                    <input
                      type="text"
                      value={otpVerification}
                      onChange={handleChange}
                     onInput={handleInput}
                      onFocus={handleFocus}
                      onBlur={handleBlur}
                      style={{
                        position: "absolute",
                        opacity: 0,
                        width: "100%",
                        height: "100%",
                        border: "none",
                        top: 0,
                        left: 0,
                        cursor:"pointer"
                      }}
                    />
                  </div>

                  <div style={{ height: "10px" }}>
  {mandatoryOTP && (
    <p className="p-0 m-0" style={{ color: "#fa5a5a" }}>
      {mandatoryOTP}
    </p>
  )}
</div>
<div className="mb-0" style={{ textAlignLast: "right" }}>
  {!disabled && (
    <p
      className="text-end mb-0"
      style={{
        color: "#407BFF",
        cursor: textDisable ? "not-allowed" : "pointer",
        fontSize: "smaller",
        opacity: textDisable ? "0.5" : "1",
      }}
      onClick={() => {
        textDisable ? <></> : handleChangePassword(1, "resend");
      }}
    >
      Resend OTP
    </p>
  )}
  {disabled && (
    <span style={{ color: "#515151", fontSize: "smaller" }}>
      Resend in {timer}s
    </span>
  )}
</div>

                </>
              )}
            </div>
            {changePasswordTab === "password" ? (
              <div className="d-flex flex-column">
                <p
                  className="p-0 m-0"
                  style={{ fontWeight: "500", fontSize: "16px" }}
                >
                  New Password<span className="text-danger">*</span>
                </p>
                <div className="d-flex">
                  <div
                    className="d-flex p-2 justify-content-between"
                    style={{
                      border: mandatoryNewPassword
                        ? "1.5px solid red"
                        : "1.5px solid #B9B9B980",
                      borderRadius: "4px",
                      width: "20rem",
                      height: "2.5rem",
                    }}
                  >
                    <input
                      type={showPasswordNewCreate ? "text" : "password"}
                      style={{
                        width: "20rem",
                        border: "none",
                        outline: "none",
                      }}
                      value={newPassword}
                      onChange={(event) => {
                        setNewPassword(event.target.value.trim());
                      }}
                      maxLength={40}
                      autoComplete="off"
                    />
                    <img
                      src={
                        showPasswordNewCreate
                          ? showPasswordIcon
                          : hidePasswordIcon
                      }
                      alt={
                        showPasswordNewCreate
                          ? "Hide Password"
                          : "Show Password"
                      }
                      onClick={toggleNewPasswordVisibility}
                      className="cursorPointer ms-1"
                      style={{
                        alignSelf: "self-end",
                      }}
                    />
                  </div>
                  <Tooltip
                    id="tooltip"
                    place="top"
                    className="custom-tooltip"
                    style={{ right: "25%" }}
                  />
                  <span
                    className="pb-2 mt-1 ms-3 tooltipIcon"
                    data-tooltip-id="tooltip"
                    data-tooltip-content="Password must contain at least one lowercase letter, one uppercase letter, one number, one special character and should be between 8 to 40 characters long."
                    style={{ paddingTop: "0.25rem" }}
                  >
                    <img
                      src={alert}
                      height={20}
                      width={20}
                      alt="icon"
                      className="cursorPointer infoImage"
                      style={{ marginTop: "-4px" }}
                    />
                  </span>
                </div>
                {mandatoryNewPassword && (
                  <p
                    className="p-0 mb-0 mx-0 w-100"
                    style={{ color: "#fa5a5a" }}
                  >
                    {mandatoryNewPassword}
                  </p>
                )}
              </div>
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
    );
  };

  const exportData = () => {
    return (
      <div
        className=" bg-white rounded-2 p-2"
        style={{ height: "100vh", width: "63vw" }}
        onClick={() => {
          setOpenDropdown(null);
        }}
      >
        {initialExportPage === "initial" && (
          <div>
            <div
              className="p-2"
              style={{
                fontSize: "24px",
                background:
                  "linear-gradient(to right, rgba(64, 123, 255, 1), rgba(18, 167, 254, 1))",
                WebkitBackgroundClip: "text",
                backgroundClip: "text",
                WebkitTextFillColor: "transparent",
              }}
            >
              Export Data
            </div>
            <div
              className="w-100 ms-2"
              style={{ color: "#515151", fontSize: "16px", fontWeight: "300" }}
            >
              You can export a max of 200,000 records. To export all of your
              data, you&apos;ll need to
              <span style={{ color: "#2177FE" }} className="cursorPointer ms-1">
                request for data back up.
              </span>
            </div>
            <div className="d-flex justify-content-between align-items-center mt-4 me-2 ms-2">
              <div className="d-flex gap-2">
                {showExportDropdownFilters ? (
                  <img
                    src={cancelFilter}
                    alt="cancel filter"
                    onClick={handleFilterExportIconClick}
                    className="cursorPointer"
                  />
                ) : (
                  <img
                    src={filter}
                    alt="filter"
                    onClick={handleFilterExportIconClick}
                    className="cursorPointer"
                  />
                )}
                {showExportDropdownFilters && (
                  <div className="d-flex gap-2">
                    {userInfo.userProfile.toLowerCase() !== "standard" && (
                      <DropdownButton
                        id="dropdown-active-user"
                        title={dropdownExportAllUsers}
                        show={openDropdown === 1}
                        onClick={(e) => {
                          e.stopPropagation();
                          handleToggle(1);
                          setSearchTerm("");
                        }}
                      >
                        <div className="ms-2 me-2">
                          <input
                            type="text"
                            placeholder="Search users..."
                            className="searchUser p-1"
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                            onClick={(e) => e.stopPropagation()}
                          />
                        </div>
                        <hr />
                        <Dropdown.Item
                          eventKey="all_users"
                          onClick={() =>
                            handleSelectDropdownExportUsers(
                              "All Users",
                              "all_users"
                            )
                          }
                        >
                          All Users
                        </Dropdown.Item>
                        {visibleUsers.length > 0 ? (
                          visibleUsers.map((user, index) => (
                            <Dropdown.Item
                              key={index}
                              eventKey={user.name}
                              onClick={() =>
                                handleSelectDropdownExportUsers(
                                  user.name,
                                  user.userId
                                )
                              }
                            >
                              {user.name}
                            </Dropdown.Item>
                          ))
                        ) : (
                          <Dropdown.Item disabled>No users found</Dropdown.Item>
                        )}
                      </DropdownButton>
                    )}

                    <DropdownButton
                      id="dropdown-active-user"
                      title={dropdownExportActions}
                      show={openDropdown === 2}
                      onClick={(e) => {
                        e.stopPropagation();
                        handleToggle(2);
                      }}
                    >
                      {dropdownExportItems.map((item, index) => (
                        <Dropdown.Item
                          key={index}
                          eventKey={item.value}
                          onClick={() =>
                            handleSelectDropdownExportActions(
                              item.label,
                              item.value
                            )
                          }
                        >
                          {item.label}
                        </Dropdown.Item>
                      ))}
                    </DropdownButton>
                    <DropdownButton
                      id="dropdown-active-user"
                      title={dropdownExportTime}
                      show={openDropdown === 3}
                      onClick={(e) => {
                        e.stopPropagation();
                        handleToggle(3);
                      }}
                    >
                      <Dropdown.Item
                        eventKey="anytime"
                        onClick={() =>
                          handleSelectEportDropdownTime("Any time", "anytime")
                        }
                      >
                        Any time
                      </Dropdown.Item>
                      <Dropdown.Item
                        eventKey="today"
                        onClick={() =>
                          handleSelectEportDropdownTime("Today", "today")
                        }
                      >
                        Today
                      </Dropdown.Item>
                      <Dropdown.Item
                        eventKey="yesterday"
                        onClick={() =>
                          handleSelectEportDropdownTime(
                            "Yesterday",
                            "yesterday"
                          )
                        }
                      >
                        Yesterday
                      </Dropdown.Item>
                      <Dropdown.Item
                        eventKey="last_7_days"
                        onClick={() =>
                          handleSelectEportDropdownTime(
                            "Last 7 days",
                            "last_7_days"
                          )
                        }
                      >
                        Last 7 days
                      </Dropdown.Item>
                      <Dropdown.Item
                        eventKey="last_30_days"
                        onClick={() =>
                          handleSelectEportDropdownTime(
                            "Last 30 days",
                            "last_30_days"
                          )
                        }
                      >
                        Last 30 days
                      </Dropdown.Item>

                      <Dropdown.Item
                        eventKey="custom_range"
                        onClick={() => {
                          handleSelectEportDropdownTime(
                            "Date Range",
                            "custom_range"
                          );
                          handleCustomRangeChange(); // This function will handle the date range selection
                        }}
                      >
                        Date Range
                      </Dropdown.Item>
                    </DropdownButton>

                    <div>
                      {showDateRange && (
                        <div className="d-flex gap-1 align-items-center">
                          <div style={{ fontSize: "14px" }}>
                            {" "}
                            From
                            <input
                              type="date"
                              value={fromDate}
                              className="settingsDate p-1 ms-1"
                              placeholder="From date"
                              max={toDate}
                              onKeyDown={(e) => e.preventDefault()}
                              onChange={(e) => setFromDate(e.target.value)}
                            />
                          </div>
                          <div style={{ fontSize: "14px" }}>
                            To
                            <input
                              type="date"
                              value={toDate}
                              className="settingsDate p-1 ms-1"
                              placeholder="To date"
                              min={fromDate}
                              max={getTodayDate()}
                              onKeyDown={(e) => e.preventDefault()}
                              onChange={(e) => setToDate(e.target.value)}
                            />
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                )}
              </div>

              <div>
                <div
                  style={{
                    color: "#2177FE",
                    fontSize: "16px",
                    textDecoration: "underline",
                  }}
                  className="p1 cursorPointer"
                  onClick={() => {
                    setInitialExportPage("create");
                    setSelectedModule("");
                    setCheckedFields([]);
                    setSelectedField("");
                    setShowExportDropdownFilters(false);
                    setListVisibleModule(false);
                  }}
                >
                  Create Export
                </div>
              </div>
            </div>
            <div>
              <div
                style={{
                  fontSize: "18px",
                  color: "#515151",
                  fontWeight: "400",
                }}
                className="ms-3 mt-4"
              >
                Export Data
              </div>
            </div>
            <div className="mt-2 exportTableClass me-2">
              <div
                className="w-100 d-flex p-2 ms-2"
                style={{
                  color: "#515151",
                  fontWeight: "500",
                  fontSize: "16px",
                }}
              >
                <div style={{ width: "15%" }}>Module</div>
                <div style={{ width: "25%" }}>Exported By</div>
                <div style={{ width: "20%" }}>Exported Time</div>
                <div style={{ width: "20%" }}>Record Count</div>
                <div style={{ width: "20%" }}>Status</div>
              </div>
              <div
                style={{ border: "1px solid #E2E5EB" }}
                className="ms-2 me-2"
              ></div>
              <div>
                {getAllExportData?.length === 0 ? (
                  <div
                    className="d-flex justify-content-center align-items-center"
                    style={{
                      textAlign: "center",
                      color: "#5F5F5F",
                      fontSize: "20px",
                      height: "60vh",
                    }}
                  >
                    No export data
                  </div>
                ) : (
                  getAllExportData?.map((item, index) => (
                    <div
                      key={index}
                      className="w-100 d-flex p-2 position-relative cursorPointer exportData"
                      style={{
                        color: "#5F5F5F",
                        fontWeight: "400",
                        fontSize: "16px",
                        borderBottom: "1px solid #E2E5EB",
                      }}
                      onMouseEnter={() => setHoveredIndex(index)}
                      onMouseLeave={() => setHoveredIndex(null)}
                      onClick={() => {
                        setInitialExportPage("details");
                        setStoreExportData(item);
                      }}
                    >
                      <div style={{ width: "15%" }} className="overflow_text">
                        {(item.moduleName || "--")
                          .replace(/_/g, " ")
                          .split(" ")
                          .map(
                            (word) =>
                              word.charAt(0).toUpperCase() +
                              word.slice(1).toLowerCase()
                          )
                          .join(" ")}
                      </div>
                      <div style={{ width: "25%" }} className="overflow_text">
                        {item.exportedBy || "--"}
                      </div>
                      <div style={{ width: "20%" }} className="overflow_text">
                        {item.exportedTime || "--"}
                      </div>
                      <div
                        style={{ width: "20%" }}
                        className="overflow_text text-center"
                      >
                        {item.recordCount || "--"}
                      </div>
                      <div style={{ width: "20%" }} className="overflow_text">
                        {item.status || "--"}
                      </div>
                      {hoveredIndex === index && (
                        <div
                          className="position-absolute"
                          style={{
                            right: "20px",
                            top: "50%",
                            transform: "translateY(-50%)",
                          }}
                          onClick={(e) => {
                            e.stopPropagation();
                            downloadExportExcel(item.exportId);
                          }}
                        >
                          <img
                            src={Download}
                            alt="Download"
                            style={{
                              width: "20px",
                              height: "20px",
                              cursor: "pointer",
                              zIndex: "100",
                            }}
                          />
                        </div>
                      )}
                    </div>
                  ))
                )}
              </div>
            </div>
            <div className="d-flex w-100 align-items-center justify-content-end gap-2 mt-2 py-2">
              {getAllExportData.length > 0 && (
                <Select
                  options={pageOption}
                  styles={customCommonStyles("100%")}
                  menuPlacement="top"
                  value={pageOption.find(
                    (option) => option.value === perPageExport.toString()
                  )}
                  onChange={handlePageDropDowmChangeExport}
                  isSearchable={false}
                />
              )}
              <Pagination
                currentPage={currentPageExport}
                totalPages={totalPagesExport}
                onPageChange={handlePageChangeExport}
              />
            </div>
          </div>
        )}

        {initialExportPage === "create" && (
          <div>
            <div className="d-flex gap-2">
              <img
                src={leftArrow}
                alt="leftArrow"
                className="cursorPointer"
                onClick={() => {
                  setInitialExportPage("initial");
                  setShowExportDropdownFilters(false);
                  resetDropdownData();
                }}
              />
              <div
                className="p-2"
                style={{
                  fontSize: "24px",
                  background:
                    "linear-gradient(to right, rgba(64, 123, 255, 1), rgba(18, 167, 254, 1))",
                  WebkitBackgroundClip: "text",
                  backgroundClip: "text",
                  WebkitTextFillColor: "transparent",
                }}
              >
                Export Data
              </div>
            </div>
            <div className="d-flex justify-content-between align-items-center me-4">
              <div
                className="w-50 ms-2 overflow_text"
                style={{
                  color: "#515151",
                  fontSize: "16px",
                  fontWeight: "300",
                }}
              >
                You can export a max of 200,000 records.
              </div>
              <div className="d-flex gap-2">
                <button
                  className="cancelButton px-4"
                  onClick={() => {
                    setInitialExportPage("initial");
                    setShowExportDropdownFilters(false);
                    resetDropdownData();
                  }}
                >
                  Cancel
                </button>
                <button
                  className="unassignButton px-4"
                  onClick={getExportDetails}
                  disabled={apiLoader}
                  style={{ width: "95px" }}
                >
                  {apiLoader ? (
                    <Lottie
                      options={customDefaultOptions("white")}
                      height="24px"
                      width="36px"
                    />
                  ) : (
                    "Export"
                  )}
                </button>
              </div>
            </div>
            <div className="d-flex gap-5 mt-4 ms-2">
              <div className="position-relative mt-2">
                <p className="mb-0">Modules</p>
                <div
                  className="d-flex input-with-clear"
                  onClick={() => {
                    setListVisibleModule(!listVisibleModule);
                    setModuleListVisible(false);
                    setSelectedFieldsListVisible(false);
                  }}
                >
                  <input
                    type="text"
                    className="inputBoxExport cursorPointer mt-1 px-2"
                    value={titleCaseNew(selectedModule)}
                    placeholder="Select a module"
                    readOnly
                  />
                  <span className="clear-button-export">
                    <img
                      src={listVisibleModule ? up : down}
                      alt="toggle"
                      height={20}
                      width={20}
                    />
                  </span>
                </div>
                {listVisibleModule && (
                  <ul
                    className="data-list list-group settings_profile_list position-absolute w-100"
                    style={{ top: "4.5rem", zIndex: "10" }}
                  >
                    {moduleNames.map((moduleName, index) => (
                      <li
                        key={index}
                        className="cursorPointer list-group-item"
                        onClick={() => handleModuleClick(moduleName)}
                      >
                        <div className="d-flex flex-column">
                          <span>{titleCaseNew(moduleName)}</span>
                        </div>
                      </li>
                    ))}
                  </ul>
                )}
              </div>

              {selectedModule && (
                <div className="position-relative mt-2">
                  <p className="mb-0">
                    {titleCaseNew(selectedModule)} Custom View
                  </p>
                  <div
                    className="d-flex input-with-clear"
                    onClick={() => {
                      setModuleListVisible(!moduleListVisible);
                      setSelectedFieldsListVisible(false);
                      setListVisibleModule(false);
                    }}
                  >
                    <input
                      type="text"
                      className="inputBoxExport cursorPointer mt-1 px-2"
                      readOnly
                      value={selectedModuleList.label}
                    />
                    <span className="clear-button-export">
                      <img
                        src={moduleListVisible ? up : down}
                        alt="toggle"
                        height={20}
                        width={20}
                      />
                    </span>
                  </div>
                  {moduleListVisible && (
                    <ul
                      className="data-list list-group settings_profile_list w-100 position-absolute"
                      style={{ top: "4.5rem" }}
                    >
                      {selectedModuleData.map((item, index) => (
                        <li
                          key={index}
                          className="cursorPointer list-group-item"
                          onClick={() => handleMuduleListClick(item)}
                        >
                          <div className="d-flex flex-column">
                            <span>{item.label}</span>
                          </div>
                        </li>
                      ))}
                    </ul>
                  )}
                </div>
              )}
            </div>
            {selectedModule && (
              <div className="d-flex gap-5 mt-2 ms-2">
                <div className="position-relative mt-2">
                  <p className="mb-0">Fields</p>
                  <div
                    className="d-flex input-with-clear"
                    onClick={() => {
                      setSelectedFieldsListVisible(!selectedFieldsListVisible);
                      setListVisibleModule(false);
                      setModuleListVisible(false);
                    }}
                  >
                    <input
                      type="text"
                      className="inputBoxExport cursorPointer mt-1 px-2"
                      value={titleCaseNew(selectedField.label)}
                      readOnly
                    />
                    <span
                      className="clear-button-export"
                      onClick={() => {
                        setSelectedFieldsListVisible(false);
                      }}
                    >
                      <img
                        src={selectedFieldsListVisible ? up : down}
                        alt="img"
                        height={20}
                        width={20}
                      />
                    </span>
                  </div>
                  {selectedFieldsListVisible && (
                    <ul
                      className="data-list list-group settings_profile_list"
                      style={{ top: "2rem" }}
                    >
                      {fieldsLabel.fields.map((item, index) => (
                        <li
                          key={index}
                          className="cursorPointer list-group-item"
                          onClick={() => handleFieldItemClick(item)}
                        >
                          <div className="d-flex flex-column">
                            <span>{item.label}</span>
                          </div>
                        </li>
                      ))}
                    </ul>
                  )}
                </div>
              </div>
            )}
            <div>{renderCheckboxes()}</div>
          </div>
        )}

        {initialExportPage === "details" && (
          <div>
            <div className="d-flex">
              <img
                alt="leftArrow"
                src={leftArrow}
                onClick={() => {
                  setInitialExportPage("initial");
                  setShowExportDropdownFilters(false);
                  resetDropdownData();
                }}
                className="cursorPointer"
              />
              <div
                className="p-2"
                style={{
                  fontSize: "24px",
                  background:
                    "linear-gradient(to right, rgba(64, 123, 255, 1), rgba(18, 167, 254, 1))",
                  WebkitBackgroundClip: "text",
                  backgroundClip: "text",
                  WebkitTextFillColor: "transparent",
                }}
              >
                Export Details
              </div>
            </div>
            <div className="mt-4 ms-4">
              <div className="w-100 d-flex">
                <div className="w-25 exportHeader">Module</div>
                <div className="w-75">
                  {" "}
                  {(storeExportData.moduleName || "--")
                    .replace(/_/g, " ")
                    .split(" ")
                    .map(
                      (word) =>
                        word.charAt(0).toUpperCase() +
                        word.slice(1).toLowerCase()
                    )
                    .join(" ")}
                </div>
              </div>
              <div className="w-100 d-flex mt-2">
                <div className="w-25 exportHeader">
                  {(storeExportData.moduleName || "--")
                    .replace(/_/g, " ")
                    .split(" ")
                    .map(
                      (word) =>
                        word.charAt(0).toUpperCase() +
                        word.slice(1).toLowerCase()
                    )
                    .join(" ")}{" "}
                  Custom view
                </div>
                <div className="w-75">
                  {titleCase(storeExportData.customView || "--")}
                </div>
              </div>
              <div className="w-100 d-flex mt-2">
                <div className="w-25 exportHeader">Export Fields</div>
                <div className="w-75">
                  {storeExportData.exportedFields || "--"}
                </div>
              </div>
              <div className="w-100 d-flex mt-2">
                <div className="w-25 exportHeader">Exported By</div>
                <div className="w-75">{storeExportData.exportedBy || "--"}</div>
              </div>
              <div className="w-100 d-flex mt-2">
                <div className="w-25 exportHeader">Exported Time</div>
                <div className="w-75">
                  {storeExportData.exportedTime || "--"}
                </div>
              </div>
              <div className="w-100 d-flex mt-2">
                <div className="w-25 exportHeader">Record Count</div>
                <div className="w-75">
                  {storeExportData.recordCount || "--"}
                </div>
              </div>
              <div className="w-100 d-flex mt-2">
                <div className="w-25 exportHeader">Status</div>
                <div className="w-75">{storeExportData.status || "--"}</div>
              </div>
              <div
                onClick={() => {
                  downloadExportExcel(storeExportData.exportId);
                }}
                className=" w-50 d-flex justify-content-end align-items-center mt-4 cursorPointer"
              >
                <img
                  src={Download}
                  alt="Download"
                  style={{ width: "20px", height: "20px", cursor: "pointer" }}
                />
                <div style={{ color: "#338cf0", fontSize: "14px" }}>
                  Download
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  };

  const removeData = () => {
    return (
      <div
        className=" bg-white rounded-2 p-2"
        style={{ height: "100vh", width: "63vw" }}
      >
        <div
          className="p-2"
          style={{
            fontSize: "24px",
            background:
              "linear-gradient(to right, rgba(64, 123, 255, 1), rgba(18, 167, 254, 1))",
            WebkitBackgroundClip: "text",
            backgroundClip: "text",
            WebkitTextFillColor: "transparent",
          }}
        >
          Remove sample data
        </div>
        {sampleDataCount !== 0 ? (
          <div
            className="w-100 ms-2"
            style={{ color: "#515151", fontSize: "16px", fontWeight: "300" }}
          >
            All Changes that you made to the sample data will be lost. Are you
            sure of removing them
          </div>
        ) : (
          <div
            className="w-100 ms-2"
            style={{ color: "#515151", fontSize: "16px", fontWeight: "300" }}
          >
            All sample data has already been deleted.
          </div>
        )}

        <div className="d-flex gap-3 align-items-center mt-4 ms-2">
          {sampleDataCount !== 0 && (
            <button
              className="p-1 px-4 deleteButton"
              onClick={removeDataBackupDetails}
            >
              Yes,Proceed
            </button>
          )}
        </div>
      </div>
    );
  };

  const profileHistory = () => {
    return (
      <div>
        {profileShow === "profile" && (
          <div
            className="bg-white rounded-1 p-2"
            style={{ height: "100vh", width: "63vw" }}
          >
            <div
              className="p-2"
              style={{
                fontSize: "24px",
                background:
                  "linear-gradient(to right, rgba(64, 123, 255, 1), rgba(18, 167, 254, 1))",
                WebkitBackgroundClip: "text",
                backgroundClip: "text",
                WebkitTextFillColor: "transparent",
              }}
            >
              Profile
            </div>
            <div
              className="w-100 ms-2 me-2"
              style={{
                color: "#515151",
                fontSize: "16px",
                fontWeight: "300",
              }}
            >
              Profile is a set of permissions dealing with module access and
              operations, setup customizations, and access to various apps. You
              can provide different set of permissions to various users.
            </div>
            <div className="d-flex justify-content-between mt-4">
              <div className="inputContainer">
                <img src={Search} alt="search" className="searchIcon" />
                <input
                  type="text"
                  placeholder="Search"
                  value={searchProfile}
                  onChange={(e) => setSearchProfile(e.target.value)}
                  className="searchGroup bg-white px-5 w-75 p-2 ms-2"
                />
              </div>
              <div>
                <button
                  className="createContactButtonSave"
                  onClick={() => {
                    setAddNewProfile(true);
                    setCloneProfile(false);
                    setSearchProfile("");
                  }}
                >
                  New Profile
                </button>
                {addNewProfile && (
                  <Modal
                    show={addNewProfile}
                    className="d-flex justify-content-center align-items-start"
                    aria-labelledby="example-custom-modal-styling-title"
                    size="xl"
                    id="commonLeadModal"
                  >
                    <Modal.Header>
                      <div className="d-flex justify-content-between align-items-center w-100">
                        <div
                          style={{
                            fontSize: "25px",
                            fontWeight: "bold",
                            background:
                              "linear-gradient(to right, rgba(64, 123, 255, 1), rgba(18, 167, 254, 1))",
                            WebkitBackgroundClip: "text",
                            backgroundClip: "text",
                            WebkitTextFillColor: "transparent",
                          }}
                        >
                          Add New Profile
                        </div>
                        <div>
                          <img
                            alt="cancel"
                            src={Cancel}
                            className="cursorPointer"
                            onClick={() => {
                              setAddNewProfile(false);
                              setProfileListVisible(false);
                              setAddNewProfile(false);
                              setAddNewProfileMember({
                                profileName: "",
                                profileDescription: "",
                              });
                              setSelectedProfileItem("");
                            }}
                          />
                        </div>
                      </div>{" "}
                    </Modal.Header>
                    <Modal.Body className="ModalBody overflow-auto">
                      <div>
                        <div className="w-100">
                          <p className="mb-0">
                            Profile Name
                            <span className="text-danger">*</span>
                          </p>
                          <input
                            type="text"
                            className="inputBoxProfile mt-1 px-2"
                            name="profileName"
                            value={addNewProfileMember.profileName}
                            onChange={handleChangeProfile}
                            onKeyDown={handleKeyDown}
                            maxLength={30}
                          />
                          {profileNameError && (
                            <p style={{ color: "red", margin: "5px " }}>
                              {profileNameError}
                            </p>
                          )}
                        </div>
                        <div className="position-relative mt-2">
                          <p className="mb-0">
                            Clone Profile
                            <span className="text-danger">*</span>
                          </p>
                          <div
                            className="d-flex input-with-clear"
                            onClick={handleProfileClick}
                          >
                            <input
                              type="text"
                              className="inputBoxProfile cursorPointer mt-1"
                              value={
                                cloneProfile
                                  ? profileDetails.profileName
                                  : selectedProfileItem?.profileName
                              }
                              readOnly={cloneProfile}
                            />
                            {!cloneProfile && (
                              <span
                                className="clear-button-profile"
                                onClick={() =>
                                  selectedItem && setSelectedItem("")
                                }
                              >
                                <img
                                  src={profileListVisible ? up : down}
                                  alt="img"
                                  height={20}
                                  width={20}
                                />
                              </span>
                            )}
                          </div>
                          {cloneprofileError && (
                            <p style={{ color: "red" }}>{cloneprofileError}</p>
                          )}
                          {profileListVisible && (
                            <ul
                              className="data-list list-group  settings_profile_list"
                              style={{ top: "2rem" }}
                            >
                              {profileLists.map((item, index) => (
                                <li
                                  key={index}
                                  className="cursorPointer list-group-item"
                                  onClick={() => handleProfileItemClick(item)}
                                >
                                  <div className="d-flex flex-column">
                                    <span> {item.profileName} </span>
                                  </div>
                                </li>
                              ))}
                            </ul>
                          )}
                        </div>

                        <div>
                          <div className="mt-2"> Profile Description</div>
                          <div className="position-relative">
                            <input
                              type="text "
                              name="profileDescription"
                              className="inputBoxProfile mt-1 px-1"
                              value={addNewProfileMember.profileDescription}
                              onChange={handleChangeProfile}
                              maxLength={30}
                              onKeyDown={handleKeyDown}
                            />
                          </div>
                        </div>

                        <div className="d-flex align-items-center justify-content-end mt-4 gap-2">
                          <button
                            className="cancelButton px-4"
                            onClick={() => {
                              setAddNewProfile(false);
                              setAddNewProfileMember({
                                profileName: "",
                                profileDescription: "",
                              });
                              setSelectedProfileItem("");
                            }}
                          >
                            Cancel
                          </button>
                          <button
                            className="unassignButton px-4"
                            onClick={addNewProfileDetails}
                            disabled={apiLoader}
                            style={{ width: "135px" }}
                          >
                            {apiLoader ? (
                              <Lottie
                                options={customDefaultOptions("white")}
                                height="24px"
                                width="36px"
                              />
                            ) : (
                              "Create"
                            )}
                          </button>
                        </div>
                      </div>
                    </Modal.Body>
                  </Modal>
                )}
              </div>
            </div>
            <div
              className="bg-white rounded-2 ms-2 mt-4 me-2"
              style={{ height: "50vh", overflowY: "auto" }}
            >
              <div className="w-100 d-flex p-2" style={{ color: "#515151" }}>
                <div
                  style={{ width: "25%" }}
                  className="d-flex justify-content-between me-2"
                >
                  <div>Profile Name </div>
                  <div style={{ color: "#E2E5EB" }}>|</div>
                </div>
                <div
                  style={{ width: "25%" }}
                  className="d-flex justify-content-between me-2"
                >
                  <div>Profile Description</div>
                  <div style={{ color: "#E2E5EB" }}>|</div>
                </div>
                <div
                  style={{ width: "25%" }}
                  className="d-flex justify-content-between me-2"
                >
                  <div>Created Date</div>
                  <div style={{ color: "#E2E5EB" }}>|</div>
                </div>
                <div style={{ width: "25%" }}>
                  <div>Modified Date</div>
                </div>
              </div>
              <div
                style={{ borderBottom: "1px solid #515151" }}
                className="ms-2 me-2"
              ></div>
              {filteredProfileNames.length > 0 ? (
                filteredProfileNames.map((item, index) => (
                  <div key={index}>
                    <div className="d-flex gap-2 p-2 position-relative">
                      <div
                        className="d-flex overflow_text"
                        style={{
                          width: "25%",
                          fontSize: "14px",
                          color: "#529CF3",
                          cursor: "pointer",
                        }}
                        onMouseEnter={() => handleRowMouseEnterProfile(index)}
                      >
                        <div className="d-flex gap-2">
                          <span
                            onClick={() => {
                              setProfileShow("newProfile");
                              getProfilePermission(item.profileId);
                              setProfileId(item.profileId);
                            }}
                          >
                            {item.profileName}
                          </span>
                          {hoveredProfile === index && (
                            <div
                              style={{
                                position: "absolute",
                                left: "15%",
                                zIndex: "10",
                              }}
                            >
                              <img
                                src={groupIcon}
                                alt="cancel"
                                style={{
                                  width: "20px",
                                  height: "20px",
                                  cursor: "pointer",
                                }}
                                onClick={() => {
                                  handleIconClickProfile(index);
                                  setProfileDetails(item);
                                }}
                              />
                              {showProfileItems &&
                                selectedProfile === index && (
                                  <div
                                    key={index}
                                    className="rounded-1 p-2"
                                    style={{
                                      backgroundColor: "#ffffff",
                                      color: "#515151",
                                      zIndex: "10",
                                      border: "1px solid #515151",
                                    }}
                                  >
                                    {item.profileName !== "Administrator" &&
                                      item.profileName !== "Standard" && (
                                        <div
                                          className="mt-1"
                                          onClick={() => {
                                            handleRenameProfileModal(index);
                                            setProfileId(item.profileId);
                                          }}
                                        >
                                          Rename
                                        </div>
                                      )}
                                    {renameProfileModal && (
                                      <Modal
                                        show={renameProfileModal}
                                        className="d-flex justify-content-center align-items-start"
                                        aria-labelledby="example-custom-modal-styling-title"
                                        size="xl"
                                        id="commonLeadModal"
                                      >
                                        <Modal.Header>
                                          <div className="d-flex justify-content-between align-items-center w-100">
                                            <div
                                              style={{
                                                fontSize: "25px",
                                                fontWeight: "bold",
                                                background:
                                                  "linear-gradient(to right, rgba(64, 123, 255, 1), rgba(18, 167, 254, 1))",
                                                WebkitBackgroundClip: "text",
                                                backgroundClip: "text",
                                                WebkitTextFillColor:
                                                  "transparent",
                                              }}
                                            >
                                              Rename Profile
                                            </div>
                                            <div>
                                              <img
                                                alt="cancel"
                                                src={Cancel}
                                                className="cursorPointer"
                                                onClick={() => {
                                                  setRenameProfileModal(false);
                                                }}
                                              />
                                            </div>
                                          </div>{" "}
                                        </Modal.Header>
                                        <Modal.Body className="ModalBody overflow-auto">
                                          <div>
                                            <div className="w-100">
                                              <p className="mb-0">
                                                Profile Name
                                              </p>
                                              <input
                                                type="text"
                                                className="inputBoxProfile mt-1"
                                                name="profileName"
                                                value={
                                                  renameProfile.profileName
                                                }
                                                onChange={
                                                  handleChangeProfileName
                                                }
                                              />
                                              {profileNameError && (
                                                <p
                                                  style={{
                                                    color: "red",
                                                    margin: "5px ",
                                                  }}
                                                >
                                                  {profileNameError}
                                                </p>
                                              )}
                                            </div>
                                            <div>
                                              <div className="mt-2">
                                                {" "}
                                                Profile Description
                                              </div>
                                              <div className="position-relative">
                                                <textarea
                                                  type="text "
                                                  rows={3}
                                                  name="profileDescription"
                                                  className="RenameClick mt-1"
                                                  value={
                                                    renameProfile.profileDescription
                                                  }
                                                  onChange={
                                                    handleChangeProfileName
                                                  }
                                                />
                                              </div>
                                            </div>

                                            <div className="d-flex align-items-center justify-content-end mt-4 gap-2">
                                              <button
                                                className="cancelButton px-4"
                                                onClick={() => {
                                                  setRenameProfileModal(false);
                                                  setRenameProfile({
                                                    groupName: "",
                                                    groupDescription: "",
                                                  });
                                                }}
                                              >
                                                Cancel
                                              </button>
                                              <button
                                                className="unassignButton px-4"
                                                onClick={renameProfileDetails}
                                              >
                                                Rename
                                              </button>
                                            </div>
                                          </div>
                                        </Modal.Body>
                                      </Modal>
                                    )}
                                    <div
                                      className="mt-1"
                                      onClick={() => {
                                        setCloneProfile(true);
                                        setAddNewProfile(true);
                                        setProfileDetails(item);
                                        setCloneProfileError("");
                                      }}
                                    >
                                      Clone
                                    </div>
                                    <div
                                      className="mt-1"
                                      onClick={() => {
                                        setViewUsersModal(true);
                                        setProfileDetails(item);
                                        viewUsersProfileDetails();
                                        setSearchViewUser("");
                                      }}
                                    >
                                      View Users
                                    </div>
                                    {viewUsersModal && (
                                      <Modal
                                        show={viewUsersModal}
                                        className="d-flex justify-content-center align-items-start"
                                        aria-labelledby="example-custom-modal-styling-title"
                                        size="lg"
                                        id="commonLeadModalUsers"
                                      >
                                        <Modal.Header>
                                          <div className="d-flex justify-content-between align-items-center w-100">
                                            <div
                                              style={{
                                                fontSize: "25px",
                                                fontWeight: "bold",
                                                background:
                                                  "linear-gradient(to right, rgba(64, 123, 255, 1), rgba(18, 167, 254, 1))",
                                                WebkitBackgroundClip: "text",
                                                backgroundClip: "text",
                                                WebkitTextFillColor:
                                                  "transparent",
                                              }}
                                            >
                                              Users in{" "}
                                              {profileDetails.profileName}{" "}
                                              Profile
                                            </div>
                                            <div>
                                              <img
                                                alt="cancel"
                                                src={Cancel}
                                                className="cursorPointer"
                                                onClick={() => {
                                                  setViewUsersModal(false);
                                                }}
                                              />
                                            </div>
                                          </div>{" "}
                                        </Modal.Header>
                                        <Modal.Body className="ModalBody overflow-auto">
                                          <div className="inputContainer">
                                            <img
                                              src={Search}
                                              alt="search"
                                              className="searchIcon"
                                            />
                                            <input
                                              type="text"
                                              placeholder="Search"
                                              value={searchViewUser}
                                              onChange={(e) =>
                                                setSearchViewUser(
                                                  e.target.value
                                                )
                                              }
                                              className="searchGroup bg-white px-5 w-50 p-2 ms-2"
                                            />
                                          </div>
                                          <div
                                            className="Owner_table_container rounded-2"
                                            style={{ height: "auto" }}
                                          >
                                            <div className="table-wrapper">
                                              <table className="userdata-table">
                                                <thead>
                                                  <tr>
                                                    <th>User Name</th>
                                                    <th>Role</th>
                                                    <th>Status</th>
                                                  </tr>
                                                </thead>
                                                <tbody className="table-body tableUserData">
                                                  {filteredViewUsers.length ===
                                                  0 ? (
                                                    <tr>
                                                      <td
                                                        colSpan="3"
                                                        style={{
                                                          textAlign: "center",
                                                        }}
                                                      >
                                                        No users found
                                                      </td>
                                                    </tr>
                                                  ) : (
                                                    filteredViewUsers.map(
                                                      (item, index) => (
                                                        <tr key={index}>
                                                          <td>
                                                            {item.userName}
                                                          </td>
                                                          <td>
                                                            {item.roleName}
                                                          </td>
                                                          <td>
                                                            {item.userStatus}
                                                          </td>
                                                        </tr>
                                                      )
                                                    )
                                                  )}
                                                </tbody>
                                              </table>
                                            </div>
                                          </div>
                                        </Modal.Body>
                                      </Modal>
                                    )}
                                    {item.profileName !== "Administrator" &&
                                      item.profileName !== "Standard" && (
                                        <div
                                          className="mt-1"
                                          onClick={() => {
                                            deleteProfileDetails(
                                              item.profileId
                                            );
                                          }}
                                        >
                                          Delete
                                        </div>
                                      )}
                                  </div>
                                )}
                            </div>
                          )}
                        </div>
                      </div>

                      <div
                        className="overflow_text"
                        style={{
                          width: "25%",
                          fontSize: "14px",
                          color: "#515151",
                        }}
                      >
                        {item.profileDescription}
                      </div>
                      <div
                        className="overflow_text"
                        style={{
                          width: "25%",
                          fontSize: "14px",
                          color: "#515151",
                        }}
                      >
                        {item.createdBy}
                      </div>
                      <div
                        className="overflow_text"
                        style={{
                          width: "25%",
                          fontSize: "14px",
                          color: "#515151",
                        }}
                      >
                        {item.modifiedBy !== null ? item.modifiedBy : "--"}
                      </div>
                    </div>
                    {index !== profileLists.length - 1 && (
                      <div
                        style={{ borderBottom: "1px solid #515151" }}
                        className="ms-2 me-2"
                      ></div>
                    )}
                  </div>
                ))
              ) : (
                <div className="text-center mt-5">
                  <p>No Profiles found</p>
                </div>
              )}
            </div>
          </div>
        )}

        {profileShow === "newProfile" && (
          <div
            className="bg-white rounded-1 p-2"
            style={{ height: "100vh", width: "63vw" }}
            onClick={() => setSelectedAction(null)}
          >
            <div className="d-flex gap-2 align-items-center">
              <div>
                <img
                  src={leftArrow}
                  alt="leftArrow"
                  className="cursorPointer"
                  onClick={() => {
                    setProfileShow("profile");
                  }}
                />
              </div>
              <div
                className="p-2"
                style={{
                  fontSize: "24px",
                  background:
                    "linear-gradient(to right, rgba(64, 123, 255, 1), rgba(18, 167, 254, 1))",
                  WebkitBackgroundClip: "text",
                  backgroundClip: "text",
                  WebkitTextFillColor: "transparent",
                }}
              >
                Basic Permissions
              </div>
            </div>
            <div
              className="w-75 ms-2"
              style={{
                color: "#515151",
                fontSize: "16px",
                fontWeight: "300",
              }}
            >
              Profile is a set of permissions dealing with module access and
              operations, setup customizations, and access to various apps. You
              can provide different set of permissions to various users.
            </div>
            <div
              className="commonContainer_bg  rounded-2 ms-2 mt-4 me-4 w-100"
              style={{
                height: "60vh",
                overflow: "auto",
                backgroundColor: "gray",
              }}
            >
              <div>
                {permissionData.getProfilePermissionDetail &&
                  Object.keys(permissionData.getProfilePermissionDetail).map(
                    (moduleName) => (
                      <div
                        key={moduleName}
                        className="w-100 p-2 d-flex justify-content-between"
                      >
                        <div style={{ width: "50%" }}>
                          {camelCaseToSpace(moduleName)}
                        </div>
                        <div
                          style={{ width: "50%" }}
                          className="d-flex gap-2 position-relative"
                        >
                          <ToggleSwitch
                            checked={
                              permissionData.getProfilePermissionDetail[
                                moduleName
                              ][0].status === "TRUE"
                                ? true
                                : false
                            }
                            onChange={(event) => {
                              const action = { action: "VIEW" };
                              updateProfilePermissions(
                                event,
                                action,
                                moduleName
                              );
                            }}
                          />
                          {permissionData.getProfilePermissionDetail[
                            moduleName
                          ].map(
                            (action) =>
                              action.status === "TRUE" && (
                                <div
                                  key={action.action}
                                  className="d-flex justify-content-between cursorPointer"
                                >
                                  <div
                                    style={{
                                      color: "#515151",
                                      fontWeight: "400",
                                      fontSize: "14px",
                                    }}
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      if (
                                        selectedAction &&
                                        selectedAction.moduleName ===
                                          moduleName &&
                                        selectedAction.action.action ===
                                          action.action
                                      ) {
                                        setSelectedAction(null);
                                      } else {
                                        setSelectedAction({
                                          moduleName,
                                          action,
                                        });
                                      }
                                    }}
                                  >
                                    {action.action.charAt(0).toUpperCase() +
                                      action.action.slice(1).toLowerCase()}
                                  </div>
                                </div>
                              )
                          )}

                          {selectedAction &&
                            selectedAction.moduleName === moduleName && (
                              <div
                                className="bg-white rounded-2 w-25 p-2"
                                style={{
                                  position: "absolute",
                                  top: "0%",
                                  right: "25%",
                                  zIndex: "10",
                                }}
                              >
                                {permissionData.getProfilePermissionDetail[
                                  moduleName
                                ].map((action) => (
                                  <div key={action.action}>
                                    <input
                                      type="checkbox"
                                      checked={action.status === "TRUE"}
                                      onChange={(event) => {
                                        updateProfilePermissions(
                                          event,
                                          action,
                                          moduleName
                                        );
                                      }}
                                    />
                                    <label className="ms-2">
                                      {action.action.charAt(0).toUpperCase() +
                                        action.action.slice(1).toLowerCase()}
                                    </label>
                                  </div>
                                ))}
                              </div>
                            )}
                        </div>
                        <div
                          style={{ borderBottom: "1px solid #515151" }}
                        ></div>
                      </div>
                    )
                  )}
              </div>
            </div>
          </div>
        )}
      </div>
    );
  };

  const handleShareDataToggle = () => {
    setEditRoleId((prevState) => ({
      ...prevState,
      shareDataWithPeers: !prevState.shareDataWithPeers,
    }));
  };

  const RolesAndSharing = () => {
    return (
      <div>
        <div
          className="bg-white rounded-2 p-2"
          style={{ height: "100vh", width: "63vw" }}
        >
          {activeRole === "role" && activeRoleItems === "roles" && (
            <div>
              <div
                className="p-2"
                style={{
                  fontSize: "24px",
                  background:
                    "linear-gradient(to right, rgba(64, 123, 255, 1), rgba(18, 167, 254, 1))",
                  WebkitBackgroundClip: "text",
                  backgroundClip: "text",
                  WebkitTextFillColor: "transparent",
                }}
              >
                Roles
              </div>
              <div
                className="w-100 ms-2"
                style={{
                  color: "#515151",
                  fontSize: "16px",
                  fontWeight: "300",
                }}
              >
                This page will allow you to define how you share the data among
                users based on your organization&apos;s role hierarchy. For more
                information, refer to online help.
              </div>

              <div className="ms-2 mt-4 d-flex justify-content-between w-100">
                <div>
                  <button
                    className="createContactButtonSave"
                    onClick={() => {
                      setActiveRoleItems("add");
                      setSelectedRoleItem("")
                      
                    }}
                  >
                    Add New Role
                  </button>
                </div>
                <div className="d-flex gap-2 me-2">
                  <button
                    className="cancelRecycle px-2"
                    onClick={handleExpandAll}
                  >
                    Expand All
                  </button>
                  <button
                    className="cancelRecycle px-2"
                    onClick={handleCollapseAll}
                  >
                    Collapse All
                  </button>
                </div>
              </div>
              <div className="position-relative w-100">
                <div
                  className="emptyDiv position-absolute"
                  style={{
                    width: "100%",
                    backgroundColor: "white",
                    height: "1.25rem",
                    zIndex: "10",
                  }}
                ></div>
                <div
                  style={{ marginTop: "10px", width: "61vw", height: "60vh" }}
                  className="mb-4 settingsRole"
                >
                  <Tree
                    lineWidth={"2px"}
                    lineColor={"#9C9C9C"}
                    lineBorderRadius={"10px"}
                    lineStyle={isOnlyRootVisible ? { display: "none" } : {}}
                  >
                    {renderTree(
                      roles,
                      hiddenNodes,
                      hoveredNodeId,
                      setHoveredNodeId
                    )}
                  </Tree>
                </div>
              </div>
            </div>
          )}

          {activeRole === "role" && activeRoleItems === "add" && (
            <div>
              <div
                className="p-2"
                style={{
                  fontSize: "24px",
                  background:
                    "linear-gradient(to right, rgba(64, 123, 255, 1), rgba(18, 167, 254, 1))",
                  WebkitBackgroundClip: "text",
                  backgroundClip: "text",
                  WebkitTextFillColor: "transparent",
                }}
              >
                New Role
              </div>
              <div
                className="w-100 ms-2"
                style={{
                  color: "#515151",
                  fontSize: "16px",
                  fontWeight: "300",
                }}
              >
                This page will allow you to create a new role based on your
                organizational hierarchy. Create a new role and associate it
                with a higher role.
              </div>
              <div className="mt-4 w-100 ms-4">
                <div className="d-flex gap-2 w-100">
                  <div className="mt-2 w-50">
                    <p className="mb-0">
                      Role Name
                      <span className="text-danger">*</span>
                    </p>
                    <input
                      type="text"
                      className="inputBoxNewRole mt-1 px-2"
                      name="roleName"
                      value={addNewRoleMember.roleName}
                      onChange={handleChangeRoleDetails}
                      onKeyDown={handleKeyDown}
                      maxLength={30}
                    />
                    {roleNameError && (
                      <p style={{ color: "red" }}>{roleNameError}</p>
                    )}
                  </div>
                  <div className="mt-2 w-50">
                    <p className="mb-0">
                      Reports To
                      <span className="text-danger">*</span>
                    </p>
                    <input
                      type="text"
                      className="inputBoxNewRole cursorPointer mt-1 px-2"
                      name="reports"
                      onClick={() => {
                        setRolesModal(true);
                        setEditRoleMember({});
                      }}
                      value={selectedRoleItem}
                      onKeyDown={handleKeyDown}
                    />
                    {roleError && <p style={{ color: "red" }}>{roleError}</p>}
                  </div>
                </div>

                <div className="d-flex gap-2 align-items-center">
                  <div className="mt-2 w-50">
                    <p className="mb-0">Description</p>
                    <input
                      type="text"
                      className="inputBoxNewRole mt-1 px-2"
                      name="description"
                      value={addNewRoleMember.description}
                      onChange={handleChangeRoleDetails}
                      onKeyDown={handleKeyDown}
                      maxLength={100}
                    />
                  </div>
                  <div className="d-flex gap-3 align-items-center">
                    <div>Share Data With Peers</div>
                    <input
                      type="checkbox"
                      checked={shareDataWithPeers}
                      onChange={() =>
                        setShareDataWithPeers(!shareDataWithPeers)
                      }
                    />
                  </div>
                </div>

                <div className="mt-4 d-flex gap-2">
                  <button
                    className="cancelButton px-4"
                    onClick={() => {
                      setActiveRole("role");
                      setActiveRoleItems("roles");
                      setAddNewRoleMember({
                        roleName: "",
                        description: "",
                      });
                      setShareDataWithPeers(false);
                      setSelectedRoleItem("");
                    }}
                  >
                    Cancel
                  </button>
                  <button
                    className="unassignButton px-4"
                    onClick={addNewRoleDetails}
                    disabled={apiLoader}
                    style={{ width: "135px" }}
                  >
                    {apiLoader ? (
                      <Lottie
                        options={customDefaultOptions("white")}
                        height="24px"
                        width="36px"
                      />
                    ) : (
                      "Save"
                    )}
                  </button>
                </div>
              </div>
            </div>
          )}

          {activeRole === "role" && activeRoleItems === "delete" && (
            <div>
              <div
                className="p-2"
                style={{
                  fontSize: "24px",
                  background:
                    "linear-gradient(to right, rgba(64, 123, 255, 1), rgba(18, 167, 254, 1))",
                  WebkitBackgroundClip: "text",
                  backgroundClip: "text",
                  WebkitTextFillColor: "transparent",
                }}
              >
                Delete Role
              </div>
              <div
                style={{
                  color: "#515151",
                  fontSize: "16px",
                  fontWeight: "300",
                }}
              >
                {" "}
                Before deleting,you must transfer users and Subordinates with
                this role to a new role{" "}
              </div>
              <div className="mt-4 ms-4">Transfer Users and Sub-roles</div>
              <div className="mt-4 w-50 ms-4">
                <p className="mb-0">Roles to be Deleted</p>
                <input
                  type="text"
                  className="inputBoxNewRole mt-1"
                  name="roleName"
                  value={storeRoleNameDeleted.roleName}
                  readOnly
                />
              </div>
              <div className="mt-4 w-50 ms-4">
                <p className="mb-0">Transfer to Role</p>
                <input
                  type="text"
                  className="inputBoxNewRole cursorPointer mt-1"
                  name="reports"
                  onClick={() => setRolesModal(true)}
                  value={selectedRoleItem}
                />
              </div>
              <div className="mt-4 ms-4 d-flex gap-2">
                <button
                  className="cancelButton px-4"
                  onClick={() => {
                    setActiveRoleItems("roles");
                    setSelectedRoleItem("");
                    setStoreRoleNameDeleted("");
                  }}
                >
                  Cancel
                </button>
                <button
                  className="unassignButton px-4"
                  onClick={() => deleteRoleDetails()}
                  // style={{ width: "192px" }}
                  disabled={apiLoader}
                >
                  {apiLoader ? (
                    <Lottie
                      options={customDefaultOptions("white")}
                      height="24px"
                      width="36px"
                    />
                  ) : (
                    "Transfer and Delete"
                  )}
                </button>
              </div>
            </div>
          )}
          {activeRole === "role" && activeRoleItems === "edit" && (
            <div>
              <div
                className="p-2"
                style={{
                  fontSize: "24px",
                  background:
                    "linear-gradient(to right, rgba(64, 123, 255, 1), rgba(18, 167, 254, 1))",
                  WebkitBackgroundClip: "text",
                  backgroundClip: "text",
                  WebkitTextFillColor: "transparent",
                }}
              >
                Edit Role
              </div>
              <div className="mt-4 w-100 ms-4">
                <div className="d-flex gap-2 w-100">
                  <div className="mt-2 w-50">
                    <p className="mb-0">
                      Role Name
                      <span className="text-danger">*</span>
                    </p>
                    <input
                      type="text"
                      className="inputBoxNewRole mt-1 px-2"
                      name="roleName"
                      value={editRoleMember?.roleName}
                      onChange={handleChangeEditRoleDetails}
                      maxLength={30}
                    />
                    {roleNameError && (
                      <p style={{ color: "red" }}>{roleNameError}</p>
                    )}
                  </div>
                  <div className="mt-2 w-50">
                    <p className="mb-0">
                      Reports To
                      <span className="text-danger">*</span>
                    </p>
                    <input
                      type="text"
                      className="inputBoxNewRole cursorPointer mt-1 px-2"
                      name="reports"
                      onClick={() => setRolesModal(true)}
                      value={selectedReportsTo.roleName}
                    />

                    {roleError && <p style={{ color: "red" }}>{roleError}</p>}
                  </div>
                </div>

                <div className="d-flex gap-2 align-items-center">
                  <div className="mt-2 w-50">
                    <p className="mb-0">Description</p>
                    <input
                      type="text"
                      className="inputBoxNewRole mt-1 px-2"
                      name="description"
                      value={editRoleMember?.description}
                      onChange={handleChangeEditRoleDetails}
                      // onKeyDown={handleKeyDown}
                    />
                  </div>
                  <div className="d-flex gap-3 align-items-center">
                    <div>Share Data with peers</div>
                    <input
                      type="checkbox"
                      checked={editRoleId.shareDataWithPeers}
                      onChange={handleShareDataToggle}
                    />
                  </div>
                </div>

                <div className="mt-4 d-flex gap-2">
                  <button
                    className="cancelButton px-4"
                    onClick={() => {
                      setActiveRoleItems("roles");
                      setEditRoleMember({});
                      setEditRoleId("");
                      setStoreRoleNameDeleted("");
                    }}
                  >
                    Cancel
                  </button>
                  <button
                    className="unassignButton px-4"
                    onClick={() => editRoleDetails()}
                    disabled={apiLoader}
                  >
                    {apiLoader ? (
                      <Lottie
                        options={customDefaultOptions("white")}
                        height="24px"
                        width="36px"
                      />
                    ) : (
                      "Save"
                    )}
                  </button>
                </div>
              </div>
            </div>
          )}

          {activeRole === "role" && activeRoleItems === "viewRoles" && (
            <div>
              <div className="d-flex gap-2 align-items-center">
                <div>
                  <img
                    src={leftArrow}
                    alt="leftArrow"
                    className="cursorPointer"
                    onClick={() => {
                      setActiveRoleItems("roles");
                    }}
                  />
                </div>
                <div
                  className="p-2"
                  style={{
                    fontSize: "24px",
                    background:
                      "linear-gradient(to right, rgba(64, 123, 255, 1), rgba(18, 167, 254, 1))",
                    WebkitBackgroundClip: "text",
                    backgroundClip: "text",
                    WebkitTextFillColor: "transparent",
                  }}
                >
                  Roles Details
                </div>
              </div>
              <div
                className="bg-white rounded-2 ms-2 mt-1 me-2"
                style={{ height: "50vh", overflowY: "auto" }}
              >
                <div className="w-100 d-flex p-2" style={{ color: "#515151" }}>
                  <div
                    style={{ width: "25%" }}
                    className="d-flex justify-content-between me-2"
                  >
                    <div>Name </div>
                    <div style={{ color: "#E2E5EB" }}>|</div>
                  </div>
                  <div
                    style={{ width: "35%" }}
                    className="d-flex justify-content-between me-2"
                  >
                    <div>Email Address</div>
                    <div style={{ color: "#E2E5EB" }}>|</div>
                  </div>
                  <div
                    style={{ width: "20%" }}
                    className="d-flex justify-content-between me-2"
                  >
                    <div>User Status</div>
                    <div style={{ color: "#E2E5EB" }}>|</div>
                  </div>
                  <div style={{ width: "20%" }}>
                    <div>Confirmation Status</div>
                  </div>
                </div>
                <div
                  style={{ borderBottom: "1px solid #515151" }}
                  className="ms-2 me-2"
                ></div>
                {rolesListData.length > 0 ? (
                  rolesListData.map((item, index) => (
                    <div key={index}>
                      <div
                        className="d-flex gap-2 p-2"
                        onMouseEnter={() => handleRowMouseEnter(index)}
                        // onMouseLeave={handleRowMouseLeave}
                      >
                        <div
                          className="d-flex gap-2"
                          style={{
                            width: "25%",
                            cursor: "pointer",
                          }}
                        >
                          <span style={{ fontSize: "14px", color: "#529CF3" }}>
                            {item.userName}
                          </span>
                        </div>
                        <div
                          style={{
                            width: "35%",
                            fontSize: "14px",
                            color: "#515151",
                          }}
                        >
                          {item.emailAddress}
                        </div>
                        <div
                          style={{
                            width: "20%",
                            fontSize: "14px",
                            color: "#515151",
                          }}
                        >
                          {item.userStatus}
                        </div>
                        <div
                          style={{
                            width: "20%",
                            fontSize: "14px",
                            color: "#515151",
                          }}
                        >
                          {item.confirmedStatus}
                        </div>
                      </div>
                      {index !== groupListData.length - 1 && (
                        <div
                          style={{ borderBottom: "1px solid #515151" }}
                          className="ms-2 me-2"
                        ></div>
                      )}
                    </div>
                  ))
                ) : (
                  <div className="text-center mt-5">
                    <p>No Users found</p>
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    );
  };

  return (
    <div className="settingMain_container d-flex gap-2 mt-2">
      <div className="setting_sidebar  d-flex flex-column  bg-white rounded-2 ">
        <span className="px-2 py-1">Settings</span>
        <hr />

        <div className="menuTab_container d-flex flex-column px-2">
          <div
            className="d-flex justify-content-between cursorPointer"
            onClick={() => {
              setIsMenuOne(!isMenuOne);
              // setIsMenuTwo(false);
              // setIsMenuThree(false);
            }}
          >
            <div className="d-flex gap-2">
              <span>
                <img src={settingGroup} alt="img" height={20} width={20} />
              </span>
              <span>General</span>
            </div>
            <div>
              <span className="cursorPointer">
                <img src={downArrow} alt="img" height={15} width={12} />
              </span>
            </div>
          </div>
          {isMenuOne && (
            <>
              <span
                className={`ps-4 mt-1 ms-2  cursorPointer ${
                  menuTab === "Personal"
                    ? "selectedMenuTab "
                    : "unSelectedMenuTab "
                }`}
                onClick={() => {
                  if (menuTab !== "Personal") {
                    setMenuTab("Personal");
                    setIsMenuTwo(false);
                    setIsMenuThree(false);
                  }
                }}
              >
                Personal Profile
              </span>
              <span
                className={`ps-4 ms-2 mt-1 cursorPointer ${
                  menuTab === "User" ? "selectedMenuTab " : "unSelectedMenuTab "
                }`}
                onClick={() => {
                  if (
                    loginUserInfo?.userProfile !== "Administrator" &&
                    loginUserInfo?.userProfile.toLowerCase() !== "super admin"
                  ) {
                    setShowAccessModal(true);
                  } else {
                    if (menuTab !== "User") {
                      setMenuTab("User");
                      setSearch("");
                      getUserList(displayValue);
                      setActiveTab("user");
                      setIsMenuTwo(false);
                      setIsMenuThree(false);
                    }
                  }
                }}
              >
                User Profiles
              </span>
              <span
                className={`ps-4 mt-1 ms-2  cursorPointer ${
                  menuTab === "company"
                    ? "selectedMenuTab "
                    : "unSelectedMenuTab "
                }`}
                onClick={() => {
                  if (menuTab !== "company") {
                    setMenuTab("company");
                    setIsMenuTwo(false);
                    setIsMenuThree(false);
                  }
                }}
              >
                Company Settings
              </span>
            </>
          )}
        </div>

        <div className="menuTab_container d-flex flex-column px-2 pt-4">
          <div
            className="d-flex justify-content-between cursorPointer"
            onClick={() => {
              setIsMenuTwo(!isMenuTwo);
            }}
          >
            <div className="d-flex gap-2">
              <span>
                <img src={security} alt="img" height={20} width={20} />
              </span>
              <span>Security Control</span>
            </div>
            <div>
              <span className="cursorPointer">
                <img src={downArrow} alt="img" height={15} width={12} />
              </span>
            </div>
          </div>
          {isMenuTwo && (
            <>
              <span
                className={`ps-4 ms-2 mt-1 cursorPointer ${
                  menuTab === "securityProfile"
                    ? "selectedMenuTab "
                    : "unSelectedMenuTab "
                }`}
                onClick={() => {
                  if (
                    loginUserInfo?.userProfile !== "Administrator" &&
                    loginUserInfo?.userProfile.toLowerCase() !== "super admin"
                  ) {
                    setShowAccessModal(true);
                  } else {
                    if (menuTab !== "securityProfile") {
                      setMenuTab("securityProfile");
                      getAllProfileDetails();
                      setIsMenuOne(false);
                      setIsMenuThree(false);
                      setSearchProfile("");
                      setSelectedAction(null);
                      setProfileShow("profile");
                    }
                  }
                }}
              >
                Profile
              </span>
              <span
                className={`ps-4 ms-2 mt-1 cursorPointer ${
                  menuTab === "loginHistory"
                    ? "selectedMenuTab "
                    : "unSelectedMenuTab "
                }`}
                onClick={() => {
                  if (
                    loginUserInfo?.userProfile !== "Administrator" &&
                    loginUserInfo?.userProfile.toLowerCase() !== "super admin"
                  ) {
                    setShowAccessModal(true);
                  } else {
                    if (menuTab !== "loginHistory") {
                      setMenuTab("loginHistory");
                      setIsLoginHistoryClicked(true);
                      setIsMenuOne(false);
                      setIsMenuThree(false);
                    }
                  }
                }}
              >
                Login History
              </span>
              <span
                className={`ps-4 ms-2 mt-1 cursorPointer ${
                  menuTab === "roles"
                    ? "selectedMenuTab "
                    : "unSelectedMenuTab "
                }`}
                onClick={() => {
                  if (
                    loginUserInfo?.userProfile !== "Administrator" &&
                    loginUserInfo?.userProfile.toLowerCase() !== "super admin"
                  ) {
                    setShowAccessModal(true);
                  } else {
                    if (menuTab !== "roles") {
                      setMenuTab("roles");
                      setActiveRole("role");
                      getAllRoles();
                      setIsMenuOne(false);
                      setIsMenuThree(false);
                      setActiveRole("role");
                      setActiveRoleItems("roles");
                    }
                  }
                }}
              >
                Roles and Sharing
              </span>
              <span
                className={`ps-4 pt-2 ms-2 cursorPointer ${
                  menuTab === "Audit"
                    ? "selectedMenuTab "
                    : "unSelectedMenuTab "
                }`}
                onClick={() => {
                  if (menuTab !== "Audit") {
                    setMenuTab("Audit");
                    getAuditLogHistory();
                    setIsMenuOne(false);
                    setIsMenuThree(false);
                    resetAuditDropdown();
                    setShowDropdownFilters(false);
                  }
                }}
              >
                Audit Log
              </span>
              <span
                className={`ps-4 ms-2 mt-1 cursorPointer ${
                  menuTab === "changePassword"
                    ? "selectedMenuTab "
                    : "unSelectedMenuTab "
                }`}
                onClick={() => {
                  if (menuTab !== "changePassword") {
                    setMenuTab("changePassword");
                    setActiveRole("changePassword");
                    getAllRoles();
                    setIsMenuOne(false);
                    setIsMenuThree(false);
                    setActiveRole("changePassword");
                    setActiveRoleItems("changePassword");
                    setOldPassword("");
                    setNewPassword("");
                  }
                }}
              >
                Change Password
              </span>
            </>
          )}
        </div>

        <div className="menuTab_container d-flex flex-column px-2 mt-4">
          <div
            className="d-flex justify-content-between cursorPointer"
            onClick={() => {
              setIsMenuThree(!isMenuThree);
              // setIsMenuTwo(false);
              // setIsMenuOne(false);
            }}
          >
            <div className="d-flex gap-2">
              <span>
                <img src={databaseSetting} alt="img" height={20} width={20} />
              </span>
              <span>Data Administration</span>
            </div>
            <div>
              <span className="cursorPointer">
                <img src={downArrow} alt="img" height={15} width={12} />
              </span>
            </div>
          </div>
          {isMenuThree && (
            <>
              <span
                className={`ps-4 ms-2 mt-1 cursorPointer ${
                  menuTab === "export"
                    ? "selectedMenuTab "
                    : "unSelectedMenuTab "
                }`}
                onClick={() => {
                  setMenuTab("export");
                  setIsMenuTwo(false);
                  setIsMenuOne(false);
                  setIsExportTabClicked(true);
                  setSearchTerm("");
                  setOpenDropdown(null);
                  setShowExportDropdownFilters(false);
                  resetDropdownData();
                  setInitialExportPage("initial");
                }}
              >
                Export
              </span>
              <span
                className={`ps-4 ms-2 mt-1 cursorPointer ${
                  menuTab === "dataBackup"
                    ? "selectedMenuTab "
                    : "unSelectedMenuTab "
                }`}
                onClick={() => {
                  if (
                    loginUserInfo?.userProfile !== "Administrator" &&
                    loginUserInfo?.userProfile.toLowerCase() !== "super admin"
                  ) {
                    setShowAccessModal(true);
                  } else {
                    setMenuTab("dataBackup");
                    setIsMenuTwo(false);
                    setIsMenuOne(false);
                  }
                }}
              >
                Data backup
              </span>

              <span
                className={`ps-4 ms-2 mt-1 cursorPointer ${
                  menuTab === "removeSampleData"
                    ? "selectedMenuTab"
                    : "unSelectedMenuTab"
                }`}
                onClick={() => {
                  if (
                    loginUserInfo?.userProfile !== "Administrator" &&
                    loginUserInfo?.userProfile.toLowerCase() !== "super admin"
                  ) {
                    setShowAccessModal(true);
                  } else {
                    setMenuTab("removeSampleData");
                    setIsMenuTwo(false);
                    setIsMenuOne(false);
                  }
                }}
              >
                Remove sample data
              </span>

              <span
                className={`ps-4 ms-2 mt-1 cursorPointer ${
                  menuTab === "storage"
                    ? "selectedMenuTab "
                    : "unSelectedMenuTab "
                }`}
                onClick={() => {
                  setMenuTab("storage");
                  getStorageData();
                }}
              >
                Storage
              </span>
              <span
                className={`ps-4 ms-2 mt-1 cursorPointer ${
                  menuTab === "recycleBin"
                    ? "selectedMenuTab "
                    : "unSelectedMenuTab "
                }`}
                onClick={() => {
                  if (menuTab !== "recycleBin") {
                    setMenuTab("recycleBin");
                    getAllRecycleBinHistory();
                    setIsMenuTwo(false);
                    setIsMenuOne(false);
                  }
                }}
              >
                Recycle Bin
              </span>
            </>
          )}
        </div>
      </div>

      {menuTab === "User" && settingHomePage()}
      {menuTab === "Personal" && (
        <Personal
          userInfo={userInfo}
          setEditUserModal={setEditUserModal}
          profileInfo={profileInfo}
          setUserInfoUpdate={setUserInfoUpdate}
        />
      )}

      {menuTab === "company" && (
        <CompanySettings
          userInfo={userInfo}
          setEditUserModal={setEditUserModal}
          profileInfo={profileInfo}
          setUserInfoUpdate={setUserInfoUpdate}
        />
      )}
      {menuTab === "loginHistory" && loginHistory()}
      {menuTab === "securityProfile" && profileHistory()}
      {menuTab === "Audit" && auditLogHistory()}
      {menuTab === "changePassword" && changePassword()}
      {menuTab === "storage" && storageHistory()}
      {menuTab === "recycleBin" && recycleBinHistory()}
      {menuTab === "roles" && RolesAndSharing()}
      {menuTab === "export" && exportData()}
      {menuTab === "removeSampleData" && removeData()}

      {showAddUserModal && (
        <Modal
          show={showAddUserModal}
          className="d-flex justify-content-center align-items-start"
          aria-labelledby="example-custom-modal-styling-title"
          size="lg"
          id="commonLeadModal"
          onClick={() => setProfileListVisible(false)}
        >
          <Modal.Header
            style={{
              fontSize: "25px",
              fontWeight: "bold",
              background:
                "linear-gradient(to right, rgba(64, 123, 255, 1), rgba(18, 167, 254, 1))",
              WebkitBackgroundClip: "text",
              backgroundClip: "text",
              WebkitTextFillColor: "transparent",
            }}
          >
            {" "}
            Add User
          </Modal.Header>
          <Modal.Body className="ModalBody overflow-auto">
            <div className="d-flex flex-column">
              <div className="d-flex gap-4">
                <div>
                  <p className="mb-0">
                    Email
                    <span className="text-danger">*</span>
                  </p>
                  <input
                    type="text"
                    className="inputBoxDeal mt-1 px-2"
                    name="emailId"
                    value={email}
                    onChange={handleChangeSettings}
                    onKeyDown={handleKeyDown}
                  />
                  {error && (
                    <p style={{ color: "red", margin: "5px " }}>{error}</p>
                  )}
                </div>
                <div>
                  <div>
                    Role
                    <span className="text-danger">*</span>
                  </div>
                  <div className="position-relative">
                    <input
                      type="text"
                      className="inputBoxDeal mt-1 px-2"
                      onClick={handleRoleClick}
                      value={selectedRoleItem}
                    />
                    {roleError && <p style={{ color: "red" }}>{roleError}</p>}
                  </div>
                </div>
              </div>

              <div className="mt-2 d-flex gap-4 align-items-start ">
                <div className="position-relative">
                  <p className="mb-0">
                    Profile
                    <span className="text-danger">*</span>
                  </p>
                  <div
                    className="d-flex input-with-clear"
                    onClick={handleProfileClick}
                  >
                    <input
                      type="text"
                      placeholder="Select Profile"
                      className="inputBoxDeal cursorPointer px-2"
                      value={selectedProfileItem.profileName}
                      // onClick={handleOwnerInputClick}
                      readOnly
                    />

                    <span
                      className="clear-button-profile"
                      onClick={() => selectedItem && setSelectedItem("")}
                    >
                      <img
                        src={listVisible ? up : down}
                        alt="img"
                        height={20}
                        width={20}
                      />
                    </span>
                  </div>
                  {profileError && (
                    <p style={{ color: "red" }}>{profileError}</p>
                  )}
                  {profileListVisible && (
                    <ul
                      className="data-list list-group  settings_list"
                      style={{ top: "2rem" }}
                    >
                      {profileLists.map((item, index) => (
                        <li
                          key={index}
                          className="cursorPointer list-group-item"
                          onClick={() => handleProfileItemClick(item)}
                        >
                          <div className="d-flex flex-column">
                            <span> {item.profileName} </span>
                          </div>
                        </li>
                      ))}
                    </ul>
                  )}
                </div>

                <div className="d-flex align-items-center justify-content-end w-50 mt-4 gap-2">
                  <button
                    className="cancelButton px-4"
                    onClick={() => {
                      setShowAddUserModal(false);
                      setEmail("");
                      setSelectedProfileItem("");
                      setSelectedRoleItem("");
                    }}
                  >
                    Cancel
                  </button>
                  <button
                    className="unassignButton px-4"
                    onClick={addUser}
                    style={{ width: "135px" }}
                    disabled={apiLoader}
                  >
                    {apiLoader ? (
                      <Lottie
                        options={customDefaultOptions("white")}
                        height="24px"
                        width="36px"
                      />
                    ) : (
                      "Add User"
                    )}
                  </button>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      )}

      {rolesModal && (
        <Modal
          show={rolesModal}
          className="d-flex justify-content-center align-items-start"
          aria-labelledby="example-custom-modal-styling-title"
          size="lg"
          id="commonLeadModal"
        >
          <Modal.Header>
            <div className="d-flex justify-content-between align-items-center w-100">
              <div
                style={{
                  fontSize: "25px",
                  fontWeight: "bold",
                  background:
                    "linear-gradient(to right, rgba(64, 123, 255, 1), rgba(18, 167, 254, 1))",
                  WebkitBackgroundClip: "text",
                  backgroundClip: "text",
                  WebkitTextFillColor: "transparent",
                }}
              >
                Roles List
              </div>
              <div>
                <img
                  alt="cancel"
                  src={Cancel}
                  className="cursorPointer"
                  onClick={() => setRolesModal(false)}
                />
              </div>
            </div>{" "}
          </Modal.Header>
          <Modal.Body className="ModalBody overflow-auto">
            <div>
              <div
                style={{
                  color: "#9C9C9C",
                  fontSize: "20px",
                  fontWeight: "400",
                }}
              >
                Please assign an organizational role to this user
              </div>
              <div className=" position-relative w-100">
                <div
                  className="emptyDiv position-absolute "
                  style={{
                    width: "100%",
                    backgroundColor: "white",
                    height: "1.25rem",
                    zIndex: "10",
                  }}
                ></div>
                <div style={{ marginTop: "10px" }}>
                  <Tree
                    lineWidth={"2px"}
                    lineColor={"#9C9C9C"}
                    lineBorderRadius={"10px"}
                    nodePadding={"5px"}
                    style={{ width: "100%" }}
                  >
                    {renderTreeRolesAndSharing(roles)}
                  </Tree>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      )}

      {createGroupModal && (
        <Modal
          show={createGroupModal}
          className="d-flex justify-content-center align-items-center"
          aria-labelledby="example-custom-modal-styling-title"
          size="xl"
          id="commonLeadModal"
        >
          <Modal.Header>
            <div className="d-flex justify-content-between w-100">
              <div
                style={{
                  fontSize: "25px",
                  fontWeight: "bold",
                  background:
                    "linear-gradient(to right, rgba(64, 123, 255, 1), rgba(18, 167, 254, 1))",
                  WebkitBackgroundClip: "text",
                  backgroundClip: "text",
                  WebkitTextFillColor: "transparent",
                }}
              >
                {editRename ? "Rename Group" : "Create Group"}
              </div>
            </div>
          </Modal.Header>
          <Modal.Body className="ModalBody overflow-auto">
            <div>
              <div>
                <div>
                  Group Name
                  <span className="text-danger">*</span>
                </div>
                <div className="position-relative">
                  <input
                    type="text"
                    name="groupName"
                    className="inputCreateGroupModal mt-1 px-2"
                    maxLength={30}
                    value={
                      editRename
                        ? renameGroup?.groupName !== undefined &&
                          renameGroup?.groupName !== null
                          ? renameGroup.groupName
                          : groupName.groupName
                        : addGroup.groupName
                    }
                    onChange={handleChangeAddGroup}
                    placeholder="Enter group name"
                    onKeyDown={handleKeyDown}
                  />
                  {groupNameErrorMsg && (
                    <p style={{ color: "red" }}>{groupNameErrorMsg}</p>
                  )}
                </div>
              </div>
              <div>
                <div className="mt-4">Description</div>
                <div className="position-relative">
                  <input
                    type="text "
                    name="groupDescription"
                    className="inputCreateGroupModal mt-1 px-2"
                    maxLength={50}
                    value={
                      editRename
                        ? renameGroup?.groupDescription !== undefined &&
                          renameGroup?.groupDescription !== null
                          ? renameGroup.groupDescription
                          : groupName.groupDescription
                        : addGroup.groupDescription
                    }
                    onChange={handleChangeAddGroup}
                    placeholder="Enter description"
                    onKeyDown={handleKeyDown}
                  />
                </div>
              </div>

              <div className="d-flex gap-3 align-items-center justify-content-end mt-4">
                <button
                  className="p-1 px-4 cancelButton"
                  onClick={() => {
                    setCreateGroupModal(false);
                    setAddGroup({
                      groupName: "",
                      groupDescription: "",
                    });
                  }}
                >
                  Cancel
                </button>
                <button
                  className="p-1 px-4 unassignButton"
                  onClick={() => {
                    if (editRename) {
                      updateRenameGroup();
                    } else {
                      createNewGroup();
                    }
                  }}
                  disabled={isButtonDisabled || apiLoader}
                >
                  {apiLoader ? (
                    <Lottie
                      options={customDefaultOptions("white")}
                      height="24px"
                      width="36px"
                    />
                  ) : (
                    "Next"
                  )}
                </button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      )}

      <Modal
        show={editUserModal}
        className="d-flex justify-content-center align-items-start w-100"
        aria-labelledby="example-custom-modal-styling-title"
        id="commonLeadModal"
      >
        <Modal.Header>
          <div className="d-flex justify-content-between align-items-center w-100">
            <div
              style={{
                fontSize: "25px",
                fontWeight: "bold",
                background:
                  "linear-gradient(to right, rgba(64, 123, 255, 1), rgba(18, 167, 254, 1))",
                WebkitBackgroundClip: "text",
                backgroundClip: "text",
                WebkitTextFillColor: "transparent",
              }}
            >
              Edit User
            </div>
            <div>
              <img
                alt="cancel"
                src={Cancel}
                className="cursorPointer"
                onClick={() => {
                  setEditUserModal(false);
                }}
              />
            </div>
          </div>{" "}
        </Modal.Header>
        <Modal.Body className="ModalBody">
          <div className="ms-2 me-2 editUserModal">
            <div style={{ fontSize: "20px", fontWeight: "500" }}>
              User Information
            </div>
            <div
              className="editUserModal"
              style={{ height: "56vh", overflow: "auto", overflowX: "hidden" }}
            >
              <div className="d-flex align-items-center mt-4 pe-4 ">
                <div style={{ width: "30%" }} className="editHeader ">
                  First Name
                  <span className="text-danger">*</span>
                </div>
                <div style={{ width: "70%" }}>
                  <input
                    type="text"
                    name="firstName"
                    className="inputBoxDeal px-2"
                    value={userInfoUpdate?.firstName}
                    onChange={handleChangeUpdate}
                    onKeyDown={handleKeyDown}
                    maxLength={50}
                  />
                   </div>
                   </div>
                   <div className="ms-2 d-flex justify-content-center">
                  {updateFirstNameError && (
                    <p style={{ color: "red", fontSize: "14px" }}>
                      {updateFirstNameError}
                    </p>
                  )}
                  </div>
               

              <div
                className="d-flex  align-items-center mt-2 pe-4"
                style={{ width: "100%" }}
              >
                <div style={{ width: "30%" }} className="editHeader">
                  Last Name
                </div>
                <div style={{ width: "70%" }}>
                  <input
                    type="text"
                    name="lastName"
                    className="inputBoxDeal px-2"
                    value={userInfoUpdate?.lastName}
                    onChange={handleChangeUpdate}
                    onKeyDown={handleKeyDown}
                    maxLength={50}
                  />
                   </div>
                   </div>
                   <div className="ms-2 d-flex justify-content-center">
                  {updateLastNameError && (
                    <p style={{ color: "red", fontSize: "14px" }}>
                      {updateLastNameError}
                    </p>
                  )}
                  </div>
               

              <div
                className="d-flex  align-items-center mt-2 pe-4"
                style={{ width: "100%" }}
              >
                <div style={{ width: "30%" }} className="editHeader">
                  Email
                </div>
                <div style={{ width: "70%" }}>
                  <input
                    type="text"
                    name="email"
                    className="inputBoxDeal px-2"
                    value={userInfoUpdate?.emailId}
                    readOnly
                    onChange={handleChangeUpdate}
                    maxLength={50}
                  />
                </div>
              </div>

              <div
                className="d-flex  align-items-center mt-2 pe-4"
                style={{ width: "100%" }}
              >
                <div style={{ width: "30%" }} className="editHeader">
                  Role
                </div>
                <div style={{ width: "70%" }}>
                  <input
                    type="text"
                    name="role"
                    className="inputBoxDeal px-2"
                    value={userInfoUpdate?.userRole}
                    readOnly
                    onChange={handleChangeUpdate}
                    maxLength={30}
                  />
                </div>
              </div>
              <div
                className="d-flex align-items-center mt-2 pe-4"
                style={{ width: "100%" }}
              >
                <div style={{ width: "30%" }} className="editHeader">
                  Profile
                </div>
                <div style={{ width: "70%" }}>
                  <input
                    type="text"
                    name="profile"
                    className=" inputBoxDeal px-2 "
                    value={userInfoUpdate?.userProfile}
                    readOnly
                    onChange={handleChangeUpdate}
                    maxLength={30}
                  />
                </div>
              </div>
              <div
                className="d-flex align-items-center mt-2 pe-4"
                style={{ width: "100%" }}
              >
                <div style={{ width: "30%" }} className="editHeader">
                  Alias
                </div>
                <div style={{ width: "70%" }}>
                  <input
                    type="text"
                    name="aliasName"
                    className=" inputBoxDeal px-2"
                    value={userInfoUpdate?.aliasName}
                    onChange={handleChangeUpdate}
                    onKeyDown={handleKeyDown}
                    maxLength={30}
                  />
                   </div>
                   </div>
                   <div className="ms-2 d-flex justify-content-center">
                  {updateAliasName && (
                    <p style={{ color: "red", fontSize: "14px" }}>
                      {updateAliasName}
                    </p>
                  )}
                  </div>
               
              <div
                className="d-flex  align-items-center pe-4 mt-2"
                style={{ width: "100%" }}
              >
                <div style={{ width: "30%" }} className="editHeader">
                  Phone
                </div>
                <div style={{ width: "70%" }}>
                  <input
                    type="text"
                    name="phoneNumber"
                    pattern="[0-9]*"
                    className=" inputBoxDeal px-2"
                    value={userInfoUpdate?.phoneNumber}
                    onChange={handleChangeUpdate}
                    onKeyDown={handleKeyDown}
                    maxLength={10}
                  />
                </div>
              </div>
              <div
                className="d-flex  align-items-center   mt-2 pe-4"
                style={{ width: "100%" }}
              >
                <div style={{ width: "30%" }} className="editHeader">
                  Mobile
                </div>
                <div style={{ width: "70%" }}>
                  <input
                    type="text"
                    pattern="[0-9]*"
                    name="mobileNumber"
                    className=" inputBoxDeal px-2"
                    value={userInfoUpdate?.mobileNumber}
                    onChange={handleChangeUpdate}
                    onKeyDown={handleKeyDown}
                    maxLength={10}
                  />
                </div>
              </div>

              <div
                className="d-flex  align-items-center   mt-2 pe-4"
                style={{ width: "100%" }}
              >
                <div style={{ width: "30%" }} className="editHeader">
                  Website
                </div>
                <div style={{ width: "70%" }}>
                  <input
                    type="text"
                    name="website"
                    className="inputBoxDeal px-2"
                    value={userInfoUpdate?.website}
                    onChange={handleChangeUpdate}
                    onKeyDown={handleKeyDown}
                  />
                   </div>
                   </div>
                   <div className="ms-2 d-flex justify-content-center">
                  {updateWebsiteError && (
                    <p style={{ color: "red", fontSize: "14px" }}>
                      {updateWebsiteError}
                    </p>
                  )}
                  </div>
              
             

              <div
                className="d-flex  align-items-center mt-2 pe-4"
                style={{ width: "100%" }}
              >
                <div style={{ width: "30%" }} className="editHeader">
                  Fax
                </div>
                <div style={{ width: "70%" }}>
                  <input
                    type="text"
                    name="fax"
                    pattern="[0-9]*"
                    className=" inputBoxDeal px-2"
                    value={userInfoUpdate?.fax}
                    onChange={handleChangeUpdate}
                    onKeyDown={handleKeyDown}
                    maxLength={12}
                  />
                </div>
              </div>
              <div className=" d-flex justify-content-center me-2">
                   {updateFaxError && (
                    <p style={{ color: "red", fontSize: "14px" }}>
                      {updateFaxError}
                    </p>
                  )}
                  </div>

              <div
                className="d-flex align-items-center mt-2 pe-4"
                style={{ width: "100%" }}
              >
                <div style={{ width: "30%" }} className="editHeader">
                  Date of Birth
                </div>
                <div style={{ width: "70%" }}>
                  <input
                    type="date"
                    name="dateOfBirth"
                    className=" inputBoxDeal px-2"
                    value={userInfoUpdate?.dateOfBirth}
                    onChange={handleChangeUpdate}
                    max={formattedDate}
                     min="1912-01-01"
      
                    onKeyDown={(e) => e.preventDefault()}
                  />
                </div>
              </div>

              <div
                style={{ fontSize: "20px", fontWeight: "500" }}
                className="mt-4"
              >
                Address Information
              </div>

              <div
                className="d-flex align-items-center mt-4 pe-4"
                style={{ width: "100%" }}
              >
                <div style={{ width: "30%" }} className="editHeader">
                  Street
                </div>
                <div style={{ width: "70%" }}>
                  <input
                    type="text"
                    name="street"
                    className=" inputBoxDeal px-2"
                    value={userInfoUpdate?.street}
                    onChange={handleChangeUpdate}
                    onKeyDown={handleKeyDown}
                    maxLength={30}
                  />
                </div>
              </div>

              <div
                className="d-flex align-items-center mt-2 pe-4"
                style={{ width: "100%" }}
              >
                <div style={{ width: "30%" }} className="editHeader">
                  City
                </div>
                <div style={{ width: "70%" }}>
                  <input
                    type="text"
                    name="city"
                    className=" inputBoxDeal px-2"
                    value={userInfoUpdate?.city}
                    onChange={handleChangeUpdate}
                    onKeyDown={handleKeyDown}
                    maxLength={30}
                  />
                </div>
              </div>
              <div className=" d-flex justify-content-center me-2">
                   {updateCityError && (
                    <p style={{ color: "red", fontSize: "14px" }}>
                      {updateCityError}
                    </p>
                  )}
                  </div>

              <div
                className="d-flex align-items-center mt-2 pe-4"
                style={{ width: "100%" }}
              >
                <div style={{ width: "30%" }} className="editHeader">
                  State
                </div>
                <div style={{ width: "70%" }}>
                  <input
                    type="text"
                    name="state"
                    className=" inputBoxDeal px-2"
                    value={userInfoUpdate?.state}
                    onChange={handleChangeUpdate}
                    onKeyDown={handleKeyDown}
                    maxLength={30}
                  />
                </div>
              </div>
              <div className=" d-flex justify-content-center me-2">
                   {updateStateError && (
                    <p style={{ color: "red", fontSize: "14px" }}>
                      {updateStateError}
                    </p>
                  )}
                  </div>

              <div
                className="d-flex align-items-center mt-2 pe-4"
                style={{ width: "100%" }}
              >
                <div style={{ width: "30%" }} className="editHeader">
                  Zip Code
                </div>
                <div style={{ width: "70%" }}>
                  <input
                    type="number"
                    name="zipCode"
                    className="inputBoxDeal px-2"
                    value={userInfoUpdate?.zipCode}
                    onChange={handleChangeUpdate}
                    onKeyDown={handleKeyDown}
                    maxLength={6}
                  />
                   </div>
                   </div>
                   <div className="ms-2 d-flex justify-content-center">
                   {updateZipCodeError && (
                    <p style={{ color: "red", fontSize: "14px" }}>
                      {updateZipCodeError}
                    </p>
                  )}
                  </div>
               

              <div
                className="d-flex align-items-center mt-2 pe-4"
                style={{ width: "100%" }}
              >
                <div style={{ width: "30%" }} className="editHeader">
                  Country
                </div>
                <div style={{ width: "70%" }}>
                  <input
                    type="text"
                    name="country"
                    className=" inputBoxDeal px-2"
                    value={userInfoUpdate?.country}
                    onChange={handleChangeUpdate}
                    onKeyDown={handleKeyDown}
                  />
                </div>
              </div>
              <div className=" d-flex justify-content-center me-2">
                   {updateCountryError && (
                    <p style={{ color: "red", fontSize: "14px" }}>
                      {updateCountryError}
                    </p>
                  )}
                  </div>
            </div>

            <div className="d-flex gap-3 align-items-center justify-content-end mt-4 w-100">
              <button
                className="p-2 px-4 cancelRecycle"
                onClick={() => {
                  setEditUserModal(false);
                }}
              >
                Cancel
              </button>
              <button
                className="p-2 px-4 createContactButtonSave"
                onClick={updateUserDetails}
                disabled={apiLoader}
              >
                {apiLoader ? (
                  <Lottie
                    options={customDefaultOptions("white")}
                    height="24px"
                    width="36px"
                  />
                ) : (
                  "Save"
                )}
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        size="lg"
        show={storageModal}
        // id="changeOwnerModal"
        aria-labelledby="example-custom-modal-styling-title"
        className="withdrw_modal justify-content-center align-items-center"
      >
        <Modal.Header
          className=" border-0 mb-0 d-flex justify-content-between align-items-start"
          style={{ backgroundColor: "#F2F2F2", width: "100%" }}
        >
          <h4 className="commonTextColor">
            {camelCaseToSpace(selectedStorageModule)}
          </h4>
          <img
            src={cancelCircle}
            alt="cancel"
            style={{
              width: "25px",
              height: "25px",
              cursor: "pointer",
            }}
            onClick={() => setStorageModal(false)}
          />
        </Modal.Header>
        <Modal.Body style={{ padding: "8px 25px 10px 10px" }}>
          <div className="d-flex flex-column pt-1">
            <div
              className="w-100 ms-2 me-3 my-2 px-2 bg-white px-1  rounded-3 py-1 d-flex flex-column recordContainer list-container"
              style={{ height: "200px", overflowY: "scroll" }}
            >
              <table className="table mt-0">
                <thead>
                  <tr>
                    <th scope="col">Users</th>
                    <th scope="col">Record Count</th>
                    <th scope="col">Storage</th>
                  </tr>
                </thead>
                <tbody>
                  {storageFileDataPopup?.map((item, index) => {
                    return (
                      <tr key={index}>
                        <td>{camelCaseToSpace(item.userName)}</td>
                        <td>{item.recordCount}</td>
                        <td>{item.storage}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <AccessModal
        setShowAccessModal={setShowAccessModal}
        showAccessModal={showAccessModal}
      />
      <NotificationContainer />
    </div>
  );
};

import "./login.css";
import React, { useEffect, useState } from "react";
import { URI } from "../../constants";
import axios from "axios";
import crmLogo from "../../Assets/crmLogo2.svg";
import iconOne from "../../Assets/Frame 144.svg";
import iconTwo from "../../Assets/Frame 145.svg";
import iconThree from "../../Assets/Frame 146.svg";
import { browserName } from "react-device-detect";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  setLoginUserId,
  setLoginUserInfo,
  setUserProfileId,
  setSocialMediaInfo,
  setPlanExpiry,
  setSubscriptionType,
} from "../../Redux/stateSlice";
import OtpInput from "react-otp-input";
import Select from "react-select";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import successAnimation from "../../Assets/lotties/successAnimation.json";
import Lottie from "react-lottie";
import showPasswordIcon from "../../Assets/showPassword.svg";
import hidePasswordIcon from "../../Assets/ClosePassword.svg";
import { Tooltip } from "react-tooltip";
import alert from "../../Assets/alert-circle (1).svg";
import {
  removeExtraSpaces,
  validateFirstName,
  validateLastName,
} from "../../common/CommonComponent";
import { defaultOptions as customDefaultOptions } from "../../utils/defaultOptions";
import { v4 as uuidv4 } from "uuid";
import { handleApiError } from "../../utils/ApiUtils";

function Login() {
  // const [emailExist, setEmailExist] = useState(false); emailExist is not used anywhere
  const [emailMsg, setEmailMsg] = useState("");
  const [emailMsgSignUp,setEmailMsgSignUp]=useState("")
  const [companyMsg, setCompanyMsg] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const [loginIpAddress, setLoginIpAddress] = useState("");
  const [loginLocation, setLoginLocation] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const [loginLeftSection, setloginLeftSection] = useState("login");
  const [otp, setOtp] = useState("");
  // const [validOtp, setValidOtp] = useState(0); validOtp is not used anywhere
  const [disabled, setDisabled] = useState(false);
  const [textDisable, setTextDisable] = useState(false);
  const [timer, setTimer] = useState(60);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [emailAddress, setEmailAddress] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [phoneNo, setPhoneNo] = useState("");
  const [stateList, setStateList] = useState([]);
  const [stateName, setStateName] = useState({
    label: "",
    value: "",
  });
  const [errorMessage, setErrorMessage] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordCreate,setShowPasswordCreate]=useState(false)

  const [firstNameMsg, setFirstNameMsg] = useState("");
  const [lastNameMsg, setLastNameMsg] = useState("");
  const [passwordMsg, setPasswordMsg] = useState("");
  const [phoneNumberMsg, setPhoneNumberMsg] = useState("");
  const [isCheckboxChecked, setIsCheckboxChecked] = useState(true);
  const [isSampleData, setIsSampleData] = useState(true);
  const [apiLoader, setApiLoader] = useState(false);
  const [apiLoaderOtp,setApiLoaderOtp]=useState(false);
  const [apiLoaderCreate,setApiLoaderCreate]=useState(false)
  const d = new Date();
  const currentYear = d.getFullYear();

  const handleCheckboxChange = (e) => {
    setIsCheckboxChecked(e.target.checked);
  };
  const handleChangeSampleData = (e) => {
    setIsSampleData(e.target.checked);
  };

  const handleStateChange = async (e) => {
    setStateName(e);
  };
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  const [ipAdd, setIpAdd] = useState("");
  const [ipPhoneCode, setIpPhoneCode] = useState("");
  const [isFocused, setIsFocused] = useState(false);

  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleBlur = () => {
    setIsFocused(false);
    if (otp.length === 0) {
      setOtp("");
    }
  };

  const customSelectLocationStyles = {
    menu: (base) => ({
      ...base,
      width: "100%",
    }),
    option: (provided, state) => ({
      ...provided,
      color: "#000000",
      backgroundColor: state.isSelected ? "#407BFF" : "515151",
      width: "100%",
      cursor: "pointer",
      "&:hover": {
        backgroundColor: "#f5f5f5",
      },
    }),
    control: (baseStyles) => ({
      ...baseStyles,
      //  border: "2px solid #316AFF",
      backgroundColor: "#fff",
      height: "40px",
      borderRadius: "10px",
      width: "100%",
      boxShadow: "none",
      cursor: "pointer",
    }),
    input: (base) => ({
      ...base,
      color: "#000000",
    }),
    singleValue: (defaultStyles) => ({ ...defaultStyles, color: "#000" }),
    menuList: (provided) => ({
      ...provided,
      paddingTop: 0,
      paddingBottom: 0,
      "&::-webkit-scrollbar": {
        width: 5,
      },
      "&::-webkit-scrollbar-thumb": {
        background: "#316AFF",
        borderRadius: "10px",
      },
    }),
    dropdownIndicator: (base) => ({
      ...base,
      color: "#316AFF", // Custom color
    }),
  };
  const customFilterOption = (option, rawInput) => {
    const input = rawInput.replace(/\s+/g, "");
    return option.label
      .replace(/\s+/g, "")
      .toLowerCase()
      .includes(input.toLowerCase());
  };

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: successAnimation,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  const handleCountry = async () => {
    setStateList([]);
    const header = {
      "X-CSCAPI-KEY":
        "SVRmaGtaRDlRZmY2WkQ0Mk9YbUVta1NER3c3VEdJZUMxdGkycFVnMA==",
    };

    await axios
      .get("https://api.countrystatecity.in/v1/countries/" + "IN" + "/states", {
        headers: header,
      })
      .then((response) => {
        if (response.status === 200) {
          const newStates = response.data
            .sort((a, b) => a.name.localeCompare(b.name))
            .map((element) => ({
              label: element.name,
              value: element.iso2,
            }));
          setStateList(newStates);
          setStateName(newStates[0]);
          handleStateChange(newStates[0]);
        }
      })
      .catch((err) => {
        throw new Error("Failed to fetch data", err);
      });
  };

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const emailValidation = (emailId) => {
    if (!emailId || emailId === "") return false;
    const regex =
      /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i;
    return regex.test(emailId);
  };

  const handleEmailChange = async () => {
    // setEmail(e.target.value);
    // const value = e.target.value;
    if (emailValidation(email)) {
      setApiLoader(true);
      await axios
        .get(URI.checkEmailExist + email)
        .then((response) => {
          if (response.data.status === 200) {
            // setEmailExist(response.data.data.emailExist);
            setloginLeftSection("signInPage");
          } else {
            setEmailMsg(response.data.message);
            setTimeout(() => {
              setEmailMsg("");
            }, 3000);
          }
        })
        .catch((err) => {
          throw new Error("Failed to fetch data", err);
        })
        .finally(() => setApiLoader(false));
    }
  };

  const getUserProfileInfo = async (userId, __token) => {
    await axios
      .get(URI.getProfileUserInfo + "/" + userId + "/" + userId, {
        headers: {
          Authorization: "Basic " + __token,
        },
      })

      .then((response) => {
        if (response.data.status === 200) {
          dispatch(setLoginUserInfo(response.data.data.userInfo));
          dispatch(setSocialMediaInfo(response.data.data.socialMediaDetails));
          navigate("/home");
        } else {
          dispatch(setLoginUserInfo({}));
        }
      })
      .catch(async (error) => {
        handleApiError(error, navigate);
      })
      .finally(() => {
        setApiLoader(false);
      });
  };

  const _getAuthToken = async (__data) => {
    let requestBody = {};
    const deviceId = uuidv4();
    const headers = {
      Authorization: "Basic QWRtaW46QDEyM0Jsb2Nr",
    };
    if (password === "") {
      requestBody = {
        userId: __data.userId,
        secureToken: __data.secureToken,
        ipCountryCode: "IN",
        deviceId: deviceId, //device id for each browser
      };
    } else {
      requestBody = {
        userId: __data.userId,
        password: password,
        ipCountryCode: "IN",
        deviceId: deviceId, //device id for each browser
      };
    }

    await axios
      .post(URI.getAuthToken, requestBody, {
        headers: headers,
      })
      .then(async (_tokenResult) => {
        if (_tokenResult.data.status === 200) {
          // sessionStorage.setItem("accessToken", _tokenResult.data.data.token);
          // sessionStorage.setItem("deviceId", deviceId);
          localStorage.setItem("accessToken", _tokenResult.data.data.token);
          localStorage.setItem("deviceId", deviceId);
          dispatch(setLoginUserId(__data.userId));
          dispatch(setUserProfileId(__data.profileId));
          dispatch(setPlanExpiry(__data.planExpiryDate));
          dispatch(setSubscriptionType(__data.subscriptionType));
          getUserProfileInfo(__data.userId, _tokenResult.data.data.token);
        } else {
          NotificationManager.error("", _tokenResult.data.message, 5000);
        }
      })
      .catch((err) => {
        throw new Error("failed to fetch data", err);
      })
      .finally(() => {
        setApiLoader(false);
      });
  };

  const handleLogin = async () => {
    // jagadish13@mailinator.com
    const requestBody = {
      emailId: email,
      custCredential: password,
      loginIpAddress: loginIpAddress,
      loginLocation: loginLocation,
      loginDeviceType: "Web",
      loginBrowser: browserName,
    };

    setApiLoader(true);
    await axios
      .post(URI.signIn, requestBody)
      .then((response) => {
        if (response.data.status === 200) {
          _getAuthToken(response.data.data);
        } else {
          setErrorMsg(response.data.message);
          setApiLoader(false);
          setTimeout(() => {
            setErrorMsg("");
          }, 3000);
        }
      })
      .catch((err) => {
        throw new Error("Failed to fetch data", err);
      });
  };

  const handleForgotPassword = async (flag) => {
    setTextDisable(true);
    let requestBody = {};
    if (flag === 1) {
      requestBody = {
        emailId: email,
        flagValue: 1,
      };
    } else if (flag === 2) {
      requestBody = {
        emailId: email,
        flagValue: 2,
        otp: otp,
        custCredential: password,
      };
    }
    // setApiLoader(true);
    setApiLoaderOtp(true);
    // setApiLoaderCreate(true)
    await axios
      .post(URI.forgotPassword, requestBody)
      .then((response) => {
        if (response.data.status === 200) {
          setTextDisable(false);
          setOtp("");
          if (flag === 1) {
            // setValidOtp(response.data.data.otp);
            setloginLeftSection("createNewPassword");
            setDisabled(true);
            setTimer(60);
            // setApiLoader(false);
    setApiLoaderOtp(false)

          }
          if (flag === 2) {
            setloginLeftSection("login");
            setEmail("");
            setApiLoaderCreate(false)
          }
        } else {
          setApiLoaderOtp(false);
          setOtp("");
          setTextDisable(false);
          setErrorMsg(response.data.message);
          setTimeout(() => {
            setErrorMsg("");
          }, 3000);
        }
      })
      .catch((err) => {
        throw new Error("Failed to fetch data", err);
      })
      .finally(() => setApiLoader(false));
  };

  const handleSignInOtp = async (flag) => {
    setTextDisable(true);
    let requestBody = {};
    if (flag === 1) {
      requestBody = {
        emailId: email,
        flagValue: 1,
      };
    } else if (flag === 2) {
      requestBody = {
        emailId: email,
        flagValue: 2,
        otp: otp,
      };
    }

    setApiLoaderOtp(true);
    await axios
      .post(URI.signInOtp, requestBody)
      .then((response) => {
        if (response.data.status === 200) {
          setTextDisable(false);

          // setValidOtp(response.data.data.otp);
          if (flag === 1) {
            setloginLeftSection("otpLogin");
            setDisabled(true);
            setTimer(60);
          }
          if (flag === 2) {
            _getAuthToken(response.data.data);
            // dispatch(setLoginUserId(response.data.data.userId));
            // getUserProfileInfo(response.data.data.userId);
            // navigate("/home");
          }
        } else {
          setTextDisable(false);
          setErrorMsg(response.data.message);
          setTimeout(() => {
            setErrorMsg("");
          }, 3000);
        }
      })
      .catch((err) => {
        throw new Error("Failed to fetch data", err);
      })
      .finally(() => setApiLoaderOtp(false));
  };

  const handleResendClick = () => {
    // startTimer();
    handleSignInOtp(1);
  };
  const handleResendForgotClick = () => {
    // startTimer();
    handleForgotPassword(1);
  };

  const handleChange = (e) => {
    const value = e.target.value;
    if (/^\d*$/.test(value) && value.length <= 6) {
      setOtp(value);
    }
  };

  const handleInput = (e) => {
    const value = e.target.value;
    if (!/^\d*$/.test(value)) {
      e.target.value = value.replace(/[^\d]/g, '');
    }
  };

  useEffect(() => {
    ipDetails();
    handleCountry();

    if (localStorage.getItem("accessToken") !== null) {
      navigate("/home");
    }
  }, []);

  useEffect(() => {
    let interval;
    if (disabled) {
      interval = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);
    }

    return () => clearInterval(interval);
  }, [disabled]);

  useEffect(() => {
    if (timer === 0) {
      setDisabled(false);
    }
  }, [timer]);

  const ipDetails = async () => {
    const response = await axios.get("https://ipwhois.app/json/");
    setLoginLocation(response.data.city);
    setLoginIpAddress(response.data.ip);
    setIpAdd(response.data.ip);
    setIpPhoneCode(response.data.country_phone);
  };

  const togglePasswordVisibilityCreate = () => {
    setShowPassword(!showPassword);
  };

  const togglePasswordVisibilityNew = () => {
    setShowPasswordCreate(!showPasswordCreate);
  };

  const resetData = () => {
    setFirstName("");
    setLastName("");
    setEmailAddress("");
    setPassword("");
    setPhoneNo("");
    setCompanyName("");
  };

  const handleNewSignup = async () => {
    let valid = true;

    if (!emailValidation(emailAddress)) {
      setEmailMsgSignUp("Enter valid email address");
      setTimeout(() => {
        setEmailMsgSignUp("");
      }, 5000);
      valid = false;
    }

    if (companyName.length === 0) {
      setCompanyMsg("Company name cannot be empty");
      setTimeout(() => {
        setCompanyMsg("");
      }, 5000);
      valid = false;
    }

    if (firstName.length === 0) {
      setFirstNameMsg("First Name cannot be empty");
      setTimeout(() => {
        setFirstNameMsg("");
      }, 5000);
      valid = false;
    }

    if (lastName.length === 0) {
      setLastNameMsg("Last Name cannot be empty");
      setTimeout(() => {
        setLastNameMsg("");
      }, 5000);
      valid = false;
    }

    if (phoneNo.length === 0) {
      setPhoneNumberMsg("Phone number cannot be empty");
      setTimeout(() => {
        setPhoneNumberMsg("");
      }, 5000);
      valid = false;
    }
  

    if (password.length === 0) {
      setPasswordMsg("Password cannot be empty.");
      setTimeout(() => {
        setPasswordMsg("");
      }, 5000);
      valid = false;
    } else if (
      /^(?=.*[a-z])(?=.*[0-9])(?=.*[A-Z])(?=.*[!@#$%^&*.])[a-zA-Z0-9!@#$%^&*.]{8,40}$/.test(
        password
      ) === false
    ) {
      setPasswordMsg(
        "Password must contain at least one lowercase letter, one uppercase letter, one number, one special character and should be between 8 to 40 characters long."
      );
      setTimeout(() => {
        setPasswordMsg("");
      }, 5000);
      valid = false;
    }

    if (firstName && !validateFirstName(firstName)) {
      setFirstNameMsg("Please enter a valid First name.");
      setTimeout(() => {
        setFirstNameMsg("");
      }, 5000);
      valid = false;
    }

    if (lastName && !validateLastName(lastName)) {
      setLastNameMsg("Please enter a valid Last name.");
      setTimeout(() => {
        setLastNameMsg("");
      }, 5000);
      valid = false;
    }

    if (valid) {
      const requestBody = {
        firstName: removeExtraSpaces(firstName),
        lastName: removeExtraSpaces(lastName),
        emailId: removeExtraSpaces(emailAddress),
        custCredential: removeExtraSpaces(password),
        state: stateName.value,
        country: "India",
        ipPhoneCode: ipPhoneCode,
        ipAddress: ipAdd,
        phoneNumber: removeExtraSpaces(phoneNo),
        companyName: removeExtraSpaces(companyName),
        ipCountryCode: "IN",
        isSampleData: isSampleData,
      };

      setApiLoader(true);

      await axios
        .post(URI.signupInvitation, requestBody)
        .then((response) => {
          if (response.data.status === 200) {
            NotificationManager.success("", response.data.message, 5000);
            resetData();
            setloginLeftSection("signUpSuccess");
          } else {
            NotificationManager.error("", response.data.message, 5000);
          }
        })
        .catch((err) => {
          throw new Error("Failed to fetch data", err);
        })
        .finally(() => setApiLoader(false));
    }
  };

  const handleInputChange = (e) => {
    const value = e.target.value;
    const isNumber = /^\d+$/.test(value);

    if (value === "" || /\S/.test(value)) {
      if (isNumber && value.length <= 10) {
        setEmail(value);
      } else if (!isNumber) {
        setEmail(value);
      }
    }
  };

  const handleOtpChange = (value) => {
    const numericValue = value.replace(/\D/g, "");
    setOtp(numericValue);
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      const isEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
      if (!isEmail) {
        setErrorMessage("Please enter a valid email address.");
        setTimeout(() => {
          setErrorMessage("");
        }, 3000);
      } else {
        handleEmailChange();
      }
    }
  };

  return (
    <>
      {loginLeftSection === "newSignUp" ? (
        <div className="signUpContainerDetails">
          <div className="signUpSectionDetails">
            <div className="signupTopLine py-1">
              <img
                src={crmLogo}
                height={40}
                className="d-flex mb-4"
                alt="crm"
              />
              <h5>Create your Chain CRM Account</h5>
            </div>
            <div className="d-flex w-100 gap-5 mt-2">
              <div className="d-flex w-50 flex-column">
                <input
                  type="text"
                  className="signupField"
                  placeholder="First Name"
                  value={firstName}
                  maxLength={50}
                  onChange={(e) => {
                    const input = e.target.value;
                    const regex = /^[A-Za-z][A-Za-z\s]*$/; // Allow letters, numbers, and spaces, but not space as the first character

                    if (
                      input === "" ||
                      (input[0] !== " " && regex.test(input))
                    ) {
                      setFirstName(input);
                    }
                  }}
                />
                {firstNameMsg && (
                  <p className="mb-0" style={{ color: "#fa5a5a" }}>
                    {firstNameMsg}
                  </p>
                )}
              </div>
              <div className="d-flex w-50 flex-column">
                <input
                  type="text"
                  className="signupField"
                  placeholder="Last Name"
                  value={lastName}
                  maxLength={50}
                  onChange={(e) => {
                    const input = e.target.value;
                    const regex = /^[A-Za-z][A-Za-z\s]*$/; // Allow letters, numbers, and spaces, but not space as the first character

                    if (
                      input === "" ||
                      (input[0] !== " " && regex.test(input))
                    ) {
                      setLastName(input);
                    }
                  }}
                />
                {lastNameMsg && (
                  <p className="mb-0" style={{ color: "#fa5a5a" }}>
                    {lastNameMsg}
                  </p>
                )}
              </div>
            </div>
            <div className="d-flex w-100 gap-5">
              <div className="d-flex w-50 flex-column">
                <input
                  type="text"
                  className="signupField"
                  placeholder="Email"
                  maxLength={64}
                  value={emailAddress}
                  onChange={(e) => {
                    setEmailAddress(e.target.value.trim());
                  }}
                />
                {emailMsgSignUp && (
                  <p className="mb-0" style={{ color: "#fa5a5a" }}>
                    {emailMsgSignUp}
                  </p>
                )}
              </div>
              <div className="d-flex w-50 flex-column position-relative">
                <div className="d-flex">
                  <div style={{ position: "relative" }} className="w-100">
                    <input
                      type={showPassword ? "text" : "password"}
                      className="signupField"
                      placeholder="Password"
                      value={password}
                      onChange={(e) => {
                        setPassword(e.target.value.trim());
                      }}
                      maxLength={40}
                    />
                    <img
                      src={showPassword ? showPasswordIcon : hidePasswordIcon}
                      alt={showPassword ? "Hide Password" : "Show Password"}
                      onClick={togglePasswordVisibility}
                      className="cursorPointer"
                      style={{
                        position: "absolute",
                        right: "4%",
                        top: "50%",
                        transform: "translateY(-50%)",
                      }}
                    />
                  </div>

                  <Tooltip
                    id="tooltip"
                    place="top"
                    className="custom-tooltip"
                  />
                  <span
                    className="py-2 mt-1 ms-3 position-absolute tooltipIcon"
                    data-tooltip-id="tooltip"
                    data-tooltip-content="Password must contain at least one lowercase letter, one uppercase letter, one number, one special character and should be between 8 to 40 characters long."
                  >
                    <img
                      src={alert}
                      height={20}
                      width={20}
                      alt="icon"
                      className="cursorPointer"
                      style={{ marginTop: "-4px" }}
                    />
                  </span>
                </div>

                {passwordMsg && (
                  <p style={{ color: "#fa5a5a" }}>{passwordMsg}</p>
                )}
              </div>
            </div>

            <div className="d-flex w-100 gap-5">
              <div className="d-flex w-50 flex-column">
                <input
                  type="text"
                  className="signupField"
                  placeholder="Company Name"
                  value={companyName}
                  maxLength={50}
                  onChange={(e) => {
                    const input = e.target.value;
                    const regex = /^[A-Za-z0-9\W][A-Za-z0-9\s\W]*$/; // Allow letters, numbers, and special characters, but not space as the first character

                    if (
                      input === "" ||
                      (input[0] !== " " && regex.test(input))
                    ) {
                      setCompanyName(input);
                    }
                  }}
                />
                {companyMsg && (
                  <p className="mb-0" style={{ color: "#fa5a5a" }}>
                    {companyMsg}
                  </p>
                )}
              </div>
              <div className="d-flex w-50 flex-column">
                <input
                  type="text"
                  pattern="[0-9]*"
                  inputMode="numeric"
                  className="signupField w-100"
                  placeholder="Phone"
                  value={phoneNo}
                  maxLength={10}
                  onChange={(e) => {
                    // Remove non-numeric characters
                    const input = e.target.value.replace(/\D/g, "");
                    setPhoneNo(input);
                    if (input.length < 10) {
                      setPhoneNumberMsg("Please enter valid phone Number");
                  } else {
                    setPhoneNumberMsg("");
                  }
                }}
                />
                {phoneNumberMsg && (
                  <p className="mb-0" style={{ color: "#fa5a5a" }}>
                    {phoneNumberMsg}
                  </p>
                )}
              </div>
            </div>
            <div className="d-flex w-100 gap-5">
              <input
                type="text"
                className="signupInputField"
                placeholder="Country"
                value="India"
                style={{ width: "50%" }}
                readOnly
              />
              <Select
                value={stateName}
                menuPlacement="auto"
                onChange={(e) => handleStateChange(e)}
                options={stateList}
                styles={customSelectLocationStyles}
                className="w-50"
                filterOption={customFilterOption}
              />
            </div>
            <div className="d-flex justify-content-start align-items-start w-100">
              <div>
                <input
                  type="checkbox"
                  checked={isCheckboxChecked}
                  onChange={handleCheckboxChange}
                  style={{ height: "27px" }}
                />
              </div>
              <p className="ms-2">
                I agree to the{" "}
                <span
                  style={{
                    color: "#407BFF",
                    fontWeight: "bold",
                    cursor: "pointer",
                  }}
                >
                  Term of Use
                </span>{" "}
                and{" "}
                <span
                  style={{
                    color: "#407BFF",
                    fontWeight: "bold",
                    cursor: "pointer",
                  }}
                >
                  Privacy Policy
                </span>{" "}
                of Block Stack Corporation{" "}
              </p>
            </div>
            <div
              className="d-flex justify-content-start align-items-start w-100"
              style={{ marginTop: "-48px" }}
            >
              <div>
                <input
                  type="checkbox"
                  checked={isSampleData}
                  onChange={handleChangeSampleData}
                  style={{ height: "27px" }}
                />
              </div>
              <div className="ms-2">I want to add sample data</div>
            </div>
            <div className="d-flex justify-content-center gap-3">
              <button
                className="signupBtn"
                style={{ width: "120px" }}
                onClick={() => {
                  setloginLeftSection("login");
                  setPassword("");
                  setShowPassword(false);
                  setEmail("")
                }}
              >
                Back
              </button>
              <button
                className="LoginNextBtn"
                style={{ width: "120px", opacity: isCheckboxChecked ? 1 : 0.5 }}
                onClick={() => handleNewSignup()}
                disabled={!isCheckboxChecked || apiLoader}
              >
                {apiLoader ? (
                  <Lottie
                    options={customDefaultOptions("white")}
                    height="24px"
                    width="36px"
                  />
                ) : (
                  "Sign Up"
                )}
              </button>
            </div>
          </div>
        </div>
      ) : loginLeftSection === "signUpSuccess" ? (
        <div className="accountActivation">
          <div
            className="d-flex justify-content-center align-items-center"
            style={{ width: "100%", height: "100vh" }}
          >
            <div className="py-2">
              <div
                className="mt-4 accountSuccess d-flex flex-column justify-content-center p-2"
                style={{ height: "62vh", width: "40vw" }}
              >
                <div className="d-flex justify-content-center">
                  <img src={crmLogo} alt="logo" />
                </div>
                <div className="mt-4">
                  <Lottie
                    options={defaultOptions}
                    height="15vh"
                    width="13.5vw"
                  />
                </div>
                <div
                  className="text-center mt-4"
                  style={{
                    color: "#000000",
                    fontWeight: "500",
                    fontSize: "30px",
                  }}
                >
                  Successful!
                </div>
                <div
                  style={{
                    fontSize: "15px",
                    color: "#000000",
                    fontWeight: "300",
                  }}
                  className="ms-2 mt-1"
                >
                  <div className="text-center">
                    {" "}
                    Congratulations, your account has been created.
                  </div>
                </div>
                <div className="mt-4 ms-2 d-flex justify-content-center">
                  <button
                    className="verifyButton p-2"
                    onClick={() => {
                      setloginLeftSection("login");
                    }}
                  >
                    Sign In
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="loginContainer">
          {/* <div className="rotateEffect"></div> */}
          <div className="loginSection">
            <div className="loginLeft">
              <img src={crmLogo} height={35} className="d-flex" alt="" />
              {loginLeftSection === "login" && (
                <div className="loginInput mb-1">
                  <div className="mb-2 mt-4">
                    <div className="fw-bold" style={{ fontSize: "22px" }}>
                      Sign in
                    </div>
                  </div>
                  <div className="d-flex flex-column gap-2 w-100 pe-5 mt-3">
                    <input
                      type="text"
                      className="loginEmailField mb-3 ps-3"
                      placeholder="Email address"
                      // onChange={handleEmailChange}
                      onKeyDown={handleKeyPress}
                      onChange={handleInputChange}
                      value={email}
                    />
                    {errorMessage !== "" && (
                      <p className="mb-0" style={{ color: "#fa5a5a" }}>
                        {errorMessage}
                      </p>
                    )}

                    <button
                      className="LoginNextBtn"
                      onClick={() => {
                        // const isNumber = /^\d{10}$/.test(email);
                        const isEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(
                          email
                        );

                        if (!isEmail) {
                          setErrorMessage(
                            "Please enter a valid email address."
                          );
                          setTimeout(() => {
                            setErrorMessage("");
                          }, 3000);
                        } else {
                          handleEmailChange();
                        }
                      }}
                      disabled={apiLoader}
                    >
                      {apiLoader ? (
                        <Lottie
                          options={customDefaultOptions("white")}
                          height="24px"
                          width="36px"
                        />
                      ) : (
                        "Next"
                      )}
                    </button>
                    <p>
                      Don’t have an account?{" "}
                      <span
                        style={{ color: "#44abfd", cursor: "pointer" }}
                        onClick={() => {
                          setloginLeftSection("newSignUp");
                          setPassword("");
                          setShowPassword(false);
                        }}
                      >
                        Sign Up
                      </span>
                    </p>
                    <div className="mt-5" style={{ fontSize: "0.75rem" }}>
                      ©{currentYear} Diamante Blockchain Holdings Inc. All
                      Rights Reserved.
                    </div>
                  </div>
                  {emailMsg !== "" && (
                    <p className="mb-0 mt-1" style={{ color: "#fa5a5a" }}>
                      {emailMsg}
                    </p>
                  )}
                </div>
              )}
              {loginLeftSection === "forgotPassword" && (
                <div className="loginInput">
                  <div className="mb-4">
                    <h4>Forgot Password</h4>
                    <p style={{ fontSize: "1rem" }}>
                      Please enter your email Address
                    </p>
                  </div>
                  <div className="d-flex flex-column gap-4 w-100 pe-5">
                    <input
                      type="text"
                      className="loginEmailField"
                      placeholder="Email address or Mobile Number"
                      onChange={handleEmailChange}
                      value={email}
                    />
                    {emailMsg !== "" && (
                      <p className="mb-0" style={{ color: "#fa5a5a" }}>
                        {emailMsg}
                      </p>
                    )}
                    {/* {emailExist && (
                  <input
                    type="text"
                    className="loginEmailField"
                    placeholder="Enter Password"
                    value={password}
                    onChange={(e) => {
                      setPassword(e.target.value);
                    }}
                  />
                )} */}
                    {errorMsg !== "" && (
                      <p className="mb-0" style={{ color: "#fa5a5a" }}>
                        {errorMsg}
                      </p>
                    )}
                    <button
                      className="LoginNextBtn"
                      onClick={() => {
                        handleForgotPassword(1);
                      }}
                      disabled={apiLoaderOtp}
                    >
                      {apiLoaderOtp ? (
                        <Lottie
                          options={customDefaultOptions("white")}
                          height="24px"
                          width="36px"
                        />
                      ) : (
                        "Next"
                      )}
                    </button>
                  </div>
                </div>
              )}
              {loginLeftSection === "createNewPassword" && (
                <>
                  <div className="mb-4">
                    <p style={{ fontSize: "1rem" }}>Please enter the OTP</p>
                    <OtpInput
                      value={otp}
                      onChange={handleOtpChange}
                      numInputs={6}
                      renderInput={(props) => (
                        <input {...props} className="otp-input" />
                      )}
                    />
                    <div
                      className="mt-2 mb-2"
                      style={{ textAlignLast: "right" }}
                    >
                      {!disabled && (
                        <p
                          className="text-end mb-0"
                          style={{
                            color: "#407BFF",
                            cursor: textDisable?"not-allowed":"pointer",
                            fontSize: "smaller",
                            opacity: textDisable ? "0.5" : "1",
                          }}
                          onClick={() => {
                            textDisable ? <></> : handleResendForgotClick();
                          }}
                          disabled={disabled}
                        >
                          Resend OTP
                        </p>
                      )}
                      {disabled && (
                        <span style={{ color: "#515151", fontSize: "smaller" }}>
                          Resend in {timer}s
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="d-flex flex-column gap-2 w-100 pe-5">
                    <h4>Create a New Password</h4>
                    <div className="w-100 position-relative">
  <input
    type={showPasswordCreate ? "text" : "password"}
    className="signupInputFieldPassword"
    placeholder="New Password"
    value={password}
    onChange={(e) => {
      setPassword(e.target.value.trim());
    }}
    maxLength={40}
    style={{ paddingRight: '50px' }} 
  />
  <img
    src={showPasswordCreate ? showPasswordIcon : hidePasswordIcon}
    alt={showPasswordCreate ? "Hide Password" : "Show Password"}
    onClick={togglePasswordVisibilityNew}
    className="cursorPointer"
    style={{
      position: "absolute",
      right: "5%",
      top: "50%",
      transform: "translateY(-50%)",
    }}
  />
  <Tooltip id="tooltip" place="top" className="custom-tooltip" />
  <span
    className="py-2 mt-1 ms-3 position-absolute tooltipIcon"
    data-tooltip-id="tooltip"
    data-tooltip-content="Password must contain at least one lowercase letter, one uppercase letter, one number, one special character and should be between 8 to 40 characters long."
  >
    <img
      src={alert}
      height={20}
      width={20}
      alt="icon"
      className="cursorPointer infoImage"
      style={{ marginTop: "-4px" }}
    />
  </span>
</div>

                <div className="w-100 position-relative">
                    <input
                    type={showPassword ? "text" : "password"}
                      className="signupInputFieldPassword"
                      placeholder="Confirm Password"
                      value={confirmPassword}
                      onChange={(e) => {
                        setConfirmPassword(e.target.value.trim());
                      }}
                      maxLength={40}
                      style={{ paddingRight: '50px' }}
                    />
                      <img
                  src={showPassword ? showPasswordIcon : hidePasswordIcon}
                  alt={showPassword ? "Hide Password" : "Show Password"}
                  onClick={togglePasswordVisibilityCreate}
                  className="cursorPointer"
                  style={{
                    position: "absolute",
                    right: "5%",
                    top: "50%",
                    transform: "translateY(-50%)",
                  }}
                />
                  </div>
                    {password !== confirmPassword && confirmPassword !== "" && (
                      <p className="mb-0 error" style={{ color: "#fa5a5a" }}>
                        Password does not match
                      </p>
                    )}
                    {password !== "" &&
                      !password.match(
                        /^(?=.*[a-z])(?=.*[0-9])(?=.*[A-Z])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,40}$/
                      ) && (
                        <p className="mb-0 error" style={{ color: "#fa5a5a" }}>
                          Weak Password
                        </p>
                      )}
                    {errorMsg !== "" && (
                      <p className="mb-0 error" style={{ color: "#fa5a5a" }}>
                        {errorMsg}
                      </p>
                    )}
                  
                  <button
                   style={{
                    opacity: password.length > 7 && confirmPassword.length>7 ? "1" : "0.7",
                    cursor: password.length > 7 && confirmPassword.length>7 ? "pointer" : "not-allowed",
                  }}
  className="LoginNextBtn"
  onClick={() => {
    handleForgotPassword(2);
    setOtp(0);
    setPassword("");
    setConfirmPassword("");
  }}
  disabled={
    !password || 
    !confirmPassword || 
    password !== confirmPassword ||
    !password.match(
      /^(?=.*[a-z])(?=.*[0-9])(?=.*[A-Z])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,40}$/
    ) ||
    apiLoaderCreate
  }
>
  {apiLoaderCreate ? (
    <Lottie
      options={customDefaultOptions("white")}
      height="24px"
      width="36px"
    />
  ) : (
    "Next"
  )}
</button>

                  </div>
                </>
              )}
              {loginLeftSection === "signInPage" && (
                <div className="loginInput mt-2">
                  <div>
                    <div style={{ fontSize: "22px" }} className="fw-bold mt-2">
                      Sign in
                    </div>
                  </div>
                  <div className="d-flex flex-column gap-2 w-100 pe-5 mt-4">
                    <div
                      className="input-container d-flex pe-2 ps-2"
                      style={{
                        borderRadius: "5px",
                        border: "1px solid #D9D9D9",
                        justifyContent: "space-between",
                        backgroundColor: "#ffffff",
                      }}
                    >
                      <input
                        type="text"
                        className="loginEmailField mb-1"
                        style={{ border: "none", width: "70%" }}
                        placeholder="Email address or Mobile Number"
                        // onChange={handleEmailChange}
                        value={email}
                        readOnly
                      />
                      <p
                        className="mt-2"
                        style={{
                          color: "#407BFF",
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          setloginLeftSection("login");
                          setPassword("")
                        }}
                      >
                        Change
                      </p>
                    </div>
                    {emailMsg !== "" && (
                      <p className="mb-0" style={{ color: "#fa5a5a" }}>
                        {emailMsg}
                      </p>
                    )}
                    <div className="w-100 position-relative">
                      <input
                        type={showPassword ? "text" : "password"}
                        className="signupInputFieldPassword"
                        placeholder="Enter Password"
                        value={password}
                        onChange={(e) => {
                          e.target.value.charAt(0) !== " " &&
                            setPassword(e.target.value);
                        }}
                        maxLength={40}
                        style={{paddingRight:"50px"}}
                      />
                      <img
                        src={showPassword ? showPasswordIcon : hidePasswordIcon}
                        alt={showPassword ? "Hide Password" : "Show Password"}
                        onClick={togglePasswordVisibility}
                        className="cursorPointer"
                        style={{
                          position: "absolute",
                          right: "5%",
                          top: "20%",
                        }}
                      />
                    </div>

                    {errorMsg !== "" && (
                      <p className="mb-0" style={{ color: "#fa5a5a" }}>
                        {errorMsg}
                      </p>
                    )}
                    <div className="d-flex justify-content-between">
                      <p
                        className="text-end mb-0"
                        style={{
                          color: "#407BFF",
                          cursor: "pointer",
                          fontSize: "smaller",
                          opacity: textDisable ? "0.5" : "1",
                        }}
                        onClick={() => {
                          textDisable ? <></> : handleSignInOtp(1);
                        }}
                      >
                        Login using OTP
                      </p>
                      <p
                        className="text-end mb-0"
                        style={{
                          color: "#407BFF",
                          cursor: "pointer",
                          fontSize: "smaller",
                        }}
                        onClick={() => {
                          setloginLeftSection("forgotPassword");
                          setPassword("");
                        }}
                      >
                        Forgot Password?
                      </p>
                    </div>
                    <button
                      className="LoginNextBtn mt-1"
                      style={{
                        opacity: password.length > 7 ? "1" : "0.7",
                        cursor: password.length > 7 ? "pointer" : "not-allowed",
                      }}
                      onClick={() => (password.length > 7 ? handleLogin() : "")}
                      disabled={apiLoader}
                    >
                      {apiLoader ? (
                        <Lottie
                          options={customDefaultOptions("white")}
                          height="24px"
                          width="36px"
                        />
                      ) : (
                        "Next"
                      )}
                    </button>
                    {/* <p>
                      Don’t have an account?{" "}
                      <span
                        style={{ color: "#44abfd", cursor: "pointer" }}
                        onClick={() => {
                          setloginLeftSection("newSignUp");
                          setPassword("");
                        }}
                      >
                        Sign Up now
                      </span>
                     
                    </p> */}
                    <div className="mt-5" style={{ fontSize: "0.75rem" }}>
                      ©{currentYear} Diamante Blockchain Holdings Inc. All
                      Rights Reserved.
                    </div>
                  </div>
                </div>
              )}
              {loginLeftSection === "otpLogin" && (
                <div className="loginInput">
                  <>
                    <div className="mt-4">
                      <h4>Sign in</h4>
                    </div>
                    <div className="d-flex flex-column gap-2 w-100 pe-5 mt-2">
                      <div
                        className="input-container d-flex pe-2 ps-2"
                        style={{
                          borderRadius: "5px",
                          border: "1px solid #D9D9D9",
                          justifyContent: "space-between",
                          backgroundColor: "#ffffff",
                        }}
                      >
                        <input
                          type="text"
                          className="loginEmailField"
                          style={{ border: "none", width: "70%" }}
                          placeholder="Email address or Mobile Number"
                          onChange={handleEmailChange}
                          value={email}
                          readOnly
                        />
                        <p
                          className="mt-2"
                          style={{
                            color: "#407BFF",
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            setloginLeftSection("login");
                           
                          }}
                        >
                          Change
                        </p>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          border: "1px solid #D9D9D9",
                          borderRadius: "4px",
                          width: "100%",
                          height: "45px",
                          padding: "0.5rem",
                          position: "relative",
                          backgroundColor:"#fff"
                        }}
                      >
                        {Array.from({ length: 6 }).map((_, index) => (
                          <div
                            key={index}
                            style={{
                              width: "2rem",
                              height: "2rem",
                              borderRadius: "50%",
                              margin: "0 0.25rem",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              color:"#515151"
                            }}
                          >
                            {isFocused || otp.length > 0 || otp[index]
                              ? otp[index] || "•"
                              : ""}
                          </div>
                        ))}
                        <input
                          type="text"
                          value={otp}
                          onChange={handleChange}
                          onInput={handleInput}
                          placeholder="Enter OTP"
                          onFocus={handleFocus}
                          onBlur={handleBlur}
                          style={{
                            position: "absolute",
                            opacity: isFocused || otp.length > 0 ? 0 : 1,
                            width: "100%",
                            height: "100%",
                            border: "none",
                            top: 0,
                            left: 0,
                            paddingLeft: "0.9rem",
                          }}
                        />
                      </div>
                      
                    </div>

                    {/* <OtpInput
                  value={otp}
                  onChange={setOtp}
                  numInputs={6}
                  renderSeparator={<span>-</span>}
                  renderInput={(props) => (
                    <input {...props} className="otp-input-login" />
                  )}
                /> */}
                  </>
                  
                  <div className="d-flex flex-column gap-2 w-100 pe-5">
                    {emailMsg !== "" && (
                      <p className="mb-0" style={{ color: "#fa5a5a" }}>
                        {emailMsg}
                      </p>
                    )}
                    {errorMsg !== "" && (
                      <p className="mb-0" style={{ color: "#fa5a5a" }}>
                        {errorMsg}
                      </p>
                    )}

                    <div className="d-flex justify-content-between mt-2 mb-2">
                      <p
                        className="text-end mb-0"
                        style={{
                          color: "#407BFF",
                          cursor: "pointer",
                          fontSize: "smaller",
                        }}
                        onClick={() => {
                          setloginLeftSection("signInPage");
                        }}
                      >
                        Login using Password
                      </p>
                      {!disabled && (
                        <p
                          className="text-end mb-0"
                          style={{
                            color: "#407BFF",
                            cursor: "pointer",
                            fontSize: "smaller",
                            opacity: textDisable ? "0.5" : "1",
                          }}
                          onClick={() => {
                            textDisable ? <></> : handleResendClick();
                          }}
                          disabled={disabled}
                        >
                          Resend OTP
                        </p>
                      )}
                      {disabled && (
                        <span style={{ color: "#515151", fontSize: "smaller" }}>
                          Resend in {timer}s
                        </span>
                      )}
                    </div>
                    <button
                      className="LoginNextBtn"
                      style={{
                        opacity: otp.length > 5 ? "1" : "0.7",
                        cursor: otp.length > 5 ? "pointer" : "not-allowed",
                      }}
                      onClick={() => (otp.length > 5 ? handleSignInOtp(2) : "")}
                      disabled={apiLoader}
                    >
                      {apiLoader ? (
                        <Lottie
                          options={customDefaultOptions("white")}
                          height="24px"
                          width="36px"
                        />
                      ) : (
                        "Verify"
                      )}
                    </button>
                    {/* <p>Don’t have a account? <span style={{ color: "#44abfd", cursor: "pointer" }}>Sign Up now</span></p> */}
                  </div>
                  <div className="mt-5" style={{ fontSize: "0.75rem" }}>
                      ©{currentYear} Diamante Blockchain Holdings Inc. All
                      Rights Reserved.
                    </div>

                </div>
              )}
            </div>
            <div className="loginRight">
              <div className="loginDiv">
                <img src={iconOne} height={60} alt="" />
                <div>
                  <p className="mb-0 fw-bold">Easy User Interface</p>
                  <div className="mb-0">
                    Simple, intuitive, and user-friendly interface for a
                    seamless user experience.
                  </div>
                </div>
              </div>
              <div className="loginDiv">
                <img src={iconTwo} height={60} alt="" />
                <div>
                  <p className="mb-0 fw-bold">Report to Manager</p>
                  <div className="mb-0">
                    Streamline sales, prioritize and track leads and identify
                    opportunities.
                  </div>
                </div>
              </div>
              <div className="loginDiv">
                <img src={iconThree} height={60} alt="" />
                <div>
                  <p className="mb-0 fw-bold">Easy Add Edit & Update</p>
                  <div className="mb-0">
                    Run effective and targeted campaigns resulting in higher
                    conversions rates.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <NotificationContainer />
    </>
  );
}

export default Login;

import React, { useState, useEffect, useRef } from "react";
import filterIcon from "../../Assets/Frame (3).svg";
import Select from "react-select";
import axios from "axios";
import { URI } from "../../constants";
import groupIcon from "../../Assets/De-Active/Vendor.svg";
import {
  glAccountOptions,
  massOptions,
  purchaseColumns,
} from "../../common/CommonData";
import "../Lead/lead.css";
import down from "../../Assets/Down31.svg";
import up from "../../Assets/Up.svg";
import updateIcon from "../../Assets/Group 71.svg";
import cancelIcon from "../../Assets/Group 72.svg";
import "./vendor.css";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import "react-notifications/lib/notifications.css";
import EditableInput from "../../common/EditableInput";
import downloadIcon from "../../Assets/Frame (5).svg";
import attachIcon from "../../Assets/file-attachment.svg";
import prodIcon from "../../Assets/product.svg";
import openActivityIcon from "../../Assets/openActivity.svg";
import closeActivityIcon from "../../Assets/closeActivity.svg";
import inviteIcon from "../../Assets/invite.svg";
import searchIcon from "../../Assets/search.svg";
import userSearch from "../../Assets/user-search-01.svg";
import groupIcon2 from "../../Assets/Group 74.svg";
import history from "../../Assets/history.svg";
// import hierarchy from "../../Assets/hierarchy.svg";
import Pagination from "../../common/Pagination";
import { pageOption } from "../../common/CommonData";
import LeadOwnerModal from "../../common/LeadOwnerModal";
import { Modal } from "react-bootstrap";
import tableFilterIcon from "../../Assets/filterIcon.svg";
import filterLine from "../../Assets/filterLine.svg";
import filterMenu from "../../Assets/filterMenu.svg";
import pin from "../../Assets/Pin_column.svg";
import unPin from "../../Assets/Unpin.svg";
import arrowUp from "../../Assets/square_arrow_up.svg";
import arrowDown from "../../Assets/square_arrow_down.svg";
import cancel from "../../Assets/cancel_circle.svg";
import NoteBook from "../../Assets/notebook-01.svg";
import Note from "../../common/commonRelatedList/Note";
import vendorAvatar from "../../Assets/vendorAvatar.svg";
import Product from "../../common/commonRelatedList/ProductList";
import OpenActivity from "../../common/commonRelatedList/OpenActivity";
import CloseActivity from "../../common/commonRelatedList/CloseActivity";
import PurchaseCommonTable from "../../common/commonRelatedList/PurchaseCommonTable";
import {
  emailValidation,
  validateCityIndia,
  validateCountryName,
  validateFirstName,
  validateMobileNumber,
  validateCategory,
  validateStateIndia,
  validateZipCode,
  removeExtraSpaces,
  customCommonStyles,
} from "../../common/CommonComponent";
import Clone from "../../Assets/Clone.svg";
import Share from "../../Assets/ShareIcon.svg";
import Delete from "../../Assets/DeleteIconNew.svg";
import ShareModal from "../../common/ShareModal";
import { useSelector } from "react-redux";
import ContactCommonTable from "../../common/ContactCommonTable";
import Loader from "../../common/commonRelatedList/Loader";
import vendorNoteData from "../../Assets/vendorNoteData.svg";
import { websiteValidation } from "../../common/CommonComponent";
import AccessModal from "../../common/AccessModal";
import ChangeOwnerModal from "../../common/commonRelatedList/ChangeOwnerModal";
import {
  leftArrow,
  rightAngleArrow,
  leftAngleArrow,
  tick,
  expandArrow,
  shrinkArrow,
} from "../../common/commonSVGs/commonSVGIcons.js";
// import AddShareMemberModal from "../../common/AddShareMemberModal";
import { EXPLORER_URL } from "../../constants/DataConst.js";
import useScrollToUndo from "../../hooks/useScrollToUndo.js.js";
import Lottie from "react-lottie";
import { defaultOptions } from "../../utils/defaultOptions.js";
import getHeaders from "../../common/ApiHeader.js";
import { validateVendorData } from "../../Handlers/vendorPage/VendorErrors.js";
import {
  handleSubmitVendorDetails,
  updateVendorDetails,
} from "../../Handlers/vendorPage/VendorApis.js";
import { handleApiError } from "../../utils/ApiUtils";
import { useNavigate } from "react-router-dom";
import MassDelete from "../../atoms/vendor/MassDelete.js";
import MassTransfer from "../../atoms/vendor/MassTransfer.js";
import MassUpdate from "../../atoms/vendor/MassUpdate.js";
import PurchaseOrderVendorModal from "../../atoms/vendor/PurchaseOrderVendorModal.js";
import VendorManageContactColumnModal from "../../atoms/vendor/VendorManageContactColumnModal.js";
import VendorDeleteModal from "../../atoms/vendor/VendorDeleteModal.js";
import VendorRemoveModal from "../../atoms/vendor/VendorRemoveModal.js";
import VendorShowImageModal from "../../atoms/vendor/VendorShowImageModal.js";

export const Vendor = ({
  setCurrentView,
  setInnerPage,
  innerPage,
  permissionList,
  notePermission,
  setPastViewInfo,
  pastViewInfo,
  currentView,
  setFetchVendorInfo,
  fetchVendorInfo,
  storeModuleId,
}) => {
  const navigate = useNavigate();
  const userList = JSON.parse(sessionStorage.getItem("userList"));
  // const [filterView, setFilterView] = useState(false); setFilterView is not used anywhere so i am commenting this
  // const [activeTab, setActiveTab] = useState("note"); activeTab is not used anywhere so i am commenting this
  const [emailOptOut, setEmailOptOut] = useState(false);
  // const [deleteMsg, setDeleteMsg] = useState(""); setDeleteMsg is not used anywhere so i am commenting this
  const [mandatoryMsg, setMandatoryMsg] = useState("");
  const [showOptionsVendor, setShowOptionsVendor] = useState(false);
  const [shareModal, setShareModal] = useState(false);
  const [dropdownAllDetails, setSelectDropdownAllDetails] = useState({
    name: "Vendor Name",
    value: "vendorName",
  });
  const [searchTerm, setSearchTerm] = useState("");
  const [massVal, setMassVal] = useState("");
  // const [emailErrorMsg, setEmailErrorMsg] = useState(""); emailErrorMsg is not used anywhere so i am commenting this
  const [websiteMsg, setWebsiteMsg] = useState("");
  const [showRemoveModal, setShowRemoveModal] = useState(false);
  const [showAccessModal, setShowAccessModal] = useState(false);
  const [imageLoader, setImageLoader] = useState(false);
  const [selectedOwnerItem, setSelectedOwnerItem] = useState(userList);
  const [isChangeOwnerList, setIsChangeOwnerList] = useState(false);
  const [isOwnerSelect, setIsOwnerSelect] = useState(false);
  const [emailNotification, setEmailNotification] = useState(true);
  const [isFirstCharacterEntered, setIsFirstCharacterEntered] = useState(false);
  const [noteDeleteConfirmation, setNoteDeleteConfirmation] = useState(false);
  const [isFieldSelected, setIsFieldSelected] = useState(
    dropdownAllDetails.name !== ""
  );
  // const [isValueEntered, setIsValueEntered] = useState(false); isValueEntered is not used anywhere so i am commenting this

  const [imageMsg, setImageMsg] = useState("");
  const [apiLoader, setApiLoader] = useState(false);

  // for save and new && save lottie loader
  const [saveAndNewSubmit, setSaveAndNewSubmit] = useState(false);
  const [btnLoader, setBtnLoader] = useState(false);

  useScrollToUndo(pastViewInfo, setPastViewInfo); // for scroll to previously clicked section
  const reduxStore = useSelector((state) => state.reduxState);
  const { loginUserId } = reduxStore;
  const options = reduxStore.filterOptions.vendor;
  const vendorsColumn = [
    {
      name: "Vendor Name",
      isChecked: true,
      value: "vendorName",
    },
    {
      name: "Email",
      isChecked: true,
      value: "emailId",
    },
    {
      name: "Vendor Owner Name",
      isChecked: true,
      value: "vendorOwner",
    },
    {
      name: "Phone",
      isChecked: true,
      value: "phone",
    },
    {
      name: "Website",
      isChecked: true,
      value: "website",
    },
    {
      name: "GL Account",
      isChecked: true,
      value: "glAccount",
    },
    {
      name: "Category",
      isChecked: false,
      value: "category",
    },
    {
      name: "Country",
      isChecked: false,
      value: "country",
    },
    {
      name: "State",
      isChecked: false,
      value: "state",
    },
    {
      name: "City",
      isChecked: false,
      value: "city",
    },
    {
      name: "street",
      isChecked: false,
      value: "Street",
    },
    {
      name: "Zip Code",
      isChecked: false,
      value: "zipCode",
    },
    {
      name: "Hash",
      isChecked: true,
      value: "transHash",
    },
  ];

  const contactColumn = [
    {
      name: "Contact Name",
      isChecked: true,
      value: "contactName",
    },
    {
      name: "Assistant",
      isChecked: true,
      value: "assistant",
    },
    {
      name: "Asst Phone",
      isChecked: true,
      value: "assistantPhone",
    },
    {
      name: "Description",
      isChecked: false,
      value: "contDescription",
    },
    {
      name: "Account Name",
      isChecked: true,
      value: "accountName",
    },
    {
      name: "Email",
      isChecked: true,
      value: "emailId",
    },
    {
      name: "Phone",
      isChecked: false,
      value: "phone",
    },
    {
      name: "Contact Owner",
      isChecked: false,
      value: "contactOwner",
    },
    {
      name: "Department",
      isChecked: false,
      value: "department",
    },
    {
      name: "First Name",
      isChecked: false,
      value: "firstName",
    },
    {
      name: "Last Name",
      isChecked: false,
      value: "lastName",
    },
    {
      name: "Fax",
      isChecked: false,
      value: "fax",
    },
    {
      name: "Mobile",
      isChecked: false,
      value: "mobile",
    },
    {
      name: "Title",
      isChecked: false,
      value: "title",
    },
    {
      name: "Lead Source",
      isChecked: false,
      value: "leadSource",
    },
    {
      name: "Date Of Birth",
      isChecked: false,
      value: "dateOfBirth",
    },
    {
      name: "Reporting To",
      isChecked: false,
      value: "reportingTo",
    },
    {
      name: "Email Opt Out",
      isChecked: false,
      value: "emailOptOut",
    },
    {
      name: "Secondary Mail",
      isChecked: false,
      value: "secondaryEmailId",
    },
    {
      name: "Created By",
      isChecked: false,
      value: "createdBy",
    },
    {
      name: "Modified By",
      isChecked: false,
      value: "modifiedBy",
    },
    {
      name: "Created Time",
      isChecked: false,
      value: "createdDate",
    },
    {
      name: "Modified Time",
      isChecked: false,
      value: "modifiedDate",
    },
    {
      name: "Skype ID",
      isChecked: false,
      value: "skypeId",
    },
    {
      name: "Twitter",
      isChecked: false,
      value: "twitter",
    },
    {
      name: "Other Country",
      isChecked: false,
      value: "otherCountry",
    },
    {
      name: "Other State",
      isChecked: false,
      value: "otherState",
    },
    {
      name: "Other City",
      isChecked: false,
      value: "otherCity",
    },
    {
      name: "Other Street",
      isChecked: false,
      value: "otherStreet",
    },
    {
      name: "Other Zip Code",
      isChecked: false,
      value: "otherZip",
    },
    {
      name: "Mailing Country",
      isChecked: false,
      value: "mailingCountry",
    },
    {
      name: "Mailing State",
      isChecked: false,
      value: "mailingState",
    },
    {
      name: "Mailing City",
      isChecked: false,
      value: "mailingCity",
    },
    {
      name: "Mailing Street",
      isChecked: false,
      value: "mailingStreet",
    },
    {
      name: "Mailing Zip Code",
      isChecked: false,
      value: "mailingZip",
    },
  ];

  const filteredOptions = vendorsColumn.filter((option) =>
    option.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const [vendorDetails, setVendorDetails] = useState([]);
  const [vendorId, setVendorId] = useState("");
  const [vendorFormData, setVendorFormData] = useState({
    venderOwner: "",
    vendorName: "",
    phone: "",
    emailId: "",
    website: "",
    glAccount: glAccountOptions[0],
    category: "",
    street: "",
    city: "",
    state: "",
    zipCode: "",
    country: "",
    vendorDescription: "",
    vendorImage: "",
    emailOptOut: false,
  });

  useEffect(() => {
    if (storeModuleId) {
      vendorOverviewDetail(storeModuleId);
      setVendorId(storeModuleId);
    }
  }, []);

  const [vendorUpdateInfo, setVendorUpdateInfo] = useState({
    venderOwner: "",
    vendorName: "",
    phone: "",
    emailId: "",
    website: "",
    glAccount: glAccountOptions[0],
    category: "",
    street: "",
    city: "",
    state: "",
    zipCode: "",
    country: "",
    vendorDescription: "",
    emailOptOut: false,
  });
  const [selectedItem, setSelectedItem] = useState(null);
  const [listVisible, setListVisible] = useState(false);
  const [selectedOption, setSelectedOption] = useState(options[0]);
  // const [selectedMassOption, setSelectedMassOption] = useState(massOptions[0]); selectedMassOption is not used anywhere so i am commenting this

  const [vendorOverviewDetails, setVendorOverviewDetails] = useState({});
  const [vendorInfoDetails, setVendorInfoDetails] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState(0); // Total number of items
  const [perPage, setPerPage] = useState(10);
  const [showImageModal, setShowImageModal] = useState(false);
  const [imageUrl, setImageUrl] = useState(null);
  const [uploadImg, setUploadImg] = useState(false);

  const [showType, setShowType] = useState("");
  const [updateNotes, setUpdateNotes] = useState({
    description: "",
    title: "",
    noteImageList: [],
  });
  const [productsList, setProductsList] = useState([]);
  const [contactsList, setContactsList] = useState([]);
  const [openActivities, setOpenActivities] = useState([]);
  const [openActivitiesCalls, setOpenActivitiesCalls] = useState([]);
  const [openActivitiesMeetings, setOpenActivitiesMeetings] = useState([]);
  const [closedActivitiesCall, setClosedActivitiesCall] = useState([]);
  const [closedActivitiesMeetings, setClosedActivitiesMeetings] = useState([]);
  const [closeActivities, setCloseActivities] = useState([]);
  const [selectedPurchaseId, setSelectedPurchaseId] = useState([]);
  // const [purchaseOrderList, setPurchaseOrderList] = useState([]); setPurchaseOrderList is not used anywhere so i am commenting this

  const [editNote, setEditNote] = useState(false);
  const totalPages = Math.ceil(Number(totalItems) / perPage);
  const fileInputRef = useRef(null);
  const resetForm = () => {
    const formData = {
      venderOwner: "",
      vendorName: "",
      phone: "",
      emailId: "",
      website: "",
      glAccount: glAccountOptions[0],
      category: "",
      street: "",
      city: "",
      state: "",
      zipCode: "",
      country: "",
      vendorDescription: "",
      emailOptOut: false,
    };

    setVendorFormData(formData);
    setVendorUpdateInfo(formData);
  };

  const [editingFields, setEditingFields] = useState({
    venderOwner: false,
    emailId: false,
    category: false,
    phone: false,
    leadStatus: false,
    glAccount: false,
    mobile: false,
    skypeId: false,
    leadOwner: false,
    leadOwnerInfo: false,
    leadInfoEmail: false,
    leadSource: false,
    vendorInfoEmail: false,
    vendorInfoPhone: false,
    vendorInfoWebsite: false,
    vendorInfoCategory: false,
    vendorInfoGlAccount: false,
    vendorInfoZipCode: false,
    vendorInfoCity: false,
    vendorInfoCountry: false,
    vendorInfoStreet: false,
    vendorInfoState: false,
  });

  const [updatedFields, setUpdatedFields] = useState({
    vendorInfoEmail: vendorInfoDetails.vendorInfoEmail,
    vendorInfoPhone: vendorInfoDetails.vendorInfoPhone,
    vendorInfoWebsite: vendorInfoDetails.vendorInfoWebsite,
    vendorInfoCategory: vendorInfoDetails.vendorInfoCategory,
    vendorInfoGlAccount: vendorInfoDetails.vendorInfoGlAccount,
    vendorInfoZipCode: vendorInfoDetails.vendorInfoZipCode,
    vendorInfoCity: vendorInfoDetails.vendorInfoCity,
    vendorInfoCountry: vendorInfoDetails.vendorInfoCountry,
    vendorInfoStreet: vendorInfoDetails.vendorInfoStreet,
    vendorInfoState: vendorInfoDetails.vendorInfoState,
    venderOwner: vendorOverviewDetails.venderOwner,
    vendorName: vendorOverviewDetails.vendorName,
    phone: vendorOverviewDetails.phone,
    emailId: vendorOverviewDetails.emailId,
    website: vendorOverviewDetails.website,
    glAccount: vendorOverviewDetails.glAccount,
    category: vendorOverviewDetails.category,
    street: vendorOverviewDetails.street,
    city: vendorOverviewDetails.city,
    state: vendorOverviewDetails.state,
    zipCode: vendorOverviewDetails.zipCode,
    country: vendorOverviewDetails.country,
    vendorDescription: vendorOverviewDetails.vendorDescription,
    emailOptOut: vendorOverviewDetails.emailOptOut,
    emailOptOutInfo: vendorInfoDetails.emailOptOutInfo,
  });

  const [existingVendor, setExistingVendor] = useState(false);
  const [cityMsg, setCityMsg] = useState("");
  const [stateMsg, setStateMsg] = useState("");
  const [codeMsg, setCodeMsg] = useState("");
  const [countryMsg, setCountryMsg] = useState("");
  const hasPermission = (action) => {
    const permission = permissionList.vendors.find((p) => p.action === action);
    return permission ? permission.status === "TRUE" : false;
  };
  const [savingHeadingsContact, setSavingHeadingsContact] =
    useState(contactColumn);
  const [selectedHeadingsPurchases, setSelectedHeadingsPurchases] =
    useState(purchaseColumns);

  useEffect(() => {
    if (pastViewInfo?.undo) {
      setInnerPage("individualVendorPage");
      vendorOverviewDetail(fetchVendorInfo?.vendorId);
      setVendorId(fetchVendorInfo?.vendorId);
    }
  }, [pastViewInfo]);

  const handleCreateVendor = () => {
    if (hasPermission("CREATE")) {
      setEmailOptOut(false);
      setExistingClone(false);
      setInnerPage("create");
    } else {
      setShowAccessModal(true);
    }
  };

  const handleCreateVendorClone = () => {
    if (hasPermission("CREATE")) {
      setInnerPage("clone");
      setShowOptionsVendor(false);
      setExistingVendor(false);
      setExistingClone(true);
      setVendorUpdateInfo({ ...vendorOverviewDetails });
    } else {
      setShowAccessModal(true);
    }
  };

  const handleEditVendor = () => {
    if (hasPermission("EDIT")) {
      setExistingVendor(true);
      setInnerPage("create");
      setVendorUpdateInfo({ ...vendorOverviewDetails });
    } else {
      setShowAccessModal(true);
    }
  };

  const handleDeleteVendor = () => {
    if (hasPermission("DELETE")) {
      setDeleteModal(true);
    } else {
      setShowAccessModal(true);
    }
  };

  const createLeadStyles = {
    menu: (base) => ({
      ...base,
      width: "60%",
    }),
    option: (provided) => ({
      ...provided,
      // backgroundColor: "#EAEAEA",
      color: "#000",
      // backgroundColor: 'rgba(33, 119, 254, 0.1)',
      width: "100%",
    }),
    control: (baseStyles) => ({
      ...baseStyles,
      border: "1px solid #b9b9b9b0",
      backgroundColor: "#F3F6FB",
      height: "40px",
      borderRadius: "10px",
      width: "60%",
      boxShadow: "none",
    }),
    input: (base) => ({
      ...base,
      // color: "#FFFFFF",
    }),
    singleValue: (defaultStyles) => ({ ...defaultStyles, color: "#000" }),

    menuList: (provided) => ({
      ...provided,
      paddingTop: 0,
      paddingBottom: 0,
      "&::-webkit-scrollbar": {
        width: 5,
      },

      "&::-webkit-scrollbar-thumb": {
        background: "#316AFF",
        borderRadius: "10px",
      },
    }),
  };

  const [vendorIndivPage, setVendorIndivPage] = useState("overview");

  const [timeLineDataList, setTimeLineDataList] = useState();
  const [isLeadOwnerModal, setIsLeadOwnerModal] = useState(false);
  const [filteredVendorDetails, setFilteredVendorDetails] = useState([]);
  const [showHeadingsContact, setShowHeadingsContact] = useState(false);

  const [selectedHeadingsContact, setSelectedHeadingsContact] =
    useState(contactColumn);

  const fieldDropdowns = [
    {
      name: "Vendor Owner",
      isShowed: false,
      type: "select",
      value: "venderOwner",
    },
    {
      name: "Email Id",
      isShowed: true,
      type: "input",
    },
    {
      name: "GL Account",
      isShowed: true,
      type: "dropdown",
      // value: "vendorInfoGlAccount",
    },

    {
      name: "Email Opt Out",
      isShowed: true,
      type: "checkbox",
    },
    {
      name: "Created By",
      isShowed: true,
      type: "readOnly",
    },
    {
      name: "Modified By",
      isShowed: false,
      type: "readOnly",
    },
    {
      name: "Country",
      isShowed: true,
      type: "input",
    },
    {
      name: "Zip Code",
      isShowed: false,
      type: "input",
    },
    {
      name: "Street",
      isShowed: false,
      type: "input",
    },
    {
      name: "City",
      isShowed: false,
      type: "input",
    },
    {
      name: "State",
      isShowed: false,
      type: "input",
    },
    {
      name: "Phone",
      isShowed: false,
      type: "input",
    },
    {
      name: "Website",
      isShowed: false,
      type: "input",
    },

    {
      name: "Vendor Name",
      isShowed: false,
      type: "readOnly",
    },
  ];

  const [fieldLabels, setFieldLabels] = useState(fieldDropdowns);
  const [showSearchFields, setShowSearchFields] = useState(fieldDropdowns);
  const [clickedDropdownObj, setClickedDropdownObj] = useState({
    label: "",
    value: "",
  });
  const [selectedField, setSelectedField] = useState("");
  const [searchField, setSearchField] = useState("");
  const [showHeadings, setShowHeadings] = useState(false);
  const [showSelectedFilter, setShowSelectedFilter] = useState("");
  const [filterType, setFilterType] = useState("");
  // const [validEmail, setValidEmail] = useState(true); i am commenting this hence validEmail is not used anywhere
  const [emailMsg, setEmailMsg] = useState("");
  const [phoneMsg, setPhoneMsg] = useState("");
  const [categoryMsg, setCategoryMsg] = useState("");
  const [existingClone, setExistingClone] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [shareUserList, setShareUserList] = useState([]);
  const [shareRoleList, setShareRoleList] = useState([]);
  const [shareGroupList, setShareGroupList] = useState([]);
  const[sharePublicList,setSharePublicList]=useState([])
  const [shareInfoModal, setShareInfoModal] = useState(false);
  const [shareMemberIds, setShareMemberIds] = useState([]);
  const [deletedImg, setDeletedImg] = useState("");

  const [savingHeadingsPurchase, setSavingHeadingsPurchase] =
    useState(purchaseColumns);

  const fieldDropdownStyles = {
    menu: (base) => ({
      ...base,
      width: "100%",
    }),
    option: (provided) => ({
      ...provided,
      // backgroundColor: "#EAEAEA",
      color: "#000",
      // backgroundColor: 'rgba(33, 119, 254, 0.1)',
      width: "100%",
    }),
    control: (baseStyles) => ({
      ...baseStyles,
      border: 0,
      // backgroundColor: 'rgba(33, 119, 254, 0.1)',
      height: "20px",
      borderRadius: "10px",
      width: "100%",
      boxShadow: "none",
    }),
    input: (base) => ({
      ...base,
      color: "#FFFFFF",
    }),
    singleValue: (defaultStyles) => ({ ...defaultStyles, color: "#000" }),

    menuList: (provided) => ({
      ...provided,
      paddingTop: 0,
      paddingBottom: 0,
      "&::-webkit-scrollbar": {
        width: 5,
      },

      "&::-webkit-scrollbar-thumb": {
        background: "#316AFF",
        borderRadius: "10px",
      },
    }),
  };

  const [pinnedColumn, setPinnedColumn] = useState("Vendor Name");
  const [selectedHeadings, setSelectedHeadings] = useState(vendorsColumn);
  const [savingHeadings, setSavingHeadings] = useState(vendorsColumn);
  const [modalType, setShowModalType] = useState("");
  const [showHeadingsPurchases, setShowHeadingsPurchases] = useState(false);

  const [searchColumn, setSearchColumn] = useState("");
  const deleteNoteDetails = (vendorId, noteId) => {
    const formData = new FormData();
    formData.append("moduleId", vendorId);
    formData.append("loggedInUserId", loginUserId);
    formData.append("moduleName", "VENDORS");
    formData.append("noteId", noteId);

    const headersResult = getHeaders(true);

    // Check if headers result indicates an error
    if (headersResult.error) {
      navigate("../"); // Redirect to login if token is missing
      return;
    }

    axios
      .post(URI.deleteNote, formData, {
        headers: headersResult,
      })
      .then((response) => {
        if (response.data.status === 200) {
          NotificationManager.success("", response.data.message, 5000);
          setNoteDeleteConfirmation(false);
          getNotesDetails(vendorId, selectRecent);
        } else {
          NotificationManager.error("", response.data.message, 5000);
        }
      })
      .catch((error) => {
        handleApiError(error, navigate);
      });
  };

  const removeImage = async () => {
    setImageLoader(true);
    const requestBody = {
      loggedInUserId: loginUserId,
      vendorId: vendorId,
    };

    const headersResult = getHeaders(true);

    // Check if headers result indicates an error
    if (headersResult.error) {
      navigate("../"); // Redirect to login if token is missing
      return;
    }

    await axios
      .post(URI.removeVendorImage, requestBody, {
        headers: headersResult,
      })

      .then((response) => {
        if (response.data.status === 200) {
          NotificationManager.success("", response.data.message, 5000);
          setShowRemoveModal(false);
          setTimeout(() => {
            // setDeleteMsg("");
            setShowImageModal(false);
            vendorOverviewDetail(vendorId);
          }, 3000);
        } else {
          NotificationManager.error("", response.data.message, 5000);
          setImageLoader(false);
        }
      })
      .catch(async (error) => {
        handleApiError(error, navigate);
      })
      .finally(() => setImageLoader(false));
  };

  const uploadImage = async () => {
    setImageLoader(true);
    const formData = new FormData();
    const requestBody = {
      vendorOwnerId: vendorOverviewDetails.vendorOwnerId,
      vendorId: vendorId,
      glAccount: vendorOverviewDetails.glAccount.value,
      loggedInUserId: loginUserId,
    };

    formData.append("vendorDtlsInputs", JSON.stringify(requestBody));
    formData.append(
      "vendorImage",
      uploadImg ? selectedFiles : vendorInfoDetails.vendorImageData
    );

    const headersResult = getHeaders(true);

    // Check if headers result indicates an error
    if (headersResult.error) {
      navigate("../"); // Redirect to login if token is missing
      return;
    }

    await axios
      .post(URI.individualUpdateVendor, formData, {
        headers: headersResult,
      })
      .then((response) => {
        if (response.data.status === 200) {
          vendorOverviewDetail(vendorId);
          setShowImageModal(false);
          setIsImageUploaded(false);
        } else {
          NotificationManager.error(response.data.message);
          setImageLoader(false);
        }
      })
      .catch(async (error) => {
        handleApiError(error, navigate);
      })
      .finally(() => setImageLoader(false));
  };

  const handleChangeNoteTitle = (e) => {
    const { name, value } = e.target;
    if (value.length === 1 && value[0] === ' ') {
      return; 
    }
    if (editNote) {
      setUpdateNotes((prevLeadInfo) => ({
        ...prevLeadInfo,
        [name]: value,
      }));
    } else {
      setAddTitle(value);
    }
  };

  const handleChildDataChange = (dataFromChild) => {
    const deletedImg = dataFromChild.toString("");
    setDeletedImg(deletedImg);
  };

  const handleChangeNoteDescription = (e) => {
    const { name, value } = e.target;
    if (value.length === 1 && value[0] === ' ') {
      return; 
    }
    if (editNote) {
      setUpdateNotes((prevLeadInfo) => ({
        ...prevLeadInfo,
        [name]: value,
      }));
    } else {
      setNote(value); // Directly set the input value
    }
  };
  const handleColumnCheckboxChange = (name, isChecked) => {
    setSavingHeadings((prevHeadings) =>
      prevHeadings.map((heading) =>
        heading.name === name ? { ...heading, isChecked } : heading
      )
    );
  };

  const handlePinColumn = (name) => {
    setPinnedColumn(name);
    const updatedColumns = [...selectedHeadings];
    const clickedColumnIndex = updatedColumns.findIndex(
      (column) => column.name === name
    );

    if (clickedColumnIndex !== -1) {
      const clickedColumn = updatedColumns.splice(clickedColumnIndex, 1)[0];
      updatedColumns.unshift(clickedColumn);
      setSelectedHeadings(updatedColumns);
    }
  };

  const handleFieldClick = (field) => {
    if (field.type === "dropdown") {
      const fieldValue = field.name
        .replace(/\s+/g, "")
        .replace(/^(.)/, (match) => match.toLowerCase());

      setClickedDropdownObj(vendorOverviewDetails[fieldValue]);
    } else if (field.type === "checkbox") {
      setUpdatedFields((prevFields) => ({
        ...prevFields,
        emailOptOut: vendorOverviewDetails["emailOptOut"],
      }));
    }
    const updatedColumns = [...fieldLabels];

    const existedObj = updatedColumns.find(
      (field) => field.name === selectedField
    );
    const selectedObj = updatedColumns.find((item) => item.name === field.name);

    const existedObjIndex = updatedColumns.findIndex(
      (field) => field.name === existedObj.name
    );
    const selectedObjIndex = updatedColumns.findIndex(
      (field) => field.name === selectedObj.name
    );

    updatedColumns[existedObjIndex].isShowed = false;
    updatedColumns[selectedObjIndex].isShowed = true;

    const removedObj = updatedColumns.splice(existedObjIndex, 1)[0];
    updatedColumns.splice(existedObjIndex, 0, selectedObj);

    updatedColumns.push(removedObj);
    const uniqueData = updatedColumns.filter(
      (item, index, self) =>
        index === self.findIndex((t) => t.name === item.name)
    );
    setFieldLabels(uniqueData);
    setShowSearchFields(uniqueData);
  };

  const handleChangeIndDropDown = (selectedOption, field) => {
    setUpdatedFields((prevFields) => ({
      ...prevFields,
      [field]: selectedOption,
    }));
  };

  const [isImageUploaded, setIsImageUploaded] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState(null);
  const [isDragging, setIsDragging] = useState(false);
  const [isShrinked, setIsShrinked] = useState(false);

  const allowedFileTypes = [
    "image/png",
    "image/jpg",
    "image/jpeg",
    "image/gif",
  ];
  const maxSizeInBytes = 2 * 1024 * 1024;

  const handleDragOver = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setIsDragging(true);
  };

  const handleDragLeave = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setIsDragging(false);
  };

  const handleColumnCheckboxChangeContact = (name, isChecked) => {
    setSavingHeadingsContact((prevHeadings) =>
      prevHeadings.map((heading) =>
        heading.name === name ? { ...heading, isChecked } : heading
      )
    );
  };

  const handleColumnCheckboxChangePurchase = (name, isChecked) => {
    setSavingHeadingsPurchase((prevHeadings) =>
      prevHeadings.map((heading) =>
        heading.name === name ? { ...heading, isChecked } : heading
      )
    );
  };

  const handleDrop = (event) => {
    const maxSizeInBytes = 2 * 1024 * 1024;

    event.preventDefault();
    event.stopPropagation();
    setIsDragging(false);
    const file = event.dataTransfer.files[0];
    if (file) {
      if (!allowedFileTypes.includes(file.type)) {
        setImageMsg(
          "Invalid file type. Please upload an image file (JPG, PNG, JPEG)."
        );
        setTimeout(() => setImageMsg(""), 3000);
        return;
      }
      if (file.size > maxSizeInBytes) {
        setImageMsg("File size exceeds 2 MB. Please select a smaller file.");
        setTimeout(() => setImageMsg(""), 3000);
        return;
      }
      setSelectedFiles(file);
      fileInputRef.current.files = event.dataTransfer.files;
      if (existingVendor || existingClone) {
        setVendorUpdateInfo((prevLeadInfo) => ({
          ...prevLeadInfo,
          vendorImage: file,
        }));
      } else {
        setVendorFormData((prevLeadInfo) => ({
          ...prevLeadInfo,
          vendorImage: file,
        }));
      }
    }
  };

  const handVendorImageChange = (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      if (!allowedFileTypes.includes(selectedFile.type)) {
        setImageMsg(
          "Invalid file type. Please upload an image file (JPG, PNG, JPEG)."
        );
        setTimeout(() => setImageMsg(""), 3000);
        return;
      }
      if (selectedFile.size > maxSizeInBytes) {
        setImageMsg("File size exceeds 2 MB. Please select a smaller file.");
        setTimeout(() => setImageMsg(""), 3000);
        return;
      }
      setSelectedFiles(selectedFile);
      if (existingVendor || existingClone) {
        setVendorUpdateInfo((prevLeadInfo) => ({
          ...prevLeadInfo,
          vendorImage: selectedFile,
        }));
      } else {
        setVendorFormData((prevLeadInfo) => ({
          ...prevLeadInfo,
          vendorImage: selectedFile,
        }));
      }
    }
  };

  const handleChangeVendor = (e) => {
    const { name, value } = e.target;

    if (value.length > 0) {
      setIsFirstCharacterEntered(true);
    } else {
      setIsFirstCharacterEntered(false);
    }
    if (existingVendor || existingClone) {
      setVendorUpdateInfo((prevLeadInfo) => ({
        ...prevLeadInfo,
        [name]: value,
      }));
    } else {
      setVendorFormData((prevLeadInfo) => ({
        ...prevLeadInfo,
        [name]: value,
      }));
    }

    // if (name === "emailId") {
    //   const isValid = emailValidation(value);
    //   // setValidEmail(isValid);
    //   // setEmailErrorMsg(isValid ? "" : "Please enter a valid email address.");
    //   // setTimeout(() => {
    //   //   setEmailErrorMsg("");
    //   // }, 3000);
    // }
  };

  const sortedColumnsData = (type, headingName) => {
    const sortedData = [...vendorDetails];

    if (type === "asc") {
      sortedData.sort((a, b) => {
        if (
          typeof a[headingName] === "string" &&
          typeof b[headingName] === "string"
        ) {
          return a[headingName].localeCompare(b[headingName]);
        }
        return a[headingName] - b[headingName];
      });
    } else if (type === "desc") {
      sortedData.sort((a, b) => {
        if (
          typeof a[headingName] === "string" &&
          typeof b[headingName] === "string"
        ) {
          return b[headingName].localeCompare(a[headingName]);
        }
        return b[headingName] - a[headingName];
      });
    }
    setFilteredVendorDetails(sortedData);
  };

  const getAllVendorDetails = async (selectedOption) => {
    setIsLoading(true);

    const requestBody = {
      loggedInUserId: loginUserId,
      customFilter: selectedOption.value,
      pageNo: currentPage,
      pageSize: perPage,
    };

    const headersResult = getHeaders(true);

    // Check if headers result indicates an error
    if (headersResult.error) {
      navigate("../"); // Redirect to login if token is missing
      return;
    }

    await axios
      .post(URI.getAllVendors, requestBody, {
        headers: headersResult,
      })

      .then((response) => {
        if (response.data.status === 200) {
          setVendorDetails(response.data.data.allVendorsDtls);
          setFilteredVendorDetails(response.data.data.allVendorsDtls);
          setVendorDetails(response.data.data.allVendorsDtls);
          setTotalItems(response.data.data.rowCount);
          setIsLoading(false);
        } else {
          setIsLoading(false);
          NotificationManager.error("", response.data.message, 5000);
        }
      })
      .catch(async (error) => {
        handleApiError(error, navigate);
      });
  };

  const getAllTimeleadVendor = async (vendorId) => {
    const headersResult = getHeaders(true);

    // Check if headers result indicates an error
    if (headersResult.error) {
      navigate("../"); // Redirect to login if token is missing
      return;
    }

    await axios
      .get(URI.getVendorTimeline + "/" + loginUserId + "/" + vendorId, {
        headers: headersResult,
      })

      .then((response) => {
        if (response.data.status === 200) {
          setTimeLineDataList(response.data.data.getVendorsTimeline);
        }
      })
      .catch(async (error) => {
        handleApiError(error, navigate);
      });
  };

  const getAllInfoShare = async (vendorId) => {
    setIsLoading(true);
    const requestBody = {
      moduleId: vendorId,
      loggedInUserId: loginUserId,
    };

    const headersResult = getHeaders(true);

    // Check if headers result indicates an error
    if (headersResult.error) {
      navigate("../"); // Redirect to login if token is missing
      return;
    }

    await axios
      .post(URI.getShareInfo, requestBody, {
        headers: headersResult,
      })
      .then((response) => {
        if (response.data.status === 200) {
          const hasSharedItems =
            (response.data.data.getSharingInfoUsers &&
              response.data.data.getSharingInfoUsers.length > 0) ||
            (response.data.data.getSharingInfoRoles &&
              response.data.data.getSharingInfoRoles.length > 0) ||
            (response.data.data.getSharingInfoGroups &&
              response.data.data.getSharingInfoGroups.length > 0)||
              (response.data.data.getSharingInfoPublic &&
                response.data.data.getSharingInfoPublic.length > 0);

          const userShareMemberIds = response.data.data.getSharingInfoUsers.map(
            (user) => user.shareModuleId
          );
          const roleShareMemberIds = response.data.data.getSharingInfoRoles.map(
            (role) => role.shareModuleId
          );
          const groupShareMemberIds =
            response.data.data.getSharingInfoGroups.map(
              (group) => group.shareModuleId
            );
            const groupPublicSharedMemberIds =
            response.data.data.getSharingInfoPublic.map(
              (user) => user.shareModuleId
            )
          setShareMemberIds([
            ...userShareMemberIds,
            ...roleShareMemberIds,
            ...groupShareMemberIds,
            ...groupPublicSharedMemberIds
          ]);
          setShareUserList(response.data.data.getSharingInfoUsers);
          setShareRoleList(response.data.data.getSharingInfoRoles);
          setShareGroupList(response.data.data.getSharingInfoGroups);
          setSharePublicList(response.data.data.getSharingInfoPublic);
          if (hasSharedItems) {
            setShareInfoModal(true);
          } else {
            setShareModal(true);
          }
          setIsLoading(false);
        }
      })
      .catch((err) => {
        handleApiError(err, navigate);
      });
  };

  const vendorOverviewDetail = async (vendorId) => {
    let vendorOverviewDetails = {};
    let vendorIndivInfoDetails = {};

    const headersResult = getHeaders(true);

    // Check if headers result indicates an error
    if (headersResult.error) {
      navigate("../"); // Redirect to login if token is missing
      return;
    }

    await axios
      .get(URI.vendorOverview + "/" + loginUserId + "/" + vendorId, {
        headers: headersResult,
      })

      .then((response) => {
        if (response.data.status === 200) {
          vendorOverviewDetails = response.data.data.vendorInfo;
          vendorIndivInfoDetails = response.data.data.vendorInfo;

          vendorIndivInfoDetails.vendorInfoEmail =
            response.data.data.vendorInfo.emailId;
          vendorIndivInfoDetails.vendorInfoZipCode =
            response.data.data.vendorInfo.zipCode;
          vendorIndivInfoDetails.emailOptOutInfo =
            response.data.data.vendorInfo.emailOptOut;
          vendorIndivInfoDetails.vendorInfoPhone =
            response.data.data.vendorInfo.phone;
          vendorIndivInfoDetails.vendorInfoWebsite =
            response.data.data.vendorInfo.website;
          vendorIndivInfoDetails.vendorInfoCategory =
            response.data.data.vendorInfo.category;
          vendorIndivInfoDetails.vendorInfoGlAccount = {
            label: response.data.data.vendorInfo.glAccount,
            value: response.data.data.vendorInfo.glAccount,
          };

          vendorIndivInfoDetails.vendorInfoCity =
            response.data.data.vendorInfo.city;
          vendorIndivInfoDetails.vendorInfoCountry =
            response.data.data.vendorInfo.country;
          vendorIndivInfoDetails.vendorInfoStreet =
            response.data.data.vendorInfo.street;
          vendorIndivInfoDetails.vendorInfoState =
            response.data.data.vendorInfo.state;

          //   vendorOverviewDetail
          vendorIndivInfoDetails.glAccount = {
            label: response.data.data.vendorInfo.glAccount,
            value: response.data.data.vendorInfo.glAccount,
          };
          vendorIndivInfoDetails.vendorImage =
            response.data.data.vendorImageData;
          setVendorOverviewDetails(vendorOverviewDetails);
          setVendorInfoDetails(vendorIndivInfoDetails);

          setProductsList(response.data.data.assignedProduct);
          setOpenActivities(response.data.data.openActiveTask);
          setCloseActivities(response.data.data.closeActiveTask);
          setContactsList(response.data.data.assignedContact);
          const updatedFields = {
            emailId: response.data.data.vendorInfo.emailId,
            phone: response.data.data.vendorInfo.phone,
            website: response.data.data.vendorInfo.website,
            zipCode: response.data.data.vendorInfo.zipCode,
            city: response.data.data.vendorInfo.city,
            country: response.data.data.vendorInfo.country,
            street: response.data.data.vendorInfo.street,
            state: response.data.data.vendorInfo.state,
            category: response.data.data.vendorInfo.category,
            vendorName: response.data.data.vendorInfo.vendorName,
            vendorImage: response.data.data.vendorInfo.vendorImageData,
            vendorInfoEmail: response.data.data.vendorInfo.emailId,
            vendorInfoPhone: response.data.data.vendorInfo.phone,
            vendorInfoWebsite: response.data.data.vendorInfo.website,
            vendorInfoCategory: response.data.data.vendorInfo.category,
            vendorInfoZipCode: response.data.data.vendorInfo.zipCode,
            emailOptOutInfo: response.data.data.vendorInfo.emailOptOut,
            vendorInfoCity: response.data.data.vendorInfo.city,
            vendorInfoCountry: response.data.data.vendorInfo.country,
            vendorInfoStreet: response.data.data.vendorInfo.street,
            vendorInfoState: response.data.data.vendorInfo.state,
            vendorInfoGlAccount: {
              label: response.data.data.vendorInfo.glAccount,
              value: response.data.data.vendorInfo.glAccount,
            },
            glAccount: {
              label: response.data.data.vendorInfo.glAccount,
              value: response.data.data.vendorInfo.glAccount,
            },
            emailOptOut: response.data.data.vendorInfo.emailOptOut,
          };
          setSelectedItem({
            name: response.data.data.vendorInfo.venderOwner,
            userId: response.data.data.vendorInfo.vendorOwnerId,
          });
          setUpdatedFields(updatedFields);
          setEmailOptOut(response.data.data.vendorInfo.emailOptOut);
          // setOpenActivities(response.data.data.assignOpenActivities);
          setOpenActivitiesCalls(response.data.data.openActivitiesCalls);
          setOpenActivitiesMeetings(response.data.data.openActivitiesMeetings);
          setClosedActivitiesCall(response.data.data.closedActivitiesCalls);
          setClosedActivitiesMeetings(
            response.data.data.closedActivitiesMeetings
          );
          setCloseActivities(response.data.data.assignCloseActivities);
          setAssignedPurchase(response.data.data.assignedPurchase);
          setInnerPage("individualVendorPage");
        } else {
          NotificationManager.error("", response.data.message, 5000);
        }
      })
      .catch(async (error) => {
        handleApiError(error, navigate);
      });
  };

  const handleChange = (selectedOption) => {
    setSelectedOption(selectedOption);
    getAllVendorDetails(selectedOption);
  };

  const handleMassChange = (selectedMassOption) => {
    setShowModalType(selectedMassOption.value);
    // setSelectedMassOption(selectedMassOption);
  };

  const handleClose = () => {
    setShowModalType("");
    setMassVal("");
    setSearchTerm("");
  };

  const handleUpdateClick = async (field) => {
    let requestBody = {};
    const isObj =
      typeof vendorOverviewDetails[field] === "object" &&
      vendorOverviewDetails[field] !== null &&
      !Array.isArray(vendorOverviewDetails[field]);

    if (field === "emailOptOutInfo") {
      updatedFields["emailOptOut"] = updatedFields[field];
    }

    const value =
      field === "emailOptOutInfo"
        ? updatedFields[field]
          ? true
          : false
        : updatedFields[field];
    const fieldMappings = {
      vendorInfoEmail: "emailId",
      vendorInfoCategory: "category",
      vendorInfoPhone: "phone",
      vendorInfoWebsite: "website",
      vendorInfoGlAccount: "glAccount",
      vendorInfoZipCode: "zipCode",
      emailOptOutInfo: "emailOptOut",
      vendorInfoCity: "city",
      vendorInfoCountry: "country",
      vendorInfoStreet: "street",
      vendorInfoState: "state",
    };
    // if (fieldMappings.hasOwnProperty(field)) {
    //   field = fieldMappings[field];
    // } not supported by eslint
    if (Object.hasOwn(fieldMappings, field)) {
      field = fieldMappings[field];
    }

    const formData = new FormData();
    if (field === "emailNotification") {
      requestBody = {
        // vendorOwnerId: selectedItem.userId,
        vendorOwnerId: isOwnerSelect
          ? selectedOwnerItem?.userId
          : selectedItem?.userId,
        vendorId: vendorId,
        // [field]: field === "glAccount" ? value.value : value,
        loggedInUserId: loginUserId,
        emailNotification: emailNotification,
      };
    } else if (field === "emailOptOut") {
      requestBody = {
        vendorId: vendorId,
        [field]: updatedFields[field] === true ? "true" : "false",
        loggedInUserId: loginUserId,
      };
    } else {
      requestBody = {
        // vendorOwnerId: selectedItem.userId,

        vendorId: vendorId,
        [field]: field === "glAccount" ? value.value : value,
        loggedInUserId: loginUserId,
      };
    }

    const compareValue = isObj
      ? vendorOverviewDetails[field].value
      : vendorOverviewDetails[field];

    const enterValue = field === "glAccount" ? value.value : value;

    if (enterValue !== compareValue || field === "emailNotification") {
      setApiLoader(true);
      formData.append("vendorDtlsInputs", JSON.stringify(requestBody));
      formData.append("vendorImage", vendorFormData.vendorImage);

      const headersResult = getHeaders(true);

      // Check if headers result indicates an error
      if (headersResult.error) {
        navigate("../"); // Redirect to login if token is missing
        return;
      }

      await axios
        .post(URI.individualUpdateVendor, formData, {
          headers: headersResult,
        })

        .then((response) => {
          if (response.data.status === 200) {
            NotificationManager.success("", response.data.message, 5000);
            setVendorOverviewDetails((prevDetails) => ({
              ...prevDetails,
              [field]: updatedFields[field],
            }));
            vendorOverviewDetail(vendorId);
            setIsOwnerSelect(false);
            setShowModalType("");
          } else {
            NotificationManager.error("", response.data.message, 5000);
          }

          const resetFields = Object.fromEntries(
            Object.keys(editingFields).map((key) => [key, false])
          );
          setEditingFields({
            ...resetFields,
          });
          setShowType("");
        })
        .catch(function (error) {
          handleApiError(error, navigate);
        })
        .finally(() => setApiLoader(false));
    }
  };

  const handleChangeSelectDropDown = (selectedOption, field) => {
    if (existingVendor || existingClone) {
      setVendorUpdateInfo((prevLeadInfo) => ({
        ...prevLeadInfo,
        [field]: selectedOption,
      }));
    } else {
      setVendorFormData((prevLeadInfo) => ({
        ...prevLeadInfo,
        [field]: selectedOption,
      }));
    }
  };

  const handleIndividualInputChange = (field, value) => {
    setUpdatedFields((prevFields) => ({
      ...prevFields,
      [field]: value,
    }));
  };

  const handleCancelClick = (field) => {
    setEditingFields((prevFields) => ({
      ...prevFields,
      [field]: false,
    }));
  };

  const handleKeyDown = (e) => {
    // Prevent typing space in the input field
    if (!isFirstCharacterEntered && e.key === " ") {
      e.preventDefault();
    }
  };

  const handleKeyDownForZip = (e) => {
    const allowedKeys = [
      "Backspace",
      "ArrowLeft",
      "ArrowRight",
      "Delete",
      "Tab",
    ];
    if (!/^[0-9]$/.test(e.key) && !allowedKeys.includes(e.key)) {
      e.preventDefault();
    }
  };

  const handleEditClick = (field) => {
    setEditingFields((prevFields) => ({
      ...prevFields,
      [field]: true,
    }));
  };

  const handleUpdateVendor = async (e) => {
    const vendorData =
      existingVendor || existingClone ? vendorUpdateInfo : vendorFormData;

    const isValidForm = validateVendorData(
      vendorData,
      setMandatoryMsg,
      setEmailMsg,
      setPhoneMsg,
      setWebsiteMsg,
      setCityMsg,
      setStateMsg,
      setCodeMsg,
      setCountryMsg,
      validateFirstName,
      emailValidation,
      validateCityIndia,
      validateStateIndia,
      validateZipCode,
      validateCountryName,
      validateMobileNumber,
      websiteValidation,
      validateCategory,
      setCategoryMsg
    );
    if (!isValidForm) return;

    if (!emailValidation(vendorUpdateInfo.emailId)) {
      setEmailMsg(false);
      setEmailMsg("Please enter a valid email address.");
      setTimeout(() => {
        setEmailMsg("");
      }, 5000);
    } else {
      const requestBody = {
        vendorId: vendorId,
        vendorOwnerId: selectedItem.userId,
        loggedInUserId: loginUserId,
        vendorName: removeExtraSpaces(vendorUpdateInfo.vendorName),
        phone: vendorUpdateInfo.phone,
        emailId: removeExtraSpaces(vendorUpdateInfo.emailId),
        website: removeExtraSpaces(vendorUpdateInfo.website),
        glAccount: vendorUpdateInfo.glAccount.value,
        category: removeExtraSpaces(vendorUpdateInfo.category),
        street: removeExtraSpaces(vendorUpdateInfo.street),
        city: removeExtraSpaces(vendorUpdateInfo.city),
        state: removeExtraSpaces(vendorUpdateInfo.state),
        zipCode: vendorUpdateInfo.zipCode,
        country: removeExtraSpaces(vendorUpdateInfo.country),
        vendorDescription: removeExtraSpaces(
          vendorUpdateInfo.vendorDescription
        ),
        emailOptOut: emailOptOut === true ? "true" : "false",
        emailNotification: emailNotification,
      };

      const data = await updateVendorDetails(
        requestBody,
        vendorFormData,
        setBtnLoader
      );
      if (data.status === 200) {
        NotificationManager.success("", data.message, 5000);
        setExistingVendor(false);
        getAllVendorDetails(selectedOption);

        if (e === "SAVE") {
          setInnerPage("list");
          resetForm();
        }
        resetForm();
      } else {
        NotificationManager.error("", data.message, 5000);
      }
    }
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handlePageDropDowmChange = (selectedOption) => {
    setCurrentPage(1);
    setPerPage(parseInt(selectedOption.value));
  };

  // mass sections api

  const deleteMassDetails = async () => {
    const requestBody = {
      loggedInUserId: loginUserId,
      moduleName: "VENDORS",
      massAction: "DELETE",
      moduleIdList: selectedCheckboxes,
    };

    const headersResult = getHeaders(true);

    // Check if headers result indicates an error
    if (headersResult.error) {
      navigate("../"); // Redirect to login if token is missing
      return;
    }

    await axios
      .post(URI.massDelete, requestBody, {
        headers: headersResult,
      })

      .then((response) => {
        if (response.data.status === 200) {
          NotificationManager.success("", response.data.message, 5000);
          getAllVendorDetails(selectedOption);
          setSelectedCheckboxes([]);
          setShowOptionsVendor(false);
          setInnerPage("list");
          handleClose();
        } else {
          NotificationManager.error("", response.data.message, 5000);
        }
      })
      .catch(function (error) {
        handleApiError(error, navigate);
      });
  };

  const handleUpdateMassDetails = async () => {
    const requestBody = {
      loggedInUserId: loginUserId,
      moduleName: "VENDORS",
      [dropdownAllDetails.value]: massVal,
      massAction: "EDIT",

      moduleIdList: selectedCheckboxes,
    };

    const headersResult = getHeaders(true);

    // Check if headers result indicates an error
    if (headersResult.error) {
      navigate("../"); // Redirect to login if token is missing
      return;
    }

    await axios
      .post(URI.massUpdate, requestBody, {
        headers: headersResult,
      })
      .then((response) => {
        if (response.data.status === 200) {
          NotificationManager.success("", response.data.message, 5000);
          getAllVendorDetails(selectedOption);
          setSelectedCheckboxes([]);
          handleClose();
          setSearchTerm("");
        } else {
          NotificationManager.error("", response.data.message, 5000);
          setSearchTerm("");
        }
      })
      .catch(async (error) => {
        handleApiError(error, navigate);
      });
  };

  const validatePhoneNumber = (value) => {
    const validNumber = value.replace(/\D/g, "").slice(0, 10);
    return validNumber;
  };

  const handleMassTransfer = async () => {
    const requestBody = {
      loggedInUserId: loginUserId,
      moduleName: "VENDORS",
      ownerId: selectedItem.userId,
      massAction: "EDIT",
      moduleIdList: selectedCheckboxes,
    };
    const headersResult = getHeaders(true);

    // Check if headers result indicates an error
    if (headersResult.error) {
      navigate("../"); // Redirect to login if token is missing
      return;
    }

    await axios
      .post(URI.massTransfer, requestBody, {
        headers: headersResult,
      })

      .then((response) => {
        if (response.data.status === 200) {
          NotificationManager.success("", response.data.message, 5000);
          // NotificationManager.success("", response.data.message, 5000);
          getAllVendorDetails(selectedOption);
          setSelectedCheckboxes([]);
          setShowOptionsVendor(false);
          setInnerPage("list");
          handleClose();
        } else {
          NotificationManager.error("", response.data.message, 5000);
        }
      })
      .catch(function (error) {
        handleApiError(error, navigate);
      });
  };

  const [noteInfo, setNoteInfo] = useState([]);
  const [addTitle, setAddTitle] = useState("");
  const [note, setNote] = useState("");
  const [selectedNoteFiles, setSelectedNoteFiles] = useState([]);
  const [showButtons, setShowButtons] = useState(false);
  const [showTitleNote, setShowTitleNote] = useState(false);
  const [noteTitle, setNoteTitle] = useState("");
  const [selectRecent, setSelectRecent] = useState("ALL");
  const [selectedVendorsId, setSelectedVendorsId] = useState(0);
  const [selectedCheckboxes, setSelectedCheckboxes] = useState([]);
  const [assignedPurchase, setAssignedPurchase] = useState([{}]);

  const handleTextAreaClick = () => {
    setShowButtons(true);
  };

  const handleCheckboxChange = (event) => {
    const { value } = event.target;

    const selectedCheckbox = { moduleId: value };

    setSelectedCheckboxes((prevSelectedCheckboxes) => {
      const isAlreadySelected = prevSelectedCheckboxes.some(
        (checkbox) => checkbox.moduleId === value
      );

      if (isAlreadySelected) {
        return prevSelectedCheckboxes.filter(
          (checkbox) => checkbox.moduleId !== value
        );
      } else {
        return [...prevSelectedCheckboxes, selectedCheckbox];
      }
    });
  };

  const handleUploadImage = (e) => {
    const file = e.target.files[0];
    setIsImageUploaded(true);
    if (file) {
      if (!allowedFileTypes.includes(file.type)) {
        setImageMsg(
          "Invalid file type. Please upload an image file (JPG, PNG, JPEG)."
        );
        setTimeout(() => setImageMsg(""), 5000);
        return;
      }
      if (file.size > maxSizeInBytes) {
        setImageMsg("File size exceeds 2 MB. Please select a smaller file.");
        setTimeout(() => setImageMsg(""), 5000);
        return;
      }
      const imageUrl = URL.createObjectURL(file);
      setImageUrl(imageUrl);
      setSelectedFiles(e.target.files[0]);
    }
    setUploadImg(true);
  };

  const handleTextFileChange = (event) => {
    const files = Array.from(event.target.files);
    if (files) {
      setSelectedNoteFiles(files);
    } else {
      setSelectedNoteFiles([]);
    }
  };
  const handleAddTitleClick = () => {
    setShowTitleNote(true);
  };

  const getNotesDetails = async (vendorId, orderType) => {
    const requestBody = {
      loggedInUserId: loginUserId,
      moduleId: vendorId,
      orderType: orderType,
    };

    const headersResult = getHeaders(true);

    // Check if headers result indicates an error
    if (headersResult.error) {
      navigate("../"); // Redirect to login if token is missing
      return;
    }

    await axios
      .post(URI.getNote, requestBody, {
        headers: headersResult,
      })
      .then((response) => {
        if (response.data.status === 200) {
          setNoteInfo(response.data.data.noteInfo);
        } else {
          NotificationManager.error("", response.data.message, 5000);
        }
      })
      .catch(async (error) => {
        handleApiError(error, navigate);
      });
  };

  const handleSelectRecent = (item) => {
    setSelectRecent(item);
    var value = "";
    if (item === "Recent Last") {
      value = "RECENT_LAST";
    } else if (item === "Recent First") {
      value = "RECENT_FIRST";
    } else {
      value = item;
    }
    getNotesDetails(vendorId, value);
  };

  const insertNote = () => {
    const formData = new FormData();
    formData.append("moduleId", vendorId);
    formData.append("loggedInUserId", loginUserId);
    formData.append("moduleName", "VENDORS");
    formData.append("noteDescription", note);
    formData.append("noteTitle", addTitle);

    selectedNoteFiles.forEach((file) => {
      formData.append("noteImages", file);
    });

    const headersResult = getHeaders(true);

    // Check if headers result indicates an error
    if (headersResult.error) {
      navigate("../"); // Redirect to login if token is missing
      return;
    }

    axios
      .post(URI.insertNote, formData, {
        headers: headersResult,
      })
      .then(() => {
        // const newNoteId = response.data.data.noteId; // Extracting noteId from response
        setShowButtons(false);
        setAddTitle("");
        setNote("");
        setSelectedNoteFiles([]);
        getNotesDetails(vendorId, selectRecent);
      })
      .catch((error) => {
        handleApiError(error, navigate);
      });
  };

  const scrollToSection = (sectionId) => {
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: "smooth" });
    }
  };
  const [selectedProductId, setSelectedProductId] = useState([]);

  const handleAssignModule = async (selectedProductId) => {
    const moduleIdList = selectedProductId.map((id) => ({ moduleId: id }));

    if (moduleIdList.length < 1) {
      return NotificationManager.error("", "Please select an item", 5000);
    }
    const requestBody = {
      loginUserId: loginUserId,
      moduleName: "VENDORS",
      moduleId: vendorId,
      assignModuleName: "PRODUCTS",
      moduleIdList,
    };

    setApiLoader(true);
    try {
      const headersResult = getHeaders(true);

      // Check if headers result indicates an error
      if (headersResult.error) {
        navigate("../"); // Redirect to login if token is missing
        return;
      }

      const response = await axios.post(URI.assignRole, requestBody, {
        headers: headersResult,
      });
      if (response.data.status === 200) {
        NotificationManager.success("", response.data.message, 5000);
        vendorOverviewDetail(vendorId);
      } else {
        NotificationManager.error("", response.data.message, 5000);
      }
    } catch (error) {
      handleApiError(error, navigate);
    } finally {
      setApiLoader(false);
    }
  };

  const handledeleteModule = async (deleteProdId) => {
    const requestBody = {
      loginUserId: loginUserId,
      moduleName: "VENDORS",
      moduleId: vendorId,
      assignModuleName: "PRODUCTS",
      assignModuleId: deleteProdId,
    };

    try {
      const headersResult = getHeaders(true);

      // Check if headers result indicates an error
      if (headersResult.error) {
        navigate("../"); // Redirect to login if token is missing
        return;
      }

      const response = await axios.post(URI.deleteRole, requestBody, {
        headers: headersResult,
      });
      if (response.data.status === 200) {
        NotificationManager.success("", response.data.message, 5000);
        vendorOverviewDetail(vendorId);
      } else {
        NotificationManager.error("", response.data.message, 5000);
      }
    } catch (error) {
      handleApiError(error, navigate);
    }
  };

  const handleChangeOwnerInputClick = (e) => {
    e.stopPropagation();
    setIsChangeOwnerList(!isChangeOwnerList);
  };

  const handleChangeOwnerItemClick = (item) => {
    setSelectedOwnerItem(item);
    setIsChangeOwnerList(false);
    setIsOwnerSelect(true);
    // setIsSelectedOwner(false);
  };

  useEffect(() => {
    const foundItem = userList.find((item) => item.userId === loginUserId);
    if (foundItem) {
      setSelectedItem(foundItem);
    }
  }, [loginUserId]);

  useEffect(() => {
    getAllVendorDetails(selectedOption);
  }, [currentPage, perPage]);

  useEffect(() => {
    setInnerPage(innerPage || "list");
  }, []);

  const nodataPage = () => {
    return (
      <div className="d-flex flex-column justify-content-center align-items-center  ">
        <img src={vendorNoteData} alt="img" height={200} width={200} />
        <h5 className="opacity-75">Manage vendor</h5>
        <p className="opacity-75 p-0 m-0">
          Quotes are legal agreements between a customer and a vector
        </p>
        <p className="opacity-75 p-0 m-0">
          to deliver the requested product(s) at the agreed upon price
        </p>
        <p className="opacity-75 p-0 m-0">within the specified time period.</p>
        <button
          className="createContactButtonSave px-2 mt-4 cursorPointer rounded-3"
          style={{ width: "15%" }}
          onClick={() => setInnerPage("create")}
        >
          Create a Vendor
        </button>
      </div>
    );
  };
  const vendorHomePage = () => {
    return (
      <div>
        {isLoading ? (
          <>
            <Loader />
          </>
        ) : filteredVendorDetails &&
          filteredVendorDetails.length === 0 &&
          selectedOption.value === "all_vendors" ? (
          <>{nodataPage()}</>
        ) : (
          <>
            <div className="creteLeadBar">
              {selectedCheckboxes.length === 0 ? (
                <div className="d-flex justify-content-between w-100">
                  <div className="d-flex w-50 align-items-center gap-2">
                    <Select
                      options={options}
                      styles={customCommonStyles("190px")}
                      value={selectedOption}
                      onChange={handleChange}
                      isSearchable={false}
                    />
                  </div>
                  <div className="d-flex w-50 justify-content-end gap-2">
                    <div className="btn-group">
                      <button
                        className="createContactButtonSave py-2"
                        // style={{
                        //   background:
                        //     "linear-gradient(86.07deg, #407BFF 0%, #12A7FE 100%)",
                        //   border: "none",
                        //   borderRight: "2px solid #fff",
                        //   color: "#fff",
                        // }}
                        onClick={handleCreateVendor}
                      >
                        Create Vendor
                      </button>
                    </div>
                  </div>
                </div>
              ) : (
                <>
                  <div className="creteLeadBar">
                    <div className="d-flex w-50 align-items-center gap-2">
                      <Select
                        options={massOptions}
                        styles={customCommonStyles("100%")}
                        value={{
                          value: "Action",
                          label: "Action",
                        }}
                        onChange={handleMassChange}
                        className="w-25"
                        isSearchable={false}
                      />
                    </div>
                  </div>
                </>
              )}
            </div>
            <div className="totalRecordBar">
              <div className="d-flex w-50">
                <h6>
                  {selectedCheckboxes.length > 0
                    ? `${selectedCheckboxes.length} Records Selected`
                    : `Total Records : ${totalItems}`}{" "}
                </h6>
              </div>
              <div className="d-flex w-50 align-items-center justify-content-end gap-2">
                {selectedCheckboxes.length === 0 ? (
                  filteredVendorDetails.length !== 0 && (
                    <Select
                      options={pageOption}
                      styles={customCommonStyles("100%")}
                      // value={selectPageOption}
                      value={pageOption.find(
                        (option) => option.value === perPage.toString()
                      )}
                      onChange={handlePageDropDowmChange}
                      isSearchable={false}
                    />
                  )
                ) : (
                  <></>
                )}

                {filteredVendorDetails.length !== 0 && (
                  <Pagination
                    currentPage={currentPage}
                    totalPages={totalPages}
                    onPageChange={handlePageChange}
                  />
                )}
              </div>
            </div>

            <div className="leadMain">
              <div
                className={
                  filterIcon ? "leadListTableFullWidth" : "leadListTable"
                }
              >
                <table className="table leadTable">
                  <thead>
                    <tr>
                      <th scope="col">
                        <input
                          className="cursorPointer"
                          type="checkbox"
                          id="all"
                          name="all"
                          value="All"
                          checked={filteredVendorDetails.every((selected) =>
                            selectedCheckboxes.some(
                              (list) => list.moduleId === selected.vendorId
                            )
                          )}
                          onChange={(e) => {
                            const allList = filteredVendorDetails.map(
                              (item) => ({
                                moduleId: item.vendorId,
                              })
                            );
                            if (e.target.checked) {
                              const newSelections = allList.filter(
                                (newItem) =>
                                  !selectedCheckboxes.some(
                                    (existingItem) =>
                                      existingItem.moduleId === newItem.moduleId
                                  )
                              );
                              setSelectedCheckboxes((prev) => [
                                ...prev,
                                ...newSelections,
                              ]);
                            } else {
                              setSelectedCheckboxes(
                                selectedCheckboxes.filter(
                                  (aItem) =>
                                    !allList.some(
                                      (bItem) =>
                                        aItem.moduleId === bItem.moduleId
                                    )
                                )
                              );
                            }
                          }}
                          // checked={
                          //   selectedCheckboxes.length ===
                          //   filteredVendorDetails.length
                          // }
                          // onChange={(e) => {
                          //   if (e.target.checked) {
                          //     const allList = filteredVendorDetails.map(
                          //       (item) => ({ moduleId: item.vendorId })
                          //     );
                          //     setSelectedCheckboxes(allList);
                          //   } else {
                          //     setSelectedCheckboxes([]);
                          //   }
                          // }}
                        />
                      </th>
                      {selectedHeadings.map(
                        (heading) =>
                          heading.isChecked && (
                            <th scope="col" key={heading.name}>
                              <span className="pe-3">{heading.name}</span>
                              <span className="position-relative">
                                <img
                                  src={tableFilterIcon}
                                  alt=""
                                  className="cursorPointer"
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    setShowHeadings(false);
                                    setShowSelectedFilter(heading.name);
                                  }}
                                />
                                {heading.name === showSelectedFilter && (
                                  <span
                                    className="position-absolute rounded-2 border bg-light top-0 mt-4"
                                    style={{ right: "-10%" }}
                                  >
                                    <span className="d-flex flex-column px-1">
                                      {/* Ascending filter */}
                                      <span
                                        className="my-1 px-1 cursorPointer fw-normal text-start filter_type"
                                        onClick={() => {
                                          if (filterType === "asc") {
                                            setFilterType("");
                                            setFilteredVendorDetails(
                                              vendorDetails
                                            );
                                          } else {
                                            setFilterType("asc");
                                            sortedColumnsData(
                                              "asc",
                                              heading.value
                                            );
                                          }
                                        }}
                                      >
                                        {filterType === "asc" ? (
                                          <img
                                            src={cancel}
                                            alt="asc"
                                            className="opacity-75 pe-1"
                                          />
                                        ) : (
                                          <img
                                            src={arrowUp}
                                            alt="asc"
                                            className="opacity-75 pe-1"
                                          />
                                        )}
                                        Asc
                                      </span>
                                      {/* Descending filter */}
                                      <span
                                        className="my-2 px-1 cursorPointer fw-normal text-start filter_type"
                                        onClick={() => {
                                          if (filterType === "desc") {
                                            setFilterType("");
                                            setFilteredVendorDetails(
                                              vendorDetails
                                            );
                                          } else {
                                            setFilterType("desc");
                                            sortedColumnsData(
                                              "desc",
                                              heading.value
                                            );
                                          }
                                        }}
                                      >
                                        {filterType === "desc" ? (
                                          <img
                                            src={cancel}
                                            alt="desc"
                                            className="opacity-75 pe-1"
                                          />
                                        ) : (
                                          <img
                                            src={arrowDown}
                                            alt="desc"
                                            className="opacity-75 pe-1"
                                          />
                                        )}
                                        Desc
                                      </span>
                                      <span
                                        className={`my-1 px-1 fw-normal text-start filter_type ${
                                          selectedHeadings[0].name ===
                                            "Vendor Name" &&
                                          heading.name === "Vendor Name"
                                            ? "opacity-50"
                                            : "cursorPointer"
                                        }`}
                                        onClick={() => {
                                          if (pinnedColumn !== "Vendor Name") {
                                            setPinnedColumn("Vendor Name");
                                            handlePinColumn("Vendor Name");
                                          } else {
                                            setPinnedColumn(heading.name);
                                            handlePinColumn(heading.name);
                                          }
                                        }}
                                      >
                                        <img
                                          src={
                                            pinnedColumn === heading.name
                                              ? unPin
                                              : pin
                                          }
                                          alt="pin"
                                          className="opacity-75 pe-1"
                                        />{" "}
                                        {pinnedColumn === heading.name
                                          ? "Unpin"
                                          : "Pin"}{" "}
                                        Column
                                      </span>
                                    </span>
                                  </span>
                                )}
                                <img src={filterLine} alt="" className="ps-2" />
                              </span>
                            </th>
                          )
                      )}

                      {/* <th scope="col">Company</th>
                <th scope="col">Email</th>
                <th scope="col">Phone</th>
                <th scope="col">Lead Source</th> */}
                      {/* <th scope="col">Lead Owner</th> */}
                      <th
                        scope="col"
                        className="text-end cursorPointer position-relative"
                      >
                        <img
                          src={filterMenu}
                          alt=""
                          className={`filter_menu ${
                            showHeadings ? "bg-light" : ""
                          }`}
                          onClick={(e) => {
                            e.stopPropagation();
                            setShowSelectedFilter("");
                            setShowHeadings(true);
                          }}
                        />
                        {showHeadings && (
                          <div
                            className="position-absolute rounded-2  border border-primary bg-light"
                            style={{
                              right: "0.2rem",
                            }}
                          >
                            <div className="d-flex flex-column px-2">
                              <span
                                className="p-2 cursorPointer fw-normal text-start"
                                onClick={() => {
                                  setSearchColumn("");
                                  setSavingHeadings(selectedHeadings);
                                  setShowModalType("Manage Columns");
                                }}
                              >
                                Manage Columns
                              </span>
                              <span
                                className="p-2 fw-normal text-start opacity-50"
                                onClick={() => {
                                  setShowModalType("Reset Column Size");
                                }}
                              >
                                Reset Column Size
                              </span>
                            </div>
                          </div>
                        )}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {filteredVendorDetails &&
                    filteredVendorDetails.length > 0 ? (
                      filteredVendorDetails.map((item, i) => {
                        return (
                          <tr key={i} className="">
                            <td>
                              <input
                                className="cursorPointer"
                                type="checkbox"
                                id="vehicle1"
                                value={item.vendorId}
                                checked={selectedCheckboxes.some(
                                  (checkbox) =>
                                    checkbox.moduleId === item.vendorId
                                )}
                                onChange={handleCheckboxChange}
                              />
                            </td>
                            <React.Fragment>
                              {selectedHeadings.map((obj, i) => {
                                const value = obj.value;
                                const itemValue = item[value];

                                return obj.isChecked ? (
                                  value === "transHash" ? (
                                    <td
                                      style={{ wordBreak: "break-all" }}
                                      className="overflow_text_hash"
                                    >
                                      <a
                                        rel="noreferrer"
                                        href={
                                          EXPLORER_URL +
                                          "about-tx-hash/" +
                                          item.transHash
                                        }
                                        className="anchorLinkHash textColor"
                                        data-title={item.transHash}
                                        target="_blank"
                                      >
                                        {item.transHash
                                          ? item.transHash.slice(0, 10) + "...."
                                          : "-"}
                                      </a>
                                    </td>
                                  ) : (
                                    <td
                                      key={i}
                                      onClick={() => {
                                        if (value === "vendorName") {
                                          vendorOverviewDetail(item.vendorId);
                                          setVendorId(item.vendorId);
                                          getNotesDetails(
                                            item.vendorId,
                                            selectRecent
                                          );
                                          setSelectedVendorsId(
                                            vendorDetails.findIndex(
                                              (lead) =>
                                                lead.vendorId === item.vendorId
                                            )
                                          );
                                          setFetchVendorInfo(item);
                                        }
                                      }}
                                      className={`overflow_text ${
                                        value === "vendorName"
                                          ? "vendorName cursorPointer"
                                          : ""
                                      }`}
                                      style={{ maxWidth: "3vw" }}
                                    >
                                      {itemValue !== null ? itemValue : "-"}
                                    </td>
                                  )
                                ) : null;
                              })}
                            </React.Fragment>
                            <td></td>
                          </tr>
                        );
                      })
                    ) : (
                      <tr>
                        <td
                          colSpan={selectedHeadings.length + 2}
                          style={{ textAlign: "center" }}
                        >
                          No Data
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </>
        )}
        <Modal
          show={modalType !== ""}
          onHide={handleClose}
          aria-labelledby="contained-modal-title-vcenter"
        >
          <Modal.Header
            className="border-0 mb-0 d-flex flex-column align-items-start"
            style={{ backgroundColor: "#F2F2F2" }}
          >
            <div
              className={`mass_heading fs-4  ${
                !["Mass Delete"].includes(modalType) ? "ps-4" : ""
              }`}
            >
              {modalType === "Mass Update" && "Mass Update"}
              {modalType === "Mass Delete" && "Mass Delete"}
              {modalType === "Mass Transfer" && "Mass Transfer"}
            </div>
          </Modal.Header>
          <Modal.Body>
            {modalType === "Mass Update" && (
              <MassUpdate
                dropdownAllDetails={dropdownAllDetails}
                setSearchTerm={setSearchTerm}
                searchTerm={searchTerm}
                filteredOptions={filteredOptions}
                setMassVal={setMassVal}
                setClickedDropdownObj={setClickedDropdownObj}
                setIsFieldSelected={setIsFieldSelected}
                setSelectDropdownAllDetails={setSelectDropdownAllDetails}
                clickedDropdownObj={clickedDropdownObj}
                glAccountOptions={glAccountOptions}
                fieldDropdownStyles={fieldDropdownStyles}
                massVal={massVal}
                validatePhoneNumber={validatePhoneNumber}
                handleUpdateMassDetails={handleUpdateMassDetails}
                isFieldSelected={isFieldSelected}
                handleClose={handleClose}
              />
            )}

            {modalType === "Mass Delete" && (
              <MassDelete
                handleClose={handleClose}
                deleteMassDetails={deleteMassDetails}
              />
            )}

            {modalType === "Mass Transfer" && (
              <MassTransfer
                handleOwnerInputClick={handleOwnerInputClick}
                setIsLeadOwnerModal={setIsLeadOwnerModal}
                handleClose={handleClose}
                handleMassTransfer={handleMassTransfer}
                selectedItem={selectedItem}
              />
            )}
          </Modal.Body>
        </Modal>
      </div>
    );
  };
  const handleOwnerInputClick = (e) => {
    e.stopPropagation();
    setListVisible(!listVisible);
  };

  const handleUserItemClick = (item) => {
    setSelectedItem(item);
    setListVisible(false);
  };

  const handleSelectedLeadChange = (value) => {
    setSelectedItem(value);
    setSelectedOwnerItem(value);
  };

  const updateNote = (noteId) => {
    const formData = new FormData();
    formData.append("moduleId", vendorId);
    formData.append("loggedInUserId", loginUserId);
    formData.append("moduleName", "VENDORS");
    formData.append("noteDescription", updateNotes.description);
    formData.append("noteTitle", updateNotes.title);
    formData.append("noteId", noteId);
    formData.append("deletedImageNames", deletedImg.toString());

    selectedNoteFiles.forEach((file) => {
      formData.append("noteImages", file);
    });
    const headersResult = getHeaders(true);

    // Check if headers result indicates an error
    if (headersResult.error) {
      navigate("../"); // Redirect to login if token is missing
      return;
    }

    axios
      .post(URI.updateNote, formData, {
        headers: headersResult,
      })
      .then((response) => {
        if (response.data.status === 200) {
          setShowButtons(false);
          setEditNote(false);
          setUpdateNotes({
            description: "",
            title: "",
            noteImageList: [],
          });
          setSelectedNoteFiles([]);
          getNotesDetails(vendorId, selectRecent);
          setDeletedImg("");
        } else {
          setDeletedImg("");
          setSelectedNoteFiles([]);
          NotificationManager.error("", response.data.message, 5000);
        }
      })
      .catch((error) => {
        handleApiError(error, navigate);
      });
  };

  const handleSubmitVendor = async (e) => {
    const vendorData =
      existingVendor || existingClone ? vendorUpdateInfo : vendorFormData;

    const isValidForm = validateVendorData(
      vendorData,
      setMandatoryMsg,
      setEmailMsg,
      setPhoneMsg,
      setWebsiteMsg,
      setCityMsg,
      setStateMsg,
      setCodeMsg,
      setCountryMsg,
      validateFirstName,
      emailValidation,
      validateCityIndia,
      validateStateIndia,
      validateZipCode,
      validateCountryName,
      validateMobileNumber,
      websiteValidation,
      validateCategory,
      setCategoryMsg
    );
    if (!isValidForm) return;

    const formData = new FormData();
    e === "SAVE AND NEW" && setSaveAndNewSubmit(true);
    e === "SAVE" && setBtnLoader(true);
    e === "CLONE SAVE AND NEW" && setSaveAndNewSubmit(true);
    e === "ADD" && setBtnLoader(true);

    let requestBody = {};
    if (existingClone) {
      requestBody = {
        vendorId: vendorId,
        vendorOwnerId: selectedItem.userId,
        loggedInUserId: loginUserId,
        vendorName: removeExtraSpaces(vendorUpdateInfo.vendorName),
        phone: vendorUpdateInfo.phone,
        emailId: removeExtraSpaces(vendorUpdateInfo.emailId),
        website: removeExtraSpaces(vendorUpdateInfo.website),
        glAccount: vendorUpdateInfo.glAccount.value,
        category: removeExtraSpaces(vendorUpdateInfo.category),
        street: removeExtraSpaces(vendorUpdateInfo.street),
        city: removeExtraSpaces(vendorUpdateInfo.city),
        state: removeExtraSpaces(vendorUpdateInfo.state),
        zipCode: vendorUpdateInfo.zipCode,
        country: removeExtraSpaces(vendorUpdateInfo.country),
        vendorDescription: removeExtraSpaces(
          vendorUpdateInfo.vendorDescription
        ),
        emailOptOut: vendorUpdateInfo.emailOptOut === true ? "true" : "false",
      };
    } else {
      requestBody = {
        vendorOwnerId: selectedItem.userId,
        loggedInUserId: loginUserId,
        vendorName: removeExtraSpaces(vendorFormData.vendorName),
        phone: vendorFormData.phone,
        emailId: removeExtraSpaces(vendorFormData.emailId),
        website: removeExtraSpaces(vendorFormData.website),
        glAccount: vendorFormData.glAccount.value,
        category: removeExtraSpaces(vendorFormData.category),
        street: removeExtraSpaces(vendorFormData.street),
        city: removeExtraSpaces(vendorFormData.city),
        state: removeExtraSpaces(vendorFormData.state),
        zipCode: removeExtraSpaces(vendorFormData.zipCode),
        country: removeExtraSpaces(vendorFormData.country),
        vendorDescription: removeExtraSpaces(vendorFormData.vendorDescription),

        emailOptOut: emailOptOut === true ? "true" : "false",
      };
    }
    formData.append("vendorDtlsInputs", JSON.stringify(requestBody));
    formData.append(
      "vendorImage",
      existingClone ? vendorUpdateInfo.vendorImage : vendorFormData.vendorImage
    );

    const data = await handleSubmitVendorDetails(
      e,
      requestBody,
      existingClone,
      vendorUpdateInfo,
      vendorFormData,
      setSaveAndNewSubmit,
      setBtnLoader,
      setInnerPage,
      navigate
    );
    if (data.status === 200) {
      NotificationManager.success("", data.message, 5000);
      getAllVendorDetails(selectedOption);
      setSelectedFiles(null);
      fileInputRef.current.value = "";
      resetForm();
      if (e === "SAVE" || e === "ADD") {
        setInnerPage("list");
        resetForm();
      }
      if (e === " SAVE AND NEW" || e === "CLONE SAVE AND NEW") {
        resetForm();
        setInnerPage("create");
      }
    } else {
      NotificationManager.error("", data.message, 5000);
    }
  };

  const [deleteModal, setDeleteModal] = useState(false);

  const deleteVendor = async () => {
    const requestBody = {
      loggedInUserId: loginUserId,
      vendorId: vendorId,
    };
    setApiLoader(true);

    const headersResult = getHeaders(true);

    // Check if headers result indicates an error
    if (headersResult.error) {
      navigate("../"); // Redirect to login if token is missing
      return;
    }

    await axios
      .post(URI.deleteVendor, requestBody, {
        headers: headersResult,
      })
      .then((response) => {
        if (response.data.status === 200) {
          NotificationManager.success("", response.data.message, 5000);
          getAllVendorDetails(selectedOption);
          setShowOptionsVendor(false);
          setInnerPage("list");
        } else {
          NotificationManager.error("", response.data.message, 5000);
        }
      })
      .catch(function (error) {
        handleApiError(error, navigate);
      })
      .finally(() => {
        setApiLoader(false);
        setDeleteModal(false);
      });
  };

  /* eslint-disable complexity */

  // there is not much complexity in this code so i am disabling the rules here
  const createVendorPage = () => {
    return (
      <>
        <div className="createLeadButtonBar">
          <div className="d-flex align-items-center w-50 gap-2">
            <img src={groupIcon} height={25} alt="img" />
            <div className="d-flex flex-column">
              <p className="mb-0 first_letter_uppercase">
                {existingVendor ? "Edit" : innerPage} Vendor
              </p>
            </div>
          </div>
          <div className="d-flex w-50 gap-2 justify-content-end">
            <button
              className="createLeadButton"
              onClick={() => {
                // setInnerPage("list");
                if (existingVendor || existingClone) {
                  setInnerPage("individualVendorPage");
                } else {
                  setInnerPage("list");
                }
                if (innerPage === "clone" && !existingVendor) {
                  setInnerPage("individualVendorPage");
                }
                resetForm();
                setExistingVendor(false);
                setSelectedFiles(null);
                fileInputRef.current.value = "";
              }}
            >
              Cancel
            </button>
            {!existingVendor && (
              <button
                className="createLeadButton"
                style={{ width: "135px", padding: "5px 2px" }}
                onClick={() => {
                  if (existingVendor) {
                    handleUpdateVendor("SAVE AND NEW");
                  }
                  if (existingClone) {
                    handleSubmitVendor("CLONE SAVE AND NEW");
                  } else {
                    handleSubmitVendor("SAVE AND NEW");
                  }
                }}
                disabled={saveAndNewSubmit}
              >
                {saveAndNewSubmit ? (
                  <Lottie
                    options={defaultOptions("blue")}
                    height="24px"
                    width="36px"
                  />
                ) : (
                  "Save and New"
                )}
              </button>
            )}

            <>
              <button
                className="save-button"
                type="submit"
                style={{ width: "135px", padding: "5px 2px" }}
                onClick={() => {
                  if (existingVendor) {
                    handleUpdateVendor("SAVE");
                  } else if (existingClone) {
                    handleSubmitVendor("ADD");
                    resetForm();
                  } else {
                    handleSubmitVendor("SAVE");
                  }
                }}
                disabled={btnLoader}
              >
                {existingVendor ? (
                  <>
                    {btnLoader ? (
                      <Lottie
                        options={defaultOptions("white")}
                        height="24px"
                        width="36px"
                      />
                    ) : (
                      "Update"
                    )}
                  </>
                ) : (
                  <>
                    {btnLoader ? (
                      <Lottie
                        options={defaultOptions("white")}
                        height="24px"
                        width="36px"
                      />
                    ) : (
                      "Save"
                    )}
                  </>
                )}
              </button>
            </>
          </div>
        </div>

        <div className="leadFields ">
          <h6 className="py-2 " style={{ borderBottom: "2px solid #316AFF" }}>
            Vendor Information
          </h6>
          <div className="accountInputFieldDiv">
            <div
              className={`form-group ${isDragging ? "drag-over" : ""}`}
              onDragOver={handleDragOver}
              onDragLeave={handleDragLeave}
              onDrop={handleDrop}
            >
              <p className="mb-0">Upload Image</p>
              <input
                type="file"
                id="vendorImage"
                name="vendorImage"
                accept=".png, .jpg, .jpeg, .gif"
                className="file-control"
                onChange={handVendorImageChange}
                ref={fileInputRef}
                style={{ display: "none" }}
              />
              <label htmlFor="vendorImage" className="label-file">
                <span>
                  {selectedFiles
                    ? selectedFiles.name.length > 30
                      ? `${selectedFiles.name.substring(0, 30)}...`
                      : selectedFiles.name
                    : "Drag and Drop files (JPG,PNG)"}
                </span>
              </label>
              {imageMsg && <p className="mb-0 small erro_msg">{imageMsg}</p>}
            </div>
          </div>
          <div className="inputFieldDiv">
            <div className="inputFieldDivRight position-relative">
              <p className="mb-0">Vendor Owner</p>
              <div className="d-flex input-with-clear ">
                <input
                  type="text"
                  className="insertCommonInputField cursorPointer"
                  value={selectedItem.name}
                  onClick={handleOwnerInputClick}
                  readOnly
                />

                {existingVendor ? (
                  <></>
                ) : (
                  <span
                    className="clear-button"
                    onClick={() => selectedItem && setSelectedItem("")}
                  >
                    <img
                      src={listVisible ? up : down}
                      alt="img"
                      height={20}
                      width={20}
                    />
                  </span>
                )}

                <span
                  className="cursorPointer pt-2 ps-1"
                  // onClick={() => setIsLeadOwnerModal(!isLeadOwnerModal)}
                  onClick={() => {
                    if (existingVendor) {
                      setShowModalType("changeOwner");
                      // setListVisible(false);
                    } else {
                      setIsLeadOwnerModal(!isLeadOwnerModal);
                    }
                  }}
                >
                  <img src={userSearch} alt="img" width={20} height={20} />
                </span>
              </div>

              {existingVendor ? (
                <></>
              ) : (
                <>
                  {listVisible && (
                    <ul
                      className="data-list list-group  lead_ownerList_container position-absolute"
                      style={{ top: "6rem" }}
                    >
                      {userList.map((item, index) => (
                        <li
                          key={index}
                          className="cursorPointer list-group-item"
                          onClick={() => handleUserItemClick(item)}
                        >
                          <div className="d-flex flex-column">
                            <span> {item.name} </span>
                            <span> {item.emailId} </span>
                          </div>
                        </li>
                      ))}
                    </ul>
                  )}
                </>
              )}
            </div>
            <div className="inputFieldDivRight">
              <p className="mb-0">
                Vendor Name<span style={{ color: "red" }}> *</span>
              </p>
              <input
                type="text"
                className={
                  mandatoryMsg ? "mandatoryField " : "insertCommonInputField"
                }
                name="vendorName"
                value={
                  existingVendor || existingClone
                    ? vendorUpdateInfo.vendorName
                    : vendorFormData.vendorName
                }
                onChange={handleChangeVendor}
                onKeyDown={handleKeyDown}
                autoComplete="off"
                onPaste={(e) => e.preventDefault()}
                onCopy={(e) => e.preventDefault()}
                onInput={(e) => {
                  // Allow only letters and white spaces
                  e.target.value = e.target.value.replace(/[^A-Za-z\s]/gi, "");
                }}
              />
              <p className="mb-0" style={{ color: "#fa5a5a" }}>
                {mandatoryMsg && mandatoryMsg}
              </p>
            </div>
          </div>
          <div className="inputFieldDiv">
            <div className="inputFieldDivLeft">
              <p className="mb-0">
                Phone<span style={{ color: "red" }}> *</span>
              </p>
              <input
                type="text"
                className={
                  phoneMsg ? "mandatoryField " : "insertCommonInputField"
                }
                name="phone"
                pattern="[0-9]"
                maxLength={10}
                value={
                  existingVendor || existingClone
                    ? vendorUpdateInfo.phone
                    : vendorFormData.phone
                }
                onChange={handleChangeVendor}
                onKeyDown={(e) => {
                  // Allow only numeric input
                  if (
                    !/\d/.test(e.key) &&
                    e.key !== "Backspace" &&
                    e.key !== "Delete" &&
                    e.key !== "ArrowLeft" &&
                    e.key !== "ArrowRight"
                  ) {
                    e.preventDefault();
                  }
                }}
                autoComplete="off"
                onPaste={(e) => e.preventDefault()}
                onCopy={(e) => e.preventDefault()}
              />
              <p style={{ color: "#fa5a5a" }}>{phoneMsg && phoneMsg}</p>
            </div>
            <div className="inputFieldDivRight">
              <p className="mb-0">
                Email<span style={{ color: "red" }}> *</span>
              </p>
              <input
                type="text"
                className={
                  emailMsg ? "mandatoryField" : "insertCommonInputField"
                }
                autoComplete="off"
                name="emailId"
                value={
                  existingVendor || existingClone
                    ? vendorUpdateInfo.emailId
                    : vendorFormData.emailId
                }
                // value={leadInfo.phoneNumber.replace(
                //   /[^0-9]|([\u2700-\u27BF]|[\uE000-\uF8FF]|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDFFF]|[\u2011-\u26FF]|\uD83E[\uDD10-\uDDFF])/g,
                //   ""
                // )}
                onChange={handleChangeVendor}
                onKeyDown={handleKeyDown}
                onPaste={(e) => e.preventDefault()}
                onCopy={(e) => e.preventDefault()}
              />

              <p style={{ color: "#fa5a5a" }}>{emailMsg && emailMsg}</p>
            </div>
          </div>
          <div className="inputFieldDiv">
            <div className="inputFieldDivLeft">
              <p className="mb-0"> Website </p>
              <input
                type="text"
                className={
                  websiteMsg ? "mandatoryField" : "insertCommonInputField"
                }
                autoComplete="off"
                name="website"
                value={
                  existingVendor || existingClone
                    ? vendorUpdateInfo.website
                    : vendorFormData.website
                }
                onChange={handleChangeVendor}
                onKeyDown={handleKeyDown}
                onPaste={(e) => e.preventDefault()}
                onCopy={(e) => e.preventDefault()}
              />
              <p style={{ color: "#fa5a5a" }}>{websiteMsg && websiteMsg}</p>
            </div>
            <div className="inputFieldDivLeft">
              <p className="mb-0">GL ACCOUNT</p>

              <Select
                value={
                  existingVendor || existingClone
                    ? vendorUpdateInfo.glAccount
                    : vendorFormData.glAccount
                }
                // value={glAccount.find(
                //   (option) => option.value === glAccountValue
                // )}
                menuPlacement="auto"
                onChange={(selectedOption) =>
                  handleChangeSelectDropDown(selectedOption, "glAccount")
                }
                options={glAccountOptions}
                styles={createLeadStyles}
                isSearchable={false}
              />
            </div>
          </div>
          <div className="inputFieldDiv">
            <div className="inputFieldDivLeft">
              <p className="mb-0">Category</p>
              <input
                type="text"
                className="insertCommonInputField"
                name="category"
                value={
                  existingVendor || existingClone
                    ? vendorUpdateInfo.category
                    : vendorFormData.category
                }
                onChange={handleChangeVendor}
                onKeyDown={handleKeyDown}
                autoComplete="off"
                onPaste={(e) => e.preventDefault()}
                onCopy={(e) => e.preventDefault()}
              />
              <p style={{ color: "#fa5a5a" }}>{categoryMsg && categoryMsg}</p>
            </div>
            <div className="inputFieldDivRight flex-row  mt-5">
              <input
                type="checkbox"
                // className="insertCommonInputField"
                name="emailOptOut"
                checked={
                  existingVendor || existingClone ? emailOptOut : emailOptOut
                }
                // value={existingVendor ? leadUpdateInfo.skypeId : leadInfo.skypeId}
                onChange={() => setEmailOptOut(!emailOptOut)}
                onKeyDown={handleKeyDown}
                autoComplete="off"
                onPaste={(e) => e.preventDefault()}
                onCopy={(e) => e.preventDefault()}
              />
              <span className="mb-0">Email Opt Out</span>
            </div>
          </div>

          {/* </form> */}
          <h6 className="py-2" style={{ borderBottom: "2px solid #316AFF" }}>
            Address Information
          </h6>

          <div className="inputFieldDiv">
            <div className="inputFieldDivRight">
              <p className="mb-0">Street</p>
              <input
                type="text"
                className="insertCommonInputField"
                name="street"
                maxLength={50}
                value={
                  existingVendor || existingClone
                    ? vendorUpdateInfo.street
                    : vendorFormData.street
                }
                onChange={handleChangeVendor}
                onKeyDown={handleKeyDown}
                autoComplete="off"
                onPaste={(e) => e.preventDefault()}
                onCopy={(e) => e.preventDefault()}
              />
            </div>

            <div className="inputFieldDivLeft">
              <p className="mb-0">City</p>
              <input
                type="text"
                className="insertCommonInputField"
                name="city"
                maxLength={30}
                value={
                  existingVendor || existingClone
                    ? vendorUpdateInfo.city
                    : vendorFormData.city
                }
                onChange={handleChangeVendor}
                onKeyDown={(e) => {
                  if (e.key === " " && !e.target.value.trim()) {
                    e.preventDefault();
                  } else if (/[^a-zA-Z\s]/.test(e.key)) {
                    e.preventDefault();
                  }
                }}
                autoComplete="off"
                onPaste={(e) => e.preventDefault()}
                onCopy={(e) => e.preventDefault()}
              />
              <p style={{ color: "#fa5a5a" }}>{cityMsg && cityMsg}</p>
            </div>
          </div>
          <div className="inputFieldDiv">
            <div className="inputFieldDivRight">
              <p className="mb-0">State</p>
              <input
                type="text"
                className="insertCommonInputField"
                name="state"
                value={
                  existingVendor || existingClone
                    ? vendorUpdateInfo.state
                    : vendorFormData.state
                }
                onChange={handleChangeVendor}
                maxLength={30}
                onKeyDown={(e) => {
                  if (e.key === " " && !e.target.value.trim()) {
                    e.preventDefault();
                  } else if (/[^a-zA-Z\s]/.test(e.key)) {
                    e.preventDefault();
                  }
                }}
                autoComplete="off"
                onPaste={(e) => e.preventDefault()}
                onCopy={(e) => e.preventDefault()}
              />
              <p style={{ color: "#fa5a5a" }}>{stateMsg && stateMsg}</p>
            </div>
            <div className="inputFieldDivLeft">
              <p className="mb-0">Zip Code</p>
              <input
                type="text"
                className="insertCommonInputField"
                pattern="[0-9]*"
                maxLength={6}
                name="zipCode"
                // value={vendorFormData.zipCode}
                // value={addressInfo.zipCode.replace(
                //   /[^0-9]|([\u2700-\u27BF]|[\uE000-\uF8FF]|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDFFF]|[\u2011-\u26FF]|\uD83E[\uDD10-\uDDFF])/g,
                //   ""
                // )}
                value={
                  existingVendor || existingClone
                    ? vendorUpdateInfo.zipCode
                    : // .replace(
                      //     /[^0-9]|([\u2700-\u27BF]|[\uE000-\uF8FF]|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDFFF]|[\u2011-\u26FF]|\uD83E[\uDD10-\uDDFF])/g,
                      //     ""
                      //   )
                      vendorFormData.zipCode
                  // .replace(
                  //     /[^0-9]|([\u2700-\u27BF]|[\uE000-\uF8FF]|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDFFF]|[\u2011-\u26FF]|\uD83E[\uDD10-\uDDFF])/g,
                  //     ""
                  //   )
                }
                onChange={handleChangeVendor}
                onKeyDown={handleKeyDownForZip}
                autoComplete="off"
                onPaste={(event) => event.preventDefault()}
                onCopy={(event) => event.preventDefault()}
              />
              <p style={{ color: "#fa5a5a" }}>{codeMsg && codeMsg}</p>
            </div>
          </div>
          <div className="inputFieldDiv">
            <div className="inputFieldDivLeft">
              <p className="mb-0">Country</p>
              <input
                type="text"
                className="insertCommonInputField"
                name="country"
                // value={vendorFormData.country}
                value={
                  existingVendor || existingClone
                    ? vendorUpdateInfo.country
                    : vendorFormData.country
                }
                onChange={handleChangeVendor}
                onKeyDown={(e) => {
                  if (e.key === " " && !e.target.value.trim()) {
                    e.preventDefault();
                  } else if (/[^a-zA-Z\s]/.test(e.key)) {
                    e.preventDefault();
                  }
                }}
                autoComplete="off"
                onPaste={(e) => e.preventDefault()}
                onCopy={(e) => e.preventDefault()}
                maxLength={30}
              />
              <p style={{ color: "#fa5a5a" }}>{countryMsg && countryMsg}</p>
            </div>
          </div>
          <h6 className="py-2" style={{ borderBottom: "2px solid #316AFF" }}>
            Description Information
          </h6>
          <div className="inputFieldDiv" style={{ display: "block" }}>
            <div
              className="inputFieldDivLeft"
              style={{ display: "block", width: "100%" }}
            >
              <p>Description</p>
              <textarea
                rows="4"
                cols="50"
                maxLength="2000"
                style={{
                  width: "100%",
                  border: "1px solid #B9B9B94D",
                  height: "100px",
                  outline: "none",
                  borderRadius: "10px",
                  backgroundColor: "transparent",
                  padding: "8px 12px",
                }}
                name="vendorDescription"
                // value={vendorFormData.vendorDescription}
                value={
                  existingVendor || existingClone
                    ? vendorUpdateInfo.vendorDescription
                    : vendorFormData.vendorDescription
                }
                onChange={handleChangeVendor}
                onKeyDown={handleKeyDown}
                autoComplete="off"
                onPaste={(e) => e.preventDefault()}
                onCopy={(e) => e.preventDefault()}
              />
            </div>
          </div>
        </div>
      </>
    );
  };

  /* eslint-enable complexity */

  const EditableCheckbox = ({
    type,
    checked,
    handleChange,
    handleEditClick,
    handleUpdateClick,
    showType,
    setShowType,
  }) => {
    return (
      <div className="d-flex editInput_container">
        {![type].includes(showType) ? (
          <span
            className="right_tick cursorPointer"
            onClick={() => {
              handleEditClick(type);
              setShowType(type);
            }}
          >
            {checked ? tick : "-"}
          </span>
        ) : (
          <input
            type="checkbox"
            // checked={updatedFields.emailOptOut}
            checked={checked}
            // onChange={(e) => {
            //   setUpdatedFields((prevFields) => ({
            //     ...prevFields,
            //     emailOptOut: e.target.checked,
            //   }));
            // }}
            // onClick={() => {
            //   handleEditClick("emailOptOut");
            // }}

            onChange={handleChange}
            onClick={() => {
              if (showType === "") {
                setShowType(type);
              }
              handleEditClick(type);
            }}
            readOnly={!editingFields[type]}
          />
        )}
        {showType === type && (
          <div className="d-flex gap-2">
            <span
              onClick={() => {
                setShowType("");
                handleUpdateClick(type);
              }}
              className="ps-2 cursorPointer"
            >
              <img src={updateIcon} alt="Update" height={20} width={20} />
            </span>
            <span
              onClick={() => {
                setShowType("");
                handleCancelClick(type);
              }}
              className="cursorPointer"
            >
              <img src={cancelIcon} alt="Cancel" height={20} width={20} />
            </span>
          </div>
        )}
      </div>
    );
  };

  const individualVendorPage = () => {
    return (
      <>
        <div className="indiv_main_container ">
          <div className="indiv_main_container ">
            <div className="individual_header_container commonContainer_bg w-100  flex-column  d-flex justify-content-between ps-2 pe-2">
              <div className="iconContainer d-flex justify-content-between px-2 pt-2">
                <span
                  className="cursorPointer"
                  onClick={() => {
                    getAllVendorDetails(selectedOption);
                    setInnerPage("list");
                    setVendorIndivPage("overview");
                    setFetchVendorInfo(null);
                    const resetFields = Object.fromEntries(
                      Object.keys(editingFields).map((key) => [key, false])
                    );
                    setEditingFields({
                      ...resetFields,
                    });
                    setShowType("");
                  }}
                >
                  {leftArrow}
                </span>
                <span>
                  <span
                    className={`me-2 ${
                      selectedVendorsId === 0 ? "opacity-50" : "cursorPointer"
                    }`}
                    onClick={() => {
                      const leadIndex = vendorDetails.findIndex(
                        (contact) => contact.vendorId === vendorId
                      );
                      if (leadIndex !== 0) {
                        setSelectedVendorsId(leadIndex - 1);
                        setVendorId(vendorDetails[leadIndex - 1].vendorId);
                        vendorOverviewDetail(
                          vendorDetails[leadIndex - 1].vendorId
                        );
                        getNotesDetails(
                          vendorDetails[leadIndex - 1].vendorId,
                          selectRecent
                        );
                        setFetchVendorInfo(vendorDetails[leadIndex - 1]);
                      }
                    }}
                  >
                    {leftAngleArrow}
                  </span>
                  <span
                    className={`ms-1 ${
                      selectedVendorsId === vendorDetails.length - 1
                        ? "opacity-50"
                        : "cursorPointer"
                    }`}
                    onClick={() => {
                      const leadIndex = vendorDetails.findIndex(
                        (lead) => lead.vendorId === vendorId
                      );
                      if (leadIndex < vendorDetails.length - 1) {
                        setSelectedVendorsId(leadIndex + 1);
                        setVendorId(vendorDetails[leadIndex + 1].vendorId);
                        vendorOverviewDetail(
                          vendorDetails[leadIndex + 1].vendorId
                        );
                        getNotesDetails(
                          vendorDetails[leadIndex + 1].vendorId,
                          selectRecent
                        );
                        setFetchVendorInfo(vendorDetails[leadIndex + 1]);
                      }
                    }}
                  >
                    {rightAngleArrow}
                  </span>
                </span>
              </div>
              <div>
                <div className="profile_logo_container d-flex pt-2 cursorPointer align-items-center">
                  <img
                    src={
                      vendorInfoDetails.vendorImageData === null
                        ? vendorAvatar
                        : vendorInfoDetails.vendorImageData
                    }
                    alt="img"
                    style={{ width: "100px", height: "100px" }}
                    onClick={() => {
                      setShowImageModal(true);
                    }}
                  />
                  <div className="d-flex flex-column  justify-content-end ps-3">
                    <span className="fs-6">
                      {vendorInfoDetails?.vendorName}
                    </span>
                    {/* <span className="opacity-75 ">
                      Creative Business Analyst
                    </span> */}
                  </div>
                </div>
                <div className="d-flex  justify-content-end align-items-end  px-2 pb-2">
                  <div className="d-flex gap-2">
                    {/* <button className="btn  btnColor text-white">
                      Send Email
                    </button> */}
                    <button
                      className="btn  btnColor text-white"
                      onClick={handleEditVendor}
                    >
                      Edit
                    </button>
                    <button
                      className="btn btn-secondary bg-white text-dark border border-primary"
                      onClick={(e) => {
                        setShowOptionsVendor(!showOptionsVendor);
                        e.stopPropagation();
                      }}
                    >
                      ---
                    </button>
                    {showOptionsVendor && (
                      <div className="rounded  showOptions p-2">
                        <div
                          className="d-flex gap-3 align-items-center mt-2"
                          onClick={handleCreateVendorClone}
                        >
                          <img src={Clone} alt="close" />
                          <div
                            style={{ color: "#515151", fontWeight: "300" }}
                            className="mt-1 cursorPointer ms-2"
                          >
                            Clone
                          </div>
                        </div>
                        {vendorOverviewDetails.isShared === false && (
                          <div
                            className="d-flex gap-3 align-items-center mt-2"
                            onClick={() => {
                              if (!isLoading) {
                                getAllInfoShare(vendorId);
                              }
                            }}
                          >
                            <img src={Share} alt="close" />
                            <div
                              style={{ color: "#515151", fontWeight: "300" }}
                              className="mt-2 cursorPointer ms-2"
                            >
                              Share
                            </div>
                          </div>
                        )}

                        <div
                          className="d-flex gap-3 align-items-center mt-2"
                          onClick={handleDeleteVendor}
                        >
                          <img src={Delete} alt="close" />
                          <div
                            style={{ color: "#515151", fontWeight: "300" }}
                            className="mt-2 cursorPointer ms-2"
                          >
                            Delete
                          </div>
                        </div>
                        <div
                          style={{ borderBottom: "1px solid #515151s" }}
                        ></div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            className="detailList_main_container commonContainer_bg"
            style={{ height: "auto" }}
          >
            <div className="detailList_main_container d-flex pt-3 pb-2">
              {/* <div className="w-25 ps-3 pt-3">Icon</div> */}
              <div
                className={` pt-3 cursorPointer ${isShrinked ? "" : "w-25"}`}
                onClick={() => {
                  setIsShrinked(!isShrinked);
                }}
              >
                {isShrinked ? expandArrow : shrinkArrow}
              </div>

              <div
                className={`d-flex justify-content-between px-2 ${
                  isShrinked ? "w-100" : "w-75 ps-3"
                }`}
              >
                <div className="pt-3">
                  Last Update: {vendorOverviewDetails.lastUpdate}
                </div>
                <div className="d-flex  gap-4 justify-content-between px-2 bg-white px-1  rounded-5 py-1">
                  <span
                    className={`cursorPointer ${
                      vendorIndivPage === "overview"
                        ? "overviewTimelineTab"
                        : "d-flex justify-content-center align-items-center"
                    }`}
                    onClick={() => setVendorIndivPage("overview")}
                  >
                    Overview
                  </span>
                  <span
                    className={`cursorPointer ${
                      vendorIndivPage === "timeline"
                        ? "overviewTimelineTab"
                        : " d-flex justify-content-center align-items-center"
                    }`}
                    onClick={() => {
                      if (vendorIndivPage !== "timeline") {
                        setVendorIndivPage("timeline");
                        getAllTimeleadVendor(vendorId);
                      }
                    }}
                  >
                    Timeline
                  </span>
                </div>
              </div>
            </div>

            <div className="detailList_content_container d-flex justify-content-between px-2 py-2 bg-white">
              <>
                <div
                  className="w-25 relatedListContainer  rounded commonContainer_bg me-2  "
                  // style={{ height: "100vh" }}
                >
                  <div className="d-flex">
                    <p className="opacity-57 ps-2 opacity-75">Related List</p>
                  </div>
                  <div className="horizontal_line mx-1"></div>
                  <ul>
                    {notePermission === "TRUE" && (
                      <li
                        className={`cursorPointer py-2 relatedList_tab rounded-1`}
                        onClick={() => {
                          if (vendorIndivPage === "timeline") {
                            setVendorIndivPage("overview");
                            // setActiveTab("notes");
                            setTimeout(
                              () => scrollToSection("notesSection"),
                              0
                            );
                          } else {
                            // setActiveTab("notes");
                            scrollToSection("notesSection");
                          }
                        }}
                      >
                        <span>
                          <img
                            src={NoteBook}
                            alt="img"
                            height={20}
                            width={20}
                          />
                        </span>
                        Notes
                      </li>
                    )}

                    <li
                      className={`cursorPointer py-2 relatedList_tab rounded-1`}
                      onClick={() => {
                        if (vendorIndivPage === "timeline") {
                          setVendorIndivPage("overview");
                          // setActiveTab("product");
                          setTimeout(() => scrollToSection("product"), 0);
                        } else {
                          // setActiveTab("product");
                          scrollToSection("product");
                        }
                      }}
                    >
                      <span>
                        <img src={prodIcon} alt="img" height={20} width={20} />
                      </span>
                      Products
                    </li>
                    <li
                      className={`cursorPointer py-2 relatedList_tab rounded-1`}
                      onClick={() => {
                        if (vendorIndivPage === "timeline") {
                          setVendorIndivPage("overview");
                          // setActiveTab("purchaseOrder");
                          setTimeout(() => scrollToSection("purchaseOrder"), 0);
                        } else {
                          // setActiveTab("purchaseOrder");
                          scrollToSection("purchaseOrder");
                        }
                      }}
                    >
                      <span>
                        <img
                          src={inviteIcon}
                          alt="img"
                          height={20}
                          width={20}
                        />
                      </span>
                      Purchase Orders
                    </li>
                    <li
                      className={`cursorPointer py-2 relatedList_tab rounded-1`}
                      onClick={() => {
                        if (vendorIndivPage === "timeline") {
                          setVendorIndivPage("overview");
                          // setActiveTab("contacts");
                          setTimeout(() => scrollToSection("contacts"), 0);
                        } else {
                          // setActiveTab("contacts");
                          scrollToSection("contacts");
                        }
                      }}
                    >
                      <span>
                        <img
                          src={attachIcon}
                          alt="img"
                          height={20}
                          width={20}
                        />
                      </span>
                      Contacts
                    </li>
                    <li
                      className={`cursorPointer py-2 relatedList_tab rounded-1`}
                      onClick={() => {
                        if (vendorIndivPage === "timeline") {
                          setVendorIndivPage("overview");
                          // setActiveTab("openActivity");
                          setTimeout(() => scrollToSection("openActivity"), 0);
                        } else {
                          // setActiveTab("openActivity");
                          scrollToSection("openActivity");
                        }
                      }}
                    >
                      <span>
                        <img
                          src={openActivityIcon}
                          alt="img"
                          height={20}
                          width={20}
                        />
                      </span>
                      Open Activity
                    </li>
                    <li
                      className={`cursorPointer py-2 relatedList_tab rounded-1`}
                      onClick={() => {
                        if (vendorIndivPage === "timeline") {
                          setVendorIndivPage("overview");
                          // setActiveTab("closeActivity");
                          setTimeout(() => scrollToSection("closeActivity"), 0);
                        } else {
                          // setActiveTab("closeActivity");
                          scrollToSection("closeActivity");
                        }
                      }}
                    >
                      <span>
                        <img
                          src={closeActivityIcon}
                          alt="img"
                          height={20}
                          width={20}
                        />
                      </span>
                      Closed Activity
                    </li>
                  </ul>
                </div>
                <div className="bg-white w-75 rounded-2 leadDetail_main_Container">
                  {vendorIndivPage === "overview" ? (
                    <>
                      <div className="py-3 rounded-2 commonContainer_bg d-flex justify-content-between align-items-center">
                        <div className="w-100 d-flex flex-column">
                          {fieldLabels.map((field, i) => {
                            const fieldValue = field.name
                              .replace(/\s+/g, "")
                              .replace(/^(.)/, (match) => match.toLowerCase());
                            return field.isShowed ? (
                              <div className="d-flex w-50 ps-3" key={i}>
                                <div className="w-50 position-relative pe-5">
                                  <div
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      setSearchField("");
                                      setSelectedField(
                                        selectedField === field.name
                                          ? ""
                                          : field.name
                                      );
                                    }}
                                    className={`cursorPointer d-flex justify-content-between w-100 my-1 p-1 opacity-75 ${
                                      selectedField === field.name
                                        ? "outline_border rounded-1"
                                        : ""
                                    }`}
                                  >
                                    <span>{field.name}</span>
                                    <img
                                      src={
                                        selectedField === field.name ? up : down
                                      }
                                      alt="img"
                                      height={20}
                                      width={20}
                                      className="pt-1"
                                    />
                                  </div>
                                  {selectedField === field.name && (
                                    <div
                                      className="position-absolute field_container w-100 pe-5"
                                      onClick={(e) => {
                                        e.stopPropagation();
                                      }}
                                    >
                                      <div className="">
                                        <label
                                          htmlFor="selectField"
                                          className="position-relative w-100"
                                        >
                                          <input
                                            id="selectField"
                                            type="text"
                                            className="rounded-2 p-1 w-100 pe-4"
                                            placeholder="Search"
                                            value={searchField}
                                            onChange={(e) => {
                                              if (
                                                e.target.value.charAt(0) !== " "
                                              ) {
                                                const value =
                                                  e.target.value.replace(
                                                    /[^a-zA-Z ]+|(\s)\s+/g,
                                                    "$1"
                                                  );
                                                setSearchField(value);
                                              }
                                            }}
                                          />
                                          <img
                                            className="position-absolute mt-1 me-2 end-0 pt-1 cursorPointer"
                                            onClick={() => {
                                              setSearchField("");
                                            }}
                                            src={searchIcon}
                                            alt="search"
                                            width={20}
                                          />
                                        </label>

                                        <div className="w-100 rounded-2">
                                          <div className="border border-1 field_container_dropdown rounded-1">
                                            {showSearchFields
                                              .filter((field) =>
                                                field.name
                                                  .toLowerCase()
                                                  .includes(
                                                    searchField
                                                      .trim()
                                                      .toLowerCase()
                                                  )
                                              )
                                              .sort((a, b) =>
                                                a.name.localeCompare(b.name)
                                              )
                                              .map(
                                                (field) =>
                                                  (selectedField ===
                                                    field.name ||
                                                    !field.isShowed) && (
                                                    <div
                                                      key={field.name}
                                                      className="d-flex gap-2 p-2 cursorPointer rounded-2 align-items-center"
                                                      onClick={() => {
                                                        selectedField ===
                                                        field.name
                                                          ? setSelectedField("")
                                                          : handleFieldClick(
                                                              field
                                                            );
                                                      }}
                                                    >
                                                      <span className="right_tick">
                                                        {selectedField ===
                                                        field.name
                                                          ? tick
                                                          : ""}
                                                      </span>
                                                      <span className="ps-2">
                                                        {field.name}
                                                      </span>
                                                    </div>
                                                  )
                                              )}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  )}
                                </div>
                                <div
                                  className={`w-50 mt-1 ms-5 position-relative`}
                                >
                                  {field.type === "input" ? (
                                    <EditableInput
                                      className="ps-3"
                                      inputType={fieldValue}
                                      handleIndividualInputChange={
                                        handleIndividualInputChange
                                      }
                                      editingFields={editingFields}
                                      handleEditClick={handleEditClick}
                                      handleCancelClick={handleCancelClick}
                                      handleUpdateClick={handleUpdateClick}
                                      updatedFields={updatedFields}
                                      accountOverviewDetails={
                                        vendorOverviewDetails
                                      }
                                      showType={showType}
                                      setShowType={setShowType}
                                    />
                                  ) : field.type === "dropdown" ? (
                                    <div
                                      className=""
                                      onClick={() => {
                                        handleEditClick("vendorInfoGlAccount");
                                      }}
                                    >
                                      {editingFields["vendorInfoGlAccount"] &&
                                      showType === "vendorInfoGlAccount" ? (
                                        <Select
                                          value={
                                            updatedFields.vendorInfoGlAccount
                                          }
                                          menuPlacement="auto"
                                          onChange={(selectedOption) =>
                                            handleChangeIndDropDown(
                                              selectedOption,
                                              "vendorInfoGlAccount"
                                            )
                                          }
                                          options={glAccountOptions}
                                          styles={createLeadStyles}
                                          isSearchable={false}
                                        />
                                      ) : (
                                        <input
                                          type="text"
                                          className={`cursorPointer indvEditInput
                            ${
                              showType === "vendorInfoGlAccount"
                                ? "selectedBorder "
                                : "unselectBorder bg-transparent"
                            }`}
                                          value={
                                            vendorOverviewDetails?.glAccount
                                              ?.value
                                          }
                                          name={"vendorInfoGlAccount"}
                                          onClick={(e) => {
                                            if (showType === "") {
                                              updatedFields.vendorInfoGlAccount =
                                                vendorOverviewDetails.glAccount;
                                              setShowType(e.target.name);
                                              setUpdatedFields(updatedFields);
                                            }
                                            handleEditClick(
                                              "vendorInfoGlAccount"
                                            );
                                          }}
                                          // readOnly={showType !== "emailId"}
                                          readOnly={true}
                                        />
                                      )}
                                      {editingFields["vendorInfoGlAccount"] &&
                                        showType === "vendorInfoGlAccount" && (
                                          <div className="d-flex gap-2">
                                            <span
                                              onClick={() => {
                                                setShowType("");
                                                handleUpdateClick(
                                                  "vendorInfoGlAccount"
                                                );
                                              }}
                                              className="ps-2 cursorPointer"
                                            >
                                              <img
                                                src={updateIcon}
                                                alt="img"
                                                height={20}
                                                width={20}
                                              />
                                            </span>
                                            <span
                                              onClick={() => {
                                                setShowType("");
                                                // handleUpdateClick(inputType);
                                                handleCancelClick(
                                                  "vendorInfoGlAccount"
                                                );
                                              }}
                                              className="cursorPointer"
                                            >
                                              <img
                                                src={cancelIcon}
                                                alt="img"
                                                height={20}
                                                width={20}
                                              />
                                            </span>
                                          </div>
                                        )}
                                    </div>
                                  ) : field.type === "readOnly" ? (
                                    <div>
                                      <span className="d-flex flex-column">
                                        <span>
                                          {
                                            vendorOverviewDetails[
                                              field.name === "Created By"
                                                ? "createdBy"
                                                : field.name === "Modified By"
                                                ? "modifiedBy"
                                                : "vendorName"
                                            ]
                                          }
                                        </span>
                                        <span className="small opacity-75">
                                          {
                                            vendorOverviewDetails[
                                              field.name === "Created By"
                                                ? "createdDate"
                                                : field.name === "Modified By"
                                                ? "modifiedDate"
                                                : ""
                                            ]
                                          }
                                        </span>
                                      </span>
                                    </div>
                                  ) : (
                                    <div>
                                      {field.type === "checkbox" ? (
                                        <div className="d-flex editInput_container">
                                          {showType !== "emailOptOut" ? (
                                            <span
                                              className="right_tick cursorPointer"
                                              onClick={() => {
                                                handleEditClick("emailOptOut");
                                                setShowType("emailOptOut");
                                              }}
                                            >
                                              {emailOptOut ? tick : "-"}
                                            </span>
                                          ) : (
                                            <input
                                              type="checkbox"
                                              checked={
                                                updatedFields.emailOptOut
                                              }
                                              onChange={(e) => {
                                                setUpdatedFields(
                                                  (prevFields) => ({
                                                    ...prevFields,
                                                    emailOptOut:
                                                      e.target.checked,
                                                  })
                                                );
                                              }}
                                              onClick={() => {
                                                handleEditClick("emailOptOut");
                                              }}
                                            />
                                          )}
                                          {showType === "emailOptOut" && (
                                            <div className="d-flex gap-2">
                                              <span
                                                onClick={() => {
                                                  setShowType("");
                                                  handleUpdateClick(
                                                    "emailOptOut"
                                                  );
                                                }}
                                                className="ps-2 cursorPointer"
                                              >
                                                <img
                                                  src={updateIcon}
                                                  alt="Update"
                                                  height={20}
                                                  width={20}
                                                />
                                              </span>
                                              <span
                                                onClick={() => {
                                                  setShowType("");
                                                  handleCancelClick(
                                                    "emailOptOut"
                                                  );
                                                }}
                                                className="cursorPointer"
                                              >
                                                <img
                                                  src={cancelIcon}
                                                  alt="Cancel"
                                                  height={20}
                                                  width={20}
                                                />
                                              </span>
                                            </div>
                                          )}
                                        </div>
                                      ) : (
                                        <div className="position-relative">
                                          <div
                                            className="d-flex input-with-clear position-relative"
                                            // onClick={handleOwnerInputClick}
                                            onClick={() =>
                                              setShowModalType("changeOwner")
                                            }
                                          >
                                            <input
                                              type="text"
                                              //   placeholder="Vendor Owner"
                                              className="w-100 ps-3 cursorPointer border-0 indvEditInput"
                                              value={selectedItem.name}
                                              readOnly
                                            />
                                          </div>
                                        </div>
                                      )}
                                    </div>
                                  )}
                                </div>
                              </div>
                            ) : null;
                          })}
                        </div>
                      </div>
                      <div className="lead_Information_mainContainer commonContainer_bg     rounded-2  d-flex flex-column my-2 px-2 py-2">
                        <h5 className="w-100 text-primary px-1 pt-1">
                          Vendor Information
                        </h5>

                        <div className="leadInformation_content_container d-flex justify-content-between ">
                          <div className="leadContact_container d-flex gap-4">
                            <div className="opacity-75 d-flex flex-column gap-2 px-2 py-2 ">
                              <span>Vendor Owner</span>
                              <span>Phone</span>
                              <span>Website</span>

                              <span>Category</span>
                            </div>
                            <div>
                              <div className="d-flex flex-column gap-2 px-2 py-2">
                                {/* <div className="d-flex singleInputContainer">
                                <input
                                  type="text"
                                  className={`cursorPointer indvEditInput
                             ${
                               isInputClicked
                                 ? "selectedBorder "
                                 : "unselectBorder bg-transparent "
                             }`}
                                  value={
                                    editingFields.leadOwnerInfo
                                      ? updatedFields.leadOwnerInfo
                                      : leadInfoDetails.leadOwner
                                  }
                                  onChange={(e) => {
                                    handleIndividualInputChange(
                                      "leadOwner",
                                      e.target.value
                                    );
                                    setIsInputClicked(!isInputClicked);
                                  }}
                                  onClick={() => {
                                    handleEditClick("leadOwner");
                                    setIsInputClicked(!isInputClicked);
                                  }}
                                  readOnly={!editingFields.leadOwner}
                                />
                                {editingFields.leadOwnerInfo &&
                                  isInputClicked && (
                                    <div className="d-flex gap-2">
                                      <span
                                        onClick={() => {
                                          handleUpdateClick("leadOwner");
                                        }}
                                        className="ps-2 cursorPointer"
                                      >
                                        <img
                                          src={updateIcon}
                                          alt="img"
                                          height={20}
                                          width={20}
                                        />
                                      </span>
                                      <span
                                        onClick={() => {
                                          handleUpdateClick("leadOwner");
                                          setIsInputClicked(isInputClicked);
                                        }}
                                        className="cursorPointer"
                                      >
                                        <img
                                          src={cancelIcon}
                                          alt="img"
                                          height={20}
                                          width={20}
                                        />
                                      </span>
                                    </div>
                                  )}
                              </div> */}

                                {/* <span>{leadInfoDetails.email}</span> */}
                                {/* <div className="d-flex editInput_container ">
                                <input
                                  type="text"
                                  autoComplete="off"
                                  name="leadInfoEmail"
                                  className={`cursorPointer indvEditInput
                            ${
                              showType === "leadInfoEmail"
                                ? "selectedBorder "
                                : "unselectBorder bg-transparent "
                            }`}
                                  value={
                                    editingFields.leadInfoEmail
                                      ? updatedFields.leadInfoEmail
                                      : leadOverviewDetails.email
                                  }
                                  onChange={(e) => {
                                    if (showType === "leadInfoEmail") {
                                      handleIndividualInputChange(
                                        "leadInfoEmail",
                                        e.target.value
                                      );
                                    }

                                    //  setShowType(e.target.name);
                                  }}
                                  onClick={(e) => {
                                    if (showType === "") {
                                      // handleEditClick("phoneNumber");
                                      setShowType(e.target.name);
                                    }
                                    handleEditClick("leadInfoEmail");
                                  }}
                                  // readOnly={showType === "phoneNumber"}
                                  readOnly={
                                    !editingFields.leadInfoEmail ||
                                    showType !== "leadInfoEmail"
                                  }
                                />
                                {editingFields.leadInfoEmail &&
                                  showType === "leadInfoEmail" && (
                                    <div className="d-flex   gap-2">
                                      <span
                                        onClick={() => {
                                          handleUpdateClick("leadInfoEmail");
                                        }}
                                        className="ps-2 cursorPointer"
                                      >
                                        <img
                                          src={updateIcon}
                                          alt="img"
                                          height={20}
                                          width={20}
                                        />
                                      </span>

                                      <span
                                        onClick={() => {
                                          setShowType("");
                                          handleUpdateClick("leadInfoEmail");
                                        }}
                                        className="cursorPointer"
                                      >
                                        <img
                                          src={cancelIcon}
                                          alt="img"
                                          height={20}
                                          width={20}
                                        />
                                      </span>
                                    </div>
                                  )}
                              </div> */}
                                {/* <span>{leadInfoDetails.phone}</span>
                              <span>{leadInfoDetails.mobile}</span>
                              <div className="d-flex editInput_container ">
                                <input
                                  type="text"
                                  autoComplete="off"
                                  name="leadSource"
                                  className={`cursorPointer indvEditInput
                            ${
                              showType === "leadSource"
                                ? "selectedBorder "
                                : "unselectBorder bg-transparent "
                            }`}
                                  value={
                                    editingFields.leadSource
                                      ? updatedFields.leadSource
                                      : leadInfoDetails.leadSource
                                  }
                                  onChange={(e) => {
                                    if (showType === "leadSource") {
                                      handleIndividualInputChange(
                                        "leadSource",
                                        e.target.value
                                      );
                                    }

                                    //  setShowType(e.target.name);
                                  }}
                                  onClick={(e) => {
                                    if (showType === "") {
                                      // handleEditClick("phoneNumber");
                                      setShowType(e.target.name);
                                    }
                                    handleEditClick("leadSource");
                                  }}
                                  // readOnly={showType === "phoneNumber"}
                                  readOnly={
                                    !editingFields.leadSource ||
                                    showType !== "leadSource"
                                  }
                                />
                                {editingFields.leadSource &&
                                  showType === "leadSource" && (
                                    <div className="d-flex   gap-2">
                                      <span
                                        onClick={() => {
                                          handleUpdateClick("leadSource");
                                        }}
                                        className="ps-2 cursorPointer"
                                      >
                                        <img
                                          src={updateIcon}
                                          alt="img"
                                          height={20}
                                          width={20}
                                        />
                                      </span>

                                      <span
                                        onClick={() => {
                                          setShowType("");
                                          handleUpdateClick("leadSource");
                                        }}
                                        className="cursorPointer"
                                      >
                                        <img
                                          src={cancelIcon}
                                          alt="img"
                                          height={20}
                                          width={20}
                                        />
                                      </span>
                                    </div>
                                  )}
                              </div> */}
                                {/* <span>{leadInfoDetails.leadSource}</span> */}
                                {/* <span>{leadInfoDetails.industry}</span>
                              <span>{leadInfoDetails.annualRevenue}</span> */}
                                <span>
                                  {/* <EditableInput
                                    inputType="venderOwner"
                                    handleIndividualInputChange={
                                      handleIndividualInputChange
                                    }
                                    editingFields={editingFields}
                                    handleEditClick={handleEditClick}
                                    handleCancelClick={handleCancelClick}
                                    handleUpdateClick={handleUpdateClick}
                                    updatedFields={updatedFields}
                                    accountOverviewDetails={
                                      vendorOverviewDetails
                                    }
                                    showType={showType}
                                    setShowType={setShowType}
                                  /> */}
                                  <div className="position-relative">
                                    <div
                                      className="d-flex input-with-clear position-relative"
                                      onClick={() => {
                                        setShowModalType("changeOwner");
                                      }}
                                    >
                                      <input
                                        type="text"
                                        className="w-100 ps-3 cursorPointer indvEditInput border-0"
                                        value={selectedItem?.name}
                                        readOnly
                                      />
                                    </div>
                                  </div>
                                </span>
                                {/* <span>{vendorOverviewDetails.venderOwner} </span> */}
                                <span>
                                  <EditableInput
                                    inputType="vendorInfoPhone"
                                    handleIndividualInputChange={
                                      handleIndividualInputChange
                                    }
                                    editingFields={editingFields}
                                    handleEditClick={handleEditClick}
                                    handleCancelClick={handleCancelClick}
                                    handleUpdateClick={handleUpdateClick}
                                    updatedFields={updatedFields}
                                    accountOverviewDetails={
                                      vendorOverviewDetails
                                    }
                                    showType={showType}
                                    setShowType={setShowType}
                                  />
                                </span>
                                <span>
                                  {" "}
                                  <EditableInput
                                    inputType="vendorInfoWebsite"
                                    handleIndividualInputChange={
                                      handleIndividualInputChange
                                    }
                                    editingFields={editingFields}
                                    handleEditClick={handleEditClick}
                                    handleCancelClick={handleCancelClick}
                                    handleUpdateClick={handleUpdateClick}
                                    updatedFields={updatedFields}
                                    accountOverviewDetails={
                                      vendorOverviewDetails
                                    }
                                    showType={showType}
                                    setShowType={setShowType}
                                  />
                                </span>
                                <span>
                                  {" "}
                                  <EditableInput
                                    inputType="vendorInfoCategory"
                                    handleIndividualInputChange={
                                      handleIndividualInputChange
                                    }
                                    editingFields={editingFields}
                                    handleEditClick={handleEditClick}
                                    handleCancelClick={handleCancelClick}
                                    handleUpdateClick={handleUpdateClick}
                                    updatedFields={updatedFields}
                                    accountOverviewDetails={
                                      vendorOverviewDetails
                                    }
                                    showType={showType}
                                    setShowType={setShowType}
                                  />
                                </span>
                              </div>
                            </div>
                          </div>

                          <div className="">
                            <div className="leadContact_container d-flex gap-1">
                              <div className="opacity-75 d-flex flex-column gap-2 px-2 py-2">
                                <span>Vendor Name</span>
                                <span>Email</span>

                                <span>GL Account</span>
                                <span>Email Opt Out</span>
                              </div>
                              <div className="d-flex flex-column gap-2 px-2 py-2">
                                <div className="d-flex flex-column gap-2 px-2">
                                  {" "}
                                  <span>
                                    <EditableInput
                                      fieldType="text"
                                      inputType="vendorName"
                                      handleIndividualInputChange={
                                        handleIndividualInputChange
                                      }
                                      editingFields={editingFields}
                                      handleEditClick={handleEditClick}
                                      handleCancelClick={handleCancelClick}
                                      handleUpdateClick={handleUpdateClick}
                                      updatedFields={updatedFields}
                                      accountOverviewDetails={
                                        vendorOverviewDetails
                                      }
                                      showType={showType}
                                      setShowType={setShowType}
                                    />
                                  </span>
                                  <span>
                                    <EditableInput
                                      inputType="vendorInfoEmail"
                                      handleIndividualInputChange={
                                        handleIndividualInputChange
                                      }
                                      editingFields={editingFields}
                                      handleEditClick={handleEditClick}
                                      handleCancelClick={handleCancelClick}
                                      handleUpdateClick={handleUpdateClick}
                                      updatedFields={updatedFields}
                                      accountOverviewDetails={
                                        vendorOverviewDetails
                                      }
                                      showType={showType}
                                      setShowType={setShowType}
                                    />
                                  </span>
                                  {/* <span>
                                    {vendorOverviewDetails?.glAccount?.value}
                                  </span> */}
                                  {editingFields["glAccount"] &&
                                  showType === "glAccount" ? (
                                    <Select
                                      value={updatedFields.glAccount}
                                      menuPlacement="auto"
                                      onChange={(selectedOption) =>
                                        handleChangeIndDropDown(
                                          selectedOption,
                                          "glAccount"
                                        )
                                      }
                                      options={glAccountOptions}
                                      styles={createLeadStyles}
                                      isSearchable={false}
                                    />
                                  ) : (
                                    <input
                                      type="text"
                                      className={`cursorPointer indvEditInput
                            ${
                              showType === "glAccount"
                                ? "selectedBorder "
                                : "unselectBorder bg-transparent"
                            }`}
                                      value={
                                        vendorOverviewDetails?.glAccount?.value
                                      }
                                      name={"glAccount"}
                                      onClick={(e) => {
                                        if (showType === "") {
                                          updatedFields.glAccount =
                                            vendorOverviewDetails.glAccount;
                                          setShowType(e.target.name);
                                          setUpdatedFields(updatedFields);
                                        }
                                        handleEditClick("glAccount");
                                      }}
                                      // readOnly={showType !== "emailId"}
                                      readOnly={true}
                                    />
                                  )}
                                  {editingFields["glAccount"] &&
                                    showType === "glAccount" && (
                                      <div className="d-flex gap-2">
                                        <span
                                          onClick={() => {
                                            setShowType("");
                                            handleUpdateClick("glAccount");
                                          }}
                                          className="ps-2 cursorPointer"
                                        >
                                          <img
                                            src={updateIcon}
                                            alt="img"
                                            height={20}
                                            width={20}
                                          />
                                        </span>
                                        <span
                                          onClick={() => {
                                            setShowType("");
                                            // handleUpdateClick(inputType);
                                            handleCancelClick("glAccount");
                                          }}
                                          className="cursorPointer"
                                        >
                                          <img
                                            src={cancelIcon}
                                            alt="img"
                                            height={20}
                                            width={20}
                                          />
                                        </span>
                                      </div>
                                    )}
                                  <span>
                                    {/* <EditableCheckbox
                                      checked={updatedFields.emailOptOutInfo}
                                      // handleChange={() => {
                                      //   setEmailOptOut(!emailOptOut);
                                      // }}
                                      handleEditClick={handleEditClick}
                                      handleUpdateClick={handleUpdateClick}
                                      showType={showType}
                                      setShowType={setShowType}
                                    /> */}
                                    <EditableCheckbox
                                      // checked={leadOverviewDetails.emailOptOut}
                                      type="emailOptOutInfo"
                                      checked={updatedFields.emailOptOutInfo}
                                      handleChange={(e) => {
                                        // setEmailOptOut(!leadInfoEmailOptOut);
                                        setUpdatedFields((prevDetails) => ({
                                          ...prevDetails,
                                          emailOptOutInfo: e.target.checked,
                                        }));
                                      }}
                                      handleEditClick={handleEditClick}
                                      handleUpdateClick={handleUpdateClick}
                                      showType={showType}
                                      setShowType={setShowType}
                                    />
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <h5 className="w-100 text-primary px-1 pt-1">
                          Address Information
                        </h5>

                        <div className="leadInformation_content_container d-flex justify-content-between ">
                          <div className="leadContact_container d-flex gap-4">
                            <div className="opacity-75 d-flex flex-column gap-2 px-2 py-2">
                              <span>Street</span>
                              <span>City</span>
                              <span>ZipCode</span>
                            </div>
                            <div className="d-flex flex-column gap-2 px-2 py-2">
                              <span>
                                <EditableInput
                                  inputType="vendorInfoStreet"
                                  handleIndividualInputChange={
                                    handleIndividualInputChange
                                  }
                                  editingFields={editingFields}
                                  handleEditClick={handleEditClick}
                                  handleCancelClick={handleCancelClick}
                                  handleUpdateClick={handleUpdateClick}
                                  updatedFields={updatedFields}
                                  accountOverviewDetails={vendorOverviewDetails}
                                  showType={showType}
                                  setShowType={setShowType}
                                />
                              </span>

                              <span>
                                <EditableInput
                                  inputType="vendorInfoCity"
                                  handleIndividualInputChange={
                                    handleIndividualInputChange
                                  }
                                  editingFields={editingFields}
                                  handleEditClick={handleEditClick}
                                  handleCancelClick={handleCancelClick}
                                  handleUpdateClick={handleUpdateClick}
                                  updatedFields={updatedFields}
                                  accountOverviewDetails={vendorOverviewDetails}
                                  showType={showType}
                                  setShowType={setShowType}
                                />
                              </span>

                              <span>
                                <EditableInput
                                  inputType="vendorInfoZipCode"
                                  handleIndividualInputChange={
                                    handleIndividualInputChange
                                  }
                                  editingFields={editingFields}
                                  handleEditClick={handleEditClick}
                                  handleCancelClick={handleCancelClick}
                                  handleUpdateClick={handleUpdateClick}
                                  updatedFields={updatedFields}
                                  accountOverviewDetails={vendorOverviewDetails}
                                  showType={showType}
                                  setShowType={setShowType}
                                />
                              </span>
                            </div>
                          </div>
                          <div className="">
                            <div className="leadContact_container d-flex gap-4">
                              <div className="opacity-75 d-flex flex-column gap-2 px-2 py-2">
                                <span>State</span>
                                <span>Country</span>
                              </div>
                              <div className="d-flex flex-column gap-2 px-2 py-2">
                                <span>
                                  <EditableInput
                                    inputType="vendorInfoState"
                                    handleIndividualInputChange={
                                      handleIndividualInputChange
                                    }
                                    editingFields={editingFields}
                                    handleEditClick={handleEditClick}
                                    handleCancelClick={handleCancelClick}
                                    handleUpdateClick={handleUpdateClick}
                                    updatedFields={updatedFields}
                                    accountOverviewDetails={
                                      vendorOverviewDetails
                                    }
                                    showType={showType}
                                    setShowType={setShowType}
                                  />
                                </span>
                                <span>
                                  <EditableInput
                                    inputType="vendorInfoCountry"
                                    handleIndividualInputChange={
                                      handleIndividualInputChange
                                    }
                                    editingFields={editingFields}
                                    handleEditClick={handleEditClick}
                                    handleCancelClick={handleCancelClick}
                                    handleUpdateClick={handleUpdateClick}
                                    updatedFields={updatedFields}
                                    accountOverviewDetails={
                                      vendorOverviewDetails
                                    }
                                    showType={showType}
                                    setShowType={setShowType}
                                  />
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <h5 className="w-100 text-primary px-1 pt-1">
                          Description
                        </h5>
                        <div className="d-flex gap-2 px-1 pt-1">
                          <span className="opacity-75 px-1">Description</span>

                          <EditableInput
                            fieldType="text"
                            inputType="vendorDescription"
                            handleIndividualInputChange={
                              handleIndividualInputChange
                            }
                            editingFields={editingFields}
                            handleEditClick={handleEditClick}
                            handleCancelClick={handleCancelClick}
                            handleUpdateClick={handleUpdateClick}
                            updatedFields={updatedFields}
                            accountOverviewDetails={vendorOverviewDetails}
                            showType={showType}
                            setShowType={setShowType}
                            isTextarea={true}
                          />
                        </div>
                      </div>
                      {notePermission === "TRUE" && (
                        <div
                          id="notesSection"
                          className="lead_Information_mainContainer commonContainer_bg     rounded-2  d-flex flex-column my-2 py-2"
                        >
                          <Note
                            setAddTitle={setAddTitle}
                            addTitle={addTitle}
                            note={note}
                            noteTitle={noteTitle}
                            setNote={setNote}
                            showButtons={showButtons}
                            setShowButtons={setShowButtons}
                            textFile={selectedNoteFiles}
                            showTitleNote={showTitleNote}
                            setShowTitleNote={setShowTitleNote}
                            handleTextAreaClick={handleTextAreaClick}
                            handleTextFileChange={handleTextFileChange}
                            handleSaveButton={insertNote}
                            handleAddTitleClick={handleAddTitleClick}
                            setNoteTitle={setNoteTitle}
                            noteInfo={noteInfo}
                            selectRecent={selectRecent}
                            handleSelectRecent={handleSelectRecent}
                            handleChangeNoteTitle={handleChangeNoteTitle}
                            handleChangeNoteDescription={
                              handleChangeNoteDescription
                            }
                            setUpdateNotes={setUpdateNotes}
                            updateNotes={updateNotes}
                            setEditNote={setEditNote}
                            editNote={editNote}
                            handleUpdateClick={updateNote}
                            handleDeleteClick={deleteNoteDetails}
                            leadId={vendorId}
                            selectedNoteFiles={selectedNoteFiles}
                            setSelectedNoteFiles={setSelectedNoteFiles}
                            onChildDataChange={handleChildDataChange}
                            moduleType={"Vendors"}
                            noteDeleteConfirmation={noteDeleteConfirmation}
                            setNoteDeleteConfirmation={
                              setNoteDeleteConfirmation
                            }
                          />

                          {showTitleNote && (
                            <textarea
                              rows={2}
                              className="notesClass px-2"
                              placeholder="Add note Title"
                              value={noteTitle}
                              onChange={(e) => setNoteTitle(e.target.value)}
                              style={{ width: "95%", marginTop: "10px" }}
                            />
                          )}
                        </div>
                      )}

                      <div
                        id="product"
                        className="lead_Information_mainContainer commonContainer_bg rounded-2  d-flex flex-column py-2 mt-4"
                      >
                        <Product
                          module={"Vendors"}
                          productsList={productsList || []}
                          assignContactID={vendorId}
                          handleAssignModule={handleAssignModule}
                          selectedProductId={selectedProductId}
                          setSelectedProductId={setSelectedProductId}
                          handleAssignProductModule={() =>
                            handleAssignModule(selectedProductId, "PRODUCTS")
                          }
                          handledeleteModule={(productId) =>
                            handledeleteModule(productId)
                          }
                          apiLoader={apiLoader}
                        />
                      </div>
                      <div
                        id="purchaseOrder"
                        // className="lead_Information_mainContainer commonContainer_bg rounded-2  d-flex flex-column py-2 mt-4"
                      >
                        <PurchaseCommonTable
                          title="Purchase Orders"
                          buttonLabel="New"
                          buttonAction={() => {
                            setCurrentView("purchaseOrders");
                            setInnerPage("create");
                            setPastViewInfo((prev) => ({
                              ...prev,
                              view: currentView,
                              innerPage: innerPage,
                              undo: null,
                            }));
                          }}
                          showHeadings={showHeadingsPurchases}
                          setShowHeadings={setShowHeadingsPurchases}
                          selectedHeadings={selectedHeadingsPurchases}
                          data={assignedPurchase}
                          onRowClick={() => {}}
                          manageColumnsAction={() =>
                            setShowModalType("Purchase Order Columns")
                          }
                          resetColumnSizeAction={() => {}}
                          // data={purchaseOrderList}
                          setShowSelectedFilter={setShowSelectedFilter}
                          selectedPurchaseId={selectedPurchaseId}
                          setSearchColumn={setSearchColumn}
                          setSelectedPurchaseId={setSelectedPurchaseId}
                        />
                      </div>

                      <div id="contacts">
                        <ContactCommonTable
                          title="Contact"
                          buttonLabel="New"
                          buttonAction={() => {
                            setCurrentView("contacts");
                            setInnerPage("create");
                            setPastViewInfo((prev) => ({
                              ...prev,
                              view: currentView,
                              innerPage: innerPage,
                              undo: null,
                            }));
                          }}
                          showHeadings={showHeadingsContact}
                          setShowHeadings={setShowHeadingsContact}
                          selectedHeadings={selectedHeadingsContact}
                          data={contactsList}
                          onRowClick={() => {}}
                          manageColumnsAction={() => {
                            setShowModalType("Manage Contact Columns");
                            setSearchColumn("");
                            setSavingHeadings(contactColumn);
                          }}
                          resetColumnSizeAction={() => {}}
                          setShowSelectedFilter={setShowSelectedFilter}
                          setSearchColumn={setSearchColumn}
                          setSavingHeadings={setSavingHeadings}
                        />
                      </div>

                      <div
                        id="openActivity"
                        className="lead_Information_mainContainer commonContainer_bg rounded-2  d-flex flex-column py-2 mt-4"
                      >
                        <OpenActivity
                          openActivities={openActivities}
                          // getOverview={() => accountOverviewDetail(accountId)}
                          openActivitiesCalls={openActivitiesCalls}
                          openActivitiesMeetings={openActivitiesMeetings}
                          moduleName="Invoices"
                        />
                      </div>

                      <div
                        id="closeActivity"
                        className="lead_Information_mainContainer commonContainer_bg rounded-2  d-flex flex-column py-2 mt-4"
                      >
                        <CloseActivity
                          closeActivities={closeActivities}
                          // getOverview={() => accountOverviewDetail(accountId)}
                          closeActivitiesCalls={closedActivitiesCall}
                          closeActivitiesMeetings={closedActivitiesMeetings}
                        />
                      </div>
                    </>
                  ) : (
                    <>
                      <div
                        className="timeline_main_container d-flex flex-column gap-4 w-75"
                        style={{ minWidth: "-webkit-fill-available" }}
                      >
                        {/* <div className="upcomingAction_container w-100 flex-column  py-2  d-flex  commonContainer_bg">
                          <div className="header d-flex gap-2 ps-2">
                            <span>
                              <img
                                src={hierarchy}
                                alt="img"
                                height={20}
                                width={20}
                              />
                            </span>
                            <span>Upcoming Actions</span>
                          </div>
                          <div className="horizontal_line w-100 opacity-25 mx-1"></div>

                          <div
                            className="d-flex justify-content-center align-items-center "
                            style={{ height: "25vh" }}
                          >
                            <h4 className="opacity-75 ">No upcoming actions</h4>
                          </div>
                        </div> */}

                        <div className="w-100 historyMain_Container flex-column   d-flex  commonContainer_bg ">
                          <div className="header  d-flex gap-2 px-2 py-2 align-items-center">
                            <span>
                              <img
                                src={history}
                                alt="img"
                                height={20}
                                width={20}
                              />
                            </span>
                            <span>History</span>
                          </div>
                          <div className="horizontal_line w-100 mx-1"></div>
                          <div className="historyContent_mainContainer ">
                            {timeLineDataList &&
                            Object.keys(timeLineDataList).length > 0 ? (
                              Object.keys(timeLineDataList).map((date) => (
                                <div
                                  className="history_container pt-3 d-flex flex-column"
                                  key={date}
                                >
                                  <span className="px-2 timelineBg_color border border-primary rounded-5 py-2 mx-5">
                                    {date}
                                  </span>
                                  {timeLineDataList[date].map((item, index) => (
                                    <div className="d-flex gap-2" key={index}>
                                      <span className="opacity-75 pt-5 ps-2">
                                        {item.createdTime}
                                      </span>
                                      <div className="d-flex flex-column align-items-center">
                                        <p className="vertical_line m-0 p-0"></p>
                                        <span>
                                          <img
                                            src={groupIcon2}
                                            alt="img"
                                            height={25}
                                            width={25}
                                          />
                                        </span>
                                      </div>
                                      <span className="pt-5">
                                        {`${item.tmlDescription} ${item.tmlCreatedBy} ${date}`}
                                      </span>
                                    </div>
                                  ))}
                                </div>
                              ))
                            ) : (
                              <div className="text-center d-flex justify-content-center align-items-center h-50 mt-2">
                                <h4>No Data available</h4>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </>
            </div>
          </div>
        </div>

        <VendorShowImageModal
          showImageModal={showImageModal}
          setShowImageModal={setShowImageModal}
          setUploadImg={setUploadImg}
          setErrorMessage={setImageMsg}
          vendorInfoDetails={vendorInfoDetails}
          setShowRemoveModal={setShowRemoveModal}
          uploadImg={uploadImg}
          imageUrl={imageUrl}
          downloadIcon={downloadIcon}
          vendorAvatar={vendorAvatar}
          errorMessage={imageMsg}
          uploadImage={uploadImage}
          isImageUploaded={isImageUploaded}
          handleUploadImage={handleUploadImage}
          imageLoader={imageLoader}
        />

        <VendorRemoveModal
          showRemoveModal={showRemoveModal}
          setShowRemoveModal={setShowRemoveModal}
          setShowImageModal={setShowImageModal}
          removeImage={removeImage}
          imageLoader={imageLoader}
        />

        <ShareModal
          moduleType="VENDORS"
          setShareModal={setShareModal}
          shareModal={shareModal}
          id={vendorId}
          shareInfoModal={shareInfoModal}
          setShareInfoModal={setShareInfoModal}
          shareUserList={shareUserList}
          shareGroupList={shareGroupList}
          shareRoleList={shareRoleList}
          shareMemberIds={shareMemberIds}
          sharePublicList={sharePublicList}
          modalHeading={"Sharing Vendor"}
          getOverview={vendorOverviewDetail}
          module={"Vendors"}
        />

        <VendorDeleteModal
          deleteModal={deleteModal}
          setDeleteModal={setDeleteModal}
          vendorInfoDetails={vendorInfoDetails}
          deleteVendor={deleteVendor}
          apiLoader={apiLoader}
        />

        <VendorManageContactColumnModal
          modalType={modalType}
          searchColumn={searchColumn}
          setSearchColumn={setSearchColumn}
          setSavingHeadings={setSavingHeadings}
          savingHeadingsContact={savingHeadingsContact}
          handleColumnCheckboxChangeContact={handleColumnCheckboxChangeContact}
          setShowModalType={setShowModalType}
          setSelectedHeadingsContact={setSelectedHeadingsContact}
          contactColumn={contactColumn}
        />

        <PurchaseOrderVendorModal
          modalType={modalType}
          searchColumn={searchColumn}
          setSearchColumn={setSearchColumn}
          setSavingHeadings={setSavingHeadings}
          purchaseColumns={purchaseColumns}
          savingHeadingsPurchase={savingHeadingsPurchase}
          handleColumnCheckboxChangePurchase={
            handleColumnCheckboxChangePurchase
          }
          setShowModalType={setShowModalType}
          setSelectedHeadingsPurchases={setSelectedHeadingsPurchases}
        />
      </>
    );
  };

  const handleVendorView = () => {
    if (innerPage === "list") {
      return <>{vendorHomePage()}</>;
    } else if (["create", "clone"].includes(innerPage)) {
      return <>{createVendorPage()}</>;
    } else if (innerPage === "individualVendorPage") {
      return <>{individualVendorPage()}</>;
    }
  };
  return (
    <div
      onClick={() => {
        setListVisible(false);
        setShowHeadings(false);
        setShowSelectedFilter("");
        setSelectedField("");
        setShowOptionsVendor(false);
      }}
    >
      {handleVendorView()}
      <Modal
        show={modalType === "Manage Columns"}
        id="ConvertModal"
        aria-labelledby="example-custom-modal-styling-title"
        className="withdrw_modal"
      >
        <Modal.Body>
          <div className="modalClass  position-relative">
            <div className="d-flex justify-content-between ">
              <h4 className="">Manage Columns</h4>
              {/* <img
                src={crossIcon}
                alt="cross"
                style={{ cursor: "pointer" }}
                className="ms-1"
                onClick={() => {
                  setShowModalType("");
                }}
              /> */}
            </div>
            <div className="w-100 position-relative">
              <div className="mb-1 pt-2 position-relative w-100">
                <input
                  type="text"
                  className="assetSearchbar searchbar rounded-2 p-2 pe-5 common_input_bg w-100 "
                  placeholder="Search"
                  aria-label="Username"
                  aria-describedby="basic-addon1"
                  value={searchColumn}
                  onChange={(e) => {
                    if (e.target.value.charAt(0) !== " ") {
                      const value = e.target.value.replace(
                        /[^a-zA-Z ]+|(\s)\s+/g,
                        "$1"
                      );
                      setSearchColumn(value);
                    }
                  }}
                />
                <span
                  className="position-absolute  mt-2 me-3 end-0 opacity-75 cursorPointer"
                  onClick={() => {
                    setSearchColumn("");
                    setSavingHeadings(vendorsColumn);
                  }}
                >
                  {searchColumn === "" ? (
                    <img src={searchIcon} alt="" width={20} />
                  ) : (
                    <div className="cursorPointer">X</div>
                  )}
                </span>
              </div>
              <div className=" opacity-75 columns_container">
                {savingHeadings
                  .filter((vendor) =>
                    vendor.name
                      .toLowerCase()
                      .includes(searchColumn.trim().toLowerCase())
                  )
                  .map((column) => (
                    <div
                      key={column.name}
                      className="d-flex gap-2 py-2 rounded-2 align-items-center"
                    >
                      <div className="d-flex gap-2 ps-2">
                        <input
                          type="checkbox"
                          id={column.name}
                          name={column.name}
                          checked={column.isChecked}
                          onChange={(e) => {
                            handleColumnCheckboxChange(
                              column.name,
                              e.target.checked
                            );
                          }}
                          className="cursorPointer"
                        />
                        <label
                          className="small cursorPointer"
                          htmlFor={column.name}
                        >
                          {column.name}
                        </label>
                      </div>
                    </div>
                  ))}
              </div>
              <aside className="float-end mt-2">
                <div className="d-flex align-items-center gap-4">
                  <button
                    className="cancel_btn bg-light"
                    onClick={() => {
                      setShowModalType("");
                      setSearchColumn("");
                      setSavingHeadings(vendorsColumn);
                    }}
                  >
                    Cancel
                  </button>
                  <button
                    className="save_btn"
                    onClick={() => {
                      setSelectedHeadings(savingHeadings);
                      setTimeout(() => {
                        setShowModalType("");
                      }, 200);
                    }}
                  >
                    Save
                  </button>
                </div>
              </aside>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <ChangeOwnerModal
        modalType={modalType}
        setShowModalType={setShowModalType}
        selectedOwnerItem={selectedOwnerItem}
        handleChangeOwnerInputClick={handleChangeOwnerInputClick}
        setSelectedOwnerItem={setSelectedOwnerItem}
        isLeadOwnerModal={isLeadOwnerModal}
        setIsLeadOwnerModal={setIsLeadOwnerModal}
        isChangeOwnerList={isChangeOwnerList}
        userList={userList}
        handleChangeOwnerItemClick={handleChangeOwnerItemClick}
        emailNotification={emailNotification}
        setEmailNotification={setEmailNotification}
        handleEditClick={handleEditClick}
        handleUpdateClick={handleUpdateClick}
        setUpdatedFields={setUpdatedFields}
        existingContact={existingVendor}
        handleUpdateContact={handleUpdateVendor}
        onSelectedChangeOwner={handleSelectedLeadChange}
        apiLoader={apiLoader} // for lottie loader
      />

      <AccessModal
        setShowAccessModal={setShowAccessModal}
        showAccessModal={showAccessModal}
      />

      <LeadOwnerModal
        isLeadOwnerModal={isLeadOwnerModal}
        setIsLeadOwnerModal={setIsLeadOwnerModal}
        onSelectedLeadChange={handleSelectedLeadChange}
        defaultUser={selectedItem}
      />

      <NotificationContainer />
    </div>
  );
};

import React, { useEffect, useState } from "react";
import Highcharts from "highcharts";
import addFunnelModule from "highcharts/modules/funnel";
import {
  HighchartsChart,
  HighchartsProvider,
  FunnelSeries,
} from "react-jsx-highcharts";

const FunnelChart = ({
  component,
  deleteIcon,
  setModalType,
  setSelectedComponentId,
  currentView,
  data,
}) => {
  const [finalData, setFinalData] = useState([]);

  // Apply Funnel Module
  addFunnelModule(Highcharts);

  const plotOptions = {
    series: {
      dataLabels: {
        enabled: true,
        format: "<b>{point.name}</b> ({point.y:,.0f})",
        softConnector: true,
      },
      colorByPoint: true, // Ensure each point gets a different color
      colors: [
        "#D74D52",
        "#008DFB",
        "#29BFA2",
        "#ECC11F",
        "#F15D8E",
        "#EE895D",
        "#68C018",
        "#68d018",
      ],
      // cursor: "pointer",
      center: ["34%", "50%"],
      neckWidth: "20%",
      neckHeight: "26%",
      width: "70%",
    },
    labels: {
      colors: [
        "#D74D52",
        "#008DFB",
        "#29BFA2",
        "#ECC11F",
        "#F15D8E",
        "#EE895D",
        "#68C018",
        "#68d018",
      ],
    },
  };

  useEffect(() => {
    const getData = data === undefined ? component.componentData : data;

    const values = getData

      ?.map((ele) => {
        if (ele.recordsCount !== null) {
          return [ele.stage, parseFloat(ele.recordsCount)];
          // return [
          //   currentView === "home" ? ele[headings[1]] : ele[headings[0]],
          //   currentView === "home"
          //     ? ele[headings[1]]
          //     : parseFloat(ele[headings[0]]),
          //   ,
          // ];
        }
      })
      .filter((ele) => ele !== undefined);

    setFinalData(values || []);
  }, [component]);

  return (
    <div
      className={` rounded-1 position-relative ${
        currentView === "home"
          ? "mx-2 mt-3 component_container"
          : "bg-transparent h-100"
      }`}
    >
      {currentView === "home" ? (
        <>
          <div
            className={`component_header d-flex justify-content-between p-2`}
          >
            <span className="first_letter_uppercase fs-5">
              {component.componentName}
            </span>
          </div>
          <div className="component_content p-2 funnel_chart">
            <HighchartsProvider Highcharts={Highcharts}>
              <HighchartsChart plotOptions={plotOptions}>
                <FunnelSeries name="Unique users" data={finalData} />
              </HighchartsChart>
            </HighchartsProvider>
          </div>
          <div className="component_footer p-2 position-absolute end-0 bottom-0 mb-1">
            <span className="">
              <span
                className="deleteIcon rounded-1 p-1 cursorPointer"
                onClick={() => {
                  // setSelectedComponentId("");
                  setModalType("delete");
                  setSelectedComponentId(component.componentId);
                }}
              >
                {deleteIcon}
              </span>
            </span>
          </div>
        </>
      ) : (
        <div className="p-2 h-100 w-100 funnel_chart_analytics">
          <HighchartsProvider Highcharts={Highcharts}>
            <HighchartsChart plotOptions={plotOptions}>
              <FunnelSeries name="Unique users" data={finalData} />
            </HighchartsChart>
          </HighchartsProvider>
        </div>
      )}
    </div>
  );
};

export default FunnelChart;

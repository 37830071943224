/* eslint-disable */
import React, { useState, useEffect } from "react";
import filterIcon from "../../Assets/Frame (3).svg";
import Select from "react-select";
import groupIcon from "../../Assets/De-Active/Quotes.svg";
import { URI } from "../../constants";
import axios from "axios";
import Dropdown from "react-bootstrap/Dropdown";
import down from "../../Assets/Down31.svg";
import up from "../../Assets/Up.svg";
import userSearch from "../../Assets/user-search-01.svg";
import LeadOwnerModal from "../../common/LeadOwnerModal";
import "./quotes.css";
import noteIcon from "../../Assets/notebook-01.svg";
import openActivityIcon from "../../Assets/openActivity.svg";
import closeActivityIcon from "../../Assets/closeActivity.svg";
import inviteIcon from "../../Assets/invite.svg";
import updateIcon from "../../Assets/Group 71.svg";
import cancelIcon from "../../Assets/Group 72.svg";
import Pagination from "../../common/Pagination";
// import hierachy from "../../Assets/hierarchy.svg";
import groupIcon2 from "../../Assets/Group 74.svg";
import history from "../../Assets/history.svg";
import moment from "moment";
import Close from "../../Assets/Clone.svg";
import Share from "../../Assets/ShareIcon.svg";
import Delete from "../../Assets/DeleteIconNew.svg";
import quoteNodata from "../../Assets/Finance-rafiki (1) 1.svg";
import dealsIcon from "../../Assets/De-Active/Deals.svg";
import ContactIcon from "../../Assets/De-Active/Contact.svg";
import accountsIcon from "../../Assets/De-Active/Account.svg";

import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import {
  massOptions,
  pageOption,
  salesColumns,
  taxOptions,
} from "../../common/CommonData";
import tableFilterIcon from "../../Assets/filterIcon.svg";
import arrowUp from "../../Assets/square_arrow_up.svg";
import arrowDown from "../../Assets/square_arrow_down.svg";
import pin from "../../Assets/Pin_column.svg";
import unPin from "../../Assets/Unpin.svg";
import cancel from "../../Assets/cancel_circle.svg";
import filterLine from "../../Assets/filterLine.svg";
import filterMenu from "../../Assets/filterMenu.svg";
import searchIcon from "../../Assets/search.svg";
import logoConvert from "../../Assets/logoConvert.svg";
import SearchIcon from "../../Assets/newSearch.svg";
import { Modal } from "react-bootstrap";
import EditableInput from "../../common/EditableInput";
import CloseActivity from "../../common/commonRelatedList/CloseActivity";
import OpenActivity from "../../common/commonRelatedList/OpenActivity";
import SalesCommonTable from "../../common/SalesCommonTable";
import Note from "../../common/commonRelatedList/Note";
import ShareModal from "../../common/ShareModal";
import { useSelector } from "react-redux";
import ProductListOverviewModal from "../../common/ProductListOverviewModal";
import Loader from "../../common/commonRelatedList/Loader";
import AccessModal from "../../common/AccessModal";
import ChangeOwnerModal from "../../common/commonRelatedList/ChangeOwnerModal";
import {
  convertDateFormat,
  convertToDisplayFormat,
  validateCallSubject,
  validateCityIndia,
  validateCountryName,
  validateStateIndia,
  validateTeamName,
  validateZipCode,
  removeExtraSpaces,
  customCommonStyles,
} from "../../common/CommonComponent";

import {
  leftArrow,
  rightAngleArrow,
  leftAngleArrow,
  editIcon,
  tick,
  expandArrow,
  shrinkArrow,
} from "../../common/commonSVGs/commonSVGIcons.js";
import { EXPLORER_URL } from "../../constants/DataConst.js";
import useScrollToUndo from "../../hooks/useScrollToUndo.js.js";
import Lottie from "react-lottie";
import { defaultOptions } from "../../utils/defaultOptions.js";
import getHeaders from "../../common/ApiHeader.js";
import { handleApiError } from "../../utils/ApiUtils";
import { useNavigate } from "react-router-dom";

function Quotes({
  setCurrentView,
  setInnerPage,
  innerPage,
  currentView,
  permissionList,
  notePermission,
  fetchContactInfo,
  fetchAccInfo,
  fetchQuoteInfo,
  setFetchQuoteInfo,
  setPastViewInfo,
  pastViewInfo,
  fetchDealInfo,
  storeModuleId,
}) {
  const navigate = useNavigate();
  const reduxStore = useSelector((state) => state.reduxState);
  const { loginUserId } = reduxStore;
  // const [filterView, setFilterView] = useState(false); setFilterView is not used anywhere so i commenting this
  const userList = JSON.parse(sessionStorage.getItem("userList"));
  // const productList = JSON.parse(sessionStorage.getItem("productsList"));

  const [allQuoteDetails, setAllQuoteDetails] = useState([]);
  const [selectedItem, setSelectedItem] = useState(null);
  // const [selectedProductItem, setSelectedProductItem] = useState();  selectedProductItem is not used anywhere so i am commenting this
  const [listVisible, setListVisible] = useState(false);
  const [isLeadOwnerModal, setIsLeadOwnerModal] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState(0); // Total number of items
  const [totalItemsAccount, setTotalItemsAccount] = useState(0);
  const [totalItemsContact, setTotalItemsContact] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [showType, setShowType] = useState("");
  const [quotePageView, setquotePageView] = useState("overview");
  const [quoteId, setQuoteId] = useState("");
  const [existingQuote, setExistingQuote] = useState(false);
  const [timeLineDataList, setTimeLineDataList] = useState([]);
  const [existingClone, setExistingClone] = useState(false);
  const options = reduxStore.filterOptions.quote;

  const [selectedOption, setSelectedOption] = useState(options[0]);
  // const [dropdownTwo, setDropdownTwo] = useState(massOptions[0]); dropdownTwo is not used anywhere so i am commenting this
  const [selectedHeadingsSales, setSelectedHeadingsSales] =
    useState(salesColumns);
  const [selectedSalesId, setSelectedSalesId] = useState([]);
  const [selectedTax, setSelectedTax] = useState(taxOptions[0]);
  const [isShrinked, setIsShrinked] = useState(false);

  const [isLoading, setIsLoading] = useState(true);
  const [showHeadingsSales, setShowHeadingsSales] = useState(false);
  const [savingHeadingsSales, setSavingHeadingsSales] = useState(salesColumns);
  const [productList, setProductList] = useState([]);
  const [showAccessModal, setShowAccessModal] = useState(false);
  const [selectedOwnerItem, setSelectedOwnerItem] = useState(userList);
  const [isChangeOwnerList, setIsChangeOwnerList] = useState(false);
  const [isOwnerSelect, setIsOwnerSelect] = useState(false);
  const [emailNotification, setEmailNotification] = useState(true);
  const [isFirstCharacterEntered, setIsFirstCharacterEntered] = useState(false);
  const [shareMemberIds, setShareMemberIds] = useState([]);
  const [currentPageAccount, setCurrentPageAccount] = useState(1);
  const [currentPageContact, setCurrentPageContact] = useState(1);
  const [perPageAccount, setPerPageAccount] = useState(10);
  const [perPageContact, setPerPageContact] = useState(10);
  const [currentPageDeal, setCurrentPageDeal] = useState(1);
  const [totalItemsDeal, setTotalItemsDeal] = useState(0);
  const [perPageDeal, setPerPageDeal] = useState(10);
  const [noteDeleteConfirmation, setNoteDeleteConfirmation] = useState(false);
  const minDate = moment("2000-01-01").format("YYYY-MM-DD");
  const maxDate = moment("2050-12-31").format("YYYY-MM-DD");

  const totalPagesDeal = Math.ceil(Number(totalItemsDeal) / perPageDeal);

  const handlepageChangeDeal = (page) => {
    setCurrentPageDeal(page);
  };

  const handlePageDropDownChangeDeal = (selectedOption) => {
    setCurrentPageDeal(1);
    setPerPageDeal(parseInt(selectedOption.value));
  };

  useEffect(() => {
    getAllDealsDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPageDeal, perPageDeal]);

  const handlePageDropDowmChange = (selectedOption) => {
    setCurrentPage(1);
    setPerPage(parseInt(selectedOption.value));
  };

  const handlePageDropDownChangeContact = (selectedOption) => {
    setCurrentPageContact(1);
    setPerPageContact(parseInt(selectedOption.value));
  };

  const hasPermission = (action) => {
    const permission = permissionList.quotes.find((p) => p.action === action);
    return permission ? permission.status === "TRUE" : false;
  };

  const handleCreateQuote = () => {
    if (hasPermission("CREATE")) {
      let discountedAmount = 0;
      let taxAmount = 0;
      let total = 0;
      if (productList.length > 0) {
        const { unitPrice, tax, productId, productName, description } =
          productList[0];
        discountedAmount = parseFloat(unitPrice) * parseFloat(unitPrice) - 0;
        taxAmount = (parseFloat(tax || 0) / 100) * discountedAmount;
        total = (discountedAmount + taxAmount).toFixed(2) || 0;

        setAllProducts([
          {
            description: description,
            productId: productId,
            productName: productName || "-",
            quantity: unitPrice || 1,
            listPrice: unitPrice || 0,
            amount: unitPrice * unitPrice,
            discount: 0,
            tax: parseFloat(tax) || 0,
            total: isNaN(total) ? 0 : total,
            discountType: 1,
            discountValue: 0,
          },
        ]);
      }

      setInnerPage("create");
      setExistingQuote(false);
      setExistingClone(false);
      setUpdateButtonLabel("Save");
      setSelectedCheckboxes([]);
      setQuoteInfo((prevInfo) => ({
        ...prevInfo,
        subTotal: isNaN(total) ? 0 : total,
        grandTotal: isNaN(total) ? 0 : total,
      }));
    } else {
      setShowAccessModal(true);
    }
  };

  const handleKeyDown = (e) => {
    // Prevent typing space as the first character
    if (!isFirstCharacterEntered && e.key === " ") {
      e.preventDefault();
    }
  };

  const handleEditQuote = () => {
    if (hasPermission("EDIT")) {
      setExistingQuote(true);
      setInnerPage("create");
      // const formattedValidUntil = moment(quoteAccContactDealData.validUntil).format("YYYY-MM-DD");

      const quoteIndivInfoDetails = {
        ...quoteAccContactDealData,
        ...salesOrderList,
        ...openActivities,
        ...quoteItemTotalDetails,
        ...quotesInfoOverview,
        ...closeActivities,
        // validUntil: formattedValidUntil
      };

      setQuoteUpdateInfo(quoteIndivInfoDetails);
      setBillingAdd({
        billingStreet: quoteIndivInfoDetails.billingStreet || "",
        billingCity: quoteIndivInfoDetails.billingCity || "",
        billingState: quoteIndivInfoDetails.billingState || "",
        billingCode: quoteIndivInfoDetails.billingCode || "",
        billingCountry: quoteIndivInfoDetails.billingCountry || "",
      });

      setShippingAdd({
        shippingStreet: quoteIndivInfoDetails.shippingStreet || "",
        shippingCity: quoteIndivInfoDetails.shippingCity || "",
        shippingState: quoteIndivInfoDetails.shippingState || "",
        shippingCode: quoteIndivInfoDetails.shippingCode || "",
        shippingCountry: quoteIndivInfoDetails.shippingCountry || "",
      });

      setUpdateButtonLabel("Update");
    } else {
      setShowAccessModal(true);
    }
  };

  const handleDeleteQuote = () => {
    if (hasPermission("DELETE")) {
      setShowModalType("delete");
    } else {
      setShowAccessModal(true);
    }
  };

  const handlePageDropDownChangeAccount = (selectedOption) => {
    setCurrentPageAccount(1);
    setPerPageAccount(parseInt(selectedOption.value));
  };

  const getAllProduct = async () => {
    const selectedProduct = {
      productName: "",
      productId: "",
    };
    const requestBody = {
      loggedInUserId: loginUserId,
      pageNo: 1,
      pageSize: 20,
    };
    const headersResult = getHeaders(true);

    // Check if headers result indicates an error
    if (headersResult.error) {
      navigate("../"); // Redirect to login if token is missing
      return;
    }
    await axios
      .post(URI.getAllProduct, requestBody, {
        headers: headersResult,
      })
      .then((response) => {
        if (response.data.status === 200) {
          if (response.data.data.allProductDetails.length > 0) {
            setProductList(response.data.data.allProductDetails);
            let obj = {
              description: response.data.data.allProductDetails[0].description,
              productId: response.data.data.allProductDetails[0].productId,
              productName: response.data.data.allProductDetails[0].productName,
              quantity: response.data.data.allProductDetails[0].unitPrice || 1,
              listPrice: response.data.data.allProductDetails[0].unitPrice || 0,
              amount:
                response.data.data.allProductDetails[0].unitPrice *
                  response.data.data.allProductDetails[0].unitPrice || 0,
              discount: 0,
              tax: parseFloat(response.data.data.allProductDetails[0].tax) || 0,
              total: isNaN(response.data.data.allProductDetails[0].total)
                ? 0
                : response.data.data.allProductDetails[0].total,
              discountType: 1,
              discountValue: 0,
            };
            setSelectedProduct(obj);

            setAllProducts([obj]);
          }
        }
      })
      .catch(async (error) => {
        handleApiError(error, navigate);
      });
  };

  const fieldDropdownStyles = {
    menu: (base) => ({
      ...base,
      width: "100%",
    }),
    option: (provided) => ({
      ...provided,
      // backgroundColor: "#EAEAEA",
      color: "#000",
      // backgroundColor: 'rgba(33, 119, 254, 0.1)',
      width: "100%",
    }),
    control: (baseStyles) => ({
      ...baseStyles,
      border: 0,
      // backgroundColor: 'rgba(33, 119, 254, 0.1)',
      height: "20px",
      borderRadius: "10px",
      width: "75%",
      boxShadow: "none",
    }),
    input: (base) => ({
      ...base,
      color: "#FFFFFF",
    }),
    singleValue: (defaultStyles) => ({ ...defaultStyles, color: "#000" }),

    menuList: (provided) => ({
      ...provided,
      paddingTop: 0,
      paddingBottom: 0,
      "&::-webkit-scrollbar": {
        width: 5,
      },

      "&::-webkit-scrollbar-thumb": {
        background: "#316AFF",
        borderRadius: "10px",
      },
    }),
  };

  const createLeadStyles = {
    menu: (base) => ({
      ...base,
      width: "60%",
    }),
    option: (provided) => ({
      ...provided,
      // backgroundColor: "#EAEAEA",
      color: "#000",
      // backgroundColor: 'rgba(33, 119, 254, 0.1)',
      width: "100%",
    }),
    control: (baseStyles) => ({
      ...baseStyles,
      border: "1px solid #b9b9b9b0",
      backgroundColor: "#F3F6FB",
      height: "40px",
      borderRadius: "10px",
      width: "60%",
      boxShadow: "none",
    }),
    input: (base) => ({
      ...base,
      color: "#515151",
    }),
    singleValue: (defaultStyles) => ({ ...defaultStyles, color: "#000" }),

    menuList: (provided) => ({
      ...provided,
      paddingTop: 0,
      paddingBottom: 0,
      "&::-webkit-scrollbar": {
        width: 5,
      },

      "&::-webkit-scrollbar-thumb": {
        background: "#316AFF",
        borderRadius: "10px",
      },
    }),
  };
  const stageOptions = [
    { value: "Created", label: "Created" },
    { value: "Negotiation", label: "Negotiation" },
    { value: "Delivered", label: "Delivered" },
    { value: "On Hold", label: "On Hold" },
    { value: "Confirmed", label: "Confirmed" },
    { value: "Closed Won", label: "Closed Won" },
    { value: "Closed Lost", label: "Closed Lost" },
  ];
  const carrierOptions = [
    { value: "FedEX", label: "FedEX" },
    { value: "UPS", label: "UPS" },
    { value: "USPS", label: "USPS" },
    { value: "DHL", label: "DHL" },
    { value: "Blue Dart", label: "Blue Dart" },
  ];

  const quotesColumn = [
    {
      name: "Subject",
      isChecked: true,
      value: "subject",
    },
    {
      name: "Quote Stage",
      isChecked: true,
      value: "quoteStage",
    },
    {
      name: "Grand Total",
      isChecked: true,
      value: "grandTotal",
    },
    {
      name: "Contact Name",
      isChecked: true,
      value: "contactName",
    },
    {
      name: "Account Name",
      isChecked: false,
      value: "accountName",
    },
    {
      name: "Quotes Owner",
      isChecked: false,
      value: "quotesOwner",
    },

    {
      name: "Deal Name",
      isChecked: true,
      value: "dealName",
    },
    {
      name: "Valid Until",
      isChecked: false,
      value: "validUntil",
    },
    {
      name: "Billing City",
      isChecked: false,
      value: "billingCity",
    },
    {
      name: "Billing Code",
      isChecked: false,
      value: "billingCode",
    },
    {
      name: "Billing Country",
      isChecked: false,
      value: "billingCountry",
    },
    {
      name: "Billing State",
      isChecked: false,
      value: "billingState",
    },
    {
      name: "Billing Street",
      isChecked: false,
      value: "billingStreet",
    },
    {
      name: "Carrier",
      isChecked: false,
      value: "carrier",
    },
    {
      name: "Shipping City",
      isChecked: false,
      value: "shippingCity",
    },
    {
      name: "Shipping Code",
      isChecked: false,
      value: "shippingCode",
    },
    {
      name: "Shipping Country",
      isChecked: false,
      value: "shippingCountry",
    },
    {
      name: "Shipping State",
      isChecked: false,
      value: "shippingState",
    },
    {
      name: "Team",
      isChecked: false,
      value: "team",
    },
    {
      name: "Hash",
      isChecked: true,
      value: "transHash",
    },
  ];

  const fieldIndividualDropdowns = [
    {
      name: "Quote Owner",
      type: "select",
      value: "quoteOwner",
    },
    {
      name: "Subject",
      type: "input",
      value: "subject",
    },
    {
      name: "Quote Stage",
      type: "dropdown",
      value: "quoteStageInfo",
    },
    {
      name: "Team",
      type: "input",
      value: "teamInfo",
    },
    {
      name: "Carrier",
      type: "dropdown",
      value: "carrierInfo",
    },
    {
      name: "Created By",
      type: "readOnly",
      value: "createdBy",
    },
    {
      name: "Quote Number",
      type: "readOnly",
      value: "quoteNumber",
    },
    {
      name: "Deal Name",
      type: "readOnly",
      value: "dealName",
    },
    {
      name: "Valid Until",
      type: "input",
      value: "validUntilInfo",
    },
    {
      name: "Contact Name",
      type: "readOnly",
      value: "contactName",
    },
    {
      name: "Account Name",
      type: "readOnly",
      value: "accountName",
    },
    {
      name: "Modified By",
      type: "readOnly",
      value: "modifiedBy",
    },
    {
      name: "Billing Street",
      type: "input",
      value: "billingStreetInfo",
    },
    {
      name: "Billing City",
      type: "input",
      value: "billingCityInfo",
    },

    {
      name: "Billing State",
      type: "input",
      value: "billingStateInfo",
    },

    {
      name: "Billing Code",
      type: "input",
      value: "billingCodeInfo",
    },

    {
      name: "Billing Country",
      type: "input",
      value: "billingCountryInfo",
    },
    {
      name: "Shipping Street",
      type: "input",
      value: "shippingStreetInfo",
    },
    {
      name: "Shipping City",
      type: "input",
      value: "shippingCityInfo",
    },
    {
      name: "Shipping State",
      type: "input",
      value: "shippingStateInfo",
    },
    {
      name: "Shipping Code",
      type: "input",
      value: "shippingCodeInfo",
    },
    {
      name: "Shipping Country",
      type: "input",
      value: "shippingCountryInfo",
    },
  ];

  const [accountListVisible, setAccountListVisible] = useState(false);
  const [isAccountListModal, setIsAccountListModal] = useState(false);

  const [isContactListModal, setIsContactListModal] = useState(false);
  const [contactListVisible, setContactListVisible] = useState(false);

  const [isDealListModal, setIsDealListModal] = useState(false);
  const [dealListVisible, setDealListVisible] = useState(false);
  const [isProductOwnerModal, setIsProductOwnerModal] = useState(false);
  const [selectedHeadings, setSelectedHeadings] = useState(quotesColumn);
  const [savingHeadings, setSavingHeadings] = useState(quotesColumn);
  const [showHeadings, setShowHeadings] = useState(false);
  const [showSelectedFilter, setShowSelectedFilter] = useState("");
  const [filterType, setFilterType] = useState("");
  const [pinnedColumn, setPinnedColumn] = useState("Subject");
  const [searchColumn, setSearchColumn] = useState("");
  const [filteredQuoteDetails, setFilteredQuoteDetails] = useState([]);
  const [modalType, setShowModalType] = useState("");
  // const [activeTab, setActiveTab] = useState("note"); activeTab is not used anywhere so i am commenting this

  const [quotesInfoOverview, setQuotesInfoOverview] = useState({});
  const [quoteAccContactDealData, setQuoteAccContactDealData] = useState({});
  const [salesOrderList, setSalesOrderList] = useState([]);
  const [closeActivities, setCloseActivities] = useState([]);
  const [quoteItemTotalDetails, setQuoteItemTotalDetails] = useState({});
  const [quotesItemInfo, setQuoteItemInfo] = useState([]);
  const [openActivities, setOpenActivities] = useState([]);
  const [openActivitiesCalls, setOpenActivitiesCalls] = useState([]);
  const [openActivitiesMeetings, setOpenActivitiesMeetings] = useState([]);
  const [closedActivitiesCall, setClosedActivitiesCall] = useState([]);
  const [closedActivitiesMeetings, setClosedActivitiesMeetings] = useState([]);
  const [quoteOverviewDetails, setQuoteOverviewDetails] = useState({});
  const [updateButtonLabel, setUpdateButtonLabel] = useState("Save");
  const [shareModal, setShareModal] = useState(false);
  const [shareUserList, setShareUserList] = useState([]);
  const [shareRoleList, setShareRoleList] = useState([]);
  const [shareGroupList, setShareGroupList] = useState([]);
  const[sharePublicList,setSharePublicList]=useState([])
  const [shareInfoModal, setShareInfoModal] = useState(false);
  const [searchDeal, setSearchDeal] = useState("");
  const [searchContact, setSearchContact] = useState("");
  const [searchAccount, setSearchAccount] = useState("");
  const [modalAccountDetails, setModalAccountDetail] = useState([]);
  const [modalContactDetails, setModalContactDetails] = useState([]);
  const [modalDealDetails, setModalDealDetails] = useState([]);
  const [mandatoryMsg, setMandatoryMsg] = useState("");
  const [accErrName, setAccErrName] = useState("");

  const [billingCityMsg, setBillingCityMsg] = useState("");
  const [shippingCityMsg, setShippingCityMsg] = useState("");
  const [billingStateMsg, setBillingStateMsg] = useState("");
  const [shippingStateMsg, setShippingStateMsg] = useState("");
  const [billingCountryMsg, setBillingCountryMsg] = useState("");
  const [shippingCountryMsg, setShippingCountryMsg] = useState("");
  const [teamMsg, setTeamMsg] = useState("");

  const [codeMsg, setCodeMsg] = useState("");
  const [shippingCodeMsg, setShippingCodeMsg] = useState("");

  const [selectedProductIndex, setSelectedProductIndex] = useState("");
  const [listVisibleType, setListVisibleType] = useState("");
  const [apiLoader, setApiLoader] = useState(false);
  const [deletedImg, setDeletedImg] = useState("");

  const filteredDeals = modalDealDetails.filter((item) =>
    item.dealName?.toLowerCase().includes(searchDeal?.toLowerCase())
  );

  const filteredContacts = modalContactDetails.filter((item) =>
    item.contactName?.toLowerCase().includes(searchContact?.toLowerCase())
  );

  const filteredAccount = modalAccountDetails.filter((item) =>
    item.accountName?.toLowerCase().includes(searchAccount?.toLowerCase())
  );

  const handleSearchDeal = (e) => {
    const { value } = e.target;
    if (value.trim() !== "" || value === "") {
      setSearchDeal(value);
    }
  };

  const handleSearchContact = (e) => {
    const { value } = e.target;
    if (value.trim() !== "" || value === "") {
      setSearchContact(value);
    }
  };

  const handleSearchAccount = (e) => {
    const { value } = e.target;
    if (value.trim() !== "" || value === "") {
      setSearchAccount(value);
    }
  };

  const [selectedProduct, setSelectedProduct] = useState({
    productName: "",
    productId: "",
  });

  const [popoutType, setPopoutType] = useState("");
  const [discountType, setDiscountType] = useState("percentage");

  const [allProducts, setAllProducts] = useState([
    {
      productId: "",
      productName: "",
      quantity: 1,
      listPrice: "",
      amount: 1,
      discount: 0,
      tax: 0,
      total: 1,
      description: "",
      discountType: 1,
      discountValue: 0,
    },
  ]);

  const [updateNotes, setUpdateNotes] = useState({
    description: "",
    title: "",
    noteImageList: [],
  });
  const [showOptionsContact, setShowOptionsContact] = useState(false);
  const [noteInfo, setNoteInfo] = useState([]);
  const [addTitle, setAddTitle] = useState("");
  const [note, setNote] = useState("");
  const [selectedNoteFiles, setSelectedNoteFiles] = useState([]);
  const [showButtons, setShowButtons] = useState(false);
  const [showTitleNote, setShowTitleNote] = useState(false);
  const [noteTitle, setNoteTitle] = useState("");
  const [editNote, setEditNote] = useState(false);
  const [discountObj, setDiscountObj] = useState({
    type: 1,
    value: 0,
  });
  const [selectRecent, setSelectRecent] = useState("ALL");
  const [purchaseOrdId, setPurchaseOrdId] = useState("");

  const handleAddRow = () => {
    const { unitPrice, tax, productId, productName, description } =
      productList[0];
    const discountedAmount = parseFloat(unitPrice) * parseFloat(unitPrice) - 0;
    const taxAmount = (parseFloat(tax || 0) / 100) * discountedAmount;
    const total = (discountedAmount + taxAmount).toFixed(2) || 0;
    setAllProducts([
      ...allProducts,
      {
        productId: productId,
        description: description,
        productName: productName,
        quantity: unitPrice || 1,
        listPrice: unitPrice || 0,
        amount: unitPrice * unitPrice || 0,
        discount: 0,
        tax: parseFloat(tax) || 0,
        total: isNaN(total) ? 0 : total,
        discountType: 1,
        discountValue: 0,
      },
    ]);

    const productsTotal = allProducts
      .reduce(
        (sum, product) =>
          parseFloat(sum) +
          parseFloat(isNaN(product.total) ? 0 : product.total),
        0
      )
      ?.toFixed(2);

    const subTotalValue =
      Number(productsTotal) + Number(isNaN(total) ? 0 : total);

    if (existingQuote || existingClone) {
      setQuoteUpdateInfo((prevInfo) => ({
        ...prevInfo,
        subTotal: subTotalValue,
        grandTotal: parseFloat(
          subTotalValue - Number(quoteInfo.discount || 0)
        ).toFixed(2),
      }));
    } else {
      setQuoteInfo((prevInfo) => ({
        ...prevInfo,
        subTotal: subTotalValue,
        grandTotal: parseFloat(
          subTotalValue - Number(quoteInfo.discount || 0)
        ).toFixed(2),
      }));
    }
  };

  const customSelectLocationStyles = {
    menu: (base) => ({
      ...base,
      width: "100%",
    }),
    option: (provided, state) => ({
      ...provided,
      color: "#000",
      backgroundColor: state.isSelected ? "#407BFF" : "",
      width: "100%",
      cursor: "pointer",
      "&:hover": {
        backgroundColor: "#f5f5f5",
      },
    }),
    control: (baseStyles) => ({
      ...baseStyles,
      border: "2px solid #316AFF",
      backgroundColor: "#fff",
      height: "40px",
      borderRadius: "10px",
      width: "100%",
      boxShadow: "none",
      cursor: "pointer",
    }),
    input: (base) => ({
      ...base,
      color: "#0000",
    }),
    singleValue: (defaultStyles) => ({ ...defaultStyles, color: "#000" }),

    menuList: (provided) => ({
      ...provided,
      paddingTop: 0,
      paddingBottom: 0,
      "&::-webkit-scrollbar": {
        width: 5,
      },

      "&::-webkit-scrollbar-thumb": {
        background: "#00EEFD",
        borderRadius: "10px",
      },
    }),
    dropdownIndicator: (base) => ({
      ...base,
      color: "#316AFF", // Custom colour
    }),
  };

  const totalPages = Math.ceil(Number(totalItems) / perPage);
  const totalPagesContact = Math.ceil(
    Number(totalItemsContact) / perPageContact
  );
  const totalPagesAccount = Math.ceil(
    Number(totalItemsAccount) / perPageAccount
  );

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleChange = (option) => {
    // setSelectedOption(selectedOption);
    if (selectedOption.value !== option.value) {
      setCurrentPage(1);
      getAllQuote(1, option);
    }
  };

  const handleRowChange = (index, e) => {
    const { name, value } = e.target;
    // const sanitizedValue = value
    //   .replace(/[^0-9.]/g, "")
    //   .replace(/(\..*)\./g, "$1");

    let discountedAmount;
    let taxAmount;
    const newRows = [...allProducts];
    if (name === "quantity") {
      const getValue = value.replace(/[^0-9+\-.]+/g, "");
      newRows[index].quantity = getValue;
      const useValue = getValue === "" ? 0 : getValue;
      discountedAmount =
        useValue * parseFloat(newRows[index].listPrice) -
        parseFloat(newRows[index].discount);
      taxAmount =
        (parseFloat(newRows[index].tax || 0) / 100) * discountedAmount;

      newRows[index].amount =
        (useValue * parseFloat(newRows[index].listPrice)).toFixed(2) || 0;
      newRows[index].total =
        (discountedAmount + taxAmount).toFixed(2) || "0.00";
    } else if (name === "listPrice") {
      const getValue = value.replace(/[^0-9+\-.]+/g, "");
      newRows[index].listPrice = getValue;
      const useValue = getValue === "" ? 0 : getValue;
      discountedAmount =
        useValue * parseFloat(newRows[index].quantity) -
        parseFloat(newRows[index].discount);
      taxAmount =
        (parseFloat(newRows[index].tax || 0) / 100) * discountedAmount;
      newRows[index].amount = (
        useValue * parseFloat(newRows[index].quantity)
      ).toFixed(2);
      newRows[index].total =
        (discountedAmount + taxAmount).toFixed(2) || "0.00";
    } else if (name === "description") {
      newRows[index][name] = value;
    }

    setAllProducts(newRows);

    const subTotalValue =
      newRows.reduce(
        (sum, product) => parseFloat(sum) + parseFloat(product.total),
        0
      ) || 0;
    if (existingQuote || existingClone) {
      setQuoteUpdateInfo((prevInfo) => ({
        ...prevInfo,
        subTotal: parseFloat(subTotalValue).toFixed(2) || 0,
        grandTotal: parseFloat(
          subTotalValue - parseFloat(quoteInfo.discount || 0)
        ).toFixed(2),
      }));
    } else {
      setQuoteInfo((prevInfo) => ({
        ...prevInfo,
        subTotal: parseFloat(subTotalValue).toFixed(2) || 0,
        grandTotal: parseFloat(
          subTotalValue - parseFloat(quoteInfo.discount || 0)
        ).toFixed(2),
      }));
    }
  };

  const handleDeleteRow = (index) => {
    if (allProducts.length === 1) {
      return; // Prevent deleting the last row
    }
    const newRows = [...allProducts];
    newRows.splice(index, 1);
    setAllProducts(newRows);
    const subTotalValue = newRows.reduce(
      (sum, product) => parseFloat(sum) + parseFloat(product.total),
      0
    );

    if (existingQuote || existingClone) {
      setQuoteUpdateInfo((prevInfo) => ({
        ...prevInfo,
        subTotal: subTotalValue || 0,
        grandTotal: parseFloat(
          subTotalValue - parseFloat(quoteInfo.discount || 0)
        ).toFixed(2),
      }));
    } else {
      setQuoteInfo((prevInfo) => ({
        ...prevInfo,
        subTotal: subTotalValue || 0,
        grandTotal: parseFloat(
          subTotalValue - parseFloat(quoteInfo.discount || 0)
        ).toFixed(2),
      }));
    }
  };

  const getAllAssignModules = async (main, sub, id) => {
    if (main === "DEALS") {
      var contactDetails = [];
      var accountDetails = [];
    } else if (main === "CONTACTS") {
      var accountDetails = [];
      var dealDetails = [];
    } else if (main === "ACCOUNTS") {
      var dealDetails = [];
      var contactDetails = [];
    }
    const requestBody = {
      loginUserId: loginUserId,
      mainModule: main,
      subModule: sub,
      moduleId: id,
    };
    const headersResult = getHeaders(true);

    // Check if headers result indicates an error
    if (headersResult.error) {
      navigate("../"); // Redirect to login if token is missing
      return;
    }

    await axios
      .post(URI.moduleWiseAssign, requestBody, {
        headers: headersResult,
      })
      .then((response) => {
        if (response.data.status === 200) {
          if (main === "DEALS") {
            for (const element of response.data.data.contactsDtlsList) {
              const modifiedResponse = {
                contactName: "",
                contactId: "",
              };

              modifiedResponse["contactName"] = element.moduleName;
              modifiedResponse["contactId"] = element.moduleId;
              contactDetails.push(modifiedResponse);
            }
            if (response.data.data.contactsDtlsList.length === 0) {
              setSelectedContactItem({
                contactName: "",
                contactId: "",
              });
            } else {
              setSelectedContactItem({
                contactName: response.data.data.contactsDtlsList[0]?.moduleName,
                contactId: response.data.data.contactsDtlsList[0]?.moduleId,
              });
            }
            setContactDetails(contactDetails);
            for (const element of response.data.data.accountsDtlsList) {
              const modifiedResponse = {
                accountName: "",
                accountId: "",
              };

              modifiedResponse["accountName"] = element.moduleName;
              modifiedResponse["accountId"] = element.moduleId;
              accountDetails.push(modifiedResponse);
            }
            if (response.data.data.accountsDtlsList.length > 0) {
              setSelectedAccountItem({
                accountName: response.data.data.accountsDtlsList[0]?.moduleName,
                accountId: response.data.data.accountsDtlsList[0]?.moduleId,
              });
            }
            setAccountDetails(accountDetails);
          } else if (main === "CONTACTS") {
            for (const element of response.data.data.dealsDtlsList) {
              const modifiedResponse = {
                dealName: "",
                dealId: "",
              };

              modifiedResponse["dealName"] = element.moduleName;
              modifiedResponse["dealId"] = element.moduleId;

              dealDetails.push(modifiedResponse);
            }

            if (response.data.data.dealsDtlsList.length === 0) {
              setSelectedDealItem({
                dealName: "",
                dealId: "",
              });
            } else {
              setSelectedDealItem({
                dealName: response.data.data.dealsDtlsList[0]?.moduleName,
                dealId: response.data.data.dealsDtlsList[0]?.moduleId,
              });
            }

            setDealDetails(dealDetails);
            for (const element of response.data.data.accountsDtlsList) {
              const modifiedResponse = {
                accountName: "",
                accountId: "",
              };

              modifiedResponse["accountName"] = element.moduleName;
              modifiedResponse["accountId"] = element.moduleId;
              accountDetails.push(modifiedResponse);
            }

            if (response.data.data.accountsDtlsList.length > 0) {
              setSelectedAccountItem({
                accountName: response.data.data.accountsDtlsList[0]?.moduleName,
                accountId: response.data.data.accountsDtlsList[0]?.moduleId,
              });
            }
            setAccountDetails(accountDetails);
          } else if (main === "ACCOUNTS") {
            for (const element of response.data.data.dealsDtlsList) {
              const modifiedResponse = {
                dealName: "",
                dealId: "",
              };

              modifiedResponse["dealName"] = element.moduleName;
              modifiedResponse["dealId"] = element.moduleId;
              dealDetails.push(modifiedResponse);
            }
            if (response.data.data.dealsDtlsList.length === 0) {
              setSelectedDealItem({
                dealName: "",
                dealId: "",
              });
            } else {
              setSelectedDealItem({
                dealName: response.data.data.dealsDtlsList[0]?.moduleName,
                dealId: response.data.data.dealsDtlsList[0]?.moduleId,
              });
            }
            setDealDetails(dealDetails);
            for (const element of response.data.data.contactsDtlsList) {
              const modifiedResponse = {
                contactName: "",
                contactId: "",
              };

              modifiedResponse["contactName"] = element.moduleName;
              modifiedResponse["contactId"] = element.moduleId;
              contactDetails.push(modifiedResponse);
            }
            if (response.data.data.contactsDtlsList.length === 0) {
              setSelectedContactItem({
                contactName: "",
                contactId: "",
              });
            } else {
              setSelectedContactItem({
                contactName: response.data.data.contactsDtlsList[0]?.moduleName,
                contactId: response.data.data.contactsDtlsList[0]?.moduleId,
              });
            }
            setContactDetails(contactDetails);
          }
        }
      })
      .catch(async (error) => {
        handleApiError(error, navigate);
      });
  };

  useEffect(() => {
    if (pastViewInfo) {
      if (pastViewInfo.view === "contacts") {
        const main = pastViewInfo.view.toUpperCase();
        const sub = ["ACCOUNTS", "DEALS"];
        const id = fetchContactInfo?.contactId;
        getAllAssignModules(main, sub, id);
        return;
      } else if (pastViewInfo.view === "accounts") {
        const main = pastViewInfo.view.toUpperCase();
        const sub = ["CONTACTS", "DEALS"];
        const id = fetchAccInfo?.accountId;
        setSelectedAccountItem({
          accountName: fetchAccInfo?.accountName,
          accountId: fetchAccInfo?.accountId,
        });
        getAllAssignModules(main, sub, id);
        return;
      } else if (pastViewInfo.view === "deals") {
        const main = pastViewInfo.view.toUpperCase();
        const sub = ["CONTACTS", "ACCOUNTS"];
        const id = fetchDealInfo?.dealId;
        getAllAssignModules(main, sub, id);
      }
    }
  }, []);

  const createQuoteStyles = {
    menu: (base) => ({
      ...base,
      width: "60%",
      border: "1px solid rgba(185, 185, 185, 0.3)",
      backgroundColor: "rgba(243, 246, 251, 1)",
    }),
    option: (provided) => ({
      ...provided,
      // backgroundColor: "#EAEAEA",
      color: "#000",
      // backgroundColor: 'rgba(33, 119, 254, 0.1)',
      width: "100%",
    }),
    control: (baseStyles) => ({
      ...baseStyles,
      border: "1px solid #b9b9b9b0",
      backgroundColor: "#F3F6FB",
      height: "40px",
      borderRadius: "5px",
      width: "60%",
      boxShadow: "none",
    }),
    input: (base) => ({
      ...base,
      color: "#FFFFFF",
    }),
    singleValue: (defaultStyles) => ({ ...defaultStyles, color: "#000" }),

    menuList: (provided) => ({
      ...provided,
      paddingTop: 0,
      paddingBottom: 0,
      "&::-webkit-scrollbar": {
        width: 5,
      },

      "&::-webkit-scrollbar-thumb": {
        background: "#316AFF",
        borderRadius: "10px",
      },
    }),
  };

  const [quoteInfo, setQuoteInfo] = useState({
    quoteOwnerId: "",
    subject: "",
    quoteStage: stageOptions[0],
    team: "",
    carrier: carrierOptions[0],
    dealId: "",
    validUntil: "",
    contactId: "",
    accountId: "",
    termsAndConditions: "",
    description: "",
    loggedInUserId: "",
    subTotal: 0,
    discount: 0,
    tax: 0,
    adjustment: 0,
    grandTotal: 0,
    discountType: 1,
    discountValue: 20,
  });

  const [billingAdd, setBillingAdd] = useState({
    billingStreet: "",
    billingCity: "",
    billingState: "",
    billingCode: "",
    billingCountry: "",
  });

  const [shippingAdd, setShippingAdd] = useState({
    shippingStreet: "",
    shippingCity: "",
    shippingState: "",
    shippingCode: "",
    shippingCountry: "",
  });
  const [selectedAccountItem, setSelectedAccountItem] = useState({
    accountName: "",
    accountId: "",
  });
  const [selectedContactItem, setSelectedContactItem] =
    useState(fetchContactInfo);

  const [updatedFields, setUpdatedFields] = useState({
    // quoteOverviewDetails: quoteOverviewDetails.emailId,
    quoteOwnerId: selectedItem?.userId,
    subject: quotesInfoOverview.subject,
    quoteStage: quotesInfoOverview.quoteStage,
    quoteStageInfo: quotesInfoOverview.quoteStage,
    team: quotesInfoOverview.team,
    carrier: quotesInfoOverview.carrier,
    carrierInfo: quotesInfoOverview.carrier,
    dealId: quotesInfoOverview?.dealId,
    validUntil: quotesInfoOverview.validUntil,
    contactId: selectedContactItem?.contactId,
    accountId: selectedAccountItem?.accountId,
    termsAndConditions: quotesInfoOverview.termsAndConditions,
    description: quotesInfoOverview.description,
    loggedInUserId: loginUserId,
    subTotal: quotesInfoOverview.subTotal,
    discount: quotesInfoOverview.discount,
    tax: quotesInfoOverview.tax,
    adjustment: quotesInfoOverview.adjustment,
    grandTotal: quotesInfoOverview.grandTotal,
    billingStreet: quotesInfoOverview.billingStreet,
    billingCity: quotesInfoOverview.billingCity,
    billingState: quotesInfoOverview.billingState,
    billingCode: quotesInfoOverview.billingCode,
    billingCountry: quotesInfoOverview.billingCountry,
    shippingStreet: quotesInfoOverview.shippingStreet,
    shippingCity: quotesInfoOverview.shippingCity,
    shippingState: quotesInfoOverview.shippingState,
    shippingCode: quotesInfoOverview.shippingCode,
    shippingCountry: quotesInfoOverview.shippingCountry,
    discountType: 1,
    discountValue: 20,
    dealNameInfo: quotesInfoOverview?.dealId,
    quotenumberInfo: selectedItem?.userId,
    billingCityInfo: quotesInfoOverview.billingCity,
    billingCodeInfo: quotesInfoOverview.billingCode,
    teamInfo: quotesInfoOverview.team,
    termsAndConditionsInfo: quotesInfoOverview.termsAndConditions,
    billingCountryInfo: quotesInfoOverview.shippingCountry,
    billingStreetInfo: quotesInfoOverview.shippingStreet,
    shippingCityInfo: quotesInfoOverview.shippingCity,
    shippingCodeInfo: quotesInfoOverview.shippingCode,
    shippingCountryInfo: quotesInfoOverview.shippingCountry,
    shippingStateInfo: quotesInfoOverview.shippingState,
    billingStateInfo: quotesInfoOverview.billingState,
    validUntilInfo: quotesInfoOverview.validUntil,
    shippingStreetInfo: quotesInfoOverview.shippingStreet,
  });

  const handleCreateQuoteClone = () => {
    setInnerPage("clone");
    setShowOptionsContact(false);
    setExistingQuote(false);
    setExistingClone(true);

    setQuoteUpdateInfo({
      ...quotesItemInfo,
      ...quoteAccContactDealData,
      ...salesOrderList,
      ...openActivities,
      ...quoteItemTotalDetails,
      ...quotesInfoOverview,
      ...closeActivities,
      // ...billingAdd,
      // ...shippingAdd
    });
    setSelectedDealItem({
      dealId: quoteAccContactDealData.dealId,
      dealName: quoteAccContactDealData.dealName,
    });
    setSelectedAccountItem({
      accountId: quoteAccContactDealData.accountId,
      accountName: quoteAccContactDealData.accountName,
    });
    setSelectedContactItem({
      contactId: quoteAccContactDealData.contactId,
      contactName: quoteAccContactDealData.contactName,
    });
    setUpdateButtonLabel("Save");

    setBillingAdd({
      billingStreet: quotesInfoOverview.billingStreet || "",
      billingCity: quotesInfoOverview.billingCity || "",
      billingState: quotesInfoOverview.billingState || "",
      billingCode: quotesInfoOverview.billingCode || "",
      billingCountry: quotesInfoOverview.billingCountry || "",
    });

    setShippingAdd({
      shippingStreet: quotesInfoOverview.shippingStreet || "",
      shippingCity: quotesInfoOverview.shippingCity || "",
      shippingState: quotesInfoOverview.shippingState || "",
      shippingCode: quotesInfoOverview.shippingCode || "",
      shippingCountry: quotesInfoOverview.shippingCountry || "",
    });
  };
  const [quoteUpdateInfo, setQuoteUpdateInfo] = useState({
    quoteOwnerId: "",
    subject: "",
    quoteStage: stageOptions[0],
    team: "",
    carrier: carrierOptions[0],
    dealId: "",
    validUntil: "",
    contactId: "",
    accountId: "",
    termsAndConditions: "",
    description: "",
    loggedInUserId: loginUserId,
    subTotal: 0,
    discount: 0,
    tax: 0,
    adjustment: 0,
    grandTotal: "",
    billingStreet: "",
    billingCity: "",
    billingState: "",
    billingCode: "",
    billingCountry: "",
    shippingStreet: "",
    shippingCity: "",
    shippingState: "",
    shippingCode: "",
    shippingCountry: "",
    discountType: 1,
    discountValue: 20,
  });
  const resetForm = () => {
    const clearForm = {
      quoteOwnerId: "",
      subject: "",
      quoteStage: stageOptions[0],
      team: "",
      carrier: carrierOptions[0],
      dealId: "",
      validUntil: "",
      contactId: "",
      accountId: "",
      termsAndConditions: "",
      description: "",
      loggedInUserId: loginUserId,
      subTotal: "",
      discount: "",
      tax: "",
      adjustment: 0,
      grandTotal: "",
      billingStreet: "",
      billingCity: "",
      billingState: "",
      billingCode: "",
      billingCountry: "",
      shippingStreet: "",
      shippingCity: "",
      shippingState: "",
      shippingCode: "",
      shippingCountry: "",
      discountType: 1,
      discountValue: 20,
    };

    setShippingAdd(clearForm);
    setBillingAdd(clearForm);
    setQuoteInfo(clearForm);
    setQuoteUpdateInfo(clearForm);
  };
  const fieldDropdowns = [
    {
      name: "Quote Number",
      isShowed: true,
      type: "readOnly",
    },
    {
      name: "Valid Until",
      isShowed: false,
      type: "input",
    },
    {
      name: "Carrier",
      isShowed: false,
      type: "dropdown",
    },
    {
      name: "Quote Stage",
      isShowed: false,
      type: "dropdown",
      value: "quoteStage",
    },
    {
      name: "Deal Name",
      isShowed: true,
      type: "readOnly",
    },
    {
      name: "Contact Name",
      isShowed: false,
      type: "readOnly",
    },
    {
      name: "Account Name",
      isShowed: true,
      type: "readOnly",
    },
    {
      name: "Quote Owner",
      isShowed: true,
      type: "select",
    },
    {
      name: "Billing Street",
      isShowed: false,
      type: "input",
    },
    {
      name: "Shipping Street",
      isShowed: false,
      type: "input",
    },
    {
      name: "Shipping Code",
      isShowed: false,
      type: "input",
    },
    {
      name: "Billing City",
      isShowed: false,
      type: "input",
    },
    {
      name: "Shipping City",
      isShowed: false,
      type: "input",
    },
    {
      name: "Billing State",
      isShowed: false,
      type: "input",
    },
    {
      name: "Shipping State",
      isShowed: false,
      type: "input",
    },
    {
      name: "Industry Type",
      isShowed: false,
      type: "dropdown",
    },
    {
      name: "Billing Country",
      isShowed: false,
      type: "input",
    },
    {
      name: "Shipping Country",
      isShowed: false,
      type: "input",
    },
    {
      name: "Billing Code",
      isShowed: false,
      type: "input",
    },
    {
      name: "Created By",
      isShowed: false,
      type: "readOnly",
    },
    {
      name: "Modified By",
      isShowed: false,
      type: "readOnly",
    },
    {
      name: "Description",
      isShowed: false,
      type: "input",
    },
    {
      name: "Tax",
      isShowed: false,
      type: "readOnly",
    },
    {
      name: "Adjustment",
      isShowed: false,
      type: "readOnly",
    },
    {
      name: "Grand Total",
      isShowed: false,
      type: "readOnly",
    },
    {
      name: "Terms and Conditions",
      isShowed: false,
      type: "input",
      value: "termsAndConditions",
    },
    {
      name: "Team",
      isShowed: false,
      type: "input",
      value: "team",
    },
    {
      name: "Sub Total",
      isShowed: false,
      type: "readOnly",
      value: "subTotal",
    },
    {
      name: "Discount",
      isShowed: false,
      type: "readOnly",
      value: "discount",
    },
  ];

  const [editingFields, setEditingFields] = useState({
    subject: false,
    quoteStage: false,
    quoteStageInfo: false,
    team: false,
    carrier: false,
    carrierInfo: false,
    dealId: false,
    validUntil: false,
    contactId: false,
    accountId: false,
    termsAndConditions: false,
    description: false,
    loggedInUserId: loginUserId,
    subTotal: false,
    discount: false,
    tax: false,
    adjustment: false,
    grandTotal: false,
    discountType: false,
    discountValue: false,
    billingStreet: false,
    billingCity: false,
    billingState: false,
    billingCode: false,
    billingCountry: false,
    shippingStreet: false,
    shippingCity: false,
    shippingState: false,
    shippingCode: false,
    shippingCountry: false,
  });

  const [fieldLabels, setFieldLabels] = useState(fieldDropdowns);
  const [showSearchFields, setShowSearchFields] = useState(fieldDropdowns);
  const [searchField, setSearchField] = useState("");
  const [selectedField, setSelectedField] = useState("");
  const [selectedQuoteIndex, setSelectedQuoteIndex] = useState(0);
  const [accountDetails, setAccountDetails] = useState([]);

  const [contactDetails, setContactDetails] = useState([]);

  const [selectedDealItem, setSelectedDealItem] = useState(fetchDealInfo);
  const [dealDetails, setDealDetails] = useState([]);
  useScrollToUndo(pastViewInfo, setPastViewInfo);

  // for save and new && save lottie loader
  const [saveSubmit, setSaveSubmit] = useState(false);
  const [saveAndNewSubmit, setSaveAndNewSubmit] = useState(false);

  const handleChangeQuotes = (e) => {
    const { name, value } = e.target;
    if (value.length > 0) {
      setIsFirstCharacterEntered(true);
    } else {
      setIsFirstCharacterEntered(false);
    }
    const formattedValue =
      name === "validUntil" ? convertToDisplayFormat(value) : value;
    // name === "validUntil" ? convertDateFormat(value) : value;

    if (existingQuote || existingClone) {
      setQuoteUpdateInfo((prevLeadInfo) => ({
        ...prevLeadInfo,
        [name]: formattedValue,
      }));
    } else {
      setQuoteInfo((prevPurchaseInfo) => ({
        ...prevPurchaseInfo,
        [name]: formattedValue,
      }));
    }
  };

  const handleChangeSelectDropDown = (selectedOption, field) => {
    if (existingQuote || existingClone) {
      setQuoteUpdateInfo((prevLeadInfo) => ({
        ...prevLeadInfo,
        [field]: selectedOption,
      }));
    } else {
      setQuoteInfo((prevLeadInfo) => ({
        ...prevLeadInfo,
        [field]: selectedOption,
      }));
    }
  };
  const handleInsertQuote = async (e) => {
    let isValidForm = true;
    const setErrorMessage = (setter, message, timeout = 5000) => {
      setter(message);
      setTimeout(() => {
        setter("");
      }, timeout);
      isValidForm = false;
    };

    const quoteData =
      existingQuote || existingClone ? quoteUpdateInfo : quoteInfo;

    if (quoteData.subject === "") {
      setErrorMessage(setMandatoryMsg, "Subject cannot be empty.", 5000);
    }

    if (
      selectedAccountItem?.accountId === "" ||
      selectedAccountItem === undefined
    ) {
      setErrorMessage(setAccErrName, "Account name cannot be empty.", 5000);
    }

    if (quoteData.subject && !validateCallSubject(quoteData.subject)) {
      setErrorMessage(
        setMandatoryMsg,
        "Please enter a valid  Subject name.",
        5000
      );
    }

    if (quoteData.team && !validateTeamName(quoteData.team)) {
      setErrorMessage(setTeamMsg, "Please enter a valid team name.", 5000);
    }

    if (billingAdd.billingCity && !validateCityIndia(billingAdd.billingCity)) {
      setErrorMessage(setBillingCityMsg, "Please enter a valid Billing City.");
    }

    if (
      billingAdd.billingState &&
      !validateStateIndia(billingAdd.billingState)
    ) {
      setErrorMessage(
        setBillingStateMsg,
        "Please enter a valid Billing State."
      );
    }

    if (billingAdd.billingCode && !validateZipCode(billingAdd.billingCode)) {
      setErrorMessage(setCodeMsg, "Please enter a valid Billing Code.");
    }

    if (
      shippingAdd.shippingCity &&
      !validateCityIndia(shippingAdd.shippingCity)
    ) {
      setErrorMessage(
        setShippingCityMsg,
        "Please enter a valid Shipping City."
      );
    }

    if (
      shippingAdd.shippingState &&
      !validateStateIndia(shippingAdd.shippingState)
    ) {
      setErrorMessage(
        setShippingStateMsg,
        "Please enter a valid Shipping State."
      );
    }

    if (
      billingAdd.billingCountry &&
      !validateCountryName(billingAdd.billingCountry)
    ) {
      setErrorMessage(
        setBillingCountryMsg,
        "Please enter a valid Billing Country."
      );
    }

    if (
      shippingAdd.shippingCode &&
      !validateZipCode(shippingAdd.shippingCode)
    ) {
      setErrorMessage(
        setShippingCodeMsg,
        "Please enter a valid Shipping Code."
      );
    }

    if (
      shippingAdd.shippingCountry &&
      !validateCountryName(shippingAdd.shippingCountry)
    ) {
      setErrorMessage(
        setShippingCountryMsg,
        "Please enter a valid Shipping Country."
      );
    }
    if (!isValidForm) return;

    // let formData = new FormData();
    let requestBody = {};
    e === "SAVE AND NEW" ? setSaveAndNewSubmit(true) : setSaveSubmit(true);

    if (existingClone) {
      requestBody = {
        quoteId: quoteId,
        quoteOwnerId: selectedItem?.userId,
        subject: removeExtraSpaces(quoteUpdateInfo.subject),
        quoteStage: quoteUpdateInfo.quoteStage.value,
        team: quoteUpdateInfo.team,
        carrier: quoteUpdateInfo.carrier.value,
        dealId: selectedDealItem?.dealId || null,
        validUntil: convertDateFormat(
          quoteUpdateInfo.validUntil?.split("-").reverse().join("-")
        ),

        contactId: selectedContactItem?.contactId || null,
        accountId: selectedAccountItem?.accountId || null,
        termsAndConditions: removeExtraSpaces(
          quoteUpdateInfo.termsAndConditions
        ),
        description: removeExtraSpaces(quoteUpdateInfo.description),
        loggedInUserId: loginUserId,
        billingStreet: removeExtraSpaces(quoteUpdateInfo.billingStreet),
        billingCity: removeExtraSpaces(quoteUpdateInfo.billingCity),
        billingState: removeExtraSpaces(quoteUpdateInfo.billingState),
        billingCode: removeExtraSpaces(quoteUpdateInfo.billingCode),
        billingCountry: removeExtraSpaces(quoteUpdateInfo.billingCountry),
        shippingStreet: removeExtraSpaces(quoteUpdateInfo.shippingStreet),
        shippingCity: removeExtraSpaces(quoteUpdateInfo.shippingCity),
        shippingState: removeExtraSpaces(quoteUpdateInfo.shippingState),
        shippingCode: removeExtraSpaces(quoteUpdateInfo.shippingCode),
        shippingCountry: removeExtraSpaces(quoteUpdateInfo.shippingCountry),
        quoteItemDtlsDto: allProducts,
        subTotal: Number(quoteUpdateInfo.subTotal),
        discount: Number(quoteUpdateInfo.discount),
        tax: Number(quoteUpdateInfo.tax),
        adjustment: Number(quoteUpdateInfo.adjustment),
        grandTotal: Number(quoteUpdateInfo.grandTotal),
        discountType: Number(quoteInfo.discountType),
        discountValue: Number(quoteInfo.discountValue),
      };
    } else {
      requestBody = {
        quoteId: quoteId,
        quoteOwnerId: selectedItem?.userId,
        subject: removeExtraSpaces(quoteInfo.subject),
        quoteStage: quoteInfo.quoteStage.value,
        team: quoteInfo.team,
        carrier: quoteInfo.carrier.value,
        dealId: selectedDealItem?.dealId || null,
        validUntil: quoteInfo.validUntil,
        // validUntil: moment(new Date(quoteInfo.validUntil)).format("DD-MM-YYYY"),
        contactId: selectedContactItem?.contactId || null,
        accountId: selectedAccountItem?.accountId || null,
        termsAndConditions: removeExtraSpaces(quoteInfo.termsAndConditions),
        description: removeExtraSpaces(quoteInfo.description),
        loggedInUserId: loginUserId,
        billingStreet: removeExtraSpaces(billingAdd.billingStreet),
        billingCity: removeExtraSpaces(billingAdd.billingCity),
        billingState: removeExtraSpaces(billingAdd.billingState),
        billingCode: removeExtraSpaces(billingAdd.billingCode),
        billingCountry: removeExtraSpaces(billingAdd.billingCountry),
        shippingStreet: removeExtraSpaces(shippingAdd.shippingStreet),
        shippingCity: removeExtraSpaces(shippingAdd.shippingCity),
        shippingState: removeExtraSpaces(shippingAdd.shippingState),
        shippingCode: removeExtraSpaces(shippingAdd.shippingCode),
        shippingCountry: removeExtraSpaces(shippingAdd.shippingCountry),
        quoteItemDtlsDto: allProducts,
        subTotal: Number(quoteInfo.subTotal),
        discount: Number(quoteInfo.discount),
        tax: Number(quoteInfo.tax),
        adjustment: Number(quoteInfo.adjustment),
        grandTotal: Number(quoteInfo.grandTotal),
        discountType: Number(quoteInfo.discountType),
        discountValue: Number(quoteInfo.discountValue),
      };
    }
    if (selectedAccountItem.accountName === "") {
      NotificationManager.error("", "Account Name cannot be empty.", 3000);
    } else {
      const headersResult = getHeaders(true);

      // Check if headers result indicates an error
      if (headersResult.error) {
        navigate("../"); // Redirect to login if token is missing
        return;
      }
      await axios
        .post(URI.createQuote, requestBody, {
          headers: headersResult,
        })
        .then((response) => {
          if (response.data.status === 200) {
            getAllQuote(1, selectedOption);
            resetForm();
            setSelectedItem(userList[0]);
            getAllDealsDetails();
            getAllContact();
            getAllAccountDetails();
            NotificationManager.success("", response.data.message, 5000);
            const discountedAmount =
              productList[0].unitPrice * productList[0].unitPrice - 0;
            const taxAmount =
              (parseFloat(productList[0].tax || 0) / 100) * discountedAmount;

            setAllProducts([
              {
                description: productList[0].description,
                productName: productList[0].productName,
                quantity: productList[0].unitPrice,
                listPrice: productList[0].unitPrice,
                amount: productList[0].unitPrice * productList[0].unitPrice,
                discount: 0,
                tax: productList[0].tax || 0,
                total: (discountedAmount + taxAmount).toFixed(2),
                // total: productList[0].unitPrice * productList[0].unitPrice,
                discountType: 1,
                discountValue: 0,
              },
            ]);
            if (
              pastViewInfo &&
              pastViewInfo?.view !== "" &&
              pastViewInfo?.innerPage !== ""
            ) {
              setCurrentView(pastViewInfo.view);
              setInnerPage(pastViewInfo.innerPage);
              setPastViewInfo((prev) => ({
                ...prev,
                view: "",
                innerPage: "",
                undo: currentView, // it will set the current view of the page so when we cancel or save it will go to same section in the page
              }));
              return;
            }

            if (e === "SAVE AND NEW") {
              resetForm();
            }
            if (e === "SAVE" || existingClone) {
              // resetForm();
              setInnerPage("list");
            }
          } else {
            NotificationManager.error("", response.data.message, 5000);
          }
        })
        .catch(function (error) {
          handleApiError(error, navigate);
        })
        .finally(() => {
          setSaveAndNewSubmit(false);
          setSaveSubmit(false);
        });
    }
    //setAccountsView("AccountsRecord");
  };

  const handleUpdateQuote = async (e) => {
    let isValidForm = true;
    const setErrorMessage = (setter, message, timeout = 5000) => {
      setter(message);
      setTimeout(() => {
        setter("");
      }, timeout);
      isValidForm = false;
    };

    if (
      selectedAccountItem?.accountId === "" ||
      selectedAccountItem === undefined
    ) {
      setErrorMessage(setAccErrName, "Account name cannot be empty.", 5000);
    }

    const quoteData =
      existingQuote || existingClone ? quoteUpdateInfo : quoteInfo;

    if (quoteData.subject === "") {
      setErrorMessage(setMandatoryMsg, "Subject cannot be empty.", 5000);
    }

    if (quoteData.subject && !validateCallSubject(quoteData.subject)) {
      setErrorMessage(
        setMandatoryMsg,
        "Please enter a valid  Subject name.",
        5000
      );
    }

    if (quoteData.team && !validateTeamName(quoteData.team)) {
      setErrorMessage(setTeamMsg, "Please enter a valid Team.", 5000);
    }

    if (billingAdd.billingCity && !validateCityIndia(billingAdd.billingCity)) {
      setErrorMessage(setBillingCityMsg, "Please enter a valid Billing City.");
    }

    if (
      billingAdd.billingState &&
      !validateStateIndia(billingAdd.billingState)
    ) {
      setErrorMessage(
        setBillingStateMsg,
        "Please enter a valid Billing State."
      );
    }

    if (billingAdd.billingCode && !validateZipCode(billingAdd.billingCode)) {
      setErrorMessage(setCodeMsg, "Please enter a valid Billing Code.");
    }

    if (
      shippingAdd.shippingCity &&
      !validateCityIndia(shippingAdd.shippingCity)
    ) {
      setErrorMessage(
        setShippingCityMsg,
        "Please enter a valid Shipping City."
      );
    }

    if (
      shippingAdd.shippingState &&
      !validateStateIndia(shippingAdd.shippingState)
    ) {
      setErrorMessage(
        setShippingStateMsg,
        "Please enter a valid Shipping State."
      );
    }

    if (
      billingAdd.billingCountry &&
      !validateCountryName(billingAdd.billingCountry)
    ) {
      setErrorMessage(
        setBillingCountryMsg,
        "Please enter a valid Billing Country."
      );
    }

    if (
      shippingAdd.shippingCode &&
      !validateZipCode(shippingAdd.shippingCode)
    ) {
      setErrorMessage(
        setShippingCodeMsg,
        "Please enter a valid Shipping Code."
      );
    }

    if (
      shippingAdd.shippingCountry &&
      !validateCountryName(shippingAdd.shippingCountry)
    ) {
      setErrorMessage(
        setShippingCountryMsg,
        "Please enter a valid Shipping Country."
      );
    }
    if (!isValidForm) return;

    const deleteItemDtlsDto = [];
    const allProductIds = allProducts.map(
      (product) => product.quoteOrderItemsId
    );

    quotesItemInfo.forEach((item) => {
      if (!allProductIds.includes(item.quoteOrderItemsId)) {
        deleteItemDtlsDto.push({
          quoteOrderItemsId: item.quoteOrderItemsId,
        });
      }
    });

    setSaveSubmit(true);

    const requestBody = {
      quoteId: quoteId,
      quoteOwnerId: selectedItem?.userId,
      subject: quoteUpdateInfo.subject,
      quoteStage: quoteUpdateInfo.quoteStage.value,
      team: quoteUpdateInfo.team,
      carrier: quoteUpdateInfo.carrier.value,
      dealId: selectedDealItem?.dealId || null,
      validUntil: convertDateFormat(
        quoteUpdateInfo.validUntil?.split("-").reverse().join("-")
      ),
      contactId: selectedContactItem?.contactId || null,
      accountId: selectedAccountItem?.accountId || null,
      termsAndConditions: quoteUpdateInfo.termsAndConditions,
      description: quoteUpdateInfo.description,
      loggedInUserId: loginUserId,
      billingStreet: quoteUpdateInfo.billingStreet,
      billingCity: quoteUpdateInfo.billingCity,
      billingState: quoteUpdateInfo.billingState,
      billingCode: quoteUpdateInfo.billingCode,
      billingCountry: quoteUpdateInfo.billingCountry,
      shippingStreet: quoteUpdateInfo.shippingStreet,
      shippingCity: quoteUpdateInfo.shippingCity,
      shippingState: quoteUpdateInfo.shippingState,
      shippingCode: quoteUpdateInfo.shippingCode,
      shippingCountry: quoteUpdateInfo.shippingCountry,
      quoteItemDtlsDto: allProducts,
      deleteItemDtlsDto: deleteItemDtlsDto,
      subTotal: Number(quoteUpdateInfo.subTotal),
      discount: Number(quoteUpdateInfo.discount),
      tax: Number(quoteUpdateInfo.tax),
      adjustment: Number(quoteUpdateInfo.adjustment),
      grandTotal: Number(quoteUpdateInfo.grandTotal),
      discountType: Number(quoteInfo.discountType),
      discountValue: Number(quoteInfo.discountValue),
      emailNotification: emailNotification,
    };

    const headersResult = getHeaders(true);

    // Check if headers result indicates an error
    if (headersResult.error) {
      navigate("../"); // Redirect to login if token is missing
      return;
    }
    await axios
      .post(URI.updateQuoteDetails, requestBody, {
        headers: headersResult,
      })

      .then((response) => {
        if (response.data.status === 200) {
          // getAllAccountDetails();
          // accountResetValues();
          NotificationManager.success("", response.data.message, 5000);
          if (e === "UPDATE") {
            setInnerPage("list");
          }
          if (e === "SAVE AND NEW") {
            resetForm();
            setInnerPage("create");
          }
        } else {
          NotificationManager.error("", response.data.message, 5000);
        }
      })
      .catch(function (error) {
        handleApiError(error, navigate);
      })
      .finally(() => {
        setSaveSubmit(false);
      });
    //setAccountsView("AccountsRecord");
  };

  const handleChangeBillingAddress = (e) => {
    const { name, value } = e.target;
    if (value && value[0] === " ") {
      e.preventDefault();
      return;
    }
    // setBillingAdd((prevPurchaseInfo) => ({
    //   ...prevPurchaseInfo,
    //   [name]: value,
    // }));

    if (existingQuote || existingClone) {
      setQuoteUpdateInfo((prevLeadInfo) => ({
        ...prevLeadInfo,
        [name]: value,
      }));
    } else {
      setBillingAdd((prevPurchaseInfo) => ({
        ...prevPurchaseInfo,
        [name]: value,
      }));
    }
  };

  const handleChangeShippingAddress = (e) => {
    const { name, value } = e.target;
    if (value && value[0] === " ") {
      e.preventDefault();
      return;
    }
    // setShippingAdd((prevPurchaseInfo) => ({
    //   ...prevPurchaseInfo,
    //   [name]: value,
    // }));

    if (existingQuote || existingClone) {
      setQuoteUpdateInfo((prevLeadInfo) => ({
        ...prevLeadInfo,
        [name]: value,
      }));
    } else {
      setShippingAdd((prevPurchaseInfo) => ({
        ...prevPurchaseInfo,
        [name]: value,
      }));
    }
  };

  const handleOwnerInputClick = (e) => {
    e.stopPropagation();
    setListVisible(!listVisible);
    setDealListVisible(false);
    setAccountListVisible(false);
    setContactListVisible(false);
  };

  const handleUserItemClick = (item) => {
    setSelectedItem(item);
    setListVisible(false);
  };

  const handleSelectedLeadChange = (value) => {
    setSelectedItem(value);
    // setSelectedOwnerItem(value);
  };

  const getAllAccountDetails = async () => {
    const requestBody = {
      loggedInUserId: loginUserId,
      pageNo: currentPageAccount,
      pageSize: perPageAccount,
    };

    const headersResult = getHeaders(true);

    // Check if headers result indicates an error
    if (headersResult.error) {
      navigate("../"); // Redirect to login if token is missing
      return;
    }
    await axios
      .post(URI.getAllAccountDetails, requestBody, {
        headers: headersResult,
      })
      .then((response) => {
        if (response.data.status === 200) {
          if (!pastViewInfo || pastViewInfo?.view === "") {
            setAccountDetails(response.data.data.allAccountDetails);
          }
          setModalAccountDetail(response.data.data.allAccountDetails);
          setTotalItemsAccount(response.data.data.rowCount);
          // setSelectedAccountItem(response.data.data.allAccountDetails);
        }
      })
      .catch(async (error) => {
        handleApiError(error, navigate);
      });
  };

  const getAllContact = async () => {
    const requestBody = {
      loggedInUserId: loginUserId,
      pageNo: currentPageContact,
      pageSize: perPageContact,
    };

    const headersResult = getHeaders(true);

    // Check if headers result indicates an error
    if (headersResult.error) {
      navigate("../"); // Redirect to login if token is missing
      return;
    }
    await axios
      .post(URI.getAllContact, requestBody, {
        headers: headersResult,
      })
      .then((response) => {
        if (response.data.status === 200) {
          if (!pastViewInfo || pastViewInfo?.view === "") {
            setContactDetails(response.data.data.allContactDetails);
          }
          setModalContactDetails(response.data.data.allContactDetails);
          setTotalItemsContact(response.data.data.rowCount);
          // setSelectedContactItem(response.data.data.allContactDetails[0]);
        } else {
          throw new Error("Failed to fetch data", response.data.message);
        }
      })
      .catch((err) => {
        handleApiError(err, navigate);
      });
  };

  const getAllDealsDetails = async () => {
    const requestBody = {
      loggedInUserId: loginUserId,
      pageNo: currentPageDeal,
      pageSize: perPageDeal,
    };
    const headersResult = getHeaders(true);

    // Check if headers result indicates an error
    if (headersResult.error) {
      navigate("../"); // Redirect to login if token is missing
      return;
    }
    await axios
      .post(URI.getAllDeals, requestBody, {
        headers: headersResult,
      })
      .then((response) => {
        if (response.data.status === 200) {
          if (!pastViewInfo || pastViewInfo?.view === "") {
            setDealDetails(response.data.data.allDealDetails);
          }
          setModalDealDetails(response.data.data.allDealDetails);
          setTotalItemsDeal(response.data.data.rowCount);
        }
      })
      .catch(async (error) => {
        handleApiError(error, navigate);
      });
  };

  const getAllQuote = async (pageNo, selectedOption) => {
    setIsLoading(true);
    setSelectedOption(selectedOption);
    const requestBody = {
      loggedInUserId: loginUserId,
      customFilter: selectedOption.value,
      pageNo: pageNo,
      pageSize: perPage,
    };

    const headersResult = getHeaders(true);

    // Check if headers result indicates an error
    if (headersResult.error) {
      navigate("../"); // Redirect to login if token is missing
      return;
    }

    await axios
      .post(URI.getAllQuote, requestBody, {
        headers: headersResult,
      })

      .then((response) => {
        if (response.data.status === 200) {
          setAllQuoteDetails(response.data.data.allQuotesDetails);
          setFilteredQuoteDetails(response.data.data.allQuotesDetails);
          setTotalItems(response.data.data.rowCount);
          setIsLoading(false);
        } else {
          setIsLoading(false);
        }
      })
      .catch(async (error) => {
        handleApiError(error, navigate);
      });
  };

  useEffect(() => {
    if (storeModuleId) {
      getQuotesOverviewDetail(storeModuleId);
      setQuoteId(storeModuleId);
    }
  }, []);

  const getQuotesOverviewDetail = async (quoteId) => {
    let quoteIndivInfoDetails = {};
    let quotesInfoOverview = {};

    const headersResult = getHeaders(true);

    // Check if headers result indicates an error
    if (headersResult.error) {
      navigate("../"); // Redirect to login if token is missing
      return;
    }

    await axios
      .get(URI.quoteOverview + "/" + loginUserId + "/" + quoteId, {
        headers: headersResult,
      })

      .then((response) => {
        if (response.data.status === 200) {
          setShowOptionsContact(false);
          // quoteOverviewDetails = response.data.data.quotesItemsInfo;
          quoteIndivInfoDetails = response.data.data.getQuotesInfo;
          quotesInfoOverview = response.data.data.getQuotesInfo;

          quoteIndivInfoDetails.dealNameInfo =
            response.data.data.getQuotesInfo.dealName;

          quoteIndivInfoDetails.quotenumberInfo =
            response.data.data.getQuotesInfo.quoteNumber;

          quoteIndivInfoDetails.billingCityInfo =
            response.data.data.getQuotesInfo.billingCity;
          quoteIndivInfoDetails.billingCodeInfo =
            response.data.data.getQuotesInfo.billingCode;
          quoteIndivInfoDetails.teamInfo =
            response.data.data.getQuotesInfo.team;
          quotesInfoOverview.quoteStageInfo = {
            label: response.data.data.getQuotesInfo.quoteStage,
            value: response.data.data.getQuotesInfo.quoteStage,
          };
          quotesInfoOverview.carrierInfo = {
            label: response.data.data.getQuotesInfo.carrier,
            value: response.data.data.getQuotesInfo.carrier,
          };
          quoteIndivInfoDetails.termsAndConditions =
            response.data.data.getQuotesInfo.termsAndConditions;
          quoteIndivInfoDetails.termsAndConditionsInfo =
            response.data.data.getQuotesInfo.termsAndConditions;
          quoteIndivInfoDetails.team = response.data.data.getQuotesInfo.team;
          quoteIndivInfoDetails.billingCountryInfo =
            response.data.data.getQuotesInfo.billingCountry;

          quoteIndivInfoDetails.billingStreetInfo =
            response.data.data.getQuotesInfo.billingStreet;

          quoteIndivInfoDetails.shippingCityInfo =
            response.data.data.getQuotesInfo.shippingCity;

          quoteIndivInfoDetails.shippingCodeInfo =
            response.data.data.getQuotesInfo.shippingCode;

          quoteIndivInfoDetails.shippingCountryInfo =
            response.data.data.getQuotesInfo.shippingCountry;
          quoteIndivInfoDetails.shippingStateInfo =
            response.data.data.getQuotesInfo.shippingState;
          quoteIndivInfoDetails.billingStateInfo =
            response.data.data.getQuotesInfo.billingState;
          quoteIndivInfoDetails.validUntilInfo =
            response.data.data.getQuotesInfo.validUntil;
          quoteIndivInfoDetails.shippingStreetInfo =
            response.data.data.getQuotesInfo.shippingStreet;

          quotesInfoOverview.quoteStage = {
            label: response.data.data.getQuotesInfo.quoteStage,
            value: response.data.data.getQuotesInfo.quoteStage,
          };
          quotesInfoOverview.carrier = {
            label: response.data.data.getQuotesInfo.carrier,
            value: response.data.data.getQuotesInfo.carrier,
          };

          const updatedFields = {
            dealNameInfo: response.data.data.getQuotesInfo.dealName,
            quotenumberInfo: response.data.data.getQuotesInfo.quoteNumber,
            billingCityInfo: response.data.data.getQuotesInfo.billingCity,
            billingCodeInfo: response.data.data.getQuotesInfo.billingCode,
            teamInfo: response.data.data.getQuotesInfo.team,
            quoteStageInfo: {
              label: response.data.data.getQuotesInfo.quoteStage,
              value: response.data.data.getQuotesInfo.quoteStage,
            },
            carrierInfo: {
              label: response.data.data.getQuotesInfo.carrier,
              value: response.data.data.getQuotesInfo.carrier,
            },
            team: response.data.data.getQuotesInfo.team,
            termsAndConditions:
              response.data.data.getQuotesInfo.termsAndConditions,
            termsAndConditionsInfo:
              response.data.data.getQuotesInfo.termsAndConditions,
            billingCountryInfo: response.data.data.getQuotesInfo.billingCountry,
            billingStreetInfo: response.data.data.getQuotesInfo.billingStreet,
            shippingCityInfo: response.data.data.getQuotesInfo.shippingCity,
            shippingCodeInfo: response.data.data.getQuotesInfo.shippingCode,
            shippingCountryInfo:
              response.data.data.getQuotesInfo.shippingCountry,
            shippingStateInfo: response.data.data.getQuotesInfo.shippingState,
            billingStateInfo: response.data.data.getQuotesInfo.billingState,
            validUntilInfo: response.data.data.getQuotesInfo.validUntil,
            shippingStreetInfo: response.data.data.getQuotesInfo.shippingStreet,
          };

          setSelectedItem(
            userList.find(
              (user) =>
                user.userId === response.data.data.getQuotesInfo.quoteOwnerId
            )
          );
          setSelectedDealItem({
            dealName: response.data.data.getQuotesInfo.dealName,
            dealId: response.data.data.getQuotesInfo.dealId,
          });
          setSelectedAccountItem({
            accountName: response.data.data.getQuotesInfo.accountName,
            accountId: response.data.data.getQuotesInfo.accountId,
          });
          setSelectedContactItem({
            contactName: response.data.data.getQuotesInfo.contactName,
            contactId: response.data.data.getQuotesInfo.contactId,
          });

          setUpdatedFields(updatedFields);

          setQuoteItemTotalDetails(response.data.data.quotesItemsTotDtls);
          setQuoteItemInfo(response.data.data.quotesItemsInfo);
          setQuotesInfoOverview(quotesInfoOverview);
          setQuoteAccContactDealData(response.data.data.quotesAccContDealData);
          setCloseActivities(response.data.data.assignCloseActivities);
          setOpenActivitiesCalls(response.data.data.openActivitiesCalls);
          setOpenActivitiesMeetings(response.data.data.openActivitiesMeetings);
          setClosedActivitiesCall(response.data.data.closedActivitiesCalls);
          setClosedActivitiesMeetings(
            response.data.data.closedActivitiesMeetings
          );
          setOpenActivities(response.data.data.assignOpenActivities);
          setSalesOrderList(response.data.data.assignSales);
          setAllProducts(response.data.data.quotesItemsInfo);
        } else {
          NotificationManager.error("", response.data.message, 5000);
        }
      })
      .catch(async (error) => {
        handleApiError(error, navigate);
      });
  };

  const handleAssignModule = async (selectedIds, assignModuleName) => {
    const moduleIdList = selectedIds.map((id) => ({ moduleId: id }));
    const requestBody = {
      loginUserId: loginUserId,
      moduleName: "QUOTES",
      moduleId: quoteId,
      assignModuleName: assignModuleName,
      moduleIdList,
    };

    const headersResult = getHeaders(true);

    // Check if headers result indicates an error
    if (headersResult.error) {
      navigate("../"); // Redirect to login if token is missing
      return;
    }
    try {
      const response = await axios.post(URI.assignRole, requestBody, {
        headers: headersResult,
      });
      if (response.data.status === 200) {
        NotificationManager.success("", response.data.message, 5000);
        getQuotesOverviewDetail(quoteId);
        getNotesDetails(quoteId, selectRecent);
      } else {
        NotificationManager.error("", response.data.message, 5000);
      }
    } catch (error) {
      handleApiError(error, navigate);
    }
  };

  const getAllTimelineQuotes = async () => {
    const headersResult = getHeaders(true);

    // Check if headers result indicates an error
    if (headersResult.error) {
      navigate("../"); // Redirect to login if token is missing
      return;
    }
    await axios
      .get(URI.getQuoteTimeline + "/" + loginUserId + "/" + quoteId, {
        headers: headersResult,
      })
      .then((response) => {
        if (response.data.status === 200) {
          setTimeLineDataList(response.data.data.getQuotesTimeline);
          // setquotePageView("timeline");
        }
      })
      .catch(async (error) => {
        throw new Error("Failed to fetch data", error);
      });
  };

  const handleTextAreaClick = () => {
    setShowButtons(true);
  };

  const handleTextFileChange = (event) => {
    const files = Array.from(event.target.files);
    if (files) {
      setSelectedNoteFiles(files);
    } else {
      setSelectedNoteFiles([]);
    }
  };
  const handleAddTitleClick = () => {
    setShowTitleNote(true);
  };

  const deleteProductDetails = async () => {
    const requestBody = {
      loggedInUserId: loginUserId,
      quoteId: quoteId,
    };

    const headersResult = getHeaders(true);

    // Check if headers result indicates an error
    if (headersResult.error) {
      navigate("../"); // Redirect to login if token is missing
      return;
    }

    await axios
      .post(URI.deleteQuote, requestBody, {
        headers: headersResult,
      })
      .then((response) => {
        if (response.data.status === 200) {
          setShowOptionsContact(false);
          setInnerPage("list");
          setShowModalType("");
          getAllQuote(currentPage, selectedOption);
          NotificationManager.success("", response.data.message, 5000);
        } else {
          NotificationManager.error("", response.data.message, 5000);
          setShowOptionsContact(false);
          setShowModalType("");
        }
      })
      .catch(async (error) => {
        handleApiError(error, navigate);
      });
  };

  const [massUpdateValue, setMassUpdateValue] = useState("");
  const [selectedObj, setSelectedObj] = useState({
    name: "",
    isChecked: false,
    value: "",
  });
  const [selectedCheckboxes, setSelectedCheckboxes] = useState([]);
  const [clickedDropdownObj, setClickedDropdownObj] = useState({
    label: "",
    value: "",
  });
  const [errorMessage, setErrorMessage] = useState("");

  const handleSelectCheckboxChange = (event) => {
    const { value } = event.target;

    const selectedCheckbox = { moduleId: value };

    setSelectedCheckboxes((prevSelectedCheckboxes) => {
      const isAlreadySelected = prevSelectedCheckboxes.some(
        (checkbox) => checkbox.moduleId === value
      );

      if (isAlreadySelected) {
        return prevSelectedCheckboxes.filter(
          (checkbox) => checkbox.moduleId !== value
        );
      } else {
        return [...prevSelectedCheckboxes, selectedCheckbox];
      }
    });
  };

  const deleteUpdateTransferMass = async () => {
    const { value } = selectedObj;
    let url = URI.massDelete;
    const requestBody = {
      loggedInUserId: loginUserId,
      moduleName: "QUOTES",
      massAction: modalType === "Mass Delete" ? "DELETE" : "EDIT",
      moduleIdList: selectedCheckboxes,
    };
    if (modalType === "Mass Update") {
      if (
        (value === "subject" || value === "carrier") &&
        !massUpdateValue.trim()
      ) {
        setErrorMessage("Value can not be empty");
        setTimeout(() => {
          setErrorMessage("");
        }, 3000);
        return;
      }

      requestBody[value] =
        value === "validUntil"
          ? moment(massUpdateValue, ["DD-MM-YYYY", "YYYY-MM-DD"]).format(
              "DD-MM-YYYY"
            )
          : massUpdateValue;
      url = URI.massUpdate;
    } else if (modalType === "Mass Transfer") {
      requestBody.ownerId = selectedItem?.userId;
      url = URI.massTransfer;
    }

    const headersResult = getHeaders(true);

    // Check if headers result indicates an error
    if (headersResult.error) {
      navigate("../"); // Redirect to login if token is missing
      return;
    }
    await axios
      .post(url, requestBody, {
        headers: headersResult,
      })
      .then((response) => {
        if (response.data.status === 200) {
          NotificationManager.success("", response.data.message, 5000);
          getAllQuote(currentPage, selectedOption);
          setSelectedCheckboxes([]);
          setInnerPage("list");
          setShowModalType("");
          setShowSearchFields(fieldDropdowns);
          setMassUpdateValue("");
        } else {
          NotificationManager.error("", response.data.message, 5000);
        }
      })
      .catch(function (error) {
        handleApiError(error, navigate);
      });
  };

  const getNotesDetails = async (quoteId, orderType) => {
    const requestBody = {
      loggedInUserId: loginUserId,
      moduleId: quoteId,
      orderType: orderType,
    };

    const headersResult = getHeaders(true);

    // Check if headers result indicates an error
    if (headersResult.error) {
      navigate("../"); // Redirect to login if token is missing
      return;
    }
    await axios
      .post(URI.getNote, requestBody, {
        headers: headersResult,
      })
      .then((response) => {
        if (response.data.status === 200) {
          setNoteInfo(response.data.data.noteInfo);
        } else {
          NotificationManager.error("", response.data.message, 5000);
        }
      })
      .catch(async (error) => {
        handleApiError(error, navigate);
      });
  };

  const handleSelectRecent = (item) => {
    setSelectRecent(item);
    var value = "";
    if (item === "Recent Last") {
      value = "RECENT_LAST";
    } else if (item === "Recent First") {
      value = "RECENT_FIRST";
    } else {
      value = item;
    }
    getNotesDetails(quoteId, value);
  };

  const insertNote = async () => {
    const formData = new FormData();
    formData.append("moduleId", quoteId);
    formData.append("loggedInUserId", loginUserId);
    formData.append("moduleName", "QUOTES");
    formData.append("noteDescription", note);
    formData.append("noteTitle", addTitle);

    selectedNoteFiles.forEach((file) => {
      formData.append("noteImages", file);
    });
    const headersResult = getHeaders(true);

    // Check if headers result indicates an error
    if (headersResult.error) {
      navigate("../"); // Redirect to login if token is missing
      return;
    }
    await axios
      .post(URI.insertNote, formData, {
        headers: headersResult,
      })
      .then(() => {
        // const newNoteId = response.data.data.noteId; // Extracting noteId from response
        setShowButtons(false);
        setAddTitle("");
        setNote("");
        setSelectedNoteFiles([]);
        // fetchNoteImage(newNoteId); // Pass newNoteId to fetchNoteImage
        getNotesDetails(quoteId, selectRecent);
      })
      .catch((error) => {
        handleApiError(error, navigate);
      });
  };

  const deleteNoteDetails = async (quoteId, noteId) => {
    const formData = new FormData();
    formData.append("moduleId", quoteId);
    formData.append("loggedInUserId", loginUserId);
    formData.append("moduleName", "QUOTES");
    formData.append("noteId", noteId);
    const headersResult = getHeaders(true);

    // Check if headers result indicates an error
    if (headersResult.error) {
      navigate("../"); // Redirect to login if token is missing
      return;
    }
    await axios
      .post(URI.deleteNote, formData, {
        headers: headersResult,
      })
      .then((response) => {
        if (response.data.status === 200) {
          NotificationManager.success("", response.data.message, 3000);
          getNotesDetails(quoteId, selectRecent);
          setNoteDeleteConfirmation(false);
        } else {
          NotificationManager.error("", response.data.message, 5000);
        }
      })
      .catch((error) => {
        handleApiError(error, navigate);
      });
  };

  const updateNote = async (noteId) => {
    const formData = new FormData();
    formData.append("moduleId", quoteId);
    formData.append("loggedInUserId", loginUserId);
    formData.append("moduleName", "QUOTES");
    formData.append("noteDescription", updateNotes.description);
    formData.append("noteTitle", updateNotes.title);
    formData.append("noteId", noteId);
    formData.append("deletedImageNames", deletedImg.toString());

    selectedNoteFiles.forEach((file) => {
      formData.append("noteImages", file);
    });
    const headersResult = getHeaders(true);

    // Check if headers result indicates an error
    if (headersResult.error) {
      navigate("../"); // Redirect to login if token is missing
      return;
    }
    await axios
      .post(URI.updateNote, formData, {
        headers: headersResult,
      })
      .then((response) => {
        if (response.data.status === 200) {
          setShowButtons(false);
          setEditNote(false);
          setUpdateNotes({
            description: "",
            title: "",
            noteImageList: [],
          });
          setSelectedNoteFiles([]);
          getNotesDetails(quoteId, selectRecent);
          setDeletedImg("");
        } else {
          setDeletedImg("");
          setSelectedNoteFiles([]);
          NotificationManager.error("", response.data.message, 5000);
        }
      })
      .catch((error) => {
        handleApiError(error, navigate);
      });
  };

  const handleChangeNoteTitle = (e) => {
    const { name, value } = e.target;
    if (value.length === 1 && value[0] === ' ') {
      return; 
    }
    if (editNote) {
      setUpdateNotes((prevLeadInfo) => ({
        ...prevLeadInfo,
        [name]: value,
      }));
    } else {
      setAddTitle(value);
    }
  };

  const handleChildDataChange = (dataFromChild) => {
    let deletedImg = dataFromChild.toString("");
    setDeletedImg(deletedImg);
  };

  const handleChangeNoteDescription = (e) => {
    const { name, value } = e.target;
    if (value.length === 1 && value[0] === ' ') {
      return; 
    }
    if (editNote) {
      setUpdateNotes((prevLeadInfo) => ({
        ...prevLeadInfo,
        [name]: value,
      }));
    } else {
      setNote(value); // Directly set the input value
    }
  };

  const scrollToSection = (sectionId) => {
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: "smooth" });
    }
  };

  const sortedColumnsData = (type, headingName) => {
    const sortedData = [...allQuoteDetails];

    if (type === "asc") {
      sortedData.sort((a, b) => {
        if (
          typeof a[headingName] === "string" &&
          typeof b[headingName] === "string"
        ) {
          return a[headingName].localeCompare(b[headingName]);
        }
        return a[headingName] - b[headingName];
      });
    } else if (type === "desc") {
      sortedData.sort((a, b) => {
        if (
          typeof a[headingName] === "string" &&
          typeof b[headingName] === "string"
        ) {
          return b[headingName].localeCompare(a[headingName]);
        }
        return b[headingName] - a[headingName];
      });
    }
    setFilteredQuoteDetails(sortedData);
  };

  const handlePinColumn = (name) => {
    setPinnedColumn(name);
    const updatedColumns = [...selectedHeadings];
    const clickedColumnIndex = updatedColumns.findIndex(
      (column) => column.name === name
    );

    if (clickedColumnIndex !== -1) {
      const clickedColumn = updatedColumns.splice(clickedColumnIndex, 1)[0];
      updatedColumns.unshift(clickedColumn);
      setSelectedHeadings(updatedColumns);
    }
  };

  const handleAssignSalesModule = (selectedSalesId) => {
    handleAssignModule(selectedSalesId, "SALES ORDERS");
  };

  const handleUpdateClick = async (field) => {
    var value = "";
    const isObj =
      typeof quotesInfoOverview[field] === "object" &&
      quotesInfoOverview[field] !== null &&
      !Array.isArray(quotesInfoOverview[field]);

    if (
      field === "quoteStage" ||
      field === "carrier" ||
      field === "quoteStageInfo" ||
      field === "carrierInfo"
    ) {
      value = updatedFields[field].value;
    } else {
      value = updatedFields[field];
    }

    // let isDate = !isNaN(Date.parse(value));

    const fieldMappings = {
      dealNameInfo: "dealName",
      quotenumberInfo: "quoteNumber",
      billingCityInfo: "billingCity",
      billingCodeInfo: "billingCode",
      teamInfo: "team",
      carrierInfo: "carrier",
      quoteStageInfo: "quoteStage",
      termsAndConditionsInfo: "termsAndConditions",
      billingCountryInfo: "billingCountry",
      billingStreetInfo: "billingStreet",
      shippingCityInfo: "shippingCity",
      shippingCodeInfo: "shippingCode",
      shippingCountryInfo: "shippingCountry",
      shippingStateInfo: "shippingState",
      billingStateInfo: "billingState",
      validUntilInfo: "validUntil",
      shippingStreetInfo: "shippingStreet",
    };
    // if (fieldMappings.hasOwnProperty(field)) {
    //   field = fieldMappings[field];
    // } not supported by eslint
    if (Object.hasOwn(fieldMappings, field)) {
      field = fieldMappings[field];
    }
    setApiLoader(true);
    try {
      const requestBody = {
        quoteId: quoteId,
        [field]: value,
        // [field]: isDate ? value.split("-").reverse().join("-") : value,
        quoteOwnerId: isOwnerSelect
          ? selectedOwnerItem?.userId
          : selectedItem?.userId,
        loggedInUserId: loginUserId,
        // productOwnerId: selectedItem.userId,
      };

      const compareValue = isObj
        ? quotesInfoOverview[field].value
        : quotesInfoOverview[field];

      if (value !== compareValue || field === "emailNotification") {
        const headersResult = getHeaders(true);
        // Check if headers result indicates an error
        if (headersResult.error) {
          navigate("../"); // Redirect to login if token is missing
          return;
        }
        const response = await axios.post(
          URI.individualUpdateQuote,
          requestBody,
          {
            headers: headersResult,
          }
        );
        if (response.data.status === 200) {
          NotificationManager.success("", response.data.message, 5000);

          setQuotesInfoOverview((prevDetails) => ({
            ...prevDetails,
            [field]: updatedFields[field],
          }));
          setShowModalType("");
          getQuotesOverviewDetail(quoteId);
          getNotesDetails(quoteId, selectRecent);
          // setQuotesInfoOverview(quoteId);
        } else {
          NotificationManager.error("", response.data.message, 5000);
        }

        const resetFields = Object.fromEntries(
          Object.keys(editingFields).map((key) => [key, false])
        );
        setEditingFields({
          ...resetFields,
        });
        setShowType("");
      }
    } catch (error) {
      handleApiError(error, navigate);
    } finally {
      setApiLoader(false);
    }
  };
  const handleIndividualInputChange = (field, value) => {
    setUpdatedFields((prevFields) => ({
      ...prevFields,
      [field]: value,
    }));
  };

  const handleEditClick = (field) => {
    setEditingFields((prevFields) => ({
      ...prevFields,
      [field]: true,
    }));
  };

  const handleCancelClick = (field) => {
    setEditingFields((prevFields) => ({
      ...prevFields,
      [field]: false,
    }));

    setUpdatedFields((prevLeadInfo) => ({
      ...prevLeadInfo,
      [field]:
        field === "status"
          ? {
              value: quotesInfoOverview[field],
              label: quotesInfoOverview[field],
            }
          : quotesInfoOverview[field],
    }));
  };

  const getAllInfoShare = async (quoteId) => {
    setIsLoading(true);
    const requestBody = {
      moduleId: quoteId,
      loggedInUserId: loginUserId,
    };
    const headersResult = getHeaders(true);

    // Check if headers result indicates an error
    if (headersResult.error) {
      navigate("../"); // Redirect to login if token is missing
      return;
    }
    await axios
      .post(URI.getShareInfo, requestBody, {
        headers: headersResult,
      })
      .then((response) => {
        if (response.data.status === 200) {
          const hasSharedItems =
            (response.data.data.getSharingInfoUsers &&
              response.data.data.getSharingInfoUsers.length > 0) ||
            (response.data.data.getSharingInfoRoles &&
              response.data.data.getSharingInfoRoles.length > 0) ||
            (response.data.data.getSharingInfoGroups &&
              response.data.data.getSharingInfoGroups.length > 0)||
              (response.data.data.getSharingInfoPublic &&
                response.data.data.getSharingInfoPublic.length > 0);
          const userShareMemberIds = response.data.data.getSharingInfoUsers.map(
            (user) => user.shareModuleId
          );
          const roleShareMemberIds = response.data.data.getSharingInfoRoles.map(
            (role) => role.shareModuleId
          );
          const groupShareMemberIds =
            response.data.data.getSharingInfoGroups.map(
              (group) => group.shareModuleId
            );
            const groupPublicSharedMemberIds =
            response.data.data.getSharingInfoPublic.map(
              (user) => user.shareModuleId
            )
          setShareMemberIds([
            ...userShareMemberIds,
            ...roleShareMemberIds,
            ...groupShareMemberIds,
            ...groupPublicSharedMemberIds
          ]);

          setShareUserList(response.data.data.getSharingInfoUsers);
          setShareRoleList(response.data.data.getSharingInfoRoles);
          setShareGroupList(response.data.data.getSharingInfoGroups);
          setSharePublicList(response.data.data.getSharingInfoPublic);
          if (hasSharedItems) {
            setShareInfoModal(true);
          } else {
            setShareModal(true);
          }
          setIsLoading(false);
        }
      })
      .catch((err) => {
        handleApiError(err, navigate);
      });
  };

  // for Account Name List dropdown
  const handleAccountClick = (e) => {
    e.stopPropagation();
    setAccountListVisible(!accountListVisible);
    setListVisible(false);
    setDealListVisible(false);
    setContactListVisible(false);
  };

  const handleAccountItemClick = (item) => {
    setSelectedAccountItem(item);
    setAccountListVisible(false);
    getAllAssignModules("ACCOUNTS", ["DEALS", "CONTACTS"], item.accountId);
  };

  // for Contact Name list
  const handleContactClick = (e) => {
    e.stopPropagation();

    setContactListVisible(!contactListVisible);
    setListVisible(false);
    setDealListVisible(false);
    setAccountListVisible(false);
  };

  const handleContactItemClick = (item) => {
    setSelectedContactItem(item);
    setContactListVisible(false);
    getAllAssignModules("CONTACTS", ["DEALS", "ACCOUNTS"], item.contactId);
  };

  // for Deal Name

  const handleDealClick = (e) => {
    e.stopPropagation();
    setDealListVisible(!dealListVisible);
    setListVisible(false);
    setAccountListVisible(false);
    setContactListVisible(false);
  };

  const handleDealItemClick = (item) => {
    setSelectedDealItem(item);
    setDealListVisible(false);
    getAllAssignModules("DEALS", ["ACCOUNTS", "CONTACTS"], item.dealId);

    if (existingQuote || existingClone) {
      setQuoteUpdateInfo((prevLeadInfo) => ({
        ...prevLeadInfo,
        subject: item.dealName,
      }));
    } else {
      setQuoteInfo((prevPurchaseInfo) => ({
        ...prevPurchaseInfo,
        subject: item.dealName,
      }));
    }
  };

  // for ProductListModal

  const handleSelectedProductListChange = (selectedProduct) => {
    setSelectedProduct({
      productId: selectedProduct.productId,
      productName: selectedProduct.productName,
    });
    // setSelectedProductItem(selectedProduct);
    const updatedProducts = [...allProducts];
    updatedProducts[selectedProductIndex] = {
      ...updatedProducts[0],
      productId: selectedProduct.productId,
      productName: selectedProduct.productName,
    };
    setAllProducts(updatedProducts);
  };

  const handleFieldClick = (field) => {
    if (field.type === "dropdown") {
      const fieldValue = field.name
        .replace(/\s+/g, "")
        .replace(/^(.)/, (match) => match.toLowerCase());

      // setClickedDropdownObj(leadOverviewDetails[fieldValue]);
      // setUpdatedFields -- need to update
      setUpdatedFields((prevFields) => ({
        ...prevFields,
        [fieldValue]: quotesInfoOverview[fieldValue],
      }));
    } else if (field.type === "checkbox") {
      setUpdatedFields((prevFields) => ({
        ...prevFields,
        emailOptOut: quotesInfoOverview["emailOptOut"],
      }));
    }

    const updatedColumns = [...fieldLabels];

    const existedObj = updatedColumns.find(
      (field) => field.name === selectedField
    );
    const selectedObj = updatedColumns.find((item) => item.name === field.name);

    const existedObjIndex = updatedColumns.findIndex(
      (field) => field.name === existedObj.name
    );
    const selectedObjIndex = updatedColumns.findIndex(
      (field) => field.name === selectedObj.name
    );

    updatedColumns[existedObjIndex].isShowed = false;
    updatedColumns[selectedObjIndex].isShowed = true;

    const removedObj = updatedColumns.splice(existedObjIndex, 1)[0];
    updatedColumns.splice(existedObjIndex, 0, selectedObj);

    updatedColumns.push(removedObj);
    const uniqueData = updatedColumns.filter(
      (item, index, self) =>
        index === self.findIndex((t) => t.name === item.name)
    );
    setFieldLabels(uniqueData);
    setShowSearchFields(uniqueData);
  };

  const handleChangeIndDropDown = (selectedOption, field) => {
    setUpdatedFields((prevFields) => ({
      ...prevFields,
      [field]: selectedOption,
    }));
  };

  const returnTotalValue = (item) => {
    const discountedAmount = item.unitPrice * item.unitPrice - 0;
    const taxAmount =
      (parseFloat(isNaN(item.tax) ? 0 : item.tax) / 100) * discountedAmount;
    return (discountedAmount + taxAmount).toFixed(2);
  };

  useEffect(() => {
    if (fetchQuoteInfo && innerPage === "overview") {
      getQuotesOverviewDetail(fetchQuoteInfo?.quoteId);
      setQuoteId(fetchQuoteInfo?.quoteId);
    }
  }, []);

  const nodataPage = () => {
    return (
      <div className="d-flex flex-column justify-content-center align-items-center  ">
        <img src={quoteNodata} alt="img" height={200} width={200} />
        <h5 className="opacity-75">Manage Quote</h5>
        <p className="opacity-75 p-0 m-0">
          Quotes are legal agreements between a customer and a vector
        </p>
        <p className="opacity-75 p-0 m-0">
          to deliver the requested product(s) at the agreed upon price
        </p>
        <p className="opacity-75 p-0 m-0">within the specified time period.</p>
        <button
          className="createContactButtonSave px-2 mt-4 cursorPointer rounded-3"
          style={{ width: "15%" }}
          onClick={() => setInnerPage("create")}
        >
          Create a Quote
        </button>
      </div>
    );
  };

  const quoteHomePage = () => {
    return (
      <>
        <div>
          {isLoading ? (
            <>
              <Loader />
            </>
          ) : filteredQuoteDetails && filteredQuoteDetails.length > 0 ? (
            <>
              <div className="creteLeadBar">
                {selectedCheckboxes.length > 0 ? (
                  <div className="d-flex w-50 align-items-center gap-2">
                    <Select
                      options={massOptions}
                      styles={customCommonStyles("100%")}
                      value={{
                        value: "Action",
                        label: "Action",
                      }}
                      onChange={(option) => {
                        // setDropdownTwo(option);
                        if (option.value === "Mass Update") {
                          setShowSearchFields(quotesColumn);
                          setSelectedObj(quotesColumn[0]);
                        } else if (option.value === "Mass Transfer") {
                          setSelectedItem(userList[0]);
                        }
                        setMassUpdateValue("");
                        setShowModalType(option.value);
                      }}
                      className="w-25"
                      isSearchable={false}
                    />
                  </div>
                ) : (
                  <div className="d-flex w-50 align-items-center gap-2">
                    {/* <img
                          src={filterView ? filterIcon : filterIconCross}
                          height={25}
                          className="leadFilter"
                          alt="img"
                        /> */}
                    <Select
                      options={options}
                      styles={customCommonStyles("190px")}
                      value={selectedOption}
                      onChange={handleChange}
                      isSearchable={false}
                    />
                  </div>
                )}
                <div className="d-flex w-50 justify-content-end gap-2">
                  <div className="btn-group">
                    <button
                      className="createContactButtonSave py-2"
                      // style={{
                      //   background:
                      //     "linear-gradient(86.07deg, #407BFF 0%, #12A7FE 100%)",
                      //   border: "none",
                      //   borderRight: "2px solid #fff",
                      //   color: "#fff",
                      // }}
                      onClick={handleCreateQuote}
                    >
                      Create Quote
                    </button>
                  </div>
                </div>
              </div>
              <div className="totalRecordBar">
                <div className="d-flex w-50">
                  <h6>
                    {" "}
                    {selectedCheckboxes.length > 0
                      ? `${selectedCheckboxes.length} Records Selected`
                      : `Total Records : ${totalItems}`}{" "}
                  </h6>
                </div>
                <div className="d-flex w-50 align-items-center justify-content-end gap-2">
                  {/* <Select
              options={options}
              styles={customSelectLocationStyles}
              value={selectedOption}
              onChange={handleChange}
            /> */}
                  {/* <p className="mb-0">· ‹1/10›</p> */}
                  {selectedCheckboxes.length === 0 && (
                    <Select
                      options={pageOption}
                      styles={customCommonStyles("100%")}
                      isSearchable={false}
                      value={pageOption.find(
                        (option) => option.value === perPage.toString()
                      )}
                      onChange={handlePageDropDowmChange}
                    />
                  )}
                  <Pagination
                    currentPage={currentPage}
                    totalPages={totalPages}
                    onPageChange={handlePageChange}
                  />
                </div>
              </div>
              <div className="leadMain">
                {/* {filterView ? (
                  <div className="filterLead">
                    <h6 style={{ borderBottom: "2px solid #316AFF" }}>
                      Filter by Sales
                    </h6>
                    <input
                      type="text"
                      placeholder="Search"
                      className="filterSearch"
                    />
                    <div className="checkboxItem">
                      <h6 style={{ color: "#515151" }}>
                        ▾ System Defined Filters
                      </h6>
                      <input
                        type="checkbox"
                        id="vehicle1"
                        name="vehicle1"
                        value="Bike"
                      />
                      <label htmlFor="vehicle1"> Touched Record</label>
                      <br />
                      <input
                        type="checkbox"
                        id="vehicle1"
                        name="vehicle1"
                        value="Bike"
                      />
                      <label htmlFor="vehicle1"> Untouched Record</label>
                      <br />
                      <input
                        type="checkbox"
                        id="vehicle1"
                        name="vehicle1"
                        value="Bike"
                      />
                      <label htmlFor="vehicle1"> Record Action</label>
                      <br />
                      <h6 style={{ color: "#515151", marginTop: "10px" }}>
                        ▾ Filter By Fields
                      </h6>
                      <input
                        type="checkbox"
                        id="vehicle1"
                        name="vehicle1"
                        value="Bike"
                      />
                      <label htmlFor="vehicle1"> Annual Revenue</label>
                      <br />
                      <input
                        type="checkbox"
                        id="vehicle1"
                        name="vehicle1"
                        value="Bike"
                      />
                      <label htmlFor="vehicle1"> City</label>
                      <br />
                      <input
                        type="checkbox"
                        id="vehicle1"
                        name="vehicle1"
                        value="Bike"
                      />
                      <label htmlFor="vehicle1"> Company</label>
                      <br />
                    </div>
                  </div>
                ) : (
                  <></>
                )} */}
                <div
                  className={
                    filterIcon ? "leadListTableFullWidth" : "leadListTable"
                  }
                >
                  <table className="table leadTable">
                    <thead>
                      <tr>
                        <th scope="col">
                          <input
                            className="cursorPointer"
                            type="checkbox"
                            id="all"
                            name="all"
                            value="All"
                            checked={filteredQuoteDetails.every((selected) =>
                              selectedCheckboxes.some(
                                (list) => list.moduleId === selected.quoteId
                              )
                            )}
                            onChange={(e) => {
                              const allList = filteredQuoteDetails.map(
                                (item) => ({
                                  moduleId: item.quoteId,
                                })
                              );
                              if (e.target.checked) {
                                const newSelections = allList.filter(
                                  (newItem) =>
                                    !selectedCheckboxes.some(
                                      (existingItem) =>
                                        existingItem.moduleId ===
                                        newItem.moduleId
                                    )
                                );
                                setSelectedCheckboxes((prev) => [
                                  ...prev,
                                  ...newSelections,
                                ]);
                              } else {
                                setSelectedCheckboxes(
                                  selectedCheckboxes.filter(
                                    (aItem) =>
                                      !allList.some(
                                        (bItem) =>
                                          aItem.moduleId === bItem.moduleId
                                      )
                                  )
                                );
                              }
                            }}
                            // checked={
                            //   selectedCheckboxes.length ===
                            //   filteredQuoteDetails.length
                            // }
                            // onChange={(e) => {
                            //   if (e.target.checked) {
                            //     const allList = filteredQuoteDetails.map(
                            //       (item) => ({
                            //         moduleId: item.quoteId,
                            //       })
                            //     );
                            //     setSelectedCheckboxes(allList);
                            //   } else {
                            //     setSelectedCheckboxes([]);
                            //   }
                            // }}
                          />
                        </th>
                        {/* <th scope="col">Subject</th>
                  <th scope="col">Status</th>
                  <th scope="col">Quote Date</th>
                  <th scope="col">Grand Total</th>
                  <th scope="col">Contact Name</th>
                  <th scope="col">Account Name</th>
                  <th scope="col">Quote Owner</th> */}
                        {selectedHeadings.map(
                          (heading) =>
                            heading.isChecked && (
                              <th scope="col" key={heading.name}>
                                <span className="pe-3">{heading.name}</span>
                                <span className="position-relative">
                                  <img
                                    src={tableFilterIcon}
                                    alt=""
                                    className="cursorPointer"
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      setShowHeadings(false);
                                      setShowSelectedFilter(heading.name);
                                    }}
                                  />
                                  {heading.name === showSelectedFilter && (
                                    <span
                                      className="position-absolute rounded-2 border bg-light top-0 mt-4"
                                      style={{ right: "-10%" }}
                                    >
                                      <span className="d-flex flex-column px-1">
                                        {/* Ascending filter */}
                                        <span
                                          className="my-1 px-1 cursorPointer fw-normal text-start filter_type"
                                          onClick={() => {
                                            if (filterType === "asc") {
                                              setFilterType("");
                                              setFilteredQuoteDetails(
                                                allQuoteDetails
                                              );
                                            } else {
                                              setFilterType("asc");
                                              sortedColumnsData(
                                                "asc",
                                                heading.value
                                              );
                                            }
                                          }}
                                        >
                                          {filterType === "asc" ? (
                                            <img
                                              src={cancel}
                                              alt="asc"
                                              className="opacity-75 pe-1"
                                            />
                                          ) : (
                                            <img
                                              src={arrowUp}
                                              alt="asc"
                                              className="opacity-75 pe-1"
                                            />
                                          )}
                                          Asc
                                        </span>
                                        {/* Descending filter */}
                                        <span
                                          className="my-2 px-1 cursorPointer fw-normal text-start filter_type"
                                          onClick={() => {
                                            if (filterType === "desc") {
                                              setFilterType("");
                                              setFilteredQuoteDetails(
                                                allQuoteDetails
                                              );
                                            } else {
                                              setFilterType("desc");
                                              sortedColumnsData(
                                                "desc",
                                                heading.value
                                              );
                                            }
                                          }}
                                        >
                                          {filterType === "desc" ? (
                                            <img
                                              src={cancel}
                                              alt="desc"
                                              className="opacity-75 pe-1"
                                            />
                                          ) : (
                                            <img
                                              src={arrowDown}
                                              alt="desc"
                                              className="opacity-75 pe-1"
                                            />
                                          )}
                                          Desc
                                        </span>
                                        <span
                                          className={`my-1 px-1 fw-normal text-start filter_type ${
                                            selectedHeadings[0].name ===
                                              "Subject" &&
                                            heading.name === "Subject"
                                              ? "opacity-50"
                                              : "cursorPointer"
                                          }`}
                                          onClick={() => {
                                            if (pinnedColumn !== "Subject") {
                                              setPinnedColumn("Subject");
                                              handlePinColumn("Subject");
                                            } else {
                                              setPinnedColumn(heading.name);
                                              handlePinColumn(heading.name);
                                            }
                                          }}
                                        >
                                          <img
                                            src={
                                              pinnedColumn === heading.name
                                                ? unPin
                                                : pin
                                            }
                                            alt="pin"
                                            className="opacity-75 pe-1"
                                          />{" "}
                                          {pinnedColumn === heading.name
                                            ? "Unpin"
                                            : "Pin"}{" "}
                                          Column
                                        </span>
                                      </span>
                                    </span>
                                  )}
                                  <img
                                    src={filterLine}
                                    alt=""
                                    className="ps-2"
                                  />
                                </span>
                              </th>
                            )
                        )}

                        <th
                          scope="col"
                          className="text-end cursorPointer position-relative"
                        >
                          <img
                            src={filterMenu}
                            alt=""
                            className={`filter_menu ${
                              showHeadings ? "bg-light" : ""
                            }`}
                            onClick={(e) => {
                              e.stopPropagation();
                              setShowSelectedFilter("");
                              setShowHeadings(true);
                              setSearchColumn("");
                            }}
                          />
                          {showHeadings && (
                            <div
                              className="position-absolute rounded-2  border border-primary bg-light"
                              style={{
                                right: "0.2rem",
                              }}
                            >
                              <div className="d-flex flex-column px-2">
                                <span
                                  className="p-2 cursorPointer fw-normal text-start"
                                  onClick={() => {
                                    setShowModalType("Manage Columns");
                                  }}
                                >
                                  Manage Columns
                                </span>
                                <span
                                  className="p-2 fw-normal text-start opacity-50"
                                  onClick={() => {
                                    setShowModalType("Reset Column Size");
                                  }}
                                >
                                  Reset Column Size
                                </span>
                              </div>
                            </div>
                          )}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {filteredQuoteDetails &&
                        filteredQuoteDetails.length > 0 &&
                        filteredQuoteDetails.map((item, index) => {
                          return (
                            <tr
                              style={{ cursor: "pointer" }}
                              onClick={() => {
                                // getContactOverview(item.contactId);
                              }}
                              key={index}
                            >
                              <th scope="col">
                                <input
                                  className="cursorPointer"
                                  type="checkbox"
                                  id="vehicle1"
                                  name="vehicle1"
                                  value={item.quoteId}
                                  checked={selectedCheckboxes.some(
                                    (checkbox) =>
                                      checkbox.moduleId === item.quoteId
                                  )}
                                  onChange={handleSelectCheckboxChange}
                                />
                              </th>
                              {/* <td>{item.quoteName}</td>
                          <td>{item.quoteCode}</td>
                          <td>
                            <input
                              type="checkbox"
                              name="quoteActive"
                              checked={item.quoteActive}

                            />
                          </td>
                          <td>{item.quoteOwner}</td> */}
                              <React.Fragment>
                                {selectedHeadings.map((obj, i) => {
                                  const value = obj.value;
                                  const itemValue = item[value];

                                  return obj.isChecked ? (
                                    value === "transHash" ? (
                                      <td
                                        style={{ wordBreak: "break-all" }}
                                        className="overflow_text_hash"
                                        key={i}
                                      >
                                        <a
                                          rel="noreferrer"
                                          href={
                                            EXPLORER_URL +
                                            "about-tx-hash/" +
                                            item.transHash
                                          }
                                          className="anchorLinkHash textColor"
                                          data-title={item.transHash}
                                          target="_blank"
                                        >
                                          {item.transHash
                                            ? item.transHash.slice(0, 10) +
                                              "...."
                                            : "-"}
                                        </a>
                                      </td>
                                    ) : (
                                      <td
                                        key={i}
                                        onClick={() => {
                                          if (value === "subject") {
                                            setInnerPage("overview");
                                            getQuotesOverviewDetail(
                                              item.quoteId
                                            );
                                            setQuoteId(item.quoteId);
                                            setPurchaseOrdId(item.quoteId);
                                            getNotesDetails(
                                              item.quoteId,
                                              selectRecent
                                            );
                                            setFetchQuoteInfo(item);
                                            setSelectedQuoteIndex(
                                              allQuoteDetails.findIndex(
                                                (lead) =>
                                                  lead.quoteId === item.quoteId
                                              )
                                            );
                                          }
                                        }}
                                        className={`overflow_text ${
                                          value === "subject"
                                            ? "subject cursorPointer"
                                            : ""
                                        }`}
                                        style={{ maxWidth: "3vw" }}
                                      >
                                        {itemValue !== null ? itemValue : "-"}
                                      </td>
                                    )
                                  ) : null;
                                })}
                              </React.Fragment>
                              <td></td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>
                </div>
              </div>
            </>
          ) : (
            <>{nodataPage()}</>
          )}
        </div>
      </>
    );
  };
  const createQuotePage = () => {
    return (
      <>
        <div className="createLeadButtonBar">
          <div className="d-flex align-items-center w-50 gap-2">
            <img src={groupIcon} height={25} alt="img" />
            <div className="d-flex flex-column">
              <p
                className="mb-0 first_letter_uppercase"
                // style={{
                //   fontfamily: "Lexen",
                //   fontSize: "22px",
                //   fontWeight: "500",
                //   lineHeight: "27.5px",
                //   textAlign: "left",
                // }}
              >
                {existingQuote ? "Edit" : innerPage} Quote
              </p>
            </div>
          </div>
          <div className="d-flex w-50 gap-2 justify-content-end">
            <button
              className="createLeadButton"
              onClick={() => {
                if (pastViewInfo) {
                  setCurrentView(pastViewInfo.view);
                  setInnerPage(pastViewInfo.innerPage);
                  setPastViewInfo((prev) => ({
                    ...prev,
                    view: "",
                    innerPage: "",
                    undo: currentView, // it will set the current view of the page so when we cancel or save it will go to same section in the page
                  }));
                  return;
                }
                if (existingQuote) {
                  setInnerPage("overview");
                } else {
                  setInnerPage("list");
                }
                if (innerPage === "clone" && !existingQuote) {
                  setInnerPage("overview");
                }
                setSelectedContactItem(null);
                setSelectedDealItem(null);
                resetForm();
              }}
            >
              Cancel
            </button>

            {existingQuote ? (
              <></>
            ) : (
              <button
                className="createLeadButton"
                onClick={() => handleInsertQuote("SAVE AND NEW")}
                style={{ width: "175px" }}
                disabled={saveAndNewSubmit}
              >
                {saveAndNewSubmit ? (
                  <Lottie
                    options={defaultOptions("blue")}
                    height="24px"
                    width="36px"
                  />
                ) : (
                  "Save and New"
                )}
              </button>
            )}

            <button
              className={`save-button`}
              type="submit"
              onClick={() => {
                if (existingQuote) {
                  handleUpdateQuote("UPDATE");
                } else if (existingClone) {
                  handleInsertQuote("ADD");
                } else {
                  handleInsertQuote("SAVE");
                }
              }}
              disabled={saveSubmit}
            >
              {saveSubmit ? (
                <Lottie
                  options={defaultOptions("white")}
                  height="30px"
                  width="36px"
                />
              ) : (
                updateButtonLabel
              )}
            </button>
          </div>
        </div>

        <div className="leadFields ">
          <h6
            className="py-2 "
            style={{
              borderBottom: "2px solid #316AFF",
              fontSize: "18px",
              color: "#515151",
              marginBottom: "0px",
            }}
          >
            Quote Information
          </h6>
          {/* <form onSubmit={handleSubmitLead}> */}

          <div className="inputFieldDiv">
            <div className="inputFieldDivLeft position-relative">
              <p className="mb-0">
                Quotes Owner<span className="text-danger">*</span>
              </p>
              <div className="d-flex input-with-clear  ">
                <input
                  type="text"
                  //   placeholder="Vendor Owner"
                  className="leadInputField cursorPointer"
                  value={selectedItem?.name}
                  onClick={handleOwnerInputClick}
                  readOnly
                />

                {existingQuote ? (
                  <></>
                ) : (
                  <>
                    <span
                      className="clear-button"
                      onClick={() => selectedItem && setSelectedItem("")}
                    >
                      <img
                        src={listVisible ? up : down}
                        alt="img"
                        height={20}
                        width={20}
                      />
                    </span>
                  </>
                )}

                <span
                  className="cursorPointer pt-2 ps-1"
                  onClick={() => {
                    if (existingQuote) {
                      setShowModalType("changeOwner");
                      // setListVisible(false);
                    } else {
                      setIsLeadOwnerModal(!isLeadOwnerModal);
                    }
                  }}
                >
                  <img src={userSearch} alt="img" width={20} height={20} />
                </span>
              </div>
              {existingQuote ? (
                <></>
              ) : (
                <>
                  {listVisible && (
                    <ul
                      className="data-list list-group  lead_ownerList_container position-absolute"
                      style={{ top: "6rem" }}
                    >
                      {userList.map((item, index) => (
                        <li
                          key={index}
                          className="cursorPointer list-group-item"
                          onClick={() => handleUserItemClick(item)}
                        >
                          <div className="d-flex flex-column">
                            <span style={{ fontWeight: "500" }}>
                              {" "}
                              {item.name}{" "}
                            </span>
                            <span
                              style={{ fontSize: "14px", color: "#999999" }}
                            >
                              {" "}
                              {item.emailId}{" "}
                            </span>
                          </div>
                        </li>
                      ))}
                    </ul>
                  )}
                </>
              )}
            </div>
            <div className="inputFieldDivRight position-relative">
              <p className="mb-0">Deal Name </p>
              <div
                className="d-flex input-with-clear  "
                onClick={handleDealClick}
              >
                <input
                  type="text"
                  //   placeholder="Vendor Owner"
                  className="leadInputField cursorPointer"
                  value={
                    isDealListModal || isContactListModal || isAccountListModal
                      ? selectedDealItem?.moduleName
                      : selectedDealItem?.dealName
                  }
                  // onClick={handleDealClick}
                  readOnly
                />
                <span
                  className="clear-button"
                  onClick={() => selectedDealItem && setSelectedDealItem("")}
                >
                  <img
                    src={dealListVisible ? up : down}
                    alt="img"
                    height={20}
                    width={20}
                  />
                </span>
                <span
                  className="cursorPointer pt-2 ps-1"
                  onClick={() => setIsDealListModal(!isDealListModal)}
                >
                  <img src={dealsIcon} alt="img" width={20} height={20} />
                </span>
              </div>
              {dealListVisible && dealDetails.length > 0 && (
                <ul
                  className="data-list list-group  lead_ownerList_container position-absolute"
                  style={{ top: "6rem" }}
                >
                  {dealDetails?.map((item, index) => (
                    <li
                      key={index}
                      className="cursorPointer list-group-item"
                      onClick={() => handleDealItemClick(item)}
                    >
                      <div className="d-flex ">
                        <span> {item.dealName}</span>
                      </div>
                    </li>
                  ))}
                </ul>
              )}
            </div>
          </div>

          <div className="inputFieldDiv">
            <div className="inputFieldDivLeft">
              <p className="mb-0">
                {" "}
                Subject<span className="text-danger">*</span>
              </p>
              <input
                type="text"
                className={
                  mandatoryMsg ? "mandatoryField" : "insertCommonInputField"
                }
                name="subject"
                style={{
                  width: "60%",
                  border: "1px solid #B9B9B94D",
                  height: "40px",
                  outline: "none",
                  borderRadius: "10px",
                }}
                value={
                  existingQuote || existingClone
                    ? quoteUpdateInfo.subject
                    : quoteInfo.subject
                }
                onChange={handleChangeQuotes}
                onKeyDown={handleKeyDown}
                maxLength={100}
              />
              <p className="text-danger">{mandatoryMsg && mandatoryMsg}</p>{" "}
            </div>
            <div className="inputFieldDivRight">
              <p className="mb-0">Valid Until</p>
              <input
                type="date"
                className="insertCommonInputField"
                name="validUntil"
                style={{
                  width: "60%",
                  border: "1px solid #B9B9B94D",
                  height: "40px",
                  outline: "none",
                  borderRadius: "10px",
                }}
                value={
                  existingClone || existingQuote
                    ? convertDateFormat(quoteUpdateInfo.validUntil)
                    : quoteInfo.validUntil
                    ? convertDateFormat(quoteInfo.validUntil)
                    : ""
                  // ? quoteUpdateInfo.validUntil
                  // : quoteInfo.validUntil
                }
                onChange={handleChangeQuotes}
                onKeyDown={(e) => e.preventDefault()}
                min="2000-01-01"
                max="2050-12-31"
              />
            </div>
          </div>
          <div className="inputFieldDiv">
            <div className="inputFieldDivLeft">
              <p className="mb-0">Quote Stage</p>
              <Select
                value={
                  existingQuote || existingQuote
                    ? quoteUpdateInfo.quoteStage
                    : quoteInfo.quoteStage
                }
                // value={
                //   existingQuote
                //     ? stageOptions.find(
                //         (option) =>
                //           option.value === quoteUpdateInfo.quoteStage.value
                //       )
                //     : stageOptions.find(
                //         (option) => option.value === quoteInfo.quoteStage.value
                //       )
                // }
                menuPlacement="auto"
                onChange={(selectedOption) =>
                  handleChangeSelectDropDown(selectedOption, "quoteStage")
                }
                options={stageOptions}
                styles={createQuoteStyles}
                isSearchable={false}
              />
            </div>
            <div className="inputFieldDivRight position-relative">
              <p className="mb-0">Contact Name </p>
              <div
                className="d-flex input-with-clear  "
                onClick={handleContactClick}
              >
                <input
                  type="text"
                  //   placeholder="Vendor Owner"
                  className="leadInputField cursorPointer"
                  value={
                    isDealListModal || isContactListModal || isAccountListModal
                      ? selectedContactItem?.moduleName
                      : selectedContactItem?.contactName
                  }
                  onClick={handleContactClick}
                  readOnly
                />

                <span
                  className="clear-button"
                  onClick={() =>
                    selectedContactItem && setSelectedContactItem("")
                  }
                >
                  <img
                    src={contactListVisible ? up : down}
                    alt="img"
                    height={20}
                    width={20}
                  />
                </span>

                <span
                  className="cursorPointer pt-2 ps-1"
                  onClick={() => setIsContactListModal(!isContactListModal)}
                >
                  <img src={ContactIcon} alt="img" width={20} height={20} />
                </span>
              </div>
              {contactListVisible && contactDetails.length > 0 && (
                <ul
                  className="data-list list-group  lead_ownerList_container position-absolute"
                  style={{ top: "6rem" }}
                >
                  {contactDetails?.map((item, index) => (
                    <li
                      key={index}
                      className="cursorPointer list-group-item"
                      onClick={() => handleContactItemClick(item)}
                    >
                      <div className="d-flex ">
                        <span> {item.contactName} </span>
                      </div>
                    </li>
                  ))}
                </ul>
              )}
            </div>
          </div>
          <div className="inputFieldDiv">
            <div className="inputFieldDivLeft">
              <p className="mb-0">Team</p>
              <input
                type="text"
                className="insertCommonInputField"
                name="team"
                style={{
                  width: "60%",
                  border: "1px solid #B9B9B94D",
                  height: "40px",
                  outline: "none",
                  borderRadius: "10px",
                }}
                value={
                  existingClone || existingQuote
                    ? quoteUpdateInfo.team
                    : quoteInfo.team
                }
                onChange={handleChangeQuotes}
                onKeyDown={handleKeyDown}
              />
              <p className="text-danger">{teamMsg && teamMsg}</p>{" "}
            </div>
            <div className="inputFieldDivRight position-relative">
              <p className="mb-0">
                Account Name <span className="text-danger">*</span>
              </p>
              <div
                className="d-flex input-with-clear  "
                onClick={handleAccountClick}
              >
                <input
                  type="text"
                  //   placeholder="Vendor Owner"
                  // className="leadInputField cursorPointer"
                  className={
                    accErrName ? "mandatoryField" : "insertCommonInputField"
                  }
                  value={
                    fetchAccInfo
                      ? fetchAccInfo.accountName
                      : isDealListModal ||
                        isContactListModal ||
                        isAccountListModal
                      ? selectedAccountItem?.moduleName
                      : selectedAccountItem?.accountName
                  }
                  onClick={handleAccountClick}
                  readOnly
                />
                {/* {selectedAccountItem ? ( */}
                <span
                  className="clear-button"
                  onClick={(e) => {
                    // selectedAccountItem &&
                    // setSelectedAccountItem({ accountName: "", accountId: "" })
                    handleAccountClick(e, selectedAccountItem);
                  }}
                >
                  <img
                    src={accountListVisible ? up : down}
                    alt="img"
                    height={20}
                    width={20}
                  />
                </span>
                <span
                  className="cursorPointer pt-2 ps-1"
                  onClick={() => setIsAccountListModal(!isAccountListModal)}
                >
                  <img src={accountsIcon} alt="img" width={20} height={20} />
                </span>
              </div>
              {accountListVisible && accountDetails.length > 0 && (
                <ul
                  className="data-list list-group  lead_ownerList_container position-absolute"
                  style={{ top: "6rem" }}
                >
                  {accountDetails?.map((item, index) => (
                    <li
                      key={index}
                      className="cursorPointer list-group-item"
                      onClick={() => handleAccountItemClick(item)}
                    >
                      <div className="d-flex ">
                        <span> {item.accountName} </span>
                      </div>
                    </li>
                  ))}
                </ul>
              )}
              <p className="text-danger">{accErrName && accErrName}</p>{" "}
            </div>
          </div>
          <div className="inputFieldDiv">
            <div className="inputFieldDivLeft">
              <p className="mb-0">
                Carrier <span className="text-danger">*</span>
              </p>
              <Select
                // value={quoteInfo.carrier}
                value={
                  existingClone || existingQuote
                    ? carrierOptions.find(
                        (option) =>
                          option.value === quoteUpdateInfo.carrier.value
                      )
                    : carrierOptions.find(
                        (option) => option.value === quoteInfo.carrier.value
                      )
                }
                menuPlacement="auto"
                onChange={(selectedOption) =>
                  handleChangeSelectDropDown(selectedOption, "carrier")
                }
                options={carrierOptions}
                styles={createQuoteStyles}
                isSearchable={false}
              />
            </div>
          </div>

          {/* </form> */}
          <h6
            className="py-2 mt-4"
            style={{
              borderBottom: "2px solid #316AFF",
              fontSize: "18px",
              color: "#515151",
              marginBottom: "10px",
            }}
          >
            Address Information
          </h6>
          <div
            className="inputFieldDiv"
            style={{ width: "min-content", alignSelf: "end" }}
          >
            <Dropdown>
              <Dropdown.Toggle id="dropdown-basic">
                Copy Address
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item
                  onClick={() => {
                    const {
                      billingStreet,
                      billingCity,
                      billingState,
                      billingCode,
                      billingCountry,
                    } = billingAdd;
                    const {
                      shippingStreet,
                      shippingCity,
                      shippingState,
                      shippingCode,
                      shippingCountry,
                    } = shippingAdd;
                    setShippingAdd((prevData) => ({
                      ...prevData,
                      shippingStreet:
                        billingStreet.length > 0
                          ? billingStreet
                          : shippingStreet,
                      shippingCity:
                        billingCity.length > 0 ? billingCity : shippingCity,
                      shippingState:
                        billingState.length > 0 ? billingState : shippingState,
                      shippingCode:
                        billingCode.length > 0 ? billingCode : shippingCode,
                      shippingCountry:
                        billingCountry.length > 0
                          ? billingCountry
                          : shippingCountry,
                    }));
                  }}
                >
                  Billing to Shipping
                </Dropdown.Item>
                <Dropdown.Item
                  onClick={() => {
                    const {
                      shippingStreet,
                      shippingCity,
                      shippingState,
                      shippingCode,
                      shippingCountry,
                    } = shippingAdd;
                    const {
                      billingStreet,
                      billingCity,
                      billingState,
                      billingCode,
                      billingCountry,
                    } = billingAdd;
                    setBillingAdd((prevData) => ({
                      ...prevData,
                      billingStreet:
                        shippingStreet.length > 0
                          ? shippingStreet
                          : billingStreet,
                      billingCity:
                        shippingCity.length > 0 ? shippingCity : billingCity,
                      billingState:
                        shippingState.length > 0 ? shippingState : billingState,
                      billingCode:
                        shippingCode.length > 0 ? shippingCode : billingCode,
                      billingCountry:
                        shippingCountry.length > 0
                          ? shippingCountry
                          : billingCountry,
                    }));
                  }}
                >
                  Shipping to Billing
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
          <div className="inputFieldDiv">
            <div className="inputFieldDivLeft">
              <p className="mb-0">Billing Street</p>
              <input
                type="text"
                className="insertCommonInputField"
                name="billingStreet"
                style={{
                  width: "60%",
                  border: "1px solid #B9B9B94D",
                  height: "40px",
                  outline: "none",
                  borderRadius: "10px",
                }}
                value={
                  existingClone || existingQuote
                    ? quoteUpdateInfo.billingStreet
                    : billingAdd.billingStreet
                  // billingAdd.billingStreet
                }
                onChange={handleChangeBillingAddress}
                onKeyDown={(e) => {
                  const isAlpha = (char) => /^[a-zA-Z\s]$/.test(char);

                  if (
                    !isAlpha(e.key) &&
                    !["Backspace", "Tab", "ArrowLeft", "ArrowRight"].includes(
                      e.key
                    )
                  ) {
                    e.preventDefault();
                  }
                }}
              />
            </div>
            <div className="inputFieldDivRight">
              <p className="mb-0">Shipping Street</p>
              <input
                type="text"
                className="insertCommonInputField"
                name="shippingStreet"
                style={{
                  width: "60%",
                  border: "1px solid #B9B9B94D",
                  height: "40px",
                  outline: "none",
                  borderRadius: "10px",
                }}
                value={
                  existingClone || existingQuote
                    ? quoteUpdateInfo.shippingStreet
                    : shippingAdd.shippingStreet

                  // shippingAdd.shippingStreet
                }
                onChange={handleChangeShippingAddress}
                onKeyDown={(e) => {
                  const isAlpha = (char) => /^[a-zA-Z\s]$/.test(char);

                  if (
                    !isAlpha(e.key) &&
                    !["Backspace", "Tab", "ArrowLeft", "ArrowRight"].includes(
                      e.key
                    )
                  ) {
                    e.preventDefault();
                  }
                }}
              />
            </div>
          </div>
          <div className="inputFieldDiv">
            <div className="inputFieldDivLeft">
              <p className="mb-0">Billing City</p>
              <input
                type="text"
                className="insertCommonInputField"
                name="billingCity"
                style={{
                  width: "60%",
                  border: "1px solid #B9B9B94D",
                  height: "40px",
                  outline: "none",
                  borderRadius: "10px",
                }}
                value={
                  existingClone || existingQuote
                    ? quoteUpdateInfo.billingCity
                    : billingAdd.billingCity
                }
                onChange={handleChangeBillingAddress}
                onKeyDown={(e) => {
                  const isAlpha = (char) => /^[a-zA-Z\s]$/.test(char);

                  if (
                    !isAlpha(e.key) &&
                    !["Backspace", "Tab", "ArrowLeft", "ArrowRight"].includes(
                      e.key
                    )
                  ) {
                    e.preventDefault();
                  }
                }}
              />
              <p className="text-danger">{billingCityMsg && billingCityMsg}</p>
            </div>
            <div className="inputFieldDivRight">
              <p className="mb-0">Shipping City</p>
              <input
                type="text"
                className="insertCommonInputField"
                name="shippingCity"
                style={{
                  width: "60%",
                  border: "1px solid #B9B9B94D",
                  height: "40px",
                  outline: "none",
                  borderRadius: "10px",
                }}
                value={
                  existingClone || existingQuote
                    ? quoteUpdateInfo.shippingCity
                    : shippingAdd.shippingCity
                }
                onChange={handleChangeShippingAddress}
                onKeyDown={(e) => {
                  const isAlpha = (char) => /^[a-zA-Z\s]$/.test(char);

                  if (
                    !isAlpha(e.key) &&
                    !["Backspace", "Tab", "ArrowLeft", "ArrowRight"].includes(
                      e.key
                    )
                  ) {
                    e.preventDefault();
                  }
                }}
              />
              <p className="text-danger">
                {shippingCityMsg && shippingCityMsg}
              </p>
            </div>
          </div>
          <div className="inputFieldDiv">
            <div className="inputFieldDivLeft">
              <p className="mb-0">Billing State</p>
              <input
                type="text"
                className="insertCommonInputField"
                name="billingState"
                style={{
                  width: "60%",
                  border: "1px solid #B9B9B94D",
                  height: "40px",
                  outline: "none",
                  borderRadius: "10px",
                }}
                value={
                  existingClone || existingQuote
                    ? quoteUpdateInfo.billingState
                    : billingAdd.billingState
                }
                onChange={handleChangeBillingAddress}
                onKeyDown={(e) => {
                  const isAlpha = (char) => /^[a-zA-Z\s]$/.test(char);

                  if (
                    !isAlpha(e.key) &&
                    !["Backspace", "Tab", "ArrowLeft", "ArrowRight"].includes(
                      e.key
                    )
                  ) {
                    e.preventDefault();
                  }
                }}
              />
              <p className="text-danger">
                {billingStateMsg && billingStateMsg}
              </p>
            </div>
            <div className="inputFieldDivRight">
              <p className="mb-0">Shipping State</p>
              <input
                type="text"
                className="insertCommonInputField"
                name="shippingState"
                style={{
                  width: "60%",
                  border: "1px solid #B9B9B94D",
                  height: "40px",
                  outline: "none",
                  borderRadius: "10px",
                }}
                value={
                  existingClone || existingQuote
                    ? quoteUpdateInfo.shippingState
                    : shippingAdd.shippingState
                }
                onChange={handleChangeShippingAddress}
                onKeyDown={(e) => {
                  const isAlpha = (char) => /^[a-zA-Z\s]$/.test(char);

                  if (
                    !isAlpha(e.key) &&
                    !["Backspace", "Tab", "ArrowLeft", "ArrowRight"].includes(
                      e.key
                    )
                  ) {
                    e.preventDefault();
                  }
                }}
              />
              <p className="text-danger">
                {shippingStateMsg && shippingStateMsg}
              </p>
            </div>
          </div>
          <div className="inputFieldDiv">
            <div className="inputFieldDivLeft">
              <p className="mb-0">Billing Code</p>
              <input
                type="text"
                className="insertCommonInputField"
                name="billingCode"
                style={{
                  width: "60%",
                  border: "1px solid #B9B9B94D",
                  height: "40px",
                  outline: "none",
                  borderRadius: "10px",
                }}
                // value={billingAdd.billingCode}
                value={
                  existingClone || existingQuote
                    ? quoteUpdateInfo.billingCode
                    : billingAdd.billingCode
                }
                onChange={handleChangeBillingAddress}
                maxLength={6}
                onKeyDown={(e) => {
                  // Allow only numbers and backspace, delete, left arrow, right arrow keys
                  if (
                    !/[0-9]/.test(e.key) &&
                    e.key !== "Backspace" &&
                    e.key !== "Delete" &&
                    e.key !== "ArrowLeft" &&
                    e.key !== "ArrowRight"
                  ) {
                    e.preventDefault();
                  }
                }}
              />
              <p className="text-danger">{codeMsg && codeMsg}</p>
            </div>
            <div className="inputFieldDivRight">
              <p className="mb-0">Shipping Code</p>
              <input
                type="text"
                className="insertCommonInputField"
                name="shippingCode"
                style={{
                  width: "60%",
                  border: "1px solid #B9B9B94D",
                  height: "40px",
                  outline: "none",
                  borderRadius: "10px",
                }}
                value={
                  existingClone || existingQuote
                    ? quoteUpdateInfo.shippingCode
                    : shippingAdd.shippingCode
                }
                onChange={handleChangeShippingAddress}
                maxLength={6}
                onKeyDown={(e) => {
                  // Allow only numbers and backspace, delete, left arrow, right arrow keys
                  if (
                    !/[0-9]/.test(e.key) &&
                    e.key !== "Backspace" &&
                    e.key !== "Delete" &&
                    e.key !== "ArrowLeft" &&
                    e.key !== "ArrowRight"
                  ) {
                    e.preventDefault();
                  }
                }}
              />
              <p className="text-danger">
                {shippingCodeMsg && shippingCodeMsg}
              </p>
            </div>
          </div>
          <div className="inputFieldDiv">
            <div className="inputFieldDivLeft">
              <p className="mb-0">Billing Country</p>
              <input
                type="text"
                className="insertCommonInputField"
                name="billingCountry"
                style={{
                  width: "60%",
                  border: "1px solid #B9B9B94D",
                  height: "40px",
                  outline: "none",
                  borderRadius: "10px",
                }}
                value={
                  existingClone || existingQuote
                    ? quoteUpdateInfo.billingCountry
                    : billingAdd.billingCountry
                }
                onChange={handleChangeBillingAddress}
                onKeyDown={(e) => {
                  const isAlpha = (char) => /^[a-zA-Z\s]$/.test(char);

                  if (
                    !isAlpha(e.key) &&
                    !["Backspace", "Tab", "ArrowLeft", "ArrowRight"].includes(
                      e.key
                    )
                  ) {
                    e.preventDefault();
                  }
                }}
              />
              <p className="text-danger">
                {billingCountryMsg && billingCountryMsg}
              </p>
            </div>
            <div className="inputFieldDivRight">
              <p className="mb-0">Shipping Country</p>
              <input
                type="text"
                className="insertCommonInputField"
                name="shippingCountry"
                style={{
                  width: "60%",
                  border: "1px solid #B9B9B94D",
                  height: "40px",
                  outline: "none",
                  borderRadius: "10px",
                }}
                value={
                  existingClone || existingQuote
                    ? quoteUpdateInfo.shippingCountry
                    : shippingAdd.shippingCountry
                }
                onChange={handleChangeShippingAddress}
                onKeyDown={(e) => {
                  const isAlpha = (char) => /^[a-zA-Z\s]$/.test(char);
                  if (
                    !isAlpha(e.key) &&
                    !["Backspace", "Tab", "ArrowLeft", "ArrowRight"].includes(
                      e.key
                    )
                  ) {
                    e.preventDefault();
                  }
                }}
              />
              <p className="text-danger">
                {shippingCountryMsg && shippingCountryMsg}
              </p>
            </div>
          </div>
          <div
          // style={{
          //   display: "grid",
          //   gridTemplateColumns: "auto auto",
          //   width: "100%",
          // }}
          >
            {/* <span
              style={{ borderLeft: "6px solid green", height: "50px" }}
            ></span> */}
            <h6
              className="py-2 mt-4"
              style={{
                borderBottom: "2px solid #316AFF",
                fontSize: "18px",
                color: "#515151",
              }}
            >
              Quotes Items<span className="text-danger">*</span>
            </h6>
          </div>
          <div
            className="horizontal-scroll-container mb-2"
            style={{
              // overflowX: "auto",
              width: "100%",
              maxWidth: "100%",
              // overflow: "hidden",
              border: "none",
            }}
          >
            <table
              className="table"
              style={{
                tableLayout: "fixed",
                minWidth: "1000px",
                margin: "0px",
              }}
            >
              <colgroup>
                <col style={{ width: "1%" }} />
                <col style={{ width: "4%" }} />
                <col style={{ width: "12%" }} />
                <col style={{ width: "6%" }} />
                <col style={{ width: "6%" }} />
                <col style={{ width: "6%" }} />
                <col style={{ width: "6%" }} />
                <col style={{ width: "6%" }} />
                <col style={{ width: "6%" }} />
              </colgroup>
              <thead>
                <tr className="mb-2">
                  <th
                    style={{
                      backgroundColor: "rgba(243, 246, 251, 1)",
                      border: "none",
                    }}
                  ></th>
                  <th
                    style={{
                      backgroundColor: "#EFEFEF",
                      border: "1px solid rgba(185, 185, 185, 0.5)",
                    }}
                  >
                    SL.NO
                  </th>
                  <th
                    style={{
                      backgroundColor: "#EFEFEF",
                      border: "1px solid rgba(185, 185, 185, 0.5)",
                    }}
                  >
                    Product Name
                  </th>
                  <th
                    style={{
                      backgroundColor: "#EFEFEF",
                      border: "1px solid rgba(185, 185, 185, 0.5)",
                    }}
                  >
                    Quantity
                  </th>
                  <th
                    style={{
                      backgroundColor: "#EFEFEF",
                      border: "1px solid rgba(185, 185, 185, 0.5)",
                    }}
                  >
                    List Price (₹)
                  </th>
                  <th
                    style={{
                      backgroundColor: "#EFEFEF",
                      border: "1px solid rgba(185, 185, 185, 0.5)",
                    }}
                  >
                    Amount (₹)
                  </th>
                  <th
                    style={{
                      backgroundColor: "#EFEFEF",
                      border: "1px solid rgba(185, 185, 185, 0.5)",
                    }}
                  >
                    Discount (₹)
                  </th>
                  <th
                    style={{
                      backgroundColor: "#EFEFEF",
                      border: "1px solid rgba(185, 185, 185, 0.5)",
                    }}
                  >
                    Tax (%)
                  </th>
                  <th
                    style={{
                      backgroundColor: "#EFEFEF",
                      border: "1px solid rgba(185, 185, 185, 0.5)",
                    }}
                  >
                    Total (₹)
                  </th>
                </tr>
              </thead>
              <tbody>
                {allProducts.map((row, index) => (
                  <tr key={index}>
                    <td
                      style={{
                        backgroundColor: "rgba(243, 246, 251, 1)",
                        border: "none",
                        alignContent: "center",
                        padding: "0px",
                      }}
                    >
                      <button
                        className={` ${
                          allProducts.length <= 1 ? "opacity-0" : "last-button"
                        }`}
                        onClick={() => handleDeleteRow(index)}
                        style={{
                          backgroundColor: "transparent",
                          padding: "0px",
                        }}
                      >
                        X
                      </button>
                    </td>
                    <td
                      style={{
                        backgroundColor: "white",
                        border: "1px solid rgba(185, 185, 185, 0.5)",
                      }}
                    >
                      <input
                        type="number"
                        name="slNo"
                        defaultValue={index + 1}
                        style={{
                          border: "none",
                          borderRadius: "5px",
                          backgroundColor: "white",
                          color: "#2177FE",
                          width: "-webkit-fill-available",
                        }}
                        readOnly
                        disabled
                      />
                    </td>
                    <td
                      style={{
                        backgroundColor: "white",
                        border: "1px solid rgba(185, 185, 185, 0.5)",
                      }}
                    >
                      <div className="d-flex input-with-clear position-relative w-100">
                        <span
                          onClick={(e) => {
                            e.stopPropagation();
                            setSelectedProductIndex(index);
                            setListVisibleType(
                              listVisibleType === "productId" ? "" : "productId"
                            );
                          }}
                          style={{
                            marginBottom: "10px",
                            borderRadius: "2.5px",
                            border: "1px solid rgba(185, 185, 185, 0.5)",
                            width: "-webkit-fill-available",
                          }}
                          className="cursorPointer px-1"
                        >
                          {row.productName || selectedProduct.productName}
                          {/* {row.productName || selectedProduct.productName} */}
                        </span>

                        <span
                          className="position-absolute cursorPointer "
                          style={{ right: "40px" }}
                        >
                          <img
                            src={
                              listVisibleType === "productId" &&
                              selectedProductIndex === index
                                ? up
                                : down
                            }
                            alt="img"
                            height={20}
                            width={20}
                          />
                        </span>

                        <span
                          className="cursorPointer"
                          onClick={() =>
                            setIsProductOwnerModal(!isProductOwnerModal)
                          }
                        >
                          <img
                            src={userSearch}
                            alt="img"
                            width={20}
                            height={20}
                          />
                        </span>
                        {listVisibleType === "productId" &&
                          selectedProductIndex === index &&
                          accountsDropdown("1.8rem", index)}
                      </div>

                      <textarea
                        type="text"
                        name="description"
                        value={row.description}
                        onChange={(e) => handleRowChange(index, e)}
                        placeholder="Add Description"
                        style={{
                          marginBottom: "10px",
                          borderRadius: "2.5px",
                          border: "1px solid rgba(185, 185, 185, 0.5)",
                          width: "-webkit-fill-available",
                        }}
                      />
                    </td>
                    <td
                      style={{
                        backgroundColor: "white",
                        border: "1px solid rgba(185, 185, 185, 0.5)",
                      }}
                    >
                      <input
                        type="number"
                        name="quantity"
                        value={row.quantity}
                        onChange={(e) => handleRowChange(index, e)}
                        style={{
                          marginBottom: "10px",
                          borderRadius: "2.5px",
                          border: "1px solid rgba(185, 185, 185, 0.5)",
                          width: "-webkit-fill-available",
                        }}
                      />
                    </td>
                    <td
                      style={{
                        backgroundColor: "white",
                        border: "1px solid rgba(185, 185, 185, 0.5)",
                      }}
                    >
                      <input
                        type="number"
                        name="listPrice"
                        value={row.listPrice}
                        onChange={(e) => handleRowChange(index, e)}
                        style={{
                          marginBottom: "10px",
                          borderRadius: "2.5px",
                          border: "1px solid rgba(185, 185, 185, 0.5)",
                          width: "-webkit-fill-available",
                        }}
                      />
                    </td>
                    <td
                      style={{
                        backgroundColor: "white",
                        border: "1px solid rgba(185, 185, 185, 0.5)",
                      }}
                    >
                      <input
                        type="number"
                        name="amount"
                        value={row.amount}
                        onChange={(e) => handleRowChange(index, e)}
                        style={{
                          marginBottom: "10px",
                          borderRadius: "2.5px",
                          border: "1px solid rgba(185, 185, 185, 0.5)",
                          width: "-webkit-fill-available",
                        }}
                        readOnly
                        disabled
                      />
                    </td>
                    <td
                      style={{
                        backgroundColor: "white",
                        border: "1px solid rgba(185, 185, 185, 0.5)",
                      }}
                      className="position-relative"
                    >
                      <div className="d-flex">
                        <input
                          type="number"
                          name="discount"
                          value={row.discount}
                          onChange={(e) => handleRowChange(index, e)}
                          style={{
                            marginBottom: "10px",
                            borderRadius: "2.5px",
                            border: "1px solid rgba(185, 185, 185, 0.5)",
                            width: "-webkit-fill-available",
                          }}
                          readOnly
                          disabled
                          className="me-3"
                        />
                        <span
                          onClick={(e) => {
                            e.stopPropagation();
                            setSelectedProductIndex(index);
                            setPopoutType(
                              popoutType === "discount" ? "" : "discount"
                            );
                          }}
                          style={{ marginLeft: "-0.7rem" }}
                          className="cursorPointer"
                        >
                          {editIcon}
                        </span>
                      </div>
                      {popoutType === "discount" &&
                        selectedProductIndex === index && (
                          <>{discountPopup()}</>
                        )}
                    </td>
                    <td
                      style={{
                        backgroundColor: "white",
                        border: "1px solid rgba(185, 185, 185, 0.5)",
                      }}
                    >
                      <input
                        type="number"
                        name="tax"
                        value={row.tax}
                        onChange={(e) => handleRowChange(index, e)}
                        style={{
                          marginBottom: "10px",
                          borderRadius: "2.5px",
                          border: "1px solid rgba(185, 185, 185, 0.5)",
                          width: "-webkit-fill-available",
                        }}
                        readOnly
                        disabled
                      />
                    </td>
                    <td
                      style={{
                        backgroundColor: "white",
                        border: "1px solid rgba(185, 185, 185, 0.5)",
                      }}
                    >
                      <input
                        type="number"
                        name="total"
                        value={row.total}
                        onChange={(e) => handleRowChange(index, e)}
                        style={{
                          marginBottom: "10px",
                          borderRadius: "2.5px",
                          border: "1px solid rgba(185, 185, 185, 0.5)",
                          width: "-webkit-fill-available",
                        }}
                        disabled
                        readOnly
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
            className="mt-3"
          >
            <button
              onClick={handleAddRow}
              style={{
                height: "max-content",
                borderRadius: "10px",
                padding: "5px 25px 5px 25px",
                borderColor: "#529CF3",
                backgroundColor: "#E4F5FF",
                color: "#515151",
                marginLeft: "1.5%",
              }}
            >
              Add Row
            </button>
            <div
              style={{
                display: "flex",
                width: "max-content",
                border: "1.86px solid rgba(226, 229, 235, 1)",
                boxShadow: "0px 0px 18.12px 0px rgba(0, 0, 0, 0.03)",
                background: "rgba(255, 255, 255, 1)",
                borderRadius: "10px",
              }}
              // className="table-responsive"
            >
              <table
                className="table"
                style={{
                  borderCollapse: "collapse",
                  // width: "max-content",
                  margin: "0",
                  boxShadow: "0px 0px 18.12px 0px rgba(0, 0, 0, 0.03)",
                  // overflow: "hidden",
                }}
              >
                {" "}
                <thead style={{ width: "max-content" }}>
                  <tr className="mb-2">
                    <th
                      scope="col"
                      style={{
                        backgroundColor: "#F8F8F8",
                        width: "max-content",
                      }}
                    >
                      Sub Total(₹)<span className="text-danger">*</span>
                    </th>
                    <td style={{ backgroundColor: "#F8F8F8" }}>
                      <input
                        type="number"
                        name="subTotal"
                        value={allProducts.reduce(
                          (sum, product) =>
                            parseFloat(sum) + parseFloat(product.total),
                          0
                        )}
                        // value={purchaseInfo.subTotal}
                        // onChange={handleChangePurchase}
                        style={{
                          borderRadius: "6px",
                          border: "1px solid rgba(185, 185, 185, 0.5)",
                          backgroundColor: "#F3F6FB",
                        }}
                        readOnly
                        disabled
                      />
                    </td>
                  </tr>
                  <tr>
                    <th scope="col">Discount(₹)</th>
                    <td className="dicount_column position-relative">
                      <div className="d-flex">
                        <input
                          type="number"
                          name="discount"
                          // value={quoteInfo.discount || 0}
                          value={
                            existingQuote || existingClone
                              ? quoteUpdateInfo.discount || 0
                              : quoteInfo.discount
                          }
                          onChange={handleChangeQuotes}
                          onKeyDown={handleKeyDown}
                          style={{
                            borderRadius: "6px",
                            border: "1px solid rgba(185, 185, 185, 0.5)",
                            backgroundColor: "#F3F6FB",
                          }}
                          readOnly
                          disabled
                          className="me-3"
                        />
                        <span
                          onClick={(e) => {
                            e.stopPropagation();
                            setSelectedProductIndex("");
                            setPopoutType(
                              popoutType === "discount" ? "" : "discount"
                            );
                          }}
                          style={{ marginLeft: "-0.7rem" }}
                          className="cursorPointer discount_icon"
                        >
                          {editIcon}
                        </span>
                      </div>
                      {popoutType === "discount" &&
                        selectedProductIndex === "" && <>{discountPopup("")}</>}
                    </td>{" "}
                  </tr>
                  <tr>
                    <th
                      scope="col"
                      style={{
                        backgroundColor: "#F8F8F8",
                      }}
                    >
                      Tax(%)
                    </th>
                    <td
                      style={{ backgroundColor: "#F8F8F8" }}
                      className="dicount_column position-relative"
                    >
                      <div className="d-flex">
                        <input
                          type="number"
                          // value={quoteInfo.tax || 0}
                          value={
                            existingQuote || existingClone
                              ? quoteUpdateInfo.tax || 0
                              : quoteInfo.tax
                          }
                          onChange={handleChangeQuotes}
                          onKeyDown={handleKeyDown}
                          style={{
                            borderRadius: "6px",
                            border: "1px solid rgba(185, 185, 185, 0.5)",
                            backgroundColor: "#F3F6FB",
                          }}
                          readOnly
                          disabled
                        />
                        <span
                          onClick={(e) => {
                            e.stopPropagation();
                            setSelectedProductIndex("");
                            setPopoutType(popoutType === "tax" ? "" : "tax");
                          }}
                          className="cursorPointer discount_icon ps-1"
                        >
                          {editIcon}
                        </span>
                      </div>
                      {popoutType === "tax" && selectedProductIndex === "" && (
                        <>{discountPopup("")}</>
                      )}
                    </td>
                  </tr>
                  <tr>
                    <th scope="col">Adjustment(₹)</th>
                    <td>
                      {" "}
                      <input
                        type="number"
                        name="adjustment"
                        // value={quoteInfo.adjustment}
                        value={
                          existingQuote || existingClone
                            ? quoteUpdateInfo.adjustment
                            : quoteInfo.adjustment
                        }
                        onChange={(e) => {
                          const subTotalOne =
                            existingQuote || existingClone
                              ? quoteUpdateInfo.subTotal
                              : quoteInfo.subTotal;

                          const discountOne =
                            existingQuote || existingClone
                              ? quoteUpdateInfo.discount
                              : quoteInfo.discount;
                          const taxOne =
                            existingQuote || existingClone
                              ? quoteUpdateInfo.tax
                              : quoteInfo.tax;

                          const total = subTotalOne - discountOne;
                          const taxValue = (taxOne / 100) * total;

                          const finalTotal =
                            parseFloat(total) +
                            parseFloat(taxValue || 0) +
                            parseFloat(e.target.value || 0);

                          if (existingQuote || existingClone) {
                            setQuoteUpdateInfo((prevInfo) => ({
                              ...prevInfo,
                              adjustment: e.target.value,
                              grandTotal: parseFloat(finalTotal).toFixed(2),
                              // subTotal: parseFloat(total).toFixed(2),
                            }));
                          } else {
                            setQuoteInfo((prevInfo) => ({
                              ...prevInfo,
                              adjustment: e.target.value,
                              grandTotal: parseFloat(finalTotal).toFixed(2),
                              // subTotal: parseFloat(total).toFixed(2),
                            }));
                          }
                        }}
                        style={{
                          borderRadius: "6px",
                          border: "1px solid rgba(185, 185, 185, 0.5)",
                          backgroundColor: "transparent",
                        }}
                      />
                    </td>
                  </tr>
                  <tr>
                    <th
                      scope="col"
                      style={{
                        backgroundColor: "#F8F8F8",
                      }}
                    >
                      Grand Total(₹)<span className="text-danger">*</span>
                    </th>
                    <td style={{ backgroundColor: "#F8F8F8" }}>
                      <input
                        type="number"
                        name="grandTotal"
                        value={quoteInfo.grandTotal}
                        // value={
                        //   parseFloat(purchaseInfo.subTotal) +
                        //   parseFloat(purchaseInfo.adjustment || 0)
                        // }
                        // value={
                        //   allProducts.reduce(
                        //     (sum, product) =>
                        //       parseFloat(sum) + parseFloat(product.total),
                        //     0
                        //   ) + parseFloat(purchaseInfo.adjustment) || 0
                        // }
                        onChange={handleChangeQuotes}
                        onKeyDown={handleKeyDown}
                        style={{
                          borderRadius: "6px",
                          border: "1px solid rgba(185, 185, 185, 0.5)",
                          backgroundColor: "#F3F6FB",
                        }}
                        readOnly
                        disabled
                      />{" "}
                    </td>
                  </tr>
                </thead>
              </table>
            </div>
          </div>

          <h6
            className="py-2 "
            style={{
              borderBottom: "2px solid #316AFF",
              fontSize: "18px",
              color: "#515151",
              marginBottom: "0px",
            }}
          >
            Terms and Conditions
          </h6>
          <div className="quotesInputFieldDiv" style={{ display: "block" }}>
            <div
              className="quotesInputFieldDivLeft"
              style={{ display: "block", width: "100%" }}
            >
              <p>Terms and Conditions</p>
              <textarea
                name="termsAndConditions"
                className="px-2"
                style={{
                  width: "100%",
                  border: "1px solid #B9B9B94D",
                  outline: "none",
                  borderRadius: "10px",
                  resize: "none",
                }}
                rows={4}
                value={
                  existingClone || existingQuote
                    ? quoteUpdateInfo.termsAndConditions
                    : quoteInfo.termsAndConditions
                }
                onChange={handleChangeQuotes}
                onKeyDown={handleKeyDown}
              />
            </div>
          </div>
          <h6
            className="py-2 mt-4"
            style={{
              borderBottom: "2px solid #316AFF",
              fontSize: "18px",
              color: "#515151",
              marginBottom: "0px",
            }}
          >
            Description Information
          </h6>
          <div className="quotesInputFieldDiv" style={{ display: "block" }}>
            <div
              className="quotesInputFieldDivLeft"
              style={{ display: "block", width: "100%" }}
            >
              <p>Description</p>

              <textarea
                name="description"
                rows={2}
                style={{
                  width: "100%",
                  border: "1px solid #B9B9B94D",
                  height: "100px",
                  outline: "none",
                  borderRadius: "10px",
                }}
                className="px-2"
                value={
                  existingQuote || existingClone
                    ? quoteUpdateInfo.description
                    : quoteInfo.description
                }
                onChange={handleChangeQuotes}
                onKeyDown={handleKeyDown}
              />
            </div>
          </div>
        </div>
        <Modal
          show={isAccountListModal}
          className="d-flex justify-content-center align-items-center"
          aria-labelledby="example-custom-modal-styling-title"
          size="lg"
          id="commonLeadModal"
        >
          <Modal.Body className="ModalBody  overflow-auto">
            <div
              className="modalUS row justify-content-center"
              style={{ margin: "0", gap: "10px", paddingBottom: "10px" }}
            >
              <div className="header_Container d-flex justify-content-between">
                <h4 className="commonTextColor pt-2">Change Account</h4>
                <div className="inputContainer d-flex justify-content-start">
                  <input
                    type="text"
                    placeholder="Search"
                    className="topBarModalSearch px-5"
                    onChange={handleSearchAccount}
                    value={searchAccount}
                  />
                  <img src={SearchIcon} alt="search" className="searchIcon" />
                </div>
              </div>

              <div className="Owner_table_container border border-dark rounded-2 h-25">
                <div className="table-wrapper">
                  <table className="userdata-table">
                    <thead>
                      <tr>
                        <th>Account Name</th>
                        <th>Phone</th>
                        <th>Website</th>
                        <th>Account Owner</th>
                      </tr>
                    </thead>
                    <tbody className="table-body tableUserData">
                      {filteredAccount?.map((item, index) => (
                        <tr
                          key={index}
                          onClick={() => {
                            setSelectedAccountItem(item);
                          }}
                        >
                          <td>{item.accountName}</td>
                          <td>{item.phone}</td>
                          <td>{item.website}</td>
                          <td>{item.accountOwner}</td>
                        </tr>
                      ))}
                      {filteredAccount &&
                        filteredAccount.length > 1 &&
                        filteredAccount?.map((item, index) => (
                          <tr
                            key={index}
                            onClick={() => {
                              setSelectedAccountItem(item);
                            }}
                          >
                            <td>{item.accountName}</td>
                            <td>{item.phone}</td>
                            <td>{item.website}</td>
                            <td>{item.accountOwner}</td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
              </div>
              <div className="d-flex w-100 align-items-center justify-content-end gap-2">
                {filteredAccount.length > 0 && (
                  <>
                    <Select
                      options={pageOption}
                      styles={customSelectLocationStyles}
                      // value={selectPageOption}
                      value={pageOption.find(
                        (option) => option.value === perPageAccount.toString()
                      )}
                      onChange={handlePageDropDownChangeAccount}
                    />
                    {/* <p className="mb-0">· ‹1/10›</p> */}

                    <Pagination
                      currentPage={currentPageAccount}
                      totalPages={totalPagesAccount}
                      onPageChange={(page) => {
                        setCurrentPageAccount(page);
                      }}
                    />
                  </>
                )}
              </div>
              <div className="d-flex pb-2 pt-3 justify-content-between">
                <div className="selectedUser d-flex gap-1">
                  <span>Selected User</span>
                  <img src={logoConvert} alt="img" height={20} width={20} />
                  <span>{selectedAccountItem?.accountName}</span>
                </div>
                <div className="d-flex gap-2">
                  <button
                    className="btn btn-outline-secondary border border-primary btn-sm px-3 py-2 text-dark"
                    onClick={() => setIsAccountListModal(false)}
                  >
                    Cancel
                  </button>
                  <button
                    className="btn btnColor btn-sm px-3 text-white"
                    onClick={() => {
                      setIsAccountListModal(false);
                      getAllAssignModules(
                        "ACCOUNTS",
                        ["DEALS", "CONTACTS"],
                        selectedAccountItem.accountId
                      );
                    }}
                  >
                    Done
                  </button>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>

        <Modal
          show={isContactListModal}
          className="d-flex justify-content-center align-items-center"
          aria-labelledby="example-custom-modal-styling-title"
          size="lg"
          id="commonLeadModal"
        >
          <Modal.Body className="ModalBody  overflow-auto">
            <div
              className="modalUS row justify-content-center"
              style={{ margin: "0", gap: "10px", paddingBottom: "10px" }}
            >
              <div className="header_Container d-flex justify-content-between">
                <h4 className="commonTextColor pt-2">Change Contact</h4>
                <div className="inputContainer d-flex justify-content-start">
                  <input
                    type="text"
                    placeholder="Search"
                    className="topBarModalSearch px-5"
                    onChange={handleSearchContact}
                    value={searchContact}
                  />
                  <img src={SearchIcon} alt="search" className="searchIcon" />
                </div>
              </div>

              <div className="Owner_table_container border border-dark rounded-2 h-25">
                <div className="table-wrapper">
                  <table className="userdata-table">
                    <thead>
                      <tr>
                        <th>Contact Name</th>
                        <th>Account Name</th>
                        <th>Email</th>
                        <th>Phone</th>
                        <th>Contact Owner</th>
                      </tr>
                    </thead>
                    <tbody className="table-body tableUserData">
                      {filteredContacts?.map((item, index) => (
                        <tr
                          key={index}
                          onClick={() => {
                            setSelectedContactItem(item);
                          }}
                        >
                          <td>{item.contactName}</td>
                          <td>{item.accountName}</td>
                          <td>{item.emailId}</td>
                          <td>{item.phone}</td>
                          <td>{item.contactOwner}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
              <div className="d-flex w-100 align-items-center justify-content-end gap-2">
                {filteredContacts.length > 0 && (
                  <>
                    <Select
                      options={pageOption}
                      styles={customSelectLocationStyles}
                      // value={selectPageOption}
                      value={pageOption.find(
                        (option) => option.value === perPageContact.toString()
                      )}
                      onChange={handlePageDropDownChangeContact}
                    />
                    {/* <p className="mb-0">· ‹1/10›</p> */}

                    <Pagination
                      currentPage={currentPageContact}
                      totalPages={totalPagesContact}
                      onPageChange={(page) => {
                        setCurrentPageContact(page);
                      }}
                    />
                  </>
                )}
              </div>
              <div className="d-flex pb-2 pt-3 justify-content-between">
                <div className="selectedUser d-flex gap-1">
                  <span>Selected User</span>
                  <img src={logoConvert} alt="img" height={20} width={20} />
                  <span>{selectedContactItem?.contactName}</span>
                </div>
                <div className="d-flex gap-2">
                  <button
                    className="btn btn-outline-secondary border border-primary btn-sm px-3 py-2 text-dark"
                    onClick={() => setIsContactListModal(false)}
                  >
                    Cancel
                  </button>
                  <button
                    className="btn btnColor btn-sm px-3 text-white"
                    onClick={() => {
                      setIsContactListModal(false);
                      getAllAssignModules(
                        "CONTACTS",
                        ["DEALS", "ACCOUNTS"],
                        selectedContactItem.contactId
                      );
                    }}
                  >
                    Done
                  </button>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>

        <Modal
          show={isDealListModal}
          className="d-flex justify-content-center align-items-center"
          aria-labelledby="example-custom-modal-styling-title"
          size="lg"
          id="commonLeadModal"
        >
          <Modal.Body className="ModalBody  overflow-auto">
            <div
              className="modalUS row justify-content-center"
              style={{ margin: "0", gap: "10px", paddingBottom: "10px" }}
            >
              <div className="header_Container d-flex justify-content-between">
                <h4 className="commonTextColor">Change Deal</h4>
                <div className="d-flex justify-content-start">
                  <div className="inputContainer d-flex justify-content-start">
                    <input
                      type="text"
                      className="topBarModalSearch px-5"
                      placeholder="Search "
                      value={searchDeal}
                      onChange={handleSearchDeal}
                    />
                    <img src={SearchIcon} alt="search" className="searchIcon" />
                  </div>
                </div>
              </div>

              <div className="Owner_table_container border border-dark rounded-2 h-25">
                <div className="table-wrapper">
                  <table className="userdata-table">
                    <thead>
                      <tr>
                        <th>Deal Name</th>
                        <th>Closing Date</th>
                        <th>Stage</th>
                        <th>Lead Source</th>
                        <th>Contact Name</th>
                      </tr>
                    </thead>
                    <tbody className="table-body tableUserData">
                      {filteredDeals?.map((item, index) => (
                        <tr
                          key={index}
                          onClick={() => {
                            setSelectedDealItem(item);
                          }}
                        >
                          <td>{item.dealName}</td>
                          <td>{item.closingDate}</td>
                          <td>{item.stage}</td>
                          <td>{item.leadSource}</td>
                          <td>{item.contactName}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
              <div className="d-flex w-100 align-items-center justify-content-end gap-2">
                {filteredDeals.length > 0 && (
                  <>
                    <Select
                      options={pageOption}
                      value={pageOption.find(
                        (option) => option.value === perPageDeal.toString()
                      )}
                      onChange={handlePageDropDownChangeDeal}
                      isSearchable={false}
                      styles={customCommonStyles("225px")}
                    />
                    <Pagination
                      currentPage={currentPageDeal}
                      totalPages={totalPagesDeal}
                      onPageChange={handlepageChangeDeal}
                    />
                  </>
                )}
              </div>
              <div className="d-flex pb-2 pt-3 justify-content-between">
                <div className="selectedUser d-flex gap-1">
                  <span>Selected User</span>
                  <img src={logoConvert} alt="img" height={20} width={20} />
                  <span>{selectedDealItem?.dealName}</span>
                </div>
                <div className="d-flex gap-2">
                  <button
                    className="btn btn-outline-secondary border border-primary btn-sm px-3 py-2 text-dark"
                    onClick={() => setIsDealListModal(false)}
                  >
                    Cancel
                  </button>
                  <button
                    className="btn btnColor btn-sm px-3 text-white"
                    onClick={() => {
                      setIsDealListModal(false);
                      getAllAssignModules(
                        "DEALS",
                        ["CONTACTS", "ACCOUNTS"],
                        selectedDealItem.dealId
                      );
                    }}
                  >
                    Done
                  </button>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>

        {isProductOwnerModal ? (
          <ProductListOverviewModal
            isProductOwnerModal={isProductOwnerModal}
            setIsProductOwnerModal={setIsProductOwnerModal}
            onSelectedProductChange={handleSelectedProductListChange}
            defaultUser={selectedProduct}
          />
        ) : null}
      </>
    );
  };
  const quotesOverViewPage = () => {
    return (
      <div className="indiv_main_container ">
        {/* <p className="text-danger fw-bolder">Work in progress... </p> */}
        <header className="commonContainer_bg w-100 mb-2">
          <div className="d-flex justify-content-between px-3 pt-2">
            <span
              className="cursorPointer"
              onClick={() => {
                getAllQuote(currentPage, selectedOption);
                setInnerPage("list");
                setquotePageView("overview");
                setFetchQuoteInfo(null);
                const resetFields = Object.fromEntries(
                  Object.keys(editingFields).map((key) => [key, false])
                );
                setEditingFields({
                  ...resetFields,
                });
                setShowType("");
              }}
            >
              {leftArrow}
            </span>
            <span>
              <span
                className={`me-2 ${
                  selectedQuoteIndex === 0 ? "opacity-50" : "cursorPointer"
                }`}
                onClick={() => {
                  const leadIndex = allQuoteDetails.findIndex(
                    (lead) => lead.quoteId === quoteId
                  );
                  if (leadIndex !== 0) {
                    setSelectedQuoteIndex(leadIndex - 1);
                    setQuoteId(allQuoteDetails[leadIndex - 1].quoteId);
                    getQuotesOverviewDetail(
                      allQuoteDetails[leadIndex - 1].quoteId
                    );
                    getNotesDetails(
                      allQuoteDetails[leadIndex - 1].quoteId,
                      selectRecent
                    );
                    setFetchQuoteInfo(allQuoteDetails[leadIndex - 1]);
                  }
                }}
              >
                {leftAngleArrow}
              </span>
              <span
                className={`ms-1 ${
                  selectedQuoteIndex === allQuoteDetails.length - 1
                    ? "opacity-50"
                    : "cursorPointer"
                }`}
                onClick={() => {
                  const leadIndex = allQuoteDetails.findIndex(
                    (lead) => lead.quoteId === quoteId
                  );
                  if (leadIndex < allQuoteDetails.length - 1) {
                    setSelectedQuoteIndex(leadIndex + 1);
                    setQuoteId(allQuoteDetails[leadIndex + 1].quoteId);
                    getQuotesOverviewDetail(
                      allQuoteDetails[leadIndex + 1].quoteId
                    );
                    getNotesDetails(
                      allQuoteDetails[leadIndex + 1].quoteId,
                      selectRecent
                    );
                    setFetchQuoteInfo(allQuoteDetails[leadIndex + 1]);
                  }
                }}
              >
                {rightAngleArrow}
              </span>
            </span>
          </div>
          <div className=" d-flex justify-content-between">
            <div className="profile_logo_container d-flex pt-2">
              <div className="d-flex flex-column align-items-center justify-content-center ps-2 cursorPointer">
                <span className="first_letter_uppercase">
                  {quotesInfoOverview.subject}
                </span>
              </div>
            </div>
            <div className="d-flex  justify-content-end align-items-end  px-2 pb-2">
              <div className="d-flex gap-2 position-relative">
                <button
                  className="btn btn-secondary bg-white text-dark border border-primary"
                  onClick={handleEditQuote}
                >
                  Edit
                </button>
                <button
                  className="btn btn-secondary bg-white text-dark border border-primary"
                  onClick={(e) => {
                    e.stopPropagation();
                    setShowOptionsContact(!showOptionsContact);
                  }}
                >
                  ---
                </button>
                {showOptionsContact && (
                  <div
                    className="rounded  showOptions p-2"
                    style={{ top: "2.6rem" }}
                  >
                    <div
                      className="d-flex gap-3 align-items-center mt-2"
                      onClick={() => handleCreateQuoteClone()}
                    >
                      <img src={Close} alt="close" />
                      <div
                        style={{ color: "#515151", fontWeight: "300" }}
                        className="mt-1 cursorPointer ms-2"
                      >
                        Clone
                      </div>
                    </div>
                    {quotesInfoOverview.isShared === false && (
                      <div className="d-flex gap-3 align-items-center mt-2">
                        <img src={Share} alt="close" />
                        <div
                          style={{ color: "#515151", fontWeight: "300" }}
                          className="mt-2 cursorPointer ms-2"
                          onClick={() => {
                            if (!isLoading) {
                              getAllInfoShare(quoteId);
                            }
                          }}
                        >
                          Share
                        </div>
                      </div>
                    )}

                    <div
                      className="d-flex gap-3 align-items-center mt-2"
                      onClick={handleDeleteQuote}
                    >
                      <img src={Delete} alt="close" />
                      <div
                        style={{ color: "#515151", fontWeight: "300" }}
                        className="mt-2 cursorPointer ms-2"
                      >
                        Delete
                      </div>
                    </div>
                    <div style={{ borderBottom: "1px solid #515151s" }}></div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </header>

        <div className="detailList_main_container pt-2 commonContainer_bg">
          <div className="detailList_main_container d-flex py-2 ">
            <div
              className={` pt-3 cursorPointer ${isShrinked ? "" : "w-25"}`}
              onClick={() => {
                setIsShrinked(!isShrinked);
              }}
            >
              {isShrinked ? expandArrow : shrinkArrow}
            </div>
            <div
              className={`d-flex justify-content-between px-2 ${
                isShrinked ? "w-100" : "w-75 ps-3"
              }`}
            >
              <span className="pt-3 ps-2">
                Last Update: {quotesInfoOverview.lastUpdate}
              </span>
              <div className="d-flex gap-4 justify-content-between px-2 bg-white px-1  rounded-5 py-1 cursorPointer">
                <span
                  // className=""
                  className={`} ${
                    quotePageView === "overview"
                      ? "overviewTimelineTab "
                      : "  d-flex justify-content-center align-items-center"
                  }`}
                  onClick={() => setquotePageView("overview")}
                >
                  Overview
                </span>
                <span
                  className={`btn btn-secondary} ${
                    quotePageView === "timeline"
                      ? "overviewTimelineTab"
                      : " d-flex justify-content-center align-items-center"
                  }`}
                  onClick={() => {
                    setquotePageView("timeline");
                    getAllTimelineQuotes();
                  }}
                >
                  Timeline
                </span>
              </div>
            </div>
          </div>

          <div className="detailList_content_container d-flex justify-content-between px-2  py-2 bg-white">
            <div className="w-25 relatedListContainer  rounded commonContainer_bg me-2 ">
              <div className="d-flex">
                <p className="opacity-57 ps-2 opacity-75 m-0 py-2">
                  Related List
                </p>
              </div>
              <div className="horizontal_line mx-1"></div>
              <ul className="relateList_container">
                {notePermission === "TRUE" && (
                  <li
                    className={`cursorPointer py-2 relatedList_tab rounded-1 }`}
                    onClick={() => {
                      if (quotePageView === "timeline") {
                        setquotePageView("overview");
                        // setActiveTab("note");
                        setTimeout(() => scrollToSection("notesSection"), 0);
                      } else {
                        // setActiveTab("note");
                        scrollToSection("notesSection");
                      }
                    }}
                  >
                    <span>
                      <img src={noteIcon} alt="img" height={20} width={20} />
                    </span>
                    Notes
                  </li>
                )}

                <li
                  className={`cursorPointer py-2 relatedList_tab rounded-1 }`}
                  onClick={() => {
                    if (quotePageView === "timeline") {
                      setquotePageView("overview");
                      // setActiveTab("sales");
                      setTimeout(() => scrollToSection("salesOrders"), 0);
                    } else {
                      // setActiveTab("sales");
                      scrollToSection("salesOrders");
                    }
                  }}
                >
                  <span>
                    <img src={inviteIcon} alt="img" height={20} width={20} />
                  </span>
                  Sales Orders
                </li>

                <li
                  className={`cursorPointer py-2 relatedList_tab rounded-1 }`}
                  onClick={() => {
                    if (quotePageView === "timeline") {
                      setquotePageView("overview");
                      // setActiveTab("openActivity");
                      setTimeout(() => scrollToSection("openActivity"), 0);
                    } else {
                      // setActiveTab("openActivity");
                      scrollToSection("openActivity");
                    }
                  }}
                >
                  <span>
                    <img
                      src={openActivityIcon}
                      alt="img"
                      height={20}
                      width={20}
                    />
                  </span>
                  Open Activity
                </li>
                <li
                  className={`cursorPointer py-2 relatedList_tab rounded-1 }`}
                  onClick={() => {
                    if (quotePageView === "timeline") {
                      setquotePageView("overview");
                      // setActiveTab("closeActivity");
                      setTimeout(() => scrollToSection("closeActivity"), 0);
                    } else {
                      // setActiveTab("closeActivity");
                      scrollToSection("closeActivity");
                    }
                  }}
                >
                  <span>
                    <img
                      src={closeActivityIcon}
                      alt="img"
                      height={20}
                      width={20}
                    />
                  </span>
                  Closed Activity
                </li>
              </ul>
            </div>

            {quotePageView === "overview" ? (
              <div className="w-75 bg-white p-3 leadDetail_main_Container rounded-2">
                <div className="lead_DetailContainer  rounded-2 commonContainer_bg d-flex justify-content-between align-items-center">
                  <div className="w-100 d-flex flex-column">
                    {fieldLabels.map((field, i) => {
                      const fieldValue =
                        field.value ||
                        field.name
                          .replace(/\s+/g, "")
                          .replace(/^(.)/, (match) => match.toLowerCase());
                      return field.isShowed ? (
                        <div className="d-flex w-50 ps-3" key={i}>
                          <div className="w-50 position-relative">
                            <div
                              onClick={(e) => {
                                e.stopPropagation();
                                setSearchField("");
                                setSelectedField(
                                  selectedField === field.name ? "" : field.name
                                );
                              }}
                              className={`cursorPointer d-flex justify-content-between w-100 my-1 p-1 ${
                                selectedField === field.name
                                  ? "outline_border rounded-1"
                                  : ""
                              }`}
                            >
                              <span>{field.name}</span>
                              <img
                                src={selectedField === field.name ? up : down}
                                alt="img"
                                height={20}
                                width={20}
                                className="pt-1"
                              />
                            </div>
                            {selectedField === field.name && (
                              <div
                                className="position-absolute field_container w-100"
                                onClick={(e) => {
                                  e.stopPropagation();
                                }}
                              >
                                <div className="">
                                  <label
                                    htmlFor="selectField"
                                    className="position-relative w-100"
                                  >
                                    <input
                                      id="selectField"
                                      type="text"
                                      className="rounded-2 p-1 w-100 pe-4"
                                      placeholder="Search"
                                      value={searchField}
                                      onChange={(e) => {
                                        if (e.target.value.charAt(0) !== " ") {
                                          const value = e.target.value.replace(
                                            /[^a-zA-Z ]+|(\s)\s+/g,
                                            "$1"
                                          );
                                          setSearchField(value);
                                        }
                                        // const value = e.target.value.replace(
                                        //   /[^a-zA-Z]/g,
                                        //   ""
                                        // );
                                        // setSearchField(value);

                                        // const filteredData =
                                        //   fieldDropdowns.filter((field) =>
                                        //     field.name
                                        //       .toLowerCase()
                                        //       .includes(
                                        //         value.trim().toLowerCase()
                                        //       )
                                        //   );
                                        // setShowSearchFields(
                                        //   /^[a-zA-Z]+$/.test(value)
                                        //     ? filteredData
                                        //     : fieldLabels
                                        // );
                                      }}
                                    />
                                    <img
                                      className="position-absolute mt-1 me-2 end-0 pt-1 cursorPointer"
                                      onClick={() => {
                                        setSearchField("");
                                      }}
                                      src={searchIcon}
                                      alt="search"
                                      width={20}
                                    />
                                  </label>

                                  <div className="w-100 rounded-2">
                                    <div className="border border-1 field_container_dropdown rounded-1">
                                      {showSearchFields
                                        .filter((field) =>
                                          field.name
                                            .toLowerCase()
                                            .includes(
                                              searchField.trim().toLowerCase()
                                            )
                                        )
                                        .sort((a, b) =>
                                          a.name.localeCompare(b.name)
                                        )
                                        .map(
                                          (field, i) =>
                                            (selectedField === field.name ||
                                              !field.isShowed) && (
                                              <div
                                                key={field.name}
                                                className="d-flex gap-2 py-2 cursorPointer rounded-2 align-items-center"
                                                onClick={() => {
                                                  selectedField === field.name
                                                    ? setSelectedField("")
                                                    : handleFieldClick(
                                                        field,
                                                        i
                                                      );
                                                }}
                                              >
                                                <span className="right_tick">
                                                  {selectedField === field.name
                                                    ? tick
                                                    : ""}
                                                </span>
                                                <span className="ps-2">
                                                  {field.name}
                                                </span>
                                              </div>
                                            )
                                        )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                          <div className={`w-50 mt-1 ms-5 position-relative`}>
                            {field.type === "input" ? (
                              <EditableInput
                                fieldType={
                                  ["validUntil"].includes(fieldValue)
                                    ? "date"
                                    : "text"
                                }
                                className="ps-3"
                                inputType={fieldValue}
                                handleIndividualInputChange={
                                  handleIndividualInputChange
                                }
                                editingFields={editingFields}
                                handleEditClick={handleEditClick}
                                handleCancelClick={handleCancelClick}
                                handleUpdateClick={handleUpdateClick}
                                updatedFields={updatedFields}
                                accountOverviewDetails={quotesInfoOverview}
                                showType={showType}
                                setShowType={setShowType}
                              />
                            ) : field.type === "dropdown" ? (
                              <div
                                className="position-relative d-flex w-100"
                                style={{ alignItems: "center" }}
                                onClick={() => {
                                  handleEditClick(fieldValue);
                                }}
                              >
                                {editingFields[fieldValue] &&
                                showType === fieldValue ? (
                                  <Select
                                    value={updatedFields[fieldValue]}
                                    // value={accountInfoDetails[fieldValue]}
                                    menuPlacement="auto"
                                    onChange={(selectedOption) =>
                                      handleChangeIndDropDown(
                                        selectedOption,
                                        fieldValue
                                      )
                                    }
                                    options={
                                      field.name === "Carrier"
                                        ? carrierOptions
                                        : stageOptions
                                    }
                                    styles={fieldDropdownStyles}
                                    isSearchable={false}
                                  />
                                ) : (
                                  <input
                                    type="text"
                                    className={`w-100 cursorPointer indvEditInput
                        ${
                          showType === fieldValue
                            ? "selectedBorder "
                            : "unselectBorder bg-transparent"
                        }`}
                                    value={
                                      quotesInfoOverview[fieldValue]?.value
                                    }
                                    name={fieldValue}
                                    onClick={(e) => {
                                      if (showType === "") {
                                        updatedFields[fieldValue] =
                                          quotesInfoOverview[fieldValue];
                                        setShowType(e.target.name);
                                        setUpdatedFields(updatedFields);
                                      }
                                      handleEditClick(fieldValue);
                                    }}
                                    // readOnly={showType !== "emailId"}
                                    readOnly={true}
                                  />
                                )}
                                {editingFields[fieldValue] &&
                                  showType === fieldValue && (
                                    <div className="d-flex gap-2">
                                      <span
                                        onClick={() => {
                                          setShowType("");
                                          handleUpdateClick(fieldValue);
                                        }}
                                        className="ps-2 cursorPointer"
                                      >
                                        <img
                                          src={updateIcon}
                                          alt="img"
                                          height={20}
                                          width={20}
                                        />
                                      </span>
                                      <span
                                        onClick={() => {
                                          setShowType("");
                                          // handleUpdateClick(inputType);
                                          handleCancelClick(fieldValue);
                                        }}
                                        className="cursorPointer"
                                      >
                                        <img
                                          src={cancelIcon}
                                          alt="img"
                                          height={20}
                                          width={20}
                                        />
                                      </span>
                                    </div>
                                  )}
                              </div>
                            ) : field.type === "readOnly" ? (
                              <div>
                                <span className="d-flex flex-column">
                                  <span>
                                    {field.name === "Created By" ||
                                    field.name === "Modified By" ||
                                    field.name === "Quote Number" ||
                                    field.name === "Deal Name" ||
                                    field.name === "Contact Name" ||
                                    field.name === "Account Name"
                                      ? quotesInfoOverview[
                                          field.name === "Created By"
                                            ? "createdBy"
                                            : field.name === "Modified By"
                                            ? "modifiedBy"
                                            : field.name === "Quote Number"
                                            ? "quoteNumber"
                                            : field.name === "Deal Name"
                                            ? "dealName"
                                            : field.name === "Contact Name"
                                            ? "contactName"
                                            : field.name === "Account Name"
                                            ? "accountName"
                                            : "tax"
                                        ]
                                      : quoteItemTotalDetails[
                                          field.name === "Adjustment"
                                            ? "adjustment"
                                            : field.name === "Grand Total"
                                            ? "grandTotal"
                                            : field.name === "Sub Total"
                                            ? "subTotal"
                                            : field.name === "Tax"
                                            ? "tax"
                                            : "discount"
                                        ]}
                                  </span>
                                  <span className="small opacity-75">
                                    {(field.name === "Created By" ||
                                      field.name === "Modified By") &&
                                      quotesInfoOverview[
                                        field.name === "Created By"
                                          ? "createdDate"
                                          : "modifiedDate"
                                      ]}
                                  </span>
                                </span>
                              </div>
                            ) : (
                              <div>
                                {field.type === "checkbox" ? (
                                  <div className="position-relative">
                                    <input
                                      type="checkbox"
                                      name={field.name}
                                      checked={updatedFields.emailOptOut}
                                      // checked={clcikedEmailOpt}
                                      onChange={(e) => {
                                        handleEditClick(fieldValue);
                                        setUpdatedFields((prevFields) => ({
                                          ...prevFields,
                                          emailOptOut: e.target.checked,
                                        }));
                                      }}
                                      className="mt-2"
                                      // checked={quoteOverviewDetails["emailOptOut"]}
                                    />
                                    {editingFields["emailOptOut"] && (
                                      <div
                                        className="position-absolute end-0 top-0"
                                        style={{ marginRight: "1rem" }}
                                      >
                                        <div className="d-flex gap-2 ">
                                          <span
                                            onClick={(e) => {
                                              e.stopPropagation();
                                              setShowType("");
                                              handleUpdateClick(fieldValue);
                                            }}
                                            className="ps-2 cursorPointer"
                                          >
                                            <img
                                              src={updateIcon}
                                              alt="img"
                                              height={20}
                                              width={20}
                                            />
                                          </span>
                                          <span
                                            onClick={(e) => {
                                              e.stopPropagation();
                                              setShowType("");
                                              handleCancelClick(fieldValue);
                                            }}
                                            className="cursorPointer"
                                          >
                                            <img
                                              src={cancelIcon}
                                              alt="img"
                                              height={20}
                                              width={20}
                                            />
                                          </span>
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                ) : (
                                  <div className="position-relative">
                                    <div
                                      className="d-flex   position-relative"
                                      // onClick={handleOwnerInputClick}
                                      onClick={(e) => {
                                        handleOwnerInputClick(e);
                                        setShowModalType("changeOwner");
                                      }}
                                    >
                                      <input
                                        type="text"
                                        //   placeholder="Vendor Owner"
                                        className="w-100 cursorPointer border-0 indivOwnerInput indvEditInput"
                                        value={selectedItem?.name}
                                        readOnly
                                      />
                                    </div>
                                  </div>
                                )}
                              </div>
                            )}
                          </div>
                        </div>
                      ) : null;
                    })}
                  </div>
                </div>
                {/*------ lead Information Conatiner -----------*/}

                <div className="lead_Information_mainContainer commonContainer_bg     rounded-2  d-flex flex-column my-2 py-2">
                  <h5 className="w-100 text-primary px-1 pt-1 ms-2 mt-1">
                    Quotes Information
                  </h5>

                  <div className="d-flex justify-content-between">
                    <div className="leadContact_container d-flex gap-4 w-100 ps-2 pe-2">
                      <div className="w-50 ">
                        {fieldIndividualDropdowns.slice(0, 6).map((field) => {
                          const fieldValue =
                            field.value ||
                            field.name
                              .replace(/\s+/g, "")
                              .replace(/^(.)/, (match) => match.toLowerCase());
                          return (
                            <div
                              className="d-flex justify-content-between align-items-center my-2 w-100 gap-3"
                              key={fieldValue}
                            >
                              <div className="opacity-75 p-2 w-100">
                                {field.name}
                              </div>
                              <div className="w-100">
                                {field.type === "other" ? (
                                  <div className="position-relative">
                                    <div>
                                      <div>
                                        <div
                                          className="d-flex gap-1"
                                          onClick={() => {
                                            setShowModalType("changeOwner");
                                            setListVisible(false);
                                          }}
                                        >
                                          <input
                                            type="text"
                                            //   placeholder="Vendor Owner"
                                            className={`w-100  cursorPointer  rounded-2  indvEditInput border-0 indivOwnerInput ${
                                              showType === "salesOwnerId"
                                                ? "selectedBorder "
                                                : "unselectBorder bg-transparent"
                                            }`}
                                            value={selectedOwnerItem?.name}
                                            readOnly
                                            style={{
                                              background: "transparent",
                                              border: "none",
                                            }}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                ) : field.type === "input" ? (
                                  <EditableInput
                                    fieldType={
                                      ["validUntil"].includes(fieldValue)
                                        ? "date"
                                        : "text"
                                    }
                                    className="ps-3"
                                    inputType={fieldValue}
                                    handleIndividualInputChange={
                                      handleIndividualInputChange
                                    }
                                    editingFields={editingFields}
                                    handleEditClick={handleEditClick}
                                    handleCancelClick={handleCancelClick}
                                    handleUpdateClick={handleUpdateClick}
                                    updatedFields={updatedFields}
                                    accountOverviewDetails={quotesInfoOverview}
                                    showType={showType}
                                    setShowType={setShowType}
                                  />
                                ) : field.type === "dropdown" ? (
                                  <div
                                    className="position-relative d-flex w-100"
                                    style={{ alignItems: "center" }}
                                    onClick={() => {
                                      handleEditClick(fieldValue);
                                    }}
                                  >
                                    {editingFields[fieldValue] &&
                                    showType === fieldValue ? (
                                      <Select
                                        value={updatedFields[fieldValue]}
                                        // value={accountInfoDetails[fieldValue]}
                                        menuPlacement="auto"
                                        onChange={(selectedOption) =>
                                          handleChangeIndDropDown(
                                            selectedOption,
                                            fieldValue
                                          )
                                        }
                                        options={
                                          field.name === "Carrier"
                                            ? carrierOptions
                                            : stageOptions
                                        }
                                        styles={fieldDropdownStyles}
                                        isSearchable={false}
                                      />
                                    ) : (
                                      <input
                                        type="text"
                                        className={`w-100 cursorPointer indvEditInput
                      ${
                        showType === fieldValue
                          ? "selectedBorder "
                          : "unselectBorder bg-transparent"
                      }`}
                                        value={
                                          quotesInfoOverview[fieldValue]?.value
                                        }
                                        name={fieldValue}
                                        onClick={(e) => {
                                          if (showType === "") {
                                            updatedFields[fieldValue] =
                                              quotesInfoOverview[fieldValue];
                                            setShowType(e.target.name);
                                            setUpdatedFields(updatedFields);
                                          }
                                          handleEditClick(fieldValue);
                                        }}
                                        // readOnly={showType !== "emailId"}
                                        readOnly={true}
                                      />
                                    )}
                                    {editingFields[fieldValue] &&
                                      showType === fieldValue && (
                                        <div className="d-flex gap-2">
                                          <span
                                            onClick={() => {
                                              setShowType("");
                                              handleUpdateClick(fieldValue);
                                            }}
                                            className="ps-2 cursorPointer"
                                          >
                                            <img
                                              src={updateIcon}
                                              alt="img"
                                              height={20}
                                              width={20}
                                            />
                                          </span>
                                          <span
                                            onClick={() => {
                                              setShowType("");
                                              // handleUpdateClick(inputType);
                                              handleCancelClick(fieldValue);
                                            }}
                                            className="cursorPointer"
                                          >
                                            <img
                                              src={cancelIcon}
                                              alt="img"
                                              height={20}
                                              width={20}
                                            />
                                          </span>
                                        </div>
                                      )}
                                  </div>
                                ) : field.type === "readOnly" ? (
                                  <div>
                                    <span className="d-flex flex-column">
                                      <span>
                                        {field.name === "Created By" ||
                                        field.name === "Modified By" ||
                                        field.name === "Quote Number" ||
                                        field.name === "Deal Name" ||
                                        field.name === "Contact Name" ||
                                        field.name === "Account Name"
                                          ? quotesInfoOverview[
                                              field.name === "Created By"
                                                ? "createdBy"
                                                : field.name === "Modified By"
                                                ? "modifiedBy"
                                                : field.name === "Quote Number"
                                                ? "quoteNumber"
                                                : field.name === "Deal Name"
                                                ? "dealName"
                                                : field.name === "Contact Name"
                                                ? "contactName"
                                                : field.name === "Account Name"
                                                ? "accountName"
                                                : "tax"
                                            ]
                                          : quoteItemTotalDetails[
                                              field.name === "Adjustment"
                                                ? "adjustment"
                                                : field.name === "Grand Total"
                                                ? "grandTotal"
                                                : field.name === "Sub Total"
                                                ? "subTotal"
                                                : field.name === "Tax"
                                                ? "tax"
                                                : "discount"
                                            ]}
                                      </span>
                                      <span className="small opacity-75">
                                        {(field.name === "Created By" ||
                                          field.name === "Modified By") &&
                                          quotesInfoOverview[
                                            field.name === "Created By"
                                              ? "createdDate"
                                              : "modifiedDate"
                                          ]}
                                      </span>
                                    </span>
                                  </div>
                                ) : (
                                  <div>
                                    {field.type === "checkbox" ? (
                                      <div className="position-relative">
                                        <input
                                          type="checkbox"
                                          name={field.name}
                                          checked={updatedFields.emailOptOut}
                                          // checked={clcikedEmailOpt}
                                          onChange={(e) => {
                                            handleEditClick(fieldValue);
                                            setUpdatedFields((prevFields) => ({
                                              ...prevFields,
                                              emailOptOut: e.target.checked,
                                            }));
                                          }}
                                          className="mt-2"
                                          // checked={quoteOverviewDetails["emailOptOut"]}
                                        />
                                        {editingFields["emailOptOut"] && (
                                          <div
                                            className="position-absolute end-0 top-0"
                                            style={{ marginRight: "1rem" }}
                                          >
                                            <div className="d-flex gap-2 ">
                                              <span
                                                onClick={(e) => {
                                                  e.stopPropagation();
                                                  setShowType("");
                                                  handleUpdateClick(fieldValue);
                                                }}
                                                className="ps-2 cursorPointer"
                                              >
                                                <img
                                                  src={updateIcon}
                                                  alt="img"
                                                  height={20}
                                                  width={20}
                                                />
                                              </span>
                                              <span
                                                onClick={(e) => {
                                                  e.stopPropagation();
                                                  setShowType("");
                                                  handleCancelClick(fieldValue);
                                                }}
                                                className="cursorPointer"
                                              >
                                                <img
                                                  src={cancelIcon}
                                                  alt="img"
                                                  height={20}
                                                  width={20}
                                                />
                                              </span>
                                            </div>
                                          </div>
                                        )}
                                      </div>
                                    ) : (
                                      <div className="position-relative">
                                        <div
                                          className="d-flex   position-relative"
                                          // onClick={handleOwnerInputClick}
                                          onClick={(e) => {
                                            handleOwnerInputClick(e);
                                            setShowModalType("changeOwner");
                                          }}
                                        >
                                          <input
                                            type="text"
                                            //   placeholder="Vendor Owner"
                                            className="w-100 cursorPointer border-0 indivOwnerInput indvEditInput"
                                            value={selectedItem?.name}
                                            readOnly
                                          />
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                )}
                              </div>
                            </div>
                          );
                        })}
                      </div>
                      <div className="w-50 ">
                        {fieldIndividualDropdowns.slice(6, 12).map((field) => {
                          const fieldValue =
                            field.value ||
                            field.name
                              .replace(/\s+/g, "")
                              .replace(/^(.)/, (match) => match.toLowerCase());
                          return (
                            <div
                              className="d-flex justify-content-between align-items-center my-2 w-100 gap-3"
                              key={fieldValue}
                            >
                              <div className="opacity-75 p-2 w-100">
                                {field.name}
                              </div>
                              <div className="w-100">
                                {field.type === "input" ? (
                                  <EditableInput
                                    fieldType={
                                      ["validUntilInfo"].includes(fieldValue)
                                        ? "date"
                                        : "text"
                                    }
                                    className="ps-3"
                                    inputType={fieldValue}
                                    handleIndividualInputChange={
                                      handleIndividualInputChange
                                    }
                                    editingFields={editingFields}
                                    handleEditClick={handleEditClick}
                                    handleCancelClick={handleCancelClick}
                                    handleUpdateClick={handleUpdateClick}
                                    updatedFields={updatedFields}
                                    accountOverviewDetails={quotesInfoOverview}
                                    showType={showType}
                                    setShowType={setShowType}
                                  />
                                ) : field.type === "dropdown" ? (
                                  <div className="">
                                    {editingFields[fieldValue] ? (
                                      <>
                                        <Select
                                          value={updatedFields[fieldValue]}
                                          menuPlacement="auto"
                                          isSearchable={false}
                                          onChange={(selectedOption) => {
                                            // setClickedDropdownObj(selectedOption);
                                            setUpdatedFields((prevFields) => ({
                                              ...prevFields,
                                              [fieldValue]: selectedOption,
                                            }));
                                          }}
                                          options={
                                            field.name === "Carrier"
                                              ? carrierOptions
                                              : stageOptions
                                          }
                                          styles={fieldDropdownStyles}
                                        />
                                        <div
                                          className="position-absolute end-0 top-0 mt-1"
                                          style={{ marginRight: "-3.5rem" }}
                                        >
                                          <div className="d-flex gap-2 ">
                                            <span
                                              onClick={(e) => {
                                                e.stopPropagation();
                                                setShowType("");
                                                handleUpdateClick(fieldValue);
                                              }}
                                              className="ps-2 cursorPointer"
                                            >
                                              <img
                                                src={updateIcon}
                                                alt="img"
                                                height={20}
                                                width={20}
                                              />
                                            </span>
                                            <span
                                              onClick={(e) => {
                                                e.stopPropagation();
                                                setShowType("");
                                                handleCancelClick(fieldValue);
                                              }}
                                              className="cursorPointer"
                                            >
                                              <img
                                                src={cancelIcon}
                                                alt="img"
                                                height={20}
                                                width={20}
                                              />
                                            </span>
                                          </div>
                                        </div>
                                      </>
                                    ) : (
                                      <input
                                        type="text"
                                        readOnly
                                        value={updatedFields[fieldValue]?.value}
                                        onClick={() => {
                                          handleEditClick(fieldValue);
                                        }}
                                        className="w-100 bg-transparent border rounded-1 p-1"
                                      />
                                    )}
                                  </div>
                                ) : field.type === "readOnly" ? (
                                  <div>
                                    <span className="d-flex flex-column">
                                      <span>
                                        {field.name === "Created By" ||
                                        field.name === "Modified By" ||
                                        field.name === "Quote Number" ||
                                        field.name === "Deal Name" ||
                                        field.name === "Contact Name" ||
                                        field.name === "Account Name"
                                          ? quotesInfoOverview[
                                              field.name === "Created By"
                                                ? "createdBy"
                                                : field.name === "Modified By"
                                                ? "modifiedBy"
                                                : field.name === "Quote Number"
                                                ? "quoteNumber"
                                                : field.name === "Deal Name"
                                                ? "dealName"
                                                : field.name === "Contact Name"
                                                ? "contactName"
                                                : field.name === "Account Name"
                                                ? "accountName"
                                                : "tax"
                                            ]
                                          : quoteItemTotalDetails[
                                              field.name === "Adjustment"
                                                ? "adjustment"
                                                : field.name === "Grand Total"
                                                ? "grandTotal"
                                                : field.name === "Sub Total"
                                                ? "subTotal"
                                                : field.name === "Tax"
                                                ? "tax"
                                                : "discount"
                                            ]}
                                      </span>
                                      <span className="small opacity-75">
                                        {(field.name === "Created By" ||
                                          field.name === "Modified By") &&
                                          quotesInfoOverview[
                                            field.name === "Created By"
                                              ? "createdDate"
                                              : "modifiedDate"
                                          ]}
                                      </span>
                                    </span>
                                  </div>
                                ) : (
                                  <div>
                                    {field.type === "checkbox" ? (
                                      <div className="position-relative">
                                        <input
                                          type="checkbox"
                                          name={field.name}
                                          checked={updatedFields.emailOptOut}
                                          // checked={clcikedEmailOpt}
                                          onChange={(e) => {
                                            handleEditClick(fieldValue);
                                            setUpdatedFields((prevFields) => ({
                                              ...prevFields,
                                              emailOptOut: e.target.checked,
                                            }));
                                          }}
                                          className="mt-2"
                                          // checked={quoteOverviewDetails["emailOptOut"]}
                                        />
                                        {editingFields["emailOptOut"] && (
                                          <div
                                            className="position-absolute end-0 top-0"
                                            style={{ marginRight: "1rem" }}
                                          >
                                            <div className="d-flex gap-2 ">
                                              <span
                                                onClick={(e) => {
                                                  e.stopPropagation();
                                                  setShowType("");
                                                  handleUpdateClick(fieldValue);
                                                }}
                                                className="ps-2 cursorPointer"
                                              >
                                                <img
                                                  src={updateIcon}
                                                  alt="img"
                                                  height={20}
                                                  width={20}
                                                />
                                              </span>
                                              <span
                                                onClick={(e) => {
                                                  e.stopPropagation();
                                                  setShowType("");
                                                  handleCancelClick(fieldValue);
                                                }}
                                                className="cursorPointer"
                                              >
                                                <img
                                                  src={cancelIcon}
                                                  alt="img"
                                                  height={20}
                                                  width={20}
                                                />
                                              </span>
                                            </div>
                                          </div>
                                        )}
                                      </div>
                                    ) : (
                                      <div className="position-relative">
                                        <div
                                          className="d-flex   position-relative"
                                          // onClick={handleOwnerInputClick}
                                          onClick={(e) => {
                                            handleOwnerInputClick(e);
                                            setShowModalType("changeOwner");
                                          }}
                                        >
                                          <input
                                            type="text"
                                            //   placeholder="Vendor Owner"
                                            className="w-100 cursorPointer border-0 indivOwnerInput indvEditInput"
                                            value={selectedItem?.name}
                                            readOnly
                                          />
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                )}
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                  <h5 className="w-100 text-primary px-1 pt-1 ms-2 mt-2">
                    Address Information
                  </h5>
                  <div className="d-flex justify-content-between">
                    <div className="leadContact_container d-flex gap-4 w-100 ps-2 pe-2">
                      <div className="w-50">
                        {fieldIndividualDropdowns.slice(12, 17).map((field) => {
                          const fieldValue =
                            field.value ||
                            field.name
                              .replace(/\s+/g, "")
                              .replace(/^(.)/, (match) => match.toLowerCase());

                          return (
                            <div
                              className="d-flex justify-content-between align-items-center my-2 w-100 gap-3"
                              key={fieldValue}
                            >
                              <div className="opacity-75 p-2 w-100">
                                {field.name}
                              </div>
                              <div className="w-100">
                                <EditableInput
                                  fieldType={
                                    ["validUntil"].includes(fieldValue)
                                      ? "date"
                                      : "text"
                                  }
                                  className="ps-3"
                                  inputType={fieldValue}
                                  handleIndividualInputChange={
                                    handleIndividualInputChange
                                  }
                                  editingFields={editingFields}
                                  handleEditClick={handleEditClick}
                                  handleCancelClick={handleCancelClick}
                                  handleUpdateClick={handleUpdateClick}
                                  updatedFields={updatedFields}
                                  accountOverviewDetails={quotesInfoOverview}
                                  showType={showType}
                                  setShowType={setShowType}
                                />
                              </div>
                            </div>
                          );
                        })}
                      </div>
                      <div className="w-50">
                        {fieldIndividualDropdowns.slice(17, 22).map((field) => {
                          const fieldValue =
                            field.value ||
                            field.name
                              .replace(/\s+/g, "")
                              .replace(/^(.)/, (match) => match.toLowerCase());
                          return (
                            <div
                              className="d-flex justify-content-between align-items-center my-2 w-100 gap-3"
                              key={fieldValue}
                            >
                              <div className="opacity-75 p-2 w-100">
                                {field.name}
                              </div>
                              <div className="w-100">
                                <EditableInput
                                  fieldType={
                                    ["validUntil"].includes(fieldValue)
                                      ? "date"
                                      : "text"
                                  }
                                  className="ps-3"
                                  inputType={fieldValue}
                                  handleIndividualInputChange={
                                    handleIndividualInputChange
                                  }
                                  editingFields={editingFields}
                                  handleEditClick={handleEditClick}
                                  handleCancelClick={handleCancelClick}
                                  handleUpdateClick={handleUpdateClick}
                                  updatedFields={updatedFields}
                                  accountOverviewDetails={quotesInfoOverview}
                                  showType={showType}
                                  setShowType={setShowType}
                                />
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </div>

                  <h5 className="w-100 text-primary px-1 pt-1 ms-2 mt-2">
                    Quoted Items
                  </h5>
                  <div
                    className="horizontal-scroll-container mb-2"
                    style={{
                      overflowX: "auto",
                      width: "100%",
                      maxWidth: "100%",
                      overflow: "hidden",
                      border: "none",
                      padding: "1rem",
                    }}
                  >
                    <table
                      className="table"
                      style={{
                        tableLayout: "fixed",
                        minWidth: "700px",
                        margin: "0px",
                      }}
                    >
                      <colgroup>
                        <col style={{ width: "3%" }} />
                        <col style={{ width: "10%" }} />
                        <col style={{ width: "5%" }} />
                        <col style={{ width: "5%" }} />
                        <col style={{ width: "5%" }} />
                        <col style={{ width: "5%" }} />
                        <col style={{ width: "5%" }} />
                        <col style={{ width: "5%" }} />
                      </colgroup>
                      <thead>
                        <tr className="mb-2">
                          <th
                            style={{
                              backgroundColor: "#EFEFEF",
                              border: "1px solid rgba(185, 185, 185, 0.5)",
                            }}
                          >
                            SL.NO
                          </th>
                          <th
                            style={{
                              backgroundColor: "#EFEFEF",
                              border: "1px solid rgba(185, 185, 185, 0.5)",
                            }}
                          >
                            Product Name
                          </th>
                          <th
                            style={{
                              backgroundColor: "#EFEFEF",
                              border: "1px solid rgba(185, 185, 185, 0.5)",
                            }}
                          >
                            Quantity
                          </th>
                          <th
                            style={{
                              backgroundColor: "#EFEFEF",
                              border: "1px solid rgba(185, 185, 185, 0.5)",
                            }}
                          >
                            List Price (₹)
                          </th>
                          <th
                            style={{
                              backgroundColor: "#EFEFEF",
                              border: "1px solid rgba(185, 185, 185, 0.5)",
                            }}
                          >
                            Amount (₹)
                          </th>
                          <th
                            style={{
                              backgroundColor: "#EFEFEF",
                              border: "1px solid rgba(185, 185, 185, 0.5)",
                            }}
                          >
                            Discount (₹)
                          </th>
                          <th
                            style={{
                              backgroundColor: "#EFEFEF",
                              border: "1px solid rgba(185, 185, 185, 0.5)",
                            }}
                          >
                            Tax (%)
                          </th>
                          <th
                            style={{
                              backgroundColor: "#EFEFEF",
                              border: "1px solid rgba(185, 185, 185, 0.5)",
                            }}
                          >
                            Total (₹)
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {quotesItemInfo?.map((row, index) => (
                          <tr key={index}>
                            <td
                              style={{
                                backgroundColor: "white",
                                border: "1px solid rgba(185, 185, 185, 0.5)",
                              }}
                            >
                              {index + 1}.
                            </td>
                            <td
                              style={{
                                backgroundColor: "white",
                                border: "1px solid rgba(185, 185, 185, 0.5)",
                                display: "flex",
                                flexDirection: "column",
                              }}
                            >
                              <span>{row?.productName}</span>
                              <span className="opacity-75 small">
                                {row?.description}
                              </span>
                            </td>
                            <td
                              style={{
                                backgroundColor: "white",
                                border: "1px solid rgba(185, 185, 185, 0.5)",
                              }}
                            >
                              {row?.quantity}
                            </td>
                            <td
                              style={{
                                backgroundColor: "white",
                                border: "1px solid rgba(185, 185, 185, 0.5)",
                              }}
                            >
                              {row?.listPrice}
                            </td>
                            <td
                              style={{
                                backgroundColor: "white",
                                border: "1px solid rgba(185, 185, 185, 0.5)",
                              }}
                            >
                              {row?.amount}
                            </td>
                            <td
                              style={{
                                backgroundColor: "white",
                                border: "1px solid rgba(185, 185, 185, 0.5)",
                              }}
                            >
                              {row?.discount.toFixed(2)}
                            </td>
                            <td
                              style={{
                                backgroundColor: "white",
                                border: "1px solid rgba(185, 185, 185, 0.5)",
                              }}
                            >
                              {row?.tax}
                            </td>
                            <td
                              style={{
                                backgroundColor: "white",
                                border: "1px solid rgba(185, 185, 185, 0.5)",
                              }}
                            >
                              {row?.total}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      width: "max-content",
                      border: "1.86px solid rgba(226, 229, 235, 1)",
                      boxShadow: "0px 0px 18.12px 0px rgba(0, 0, 0, 0.03)",
                      background: "rgba(255, 255, 255, 1)",
                      borderRadius: "10px",
                      alignSelf: "end",
                      marginRight: "1rem",
                    }}
                    className="table-responsive"
                  >
                    <table
                      className="table"
                      style={{
                        borderCollapse: "collapse",
                        width: "max-content",
                        margin: "0",
                        boxShadow: "0px 0px 18.12px 0px rgba(0, 0, 0, 0.03)",
                      }}
                    >
                      {" "}
                      <thead style={{ width: "max-content" }}>
                        <tr className="mb-2">
                          <th
                            scope="col"
                            style={{
                              backgroundColor: "#F8F8F8",
                              width: "max-content",
                            }}
                          >
                            Sub Total
                          </th>
                          <td style={{ backgroundColor: "#F8F8F8" }}>
                            ₹{quoteItemTotalDetails?.subTotal}
                          </td>
                        </tr>
                        <tr className="mb-2">
                          <th
                            scope="col"
                            style={{
                              backgroundColor: "#F8F8F8",
                              width: "max-content",
                            }}
                          >
                            Discount
                          </th>
                          <td style={{ backgroundColor: "#F8F8F8" }}>
                            ₹{quoteItemTotalDetails?.discount}
                          </td>
                        </tr>
                        <tr className="mb-2">
                          <th
                            scope="col"
                            style={{
                              backgroundColor: "#F8F8F8",
                              width: "max-content",
                            }}
                          >
                            Tax
                          </th>
                          <td style={{ backgroundColor: "#F8F8F8" }}>
                            {quoteItemTotalDetails?.tax}%
                          </td>
                        </tr>
                        <tr className="mb-2">
                          <th
                            scope="col"
                            style={{
                              backgroundColor: "#F8F8F8",
                              width: "max-content",
                            }}
                          >
                            Adjustment
                          </th>
                          <td style={{ backgroundColor: "#F8F8F8" }}>
                            ₹{quoteItemTotalDetails?.adjustment}
                          </td>
                        </tr>
                        <tr className="mb-2">
                          <th
                            scope="col"
                            style={{
                              backgroundColor: "#F8F8F8",
                              width: "max-content",
                            }}
                          >
                            Grand Total
                          </th>
                          <td style={{ backgroundColor: "#F8F8F8" }}>
                            ₹{quoteItemTotalDetails?.grandTotal}
                          </td>
                        </tr>
                      </thead>
                    </table>
                  </div>
                  <h5 className="w-100 text-primary px-1 pt-1 ms-2 mt-2">
                    Terms and Conditions
                  </h5>
                  <div className="d-flex gap-2 px-1 pt-1 ms-2">
                    <span className="opacity-75 px-1">
                      Terms and Conditions
                    </span>
                    {/* <p>{quoteOverviewDetails.description}</p> */}
                    <EditableInput
                      fieldType="text"
                      inputType="termsAndConditionsInfo"
                      handleIndividualInputChange={handleIndividualInputChange}
                      editingFields={editingFields}
                      handleEditClick={handleEditClick}
                      handleCancelClick={handleCancelClick}
                      handleUpdateClick={handleUpdateClick}
                      updatedFields={updatedFields}
                      accountOverviewDetails={quotesInfoOverview}
                      showType={showType}
                      setShowType={setShowType}
                      isTextarea={true}
                    />
                  </div>
                  <h5 className="w-100 text-primary px-1 pt-1 ms-2 mt-2">
                    Description
                  </h5>
                  <div className="d-flex gap-2 px-1 pt-1 ms-2">
                    <span className="opacity-75 px-1 ">Description</span>
                    {/* <p>{quoteOverviewDetails.description}</p> */}
                    <EditableInput
                      fieldType="text"
                      inputType="description"
                      handleIndividualInputChange={handleIndividualInputChange}
                      editingFields={editingFields}
                      handleEditClick={handleEditClick}
                      handleCancelClick={handleCancelClick}
                      handleUpdateClick={handleUpdateClick}
                      updatedFields={updatedFields}
                      accountOverviewDetails={quotesInfoOverview}
                      showType={showType}
                      setShowType={setShowType}
                      isTextarea={true}
                    />
                  </div>
                </div>
                {notePermission === "TRUE" && (
                  <div
                    id="notesSection"
                    className="lead_Information_mainContainer commonContainer_bg rounded-2  d-flex flex-column py-2 mt-2"
                  >
                    <div>
                      <Note
                        note={note}
                        addTitle={addTitle}
                        showButtons={showButtons}
                        setShowButtons={setShowButtons}
                        textFile={selectedNoteFiles}
                        showTitleNote={showTitleNote}
                        setShowTitleNote={setShowTitleNote}
                        handleTextAreaClick={handleTextAreaClick}
                        handleTextFileChange={handleTextFileChange}
                        handleSaveButton={insertNote}
                        handleAddTitleClick={handleAddTitleClick}
                        setNoteTitle={setNoteTitle}
                        noteInfo={noteInfo}
                        selectRecent={selectRecent}
                        handleSelectRecent={handleSelectRecent}
                        handleDeleteClick={deleteNoteDetails}
                        leadId={quoteId}
                        handleUpdateClick={updateNote}
                        setUpdateNotes={setUpdateNotes}
                        updateNotes={updateNotes}
                        setEditNote={setEditNote}
                        editNote={editNote}
                        handleChangeNoteTitle={handleChangeNoteTitle}
                        handleChangeNoteDescription={
                          handleChangeNoteDescription
                        }
                        setAddTitle={setAddTitle}
                        setNote={setNote}
                        moduleType={"Quotes"}
                        recordName={quotesInfoOverview?.subject}
                        selectedNoteFiles={selectedNoteFiles}
                        setSelectedNoteFiles={setSelectedNoteFiles}
                        onChildDataChange={handleChildDataChange}
                        noteDeleteConfirmation={noteDeleteConfirmation}
                        setNoteDeleteConfirmation={setNoteDeleteConfirmation}
                      />

                      {showTitleNote && (
                        <textarea
                          rows={2}
                          className="notesClass px-2"
                          placeholder="Add note Title"
                          value={noteTitle}
                          onChange={(e) => setNoteTitle(e.target.value)}
                          style={{ width: "95%", marginTop: "10px" }}
                        />
                      )}
                    </div>
                  </div>
                )}

                <div
                  id="salesOrders"
                  className="lead_Information_mainContainer commonContainer_bg rounded-2  d-flex flex-column py-2 mt-4"
                >
                  <SalesCommonTable
                    title="Sales Orders"
                    buttonLabel="New"
                    buttonAction={() => {
                      setCurrentView("salesOrders");
                      setInnerPage("create");
                      setPastViewInfo((prev) => ({
                        ...prev,
                        view: currentView,
                        innerPage: innerPage,
                        undo: null,
                      }));
                    }}
                    showHeadings={showHeadingsSales}
                    setShowHeadings={setShowHeadingsSales}
                    selectedHeadings={selectedHeadingsSales}
                    onRowClick={() => {}}
                    manageColumnsAction={() =>
                      setShowModalType("Manage Sales Columns")
                    }
                    resetColumnSizeAction={() => {}}
                    setShowSelectedFilter={setShowSelectedFilter}
                    setSelectedSalesId={setSelectedSalesId}
                    handleAssignSalesModule={handleAssignSalesModule}
                    selectedSalesId={selectedSalesId}
                    data={salesOrderList}
                  />
                </div>
                <div
                  id="openActivity"
                  className="lead_Information_mainContainer commonContainer_bg rounded-2  d-flex flex-column py-2 mt-4"
                >
                  <OpenActivity
                    openActivities={openActivities}
                    // getOverview={() => accountOverviewDetail(accountId)}
                    openActivitiesCalls={openActivitiesCalls}
                    openActivitiesMeetings={openActivitiesMeetings}
                    moduleName="Quotes"
                  />
                </div>
                <div
                  id="closeActivity"
                  className="lead_Information_mainContainer commonContainer_bg rounded-2  d-flex flex-column py-2 mt-4"
                >
                  <CloseActivity
                    closeActivities={closeActivities}
                    // getOverview={() => accountOverviewDetail(accountId)}
                    closeActivitiesCalls={closedActivitiesCall}
                    closeActivitiesMeetings={closedActivitiesMeetings}
                  />
                </div>
              </div>
            ) : (
              <div className="timeline_main_container rounded-2 bg-white  d-flex flex-column gap-4 w-75 ">
                {/* <div className="upcomingAction_container w-100 flex-column  py-2  d-flex  commonContainer_bg rounded-2">
                  <div className="header d-flex gap-2 ps-2">
                    <span>
                      <img src={hierachy} alt="img" height={20} width={20} />
                    </span>
                    <span>Upcoming Actions</span>
                  </div>
                  <div className="horizontal_line w-100 opacity-25 mx-1"></div>

                  <div
                    className="d-flex justify-content-center align-items-center "
                    style={{ height: "25vh" }}
                  >
                    <h4 className="opacity-75 ">No upcoming actions</h4>
                  </div>
                </div> */}

                <div className="w-100 historyMain_Container flex-column   d-flex  commonContainer_bg rounded-2 ">
                  <div className="header d-flex gap-2 px-2  py-2 align-items-center">
                    <span>
                      <img src={history} alt="img" height={20} width={20} />
                    </span>
                    <span>History</span>
                  </div>
                  <div className="horizontal_line w-100 mx-1"></div>
                  <div className="historyContent_mainContainer ">
                    {timeLineDataList &&
                    Object.keys(timeLineDataList).length > 0 ? (
                      Object.keys(timeLineDataList).map((date) => (
                        <div
                          className="history_container pt-3 d-flex flex-column"
                          key={date}
                        >
                          <span className="px-2 timelineBg_color border border-primary rounded-5 py-2 mx-5">
                            {date}
                          </span>
                          {timeLineDataList[date].map((item, index) => (
                            <div className="d-flex gap-2" key={index}>
                              <span className="opacity-75 pt-5 ps-2">
                                {item.createdTime}
                              </span>
                              <div className="d-flex flex-column align-items-center">
                                <p className="vertical_line m-0 p-0"></p>
                                <span>
                                  <img
                                    src={groupIcon2}
                                    alt="img"
                                    height={25}
                                    width={25}
                                  />
                                </span>
                              </div>
                              <span className="pt-5">
                                {`${item.tmlDescription} ${item.tmlCreatedBy} ${date}`}
                              </span>
                            </div>
                          ))}
                        </div>
                      ))
                    ) : (
                      <div className="text-center d-flex justify-content-center align-items-center h-50 mt-2">
                        <h4>No Data available</h4>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
        <ShareModal
          moduleType="QUOTES"
          setShareModal={setShareModal}
          shareModal={shareModal}
          id={quoteId}
          shareUserList={shareUserList}
          shareGroupList={shareGroupList}
          shareRoleList={shareRoleList}
          shareMemberIds={shareMemberIds}
          modalHeading={"Sharing Quotes"}
          shareInfoModal={shareInfoModal}
          sharePublicList={sharePublicList}
          setShareInfoModal={setShareInfoModal}
          module={"Quotes"}
        />
      </div>
    );
  };

  const handleColumnCheckboxChange = (name, isChecked) => {
    setSavingHeadings((prevHeadings) =>
      prevHeadings.map((heading) =>
        heading.name === name ? { ...heading, isChecked } : heading
      )
    );
  };

  const handleUpdateIdClick = async (field, value) => {
    const fieldName =
      field === "contactName"
        ? "contactId"
        : field === "vendorName"
        ? "vendorId"
        : "purchaseOrderOwnerId";

    try {
      const requestBody = {
        purchaseOrdId: purchaseOrdId,
        [fieldName]:
          field === "contactName"
            ? value.contactId
            : field === "vendorName"
            ? value.vendorId
            : value.userId,
        loggedInUserId: loginUserId,
      };
      if (field === "vendorName") {
        requestBody.vendorName = value.vendorName;
      }

      const headersResult = getHeaders(true);

      // Check if headers result indicates an error
      if (headersResult.error) {
        navigate("../"); // Redirect to login if token is missing
        return;
      }
      const response = await axios.post(URI.updatePurchase, requestBody, {
        headers: headersResult,
      });
      if (response.data.status === 200) {
        NotificationManager.success("", response.data.message, 4000);
        setQuoteInfo((prevDetails) => ({
          ...prevDetails,
          [field]: quotesInfoOverview[field],
        }));

        setEditingFields((prevFields) => ({
          ...prevFields,
          [field]: false,
        }));
        // quotesInfoOverview(purchaseOrdId);
        getQuotesOverviewDetail(quoteId);
        getNotesDetails(quoteId, selectRecent);
      } else {
        NotificationManager.error("", response.data.message, 4000);
      }
    } catch (error) {
      handleApiError(error, navigate);
    }
  };

  const handleUserItemProductClick = (field, item, i) => {
    !["create", "clone"].includes(innerPage) &&
      handleUpdateIdClick(field, item);
    // setSelectedItem(item);
    setListVisibleType(field);
    // field === "contactName"
    //   ? setSelectedContact(item)
    //   : field === "accountName"
    //   ? setSelectedAccount(item)
    //   : field === "quotesOwner"
    //   ? setSelectedQuote(item)
    //   : field === "dealName"
    //   ? setSelectedDeal(item)
    //   : setSelectedOwner(item);
    if (field === "productId") {
      setSelectedProduct({
        productId: item.productId,
        productName: item.productName,
      });
      const newRows = [...allProducts];

      newRows[i] = {
        ...newRows[i],
        productId: item.productId,
        productName: item.productName,
        description: item.description,
        quantity: item.unitPrice || 1,
        listPrice: item.unitPrice,
        amount: item.unitPrice * item.unitPrice,
        discount: 0,
        tax: parseFloat(item.tax || 0),
        total: returnTotalValue(item),
        discountType: discountType === "percentage" ? 1 : 2,
        discountValue: 0,
      };
      setAllProducts(newRows);
      const subTotalValue = newRows.reduce(
        (sum, product) => parseFloat(sum) + parseFloat(product.total),
        0
      );

      if (existingQuote || existingClone) {
        setQuoteUpdateInfo((prevInfo) => ({
          ...prevInfo,
          subTotal: parseFloat(subTotalValue).toFixed(2) || 0,
          grandTotal:
            parseFloat(subTotalValue).toFixed(2) -
            parseFloat(quoteInfo.discount || 0),
        }));
      } else {
        setQuoteInfo((prevInfo) => ({
          ...prevInfo,
          subTotal: parseFloat(subTotalValue).toFixed(2) || 0,
          grandTotal:
            parseFloat(subTotalValue).toFixed(2) -
            parseFloat(quoteInfo.discount || 0),
        }));
      }
    }
  };

  const accountsDropdown = (topGap, tableIndex) => {
    const list =
      listVisibleType === "purchaseOwner"
        ? userList
        : // : listVisibleType === "contactName"
          // ? contactList
          // : listVisibleType === "vendorName"
          // ? vendorList
          productList;

    if (listVisibleType !== "") {
      return (
        <ul
          className="data-list list-group  lead_ownerList_container position-absolute bg-white"
          style={{
            top: topGap,
            width:
              topGap === "2rem" ? "112%" : topGap === "1.8rem" ? "100%" : "",
          }}
        >
          {list.map((item, index) => (
            <li
              key={index}
              className="cursorPointer w-100 px-2 border-bottom my-1"
              onClick={() => {
                handleUserItemProductClick(listVisibleType, item, tableIndex);
              }}
            >
              <div className="d-flex flex-column">
                <span style={{ fontWeight: "500" }}>
                  {" "}
                  {listVisibleType === "purchaseOwner"
                    ? item?.name
                    : listVisibleType === "contactName"
                    ? item?.contactName
                    : listVisibleType === "vendorName"
                    ? item?.vendorName
                    : item?.productName}
                </span>
                <span className="small" style={{ color: "#999999" }}>
                  {" "}
                  {item.emailId}{" "}
                </span>
              </div>
            </li>
          ))}
        </ul>
      );
    }
  };

  const handleColumnCheckboxChangeSales = (name, isChecked) => {
    setSavingHeadingsSales((prevHeadings) =>
      prevHeadings.map((heading) =>
        heading.name === name ? { ...heading, isChecked } : heading
      )
    );
  };

  const discountPopup = () => {
    return (
      <div
        className="position-absolute w-100 popout_box rounded-1 p-3 bg-white"
        style={{ left: selectedProductIndex === "" && "-4rem" }}
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <h6 className="fw-bold">
          {popoutType === "tax" ? "Select Tax" : "Set Discount"}
        </h6>

        {popoutType === "discount" ? (
          <>
            <div className="d-flex align-items-center my-3">
              <input
                type="radio"
                id="percentage"
                name="discount"
                value="percentage"
                className="cursorPointer"
                onChange={(e) => {
                  setDiscountType(e.target.checked ? "percentage" : "");
                }}
                checked={discountType === "percentage"}
              />
              <label className="ms-1">Percentage</label>
              {discountType === "percentage" ? (
                <div className="w-50 d-flex">
                  <input
                    type="text"
                    className="w-75 ms-2 me-1"
                    name="percentage"
                    maxLength={3}
                    onChange={(e) => {
                      setDiscountObj({
                        type: 1,
                        value: e.target.value,
                      });
                    }}
                    value={discountObj.value}
                    // value={
                    //   (discountObj.value / 100) *
                    //   parseFloat(allProducts[index].amount || 1)
                    // }
                  />
                  <span>%</span>
                </div>
              ) : null}
            </div>
            <div className="d-flex align-items-center my-2">
              <input
                type="radio"
                id="price"
                name="discount"
                value={quoteInfo.discountType}
                className="cursorPointer"
                onChange={(e) => {
                  setDiscountType(e.target.checked ? "price" : "");
                }}
                checked={discountType === "price"}
              />
              <label className="ms-1">Direct Price Reduction</label>
              {discountType === "price" ? (
                <input
                  type="text"
                  className="w-25 ms-2"
                  maxLength={3}
                  value={discountObj.value}
                  onChange={(e) => {
                    setDiscountObj({
                      type: 2,
                      value: e.target.value,
                    });
                  }}
                />
              ) : null}
            </div>
          </>
        ) : (
          <div className="my-3">
            <Select
              value={selectedTax}
              menuPlacement="auto"
              onChange={(selectedOption) => {
                setSelectedTax(selectedOption);
              }}
              options={taxOptions}
              styles={createLeadStyles}
              isSearchable={false}
            />
          </div>
        )}

        <div className="pt-2 d-flex gap-3">
          <button
            className="btn btn-primary px-3 py-1 opacity-75"
            onClick={() => {
              const newRows = [...allProducts];
              const taxOne =
                existingQuote || existingClone
                  ? quoteUpdateInfo.tax
                  : quoteInfo.tax;

              const subTotalOne =
                existingQuote || existingClone
                  ? quoteUpdateInfo.subTotal
                  : quoteInfo.subTotal;
              if (selectedProductIndex === "") {
                if (popoutType === "tax") {
                  const discountOne =
                    existingQuote || existingClone
                      ? quoteUpdateInfo.discount
                      : quoteInfo.discount;

                  const total = subTotalOne - discountOne;
                  const taxValue = (selectedTax.value / 100) * total;

                  const finalTotal =
                    parseFloat(total) +
                    parseFloat(taxValue || 0) +
                    parseFloat(quoteInfo.adjustment);

                  if (existingQuote || existingClone) {
                    setQuoteUpdateInfo((prevInfo) => ({
                      ...prevInfo,
                      tax: selectedTax.value,
                      grandTotal: parseFloat(finalTotal).toFixed(2),
                      // subTotal: parseFloat(total).toFixed(2),
                    }));
                  } else {
                    setQuoteInfo((prevInfo) => ({
                      ...prevInfo,
                      tax: selectedTax.value,
                      grandTotal: parseFloat(finalTotal).toFixed(2),
                      // subTotal: parseFloat(total).toFixed(2),
                    }));
                  }
                } else {
                  const subTotalTwo =
                    existingQuote || existingClone
                      ? quoteUpdateInfo.subTotal
                      : quoteInfo.subTotal;

                  const discount =
                    discountObj.type === 1
                      ? (discountObj.value / 100) * parseFloat(subTotalTwo)
                      : discountObj.value;
                  const total = subTotalTwo - discount;
                  const taxValue = (parseFloat(taxOne) / 100) * total;

                  const finalTotal =
                    parseFloat(total) +
                    parseFloat(taxValue || 0) +
                    parseFloat(quoteInfo.adjustment);

                  if (existingQuote || existingClone) {
                    setQuoteUpdateInfo((prevInfo) => ({
                      ...prevInfo,
                      discount: parseFloat(discount).toFixed(2),
                      discountType: discountObj.type,
                      discountValue: parseFloat(discountObj.value).toFixed(2),
                      grandTotal: parseFloat(finalTotal).toFixed(2),
                      // subTotal: parseFloat(total).toFixed(2),
                    }));
                  } else {
                    setQuoteInfo((prevInfo) => ({
                      ...prevInfo,
                      discount: parseFloat(discount).toFixed(2),
                      discountType: discountObj.type,
                      discountValue: parseFloat(discountObj.value).toFixed(2),
                      grandTotal: parseFloat(finalTotal).toFixed(2),
                      // subTotal: parseFloat(total).toFixed(2),
                    }));
                  }
                }
              } else {
                const index = parseInt(selectedProductIndex, 10);
                if (index >= 0 && index < newRows.length) {
                  const selectedRow = newRows[index];

                  const discount =
                    discountObj.type === 1
                      ? (discountObj.value / 100) *
                        parseFloat(selectedRow.amount || 0)
                      : discountObj.value;
                  const discountedAmount =
                    parseFloat(selectedRow.amount || 0) - discount;

                  const taxAmount =
                    (parseFloat(selectedRow.tax || 0) / 100) * discountedAmount;

                  selectedRow.total = (discountedAmount + taxAmount).toFixed(2);
                  selectedRow.discountType = discountObj.type;
                  selectedRow.discountValue = discountObj.value;
                  selectedRow.discount = discount;

                  newRows[index] = selectedRow;
                  setAllProducts(newRows);
                  const subTotalValue = newRows.reduce(
                    (sum, product) =>
                      parseFloat(sum) + parseFloat(product.total || 0),
                    0
                  );

                  if (existingQuote || existingClone) {
                    setQuoteUpdateInfo((prevInfo) => ({
                      ...prevInfo,
                      subTotal: subTotalValue,
                      grandTotal: (
                        subTotalValue - parseFloat(quoteInfo.discount || 0)
                      ).toFixed(2),
                    }));
                  } else {
                    setQuoteInfo((prevInfo) => ({
                      ...prevInfo,
                      subTotal: subTotalValue,
                      grandTotal: (
                        subTotalValue - parseFloat(quoteInfo.discount || 0)
                      ).toFixed(2),
                    }));
                  }
                }
              }

              setSelectedProductIndex("");
              setPopoutType("");
            }}
          >
            Done
          </button>
          <button
            className="btn btn-light border"
            onClick={() => {
              setSelectedProductIndex("");
              setPopoutType("");
            }}
          >
            Cancel
          </button>
        </div>
      </div>
    );
  };

  const handleChangeOwnerInputClick = (e) => {
    e.stopPropagation();
    setIsChangeOwnerList(!isChangeOwnerList);
  };

  const handleChangeOwnerItemClick = (item) => {
    setSelectedOwnerItem(item);
    setIsChangeOwnerList(false);
    setIsOwnerSelect(true);
    // setIsSelectedOwner(false);
  };

  useEffect(() => {
    const foundItem =
      userList && userList.find((item) => item.userId === loginUserId);
    if (foundItem) {
      setSelectedItem(foundItem);
    }
  }, [loginUserId]);

  useEffect(() => {
    getAllQuote(currentPage, selectedOption);
  }, [currentPage, perPage]);

  useEffect(() => {
    getAllAccountDetails();
  }, [currentPageAccount, perPageAccount]);

  useEffect(() => {
    getAllContact();
  }, [currentPageContact, perPageContact]);

  useEffect(() => {
    getAllAccountDetails();
    getAllContact();
    getAllDealsDetails();
    getAllProduct();
    setInnerPage(innerPage || "list");
    setShowOptionsContact(false);
  }, []);

  const handleQuoteView = () => {
    if (innerPage === "list") {
      return <>{quoteHomePage()}</>;
    } else if (["create", "clone"].includes(innerPage)) {
      return <>{createQuotePage()}</>;
    } else if (innerPage === "overview") {
      return <>{quotesOverViewPage()}</>;
    }
  };

  return (
    <div
      onClick={() => {
        setShowHeadings(false);
        setShowSelectedFilter("");
        setListVisibleType("");
        setSelectedField("");
        setPopoutType("");
        setShowSelectedFilter("");
        setShowOptionsContact(false);
        setContactListVisible(false);
        setDealListVisible(false);
        setListVisible(false);
        setAccountListVisible(false);
        // setShowOptionsQuote(false);
      }}
    >
      {handleQuoteView()}
      <LeadOwnerModal
        isLeadOwnerModal={isLeadOwnerModal}
        setIsLeadOwnerModal={setIsLeadOwnerModal}
        onSelectedLeadChange={handleSelectedLeadChange}
        defaultUser={selectedItem}
      />
      <Modal
        show={modalType === "Manage Columns"}
        id="ConvertModal"
        aria-labelledby="example-custom-modal-styling-title"
        className="withdrw_modal"
      >
        <Modal.Body>
          <div className="modalClass  position-relative">
            <div className="d-flex justify-content-between ">
              <h4 className="">Manage Columns</h4>
            </div>
            <div className="w-100 position-relative">
              <div className="mb-1 pt-2 position-relative w-100">
                <input
                  type="text"
                  className="assetSearchbar searchbar rounded-2 p-2 pe-5 common_input_bg w-100 "
                  placeholder="Search"
                  aria-label="Username"
                  aria-describedby="basic-addon1"
                  value={searchColumn}
                  onChange={(e) => {
                    if (e.target.value.charAt(0) !== " ") {
                      const value = e.target.value.replace(
                        /[^a-zA-Z ]+|(\s)\s+/g,
                        "$1"
                      );
                      setSearchColumn(value);
                    }
                  }}
                />
                <span
                  className="position-absolute  mt-2 me-2 end-0 opacity-75"
                  onClick={() => {
                    setSearchColumn("");
                    setSavingHeadings(quotesColumn);
                  }}
                >
                  {searchColumn === "" ? (
                    <img src={searchIcon} alt="" width={20} />
                  ) : (
                    "X"
                  )}
                </span>
              </div>
              <div className=" opacity-75 columns_container">
                {savingHeadings
                  .filter((lead) =>
                    lead.name
                      .toLowerCase()
                      .includes(searchColumn.trim().toLowerCase())
                  )
                  .sort((a, b) => a.name.localeCompare(b.name))
                  .map((column) => {
                    return (
                      <div
                        key={column.name}
                        className="d-flex gap-2 py-2 rounded-2 align-items-center"
                      >
                        <div className="d-flex gap-2 ps-2">
                          <input
                            type="checkbox"
                            id={column.name}
                            name={column.name}
                            checked={column.isChecked}
                            onChange={(e) => {
                              handleColumnCheckboxChange(
                                column.name,
                                e.target.checked
                              );
                            }}
                            className="cursorPointer"
                          />
                          <label
                            className="small cursorPointer"
                            htmlFor={column.name}
                          >
                            {column.name}
                          </label>
                        </div>
                      </div>
                    );
                  })}
              </div>
              <aside className="float-end mt-2">
                <div className="d-flex align-items-center gap-4">
                  <button
                    className="cancel_btn bg-light"
                    onClick={() => {
                      setShowModalType("");
                    }}
                  >
                    Cancel
                  </button>
                  <button
                    className="save_btn"
                    onClick={() => {
                      setSelectedHeadings(savingHeadings);
                      setTimeout(() => {
                        setShowModalType("");
                      }, 200);
                    }}
                  >
                    Save
                  </button>
                </div>
              </aside>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      {/* delete/update/transfer mass modal */}
      <Modal
        show={[
          "delete",
          "Mass Delete",
          "Mass Update",
          "Mass Transfer",
        ].includes(modalType)}
        aria-labelledby="contained-modal-title-vcenter"
        onClick={(e) => e.stopPropagation()}
        // size={showModalType !== "delete" ? "md" : "lg"}
      >
        <Modal.Header
          className=" border-0 mb-0 d-flex flex-column align-items-start"
          style={{ backgroundColor: modalType !== "delete" && "#F2F2F2" }}
        >
          {modalType !== "delete" && (
            <div
              className={`mass_heading fs-4 ${
                modalType !== "Mass Delete" && "ps-4"
              }`}
            >
              {modalType}
            </div>
          )}
          {modalType === "delete" && (
            <div className="fs-5" style={{ color: "#3F7DFF" }}>
              Are you sure you want to delete {quotesInfoOverview?.subject}?
              {/* {quotesInfoOverview?.invoiceOwner}? */}
            </div>
          )}
        </Modal.Header>
        <Modal.Body className="ModalBody">
          {modalType === "delete" ? (
            <>
              <div className="d-flex gap-2 pb-2 align-items-center">
                <span className="fw-bold">Note:</span>
                <span className="small pt-1">
                  Any associated activities, visits, drafts will also be
                  deleted.
                </span>
              </div>
            </>
          ) : modalType === "Mass Delete" ? (
            <>
              <div className="fs-5 pt-0 pb-2">
                Are you sure you want to delete selected item(s)?
              </div>
              <div className="small">
                <span className="fw-bold">Note: </span>
                <span>
                  Any associated Activities, Drafts will also be Deleted
                </span>
              </div>
            </>
          ) : modalType === "Mass Update" ? (
            <div className="d-flex gap-2 mb-3 mt-2 align-items-center w-100">
              <div className="w-100">
                <div className="d-flex flex-column ps-1 align-items-center mx-4">
                  <label className="text-start w-100 opacity-75">
                    Select a field
                  </label>
                  <div
                    className="position-relative w-100 rounded-1"
                    style={{ border: "1px solid rgba(185, 185, 185, 0.5)" }}
                  >
                    <div
                      onClick={(e) => {
                        e.stopPropagation();
                        setListVisible(!listVisible);
                        setSearchField("");
                      }}
                      className={`cursorPointer d-flex justify-content-between w-100 my-1 p-1 border-0`}
                    >
                      <span className="">{selectedObj.name}</span>
                      <img
                        src={listVisible ? up : down}
                        alt="img"
                        height={20}
                        width={20}
                        className="pt-1"
                      />
                    </div>
                    {listVisible && (
                      <div
                        className="position-absolute field_container w-100"
                        onClick={(e) => {
                          e.stopPropagation();
                        }}
                      >
                        <div className="">
                          <label
                            htmlFor="selectField"
                            className="position-relative w-100"
                          >
                            <input
                              id="selectField"
                              type="text"
                              className="rounded-2 p-1 w-100 pe-4"
                              placeholder="Search"
                              value={searchField}
                              onChange={(e) => {
                                if (e.target.value.charAt(0) !== " ") {
                                  const value = e.target.value.replace(
                                    /[^a-zA-Z ]+|(\s)\s+/g,
                                    "$1"
                                  );
                                  setSearchField(value);
                                }
                                // const value = e.target.value.replace(
                                //   /[^a-zA-Z]/g,
                                //   ""
                                // );
                                // setSearchField(value);

                                // const filteredData = quotesColumn.filter(
                                //   (field) =>
                                //     field.name
                                //       .toLowerCase()
                                //       .includes(value.trim().toLowerCase())
                                // );
                                // setShowSearchFields(
                                //   /^[a-zA-Z]+$/.test(value)
                                //     ? filteredData
                                //     : fieldLabels
                                // );
                              }}
                            />
                            <img
                              className="position-absolute mt-1 me-2 end-0 pt-1 cursorPointer"
                              onClick={() => {
                                setSearchField("");
                              }}
                              src={searchIcon}
                              alt="search"
                              width={20}
                            />
                          </label>

                          <div className="w-100 rounded-2">
                            <div className="border border-1 field_container_dropdown rounded-1">
                              {showSearchFields
                                .filter((field) =>
                                  field.name
                                    .toLowerCase()
                                    .includes(searchField.trim().toLowerCase())
                                )
                                .sort((a, b) => a.name.localeCompare(b.name))
                                .map(
                                  (field) =>
                                    ![
                                      "Quotes Owner",
                                      "Deal Name",
                                      "Account Name",
                                      "Contact Name",
                                      "Hash",
                                      "Grand Total",
                                    ].includes(field.name) && (
                                      <div
                                        key={field.name}
                                        className="d-flex gap-2 p-2 cursorPointer rounded-2 align-items-center"
                                        onClick={() => {
                                          setClickedDropdownObj({
                                            label: "",
                                            value: "",
                                          });
                                          setMassUpdateValue("");
                                          setSelectedObj(field);
                                          setListVisible(false);
                                        }}
                                      >
                                        <span className="ps-2">
                                          {field.name}
                                        </span>
                                      </div>
                                    )
                                )}
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="w-100 mx-auto d-flex justify-content-center mt-4">
                    <div className="w-100">
                      <label className="opacity-75" htmlFor="massValue">
                        Enter value
                      </label>
                      <div
                        className="position-relative w-100 border rounded-1"
                        style={{ border: "1px solid rgba(185, 185, 185, 0.5)" }}
                      >
                        {["Carrier", "Quote Stage"].includes(
                          selectedObj.name
                        ) ? (
                          <Select
                            value={clickedDropdownObj}
                            menuPlacement="auto"
                            onChange={(selectedOption) => {
                              setClickedDropdownObj(selectedOption);
                              setMassUpdateValue(selectedOption.value);
                            }}
                            options={
                              selectedObj.name === "Carrier"
                                ? carrierOptions
                                : stageOptions
                            }
                            styles={fieldDropdownStyles}
                            isSearchable={false}
                          />
                        ) : (
                          <input
                            className="w-100 border-0 py-2 rounded-1"
                            type={`${
                              ["Valid Until"].includes(selectedObj.name)
                                ? "date"
                                : "text"
                            }`}
                            value={massUpdateValue}
                            // onChange={(e) => {
                            //   setMassUpdateValue(e.target.value);
                            // }}
                            onChange={(e) => {
                              const trimmedValue = e.target.value
                                ?.replace(/\s{2,}/g, " ")
                                .trimStart();
                              setMassUpdateValue(trimmedValue);
                            }}
                            style={{ outline: "none" }}
                            id="massValue"
                            min={minDate}
                            max={maxDate}
                          />
                        )}
                      </div>
                      {errorMessage && (
                        <span className="text-danger text-center pt-1">
                          {errorMessage}
                        </span>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="w-100 px-4 py-2 mb-1">
              <label className="text-start w-100 opacity-75">Transfer To</label>
              <div
                className="d-flex input-with-clear position-relative mx-auto"
                onClick={(e) => {
                  handleOwnerInputClick(e);
                }}
              >
                <input
                  type="text"
                  className="ps-3 w-100 cursorPointer py-2"
                  value={selectedItem?.name}
                  readOnly
                />
                <span
                  className="position-absolute h-100 mass_transfer_user pt-1"
                  style={{ right: "0.1rem" }}
                >
                  <span
                    className="cursorPointer px-1"
                    onClick={() => setIsLeadOwnerModal(!isLeadOwnerModal)}
                  >
                    <img
                      src={userSearch}
                      alt="img"
                      width={20}
                      height={20}
                      className="ms-2 mt-2 me-2"
                    />
                  </span>
                </span>
              </div>
            </div>
          )}

          <div className={`pt-4  ${modalType !== "delete" && "pe-3"}`}>
            <div className="d-flex justify-content-end w-100 gap-4 pe-2">
              <button
                className="cancelButton px-4"
                onClick={() => {
                  setShowModalType("");
                  // setSelectedCheckboxes([]);
                  setShowSearchFields(fieldDropdowns);
                }}
              >
                Cancel
              </button>
              <button
                className="createContactButtonSave"
                onClick={() => {
                  if (modalType === "delete") {
                    deleteProductDetails();
                  } else {
                    //  else if (showModalType === "Mass Delete") {
                    //   deleteUpdateTransferMass();
                    // } else {
                    deleteUpdateTransferMass();
                  }
                }}
              >
                {modalType === "Mass Update"
                  ? "Update"
                  : modalType === "Mass Transfer"
                  ? "Done"
                  : "Delete"}
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={modalType === "Manage Sales Columns"}
        id="ConvertModal"
        aria-labelledby="example-custom-modal-styling-title"
        className="withdrw_modal"
      >
        <Modal.Body>
          <div className="modalClass  position-relative">
            <div className="d-flex justify-content-between ">
              <h4 className="">Manage Columns</h4>
              {/* <img
                src={crossIcon}
                alt="cross"
                style={{ cursor: "pointer" }}
                className="ms-1"
                onClick={() => {
                  setShowModalType("");
                }}
              /> */}
            </div>
            <div className="w-100 position-relative">
              <div className="mb-1 pt-2 position-relative w-100">
                <input
                  type="text"
                  className="assetSearchbar searchbar rounded-2 p-2 pe-5 common_input_bg w-100 "
                  placeholder="Search"
                  aria-label="Username"
                  aria-describedby="basic-addon1"
                  value={searchColumn}
                  onChange={(e) => {
                    if (e.target.value.charAt(0) !== " ") {
                      const value = e.target.value.replace(
                        /[^a-zA-Z ]+|(\s)\s+/g,
                        "$1"
                      );
                      setSearchColumn(value);
                      const list = salesColumns.filter((contact) =>
                        contact.name
                          .toLowerCase()
                          .includes(value.trim().toLowerCase())
                      );
                      setSelectedHeadingsSales(list);
                    }
                  }}
                />
                <span
                  className="position-absolute  mt-2 me-2 end-0 opacity-75"
                  onClick={() => {
                    setSearchColumn("");
                  }}
                >
                  {searchColumn === "" ? (
                    <img src={searchIcon} alt="" width={20} />
                  ) : (
                    <div style={{ cursor: "pointer" }}>X</div>
                  )}
                </span>
              </div>
              <div className=" opacity-75 columns_container">
                {savingHeadingsSales.map((column) => {
                  return (
                    <div
                      key={column.name}
                      className="d-flex gap-2 py-2 rounded-2 align-items-center"
                    >
                      <div className="d-flex gap-2 ps-2">
                        <input
                          type="checkbox"
                          id={column.name}
                          name={column.name}
                          checked={column.isChecked}
                          onChange={(e) => {
                            handleColumnCheckboxChangeSales(
                              column.name,
                              e.target.checked
                            );
                          }}
                          className="cursorPointer"
                        />
                        <label
                          className="small cursorPointer"
                          htmlFor={column.name}
                        >
                          {column.name}
                        </label>
                      </div>
                    </div>
                  );
                })}
              </div>
              <aside className="float-end mt-2">
                <div className="d-flex align-items-center gap-4">
                  <button
                    className="cancel_btn bg-light"
                    onClick={() => {
                      setShowModalType("");
                      resetForm();
                    }}
                  >
                    Cancel
                  </button>
                  <button
                    className="save_btn"
                    onClick={() => {
                      setSelectedHeadingsSales(savingHeadingsSales);
                      setTimeout(() => {
                        setShowModalType("");
                      }, 200);
                    }}
                  >
                    Save
                  </button>
                </div>
              </aside>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <AccessModal
        setShowAccessModal={setShowAccessModal}
        showAccessModal={showAccessModal}
      />
      <ChangeOwnerModal
        modalType={modalType}
        setShowModalType={setShowModalType}
        selectedOwnerItem={selectedOwnerItem}
        handleChangeOwnerInputClick={handleChangeOwnerInputClick}
        setSelectedOwnerItem={setSelectedOwnerItem}
        isLeadOwnerModal={isLeadOwnerModal}
        setIsLeadOwnerModal={setIsLeadOwnerModal}
        isChangeOwnerList={isChangeOwnerList}
        userList={userList}
        handleChangeOwnerItemClick={handleChangeOwnerItemClick}
        emailNotification={emailNotification}
        setEmailNotification={setEmailNotification}
        handleEditClick={handleEditClick}
        handleUpdateClick={handleUpdateClick}
        setUpdatedFields={setUpdatedFields}
        existingContact={existingQuote}
        handleUpdateContact={handleUpdateQuote}
        onSelectedChangeOwner={handleSelectedLeadChange}
        apiLoader={apiLoader} // for lottie loader
      />

      <NotificationContainer />
    </div>
  );
}

export default Quotes;

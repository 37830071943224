import React, { useEffect, useRef, useState } from "react";
import { URI } from "../../constants";
import axios from "axios";
import Select from "react-select";
import groupIcon from "../../Assets/prodInactive.svg";
import noteIcon from "../../Assets/notebook-01.svg";
// import hierachy from "../../Assets/hierarchy.svg";
import history from "../../Assets/history.svg";
import groupIcon2 from "../../Assets/Group 74.svg";
import down from "../../Assets/Down31.svg";
import up from "../../Assets/Up.svg";
import userSearch from "../../Assets/user-search-01.svg";
import tableFilterIcon from "../../Assets/filterIcon.svg";
import arrowUp from "../../Assets/square_arrow_up.svg";
import arrowDown from "../../Assets/square_arrow_down.svg";
import pin from "../../Assets/Pin_column.svg";
import unPin from "../../Assets/Unpin.svg";
import cancel from "../../Assets/cancel_circle.svg";
import filterLine from "../../Assets/filterLine.svg";
import filterMenu from "../../Assets/filterMenu.svg";
import searchIcon from "../../Assets/search.svg";
import EditableInput from "../../common/EditableInput";
import updateIcon from "../../Assets/Group 71.svg";
import cancelIcon from "../../Assets/Group 72.svg";
import downloadIcon from "../../Assets/Frame (5).svg";
import productImg from "../../Assets/PRODUCT .svg";
import Close from "../../Assets/Clone.svg";
import Share from "../../Assets/ShareIcon.svg";
import Delete from "../../Assets/DeleteIconNew.svg";
import openActivityIcon from "../../Assets/openActivity.svg";
import closeActivityIcon from "../../Assets/closeActivity.svg";
import leadListIcon from "../../Assets/Leadlist.svg";
import accListIcon from "../../Assets/Accountlist.svg";
import vendorsIcon from "../../Assets/De-Active/vendorHome.svg";

import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import "react-notifications/lib/notifications.css";
import LeadOwnerModal from "../../common/LeadOwnerModal";
import {
  accountColumn,
  contactColumn,
  dealsColumn,
  leadsColumn,
  taxOptions,
} from "../../common/CommonData";
import Pagination from "../../common/Pagination";
import noDataProduct from "../../Assets/Product presentation-bro 1.svg";
import ContactCommonTable from "../../common/ContactCommonTable";
import {
  pageOption,
  industryOptions,
  fieldDropdownStyles,
  manufacturerOptions,
  productCategoryOptions,
  massOptions,
} from "../../common/CommonData";
import { Modal } from "react-bootstrap";
import OpenActivity from "../../common/commonRelatedList/OpenActivity";
import Note from "../../common/commonRelatedList/Note";
import CloseActivity from "../../common/commonRelatedList/CloseActivity";
import DealListIcon from "../../Assets/DealsList.svg";
import ShareModal from "../../common/ShareModal";
import { useSelector } from "react-redux";
import Loader from "../../common/commonRelatedList/Loader";
import {
  convertDateFormat,
  convertToDisplayFormat,
  validateProductName,
  validateProductCode,
  validateUnitPrice,
  validateQuantityOrdered,
  validateQuantityInStock,
  validateReorderLevel,
  validateQuantityInDemand,
  removeExtraSpaces,
  validateCalcField,
  customCommonStyles,
  removeLeadingZeros,
} from "../../common/CommonComponent";
import DealsCommonTable from "../../common/DealsCommonTable";
import AccessModal from "../../common/AccessModal";
import ChangeOwnerModal from "../../common/commonRelatedList/ChangeOwnerModal";
import {
  leftArrow,
  rightAngleArrow,
  leftAngleArrow,
  tick,
  expandArrow,
  shrinkArrow,
} from "../../common/commonSVGs/commonSVGIcons.js";
import { EXPLORER_URL } from "../../constants/DataConst.js";
import Lottie from "react-lottie";
import { defaultOptions } from "../../utils/defaultOptions.js";
import getHeaders from "../../common/ApiHeader.js";
import useScrollToUndo from "../../hooks/useScrollToUndo.js.js";
import { handleApiError } from "../../utils/ApiUtils";
import { useNavigate } from "react-router-dom";
import SearchIcon from "../../Assets/SearchIcon.svg";
import logoConvert from "../../Assets/logoConvert.svg";
// import CalculatorTooltip from "../../common/CalculatorTooltip.js";

const handleKeyDown = (e) => {
  // Prevent typing space in the input field
  if (e.key === " ") {
    e.preventDefault();
  }
};

function Product({
  setCurrentView,
  setInnerPage,
  innerPage,
  currentView,
  permissionList,
  notePermission,
  pastViewInfo,
  setPastViewInfo,
  fetchProdInfo,
  setFetchProdInfo,
  storeModuleId,
}) {
  const navigate = useNavigate();
  const reduxStore = useSelector((state) => state.reduxState);
  const { loginUserId } = reduxStore;
  // const [innerPage, setInnerPage] = useState("list");
  const [selectedCheckboxes, setSelectedCheckboxes] = useState([]);

  const [allProductDetails, setAllProductDetails] = useState([]);
  // const [activeTab, setActiveTab] = useState("notes");  activeTab is not used anywhere so i commented this
  const [productPageView, setProductPageView] = useState("overview");
  const [timeLineDataList, setTimeLineDataList] = useState([]);
  const userList = JSON.parse(sessionStorage.getItem("userList"));
  // const vendorList = JSON.parse(sessionStorage.getItem("vendorList"));
  const [vendorList, setVendorList] = useState([]);
  const [isLeadOwnerModal, setIsLeadOwnerModal] = useState(false);
  const [showType, setShowType] = useState("");
  const [listVisible, setListVisible] = useState(false);
  const [listVisibleHandler, setListVisibleHandler] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [selectedHandlerItem, setSelectedHandlerItem] = useState(null);
  const [selectedOwnerItem, setSelectedOwnerItem] = useState(userList);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState(0); // Total number of items
  const [perPage, setPerPage] = useState(10);
  // const [filteredProductDetails, setFilteredProductDetails] = useState([]);
  const [productId, setProductId] = useState("");
  const [deleteMsg, setDeleteMsg] = useState(""); // Total number of items
  const [addTitle, setAddTitle] = useState("");
  const [noteInfo, setNoteInfo] = useState([]);
  const [selectRecent, setSelectRecent] = useState("ALL");
  const [openActivities, setOpenActivities] = useState([]);
  const [openActivitiesCalls, setOpenActivitiesCalls] = useState([]);
  const [openActivitiesMeetings, setOpenActivitiesMeetings] = useState([]);
  const [closedActivitiesCall, setClosedActivitiesCall] = useState([]);
  const [closedActivitiesMeetings, setClosedActivitiesMeetings] = useState([]);
  const [closeActivities, setCloseActivities] = useState([]);
  const [shareModal, setShareModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  // const [isProductForm, setIsProductForm] = useState(false); isProductForm is not used anywhere so i am commenting
  const [isLoading, setIsLoading] = useState(true);
  const [productNameErrorMsg, setProductNameErrorMsg] = useState("");
  const [vendorNameErrorMsg, setVendorNameErrorMsg] = useState("");
  const [unitPriceMsg, setUnitPriceMsg] = useState("");
  const [productCodeErrorMsg, setProductCodeErrorMsg] = useState("");
  const [quantityOrderedMsg, setQuantityOrderedMsg] = useState("");
  const [quantityInStockMsg, setQuantityInStockMsg] = useState("");
  const [recordLevelMsg, setRecordLevelMsg] = useState("");
  const [quantityDemandMsg, setQuantityDemandMsg] = useState("");
  const [imageLoader, setImageLoader] = useState(false);
  const [showRemoveModal, setShowRemoveModal] = useState(false);
  // const [productActive, setProductActive] = useState(false);

  const [isChangeOwnerList, setIsChangeOwnerList] = useState(false);
  const [isOwnerSelect, setIsOwnerSelect] = useState(false);
  const [updateNotes, setUpdateNotes] = useState({
    description: "",
    title: "",
    noteImageList: [],
  });
  const [filterContactDetails, setFilterContactDetails] = useState([]);
  const [filterAccountDetails, setFilterAccountDetails] = useState([]);
  const [filterLeadDetails, setFilterLeadDetails] = useState([]);
  const [filterDealDetails, setFilterDealDetails] = useState([]);
  const [imageMsg, setImageMsg] = useState("");
  const [isDragging, setIsDragging] = useState(false);
  const allowedFileTypes = [
    "image/png",
    "image/jpg",
    "image/jpeg",
    "image/gif",
  ];
  const maxSizeInBytes = 2 * 1024 * 1024; // 2 MB in bytes
  const [clickedDropdownObj, setClickedDropdownObj] = useState({
    label: "",
    value: "",
  });
  const [selectedObj, setSelectedObj] = useState({
    name: "Commission Rate",
    isChecked: false,
    value: "commissionRate",
  });
  const [showHeadingsContact, setShowHeadingsContact] = useState(false);
  const [selectedHeadingsContact, setSelectedHeadingsContact] =
    useState(contactColumn);
  const [savingHeadingsContact, setSavingHeadingsContact] =
    useState(contactColumn);
  const [showHeadingsLead, setShowHeadingsLead] = useState(false);
  const [selectedHeadingsLead, setSelectedHeadingsLead] = useState(leadsColumn);
  const [savingHeadingsLead, setSavingHeadingsLead] = useState(leadsColumn);
  const [showHeadingsDeals, setShowHeadingsDeals] = useState(false);
  const [selectedHeadingsDeals, setSelectedHeadingsDeals] =
    useState(dealsColumn);
  const [savingHeadingsDeals, setSavingHeadingsDeals] = useState(dealsColumn);
  const [showHeadingsAccount, setShowHeadingsAccount] = useState(false);
  const [selectedHeadingsAccount, setSelectedHeadingsAccount] =
    useState(accountColumn);
  const [savingHeadingsAccount, setSavingHeadingsAccount] =
    useState(accountColumn);
  const [shareUserList, setShareUserList] = useState([]);
  const [shareRoleList, setShareRoleList] = useState([]);
  const [shareGroupList, setShareGroupList] = useState([]);
  const [sharePublicList, setSharePublicList] = useState([]);
  const [shareMemberIds, setShareMemberIds] = useState([]);
  const [shareInfoModal, setShareInfoModal] = useState(false);
  const [showTooltipExpectedRevenue, setShowTooltipExpectedRevenue] =
    useState(false);
  useScrollToUndo(pastViewInfo, setPastViewInfo);

  const hideAllTooltips = () => {
    setShowTooltipExpectedRevenue(false);
    setShowTooltipSalesCommission(false);
  };

  const [currentPageVendor, setCurrentPageVendor] = useState(1);
  const [totalItemsVendor, setTotalItemsVendor] = useState(0);
  const [perPageVendor, setPerPageVendor] = useState(10);
  const [modalVendorDetails, setModalVendorDetails] = useState([]);
  const [searchVendor, setSearchVendor] = useState("");
  const [noteDeleteConfirmation, setNoteDeleteConfirmation] = useState(false);
  const [tooltipExpectedRevenue, setTooltipExpectedRevenue] = useState("");
  const [tooltipSalesCommission, setTooltipSalesCommission] = useState("");
  const totalPagesVendor = Math.ceil(Number(totalItemsVendor) / perPageVendor);

  const handlepageChangeVendor = (page) => {
    setCurrentPageVendor(page);
  };

  const handlePageDropDownChangeVendor = (selectedOption) => {
    setCurrentPageVendor(1);
    setPerPageVendor(parseInt(selectedOption.value));
  };

  const filteredVendors = modalVendorDetails.filter((item) =>
    item.vendorName?.toLowerCase().includes(searchVendor?.toLowerCase())
  );

  const handleSearchVendor = (e) => {
    const { value } = e.target;
    if (value.trim() !== "" || value === "") {
      setSearchVendor(value);
    }
  };

  useEffect(() => {
    getAllVendorDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPageVendor, perPageVendor]);

  useEffect(() => {
    if (storeModuleId) {
      getProductOverview(storeModuleId);
      setProductId(storeModuleId);
    }
  }, []);

  /* eslint-disable no-useless-escape */
  const sanitizeExpression = (expression) => {
    const match = expression.match(/^\s*-?\d+(\.\d+)?([+\-*\/]-?\d+(\.\d+)?)*/);

    if (match) {
      return match[0].trim();
    } else {
      return "Invalid input";
    }
  };

  const calculateResult = (field) => {
    const value = (
      existingProduct || existingClone
        ? productUpdateInfo[field]
        : formData[field]
    ).trim();
    const sanitizedValue = value;
    const regex = /^[\d.\+\-\*\/\(\)]+$/;

    if (regex.test(sanitizedValue)) {
      try {
        const cleanedValue = removeLeadingZeros(sanitizedValue);
        let result = eval(cleanedValue);

        if (typeof result === "number" && result % 1 !== 0) {
          const decimalPart = result.toString().split(".")[1];
          if (decimalPart && decimalPart.length > 2) {
            result = parseFloat(result).toFixed(2);
          }
        }
        if (existingProduct || existingClone) {
          setProductUpdateInfo((prevData) => ({
            ...prevData,
            [field]: result.toString(),
          }));
        } else {
          setFormData((prevData) => ({
            ...prevData,
            [field]: result.toString(),
          }));
        }
      } catch (error) {
        if (existingProduct || existingClone) {
          setProductUpdateInfo((prevData) => ({
            ...prevData,
            [field]: "Error",
          }));
        } else {
          setFormData((prevData) => ({
            ...prevData,
            [field]: "Error",
          }));
        }
      }
    }
  };

  const handleBlur = (field) => {
    calculateResult(field);
    hideAllTooltips();
  };

  const handleKeyPress = (event) => {
    const allowedCharacters = /[0-9.\+\-\*\/\(\)]/;
    const char = event.key;
    if (!allowedCharacters.test(char)) {
      event.preventDefault();
    }
  };

  const tooltipStyles = {
    position: "absolute",
    top: "100%",
    left: "0",
    backgroundColor: "#f9f9f9",
    border: "1px solid #d3d3d3",
    padding: "5px",
    borderRadius: "3px",
    boxShadow: "0 2px 5px rgba(0,0,0,0.2)",
    zIndex: 1000,
    height: "fit-content",
    width: "fit-content",
  };

  const handleTooltip = (value, setShowTooltip, setTooltipContent) => {
    const regex = /^[\d.\+\-\*\/\(\)]+$/;

    if (regex.test(value)) {
      try {
        const sanitizedValue = removeLeadingZeros(value);
        const previewResult = eval(sanitizedValue);
        setTooltipContent(previewResult.toString());
      } catch (error) {
        setTooltipContent(sanitizeExpression(value));
      }
      setShowTooltip(true);
    } else {
      setShowTooltip(false);
    }
  };

  /* eslint-enable no-useless-escape */

  const [showAccessModal, setShowAccessModal] = useState(false);
  const fileInputRef = useRef(null);

  const handleSelectRecent = (item) => {
    setSelectRecent(item);
    var value = "";
    if (item === "Recent Last") {
      value = "RECENT_LAST";
    } else if (item === "Recent First") {
      value = "RECENT_FIRST";
    } else {
      value = item;
    }
    getNotesDetails(productId, value);
  };

  const hasPermission = (action) => {
    const permission = permissionList.products.find((p) => p.action === action);
    return permission ? permission.status === "TRUE" : false;
  };

  const handleCreateProduct = () => {
    if (hasPermission("CREATE")) {
      setInnerPage("create");
      setUpdateButtonLabel("Save");
      setSelectedVendorItem(vendorList[0]);
      setExistingProduct(false);
      setUpdateButtonLabel("Save");
      resetForm();
    } else {
      setShowAccessModal(true);
    }
  };

  const handleCreateProductClone = () => {
    if (hasPermission("CREATE")) {
      productInfo.productCode = "";
      setInnerPage("clone");
      setShowOptionsProduct(false);
      setExistingClone(true);
      setProductUpdateInfo({ ...productInfo });
      setUpdateButtonLabel("Save");
      setExistingProduct(false);
    } else {
      setShowAccessModal(true);
    }
  };

  const handleEditProduct = () => {
    if (hasPermission("EDIT")) {
      setExistingProduct(true);
      setUpdateButtonLabel("Update");
      setInnerPage("create");
      setProductUpdateInfo({ ...productInfo });
    } else {
      setShowAccessModal(true);
    }
  };

  const handleDeleteProduct = () => {
    if (hasPermission("DELETE")) {
      setDeleteModal(true);
    } else {
      setShowAccessModal(true);
    }
  };

  const handleSelectCheckboxChange = (event) => {
    const { value } = event.target;

    const selectedCheckbox = { moduleId: value };

    setSelectedCheckboxes((prevSelectedCheckboxes) => {
      const isAlreadySelected = prevSelectedCheckboxes.some(
        (checkbox) => checkbox.moduleId === value
      );

      if (isAlreadySelected) {
        return prevSelectedCheckboxes.filter(
          (checkbox) => checkbox.moduleId !== value
        );
      } else {
        return [...prevSelectedCheckboxes, selectedCheckbox];
      }
    });
  };

  const productColumn = [
    {
      name: "Product Name",
      isChecked: true,
      value: "productName",
    },
    {
      name: "Product Owner",
      isChecked: true,
      value: "productOwner",
    },
    {
      name: "Product Active",
      isChecked: true,
      value: "productActive",
    },
    {
      name: "Taxable",
      isChecked: false,
      value: "taxable",
    },
    {
      name: "Product Code",
      isChecked: true,
      value: "productCode",
    },
    {
      name: "Manufacturer",
      isChecked: false,
      value: "manufacturer",
    },
    {
      name: "Quantity in Stock",
      isChecked: false,
      value: "quantityInStock",
    },
    {
      name: "Sale Start Date",
      isChecked: false,
      value: "salesStartDate",
    },
    {
      name: "Sale End Date",
      isChecked: false,
      value: "saleEndDate",
    },
    {
      name: "Support End Date",
      isChecked: false,
      value: "supportEndDate",
    },
    {
      name: "Support Start Date",
      isChecked: false,
      value: "supportStartDate",
    },
    {
      name: "Unit Price",
      isChecked: false,
      value: "unitPrice",
    },

    {
      name: "Commission Rate",
      isChecked: false,
      value: "commissionRate",
    },
    {
      name: "Tax",
      isChecked: false,
      value: "tax",
    },
    {
      name: "Usage Unit",
      isChecked: false,
      value: "usageUnit",
    },
    {
      name: "Qty Ordered",
      isChecked: false,
      value: "qtyOrdered",
    },
    {
      name: "Reorder Level",
      isChecked: false,
      value: "reorderLevel",
    },
    {
      name: "Handler",
      isChecked: false,
      value: "handlerName",
    },
    {
      name: "Description",
      isChecked: false,
      value: "description",
    },
    {
      name: "Hash",
      isChecked: true,
      value: "transHash",
    },
  ];

  const [selectedHeadings, setSelectedHeadings] = useState(productColumn);
  const [savingHeadings, setSavingHeadings] = useState(productColumn);
  const [showHeadings, setShowHeadings] = useState(false);
  const [showSelectedFilter, setShowSelectedFilter] = useState("");
  const [filterType, setFilterType] = useState("");
  const [pinnedColumn, setPinnedColumn] = useState("Product Owner");
  const [searchColumn, setSearchColumn] = useState("");
  const [filteredProductDetails, setFilteredProductDetails] = useState([]);
  const [modalType, setShowModalType] = useState("");
  const [selectedFiles, setSelectedFiles] = useState(null);
  const [showImageModal, setShowImageModal] = useState(false);
  const [massUpdateValue, setMassUpdateValue] = useState("");
  const [isForHandler, setIsForHandler] = useState(false);
  const fieldDropdowns = [
    {
      name: "Product Owner",
      isShowed: false,
      type: "select",
    },
    {
      name: "Manufacturer",
      isShowed: true,
      type: "dropdown",
    },
    {
      name: "Product Active",
      isShowed: false,
      type: "checkbox",
    },
    {
      name: "Product Category",
      isShowed: false,
      type: "dropdown",
    },
    {
      name: "Sale End Date",
      isShowed: false,
      type: "date",
    },
    {
      name: "Support Start Date",
      isShowed: false,
      type: "date",
    },
    {
      name: "Created By",
      isShowed: false,
      type: "readOnly",
    },
    {
      name: "Modified By",
      isShowed: false,
      type: "readOnly",
    },
    {
      name: "Support End Date",
      isShowed: false,
      type: "date",
    },
    {
      name: "Unit Price",
      isShowed: true,
      type: "input",
    },
    {
      name: "Vendor Name",
      isShowed: true,
      type: "readOnly",
    },
    {
      name: "Qty Ordered",
      isShowed: true,
      type: "input",
    },
    {
      name: "Sale Start Date",
      isShowed: false,
      type: "date",
    },
    {
      name: "Commission Rate",
      isShowed: false,
      type: "input",
    },
    {
      name: "Tax",
      isShowed: false,
      type: "dropdown",
    },
    {
      name: "Taxable",
      isShowed: false,
      type: "checkbox",
    },
    {
      name: "Usage Unit",
      isShowed: false,
      type: "dropdown",
    },
    {
      name: "Reorder Level",
      isShowed: false,
      type: "input",
    },
    {
      name: "Handler",
      isShowed: false,
      type: "readOnly",
    },
    {
      name: "Quantity In Demand",
      isShowed: false,
      type: "input",
      value: "quantityInDemand",
    },
    {
      name: "Quantity In Stock",
      isShowed: true,
      type: "input",
      value: "quantityInStock",
    },
  ];

  const fieldIndividualDropdowns = [
    {
      name: "Product Owner",
      type: "select",
      value: "productOwner",
    },
    {
      name: "Product Code",
      type: "input",
      value: "productCode",
    },
    {
      name: "Product Active",
      type: "checkbox",
      value: "productActive",
    },
    {
      name: "Product Category",
      type: "dropdown",
      value: "productCategory",
    },
    {
      name: "Sale End Date",
      type: "date",
      value: "saleEndDate",
    },
    {
      name: "Support End Date",
      type: "date",
      value: "supportEndDate",
    },
    {
      name: "Modified By",
      type: "readOnly",
      value: "modifiedBy",
    },
    {
      name: "Product Name",
      type: "input",
      value: "productName",
    },
    {
      name: "Vendor Name",
      type: "readOnly",
      value: "vendorName",
    },
    {
      name: "Manufacturer",
      type: "dropdown",
      value: "manufacturer",
    },
    {
      name: "Sale Start Date",
      type: "date",
      value: "saleStartDate",
    },
    {
      name: "Support Start Date",
      type: "date",
      value: "supportStartDate",
    },
    {
      name: "Created By",
      type: "readOnly",
      value: "createdBy",
    },
    {
      name: "Unit Price",
      type: "input",
      value: "unitPrice",
    },
    {
      name: "Tax",
      type: "dropdown",
      value: "tax",
    },
    {
      name: "Commission Rate",
      type: "input",
      value: "commissionRate",
    },
    {
      name: "Taxable",
      type: "checkbox",
      value: "taxable",
    },
    {
      name: "Usage Unit",
      type: "dropdown",
      value: "usageUnit",
    },
    {
      name: "Quantity In Stock",
      type: "input",
      value: "quantityInStock",
    },
    {
      name: "Handler",
      type: "readOnly",
      value: "handler",
    },
    {
      name: "Qty Ordered",
      type: "input",
      value: "qtyOrdered",
    },
    {
      name: "Reorder Level",
      type: "input",
      value: "reorderLevel",
    },
    {
      name: "Quantity in Demand",
      type: "input",
      value: "quantityInDemand",
    },
  ];

  const [fieldLabels, setFieldLabels] = useState(fieldDropdowns);
  const [showSearchFields, setShowSearchFields] = useState(fieldDropdowns);
  const [searchField, setSearchField] = useState("");
  const [selectedField, setSelectedField] = useState("");
  const [existingProduct, setExistingProduct] = useState(false);
  const [vendorListVisible, setVendorListVisible] = useState(false);

  const [selectedVendorItem, setSelectedVendorItem] = useState(vendorList[0]);
  const [isVendorListModal, setIsVendorListModal] = useState(false);
  const [imageUrl, setImageUrl] = useState(null);
  const [uploadImg, setUploadImg] = useState(false);
  const [showOptionsProduct, setShowOptionsProduct] = useState(false);
  const [editNote, setEditNote] = useState(false);
  const [showTooltipSalesCommission, setShowTooltipSalesCommission] =
    useState(false);
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };
  const [selectedDealsId, setSelectedDealsId] = useState([]);

  // const [selectedHeadingsDeals, setSelectedHeadingsDeals] =
  //   useState(dealsColumn);

  const totalPages = Math.ceil(Number(totalItems) / perPage);
  const options = reduxStore.filterOptions.product;

  const usageUnitOptions = [
    { value: "Box", label: "Box" },
    { value: "Carto", label: "Carto" },
    { value: "Dozen", label: "Dozen" },
    { value: "Each", label: "Each" },
    { value: "Hour(s)", label: "Hour(s)" },
    { value: "Impressions", label: "Impressions" },
    { value: "Lb", label: "Lb" },
    { value: "M", label: "M" },
    { value: "Pack", label: "Pack" },
    { value: "Pieces", label: "Pieces" },
    { value: "Quantity", label: "Quantity" },
    { value: "Reams", label: "Reams" },
    { value: "Sheet", label: "Sheet" },
    { value: "Spiral Binder", label: "Spiral Binder" },
    { value: "Square Feet", label: "Square Feet" },
  ];

  const [selectedOption, setSelectedOption] = useState(options[0]);
  const [productInfo, setProductInfo] = useState({});
  const [selectedProductIndex, setSelectedProductIndex] = useState(0);
  const [formData, setFormData] = useState({
    productCode: "",
    productActive: false,
    productCategory: productCategoryOptions[0],
    saleEndDate: "",
    supportEndDate: "",
    productName: "",
    vendorName: "",
    vendorId: "",
    manufacturer: manufacturerOptions[0],
    saleStartDate: "",
    supportStartDate: "",
    unitPrice: "",
    tax: taxOptions[0],
    taxable: true,
    commissionRate: "",
    usageUnit: usageUnitOptions[0],
    quantityInStock: "",
    handler: "",
    qtyOrdered: "",
    reorderLevel: "",
    quantityInDemand: "",
    description: "",
    loggedInUserId: loginUserId,
    productImage: "",
  });

  const [note, setNote] = useState("");
  const [selectedNoteFiles, setSelectedNoteFiles] = useState([]);
  const [showTitleNote, setShowTitleNote] = useState(false);
  const [noteTitle, setNoteTitle] = useState("");
  const [showButtons, setShowButtons] = useState(false);
  const [isShrinked, setIsShrinked] = useState(false);
  const [emailNotification, setEmailNotification] = useState(false);
  const [apiLoader, setApiLoader] = useState(false);
  const [productUpdateInfo, setProductUpdateInfo] = useState({
    productCode: "",
    productActive: false,
    productCategory: productCategoryOptions[0],
    saleEndDate: "",
    supportEndDate: "",
    productName: "",
    vendorName: "",
    vendorId: "",
    manufacturer: manufacturerOptions[0],
    saleStartDate: "",
    supportStartDate: "",
    unitPrice: "",
    tax: taxOptions[0],
    taxable: false,
    commissionRate: "",
    usageUnit: usageUnitOptions[0],
    quantityInStock: "",
    handler: "",
    qtyOrdered: "",
    reorderLevel: "",
    quantityInDemand: "",
    description: "",
    loggedInUserId: loginUserId,
    productImage: "",
    emailNotification: false,
  });

  const resetForm = () => {
    const formData = {
      productCode: "",
      productActive: false,
      productCategory: productCategoryOptions[0],
      saleEndDate: "",
      supportEndDate: "",
      productName: "",
      vendorName: "",
      vendorId: "",
      manufacturer: manufacturerOptions[0],
      saleStartDate: "",
      supportStartDate: "",
      unitPrice: "",
      tax: taxOptions[0],
      taxable: false,
      commissionRate: "",
      usageUnit: usageUnitOptions[0],
      quantityInStock: "",
      handler: "",
      qtyOrdered: "",
      reorderLevel: "",
      quantityInDemand: "",
      description: "",
      loggedInUserId: loginUserId,
    };
    setFormData(formData);
    setProductUpdateInfo(formData);
  };

  const [editingFields, setEditingFields] = useState({
    productCode: false,
    // productActive: true,
    productCategory: false,
    saleEndDate: false,
    supportEndDate: false,
    productName: false,
    vendorName: false,
    vendorId: false,
    manufacturer: false,
    saleStartDate: false,
    supportStartDate: false,
    unitPrice: false,
    tax: false,
    taxable: false,
    // taxableInfo: false,
    commissionRate: false,
    usageUnit: false,
    quantityInStock: false,
    handler: false,
    qtyOrdered: false,
    reorderLevel: false,
    quantityInDemand: false,
    description: false,
    loggedInUserId: loginUserId,
    emailNotification: false,
  });

  const [existingClone, setExistingClone] = useState(false);
  const [updateButtonLabel, setUpdateButtonLabel] = useState("Save");
  const [deletedImg, setDeletedImg] = useState("");

  const deleteUpdateTransferMass = async () => {
    const { value } = selectedObj;
    let url = URI.massDelete;
    const requestBody = {
      loggedInUserId: loginUserId,
      moduleName: "PRODUCTS",
      massAction: modalType === "Mass Delete" ? "DELETE" : "EDIT",
      moduleIdList: selectedCheckboxes,
    };
    if (modalType === "Mass Update") {
      requestBody[value] = massUpdateValue;
      url = URI.massUpdate;
    } else if (modalType === "Mass Transfer") {
      requestBody.ownerId = selectedItem.userId;
      url = URI.massTransfer;
    }
    const headersResult = getHeaders(true);

    // Check if headers result indicates an error
    if (headersResult.error) {
      navigate("../"); // Redirect to login if token is missing
      return;
    }
    await axios
      .post(url, requestBody, {
        headers: headersResult,
      })
      .then((response) => {
        if (response.data.status === 200) {
          NotificationManager.success("", response.data.message, 5000);
          getAllProduct(currentPage, selectedOption);
          setSelectedCheckboxes([]);
          setInnerPage("list");
          setShowModalType("");
          setShowSearchFields(fieldDropdowns);
          setMassUpdateValue("");
        } else {
          NotificationManager.error("", response.data.message, 5000);
        }
      })
      .catch(function (error) {
        handleApiError(error, navigate);
      });
  };

  const insertNote = async () => {
    const formData = new FormData();
    formData.append("moduleId", productId);
    formData.append("loggedInUserId", loginUserId);
    formData.append("moduleName", "PRODUCTS");
    formData.append("noteDescription", note);
    formData.append("noteTitle", addTitle);

    selectedNoteFiles.forEach((file) => {
      formData.append("noteImages", file);
    });

    const headersResult = getHeaders(true);

    // Check if headers result indicates an error
    if (headersResult.error) {
      navigate("../"); // Redirect to login if token is missing
      return;
    }
    await axios
      .post(URI.insertNote, formData, {
        headers: headersResult,
      })
      .then(() => {
        setShowButtons(false);
        setAddTitle("");
        setNote("");
        setSelectedNoteFiles([]);
        // Pass newNoteId to fetchNoteImage
        getNotesDetails(productId, selectRecent);
      })
      .catch((error) => {
        handleApiError(error, navigate);
      });
  };

  const handleTextAreaClick = () => {
    setShowButtons(true);
  };

  const handleTextFileChange = (event) => {
    const files = Array.from(event.target.files);
    if (files) {
      setSelectedNoteFiles(files);
    } else {
      setSelectedNoteFiles([]);
    }
  };

  const handleEditClick = (field) => {
    setUpdatedFields((prevFields) => ({
      ...prevFields,
      [field]: productInfo[field],
    }));
    setEditingFields((prevFields) => ({
      ...prevFields,
      [field]: true,
    }));
  };

  const handleCancelClick = (field) => {
    setEditingFields((prevFields) => ({
      ...prevFields,
      [field]: false,
    }));
  };

  const [updatedFields, setUpdatedFields] = useState({
    productCode: productInfo.productCode,
    productActive: productInfo.productActive,
    productCategory: productInfo.productCategory,
    saleEndDate: productInfo.saleEndDate,
    supportEndDate: productInfo.supportEndDate,
    productName: productInfo.productName,
    vendorName: productInfo.vendorName,
    vendorId: selectedVendorItem?.vendorId,
    manufacturer: productInfo.manufacturer,
    saleStartDate: productInfo.saleStartDate,
    supportStartDate: productInfo.supportStartDate,
    unitPrice: productInfo.unitPrice,
    tax: productInfo.taxOptions,
    taxable: productInfo.taxable,
    commissionRate: productInfo.commissionRate,
    usageUnit: productInfo.usageUnit,
    quantityInStock: productInfo.quantityInStock,
    handler: productInfo.handler,
    qtyOrdered: productInfo.qtyOrdered,
    reorderLevel: productInfo.reorderLevel,
    quantityInDemand: productInfo.quantityInDemand,
    description: productInfo.description,
    salesEndDateInfo: productInfo.saleEndDate,
    salesStartInfo: productInfo.saleStartDate,
    quantityInStockInfo: productInfo.quantityInStock,
    commissionRateInfo: productInfo.commissionRate,
    // taxable: productInfo.taxable,
    usageUnitInfo: productInfo.usageUnit,
    reorderLevelInfo: productInfo.reorderLevel,
    descriptionInfo: productInfo.description,
    supportEndDateInfo: productInfo.supportEndDate,
    supportStartDateInfo: productInfo.supportStartDate,
    taxInfo: productInfo.taxOptions,
    unitPriceInfo: productInfo.unitPrice,

    loggedInUserId: loginUserId,
    emailNotification: emailNotification,
  });
  // for save and new && save lottie loader
  const [saveSubmit, setSaveSubmit] = useState(false);
  const [saveAndNewSubmit, setSaveAndNewSubmit] = useState(false);

  const handleChange = (option) => {
    // setSelectedOption(selectedOption);

    if (selectedOption.value !== option.value) {
      setCurrentPage(1);
      // getAllDealsDetails(1,option);
      getAllProduct(1, option);
    }
  };

  const handleAddTitleClick = () => {
    setShowTitleNote(true);
  };

  const createLeadStyles = {
    menu: (base) => ({
      ...base,
      width: "60%",
    }),
    option: (provided) => ({
      ...provided,
      // backgroundColor: "#EAEAEA",
      color: "#000",
      // backgroundColor: 'rgba(33, 119, 254, 0.1)',
      width: "100%",
    }),
    control: (baseStyles) => ({
      ...baseStyles,
      border: "1px solid #b9b9b9b0",
      backgroundColor: "#F3F6FB",
      height: "40px",
      borderRadius: "10px",
      width: "60%",
      boxShadow: "none",
    }),
    input: (base) => ({
      ...base,
      color: "#FFFFFF",
    }),
    singleValue: (defaultStyles) => ({ ...defaultStyles, color: "#000" }),

    menuList: (provided) => ({
      ...provided,
      paddingTop: 0,
      paddingBottom: 0,
      "&::-webkit-scrollbar": {
        width: 5,
      },

      "&::-webkit-scrollbar-thumb": {
        background: "#316AFF",
        borderRadius: "10px",
      },
    }),
  };

  const handleInputCheckbox = (e) => {
    const { name, checked } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: checked,
    }));
  };
  const handleInputFieldChange = (e) => {
    const { name, value } = e.target;
    // if (name === "productCode" && !validateProductCode(value)) {
    //   return;
    // }

    if (name === "unitPrice") {
      handleTooltip(
        value,
        setShowTooltipExpectedRevenue,
        setTooltipExpectedRevenue
      );
    } else if (name === "commissionRate") {
      handleTooltip(
        value,
        setShowTooltipSalesCommission,
        setTooltipSalesCommission
      );
    }

    const dateFields = [
      "saleStartDate",
      "saleEndDate",
      "supportStartDate",
      "supportEndDate",
    ];

    const formattedValue = dateFields.includes(name)
      ? convertToDisplayFormat(value)
      : value;

    if (existingProduct || existingClone) {
      setProductUpdateInfo((prevLeadInfo) => ({
        ...prevLeadInfo,
        [name]: formattedValue,
      }));
    } else {
      setFormData((prevLeadInfo) => ({
        ...prevLeadInfo,
        [name]: formattedValue,
      }));
    }
  };

  const handleIndividualInputChange = (field, value) => {
    const formattedValue = [
      "unitPrice",
      "commissionRate",
      "quantityInStock",
      "qtyOrdered",
      "reorderLevel",
      "quantityInDemand",
    ].includes(field)
      ? value.replace(/[^0-9]/g, "")
      : value;

    if (field === "productCode" && !validateProductCode(value)) {
      return;
    }
    if (field === "supportEndDate" || field === "saleEndDate") {
      if (field === "supportEndDate") {
        if (value < productInfo.supportStartDate) {
          return;
        }
      } else if (field === "saleEndDate") {
        if (value < productInfo.saleStartDate) {
          return;
        }
      }
    }

    setUpdatedFields((prevFields) => ({
      ...prevFields,
      [field]: formattedValue,
    }));
  };

  const handleChangeSelectDropDown = (selectedOption, field) => {
    if (existingProduct || existingClone) {
      setProductUpdateInfo((prevLeadInfo) => ({
        ...prevLeadInfo,
        [field]: selectedOption,
      }));
    } else {
      setFormData((prevLeadInfo) => ({
        ...prevLeadInfo,
        [field]: selectedOption,
      }));
    }
  };

  const handleChangeIndDropDown = (selectedOption, field) => {
    setUpdatedFields((prevFields) => ({
      ...prevFields,
      [field]: selectedOption,
    }));
  };

  const addNewProduct = async (e) => {
    let isValidForm = true;

    const setErrorMessage = (setter, message, timeout = 5000) => {
      setter(message);
      setTimeout(() => {
        setter("");
      }, timeout);
      isValidForm = false;
    };

    const productData =
      existingProduct || existingClone ? productUpdateInfo : formData;

    if (
      selectedVendorItem?.vendorId === "" ||
      selectedVendorItem === undefined
    ) {
      setErrorMessage(
        setVendorNameErrorMsg,
        "Vendor name cannot be empty.",
        5000
      );
    }
    if (productData.productName === "") {
      setErrorMessage(
        setProductNameErrorMsg,
        "Product name cannot be empty.",
        5000
      );
    }
    if (
      !validateProductName(productData?.productName?.toString()) &&
      productData.productName
    ) {
      setErrorMessage(
        setProductNameErrorMsg,
        "Please enter a valid Product name."
      );
    }
    if (productData.productCode === "") {
      setErrorMessage(
        setProductCodeErrorMsg,
        "Product code cannot be empty.",
        5000
      );
    }
    if (productData.unitPrice && !validateCalcField(productData.unitPrice)) {
      setErrorMessage(
        setUnitPriceMsg,
        "Please enter a valid Unit Price.",
        5000
      );
    }
    if (
      !validateQuantityOrdered(productData?.qtyOrdered?.toString()) &&
      productData.qtyOrdered
    ) {
      setErrorMessage(
        setQuantityOrderedMsg,
        "Please enter a valid Qty Ordered.",
        5000
      );
    }
    if (
      !validateQuantityInStock(productData?.quantityInStock?.toString()) &&
      productData.quantityInStock
    ) {
      setErrorMessage(
        setQuantityInStockMsg,
        "Please enter a valid Quantity in Stock.",
        5000
      );
    }
    if (
      !validateReorderLevel(productData?.reorderLevel?.toString()) &&
      productData.reorderLevel
    ) {
      setErrorMessage(
        setRecordLevelMsg,
        "Please enter a valid Reorder Level.",
        5000
      );
    }
    if (
      !validateQuantityInDemand(productData?.quantityInDemand?.toString()) &&
      productData.quantityInDemand
    ) {
      setErrorMessage(
        setQuantityDemandMsg,
        "Please enter a valid quantity in demand.",
        5000
      );
    }

    if (!isValidForm) return;
    const formDataProduct = new FormData();
    let requestBody = {};
    e === "SAVE" ? setSaveSubmit(true) : setSaveAndNewSubmit(true);

    if (existingClone) {
      requestBody = {
        productId: productId,
        productOwnerId: selectedItem?.userId,
        productCode: removeExtraSpaces(productUpdateInfo.productCode),
        productActive:
          productUpdateInfo.productActive === true ? "true" : "false",
        productCategory: productUpdateInfo.productCategory.value,
        saleEndDate: productUpdateInfo.saleEndDate,
        supportEndDate: productUpdateInfo.supportEndDate,
        productName: removeExtraSpaces(productUpdateInfo.productName),
        vendorId: selectedVendorItem?.vendorId,
        manufacturer: productUpdateInfo.manufacturer.value,
        saleStartDate: productUpdateInfo.saleStartDate,
        supportStartDate: productUpdateInfo.supportStartDate,
        unitPrice: productUpdateInfo.unitPrice,
        tax: productUpdateInfo.tax.value,
        taxable: productUpdateInfo.taxable === true ? "true" : "false",
        commissionRate: productUpdateInfo.commissionRate,
        usageUnit: productUpdateInfo.usageUnit.value,
        quantityInStock: productUpdateInfo.quantityInStock,
        handlerOwnerId: selectedHandlerItem?.userId,
        qtyOrdered: productUpdateInfo.qtyOrdered,
        reorderLevel: productUpdateInfo.reorderLevel,
        quantityInDemand: productUpdateInfo.quantityInDemand,
        description: removeExtraSpaces(productUpdateInfo.description),
        loggedInUserId: loginUserId,
      };
    } else {
      requestBody = {
        productOwnerId: selectedItem?.userId,
        // productOwner: "Jagadish",
        productCode: removeExtraSpaces(formData.productCode),
        productActive: formData.productActive === true ? "true" : "false",
        productCategory: formData.productCategory.value,
        saleEndDate: formData.saleEndDate,
        supportEndDate: formData.supportEndDate,
        productName: removeExtraSpaces(formData.productName),
        // vendorName: "BCRM0030",
        vendorId: selectedVendorItem?.vendorId,
        manufacturer: formData.manufacturer.value,
        saleStartDate: formData.saleStartDate,
        supportStartDate: formData.supportStartDate,
        unitPrice: parseFloat(formData.unitPrice),
        tax: formData.tax.value,
        taxable: formData.taxable === true ? "true" : "false",
        commissionRate: parseFloat(formData.commissionRate),
        usageUnit: formData.usageUnit.value,
        quantityInStock: parseFloat(formData.quantityInStock),
        handlerOwnerId: selectedHandlerItem?.userId,
        qtyOrdered: parseFloat(formData.qtyOrdered),
        reorderLevel: formData.reorderLevel,
        quantityInDemand: parseFloat(formData.quantityInDemand),
        description: removeExtraSpaces(formData.description),
        loggedInUserId: loginUserId,
      };
    }

    formDataProduct.append("productDtlsInputs", JSON.stringify(requestBody));
    // formDataProduct.append("productImage", formData.productImage);
    formDataProduct.append(
      "productImage",
      existingClone ? productUpdateInfo.productImage : formData.productImage
    );

    const headersResult = getHeaders(true);

    // Check if headers result indicates an error
    if (headersResult.error) {
      navigate("../"); // Redirect to login if token is missing
      return;
    }

    await axios
      .post(URI.createProduct, formDataProduct, {
        headers: headersResult,
      })
      .then((response) => {
        if (response.data.status === 200) {
          NotificationManager.success("", response.data.message, 5000);
          setSelectedFiles(null);
          fileInputRef.current.value = "";
          resetForm();
          setSelectedItem(userList[0]);
          setSelectedVendorItem(vendorList[0]);
          getAllProduct(1, selectedOption);
          if (e === "SAVE AND NEW") {
            setInnerPage("create");
          } else {
            setInnerPage("list");
          }
        } else {
          NotificationManager.error("", response.data.message, 5000);
        }
      })
      .catch((err) => {
        handleApiError(err, navigate);
      })
      .finally(() => {
        setSaveSubmit(false);
        setSaveAndNewSubmit(false);
      });
  };

  const updateProduct = async (e) => {
    let isValidForm = true;

    const setErrorMessage = (setter, message, timeout = 5000) => {
      setter(message);
      setTimeout(() => {
        setter("");
      }, timeout);
      isValidForm = false;
    };

    const productData =
      existingProduct || existingClone ? productUpdateInfo : formData;

    if (productData.productName === "") {
      setErrorMessage(
        setProductNameErrorMsg,
        "Product name cannot be empty.",
        5000
      );
    }

    if (
      selectedVendorItem?.vendorId === "" ||
      selectedVendorItem === undefined
    ) {
      setErrorMessage(
        setVendorNameErrorMsg,
        "Vendor name cannot be empty.",
        5000
      );
    }

    if (
      !validateProductName(productData?.productName?.toString()) &&
      productData.productName.length > 0
    ) {
      setErrorMessage(
        setProductNameErrorMsg,
        "Please enter a valid Product name."
      );
    }
    if (productData.productCode === "") {
      setErrorMessage(
        setProductCodeErrorMsg,
        "Product code cannot be empty.",
        5000
      );
    }
    if (
      !validateUnitPrice(productData?.unitPrice?.toString()) &&
      productData.unitPrice
    ) {
      setErrorMessage(
        setUnitPriceMsg,
        "Please enter a valid Unit price.",
        5000
      );
    }
    if (
      !validateQuantityOrdered(productData?.qtyOrdered?.toString()) &&
      productData.qtyOrdered
    ) {
      setErrorMessage(
        setQuantityOrderedMsg,
        "Please enter a valid Qty Ordered.",
        5000
      );
    }

    if (
      !validateQuantityInStock(productData?.quantityInStock?.toString()) &&
      productData.quantityInStock
    ) {
      setErrorMessage(
        setQuantityInStockMsg,
        "Please enter a valid Quantity in Stock.",
        5000
      );
    }
    if (
      !validateReorderLevel(productData?.reorderLevel?.toString()) &&
      productData.reorderLevel
    ) {
      setErrorMessage(
        setRecordLevelMsg,
        "Please enter a valid Reorder Level.",
        5000
      );
    }
    if (
      !validateQuantityInDemand(productData?.quantityInDemand?.toString()) &&
      productData.quantityInDemand
    ) {
      setErrorMessage(
        setQuantityDemandMsg,
        "Please enter a valid Quantity in demand.",
        5000
      );
    }

    if (!isValidForm) return;
    const formDataProduct = new FormData();
    setSaveSubmit(true);

    const requestBody = {
      productId: productId,
      productOwnerId: selectedItem?.userId,
      productCode: removeExtraSpaces(productUpdateInfo.productCode),
      productActive:
        productUpdateInfo.productActive === true ? "true" : "false",
      productCategory: productUpdateInfo?.productCategory?.value,
      productName: removeExtraSpaces(productUpdateInfo.productName),
      vendorId: selectedVendorItem?.vendorId,
      manufacturer: productUpdateInfo?.manufacturer?.value,
      saleStartDate: productUpdateInfo.saleStartDate,
      saleEndDate: productUpdateInfo.saleEndDate,
      supportStartDate: productUpdateInfo.supportStartDate,
      supportEndDate: productUpdateInfo.supportEndDate,
      unitPrice: productUpdateInfo.unitPrice,
      tax: productUpdateInfo?.tax?.value,
      taxable: productUpdateInfo.taxable === true ? "true" : "false",
      commissionRate: productUpdateInfo.commissionRate,
      usageUnit: productUpdateInfo?.usageUnit?.value,
      quantityInStock: productUpdateInfo.quantityInStock,
      handlerOwnerId: selectedHandlerItem?.userId,
      qtyOrdered: productUpdateInfo.qtyOrdered,
      reorderLevel: productUpdateInfo.reorderLevel,
      quantityInDemand: productUpdateInfo.quantityInDemand,
      description: removeExtraSpaces(productUpdateInfo.description),
      loggedInUserId: loginUserId,
      emailNotification: emailNotification,
    };

    formDataProduct.append("productDtlsInputs", JSON.stringify(requestBody));
    formDataProduct.append("productImage", productUpdateInfo.productImage);

    const headersResult = getHeaders(true);

    // Check if headers result indicates an error
    if (headersResult.error) {
      navigate("../"); // Redirect to login if token is missing
      return;
    }
    await axios
      .post(URI.updateAllProduct, formDataProduct, {
        headers: headersResult,
      })
      .then((response) => {
        if (response.data.status === 200) {
          resetForm();
          setSelectedFiles(null);
          fileInputRef.current.value = "";
          NotificationManager.success("", response.data.message, 5000);

          if (e === "UPDATE") {
            getProductOverview(productId);
            setInnerPage("existingProduct");
            resetForm();
            setSelectedItem();
            setSelectedVendorItem();
            setExistingProduct(false);
          }
        } else {
          NotificationManager.error("", response.data.message, 5000);
        }
      })
      .catch((err) => {
        handleApiError(err, navigate);
      })
      .finally(() => {
        setSaveSubmit(false);
      });
  };

  const EditableCheckbox = ({
    fieldType,
    checked,
    handleEditClick,
    handleUpdateClick,
    showType,
    setShowType,
  }) => {
    return (
      <div className="d-flex editInput_container">
        {showType !== fieldType ? (
          <span
            className="right_tick cursorPointer"
            onClick={() => {
              handleEditClick(fieldType);
              setShowType(fieldType);
            }}
          >
            {checked ? tick : "-"}
          </span>
        ) : (
          <input
            type="checkbox"
            checked={updatedFields[fieldType]}
            onChange={(e) => {
              setUpdatedFields((prevFields) => ({
                ...prevFields,
                [fieldType]: e.target.checked,
              }));
            }}
            onClick={() => {
              handleEditClick(fieldType);
            }}
          />
        )}
        {showType === fieldType && (
          <div className="d-flex gap-2">
            <span
              onClick={() => {
                setShowType("");
                handleUpdateClick(fieldType);
              }}
              className="ps-2 cursorPointer"
            >
              <img src={updateIcon} alt="Update" height={20} width={20} />
            </span>
            <span
              onClick={() => {
                setShowType("");
                handleCancelClick(fieldType);
              }}
              className="cursorPointer"
            >
              <img src={cancelIcon} alt="Cancel" height={20} width={20} />
            </span>
          </div>
        )}
      </div>
    );
  };

  const getAllInfoShare = async (productID) => {
    setIsLoading(true);
    const requestBody = {
      moduleId: productID,
      loggedInUserId: loginUserId,
    };

    const headersResult = getHeaders(true);

    // Check if headers result indicates an error
    if (headersResult.error) {
      navigate("../"); // Redirect to login if token is missing
      return;
    }
    await axios
      .post(URI.getShareInfo, requestBody, {
        headers: headersResult,
      })
      .then((response) => {
        if (response.data.status === 200) {
          const hasSharedItems =
            (response.data.data.getSharingInfoUsers &&
              response.data.data.getSharingInfoUsers.length > 0) ||
            (response.data.data.getSharingInfoRoles &&
              response.data.data.getSharingInfoRoles.length > 0) ||
            (response.data.data.getSharingInfoGroups &&
              response.data.data.getSharingInfoGroups.length > 0) ||
            (response.data.data.getSharingInfoPublic &&
              response.data.data.getSharingInfoPublic.length > 0);

          const userShareMemberIds = response.data.data.getSharingInfoUsers.map(
            (user) => user.shareModuleId
          );
          const roleShareMemberIds = response.data.data.getSharingInfoRoles.map(
            (role) => role.shareModuleId
          );
          const groupShareMemberIds =
            response.data.data.getSharingInfoGroups.map(
              (group) => group.shareModuleId
            );
          const groupPublicSharedMemberIds =
            response.data.data.getSharingInfoPublic.map(
              (user) => user.shareModuleId
            );
          setShareMemberIds([
            ...userShareMemberIds,
            ...roleShareMemberIds,
            ...groupShareMemberIds,
            ...groupPublicSharedMemberIds,
          ]);

          setShareUserList(response.data.data.getSharingInfoUsers);
          setShareRoleList(response.data.data.getSharingInfoRoles);
          setShareGroupList(response.data.data.getSharingInfoGroups);
          setSharePublicList(response.data.data.getSharingInfoPublic);
          if (hasSharedItems) {
            setShareInfoModal(true);
          } else {
            setShareModal(true);
          }
          setIsLoading(false);
        }
      })
      .catch((err) => {
        handleApiError(err, navigate);
      });
  };

  const getProductOverview = async (productID) => {
    let productOverviewDetails = {};

    const headersResult = getHeaders(true);

    // Check if headers result indicates an error
    if (headersResult.error) {
      navigate("../"); // Redirect to login if token is missing
      return;
    }
    await axios
      .get(URI.getProductOverview + loginUserId + "/" + productID, {
        headers: headersResult,
      })
      .then((response) => {
        if (response.data.status === 200) {
          productOverviewDetails = response.data.data.getProductDeatils;

          productOverviewDetails.salesEndDateInfo =
            response.data.data.getProductDeatils.saleEndDate;
          productOverviewDetails.salesStartInfo =
            response.data.data.getProductDeatils.saleStartDate;
          productOverviewDetails.quantityInStockInfo =
            response.data.data.getProductDeatils.quantityInStock;
          productOverviewDetails.commissionRateInfo =
            response.data.data.getProductDeatils.commissionRate;
          productOverviewDetails.taxable =
            response.data.data.getProductDeatils.taxable;
          productOverviewDetails.usageUnitInfo =
            response.data.data.getProductDeatils.usageUnit;
          productOverviewDetails.reorderLevelInfo =
            response.data.data.getProductDeatils.reorderLevel;
          productOverviewDetails.descriptionInfo =
            response.data.data.getProductDeatils.description;
          productOverviewDetails.supportEndDateInfo =
            response.data.data.getProductDeatils.supportEndDate;
          productOverviewDetails.supportStartDateInfo =
            response.data.data.getProductDeatils.supportStartDate;
          productOverviewDetails.unitPriceInfo =
            response.data.data.getProductDeatils.unitPrice;

          productOverviewDetails.taxInfo = {
            label: response.data.data.getProductDeatils.tax,
            value: response.data.data.getProductDeatils.tax,
          };

          productOverviewDetails.manufacturer = {
            label: response.data.data.getProductDeatils.manufacturer,
            value: response.data.data.getProductDeatils.manufacturer,
          };

          productOverviewDetails.productCategory = {
            label: response.data.data.getProductDeatils.productCategory,
            value: response.data.data.getProductDeatils.productCategory,
          };

          productOverviewDetails.usageUnit = {
            label: response.data.data.getProductDeatils.usageUnit,
            value: response.data.data.getProductDeatils.usageUnit,
          };

          productOverviewDetails.tax = {
            label: response.data.data.getProductDeatils.tax,
            value: response.data.data.getProductDeatils.tax,
          };
          const updatedFields = {
            salesEndDateInfo: response.data.data.getProductDeatils.saleEndDate,
            salesStartInfo: response.data.data.getProductDeatils.saleStartDate,
            saleStartDate: response.data.data.getProductDeatils.saleStartDate,
            quantityInStockInfo:
              response.data.data.getProductDeatils.quantityInStock,
            commissionRateInfo:
              response.data.data.getProductDeatils.commissionRate,
            taxable: response.data.data.getProductDeatils.taxable,
            usageUnitInfo: response.data.data.getProductDeatils.usageUnit,
            reorderLevelInfo: response.data.data.getProductDeatils.reorderLevel,
            descriptionInfo: response.data.data.getProductDeatils.description,
            supportEndDateInfo:
              response.data.data.getProductDeatils.supportEndDate,
            supportStartDateInfo:
              response.data.data.getProductDeatils.supportStartDate,
            unitPriceInfo: response.data.data.getProductDeatils.unitPrice,
            taxInfo: {
              label: response.data.data.getProductDeatils.tax,
              value: response.data.data.getProductDeatils.tax,
            },

            manufacturer: {
              label: response.data.data.getProductDeatils.manufacturer,
              value: response.data.data.getProductDeatils.manufacturer,
            },

            productCategory: {
              label: response.data.data.getProductDeatils.productCategory,
              value: response.data.data.getProductDeatils.productCategory,
            },

            tax: {
              label: response.data.data.getProductDeatils.tax,
              value: response.data.data.getProductDeatils.tax,
            },

            productActive: response.data.data.getProductDeatils.productActive,
            // taxable: response.data.data.getProductDeatils.taxable, duplicate key

            productImage: response.data.data.getProductDeatils.productImageData,
          };

          // let vendorNameObj = vendorList.find(
          //   (item) =>
          //     item.vendorId ===
          //     response.data.data?.purchaseVendorDetails?.vendorId
          // );
          setFilterContactDetails(response.data.data.getProdContDetails);
          setFilterLeadDetails(response.data.data.getProdLeadDetails);
          setFilterDealDetails(response.data.data.getProdDealDetails);
          setFilterAccountDetails(response.data.data.getProdAccoDetails);
          // setProductActive(response.data.data.getProductDeatils.productActive);
          setUpdatedFields(updatedFields);
          // let vendorName = vendorList.find(
          //   (item) =>
          //     item.vendorName === response.data.data.getProductDeatils.dealName
          // );

          // setSelectedVendorItem({
          //   vendorName: response.data.data.getProductDeatils.vendorName,
          //   vendorId: response.data.data.getProductDeatils.vendorId,
          // });

          setSelectedVendorItem(
            vendorList.find(
              (user) =>
                user.vendorName ===
                response.data.data.getProductDeatils.vendorName
            )
          );
          // setProductInfo(response.data.data.getProductDeatils);
          setProductInfo(
            productOverviewDetails
            // vendorName: vendorName?.vendorName,
          );
          setSelectedItem(
            userList.find(
              (user) =>
                user.userId === response.data.data.getProductDeatils.prodOwnerId
            )
          );
          setSelectedOwnerItem(
            userList.find(
              (user) =>
                user.userId === response.data.data.getProductDeatils.prodOwnerId
            )
          );
          setOpenActivities(response.data.data.getOpenActivitiesDetails);
          setOpenActivitiesCalls(response.data.data.openActivitiesCalls);
          setOpenActivitiesMeetings(response.data.data.openActivitiesMeetings);
          setClosedActivitiesCall(response.data.data.closedActivitiesCalls);
          setClosedActivitiesMeetings(
            response.data.data.closedActivitiesMeetings
          );
          setCloseActivities(response.data.data.getClosedActivitiesDetails);
          setInnerPage("existingProduct");
          setSelectedHandlerItem(
            userList.find(
              (item) =>
                item.userId ===
                response.data.data.getProductDeatils.handlerOwnerId
            )
          );
        }
      })
      .catch((err) => {
        handleApiError(err, navigate);
      });
  };

  const getAllProduct = async (pageNo, selectedOption) => {
    setSelectedOption(selectedOption);
    setIsLoading(true);
    const requestBody = {
      loggedInUserId: loginUserId,
      customFilter: selectedOption.value,
      pageNo: pageNo,
      pageSize: perPage,
    };

    const headersResult = getHeaders(true);

    // Check if headers result indicates an error
    if (headersResult.error) {
      navigate("../"); // Redirect to login if token is missing
      return;
    }
    await axios
      .post(URI.getAllProduct, requestBody, {
        headers: headersResult,
      })
      .then((response) => {
        if (response.data.status === 200) {
          setAllProductDetails(response.data.data.allProductDetails);
          setFilteredProductDetails(response.data.data.allProductDetails);
          setTotalItems(response.data.data.rowCount);
          setIsLoading(false);
        } else {
          setIsLoading(false);
        }
      })
      .catch(async (error) => {
        handleApiError(error, navigate);
      });
  };

  const getAllVendorDetails = async () => {
    const requestBody = {
      loggedInUserId: loginUserId,
      pageNo: currentPageVendor,
      pageSize: perPageVendor,
    };

    const headersResult = getHeaders(true);

    // Check if headers result indicates an error
    if (headersResult.error) {
      navigate("../"); // Redirect to login if token is missing
      return;
    }

    await axios
      .post(URI.getAllVendors, requestBody, {
        headers: headersResult,
      })

      .then((response) => {
        if (response.data.status === 200) {
          setVendorList(response.data.data.allVendorsDtls);
          setModalVendorDetails(response.data.data.allVendorsDtls);
          setTotalItemsVendor(response.data.data.rowCount);
        }
      })
      .catch(async (error) => {
        handleApiError(error, navigate);
      });
  };

  const handleOwnerInputClick = (e) => {
    e.stopPropagation();
    setListVisible(!listVisible);
    setListVisibleHandler(false);
    setVendorListVisible(false);
  };

  const handleHandlerInputClick = (e) => {
    e.stopPropagation();
    setVendorListVisible(false);
    setListVisible(false);
    setListVisibleHandler(!listVisibleHandler);

    // Setting the state to determine which input triggered the modal
  };

  const handleUserItemClick = (item) => {
    setSelectedItem(item);
    setListVisible(false);
  };

  const handleHandlerItemClick = (item) => {
    setSelectedHandlerItem(item);
    setListVisible(false);
  };

  const handleChangeOwnerItemClick = (item) => {
    setSelectedOwnerItem(item);
    setIsChangeOwnerList(false);
    setIsOwnerSelect(true);
  };

  const handleChangeOwnerInputClick = (e) => {
    e.stopPropagation();
    setIsChangeOwnerList(!isChangeOwnerList);

    // setInvidualListVisible(!individualListVisible);
  };
  const handleSelectedLeadChange = (value) => {
    if (isForHandler) {
      setSelectedOwnerItem(value);
    } else {
      setSelectedItem(value);
    }
    setSelectedHandlerItem(value);
  };

  const handlePageDropDowmChange = (selectedOption) => {
    setCurrentPage(1);
    setPerPage(parseInt(selectedOption.value));
  };

  const sortedColumnsData = (type, headingName) => {
    const sortedData = [...allProductDetails];

    if (type === "asc") {
      sortedData.sort((a, b) => {
        if (
          typeof a[headingName] === "string" &&
          typeof b[headingName] === "string"
        ) {
          return a[headingName].localeCompare(b[headingName]);
        }
        return a[headingName] - b[headingName];
      });
    } else if (type === "desc") {
      sortedData.sort((a, b) => {
        if (
          typeof a[headingName] === "string" &&
          typeof b[headingName] === "string"
        ) {
          return b[headingName].localeCompare(a[headingName]);
        }
        return b[headingName] - a[headingName];
      });
    }
    setFilteredProductDetails(sortedData);
  };

  const handlePinColumn = (name) => {
    setPinnedColumn(name);
    const updatedColumns = [...selectedHeadings];
    const clickedColumnIndex = updatedColumns.findIndex(
      (column) => column.name === name
    );

    if (clickedColumnIndex !== -1) {
      const clickedColumn = updatedColumns.splice(clickedColumnIndex, 1)[0];
      updatedColumns.unshift(clickedColumn);
      setSelectedHeadings(updatedColumns);
    }
  };

  const handleFieldClick = (field) => {
    if (field.type === "dropdown") {
      const fieldValue = field.name
        .replace(/\s+/g, "")
        .replace(/^(.)/, (match) => match.toLowerCase());

      // setClickedDropdownObj(leadOverviewDetails[fieldValue]);
      // setUpdatedFields -- need to update
      setUpdatedFields((prevFields) => ({
        ...prevFields,
        [fieldValue]: productInfo[fieldValue],
      }));
    } else if (field.type === "checkbox") {
      setUpdatedFields((prevFields) => ({
        ...prevFields,
        productActive: productInfo["productActive"],
      }));
    }

    const updatedColumns = [...fieldLabels];

    const existedObj = updatedColumns.find(
      (field) => field.name === selectedField
    );
    const selectedObj = updatedColumns.find((item) => item.name === field.name);

    const existedObjIndex = updatedColumns.findIndex(
      (field) => field.name === existedObj.name
    );
    const selectedObjIndex = updatedColumns.findIndex(
      (field) => field.name === selectedObj.name
    );

    updatedColumns[existedObjIndex].isShowed = false;
    updatedColumns[selectedObjIndex].isShowed = true;

    const removedObj = updatedColumns.splice(existedObjIndex, 1)[0];
    updatedColumns.splice(existedObjIndex, 0, selectedObj);

    updatedColumns.push(removedObj);
    const uniqueData = updatedColumns.filter(
      (item, index, self) =>
        index === self.findIndex((t) => t.name === item.name)
    );
    setFieldLabels(uniqueData);
    setShowSearchFields(uniqueData);
  };

  const handleUpdateClick = async (field) => {
    let requestBody = {};
    var value = "";
    const isObj =
      typeof productInfo[field] === "object" &&
      productInfo[field] !== null &&
      !Array.isArray(productInfo[field]);

    if (
      field === "productCategory" ||
      field === "manufacturer" ||
      field === "usageUnit" ||
      field === "tax" ||
      field === "taxInfo"
    ) {
      value = updatedFields[field].value;
    } else {
      value = updatedFields[field];
    }
    const fieldMappings = {
      salesEndDateInfo: "saleEndDate",
      salesStartInfo: "saleStartDate",
      quantityInStockInfo: "quantityInStock",
      commissionRateInfo: "commissionRate",
      // taxableInfo: "taxable",
      usageUnitInfo: "usageUnit",
      reorderLevelInfo: "reorderLevel",
      descriptionInfo: "description",
      supportEndDateInfo: "supportEndDate",
      supportStartDateInfo: "supportStartDate",
      taxInfo: "tax",
      unitPriceInfo: "unitPrice",
    };

    // if (fieldMappings.hasOwnProperty(field)) {
    //   field = fieldMappings[field];
    // } not supported by eslint
    if (Object.hasOwn(fieldMappings, field)) {
      field = fieldMappings[field];
    }

    try {
      const formData = new FormData();
      if (field === "emailNotification") {
        requestBody = {
          productId: productId,
          loggedInUserId: loginUserId,
          productOwnerId: isOwnerSelect
            ? selectedOwnerItem?.userId
            : selectedItem?.userId,
          emailNotification: emailNotification,
        };
      } else if (["taxable", "productActive"].includes(field)) {
        requestBody = {
          productId: productId,
          [field]: updatedFields[field] === true ? "true" : "false",
          loggedInUserId: loginUserId,
        };
      } else {
        requestBody = {
          productId: productId,
          [field]: value,
          loggedInUserId: loginUserId,
        };
      }

      const compareValue = isObj
        ? productInfo[field].value
        : productInfo[field];

      if (value !== compareValue || field === "emailNotification") {
        setApiLoader(true);
        formData.append("productDtlsInputs", JSON.stringify(requestBody));

        const headersResult = getHeaders(true);
        // Check if headers result indicates an error
        if (headersResult.error) {
          navigate("../"); // Redirect to login if token is missing
          return;
        }
        const response = await axios.post(
          URI.individualUpdateProduct,
          formData,
          {
            headers: headersResult,
          }
        );
        if (response.data.status === 200) {
          NotificationManager.success("", response.data.message, 5000);

          setProductInfo((prevDetails) => ({
            ...prevDetails,
            [field]: updatedFields[field],
          }));
          setIsOwnerSelect(false);
          getProductOverview(productId);
          setShowModalType("");
          // setIsSelectedOwner(true);
        } else {
          NotificationManager.error("", response.data.message, 5000);
        }

        const resetFields = Object.fromEntries(
          Object.keys(editingFields).map((key) => [key, false])
        );
        setEditingFields({
          ...resetFields,
        });
        setShowType("");
      }
    } catch (error) {
      handleApiError(error, navigate);
    } finally {
      setApiLoader(false);
    }
  };

  const getAllTimelineProduct = async () => {
    const headersResult = getHeaders(true);

    // Check if headers result indicates an error
    if (headersResult.error) {
      navigate("../"); // Redirect to login if token is missing
      return;
    }
    await axios
      .get(URI.getProductTimeline + "/" + productId + "/" + loginUserId, {
        headers: headersResult,
      })
      .then((response) => {
        if (response.data.status === 200) {
          setTimeLineDataList(response.data.data.getProductTimeline);
          setProductPageView("timeline");
        }
      })
      .catch(async (error) => {
        handleApiError(error, navigate);
      });
  };

  const handleAssignModule = async (selectedIds, assignModuleName) => {
    const moduleIdList = selectedIds.map((id) => ({ moduleId: id }));
    const requestBody = {
      loginUserId: loginUserId,
      moduleName: "PRODUCTS",
      moduleId: productId,
      assignModuleName: assignModuleName,
      moduleIdList,
    };

    try {
      const headersResult = getHeaders(true);

      // Check if headers result indicates an error
      if (headersResult.error) {
        navigate("../"); // Redirect to login if token is missing
        return;
      }

      const response = await axios.post(URI.assignRole, requestBody, {
        headers: headersResult,
      });
      if (response.data.status === 200) {
        NotificationManager.success("", response.data.message, 5000);
        getProductOverview(productId);
      } else {
        NotificationManager.error("", response.data.message, 5000);
      }
    } catch (error) {
      handleApiError(error, navigate);
    }
  };

  const handleAssignDealsModule = (selectedInvoiceId) => {
    handleAssignModule(selectedInvoiceId, "DEALS");
  };

  const handledeleteModuleDeal = async (deleteDealId) => {
    const requestBody = {
      loginUserId: loginUserId,
      moduleName: "PRODUCTS",
      moduleId: productId,
      assignModuleName: "DEALS",
      assignModuleId: deleteDealId,
    };

    try {
      const headersResult = getHeaders(true);

      // Check if headers result indicates an error
      if (headersResult.error) {
        navigate("../"); // Redirect to login if token is missing
        return;
      }
      const response = await axios.post(URI.deleteRole, requestBody, {
        headers: headersResult,
      });
      if (response.data.status === 200) {
        NotificationManager.success("", response.data.message, 5000);
        getProductOverview(productId);
      } else {
        NotificationManager.error("", response.data.message, 5000);
      }
    } catch (error) {
      handleApiError(error, navigate);
    }
  };

  // for Vendor Name List dropdown

  const handleVendorClick = (e) => {
    e.stopPropagation();
    setVendorListVisible(!vendorListVisible);
    setListVisibleHandler(false);
    setListVisible(false);
  };

  const handleVendorItemClick = (item) => {
    setSelectedVendorItem(item);
    setVendorListVisible(false);
  };

  const updateNote = async (noteId) => {
    const formData = new FormData();
    formData.append("moduleId", productId);
    formData.append("loggedInUserId", loginUserId);
    formData.append("moduleName", "PRODUCTS");
    formData.append("noteDescription", updateNotes.description);
    formData.append("noteTitle", updateNotes.title);
    formData.append("noteId", noteId);
    formData.append("deletedImageNames", deletedImg.toString());

    selectedNoteFiles.forEach(() => {
      formData.append("noteImages", updateNotes.noteImageList);
    });
    const headersResult = getHeaders(true);

    // Check if headers result indicates an error
    if (headersResult.error) {
      navigate("../"); // Redirect to login if token is missing
      return;
    }
    await axios
      .post(URI.updateNote, formData, {
        headers: headersResult,
      })
      .then((response) => {
        if (response.data.status === 200) {
          setShowButtons(false);
          setEditNote(false);
          NotificationManager.success("", response.data.message, 5000);
          setUpdateNotes({
            description: "",
            title: "",
            noteImageList: [],
          });
          setSelectedNoteFiles([]);
          getNotesDetails(productId, selectRecent);
          setDeletedImg("");
        } else {
          setDeletedImg("");
          setSelectedNoteFiles([]);
          NotificationManager.error("", response.data.message, 5000);
        }
      })
      .catch((error) => {
        handleApiError(error, navigate);
      });
  };

  // const handleUpdateIdClick = async (field, value) => {
  //   try {
  //     const formData = new FormData();

  //     const requestBody = {
  //       productId: productId,

  //       [field]: value,
  //       loggedInUserId: loginUserId,
  //       productOwnerId: selectedItem?.userId,
  //     };

  //     formData.append("productDtlsInputs", JSON.stringify(requestBody));
  //     const headersResult = getHeaders(true);

  //     // Check if headers result indicates an error
  //     if (headersResult.error) {
  //       navigate("../"); // Redirect to login if token is missing
  //       return;
  //     }
  //     const response = await axios.post(URI.individualUpdateProduct, formData, {
  //       headers: headersResult,
  //     });
  //     if (response.data.status === 200) {
  //       NotificationManager.success("", response.data.message, 4000);
  //       setProductInfo((prevDetails) => ({
  //         ...prevDetails,
  //         [field]: productInfo[field],
  //       }));

  //       setEditingFields((prevFields) => ({
  //         ...prevFields,
  //         [field]: false,
  //       }));
  //       getProductOverview(productId);
  //     } else {
  //       NotificationManager.error("", response.data.message, 4000);
  //     }
  //   } catch (error) {
  //     handleApiError(error, navigate);
  //   }
  // };

  const handleChangeNoteTitle = (e) => {
    const { name, value } = e.target;
    if (value.length === 1 && value[0] === " ") {
      return;
    }
    if (editNote) {
      setUpdateNotes((prevLeadInfo) => ({
        ...prevLeadInfo,
        [name]: value,
      }));
    } else {
      setAddTitle(value);
    }
  };

  const handleChildDataChange = (dataFromChild) => {
    const deletedImg = dataFromChild.toString("");
    setDeletedImg(deletedImg);
  };

  const handleChangeNoteDescription = (e) => {
    const { name, value } = e.target;
    if (value.length === 1 && value[0] === " ") {
      return;
    }
    if (editNote) {
      setUpdateNotes((prevLeadInfo) => ({
        ...prevLeadInfo,
        [name]: value,
      }));
    } else {
      setNote(value); // Directly set the input value
    }
  };

  // const handleSelectedVendorListChange = (value) => {
  //   setSelectedVendorItem(value); // Update the selected lead value
  // };

  const handleImageChange = (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      if (!allowedFileTypes.includes(selectedFile.type)) {
        setImageMsg(
          "Invalid file type. Please upload an image file (JPG, PNG, JPEG)."
        );
        setTimeout(() => setImageMsg(""), 3000);
        return;
      }
      if (selectedFile.size > maxSizeInBytes) {
        setImageMsg("File size exceeds 2 MB. Please select a smaller file.");
        setTimeout(() => setImageMsg(""), 3000);
        return;
      }
      setSelectedFiles(selectedFile);

      if (existingProduct) {
        setProductUpdateInfo((prevLeadInfo) => ({
          ...prevLeadInfo,
          productImage: selectedFile,
        }));
      } else {
        setFormData((prevLeadInfo) => ({
          ...prevLeadInfo,
          productImage: selectedFile,
        }));
      }
    }
  };

  const handleUploadImage = (e) => {
    const file = e.target.files[0];
    if (file) {
      if (!allowedFileTypes.includes(file.type)) {
        setImageMsg(
          "Invalid file type. Please upload an image file (JPG, PNG, JPEG)."
        );
        setTimeout(() => setImageMsg(""), 5000);
        return;
      }
      if (file.size > maxSizeInBytes) {
        setImageMsg("File size exceeds 2 MB. Please select a smaller file.");
        setTimeout(() => setImageMsg(""), 5000);
        return;
      }
      const imageUrl = URL.createObjectURL(file);
      setImageUrl(imageUrl);
      setSelectedFiles(e.target.files[0]);
    }
    setUploadImg(true);
  };

  const handleDragOver = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setIsDragging(true);
  };

  const handleDragLeave = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setIsDragging(false);
  };

  const handleDrop = (event) => {
    const maxSizeInBytes = 2 * 1024 * 1024; // 2 MB in bytes

    event.preventDefault();
    event.stopPropagation();
    setIsDragging(false);
    const file = event.dataTransfer.files[0];
    if (file) {
      if (!allowedFileTypes?.includes(file.type)) {
        setImageMsg(
          "Invalid file type. Please upload an image file (JPG, PNG, JPEG)."
        );
        setTimeout(() => setImageMsg(""), 3000);
        return;
      }
      if (file.size > maxSizeInBytes) {
        setImageMsg("File size exceeds 2 MB. Please select a smaller file.");
        setTimeout(() => setImageMsg(""), 3000);
        return;
      }
      setSelectedFiles(file);
      fileInputRef.current.files = event.dataTransfer.files;
      if (existingProduct || existingClone) {
        setProductUpdateInfo((prevLeadInfo) => ({
          ...prevLeadInfo,
          productImage: file,
        }));
      } else {
        setProductInfo((prevLeadInfo) => ({
          ...prevLeadInfo,
          productImage: file,
        }));
      }
    }
  };

  const removeImage = async () => {
    setImageLoader(true);
    const requestBody = {
      loggedInUserId: loginUserId,
      productId: productId,
    };
    const headersResult = getHeaders(true);

    // Check if headers result indicates an error
    if (headersResult.error) {
      navigate("../"); // Redirect to login if token is missing
      return;
    }
    await axios
      .post(URI.removeProductImage, requestBody, {
        headers: headersResult,
      })
      .then((response) => {
        if (response.data.status === 200) {
          NotificationManager.success("", response.data.message, 5000);
          setDeleteMsg(response.data.message);
          setTimeout(() => {
            setDeleteMsg("");
            setShowImageModal(false);
            setShowRemoveModal(false);
            getProductOverview(productId);
          }, 3000);
        } else {
          NotificationManager.error("", response.data.message, 5000);
        }
      })
      .catch(async (error) => {
        handleApiError(error, navigate);
      })
      .finally(() => setImageLoader(false));
  };

  const uploadImage = async () => {
    setImageLoader(true);
    const formData = new FormData();
    const requestBody = {
      loggedInUserId: loginUserId,
      // accountSite: "bangalore office",
      productId: productId,
    };
    formData.append("productDtlsInputs", JSON.stringify(requestBody));
    formData.append(
      "productImage",
      uploadImg ? selectedFiles : productInfo.productImageData
    );

    const headersResult = getHeaders(true);

    // Check if headers result indicates an error
    if (headersResult.error) {
      navigate("../"); // Redirect to login if token is missing
      return;
    }

    await axios
      .post(URI.individualUpdateProduct, formData, {
        headers: headersResult,
      })
      .then((response) => {
        if (response.data.status === 200) {
          getProductOverview(productId);
          setShowImageModal(false);
        } else {
          NotificationManager.error("", response.data.message, 5000);
        }
      })
      .catch(async (error) => {
        handleApiError(error, navigate);
      })
      .finally(() => setImageLoader(false));
  };

  const deleteProductDetails = async () => {
    const requestBody = {
      loggedInUserId: loginUserId,
      productId: productId,
    };
    const headersResult = getHeaders(true);

    // Check if headers result indicates an error
    if (headersResult.error) {
      navigate("../"); // Redirect to login if token is missing
      return;
    }
    await axios
      .post(URI.deleteProductDetails, requestBody, {
        headers: headersResult,
      })
      .then((response) => {
        if (response.data.status === 200) {
          setInnerPage("list");
          setDeleteModal(false);
          getAllProduct(currentPage, selectedOption);
          NotificationManager.success("", response.data.message, 5000);
        } else {
          NotificationManager.error("", response.data.message, 5000);
        }
      })
      .catch(async (error) => {
        handleApiError(error, navigate);
      });
  };

  const getNotesDetails = async (productId, orderType) => {
    const requestBody = {
      loggedInUserId: loginUserId,
      moduleId: productId,
      orderType: orderType,
    };

    const headersResult = getHeaders(true);

    // Check if headers result indicates an error
    if (headersResult.error) {
      navigate("../"); // Redirect to login if token is missing
      return;
    }
    await axios
      .post(URI.getNote, requestBody, {
        headers: headersResult,
      })
      .then((response) => {
        if (response.data.status === 200) {
          setNoteInfo(response.data.data.noteInfo);
        } else {
          NotificationManager.error("", response.data.message, 5000);
        }
      })
      .catch(async (error) => {
        handleApiError(error, navigate);
      });
  };

  const deleteNoteDetails = async (productId, noteId) => {
    const formData = new FormData();
    formData.append("moduleId", productId);
    formData.append("loggedInUserId", loginUserId);
    formData.append("moduleName", "PRODUCTS");
    formData.append("noteId", noteId);
    const headersResult = getHeaders(true);

    // Check if headers result indicates an error
    if (headersResult.error) {
      navigate("../"); // Redirect to login if token is missing
      return;
    }
    await axios
      .post(URI.deleteNote, formData, {
        headers: headersResult,
      })
      .then((response) => {
        if (response.data.status === 200) {
          NotificationManager.success("", response.data.message, 3000);
          getNotesDetails(productId, selectRecent);
          setNoteDeleteConfirmation(false);
        } else {
          NotificationManager.error("", response.data.message, 5000);
        }
      })
      .catch((error) => {
        handleApiError(error, navigate);
      });
  };

  const scrollToSection = (sectionId) => {
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: "smooth" });
    }
  };

  useEffect(() => {
    if (fetchProdInfo && innerPage === "existingProduct") {
      getProductOverview(fetchProdInfo?.productId);
      setProductId(fetchProdInfo?.productId);
    }
  }, []);

  const nodataPage = () => {
    return (
      <div className="d-flex flex-column justify-content-center align-items-center  ">
        <img src={noDataProduct} alt="img" height={200} width={200} />
        <h5 className="opacity-75">Manage Product</h5>
        <p className="opacity-75 p-0 m-0">
          Product are legal agreements between a customer and a vector
        </p>
        <p className="opacity-75 p-0 m-0">
          to deliver the requested product(s) at the agreed upon price
        </p>
        <p className="opacity-75 p-0 m-0">within the specified time period.</p>
        <button
          className="createContactButtonSave px-2 mt-4 cursorPointer"
          style={{ width: "15%" }}
          onClick={() => setInnerPage("create")}
        >
          Create a Product
        </button>
      </div>
    );
  };

  useEffect(() => {
    getAllProduct(currentPage, selectedOption);
  }, [currentPage, perPage]);

  useEffect(() => {
    getAllVendorDetails();
    setInnerPage(innerPage || "list");
  }, []);

  useEffect(() => {
    const foundItem =
      userList && userList.find((item) => item.userId === loginUserId);
    if (foundItem) {
      setSelectedItem(foundItem);
      // setSelectedHandlerItem(foundItem);
    }
  }, [loginUserId]);

  const showValues = (itemValue, value) => {
    if (["productActive", "taxable"].includes(value)) {
      return itemValue === true ? "✓" : "✕";
    } else {
      return itemValue !== null ? itemValue : "-";
    }
  };
  const handleProductsView = () => {
    if (innerPage === "list") {
      return (
        <>
          <div>
            {isLoading ? (
              <>
                <Loader />
              </>
            ) : (
              <>
                {filteredProductDetails &&
                filteredProductDetails.length === 0 &&
                selectedOption.value === "all_products" ? (
                  <>{nodataPage()}</>
                ) : (
                  <>
                    <div className="creteContactBar">
                      {selectedCheckboxes.length > 0 ? (
                        <div className="d-flex w-50 align-items-center gap-2">
                          <Select
                            options={massOptions}
                            styles={customCommonStyles("100%")}
                            value={{
                              value: "Action",
                              label: "Action",
                            }}
                            onChange={(option) => {
                              // setDropdownTwo(option);
                              if (option.value === "Mass Update") {
                                setShowSearchFields(productColumn);
                                setSelectedObj({
                                  name: "Commission Rate",
                                  isChecked: false,
                                  value: "commissionRate",
                                });
                              } else if (option.value === "Mass Transfer") {
                                setSelectedItem(userList[0]);
                              }
                              setMassUpdateValue("");
                              setShowModalType(option.value);
                            }}
                            className="w-25"
                            isSearchable={false}
                          />
                        </div>
                      ) : (
                        <>
                          <div className="d-flex w-50 align-items-center gap-2">
                            {/* <img
                              src={filterIcon}
                              height={25}
                              className="contactFilter"
                              alt="img"
                            /> */}
                            <Select
                              options={options}
                              styles={customCommonStyles("195px")}
                              value={selectedOption}
                              onChange={handleChange}
                              isSearchable={false}
                            />
                          </div>
                          <div className="d-flex w-50 justify-content-end gap-2">
                            <div className="btn-group">
                              <button
                                className=" py-2 createContactButtonSave"
                                style={{
                                  background:
                                    "linear-gradient(86.07deg, #407BFF 0%, #12A7FE 100%)",
                                  borderRight: "2px solid #fff",
                                  color: "#fff",
                                }}
                                onClick={handleCreateProduct}
                              >
                                Create Product
                              </button>
                              {/* <button
                                type="button"
                                className="btn btn-sm  dropdown-toggle dropdown-toggle-split"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                                style={{
                                  background:
                                    "linear-gradient(86.07deg, #407BFF 0%, #12A7FE 100%)",
                                  color: "#fff",
                                }}
                              >
                                <span className="visually-hidden">
                                  Toggle Dropdown
                                </span>
                              </button>
                              <ul className="dropdown-menu">
                                <li>
                                  <a className="dropdown-item ">Import Leads</a>
                                </li>
                                <li>
                                  <a className="dropdown-item ">Import Notes</a>
                                </li>
                              </ul> */}
                            </div>
                            {/* <Select
                            options={massOptions}
                            styles={customSelectLocationStyles}
                            value={selectedMassOption}
                            onChange={handleMassOption}
                          /> */}
                          </div>
                        </>
                      )}
                    </div>
                    <div className="totalRecordBarContact">
                      <div className="d-flex w-50">
                        <h6>
                          {" "}
                          {selectedCheckboxes.length > 0
                            ? `${selectedCheckboxes.length} Records Selected`
                            : `Total Records : ${totalItems}`}{" "}
                        </h6>
                      </div>
                      <div className="d-flex w-50 align-items-center justify-content-end gap-2">
                        {/* <Select
                          options={pageOption}
                          styles={customSelectLocationStylesPage}
                          // value={selectPageOption}
                          value={pageOption.find(
                            (option) => option.value === perPage.toString()
                          )}
                          onChange={handlePageDropDowmChange}
                        /> */}

                        {selectedCheckboxes.length === 0 ? (
                          filteredProductDetails.length !== 0 && (
                            <Select
                              options={pageOption}
                              styles={customCommonStyles("100%")}
                              // value={selectPageOption}
                              value={pageOption.find(
                                (option) => option.value === perPage.toString()
                              )}
                              onChange={handlePageDropDowmChange}
                              isSearchable={false}
                            />
                          )
                        ) : (
                          <></>
                        )}

                        {/* <p className="mb-0">· ‹1/10›</p> */}
                        {/* <Pagination
                          currentPage={currentPage}
                          totalPages={totalPages}
                          onPageChange={handlePageChange}
                        /> */}

                        {filteredProductDetails.length !== 0 && (
                          <Pagination
                            currentPage={currentPage}
                            totalPages={totalPages}
                            onPageChange={handlePageChange}
                          />
                        )}
                      </div>
                    </div>
                    <div className="contactMain">
                      <div className="leadListTable">
                        <table className="table leadTable">
                          <thead>
                            <tr>
                              <th scope="col">
                                <input
                                  className="cursorPointer"
                                  type="checkbox"
                                  id="all"
                                  name="all"
                                  value="All"
                                  checked={filteredProductDetails.every(
                                    (selected) =>
                                      selectedCheckboxes.some(
                                        (list) =>
                                          list.moduleId === selected.productId
                                      )
                                  )}
                                  onChange={(e) => {
                                    const allList = filteredProductDetails.map(
                                      (item) => ({
                                        moduleId: item.productId,
                                      })
                                    );
                                    if (e.target.checked) {
                                      const newSelections = allList.filter(
                                        (newItem) =>
                                          !selectedCheckboxes.some(
                                            (existingItem) =>
                                              existingItem.moduleId ===
                                              newItem.moduleId
                                          )
                                      );
                                      setSelectedCheckboxes((prev) => [
                                        ...prev,
                                        ...newSelections,
                                      ]);
                                    } else {
                                      setSelectedCheckboxes(
                                        selectedCheckboxes.filter(
                                          (aItem) =>
                                            !allList.some(
                                              (bItem) =>
                                                aItem.moduleId ===
                                                bItem.moduleId
                                            )
                                        )
                                      );
                                    }
                                  }}
                                  // checked={
                                  //   selectedCheckboxes.length ===
                                  //   filteredProductDetails.length
                                  // }
                                  // onChange={(e) => {
                                  //   if (e.target.checked) {
                                  //     const allList =
                                  //       filteredProductDetails.map((item) => ({
                                  //         moduleId: item.productId,
                                  //       }));
                                  //     setSelectedCheckboxes(allList);
                                  //   } else {
                                  //     setSelectedCheckboxes([]);
                                  //   }
                                  // }}
                                />
                              </th>

                              {selectedHeadings.map(
                                (heading) =>
                                  heading.isChecked && (
                                    <th scope="col" key={heading.name}>
                                      <span className="pe-3">
                                        {heading.name}
                                      </span>
                                      <span className="position-relative">
                                        <img
                                          src={tableFilterIcon}
                                          alt=""
                                          className="cursorPointer"
                                          onClick={(e) => {
                                            e.stopPropagation();
                                            setShowHeadings(false);
                                            setShowSelectedFilter(heading.name);
                                          }}
                                        />
                                        {heading.name ===
                                          showSelectedFilter && (
                                          <span
                                            className="position-absolute rounded-2 border bg-light top-0 mt-4"
                                            style={{ right: "-10%" }}
                                          >
                                            <span className="d-flex flex-column px-1">
                                              {/* Ascending filter */}
                                              <span
                                                className="my-1 px-1 cursorPointer fw-normal text-start filter_type"
                                                onClick={() => {
                                                  if (filterType === "asc") {
                                                    setFilterType("");
                                                    setFilteredProductDetails(
                                                      allProductDetails
                                                    );
                                                  } else {
                                                    setFilterType("asc");
                                                    sortedColumnsData(
                                                      "asc",
                                                      heading.value
                                                    );
                                                  }
                                                }}
                                              >
                                                {filterType === "asc" ? (
                                                  <img
                                                    src={cancel}
                                                    alt="asc"
                                                    className="opacity-75 pe-1"
                                                  />
                                                ) : (
                                                  <img
                                                    src={arrowUp}
                                                    alt="asc"
                                                    className="opacity-75 pe-1"
                                                  />
                                                )}
                                                Asc
                                              </span>
                                              {/* Descending filter */}
                                              <span
                                                className="my-2 px-1 cursorPointer fw-normal text-start filter_type"
                                                onClick={() => {
                                                  if (filterType === "desc") {
                                                    setFilterType("");
                                                    setFilteredProductDetails(
                                                      allProductDetails
                                                    );
                                                  } else {
                                                    setFilterType("desc");
                                                    sortedColumnsData(
                                                      "desc",
                                                      heading.value
                                                    );
                                                  }
                                                }}
                                              >
                                                {filterType === "desc" ? (
                                                  <img
                                                    src={cancel}
                                                    alt="desc"
                                                    className="opacity-75 pe-1"
                                                  />
                                                ) : (
                                                  <img
                                                    src={arrowDown}
                                                    alt="desc"
                                                    className="opacity-75 pe-1"
                                                  />
                                                )}
                                                Desc
                                              </span>
                                              {/* Pin/Unpin column */}
                                              <span
                                                className={`my-1 px-1 fw-normal text-start filter_type ${
                                                  selectedHeadings[0].name ===
                                                    "Product Owner" &&
                                                  heading.name ===
                                                    "Product Owner"
                                                    ? "opacity-50"
                                                    : "cursorPointer"
                                                }`}
                                                onClick={() => {
                                                  if (
                                                    pinnedColumn !==
                                                    "Product Owner"
                                                  ) {
                                                    setPinnedColumn(
                                                      "Product Owner"
                                                    );
                                                    handlePinColumn(
                                                      "Product Owner"
                                                    );
                                                  } else {
                                                    setPinnedColumn(
                                                      heading.name
                                                    );
                                                    handlePinColumn(
                                                      heading.name
                                                    );
                                                  }
                                                }}
                                              >
                                                <img
                                                  src={
                                                    pinnedColumn ===
                                                    heading.name
                                                      ? unPin
                                                      : pin
                                                  }
                                                  alt="pin"
                                                  className="opacity-75 pe-1"
                                                />{" "}
                                                {pinnedColumn === heading.name
                                                  ? "Unpin"
                                                  : "Pin"}{" "}
                                                Column
                                              </span>
                                            </span>
                                          </span>
                                        )}
                                        <img
                                          src={filterLine}
                                          alt=""
                                          className="ps-2"
                                        />
                                      </span>
                                    </th>
                                  )
                              )}

                              <th
                                scope="col"
                                className="text-end cursorPointer position-relative"
                              >
                                <img
                                  src={filterMenu}
                                  alt=""
                                  className={`filter_menu ${
                                    showHeadings ? "bg-light" : ""
                                  }`}
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    setShowSelectedFilter("");
                                    setShowHeadings(true);
                                  }}
                                />
                                {showHeadings && (
                                  <div
                                    className="position-absolute rounded-2  border border-primary bg-light"
                                    style={{
                                      right: "0.2rem",
                                    }}
                                  >
                                    <div className="d-flex flex-column px-2">
                                      <span
                                        className="p-2 cursorPointer fw-normal text-start"
                                        onClick={() => {
                                          setShowModalType("Manage Columns");
                                          setSearchColumn("");
                                          setSavingHeadings(selectedHeadings);
                                        }}
                                      >
                                        Manage Columns
                                      </span>
                                      <span
                                        className="p-2 fw-normal text-start opacity-50"
                                        onClick={() => {
                                          setShowModalType("Reset Column Size");
                                        }}
                                      >
                                        Reset Column Size
                                      </span>
                                    </div>
                                  </div>
                                )}
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {filteredProductDetails &&
                            filteredProductDetails.length > 0 ? (
                              filteredProductDetails.map((item, index) => {
                                return (
                                  <tr style={{ cursor: "pointer" }} key={index}>
                                    <td>
                                      <input
                                        className="cursorPointer"
                                        type="checkbox"
                                        id="vehicle1"
                                        value={item.productId}
                                        // checked={selectedCheckboxes.includes(
                                        //   item.leadId
                                        // )}
                                        checked={selectedCheckboxes.some(
                                          (checkbox) =>
                                            checkbox.moduleId === item.productId
                                        )}
                                        onChange={handleSelectCheckboxChange}
                                      />
                                    </td>

                                    <React.Fragment>
                                      {selectedHeadings.map((obj, i) => {
                                        const value = obj.value;
                                        const itemValue = item[value];

                                        return obj.isChecked ? (
                                          value === "transHash" ? (
                                            <td
                                              style={{ wordBreak: "break-all" }}
                                              className="overflow_text_hash"
                                              key={i}
                                            >
                                              <a
                                                rel="noreferrer"
                                                href={
                                                  EXPLORER_URL +
                                                  "about-tx-hash/" +
                                                  item.transHash
                                                }
                                                className="anchorLinkHash textColor"
                                                data-title={item.transHash}
                                                target="_blank"
                                              >
                                                {item.transHash
                                                  ? item.transHash.slice(
                                                      0,
                                                      10
                                                    ) + "...."
                                                  : "-"}
                                              </a>
                                            </td>
                                          ) : (
                                            <td
                                              key={i}
                                              onClick={() => {
                                                setFetchProdInfo(item);
                                                if (value === "productName") {
                                                  setInnerPage(
                                                    "existingProduct"
                                                  );
                                                  getProductOverview(
                                                    item.productId
                                                  );
                                                  setProductId(item.productId);
                                                  setSelectedProductIndex(
                                                    allProductDetails.findIndex(
                                                      (lead) =>
                                                        lead.productId ===
                                                        item.productId
                                                    )
                                                  );
                                                }
                                              }}
                                              className={` ${
                                                value === "productName"
                                                  ? "productName cursorPointer"
                                                  : ""
                                              }`}
                                            >
                                              {showValues(itemValue, value)}

                                              {/* {itemValue !== null ? itemValue : "-"} */}
                                            </td>
                                          )
                                        ) : null;
                                      })}
                                    </React.Fragment>
                                    <td></td>
                                  </tr>
                                );
                              })
                            ) : (
                              <tr>
                                <td
                                  colSpan={selectedHeadings.length + 2}
                                  style={{ textAlign: "center" }}
                                >
                                  No Data
                                </td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </>
                )}
              </>
            )}
          </div>
        </>
      );
    } else if (["create", "clone"].includes(innerPage)) {
      return (
        <>
          <div className="createContactButtonBar">
            <div className="d-flex align-items-center w-50 gap-2">
              <img src={groupIcon} height={25} alt="img" />
              <div className="d-flex flex-column">
                <p className="mb-0 first_letter_uppercase">
                  {existingProduct ? "Edit" : innerPage} Product
                </p>
                {/* <p
                  className="mb-0"
                  style={{
                    color: "#529CF3",
                    fontSize: "12px",
                    width: "fit-content",
                  }}
                >
                  Edit Layout
                </p> */}
              </div>
            </div>
            <div className="d-flex w-50 gap-2 justify-content-end">
              <button
                className="createLeadButton"
                onClick={() => {
                  setInnerPage("list");
                  // setProductPageView("productRecord")
                  setUpdateButtonLabel("Save");

                  resetForm();
                  setSelectedFiles(null);
                  fileInputRef.current.value = "";
                  if (innerPage === "clone" && !existingProduct) {
                    setInnerPage("existingProduct");
                  }
                }}
              >
                Cancel
              </button>

              {existingProduct ? (
                <></>
              ) : (
                <button
                  className="createLeadButton"
                  onClick={() => addNewProduct("SAVE AND NEW")}
                  style={{ width: "175px" }}
                  disabled={saveAndNewSubmit}
                >
                  {saveAndNewSubmit ? (
                    <Lottie
                      options={defaultOptions("blue")}
                      height="24px"
                      width="36px"
                    />
                  ) : (
                    "Save and New"
                  )}
                </button>
              )}

              <button
                className="save-button"
                onClick={() => {
                  if (existingProduct) {
                    updateProduct("UPDATE");
                  } else if (existingClone) {
                    addNewProduct("ADD");
                  } else {
                    addNewProduct("SAVE");
                  }
                }}
                disabled={saveSubmit}
              >
                {saveSubmit ? (
                  <Lottie
                    options={defaultOptions("white")}
                    height="30px"
                    width="36px"
                  />
                ) : (
                  updateButtonLabel
                )}
              </button>
            </div>
          </div>

          <div className="contactFields">
            <h6
              className="py-2"
              style={{
                borderBottom: "2px solid #316AFF",
                fontSize: "18px",
                color: "#515151",
                marginBottom: "0px",
              }}
            >
              Product Information
            </h6>
            <div className="inputFieldDiv">
              <div
                className={`form-group ${isDragging ? "drag-over" : ""}`}
                onDragOver={handleDragOver}
                onDragLeave={handleDragLeave}
                onDrop={handleDrop}
              >
                <p className="mb-0">Upload Image</p>
                <input
                  type="file"
                  id="productImage"
                  name="productImage"
                  accept=".png, .jpg, .jpeg, .gif"
                  style={{ display: "none" }}
                  ref={fileInputRef}
                  className="file-control"
                  onChange={handleImageChange}
                />
                <label htmlFor="productImage" className="label-file">
                  <span>
                    {selectedFiles
                      ? selectedFiles.name.length > 30
                        ? `${selectedFiles.name.substring(0, 30)}...`
                        : selectedFiles.name
                      : "Drag and Drop files (JPG,PNG)"}
                  </span>
                </label>
                {imageMsg && <p className="mb-0 small erro_msg">{imageMsg}</p>}
              </div>
            </div>
            <div className="inputFieldDiv">
              <div className="inputFieldDivRight position position-relative">
                <p className="mb-0">
                  Product Owner <span className="text-danger">*</span>
                </p>
                <div
                  className="d-flex input-with-clear "
                  // onClick={handleOwnerInputClick}
                >
                  <input
                    type="text"
                    //   placeholder="Vendor Owner"
                    className="leadInputField cursorPointer  "
                    value={selectedItem?.name}
                    // value={
                    //   existingProduct
                    //     ? selectedOwnerItem?.name
                    //     : selectedItem?.name
                    // }
                    onClick={handleOwnerInputClick}
                    readOnly
                  />
                  {!existingProduct && (
                    <span
                      className="clear-button"
                      onClick={(e) => {
                        handleOwnerInputClick(e);
                        setListVisible(listVisible ? false : true);
                      }}
                    >
                      <img
                        src={listVisible ? down : up}
                        alt="img"
                        height={20}
                        width={20}
                      />
                    </span>
                  )}
                  <span
                    className="cursorPointer pt-2 ps-1"
                    onClick={() => {
                      if (existingProduct) {
                        setShowModalType("changeOwner");
                        setListVisible(false);
                      } else {
                        setIsLeadOwnerModal(!isLeadOwnerModal);
                      }
                    }}
                    // onClick={() => setIsLeadOwnerModal(!isLeadOwnerModal)}
                  >
                    <img src={userSearch} alt="img" width={20} height={20} />
                  </span>
                </div>

                {existingProduct ? (
                  <></>
                ) : (
                  <>
                    {listVisible && (
                      <ul
                        className="data-list list-group  lead_ownerList_container position-absolute"
                        // style={{ width: "0px", top: "0px" }}
                        style={{ top: "6rem" }}
                      >
                        {userList.map((item, index) => (
                          <li
                            key={index}
                            className="cursorPointer list-group-item"
                            onClick={() => handleUserItemClick(item)}
                          >
                            <div className="d-flex flex-column">
                              <span> {item.name} </span>
                              <span> {item.emailId} </span>
                            </div>
                          </li>
                        ))}
                      </ul>
                    )}
                  </>
                )}
              </div>
              <div className="inputFieldDivLeft">
                <p className="mb-0">
                  Product Name <span className="text-danger">*</span>
                </p>
                <input
                  type="text"
                  className={
                    productNameErrorMsg
                      ? "mandatoryField"
                      : "insertCommonInputField"
                  }
                  style={{
                    width: "60%",
                    border: "1px solid #B9B9B94D",
                    height: "40px",
                    outline: "none",
                    borderRadius: "10px",
                  }}
                  value={
                    existingProduct || existingClone
                      ? productUpdateInfo.productName
                      : formData.productName
                  }
                  // value={formData.productName}
                  name="productName"
                  onChange={handleInputFieldChange}
                />
                <span className="small erro_msg">{productNameErrorMsg}</span>
              </div>
            </div>
            <div className="inputFieldDiv">
              <div className="inputFieldDivRight">
                <p className="mb-0">
                  Product Code <span className="text-danger">*</span>
                </p>
                <input
                  type="text"
                  className={
                    productCodeErrorMsg
                      ? "mandatoryField"
                      : "insertCommonInputField"
                  }
                  style={{
                    width: "60%",
                    border: "1px solid #B9B9B94D",
                    height: "40px",
                    outline: "none",
                    borderRadius: "10px",
                  }}
                  value={
                    existingProduct || existingClone
                      ? productUpdateInfo.productCode
                      : formData.productCode
                  }
                  // value={formData.productCode}
                  name="productCode"
                  onChange={handleInputFieldChange}
                  onKeyDown={handleKeyDown}
                />
                <span className="small erro_msg">{productCodeErrorMsg}</span>
              </div>
              {/* <div className="inputFieldDivRight">
                <p className="mb-0">Vendor Name</p>
                <input
                  type="text"
                  className="insertCommonInputField"
                  style={{
                    width: "60%",
                    border: "1px solid #B9B9B94D",
                    height: "40px",
                    outline: "none",
                    borderRadius: "10px",
                  }}
                  value={
                    existingProduct
                      ? productUpdateInfo.vendorName
                      : formData.vendorName
                  }
                  name="vendorName"
                  onChange={handleInputFieldChange}
                />
              </div> */}

              <div className="inputFieldDivRight  ">
                <p className="mb-1">
                  Vendor Name
                  <span className="text-danger">*</span>
                </p>
                <div
                  className="d-flex input-with-clear  "
                  // onClick={handleVendorClick}
                >
                  <input
                    type="text"
                    //   placeholder="Vendor Owner"
                    value={selectedVendorItem?.vendorName}
                    onClick={handleVendorClick}
                    className={"leadInputField cursorPointer"}
                    style={{
                      borderColor: vendorNameErrorMsg ? "red" : "",
                    }}
                    readOnly
                  />
                  <span
                    className="clear-button"
                    onClick={(e) => {
                      setVendorListVisible(!vendorListVisible);
                      handleVendorClick(e);
                    }}
                  >
                    <img
                      src={vendorListVisible ? down : up}
                      alt="img"
                      height={20}
                      width={20}
                    />
                  </span>

                  <span
                    className="cursorPointer pt-2 ps-1"
                    onClick={() => setIsVendorListModal(!isVendorListModal)}
                  >
                    <img src={vendorsIcon} alt="img" width={23} height={22} />
                  </span>
                </div>
                {vendorListVisible && (
                  <ul
                    className="data-list list-group  lead_ownerList_container position-absolute"
                    style={{ top: "450px", minHeight: "max-content" }}
                  >
                    {vendorList.map((item, index) => (
                      <li
                        key={index}
                        className="cursorPointer list-group-item"
                        onClick={() => {
                          handleVendorItemClick(item);
                        }}
                      >
                        <div className="d-flex ">
                          <span> {item.vendorName} </span>
                        </div>
                      </li>
                    ))}
                  </ul>
                )}
                {vendorNameErrorMsg && (
                  <span className="small erro_msg">{vendorNameErrorMsg}</span>
                )}
              </div>
            </div>
            <div className="inputFieldDiv">
              <div
                className="inputFieldDivLeft"
                style={{ display: "flex", flexDirection: "row" }}
              >
                <p className="mb-0 me-4">Product Active</p>
                <input
                  type="checkbox"
                  style={{
                    width: "3.5%",
                    border: "1px solid #B9B9B94D",
                    height: "25px",
                    outline: "none",
                    borderRadius: "10px",
                  }}
                  // value={existingProduct ? updateData.title : formData.title}
                  checked={formData.productActive}
                  name="productActive"
                  onChange={handleInputCheckbox}
                />
              </div>
              <div className="inputFieldDivRight">
                <p className="mb-0">Manufacturer</p>
                <Select
                  // value={
                  //   existingProduct
                  //     ? manufacturerOptions.find(

                  //           productUpdateInfo.manufacturer
                  //       )
                  //     : manufacturerOptions.find(
                  //         formData.manufacturer.
                  //       )
                  // }

                  value={
                    existingProduct || existingClone
                      ? productUpdateInfo.manufacturer
                      : formData.manufacturer
                  }
                  menuPlacement="auto"
                  onChange={(selectedOption) =>
                    handleChangeSelectDropDown(selectedOption, "manufacturer")
                  }
                  options={manufacturerOptions}
                  styles={createLeadStyles}
                  isSearchable={false}
                  // value={formData.manufacturer}
                />
              </div>
            </div>
            <div className="inputFieldDiv">
              <div className="inputFieldDivLeft">
                <p className="mb-0">Product Category</p>
                <Select
                  // value={
                  //   existingProduct || existingClone
                  //     ? productCategoryOptions.find(
                  //         (option) =>
                  //           option.value ===
                  //           productUpdateInfo.productCategory?.value
                  //       )
                  //     : productCategoryOptions.find(
                  //         (option) =>
                  //           option.value === formData.productCategory?.value
                  //       )
                  // }

                  value={
                    existingProduct || existingClone
                      ? productUpdateInfo.productCategory
                      : formData.productCategory
                  }
                  menuPlacement="auto"
                  onChange={(selectedOption) =>
                    handleChangeSelectDropDown(
                      selectedOption,
                      "productCategory"
                    )
                  }
                  options={productCategoryOptions}
                  styles={createLeadStyles}
                  isSearchable={false}
                  // value={formData.productCategory}
                />
              </div>
              <div className="inputFieldDivRight">
                <p className="mb-0">Sales Start Date</p>
                <input
                  type="date"
                  className="insertCommonInputField"
                  style={{
                    width: "60%",
                    border: "1px solid #B9B9B94D",
                    height: "40px",
                    outline: "none",
                    borderRadius: "10px",
                  }}
                  min={
                    new Date(
                      new Date().getFullYear() - 24,
                      new Date().getMonth(),
                      new Date().getDate()
                    )
                      .toISOString()
                      .split("T")[0]
                  }
                  max={
                    new Date(
                      new Date().getFullYear() + 26,
                      new Date().getMonth(),
                      new Date().getDate()
                    )
                      .toISOString()
                      .split("T")[0]
                  }
                  value={
                    existingProduct || existingClone
                      ? convertDateFormat(productUpdateInfo.saleStartDate)
                      : convertDateFormat(formData.saleStartDate)
                  }
                  // value={formData.saleStartDate}
                  name="saleStartDate"
                  onChange={handleInputFieldChange}
                  onKeyDown={(e) => e.preventDefault()}
                />
                {/* {!validEmail && mail != "" && <p style={{ color: 'red', margin: '5px 0' }}>Please enter a valid saleStartDate address.</p>} */}
              </div>
            </div>
            <div className="inputFieldDiv">
              <div className="inputFieldDivLeft">
                <p className="mb-0">Sales End Date</p>
                <input
                  type="date"
                  className="insertCommonInputField"
                  style={{
                    width: "60%",
                    border: "1px solid #B9B9B94D",
                    height: "40px",
                    outline: "none",
                    borderRadius: "10px",
                  }}
                  value={
                    existingProduct || existingClone
                      ? convertDateFormat(productUpdateInfo.saleEndDate)
                      : convertDateFormat(formData.saleEndDate)
                  }
                  min={
                    new Date(
                      new Date().getFullYear() - 24,
                      new Date().getMonth(),
                      new Date().getDate()
                    )
                      .toISOString()
                      .split("T")[0]
                  }
                  max={
                    new Date(
                      new Date().getFullYear() + 26,
                      new Date().getMonth(),
                      new Date().getDate()
                    )
                      .toISOString()
                      .split("T")[0]
                  }
                  // value={formData.saleEndDate}
                  name="saleEndDate"
                  onChange={handleInputFieldChange}
                  onKeyDown={(e) => e.preventDefault()}
                />
              </div>
              <div className="inputFieldDivRight">
                <p className="mb-0">Support Start Date</p>
                <input
                  type="date"
                  className="insertCommonInputField"
                  style={{
                    width: "60%",
                    border: "1px solid #B9B9B94D",
                    height: "40px",
                    outline: "none",
                    borderRadius: "10px",
                  }}
                  value={
                    existingProduct || existingClone
                      ? convertDateFormat(productUpdateInfo.supportStartDate)
                      : convertDateFormat(formData.supportStartDate)
                  }
                  min={
                    new Date(
                      new Date().getFullYear() - 24,
                      new Date().getMonth(),
                      new Date().getDate()
                    )
                      .toISOString()
                      .split("T")[0]
                  }
                  max={
                    new Date(
                      new Date().getFullYear() + 26,
                      new Date().getMonth(),
                      new Date().getDate()
                    )
                      .toISOString()
                      .split("T")[0]
                  }
                  // value={formData.supportStartDate}
                  name="supportStartDate"
                  onChange={handleInputFieldChange}
                  onKeyDown={(e) => e.preventDefault()}
                />
              </div>
            </div>
            <div className="inputFieldDiv">
              <div className="inputFieldDivLeft">
                <p className="mb-0">Support End Date</p>
                <input
                  type="date"
                  className="insertCommonInputField"
                  style={{
                    width: "60%",
                    border: "1px solid #B9B9B94D",
                    height: "40px",
                    outline: "none",
                    borderRadius: "10px",
                  }}
                  value={
                    existingProduct || existingClone
                      ? convertDateFormat(productUpdateInfo.supportEndDate)
                      : convertDateFormat(formData.supportEndDate)
                  }
                  min={
                    new Date(
                      new Date().getFullYear() - 24,
                      new Date().getMonth(),
                      new Date().getDate()
                    )
                      .toISOString()
                      .split("T")[0]
                  }
                  max={
                    new Date(
                      new Date().getFullYear() + 26,
                      new Date().getMonth(),
                      new Date().getDate()
                    )
                      .toISOString()
                      .split("T")[0]
                  }
                  // value={formData.supportEndDate}
                  name="supportEndDate"
                  onChange={handleInputFieldChange}
                  onKeyDown={(e) => e.preventDefault()}
                />
                {/* {!secEmailError && secondaryEmailId != "" && <p style={{ color: 'red', margin: '5px 0' }}>Please enter a valid email address.</p>} */}
              </div>
            </div>

            <h6
              className="py-2 mt-4"
              style={{
                borderBottom: "2px solid #316AFF",
                fontSize: "18px",
                color: "#515151",
                marginBottom: "0px",
              }}
            >
              Price Information
            </h6>
            <div className="inputFieldDiv position-relative">
              <div className="inputFieldDivLeft">
                <p className="mb-0">Unit Price</p>
                <input
                  type="text"
                  className="insertCommonInputField"
                  style={{
                    width: "60%",
                    border: "1px solid #B9B9B94D",
                    height: "40px",
                    outline: "none",
                    borderRadius: "10px",
                    padding: "12px 15px",
                  }}
                  value={
                    existingProduct || existingClone
                      ? productUpdateInfo.unitPrice
                      : formData.unitPrice
                  }
                  // value={formData.unitPrice}
                  name="unitPrice"
                  onChange={handleInputFieldChange}
                  onBlur={() => handleBlur("unitPrice")}
                  onKeyPress={handleKeyPress}
                />
                <span
                  className="clear-button position-absolute px-1"
                  style={{ left: "0px", bottom: "9px" }}
                >
                  ₹
                </span>
                {showTooltipExpectedRevenue && (
                  <div style={{ ...tooltipStyles, bottom: "30px" }}>
                    {"= " + tooltipExpectedRevenue}
                  </div>
                )}
                <p
                  className="small erro_msg position-absolute"
                  style={{ left: "0px", bottom: "-35px" }}
                >
                  {unitPriceMsg && unitPriceMsg}
                </p>
              </div>
              <div className="inputFieldDivRight position-relative">
                <p className="mb-0">Commission Rate</p>
                <input
                  type="text"
                  className="insertCommonInputField"
                  style={{
                    width: "60%",
                    border: "1px solid #B9B9B94D",
                    height: "40px",
                    outline: "none",
                    borderRadius: "10px",
                  }}
                  value={
                    existingProduct || existingClone
                      ? productUpdateInfo.commissionRate
                      : formData.commissionRate
                  }
                  name="commissionRate"
                  onBlur={() => handleBlur("commissionRate")}
                  onChange={handleInputFieldChange}
                  onKeyPress={handleKeyPress}
                />
                {/* <span
                  className="position-absolute"
                  style={{ top: "1.4rem", right: "70%" }}
                >
                  <CalculatorTooltip />
                </span> */}
                {showTooltipSalesCommission && (
                  <div style={{ ...tooltipStyles, bottom: "30px" }}>
                    {"= " + tooltipSalesCommission}
                  </div>
                )}
              </div>
            </div>
            <div className="inputFieldDiv">
              <div className="inputFieldDivLeft">
                <p className="mb-0">Tax</p>
                <Select
                  value={
                    existingProduct || existingClone
                      ? productUpdateInfo.tax
                      : formData.tax
                  }
                  // value={formData.tax}
                  menuPlacement="auto"
                  onChange={(selectedOption) =>
                    handleChangeSelectDropDown(selectedOption, "tax")
                  }
                  options={taxOptions}
                  styles={createLeadStyles}
                  isSearchable={false}
                />
                {/* <input
                  type="text"
                  className="insertCommonInputField"
                  style={{
                    width: "60%",
                    border: "1px solid #B9B9B94D",
                    height: "40px",
                    outline: "none",
                    borderRadius: "10px",
                  }}
                  // value={
                  //   existingProduct || existingClone
                  //     ? productInfo.tax.value
                  //     : formData.tax.value
                  // }
                  defaultValue="GST - 18.00%"
                  name="tax"
                  readOnly
                  // onChange={handleInputFieldChange}
                /> */}
              </div>
            </div>
            <div className="inputFieldDiv">
              <div className="inputFieldDivLeft d-flex flex-row">
                <p className="mb-0 me-4">Taxable</p>
                <input
                  type="checkbox"
                  style={{
                    width: "3.5%",
                    border: "1px solid #B9B9B94D",
                    height: "25px",
                    outline: "none",
                    borderRadius: "10px",
                  }}
                  checked={formData.taxable}
                  // value={
                  //   existingProduct
                  //     ? productUpdateInfo.taxable
                  //     : formData.taxable
                  // }
                  name="taxable"
                  onChange={handleInputCheckbox}
                />
              </div>
            </div>

            <h6
              className="py-2 mt-4 mb-0"
              style={{
                borderBottom: "2px solid #316AFF",
                fontSize: "18px",
                color: "#515151",
              }}
            >
              Stock Information
            </h6>
            <div className="inputFieldDiv">
              <div className="inputFieldDivLeft">
                <p className="mb-0">Usage Unit</p>
                <Select
                  value={
                    existingProduct || existingClone
                      ? productUpdateInfo.usageUnit
                      : formData.usageUnit
                  }
                  menuPlacement="auto"
                  onChange={(selectedOption) =>
                    handleChangeSelectDropDown(selectedOption, "usageUnit")
                  }
                  options={usageUnitOptions}
                  styles={createLeadStyles}
                  isSearchable={false}
                />
              </div>
              <div className="inputFieldDivRight">
                <p className="mb-0">Qty Ordered</p>
                <input
                  type="text"
                  className="insertCommonInputField"
                  style={{
                    width: "60%",
                    border: quantityOrderedMsg
                      ? "1px solid red"
                      : "1px solid #B9B9B94D",
                    height: "40px",
                    outline: "none",
                    borderRadius: "10px",
                  }}
                  value={
                    existingProduct || existingClone
                      ? productUpdateInfo.qtyOrdered
                      : formData.qtyOrdered
                  }
                  name="qtyOrdered"
                  onChange={handleInputFieldChange}
                  onKeyDown={(e) => {
                    // Allow only numeric input
                    if (
                      !/\d/.test(e.key) &&
                      e.key !== "Backspace" &&
                      e.key !== "Delete" &&
                      e.key !== "ArrowLeft" &&
                      e.key !== "ArrowRight"
                    ) {
                      e.preventDefault();
                    }
                  }}
                />
                <span className="small erro_msg">{quantityOrderedMsg}</span>
              </div>
            </div>
            <div className="inputFieldDiv">
              <div className="inputFieldDivRight">
                <p className="mb-0">Quantity in Stock</p>
                <input
                  type="text"
                  className="insertCommonInputField"
                  style={{
                    width: "60%",
                    // border: "1px solid #B9B9B94D",
                    border: quantityInStockMsg
                      ? "1px solid red"
                      : "1px solid #B9B9B94D",
                    height: "40px",
                    outline: "none",
                    borderRadius: "10px",
                  }}
                  value={
                    existingProduct || existingClone
                      ? productUpdateInfo.quantityInStock
                      : formData.quantityInStock
                  }
                  // value={formData.quantityInStock}
                  name="quantityInStock"
                  onChange={handleInputFieldChange}
                  onKeyDown={(e) => {
                    // Allow only numeric input
                    if (
                      !/\d/.test(e.key) &&
                      e.key !== "Backspace" &&
                      e.key !== "Delete" &&
                      e.key !== "ArrowLeft" &&
                      e.key !== "ArrowRight"
                    ) {
                      e.preventDefault();
                    }
                  }}
                />
                <span className="small erro_msg">{quantityInStockMsg}</span>
              </div>
              <div className="inputFieldDivRight">
                <p className="mb-0">Reorder Level</p>
                <input
                  type="text"
                  className="insertCommonInputField"
                  style={{
                    width: "60%",
                    border: recordLevelMsg
                      ? "1px solid red"
                      : "1px solid #B9B9B94D",
                    height: "40px",
                    outline: "none",
                    borderRadius: "10px",
                  }}
                  value={
                    existingProduct || existingClone
                      ? productUpdateInfo.reorderLevel
                      : formData.reorderLevel
                  }
                  name="reorderLevel"
                  onChange={handleInputFieldChange}
                  onKeyDown={(e) => {
                    // Allow only numeric input
                    if (
                      !/\d/.test(e.key) &&
                      e.key !== "Backspace" &&
                      e.key !== "Delete" &&
                      e.key !== "ArrowLeft" &&
                      e.key !== "ArrowRight"
                    ) {
                      e.preventDefault();
                    }
                  }}
                />
                <span className="small erro_msg">{recordLevelMsg}</span>
              </div>
            </div>
            <div className="inputFieldDiv">
              {/* <div className="inputFieldDivRight">
                <p className="mb-0">Handler</p>
                <input
                  type="text"
                  className="insertCommonInputField"
                  style={{
                    width: "60%",
                    border: "1px solid #B9B9B94D",
                    height: "40px",
                    outline: "none",
                    borderRadius: "10px",
                  }}
                  value={
                    existingProduct || existingClone
                      ? productUpdateInfo.handler
                      : formData.handler
                  }
                  name="handler"
                  onChange={handleInputFieldChange}
                  onKeyDown={handleKeyDown}
                />
              </div> */}
              <div className="inputFieldDivRight position position-relative">
                <p className="mb-0">Handler</p>
                <div
                  className="d-flex input-with-clear "
                  // onClick={handleOwnerInputClick}
                >
                  <input
                    type="text"
                    //   placeholder="Vendor Owner"
                    className="leadInputField cursorPointer  "
                    value={selectedHandlerItem?.name}
                    // value={
                    //   existingProduct
                    //     ? selectedOwnerItem?.name
                    //     : selectedItem?.name
                    // }
                    onClick={handleHandlerInputClick}
                    readOnly
                  />

                  <span
                    className="clear-button"
                    onClick={() => setListVisibleHandler(!listVisibleHandler)}
                  >
                    <img
                      src={listVisibleHandler ? down : up}
                      alt="img"
                      height={20}
                      width={20}
                    />
                  </span>
                  <span
                    className="cursorPointer pt-2 ps-1"
                    onClick={() => {
                      setIsLeadOwnerModal(!isLeadOwnerModal);
                      setIsForHandler(true);
                    }}
                  >
                    <img src={userSearch} alt="img" width={20} height={20} />
                  </span>
                </div>

                {listVisibleHandler && (
                  <ul
                    className="data-list list-group  lead_ownerList_container position-absolute"
                    // style={{ width: "0px", top: "0px" }}
                    style={{ top: "6rem" }}
                  >
                    {userList.map((item, index) => (
                      <li
                        key={index}
                        className="cursorPointer list-group-item"
                        onClick={() => handleHandlerItemClick(item)}
                      >
                        <div className="d-flex flex-column">
                          <span> {item.name} </span>
                          <span> {item.emailId} </span>
                        </div>
                      </li>
                    ))}
                  </ul>
                )}
              </div>
              <div className="inputFieldDivRight">
                <p className="mb-0">Quantity in Demand</p>
                <input
                  type="text"
                  className="insertCommonInputField"
                  style={{
                    width: "60%",
                    border: quantityDemandMsg
                      ? "1px solid red"
                      : "1px solid #B9B9B94D",
                    height: "40px",
                    outline: "none",
                    borderRadius: "10px",
                  }}
                  value={
                    existingProduct || existingClone
                      ? productUpdateInfo.quantityInDemand
                      : formData.quantityInDemand
                  }
                  // value={formData.quantityDemand}
                  name="quantityInDemand"
                  onChange={handleInputFieldChange}
                  onKeyDown={(e) => {
                    // Allow only numeric input
                    if (
                      !/\d/.test(e.key) &&
                      e.key !== "Backspace" &&
                      e.key !== "Delete" &&
                      e.key !== "ArrowLeft" &&
                      e.key !== "ArrowRight"
                    ) {
                      e.preventDefault();
                    }
                  }}
                />
                <span className="small erro_msg">{quantityDemandMsg}</span>
              </div>
            </div>
            <h6
              className="py-2 mt-4"
              style={{
                borderBottom: "2px solid #316AFF",
                fontSize: "18px",
                color: "#515151",
                marginBottom: "0px",
              }}
            >
              Description Information
            </h6>
            <div className="inputFieldDiv" style={{ display: "block" }}>
              <div
                className="inputFieldDivLeft"
                style={{ display: "block", width: "100%" }}
              >
                <p>Description</p>
                <textarea
                  rows="4"
                  cols="50"
                  maxLength="2000"
                  style={{
                    width: "100%",
                    border: "1px solid #B9B9B94D",
                    height: "100px",
                    outline: "none",
                    borderRadius: "10px",
                    backgroundColor: "transparent",
                    padding: "8px 12px",
                  }}
                  value={
                    existingProduct || existingClone
                      ? productUpdateInfo.description
                      : formData.description
                  }
                  // value={formData.description}
                  name="description"
                  onChange={handleInputFieldChange}
                  onKeyDown={handleKeyDown}
                />
              </div>
            </div>
          </div>
          {/* {isLeadOwnerModal ? (
            <LeadOwnerModal
              isLeadOwnerModal={isLeadOwnerModal}
              setIsLeadOwnerModal={setIsLeadOwnerModal}
              onSelectedLeadChange={handleSelectedLeadChange}
            />
          ) : null} */}

          {/* {isVendorListModal ? (
            <VendorListModal
              isVendorListModal={isVendorListModal}
              setIsVendorListModal={setIsVendorListModal}
              onSelectedVendorChange={handleSelectedVendorListChange}
              defaultUser={selectedVendorItem}
              vendorDetails={vendorList}
            />
          ) : null} */}

          <Modal
            show={isVendorListModal}
            className="d-flex justify-content-center align-items-center"
            aria-labelledby="example-custom-modal-styling-title"
            size="lg"
            id="commonLeadModal"
          >
            <Modal.Body className="ModalBody  overflow-auto">
              <div
                className="modalUS row justify-content-center"
                style={{ margin: "0", gap: "10px", paddingBottom: "10px" }}
              >
                <div className="header_Container d-flex justify-content-between">
                  <h4 className="commonTextColor">Choose Vendor</h4>
                  <div className="d-flex justify-content-start">
                    <div className="inputContainer d-flex justify-content-start">
                      <input
                        type="text"
                        className="inputBox px-5 mb-1 w-75 py-1"
                        style={{ outline: "none" }}
                        placeholder="Search Users"
                        value={searchVendor}
                        onChange={handleSearchVendor}
                      />
                      <img
                        src={SearchIcon}
                        alt="search"
                        className="searchIcon"
                        style={{ width: "20px" }}
                      />
                    </div>
                  </div>
                </div>

                <div className="Owner_table_container border border-dark rounded-2 h-25">
                  <div className="table-wrapper">
                    <table className="userdata-table">
                      <thead>
                        <tr>
                          <th>Vendor Name</th>
                          <th>Email</th>

                          <th>Phone</th>
                          <th>Website</th>
                          <th> Owner</th>
                        </tr>
                      </thead>
                      <tbody className="table-body tableUserData">
                        {filteredVendors?.map((item, index) => (
                          <tr
                            key={index}
                            onClick={() => {
                              setSelectedVendorItem(item);
                            }}
                          >
                            <td className="d-flex gap-2">
                              <img
                                src={logoConvert}
                                alt="img"
                                height={20}
                                width={20}
                              />

                              {item.vendorName}
                            </td>
                            <td>{item.emailId}</td>
                            <td>{item.phone}</td>
                            <td>{item.website}</td>
                            <td>{item.vendorId}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
                <div className="d-flex w-100 align-items-center justify-content-end gap-2">
                  {filteredVendors.length > 0 && (
                    <>
                      <Select
                        options={pageOption}
                        value={pageOption.find(
                          (option) => option.value === perPageVendor.toString()
                        )}
                        onChange={handlePageDropDownChangeVendor}
                        isSearchable={false}
                        styles={customCommonStyles("225px")}
                      />
                      <Pagination
                        currentPage={currentPageVendor}
                        totalPages={totalPagesVendor}
                        onPageChange={handlepageChangeVendor}
                      />
                    </>
                  )}
                </div>
                <div className="d-flex pb-2 pt-3 justify-content-between">
                  <div className="selectedUser d-flex gap-1">
                    <span>Selected User</span>
                    <img src={logoConvert} alt="img" height={20} width={20} />
                    <span>{selectedVendorItem?.vendorName}</span>
                  </div>
                  <div className="d-flex gap-2">
                    <button
                      className="btn btn-outline-secondary border border-primary btn-sm px-3 py-2 text-dark"
                      onClick={() => {
                        setIsVendorListModal(false);
                        setSelectedVendorItem({});
                      }}
                    >
                      Cancel
                    </button>
                    <button
                      className="btn btnColor btn-sm px-3 text-white"
                      onClick={() => {
                        setIsVendorListModal(false);
                      }}
                    >
                      Done
                    </button>
                  </div>
                </div>
              </div>
            </Modal.Body>
          </Modal>
        </>
      );
    } else if (innerPage === "existingProduct") {
      return (
        <>
          {" "}
          <div className="indiv_main_container ">
            {/* <p className="text-danger fw-bolder">Work in progress... </p> */}
            <header className="commonContainer_bg w-100 mb-2">
              <div className="d-flex justify-content-between px-3 pt-2">
                <span
                  className="cursorPointer"
                  onClick={() => {
                    getAllProduct(1, selectedOption);
                    setInnerPage("list");
                    setFetchProdInfo(null);
                    const resetFields = Object.fromEntries(
                      Object.keys(editingFields).map((key) => [key, false])
                    );
                    setEditingFields({
                      ...resetFields,
                    });
                    setShowType("");
                  }}
                >
                  {leftArrow}
                </span>
                <span>
                  <span
                    className={`me-2 ${
                      selectedProductIndex === 0
                        ? "opacity-50"
                        : "cursorPointer"
                    }`}
                    onClick={() => {
                      const leadIndex = allProductDetails.findIndex(
                        (lead) => lead.productId === productId
                      );
                      if (leadIndex !== 0) {
                        setSelectedProductIndex(leadIndex - 1);
                        setProductId(
                          allProductDetails[leadIndex - 1].productId
                        );
                        getProductOverview(
                          allProductDetails[leadIndex - 1].productId
                        );
                        setFetchProdInfo(allProductDetails[leadIndex - 1]);
                      }
                    }}
                  >
                    {leftAngleArrow}
                  </span>
                  <span
                    className={`ms-1 ${
                      selectedProductIndex === allProductDetails.length - 1
                        ? "opacity-50"
                        : "cursorPointer"
                    }`}
                    onClick={() => {
                      const leadIndex = allProductDetails.findIndex(
                        (lead) => lead.productId === productId
                      );
                      if (leadIndex < allProductDetails.length - 1) {
                        setSelectedProductIndex(leadIndex + 1);
                        setProductId(
                          allProductDetails[leadIndex + 1].productId
                        );
                        getProductOverview(
                          allProductDetails[leadIndex + 1].productId
                        );
                        setFetchProdInfo(allProductDetails[leadIndex + 1]);
                      }
                    }}
                  >
                    {rightAngleArrow}
                  </span>
                </span>
              </div>
              <div className=" d-flex justify-content-between">
                <div className="profile_logo_container d-flex pt-2">
                  <div className="logoContainer d-flex align-items-center justify-content-center pt-1">
                    {/* <img src={profileImg} alt="img" height={160} width={160} /> */}
                    <img
                      src={
                        productInfo.productImageData
                          ? productInfo.productImageData
                          : productImg
                      }
                      name="productImageData"
                      alt="img"
                      style={{ width: "100px", height: "100px" }}
                      onClick={() => {
                        setShowImageModal(true);
                      }}
                    />
                  </div>
                  <div className="d-flex flex-column align-items-center justify-content-center ps-2">
                    <span className="first_letter_uppercase">
                      {productInfo.productName}
                    </span>
                    <span className="opacity-75 ">
                      {productInfo?.productCode}
                    </span>
                  </div>
                </div>
                <div className="d-flex  justify-content-end align-items-end  px-2 pb-2">
                  <div className="d-flex gap-2 position-relative">
                    {/* <button className="btn  btnColor text-white">
                      Send Email
                    </button>
                    <button

                      className={`btn btn-secondary} ${
                        innerPage === "convert"
                          ? "text-white btnColor"
                          : "text-dark bg-white"
                      }`}
                      onClick={() => setInnerPage("convert")}
                    >
                      Convert
                    </button> */}
                    <button
                      className="btn btn-secondary bg-white text-dark border border-primary"
                      onClick={handleEditProduct}
                    >
                      Edit
                    </button>
                    <button
                      className="btn btn-secondary bg-white text-dark border border-primary"
                      onClick={(e) => {
                        setShowOptionsProduct(!showOptionsProduct);

                        e.stopPropagation();
                      }}
                    >
                      ---
                    </button>

                    {showOptionsProduct && (
                      <div
                        className="rounded  showOptions p-2"
                        style={{ top: "2.6rem" }}
                      >
                        <div
                          className="d-flex gap-3 align-items-center mt-2"
                          onClick={handleCreateProductClone}
                        >
                          <img src={Close} alt="close" />
                          <div
                            style={{ color: "#515151", fontWeight: "300" }}
                            className="mt-1 cursorPointer ms-2"
                          >
                            Clone
                          </div>
                        </div>
                        {productInfo.isShared === false && (
                          <div className="d-flex gap-3 align-items-center mt-2">
                            <img src={Share} alt="close" />
                            <div
                              style={{ color: "#515151", fontWeight: "300" }}
                              className="mt-2 cursorPointer ms-2"
                              onClick={() => {
                                if (!isLoading) {
                                  getAllInfoShare(productId);
                                }
                              }}
                            >
                              Share
                            </div>
                          </div>
                        )}

                        <div
                          className="d-flex gap-3 align-items-center mt-2"
                          onClick={handleDeleteProduct}
                        >
                          <img src={Delete} alt="close" />
                          <div
                            style={{ color: "#515151", fontWeight: "300" }}
                            className="mt-2 cursorPointer ms-2"
                          >
                            Delete
                          </div>
                        </div>
                        <div
                          style={{ borderBottom: "1px solid #515151s" }}
                        ></div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </header>

            <div className="detailList_main_container pt-2 commonContainer_bg">
              <div className="detailList_main_container d-flex  py-2 ">
                <div
                  className={` pt-3 cursorPointer ${isShrinked ? "" : "w-25"}`}
                  onClick={() => {
                    setIsShrinked(!isShrinked);
                  }}
                >
                  {isShrinked ? expandArrow : shrinkArrow}
                </div>
                <div
                  className={`d-flex justify-content-between px-2 ${
                    isShrinked ? "w-100" : "w-75 ps-3"
                  }`}
                >
                  <span className="pt-3 ps-2">
                    Last Update: {productInfo.lastUpdate}
                  </span>
                  <div className="d-flex  gap-4 justify-content-between px-2 bg-white px-1    rounded-5 py-1">
                    <span
                      // className=""

                      className={`cursorPointer } ${
                        productPageView === "overview"
                          ? "overviewTimelineTab "
                          : "  d-flex justify-content-center align-items-center"
                      }`}
                      onClick={() => setProductPageView("overview")}
                    >
                      Overview
                    </span>
                    <span
                      className={`cursorPointer } ${
                        productPageView === "timeline"
                          ? "overviewTimelineTab"
                          : " d-flex justify-content-center align-items-center"
                      }`}
                      onClick={() => {
                        setProductPageView("timeline");
                        getAllTimelineProduct();
                      }}
                    >
                      Timeline
                    </span>
                  </div>
                </div>
              </div>

              <div className="detailList_content_container bg-white d-flex justify-content-between px-2 py-2 bg-white">
                <div
                  className={`w-25 relatedListContainer rounded commonContainer_bg  me-2 ${
                    isShrinked ? "d-none" : ""
                  }`} // style={{ height: "100vh" }}
                >
                  <div className="d-flex">
                    <p className="opacity-57 ps-2 opacity-75 m-0 py-2">
                      Related List
                    </p>
                  </div>
                  <div className="horizontal_line mx-1"></div>
                  <ul>
                    {notePermission === "TRUE" && (
                      <li
                        className={`cursorPointer p-2 relatedList_tab rounded-1`}
                        onClick={() => {
                          if (productPageView === "timeline") {
                            setProductPageView("overview");
                            // setActiveTab("notes");
                            setTimeout(
                              () => scrollToSection("notesSection"),
                              0
                            );
                          } else {
                            // setActiveTab("notes");
                            scrollToSection("notesSection");
                          }
                        }}
                      >
                        <span>
                          <img
                            src={noteIcon}
                            alt="img"
                            height={20}
                            width={20}
                          />
                        </span>
                        Notes
                      </li>
                    )}

                    <li
                      className={`cursorPointer p-2 relatedList_tab rounded-1`}
                      onClick={() => {
                        if (productPageView === "timeline") {
                          setProductPageView("overview");
                          // setActiveTab("openActivity");
                          setTimeout(() => scrollToSection("openActivity"), 0);
                        } else {
                          // setActiveTab("openActivity");
                          scrollToSection("openActivity");
                        }
                      }}
                    >
                      <span>
                        <img
                          src={openActivityIcon}
                          alt="img"
                          height={20}
                          width={20}
                        />
                      </span>
                      Open Activity
                    </li>
                    <li
                      className={`cursorPointer p-2 relatedList_tab rounded-1`}
                      onClick={() => {
                        if (productPageView === "timeline") {
                          setProductPageView("overview");
                          // setActiveTab("closeActivity");
                          setTimeout(() => scrollToSection("closeActivity"), 0);
                        } else {
                          // setActiveTab("closeActivity");
                          scrollToSection("closeActivity");
                        }
                      }}
                    >
                      <span>
                        <img
                          src={closeActivityIcon}
                          alt="img"
                          height={20}
                          width={20}
                        />
                      </span>
                      Closed Activity
                    </li>
                    <li
                      className={`cursorPointer p-2 relatedList_tab rounded-1`}
                      onClick={() => {
                        if (productPageView === "timeline") {
                          setProductPageView("overview");
                          // setActiveTab("contacts");
                          setTimeout(() => scrollToSection("contacts"), 0);
                        } else {
                          // setActiveTab("contacts");
                          scrollToSection("contacts");
                        }
                      }}
                    >
                      <span>
                        <img src={noteIcon} alt="img" height={20} width={20} />
                      </span>
                      Contact
                    </li>

                    <li
                      className={`cursorPointer p-2 relatedList_tab rounded-1`}
                      onClick={() => {
                        if (productPageView === "timeline") {
                          setProductPageView("overview");
                          // setActiveTab("leads");
                          setTimeout(() => scrollToSection("leads"), 0);
                        } else {
                          // setActiveTab("leads");
                          scrollToSection("leads");
                        }
                      }}
                    >
                      <span>
                        <img
                          src={leadListIcon}
                          alt="img"
                          height={20}
                          width={20}
                          style={{ color: "red" }}
                        />
                      </span>
                      Leads{" "}
                    </li>

                    <li
                      className={`cursorPointer p-2 relatedList_tab rounded-1`}
                      onClick={() => {
                        if (productPageView === "timeline") {
                          setProductPageView("overview");
                          // setActiveTab("deals");
                          setTimeout(() => scrollToSection("deals"), 0);
                        } else {
                          // setActiveTab("deals");
                          scrollToSection("deals");
                        }
                      }}
                    >
                      <span>
                        <img
                          src={DealListIcon}
                          alt="img"
                          height={20}
                          width={20}
                        />
                      </span>
                      Deals
                    </li>

                    <li
                      className={`cursorPointer p-2 relatedList_tab rounded-1`}
                      onClick={() => {
                        if (productPageView === "timeline") {
                          setProductPageView("overview");
                          // setActiveTab("accounts");
                          setTimeout(() => scrollToSection("accounts"), 0);
                        } else {
                          // setActiveTab("accounts");
                          scrollToSection("accounts");
                        }
                      }}
                    >
                      <span>
                        <img
                          src={accListIcon}
                          alt="img"
                          height={20}
                          width={20}
                        />
                      </span>
                      Accounts
                    </li>
                  </ul>
                </div>
                {/* Contact Timeline Implementation */}
                {productPageView === "overview" ? (
                  <>
                    <div
                      className={`bg-white w-75 rounded-2 leadDetail_main_Container ${
                        isShrinked ? "w-100" : "w-75"
                      }`}
                    >
                      <div className="py-2 rounded-2 commonContainer_bg d-flex justify-content-between align-items-center">
                        <div className="w-100 d-flex flex-column">
                          {fieldLabels.map((field, i) => {
                            const fieldValue =
                              field.value ||
                              field.name
                                .replace(/\s+/g, "")
                                .replace(/^(.)/, (match) =>
                                  match.toLowerCase()
                                );
                            return field.isShowed ? (
                              <div className="d-flex w-50 ps-3" key={i}>
                                <div className="w-50 position-relative">
                                  <div
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      setSearchField("");
                                      setSelectedField(
                                        selectedField === field.name
                                          ? ""
                                          : field.name
                                      );
                                    }}
                                    className={`cursorPointer d-flex justify-content-between w-100 my-1 p-1 ${
                                      selectedField === field.name
                                        ? "outline_border rounded-1"
                                        : ""
                                    }`}
                                  >
                                    <span>{field.name}</span>
                                    <img
                                      src={
                                        selectedField === field.name ? up : down
                                      }
                                      alt="img"
                                      height={20}
                                      width={20}
                                      className="pt-1"
                                    />
                                  </div>
                                  {selectedField === field.name && (
                                    <div
                                      className="position-absolute field_container w-100"
                                      onClick={(e) => {
                                        e.stopPropagation();
                                      }}
                                    >
                                      <div className="">
                                        <label
                                          htmlFor="selectField"
                                          className="position-relative w-100"
                                        >
                                          <input
                                            id="selectField"
                                            type="text"
                                            className="rounded-2 p-1 w-100 pe-4"
                                            placeholder="Search"
                                            value={searchField}
                                            onChange={(e) => {
                                              if (
                                                e.target.value.charAt(0) !== " "
                                              ) {
                                                const value =
                                                  e.target.value.replace(
                                                    /[^a-zA-Z ]+|(\s)\s+/g,
                                                    "$1"
                                                  );
                                                setSearchField(value);
                                              }
                                            }}
                                          />
                                          <img
                                            className="position-absolute mt-1 me-2 end-0 pt-1 cursorPointer"
                                            onClick={() => {
                                              setSearchField("");
                                            }}
                                            src={searchIcon}
                                            alt="search"
                                            width={20}
                                          />
                                        </label>

                                        <div className="w-100 rounded-2">
                                          <div className="border border-1 field_container_dropdown rounded-1">
                                            {showSearchFields
                                              .filter((field) =>
                                                field.name
                                                  .toLowerCase()
                                                  .includes(
                                                    searchField
                                                      .trim()
                                                      .toLowerCase()
                                                  )
                                              )
                                              .sort((a, b) =>
                                                a.name.localeCompare(b.name)
                                              )
                                              .map(
                                                (field, i) =>
                                                  (selectedField ===
                                                    field.name ||
                                                    !field.isShowed) && (
                                                    <div
                                                      key={field.name}
                                                      className="d-flex gap-2 p-2 cursorPointer rounded-2 align-items-center"
                                                      onClick={() => {
                                                        selectedField ===
                                                        field.name
                                                          ? setSelectedField("")
                                                          : handleFieldClick(
                                                              field,
                                                              i
                                                            );
                                                      }}
                                                    >
                                                      <span className="right_tick">
                                                        {selectedField ===
                                                        field.name
                                                          ? tick
                                                          : ""}
                                                      </span>
                                                      <span className="ps-2">
                                                        {field.name}
                                                      </span>
                                                    </div>
                                                  )
                                              )}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  )}
                                </div>
                                <div
                                  className={`w-50 mt-1 ms-5 position-relative`}
                                >
                                  {field.type === "input" ? (
                                    <EditableInput
                                      fieldType="text"
                                      className="ps-3"
                                      inputType={fieldValue}
                                      handleIndividualInputChange={
                                        handleIndividualInputChange
                                      }
                                      editingFields={editingFields}
                                      handleEditClick={handleEditClick}
                                      handleCancelClick={handleCancelClick}
                                      handleUpdateClick={handleUpdateClick}
                                      updatedFields={updatedFields}
                                      accountOverviewDetails={productInfo}
                                      showType={showType}
                                      setShowType={setShowType}
                                    />
                                  ) : field.type === "date" ? (
                                    <EditableInput
                                      fieldType={"date"}
                                      inputType={fieldValue}
                                      handleIndividualInputChange={
                                        handleIndividualInputChange
                                      }
                                      editingFields={editingFields}
                                      handleEditClick={handleEditClick}
                                      handleCancelClick={handleCancelClick}
                                      handleUpdateClick={handleUpdateClick}
                                      updatedFields={updatedFields}
                                      accountOverviewDetails={productInfo}
                                      showType={showType}
                                      setShowType={setShowType}
                                    />
                                  ) : field.type === "dropdown" ? (
                                    <span>
                                      {editingFields[fieldValue] &&
                                      showType === fieldValue ? (
                                        <Select
                                          value={updatedFields[fieldValue]}
                                          menuPlacement="auto"
                                          onChange={(selectedOption) =>
                                            handleChangeIndDropDown(
                                              selectedOption,
                                              fieldValue
                                            )
                                          }
                                          options={
                                            field.name === "Tax"
                                              ? taxOptions
                                              : field.name === "Manufacturer"
                                              ? manufacturerOptions
                                              : field.name === "Usage Unit"
                                              ? usageUnitOptions
                                              : field.name ===
                                                "Product Category"
                                              ? productCategoryOptions
                                              : industryOptions
                                          }
                                          styles={createLeadStyles}
                                          isSearchable={false}
                                        />
                                      ) : (
                                        <input
                                          type="text"
                                          className={`cursorPointer indvEditInput
                            ${
                              showType === fieldValue
                                ? "selectedBorder "
                                : "unselectBorder bg-transparent"
                            }`}
                                          value={
                                            field.name === "Tax"
                                              ? productInfo[fieldValue]?.value +
                                                "%"
                                              : productInfo[fieldValue]?.value
                                          }
                                          name={fieldValue}
                                          onClick={(e) => {
                                            if (showType === "") {
                                              updatedFields[fieldValue] =
                                                productInfo[fieldValue]?.value;
                                              setShowType(e.target.name);
                                              setUpdatedFields(updatedFields);
                                            }
                                            handleEditClick(fieldValue);
                                          }}
                                          // readOnly={showType !== "emailId"}
                                          readOnly={true}
                                        />
                                      )}
                                      {editingFields[fieldValue] &&
                                        showType === fieldValue && (
                                          <div className="d-flex gap-2">
                                            <span
                                              onClick={() => {
                                                setShowType("");
                                                handleUpdateClick(fieldValue);
                                              }}
                                              className="ps-2 cursorPointer"
                                            >
                                              <img
                                                src={updateIcon}
                                                alt="img"
                                                height={20}
                                                width={20}
                                              />
                                            </span>
                                            <span
                                              onClick={() => {
                                                setShowType("");
                                                // handleUpdateClick(inputType);
                                                handleCancelClick(fieldValue);
                                              }}
                                              className="cursorPointer"
                                            >
                                              <img
                                                src={cancelIcon}
                                                alt="img"
                                                height={20}
                                                width={20}
                                              />
                                            </span>
                                          </div>
                                        )}
                                    </span>
                                  ) : field.type === "readOnly" ? (
                                    <div>
                                      <span className="d-flex flex-column">
                                        <span>
                                          {
                                            productInfo[
                                              field.name === "Created By"
                                                ? "createdBy"
                                                : field.name === "Vendor Name"
                                                ? "vendorName"
                                                : field.name === "Handler"
                                                ? "handlerName"
                                                : "modifiedBy"
                                            ]
                                          }
                                        </span>
                                        <span className="small opacity-75">
                                          {(field.name === "Created By" ||
                                            field.name === "Modified By") &&
                                            productInfo[
                                              field.name === "Created By"
                                                ? "createdDate"
                                                : "modifiedDate"
                                            ]}
                                        </span>
                                      </span>
                                    </div>
                                  ) : field.type === "checkbox" ? (
                                    <span>
                                      <EditableCheckbox
                                        fieldType={fieldValue}
                                        checked={updatedFields[fieldValue]}
                                        handleEditClick={handleEditClick}
                                        handleUpdateClick={handleUpdateClick}
                                        showType={showType}
                                        setShowType={setShowType}
                                      />
                                    </span>
                                  ) : (
                                    <div>
                                      <div className="position-relative">
                                        <div
                                          className="d-flex position-relative"
                                          onClick={() => {
                                            // setIsProductForm(false);
                                            // handleOwnerInputClick(e);
                                            setShowModalType("changeOwner");
                                          }}
                                        >
                                          <input
                                            type="text"
                                            placeholder="Vendor Owner"
                                            className="w-100  cursorPointer  indvEditInput border-0 indivOwnerInput"
                                            value={
                                              selectedItem?.name ||
                                              selectedHandlerItem?.name
                                            }
                                            readOnly
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  )}
                                </div>
                              </div>
                            ) : null;
                          })}
                        </div>
                      </div>

                      <div className="lead_Information_mainContainer commonContainer_bg     rounded-2  d-flex flex-column my-2 py-2">
                        <h5 className="w-100 text-primary px-1 pt-1">
                          Product Information
                        </h5>
                        <div className="d-flex justify-content-between">
                          <div className="leadContact_container d-flex gap-4 w-100 ps-2 pe-2">
                            <div className="w-50 ">
                              {fieldIndividualDropdowns
                                .slice(0, 7)
                                .map((field) => {
                                  const fieldValue =
                                    field.value ||
                                    field.name
                                      .replace(/\s+/g, "")
                                      .replace(/^(.)/, (match) =>
                                        match.toLowerCase()
                                      );
                                  return (
                                    <div
                                      className="d-flex justify-content-between align-items-center my-2 w-100 gap-3"
                                      key={fieldValue}
                                    >
                                      <div className="opacity-75 p-2 w-100">
                                        {field.name}
                                      </div>
                                      <div className="w-100">
                                        {field.type === "input" ? (
                                          <EditableInput
                                            fieldType="text"
                                            className="ps-3"
                                            inputType={fieldValue}
                                            handleIndividualInputChange={
                                              handleIndividualInputChange
                                            }
                                            editingFields={editingFields}
                                            handleEditClick={handleEditClick}
                                            handleCancelClick={
                                              handleCancelClick
                                            }
                                            handleUpdateClick={
                                              handleUpdateClick
                                            }
                                            updatedFields={updatedFields}
                                            accountOverviewDetails={productInfo}
                                            showType={showType}
                                            setShowType={setShowType}
                                          />
                                        ) : field.type === "date" ? (
                                          <EditableInput
                                            fieldType={"date"}
                                            inputType={fieldValue}
                                            handleIndividualInputChange={
                                              handleIndividualInputChange
                                            }
                                            editingFields={editingFields}
                                            handleEditClick={handleEditClick}
                                            handleCancelClick={
                                              handleCancelClick
                                            }
                                            handleUpdateClick={
                                              handleUpdateClick
                                            }
                                            updatedFields={updatedFields}
                                            accountOverviewDetails={productInfo}
                                            showType={showType}
                                            setShowType={setShowType}
                                          />
                                        ) : field.type === "dropdown" ? (
                                          <div
                                            className="position-relative d-flex w-100"
                                            style={{ alignItems: "center" }}
                                            onClick={() => {
                                              handleEditClick(fieldValue);
                                            }}
                                          >
                                            {editingFields[fieldValue] &&
                                            showType === fieldValue ? (
                                              <Select
                                                value={
                                                  updatedFields[fieldValue]
                                                }
                                                // value={accountInfoDetails[fieldValue]}
                                                menuPlacement="auto"
                                                onChange={(selectedOption) =>
                                                  handleChangeIndDropDown(
                                                    selectedOption,
                                                    fieldValue
                                                  )
                                                }
                                                options={
                                                  field.name === "Tax"
                                                    ? taxOptions
                                                    : field.name ===
                                                      "Manufacturer"
                                                    ? manufacturerOptions
                                                    : field.name ===
                                                      "Usage Unit"
                                                    ? usageUnitOptions
                                                    : field.name ===
                                                      "Product Category"
                                                    ? productCategoryOptions
                                                    : industryOptions
                                                }
                                                styles={fieldDropdownStyles}
                                                isSearchable={false}
                                              />
                                            ) : (
                                              <input
                                                type="text"
                                                className={`w-100 cursorPointer indvEditInput
                              ${
                                showType === fieldValue
                                  ? "selectedBorder "
                                  : "unselectBorder bg-transparent"
                              }`}
                                                value={
                                                  field.name === "Tax"
                                                    ? productInfo[fieldValue]
                                                        ?.value + "%"
                                                    : productInfo[fieldValue]
                                                        ?.value
                                                }
                                                name={fieldValue}
                                                onClick={(e) => {
                                                  if (showType === "") {
                                                    updatedFields[fieldValue] =
                                                      productInfo[
                                                        fieldValue
                                                      ]?.value;
                                                    setShowType(e.target.name);
                                                    setUpdatedFields(
                                                      updatedFields
                                                    );
                                                  }
                                                  handleEditClick(fieldValue);
                                                }}
                                                // readOnly={showType !== "emailId"}
                                                readOnly={true}
                                              />
                                            )}
                                            {editingFields[fieldValue] &&
                                              showType === fieldValue && (
                                                <div className="d-flex gap-2">
                                                  <span
                                                    onClick={() => {
                                                      setShowType("");
                                                      handleUpdateClick(
                                                        fieldValue
                                                      );
                                                    }}
                                                    className="ps-2 cursorPointer"
                                                  >
                                                    <img
                                                      src={updateIcon}
                                                      alt="img"
                                                      height={20}
                                                      width={20}
                                                    />
                                                  </span>
                                                  <span
                                                    onClick={() => {
                                                      setShowType("");
                                                      // handleUpdateClick(inputType);
                                                      handleCancelClick(
                                                        fieldValue
                                                      );
                                                    }}
                                                    className="cursorPointer"
                                                  >
                                                    <img
                                                      src={cancelIcon}
                                                      alt="img"
                                                      height={20}
                                                      width={20}
                                                    />
                                                  </span>
                                                </div>
                                              )}
                                          </div>
                                        ) : field.type === "readOnly" ? (
                                          <div>
                                            <span className="d-flex flex-column">
                                              <span>
                                                {
                                                  productInfo[
                                                    field.name === "Created By"
                                                      ? "createdBy"
                                                      : field.name ===
                                                        "Vendor Name"
                                                      ? "vendorName"
                                                      : field.name === "Handler"
                                                      ? "handlerName"
                                                      : "modifiedBy"
                                                  ]
                                                }
                                              </span>
                                              <span className="small opacity-75">
                                                {(field.name === "Created By" ||
                                                  field.name ===
                                                    "Modified By") &&
                                                  productInfo[
                                                    field.name === "Created By"
                                                      ? "createdDate"
                                                      : "modifiedDate"
                                                  ]}
                                              </span>
                                            </span>
                                          </div>
                                        ) : field.type === "checkbox" ? (
                                          <span>
                                            <EditableCheckbox
                                              fieldType={fieldValue}
                                              checked={
                                                updatedFields[fieldValue]
                                              }
                                              handleEditClick={handleEditClick}
                                              handleUpdateClick={
                                                handleUpdateClick
                                              }
                                              showType={showType}
                                              setShowType={setShowType}
                                            />
                                          </span>
                                        ) : (
                                          <div>
                                            <div className="position-relative">
                                              <div
                                                className="d-flex position-relative"
                                                onClick={() => {
                                                  // setIsProductForm(false);
                                                  // handleOwnerInputClick(e);
                                                  setShowModalType(
                                                    "changeOwner"
                                                  );
                                                }}
                                              >
                                                <input
                                                  type="text"
                                                  placeholder="Vendor Owner"
                                                  className="w-100  cursorPointer  indvEditInput border-0 indivOwnerInput"
                                                  value={
                                                    selectedItem?.name ||
                                                    selectedHandlerItem?.name
                                                  }
                                                  readOnly
                                                />
                                              </div>
                                            </div>
                                          </div>
                                        )}
                                      </div>
                                    </div>
                                  );
                                })}
                            </div>
                            <div className="w-50 ">
                              {fieldIndividualDropdowns
                                .slice(7, 13)
                                .map((field) => {
                                  const fieldValue =
                                    field.value ||
                                    field.name
                                      .replace(/\s+/g, "")
                                      .replace(/^(.)/, (match) =>
                                        match.toLowerCase()
                                      );
                                  return (
                                    <div
                                      className="d-flex justify-content-between align-items-center my-2 w-100 gap-3"
                                      key={fieldValue}
                                    >
                                      <div className="opacity-75 p-2 w-100">
                                        {field.name}
                                      </div>
                                      <div className="w-100">
                                        {field.type === "input" ? (
                                          <EditableInput
                                            fieldType="text"
                                            className="ps-3"
                                            inputType={fieldValue}
                                            handleIndividualInputChange={
                                              handleIndividualInputChange
                                            }
                                            editingFields={editingFields}
                                            handleEditClick={handleEditClick}
                                            handleCancelClick={
                                              handleCancelClick
                                            }
                                            handleUpdateClick={
                                              handleUpdateClick
                                            }
                                            updatedFields={updatedFields}
                                            accountOverviewDetails={productInfo}
                                            showType={showType}
                                            setShowType={setShowType}
                                          />
                                        ) : field.type === "date" ? (
                                          <EditableInput
                                            fieldType={"date"}
                                            inputType={fieldValue}
                                            handleIndividualInputChange={
                                              handleIndividualInputChange
                                            }
                                            editingFields={editingFields}
                                            handleEditClick={handleEditClick}
                                            handleCancelClick={
                                              handleCancelClick
                                            }
                                            handleUpdateClick={
                                              handleUpdateClick
                                            }
                                            updatedFields={updatedFields}
                                            accountOverviewDetails={productInfo}
                                            showType={showType}
                                            setShowType={setShowType}
                                          />
                                        ) : field.type === "dropdown" ? (
                                          <span>
                                            {editingFields[fieldValue] &&
                                            showType === fieldValue ? (
                                              <Select
                                                value={
                                                  updatedFields[fieldValue]
                                                }
                                                menuPlacement="auto"
                                                onChange={(selectedOption) =>
                                                  handleChangeIndDropDown(
                                                    selectedOption,
                                                    fieldValue
                                                  )
                                                }
                                                options={
                                                  field.name === "Tax"
                                                    ? taxOptions
                                                    : field.name ===
                                                      "Manufacturer"
                                                    ? manufacturerOptions
                                                    : field.name ===
                                                      "Usage Unit"
                                                    ? usageUnitOptions
                                                    : field.name ===
                                                      "Product Category"
                                                    ? productCategoryOptions
                                                    : industryOptions
                                                }
                                                styles={createLeadStyles}
                                                isSearchable={false}
                                              />
                                            ) : (
                                              <input
                                                type="text"
                                                className={`cursorPointer indvEditInput
                            ${
                              showType === fieldValue
                                ? "selectedBorder "
                                : "unselectBorder bg-transparent"
                            }`}
                                                value={
                                                  field.name === "Tax"
                                                    ? productInfo[fieldValue]
                                                        ?.value + "%"
                                                    : productInfo[fieldValue]
                                                        ?.value
                                                }
                                                name={fieldValue}
                                                onClick={(e) => {
                                                  if (showType === "") {
                                                    updatedFields[fieldValue] =
                                                      productInfo[
                                                        fieldValue
                                                      ]?.value;
                                                    setShowType(e.target.name);
                                                    setUpdatedFields(
                                                      updatedFields
                                                    );
                                                  }
                                                  handleEditClick(fieldValue);
                                                }}
                                                // readOnly={showType !== "emailId"}
                                                readOnly={true}
                                              />
                                            )}
                                            {editingFields[fieldValue] &&
                                              showType === fieldValue && (
                                                <div className="d-flex gap-2">
                                                  <span
                                                    onClick={() => {
                                                      setShowType("");
                                                      handleUpdateClick(
                                                        fieldValue
                                                      );
                                                    }}
                                                    className="ps-2 cursorPointer"
                                                  >
                                                    <img
                                                      src={updateIcon}
                                                      alt="img"
                                                      height={20}
                                                      width={20}
                                                    />
                                                  </span>
                                                  <span
                                                    onClick={() => {
                                                      setShowType("");
                                                      // handleUpdateClick(inputType);
                                                      handleCancelClick(
                                                        fieldValue
                                                      );
                                                    }}
                                                    className="cursorPointer"
                                                  >
                                                    <img
                                                      src={cancelIcon}
                                                      alt="img"
                                                      height={20}
                                                      width={20}
                                                    />
                                                  </span>
                                                </div>
                                              )}
                                          </span>
                                        ) : field.type === "readOnly" ? (
                                          <div>
                                            <span className="d-flex flex-column">
                                              <span>
                                                {
                                                  productInfo[
                                                    field.name === "Created By"
                                                      ? "createdBy"
                                                      : field.name ===
                                                        "Vendor Name"
                                                      ? "vendorName"
                                                      : field.name === "Handler"
                                                      ? "handlerName"
                                                      : "modifiedBy"
                                                  ]
                                                }
                                              </span>
                                              <span className="small opacity-75">
                                                {(field.name === "Created By" ||
                                                  field.name ===
                                                    "Modified By") &&
                                                  productInfo[
                                                    field.name === "Created By"
                                                      ? "createdDate"
                                                      : "modifiedDate"
                                                  ]}
                                              </span>
                                            </span>
                                          </div>
                                        ) : field.type === "checkbox" ? (
                                          <span>
                                            <EditableCheckbox
                                              fieldType={fieldValue}
                                              checked={
                                                updatedFields[fieldValue]
                                              }
                                              handleEditClick={handleEditClick}
                                              handleUpdateClick={
                                                handleUpdateClick
                                              }
                                              showType={showType}
                                              setShowType={setShowType}
                                            />
                                          </span>
                                        ) : (
                                          <div>
                                            <div className="position-relative">
                                              <div
                                                className="d-flex position-relative"
                                                onClick={() => {
                                                  // setIsProductForm(false);
                                                  // handleOwnerInputClick(e);
                                                  setShowModalType(
                                                    "changeOwner"
                                                  );
                                                }}
                                              >
                                                <input
                                                  type="text"
                                                  placeholder="Vendor Owner"
                                                  className="w-100  cursorPointer  indvEditInput border-0 indivOwnerInput"
                                                  value={
                                                    selectedItem?.name ||
                                                    selectedHandlerItem?.name
                                                  }
                                                  readOnly
                                                />
                                              </div>
                                            </div>
                                          </div>
                                        )}
                                      </div>
                                    </div>
                                  );
                                })}
                            </div>
                          </div>
                        </div>
                        <h5 className="w-100 text-primary px-1 pt-1">
                          Price Information
                        </h5>
                        <div className="d-flex justify-content-between">
                          <div className="leadContact_container d-flex gap-4 w-100 ps-2 pe-2">
                            <div className="w-50 ">
                              {fieldIndividualDropdowns
                                .slice(13, 15)
                                .map((field) => {
                                  const fieldValue =
                                    field.value ||
                                    field.name
                                      .replace(/\s+/g, "")
                                      .replace(/^(.)/, (match) =>
                                        match.toLowerCase()
                                      );
                                  return (
                                    <div
                                      className="d-flex justify-content-between align-items-center my-2 w-100 gap-3"
                                      key={fieldValue}
                                    >
                                      <div className="opacity-75 p-2 w-100">
                                        {field.name}
                                      </div>
                                      <div className="w-100">
                                        {field.type === "input" ? (
                                          <EditableInput
                                            fieldType="text"
                                            className="ps-3"
                                            inputType={fieldValue}
                                            handleIndividualInputChange={
                                              handleIndividualInputChange
                                            }
                                            editingFields={editingFields}
                                            handleEditClick={handleEditClick}
                                            handleCancelClick={
                                              handleCancelClick
                                            }
                                            handleUpdateClick={
                                              handleUpdateClick
                                            }
                                            updatedFields={updatedFields}
                                            accountOverviewDetails={productInfo}
                                            showType={showType}
                                            setShowType={setShowType}
                                          />
                                        ) : field.type === "dropdown" ? (
                                          <span>
                                            {editingFields[fieldValue] &&
                                            showType === fieldValue ? (
                                              <Select
                                                value={
                                                  updatedFields[fieldValue]
                                                }
                                                menuPlacement="auto"
                                                onChange={(selectedOption) =>
                                                  handleChangeIndDropDown(
                                                    selectedOption,
                                                    fieldValue
                                                  )
                                                }
                                                options={
                                                  field.name === "Tax"
                                                    ? taxOptions
                                                    : field.name ===
                                                      "Manufacturer"
                                                    ? manufacturerOptions
                                                    : field.name ===
                                                      "Usage Unit"
                                                    ? usageUnitOptions
                                                    : field.name ===
                                                      "Product Category"
                                                    ? productCategoryOptions
                                                    : industryOptions
                                                }
                                                styles={createLeadStyles}
                                                isSearchable={false}
                                              />
                                            ) : (
                                              <input
                                                type="text"
                                                className={`cursorPointer indvEditInput
                            ${
                              showType === fieldValue
                                ? "selectedBorder "
                                : "unselectBorder bg-transparent"
                            }`}
                                                value={
                                                  field.name === "Tax"
                                                    ? productInfo[fieldValue]
                                                        ?.value + "%"
                                                    : productInfo[fieldValue]
                                                        ?.value
                                                }
                                                name={fieldValue}
                                                onClick={(e) => {
                                                  if (showType === "") {
                                                    updatedFields[fieldValue] =
                                                      productInfo[
                                                        fieldValue
                                                      ]?.value;
                                                    setShowType(e.target.name);
                                                    setUpdatedFields(
                                                      updatedFields
                                                    );
                                                  }
                                                  handleEditClick(fieldValue);
                                                }}
                                                // readOnly={showType !== "emailId"}
                                                readOnly={true}
                                              />
                                            )}
                                            {editingFields[fieldValue] &&
                                              showType === fieldValue && (
                                                <div className="d-flex gap-2">
                                                  <span
                                                    onClick={() => {
                                                      setShowType("");
                                                      handleUpdateClick(
                                                        fieldValue
                                                      );
                                                    }}
                                                    className="ps-2 cursorPointer"
                                                  >
                                                    <img
                                                      src={updateIcon}
                                                      alt="img"
                                                      height={20}
                                                      width={20}
                                                    />
                                                  </span>
                                                  <span
                                                    onClick={() => {
                                                      setShowType("");
                                                      // handleUpdateClick(inputType);
                                                      handleCancelClick(
                                                        fieldValue
                                                      );
                                                    }}
                                                    className="cursorPointer"
                                                  >
                                                    <img
                                                      src={cancelIcon}
                                                      alt="img"
                                                      height={20}
                                                      width={20}
                                                    />
                                                  </span>
                                                </div>
                                              )}
                                          </span>
                                        ) : field.type === "readOnly" ? (
                                          <div>
                                            <span className="d-flex flex-column">
                                              <span>
                                                {
                                                  productInfo[
                                                    field.name === "Created By"
                                                      ? "createdBy"
                                                      : field.name ===
                                                        "Vendor Name"
                                                      ? "vendorName"
                                                      : field.name === "Handler"
                                                      ? "handlerName"
                                                      : "modifiedBy"
                                                  ]
                                                }
                                              </span>
                                              <span className="small opacity-75">
                                                {(field.name === "Created By" ||
                                                  field.name ===
                                                    "Modified By") &&
                                                  productInfo[
                                                    field.name === "Created By"
                                                      ? "createdDate"
                                                      : "modifiedDate"
                                                  ]}
                                              </span>
                                            </span>
                                          </div>
                                        ) : (
                                          <div>
                                            <div className="position-relative">
                                              <div
                                                className="d-flex position-relative"
                                                onClick={() => {
                                                  setShowModalType(
                                                    "changeOwner"
                                                  );
                                                }}
                                              >
                                                <input
                                                  type="text"
                                                  placeholder="Vendor Owner"
                                                  className="w-100  cursorPointer  indvEditInput border-0 indivOwnerInput"
                                                  value={
                                                    selectedItem?.name ||
                                                    selectedHandlerItem?.name
                                                  }
                                                  readOnly
                                                />
                                              </div>
                                            </div>
                                          </div>
                                        )}
                                      </div>
                                    </div>
                                  );
                                })}
                            </div>
                            <div className="w-50 ">
                              {fieldIndividualDropdowns
                                .slice(15, 17)
                                .map((field) => {
                                  const fieldValue =
                                    field.value ||
                                    field.name
                                      .replace(/\s+/g, "")
                                      .replace(/^(.)/, (match) =>
                                        match.toLowerCase()
                                      );
                                  return (
                                    <div
                                      className="d-flex justify-content-between align-items-center my-2 w-100 gap-3"
                                      key={fieldValue}
                                    >
                                      <div className="opacity-75 p-2 w-100">
                                        {field.name}
                                      </div>
                                      <div className="w-100">
                                        {field.type === "input" ? (
                                          <EditableInput
                                            fieldType="text"
                                            className="ps-3"
                                            inputType={fieldValue}
                                            handleIndividualInputChange={
                                              handleIndividualInputChange
                                            }
                                            editingFields={editingFields}
                                            handleEditClick={handleEditClick}
                                            handleCancelClick={
                                              handleCancelClick
                                            }
                                            handleUpdateClick={
                                              handleUpdateClick
                                            }
                                            updatedFields={updatedFields}
                                            accountOverviewDetails={productInfo}
                                            showType={showType}
                                            setShowType={setShowType}
                                          />
                                        ) : field.type === "checkbox" ? (
                                          <span>
                                            <EditableCheckbox
                                              fieldType={fieldValue}
                                              checked={
                                                updatedFields[fieldValue]
                                              }
                                              handleEditClick={handleEditClick}
                                              handleUpdateClick={
                                                handleUpdateClick
                                              }
                                              showType={showType}
                                              setShowType={setShowType}
                                            />
                                          </span>
                                        ) : (
                                          <div>
                                            <div className="position-relative">
                                              <div
                                                className="d-flex position-relative"
                                                onClick={() => {
                                                  // setIsProductForm(false);
                                                  // handleOwnerInputClick(e);
                                                  setShowModalType(
                                                    "changeOwner"
                                                  );
                                                }}
                                              >
                                                <input
                                                  type="text"
                                                  placeholder="Vendor Owner"
                                                  className="w-100  cursorPointer  indvEditInput border-0 indivOwnerInput"
                                                  value={
                                                    selectedItem?.name ||
                                                    selectedHandlerItem?.name
                                                  }
                                                  readOnly
                                                />
                                              </div>
                                              {/* {listVisible && !isProductForm && (
                                            <ul className="position-absolute w-100 px-2 small field_container_dropdown">
                                              {userList.map((item, index) => (
                                                <li
                                                  key={index}
                                                  className="cursorPointer list-group-item my-2 border-bottom"
                                                  onClick={() => {
                                                    handleUserItemClick(item);
                                                  }}
                                                >
                                                  <div className="d-flex flex-column">
                                                    <span> {item.name}</span>
                                                    <span className="small opacity-75">
                                                      {" "}
                                                      {item.emailId}
                                                    </span>
                                                  </div>
                                                </li>
                                              ))}
                                            </ul>
                                          )} */}

                                              {/* {editingFields["productInfo"] &&
                                            !isProductForm && (
                                              <div
                                                className="position-absolute top-0"
                                                style={{ right: "-5rem" }}
                                              >
                                                <div className="d-flex gap-2">
                                                  <span
                                                    onClick={() => {
                                                      setShowType("");
                                                      handleUpdateClick(
                                                        "productInfo"
                                                      );
                                                    }}
                                                    className="ps-2 cursorPointer"
                                                  >
                                                    <img
                                                      src={updateIcon}
                                                      alt="img"
                                                      height={20}
                                                      width={20}
                                                    />
                                                  </span>
                                                  <span
                                                    onClick={() => {
                                                      setShowType("");
                                                      // handleUpdateClick(inputType);
                                                      handleCancelClick(
                                                        "productInfo"
                                                      );

                                                      setSelectedItem(
                                                        userList.find(
                                                          (user) =>
                                                            user.userId ===
                                                            productInfo.productOwnerId
                                                        )
                                                      );
                                                    }}
                                                    className="cursorPointer"
                                                  >
                                                    <img
                                                      src={cancelIcon}
                                                      alt="img"
                                                      height={20}
                                                      width={20}
                                                    />
                                                  </span>
                                                </div>
                                              </div>
                                            )} */}
                                            </div>
                                          </div>
                                        )}
                                      </div>
                                    </div>
                                  );
                                })}
                            </div>
                          </div>
                        </div>
                        <h5 className="w-100 text-primary px-1 pt-1">
                          Stock Information
                        </h5>
                        <div className="d-flex justify-content-between">
                          <div className="leadContact_container d-flex gap-4 w-100 ps-2 pe-2">
                            <div className="w-50 ">
                              {fieldIndividualDropdowns
                                .slice(17, 20)
                                .map((field) => {
                                  const fieldValue =
                                    field.value ||
                                    field.name
                                      .replace(/\s+/g, "")
                                      .replace(/^(.)/, (match) =>
                                        match.toLowerCase()
                                      );
                                  return (
                                    <div
                                      className="d-flex justify-content-between align-items-center my-2 w-100 gap-3"
                                      key={fieldValue}
                                    >
                                      <div className="opacity-75 p-2 w-100">
                                        {field.name}
                                      </div>
                                      <div className="w-100">
                                        {field.type === "input" ? (
                                          <EditableInput
                                            fieldType="text"
                                            className="ps-3"
                                            inputType={fieldValue}
                                            handleIndividualInputChange={
                                              handleIndividualInputChange
                                            }
                                            editingFields={editingFields}
                                            handleEditClick={handleEditClick}
                                            handleCancelClick={
                                              handleCancelClick
                                            }
                                            handleUpdateClick={
                                              handleUpdateClick
                                            }
                                            updatedFields={updatedFields}
                                            accountOverviewDetails={productInfo}
                                            showType={showType}
                                            setShowType={setShowType}
                                          />
                                        ) : field.type === "dropdown" ? (
                                          <span>
                                            {editingFields[fieldValue] &&
                                            showType === fieldValue ? (
                                              <Select
                                                value={
                                                  updatedFields[fieldValue]
                                                }
                                                menuPlacement="auto"
                                                onChange={(selectedOption) =>
                                                  handleChangeIndDropDown(
                                                    selectedOption,
                                                    fieldValue
                                                  )
                                                }
                                                options={
                                                  field.name === "Tax"
                                                    ? taxOptions
                                                    : field.name ===
                                                      "Manufacturer"
                                                    ? manufacturerOptions
                                                    : field.name ===
                                                      "Usage Unit"
                                                    ? usageUnitOptions
                                                    : field.name ===
                                                      "Product Category"
                                                    ? productCategoryOptions
                                                    : industryOptions
                                                }
                                                styles={createLeadStyles}
                                                isSearchable={false}
                                              />
                                            ) : (
                                              <input
                                                type="text"
                                                className={`cursorPointer indvEditInput
                            ${
                              showType === fieldValue
                                ? "selectedBorder "
                                : "unselectBorder bg-transparent"
                            }`}
                                                value={
                                                  field.name === "Tax"
                                                    ? productInfo[fieldValue]
                                                        ?.value + "%"
                                                    : productInfo[fieldValue]
                                                        ?.value
                                                }
                                                name={fieldValue}
                                                onClick={(e) => {
                                                  if (showType === "") {
                                                    updatedFields[fieldValue] =
                                                      productInfo[
                                                        fieldValue
                                                      ]?.value;
                                                    setShowType(e.target.name);
                                                    setUpdatedFields(
                                                      updatedFields
                                                    );
                                                  }
                                                  handleEditClick(fieldValue);
                                                }}
                                                // readOnly={showType !== "emailId"}
                                                readOnly={true}
                                              />
                                            )}
                                            {editingFields[fieldValue] &&
                                              showType === fieldValue && (
                                                <div className="d-flex gap-2">
                                                  <span
                                                    onClick={() => {
                                                      setShowType("");
                                                      handleUpdateClick(
                                                        fieldValue
                                                      );
                                                    }}
                                                    className="ps-2 cursorPointer"
                                                  >
                                                    <img
                                                      src={updateIcon}
                                                      alt="img"
                                                      height={20}
                                                      width={20}
                                                    />
                                                  </span>
                                                  <span
                                                    onClick={() => {
                                                      setShowType("");
                                                      // handleUpdateClick(inputType);
                                                      handleCancelClick(
                                                        fieldValue
                                                      );
                                                    }}
                                                    className="cursorPointer"
                                                  >
                                                    <img
                                                      src={cancelIcon}
                                                      alt="img"
                                                      height={20}
                                                      width={20}
                                                    />
                                                  </span>
                                                </div>
                                              )}
                                          </span>
                                        ) : field.type === "readOnly" ? (
                                          <div>
                                            <span className="d-flex flex-column">
                                              <span>
                                                {
                                                  productInfo[
                                                    field.name === "Created By"
                                                      ? "createdBy"
                                                      : field.name ===
                                                        "Vendor Name"
                                                      ? "vendorName"
                                                      : field.name === "Handler"
                                                      ? "handlerName"
                                                      : "modifiedBy"
                                                  ]
                                                }
                                              </span>
                                              <span className="small opacity-75">
                                                {(field.name === "Created By" ||
                                                  field.name ===
                                                    "Modified By") &&
                                                  productInfo[
                                                    field.name === "Created By"
                                                      ? "createdDate"
                                                      : "modifiedDate"
                                                  ]}
                                              </span>
                                            </span>
                                          </div>
                                        ) : (
                                          <div>
                                            <div className="position-relative">
                                              <div
                                                className="d-flex position-relative"
                                                onClick={() => {
                                                  setShowModalType(
                                                    "changeOwner"
                                                  );
                                                }}
                                              >
                                                <input
                                                  type="text"
                                                  placeholder="Vendor Owner"
                                                  className="w-100  cursorPointer  indvEditInput border-0 indivOwnerInput"
                                                  value={
                                                    selectedItem?.name ||
                                                    selectedHandlerItem?.name
                                                  }
                                                  readOnly
                                                />
                                              </div>
                                            </div>
                                          </div>
                                        )}
                                      </div>
                                    </div>
                                  );
                                })}
                            </div>
                            <div className="w-50 ">
                              {fieldIndividualDropdowns
                                .slice(20, 24)
                                .map((field) => {
                                  const fieldValue =
                                    field.value ||
                                    field.name
                                      .replace(/\s+/g, "")
                                      .replace(/^(.)/, (match) =>
                                        match.toLowerCase()
                                      );
                                  return (
                                    <div
                                      className="d-flex justify-content-between align-items-center my-2 w-100 gap-3"
                                      key={fieldValue}
                                    >
                                      <div className="opacity-75 p-2 w-100">
                                        {field.name}
                                      </div>
                                      <div className="w-100">
                                        {field.type === "input" ? (
                                          <EditableInput
                                            fieldType="text"
                                            className="ps-3"
                                            inputType={fieldValue}
                                            handleIndividualInputChange={
                                              handleIndividualInputChange
                                            }
                                            editingFields={editingFields}
                                            handleEditClick={handleEditClick}
                                            handleCancelClick={
                                              handleCancelClick
                                            }
                                            handleUpdateClick={
                                              handleUpdateClick
                                            }
                                            updatedFields={updatedFields}
                                            accountOverviewDetails={productInfo}
                                            showType={showType}
                                            setShowType={setShowType}
                                          />
                                        ) : (
                                          <div>
                                            <div className="position-relative">
                                              <div
                                                className="d-flex position-relative"
                                                onClick={() => {
                                                  // setIsProductForm(false);
                                                  // handleOwnerInputClick(e);
                                                  setShowModalType(
                                                    "changeOwner"
                                                  );
                                                }}
                                              >
                                                <input
                                                  type="text"
                                                  placeholder="Vendor Owner"
                                                  className="w-100  cursorPointer  indvEditInput border-0 indivOwnerInput"
                                                  value={
                                                    selectedItem?.name ||
                                                    selectedHandlerItem?.name
                                                  }
                                                  readOnly
                                                />
                                              </div>
                                              {/* {listVisible && !isProductForm && (
                                            <ul className="position-absolute w-100 px-2 small field_container_dropdown">
                                              {userList.map((item, index) => (
                                                <li
                                                  key={index}
                                                  className="cursorPointer list-group-item my-2 border-bottom"
                                                  onClick={() => {
                                                    handleUserItemClick(item);
                                                  }}
                                                >
                                                  <div className="d-flex flex-column">
                                                    <span> {item.name}</span>
                                                    <span className="small opacity-75">
                                                      {" "}
                                                      {item.emailId}
                                                    </span>
                                                  </div>
                                                </li>
                                              ))}
                                            </ul>
                                          )} */}

                                              {/* {editingFields["productInfo"] &&
                                            !isProductForm && (
                                              <div
                                                className="position-absolute top-0"
                                                style={{ right: "-5rem" }}
                                              >
                                                <div className="d-flex gap-2">
                                                  <span
                                                    onClick={() => {
                                                      setShowType("");
                                                      handleUpdateClick(
                                                        "productInfo"
                                                      );
                                                    }}
                                                    className="ps-2 cursorPointer"
                                                  >
                                                    <img
                                                      src={updateIcon}
                                                      alt="img"
                                                      height={20}
                                                      width={20}
                                                    />
                                                  </span>
                                                  <span
                                                    onClick={() => {
                                                      setShowType("");
                                                      // handleUpdateClick(inputType);
                                                      handleCancelClick(
                                                        "productInfo"
                                                      );

                                                      setSelectedItem(
                                                        userList.find(
                                                          (user) =>
                                                            user.userId ===
                                                            productInfo.productOwnerId
                                                        )
                                                      );
                                                    }}
                                                    className="cursorPointer"
                                                  >
                                                    <img
                                                      src={cancelIcon}
                                                      alt="img"
                                                      height={20}
                                                      width={20}
                                                    />
                                                  </span>
                                                </div>
                                              </div>
                                            )} */}
                                            </div>
                                          </div>
                                        )}
                                      </div>
                                    </div>
                                  );
                                })}
                            </div>
                          </div>
                        </div>
                        <h5 className="w-100 text-primary px-1 pt-1">
                          Description
                        </h5>
                        <div className="d-flex gap-2 px-2">
                          <span className="opacity-75 px-2 py-2">
                            Description
                          </span>
                          <span>
                            <EditableInput
                              fieldType="text"
                              inputType="descriptionInfo"
                              handleIndividualInputChange={
                                handleIndividualInputChange
                              }
                              editingFields={editingFields}
                              handleEditClick={handleEditClick}
                              handleCancelClick={handleCancelClick}
                              handleUpdateClick={handleUpdateClick}
                              updatedFields={updatedFields}
                              accountOverviewDetails={productInfo}
                              showType={showType}
                              setShowType={setShowType}
                              isTextarea={true}
                            />
                          </span>
                        </div>
                      </div>
                      {notePermission === "TRUE" && (
                        <div
                          id="notesSection"
                          className="lead_Information_mainContainer commonContainer_bg rounded-2  d-flex flex-column py-2 mt-2"
                        >
                          <div>
                            <Note
                              note={note}
                              addTitle={addTitle}
                              showButtons={showButtons}
                              setShowButtons={setShowButtons}
                              textFile={selectedNoteFiles}
                              showTitleNote={showTitleNote}
                              setShowTitleNote={setShowTitleNote}
                              handleTextAreaClick={handleTextAreaClick}
                              handleTextFileChange={handleTextFileChange}
                              handleSaveButton={insertNote}
                              handleAddTitleClick={handleAddTitleClick}
                              setNoteTitle={setNoteTitle}
                              noteInfo={noteInfo}
                              selectRecent={selectRecent}
                              handleSelectRecent={handleSelectRecent}
                              handleDeleteClick={deleteNoteDetails}
                              leadId={productId}
                              handleUpdateClick={updateNote}
                              setUpdateNotes={setUpdateNotes}
                              updateNotes={updateNotes}
                              setEditNote={setEditNote}
                              editNote={editNote}
                              handleChangeNoteTitle={handleChangeNoteTitle}
                              handleChangeNoteDescription={
                                handleChangeNoteDescription
                              }
                              setAddTitle={setAddTitle}
                              setNote={setNote}
                              selectedNoteFiles={selectedNoteFiles}
                              setSelectedNoteFiles={setSelectedNoteFiles}
                              onChildDataChange={handleChildDataChange}
                              moduleType={"Product"}
                              noteDeleteConfirmation={noteDeleteConfirmation}
                              setNoteDeleteConfirmation={
                                setNoteDeleteConfirmation
                              }
                            />

                            {showTitleNote && (
                              <textarea
                                rows={2}
                                className="notesClass px-2"
                                placeholder="Add note Title"
                                value={noteTitle}
                                onChange={(e) => setNoteTitle(e.target.value)}
                                style={{ width: "95%", marginTop: "10px" }}
                              />
                            )}
                          </div>
                        </div>
                      )}

                      <div
                        id="openActivity"
                        className="lead_Information_mainContainer commonContainer_bg rounded-2  d-flex flex-column py-2 mt-4"
                      >
                        <OpenActivity
                          openActivities={openActivities}
                          // getOverview={() => accountOverviewDetail(accountId)}
                          openActivitiesCalls={openActivitiesCalls}
                          openActivitiesMeetings={openActivitiesMeetings}
                          moduleName="Products"
                        />
                      </div>

                      <div
                        id="closeActivity"
                        className="lead_Information_mainContainer commonContainer_bg rounded-2  d-flex flex-column py-2 mt-4"
                      >
                        <CloseActivity
                          closeActivities={closeActivities}
                          // getOverview={() => accountOverviewDetail(accountId)}
                          closeActivitiesCalls={closedActivitiesCall}
                          closeActivitiesMeetings={closedActivitiesMeetings}
                        />
                      </div>
                      <div
                        id="contacts"
                        // className="lead_Information_mainContainer commonContainer_bg rounded-2  d-flex flex-column py-2 mt-4"
                      >
                        <ContactCommonTable
                          title="Contact"
                          buttonLabel="New"
                          buttonAction={() => {
                            setCurrentView("contacts");
                            setInnerPage("create");
                            setPastViewInfo((prev) => ({
                              ...prev,
                              view: currentView,
                              innerPage: innerPage,
                              undo: null,
                            }));
                          }}
                          showHeadings={showHeadingsContact}
                          setShowHeadings={setShowHeadingsContact}
                          selectedHeadings={selectedHeadingsContact}
                          data={filterContactDetails}
                          onRowClick={() => {}}
                          manageColumnsAction={() => {
                            setShowModalType("Manage Contact Columns");
                            setSearchColumn("");
                            setSavingHeadings(contactColumn);
                          }}
                          resetColumnSizeAction={() => {}}
                          setShowSelectedFilter={setShowSelectedFilter}
                          setSearchColumn={setSearchColumn}
                          setSavingHeadings={setSavingHeadings}
                        />{" "}
                      </div>
                      <div
                        id="leads"
                        // className="lead_Information_mainContainer commonContainer_bg rounded-2  d-flex flex-column py-2 mt-4"
                      >
                        <ContactCommonTable
                          title="Leads"
                          buttonLabel="New"
                          buttonAction={() => {
                            setCurrentView("leads");
                            setInnerPage("create");
                            setPastViewInfo((prev) => ({
                              ...prev,
                              view: currentView,
                              innerPage: innerPage,
                              undo: null,
                            }));
                          }}
                          showHeadings={showHeadingsLead}
                          setShowHeadings={setShowHeadingsLead}
                          selectedHeadings={selectedHeadingsLead}
                          data={filterLeadDetails}
                          onRowClick={() => {}}
                          manageColumnsAction={() => {
                            setShowModalType("Manage Lead Columns");
                            setSearchColumn("");
                            setSavingHeadings(leadsColumn);
                          }}
                          resetColumnSizeAction={() => {}}
                          setShowSelectedFilter={setShowSelectedFilter}
                          setSearchColumn={setSearchColumn}
                          setSavingHeadings={setSavingHeadings}
                        />{" "}
                      </div>

                      <div
                        id="deals"
                        // className="lead_Information_mainContainer commonContainer_bg rounded-2  d-flex flex-column py-2 mt-4"
                      >
                        <DealsCommonTable
                          title="Deals"
                          buttonLabel="New"
                          buttonAction={() => {
                            setCurrentView("deals");
                            setInnerPage("create");
                            setPastViewInfo((prev) => ({
                              ...prev,
                              view: currentView,
                              innerPage: innerPage,
                              undo: null,
                            }));
                          }}
                          showHeadings={showHeadingsDeals}
                          setShowHeadings={setShowHeadingsDeals}
                          selectedHeadings={selectedHeadingsDeals}
                          data={filterDealDetails}
                          onRowClick={() => {}}
                          manageColumnsAction={() => {
                            setShowModalType("Manage Deals Columns");
                            setSearchColumn("");
                            setSavingHeadings(dealsColumn);
                          }}
                          resetColumnSizeAction={() => {}}
                          setShowSelectedFilter={setShowSelectedFilter}
                          selectedDealsId={selectedDealsId}
                          setSelectedDealsId={setSelectedDealsId}
                          setSearchColumn={setSearchColumn}
                          setSavingHeadings={setSavingHeadings}
                          handleAssignDealsModule={handleAssignDealsModule}
                          handledeleteModuleDeal={(productId) =>
                            handledeleteModuleDeal(productId)
                          }
                        />
                      </div>
                      <div
                        id="accounts"
                        // className="lead_Information_mainContainer commonContainer_bg rounded-2  d-flex flex-column py-2 mt-4"
                      >
                        <ContactCommonTable
                          title="Accounts"
                          buttonLabel="New"
                          buttonAction={() => {
                            setCurrentView("accounts");
                            setInnerPage("create");
                            setPastViewInfo((prev) => ({
                              ...prev,
                              view: currentView,
                              innerPage: innerPage,
                              undo: null,
                            }));
                          }}
                          showHeadings={showHeadingsAccount}
                          setShowHeadings={setShowHeadingsAccount}
                          selectedHeadings={selectedHeadingsAccount}
                          data={filterAccountDetails}
                          onRowClick={() => {}}
                          manageColumnsAction={() => {
                            setShowModalType("Manage Account Columns");
                            setSearchColumn("");
                            setSavingHeadings(accountColumn);
                          }}
                          resetColumnSizeAction={() => {}}
                          setShowSelectedFilter={setShowSelectedFilter}
                          setSearchColumn={setSearchColumn}
                          setSavingHeadings={setSavingHeadings}
                        />{" "}
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <div
                      className={`timeline_main_container rounded-2 bg-white  d-flex flex-column gap-4  ${
                        isShrinked ? "w-100" : "w-75"
                      }`}
                    >
                      {/* <div className="upcomingAction_container w-100 flex-column h-25 py-2  d-flex  commonContainer_bg rounded-2">
                        <div className="header d-flex gap-2 p-2">
                          <span>
                            <img
                              src={hierachy}
                              alt="img"
                              height={20}
                              width={20}
                            />
                          </span>
                          <span>Upcoming Actions</span>
                        </div>
                        <div className="horizontal_line w-100  mx-1 border border-white"></div>

                        <div
                          className="d-flex justify-content-center align-items-center "
                          style={{ height: "20vh" }}
                        >
                          <h4 className="opacity-75 ">No upcoming actions</h4>
                        </div>
                      </div> */}

                      <div className="w-100 historyMain_Container flex-column   d-flex  commonContainer_bg rounded-2 ">
                        <div className="header d-flex gap-2 px-2 py-2 align-items-center">
                          <span>
                            <img
                              src={history}
                              alt="img"
                              height={20}
                              width={20}
                            />
                          </span>
                          <span>History</span>
                        </div>
                        <div className="horizontal_line w-100 mx-1"></div>
                        <div className="historyContent_mainContainer ">
                          {timeLineDataList &&
                          Object.keys(timeLineDataList).length > 0 ? (
                            Object.keys(timeLineDataList).map((date) => (
                              <div
                                className="history_container pt-3 d-flex flex-column"
                                key={date}
                              >
                                <span className="px-2 timelineBg_color border border-primary rounded-5 py-2 mx-5">
                                  {date}
                                </span>
                                {timeLineDataList[date].map((item, index) => (
                                  <div className="d-flex gap-2" key={index}>
                                    <span className="opacity-75 pt-5 ps-2">
                                      {item.createdTime}
                                    </span>
                                    <div className="d-flex flex-column align-items-center">
                                      <p className="vertical_line m-0 p-0"></p>
                                      <span>
                                        <img
                                          src={groupIcon2}
                                          alt="img"
                                          height={25}
                                          width={25}
                                        />
                                      </span>
                                    </div>
                                    <span className="pt-5 overflow_text w-75">
                                      {`${item.tmlDescription} ${item.tmlCreatedBy} ${date}`}
                                    </span>
                                  </div>
                                ))}
                              </div>
                            ))
                          ) : (
                            <div
                              className="text-center d-flex justify-content-center align-items-center opacity-75"
                              style={{ height: "20vh" }}
                            >
                              <h4>No Data available</h4>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
          <Modal
            show={showImageModal}
            className="d-flex justify-content-center align-items-center"
            aria-labelledby="example-custom-modal-styling-title"
            size="lg"
            id="commonLeadModal"
          >
            <Modal.Header
              closeButton
              onClick={() => {
                setShowImageModal(false);
                setUploadImg(false);
              }}
            >
              {productInfo.productImageData && (
                <button
                  className="imageAccButtonRemove"
                  onClick={() => {
                    setShowRemoveModal(true);
                  }}
                >
                  Remove
                </button>
              )}
            </Modal.Header>
            <Modal.Body className="ModalBody overflow-auto">
              <div className="d-flex flex-column gap-4">
                <div className="d-flex" style={{ position: "relative" }}>
                  {productInfo.productImageData && (
                    <a
                      href={uploadImg ? imageUrl : productInfo.productImageData}
                      download
                      style={{
                        position: "absolute",
                        right: "10px",
                        top: "5px",
                        background: "#b0b0b2",
                        borderRadius: "10px",
                        padding: "5px",
                        cursor: "pointer",
                      }}
                    >
                      <img src={downloadIcon} alt="img" />
                    </a>
                  )}

                  <img
                    src={
                      uploadImg
                        ? imageUrl
                        : productInfo.productImageData !== null
                        ? productInfo.productImageData
                        : productImg
                    }
                    alt="img"
                    className="profilePhoto cursorPointer"
                  />
                </div>
                <p>{deleteMsg}</p>
                <div className="d-flex w-100 gap-2">
                  <input
                    type="file"
                    id="productImage"
                    name="productImage"
                    accept="image/*"
                    className="file-control"
                    onChange={handleUploadImage}
                  />
                  <label htmlFor="productImage" className="imageAccButton">
                    <span>Upload Image</span>
                  </label>
                  <button
                    className="imageAccButtonSave"
                    onClick={() => {
                      uploadImage();
                    }}
                  >
                    {imageLoader ? (
                      <Lottie
                        options={defaultOptions("white")}
                        height="24px"
                        width="36px"
                      />
                    ) : (
                      "Save"
                    )}
                  </button>
                </div>
                {imageMsg && (
                  <span className="mb-0 small erro_msg">{imageMsg}</span>
                )}
              </div>
            </Modal.Body>
          </Modal>
          {/* ---------------------- */}
          <Modal
            show={showRemoveModal}
            className="d-flex justify-content-center align-items-center"
            aria-labelledby="example-custom-modal-styling-title"
            size="lg"
          >
            <Modal.Body className="ModalBody overflow-auto">
              <div className="d-flex flex-column gap-4">
                <p className="text-center mt-3">
                  Are you sure you want to remove?
                </p>
                <div className="d-flex w-100 gap-2">
                  <button
                    className="imageAccButton"
                    onClick={() => {
                      setShowRemoveModal(false);
                      setShowImageModal(true);
                    }}
                  >
                    Cancel
                  </button>
                  <button
                    className="imageAccButtonSave"
                    onClick={() => {
                      removeImage();
                    }}
                  >
                    {imageLoader ? (
                      <Lottie
                        options={defaultOptions("white")}
                        height="24px"
                        width="36px"
                      />
                    ) : (
                      "Remove"
                    )}
                  </button>
                </div>
              </div>
            </Modal.Body>
          </Modal>
          {/* ---------------------- */}
          <Modal
            show={deleteModal}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
          >
            <Modal.Header
              onClick={() => {
                setDeleteModal(false);
              }}
              style={{
                fontSize: "20px",
                fontWeight: "bold",
                background:
                  "linear-gradient(to right, rgba(64, 123, 255, 1), rgba(18, 167, 254, 1))",
                WebkitBackgroundClip: "text",
                backgroundClip: "text",
                WebkitTextFillColor: "transparent",
              }}
            >
              Are you sure you want to delete &nbsp;
              <span>{productInfo?.productName}</span>?
            </Modal.Header>
            <Modal.Body className="ModalBody overflow-auto">
              <div className="d-flex">
                <p className="mb-0 fw-bold">Note :&nbsp;</p>
                <span>
                  Any associated activities, visits, drafts will also be
                  deleted.
                </span>
              </div>
              <div className="d-flex flex-column gap-4">
                <div className="d-flex justify-content-end w-100 gap-4">
                  <button
                    className="cancelButton"
                    onClick={() => setDeleteModal(false)}
                  >
                    Cancel
                  </button>
                  <button
                    className="createContactButtonSave"
                    onClick={() => {
                      deleteProductDetails();
                    }}
                  >
                    Delete
                  </button>
                </div>
              </div>
            </Modal.Body>
          </Modal>
          {/* ---------------------- */}
          <ShareModal
            moduleType="PRODUCTS"
            setShareModal={setShareModal}
            shareModal={shareModal}
            id={productId}
            shareInfoModal={shareInfoModal}
            setShareInfoModal={setShareInfoModal}
            shareUserList={shareUserList}
            shareGroupList={shareGroupList}
            shareRoleList={shareRoleList}
            sharePublicList={sharePublicList}
            shareMemberIds={shareMemberIds}
            modalHeading={"Sharing Product"}
            module={"Products"}
          />
        </>
      );
    }
  };

  const handleColumnCheckboxChange = (name, isChecked) => {
    setSavingHeadings((prevHeadings) =>
      prevHeadings.map((heading) =>
        heading.name === name ? { ...heading, isChecked } : heading
      )
    );
  };

  const handleColumnCheckboxChangeContact = (name, isChecked) => {
    setSavingHeadingsContact((prevHeadings) =>
      prevHeadings.map((heading) =>
        heading.name === name ? { ...heading, isChecked } : heading
      )
    );
  };

  const handleColumnCheckboxChangeLead = (name, isChecked) => {
    setSavingHeadingsLead((prevHeadings) =>
      prevHeadings.map((heading) =>
        heading.name === name ? { ...heading, isChecked } : heading
      )
    );
  };

  const handleColumnCheckboxChangeDeal = (name, isChecked) => {
    setSavingHeadingsDeals((prevHeadings) =>
      prevHeadings.map((heading) =>
        heading.name === name ? { ...heading, isChecked } : heading
      )
    );
  };

  const handleColumnCheckboxChangeAccount = (name, isChecked) => {
    setSavingHeadingsAccount((prevHeadings) =>
      prevHeadings.map((heading) =>
        heading.name === name ? { ...heading, isChecked } : heading
      )
    );
  };
  return (
    <div
      onClick={() => {
        setShowHeadings(false);
        setShowSelectedFilter("");
        setSelectedField("");
        setShowOptionsProduct(false);
        setListVisible(false);
        setVendorListVisible(false);
        setIsChangeOwnerList(false);
      }}
    >
      {handleProductsView()}

      <ChangeOwnerModal
        modalType={modalType}
        setShowModalType={setShowModalType}
        selectedOwnerItem={selectedOwnerItem}
        handleChangeOwnerInputClick={handleChangeOwnerInputClick}
        setSelectedOwnerItem={setSelectedOwnerItem}
        isLeadOwnerModal={isLeadOwnerModal}
        setIsLeadOwnerModal={setIsLeadOwnerModal}
        isChangeOwnerList={isChangeOwnerList}
        userList={userList}
        handleChangeOwnerItemClick={handleChangeOwnerItemClick}
        emailNotification={emailNotification}
        setEmailNotification={setEmailNotification}
        handleEditClick={handleEditClick}
        handleUpdateClick={handleUpdateClick}
        setUpdatedFields={setUpdatedFields}
        existingContact={existingProduct}
        handleUpdateContact={updateProduct}
        onSelectedChangeOwner={handleSelectedLeadChange}
        apiLoader={apiLoader}
      />

      <Modal
        show={modalType === "Manage Columns"}
        id="ConvertModal"
        aria-labelledby="example-custom-modal-styling-title"
        className="withdrw_modal"
      >
        <Modal.Body>
          <div className="modalClass  position-relative">
            <div className="d-flex justify-content-between ">
              <h4 className="">Manage Columns</h4>
            </div>
            <div className="w-100 position-relative">
              <div className="mb-1 pt-2 position-relative w-100">
                <input
                  type="text"
                  className="assetSearchbar searchbar rounded-2 p-2 pe-5 common_input_bg w-100 "
                  placeholder="Search"
                  value={searchColumn}
                  onChange={(e) => {
                    if (e.target.value.charAt(0) !== " ") {
                      const value = e.target.value.replace(
                        /[^a-zA-Z ]+|(\s)\s+/g,
                        "$1"
                      );
                      setSearchColumn(value);
                      // const list = productColumn.filter((product) =>
                      //   product.name
                      //     .toLowerCase()
                      //     .includes(value.trim().toLowerCase())
                      // );
                      // setSavingHeadings(list);
                      // setSearchColumn(value);
                    }
                  }}
                />
                <span
                  className="position-absolute  mt-2 me-2 end-0 opacity-75"
                  onClick={() => {
                    setSearchColumn("");
                    setSavingHeadings(productColumn);
                  }}
                >
                  {searchColumn === "" ? (
                    <img src={searchIcon} alt="" width={20} />
                  ) : (
                    <div className="cursorPointer">X</div>
                  )}
                </span>
              </div>
              <div className=" opacity-75 columns_container">
                {savingHeadings
                  .filter((lead) =>
                    lead.name
                      .toLowerCase()
                      .includes(searchColumn.trim().toLowerCase())
                  )
                  .sort((a, b) => a.name.localeCompare(b.name))
                  .map((column) => {
                    return (
                      <div
                        key={column.name}
                        className="d-flex gap-2 py-2 rounded-2 align-items-center"
                      >
                        <div className="d-flex gap-2 ps-2">
                          <input
                            type="checkbox"
                            id={column.name}
                            name={column.name}
                            checked={column.isChecked}
                            onChange={(e) => {
                              handleColumnCheckboxChange(
                                column.name,
                                e.target.checked
                              );
                            }}
                            className="cursorPointer"
                          />
                          <label
                            className="small cursorPointer"
                            htmlFor={column.name}
                          >
                            {column.name}
                          </label>
                        </div>
                      </div>
                    );
                  })}
              </div>
              <aside className="float-end mt-2">
                <div className="d-flex align-items-center gap-4">
                  <button
                    className="cancel_btn bg-light"
                    onClick={() => {
                      setShowModalType("");
                      // setSavingHeadings(productColumn);
                      // setSearchColumn("");
                    }}
                  >
                    Cancel
                  </button>
                  <button
                    className="save_btn"
                    onClick={() => {
                      setSelectedHeadings(savingHeadings);
                      setTimeout(() => {
                        setShowModalType("");
                      }, 200);
                    }}
                  >
                    Save
                  </button>
                </div>
              </aside>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      {/* Delete Modal */}
      <Modal
        show={[
          "delete",
          "Mass Delete",
          "Mass Update",
          "Mass Transfer",
        ].includes(modalType)}
        aria-labelledby="contained-modal-title-vcenter"
        onClick={(e) => e.stopPropagation()}
        // size={modalType !== "delete" ? "md" : "lg"}
      >
        <Modal.Header
          className=" border-0 mb-0 d-flex flex-column align-items-start"
          style={{ backgroundColor: modalType !== "delete" && "#F2F2F2" }}
        >
          {modalType !== "delete" && (
            <div
              className={`mass_heading fs-4 ${
                modalType !== "Mass Delete" && "ps-4"
              }`}
            >
              {modalType}
            </div>
          )}
          {modalType === "delete" && (
            <div className="fs-5" style={{ color: "#3F7DFF" }}>
              Are you sure you want to delete {productInfo?.productName}?
            </div>
          )}
        </Modal.Header>
        <Modal.Body className="ModalBody">
          {modalType === "delete" ? (
            <>
              <div className="d-flex gap-2 pb-2 align-items-center">
                <span className="fw-bold">Note:</span>
                <span className="small pt-1">
                  Any associated activities, visits, drafts will also be
                  deleted.
                </span>
              </div>
            </>
          ) : modalType === "Mass Delete" ? (
            <>
              <div className="fs-5 pt-0 pb-2">
                Are you sure you want to delete selected item(s)?
              </div>
              <div className="small">
                <span className="fw-bold">Note: </span>{" "}
                <span>
                  {" "}
                  Any associated Activities, Solutions, Cases, Drafts will also
                  be Deleted
                </span>
              </div>
            </>
          ) : modalType === "Mass Update" ? (
            <div className="d-flex gap-2 mb-3 mt-2 align-items-center w-100">
              <div className="w-100">
                <div className="d-flex flex-column ps-1 align-items-center mx-4">
                  <label className="text-start w-100 opacity-75">
                    Select a field
                  </label>
                  <div
                    className="position-relative w-100 rounded-1"
                    style={{ border: "1px solid rgba(185, 185, 185, 0.5)" }}
                  >
                    <div
                      onClick={(e) => {
                        e.stopPropagation();
                        setListVisible(!listVisible);
                      }}
                      className={`cursorPointer d-flex justify-content-between w-100 my-1 p-1 border-0`}
                    >
                      <span className="">{selectedObj.name}</span>
                      <img
                        src={listVisible ? up : down}
                        alt="img"
                        height={20}
                        width={20}
                        className="pt-1"
                      />
                    </div>
                    {listVisible && (
                      <div
                        className="position-absolute field_container w-100"
                        onClick={(e) => {
                          e.stopPropagation();
                        }}
                      >
                        <div className="">
                          <label
                            htmlFor="selectField"
                            className="position-relative w-100"
                          >
                            <input
                              id="selectField"
                              type="text"
                              className="rounded-2 p-1 w-100 pe-4"
                              placeholder="Search"
                              aria-label="Username"
                              aria-describedby="basic-addon1"
                              value={searchField}
                              onChange={(e) => {
                                const value = e.target.value.replace(
                                  /[^a-zA-Z]/g,
                                  ""
                                );
                                setSearchField(value);

                                const filteredData = productColumn.filter(
                                  (field) =>
                                    field.name
                                      .toLowerCase()
                                      .includes(value.trim().toLowerCase())
                                );
                                setShowSearchFields(
                                  /^[a-zA-Z]+$/.test(value)
                                    ? filteredData
                                    : fieldLabels
                                );
                              }}
                            />
                            <img
                              className="position-absolute mt-1 me-2 end-0 pt-1 cursorPointer"
                              onClick={() => {
                                setSearchField("");
                              }}
                              src={searchIcon}
                              alt="search"
                              width={20}
                            />
                          </label>

                          <div className="w-100 rounded-2">
                            <div className="border border-1 field_container_dropdown rounded-1">
                              {showSearchFields
                                .sort((a, b) => a.name.localeCompare(b.name))
                                .map(
                                  (field) =>
                                    ![
                                      "Lead Owner",
                                      "Created By",
                                      "Modified By",
                                      "Secondary Email Id",
                                      "Email Opt Out",
                                      "Lead Name",
                                      "First Name",
                                      "Last Name",
                                      "Hash",
                                      "Handler",
                                      "Description",
                                      "Product Name",
                                      "Product Owner",
                                    ].includes(field.name) && (
                                      <div
                                        key={field.name}
                                        className="d-flex gap-2 p-2 cursorPointer rounded-2 align-items-center"
                                        onClick={() => {
                                          setClickedDropdownObj({
                                            label: "",
                                            value: "",
                                          });
                                          setMassUpdateValue("");
                                          setSelectedObj(field);

                                          if (field.value === "productActive") {
                                            setMassUpdateValue(false);
                                          }
                                          setListVisible(false);
                                        }}
                                      >
                                        <span className="ps-2">
                                          {field.name}
                                        </span>
                                      </div>
                                    )
                                )}
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="w-100 mx-auto d-flex justify-content-center mt-4">
                    <div className="w-100">
                      <label
                        className={`${
                          ["Sale End Date", "Sale Start Date"].includes(
                            selectedObj.name
                          )
                            ? "d-none"
                            : "opacity-75"
                        }`}
                        htmlFor="massValue"
                      >
                        Enter value
                      </label>
                      <div
                        className="position-relative w-100 border rounded-1"
                        style={{ border: "1px solid rgba(185, 185, 185, 0.5)" }}
                      >
                        {[
                          "Product Category",
                          "Manufacturer",
                          "Usage Unit",
                          "Tax",
                        ].includes(selectedObj.name) ? (
                          <Select
                            value={clickedDropdownObj}
                            menuPlacement="auto"
                            onChange={(selectedOption) => {
                              setClickedDropdownObj(selectedOption);
                              setMassUpdateValue(selectedOption.value);
                            }}
                            options={
                              selectedObj.name === "Product Category"
                                ? productCategoryOptions
                                : selectedObj.name === "Manufacturer"
                                ? manufacturerOptions
                                : selectedObj.name === "Tax"
                                ? taxOptions
                                : usageUnitOptions
                            }
                            styles={fieldDropdownStyles}
                            isSearchable={false}
                          />
                        ) : ["Sale End Date", "Sale Start Date"].includes(
                            selectedObj.name
                          ) ? (
                          <input
                            className="w-100 border-0 py-2 rounded-1"
                            type="date"
                            value={massUpdateValue}
                            onChange={(e) => {
                              setMassUpdateValue(e.target.value);
                            }}
                            style={{ outline: "none" }}
                            id="massValue"
                          />
                        ) : ["Product Active", "Taxable"].includes(
                            selectedObj.name
                          ) ? (
                          <input
                            className="cursorPointer ms-2 mt-1"
                            type="checkbox"
                            checked={massUpdateValue}
                            // onChange={(e) => {
                            //   setMassUpdateValue(e.target.value);
                            // }}

                            onChange={(e) => {
                              setMassUpdateValue(e.target.checked);
                            }}
                            style={{
                              outline: "none",
                              width: "1.2rem",
                              height: "1.2rem",
                            }}
                            id="massValue"
                          />
                        ) : (
                          <input
                            className="w-100 border-0 py-2 rounded-1"
                            type="text"
                            value={massUpdateValue
                              .replace(/[^0-9]/g, "")
                              .slice(0, 10)}
                            // onChange={(e) => {
                            //   setMassUpdateValue(e.target.value);
                            // }}

                            onChange={(e) => {
                              const trimmedValue = e.target.value.trimStart();
                              setMassUpdateValue(trimmedValue);
                            }}
                            style={{ outline: "none" }}
                            id="massValue"
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="w-100 px-4 py-2 mb-1">
              <label className="text-start w-100 opacity-75">Transfer To</label>
              <div
                className="d-flex input-with-clear position-relative mx-auto"
                onClick={(e) => {
                  handleOwnerInputClick(e);
                }}
              >
                <input
                  type="text"
                  className="ps-3 w-100 cursorPointer py-2"
                  value={selectedItem?.name}
                  readOnly
                />
                <span
                  className="position-absolute h-100 mass_transfer_user pt-1"
                  style={{ right: "0.1rem" }}
                >
                  <span
                    className="cursorPointer px-1"
                    onClick={() => setIsLeadOwnerModal(!isLeadOwnerModal)}
                  >
                    <img
                      src={userSearch}
                      alt="img"
                      width={20}
                      height={20}
                      className="ms-2 mt-2 me-2"
                    />
                  </span>
                </span>
              </div>
            </div>
          )}

          <div className={`pt-4  ${modalType !== "delete" && "pe-3"}`}>
            <div className="d-flex justify-content-end w-100 gap-4 pe-2">
              <button
                className="cancelButton px-4"
                onClick={() => {
                  setShowModalType("");
                  // setSelectedCheckboxes([]);
                  setShowSearchFields(fieldDropdowns);
                }}
              >
                Cancel
              </button>
              <button
                className="createContactButtonSave"
                onClick={() => {
                  if (modalType === "delete") {
                    // deleteProduct();
                  } else {
                    //  else if (modalType === "Mass Delete") {
                    //   deleteUpdateTransferMass();
                    // } else {
                    deleteUpdateTransferMass();
                  }
                }}
              >
                {modalType === "Mass Update"
                  ? "Update"
                  : modalType === "Mass Transfer"
                  ? "Done"
                  : "Delete"}
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={modalType === "Manage Contact Columns"}
        id="ConvertModal"
        aria-labelledby="example-custom-modal-styling-title"
        className="withdrw_modal"
      >
        <Modal.Body>
          <div className="modalClass  position-relative">
            <div className="d-flex justify-content-between ">
              <h4 className="">Manage Columns</h4>
              {/* <img
                src={crossIcon}
                alt="cross"
                style={{ cursor: "pointer" }}
                className="ms-1"
                onClick={() => {
                  setShowModalType("");
                }}
              /> */}
            </div>
            <div className="w-100 position-relative">
              <div className="mb-1 pt-2 position-relative w-100">
                <input
                  type="text"
                  className="assetSearchbar searchbar rounded-2 p-2 pe-5 common_input_bg w-100 "
                  placeholder="Search"
                  aria-label="Username"
                  aria-describedby="basic-addon1"
                  value={searchColumn}
                  onChange={(e) => {
                    if (e.target.value.charAt(0) !== " ") {
                      const value = e.target.value.replace(
                        /[^a-zA-Z ]+|(\s)\s+/g,
                        "$1"
                      );
                      setSearchColumn(value);
                    }
                  }}
                />
                <span
                  className="position-absolute  mt-2 me-2 end-0 opacity-75"
                  onClick={() => {
                    setSearchColumn("");
                    setSavingHeadings(contactColumn);
                  }}
                >
                  {searchColumn === "" ? (
                    <img src={searchIcon} alt="" width={20} />
                  ) : (
                    <div style={{ cursor: "pointer" }}>X</div>
                  )}
                </span>
              </div>
              <div className=" opacity-75 columns_container">
                {savingHeadingsContact
                  .filter((lead) =>
                    lead.name
                      .toLowerCase()
                      .includes(searchColumn.trim().toLowerCase())
                  )
                  .map((column) => {
                    return (
                      <div
                        key={column.name}
                        className="d-flex gap-2 py-2 rounded-2 align-items-center"
                      >
                        <div className="d-flex gap-2 ps-2">
                          <input
                            type="checkbox"
                            id={column.name}
                            name={column.name}
                            checked={column.isChecked}
                            onChange={(e) => {
                              handleColumnCheckboxChangeContact(
                                column.name,
                                e.target.checked
                              );
                            }}
                            className="cursorPointer"
                          />
                          <label
                            className="small cursorPointer"
                            htmlFor={column.name}
                          >
                            {column.name}
                          </label>
                        </div>
                      </div>
                    );
                  })}
              </div>
              <aside className="float-end mt-2">
                <div className="d-flex align-items-center gap-4">
                  <button
                    className="cancel_btn bg-light"
                    onClick={() => {
                      setShowModalType("");
                    }}
                  >
                    Cancel
                  </button>
                  <button
                    className="save_btn"
                    onClick={() => {
                      setSelectedHeadingsContact(savingHeadingsContact);
                      setTimeout(() => {
                        setShowModalType("");
                      }, 200);
                    }}
                  >
                    Save
                  </button>
                </div>
              </aside>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={modalType === "Manage Lead Columns"}
        id="ConvertModal"
        aria-labelledby="example-custom-modal-styling-title"
        className="withdrw_modal"
      >
        <Modal.Body>
          <div className="modalClass  position-relative">
            <div className="d-flex justify-content-between ">
              <h4 className="">Manage Columns</h4>
              {/* <img
                src={crossIcon}
                alt="cross"
                style={{ cursor: "pointer" }}
                className="ms-1"
                onClick={() => {
                  setShowModalType("");
                }}
              /> */}
            </div>
            <div className="w-100 position-relative">
              <div className="mb-1 pt-2 position-relative w-100">
                <input
                  type="text"
                  className="assetSearchbar searchbar rounded-2 p-2 pe-5 common_input_bg w-100 "
                  placeholder="Search"
                  aria-label="Username"
                  aria-describedby="basic-addon1"
                  value={searchColumn}
                  onChange={(e) => {
                    if (e.target.value.charAt(0) !== " ") {
                      const value = e.target.value.replace(
                        /[^a-zA-Z ]+|(\s)\s+/g,
                        "$1"
                      );
                      setSearchColumn(value);
                    }
                  }}
                />
                <span
                  className="position-absolute  mt-2 me-2 end-0 opacity-75"
                  onClick={() => {
                    setSearchColumn("");
                    setSavingHeadings(leadsColumn);
                  }}
                >
                  {searchColumn === "" ? (
                    <img src={searchIcon} alt="" width={20} />
                  ) : (
                    <div style={{ cursor: "pointer" }}>X</div>
                  )}
                </span>
              </div>
              <div className=" opacity-75 columns_container">
                {savingHeadingsLead
                  .filter((lead) =>
                    lead.name
                      .toLowerCase()
                      .includes(searchColumn.trim().toLowerCase())
                  )
                  .map((column) => {
                    return (
                      <div
                        key={column.name}
                        className="d-flex gap-2 py-2 rounded-2 align-items-center"
                      >
                        <div className="d-flex gap-2 ps-2">
                          <input
                            type="checkbox"
                            id={column.name}
                            name={column.name}
                            checked={column.isChecked}
                            onChange={(e) => {
                              handleColumnCheckboxChangeLead(
                                column.name,
                                e.target.checked
                              );
                            }}
                            className="cursorPointer"
                          />
                          <label
                            className="small cursorPointer"
                            htmlFor={column.name}
                          >
                            {column.name}
                          </label>
                        </div>
                      </div>
                    );
                  })}
              </div>
              <aside className="float-end mt-2">
                <div className="d-flex align-items-center gap-4">
                  <button
                    className="cancel_btn bg-light"
                    onClick={() => {
                      setShowModalType("");
                    }}
                  >
                    Cancel
                  </button>
                  <button
                    className="save_btn"
                    onClick={() => {
                      setSelectedHeadingsLead(savingHeadingsLead);
                      setTimeout(() => {
                        setShowModalType("");
                      }, 200);
                    }}
                  >
                    Save
                  </button>
                </div>
              </aside>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={modalType === "Manage Deals Columns"}
        id="ConvertModal"
        aria-labelledby="example-custom-modal-styling-title"
        className="withdrw_modal"
      >
        <Modal.Body>
          <div className="modalClass  position-relative">
            <div className="d-flex justify-content-between ">
              <h4 className="">Manage Columns</h4>
              {/* <img
                src={crossIcon}
                alt="cross"
                style={{ cursor: "pointer" }}
                className="ms-1"
                onClick={() => {
                  setShowModalType("");
                }}
              /> */}
            </div>
            <div className="w-100 position-relative">
              <div className="mb-1 pt-2 position-relative w-100">
                <input
                  type="text"
                  className="assetSearchbar searchbar rounded-2 p-2 pe-5 common_input_bg w-100 "
                  placeholder="Search"
                  aria-label="Username"
                  aria-describedby="basic-addon1"
                  value={searchColumn}
                  onChange={(e) => {
                    if (e.target.value.charAt(0) !== " ") {
                      const value = e.target.value.replace(
                        /[^a-zA-Z ]+|(\s)\s+/g,
                        "$1"
                      );
                      setSearchColumn(value);
                    }
                  }}
                />
                <span
                  className="position-absolute  mt-2 me-2 end-0 opacity-75"
                  onClick={() => {
                    setSearchColumn("");
                    setSavingHeadings(dealsColumn);
                  }}
                >
                  {searchColumn === "" ? (
                    <img src={searchIcon} alt="" width={20} />
                  ) : (
                    <div style={{ cursor: "pointer" }}>X</div>
                  )}
                </span>
              </div>
              <div className=" opacity-75 columns_container">
                {savingHeadingsDeals
                  .filter((lead) =>
                    lead.name
                      .toLowerCase()
                      .includes(searchColumn.trim().toLowerCase())
                  )
                  .map((column) => {
                    return (
                      <div
                        key={column.name}
                        className="d-flex gap-2 py-2 rounded-2 align-items-center"
                      >
                        <div className="d-flex gap-2 ps-2">
                          <input
                            type="checkbox"
                            id={column.name}
                            name={column.name}
                            checked={column.isChecked}
                            onChange={(e) => {
                              handleColumnCheckboxChangeDeal(
                                column.name,
                                e.target.checked
                              );
                            }}
                            className="cursorPointer"
                          />
                          <label
                            className="small cursorPointer"
                            htmlFor={column.name}
                          >
                            {column.name}
                          </label>
                        </div>
                      </div>
                    );
                  })}
              </div>
              <aside className="float-end mt-2">
                <div className="d-flex align-items-center gap-4">
                  <button
                    className="cancel_btn bg-light"
                    onClick={() => {
                      setShowModalType("");
                    }}
                  >
                    Cancel
                  </button>
                  <button
                    className="save_btn"
                    onClick={() => {
                      setSelectedHeadingsDeals(savingHeadingsDeals);
                      setTimeout(() => {
                        setShowModalType("");
                      }, 200);
                    }}
                  >
                    Save
                  </button>
                </div>
              </aside>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={modalType === "Manage Account Columns"}
        id="ConvertModal"
        aria-labelledby="example-custom-modal-styling-title"
        className="withdrw_modal"
      >
        <Modal.Body>
          <div className="modalClass  position-relative">
            <div className="d-flex justify-content-between ">
              <h4 className="">Manage Columns</h4>
              {/* <img
                src={crossIcon}
                alt="cross"
                style={{ cursor: "pointer" }}
                className="ms-1"
                onClick={() => {
                  setShowModalType("");
                }}
              /> */}
            </div>
            <div className="w-100 position-relative">
              <div className="mb-1 pt-2 position-relative w-100">
                <input
                  type="text"
                  className="assetSearchbar searchbar rounded-2 p-2 pe-5 common_input_bg w-100 "
                  placeholder="Search"
                  aria-label="Username"
                  aria-describedby="basic-addon1"
                  value={searchColumn}
                  onChange={(e) => {
                    if (e.target.value.charAt(0) !== " ") {
                      const value = e.target.value.replace(
                        /[^a-zA-Z ]+|(\s)\s+/g,
                        "$1"
                      );
                      setSearchColumn(value);
                    }
                  }}
                />
                <span
                  className="position-absolute  mt-2 me-2 end-0 opacity-75"
                  onClick={() => {
                    setSearchColumn("");
                    setSavingHeadings(accountColumn);
                  }}
                >
                  {searchColumn === "" ? (
                    <img src={searchIcon} alt="" width={20} />
                  ) : (
                    <div style={{ cursor: "pointer" }}>X</div>
                  )}
                </span>
              </div>
              <div className=" opacity-75 columns_container">
                {savingHeadingsAccount
                  .filter((lead) =>
                    lead.name
                      .toLowerCase()
                      .includes(searchColumn.trim().toLowerCase())
                  )
                  .map((column) => {
                    return (
                      <div
                        key={column.name}
                        className="d-flex gap-2 py-2 rounded-2 align-items-center"
                      >
                        <div className="d-flex gap-2 ps-2">
                          <input
                            type="checkbox"
                            id={column.name}
                            name={column.name}
                            checked={column.isChecked}
                            onChange={(e) => {
                              handleColumnCheckboxChangeAccount(
                                column.name,
                                e.target.checked
                              );
                            }}
                            className="cursorPointer"
                          />
                          <label
                            className="small cursorPointer"
                            htmlFor={column.name}
                          >
                            {column.name}
                          </label>
                        </div>
                      </div>
                    );
                  })}
              </div>
              <aside className="float-end mt-2">
                <div className="d-flex align-items-center gap-4 cursorPointer">
                  <button
                    className="cancel_btn bg-light"
                    onClick={() => {
                      setShowModalType("");
                    }}
                  >
                    Cancel
                  </button>
                  <button
                    className="save_btn"
                    onClick={() => {
                      setSelectedHeadingsAccount(savingHeadingsAccount);
                      setTimeout(() => {
                        setShowModalType("");
                      }, 200);
                    }}
                  >
                    Save
                  </button>
                </div>
              </aside>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <AccessModal
        setShowAccessModal={setShowAccessModal}
        showAccessModal={showAccessModal}
      />

      <LeadOwnerModal
        isLeadOwnerModal={isLeadOwnerModal}
        setIsLeadOwnerModal={setIsLeadOwnerModal}
        onSelectedLeadChange={handleSelectedLeadChange}
        defaultUser={isForHandler ? selectedHandlerItem : selectedItem}
      />
      <NotificationContainer />
    </div>
  );
}

export default Product;

import React from "react";
import { Modal } from "react-bootstrap";
import Lottie from "react-lottie";
import { defaultOptions } from "../../utils/defaultOptions";

const VendorDeleteModal = ({
  deleteModal,
  setDeleteModal,
  vendorInfoDetails,
  deleteVendor,
  apiLoader,
}) => {
  return (
    <Modal
      show={deleteModal}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
    >
      <Modal.Header
        onClick={() => {
          setDeleteModal(false);
        }}
        style={{
          fontSize: "20px",
          fontWeight: "bold",
          background:
            "linear-gradient(to right, rgba(64, 123, 255, 1), rgba(18, 167, 254, 1))",
          WebkitBackgroundClip: "text",
          backgroundClip: "text",
          WebkitTextFillColor: "transparent",
        }}
      >
        Are you sure you want to delete &nbsp;
        <span>{vendorInfoDetails?.vendorName}</span>?
      </Modal.Header>
      <Modal.Body className="ModalBody overflow-auto">
        <div className="d-flex">
          <p className="mb-0 fw-bold">Note :&nbsp;</p>
          <span>
            Any associated activities, visits, drafts will also be deleted.
          </span>
        </div>
        <div className="d-flex flex-column gap-4">
          <div className="d-flex justify-content-end w-100 gap-4">
            <button
              className="cancelButton"
              onClick={() => setDeleteModal(false)}
            >
              Cancel
            </button>
            <button
              className="createContactButtonSave"
              onClick={() => {
                deleteVendor();
              }}
              disabled={apiLoader}
            >
              {apiLoader ? (
                <Lottie
                  options={defaultOptions("white")}
                  height="24px"
                  width="36px"
                />
              ) : (
                "Delete"
              )}
            </button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default VendorDeleteModal;

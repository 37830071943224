import React, { useEffect, useRef, useState } from "react";
import CanvasJSReact from "@canvasjs/react-charts";
import { convertCamelCaseToReadable } from "../../../common/CommonComponent";

const CanvasJSChart = CanvasJSReact.CanvasJSChart;

const addSymbols = (e) => {
  const suffixes = ["", "K", "M", "B"];
  let order = Math.max(
    Math.floor(Math.log(Math.abs(e.value)) / Math.log(1000)),
    0
  );
  if (order > suffixes.length - 1) order = suffixes.length - 1;
  const suffix = suffixes[order];
  return (
    "₹" +
    CanvasJSReact.CanvasJS.formatNumber(e.value / Math.pow(1000, order)) +
    suffix
  );
};

const SingleColumnChart = ({ data }) => {
  const chartContainerRef = useRef(null);
  const [chartDimensions, setChartDimensions] = useState({
    width: 0,
    height: 0,
  });
  const [keys, setKeys] = useState([]);

  useEffect(() => {
    const headings =
      data?.length > 0 ? convertCamelCaseToReadable(Object.keys(data[0])) : [];
    setKeys(headings || []);

    const handleResize = () => {
      if (chartContainerRef.current) {
        setChartDimensions({
          width: chartContainerRef.current.offsetWidth,
          height: chartContainerRef.current.offsetHeight - 22,
        });
      }
    };

    // Initial resize
    handleResize();

    // Add resize event listener
    window.addEventListener("resize", handleResize);

    // Cleanup event listener on unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const options = {
    animationEnabled: true,
    theme: "light2",
    // title: {
    //   text: "Most Popular Social Networking Sites",
    // },
    axisX: {
      reversed: true,
    },

    axisY: {
      title: keys[1],
      includeZero: true,
      labelFormatter: addSymbols,
    },
    data: [
      {
        type: "bar",
        color: "rgb(33, 119, 254)",
        // dataPoints: [
        //   {
        //     y: data[0].sumOfAmount + data[0].percentage,
        //     label: keys[1] + "(₹)",
        //   },
        // ],
        dataPoints: data.map((item) => ({
          y: item.sumOfAmount,
          label: `${keys[1]}`,
          toolTipContent: `${keys[1]}:  ₹ ${item.sumOfAmount}  (${item.percentage}%)`,
        })),
        opacity: "1",
      },
    ],
  };

  return (
    <div ref={chartContainerRef} style={{ width: "100%", height: "100%" }}>
      {chartDimensions.width > 0 && chartDimensions.height > 0 && (
        <CanvasJSChart
          options={{
            ...options,
            width: chartDimensions.width,
            height: chartDimensions.height,
          }}
        />
      )}
    </div>
  );
  //   return <CanvasJSChart options={options} />;
};

export default SingleColumnChart;

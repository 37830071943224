import React, { useState, useEffect, useRef } from "react";
import { handleApiError } from "../utils/ApiUtils";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { URI } from "../constants";
import getHeaders from "../common/ApiHeader.js";
import { useSelector } from "react-redux";


const SearchScreenCommon = ({
  checkedItems,
  filterSearchCheckBoxes,
  setCheckedItems,
  labels,
  inputValue,
  setCurrentView,
  searchResults,
  setSearchResults,
  setInnerPage,
  setStoreModuleId,
  storeModuleId,
  setShowSearchInput
}) => {
  const reduxStore = useSelector((state) => state.reduxState);
  const navigate = useNavigate();
  const { loginUserId } = reduxStore;
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const dropdownRef = useRef(null);

  

  const moduleTableConfig = {
    accountDataSearch: {
      columns: [
        "Account Name",
        "Website",
        "Phone",
        "Module Name",
        "Account Owner",
      ],
      fields: ["accountName", "website", "phone", "moduleName", "accountOwner"],
    },
    dealDataSearch: {
      columns: [
        "Deal Name",
        "Amount",
        "Stage",
        "Closing Date",
        "Account Name",
        "Contact Name",
        "Module Name",
        "Deal Owner",
      ],
      fields: [
        "dealName",
        "amount",
        "stage",
        "closingDate",
        "accountName",
        "contactName",
        "moduleName",
        "dealOwner",
      ],
    },
    leadDataSearch: {
      columns: [
        "Full Name",
        "Email",
        "Company",
        "Phone",
        "Mobile",
        "Rating",
        "Fax",
        "Annual Revenue",
      ],
      fields: [
        "fullName",
        "email",
        "company",
        "phone",
        "mobile",
        "rating",
        "fax",
        "annualRevenue",
      ],
    },
    contactDataSearch: {
      columns: [
        "Full Name",
         "Phone",
        "Module Name",
        "Contact Owner",
        "Account Name",
        "Email",
      ],
      fields: [
        "fullName",
        "phone",
        "moduleName",
        "contactOwner",
        "accountName",
        "email",
      ],
    },
    campaignDataSearch: {
      columns: [
        "Campaign Name",
        "Module Name",
        "Type",
        "Campaign Owner",
        "Start Date",
        "End Date",
        "Status",
      ],
      fields: [
        "campaignName",
        "moduleName",
        "type",
        "campaignOwner",
        "startDate",
        "endDate",
        "status",
      ],
    },
    callDataSearch: {
      columns: [
        "Call Owner",
        "Call Duration",
        "Subject",
        "Module Name",
        "Call Type",
        "Call To",
        "Call Start Time",
        "Related To",
      ],
      fields: [
        "callOwner",
        "callDuration",
        "subject",
        "moduleName",
        "callType",
        "callTo",
        "callStartTime",
        "relatedTo"
      ],
    },
    quoteDataSearch: {
      columns: [
        "Quote Owner",
        "Deal Name",
        "Contact Name",
        "Accout Name",
        "Module Name",
        "Grand Total",
        "Subject",
        "Quote Stage",
      ],
      fields: [
        "quoteOwner",
        "dealName",
        "contactName",
        "accountName",
        "moduleName",
        "grandTotal",
        "subject",
        "quoteStage",
      ],
    },
    productDataSearch: {
      columns: [
        "Product Name",
        "Product Owner",
        "Product Code",
        "Module Name",
        "Product Active",
      ],
      fields: [
        "productName",
        "productOwner",
        "productCode",
        "moduleName",
        "productActive",
      ],
    },
    meetingDataSearch: {
      columns: ["Contact Name", "Host", "Related To", "Module Name", "Title"],
      fields: ["contactName", "host", "relatedTo", "moduleName", "title"],
    },
    invoiceDataSearch: {
      columns: [
        "Invoice Owner",
        "Invoice Date",
        "Contact Name",
        "Module Name",
        "Account Name",
        "Subject",
        "Status",
        "Grand Total",
      ],
      fields: [
        "invoiceOwner",
        "invoiceDate",
        "contactName",
        "moduleName",
        "accountName",
        "subject",
        "status",
        "grandTotal",
      ],
    },
    purchaseOrderDataSearch: {
      columns: [
        "Purchase Order Owner",
        "Contact Name",
        "Subject",
        "Module Name",
        "Vendor Name",
        "Status",
        "Grand Total",
      ],
      fields: [
        "purchaseOrderOwner",
        "contactName",
        "subject",
        "moduleName",
        "vendorName",
        "status",
        "grandTotal",
      ],
    },
    salesOrderDataSearch: {
      columns: [
        "Sales Order Owner",
        "Contact Name",
        "Subject",
        "Module Name",
        "Account Name",
        "Deal Name",
        "Status",
        "Grand Total",
      ],
      fields: [
        "salesOrderOwner",
        "contactName",
        "subject",
        "moduleName",
        "accountName",
        "dealName",
        "status",
        "grandTotal",
      ],
    },
    taskDataSearch: {
      columns: [
        "Task Owner",
        "Subject",
        "Module Name",
        "Status",
        "Due Date",
        "Related To",
        "Priority",
      ],
      fields: [
        "taskOwner",
        "subject",
        "moduleName",
        "status",
        "dueDate",
        "relatedTo",
        "priority",
      ],
    },
    vendorDataSearch: {
      columns: [
        "Vendor Name",
        "Email",
        "Phone",
        "Module Name",
        "website",
        "Country",
        "Category",
      ],
      fields: [
        "vendorName",
        "email",
        "phone",
        "moduleName",
        "website",
        "country",
        "category",
      ],
    },
  };

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const handleCheckboxChange = (e) => {
    const { value, checked } = e.target;
    if (value === "ALL") {
      const newCheckedItems = filterSearchCheckBoxes.reduce((acc, checkbox) => {
        acc[checkbox.value] = checked;
        return acc;
      }, {});
      setCheckedItems(newCheckedItems);
      getGeneralSearchList(newCheckedItems);
    } else {
      setCheckedItems((prev) => {
        const updatedItems = {
          ...prev,
          [value]: checked,
        };
        const allChecked = filterSearchCheckBoxes
          .slice(1) // Exclude "ALL"
          .every((checkbox) => updatedItems[checkbox.value]);
        return { ...updatedItems, ALL: allChecked };
      });
      getGeneralSearchList({
        ...checkedItems,
        [value]: checked,
        ALL: false, // Ensure "ALL" is set to false when any individual checkbox is selected
      });
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setDropdownOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const getGeneralSearchList = async (selectedItems) => {
    let moduleName;
    const allUnchecked = Object.values(selectedItems).every(
      (checked) => !checked
    );
    if (selectedItems["ALL"] || allUnchecked) {
      moduleName = ["All"];
    } else {
      moduleName = Object.keys(selectedItems).filter(
        (key) => selectedItems[key]
      );
    }
    const requestBody = {
      loggedInUserId: loginUserId,
      searchString: inputValue,
      moduleName: moduleName,
    };
    await axios
      .post(URI.generalSearch, requestBody, {
        headers: getHeaders(true),
      })

      .then((response) => {
        if (response.data.status === 200) {
          setCurrentView("showScreen");
          setSearchResults(response.data.data);
        }
      })
      .catch(async (error) => {
        handleApiError(error, navigate);
      });
  };

  const emptyModules = [];

  Object.entries(searchResults).forEach(([module, data]) => {
    if (data?.length === 0) {
      emptyModules.push(module);
    }
  });

  const formatModuleName = (module) => {
    return (
      module.charAt(0).toUpperCase() +
      module.slice(1).replace("DataSearch", "s")
    );
  };


  const handleCellClick = (field, item) => {
    const fieldToModuleMapping = {
        accountName: 'accounts',
        dealName: 'deals',
        contactName: 'contacts',
        quoteName: 'quotes',
        leadName: 'leads',
        campaignName: 'campaigns',
        callOwner: 'calls',
        vendorName: 'vendors',
        salesOrderName: 'sales_orders',
        purchaseOrderOwner: 'purchase_orders',
        productName: 'products',
        meetingName: 'meetings',
        invoiceName: 'invoices',
        taskName: 'tasks'
    };

    const moduleName = fieldToModuleMapping[field] || field;

    setInnerPage("overview");


    const getCurrentView = (moduleName) => {
        switch (moduleName) {
            case "sales_orders":
                return "salesOrders";
            case "purchase_orders":
                return "purchaseOrders";
            default:
                return moduleName;
        }
    };

    setCurrentView(getCurrentView(moduleName));
    setShowSearchInput(false);

    const idMapping = {
        accounts: item.accountId,
        deals: item.dealId,
        contacts: item.contactId,
        quotes: item.quoteId,
        leads: item.leadId,
        campaigns: item.campaignId,
        calls: item.callId,
        vendors: item.vendorId,
        sales_orders: item.salesOrdId,
        purchase_orders: item.purchaseOrdId,
        products: item.productId,
        meetings: item.meetingId,
        invoices: item.invoiceId,
        tasks: item.taskId
    };
 const id = idMapping[moduleName];
    setStoreModuleId(id);
};

return (
    <div style={{ minHeight: "100vh" }}>
      <div
        className="custom-dropdown float-end mt-2"
        ref={dropdownRef}
      >
        <button
          className="checkboxButton "
          type="button"
          onClick={toggleDropdown}
        >
          <div className="overflow_text labelText"> {labels}</div>
          <span className="ms-2 opacity-50" style={{ fontSize: "25px" }}>
            |
          </span>
        </button>
        {dropdownOpen && (
          <div
            className="dropdown-menu show"
            style={{ width: "14vw", height: "40vh", overflowY: "scroll" }}
          >
            {filterSearchCheckBoxes.map((checkbox, index) => (
              <div key={index} className="w-100 px-2 cursorPointer">
                <div className="form-check">
                  <input
                    className="form-check-input cursorPointer"
                    type="checkbox"
                    value={checkbox.value}
                    id={`checkbox-${checkbox.value}`}
                    checked={checkedItems[checkbox.value] || false}
                    onChange={handleCheckboxChange}
                  />
                  <label
                    className="form-check-label cursorPointer"
                    htmlFor={`checkbox-${checkbox.value}`}
                    style={{ fontSize: "15px" }}
                  >
                    {checkbox.label}
                  </label>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
      <div>
        {Object.entries(searchResults).map(([module, data]) => {
          const config = moduleTableConfig[module];
          if (!config) {
            return null; // Skip modules without configuration
          }
    return (
            <div key={module} className="mt-2">
             {data.length > 0 && (
        <>
          <div
            className="mt-4 ms-1"
            style={{
              fontSize: "20px",
              background:
                "linear-gradient(to right, rgba(64, 123, 255, 1), rgba(18, 167, 254, 1))",
              WebkitBackgroundClip: "text",
              backgroundClip: "text",
              WebkitTextFillColor: "transparent",
            }}
          >
            {formatModuleName(module)}
          </div>
          <table
            className="w-100 mt-2 searchTable p-2 bordered"
            style={{ borderRadius: "20px" }}
          >
            <thead>
              <tr>
                {config.columns.map((col, index) => (
                  <th
                    key={index}
                    style={{ width: `${100 / config.columns.length}%` }}
                  >
                    {col}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
  {data.map((item, index) => (
    <tr key={index}>
      {config.fields.map((field, idx) => (
        <td
          key={idx}
          className={`${idx === 0 ? "searchedData overflow_text" : "overflow_text"} ${
            storeModuleId && storeModuleId.rowIndex === item.id && storeModuleId.field === field ? "clickedCell" : ""
          }`}
          style={{
            width: `${100 / config.fields.length}%`,
          }}
          onClick={() => handleCellClick(item.moduleName, item)}
        >
          {field === "productActive" ? (
            item[field] ? (
              <td style={{border:"none"}}>✓</td>
            ) : (
            <td style={{border:"none"}}>✕</td>
            )
          ) : field === "moduleName" ? (
            item[field]
              ?.charAt(0)
              .toUpperCase() + item[field]?.slice(1) || "--"
          ) : item[field] === null || item[field] === "" ? (
            "--"
          ) : item[field] === false ? (
            "false"
          ) : (
            item[field]
          )}
        </td>
      ))}
    </tr>
  ))}
</tbody>





          </table>
        </>
      )}
            </div>
          );
        })}
        {emptyModules.length > 0 && (
          <div
            className="bg-white mt-2 p-2 rounded-2 ms-1"
            style={{ fontSize: "16px" }}
          >
            No matching records found for
            <span style={{ fontSize: "16px", fontWeight: "bold" }}>
              {" "}
              {emptyModules.map(formatModuleName).join(", ")}
            </span>
          </div>
        )}
      </div>
    </div>
  );
};

export default SearchScreenCommon;

import { useEffect } from "react";

const useScrollToUndo = (pastViewInfo, setPastViewInfo) => {
  useEffect(() => {
    // we will get pastViewInfo.undo when we are routing to new creation page
    if (pastViewInfo?.undo && pastViewInfo?.undo !== "") {
      const scrollToElement = (id) => {
        // the same name given in div id for particular section so when we come back it will scroll to the section
        const element = document.getElementById(id);
        if (element) {
          const top = element.getBoundingClientRect().top + window.scrollY;
          window.scrollTo({
            top: top,
            behavior: "smooth",
          });
        }
      };

      setTimeout(() => scrollToElement(pastViewInfo?.undo), 100); // dom needs to be created so delay

      // setPastViewInfo((prev) => ({
      //   ...prev,
      //   undo: null, // after scrolling we are making it null easy for next routing
      // }));

      setPastViewInfo(null);
    }
  }, []);
};

export default useScrollToUndo;

import React, { useEffect, useState, useRef } from "react";
import Select from "react-select";
import "./account.css";
import groupIcon from "../../Assets/De-Active/Account.svg";
import { URI } from "../../constants";
import axios from "axios";
import noteOne from "../../Assets/notebook-01.svg";
import cancelIcon from "../../Assets/Group 72.svg";
import updateIcon from "../../Assets/Group 71.svg";
import EditableInput from "../../common/EditableInput";
// import hierachy from "../../Assets/hierarchy.svg";
import history from "../../Assets/history.svg";
import groupIcon2 from "../../Assets/Group 74.svg";
import down from "../../Assets/Down31.svg";
import up from "../../Assets/Up.svg";
import userSearch from "../../Assets/user-search-01.svg";
import downloadIcon from "../../Assets/Frame (5).svg";
import { Modal } from "react-bootstrap";
import Pagination from "../../common/Pagination";
import tableFilterIcon from "../../Assets/filterIcon.svg";
import { Dropdown } from "react-bootstrap";
import SearchIcon from "../../Assets/newSearch.svg";
import logoConvert from "../../Assets/logoConvert.svg";
import {
  ratingOptions,
  accountTypeOptions,
  ownershipOptions,
  industryOptions,
  contactColumn,
  pageOption,
  massOptions,
} from "../../common/CommonData";
import {
  websiteValidation,
  validateMobileNumber,
  validateAccountNumber,
  validateNumberOfEmployees,
  validateTicketSymbol,
  validateAnnualRevenue,
  validateSICCode,
  validateCityIndia,
  validateStateIndia,
  validateCountryName,
  validateZipCode,
  validateFirstName,
  removeExtraSpaces,
  customCommonStyles,
  removeLeadingZeros,
  validateAccountSite,
} from "../../common/CommonComponent";
import accountsIcon from "../../Assets/De-Active/Account.svg";
import LeadOwnerModal from "../../common/LeadOwnerModal";
import arrowUp from "../../Assets/square_arrow_up.svg";
import arrowDown from "../../Assets/square_arrow_down.svg";
import pin from "../../Assets/Pin_column.svg";
import unPin from "../../Assets/Unpin.svg";
import cancel from "../../Assets/cancel_circle.svg";
import filterLine from "../../Assets/filterLine.svg";
import searchIcon from "../../Assets/search.svg";
import filterMenu from "../../Assets/filterMenu.svg";
import Clone from "../../Assets/Clone.svg";
import Share from "../../Assets/ShareIcon.svg";
import Delete from "../../Assets/DeleteIconNew.svg";
import Note from "../../common/commonRelatedList/Note";
import attachIcon from "../../Assets/file-attachment.svg";
import prodIcon from "../../Assets/product.svg";
import openActivityIcon from "../../Assets/openActivity.svg";
import closeActivityIcon from "../../Assets/closeActivity.svg";
import inviteIcon from "../../Assets/invite.svg";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import "react-notifications/lib/notifications.css";
import AccountAvatar from "../../Assets/Account_Avatar.png";
import Product from "../../common/commonRelatedList/ProductList";
import OpenActivity from "../../common/commonRelatedList/OpenActivity";
import CloseActivity from "../../common/commonRelatedList/CloseActivity";
import ShareModal from "../../common/ShareModal";
import ContactCommonTable from "../../common/ContactCommonTable";
import QuotesCommonTable from "../../common/QuotesCommonTable";
import InvoicesCommonTable from "../../common/InvoicesCommonTable";
import SalesCommonTable from "../../common/SalesCommonTable";
import DealsCommonTable from "../../common/DealsCommonTable";
import { useSelector } from "react-redux";
import Loader from "../../common/commonRelatedList/Loader";
import noDataAccount from "../../Assets/Account-rafiki 1.svg";
import AccessModal from "../../common/AccessModal";
import ChangeOwnerModal from "../../common/commonRelatedList/ChangeOwnerModal";
import {
  leftArrow,
  rightAngleArrow,
  leftAngleArrow,
  tick,
  expandArrow,
  shrinkArrow,
} from "../../common/commonSVGs/commonSVGIcons.js";
import { EXPLORER_URL } from "../../constants/DataConst.js";
import useScrollToUndo from "../../hooks/useScrollToUndo.js.js";
import Lottie from "react-lottie";
import { defaultOptions } from "../../utils/defaultOptions.js";
import getHeaders from "../../common/ApiHeader.js";
import { handleApiError } from "../../utils/ApiUtils";
import { useNavigate } from "react-router-dom";

function Accounts({
  setCurrentView,
  setInnerPage,
  innerPage,
  currentView,
  permissionList,
  notePermission,
  fetchAccInfo,
  setFetchAccInfo,
  pastViewInfo,
  setPastViewInfo,
  fetchProdInfo,
  storeModuleId,
}) {
  const navigate = useNavigate();
  // const [innerPage, setInnerPage] = useState("list");
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [totalItems, setTotalItems] = useState(0); // Total number of items
  const [deleteMsg, setDeleteMsg] = useState(""); // Total number of items
  const [selectedFiles, setSelectedFiles] = useState(null);
  const [imageUrl, setImageUrl] = useState(null);
  const [uploadImg, setUploadImg] = useState(false);
  const [showOptionsLead, setShowOptionsLead] = useState(false);
  const [showButtons, setShowButtons] = useState(false);
  const [selectedNoteFiles, setSelectedNoteFiles] = useState([]);
  const [showTitleNote, setShowTitleNote] = useState(false);
  const [note, setNote] = useState("");
  const [shareModal, setShareModal] = useState(false);
  const [noteInfo, setNoteInfo] = useState([]);
  const [selectRecent, setSelectRecent] = useState("ALL");
  const [addTitle, setAddTitle] = useState("");
  const [mandatoryMsg, setMandatoryMsg] = useState("");
  const [websiteMsg, setWebsiteMsg] = useState("");
  const [accountNumberMsg, setAccountNumberMsg] = useState("");
  const [tickerMsg, setTickerMsg] = useState("");
  const [employeesMsg, setEmployeeMsg] = useState("");
  const [annualRevenueMsg, setAnnualRevenueMsg] = useState("");
  const [sicCodeMsg, setSicCodeMsg] = useState("");
  const [billingCityMsg, setBillingCityMsg] = useState("");
  const [shippingCityMsg, setShippingCityMsg] = useState("");
  const [billingStateMsg, setBillingStateMsg] = useState("");
  const [shippingStateMsg, setShippingStateMsg] = useState("");
  const [billingCountryMsg, setBillingCountryMsg] = useState("");
  const [shippingCountryMsg, setShippingCountryMsg] = useState("");
  const [codeMsg, setCodeMsg] = useState("");
  const [shippingCodeMsg, setShippingCodeMsg] = useState("");
  const [productsList, setProductsList] = useState([]);
  const [openActivities, setOpenActivities] = useState([]);
  const [openActivitiesCalls, setOpenActivitiesCalls] = useState([]);
  const [openActivitiesMeetings, setOpenActivitiesMeetings] = useState([]);
  const [closedActivitiesCall, setClosedActivitiesCall] = useState([]);
  const [closedActivitiesMeetings, setClosedActivitiesMeetings] = useState([]);
  const [closeActivities, setCloseActivities] = useState([]);
  const [deleteModal, setDeleteModal] = useState(false);
  const [selectedProductId, setSelectedProductId] = useState([]);
  const [selectedQuoteId, setSelectedQuoteId] = useState([]);
  const [selectedSalesId, setSelectedSalesId] = useState([]);
  const [selectedInvoiceId, setSelectedInvoiceId] = useState([]);
  const [selectedDealsId, setSelectedDealsId] = useState([]);
  const [selectedContactsId, setSelectedContactsId] = useState([]);
  const [isShrinked, setIsShrinked] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [imageMsg, setImageMsg] = useState("");
  const [deletedImg, setDeletedImg] = useState("");
  const [searchAccount, setSearchAccount] = useState("");
  const [modalAccountDetails, setModalAccountDetails] = useState([]);
  const [showTooltipAnnualRevenue, setShowTooltipAnnualRevenue] =
    useState(false);
  const [tooltipContent, setTooltipContent] = useState("");
  const [noteDeleteConfirmation, setNoteDeleteConfirmation] = useState(false);

  const handleSearchAccount = (e) => {
    const { value } = e.target;
    if (value.trim() !== "" || value === "") {
      setSearchAccount(value);
    }
  };

  const filteredAccounts = modalAccountDetails.filter((item) =>
    item.accountName?.toLowerCase().includes(searchAccount?.toLowerCase())
  );
  const allowedFileTypes = [
    "image/png",
    "image/jpg",
    "image/jpeg",
    "image/gif",
  ];
  const maxSizeInBytes = 2 * 1024 * 1024; // 2 MB in bytes
  const fileInputRef = useRef(null);
  const [isDragging, setIsDragging] = useState(false);
  const [apiLoader, setApiLoader] = useState(false);

  const fieldDropdownStyles = {
    menu: (base) => ({
      ...base,
      width: "100%",
    }),
    option: (provided) => ({
      ...provided,
      // backgroundColor: "#EAEAEA",
      color: "#000",
      // backgroundColor: 'rgba(33, 119, 254, 0.1)',
      width: "100%",
    }),
    control: (baseStyles) => ({
      ...baseStyles,
      border: 0,
      // backgroundColor: 'rgba(33, 119, 254, 0.1)',
      height: "20px",
      borderRadius: "10px",
      width: "100%",
      boxShadow: "none",
    }),
    input: (base) => ({
      ...base,
      color: "#FFFFFF",
    }),
    singleValue: (defaultStyles) => ({ ...defaultStyles, color: "#000" }),

    menuList: (provided) => ({
      ...provided,
      paddingTop: 0,
      paddingBottom: 0,
      "&::-webkit-scrollbar": {
        width: 5,
      },

      "&::-webkit-scrollbar-thumb": {
        background: "#316AFF",
        borderRadius: "10px",
      },
    }),
  };

  // const contactColumn = [
  //   {
  //     name: "Contact Name",
  //     isChecked: true,
  //     value: "contactName",
  //   },

  //   {
  //     name: "Assistant",
  //     isChecked: true,
  //     value: "assistant",
  //   },

  //   {
  //     name: "Assistant Phone",
  //     isChecked: true,
  //     value: "assistantPhone",
  //   },

  //   {
  //     name: "Description",
  //     isChecked: false,
  //     value: "contDescription",
  //   },

  //   {
  //     name: "Account Name",
  //     isChecked: true,
  //     value: "accountName",
  //   },
  //   {
  //     name: "Email",
  //     isChecked: true,
  //     value: "emailId",
  //   },
  //   {
  //     name: "Phone",
  //     isChecked: false,
  //     value: "phone",
  //   },

  //   {
  //     name: "Contact Owner",
  //     isChecked: false,
  //     value: "contactOwner",
  //   },
  //   {
  //     name: "Department",
  //     isChecked: false,
  //     value: "department",
  //   },
  //   // {
  //   //   name: "Contact Source",
  //   //   isChecked: true,
  //   //   value: "ContactSource",
  //   // },
  //   {
  //     name: "First Name",
  //     isChecked: false,
  //     value: "firstName",
  //   },
  //   {
  //     name: "Last Name",
  //     isChecked: false,
  //     value: "lastName",
  //   },
  //   {
  //     name: "Fax",
  //     isChecked: false,
  //     value: "fax",
  //   },
  //   {
  //     name: "Mobile",
  //     isChecked: false,
  //     value: "mobile",
  //   },
  //   {
  //     name: "Job Title",
  //     isChecked: false,
  //     value: "title",
  //   },
  //   {
  //     name: "Website",
  //     isChecked: false,
  //     value: "website",
  //   },
  //   {
  //     name: "Lead Status",
  //     isChecked: false,
  //     value: "leadStatus",
  //   },

  //   {
  //     name: "Lead Source",
  //     isChecked: false,
  //     value: "leadSource",
  //   },
  //   {
  //     name: "Industry",
  //     isChecked: false,
  //     value: "industryType",
  //   },
  //   {
  //     name: "No. of Employees",
  //     isChecked: false,
  //     value: "noOfEmployees",
  //   },
  //   {
  //     name: "Annual Revenue",
  //     isChecked: false,
  //     value: "annualRevenue",
  //   },
  //   {
  //     name: "Rating",
  //     isChecked: false,
  //     value: "rating",
  //   },
  //   {
  //     name: "Secondary Mail",
  //     isChecked: false,
  //     value: "secondaryEmailId",
  //   },
  //   {
  //     name: "Created By",
  //     isChecked: false,
  //     value: "createdBy",
  //   },
  //   {
  //     name: "Modified By",
  //     isChecked: false,
  //     value: "modifiedBy",
  //   },
  //   {
  //     name: "Skype ID",
  //     isChecked: false,
  //     value: "skypeId",
  //   },
  //   {
  //     name: "Twitter",
  //     isChecked: false,
  //     value: "twitter",
  //   },
  //   {
  //     name: "Country",
  //     isChecked: false,
  //     value: "country",
  //   },
  //   {
  //     name: "State",
  //     isChecked: false,
  //     value: "state",
  //   },
  //   {
  //     name: "City",
  //     isChecked: false,
  //     value: "city",
  //   },
  //   {
  //     name: "street",
  //     isChecked: false,
  //     value: "Street",
  //   },
  //   {
  //     name: "Zip Code",
  //     isChecked: false,
  //     value: "zipCode",
  //   },
  // ];

  const fieldIndividualDropdowns = [
    {
      name: "Account Owner",
      type: "owner",
      value: "accountOwner",
    },

    {
      name: "Account Name",
      type: "input",
      value: "accountName",
    },
    {
      name: "Account Site",
      type: "input",
      value: "accountSiteInfo",
    },
    {
      name: "Parent Account",
      type: "readOnly",
      value: "parentAccountName",
    },
    {
      name: "Account Number",
      type: "input",
      value: "accountNumberInfo",
    },
    {
      name: "Account Type",
      type: "dropdown",
      value: "accountTypeInfo",
    },
    {
      name: "Industry",
      type: "dropdown",
      value: "industryInfo",
    },
    {
      name: "Annual Revenue",
      type: "input",
      value: "annualRevenueInfo",
    },
    {
      name: "Created By",
      type: "readOnly",
      value: "createdBy",
    },
    {
      name: "Rating",
      type: "dropdown",
      value: "ratingInfo",
    },
    {
      name: "Phone",
      type: "input",
      value: "phoneInfo",
    },
    {
      name: "Fax",
      type: "input",
      value: "faxInfo",
    },
    {
      name: "Website",
      type: "input",
      value: "website",
    },
    {
      name: "Ticker Symbol",
      type: "input",
      value: "tickerSymbolInfo",
    },

    {
      name: "Ownership",
      type: "dropdown",
      value: "ownershipInfo",
    },
    {
      name: "Employees",
      type: "input",
      value: "employeesInfo",
    },
    {
      name: "SIC Code",
      type: "input",
      value: "sicCodeInfo",
    },
    {
      name: "Modified By",
      type: "readOnly",
      value: "modifiedBy",
    },
    {
      name: "Billing Street",
      type: "input",
      value: "billingStreetInfo",
    },
    {
      name: "Billing State",
      type: "input",
      value: "billingStateInfo",
    },
    {
      name: "Billing City",
      type: "input",
      value: "billingCityInfo",
    },
    {
      name: "Billing Code",
      type: "input",
      value: "billingCodeInfo",
    },

    {
      name: "Billing Country",
      type: "input",
      value: "billingCountryInfo",
    },
    {
      name: "Shipping Street",
      type: "input",
      value: "shippingStreetInfo",
    },
    {
      name: "Shipping State",
      type: "input",
      value: "shippingStateInfo",
    },
    {
      name: "Shipping City",
      type: "input",
      value: "shippingCityInfo",
    },
    {
      name: "Shipping Code",
      type: "input",
      value: "shippingCodeInfo",
    },

    {
      name: "Shipping Country",
      type: "input",
      value: "shippingCountryInfo",
    },
  ];

  const quotesColumn = [
    {
      name: "Subject",
      isChecked: true,
      value: "subject",
    },
    {
      name: "Quote Stage",
      isChecked: true,
      value: "quoteStage",
    },

    {
      name: "Grand Total",
      isChecked: true,
      value: "grandTotal",
    },
    {
      name: "Contact Name",
      isChecked: true,
      value: "contactName",
    },
    {
      name: "Account Name",
      isChecked: false,
      value: "accountName",
    },
    {
      name: "Quotes Owner",
      isChecked: false,
      value: "quoteOwner",
    },

    {
      name: "Deal Name",
      isChecked: true,
      value: "dealName",
    },
    {
      name: "Valid Until",
      isChecked: false,
      value: "validUntil",
    },

    {
      name: "Billing City",
      isChecked: false,
      value: "billingCity",
    },
    {
      name: "Billing Code",
      isChecked: false,
      value: "billingCode",
    },

    {
      name: "Billing Country",
      isChecked: false,
      value: "billingCountry",
    },

    {
      name: "Billing State",
      isChecked: false,
      value: "billingState",
    },

    {
      name: "Billing Street",
      isChecked: false,
      value: "billingStreet",
    },

    {
      name: "Carrier",
      isChecked: false,
      value: "carrier",
    },

    {
      name: "Shipping City",
      isChecked: false,
      value: "shippingCity",
    },

    {
      name: "Shipping Code",
      isChecked: false,
      value: "shippingCode",
    },

    {
      name: "Shipping Country",
      isChecked: false,
      value: "shippingCountry",
    },

    {
      name: "Shipping State",
      isChecked: false,
      value: "shippingState",
    },

    {
      name: "Team",
      isChecked: false,
      value: "team",
    },
  ];

  const dealsColumn = [
    {
      name: "Deal Name",
      isChecked: true,
      value: "dealName",
    },
    {
      name: "Amount",
      isChecked: true,
      value: "amount",
    },
    {
      name: "Stage",
      isChecked: true,
      value: "stage",
    },
    {
      name: "Closing Date",
      isChecked: true,
      value: "closingDate",
    },
    {
      name: "Account Name",
      isChecked: true,
      value: "accountName",
    },
    {
      name: "Contact Name",
      isChecked: true,
      value: "contactName",
    },
    {
      name: "Probability",
      isChecked: false,
      value: "probability",
    },
    {
      name: "Type",
      isChecked: false,
      value: "type",
    },
    {
      name: "Next Step",
      isChecked: false,
      value: "nextStep",
    },
    {
      name: "Lead Source",
      isChecked: false,
      value: "leadSource",
    },
    {
      name: "Expected Revenue",
      isChecked: false,
      value: "expectedRevenue",
    },
    {
      name: "Description",
      isChecked: false,
      value: "description",
    },
    {
      name: "Campaign Source",
      isChecked: false,
      value: "campaignSource",
    },
    {
      name: "Modified By",
      isChecked: false,
      value: "modifiedBy",
    },
    {
      name: "Deal Owner",
      isChecked: true,
      value: "dealOwner",
    },
  ];

  const invoiceColumns = [
    {
      name: "Subject",
      isChecked: true,
      value: "subject",
    },
    {
      name: "Shipping City",
      isChecked: false,
      value: "shippingCity",
    },
    {
      name: "Shipping Code",
      isChecked: false,
      value: "shippingCode",
    },
    {
      name: "Grand Total",
      isChecked: true,
      value: "grandTotal",
    },
    {
      name: "Account Name",
      isChecked: true,
      value: "accountName",
    },
    {
      name: "Contact Name",
      isChecked: true,
      value: "contactName",
    },
    {
      name: "Invoice Number",
      isChecked: false,
      value: "invoiceNumber",
    },
    {
      name: "Shipping Street",
      isChecked: false,
      value: "shippingStreet",
    },
    {
      name: "Due Date",
      isChecked: false,
      value: "dueDate",
    },
    {
      name: "Sales Commision",
      isChecked: false,
      value: "salesCommision",
    },
    {
      name: "Billing State",
      isChecked: false,
      value: "billingState",
    },
    {
      name: "Invoice Date",
      isChecked: true,
      value: "invoiceDate",
    },
    {
      name: "Deal Name",
      isChecked: false,
      value: "dealName",
    },
    {
      name: "Billing Code",
      isChecked: false,
      value: "billingCode",
    },
    {
      name: "Invoice Owner",
      isChecked: false,
      value: "invoiceOwner",
    },
    {
      name: "Billing Streete",
      isChecked: false,
      value: "billingStreet",
    },
    {
      name: "Shipping Country",
      isChecked: false,
      value: "shippingCountry",
    },
    {
      name: "Purchase Order",
      isChecked: false,
      value: "purchaseOrder",
    },
    {
      name: "Billing Country",
      isChecked: false,
      value: "billingCountry",
    },
    {
      name: "Shipping State",
      isChecked: false,
      value: "shippingState",
    },
    {
      name: "Excise Duty",
      isChecked: false,
      value: "exciseDuty",
    },
    {
      name: "Billing City",
      isChecked: false,
      value: "billingCity",
    },
    {
      name: "Status",
      isChecked: false,
      value: "status",
    },
  ];

  const salesColumns = [
    {
      name: "Sales Order Owner",
      isChecked: true,
      value: "salesOrderOwner",
    },
    {
      name: "Subject",
      isChecked: true,
      value: "subject",
    },
    {
      name: "Status",
      isChecked: true,
      value: "status",
    },
    {
      name: "Grand Total",
      isChecked: true,
      value: "grandTotal",
    },
    {
      name: "Deal Name",
      isChecked: true,
      value: "dealName",
    },
    {
      name: "Purchase Order",
      isChecked: false,
      value: "purchaseOrder",
    },
    {
      name: "Shipping City",
      isChecked: false,
      value: "shippingCity",
    },
    {
      name: "Shipping Code",
      isChecked: false,
      value: "shippingCode",
    },
    {
      name: "Shipping Street",
      isChecked: false,
      value: "shippingStreet",
    },
    {
      name: "Shipping Country",
      isChecked: false,
      value: "shippingCountry",
    },
    {
      name: "Billing Country",
      isChecked: false,
      value: "billingCountry",
    },
    {
      name: "Excise Duty",
      isChecked: false,
      value: "exciseDuty",
    },
    {
      name: "Billing Code",
      isChecked: false,
      value: "billingCode",
    },
    {
      name: "Contact Name",
      isChecked: true,
      value: "contactName",
    },
    {
      name: "Billing Street",
      isChecked: false,
      value: "billingStreet",
    },
    {
      name: "Billing State",
      isChecked: false,
      value: "billingState",
    },
    {
      name: "Account Name",
      isChecked: false,
      value: "accountName",
    },
    {
      name: "Carrier",
      isChecked: false,
      value: "carrier",
    },
    {
      name: "Shipping State",
      isChecked: false,
      value: "shippingState",
    },
    {
      name: "Billing City",
      isChecked: false,
      value: "billingCity",
    },
    {
      name: "Due Date",
      isChecked: false,
      value: "dueDate",
    },
  ];
  const userList = JSON.parse(sessionStorage.getItem("userList")) || [];
  const [selectedHeadingsQuotes, setSelectedHeadingsQuotes] =
    useState(quotesColumn);
  const [selectedHeadingsDeals, setSelectedHeadingsDeals] =
    useState(dealsColumn);
  const [selectedHeadingsInvoices, setSelectedHeadingsInvoices] =
    useState(invoiceColumns);
  const [selectedHeadingsSales, setSelectedHeadingsSales] =
    useState(salesColumns);
  const [savingHeadingsQuotes, setSavingHeadingsQuotes] =
    useState(quotesColumn);
  const [savingHeadingsDeals, setSavingHeadingsDeals] = useState(dealsColumn);
  const [savingHeadingsInvoices, setSavingHeadingsInvoices] =
    useState(invoiceColumns);
  const [savingHeadingsSales, setSavingHeadingsSales] = useState(salesColumns);
  const [filterQuotesDetails, setFilterQuotesDetails] = useState([]);
  const [filterDealsDetails, setFilterDealsDetails] = useState([]);
  const [filterInvoicesDetails, setFilterInvoicesDetails] = useState([]);
  const [filterSalesDetails, setFilterSalesDetails] = useState([]);
  const [showHeadingsQuotes, setShowHeadingsQuotes] = useState(false);
  const [showHeadingsContact, setShowHeadingsContact] = useState(false);
  const [showHeadingsDeals, setShowHeadingsDeals] = useState(false);
  const [showHeadingsInvoices, setShowHeadingsInvoices] = useState(false);
  const [showHeadingsSales, setShowHeadingsSales] = useState(false);
  const [showModalType, setShowModalType] = useState("");
  const [selectedOwnerItem, setSelectedOwnerItem] = useState(userList);
  const [isChangeOwnerList, setIsChangeOwnerList] = useState(false);
  const [isOwnerSelect, setIsOwnerSelect] = useState(false);

  const [emailNotification, setEmailNotification] = useState(true);
  const [selectedHeadingsContact, setSelectedHeadingsContact] =
    useState(contactColumn);
  const [savingHeadingsContact, setSavingHeadingsContact] =
    useState(contactColumn);

  const [filterContactDetails, setFilterContactDetails] = useState([]);

  useEffect(() => {
    if (storeModuleId) {
      accountOverviewDetail(storeModuleId);
      setAccountId(storeModuleId);
    }
  }, []);

  const fieldDropdowns = [
    {
      name: "Account Owner",
      isShowed: true,
      type: "select",
      value: "accountOwner",
    },
    {
      name: "Phone",
      isShowed: true,
      type: "input",
      value: "phone",
    },
    {
      name: "Modified By",
      isShowed: true,
      type: "readOnly",
    },
    {
      name: "Sic Code",
      isShowed: true,
      type: "input",
      value: "sicCode",
    },
    {
      name: "Fax",
      isShowed: true,
      type: "input",
      value: "fax",
    },
    {
      name: "Rating",
      isShowed: false,
      type: "dropdown",
      value: "rating",
    },
    {
      name: "Ticker Symbol",
      isShowed: false,
      type: "input",
    },
    {
      name: "Account Type",
      isShowed: false,
      type: "dropdown",
    },
    {
      name: "Ownership",
      isShowed: false,
      type: "dropdown",
    },
    {
      name: "Industry",
      isShowed: false,
      type: "dropdown",
    },
    {
      name: "Employees",
      isShowed: false,
      type: "input",
    },
    {
      name: "Annual Revenue",
      isShowed: false,
      type: "input",
    },
    {
      name: "Created By",
      isShowed: false,
      type: "readOnly",
    },
    {
      name: "Account Number",
      isShowed: false,
      type: "input",
      value: "accountNumber",
    },
    {
      name: "Account Site",
      isShowed: false,
      type: "input",
      value: "accountSite",
    },
    {
      name: "Billing Street",
      isShowed: false,
      type: "input",
    },
    {
      name: "Shipping Street",
      isShowed: false,
      type: "input",
    },
    {
      name: "Billing City",
      isShowed: false,
      type: "input",
    },
    {
      name: "Shipping City",
      isShowed: false,
      type: "input",
    },
    {
      name: "Billing State",
      isShowed: false,
      type: "input",
    },
    {
      name: "Shipping State",
      isShowed: false,
      type: "input",
    },
    {
      name: "Billing Code",
      isShowed: false,
      type: "input",
    },
    {
      name: "Shipping Code",
      isShowed: false,
      type: "input",
    },
    {
      name: "Billing Country",
      isShowed: false,
      type: "input",
    },
    {
      name: "Shipping Country",
      isShowed: false,
      type: "input",
    },
    {
      name: "Description",
      isShowed: false,
      type: "input",
      value: "description",
    },
  ];

  const reduxStore = useSelector((state) => state.reduxState);
  const { loginUserId } = reduxStore;
  const [fieldLabels, setFieldLabels] = useState(fieldDropdowns);
  const [showSearchFields, setShowSearchFields] = useState(fieldDropdowns);
  const [clickedDropdownObj, setClickedDropdownObj] = useState({
    label: "",
    value: "",
  });
  const [selectedField, setSelectedField] = useState("");
  const [searchField, setSearchField] = useState("");
  const [updateNotes, setUpdateNotes] = useState({
    description: "",
    title: "",
    noteImageList: [],
  });
  const [editNote, setEditNote] = useState(false);
  const [existingClone, setExistingClone] = useState(false);
  const [updateButtonLabel, setUpdateButtonLabel] = useState("Save");
  const [showAccessModal, setShowAccessModal] = useState(false);
  const [shareUserList, setShareUserList] = useState([]);
  const [shareRoleList, setShareRoleList] = useState([]);
  const [shareGroupList, setShareGroupList] = useState([]);
  const [shareInfoModal, setShareInfoModal] = useState(false);
  const [phoneMsg, setPhoneMsg] = useState("");
  const [accountSiteMsg, setAccountSiteMsg] = useState("");
  const [shareMemberIds, setShareMemberIds] = useState([]);
  const [showCancelModal, setShowCancelModal] = useState(false);
  const[sharePublicList,setSharePublicList]=useState([])

  const hasPermission = (action) => {
    const permission = permissionList.accounts.find((p) => p.action === action);
    return permission ? permission.status === "TRUE" : false;
  };

  const handleCreateAccount = () => {
    if (hasPermission("CREATE")) {
      setInnerPage("create");
      setCurrentPage(1);
      setPerPage(10);
      setUpdateButtonLabel("Save");
    } else {
      setShowAccessModal(true);
    }
  };

  const handleCreateAccountClone = () => {
    if (hasPermission("CREATE")) {
      if (accountInfoDetails.userPermission === "Read Only") {
        setShowAccessModal(true);
        return;
      }
      setInnerPage("clone");
      setexistingAccount(false);
      setShowOptionsLead(false);
      setExistingClone(true);
      setAccountUpdateInfo({ ...accountInfoDetails });
      setUpdateButtonLabel("Save");
    } else {
      setShowAccessModal(true);
    }
  };

  const handleEditAccount = () => {
    if (hasPermission("EDIT")) {
      if (accountInfoDetails.userPermission === "Read Only") {
        setShowAccessModal(true);
        return;
      }
      setexistingAccount(true);
      setInnerPage("create");
      setAccountUpdateInfo({ ...accountInfoDetails });
      setUpdateButtonLabel("Update");
    } else {
      setShowAccessModal(true);
    }
  };

  const handleDeleteAccount = () => {
    if (hasPermission("DELETE")) {
      if (accountInfoDetails.userPermission === "Read Only" ||accountInfoDetails.userPermission === "Read Write") {
        setShowAccessModal(true);
        return;
      }
      setDeleteModal(true);
    } else {
      setShowAccessModal(true);
    }
  };

  const handleChangeNoteTitle = (e) => {
    const { name, value } = e.target;
    if (value.length === 1 && value[0] === ' ') {
      return;
    }
    if (editNote) {
      setUpdateNotes((prevLeadInfo) => ({
        ...prevLeadInfo,
        [name]: value,
      }));
    } else {
      setAddTitle(value);
    }
  };

  const handleChildDataChange = (dataFromChild) => {
    const deletedImg = dataFromChild.toString("");
    setDeletedImg(deletedImg);
  };

  const handleChangeNoteDescription = (e) => {
    const { name, value } = e.target;
    if (value.length === 1 && value[0] === ' ') {
      return; 
    }
  
    if (editNote) {
      setUpdateNotes((prevLeadInfo) => ({
        ...prevLeadInfo,
        [name]: value,
      }));
    } else {
      setNote(value);
    }
  };
  

  const deleteUpdateTransferMass = async () => {
    const { value } = selectedObj;
    let url = URI.massDelete;
    const requestBody = {
      loggedInUserId: loginUserId,
      moduleName: "ACCOUNTS",
      massAction: showModalType === "Mass Delete" ? "DELETE" : "EDIT",
      moduleIdList: selectedCheckboxes,
    };
    if (showModalType === "Mass Update") {
      requestBody[value] = massUpdateValue;
      url = URI.massUpdate;
    } else if (showModalType === "Mass Transfer") {
      requestBody.ownerId = selectedItem.userId;
      url = URI.massTransfer;
    }
    const headersResult = getHeaders(true);

    // Check if headers result indicates an error
    if (headersResult.error) {
      navigate("../"); // Redirect to login if token is missing
      return;
    }
    await axios
      .post(url, requestBody, {
        headers: headersResult,
      })
      .then((response) => {
        if (response.data.status === 200) {
          NotificationManager.success("", response.data.message, 5000);
          getAllAccountDetails(selectedOption);
          setSelectedCheckboxes([]);
          setInnerPage("list");
          setShowModalType("");
          setShowSearchFields(fieldDropdowns);
          setMassUpdateValue("");
        } else {
          NotificationManager.error("", response.data.message, 5000);
        }
      })
      .catch(function (error) {
        handleApiError(error, navigate);
      });
  };

  const handledeleteModule = async (deleteProdId) => {
    const requestBody = {
      loginUserId: loginUserId,
      moduleName: "ACCOUNTS",
      moduleId: accountId,
      assignModuleName: "PRODUCTS",
      assignModuleId: deleteProdId,
    };

    try {
      const headersResult = getHeaders(true);

      // Check if headers result indicates an error
      if (headersResult.error) {
        navigate("../"); // Redirect to login if token is missing
        return;
      }
      const response = await axios.post(URI.deleteRole, requestBody, {
        headers: headersResult,
      });
      if (response.data.status === 200) {
        NotificationManager.success("", response.data.message, 5000);
        accountOverviewDetail(accountId);
      } else {
        NotificationManager.error("", response.data.message, 5000);
      }
    } catch (error) {
      handleApiError(error, navigate);
    }
  };

  const handleAssignModule = async (selectedIds, assignModuleName) => {
    const moduleIdList = selectedIds.map((id) => ({ moduleId: id }));

    if (moduleIdList.length < 1) {
      return NotificationManager.error("", "Please select an item", 5000);
    }

    setApiLoader(true);

    const requestBody = {
      loginUserId: loginUserId,
      moduleName: "ACCOUNTS",
      moduleId: accountId,
      assignModuleName: assignModuleName,
      moduleIdList,
    };

    try {
      const headersResult = getHeaders(true);

      // Check if headers result indicates an error
      if (headersResult.error) {
        navigate("../"); // Redirect to login if token is missing
        return;
      }
      const response = await axios.post(URI.assignRole, requestBody, {
        headers: headersResult,
      });
      if (response.data.status === 200) {
        NotificationManager.success("", response.data.message, 5000);
        accountOverviewDetail(accountId);
      } else {
        NotificationManager.error("", response.data.message, 5000);
      }
    } catch (error) {
      handleApiError(error, navigate);
    } finally {
      setApiLoader(false);
    }
  };

  const handleAssignProductModule = (selectedProductId) => {
    handleAssignModule(selectedProductId, "PRODUCTS");
  };

  const handleAssignQuoteModule = (selectedQuoteId) => {
    handleAssignModule(selectedQuoteId, "QUOTES");
  };

  const handleAssignSalesModule = (selectedSalesId) => {
    handleAssignModule(selectedSalesId, "SALES ORDERS");
  };

  const handleAssignInvoiceModule = (selectedInvoiceId) => {
    handleAssignModule(selectedInvoiceId, "INVOICES");
  };
  const handleAssignDealsModule = (selectedInvoiceId) => {
    handleAssignModule(selectedInvoiceId, "DEALS");
  };
  const handleAssignContactModule = (selectedInvoiceId) => {
    handleAssignModule(selectedInvoiceId, "CONTACTS");
  };

  const handleFieldClick = (field) => {
    if (field.type === "dropdown") {
      const fieldValue = field.name
        .replace(/\s+/g, "")
        .replace(/^(.)/, (match) => match.toLowerCase());

      setClickedDropdownObj(accountInfoDetails[fieldValue]);
    } else if (field.type === "checkbox") {
      setUpdatedFields((prevFields) => ({
        ...prevFields,
        emailOptOut: accountInfoDetails["emailOptOut"],
      }));
    }
    const updatedColumns = [...fieldLabels];

    const existedObj = updatedColumns.find(
      (field) => field.name === selectedField
    );
    const selectedObj = updatedColumns.find((item) => item.name === field.name);

    const existedObjIndex = updatedColumns.findIndex(
      (field) => field.name === existedObj.name
    );
    const selectedObjIndex = updatedColumns.findIndex(
      (field) => field.name === selectedObj.name
    );

    updatedColumns[existedObjIndex].isShowed = false;
    updatedColumns[selectedObjIndex].isShowed = true;

    const removedObj = updatedColumns.splice(existedObjIndex, 1)[0];
    updatedColumns.splice(existedObjIndex, 0, selectedObj);

    updatedColumns.push(removedObj);
    const uniqueData = updatedColumns.filter(
      (item, index, self) =>
        index === self.findIndex((t) => t.name === item.name)
    );
    setFieldLabels(uniqueData);
    setShowSearchFields(uniqueData);
  };

  const handleSelectRecent = (item) => {
    setSelectRecent(item);
    var value = "";
    if (item === "Recent Last") {
      value = "RECENT_LAST";
    } else if (item === "Recent First") {
      value = "RECENT_FIRST";
    } else {
      value = item;
    }
    getNotesDetails(accountId, value);
  };

  const handleTextAreaClick = () => {
    setShowButtons(true);
  };

  const handleAddTitleClick = () => {
    setShowTitleNote(true);
  };

  const copyShippingToBilling = () => {
    handleCopyAddress("shippingToBilling");
  };

  const copyBillingToShipping = () => {
    handleCopyAddress("billingToShipping");
  };

  const scrollToSection = (sectionId) => {
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: "smooth" });
    }
  };

  const getNotesDetails = async (accountId, orderType) => {
    const requestBody = {
      loggedInUserId: loginUserId,
      moduleId: accountId,
      orderType: orderType,
    };

    const headersResult = getHeaders(true);

    // Check if headers result indicates an error
    if (headersResult.error) {
      navigate("../"); // Redirect to login if token is missing
      return;
    }
    await axios
      .post(URI.getNote, requestBody, {
        headers: headersResult,
      })
      .then((response) => {
        if (response.data.status === 200) {
          setNoteInfo(response.data.data.noteInfo);
        } else {
          NotificationManager.error("", response.data.message, 5000);
        }
      })
      .catch(async (error) => {
        handleApiError(error, navigate);
      });
  };

  const deleteNoteDetails = async (accountId, noteId) => {
    const formData = new FormData();
    formData.append("moduleId", accountId);
    formData.append("loggedInUserId", loginUserId);
    formData.append("moduleName", "ACCOUNTS");
    formData.append("noteId", noteId);

    const headersResult = getHeaders(true);

    // Check if headers result indicates an error
    if (headersResult.error) {
      navigate("../"); // Redirect to login if token is missing
      return;
    }

    await axios
      .post(URI.deleteNote, formData, {
        headers: headersResult,
      })
      .then((response) => {
        if (response.data.status === 200) {
          NotificationManager.success("", response.data.message, 3000);
          getNotesDetails(accountId, selectRecent);
          setNoteDeleteConfirmation(false);
        } else {
          NotificationManager.error("", response.data.message, 5000);
        }
      })
      .catch((error) => {
        handleApiError(error, navigate);
      });
  };

  const updateNote = async (noteId) => {
    const formData = new FormData();
    formData.append("moduleId", accountId);
    formData.append("loggedInUserId", loginUserId);
    formData.append("moduleName", "ACCOUNTS");
    formData.append("noteDescription", updateNotes.description);
    formData.append("noteTitle", updateNotes.title);
    formData.append("noteId", noteId);
    formData.append("deletedImageNames", deletedImg.toString());

    selectedNoteFiles.forEach((file) => {
      formData.append("noteImages", file);
    });

    const headersResult = getHeaders(true);

    // Check if headers result indicates an error
    if (headersResult.error) {
      navigate("../"); // Redirect to login if token is missing
      return;
    }

    await axios
      .post(URI.updateNote, formData, {
        headers: headersResult,
      })
      .then((response) => {
        if (response.data.status === 200) {
          setShowButtons(false);
          setEditNote(false);
          NotificationManager.success("", response.data.message, 5000);
          setUpdateNotes({
            description: "",
            title: "",
            noteImageList: [],
          });
          setSelectedNoteFiles([]);
          getNotesDetails(accountId, selectRecent);
          setDeletedImg("");
        } else {
          setDeletedImg("");
          setSelectedNoteFiles([]);
          NotificationManager.error("", response.data.message, 5000);
        }
      })
      .catch((error) => {
        handleApiError(error, navigate);
      });
  };

  const insertNote = async () => {
    const formData = new FormData();
    formData.append("moduleId", accountId);
    formData.append("loggedInUserId", loginUserId);
    formData.append("moduleName", "ACCOUNTS");
    formData.append("noteDescription", note);
    formData.append("noteTitle", addTitle);

    selectedNoteFiles.forEach((file) => {
      formData.append("noteImages", file);
    });
    const headersResult = getHeaders(true);

    // Check if headers result indicates an error
    if (headersResult.error) {
      navigate("../"); // Redirect to login if token is missing
      return;
    }
    await axios
      .post(URI.insertNote, formData, {
        headers: headersResult,
      })
      .then(() => {
        // const newNoteId = response.data.data.noteId; // Extracting noteId from response
        setShowButtons(false);
        setNote("");
        setAddTitle("");
        setSelectedNoteFiles([]);
        getNotesDetails(accountId, selectRecent);
        // fetchNoteImage(newNoteId); // Pass newNoteId to fetchNoteImage
      })
      .catch((error) => {
        handleApiError(error, navigate);
      });
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const totalPages = Math.ceil(Number(totalItems) / perPage);

  const options = reduxStore.filterOptions.account;
  const [selectedOption, setSelectedOption] = useState(options[0]);
  // const [dropdownTwo, setDropdownTwo] = useState(massOptions[0]); dropdownTwo is not used anywhere so i am commenting this

  const [selectedLeadIndex, setSelectedLeadIndex] = useState(0);

  const [accountInfo, setaccountInfo] = useState({
    accountOwner: "",
    accountName: "",
    phone: "",
    accountSite: "",
    fax: "",
    parentAccount: "",
    website: "",
    accountNumber: "",
    tickerSymbol: "",
    employees: "",
    annualRevenue: "",
    sicCode: "",
    rating: ratingOptions[0],
    industry: industryOptions[0],
    accountType: accountTypeOptions[0],
    ownership: ownershipOptions[0],
    accountImage: "",
    billingStreet: "",
    billingCity: "",
    billingState: "",
    billingCode: "",
    billingCountry: "",
    shippingStreet: "",
    shippingCity: "",
    shippingState: "",
    shippingCode: "",
    shippingCountry: "",
    description: "",
  });

  const [existingAccount, setexistingAccount] = useState(false);
  const [showImageModal, setShowImageModal] = useState(false);

  const [selectedItem, setSelectedItem] = useState(null);
  const [listVisible, setListVisible] = useState(false);
  const [isLeadOwnerModal, setIsLeadOwnerModal] = useState(false);
  const [isAccountListModal, setIsAccountListModal] = useState(false);
  const [imageLoader, setImageLoader] = useState(false);
  const [showRemoveModal, setShowRemoveModal] = useState(false);

  // for save and new && save loader
  const [saveAndNew, setSaveAndNew] = useState(false);
  const [saveSubmit, setSaveSubmit] = useState(false);

  const handlePageDropDowmChange = (selectedOption) => {
    setCurrentPage(1);
    setPerPage(parseInt(selectedOption.value));
  };

  const [accountUpdateInfo, setAccountUpdateInfo] = useState({
    accountOwner: "",
    accountName: "",
    phone: "",
    accountSite: "",
    fax: "",
    parentAccount: "",
    website: "",
    accountNumber: "",
    tickerSymbol: "",
    employees: "",
    annualRevenue: "",
    sicCode: "",
    rating: ratingOptions[0],
    industry: industryOptions[0],
    accountType: accountTypeOptions[0],
    ownership: ownershipOptions[0],
    billingStreet: "",
    billingCity: "",
    billingState: "",
    billingCode: "",
    billingCountry: "",
    shippingStreet: "",
    shippingCity: "",
    shippingState: "",
    shippingCode: "",
    shippingCountry: "",
    description: "",
  });

  const accountColumn = [
    {
      name: "Shipping City",
      isChecked: false,
      value: "shippingCity",
    },
    {
      name: "Shipping Code",
      isChecked: false,
      value: "shippingCode",
    },
    {
      name: "Account Name",
      isChecked: true,
      value: "accountName",
    },
    {
      name: "Shipping Street",
      isChecked: false,
      value: "shippingStreet",
    },
    {
      name: "Rating",
      isChecked: false,
      value: "rating",
    },
    {
      name: "Description",
      isChecked: false,
      value: "description",
    },
    {
      name: "Industry",
      isChecked: false,
      value: "industry",
    },
    {
      name: "Account Site",
      isChecked: false,
      value: "accountSite",
    },
    {
      name: "Parent Account Name",
      isChecked: false,
      value: "parentAccountName",
    },
    {
      name: "Shipping Country",
      isChecked: false,
      value: "shippingCountry",
    },
    {
      name: "Billing Country",
      isChecked: false,
      value: "billingCountry",
    },
    {
      name: "Modified By",
      isChecked: false,
      value: "modifiedBy",
    },
    {
      name: "Fax",
      isChecked: false,
      value: "fax",
    },
    {
      name: "Website",
      isChecked: true,
      value: "website",
    },
    {
      name: "Account Type",
      isChecked: false,
      value: "accountType",
    },
    {
      name: "Ticker Symbol",
      isChecked: false,
      value: "tickerSymbol",
    },
    {
      name: "Account Owner",
      isChecked: true,
      value: "accountOwner",
    },
    {
      name: "Billing State",
      isChecked: false,
      value: "billingState",
    },
    {
      name: "Account Number",
      isChecked: false,
      value: "accountNumber",
    },
    {
      name: "Billing Code",
      isChecked: false,
      value: "billingCode",
    },
    {
      name: "Ownership",
      isChecked: false,
      value: "ownership",
    },
    {
      name: "Phone",
      isChecked: true,
      value: "phone",
    },
    {
      name: "Created By",
      isChecked: false,
      value: "createdBy",
    },
    {
      name: "Billing Street",
      isChecked: false,
      value: "billingStreet",
    },
    {
      name: "SIC Code",
      isChecked: false,
      value: "sicCode",
    },
    {
      name: "Shipping State",
      isChecked: false,
      value: "shippingState",
    },
    {
      name: "Employees",
      isChecked: false,
      value: "employees",
    },
    {
      name: "Annual Revenue",
      isChecked: false,
      value: "annualRevenue",
    },
    {
      name: "Billing City",
      isChecked: false,
      value: "billingCity",
    },
    {
      name: "Hash",
      isChecked: true,
      value: "transHash",
    },
  ];

  const [selectedHeadings, setSelectedHeadings] = useState(accountColumn);
  const [savingHeadings, setSavingHeadings] = useState(accountColumn);
  const [showHeadings, setShowHeadings] = useState(false);
  const [showSelectedFilter, setShowSelectedFilter] = useState("");
  const [filterType, setFilterType] = useState("");
  const [filteredLeadDetails, setFilteredLeadDetails] = useState([]);
  const [pinnedColumn, setPinnedColumn] = useState("Account Name");
  const [searchColumn, setSearchColumn] = useState("");
  const [selectedCheckboxes, setSelectedCheckboxes] = useState([]);
  const [massUpdateValue, setMassUpdateValue] = useState("");
  const [selectedObj, setSelectedObj] = useState({
    name: "Account Name",
    isChecked: false,
    value: "accountName",
  });

  const handleSelectCheckboxChange = (event) => {
    const { value } = event.target;

    const selectedCheckbox = { moduleId: value };

    setSelectedCheckboxes((prevSelectedCheckboxes) => {
      const isAlreadySelected = prevSelectedCheckboxes.some(
        (checkbox) => checkbox.moduleId === value
      );

      if (isAlreadySelected) {
        return prevSelectedCheckboxes.filter(
          (checkbox) => checkbox.moduleId !== value
        );
      } else {
        return [...prevSelectedCheckboxes, selectedCheckbox];
      }
    });
  };

  const handleCopyAddress = (direction) => {
    if (existingAccount) {
      if (direction === "shippingToBilling") {
        setAccountUpdateInfo((prevInfo) => ({
          ...prevInfo,
          billingStreet: prevInfo.shippingStreet || prevInfo.billingStreet,
          billingCity: prevInfo.shippingCity || prevInfo.billingCity,
          billingState: prevInfo.shippingState || prevInfo.billingState,
          billingCode: prevInfo.shippingCode || prevInfo.billingCode,
          billingCountry: prevInfo.shippingCountry || prevInfo.billingCountry,
        }));
      } else if (direction === "billingToShipping") {
        setAccountUpdateInfo((prevInfo) => ({
          ...prevInfo,
          shippingStreet: prevInfo.billingStreet || prevInfo.shippingStreet,
          shippingCity: prevInfo.billingCity || prevInfo.shippingCity,
          shippingState: prevInfo.billingState || prevInfo.shippingState,
          shippingCode: prevInfo.billingCode || prevInfo.shippingCode,
          shippingCountry: prevInfo.billingCountry || prevInfo.shippingCountry,
        }));
      }
    } else {
      if (direction === "shippingToBilling") {
        setaccountInfo((prevInfo) => ({
          ...prevInfo,
          billingStreet: prevInfo.shippingStreet || prevInfo.billingStreet,
          billingCity: prevInfo.shippingCity || prevInfo.billingCity,
          billingState: prevInfo.shippingState || prevInfo.billingState,
          billingCode: prevInfo.shippingCode || prevInfo.billingCode,
          billingCountry: prevInfo.shippingCountry || prevInfo.billingCountry,
        }));
      } else if (direction === "billingToShipping") {
        setaccountInfo((prevInfo) => ({
          ...prevInfo,
          shippingStreet: prevInfo.billingStreet || prevInfo.shippingStreet,
          shippingCity: prevInfo.billingCity || prevInfo.shippingCity,
          shippingState: prevInfo.billingState || prevInfo.shippingState,
          shippingCode: prevInfo.billingCode || prevInfo.shippingCode,
          shippingCountry: prevInfo.billingCountry || prevInfo.shippingCountry,
        }));
      }
    }
  };

  const handlePinColumn = (name) => {
    setPinnedColumn(name);
    const updatedColumns = [...selectedHeadings];
    const clickedColumnIndex = updatedColumns.findIndex(
      (column) => column.name === name
    );

    if (clickedColumnIndex !== -1) {
      const clickedColumn = updatedColumns.splice(clickedColumnIndex, 1)[0];
      updatedColumns.unshift(clickedColumn);
      setSelectedHeadings(updatedColumns);
    }
  };

  const sortedColumnsData = (type, headingName) => {
    const sortedData = [...allList];

    if (type === "asc") {
      sortedData.sort((a, b) => {
        if (
          typeof a[headingName] === "string" &&
          typeof b[headingName] === "string"
        ) {
          return a[headingName].localeCompare(b[headingName]);
        }
        return a[headingName] - b[headingName];
      });
    } else if (type === "desc") {
      sortedData.sort((a, b) => {
        if (
          typeof a[headingName] === "string" &&
          typeof b[headingName] === "string"
        ) {
          return b[headingName].localeCompare(a[headingName]);
        }
        return b[headingName] - a[headingName];
      });
    }
    setFilteredLeadDetails(sortedData);
  };

  const [allList, setAllList] = useState([]);
  const [selectedAccountItem, setSelectedAccountItem] = useState([]);
  const [accountId, setAccountId] = useState("");
  const [accountInfoDetails, setAccountInfoDetails] = useState({});
  //const [accountContactList, setAccountContactList] = useState();
  const [editingFields, setEditingFields] = useState({
    accountOwner: false,
    accountName: false,
    phone: false,
    accountSite: false,
    fax: false,
    parentAccount: false,
    website: false,
    accountNumber: false,
    tickerSymbol: false,
    employees: false,
    annualRevenue: false,
    sicCode: false,
    rating: false,
    industry: false,
    accountType: false,
    ownership: false,
    billingStreet: false,
    billingCity: false,
    billingState: false,
    billingCode: false,
    billingCountry: false,
    shippingStreet: false,
    shippingCity: false,
    shippingState: false,
    shippingCode: false,
    shippingCountry: false,
    description: false,
  });
  const [updatedFields, setUpdatedFields] = useState({
    accountOwner: "",
    accountOwnerInfo: accountInfoDetails.accountOwner,
    createdByInfo: accountInfoDetails.accountOwner,
    //modifiedByInfo: accountInfoDetails.accountOwner,

    industry: industryOptions[0],
    industryInfo: accountInfoDetails.industry,
    employees: "",
    annualRevenue: "",
    annualRevenueInfo: accountInfoDetails.annualRevenue,
    phone: "",
    phoneInfo: accountInfoDetails.phone,
    accountName: "",
    accountSite: "",
    fax: "",
    parentAccount: accountInfoDetails.parentAccount,
    website: accountInfoDetails.website,
    accountNumber: accountInfoDetails.accountNumber,
    tickerSymbol: accountInfoDetails.tickerSymbol,
    sicCode: accountInfoDetails.sicCode,
    rating: accountInfoDetails.rating,
    accountType: accountInfoDetails.accountType,
    ownership: accountInfoDetails.ownership,
    billingStreet: accountInfoDetails.billingStreet,
    billingCity: accountInfoDetails.billingCity,
    billingState: accountInfoDetails.billingState,
    billingCode: accountInfoDetails.billingCode,
    billingCountry: accountInfoDetails.billingCountry,
    shippingStreet: accountInfoDetails.shippingStreet,
    shippingCity: accountInfoDetails.shippingCity,
    shippingState: accountInfoDetails.shippingState,
    shippingCode: accountInfoDetails.shippingCode,
    shippingCountry: accountInfoDetails.shippingCountry,
    description: accountInfoDetails.description,
    employeesInfo: accountInfoDetails.employees,
    accountSiteInfo: accountInfoDetails.accountSite,
    billingStreetInfo: accountInfoDetails.billingStreet,
    faxInfo: accountInfoDetails.fax,
    sicCodeInfo: accountInfoDetails.sicCode,
    billingCityInfo: accountInfoDetails.billingCity,
    billingStateInfo: accountInfoDetails.billingState,
    billingCodeInfo: accountInfoDetails.billingCode,
    billingCountryInfo: accountInfoDetails.billingCountry,
    shippingStreetInfo: accountInfoDetails.shippingStreet,
    shippingCityInfo: accountInfoDetails.shippingCity,
    shippingStateInfo: accountInfoDetails.shippingState,
    shippingCodeInfo: accountInfoDetails.shippingCode,
    shippingCountryInfo: accountInfoDetails.shippingCountry,
    descriptionInfo: accountInfoDetails.description,
    tickerSymbolInfo: accountInfoDetails.tickerSymbol,
    accountNumberInfo: accountInfoDetails.accountNumber,
    ratingInfo: accountInfoDetails.rating,
    accountTypeInfo: accountInfoDetails.accountType,
    ownershipInfo: accountInfoDetails.ownership,
  });
  const [showType, setShowType] = useState("");
  const [timeLineDataList, setTimeLineDataList] = useState([]);
  const [accountPageView, setAccountPageView] = useState("overview");

  const [parentlistVisible, setParentListVisible] = useState(false);
  useScrollToUndo(pastViewInfo, setPastViewInfo); // for scroll to previously clicked section
  //// reset all fields ///////

  const accountResetValues = () => {
    const accountResetData = {
      accountOwner: "",
      accountName: "",
      phone: "",
      accountSite: "",
      fax: "",
      parentAccount: "",
      website: "",
      accountNumber: "",
      tickerSymbol: "",
      employees: "",
      annualRevenue: "",
      sicCode: "",
      rating: ratingOptions[0],
      industry: industryOptions[0],
      accountType: accountTypeOptions[0],
      ownership: ownershipOptions[0],
      billingStreet: "",
      billingCity: "",
      billingState: "",
      billingCode: "",
      billingCountry: "",
      shippingStreet: "",
      shippingCity: "",
      shippingState: "",
      shippingCode: "",
      shippingCountry: "",
      description: "",
    };
    setaccountInfo(accountResetData);
    setAccountUpdateInfo(accountResetData);
  };

  const getAllAccountDetails = async (selectedOption) => {
    setIsLoading(true);
    setSelectedOption(selectedOption);
    const requestBody = {
      loggedInUserId: loginUserId,
      customFilter: selectedOption.value,
      pageNo: currentPage,
      pageSize: perPage,
    };

    const headersResult = getHeaders(true);

    // Check if headers result indicates an error
    if (headersResult.error) {
      navigate("../"); // Redirect to login if token is missing
      return;
    }
    await axios
      .post(URI.getAllAccountDetails, requestBody, {
        headers: headersResult,
      })

      .then((response) => {
        if (response.data.status === 200) {
          setAllList(response.data.data.allAccountDetails);
          setSelectedAccountItem(response.data.data.allAccountDetails);
          setFilteredLeadDetails(response.data.data.allAccountDetails);
          setTotalItems(response.data.data.rowCount);
          setModalAccountDetails(response.data.data.allAccountDetails);
          setIsLoading(false);
        } else {
          NotificationManager.error("", response.data.message, 5000);
          setIsLoading(false);
        }
      })
      .catch(async (error) => {
        handleApiError(error, navigate);
      });
  };

  const handledeleteModuleDeal = async (deleteDealId) => {
    const requestBody = {
      loginUserId: loginUserId,
      moduleName: "ACCOUNTS",
      moduleId: accountId,
      assignModuleName: "DEALS",
      assignModuleId: deleteDealId,
    };

    try {
      const headersResult = getHeaders(true);

      // Check if headers result indicates an error
      if (headersResult.error) {
        navigate("../"); // Redirect to login if token is missing
        return;
      }
      const response = await axios.post(URI.deleteRole, requestBody, {
        headers: headersResult,
      });
      if (response.data.status === 200) {
        NotificationManager.success("", response.data.message, 5000);
        accountOverviewDetail(accountId);
      } else {
        NotificationManager.error("", response.data.message, 5000);
      }
    } catch (error) {
      handleApiError(error, navigate);
    }
  };

  const handledeleteModuleContact = async (deleteContactId) => {
    const requestBody = {
      loginUserId: loginUserId,
      moduleName: "ACCOUNTS",
      moduleId: accountId,
      assignModuleName: "CONTACTS",
      assignModuleId: deleteContactId,
    };

    try {
      const headersResult = getHeaders(true);

      // Check if headers result indicates an error
      if (headersResult.error) {
        navigate("../"); // Redirect to login if token is missing
        return;
      }
      const response = await axios.post(URI.deleteRole, requestBody, {
        headers: headersResult,
      });
      if (response.data.status === 200) {
        NotificationManager.success("", response.data.message, 5000);
        accountOverviewDetail(accountId);
      } else {
        NotificationManager.error("", response.data.message, 5000);
      }
    } catch (error) {
      handleApiError(error, navigate);
    }
  };

  const getAllTimelineAccounts = async () => {
    const headersResult = getHeaders(true);

    // Check if headers result indicates an error
    if (headersResult.error) {
      navigate("../"); // Redirect to login if token is missing
      return;
    }
    await axios
      .get(URI.getAccountTimeline + "/" + loginUserId + "/" + accountId, {
        headers: headersResult,
      })
      .then((response) => {
        if (response.data.status === 200) {
          setTimeLineDataList(response.data.data.getAccountDetails);
          setAccountPageView("timeline");
        } else {
          NotificationManager.error("", response.data.message, 5000);
        }
      })
      .catch(async (error) => {
        handleApiError(error, navigate);
      });
  };

  const createAccountStyles = {
    menu: (base) => ({
      ...base,
      width: "60%",
    }),
    option: (provided) => ({
      ...provided,
      // backgroundColor: "#EAEAEA",
      color: "#000",
      // backgroundColor: 'rgba(33, 119, 254, 0.1)',
      width: "100%",
    }),
    control: (baseStyles) => ({
      ...baseStyles,
      border: "1px solid #b9b9b9b0",
      backgroundColor: "#F3F6FB",
      height: "40px",
      borderRadius: "10px",
      width: "60%",
      boxShadow: "none",
    }),
    input: (base) => ({
      ...base,
      color: "#FFFFFF",
    }),
    singleValue: (defaultStyles) => ({ ...defaultStyles, color: "#000" }),

    menuList: (provided) => ({
      ...provided,
      paddingTop: 0,
      paddingBottom: 0,
      "&::-webkit-scrollbar": {
        width: 5,
      },

      "&::-webkit-scrollbar-thumb": {
        background: "#316AFF",
        borderRadius: "10px",
      },
    }),
  };
  const handleDragOver = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setIsDragging(true);
  };

  const handleDragLeave = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setIsDragging(false);
  };

  const handleDrop = (e) => {
    const maxSizeInBytes = 2 * 1024 * 1024; // 2 MB in bytes

    e.preventDefault();
    e.stopPropagation();
    setIsDragging(false);
    const file = e.dataTransfer.files[0];
    if (file) {
      if (!allowedFileTypes.includes(file.type)) {
        setImageMsg(
          "Invalid file type. Please upload an image file (JPG, PNG, JPEG)."
        );
        setTimeout(() => setImageMsg(""), 3000);
        return;
      }
      if (file.size > maxSizeInBytes) {
        setImageMsg("File size exceeds 2 MB. Please select a smaller file.");
        setTimeout(() => setImageMsg(""), 3000);
        return;
      }
      setSelectedFiles(file);
      fileInputRef.current.files = e.dataTransfer.files;
      if (existingAccount || existingClone) {
        setAccountUpdateInfo((prevLeadInfo) => ({
          ...prevLeadInfo,
          ["accountImage"]: file,
        }));
      } else {
        setaccountInfo((prevLeadInfo) => ({
          ...prevLeadInfo,
          ["accountImage"]: file,
        }));
      }
    }
  };

  const handleAccountImageChange = (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      if (!allowedFileTypes.includes(selectedFile.type)) {
        setImageMsg(
          "Invalid file type. Please upload an image file (JPG, PNG, JPEG)."
        );
        setTimeout(() => setImageMsg(""), 3000);
        return;
      }
      if (selectedFile.size > maxSizeInBytes) {
        setImageMsg("File size exceeds 2 MB. Please select a smaller file.");
        setTimeout(() => setImageMsg(""), 3000);
        return;
      }
      setSelectedFiles(selectedFile);
      if (existingAccount || existingClone) {
        setAccountUpdateInfo((prevLeadInfo) => ({
          ...prevLeadInfo,
          ["accountImage"]: e.target.files[0],
        }));
      } else {
        setaccountInfo((prevLeadInfo) => ({
          ...prevLeadInfo,
          ["accountImage"]: e.target.files[0],
        }));
      }
    }
  };

  const handleUploadImage = (e) => {
    const file = e.target.files[0];
    if (file) {
      if (!allowedFileTypes.includes(file.type)) {
        setImageMsg(
          "Invalid file type. Please upload an image file (JPG, PNG, JPEG)."
        );
        setTimeout(() => setImageMsg(""), 5000);
        return;
      }
      if (file.size > maxSizeInBytes) {
        setImageMsg("File size exceeds 2 MB. Please select a smaller file.");
        setTimeout(() => setImageMsg(""), 5000);
        return;
      }
      const imageUrl = URL.createObjectURL(file);
      setImageUrl(imageUrl);
      setSelectedFiles(e.target.files[0]);
    }
    setUploadImg(true);
  };
  /* eslint-disable no-useless-escape */
  const sanitizeExpression = (expression) => {
    const match = expression.match(/^\s*-?\d+(\.\d+)?([+\-*\/]-?\d+(\.\d+)?)*/);

    if (match) {
      return match[0].trim();
    } else {
      return "Invalid input";
    }
  };

  const calculateResult = (name) => {
    const value = (
      existingAccount || existingClone
        ? accountUpdateInfo[name]
        : accountInfo[name]
    ).trim();

    const sanitizedValue = value;
    const regex = /^[\d.\+\-\*\/\(\)]+$/;

    if (regex.test(sanitizedValue)) {
      try {
        const cleanedValue = removeLeadingZeros(sanitizedValue);
        let result = eval(cleanedValue);
        if (typeof result === "number" && result % 1 !== 0) {
          const decimalPart = result.toString().split(".")[1];
          if (decimalPart && decimalPart.length > 2) {
            result = parseFloat(result).toFixed(2);
          }
        }

        if (existingAccount || existingClone) {
          setAccountUpdateInfo((prevLeadInfo) => ({
            ...prevLeadInfo,
            [name]: result.toString(),
          }));
        } else {
          setaccountInfo((prevLeadInfo) => ({
            ...prevLeadInfo,
            [name]: result.toString(),
          }));
        }
      } catch (error) {
        if (existingAccount || existingClone) {
          setAccountUpdateInfo((prevLeadInfo) => ({
            ...prevLeadInfo,
            [name]: sanitizeExpression(value),
          }));
        } else {
          setaccountInfo((prevLeadInfo) => ({
            ...prevLeadInfo,
            [name]: sanitizeExpression(value),
          }));
        }
      }
    }
  };
  /* eslint-disable no-useless-escape */
  const handleKeyPress = (event) => {
    const allowedCharacters = /[0-9.\+\-\*\/\(\)]/;
    const char = event.key;
    if (!allowedCharacters.test(char)) {
      event.preventDefault();
    }
  };
  /* eslint-disable no-useless-escape */

  const handleTooltip = (value, setShowTooltip) => {
    const regex = /^[\d.\+\-\*\/\(\)]+$/;
    if (regex.test(value)) {
      try {
        const sanitizedValue = removeLeadingZeros(value);
        const previewResult = eval(sanitizedValue);
        setTooltipContent(previewResult.toString());
      } catch (error) {
        setTooltipContent(sanitizeExpression(value));
      }
      setShowTooltip(true);
    } else {
      setShowTooltip(false);
    }
  };

  const handleChangeAccount = (e) => {
    const { name, value } = e.target;

    if (existingAccount || existingClone) {
      setAccountUpdateInfo((prevLeadInfo) => ({
        ...prevLeadInfo,
        [name]: value,
      }));
    } else {
      setaccountInfo((prevLeadInfo) => ({
        ...prevLeadInfo,
        [name]: value,
      }));
    }
    switch (name) {
      case "annualRevenue":
        handleTooltip(value, setShowTooltipAnnualRevenue);
        break;

      default:
        break;
    }
  };
  const hideAllTooltips = () => {
    setShowTooltipAnnualRevenue(false);
  };

  const tooltipStyles = {
    position: "absolute",
    top: "100%",
    left: "0",
    backgroundColor: "#f9f9f9",
    border: "1px solid #d3d3d3",
    padding: "5px",
    borderRadius: "3px",
    boxShadow: "0 2px 5px rgba(0,0,0,0.2)",
    zIndex: 1000,
    height: "fit-content",
    width: "fit-content",
  };

  const handleBlur = (field) => {
    calculateResult(field);
    hideAllTooltips();
  };

  const handleChangeSelectDropDown = (selectedOption, field) => {
    if (existingAccount || existingClone) {
      setAccountUpdateInfo((prevLeadInfo) => ({
        ...prevLeadInfo,
        [field]: selectedOption,
      }));
    } else {
      setaccountInfo((prevLeadInfo) => ({
        ...prevLeadInfo,
        [field]: selectedOption,
      }));
      setAccountInfoDetails((prevLeadInfo) => ({
        ...prevLeadInfo,
        [field]: selectedOption,
      }));
    }
  };
  const handleSaveButton = async (e) => {
    let isValidForm = true;

    const setErrorMessage = (setter, message, timeout = 5000) => {
      setter(message);
      setTimeout(() => {
        setter("");
      }, timeout);
      isValidForm = false;
    };

    const accountData =
      existingAccount || existingClone ? accountUpdateInfo : accountInfo;

    if (accountData.accountName === "") {
      setErrorMessage(setMandatoryMsg, "Account name cannot be empty.", 5000);
    }
    if (
      accountData.accountName &&
      !validateFirstName(accountData.accountName)
    ) {
      setErrorMessage(
        setMandatoryMsg,
        "Please enter a valid Account name.",
        5000
      );
    }
    if (
      accountData.phone.length > 0 &&
      !validateMobileNumber(accountData.phone)
    ) {
      setErrorMessage(setPhoneMsg, "Please enter a valid Phone Number.");
    }
    if (
      accountData.accountSite.length > 0 &&
      !validateAccountSite(accountData.accountSite)
    ) {
      setErrorMessage(setAccountSiteMsg, "Please enter a valid Account site.");
    }
    if (
      accountData.website.length > 0 &&
      !websiteValidation(accountData.website)
    ) {
      setErrorMessage(setWebsiteMsg, "Please enter a valid website name.");
    }
    if (
      accountData.accountNumber.length > 0 &&
      !validateAccountNumber(accountData.accountNumber)
    ) {
      setErrorMessage(
        setAccountNumberMsg,
        "Please enter a valid Account number."
      );
    }
    if (
      accountData.tickerSymbol.length > 0 &&
      !validateTicketSymbol(accountData.tickerSymbol)
    ) {
      setErrorMessage(setTickerMsg, "Please enter a valid Ticker symbol.");
    }
    if (
      accountData.employees.length > 0 &&
      !validateNumberOfEmployees(accountData.employees)
    ) {
      setErrorMessage(setEmployeeMsg, "Please enter a valid Employees Number.");
    }
    if (
      accountData.annualRevenue.length > 0 &&
      !validateAnnualRevenue(accountData.annualRevenue)
    ) {
      setErrorMessage(
        setAnnualRevenueMsg,
        "Please enter a valid Annual revenue."
      );
    }
    if (
      accountData.sicCode.length > 0 &&
      !validateSICCode(accountData.sicCode)
    ) {
      setErrorMessage(setSicCodeMsg, "Please enter a valid SIC code.");
    }
    if (
      accountData.billingCity &&
      !validateCityIndia(accountData.billingCity)
    ) {
      setErrorMessage(setBillingCityMsg, "Please enter a valid Billing City.");
    }
    if (
      accountData.shippingCity &&
      !validateCityIndia(accountData.shippingCity)
    ) {
      setErrorMessage(
        setShippingCityMsg,
        "Please enter a valid Shipping City."
      );
    }
    if (
      accountData.billingState &&
      !validateStateIndia(accountData.billingState)
    ) {
      setErrorMessage(
        setBillingStateMsg,
        "Please enter a valid Billing State."
      );
    }
    if (
      accountData.shippingState &&
      !validateStateIndia(accountData.shippingState)
    ) {
      setErrorMessage(
        setShippingStateMsg,
        "Please enter a valid Shipping State."
      );
    }
    if (
      accountData.billingCountry &&
      !validateCountryName(accountData.billingCountry)
    ) {
      setErrorMessage(
        setBillingCountryMsg,
        "Please enter a valid Billing Country."
      );
    }
    if (
      accountData.shippingCountry &&
      !validateCountryName(accountData.shippingCountry)
    ) {
      setErrorMessage(
        setShippingCountryMsg,
        "Please enter a valid Shipping Country."
      );
    }
    if (accountData.billingCode && !validateZipCode(accountData.billingCode)) {
      setErrorMessage(setCodeMsg, "Please enter a valid Billing Code.");
    }
    if (
      accountData.shippingCode &&
      !validateZipCode(accountData.shippingCode)
    ) {
      setErrorMessage(
        setShippingCodeMsg,
        "Please enter a valid Shipping Code."
      );
    }

    if (!isValidForm) return;

    const formData = new FormData();
    let requestBody = {};
    e === "SAVE AND NEW" ? setSaveAndNew(true) : setSaveSubmit(true);
    if (existingClone || existingAccount) {
      requestBody = {
        loggedInUserId: loginUserId,
        accountOwnerId: selectedItem.userId,
        accountId: accountId,
        accountName: removeExtraSpaces(accountUpdateInfo.accountName),
        accountSite: removeExtraSpaces(accountUpdateInfo.accountSite),
        parentAccountId: selectedAccountItem.accountId,
        accountNumber: removeExtraSpaces(accountUpdateInfo.accountNumber),
        accountType: accountUpdateInfo.accountType.value ||"",
        industry: accountUpdateInfo.industry.value,
        annualRevenue: Number(accountUpdateInfo.annualRevenue),
        rating: accountUpdateInfo.rating.value,
        phone: removeExtraSpaces(accountUpdateInfo.phone),
        fax: removeExtraSpaces(accountUpdateInfo.fax),
        website: removeExtraSpaces(accountUpdateInfo.website),
        tickerSymbol: removeExtraSpaces(accountUpdateInfo.tickerSymbol),
        ownership: accountUpdateInfo.ownership.value,
        employees: Number(accountUpdateInfo.employees),
        sicCode: removeExtraSpaces(accountUpdateInfo.sicCode),
        billingStreet: removeExtraSpaces(accountUpdateInfo.billingCity),
        billingCity: removeExtraSpaces(accountUpdateInfo.billingCity),
        billingState: removeExtraSpaces(accountUpdateInfo.billingState),
        billingCode: removeExtraSpaces(accountUpdateInfo.billingCode),
        billingCountry: removeExtraSpaces(accountUpdateInfo.billingCountry),
        shippingStreet: removeExtraSpaces(accountUpdateInfo.shippingStreet),
        shippingCity: removeExtraSpaces(accountUpdateInfo.shippingCity),
        shippingState: removeExtraSpaces(accountUpdateInfo.shippingState),
        shippingCode: removeExtraSpaces(accountUpdateInfo.shippingCode),
        shippingCountry: removeExtraSpaces(accountUpdateInfo.shippingCountry),
        description: removeExtraSpaces(accountUpdateInfo.description),
      };
    } else {
      requestBody = {
        loggedInUserId: loginUserId,
        accountOwnerId: selectedItem.userId,
        accountName: removeExtraSpaces(accountInfo.accountName),
        accountSite: removeExtraSpaces(accountInfo.accountSite),
        parentAccountId: selectedAccountItem?.accountId,
        accountNumber: removeExtraSpaces(accountInfo.accountNumber),
        accountType: accountInfo.accountType.value ||"",
        industry: accountInfo.industry.value,
        annualRevenue: Number(accountInfo.annualRevenue),
        rating: accountInfo.rating.value,
        phone: removeExtraSpaces(accountInfo.phone),
        fax: removeExtraSpaces(accountInfo.fax),
        website: removeExtraSpaces(accountInfo.website),
        tickerSymbol: removeExtraSpaces(accountInfo.tickerSymbol),
        ownership: accountInfo.ownership.value,
        employees: Number(accountInfo.employees),
        sicCode: removeExtraSpaces(accountInfo.sicCode),
        billingStreet: removeExtraSpaces(accountInfo.billingStreet),
        billingCity: removeExtraSpaces(accountInfo.billingCity),
        billingState: removeExtraSpaces(accountInfo.billingState),
        billingCode: removeExtraSpaces(accountInfo.billingCode),
        billingCountry: removeExtraSpaces(accountInfo.billingCountry),
        shippingStreet: removeExtraSpaces(accountInfo.shippingStreet),
        shippingCity: removeExtraSpaces(accountInfo.shippingCity),
        shippingState: removeExtraSpaces(accountInfo.shippingState),
        shippingCode: removeExtraSpaces(accountInfo.shippingCode),
        shippingCountry: removeExtraSpaces(accountInfo.shippingCountry),
        description: removeExtraSpaces(accountInfo.description),
        productId:
          pastViewInfo?.view !== "" && fetchProdInfo
            ? fetchProdInfo?.productId
            : null, // it will pass the productId when we are routing from other page
      };
    }

    formData.append("accountDtlsInputs", JSON.stringify(requestBody));
    formData.append("accountImage", accountInfo.accountImage);

    const headersResult = getHeaders(true);

    // Check if headers result indicates an error
    if (headersResult.error) {
      navigate("../"); // Redirect to login if token is missing
      return;
    }
    await axios
      .post(URI.addAccountDetails, formData, {
        headers: headersResult,
      })
      .then((response) => {
        if (response.data.status === 200) {
          NotificationManager.success("", response.data.message, 5000);
          if (e === "SAVE AND NEW") {
            setInnerPage("create");
          } else {
            setInnerPage("list");
          }
          getAllAccountDetails(selectedOption);
          accountResetValues();
          setSelectedFiles(null);
          setCurrentPage(1);
          setPerPage(10);
          fileInputRef.current.value = "";
        } else {
          NotificationManager.error("", response.data.message, 5000);
        }
      })
      .catch(function (error) {
        handleApiError(error, navigate);
      })
      .finally(() => {
        setSaveAndNew(false);
        setSaveSubmit(false);
      });
  };
  const handleUpdateAccount = async (e) => {
    let isValidForm = true;

    const setErrorMessage = (setter, message, timeout = 5000) => {
      setter(message);
      setTimeout(() => {
        setter("");
      }, timeout);
      isValidForm = false;
    };

    const accountData =
      existingAccount || existingClone ? accountUpdateInfo : accountInfo;

    if (accountData.accountName === "") {
      setErrorMessage(setMandatoryMsg, "Account name cannot be empty.", 5000);
    }
    if (
      accountData.accountName &&
      !validateFirstName(accountData.accountName)
    ) {
      setErrorMessage(
        setMandatoryMsg,
        "Please enter a valid Account name.",
        5000
      );
    }
    if (
      !validateMobileNumber(accountData.phone) &&
      accountData?.phone?.length > 0
    ) {
      setErrorMessage(setPhoneMsg, "Please enter a valid Phone Number.");
    }
    if (
      !validateAccountSite(accountData?.accountSite) &&
      accountData?.accountSite?.length > 0
    ) {
      setErrorMessage(setAccountSiteMsg, "Please enter a valid Account site.");
    }
    if (
      !websiteValidation(accountData.website) &&
      accountData?.website?.length > 0
    ) {
      setErrorMessage(setWebsiteMsg, "Please enter a valid website name.");
    }
    if (
      !validateAccountNumber(accountData.accountNumber) &&
      accountData?.accountNumber?.length > 0
    ) {
      setErrorMessage(
        setAccountNumberMsg,
        "Please enter a valid Account number."
      );
    }
    if (
      !validateTicketSymbol(accountData.tickerSymbol) &&
      accountData?.tickerSymbol?.length > 0
    ) {
      setErrorMessage(setTickerMsg, "Please enter a valid Ticker symbol.");
    }
    if (
      !validateNumberOfEmployees(accountData.employees) &&
      accountData?.employees?.length > 0
    ) {
      setErrorMessage(setEmployeeMsg, "Please enter a valid Employees Number.");
    }
    if (
      !validateAnnualRevenue(accountData.annualRevenue) &&
      accountData?.annualRevenue?.length > 0
    ) {
      setErrorMessage(
        setAnnualRevenueMsg,
        "Please enter a valid Annual revenue."
      );
    }
    if (
      !validateSICCode(accountData.sicCode) &&
      accountData?.sicCode?.length > 0
    ) {
      setErrorMessage(setSicCodeMsg, "Please enter a valid SIC code.");
    }
    if (
      accountData.billingCity &&
      !validateCityIndia(accountData?.billingCity)
    ) {
      setErrorMessage(setBillingCityMsg, "Please enter a valid Billing City.");
    }
    if (
      accountData.shippingCity &&
      !validateCityIndia(accountData?.shippingCity)
    ) {
      setErrorMessage(
        setShippingCityMsg,
        "Please enter a valid Shipping City."
      );
    }
    if (
      accountData.billingState &&
      !validateStateIndia(accountData.billingState)
    ) {
      setErrorMessage(
        setBillingStateMsg,
        "Please enter a valid Billing State."
      );
    }
    if (
      accountData.shippingState &&
      !validateStateIndia(accountData.shippingState)
    ) {
      setErrorMessage(
        setShippingStateMsg,
        "Please enter a valid Shipping State."
      );
    }
    if (
      accountData.billingCountry &&
      !validateCountryName(accountData.billingCountry)
    ) {
      setErrorMessage(
        setBillingCountryMsg,
        "Please enter a valid Billing Country."
      );
    }
    if (
      accountData.shippingCountry &&
      !validateCountryName(accountData.shippingCountry)
    ) {
      setErrorMessage(
        setShippingCountryMsg,
        "Please enter a valid Shipping Country."
      );
    }
    if (accountData.billingCode && !validateZipCode(accountData.billingCode)) {
      setErrorMessage(setCodeMsg, "Please enter a valid Billing Code.");
    }
    if (
      accountData.shippingCode &&
      !validateZipCode(accountData.shippingCode)
    ) {
      setErrorMessage(
        setShippingCodeMsg,
        "Please enter a valid Shipping Code."
      );
    }

    if (!isValidForm) return;
    const formData = new FormData();
    setSaveSubmit(true);
    const requestBody = {
      loggedInUserId: loginUserId,
      accountOwnerId: selectedItem.userId,
      accountId: accountId,
      accountName: removeExtraSpaces(accountUpdateInfo.accountName),
      accountSite: removeExtraSpaces(accountUpdateInfo.accountSite),
      parentAccountId: selectedAccountItem.accountId,
      accountNumber: removeExtraSpaces(accountUpdateInfo.accountNumber),
      accountType: accountUpdateInfo.accountType.value ||"",
      industry: accountUpdateInfo.industry.value,
      annualRevenue: Number(accountUpdateInfo.annualRevenue),
      rating: accountUpdateInfo.rating.value,
      phone: removeExtraSpaces(accountUpdateInfo.phone),
      fax: removeExtraSpaces(accountUpdateInfo.fax),
      website: removeExtraSpaces(accountUpdateInfo.website),
      tickerSymbol: removeExtraSpaces(accountUpdateInfo.tickerSymbol),
      ownership: accountUpdateInfo.ownership.value,
      employees: Number(accountUpdateInfo.employees),
      sicCode: removeExtraSpaces(accountUpdateInfo.sicCode),
      billingStreet: removeExtraSpaces(accountUpdateInfo.billingCity),
      billingCity: removeExtraSpaces(accountUpdateInfo.billingCity),
      billingState: removeExtraSpaces(accountUpdateInfo.billingState),
      billingCode: removeExtraSpaces(accountUpdateInfo.billingCode),
      billingCountry: removeExtraSpaces(accountUpdateInfo.billingCountry),
      shippingStreet: removeExtraSpaces(accountUpdateInfo.shippingStreet),
      shippingCity: removeExtraSpaces(accountUpdateInfo.shippingCity),
      shippingState: removeExtraSpaces(accountUpdateInfo.shippingState),
      shippingCode: removeExtraSpaces(accountUpdateInfo.shippingCode),
      shippingCountry: removeExtraSpaces(accountUpdateInfo.shippingCountry),
      description: removeExtraSpaces(accountUpdateInfo.description),
      emailNotification: emailNotification,
    };
    formData.append("accountDtlsInputs", JSON.stringify(requestBody));
    formData.append("accountImage", accountUpdateInfo.accountImage);
    const headersResult = getHeaders(true);

    // Check if headers result indicates an error
    if (headersResult.error) {
      navigate("../"); // Redirect to login if token is missing
      return;
    }

    await axios
      .post(URI.updateAccountDetails, formData, {
        headers: headersResult,
      })
      .then((response) => {
        if (response.data.status === 200) {
          NotificationManager.success("", response.data.message, 5000);
          setexistingAccount(false);
          accountResetValues();
          if (e === "SAVE") {
            setInnerPage("list");
            getAllAccountDetails(selectedOption);
          }
        } else {
          NotificationManager.error("", response.data.message, 5000);
        }
      })
      .catch(function (error) {
        handleApiError(error, navigate);
      })
      .finally(() => {
        setSaveSubmit(false);
      });
  };
  const handleUpdateClick = async (field) => {
    var value = "";
    let requestBody = {};
    const isObj =
      typeof accountInfoDetails[field] === "object" &&
      accountInfoDetails[field] !== null &&
      !Array.isArray(accountInfoDetails[field]);

    if (
      field === "industryInfo" ||
      field === "ratingInfo" ||
      field === "accountTypeInfo" ||
      field === "ownershipInfo" ||
      field === "rating" ||
      field === "accountType" ||
      field === "ownership" ||
      field === "industry"
    ) {
      value = updatedFields[field].value;
    } else {
      value = updatedFields[field];
    }
    const fieldMappings = {
      accountOwnerInfo: "accountOwner",
      // createdByInfo: "accountOwner",
      //modifiedByInfo: "accountOwner",
      industryInfo: "industry",
      sicCodeInfo: "sicCode",
      ratingInfo: "rating",
      accountTypeInfo: "accountType",
      ownershipInfo: "ownership",
      employeesInfo: "employees",
      accountSiteInfo: "accountSite", //
      billingStreetInfo: "billingStreet",
      faxInfo: "fax",
      billingCityInfo: "billingCity",
      billingStateInfo: "billingState",
      billingCodeInfo: "billingCode",
      billingCountryInfo: "billingCountry",
      shippingStreetInfo: "shippingStreet",
      shippingCityInfo: "shippingCity",
      shippingStateInfo: "shippingState",
      shippingCodeInfo: "shippingCode",
      shippingCountryInfo: "shippingCountry",
      descriptionInfo: "description",
      tickerSymbolInfo: "tickerSymbol",
      accountNumberInfo: "accountNumber", //
      annualRevenueInfo: "annualRevenue",
      phoneInfo: "phone",
    };
    // if (fieldMappings.hasOwnProperty(field)) {
    //   field = fieldMappings[field];
    // } not supported by eslint
    if (Object.hasOwn(fieldMappings, field)) {
      field = fieldMappings[field];
    }

    try {
      const formData = new FormData();
      if (field === "emailNotification") {
        requestBody = {
          loggedInUserId: loginUserId,
          accountId: accountId,
          accountOwnerId: isOwnerSelect
            ? selectedOwnerItem?.userId
            : selectedItem?.userId,
          emailNotification: emailNotification,
        };
      } else {
        requestBody = {
          accountId: accountId,
          loggedInUserId: loginUserId,
          [field]: field === "employees" ? parseInt(value) : value,

          // [field]: value,
        };
      }
      const compareValue = isObj
        ? accountInfoDetails[field].value
        : accountInfoDetails[field];

      if (value !== compareValue || field === "emailNotification") {
        setApiLoader(true);
        formData.append("accountDtlsInputs", JSON.stringify(requestBody));

        const headersResult = getHeaders(true);

        // Check if headers result indicates an error
        if (headersResult.error) {
          navigate("../"); // Redirect to login if token is missing
          return;
        }
        const response = await axios.post(
          URI.individualUpdateAccount,
          formData,
          {
            headers: headersResult,
          }
        );
        if (response.data.status === 200) {
          NotificationManager.success("", response.data.message, 5000);
          setAccountInfoDetails((prevDetails) => ({
            ...prevDetails,
            [field]: updatedFields[field],
          }));
          accountOverviewDetail(accountId);
          setIsOwnerSelect(false);
          setShowModalType("");
        } else {
          NotificationManager.error("", response.data.message, 5000);
        }
      }

      const resetFields = Object.fromEntries(
        Object.keys(editingFields).map((key) => [key, false])
      );
      setEditingFields({
        ...resetFields,
      });
      setShowType("");
    } catch (error) {
      handleApiError(error, navigate);
    } finally {
      setApiLoader(false);
    }
  };

  const getAllInfoShare = async (accountId) => {
    setIsLoading(true);
    const requestBody = {
      moduleId: accountId,
      loggedInUserId: loginUserId,
    };
    const headersResult = getHeaders(true);

    // Check if headers result indicates an error
    if (headersResult.error) {
      navigate("../"); // Redirect to login if token is missing
      return;
    }
    await axios
      .post(URI.getShareInfo, requestBody, {
        headers: headersResult,
      })
      .then((response) => {
        if (response.data.status === 200) {
          const hasSharedItems =
            (response.data.data.getSharingInfoUsers &&
              response.data.data.getSharingInfoUsers.length > 0) ||
            (response.data.data.getSharingInfoRoles &&
              response.data.data.getSharingInfoRoles.length > 0) ||
            (response.data.data.getSharingInfoGroups &&
              response.data.data.getSharingInfoGroups.length > 0)||
              (response.data.data.getSharingInfoPublic &&
                response.data.data.getSharingInfoPublic.length > 0);

          const userShareMemberIds = response.data.data.getSharingInfoUsers.map(
            (user) => user.shareModuleId
          );
          const roleShareMemberIds = response.data.data.getSharingInfoRoles.map(
            (role) => role.shareModuleId
          );
          const groupShareMemberIds =
            response.data.data.getSharingInfoGroups.map(
              (group) => group.shareModuleId
            );
            const groupPublicSharedMemberIds =
            response.data.data.getSharingInfoPublic.map(
              (user) => user.shareModuleId
            );
          setShareMemberIds([
            ...userShareMemberIds,
            ...roleShareMemberIds,
            ...groupShareMemberIds,
            ...groupPublicSharedMemberIds
          ]);

          setShareUserList(response.data.data.getSharingInfoUsers);
          setShareRoleList(response.data.data.getSharingInfoRoles);
          setShareGroupList(response.data.data.getSharingInfoGroups);
          setSharePublicList(response.data.data.getSharingInfoPublic);
          if (hasSharedItems) {
            setShareInfoModal(true);
          } else {
            setShareModal(true);
          }
          setIsLoading(false);
        }
      })
      .catch((err) => {
        handleApiError(err, navigate);
      });
  };

  const accountOverviewDetail = async (accountID) => {
    let accountInfoDetails = {};
    const headersResult = getHeaders(true);

    // Check if headers result indicates an error
    if (headersResult.error) {
      navigate("../"); // Redirect to login if token is missing
      return;
    }
    await axios
      .get(URI.accountOverview + "/" + loginUserId + "/" + accountID, {
        headers: headersResult,
      })
      .then((response) => {
        if (response.data.status === 200) {
          accountInfoDetails = response.data.data.accountInfo;
          accountInfoDetails.accountOwnerInfo =
            response.data.data.accountInfo.accountOwner;
          accountInfoDetails.createdByInfo =
            response.data.data.accountInfo.accountOwner;
          // accountInfoDetails.modifiedByInfo =
          //   response.data.data.accountInfo.accountOwner;

          accountInfoDetails.industryInfo = {
            label: response.data.data.accountInfo.industry,
            value: response.data.data.accountInfo.industry,
          };
          accountInfoDetails.ratingInfo = {
            label: response.data.data.accountInfo.rating,
            value: response.data.data.accountInfo.rating,
          };
          accountInfoDetails.accountTypeInfo = {
            label: response.data.data.accountInfo.accountType,
            value: response.data.data.accountInfo.accountType,
          };
          accountInfoDetails.ownershipInfo = {
            label: response.data.data.accountInfo.ownership,
            value: response.data.data.accountInfo.ownership,
          };

          accountInfoDetails.employeesInfo =
            response.data.data.accountInfo.employees;
          accountInfoDetails.accountSiteInfo =
            response.data.data.accountInfo.accountSite;
          accountInfoDetails.billingStreetInfo =
            response.data.data.accountInfo.billingStreet;
          accountInfoDetails.faxInfo = response.data.data.accountInfo.fax;
          accountInfoDetails.sicCodeInfo =
            response.data.data.accountInfo.sicCode;
          accountInfoDetails.billingCityInfo =
            response.data.data.accountInfo.billingCity;
          accountInfoDetails.billingStateInfo =
            response.data.data.accountInfo.billingState;
          accountInfoDetails.billingCodeInfo =
            response.data.data.accountInfo.billingCode;
          accountInfoDetails.billingCountryInfo =
            response.data.data.accountInfo.billingCountry;
          accountInfoDetails.shippingStreetInfo =
            response.data.data.accountInfo.shippingStreet;
          accountInfoDetails.shippingCityInfo =
            response.data.data.accountInfo.shippingCity;
          accountInfoDetails.shippingStateInfo =
            response.data.data.accountInfo.shippingState;
          accountInfoDetails.shippingCodeInfo =
            response.data.data.accountInfo.shippingCode;
          accountInfoDetails.shippingCountryInfo =
            response.data.data.accountInfo.shippingCountry;
          accountInfoDetails.descriptionInfo =
            response.data.data.accountInfo.description;
          accountInfoDetails.tickerSymbolInfo =
            response.data.data.accountInfo.tickerSymbol;
          accountInfoDetails.accountNumberInfo =
            response.data.data.accountInfo.accountNumber;
          accountInfoDetails.annualRevenueInfo =
            response.data.data.accountInfo.annualRevenue;
          accountInfoDetails.description =
            response.data.data.accountInfo.description;

          accountInfoDetails.phoneInfo = response.data.data.accountInfo.phone;

          accountInfoDetails.industry = {
            label: response.data.data.accountInfo.industry,
            value: response.data.data.accountInfo.industry,
          };
          accountInfoDetails.rating = {
            label: response.data.data.accountInfo.rating,
            value: response.data.data.accountInfo.rating,
          };
          accountInfoDetails.accountType = {
            label: response.data.data.accountInfo.accountType,
            value: response.data.data.accountInfo.accountType,
          };
          accountInfoDetails.ownership = {
            label: response.data.data.accountInfo.ownership,
            value: response.data.data.accountInfo.ownership,
          };
          accountInfoDetails.accountImage = response.data.data.accountImageData;

          // setaccountOverviewDetails(accountInfoDetails);
          setAccountInfoDetails(accountInfoDetails);
          // setSelectedItem({
          //   name: response.data.data.accountInfo.accountOwner,
          //   userId: response.data.data.accountInfo.accountOwnerId,
          // });
          setSelectedItem(
            userList.find(
              (user) =>
                user.userId === response.data.data.accountInfo.accountOwnerId
            )
          );
          setSelectedAccountItem({
            accountName: response.data.data.accountInfo.parentAccountName,
            accountId: response.data.data.accountInfo.parentAccountId,
          });

          const updatedFields = {
            accountOwner: response.data.data.accountInfo.accountOwner,
            industry: {
              label: response.data.data.accountInfo.industry,
              value: response.data.data.accountInfo.industry,
            },
            employees: response.data.data.accountInfo.employees,
            annualRevenue: response.data.data.accountInfo.annualRevenue,
            phone: response.data.data.accountInfo.phone,
            accountOwnerInfo: response.data.data.accountInfo.accountOwner,
            createdByInfo: response.data.data.accountInfo.accountOwner,
            // modifiedByInfo: response.data.data.accountInfo.accountOwner,
            industryInfo: {
              label: response.data.data.accountInfo.industry,
              value: response.data.data.accountInfo.industry,
            },
            ratingInfo: {
              label: response.data.data.accountInfo.rating,
              value: response.data.data.accountInfo.rating,
            },
            accountTypeInfo: {
              label: response.data.data.accountInfo.accountType,
              value: response.data.data.accountInfo.accountType,
            },
            ownershipInfo: {
              label: response.data.data.accountInfo.ownership,
              value: response.data.data.accountInfo.ownership,
            },
            employeesInfo: response.data.data.accountInfo.employees,
            accountSiteInfo: response.data.data.accountInfo.accountSite,
            sicCodeInfo: response.data.data.accountInfo.sicCode,
            billingStreetInfo: response.data.data.accountInfo.billingStreet,
            faxInfo: response.data.data.accountInfo.fax,
            billingCityInfo: response.data.data.accountInfo.billingCity,
            billingStateInfo: response.data.data.accountInfo.billingState,
            billingCodeInfo: response.data.data.accountInfo.billingCode,
            billingCountryInfo: response.data.data.accountInfo.billingCountry,
            shippingStreetInfo: response.data.data.accountInfo.shippingStreet,
            shippingCityInfo: response.data.data.accountInfo.shippingCity,
            shippingStateInfo: response.data.data.accountInfo.shippingState,
            shippingCodeInfo: response.data.data.accountInfo.shippingCode,
            shippingCountryInfo: response.data.data.accountInfo.shippingCountry,
            descriptionInfo: response.data.data.accountInfo.description,
            tickerSymbolInfo: response.data.data.accountInfo.tickerSymbol,
            accountNumberInfo: response.data.data.accountInfo.accountNumber,
            annualRevenueInfo: response.data.data.accountInfo.annualRevenue,
            phoneInfo: response.data.data.accountInfo.phone,
            accountName: response.data.data.accountInfo.accountName,
            accountSite: response.data.data.accountInfo.accountSite,
            fax: response.data.data.accountInfo.fax,
            parentAccount: response.data.data.accountInfo.parentAccount,
            website: response.data.data.accountInfo.website,
            accountNumber: response.data.data.accountInfo.accountNumber,
            tickerSymbol: response.data.data.accountInfo.tickerSymbol,
            sicCode: response.data.data.accountInfo.sicCode,
            rating: {
              label: response.data.data.accountInfo.rating,
              value: response.data.data.accountInfo.rating,
            },
            accountType: {
              label: response.data.data.accountInfo.accountType,
              value: response.data.data.accountInfo.accountType,
            },
            ownership: {
              label: response.data.data.accountInfo.ownership,
              value: response.data.data.accountInfo.ownership,
            },
            billingStreet: response.data.data.accountInfo.billingStreet,
            billingCity: response.data.data.accountInfo.billingCity,
            billingState: response.data.data.accountInfo.billingState,
            billingCode: response.data.data.accountInfo.billingCode,
            billingCountry: response.data.data.accountInfo.billingCountry,
            shippingStreet: response.data.data.accountInfo.shippingStreet,
            shippingCity: response.data.data.accountInfo.shippingCity,
            shippingState: response.data.data.accountInfo.shippingState,
            shippingCode: response.data.data.accountInfo.shippingCode,
            shippingCountry: response.data.data.accountInfo.shippingCountry,
            description: response.data.data.accountInfo.description,
            accountImage: response.data.data.accountInfo.accountImageData,
          };
          setUpdatedFields(updatedFields);
          setProductsList(response.data.data.productsList);
          setOpenActivities(response.data.data.openTasksList);
          setOpenActivitiesCalls(response.data.data.openActivitiesCall);
          setOpenActivitiesMeetings(response.data.data.openActivitiesMeetings);
          setCloseActivities(response.data.data.closedTasksList);
          setClosedActivitiesCall(response.data.data.closedActivitiesCall);
          setClosedActivitiesMeetings(
            response.data.data.closedActivitiesMeetings
          );
          setInnerPage("overview");
          setFilterContactDetails(response.data.data.contactsList);
          setFilterQuotesDetails(response.data.data.quotesList);
          setFilterDealsDetails(response.data.data.dealsList);
          setFilterInvoicesDetails(response.data.data.invoiceList);
          setFilterSalesDetails(response.data.data.salesList);

          // getAllAccountDetails(selectedOption);
        } else {
          NotificationManager.error("", response.data.message, 5000);
        }
      })
      .catch(async (error) => {
        handleApiError(error, navigate);
      });
  };

  const handleChange = (selectedOption) => {
    getAllAccountDetails(selectedOption);
    // setSelectedOption(selectedOption);
  };

  const handleChangeIndDropDown = (selectedOption, field) => {
    setUpdatedFields((prevFields) => ({
      ...prevFields,
      [field]: selectedOption,
    }));
  };

  const handleIndividualInputChange = (field, value) => {
    setUpdatedFields((prevFields) => ({
      ...prevFields,
      [field]: value,
    }));
  };
  const handleEditClick = (field) => {
    setEditingFields((prevFields) => ({
      ...prevFields,
      [field]: true,
    }));
  };

  const handleCancelClick = (field) => {
    setEditingFields((prevFields) => ({
      ...prevFields,
      [field]: false,
    }));
  };

  const handleOwnerInputClick = (e) => {
    e.stopPropagation();
    setListVisible(!listVisible);
  };

  const handleUserItemClick = (item) => {
    // if (existingAccount) {
    //   setSelectUpdateItem(item);
    //   setSelectedItem(item);
    // } else {
    //   setSelectedItem(item);
    // }
    setSelectedItem(item);
    setListVisible(false);
  };

  const handleSelectedLeadChange = (value) => {
    setSelectedItem(value);
    setSelectedOwnerItem(value);
  };

  // handle Parent Account

  // const handleSelectParentList = (value) => {
  //   // eslint-disable-next-line no-console
  //   console.log("value",value)
  //   setSelectedAccountItem(value); // Update the selected lead value
  // };

  const handleOwnerParentInputClick = (e) => {
    e.stopPropagation();
    setParentListVisible(!parentlistVisible);
  };

  const handleParentUserItemClick = (item) => {
    // if (existingAccount) {
    //   setSelectUpdateItem(item);
    // } else {
    //   setSelectedAccountItem(item);
    // }
    setSelectedAccountItem(item);
    setParentListVisible(false);
  };

  // const handleCloseClick = (field) => {
  //   setUpdatedFields((prevFields) => ({
  //     ...prevFields,
  //     [field]: accountInfoDetails[field],
  //   }));
  //   setEditingFields((prevFields) => ({
  //     ...prevFields,
  //     [field]: false,
  //   }));
  // };

  const handleChangeOwnerInputClick = (e) => {
    e.stopPropagation();
    setIsChangeOwnerList(!isChangeOwnerList);

    // setInvidualListVisible(!individualListVisible);
  };

  const handleChangeOwnerItemClick = (item) => {
    setSelectedOwnerItem(item);
    setIsChangeOwnerList(false);
    setIsOwnerSelect(true);
    // setIsSelectedOwner(false);
  };

  React.useEffect(() => {
    getAllAccountDetails(selectedOption);
  }, [currentPage, perPage]);

  React.useEffect(() => {
    setInnerPage(innerPage || "list");
  }, []);

  useEffect(() => {
    const foundItem = userList.find((item) => item.userId === loginUserId);
    if (foundItem) {
      setSelectedItem(foundItem);
    }
  }, [loginUserId]);

  const deleteAccountDetails = async () => {
    const requestBody = {
      loggedInUserId: loginUserId,
      accountId: accountId,
    };
    const headersResult = getHeaders(true);

    // Check if headers result indicates an error
    if (headersResult.error) {
      navigate("../"); // Redirect to login if token is missing
      return;
    }
    await axios
      .post(URI.deleteAccountDetails, requestBody, {
        headers: headersResult,
      })
      .then((response) => {
        if (response.data.status === 200) {
          setInnerPage("list");
          setDeleteModal(false);
          getAllAccountDetails(selectedOption);
        } else {
          NotificationManager.error("", response.data.message, 5000);
        }
      })
      .catch(async (error) => {
        handleApiError(error, navigate);
      });
  };

  const removeImage = async () => {
    setImageLoader(true);
    const requestBody = {
      loggedInUserId: loginUserId,
      accountId: accountId,
    };
    const headersResult = getHeaders(true);

    // Check if headers result indicates an error
    if (headersResult.error) {
      navigate("../"); // Redirect to login if token is missing
      return;
    }
    await axios
      .post(URI.removeImage, requestBody, {
        headers: headersResult,
      })
      .then((response) => {
        if (response.data.status === 200) {
          NotificationManager.success("", response.data.message, 5000);
          setTimeout(() => {
            setDeleteMsg("");
            setShowImageModal(false);
            setShowRemoveModal(false);
            accountOverviewDetail(accountId);
          }, 3000);
        } else {
          NotificationManager.error("", response.data.message, 5000);
        }
      })
      .catch(async (error) => {
        handleApiError(error, navigate);
      })
      .finally(() => setImageLoader(false));
  };
  const uploadImage = async () => {
    setImageLoader(true);
    const formData = new FormData();
    const requestBody = {
      loggedInUserId: loginUserId,
      accountSite: "bangalore office",
      accountId: accountId,
    };
    formData.append("accountDtlsInputs", JSON.stringify(requestBody));
    formData.append(
      "accountImage",
      uploadImg ? selectedFiles : accountInfoDetails.accountImageData
    );
    const headersResult = getHeaders(true);

    // Check if headers result indicates an error
    if (headersResult.error) {
      navigate("../"); // Redirect to login if token is missing
      return;
    }
    await axios
      .post(URI.individualUpdateAccount, formData, {
        headers: headersResult,
      })
      .then((response) => {
        if (response.data.status === 200) {
          accountOverviewDetail(accountId);
          setShowImageModal(false);
          NotificationManager.success("", response.data.message, 5000);
        } else {
          NotificationManager.error("", response.data.message, 5000);
        }
      })
      .catch(async (error) => {
        handleApiError(error, navigate);
      })
      .finally(() => setImageLoader(false));
  };

  useEffect(() => {
    if (fetchAccInfo && innerPage === "overview") {
      accountOverviewDetail(fetchAccInfo?.accountId);
      setAccountId(fetchAccInfo?.accountId);
    }
  }, []);

  const nodataPage = () => {
    return (
      <div className="d-flex flex-column justify-content-center align-items-center  ">
        <img src={noDataAccount} alt="img" height={200} width={200} />
        <h5 className="opacity-75">Manage Account</h5>
        <p className="opacity-75 p-0 m-0">
          Quotes are legal agreements between a customer and a vector
        </p>
        <p className="opacity-75 p-0 m-0">
          to deliver the requested product(s) at the agreed upon price
        </p>
        <p className="opacity-75 p-0 m-0">within the specified time period.</p>
        <button
          className="createContactButtonSave px-2 mt-4 cursorPointer"
          style={{ width: "15%" }}
          onClick={handleCreateAccount}
        >
          Create a Account
        </button>
      </div>
    );
  };

  const handleContactsView = () => {
    if (innerPage === "list") {
      return (
        <div>
          {isLoading ? (
            <>
              <Loader />
            </>
          ) : (
            <>
              {allList &&
              allList.length === 0 &&
              selectedOption.value === "all_accounts" ? (
                <>{nodataPage()}</>
              ) : (
                <>
                  <div className="creteContactBar">
                    {selectedCheckboxes.length > 0 ? (
                      <div className="d-flex w-50 align-items-center gap-2">
                        <Select
                          options={massOptions}
                          styles={customCommonStyles("100%")}
                          value={{
                            value: "Action",
                            label: "Action",
                          }}
                          onChange={(option) => {
                            // setDropdownTwo(option);
                            if (option.value === "Mass Update") {
                              setShowSearchFields(accountColumn);
                            } else if (option.value === "Mass Transfer") {
                              setSelectedItem(userList[0]);
                            }
                            setMassUpdateValue("");
                            setShowModalType(option.value);
                            setListVisible(false);
                          }}
                          className="w-25"
                          isSearchable={false}
                        />
                        {/* <Select
                      options={massOptions}
                      styles={customSelectLocationStyles}
                      value={dropdownTwo}
                      onChange={(option) => {
                        setDropdownTwo(option);
                      }}
                    /> */}
                      </div>
                    ) : (
                      <>
                        <div className="d-flex w-50 align-items-center gap-2">
                          {/* <img
                      src={filterIcon}
                      height={25}
                      className="contactFilter"
                      alt=""
                    /> */}
                          <Select
                            options={options}
                            styles={customCommonStyles("225px")}
                            value={selectedOption}
                            onChange={handleChange}
                            isSearchable={false}
                          />
                        </div>
                        <div className="d-flex w-50 justify-content-end ">
                          {/* <div class="btn-group"> */}
                          <button
                            className="createContactButtonSave py-2"
                            // style={{
                            //   background:
                            //     "linear-gradient(86.07deg, #407BFF 0%, #12A7FE 100%)",
                            //   borderRight: "2px solid #fff",
                            //   color: "#fff",
                            // }}
                            onClick={handleCreateAccount}
                          >
                            Create Account
                          </button>
                          {/* <button
                              type="button"
                              class="btn btn-sm  dropdown-toggle dropdown-toggle-split"
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                              style={{
                                background:
                                  "linear-gradient(86.07deg, #407BFF 0%, #12A7FE 100%)",
                                color: "#fff",
                              }}
                            >
                              <span class="visually-hidden">
                                Toggle Dropdown
                              </span>
                            </button>
                            <ul class="dropdown-menu">
                              <li>
                                <a class="dropdown-item ">Import Leads</a>
                              </li>
                              <li>
                                <a class="dropdown-item ">Import Notes</a>
                              </li>
                            </ul> */}
                          {/* </div> */}
                        </div>
                      </>
                    )}
                  </div>

                  <div className="totalRecordBarContact">
                    <div className="d-flex w-50">
                      {selectedCheckboxes.length > 0
                        ? `${selectedCheckboxes.length} Records Selected`
                        : `Total Records : ${totalItems}`}
                    </div>
                    <div className="d-flex w-50 align-items-center justify-content-end gap-2">
                      {selectedCheckboxes.length === 0 ? (
                        allList.length !== 0 && (
                          <Select
                            options={pageOption}
                            styles={customCommonStyles("100%")}
                            value={pageOption.find(
                              (option) => option.value === perPage.toString()
                            )}
                            onChange={handlePageDropDowmChange}
                            isSearchable={false}
                          />
                        )
                      ) : (
                        <></>
                      )}
                      {allList.length !== 0 && (
                        <Pagination
                          currentPage={currentPage}
                          totalPages={totalPages}
                          onPageChange={handlePageChange}
                        />
                      )}
                    </div>
                  </div>
                  <div className="contactMain">
                    {/* <div className="contactLead">
            <h6 style={{ borderBottom: "2px solid #316AFF" }}>
              Filter Accounts by
            </h6>
            <input
              type="text"
              placeholder="Search"
              className="contactSearch"
            />
            <div className="checkboxItemAccount">
              <h6 style={{ color: "#515151" }}>▾ System Defined Filters</h6>
              <input
                type="checkbox"
                id="vehicle1"
                name="vehicle1"
                value="Bike"
              />
              &nbsp;
              <label for="vehicle1">Touched Record</label>
              <br />
              <input
                type="checkbox"
                id="vehicle1"
                name="vehicle1"
                value="Bike"
              />
              &nbsp;
              <label for="vehicle1">Untouched Record</label>
              <br />
              <input
                type="checkbox"
                id="vehicle1"
                name="vehicle1"
                value="Bike"
              />
              &nbsp;
              <label for="vehicle1">Record Action</label>
              <br />
              <input
                type="checkbox"
                id="vehicle1"
                name="vehicle1"
                value="Bike"
              />
              &nbsp;
              <label for="vehicle1">Touched Record</label>
              <br />
              <input
                type="checkbox"
                id="vehicle1"
                name="vehicle1"
                value="Bike"
              />
              &nbsp;
              <label for="vehicle1">Untouched Record</label>
              <br />
              <input
                type="checkbox"
                id="vehicle1"
                name="vehicle1"
                value="Bike"
              />
              &nbsp;
              <label for="vehicle1">Record Action</label>
              <br />
              <h6 style={{ color: "#515151", marginTop: "10px" }}>
                ▾ Filter By Fields
              </h6>
              <input
                type="checkbox"
                id="vehicle1"
                name="vehicle1"
                value="Bike"
              />
              &nbsp;
              <label for="vehicle1">Annual Revenue</label>
              <br />
              <input
                type="checkbox"
                id="vehicle1"
                name="vehicle1"
                value="Bike"
              />
              &nbsp;
              <label for="vehicle1">City</label>
              <br />
              <input
                type="checkbox"
                id="vehicle1"
                name="vehicle1"
                value="Bike"
              />
              &nbsp;
              <label for="vehicle1">Company</label>
              <br />
              <input
                type="checkbox"
                id="vehicle1"
                name="vehicle1"
                value="Bike"
              />
              &nbsp;
              <label for="vehicle1">Annual Revenue</label>
              <br />
              <input
                type="checkbox"
                id="vehicle1"
                name="vehicle1"
                value="Bike"
              />
              &nbsp;
              <label for="vehicle1">City</label>
              <br />
              <input
                type="checkbox"
                id="vehicle1"
                name="vehicle1"
                value="Bike"
              />
              &nbsp;
              <label for="vehicle1">Company</label>
              <br />
            </div>
          </div> */}
                    <div className="leadListTable">
                      <table className="table leadTable">
                        <thead>
                          <tr>
                            <th scope="col">
                              <input
                                className="cursorPointer"
                                type="checkbox"
                                id="all"
                                name="all"
                                // checked={
                                //   selectedCheckboxes.length ===
                                //   filteredLeadDetails.length
                                // }
                                // onChange={(e) => {
                                //   if (e.target.checked) {
                                //     const allList = filteredLeadDetails.map(
                                //       (item) => ({ moduleId: item.accountId })
                                //     );
                                //     setSelectedCheckboxes(allList);
                                //   } else {
                                //     setSelectedCheckboxes([]);
                                //   }
                                // }}
                                checked={filteredLeadDetails.every((selected) =>
                                  selectedCheckboxes.some(
                                    (list) =>
                                      list.moduleId === selected.accountId
                                  )
                                )}
                                onChange={(e) => {
                                  const allList = filteredLeadDetails.map(
                                    (item) => ({
                                      moduleId: item.accountId,
                                    })
                                  );
                                  if (e.target.checked) {
                                    const newSelections = allList.filter(
                                      (newItem) =>
                                        !selectedCheckboxes.some(
                                          (existingItem) =>
                                            existingItem.moduleId ===
                                            newItem.moduleId
                                        )
                                    );
                                    setSelectedCheckboxes((prev) => [
                                      ...prev,
                                      ...newSelections,
                                    ]);
                                  } else {
                                    setSelectedCheckboxes(
                                      selectedCheckboxes.filter(
                                        (aItem) =>
                                          !allList.some(
                                            (bItem) =>
                                              aItem.moduleId === bItem.moduleId
                                          )
                                      )
                                    );
                                  }
                                }}
                              />
                            </th>
                            {selectedHeadings.map(
                              (heading) =>
                                heading.isChecked && (
                                  <th scope="col" key={heading.name}>
                                    <span className="pe-3">{heading.name}</span>
                                    <span className="position-relative">
                                      <img
                                        src={tableFilterIcon}
                                        alt=""
                                        className="cursorPointer"
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          setShowHeadings(false);
                                          setShowSelectedFilter(heading.name);
                                        }}
                                      />
                                      {heading.name === showSelectedFilter && (
                                        <span
                                          className="position-absolute rounded-2 border bg-light top-0 mt-4"
                                          style={{ right: "-10%" }}
                                        >
                                          <span className="d-flex flex-column px-1">
                                            {/* Ascending filter */}
                                            <span
                                              className="my-1 px-1 cursorPointer fw-normal text-start filter_type"
                                              onClick={() => {
                                                if (filterType === "asc") {
                                                  setFilterType("");
                                                  setFilteredLeadDetails(
                                                    allList
                                                  );
                                                } else {
                                                  setFilterType("asc");
                                                  sortedColumnsData(
                                                    "asc",
                                                    heading.value
                                                  );
                                                }
                                              }}
                                            >
                                              {filterType === "asc" ? (
                                                <img
                                                  src={cancel}
                                                  alt="asc"
                                                  className="opacity-75 pe-1"
                                                />
                                              ) : (
                                                <img
                                                  src={arrowUp}
                                                  alt="asc"
                                                  className="opacity-75 pe-1"
                                                />
                                              )}
                                              Asc
                                            </span>
                                            {/* Descending filter */}
                                            <span
                                              className="my-2 px-1 cursorPointer fw-normal text-start filter_type"
                                              onClick={() => {
                                                if (filterType === "desc") {
                                                  setFilterType("");
                                                  setFilteredLeadDetails(
                                                    allList
                                                  );
                                                } else {
                                                  setFilterType("desc");
                                                  sortedColumnsData(
                                                    "desc",
                                                    heading.value
                                                  );
                                                }
                                              }}
                                            >
                                              {filterType === "desc" ? (
                                                <img
                                                  src={cancel}
                                                  alt="desc"
                                                  className="opacity-75 pe-1"
                                                />
                                              ) : (
                                                <img
                                                  src={arrowDown}
                                                  alt="desc"
                                                  className="opacity-75 pe-1"
                                                />
                                              )}
                                              Desc
                                            </span>
                                            <span
                                              className={`my-1 px-1 fw-normal text-start filter_type ${
                                                selectedHeadings[0].name ===
                                                  "Account Name" &&
                                                heading.name === "Account Name"
                                                  ? "opacity-50"
                                                  : "cursorPointer"
                                              }`}
                                              onClick={() => {
                                                if (
                                                  pinnedColumn !==
                                                  "Account Name"
                                                ) {
                                                  setPinnedColumn(
                                                    "Account Name"
                                                  );
                                                  handlePinColumn(
                                                    "Account Name"
                                                  );
                                                } else {
                                                  setPinnedColumn(heading.name);
                                                  handlePinColumn(heading.name);
                                                }
                                              }}
                                            >
                                              <img
                                                src={
                                                  pinnedColumn === heading.name
                                                    ? unPin
                                                    : pin
                                                }
                                                alt="pin"
                                                className="opacity-75 pe-1"
                                              />{" "}
                                              {pinnedColumn === heading.name
                                                ? "Unpin"
                                                : "Pin"}{" "}
                                              Column
                                            </span>
                                          </span>
                                        </span>
                                      )}
                                      <img
                                        src={filterLine}
                                        alt=""
                                        className="ps-2"
                                      />
                                    </span>
                                  </th>
                                )
                            )}
                            <th
                              scope="col"
                              className="text-end cursorPointer position-relative"
                            >
                              <img
                                src={filterMenu}
                                alt=""
                                className={`filter_menu ${
                                  showHeadings ? "bg-light" : ""
                                }`}
                                onClick={(e) => {
                                  e.stopPropagation();
                                  setShowSelectedFilter("");
                                  setShowHeadings(true);
                                }}
                              />
                              {showHeadings && (
                                <div
                                  className="position-absolute rounded-2  border border-primary bg-light"
                                  style={{
                                    right: "0.2rem",
                                  }}
                                >
                                  <div className="d-flex flex-column px-2">
                                    <span
                                      className="p-2 cursorPointer fw-normal text-start"
                                      onClick={() => {
                                        setSearchColumn("");
                                        setSavingHeadings(selectedHeadings);
                                        setShowModalType("Manage Columns");
                                      }}
                                    >
                                      Manage Columns
                                    </span>
                                    <span
                                      className="p-2 fw-normal text-start opacity-50"
                                      onClick={() => {
                                        setShowModalType("Reset Column Size");
                                      }}
                                    >
                                      Reset Column Size
                                    </span>
                                  </div>
                                </div>
                              )}
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {filteredLeadDetails &&
                          filteredLeadDetails.length > 0 ? (
                            filteredLeadDetails.map((item, i) => {
                              return (
                                <tr key={i} className="">
                                  <td>
                                    <input
                                      className="cursorPointer"
                                      type="checkbox"
                                      id="vehicle1"
                                      name="vehicle1"
                                      value={item.accountId}
                                      // checked={selectedCheckboxes.includes(
                                      //   item.accountId
                                      // )}
                                      checked={selectedCheckboxes.some(
                                        (checkbox) =>
                                          checkbox.moduleId === item.accountId
                                      )}
                                      onChange={handleSelectCheckboxChange}
                                    />
                                  </td>
                                  <React.Fragment>
                                    {selectedHeadings.map((obj, i) => {
                                      const value = obj.value;
                                      const itemValue = item[value];
                                      return obj.isChecked ? (
                                        value === "transHash" ? (
                                          <td
                                            style={{ wordBreak: "break-all" }}
                                            className="overflow_text_hash"
                                            key={itemValue}
                                          >
                                            <a
                                              rel="noreferrer"
                                              href={
                                                EXPLORER_URL +
                                                "about-tx-hash/" +
                                                item.transHash
                                              }
                                              className="anchorLinkHash textColor"
                                              data-title={item.transHash}
                                              target="_blank"
                                            >
                                              {item.transHash
                                                ? item.transHash.slice(0, 10) +
                                                  "...."
                                                : "-"}
                                            </a>
                                          </td>
                                        ) : (
                                          <td
                                            key={i}
                                            onClick={() => {
                                              if (value === "accountName") {
                                                accountOverviewDetail(
                                                  item.accountId
                                                );
                                                setAccountId(item.accountId);
                                                getNotesDetails(
                                                  item.accountId,
                                                  selectRecent
                                                );
                                                setSelectedLeadIndex(
                                                  allList.findIndex(
                                                    (acc) =>
                                                      acc.accountId ===
                                                      item.accountId
                                                  )
                                                );
                                              }
                                              setFetchAccInfo(item);
                                            }}
                                            className={`overflow_text ${
                                              value === "accountName"
                                                ? "leadName cursorPointer"
                                                : ""
                                            }`}
                                            style={{ maxWidth: "3vw" }}
                                          >
                                            {itemValue !== null
                                              ? itemValue
                                              : "-"}
                                          </td>
                                        )
                                      ) : null;
                                    })}
                                  </React.Fragment>
                                  <td></td>
                                </tr>
                              );
                            })
                          ) : (
                            <tr>
                              <td
                                colSpan={selectedHeadings.length + 2}
                                style={{ textAlign: "center" }}
                              >
                                No Data
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </>
              )}
            </>
          )}
        </div>
      );
    } else if (["create", "clone"].includes(innerPage)) {
      return (
        <>
          <div className="createContactButtonBar">
            <div className="d-flex align-items-center w-50 gap-2">
              <img src={groupIcon} height={25} />
              <div className="d-flex flex-column">
                <p className="mb-0">
                  {existingAccount ? "Edit Account" : "Create Account"}
                </p>
                {/* <p
                  className="mb-0"
                  style={{
                    color: "#529CF3",
                    fontSize: "12px",
                    width: "fit-content",
                  }}
                >
                  Edit Layout
                </p> */}
              </div>
            </div>
            <div className="d-flex w-50 gap-2 justify-content-end">
              <button
                className="createContactButton"
                onClick={() => {
                  if (pastViewInfo) {
                    setCurrentView(pastViewInfo.view);
                    setInnerPage(pastViewInfo.innerPage);
                    setPastViewInfo((prev) => ({
                      ...prev,
                      view: "",
                      innerPage: "",
                      undo: currentView, // it will set the current view of the page so when we cancel or save it will go to same section in the page
                    }));
                    return;
                  }
                  accountResetValues();
                  setInnerPage("list");
                  setexistingAccount(false);
                  setSelectedFiles(null);
                  fileInputRef.current.value = "";
                  if (innerPage === "clone" && !existingAccount) {
                    setInnerPage("overview");
                  }

                  if (existingAccount) {
                    setInnerPage("overview");
                  }
                }}
              >
                Cancel
              </button>
              {existingAccount ? (
                <></>
              ) : (
                <button
                  className="createContactButton"
                  onClick={() => {
                    handleSaveButton("SAVE AND NEW");
                  }}
                  disabled={saveAndNew}
                >
                  {saveAndNew ? (
                    <Lottie
                      options={defaultOptions("blue")}
                      height="24px"
                      width="36px"
                    />
                  ) : (
                    "Save and New"
                  )}
                </button>
              )}

              <button
                className="createContactButtonSave"
                type="submit"
                disabled={saveSubmit}
                onClick={() => {
                  if (existingAccount) {
                    handleUpdateAccount("SAVE");
                  } else if (existingClone) {
                    handleSaveButton("ADD");
                  } else {
                    handleSaveButton("SAVE");
                  }
                }}
              >
                {/* {existingAccount ? "UPDATE" : "SAVE"} */}
                {saveSubmit ? (
                  <Lottie
                    options={defaultOptions("white")}
                    height="30px"
                    width="36px"
                  />
                ) : (
                  updateButtonLabel
                )}
              </button>
            </div>
          </div>
          <div className="leadFields">
            <h6 className="py-2" style={{ borderBottom: "2px solid #316AFF" }}>
              Account Information
            </h6>
            <div className="inputFieldDiv">
              <div
                className={`form-group ${isDragging ? "drag-over" : ""}`}
                onDragOver={handleDragOver}
                onDragLeave={handleDragLeave}
                onDrop={handleDrop}
              >
                <p className="mb-0">Upload Image</p>
                <input
                  type="file"
                  id="accountImage"
                  name="accountImage"
                  accept=".png, .jpg, .jpeg, .gif"
                  className="file-control"
                  onChange={handleAccountImageChange}
                  ref={fileInputRef}
                  style={{ display: "none" }}
                />
                <label htmlFor="accountImage" className="label-file">
                  <span style={{ fontSize: "15px" }}>
                    {selectedFiles
                      ? selectedFiles.name.length > 30
                        ? `${selectedFiles.name.substring(0, 30)}...`
                        : selectedFiles.name
                      : "Drag and Drop files (JPG,PNG)"}
                  </span>
                </label>
                {imageMsg && <p className="mb-0 small erro_msg">{imageMsg}</p>}
              </div>
            </div>
            <div className="inputFieldDiv selectBox">
              <div className="inputFieldDivRight position-relative">
                <p className="mb-0">
                  Account Owner <span className="text-danger">*</span>
                </p>
                <div className="d-flex input-with-clear ">
                  <input
                    type="text"
                    className="leadInputField cursorPointer"
                    value={selectedItem?.name || ""}
                    onClick={handleOwnerInputClick}
                    readOnly
                  />
                  {existingAccount ? (
                    <></>
                  ) : (
                    <span
                      className="clear-button"
                      onClick={(e) => {
                        handleOwnerInputClick(e);
                      }}
                    >
                      <img
                        src={listVisible ? up : down}
                        alt="img"
                        height={20}
                        width={20}
                      />
                    </span>
                  )}

                  <span
                    className="cursorPointer pt-2 ps-1"
                    onClick={() => {
                      if (existingAccount) {
                        setShowModalType("changeOwner");
                        setListVisible(false);
                      } else {
                        setIsLeadOwnerModal(!isLeadOwnerModal);
                      }
                    }}
                    // onClick={() => setIsLeadOwnerModal(!isLeadOwnerModal)}
                  >
                    <img src={userSearch} alt="img" width={20} height={20} />
                  </span>
                </div>
                {existingAccount ? (
                  <></>
                ) : (
                  <>
                    {listVisible && (
                      <ul
                        className="data-list list-group  lead_ownerList_container position-absolute"
                        style={{ top: "6rem" }}
                        onClick={(e) => e.stopPropagation()}
                      >
                        {userList.map((item, index) => (
                          <li
                            key={index}
                            className="cursorPointer list-group-item"
                            onClick={() => handleUserItemClick(item)}
                          >
                            <div className="d-flex flex-column">
                              <span> {item.name} </span>
                              <span> {item.emailId} </span>
                            </div>
                          </li>
                        ))}
                      </ul>
                    )}
                  </>
                )}
              </div>
              <div className="inputFieldDivRight">
                <p className="mb-0">Rating</p>
                <Select
                  value={
                    existingAccount || existingClone
                      ? accountUpdateInfo.rating
                      : accountInfo.rating
                  }
                  menuPlacement="auto"
                  onChange={(selectedOption) =>
                    handleChangeSelectDropDown(selectedOption, "rating")
                  }
                  options={ratingOptions}
                  styles={createAccountStyles}
                  isSearchable={false}
                />
              </div>
            </div>
            <div className="inputFieldDiv selectBox">
              <div className="inputFieldDivLeft">
                <p className="mb-0">
                  Account Name <span className="text-danger">*</span>
                </p>
                <input
                  type="text"
                  maxLength={50}
                  name="accountName"
                  className={
                    mandatoryMsg ? "mandatoryField" : "insertCommonInputField"
                  }
                  value={
                    existingAccount || existingClone
                      ? accountUpdateInfo.accountName
                      : accountInfo.accountName
                  }
                  onChange={handleChangeAccount}
                />
                <p style={{ color: "#fa5a5a" }}>
                  {mandatoryMsg && mandatoryMsg}
                </p>
              </div>
              <div className="inputFieldDivRight">
                <p className="mb-0">Phone</p>
                <input
                  type="text"
                  className="insertCommonInputField"
                  pattern="[0-9]*"
                  maxLength={10}
                  autoComplete="off"
                  name="phone"
                  value={
                    existingAccount || existingClone
                      ? accountUpdateInfo.phone
                      : accountInfo.phone
                  }
                  // onKeyDown={(e) => {
                  //   // Allow only numeric input
                  //   if (
                  //     !/\d/.test(e.key) &&
                  //     e.key !== "Backspace" &&
                  //     e.key !== "Delete" &&
                  //     e.key !== "ArrowLeft" &&
                  //     e.key !== "ArrowRight"
                  //   ) {
                  //     e.preventDefault();
                  //   }
                  // }}
                  onChange={handleChangeAccount}
                />
                <span className="small erro_msg">{phoneMsg}</span>
              </div>
            </div>
            <div className="inputFieldDiv">
              <div className="inputFieldDivLeft">
                <p className="mb-0">Account Site</p>
                <input
                  type="text"
                  maxLength={80}
                  name="accountSite"
                  className="insertCommonInputField"
                  value={
                    existingAccount || existingClone
                      ? accountUpdateInfo.accountSite
                      : accountInfo.accountSite
                  }
                  onChange={handleChangeAccount}
                />
                <span className="small erro_msg">{accountSiteMsg}</span>
              </div>
              <div className="inputFieldDivRight">
                <p className="mb-0">Fax</p>
                <input
                  type="text"
                  className="insertCommonInputField"
                  maxLength={12}
                  autoComplete="off"
                  value={
                    existingAccount || existingClone
                      ? accountUpdateInfo.fax
                      : accountInfo.fax
                  }
                  name="fax"
                  onChange={handleChangeAccount}
                  // onKeyDown={(e) => {
                  //   if (
                  //     !/[a-zA-Z0-9]/.test(e.key) &&
                  //     e.key !== "Backspace" &&
                  //     e.key !== "Delete" &&
                  //     e.key !== "ArrowLeft" &&
                  //     e.key !== "ArrowRight" &&
                  //     e.key !== "Tab" &&
                  //     e.key !== "Enter"
                  //   ) {
                  //     e.preventDefault();
                  //   }
                  // }}
                />
              </div>
            </div>
            <div className="inputFieldDiv selectBox">
              <div className="inputFieldDivRight position-relative">
                <p className="mb-0"> Parent Account</p>
                <div
                  className="d-flex input-with-clear "
                  onClick={handleOwnerParentInputClick}
                >
                  <input
                    type="text"
                    className="leadInputField cursorPointer"
                    value={selectedAccountItem?.accountName || ""}
                    // onClick={handleOwnerParentInputClick}
                    readOnly
                  />
                  <span
                    className="clear-button"
                    onClick={() =>
                      selectedAccountItem && setSelectedAccountItem("")
                    }
                  >
                    <img
                      src={parentlistVisible ? up : down}
                      alt="img"
                      height={20}
                      width={20}
                    />
                  </span>

                  <span
                    className="cursorPointer pt-2 ps-1"
                    onClick={(e) => {
                      e.stopPropagation();
                      setIsAccountListModal(!isAccountListModal);
                    }}
                  >
                    <img src={accountsIcon} alt="img" width={25} height={25} />
                  </span>
                </div>
                {parentlistVisible && (
                  <ul
                    className="data-list list-group  lead_ownerList_container position-absolute"
                    style={{ top: "6rem" }}
                    onClick={(e) => e.stopPropagation()}
                  >
                    {allList.map((item, index) => (
                      <li
                        key={index}
                        className="cursorPointer list-group-item"
                        onClick={() => handleParentUserItemClick(item)}
                      >
                        <div className="d-flex flex-column">
                          <span> {item.accountName} </span>
                          {/* <span> {item.emailId} </span> */}
                        </div>
                      </li>
                    ))}
                  </ul>
                )}
              </div>
              <div className="inputFieldDivRight">
                <p className="mb-0">Website</p>
                <input
                  type="text"
                  className={
                    websiteMsg ? "mandatoryField" : "insertCommonInputField"
                  }
                  value={
                    existingAccount || existingClone
                      ? accountUpdateInfo.website
                      : accountInfo.website
                  }
                  name="website"
                  onChange={handleChangeAccount}
                />
                <p style={{ color: "#fa5a5a" }}>{websiteMsg && websiteMsg}</p>
              </div>
            </div>
            <div className="inputFieldDiv">
              <div className="inputFieldDivLeft">
                <p className="mb-0">Account Number</p>
                <input
                  type="text"
                  className="insertCommonInputField"
                  maxLength={20}
                  autoComplete="off"
                  value={
                    existingAccount || existingClone
                      ? accountUpdateInfo.accountNumber
                      : accountInfo.accountNumber
                  }
                  name="accountNumber"
                  onChange={handleChangeAccount}
                  // onKeyDown={(e) => {
                  //   if (
                  //     !/\d/.test(e.key) &&
                  //     e.key !== "Backspace" &&
                  //     e.key !== "Delete" &&
                  //     e.key !== "ArrowLeft" &&
                  //     e.key !== "ArrowRight"
                  //   ) {
                  //     e.preventDefault();
                  //   }
                  // }}
                />
                <span className="small erro_msg">{accountNumberMsg}</span>
              </div>
              <div className="inputFieldDivRight">
                <p className="mb-0">Ticker Symbol</p>
                <input
                  type="text"
                  className="insertCommonInputField"
                  value={
                    existingAccount || existingClone
                      ? accountUpdateInfo.tickerSymbol
                      : accountInfo.tickerSymbol
                  }
                  name="tickerSymbol"
                  onChange={handleChangeAccount}
                />
                <span className="small erro_msg">{tickerMsg}</span>
              </div>
            </div>
            <div className="inputFieldDiv">
              <div className="inputFieldDivLeft">
                <p className="mb-0">Account Type</p>
                <Select
                  value={
                    existingAccount || existingClone
                      ? accountUpdateInfo.accountType
                      : accountInfo.accountType
                  }
                  menuPlacement="auto"
                  onChange={(selectedOption) =>
                    handleChangeSelectDropDown(selectedOption, "accountType")
                  }
                  options={accountTypeOptions}
                  styles={createAccountStyles}
                  isSearchable={false}
                />
              </div>
              <div className="inputFieldDivRight">
                <p className="mb-0">Ownership</p>
                <Select
                  value={
                    existingAccount || existingClone
                      ? accountUpdateInfo.ownership
                      : accountInfo.ownership
                  }
                  menuPlacement="auto"
                  onChange={(selectedOption) =>
                    handleChangeSelectDropDown(selectedOption, "ownership")
                  }
                  options={ownershipOptions}
                  styles={createAccountStyles}
                  isSearchable={false}
                />
              </div>
            </div>
            <div className="inputFieldDiv">
              <div className="inputFieldDivLeft">
                <p className="mb-0">Industry</p>
                <Select
                  value={
                    existingAccount || existingClone
                      ? accountUpdateInfo.industry
                      : accountInfo.industry
                  }
                  menuPlacement="auto"
                  onChange={(selectedOption) =>
                    handleChangeSelectDropDown(selectedOption, "industry")
                  }
                  options={industryOptions}
                  styles={createAccountStyles}
                  isSearchable={false}
                />
              </div>
              <div className="inputFieldDivRight">
                <p className="mb-0">Employees</p>
                <input
                  type="text"
                  autoComplete="off"
                  className="insertCommonInputField"
                  value={
                    existingAccount || existingClone
                      ? accountUpdateInfo.employees
                      : accountInfo.employees
                  }
                  // onKeyDown={(e) => {
                  //   // Allow only numeric input
                  //   if (
                  //     !/\d/.test(e.key) &&
                  //     e.key !== "Backspace" &&
                  //     e.key !== "Delete" &&
                  //     e.key !== "ArrowLeft" &&
                  //     e.key !== "ArrowRight"
                  //   ) {
                  //     e.preventDefault();
                  //   }
                  // }}
                  name="employees"
                  onChange={handleChangeAccount}
                />
                <span className="small erro_msg">{employeesMsg}</span>
              </div>
            </div>
            <div className="inputFieldDiv">
              <div className="inputFieldDivLeft position-relative">
                <p className="mb-0">Annual Revenue</p>
                <input
                  type="text"
                  className="insertCommonInputField"
                  pattern="[0-9.]*"
                  maxLength={16}
                  name="annualRevenue"
                  style={{ padding: "12px 15px" }}
                  // onKeyDown={(e) => {
                  //   // Allow only numeric input
                  //   if (
                  //     !/\d/.test(e.key) &&
                  //     e.key !== "Backspace" &&
                  //     e.key !== "Delete" &&
                  //     e.key !== "ArrowLeft" &&
                  //     e.key !== "ArrowRight"
                  //   ) {
                  //     e.preventDefault();
                  //   }
                  // }}
                  value={
                    existingAccount || existingClone
                      ? accountUpdateInfo.annualRevenue
                      : accountInfo.annualRevenue
                  }
                  onChange={handleChangeAccount}
                  onBlur={() => handleBlur("annualRevenue")}
                  onKeyPress={handleKeyPress}
                />
                <span
                  className="clear-button position-absolute px-1"
                  style={{ left: "0px", bottom: "9px" }}
                >
                  ₹
                </span>
                {showTooltipAnnualRevenue && (
                  <div style={{ ...tooltipStyles, bottom: "30px" }}>
                    {"= " + tooltipContent}
                  </div>
                )}
                <span
                  className="small erro_msg position-absolute"
                  style={{ left: "0px", bottom: "-22px" }}
                >
                  {annualRevenueMsg}
                </span>
              </div>
              <div className="inputFieldDivRight">
                <p className="mb-0">SIC Code</p>
                <input
                  type="text"
                  className="insertCommonInputField"
                  maxLength={4}
                  autoComplete="off"
                  name="sicCode"
                  value={
                    existingAccount || existingClone
                      ? accountUpdateInfo.sicCode
                      : accountInfo.sicCode
                  }
                  onChange={handleChangeAccount}
                  // onKeyDown={(e) => {
                  //   if (
                  //     !/\d/.test(e.key) &&
                  //     e.key !== "Backspace" &&
                  //     e.key !== "Delete" &&
                  //     e.key !== "ArrowLeft" &&
                  //     e.key !== "ArrowRight" &&
                  //     e.key !== "Tab" &&
                  //     e.key !== "Enter"
                  //   ) {
                  //     e.preventDefault();
                  //   }
                  // }}
                />
                <span className="small erro_msg">{sicCodeMsg}</span>
              </div>
            </div>
            <h6 className="py-2" style={{ borderBottom: "2px solid #316AFF" }}>
              Address Information
            </h6>
            <div
              className="inputFieldDiv"
              style={{ width: "min-content", alignSelf: "end" }}
            >
              <Dropdown>
                <Dropdown.Toggle id="dropdown-basic">
                  Copy Address
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Item onClick={copyBillingToShipping}>
                    Billing to Shipping
                  </Dropdown.Item>
                  <Dropdown.Item onClick={copyShippingToBilling}>
                    Shipping to Billing
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
            <div className="inputFieldDiv">
              <div className="inputFieldDivLeft">
                <p className="mb-0">Billing Street</p>
                <input
                  type="text"
                  className="insertCommonInputField"
                  value={
                    existingAccount || existingClone
                      ? accountUpdateInfo.billingStreet
                      : accountInfo.billingStreet
                  }
                  name="billingStreet"
                  onChange={handleChangeAccount}
                />
              </div>
              <div className="inputFieldDivRight">
                <p className="mb-0">Shipping Street</p>
                <input
                  type="text"
                  className="insertCommonInputField"
                  value={
                    existingAccount || existingClone
                      ? accountUpdateInfo.shippingStreet
                      : accountInfo.shippingStreet
                  }
                  name="shippingStreet"
                  onChange={handleChangeAccount}
                />
              </div>
            </div>
            <div className="inputFieldDiv">
              <div className="inputFieldDivLeft">
                <p className="mb-0">Billing City</p>
                <input
                  type="text"
                  className="insertCommonInputField"
                  value={
                    existingAccount || existingClone
                      ? accountUpdateInfo.billingCity
                      : accountInfo.billingCity
                  }
                  name="billingCity"
                  onChange={handleChangeAccount}
                />
                <p style={{ color: "#fa5a5a" }}>
                  {billingCityMsg && billingCityMsg}
                </p>
              </div>
              <div className="inputFieldDivRight">
                <p className="mb-0">Shipping City</p>
                <input
                  type="text"
                  className="insertCommonInputField"
                  value={
                    existingAccount || existingClone
                      ? accountUpdateInfo.shippingCity
                      : accountInfo.shippingCity
                  }
                  name="shippingCity"
                  onChange={handleChangeAccount}
                />
                <p style={{ color: "#fa5a5a" }}>
                  {shippingCityMsg && shippingCityMsg}
                </p>
              </div>
            </div>
            <div className="inputFieldDiv">
              <div className="inputFieldDivLeft">
                <p className="mb-0">Billing State</p>
                <input
                  type="text"
                  className="insertCommonInputField"
                  value={
                    existingAccount || existingClone
                      ? accountUpdateInfo.billingState
                      : accountInfo.billingState
                  }
                  name="billingState"
                  onChange={handleChangeAccount}
                />
                <p style={{ color: "#fa5a5a" }}>
                  {billingStateMsg && billingStateMsg}
                </p>
              </div>
              <div className="inputFieldDivRight">
                <p className="mb-0">Shipping State</p>
                <input
                  type="text"
                  className="insertCommonInputField"
                  value={
                    existingAccount || existingClone
                      ? accountUpdateInfo.shippingState
                      : accountInfo.shippingState
                  }
                  name="shippingState"
                  onChange={handleChangeAccount}
                />
                <p style={{ color: "#fa5a5a" }}>
                  {shippingStateMsg && shippingStateMsg}
                </p>
              </div>
            </div>
            <div className="inputFieldDiv">
              <div className="inputFieldDivLeft">
                <p className="mb-0">Billing Code</p>
                <input
                  type="text"
                  className="insertCommonInputField"
                  pattern="[0-9]*"
                  maxLength={6}
                  value={
                    existingAccount || existingClone
                      ? accountUpdateInfo.billingCode
                      : accountInfo.billingCode
                  }
                  name="billingCode"
                  onChange={handleChangeAccount}
                />
                <p style={{ color: "#fa5a5a" }}>{codeMsg && codeMsg}</p>
              </div>
              <div className="inputFieldDivRight">
                <p className="mb-0">Shipping Code</p>
                <input
                  type="text"
                  className="insertCommonInputField"
                  pattern="[0-9]*"
                  maxLength={6}
                  value={
                    existingAccount || existingClone
                      ? accountUpdateInfo.shippingCode
                      : accountInfo.shippingCode
                  }
                  name="shippingCode"
                  onChange={handleChangeAccount}
                />
                <p style={{ color: "#fa5a5a" }}>
                  {shippingCodeMsg && shippingCodeMsg}
                </p>
              </div>
            </div>
            <div className="inputFieldDiv">
              <div className="inputFieldDivLeft">
                <p className="mb-0">Billing Country</p>
                <input
                  type="text"
                  className="insertCommonInputField"
                  value={
                    existingAccount || existingClone
                      ? accountUpdateInfo.billingCountry
                      : accountInfo.billingCountry
                  }
                  name="billingCountry"
                  onChange={handleChangeAccount}
                />
                <p style={{ color: "#fa5a5a" }}>
                  {billingCountryMsg && billingCountryMsg}
                </p>
              </div>
              <div className="inputFieldDivRight">
                <p className="mb-0">Shipping Country</p>
                <input
                  type="text"
                  className="insertCommonInputField"
                  value={
                    existingAccount || existingClone
                      ? accountUpdateInfo.shippingCountry
                      : accountInfo.shippingCountry
                  }
                  name="shippingCountry"
                  onChange={handleChangeAccount}
                />
                <p style={{ color: "#fa5a5a" }}>
                  {shippingCountryMsg && shippingCountryMsg}
                </p>
              </div>
            </div>
            <h6 className="py-2" style={{ borderBottom: "2px solid #316AFF" }}>
              Description Information
            </h6>
            <div className="inputFieldDiv">
              <div className="inputFieldDivLeft">
                <p>Description</p>
                <textarea
                  rows="4"
                  cols="50"
                  maxLength="2000"
                  style={{
                    width: "100%",
                    border: "1px solid #B9B9B94D",
                    height: "100px",
                    outline: "none",
                    borderRadius: "10px",
                    backgroundColor: "transparent",
                    padding: "8px 12px",
                  }}
                  value={
                    existingAccount || existingClone
                      ? accountUpdateInfo.description
                      : accountInfo.description
                  }
                  name="description"
                  onChange={handleChangeAccount}
                />
              </div>
            </div>
          </div>

          <Modal
            show={isAccountListModal}
            className="d-flex justify-content-center align-items-center"
            aria-labelledby="example-custom-modal-styling-title"
            size="lg"
            id="commonLeadModal"
          >
            <Modal.Body className="ModalBody overflow-auto">
              <div
                className="modalUS row justify-content-center"
                style={{ margin: "0", gap: "10px", paddingBottom: "10px" }}
              >
                <div className="header_Container d-flex justify-content-between">
                  <h4 className="commonTextColor pt-2">Choose Account</h4>
                  <div className="inputContainer d-flex justify-content-start">
                    <input
                      type="text"
                      placeholder="Search"
                      className="topBarModalSearch px-5"
                      onChange={handleSearchAccount}
                      value={searchAccount}
                    />
                    <img src={SearchIcon} alt="search" className="searchIcon" />
                  </div>
                </div>

                <div className="Owner_table_container border border-dark rounded-2 h-25">
                  <div className="table-wrapper">
                    <table className="userdata-table">
                      <thead>
                        <tr>
                          <th>Account Name</th>
                          <th>Phone</th>
                          <th>Website</th>
                          <th>Account Owner</th>
                        </tr>
                      </thead>
                      <tbody className="table-body tableUserData">
                        {filteredAccounts.length > 0 &&
                          filteredAccounts?.map((item, index) => (
                            <tr
                              key={index}
                              onClick={() => {
                                setSelectedAccountItem(item);
                              }}
                            >
                              <>
                                <td>{item.accountName || "--"}</td>
                                <td>{item.phone || "--"}</td>
                                <td>{item.website || "--"}</td>
                                <td>{item.accountOwner || "--"}</td>
                              </>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </div>
                </div>
                <div className="d-flex w-100 align-items-center justify-content-end gap-2">
                  {filteredAccounts.length > 0 && (
                    <>
                      <Select
                        options={pageOption}
                        // styles={customSelectLocationStyles}
                        value={pageOption.find(
                          (option) => option.value === perPage.toString()
                        )}
                        onChange={handlePageDropDowmChange}
                        isSearchable={false}
                        styles={customCommonStyles("225px")}
                      />
                      <Pagination
                        currentPage={currentPage}
                        totalPages={totalPages}
                        onPageChange={handlePageChange}
                      />
                    </>
                  )}
                </div>
                <div className="d-flex pb-2 pt-3 justify-content-between">
                  <div className="selectedUser d-flex gap-1">
                    <span>Selected Account</span>
                    <img src={logoConvert} alt="img" height={20} width={20} />
                    <span>{selectedAccountItem?.accountName}</span>
                  </div>
                  <div className="d-flex gap-2">
                    <button
                      className="btn btn-outline-secondary border border-primary btn-sm px-3 py-2 text-dark"
                      onClick={() => {
                        setIsAccountListModal(false);
                        setSelectedAccountItem({});
                      }}
                    >
                      Cancel
                    </button>
                    <button
                      className="btn btnColor btn-sm px-3 text-white"
                      onClick={() => {
                        setIsAccountListModal(false);
                      }}
                    >
                      Done
                    </button>
                  </div>
                </div>
              </div>
            </Modal.Body>
          </Modal>
        </>
      );
    } else if (innerPage === "overview") {
      return (
        <>
          <div className="indiv_main_container ">
            <div className="commonContainer_bg w-100">
              <div className="d-flex justify-content-between px-3 pt-2">
                <span
                  className="cursorPointer"
                  onClick={() => {
                    getAllAccountDetails(selectedOption);
                    setInnerPage("list");
                    setFetchAccInfo(null);
                    const resetFields = Object.fromEntries(
                      Object.keys(editingFields).map((key) => [key, false])
                    );
                    setEditingFields({
                      ...resetFields,
                    });
                    setShowType("");
                  }}
                >
                  {leftArrow}
                </span>
                <span>
                  <span
                    className={`me-2 ${
                      selectedLeadIndex === 0 ? "opacity-50" : "cursorPointer"
                    }`}
                    onClick={() => {
                      const leadIndex = allList.findIndex(
                        (acc) => acc.accountId === accountId
                      );
                      if (leadIndex !== 0) {
                        setSelectedLeadIndex(leadIndex - 1);
                        setAccountId(allList[leadIndex - 1].accountId);
                        accountOverviewDetail(allList[leadIndex - 1].accountId);
                        getNotesDetails(
                          allList[leadIndex + 1].accountId,
                          "ALL"
                        );
                        setFetchAccInfo(allList[leadIndex - 1]);
                      }
                    }}
                  >
                    {leftAngleArrow}
                  </span>
                  <span
                    className={`ms-1 ${
                      selectedLeadIndex === allList.length - 1
                        ? "opacity-50"
                        : "cursorPointer"
                    }`}
                    onClick={() => {
                      const leadIndex = allList.findIndex(
                        (acc) => acc.accountId === accountId
                      );
                      if (leadIndex < allList.length - 1) {
                        setSelectedLeadIndex(leadIndex + 1);
                        setAccountId(allList[leadIndex + 1].accountId);
                        accountOverviewDetail(allList[leadIndex + 1].accountId);
                        getNotesDetails(
                          allList[leadIndex + 1].accountId,
                          "ALL"
                        );
                        setFetchAccInfo(allList[leadIndex + 1]);
                      }
                    }}
                  >
                    {rightAngleArrow}
                  </span>
                </span>
              </div>
              <div className="profile_logo_container d-flex pt-2">
                <div className="logoContainer d-flex align-items-center justify-content-center pt-1">
                  <img
                    src={
                      accountInfoDetails.accountImageData === null
                        ? AccountAvatar
                        : accountInfoDetails.accountImageData
                    }
                    name="accountImageData"
                    alt="img"
                    style={{ width: "100px", height: "100px" }}
                    onClick={() => {
                      setShowImageModal(true);
                    }}
                  />
                </div>
                <div className="d-flex flex-column align-items-center justify-content-center ps-2">
                  <span>{accountInfoDetails?.accountName}</span>
                  <span className="opacity-75 text-start w-100">
                    {accountInfoDetails?.website || ""}
                  </span>
                </div>
              </div>
              <div className="d-flex  justify-content-end align-items-end  px-2 pb-2">
                <div className="d-flex gap-2">
                  {/* <button className="btn  accountBtnColor text-white">
                    Send Email
                  </button> */}
                  <button
                    className="btn btn-secondary bg-white text-dark border border-primary"
                    onClick={handleEditAccount}
                  >
                    Edit
                  </button>
                  <button
                    className="btn btn-secondary bg-white text-dark border border-primary"
                    onClick={(e) => {
                      setShowOptionsLead(!showOptionsLead);
                      e.stopPropagation();
                    }}
                  >
                    ---
                  </button>
                  {showOptionsLead && (
                    <div className="rounded  showOptions p-2">
                      <div
                        className="d-flex gap-3 align-items-center mt-2"
                        onClick={handleCreateAccountClone}
                      >
                        <img src={Clone} alt="clone" />
                        <div
                          style={{ color: "#515151", fontWeight: "300" }}
                          className="mt-1 cursorPointer ms-2"
                        >
                          Clone
                        </div>
                      </div>
                      {accountInfoDetails.isShared === false ? (
                        <div className="d-flex gap-3 align-items-center mt-2">
                          <img src={Share} alt="share" />
                          <div
                            style={{ color: "#515151", fontWeight: "300" }}
                            className="mt-2 cursorPointer ms-2"
                            onClick={() => {
                              if (!isLoading) {
                                getAllInfoShare(accountId);
                              }
                            }}
                          >
                            Share
                          </div>
                        </div>
                      ) : (
                        " "
                      )}

                      <div
                        className="d-flex gap-3 align-items-center mt-2"
                        onClick={handleDeleteAccount}
                      >
                        <img src={Delete} alt="delete" />
                        <div
                          style={{ color: "#515151", fontWeight: "300" }}
                          className="mt-2 cursorPointer ms-2"
                        >
                          Delete
                        </div>
                      </div>
                      <div style={{ borderBottom: "1px solid #515151s" }}></div>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="detailList_main_container mt-2 commonContainer_bg">
              <div className="detailList_main_container d-flex py-2 ">
                <div
                  className={` pt-3 cursorPointer ${isShrinked ? "" : "w-25"}`}
                  onClick={() => {
                    setIsShrinked(!isShrinked);
                  }}
                >
                  {isShrinked ? expandArrow : shrinkArrow}
                </div>{" "}
                <div
                  className={`d-flex justify-content-between px-2 ${
                    isShrinked ? "w-100" : "w-75 ps-3"
                  }`}
                >
                  <span className="pt-3 ps-2">
                    Last Update: {accountInfoDetails.lastUpdate}
                  </span>
                  <div className="d-flex gap-4 px-2 bg-white px-1  rounded-5 py-1">
                    <span
                      // className=""
                      className={`cursorPointer } ${
                        accountPageView === "overview"
                          ? "overviewTimelineTab "
                          : "  d-flex justify-content-center align-items-center"
                      }`}
                      onClick={() => setAccountPageView("overview")}
                    >
                      Overview
                    </span>
                    <span
                      className={`cursorPointer } ${
                        accountPageView === "timeline"
                          ? "overviewTimelineTab"
                          : " d-flex justify-content-center align-items-center"
                      }`}
                      onClick={() => getAllTimelineAccounts()}
                    >
                      Timeline
                    </span>
                  </div>
                </div>
              </div>
              <div className="detailList_content_container d-flex justify-content-between px-2 py-2 bg-white">
                <div
                  className={`w-25 relatedListContainer rounded commonContainer_bg  me-2 ${
                    isShrinked ? "d-none" : ""
                  }`} // style={{ height: "100vh" }}
                >
                  <div className="d-flex">
                    <p className="opacity-57 ps-2 opacity-75 m-0 py-2">
                      Related List
                    </p>
                  </div>
                  <div className="horizontal_line  mx-1"></div>
                  <ul className="relateList_container">
                    {notePermission === "TRUE" && (
                      <li
                        className={`cursorPointer py-2 relatedList_tab rounded-1`}
                        onClick={() => {
                          if (accountPageView === "timeline") {
                            setAccountPageView("overview");
                            setTimeout(
                              () => scrollToSection("notesSection"),
                              0
                            );
                          } else {
                            scrollToSection("notesSection");
                          }
                        }}
                      >
                        <span>
                          <img src={noteOne} alt="img" height={20} width={20} />
                        </span>
                        Notes
                      </li>
                    )}
                    <li
                      className={`cursorPointer py-2 relatedList_tab rounded-1`}
                      onClick={() => {
                        if (accountPageView === "timeline") {
                          setAccountPageView("overview");
                          setTimeout(() => scrollToSection("products"), 0);
                        } else {
                          scrollToSection("products");
                        }
                      }}
                    >
                      <span>
                        <img src={prodIcon} alt="img" height={20} width={20} />
                      </span>
                      Products
                    </li>

                    <li
                      className={`cursorPointer py-2 relatedList_tab rounded-1`}
                      onClick={() => {
                        if (accountPageView === "timeline") {
                          setAccountPageView("overview");
                          setTimeout(
                            () => scrollToSection("openActivities"),
                            0
                          );
                        } else {
                          scrollToSection("openActivities");
                        }
                      }}
                    >
                      <span>
                        <img
                          src={openActivityIcon}
                          alt="img"
                          height={20}
                          width={20}
                        />
                      </span>
                      Open Activities
                    </li>
                    <li
                      className={`cursorPointer py-2 relatedList_tab rounded-1`}
                      onClick={() => {
                        if (accountPageView === "timeline") {
                          setAccountPageView("overview");
                          setTimeout(
                            () => scrollToSection("closedActivities"),
                            0
                          );
                        } else {
                          scrollToSection("closedActivities");
                        }
                      }}
                    >
                      <span>
                        <img
                          src={closeActivityIcon}
                          alt="img"
                          height={20}
                          width={20}
                        />
                      </span>
                      Closed Activities
                    </li>
                    <li
                      className={`cursorPointer py-2 relatedList_tab rounded-1`}
                      onClick={() => {
                        if (accountPageView === "timeline") {
                          setAccountPageView("overview");
                          setTimeout(() => scrollToSection("contacts"), 0);
                        } else {
                          scrollToSection("contacts");
                        }
                      }}
                    >
                      <span>
                        <img src={noteOne} alt="img" height={20} width={20} />
                      </span>
                      Contacts
                    </li>
                    <li
                      className={`cursorPointer py-2 relatedList_tab rounded-1`}
                      onClick={() => {
                        if (accountPageView === "timeline") {
                          setAccountPageView("overview");
                          setTimeout(() => scrollToSection("deals"), 0);
                        } else {
                          scrollToSection("deals");
                        }
                      }}
                    >
                      <span>
                        <img src={noteOne} alt="img" height={20} width={20} />
                      </span>
                      Deals
                    </li>
                    <li
                      className={`cursorPointer py-2 relatedList_tab rounded-1`}
                      onClick={() => {
                        if (accountPageView === "timeline") {
                          setAccountPageView("overview");
                          setTimeout(() => scrollToSection("invoices"), 0);
                        } else {
                          scrollToSection("invoices");
                        }
                      }}
                    >
                      <span>
                        <img
                          src={attachIcon}
                          alt="img"
                          height={20}
                          width={20}
                        />
                      </span>
                      Invoices
                    </li>
                    <li
                      className={`cursorPointer py-2 relatedList_tab rounded-1`}
                      onClick={() => {
                        if (accountPageView === "timeline") {
                          setAccountPageView("overview");
                          setTimeout(() => scrollToSection("salesOrders"), 0);
                        } else {
                          scrollToSection("salesOrders");
                        }
                      }}
                    >
                      <span>
                        <img
                          src={inviteIcon}
                          alt="img"
                          height={20}
                          width={20}
                        />
                      </span>
                      Sales Orders
                    </li>
                    <li
                      className={`cursorPointer py-2 relatedList_tab rounded-1`}
                      onClick={() => {
                        if (accountPageView === "timeline") {
                          setAccountPageView("overview");
                          setTimeout(() => scrollToSection("quotes"), 0);
                        } else {
                          scrollToSection("quotes");
                        }
                      }}
                    >
                      <span>
                        <img
                          src={attachIcon}
                          alt="img"
                          height={20}
                          width={20}
                        />
                      </span>
                      Quotes
                    </li>
                  </ul>
                </div>
                {/* Account OverView and Timeline Implementation*/}
                {accountPageView === "overview" ? (
                  <div
                    className={`bg-white w-75 rounded-2 leadDetail_main_Container ${
                      isShrinked ? "w-100" : "w-75"
                    }`}
                  >
                    <div className=" common_bg ">
                      <div className="lead_DetailContainer h-25 py-2    rounded-2  commonContainer_bg d-flex justify-content-between align-items-center">
                        <div className="w-100 d-flex flex-column">
                          {fieldLabels.map((field, i) => {
                            const fieldValue = field.name
                              .replace(/\s+/g, "")
                              .replace(/^(.)/, (match) => match.toLowerCase());
                            return field.isShowed ? (
                              <div className="d-flex w-50 ps-3" key={i}>
                                <div className="w-50 position-relative">
                                  <div
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      setSelectedField(
                                        selectedField === field.name
                                          ? ""
                                          : field.name
                                      );
                                      setSearchField("");
                                    }}
                                    className={`cursorPointer d-flex justify-content-between w-100 my-1 p-1 ${
                                      selectedField === field.name
                                        ? "outline_border rounded-1"
                                        : ""
                                    }`}
                                  >
                                    <span>{field.name}</span>
                                    <img
                                      src={
                                        selectedField === field.name ? up : down
                                      }
                                      alt="img"
                                      height={20}
                                      width={20}
                                      className="pt-1"
                                    />
                                  </div>
                                  {selectedField === field.name && (
                                    <div
                                      className="position-absolute field_container w-100"
                                      onClick={(e) => {
                                        e.stopPropagation();
                                      }}
                                    >
                                      <div className="">
                                        <label
                                          htmlFor="selectField"
                                          className="position-relative w-100"
                                        >
                                          <input
                                            id="selectField"
                                            type="text"
                                            className="rounded-2 p-1 w-100 pe-4"
                                            placeholder="Search"
                                            aria-label="Username"
                                            aria-describedby="basic-addon1"
                                            value={searchField}
                                            onChange={(e) => {
                                              if (
                                                e.target.value.charAt(0) !== " "
                                              ) {
                                                const value =
                                                  e.target.value.replace(
                                                    /[^a-zA-Z ]+|(\s)\s+/g,
                                                    "$1"
                                                  );
                                                setSearchField(value);
                                              }
                                              // setSearchField(value);

                                              // const filteredData =
                                              //   fieldDropdowns.filter((field) =>
                                              //     field.name
                                              //       .toLowerCase()
                                              //       .includes(
                                              //         value.trim().toLowerCase()
                                              //       )
                                              //   );
                                              // setShowSearchFields(
                                              //   /^[a-zA-Z]+$/.test(value)
                                              //     ? filteredData
                                              //     : fieldLabels
                                              // );
                                            }}
                                          />
                                          <img
                                            className="position-absolute mt-1 me-2 end-0 pt-1 cursorPointer"
                                            onClick={() => {
                                              setSearchField("");
                                            }}
                                            src={searchIcon}
                                            alt="search"
                                            width={20}
                                          />
                                        </label>

                                        <div className="w-100 rounded-2">
                                          <div className="border border-1 field_container_dropdown rounded-1">
                                            {showSearchFields
                                              .filter((field) =>
                                                field.name
                                                  .toLowerCase()
                                                  .includes(
                                                    searchField
                                                      .trim()
                                                      .toLowerCase()
                                                  )
                                              )
                                              .sort((a, b) =>
                                                a.name.localeCompare(b.name)
                                              )
                                              .map(
                                                (field) =>
                                                  (selectedField ===
                                                    field.name ||
                                                    !field.isShowed) && (
                                                    <div
                                                      key={field.name}
                                                      className="d-flex gap-2 p-2 cursorPointer rounded-2 align-items-center"
                                                      onClick={() => {
                                                        selectedField ===
                                                        field.name
                                                          ? setSelectedField("")
                                                          : handleFieldClick(
                                                              field
                                                            );
                                                      }}
                                                    >
                                                      <span className="right_tick">
                                                        {selectedField ===
                                                        field.name
                                                          ? tick
                                                          : ""}
                                                      </span>
                                                      <span className="ps-2">
                                                        {field.name}
                                                      </span>
                                                    </div>
                                                  )
                                              )}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  )}
                                </div>
                                <div
                                  className={`w-50 mt-1 ms-5 position-relative`}
                                >
                                  {field.type === "input" ? (
                                    <EditableInput
                                      className="ps-3"
                                      inputType={fieldValue}
                                      handleIndividualInputChange={
                                        handleIndividualInputChange
                                      }
                                      editingFields={editingFields}
                                      handleEditClick={handleEditClick}
                                      handleCancelClick={handleCancelClick}
                                      handleUpdateClick={handleUpdateClick}
                                      updatedFields={updatedFields}
                                      accountOverviewDetails={
                                        accountInfoDetails
                                      }
                                      showType={showType}
                                      setShowType={setShowType}
                                    />
                                  ) : field.type === "dropdown" ? (
                                    <div
                                      className="position-relative d-flex"
                                      style={{ alignItems: "center" }}
                                      onClick={() => {
                                        handleEditClick(fieldValue);
                                      }}
                                    >
                                      {editingFields[fieldValue] &&
                                      showType === fieldValue ? (
                                        <Select
                                          value={updatedFields[fieldValue]}
                                          // value={accountInfoDetails[fieldValue]}
                                          menuPlacement="auto"
                                          onChange={(selectedOption) =>
                                            handleChangeIndDropDown(
                                              selectedOption,
                                              fieldValue
                                            )
                                          }
                                          options={
                                            fieldValue === "rating"
                                              ? ratingOptions
                                              : fieldValue === "accountType"
                                              ? accountTypeOptions
                                              : fieldValue === "ownership"
                                              ? ownershipOptions
                                              : industryOptions
                                          }
                                          styles={fieldDropdownStyles}
                                          isSearchable={false}
                                        />
                                      ) : (
                                        <input
                                          type="text"
                                          className={`cursorPointer indvEditInput
                            ${
                              showType === fieldValue
                                ? "selectedBorder "
                                : "unselectBorder bg-transparent"
                            }`}
                                          value={
                                            accountInfoDetails[fieldValue]
                                              ?.value
                                          }
                                          name={fieldValue}
                                          onClick={(e) => {
                                            if (showType === "") {
                                              updatedFields[fieldValue] =
                                                accountInfoDetails[fieldValue];
                                              setShowType(e.target.name);
                                              setUpdatedFields(updatedFields);
                                            }
                                            handleEditClick(fieldValue);
                                          }}
                                          // readOnly={showType !== "emailId"}
                                          readOnly={true}
                                        />
                                      )}
                                      {editingFields[fieldValue] &&
                                        showType === fieldValue && (
                                          <div className="d-flex gap-2">
                                            <span
                                              onClick={() => {
                                                setShowType("");
                                                handleUpdateClick(fieldValue);
                                              }}
                                              className="ps-2 cursorPointer"
                                            >
                                              <img
                                                src={updateIcon}
                                                alt="img"
                                                height={20}
                                                width={20}
                                              />
                                            </span>
                                            <span
                                              onClick={() => {
                                                setShowType("");
                                                // handleUpdateClick(inputType);
                                                handleCancelClick(fieldValue);
                                              }}
                                              className="cursorPointer"
                                            >
                                              <img
                                                src={cancelIcon}
                                                alt="img"
                                                height={20}
                                                width={20}
                                              />
                                            </span>
                                          </div>
                                        )}
                                    </div>
                                  ) : field.type === "readOnly" ? (
                                    <div>
                                      <span className="d-flex flex-column">
                                        <span>
                                          {
                                            accountInfoDetails[
                                              field.name === "Created By"
                                                ? "createdBy"
                                                : field.name === "Modified By"
                                                ? "modifiedBy"
                                                : "parentAccountName"
                                            ]
                                          }
                                        </span>
                                        {(field.name === "Created By" ||
                                          field.name === "Modified By") && (
                                          <span className="small opacity-75">
                                            {
                                              accountInfoDetails[
                                                field.name === "Created By"
                                                  ? "createdDate"
                                                  : "modifiedDate"
                                              ]
                                            }
                                          </span>
                                        )}
                                      </span>
                                    </div>
                                  ) : (
                                    <div>
                                      {field.type === "checkbox" ? (
                                        <div className="position-relative">
                                          {!editingFields["emailOptOut"] ? (
                                            <span
                                              className="right_tick cursorPointer"
                                              onClick={() => {
                                                handleEditClick(fieldValue);
                                                setShowType("emailOptOut");
                                              }}
                                            >
                                              {updatedFields.emailOptOut
                                                ? tick
                                                : "-"}
                                            </span>
                                          ) : (
                                            <input
                                              type="checkbox"
                                              name={field.name}
                                              checked={
                                                updatedFields.emailOptOut
                                              }
                                              onChange={(e) => {
                                                setUpdatedFields(
                                                  (prevFields) => ({
                                                    ...prevFields,
                                                    emailOptOut:
                                                      e.target.checked,
                                                  })
                                                );
                                              }}
                                              className="mt-2"
                                            />
                                          )}
                                          {editingFields["emailOptOut"] && (
                                            <div
                                              className="position-absolute end-0 top-0"
                                              style={{ marginRight: "1rem" }}
                                            >
                                              <div className="d-flex gap-2 ">
                                                <span
                                                  onClick={(e) => {
                                                    e.stopPropagation();
                                                    setShowType("");
                                                    handleUpdateClick(
                                                      fieldValue
                                                    );
                                                  }}
                                                  className="ps-2 cursorPointer"
                                                >
                                                  <img
                                                    src={updateIcon}
                                                    alt="Update"
                                                    height={20}
                                                    width={20}
                                                  />
                                                </span>
                                                <span
                                                  onClick={(e) => {
                                                    e.stopPropagation();
                                                    setShowType("");
                                                    handleCancelClick(
                                                      fieldValue
                                                    );
                                                  }}
                                                  className="cursorPointer"
                                                >
                                                  <img
                                                    src={cancelIcon}
                                                    alt="Cancel"
                                                    height={20}
                                                    width={20}
                                                  />
                                                </span>
                                              </div>
                                            </div>
                                          )}
                                        </div>
                                      ) : (
                                        <div className="position-relative">
                                          <div
                                            className="d-flex input-with-clear position-relative"
                                            onClick={() => {
                                              // handleOwnerInputClick
                                              setShowModalType("changeOwner");
                                            }}
                                          >
                                            <input
                                              type="text"
                                              //   placeholder="Vendor Owner"
                                              className="w-100 cursorPointer  indvEditInput border-0 indivOwnerInput"
                                              value={selectedItem?.name}
                                              readOnly
                                            />
                                          </div>
                                          {/* {listVisible && (
                                            <ul className="position-absolute w-100 px-2 small field_container_dropdown">
                                              {userList.map((item, index) => (
                                                <li
                                                  key={index}
                                                  className="cursorPointer list-group-item my-2 border-bottom"
                                                  onClick={() => {
                                                    handleUserItemClick(item);
                                                  }}
                                                >
                                                  <div className="d-flex flex-column">
                                                    <span> {item.name}</span>
                                                    <span className="small opacity-75">
                                                      {" "}
                                                      {item.emailId}
                                                    </span>
                                                  </div>
                                                </li>
                                              ))}
                                            </ul>
                                          )} */}
                                        </div>
                                      )}
                                    </div>
                                  )}
                                </div>
                              </div>
                            ) : null;
                          })}
                        </div>
                      </div>
                      <div className="lead_Information_mainContainer commonContainer_bg     rounded-2  d-flex flex-column my-2 py-2">
                        <h5 className="w-100 text-primary px-2 py-2">
                          Account Information
                        </h5>
                        <div className="d-flex justify-content-between">
                          <div className="leadContact_container d-flex gap-4 w-100 ps-2 pe-2">
                            <div className="w-50 ">
                              {fieldIndividualDropdowns
                                .slice(0, 9)
                                .map((field) => {
                                  const fieldValue =
                                    field.value ||
                                    field.name
                                      .replace(/\s+/g, "")
                                      .replace(/^(.)/, (match) =>
                                        match.toLowerCase()
                                      );
                                  return (
                                    <div
                                      className="d-flex justify-content-between align-items-center my-2 w-100 gap-3"
                                      key={fieldValue}
                                    >
                                      <div className="opacity-75 p-2 w-100">
                                        {field.name}
                                      </div>
                                      <div className="w-100">
                                        {field.type === "other" ? (
                                          <div>
                                            <input
                                              type="text"
                                              className={`cursorPointer indvEditInput rounded-1 w-100 ${
                                                showType === "salesOwnerId"
                                                  ? "selectedBorder "
                                                  : "unselectBorder bg-transparent"
                                              }`}
                                              value={selectedItem?.name}
                                              readOnly
                                            />
                                          </div>
                                        ) : field.type === "input" ? (
                                          <EditableInput
                                            fieldType={
                                              ["dueDate"].includes(fieldValue)
                                                ? "date"
                                                : "text"
                                            }
                                            className="ps-3"
                                            inputType={fieldValue}
                                            handleIndividualInputChange={
                                              handleIndividualInputChange
                                            }
                                            editingFields={editingFields}
                                            handleEditClick={handleEditClick}
                                            handleCancelClick={
                                              handleCancelClick
                                            }
                                            handleUpdateClick={
                                              handleUpdateClick
                                            }
                                            updatedFields={updatedFields}
                                            accountOverviewDetails={
                                              accountInfoDetails
                                            }
                                            setShowType={setShowType}
                                            showType={showType}
                                            fieldsType="second"
                                          />
                                        ) : field.type === "dropdown" ? (
                                          <div
                                            className="position-relative d-flex"
                                            style={{ alignItems: "center" }}
                                            onClick={() =>
                                              handleEditClick(fieldValue)
                                            }
                                          >
                                            {editingFields[fieldValue] &&
                                            showType === fieldValue ? (
                                              <Select
                                                value={
                                                  updatedFields[fieldValue]
                                                }
                                                menuPlacement="auto"
                                                onChange={(selectedOption) =>
                                                  handleChangeIndDropDown(
                                                    selectedOption,
                                                    fieldValue
                                                  )
                                                }
                                                options={
                                                  fieldValue === "ratingInfo"
                                                    ? ratingOptions
                                                    : fieldValue ===
                                                      "accountTypeInfo"
                                                    ? accountTypeOptions
                                                    : fieldValue ===
                                                      "ownershipInfo"
                                                    ? ownershipOptions
                                                    : industryOptions
                                                }
                                                styles={fieldDropdownStyles}
                                                isSearchable={false}
                                              />
                                            ) : (
                                              <input
                                                type="text"
                                                className={`cursorPointer indvEditInput ${
                                                  showType === fieldValue
                                                    ? "selectedBorder "
                                                    : "unselectBorder bg-transparent"
                                                }`}
                                                value={
                                                  accountInfoDetails[fieldValue]
                                                    ?.value
                                                }
                                                name={fieldValue}
                                                onClick={(e) => {
                                                  if (showType === "") {
                                                    updatedFields[fieldValue] =
                                                      accountInfoDetails[
                                                        fieldValue
                                                      ];
                                                    setShowType(e.target.name);
                                                    setUpdatedFields(
                                                      updatedFields
                                                    );
                                                  }
                                                  handleEditClick(fieldValue);
                                                }}
                                                readOnly={true}
                                              />
                                            )}
                                            {editingFields[fieldValue] &&
                                              showType === fieldValue && (
                                                <div className="d-flex gap-2">
                                                  <span
                                                    onClick={() => {
                                                      setShowType("");
                                                      handleUpdateClick(
                                                        fieldValue
                                                      );
                                                    }}
                                                    className="ps-2 cursorPointer"
                                                  >
                                                    <img
                                                      src={updateIcon}
                                                      alt="img"
                                                      height={20}
                                                      width={20}
                                                    />
                                                  </span>
                                                  <span
                                                    onClick={() => {
                                                      setShowType("");
                                                      handleCancelClick(
                                                        fieldValue
                                                      );
                                                    }}
                                                    className="cursorPointer"
                                                  >
                                                    <img
                                                      src={cancelIcon}
                                                      alt="img"
                                                      height={20}
                                                      width={20}
                                                    />
                                                  </span>
                                                </div>
                                              )}
                                          </div>
                                        ) : field.type === "readOnly" ? (
                                          <div>
                                            <span className="d-flex flex-column">
                                              <span>
                                                {
                                                  accountInfoDetails[
                                                    field.name === "Created By"
                                                      ? "createdBy"
                                                      : field.name ===
                                                        "Modified By"
                                                      ? "modifiedBy"
                                                      : "parentAccountName"
                                                  ]
                                                }
                                              </span>
                                              {(field.name === "Created By" ||
                                                field.name ===
                                                  "Modified By") && (
                                                <span className="small opacity-75">
                                                  {
                                                    accountInfoDetails[
                                                      field.name ===
                                                      "Created By"
                                                        ? "createdDate"
                                                        : "modifiedDate"
                                                    ]
                                                  }
                                                </span>
                                              )}
                                            </span>
                                          </div>
                                        ) : (
                                          <div className="position-relative">
                                            <div
                                              className="d-flex  position-relative"
                                              onClick={(e) => {
                                                handleOwnerInputClick(e);
                                                setShowModalType("changeOwner");
                                              }}
                                            >
                                              <input
                                                type="text"
                                                className="w-100 cursorPointer  indvEditInput border-0 indivOwnerInput"
                                                value={selectedItem?.name}
                                                readOnly
                                              />
                                            </div>
                                          </div>
                                        )}
                                      </div>
                                    </div>
                                  );
                                })}
                            </div>
                            <div className="w-50 ">
                              {fieldIndividualDropdowns
                                .slice(9, 18)
                                .map((field) => {
                                  const fieldValue =
                                    field.value ||
                                    field.name
                                      .replace(/\s+/g, "")
                                      .replace(/^(.)/, (match) =>
                                        match.toLowerCase()
                                      );
                                  return (
                                    <div
                                      className="d-flex justify-content-between align-items-center my-2 w-100 gap-3"
                                      key={fieldValue}
                                    >
                                      <div className="opacity-75 p-2 w-100">
                                        {field.name}
                                      </div>
                                      <div className="w-100">
                                        {field.type === "input" ? (
                                          <EditableInput
                                            fieldType={
                                              ["dueDate"].includes(fieldValue)
                                                ? "date"
                                                : "text"
                                            }
                                            className="ps-3"
                                            inputType={fieldValue}
                                            handleIndividualInputChange={
                                              handleIndividualInputChange
                                            }
                                            editingFields={editingFields}
                                            handleEditClick={handleEditClick}
                                            handleCancelClick={
                                              handleCancelClick
                                            }
                                            handleUpdateClick={
                                              handleUpdateClick
                                            }
                                            updatedFields={updatedFields}
                                            accountOverviewDetails={
                                              accountInfoDetails
                                            }
                                            setShowType={setShowType}
                                            showType={showType}
                                            fieldsType="second"
                                          />
                                        ) : field.type === "dropdown" ? (
                                          <div
                                            className="position-relative d-flex"
                                            style={{ alignItems: "center" }}
                                            onClick={() =>
                                              handleEditClick(fieldValue)
                                            }
                                          >
                                            {editingFields[fieldValue] &&
                                            showType === fieldValue ? (
                                              <Select
                                                value={
                                                  updatedFields[fieldValue]
                                                }
                                                menuPlacement="auto"
                                                onChange={(selectedOption) =>
                                                  handleChangeIndDropDown(
                                                    selectedOption,
                                                    fieldValue
                                                  )
                                                }
                                                options={
                                                  fieldValue === "ratingInfo"
                                                    ? ratingOptions
                                                    : fieldValue ===
                                                      "accountTypeInfo"
                                                    ? accountTypeOptions
                                                    : fieldValue ===
                                                      "ownershipInfo"
                                                    ? ownershipOptions
                                                    : industryOptions
                                                }
                                                styles={fieldDropdownStyles}
                                                isSearchable={false}
                                              />
                                            ) : (
                                              <input
                                                type="text"
                                                className={`cursorPointer indvEditInput ${
                                                  showType === fieldValue
                                                    ? "selectedBorder "
                                                    : "unselectBorder bg-transparent"
                                                }`}
                                                value={
                                                  accountInfoDetails[fieldValue]
                                                    ?.value
                                                }
                                                name={fieldValue}
                                                onClick={(e) => {
                                                  if (showType === "") {
                                                    updatedFields[fieldValue] =
                                                      accountInfoDetails[
                                                        fieldValue
                                                      ];
                                                    setShowType(e.target.name);
                                                    setUpdatedFields(
                                                      updatedFields
                                                    );
                                                  }
                                                  handleEditClick(fieldValue);
                                                }}
                                                readOnly={true}
                                              />
                                            )}
                                            {editingFields[fieldValue] &&
                                              showType === fieldValue && (
                                                <div className="d-flex gap-2">
                                                  <span
                                                    onClick={() => {
                                                      setShowType("");
                                                      handleUpdateClick(
                                                        fieldValue
                                                      );
                                                    }}
                                                    className="ps-2 cursorPointer"
                                                  >
                                                    <img
                                                      src={updateIcon}
                                                      alt="img"
                                                      height={20}
                                                      width={20}
                                                    />
                                                  </span>
                                                  <span
                                                    onClick={() => {
                                                      setShowType("");
                                                      handleCancelClick(
                                                        fieldValue
                                                      );
                                                    }}
                                                    className="cursorPointer"
                                                  >
                                                    <img
                                                      src={cancelIcon}
                                                      alt="img"
                                                      height={20}
                                                      width={20}
                                                    />
                                                  </span>
                                                </div>
                                              )}
                                          </div>
                                        ) : field.type === "readOnly" ? (
                                          <div>
                                            <span className="d-flex flex-column">
                                              <span>
                                                {
                                                  accountInfoDetails[
                                                    field.name === "Created By"
                                                      ? "createdBy"
                                                      : field.name ===
                                                        "Modified By"
                                                      ? "modifiedBy"
                                                      : "parentAccountName"
                                                  ]
                                                }
                                              </span>
                                              {(field.name === "Created By" ||
                                                field.name ===
                                                  "Modified By") && (
                                                <span className="small opacity-75">
                                                  {
                                                    accountInfoDetails[
                                                      field.name ===
                                                      "Created By"
                                                        ? "createdDate"
                                                        : "modifiedDate"
                                                    ]
                                                  }
                                                </span>
                                              )}
                                            </span>
                                          </div>
                                        ) : (
                                          <div className="position-relative">
                                            <div
                                              className="d-flex input-with-clear position-relative"
                                              // onClick={(e) => {
                                              //   e.stopPropagation();
                                              //   setListVisibleType(
                                              //     listVisibleType === fieldValue
                                              //       ? ""
                                              //       : fieldValue
                                              //   );
                                              // }}
                                            >
                                              {/* <span
                                              className="w-100 ps-3 cursorPointer  rounded-2   indvEditInput border-0 indivOwnerInput"
                                          
                                            >
                                              {salesInfoDetails[fieldValue]}
                                            </span> */}
                                              <input
                                                type="text"
                                                className={`cursorPointer indvEditInput rounded-1 w-100 ${
                                                  showType === "salesOwnerId"
                                                    ? "selectedBorder "
                                                    : "unselectBorder bg-transparent"
                                                }`}
                                                value={
                                                  accountInfoDetails[fieldValue]
                                                }
                                                readOnly
                                              />
                                            </div>
                                          </div>
                                        )}
                                      </div>
                                    </div>
                                  );
                                })}
                            </div>
                          </div>
                        </div>
                        <h5 className="w-100 text-primary px-2 py-2">
                          Address Information
                        </h5>

                        <div className="d-flex justify-content-between">
                          <div className="leadContact_container d-flex gap-4 w-100 ps-2 pe-2">
                            <div className="w-50">
                              {fieldIndividualDropdowns
                                .slice(18, 23)
                                .map((field) => {
                                  const fieldValue =
                                    field.value ||
                                    field.name
                                      .replace(/\s+/g, "")
                                      .replace(/^(.)/, (match) =>
                                        match.toLowerCase()
                                      );

                                  return (
                                    <div
                                      className="d-flex justify-content-between align-items-center my-2 w-100 gap-3"
                                      key={fieldValue}
                                    >
                                      <div className="opacity-75 p-2 w-100">
                                        {field.name}
                                      </div>
                                      <div className="w-100">
                                        <EditableInput
                                          fieldType={"text"}
                                          className="ps-3"
                                          inputType={fieldValue}
                                          handleIndividualInputChange={
                                            handleIndividualInputChange
                                          }
                                          editingFields={editingFields}
                                          handleEditClick={handleEditClick}
                                          handleCancelClick={handleCancelClick}
                                          handleUpdateClick={handleUpdateClick}
                                          updatedFields={updatedFields}
                                          accountOverviewDetails={
                                            accountInfoDetails
                                          }
                                          setShowType={setShowType}
                                          showType={showType}
                                          fieldsType="second"
                                        />
                                      </div>
                                    </div>
                                  );
                                })}
                            </div>
                            <div className="w-50">
                              {fieldIndividualDropdowns
                                .slice(23, 29)
                                .map((field) => {
                                  const fieldValue =
                                    field.value ||
                                    field.name
                                      .replace(/\s+/g, "")
                                      .replace(/^(.)/, (match) =>
                                        match.toLowerCase()
                                      );
                                  return (
                                    <div
                                      className="d-flex justify-content-between align-items-center my-2 w-100 gap-3"
                                      key={fieldValue}
                                    >
                                      <div className="opacity-75 p-2 w-100">
                                        {field.name}
                                      </div>
                                      <div className="w-100">
                                        <EditableInput
                                          fieldType={"text"}
                                          className="ps-3"
                                          inputType={fieldValue}
                                          handleIndividualInputChange={
                                            handleIndividualInputChange
                                          }
                                          editingFields={editingFields}
                                          handleEditClick={handleEditClick}
                                          handleCancelClick={handleCancelClick}
                                          handleUpdateClick={handleUpdateClick}
                                          updatedFields={updatedFields}
                                          accountOverviewDetails={
                                            accountInfoDetails
                                          }
                                          setShowType={setShowType}
                                          showType={showType}
                                          fieldsType="second"
                                        />
                                      </div>
                                    </div>
                                  );
                                })}
                            </div>
                          </div>
                        </div>
                        <h5 className="w-100 text-primary mt-4 ps-3">
                          Description
                        </h5>
                        <div className="d-flex gap-2 px-2">
                          <span className="opacity-75 px-2 py-2">
                            Description
                          </span>
                          {/* <p>{accountInfoDetails?.description}</p> */}
                          <EditableInput
                            fieldType="text"
                            inputType="descriptionInfo"
                            handleIndividualInputChange={
                              handleIndividualInputChange
                            }
                            editingFields={editingFields}
                            handleEditClick={handleEditClick}
                            handleCancelClick={handleCancelClick}
                            handleUpdateClick={handleUpdateClick}
                            updatedFields={updatedFields}
                            accountOverviewDetails={accountInfoDetails}
                            showType={showType}
                            setShowType={setShowType}
                            isTextarea={true}
                          />
                        </div>
                      </div>
                      {notePermission === "TRUE" && (
                        <div
                          id="notesSection"
                          className="lead_Information_mainContainer commonContainer_bg rounded-2  d-flex flex-column py-2 mt-2"
                        >
                          <div>
                            <Note
                              note={note}
                              addTitle={addTitle}
                              showButtons={showButtons}
                              setShowButtons={setShowButtons}
                              textFile={selectedNoteFiles}
                              showTitleNote={showTitleNote}
                              setShowTitleNote={setShowTitleNote}
                              handleTextAreaClick={handleTextAreaClick}
                              handleSaveButton={insertNote}
                              handleAddTitleClick={handleAddTitleClick}
                              noteInfo={noteInfo}
                              selectRecent={selectRecent}
                              handleSelectRecent={handleSelectRecent}
                              handleDeleteClick={deleteNoteDetails}
                              leadId={accountId}
                              handleUpdateClick={updateNote}
                              setUpdateNotes={setUpdateNotes}
                              updateNotes={updateNotes}
                              setEditNote={setEditNote}
                              editNote={editNote}
                              handleChangeNoteTitle={handleChangeNoteTitle}
                              handleChangeNoteDescription={
                                handleChangeNoteDescription
                              }
                              setAddTitle={setAddTitle}
                              setNote={setNote}
                              selectedNoteFiles={selectedNoteFiles}
                              setSelectedNoteFiles={setSelectedNoteFiles}
                              onChildDataChange={handleChildDataChange}
                              moduleType={"Accounts"}
                              noteDeleteConfirmation={noteDeleteConfirmation}
                              setNoteDeleteConfirmation={
                                setNoteDeleteConfirmation
                              }
                            />

                            {showTitleNote && (
                              <textarea
                                rows={2}
                                className="notesClass px-2"
                                placeholder="Add note Title"
                                value={addTitle}
                                onChange={(e) => setAddTitle(e.target.value)}
                                style={{ width: "95%", marginTop: "10px" }}
                              />
                            )}
                          </div>
                        </div>
                      )}

                      <div
                        id="products"
                        className="lead_Information_mainContainer commonContainer_bg rounded-2  d-flex flex-column py-2 mt-4"
                      >
                        <Product
                          module={"Accounts"}
                          productsList={productsList}
                          assignContactID={accountId}
                          handleAssignProductModule={handleAssignProductModule}
                          selectedProductId={selectedProductId}
                          handledeleteModule={(productId) =>
                            handledeleteModule(productId)
                          }
                          setSelectedProductId={setSelectedProductId}
                          apiLoader={apiLoader}
                        />
                      </div>

                      <div
                        id="openActivities"
                        className="lead_Information_mainContainer commonContainer_bg rounded-2  d-flex flex-column py-2 mt-4"
                      >
                        <OpenActivity
                          openActivities={openActivities}
                          getOverview={() => accountOverviewDetail(accountId)}
                          openActivitiesCalls={openActivitiesCalls}
                          openActivitiesMeetings={openActivitiesMeetings}
                          moduleName="Accounts"
                        />
                      </div>

                      <div
                        id="closedActivities"
                        className="lead_Information_mainContainer commonContainer_bg rounded-2  d-flex flex-column py-2 mt-4"
                      >
                        <CloseActivity
                          closeActivities={closeActivities}
                          getOverview={() => accountOverviewDetail(accountId)}
                          closeActivitiesCalls={closedActivitiesCall}
                          closeActivitiesMeetings={closedActivitiesMeetings}
                        />
                      </div>

                      <div id="contacts">
                        <ContactCommonTable
                          title="Contacts"
                          buttonLabel="New"
                          buttonAction={() => {
                            setCurrentView("contacts");
                            setInnerPage("create");
                            setPastViewInfo((prev) => ({
                              ...prev,
                              view: currentView,
                              innerPage: innerPage,
                              undo: null,
                            }));
                          }}
                          showHeadings={showHeadingsContact}
                          setShowHeadings={setShowHeadingsContact}
                          selectedHeadings={selectedHeadingsContact}
                          data={filterContactDetails}
                          onRowClick={(index) => alert(`Row ${index} clicked`)}
                          manageColumnsAction={() => {
                            setShowModalType("Manage Contact Columns");
                            setSearchColumn("");
                            setSavingHeadings(contactColumn);
                          }}
                          resetColumnSizeAction={() =>
                            alert("Reset Column Size")
                          }
                          setShowSelectedFilter={setShowSelectedFilter}
                          selectedContactsId={selectedContactsId}
                          setSelectedContactsId={setSelectedContactsId}
                          handleAssignContactModule={handleAssignContactModule}
                          setSearchColumn={setSearchColumn}
                          setSavingHeadings={setSavingHeadings}
                          showCancelModal={showCancelModal}
                          setShowCancelModal={setShowCancelModal}
                          handledeleteModuleContact={(contactId) =>
                            handledeleteModuleContact(contactId)
                          }
                        />
                      </div>

                      <div id="deals">
                        <DealsCommonTable
                          title="Deals"
                          buttonLabel="New"
                          buttonAction={() => {
                            setCurrentView("deals");
                            setInnerPage("create");
                            setPastViewInfo((prev) => ({
                              ...prev,
                              view: currentView,
                              innerPage: innerPage,
                              undo: null,
                            }));
                          }}
                          showHeadings={showHeadingsDeals}
                          setShowHeadings={setShowHeadingsDeals}
                          selectedHeadings={selectedHeadingsDeals}
                          data={filterDealsDetails}
                          manageColumnsAction={() => {
                            setShowModalType("Manage Deals Columns");
                            setSearchColumn("");
                            setSavingHeadings(dealsColumn);
                          }}
                          setShowSelectedFilter={setShowSelectedFilter}
                          selectedDealsId={selectedDealsId}
                          setSelectedDealsId={setSelectedDealsId}
                          handleAssignDealsModule={handleAssignDealsModule}
                          setSearchColumn={setSearchColumn}
                          setSavingHeadings={setSavingHeadings}
                          handledeleteModuleDeal={handledeleteModuleDeal}
                        />
                      </div>

                      <div id="invoices">
                        <InvoicesCommonTable
                          title="Invoices"
                          buttonLabel="New"
                          buttonAction={() => {
                            setCurrentView("invoices");
                            setInnerPage("create");
                            setPastViewInfo((prev) => ({
                              ...prev,
                              view: currentView,
                              innerPage: innerPage,
                              undo: null,
                            }));
                          }}
                          showHeadings={showHeadingsInvoices}
                          setShowHeadings={setShowHeadingsInvoices}
                          selectedHeadings={selectedHeadingsInvoices}
                          data={filterInvoicesDetails}
                          onRowClick={(index) => alert(`Row ${index} clicked`)}
                          manageColumnsAction={() => {
                            setShowModalType("Manage Invoices Columns");
                            setSearchColumn("");
                            setSavingHeadings(invoiceColumns);
                          }}
                          resetColumnSizeAction={() =>
                            alert("Reset Column Size")
                          }
                          setShowSelectedFilter={setShowSelectedFilter}
                          selectedInvoiceId={selectedInvoiceId}
                          setSelectedInvoiceId={setSelectedInvoiceId}
                          handleAssignInvoiceModule={handleAssignInvoiceModule}
                          setSearchColumn={setSearchColumn}
                          setSavingHeadings={setSavingHeadings}
                        />
                      </div>

                      <div id="salesOrders">
                        <SalesCommonTable
                          title="Sales Orders"
                          buttonLabel="New"
                          buttonAction={() => {
                            setCurrentView("salesOrders");
                            setInnerPage("create");
                            setPastViewInfo((prev) => ({
                              ...prev,
                              view: currentView,
                              innerPage: innerPage,
                              undo: null,
                            }));
                          }}
                          showHeadings={showHeadingsSales}
                          setShowHeadings={setShowHeadingsSales}
                          selectedHeadings={selectedHeadingsSales}
                          data={filterSalesDetails}
                          onRowClick={(index) => alert(`Row ${index} clicked`)}
                          manageColumnsAction={() => {
                            setShowModalType("Manage Sales Columns");
                            setSearchColumn("");
                            setSavingHeadings(salesColumns);
                          }}
                          resetColumnSizeAction={() =>
                            alert("Reset Column Size")
                          }
                          setShowSelectedFilter={setShowSelectedFilter}
                          setSelectedSalesId={setSelectedSalesId}
                          handleAssignSalesModule={handleAssignSalesModule}
                          selectedSalesId={selectedSalesId}
                          setSearchColumn={setSearchColumn}
                          setSavingHeadings={setSavingHeadings}
                        />
                      </div>

                      <div id="quotes">
                        <QuotesCommonTable
                          title="Quotes"
                          buttonLabel="New"
                          buttonAction={() => {
                            setCurrentView("quotes");
                            setInnerPage("create");
                            setPastViewInfo((prev) => ({
                              ...prev,
                              view: currentView,
                              innerPage: innerPage,
                              undo: null,
                            }));
                          }}
                          showHeadings={showHeadingsQuotes}
                          setShowHeadings={setShowHeadingsQuotes}
                          selectedHeadings={selectedHeadingsQuotes}
                          data={filterQuotesDetails}
                          onRowClick={(index) => alert(`Row ${index} clicked`)}
                          manageColumnsAction={() => {
                            setShowModalType("Manage Quotes Columns");
                            setSearchColumn("");
                            setSavingHeadings(quotesColumn);
                          }}
                          resetColumnSizeAction={() =>
                            alert("Reset Column Size")
                          }
                          setShowSelectedFilter={setShowSelectedFilter}
                          setSelectedQuoteId={setSelectedQuoteId}
                          handleAssignQuoteModule={handleAssignQuoteModule}
                          selectedQuoteId={selectedQuoteId}
                          setSearchColumn={setSearchColumn}
                          setSavingHeadings={setSavingHeadings}
                        />
                      </div>
                    </div>
                  </div>
                ) : (
                  <>
                    <div className="timeline_main_container  rounded-2 bg-white  d-flex flex-column gap-4 w-75 ">
                      {/* <div className="upcomingAction_container w-100 flex-column h-25 py-2  d-flex  commonContainer_bg rounded-2">
                        <div className="header d-flex gap-2 p-2">
                          <span>
                            <img
                              src={hierachy}
                              alt="img"
                              height={20}
                              width={20}
                            />
                          </span>
                          <span>Upcoming Actions</span>
                        </div>
                        <div className="horizontal_line w-100  mx-1 border border-white"></div>

                        <div
                          className="d-flex justify-content-center align-items-center "
                          style={{ height: "20vh" }}
                        >
                          <h4 className="opacity-75 ">No upcoming actions</h4>
                        </div>
                      </div> */}
                      <div className="w-100 historyMain_Container flex-column   d-flex  commonContainer_bg rounded-2 ">
                        <div className="header d-flex gap-2 p-2">
                          <span>
                            <img
                              src={history}
                              alt="img"
                              height={20}
                              width={20}
                            />
                          </span>
                          <span>History</span>
                        </div>
                        <div className="horizontal_line w-100   mx-1"></div>
                        <div className="historyContent_mainContainer ">
                          {timeLineDataList &&
                          Object.keys(timeLineDataList).length > 0 ? (
                            Object.keys(timeLineDataList).map((date) => (
                              <div
                                className="history_container pt-3 d-flex flex-column"
                                key={date}
                              >
                                <span className="px-2 timelineBg_color border border-primary rounded-5 py-2 mx-5">
                                  {date}
                                </span>
                                {timeLineDataList[date].map((item, index) => (
                                  <div className="d-flex gap-2" key={index}>
                                    <span className="opacity-75 pt-5 ps-2">
                                      {item.createdTime}
                                    </span>
                                    <div className="d-flex flex-column align-items-center">
                                      <p className="vertical_line m-0 p-0"></p>
                                      <span>
                                        <img
                                          src={groupIcon2}
                                          alt="img"
                                          height={25}
                                          width={25}
                                        />
                                      </span>
                                    </div>
                                    <span className="pt-5">
                                      {`${item.tmlDescription} ${item.tmlCreatedBy} ${date}`}
                                    </span>
                                  </div>
                                ))}
                              </div>
                            ))
                          ) : (
                            <div
                              className="text-center d-flex justify-content-center align-items-center opacity-75"
                              style={{ height: "20vh" }}
                            >
                              <h4 className="h-75">No Data available</h4>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
          {/* ---------------------- */}
          <Modal
            show={showImageModal}
            className="d-flex justify-content-center align-items-center"
            aria-labelledby="example-custom-modal-styling-title"
            size="lg"
            id="commonLeadModal"
          >
            <Modal.Header
              closeButton
              onClick={() => {
                setShowImageModal(false);
                setUploadImg(false);
              }}
            >
              {accountInfoDetails.accountImageData && (
                <button
                  className="imageAccButtonRemove"
                  onClick={() => {
                    setShowRemoveModal(true);
                  }}
                >
                  Remove
                </button>
              )}
            </Modal.Header>
            <Modal.Body className="ModalBody overflow-auto">
              <div className="d-flex flex-column gap-4">
                <div className="d-flex" style={{ position: "relative" }}>
                  {accountInfoDetails.accountImageData && (
                    <a
                      href={
                        uploadImg
                          ? imageUrl
                          : accountInfoDetails.accountImageData
                      }
                      download="image.jpg"
                      style={{
                        position: "absolute",
                        right: "10px",
                        top: "5px",
                        background: "#b0b0b2",
                        borderRadius: "10px",
                        padding: "5px",
                        cursor: "pointer",
                      }}
                    >
                      <img src={downloadIcon} alt="download" />
                    </a>
                  )}

                  <img
                    alt="upload"
                    src={
                      uploadImg
                        ? imageUrl
                        : accountInfoDetails.accountImageData !== null
                        ? accountInfoDetails.accountImageData
                        : AccountAvatar
                    }
                    className="profilePhoto"
                  />
                </div>
                <p>{deleteMsg}</p>
                <div className="d-flex w-100 gap-2">
                  <input
                    type="file"
                    id="accountImage"
                    name="accountImage"
                    accept="image/*"
                    className="file-control"
                    onChange={handleUploadImage}
                  />
                  <label htmlFor="accountImage" className="imageAccButton">
                    <span>Upload Image</span>
                  </label>
                  <button
                    className="imageAccButtonSave"
                    onClick={() => {
                      uploadImage();
                    }}
                  >
                    {imageLoader ? (
                      <Lottie
                        options={defaultOptions("white")}
                        height="24px"
                        width="36px"
                      />
                    ) : (
                      "Save"
                    )}
                  </button>
                </div>
                {imageMsg && (
                  <span className="mb-0 small erro_msg">{imageMsg}</span>
                )}
              </div>
            </Modal.Body>
          </Modal>
          {/* ---------------------- */}
          <Modal
            show={showRemoveModal}
            className="d-flex justify-content-center align-items-center"
            aria-labelledby="example-custom-modal-styling-title"
            size="lg"
            // id="commonLeadModal"
          >
            <Modal.Body className="ModalBody overflow-auto">
              <div className="d-flex flex-column gap-4">
                <p className="text-center mt-3">
                  Are you sure you want to remove?
                </p>
                <div className="d-flex w-100 gap-2">
                  <button
                    className="imageAccButton"
                    onClick={() => {
                      setShowRemoveModal(false);
                      setShowImageModal(true);
                    }}
                  >
                    Cancel
                  </button>
                  <button
                    className="imageAccButtonSave"
                    onClick={() => {
                      removeImage();
                    }}
                  >
                    {imageLoader ? (
                      <Lottie
                        options={defaultOptions("white")}
                        height="24px"
                        width="36px"
                      />
                    ) : (
                      "Remove"
                    )}
                  </button>
                </div>
              </div>
            </Modal.Body>
          </Modal>

          <ShareModal
            moduleType="ACCOUNTS"
            setShareModal={setShareModal}
            shareModal={shareModal}
            shareInfoModal={shareInfoModal}
            setShareInfoModal={setShareInfoModal}
            id={accountId}
            shareUserList={shareUserList}
            shareGroupList={shareGroupList}
            shareRoleList={shareRoleList}
            shareMemberIds={shareMemberIds}
            sharePublicList={sharePublicList}
            modalHeading={"Sharing Account"}
            module={"Accounts"}
          />
          {/* ---------------------- */}
          <Modal
            show={deleteModal}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
          >
            <Modal.Header
              onClick={() => {
                setDeleteModal(false);
              }}
              style={{
                fontSize: "20px",
                fontWeight: "bold",
                background:
                  "linear-gradient(to right, rgba(64, 123, 255, 1), rgba(18, 167, 254, 1))",
                WebkitBackgroundClip: "text",
                backgroundClip: "text",
                WebkitTextFillColor: "transparent",
              }}
            >
              Are you sure you want to delete &nbsp;
              <span>{accountInfoDetails?.accountName}</span>?
            </Modal.Header>
            <Modal.Body className="ModalBody overflow-auto">
              <div className="d-flex">
                <p className="mb-0 fw-bold">Note :&nbsp;</p>
                <span>
                  Any associated activities, visits, drafts will also be
                  deleted.
                </span>
              </div>
              <div className="d-flex flex-column gap-4">
                <div className="d-flex justify-content-end w-100 gap-4">
                  <button
                    className="cancelButton"
                    onClick={() => setDeleteModal(false)}
                  >
                    Cancel
                  </button>
                  <button
                    className="createContactButtonSave"
                    onClick={() => {
                      deleteAccountDetails();
                    }}
                  >
                    Delete
                  </button>
                </div>
              </div>
            </Modal.Body>
          </Modal>
          {/* ---------------------- */}
        </>
      );
    }
  };
  const handleColumnCheckboxChange = (name, isChecked) => {
    setSavingHeadings((prevHeadings) =>
      prevHeadings.map((heading) =>
        heading.name === name ? { ...heading, isChecked } : heading
      )
    );
  };
  const handleColumnCheckboxChangeContact = (name, isChecked) => {
    setSavingHeadingsContact((prevHeadings) =>
      prevHeadings.map((heading) =>
        heading.name === name ? { ...heading, isChecked } : heading
      )
    );
  };
  const handleColumnCheckboxChangeQuotes = (name, isChecked) => {
    setSavingHeadingsQuotes((prevHeadings) =>
      prevHeadings.map((heading) =>
        heading.name === name ? { ...heading, isChecked } : heading
      )
    );
  };
  const handleColumnCheckboxChangeDeals = (name, isChecked) => {
    setSavingHeadingsDeals((prevHeadings) =>
      prevHeadings.map((heading) =>
        heading.name === name ? { ...heading, isChecked } : heading
      )
    );
  };
  const handleColumnCheckboxChangeInvoices = (name, isChecked) => {
    setSavingHeadingsInvoices((prevHeadings) =>
      prevHeadings.map((heading) =>
        heading.name === name ? { ...heading, isChecked } : heading
      )
    );
  };
  const handleColumnCheckboxChangeSales = (name, isChecked) => {
    setSavingHeadingsSales((prevHeadings) =>
      prevHeadings.map((heading) =>
        heading.name === name ? { ...heading, isChecked } : heading
      )
    );
  };
  return (
    <div
      onClick={() => {
        setListVisible(false);
        setParentListVisible(false);
        setShowHeadings(false);
        setShowSelectedFilter("");
        setSelectedField("");
        setShowOptionsLead(false);
      }}
    >
      {handleContactsView()}
      <Modal
        show={showModalType === "Manage Columns"}
        id="ConvertModal"
        aria-labelledby="example-custom-modal-styling-title"
        className="withdrw_modal"
      >
        <Modal.Body>
          <div className="modalClass  position-relative">
            <div className="d-flex justify-content-between ">
              <h4 className="">Manage Columns</h4>
              {/* <img
                src={crossIcon}
                alt="cross"
                style={{ cursor: "pointer" }}
                className="ms-1"
                onClick={() => {
                  setShowModalType("");
                }}
              /> */}
            </div>
            <div className="w-100 position-relative">
              <div className="mb-1 pt-2 position-relative w-100">
                <input
                  type="text"
                  className="assetSearchbar searchbar rounded-2 p-2 pe-5 common_input_bg w-100 "
                  placeholder="Search"
                  aria-label="Username"
                  aria-describedby="basic-addon1"
                  value={searchColumn}
                  onChange={(e) => {
                    if (e.target.value.charAt(0) !== " ") {
                      const value = e.target.value.replace(
                        /[^a-zA-Z ]+|(\s)\s+/g,
                        "$1"
                      );
                      setSearchColumn(value);
                      // const list = accountColumn.filter((lead) =>
                      //   lead.name
                      //     .toLowerCase()
                      //     .includes(value.trim().toLowerCase())
                      // );
                      // setSavingHeadings(list);
                    }
                  }}
                />
                <span
                  className="position-absolute  mt-2 me-2 end-0 opacity-75"
                  onClick={() => {
                    setSearchColumn("");
                    setSavingHeadings(accountColumn);
                  }}
                >
                  {searchColumn === "" ? (
                    <img src={searchIcon} alt="" width={20} />
                  ) : (
                    <div style={{ cursor: "pointer" }}>X</div>
                  )}
                </span>
              </div>
              <div className=" opacity-75 columns_container">
                {savingHeadings
                  .filter((account) =>
                    account.name
                      .toLowerCase()
                      .includes(searchColumn.trim().toLowerCase())
                  )
                  .map((column) => {
                    return (
                      <div
                        key={column.name}
                        className="d-flex gap-2 py-2 rounded-2 align-items-center"
                      >
                        <div className="d-flex gap-2 ps-2">
                          <input
                            type="checkbox"
                            id={column.name}
                            name={column.name}
                            checked={column.isChecked}
                            onChange={(e) => {
                              handleColumnCheckboxChange(
                                column.name,
                                e.target.checked
                              );
                            }}
                            className="cursorPointer"
                          />
                          <label
                            className="small cursorPointer"
                            htmlFor={column.name}
                          >
                            {column.name}
                          </label>
                        </div>
                      </div>
                    );
                  })}
              </div>
              <aside className="float-end mt-2">
                <div className="d-flex align-items-center gap-4">
                  <button
                    className="cancel_btn bg-light"
                    onClick={() => {
                      setShowModalType("");
                    }}
                  >
                    Cancel
                  </button>
                  <button
                    className="save_btn"
                    onClick={() => {
                      setSelectedHeadings(savingHeadings);
                      setTimeout(() => {
                        setShowModalType("");
                      }, 200);
                    }}
                  >
                    Save
                  </button>
                </div>
              </aside>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      {/* -------------------- */}
      <Modal
        show={showModalType === "Manage Contact Columns"}
        id="ConvertModal"
        aria-labelledby="example-custom-modal-styling-title"
        className="withdrw_modal"
      >
        <Modal.Body>
          <div className="modalClass  position-relative">
            <div className="d-flex justify-content-between ">
              <h4 className="">Manage Columns</h4>
              {/* <img
                src={crossIcon}
                alt="cross"
                style={{ cursor: "pointer" }}
                className="ms-1"
                onClick={() => {
                  setShowModalType("");
                }}
              /> */}
            </div>
            <div className="w-100 position-relative">
              <div className="mb-1 pt-2 position-relative w-100">
                <input
                  type="text"
                  className="assetSearchbar searchbar rounded-2 p-2 pe-5 common_input_bg w-100 "
                  placeholder="Search"
                  aria-label="Username"
                  aria-describedby="basic-addon1"
                  value={searchColumn}
                  onChange={(e) => {
                    if (e.target.value.charAt(0) !== " ") {
                      const value = e.target.value.replace(
                        /[^a-zA-Z ]+|(\s)\s+/g,
                        "$1"
                      );
                      setSearchColumn(value);
                      // const list = contactColumn.filter((contact) =>
                      //   contact.name
                      //     .toLowerCase()
                      //     .includes(value.trim().toLowerCase())
                      // );
                      // setSavingHeadingsContact(list);
                    }
                  }}
                />
                <span
                  className="position-absolute  mt-2 me-2 end-0 opacity-75"
                  onClick={() => {
                    setSearchColumn("");
                    setSavingHeadings(contactColumn);
                  }}
                >
                  {searchColumn === "" ? (
                    <img src={searchIcon} alt="" width={20} />
                  ) : (
                    <div style={{ cursor: "pointer" }}>X</div>
                  )}
                </span>
              </div>
              <div className=" opacity-75 columns_container">
                {savingHeadingsContact
                  .filter((lead) =>
                    lead.name
                      .toLowerCase()
                      .includes(searchColumn.trim().toLowerCase())
                  )
                  .sort((a, b) => a.name.localeCompare(b.name))
                  .map((column) => {
                    return (
                      <div
                        key={column.name}
                        className="d-flex gap-2 py-2 rounded-2 align-items-center"
                      >
                        <div className="d-flex gap-2 ps-2">
                          <input
                            type="checkbox"
                            id={column.name}
                            name={column.name}
                            checked={column.isChecked}
                            onChange={(e) => {
                              handleColumnCheckboxChangeContact(
                                column.name,
                                e.target.checked
                              );
                            }}
                            className="cursorPointer"
                          />
                          <label
                            className="small cursorPointer"
                            htmlFor={column.name}
                          >
                            {column.name}
                          </label>
                        </div>
                      </div>
                    );
                  })}
              </div>
              <aside className="float-end mt-2">
                <div className="d-flex align-items-center gap-4">
                  <button
                    className="cancel_btn bg-light"
                    onClick={() => {
                      setShowModalType("");
                    }}
                  >
                    Cancel
                  </button>
                  <button
                    className="save_btn"
                    onClick={() => {
                      setSelectedHeadingsContact(savingHeadingsContact);
                      setTimeout(() => {
                        setShowModalType("");
                      }, 200);
                    }}
                  >
                    Save
                  </button>
                </div>
              </aside>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      {/* -------------------- */}
      <Modal
        show={showModalType === "Manage Quotes Columns"}
        id="ConvertModal"
        aria-labelledby="example-custom-modal-styling-title"
        className="withdrw_modal"
      >
        <Modal.Body>
          <div className="modalClass  position-relative">
            <div className="d-flex justify-content-between ">
              <h4 className="">Manage Columns</h4>
              {/* <img
                src={crossIcon}
                alt="cross"
                style={{ cursor: "pointer" }}
                className="ms-1"
                onClick={() => {
                  setShowModalType("");
                }}
              /> */}
            </div>
            <div className="w-100 position-relative">
              <div className="mb-1 pt-2 position-relative w-100">
                <input
                  type="text"
                  className="assetSearchbar searchbar rounded-2 p-2 pe-5 common_input_bg w-100 "
                  placeholder="Search"
                  aria-label="Username"
                  aria-describedby="basic-addon1"
                  value={searchColumn}
                  onChange={(e) => {
                    if (e.target.value.charAt(0) !== " ") {
                      const value = e.target.value.replace(
                        /[^a-zA-Z ]+|(\s)\s+/g,
                        "$1"
                      );
                      setSearchColumn(value);
                      // const list = quotesColumn.filter((contact) =>
                      //   contact.name
                      //     .toLowerCase()
                      //     .includes(value.trim().toLowerCase())
                      // );
                      // setSavingHeadingsQuotes(list);
                    }
                  }}
                />
                <span
                  className="position-absolute  mt-2 me-2 end-0 opacity-75"
                  onClick={() => {
                    setSearchColumn("");
                    setSavingHeadings(quotesColumn);
                  }}
                >
                  {searchColumn === "" ? (
                    <img src={searchIcon} alt="" width={20} />
                  ) : (
                    <div style={{ cursor: "pointer" }}>X</div>
                  )}
                </span>
              </div>
              <div className=" opacity-75 columns_container">
                {savingHeadingsQuotes
                  .filter((lead) =>
                    lead.name
                      .toLowerCase()
                      .includes(searchColumn.trim().toLowerCase())
                  )
                  .sort((a, b) => a.name.localeCompare(b.name))
                  .map((column) => {
                    return (
                      <div
                        key={column.name}
                        className="d-flex gap-2 py-2 rounded-2 align-items-center"
                      >
                        <div className="d-flex gap-2 ps-2">
                          <input
                            type="checkbox"
                            id={column.name}
                            name={column.name}
                            checked={column.isChecked}
                            onChange={(e) => {
                              handleColumnCheckboxChangeQuotes(
                                column.name,
                                e.target.checked
                              );
                            }}
                            className="cursorPointer"
                          />
                          <label
                            className="small cursorPointer"
                            htmlFor={column.name}
                          >
                            {column.name}
                          </label>
                        </div>
                      </div>
                    );
                  })}
              </div>
              <aside className="float-end mt-2">
                <div className="d-flex align-items-center gap-4">
                  <button
                    className="cancel_btn bg-light"
                    onClick={() => {
                      setShowModalType("");
                    }}
                  >
                    Cancel
                  </button>
                  <button
                    className="save_btn"
                    onClick={() => {
                      setSelectedHeadingsQuotes(savingHeadingsQuotes);
                      setTimeout(() => {
                        setShowModalType("");
                      }, 200);
                    }}
                  >
                    Save
                  </button>
                </div>
              </aside>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      {/* -------------------- */}
      <Modal
        show={showModalType === "Manage Deals Columns"}
        id="ConvertModal"
        aria-labelledby="example-custom-modal-styling-title"
        className="withdrw_modal"
      >
        <Modal.Body>
          <div className="modalClass  position-relative">
            <div className="d-flex justify-content-between ">
              <h4 className="">Manage Columns</h4>
              {/* <img
                src={crossIcon}
                alt="cross"
                style={{ cursor: "pointer" }}
                className="ms-1"
                onClick={() => {
                  setShowModalType("");
                }}
              /> */}
            </div>
            <div className="w-100 position-relative">
              <div className="mb-1 pt-2 position-relative w-100">
                <input
                  type="text"
                  className="assetSearchbar searchbar rounded-2 p-2 pe-5 common_input_bg w-100 "
                  placeholder="Search"
                  aria-label="Username"
                  aria-describedby="basic-addon1"
                  value={searchColumn}
                  onChange={(e) => {
                    if (e.target.value.charAt(0) !== " ") {
                      const value = e.target.value.replace(
                        /[^a-zA-Z ]+|(\s)\s+/g,
                        "$1"
                      );
                      setSearchColumn(value);
                      // const list = quotesColumn.filter((contact) =>
                      //   contact.name
                      //     .toLowerCase()
                      //     .includes(value.trim().toLowerCase())
                      // );
                      // setSelectedHeadingsDeals(list);
                    }
                  }}
                />
                <span
                  className="position-absolute  mt-2 me-2 end-0 opacity-75"
                  onClick={() => {
                    setSearchColumn("");
                    setSavingHeadings(dealsColumn);
                  }}
                >
                  {searchColumn === "" ? (
                    <img src={searchIcon} alt="" width={20} />
                  ) : (
                    <div style={{ cursor: "pointer" }}>X</div>
                  )}
                </span>
              </div>
              <div className=" opacity-75 columns_container">
                {savingHeadingsDeals
                  .filter((lead) =>
                    lead.name
                      .toLowerCase()
                      .includes(searchColumn.trim().toLowerCase())
                  )
                  .sort((a, b) => a.name.localeCompare(b.name))
                  .map((column) => {
                    return (
                      <div
                        key={column.name}
                        className="d-flex gap-2 py-2 rounded-2 align-items-center"
                      >
                        <div className="d-flex gap-2 ps-2">
                          <input
                            type="checkbox"
                            id={column.name}
                            name={column.name}
                            checked={column.isChecked}
                            onChange={(e) => {
                              handleColumnCheckboxChangeDeals(
                                column.name,
                                e.target.checked
                              );
                            }}
                            className="cursorPointer"
                          />
                          <label
                            className="small cursorPointer"
                            htmlFor={column.name}
                          >
                            {column.name}
                          </label>
                        </div>
                      </div>
                    );
                  })}
              </div>
              <aside className="float-end mt-2">
                <div className="d-flex align-items-center gap-4">
                  <button
                    className="cancel_btn bg-light"
                    onClick={() => {
                      setShowModalType("");
                    }}
                  >
                    Cancel
                  </button>
                  <button
                    className="save_btn"
                    onClick={() => {
                      setSelectedHeadingsDeals(savingHeadingsDeals);
                      setTimeout(() => {
                        setShowModalType("");
                      }, 200);
                    }}
                  >
                    Save
                  </button>
                </div>
              </aside>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      {/* -------------------- */}
      <Modal
        show={showModalType === "Manage Invoices Columns"}
        id="ConvertModal"
        aria-labelledby="example-custom-modal-styling-title"
        className="withdrw_modal"
      >
        <Modal.Body>
          <div className="modalClass  position-relative">
            <div className="d-flex justify-content-between ">
              <h4 className="">Manage Columns</h4>
              {/* <img
                src={crossIcon}
                alt="cross"
                style={{ cursor: "pointer" }}
                className="ms-1"
                onClick={() => {
                  setShowModalType("");
                }}
              /> */}
            </div>
            <div className="w-100 position-relative">
              <div className="mb-1 pt-2 position-relative w-100">
                <input
                  type="text"
                  className="assetSearchbar searchbar rounded-2 p-2 pe-5 common_input_bg w-100 "
                  placeholder="Search"
                  aria-label="Username"
                  aria-describedby="basic-addon1"
                  value={searchColumn}
                  onChange={(e) => {
                    if (e.target.value.charAt(0) !== " ") {
                      const value = e.target.value.replace(
                        /[^a-zA-Z ]+|(\s)\s+/g,
                        "$1"
                      );
                      setSearchColumn(value);
                      // const list = invoiceColumns.filter((contact) =>
                      //   contact.name
                      //     .toLowerCase()
                      //     .includes(value.trim().toLowerCase())
                      // );
                      // setSelectedHeadingsInvoices(list);
                    }
                  }}
                />
                <span
                  className="position-absolute  mt-2 me-2 end-0 opacity-75"
                  onClick={() => {
                    setSearchColumn("");
                    setSavingHeadings(invoiceColumns);
                  }}
                >
                  {searchColumn === "" ? (
                    <img src={searchIcon} alt="" width={20} />
                  ) : (
                    <div style={{ cursor: "pointer" }}>X</div>
                  )}
                </span>
              </div>
              <div className=" opacity-75 columns_container">
                {savingHeadingsInvoices
                  .filter((lead) =>
                    lead.name
                      .toLowerCase()
                      .includes(searchColumn.trim().toLowerCase())
                  )
                  .sort((a, b) => a.name.localeCompare(b.name))
                  .map((column) => {
                    return (
                      <div
                        key={column.name}
                        className="d-flex gap-2 py-2 rounded-2 align-items-center"
                      >
                        <div className="d-flex gap-2 ps-2">
                          <input
                            type="checkbox"
                            id={column.name}
                            name={column.name}
                            checked={column.isChecked}
                            onChange={(e) => {
                              handleColumnCheckboxChangeInvoices(
                                column.name,
                                e.target.checked
                              );
                            }}
                            className="cursorPointer"
                          />
                          <label
                            className="small cursorPointer"
                            htmlFor={column.name}
                          >
                            {column.name}
                          </label>
                        </div>
                      </div>
                    );
                  })}
              </div>
              <aside className="float-end mt-2">
                <div className="d-flex align-items-center gap-4">
                  <button
                    className="cancel_btn bg-light"
                    onClick={() => {
                      setShowModalType("");
                    }}
                  >
                    Cancel
                  </button>
                  <button
                    className="save_btn"
                    onClick={() => {
                      setSelectedHeadingsInvoices(savingHeadingsInvoices);
                      setTimeout(() => {
                        setShowModalType("");
                      }, 200);
                    }}
                  >
                    Save
                  </button>
                </div>
              </aside>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      {/* -------------------- */}
      <Modal
        show={showModalType === "Manage Sales Columns"}
        id="ConvertModal"
        aria-labelledby="example-custom-modal-styling-title"
        className="withdrw_modal"
      >
        <Modal.Body>
          <div className="modalClass  position-relative">
            <div className="d-flex justify-content-between ">
              <h4 className="">Manage Columns</h4>
              {/* <img
                src={crossIcon}
                alt="cross"
                style={{ cursor: "pointer" }}
                className="ms-1"
                onClick={() => {
                  setShowModalType("");
                }}
              /> */}
            </div>
            <div className="w-100 position-relative">
              <div className="mb-1 pt-2 position-relative w-100">
                <input
                  type="text"
                  className="assetSearchbar searchbar rounded-2 p-2 pe-5 common_input_bg w-100 "
                  placeholder="Search"
                  aria-label="Username"
                  aria-describedby="basic-addon1"
                  value={searchColumn}
                  onChange={(e) => {
                    if (e.target.value.charAt(0) !== " ") {
                      const value = e.target.value.replace(
                        /[^a-zA-Z ]+|(\s)\s+/g,
                        "$1"
                      );
                      setSearchColumn(value);
                      // const list = salesColumns.filter((contact) =>
                      //   contact.name
                      //     .toLowerCase()
                      //     .includes(value.trim().toLowerCase())
                      // );
                      // setSelectedHeadingsSales(list);
                    }
                  }}
                />
                <span
                  className="position-absolute  mt-2 me-2 end-0 opacity-75"
                  onClick={() => {
                    setSearchColumn("");
                    setSavingHeadings(salesColumns);
                  }}
                >
                  {searchColumn === "" ? (
                    <img src={searchIcon} alt="" width={20} />
                  ) : (
                    <div style={{ cursor: "pointer" }}>X</div>
                  )}
                </span>
              </div>
              <div className=" opacity-75 columns_container">
                {savingHeadingsSales
                  .filter((lead) =>
                    lead.name
                      .toLowerCase()
                      .includes(searchColumn.trim().toLowerCase())
                  )
                  .sort((a, b) => a.name.localeCompare(b.name))
                  .map((column) => {
                    return (
                      <div
                        key={column.name}
                        className="d-flex gap-2 py-2 rounded-2 align-items-center"
                      >
                        <div className="d-flex gap-2 ps-2">
                          <input
                            type="checkbox"
                            id={column.name}
                            name={column.name}
                            checked={column.isChecked}
                            onChange={(e) => {
                              handleColumnCheckboxChangeSales(
                                column.name,
                                e.target.checked
                              );
                            }}
                            className="cursorPointer"
                          />
                          <label
                            className="small cursorPointer"
                            htmlFor={column.name}
                          >
                            {column.name}
                          </label>
                        </div>
                      </div>
                    );
                  })}
              </div>
              <aside className="float-end mt-2">
                <div className="d-flex align-items-center gap-4">
                  <button
                    className="cancel_btn bg-light"
                    onClick={() => {
                      setShowModalType("");
                    }}
                  >
                    Cancel
                  </button>
                  <button
                    className="save_btn"
                    onClick={() => {
                      setSelectedHeadingsSales(savingHeadingsSales);
                      setTimeout(() => {
                        setShowModalType("");
                      }, 200);
                    }}
                  >
                    Save
                  </button>
                </div>
              </aside>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      {/* Delete Modal */}
      <Modal
        show={["Mass Delete", "Mass Update", "Mass Transfer"].includes(
          showModalType
        )}
        aria-labelledby="contained-modal-title-vcenter"
        onClick={(e) => e.stopPropagation()}
      >
        <Modal.Header
          className=" border-0 mb-0 d-flex flex-column align-items-start"
          style={{ backgroundColor: "#F2F2F2" }}
        >
          <div
            className={`mass_heading fs-4 ${
              showModalType !== "Mass Delete" && "ps-4"
            }`}
          >
            {showModalType}
          </div>
        </Modal.Header>
        <Modal.Body className="ModalBody">
          {showModalType === "Mass Delete" ? (
            <>
              <div className="fs-5 pt-0 pb-2">
                Are you sure you want to delete selected item(s)?
              </div>
              <div className="small">
                <span className="fw-bold">Note: </span>
                <span>
                  Any associated Activities, Contacts, Deals, Quotes, Sales
                  Orders, Invoices, Cases, Drafts will also be Deleted
                </span>
              </div>
            </>
          ) : showModalType === "Mass Update" ? (
            <div className="d-flex gap-2 mb-3 mt-2 align-items-center w-100">
              <div className="w-100">
                <div className="d-flex flex-column ps-1 align-items-center mx-4">
                  <label className="text-start w-100 opacity-75">
                    Select a field
                  </label>
                  <div
                    className="position-relative w-100 rounded-1"
                    style={{ border: "1px solid rgba(185, 185, 185, 0.5)" }}
                  >
                    <div
                      onClick={(e) => {
                        e.stopPropagation();
                        setListVisible(!listVisible);
                      }}
                      className={`cursorPointer d-flex justify-content-between w-100 my-1 p-1 border-0`}
                    >
                      <span className="">{selectedObj.name}</span>
                      <img
                        src={listVisible ? up : down}
                        alt="img"
                        height={20}
                        width={20}
                        className="pt-1"
                      />
                    </div>
                    {listVisible && (
                      <div
                        className="position-absolute field_container w-100"
                        onClick={(e) => {
                          e.stopPropagation();
                        }}
                      >
                        <div className="">
                          <label
                            htmlFor="selectField"
                            className="position-relative w-100"
                          >
                            <input
                              id="selectField"
                              type="text"
                              className="rounded-2 p-1 w-100 pe-4"
                              placeholder="Search"
                              aria-label="Username"
                              aria-describedby="basic-addon1"
                              value={searchField}
                              onChange={(e) => {
                                let value = e.target.value;
                              
                                // Check if the input already has valid alphabetic characters
                                const hasValidChars = /[a-zA-Z]/.test(searchField);
                              
                                if (!hasValidChars) {
                                  // If no valid chars yet, replace all non-alphabetic characters
                                  value = value.replace(/[^a-zA-Z]/g, "");
                                } else {
                                  // If there are valid chars, allow spaces as well
                                  value = value.replace(/[^a-zA-Z\s]/g, "");
                                }
                              
                                setSearchField(value);
                              
                                const filteredData = accountColumn.filter((field) =>
                                  field.name.toLowerCase().includes(value.toLowerCase())
                                );
                                
                                setShowSearchFields(
                                  /^[a-zA-Z\s]+$/.test(value) ? filteredData : fieldLabels
                                );
                              }}
                              
                            />
                            <img
                              className="position-absolute mt-1 me-2 end-0 pt-1 cursorPointer"
                              onClick={() => {
                                setSearchField("");
                              }}
                              src={searchIcon}
                              alt="search"
                              width={20}
                            />
                          </label>

                          <div className="w-100 rounded-2">
                            <div className="border border-1 field_container_dropdown rounded-1">
                              {showSearchFields
                                .sort((a, b) => a.name.localeCompare(b.name))
                                .map(
                                  (field) =>
                                    ![
                                      "Lead ID",
                                      "File Name",
                                      "Description",
                                      "Parent Account ID",
                                      "Account Owner",
                                      "Created By",
                                      "Modified By",
                                      "Hash",
                                    ].includes(field.name) && (
                                      <div
                                        key={field.name}
                                        className="d-flex gap-2 p-2 cursorPointer rounded-2 align-items-center"
                                        onClick={() => {
                                          setClickedDropdownObj({
                                            label: "",
                                            value: "",
                                          });
                                          setMassUpdateValue("");
                                          setSelectedObj(field);
                                          setListVisible(false);
                                        }}
                                      >
                                        <span className="ps-2">
                                          {field.name}
                                        </span>
                                      </div>
                                    )
                                )}
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="w-100 mx-auto d-flex justify-content-center mt-4">
                    <div className="w-100">
                      <label className="opacity-75" htmlFor="massValue">
                        Enter value
                      </label>
                      <div
                        className="position-relative w-100 border rounded-1"
                        style={{ border: "1px solid rgba(185, 185, 185, 0.5)" }}
                      >
                        {[
                          "Account Type",
                          "Ownership",
                          "Rating",
                          "Industry",
                        ].includes(selectedObj.name) ? (
                          <Select
                            value={clickedDropdownObj}
                            menuPlacement="auto"
                            onChange={(selectedOption) => {
                              setClickedDropdownObj(selectedOption);
                              setMassUpdateValue(selectedOption.value);
                            }}
                            options={
                              selectedObj.name === "Rating"
                                ? ratingOptions
                                : selectedObj.name === "Ownership"
                                ? ownershipOptions
                                : selectedObj.name === "Account Type,"
                                ? accountTypeOptions
                                : industryOptions
                            }
                            styles={fieldDropdownStyles}
                            isSearchable={false}
                          />
                        ) : (
                          <input
                            className="w-100 border-0 py-2 rounded-1"
                            type="text"
                            value={massUpdateValue}
                            // onChange={(e) => {
                            //   setMassUpdateValue(e.target.value);
                            // }}
                            onChange={(e) => {
                              const trimmedValue = e.target.value.trimStart();
                              setMassUpdateValue(trimmedValue);
                            }}
                            style={{ outline: "none" }}
                            id="massValue"
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="w-100 px-4 py-2 mb-1">
              <label className="text-start w-100 opacity-75">Transfer To</label>
              <div
                className="d-flex input-with-clear position-relative mx-auto"
                onClick={(e) => {
                  handleOwnerInputClick(e);
                }}
              >
                <input
                  type="text"
                  className="ps-3 w-100 cursorPointer py-2"
                  value={selectedItem?.name}
                  readOnly
                />
                <span
                  className="position-absolute h-100 mass_transfer_user pt-1"
                  style={{ right: "0.1rem" }}
                >
                  <span
                    className="cursorPointer px-1"
                    onClick={() => setIsLeadOwnerModal(!isLeadOwnerModal)}
                  >
                    <img
                      src={userSearch}
                      alt="img"
                      width={20}
                      height={20}
                      className="ms-2 mt-2 me-2"
                    />
                  </span>
                </span>
              </div>
            </div>
          )}

          <div className={`pt-4 pe-3`}>
            <div className="d-flex justify-content-end w-100 gap-4 pe-2">
              <button
                className="cancelButton px-4"
                onClick={() => {
                  setShowModalType("");
                  // setSelectedCheckboxes([]);
                  setShowSearchFields(fieldDropdowns);
                }}
              >
                Cancel
              </button>
              <button
                className="createContactButtonSave"
                onClick={() => {
                  deleteUpdateTransferMass();
                }}
              >
                {showModalType === "Mass Update"
                  ? "Update"
                  : showModalType === "Mass Transfer"
                  ? "Done"
                  : "Delete"}
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <AccessModal
        setShowAccessModal={setShowAccessModal}
        showAccessModal={showAccessModal}
      />

      <ChangeOwnerModal
        modalType={showModalType}
        setShowModalType={setShowModalType}
        selectedOwnerItem={selectedOwnerItem}
        handleChangeOwnerInputClick={handleChangeOwnerInputClick}
        setSelectedOwnerItem={setSelectedOwnerItem}
        isLeadOwnerModal={isLeadOwnerModal}
        setIsLeadOwnerModal={setIsLeadOwnerModal}
        isChangeOwnerList={isChangeOwnerList}
        userList={userList}
        handleChangeOwnerItemClick={handleChangeOwnerItemClick}
        emailNotification={emailNotification}
        setEmailNotification={setEmailNotification}
        handleEditClick={handleEditClick}
        handleUpdateClick={handleUpdateClick}
        setUpdatedFields={setUpdatedFields}
        existingContact={existingAccount}
        handleUpdateContact={handleUpdateAccount}
        onSelectedChangeOwner={handleSelectedLeadChange}
        apiLoader={apiLoader} // for lottie loader
      />

      <LeadOwnerModal
        isLeadOwnerModal={isLeadOwnerModal}
        setIsLeadOwnerModal={setIsLeadOwnerModal}
        onSelectedLeadChange={handleSelectedLeadChange}
        defaultUser={selectedItem}
      />

      {/* -------------------- */}
      <NotificationContainer />
    </div>
  );
}

export default Accounts;

/* eslint-disable */
import React, { useEffect, useState, useRef } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Select from "react-select";
import filterIcon from "../../Assets/Frame (3).svg";
import groupIcon from "../../Assets/De-Active/Deals.svg";
import searchIcon from "../../Assets/search.svg";
import axios from "axios";
import "../../Stylesheet/commonstyle.css";
import cadences from "../../Assets/concade.svg";
import prodIcon from "../../Assets/product.svg";
import openActivityIcon from "../../Assets/openActivity.svg";
import closeActivityIcon from "../../Assets/closeActivity.svg";
import inviteIcon from "../../Assets/invite.svg";
import emailIcon from "../../Assets/mail-01.svg";
import campaignsIcon from "../../Assets/user-group.svg";
import updateIcon from "../../Assets/Group 71.svg";
import cancelIcon from "../../Assets/Group 72.svg";
import groupIcon2 from "../../Assets/Group 74.svg";
import history from "../../Assets/history.svg";
import SearchIcon from "../../Assets/SearchIcon.svg";
import logoConvert from "../../Assets/logoConvert.svg";
import userSearch from "../../Assets/user-search-01.svg";
import down from "../../Assets/Down31.svg";
import up from "../../Assets/Up.svg";
import accountsIcon from "../../Assets/De-Active/Account.svg";
import campaignIcon from "../../Assets/De-Active/campaign.svg";

import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import "react-notifications/lib/notifications.css";
import filterMenu from "../../Assets/filterMenu.svg";
import tableFilterIcon from "../../Assets/filterIcon.svg";
import hierachy from "../../Assets/hierarchy.svg";
import leftSlide from "../../Assets/Vector (1).svg";
import rightSlide from "../../Assets/Vector (2).svg";
import Pagination from "../../common/Pagination";
import Close from "../../Assets/Clone.svg";
import Share from "../../Assets/ShareIcon.svg";
import Delete from "../../Assets/DeleteIconNew.svg";
import NoteBook from "../../Assets/notebook-01.svg";
import { URI } from "../../constants";
import LeadOwnerModal from "../../common/LeadOwnerModal";
import {
  leadSourceOptions,
  industryOptions,
  pageOption,
  stageOptions,
  massOptions,
} from "../../common/CommonData";
import {
  validateAmount,
  validateFirstName,
  removeExtraSpaces,
  customCommonStyles,
} from "../../common/CommonComponent";
import EditableInput from "../../common/EditableInput";
import filterLine from "../../Assets/filterLine.svg";
import { Modal } from "react-bootstrap";
import arrowUp from "../../Assets/square_arrow_up.svg";
import arrowDown from "../../Assets/square_arrow_down.svg";
import pin from "../../Assets/Pin_column.svg";
import unPin from "../../Assets/Unpin.svg";
import cancel from "../../Assets/cancel_circle.svg";
import AccountListModal from "../../common/AccountListModal";
import Note from "../../common/commonRelatedList/Note";
import StageHistoryList from "../../common/commonRelatedList/StageHistoryList";
import Product from "../../common/commonRelatedList/ProductList";
import CloseActivity from "../../common/commonRelatedList/CloseActivity";
import OpenActivity from "../../common/commonRelatedList/OpenActivity";
import "./deal.css";
import moment from "moment";
import ShareModal from "../../common/ShareModal";
import LikeDropdown from "../../Assets/likeDropdownNew.svg";
import DislikeDropdown from "../../Assets/dislikeDropdown.svg";
import { useSelector } from "react-redux";
import SalesCommonTable from "../../common/SalesCommonTable";
import QuotesCommonTable from "../../common/QuotesCommonTable";
import InvoicesCommonTable from "../../common/InvoicesCommonTable";
import AccessModal from "../../common/AccessModal";
import ChangeOwnerModal from "../../common/commonRelatedList/ChangeOwnerModal";
import Loader from "../../common/commonRelatedList/Loader";
import dealNoteData from "../../Assets/Business deal-bro 1.svg";
import { EXPLORER_URL } from "../../constants/DataConst.js";
import ContactIcon from "../../Assets/De-Active/Contact.svg";
import {
  leftArrow,
  rightAngleArrow,
  leftAngleArrow,
  tick,
  expandArrow,
  shrinkArrow,
} from "../../common/commonSVGs/commonSVGIcons.js";
import useScrollToUndo from "../../hooks/useScrollToUndo.js.js";
import Lottie from "react-lottie";
import { defaultOptions } from "../../utils/defaultOptions.js";
import getHeaders from "../../common/ApiHeader.js";
import { handleApiError } from "../../utils/ApiUtils";
import { useNavigate } from "react-router-dom";
import hiddenCal from "../../Assets/calendar-03.svg";

function Deals({
  setCurrentView,
  setInnerPage,
  innerPage,
  permissionList,
  notePermission,
  fetchContactInfo,
  fetchAccInfo,
  currentView,
  pastViewInfo,
  setPastViewInfo,
  setFetchDealInfo,
  fetchDealInfo,
  selectedCampaignName,
  selectedCampaignId,
  fetchProdInfo,
  storeModuleId,
}) {
  const navigate = useNavigate();
  const userList = JSON.parse(sessionStorage.getItem("userList"));

  const stages = {
    Qualification: 10,
    "Needs Analysis": 20,
    "Value Proposition": 40,
    "Identify Decision Makers": 60,
    "Proposal/Price Quote": 75,
    "Negotiation/Review": 90,
    "Closed Won": 100,
    "Closed Lost": 0,
    "Closed Lost to Competition": 0,
    // "Identify Decision Makers": 0,
  };

  const permissionOptions = [
    { value: "Full Access", label: "Full Access" },
    { value: "Read Write", label: "Read Write" },
    { value: "Read Only", label: "Read Only" },
  ];

  const ReasonOptions = [
    { value: "None", label: "None" },
    { value: "Expectation Mismatch", label: "Expectation Mismatch" },
    { value: "Price", label: "Price" },
    { value: "Unqualified Customer", label: "Unqualified Customer" },
    { value: "Lack of response", label: "Lack of response" },
    { value: "Missed Follow Ups", label: "Missed Follow Ups" },
    { value: "Wrong Target", label: "Wrong Target" },
    { value: "Competition", label: "Competition" },
    { value: "Future Interest", label: "Future Interest" },
    { value: "Other", label: "Other" },
  ];

  const [filterView, setFilterView] = useState(false);
  const [stage, setStage] = useState(stageOptions[0].value);
  const [dealDetails, setDealDetails] = useState([]);
  const [filteredLeadDetails, setFilteredLeadDetails] = useState([]);
  const [dealOverviewDetails, setdealOverviewDetails] = useState({});
  const [showOptionsDeal, setShowOptionsDeal] = useState(false);
  const [shareModal, setShareModal] = useState(false);
  const [addedUsers, setAddedUsers] = useState([]);
  const [stageHistoryList, setStageHistoryList] = useState([]);
  const [productsList, setProductsList] = useState([]);
  const [openActivities, setOpenActivities] = useState([]);
  const [closeActivities, setCloseActivities] = useState([]);
  const [mandatoryMsg, setMandatoryMsg] = useState("");
  const [closingDateMsg, setClosingDateMsg] = useState("");
  const [accountIdMsg, setAccountIdMsg] = useState("");
  const [contactIdMsg, setContactIdMsg] = useState("");
  const [stageMsg, setStageMsg] = useState("");
  const [probabilityMsg, setProbabilityMsg] = useState("");
  const [amountMsg, setAmountMsg] = useState("");
  const [updateButtonLabel, setUpdateButtonLabel] = useState("Save");
  const [existingClone, setExistingClone] = useState(false);
  const [isDropdownVisibleLike, setIsDropdownVisibleLike] = useState(false);
  const [isDropdownVisibleDisLike, setIsDropdownVisisbleDislike] =
    useState(false);
  const [closedWonModalOpen, setIsClosedWonModalOpen] = useState(false);
  const [showReason, setShowReason] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [selectedProductId, setSelectedProductId] = useState([]);
  const [showHeadingsQuotes, setShowHeadingsQuotes] = useState(false);
  const [filterQuotesDetails, setFilterQuotesDetails] = useState([]);
  const [selectedQuoteId, setSelectedQuoteId] = useState([]);
  const [showHeadingsSales, setShowHeadingsSales] = useState(false);
  const [filterSalesDetails, setFilterSalesDetails] = useState([]);
  const [selectedSalesId, setSelectedSalesId] = useState([]);
  const [showHeadingsInvoices, setShowHeadingsInvoices] = useState(false);
  const [filterInvoicesDetails, setFilterInvoicesDetails] = useState([]);
  const [selectedInvoiceId, setSelectedInvoiceId] = useState([]);
  const [showAccessModal, setShowAccessModal] = useState(false);
  const [selectedOwnerItem, setSelectedOwnerItem] = useState(userList);
  const [isChangeOwnerList, setIsChangeOwnerList] = useState(false);
  const [isOwnerSelect, setIsOwnerSelect] = useState(false);
  const [emailNotification, setEmailNotification] = useState(true);
  const [isShrinked, setIsShrinked] = useState(false);
  const [searchAccount, setSearchAccount] = useState("");
  const [searchContact, setSearchContact] = useState("");
  const [amtErrorMsg, setAmtErrorMsg] = useState("");
  const [dateErrorMsg, setDateErrorMsg] = useState("");
  const [reasonMsg, setReasonMsg] = useState("");
  const [shareUserList, setShareUserList] = useState([]);
  const [shareRoleList, setShareRoleList] = useState([]);
  const [shareGroupList, setShareGroupList] = useState([]);
  const [sharePublicList, setSharePublicList] = useState([]);
  const [openActivitiesCalls, setOpenActivitiesCalls] = useState([]);
  const [openActivitiesMeetings, setOpenActivitiesMeetings] = useState([]);
  const [closedActivitiesCall, setClosedActivitiesCall] = useState([]);
  const [closedActivitiesMeetings, setClosedActivitiesMeetings] = useState([]);
  const [shareInfoModal, setShareInfoModal] = useState(false);
  const [parentListVisible, setParentListVisible] = useState(false);
  const [allCampaignListVisible, setAllCampaignListVisible] = useState(false);
  const [apiLoader, setApiLoader] = useState(false);
  const [deletedImg, setDeletedImg] = useState("");
  const [noteDeleteConfirmation, setNoteDeleteConfirmation] = useState(false);

  const hasPermission = (action) => {
    const permission = permissionList.deals.find((p) => p.action === action);
    return permission ? permission.status === "TRUE" : false;
  };

  const handleCreateDeal = () => {
    if (hasPermission("CREATE")) {
      setInnerPage("create");
      setUpdateButtonLabel("Save");
      setSelectedCheckboxes([]);
      setSelectedAccountItem({});
      setSelectedContactItem({});
      resetForm();
    } else {
      setShowAccessModal(true);
    }
  };

  const handleCreateDealClone = () => {
    if (hasPermission("CREATE")) {
      setExistingDeal(false);
      setInnerPage("clone");
      setShowOptionsDeal(false);
      setExistingClone(true);
      setDealUpdateInfo({ ...dealOverviewDetails });
      setUpdateButtonLabel("Save");
    } else {
      setShowAccessModal(true);
    }
  };

  useEffect(() => {
    if (storeModuleId) {
      dealOverviewDetail(storeModuleId);
      setDealId(storeModuleId);
    }
  }, []);

  const handleEditDeal = () => {
    if (hasPermission("EDIT")) {
      setExistingDeal(true);
      setInnerPage("create");
      setDealUpdateInfo({ ...dealOverviewDetails });
      setUpdateButtonLabel("Update");
    } else {
      setShowAccessModal(true);
    }
  };

  const handleDeleteDeal = () => {
    if (hasPermission("DELETE")) {
      setDeleteModal(true);
    } else {
      setShowAccessModal(true);
    }
  };
  const invoiceColumns = [
    {
      name: "Subject",
      isChecked: true,
      value: "subject",
    },
    {
      name: "Shipping City",
      isChecked: false,
      value: "shippingCity",
    },
    {
      name: "Shipping Code",
      isChecked: false,
      value: "shippingCode",
    },
    {
      name: "Grand Total",
      isChecked: true,
      value: "grandTotal",
    },
    {
      name: "Account Name",
      isChecked: true,
      value: "accountName",
    },
    {
      name: "Contact Name",
      isChecked: true,
      value: "contactName",
    },
    {
      name: "Invoice Number",
      isChecked: false,
      value: "invoiceNumber",
    },
    {
      name: "Shipping Street",
      isChecked: false,
      value: "shippingStreet",
    },

    // {
    //   name: "Campaign Name",
    //   isChecked: true,
    //   value: "CampaignName",
    // },
    {
      name: "Due Date",
      isChecked: false,
      value: "dueDate",
    },
    {
      name: "Sales Commision",
      isChecked: false,
      value: "salesCommision",
    },
    {
      name: "Billing State",
      isChecked: false,
      value: "billingState",
    },
    {
      name: "Invoice Date",
      isChecked: true,
      value: "invoiceDate",
    },
    {
      name: "Deal Name",
      isChecked: false,
      value: "dealName",
    },
    {
      name: "Billing Code",
      isChecked: false,
      value: "billingCode",
    },
    {
      name: "Invoice Owner",
      isChecked: false,
      value: "invoiceOwner",
    },
    {
      name: "Billing Streete",
      isChecked: false,
      value: "billingStreet",
    },
    {
      name: "Shipping Country",
      isChecked: false,
      value: "shippingCountry",
    },
    {
      name: "Purchase Order",
      isChecked: false,
      value: "purchaseOrder",
    },
    {
      name: "Billing Country",
      isChecked: false,
      value: "billingCountry",
    },
    {
      name: "Shipping State",
      isChecked: false,
      value: "shippingState",
    },
    {
      name: "Excise Duty",
      isChecked: false,
      value: "exciseDuty",
    },
    {
      name: "Billing City",
      isChecked: false,
      value: "billingCity",
    },
    {
      name: "Status",
      isChecked: false,
      value: "status",
    },
  ];
  const [selectedHeadingsInvoices, setSelectedHeadingsInvoices] =
    useState(invoiceColumns);
  const [errorMessage, setErrorMessage] = useState("");
  const [savingHeadingsInvoices, setSavingHeadingsInvoices] =
    useState(invoiceColumns);
  const salesColumns = [
    {
      name: "Sales Order Owner",
      isChecked: true,
      value: "salesOrderOwner",
    },
    {
      name: "Subject",
      isChecked: true,
      value: "subject",
    },
    {
      name: "Status",
      isChecked: true,
      value: "status",
    },
    {
      name: "Grand Total",
      isChecked: true,
      value: "grandTotal",
    },
    {
      name: "Deal Name",
      isChecked: true,
      value: "dealName",
    },
    {
      name: "Purchase Order",
      isChecked: false,
      value: "purchaseOrder",
    },
    {
      name: "Shipping City",
      isChecked: false,
      value: "shippingCity",
    },
    {
      name: "Shipping Code",
      isChecked: false,
      value: "shippingCode",
    },
    {
      name: "Shipping Street",
      isChecked: false,
      value: "shippingStreet",
    },
    {
      name: "Shipping Country",
      isChecked: false,
      value: "shippingCountry",
    },
    {
      name: "Billing Country",
      isChecked: false,
      value: "billingCountry",
    },
    {
      name: "Excise Duty",
      isChecked: false,
      value: "exciseDuty",
    },
    {
      name: "Billing Code",
      isChecked: false,
      value: "billingCode",
    },
    {
      name: "Contact Name",
      isChecked: true,
      value: "contactName",
    },

    {
      name: "Billing Street",
      isChecked: false,
      value: "billingStreet",
    },
    {
      name: "Billing State",
      isChecked: false,
      value: "billingState",
    },
    {
      name: "Account Name",
      isChecked: false,
      value: "accountName",
    },
    {
      name: "Carrier",
      isChecked: false,
      value: "carrier",
    },
    {
      name: "Shipping State",
      isChecked: false,
      value: "shippingState",
    },
    {
      name: "Billing City",
      isChecked: false,
      value: "billingCity",
    },
    {
      name: "Due Date",
      isChecked: false,
      value: "dueDate",
    },
  ];
  const [selectedHeadingsSales, setSelectedHeadingsSales] =
    useState(salesColumns);
  const [savingHeadingsSales, setSavingHeadingsSales] = useState(salesColumns);
  const quotesColumn = [
    {
      name: "Subject",
      isChecked: true,
      value: "subject",
    },
    {
      name: "Quote Stage",
      isChecked: true,
      value: "quoteStage",
    },

    {
      name: "Grand Total",
      isChecked: true,
      value: "grandTotal",
    },
    {
      name: "Contact Name",
      isChecked: true,
      value: "contactName",
    },
    {
      name: "Account Name",
      isChecked: false,
      value: "accountName",
    },
    {
      name: "Quotes Owner",
      isChecked: false,
      value: "quotesOwner",
    },

    {
      name: "Deal Name",
      isChecked: true,
      value: "dealName",
    },
    {
      name: "Valid Until",
      isChecked: false,
      value: "validUntil",
    },

    {
      name: "Billing City",
      isChecked: false,
      value: "billingCity",
    },
    {
      name: "Billing Code",
      isChecked: false,
      value: "billingCode",
    },

    {
      name: "Billing Country",
      isChecked: false,
      value: "billingCountry",
    },

    {
      name: "Billing State",
      isChecked: false,
      value: "billingState",
    },

    {
      name: "Billing Street",
      isChecked: false,
      value: "billingStreet",
    },

    {
      name: "Carrier",
      isChecked: false,
      value: "carrier",
    },

    {
      name: "Shipping City",
      isChecked: false,
      value: "shippingCity",
    },

    {
      name: "Shipping Code",
      isChecked: false,
      value: "shippingCode",
    },

    {
      name: "Shipping Country",
      isChecked: false,
      value: "shippingCountry",
    },

    {
      name: "Shipping State",
      isChecked: false,
      value: "shippingState",
    },
    {
      name: "Team",
      isChecked: false,
      value: "team",
    },
  ];
  const [selectedHeadingsQuotes, setSelectedHeadingsQuotes] =
    useState(quotesColumn);
  const [savingHeadingsQuotes, setSavingHeadingsQuotes] =
    useState(quotesColumn);
  const [isCampaignListModal, setIsCampaignListModal] = useState(false);

  const [selectedCampaignItem, setSelectedCampaignItem] = useState({
    campaignName: selectedCampaignName || "",
    campaignId: selectedCampaignId || "",
  });
  const [totalItemsCampaign, setTotalItemsCampaign] = useState(0);
  const [currentPageCampaign, setCurrentPageCampaign] = useState(1);
  const [perPageCampaign, setPerPageCampaign] = useState(10);

  const totalPagesCampaign = Math.ceil(
    Number(totalItemsCampaign) / perPageCampaign
  );

  useEffect(() => {
    getAllCampaignDetails();
  }, [currentPageCampaign, perPageCampaign]);

  const [allList, setAllList] = useState([]);

  const handlePageDropDownChangeCampaign = (selectedOption) => {
    setCurrentPageCampaign(1);
    setPerPageCampaign(parseInt(selectedOption.value));
  };

  const reduxStore = useSelector((state) => state.reduxState);
  const { loginUserId } = reduxStore;

  const toggleDropdown = (e) => {
    e.stopPropagation();
    setIsDropdownVisisbleDislike(false);
    setIsDropdownVisibleLike(!isDropdownVisibleLike);
  };

  const [editNote, setEditNote] = useState(false);
  const handleChangeNoteTitle = (e) => {
    const { name, value } = e.target;
    if (value.length === 1 && value[0] === " ") {
      return;
    }
    if (editNote) {
      setUpdateNotes((prevLeadInfo) => ({
        ...prevLeadInfo,
        [name]: value,
      }));
    } else {
      setAddTitle(value);
    }
  };

  const handleChildDataChange = (dataFromChild) => {
    let deletedImg = dataFromChild.toString("");
    setDeletedImg(deletedImg);
  };

  const handleChangeNoteDescription = (e) => {
    const { name, value } = e.target;
    if (value.length === 1 && value[0] === " ") {
      return;
    }
    if (editNote) {
      setUpdateNotes((prevLeadInfo) => ({
        ...prevLeadInfo,
        [name]: value,
      }));
    } else {
      setNote(value); // Directly set the input value
    }
  };
  const scrollToSection = (sectionId) => {
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: "smooth" });
    }
  };

  const [updateNotes, setUpdateNotes] = useState({
    description: "",
    title: "",
    noteImageList: [],
  });

  const VerifyDealsInfo = async () => {
    let missingFields = false;

    const amount =
      existingDeal || existingClone ? dealUpdateInfo.amount : dealsInfo.amount;
    if (!amount || amount === 0 || amount === "") {
      setAmtErrorMsg("Amount cannot be empty.");
      setTimeout(() => {
        setAmtErrorMsg("");
      }, 4000);
      missingFields = true;
    }

    const closingDate =
      existingDeal || existingClone
        ? dealUpdateInfo.closingDate
        : dealsInfo.closingDate;
    if (!closingDate || closingDate === "") {
      setDateErrorMsg("Closing Date cannot be empty.");
      setTimeout(() => {
        setDateErrorMsg("");
      }, 4000);
      missingFields = true;
    }

    if (showReason) {
      const reasonForLost =
        existingDeal || existingClone
          ? dealUpdateInfo.reasonForLost
          : dealsInfo.reasonForLost;
      if (
        !reasonForLost ||
        reasonForLost === "" ||
        reasonForLost === undefined
      ) {
        setReasonMsg("Reason cannot be empty.");
        setTimeout(() => {
          setReasonMsg("");
        }, 4000);
        missingFields = true;
      }
    }

    if (missingFields) {
      return;
    } else {
      let requestBody = {};
      if (showReason) {
        requestBody = {
          loggedInUserId: loginUserId,
          dealId: dealId,
          closeWonLost: "close_lost",
          amount: Number(dealUpdateInfo.amount),
          closingDate:
            moment(new Date(dealUpdateInfo.closingDate)).format(
              "DD-MM-YYYY"
            ) === "Invalid date"
              ? dealUpdateInfo.closingDate
              : moment(new Date(dealUpdateInfo.closingDate)).format(
                  "DD-MM-YYYY"
                ),
          stage: "Closed Lost",
          reasonForLost: dealUpdateInfo.reasonForLost.value,
        };
      } else {
        requestBody = {
          loggedInUserId: loginUserId,
          dealId: dealId,
          closeWonLost: "close_won",
          amount: Number(dealUpdateInfo.amount),
          closingDate:
            moment(new Date(dealUpdateInfo.closingDate)).format(
              "DD-MM-YYYY"
            ) === "Invalid date"
              ? dealUpdateInfo.closingDate
              : moment(new Date(dealUpdateInfo.closingDate)).format(
                  "DD-MM-YYYY"
                ),
          stage: "Closed Won",
        };
      }
      try {
        const headersResult = getHeaders(true);

        // Check if headers result indicates an error
        if (headersResult.error) {
          navigate("../"); // Redirect to login if token is missing
          return;
        }
        const response = await axios.post(URI.verifyDeal, requestBody, {
          headers: headersResult,
        });

        if (response.data.status === 200) {
          setShowReason(false);
          setUpdatedFields((prevFields) => ({
            ...prevFields,
            ["stage"]: requestBody.stage,
          }));
          setStage(updatedFields["stage"]?.value);
          handleUpdateClick("stage", requestBody.stage);
          // dealOverviewDetail(dealId, updatedFields["stage"]?.value);
          NotificationManager.success("", response.data.message, 5000);
        } else {
          NotificationManager.error("", response.data.message, 5000);
        }
        setIsClosedWonModalOpen(false);
      } catch (error) {
        handleApiError(error, navigate);
      }
    }
  };

  const options = reduxStore.filterOptions.deal;

  const sliderRef = useRef(null);

  const settings = {
    slidesToShow: 3,
    slidesToScroll: 1,
    infinite: false,
    arrows: false,
    initialSlide: stageOptions.findIndex((item) => stage === item.value),
    responsive: [
      {
        breakpoint: 1920,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 1280,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const goToPrev = () => {
    sliderRef.current.slickPrev();
  };

  const goToNext = () => {
    sliderRef.current.slickNext();
  };

  const handleStageClick = (selectedStage) => {
    if (existingDeal || existingClone) {
      setDealUpdateInfo((prevLeadInfo) => ({
        ...prevLeadInfo,
        stage: {
          value: selectedStage,
          label: selectedStage,
        },
      }));
      setUpdatedFields((prevFields) => ({
        ...prevFields,
        stage: {
          value: selectedStage,
          label: selectedStage,
        },
      }));
    } else {
      setDealsInfo((prevDealsInfo) => ({
        ...prevDealsInfo,
        stage: {
          value: selectedStage,
          label: selectedStage,
        },
      }));
      setUpdatedFields((prevFields) => ({
        ...prevFields,
        stage: {
          value: selectedStage,
          label: selectedStage,
        },
      }));
      setdealOverviewDetails((prevDealsInfo) => ({
        ...prevDealsInfo,
        stage: {
          value: selectedStage,
          label: selectedStage,
        },
      }));
    }
    setStage(selectedStage);
    // setDealUpdateInfo({ ...dealOverviewDetails });
    // dealOverviewDetail(dealId, selectedStage);

    if (["Closed Won"].includes(selectedStage)) {
      setIsClosedWonModalOpen(true);
      setIsDropdownVisibleLike(false);
      setExistingClone(true);
    } else if (
      ["Closed Lost", "Closed Lost to Competition"].includes(selectedStage)
    ) {
      setExistingClone(true);
      setIsClosedWonModalOpen(true);
      setIsDropdownVisisbleDislike(false);
      setShowReason(true);
    } else {
      handleUpdateStatusClick(selectedStage);
    }
  };

  const deleteDealsDetails = async () => {
    const requestBody = {
      loggedInUserId: loginUserId,
      dealId: dealId,
    };

    const headersResult = getHeaders(true);

    // Check if headers result indicates an error
    if (headersResult.error) {
      navigate("../"); // Redirect to login if token is missing
      return;
    }

    await axios
      .post(URI.deleteDealDetails, requestBody, {
        headers: headersResult,
      })
      .then((response) => {
        if (response.data.status === 200) {
          // setDeleteMsg(response.data.message);
          setInnerPage("list");
          getAllDealsDetails(currentPage, selectedOption);
          setDeleteModal(false);
          setShowOptionsDeal(false);
          setTimeout(() => {
            // setDeleteMsg("");
          }, 3000);
        }
      })
      .catch(async (error) => {
        handleApiError(error, navigate);
      });
  };

  const [selectedOption, setSelectedOption] = useState(options[0]);
  const dealsColumn = [
    {
      name: "Deal Name",
      isChecked: true,
      value: "dealName",
    },
    {
      name: "Amount",
      isChecked: true,
      value: "amount",
    },
    {
      name: "Stage",
      isChecked: true,
      value: "stage",
    },
    {
      name: "Closing Date",
      isChecked: true,
      value: "closingDate",
    },
    {
      name: "Account Name",
      isChecked: true,
      value: "accountName",
    },
    {
      name: "Contact Name",
      isChecked: true,
      value: "contactName",
    },
    {
      name: "Campaign Source",
      isChecked: false,
      value: "campaignName",
    },
    {
      name: "Probability",
      isChecked: false,
      value: "probability",
    },
    {
      name: "Type",
      isChecked: false,
      value: "type",
    },
    {
      name: "Next Step",
      isChecked: false,
      value: "nextStep",
    },
    {
      name: "Lead Source",
      isChecked: false,
      value: "leadSource",
    },
    {
      name: "Expected Revenue",
      isChecked: false,
      value: "expectedRevenue",
    },
    {
      name: "Description",
      isChecked: false,
      value: "description",
    },

    {
      name: "Modified By",
      isChecked: false,
      value: "modifiedBy",
    },
    {
      name: "Deal Owner",
      isChecked: true,
      value: "dealOwner",
    },
    {
      name: "Hash",
      isChecked: true,
      value: "transHash",
    },
  ];
  const fieldDropdowns = [
    {
      name: "Deal Owner",
      isShowed: true,
      type: "select",
      value: "",
    },
    {
      name: "Account Name",
      isShowed: true,
      type: "readOnly",
    },
    {
      name: "Stage",
      isShowed: true,
      type: "dropdown",
      // value: "value",
    },
    {
      name: "Type",
      isShowed: false,
      type: "dropdown",
    },
    {
      name: "Next Step",
      isShowed: true,
      type: "input",
    },

    {
      name: "Lead Source",
      isShowed: false,
      type: "dropdown",
    },

    {
      name: "Created By",
      isShowed: false,
      type: "readOnly",
    },

    {
      name: "Deal Name",
      isShowed: false,
      type: "input",
    },
    {
      name: "Amount",
      isShowed: false,
      type: "input",
    },
    {
      name: "Modified By",
      isShowed: false,
      type: "readOnly",
    },
    {
      name: "Probability",
      isShowed: false,
      type: "readOnly",
    },
    {
      name: "Expected Revenue",
      isShowed: false,
      type: "readOnly",
    },
    {
      name: "Closing Date",
      isShowed: false,
      type: "input",
    },
  ];
  const [fieldLabels, setFieldLabels] = useState(fieldDropdowns);
  const [showSearchFields, setShowSearchFields] = useState(fieldDropdowns);
  const [searchField, setSearchField] = useState("");
  const [selectedField, setSelectedField] = useState("");
  const [selectedHeadings, setSelectedHeadings] = useState(dealsColumn);
  const [savingHeadings, setSavingHeadings] = useState(dealsColumn);
  const [showHeadings, setShowHeadings] = useState(false);
  const [showModalType, setShowModalType] = useState("");
  const [searchColumn, setSearchColumn] = useState("");
  const [showSelectedFilter, setShowSelectedFilter] = useState("");
  const [filterType, setFilterType] = useState("");
  const [pinnedColumn, setPinnedColumn] = useState("Deal Name");
  const [accountDetails, setAccountDetails] = useState([]);
  const [selectedAccountItem, setSelectedAccountItem] = useState(
    fetchAccInfo ? fetchAccInfo : {}
  );
  const [accountListVisible, setAccountListVisible] = useState(false);
  const [isAccountListModal, setIsAccountListModal] = useState(false);
  const [selectedCheckboxes, setSelectedCheckboxes] = useState([]);
  const [selectedObj, setSelectedObj] = useState({
    name: "",
    isChecked: false,
    value: "",
  });
  const [massUpdateValue, setMassUpdateValue] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [moduleWiseData, setModuleWiseData] = useState([]);
  const [modalAccountDetails, setModalAccountDetail] = useState([]);
  const [modalContactDetails, setModalContactDetails] = useState([]);
  const [selectedModuleItem, setSelectedModuleItem] = useState(moduleWiseData);
  const [modalCampaignsDetails, setModalCampaignDetails] = useState([]);
  const [searchCampaign, setSearchCampaign] = useState("");

  const filteredCampigns = modalCampaignsDetails.filter((item) =>
    item.campaignName?.toLowerCase().includes(searchCampaign?.toLowerCase())
  );

  const handleSearchCampaign = (e) => {
    const { value } = e.target;
    if (value.trim() !== "" || value === "") {
      setSearchCampaign(value);
    }
  };

  const filteredAccountDetails = modalAccountDetails.filter((item) =>
    item.accountName?.toLowerCase().includes(searchAccount?.toLowerCase())
  );

  const handleSearchChange = (e) => {
    const { value } = e.target;
    if (value.trim() !== "" || value === "") {
      setSearchAccount(value);
    }
  };

  const handleSearchChangeContact = (e) => {
    const { value } = e.target;
    if (value.trim() !== "" || value === "") {
      setSearchContact(value);
    }
  };

  const handleSelectCheckboxChange = (event) => {
    const { value } = event.target;

    const selectedCheckbox = { moduleId: value };

    setSelectedCheckboxes((prevSelectedCheckboxes) => {
      const isAlreadySelected = prevSelectedCheckboxes.some(
        (checkbox) => checkbox.moduleId === value
      );

      if (isAlreadySelected) {
        return prevSelectedCheckboxes.filter(
          (checkbox) => checkbox.moduleId !== value
        );
      } else {
        return [...prevSelectedCheckboxes, selectedCheckbox];
      }
    });
  };

  const getAllAssignModules = async (main, sub, id) => {
    if (main === "ACCOUNTS") {
      var contactDetails = [];
    } else {
      var accountDetails = [];
    }

    const requestBody = {
      loginUserId: loginUserId,
      mainModule: main,
      subModule: sub,
      moduleId: id,
    };
    const headersResult = getHeaders(true);

    // Check if headers result indicates an error
    if (headersResult.error) {
      navigate("../"); // Redirect to login if token is missing
      return;
    }
    await axios
      .post(URI.moduleWiseAssign, requestBody, {
        headers: headersResult,
      })
      .then((response) => {
        if (response.data.status === 200) {
          if (main === "ACCOUNTS") {
            for (const element of response.data.data.assignModulewiseDetails) {
              const modifiedResponse = {
                contactName: "",
                contactId: "",
              };

              modifiedResponse["contactName"] = element.moduleName;
              modifiedResponse["contactId"] = element.moduleId;
              contactDetails.push(modifiedResponse);
            }
            setContactDetails(contactDetails);
            setIsAccountListModal(false);
          } else {
            for (const element of response.data.data.assignModulewiseDetails) {
              const modifiedResponse = {
                accountName: "",
                accountId: "",
              };

              modifiedResponse["accountName"] = element.moduleName;
              modifiedResponse["accountId"] = element.moduleId;
              accountDetails.push(modifiedResponse);
            }
            setAccountDetails(accountDetails);
            setIsContactListModal(false);
          }
          // setModuleWiseData(response.data.data.assignModulewiseDetails);
        }
        setIsAccountListModal(false);
      })
      .catch(async (error) => {
        handleApiError(error, navigate);
      });
  };

  useEffect(() => {
    if (pastViewInfo) {
      if (pastViewInfo.view === "contacts") {
        const main = pastViewInfo.view.toUpperCase();
        const sub = ["ACCOUNTS"];
        const id = fetchContactInfo?.contactId;
        getAllAssignModules(main, sub, id);
        return;
      } else if (pastViewInfo.view === "accounts") {
        const main = pastViewInfo.view.toUpperCase();
        const sub = ["CONTACTS"];
        const id = fetchAccInfo?.accountId;
        getAllAssignModules(main, sub, id);
      }
    }
  }, []);
  const deleteUpdateTransferMass = async () => {
    const { value } = selectedObj;

    console.log("value:", value);
    console.log("selectedCampaignItem:", selectedCampaignItem);
    console.log("massUpdateValue:", massUpdateValue);

    const requiredFields = [
      "dealName",
      "closingDate",
      "stage",
      "contactName",
      "amount",
    ];

    let url = URI.massDelete;
    const requestBody = {
      loggedInUserId: loginUserId,
      moduleName: "DEALS",
      massAction: showModalType === "Mass Delete" ? "DELETE" : "EDIT",
      moduleIdList: selectedCheckboxes,
    };

    if (showModalType === "Mass Update") {
      if (value === "closingDate") {
        if (massUpdateValue.length === 0) {
          setErrorMessage("Value cannot be empty");
          setTimeout(() => setErrorMessage(""), 3000);
          return;
        }
        requestBody[value] = convertToDisplayFormat(massUpdateValue);
      } else if (value === "campaignName") {
        if (!selectedCampaignItem?.campaignId) {
          setErrorMessage("Campaign Name cannot be empty");
          setTimeout(() => setErrorMessage(""), 3000);
          return;
        }
        // Assign campaignId to requestBody for campaignName
        requestBody.campaignSource = selectedCampaignItem.campaignId;
      } else {
        if (requiredFields.includes(value) && massUpdateValue.length === 0) {
          setErrorMessage("Value cannot be empty");
          setTimeout(() => setErrorMessage(""), 3000);
          return;
        }
        requestBody[value] = massUpdateValue;
      }
      url = URI.massUpdate;
    } else if (showModalType === "Mass Transfer") {
      requestBody.ownerId = selectedItem.userId;
      url = URI.massTransfer;
    }

    console.log("requestBody before API call:", requestBody);

    const headersResult = getHeaders(true);

    // Check if headers result indicates an error
    if (headersResult.error) {
      navigate("../"); // Redirect to login if token is missing
      return;
    }

    await axios
      .post(url, requestBody, {
        headers: headersResult,
      })
      .then((response) => {
        if (response.data.status === 200) {
          NotificationManager.success("", response.data.message, 5000);
          getAllDealsDetails(currentPage, selectedOption);
          setSelectedCheckboxes([]);
          setInnerPage("list");
          setShowModalType("");
          setShowSearchFields(fieldDropdowns);
          setMassUpdateValue("");
        } else {
          NotificationManager.error("", response.data.message, 5000);
        }
      })
      .catch(function (error) {
        handleApiError(error, navigate);
      });
  };

  const handleAccountClick = (e) => {
    e.stopPropagation();
    setAccountListVisible(!accountListVisible);
    setContactListVisible(false);
    setListVisible(false);
  };

  const handleAccountItemClick = (item) => {
    setSelectedAccountItem(item);
    setAccountListVisible(false);
    getAllAssignModules("ACCOUNTS", ["CONTACTS"], item.accountId);
  };

  const getAllAccountDetails = async () => {
    const requestBody = {
      loggedInUserId: loginUserId,
      pageNo: currentPageAccount,
      pageSize: perPageAccount,
    };

    const headersResult = getHeaders(true);

    // Check if headers result indicates an error
    if (headersResult.error) {
      navigate("../"); // Redirect to login if token is missing
      return;
    }
    await axios
      .post(URI.getAllAccountDetails, requestBody, {
        headers: headersResult,
      })
      .then((response) => {
        if (response.data.status === 200) {
          if (
            !pastViewInfo ||
            pastViewInfo?.view === "" ||
            pastViewInfo?.view === "campaigns"
          ) {
            setAccountDetails(response.data.data.allAccountDetails);
          }
          setModalAccountDetail(response.data.data.allAccountDetails);
          setTotalItemsAccount(response.data.data.rowCount);
        }
      })
      .catch(async (error) => {
        handleApiError(error, navigate);
      });
  };

  useEffect(() => {
    // getAllAccountDetails();
    getAllContact();
    setInnerPage(innerPage || "list");
  }, []);

  const [contactDetails, setContactDetails] = useState([]);
  const [selectedContactItem, setSelectedContactItem] = useState(
    fetchContactInfo ? fetchContactInfo : ""
  );

  const filteredContactDetails = modalContactDetails.filter((item) =>
    item.contactName?.toLowerCase().includes(searchContact?.toLowerCase())
  );

  const convertDateFormat = (dateString) => {
    if (!dateString) return "";
    const [day, month, year] = dateString.split("-");
    return `${year}-${month}-${day}`;
  };

  useEffect(() => {
    if (moduleWiseData.length > 0) {
      setSelectedModuleItem(moduleWiseData[0]);
    }
  }, [moduleWiseData]);

  const [contactListVisible, setContactListVisible] = useState(false);
  const [isContactListModal, setIsContactListModal] = useState(false);

  const handleContactClick = (e) => {
    e.stopPropagation();
    setContactListVisible(!contactListVisible);
    setAccountListVisible(false);
    setListVisible(false);
  };

  // const handleCampaignClick = (e, obj) => {
  //   e.stopPropagation();
  //   setCampaignListVisible(!campaignListVisible);
  //   setAccountListVisible(false);
  //   setListVisible(false);
  // };

  const handleContactItemClick = (item) => {
    setSelectedContactItem(item);
    setContactListVisible(false);
    getAllAssignModules("CONTACTS", ["ACCOUNTS"], item.contactId);
  };

  const getAllContact = async () => {
    const requestBody = {
      loggedInUserId: loginUserId,
      pageNo: currentPageContact,
      pageSize: perPageContact,
    };

    const headersResult = getHeaders(true);

    // Check if headers result indicates an error
    if (headersResult.error) {
      navigate("../"); // Redirect to login if token is missing
      return;
    }

    await axios
      .post(URI.getAllContact, requestBody, {
        headers: headersResult,
      })
      .then((response) => {
        if (response.data.status === 200) {
          if (
            !pastViewInfo ||
            pastViewInfo?.view === "" ||
            pastViewInfo?.view === "campaigns" ||
            pastViewInfo?.view === "contacts"
          ) {
            setContactDetails(response.data.data.allContactDetails);
          }
          setModalContactDetails(response.data.data.allContactDetails);
          setTotalItemsContact(response.data.data.rowCount);
        } else {
          throw new Error(response.data.message);
        }
      })
      .catch((err) => {
        handleApiError(err, navigate);
      });
  };

  const [clickedDropdownObj, setClickedDropdownObj] = useState({
    label: "",
    value: "",
  });

  const typeOptions = [
    { value: "None", label: "None" },
    { value: "Existing Business", label: "Existing Business" },
    { value: "New Business", label: "New Business" },
  ];

  const [dealsInfo, setDealsInfo] = useState({
    dealName: "",
    accountName: "",
    type: typeOptions[0],
    nextStep: "",
    leadSource: leadSourceOptions[0],
    contactName: "",
    amount: "",
    closingDate: "",
    stage: stageOptions[0],
    probability: stages[stageOptions[0].value],
    reasonForLost: ReasonOptions[0],
    expectedRevenue: "",
    description: "",
    campaignSource: "",
  });

  const [dealUpdateInfo, setDealUpdateInfo] = useState({
    dealName: "",
    accountName: "",
    type: typeOptions[0],
    nextStep: "",
    leadSource: leadSourceOptions[0],
    contactName: "",
    amount: "",
    closingDate: "",
    stage: stageOptions[0],
    probability: stages[stageOptions[0].value],
    reasonForLost: ReasonOptions[0],
    expectedRevenue: "",
    description: "",
    campaignSource: "",
  });
  console.log(
    dealsInfo.closingDate,
    dealUpdateInfo.closingDate,
    convertDateFormat(new Date().toISOString().split("T")[0])
  );
  const [activeTab, setActiveTab] = useState("note");
  const [existingDeal, setExistingDeal] = useState(false);
  const [dealId, setDealId] = useState("");
  const [selectedLeadIndex, setSelectedLeadIndex] = useState(0);
  const [showType, setShowType] = useState("");
  const [leadPageView, setLeadPageView] = useState("overview");
  const [timeLineDataList, setTimeLineDataList] = useState([]);

  const deleteNoteDetails = async (accountId, noteId) => {
    const formData = new FormData();
    formData.append("moduleId", accountId);
    formData.append("loggedInUserId", loginUserId);
    formData.append("moduleName", "DEALS");
    formData.append("noteId", noteId);
    const headersResult = getHeaders(true);

    // Check if headers result indicates an error
    if (headersResult.error) {
      navigate("../"); // Redirect to login if token is missing
      return;
    }
    await axios
      .post(URI.deleteNote, formData, {
        headers: headersResult,
      })
      .then((response) => {
        if (response.data.status === 200) {
          NotificationManager.success("", response.data.message, 3000);
          getNotesDetails(accountId, selectRecent);
          setNoteDeleteConfirmation(false);
        } else {
          NotificationManager.error("", response.data.message, 5000);
        }
      })
      .catch((error) => {
        handleApiError(error, navigate);
      });
  };

  const updateNote = async (noteId) => {
    const formData = new FormData();
    formData.append("moduleId", dealId);
    formData.append("loggedInUserId", loginUserId);
    formData.append("moduleName", "DEALS");
    formData.append("noteDescription", updateNotes.description);
    formData.append("noteTitle", updateNotes.title);
    formData.append("noteId", noteId);
    formData.append("deletedImageNames", deletedImg.toString());

    selectedNoteFiles.forEach((file) => {
      formData.append("noteImages", file);
    });
    const headersResult = getHeaders(true);

    // Check if headers result indicates an error
    if (headersResult.error) {
      navigate("../"); // Redirect to login if token is missing
      return;
    }
    await axios
      .post(URI.updateNote, formData, {
        headers: headersResult,
      })
      .then((response) => {
        if (response.data.status === 200) {
          setShowButtons(false);
          setEditNote(false);
          NotificationManager.success("", response.data.message, 5000);
          setUpdateNotes({
            description: "",
            title: "",
            noteImageList: [],
          });
          setSelectedNoteFiles([]);
          getNotesDetails(dealId, selectRecent);
          setDeletedImg("");
        } else {
          setDeletedImg("");
          setSelectedNoteFiles([]);
          NotificationManager.error("", response.data.message, 5000);
        }
      })
      .catch((error) => {
        handleApiError(error, navigate);
      });
  };

  const handleDealsChange = (e) => {
    const { name, value } = e.target;

    let newValue = value;
    if (name === "closingDate") {
      newValue = convertToDisplayFormat(value);
    }

    if (existingDeal || existingClone) {
      setDealUpdateInfo((prevLeadInfo) => ({
        ...prevLeadInfo,
        [name]: newValue,
      }));
    } else {
      setDealsInfo((prevDealsInfo) => ({
        ...prevDealsInfo,
        [name]: newValue,
      }));
    }
    if (name === "amount") {
      const stagePercentageValue = dealsInfo.probability / 100;
      const amount = parseFloat(value.replace(/[^0-9]/g, "")) || 0;
      if (existingDeal || existingClone) {
        setDealUpdateInfo((prevLeadInfo) => ({
          ...prevLeadInfo,
          expectedRevenue:
            stagePercentageValue !== 0
              ? parseFloat(amount * stagePercentageValue).toFixed(2)
              : amount,
        }));
      } else {
        setDealsInfo((prevDealsInfo) => ({
          ...prevDealsInfo,
          expectedRevenue:
            stagePercentageValue !== 0
              ? parseFloat(amount * stagePercentageValue).toFixed(2)
              : amount,
        }));
      }
    }
  };

  const resetForm = () => {
    const formData = {
      dealName: "",
      accountName: "",
      type: typeOptions[0],
      nextStep: "",
      leadSource: leadSourceOptions[0],
      contactName: "",
      amount: "",
      closingDate: "",
      stage: stageOptions[0],
      probability: stages[stageOptions[0].value],
      reasonForLost: ReasonOptions[0],
      expectedRevenue: "",
      description: "",
      campaignSource: "",
    };

    setDealsInfo(formData);
    setDealUpdateInfo(formData);
  };

  const [listVisible, setListVisible] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [currentPageContact, setCurrentPageContact] = useState(1);
  const [currentPageAccount, setCurrentPageAccount] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [perPageContact, setPerPageContact] = useState(10);
  const [perPageAccount, setPerPageAccount] = useState(10);
  const [totalItems, setTotalItems] = useState(0); // Total number of items
  const [totalItemsContact, setTotalItemsContact] = useState(0);
  const [totalItemsAccount, setTotalItemsAccount] = useState(0);
  const [editingFields, setEditingFields] = useState({
    dealOwnerId: false,
    dealName: false,
    accountId: false,
    type: false,
    nextStep: false,
    leadSource: false,
    contactId: false,
    amount: false,
    closingDate: false,
    stage: false,
    probability: false,
    reasonForLost: false,
    expectedRevenue: false,
    description: false,
    campaignSource: false,
    loggedInUserId: false,
  });

  // for save and new && save lottie loader
  const [saveAndNew, setSaveAndNew] = useState(false);
  const [saveSubmit, setSaveSubmit] = useState(false);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const hadlePageChangeContact = (page) => {
    setCurrentPageContact(page);
  };

  const handlepageChangeAccount = (page) => {
    setCurrentPageAccount(page);
  };

  const handlePageChangeCampaign = (page) => {
    setCurrentPageCampaign(page);
  };

  const handlePageDropDowmChange = (selectedOption) => {
    setCurrentPage(1);
    setPerPage(parseInt(selectedOption.value));
  };

  const handlePageDropDownChangeContact = (selectedOption) => {
    setCurrentPageContact(1);
    setPerPageContact(parseInt(selectedOption.value));
  };

  const handlePageDropDownChangeAccount = (selectedOption) => {
    setCurrentPageAccount(1);
    setPerPageAccount(parseInt(selectedOption.value));
  };

  const totalPages = Math.ceil(Number(totalItems) / perPage);
  const totalPagesContact = Math.ceil(
    Number(totalItemsContact) / perPageContact
  );
  const totalPagesAccount = Math.ceil(
    Number(totalItemsAccount) / perPageAccount
  );

  const handlePinColumn = (name) => {
    setPinnedColumn(name);
    const updatedColumns = [...selectedHeadings];
    const clickedColumnIndex = updatedColumns.findIndex(
      (column) => column.name === name
    );

    if (clickedColumnIndex !== -1) {
      const clickedColumn = updatedColumns.splice(clickedColumnIndex, 1)[0];
      updatedColumns.unshift(clickedColumn);
      setSelectedHeadings(updatedColumns);
    }
  };

  const sortedColumnsData = (type, headingName) => {
    const sortedData = [...dealDetails];

    if (type === "asc") {
      sortedData.sort((a, b) => {
        if (
          typeof a[headingName] === "string" &&
          typeof b[headingName] === "string"
        ) {
          return a[headingName].localeCompare(b[headingName]);
        }
        return a[headingName] - b[headingName];
      });
    } else if (type === "desc") {
      sortedData.sort((a, b) => {
        if (
          typeof a[headingName] === "string" &&
          typeof b[headingName] === "string"
        ) {
          return b[headingName].localeCompare(a[headingName]);
        }
        return b[headingName] - a[headingName];
      });
    }
    setFilteredLeadDetails(sortedData);
  };

  const handleSubmitDeal = async (e) => {
    let isValidForm = true;

    const setErrorMessage = (setter, message, timeout = 5000) => {
      setter(message);
      setTimeout(() => {
        setter("");
      }, timeout);
      isValidForm = false;
    };

    const dealsData =
      existingDeal || existingClone ? dealUpdateInfo : dealsInfo;

    if (dealsData.dealName === "") {
      setErrorMessage(setMandatoryMsg, "Deal Name cannot be empty.", 5000);
    }
    if (dealsData.dealName && !validateFirstName(dealsData.dealName)) {
      setErrorMessage(setMandatoryMsg, "Please enter a valid Deal name.", 5000);
    }
    if (dealsData.closingDate === "") {
      setErrorMessage(setClosingDateMsg, "Closing date cannot be empty.", 5000);
    }
    if (dealsData.amount === "") {
      setErrorMessage(setAmountMsg, "Amount cannot be empty.", 5000);
    }
    if (dealsData.amount && !validateAmount(dealsData.amount)) {
      setErrorMessage(setAmountMsg, "Please enter a valid Amount.", 5000);
    }
    if (!selectedContactItem.contactId) {
      setErrorMessage(setContactIdMsg, "Contact cannot be empty.", 5000);
    }
    if (!selectedAccountItem.accountId) {
      setErrorMessage(setAccountIdMsg, "Account cannot be empty.", 5000);
    }

    if (!isValidForm) return;
    let requestBody = {};
    e === "SAVE AND NEW" ? setSaveAndNew(true) : setSaveSubmit(true);
    if (existingClone) {
      requestBody = {
        dealOwnerId: selectedItem?.userId,
        dealId: dealId,
        dealName: removeExtraSpaces(dealUpdateInfo.dealName),
        accountId: selectedAccountItem.accountId,
        type: dealUpdateInfo.type.value?.replace(/^-|-$/g, ""),
        nextStep: removeExtraSpaces(dealUpdateInfo.nextStep),
        leadSource: dealUpdateInfo.leadSource.value?.replace(/^-|-$/g, ""),
        contactId: selectedContactItem.contactId,
        amount: Number(dealUpdateInfo.amount),
        closingDate: dealUpdateInfo.closingDate,
        stage: dealUpdateInfo.stage.value,
        probability: Number(dealUpdateInfo.probability),
        expectedRevenue: Number(dealUpdateInfo.expectedRevenue),
        description: removeExtraSpaces(dealUpdateInfo.description),
        campaignSource: selectedCampaignItem?.campaignId,
        loggedInUserId: loginUserId,
      };
      if (
        ["Closed Lost", "Closed Lost to Competition"].includes(
          dealUpdateInfo.stage.value
        )
      ) {
        requestBody.reasonForLost = dealUpdateInfo.reasonForLost.value?.replace(
          /^-|-$/g,
          ""
        );
      }
    } else {
      requestBody = {
        dealOwnerId: selectedItem?.userId,
        campaignSource: selectedCampaignItem?.campaignId,
        dealName: removeExtraSpaces(dealsInfo.dealName),
        accountId: selectedAccountItem.accountId,
        type: dealsInfo.type.value?.replace(/^-|-$/g, ""),
        nextStep: removeExtraSpaces(dealsInfo.nextStep),
        leadSource: dealsInfo.leadSource.value?.replace(/^-|-$/g, ""),
        contactId: selectedContactItem.contactId,
        amount: Number(dealsInfo.amount),
        closingDate: dealsInfo.closingDate,
        stage: dealsInfo.stage.value,
        probability: Number(dealsInfo.probability),
        expectedRevenue:
          dealsInfo.stage.value === "Closed Lost" ||
          dealsInfo.stage.value === "Closed Lost to Competition"
            ? 0
            : Number(dealsInfo.expectedRevenue),

        description: removeExtraSpaces(dealsInfo.description),
        // campaignSource: dealsInfo.campaignSource,
        loggedInUserId: loginUserId,
        productId:
          pastViewInfo?.view !== "" && fetchProdInfo
            ? fetchProdInfo?.productId
            : null, // it will pass the productId when we are routing from other page
      };
      if (
        ["Closed Lost", "Closed Lost to Competition"].includes(
          dealsInfo.stage.value
        )
      ) {
        requestBody.reasonForLost = dealUpdateInfo.reasonForLost.value?.replace(
          /^-|-$/g,
          ""
        );
      }
    }

    const headersResult = getHeaders(true);

    // Check if headers result indicates an error
    if (headersResult.error) {
      navigate("../"); // Redirect to login if token is missing
      return;
    }
    await axios
      .post(URI.createDeal, requestBody, {
        headers: headersResult,
      })
      .then((response) => {
        if (response.data.status === 200) {
          NotificationManager.success("", response.data.message, 5000);
          getAllDealsDetails(1, selectedOption);
          resetForm();
          if (
            pastViewInfo &&
            pastViewInfo?.view !== "" &&
            pastViewInfo?.innerPage !== ""
          ) {
            setCurrentView(pastViewInfo.view);
            setInnerPage(pastViewInfo.innerPage);
            setPastViewInfo((prev) => ({
              ...prev,
              view: "",
              innerPage: "",
              undo: currentView,
            }));
            console.log(pastViewInfo, "setPastViewInfo check>>>>>>>>>>>>>");

            return;
          }
          if (e === "SAVE" || e === "ADD") {
            setInnerPage("list");
            resetForm();
          }
        } else {
          NotificationManager.error("", response.data.message, 5000);
        }
      })
      .catch((error) => {
        handleApiError(error, navigate);
      })
      .finally(() => {
        setSaveAndNew(false);
        setSaveSubmit(false);
      });
  };

  const handleUpdateDeal = async (e) => {
    let isValidForm = true;

    const setErrorMessage = (setter, message, timeout = 5000) => {
      setter(message);
      setTimeout(() => {
        setter("");
      }, timeout);
      isValidForm = false;
    };

    const dealsData =
      existingDeal || existingClone ? dealUpdateInfo : dealsInfo;

    if (dealsData.dealName === "") {
      setErrorMessage(setMandatoryMsg, "Deal Name cannot be empty.", 5000);
    }
    if (dealsData.dealName && !validateFirstName(dealsData.dealName)) {
      setErrorMessage(setMandatoryMsg, "Please enter a valid Deal name.", 5000);
    }
    if (dealsData.closingDate === "") {
      setErrorMessage(setClosingDateMsg, "Closing date cannot be empty.", 5000);
    }
    if (dealsData.amount === "") {
      setErrorMessage(setAmountMsg, "Amount cannot be empty.", 5000);
    }
    if (dealsData.amount && !validateAmount(dealsData.amount)) {
      setErrorMessage(setAmountMsg, "Please enter a valid Amount.", 5000);
    }
    if (!selectedContactItem.contactId) {
      setErrorMessage(setContactIdMsg, "Contact cannot be empty.", 5000);
    }
    if (!selectedAccountItem.accountId) {
      setErrorMessage(setAccountIdMsg, "Account cannot be empty.", 5000);
    }

    if (!isValidForm) return;

    setSaveSubmit(true);
    const requestBody = {
      dealOwnerId: selectedItem?.userId,
      dealId: dealId,
      campaignSource: selectedCampaignItem?.campaignId,
      dealName: removeExtraSpaces(dealUpdateInfo.dealName),
      accountId: selectedAccountItem.accountId,
      type: dealUpdateInfo.type.value?.replace(/^-|-$/g, ""),
      nextStep: removeExtraSpaces(dealUpdateInfo.nextStep),
      leadSource: dealUpdateInfo.leadSource.value?.replace(/^-|-$/g, ""),
      contactId: selectedContactItem.contactId,
      amount: Number(dealUpdateInfo.amount),
      closingDate: dealUpdateInfo.closingDate,
      stage: dealUpdateInfo.stage.value,
      probability: String(dealUpdateInfo.probability),
      expectedRevenue: String(dealUpdateInfo.expectedRevenue),
      description: removeExtraSpaces(dealUpdateInfo.description),
      // campaignSource: dealUpdateInfo.campaignSource,
      loggedInUserId: loginUserId,
    };
    if (
      ["Closed Lost", "Closed Lost to Competition"].includes(
        dealUpdateInfo.stage.value
      )
    ) {
      requestBody.reasonForLost = dealUpdateInfo.reasonForLost.value?.replace(
        /^-|-$/g,
        ""
      );
    }

    const headersResult = getHeaders(true);

    // Check if headers result indicates an error
    if (headersResult.error) {
      navigate("../"); // Redirect to login if token is missing
      return;
    }

    await axios
      .post(URI.updateDealDetails, requestBody, {
        headers: headersResult,
      })

      .then((response) => {
        if (response.data.status === 200) {
          NotificationManager.success("", response.data.message, 5000);
          setExistingDeal(false);
          getAllDealsDetails(1, selectedOption);

          if (e === "Save") {
            setInnerPage("list");
          }
          resetForm();
        } else {
          NotificationManager.error("", response.data.message, 5000);
        }
      })
      .catch(function (error) {
        handleApiError(error, navigate);
      })
      .finally(() => {
        setSaveSubmit(false);
      });
  };

  const [isLeadOwnerModal, setIsLeadOwnerModal] = useState(false);

  const [updatedFields, setUpdatedFields] = useState({
    // dealOverviewDetails: dealOverviewDetails.emailId,
    phoneNumber: dealOverviewDetails.phoneNumber,
    mobile: dealOverviewDetails.mobile,
    leadStatus: dealOverviewDetails.leadStatus,
    emailOptOut: dealOverviewDetails.emailOptOut,
    skypeId: dealOverviewDetails.skypeId,
    leadOwner: dealOverviewDetails.leadOwner,
    dealOwnerInfo: dealOverviewDetails.dealOwner,
    dealsInfoEmail: dealOverviewDetails.dealsInfoEmail,
    dealInfoNext: dealOverviewDetails.nextStep,
    dealInfoAmt: dealOverviewDetails.amount,
    dealInfoClosingDate: dealOverviewDetails.closingDate,
    dealInfoDealName: dealOverviewDetails.dealName,
    leadPhone: dealOverviewDetails.leadPhone,
    leadStatusInfo: dealOverviewDetails.leadStatusInfo,
    leadMobile: dealOverviewDetails.leadMobile,
    leadSource: dealOverviewDetails.leadSource,
    firstName: dealOverviewDetails.firstName,
    lastName: dealOverviewDetails.lastName,
    title: dealOverviewDetails.title,
    contactName: dealOverviewDetails.contactName,
    annualRevenue: dealOverviewDetails.annualRevenue,
    numberEmployee: dealOverviewDetails.noOfEmployees,
    rating: dealOverviewDetails.rating,
    industryType: dealOverviewDetails.industryType,
    country: dealOverviewDetails.country,
    state: dealOverviewDetails.state,
    city: dealOverviewDetails.city,
    street: dealOverviewDetails.street,
    zipCode: dealOverviewDetails.zipCode,
    description: dealOverviewDetails.description,
    stage: dealOverviewDetails.stage,
  });

  const [noteInfo, setNoteInfo] = useState([]);
  const [addTitle, setAddTitle] = useState("");
  const [note, setNote] = useState("");
  const [selectedNoteFiles, setSelectedNoteFiles] = useState([]);
  const [showButtons, setShowButtons] = useState(false);
  const [showTitleNote, setShowTitleNote] = useState(false);
  const [noteTitle, setNoteTitle] = useState("");
  const [selectRecent, setSelectRecent] = useState("ALL");
  const [shareMemberIds, setShareMemberIds] = useState([]);
  useScrollToUndo(pastViewInfo, setPastViewInfo); // for scroll to previously clicked section

  const convertToDisplayFormat = (dateString) => {
    if (!dateString) return "";
    const [year, month, day] = dateString.split("-");
    return `${day}-${month}-${year}`;
  };

  const handleTextAreaClick = () => {
    setShowButtons(true);
  };

  const handleTextFileChange = (event) => {
    const files = Array.from(event.target.files);
    if (files) {
      setSelectedNoteFiles(files);
    } else {
      setSelectedNoteFiles([]);
    }
  };
  const handleAddTitleClick = () => {
    setShowTitleNote(true);
  };

  const getNotesDetails = async (dealId, orderType) => {
    const requestBody = {
      loggedInUserId: loginUserId,
      moduleId: dealId,
      orderType: orderType,
    };
    const headersResult = getHeaders(true);

    // Check if headers result indicates an error
    if (headersResult.error) {
      navigate("../"); // Redirect to login if token is missing
      return;
    }
    await axios
      .post(URI.getNote, requestBody, {
        headers: headersResult,
      })
      .then((response) => {
        if (response.data.status === 200) {
          setNoteInfo(response.data.data.noteInfo);
        } else {
          NotificationManager.error("", response.data.message, 5000);
        }
      })
      .catch(async (error) => {
        handleApiError(error, navigate);
      });
  };

  const handleSelectRecent = (item) => {
    setSelectRecent(item);
    var value = "";
    if (item === "Recent Last") {
      value = "RECENT_LAST";
    } else if (item === "Recent First") {
      value = "RECENT_FIRST";
    } else {
      value = item;
    }
    getNotesDetails(dealId, value);
  };

  const insertNote = async () => {
    const formData = new FormData();
    formData.append("moduleId", dealId);
    formData.append("loggedInUserId", loginUserId);
    formData.append("moduleName", "DEALS");
    formData.append("noteDescription", note);
    formData.append("noteTitle", addTitle);

    selectedNoteFiles.forEach((file) => {
      formData.append("noteImages", file);
    });
    const headersResult = getHeaders(true);

    // Check if headers result indicates an error
    if (headersResult.error) {
      navigate("../"); // Redirect to login if token is missing
      return;
    }
    await axios
      .post(URI.insertNote, formData, {
        headers: headersResult,
      })
      .then(() => {
        // const newNoteId = response.data.data.noteId; // Extracting noteId from response
        setShowButtons(false);
        setNote("");
        setSelectedNoteFiles([]);
        // fetchNoteImage(newNoteId); // Pass newNoteId to fetchNoteImage
        getNotesDetails(dealId, selectRecent);
      })
      .catch((error) => {
        handleApiError(error, navigate);
      });
  };

  const getAllDealsDetails = async (pageNo, selectedOption) => {
    setIsLoading(true);
    setSelectedOption(selectedOption);
    const requestBody = {
      loggedInUserId: loginUserId,
      customFilter: selectedOption.value,
      pageNo: pageNo,
      pageSize: perPage,
    };
    const headersResult = getHeaders(true);

    // Check if headers result indicates an error
    if (headersResult.error) {
      navigate("../"); // Redirect to login if token is missing
      return;
    }
    await axios
      .post(URI.getAllDeals, requestBody, {
        headers: headersResult,
      })
      .then((response) => {
        if (response.data.status === 200) {
          setDealDetails(response.data.data.allDealDetails);
          setFilteredLeadDetails(response.data.data.allDealDetails);
          setTotalItems(response.data.data.rowCount);
          setIsLoading(false);
        } else {
          setIsLoading(false);
        }
      })
      .catch(async (error) => {
        handleApiError(error, navigate);
      });
  };

  const getAllTimeleadLeads = async (dealId) => {
    const headersResult = getHeaders(true);

    // Check if headers result indicates an error
    if (headersResult.error) {
      navigate("../"); // Redirect to login if token is missing
      return;
    }
    await axios
      .get(URI.getDealTimeline + "/" + loginUserId + "/" + dealId, {
        headers: headersResult,
      })
      .then((response) => {
        if (response.data.status === 200) {
          setTimeLineDataList(response.data.data.getDealDetails);
        }
      })
      .catch(async (error) => {
        throw new Error("Failed to fetch data", error);
      });
  };

  const dealOverviewDetail = async (dealId, selectedStage) => {
    let dealOverviewDetails = {};
    let updatedFields = {};
    const requestBody = {
      loggedInUserId: loginUserId,
      dealId: dealId,
      stage: selectedStage,
    };

    const headersResult = getHeaders(true);

    // Check if headers result indicates an error
    if (headersResult.error) {
      navigate("../"); // Redirect to login if token is missing
      return;
    }
    await axios
      .post(URI.dealOverview, requestBody, {
        headers: headersResult,
      })
      .then((response) => {
        if (response.data.status === 200) {
          dealOverviewDetails = response.data.data.dealInfo;
          updatedFields = response.data.data.dealInfo;

          dealOverviewDetails.dealInfoNext =
            response.data.data.dealInfo.nextStep;
          dealOverviewDetails.dealInfoAmt = response.data.data.dealInfo?.amount;
          dealOverviewDetails.dealInfoClosingDate =
            response.data.data.dealInfo.closingDate;
          dealOverviewDetails.dealInfoDealName =
            response.data.data.dealInfo.dealName;

          dealOverviewDetails.dealOwnerInfo =
            response.data.data.dealInfo?.dealOwner;
          dealOverviewDetails.accountName =
            response.data.data.dealInfo?.accountName;
          dealOverviewDetails.amount = response.data.data.dealInfo?.amount;
          dealOverviewDetails.dealName = response.data.data.dealInfo?.dealName;
          dealOverviewDetails.closingDate =
            response.data.data.dealInfo?.closingDate;

          dealOverviewDetails.probability =
            response.data.data.dealInfo.probability;
          dealOverviewDetails.type = response.data.data.dealInfo.type;
          dealOverviewDetails.nextStep = response.data.data.dealInfo.nextStep;
          dealOverviewDetails.expectedRevenue =
            response.data.data.dealInfo.expectedRevenue;
          dealOverviewDetails.campaignSource =
            response.data.data.dealInfo.campaignSource;
          dealOverviewDetails.description =
            response.data.data.dealInfo.description;
          dealOverviewDetails.lastUpdate =
            response.data.data.dealInfo.lastUpdate;
          dealOverviewDetails.closingDate =
            response.data.data.dealInfo.closingDate;

          dealOverviewDetails.leadSource = {
            label: response.data.data.dealInfo.leadSource,
            value: response.data.data.dealInfo.leadSource,
          };

          dealOverviewDetails.stage = {
            label: response.data.data.dealInfo.stage,
            value: response.data.data.dealInfo.stage,
          };
          dealOverviewDetails.type = {
            label: response.data.data.dealInfo.type,
            value: response.data.data.dealInfo.type,
          };
          dealOverviewDetails.reasonForLost = {
            label: response.data.data.dealInfo.reasonForLost,
            value: response.data.data.dealInfo.reasonForLost,
          };
          updatedFields = dealOverviewDetails;
          setSelectedAccountItem({
            accountName: response.data.data.dealInfo.accountName,
            accountId: response.data.data.dealInfo.accountId,
          });
          setSelectedContactItem({
            contactName: response.data.data.dealInfo.contactName,
            contactId: response.data.data.dealInfo.contactId,
          });

          setSelectedItem(
            userList.find(
              (user) => user.userId === response.data.data.dealInfo.dealOwnerId
            )
          );
          setStage(dealOverviewDetails.stage.value);
          setClickedDropdownObj(dealOverviewDetails.stage);
          setdealOverviewDetails(dealOverviewDetails);
          setStageHistoryList(response.data.data.dealStageHist);
          setProductsList(response.data.data.assignedProducts);
          setOpenActivities(response.data.data.assignOpenActivities);
          setOpenActivitiesCalls(response.data.data.openActivitiesCalls);
          setOpenActivitiesMeetings(response.data.data.openActivitiesMeetings);
          setClosedActivitiesCall(response.data.data.closedActivitiesCalls);
          setClosedActivitiesMeetings(
            response.data.data.closedActivitiesMeetings
          );
          setCloseActivities(response.data.data.assignClosedActivities);
          setFilterQuotesDetails(response.data.data.assignQuotes);
          setFilterSalesDetails(response.data.data.assignedSales);
          setFilterInvoicesDetails(response.data.data.assignInvoice);

          setUpdatedFields(updatedFields);
        }
      })
      .catch(async (error) => {
        handleApiError(error, navigate);
      });
  };

  const handleChangeIndDropDown = (selectedOption, field) => {
    setUpdatedFields((prevFields) => ({
      ...prevFields,
      [field]: selectedOption,
    }));
  };

  const handleStageDropDown = (selectedOption, field) => {
    if (existingDeal || existingClone) {
      setDealUpdateInfo((prevLeadInfo) => ({
        ...prevLeadInfo,
        [field]: selectedOption,
      }));
    } else {
      setDealsInfo((prevDealsInfo) => ({
        ...prevDealsInfo,
        [field]: selectedOption,
      }));
      setdealOverviewDetails((prevDealsInfo) => ({
        ...prevDealsInfo,
        [field]: selectedOption,
      }));
    }
  };

  const handledeleteModule = async (deleteProdId) => {
    const requestBody = {
      loginUserId: loginUserId,
      moduleName: "DEALS",
      moduleId: dealId,
      assignModuleName: "PRODUCTS",
      assignModuleId: deleteProdId,
    };

    try {
      const headersResult = getHeaders(true);

      // Check if headers result indicates an error
      if (headersResult.error) {
        navigate("../"); // Redirect to login if token is missing
        return;
      }
      const response = await axios.post(URI.deleteRole, requestBody, {
        headers: headersResult,
      });
      if (response.data.status === 200) {
        NotificationManager.success("", response.data.message, 5000);
        dealOverviewDetail(dealId, stage);
      } else {
        NotificationManager.error("", response.data.message, 5000);
      }
    } catch (error) {
      handleApiError(error, navigate);
    }
  };

  const handleAssignModule = async (selectedIds, assignModuleName) => {
    const moduleIdList = selectedIds.map((id) => ({ moduleId: id }));

    if (moduleIdList.length < 1) {
      return NotificationManager.error("", "Please select an item", 5000);
    }

    const requestBody = {
      loginUserId: loginUserId,
      moduleName: "DEALS",
      moduleId: dealId,
      assignModuleName: assignModuleName,
      moduleIdList,
    };

    setApiLoader(true);
    try {
      const headersResult = getHeaders(true);

      // Check if headers result indicates an error
      if (headersResult.error) {
        navigate("../"); // Redirect to login if token is missing
        return;
      }
      const response = await axios.post(URI.assignRole, requestBody, {
        headers: headersResult,
      });
      if (response.data.status === 200) {
        NotificationManager.success("", response.data.message, 5000);
        dealOverviewDetail(dealId, stage);
      } else {
        NotificationManager.error("", response.data.message, 5000);
      }
    } catch (error) {
      handleApiError(error, navigate);
    } finally {
      setApiLoader(false);
    }
  };

  const getAllInfoShare = async (dealId) => {
    setIsLoading(true);
    const requestBody = {
      moduleId: dealId,
      loggedInUserId: loginUserId,
    };

    const headersResult = getHeaders(true);

    // Check if headers result indicates an error
    if (headersResult.error) {
      navigate("../"); // Redirect to login if token is missing
      return;
    }

    await axios
      .post(URI.getShareInfo, requestBody, {
        headers: headersResult,
      })
      .then((response) => {
        if (response.data.status === 200) {
          const hasSharedItems =
            (response.data.data.getSharingInfoUsers &&
              response.data.data.getSharingInfoUsers.length > 0) ||
            (response.data.data.getSharingInfoRoles &&
              response.data.data.getSharingInfoRoles.length > 0) ||
            (response.data.data.getSharingInfoGroups &&
              response.data.data.getSharingInfoGroups.length > 0);

          const userShareMemberIds = response.data.data.getSharingInfoUsers.map(
            (user) => user.shareModuleId
          );
          const roleShareMemberIds = response.data.data.getSharingInfoRoles.map(
            (role) => role.shareModuleId
          );
          const groupShareMemberIds =
            response.data.data.getSharingInfoGroups.map(
              (group) => group.shareModuleId
            );
          const groupPublicSharedMemberIds =
            response.data.data.getSharingInfoPublic.map(
              (user) => user.shareModuleId
            );
          setShareMemberIds([
            ...userShareMemberIds,
            ...roleShareMemberIds,
            ...groupShareMemberIds,
            ...groupPublicSharedMemberIds,
          ]);

          setShareUserList(response.data.data.getSharingInfoUsers);
          setShareRoleList(response.data.data.getSharingInfoRoles);
          setShareGroupList(response.data.data.getSharingInfoGroups);
          setSharePublicList(response.data.data.getSharingInfoPublic);
          if (hasSharedItems) {
            setShareInfoModal(true);
          } else {
            setShareModal(true);
          }
          setIsLoading(false);
        }
      })
      .catch((err) => {
        handleApiError(err, navigate);
      });
  };

  const handleAssignProductModule = (selectedProductId) => {
    handleAssignModule(selectedProductId, "PRODUCTS");
  };

  const handleAssignQuoteModule = (selectedQuoteId) => {
    handleAssignModule(selectedQuoteId, "QUOTES");
  };

  const handleAssignSalesModule = (selectedSalesId) => {
    handleAssignModule(selectedSalesId, "SALES ORDERS");
  };

  const handleAssignInvoiceModule = (selectedInvoiceId) => {
    handleAssignModule(selectedInvoiceId, "INVOICES");
  };

  const createLeadStyles = {
    menu: (base) => ({
      ...base,
      width: "60%",
    }),
    option: (provided) => ({
      ...provided,
      // backgroundColor: "#EAEAEA",
      color: "#000",
      // backgroundColor: 'rgba(33, 119, 254, 0.1)',
      width: "100%",
      cursor: "pointer",
    }),
    control: (baseStyles) => ({
      ...baseStyles,
      border: "1px solid #b9b9b9b0",
      backgroundColor: "transparent",
      height: "40px",
      borderRadius: "4px",
      width: "60%",
      boxShadow: "none",
      cursor: "pointer",
    }),
    input: (base) => ({
      ...base,
      color: "#000",
    }),
    singleValue: (defaultStyles) => ({ ...defaultStyles, color: "#000" }),

    menuList: (provided) => ({
      ...provided,
      paddingTop: 0,
      paddingBottom: 0,
      "&::-webkit-scrollbar": {
        width: 5,
      },

      "&::-webkit-scrollbar-thumb": {
        background: "#316AFF",
        borderRadius: "10px",
      },
    }),
  };

  const fieldDropdownStyles = {
    menu: (base) => ({
      ...base,
      width: "100%",
    }),
    option: (provided) => ({
      ...provided,
      // backgroundColor: "#EAEAEA",
      color: "#000",
      // backgroundColor: 'rgba(33, 119, 254, 0.1)',
      width: "100%",
      cursor: "pointer",
    }),
    control: (baseStyles) => ({
      ...baseStyles,
      border: "none",
      // border: closedWonModalOpen ? "none" : "1px solid gray",
      // backgroundColor: "rgba(33, 119, 254, 0.1)",
      backgroundColor: "transparent",
      height: "20px",
      borderRadius: "4px",
      width: "100%",
      boxShadow: "none",
      cursor: "pointer",
      // maxHeight: "20vh",
    }),
    input: (base) => ({
      ...base,
      color: "#FFFFFF",
    }),
    singleValue: (defaultStyles) => ({
      ...defaultStyles,
      color: "#000",
    }),

    menuList: (provided) => ({
      ...provided,
      height: closedWonModalOpen ? "9.5rem" : "14rem",
      // height:
      //   closedWonModalOpen || showModalType === "Mass Update" ? "9.5rem" : "",
      paddingTop: 0,
      paddingBottom: 0,
      "&::-webkit-scrollbar": {
        width: 5,
      },

      "&::-webkit-scrollbar-thumb": {
        background: "#316AFF",
        borderRadius: "10px",
      },
    }),
  };

  const handleChange = (option) => {
    if (selectedOption.value !== option.value) {
      setCurrentPage(1);
      getAllDealsDetails(1, option);
    }
  };

  const handleIndividualInputChange = (field, value) => {
    setUpdatedFields((prevFields) => ({
      ...prevFields,
      [field]: value,
    }));
  };

  const handleEditClick = (field) => {
    if (Object.values(editingFields).some((value) => value)) {
      return;
    }
    setEditingFields((prevFields) => ({
      ...prevFields,
      [field]: true,
    }));
    setUpdatedFields((prevFields) => ({
      ...prevFields,
      [field]: dealOverviewDetails[field],
    }));
  };
  const handleCancelClick = (field) => {
    setEditingFields((prevFields) => ({
      ...prevFields,
      [field]: false,
    }));
    setUpdatedFields((prevFields) => ({
      ...prevFields,
      [field]: dealOverviewDetails[field],
    }));
  };

  const handleUpdateClick = async (field, selectedStage) => {
    var value = "";
    let requestBody = {};
    const isObj =
      typeof dealOverviewDetails[field] === "object" &&
      dealOverviewDetails[field] !== null &&
      !Array.isArray(dealOverviewDetails[field]);

    if (
      field === "industryType" ||
      field === "stage" ||
      field === "leadSource" ||
      field === "type"
    ) {
      value = updatedFields[field].value;
    } else {
      value = updatedFields[field];
    }

    const fieldMappings = {
      // dealsInfoEmail: "emailId",
      // dealOwnerInfo: "dealOwner",
      // leadPhone: "phoneNumber",
      // leadMobile: "mobile",
      // leadStatusInfo: "leadStatus",
      dealInfoNext: "nextStep",
      dealInfoAmt: "amount",
      dealInfoClosingDate: "closingDate",
      dealInfoDealName: "dealName",
    };
    // if (fieldMappings.hasOwnProperty(field)) {
    //   field = fieldMappings[field];
    // } not supported by eslint
    if (Object.hasOwn(fieldMappings, field)) {
      field = fieldMappings[field];
    }
    setApiLoader(true);
    try {
      if (field === "amount") {
        requestBody = {
          dealId: dealId,
          [field]: value,
          loggedInUserId: loginUserId,
          expectedRevenue: parseFloat(
            (value * dealOverviewDetails.probability) / 100
          ).toFixed(2),
        };
      } else if (field === "stage") {
        const stagePercentageValue = stages[value] / 100;
        const amount = dealOverviewDetails.amount;

        requestBody = {
          dealId: dealId,
          stage: value,
          loggedInUserId: loginUserId,
          probability: stages[value].toString(),
          expectedRevenue:
            stagePercentageValue !== 0
              ? parseFloat(amount * stagePercentageValue).toFixed(2)
              : amount,
        };
      } else {
        requestBody = {
          dealId: dealId,
          [field]: value,
          loggedInUserId: loginUserId,
          // dealOwnerId: isOwnerSelect
          //   ? selectedOwnerItem?.userId
          //   : selectedItem?.userId,
        };
        if (field === "emailNotification") {
          requestBody.dealOwnerId = isOwnerSelect
            ? selectedOwnerItem?.userId
            : selectedItem?.userId;
        }
      }

      const compareValue = isObj
        ? dealOverviewDetails[field].value
        : dealOverviewDetails[field];

      if (value !== compareValue || field === "emailNotification") {
        const headersResult = getHeaders(true);

        // Check if headers result indicates an error
        if (headersResult.error) {
          navigate("../"); // Redirect to login if token is missing
          return;
        }
        const response = await axios.post(
          URI.individualUpdateDeals,
          requestBody,
          {
            headers: headersResult,
          }
        );

        if (response.data.status === 200) {
          NotificationManager.success("", response.data.message, 5000);
          setdealOverviewDetails((prevDetails) => ({
            ...prevDetails,
            [field]: updatedFields[field],
          }));
          setStage(updatedFields["stage"]?.value);
          dealOverviewDetail(dealId, updatedFields["stage"]?.value);
          setIsOwnerSelect(false);
          setShowModalType("");
        } else {
          NotificationManager.error("", response.data.message, 5000);
        }
      }

      const resetFields = Object.fromEntries(
        Object.keys(editingFields).map((key) => [key, false])
      );
      setEditingFields({
        ...resetFields,
      });
      setShowType("");
    } catch (error) {
      handleApiError(error, navigate);
    } finally {
      setApiLoader(false);
    }
  };

  const handleUpdateStatusClick = async (stage) => {
    const stagePercentageValue = stages[stage] / 100;
    const amount = dealOverviewDetails.amount;

    try {
      const requestBody = {
        dealId: dealId,
        stage: stage,
        loggedInUserId: loginUserId,
        probability: stages[stage].toString(),
        expectedRevenue:
          stagePercentageValue !== 0
            ? parseFloat(amount * stagePercentageValue).toFixed(2)
            : amount,
      };

      const headersResult = getHeaders(true);

      // Check if headers result indicates an error
      if (headersResult.error) {
        navigate("../"); // Redirect to login if token is missing
        return;
      }
      const response = await axios.post(
        URI.individualUpdateDeals,
        requestBody,
        {
          headers: headersResult,
        }
      );

      if (response.status === 200) {
        setdealOverviewDetails((prevDetails) => ({
          ...prevDetails,
          ["status"]: updatedFields["status"],
        }));

        dealOverviewDetail(dealId, stage);
      } else {
        alert("Unexpected status code:");
      }
    } catch (error) {
      handleApiError(error, navigate);
    }
  };

  const getAllCampaignDetails = async () => {
    setIsLoading(true);
    // setSelectedOption(selectedOption);
    const requestBody = {
      loggedInUserId: loginUserId,
      filter: "all_campaigns",
      pageNo: currentPageCampaign,
      pageSize: perPageCampaign,
    };

    const headersResult = getHeaders(true);

    // Check if headers result indicates an error
    if (headersResult.error) {
      navigate("../"); // Redirect to login if token is missing
      return;
    }

    await axios
      .post(URI.getAllCampaigns, requestBody, {
        headers: headersResult,
      })
      .then((response) => {
        if (response.data.status === 200) {
          setAllList(response.data.data.getCampaignInformation);
          if (!selectedCampaignName && !selectedCampaignId) {
            setSelectedCampaignItem(response.data.data.getCampaignInformation);
          }
          setModalCampaignDetails(response.data.data.getCampaignInformation);
          setTotalItemsCampaign(response.data.data.p_row_count);
          // setFilteredLeadDetails(response.data.data.getCampaignInformation);
          // setTotalItems(response.data.data.p_row_count);
          setIsLoading(false);
        } else {
          setIsLoading(false);
        }
      })
      .catch(async (error) => {
        handleApiError(error, navigate);
      });
  };

  const handleOwnerInputClick = (e) => {
    e.stopPropagation();
    setListVisible(!listVisible);
    setAccountListVisible(false);
    setContactListVisible(false);
  };

  const handleUserItemClick = (item) => {
    setSelectedItem(item);
    setListVisible(false);
  };

  const handleOwnerParentInputClick = (e) => {
    e.stopPropagation();
    setParentListVisible(!parentListVisible);
  };

  const handleOwnerParentInputClickCampaign = (e) => {
    e.stopPropagation();
    setAllCampaignListVisible(!allCampaignListVisible);
  };

  const handleParentUserItemClick = (item) => {
    setSelectedCampaignItem({
      campaignName: item.campaignName,
      campaignId: item.campaignId,
    });
    setParentListVisible(false);
  };
  const handleParentUserItemCampaign = (item) => {
    setSelectedCampaignItem({
      campaignName: item.campaignName,
      campaignId: item.campaignId,
    });
    setAllCampaignListVisible(false);
  };

  const handleSelectedLeadChange = (value) => {
    setSelectedItem(value); // Update the selected lead value
    setSelectedOwnerItem(value);
  };

  useEffect(() => {
    if (fetchDealInfo && innerPage === "overview") {
      dealOverviewDetail(fetchDealInfo?.dealId);
      setDealId(fetchDealInfo?.dealId);
    }
    getAllCampaignDetails();
  }, []);

  const nodataPage = () => {
    return (
      <div className="d-flex flex-column justify-content-center align-items-center  ">
        <img src={dealNoteData} alt="img" height={200} width={200} />
        <h5 className="opacity-75">Manage Deal</h5>
        <p className="opacity-75 p-0 m-0">
          Quotes are legal agreements between a customer and a vector
        </p>
        <p className="opacity-75 p-0 m-0">
          to deliver the requested product(s) at the agreed upon price
        </p>
        <p className="opacity-75 p-0 m-0">within the specified time period.</p>
        <button
          className="createContactButtonSave px-2 mt-4 cursorPointer rounded-3"
          style={{ width: "15%" }}
          onClick={() => {
            setSelectedCampaignItem({ campaignName: "", campaignId: "" });
            setInnerPage("create");
          }}
        >
          Create a Deal
        </button>
      </div>
    );
  };

  const dealHomePage = () => {
    return (
      <>
        <div>
          {isLoading ? (
            <>
              <Loader />
            </>
          ) : filteredLeadDetails && filteredLeadDetails.length > 0 ? (
            <>
              <div className="creteLeadBar">
                {selectedCheckboxes.length > 0 ? (
                  <div className="d-flex w-50 align-items-center gap-2">
                    <Select
                      options={massOptions}
                      styles={customCommonStyles("100%")}
                      value={{
                        value: "Action",
                        label: "Action",
                      }}
                      onChange={(option) => {
                        if (option.value === "Mass Update") {
                          setShowSearchFields(dealsColumn);
                          setSelectedObj(dealsColumn[0]);
                        } else if (option.value === "Mass Transfer") {
                          setSelectedItem(userList[0]);
                        }
                        setMassUpdateValue("");
                        setShowModalType(option.value);
                      }}
                      className="w-25"
                      isSearchable={false}
                    />
                  </div>
                ) : (
                  <>
                    <div className="d-flex w-50 align-items-center gap-2">
                      {/* <img
              src={filterView ? filterIcon : filterIconCross}
              height={25}
              className="leadFilter"
              alt="img"
            /> */}
                      <Select
                        options={options}
                        styles={customCommonStyles("240px", "300px")}
                        value={selectedOption}
                        onChange={handleChange}
                        isSearchable={false}
                      />
                    </div>

                    <div className="d-flex w-50 justify-content-end gap-2">
                      <div className="btn-group">
                        <button
                          className="createContactButtonSave py-2"
                          style={{
                            background:
                              "linear-gradient(86.07deg, #407BFF 0%, #12A7FE 100%)",
                            border: "none",
                            borderRight: "2px solid #fff",
                            color: "#fff",
                          }}
                          onClick={handleCreateDeal}
                        >
                          Create Deal
                        </button>
                        {/* <button
                type="button"
                className="btn btn-sm  dropdown-toggle dropdown-toggle-split"
                data-bs-toggle="dropdown"
                aria-expanded="false"
                style={{
                  background:
                    "linear-gradient(86.07deg, #407BFF 0%, #12A7FE 100%)",
                  color: "#fff",
                }}
              >
                <span className="visually-hidden"></span>
              </button> */}
                        {/* <ul className="dropdown-menu">
                <li>
                  <a className="dropdown-item ">Import Deals</a>
                </li>
                <li>
                  <a className="dropdown-item ">Import Notes</a>
                </li>
              </ul> */}
                      </div>
                      {/* <Select
              options={massOptions}
              styles={customCommonStyles("100%")}
              value={dropdownTwo}
              onChange={(option) => {
                setDropdownTwo(option);
              }}
            /> */}
                    </div>
                  </>
                )}
              </div>
              <div className="totalRecordBar">
                <div className="d-flex w-50">
                  <h6>
                    {selectedCheckboxes.length > 0
                      ? `${selectedCheckboxes.length} Records Selected`
                      : `Total Records : ${totalItems}`}{" "}
                  </h6>
                </div>
                <div className="d-flex w-50 align-items-center justify-content-end gap-2">
                  {selectedCheckboxes.length === 0 && (
                    <Select
                      options={pageOption}
                      styles={customCommonStyles("100%")}
                      value={pageOption.find(
                        (option) => option.value === perPage.toString()
                      )}
                      onChange={handlePageDropDowmChange}
                      isSearchable={false}
                    />
                  )}
                  <Pagination
                    currentPage={currentPage}
                    totalPages={totalPages}
                    onPageChange={handlePageChange}
                  />
                </div>
              </div>
              <div className="leadMain">
                {filterView ? (
                  <div className="filterLead">
                    <h6 style={{ borderBottom: "2px solid #316AFF" }}>
                      Filter by Lead
                    </h6>
                    {/* <div> */}
                    <input
                      type="text"
                      placeholder="Search"
                      className="filterSearch"
                    />
                    {/* </div> */}
                    <div className="checkboxItem">
                      <h6 style={{ color: "#515151" }}>
                        ▾ System Defined Filters
                      </h6>
                      <input
                        type="checkbox"
                        id="vehicle1"
                        name="vehicle1"
                        value="Bike"
                      />
                      &nbsp;
                      <label htmlFor="vehicle1">Touched Record</label>
                      <br />
                      <input
                        type="checkbox"
                        id="vehicle1"
                        name="vehicle1"
                        value="Bike"
                      />
                      &nbsp;
                      <label htmlFor="vehicle1">Untouched Record</label>
                      <br />
                      <input
                        type="checkbox"
                        id="vehicle1"
                        name="vehicle1"
                        value="Bike"
                      />
                      &nbsp;
                      <label htmlFor="vehicle1">Record Action</label>
                      <br />
                      <input
                        type="checkbox"
                        id="vehicle1"
                        name="vehicle1"
                        value="Bike"
                      />
                      &nbsp;
                      <label htmlFor="vehicle1">Touched Record</label>
                      <br />
                      <input
                        type="checkbox"
                        id="vehicle1"
                        name="vehicle1"
                        value="Bike"
                      />
                      &nbsp;
                      <label htmlFor="vehicle1">Untouched Record</label>
                      <br />
                      <input
                        type="checkbox"
                        id="vehicle1"
                        name="vehicle1"
                        value="Bike"
                      />
                      &nbsp;
                      <label htmlFor="vehicle1">Record Action</label>
                      <br />
                      <h6 style={{ color: "#515151", marginTop: "10px" }}>
                        ▾ Filter By Fields
                      </h6>
                      <input
                        type="checkbox"
                        id="vehicle1"
                        name="vehicle1"
                        value="Bike"
                      />
                      &nbsp;
                      <label htmlFor="vehicle1">Annual Revenue</label>
                      <br />
                      <input
                        type="checkbox"
                        id="vehicle1"
                        name="vehicle1"
                        value="Bike"
                      />
                      &nbsp;
                      <label htmlFor="vehicle1">City</label>
                      <br />
                      <input
                        type="checkbox"
                        id="vehicle1"
                        name="vehicle1"
                        value="Bike"
                      />
                      &nbsp;
                      <label htmlFor="vehicle1">Company</label>
                      <br />
                      <input
                        type="checkbox"
                        id="vehicle1"
                        name="vehicle1"
                        value="Bike"
                      />
                      &nbsp;
                      <label htmlFor="vehicle1">Annual Revenue</label>
                      <br />
                      <input
                        type="checkbox"
                        id="vehicle1"
                        name="vehicle1"
                        value="Bike"
                      />
                      &nbsp;
                      <label htmlFor="vehicle1">City</label>
                      <br />
                      <input
                        type="checkbox"
                        id="vehicle1"
                        name="vehicle1"
                        value="Bike"
                      />
                      &nbsp;
                      <label htmlFor="vehicle1">Company</label>
                      <br />
                    </div>
                  </div>
                ) : (
                  <></>
                )}

                <div
                  className={
                    filterIcon ? "leadListTableFullWidth" : "leadListTable"
                  }
                >
                  <table className="table leadTable">
                    <thead>
                      <tr>
                        <th scope="col">
                          <input
                            className="cursorPointer"
                            type="checkbox"
                            id="all"
                            name="all"
                            value="All"
                            // checked={
                            //   selectedCheckboxes.length ===
                            //     filteredLeadDetails.length &&
                            //   filteredLeadDetails.length > 0
                            // }
                            // onChange={(e) => {
                            //   if (e.target.checked) {
                            //     const allList = filteredLeadDetails.map(
                            //       (item) => ({ moduleId: item.dealId })
                            //     );
                            //     setSelectedCheckboxes(allList);
                            //   } else {
                            //     setSelectedCheckboxes([]);
                            //   }
                            // }}
                            checked={filteredLeadDetails.every((selected) =>
                              selectedCheckboxes.some(
                                (list) => list.moduleId === selected.dealId
                              )
                            )}
                            onChange={(e) => {
                              const allList = filteredLeadDetails.map(
                                (item) => ({
                                  moduleId: item.dealId,
                                })
                              );
                              if (e.target.checked) {
                                const newSelections = allList.filter(
                                  (newItem) =>
                                    !selectedCheckboxes.some(
                                      (existingItem) =>
                                        existingItem.moduleId ===
                                        newItem.moduleId
                                    )
                                );
                                setSelectedCheckboxes((prev) => [
                                  ...prev,
                                  ...newSelections,
                                ]);
                              } else {
                                setSelectedCheckboxes(
                                  selectedCheckboxes.filter(
                                    (aItem) =>
                                      !allList.some(
                                        (bItem) =>
                                          aItem.moduleId === bItem.moduleId
                                      )
                                  )
                                );
                              }
                            }}
                          />
                        </th>
                        {selectedHeadings.map(
                          (heading) =>
                            heading.isChecked && (
                              <th scope="col" key={heading.name}>
                                <span className="pe-3">{heading.name}</span>
                                <span className="position-relative">
                                  <img
                                    src={tableFilterIcon}
                                    alt=""
                                    className="cursorPointer"
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      setShowHeadings(false);
                                      setShowSelectedFilter(heading.name);
                                    }}
                                  />
                                  {heading.name === showSelectedFilter && (
                                    <span
                                      className="position-absolute rounded-2 border bg-light top-0 mt-4"
                                      style={{ right: "-10%" }}
                                    >
                                      <span className="d-flex flex-column px-1">
                                        {/* Ascending filter */}
                                        <span
                                          className="my-1 px-1 cursorPointer fw-normal text-start filter_type"
                                          onClick={() => {
                                            if (filterType === "asc") {
                                              setFilterType("");
                                              setFilteredLeadDetails(
                                                dealDetails
                                              );
                                            } else {
                                              setFilterType("asc");
                                              sortedColumnsData(
                                                "asc",
                                                heading.value
                                              );
                                            }
                                          }}
                                        >
                                          {filterType === "asc" ? (
                                            <img
                                              src={cancel}
                                              alt="asc"
                                              className="opacity-75 pe-1"
                                            />
                                          ) : (
                                            <img
                                              src={arrowUp}
                                              alt="asc"
                                              className="opacity-75 pe-1"
                                            />
                                          )}
                                          Asc
                                        </span>
                                        {/* Descending filter */}
                                        <span
                                          className="my-2 px-1 cursorPointer fw-normal text-start filter_type"
                                          onClick={() => {
                                            if (filterType === "desc") {
                                              setFilterType("");
                                              setFilteredLeadDetails(
                                                dealDetails
                                              );
                                            } else {
                                              setFilterType("desc");
                                              sortedColumnsData(
                                                "desc",
                                                heading.value
                                              );
                                            }
                                          }}
                                        >
                                          {filterType === "desc" ? (
                                            <img
                                              src={cancel}
                                              alt="desc"
                                              className="opacity-75 pe-1"
                                            />
                                          ) : (
                                            <img
                                              src={arrowDown}
                                              alt="desc"
                                              className="opacity-75 pe-1"
                                            />
                                          )}
                                          Desc
                                        </span>
                                        <span
                                          className={`my-1 px-1 fw-normal text-start filter_type ${
                                            selectedHeadings[0].name ===
                                              "Deal Name" &&
                                            heading.name === "Deal Name"
                                              ? "opacity-50"
                                              : "cursorPointer"
                                          }`}
                                          onClick={() => {
                                            if (pinnedColumn !== "Deal Name") {
                                              setPinnedColumn("Deal Name");
                                              handlePinColumn("Deal Name");
                                            } else {
                                              setPinnedColumn(heading.name);
                                              handlePinColumn(heading.name);
                                            }
                                          }}
                                        >
                                          <img
                                            src={
                                              pinnedColumn === heading.name
                                                ? unPin
                                                : pin
                                            }
                                            alt="pin"
                                            className="opacity-75 pe-1"
                                          />{" "}
                                          {pinnedColumn === heading.name
                                            ? "Unpin"
                                            : "Pin"}{" "}
                                          Column
                                        </span>
                                      </span>
                                    </span>
                                  )}
                                  <img
                                    src={filterLine}
                                    alt=""
                                    className="ps-2"
                                  />
                                </span>
                              </th>
                            )
                        )}

                        {/* <th scope="col">Company</th>
                  <th scope="col">Email</th>
                  <th scope="col">Phone</th>
                  <th scope="col">Lead Source</th> */}
                        {/* <th scope="col">Lead Owner</th> */}
                        <th
                          scope="col"
                          className="text-end cursorPointer position-relative"
                        >
                          <img
                            src={filterMenu}
                            alt=""
                            className={`filter_menu ${
                              showHeadings ? "bg-light" : ""
                            }`}
                            onClick={(e) => {
                              e.stopPropagation();
                              setShowSelectedFilter("");
                              setShowHeadings(true);
                            }}
                          />
                          {showHeadings && (
                            <div
                              className="position-absolute rounded-2  border border-primary bg-light"
                              style={{
                                right: "0.2rem",
                              }}
                            >
                              <div className="d-flex flex-column px-2">
                                <span
                                  className="p-2 cursorPointer fw-normal text-start"
                                  onClick={() => {
                                    setSearchColumn("");
                                    setSavingHeadings(selectedHeadings);
                                    setShowModalType("Manage Columns");
                                  }}
                                >
                                  Manage Columns
                                </span>
                                <span
                                  className="p-2 fw-normal text-start opacity-50"
                                  onClick={() => {
                                    setShowModalType("Reset Column Size");
                                  }}
                                >
                                  Reset Column Size
                                </span>
                              </div>
                            </div>
                          )}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {filteredLeadDetails &&
                        filteredLeadDetails.map((item, i) => {
                          return (
                            <tr key={i} className="">
                              <td>
                                <input
                                  className="cursorPointer"
                                  type="checkbox"
                                  id="vehicle1"
                                  name="vehicle1"
                                  value={item.dealId}
                                  checked={selectedCheckboxes.some(
                                    (checkbox) =>
                                      checkbox.moduleId === item.dealId
                                  )}
                                  onChange={handleSelectCheckboxChange}
                                />
                              </td>
                              <React.Fragment>
                                {selectedHeadings.map((obj, i) => {
                                  const value = obj.value;
                                  const itemValue = item[value];

                                  return obj.isChecked ? (
                                    value === "transHash" ? (
                                      <td
                                        style={{ wordBreak: "break-all" }}
                                        className="overflow_text_hash"
                                        key={itemValue}
                                      >
                                        <a
                                          rel="noreferrer"
                                          href={
                                            EXPLORER_URL +
                                            "about-tx-hash/" +
                                            item.transHash
                                          }
                                          className="anchorLinkHash textColor"
                                          data-title={item.transHash}
                                          target="_blank"
                                        >
                                          {item.transHash
                                            ? item.transHash.slice(0, 10) +
                                              "...."
                                            : "-"}
                                        </a>
                                      </td>
                                    ) : (
                                      <td
                                        key={i}
                                        onClick={() => {
                                          if (value === "dealName") {
                                            setInnerPage("overview");
                                            dealOverviewDetail(
                                              item.dealId,
                                              item.stage
                                            );
                                            setFetchDealInfo(item);
                                            setStage(item.stage);
                                            setDealId(item.dealId);
                                            getNotesDetails(
                                              item.dealId,
                                              selectRecent
                                            );
                                            setSelectedLeadIndex(
                                              dealDetails.findIndex(
                                                (deal) =>
                                                  deal.dealId === item.dealId
                                              )
                                            );
                                            setSelectedCheckboxes([]);
                                          }
                                        }}
                                        className={`overflow_text ${
                                          value === "dealName"
                                            ? "leadName cursorPointer"
                                            : ""
                                        }`}
                                        style={{ maxWidth: "3vw" }}
                                      >
                                        {itemValue !== null ? itemValue : "-"}
                                      </td>
                                    )
                                  ) : null;
                                })}
                              </React.Fragment>
                              <td></td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>
                </div>
              </div>
            </>
          ) : (
            <>{nodataPage()}</>
          )}
        </div>
      </>
    );
  };

  const createDealPage = () => {
    return (
      <>
        <div className="createLeadButtonBar">
          <div className="d-flex align-items-center w-50 gap-2">
            <img src={groupIcon} height={25} alt="img" />
            <div className="d-flex flex-column">
              <p
                className="mb-0 first_letter_uppercase"
                style={{ fontSize: "22px" }}
              >
                <span>{existingDeal ? "Edit" : innerPage}</span> Deal
              </p>
            </div>
          </div>
          <div className="d-flex w-50 gap-2 justify-content-end">
            <button
              className="createContactButton"
              onClick={() => {
                if (pastViewInfo) {
                  setCurrentView(pastViewInfo.view);
                  setInnerPage(pastViewInfo.innerPage);
                  setPastViewInfo((prev) => ({
                    ...prev,
                    view: "",
                    innerPage: "",
                    undo: currentView, // it will set the current view of the page so when we cancel or save it will go to same section in the page
                  }));
                  return;
                }
                setInnerPage("list");
                setSelectedItem("");
                setSelectedAccountItem("");
                setSelectedContactItem("");
                setExistingDeal(false);
                if (existingDeal) {
                  setInnerPage("overview");
                }
                if (innerPage === "clone" && !existingDeal) {
                  setInnerPage("overview");
                }
              }}
            >
              Cancel
            </button>
            {existingDeal === false && (
              <button
                className="createContactButton"
                onClick={() => handleSubmitDeal("SAVE AND NEW")}
                disabled={saveAndNew}
              >
                {saveAndNew ? (
                  <Lottie
                    options={defaultOptions("blue")}
                    height="24px"
                    width="36px"
                  />
                ) : (
                  "Save and New"
                )}
              </button>
            )}

            <button
              className="createContactButtonSave"
              type="submit"
              onClick={() => {
                if (existingDeal) {
                  handleUpdateDeal("Save");
                } else if (existingClone) {
                  handleSubmitDeal("ADD");
                } else {
                  handleSubmitDeal("ADD");
                }
              }}
              disabled={saveSubmit}
            >
              {/* {existingDeal ? "UPDATE" : "SAVE"} */}
              {saveSubmit ? (
                <Lottie
                  options={defaultOptions("white")}
                  height="30px"
                  width="36px"
                />
              ) : (
                updateButtonLabel
              )}
            </button>
          </div>
        </div>

        <div className="leadFields ">
          <h6 className="py-2 " style={{ borderBottom: "2px solid #316AFF" }}>
            Deal Information
          </h6>
          {/* <form onSubmit={handleSubmitDeal}> */}

          <div className="inputFieldDiv">
            <div className="inputFieldDivLeft position-relative">
              <p className="mb-0">Deal Owner </p>
              <div
                className="d-flex input-with-clear"
                // onClick={handleOwnerInputClick}
              >
                <input
                  type="text"
                  className="leadInputField cursorPointer"
                  value={selectedItem?.name || ""}
                  onClick={handleOwnerInputClick}
                  readOnly
                />

                {existingDeal ? (
                  <></>
                ) : (
                  <span
                    className="clear-button"
                    onClick={(e) => {
                      handleOwnerInputClick(e);
                    }}
                  >
                    <img
                      src={listVisible ? up : down}
                      alt="img"
                      height={20}
                      width={20}
                    />
                  </span>
                )}

                <span
                  className="cursorPointer pt-2 ps-1"
                  // onClick={() => setIsLeadOwnerModal(!isLeadOwnerModal)}
                  onClick={() => {
                    if (existingDeal) {
                      setShowModalType("changeOwner");
                      // setSelectedOwnerItem(selectedItem);
                      setListVisible(false);
                    } else {
                      setIsLeadOwnerModal(!isLeadOwnerModal);
                    }
                  }}
                >
                  <img src={userSearch} alt="img" width={20} height={20} />
                </span>
              </div>
              {existingDeal ? (
                <></>
              ) : (
                <>
                  {listVisible && (
                    <ul
                      className="data-list list-group  lead_ownerList_container position-absolute"
                      style={{ top: "6rem" }}
                    >
                      {userList.map((item, index) => (
                        <li
                          key={index}
                          className="cursorPointer list-group-item"
                          onClick={() => handleUserItemClick(item)}
                        >
                          <div className="d-flex flex-column">
                            <span> {item.name} </span>
                            <span> {item.emailId} </span>
                          </div>
                        </li>
                      ))}
                    </ul>
                  )}
                </>
              )}
            </div>
            <div className="inputFieldDivRight position-relative">
              <p className="mb-0">
                Amount <span className="text-danger">*</span>
              </p>
              <input
                type="text"
                className={
                  amountMsg ? "mandatoryField" : "insertCommonInputField"
                }
                pattern="[0-9]*"
                maxLength={10}
                autoComplete="off"
                style={{ padding: "12px 15px" }}
                value={
                  existingDeal || existingClone
                    ? dealUpdateInfo.amount
                    : dealsInfo.amount
                }
                name="amount"
                onChange={handleDealsChange}
                onKeyDown={(e) => {
                  const { value } = e.target;
                  if (
                    (!/\d/.test(e.key) &&
                      e.key !== "Backspace" &&
                      e.key !== "Delete" &&
                      e.key !== "ArrowLeft" &&
                      e.key !== "ArrowRight" &&
                      e.key !== ".") ||
                    (e.key === "." && value.includes("."))
                  ) {
                    e.preventDefault();
                  }
                }}
              />
              <span
                className="clear-button position-absolute px-1"
                style={{ left: "0px", bottom: amountMsg ? "37.5px" : "9px" }}
              >
                ₹
              </span>
              <span style={{ color: "#fa5a5a" }}>{amountMsg && amountMsg}</span>
            </div>
          </div>
          <div className="inputFieldDiv">
            <div className="inputFieldDivLeft">
              <p className="mb-0">
                Deal Name
                <span className="text-danger">*</span>
              </p>
              <input
                type="text"
                className={
                  mandatoryMsg ? "mandatoryField" : "insertCommonInputField"
                }
                value={
                  existingDeal || existingClone
                    ? dealUpdateInfo.dealName
                    : dealsInfo.dealName
                }
                name="dealName"
                onChange={handleDealsChange}
              />
              <p style={{ color: "#fa5a5a" }}>{mandatoryMsg && mandatoryMsg}</p>
            </div>
            <div className="inputFieldDivRight">
              <p className="mb-0">
                Closing Date
                <span className="text-danger">*</span>
              </p>
              <input
                type="date"
                className={
                  closingDateMsg ? "mandatoryField" : "insertCommonInputField"
                }
                value={
                  existingDeal || existingClone
                    ? convertDateFormat(dealUpdateInfo.closingDate)
                    : convertDateFormat(dealsInfo.closingDate)
                }
                name="closingDate"
                onChange={handleDealsChange}
                onKeyDown={(e) => e.preventDefault()}
                onClick={(e) => !e.target.showPicker()}
                min="2000-01-01"
                max="2050-12-31"
              />
              <p style={{ color: "#fa5a5a" }}>
                {closingDateMsg && closingDateMsg}
              </p>
            </div>
          </div>
          <div className="inputFieldDiv">
            <div className="inputFieldDivLeft position-relative">
              <p className="mb-0">
                Account Name
                <span className="text-danger">*</span>
              </p>
              <div>
                <span className="d-flex input-with-clear">
                  <input
                    type="text"
                    className={
                      accountIdMsg
                        ? "mandatoryField"
                        : "insertCommonInputField cursorPointer"
                    }
                    value={selectedAccountItem?.accountName || ""}
                    // value={ moduleWiseData.length > 0
                    //   ? selectedModuleItem.moduleName
                    //   :""}
                    onClick={(e) => {
                      handleAccountClick(e, selectedAccountItem);
                    }}
                    readOnly
                  />
                  <span
                    className="clear-button"
                    onClick={(e) => {
                      handleAccountClick(e, selectedAccountItem);
                    }}
                  >
                    <img
                      src={accountListVisible ? up : down}
                      alt="img"
                      height={20}
                      width={20}
                    />
                  </span>
                  {/* ) : (
                  <span className="clear-button">
                    <img src={up} alt="img" height={20} width={20} />
                  </span>
                )} */}
                  <span
                    className="cursorPointer pt-2 ps-1"
                    onClick={() => {
                      setIsAccountListModal(!isAccountListModal);
                    }}
                  >
                    <img src={accountsIcon} alt="img" width={22} height={25} />
                  </span>
                </span>
                <span style={{ color: "#fa5a5a", top: "40px" }}>
                  {accountIdMsg && accountIdMsg}
                </span>
              </div>

              {accountListVisible && accountDetails.length > 0 && (
                <ul
                  className="data-list list-group  lead_ownerList_container position-absolute"
                  style={{ top: "6rem" }}
                  onClick={(e) => e.stopPropagation()}
                >
                  {accountDetails.map((item, index) => (
                    <li
                      key={index}
                      className="cursorPointer list-group-item"
                      onClick={() => handleAccountItemClick(item)}
                    >
                      <div className="d-flex">{item.accountName}</div>
                    </li>
                  ))}
                </ul>
              )}
            </div>
            {/* {isAccountListModal ? (
              <AccountListModal
                isAccountListModal={isAccountListModal}
                setIsAccountListModal={setIsAccountListModal}
                onSelectedLeadChange={handleSelectedAccountListChange}
                defaultUser={selectedAccountItem}
                
              />
            ) : null} */}
            <div className="inputFieldDivRight">
              <p className="mb-0">
                Stage
                <span className="text-danger">*</span>
              </p>
              <Select
                value={
                  existingDeal || existingClone
                    ? dealUpdateInfo.stage
                    : dealsInfo.stage
                }
                menuPlacement="auto"
                onChange={(selectedOption) => {
                  handleStageDropDown(selectedOption, "stage");
                  const stagePercentageValue =
                    stages[selectedOption.value] / 100;
                  console.log("stagePercentageValue", stagePercentageValue);
                  const amount =
                    existingDeal || existingClone
                      ? dealUpdateInfo.amount
                      : dealsInfo.amount;

                  if (existingDeal || existingClone) {
                    setDealUpdateInfo((prevLeadInfo) => ({
                      ...prevLeadInfo,
                      probability: stages[selectedOption.value],
                      expectedRevenue:
                        stagePercentageValue !== 0
                          ? parseFloat(amount * stagePercentageValue).toFixed(2)
                          : amount,
                      reasonForLost: ReasonOptions[0],
                    }));
                  } else {
                    setDealsInfo((prevDealsInfo) => ({
                      ...prevDealsInfo,
                      probability: stages[selectedOption.value],
                      expectedRevenue:
                        stagePercentageValue !== 0
                          ? parseFloat(amount * stagePercentageValue).toFixed(2)
                          : amount,
                      reasonForLost: ReasonOptions[0],
                    }));
                  }
                }}
                options={stageOptions}
                styles={createLeadStyles}
                isSearchable={false}
              />
              <p style={{ color: "#fa5a5a" }}>{stageMsg && stageMsg}</p>
            </div>
          </div>

          <div className="inputFieldDiv">
            <div className="inputFieldDivLeft">
              <p className="mb-0">Type</p>
              <Select
                value={
                  existingDeal || existingClone
                    ? dealUpdateInfo.type
                    : dealsInfo.type
                }
                menuPlacement="auto"
                onChange={(selectedOption) =>
                  handleStageDropDown(selectedOption, "type")
                }
                options={typeOptions}
                styles={createLeadStyles}
                isSearchable={false}
              />
            </div>

            {["Closed Lost", "Closed Lost to Competition"].includes(
              existingDeal || existingClone
                ? dealUpdateInfo.stage.value
                : dealsInfo.stage.value
            ) ? (
              <div className="inputFieldDivRight">
                <p className="mb-0">
                  Reason For Loss
                  <span className="text-danger">*</span>
                </p>
                <Select
                  value={
                    existingDeal || existingClone
                      ? dealUpdateInfo.reasonForLost
                      : dealsInfo.reasonForLost
                  }
                  menuPlacement="auto"
                  onChange={(selectedOption) =>
                    handleStageDropDown(selectedOption, "reasonForLost")
                  }
                  options={ReasonOptions}
                  styles={createLeadStyles}
                  isSearchable={false}
                />
                {/* <p style={{ color: "#fa5a5a" }}>{stageMsg && stageMsg}</p> */}
              </div>
            ) : (
              <div className="inputFieldDivRight">
                <p className="mb-0">
                  Probability (%)
                  <span className="text-danger">*</span>
                </p>
                <input
                  type="text"
                  pattern="[0-9]*"
                  maxLength={3}
                  autoComplete="off"
                  className={
                    probabilityMsg ? "mandatoryField" : "insertCommonInputField"
                  }
                  name="probability"
                  value={
                    existingDeal || existingClone
                      ? dealUpdateInfo.probability
                      : dealsInfo.probability
                  }
                  onChange={handleDealsChange}
                  readOnly
                />

                <p style={{ color: "#fa5a5a" }}>
                  {probabilityMsg && probabilityMsg}
                </p>
              </div>
            )}
          </div>
          <div className="inputFieldDiv">
            <div className="inputFieldDivLeft">
              <p className="mb-0">Next Step</p>
              <input
                type="text"
                className="insertCommonInputField"
                value={
                  existingDeal || existingClone
                    ? dealUpdateInfo.nextStep
                    : dealsInfo.nextStep
                }
                name="nextStep"
                onChange={handleDealsChange}
              />
            </div>
            {["Closed Lost", "Closed Lost to Competition"].includes(
              existingDeal || existingClone
                ? dealUpdateInfo.stage.value
                : dealsInfo.stage.value
            ) ? (
              <div className="inputFieldDivRight mt-3">
                <p className="mb-0">
                  Probability (%)
                  <span className="text-danger">*</span>
                </p>
                <input
                  type="text"
                  pattern="[0-9]*"
                  maxLength={3}
                  autoComplete="off"
                  className={
                    probabilityMsg ? "mandatoryField" : "insertCommonInputField"
                  }
                  name="probability"
                  value={
                    existingDeal || existingClone
                      ? dealUpdateInfo.probability
                      : dealsInfo.probability
                  }
                  onChange={handleDealsChange}
                  readOnly
                />

                <p style={{ color: "#fa5a5a" }}>
                  {probabilityMsg && probabilityMsg}
                </p>
              </div>
            ) : (
              <div className="inputFieldDivRight mt-1">
                <p className="mb-0">Expected Revenue</p>
                <input
                  type="text"
                  className="insertCommonInputField"
                  pattern="[0-9]*"
                  maxLength={10}
                  autoComplete="off"
                  name="expectedRevenue"
                  value={
                    existingDeal || existingClone
                      ? dealUpdateInfo.expectedRevenue
                      : dealsInfo.expectedRevenue
                  }
                  onChange={handleDealsChange}
                  readOnly
                />
              </div>
            )}
          </div>
          <div className="inputFieldDiv">
            <div className="inputFieldDivLeft">
              <p className="mb-0">Lead Source</p>
              <Select
                // value={
                //   existingDeal
                //     ? leadSourceOptions.find(
                //         (option) => option.value === dealUpdateInfo.leadSource
                //       )
                //     : leadSourceOptions.find(
                //         (option) => option.value === dealsInfo.leadSource
                //       )
                // }

                value={
                  existingDeal || existingClone
                    ? dealUpdateInfo.leadSource
                    : dealsInfo.leadSource
                }
                menuPlacement="auto"
                onChange={(selectedOption) =>
                  handleStageDropDown(selectedOption, "leadSource")
                }
                options={leadSourceOptions}
                styles={createLeadStyles}
                isSearchable={false}
              />
            </div>

            <div className="inputFieldDivLeft position-relative">
              <p className="mb-0">Campaign Source</p>
              <div className="d-flex input-with-clear ">
                <input
                  type="text"
                  className={"insertCommonInputField cursorPointer"}
                  value={selectedCampaignItem?.campaignName || ""}
                  onClick={handleOwnerParentInputClick}
                  readOnly
                />
                <span
                  className="clear-button"
                  onClick={handleOwnerParentInputClick}
                >
                  <img
                    src={parentListVisible ? up : down}
                    alt="img"
                    height={20}
                    width={20}
                  />
                </span>
                <span
                  className="cursorPointer pt-2 ps-1"
                  onClick={() => {
                    setIsCampaignListModal(!isCampaignListModal);
                  }}
                >
                  <img src={campaignIcon} alt="img" width={20} height={20} />
                </span>
              </div>
              {parentListVisible && allList.length > 0 && (
                <ul
                  className="data-list list-group  lead_ownerList_container position-absolute"
                  style={{ top: "6rem" }}
                  onClick={(e) => e.stopPropagation()}
                >
                  {allList.map((item, index) => (
                    <li
                      key={index}
                      className="cursorPointer list-group-item"
                      onClick={() => handleParentUserItemClick(item)}
                    >
                      {item.campaignName}
                    </li>
                  ))}
                </ul>
              )}
            </div>
          </div>
          <div className="inputFieldDiv">
            {["Closed Lost", "Closed Lost to Competition"].includes(
              existingDeal || existingClone
                ? dealUpdateInfo.stage.value
                : dealsInfo.stage.value
            ) ? (
              <div className="inputFieldDivRight mt-1">
                <p className="mb-0">Expected Revenue</p>
                <input
                  type="text"
                  className="insertCommonInputField"
                  pattern="[0-9]*"
                  maxLength={10}
                  autoComplete="off"
                  name="expectedRevenue"
                  // value={
                  //   existingDeal || existingClone
                  //     ? dealUpdateInfo.expectedRevenue
                  //     : dealsInfo.expectedRevenue
                  // }
                  value="0"
                  onChange={handleDealsChange}
                  readOnly
                />
              </div>
            ) : null}
            <div className="inputFieldDivLeft position-relative">
              <p className="mb-0">
                Contact Name
                <span className="text-danger">*</span>
              </p>
              <div>
                <span className="d-flex input-with-clear ">
                  <input
                    type="text"
                    className={
                      contactIdMsg
                        ? "mandatoryField"
                        : "insertCommonInputField cursorPointer"
                    }
                    value={selectedContactItem.contactName}
                    onClick={(e) => handleContactClick(e, selectedContactItem)}
                    readOnly
                  />
                  <span
                    className="clear-button"
                    onClick={(e) => {
                      handleContactClick(e, selectedContactItem);
                    }}
                  >
                    <img
                      src={contactListVisible ? up : down}
                      alt="img"
                      height={20}
                      width={20}
                    />
                  </span>
                  <span
                    className="cursorPointer pt-2 ps-1"
                    onClick={() => setIsContactListModal(!isContactListModal)}
                  >
                    <img src={ContactIcon} alt="img" width={20} height={20} />
                  </span>
                </span>
                <span style={{ color: "#fa5a5a", top: "40px" }}>
                  {contactIdMsg && contactIdMsg}
                </span>
              </div>
              {contactListVisible && contactDetails.length > 0 && (
                <ul
                  className="data-list list-group  lead_ownerList_container position-absolute"
                  style={{ top: "6rem" }}
                  onClick={(e) => e.stopPropagation()}
                >
                  {contactDetails.map((item, index) => (
                    <li
                      key={index}
                      className="cursorPointer list-group-item"
                      onClick={() => handleContactItemClick(item)}
                    >
                      {item.contactName}
                    </li>
                  ))}
                </ul>
              )}
            </div>
          </div>

          <div className="inputFieldDiv"></div>

          {/* {isContactListModal ? (
            <ContactListModal
              isContactListModal={isContactListModal}
              setIsContactListModal={setIsContactListModal}
              onSelectedLeadChange={handleSelectedContactListChange}
              defaultUser={selectedContactItem}
            />
          ) : null} */}
          <h6
            className="py-2 mt-4"
            style={{ borderBottom: "2px solid #316AFF" }}
          >
            Description Information
          </h6>
          <div className="inputFieldDiv">
            <div className="inputFieldDivLeft">
              <p>Description</p>
              <textarea
                rows="4"
                cols="50"
                maxLength="1000"
                style={{
                  width: "100%",
                  border: "1px solid #B9B9B94D",
                  height: "100px",
                  outline: "none",
                  borderRadius: "10px",
                  backgroundColor: "transparent",
                  padding: "8px 12px",
                  resize: "none",
                }}
                value={
                  existingDeal || existingClone
                    ? dealUpdateInfo.description
                    : dealsInfo.description
                }
                name="description"
                onChange={handleDealsChange}
              />
            </div>
          </div>
        </div>
      </>
    );
  };

  const handleFieldClick = (field) => {
    if (field.type === "dropdown") {
      const fieldValue = field.name
        .replace(/\s+/g, "")
        .replace(/^(.)/, (match) => match.toLowerCase());

      setClickedDropdownObj(dealOverviewDetails[fieldValue]);
    } else if (field.type === "checkbox") {
      // setClickedEmailOtp(dealOverviewDetails["emailOptOut"]);
    }

    const updatedColumns = [...fieldLabels];

    const existedObj = updatedColumns.find(
      (field) => field.name === selectedField
    );
    const selectedObj = updatedColumns.find((item) => item.name === field.name);

    const existedObjIndex = updatedColumns.findIndex(
      (field) => field.name === existedObj.name
    );
    const selectedObjIndex = updatedColumns.findIndex(
      (field) => field.name === selectedObj.name
    );

    updatedColumns[existedObjIndex].isShowed = false;
    updatedColumns[selectedObjIndex].isShowed = true;

    const removedObj = updatedColumns.splice(existedObjIndex, 1)[0];
    updatedColumns.splice(existedObjIndex, 0, selectedObj);

    updatedColumns.push(removedObj);
    const uniqueData = updatedColumns.filter(
      (item, index, self) =>
        index === self.findIndex((t) => t.name === item.name)
    );
    setFieldLabels(uniqueData);
    setShowSearchFields(uniqueData);
  };

  const individualDealDetailPage = () => {
    return (
      <div className="indiv_main_container ">
        {/* <p className="text-danger fw-bolder">Work in progress... </p> */}
        <header className="commonContainer_bg w-100">
          <div className="d-flex justify-content-between px-3 pt-2">
            <span
              className="cursorPointer"
              onClick={() => {
                // setCurrentPage(1);
                setInnerPage("list");
                getAllDealsDetails(currentPage, selectedOption);
                setFetchDealInfo(null);
                setLeadPageView("overview");
                const resetFields = Object.fromEntries(
                  Object.keys(editingFields).map((key) => [key, false])
                );
                setEditingFields({
                  ...resetFields,
                });
                setShowType("");
              }}
            >
              {leftArrow}
            </span>
            <span>
              <span
                className={`me-2 ${
                  selectedLeadIndex === 0 ? "opacity-50" : "cursorPointer"
                }`}
                onClick={() => {
                  const leadIndex = dealDetails.findIndex(
                    (deal) => deal.dealId === dealId
                  );
                  if (leadIndex !== 0) {
                    setSelectedLeadIndex(leadIndex - 1);
                    setDealId(dealDetails[leadIndex - 1].dealId);
                    dealOverviewDetail(
                      dealDetails[leadIndex - 1].dealId,
                      dealDetails[leadIndex - 1].stage
                    );
                    setStage(dealDetails[leadIndex - 1].stage);
                    setFetchDealInfo(dealDetails[leadIndex - 1]);
                  }
                }}
              >
                {leftAngleArrow}
              </span>
              <span
                className={`ms-1 ${
                  selectedLeadIndex === dealDetails.length - 1
                    ? "opacity-50"
                    : "cursorPointer"
                }`}
                onClick={() => {
                  const leadIndex = dealDetails.findIndex(
                    (deal) => deal.dealId === dealId
                  );
                  if (leadIndex < dealDetails.length - 1) {
                    setSelectedLeadIndex(leadIndex + 1);
                    setDealId(dealDetails[leadIndex + 1].dealId);
                    dealOverviewDetail(
                      dealDetails[leadIndex + 1].dealId,
                      dealDetails[leadIndex + 1].stage
                    );
                    setStage(dealDetails[leadIndex + 1].stage);
                    setFetchDealInfo(dealDetails[leadIndex + 1]);
                  }
                }}
              >
                {rightAngleArrow}
              </span>
            </span>
          </div>
          <div className=" d-flex justify-content-between">
            <div className="profile_logo_container d-flex pt-2">
              <div className="d-flex flex-column  justify-content-center ps-2">
                <span>{dealOverviewDetails?.dealName}</span>
                <span className="opacity-75 ">
                  ₹{" " + dealOverviewDetails.amount}
                </span>
              </div>
            </div>
            <div className="d-flex  justify-content-end align-items-end  px-2 pb-2">
              <div className="d-flex gap-2">
                {/* <button className="btn  btnColor text-white">Send Email</button> */}
                <button
                  className="btn btn-secondary bg-white text-dark border border-primary"
                  onClick={handleEditDeal}
                >
                  Edit
                </button>
                <button
                  className="btn btn-secondary bg-white text-dark border border-primary"
                  onClick={(e) => {
                    e.stopPropagation();
                    setShowOptionsDeal(!showOptionsDeal);
                  }}
                >
                  ---
                </button>
                {showOptionsDeal && (
                  <div
                    className="rounded  showOptions p-2 end-0"
                    style={{ top: "11.5rem", zIndex: "1" }}
                  >
                    <div
                      className="d-flex gap-3 align-items-center mt-2"
                      onClick={handleCreateDealClone}
                    >
                      <img src={Close} alt="close" />
                      <div
                        style={{ color: "#515151", fontWeight: "300" }}
                        className="mt-1 cursorPointer ms-2"
                      >
                        Clone
                      </div>
                    </div>
                    {dealOverviewDetails.isShared === false && (
                      <div
                        className="d-flex gap-3 align-items-center mt-2"
                        onClick={() => {
                          if (!isLoading) {
                            getAllInfoShare(dealId);
                          }
                        }}
                      >
                        <img src={Share} alt="close" />
                        <div
                          style={{ color: "#515151", fontWeight: "300" }}
                          className="mt-2 cursorPointer ms-2"
                        >
                          Share
                        </div>
                      </div>
                    )}

                    <div
                      className="d-flex gap-3 align-items-center mt-2"
                      onClick={handleDeleteDeal}
                    >
                      <img src={Delete} alt="close" />
                      <div
                        style={{ color: "#515151", fontWeight: "300" }}
                        className="mt-2 cursorPointer ms-2"
                      >
                        Delete
                      </div>
                    </div>
                    <div style={{ borderBottom: "1px solid #515151s" }}></div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </header>

        <div className="detailList_main_container mt-2 commonContainer_bg">
          <div className="detailList_main_container d-flex py-2 ">
            <div
              className={` pt-3 cursorPointer ${isShrinked ? "" : "w-25"}`}
              onClick={() => {
                setIsShrinked(!isShrinked);
              }}
            >
              {isShrinked ? expandArrow : shrinkArrow}
            </div>
            <div
              className={`d-flex justify-content-between px-2 ${
                isShrinked ? "w-100" : "w-75 ps-3"
              }`}
            >
              <span className="pt-3">
                Last Update: {dealOverviewDetails.lastUpdate}
              </span>
              <div className="d-flex gap-4 justify-content-between px-2 bg-white px-1  rounded-5 py-1">
                <span
                  // className=""
                  className={`cursorPointer ${
                    leadPageView === "overview"
                      ? "overviewTimelineTab "
                      : "  d-flex justify-content-center align-items-center"
                  }`}
                  onClick={() => setLeadPageView("overview")}
                >
                  Overview
                </span>
                <span
                  className={`cursorPointer ${
                    leadPageView === "timeline"
                      ? "overviewTimelineTab"
                      : " d-flex justify-content-center align-items-center"
                  }`}
                  onClick={() => {
                    setLeadPageView("timeline");
                    getAllTimeleadLeads(dealId);
                  }}
                >
                  Timeline
                </span>
              </div>
            </div>
          </div>

          <div className="detailList_content_container bg-white d-flex justify-content-between px-2 pt-2">
            <div
              className={`w-25 relatedListContainer rounded commonContainer_bg  me-2 ${
                isShrinked ? "d-none" : ""
              }`} // style={{ height: "100vh" }}
            >
              <div className="d-flex">
                <p className="opacity-57 ps-2 opacity-75 m-0 py-2">
                  Related List
                </p>
              </div>
              <div className="horizontal_line mx-1"></div>
              <ul className="relateList_container">
                {notePermission === "TRUE" && (
                  <li
                    className={`cursorPointer py-2 relatedList_tab rounded-1 }`}
                    onClick={() => {
                      if (leadPageView === "timeline") {
                        setLeadPageView("overview");
                        setTimeout(() => scrollToSection("notesSection"), 0);
                        setActiveTab("note");
                      } else {
                        setActiveTab("note");
                        scrollToSection("notesSection");
                      }
                    }}
                  >
                    <span>
                      <img src={NoteBook} alt="img" height={20} width={20} />
                    </span>
                    Notes
                  </li>
                )}

                <li
                  className={`cursorPointer py-2 relatedList_tab rounded-1 }`}
                  onClick={() => {
                    if (leadPageView === "timeline") {
                      setLeadPageView("overview");
                      setTimeout(() => scrollToSection("stageHistory"), 0);
                      setActiveTab("stageHistory");
                    } else {
                      setActiveTab("stageHistory");
                      scrollToSection("stageHistory");
                    }
                  }}
                >
                  <span>
                    <img src={cadences} alt="img" height={20} width={20} />
                  </span>
                  Stage History
                </li>
                <li
                  className={`cursorPointer py-2 relatedList_tab rounded-1 }`}
                  onClick={() => {
                    if (leadPageView === "timeline") {
                      setLeadPageView("overview");
                      setTimeout(() => scrollToSection("openActivity"), 0);
                      setActiveTab("openActivity");
                    } else {
                      setActiveTab("openActivity");
                      scrollToSection("openActivity");
                    }
                  }}
                >
                  <span>
                    <img
                      src={openActivityIcon}
                      alt="img"
                      height={20}
                      width={20}
                    />
                  </span>
                  Open Activities
                </li>
                <li
                  className={`cursorPointer py-2 relatedList_tab rounded-1 }`}
                  onClick={() => {
                    if (leadPageView === "timeline") {
                      setLeadPageView("overview");
                      setTimeout(() => scrollToSection("closeActivity"), 0);
                      setActiveTab("closeActivity");
                    } else {
                      setActiveTab("closeActivity");
                      scrollToSection("closeActivity");
                    }
                  }}
                >
                  <span>
                    <img
                      src={closeActivityIcon}
                      alt="img"
                      height={20}
                      width={20}
                    />
                  </span>
                  Closed Activities
                </li>
                <li
                  className={`cursorPointer py-2 relatedList_tab rounded-1 }`}
                  onClick={() => {
                    if (leadPageView === "timeline") {
                      setLeadPageView("overview");
                      setTimeout(() => scrollToSection("product"), 0);
                      setActiveTab("product");
                    } else {
                      setActiveTab("product");
                      scrollToSection("product");
                    }
                  }}
                >
                  <span>
                    <img src={prodIcon} alt="img" height={20} width={20} />
                  </span>
                  Products
                </li>
                <li
                  className={`cursorPointer py-2 relatedList_tab rounded-1 }`}
                  onClick={() => {
                    if (leadPageView === "timeline") {
                      setLeadPageView("overview");
                      setTimeout(() => scrollToSection("quotes"), 0);
                      setActiveTab("quotes");
                    } else {
                      setActiveTab("quotes");
                      scrollToSection("quotes");
                    }
                  }}
                >
                  <span>
                    <img src={inviteIcon} alt="img" height={20} width={20} />
                  </span>
                  Quotes
                </li>
                <li
                  className={`cursorPointer py-2 relatedList_tab rounded-1 }`}
                  onClick={() => {
                    if (leadPageView === "timeline") {
                      setLeadPageView("overview");
                      setTimeout(() => scrollToSection("salesOrders"), 0);
                      setActiveTab("salesOrders");
                    } else {
                      setActiveTab("salesOrders");
                      scrollToSection("salesOrders");
                    }
                  }}
                >
                  <span>
                    <img src={emailIcon} alt="img" height={20} width={20} />
                  </span>
                  Sales Orders
                </li>
                <li
                  className={`cursorPointer py-2 relatedList_tab rounded-1 }`}
                  onClick={() => {
                    if (leadPageView === "timeline") {
                      setLeadPageView("overview");
                      setTimeout(() => scrollToSection("invoices"), 0);
                      setActiveTab("invoices");
                    } else {
                      setActiveTab("invoices");
                      scrollToSection("invoices");
                    }
                  }}
                >
                  <span>
                    <img src={campaignsIcon} alt="img" height={20} width={20} />
                  </span>
                  Invoices
                </li>
              </ul>
            </div>

            {leadPageView === "overview" ? (
              <div
                className={`bg-white w-75 rounded-2 ps-1 pb-2 leadDetail_main_Container ${
                  isShrinked ? "w-100" : "w-75"
                }`}
              >
                <div className=" common_bg ">
                  <div className="lead_Information_mainContainer commonContainer_bg rounded-2  d-flex flex-column mb-2">
                    <div className="d-flex align-items-center gap-2 px-4 py-3 w-100">
                      <div
                        className="d-flex flex-column "
                        style={{ width: "15%" }}
                      >
                        <p className="commonTextColor fw-bold mb-0">START</p>
                        <p
                          className="mb-0"
                          style={{ color: "rgba(81, 81, 81, 1)" }}
                        >
                          {dealOverviewDetails.startingDate}
                        </p>
                      </div>
                      <div
                        className="d-flex flex-column"
                        style={{ width: "80%" }}
                      >
                        <span
                          style={{
                            border: "1.5px dotted rgba(64, 123, 255, 1)",
                          }}
                        ></span>
                      </div>
                      <div
                        className="d-flex flex-column align-items-end"
                        style={{ width: "15%" }}
                      >
                        <p className="commonTextColor fw-bold mb-0">CLOSING</p>
                        <p
                          className="mb-0"
                          style={{ color: "rgba(81, 81, 81, 1)" }}
                        >
                          {dealOverviewDetails.closingDate}
                        </p>
                      </div>
                    </div>

                    <div className="d-flex align-items-center gap-2 px-4 py-3 w-100 justify-content-between">
                      <div className="" onClick={goToPrev}>
                        <img
                          src={leftSlide}
                          alt="leftSlide"
                          style={{
                            cursor: "pointer",
                            color: "rgba(82, 156, 243, 1)",
                            border: "1px solid rgba(82, 156, 243, 1)",
                            padding: "10px",
                            borderRadius: "50%",
                            // width:"10%"
                          }}
                        />
                      </div>
                      <div className=" px-2" style={{ width: "90%" }}>
                        <Slider
                          ref={sliderRef}
                          {...settings}
                          style={{
                            // width: "70%",
                            width: "100%",
                            backgroundColor: "#fff",
                            borderRadius: "10px",
                            border: "1px solid #529CF3",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          {stageOptions.map((item, index) => {
                            return (
                              <div
                                key={index}
                                className=""
                                style={{
                                  alignItems: "center",
                                  justifyContent: "center",
                                }}
                              >
                                <p
                                  style={{ fontSize: "14px" }}
                                  className={`mb-0 cursorPointer text-center w-100 p-2 ${
                                    stage === item.value
                                      ? "dealStage"
                                      : "dealUnstage"
                                  }`}
                                  onClick={() => handleStageClick(item.label)}
                                >
                                  {item.label}
                                </p>
                              </div>
                            );
                          })}
                        </Slider>
                      </div>
                      <div className="" onClick={goToNext}>
                        <img
                          src={rightSlide}
                          alt="rightSlide"
                          style={{
                            cursor: "pointer",
                            color: "rgba(82, 156, 243, 1)",
                            border: "1px solid rgba(82, 156, 243, 1)",
                            padding: "10px",
                            borderRadius: "50%",
                            // width:"10%"
                          }}
                        />
                      </div>
                    </div>

                    <div className="d-flex justify-content-end flex-wrap align-items-center position-relative">
                      <div
                        onClick={(e) => {
                          toggleDropdown(e);
                        }}
                        style={{ cursor: "pointer" }}
                      >
                        <img src={LikeDropdown} alt="like" />
                      </div>
                      {isDropdownVisibleLike && (
                        <div
                          style={{
                            position: "absolute",
                            top: "72%",
                            right: "10%",
                            backgroundColor: "white",
                            borderRadius: "8px",
                            boxShadow: "0 8px 16px rgba(0,0,0,0.2)",
                            zIndex: 1,
                          }}
                        >
                          <ul
                            style={{
                              listStyle: "none",
                              padding: "10px",
                              margin: "0",
                            }}
                          >
                            <li
                              style={{ padding: "8px 12px", cursor: "pointer" }}
                              onClick={() => {
                                setIsClosedWonModalOpen(true);
                                setExistingClone(true);
                                setDealUpdateInfo({ ...dealOverviewDetails });
                              }}
                            >
                              Closed Won
                            </li>
                          </ul>
                        </div>
                      )}

                      <Modal
                        show={closedWonModalOpen}
                        className="d-flex justify-content-center align-items-start"
                        aria-labelledby="example-custom-modal-styling-title"
                        size="lg"
                        id="commonLeadModal"
                      >
                        <div
                          style={{
                            fontSize: "25px",
                            fontWeight: "bold",
                            color: "rgb(64, 123, 255)",
                            // background:
                            //   "linear-gradient(to right, rgba(64, 123, 255, 1), rgba(18, 167, 254, 1))",
                            // WebkitBackgroundClip: "text",
                            // backgroundClip: "text",
                            // WebkitTextFillColor: "transparent",
                          }}
                          className="pb-2 pt-3 ps-3"
                        >
                          Verify Details
                        </div>
                        <Modal.Body className="ModalBody overflow-auto py-4">
                          <div className="d-flex flex-column gap-4 pt-2">
                            <div className="d-flex gap-4">
                              <div>
                                <div>Amount</div>
                                <input
                                  type="text"
                                  className="inputBoxDeal mt-1"
                                  pattern="[0-9]*"
                                  maxLength={10}
                                  autoComplete="off"
                                  style={{ padding: "12px 15px" }}
                                  value={
                                    existingDeal || existingClone
                                      ? dealUpdateInfo.amount
                                      : dealsInfo.amount
                                  }
                                  name="amount"
                                  onChange={handleDealsChange}
                                  onKeyDown={(e) => {
                                    const { value } = e.target;
                                    if (
                                      (!/\d/.test(e.key) &&
                                        e.key !== "Backspace" &&
                                        e.key !== "Delete" &&
                                        e.key !== "ArrowLeft" &&
                                        e.key !== "ArrowRight" &&
                                        e.key !== ".") ||
                                      (e.key === "." && value.includes("."))
                                    ) {
                                      e.preventDefault();
                                    }
                                  }}
                                />
                                <p className="mb-0 small erro_msg">
                                  {amtErrorMsg}
                                </p>
                              </div>

                              <div>
                                <div>
                                  Closing Date
                                  <span className="text-danger">*</span>
                                </div>
                                <input
                                  type="date"
                                  className="inputBoxDeal mt-1 px-2"
                                  value={
                                    existingDeal || existingClone
                                      ? convertDateFormat(
                                          dealUpdateInfo.closingDate
                                        )
                                      : convertDateFormat(dealsInfo.closingDate)
                                  }
                                  name="closingDate"
                                  onChange={handleDealsChange}
                                  onKeyDown={(e) => e.preventDefault()}
                                  min="2000-01-01"
                                  max="2050-12-31"
                                />
                                <p className="mb-0 small erro_msg">
                                  {dateErrorMsg}
                                </p>
                              </div>
                            </div>

                            <div className="mt-2 d-flex gap-4">
                              <div>
                                <div>
                                  Stage
                                  <span className="text-danger">*</span>
                                </div>
                                <input
                                  type="text"
                                  className="inputBoxDeal mt-1"
                                  value={
                                    showReason ? "Closed Lost" : "Closed Won"
                                  }
                                  readOnly
                                  style={{ opacity: "0.6" }}
                                />
                              </div>
                              {showReason && (
                                <div style={{ width: "50%" }} className="mt-1 ">
                                  <div>
                                    Reason
                                    <span className="text-danger">*</span>
                                  </div>
                                  <div className="inputBoxDeal">
                                    <Select
                                      value={
                                        existingDeal || existingClone
                                          ? dealUpdateInfo.reasonForLost
                                          : dealsInfo.reasonForLost
                                      }
                                      menuPlacement="auto"
                                      onChange={(selectedOption) =>
                                        handleStageDropDown(
                                          selectedOption,
                                          "reasonForLost"
                                        )
                                      }
                                      options={ReasonOptions}
                                      styles={fieldDropdownStyles}
                                      isSearchable={false}
                                    />
                                  </div>
                                  <p className="mb-0 small erro_msg">
                                    {reasonMsg}
                                  </p>
                                </div>
                              )}
                            </div>

                            <div className="d-flex  justify-content-end w-100 gap-2 pt-3">
                              <button
                                className="cancelButton px-4"
                                onClick={() => {
                                  setIsClosedWonModalOpen(false);
                                  setShowReason(false);
                                  setStage(dealOverviewDetails.stage.value);
                                }}
                              >
                                Cancel
                              </button>
                              <button
                                className="unassignButton px-4"
                                onClick={VerifyDealsInfo}
                              >
                                Save
                              </button>
                            </div>
                          </div>
                        </Modal.Body>
                      </Modal>

                      <div
                        onClick={(e) => {
                          e.stopPropagation();
                          setIsDropdownVisisbleDislike(
                            !isDropdownVisibleDisLike
                          );
                          setIsDropdownVisibleLike(false);
                        }}
                        style={{ cursor: "pointer" }}
                      >
                        <img src={DislikeDropdown} alt="like" />
                      </div>
                      {isDropdownVisibleDisLike && (
                        <div
                          style={{
                            position: "absolute",
                            top: "72%",
                            right: "0%",
                            backgroundColor: "white",
                            borderRadius: "8px",
                            boxShadow: "0 8px 16px rgba(0,0,0,0.2)",
                            zIndex: 1,
                          }}
                        >
                          <ul
                            style={{
                              listStyle: "none",
                              padding: "10px",
                              margin: "0",
                            }}
                          >
                            <li
                              style={{ padding: "8px 12px", cursor: "pointer" }}
                              onClick={() => {
                                setIsClosedWonModalOpen(true);
                                setShowReason(true);
                                setExistingClone(true);
                                setDealUpdateInfo({ ...dealOverviewDetails });
                              }}
                            >
                              Closed Lost
                            </li>
                          </ul>
                        </div>
                      )}
                    </div>
                  </div>

                  <div className="lead_DetailContainer     rounded-2  commonContainer_bg d-flex justify-content-between">
                    <div className="w-100 d-flex flex-column">
                      {fieldLabels.map((field, i) => {
                        const fieldValue = field.name
                          .replace(/\s+/g, "")
                          .replace(/^(.)/, (match) => match.toLowerCase());
                        return field.isShowed ? (
                          <div className="d-flex w-50 ps-3" key={i}>
                            <div className="w-50 position-relative">
                              <div
                                onClick={(e) => {
                                  e.stopPropagation();
                                  setSearchField("");
                                  setSelectedField(
                                    selectedField === field.name
                                      ? ""
                                      : field.name
                                  );
                                }}
                                className={`cursorPointer d-flex justify-content-between w-100 my-1 p-1 ${
                                  selectedField === field.name
                                    ? "outline_border rounded-1"
                                    : ""
                                }`}
                              >
                                <span>{field.name}</span>
                                <img
                                  src={selectedField === field.name ? up : down}
                                  alt="img"
                                  height={20}
                                  width={20}
                                  className="pt-1"
                                />
                              </div>
                              {selectedField === field.name && (
                                <div
                                  className="position-absolute field_container w-100"
                                  onClick={(e) => {
                                    e.stopPropagation();
                                  }}
                                >
                                  <div className="">
                                    <label
                                      htmlFor="selectField"
                                      className="position-relative w-100"
                                    >
                                      <input
                                        id="selectField"
                                        type="text"
                                        className="rounded-2 p-1 w-100 pe-4"
                                        placeholder="Search"
                                        value={searchField}
                                        onChange={(e) => {
                                          if (
                                            e.target.value.charAt(0) !== " "
                                          ) {
                                            const value =
                                              e.target.value.replace(
                                                /[^a-zA-Z ]+|(\s)\s+/g,
                                                "$1"
                                              );
                                            setSearchField(value);
                                          }
                                          // let value = e.target.value;
                                          // value = value.replace(
                                          //   /[^a-zA-Z\s]/g,
                                          //   ""
                                          // );
                                          // if (value.startsWith(" ")) {
                                          //   value = value.trimStart();
                                          // }
                                          // setSearchField(value);

                                          // const trimmedValue = value.trim();
                                          // const filteredData =
                                          //   fieldDropdowns.filter((field) =>
                                          //     field.name
                                          //       .toLowerCase()
                                          //       .includes(
                                          //         trimmedValue.toLowerCase()
                                          //       )
                                          //   );

                                          // setShowSearchFields(
                                          //   /^[a-zA-Z\s]*$/.test(value)
                                          //     ? filteredData
                                          //     : fieldLabels
                                          // );
                                        }}
                                      />
                                      <img
                                        className="position-absolute mt-1 me-2 end-0 pt-1 cursorPointer"
                                        onClick={() => {
                                          setSearchField("");
                                        }}
                                        src={searchIcon}
                                        alt="search"
                                        width={20}
                                      />
                                    </label>

                                    <div className="w-100 rounded-2">
                                      <div className="border border-1 field_container_dropdown rounded-1">
                                        {showSearchFields
                                          .filter((field) =>
                                            field.name
                                              .toLowerCase()
                                              .includes(
                                                searchField.trim().toLowerCase()
                                              )
                                          )
                                          .sort((a, b) =>
                                            a.name.localeCompare(b.name)
                                          )
                                          .map(
                                            (field) =>
                                              (selectedField === field.name ||
                                                !field.isShowed) && (
                                                <div
                                                  key={field.name}
                                                  className="d-flex gap-2 p-2 cursorPointer rounded-2 align-items-center"
                                                  onClick={() => {
                                                    selectedField === field.name
                                                      ? setSelectedField("")
                                                      : handleFieldClick(field);
                                                  }}
                                                >
                                                  <span className="right_tick">
                                                    {selectedField ===
                                                    field.name
                                                      ? tick
                                                      : ""}
                                                  </span>
                                                  <span className="ps-2">
                                                    {field.name}
                                                  </span>
                                                </div>
                                              )
                                          )}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              )}
                            </div>
                            <div className={`w-50 mt-1 ms-5 position-relative`}>
                              {field.type === "input" ? (
                                <EditableInput
                                  className="ps-3"
                                  inputType={fieldValue}
                                  handleIndividualInputChange={
                                    handleIndividualInputChange
                                  }
                                  editingFields={editingFields}
                                  handleEditClick={handleEditClick}
                                  handleCancelClick={handleCancelClick}
                                  handleUpdateClick={handleUpdateClick}
                                  updatedFields={updatedFields}
                                  accountOverviewDetails={dealOverviewDetails}
                                  showType={showType}
                                  setShowType={setShowType}
                                />
                              ) : field.type === "dropdown" ? (
                                <div className="">
                                  {editingFields[fieldValue] ? (
                                    <>
                                      <Select
                                        value={updatedFields[fieldValue]}
                                        menuPlacement="auto"
                                        onChange={(selectedOption) => {
                                          setClickedDropdownObj(selectedOption);
                                          setUpdatedFields((prevFields) => ({
                                            ...prevFields,
                                            [fieldValue]: selectedOption,
                                          }));
                                        }}
                                        options={
                                          field.name === "Lead Source"
                                            ? leadSourceOptions
                                            : field.name === "Stage"
                                            ? stageOptions
                                            : field.name === "Type"
                                            ? typeOptions
                                            : industryOptions
                                        }
                                        styles={fieldDropdownStyles}
                                        isSearchable={false}
                                      />
                                      <div
                                        className="position-absolute end-0 top-0 mt-1"
                                        style={{ marginRight: "-3.5rem" }}
                                      >
                                        <div className="d-flex gap-2 ">
                                          <span
                                            onClick={(e) => {
                                              e.stopPropagation();
                                              setShowType("");
                                              handleUpdateClick(fieldValue);
                                            }}
                                            className="ps-2 cursorPointer"
                                          >
                                            <img
                                              src={updateIcon}
                                              alt="img"
                                              height={20}
                                              width={20}
                                            />
                                          </span>
                                          <span
                                            onClick={(e) => {
                                              e.stopPropagation();
                                              setShowType("");
                                              handleCancelClick(fieldValue);
                                            }}
                                            className="cursorPointer"
                                          >
                                            <img
                                              src={cancelIcon}
                                              alt="img"
                                              height={20}
                                              width={20}
                                            />
                                          </span>
                                        </div>
                                      </div>
                                    </>
                                  ) : (
                                    <input
                                      type="text"
                                      readOnly
                                      value={
                                        dealOverviewDetails[fieldValue]?.value
                                      }
                                      onClick={() => {
                                        handleEditClick(fieldValue);
                                      }}
                                      className="w-100 bg-transparent border rounded-1 p-1"
                                    />
                                  )}
                                </div>
                              ) : field.type === "readOnly" ? (
                                <div>
                                  <span className="d-flex flex-column">
                                    <span>
                                      {
                                        dealOverviewDetails[
                                          field.name === "Created By"
                                            ? "createdBy"
                                            : field.name === "Modified By"
                                            ? "modifiedBy"
                                            : field.name === "Probability"
                                            ? "probability"
                                            : field.name === "Expected Revenue"
                                            ? "expectedRevenue"
                                            : "accountName"
                                        ]
                                      }
                                    </span>
                                    <span className="small opacity-75">
                                      {field.name === "Created By" ||
                                      field.name === "Modified By"
                                        ? dealOverviewDetails[
                                            field.name === "Created By"
                                              ? "createdDate"
                                              : "modifiedDate"
                                          ]
                                        : ""}
                                    </span>
                                  </span>
                                </div>
                              ) : (
                                <div className="position-relative">
                                  <div
                                    className="d-flex input-with-clear position-relative"
                                    onClick={() => {
                                      setShowModalType("changeOwner");
                                      // setSelectedOwnerItem(selectedItem);
                                    }}
                                  >
                                    <input
                                      type="text"
                                      //   placeholder="Vendor Owner"
                                      className="w-100 cursorPointer  indvEditInput border-0 indivOwnerInput"
                                      value={selectedItem?.name}
                                      readOnly
                                    />
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                        ) : null;
                      })}
                    </div>
                  </div>

                  {/*------ lead Information Conatiner -----------*/}

                  <div className="lead_Information_mainContainer commonContainer_bg     rounded-2  d-flex flex-column my-2">
                    <h5 className="w-100 text-primary px-1 pt-1">
                      Deal Information
                    </h5>

                    <div className="leadInformation_content_container d-flex justify-content-between pe-4">
                      <div className="leadContact_container d-flex gap-2 w-100 ps-2 pe-5">
                        <div className="w-50">
                          <div className="d-flex justify-content-between align-items-center my-2 w-100">
                            <div className="opacity-75 p-2 w-75">
                              Deal Owner
                            </div>
                            <div className="w-100">
                              <div className="position-relative">
                                <div
                                  className="d-flex input-with-clear position-relative"
                                  onClick={() => {
                                    setShowModalType("changeOwner");
                                    // setSelectedOwnerItem(selectedItem);
                                  }}
                                >
                                  <input
                                    type="text"
                                    className="w-100 cursorPointer  indvEditInput border-0 "
                                    // value={"okk"}
                                    value={selectedItem?.name}
                                    readOnly
                                  />
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="d-flex justify-content-between align-items-center my-2 w-100">
                            <div className="opacity-75 p-2 w-75">Deal Name</div>
                            <div className="w-100">
                              <EditableInput
                                inputType="dealInfoDealName"
                                handleIndividualInputChange={
                                  handleIndividualInputChange
                                }
                                editingFields={editingFields}
                                handleEditClick={handleEditClick}
                                handleCancelClick={handleCancelClick}
                                handleUpdateClick={handleUpdateClick}
                                updatedFields={updatedFields}
                                accountOverviewDetails={dealOverviewDetails}
                                showType={showType}
                                setShowType={setShowType}
                              />
                            </div>
                          </div>

                          <div className="d-flex justify-content-between align-items-center my-2 w-100">
                            <div className="opacity-75 p-2 w-75">
                              Account Name
                            </div>
                            <div className="w-100">
                              <span>{dealOverviewDetails?.accountName}</span>
                            </div>
                          </div>
                          <div className="d-flex justify-content-between align-items-center my-2 w-100">
                            <div className="opacity-75 p-2 w-75">Type</div>
                            <div className="w-100">
                              {editingFields["type"] && showType === "type" ? (
                                <div className="position-relative">
                                  <Select
                                    value={updatedFields.type}
                                    menuPlacement="auto"
                                    onChange={(selectedOption) =>
                                      handleChangeIndDropDown(
                                        selectedOption,
                                        "type"
                                      )
                                    }
                                    options={typeOptions}
                                    styles={fieldDropdownStyles}
                                    isSearchable={false}
                                  />
                                  {editingFields["type"] &&
                                    showType === "type" && (
                                      <div
                                        className="position-absolute top-0"
                                        style={{ right: "-3.5rem" }}
                                      >
                                        <div className="d-flex gap-2">
                                          <span
                                            onClick={() => {
                                              setShowType("");
                                              handleUpdateClick("type");
                                            }}
                                            className="ps-2 cursorPointer"
                                          >
                                            <img
                                              src={updateIcon}
                                              alt="img"
                                              height={20}
                                              width={20}
                                            />
                                          </span>
                                          <span
                                            onClick={() => {
                                              setShowType("");
                                              // handleUpdateClick(inputType);
                                              handleCancelClick("type");
                                            }}
                                            className="cursorPointer"
                                          >
                                            <img
                                              src={cancelIcon}
                                              alt="img"
                                              height={20}
                                              width={20}
                                            />
                                          </span>
                                        </div>
                                      </div>
                                    )}
                                </div>
                              ) : (
                                <input
                                  type="text"
                                  className={`mt-1 cursorPointer indvEditInput
                            ${
                              showType === "type"
                                ? "selectedBorder "
                                : "unselectBorder bg-transparent"
                            }`}
                                  value={dealOverviewDetails?.type?.value}
                                  name={"type"}
                                  onClick={(e) => {
                                    if (showType === "") {
                                      updatedFields.type =
                                        dealOverviewDetails.type;
                                      setShowType(e.target.name);
                                      setUpdatedFields(updatedFields);
                                    }
                                    handleEditClick("type");
                                  }}
                                  readOnly={true}
                                />
                              )}
                            </div>
                          </div>

                          <div className="d-flex justify-content-between align-items-center my-2 w-100">
                            <div className="opacity-75 p-2 w-75">Next Step</div>
                            <div className="w-100">
                              <EditableInput
                                inputType="dealInfoNext"
                                handleIndividualInputChange={
                                  handleIndividualInputChange
                                }
                                editingFields={editingFields}
                                handleEditClick={handleEditClick}
                                handleCancelClick={handleCancelClick}
                                handleUpdateClick={handleUpdateClick}
                                updatedFields={updatedFields}
                                accountOverviewDetails={dealOverviewDetails}
                                showType={showType}
                                setShowType={setShowType}
                              />
                            </div>
                          </div>

                          <div className="d-flex justify-content-between align-items-center my-2 w-100">
                            <div className="opacity-75 p-2 w-75">
                              Lead Source
                            </div>
                            <div className="w-100">
                              {editingFields["leadSource"] &&
                              showType === "leadSource" ? (
                                <div className="position-relative">
                                  <Select
                                    value={updatedFields.leadSource}
                                    menuPlacement="auto"
                                    onChange={(selectedOption) =>
                                      handleChangeIndDropDown(
                                        selectedOption,
                                        "leadSource"
                                      )
                                    }
                                    options={leadSourceOptions}
                                    styles={fieldDropdownStyles}
                                    isSearchable={false}
                                  />
                                  {editingFields["leadSource"] &&
                                    showType === "leadSource" && (
                                      <div
                                        className="position-absolute top-0"
                                        style={{ right: "-3.5rem" }}
                                      >
                                        <div className="d-flex gap-2">
                                          <span
                                            onClick={() => {
                                              setShowType("");
                                              handleUpdateClick("leadSource");
                                            }}
                                            className="ps-2 cursorPointer"
                                          >
                                            <img
                                              src={updateIcon}
                                              alt="img"
                                              height={20}
                                              width={20}
                                            />
                                          </span>
                                          <span
                                            onClick={() => {
                                              setShowType("");
                                              // handleUpdateClick(inputType);
                                              handleCancelClick("leadSource");
                                            }}
                                            className="cursorPointer"
                                          >
                                            <img
                                              src={cancelIcon}
                                              alt="img"
                                              height={20}
                                              width={20}
                                            />
                                          </span>
                                        </div>
                                      </div>
                                    )}
                                </div>
                              ) : (
                                <input
                                  type="text"
                                  className={`mt-1 cursorPointer indvEditInput
                            ${
                              showType === "leadSource"
                                ? "selectedBorder "
                                : "unselectBorder bg-transparent"
                            }`}
                                  value={dealOverviewDetails?.leadSource?.value}
                                  name={"leadSource"}
                                  onClick={(e) => {
                                    if (showType === "") {
                                      updatedFields.leadSource =
                                        dealOverviewDetails.leadSource;
                                      setShowType(e.target.name);
                                      setUpdatedFields(updatedFields);
                                    }
                                    handleEditClick("leadSource");
                                  }}
                                  readOnly={true}
                                />
                              )}
                            </div>
                          </div>

                          <div className="d-flex justify-content-between align-items-center my-2 w-100">
                            <div className="opacity-75 p-2 w-75">
                              Contact Name
                            </div>
                            <div className="w-100">
                              <span>{dealOverviewDetails?.contactName}</span>
                            </div>
                          </div>
                          <div className="d-flex justify-content-between align-items-center my-2 w-100">
                            <div className="opacity-75 p-2 w-75">
                              Modified By
                            </div>
                            <div className="w-100">
                              <span className="d-flex flex-column px-0">
                                <span className="pb-0 px-0">
                                  {dealOverviewDetails?.modifiedBy}
                                </span>
                                <span className="opacity-50 small pt-0 px-0">
                                  {dealOverviewDetails?.modifiedDate}
                                </span>
                              </span>
                            </div>
                          </div>
                        </div>
                        <div className="w-50 ps-5 pe-0">
                          <div className="d-flex justify-content-between align-items-center my-2 w-100">
                            <div className="opacity-75 p-2 w-100">Amount</div>
                            <div className="w-100">
                              <EditableInput
                                inputType="dealInfoAmt"
                                handleIndividualInputChange={
                                  handleIndividualInputChange
                                }
                                editingFields={editingFields}
                                handleEditClick={handleEditClick}
                                handleCancelClick={handleCancelClick}
                                handleUpdateClick={handleUpdateClick}
                                updatedFields={updatedFields}
                                accountOverviewDetails={dealOverviewDetails}
                                showType={showType}
                                setShowType={setShowType}
                              />
                            </div>
                          </div>
                          <div className="d-flex justify-content-between align-items-center my-2 w-100">
                            <div className="opacity-75 p-2 w-100">
                              Closing Date
                            </div>
                            <div className="w-100">
                              <EditableInput
                                inputType="dealInfoClosingDate"
                                handleIndividualInputChange={
                                  handleIndividualInputChange
                                }
                                editingFields={editingFields}
                                handleEditClick={handleEditClick}
                                handleCancelClick={handleCancelClick}
                                handleUpdateClick={handleUpdateClick}
                                updatedFields={updatedFields}
                                accountOverviewDetails={dealOverviewDetails}
                                showType={showType}
                                setShowType={setShowType}
                              />
                            </div>
                          </div>
                          <div className="d-flex justify-content-between align-items-center my-2 w-100">
                            <div className="opacity-75 p-2 w-100">Stage</div>
                            <div className="w-100">
                              {editingFields["stage"] &&
                              showType === "stage" ? (
                                <div className="position-relative">
                                  <Select
                                    value={updatedFields.stage}
                                    menuPlacement="auto"
                                    onChange={(selectedOption) => {
                                      handleChangeIndDropDown(
                                        selectedOption,
                                        "stage"
                                      );
                                      setDealUpdateInfo({
                                        ...dealOverviewDetails,
                                      });
                                      if (
                                        ["Closed Won"].includes(
                                          selectedOption.value
                                        )
                                      ) {
                                        setIsClosedWonModalOpen(true);
                                        setIsDropdownVisibleLike(false);
                                        setExistingClone(true);
                                      } else if (
                                        [
                                          "Closed Lost",
                                          "Closed Lost to Competition",
                                        ].includes(selectedOption.value)
                                      ) {
                                        setIsClosedWonModalOpen(true);
                                        setIsDropdownVisisbleDislike(false);
                                        setShowReason(true);
                                      }
                                    }}
                                    options={stageOptions}
                                    styles={fieldDropdownStyles}
                                    isSearchable={false}
                                  />
                                  {editingFields["stage"] &&
                                    showType === "stage" && (
                                      <div
                                        className="position-absolute top-0"
                                        style={{ right: "-3.5rem" }}
                                      >
                                        <div className="d-flex gap-2">
                                          <span
                                            onClick={() => {
                                              setShowType("");
                                              handleUpdateClick("stage");
                                            }}
                                            className="ps-2 cursorPointer"
                                          >
                                            <img
                                              src={updateIcon}
                                              alt="img"
                                              height={20}
                                              width={20}
                                            />
                                          </span>
                                          <span
                                            onClick={() => {
                                              setShowType("");
                                              // handleUpdateClick(inputType);
                                              handleCancelClick("stage");
                                            }}
                                            className="cursorPointer"
                                          >
                                            <img
                                              src={cancelIcon}
                                              alt="img"
                                              height={20}
                                              width={20}
                                            />
                                          </span>
                                        </div>
                                      </div>
                                    )}
                                </div>
                              ) : (
                                <input
                                  type="text"
                                  className={`mt-1 cursorPointer indvEditInput
                            ${
                              showType === "stage"
                                ? "selectedBorder "
                                : "unselectBorder bg-transparent"
                            }`}
                                  value={dealOverviewDetails?.stage?.label}
                                  name={"stage"}
                                  onClick={(e) => {
                                    if (showType === "") {
                                      updatedFields.stage =
                                        dealOverviewDetails.stage;
                                      setShowType(e.target.name);
                                      setUpdatedFields(updatedFields);
                                    }
                                    handleEditClick("stage");
                                  }}
                                  readOnly={true}
                                />
                              )}
                            </div>
                          </div>

                          <div className="d-flex justify-content-between align-items-center my-2 w-100">
                            <div className="opacity-75 p-2 w-100">
                              Probability (%)
                            </div>
                            <div className="w-100">
                              <span>{dealOverviewDetails.probability}</span>
                            </div>
                          </div>
                          <div className="d-flex justify-content-between align-items-center my-2 w-100">
                            <div className="opacity-75 p-2 w-100">
                              Expected Revenue
                            </div>
                            <div className="w-100">
                              <span>{dealOverviewDetails.expectedRevenue}</span>
                            </div>
                          </div>
                          <div className="d-flex justify-content-between align-items-center my-2 w-100">
                            <div className="opacity-75 p-2 w-100">
                              Campaign Source
                            </div>
                            <div className="w-100">
                              <span>{dealOverviewDetails.campaignName}</span>
                            </div>
                          </div>
                          <div className="d-flex justify-content-between align-items-center my-2 w-100">
                            <div className="opacity-75 p-2 w-100">
                              Created By
                            </div>
                            <div className="w-100">
                              <span className="d-flex flex-column px-0">
                                <span className="pb-0 px-0">
                                  {dealOverviewDetails.createdBy}
                                </span>
                                <span className="opacity-50 small pt-0 px-0">
                                  {dealOverviewDetails.createdDate}
                                </span>
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="lead_Information_mainContainer commonContainer_bg     rounded-2  d-flex flex-column my-2">
                    <h5 className="w-100 text-primary px-1 pt-1">
                      Description
                    </h5>
                    <div className="d-flex gap-2 p-3">
                      <span className="opacity-75 px-1">Description</span>
                      {/* <p>{dealOverviewDetails.description}</p> */}
                      <EditableInput
                        fieldType="text"
                        inputType="description"
                        handleIndividualInputChange={
                          handleIndividualInputChange
                        }
                        editingFields={editingFields}
                        handleEditClick={handleEditClick}
                        handleCancelClick={handleCancelClick}
                        handleUpdateClick={handleUpdateClick}
                        updatedFields={updatedFields}
                        accountOverviewDetails={dealOverviewDetails}
                        showType={showType}
                        setShowType={setShowType}
                        isTextarea={true}
                      />
                    </div>
                  </div>
                </div>
                {notePermission === "TRUE" && (
                  <div
                    id="notesSection"
                    className="lead_Information_mainContainer commonContainer_bg rounded-2  d-flex flex-column py-2 mt-2"
                  >
                    <Note
                      note={note}
                      addTitle={addTitle}
                      showButtons={showButtons}
                      setShowButtons={setShowButtons}
                      textFile={selectedNoteFiles}
                      showTitleNote={showTitleNote}
                      setShowTitleNote={setShowTitleNote}
                      handleTextAreaClick={handleTextAreaClick}
                      handleTextFileChange={handleTextFileChange}
                      handleSaveButton={insertNote}
                      handleAddTitleClick={handleAddTitleClick}
                      setNoteTitle={setNoteTitle}
                      noteInfo={noteInfo}
                      selectRecent={selectRecent}
                      handleSelectRecent={handleSelectRecent}
                      handleDeleteClick={deleteNoteDetails}
                      leadId={dealId}
                      handleUpdateClick={updateNote}
                      setUpdateNotes={setUpdateNotes}
                      updateNotes={updateNotes}
                      setEditNote={setEditNote}
                      editNote={editNote}
                      handleChangeNoteTitle={handleChangeNoteTitle}
                      handleChangeNoteDescription={handleChangeNoteDescription}
                      setAddTitle={setAddTitle}
                      setNote={setNote}
                      moduleType={"Deal"}
                      recordName={dealOverviewDetails?.dealName}
                      selectedNoteFiles={selectedNoteFiles}
                      setSelectedNoteFiles={setSelectedNoteFiles}
                      onChildDataChange={handleChildDataChange}
                      noteDeleteConfirmation={noteDeleteConfirmation}
                      setNoteDeleteConfirmation={setNoteDeleteConfirmation}
                    />

                    {showTitleNote && (
                      <textarea
                        rows={2}
                        className="notesClass px-2"
                        placeholder="Add note Title"
                        value={noteTitle}
                        onChange={(e) => setNoteTitle(e.target.value)}
                        style={{ width: "95%", marginTop: "10px" }}
                      />
                    )}
                  </div>
                )}

                <div
                  id="stageHistory"
                  className="lead_Information_mainContainer commonContainer_bg rounded-2  d-flex flex-column py-2 mt-2"
                >
                  <StageHistoryList stageHistoryList={stageHistoryList} />
                </div>
                <div
                  id="openActivity"
                  className="lead_Information_mainContainer commonContainer_bg rounded-2  d-flex flex-column py-2 mt-2"
                >
                  <OpenActivity
                    openActivities={openActivities}
                    getOverview={() => accountOverviewDetail(accountId)}
                    openActivitiesCalls={openActivitiesCalls}
                    openActivitiesMeetings={openActivitiesMeetings}
                    moduleName="Deals"
                  />
                </div>

                <div
                  id="closeActivity"
                  className="lead_Information_mainContainer commonContainer_bg rounded-2  d-flex flex-column py-2 mt-2"
                >
                  <CloseActivity
                    closeActivities={closeActivities}
                    getOverview={() => accountOverviewDetail(accountId)}
                    closeActivitiesCalls={closedActivitiesCall}
                    closeActivitiesMeetings={closedActivitiesMeetings}
                  />
                </div>

                <div
                  id="product"
                  className="lead_Information_mainContainer commonContainer_bg rounded-2  d-flex flex-column py-2 mt-2"
                >
                  <Product
                    module={"Deals"}
                    productsList={productsList}
                    assignContactID={dealId}
                    handleAssignProductModule={handleAssignProductModule}
                    selectedProductId={selectedProductId}
                    handledeleteModule={(productId) =>
                      handledeleteModule(productId)
                    }
                    setSelectedProductId={setSelectedProductId}
                    apiLoader={apiLoader}
                  />
                </div>
                <div id="quotes">
                  <QuotesCommonTable
                    title="Quotes"
                    buttonLabel="New"
                    buttonAction={() => {
                      setCurrentView("quotes");
                      setInnerPage("create");
                      setPastViewInfo((prev) => ({
                        ...prev,
                        view: currentView,
                        innerPage: innerPage,
                        undo: null,
                      }));
                    }}
                    showHeadings={showHeadingsQuotes}
                    setShowHeadings={setShowHeadingsQuotes}
                    selectedHeadings={selectedHeadingsQuotes}
                    data={filterQuotesDetails}
                    onRowClick={() => {}}
                    manageColumnsAction={() => {
                      setShowModalType("Manage Quotes Columns");
                      setSearchColumn("");
                      setSavingHeadings(quotesColumn);
                    }}
                    resetColumnSizeAction={() => {}}
                    setShowSelectedFilter={setShowSelectedFilter}
                    setSelectedQuoteId={setSelectedQuoteId}
                    handleAssignQuoteModule={handleAssignQuoteModule}
                    selectedQuoteId={selectedQuoteId}
                    setSearchColumn={setSearchColumn}
                    setSavingHeadings={setSavingHeadings}
                  />
                </div>

                <div id="salesOrders">
                  <SalesCommonTable
                    title="Sales Orders"
                    buttonLabel="New"
                    buttonAction={() => {
                      setCurrentView("salesOrders");
                      setInnerPage("create");
                      setPastViewInfo((prev) => ({
                        ...prev,
                        view: currentView,
                        innerPage: innerPage,
                        undo: null,
                      }));
                    }}
                    showHeadings={showHeadingsSales}
                    setShowHeadings={setShowHeadingsSales}
                    selectedHeadings={selectedHeadingsSales}
                    data={filterSalesDetails}
                    onRowClick={() => {}}
                    manageColumnsAction={() => {
                      setShowModalType("Manage Sales Columns");
                      setSearchColumn("");
                      setSavingHeadings(salesColumns);
                    }}
                    resetColumnSizeAction={() => {}}
                    setShowSelectedFilter={setShowSelectedFilter}
                    setSelectedSalesId={setSelectedSalesId}
                    handleAssignSalesModule={handleAssignSalesModule}
                    selectedSalesId={selectedSalesId}
                    setSearchColumn={setSearchColumn}
                    setSavingHeadings={setSavingHeadings}
                  />
                </div>

                <div id="invoices">
                  <InvoicesCommonTable
                    title="Invoices"
                    buttonLabel="New"
                    buttonAction={() => {
                      setCurrentView("invoices");
                      setInnerPage("create");
                      setPastViewInfo((prev) => ({
                        ...prev,
                        view: currentView,
                        innerPage: innerPage,
                        undo: null,
                      }));
                    }}
                    showHeadings={showHeadingsInvoices}
                    setShowHeadings={setShowHeadingsInvoices}
                    selectedHeadings={selectedHeadingsInvoices}
                    data={filterInvoicesDetails}
                    onRowClick={() => {}}
                    manageColumnsAction={() => {
                      setShowModalType("Manage Invoices Columns");
                      setSearchColumn("");
                      setSavingHeadings(invoiceColumns);
                    }}
                    resetColumnSizeAction={() => {}}
                    setShowSelectedFilter={setShowSelectedFilter}
                    selectedInvoiceId={selectedInvoiceId}
                    setSelectedInvoiceId={setSelectedInvoiceId}
                    handleAssignInvoiceModule={handleAssignInvoiceModule}
                    setSearchColumn={setSearchColumn}
                    setSavingHeadings={setSavingHeadings}
                  />
                </div>
              </div>
            ) : (
              <div
                className={`timeline_main_container d-flex flex-column gap-2 ${
                  isShrinked ? "w-100" : "w-75"
                }`}
              >
                {/* <div className="upcomingAction_container w-100 flex-column  pt-2  d-flex  commonContainer_bg rounded-2">
                  <div className="header d-flex gap-2 ps-2">
                    <span>
                      <img src={hierachy} alt="img" height={20} width={20} />
                    </span>
                    <span>Upcoming Actions</span>
                  </div>
                  <div className="horizontal_line w-100 opacity-25 mx-1"></div>

                  <div
                    className="d-flex justify-content-center align-items-center "
                    style={{ height: "25vh" }}
                  >
                    <h4 className="opacity-75 ">No upcoming actions</h4>
                  </div>
                </div> */}

                <div className="w-100 historyMain_Container flex-column   d-flex  commonContainer_bg rounded-2 ">
                  <div className="header d-flex gap-2 px-2 py-2 align-items-center">
                    <span>
                      <img src={history} alt="img" height={20} width={20} />
                    </span>
                    <span>History</span>
                  </div>
                  <div className="horizontal_line w-100 mx-1"></div>
                  <div className="historyContent_mainContainer ">
                    {timeLineDataList &&
                    Object.keys(timeLineDataList).length > 0 ? (
                      Object.keys(timeLineDataList).map((date) => (
                        <div
                          className="history_container pt-3 d-flex flex-column"
                          key={date}
                        >
                          <span className="px-2 timelineBg_color border border-primary rounded-5 py-2 mx-5">
                            {date}
                          </span>
                          {timeLineDataList[date].map((item, index) => (
                            <div className="d-flex gap-2" key={index}>
                              <div className="opacity-75 pt-5 ps-2 text-nowrap">
                                {item.createdTime}
                              </div>
                              <div className="d-flex flex-column align-items-center">
                                <p className="vertical_line m-0 p-0"></p>
                                <span>
                                  <img
                                    src={groupIcon2}
                                    alt="img"
                                    height={25}
                                    width={25}
                                  />
                                </span>
                              </div>
                              <div className="pt-5">
                                {`${item.tmlDescription} ${item.tmlCreatedBy} ${date}`}
                              </div>
                            </div>
                          ))}
                        </div>
                      ))
                    ) : (
                      <div className="text-center d-flex justify-content-center align-items-center h-50 mt-2">
                        <h4>No Data available</h4>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  };

  const handleColumnCheckboxChange = (name, isChecked) => {
    setSavingHeadings((prevHeadings) =>
      prevHeadings.map((heading) =>
        heading.name === name ? { ...heading, isChecked } : heading
      )
    );
  };

  const handleChangeOwnerInputClick = (e) => {
    e.stopPropagation();
    setIsChangeOwnerList(!isChangeOwnerList);

    // setInvidualListVisible(!individualListVisible);
  };

  const handleChangeOwnerItemClick = (item) => {
    setSelectedOwnerItem(item);
    setIsChangeOwnerList(false);
    setIsOwnerSelect(true);
    // setIsSelectedOwner(false);
  };

  const handleDealView = () => {
    if (innerPage === "list") {
      return <>{dealHomePage()}</>;
    } else if (["create", "clone"].includes(innerPage)) {
      return <>{createDealPage()}</>;
    } else if (innerPage === "overview") {
      return <>{individualDealDetailPage()}</>;
    }
  };
  useEffect(() => {
    getAllDealsDetails(currentPage, selectedOption);
  }, [currentPage, perPage]);

  useEffect(() => {
    getAllContact();
  }, [currentPageContact, perPageContact]);

  useEffect(() => {
    getAllAccountDetails();
  }, [currentPageAccount, perPageAccount]);

  const handleColumnCheckboxChangeQuotes = (name, isChecked) => {
    setSavingHeadingsQuotes((prevHeadings) =>
      prevHeadings.map((heading) =>
        heading.name === name ? { ...heading, isChecked } : heading
      )
    );
  };

  const handleColumnCheckboxChangeSales = (name, isChecked) => {
    setSavingHeadingsSales((prevHeadings) =>
      prevHeadings.map((heading) =>
        heading.name === name ? { ...heading, isChecked } : heading
      )
    );
  };

  const handleColumnCheckboxChangeInvoices = (name, isChecked) => {
    setSavingHeadingsInvoices((prevHeadings) =>
      prevHeadings.map((heading) =>
        heading.name === name ? { ...heading, isChecked } : heading
      )
    );
  };

  useEffect(() => {
    const foundItem = userList.find((item) => item.userId === loginUserId);
    if (foundItem) {
      setSelectedItem(foundItem);
    }
  }, [loginUserId]);

  return (
    <div
      onClick={() => {
        setShowHeadings(false);
        setShowSelectedFilter("");
        setSelectedField("");
        setListVisible(false);
        setAccountListVisible(false);
        setContactListVisible(false);
        setShowOptionsDeal(false);
        setIsDropdownVisibleLike(false);
        setIsDropdownVisisbleDislike(false);
        setParentListVisible(false);
      }}
    >
      {handleDealView()}

      {/* Modals */}
      <Modal
        show={showModalType === "Manage Columns"}
        id="ConvertModal"
        aria-labelledby="example-custom-modal-styling-title"
        className="withdrw_modal"
      >
        <Modal.Body>
          <div className="modalClass  position-relative">
            <div className="d-flex justify-content-between ">
              <h4 className="">Manage Columns</h4>
            </div>
            <div className="w-100 position-relative">
              <div className="mb-1 pt-2 position-relative w-100">
                <input
                  type="text"
                  className="assetSearchbar searchbar rounded-2 p-2 pe-5 common_input_bg w-100 "
                  placeholder="Search"
                  aria-label="Username"
                  aria-describedby="basic-addon1"
                  value={searchColumn}
                  onChange={(e) => {
                    if (e.target.value.charAt(0) !== " ") {
                      const value = e.target.value.replace(
                        /[^a-zA-Z ]+|(\s)\s+/g,
                        "$1"
                      );
                      setSearchColumn(value);
                    }
                  }}
                />
                <span
                  className="position-absolute  mt-2 me-2 end-0 opacity-75"
                  onClick={() => {
                    setSearchColumn("");
                    setSavingHeadings(dealsColumn);
                  }}
                >
                  {searchColumn === "" ? (
                    <img src={searchIcon} alt="" width={20} />
                  ) : (
                    <div style={{ cursor: "pointer" }}>X</div>
                  )}
                </span>
              </div>
              <div className=" opacity-75 columns_container">
                {savingHeadings
                  .filter((lead) =>
                    lead.name
                      .toLowerCase()
                      .includes(searchColumn.trim().toLowerCase())
                  )
                  .sort((a, b) => a.name.localeCompare(b.name))
                  .map((column) => {
                    return (
                      <div
                        key={column.name}
                        className="d-flex gap-2 py-2 rounded-2 align-items-center"
                      >
                        <div className="d-flex gap-2 ps-2">
                          <input
                            type="checkbox"
                            id={column.name}
                            name={column.name}
                            checked={column.isChecked}
                            onChange={(e) => {
                              handleColumnCheckboxChange(
                                column.name,
                                e.target.checked
                              );
                            }}
                            className="cursorPointer"
                          />
                          <label
                            className="small cursorPointer"
                            htmlFor={column.name}
                          >
                            {column.name}
                          </label>
                        </div>
                      </div>
                    );
                  })}
              </div>
              <aside className="float-end mt-2">
                <div className="d-flex align-items-center gap-4">
                  <button
                    className="cancel_btn bg-light"
                    onClick={() => {
                      setShowModalType("");
                      // setSavingHeadings(dealsColumn);
                      // setSearchColumn("");
                    }}
                  >
                    Cancel
                  </button>
                  <button
                    className="save_btn"
                    onClick={() => {
                      setSelectedHeadings(savingHeadings);
                      setTimeout(() => {
                        setShowModalType("");
                      }, 200);
                    }}
                  >
                    Save
                  </button>
                </div>
              </aside>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      {/* -------------------- */}
      <Modal
        show={showModalType === "Manage Quotes Columns"}
        id="ConvertModal"
        aria-labelledby="example-custom-modal-styling-title"
        className="withdrw_modal"
      >
        <Modal.Body>
          <div className="modalClass  position-relative">
            <div className="d-flex justify-content-between ">
              <h4 className="">Manage Columns</h4>
              {/* <img
                src={crossIcon}
                alt="cross"
                style={{ cursor: "pointer" }}
                className="ms-1"
                onClick={() => {
                  setShowModalType("");
                }}
              /> */}
            </div>
            <div className="w-100 position-relative">
              <div className="mb-1 pt-2 position-relative w-100">
                <input
                  type="text"
                  className="assetSearchbar searchbar rounded-2 p-2 pe-5 common_input_bg w-100 "
                  placeholder="Search"
                  aria-label="Username"
                  aria-describedby="basic-addon1"
                  value={searchColumn}
                  onChange={(e) => {
                    if (e.target.value.charAt(0) !== " ") {
                      const value = e.target.value.replace(
                        /[^a-zA-Z ]+|(\s)\s+/g,
                        "$1"
                      );
                      setSearchColumn(value);
                      // const list = quotesColumn.filter((contact) =>
                      //   contact.name
                      //     .toLowerCase()
                      //     .includes(value.trim().toLowerCase())
                      // );
                      // setSavingHeadingsQuotes(list);
                    }
                  }}
                />
                <span
                  className="position-absolute  mt-2 me-2 end-0 opacity-75"
                  onClick={() => {
                    setSearchColumn("");
                    setSavingHeadings(quotesColumn);
                  }}
                >
                  {searchColumn === "" ? (
                    <img src={searchIcon} alt="" width={20} />
                  ) : (
                    <div style={{ cursor: "pointer" }}>X</div>
                  )}
                </span>
              </div>
              <div className=" opacity-75 columns_container">
                {savingHeadingsQuotes
                  .filter((lead) =>
                    lead.name
                      .toLowerCase()
                      .includes(searchColumn.trim().toLowerCase())
                  )
                  .sort((a, b) => a.name.localeCompare(b.name))
                  .map((column) => {
                    return (
                      <div
                        key={column.name}
                        className="d-flex gap-2 py-2 rounded-2 align-items-center"
                      >
                        <div className="d-flex gap-2 ps-2">
                          <input
                            type="checkbox"
                            id={column.name}
                            name={column.name}
                            checked={column.isChecked}
                            onChange={(e) => {
                              handleColumnCheckboxChangeQuotes(
                                column.name,
                                e.target.checked
                              );
                            }}
                            className="cursorPointer"
                          />
                          <label
                            className="small cursorPointer"
                            htmlFor={column.name}
                          >
                            {column.name}
                          </label>
                        </div>
                      </div>
                    );
                  })}
              </div>
              <aside className="float-end mt-2">
                <div className="d-flex align-items-center gap-4">
                  <button
                    className="cancel_btn bg-light"
                    onClick={() => {
                      setShowModalType("");
                    }}
                  >
                    Cancel
                  </button>
                  <button
                    className="save_btn"
                    onClick={() => {
                      setSelectedHeadingsQuotes(savingHeadingsQuotes);
                      setTimeout(() => {
                        setShowModalType("");
                      }, 200);
                    }}
                  >
                    Save
                  </button>
                </div>
              </aside>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      {/* -------------------- */}
      {/* -------------------- */}
      <Modal
        show={showModalType === "Manage Sales Columns"}
        id="ConvertModal"
        aria-labelledby="example-custom-modal-styling-title"
        className="withdrw_modal"
      >
        <Modal.Body>
          <div className="modalClass  position-relative">
            <div className="d-flex justify-content-between ">
              <h4 className="">Manage Columns</h4>
              {/* <img
                src={crossIcon}
                alt="cross"
                style={{ cursor: "pointer" }}
                className="ms-1"
                onClick={() => {
                  setShowModalType("");
                }}
              /> */}
            </div>
            <div className="w-100 position-relative">
              <div className="mb-1 pt-2 position-relative w-100">
                <input
                  type="text"
                  className="assetSearchbar searchbar rounded-2 p-2 pe-5 common_input_bg w-100 "
                  placeholder="Search"
                  aria-label="Username"
                  aria-describedby="basic-addon1"
                  value={searchColumn}
                  onChange={(e) => {
                    if (e.target.value.charAt(0) !== " ") {
                      const value = e.target.value.replace(
                        /[^a-zA-Z ]+|(\s)\s+/g,
                        "$1"
                      );
                      setSearchColumn(value);
                      // const list = salesColumns.filter((contact) =>
                      //   contact.name
                      //     .toLowerCase()
                      //     .includes(value.trim().toLowerCase())
                      // );
                      // setSelectedHeadingsSales(list);
                    }
                  }}
                />
                <span
                  className="position-absolute  mt-2 me-2 end-0 opacity-75"
                  onClick={() => {
                    setSearchColumn("");
                    setSavingHeadings(salesColumns);
                  }}
                >
                  {searchColumn === "" ? (
                    <img src={searchIcon} alt="" width={20} />
                  ) : (
                    <div style={{ cursor: "pointer" }}>X</div>
                  )}
                </span>
              </div>
              <div className=" opacity-75 columns_container">
                {savingHeadingsSales
                  .filter((lead) =>
                    lead.name
                      .toLowerCase()
                      .includes(searchColumn.trim().toLowerCase())
                  )
                  .sort((a, b) => a.name.localeCompare(b.name))
                  .map((column) => {
                    return (
                      <div
                        key={column.name}
                        className="d-flex gap-2 py-2 rounded-2 align-items-center"
                      >
                        <div className="d-flex gap-2 ps-2">
                          <input
                            type="checkbox"
                            id={column.name}
                            name={column.name}
                            checked={column.isChecked}
                            onChange={(e) => {
                              handleColumnCheckboxChangeSales(
                                column.name,
                                e.target.checked
                              );
                            }}
                            className="cursorPointer"
                          />
                          <label
                            className="small cursorPointer"
                            htmlFor={column.name}
                          >
                            {column.name}
                          </label>
                        </div>
                      </div>
                    );
                  })}
              </div>
              <aside className="float-end mt-2">
                <div className="d-flex align-items-center gap-4">
                  <button
                    className="cancel_btn bg-light"
                    onClick={() => {
                      setShowModalType("");
                    }}
                  >
                    Cancel
                  </button>
                  <button
                    className="save_btn"
                    onClick={() => {
                      setSelectedHeadingsSales(savingHeadingsSales);
                      setTimeout(() => {
                        setShowModalType("");
                      }, 200);
                    }}
                  >
                    Save
                  </button>
                </div>
              </aside>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      {/* -------------------- */}
      {/* -------------------- */}
      <Modal
        show={showModalType === "Manage Invoices Columns"}
        id="ConvertModal"
        aria-labelledby="example-custom-modal-styling-title"
        className="withdrw_modal"
      >
        <Modal.Body>
          <div className="modalClass  position-relative">
            <div className="d-flex justify-content-between ">
              <h4 className="">Manage Columns</h4>
              {/* <img
                src={crossIcon}
                alt="cross"
                style={{ cursor: "pointer" }}
                className="ms-1"
                onClick={() => {
                  setShowModalType("");
                }}
              /> */}
            </div>
            <div className="w-100 position-relative">
              <div className="mb-1 pt-2 position-relative w-100">
                <input
                  type="text"
                  className="assetSearchbar searchbar rounded-2 p-2 pe-5 common_input_bg w-100 "
                  placeholder="Search"
                  aria-label="Username"
                  aria-describedby="basic-addon1"
                  value={searchColumn}
                  onChange={(e) => {
                    if (e.target.value.charAt(0) !== " ") {
                      const value = e.target.value.replace(
                        /[^a-zA-Z ]+|(\s)\s+/g,
                        "$1"
                      );
                      setSearchColumn(value);
                      // const list = invoiceColumns.filter((contact) =>
                      //   contact.name
                      //     .toLowerCase()
                      //     .includes(value.trim().toLowerCase())
                      // );
                      // setSelectedHeadingsInvoices(list);
                    }
                  }}
                />
                <span
                  className="position-absolute  mt-2 me-2 end-0 opacity-75"
                  onClick={() => {
                    setSearchColumn("");
                    setSavingHeadings(invoiceColumns);
                  }}
                >
                  {searchColumn === "" ? (
                    <img src={searchIcon} alt="" width={20} />
                  ) : (
                    <div style={{ cursor: "pointer" }}>X</div>
                  )}
                </span>
              </div>
              <div className=" opacity-75 columns_container">
                {savingHeadingsInvoices
                  .filter((lead) =>
                    lead.name
                      .toLowerCase()
                      .includes(searchColumn.trim().toLowerCase())
                  )
                  .sort((a, b) => a.name.localeCompare(b.name))
                  .map((column) => {
                    return (
                      <div
                        key={column.name}
                        className="d-flex gap-2 py-2 rounded-2 align-items-center"
                      >
                        <div className="d-flex gap-2 ps-2">
                          <input
                            type="checkbox"
                            id={column.name}
                            name={column.name}
                            checked={column.isChecked}
                            onChange={(e) => {
                              handleColumnCheckboxChangeInvoices(
                                column.name,
                                e.target.checked
                              );
                            }}
                            className="cursorPointer"
                          />
                          <label
                            className="small cursorPointer"
                            htmlFor={column.name}
                          >
                            {column.name}
                          </label>
                        </div>
                      </div>
                    );
                  })}
              </div>
              <aside className="float-end mt-2">
                <div className="d-flex align-items-center gap-4">
                  <button
                    className="cancel_btn bg-light"
                    onClick={() => {
                      setShowModalType("");
                    }}
                  >
                    Cancel
                  </button>
                  <button
                    className="save_btn"
                    onClick={() => {
                      setSelectedHeadingsInvoices(savingHeadingsInvoices);
                      setTimeout(() => {
                        setShowModalType("");
                      }, 200);
                    }}
                  >
                    Save
                  </button>
                </div>
              </aside>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      {/* -------------------- */}

      <ShareModal
        moduleType="DEALS"
        setShareModal={setShareModal}
        shareModal={shareModal}
        shareInfoModal={shareInfoModal}
        setShareInfoModal={setShareInfoModal}
        id={dealId}
        modalHeading={"Sharing Deal"}
        sharePublicList={sharePublicList}
        shareUserList={shareUserList}
        shareGroupList={shareGroupList}
        shareRoleList={shareRoleList}
        shareMemberIds={shareMemberIds}
        module={"Deals"}
      />

      <LeadOwnerModal
        isLeadOwnerModal={isLeadOwnerModal}
        setIsLeadOwnerModal={setIsLeadOwnerModal}
        onSelectedLeadChange={handleSelectedLeadChange}
        defaultUser={selectedItem}
      />

      {/* ---------------------- */}
      <Modal
        show={deleteModal}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
      >
        <Modal.Header
          // onClick={() => {
          //   setDeleteModal(false);
          // }}
          style={{
            fontSize: "20px",
            fontWeight: "bold",
            background:
              "linear-gradient(to right, rgba(64, 123, 255, 1), rgba(18, 167, 254, 1))",
            WebkitBackgroundClip: "text",
            backgroundClip: "text",
            WebkitTextFillColor: "transparent",
          }}
        >
          Are you sure you want to delete&nbsp;
          <span>{dealOverviewDetails?.dealName}</span>?
        </Modal.Header>
        <Modal.Body className="ModalBody overflow-auto">
          <div className="d-flex">
            <p className="mb-0 fw-bold">Note :&nbsp;</p>
            <span>
              Any associated activities, visits, drafts will also be deleted.
            </span>
          </div>
          <div className="d-flex flex-column gap-4">
            <div className="d-flex justify-content-end w-100 gap-4">
              <button
                className="cancelButton"
                onClick={() => {
                  setDeleteModal(false);
                  setShowOptionsDeal(false);
                }}
              >
                Cancel
              </button>
              <button
                className="createContactButtonSave"
                onClick={() => {
                  deleteDealsDetails();
                }}
              >
                Delete
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      {/* ---------------------- */}
      <Modal
        show={[
          "delete",
          "Mass Delete",
          "Mass Update",
          "Mass Transfer",
        ].includes(showModalType)}
        aria-labelledby="contained-modal-title-vcenter"
        onClick={(e) => e.stopPropagation()}
        // size={showModalType !== "delete" ? "md" : "lg"}
      >
        <Modal.Header
          className=" border-0 mb-0 d-flex flex-column align-items-start"
          style={{ backgroundColor: showModalType !== "delete" && "#F2F2F2" }}
        >
          {showModalType !== "delete" && (
            <div
              className={`mass_heading fs-4 ${
                showModalType !== "Mass Delete" && "ps-4"
              }`}
            >
              {showModalType}
            </div>
          )}
          {showModalType === "delete" && (
            <div className="fs-5" style={{ color: "#3F7DFF" }}>
              Are you sure you want to delete {dealOverviewDetails?.dealName}?
            </div>
          )}
        </Modal.Header>
        <Modal.Body className="ModalBody">
          {showModalType === "delete" ? (
            <>
              <div className="d-flex gap-2 pb-2 align-items-center">
                <span className="fw-bold">Note:</span>
                <span className="small pt-1">
                  Any associated activities, visits, drafts will also be
                  deleted.
                </span>
              </div>
            </>
          ) : showModalType === "Mass Delete" ? (
            <>
              <div className="fs-5 pt-0 pb-2">
                Are you sure you want to delete selected item(s)?
              </div>
              <div className="small">
                <span className="fw-bold">Note: </span>{" "}
                <span>
                  {" "}
                  Any associated Activities, Visits, Drafts will also be Deleted
                </span>
              </div>
            </>
          ) : showModalType === "Mass Update" ? (
            <div className="d-flex gap-2 mb-3 mt-2 align-items-center w-100">
              <div className="w-100">
                <div className="d-flex flex-column ps-1 align-items-center mx-4">
                  <label className="text-start w-100 opacity-75">
                    Select a field
                  </label>
                  <div
                    className="position-relative w-100 rounded-1"
                    style={{ border: "1px solid rgba(185, 185, 185, 0.5)" }}
                  >
                    <div
                      onClick={(e) => {
                        e.stopPropagation();
                        setListVisible(!listVisible);
                        setSearchField("");
                      }}
                      className={`cursorPointer d-flex justify-content-between w-100 my-1 p-1 border-0`}
                    >
                      <span className="">{selectedObj.name}</span>
                      <img
                        src={listVisible ? up : down}
                        alt="img"
                        height={20}
                        width={20}
                        className="pt-1"
                      />
                    </div>
                    {listVisible && (
                      <div
                        className="position-absolute field_container w-100"
                        onClick={(e) => {
                          e.stopPropagation();
                        }}
                      >
                        <div className="">
                          <label
                            htmlFor="selectField"
                            className="position-relative w-100"
                          >
                            <input
                              id="selectField"
                              type="text"
                              className="rounded-2 p-1 w-100 pe-4"
                              placeholder="Search"
                              value={searchField}
                              onChange={(e) => {
                                if (e.target.value.charAt(0) !== " ") {
                                  const value = e.target.value.replace(
                                    /[^a-zA-Z ]+|(\s)\s+/g,
                                    "$1"
                                  );
                                  setSearchField(value);
                                }

                                // const value = e.target.value.replace(
                                //   /[^a-zA-Z]/g,
                                //   ""
                                // );
                                // setSearchField(value);

                                // const filteredData = dealsColumn.filter(
                                //   (field) =>
                                //     field.name
                                //       .toLowerCase()
                                //       .includes(value.trim().toLowerCase())
                                // );
                                // setShowSearchFields(
                                //   /^[a-zA-Z]+$/.test(value)
                                //     ? filteredData
                                //     : fieldLabels
                                // );
                              }}
                            />
                            <img
                              className="position-absolute mt-1 me-2 end-0 pt-1 cursorPointer"
                              onClick={() => {
                                setSearchField("");
                              }}
                              src={searchIcon}
                              alt="search"
                              width={20}
                            />
                          </label>

                          <div className="w-100 rounded-2">
                            <div className="border border-1 field_container_dropdown rounded-1">
                              {showSearchFields
                                .filter((field) =>
                                  field.name
                                    .toLowerCase()
                                    .includes(searchField.trim().toLowerCase())
                                )
                                .sort((a, b) => a.name.localeCompare(b.name))
                                .map(
                                  (field) =>
                                    ![
                                      "Lead Owner",
                                      "Created By",
                                      "Modified By",
                                      "Secondary Email Id",
                                      "Email Opt Out",
                                      "Lead Name",
                                      "First Name",
                                      "Last Name",
                                      "Contact Name",
                                      "Account Name",
                                      "Deal Owner",
                                      "Description",
                                      "Expected Revenue",
                                      "Probability",
                                      "Hash",
                                    ].includes(field.name) && (
                                      <div
                                        key={field.name}
                                        className="d-flex gap-2 p-2 cursorPointer rounded-2 align-items-center"
                                        onClick={() => {
                                          setClickedDropdownObj({
                                            label: "",
                                            value: "",
                                          });
                                          setMassUpdateValue("");
                                          setSelectedObj(field);
                                          setListVisible(false);
                                        }}
                                      >
                                        <span className="ps-2">
                                          {field.name}
                                        </span>
                                      </div>
                                    )
                                )}
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="w-100 mx-auto d-flex justify-content-center mt-4">
                    <div className="w-100">
                      <label className="opacity-75" htmlFor="massValue">
                        Enter value
                      </label>
                      <div
                        className="position-relative w-100 border rounded-1"
                        style={{ border: "1px solid rgba(185, 185, 185, 0.5)" }}
                      >
                        {[
                          "Lead Source",
                          "Stage",
                          "Type",
                          "Reason for loss",
                        ].includes(selectedObj.name) ? (
                          <Select
                            value={clickedDropdownObj}
                            menuPlacement="auto"
                            onChange={(selectedOption) => {
                              setClickedDropdownObj(selectedOption);
                              setMassUpdateValue(selectedOption.value);
                            }}
                            options={
                              selectedObj.name === "Lead Source"
                                ? leadSourceOptions
                                : selectedObj.name === "Stage"
                                ? stageOptions
                                : selectedObj.name === "Type"
                                ? typeOptions
                                : industryOptions
                            }
                            styles={fieldDropdownStyles}
                            isSearchable={false}
                          />
                        ) : selectedObj.name === "Campaign Source" ? (
                          <div>
                            <div className="d-flex input-with-clear">
                              <input
                                type="text"
                                className="insertCommonInputField cursorPointer w-100"
                                value={selectedCampaignItem?.campaignName}
                                onClick={handleOwnerParentInputClickCampaign}
                                readOnly
                              />
                              <span
                                className="clear-button-campaign d-flex justify-content-end"
                                onClick={handleOwnerParentInputClickCampaign}
                              >
                                <img
                                  src={allCampaignListVisible ? up : down}
                                  alt="img"
                                  height={20}
                                  width={20}
                                />
                              </span>
                            </div>
                            {allCampaignListVisible && allList.length > 0 && (
                              <ul
                                className="data-list list-group  lead_ownerList_container position-absolute"
                                style={{ top: "3rem" }}
                                onClick={(e) => e.stopPropagation()}
                              >
                                {allList.map((item, index) => (
                                  <li
                                    key={index}
                                    className="cursorPointer list-group-item"
                                    onClick={() =>
                                      handleParentUserItemCampaign(item)
                                    }
                                  >
                                    {item.campaignName}
                                  </li>
                                ))}
                              </ul>
                            )}
                          </div>
                        ) : selectedObj.name === "Closing Date" ? (
                          <input
                            className="w-100 border-0 py-2 rounded-1 px-2"
                            type="date"
                            value={massUpdateValue}
                            onChange={(e) => {
                              setMassUpdateValue(e.target.value);
                            }}
                            style={{ outline: "none" }}
                            id="massValue"
                          />
                        ) : (
                          <input
                            className="w-100 border-0 py-2 rounded-1 px-2"
                            type="text"
                            value={massUpdateValue}
                            onChange={(e) => {
                              const trimmedValue = e.target.value
                                ?.replace(/\s{2,}/g, " ")
                                .trimStart();
                              setMassUpdateValue(
                                selectedObj.name === "Amount"
                                  ? trimmedValue
                                      .replace(/[^0-9.]/g, "") // Allow only numbers and dots
                                      .replace(/(\..*)\./g, "$1") // Remove multiple dots
                                  : trimmedValue.replace(/^(.)/, (match) =>
                                      match.toLowerCase()
                                    ) // Convert first character to lowercase
                              );
                            }}
                            style={{ outline: "none" }}
                            id="massValue"
                            maxLength={50}
                          />
                        )}
                      </div>

                      {errorMessage && (
                        <span className="text-danger text-center pt-1">
                          {errorMessage}
                        </span>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="w-100 px-4 py-2 mb-1">
              <label className="text-start w-100 opacity-75">Transfer To</label>
              <div
                className="d-flex input-with-clear position-relative mx-auto"
                onClick={(e) => {
                  handleOwnerInputClick(e);
                }}
              >
                <input
                  type="text"
                  className="ps-3 w-100 cursorPointer py-2"
                  value={selectedItem?.name}
                  readOnly
                />
                <span
                  className="position-absolute h-100 mass_transfer_user pt-1"
                  style={{ right: "0.1rem" }}
                >
                  <span
                    className="cursorPointer px-1"
                    onClick={() => setIsLeadOwnerModal(!isLeadOwnerModal)}
                  >
                    <img
                      src={userSearch}
                      alt="img"
                      width={20}
                      height={20}
                      className="ms-2 mt-2 me-2"
                    />
                  </span>
                </span>
              </div>
            </div>
          )}

          <div className={`pt-4  ${showModalType !== "delete" && "pe-3"}`}>
            <div className="d-flex justify-content-end w-100 gap-4 pe-2">
              <button
                className="cancelButton px-4"
                onClick={() => {
                  setShowModalType("");
                  // setSelectedCheckboxes([]);
                  setShowSearchFields(fieldDropdowns);
                }}
              >
                Cancel
              </button>
              <button
                className="createContactButtonSave"
                onClick={() => {
                  if (showModalType === "delete") {
                    deleteDealsDetails();
                  } else {
                    //  else if (showModalType === "Mass Delete") {
                    //   deleteUpdateTransferMass();
                    // } else {
                    deleteUpdateTransferMass();
                  }
                }}
              >
                {showModalType === "Mass Update"
                  ? "Update"
                  : showModalType === "Mass Transfer"
                  ? "Done"
                  : "Delete"}
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      {/* ---------------------- */}

      <AccessModal
        setShowAccessModal={setShowAccessModal}
        showAccessModal={showAccessModal}
      />

      {/* {isCampaignListModal ? (
        <AccountListModal
          isAccountListModal={isCampaignListModal}
          setIsAccountListModal={setIsCampaignListModal}
          onSelectedLeadChange={handleSelectParentList}
          defaultUser={selectedCampaignItem}
          allAccountDetails={allList}
          type="campaign"
        />
      ) : null} */}
      <Modal
        show={isCampaignListModal}
        className="d-flex justify-content-center align-items-center"
        aria-labelledby="example-custom-modal-styling-title"
        size="lg"
        id="commonLeadModal"
      >
        <Modal.Body className="ModalBody overflow-auto">
          <div
            className="modalUS row justify-content-center"
            style={{ margin: "0", gap: "10px", paddingBottom: "10px" }}
          >
            <div className="header_Container d-flex justify-content-between">
              <h4 className="commonTextColor pt-2">Choose Campaign</h4>
              <div className="inputContainer d-flex justify-content-start">
                <input
                  type="text"
                  placeholder="Search"
                  className="topBarModalSearch px-5"
                  onChange={handleSearchCampaign}
                  value={searchCampaign}
                />
                <img src={SearchIcon} alt="search" className="searchIcon" />
              </div>
            </div>

            <div className="Owner_table_container border border-dark rounded-2 h-25">
              <div className="table-wrapper">
                <table className="userdata-table">
                  <thead>
                    <tr>
                      <th>Campaign Name</th>
                      <th>Campaign Type</th>
                      <th>Campaign Owner</th>
                      <th>Start Date</th>
                      <th>End Date</th>
                    </tr>
                  </thead>
                  <tbody className="table-body tableUserData">
                    {filteredCampigns.length > 0 &&
                      filteredCampigns?.map((item, index) => (
                        <tr
                          key={index}
                          onClick={() => {
                            setSelectedCampaignItem(item);
                          }}
                        >
                          <>
                            <td>{item.campaignName}</td>
                            <td>{item.campaignType}</td>
                            <td>{item.campaignOwner}</td>
                            <td>{item.startDate}</td>
                            <td>{item.endDate}</td>
                          </>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            </div>
            <div className="d-flex w-100 align-items-center justify-content-end gap-2">
              {filteredCampigns.length > 0 && (
                <>
                  <Select
                    options={pageOption}
                    styles={customCommonStyles("225px")}
                    value={pageOption.find(
                      (option) => option.value === perPageCampaign.toString()
                    )}
                    onChange={handlePageDropDownChangeCampaign}
                    isSearchable={false}
                  />
                  <Pagination
                    currentPage={currentPageCampaign}
                    totalPages={totalPagesCampaign}
                    onPageChange={handlePageChangeCampaign}
                  />
                </>
              )}
            </div>
            <div className="d-flex pb-2 pt-3 justify-content-between">
              <div className="selectedUser d-flex gap-1">
                <span>Selected Campaign</span>
                <img src={logoConvert} alt="img" height={20} width={20} />
                <span>{selectedCampaignItem.campaignName}</span>
              </div>
              <div className="d-flex gap-2">
                <button
                  className="btn btn-outline-secondary border border-primary btn-sm px-3 py-2 text-dark"
                  onClick={() => {
                    setIsCampaignListModal(false);
                    setSelectedCampaignItem({});
                  }}
                >
                  Cancel
                </button>
                <button
                  className="btn btnColor btn-sm px-3 text-white"
                  onClick={() => {
                    setIsCampaignListModal(false);
                    //  setSelectedCampaignId(selectedCampaignItem.campaignId)
                  }}
                >
                  Done
                </button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <ChangeOwnerModal
        modalType={showModalType}
        setShowModalType={setShowModalType}
        selectedOwnerItem={selectedOwnerItem}
        handleChangeOwnerInputClick={handleChangeOwnerInputClick}
        setSelectedOwnerItem={setSelectedOwnerItem}
        isLeadOwnerModal={isLeadOwnerModal}
        setIsLeadOwnerModal={setIsLeadOwnerModal}
        isChangeOwnerList={isChangeOwnerList}
        userList={userList}
        handleChangeOwnerItemClick={handleChangeOwnerItemClick}
        emailNotification={emailNotification}
        setEmailNotification={setEmailNotification}
        handleEditClick={handleEditClick}
        handleUpdateClick={handleUpdateClick}
        setUpdatedFields={setUpdatedFields}
        existingContact={existingDeal}
        handleUpdateContact={handleUpdateDeal}
        onSelectedChangeOwner={handleSelectedLeadChange}
        apiLoader={apiLoader}
      />

      <Modal
        show={isAccountListModal}
        className="d-flex justify-content-center align-items-center"
        aria-labelledby="example-custom-modal-styling-title"
        size="lg"
        id="commonLeadModal"
      >
        <Modal.Body className="ModalBody  overflow-auto">
          <div
            className="modalUS row justify-content-center"
            style={{ margin: "0", gap: "10px", paddingBottom: "10px" }}
          >
            <div className="header_Container d-flex justify-content-between">
              <h4 className="commonTextColor pt-2">Choose Account</h4>
              <div className="inputContainer d-flex justify-content-start">
                <input
                  type="text"
                  placeholder="Search"
                  className="topBarModalSearch px-5"
                  onChange={handleSearchChange}
                  value={searchAccount}
                />
                <img src={SearchIcon} alt="search" className="searchIcon" />
              </div>
            </div>

            <div className="Owner_table_container border border-dark rounded-2 h-25">
              <div className="table-wrapper">
                <table className="userdata-table">
                  <thead>
                    <tr>
                      <th>Account Name</th>
                      <th>Phone</th>
                      <th>Website</th>
                      <th>Account Owner</th>
                    </tr>
                  </thead>
                  <tbody className="table-body tableUserData">
                    {filteredAccountDetails?.map((item, index) => (
                      <tr
                        key={index}
                        onClick={() => {
                          setSelectedAccountItem(item);
                        }}
                      >
                        <td>{item.accountName || "--"}</td>
                        <td>{item.phone || "--"}</td>
                        <td>{item.website || "--"}</td>
                        <td>{item.accountOwner || "--"}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
            <div className="d-flex w-100 align-items-center justify-content-end gap-2">
              {filteredAccountDetails.length > 0 && (
                <>
                  <Select
                    options={pageOption}
                    styles={customCommonStyles("100%")}
                    // value={selectPageOption}
                    value={pageOption.find(
                      (option) => option.value === perPageAccount.toString()
                    )}
                    onChange={handlePageDropDownChangeAccount}
                  />
                  {/* <p className="mb-0">· ‹1/10›</p> */}

                  <Pagination
                    currentPage={currentPageAccount}
                    totalPages={totalPagesAccount}
                    onPageChange={handlepageChangeAccount}
                  />
                </>
              )}
            </div>
            <div className="d-flex pb-2 pt-3 justify-content-between">
              <div className="selectedUser d-flex gap-1">
                <span>Selected User</span>
                <img src={logoConvert} alt="img" height={20} width={20} />
                <span>{selectedAccountItem.accountName}</span>
              </div>
              <div className="d-flex gap-2">
                <button
                  className="btn btn-outline-secondary border border-primary btn-sm px-3 py-2 text-dark"
                  onClick={() => {
                    setIsAccountListModal(false);
                    setSelectedAccountItem({});
                  }}
                >
                  Cancel
                </button>
                <button
                  className="btn btnColor btn-sm px-3 text-white"
                  onClick={() => {
                    getAllAssignModules(
                      "ACCOUNTS",
                      ["CONTACTS"],
                      selectedAccountItem.accountId
                    );
                  }}
                >
                  Done
                </button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={isContactListModal}
        className="d-flex justify-content-center align-items-center"
        aria-labelledby="example-custom-modal-styling-title"
        size="lg"
        id="commonLeadModal"
      >
        <Modal.Body className="ModalBody  overflow-auto">
          <div
            className="modalUS row justify-content-center"
            style={{ margin: "0", gap: "10px", paddingBottom: "10px" }}
          >
            <div className="header_Container d-flex justify-content-between">
              <h4 className="commonTextColor pt-2">Change Contact</h4>
              <div className="inputContainer d-flex justify-content-start">
                <input
                  type="text"
                  placeholder="Search"
                  className="topBarModalSearch px-5"
                  onChange={handleSearchChangeContact}
                  value={searchContact}
                />
                <img src={SearchIcon} alt="search" className="searchIcon" />
              </div>
            </div>

            <div className="Owner_table_container border border-dark rounded-2 h-25">
              <div className="table-wrapper">
                <table className="userdata-table">
                  <thead>
                    <tr>
                      <th>Contact Name</th>
                      <th>Account Name</th>
                      <th>Email</th>
                      <th>Phone</th>
                      <th>Contact Owner</th>
                    </tr>
                  </thead>
                  <tbody className="table-body tableUserData">
                    {filteredContactDetails?.map((item, index) => (
                      <tr
                        key={index}
                        onClick={() => {
                          setSelectedContactItem(item);
                        }}
                      >
                        <td>{item.contactName}</td>
                        <td>{item.accountName}</td>
                        <td>{item.emailId}</td>
                        <td>{item.phone}</td>
                        <td>{item.contactOwner}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
            <div className="d-flex w-100 align-items-center justify-content-end gap-2">
              {filteredContactDetails.length > 0 && (
                <>
                  <Select
                    options={pageOption}
                    styles={customCommonStyles("100%")}
                    // value={selectPageOption}
                    value={pageOption.find(
                      (option) => option.value === perPageContact.toString()
                    )}
                    onChange={handlePageDropDownChangeContact}
                  />
                  {/* <p className="mb-0">· ‹1/10›</p> */}

                  <Pagination
                    currentPage={currentPageContact}
                    totalPages={totalPagesContact}
                    onPageChange={hadlePageChangeContact}
                  />
                </>
              )}
            </div>
            <div className="d-flex pb-2 pt-3 justify-content-between">
              <div className="selectedUser d-flex gap-1">
                <span>Selected User</span>
                <img src={logoConvert} alt="img" height={20} width={20} />
                <span>{selectedContactItem.contactName}</span>
              </div>
              <div className="d-flex gap-2">
                <button
                  className="btn btn-outline-secondary border border-primary btn-sm px-3 py-2 text-dark"
                  onClick={() => {
                    setIsContactListModal(false);
                    setSelectedContactItem({});
                  }}
                >
                  Cancel
                </button>
                <button
                  className="btn btnColor btn-sm px-3 text-white"
                  onClick={() => {
                    getAllAssignModules(
                      "CONTACTS",
                      ["ACCOUNTS"],
                      selectedContactItem.contactId
                    );
                  }}
                >
                  Done
                </button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <NotificationContainer />
    </div>
  );
}

export default Deals;

import React, { useEffect, useState } from "react";
import filterIcon from "../../Assets/Frame (3).svg";
import Select from "react-select";
import groupIcon from "../../Assets/De-Active/Purchase Order.svg";
import "./purchase.css";
import axios from "axios";
import { URI } from "../../constants";
import down from "../../Assets/Down31.svg";
import up from "../../Assets/Up.svg";
import userSearch from "../../Assets/user-search-01.svg";
import LeadOwnerModal from "../../common/LeadOwnerModal";
import Dropdown from "react-bootstrap/Dropdown";
import Pagination from "../../common/Pagination";
import { massOptions, pageOption, taxOptions } from "../../common/CommonData";
import filterMenu from "../../Assets/filterMenu.svg";
import tableFilterIcon from "../../Assets/filterIcon.svg";
import filterLine from "../../Assets/filterLine.svg";
import { Modal } from "react-bootstrap";
import arrowUp from "../../Assets/square_arrow_up.svg";
import arrowDown from "../../Assets/square_arrow_down.svg";
import pin from "../../Assets/Pin_column.svg";
import unPin from "../../Assets/Unpin.svg";
import cancel from "../../Assets/cancel_circle.svg";
import noteIcon from "../../Assets/notebook-01.svg";
import openActivityIcon from "../../Assets/openActivity.svg";
import closeActivityIcon from "../../Assets/closeActivity.svg";
import updateIcon from "../../Assets/Group 71.svg";
import cancelIcon from "../../Assets/Group 72.svg";
import groupIcon2 from "../../Assets/Group 74.svg";
import history from "../../Assets/history.svg";
// import hierachy from "../../Assets/hierarchy.svg";
import EditableInput from "../../common/EditableInput";
import Clone from "../../Assets/Clone.svg";
import Share from "../../Assets/ShareIcon.svg";
import Delete from "../../Assets/DeleteIconNew.svg";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import Note from "../../common/commonRelatedList/Note";
import OpenActivity from "../../common/commonRelatedList/OpenActivity";
import CloseActivity from "../../common/commonRelatedList/CloseActivity";
import ShareModal from "../../common/ShareModal";
import { useSelector } from "react-redux";
import Loader from "../../common/commonRelatedList/Loader";
import PurchaseNoDataImage from "../../Assets/PurchaseNoDataImage.svg";
import {
  leftArrow,
  rightAngleArrow,
  leftAngleArrow,
  editIcon,
  tick,
  expandArrow,
  shrinkArrow,
} from "../../common/commonSVGs/commonSVGIcons.js";
import AccessModal from "../../common/AccessModal";
import moment from "moment";
import {
  validateCallSubject,
  validateCityIndia,
  validateStateIndia,
  validateCountryName,
  validateZipCode,
  removeExtraSpaces,
  validateCalcField,
  customCommonStyles,
  removeLeadingZeros,
} from "../../common/CommonComponent.js";
import { EXPLORER_URL } from "../../constants/DataConst.js";
// import vendorsIcon from "../../Assets/vendorDeactive2.svg";
import vendorsIcon from "../../Assets/De-Active/vendorHome.svg";
import ContactIcon from "../../Assets/De-Active/Contact.svg";
import Lottie from "react-lottie";
import { defaultOptions } from "../../utils/defaultOptions.js";
import ChangeOwnerModal from "../../common/commonRelatedList/ChangeOwnerModal.js";
import getHeaders from "../../common/ApiHeader.js";
import { handleApiError } from "../../utils/ApiUtils";
import { useNavigate } from "react-router-dom";
// import searchIcon from "../../Assets/searchIcon.svg";
import searchIcon from "../../Assets/search.svg";
import logoConvert from "../../Assets/logoConvert.svg";
import CalculatorTooltip from "../../common/CalculatorTooltip.js";

function Purchase({
  setCurrentView,
  setInnerPage,
  innerPage,
  currentView,
  permissionList,
  notePermission,
  fetchContactInfo,
  setFetchContactInfo,
  pastViewInfo,
  setPastViewInfo,
  storeModuleId,
}) {
  const navigate = useNavigate();
  // const [innerPage, setInnerPage] = useState(
  //   "list"
  // );

  const purchaseColumns = [
    {
      name: "Subject",
      isChecked: true,
      value: "subject",
    },
    {
      name: "Shipping City",
      isChecked: false,
      value: "shippingCity",
    },
    {
      name: "Shipping Code",
      isChecked: false,
      value: "shippingCode",
    },
    {
      name: "Discount",
      isChecked: false,
      value: "discount",
    },
    {
      name: "Sub Total",
      isChecked: true,
      value: "subTotal",
    },
    {
      name: "PO Date",
      isChecked: true,
      value: "poDate",
    },
    {
      name: "Shipping Country",
      isChecked: false,
      value: "shippingCountry",
    },
    {
      name: "Billing Country",
      isChecked: false,
      value: "billingCountry",
    },
    {
      name: "Excise Duty",
      isChecked: false,
      value: "exciseDuty",
    },
    {
      name: "Tracking Number",
      isChecked: true,
      value: "trackingNumber",
    },
    {
      name: "Sales Commission",
      isChecked: false,
      value: "salesCommission",
    },
    {
      name: "Requisition Number",
      isChecked: false,
      value: "requisitionNumber",
    },
    {
      name: "Grand Total",
      isChecked: false,
      value: "grandTotal",
    },
    {
      name: "Contact Name",
      isChecked: true,
      value: "contactName",
    },
    {
      name: "Tax",
      isChecked: false,
      value: "tax",
    },
    {
      name: "Billing State",
      isChecked: false,
      value: "billingState",
    },
    {
      name: "Vendor Name",
      isChecked: false,
      value: "vendorName",
    },
    {
      name: "Carrier",
      isChecked: false,
      value: "carrier",
    },
    {
      name: "Purchase Order Owner",
      isChecked: false,
      value: "purchaseOrderOwner",
    },
    {
      name: "Shipping State",
      isChecked: false,
      value: "shippingState",
    },
    {
      name: "Po Number",
      isChecked: false,
      value: "poNumber",
    },
    {
      name: "Billing City",
      isChecked: false,
      value: "billingCity",
    },
    {
      name: "Status",
      isChecked: false,
      value: "status",
    },
    {
      name: "Due Date",
      isChecked: false,
      value: "dueDate",
    },
    {
      name: "Hash",
      isChecked: true,
      value: "transHash",
    },
  ];
  const [selectedHeadings, setSelectedHeadings] = useState(purchaseColumns);
  const [pinnedColumn, setPinnedColumn] = useState("Subject");
  const [savingHeadings, setSavingHeadings] = useState(purchaseColumns);
  const [showHeadings, setShowHeadings] = useState(false);
  const [modalType, setShowModalType] = useState("");
  const [searchColumn, setSearchColumn] = useState("");
  const [showSelectedFilter, setShowSelectedFilter] = useState("");
  const [filterType, setFilterType] = useState("");
  const [showOptionsPurchase, setShowOptionsPurchase] = useState(false);
  const [allPurchaseOrders, setAllPurchaseOrders] = useState([]);
  const [filteredPurchaseOrders, setFilteredPurchaseOrders] = useState([]);
  const [selectedPurchaseId, setSelectedPurchaseId] = useState("");
  const [purchaseOrdId, setPurchaseOrdId] = useState("");
  const [orderPageView, setOrderPageView] = useState("overview");
  const [editPurchaseOverview, setEditPurchaseOverview] = useState(false);
  const [isShrinked, setIsShrinked] = useState(false);
  const [updateNotes, setUpdateNotes] = useState({
    description: "",
    title: "",
    noteImageList: [],
  });
  const [editNote, setEditNote] = useState(false);
  const [showAccessModal, setShowAccessModal] = useState(false);
  const [shareUserList, setShareUserList] = useState([]);
  const [shareRoleList, setShareRoleList] = useState([]);
  const [shareGroupList, setShareGroupList] = useState([]);
  const [sharePublicList, setSharePublicList] = useState([]);
  const [shareInfoModal, setShareInfoModal] = useState(false);
  const [billingCityError, setBillingCityError] = useState("");
  const [billingStateError, setBillingStateError] = useState("");
  const [billingCountryError, setBillingCountryError] = useState("");
  const [billingCodeError, setBillingCodeError] = useState("");
  const [shippingCityError, setShippingCityError] = useState("");
  const [shippingStateError, setShippingStateError] = useState("");
  const [shippingCountryError, setShippingCountryError] = useState("");
  const [shippingCodeError, setShippingCodeError] = useState("");
  const [shareMemberIds, setShareMemberIds] = useState([]);
  const [showTooltipExciseDuty, setShowTooltipExciseDuty] = useState(false);
  const [tooltipContentExciseDuty, setTooltipContentExciseDuty] = useState("");
  const [showTooltipSalesCommission, setShowTooltipSalesCommission] =
    useState(false);
  const [tooltipContentSalesCommission, setTooltipContentSalesCommission] =
    useState("");
  const [exciseDutyMsg, setExciseDutyMsg] = useState("");
  const [salesCommissionMsg, setSalesCommissionMsg] = useState("");
  const [deletedImg, setDeletedImg] = useState("");
  const [searchVendor, setSearchVendor] = useState("");
  const [modalVendorDetails, setModalVendorDetails] = useState([]);
  const [currentPageVendor, setCurrentPageVendor] = useState(1);
  const [totalItemsVendor, setTotalItemsVendor] = useState(0);
  const [perPageVendor, setPerPageVendor] = useState(10);

  const handlepageChangeVendor = (page) => {
    setCurrentPageVendor(page);
  };

  const handlePageDropDownChangeVendor = (selectedOption) => {
    setCurrentPageVendor(1);
    setPerPageVendor(parseInt(selectedOption.value));
  };

  const totalPagesVendor = Math.ceil(Number(totalItemsVendor) / perPageVendor);

  const handleSearchVendor = (e) => {
    const { value } = e.target;
    if (value.trim() !== "" || value === "") {
      setSearchVendor(value);
    }
  };
  const filteredVendors = modalVendorDetails.filter((item) =>
    item.vendorName?.toLowerCase().includes(searchVendor?.toLowerCase())
  );

  const requiredDateFormatConversion = (inputDate) =>
    moment(inputDate, ["DD-MM-YYYY", "YYYY-MM-DD"]).format("DD-MM-YYYY");

  const hasPermission = (action) => {
    const permission = permissionList.purchaseOrders.find(
      (p) => p.action === action
    );
    return permission ? permission.status === "TRUE" : false;
  };

  const handleCreatePurchase = () => {
    if (hasPermission("CREATE")) {
      setSelectedContact(contactList[0]);
      setSelectedVendor(vendorList[0]);
      setInnerPage("create");
      setSelectedCheckboxes([]);
      if (products.length > 0) {
        const { unitPrice, tax, productId, productName, description } =
          products[0];
        const discountedAmount =
          parseFloat(unitPrice) * parseFloat(unitPrice) - 0;
        const taxAmount = (parseFloat(tax || 0) / 100) * discountedAmount;
        const total = (discountedAmount + taxAmount).toFixed(2) || 0;

        setEditPurchaseOverview(false);
        setPurchaseInfo({
          purchaseOrderOwnerId: "",
          subject: "",
          poNumber: "",
          requisitionNumber: "",
          vendorName: "",
          vendorId: "",
          trackingNumber: "",
          contactId: "",
          poDate: new Date()
            .toISOString()
            .split("T")[0]
            ?.split("-")
            .reverse()
            .join("-"),
          dueDate: "",
          carrier: carrierOptions[0],
          exciseDuty: "",
          salesCommission: "",
          status: stausOptions[0],
          termsAndConditions: "",
          description: "",
          billingStreet: "",
          billingCity: "",
          billingState: "",
          billingCode: "",
          billingCountry: "",
          shippingStreet: "",
          shippingCity: "",
          shippingState: "",
          shippingCode: "",
          shippingCountry: "",
          loggedInUserId: "",
          purchaseItemDtlsDto: [],
          subTotal: isNaN(total) ? 0 : total,
          discount: 0,
          discountType: 1,
          discountValue: 0,
          tax: 0,
          adjustment: 0,
          grandTotal: isNaN(total) ? 0 : total,
        });
        setAllProducts([
          {
            description: description,
            productId: productId,
            productName: productName,
            quantity: unitPrice || 1,
            listPrice: unitPrice || 0,
            amount: unitPrice * unitPrice || 0,
            discount: 0,
            tax: parseFloat(tax) || 0,
            total: isNaN(total) ? 0 : total,
            // total:
            //   products[0].unitPrice * products[0].unitPrice,
            discountType: 1,
            discountValue: 0,
          },
        ]);
      }
    } else {
      setShowAccessModal(true);
    }
  };

  useEffect(() => {
    if (storeModuleId) {
      purchaseOrderOverview(storeModuleId);
      setPurchaseOrdId(storeModuleId);
    }
    if (innerPage === "create") {
      setPurchaseInfo((prevFields) => ({
        ...prevFields,
        poDate: new Date()
          .toISOString()
          .split("T")[0]
          ?.split("-")
          .reverse()
          .join("-"),
        // dueDate: new Date()
        //   .toISOString()
        //   .split("T")[0]
        //   ?.split("-")
        //   .reverse()
        //   .join("-"),
      }));
    }
  }, []);

  const handleCreateNoDataPurchase = () => {
    if (hasPermission("CREATE")) {
      setInnerPage("create");
    } else {
      setShowAccessModal(true);
    }
  };

  const handleEditPurchase = () => {
    if (hasPermission("EDIT")) {
      setInnerPage("create");
      setEditPurchaseOverview(true);
      const selecteOne = singlePurchaseDetails.purchaseItemsTotalInfoDetails;
      // if (selecteOne.length > 0) {
      const selectedObj = {
        adjustment: selecteOne.adjustment,
        discount: selecteOne.discount,
        grandTotal: selecteOne.grandTotal,
        purchaseOrdId: selecteOne.purchaseOrdId,
        subTotal: selecteOne.subTotal,
        discountType: selecteOne.discountType || 1,
        discountValue: selecteOne.discountValue || 0,
        tax: selecteOne.tax,
      };
      // }
      setPurchaseInfo({
        ...singlePurchaseDetails.purchaseInfoDetails,
        poDate: singlePurchaseDetails.purchaseInfoDetails.poDate,
        dueDate: singlePurchaseDetails.purchaseInfoDetails.dueDate,
        carrier: {
          label: singlePurchaseDetails.purchaseInfoDetails.carrier,
          value: singlePurchaseDetails.purchaseInfoDetails.carrier,
        },
        status: {
          label: singlePurchaseDetails.purchaseInfoDetails.status,
          value: singlePurchaseDetails.purchaseInfoDetails.status,
        },
        ...selectedObj,
      });
      setPurchaseItemsTotalInfoDetails(
        singlePurchaseDetails.purchaseItemsTotalInfoDetails
      );
    } else {
      setShowAccessModal(true);
    }
  };

  const [isOwnerSelect, setIsOwnerSelect] = useState(false);
  const [emailNotification, setEmailNotification] = useState(true);
  const [apiLoader, setApiLoader] = useState(false);

  const handleChangeOwnerItemClick = (item) => {
    setSelectedOwner(item);
    setIsChangeOwnerList(false);
    setIsOwnerSelect(true);
    // setIsSelectedOwner(false);
  };

  const handleChangeIndDropDown = (selectedOption, field) => {
    setPurchaseInfo((prevFields) => ({
      ...prevFields,
      [field]: selectedOption,
    }));
  };

  const handleSelectedChangeOwner = (value) => {
    setSelectedOwner(value);
  };

  const handleDeletePurchase = () => {
    if (hasPermission("DELETE")) {
      setShowModalType("delete");
    } else {
      setShowAccessModal(true);
    }
  };

  const [isChangeOwnerList, setIsChangeOwnerList] = useState(false);

  const handleChangeOwnerInputClick = (e) => {
    e.stopPropagation();
    setIsChangeOwnerList(!isChangeOwnerList);

    // setInvidualListVisible(!individualListVisible);
  };

  const fieldDropdowns = [
    {
      name: "Purchase Order Owner",
      isShowed: false,
      type: "select",
      value: "purchaseOwner",
    },
    {
      name: "PO Date",
      isShowed: true,
      type: "input",
      value: "poDate",
    },
    {
      name: "Due Date",
      isShowed: true,
      type: "input",
    },
    {
      name: "Carrier",
      isShowed: true,
      type: "dropdown",
    },
    {
      name: "Subject",
      isShowed: false,
      type: "input",
    },
    {
      name: "Requisition Number",
      isShowed: false,
      type: "input",
    },
    {
      name: "Tracking Number",
      isShowed: true,
      type: "input",
    },
    {
      name: "Status",
      isShowed: false,
      type: "dropdown",
    },
    {
      name: "Excise Duty",
      isShowed: false,
      type: "input",
    },
    {
      name: "Vendor Name",
      isShowed: false,
      // type: "input",
      type: "readOnly",
    },
    {
      name: "Contact Name",
      isShowed: false,
      // type: "input",
      type: "readOnly",
    },
    {
      name: "Sales Commission",
      isShowed: false,
      type: "input",
    },
    {
      name: "PO Number",
      isShowed: true,
      type: "input",
      value: "poNumber",
    },
    {
      name: "Created By",
      isShowed: false,
      type: "readOnly",
    },
    {
      name: "Modified By",
      isShowed: false,
      type: "readOnly",
    },
    {
      name: "Billing Street",
      isShowed: false,
      type: "input",
    },
    {
      name: "Billing State",
      isShowed: false,
      type: "input",
    },
    {
      name: "Billing City",
      isShowed: false,
      type: "input",
    },
    {
      name: "Billing Code",
      isShowed: false,
      type: "input",
    },
    {
      name: "Billing Country",
      isShowed: false,
      type: "input",
    },
    {
      name: "Shipping Street",
      isShowed: false,
      type: "input",
    },
    {
      name: "Shipping State",
      isShowed: false,
      type: "input",
    },
    {
      name: "Shipping City",
      isShowed: false,
      type: "input",
    },
    {
      name: "Shipping Code",
      isShowed: false,
      type: "input",
    },

    {
      name: "Shipping Country",
      isShowed: false,
      type: "input",
    },
    {
      name: "Sub Total",
      isShowed: false,
      type: "readOnly",
    },
    {
      name: "Discount",
      isShowed: false,
      type: "readOnly",
    },
    {
      name: "Tax",
      isShowed: false,
      type: "readOnly",
    },
    {
      name: "Adjustment",
      isShowed: false,
      type: "readOnly",
    },
    {
      name: "Grand Total",
      isShowed: true,
      type: "readOnly",
    },
    // {
    //   name: "Terms and Conditions",
    //   isShowed: false,
    //   type: "textbox",
    // },
    // {
    //   name: "Description Information",
    //   isShowed: false,
    //   type: "textbox",
    // },
  ];

  const fieldIndividualDropdowns = [
    {
      name: "Purchase Order Owner",
      isShowed: false,
      type: "select",
      value: "purchaseOwner",
    },

    {
      name: "PO Date",
      isShowed: true,
      type: "input",
      value: "poDateInfo",
    },
    {
      name: "Due Date",
      isShowed: true,
      type: "input",
      value: "dueDateInfo",
    },
    {
      name: "Carrier",
      isShowed: true,
      type: "dropdown",
      value: "carrierInfo",
    },
    {
      name: "Subject",
      isShowed: false,
      type: "input",
      value: "subjectInfo",
    },
    {
      name: "Requisition Number",
      isShowed: false,
      type: "input",
      value: "requisitionNumberInfo",
    },
    {
      name: "Tracking Number",
      isShowed: true,
      type: "input",
      value: "trackingNumberInfo",
    },
    {
      name: "Status",
      isShowed: false,
      type: "dropdown",
      value: "statusInfo",
    },
    {
      name: "Excise Duty",
      isShowed: false,
      type: "input",
      value: "exciseDutyInfo",
    },
    {
      name: "Vendor Name",
      isShowed: false,
      // type: "input",
      type: "readOnly",
    },
    {
      name: "Contact Name",
      isShowed: false,
      // type: "input",
      type: "readOnly",
    },
    {
      name: "Sales Commission",
      isShowed: false,
      type: "input",
      value: "salesCommissionInfo",
    },
    {
      name: "PO Number",
      isShowed: true,
      type: "input",
      value: "poNumberInfo",
    },
    {
      name: "Created By",
      isShowed: false,
      type: "readOnly",
    },
    {
      name: "Modified By",
      isShowed: false,
      type: "readOnly",
    },
    {
      name: "Billing Street",
      isShowed: false,
      type: "input",
      value: "billingStreetInfo",
    },
    {
      name: "Billing State",
      isShowed: false,
      type: "input",
      value: "billingStateInfo",
    },
    {
      name: "Billing City",
      isShowed: false,
      type: "input",
      value: "billingCityInfo",
    },
    {
      name: "Billing Code",
      isShowed: false,
      type: "input",
      value: "billingCodeInfo",
    },
    {
      name: "Billing Country",
      isShowed: false,
      type: "input",
      value: "billingCountryInfo",
    },
    {
      name: "Shipping Street",
      isShowed: false,
      type: "input",
      value: "shippingStreetInfo",
    },
    {
      name: "Shipping State",
      isShowed: false,
      type: "input",
      value: "shippingStateInfo",
    },
    {
      name: "Shipping City",
      isShowed: false,
      type: "input",
      value: "shippingCityInfo",
    },
    {
      name: "Shipping Code",
      isShowed: false,
      type: "input",
      value: "shippingCodeInfo",
    },

    {
      name: "Shipping Country",
      isShowed: false,
      type: "input",
      value: "shippingCountryInfo",
    },
    {
      name: "Sub Total",
      isShowed: false,
      type: "readOnly",
    },
    {
      name: "Discount",
      isShowed: false,
      type: "input",
    },
    {
      name: "Tax",
      isShowed: false,
      type: "readOnly",
    },
    {
      name: "Adjustment",
      isShowed: false,
      type: "readOnly",
    },
    {
      name: "Grand Total",
      isShowed: true,
      type: "readOnly",
    },
    // {
    //   name: "Terms and Conditions",
    //   isShowed: false,
    //   type: "textbox",
    // },
    // {
    //   name: "Description Information",
    //   isShowed: false,
    //   type: "textbox",
    // },
  ];

  const [fieldLabels, setFieldLabels] = useState(fieldDropdowns);
  const [showSearchFields, setShowSearchFields] = useState(fieldDropdowns);
  const [searchField, setSearchField] = useState("");
  const [selectedField, setSelectedField] = useState("");
  const reduxStore = useSelector((state) => state.reduxState);
  const { loginUserId } = reduxStore;
  const options = reduxStore.filterOptions.purchaseOrder;
  const [selectedOption, setSelectedOption] = useState(options[0]);
  const carrierOptions = [
    { value: "FedEX", label: "FedEX" },
    { value: "UPS", label: "UPS" },
    { value: "USPS", label: "USPS" },
    { value: "DHL", label: "DHL" },
    { value: "Blue Dart", label: "Blue Dart" },
  ];
  const stausOptions = [
    { value: "Created", label: "Created" },
    { value: "Approved", label: "Approved" },
    { value: "Delivered", label: "Delivered" },
    { value: "Cancelled", label: "Cancelled" },
  ];
  const [openActivities, setOpenActivities] = useState([]);
  const [openActivitiesCalls, setOpenActivitiesCalls] = useState([]);
  const [openActivitiesMeetings, setOpenActivitiesMeetings] = useState([]);
  const [closedActivitiesCall, setClosedActivitiesCall] = useState([]);
  const [closedActivitiesMeetings, setClosedActivitiesMeetings] = useState([]);
  const [closeActivities, setCloseActivities] = useState([]);
  const [singlePurchaseDetails, setSinglePurchaseDetails] = useState({
    purchaseInfoDetails: {},
    purchaseItemsInfoDetails: [],
    purchaseItemsTotalInfoDetails: {},
    purchaseVendorDetails: {},
    closedActivitiesDetails: [],
    openActivitiesDetails: [],
    productsList: [],
  });
  const {
    // purchaseInfoDetails,
    purchaseItemsInfoDetails,
    // purchaseItemsTotalInfoDetails,
    // purchaseVendorDetails,
    // closedActivitiesDetails,
    // openActivitiesDetails,
    // productsList,
  } = singlePurchaseDetails;
  const [purchaseInfo, setPurchaseInfo] = useState({
    purchaseOrderOwnerId: "",
    subject: "",
    poNumber: "",
    requisitionNumber: "",
    vendorName: "",
    vendorId: "",
    trackingNumber: "",
    contactId: "",
    poDate: new Date().toISOString().split("T")[0],
    dueDate: "",
    carrier: carrierOptions[0],
    exciseDuty: "",
    salesCommission: "",
    status: stausOptions[0],
    termsAndConditions: "",
    description: "",
    // billingStreet: "",
    // billingCity: "",
    // billingState: "",
    // billingCode: "",
    // billingCountry: "",
    // shippingStreet: "",
    // shippingCity: "",
    // shippingState: "",
    // shippingCode: "",
    // shippingCountry: "",
    loggedInUserId: "",
    purchaseItemDtlsDto: [],
    subTotal: 0,
    discount: 0,
    discountType: 1,
    discountValue: 0,
    tax: 0,
    adjustment: 0,
    grandTotal: 0,
  });
  const [billingAdd, setBillingAdd] = useState({
    billingStreet: "",
    billingCity: "",
    billingState: "",
    billingCode: "",
    billingCountry: "",
  });

  const [shippingAdd, setShippingAdd] = useState({
    shippingStreet: "",
    shippingCity: "",
    shippingState: "",
    shippingCode: "",
    shippingCountry: "",
  });

  const [purchaseItemsTotalInfoDetails, setPurchaseItemsTotalInfoDetails] =
    useState([]);
  const [noteInfo, setNoteInfo] = useState([]);
  const [addTitle, setAddTitle] = useState("");
  const [note, setNote] = useState("");
  const [selectedNoteFiles, setSelectedNoteFiles] = useState([]);
  const [showTitleNote, setShowTitleNote] = useState(false);
  const [noteTitle, setNoteTitle] = useState("");
  const [showButtons, setShowButtons] = useState(false);
  const [selectRecent, setSelectRecent] = useState("ALL");
  const [subjectError, setSubjectError] = useState("");
  const [vendorList, setVendorList] = useState([]);
  const [contactList, setContactList] = useState([]);
  const [products, setProductList] = useState([]);
  const [noteDeleteConfirmation, setNoteDeleteConfirmation] = useState(false);

  const userList = JSON.parse(sessionStorage.getItem("userList"));

  const [listVisibleType, setListVisibleType] = useState("");
  const [selectedOwner, setSelectedOwner] = useState(null);
  const [selectedContact, setSelectedContact] = useState({
    contactName: fetchContactInfo?.contactName,
    contactId: "",
  });
  const [selectedVendor, setSelectedVendor] = useState({
    vendorName: "",
    vendorId: "",
  });
  const [selectedProduct, setSelectedProduct] = useState({
    productName: "",
    productId: "",
  });
  const [selectedProductIndex, setSelectedProductIndex] = useState("");
  const [shareModal, setShareModal] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState(0); // Total number of items
  const [perPage, setPerPage] = useState(10);
  const [showType, setShowType] = useState("");
  const [timeLineDataList, setTimeLineDataList] = useState([]);
  const [allProducts, setAllProducts] = useState([
    {
      productId: "",
      productName: "",
      quantity: 1,
      listPrice: "",
      amount: 1,
      discount: 0,
      tax: 0,
      total: 1,
      description: "",
      discountType: 1,
      discountValue: 0,
    },
  ]);
  const [discountObj, setDiscountObj] = useState({
    type: 1,
    value: 0,
  });
  const [selectedTax, setSelectedTax] = useState(taxOptions[0]);
  const [popoutType, setPopoutType] = useState("");
  const [discountType, setDiscountType] = useState("percentage");
  const [editingFields, setEditingFields] = useState({
    purchaseOwner: false,
    // purchaseOrderOwnerId: false,
    subject: false,
    poNumber: false,
    requisitionNumber: false,
    vendorName: false,
    trackingNumber: false,
    poDate: false,
    dueDate: false,
    carrier: false,
    exciseDuty: false,
    salesCommission: false,
    status: false,
    termsAndConditions: false,
    description: false,
    billingStreet: false,
    billingCity: false,
    billingState: false,
    billingCode: false,
    billingCountry: false,
    shippingStreet: false,
    shippingCity: false,
    shippingState: false,
    shippingCode: false,
    shippingCountry: false,
  });
  const [massUpdateValue, setMassUpdateValue] = useState("");
  const [selectedObj, setSelectedObj] = useState({
    name: "Subject",
    isShowed: false,
    type: "input",
  });
  const [errorMessage, setErrorMessage] = useState("");
  const [selectedCheckboxes, setSelectedCheckboxes] = useState([]);
  // const [filterView, setFilterView] = useState(false); setFilterView is not used anywhere so i am commenting this
  const [isLoading, setIsLoading] = useState(true);
  const [listVisible, setListVisible] = useState(false);
  const [clickedDropdownObj, setClickedDropdownObj] = useState({
    label: "",
    value: "",
  });
  const [isLeadOwnerModal, setIsLeadOwnerModal] = useState(false);
  const [isContactListModal, setIsContactListModal] = useState(false);
  const [isVendorListModal, setIsVendorListModal] = useState(false);
  const [searchContact, setSearchContact] = useState("");
  const [modalContactDetails, setModalContactDetails] = useState([]);
  const [currentPageContact, setCurrentPageContact] = useState(1);
  const [perPageContact, setPerPageContact] = useState(10);
  const [totalItemsContact, setTotalItemsContact] = useState(0);

  const totalPagesContact = Math.ceil(
    Number(totalItemsContact) / perPageContact
  );

  const hadlePageChangeContact = (page) => {
    setCurrentPageContact(page);
  };

  const handlePageDropDownChangeContact = (selectedOption) => {
    setCurrentPageContact(1);
    setPerPageContact(parseInt(selectedOption.value));
  };

  const filteredContactDetails = modalContactDetails.filter((item) =>
    item.contactName?.toLowerCase().includes(searchContact?.toLowerCase())
  );

  const handleSearchChangeContact = (e) => {
    const { value } = e.target;
    if (value.trim() !== "" || value === "") {
      setSearchContact(value);
    }
  };

  // for save and new && save lottie loader
  const [saveSubmit, setSaveSubmit] = useState(false);
  const [saveAndNewSubmit, setSaveAndNewSubmit] = useState(false);

  const handleChangeBillingAddress = (e) => {
    const { name, value } = e.target;

    setBillingAdd((prevPurchaseInfo) => ({
      ...prevPurchaseInfo,
      [name]: value,
    }));
  };

  const handleChangeShippingAddress = (e) => {
    const { name, value } = e.target;

    setShippingAdd((prevPurchaseInfo) => ({
      ...prevPurchaseInfo,
      [name]: value,
    }));
  };

  const handleOwnerInputClick = () => {
    setListVisible(!listVisible);
  };

  const handleSelectCheckboxChange = (event) => {
    const { value } = event.target;

    const selectedCheckbox = { moduleId: value };

    setSelectedCheckboxes((prevSelectedCheckboxes) => {
      const isAlreadySelected = prevSelectedCheckboxes.some(
        (checkbox) => checkbox.moduleId === value
      );

      if (isAlreadySelected) {
        return prevSelectedCheckboxes.filter(
          (checkbox) => checkbox.moduleId !== value
        );
      } else {
        return [...prevSelectedCheckboxes, selectedCheckbox];
      }
    });
  };

  const getAllInfoShare = async (purchaseOrdId) => {
    setIsLoading(true);
    const requestBody = {
      moduleId: purchaseOrdId,
      loggedInUserId: loginUserId,
    };

    const headersResult = getHeaders(true);

    // Check if headers result indicates an error
    if (headersResult.error) {
      navigate("../"); // Redirect to login if token is missing
      return;
    }

    await axios
      .post(URI.getShareInfo, requestBody, {
        headers: headersResult,
      })
      .then((response) => {
        if (response.data.status === 200) {
          const hasSharedItems =
            (response.data.data.getSharingInfoUsers &&
              response.data.data.getSharingInfoUsers.length > 0) ||
            (response.data.data.getSharingInfoRoles &&
              response.data.data.getSharingInfoRoles.length > 0) ||
            (response.data.data.getSharingInfoGroups &&
              response.data.data.getSharingInfoGroups.length > 0) ||
            (response.data.data.getSharingInfoPublic &&
              response.data.data.getSharingInfoPublic.length > 0);

          const userShareMemberIds = response.data.data.getSharingInfoUsers.map(
            (user) => user.shareModuleId
          );
          const roleShareMemberIds = response.data.data.getSharingInfoRoles.map(
            (role) => role.shareModuleId
          );
          const groupShareMemberIds =
            response.data.data.getSharingInfoGroups.map(
              (group) => group.shareModuleId
            );
          const groupPublicSharedMemberIds =
            response.data.data.getSharingInfoPublic.map(
              (user) => user.shareModuleId
            );
          setShareMemberIds([
            ...userShareMemberIds,
            ...roleShareMemberIds,
            ...groupShareMemberIds,
            ...groupPublicSharedMemberIds,
          ]);

          setShareUserList(response.data.data.getSharingInfoUsers);
          setShareRoleList(response.data.data.getSharingInfoRoles);
          setShareGroupList(response.data.data.getSharingInfoGroups);
          setSharePublicList(response.data.data.getSharingInfoPublic);
          if (hasSharedItems) {
            setShareInfoModal(true);
          } else {
            setShareModal(true);
          }
          setIsLoading(false);
        }
      })
      .catch((err) => {
        handleApiError(err, navigate);
      });
  };

  const deleteUpdateTransferMass = async () => {
    // const { value } = selectedObj;
    const value = selectedObj.name
      ?.replace(/\s+/g, "")
      .replace(/^(.)/, (match) => match.toLowerCase());
    let url = URI.massDelete;
    const requestBody = {
      loggedInUserId: loginUserId,
      moduleName: "PURCHASE ORDERS",
      massAction: modalType === "Mass Delete" ? "DELETE" : "EDIT",
      moduleIdList: selectedCheckboxes,
    };
    if (modalType === "Mass Update") {
      if (value === "subject" && massUpdateValue.length === 0) {
        setErrorMessage("Value can not be empty");
        setTimeout(() => {
          setErrorMessage("");
        }, 3000);
        return;
      }

      requestBody[value] = ["Due Date", "PO Date"].includes(selectedObj.name)
        ? requiredDateFormatConversion(massUpdateValue)
        : massUpdateValue || null;

      // requestBody[value] = massUpdateValue;
      url = URI.massUpdate;
    } else if (modalType === "Mass Transfer") {
      requestBody.ownerId = selectedOwner.userId;
      url = URI.massTransfer;
    }

    const headersResult = getHeaders(true);

    // Check if headers result indicates an error
    if (headersResult.error) {
      navigate("../"); // Redirect to login if token is missing
      return;
    }

    await axios
      .post(url, requestBody, {
        headers: headersResult,
      })
      .then((response) => {
        if (response.data.status === 200) {
          NotificationManager.success("", response.data.message, 5000);
          getAllPurchaseOrders(selectedOption);
          setSelectedCheckboxes([]);
          setInnerPage("list");
          setShowModalType("");
          setShowSearchFields(fieldDropdowns);
          setMassUpdateValue("");
        } else {
          NotificationManager.error("", response.data.message, 5000);
        }
      })
      .catch(function (error) {
        handleApiError(error, navigate);
      });
  };

  const getAllContact = async () => {
    const requestBody = {
      loggedInUserId: loginUserId,
      pageNo: currentPageContact,
      pageSize: perPageContact,
    };

    const headersResult = getHeaders(true);

    // Check if headers result indicates an error
    if (headersResult.error) {
      navigate("../"); // Redirect to login if token is missing
      return;
    }

    await axios
      .post(URI.getAllContact, requestBody, {
        headers: headersResult,
      })
      .then((response) => {
        if (response.data.status === 200) {
          setContactList(response.data.data.allContactDetails);
          setModalContactDetails(response.data.data.allContactDetails);
          setTotalItemsContact(response.data.data.rowCount);
        }
      })
      .catch((err) => {
        handleApiError(err, navigate);
      });
  };

  const handlePageDropDowmChange = (selectedOption) => {
    setCurrentPage(1);
    setPerPage(parseInt(selectedOption.value));
  };

  const getAllProduct = async () => {
    const requestBody = {
      loggedInUserId: loginUserId,
      pageNo: 1,
      pageSize: 20,
    };

    const headersResult = getHeaders(true);

    // Check if headers result indicates an error
    if (headersResult.error) {
      navigate("../"); // Redirect to login if token is missing
      return;
    }

    await axios
      .post(URI.getAllProduct, requestBody, {
        headers: headersResult,
      })
      .then((response) => {
        if (response.data.status === 200) {
          if (response.data.data.allProductDetails.length > 0) {
            setProductList(response.data.data.allProductDetails);
            setAllProducts([
              {
                description:
                  response.data.data.allProductDetails[0].description,
                productId: response.data.data.allProductDetails[0].productId,
                productName:
                  response.data.data.allProductDetails[0].productName,
                quantity:
                  response.data.data.allProductDetails[0].unitPrice || 1,
                listPrice:
                  response.data.data.allProductDetails[0].unitPrice || 0,
                amount:
                  response.data.data.allProductDetails[0].unitPrice *
                    response.data.data.allProductDetails[0].unitPrice || 0,
                discount: 0,
                tax:
                  parseFloat(response.data.data.allProductDetails[0].tax) || 0,
                total: isNaN(response.data.data.allProductDetails[0].total)
                  ? 0
                  : response.data.data.allProductDetails[0].total,
                discountType: 1,
                discountValue: 0,
              },
            ]);
          }
        }
      })
      .catch(async (error) => {
        handleApiError(error, navigate);
      });
  };

  const getAllVendorDetails = async () => {
    const requestBody = {
      loggedInUserId: loginUserId,
      pageNo: currentPageVendor,
      pageSize: perPageVendor,
    };

    const headersResult = getHeaders(true);

    // Check if headers result indicates an error
    if (headersResult.error) {
      navigate("../"); // Redirect to login if token is missing
      return;
    }

    await axios
      .post(URI.getAllVendors, requestBody, {
        headers: headersResult,
      })
      .then((response) => {
        if (response.data.status === 200) {
          setVendorList(response.data.data.allVendorsDtls);
          setModalVendorDetails(response.data.data.allVendorsDtls);
          setTotalItemsVendor(response.data.data.rowCount);
          setSelectedVendor(response.data.data.allVendorsDtls[0]);
        }
      })
      .catch(async (error) => {
        handleApiError(error, navigate);
      });
  };

  const handleSelectRecent = (item) => {
    setSelectRecent(item);
    var value = "";
    if (item === "Recent Last") {
      value = "RECENT_LAST";
    } else if (item === "Recent First") {
      value = "RECENT_FIRST";
    } else {
      value = item;
    }
    getNotesDetails(purchaseOrdId, value);
  };

  const handleAddTitleClick = () => {
    setShowTitleNote(true);
  };

  const handleTextFileChange = (event) => {
    const files = Array.from(event.target.files);
    if (files) {
      setSelectedNoteFiles(files);
    } else {
      setSelectedNoteFiles([]);
    }
  };

  const handleChangeNoteTitle = (e) => {
    const { name, value } = e.target;
    if (value.length === 1 && value[0] === ' ') {
      return; 
    }
    if (editNote) {
      setUpdateNotes((prevLeadInfo) => ({
        ...prevLeadInfo,
        [name]: value,
      }));
    } else {
      setAddTitle(value);
    }
  };

  const handleChildDataChange = (dataFromChild) => {
    const deletedImg = dataFromChild.toString("");
    setDeletedImg(deletedImg);
  };

  const handleChangeNoteDescription = (e) => {
    const { name, value } = e.target;
    if (value.length === 1 && value[0] === ' ') {
      return; 
    }
    if (editNote) {
      setUpdateNotes((prevLeadInfo) => ({
        ...prevLeadInfo,
        [name]: value,
      }));
    } else {
      setNote(value); // Directly set the input value
    }
  };

  const insertNote = () => {
    const formData = new FormData();
    formData.append("moduleId", purchaseOrdId);
    formData.append("loggedInUserId", loginUserId);
    formData.append("moduleName", "PURCHASES");
    formData.append("noteDescription", removeExtraSpaces(note));
    formData.append("noteTitle", removeExtraSpaces(addTitle));

    selectedNoteFiles.forEach((file) => {
      formData.append("noteImages", file);
    });

    const headersResult = getHeaders(true);

    // Check if headers result indicates an error
    if (headersResult.error) {
      navigate("../"); // Redirect to login if token is missing
      return;
    }

    axios
      .post(URI.insertNote, formData, {
        headers: headersResult,
      })
      .then((response) => {
        if (response.data.status === 200) {
          setShowButtons(false);
          setAddTitle("");
          setNote("");
          setSelectedNoteFiles([]);
          // getNotesDetails(newNoteId,leadId);
          // Pass newNoteId to fetchNoteImage
          getNotesDetails(purchaseOrdId, selectRecent);
        } else {
          NotificationManager.error("", response.data.message, 5000);
        }
      })
      .catch((error) => {
        handleApiError(error, navigate);
      });
  };

  const updateNote = (noteId) => {
    const formData = new FormData();
    formData.append("moduleId", purchaseOrdId);
    formData.append("loggedInUserId", loginUserId);
    formData.append("moduleName", "PURCHASES");
    formData.append(
      "noteDescription",
      removeExtraSpaces(updateNotes.description)
    );
    formData.append("noteTitle", removeExtraSpaces(updateNotes.title));
    formData.append("noteId", noteId);
    formData.append("deletedImageNames", deletedImg.toString());

    selectedNoteFiles.forEach(() => {
      formData.append("noteImages", updateNotes.noteImageList);
    });

    const headersResult = getHeaders(true);

    // Check if headers result indicates an error
    if (headersResult.error) {
      navigate("../"); // Redirect to login if token is missing
      return;
    }

    axios
      .post(URI.updateNote, formData, {
        headers: headersResult,
      })
      .then((response) => {
        if (response.data.status === 200) {
          setShowButtons(false);
          setEditNote(false);
          NotificationManager.success("", response.data.message, 5000);
          setUpdateNotes({
            description: "",
            title: "",
            noteImageList: [],
          });
          setSelectedNoteFiles([]);
          getNotesDetails(purchaseOrdId, selectRecent);
          setDeletedImg("");
        } else {
          setDeletedImg("");
          setSelectedNoteFiles([]);
          NotificationManager.error("", response.data.message, 5000);
        }
      })
      .catch((error) => {
        handleApiError(error, navigate);
      });
  };

  const deleteNoteDetails = (id, noteId) => {
    const formData = new FormData();
    formData.append("moduleId", id);
    formData.append("loggedInUserId", loginUserId);
    formData.append("moduleName", "PURCHASES");
    formData.append("noteId", noteId);

    const headersResult = getHeaders(true);

    // Check if headers result indicates an error
    if (headersResult.error) {
      navigate("../"); // Redirect to login if token is missing
      return;
    }

    axios
      .post(URI.deleteNote, formData, {
        headers: headersResult,
      })
      .then((response) => {
        if (response.data.status === 200) {
          NotificationManager.success("", response.data.message, 3000);
          getNotesDetails(id, selectRecent);
          setNoteDeleteConfirmation(false);
        } else {
          NotificationManager.error("", response.data.message, 5000);
        }
      })
      .catch((error) => {
        handleApiError(error, navigate);
      });
  };

  const scrollToSection = (sectionId) => {
    setOrderPageView("overview");
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: "smooth" });
    }
  };

  const handleAddRow = () => {
    const { unitPrice, tax, productId, productName, description } = products[0];
    const discountedAmount = parseFloat(unitPrice) * parseFloat(unitPrice) - 0;
    const taxAmount = (parseFloat(tax || 0) / 100) * discountedAmount;
    const total = (discountedAmount + taxAmount).toFixed(2) || 0;
    setAllProducts([
      ...allProducts,
      {
        // slNo: 1,
        productId: productId,
        description: description,
        productName: productName,
        quantity: unitPrice || 1,
        listPrice: unitPrice,
        amount: unitPrice * unitPrice,
        discount: 0,
        tax: parseFloat(tax) || 0,
        total: isNaN(total) ? 0 : total,
        // total: products[0].unitPrice * products[0].unitPrice,
        discountType: 1,
        discountValue: 0,
      },
    ]);

    const productsTotal = allProducts
      .reduce(
        (sum, product) =>
          parseFloat(sum) +
          parseFloat(isNaN(product.total) ? 0 : product.total),
        0
      )
      ?.toFixed(2);

    const subTotalValue =
      Number(productsTotal) + Number(isNaN(total) ? 0 : total);

    setPurchaseInfo((prevPurchaseInfo) => ({
      ...prevPurchaseInfo,
      subTotal: subTotalValue,
      // grandTotal: subTotalValue?.toFixed(2) || 0,
      grandTotal: parseFloat(
        subTotalValue - Number(purchaseInfo.discount || 0)
      ).toFixed(2),
    }));
  };

  const handleIndividualInputChange = (field, value) => {
    setPurchaseInfo((prevState) => ({
      ...prevState,
      [field]: value,
    }));
  };
  const handleEditClick = (field) => {
    if (Object.values(editingFields).some((value) => value)) {
      return;
    }
    setEditingFields((prevFields) => ({
      ...prevFields,
      [field]: true,
    }));
  };

  const handleCancelClick = (field) => {
    setEditingFields((prevFields) => ({
      ...prevFields,
      [field]: false,
    }));

    setPurchaseInfo((prevFields) => ({
      ...prevFields,
      [field]:
        field === "carrier"
          ? {
              value: singlePurchaseDetails.purchaseInfoDetails[field],
              label: singlePurchaseDetails.purchaseInfoDetails[field],
            }
          : singlePurchaseDetails.purchaseInfoDetails[field] || "",
    }));
  };

  const handleDeleteRow = (index) => {
    if (allProducts.length === 1) {
      return; // Prevent deleting the last row
    }
    const newRows = [...allProducts];
    newRows.splice(index, 1);
    setAllProducts(newRows);

    const subTotalValue = newRows.reduce(
      (sum, product) => parseFloat(sum) + parseFloat(product.total),
      0
    );
    setPurchaseInfo((prevPurchaseInfo) => ({
      ...prevPurchaseInfo,
      subTotal: subTotalValue || 0,
      grandTotal: parseFloat(
        subTotalValue - parseFloat(purchaseInfo.discount || 0)
      ).toFixed(2),
    }));
  };

  const handleChange = (selectedOption) => {
    setSelectedOption(selectedOption);
    getAllPurchaseOrders(selectedOption);
  };

  const handleRowChange = (index, e) => {
    const { name, value } = e.target;
    // praseFloat removes all special characters include - and +

    let discountedAmount;
    let taxAmount;
    const newRows = [...allProducts];
    if (name === "quantity") {
      const getValue = value.replace(/[^0-9+\-.]+/g, "");
      newRows[index].quantity = getValue;
      const useValue = getValue === "" ? 0 : getValue;
      discountedAmount =
        useValue * parseFloat(newRows[index].listPrice) -
        parseFloat(newRows[index].discount);
      taxAmount =
        (parseFloat(newRows[index].tax || 0) / 100) * discountedAmount;

      newRows[index].amount =
        (useValue * parseFloat(newRows[index].listPrice)).toFixed(2) || 0;
      newRows[index].total =
        (discountedAmount + taxAmount).toFixed(2) || "0.00";
    } else if (name === "listPrice") {
      const getValue = value.replace(/[^0-9+\-.]+/g, "");
      newRows[index].listPrice = getValue;
      const useValue = getValue === "" ? 0 : getValue;
      discountedAmount =
        useValue * parseFloat(newRows[index].quantity) -
        parseFloat(newRows[index].discount);
      taxAmount =
        (parseFloat(newRows[index].tax || 0) / 100) * discountedAmount;

      newRows[index].amount = (
        useValue * parseFloat(newRows[index].quantity)
      ).toFixed(2);

      newRows[index].total =
        /// / !(discountedAmount) && !isNaN(taxAmount)
        //   ?
        (discountedAmount + taxAmount).toFixed(2) || "0.00";
    } else if (name === "description") {
      newRows[index][name] = value;
    }

    setAllProducts(newRows);

    const subTotalValue =
      newRows.reduce(
        (sum, product) => parseFloat(sum) + parseFloat(product.total),
        0
      ) || 0;
    setPurchaseInfo((prevPurchaseInfo) => ({
      ...prevPurchaseInfo,
      subTotal: parseFloat(subTotalValue).toFixed(2) || 0,
      grandTotal: parseFloat(
        subTotalValue - parseFloat(purchaseInfo.discount || 0)
      ).toFixed(2),
    }));
  };

  const minDate = moment("2000-01-01").format("YYYY-MM-DD");
  const maxDate = moment("2050-12-31").format("YYYY-MM-DD");

  /* eslint-disable no-useless-escape */
  const sanitizeExpression = (expression) => {
    const match = expression.match(/^\s*-?\d+(\.\d+)?([+\-*\/]-?\d+(\.\d+)?)*/);
    if (match) {
      return match[0].trim();
    } else {
      return "Invalid input";
    }
  };

  /* eslint-enable no-useless-escape */
  const handleChangePurchase = (e) => {
    const { name, value } = e.target;
    const formattedValue = ["poDate", "dueDate"].includes(name)
      ? value?.split("-").reverse().join("-")
      : value;

    if (name === "exciseDuty") {
      handleTooltipForSales(
        value,
        setShowTooltipExciseDuty,
        setTooltipContentExciseDuty
      );
    } else if (name === "salesCommission") {
      handleTooltipForSales(
        value,
        setShowTooltipSalesCommission,
        setTooltipContentSalesCommission
      );
    }

    setPurchaseInfo((prevPurchaseInfo) => ({
      ...prevPurchaseInfo,
      [name]: formattedValue,
    }));
  };

  /* eslint-disable no-useless-escape */
  const handleTooltipForSales = (value, setShowTooltip, setTooltipContent) => {
    const regex = /^[\d.\+\-\*\/\(\)]+$/;
    if (regex.test(value)) {
      try {
        const sanitizedValue = removeLeadingZeros(value);
        const previewResult = eval(sanitizedValue);
        setTooltipContent(previewResult.toString());
      } catch (error) {
        setTooltipContent(sanitizeExpression(value));
      }
      setShowTooltip(true);
    } else {
      setShowTooltip(false);
    }
  };

  /* eslint-disable no-useless-escape */
  const calculateResultForSales = (field) => {
    const value = purchaseInfo[field].trim();
    const sanitizedValue = value;
    const regex = /^[\d.\+\-\*\/\(\)]+$/;

    if (regex.test(sanitizedValue)) {
      try {
        const cleanedValue = removeLeadingZeros(sanitizedValue);
        let result = eval(cleanedValue);
        if (typeof result === "number" && result % 1 !== 0) {
          const decimalPart = result.toString().split(".")[1];
          if (decimalPart && decimalPart.length > 2) {
            result = parseFloat(result).toFixed(2);
          }
        }
        setPurchaseInfo((prevPurchaseInfo) => ({
          ...prevPurchaseInfo,
          [field]: result.toString(),
        }));
      } catch (error) {
        setPurchaseInfo((prevPurchaseInfo) => ({
          ...prevPurchaseInfo,
          [field]: sanitizeExpression(value),
        }));
      }
    }
  };

  const handleKeyPress = (event) => {
    const allowedCharacters = /[0-9.\+\-\*\/\(\)]/;
    const char = event.key;
    if (!allowedCharacters.test(char)) {
      event.preventDefault();
    }
  };
  /* eslint-enable no-useless-escape */

  const handleBlurForSales = (field) => {
    calculateResultForSales(field);
    hideAllTooltips();
  };

  const hideAllTooltips = () => {
    setShowTooltipExciseDuty(false);
    setShowTooltipSalesCommission(false);
  };

  const tooltipStyles = {
    position: "absolute",
    top: "100%",
    left: "0",
    backgroundColor: "#f9f9f9",
    border: "1px solid #d3d3d3",
    padding: "5px",
    borderRadius: "3px",
    boxShadow: "0 2px 5px rgba(0,0,0,0.2)",
    zIndex: 1000,
    height: "fit-content",
    width: "fit-content",
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const totalPages = Math.ceil(Number(totalItems) / perPage);

  const fieldDropdownStyles = {
    menu: (base) => ({
      ...base,
      width: "100%",
    }),
    option: (provided) => ({
      ...provided,
      // backgroundColor: "#EAEAEA",
      color: "#000",
      // backgroundColor: 'rgba(33, 119, 254, 0.1)',
      width: "100%",
    }),
    control: (baseStyles) => ({
      ...baseStyles,
      border: 0,
      // backgroundColor: 'rgba(33, 119, 254, 0.1)',
      height: "20px",
      borderRadius: "10px",
      // width: "75%",
      boxShadow: "none",
    }),
    input: (base) => ({
      ...base,
      color: "#FFFFFF",
    }),
    singleValue: (defaultStyles) => ({ ...defaultStyles, color: "#000" }),

    menuList: (provided) => ({
      ...provided,
      paddingTop: 0,
      paddingBottom: 0,
      "&::-webkit-scrollbar": {
        width: 5,
      },

      "&::-webkit-scrollbar-thumb": {
        background: "#316AFF",
        borderRadius: "10px",
      },
    }),
  };

  const createLeadStyles = {
    menu: (base) => ({
      ...base,
      width: "60%",
    }),
    option: (provided) => ({
      ...provided,
      // backgroundColor: "#EAEAEA",
      color: "#000",
      // backgroundColor: 'rgba(33, 119, 254, 0.1)',
      width: "100%",
    }),
    control: (baseStyles) => ({
      ...baseStyles,
      border: "1px solid #b9b9b9b0",
      backgroundColor: "#F3F6FB",
      height: "40px",
      borderRadius: "4px",
      width: "60%",
      boxShadow: "none",
    }),
    input: (base) => ({
      ...base,
      color: "#FFFFFF",
    }),
    singleValue: (defaultStyles) => ({ ...defaultStyles, color: "#000" }),

    menuList: (provided) => ({
      ...provided,
      paddingTop: 0,
      paddingBottom: 0,
      "&::-webkit-scrollbar": {
        width: 5,
      },

      "&::-webkit-scrollbar-thumb": {
        background: "#316AFF",
        borderRadius: "10px",
      },
    }),
  };

  const handleChangeSelectDropDown = (selectedOption, field) => {
    setPurchaseInfo((prevLeadInfo) => ({
      ...prevLeadInfo,
      [field]: selectedOption,
    }));
  };

  const getAllPurchaseOrders = async (selectedOption) => {
    setIsLoading(true);
    const requestBody = {
      loggedInUserId: loginUserId,
      customFilter: selectedOption.value,
      pageNo: currentPage,
      pageSize: perPage,
    };

    const headersResult = getHeaders(true);

    // Check if headers result indicates an error
    if (headersResult.error) {
      navigate("../"); // Redirect to login if token is missing
      return;
    }

    await axios
      .post(URI.getAllPurchaseDetails, requestBody, {
        headers: headersResult,
      })

      .then((response) => {
        if (response.data.status === 200) {
          setAllPurchaseOrders(response.data.data.getAllPurchaseOrder);
          setFilteredPurchaseOrders(response.data.data.getAllPurchaseOrder);
          setTotalItems(response.data.data.rowCount);
          setIsLoading(false);
        } else {
          setAllPurchaseOrders([]);
          setFilteredPurchaseOrders([]);
          NotificationManager.error("", response.data.message, 4000);
          setIsLoading(false);
        }
      })
      .catch(async (error) => {
        handleApiError(error, navigate);
      });
  };

  const getNotesDetails = async (purchaseID, orderType) => {
    const requestBody = {
      loggedInUserId: loginUserId,
      moduleId: purchaseID,
      orderType: orderType,
    };

    const headersResult = getHeaders(true);

    // Check if headers result indicates an error
    if (headersResult.error) {
      navigate("../"); // Redirect to login if token is missing
      return;
    }

    await axios
      .post(URI.getNote, requestBody, {
        headers: headersResult,
      })
      .then((response) => {
        if (response.data.status === 200) {
          setNoteInfo(response.data.data.noteInfo);
        } else {
          NotificationManager.error("", response.data.message, 5000);
        }
      })
      .catch(async (error) => {
        handleApiError(error, navigate);
      });
  };

  const purchaseOrderOverview = async (purchaseID) => {
    const headersResult = getHeaders(true);

    // Check if headers result indicates an error
    if (headersResult.error) {
      navigate("../"); // Redirect to login if token is missing
      return;
    }

    await axios
      .get(`${URI.getOverviewPurchaseDetails}${loginUserId}/${purchaseID}`, {
        headers: headersResult,
      })
      .then((response) => {
        if (response.data.status === 200) {
          setInnerPage("overview");
          setOrderPageView("overview");
          setSinglePurchaseDetails(response.data.data);
          setOpenActivities(response.data.data.openActivitiesDetails);
          setOpenActivitiesCalls(response.data.data.openActivitiesCalls);
          setOpenActivitiesMeetings(response.data.data.openActivitiesMeetings);
          setClosedActivitiesCall(response.data.data.closedActivitiesCalls);
          setClosedActivitiesMeetings(
            response.data.data.closedActivitiesMeetings
          );
          setCloseActivities(response.data.data.closedActivitiesDetails);
          const ownerNameObj = userList.find(
            (item) =>
              item.name === response.data.data.purchaseInfoDetails.purchaseOwner
          );
          const contactNameObj = contactList.find(
            (item) =>
              item.contactName ===
              response.data.data.purchaseInfoDetails.contactName
          );
          const vendorNameObj = vendorList.find(
            (item) =>
              item.vendorId ===
              response.data.data?.purchaseVendorDetails?.vendorId
          );
          const obj = {
            ...response.data.data.purchaseInfoDetails,
            carrier: {
              label: response.data.data.purchaseInfoDetails.carrier,
              value: response.data.data.purchaseInfoDetails.carrier,
            },
            carrierInfo: {
              label: response.data.data.purchaseInfoDetails.carrier,
              value: response.data.data.purchaseInfoDetails.carrier,
            },
            statusInfo: {
              label: response.data.data.purchaseInfoDetails.status,
              value: response.data.data.purchaseInfoDetails.status,
            },
            status: {
              label: response.data.data.purchaseInfoDetails.status,
              value: response.data.data.purchaseInfoDetails.status,
            },
            poDate: response.data.data.purchaseInfoDetails.poDate,
            dueDate: response.data.data.purchaseInfoDetails.dueDate,
            poDateInfo: response.data.data.purchaseInfoDetails.poDate,
            dueDateInfo: response.data.data.purchaseInfoDetails.dueDate,
            subjectInfo: response.data.data.purchaseInfoDetails.subject,
            requisitionNumberInfo:
              response.data.data.purchaseInfoDetails.requisitionNumber,
            trackingNumberInfo:
              response.data.data.purchaseInfoDetails.trackingNumber,
            exciseDutyInfo: response.data.data.purchaseInfoDetails.exciseDuty,
            salesCommissionInfo:
              response.data.data.purchaseInfoDetails.salesCommission,
            poNumberInfo: response.data.data.purchaseInfoDetails.poNumber,
            billingCityInfo: response.data.data.purchaseInfoDetails.billingCity,
            billingStreetInfo:
              response.data.data.purchaseInfoDetails.billingStreet,
            billingStateInfo:
              response.data.data.purchaseInfoDetails.billingState,
            billingCodeInfo: response.data.data.purchaseInfoDetails.billingCode,
            billingCountryInfo:
              response.data.data.purchaseInfoDetails.billingCountry,
            shippingStreetInfo:
              response.data.data.purchaseInfoDetails.shippingStreet,
            shippingCityInfo:
              response.data.data.purchaseInfoDetails.shippingCity,
            shippingStateInfo:
              response.data.data.purchaseInfoDetails.shippingState,
            shippingCodeInfo:
              response.data.data.purchaseInfoDetails.shippingCode,
            shippingCountryInfo:
              response.data.data.purchaseInfoDetails.shippingCountry,
            purchaseOwner: ownerNameObj?.name,
            contactName: contactNameObj?.contactName,
            vendorName: vendorNameObj?.vendorName,
          };
          setPurchaseInfo(obj);
          ownerNameObj !== undefined && setSelectedOwner(ownerNameObj);
          vendorNameObj !== undefined && setSelectedVendor(vendorNameObj);
          contactNameObj !== undefined && setSelectedContact(contactNameObj);
          setPurchaseItemsTotalInfoDetails(
            response.data.data.purchaseItemsTotalInfoDetails
          );
          response.data.data.purchaseItemsInfoDetails.length > 0 &&
            setAllProducts(response.data.data.purchaseItemsInfoDetails);
        } else {
          setSinglePurchaseDetails({
            purchaseInfoDetails: {},
            purchaseItemsInfoDetails: [],
            purchaseItemsTotalInfoDetails: {},
            purchaseVendorDetails: {},
            closedActivitiesDetails: [],
            openActivitiesDetails: [],
            productsList: [],
          });
          NotificationManager.error("", response.data.message, 4000);
        }
      })
      .catch(async (error) => {
        handleApiError(error, navigate);
      });
  };

  const handleUpdateClick = async (field) => {
    var value = "";
    let requestBody = {};
    // const isObj =
    // typeof singlePurchaseDetails.purchaseInfoDetails[field] === "object" &&
    // singlePurchaseDetails.purchaseInfoDetails[field] !== null &&
    // / !Array.isArray(singlePurchaseDetails.purchaseInfoDetails[field]);

    if (
      field === "status" ||
      field === "carrier" ||
      field === "carrierInfo" ||
      field === "statusInfo"
    ) {
      value = purchaseInfo[field].value;
    } else {
      value = purchaseInfo[field];
    }
    const isDate = !isNaN(Date.parse(value));
    const fieldMappings = {
      billingCityInfo: "billingCity",
      billingStateInfo: "billingState",
      billingCodeInfo: "billingCode",
      billingStreetInfo: "billingStreet",
      billingCountryInfo: "billingCountry",
      shippingStreetInfo: "shippingStreet",
      shippingCityInfo: "shippingCity",
      shippingStateInfo: "shippingState",
      shippingCodeInfo: "shippingCode",
      shippingCountryInfo: "shippingCountry",
      poDateInfo: "poDate",
      dueDateInfo: "dueDate",
      carrierInfo: "carrier",
      statusInfo: "status",
      subjectInfo: "subject",
      requisitionNumberInfo: "requisitionNumber",
      trackingNumberInfo: "trackingNumber",
      exciseDutyInfo: "exciseDuty",
      salesCommissionInfo: "salesCommission",
      poNumberInfo: "poNumber",
    };
    // if (fieldMappings.hasOwnProperty(field)) {
    //   field = fieldMappings[field];
    // } not supported by eslint
    if (Object.hasOwn(fieldMappings, field)) {
      field = fieldMappings[field];
    }

    field === "emailNotification" && setApiLoader(true);
    try {
      requestBody = {
        purchaseOrdId: purchaseOrdId,
        [field]: isDate ? value : value,
        loggedInUserId: loginUserId,
      };
      if (field === "emailNotification") {
        requestBody = {
          purchaseOrdId: purchaseOrdId,
          loggedInUserId: loginUserId,
          purchaseOrderOwnerId: isOwnerSelect
            ? selectedOwner?.userId
            : selectedOwner?.userId,
          emailNotification: emailNotification,
        };
      } else {
        requestBody = {
          purchaseOrdId: purchaseOrdId,
          [field]: isDate ? value : value,
          loggedInUserId: loginUserId,
        };
      }

      if (
        value !== singlePurchaseDetails.purchaseInfoDetails[field] ||
        field === "emailNotification"
      ) {
        const headersResult = getHeaders(true);

        // Check if headers result indicates an error
        if (headersResult.error) {
          navigate("../"); // Redirect to login if token is missing
          return;
        }

        const response = await axios.post(URI.updatePurchase, requestBody, {
          headers: headersResult,
        });
        if (response.data.status === 200) {
          NotificationManager.success("", response.data.message, 4000);
          setShowModalType("");
          setPurchaseInfo((prevDetails) => ({
            ...prevDetails,
            [field]: singlePurchaseDetails.purchaseInfoDetails[field],
          }));
          purchaseOrderOverview(purchaseOrdId);
          getNotesDetails(purchaseOrdId, selectRecent);
        } else {
          NotificationManager.error("", response.data.message, 4000);
        }
      }
      const resetFields = Object.fromEntries(
        Object.keys(editingFields).map((key) => [key, false])
      );
      setEditingFields({
        ...resetFields,
      });
      setShowType("");
    } catch (error) {
      handleApiError(error, navigate);
    } finally {
      setApiLoader(false);
    }
  };

  const handleUpdateIdClick = async (field, value) => {
    const fieldName =
      field === "contactName"
        ? "contactId"
        : field === "vendorName"
        ? "vendorId"
        : "purchaseOrderOwnerId";

    try {
      const requestBody = {
        purchaseOrdId: purchaseOrdId,
        [fieldName]:
          field === "contactName"
            ? value.contactId
            : field === "vendorName"
            ? value.vendorId
            : value.userId,
        loggedInUserId: loginUserId,
      };
      if (field === "vendorName") {
        requestBody.vendorName = value.vendorName;
      }

      const headersResult = getHeaders(true);

      // Check if headers result indicates an error
      if (headersResult.error) {
        navigate("../"); // Redirect to login if token is missing
        return;
      }

      const response = await axios.post(URI.updatePurchase, requestBody, {
        headers: headersResult,
      });
      if (response.data.status === 200) {
        NotificationManager.success("", response.data.message, 4000);
        setPurchaseInfo((prevDetails) => ({
          ...prevDetails,
          [field]: singlePurchaseDetails.purchaseInfoDetails[field],
        }));

        setEditingFields((prevFields) => ({
          ...prevFields,
          [field]: false,
        }));
        purchaseOrderOverview(purchaseOrdId);
        getNotesDetails(purchaseOrdId, selectRecent);
      } else {
        NotificationManager.error("", response.data.message, 4000);
      }
    } catch (error) {
      handleApiError(error, navigate);
    }
  };

  const handleSaveButton = async (e) => {
    let missingFields = false;
    if (purchaseInfo.subject === "") {
      setSubjectError("Subject cannot be empty.");
      setTimeout(() => {
        setSubjectError("");
      }, 4000);
      missingFields = true;
    }
    if (purchaseInfo.subject && !validateCallSubject(purchaseInfo.subject)) {
      setSubjectError("Please enter a valid subject");
      setTimeout(() => {
        setSubjectError("");
      }, 4000);
      missingFields = true;
    }

    if (
      purchaseInfo.exciseDuty &&
      !validateCalcField(purchaseInfo.exciseDuty)
    ) {
      setExciseDutyMsg("Please enter a valid ExciseDuty.");
      setTimeout(() => {
        setExciseDutyMsg("");
      }, 4000);
      missingFields = true;
    }
    if (
      purchaseInfo.salesCommission &&
      !validateCalcField(purchaseInfo.salesCommission)
    ) {
      setSalesCommissionMsg(
        !validateCalcField(purchaseInfo.salesCommission)
          ? "Please enter a valid Sales Commission."
          : "Please enter a Sales Commission."
      );
      setTimeout(() => {
        setSalesCommissionMsg("");
      }, 4000);
      missingFields = true;
    }

    if (billingAdd.billingCity && !validateCityIndia(billingAdd.billingCity)) {
      setBillingCityError("Invalid billing city.");
      setTimeout(() => {
        setBillingCityError("");
      }, 4000);
      missingFields = true;
    }

    if (
      billingAdd.billingState &&
      !validateStateIndia(billingAdd.billingState)
    ) {
      setBillingStateError("Invalid billing state.");
      setTimeout(() => {
        setBillingStateError("");
      }, 4000);
      missingFields = true;
    }

    if (
      billingAdd.billingCountry &&
      !validateCountryName(billingAdd.billingCountry)
    ) {
      setBillingCountryError("Invalid billing country.");
      setTimeout(() => {
        setBillingCountryError("");
      }, 4000);
      missingFields = true;
    }

    if (billingAdd.billingCode && !validateZipCode(billingAdd.billingCode)) {
      setBillingCodeError("Invalid billing code.");
      setTimeout(() => {
        setBillingCodeError("");
      }, 4000);
      missingFields = true;
    }

    if (
      shippingAdd.shippingCity &&
      !validateCityIndia(shippingAdd.shippingCity)
    ) {
      setShippingCityError("Invalid shipping city.");
      setTimeout(() => {
        setShippingCityError("");
      }, 4000);
      missingFields = true;
    }
    if (
      shippingAdd.shippingState &&
      !validateStateIndia(shippingAdd.shippingState)
    ) {
      setShippingStateError("Invalid shipping state.");
      setTimeout(() => {
        setShippingStateError("");
      }, 4000);
      missingFields = true;
    }

    if (
      shippingAdd.shippingCountry &&
      !validateCountryName(shippingAdd.shippingCountry)
    ) {
      setShippingCountryError("Invalid shipping country.");
      setTimeout(() => {
        setShippingCountryError("");
      }, 4000);
      missingFields = true;
    }
    if (
      shippingAdd.shippingCode &&
      !validateZipCode(shippingAdd.shippingCode)
    ) {
      setShippingCodeError("Invalid shipping code.");
      setTimeout(() => {
        setShippingCodeError("");
      }, 4000);
      missingFields = true;
    }

    if (missingFields) {
      return;
    } else {
      e === "SAVE" ? setSaveSubmit(true) : setSaveAndNewSubmit(true);
      const requestBody = {
        loggedInUserId: loginUserId,
        purchaseOrderOwnerId: selectedOwner.userId,
        subject: removeExtraSpaces(purchaseInfo.subject),
        poNumber: purchaseInfo.poNumber,
        requisitionNumber: purchaseInfo.requisitionNumber || "",
        vendorName: selectedVendor?.vendorName || "",
        // vendorId: purchaseInfo.vendorId,
        vendorId: selectedVendor?.vendorId || "",
        trackingNumber: purchaseInfo.trackingNumber,
        // contactId: purchaseInfo.contactName,
        contactId: selectedContact?.contactId || fetchContactInfo?.contactId,
        poDate: purchaseInfo.poDate,
        dueDate: purchaseInfo.dueDate,
        carrier: purchaseInfo.carrier.value,
        exciseDuty: Number(purchaseInfo.exciseDuty),
        salesCommission: Number(purchaseInfo.salesCommission),
        status: purchaseInfo.status.value,
        termsAndConditions: purchaseInfo.termsAndConditions,
        description: removeExtraSpaces(purchaseInfo.description),
        billingStreet: removeExtraSpaces(billingAdd.billingStreet) || "",
        billingCity: removeExtraSpaces(billingAdd.billingCity) || "",
        billingState: removeExtraSpaces(billingAdd.billingState) || "",
        billingCode: removeExtraSpaces(billingAdd.billingCode) || "",
        billingCountry: removeExtraSpaces(billingAdd.billingCountry) || "",
        shippingStreet: removeExtraSpaces(shippingAdd.shippingStreet) || "",
        shippingCity: removeExtraSpaces(shippingAdd.shippingCity) || "",
        shippingState: removeExtraSpaces(shippingAdd.shippingState) || "",
        shippingCode: removeExtraSpaces(shippingAdd.shippingCode) || "",
        // shippingCountry: purchaseInfo.shippingCountry || "",
        purchaseItemDtlsDto: allProducts,
        // purchaseItemDtlsDto: allProducts[0].Quantity !== "" ? allProducts : [{}],
        subTotal: Number(purchaseInfo.subTotal),
        discount: Number(purchaseInfo.discount),
        discountType: Number(purchaseInfo.discountType),
        discountValue: Number(purchaseInfo.discountValue),
        tax: Number(purchaseInfo.tax),
        adjustment: Number(purchaseInfo.adjustment),
        grandTotal: Number(purchaseInfo.grandTotal),
        shippingCountry: removeExtraSpaces(shippingAdd.shippingCountry),
      };

      if (editPurchaseOverview) {
        const deleteItemDtlsDto = [];
        const allProductIds = allProducts.map(
          (product) => product.purchaseOrderItemsId
        );

        purchaseItemsInfoDetails.forEach((item) => {
          if (!allProductIds.includes(item.purchaseOrderItemsId)) {
            deleteItemDtlsDto.push({
              purchaseOrderItemsId: item.purchaseOrderItemsId,
            });
          }
        });
        // if (deleteItemDtlsDto.length > 0) {
        requestBody.deleteItemDtlsDto = deleteItemDtlsDto;
        // }
        requestBody.purchaseOrdId = purchaseOrdId;
      }

      const url = editPurchaseOverview
        ? URI.updateAllPurchase
        : URI.addPurchaseDetails;

      const headersResult = getHeaders(true);

      // Check if headers result indicates an error
      if (headersResult.error) {
        navigate("../"); // Redirect to login if token is missing
        return;
      }

      await axios
        .post(url, requestBody, {
          headers: headersResult,
        })
        .then((response) => {
          if (response.data.status === 200) {
            NotificationManager.success("", response.data.message, 4000);
            // handleChangePurchase();
            const discountedAmount =
              products[0].unitPrice * products[0].unitPrice - 0;
            const taxAmount =
              (parseFloat(products[0].tax || 0) / 100) * discountedAmount;
            setPurchaseInfo({
              purchaseOrderOwnerId: "",
              subject: "",
              poNumber: "",
              requisitionNumber: "",
              vendorName: "",
              vendorId: "",
              trackingNumber: "",
              contactId: "",
              poDate: new Date().toISOString().split("T")[0],
              dueDate: "",
              carrier: carrierOptions[0],
              exciseDuty: "",
              salesCommission: "",
              status: stausOptions[0],
              termsAndConditions: "",
              description: "",
              billingStreet: "",
              billingCity: "",
              billingState: "",
              billingCode: "",
              billingCountry: "",
              shippingStreet: "",
              shippingCity: "",
              shippingState: "",
              shippingCode: "",
              shippingCountry: "",
              loggedInUserId: "",
              purchaseItemDtlsDto: [],
              subTotal: (discountedAmount + taxAmount).toFixed(2) || 0,
              discountType: 1,
              discountValue: 0,
              discount: 0,
              tax: 0,
              adjustment: 0,
              grandTotal: (discountedAmount + taxAmount).toFixed(2) || 0,
            });

            setSinglePurchaseDetails({
              purchaseInfoDetails: {},
              purchaseItemsInfoDetails: [],
              purchaseItemsTotalInfoDetails: {},
              purchaseVendorDetails: {},
              closedActivitiesDetails: [],
              openActivitiesDetails: [],
              productsList: [],
            });

            setAllProducts([
              {
                productId: products[0].productId,
                description: products[0].description,
                productName: products[0].productName,
                quantity: products[0].unitPrice,
                listPrice: products[0].unitPrice,
                amount: products[0].unitPrice * products[0].unitPrice,
                discount: 0,
                tax: products[0].tax || 0,
                total: (discountedAmount + taxAmount).toFixed(2),
                // total: products[0].unitPrice * products[0].unitPrice,
                discountType: 1,
                discountValue: 0,
              },
            ]);
            getAllPurchaseOrders(selectedOption);
            if (pastViewInfo) {
              setCurrentView(pastViewInfo.view);
              setInnerPage(pastViewInfo.innerPage);
              setPastViewInfo((prev) => ({
                ...prev,
                view: "",
                innerPage: "",
                undo:
                  currentView === "purchaseOrders"
                    ? "purchaseOrder"
                    : currentView, // it will set the current view of the page so when we cancel or save it will go to same section in the page
              }));
              return;
            }
            if (e === "SAVE") {
              setInnerPage("list");
            }
          } else {
            NotificationManager.error("", response.data.message, 4000);
          }
        })
        .catch(function (error) {
          handleApiError(error, navigate);
        })
        .finally(() => {
          setSaveAndNewSubmit(false);
          setSaveSubmit(false);
        });
    }
  };

  const getAllTimeline = async () => {
    const headersResult = getHeaders(true);

    // Check if headers result indicates an error
    if (headersResult.error) {
      navigate("../"); // Redirect to login if token is missing
      return;
    }

    await axios
      .get(URI.getTimeline + loginUserId + "/" + purchaseOrdId, {
        headers: headersResult,
      })

      .then((response) => {
        if (response.data.status === 200) {
          setTimeLineDataList(response.data.data.getPurchaseTimelineDetails);
          setOrderPageView("timeline");
        } else {
          NotificationManager.error("", response.data.message, 4000);
        }
      })
      .catch(async (error) => {
        handleApiError(error, navigate);
      });
  };

  const deletePurchase = async () => {
    const requestBody = {
      loggedInUserId: loginUserId,
      purchaseOrdId: purchaseOrdId,
    };

    const headersResult = getHeaders(true);

    // Check if headers result indicates an error
    if (headersResult.error) {
      navigate("../"); // Redirect to login if token is missing
      return;
    }

    await axios
      .post(URI.deletePurchase, requestBody, {
        headers: headersResult,
      })

      .then((response) => {
        if (response.data.status === 200) {
          NotificationManager.success("", response.data.message, 5000);
          getAllPurchaseOrders(selectedOption);
          setShowModalType("");
          setInnerPage("list");
        } else {
          NotificationManager.error("", response.data.message, 5000);
          setShowModalType("");
        }
      })
      .catch(function (error) {
        handleApiError(error, navigate);
      });
  };

  const handleFieldClick = (field) => {
    if (field.type === "dropdown") {
      const fieldValue = field.name
        .replace(/\s+/g, "")
        .replace(/^(.)/, (match) => match.toLowerCase());

      // purchaseInfo -- need to update
      setPurchaseInfo((prevFields) => ({
        ...prevFields,
        [fieldValue]: singlePurchaseDetails.purchaseInfoDetails[fieldValue],
      }));
    }

    const updatedColumns = [...fieldLabels];

    const existedObj = updatedColumns.find(
      (field) => field.name === selectedField
    );
    const selectedObj = updatedColumns.find((item) => item.name === field.name);

    const existedObjIndex = updatedColumns.findIndex(
      (field) => field.name === existedObj.name
    );
    const selectedObjIndex = updatedColumns.findIndex(
      (field) => field.name === selectedObj.name
    );

    updatedColumns[existedObjIndex].isShowed = false;
    updatedColumns[selectedObjIndex].isShowed = true;

    const removedObj = updatedColumns.splice(existedObjIndex, 1)[0];
    updatedColumns.splice(existedObjIndex, 0, selectedObj);

    updatedColumns.push(removedObj);
    const uniqueData = updatedColumns.filter(
      (item, index, self) =>
        index === self.findIndex((t) => t.name === item.name)
    );
    setFieldLabels(uniqueData);
    setShowSearchFields(uniqueData);
  };

  const returnTotalValue = (item) => {
    const discountedAmount = item.unitPrice * item.unitPrice - 0;
    const taxAmount =
      (parseFloat(isNaN(item.tax) ? 0 : item.tax) / 100) * discountedAmount;
    return (discountedAmount + taxAmount).toFixed(2);
  };

  const handleUserItemClick = (field, item, i) => {
    !["create", "clone"].includes(innerPage) &&
      handleUpdateIdClick(field, item);
    setListVisibleType(field);
    field === "contactName"
      ? setSelectedContact(item)
      : field === "vendorName"
      ? setSelectedVendor(item)
      : field === "purchaseOwner"
      ? setSelectedOwner(item)
      : setSelectedProduct(item);

    if (field === "productId") {
      const newRows = [...allProducts];

      newRows[i] = {
        ...newRows[i],
        productId: item.productId,
        productName: item.productName,
        description: item.description,
        quantity: item.unitPrice || 1,
        listPrice: item.unitPrice,
        amount: item.unitPrice * item.unitPrice,
        discount: 0,
        tax: parseFloat(item.tax || 0),
        total: returnTotalValue(item),
        discountType: discountType === "percentage" ? 1 : 2,
        discountValue: 0,
      };
      setAllProducts(newRows);
      const subTotalValue = newRows.reduce(
        (sum, product) => parseFloat(sum) + parseFloat(product.total),
        0
      );
      setPurchaseInfo((prevPurchaseInfo) => ({
        ...prevPurchaseInfo,
        subTotal: parseFloat(subTotalValue).toFixed(2) || 0,
        grandTotal:
          parseFloat(subTotalValue).toFixed(2) -
          parseFloat(purchaseInfo.discount || 0),
      }));
    }
  };

  const handleSelectedLeadChange = (value) => {
    // listVisibleType === "contactName"
    //   ? setSelectedContact(value)
    //   : listVisibleType === "vendorName"
    //   ? setSelectedVendor(value)
    //   : listVisibleType === "purchaseOwner"
    // ?
    setSelectedOwner(value);
    // : setSelectedProduct(value);
  };

  const sortedColumnsData = (type, headingName) => {
    const sortedData = [...allPurchaseOrders];

    if (type === "asc") {
      sortedData.sort((a, b) => {
        if (
          typeof a[headingName] === "string" &&
          typeof b[headingName] === "string"
        ) {
          return a[headingName].localeCompare(b[headingName]);
        }
        return a[headingName] - b[headingName];
      });
    } else if (type === "desc") {
      sortedData.sort((a, b) => {
        if (
          typeof a[headingName] === "string" &&
          typeof b[headingName] === "string"
        ) {
          return b[headingName].localeCompare(a[headingName]);
        }
        return b[headingName] - a[headingName];
      });
    }
    setFilteredPurchaseOrders(sortedData);
  };

  const handlePinColumn = (name) => {
    setPinnedColumn(name);
    const updatedColumns = [...selectedHeadings];
    const clickedColumnIndex = updatedColumns.findIndex(
      (column) => column.name === name
    );

    if (clickedColumnIndex !== -1) {
      const clickedColumn = updatedColumns.splice(clickedColumnIndex, 1)[0];
      updatedColumns.unshift(clickedColumn);
      setSelectedHeadings(updatedColumns);
    }
  };

  const handleColumnCheckboxChange = (name, isChecked) => {
    setSavingHeadings((prevHeadings) =>
      prevHeadings.map((heading) =>
        heading.name === name ? { ...heading, isChecked } : heading
      )
    );
  };

  const nodataPage = () => {
    return (
      <div className="nodatapage d-flex flex-column justify-content-center align-items-center bg-white px-2 py-5 my-3 mx-5 rounded-3">
        <img src={PurchaseNoDataImage} alt="img" height={200} width={200} />
        <h5 className="opacity-75">Manage Purchase Order</h5>
        <p className="opacity-75 p-0 m-0">
          Quotes are legal agreements between a customer and a vector
        </p>
        <p className="opacity-75 p-0 m-0">
          to deliver the requested product(s) at the agreed upon price
        </p>
        <p className="opacity-75 p-0 m-0">within the specified time period.</p>
        <button
          className="createContactButtonSave px-2 mt-4 cursorPointer rounded-5"
          style={{ width: "19.5%", height: "42.5px" }}
          onClick={handleCreateNoDataPurchase}
        >
          Create a Purchase Order
        </button>
      </div>
    );
  };

  useEffect(() => {
    getAllPurchaseOrders(selectedOption);
  }, [perPage, currentPage]);

  useEffect(() => {
    const foundItem =
      userList && userList.find((item) => item.userId === loginUserId);
    if (foundItem) {
      setSelectedOwner(foundItem);
    }
  }, [loginUserId]);

  useEffect(() => {
    getAllProduct();
    // getAllVendorDetails();
    setInnerPage(innerPage || "list");
  }, []);

  useEffect(() => {
    getAllContact();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPageContact, perPageContact]);

  useEffect(() => {
    getAllVendorDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPageVendor, perPageVendor]);

  const accountsDropdown = (topGap, tableIndex) => {
    const list =
      listVisibleType === "purchaseOwner"
        ? userList
        : listVisibleType === "contactName"
        ? contactList
        : listVisibleType === "vendorName"
        ? vendorList
        : products;
    if (listVisibleType !== "") {
      return (
        <ul
          className="data-list list-group  lead_ownerList_container position-absolute bg-white"
          style={{
            top: topGap,
            width:
              topGap === "2rem" ? "112%" : topGap === "1.8rem" ? "100%" : "",
          }}
        >
          {list.map((item, index) => (
            <li
              key={index}
              className="cursorPointer w-100 px-2 border-bottom my-1"
              onClick={() => {
                handleUserItemClick(listVisibleType, item, tableIndex);
              }}
            >
              <div className="d-flex flex-column">
                <span style={{ fontWeight: "500" }}>
                  {" "}
                  {listVisibleType === "purchaseOwner"
                    ? item?.name
                    : listVisibleType === "contactName"
                    ? item?.contactName
                    : listVisibleType === "vendorName"
                    ? item?.vendorName
                    : item?.productName}
                </span>
                <span className="small" style={{ color: "#999999" }}>
                  {" "}
                  {item.emailId}{" "}
                </span>
              </div>
            </li>
          ))}
        </ul>
      );
    }
  };

  const discountPopup = (index) => {
    return (
      <div
        className="position-absolute w-100 popout_box rounded-1 p-3 bg-white"
        style={{ left: selectedProductIndex === "" && "-4rem" }}
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <h6 className="fw-bold">
          {popoutType === "tax" ? "Select Tax" : "Set Discount"}
        </h6>
        {popoutType === "discount" ? (
          <>
            <div className="d-flex align-items-center my-3">
              <input
                type="radio"
                id="percentage"
                name="discount"
                value="percentage"
                className="cursorPointer"
                onChange={(e) => {
                  setDiscountType(e.target.checked ? "percentage" : "");
                  setDiscountObj({
                    type: 1,
                    value: 0,
                  });
                }}
                checked={discountType === "percentage"}
              />
              <label className="ms-1">Percentage</label>
              {discountType === "percentage" ? (
                <div className="w-50 d-flex">
                  <input
                    type="text"
                    className="w-75 ms-2 me-1"
                    name="percentage"
                    maxLength={3}
                    onChange={(e) => {
                      setDiscountObj({
                        type: 1,
                        value: e.target.value,
                      });
                    }}
                    value={discountObj.value}
                    // value={
                    //   (discountObj.value / 100) *
                    //   parseFloat(allProducts[index].amount || 1)
                    // }
                  />
                  <span>%</span>
                </div>
              ) : null}
            </div>
            <div className="d-flex align-items-center my-2">
              <input
                type="radio"
                id="price"
                name="discount"
                value="price"
                className="cursorPointer"
                onChange={(e) => {
                  setDiscountType(e.target.checked ? "price" : "");
                  setDiscountObj({
                    type: 2,
                    value: 0,
                  });
                }}
                checked={discountType === "price"}
              />
              <label className="ms-1">Direct Price Reduction</label>
              {discountType === "price" ? (
                <input
                  type="text"
                  className="w-25 ms-2"
                  name="percentage"
                  value={discountObj.value}
                  onChange={(e) => {
                    setDiscountObj({
                      type: 2,
                      value: e.target.value,
                    });
                  }}
                />
              ) : null}
            </div>
          </>
        ) : (
          <div className="my-3">
            <Select
              value={selectedTax}
              menuPlacement="auto"
              onChange={(selectedOption) => {
                setSelectedTax(selectedOption);
              }}
              isSearchable={false}
              options={taxOptions}
              styles={createLeadStyles}
            />
          </div>
        )}

        <div className="pt-2 d-flex gap-3">
          <button
            className="btn btn-primary px-3 py-1 opacity-75"
            onClick={() => {
              const newRows = [...allProducts];
              let discountedAmount;
              let taxAmount;
              // let total = parseFloat(newRows[index].amount);

              if (selectedProductIndex === "") {
                if (popoutType === "tax") {
                  const total = purchaseInfo.subTotal - purchaseInfo.discount;
                  const taxValue = (selectedTax.value / 100) * total;
                  setPurchaseInfo((prevPurchaseInfo) => ({
                    ...prevPurchaseInfo,
                    tax: selectedTax.value,
                    grandTotal: (
                      parseFloat(total) +
                      parseFloat(taxValue || 0) +
                      parseFloat(purchaseInfo.adjustment)
                    ).toFixed(2),
                  }));
                } else {
                  const discount =
                    discountObj.type === 1
                      ? (discountObj.value / 100) *
                        parseFloat(purchaseInfo.subTotal)
                      : discountObj.value;
                  const total = purchaseInfo.subTotal - discount;
                  const taxValue = (purchaseInfo.tax / 100) * total;

                  setPurchaseInfo((prevPurchaseInfo) => ({
                    ...prevPurchaseInfo,
                    discount: parseFloat(discount).toFixed(2),
                    discountType: discountObj.type,
                    discountValue: parseFloat(discountObj.value).toFixed(2),
                    grandTotal: (
                      parseFloat(total) +
                      parseFloat(taxValue || 0) +
                      parseFloat(purchaseInfo.adjustment)
                    ).toFixed(2),
                  }));
                }
              } else {
                const discount =
                  discountObj.type === 1
                    ? (discountObj.value / 100) *
                      parseFloat(newRows[index].amount)
                    : discountObj.value;
                discountedAmount =
                  parseFloat(newRows[index].amount || 0) - discount;
                taxAmount =
                  (parseFloat(newRows[index].tax || 0) / 100) *
                  discountedAmount;
                // newRows[index].amount = discountedAmount;
                newRows[index].total = (discountedAmount + taxAmount).toFixed(
                  2
                );
                newRows[index].discountType = discountObj.type;
                newRows[index].discountValue = discountObj.value;
                newRows[index].discount = discount;

                setAllProducts(newRows);
                const subTotalValue = newRows.reduce(
                  (sum, product) => parseFloat(sum) + parseFloat(product.total),
                  0
                );
                setPurchaseInfo((prevPurchaseInfo) => ({
                  ...prevPurchaseInfo,
                  subTotal: subTotalValue || 0,
                  grandTotal:
                    subTotalValue - parseFloat(purchaseInfo.discount || 0),
                }));
              }
              setSelectedProductIndex("");
              setPopoutType("");
            }}
          >
            Done
          </button>
          <button
            className="btn btn-light border"
            onClick={() => {
              setSelectedProductIndex("");
              setPopoutType("");
            }}
          >
            Cancel
          </button>
        </div>
      </div>
    );
  };

  const handlePurchaseOrdersView = () => {
    if (innerPage === "list") {
      return (
        <>
          {isLoading ? (
            <>
              <Loader />
            </>
          ) : (
            <>
              {filteredPurchaseOrders &&
              filteredPurchaseOrders.length === 0 &&
              selectedOption.value === "all_purchase_orders" ? (
                <>{nodataPage()}</>
              ) : (
                <>
                  <div className="creteContactBar">
                    {selectedCheckboxes.length > 0 ? (
                      <div className="d-flex w-50 align-items-center gap-2">
                        <Select
                          options={massOptions}
                          styles={customCommonStyles("100%")}
                          value={{
                            value: "Action",
                            label: "Action",
                          }}
                          onChange={(option) => {
                            if (option.value === "Mass Update") {
                              setShowSearchFields(fieldDropdowns);
                              setSelectedObj({
                                name: "Subject",
                                isShowed: false,
                                type: "input",
                              });
                            } else if (option.value === "Mass Transfer") {
                              setSelectedOwner(userList[0]);
                            }
                            setMassUpdateValue("");
                            setShowModalType(option.value);
                          }}
                          className="w-25"
                          isSearchable={false}
                        />
                      </div>
                    ) : (
                      <div className="d-flex w-50 align-items-center gap-2">
                        {/* <img
                        src={filterView ? filterIcon : filterIconCross}
                        height={25}
                        className="leadFilter"
                        alt="img"
                      /> */}
                        <Select
                          options={options}
                          styles={customCommonStyles("250px")}
                          value={selectedOption}
                          onChange={handleChange}
                          isSearchable={false}
                        />
                      </div>
                    )}
                    <div className="d-flex w-50 justify-content-end gap-2">
                      <div className="btn-group">
                        <button
                          className="createContactButtonSave py-2"
                          style={{ width: "195px" }}
                          onClick={handleCreatePurchase}
                        >
                          Create Purchase Order
                        </button>
                        {/* <button
                          type="button"
                          className="btn btn-sm  dropdown-toggle dropdown-toggle-split py-2"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                          style={{
                            background:
                              "linear-gradient(86.07deg, #407BFF 0%, #12A7FE 100%)",
                            color: "#fff",
                          }}
                        >
                          <span className="visually-hidden">
                            Toggle Dropdown
                          </span>
                        </button> */}
                        {/* <ul className="dropdown-menu">
                          <li>
                            <a className="dropdown-item">Import Leads</a>
                          </li>
                          <li>
                            <a className="dropdown-item">Import Notes</a>
                          </li>
                        </ul> */}
                      </div>
                    </div>
                  </div>
                  <div className="totalRecordBarContact">
                    <div className="d-flex w-50">
                      <h6>
                        {" "}
                        {selectedCheckboxes.length > 0
                          ? `${selectedCheckboxes.length} Records Selected`
                          : `Total Records : ${totalItems}`}{" "}
                      </h6>
                    </div>
                    <div className="d-flex w-50 align-items-center justify-content-end gap-2">
                      {selectedCheckboxes.length === 0 ? (
                        filteredPurchaseOrders.length !== 0 && (
                          <Select
                            options={pageOption}
                            styles={customCommonStyles("100%")}
                            isSearchable={false}
                            value={pageOption.find(
                              (option) => option.value === perPage.toString()
                            )}
                            onChange={handlePageDropDowmChange}
                          />
                        )
                      ) : (
                        <></>
                      )}

                      {filteredPurchaseOrders.length !== 0 && (
                        <Pagination
                          currentPage={currentPage}
                          totalPages={totalPages}
                          onPageChange={handlePageChange}
                        />
                      )}
                    </div>
                  </div>
                  <div className="contactMain">
                    {/* {filterView ? (
                      <div className="filterLead">
                        <h6 style={{ borderBottom: "2px solid #316AFF" }}>
                          Filter by Sales
                        </h6>
                        <input
                          type="text"
                          placeholder="Search"
                          className="filterSearch"
                        />
                        <div className="checkboxItem">
                          <h6 style={{ color: "#515151" }}>
                            ▾ System Defined Filters
                          </h6>
                          <input
                            type="checkbox"
                            id="vehicle1"
                            name="vehicle1"
                            value="Bike"
                          />
                          <label htmlFor="vehicle1"> Touched Record</label>
                          <br />
                          <input
                            type="checkbox"
                            id="vehicle1"
                            name="vehicle1"
                            value="Bike"
                          />
                          <label htmlFor="vehicle1"> Untouched Record</label>
                          <br />
                          <input
                            type="checkbox"
                            id="vehicle1"
                            name="vehicle1"
                            value="Bike"
                          />
                          <label htmlFor="vehicle1"> Record Action</label>
                          <br />
                          <h6 style={{ color: "#515151", marginTop: "10px" }}>
                            ▾ Filter By Fields
                          </h6>
                          <input
                            type="checkbox"
                            id="vehicle1"
                            name="vehicle1"
                            value="Bike"
                          />
                          <label htmlFor="vehicle1"> Annual Revenue</label>
                          <br />
                          <input
                            type="checkbox"
                            id="vehicle1"
                            name="vehicle1"
                            value="Bike"
                          />
                          <label htmlFor="vehicle1"> City</label>
                          <br />
                          <input
                            type="checkbox"
                            id="vehicle1"
                            name="vehicle1"
                            value="Bike"
                          />
                          <label htmlFor="vehicle1"> Company</label>
                          <br />
                        </div>
                      </div>
                    ) : (
                      <></>
                    )} */}
                    <div
                      className={
                        filterIcon ? "leadListTableFullWidth" : "leadListTable"
                      }
                    >
                      <table className="table leadTable">
                        <thead>
                          <tr>
                            <th scope="col">
                              <input
                                className="cursorPointer"
                                type="checkbox"
                                id="all"
                                name="all"
                                value="All"
                                checked={filteredPurchaseOrders.every(
                                  (selected) =>
                                    selectedCheckboxes.some(
                                      (list) =>
                                        list.moduleId === selected.purchaseOrdId
                                    )
                                )}
                                onChange={(e) => {
                                  const allList = filteredPurchaseOrders.map(
                                    (item) => ({
                                      moduleId: item.purchaseOrdId,
                                    })
                                  );
                                  if (e.target.checked) {
                                    const newSelections = allList.filter(
                                      (newItem) =>
                                        !selectedCheckboxes.some(
                                          (existingItem) =>
                                            existingItem.moduleId ===
                                            newItem.moduleId
                                        )
                                    );
                                    setSelectedCheckboxes((prev) => [
                                      ...prev,
                                      ...newSelections,
                                    ]);
                                  } else {
                                    setSelectedCheckboxes(
                                      selectedCheckboxes.filter(
                                        (aItem) =>
                                          !allList.some(
                                            (bItem) =>
                                              aItem.moduleId === bItem.moduleId
                                          )
                                      )
                                    );
                                  }
                                }}
                                // checked={
                                //   selectedCheckboxes.length ===
                                //   filteredPurchaseOrders.length
                                // }
                                // onChange={(e) => {
                                //   if (e.target.checked) {
                                //     const allList = filteredPurchaseOrders.map(
                                //       (item) => ({
                                //         moduleId: item.purchaseOrdId,
                                //       })
                                //     );
                                //     setSelectedCheckboxes(allList);
                                //   } else {
                                //     setSelectedCheckboxes([]);
                                //   }
                                // }}
                              />
                            </th>
                            {selectedHeadings.map(
                              (heading) =>
                                heading.isChecked && (
                                  <th scope="col" key={heading.name}>
                                    <span className="pe-3">{heading.name}</span>
                                    <span className="position-relative">
                                      <img
                                        src={tableFilterIcon}
                                        alt=""
                                        className="cursorPointer"
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          setShowHeadings(false);
                                          setShowSelectedFilter(heading.name);
                                        }}
                                      />
                                      {heading.name === showSelectedFilter && (
                                        <span
                                          className="position-absolute rounded-2 border bg-light top-0 mt-4"
                                          style={{ right: "-10%" }}
                                        >
                                          <span className="d-flex flex-column px-1">
                                            <span
                                              className="my-1 px-1 cursorPointer fw-normal text-start filter_type"
                                              onClick={() => {
                                                if (filterType === "asc") {
                                                  setFilterType("");
                                                  setFilteredPurchaseOrders(
                                                    allPurchaseOrders
                                                  );
                                                } else {
                                                  setFilterType("asc");
                                                  sortedColumnsData(
                                                    "asc",
                                                    heading.value
                                                  );
                                                }
                                              }}
                                            >
                                              {filterType === "asc" ? (
                                                <img
                                                  src={cancel}
                                                  alt="asc"
                                                  className="opacity-75 pe-1"
                                                />
                                              ) : (
                                                <img
                                                  src={arrowUp}
                                                  alt="asc"
                                                  className="opacity-75 pe-1"
                                                />
                                              )}
                                              Asc
                                            </span>
                                            {/* Descending filter */}
                                            <span
                                              className="my-2 px-1 cursorPointer fw-normal text-start filter_type"
                                              onClick={() => {
                                                if (filterType === "desc") {
                                                  setFilterType("");
                                                  setFilteredPurchaseOrders(
                                                    allPurchaseOrders
                                                  );
                                                } else {
                                                  setFilterType("desc");
                                                  sortedColumnsData(
                                                    "desc",
                                                    heading.value
                                                  );
                                                }
                                              }}
                                            >
                                              {filterType === "desc" ? (
                                                <img
                                                  src={cancel}
                                                  alt="desc"
                                                  className="opacity-75 pe-1"
                                                />
                                              ) : (
                                                <img
                                                  src={arrowDown}
                                                  alt="desc"
                                                  className="opacity-75 pe-1"
                                                />
                                              )}
                                              Desc
                                            </span>
                                            <span
                                              className={`my-1 px-1 fw-normal text-start filter_type ${
                                                selectedHeadings[0].name ===
                                                  "Subject" &&
                                                heading.name === "Subject"
                                                  ? "opacity-50"
                                                  : "cursorPointer"
                                              }`}
                                              onClick={() => {
                                                if (
                                                  pinnedColumn !== "Subject"
                                                ) {
                                                  setPinnedColumn("Subject");
                                                  handlePinColumn("Subject");
                                                } else {
                                                  setPinnedColumn(heading.name);
                                                  handlePinColumn(heading.name);
                                                }
                                              }}
                                            >
                                              <img
                                                src={
                                                  pinnedColumn === heading.name
                                                    ? unPin
                                                    : pin
                                                }
                                                alt="pin"
                                                className="opacity-75 pe-1"
                                              />
                                              {pinnedColumn === heading.name
                                                ? "Unpin "
                                                : "Pin "}
                                              Column
                                            </span>
                                          </span>
                                        </span>
                                      )}
                                      <img
                                        src={filterLine}
                                        alt=""
                                        className="ps-2"
                                      />
                                    </span>
                                  </th>
                                )
                            )}

                            {/* <th scope="col">Company</th>
                <th scope="col">Email</th>
                <th scope="col">Phone</th>
                <th scope="col">Lead Source</th> */}
                            {/* <th scope="col">Lead Owner</th> */}
                            <th
                              scope="col"
                              className="text-end cursorPointer position-relative"
                            >
                              <img
                                src={filterMenu}
                                alt=""
                                className={`filter_menu ${
                                  showHeadings ? "bg-light" : ""
                                }`}
                                onClick={(e) => {
                                  e.stopPropagation();
                                  setShowSelectedFilter("");
                                  setShowHeadings(true);
                                }}
                              />
                              {showHeadings && (
                                <div
                                  className="position-absolute rounded-2  border border-primary bg-light"
                                  style={{
                                    right: "0.2rem",
                                  }}
                                >
                                  <div className="d-flex flex-column px-2">
                                    <span
                                      className="p-2 cursorPointer fw-normal text-start"
                                      onClick={() => {
                                        setShowModalType("Manage Columns");
                                        setSavingHeadings(selectedHeadings);
                                      }}
                                    >
                                      Manage Columns
                                    </span>
                                    <span
                                      className="p-2 fw-normal text-start opacity-50"
                                      onClick={() => {
                                        setShowModalType("Reset Column Size");
                                      }}
                                    >
                                      Reset Column Size
                                    </span>
                                  </div>
                                </div>
                              )}
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {filteredPurchaseOrders &&
                          filteredPurchaseOrders.length > 0 ? (
                            filteredPurchaseOrders.map((item, i) => {
                              return (
                                <tr key={i} className="">
                                  <td>
                                    <input
                                      className="cursorPointer"
                                      type="checkbox"
                                      id="vehicle1"
                                      name="vehicle1"
                                      value={item.purchaseOrdId}
                                      checked={selectedCheckboxes.some(
                                        (checkbox) =>
                                          checkbox.moduleId ===
                                          item.purchaseOrdId
                                      )}
                                      onChange={handleSelectCheckboxChange}
                                    />
                                  </td>
                                  <React.Fragment>
                                    {selectedHeadings.map((obj, i) => {
                                      const value = obj.value;
                                      const itemValue = item[value];
                                      return obj.isChecked ? (
                                        value === "transHash" ? (
                                          <td
                                            style={{ wordBreak: "break-all" }}
                                            className="overflow_text_hash"
                                            key={itemValue}
                                          >
                                            <a
                                              rel="noreferrer"
                                              href={
                                                EXPLORER_URL +
                                                "about-tx-hash/" +
                                                item.transHash
                                              }
                                              className="anchorLinkHash textColor"
                                              data-title={item.transHash}
                                              target="_blank"
                                            >
                                              {item.transHash
                                                ? item.transHash.slice(0, 10) +
                                                  "...."
                                                : "-"}
                                            </a>
                                          </td>
                                        ) : (
                                          <td
                                            key={i}
                                            onClick={() => {
                                              if (value === "subject") {
                                                // setLeadView("individualLeadDetailPage");
                                                // leadOverviewDetail(item.leadId);
                                                // setLeadId(item.leadId);

                                                purchaseOrderOverview(
                                                  item.purchaseOrdId
                                                );
                                                getNotesDetails(
                                                  item.purchaseOrdId,
                                                  selectRecent
                                                );
                                                setPurchaseOrdId(
                                                  item.purchaseOrdId
                                                );
                                                setSelectedCheckboxes([]);
                                                setSelectedPurchaseId(
                                                  allPurchaseOrders.findIndex(
                                                    (lead) =>
                                                      lead.purchaseOrdId ===
                                                      item.purchaseOrdId
                                                  )
                                                );
                                              }
                                            }}
                                            className={`overflow_text pe-1 ${
                                              value === "subject"
                                                ? "leadName cursorPointer"
                                                : ""
                                            }`}
                                            style={{ maxWidth: "3vw" }}
                                          >
                                            {itemValue !== null
                                              ? itemValue
                                              : "-"}
                                          </td>
                                        )
                                      ) : null;
                                    })}
                                  </React.Fragment>
                                  <td></td>
                                </tr>
                              );
                            })
                          ) : (
                            <tr>
                              <td
                                colSpan={selectedHeadings.length + 2}
                                style={{ textAlign: "center" }}
                              >
                                No Data
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </>
              )}
            </>
          )}
        </>
      );
    } else if (["create", "clone"].includes(innerPage)) {
      return (
        <>
          <div className="createContactButtonBar">
            <div className="d-flex align-items-center w-50 gap-2">
              <img src={groupIcon} height={25} alt="" />
              <div className="d-flex flex-column">
                <p
                  className="mb-0 first_letter_uppercase"
                  style={{ fontSize: "22px" }}
                >
                  {editPurchaseOverview ? "Edit" : innerPage} Purchase Order
                </p>
              </div>
            </div>
            <div className="d-flex w-50 gap-2 justify-content-end">
              <button
                className="createLeadButton"
                onClick={() => {
                  if (pastViewInfo) {
                    setCurrentView(pastViewInfo.view);
                    setInnerPage(pastViewInfo.innerPage);
                    setPastViewInfo((prev) => ({
                      ...prev,
                      view: "",
                      innerPage: "",
                      undo:
                        currentView === "purchaseOrders"
                          ? "purchaseOrder"
                          : currentView, // it will set the current view of the page so when we cancel or save it will go to same section in the page
                    }));
                    return;
                  }
                  if (editPurchaseOverview) {
                    setInnerPage("overview");
                  } else {
                    setInnerPage("list");
                  }
                  setFetchContactInfo(null);
                  setSelectedContact({
                    contactName: "",
                    contactId: "",
                  });

                  if (innerPage === "clone" && !editPurchaseOverview) {
                    setInnerPage("overview");
                  }
                }}
              >
                Cancel
              </button>
              {!editPurchaseOverview && (
                <button
                  className="createLeadButton"
                  style={{ width: "175px" }}
                  onClick={() => {
                    if (
                      new Date(
                        purchaseInfo.poDate?.split("-").reverse().join("-")
                      ) >
                      new Date(
                        purchaseInfo.dueDate?.split("-").reverse().join("-")
                      )
                    ) {
                      NotificationManager.error(
                        "",
                        "Due date can not be less than PO date",
                        3000
                      );
                    } else {
                      handleSaveButton("SAVE AND NEW");
                    }
                  }}
                  // disabled={
                  //   purchaseInfo.subject?.length < 2
                  //   // purchaseInfo.subTotal <= 0 ||
                  //   // allProducts[0].description?.length < 2
                  // }
                  disabled={saveAndNewSubmit}
                >
                  {saveAndNewSubmit ? (
                    <Lottie
                      options={defaultOptions("blue")}
                      height="24px"
                      width="36px"
                    />
                  ) : (
                    "Save and New"
                  )}
                </button>
              )}
              <button
                className="save-button"
                onClick={() => {
                  if (
                    new Date(
                      purchaseInfo.poDate?.split("-").reverse().join("-")
                    ) >
                    new Date(
                      purchaseInfo.dueDate?.split("-").reverse().join("-")
                    )
                  ) {
                    NotificationManager.error(
                      "",
                      "Due date can not be less than PO date",
                      3000
                    );
                  } else {
                    handleSaveButton("SAVE");
                  }
                }}
                // disabled={allProducts[0].description?.length < 2}
                disabled={saveSubmit}
              >
                {saveSubmit ? (
                  <Lottie
                    options={defaultOptions("white")}
                    height="30px"
                    width="36px"
                  />
                ) : !editPurchaseOverview ? (
                  "Save"
                ) : (
                  "Update"
                )}
              </button>
            </div>
          </div>

          <div className="contactFields">
            <h6
              className="py-2"
              style={{
                borderBottom: "2px solid #316AFF",
                fontSize: "18px",
                color: "#515151",
                marginBottom: "0px",
              }}
            >
              Purchase Order Information
            </h6>
            <div className="inputFieldDiv">
              <div className="inputFieldDivRight position-relative">
                <p className="mb-0">
                  Purchase Order Owner<span className="text-danger">*</span>
                </p>
                <div className="d-flex input-with-clear position-relative">
                  <span
                    className="leadInputField cursorPointer rounded-1 pt-2"
                    onClick={(e) => {
                      e.stopPropagation();
                      setListVisibleType(
                        listVisibleType === "purchaseOwner" ||
                          editPurchaseOverview
                          ? ""
                          : "purchaseOwner"
                      );
                    }}
                  >
                    {selectedOwner?.name}
                  </span>
                  {!editPurchaseOverview && 
                  <span
                    className="position-absolute pt-2 cursorPointer"
                    onClick={(e) => {
                      e.stopPropagation();
                      setSelectedOwner("");
                      setListVisibleType(
                        listVisibleType === "purchaseOwner"
                          ? ""
                          : "purchaseOwner"
                      );
                    }}
                    style={{ right: "42%" }}
                  >
                    <img
                      src={listVisibleType === "purchaseOwner" ? up : down}
                      alt="img"
                      height={20}
                      width={20}
                    />
                  </span>}

                  <span
                    className="cursorPointer pt-2 ps-1"
                    onClick={() => setIsLeadOwnerModal(!isLeadOwnerModal)}
                  >
                    <img src={userSearch} alt="img" width={20} height={20} />
                  </span>
                </div>
                {listVisibleType === "purchaseOwner" &&
                  accountsDropdown("6rem")}
              </div>
              <div className="inputFieldDivLeft">
                <p className="mb-0">PO Number</p>
                <input
                  type="number"
                  className="insertCommonInputField"
                  name="poNumber"
                  value={purchaseInfo.poNumber}
                  onChange={(e) => {
                    if (e.target.value.length > 10) {
                      return;
                    }
                    handleChangePurchase(e);
                  }}
                  onKeyDown={(e) => {
                    if (e.key === "e" || e.key === "E") {
                      e.preventDefault();
                    }
                  }}
                />
              </div>
            </div>
            <div className="inputFieldDiv">
              <div className="inputFieldDivRight">
                <p className="mb-0">
                  Subject <span className="text-danger">*</span>
                </p>
                <input
                  type="text"
                  maxLength={100}
                  // className="insertCommonInputField overflow_text pe-4"
                  className={
                    subjectError
                      ? "mandatoryField overflow_text pe-4"
                      : "insertCommonInputField overflow_text pe-4"
                  }
                  name="subject"
                  value={purchaseInfo.subject}
                  onChange={handleChangePurchase}
                />
                <span className="small erro_msg">{subjectError}</span>
              </div>
              <div className="inputFieldDivRight position-relative">
                <p className="mb-0">
                  Vendor Name <span className="text-danger">*</span>
                </p>
                <div className="d-flex input-with-clear position-relative">
                  <span
                    className="leadInputField cursorPointer rounded-1 pt-2 overflow_text pe-4"
                    onClick={(e) => {
                      e.stopPropagation();
                      setListVisibleType(
                        listVisibleType === "vendorName" ? "" : "vendorName"
                      );
                    }}
                  >
                    {selectedVendor?.vendorName}
                  </span>
                  <span
                    className="position-absolute pt-2 cursorPointer"
                    style={{ right: "42%" }}
                    onClick={(e) => {
                      e.stopPropagation();
                      setListVisibleType(
                        listVisibleType === "vendorName" ? "" : "vendorName"
                      );
                    }}
                  >
                    <img
                      src={listVisibleType === "vendorName" ? up : down}
                      alt="img"
                      height={20}
                      width={20}
                    />
                  </span>
                  <span
                    className="cursorPointer pt-2 ps-1"
                    onClick={() => setIsVendorListModal(true)}
                  >
                    <img src={vendorsIcon} alt="img" width={20} height={20} />
                  </span>
                </div>
                {listVisibleType === "vendorName" && accountsDropdown("6rem")}
              </div>
            </div>
            <div className="inputFieldDiv">
              <div className="inputFieldDivLeft">
                <p className="mb-0">Requisition Number</p>
                <input
                  type="number"
                  className="insertCommonInputField"
                  name="requisitionNumber"
                  value={purchaseInfo.requisitionNumber}
                  onChange={handleChangePurchase}
                  onKeyDown={(e) => {
                    if (e.key === "e" || e.key === "E") {
                      e.preventDefault();
                    }
                  }}
                />
              </div>
              <div className="inputFieldDivRight">
                <p className="mb-0">Tracking Number</p>
                <input
                  type="number"
                  className="insertCommonInputField"
                  name="trackingNumber"
                  value={purchaseInfo.trackingNumber}
                  onChange={handleChangePurchase}
                  onKeyDown={(e) => {
                    if (e.key === "e" || e.key === "E") {
                      e.preventDefault();
                    }
                  }}
                />
              </div>
            </div>
            <div className="inputFieldDiv">
              <div className="inputFieldDivLeft position-relative">
                <p className="mb-0">Contact Name</p>
                <div className="d-flex input-with-clear position-relative">
                  <span
                    className="leadInputField cursorPointer rounded-1 pt-2 overflow_text pe-4"
                    onClick={(e) => {
                      e.stopPropagation();
                      setListVisibleType(
                        listVisibleType === "contactName" ? "" : "contactName"
                      );
                      setFetchContactInfo(null);
                    }}
                  >
                    {fetchContactInfo
                      ? fetchContactInfo.contactName
                      : selectedContact.contactName}
                  </span>

                  <span
                    className="position-absolute pt-2 cursorPointer"
                    style={{ right: "42%" }}
                    onClick={(e) => {
                      e.stopPropagation();
                      setListVisibleType(
                        listVisibleType === "contactName" ? "" : "contactName"
                      );
                      setFetchContactInfo(null);
                    }}
                  >
                    <img
                      src={listVisibleType === "contactName" ? up : down}
                      alt="img"
                      height={20}
                      width={20}
                    />
                  </span>

                  <span
                    className="cursorPointer pt-2 ps-1"
                    onClick={() => setIsContactListModal(true)}
                  >
                    <img src={ContactIcon} alt="img" width={20} height={20} />
                  </span>
                </div>
                {listVisibleType === "contactName" && accountsDropdown("6rem")}
              </div>
              <div className="inputFieldDivRight">
                <p className="mb-0">PO Date</p>
                <input
                  type="date"
                  className="insertCommonInputField"
                  name="poDate"
                  value={purchaseInfo.poDate?.split("-").reverse().join("-")}
                  onChange={handleChangePurchase}
                  min={minDate}
                  max={maxDate}
                  onKeyDown={(e) => e.preventDefault()}
                />
                {/* {!validEmail && mail != "" && <p style={{ color: 'red', margin: '5px 0' }}>Please enter a valid email address.</p>} */}
              </div>
            </div>
            <div className="inputFieldDiv">
              <div className="inputFieldDivLeft">
                <p className="mb-0">Due Date</p>
                <input
                  type="date"
                  className="insertCommonInputField"
                  name="dueDate"
                  value={purchaseInfo.dueDate?.split("-").reverse().join("-")}
                  onChange={handleChangePurchase}
                  min={purchaseInfo.poDate?.split("-").reverse().join("-")}
                  max={maxDate}
                  onKeyDown={(e) => e.preventDefault()}
                />
              </div>
              <div className="inputFieldDivRight">
                <p className="mb-0">
                  Carrier <span className="text-danger">*</span>
                </p>
                <Select
                  value={purchaseInfo.carrier}
                  menuPlacement="auto"
                  onChange={(selectedOption) =>
                    handleChangeSelectDropDown(selectedOption, "carrier")
                  }
                  options={carrierOptions}
                  styles={createLeadStyles}
                  isSearchable={false}
                />
              </div>
            </div>
            <div className="inputFieldDiv">
              <div className="inputFieldDivLeft position-relative">
                <p className="mb-0">Excise Duty</p>
                <div className="position-relative">
                  <input
                    type="text"
                    maxLength={16}
                    className="insertCommonInputField ps-3"
                    name="exciseDuty"
                    value={purchaseInfo.exciseDuty}
                    onChange={handleChangePurchase}
                    onBlur={() => handleBlurForSales("exciseDuty")}
                    onKeyPress={handleKeyPress}
                  />
                  <span
                    className="position-absolute"
                    style={{ top: "0.4rem", right: "35%" }}
                  >
                    <CalculatorTooltip />
                  </span>
                </div>
                {showTooltipExciseDuty && (
                  <div style={{ ...tooltipStyles, bottom: "30px" }}>
                    {"= " + tooltipContentExciseDuty}
                  </div>
                )}
                <span className="position-absolute start-0 pt-2 ms-1 dollar_icon">
                  ₹
                </span>
                <p
                  className="small erro_msg position-absolute"
                  style={{ left: "0px", bottom: "-35px" }}
                >
                  {exciseDutyMsg && exciseDutyMsg}
                </p>
              </div>
              <div className="inputFieldDivRight position-relative">
                <p className="mb-0">Sales Commission</p>
                <div className="position-relative">
                  <input
                    type="text"
                    className="insertCommonInputField ps-3"
                    name="salesCommission"
                    value={purchaseInfo.salesCommission}
                    onChange={handleChangePurchase}
                    onBlur={() => handleBlurForSales("salesCommission")}
                    onKeyPress={handleKeyPress}
                  />
                  <span
                    className="position-absolute"
                    style={{ top: "0.4rem", right: "35%" }}
                  >
                    <CalculatorTooltip />
                  </span>
                </div>
                {showTooltipSalesCommission && (
                  <div style={{ ...tooltipStyles, bottom: "30px" }}>
                    {"= " + tooltipContentSalesCommission}
                  </div>
                )}
                <span className="position-absolute start-0 pt-2 ms-1 dollar_icon">
                  ₹
                </span>
                <span className="small erro_msg">
                  {salesCommissionMsg && salesCommissionMsg}
                </span>
              </div>
            </div>
            <div className="inputFieldDiv">
              <div className="inputFieldDivLeft">
                <p className="mb-0">
                  Status<span className="text-danger">*</span>
                </p>
                <Select
                  value={purchaseInfo.status}
                  menuPlacement="auto"
                  onChange={(selectedOption) =>
                    handleChangeSelectDropDown(selectedOption, "status")
                  }
                  options={stausOptions}
                  styles={createLeadStyles}
                  isSearchable={false}
                />
                {/* {!isValidWebsite && <p style={{ color: 'red' }}>Please enter a valid website URL</p>} */}
              </div>
            </div>

            <h6
              className="py-2 mt-4"
              style={{
                borderBottom: "2px solid #316AFF",
                fontSize: "18px",
                color: "#515151",
              }}
            >
              Address Information
            </h6>
            <div
              className="inputFieldDiv"
              style={{ width: "min-content", alignSelf: "end" }}
            >
              <Dropdown>
                <Dropdown.Toggle id="dropdown-basic">
                  Copy Address
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  {/* <Dropdown.Item
                    onClick={() => {
                      setPurchaseInfo((prevData) => ({
                        ...prevData,
                        billingStreet:
                          purchaseInfo.shippingStreet.length > 0
                            ? purchaseInfo.shippingStreet
                            : purchaseInfo.billingStreet,
                        billingCity:
                          purchaseInfo.shippingCity.length > 0
                            ? purchaseInfo.shippingCity
                            : purchaseInfo.billingCity,
                        billingState:
                          purchaseInfo.shippingState.length > 0
                            ? purchaseInfo.shippingState
                            : purchaseInfo.billingState,
                        billingCode:
                          purchaseInfo.shippingCode.length > 0
                            ? purchaseInfo.shippingCode
                            : purchaseInfo.billingCode,
                        billingCountry:
                          purchaseInfo.shippingCountry.length > 0
                            ? purchaseInfo.shippingCountry
                            : purchaseInfo.billingCountry,
                      }));
                    }}
                  >
                    Billing to Shipping
                  </Dropdown.Item> */}
                  <Dropdown.Item
                    onClick={() => {
                      const {
                        billingStreet,
                        billingCity,
                        billingState,
                        billingCode,
                        billingCountry,
                      } = billingAdd;
                      const {
                        shippingStreet,
                        shippingCity,
                        shippingState,
                        shippingCode,
                        shippingCountry,
                      } = shippingAdd;
                      setShippingAdd((prevData) => ({
                        ...prevData,
                        shippingStreet:
                          billingStreet.length > 0
                            ? billingStreet
                            : shippingStreet,
                        shippingCity:
                          billingCity.length > 0 ? billingCity : shippingCity,
                        shippingState:
                          billingState.length > 0
                            ? billingState
                            : shippingState,
                        shippingCode:
                          billingCode.length > 0 ? billingCode : shippingCode,
                        shippingCountry:
                          billingCountry.length > 0
                            ? billingCountry
                            : shippingCountry,
                      }));
                    }}
                  >
                    Billing to Shipping
                  </Dropdown.Item>

                  <Dropdown.Item
                    onClick={() => {
                      const {
                        shippingStreet,
                        shippingCity,
                        shippingState,
                        shippingCode,
                        shippingCountry,
                      } = shippingAdd;
                      const {
                        billingStreet,
                        billingCity,
                        billingState,
                        billingCode,
                        billingCountry,
                      } = billingAdd;
                      setBillingAdd((prevData) => ({
                        ...prevData,
                        billingStreet:
                          shippingStreet.length > 0
                            ? shippingStreet
                            : billingStreet,
                        billingCity:
                          shippingCity.length > 0 ? shippingCity : billingCity,
                        billingState:
                          shippingState.length > 0
                            ? shippingState
                            : billingState,
                        billingCode:
                          shippingCode.length > 0 ? shippingCode : billingCode,
                        billingCountry:
                          shippingCountry.length > 0
                            ? shippingCountry
                            : billingCountry,
                      }));
                    }}
                  >
                    Shipping to Billing
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
            <div className="inputFieldDiv">
              <div className="inputFieldDivLeft">
                <p className="mb-0">Billing Street</p>
                <input
                  type="text"
                  className="insertCommonInputField"
                  name="billingStreet"
                  value={billingAdd.billingStreet}
                  onChange={handleChangeBillingAddress}
                />
              </div>
              <div className="inputFieldDivRight">
                <p className="mb-0">Shipping Street</p>
                <input
                  type="text"
                  className="insertCommonInputField"
                  name="shippingStreet"
                  value={shippingAdd.shippingStreet}
                  onChange={handleChangeShippingAddress}
                />
              </div>
            </div>
            <div className="inputFieldDiv">
              <div className="inputFieldDivLeft">
                <p className="mb-0">Billing City</p>
                <input
                  type="text"
                  className="insertCommonInputField"
                  name="billingCity"
                  value={billingAdd.billingCity}
                  onChange={handleChangeBillingAddress}
                />
                <span className="small erro_msg">{billingCityError}</span>
              </div>
              <div className="inputFieldDivRight">
                <p className="mb-0">Shipping City</p>
                <input
                  type="text"
                  className="insertCommonInputField"
                  name="shippingCity"
                  value={shippingAdd.shippingCity}
                  onChange={handleChangeShippingAddress}
                />
                <span className="small erro_msg">{shippingCityError}</span>
              </div>
            </div>
            <div className="inputFieldDiv">
              <div className="inputFieldDivLeft">
                <p className="mb-0">Billing State</p>
                <input
                  type="text"
                  className="insertCommonInputField"
                  name="billingState"
                  value={billingAdd.billingState}
                  onChange={handleChangeBillingAddress}
                />
                <span className="small erro_msg">{billingStateError}</span>
              </div>
              <div className="inputFieldDivRight">
                <p className="mb-0">Shipping State</p>
                <input
                  type="text"
                  className="insertCommonInputField"
                  name="shippingState"
                  value={shippingAdd.shippingState}
                  onChange={handleChangeShippingAddress}
                />
                <span className="small erro_msg">{shippingStateError}</span>
              </div>
            </div>
            <div className="inputFieldDiv">
              <div className="inputFieldDivLeft">
                <p className="mb-0">Billing Code</p>
                <input
                  type="text"
                  className="insertCommonInputField"
                  name="billingCode"
                  value={billingAdd.billingCode.replace(/\D/g, "").slice(0, 6)}
                  onChange={handleChangeBillingAddress}
                />
                <span className="small erro_msg">{billingCodeError}</span>
              </div>
              <div className="inputFieldDivRight">
                <p className="mb-0">Shipping Code</p>
                <input
                  type="text"
                  className="insertCommonInputField"
                  name="shippingCode"
                  value={shippingAdd.shippingCode
                    .replace(/\D/g, "")
                    .slice(0, 6)}
                  onChange={handleChangeShippingAddress}
                />
                <span className="small erro_msg">{shippingCodeError}</span>
              </div>
            </div>
            <div className="inputFieldDiv">
              <div className="inputFieldDivLeft">
                <p className="mb-0">Billing Country</p>
                <input
                  type="text"
                  className="insertCommonInputField"
                  name="billingCountry"
                  value={billingAdd.billingCountry}
                  onChange={handleChangeBillingAddress}
                />
                <span className="small erro_msg">{billingCountryError}</span>
              </div>
              <div className="inputFieldDivRight">
                <p className="mb-0">Shipping Country</p>
                <input
                  type="text"
                  className="insertCommonInputField"
                  name="shippingCountry"
                  value={shippingAdd.shippingCountry}
                  onChange={handleChangeShippingAddress}
                />
                <span className="small erro_msg">{shippingCountryError}</span>
              </div>
            </div>

            <h6
              className="py-2 mt-4"
              style={{
                borderBottom: "2px solid #316AFF",
                fontSize: "18px",
                color: "#515151",
              }}
            >
              Purchase Items<span className="text-danger">*</span>
            </h6>
            <div
              className="horizontal-scroll-container mb-2"
              style={{
                // overflowX: "auto",
                width: "100%",
                maxWidth: "100%",
                // overflow: "hidden",
                border: "none",
              }}
            >
              <table
                className="table"
                style={{
                  tableLayout: "fixed",
                  minWidth: "1000px",
                  margin: "0px",
                }}
              >
                <colgroup>
                  <col style={{ width: "1%" }} />
                  <col style={{ width: "4%" }} />
                  <col style={{ width: "12%" }} />
                  <col style={{ width: "6%" }} />
                  <col style={{ width: "6%" }} />
                  <col style={{ width: "6%" }} />
                  <col style={{ width: "6%" }} />
                  <col style={{ width: "6%" }} />
                  <col style={{ width: "6%" }} />
                </colgroup>
                <thead>
                  <tr className="mb-2">
                    <th
                      style={{
                        backgroundColor: "rgba(243, 246, 251, 1)",
                        border: "none",
                      }}
                    ></th>
                    <th
                      style={{
                        backgroundColor: "#EFEFEF",
                        border: "1px solid rgba(185, 185, 185, 0.5)",
                      }}
                    >
                      SL.NO
                    </th>
                    <th
                      style={{
                        backgroundColor: "#EFEFEF",
                        border: "1px solid rgba(185, 185, 185, 0.5)",
                      }}
                    >
                      Product Name
                    </th>
                    <th
                      style={{
                        backgroundColor: "#EFEFEF",
                        border: "1px solid rgba(185, 185, 185, 0.5)",
                      }}
                    >
                      Quantity
                    </th>
                    <th
                      style={{
                        backgroundColor: "#EFEFEF",
                        border: "1px solid rgba(185, 185, 185, 0.5)",
                      }}
                    >
                      List Price (₹)
                    </th>
                    <th
                      style={{
                        backgroundColor: "#EFEFEF",
                        border: "1px solid rgba(185, 185, 185, 0.5)",
                      }}
                    >
                      Amount (₹)
                    </th>
                    <th
                      style={{
                        backgroundColor: "#EFEFEF",
                        border: "1px solid rgba(185, 185, 185, 0.5)",
                      }}
                    >
                      Discount (₹)
                    </th>
                    <th
                      style={{
                        backgroundColor: "#EFEFEF",
                        border: "1px solid rgba(185, 185, 185, 0.5)",
                      }}
                    >
                      Tax (%)
                    </th>
                    <th
                      style={{
                        backgroundColor: "#EFEFEF",
                        border: "1px solid rgba(185, 185, 185, 0.5)",
                      }}
                    >
                      Total (₹)
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {allProducts.map((row, index) => (
                    <tr key={index}>
                      <td
                        style={{
                          backgroundColor: "rgba(243, 246, 251, 1)",
                          border: "none",
                          alignContent: "center",
                          padding: "0px",
                        }}
                      >
                        <button
                          className={` ${
                            index === 0 ? "opacity-0" : "last-button"
                          }`}
                          onClick={() => handleDeleteRow(index)}
                          style={{
                            backgroundColor: "transparent",
                            padding: "0px",
                          }}
                        >
                          X
                        </button>
                      </td>
                      <td
                        style={{
                          backgroundColor: "white",
                          border: "1px solid rgba(185, 185, 185, 0.5)",
                        }}
                      >
                        <input
                          type="number"
                          name="slNo"
                          defaultValue={index + 1}
                          style={{
                            border: "none",
                            borderRadius: "5px",
                            backgroundColor: "white",
                            color: "#2177FE",
                            width: "-webkit-fill-available",
                          }}
                          readOnly
                          disabled
                        />
                      </td>
                      <td
                        style={{
                          backgroundColor: "white",
                          border: "1px solid rgba(185, 185, 185, 0.5)",
                        }}
                      >
                        <div
                          className="d-flex input-with-clear position-relative w-100"
                          style={{
                            marginBottom: "10px",
                            borderRadius: "2.5px",
                            border: "1px solid rgba(185, 185, 185, 0.5)",
                          }}
                        >
                          <span
                            onClick={(e) => {
                              e.stopPropagation();
                              setSelectedProductIndex(index);
                              setListVisibleType(
                                listVisibleType === "productId"
                                  ? ""
                                  : "productId"
                              );
                            }}
                            style={{
                              width: "92%",
                            }}
                            className="cursorPointer px-1 overflow_text"
                          >
                            {row.productName || selectedProduct.productName}
                          </span>
                          <span
                            className="position-absolute cursorPointer end-0"
                            onClick={(e) => {
                              e.stopPropagation();
                              setSelectedProductIndex(index);
                              setListVisibleType(
                                listVisibleType === "productId"
                                  ? ""
                                  : "productId"
                              );
                            }}
                          >
                            <img
                              src={
                                listVisibleType === "productId" &&
                                selectedProductIndex === index
                                  ? up
                                  : down
                              }
                              alt="img"
                              height={20}
                              width={20}
                            />
                          </span>
                          {listVisibleType === "productId" &&
                            selectedProductIndex === index &&
                            accountsDropdown("1.8rem", index)}
                        </div>
                        <textarea
                          type="text"
                          name="description"
                          value={row.description}
                          onChange={(e) => handleRowChange(index, e)}
                          placeholder="Add Description"
                          style={{
                            marginBottom: "10px",
                            borderRadius: "2.5px",
                            border: "1px solid rgba(185, 185, 185, 0.5)",
                            width: "-webkit-fill-available",
                          }}
                          className="pt-2 pb-3 ps-1"
                        />
                      </td>
                      <td
                        style={{
                          backgroundColor: "white",
                          border: "1px solid rgba(185, 185, 185, 0.5)",
                        }}
                      >
                        <input
                          type="number"
                          name="quantity"
                          value={row.quantity}
                          onChange={(e) => handleRowChange(index, e)}
                          style={{
                            marginBottom: "10px",
                            borderRadius: "2.5px",
                            border: "1px solid rgba(185, 185, 185, 0.5)",
                            width: "-webkit-fill-available",
                          }}
                        />
                      </td>
                      <td
                        style={{
                          backgroundColor: "white",
                          border: "1px solid rgba(185, 185, 185, 0.5)",
                        }}
                      >
                        <input
                          type="text"
                          name="listPrice"
                          value={row.listPrice}
                          onChange={(e) => handleRowChange(index, e)}
                          style={{
                            marginBottom: "10px",
                            borderRadius: "2.5px",
                            border: "1px solid rgba(185, 185, 185, 0.5)",
                            width: "-webkit-fill-available",
                          }}
                        />
                      </td>
                      <td
                        style={{
                          backgroundColor: "white",
                          border: "1px solid rgba(185, 185, 185, 0.5)",
                        }}
                      >
                        <input
                          type="number"
                          name="amount"
                          value={row.amount}
                          onChange={(e) => handleRowChange(index, e)}
                          style={{
                            marginBottom: "10px",
                            borderRadius: "2.5px",
                            border: "1px solid rgba(185, 185, 185, 0.5)",
                            width: "-webkit-fill-available",
                          }}
                          readOnly
                          disabled
                        />
                      </td>
                      <td
                        style={{
                          backgroundColor: "white",
                          border: "1px solid rgba(185, 185, 185, 0.5)",
                        }}
                        className="position-relative"
                      >
                        <div className="d-flex">
                          <input
                            type="number"
                            name="discount"
                            value={row.discount}
                            onChange={(e) => handleRowChange(index, e)}
                            style={{
                              marginBottom: "10px",
                              borderRadius: "2.5px",
                              border: "1px solid rgba(185, 185, 185, 0.5)",
                              width: "-webkit-fill-available",
                            }}
                            readOnly
                            disabled
                            className="me-3"
                          />
                          <span
                            onClick={(e) => {
                              e.stopPropagation();
                              setSelectedProductIndex(index);
                              setPopoutType(
                                popoutType === "discount" ? "" : "discount"
                              );
                            }}
                            style={{ marginLeft: "-0.7rem" }}
                            className="cursorPointer"
                          >
                            {editIcon}
                          </span>
                        </div>
                        {popoutType === "discount" &&
                          selectedProductIndex === index && (
                            <>{discountPopup(index)}</>
                          )}
                      </td>
                      <td
                        style={{
                          backgroundColor: "white",
                          border: "1px solid rgba(185, 185, 185, 0.5)",
                        }}
                      >
                        <input
                          type="number"
                          name="tax"
                          value={row.tax || 0}
                          onChange={(e) => handleRowChange(index, e)}
                          style={{
                            marginBottom: "10px",
                            borderRadius: "2.5px",
                            border: "1px solid rgba(185, 185, 185, 0.5)",
                            width: "-webkit-fill-available",
                          }}
                          readOnly
                          disabled
                        />
                      </td>
                      <td
                        style={{
                          backgroundColor: "white",
                          border: "1px solid rgba(185, 185, 185, 0.5)",
                        }}
                      >
                        <input
                          type="number"
                          name="total"
                          value={row.total}
                          onChange={(e) => handleRowChange(index, e)}
                          style={{
                            marginBottom: "10px",
                            borderRadius: "2.5px",
                            border: "1px solid rgba(185, 185, 185, 0.5)",
                            width: "-webkit-fill-available",
                          }}
                          disabled
                          readOnly
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>

            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
              className="mt-3"
            >
              <button
                onClick={handleAddRow}
                style={{
                  height: "max-content",
                  borderRadius: "10px",
                  padding: "5px 25px 5px 25px",
                  borderColor: "#529CF3",
                  backgroundColor: "#E4F5FF",
                  color: "#515151",
                  marginLeft: "1.5%",
                }}
              >
                Add Row
              </button>
              <div
                style={{
                  display: "flex",
                  width: "max-content",
                  border: "1.86px solid rgba(226, 229, 235, 1)",
                  boxShadow: "0px 0px 18.12px 0px rgba(0, 0, 0, 0.03)",
                  background: "rgba(255, 255, 255, 1)",
                  borderRadius: "10px",
                }}
                className="table-responsive"
              >
                <table
                  className="table"
                  style={{
                    borderCollapse: "collapse",
                    // width: "max-content",
                    margin: "0",
                    boxShadow: "0px 0px 18.12px 0px rgba(0, 0, 0, 0.03)",
                    // overflow: "hidden",
                  }}
                >
                  {" "}
                  <thead style={{ width: "max-content" }}>
                    <tr className="mb-2">
                      <th
                        scope="col"
                        style={{
                          backgroundColor: "#F8F8F8",
                          width: "max-content",
                        }}
                      >
                        Sub Total(₹)<span className="text-danger">*</span>
                      </th>
                      <td style={{ backgroundColor: "#F8F8F8" }}>
                        <input
                          type="number"
                          name="subTotal"
                          value={allProducts
                            .reduce(
                              (sum, product) =>
                                parseFloat(sum) + parseFloat(product.total),
                              0
                            )
                            ?.toFixed(2)}
                          // value={purchaseInfo.subTotal}
                          onChange={handleChangePurchase}
                          style={{
                            borderRadius: "6px",
                            border: "1px solid rgba(185, 185, 185, 0.5)",
                            backgroundColor: "#F3F6FB",
                          }}
                          readOnly
                          disabled
                        />
                      </td>
                    </tr>
                    <tr>
                      {" "}
                      <th scope="col">Discount(₹)</th>
                      <td className="dicount_column position-relative">
                        <div className="d-flex">
                          <input
                            type="number"
                            name="discount"
                            value={purchaseInfo.discount || 0}
                            onChange={handleChangePurchase}
                            style={{
                              borderRadius: "6px",
                              border: "1px solid rgba(185, 185, 185, 0.5)",
                              backgroundColor: "#F3F6FB",
                            }}
                            readOnly
                            disabled
                            className="me-3"
                          />
                          <span
                            onClick={(e) => {
                              e.stopPropagation();
                              setSelectedProductIndex("");
                              setPopoutType(
                                popoutType === "discount" ? "" : "discount"
                              );
                            }}
                            style={{ marginLeft: "-0.7rem" }}
                            className="cursorPointer discount_icon"
                          >
                            {editIcon}
                          </span>
                        </div>
                        {popoutType === "discount" &&
                          selectedProductIndex === "" && (
                            <>{discountPopup("")}</>
                          )}
                      </td>
                    </tr>
                    <tr>
                      <th
                        scope="col"
                        style={{
                          backgroundColor: "#F8F8F8",
                        }}
                      >
                        Tax(%)
                      </th>
                      <td
                        style={{ backgroundColor: "#F8F8F8" }}
                        className="dicount_column position-relative"
                      >
                        <div className="d-flex">
                          <input
                            type="number"
                            value={purchaseInfo.tax || 0}
                            onChange={handleChangePurchase}
                            style={{
                              borderRadius: "6px",
                              border: "1px solid rgba(185, 185, 185, 0.5)",
                              backgroundColor: "#F3F6FB",
                            }}
                            readOnly
                            disabled
                          />
                          <span
                            onClick={(e) => {
                              e.stopPropagation();
                              setSelectedProductIndex("");
                              setPopoutType(popoutType === "tax" ? "" : "tax");
                            }}
                            className="cursorPointer discount_icon ps-1"
                          >
                            {editIcon}
                          </span>
                        </div>
                        {popoutType === "tax" &&
                          selectedProductIndex === "" && (
                            <>{discountPopup("")}</>
                          )}
                      </td>
                    </tr>
                    <tr>
                      <th scope="col">Adjustment(₹)</th>
                      <td>
                        <input
                          type="number"
                          name="adjustment"
                          value={purchaseInfo.adjustment}
                          onChange={(e) => {
                            const total =
                              purchaseInfo.subTotal - purchaseInfo.discount;
                            const taxValue = (purchaseInfo.tax / 100) * total;
                            setPurchaseInfo((prevPurchaseInfo) => ({
                              ...prevPurchaseInfo,
                              adjustment: e.target.value,
                              grandTotal: (
                                parseFloat(total) +
                                parseFloat(taxValue || 0) +
                                parseFloat(e.target.value || 0)
                              ).toFixed(2),
                            }));
                          }}
                          style={{
                            borderRadius: "6px",
                            border: "1px solid rgba(185, 185, 185, 0.5)",
                            backgroundColor: "transparent",
                          }}
                        />
                      </td>
                    </tr>
                    <tr>
                      <th
                        scope="col"
                        style={{
                          backgroundColor: "#F8F8F8",
                        }}
                      >
                        Grand Total(₹)<span className="text-danger">*</span>
                      </th>
                      <td style={{ backgroundColor: "#F8F8F8" }}>
                        <input
                          type="number"
                          name="grandTotal"
                          value={purchaseInfo.grandTotal}
                          // value={
                          //   parseFloat(purchaseInfo.subTotal) +
                          //   parseFloat(purchaseInfo.adjustment || 0)
                          // }
                          // value={
                          //   allProducts.reduce(
                          //     (sum, product) =>
                          //       parseFloat(sum) + parseFloat(product.total),
                          //     0
                          //   ) + parseFloat(purchaseInfo.adjustment) || 0
                          // }
                          onChange={handleChangePurchase}
                          style={{
                            borderRadius: "6px",
                            border: "1px solid rgba(185, 185, 185, 0.5)",
                            backgroundColor: "#F3F6FB",
                          }}
                          readOnly
                          disabled
                        />
                      </td>
                    </tr>
                  </thead>
                </table>
              </div>
            </div>
            <h6
              className="py-2 mt-4"
              style={{
                borderBottom: "2px solid #316AFF",
                fontSize: "18px",
                color: "#515151",
              }}
            >
              Terms and Conditions
            </h6>
            <div className="inputFieldDiv" style={{ display: "block" }}>
              <div
                className="inputFieldDivLeft"
                style={{ display: "block", width: "100%" }}
              >
                <p>Terms and Conditions</p>
                <input
                  type="text"
                  maxLength={2000}
                  name="termsAndConditions"
                  className="ps-2"
                  style={{
                    width: "100%",
                    border: "1px solid #B9B9B94D",
                    height: "100px",
                    outline: "none",
                    borderRadius: "10px",
                    backgroundColor: "transparent",
                  }}
                  value={purchaseInfo.termsAndConditions}
                  onChange={handleChangePurchase}
                />
              </div>
            </div>
            <h6
              className="py-2 mt-4"
              style={{
                borderBottom: "2px solid #316AFF",
                fontSize: "18px",
                color: "#515151",
              }}
            >
              Description Information
            </h6>
            <div className="inputFieldDiv" style={{ display: "block" }}>
              <div
                className="inputFieldDivLeft"
                style={{ display: "block", width: "100%" }}
              >
                <p>Description</p>
                <input
                  type="text"
                  name="description"
                  maxLength={2000}
                  className="ps-2"
                  style={{
                    width: "100%",
                    border: "1px solid #B9B9B94D",
                    height: "100px",
                    outline: "none",
                    borderRadius: "10px",
                    backgroundColor: "transparent",
                  }}
                  value={purchaseInfo.description}
                  onChange={handleChangePurchase}
                />
              </div>
            </div>
          </div>
        </>
      );
    } else if (innerPage === "editSalesOrder") {
      return <></>;
    } else if (innerPage === "overview") {
      return (
        <>
          <div className="indiv_main_container ">
            <div className="individual_header_container commonContainer_bg w-100  flex-column  d-flex justify-content-between">
              <div className="iconContainer d-flex justify-content-between px-2 pt-2">
                <span
                  className="cursorPointer"
                  onClick={() => {
                    getAllPurchaseOrders(selectedOption);
                    setInnerPage("list");
                    setEditPurchaseOverview(false);
                    const resetFields = Object.fromEntries(
                      Object.keys(editingFields).map((key) => [key, false])
                    );
                    setEditingFields({
                      ...resetFields,
                    });
                    setShowType("");
                  }}
                >
                  {leftArrow}
                </span>

                <span>
                  <span
                    className={`me-2 ${
                      selectedPurchaseId === 0 ? "opacity-50" : "cursorPointer"
                    }`}
                    onClick={() => {
                      const leadIndex = allPurchaseOrders.findIndex(
                        (contact) => contact.purchaseOrdId === purchaseOrdId
                      );
                      if (leadIndex !== 0) {
                        setSelectedPurchaseId(leadIndex - 1);
                        setPurchaseOrdId(
                          allPurchaseOrders[leadIndex - 1].purchaseOrdId
                        );
                        purchaseOrderOverview(
                          allPurchaseOrders[leadIndex - 1].purchaseOrdId
                        );
                        getNotesDetails(
                          allPurchaseOrders[leadIndex - 1].purchaseOrdId,
                          selectRecent
                        );
                      }
                    }}
                  >
                    {leftAngleArrow}
                  </span>
                  <span
                    className={`ms-1 ${
                      selectedPurchaseId === allPurchaseOrders.length - 1
                        ? "opacity-50"
                        : "cursorPointer"
                    }`}
                    onClick={() => {
                      const leadIndex = allPurchaseOrders.findIndex(
                        (lead) => lead.purchaseOrdId === purchaseOrdId
                      );
                      if (leadIndex < allPurchaseOrders.length - 1) {
                        setSelectedPurchaseId(leadIndex + 1);
                        setPurchaseOrdId(
                          allPurchaseOrders[leadIndex + 1].purchaseOrdId
                        );
                        purchaseOrderOverview(
                          allPurchaseOrders[leadIndex + 1].purchaseOrdId
                        );
                        getNotesDetails(
                          allPurchaseOrders[leadIndex + 1].purchaseOrdId,
                          selectRecent
                        );
                      }
                    }}
                  >
                    {rightAngleArrow}
                  </span>
                </span>
              </div>
              <div>
                <div className="profile_logo_container d-flex py-2 justify-content-between mt-1">
                  <div className="d-flex flex-column ps-2 pb-2 ">
                    <span className="first_letter_uppercase">
                      {purchaseInfo?.subject}
                    </span>
                    <span className="opacity-75 ">
                      {purchaseInfo?.status.value}
                    </span>
                    {/* <span
                  className="createContactButton  backBtn mt-2  "
                  onClick={() => setInvoiceView("contactRecord")}
                >
                  Back
                </span> */}
                  </div>
                  <div className="d-flex  justify-content-end align-items-end  px-2 pb-2">
                    <div className="d-flex gap-2 position-relative">
                      <button
                        className="btn btn-secondary bg-white text-dark border border-primary"
                        onClick={handleEditPurchase}
                      >
                        Edit
                      </button>
                      <button
                        className="btn btn-secondary bg-white text-dark border border-primary"
                        onClick={(e) => {
                          e.stopPropagation();
                          setShowOptionsPurchase(!showOptionsPurchase);
                        }}
                      >
                        ---
                      </button>
                      {showOptionsPurchase && (
                        <div
                          className="rounded showOptions p-2 end-0"
                          style={{ top: "2.6rem" }}
                        >
                          <div
                            className="d-flex gap-3 align-items-center mt-2"
                            onClick={() => {
                              setInnerPage("clone");
                              setEditPurchaseOverview(false);
                              let selectedObj;
                              const selecteOne =
                                singlePurchaseDetails.purchaseItemsTotalInfoDetails;
                              if (selecteOne.length > 0) {
                                selectedObj = {
                                  adjustment: selecteOne.adjustment,
                                  discount: selecteOne.discount,
                                  grandTotal: selecteOne.grandTotal,
                                  purchaseOrdId: selecteOne.purchaseOrdId,
                                  subTotal: selecteOne.subTotal,
                                  discountType: selecteOne.discountType || 1,
                                  discountValue: selecteOne.discountValue || 0,
                                  tax: selecteOne.tax,
                                };
                              }
                              setPurchaseInfo({
                                ...singlePurchaseDetails.purchaseInfoDetails,
                                poDate:
                                  singlePurchaseDetails.purchaseInfoDetails
                                    .poDate,
                                dueDate:
                                  singlePurchaseDetails.purchaseInfoDetails
                                    .dueDate,
                                carrier: {
                                  label:
                                    singlePurchaseDetails.purchaseInfoDetails
                                      .carrier,
                                  value:
                                    singlePurchaseDetails.purchaseInfoDetails
                                      .carrier,
                                },
                                status: {
                                  label:
                                    singlePurchaseDetails.purchaseInfoDetails
                                      .status,
                                  value:
                                    singlePurchaseDetails.purchaseInfoDetails
                                      .status,
                                },

                                ...selectedObj,
                              });
                              setPurchaseItemsTotalInfoDetails(
                                singlePurchaseDetails.purchaseItemsTotalInfoDetails
                              );
                            }}
                          >
                            <img src={Clone} alt="clone" />
                            <div
                              style={{ color: "#515151", fontWeight: "300" }}
                              className="mt-1 cursorPointer ms-2"
                            >
                              Clone
                            </div>
                          </div>
                          <div
                            className="d-flex gap-3 align-items-center mt-2"
                            onClick={() => {
                              if (!isLoading) {
                                getAllInfoShare(purchaseOrdId);
                              }
                            }}
                          >
                            <img src={Share} alt="share" />
                            <div
                              style={{ color: "#515151", fontWeight: "300" }}
                              className="mt-2 cursorPointer ms-2"
                            >
                              Share
                            </div>
                          </div>
                          <div
                            className="d-flex gap-3 align-items-center mt-2"
                            onClick={handleDeletePurchase}
                          >
                            <img src={Delete} alt="delete" />
                            <div
                              style={{ color: "#515151", fontWeight: "300" }}
                              className="mt-2 cursorPointer ms-2"
                            >
                              Delete
                            </div>
                          </div>
                          <div
                            style={{ borderBottom: "1px solid #515151s" }}
                          ></div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="detailList_main_container mt-2 commonContainer_bg">
            <div className="detailList_main_container d-flex py-2 ">
              <div
                className={` pt-3 cursorPointer ${isShrinked ? "" : "w-25"}`}
                onClick={() => {
                  setIsShrinked(!isShrinked);
                }}
              >
                {isShrinked ? expandArrow : shrinkArrow}
              </div>
              {/* <div className="d-flex justify-content-between w-75 px-2">
                <span className="pt-3 ps-2">
                  Last Update:{" "}
                  {singlePurchaseDetails.purchaseInfoDetails.lastUpdate}
                </span>
                <div className="d-flex  gap-4 justify-content-between px-2 bg-white px-1  rounded-5 py-1">
                  <span
                    // className=""
                    className={`cursorPointer } ${
                      orderPageView === "overview"
                        ? "overviewTimelineTab"
                        : "  d-flex justify-content-center align-items-center"
                    }`}
                    onClick={() => setOrderPageView("overview")}
                  >
                    Overview
                  </span>
                  <span
                    className={`cursorPointer } ${
                      orderPageView === "timeline"
                        ? "overviewTimelineTab"
                        : " d-flex justify-content-center align-items-center"
                    }`}
                    onClick={() => {
                      if (orderPageView !== "timeline") {
                        getAllTimeline();
                        setOrderPageView("timeline");
                      }
                    }}
                  >
                    Timeline
                  </span>
                </div>
              </div> */}
              <div
                className={`d-flex justify-content-between px-2 ${
                  isShrinked ? "w-100" : "w-75 ps-3"
                }`}
              >
                <span className="pt-3 ps-2">
                  Last Update:{" "}
                  {singlePurchaseDetails.purchaseInfoDetails.lastUpdate}
                </span>
                <div className="d-flex  gap-4 justify-content-between px-2 bg-white px-1  rounded-5 py-1">
                  <span
                    // className=""
                    className={`cursorPointer } ${
                      orderPageView === "overview"
                        ? "overviewTimelineTab "
                        : "  d-flex justify-content-center align-items-center"
                    }`}
                    onClick={() => setOrderPageView("overview")}
                  >
                    Overview
                  </span>
                  <span
                    className={`cursorPointer } ${
                      orderPageView === "timeline"
                        ? "overviewTimelineTab"
                        : " d-flex justify-content-center align-items-center"
                    }`}
                    onClick={() => {
                      getAllTimeline();
                    }}
                  >
                    Timeline
                  </span>
                </div>
              </div>
            </div>

            <div className="detailList_content_container d-flex justify-content-between px-2 pt-2 bg-white">
              <div
                className={`w-25 relatedListContainer  rounded  commonContainer_bg me-2 ${
                  isShrinked ? "d-none" : ""
                }`}
              >
                <div className="d-flex pt-2">
                  <p className="opacity-57 ps-2 opacity-75 m-0 py-2">
                    Related List
                  </p>
                </div>
                <div className="horizontal_line opacity-50 mx-1 border border-secondary"></div>
                <ul className="relateList_container">
                  {notePermission === "TRUE" && (
                    <li
                      className={`cursorPointer py-2 relatedList_tab rounded-1 }`}
                      onClick={() => {
                        if (orderPageView === "timeline") {
                          setOrderPageView("overview");
                          setTimeout(() => scrollToSection("notesSection"), 0);
                        } else {
                          scrollToSection("notesSection");
                        }
                      }}
                    >
                      <span>
                        <img src={noteIcon} alt="img" height={20} width={20} />
                      </span>
                      Notes
                    </li>
                  )}

                  <li
                    className={`cursorPointer py-2 relatedList_tab rounded-1 }`}
                    onClick={() => {
                      if (orderPageView === "timeline") {
                        setOrderPageView("overview");
                        setTimeout(() => scrollToSection("openActivity"), 0);
                      } else {
                        scrollToSection("openActivity");
                      }
                    }}
                  >
                    <span>
                      <img
                        src={openActivityIcon}
                        alt="img"
                        height={20}
                        width={20}
                      />
                    </span>
                    Open Activities
                  </li>
                  <li
                    className={`cursorPointer py-2 relatedList_tab rounded-1 }`}
                    onClick={() => {
                      if (orderPageView === "timeline") {
                        setOrderPageView("overview");
                        setTimeout(() => scrollToSection("closeActivity"), 0);
                      } else {
                        scrollToSection("closeActivity");
                      }
                    }}
                  >
                    <span>
                      <img
                        src={closeActivityIcon}
                        alt="img"
                        height={20}
                        width={20}
                      />
                    </span>
                    Closed Activities
                  </li>
                </ul>
              </div>
              {/* Contact Overview Implementation */}
              {orderPageView === "overview" ? (
                <div
                  // className="bg-white w-75 rounded-2 p-3 leadDetail_main_Container "
                  className={`common_bg bg-white w-75 rounded-2 leadDetail_main_Container ${
                    isShrinked ? "w-100" : "w-75"
                  }`}
                >
                  <div className=" common_bg ">
                    <div className="lead_DetailContainer h-25    rounded-2  commonContainer_bg d-flex justify-content-between align-items-center py-2">
                      <div className="w-100 d-flex flex-column">
                        {fieldLabels.map((field, i) => {
                          const fieldValue =
                            field.value ||
                            field.name
                              .replace(/\s+/g, "")
                              .replace(/^(.)/, (match) => match.toLowerCase());
                          return field.isShowed ? (
                            <div className="d-flex w-75 ps-3" key={i}>
                              <div className="w-50 position-relative pe-5">
                                <div
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    setSearchField("");
                                    setSelectedField(
                                      selectedField === field.name
                                        ? ""
                                        : field.name
                                    );
                                  }}
                                  className={`cursorPointer d-flex justify-content-between w-100 my-1 p-1 opacity-75 ${
                                    selectedField === field.name
                                      ? "outline_border rounded-1"
                                      : ""
                                  }`}
                                >
                                  <span>{field.name}</span>
                                  <img
                                    src={
                                      selectedField === field.name ? up : down
                                    }
                                    alt="img"
                                    height={20}
                                    width={20}
                                    className="pt-1"
                                  />
                                </div>
                                {selectedField === field.name && (
                                  <div
                                    className="position-absolute field_container w-100 pe-5"
                                    onClick={(e) => {
                                      e.stopPropagation();
                                    }}
                                  >
                                    <div className="">
                                      <label
                                        htmlFor="selectField"
                                        className="position-relative w-100"
                                      >
                                        <input
                                          id="selectField"
                                          type="text"
                                          className="rounded-2 p-1 w-100 pe-4"
                                          placeholder="Search"
                                          value={searchField}
                                          onChange={(e) => {
                                            if (
                                              e.target.value.charAt(0) !== " "
                                            ) {
                                              const value =
                                                e.target.value.replace(
                                                  /[^a-zA-Z ]+|(\s)\s+/g,
                                                  "$1"
                                                );
                                              setSearchField(value);
                                            }
                                          }}
                                        />
                                        <img
                                          className="position-absolute mt-1 me-2 end-0 pt-1 cursorPointer"
                                          onClick={() => {
                                            setSearchField("");
                                          }}
                                          src={searchIcon}
                                          alt="search"
                                          width={20}
                                        />
                                      </label>

                                      <div className="w-100 rounded-2">
                                        <div className="border border-1 field_container_dropdown rounded-1">
                                          {showSearchFields
                                            .filter((field) =>
                                              field.name
                                                .toLowerCase()
                                                .includes(
                                                  searchField
                                                    .trim()
                                                    .toLowerCase()
                                                )
                                            )
                                            .sort((a, b) =>
                                              a.name.localeCompare(b.name)
                                            )
                                            .map(
                                              (field) =>
                                                (selectedField === field.name ||
                                                  !field.isShowed) && (
                                                  <div
                                                    key={field.name}
                                                    className="d-flex gap-2 p-2 cursorPointer rounded-2 align-items-center"
                                                    onClick={() => {
                                                      selectedField ===
                                                      field.name
                                                        ? setSelectedField("")
                                                        : handleFieldClick(
                                                            field
                                                          );
                                                    }}
                                                  >
                                                    <span className="right_tick">
                                                      {selectedField ===
                                                      field.name
                                                        ? tick
                                                        : ""}
                                                    </span>
                                                    <span className="ps-2">
                                                      {field.name}
                                                    </span>
                                                  </div>
                                                )
                                            )}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                )}
                              </div>
                              <div
                                className={`w-50 mt-1 ms-5 position-relative`}
                              >
                                {field.type === "input" ||
                                field.type === "textbox" ? (
                                  <EditableInput
                                    fieldType={
                                      ["dueDate", "poDate"].includes(fieldValue)
                                        ? "date"
                                        : "text"
                                    }
                                    className="ps-3"
                                    inputType={fieldValue}
                                    handleIndividualInputChange={
                                      handleIndividualInputChange
                                    }
                                    editingFields={editingFields}
                                    handleEditClick={handleEditClick}
                                    handleCancelClick={handleCancelClick}
                                    handleUpdateClick={handleUpdateClick}
                                    updatedFields={purchaseInfo}
                                    accountOverviewDetails={purchaseInfo}
                                    setShowType={setShowType}
                                    showType={showType}
                                  />
                                ) : field.type === "dropdown" ? (
                                  <div
                                    className="position-relative d-flex"
                                    style={{ alignItems: "center" }}
                                    onClick={() => {
                                      handleEditClick(fieldValue);
                                    }}
                                  >
                                    {editingFields[fieldValue] &&
                                    showType === fieldValue ? (
                                      <Select
                                        value={purchaseInfo[fieldValue]}
                                        // value={accountInfoDetails[fieldValue]}
                                        menuPlacement="auto"
                                        onChange={(selectedOption) =>
                                          handleChangeIndDropDown(
                                            selectedOption,
                                            fieldValue
                                          )
                                        }
                                        options={
                                          fieldValue === "status"
                                            ? stausOptions
                                            : carrierOptions
                                        }
                                        styles={fieldDropdownStyles}
                                        isSearchable={false}
                                      />
                                    ) : (
                                      <input
                                        type="text"
                                        className={`cursorPointer indvEditInput
                        ${
                          showType === fieldValue
                            ? "selectedBorder "
                            : "unselectBorder bg-transparent"
                        }`}
                                        value={purchaseInfo[fieldValue]?.value}
                                        name={fieldValue}
                                        onClick={(e) => {
                                          if (showType === "") {
                                            // purchaseInfo[fieldValue] =
                                            // purchaseInfo[fieldValue];
                                            setShowType(e.target.name);
                                            setPurchaseInfo(purchaseInfo);
                                          }
                                          handleEditClick(fieldValue);
                                        }}
                                        // readOnly={showType !== "emailId"}
                                        readOnly={true}
                                      />
                                    )}
                                    {editingFields[fieldValue] &&
                                      showType === fieldValue && (
                                        <div className="d-flex gap-2">
                                          <span
                                            onClick={() => {
                                              setShowType("");
                                              handleUpdateClick(fieldValue);
                                            }}
                                            className="ps-2 cursorPointer"
                                          >
                                            <img
                                              src={updateIcon}
                                              alt="img"
                                              height={20}
                                              width={20}
                                            />
                                          </span>
                                          <span
                                            onClick={() => {
                                              setShowType("");
                                              // handleUpdateClick(inputType);
                                              handleCancelClick(fieldValue);
                                            }}
                                            className="cursorPointer"
                                          >
                                            <img
                                              src={cancelIcon}
                                              alt="img"
                                              height={20}
                                              width={20}
                                            />
                                          </span>
                                        </div>
                                      )}
                                  </div>
                                ) : field.type === "readOnly" ? (
                                  <div>
                                    <span className="d-flex flex-column">
                                      <span>
                                        {field.name === "Created By" ||
                                        field.name === "Modified By" ||
                                        field.name === "Vendor Name" ||
                                        field.name === "Contact Name"
                                          ? purchaseInfo[
                                              field.name === "Created By"
                                                ? "createdBy"
                                                : field.name === "Modified By"
                                                ? "modifiedBy"
                                                : field.name === "Vendor Name"
                                                ? "vendorName"
                                                : "contactName"
                                            ]
                                          : purchaseItemsTotalInfoDetails[
                                              field.name === "Grand Total"
                                                ? "grandTotal"
                                                : field.name === "Sub Total"
                                                ? "subTotal"
                                                : field.name === "Tax"
                                                ? "tax"
                                                : field.name === "Discount"
                                                ? "discount"
                                                : "adjustment"
                                            ]}
                                      </span>
                                      {(field.name === "Created By" ||
                                        field.name === "modifiedBy") && (
                                        <span className="small opacity-75">
                                          {
                                            purchaseInfo[
                                              field.name === "Created By"
                                                ? "createdDate"
                                                : "modifiedDate"
                                            ]
                                          }
                                        </span>
                                      )}
                                    </span>
                                  </div>
                                ) : (
                                  <div className="position-relative">
                                    <div
                                      className="d-flex  position-relative"
                                      onClick={(e) => {
                                        handleOwnerInputClick(e);
                                        setSelectedOwner(selectedOwner);
                                        setShowModalType("changeOwner");
                                        setListVisible(false);
                                      }}
                                    >
                                      <input
                                        type="text"
                                        className="w-100 ps-1 cursorPointer  indvEditInput border-0 indivOwnerInput"
                                        value={selectedOwner.name}
                                        readOnly
                                      />
                                      {/* <span
                                className="position-absolute cursorPointer"
                                style={{ right: "0.8rem" }}
                                onClick={() => setSelectedItem("")}
                              >
                                <img
                                  src={listVisible ? up : down}
                                  alt="img"
                                  height={20}
                                  width={20}
                                />
                              </span> */}
                                      {/* <span
                                className="position-absolute end-0"
                                style={{ marginRight: "-1.6rem" }}
                              >
                                <span
                                  className="cursorPointer pb-1 ps-1"
                                  onClick={() => {
                                    setSelectedOwnerItem(selectedItem);
                                    setShowModalType("changeOwner");
                                    setListVisible(false);
                                  }}
                                >
                                  <img
                                    src={userSearch}
                                    alt="img"
                                    width={20}
                                    height={20}
                                    // className="mb-2 ms-1"
                                  />
                                </span>
                              </span> */}
                                    </div>
                                  </div>
                                )}
                              </div>
                            </div>
                          ) : null;
                        })}
                      </div>
                    </div>

                    <div className="lead_Information_mainContainer commonContainer_bg rounded-2  d-flex flex-column my-2">
                      <h5 className="w-100 text-primary px-2 py-2">
                        Purchase Order Information
                      </h5>

                      <div className="d-flex justify-content-between">
                        <div className="leadContact_container d-flex gap-4 w-100 ps-2 pe-2">
                          <div className="w-50">
                            {fieldIndividualDropdowns
                              .slice(0, 8)
                              .map((field) => {
                                const fieldValue =
                                  field.value ||
                                  field.name
                                    .replace(/\s+/g, "")
                                    .replace(/^(.)/, (match) =>
                                      match.toLowerCase()
                                    );
                                return (
                                  <div
                                    className="d-flex justify-content-between align-items-center my-2 w-100 gap-3"
                                    key={fieldValue}
                                  >
                                    <div className="opacity-75 p-2 w-100">
                                      {field.name}
                                    </div>
                                    <div className="w-100 ">
                                      {field.type === "input" ? (
                                        <EditableInput
                                          fieldType={
                                            [
                                              "dueDateInfo",
                                              "poDateInfo",
                                            ].includes(fieldValue)
                                              ? "date"
                                              : "text"
                                          }
                                          className="ps-3"
                                          inputType={fieldValue}
                                          handleIndividualInputChange={
                                            handleIndividualInputChange
                                          }
                                          editingFields={editingFields}
                                          handleEditClick={handleEditClick}
                                          handleCancelClick={handleCancelClick}
                                          handleUpdateClick={handleUpdateClick}
                                          updatedFields={purchaseInfo}
                                          accountOverviewDetails={purchaseInfo}
                                          setShowType={setShowType}
                                          showType={showType}
                                          fieldsType="second"
                                        />
                                      ) : field.type === "dropdown" ? (
                                        <div
                                          className="position-relative"
                                          onClick={() => {
                                            handleEditClick(fieldValue);
                                          }}
                                        >
                                          {editingFields[fieldValue] ? (
                                            <div className="position-relative">
                                              <Select
                                                value={purchaseInfo[fieldValue]}
                                                menuPlacement="auto"
                                                onChange={(selectedOption) => {
                                                  handleChangeSelectDropDown(
                                                    selectedOption,
                                                    fieldValue
                                                  );
                                                }}
                                                options={
                                                  fieldValue === "status"
                                                    ? stausOptions
                                                    : carrierOptions
                                                }
                                                styles={fieldDropdownStyles}
                                                isSearchable={false}
                                              />
                                              <div
                                                className="position-absolute end-0 top-0 mt-1"
                                                style={{
                                                  marginRight: "-0.7rem",
                                                }}
                                              >
                                                <div className="d-flex gap-1">
                                                  <span
                                                    onClick={(e) => {
                                                      e.stopPropagation();
                                                      setShowType("");
                                                      handleUpdateClick(
                                                        fieldValue
                                                      );
                                                    }}
                                                    className="ps-2 cursorPointer"
                                                  >
                                                    <img
                                                      src={updateIcon}
                                                      alt="img"
                                                      height={20}
                                                      width={20}
                                                    />
                                                  </span>
                                                  <span
                                                    onClick={(e) => {
                                                      e.stopPropagation();
                                                      setShowType("");
                                                      handleCancelClick(
                                                        fieldValue
                                                      );
                                                    }}
                                                    className="cursorPointer"
                                                  >
                                                    <img
                                                      src={cancelIcon}
                                                      alt="img"
                                                      height={20}
                                                      width={20}
                                                    />
                                                  </span>
                                                </div>
                                              </div>
                                            </div>
                                          ) : (
                                            <div
                                              onClick={() => {
                                                handleEditClick(fieldValue);
                                              }}
                                              className="w-100 bg-transparent border rounded-1 p-1"
                                            >
                                              {purchaseInfo[fieldValue]?.value}
                                            </div>
                                          )}
                                        </div>
                                      ) : field.type === "readOnly" ? (
                                        <div>
                                          <span className="d-flex flex-column">
                                            <span>
                                              {field.name === "Created By" ||
                                              field.name === "Modified By" ||
                                              field.name === "Vendor Name" ||
                                              field.name === "Contact Name"
                                                ? purchaseInfo[
                                                    field.name === "Created By"
                                                      ? "createdBy"
                                                      : field.name ===
                                                        "Modified By"
                                                      ? "modifiedBy"
                                                      : field.name ===
                                                        "Vendor Name"
                                                      ? "vendorName"
                                                      : "contactName"
                                                  ]
                                                : purchaseItemsTotalInfoDetails[
                                                    field.name === "Grand Total"
                                                      ? "grandTotal"
                                                      : field.name ===
                                                        "Sub Total"
                                                      ? "subTotal"
                                                      : field.name === "Tax"
                                                      ? "tax"
                                                      : field.name ===
                                                        "Discount"
                                                      ? "discount"
                                                      : "adjustment"
                                                  ]}
                                            </span>
                                            {(field.name === "Created By" ||
                                              field.name === "Modified By") && (
                                              <span className="small opacity-75">
                                                {
                                                  purchaseInfo[
                                                    field.name === "Created By"
                                                      ? "createdDate"
                                                      : "modifiedDate"
                                                  ]
                                                }
                                              </span>
                                            )}
                                          </span>
                                        </div>
                                      ) : (
                                        <div className="position-relative">
                                          <div
                                            className="d-flex  position-relative"
                                            onClick={(e) => {
                                              handleOwnerInputClick(e);
                                              setSelectedOwner(selectedOwner);
                                              setShowModalType("changeOwner");
                                              setListVisible(false);
                                            }}
                                          >
                                            <input
                                              type="text"
                                              className="w-100 ps-1 cursorPointer  indvEditInput border-0 indivOwnerInput"
                                              value={selectedOwner?.name}
                                              readOnly
                                            />
                                            {/* <span
                                        className="position-absolute cursorPointer"
                                        style={{ right: "0.8rem" }}
                                        onClick={() => setSelectedItem("")}
                                      >
                                        <img
                                          src={listVisible ? up : down}
                                          alt="img"
                                          height={20}
                                          width={20}
                                        />
                                      </span> */}
                                            {/* <span
                                        className="position-absolute end-0"
                                        style={{ marginRight: "-1.6rem" }}
                                      >
                                        <span
                                          className="cursorPointer pb-1 ps-1"
                                          onClick={() => {
                                            setSelectedOwnerItem(selectedItem);
                                            setShowModalType("changeOwner");
                                            setListVisible(false);
                                          }}
                                        >
                                          <img
                                            src={userSearch}
                                            alt="img"
                                            width={20}
                                            height={20}
                                            // className="mb-2 ms-1"
                                          />
                                        </span>
                                      </span> */}
                                          </div>
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                );
                              })}
                          </div>
                          <div className="w-50">
                            {fieldIndividualDropdowns
                              .slice(8, 15)
                              .map((field) => {
                                const fieldValue =
                                  field.value ||
                                  field.name
                                    .replace(/\s+/g, "")
                                    .replace(/^(.)/, (match) =>
                                      match.toLowerCase()
                                    );
                                return (
                                  <div
                                    className="d-flex justify-content-between align-items-center my-2 w-100 gap-3"
                                    key={fieldValue}
                                  >
                                    <div className="opacity-75 p-2 w-100">
                                      {field.name}
                                    </div>
                                    <div className="w-100">
                                      {field.type === "input" ? (
                                        <EditableInput
                                          fieldType={
                                            ["dueDate", "poDate"].includes(
                                              fieldValue
                                            )
                                              ? "date"
                                              : "text"
                                          }
                                          className="ps-3"
                                          inputType={fieldValue}
                                          handleIndividualInputChange={
                                            handleIndividualInputChange
                                          }
                                          editingFields={editingFields}
                                          handleEditClick={handleEditClick}
                                          handleCancelClick={handleCancelClick}
                                          handleUpdateClick={handleUpdateClick}
                                          updatedFields={purchaseInfo}
                                          accountOverviewDetails={purchaseInfo}
                                          setShowType={setShowType}
                                          showType={showType}
                                          fieldsType="second"
                                        />
                                      ) : field.type === "readOnly" ? (
                                        <div>
                                          <span className="d-flex flex-column">
                                            <span>
                                              {field.name === "Created By" ||
                                              field.name === "Modified By" ||
                                              field.name === "Vendor Name" ||
                                              field.name === "Contact Name"
                                                ? purchaseInfo[
                                                    field.name === "Created By"
                                                      ? "createdBy"
                                                      : field.name ===
                                                        "Modified By"
                                                      ? "modifiedBy"
                                                      : field.name ===
                                                        "Vendor Name"
                                                      ? "vendorName"
                                                      : "contactName"
                                                  ]
                                                : purchaseItemsTotalInfoDetails[
                                                    field.name === "Grand Total"
                                                      ? "grandTotal"
                                                      : field.name ===
                                                        "Sub Total"
                                                      ? "subTotal"
                                                      : field.name === "Tax"
                                                      ? "tax"
                                                      : field.name ===
                                                        "Discount"
                                                      ? "discount"
                                                      : "adjustment"
                                                  ]}
                                            </span>
                                            {(field.name === "Created By" ||
                                              field.name === "Modified By") && (
                                              <span className="small opacity-75">
                                                {
                                                  purchaseInfo[
                                                    field.name === "Created By"
                                                      ? "createdDate"
                                                      : "modifiedDate"
                                                  ]
                                                }
                                              </span>
                                            )}
                                          </span>
                                        </div>
                                      ) : (
                                        <div className="position-relative">
                                          <div
                                            className="d-flex  position-relative"
                                            onClick={(e) => {
                                              handleOwnerInputClick(e);
                                              setSelectedOwner(selectedOwner);
                                              setShowModalType("changeOwner");
                                              setListVisible(false);
                                            }}
                                          >
                                            <input
                                              type="text"
                                              className="w-100 ps-1 cursorPointer  indvEditInput border-0 indivOwnerInput"
                                              value={selectedOwner.name}
                                              readOnly
                                            />
                                            {/* <span
                                        className="position-absolute cursorPointer"
                                        style={{ right: "0.8rem" }}
                                        onClick={() => setSelectedItem("")}
                                      >
                                        <img
                                          src={listVisible ? up : down}
                                          alt="img"
                                          height={20}
                                          width={20}
                                        />
                                      </span> */}
                                            {/* <span
                                        className="position-absolute end-0"
                                        style={{ marginRight: "-1.6rem" }}
                                      >
                                        <span
                                          className="cursorPointer pb-1 ps-1"
                                          onClick={() => {
                                            setSelectedOwnerItem(selectedItem);
                                            setShowModalType("changeOwner");
                                            setListVisible(false);
                                          }}
                                        >
                                          <img
                                            src={userSearch}
                                            alt="img"
                                            width={20}
                                            height={20}
                                            // className="mb-2 ms-1"
                                          />
                                        </span>
                                      </span> */}
                                          </div>
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                );
                              })}
                          </div>
                        </div>
                      </div>
                      <h5 className="w-100 text-primary px-2 py-2">
                        Address Information
                      </h5>

                      <div className="d-flex justify-content-between">
                        <div className="leadContact_container d-flex gap-4 w-100 ps-2 pe-2">
                          <div className="w-50">
                            {fieldIndividualDropdowns
                              .slice(15, 20)
                              .map((field) => {
                                const fieldValue =
                                  field.value ||
                                  field.name
                                    .replace(/\s+/g, "")
                                    .replace(/^(.)/, (match) =>
                                      match.toLowerCase()
                                    );

                                return (
                                  <div
                                    className="d-flex justify-content-between align-items-center my-2 w-100 gap-3"
                                    key={fieldValue}
                                  >
                                    <div className="opacity-75 p-2 w-100">
                                      {field.name}
                                    </div>
                                    <div className="w-100">
                                      <EditableInput
                                        fieldType={"text"}
                                        className="ps-3"
                                        inputType={fieldValue}
                                        handleIndividualInputChange={
                                          handleIndividualInputChange
                                        }
                                        editingFields={editingFields}
                                        handleEditClick={handleEditClick}
                                        handleCancelClick={handleCancelClick}
                                        handleUpdateClick={handleUpdateClick}
                                        updatedFields={purchaseInfo}
                                        accountOverviewDetails={purchaseInfo}
                                        setShowType={setShowType}
                                        showType={showType}
                                        fieldsType="second"
                                      />
                                    </div>
                                  </div>
                                );
                              })}
                          </div>
                          <div className="w-50">
                            {fieldIndividualDropdowns
                              .slice(19, 24)
                              .map((field) => {
                                const fieldValue =
                                  field.value ||
                                  field.name
                                    .replace(/\s+/g, "")
                                    .replace(/^(.)/, (match) =>
                                      match.toLowerCase()
                                    );
                                return (
                                  <div
                                    className="d-flex justify-content-between align-items-center my-2 w-100 gap-3"
                                    key={fieldValue}
                                  >
                                    <div className="opacity-75 p-2 w-100">
                                      {field.name}
                                    </div>
                                    <div className="w-100">
                                      <EditableInput
                                        fieldType={"text"}
                                        className="ps-3"
                                        inputType={fieldValue}
                                        handleIndividualInputChange={
                                          handleIndividualInputChange
                                        }
                                        editingFields={editingFields}
                                        handleEditClick={handleEditClick}
                                        handleCancelClick={handleCancelClick}
                                        handleUpdateClick={handleUpdateClick}
                                        updatedFields={purchaseInfo}
                                        accountOverviewDetails={purchaseInfo}
                                        setShowType={setShowType}
                                        showType={showType}
                                        fieldsType="second"
                                      />
                                    </div>
                                  </div>
                                );
                              })}
                          </div>
                        </div>
                      </div>

                      <h5 className="w-100 text-primary px-2 pt-2">
                        Purchase Items
                      </h5>
                      <div
                        className="horizontal-scroll-container mb-2 px-2 border-0 w-100"
                        style={{
                          overflow: "auto",
                          // border: "none",
                        }}
                      >
                        <table
                          className="table w-100 responsive"
                          style={{
                            // tableLayout: "fixed",
                            // minWidth: "1000px",
                            margin: "0px",
                          }}
                        >
                          <colgroup>
                            <col />
                            <col />
                            <col />
                            <col />
                            <col />
                            <col />
                            <col />
                            <col />
                            <col />
                          </colgroup>
                          <thead>
                            <tr className="mb-2">
                              {/* <th
                                className="border-nonw"
                                style={{
                                  backgroundColor: "rgba(243, 246, 251, 1)",
                                }}
                              ></th> */}
                              <th
                                style={{
                                  backgroundColor: "#EFEFEF",
                                  border: "1px solid rgba(185, 185, 185, 0.5)",
                                }}
                              >
                                SL.NO
                              </th>
                              <th
                                style={{
                                  backgroundColor: "#EFEFEF",
                                  border: "1px solid rgba(185, 185, 185, 0.5)",
                                }}
                              >
                                Product Name
                              </th>
                              <th
                                style={{
                                  backgroundColor: "#EFEFEF",
                                  border: "1px solid rgba(185, 185, 185, 0.5)",
                                }}
                              >
                                Quantity
                              </th>
                              <th
                                style={{
                                  backgroundColor: "#EFEFEF",
                                  border: "1px solid rgba(185, 185, 185, 0.5)",
                                }}
                              >
                                List Price (₹)
                              </th>
                              <th
                                style={{
                                  backgroundColor: "#EFEFEF",
                                  border: "1px solid rgba(185, 185, 185, 0.5)",
                                }}
                              >
                                Amount (₹)
                              </th>
                              <th
                                style={{
                                  backgroundColor: "#EFEFEF",
                                  border: "1px solid rgba(185, 185, 185, 0.5)",
                                }}
                              >
                                Discount (₹)
                              </th>
                              <th
                                style={{
                                  backgroundColor: "#EFEFEF",
                                  border: "1px solid rgba(185, 185, 185, 0.5)",
                                }}
                              >
                                Tax (%)
                              </th>
                              <th
                                style={{
                                  backgroundColor: "#EFEFEF",
                                  border: "1px solid rgba(185, 185, 185, 0.5)",
                                }}
                              >
                                Total (₹)
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {allProducts.map((row, index) => (
                              <tr key={index}>
                                {/* <td
                                  style={{
                                    backgroundColor: "rgba(243, 246, 251, 1)",
                                    border: "none",
                                    alignContent: "center",
                                    padding: "0px",
                                  }}
                                ></td> */}
                                <td
                                  style={{
                                    color: "#2177FE",
                                  }}
                                >
                                  {index + 1}
                                </td>
                                <td
                                  style={{
                                    backgroundColor: "white",
                                    border:
                                      "1px solid rgba(185, 185, 185, 0.5)",
                                  }}
                                  className="d-flex flex-column gap-1 w-100"
                                >
                                  <span>{row.productName || "-"}</span>
                                  <span className="opacity-50 pt-0">
                                    {row.description || "-"}
                                  </span>
                                </td>
                                <td
                                  style={{
                                    backgroundColor: "white",
                                    border:
                                      "1px solid rgba(185, 185, 185, 0.5)",
                                  }}
                                >
                                  {row.quantity}
                                </td>
                                <td
                                  style={{
                                    backgroundColor: "white",
                                    border:
                                      "1px solid rgba(185, 185, 185, 0.5)",
                                  }}
                                >
                                  {row.listPrice}
                                </td>
                                <td
                                  style={{
                                    backgroundColor: "white",
                                    border:
                                      "1px solid rgba(185, 185, 185, 0.5)",
                                  }}
                                >
                                  {row.amount}
                                </td>
                                <td
                                  style={{
                                    backgroundColor: "white",
                                    border:
                                      "1px solid rgba(185, 185, 185, 0.5)",
                                  }}
                                >
                                  {row.discount}
                                </td>
                                <td
                                  style={{
                                    backgroundColor: "white",
                                    border:
                                      "1px solid rgba(185, 185, 185, 0.5)",
                                  }}
                                >
                                  {row.tax}
                                </td>
                                <td
                                  style={{
                                    backgroundColor: "white",
                                    border:
                                      "1px solid rgba(185, 185, 185, 0.5)",
                                  }}
                                >
                                  {row.total}
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>

                      <div className="table-responsive rounded-2 d-flex justify-content-end pe-2">
                        <table
                          className="table"
                          style={{
                            borderCollapse: "collapse",
                            width: "26vw",
                            margin: "0",
                            boxShadow:
                              "0px 0px 18.12px 0px rgba(0, 0, 0, 0.03)",
                            background: "rgba(255, 255, 255, 1)",
                          }}
                        >
                          {/* {purchaseItemsTotalInfoDetails?.map(
                            (purchaseInfo) => {
                              return ( */}
                          <thead
                            style={{ width: "max-content" }}
                            key={purchaseItemsTotalInfoDetails.purchaseOrdId}
                          >
                            <tr className="mb-2">
                              <th
                                scope="col"
                                style={{
                                  backgroundColor: "#F8F8F8",
                                  width: "max-content",
                                }}
                              >
                                Sub Total(₹)
                              </th>
                              <td style={{ backgroundColor: "#F8F8F8" }}>
                                {purchaseItemsTotalInfoDetails.subTotal}
                              </td>
                            </tr>
                            <tr>
                              <th scope="col">Discount(₹)</th>
                              <td>{purchaseItemsTotalInfoDetails.discount}</td>
                            </tr>
                            <tr>
                              <th
                                scope="col"
                                style={{
                                  backgroundColor: "#F8F8F8",
                                }}
                              >
                                Tax(₹)
                              </th>
                              <td style={{ backgroundColor: "#F8F8F8" }}>
                                {purchaseItemsTotalInfoDetails.tax}
                              </td>
                            </tr>
                            <tr>
                              <th scope="col">Adjustment(₹)</th>
                              <td>
                                {purchaseItemsTotalInfoDetails.adjustment}
                              </td>
                            </tr>
                            <tr>
                              <th
                                scope="col"
                                style={{
                                  backgroundColor: "#F8F8F8",
                                }}
                              >
                                Grand Total(₹)
                              </th>
                              <td style={{ backgroundColor: "#F8F8F8" }}>
                                {purchaseItemsTotalInfoDetails.grandTotal}
                              </td>
                            </tr>
                          </thead>
                        </table>
                      </div>

                      <h5 className="w-100 text-primary px-2 pt-2">
                        Terms and Conditions
                      </h5>
                      <div className="d-flex gap-2 w-75">
                        <span className="opacity-75 px-2 pb-2 w-50">
                          Terms and Conditions
                        </span>
                        <EditableInput
                          fieldType="text"
                          inputType="termsAndConditions"
                          handleIndividualInputChange={
                            handleIndividualInputChange
                          }
                          editingFields={editingFields}
                          handleEditClick={handleEditClick}
                          handleCancelClick={handleCancelClick}
                          handleUpdateClick={handleUpdateClick}
                          updatedFields={purchaseInfo}
                          accountOverviewDetails={purchaseInfo}
                          showType={showType}
                          setShowType={setShowType}
                          isTextarea={true}
                        />
                        {/* <EditableInput
                          inputType="termsAndConditions"
                          handleIndividualInputChange={
                            handleIndividualInputChange
                          }
                          editingFields={editingFields}
                          handleEditClick={handleEditClick}
                          handleCancelClick={handleCancelClick}
                          handleUpdateClick={handleUpdateClick}
                          updatedFields={purchaseInfo}
                          accountOverviewDetails={purchaseInfo}
                          showType={showType}
                          setShowType={setShowType}
                        /> */}
                      </div>
                      <h5 className="w-100 text-primary px-2 pt-2">
                        Description
                      </h5>
                      <div className="d-flex gap-2 mb-2 w-75">
                        <span className="opacity-75 px-2 pb-2 w-50">
                          Description
                        </span>

                        <EditableInput
                          fieldType="text"
                          inputType="description"
                          handleIndividualInputChange={
                            handleIndividualInputChange
                          }
                          editingFields={editingFields}
                          handleEditClick={handleEditClick}
                          handleCancelClick={handleCancelClick}
                          handleUpdateClick={handleUpdateClick}
                          updatedFields={purchaseInfo}
                          accountOverviewDetails={purchaseInfo}
                          showType={showType}
                          setShowType={setShowType}
                          isTextarea={true}
                        />
                      </div>
                    </div>
                    {notePermission === "TRUE" && (
                      <div
                        id="notesSection"
                        className="lead_Information_mainContainer commonContainer_bg rounded-2  d-flex flex-column py-2 mt-2"
                      >
                        <div>
                          <Note
                            note={note}
                            addTitle={addTitle}
                            showButtons={showButtons}
                            setShowButtons={setShowButtons}
                            textFile={selectedNoteFiles}
                            showTitleNote={showTitleNote}
                            setShowTitleNote={setShowTitleNote}
                            handleTextAreaClick={() => setShowButtons(true)}
                            handleTextFileChange={handleTextFileChange}
                            handleSaveButton={insertNote}
                            handleAddTitleClick={handleAddTitleClick}
                            setNoteTitle={setNoteTitle}
                            noteInfo={noteInfo}
                            selectRecent={selectRecent}
                            handleSelectRecent={handleSelectRecent}
                            handleDeleteClick={deleteNoteDetails}
                            leadId={purchaseOrdId}
                            handleUpdateClick={updateNote}
                            setUpdateNotes={setUpdateNotes}
                            updateNotes={updateNotes}
                            setEditNote={setEditNote}
                            editNote={editNote}
                            handleChangeNoteTitle={handleChangeNoteTitle}
                            handleChangeNoteDescription={
                              handleChangeNoteDescription
                            }
                            setAddTitle={setAddTitle}
                            setNote={setNote}
                            moduleType={"Purchase Orders"}
                            recordName={purchaseInfo?.subject}
                            selectedNoteFiles={selectedNoteFiles}
                            setSelectedNoteFiles={setSelectedNoteFiles}
                            onChildDataChange={handleChildDataChange}
                            noteDeleteConfirmation={noteDeleteConfirmation}
                            setNoteDeleteConfirmation={
                              setNoteDeleteConfirmation
                            }
                          />

                          {showTitleNote && (
                            <textarea
                              rows={2}
                              className="notesClass px-2"
                              placeholder="Add note Title"
                              value={noteTitle}
                              onChange={(e) => setNoteTitle(e.target.value)}
                              style={{ width: "95%", marginTop: "10px" }}
                            />
                          )}
                        </div>
                      </div>
                    )}

                    <div
                      id="openActivity"
                      className="lead_Information_mainContainer commonContainer_bg rounded-2  d-flex flex-column py-2 mt-2"
                    >
                      <OpenActivity
                        openActivities={openActivities}
                        // getOverview={() => accountOverviewDetail(accountId)}
                        openActivitiesCalls={openActivitiesCalls}
                        openActivitiesMeetings={openActivitiesMeetings}
                        moduleName="Products"
                      />
                    </div>

                    <div
                      id="closeActivity"
                      className="lead_Information_mainContainer commonContainer_bg rounded-2  d-flex flex-column py-2 mt-2"
                    >
                      <CloseActivity
                        closeActivities={closeActivities}
                        // getOverview={() => accountOverviewDetail(accountId)}
                        closeActivitiesCalls={closedActivitiesCall}
                        closeActivitiesMeetings={closedActivitiesMeetings}
                      />
                    </div>
                  </div>
                </div>
              ) : (
                <>
                  <div
                    className={`timeline_main_container rounded-2 bg-white d-flex flex-column gap-4 ${
                      isShrinked ? "w-100" : "w-75"
                    }`}
                    // className="timeline_main_container p-3 rounded-2 bg-white  d-flex flex-column gap-4 w-75 "
                  >
                    {/* <div className="upcomingAction_container w-100 flex-column h-25 py-2  d-flex  commonContainer_bg rounded-2">
                      <div className="header d-flex gap-2 p-2">
                        <span>
                          <img
                            src={hierachy}
                            alt="img"
                            height={20}
                            width={20}
                          />
                        </span>
                        <span>Upcoming Actions</span>
                      </div>
                      <div className="horizontal_line w-100  mx-1 border border-white"></div>

                      <div
                        className="d-flex justify-content-center align-items-center "
                        style={{ height: "20vh" }}
                      >
                        <h4 className="opacity-75 ">No upcoming actions</h4>
                      </div>
                    </div> */}

                    <div className="w-100 historyMain_Container flex-column   d-flex  commonContainer_bg rounded-2 ">
                      <div className="header d-flex gap-2 px-2 py-2 align-items-center">
                        <span>
                          <img src={history} alt="img" height={20} width={20} />
                        </span>
                        <span>History</span>
                      </div>
                      <div className="horizontal_line w-100  mx-1"></div>
                      <div className="historyContent_mainContainer ">
                        {timeLineDataList &&
                        Object.keys(timeLineDataList).length > 0 ? (
                          Object.keys(timeLineDataList).map((date) => (
                            <div
                              className="history_container pt-3 d-flex flex-column"
                              key={date}
                            >
                              <span className="px-2 timelineBg_color border border-primary rounded-5 py-2 mx-5">
                                {date}
                              </span>
                              {timeLineDataList[date].map((item, index) => (
                                <div className="d-flex gap-2" key={index}>
                                  <span className="opacity-75 pt-5 ps-2">
                                    {item.createdTime}
                                  </span>
                                  <div className="d-flex flex-column align-items-center">
                                    <p className="vertical_line m-0 p-0"></p>
                                    <span>
                                      <img
                                        src={groupIcon2}
                                        alt="img"
                                        height={25}
                                        width={25}
                                      />
                                    </span>
                                  </div>
                                  <span className="pt-5">
                                    {`${item.tmlDescription} ${item.tmlCreatedBy} ${date}`}
                                  </span>
                                </div>
                              ))}
                            </div>
                          ))
                        ) : (
                          <div
                            className="text-center d-flex justify-content-center align-items-center opacity-75"
                            style={{ height: "20vh" }}
                          >
                            <h4 className="h-75">No Data available</h4>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </>
      );
    }
  };
  return (
    <div
      onClick={() => {
        setListVisibleType("");
        setShowHeadings(false);
        setShowSelectedFilter("");
        setSelectedField("");
        setShowOptionsPurchase(false);
        setPopoutType("");
      }}
    >
      {handlePurchaseOrdersView()}

      {/* Modals */}
      <LeadOwnerModal
        isLeadOwnerModal={isLeadOwnerModal}
        setIsLeadOwnerModal={setIsLeadOwnerModal}
        onSelectedLeadChange={handleSelectedLeadChange}
        defaultUser={selectedOwner}
      />
      {/* <VendorListModal
        isVendorListModal={isVendorListModal}
        setIsVendorListModal={setIsVendorListModal}
        onSelectedVendorChange={handleSelectedVendorListChange}
        defaultUser={selectedVendor}
        vendorDetails={vendorList}
      /> */}

      <Modal
        show={isVendorListModal}
        className="d-flex justify-content-center align-items-center"
        aria-labelledby="example-custom-modal-styling-title"
        size="lg"
        id="commonLeadModal"
      >
        <Modal.Body className="ModalBody  overflow-auto">
          <div
            className="modalUS row justify-content-center"
            style={{ margin: "0", gap: "10px", paddingBottom: "10px" }}
          >
            <div className="header_Container d-flex justify-content-between">
              <h4 className="commonTextColor">Choose Vendor</h4>
              <div className="d-flex justify-content-start">
                <div className="inputContainer d-flex justify-content-start">
                  <input
                    type="text"
                    className="inputBox px-5 mb-1 w-75 py-1"
                    style={{ outline: "none" }}
                    placeholder="Search Users"
                    value={searchVendor}
                    onChange={handleSearchVendor}
                  />
                  <img
                    src={searchIcon}
                    alt="search"
                    className="searchIcon"
                    style={{ width: "20px" }}
                  />
                </div>
              </div>
            </div>

            <div className="Owner_table_container border border-dark rounded-2 h-25">
              <div className="table-wrapper">
                <table className="userdata-table">
                  <thead>
                    <tr>
                      <th>Vendor Name</th>
                      <th>Email</th>

                      <th>Phone</th>
                      <th>Website</th>
                      <th> Owner</th>
                    </tr>
                  </thead>
                  <tbody className="table-body tableUserData">
                    {filteredVendors?.map((item, index) => (
                      <tr
                        key={index}
                        onClick={() => {
                          setSelectedVendor(item);
                        }}
                      >
                        <td className="d-flex gap-2">
                          <img
                            src={logoConvert}
                            alt="img"
                            height={20}
                            width={20}
                          />

                          {item.vendorName}
                        </td>
                        <td>{item.emailId}</td>
                        <td>{item.phone}</td>
                        <td>{item.website}</td>
                        <td>{item.vendorId}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
            <div className="d-flex w-100 align-items-center justify-content-end gap-2">
              {filteredVendors.length > 0 && (
                <>
                  <Select
                    options={pageOption}
                    value={pageOption.find(
                      (option) => option.value === perPageVendor.toString()
                    )}
                    onChange={handlePageDropDownChangeVendor}
                    isSearchable={false}
                    styles={customCommonStyles("225px")}
                  />
                  <Pagination
                    currentPage={currentPageVendor}
                    totalPages={totalPagesVendor}
                    onPageChange={handlepageChangeVendor}
                  />
                </>
              )}
            </div>
            <div className="d-flex pb-2 pt-3 justify-content-between">
              <div className="selectedUser d-flex gap-1">
                <span>Selected User</span>
                <img src={logoConvert} alt="img" height={20} width={20} />
                <span>{selectedVendor.vendorName}</span>
              </div>
              <div className="d-flex gap-2">
                <button
                  className="btn btn-outline-secondary border border-primary btn-sm px-3 py-2 text-dark"
                  onClick={() => {
                    setIsVendorListModal(false);
                    setSelectedVendor({});
                  }}
                >
                  Cancel
                </button>
                <button
                  className="btn btnColor btn-sm px-3 text-white"
                  onClick={() => {
                    setIsVendorListModal(false);
                  }}
                >
                  Done
                </button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      {/* <ContactListModal
        isContactListModal={isContactListModal}
        setIsContactListModal={setIsContactListModal}
        onSelectedLeadChange={handleSelectedContactListChange}
        defaultUser={selectedContact}
        allContactDetails={contactList}
      /> */}

      <Modal
        show={isContactListModal}
        className="d-flex justify-content-center align-items-center"
        aria-labelledby="example-custom-modal-styling-title"
        size="lg"
        id="commonLeadModal"
      >
        <Modal.Body className="ModalBody  overflow-auto">
          <div
            className="modalUS row justify-content-center"
            style={{ margin: "0", gap: "10px", paddingBottom: "10px" }}
          >
            <div className="header_Container d-flex justify-content-between">
              <h4 className="commonTextColor pt-2">Change Contact</h4>
              <div className="inputContainer d-flex justify-content-start">
                <input
                  type="text"
                  placeholder="Search"
                  className="topBarModalSearch px-5"
                  onChange={handleSearchChangeContact}
                  value={searchContact}
                />
                <img src={searchIcon} alt="search" className="searchIcon" />
              </div>
            </div>

            <div className="Owner_table_container border border-dark rounded-2 h-25">
              <div className="table-wrapper">
                <table className="userdata-table">
                  <thead>
                    <tr>
                      <th style={{ width: "20%" }}>Contact Name</th>
                      <th style={{ width: "20%" }}>Account Name</th>
                      <th style={{ width: "20%" }}>Email</th>
                      <th style={{ width: "20%" }}>Phone</th>
                      <th style={{ width: "20%" }}>Contact Owner</th>
                    </tr>
                  </thead>
                  <tbody className="table-body tableUserData">
                    {filteredContactDetails?.map((item, index) => (
                      <tr
                        key={index}
                        onClick={() => {
                          setSelectedContact(item);
                        }}
                      >
                        <td style={{ width: "20%" }} className="overflow_text">
                          {" "}
                          {item.contactName}
                        </td>
                        <td style={{ width: "20%" }} className="overflow_text">
                          {item.accountName}
                        </td>
                        <td style={{ width: "20%" }} className="overflow_text">
                          {item.emailId}
                        </td>
                        <td style={{ width: "20%" }} className="overflow_text">
                          {item.phone}
                        </td>
                        <td style={{ width: "20%" }} className="overflow_text">
                          {item.contactOwner}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
            <div className="d-flex w-100 align-items-center justify-content-end gap-2">
              {filteredContactDetails.length > 0 && (
                <>
                  <Select
                    options={pageOption}
                    styles={customCommonStyles("100%")}
                    // value={selectPageOption}
                    value={pageOption.find(
                      (option) => option.value === perPageContact.toString()
                    )}
                    onChange={handlePageDropDownChangeContact}
                  />
                  {/* <p className="mb-0">· ‹1/10›</p> */}

                  <Pagination
                    currentPage={currentPageContact}
                    totalPages={totalPagesContact}
                    onPageChange={hadlePageChangeContact}
                  />
                </>
              )}
            </div>
            <div className="d-flex pb-2 pt-3 justify-content-between">
              <div className="selectedUser d-flex gap-1">
                <span>Selected User</span>
                <img src={logoConvert} alt="img" height={20} width={20} />
                <span>{selectedContact.contactName}</span>
              </div>
              <div className="d-flex gap-2">
                <button
                  className="btn btn-outline-secondary border border-primary btn-sm px-3 py-2 text-dark"
                  onClick={() => {
                    setIsContactListModal(false);
                    setSelectedContact({});
                  }}
                >
                  Cancel
                </button>
                <button
                  className="btn btnColor btn-sm px-3 text-white"
                  onClick={() => {
                    setIsContactListModal(false);
                  }}
                >
                  Done
                </button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        show={modalType === "Manage Columns"}
        id="ConvertModal"
        aria-labelledby="example-custom-modal-styling-title"
        className="withdrw_modal"
      >
        <Modal.Body>
          <div className="modalClass  position-relative">
            <div className="d-flex justify-content-between ">
              <h4 className="">Manage Columns</h4>
            </div>
            <div className="w-100 position-relative">
              <div className="mb-1 pt-2 position-relative w-100">
                <input
                  type="text"
                  className="assetSearchbar searchbar rounded-2 p-2 pe-5 common_input_bg w-100 "
                  placeholder="Search"
                  value={searchColumn}
                  onChange={(e) => {
                    if (e.target.value.charAt(0) !== " ") {
                      const value = e.target.value.replace(
                        /[^a-zA-Z ]+|(\s)\s+/g,
                        "$1"
                      );
                      setSearchColumn(value);
                      // const list = purchaseColumns.filter((lead) =>
                      //   lead.name
                      //     .toLowerCase()
                      //     .includes(value.trim().toLowerCase())
                      // );
                      // setSavingHeadings(list);
                    }
                  }}
                />
                <span
                  className="position-absolute  mt-2 me-2 end-0 opacity-75"
                  onClick={() => {
                    setSearchColumn("");
                  }}
                >
                  {searchColumn === "" ? (
                    <img src={searchIcon} alt="" width={20} />
                  ) : (
                    "X"
                  )}
                </span>
              </div>
              <div className=" opacity-75 columns_container">
                {savingHeadings
                  .filter((lead) =>
                    lead.name
                      .toLowerCase()
                      .includes(searchColumn.trim().toLowerCase())
                  )
                  .sort((a, b) => a.name.localeCompare(b.name))
                  .map((column) => {
                    return (
                      <div
                        key={column.name}
                        className="d-flex gap-2 py-2 rounded-2 align-items-center"
                      >
                        <div className="d-flex gap-2 ps-2">
                          <input
                            type="checkbox"
                            id={column.name}
                            name={column.name}
                            checked={column.isChecked}
                            onChange={(e) => {
                              handleColumnCheckboxChange(
                                column.name,
                                e.target.checked
                              );
                            }}
                            className="cursorPointer"
                          />
                          <label
                            className="small cursorPointer"
                            htmlFor={column.name}
                          >
                            {column.name}
                          </label>
                        </div>
                      </div>
                    );
                  })}
              </div>
              <aside className="float-end mt-2">
                <div className="d-flex align-items-center gap-4">
                  <button
                    className="cancel_btn bg-light"
                    onClick={() => {
                      setShowModalType("");
                      // setSelectedHeadings(purchaseColumns);
                    }}
                  >
                    Cancel
                  </button>
                  <button
                    className="save_btn"
                    onClick={() => {
                      setSelectedHeadings(savingHeadings);
                      setTimeout(() => {
                        setShowModalType("");
                      }, 200);
                    }}
                  >
                    Save
                  </button>
                </div>
              </aside>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      {/* Delete Modal */}
      <Modal
        show={[
          "delete",
          "Mass Delete",
          "Mass Update",
          "Mass Transfer",
        ].includes(modalType)}
        aria-labelledby="contained-modal-title-vcenter"
        onClick={(e) => e.stopPropagation()}
        // size={showModalType !== "delete" ? "md" : "lg"}
      >
        <Modal.Header
          className=" border-0 mb-0 d-flex flex-column align-items-start"
          style={{ backgroundColor: modalType !== "delete" && "#F2F2F2" }}
        >
          {modalType !== "delete" && (
            <div
              className={`mass_heading fs-4 ${
                modalType !== "Mass Delete" && "ps-4"
              }`}
            >
              {modalType}
            </div>
          )}
          {modalType === "delete" && (
            <div className="fs-5" style={{ color: "#3F7DFF" }}>
              Are you sure you want to delete {purchaseInfo?.subject}?
            </div>
          )}
        </Modal.Header>
        <Modal.Body className="ModalBody">
          {modalType === "delete" ? (
            <>
              <div className="d-flex gap-2 pb-2 align-items-center">
                <span className="fw-bold">Note:</span>
                <span className="small pt-1">
                  Any associated activities, visits, drafts will also be
                  deleted.
                </span>
              </div>
            </>
          ) : modalType === "Mass Delete" ? (
            <>
              <div className="fs-5 pt-0 pb-2">
                Are you sure you want to delete selected item(s)?
              </div>
              <div className="small">
                <span className="fw-bold">Note: </span>
                <span>
                  Any associated Activities, Drafts will also be Deleted
                </span>
              </div>
            </>
          ) : modalType === "Mass Update" ? (
            <div className="d-flex gap-2 mb-3 mt-2 align-items-center w-100">
              <div className="w-100">
                <div className="d-flex flex-column ps-1 align-items-center mx-4">
                  <label className="text-start w-100 opacity-75">
                    Select a field
                  </label>
                  <div
                    className="position-relative w-100 rounded-1"
                    style={{ border: "1px solid rgba(185, 185, 185, 0.5)" }}
                  >
                    <div
                      onClick={(e) => {
                        e.stopPropagation();
                        setListVisible(!listVisible);
                        setSearchField("");
                      }}
                      className={`cursorPointer d-flex justify-content-between w-100 my-1 p-1 border-0`}
                    >
                      <span className="">{selectedObj.name}</span>
                      <img
                        src={listVisible ? up : down}
                        alt="img"
                        height={20}
                        width={20}
                        className="pt-1"
                      />
                    </div>
                    {listVisible && (
                      <div
                        className="position-absolute field_container w-100"
                        onClick={(e) => {
                          e.stopPropagation();
                        }}
                      >
                        <div className="">
                          <label
                            htmlFor="selectField"
                            className="position-relative w-100"
                          >
                            <input
                              id="selectField"
                              type="text"
                              className="rounded-2 p-1 w-100 pe-4"
                              placeholder="Search"
                              value={searchField}
                              onChange={(e) => {
                                if (e.target.value.charAt(0) !== " ") {
                                  const value = e.target.value.replace(
                                    /[^a-zA-Z ]+|(\s)\s+/g,
                                    "$1"
                                  );
                                  setSearchField(value);
                                }
                              }}
                            />
                            <img
                              className="position-absolute mt-1 me-2 end-0 pt-1 cursorPointer"
                              onClick={() => {
                                setSearchField("");
                                // setShowSearchFields(fieldDropdowns);
                              }}
                              src={searchIcon}
                              alt="search"
                              width={20}
                            />
                          </label>

                          <div className="w-100 rounded-2">
                            <div className="border border-1 field_container_dropdown rounded-1">
                              {showSearchFields
                                .filter((field) =>
                                  field.name
                                    .toLowerCase()
                                    .includes(searchField.trim().toLowerCase())
                                )
                                .sort((a, b) => a.name.localeCompare(b.name))
                                .map(
                                  (field) =>
                                    ![
                                      "Purchase Order Owner",
                                      "Vendor Name",
                                      "Contact Name",
                                      "Created By",
                                      "Modified By",
                                      "Deal Name",
                                      "Email Opt Out",
                                      "Grand Total",
                                      "Discount",
                                      "Hash",
                                      "Tax",
                                      "Sub Total",
                                      "Adjustment",
                                    ].includes(field.name) && (
                                      <div
                                        key={field.name}
                                        className="d-flex gap-2 p-2 cursorPointer rounded-2 align-items-center"
                                        onClick={() => {
                                          setClickedDropdownObj({
                                            label: "",
                                            value: "",
                                          });
                                          setMassUpdateValue("");
                                          setSelectedObj(field);
                                          setListVisible(false);
                                        }}
                                      >
                                        <span className="ps-2">
                                          {field.name}
                                        </span>
                                      </div>
                                    )
                                )}
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="w-100 mx-auto d-flex justify-content-center mt-4">
                    <div className="w-100">
                      <label className="opacity-75" htmlFor="massValue">
                        Enter value
                      </label>
                      <div
                        className="position-relative w-100 border rounded-1"
                        style={{ border: "1px solid rgba(185, 185, 185, 0.5)" }}
                      >
                        {["Status", "Carrier"].includes(selectedObj.name) ? (
                          <Select
                            value={clickedDropdownObj}
                            menuPlacement="auto"
                            onChange={(selectedOption) => {
                              setClickedDropdownObj(selectedOption);
                              setMassUpdateValue(selectedOption.value);
                            }}
                            options={
                              selectedObj.name === "Carrier"
                                ? carrierOptions
                                : stausOptions
                            }
                            styles={fieldDropdownStyles}
                            isSearchable={false}
                          />
                        ) : (
                          <input
                            className="w-100 border-0 py-2 rounded-1 ps-1"
                            // type="text"
                            value={massUpdateValue}
                            type={`${
                              ["Due Date", "PO Date"].includes(selectedObj.name)
                                ? "date"
                                : "text"
                            }`}
                            onChange={(e) => {
                              // const trimmedValue = e.target.value?.replace(
                              //   /\s+/g,
                              //   " "
                              // );
                              const trimmedValue = e.target.value
                                ?.replace(/\s{2,}/g, " ")
                                .trimStart();
                              if (
                                [
                                  "Billing Code",
                                  "Excise Duty",
                                  "Sales Commision",
                                  "Shipping Code",
                                  "Discount",
                                  "Requisition Number",
                                  "Tax",
                                  // "Tracking Number"
                                ].includes(selectedObj.name)
                              ) {
                                setMassUpdateValue(
                                  trimmedValue.replace(/[^0-9.]+/g, "")
                                );
                              } else if (
                                [
                                  "Billing Street",
                                  "Subject",
                                  "Shipping Street",
                                  "Tracking Number",
                                ].includes(selectedObj.name)
                              ) {
                                setMassUpdateValue(trimmedValue);
                              } else {
                                setMassUpdateValue(
                                  !["PO Date", "Due Date"].includes(
                                    selectedObj.name
                                  )
                                    ? trimmedValue.replace(/[^a-zA-Z]+/g, "")
                                    : e.target.value
                                );
                              }
                            }}
                            style={{ outline: "none" }}
                            id="massValue"
                            min={minDate}
                            max={maxDate}
                          />
                        )}
                      </div>
                      {errorMessage && (
                        <span className="text-danger text-center pt-1">
                          {errorMessage}
                        </span>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="w-100 px-4 py-2 mb-1">
              <label className="text-start w-100 opacity-75">Transfer To</label>
              <div
                className="d-flex input-with-clear position-relative mx-auto"
                onClick={(e) => {
                  handleOwnerInputClick(e);
                }}
              >
                <input
                  type="text"
                  className="ps-3 w-100 cursorPointer py-2"
                  value={selectedOwner?.name}
                  readOnly
                />
                <span
                  className="position-absolute h-100 mass_transfer_user pt-1"
                  style={{ right: "0.1rem" }}
                >
                  <span
                    className="cursorPointer px-1"
                    onClick={() => setIsLeadOwnerModal(!isLeadOwnerModal)}
                  >
                    <img
                      src={userSearch}
                      alt="img"
                      width={20}
                      height={20}
                      className="ms-2 mt-2 me-2"
                    />
                  </span>
                </span>
              </div>
            </div>
          )}

          <div className={`pt-4  ${modalType !== "delete" && "pe-3"}`}>
            <div className="d-flex justify-content-end w-100 gap-4 pe-2">
              <button
                className="cancelButton px-4"
                onClick={() => {
                  setShowModalType("");
                  // setSelectedCheckboxes([]);
                  setShowSearchFields(fieldDropdowns);
                }}
              >
                Cancel
              </button>
              <button
                className="createContactButtonSave"
                onClick={() => {
                  if (modalType === "delete") {
                    deletePurchase();
                  } else {
                    //  else if (showModalType === "Mass Delete") {
                    //   deleteUpdateTransferMass();
                    // } else {
                    deleteUpdateTransferMass();
                  }
                }}
                // disabled={
                //   massUpdateValue.length < 2 ||
                //  / !["Mass Update"].includes(modalType)
                // }
              >
                {modalType === "Mass Update"
                  ? "Update"
                  : modalType === "Mass Transfer"
                  ? "Done"
                  : "Delete"}
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <ShareModal
        moduleType="PURCHASE ORDERS"
        setShareModal={setShareModal}
        shareModal={shareModal}
        id={purchaseOrdId}
        modalHeading={"Sharing Purchase Order"}
        shareInfoModal={shareInfoModal}
        setShareInfoModal={setShareInfoModal}
        shareUserList={shareUserList}
        shareGroupList={shareGroupList}
        shareRoleList={shareRoleList}
        sharePublicList={sharePublicList}
        getOverview={purchaseOrderOverview}
        shareMemberIds={shareMemberIds}
        module={"Purchase Order"}
      />
      <AccessModal
        setShowAccessModal={setShowAccessModal}
        showAccessModal={showAccessModal}
      />
      <ChangeOwnerModal
        modalType={modalType}
        setShowModalType={setShowModalType}
        selectedOwnerItem={selectedOwner}
        handleChangeOwnerInputClick={handleChangeOwnerInputClick}
        setSelectedOwnerItem={setSelectedOwner}
        isLeadOwnerModal={isLeadOwnerModal}
        setIsLeadOwnerModal={setIsLeadOwnerModal}
        isChangeOwnerList={isChangeOwnerList}
        userList={userList}
        handleChangeOwnerItemClick={handleChangeOwnerItemClick}
        emailNotification={emailNotification}
        setEmailNotification={setEmailNotification}
        handleEditClick={handleEditClick}
        handleUpdateClick={handleUpdateClick}
        setUpdatedFields={setPurchaseInfo}
        existingContact={editPurchaseOverview}
        handleUpdateContact={handleSaveButton}
        onSelectedChangeOwner={handleSelectedChangeOwner}
        apiLoader={apiLoader} // for lottie loader
      />
      <NotificationContainer />
    </div>
  );
}

export default Purchase;

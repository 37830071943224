export const componentData = {
  org_overview: [
    {
      label: "Leads THIS MONTH",
      value: "leads_this_month",
      view: "Single value",
    },
    {
      label: "Deals IN PIPELINE",
      value: "deals_in_pipeline",
      view: "Single value",
    },
    {
      label: "Accounts THIS MONTH",
      value: "account_this_month",
      view: "Single value",
    },
    {
      label: "PROLIFIC SALES REPS",
      value: "prolific_sales_reps",
      view: "Table",
    },
    {
      label: "Leads BY SOURCE",
      value: "leads_by_source",
      view: "Doughnut chart",
    },
  ],
  deal_insights: [
    {
      label: "REVENUE THIS MONTH",
      value: "revenue_this_month",
      view: "Single value",
    },
    {
      label: "Deals CREATED",
      value: "deals_created",
      view: "Single value",
    },
    {
      label: "Deals IN PIPELINE",
      value: "deals_in_pipeline",
      view: "Single value",
    },
    {
      label: "REVENUE LOST",
      value: "revenue_lost",
      view: "Single value",
    },
    {
      label: "REVENUE BY USERS",
      value: "revenue_by_users",
      view: "Table",
    },
    {
      label: "Deals BY STAGES",
      value: "deals_by_stages",
      view: "Funnel chart",
    },
    {
      label: "OPEN AMOUNT BY USERS",
      value: "open_amount_by_users",
      view: "Table",
    },
    {
      label: "AMOUNT BY STAGE",
      value: "amount_by_stage",
      view: "Column chart",
      //   view: "Bar chart",
    },
    {
      label: "AMOUNT BY Lead SOURCE",
      value: "amount_by_lead_source",
      view: "Column chart",
      // view: "Table",
    },
  ],
  lead_analytics: [
    {
      label: "TODAY'S Leads",
      value: "today's_leads",
      //   value: "today's_leads",
      view: "Single value",
    },
    {
      label: "TOP 10 Lead Sources",
      value: "top_10_lead_sources",
      view: "Table",
    },
    {
      label: "THIS WEEK'S Leads",
      value: "this_week_leads",
      view: "Single value",
    },
    {
      label: "TOP Lead OWNERS",
      value: "top_lead_owners",
      view: "Table",
    },
    {
      label: "Leads BY INDUSTRY",
      value: "leads_by_industry",
      view: "Doughnut chart",
    },
    {
      label: "MONTHLY Lead CREATION",
      value: "monthly_lead_creation",
      view: "Column chart",
    },
  ],
  marketing_metrics: [
    {
      label: "TOTAL Campaigns",
      value: "total_campaigns",
      view: "Single value",
    },
    {
      label: "ACTUAL COST",
      value: "actual_cost",
      view: "Single value",
    },
    {
      label: "BUDGETED COST",
      value: "budgeted_cost",
      view: "Single value",
    },
    {
      label: "EXPECTED REVENUE",
      value: "expected_revenue",
      view: "Single value",
    },
    {
      label: "Campaigns BY TYPE",
      value: "campaigns_by_type",
      view: "Column chart",
    },
    {
      label: "Campaigns BY Leads",
      value: "campaigns_by_leads",
      view: "Column chart",
    },
  ],
  leads: [
    {
      label: "All Leads",
      value: "all_leads",
    },
    {
      label: "My Leads",
      value: "my_leads",
    },
    {
      label: "Recently Created Leads",
      value: "recently_created_leads",
    },
    {
      label: "Recently Modified Leads",
      value: "recently_modified_leads",
    },
    {
      label: "Converted Leads",
      value: "converted_leads",
    },
    {
      label: "My Converted Leads",
      value: "my_converted_leads",
    },
    {
      label: "Today's Leads",
      value: "today's_leads",
    },
    {
      label: "Junk Leads",
      value: "junk_leads",
    },
    {
      label: "Open Leads",
      value: "open_leads",
    },
    {
      // label: "Mailing Leads",
      label: "Mailing Labels",
      value: "mailing_labels",
      // value: "mailing_leads",
    },
    {
      label: "Not Qualified Leads",
      value: "not_qualified_leads",
    },
    {
      label: "Leads Shared By Me",
      value: "leads_shared_by_me",
    },
    {
      label: "Leads Shared To Me",
      value: "leads_shared_to_me",
    },
    // {
    //   label: "Leads THIS MONTH",
    //   value: "leads_this_month",
    // },
  ],
  contacts: [
    {
      label: "All Contacts",
      value: "all_contacts",
    },
    {
      label: "My Contacts",
      value: "my_contacts",
    },
    {
      label: "New Last Week",
      value: "new_last_week",
    },
    {
      label: "New This Week",
      value: "new_this_week",
    },
    {
      label: "Recently Created Contacts",
      value: "recently_created_contacts",
    },
    {
      label: "Recently Modified Contacts",
      value: "recently_modified_contacts",
    },
    {
      label: "Mailing Labels",
      value: "mailing_labels",
    },
    {
      label: "Contacts Shared By Me",
      value: "contacts_shared_by_me",
    },
    {
      label: "Contacts Shared To Me",
      value: "contacts_shared_to_me",
    },
  ],
  accounts: [
    {
      label: "All Accounts",
      value: "all_accounts",
    },
    {
      label: "My Accounts",
      value: "my_accounts",
    },
    {
      label: "New Last Week",
      value: "new_last_week",
    },
    {
      label: "New This Week",
      value: "new_this_week",
    },
    {
      label: "Recently Created Accounts",
      value: "recently_created_accounts",
    },
    {
      label: "Recently Modified Accounts",
      value: "recently_modified_accounts",
    },
    {
      label: "Accounts Shared By Me",
      value: "accounts_shared_by_me",
    },
    {
      label: "Accounts Shared To Me",
      value: "accounts_shared_to_me",
    },
  ],
  quotes: [
    {
      label: "All Quotes",
      value: "all_quotes",
    },
    {
      label: "My Quotes",
      value: "my_quotes",
    },
    {
      label: "Quotes Shared By Me",
      value: "quotes_shared_by_me",
    },
    {
      label: "Quotes Shared To Me",
      value: "quotes_shared_to_me",
    },
  ],
  deals: [
    {
      label: "All Deals",
      value: "all_deals",
    },
    {
      label: "My Deals",
      value: "my_deals",
    },
    {
      label: "My Deals Closing This Month",
      value: "my_deals_closing_this_month",
    },
    {
      label: "Closing Next Month",
      value: "closing_next_month",
    },
    {
      label: "Closing This Month",
      value: "closing_this_month",
    },
    {
      label: "New Last Week",
      value: "new_last_week",
    },
    {
      label: "New This Week",
      value: "new_this_week",
    },
    {
      label: "Recently Created Deals",
      value: "recently_created_deals",
    },
    {
      label: "Recently Modified Deals",
      value: "recently_modified_deals",
    },
    {
      label: "Deals Shared By Me",
      value: "deals_shared_by_me",
    },
    {
      label: "Deals Shared To Me",
      value: "deals_shared_to_me",
    },
  ],
  vendors: [
    {
      label: "All Vendors",
      value: "all_vendors",
    },
    {
      label: "My Vendors",
      value: "my_vendors",
    },
    {
      label: "Vendors Shared By Me",
      value: "vendors_shared_by_me",
    },
    {
      label: "Vendors Shared To Me",
      value: "vendors_shared_to_me",
    },
  ],
  invoices: [
    {
      label: "All Invoices",
      value: "all_invoices",
    },
    {
      label: "My Invoices",
      value: "my_invoices",
    },
    {
      label: "Invoices Shared By Me",
      value: "invoices_shared_by_me",
    },
    {
      label: "Invoices Shared To Me",
      value: "invoices_shared_to_me",
    },
  ],
  purchases: [
    {
      label: "All Purchase Orders",
      value: "all_purchase_orders",
    },
    {
      label: "My Purchase Orders",
      value: "my_purchase_orders",
    },
    {
      label: "Overdue Purchase Orders",
      value: "overdue_purchase_orders",
    },
    {
      label: "Purchase Orders Shared By Me",
      value: "purchase_orders_shared_by_me",
    },
    {
      label: "Purchase Orders Shared To Me",
      value: "purchase_orders_shared_to_me",
    },
  ],
  sales: [
    {
      label: "All Sales Orders",
      value: "all_sales_orders",
    },
    {
      label: "My Sales Orders",
      value: "my_sales_orders",
    },
    {
      label: "Sales Orders Shared By Me",
      value: "sales_orders_shared_by_me",
    },
    {
      label: "Sales Orders Shared To Me",
      value: "sales_orders_shared_to_me",
    },
  ],
  products: [
    {
      label: "All Products",
      value: "all_products",
    },
    {
      label: "My Products",
      value: "my_products",
    },
    {
      label: "Active Products",
      value: "active_products",
    },
    {
      label: "Products Shared By Me",
      value: "products_shared_by_me",
    },
    {
      label: "Products Shared To Me",
      value: "products_shared_to_me",
    },
  ],
  meetings: [
    {
      label: "All Meetings",
      value: "all_meetings",
    },
    {
      label: "My Meetings",
      value: "my_meetings",
    },
    {
      label: "My Today's Meetings",
      value: "my_today's_meetings",
    },
    {
      label: "My Upcoming Meetings",
      value: "my_upcoming_meetings",
    },
    {
      label: "Today's Meetings",
      value: "today's_meetings",
    },
    {
      label: "This Weeks Meetings",
      value: "this_weeks_meetings",
    },
    {
      label: "Upcoming Meetings",
      value: "upcoming_meetings",
    },
    {
      label: "Completed Meetings",
      value: "completed_meetings",
    },
    {
      label: "My Completed Meetings",
      value: "my_completed_meetings",
    },
    // {
    //   label: "Meetings Shared By Me",
    //   value: "meetings_shared_by_me",
    // },
    // {
    //   label: "Meetings Shared To Me",
    //   value: "meetings_shared_to_me",
    // },
  ],
  tasks: [
    {
      label: "All Tasks",
      value: "all_tasks",
    },
    {
      label: "Closed Tasks",
      value: "closed_tasks",
    },
    {
      label: "My Closed Tasks",
      value: "my_closed_tasks",
    },
    {
      label: "My Tasks",
      value: "my_tasks",
    },
    {
      label: "My Next 7 Days + Overdue Tasks",
      value: "my_next_7_days_+_overdue_tasks",
    },
    {
      label: "My Open Tasks",
      value: "my_open_tasks",
    },
    {
      label: "My Overdue Tasks",
      value: "my_overdue_tasks",
    },
    {
      label: "My Today + Overdue Tasks",
      value: "my_today_+_overdue_tasks",
    },
    {
      label: "My Today's Tasks",
      value: "my_today's_tasks",
    },
    {
      label: "My Tomorrow's Tasks",
      value: "my_tomorrow's_tasks",
    },
    {
      label: "Next 7 days + Overdue Tasks",
      value: "next_7_days_+_overdue_tasks",
    },
    {
      label: "Open Tasks",
      value: "open_tasks",
    },
    {
      label: "Overdue Tasks",
      value: "overdue_tasks",
    },
    {
      label: "Today + Overdue Tasks",
      value: "today_+_overdue_tasks",
    },
    {
      label: "Tomorrow's Tasks",
      value: "tomorrow's_tasks",
    },
    // {
    //   label: "Tasks Shared By Me",
    //   value: "tasks_shared_by_me",
    // },
    // {
    //   label: "Tasks Shared To Me",
    //   value: "tasks_shared_to_me",
    // },
  ],
  calls: [
    {
      label: "All Calls",
      value: "all_calls",
    },
    {
      label: "Completed Calls",
      value: "completed_calls",
    },
    {
      label: "My Scheduled Calls",
      value: "my_scheduled_calls",
    },
    {
      label: "My Today's Scheduled Calls",
      value: "my_today's_scheduled_calls",
    },
    {
      label: "Today's Scheduled Calls",
      value: "today's_scheduled_calls",
    },
    // {
    //   label: "Calls Shared by Me",
    //   value: "calls_shared_by_me",
    // },
    // {
    //   label: "Calls Shared to Me",
    //   value: "calls_shared_to_me",
    // },
  ],
};

export const getChartColor = (index) => {
  const colors = [
    "#68E169",
    "#67C2C6",
    "#728DCE",
    "#8C71D0",
    "#AB6BCE",
    "#E367AC",
    "#FF6867",
    "#FFAE65",
    "#FFCD66",
    // "#FFE767",
    // "#FFFF68",
    // "#C5F566",
    "#00CE02",
    "#00999A",
    "#1140AB",
    "#3914B2",
    "#720AAC",
    "#CF0175",
    "#FF0000",
    "#FF7400",
    "#FFAB00",
  ];
  return colors[index % colors.length]; // Cycle through colors if there are more data points than colors
};

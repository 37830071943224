import React, { useEffect, useState } from "react";
import Opened from "../../Assets/Open Activity.svg";
import { Modal } from "react-bootstrap";
import Select from "react-select";
import { useSelector } from "react-redux";
import moment from "moment";
import { URI } from "../../constants";
import axios from "axios";
import cancelCircle from "../../Assets/cancelCircle.svg";
import down from "../../Assets/Down31.svg";
import up from "../../Assets/Up.svg";
import userSearch from "../../Assets/user-search-01.svg";
import "./openActivity.css";
import { NotificationManager } from "react-notifications";
import LeadOwnerModal from "../LeadOwnerModal";
import Button from "react-bootstrap/Button";
import {
  convertDateFormat,
  validateCallAgenda,
  validateCallSubject,
  validateJobTitle,
  customCommonStyles,
} from "../CommonComponent";
import addIcon from "../../Assets/add-01.svg";
import editIcon1 from "../../Assets/editIconMeet.svg";
import Switch from "react-switch";
import Lottie from "react-lottie";
import { defaultOptions } from "../../utils/defaultOptions";
import getHeaders from "../../common/ApiHeader.js";
import logoConvert from "../../Assets/logoConvert.svg";
import { handleApiError } from "../../utils/ApiUtils";
import { useNavigate } from "react-router-dom";
import { pageOption } from "../../common/CommonData";
import SearchIcon from "../../Assets/SearchIcon.svg";
import Pagination from "../../common/Pagination";

function OpenActivity({
  // openActivities,
  getOverview,
  openActivitiesCalls,
  openActivitiesMeetings,
  openActivitiesTasks,
  moduleName,
  selectedData,
}) {
  const navigate = useNavigate();
  const createAccountStyles = {
    menu: (base) => ({
      ...base,
      width: "95%",
    }),
    option: (provided) => ({
      ...provided,
      color: "#000",
      width: "100%",
    }),
    control: (baseStyles) => ({
      ...baseStyles,
      border: "1px solid #b9b9b9b0",
      //   backgroundColor: "#F3F6FB",
      height: "50px",
      borderRadius: "5px",
      width: "95%",
      boxShadow: "none",
    }),
    input: (base) => ({
      ...base,
      color: "#515151",
    }),
    singleValue: (defaultStyles) => ({ ...defaultStyles, color: "#000000" }),

    menuList: (provided) => ({
      ...provided,
      paddingTop: 0,
      paddingBottom: 0,
      "&::-webkit-scrollbar": {
        width: 5,
      },

      "&::-webkit-scrollbar-thumb": {
        background: "#316AFF",
        borderRadius: "10px",
      },
    }),
  };

  const customSelectLocationStyles = {
    menu: (base) => ({
      ...base,
      width: "100%",
    }),
    option: (provided) => ({
      ...provided,
      color: "#000",
      backgroundColor: "#fff",
      width: "100%",
    }),
    control: (baseStyles) => ({
      ...baseStyles,
      border: "2px solid #316AFF",
      backgroundColor: "#fff",
      height: "40px",
      borderRadius: "10px",
      width: "100%",
      boxShadow: "none",
    }),
    input: (base) => ({
      ...base,
      color: "#000",
    }),
    singleValue: (defaultStyles) => ({ ...defaultStyles, color: "#000" }),

    menuList: (provided) => ({
      ...provided,
      paddingTop: 0,
      paddingBottom: 0,
      "&::-webkit-scrollbar": {
        width: 5,
      },

      "&::-webkit-scrollbar-thumb": {
        background: "#316AFF",
        borderRadius: "10px",
      },
    }),
    dropdownIndicator: (base) => ({
      ...base,
      color: "#316AFF",
    }),
  };

  const leadContactOptions = [
    { value: "Lead", label: "Lead" },
    { value: "Contact", label: "Contact" },
  ];
  const relatedToOptions = [
    { value: "ACCOUNTS", label: "Account" },
    { value: "DEALS", label: "Deal" },
    { value: "PRODUCTS", label: "Product" },
    { value: "QUOTES", label: "Quote" },
    { value: "SALES_ORDERS", label: "Sales Order" },
    { value: "PURCHASE_ORDERS", label: "Purchase Order" },
    { value: "INVOICES", label: "Invoice" },
    { value: "VENDORS", label: "Vendor" },
    { value: "CAMPAIGNS", label: "Campaign" },
  ];

  const callPurposeOptions = [
    { value: "None", label: "None" },
    { value: "Prospecting", label: "Prospecting" },
    { value: "Administrative", label: "Administrative" },
    { value: "Negotiation", label: "Negotiation" },
    { value: "Demo", label: "Demo" },
    { value: "Project", label: "Project" },
    { value: "Support", label: "Support" },
  ];
  const reminderOptions = [
    { value: "None", label: "None" },
    { value: "5 minutes before", label: "5 minutes before" },
    { value: "10 minutes before", label: "10 minutes before" },
    { value: "15 minutes before", label: "15 minutes before" },
    { value: "30 minutes before", label: "30 minutes before" },
    { value: "1 hour before", label: "1 hour before" },
  ];

  const callResultOptions = [
    { value: "None", label: "None" },
    { value: "Interested", label: "Interested" },
    { value: "Not interested", label: "Not interested" },
    { value: "No response/Busy", label: "No response/Busy" },
    { value: "Requested more info", label: "Requested more info" },
    { value: "Requested call back", label: "Requested call back" },
    { value: "Invalid number", label: "Invalid number" },
  ];
  const callTypeOptions = [
    { value: "Outbound", label: "Outbound" },
    { value: "Inbound", label: "Inbound" },
    { value: "Missed", label: "Missed" },
  ];

  const leadContactOptionsMeetings = [
    { label: "None", value: "None" },
    { label: "Lead", value: "Lead" },
    { label: "Contact", value: "Contact" },
    // { label: "Others", value: "Others" },
  ];

  const AddParticipantsOptions = [
    { label: "Users", value: "Users" },
    { label: "Leads", value: "Leads" },
    { label: "Contacts", value: "Contacts" },
  ];

  const repeatEveryOptions = [
    { label: "None", value: "None" },
    { label: "Daily", value: "Daily" },
    { label: "Weekly", value: "Weekly" },
    { label: "Monthly", value: "Monthly" },
    { label: "Yearly", value: "Yearly" },
    { label: "Custom", value: "Custom" },
  ];

  const FrequencyOptions = [
    { label: "Daily", value: "Daily" },
    { label: "Weekly", value: "Weekly" },
    { label: "Monthly", value: "Monthly" },
    { label: "Yearly", value: "Yearly" },
  ];

  const statusOptions = [
    { value: "Not Started", label: "Not Started" },
    { value: "Deferred", label: "Deferred" },
    { value: "In Progress", label: "In Progress" },
    { value: "Completed", label: "Completed" },
    { value: "Waiting for input", label: "Waiting for input" },
  ];
  const priorityOptions = [
    { value: "High", label: "High" },
    { value: "Highest", label: "Highest" },
    { value: "Low", label: "Low" },
    { value: "Lowest", label: "Lowest" },
    { value: "Normal", label: "Normal" },
  ];
  const notifyOptions = [
    { value: "Email", label: "Email" },
    { value: "Pop Up", label: "Pop Up" },
    { value: "Both", label: "Both" },
  ];
  const repeatOptions = [
    { value: "Daily", label: "Daily" },
    { value: "Weekly", label: "Weekly" },
    { value: "Monthly", label: "Monthly" },
    { value: "Yearly", label: "Yearly" },
  ];

  const reduxStore = useSelector((state) => state.reduxState);
  const { loginUserId } = reduxStore;
  const [selectedOptionFrequency, setSelectedOptionFrequency] = useState(
    FrequencyOptions[0]
  );
  const [showDateInput, setShowDateInput] = useState(false);
  const [addParticipant, setAddParticipant] = useState(
    AddParticipantsOptions[0]
  );
  const [isLeadOwnerModal, setIsLeadOwnerModal] = useState(false);
  const [isLeadListModal, setIsLeadListModal] = useState(false);
  const [isContactListModal, setIsContactListModal] = useState(false);
  const [isDropdownCallsOpen, setIsDropdownCallsOpen] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [moduleWiseData, setModuleWiseData] = useState([]);
  const [selectedModuleItem, setSelectedModuleItem] = useState(moduleWiseData);
  const [mandatoryMsg, setMandatoryMsg] = useState("");
  const [modalView, setModalView] = useState("ScheduleCall");
  const [selectedOptionLead, setSelectedOptionLead] = useState(
    moduleName === "lead" ? leadContactOptions[0] : leadContactOptions[1]
  );
  const userList = JSON.parse(sessionStorage.getItem("userList"));
  const [leadList, setLeadList] = useState([]);
  const [selectedLeadItem, setSelectedLeadItem] = useState(
    moduleName === "lead" ? selectedData : {}
  );
  const [selectedContact, setSelectedContact] = useState(
    moduleName === "contact" ? selectedData : {}
  );
  const [selectedOwner, setSelectedOwner] = useState(null);
  const [selectedOptionModule, setSelectedOptionModule] = useState(
    relatedToOptions[0]
  );
  const [listVisibleType, setListVisibleType] = useState("");
  const [listVisible, setListVisible] = useState(false);
  const [callStartTime, setCallStartTime] = useState({
    hour: "00",
    minutes: "00",
    meridiem: "AM",
  });
  const [moduleListVisible, setModuleListVisible] = useState(false);
  const [showCallStartTime, setShowCallStartTime] = useState({
    hour: false,
    minutes: false,
  });
  const [contactList, setContactList] = useState([]);
  const [modalType, setShowModalType] = useState("");
  const [overlapMessage, setOverlapMessage] = useState("");
  const [overlapModal, setOverlapModal] = useState(false);
  const [isAllDay, setIsAllDay] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);
  const [selectedOptionRepeat, setSelectedOptionRepeat] = useState(
    repeatEveryOptions[0]
  );
  const [checked, setChecked] = useState(false);
  const [modalSubType, setShowModalSubType] = useState("");
  const [selectedOptionRadio, setSelectedOptionRadio] = useState("never");
  const [emailList, setEmailList] = useState([]);
  const [selectedDate, setSelectedDate] = useState(null);
  const [searchInput, setSearchInput] = useState("");
  const [filteredItems, setFilteredItems] = useState([]);
  const [emailInput, setEmailInput] = useState("");
  const [addButton, setAddButton] = useState(false);
  const [repeatChecked, setRepeatChecked] = useState(false);
  const [mandatoryMsgDueDate, setMandatoryMsgDueDate] = useState("");
  const [endOn, setEndOn] = useState("Never");
  // const [mandatoryCallMsg, setMandatoryCallMsg] = useState("");  mandatoryCallMsg is not used anywhere so i commented it
  const [checkMeetingCalls, setCheckMeetingCalls] = useState("");
  const [show, setShow] = useState(false);
  const [showRepeat, setShowRepeat] = useState(false);
  const [repeatMsg, setRepeatMsg] = useState("");
  // const [showSelectTimeMsg, setShowSelectTimeMsg] = useState(""); setShowSelectTimeMsg is not used anywhere so i commented it
  // const [showSelectDateMsg, setShowSelectDateMsg] = useState(""); setShowSelectDateMsg is not used anywhere so i commented it
  const [openActivityTabView, setOpenActivityTabView] = useState("Tasks");
  const [dropdownSelect, setDropdownSelect] = useState(false);
  const [dropdownSelectReminder, setDropdownSelectReminder] = useState(false);
  const [dropdownCallPurpose, setDropdownCallPurpose] = useState(false);
  const [dropdownRelatedTo, setDropdownRelatedTo] = useState(false);
  const [dropdownCallResult, setDropdownCallResult] = useState(false);
  const [modalLeadDetails, setModalLeadDetails] = useState([]);
  const [searchLead, setSearchLead] = useState("");
  const [currentPageLead, setCurrentPageLead] = useState(1);
  const [perPageLead, setPerPageLead] = useState(10);
  const [totalItemsLead, setTotalItemsLead] = useState(0);
  const [currentPageContact, setCurrentPageContact] = useState(1);
  const [perPageContact, setPerPageContact] = useState(10);
  const [totalItemsContact, setTotalItemsContact] = useState(0);
  const [modalContactDetails, setModalContactDetails] = useState([]);
  const [searchContact, setSearchContact] = useState("");
  const [dropdownSelectPriority, setDropdownSelectPriority] = useState(false);
  const [dropdownSelectStatus, setDropdownSelectStatus] = useState(false);

  const handleSearchChangeContact = (e) => {
    const { value } = e.target;
    if (value.trim() !== "" || value === "") {
      setSearchContact(value);
    }
  };

  const totalPagesContact = Math.ceil(
    Number(totalItemsContact) / perPageContact
  );

  const totalPagesLead = Math.ceil(Number(totalItemsLead) / perPageLead);

  const filteredLeadDetails = modalLeadDetails.filter((item) =>
    item.leadName?.toLowerCase().includes(searchLead?.toLowerCase())
  );

  const filteredContactDetails = modalContactDetails.filter((item) =>
    item.contactName?.toLowerCase().includes(searchContact?.toLowerCase())
  );

  const hadlePageChangeContact = (page) => {
    setCurrentPageContact(page);
  };

  const handlePageDropDownChangeContact = (selectedOption) => {
    setCurrentPageContact(1);
    setPerPageContact(parseInt(selectedOption.value));
  };

  const hadlePageChangeLead = (page) => {
    setCurrentPageLead(page);
  };
  const handlePageDropDownChangeLead = (selectedOption) => {
    setCurrentPageLead(1);
    setPerPageLead(parseInt(selectedOption.value));
  };

  const handleSearchChangeLead = (e) => {
    const { value } = e.target;
    if (value.trim() !== "" || value === "") {
      setSearchLead(value);
    }
  };

  const [fromTimeSet, setFromTimeSet] = useState({
    hour: "00",
    minutes: "00",
    meridiem: "AM",
  });
  const [showFromTime, setShowFromTime] = useState({
    hour: false,
    minutes: false,
  });
  const [toTimeSet, setToTimeSet] = useState({
    hour: "00",
    minutes: "00",
    meridiem: "AM",
  });
  const [showToTime, setShowToTime] = useState({
    hour: false,
    minutes: false,
  });

  function generateTimeArray() {
    const times = [];
    const period = ["AM", "PM"];

    for (let i = 0; i < 2; i++) {
      // 0 for AM, 1 for PM
      for (let hour = 0; hour < 12; hour++) {
        for (let minute = 0; minute < 60; minute += 30) {
          const formattedHour = (hour === 0 ? 12 : hour)
            .toString()
            .padStart(2, "0");
          const formattedMinute = minute.toString().padStart(2, "0");
          const time = `${formattedHour}:${formattedMinute} ${period[i]}`;
          times.push({ label: time, value: time });
        }
      }
    }

    return times;
  }

  const timeArray = generateTimeArray();

  const [taskInfo, setTaskInfo] = useState({
    subject: "",
    dueDate: new Date().toISOString().split("T")[0],
    status: statusOptions[0],
    priority: priorityOptions[0],
    description: "",
    taskOwner: "",
    modifiedBy: "",
    remindStatus: "",
    relatedTo: "",
    closedTime: "",
    modifiedTime: "",
    contactName: "",
    remindTime: timeArray[0].value,
    remindDate: new Date().toISOString().split("T")[0],
    repeatEndTimes: "",
    repeatEndDate: new Date().toISOString().split("T")[0],
    repeatType: repeatOptions[0],
    notify: notifyOptions[0],
  });

  const resetData = () => {
    const formData = {
      subject: "",
      dueDate: new Date().toISOString().split("T")[0],
      status: statusOptions[0],
      priority: priorityOptions[0],
      description: "",
      modSearchId: "",
      modSearchName: "",
      remindStatus: "",
      remindTime: timeArray[0].value,
      notify: notifyOptions[0],
      repeatStatus: "",
      repeatType: repeatOptions[0],
      repeatEnd: "",
      repeatEndDate: new Date().toISOString().split("T")[0],
      remindDate: new Date().toISOString().split("T")[0],
    };
    setTaskInfo(formData);
  };

  const [callInfo, setCallInfo] = useState({
    loggedInUserId: loginUserId,
    choosingType: "",
    leadId: "",
    callType: callTypeOptions[0],
    outGoingCallStatus: "",
    callStartDate: new Date().toISOString().split("T")[0],
    callStartTime: "",
    callOwnerId: "",
    subject: "",
    reminder: reminderOptions[0],
    callPurpose: callPurposeOptions[0],
    callAgenda: "",
    callDuration: "0:0",
    callResult: callResultOptions[0],
    description: "",
    modSearchName: relatedToOptions[0],
    modSearchId: "",
    minutes: 0,
    seconds: 0,
  });

  const [apiLoader, setApiLoader] = useState(false);
  const [createLoader, setCreateLoader] = useState(false);

  const callInformation = {
    loggedInUserId: loginUserId,
    choosingType: "",
    leadId: "",
    callType: callTypeOptions[0],
    outGoingCallStatus: "",
    callStartDate: new Date().toISOString().split("T")[0],
    callStartTime: "",
    callOwnerId: "",
    subject: "",
    reminder: reminderOptions[0],
    callPurpose: callPurposeOptions[0],
    callAgenda: "",
    callDuration: "0:0",
    callResult: callResultOptions[0],
    description: "",
    modSearchName: relatedToOptions[0],
    modSearchId: "",
    minutes: 0,
    seconds: 0,
  };

  const [meetingInfo, setMeetingInfo] = useState({
    title: "",
    makeThisOnline: false,
    fromDate: new Date().toISOString().split("T")[0],
    fromTime: "",
    toDate: new Date().toISOString().split("T")[0],
    toTime: "",
    location: "",
    repeatType: "NONE",
    description: "",
    allDay: true,
    participantsRemainder: reminderOptions[0],
    remainder: reminderOptions[0],
    afterTimes: 1,
    repeatEveryNum: 0,
  });

  const resetForm = {
    title: "",
    makeThisOnline: false,
    fromDate: new Date().toISOString().split("T")[0],
    fromTime: "",
    toDate: new Date().toISOString().split("T")[0],
    toTime: "",
    location: "",
    repeatType: "NONE",
    description: "",
    allDay: true,
    participantsRemainder: reminderOptions[0],
    remainder: reminderOptions[0],
    relatedTo: "",
    relatedRelationId: "",
    addParticipants: [],
    afterTimes: 1,
    repeatEveryNum: 0,
  };

  const getMinDate = (repeatType) => {
    const today = new Date();
    let minDate;

    switch (repeatType) {
      case "Weekly":
        minDate = new Date(today.setDate(today.getDate() + 7));
        break;
      case "Monthly":
        minDate = new Date(today.setDate(today.getDate() + 30));
        break;
      case "Yearly":
        minDate = new Date(today.setDate(today.getDate() + 365));
        break;
      default:
        minDate = new Date(today.setDate(today.getDate()));
    }

    if (minDate) {
      const year = minDate.getFullYear();
      const month = String(minDate.getMonth() + 1).padStart(2, "0");
      const day = String(minDate.getDate()).padStart(2, "0");
      return `${year}-${month}-${day}`;
    }
    return "";
  };

  const [minDate, setMinDate] = useState(getMinDate(taskInfo.repeatType.value));

  useEffect(() => {
    taskInfo.repeatEndDate = "";
    setMinDate(getMinDate(taskInfo.repeatType.value));
  }, [taskInfo.repeatType.value]);

  const handleModuleItemClick = (item) => {
    setSelectedModuleItem(item);
    setModuleListVisible(false);
  };

  const handleDoneButtonClick = () => {
    const valid = true;

    if (valid) {
      handleClose();
      setChecked(true);
    }
  };

  const handleSelect = (selectedOption) => {
    if (selectedOption === "Meetings") {
      setShowModalType("Meetings");
    }
    if (selectedOption === "Tasks") {
      setShowModalType("Tasks");
    }
    if (selectedOption === "saveTasks") {
      setShowModalType("saveTasks");
    }
  };

  const handleClose = () => {
    setShow(false);
    setShowModalType("Tasks");
  };
  const handleRepeatClose = () => {
    setShowRepeat(false);
    setShowModalType("Tasks");
  };

  const handleRepeatChange = (checked) => {
    if (checked) {
      setShowRepeat(true);
      setShowModalType("");
      setRepeatChecked(checked);
      taskInfo.repeatType = repeatOptions[0];
    } else {
      setShowRepeat(false);
      setShowModalType("Tasks");
      setRepeatChecked(false);
      taskInfo.repeatType = repeatOptions[0];
      taskInfo.repeatEndDate = "";
      taskInfo.repeatEnd = "";
    }
  };

  const handleReminderChange = (checked) => {
    if (checked) {
      setShow(true);
      setShowModalType("");
      setTimeout(() => {
        setChecked(checked);
      }, 1000);
      taskInfo.notify = notifyOptions[0];
    } else {
      setShow(false);
      setShowModalType("Tasks");
      setChecked(false);
      taskInfo.notify = notifyOptions[0];
      taskInfo.remindTime = "";
    }
  };

  const handleChangeMeetings = (e) => {
    const { name, value } = e.target;
    if (
      (name === "description" || name === "title" || name === "location") &&
      value.startsWith(" ")
    ) {
      return;
    }
    if (name === "fromDate" && meetingInfo.toDate < value) {
      setTimeout(() => {
        NotificationManager.error(
          "",
          "To Date must be greater than From date",
          3000
        );
      });
    }
    if (name === "toDate" && value < meetingInfo.fromDate) {
      setTimeout(() => {
        NotificationManager.error(
          "",
          "To Date must be greater than From date",
          3000
        );
      });
    }
    setMeetingInfo((prevCallsInfo) => ({
      ...prevCallsInfo,
      [name]: value,
    }));
  };

  const handleSubSelect = (selectedOption) => {
    if (selectedOption === "AddParticipants") {
      setShowModalSubType("AddParticipants");
    }
    if (selectedOption === "Reset") {
      setShowModalSubType("Reset");
    }
  };

  const handleInputChange = (event) => {
    if (event.target.value.startsWith(" ")) {
      return;
    }
    setEmailInput(event.target.value);
  };

  const handleInputFieldChange = (e) => {
    const { name, value } = e.target;
    let formattedValue = value;

    if (name === "dueDate" || name === "remindDate") {
      formattedValue = value;
    }

    if (name === "subject" && value.startsWith(" ")) {
      return;
    }

    if (name === "repeatEndTimes") {
      if (
        /^\d*$/.test(value) &&
        (value === "" || (parseInt(value) >= 1 && parseInt(value) <= 30))
      ) {
        formattedValue = value;
      } else {
        return;
      }
    }
    setTaskInfo((prevLeadInfo) => ({
      ...prevLeadInfo,
      [name]: formattedValue,
    }));
  };

  const handleCheckboxToggle = (itemId, itemType) => {
    const isSelected = selectedItems?.some(
      (item) => item.moduleId === itemId
      // && item.flagValue === itemType
    );

    if (isSelected) {
      setSelectedItems(
        selectedItems.filter((item) => item.moduleId !== itemId)
      );
    } else {
      setSelectedItems([
        ...selectedItems,
        { moduleId: itemId, flagValue: itemType },
      ]);
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter" || event.key === ",") {
      handleAddEmail();
    }
  };

  const handleRemoveEmail = (indexToRemove) => {
    const updatedEmailList = emailList.filter(
      (email, index) => index !== indexToRemove
    );

    setEmailList(updatedEmailList);
  };

  const validateEmails = (email) => {
    // Regular expression for basic email validation
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  };

  const handleAddEmail = () => {
    const emails = emailInput.trim().split(",");
    if (!validateEmails(emails)) {
      return;
    } else {
      const uniqueEmails = emails.filter(
        (email) => !emailList.includes(email.trim())
      );
      setEmailList([...emailList, ...uniqueEmails]);
    }
    setEmailInput("");
  };

  const handleRadioChange = (event) => {
    setSelectedOptionRadio(event.target.value);
  };

  const handleAddParticipant = (addParticipant) => {
    setAddParticipant(addParticipant);
  };

  const handleSearchInputChange = (event) => {
    const inputValue = event.target.value;
    if (inputValue.startsWith(" ")) {
      return;
    }
    setSearchInput(inputValue);

    let filteredList = [];
    if (addParticipant.value === "Users") {
      filteredList = userList.filter((item) =>
        item.name.toLowerCase().includes(inputValue.toLowerCase())
      );
    } else if (addParticipant.value === "Contacts") {
      filteredList = contactList.filter((item) =>
        item?.contactName.toLowerCase().includes(inputValue.toLowerCase())
      );
    } else if (addParticipant.value === "Leads") {
      filteredList = leadList.filter((item) =>
        item.leadName.toLowerCase().includes(inputValue.toLowerCase())
      );
    }
    setFilteredItems(filteredList);
  };

  const handleDateTextClick = () => {
    setShowDateInput(true);
  };

  const handleDateInputChange = (event) => {
    setSelectedDate(event.target.value);
    setShowDateInput(false);
  };

  const handleCheckboxChange = () => {
    setIsAllDay(!isAllDay);
  };

  const handleChangeSelectDropDown = (selectedOption, field) => {
    setCallInfo((prevLeadInfo) => ({
      ...prevLeadInfo,
      [field]: selectedOption,
    }));
  };

  const handleChangeSelectDropDownTask = (selectedOption, field) => {
    setTaskInfo((prevLeadInfo) => ({
      ...prevLeadInfo,
      [field]: selectedOption,
    }));
  };

  // Create a task API

  const handleCreateTask = async (e) => {
    let isValidForm = true;

    const setErrorMessage = (setter, message, timeout = 5000) => {
      setter(message);
      setTimeout(() => {
        setter("");
      }, timeout);
      isValidForm = false;
    };

    const currentTaskInfo = taskInfo;

    if (currentTaskInfo.subject === "") {
      setErrorMessage(setMandatoryMsg, "Subject cannot be empty.", 5000);
    }
    if (
      currentTaskInfo.subject &&
      !validateCallSubject(currentTaskInfo.subject)
    ) {
      setErrorMessage(
        setMandatoryMsg,
        "Please enter a valid  Subject name.",
        5000
      );
    }

    if (currentTaskInfo.dueDate === "") {
      setErrorMessage(
        setMandatoryMsgDueDate,
        "Due date cannot be empty.",
        5000
      );
    }
    let requestBody = {};

    if (!isValidForm) return;
    requestBody = {
      taskOwnerId: selectedOwner.userId,
      loggedInUserId: loginUserId,
      subject: taskInfo.subject,
      dueDate: convertDateFormat(taskInfo.dueDate),
      status: taskInfo.status.value,
      priority: taskInfo.priority.value,
      description: taskInfo.description,
      recurringRepeatStatus: repeatChecked ? "true" : "false",
      reminderStatus: checked ? "true" : "false",
    };
    if (selectedOptionLead.value === "Lead") {
      requestBody.leadId = selectedLeadItem.leadId;
    } else if (selectedOptionLead.value === "Contact") {
      requestBody.contactId = selectedContact?.contactId;
      requestBody.modSearchId = selectedModuleItem?.moduleId;
      requestBody.modSearchName = selectedOptionModule.value;
    }
    if (repeatChecked) {
      requestBody.repeatType = taskInfo.repeatType.value;
      if (endOn === "Never") {
        requestBody.repeatEnd = "Never";
      }
      if (endOn === "After") {
        requestBody.repeatEnd = "After " + taskInfo.repeatEndTimes + " Times";
      }
      if (endOn === "On") {
        requestBody.repeatEnd = taskInfo.repeatEndDate
          ? "On " +
            moment(new Date(taskInfo.repeatEndDate)).format("DD-MM-YYYY")
          : "";
      }
    }
    if (checked) {
      requestBody.remindTime = taskInfo.remindTime;
      requestBody.notify = taskInfo.notify.value;
      requestBody.remindDate = convertDateFormat(taskInfo.remindDate);
    }

    setApiLoader(true);
    await axios
      .post(URI.createTask, requestBody, {
        headers: getHeaders(true),
      })
      .then((response) => {
        if (response.data.status === 200) {
          resetData();
          getOverview();
          setSelectedOwner(userList[0]);

          // setSelectedLeadItem(leadList[0]);
          // setSelectedContact(contactList[0]);
          setRepeatChecked(false);
          setChecked(false);
          NotificationManager.success("", response.data.message, 5000);
          if (e === "SAVE" || e === "ADD") {
            resetData();
            setSelectedOwner(userList[0]);

            // setSelectedContact(contactList[0]);
            // setSelectedLeadItem(leadList[0]);
            setRepeatChecked(false);
            setChecked(false);
          }
          setShowModalType("");
        } else {
          NotificationManager.error("", response.data.message, 5000);
        }
      })
      .catch((error) => {
        handleApiError(error, navigate);
      })
      .finally(() => setApiLoader(false));
  };

  const getTodayDate = () => {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, "0");
    const day = String(today.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  const todayDate = getTodayDate();

  const getAllAssignModules = async (main, sub, id) => {
    const requestBody = {
      loginUserId: loginUserId,
      mainModule: main,
      subModule: sub,
      moduleId: id,
    };

    await axios
      .post(URI.moduleWiseAssign, requestBody, {
        headers: getHeaders(true),
      })
      .then((response) => {
        if (response.data.status === 200) {
          setModuleWiseData(response.data.data.assignModulewiseDetails);
        }
      })
      .catch(async (error) => {
        handleApiError(error, navigate);
      });
  };

  useEffect(() => {
    getAllLeadsDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPageLead, perPageLead]);

  const handleInputClickSet = (item, value) => {
    value === "Owner"
      ? setSelectedOwner(item)
      : value === "Contacts"
      ? setSelectedContact(item)
      : setSelectedLeadItem(item);

    if (value === "Contacts") {
      getAllAssignModules(
        "CONTACTS",
        [selectedOptionModule.value],
        item?.contactId
      );
    }

    if (value === "participantsRemainder") {
      setMeetingInfo((prevLeadInfo) => ({
        ...prevLeadInfo,
        participantsRemainder: item,
      }));
    }

    if (value === "remainder") {
      setMeetingInfo((prevLeadInfo) => ({
        ...prevLeadInfo,
        remainder: item,
      }));
    }
  };

  const handleModuleClick = (e) => {
    e.stopPropagation();
    setListVisible(false);
    setModuleListVisible(!moduleListVisible);
    setDropdownRelatedTo(false);
    setDropdownSelectStatus(false);
  };

  const handleChangeModule = (selectedOption) => {
    setSelectedOptionModule(selectedOption);
    getAllAssignModules(
      "CONTACTS",
      [selectedOption.value],
      selectedContact?.contactId
    );
  };

  const formatDate = (date) => {
    const options = { year: "numeric", month: "short", day: "numeric" };
    return new Date(date).toLocaleDateString("en-US", options);
  };

  const handleSelectedLeadChange = (value) => {
    setSelectedOwner(value);
  };

  // const handleSelectedContactListChange = (value) => {
  //   setSelectedContact(value);
  // };

  // const handleSelectedLeadListChange = (value) => {
  //   setSelectedLeadItem(value);
  // };

  const handleChangeRepeat = (selectedOption) => {
    setSelectedOptionRepeat(selectedOption);
  };

  const handleChangeFrequency = (selectedOption) => {
    setSelectedOptionFrequency(selectedOption);
  };

  const handleChangeLead = (selectedOption) => {
    setSelectedOptionLead(selectedOption);
    if (selectedOption.value === "Contact") {
      getAllAssignModules(
        "CONTACTS",
        [selectedOptionModule.value],
        selectedContact?.contactId
      );
    }
  };

  const getAllContact = async () => {
    const requestBody = {
      loggedInUserId: loginUserId,
      pageNo: currentPageContact,
      pageSize: perPageContact,
    };
    await axios
      .post(URI.getAllContact, requestBody, {
        headers: getHeaders(true),
      })
      .then((response) => {
        if (response.data.status === 200) {
          setContactList(response.data.data.allContactDetails);
          setModalContactDetails(response.data.data.allContactDetails);
          setTotalItemsContact(response.data.data.rowCount);
        }
      })
      .catch((err) => {
        handleApiError(err, navigate);
      });
  };

  const getAllLeadsDetails = async () => {
    const requestBody = {
      loggedInUserId: loginUserId,
      pageNo: currentPageLead,
      pageSize: perPageLead,
    };
    await axios
      .post(URI.getAllLeads, requestBody, { headers: getHeaders(true) })

      .then((response) => {
        if (response.data.status === 200) {
          setLeadList(response.data.data.allLeadDetails);
          setModalLeadDetails(response.data.data.allLeadDetails);
          setTotalItemsLead(response.data.data.rowCount);
        }
      })
      .catch(async (error) => {
        handleApiError(error, navigate);
      });
  };

  const handleInsertCalls = async () => {
    let requestBody = {};
    const modId = selectedModuleItem?.moduleId;

    if (callInfo.subject === "") {
      if (callInfo.subject === "") {
        setMandatoryMsg("Subject cannot be empty.");
        setTimeout(() => {
          setMandatoryMsg("");
        }, 3000);
      }
    } else {
      if (modalView !== "ScheduleCall") {
        if (
          callInfo.callType.value === "Outbound" ||
          callInfo.callType.value === "Inbound"
        ) {
          requestBody = {
            ...requestBody,
            callDuration:
              callInfo.minutes || callInfo.seconds
                ? `${callInfo.minutes}:${callInfo.seconds}`
                : callInfo.callDuration,
          };
          if (callInfo.callType.value === "Outbound") {
            requestBody = {
              ...requestBody,
              outGoingCallStatus: "Completed",
            };
          }
        }
      }
      if (modalView === "ScheduleCall") {
        if (selectedOptionLead.value === "Contact") {
          requestBody = {
            ...requestBody,
            contactId: selectedContact?.contactId,
          };
        } else {
          requestBody = { ...requestBody, leadId: selectedLeadItem.leadId };
        }
        requestBody = {
          ...requestBody,
          loggedInUserId: loginUserId,
          choosingType: "schedule_a_Call",
          callType: "Outbound",
          outGoingCallStatus: "Scheduled",
          callStartDate: moment(new Date(callInfo.callStartDate)).format(
            "DD-MM-YYYY"
          ),
          callStartTime: `${callStartTime.hour}:${callStartTime.minutes} ${callStartTime.meridiem}`,
          // callStartTime: callInfo.callStartTime,
          callOwnerId: selectedOwner.userId,
          subject: callInfo.subject,
          reminder: callInfo.reminder.value,
          callPurpose: callInfo.callPurpose.value,
          callAgenda: callInfo.callAgenda,
          modSearchName: selectedOptionModule.value,
          modSearchId: modId,
          choosing: selectedOptionLead.value,
        };
      } else {
        if (selectedOptionLead.value === "Contact") {
          requestBody = {
            ...requestBody,
            contactId: selectedContact?.contactId,
          };
        } else {
          requestBody = { ...requestBody, leadId: selectedLeadItem.leadId };
        }
        requestBody = {
          ...requestBody,
          loggedInUserId: loginUserId,
          choosingType: "log_a_call",
          callType: callInfo.callType.value,
          callStartDate: moment(new Date(callInfo.callStartDate)).format(
            "DD-MM-YYYY"
          ),
          // callStartTime: callInfo.callStartTime,
          callStartTime: `${callStartTime.hour}:${callStartTime.minutes} ${callStartTime.meridiem}`,
          callOwnerId: selectedOwner?.userId,
          subject: callInfo.subject,
          callPurpose: callInfo.callPurpose.value,
          callAgenda: callInfo.callAgenda,

          description: callInfo.description,
          callResult: callInfo.callResult.value,
          // outGoingCallStatus: "Completed",
          modSearchName: selectedOptionModule.value,
          modSearchId: modId,
          choosing: selectedOptionLead.value,
        };
      }
      const url = URI.insertCalls;
      setCreateLoader(true);
      await axios
        .post(url, requestBody, {
          headers: getHeaders(true),
        })

        .then((response) => {
          if (response.data.status === 200) {
            NotificationManager.success("", response.data.message, 5000);
            setShowModalType("");
            getOverview();
            setCallInfo(callInformation);
          } else {
            NotificationManager.error("", response.data.message, 5000);
          }
          const callStartTime = {
            hour: "00",
            minutes: "00",
            meridiem: "AM",
          };
          setCallStartTime(callStartTime);
        })
        .catch(function (error) {
          throw new Error("Failed to fetch data", error);
        })
        .finally(() => {
          setCreateLoader(false);
          setOverlapModal(false);
        });
    }
  };

  const handleCheckCalls = async (e) => {
    let isValidForm = true;

    const setErrorMessage = (setter, message, timeout = 5000) => {
      setter(message);
      setTimeout(() => {
        setter("");
      }, timeout);
      isValidForm = false;
    };

    if (callInfo.subject === "") {
      setErrorMessage(setMandatoryMsg, "subject name cannot be empty.", 4000);
    }

    if (!isValidForm) return;

    if (callInfo.callAgenda && !validateCallAgenda(callInfo.callAgenda)) {
      // setMandatoryCallMsg("Please enter a valid agenda.");
      setTimeout(() => {
        // setMandatoryCallMsg("");
      }, 3000);
    } else {
      setApiLoader(true);
      const requestBody = {
        moduleName: "calls",
        loggedInUserId: loginUserId,
        fromDate: moment(new Date(callInfo.callStartDate)).format("DD-MM-YYYY"),
        fromTime: `${callStartTime.hour}:${callStartTime.minutes} ${callStartTime.meridiem}`,
      };

      await axios
        .post(URI.checkMeetingAvailable, requestBody, {
          headers: getHeaders(true),
        })

        .then((response) => {
          if (response.data.status === 200) {
            setOverlapMessage(response.data.message);
            if (response.data.data.isScheduled) {
              setOverlapModal(true);
            } else {
              handleInsertCalls(e);
            }
          } else {
            NotificationManager.error("", response.data.message, 5000);
          }
        })
        .catch(function (error) {
          throw new Error("Failed to fetch data", error);
        })
        .finally(() => setApiLoader(false));
    }
  };

  const handleCheckMeeting = async (e) => {
    let isValidForm = true;

    const setErrorMessage = (setter, message, timeout = 5000) => {
      setter(message);
      setTimeout(() => {
        setter("");
      }, timeout);
      isValidForm = false;
    };

    if (meetingInfo.title === "") {
      setErrorMessage(setMandatoryMsg, "Title name cannot be empty.", 4000);
    }

    if (!validateJobTitle(meetingInfo.title) && meetingInfo.title.length > 0) {
      setErrorMessage(setMandatoryMsg, "Please enter a valid title.");
    }

    if (!isValidForm) return;

    if (
      meetingInfo.fromDate > meetingInfo.toDate ||
      (!isAllDay && (fromTimeSet.hour === "00" || toTimeSet.hour === "00")) ||
      (!isAllDay &&
        meetingInfo.fromDate === meetingInfo.toDate &&
        fromTimeSet.hour === toTimeSet.hour &&
        fromTimeSet.minutes === toTimeSet.minutes &&
        fromTimeSet.meridiem === toTimeSet.meridiem)
    ) {
      if (meetingInfo.fromDate > meetingInfo.toDate) {
        setTimeout(() => {
          NotificationManager.error(
            "",
            "From date cannot be greater than to date",
            3000
          );
        });
      }
      if (!isAllDay && (fromTimeSet.hour === "00" || toTimeSet.hour === "00")) {
        setTimeout(() => {
          NotificationManager.error("", "Hours cannot be Zero", 3000);
        });
      } else if (
        !isAllDay &&
        meetingInfo.fromDate === meetingInfo.toDate &&
        fromTimeSet.hour === toTimeSet.hour &&
        fromTimeSet.minutes === toTimeSet.minutes &&
        fromTimeSet.meridiem === toTimeSet.meridiem
      ) {
        setTimeout(() => {
          NotificationManager.error(
            "",
            "From and To time cannot be the same",
            3000
          );
        });
      }
    } else {
      let requestBody = {};
      requestBody = {
        ...requestBody,
        moduleName: "meetings",
        loggedInUserId: loginUserId,
        fromDate: moment(new Date(meetingInfo.fromDate)).format("DD-MM-YYYY"),
        toDate: moment(new Date(meetingInfo.toDate)).format("DD-MM-YYYY"),
        allDay: isAllDay ? "true" : "false",
      };
      if (!isAllDay) {
        requestBody = {
          ...requestBody,
          fromTime: `${fromTimeSet.hour}:${fromTimeSet.minutes} ${fromTimeSet.meridiem}`,
          toTime: `${toTimeSet.hour}:${toTimeSet.minutes} ${toTimeSet.meridiem}`,
        };
      }
      setApiLoader(true);
      await axios
        .post(URI.checkMeetingAvailable, requestBody, {
          headers: getHeaders(true),
        })

        .then((response) => {
          if (response.data.status === 200) {
            setOverlapMessage(response.data.message);
            if (response.data.data.isScheduled) {
              setOverlapModal(true);
            } else {
              handleInsertMeetings(e);
            }
          } else {
            NotificationManager.error("", response.data.message, 5000);
          }
        })
        .catch(function (error) {
          throw new Error("Failed to fetch data", error);
        })
        .finally(() => setApiLoader(false));
    }
  };

  const handleInsertMeetings = async (e) => {
    if (
      meetingInfo.title === "" ||
      meetingInfo.fromDate > meetingInfo.toDate ||
      (!isAllDay &&
        fromTimeSet.hour === toTimeSet.hour &&
        fromTimeSet.minutes === toTimeSet.minutes &&
        fromTimeSet.meridiem === toTimeSet.meridiem) ||
      (!isAllDay && (fromTimeSet.hour === "00" || toTimeSet.hour === "00"))
    ) {
      if (meetingInfo.title === "") {
        setMandatoryMsg("Title cannot be empty.");
        setTimeout(() => {
          setMandatoryMsg("");
        }, 3000);
      }
      if (meetingInfo.fromDate > meetingInfo.toDate) {
        setTimeout(() => {
          NotificationManager.error(
            "",
            "From cannot be greater than To time",
            3000
          );
        });
      }
      if (
        meetingInfo.fromDate === meetingInfo.toDate ||
        (!isAllDay && (fromTimeSet.hour === "00" || toTimeSet.hour === "00"))
      ) {
        if (
          !isAllDay &&
          (fromTimeSet.hour === "00" || toTimeSet.hour === "00")
        ) {
          setTimeout(() => {
            NotificationManager.error("", "Hours cannot be Zero", 3000);
          });
        } else if (
          !isAllDay &&
          meetingInfo.fromDate === meetingInfo.toDate &&
          fromTimeSet.hour === toTimeSet.hour &&
          fromTimeSet.minutes === toTimeSet.minutes &&
          fromTimeSet.meridiem === toTimeSet.meridiem
        ) {
          setTimeout(() => {
            NotificationManager.error(
              "",
              "From and To time cannot be the same",
              3000
            );
          });
        }
      }
    } else {
      let requestBody = {};
      if (!isAllDay) {
        requestBody = {
          fromTime: `${fromTimeSet.hour}:${fromTimeSet.minutes} ${fromTimeSet.meridiem}`,
          toTime: `${toTimeSet.hour}:${toTimeSet.minutes} ${toTimeSet.meridiem}`,
        };
      }
      if (
        selectedOptionRepeat.value !== "None" &&
        selectedOptionRadio === "never"
      ) {
        requestBody = {
          ...requestBody,
          repeatEnds: "Never",
        };
      } else if (
        selectedOptionRepeat.value !== "None" &&
        selectedOptionRadio === "after"
      ) {
        requestBody = {
          ...requestBody,
          repeatEnds: `After ${meetingInfo.afterTimes} Times`,
        };
      } else if (
        selectedOptionRepeat.value !== "None" &&
        selectedOptionRadio === "on"
      ) {
        requestBody = {
          ...requestBody,
          repeatEnds: `On ${moment(new Date(selectedDate)).format(
            "DD-MM-YYYY"
          )}`,
        };
      }
      const modId = selectedModuleItem?.moduleId;

      if (selectedOptionLead.value === "Contact") {
        requestBody = {
          ...requestBody,
          relatedTo: selectedContact?.contactId,
          relatedRelationId: modId,
        };
      } else if (selectedOptionLead.value === "Lead") {
        requestBody = {
          ...requestBody,
          relatedTo: selectedLeadItem.leadId,
        };
      } else if (selectedOptionLead.value === "Others") {
        requestBody = {
          ...requestBody,
          relatedRelationId: modId,
        };
      }
      if (selectedItems.length > 0) {
        requestBody = {
          ...requestBody,
          addParticipants: selectedItems,
        };
      }
      if (selectedOptionRepeat.value === "Custom") {
        requestBody = {
          ...requestBody,
          frequency: "Daily",
          repeatEvery: `${meetingInfo.repeatEveryNum} Days`,
        };
      }
      setCreateLoader(true);
      requestBody = {
        ...requestBody,
        loggedInUserId: loginUserId,
        meetingOwnerId: selectedOwner?.userId,
        title: meetingInfo.title,
        makeThisOnline: meetingInfo.makeThisOnline,
        fromDate: moment(new Date(meetingInfo.fromDate)).format("DD-MM-YYYY"),
        toDate: moment(new Date(meetingInfo.toDate)).format("DD-MM-YYYY"),
        hostId: selectedOwner.userId,
        location: meetingInfo.location,
        choosingType: selectedOptionLead.value.toUpperCase(),
        description: meetingInfo.description,
        allDay: isAllDay ? "true" : "false",
        participantsRemainder: meetingInfo.participantsRemainder.value,
        remainder: meetingInfo.remainder.value,
        inviteByEmails: emailList.length > 0 ? emailList : [],
        repeatType: selectedOptionRepeat.value,
      };

      const url = URI.insertMeetings;

      await axios
        .post(url, requestBody, {
          headers: getHeaders(true),
        })

        .then((response) => {
          if (response.data.status === 200) {
            NotificationManager.success("", response.data.message, 5000);
            getOverview();
            setShowModalType("");
            if (e === "SAVE") {
              setMeetingInfo(resetForm);
              setEmailList([]);
              setSelectedItems([]);
            }
          } else {
            NotificationManager.error("", response.data.message, 5000);
          }
        })
        .catch(function (error) {
          throw new Error("Failed to fetch the data", error);
        })
        .finally(() => {
          setCreateLoader(false);
          setOverlapModal(false);
        });
    }
  };

  const handleInputClick = (type) => {
    setListVisible(!listVisible);
    setModuleListVisible(false);
    setListVisibleType(type);
  };

  const hours = Array.from({ length: 12 }, (_, i) =>
    String(i + 1).padStart(2, "0")
  );
  const minutes = Array.from({ length: 12 }, (_, i) =>
    String(i * 5).padStart(2, "0")
  );
  const handleChangeCalls = (e) => {
    const { name, value } = e.target;
    if (
      (name === "subject" || name === "callAgenda" || name === "description") &&
      value.startsWith(" ")
    ) {
      return;
    }

    if (name === "minutes") {
      if (parseFloat(value) < 0 || parseFloat(value) > 999) {
        return;
      }
    }

    if (name === "seconds") {
      if (parseFloat(value) < 0 || parseFloat(value) > 59) {
        return;
      }
      // .replace(/\D/g, "")
    }
    setCallInfo((prevCallsInfo) => ({
      ...prevCallsInfo,
      [name]: ["seconds", "minutes"].includes(name)
        ? value.replace(/\D/g, "")
        : value,
    }));

    if (name === "minutes" && value.trim() === "") {
      setCallInfo((prevCallsInfo) => ({
        ...prevCallsInfo,
        minutes: 0,
      }));
    }
    if (name === "seconds" && value.trim() === "") {
      setCallInfo((prevCallsInfo) => ({
        ...prevCallsInfo,
        seconds: 0,
      }));
    }
  };

  // useEffect(() => {
  //   getAllContact();
  //   getAllLeadsDetails();
  // }, []);

  useEffect(() => {
    getAllContact();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPageContact, perPageContact]);

  useEffect(() => {
    if (contactList.length > 0) {
      setSelectedContact(contactList[0]);
    }
  }, [contactList]);

  useEffect(() => {
    if (leadList.length > 0) {
      setSelectedLeadItem(leadList[0]);
    }
  }, [leadList]);

  useEffect(() => {
    const foundItem = userList?.find((item) => item.userId === loginUserId);
    if (foundItem) {
      setSelectedOwner(foundItem);
    }
  }, [loginUserId]);

  useEffect(() => {
    // Initialize filteredItems with the entire list when the component mounts
    if (addParticipant.value === "Users") {
      setFilteredItems(userList);
    } else if (addParticipant.value === "Contacts") {
      setFilteredItems(contactList);
    } else if (addParticipant.value === "Leads") {
      setFilteredItems(leadList);
    }
  }, [addParticipant.value]);

  useEffect(() => {
    if (moduleName === "contact") {
      getAllAssignModules("CONTACTS", ["ACCOUNTS"], selectedData?.contactId);
    }
  }, []);

  return (
    <div
      onClick={() => {
        setListVisibleType("");
        setListVisible(false);
        setIsDropdownOpen(false);
      }}
    >
      <div className="p-0">
        {/* <div className='d-flex gap-3 ms-2'>
        <img src={Opened} alt="opened"/>
        <div className="notesHeader">Open Activities</div>
    </div> */}
        <div className="d-flex justify-content-between align-items-center ms-2 ">
          <div className="d-flex gap-2">
            <img src={Opened} alt="opened" height={20} width={20} />
            <div className="notesHeader">Open Activities</div>
          </div>
          <div className=" d-flex gap-2 pe-1">
            <div className="btn-group">
              {moduleName === "lead" || moduleName === "contact" ? (
                <button
                  type="button"
                  className="addProductsButton py-1"
                  data-bs-toggle="dropdown"
                  aria-expanded={isDropdownOpen ? "true" : "false"}
                  onClick={(e) => {
                    e.stopPropagation();
                    setIsDropdownCallsOpen(false);
                    setIsDropdownOpen(!isDropdownOpen);
                  }}
                  style={{ width: "125px", marginRight: "10px" }}
                >
                  Add New
                </button>
              ) : (
                <></>
              )}
              <ul className={`dropdown-menu ${isDropdownOpen ? "show" : ""}`}>
                <li
                  className="dropdown-item cursorPointer"
                  onClick={() => {
                    setIsDropdownOpen(false);
                    handleSelect("Tasks");
                    resetData();
                  }}
                >
                  Task
                </li>
                <li
                  className="dropdown-item cursorPointer"
                  onClick={() => {
                    handleSelect("Meetings");
                    setMeetingInfo(resetForm);
                    setEmailList([]);
                    setSelectedItems([]);
                    setIsAllDay(false);
                    setFromTimeSet({
                      hour: "00",
                      minutes: "00",
                      meridiem: "AM",
                    });
                    setToTimeSet({
                      hour: "00",
                      minutes: "00",
                      meridiem: "AM",
                    });
                    setIsDropdownOpen(false);
                  }}
                >
                  Meeting
                </li>
                <li
                  className="dropdown-item cursorPointer"
                  onClick={(e) => {
                    e.stopPropagation();
                    setIsDropdownOpen(true);
                    setIsDropdownCallsOpen(!isDropdownCallsOpen);
                  }}
                >
                  Call
                </li>
                <ul
                  className={`dropdown-menu ${
                    isDropdownCallsOpen ? "show" : ""
                  }`}
                  style={{ right: "10%" }}
                >
                  <li
                    className="dropdown-item cursorPointer"
                    onClick={() => {
                      setIsDropdownCallsOpen(false);
                      setIsDropdownOpen(false);
                      setShowModalType("ScheduleCall");
                      setModalView("ScheduleCall");
                      // setSelectedOptionLead(leadContactOptions[0]);
                    }}
                  >
                    Schedule a Call
                  </li>
                  <li
                    className="dropdown-item cursorPointer"
                    onClick={() => {
                      setIsDropdownCallsOpen(false);
                      setIsDropdownOpen(false);
                      setShowModalType("ScheduleCall");
                      setModalView("LogCall");
                      // setSelectedOptionLead(leadContactOptions[0]);
                    }}
                  >
                    Log a Call
                  </li>
                </ul>
              </ul>
            </div>
          </div>
        </div>
        <div
          style={{ borderBottom: "2px solid #ffffff" }}
          className="mt-2"
        ></div>
        <div className="p-2 mt-2">
          {(openActivitiesTasks?.length > 0 ||
            openActivitiesMeetings?.length > 0 ||
            openActivitiesCalls?.length > 0) && (
            <div className="mt-2 ms-2 d-flex gap-0 justify-content-between px-3 bg-white rounded-5 py-1 widthContainerDataOpenActivity">
              <span
                className={`cursorPointer ${
                  openActivityTabView === "Tasks"
                    ? "overviewTimelineTabOpenActivity"
                    : "  d-flex justify-content-center align-items-center"
                }`}
                onClick={() => {
                  setOpenActivityTabView("Tasks");
                }}
              >
                Tasks
              </span>
              <span
                className={`cursorPointer ${
                  openActivityTabView === "Meetings"
                    ? "overviewTimelineTabOpenActivity"
                    : "  d-flex justify-content-center align-items-center"
                }`}
                onClick={() => {
                  setOpenActivityTabView("Meetings");
                }}
              >
                Meetings
              </span>
              <span
                className={`cursorPointer ${
                  openActivityTabView === "Calls"
                    ? "overviewTimelineTabOpenActivity"
                    : "  d-flex justify-content-center align-items-center"
                }`}
                onClick={() => {
                  setOpenActivityTabView("Calls");
                }}
              >
                Calls
              </span>
            </div>
          )}
          <div
            className="mb-2 mt-4 mx-2"
            style={{
              border: "1px solid #DEDFDF",
              borderRadius: "10px",
            }}
          >
            {openActivityTabView === "Tasks" && (
              <table
                id="productTable"
                style={{
                  borderCollapse: "collapse",
                  width: "100%",
                  textAlign: "center",
                }}
                className="p-2"
              >
                {openActivitiesTasks?.length > 0 && (
                  <thead
                    style={{
                      borderBottom: "2px solid #DEDFDF",
                      height: "40px",
                    }}
                  >
                    <tr className="p-2 productTableHeader">
                      <th style={{ width: "20%" }}>Subject</th>
                      <th style={{ width: "20%" }}>Due Date</th>
                      <th style={{ width: "20%" }}>Task Owner</th>
                      <th style={{ width: "20%" }}>Status</th>
                      <th style={{ width: "20%" }}>Priority</th>
                    </tr>
                  </thead>
                )}
                <tbody className="p-2">
                  {openActivitiesTasks?.length > 0 ? (
                    openActivitiesTasks?.map((item, index) => (
                      <tr
                        style={{ borderBottom: "1px solid #ccc" }}
                        key={index}
                      >
                        <td style={{ color: "#2177FE", fontSize: "16px" }}>
                          {item?.subject}
                        </td>
                        <td className="productsTabledata">{item?.dueDate}</td>
                        <td className="productsTabledata">{item?.taskOwner}</td>
                        <td className="productsTabledata">{item?.status}</td>
                        <td className="productsTabledata">{item?.priority}</td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="5" style={{ textAlign: "center" }}>
                        <div className="text-center mt-2">
                          <div
                            className="ms-4"
                            style={{ color: "#B9B9B9", fontSize: "20px" }}
                          >
                            No records found
                          </div>
                          <div
                            className="ms-4"
                            style={{ color: "#B9B9B9", fontSize: "14px" }}
                          >
                            There is no data available to show
                          </div>
                        </div>
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            )}
            {openActivityTabView === "Meetings" && (
              <table
                id="productTable"
                style={{
                  borderCollapse: "collapse",
                  width: "100%",
                  textAlign: "center",
                }}
                className="p-2"
              >
                {openActivitiesMeetings?.length > 0 && (
                  <thead
                    style={{
                      borderBottom: "2px solid #DEDFDF",
                      height: "40px",
                    }}
                  >
                    <tr className="p-2 productTableHeader">
                      <th style={{ width: "20%" }}>Title</th>
                      <th style={{ width: "20%" }}>From</th>
                      <th style={{ width: "20%" }}>To</th>
                      <th style={{ width: "20%" }}>All Day</th>
                      <th style={{ width: "20%" }}>Host</th>
                    </tr>
                  </thead>
                )}
                <tbody className="p-2">
                  {openActivitiesMeetings?.length > 0 ? (
                    openActivitiesMeetings?.map((item, index) => (
                      <tr
                        style={{ borderBottom: "1px solid #ccc" }}
                        key={index}
                      >
                        <td style={{ color: "#2177FE", fontSize: "16px" }}>
                          {item?.title}
                        </td>
                        <td className="productsTabledata">{item?.from}</td>
                        <td className="productsTabledata">{item?.to}</td>
                        <td className="productsTabledata">
                          {item?.allDay ? "✔️" : "X"}
                        </td>
                        <td className="productsTabledata">{item?.host}</td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="5" style={{ textAlign: "center" }}>
                        <div className="text-center mt-2">
                          <div
                            className="ms-4"
                            style={{ color: "#B9B9B9", fontSize: "20px" }}
                          >
                            No records found
                          </div>
                          <div
                            className="ms-4"
                            style={{ color: "#B9B9B9", fontSize: "14px" }}
                          >
                            There is no data available to show
                          </div>
                        </div>
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            )}
            {openActivityTabView === "Calls" && (
              <table
                id="productTable"
                style={{
                  borderCollapse: "collapse",
                  width: "100%",
                  textAlign: "center",
                }}
                className="p-2"
              >
                {openActivitiesCalls?.length > 0 && (
                  <thead
                    style={{
                      borderBottom: "2px solid #DEDFDF",
                      height: "40px",
                    }}
                  >
                    <tr className="p-2 productTableHeader">
                      <th style={{ width: "20%" }}>Subject</th>
                      <th style={{ width: "20%" }}>Call Start Time</th>
                      <th style={{ width: "20%" }}>Call Owner</th>
                      {/* <th style={{ width: "20%" }}>Call Agenda</th> */}
                      {/* <th style={{ width: "20%" }}>Call Result</th> */}
                      <th style={{ width: "20%" }}>Call To</th>
                      <th style={{ width: "20%" }}>Call Type</th>
                      {/* <th style={{ width: "20%" }}>Call Duration</th> */}
                    </tr>
                  </thead>
                )}
                <tbody className="p-2">
                  {openActivitiesCalls?.length > 0 ? (
                    openActivitiesCalls?.map((item, index) => (
                      <tr
                        style={{ borderBottom: "1px solid #ccc" }}
                        key={index}
                      >
                        <td style={{ color: "#2177FE", fontSize: "16px" }}>
                          {item?.subject}
                        </td>
                        <td className="productsTabledata">
                          {item?.callStartTime}
                        </td>
                        <td className="productsTabledata">{item?.callOwner}</td>
                        <td className="productsTabledata">{item?.callTo}</td>
                        <td className="productsTabledata">{item?.callType}</td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="5" style={{ textAlign: "center" }}>
                        <div className="text-center mt-2">
                          <div
                            className="ms-4"
                            style={{ color: "#B9B9B9", fontSize: "20px" }}
                          >
                            No records found
                          </div>
                          <div
                            className="ms-4"
                            style={{ color: "#B9B9B9", fontSize: "14px" }}
                          >
                            There is no data available to show
                          </div>
                        </div>
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            )}
          </div>
        </div>
      </div>
      <Modal
        show={modalType === "ScheduleCall"}
        id="ConvertModal"
        aria-labelledby="example-custom-modal-styling-title"
        className={`withdrw_modal rounded-2 modal-lg ${
          overlapModal ? "opacity-75" : ""
        }`}
        onClick={() => {
          setDropdownSelectReminder(false);
          setDropdownCallPurpose(false);
          setListVisible(false);
          setModuleListVisible(false);
          setDropdownCallResult(false);
        }}
      >
        <Modal.Body
          className="p-0 rounded-2"
          onClick={() => {
            setShowCallStartTime({
              hour: false,
              minutes: false,
            });
          }}
        >
          <div className="modalClass position-relative">
            <div
              className="d-flex justify-content-between px-4 pt-4 pb-2 rounded-2"
              style={{ background: "rgba(242, 242, 242, 1)" }}
            >
              <h4 className="commonTextColor">
                {modalView === "ScheduleCall"
                  ? "Schedule a call"
                  : "Log a Call"}
              </h4>
              <img
                src={cancelCircle}
                alt="cancel"
                style={{
                  width: "25px",
                  height: "25px",
                  cursor: "pointer",
                }}
                onClick={() => {
                  setShowModalType("");
                  setCallInfo(callInformation);
                  // setSelectedOptionLead(leadContactOptions[0]);

                  setCallStartTime({
                    hour: "00",
                    minutes: "00",
                    meridiem: "AM",
                  });
                }}
              />
            </div>
            <div className="w-100 position-relative">
              <div className="columns_container ps-4 pe-2 pt-2 pb-3">
                <div className="inputFieldDivCalls mt-2 gap-3">
                  <div className="inputFieldDivLeftCalls">
                    <p className="mb-0 mb-1" style={{ color: "#515151" }}>
                      Call To<span className="text-danger">*</span>
                    </p>
                    <Select
                      options={leadContactOptions}
                      styles={createAccountStyles}
                      value={selectedOptionLead}
                      onChange={handleChangeLead}
                    />
                  </div>

                  <div className="inputFieldDivLeftCalls">
                    <p className="mb-0 mb-1" style={{ color: "#515151" }}>
                      {selectedOptionLead.value}
                      <span className="text-danger"></span>
                    </p>
                    {/* <input
                  type="text"
                  placeholder="Search by Name"
                  className="insertCommonInputFieldCalls"
                  value={selectedOptionLead.value}
                /> */}
                    {selectedOptionLead.value === "Contact" && (
                      <div
                        className="d-flex input-with-clear "
                        // onClick={handleOwnerInputClick}
                      >
                        <input
                          type="text"
                          //   placeholder="Vendor Owner"
                          className="insertCommonInputFieldCalls cursorPointer"
                          value={selectedContact?.contactName}
                          onClick={(e) => {
                            e.stopPropagation();
                            handleInputClick("Contacts");
                            setDropdownSelectReminder(false);
                            setDropdownCallPurpose(false);
                            setDropdownCallResult(false);
                          }}
                          readOnly
                        />
                        {selectedContact ? (
                          <span
                            className="clear-buttonCalls"
                            onClick={() => setSelectedContact("")}
                          >
                            <img src={down} alt="img" height={20} width={20} />
                          </span>
                        ) : (
                          <span
                            className="clear-buttonCalls"
                            // onClick={() => setSelectedOwner("")}
                          >
                            <img src={up} alt="img" height={20} width={20} />
                          </span>
                        )}

                        <span
                          className="cursorPointer pt-2 ps-1"
                          onClick={() => setIsContactListModal(true)}
                        >
                          <img
                            src={userSearch}
                            alt="img"
                            width={20}
                            height={20}
                          />
                        </span>
                        {listVisibleType === "Contacts" && listVisible && (
                          <ul
                            className="data-list list-group  lead_ownerList_container position-absolute "
                            style={{
                              top: "3.2rem",
                              width: "21.8rem",
                              minHeight: "max-content",
                            }}
                          >
                            {contactList.map((item, index) => (
                              <li
                                key={index}
                                className="cursorPointer list-group-item"
                                onClick={() => {
                                  handleInputClickSet(item, "Contacts");
                                }}
                              >
                                <div className="d-flex flex-column">
                                  <span> {item?.contactName} </span>
                                </div>
                              </li>
                            ))}
                          </ul>
                        )}
                      </div>
                    )}
                    {selectedOptionLead.value === "Lead" && (
                      <div
                        className="d-flex input-with-clear "
                        // onClick={handleOwnerInputClick}
                      >
                        <input
                          type="text"
                          //   placeholder="Vendor Owner"
                          className="insertCommonInputFieldCalls cursorPointer"
                          value={selectedLeadItem?.leadName}
                          onClick={(e) => {
                            e.stopPropagation();
                            handleInputClick("Leads");
                            setDropdownSelectReminder(false);
                            setDropdownCallPurpose(false);
                            setDropdownCallResult(false);
                          }}
                          readOnly
                        />
                        {selectedLeadItem ? (
                          <span
                            className="clear-buttonCalls"
                            onClick={() => setSelectedLeadItem("")}
                          >
                            <img src={down} alt="img" height={20} width={20} />
                          </span>
                        ) : (
                          <span
                            className="clear-buttonCalls"
                            // onClick={() => setSelectedOwner("")}
                          >
                            <img src={up} alt="img" height={20} width={20} />
                          </span>
                        )}

                        <span
                          className="cursorPointer pt-2 ps-1"
                          onClick={() => setIsLeadListModal(true)}
                        >
                          <img
                            src={userSearch}
                            alt="img"
                            width={20}
                            height={20}
                          />
                        </span>
                        {listVisibleType === "Leads" && listVisible && (
                          <ul
                            className="data-list list-group  lead_ownerList_container position-absolute "
                            style={{
                              top: "3.2rem",
                              width: "21.4rem",
                              minHeight: "max-content",
                            }}
                          >
                            {leadList.map((item, index) => (
                              <li
                                key={index}
                                className="cursorPointer list-group-item"
                                onClick={() =>
                                  handleInputClickSet(item, "Leads")
                                }
                              >
                                <div className="d-flex flex-column">
                                  <span> {item.leadName} </span>
                                </div>
                              </li>
                            ))}
                          </ul>
                        )}
                      </div>
                    )}
                  </div>
                </div>
                <div className="inputFieldDivCalls mt-4 gap-3">
                  <div className="inputFieldDivLeftCalls">
                    <p className="mb-0 mb-1" style={{ color: "#515151" }}>
                      Related To
                    </p>
                    {/* <Select
                      options={relatedToOptions}
                      styles={createAccountStyles}
                      value={selectedOptionModule}
                      onChange={handleChangeModule}
                      isDisabled={selectedOptionLead.value === "Lead"}
                    /> */}
                    <div className="d-flex input-with-clear">
                      <input
                        className="insertCommonInputFieldCalls cursorPointer"
                        value={selectedOptionModule.label}
                        onClick={(e) => {
                          e.stopPropagation();
                          setDropdownCallPurpose(false);
                          setDropdownRelatedTo(!dropdownRelatedTo);
                          setDropdownSelectReminder(false);
                          setListVisible(false);
                          setModuleListVisible(false);
                          setDropdownCallResult(false);
                          setDropdownSelect(false);
                        }}
                        readOnly
                        disabled={selectedOptionLead.value === "Lead"}
                      />
                      {dropdownRelatedTo ? (
                        <>
                          <span
                            className="clear-buttonCalls"
                            onClick={() => setDropdownRelatedTo(false)}
                            style={{
                              top: "1.5rem",
                              left: "19.5rem",
                            }}
                          >
                            <img src={up} alt="img" height={20} width={20} />
                          </span>
                        </>
                      ) : (
                        <>
                          <span
                            className="clear-buttonCalls"
                            style={{
                              top: "1.5rem",
                              left: "19.5rem",
                            }}
                          >
                            <img src={down} alt="img" height={20} width={20} />
                          </span>
                        </>
                      )}
                      {dropdownRelatedTo && (
                        <ul
                          className="data-list list-group  lead_ownerList_container position-absolute "
                          style={{
                            top: "3.25rem",
                            width: "21.9rem",
                            minHeight: "max-content",
                          }}
                        >
                          {relatedToOptions.map((item, index) => (
                            <li
                              key={index}
                              className="cursorPointer list-group-item"
                              onClick={() => {
                                handleChangeModule(item);
                                setDropdownRelatedTo(false);
                                setDropdownCallPurpose(false);
                                setSelectedOptionModule(item);
                              }}
                            >
                              <div className="d-flex flex-column">
                                <span> {item.label} </span>
                              </div>
                            </li>
                          ))}
                        </ul>
                      )}
                    </div>
                  </div>

                  <div className="inputFieldDivLeftCalls position-relative">
                    <p className="mb-0 mb-1">{selectedOptionModule.label}</p>
                    <div className="d-flex input-with-clear ">
                      <input
                        type="text"
                        className="insertCommonInputFieldCalls cursorPointer"
                        value={
                          selectedOptionLead.value === "Lead"
                            ? ""
                            : moduleWiseData.length > 0
                            ? selectedModuleItem.moduleName
                            : ""
                        }
                        disabled={selectedOptionLead.value === "Lead"}
                        onClick={handleModuleClick}
                        readOnly
                      />
                      {selectedOptionLead.value === "Lead" ? (
                        <span className="clear-buttonCalls">
                          <img
                            src={moduleListVisible ? up : down}
                            alt="img"
                            height={20}
                            width={20}
                          />{" "}
                        </span>
                      ) : (
                        <span
                          className="clear-buttonCalls"
                          onClick={handleModuleClick}
                        >
                          <img
                            src={moduleListVisible ? up : down}
                            alt="img"
                            height={20}
                            width={20}
                          />{" "}
                        </span>
                      )}
                    </div>
                    {moduleListVisible && moduleWiseData.length > 0 && (
                      <ul
                        className="data-list list-group  lead_ownerList_container position-absolute "
                        // style={{
                        //   top: "200px",
                        //    width: "27.5vw",
                        // }}

                        style={{
                          top: "4.9rem",
                          width: "21.8rem",
                          minHeight: "max-content",
                        }}
                      >
                        {" "}
                        {moduleWiseData.map((item, index) => (
                          <li
                            key={index}
                            className="cursorPointer list-group-item"
                            onClick={() => handleModuleItemClick(item)}
                          >
                            {item.moduleName}
                          </li>
                        ))}
                      </ul>
                    )}
                  </div>
                </div>
                <div
                  className="inputFieldDivCalls mt-4 gap-3"
                  style={{ alignItems: "start" }}
                >
                  <div className="inputFieldDivLeftCalls">
                    <p className="mb-0 mb-1" style={{ color: "#515151" }}>
                      Call Type<span className="text-danger">*</span>
                    </p>
                    {modalView === "ScheduleCall" ? (
                      <input
                        type="text"
                        className="insertCommonInputFieldCalls"
                        value="Outbound"
                        readOnly
                      />
                    ) : (
                      <Select
                        options={callTypeOptions}
                        styles={createAccountStyles}
                        value={callInfo.callType}
                        menuPlacement="auto"
                        onChange={(selectedOption) =>
                          handleChangeSelectDropDown(selectedOption, "callType")
                        }
                      />
                    )}
                  </div>

                  <div className="inputFieldDivLeftCalls">
                    {callInfo.callType.value === "Outbound" ? (
                      <>
                        <p className="mb-0 mb-1" style={{ color: "#515151" }}>
                          Outgoing Call Status
                          <span className="text-danger">*</span>
                        </p>
                        <input
                          type="text"
                          className="insertCommonInputFieldCalls"
                          style={{ cursor: "not-allowed" }}
                          value={
                            modalView === "ScheduleCall"
                              ? "Scheduled"
                              : "Completed"
                          }
                        />
                      </>
                    ) : (
                      <>
                        <div className="inputFieldDivLeftCalls">
                          <p
                            className="mb-0 mb-1"
                            style={{
                              color: "#515151",
                            }}
                          >
                            Subject<span className="text-danger">*</span>
                          </p>
                          <input
                            type="text"
                            // className="insertCommonInputFieldCalls"
                            className={
                              mandatoryMsg
                                ? "mandatoryField w-100"
                                : "insertCommonInputFieldCalls"
                            }
                            name="subject"
                            value={callInfo.subject}
                            onChange={handleChangeCalls}
                            maxLength={100}
                          />
                          <p style={{ color: "#fa5a5a" }}>
                            {mandatoryMsg && mandatoryMsg}
                          </p>
                        </div>
                      </>
                    )}
                  </div>
                </div>
                <div className="inputFieldDivCalls mt-4 gap-3">
                  <div className="inputFieldDivLeftCalls">
                    <p className="mb-0 mb-1" style={{ color: "#515151" }}>
                      Call Start Date<span className="text-danger">*</span>
                    </p>
                    <input
                      type="date"
                      className="insertCommonInputFieldCalls"
                      name="callStartDate"
                      value={callInfo.callStartDate}
                      onChange={handleChangeCalls}
                      min={
                        modalView === "ScheduleCall"
                          ? new Date().toISOString().split("T")[0]
                          : new Date(
                              new Date().getFullYear() - 5,
                              new Date().getMonth(),
                              new Date().getDate()
                            )
                              .toISOString()
                              .split("T")[0]
                      }
                      max={
                        modalView === "ScheduleCall"
                          ? new Date(
                              new Date().getFullYear() + 1,
                              new Date().getMonth(),
                              new Date().getDate()
                            )
                              .toISOString()
                              .split("T")[0]
                          : new Date().toISOString().split("T")[0]
                      }
                      onKeyDown={(e) => e.preventDefault()}
                    />
                  </div>

                  <div className="inputFieldDivLeftCalls">
                    <p className="mb-1" style={{ color: "#515151" }}>
                      Call Start Time<span className="text-danger">*</span>
                    </p>
                    <div className="insertCommonInputFieldCalls d-flex gap-1">
                      <div className="hours_box position-relative">
                        <div
                          className="cursorPointer"
                          onClick={(e) => {
                            e.stopPropagation();
                            setShowCallStartTime({
                              hour: !showCallStartTime.hour,
                              minutes: false,
                            });
                          }}
                        >
                          {callStartTime.hour}
                        </div>
                        {showCallStartTime.hour ? (
                          <div className="hours_options position-absolute d-flex flex-column rounded-1">
                            {hours.map((hour, index) => {
                              return (
                                <span
                                  className="p-1 cursorPointer"
                                  onClick={() => {
                                    setCallStartTime({
                                      hour: hour,
                                      minutes: callStartTime.minutes,
                                      meridiem: callStartTime.meridiem,
                                    });
                                  }}
                                  key={index}
                                >
                                  {hour}
                                </span>
                              );
                            })}
                          </div>
                        ) : null}
                      </div>
                      <div>:</div>
                      <div className="minutes_box position-relative">
                        <div
                          className="cursorPointer"
                          onClick={(e) => {
                            e.stopPropagation();
                            setShowCallStartTime({
                              hour: false,
                              minutes: !showCallStartTime.minutes,
                            });
                          }}
                        >
                          {callStartTime.minutes}
                        </div>
                        {showCallStartTime.minutes ? (
                          <div className="hours_options position-absolute d-flex flex-column rounded-1">
                            {" "}
                            {minutes.map((minute, index) => {
                              return (
                                <span
                                  className="p-1 cursorPointer"
                                  onClick={() => {
                                    setCallStartTime({
                                      hour: callStartTime.hour,
                                      minutes: minute,
                                      meridiem: callStartTime.meridiem,
                                    });
                                  }}
                                  key={index}
                                >
                                  {minute}
                                </span>
                              );
                            })}
                          </div>
                        ) : null}
                      </div>
                      <div className="hours_box_type">
                        <div
                          className="cursorPointer"
                          onClick={(e) => {
                            e.stopPropagation();
                            setShowCallStartTime({
                              hour: false,
                              minutes: false,
                            });

                            setCallStartTime({
                              hour: callStartTime.hour,
                              minutes: callStartTime.minutes,
                              meridiem:
                                callStartTime.meridiem === "AM" ? "PM" : "AM",
                            });
                          }}
                        >
                          {callStartTime.meridiem}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="inputFieldDivCalls mt-4 gap-3">
                  <div className="inputFieldDivLeftCalls">
                    <p className="mb-0 mb-1" style={{ color: "#515151" }}>
                      {modalView === "ScheduleCall"
                        ? "Call Owner"
                        : callInfo.callType.value !== "Missed"
                        ? "Call Duration"
                        : ""}
                      {modalView === "ScheduleCall" ? (
                        <></>
                      ) : (
                        <>
                          {callInfo.callType.value !== "Missed" && (
                            <span className="text-danger">*</span>
                          )}
                        </>
                      )}
                    </p>
                    {modalView === "ScheduleCall" ? (
                      <>
                        <div className="d-flex input-with-clear ">
                          <input
                            type="text"
                            //   placeholder="Vendor Owner"
                            className="insertCommonInputFieldCalls cursorPointer"
                            value={selectedOwner?.name}
                            onClick={(e) => {
                              e.stopPropagation();
                              handleInputClick("Owner");
                              setDropdownSelectReminder(false);
                              setDropdownCallPurpose(false);
                              setDropdownCallResult(false);
                            }}
                            readOnly
                          />
                          {selectedOwner ? (
                            <span
                              className="clear-buttonCalls"
                              onClick={() => setSelectedOwner("")}
                            >
                              <img
                                src={down}
                                alt="img"
                                height={20}
                                width={20}
                              />
                            </span>
                          ) : (
                            <span
                              className="clear-buttonCalls"
                              // onClick={() => setSelectedOwner("")}
                            >
                              <img src={up} alt="img" height={20} width={20} />
                            </span>
                          )}
                          <span
                            className="cursorPointer pt-2 ps-1"
                            onClick={() => {
                              setIsLeadOwnerModal(!isLeadOwnerModal);
                            }}
                          >
                            <img
                              src={userSearch}
                              alt="img"
                              width={20}
                              height={20}
                            />
                          </span>

                          {listVisibleType === "Owner" && listVisible && (
                            <ul
                              className="data-list list-group  lead_ownerList_container position-absolute"
                              style={{
                                top: "3.25rem",
                                width: "21.5rem",
                                minHeight: "max-content",
                              }}
                            >
                              {userList.map((item, index) => (
                                <li
                                  key={index}
                                  className="cursorPointer list-group-item"
                                  onClick={() =>
                                    handleInputClickSet(item, "Owner")
                                  }
                                >
                                  <div className="d-flex flex-column">
                                    <span> {item.name} </span>
                                    <span> {item.emailId} </span>
                                  </div>
                                </li>
                              ))}
                            </ul>
                          )}
                        </div>
                      </>
                    ) : (
                      <>
                        {callInfo.callType.value !== "Missed" && (
                          <div
                            className="insertCommonInputFieldCalls d-flex gap-2"
                            style={{
                              width:
                                callInfo.callType.value === "Inbound"
                                  ? "50%"
                                  : "95%",
                            }}
                          >
                            <input
                              type="text"
                              // min={0}
                              // max={1}
                              maxLength={3}
                              style={{
                                border: "None",
                                width: "25px",
                              }}
                              placeholder="00"
                              name="minutes"
                              value={callInfo?.minutes}
                              onChange={handleChangeCalls}
                            />
                            <p>Minutes</p>
                            <input
                              type="text"
                              maxLength={2}
                              style={{
                                border: "None",
                                width: "25px",
                              }}
                              placeholder="00"
                              name="seconds"
                              value={callInfo.seconds}
                              onChange={handleChangeCalls}
                            />
                            <p>Seconds</p>
                          </div>
                        )}
                      </>
                    )}
                  </div>
                  {callInfo.callType.value === "Outbound" && (
                    <div className="inputFieldDivLeftCalls">
                      <p className="mb-0 mb-1" style={{ color: "#515151" }}>
                        Subject<span className="text-danger">*</span>
                      </p>
                      <input
                        type="text"
                        // className="insertCommonInputFieldCalls"
                        className={
                          mandatoryMsg
                            ? "mandatoryField w-100"
                            : "insertCommonInputFieldCalls"
                        }
                        name="subject"
                        value={callInfo.subject}
                        onChange={handleChangeCalls}
                      />
                      <p style={{ color: "#fa5a5a" }}>
                        {mandatoryMsg && mandatoryMsg}
                      </p>
                    </div>
                  )}
                </div>
                {modalView === "ScheduleCall" && (
                  <div className="inputFieldDivCalls mt-4 gap-3">
                    <div className="inputFieldDivLeftCalls w-50">
                      <p className="mb-0 mb-1" style={{ color: "#515151" }}>
                        Reminder
                      </p>
                      <div className="d-flex input-with-clear">
                        <input
                          className="insertCommonInputFieldCalls cursorPointer"
                          style={{ width: "93%" }}
                          value={callInfo.reminder.value}
                          onClick={(e) => {
                            e.stopPropagation();
                            setDropdownSelectReminder(!dropdownSelectReminder);
                            setListVisible(false);
                            setModuleListVisible(false);
                            setDropdownSelect(false);
                            setDropdownCallPurpose(false);
                            setDropdownCallResult(false);
                          }}
                          readOnly
                        />
                        {/* <Select
                      options={reminderOptions}
                      styles={createAccountStyles}
                      value={meetingInfo.participantsRemainder}
                      menuPlacement="auto"
                      onChange={(selectedOption) =>
                        handleChangeSelectDropDownMeetings(
                          selectedOption,
                          "participantsRemainder"
                        )
                      }
                    /> */}
                        {dropdownSelectReminder ? (
                          <>
                            <span
                              className="clear-buttonCalls"
                              onClick={() => setDropdownSelectReminder(false)}
                              style={{
                                top: "1.5rem",
                                left: "19.5rem",
                              }}
                            >
                              <img src={up} alt="img" height={20} width={20} />
                            </span>
                          </>
                        ) : (
                          <>
                            <span
                              className="clear-buttonCalls"
                              style={{
                                top: "1.5rem",
                                left: "19.5rem",
                              }}
                            >
                              <img
                                src={down}
                                alt="img"
                                height={20}
                                width={20}
                              />
                            </span>
                          </>
                        )}
                        {dropdownSelectReminder && (
                          <ul
                            className="data-list list-group  lead_ownerList_container position-absolute "
                            style={{
                              top: "3.25rem",
                              width: "21.9rem",
                              minHeight: "max-content",
                            }}
                          >
                            {reminderOptions.map((item, index) => (
                              <li
                                key={index}
                                className="cursorPointer list-group-item"
                                onClick={() => {
                                  handleChangeSelectDropDown(item, "reminder");
                                  setDropdownSelectReminder(false);
                                }}
                              >
                                <div className="d-flex flex-column">
                                  <span> {item.label} </span>
                                </div>
                              </li>
                            ))}
                          </ul>
                        )}
                      </div>
                    </div>
                  </div>
                )}

                <div className="d-flex justify-content-between pt-5 pb-2 rounded-2 relative">
                  <h4 className="commonTextColor">Purpose of Outgoing Call</h4>
                </div>
                <div className="columns_container pt-1 pb-3 overflow-y-visible">
                  <div className="inputFieldDivCalls mt-2 gap-3">
                    <div className="inputFieldDivLeftCalls">
                      <p className="mb-0 mb-1" style={{ color: "#515151" }}>
                        Call Purpose
                      </p>
                      {/* <Select
                        options={callPurposeOptions}
                        styles={createAccountStyles}
                        value={callInfo.callPurpose}
                        menuPlacement="auto"
                        onChange={(selectedOption) =>
                          handleChangeSelectDropDown(
                            selectedOption,
                            "callPurpose"
                          )
                        }
                      /> */}
                      <div className="d-flex input-with-clear">
                        <input
                          className="insertCommonInputFieldCalls cursorPointer"
                          value={callInfo.callPurpose.value}
                          onClick={(e) => {
                            e.stopPropagation();
                            setDropdownCallPurpose(!dropdownCallPurpose);
                            setDropdownSelectReminder(false);
                            setListVisible(false);
                            setModuleListVisible(false);
                            setDropdownCallResult(false);
                            setDropdownSelect(false);
                          }}
                          readOnly
                        />
                        {dropdownCallPurpose ? (
                          <>
                            <span
                              className="clear-buttonCalls"
                              onClick={() => setDropdownSelectReminder(false)}
                              style={{
                                top: "1.5rem",
                                left: "19.5rem",
                              }}
                            >
                              <img src={up} alt="img" height={20} width={20} />
                            </span>
                          </>
                        ) : (
                          <>
                            <span
                              className="clear-buttonCalls"
                              style={{
                                top: "1.5rem",
                                left: "19.5rem",
                              }}
                            >
                              <img
                                src={down}
                                alt="img"
                                height={20}
                                width={20}
                              />
                            </span>
                          </>
                        )}
                        {dropdownCallPurpose && (
                          <ul
                            className="data-list list-group  lead_ownerList_container position-absolute "
                            style={{
                              top: "3.25rem",
                              width: "21.9rem",
                              minHeight: "max-content",
                            }}
                          >
                            {callPurposeOptions.map((item, index) => (
                              <li
                                key={index}
                                className="cursorPointer list-group-item"
                                onClick={() => {
                                  handleChangeSelectDropDown(
                                    item,
                                    "callPurpose"
                                  );
                                  setDropdownSelectReminder(false);
                                  setDropdownCallPurpose(false);
                                }}
                              >
                                <div className="d-flex flex-column">
                                  <span> {item.label} </span>
                                </div>
                              </li>
                            ))}
                          </ul>
                        )}
                      </div>
                    </div>

                    <div className="inputFieldDivLeftCalls">
                      <p className="mb-0 mb-1" style={{ color: "#515151" }}>
                        Call Agenda
                      </p>
                      <input
                        type="text"
                        className="insertCommonInputFieldCalls"
                        name="callAgenda"
                        value={callInfo.callAgenda}
                        onChange={handleChangeCalls}
                        autoComplete="off"
                        onPaste={(e) => e.preventDefault()}
                        onCopy={(e) => e.preventDefault()}
                      />
                    </div>
                  </div>
                </div>
                {modalView === "LogCall" && (
                  <>
                    <div className="d-flex justify-content-between pt-5 pb-2 rounded-2 relative">
                      <h4 className="commonTextColor">
                        Outcome Of Outgoing Call
                      </h4>
                    </div>
                    <div className="columns_container pt-1 pb-3 overflow-y-visible">
                      <div className="inputFieldDivCalls mt-2 gap-3">
                        <div className="inputFieldDivLeftCalls">
                          <p className="mb-0 mb-1" style={{ color: "#515151" }}>
                            Call Result
                          </p>
                          {/* <Select
                            options={callResultOptions}
                            styles={createAccountStyles}
                            value={callInfo.callResult}
                            menuPlacement="auto"
                            onChange={(selectedOption) =>
                              handleChangeSelectDropDown(
                                selectedOption,
                                "callResult"
                              )
                            }
                          /> */}
                          <div className="d-flex input-with-clear">
                            <input
                              className="insertCommonInputFieldCalls cursorPointer"
                              value={callInfo.callResult.value}
                              onClick={(e) => {
                                e.stopPropagation();
                                setDropdownCallResult(!dropdownCallResult);
                                setDropdownSelectReminder(false);
                                setListVisible(false);
                                setDropdownSelect(false);
                                setDropdownCallPurpose(false);
                                setModuleListVisible(false);
                              }}
                              readOnly
                            />
                            {dropdownCallResult ? (
                              <>
                                <span
                                  className="clear-buttonCalls"
                                  onClick={() => setDropdownCallResult(false)}
                                  style={{
                                    top: "1.5rem",
                                    left: "19.5rem",
                                  }}
                                >
                                  <img
                                    src={up}
                                    alt="img"
                                    height={20}
                                    width={20}
                                  />
                                </span>
                              </>
                            ) : (
                              <>
                                <span
                                  className="clear-buttonCalls"
                                  style={{
                                    top: "1.5rem",
                                    left: "19.5rem",
                                  }}
                                >
                                  <img
                                    src={down}
                                    alt="img"
                                    height={20}
                                    width={20}
                                  />
                                </span>
                              </>
                            )}
                            {dropdownCallResult && (
                              <ul
                                className="data-list list-group  lead_ownerList_container position-absolute "
                                style={{
                                  top: "3.25rem",
                                  width: "21.9rem",
                                  minHeight: "max-content",
                                }}
                              >
                                {callResultOptions.map((item, index) => (
                                  <li
                                    key={index}
                                    className="cursorPointer list-group-item"
                                    onClick={() => {
                                      handleChangeSelectDropDown(
                                        item,
                                        "callResult"
                                      );
                                      setDropdownSelectReminder(false);
                                      setDropdownCallPurpose(false);
                                      setDropdownCallResult(false);
                                    }}
                                  >
                                    <div className="d-flex flex-column">
                                      <span> {item.label} </span>
                                    </div>
                                  </li>
                                ))}
                              </ul>
                            )}
                          </div>
                        </div>

                        <div className="inputFieldDivLeftCalls">
                          <p className="mb-0 mb-1" style={{ color: "#515151" }}>
                            Description
                          </p>
                          <input
                            type="text"
                            className="insertCommonInputFieldCalls"
                            name="description"
                            value={callInfo.description}
                            onChange={handleChangeCalls}
                            placeholder="Description"
                          />
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </div>
              <aside className="float-end mt-2">
                <div className="d-flex align-items-center gap-2 px-4 pt-2 pb-3 mb-3">
                  <button
                    className="cancel_btn bg-light rounded-2 ms-2"
                    style={{
                      paddingTop: "6px",
                      paddingBottom: "6px",
                      width: "100px",
                    }}
                    onClick={() => {
                      setShowModalType("");
                      setCallInfo(callInformation);
                      // setSelectedOptionLead(leadContactOptions[0]);
                      setSelectedOptionModule(relatedToOptions[0]);
                      setCallStartTime({
                        hour: "00",
                        minutes: "00",
                        meridiem: "AM",
                      });
                    }}
                  >
                    Cancel
                  </button>
                  <button
                    className="save_btn py-2 border border-0 rounded-2"
                    style={{ width: "122px" }}
                    onClick={(e) => {
                      setCheckMeetingCalls("calls");
                      modalView === "ScheduleCall"
                        ? handleCheckCalls(e)
                        : handleInsertCalls(e);
                    }}
                    disabled={apiLoader}
                  >
                    {modalView === "ScheduleCall" ? (
                      <>
                        {apiLoader ? (
                          <Lottie
                            options={defaultOptions("white")}
                            height="24px"
                            width="36px"
                          />
                        ) : (
                          "Schedule"
                        )}
                      </>
                    ) : (
                      <>
                        {apiLoader ? (
                          <Lottie
                            options={defaultOptions("white")}
                            height="24px"
                            width="36px"
                          />
                        ) : (
                          "Save"
                        )}
                      </>
                    )}
                  </button>
                </div>
              </aside>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      {/* Meeting */}
      <Modal
        show={modalType === "Meetings"}
        id="ConvertModal"
        aria-labelledby="example-custom-modal-styling-title"
        className={`withdrw_modal rounded-2 modal-lg ${
          overlapModal ? "opacity-75" : ""
        }`}
        onClick={(e) => {
          e.stopPropagation();
          setListVisible(false);
          setModuleListVisible(false);
          setShowFromTime({
            hour: false,
            minutes: false,
          });
          setShowToTime({
            hour: false,
            minutes: false,
          });
          setDropdownSelect(false);
          setDropdownRelatedTo(false);
          setDropdownSelectReminder(false);
        }}
      >
        <Modal.Body className="p-0 rounded-2">
          <div className="modalClass position-relative">
            <div
              className="d-flex justify-content-between px-4 pt-4 pb-2 rounded-2"
              style={{ background: "rgba(242, 242, 242, 1)" }}
            >
              <h4 className="commonTextColor">Meeting Information</h4>
              <img
                src={cancelCircle}
                alt="cancel"
                style={{
                  width: "25px",
                  height: "25px",
                  cursor: "pointer",
                }}
                onClick={() => {
                  setShowModalType("");
                }}
              />
            </div>
            <div className="w-100 position-relative">
              <div className="columns_container ps-4 pe-2 pt-2 pb-3">
                <div className="inputFieldDivCalls mt-2 gap-3">
                  <div className="inputFieldDivLeftCalls">
                    <p className="mb-0 mb-1" style={{ color: "#515151" }}>
                      Title<span className="text-danger">*</span>
                    </p>
                    <input
                      type="text"
                      className={
                        mandatoryMsg
                          ? "mandatoryField"
                          : "insertCommonInputFieldCalls"
                      }
                      style={{ width: mandatoryMsg ? "95%" : "95%" }}
                      name="title"
                      value={meetingInfo.title}
                      onChange={handleChangeMeetings}
                      placeholder="Enter a Title"
                    />
                    {mandatoryMsg && (
                      <p style={{ color: "#fa5a5a" }}>
                        {mandatoryMsg && mandatoryMsg}
                      </p>
                    )}
                    <div className="mt-1">
                      <input
                        type="checkbox"
                        checked={isAllDay}
                        onChange={handleCheckboxChange}
                      />
                      <label className="ms-1">All Day</label>
                    </div>
                  </div>

                  <div className="inputFieldDivLeftCalls">
                    <p className="mb-0 mb-1" style={{ color: "#515151" }}>
                      Location
                    </p>
                    <input
                      type="text"
                      className="insertCommonInputFieldCalls"
                      name="location"
                      maxLength={80}
                      value={meetingInfo.location}
                      onChange={handleChangeMeetings}
                      placeholder="Enter Location"
                    />
                    <div className="mt-1">
                      <input
                        type="checkbox"
                        // checked={isAllDay}
                        // onChange={handleCheckboxChange}
                      />

                      <label className="ms-2">
                        Make this an online meeting
                      </label>
                    </div>
                  </div>
                </div>
                <div className="inputFieldDivCalls mt-4 gap-3">
                  <div className="inputFieldDivLeftCalls">
                    <div className={isAllDay ? "" : "d-flex"}>
                      <div className={isAllDay ? "w-100" : "w-50"}>
                        <p className="mb-0 mb-1" style={{ color: "#515151" }}>
                          From Date<span className="text-danger">*</span>
                        </p>
                        <input
                          type="date"
                          className="insertCommonInputFieldCalls"
                          name="fromDate"
                          value={meetingInfo.fromDate}
                          onChange={handleChangeMeetings}
                          min={new Date().toISOString().split("T")[0]}
                          max={
                            new Date(
                              new Date().getFullYear() + 3,
                              new Date().getMonth(),
                              new Date().getDate()
                            )
                              .toISOString()
                              .split("T")[0]
                          }
                          onKeyDown={(e) => e.preventDefault()}
                        />
                      </div>
                      <div className="w-50">
                        {!isAllDay && (
                          <>
                            <p
                              className="mb-0 mb-1"
                              style={{ color: "#515151" }}
                            >
                              From Time<span className="text-danger">*</span>
                            </p>
                            <div className="insertCommonInputFieldCalls d-flex gap-2">
                              <div className="hours_box position-relative">
                                <div
                                  className="cursorPointer"
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    setShowToTime({
                                      hour: false,
                                      minutes: false,
                                    });
                                    setListVisible(false);
                                    setListVisibleType("");
                                    setModuleListVisible(false);
                                    setDropdownSelect(false);
                                    setDropdownRelatedTo(false);
                                    setDropdownSelectReminder(false);
                                    setShowFromTime({
                                      hour: !showFromTime.hour,
                                      minutes: false,
                                    });
                                  }}
                                >
                                  {fromTimeSet.hour}
                                </div>
                                {showFromTime.hour ? (
                                  <div className="hours_options position-absolute d-flex flex-column rounded-1">
                                    {hours.map((hour, index) => {
                                      return (
                                        <span
                                          className="p-1 cursorPointer"
                                          onClick={() => {
                                            setFromTimeSet({
                                              hour: hour,
                                              minutes: fromTimeSet.minutes,
                                              meridiem: fromTimeSet.meridiem,
                                            });
                                          }}
                                          key={index}
                                        >
                                          {hour}
                                        </span>
                                      );
                                    })}
                                  </div>
                                ) : null}
                              </div>
                              <div>:</div>
                              <div className="minutes_box position-relative">
                                <div
                                  className="cursorPointer"
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    setShowToTime({
                                      hour: false,
                                      minutes: false,
                                    });
                                    setListVisible(false);
                                    setListVisible(false);
                                    setDropdownRelatedTo(false);
                                    setDropdownSelect(false);
                                    setDropdownSelectReminder(false);
                                    setListVisibleType("");
                                    setShowFromTime({
                                      hour: false,
                                      minutes: !showFromTime.minutes,
                                    });
                                  }}
                                >
                                  {fromTimeSet.minutes}
                                </div>
                                {showFromTime.minutes ? (
                                  <div className="hours_options position-absolute d-flex flex-column rounded-1">
                                    {" "}
                                    {minutes.map((minute, index) => {
                                      return (
                                        <span
                                          className="p-1 cursorPointer"
                                          onClick={() => {
                                            setFromTimeSet({
                                              hour: fromTimeSet.hour,
                                              minutes: minute,
                                              meridiem: fromTimeSet.meridiem,
                                            });
                                          }}
                                          key={index}
                                        >
                                          {minute}
                                        </span>
                                      );
                                    })}
                                  </div>
                                ) : null}
                              </div>
                              <div>:</div>
                              <div className="hours_box_type">
                                <div
                                  className="cursorPointer"
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    setShowFromTime({
                                      hour: false,
                                      minutes: false,
                                    });

                                    setFromTimeSet({
                                      hour: fromTimeSet.hour,
                                      minutes: fromTimeSet.minutes,
                                      meridiem:
                                        fromTimeSet.meridiem === "AM"
                                          ? "PM"
                                          : "AM",
                                    });
                                  }}
                                >
                                  {fromTimeSet.meridiem}
                                </div>
                              </div>
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="inputFieldDivLeftCalls">
                    <div className={isAllDay ? "" : "d-flex"}>
                      <div className={isAllDay ? "w-100" : "w-50"}>
                        <p className="mb-0 mb-1" style={{ color: "#515151" }}>
                          To Date<span className="text-danger">*</span>
                        </p>
                        <input
                          type="date"
                          className="insertCommonInputFieldCalls"
                          name="toDate"
                          value={meetingInfo.toDate}
                          onChange={handleChangeMeetings}
                          min={new Date().toISOString().split("T")[0]}
                          max={
                            new Date(
                              new Date().getFullYear() + 3,
                              new Date().getMonth(),
                              new Date().getDate()
                            )
                              .toISOString()
                              .split("T")[0]
                          }
                          onKeyDown={(e) => e.preventDefault()}
                        />
                      </div>
                      <div className="w-50">
                        {!isAllDay && (
                          <>
                            <p
                              className="mb-0 mb-1"
                              style={{ color: "#515151" }}
                            >
                              To Time<span className="text-danger">*</span>
                            </p>
                            <div className="insertCommonInputFieldCalls d-flex gap-2">
                              <div className="hours_box position-relative">
                                <div
                                  className="cursorPointer"
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    setShowFromTime({
                                      hour: false,
                                      minutes: false,
                                    });
                                    setDropdownSelect(false);
                                    setDropdownRelatedTo(false);
                                    setDropdownSelectReminder(false);
                                    setListVisible(false);
                                    setListVisibleType("");
                                    setShowToTime({
                                      hour: !showToTime.hour,
                                      minutes: false,
                                    });
                                  }}
                                >
                                  {toTimeSet.hour}
                                </div>
                                {showToTime.hour ? (
                                  <div className="hours_options position-absolute d-flex flex-column rounded-1">
                                    {hours.map((hour, index) => {
                                      return (
                                        <span
                                          className="p-1 cursorPointer"
                                          onClick={() => {
                                            setToTimeSet({
                                              hour: hour,
                                              minutes: toTimeSet.minutes,
                                              meridiem: toTimeSet.meridiem,
                                            });
                                          }}
                                          key={index}
                                        >
                                          {hour}
                                        </span>
                                      );
                                    })}
                                  </div>
                                ) : null}
                              </div>
                              <div>:</div>
                              <div className="minutes_box position-relative">
                                <div
                                  className="cursorPointer"
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    setShowFromTime({
                                      hour: false,
                                      minutes: false,
                                    });
                                    setListVisible(false);
                                    setListVisibleType("");
                                    setShowToTime({
                                      hour: false,
                                      minutes: !showToTime.minutes,
                                    });
                                  }}
                                >
                                  {toTimeSet.minutes}
                                </div>
                                {showToTime.minutes ? (
                                  <div className="hours_options position-absolute d-flex flex-column rounded-1">
                                    {" "}
                                    {minutes.map((minute, index) => {
                                      return (
                                        <span
                                          className="p-1 cursorPointer"
                                          onClick={() => {
                                            setToTimeSet({
                                              hour: toTimeSet.hour,
                                              minutes: minute,
                                              meridiem: toTimeSet.meridiem,
                                            });
                                          }}
                                          key={index}
                                        >
                                          {minute}
                                        </span>
                                      );
                                    })}
                                  </div>
                                ) : null}
                              </div>
                              <div>:</div>
                              <div className="hours_box_type">
                                <div
                                  className="cursorPointer"
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    setShowToTime({
                                      hour: false,
                                      minutes: false,
                                    });
                                    setToTimeSet({
                                      hour: toTimeSet.hour,
                                      minutes: toTimeSet.minutes,
                                      meridiem:
                                        toTimeSet.meridiem === "AM"
                                          ? "PM"
                                          : "AM",
                                    });
                                  }}
                                >
                                  {toTimeSet.meridiem}
                                </div>
                              </div>
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="inputFieldDivCalls mt-4 gap-3">
                  <div className="inputFieldDivLeftCalls">
                    <p className="mb-0 mb-1" style={{ color: "#515151" }}>
                      Host
                    </p>
                    <div className="d-flex input-with-clear">
                      <input
                        type="text"
                        //   placeholder="Vendor Owner"
                        className="insertCommonInputFieldCalls cursorPointer"
                        value={selectedOwner?.name}
                        onClick={(e) => {
                          e.stopPropagation();
                          setDropdownSelect(false);
                          setDropdownSelectReminder(false);
                          setDropdownRelatedTo(false);
                          setShowFromTime({
                            hour: false,
                            minutes: false,
                          });
                          setShowToTime({
                            hour: false,
                            minutes: false,
                          });
                          handleInputClick("Owner");
                        }}
                        readOnly
                      />
                      <span
                        className="clear-buttonCalls"
                        onClick={(e) => {
                          e.stopPropagation();
                          handleInputClick("Owner");
                          setListVisible(false);
                          setModuleListVisible(false);
                        }}
                      >
                        <img
                          src={listVisible ? up : down}
                          alt="img"
                          height={20}
                          width={20}
                        />
                      </span>
                      <span
                        className="cursorPointer pt-2 ps-1"
                        onClick={() => {
                          setIsLeadOwnerModal(!isLeadOwnerModal);
                        }}
                      >
                        <img
                          src={userSearch}
                          alt="img"
                          width={20}
                          height={20}
                        />
                      </span>
                      {listVisibleType === "Owner" && listVisible && (
                        <ul
                          className="data-list list-group  lead_ownerList_container position-absolute"
                          style={{
                            top: "3.2rem",
                            width: "95%",
                            minHeight: "max-content",
                          }}
                        >
                          {userList.map((item, index) => (
                            <li
                              key={index}
                              className="cursorPointer list-group-item"
                              onClick={() => {
                                setListVisible(false);
                                setModuleListVisible(false);
                                handleInputClickSet(item, "Owner");
                              }}
                            >
                              <div className="d-flex flex-column">
                                <span> {item?.name} </span>
                                <span> {item.emailId} </span>
                              </div>
                            </li>
                          ))}
                        </ul>
                      )}
                    </div>
                  </div>

                  <div className="inputFieldDivLeftCalls">
                    <p className="mb-0 mb-1" style={{ color: "#515151" }}>
                      Participants
                    </p>
                    <div
                      className="input-container d-flex ps-2"
                      style={{
                        borderRadius: "5px",
                        border: "1px solid #D9D9D9",
                        justifyContent: "space-between",
                        width: "95%",
                        height: "49px",
                      }}
                    >
                      <input
                        type="text"
                        className="insertCommonInputFieldCalls p-0 mb-1"
                        style={{ border: "none", width: "70%" }}
                        placeholder={selectedItems.length + emailList.length}
                        readOnly
                      />
                      <div
                        className="m-0"
                        style={{
                          color: "#407BFF",
                          cursor: "pointer",
                          fontSize: "35px",
                          backgroundColor: "#0000000D",
                          width: "15%",
                          textAlign: "-webkit-center",
                        }}
                        onClick={() => {
                          handleSubSelect("AddParticipants");
                          setShowModalType("");
                        }}
                      >
                        <img
                          src={addIcon}
                          alt=""
                          width={26}
                          style={{ verticalAlign: "initial" }}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="inputFieldDivCalls mt-4 gap-3">
                  <div className="inputFieldDivLeftCalls">
                    <p className="mb-0 mb-1" style={{ color: "#515151" }}>
                      Related To
                    </p>
                    <Select
                      options={leadContactOptionsMeetings}
                      styles={createAccountStyles}
                      value={selectedOptionLead}
                      onChange={handleChangeLead}
                    />
                  </div>
                  <div className="inputFieldDivLeftCalls">
                    <p className="mb-0 mb-1" style={{ color: "#515151" }}>
                      Repeat
                    </p>
                    <div
                      className="input-container d-flex ps-2"
                      style={{
                        borderRadius: "5px",
                        border: "1px solid #D9D9D9",
                        justifyContent: "space-between",
                        width: "95%",
                        height: "49px",
                      }}
                    >
                      <input
                        type="text"
                        className="insertCommonInputFieldCalls mb-1 p-0"
                        style={{ border: "none", width: "70%" }}
                        placeholder={selectedOptionRepeat.value}
                        // onChange={handleEmailChange}
                        readOnly
                      />
                      <div
                        className="m-0"
                        style={{
                          color: "#407BFF",
                          cursor: "pointer",
                          fontSize: "35px",
                          backgroundColor: "#0000000D",
                          width: "15%",
                          textAlign: "-webkit-center",
                        }}
                        onClick={() => {
                          handleSubSelect("Reset");
                          setShowModalType("");
                        }}
                      >
                        <img
                          src={editIcon1}
                          alt=""
                          width={20}
                          style={{
                            verticalAlign: "initial",
                            marginBottom: "5px",
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                {(selectedOptionLead.value === "Contact" ||
                  selectedOptionLead.value === "Lead") && (
                  <div className="inputFieldDivCalls mt-4 gap-3">
                    <div className="inputFieldDivLeftCalls">
                      <p className="mb-0 mb-1" style={{ color: "#515151" }}>
                        Selected Relation
                      </p>
                      <input
                        type="text"
                        className="insertCommonInputFieldCalls cursorPointer"
                        value={selectedOptionLead.value}
                        disabled
                      />
                    </div>
                    <div className="inputFieldDivLeftCalls">
                      <p className="mb-0 mb-1" style={{ color: "#515151" }}>
                        {selectedOptionLead.value}
                      </p>

                      {selectedOptionLead.value === "Contact" && (
                        <div className="d-flex input-with-clear ">
                          <input
                            type="text"
                            //   placeholder="Vendor Owner"
                            className="insertCommonInputFieldCalls cursorPointer"
                            value={selectedContact?.contactName}
                            onClick={(e) => {
                              e.stopPropagation();
                              handleInputClick("Contact");
                              setDropdownSelect(false);
                              setDropdownSelectReminder(false);
                              setDropdownRelatedTo(false);
                            }}
                            readOnly
                          />
                          <span
                            className="clear-buttonCalls"
                            onClick={(e) => {
                              e.stopPropagation();
                              handleInputClick("Contact");
                              setDropdownSelect(false);
                              setDropdownSelectReminder(false);
                              setDropdownRelatedTo(false);
                            }}
                          >
                            <img
                              src={listVisible ? up : down}
                              alt="img"
                              height={20}
                              width={20}
                            />
                          </span>
                          <span
                            className="cursorPointer pt-2 ps-1"
                            onClick={() => setIsContactListModal(true)}
                          >
                            <img
                              src={userSearch}
                              alt="img"
                              width={20}
                              height={20}
                            />
                          </span>
                          {listVisibleType === "Contact" && listVisible && (
                            <ul
                              className="data-list list-group  lead_ownerList_container position-absolute"
                              style={{
                                top: "3.2rem",
                                width: "95%",
                                minHeight: "max-content",
                              }}
                            >
                              {contactList.map((item, index) => (
                                <li
                                  key={index}
                                  className="cursorPointer list-group-item"
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    handleInputClickSet(item, "Contacts");
                                    setDropdownSelect(false);
                                    setDropdownRelatedTo(false);
                                    setDropdownSelectReminder(false);
                                    setListVisibleType("");
                                  }}
                                >
                                  <div className="d-flex flex-column">
                                    <span> {item?.contactName} </span>
                                  </div>
                                </li>
                              ))}
                            </ul>
                          )}
                        </div>
                      )}
                      {selectedOptionLead.value === "Lead" && (
                        <div className="d-flex input-with-clear ">
                          <input
                            type="text"
                            //   placeholder="Vendor Owner"
                            className="insertCommonInputFieldCalls cursorPointer"
                            value={selectedLeadItem?.leadName || ""}
                            onClick={(e) => {
                              e.stopPropagation();
                              handleInputClick("Lead");
                              setDropdownSelect(false);
                              setDropdownSelectReminder(false);
                              setDropdownRelatedTo(false);
                            }}
                            readOnly
                          />
                          <span
                            className="clear-buttonCalls"
                            onClick={(e) => {
                              e.stopPropagation();
                              handleInputClick("Lead");
                            }}
                          >
                            <img
                              src={listVisible ? up : down}
                              alt="img"
                              height={20}
                              width={20}
                            />
                          </span>
                          <span
                            className="cursorPointer pt-2 ps-1"
                            onClick={() => setIsLeadListModal(true)}
                          >
                            <img
                              src={userSearch}
                              alt="img"
                              width={20}
                              height={20}
                            />
                          </span>

                          {listVisibleType === "Lead" && listVisible && (
                            <ul
                              className="data-list list-group  lead_ownerList_container position-absolute"
                              style={{
                                top: "3.2rem",
                                width: "95%",
                                minHeight: "max-content",
                              }}
                            >
                              {leadList.map((item, index) => (
                                <li
                                  key={index}
                                  className="cursorPointer list-group-item"
                                  onClick={() => {
                                    handleInputClickSet(item, "Lead");
                                    setDropdownSelect(false);
                                    setDropdownRelatedTo(false);
                                    setDropdownSelectReminder(false);
                                  }}
                                >
                                  <div className="d-flex flex-column">
                                    <span> {item.leadName} </span>
                                  </div>
                                </li>
                              ))}
                            </ul>
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                )}
                {(selectedOptionLead.value === "Contact" ||
                  selectedOptionLead.value === "Others") && (
                  <>
                    {" "}
                    <div className="inputFieldDivCalls mt-4 gap-3">
                      <div className="inputFieldDivLeftCalls">
                        <p className="mb-0 mb-1" style={{ color: "#515151" }}>
                          Related To Module
                        </p>
                        {/* <Select
                          options={relatedToOptions}
                          styles={createAccountStyles}
                          value={selectedOptionModule}
                          onChange={handleChangeModule}
                          isDisabled={selectedOptionLead.value === "Lead"}
                        /> */}
                        <div className="d-flex input-with-clear">
                          <input
                            className="insertCommonInputFieldCalls cursorPointer"
                            value={selectedOptionModule.label}
                            onClick={(e) => {
                              e.stopPropagation();
                              setDropdownCallPurpose(false);
                              setDropdownRelatedTo(!dropdownRelatedTo);
                              setDropdownSelectReminder(false);
                              setListVisible(false);
                              setModuleListVisible(false);
                              setDropdownCallResult(false);
                              setDropdownSelect(false);
                            }}
                            readOnly
                            disabled={selectedOptionLead.value === "Lead"}
                          />
                          {dropdownRelatedTo ? (
                            <>
                              <span
                                className="clear-buttonCalls"
                                onClick={() => setDropdownRelatedTo(false)}
                                style={{
                                  top: "1.5rem",
                                  left: "19.5rem",
                                }}
                              >
                                <img
                                  src={up}
                                  alt="img"
                                  height={20}
                                  width={20}
                                />
                              </span>
                            </>
                          ) : (
                            <>
                              <span
                                className="clear-buttonCalls"
                                style={{
                                  top: "1.5rem",
                                  left: "19.5rem",
                                }}
                              >
                                <img
                                  src={down}
                                  alt="img"
                                  height={20}
                                  width={20}
                                />
                              </span>
                            </>
                          )}
                          {dropdownRelatedTo && (
                            <ul
                              className="data-list list-group  lead_ownerList_container position-absolute "
                              style={{
                                top: "3.25rem",
                                width: "21.9rem",
                                minHeight: "max-content",
                              }}
                            >
                              {relatedToOptions.map((item, index) => (
                                <li
                                  key={index}
                                  className="cursorPointer list-group-item"
                                  onClick={() => {
                                    handleChangeModule(item);
                                    setDropdownRelatedTo(false);
                                    setDropdownCallPurpose(false);
                                    setSelectedOptionModule(item);
                                  }}
                                >
                                  <div className="d-flex flex-column">
                                    <span> {item.label} </span>
                                  </div>
                                </li>
                              ))}
                            </ul>
                          )}
                        </div>
                      </div>
                      <div className="inputFieldDivLeftCalls position-relative">
                        <p className="mb-0 mb-1">
                          {selectedOptionModule.label}
                        </p>
                        <div className="d-flex input-with-clear ">
                          <input
                            type="text"
                            className="insertCommonInputFieldCalls cursorPointer"
                            value={
                              selectedOptionLead.value === "Lead"
                                ? ""
                                : moduleWiseData.length > 0
                                ? selectedModuleItem.moduleName
                                : ""
                            }
                            disabled={selectedOptionLead.value === "Lead"}
                            onClick={handleModuleClick}
                            readOnly
                          />
                          {selectedOptionLead.value === "Lead" ? (
                            <span className="clear-buttonCalls">
                              <img
                                src={moduleListVisible ? up : down}
                                alt="img"
                                height={20}
                                width={20}
                              />{" "}
                            </span>
                          ) : (
                            <span
                              className="clear-buttonCalls"
                              onClick={handleModuleClick}
                            >
                              <img
                                src={moduleListVisible ? up : down}
                                alt="img"
                                height={20}
                                width={20}
                              />{" "}
                            </span>
                          )}
                        </div>
                        {moduleListVisible && moduleWiseData.length > 0 && (
                          <ul
                            className="data-list list-group  lead_ownerList_container position-absolute "
                            // style={{
                            //   top: "200px",
                            //    width: "27.5vw",
                            // }}

                            style={{
                              top: "4.9rem",
                              width: "21.8rem",
                              minHeight: "max-content",
                            }}
                          >
                            {" "}
                            {moduleWiseData.map((item, index) => (
                              <li
                                key={index}
                                className="cursorPointer list-group-item"
                                onClick={() => handleModuleItemClick(item)}
                              >
                                {item.moduleName}
                              </li>
                            ))}
                          </ul>
                        )}
                      </div>
                    </div>
                  </>
                )}
                <div
                  className="inputFieldDivCalls mt-4 gap-3"
                  style={{ alignItems: "self-start" }}
                >
                  <div className="inputFieldDivLeftCalls">
                    <p className="mb-0 mb-1" style={{ color: "#515151" }}>
                      Participants Reminder
                    </p>
                    <div className="d-flex input-with-clear">
                      <input
                        className="insertCommonInputFieldCalls cursorPointer"
                        value={meetingInfo.participantsRemainder.value}
                        onClick={(e) => {
                          e.stopPropagation();
                          setDropdownSelect(!dropdownSelect);
                          setDropdownSelectReminder(false);
                          setListVisible(false);
                          setModuleListVisible(false);
                          setDropdownRelatedTo(false);
                        }}
                        readOnly
                      />
                      {/* <Select
                      options={reminderOptions}
                      styles={createAccountStyles}
                      value={meetingInfo.participantsRemainder}
                      menuPlacement="auto"
                      onChange={(selectedOption) =>
                        handleChangeSelectDropDownMeetings(
                          selectedOption,
                          "participantsRemainder"
                        )
                      }
                    /> */}
                      {dropdownSelect ? (
                        <span
                          className="clear-buttonCalls"
                          onClick={(e) => {
                            e.stopPropagation();
                            setDropdownSelect(false);
                            setListVisible(false);
                            setModuleListVisible(false);
                            setDropdownRelatedTo(false);
                            setDropdownSelectReminder(false);
                          }}
                          style={{
                            top: "1.5rem",
                            left: "19.5rem",
                          }}
                        >
                          <img src={up} alt="img" height={20} width={20} />
                        </span>
                      ) : (
                        <span
                          className="clear-buttonCalls"
                          style={{
                            top: "1.5rem",
                            left: "19.5rem",
                          }}
                        >
                          <img src={down} alt="img" height={20} width={20} />
                        </span>
                      )}
                      {dropdownSelect && (
                        <ul
                          className="data-list list-group  lead_ownerList_container position-absolute "
                          style={{
                            top: "3.25rem",
                            width: "21.9rem",
                            minHeight: "max-content",
                          }}
                        >
                          {reminderOptions.map((item, index) => (
                            <li
                              key={index}
                              className="cursorPointer list-group-item"
                              onClick={(e) => {
                                e.stopPropagation();
                                handleInputClickSet(
                                  item,
                                  "participantsRemainder"
                                );
                                setDropdownSelect(false);
                                setDropdownRelatedTo(false);
                                setListVisible(false);
                                setModuleListVisible(false);
                                setDropdownSelectReminder(false);
                              }}
                            >
                              <div className="d-flex flex-column">
                                <span> {item.label} </span>
                              </div>
                            </li>
                          ))}
                        </ul>
                      )}
                    </div>
                    <p className="mb-0 mb-1 mt-4" style={{ color: "#515151" }}>
                      Reminder
                    </p>
                    <div>
                      {/* <Select
                      options={reminderOptions}
                      value={meetingInfo.remainder}
                      styles={createAccountStyles}
                      menuPlacement="auto"
                      onChange={(selectedOption) =>
                        handleChangeSelectDropDownMeetings(
                          selectedOption,
                          "remainder"
                        )
                      }
                    /> */}
                      <div className="d-flex input-with-clear">
                        <input
                          className="insertCommonInputFieldCalls cursorPointer"
                          value={meetingInfo.remainder.value}
                          onClick={(e) => {
                            e.stopPropagation();
                            setDropdownSelectReminder(!dropdownSelectReminder);
                            setListVisible(false);
                            setModuleListVisible(false);
                            setDropdownSelect(false);
                            setDropdownRelatedTo(false);
                          }}
                          readOnly
                        />
                        {/* <Select
                      options={reminderOptions}
                      styles={createAccountStyles}
                      value={meetingInfo.participantsRemainder}
                      menuPlacement="auto"
                      onChange={(selectedOption) =>
                        handleChangeSelectDropDownMeetings(
                          selectedOption,
                          "participantsRemainder"
                        )
                      }
                    /> */}
                        {dropdownSelectReminder ? (
                          <>
                            <span
                              className="clear-buttonCalls"
                              onClick={() => setDropdownSelectReminder(false)}
                              style={{
                                top: "1.5rem",
                                left: "19.5rem",
                              }}
                            >
                              <img src={up} alt="img" height={20} width={20} />
                            </span>
                          </>
                        ) : (
                          <>
                            <span
                              className="clear-buttonCalls"
                              style={{
                                top: "1.5rem",
                                left: "19.5rem",
                              }}
                            >
                              <img
                                src={down}
                                alt="img"
                                height={20}
                                width={20}
                              />
                            </span>
                          </>
                        )}
                        {dropdownSelectReminder && (
                          <ul
                            className="data-list list-group  lead_ownerList_container position-absolute "
                            style={{
                              top: "3.25rem",
                              width: "21.9rem",
                              minHeight: "max-content",
                            }}
                          >
                            {reminderOptions.map((item, index) => (
                              <li
                                key={index}
                                className="cursorPointer list-group-item"
                                onClick={() => {
                                  handleInputClickSet(item, "remainder");
                                  setDropdownSelectReminder(false);
                                }}
                              >
                                <div className="d-flex flex-column">
                                  <span> {item.label} </span>
                                </div>
                              </li>
                            ))}
                          </ul>
                        )}
                      </div>
                    </div>
                  </div>

                  <div className="inputFieldDivLeftCalls">
                    <p className="mb-0 mb-1" style={{ color: "#515151" }}>
                      Description
                    </p>
                    <input
                      type="text"
                      maxLength={2000}
                      className="insertCommonInputFieldCalls"
                      name="description"
                      value={meetingInfo.description}
                      onChange={handleChangeMeetings}
                      placeholder="Description"
                    />
                  </div>
                </div>
              </div>
              <aside className="float-end mt-2">
                <div className="d-flex align-items-center gap-2 px-4 pt-2 pb-3 mb-3">
                  <button
                    className="cancel_btn bg-light rounded-2"
                    style={{
                      paddingTop: "6px",
                      paddingBottom: "6px",
                      width: "100px",
                    }}
                    onClick={() => {
                      setShowModalType("");
                      // setSelectedOptionLead(leadContactOptionsMeetings[0]);
                      setSelectedOptionModule(relatedToOptions[0]);
                    }}
                  >
                    Cancel
                  </button>
                  <button
                    className="save_btn py-2 border border-0 rounded-2"
                    style={{ width: "122px" }}
                    onClick={() => {
                      setCheckMeetingCalls("meetings");
                      handleCheckMeeting("SAVE");
                    }}
                    disabled={apiLoader}
                  >
                    {apiLoader ? (
                      <Lottie
                        options={defaultOptions("white")}
                        height="24px"
                        width="36px"
                      />
                    ) : (
                      "Save"
                    )}
                  </button>
                </div>
              </aside>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        show={modalType === "Tasks"}
        id="ConvertModal"
        aria-labelledby="example-custom-modal-styling-title"
        className={`withdrw_modal rounded-2 modal-lg ${
          show || showRepeat ? "opacity-75" : ""
        }`}
        onClick={() => {
          setListVisible(false);
          setDropdownSelectPriority(false);
        }}
      >
        <Modal.Body className="p-0 rounded-2">
          <div className="modalClass position-relative">
            <div
              className="d-flex justify-content-between px-4 pt-4 pb-2 rounded-2"
              style={{ background: "rgba(242, 242, 242, 1)" }}
            >
              <h4 className="commonTextColor">Create Task</h4>
              <img
                src={cancelCircle}
                alt="cancel"
                style={{
                  width: "25px",
                  height: "25px",
                  cursor: "pointer",
                }}
                onClick={() => {
                  setShowModalType("");
                }}
              />
            </div>
            <div className="w-100 position-relative">
              <div className="columns_container ps-4 pe-2 pt-2 pb-3">
                <div className="inputFieldDivCalls mt-2 gap-3">
                  <div className="inputFieldDivLeftCalls">
                    <p className="mb-0 mb-1" style={{ color: "#515151" }}>
                      Subject<span className="text-danger">*</span>
                    </p>
                    <input
                      style={{ width: "98%" }}
                      type="text"
                      className={
                        mandatoryMsg
                          ? "mandatoryField w-100"
                          : "insertCommonInputFieldCalls"
                      }
                      name="subject"
                      value={taskInfo.subject}
                      onChange={handleInputFieldChange}
                      placeholder="Subject"
                    />
                    {mandatoryMsg && (
                      <p style={{ color: "#fa5a5a" }}>
                        {mandatoryMsg && mandatoryMsg}
                      </p>
                    )}
                  </div>
                  <div className="inputFieldDivLeftCalls">
                    <p className="mb-0" style={{ color: "#515151" }}>
                      Due Date
                      <span className="text-danger">*</span>
                    </p>
                    <input
                      type="date"
                      className={
                        mandatoryMsgDueDate
                          ? "mandatoryField w-100"
                          : "insertCommonInputFieldCalls"
                      }
                      value={taskInfo.dueDate ? taskInfo.dueDate : ""}
                      min={todayDate}
                      name="dueDate"
                      onChange={handleInputFieldChange}
                      onKeyDown={(e) => e.preventDefault()}
                    />
                  </div>
                </div>
                <div className="inputFieldDivCalls mt-4 gap-4">
                  <div className="inputFieldDivLeftCalls">
                    <p className="mb-0 mb-1" style={{ color: "#515151" }}>
                      Priority
                    </p>
                    <div className="d-flex input-with-clear">
                      <input
                        className="insertCommonInputFieldCalls cursorPointer w-100"
                        value={taskInfo.priority.value}
                        onClick={(e) => {
                          e.stopPropagation();
                          setDropdownSelectPriority(!dropdownSelectPriority);
                          setListVisible(false);
                        }}
                        readOnly
                      />
                      {/* <Select
                        options={priorityOptions}
                        styles={createAccountStyles}
                        value={taskInfo.priority}
                        menuPlacement="auto"
                        onChange={(selectedOption) =>
                          handleChangeSelectDropDownMeetings(
                            selectedOption,
                            "priority"
                          )
                        }
                      /> */}
                      {dropdownSelectReminder ? (
                        <>
                          <span
                            className="clear-buttonCalls"
                            onClick={() => setDropdownSelectPriority(false)}
                            style={{
                              top: "1.5rem",
                              left: "21rem",
                            }}
                          >
                            <img src={up} alt="img" height={20} width={20} />
                          </span>
                        </>
                      ) : (
                        <>
                          <span
                            className="clear-buttonCalls"
                            style={{
                              top: "1.5rem",
                              left: "21rem",
                            }}
                          >
                            <img src={down} alt="img" height={20} width={20} />
                          </span>
                        </>
                      )}
                      {dropdownSelectPriority && (
                        <ul
                          className="data-list list-group  lead_ownerList_container position-absolute "
                          style={{
                            top: "3.25rem",
                            width: "22.7rem",
                            minHeight: "max-content",
                          }}
                        >
                          {priorityOptions.map((item, index) => (
                            <li
                              key={index}
                              className="cursorPointer list-group-item"
                              onClick={() => {
                                handleChangeSelectDropDownTask(
                                  item,
                                  "priority"
                                );
                                setDropdownSelectPriority(false);
                              }}
                            >
                              <div className="d-flex flex-column">
                                <span> {item.label} </span>
                              </div>
                            </li>
                          ))}
                        </ul>
                      )}
                    </div>
                  </div>
                  <div className="inputFieldDivLeftCalls">
                    <p className="mb-0 mb-1" style={{ color: "#515151" }}>
                      Task Owner<span className="text-danger">*</span>
                    </p>
                    <div className="d-flex input-with-clear">
                      <input
                        type="text"
                        //   placeholder="Vendor Owner"
                        className="insertCommonInputFieldCalls cursorPointer"
                        value={selectedOwner?.name}
                        onClick={(e) => {
                          e.stopPropagation();
                          setDropdownSelectPriority(false);
                          setShowFromTime({
                            hour: false,
                            minutes: false,
                          });
                          setShowToTime({
                            hour: false,
                            minutes: false,
                          });
                          handleInputClick("Owner");
                        }}
                        readOnly
                      />
                      <span
                        className="clear-buttonCalls"
                        onClick={(e) => {
                          e.stopPropagation();
                          handleInputClick("Owner");
                        }}
                      >
                        <img
                          src={listVisible ? up : down}
                          alt="img"
                          height={20}
                          width={20}
                        />
                      </span>
                      <span
                        className="cursorPointer pt-2 ps-1"
                        onClick={() => {
                          setIsLeadOwnerModal(!isLeadOwnerModal);
                          setDropdownSelectPriority(false);
                        }}
                      >
                        <img
                          src={userSearch}
                          alt="img"
                          width={20}
                          height={20}
                        />
                      </span>
                      {listVisibleType === "Owner" && listVisible && (
                        <ul
                          className="data-list list-group  lead_ownerList_container position-absolute"
                          style={{
                            top: "3.2rem",
                            width: "95%",
                            minHeight: "max-content",
                          }}
                        >
                          {userList.map((item, index) => (
                            <li
                              key={index}
                              className="cursorPointer list-group-item"
                              onClick={() => handleInputClickSet(item, "Owner")}
                            >
                              <div className="d-flex flex-column">
                                <span> {item?.name} </span>
                                <span> {item.emailId} </span>
                              </div>
                            </li>
                          ))}
                        </ul>
                      )}
                    </div>
                  </div>
                </div>
                <div className="inputFieldDivCalls mt-4 gap-3">
                  <div className="inputFieldDivLeftCalls flex-row align-items-center">
                    <p className="mb-0">Reminder</p>
                    <Switch
                      onChange={handleReminderChange}
                      checked={checked}
                      uncheckedIcon={false}
                      checkedIcon={false}
                    />
                    <p
                      className="mb-0 ms-2"
                      onClick={() => {
                        setShow(true);
                      }}
                    >
                      {checked &&
                        "On " +
                          taskInfo.remindDate +
                          " at " +
                          taskInfo.remindTime +
                          " by " +
                          taskInfo.notify.value}
                    </p>
                  </div>
                  <div className="inputFieldDivLeftCalls">
                    <p className="mb-0">Repeat</p>
                    <Switch
                      onChange={handleRepeatChange}
                      checked={repeatChecked}
                      uncheckedIcon={false}
                      checkedIcon={false}
                    />
                    {repeatChecked ? (
                      <p className="mb-0 ms-2">{taskInfo.repeatType.value}</p>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
              </div>
              <aside className="float-end mt-2">
                <div className="d-flex align-items-center gap-2 px-4 pt-2 pb-3 mb-3">
                  <button
                    className="cancel_btn bg-light rounded-2"
                    style={{
                      paddingTop: "6px",
                      paddingBottom: "6px",
                      width: "100px",
                    }}
                    onClick={() => {
                      setShowModalType("");
                      // setSelectedOptionLead(leadContactOptionsMeetings[0]);
                      setSelectedOptionModule(relatedToOptions[0]);
                      setListVisible(false);
                      setDropdownSelectPriority(false);
                    }}
                  >
                    Cancel
                  </button>
                  <button
                    className="save_btn py-2 border border-0 rounded-2"
                    style={{ width: "122px" }}
                    onClick={() => {
                      // setSelectedOptionLead(leadContactOptions[0]);
                      let isValidForm = true;
                      const setErrorMessage = (
                        setter,
                        message,
                        timeout = 5000
                      ) => {
                        setter(message);
                        setTimeout(() => {
                          setter("");
                        }, timeout);
                        isValidForm = false;
                      };
                      if (taskInfo.subject === "") {
                        setErrorMessage(
                          setMandatoryMsg,
                          "Subject cannot be empty.",
                          5000
                        );
                      }
                      if (isValidForm) handleSelect("saveTasks");
                      setListVisible(false);
                      setDropdownSelectPriority(false);
                    }}
                  >
                    Next
                  </button>
                </div>
              </aside>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        show={modalType === "saveTasks"}
        id="ConvertModal"
        aria-labelledby="example-custom-modal-styling-title"
        className={`withdrw_modal rounded-2 modal-lg ${
          show || showRepeat ? "opacity-75" : ""
        }`}
        onClick={() => {
          setListVisible(false);
          setDropdownRelatedTo(false);
          setDropdownSelectStatus(false);
          setModuleListVisible(false);
        }}
      >
        <Modal.Body className="p-0 rounded-2">
          <div className="modalClass position-relative">
            <div
              className="d-flex justify-content-between px-4 pt-4 pb-2 rounded-2"
              style={{ background: "rgba(242, 242, 242, 1)" }}
            >
              <h4 className="commonTextColor">Create Task</h4>
              <img
                src={cancelCircle}
                alt="cancel"
                style={{
                  width: "25px",
                  height: "25px",
                  cursor: "pointer",
                }}
                onClick={() => {
                  setShowModalType("");
                }}
              />
            </div>
            <div className="w-100 position-relative">
              <div className="columns_container ps-4 pe-2 pt-2 pb-3">
                <div className="inputFieldDivCalls mt-2 gap-3">
                  <div className="inputFieldDivLeftCalls">
                    <p className="mb-0 mb-1" style={{ color: "#515151" }}>
                      Contact Name<span className="text-danger">*</span>
                    </p>
                    <Select
                      options={leadContactOptions}
                      styles={createAccountStyles}
                      value={selectedOptionLead}
                      onChange={handleChangeLead}
                    />
                  </div>

                  <div className="inputFieldDivLeftCalls">
                    <p className="mb-0 mb-1" style={{ color: "#515151" }}>
                      {selectedOptionLead.value}
                      <span className="text-danger"></span>
                    </p>
                    {/* <input
                      type="text"
                      placeholder="Search by Name"
                      className="insertCommonInputFieldCalls"
                      value={selectedOptionLead.value}
                    /> */}
                    {selectedOptionLead.value === "Contact" && (
                      <div
                        className="d-flex input-with-clear "
                        // onClick={handleOwnerInputClick}
                      >
                        <input
                          type="text"
                          //   placeholder="Vendor Owner"
                          className="insertCommonInputFieldCalls cursorPointer"
                          value={selectedContact?.contactName}
                          onClick={(e) => {
                            e.stopPropagation();
                            handleInputClick("Contacts");
                            setDropdownRelatedTo(false);
                            setDropdownSelectStatus(false);
                            setModuleListVisible(false);
                          }}
                          readOnly
                        />
                        {selectedContact ? (
                          <span
                            className="clear-buttonCalls"
                            onClick={() => setSelectedContact("")}
                          >
                            <img src={down} alt="img" height={20} width={20} />
                          </span>
                        ) : (
                          <span
                            className="clear-buttonCalls"
                            // onClick={() => setSelectedItem("")}
                          >
                            <img src={up} alt="img" height={20} width={20} />
                          </span>
                        )}

                        <span
                          className="cursorPointer pt-2 ps-1"
                          onClick={() => setIsContactListModal(true)}
                        >
                          <img
                            src={userSearch}
                            alt="img"
                            width={20}
                            height={20}
                          />
                        </span>
                        {listVisibleType === "Contacts" && listVisible && (
                          <ul
                            className="data-list list-group  lead_ownerList_container position-absolute "
                            style={{
                              top: "3.2rem",
                              width: "21.8rem",
                              minHeight: "max-content",
                            }}
                          >
                            {contactList.map((item, index) => (
                              <li
                                key={index}
                                className="cursorPointer list-group-item"
                                onClick={() =>
                                  handleInputClickSet(item, "Contacts")
                                }
                              >
                                <div className="d-flex flex-column">
                                  <span> {item?.contactName} </span>
                                </div>
                              </li>
                            ))}
                          </ul>
                        )}
                      </div>
                    )}
                    {selectedOptionLead.value === "Lead" && (
                      <div
                        className="d-flex input-with-clear "
                        // onClick={handleOwnerInputClick}
                      >
                        <input
                          type="text"
                          //   placeholder="Vendor Owner"
                          className="insertCommonInputFieldCalls cursorPointer"
                          value={selectedLeadItem?.leadName || ""}
                          onClick={(e) => {
                            e.stopPropagation();
                            handleInputClick("Leads");
                            setDropdownRelatedTo(false);
                            setDropdownSelectStatus(false);
                            setModuleListVisible(false);
                          }}
                          readOnly
                        />
                        {selectedLeadItem ? (
                          <span
                            className="clear-buttonCalls"
                            onClick={() => setSelectedLeadItem("")}
                          >
                            <img src={down} alt="img" height={20} width={20} />
                          </span>
                        ) : (
                          <span
                            className="clear-buttonCalls"
                            // onClick={() => setSelectedItem("")}
                          >
                            <img src={up} alt="img" height={20} width={20} />
                          </span>
                        )}

                        <span
                          className="cursorPointer pt-2 ps-1"
                          onClick={() => setIsLeadListModal(true)}
                        >
                          <img
                            src={userSearch}
                            alt="img"
                            width={20}
                            height={20}
                          />
                        </span>
                        {listVisibleType === "Leads" && listVisible && (
                          <ul
                            className="data-list list-group  lead_ownerList_container position-absolute "
                            style={{
                              top: "3.2rem",
                              width: "21.4rem",
                              minHeight: "max-content",
                            }}
                          >
                            {leadList.map((item, index) => (
                              <li
                                key={index}
                                className="cursorPointer list-group-item"
                                onClick={() =>
                                  handleInputClickSet(item, "Leads")
                                }
                              >
                                <div className="d-flex flex-column">
                                  <span> {item.leadName} </span>
                                </div>
                              </li>
                            ))}
                          </ul>
                        )}
                      </div>
                    )}
                  </div>
                </div>
                <div className="inputFieldDivCalls mt-4 gap-3">
                  <div className="inputFieldDivLeftCalls">
                    <p className="mb-0 mb-1" style={{ color: "#515151" }}>
                      Related To
                    </p>
                    <div className="d-flex input-with-clear">
                      <input
                        className="insertCommonInputFieldCalls cursorPointer"
                        value={selectedOptionModule.label}
                        onClick={(e) => {
                          e.stopPropagation();
                          setDropdownRelatedTo(!dropdownRelatedTo);
                          setDropdownSelectStatus(false);
                          setListVisible(false);
                          setModuleListVisible(false);
                        }}
                        readOnly
                        disabled={selectedOptionLead.value === "Lead"}
                      />
                      {dropdownRelatedTo ? (
                        <>
                          <span
                            className="clear-buttonCalls"
                            onClick={() => setDropdownRelatedTo(false)}
                            style={{
                              top: "1.5rem",
                              left: "20rem",
                            }}
                          >
                            <img src={up} alt="img" height={20} width={20} />
                          </span>
                        </>
                      ) : (
                        <>
                          <span
                            className="clear-buttonCalls"
                            style={{
                              top: "1.5rem",
                              left: "20rem",
                            }}
                          >
                            <img src={down} alt="img" height={20} width={20} />
                          </span>
                        </>
                      )}
                      {dropdownRelatedTo && (
                        <ul
                          className="data-list list-group  lead_ownerList_container position-absolute "
                          style={{
                            top: "3.25rem",
                            width: "21.9rem",
                            minHeight: "max-content",
                          }}
                        >
                          {relatedToOptions.map((item, index) => (
                            <li
                              key={index}
                              className="cursorPointer list-group-item"
                              onClick={() => {
                                handleChangeModule(item);
                                setDropdownRelatedTo(false);
                                setDropdownCallPurpose(false);
                                setSelectedOptionModule(item);
                              }}
                            >
                              <div className="d-flex flex-column">
                                <span> {item.label} </span>
                              </div>
                            </li>
                          ))}
                        </ul>
                      )}
                    </div>
                  </div>
                  <div className="inputFieldDivLeftCalls position-relative">
                    <p className="mb-0 mb-1">{selectedOptionModule.label}</p>
                    <div className="d-flex input-with-clear ">
                      <input
                        type="text"
                        className="insertCommonInputFieldCalls cursorPointer"
                        value={
                          selectedOptionLead.value === "Lead"
                            ? ""
                            : moduleWiseData.length > 0
                            ? selectedModuleItem.moduleName
                            : ""
                        }
                        disabled={selectedOptionLead.value === "Lead"}
                        onClick={handleModuleClick}
                        readOnly
                      />
                      {selectedOptionLead.value === "Lead" ? (
                        <span className="clear-buttonCalls">
                          <img
                            src={moduleListVisible ? up : down}
                            alt="img"
                            height={20}
                            width={20}
                          />{" "}
                        </span>
                      ) : (
                        <span
                          className="clear-buttonCalls"
                          onClick={handleModuleClick}
                        >
                          <img
                            src={moduleListVisible ? up : down}
                            alt="img"
                            height={20}
                            width={20}
                          />{" "}
                        </span>
                      )}
                    </div>
                    {moduleListVisible && moduleWiseData.length > 0 && (
                      <ul
                        className="data-list list-group  lead_ownerList_container position-absolute "
                        // style={{
                        //   top: "200px",
                        //    width: "27.5vw",
                        // }}

                        style={{
                          top: "4.9rem",
                          width: "21.8rem",
                          minHeight: "max-content",
                        }}
                      >
                        {" "}
                        {moduleWiseData.map((item, index) => (
                          <li
                            key={index}
                            className="cursorPointer list-group-item"
                            onClick={() => handleModuleItemClick(item)}
                          >
                            {item.moduleName}
                          </li>
                        ))}
                      </ul>
                    )}
                  </div>
                </div>
                <div
                  className="inputFieldDivCalls mt-4 gap-3"
                  style={{ alignItems: "self-start" }}
                >
                  <div className="inputFieldDivLeftCalls">
                    <p className="mb-0 mb-1" style={{ color: "#515151" }}>
                      Status
                    </p>
                    {/* <Select
                      options={statusOptions}
                      styles={createAccountStyles}
                      value={taskInfo.status}
                      menuPlacement="auto"
                      onChange={(selectedOption) =>
                        handleDropDownChanges(selectedOption, "status")
                      }
                    /> */}
                    <div className="d-flex input-with-clear">
                      <input
                        className="insertCommonInputFieldCalls cursorPointer w-100"
                        value={taskInfo.status.value}
                        onClick={(e) => {
                          e.stopPropagation();
                          setDropdownSelectStatus(!dropdownSelectStatus);
                          setListVisible(false);
                          setModuleListVisible(false);
                          setDropdownRelatedTo(false);
                        }}
                        readOnly
                      />
                      {/* <Select
                        options={statusOptions}
                        styles={createAccountStyles}
                        value={taskInfo.status}
                        menuPlacement="auto"
                        onChange={(selectedOption) =>
                          handleChangeSelectDropDownMeetings(
                            selectedOption,
                            "status"
                          )
                        }
                      /> */}
                      {dropdownSelectReminder ? (
                        <>
                          <span
                            className="clear-buttonCalls"
                            onClick={() => setDropdownSelectStatus(false)}
                            style={{
                              top: "1.5rem",
                              left: "21rem",
                            }}
                          >
                            <img src={up} alt="img" height={20} width={20} />
                          </span>
                        </>
                      ) : (
                        <>
                          <span
                            className="clear-buttonCalls"
                            style={{
                              top: "1.5rem",
                              left: "21rem",
                            }}
                          >
                            <img src={down} alt="img" height={20} width={20} />
                          </span>
                        </>
                      )}
                      {dropdownSelectStatus && (
                        <ul
                          className="data-list list-group  lead_ownerList_container position-absolute "
                          style={{
                            top: "3.25rem",
                            width: "22.7rem",
                            minHeight: "max-content",
                          }}
                        >
                          {statusOptions.map((item, index) => (
                            <li
                              key={index}
                              className="cursorPointer list-group-item"
                              onClick={() => {
                                handleChangeSelectDropDownTask(item, "status");
                                setDropdownSelectStatus(false);
                              }}
                            >
                              <div className="d-flex flex-column">
                                <span> {item.label} </span>
                              </div>
                            </li>
                          ))}
                        </ul>
                      )}
                    </div>
                  </div>
                  <div className="inputFieldDivLeftCalls">
                    <p className="mb-0 mb-1" style={{ color: "#515151" }}>
                      Description
                    </p>
                    <textarea
                      rows="4"
                      cols="50"
                      type="text"
                      maxLength="1000"
                      className="insertCommonInputFieldCalls"
                      name="description"
                      value={taskInfo.description}
                      onChange={handleInputFieldChange}
                      placeholder="Description"
                    />
                  </div>
                </div>
              </div>
              <aside className="float-end mt-2">
                <div className="d-flex align-items-center gap-2 px-4 pt-2 pb-3 mb-3">
                  <button
                    className="cancel_btn bg-light rounded-2"
                    style={{
                      paddingTop: "6px",
                      paddingBottom: "6px",
                      width: "100px",
                    }}
                    onClick={() => {
                      // setSelectedOptionLead(leadContactOptionsMeetings[0]);
                      setSelectedOptionModule(relatedToOptions[0]);
                      handleSelect("Tasks");
                      setListVisible(false);
                      setDropdownRelatedTo(false);
                      setDropdownSelectStatus(false);
                    }}
                  >
                    Back
                  </button>
                  <button
                    className="save_btn py-2 border border-0 rounded-2"
                    style={{ width: "122px" }}
                    onClick={() => {
                      handleCreateTask("SAVE");
                    }}
                    disabled={apiLoader}
                  >
                    {apiLoader ? (
                      <Lottie
                        options={defaultOptions("white")}
                        height="24px"
                        width="36px"
                      />
                    ) : (
                      "Save"
                    )}
                  </button>
                </div>
              </aside>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
      >
        <Modal.Header
          style={{ background: "rgba(242, 242, 242, 1)", border: "none" }}
        >
          <Modal.Title className="commonTextColor">Set a Reminder</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="inputFieldDiv gap-3">
            <div className="inputFieldDivLeft">
              <p className="mb-0">Select a Date</p>
              <input
                type="date"
                name="remindDate"
                className="leadInputFieldModal"
                value={taskInfo.remindDate}
                min={todayDate}
                onChange={handleInputFieldChange}
                onKeyDown={(e) => e.preventDefault()}
              />
              {/* <p className="mb-0 text-danger">{showSelectDateMsg}</p> */}
            </div>
            <div className="inputFieldDivRight">
              <p className="mb-0">
                Select a Time
                <span className="text-danger">*</span>
              </p>
              <Select
                options={timeArray}
                styles={customSelectLocationStyles}
                value={{
                  label: taskInfo.remindTime,
                  value: taskInfo.remindTime,
                }}
                onChange={(selectedOption) =>
                  handleChangeSelectDropDownTask(
                    selectedOption.value,
                    "remindTime"
                  )
                }
              />
              {/* <p className="mb-0 text-danger">{showSelectTimeMsg}</p> */}
            </div>
          </div>
          <div className="inputFieldDiv">
            <div className="inputFieldDivLeft">
              <p className="mb-0">
                Notify
                <span className="text-danger">*</span>
              </p>
              <Select
                options={notifyOptions}
                styles={customSelectLocationStyles}
                value={taskInfo.notify}
                onChange={(selectedOption) =>
                  handleChangeSelectDropDownTask(selectedOption, "notify")
                }
              />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer style={{ border: "none" }}>
          <Button
            style={{ color: "black", width: "100px" }}
            className="cancel_btn bg-light rounded-2"
            onClick={() => {
              // taskInfo.notify = notifyOptions[0];
              taskInfo.remindTime = "";
              taskInfo.remindDate = "";
              handleClose();
              setChecked(false);
              // setTaskInfo(taskInfo);
            }}
          >
            Cancel
          </Button>

          <Button
            className="save_btn py-2 border border-0 rounded-2"
            style={{ width: "100px" }}
            onClick={handleDoneButtonClick}
          >
            Done
          </Button>
        </Modal.Footer>
      </Modal>
      {/* ----------------------------------------------------------------------- */}
      <Modal
        show={showRepeat}
        onHide={handleRepeatClose}
        backdrop="static"
        keyboard={false}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
      >
        <Modal.Header
          style={{ background: "rgba(242, 242, 242, 1)", border: "none" }}
        >
          <Modal.Title className="commonTextColor">Repeat</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="inputFieldDiv gap-2">
            <div className="inputFieldDivLeft">
              <p className="mb-0">
                Repeat Type
                <span className="text-danger">*</span>
              </p>
              <Select
                options={repeatOptions}
                styles={customSelectLocationStyles}
                value={taskInfo.repeatType}
                onChange={(selectedOption) =>
                  handleChangeSelectDropDownTask(selectedOption, "repeatType")
                }
              />
            </div>
            <div className="inputFieldDivRight">
              <p className="mb-0">
                Ends On
                <span className="text-danger">*</span>
              </p>
              <div className="d-flex gap-2 w-100">
                <input
                  type="radio"
                  name="endsOn"
                  value="Never"
                  onClick={() => {
                    setEndOn("Never");
                  }}
                  checked={endOn === "Never"}
                />
                <span
                  className="d-flex align-items-center"
                  style={{ width: "40px" }}
                >
                  Never
                </span>
              </div>
              <div className="d-flex gap-2 w-100">
                <input
                  type="radio"
                  name="endsOn"
                  value="After"
                  onClick={() => {
                    setEndOn("After");
                  }}
                />
                <span
                  className="d-flex align-items-center"
                  style={{ width: "40px" }}
                >
                  After
                </span>
                <input
                  type="text"
                  pattern="[0-9]*"
                  maxLength={1}
                  className="leadInputFieldModal defaultNumberInput"
                  style={{ width: "10%" }}
                  value={taskInfo.repeatEndTimes}
                  name="repeatEndTimes"
                  onChange={handleInputFieldChange}
                  onKeyDown={(e) => {
                    if (
                      !/\d/.test(e.key) &&
                      e.key !== "Backspace" &&
                      e.key !== "Delete" &&
                      e.key !== "ArrowLeft" &&
                      e.key !== "ArrowRight"
                    ) {
                      e.preventDefault();
                    }
                  }}
                />
                <span className="d-flex align-items-center">Times</span>
              </div>
              <div className="d-flex gap-2 w-100">
                <input
                  type="radio"
                  name="endsOn"
                  value="On"
                  onClick={() => {
                    setEndOn("On");
                  }}
                />
                <span
                  className="d-flex align-items-center"
                  style={{ width: "50px" }}
                >
                  On
                </span>
                <input
                  type="date"
                  className="leadInputFieldModal"
                  style={{ width: "100%" }}
                  value={taskInfo.repeatEndDate}
                  name="repeatEndDate"
                  min={minDate}
                  onKeyDown={(e) => e.preventDefault()}
                  onChange={handleInputFieldChange}
                />
              </div>
            </div>
          </div>
          <p className="mb-0 text-center mt-3" style={{ color: "#fa5a5a" }}>
            {repeatMsg && repeatMsg}
          </p>
          {/* <div className="inputFieldDiv">
            <div className="inputFieldDivLeft">
              <p className="mb-0">Notify</p>
              <Select
                options={notifyOptions}
                styles={customSelectLocationStyles}
                value={selectedNotifyOption}
                onChange={handleNotifyChange}
              />
            </div>
          </div> */}
        </Modal.Body>
        <Modal.Footer style={{ border: "none" }}>
          <Button
            style={{ color: "black", width: "100px" }}
            className="cancel_btn bg-light rounded-2"
            onClick={() => {
              taskInfo.repeatType = repeatOptions[0];
              taskInfo.repeatEndDate = "";
              taskInfo.repeatEnd = "";
              handleRepeatClose();
              setRepeatChecked(false);
            }}
          >
            Cancel
          </Button>
          <Button
            className="save_btn py-2 border border-0 rounded-2"
            style={{ width: "100px" }}
            onClick={() => {
              if (endOn === "After") {
                if (taskInfo.repeatEndTimes === "") {
                  setRepeatMsg("Repeat end times is Mandatory");
                  setTimeout(() => {
                    setRepeatMsg("");
                  }, 3000);
                } else {
                  handleRepeatClose();
                  setRepeatChecked(true);
                }
              } else if (endOn === "On") {
                if (taskInfo.repeatEndDate === "") {
                  setRepeatMsg("Repeat end date is Mandatory");
                  setTimeout(() => {
                    setRepeatMsg("");
                  }, 3000);
                } else {
                  handleRepeatClose();
                  setRepeatChecked(true);
                }
              } else {
                handleRepeatClose();
                setRepeatChecked(true);
              }
            }}
          >
            Done
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal
        show={modalSubType === "AddParticipants"}
        id="ConvertModal"
        aria-labelledby="example-custom-modal-styling-title"
        className="withdrw_modal rounded-2 modal-lg"
      >
        <Modal.Body className="p-0 rounded-2">
          <div className="modalClass position-relative">
            <div
              className="d-flex justify-content-between px-4 pt-4 pb-2 rounded-2"
              style={{ background: "rgba(242, 242, 242, 1)" }}
            >
              <h4 className="commonTextColor">Add Participants</h4>
            </div>
            <div className="w-100 position-relative">
              <div className="columns_container ps-4 pe-2 pt-2 pb-3">
                <div className="inputFieldDivCalls mt-2 gap-3">
                  <div className="inputFieldDivLeftCalls">
                    <p className="mb-0 mb-1" style={{ color: "#515151" }}>
                      {addParticipant.value}
                    </p>
                    <Select
                      options={AddParticipantsOptions}
                      styles={createAccountStyles}
                      value={addParticipant}
                      onChange={handleAddParticipant}
                    />
                  </div>

                  <div className="inputFieldDivLeftCalls">
                    <p className="mb-0 mb-1" style={{ color: "#515151" }}>
                      Search
                    </p>
                    <input
                      type="text"
                      placeholder="Search by Name"
                      className="insertCommonInputFieldCalls"
                      value={searchInput}
                      onChange={handleSearchInputChange}
                    />
                  </div>
                </div>
                <div
                  className="inputFieldDivCalls mt-4 gap-3"
                  style={{ alignItems: "self-start" }}
                >
                  <div className="inputFieldDivLeftCalls ">
                    <p className="mb-0 mb-1" style={{ color: "#515151" }}>
                      Invite by Email Address
                    </p>
                    <div>
                      <input
                        type="text"
                        className="insertCommonInputFieldCalls"
                        placeholder="Add Email Address"
                        value={emailInput}
                        onChange={handleInputChange}
                        onKeyDown={handleKeyDown}
                      />
                      {emailList.length > 0 && (
                        <div className="email-list my-2 mx-0 list-container">
                          {emailList.map((email, index) => (
                            <div key={index} className="email-item">
                              {email}
                              <button
                                className="remove-email"
                                onClick={() => handleRemoveEmail(index)}
                              >
                                &#10006;
                              </button>
                            </div>
                          ))}
                        </div>
                      )}
                    </div>
                  </div>
                  <div
                    className="inputFieldDivLeftCalls mt-0"
                    style={{ overflowX: "hidden" }}
                  >
                    <div className="checkList">
                      <div
                        className="list-container p-2 overflow-y-scroll m-0"
                        style={{
                          border: "1.5px solid #B9B9B980",
                          height: "150px",
                        }}
                      >
                        {filteredItems?.map((item, index) => (
                          <li
                            key={index}
                            className="cursorPointer list-group-item"
                          >
                            <div className="d-flex flex-column">
                              {addParticipant.value === "Users" && (
                                <>
                                  <div
                                    className="ms-1 d-flex flex-column"
                                    onClick={() =>
                                      handleCheckboxToggle(item.userId, "Users")
                                    }
                                  >
                                    <div className="d-flex">
                                      <input
                                        type="checkbox"
                                        checked={selectedItems.some(
                                          (selectedOwner) =>
                                            selectedOwner.moduleId ===
                                            item.userId
                                        )}
                                        readOnly
                                      />

                                      <span className="ms-1">{item.name}</span>
                                    </div>
                                    <span>{item.emailId}</span>
                                  </div>
                                </>
                              )}
                              {addParticipant.value === "Contacts" && (
                                <div
                                  className="ms-1 d-flex flex-column"
                                  onClick={() =>
                                    handleCheckboxToggle(
                                      item?.contactId,
                                      "CONTACTS"
                                    )
                                  }
                                >
                                  <div className="d-flex">
                                    <input
                                      type="checkbox"
                                      checked={selectedItems.some(
                                        (selectedOwner) =>
                                          selectedOwner.moduleId ===
                                          item?.contactId
                                      )}
                                      readOnly
                                    />

                                    <span className="ms-1">
                                      {item?.contactName}
                                    </span>
                                  </div>
                                  <span>{item.emailId}</span>
                                </div>
                              )}
                              {addParticipant.value === "Leads" && (
                                <div
                                  className="ms-1 d-flex flex-column"
                                  onClick={() =>
                                    handleCheckboxToggle(item.leadId, "LEADS")
                                  }
                                >
                                  <div className="d-flex">
                                    <input
                                      type="checkbox"
                                      checked={selectedItems.some(
                                        (selectedOwner) =>
                                          selectedOwner.moduleId === item.leadId
                                      )}
                                      readOnly
                                    />

                                    <span className="ms-1">
                                      {item.leadName}
                                    </span>
                                  </div>
                                  <span>{item.emailId}</span>
                                </div>
                              )}
                            </div>
                          </li>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <aside className="float-end mt-2">
                <div className="d-flex align-items-center gap-2 px-4 pt-2 pb-3 mb-3">
                  <button
                    className="cancel_btn bg-light rounded-2"
                    style={{ width: "122px", height: "40px" }}
                    onClick={() => {
                      if (!addButton) {
                        setShowModalSubType("");
                        setShowModalType("Meetings");
                        setSelectedItems([]);
                        setEmailList([]);
                      } else {
                        setShowModalSubType("");
                        setSelectedItems([]);
                        setEmailList([]);
                        setAddButton(false);
                      }
                    }}
                  >
                    Cancel
                  </button>
                  <button
                    className="save_btn py-2 border border-0 rounded-2 h-100"
                    style={{ width: "122px" }}
                    onClick={() => {
                      setShowModalSubType("");
                      setShowModalType("Meetings");
                    }}
                  >
                    Done
                  </button>
                </div>
              </aside>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        size="md"
        show={overlapModal}
        // id="changeOwnerModal"
        aria-labelledby="example-custom-modal-styling-title"
        className="withdrw_modal justify-content-center align-items-center w-100"
      >
        <Modal.Body style={{ padding: "8px 25px 10px 10px" }}>
          <div className="d-flex flex-column pt-1">
            <div
              className="w-100 ms-2 me-3 my-2 px-2 bg-white px-1 py-1 d-flex flex-column recordContainer"
              style={{
                height: "100px",
                overflowY: "hidden",
                color: "black",
                border: "none",
              }}
            >
              <p>{overlapMessage}</p>
              <p>Are you Sure you want to create this meeting anyway?</p>
            </div>
            <aside
              className="float-end mt-2  bg-white "
              style={{ marginBottom: "12px" }}
            >
              <div
                className="d-flex align-items-center gap-3 bg-white "
                style={{ flexFlow: "row-reverse" }}
              >
                <button
                  className="save_btn"
                  style={{
                    height: "35px",
                    border: "none",
                    width: "100px",
                    borderRadius: "5px",
                  }}
                  onClick={() => {
                    checkMeetingCalls === "meetings"
                      ? handleInsertMeetings("SAVE")
                      : handleInsertCalls();
                  }}
                  disabled={createLoader}
                >
                  {createLoader ? (
                    <Lottie
                      options={defaultOptions("white")}
                      height="24px"
                      width="36px"
                    />
                  ) : (
                    "Create"
                  )}
                </button>
                <button
                  className="cancel_btn bg-light"
                  style={{
                    height: "32.5px",
                    width: "100px",
                    borderRadius: "5px",
                  }}
                  onClick={() => setOverlapModal(false)}
                >
                  Cancel
                </button>
              </div>
            </aside>
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        show={modalSubType === "Reset"}
        id="ConvertModal"
        aria-labelledby="example-custom-modal-styling-title"
        className="withdrw_modal rounded-2"
        onClick={() => {
          setShowFromTime({
            hour: false,
            minutes: false,
          });
          setShowToTime({
            hour: false,
            minutes: false,
          });
        }}
      >
        <Modal.Body className="p-0 rounded-2">
          <div className="modalClass position-relative">
            <div
              className="d-flex justify-content-between px-4 pt-4 pb-2 rounded-2"
              style={{ background: "rgba(242, 242, 242, 1)" }}
            >
              <h4 className="commonTextColor">Add Reset</h4>
            </div>
            <div className="w-100 position-relative">
              <div className="columns_container ps-4 pe-2 pt-2 pb-3">
                <div className="inputFieldDivCalls mt-2 gap-3">
                  <div className="inputFieldDivLeftCalls d-flex">
                    <p className="mb-0 mb-1 me-3" style={{ color: "#515151" }}>
                      All Day
                    </p>
                    <input
                      type="checkbox"
                      checked={isAllDay}
                      onChange={handleCheckboxChange}
                    />
                  </div>
                </div>
                <div className="inputFieldDivCalls mt-4 gap-3">
                  <div className="inputFieldDivLeftCalls">
                    <div className="d-flex">
                      <div className="w-50">
                        <p className="mb-0 mb-1" style={{ color: "#515151" }}>
                          From Date
                        </p>
                        <input
                          type="date"
                          className="insertCommonInputFieldCalls"
                          name="fromDate"
                          value={meetingInfo.fromDate}
                          onChange={handleChangeMeetings}
                          min={new Date().toISOString().split("T")[0]}
                          max={
                            new Date(
                              new Date().getFullYear() + 3,
                              new Date().getMonth(),
                              new Date().getDate()
                            )
                              .toISOString()
                              .split("T")[0]
                          }
                        />
                      </div>
                      <div className="w-50">
                        {!isAllDay && (
                          <>
                            {" "}
                            <p
                              className="mb-0 mb-1"
                              style={{ color: "#515151" }}
                            >
                              From Time
                            </p>
                            <div className="insertCommonInputFieldCalls d-flex gap-2">
                              <div className="hours_box position-relative">
                                <div
                                  className="cursorPointer"
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    setShowFromTime({
                                      hour: !showFromTime.hour,
                                      minutes: false,
                                    });
                                  }}
                                >
                                  {fromTimeSet.hour}
                                </div>
                                {showFromTime.hour ? (
                                  <div className="hours_options position-absolute d-flex flex-column rounded-1">
                                    {hours.map((hour, index) => {
                                      return (
                                        <span
                                          className="p-1 cursorPointer"
                                          onClick={() => {
                                            setFromTimeSet({
                                              hour: hour,
                                              minutes: fromTimeSet.minutes,
                                              meridiem: fromTimeSet.meridiem,
                                            });
                                          }}
                                          key={index}
                                        >
                                          {hour}
                                        </span>
                                      );
                                    })}
                                  </div>
                                ) : null}
                              </div>
                              <div>:</div>
                              <div className="minutes_box position-relative">
                                <div
                                  className="cursorPointer"
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    setShowFromTime({
                                      hour: false,
                                      minutes: !showFromTime.minutes,
                                    });
                                  }}
                                >
                                  {fromTimeSet.minutes}
                                </div>
                                {showFromTime.minutes ? (
                                  <div className="hours_options position-absolute d-flex flex-column rounded-1">
                                    {" "}
                                    {minutes.map((minute, index) => {
                                      return (
                                        <span
                                          className="p-1 cursorPointer"
                                          onClick={() => {
                                            setFromTimeSet({
                                              hour: fromTimeSet.hour,
                                              minutes: minute,
                                              meridiem: fromTimeSet.meridiem,
                                            });
                                          }}
                                          key={index}
                                        >
                                          {minute}
                                        </span>
                                      );
                                    })}
                                  </div>
                                ) : null}
                              </div>
                              <div>:</div>
                              <div className="hours_box_type">
                                <div
                                  className="cursorPointer"
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    setShowFromTime({
                                      hour: false,
                                      minutes: false,
                                    });

                                    setFromTimeSet({
                                      hour: fromTimeSet.hour,
                                      minutes: fromTimeSet.minutes,
                                      meridiem:
                                        fromTimeSet.meridiem === "AM"
                                          ? "PM"
                                          : "AM",
                                    });
                                  }}
                                >
                                  {fromTimeSet.meridiem}
                                </div>
                              </div>
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="inputFieldDivCalls mt-4 gap-3">
                  <div className="inputFieldDivLeftCalls">
                    <div className="d-flex">
                      <div className="w-50">
                        <p className="mb-0 mb-1" style={{ color: "#515151" }}>
                          To Date
                        </p>
                        <input
                          type="date"
                          className="insertCommonInputFieldCalls"
                          name="toDate"
                          value={meetingInfo.toDate}
                          onChange={handleChangeMeetings}
                          min={new Date().toISOString().split("T")[0]}
                          max={
                            new Date(
                              new Date().getFullYear() + 3,
                              new Date().getMonth(),
                              new Date().getDate()
                            )
                              .toISOString()
                              .split("T")[0]
                          }
                        />
                      </div>
                      <div className="w-50">
                        {!isAllDay && (
                          <>
                            <p
                              className="mb-0 mb-1"
                              style={{ color: "#515151" }}
                            >
                              To Time
                            </p>
                            <div className="insertCommonInputFieldCalls d-flex gap-2">
                              <div className="hours_box position-relative">
                                <div
                                  className="cursorPointer"
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    setShowFromTime({
                                      hour: false,
                                      minutes: false,
                                    });
                                    setListVisible(false);
                                    setDropdownSelect(false);
                                    setDropdownSelectReminder(false);
                                    setListVisibleType("");
                                    setShowToTime({
                                      hour: !showToTime.hour,
                                      minutes: false,
                                    });
                                  }}
                                >
                                  {toTimeSet.hour}
                                </div>
                                {showToTime.hour ? (
                                  <div className="hours_options position-absolute d-flex flex-column rounded-1">
                                    {hours.map((hour, index) => {
                                      return (
                                        <span
                                          className="p-1 cursorPointer"
                                          onClick={() => {
                                            setToTimeSet({
                                              hour: hour,
                                              minutes: toTimeSet.minutes,
                                              meridiem: toTimeSet.meridiem,
                                            });
                                          }}
                                          key={index}
                                        >
                                          {hour}
                                        </span>
                                      );
                                    })}
                                  </div>
                                ) : null}
                              </div>
                              <div>:</div>
                              <div className="minutes_box position-relative">
                                <div
                                  className="cursorPointer"
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    setShowFromTime({
                                      hour: false,
                                      minutes: false,
                                    });
                                    setListVisible(false);
                                    setDropdownSelect(false);
                                    setDropdownSelectReminder(false);
                                    setListVisibleType("");
                                    setShowToTime({
                                      hour: false,
                                      minutes: !showToTime.minutes,
                                    });
                                  }}
                                >
                                  {toTimeSet.minutes}
                                </div>
                                {showToTime.minutes ? (
                                  <div className="hours_options position-absolute d-flex flex-column rounded-1">
                                    {" "}
                                    {minutes.map((minute, index) => {
                                      return (
                                        <span
                                          className="p-1 cursorPointer"
                                          onClick={() => {
                                            setToTimeSet({
                                              hour: toTimeSet.hour,
                                              minutes: minute,
                                              meridiem: toTimeSet.meridiem,
                                            });
                                          }}
                                          key={index}
                                        >
                                          {minute}
                                        </span>
                                      );
                                    })}
                                  </div>
                                ) : null}
                              </div>
                              <div>:</div>
                              <div className="hours_box_type">
                                <div
                                  className="cursorPointer"
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    setShowToTime({
                                      hour: false,
                                      minutes: false,
                                    });
                                    setToTimeSet({
                                      hour: toTimeSet.hour,
                                      minutes: toTimeSet.minutes,
                                      meridiem:
                                        toTimeSet.meridiem === "AM"
                                          ? "PM"
                                          : "AM",
                                    });
                                  }}
                                >
                                  {toTimeSet.meridiem}
                                </div>
                              </div>
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="inputFieldDivCalls mt-4 gap-3">
                  <div className="inputFieldDivLeftCalls">
                    <p className="mb-0 mb-1" style={{ color: "#515151" }}>
                      Repeat
                    </p>
                    <Select
                      options={repeatEveryOptions}
                      styles={createAccountStyles}
                      value={selectedOptionRepeat}
                      onChange={handleChangeRepeat}
                    />
                  </div>

                  <div className="inputFieldDivLeftCalls">
                    {selectedOptionRepeat.value === "Custom" && (
                      <>
                        <p className="mb-0 mb-1" style={{ color: "#515151" }}>
                          Frequency
                        </p>
                        <Select
                          options={FrequencyOptions}
                          styles={createAccountStyles}
                          value={selectedOptionFrequency}
                          onChange={handleChangeFrequency}
                        />
                      </>
                    )}
                  </div>
                </div>
                <div className="inputFieldDivCalls mt-4 gap-3">
                  <div className="inputFieldDivLeftCalls">
                    {selectedOptionRepeat.value === "Custom" && (
                      <>
                        <p className="mb-0 mb-1" style={{ color: "#515151" }}>
                          Repeat Every
                        </p>
                        <div className="d-flex">
                          <input
                            type="number"
                            className="insertCommonInputFieldCalls mb-1"
                            name="repeatEveryNum"
                            value={meetingInfo.repeatEveryNum}
                            onChange={handleChangeMeetings}
                          />
                          <div
                            className="m-0"
                            style={{
                              color: "rgba(156, 156, 156, 1)",
                              fontSize: "18px",
                              backgroundColor: "#0000000D",
                              width: "0%",
                              textAlign: "-webkit-center",
                            }}
                          >
                            {/* selectedOptionFrequency */}
                            <p
                              className="ms-2 h-100"
                              style={{
                                alignContent: "center",
                                backgroundColor: "inherit",
                                width: "50px",
                              }}
                            >
                              Days
                            </p>
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                  <div className="inputFieldDivLeftCalls">
                    {selectedOptionRepeat.value === "Custom" && <></>}
                  </div>
                </div>
                {selectedOptionRepeat.value !== "None" && (
                  <>
                    <div className="d-flex justify-content-between pt-5 pb-2 rounded-2 relative">
                      <h4 className="commonTextColor">End</h4>
                    </div>
                    <div className="columns_container pt-1 pb-3 overflow-y-visible">
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="flexRadioDefault"
                          id="flexRadioDefault1"
                          value="never"
                          checked={selectedOptionRadio === "never"}
                          onChange={handleRadioChange}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="flexRadioDefault1"
                        >
                          Never
                        </label>
                      </div>
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="flexRadioDefault"
                          id="flexRadioDefault2"
                          value="after"
                          checked={selectedOptionRadio === "after"}
                          onChange={handleRadioChange}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="flexRadioDefault2"
                        >
                          After{" "}
                          <input
                            type="number"
                            max="10"
                            style={{
                              background: "rgba(185, 185, 185, 0.25)",
                              border: "None",
                              width: "20px",
                            }}
                            placeholder="1"
                            name="afterTimes"
                            value={meetingInfo.afterTimes}
                            onChange={handleChangeMeetings}
                          />{" "}
                          times
                        </label>
                      </div>
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="flexRadioDefault"
                          id="flexRadioDefault3"
                          value="on"
                          checked={selectedOptionRadio === "on"}
                          onChange={handleRadioChange}
                        />
                        <p>
                          On{" "}
                          <span
                            onClick={handleDateTextClick}
                            style={{ cursor: "pointer" }}
                          >
                            {selectedDate
                              ? formatDate(selectedDate)
                              : " (Click to select date)"}
                          </span>
                        </p>
                        {showDateInput && (
                          <input
                            type="date"
                            value={selectedDate || ""}
                            onChange={handleDateInputChange}
                          />
                        )}
                      </div>
                    </div>{" "}
                    <div className="inputFieldDivCalls mt-0 gap-3">
                      <div className="inputFieldDivLeftCalls d-flex">
                        <input type="checkbox" className="me-3" />
                        <p className="mb-0 mb-1" style={{ color: "#515151" }}>
                          Send Seperate invites for each meeting.
                        </p>
                      </div>
                    </div>
                  </>
                )}
              </div>
              <aside className="float-end mt-2">
                <div className="d-flex align-items-center gap-2 px-4 pt-2 pb-3 mb-3">
                  <button
                    className="cancel_btn bg-light rounded-2"
                    style={{ width: "122px", height: "40px" }}
                    onClick={() => {
                      setShowModalSubType("");
                      setShowModalType("Meetings");
                    }}
                  >
                    Cancel
                  </button>
                  <button
                    className="save_btn py-2 border border-0 rounded-2"
                    style={{ width: "122px" }}
                    onClick={() => {
                      setShowModalSubType("");
                      setShowModalType("Meetings");
                    }}
                  >
                    Done
                  </button>
                </div>
              </aside>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <LeadOwnerModal
        isLeadOwnerModal={isLeadOwnerModal}
        setIsLeadOwnerModal={setIsLeadOwnerModal}
        onSelectedLeadChange={handleSelectedLeadChange}
        defaultUser={selectedOwner}
      />
      {/* <ContactListModal
        isContactListModal={isContactListModal}
        setIsContactListModal={setIsContactListModal}
        onSelectedLeadChange={handleSelectedContactListChange}
        defaultUser={selectedContact}
        allContactDetails={contactList}
      /> */}
      {/* <LeadListModal
        isLeadListModal={isLeadListModal}
        setIsLeadListModal={setIsLeadListModal}
        onSelectedLeadChange={handleSelectedLeadListChange}
        defaultUser={selectedLeadItem}
        leadDetails={leadList}
      /> */}

      <Modal
        show={isContactListModal}
        className="d-flex justify-content-center align-items-center"
        aria-labelledby="example-custom-modal-styling-title"
        size="lg"
        id="commonLeadModal"
      >
        <Modal.Body className="ModalBody  overflow-auto">
          <div
            className="modalUS row justify-content-center"
            style={{ margin: "0", gap: "10px", paddingBottom: "10px" }}
          >
            <div className="header_Container d-flex justify-content-between">
              <h4 className="commonTextColor pt-2">Change Contact</h4>
              <div className="inputContainer d-flex justify-content-start">
                <input
                  type="text"
                  placeholder="Search"
                  className="topBarModalSearch px-5"
                  onChange={handleSearchChangeContact}
                  value={searchContact}
                />
                <img src={SearchIcon} alt="search" className="searchIcon" />
              </div>
            </div>

            <div className="Owner_table_container border border-dark rounded-2 h-25">
              <div className="table-wrapper">
                <table className="userdata-table">
                  <thead>
                    <tr>
                      <th style={{ width: "20%" }}>Contact Name</th>
                      <th style={{ width: "20%" }}>Account Name</th>
                      <th style={{ width: "20%" }}>Email</th>
                      <th style={{ width: "20%" }}>Phone</th>
                      <th style={{ width: "20%" }}>Contact Owner</th>
                    </tr>
                  </thead>
                  <tbody className="table-body tableUserData">
                    {filteredContactDetails?.map((item, index) => (
                      <tr
                        key={index}
                        onClick={() => {
                          setSelectedContact(item);
                        }}
                      >
                        <td style={{ width: "20%" }} className="overflow_text">
                          {item.contactName}
                        </td>
                        <td style={{ width: "20%" }} className="overflow_text">
                          {item.accountName}
                        </td>
                        <td style={{ width: "20%" }} className="overflow_text">
                          {item.emailId}
                        </td>
                        <td style={{ width: "20%" }} className="overflow_text">
                          {item.phone}
                        </td>
                        <td style={{ width: "20%" }} className="overflow_text">
                          {item.contactOwner}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
            <div className="d-flex w-100 align-items-center justify-content-end gap-2">
              {filteredContactDetails.length > 0 && (
                <>
                  <Select
                    options={pageOption}
                    styles={customCommonStyles("100%")}
                    // value={selectPageOption}
                    value={pageOption.find(
                      (option) => option.value === perPageContact.toString()
                    )}
                    onChange={handlePageDropDownChangeContact}
                  />
                  <Pagination
                    currentPage={currentPageContact}
                    totalPages={totalPagesContact}
                    onPageChange={hadlePageChangeContact}
                  />
                </>
              )}
            </div>
            <div className="d-flex pb-2 pt-3 justify-content-between">
              <div className="selectedUser d-flex gap-1">
                <span>Selected User</span>
                <img src={logoConvert} alt="img" height={20} width={20} />
                <span>{selectedContact.contactName}</span>
              </div>
              <div className="d-flex gap-2">
                <button
                  className="btn btn-outline-secondary border border-primary btn-sm px-3 py-2 text-dark"
                  onClick={() => {
                    setIsContactListModal(false);
                    setSelectedContact({});
                  }}
                >
                  Cancel
                </button>
                <button
                  className="btn btnColor btn-sm px-3 text-white"
                  onClick={() => {
                    setIsContactListModal(false);
                  }}
                >
                  Done
                </button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={isLeadListModal}
        className="d-flex justify-content-center align-items-center"
        aria-labelledby="example-custom-modal-styling-title"
        size="lg"
        id="commonLeadModal"
      >
        <Modal.Body className="ModalBody  overflow-auto">
          <div
            className="modalUS row justify-content-center"
            style={{ margin: "0", gap: "10px", paddingBottom: "10px" }}
          >
            <div className="header_Container d-flex justify-content-between">
              <h4 className="commonTextColor pt-2">Change Lead</h4>
              <div className="inputContainer d-flex justify-content-start">
                <input
                  type="text"
                  placeholder="Search"
                  className="topBarModalSearch px-5"
                  onChange={handleSearchChangeLead}
                  value={searchLead}
                />
                <img src={SearchIcon} alt="search" className="searchIcon" />
              </div>
            </div>

            <div className="Owner_table_container border border-dark rounded-2 h-25">
              <div className="table-wrapper">
                <table className="userdata-table">
                  <thead>
                    <tr>
                      <th style={{ width: "15%" }}>Lead Name</th>
                      <th style={{ width: "15%" }}>Company</th>
                      <th style={{ width: "20%" }}>Email</th>
                      <th style={{ width: "15%" }}>Phone</th>
                      <th style={{ width: "15%" }}>Lead Source</th>
                      <th style={{ width: "15%" }}>Lead Owner</th>
                    </tr>
                  </thead>
                  <tbody className="table-body tableUserData">
                    {filteredLeadDetails?.map((item, index) => (
                      <tr
                        key={index}
                        onClick={() => {
                          setSelectedLeadItem(item);
                        }}
                      >
                        <td style={{ width: "15%" }} className="overflow_text">
                          {" "}
                          {item.leadName}{" "}
                        </td>
                        <td style={{ width: "15%" }} className="overflow_text">
                          {item.companyName}
                        </td>
                        <td style={{ width: "20%" }} className="overflow_text">
                          {item.emailId}
                        </td>
                        <td style={{ width: "15%" }} className="overflow_text">
                          {item.phoneNumber}
                        </td>
                        <td style={{ width: "15%" }} className="overflow_text">
                          {item.leadSource}
                        </td>
                        <td style={{ width: "15%" }} className="overflow_text">
                          {item.leadOwner}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
            <div className="d-flex w-100 align-items-center justify-content-end gap-2">
              {filteredLeadDetails.length > 0 && (
                <>
                  <Select
                    options={pageOption}
                    styles={customCommonStyles("100%")}
                    // value={selectPageOption}
                    value={pageOption.find(
                      (option) => option.value === perPageLead.toString()
                    )}
                    onChange={handlePageDropDownChangeLead}
                  />
                  <Pagination
                    currentPage={currentPageLead}
                    totalPages={totalPagesLead}
                    onPageChange={hadlePageChangeLead}
                  />
                </>
              )}
            </div>
            <div className="d-flex pb-2 pt-3 justify-content-between">
              <div className="selectedUser d-flex gap-1">
                <span>Selected User</span>
                <img src={logoConvert} alt="img" height={20} width={20} />
                <span>{selectedLeadItem?.leadName}</span>
              </div>
              <div className="d-flex gap-2">
                <button
                  className="btn btn-outline-secondary border border-primary btn-sm px-3 py-2 text-dark"
                  onClick={() => {
                    setIsLeadListModal(false);
                    setSelectedLeadItem({});
                  }}
                >
                  Cancel
                </button>
                <button
                  className="btn btnColor btn-sm px-3 text-white"
                  onClick={() => {
                    setIsLeadListModal(false);
                  }}
                >
                  Done
                </button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default OpenActivity;

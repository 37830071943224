import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import SearchIcon from "../Assets/search.svg";
import logoConvert from "../Assets/logoConvert.svg";

function LeadListModal({
  isLeadListModal,
  setIsLeadListModal,
  onSelectedLeadChange,
  defaultUser,
  leadDetails,
}) {
  //   const userList = JSON.parse(sessionStorage.getItem("userList"));
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedLead, setSelectedLead] = useState("");
  // const [leadDetails, setLeadDetails] = useState([]);

  const handleSearchChange = (e) => {
    const { value } = e.target;
    // Check if the input is not just spaces
    if (value.trim() !== "" || value === "") {
      setSearchQuery(value);
    }
  };
  const filteredUserList = leadDetails?.filter((item) =>
    item.leadName?.toLowerCase().includes(searchQuery?.toLowerCase())
  );

  useEffect(() => {
    setSearchQuery("");
    setSelectedLead(
      defaultUser || {
        name: "",
        userId: "",
      }
    );
  }, [isLeadListModal]);

  // const getAllLeadsDetails = async () => {
  //   const requestBody = {
  //     loggedInUserId: loginUserId,
  //     pageNo: 1,
  //     pageSize: 10,
  //   };
  //   await axios
  //     .post(URI.getAllLeads, requestBody)

  //     .then((response) => {
  //       if (response.data.status === 200) {
  //         setLeadDetails(response.data.data.allLeadDetails);
  //       }
  //     })
  //     .catch(async (error) => {
  //        throw new Error("Failed to fetch data", error);;
  //     });
  // };

  // useEffect(() => {
  //   getAllLeadsDetails();
  // }, []);

  return (
    <div className="OwnerModal">
      <Modal
        show={isLeadListModal}
        className="d-flex justify-content-center align-items-center"
        aria-labelledby="example-custom-modal-styling-title"
        size="lg"
        id="commonLeadModal"
      >
        <Modal.Body className="ModalBody  overflow-auto">
          <div
            className="modalUS row justify-content-center"
            style={{ margin: "0", gap: "10px", paddingBottom: "10px" }}
          >
            <div className="header_Container d-flex justify-content-between">
              <h4 className="commonTextColor pt-2">Choose Lead</h4>
              <div className="inputContainer d-flex justify-content-start">
                <input
                  type="text"
                  placeholder="Search"
                  className="topBarModalSearch px-5"
                  onChange={handleSearchChange}
                  value={searchQuery}
                />
                <img src={SearchIcon} alt="search" className="searchIcon" />
              </div>
            </div>

            <div className="Owner_table_container border border-dark rounded-2 h-25">
              <div className="table-wrapper">
                <table className="userdata-table">
                  <thead>
                    <tr>
                      <th>Lead Name</th>
                      <th>Company</th>
                      <th>Email</th>
                      <th>Phone</th>
                      <th>Lead Source</th>
                      <th>Lead Owner</th>
                    </tr>
                  </thead>
                  <tbody className="table-body tableUserData">
                    {filteredUserList?.map((item, index) => (
                      <tr
                        key={index}
                        onClick={() => {
                          setSelectedLead(item);
                        }}
                      >
                        <td>
                          {/* <img
                            src={logoConvert}
                            alt="img"
                            height={20}
                            width={20}
                          /> */}

                          {item.leadName}
                        </td>
                        <td>{item.companyName}</td>
                        <td>{item.emailId}</td>
                        <td>{item.phoneNumber}</td>
                        <td>{item.leadSource}</td>
                        <td>{item.leadOwner}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
            <div className="d-flex pb-2 pt-3 justify-content-between">
              <div className="selectedUser d-flex gap-1">
                <span>Selected User</span>
                <img src={logoConvert} alt="img" height={20} width={20} />
                <span>{selectedLead.leadName}</span>
              </div>
              <div className="d-flex gap-2">
                <button
                  className="btn btn-outline-secondary border border-primary btn-sm px-3 py-2 text-dark"
                  onClick={() => setIsLeadListModal(false)}
                >
                  Cancel
                </button>
                <button
                  className="btn btnColor btn-sm px-3 text-white"
                  onClick={() => {
                    setIsLeadListModal(false);
                    onSelectedLeadChange(selectedLead);
                  }}
                >
                  Done
                </button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default LeadListModal;

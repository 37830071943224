import React from "react";
import ReactApexChart from "react-apexcharts";

const GaugeChartComponent = ({
  component,
  deleteIcon,
  setModalType,
  setSelectedComponentId,
  currentView,
  data,
}) => {
  const value = data[0]?.totalLeads / data[0]?.target;
  // let value = data[0]?.target - data[0]?.remaining;
  const options = {
    chart: {
      type: "radialBar",
      offsetY: -20,
      sparkline: {
        enabled: true,
      },
    },
    plotOptions: {
      radialBar: {
        startAngle: -90,
        endAngle: 90,
        track: {
          background: "#e7e7e7",
          strokeWidth: "97%",
          margin: 5, // margin is in pixels
          dropShadow: {
            enabled: true,
            top: 2,
            left: 0,
            color: "#999",
            opacity: 1,
            blur: 2,
          },
        },
        dataLabels: {
          name: {
            show: true,
          },
          value: {
            offsetY: -45,
            fontWeight: "bold",
            color: "#333",
          },
        },
      },
    },
    grid: {
      padding: {
        top: 0,
      },
    },
    fill: {
      type: "gradient",
      gradient: {
        shade: "light",
        shadeIntensity: 0.4,
        inverseColors: false,
        opacityFrom: 1,
        opacityTo: 1,
        stops: [0, 50, 53, 91],
      },
    },
    labels: [`Remaining ${data[0]?.remaining}`],
    // labels: [
    //   {
    //     text: `Remaining ${data[0]?.remaining}`,
    //     style: {
    //       fontSize: "20px", // Adjust the font size for the label
    //       fontWeight: "bold", // Make the label bold
    //       color: "#333", // Change the label color for better contrast
    //       marginTop: "10px", // Adjust the top margin for better positioning
    //     },
    //   },
    // ],
  };

  const series = [parseFloat(value * 100).toFixed(2)];

  return (
    <div
      className={` rounded-1 position-relative ${
        currentView === "analytics"
          ? "bg-transparent h-100"
          : "mx-2 mt-3 component_container"
      }`}
    >
      {currentView !== "analytics" && (
        <div className={`component_header d-flex justify-content-between p-2`}>
          <span className="first_letter_uppercase fs-5">
            {component.componentName}
          </span>
        </div>
      )}
      <div
        className={`component_content p-2 text-dark gauge_chart ${
          currentView === "analytics" ? "position-relative" : ""
        }`}
      >
        <ReactApexChart
          options={options}
          series={series}
          type="radialBar"
          // height={350}
        />
        <span className="position-absolute" style={{ left: "40%" }}>
          Total: {data[0]?.target}
        </span>
      </div>
      {currentView !== "analytics" && (
        <div
          className={`component_footer p-2 position-absolute end-0 bottom-0 mb-1 `}
        >
          <span className="">
            <span
              className="deleteIcon rounded-1 p-1 cursorPointer"
              onClick={() => {
                // setSelectedComponentId("");
                setModalType("delete");
                setSelectedComponentId(component.componentId);
              }}
            >
              {deleteIcon}
            </span>
          </span>
        </div>
      )}
    </div>
  );
};

export default GaugeChartComponent;

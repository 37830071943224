import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import SearchIcon from "../Assets/search.svg";
import logoConvert from "../Assets/logoConvert.svg";

const LeadOwnerModal = ({
  showModalType,
  isLeadOwnerModal,
  setIsLeadOwnerModal,
  onSelectedLeadChange,
  defaultUser,
}) => {
  const userList = JSON.parse(sessionStorage.getItem("userList"));
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedLead, setSelectedLead] = useState("");

  const handleSearchChange = (e) => {
    const { value } = e.target;
    // Check if the input is not just spaces
    if (value.trim() !== "" || value === "") {
      setSearchQuery(value);
    }
  };
  const filteredUserList =
    userList &&
    userList.filter((item) =>
      item.name?.toLowerCase().includes(searchQuery?.toLowerCase())
    );

  useEffect(() => {
    setSearchQuery("");
    setSelectedLead(
      defaultUser || {
        name: "",
        userId: "",
      }
    );
  }, [isLeadOwnerModal]);

  return (
    <div className="OwnerModal">
      <Modal
        show={
          showModalType === "type" ? isLeadOwnerModal !== "" : isLeadOwnerModal
        }
        className="d-flex justify-content-center align-items-center"
        aria-labelledby="example-custom-modal-styling-title"
        size="lg"
        id="commonLeadModal"
      >
        <Modal.Body className="ModalBody  overflow-auto">
          <div
            className="modalUS row justify-content-center"
            style={{
              margin: "0",
              gap: "10px",
              paddingBottom: "10px",
            }}
          >
            <div className="header_Container d-flex justify-content-between">
              <h4 className="commonTextColor pt-2">Change Owner </h4>
              {/* <div className="d-flex justify-content-start">
                <input
                  type="text"
                  className="inputBox"
                  placeholder="Search users"
                  value={searchQuery}
                  onChange={handleSearchChange}
                />
                <img src={SearchIcon} alt="search" className="SearchIcon" />
              </div> */}
              <div className="inputContainer d-flex justify-content-start">
                <input
                  type="text"
                  placeholder="Search"
                  className="topBarModalSearch px-5"
                  onChange={handleSearchChange}
                  value={searchQuery}
                />
                <img src={SearchIcon} alt="search" className="searchIcon" />
              </div>
            </div>

            <div className="Owner_table_container rounded-4 h-25">
              <div className="table-wrapper">
                <table className="userdata-table">
                  <thead>
                    <tr>
                      <th>User Name</th>
                      <th>Role</th>
                      <th>Email</th>
                      <th>Profile</th>
                    </tr>
                  </thead>
                  <tbody className="table-body tableUserData">
                    {filteredUserList?.map((user, index) => (
                      <tr
                        key={index}
                        onClick={() => {
                          setSelectedLead(user);
                        }}
                      >
                        <td className="">
                          <span className="ms-2"> {user.name} </span>
                        </td>
                        <td>{user.role}</td>
                        <td>{user.emailId}</td>
                        <td>{user.profile}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>

            <div className="d-flex pb-2 pt-3 justify-content-between">
              <div className="selectedUser d-flex gap-1 pt-2">
                <span>Selected User</span>
                <img src={logoConvert} alt="img" height={25} width={25} />
                <span>{selectedLead?.name}</span>
              </div>
              <div className="d-flex gap-2">
                <button
                  className="createContactButton modalButton"
                  onClick={() =>
                    setIsLeadOwnerModal(showModalType === "type" ? "" : false)
                  }
                >
                  Cancel
                </button>
                <button
                  className="createContactButtonSave modalButton"
                  onClick={() => {
                    setIsLeadOwnerModal(false);
                    onSelectedLeadChange(selectedLead);
                  }}
                >
                  Done
                </button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default LeadOwnerModal;

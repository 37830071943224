import { BASE_URL, SOCKET_BASE_URL } from "./DataConst";

export function getHost() {
  return BASE_URL;
}

export function getSocketHost() {
  return SOCKET_BASE_URL;
}

export var URI = getURI();

export function getURI() {
  return {
    // Login
    checkEmailExist: `${getHost()}/user/v1/check-email-exists/`,

    signIn: `${getHost()}/user/v1/sign-in`,
    getLoginHistory: `${getHost()}/user/v1/get-login-history`,
    getLoginActivity: `${getHost()}/user/v1/get-login-activity`,
    forgotPassword: `${getHost()}/user/v1/forgot-password`,
    signInOtp: `${getHost()}/user/v1/sign-in-with-otp`,
    rejectEmail: `${getHost()}/user/v1/reject-sign-up-invitation`,

    //sign up
    verifyEmailLink: `${getHost()}/user/v1/verify-invite-token-dtls`,
    signupInvitation: `${getHost()}/user/v1/user-sign-up`,
    newUserSignUp: `${getHost()}/user/v1/sign-up`,
    generalSearch:`${getHost()}/user/v1/general-search`,

    //user
    getUserList: `${getHost()}/user/v1/search-user-details`,
    getRoles: `${getHost()}/user/v1/get-all-role-details`,
    getProfileList: `${getHost()}/user/v1/get-all-profile-details`,
    addUser: `${getHost()}/user/v1/invite-user-mail`,
    activeInactiveUser: `${getHost()}/user/v1/active-inactive-user`,
    getRecyclebinHistory: `${getHost()}/user/v1/get-recycle-bin-dtls`,
    deleteRestore: `${getHost()}/user/v1/delete-restore-recycle-bin`,
    emptyRecycle: `${getHost()}/user/v1/empty-recycle-bin`,
    getProfileUserInfo: `${getHost()}/user/v1/get-user-info-by-userId`,
    getGroupList: `${getHost()}/user/v1/get-group-list`,
    getAuditLog: `${getHost()}/user/v1/get-audit-log`,
    addGroup: `${getHost()}/user/v1/add-group-details`,
    deleteGroup: `${getHost()}/user/v1/delete-group-details`,
    renameEditGroup: `${getHost()}/user/v1/update-group-details`,
    getGroupMembers: `${getHost()}/user/v1/get-group-members-details`,
    addUserMember: `${getHost()}/user/v1/create-group-with-group-members`,
    deleteUser: `${getHost()}/user/v1/delete-user-details`,
    getAllRoleList: `${getHost()}/user/v1/get-all-role-list`,
    addGroupMemberList: `${getHost()}/user/v1/add-group-member-list`,
    deleteGroupUsers: `${getHost()}/user/v1/delete-group-members`,
    deleteGroupRoles: `${getHost()}/user/v1/delete-group-role`,
    deleteGroupByGroups: `${getHost()}/user/v1/delete-group-by-groupId`,
    getMembersToAdd: `${getHost()}/user/v1/get-members-details-to-add`,
    getGroupUserList: `${getHost()}/user/v1/get-group-user-list`,
    updateUserDetails: `${getHost()}/user/v1/update-user-details`,
    addNewProfile: `${getHost()}/user/v1/add-profile-details`,
    getProfilePermission: `${getHost()}/user/v1/get-profile-permission`,
    updateProfilePermission: `${getHost()}/user/v1/update-module-permission-details`,
    addNewRole: `${getHost()}/user/v1/add-role-details`,
    deleteRoleDetails: `${getHost()}/user/v1/delete-role-details`,
    renameProfile: `${getHost()}/user/v1/update-profile-details`,
    deleteProfile: `${getHost()}/user/v1/delete-profile-details`,
    viewUsersProfile: `${getHost()}/user/v1/get-users-by-profile`,
    editRole: `${getHost()}/user/v1/update-role-details`,
    getUsersRole: `${getHost()}/user/v1/get-users-by-role`,
    addSocialProfile: `${getHost()}/user/v1/add-social-profile-details`,
    deleteSocialProfile: `${getHost()}/user/v1/delete-socialmedia-info`,
    uploadUserImage: `${getHost()}/user/v1/upload-user-image`,
    getStorageData: `${getHost()}/user/v1/get-storage-module`,
    getStorageFileData: `${getHost()}/user/v1/get-files-storage-details`,
    getStorageFileDataPopup: `${getHost()}/user/v1/get-usage-by-module-storage`,
    getAllExportData: `${getHost()}/user/v1/get-all-data-export-history`,
    downloadExcel: `${getHost()}/user/v1/get-export-data-excel`,
    getExportDetails: `${getHost()}/user/v1/create-export-data`,
    getAuditLogExportDetails: `${getHost()}/user/v1/get-audit-export-details`,
    downloadAuditLog: `${getHost()}/user/v1/get-export-data-excel`,
    removedataBackup: `${getHost()}/user/v1/delete-all-module-sample-data`,
    getSampleData: `${getHost()}/user/v1/get-sample-data-count`,
    getCompanyDetails: `${getHost()}/user/v1/get-company-details`,
    updateCompanyDetails: `${getHost()}/user/v1/update-company-details`,
    uploadCompanyProfile: `${getHost()}/user/v1/upload-company-profile`,
    changePassword: `${getHost()}/user/v1/change-password`,

    // Share
    insertShare: `${getHost()}/user/v1/insert-share-details`,
    getShareInfo: `${getHost()}/user/v1/get-sharing-info`,
    revokeShare: `${getHost()}/user/v1/update-share-revoke`,
    // lead
    addLeadDetails: `${getHost()}/user/v1/insert-lead-details`,
    getAllLeads: `${getHost()}/user/v1/get-all-leads`,
    leadOverview: `${getHost()}/user/v1/get-lead-overview`,
    updateLeadDetails: `${getHost()}/user/v1/update-lead-all-fields`,
    individualUpdateLeads: `${getHost()}/user/v1/update-lead-details`,
    searchLeadList: `${getHost()}/user/v1/search-for-lead-details`,
    getLeadTimeline: `${getHost()}/user/v1/get-lead-timeline`,
    convertLeadDetails: `${getHost()}/user/v1/convert-lead-info-details`,
    searchLeadOwnerList: `${getHost()}/user/v1/get-user-details-by-name-email`,
    removeLeadImage: `${getHost()}/user/v1/delete-lead-image`,
    deleteLead: `${getHost()}/user/v1/delete-lead-details`,

    // contact
    createContact: `${getHost()}/user/v1/insert-contact-details`,
    getAllContact: `${getHost()}/user/v1/get-all-contacts`,
    getContactOverview: `${getHost()}/user/v1/get-contact-overview/`,
    updateContactDetails: `${getHost()}/user/v1/update-contact-all-fields`,
    getContactTimeline: `${getHost()}/user/v1/get-contact-timeline`,
    individualUpdateContact: `${getHost()}/user/v1/update-contact-details`,
    removeContactImage: `${getHost()}/user/v1/delete-contact-image`,
    deleteContact: `${getHost()}/user/v1/delete-contact-details`,

    // account
    addAccountDetails: `${getHost()}/user/v1/insert-account-details`,
    accountOverview: `${getHost()}/user/v1/get-account-overview`,
    updateAccountDetails: `${getHost()}/user/v1/update-account-all-fields`,
    individualUpdateAccount: `${getHost()}/user/v1/update-account-details`,
    getAllAccountDetails: `${getHost()}/user/v1/get-all-accounts`,
    getAccountTimeline: `${getHost()}/user/v1/get-account-timeline`,
    removeImage: `${getHost()}/user/v1/delete-account-image`,
    deleteAccountDetails: `${getHost()}/user/v1/delete-account-details`,
    getAccountOrContactDetails: `${getHost()}/user/v1/get-account-or-contact-dtls-id/`,

    // Task
    getAllTask: `${getHost()}/user/v1/get-all-tasks`,
    createTask: `${getHost()}/user/v1/insert-task-details`,
    taskOverview: `${getHost()}/user/v1/get-task-overview-details`,
    taskTimeline: `${getHost()}/user/v1/get-task-timeline-details`,
    individualUpdateTask: `${getHost()}/user/v1/update-task-details`,
    updateAllTask: `${getHost()}/user/v1/update-task-all-fields`,
    closeTask: `${getHost()}/user/v1/update-close-task`,
    deleteTask: `${getHost()}/user/v1/delete-task-details`,

    //vendor
    getAllVendors: `${getHost()}/user/v1/get-all-vendors`,
    addVendorDetails: `${getHost()}/user/v1/insert-vendor-details`,
    vendorOverview: `${getHost()}/user/v1/get-vendor-overview-details`,
    updateAllVendorDetails: `${getHost()}/user/v1/update-vendor-all-fields`,
    individualUpdateVendor: `${getHost()}/user/v1/update-vendor-details`,
    getVendorTimeline: `${getHost()}/user/v1/get-vendors-timeline`,
    removeVendorImage: `${getHost()}/user/v1/delete-vendor-image`,
    deleteVendor: `${getHost()}/user/v1/delete-vender-details`,

    // Product
    createProduct: `${getHost()}/user/v1/insert-product-details`,
    getAllProduct: `${getHost()}/user/v1/get-all-products`,
    getProductOverview: `${getHost()}/user/v1/get-product-overview-details/`,
    updateAllProduct: `${getHost()}/user/v1/update-product-all-fields`,
    individualUpdateProduct: `${getHost()}/user/v1/update-product-details`,
    getProductTimeline: `${getHost()}/user/v1/get-product-timeline`,
    removeProductImage: `${getHost()}/user/v1/delete-product-image`,
    deleteProductDetails: `${getHost()}/user/v1/delete-product-details`,

    // Sales
    createSales: `${getHost()}/user/v1/insert-sales-details`,
    getAllSales: `${getHost()}/user/v1/get-all-sales-orders`,
    updateSalesDetails: `${getHost()}/user/v1/update-sales-all-fields`,
    getSalesTimeline: `${getHost()}/user/v1/get-sales-timeline-details`,
    salesOverview: `${getHost()}/user/v1/get-sales-overview`,
    individualUpdateSales: `${getHost()}/user/v1/update-sales-details`,
    deleteSales: `${getHost()}/user/v1/delete-sales-details`,

    // Quote
    createQuote: `${getHost()}/user/v1/insert-quote-details`,
    getAllQuote: `${getHost()}/user/v1/get-all-quotes`,
    quoteOverview: `${getHost()}/user/v1/get-quotes-overview-details`,
    updateQuoteDetails: `${getHost()}/user/v1/update-quote-all-fields`,
    individualUpdateQuote: `${getHost()}/user/v1/update-quote-details`,
    getQuoteTimeline: `${getHost()}/user/v1/get-quotes-timeline`,
    deleteQuote: `${getHost()}/user/v1/delete-quote-details`,

    //purchase
    addPurchaseDetails: `${getHost()}/user/v1/insert-purchase-details`,
    getAllPurchaseDetails: `${getHost()}/user/v1/get-all-purchase-orders`,
    getOverviewPurchaseDetails: `${getHost()}/user/v1/get-purchase-overview-details/`,
    getTimeline: `${getHost()}/user/v1/get-purchase-timeline-details/`,
    updatePurchase: `${getHost()}/user/v1/update-purchase-details`,
    updateAllPurchase: `${getHost()}/user/v1/update-purchase-all-fields`,
    deletePurchase: `${getHost()}/user/v1/delete-purchase-details`,

    //Deals
    createDeal: `${getHost()}/user/v1/insert-deal-details`,
    addDealDetails: `${getHost()}/user/v1/insert-deal-details`,
    getAllDeals: `${getHost()}/user/v1/get-all-deals`,
    dealOverview: `${getHost()}/user/v1/get-deal-overview`,
    updateDealDetails: `${getHost()}/user/v1/update-deal-all-fields`,
    individualUpdateDeals: `${getHost()}/user/v1/update-deal-details`,
    getDealTimeline: `${getHost()}/user/v1/get-deal-timeline`,
    deleteDealDetails: `${getHost()}/user/v1/delete-deal-details`,
    verifyDeal: `${getHost()}/user/v1/verify-deal-info`,

    // Invoice
    getAllInvoices: `${getHost()}/user/v1/get-all-invoices`,
    insertInvoice: `${getHost()}/user/v1/insert-invoice-details`,
    invoiceOverview: `${getHost()}/user/v1/get-invoice-overview/`,
    getInvoiceTimeline: `${getHost()}/user/v1/get-invoice-timeline/`,
    updateAllInvoice: `${getHost()}/user/v1/update-invoice-all-fields`,
    updateInvoice: `${getHost()}/user/v1/update-invoice-details`,
    deleteInvoice: `${getHost()}/user/v1/delete-invoice-details`,

    //note
    fetchNote: `${getHost()}/user/v1/fetch-notes-image`,
    insertNote: `${getHost()}/user/v1/insert-notes-details`,
    getNote: `${getHost()}/user/v1/get-notes-details`,
    deleteNote: `${getHost()}/user/v1/delete-notes-details`,
    updateNote: `${getHost()}/user/v1/update-notes-details`,

    // assign role

    assignRole: `${getHost()}/user/v1/assign-module-relation`,
    deleteRole: `${getHost()}/user/v1/delete-module-relation`,

    //reports
    getReports: `${getHost()}/user/v1/get-reports`,

    //mass
    massDelete: `${getHost()}/user/v1/mass-delete`,
    massUpdate: `${getHost()}/user/v1/mass-update`,
    massTransfer: `${getHost()}/user/v1/mass-transfer`,

    //calls
    getAllCalls: `${getHost()}/user/v1/get-all-call-information`,
    insertCalls: `${getHost()}/user/v1/insert-call-information`,
    callOverviewDetails: `${getHost()}/user/v1/get-call-overview`,
    getCallsTimeline: `${getHost()}/user/v1/get-call-timeline`,
    deleteCalls: `${getHost()}/user/v1/delete-call-information`,
    updateCalls: `${getHost()}/user/v1/update-all-call-information`,
    individualUpdateCalls: `${getHost()}/user/v1/update-call-information`,
    rescheduleCancelCalls: `${getHost()}/user/v1/reschedule-cancel-call`,

    //meetings
    getAllMeetings: `${getHost()}/user/v1/get-all-meeting-information`,
    insertMeetings: `${getHost()}/user/v1/insert-meeting-information`,
    meetingOverviewDetails: `${getHost()}/user/v1/get-meeting-overview`,
    addMeetingParticipants: `${getHost()}/user/v1/add-meeting-participants`,
    deleteMeetings: `${getHost()}/user/v1/delete-meeting-information`,
    updateMeetings: `${getHost()}/user/v1/update-meeting-information`,
    getMeetingsTimeline: `${getHost()}/user/v1/get-meeting-timeline`,
    checkMeetingAvailable: `${getHost()}/user/v1/check-meeting-overlaps`,

    //campaigns
    getAllCampaigns: `${getHost()}/user/v1/get-all-campaign-information`,
    insertCampaigns: `${getHost()}/user/v1/insert-campaign-information`,
    campaignOverviewDetails: `${getHost()}/user/v1/get-campaign-overview-details`,
    getCampaignTimeline: `${getHost()}/user/v1/get-campaign-timeline-details`,
    deleteCampaignDetails: `${getHost()}/user/v1/delete-campaign-details`,
    updateAllCampaigns: `${getHost()}/user/v1/update-campaign-information`,

    //Home
    getDashboardData: `${getHost()}/user/v1/get-all-dashboard-data`,
    addDashboardData: `${getHost()}/user/v1/add-dashboard-data`,
    deleteDashboardData: `${getHost()}/user/v1/delete-dashboard-data`,

    // ModuleWiseAssign
    moduleWiseAssign: `${getHost()}/user/v1/get-all-assign-modulewise-details`,
    getAllUnassignProducts: `${getHost()}/user/v1/get-all-unassign-module-details`,

    //subscription
    subscriptionPlan: `${getHost()}/user/v1/get-subscription-plan-details`,
    insertSubscription: `${getHost()}/user/v1/insert-subscriptions-details`,

    // Analytics
    getAnalyticDetails: `${getHost()}/user/v1/get-analytics-details/`,
    getAnalyticsByFilterTrend: `${getHost()}/user/v1/get-analytics-sales-trend-filter`,
    getAnalyticsByFilterFollowUp: `${getHost()}/user/v1/get-analytics-sales-follow-up-trend-filter`,

    // Alerts
    getUserAlerts: `${getHost()}/user/v1/get-user-alerts`,
    userAlertSetting: `${getHost()}/user/v1/crm-alert-setting`,

    /////////////////// Auth Token //////////////////////
    getAuthToken: `${getHost()}/auth/token`,

    //////////////// Sign out /////////////////
    signOut: `${getHost()}/user/v1/sign-out`,

    /////////////// socket API //////////////
    socketStream: `${getSocketHost()}/ws`,
  };
}

import React from "react";
import userSearch from "../../Assets/user-search-01.svg";

const MassTransfer = ({
  handleOwnerInputClick,
  setIsLeadOwnerModal,
  handleClose,
  handleMassTransfer,
  selectedItem,
}) => {
  return (
    <div className="w-100 px-4 py-2 mb-1">
      <label className="text-start w-100 opacity-75">Transfer To</label>
      <div
        className="d-flex input-with-clear position-relative mx-auto"
        onClick={(e) => handleOwnerInputClick(e)}
      >
        <input
          type="text"
          className="ps-3 w-100 cursorPointer py-2"
          value={selectedItem.name}
          readOnly
        />
        <span
          className="position-absolute h-100 mass_transfer_user pt-1"
          style={{ right: "0.1rem" }}
        >
          <span
            className="cursorPointer px-1"
            onClick={() => setIsLeadOwnerModal(true)}
          >
            <img
              src={userSearch}
              alt="img"
              width={20}
              height={20}
              className="ms-2 mt-2 me-2"
            />
          </span>
        </span>
      </div>
      <div className="pt-4 pe-3">
        <div className="d-flex justify-content-end w-100 gap-4 pe-2">
          <button className="cancelButton px-4" onClick={handleClose}>
            Cancel
          </button>
          <button className="btn-update" onClick={handleMassTransfer}>
            Update
          </button>
        </div>
      </div>
    </div>
  );
};

export default MassTransfer;

import React from "react";
import { Outlet, Navigate } from "react-router-dom";

const PrivateRoute = () => {
  //   let auth = { token: sessionStorage.getItem("accessToken") };
  const auth = { token: localStorage.getItem("accessToken") };
  // console.log(auth, "auth");
  return auth.token !== "" && auth.token !== null ? (
    <Outlet />
  ) : (
    <Navigate to="../" />
  );
};
export default PrivateRoute;
